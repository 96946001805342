import React, { Fragment } from 'react'
import Slider from 'react-slick'
import Grp from '../../profile-components/Img/Grp.png'
import Grp1 from '../../profile-components/Img/Grp.png'
import Grp2 from '../../profile-components/Img/Grp.png'
import { Card, CardImg } from 'reactstrap'
import { Users } from 'react-feather'

const GroupCardData = (props) => {

    let sportSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };



    const SliderPostTypeData = [
        {
            img: Grp,
            sportName: "Enjoy Group",

        },
        {
            img: Grp1,
            sportName: "College Group",

        },
        {
            img: Grp2,
            sportName: "Dance Group",

        },
        {
            img: Grp1,
            sportName: "Cricket",

        },
        {
            img: Grp,
            sportName: "Cricket",

        }
    ]

    //============================ Main_return_function ========================// 
    return (
        <Fragment>
            <div className="setting_Group_blk_section">
                <div className="Settings_card-title">
                    <h3>{props.title}</h3>
                </div>
                <div className="card-block-box">
                    <Slider {...sportSettings} className="default-space">
                        {
                            SliderPostTypeData.map((data) => (
                                <div>
                                    <Card className="profile-slide-box">
                                        <CardImg
                                            alt="Card image cap"
                                            src={data.img}
                                            style={{ height: '200px', width: 'auto' }}
                                            className="img-fluid "
                                        />
                                        <div className="story-content">
                                            <h6>{data.sportName}</h6>
                                            <p><Users size={10} />&nbsp;150</p>
                                        </div>
                                    </Card>
                                </div>
                            ))
                        }


                    </Slider>
                </div>

            </div>

        </Fragment>
    )
}

export default GroupCardData