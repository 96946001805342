import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const DiscaedModal = ({ discard, toggleDiscard, toggleAllDiscard }) => {

    //============== Main_return_function =============//

    return (
        <Fragment>
            {discard !== false &&
                <Modal isOpen={discard} toggle={toggleDiscard} className='modal-dialog-centered '>
                    <ModalHeader toggle={toggleDiscard}>
                        <h3 style={{ color: "black" }}>Post Discard</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className='text-center'>
                            <h2 style={{ color: "black" }}>Are you sure ?</h2>
                            <h4 className='py-1' style={{ color: "#6E6B7B" }}>Do you really want to discard this post</h4>
                        </div>
                    </ModalBody>
                    <div className='w-100 d-flex  justify-content-center mb-3'>
                       
                        <Button onClick={toggleDiscard} color="primary" className="w-25 ml-2">
                        cancel</Button>
                        <Button onClick={toggleAllDiscard} color="primary" className="w-25 ml-2">Discard</Button>
                    </div>
                </Modal>
            }
        </Fragment>
    )
}

export default DiscaedModal