import { Radio } from '@mui/material';
import React from 'react'
import { Fragment } from 'react'
import { ProfileCommentData } from './PostStoryArray'

const PostCommentData = () => {
    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);

    };

    return (
        <Fragment>
            {/*=============== start_TagPravicy_Data_section ================*/}
            <div className='SettingPrivacy_Card_Body'>
                <h3 className='py-3'>Who can comment on your post?</h3>
                {ProfileCommentData.map((data) => (
                    <div className='SettingPrivacy_Content_Section'>
                        <div className='SettingPrivacy_SubContent_Section '>
                            <div>
                                <h4>{data.heading}</h4>
                                <p>{data.subContent}</p>
                            </div>
                            <div>
                                <Radio checked={selectedValue === data.id}
                                    onChange={handleChange}
                                    value={data.id}
                                    name="radio-buttons"
                                    className='Radio_btn_check'
                                    color='primary'
                                    inputProps={{ 'aria-label': 'A' }} />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            {/*=============== End_TagPravicy_Data_section ================*/}
        </Fragment>
    )
}

export default PostCommentData