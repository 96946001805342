import React, { Fragment, useEffect, useState } from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, Progress, Row, TabContent, NavLink, TabPane } from 'reactstrap';
import { BellOff, Edit2, EyeOff, Globe, Link2, MessageCircle, MoreHorizontal, Navigation, Search, Send, Trash2 } from 'react-feather';
import ViewProfile from "./Img/ViewProfile.png"
import Profile2 from "./Img/profile2.png"
import Profile3 from "./Img/profile3.png"
import Profile4 from "./Img/profile4.png"
import Union from './Img/Union.svg'
import PollLeftComponents from './PollAllComponents/PollLeftComponents';
import CreationMainSection from '../../components/Social-feed/CreationMainSection';
import DiscaedModal from '../../components/Social-feed/DiscaedModal';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const MyPoll = () => {
    const [pollSearch, setPollSearch] = useState('')
    const [pollCount,setPollCount]=useState(0);
    const [error,setError]=useState('');
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                        { postType:"poll", pageSize:5, pageIndex:0, searchKey:'', userId:userId},
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setPollCount(response.data.data.successResult.count);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error);
            }
        };
      
        fetchData();
      }, []);

      //========================poll creation=====================

      const [activeTab, setActiveTab] = useState(1000);
      const states=activeTab;
      const [postType, setPostType] = useState("poll");
      const [currState,setCurrState]=useState(0)
      const [modal, setModal]=useState('')
      const [selectedImages,setSelectedImages]=useState('')
      const [path, setPath] = useState("/modal/");
      const [discard, setDisCard] = useState(false);
      const [feelingCategory,setFeelingCategory]=useState("");

      const toggleDiscard = () => {
        setDisCard((preState) => !preState);
      };
      const toggleAllDiscard = () => {
        setModal(false);
        toggleDiscard(false);
    
      };

    const toggle = (path) => {

        console.log(path.indexOf("/modal/"),"   path.indexof(`modal`)")
        if (path.indexOf("/modal/" > -1)) {
          if (modal) {
            setActiveTab(parseFloat(path.replace("/modal/", "")));
        
            setPath(path);
           
            const index = parseFloat(path.replace("/modal/", ""));
          // console.log("in toggle function",index)
            const postType ="event"
           
          } else {
          console.log("in toglle mode elese sections")
          const index = parseFloat(path.replace("/modal/", ""));
          
          
            setActiveTab(parseFloat(path.replace("/modal/", "")));
            setModal(true);
            setPath(path);
          
          }
        } else {
    
          setModal(false);
        }
      };

      const handleLinkClick = (e) => {
        e.preventDefault();
        setModal(true);
        toggle("/modal/7.8");
    };

    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                <div className="page-content">
                    <div className="content-center w-100">
                        <div className="gallery-page-section section-b-space">
                            <div className="card-title">
                                <h3>My Polls ({pollCount||0})</h3>
                                <div className="right-setting">
                                    <div className="profile-search-blk ">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        <input 
                                        type="text"
                                         className="form-control"
                                          name="search" 
                                          placeholder="Find ..." 
                                          data-bs-toggle="modal" 
                                          data-bs-target="#peopleSearch" 
                                          onChange={(e) => {setPollSearch(e.target.value)}}
                                          />
                                    </div>
                                    <Link onClick={(e) => handleLinkClick(e)} className="btn btn-outline-green ms-3">Create Poll</Link>
                                </div>
                            </div>

                        </div>
                        <PollLeftComponents pollSearch={pollSearch}/>
                    </div>
                </div>
            </div>
                </div>
                <RightSidebar />
                <CreationMainSection
                userId={userId}
                states={states}
                currState={currState}
                    setModal={setModal}
                    modal={modal}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    path={path}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    postType={postType}
                    setPostType={setPostType}
                    toggle={toggle}
                    toggleDiscard={toggleDiscard}
                    feelingCategories={feelingCategory}
                />
                <DiscaedModal
                    discard={discard}
                    toggleDiscard={toggleDiscard}
                    toggleAllDiscard={toggleAllDiscard}
                />
            </div>
        </>
    )
}

export default MyPoll