import React, { useState } from 'react'
import { AlertTriangle, Archive, Download, Edit, Eye, EyeOff, Filter, Link, MessageCircle, MoreHorizontal, MoreVertical, Music, Send, Share2, Trash2, Video, XCircle } from 'react-feather'
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import like from '../../NewComponents/IMG/like.png'
import Shotzs from '../../NewComponents/IMG/Shotzs.png'
import LocationIcon from '../../NewComponents/IMG/LocationIcon.svg'
import SliderButton from './SliderButton';
import MediaModal from '../../components/profile-components/MediaModal'
import ShotzModal from '../Shotzs/shotz_components/ShotzModal'
import LikeBlack from '../profile-components/Img//likesvg.svg'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
const Shotz = () => {
  const theme = useTheme()
  const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
  const toggleShotzCarouselModal = () => {
    setShotzCarouselModal((prevState) => !prevState)
  }
  const [shareOpen, setShareOpen] = useState(false)
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState)
  }
  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = () => {
    setReportOpen((preState) => !preState)
  }
  const ReportDataInfo = [
    {
      name: "Spam"
    },
    {
      name: "Nudity"
    },
    {
      name: "Violence"
    },
    {
      name: "Harrasment"
    },
    {
      name: "Suicide or self-injury"
    },
    {
      name: "Terrorism"
    },
    {
      name: "Others"
    }
  ]
  const [modal, setModal] = useState(false);
  const [basicModal, setBasicModal] = useState(false)
  const toggle = () => setModal(!modal);
  const chackbox = [
    {
      songs: 'music',
      chackbox: <input type="checkbox" />
    },
    {
      songs: 'Arts',
      chackbox: <input type="checkbox" />
    },
    {
      songs: 'Weddings',
      chackbox: <input type="checkbox" />
    },
    {
      songs: 'Food & Recipe',
      chackbox: <input type="checkbox" />
    }
  ]

  const numFormatter = (num) => {
    // eslint-disable-next-line prefer-template
    if (num > 999 && num < 1000000) {
      return `${parseInt(num / 1000)} K`
    } else if (num > 999999) {
      return `${parseInt(num / 1000000)} M`
    } else if (num < 900) {
      return `${num}`
    }
  }
  const Shotzdata = [
    {
      gridImg: Shotzs,
      icon: <Eye size={18} />,
      num: '12000',
      timeline: 'Hide',
      post: 'Edit',
      link: 'Copy Link ',
      delete: 'Delete',
      share: 'Share',
      download: 'Download',
      archive: 'Add to Achive',
      songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
      icon1: <Music size={25} color='#FFFFFF' />,
      num1: '1000',
      num2: '30000',
      num3: '23000'

    },
    {
      gridImg: Shotzs,
      icon: <Eye size={18} />,
      num: '14000',
      timeline: 'Hide',
      post: 'Edit',
      link: 'Copy Link ',
      delete: 'Delete',
      share: 'Share',
      download: 'Download',
      archive: 'Add to Achive',
      num1: '20000',
      num2: '33000',
      num3: '28000'
    },
    {
      gridImg: Shotzs,
      icon: <Eye size={18} />,
      num: '13000',
      timeline: 'Hide',
      post: 'Edit',
      link: 'Copy Link ',
      delete: 'Delete',
      share: 'Share',
      download: 'Download',
      archive: 'Add to Achive',
      songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
      icon1: <Music size={25} />,
      num1: '20000',
      num2: '34000',
      num3: '22000'
    },
    {
      gridImg: Shotzs,
      icon: <Eye size={18} />,
      num: '20000',
      timeline: 'Hide',
      post: 'Edit',
      link: 'Copy Link ',
      delete: 'Delete',
      share: 'Share',
      download: 'Download',
      archive: 'Add to Achive',
      num1: '2400',
      num2: '3900',
      num3: '2600'
    }

  ]


  return (
    <div>

      <Card className='p-2 border-0'>
        <Row className='d-flex justify-content-around align-items-center'>
          <Col sm-6>
            <div className='d-flex mt-2 ml-2 align-items-center'>
              <p className='Shotztabstext'>Showing best results for</p>
              <p className='font-weight-bold'>“Traditional Wedding ”</p>
            </div>
          </Col>
          <Col sm-6>
            <div className='d-flex justify-content-end p-2'>
              {/* -------------------- Filter for Mobile-------------------- */}
              <div className='rem-web-view'>
                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                  <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                    <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                  </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
              {/* --------------------Filter for Desktop-------------------- */}
              <div className='rem-mob-view ml-2'>
                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                  <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                    <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                      <Filter size={18} className='mr-2' />
                      Filters
                    </Button>
                  </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='AllTagsThis'>

          {
            Shotzdata.map((data) => {
              return (
                <Col xl='3' className=''>
                  <div className=''>
                    <div className="media-images-blk shotz-album-block  align-items-center mt-1 p-2">
                      <div className="main-shotz-blk-list">
                        <div>
                          <img className="gallery-img img-gradient rounded" src={data.gridImg} alt="Shotz" />
                        </div>
                        <div className="text-gallery d-flex justify-content-center">
                          <div className="top-gallery-cont align-items-center">
                            <h5 className='text-white'><Eye color='#FFFFFF' size={20} /> {numFormatter(data.num)}</h5>
                            <div className='gallery-img-sublink-this-bizpage'>
                              <UncontrolledButtonDropdown className=''>
                                <DropdownToggle color='flat' >
                                  <MoreVertical color='white' className='' size={20} />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                  <DropdownItem onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                  <DropdownItem divider></DropdownItem>
                                  <DropdownItem ><Eye size={13} />&nbsp;View user</DropdownItem>
                                  <DropdownItem divider></DropdownItem>
                                  <DropdownItem ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                  <DropdownItem divider></DropdownItem>
                                  <DropdownItem onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </div>
                          </div>
                          <div className="center-gallery-cont">
                            <div onClick={toggleShotzCarouselModal}>
                              <img src="assets/images/shotz-icon.png" className='shotzModel' alt='ShotzIcon' />
                            </div>
                          </div>
                          <div className="Music-gallery-cont mx-2 py-3">
                            <p className='shotz-text'><Music size={20} /></p>&nbsp;&nbsp;
                            <h5 className='text-white mr-2'>{data.songs}</h5>
                          </div>
                          <div className="bottom-gallery-cont align-items-center">
                            <div className='shotz-text'><img src={LikeBlack} height={18} alt='like' /> {numFormatter(data.num1)}</div>
                            <div className='shotz-text'><MessageCircle size={18} /> {numFormatter(data.num2)}</div>
                            <div className='shotz-text'><Send size={18} />  {numFormatter(data.num3)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Card>
      <MediaModal />
      <ShareEventModal
        isOpen={shareOpen}
        updateOpen={toggleShareOpen}
      />
      <div className='Review_Report_modal'>
        {
          reportOpen !== false &&
          <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
              <h3>Report a Shotz</h3>
            </ModalHeader>
            <ModalBody>
              <div className='Modal_Body_Section'>
                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
              </div>
              {
                ReportDataInfo.map((data) => (
                  <div className='d-flex justify-content-between mt-4 Report_Section'>
                    <h4>{data.name}</h4>
                    <div>
                      <Input
                        name="radio1"
                        type="radio"
                        className="Check"
                      />
                      {' '}
                    </div>
                  </div>
                ))
              }
              <div className='d-flex justify-content-center mt-3'>
                <Input
                  id="ReportName"
                  name="ReportName"
                  placeholder="Write a comment..."
                  type="Report"
                />
              </div>
              <hr className='w-100 text-muted' />
              <div className='text-center'>
                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
              </div>
            </ModalBody>

          </Modal>
        }

      </div>
      <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
        <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Shotz
        </b>
        </ModalHeader>
        {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
        <ModalBody>
          <div>
            <div className='d-flex justify-content-between'>
              <h4>Withim (Km)</h4>
              <h4>8821 Km</h4>
            </div>
            <div className='d-flex justify-content-between mt-2'>
              <div className='w-100'>
                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                  <MatSlider
                    aria-label="Volume"
                    defaultValue={100}
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                      '& .MuiSlider-track': {
                        border: 'none',
                      },
                      '& .MuiSlider-thumb': {
                        width: 20,
                        height: 20,
                        backgroundColor: '#81C14B',
                        '&:before': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none',
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
              <div className='d-flex align-items-center ml-3' role='button'>
                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
              </div>
            </div>
            <div className='border-top mt-2'>
              <h4 className='mt-3'>Choose locations</h4>
              <ChipBoxLocation />
            </div>
            {/* <div className=''>
                            <h4 className='mt-3'>Choose Interest</h4>
                            <ChipBox />
                        </div> */}
            <div className='d-flex justify-content-center mt-3 '>
              <Button color='primary' outline>CLEAR ALL</Button>
              <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
            </div>
          </div>

        </ModalBody>
      </Modal>
      {/*----------------- Shotz Carousel Modal ----------------- */}
      {
        shotzCarouselModal !== false &&
        <Modal isOpen={shotzCarouselModal} toggle={toggleShotzCarouselModal} className='modal-dialog-centered modal-xl'>
          <XCircle size={30} className='close-icon' onClick={toggleShotzCarouselModal} />
          <div className='shotz-border'>
            <ShotzModal />
          </div>
          <div className='shotz-border'>
            <ShotzModal />
          </div>
          <div className='shotz-border'>
            <ShotzModal />
          </div>
          <div>
            <ShotzModal />
          </div>
        </Modal>

      }
    </div>
  )
}

export default Shotz