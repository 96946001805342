import React from 'react'
import { CardText } from 'reactstrap'
import LeftquotationIcon from '../../../../NewComponents/IMG/LeftquotationIcon.svg'
import RightquotationIcon from '../../../../NewComponents/IMG/RightquotationIcon.svg'

const Alert = () => {
    return (
        <>
            <CardText className='ml-2'>Mild</CardText>

            <div className="pvt-event-cont-blk text-dark m-2" style={{ background: '#FFD600', fontWeight: '700' }} >
                <div className="pvt-eventcont" style={{ background: '#FF822E' }} >#Mild</div>
                <img src={LeftquotationIcon} alt="icon" />
                <div>
                    <div className='px-4'>Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho</div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                </div>
            </div>

            <CardText className='ml-2'>Moderate</CardText>

            <div className="pvt-event-cont-blk text-dark m-2" style={{ background: '#FF822E', fontWeight: '700' }} >
                <div className="pvt-eventcont" style={{ background: '#FFD600', color: "#000000" }} >#Moderate</div>
                <img src={LeftquotationIcon} alt="icon" />
                <div>
                    <div className='px-4'>Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho</div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                </div>
            </div>

            <CardText className='ml-2'>Severe</CardText>

            <div className="pvt-event-cont-blk text-white m-2" style={{ background: '#FF1010', fontWeight: '700' }} >
                <div className="pvt-eventcont" style={{ background: '#FF822E' }} >#Severe</div>
                <img src={LeftquotationIcon} alt="icon" />
                <div>
                    <div className='px-4'>Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho</div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Alert