import React from 'react'
import { Fragment } from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import GroupMenu from './GroupMenu';
import { AlertCircle, Bookmark, Globe, Link2, Lock, User } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { loadAllInvitationGroup } from '../../Services/Actions/Group/getAllGroupMainPageAction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';



const InviteGroup = () => {

    let member = 0
    const dispatch = useDispatch()
    const { getGroupInvitation } = useSelector((state) => {return state.getAllGroupInvitationData});
    useEffect(() => {
        dispatch(loadAllInvitationGroup())
    },[])
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    //================== main_return_function ===================//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="group-heading"><h2>Groups</h2></div>
                    <GroupMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="group-single-list-block">
                            <Row>
                                {
                                    getGroupInvitation.data.successResult.data.rows.map((data, index) => (
                                        <Col md="3">
                                            <Card className="group-slide-box">
                                                <div className="gallery-img-sublink">
                                                    <Link to="#" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></Link>
                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                        <ul>
                                                            <li>
                                                                <Link to="#"><User size={15} color="#4D4D4D" /> View Profile</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><AlertCircle size={15} color="#4D4D4D" /> Learn More</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>                                          


                                                <NavLink to={`/GroupDetail/${data.id}/${data.groupName}/${member}/${index}`}>
                                                    <img alt='' src={data.coverImage} className="img-fluid group-img" />
                                                </NavLink>
                                                <div className="group-content">
                                                    <h4>{data.name}</h4>
                                                    {data.Type === "People" ?
                                                        <div className="Suggestmember-blk">
                                                            <span><Globe style={{marginTop:'-3px'}} color='#4D4D4D' size={12} /> People</span> • 
                                                            <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>&nbsp;Be the first</p>
                                                            }
                                                        </span>
                                                        </div> :
                                                        <div className="Suggestmember-blk">
                                                            <span><Lock style={{marginTop:'-3px'}} color='#4D4D4D' size={12} /> Group</span> •
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>&nbsp;Be the first</p>
                                                            }
                                                        </div>
                                                    }
                                                    <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                        <p>{data.paragraph}</p>
                                                    </div>
                                                    <div className="group-buttons-blk mt-3">
                                                    <Button className="Invite_Join_Button" color='primary'>
                                               {data.status === 'pending' ? 'Follow'  :'UnFollow'} 
                                                </Button>
                                            <Button className="Invite_Decline_Button ml-2" outline >Decline</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))
                                }

                            </Row>

                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}

export default InviteGroup