import React from 'react'
import { Button, Modal } from 'reactstrap'

const MsgMeeUserModal = ({ modal, toggle }) => {
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered " >
            <div className='w-100 d-flex flex-column align-items-center p-5'>
                <h5 className='Login000_500'>You are already connected with us through MsgMee, set up your profile to stay up to date.</h5>
                <div className="MsgMeeUserModal w-100 d-flex align-items-center justify-content-between mt-5 gap-2">
                    <Button className="w-100" outline color="primary" >Go back</Button>
                    <Button className="w-100" color="primary" >Setup your profile</Button>
                </div>
            </div>
        </Modal>
    )
}

export default MsgMeeUserModal
