import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Book, BookOpen, Truck } from "react-feather";
import { Modal, Table } from "reactstrap";
import Pen1 from "../Img/Pen1.png";
import { Checkbox } from "@mui/material";
import { getAllAdminInterestAction, getAllUserInterestAction } from "../../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import {
  addInterests,
  loadAllInterests,
} from "../../../Services/Actions/UserProfile/getAllInterestsAction";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { SweetErrorAlert } from "../../group-components/AllGroupsSections/SweetErrorAlert";
import { sweetAlertConfirmation } from "../../group-components/AllGroupsSections/SweetAlert";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import axios from "axios";
import { min } from "moment/moment";

const MyInterest = (props) => {
  const dispatch = useDispatch();
 const [interestSearch, setInterestsSearch] = useState("")
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const { allInterests } = useSelector((state) => state.getAllInterestsData);

  const getAllUserInterestSuccess = useSelector((state) => {
    return state.getAllUserInterestData;
  });

  const {
    loading: loadingUserInterest,
    error: errorUserInterest,
    result: userInterest,
  } = getAllUserInterestSuccess;

  const postAllUserInterestSuccess = useSelector((state) => {
    return state.postAllUserInterestData;
  });

  
  const {
    loading: loadingPostInterest,
    error: errorPostInterest,
    result: PostInterest,
  } = postAllUserInterestSuccess;

  const [interestOpen, setInterestOpen] = useState(false);
  const [friendsId, setFriendsId] = useState([]);
  const [showError, setShowError] = useState('');
  const [successResult,setSuccessResult]=useState('');

  useEffect(() => {
    dispatch(getAllUserInterestAction(props.userId));
  }, []);

  const saveInterest = async () => {

    if(friendsId.length>=3){
      const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/addInterests`,
                    {userId:user.id,interestIds: friendsId},
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                if(response.data.data.successResult){
                  dispatch(loadProfileByUserId(props.userId));
                  sweetAlertConfirmation("Your Interests updated successfully");
                  setInterestOpen((preState) => !preState);
                }else{
                  SweetErrorAlert(response.data.data.errorResult);
                }
                
            } else {
              showError('no user found');
            }
        } catch (error) {
          showError(error.message);
        }
      };
      await fetchData();
    }else{
      sweetAlertConfirmation("please choose minimum 3 interests")
    }
    
  };

  const toggleInterest = () => {
    let hobbies = userInterest.map((userHobby) => {
      return userHobby.id;
    });
    dispatch(loadAllInterests(""));
    setFriendsId(hobbies);
    setInterestOpen((preState) => !preState);
    setInterestsSearch("")
  };
  const inviteFriends = (e) => {
    let updatedList = [...friendsId];
    if (e.target.checked) {
      updatedList = [...friendsId, e.target.value];
    } else {
      updatedList.splice(friendsId.indexOf(e.target.value), 1);
    }
    setFriendsId(updatedList);
    console.log(updatedList);
  };

  const handleSearch = (e) => {
    setInterestsSearch(e.target.value);
    dispatch(loadAllInterests(interestSearch))
  };

  return (
    <Fragment>
      <div className="about-profile-box-blk">
        <div className="card-title">
          <h3>My Interests</h3>
          {props.type !== "viewer" && (
            <div className="settings">
            {userProfileByUserId?.interest && userProfileByUserId?.interest.length > 0 && (
              <div onClick={toggleInterest} className="setting-btn" style={{ cursor: "pointer" }}>
                <img src={Pen1} width={12} className="img-fluid" alt="penIcon" />
              </div>
            )}
            </div>
          )}
          
        </div>
        <div className="card-block-box">
          <ul className="Interestes-blk">
          
            {userProfileByUserId?.interest?.length > 0 ? userProfileByUserId?.interest?.map((data) => (
              <li>
                <img
                  src={`${process.env.REACT_APP_IMAGEURL}${data?.icon_url}`}
                  style={{ marginRight: "6px", width: "24px" }}
                  alt="iconUrl"
                />
                {data?.name}
              </li>
            )):(
              <>
              {props.type!=="viewer" && (
              <div className="mx-3 py-4 Profession_Modal_section">
            <p onClick={toggleInterest}>+ Add your interest</p>
           </div>
           )}
              </>


            )}
          
          </ul>
        </div>
      </div>
      <div className="Modal_section_Basic_info_Interestes">
        {interestOpen !== false && (
          <Modal
            scrollable
            isOpen={interestOpen}
            toggle={toggleInterest}
            className="modal-dialog-centered"
          >
            <div className="Profession_Modal_section AboutModal_Main_section p-3 py-3 ">
            <h4>Choose your Interests</h4>
              <p onClick={saveInterest}>
                Save
              </p>
             
            </div>
            
            <div className="Modal_scroll_box mb-4">
              <div className="AboutModal_Main_section p-3 py-3">
                <div className="profile-search-blk w-100 ">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Find interest..."
                    data-bs-toggle="modal"
                    data-bs-target="#peopleSearch"
                    value={interestSearch}
                    onChange={handleSearch}
                  />
                </div>
              </div>

              {allInterests.rows?.map((data) => (
                <div className="Profession_Modal_Option AboutModal_Main_section p-3 py-4 ">
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        src={`${data?.selectedIconUrl}`}
                        alt="iconUrl"
                        style={{ width: "28px" }}
                      />
                    </div>
                    <h4 className="ml-3  Basic_Info">{data?.name}</h4>
                  </div>
                  {/* <Checkbox {...label} defaultChecked /> */}

                  <input
                    value={data?.id}
                    checked={friendsId.includes(data.id)}
                    onChange={(e) => {
                      inviteFriends(e);
                    }}
                    type="checkbox"
                    className="customcheckboxForFilter"
                    id={data.id}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))}
            </div>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};

export default MyInterest;
