
import { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import user2 from '../../../NewComponents/IMG/userview.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { trendingGoliveGridData } from '../DummyArray'
import ReportModal from './ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import LiveGif from '../../../NewComponents/IMG/LiveGif.gif'


const GridGolive = () => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    {/* --------------------------------------------------------- Main return function --------------------------------------------------------- */ }
    return (
        <>
            <Row className='p-2'>

                {/* --------------------------------------------------------- Dynamic card data rendering --------------------------------------------------------- */}
                {
                    trendingGoliveGridData.map((data, index) => {
                        return (
                            <>
                                <Col xl='3' lg='4' md='6' className='p-1' key={index}>
                                    <div className='border roundedThisBorder on-hover-bg'>
                                        <div className='d-flex p-2'>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                                    Trending #{data.trendingNumber}
                                                </Badge>
                                            </NavLink>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                                    Top #{data.topsNumber}
                                                </Badge>
                                            </NavLink>
                                        </div>


                                        {/* <div className="CardbodyThis m-2 ">
                                            <div className='d-flex align-items-center justify-content-center' >
                                            <div className="livestatus-con topleft"><span></span> Live</div>
                                            <div className='CountLeft align-items-center'>
                                                <Eye color='white' size={18} />
                                                <p className='text-white ml-1'>{data.viewerCount}</p>
                                            </div>
                                            <div className='topright'>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                                        <MoreHorizontal color='white' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            </div>
                                            <img src={data.Golive} alt='GoLiveImg' height={240} className='w-100 rounded-lg' style={{ borderRadius: '10px' }} />
                                            <div className="bottomleft">
                                                <img src='https://picsum.photos/318/180' width={55} height={55} className='rounded-circle ml-2 p-1' alt='img' style={{ borderRadius: '10px' }} />
                                                <div className='ml-1 p-1 mt-1'>
                                                    <p>Demon slayer</p>
                                                    <p className='mt-1'>#Entertainment</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* /GoLiveDetail */}
                                        <NavLink to='' >
                                            <div className="CardbodyThis m-2 ">
                                                <div style={{ position: 'relative' }} className='' >
                                                    <div className='d-flex' >
                                                        <img src={data.Golive} alt='GoLiveImg' height={240} className='w-100 rounded-lg' style={{ borderRadius: '10px' }} />
                                                    </div>

                                                    <div style={{ position: 'absolute', top: '0', borderRadius: '10px 10px 0 0' }} className='text-white w-100 simple-linear d-flex align-items-center px-2 py-2' >
                                                        <span className="GoLive_section d-flex align-items-center" > <img width={20} src={LiveGif} alt="LiveGif1" /> </span>
                                                        <div className="livestatus-con "><span></span> Live</div>
                                                        <div className='d-flex'>
                                                            <Eye color='white' size={18} />
                                                            <p className='text-white ml-1'>{data.viewerCount}</p>
                                                        </div>
                                                        <div className='ml-auto'>
                                                            <UncontrolledButtonDropdown>
                                                                <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                                                    <MoreHorizontal color='white' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Golive</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} > <Eye size={13} />&nbsp;&nbsp;View Creator's profile</NavLink></DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bottomleft">
                                                    <img src='https://picsum.photos/318/180' width={55} height={55} className='rounded-circle ml-2 p-1 mb-2' alt='img' style={{ borderRadius: '10px' }} />
                                                    <div className='ml-1 p-1 mt-1'>
                                                        <NavLink to='/MyProfile' style={{ color: 'white' }} ><p className='user-profile' >Demon slayer</p></NavLink>
                                                        <p className='mt-1'>#Entertainment</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </NavLink>


                                    </div>
                                </Col>
                            </>
                        )
                    })
                }
            </Row>

            {/* --------------------------------------------------------- Modal component start --------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* --------------------------------------------------------- Modal component end --------------------------------------------------------- */}
        </>
    )
}

export default GridGolive