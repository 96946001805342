import React, { useEffect, useRef, useState } from 'react'
import { ChevronsRight, Maximize2, Minimize2, MoreVertical, Pause, Play, PlayCircle, Send, X } from 'react-feather';
import { Button, Card, CardBody, CardFooter, CardImg, CardImgOverlay, Col, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap';
import UserPro from '../../../../../NewComponents/IMG/Adspreview.svg'
import UserAdPro from '../../../../../NewComponents/IMG/user2.png'
import group5 from '../../../../../NewComponents/IMG/group5.png'
import group2 from '../../../../../NewComponents/IMG/group2.png'
import Story from '../../../../../NewComponents/IMG/Story.png'
import Svs from '../../../../../NewComponents/IMG/Svs.png'
import Framev from '../../../../../NewComponents/IMG/Framev.png'
import Shotzs1 from '../../../../../NewComponents/IMG/Shotzs1.png'
import Res from '../../../../../NewComponents/IMG/Res.png'
import Rec1 from '../../../../../NewComponents/IMG/Rec1.png'
import Market from '../../../../../NewComponents/IMG/Market.png'
import Market2 from '../../../../../NewComponents/IMG/Market2.png'
import Market1 from '../../../../../NewComponents/IMG/Market1.png'
import Groups from '../../../../../NewComponents/IMG/Groups.png'
import Group6 from '../../../../../NewComponents/IMG/Group6.png'
import Ads from '../../../../../NewComponents/IMG/Ads.png'

const VideoAdpreview = ({ heading, imageDataUrl, discriptions, imageGroupUrl, imageStoryUrl, imageBizUrl, imageMarketUrl, imagePodcastUrl, imageEventUrl, subHeading }) => {
    console.log(subHeading,'===============>subHeading')
    const [accord, setAccord] = useState(1);
    const [centeredModal, setCenteredModal] = useState(false)
    useEffect(() => {
        console.log("========>preview", imageDataUrl)
    }, [imageDataUrl])
    useEffect(() => {
        console.log("========>preview", imageGroupUrl)
    }, [imageGroupUrl, imageStoryUrl, imageBizUrl, imageMarketUrl, imagePodcastUrl, imageEventUrl])
    const [playVideo, setplayVideo] = useState(false)
    const [playFeedVideo, setplayFeedVideo] = useState(false)
    const [playGroupVideo, setplayGroupVideo] = useState(false)
    const [playGroupsVideo, setplayGroupsVideo] = useState(false)
    const [playShotzVideo, setplayShotzVideo] = useState(false)
    const [playBizVideo, setplayBizVideo] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [isOpenA, setIsOpenA] = useState(false)
    const [isSlow, setIsSlow] = useState(false)
    const videoRef = useRef(null)
    const FeedvideoRef = useRef(null)
    const GroupvideoRef = useRef(null)
    const GroupsvideoRef = useRef(null)
    const ShotzvideoRef = useRef(null)
    const BizvideoRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isFeedPlay, setIsFeedPlay] = useState(false)
    const [isGroupPlay, setIsGroupPlay] = useState(false)
    const [isShotzPlay, setIsShotzPlay] = useState(false)
    const [isBizPlay, setIsBizPlay] = useState(false)
    const [isGroup, setIsGroup] = useState(false)

    const togglePlay = () => {
        const video = videoRef.current;

        if (video) {
            if (isPlaying) {
                video.pause();
            } else {
                video.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const togglePlayFeed = () => {
        const videoFeed = FeedvideoRef.current;

        if (videoFeed) {
            if (isFeedPlay) {
                videoFeed.pause();
            } else {
                videoFeed.play();
            }
            setIsFeedPlay(!isFeedPlay);
        }
    };
    // ==================group play
    const togglePlayGroup = () => {
        const videoGroup = GroupvideoRef.current;

        if (videoGroup) {
            if (isGroupPlay) {
                videoGroup.pause();
            } else {
                videoGroup.play();
            }
            setIsGroupPlay(!isGroupPlay);
        }
    };
    const toggleGroup = () => {
        const videoGroups = GroupsvideoRef.current;

        if (videoGroups) {
            if (isGroup) {
                videoGroups.pause();
            } else {
                videoGroups.play();
            }
            setIsGroup(!isGroup);
        }
    };
    // =====================shotz======
    const togglePlayShotz = () => {
        const videoShotz = ShotzvideoRef.current;

        if (videoShotz) {
            if (isShotzPlay) {
                videoShotz.pause();
            } else {
                videoShotz.play();
            }
            setIsShotzPlay(!isShotzPlay);
        }
    };
    // =====================togglePlayBizpage======
    const togglePlayBizpage = () => {
        const videoBiz = BizvideoRef.current;

        if (videoBiz) {
            if (isBizPlay) {
                videoBiz.pause();
            } else {
                videoBiz.play();
            }
            setIsBizPlay(!isBizPlay);
        }
    };
    const toggleplayVideo = () => {
        setplayVideo((prevState) => !prevState)
        setplayFeedVideo((prevState) => !prevState)
        setplayGroupVideo((prevState) => !prevState)
        setplayGroupsVideo((prevState) => !prevState)
        setplayShotzVideo((prevState) => !prevState)
        setplayBizVideo((prevState) => !prevState)

    }
    const toggleIsOpenA = () => {
        // Check if this function is actually slow
        console.time('toggleIsOpenA')

        setIsOpenA(!isOpenA)
        setIsSlow(true)

        setTimeout(() => {
            setIsSlow(false)
            console.timeEnd('toggleIsOpenA')
        }, 1500)
    }
    const handleMouseEnter = () => {
        setIsHovered(true)
    }
    const Previewmodal = () => {
      
        return (
            <>
                <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-xl modal-dialog-centered'>
                    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Advanced preview</ModalHeader>
                    <ModalBody>
                        <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                            {/* Feed Card */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="post-details">
                                                            <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px', top: '50%', left: '50%' }}>
                                                                    {isFeedPlay ? <Pause onClick={togglePlayFeed} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                </div>
                                                                {imageGroupUrl ? <video width="100%" height="150" onClick={togglePlayFeed} ref={FeedvideoRef}
                                                                    autoPlay
                                                                    style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                                    <source
                                                                        src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                        type="video/mp4"
                                                                    />
                                                                </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' />}
                                                                <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-3px' }}>
                                                                    <h4 style={{
                                                                        fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}>{heading}</h4>
                                                                    {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '26px',
                                                                        left: '1px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '100%',
                                                                    }}>
                                                                        {/* <p style={{
                                                                            fontSize: '10px',
                                                                            marginLeft: '3px',
                                                                            color: 'white',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}>{subHeading}</p> */}
                                                                        </div>
                                                                </div>
                                                                <div><p style={{
                                                                    fontSize: '10px', textWrap: 'nowrap', marginLeft: '3px', overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>{subHeading}</p></div>
                                                            </div>
                                                            <div className="detail-box">
                                                                <h4 className="small-preview-heading" style={{
                                                                    fontSize: '10px',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {discriptions && discriptions.length > 40 ? discriptions.substring(0, 40) + '...' : discriptions}
                                                                </h4>
                                                            </div>
                                                            <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*====================== Podcast Card================= */}
                                            {/*================== Group Card=================*/}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded  py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1" style={{ fontSize: '12px' }}><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    {/* <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div> */}
                                                                    <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px', top: '48%', left: '26%' }}>
                                                                        {isGroup ? <Pause onClick={toggleGroup} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={toggleGroup} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                    </div>

                                                                    {imageGroupUrl ? <video width="100%" ref={GroupsvideoRef}
                                                                        autoPlay
                                                                        onClick={toggleGroup} height="65%" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                                        <source
                                                                            src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                            type="video/mp4" className='rounded-2'
                                                                        />
                                                                    </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' width='100%' className='rounded-2' />}
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <div style={{ position: 'absolute', top: '48%', left: '74.3%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                                        {isGroupPlay ? <Pause onClick={togglePlayGroup} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayGroup} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                    </div>
                                                                    {imageGroupUrl ? <video width="100%" ref={GroupvideoRef}
                                                                        autoPlay
                                                                        onClick={togglePlayGroup} height="65%" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                                        <source
                                                                            src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                            type="video/mp4" className='rounded-2'
                                                                        />
                                                                    </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' width="100%" className='rounded-2' />}
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all Groups <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=====================Biz Page Card=============== */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <div style={{ position: 'absolute', top: '48%', left: '26%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                                        {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                    </div>
                                                                    {imageGroupUrl ? <video width="100%" height="65%" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} autoPlay>
                                                                        <source
                                                                            src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                            type="video/mp4" className='rounded-2'
                                                                        />
                                                                    </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' width="100%" className='rounded-2' />}
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <h4 className="small-preview-heading" style={{
                                                                                fontSize: '10px',
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>
                                                                                {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                            </h4>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <div style={{ position: 'absolute', top: '46.6%', left: '74.3%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                                        {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                    </div>
                                                                    {imageGroupUrl ? <video width="100%" height="65%" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} autoPlay>
                                                                        <source
                                                                            src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                            type="video/mp4" className='rounded-2'
                                                                        />
                                                                    </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' width="100%" className='rounded-2' />}
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <h4 className="small-preview-heading" style={{
                                                                                fontSize: '10px',
                                                                                whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>
                                                                                {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                            </h4>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all BizPages <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert mt-3'>
                                            {/*=================Event Card end================= */}
                                            {/*=====================Biz Page Card end=============== */}
                                        </Row>
                                        <Row className='rowForrevert mt-4'>
                                            {/*======================Stories Card================== */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Shotz</h3>
                                                <div className=" m-1">
                                                    {/* className="border rounded py-4 m-1" */}
                                                    <Card inverse className=''>
                                                        <div className='small-bg-mains'>
                                                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                                                <div className="post-wrapper col-grid-box">
                                                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                                        <div className="profile">
                                                                            <div className="media d-flex mb-1" >
                                                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                                </a>
                                                                                <div className="media-body ml-2">
                                                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>florida roxin</h6>
                                                                                    <h6><span className="sponsored-text" style={{ color: 'black' }}>Sponsored</span></h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                                                <MoreVertical size={15} style={{ color: 'black' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post-details">
                                                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                            <div style={{ position: 'absolute', top: '46%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                                                {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                            </div>
                                                                            {imageGroupUrl ? <video width="100%" height="400" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={videoRef}
                                                                                autoPlay
                                                                                onClick={togglePlay}>
                                                                                <source height='350' width='100%'
                                                                                    src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video> : <img style={{ backgroundColor: 'c4c4c4' }} alt='' height='350' width='100%' />}
                                                                        </div>
                                                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                                            <h4 style={{
                                                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>{heading}</h4>

                                                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '26px',
                                                                                left: '1px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                maxWidth: '100%',
                                                                            }}>
                                                                                <p style={{
                                                                                    fontSize: '10px',
                                                                                    marginLeft: '3px',
                                                                                    color: 'white',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                }}>{subHeading}</p></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*==================Shotz=================== */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Story</h3>
                                                <div className=" m-1">
                                                    {/* className="border rounded py-4 m-1" */}
                                                    <Card inverse className=''>
                                                        <div className='small-bg-mains'>
                                                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                                                <div className="post-wrapper col-grid-box">
                                                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                                        <div className="profile">
                                                                            <div className="media d-flex mb-1" >
                                                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                                </a>
                                                                                <div className="media-body ml-2">
                                                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>florida roxin</h6>
                                                                                    <h6><span className="sponsored-text" style={{ color: 'black' }}>Sponsored</span></h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                                                <MoreVertical size={15} style={{ color: 'black' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post-details">
                                                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                            <div style={{ position: 'absolute', top: '46%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                                                {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                                            </div>
                                                                            {imageGroupUrl ? <video width="100%" height="400" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={videoRef}
                                                                                autoPlay
                                                                                onClick={togglePlay}>
                                                                                <source height='350' width='100%'
                                                                                    src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video> : <img style={{ backgroundColor: 'c4c4c4' }} alt='' height='350' width='100%' />}
                                                                        </div>
                                                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                                         
                                                                            <h4 style={{
                                                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            
                                                                            }}>{heading}</h4>
                                                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '26px',
                                                                                left: '1px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                maxWidth: '100%',
                                                                            }}>
                                                                                <p style={{
                                                                                    fontSize: '10px',
                                                                                    marginLeft: '3px',
                                                                                    color: 'white',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                }}>{subHeading}</p></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
    return (
        <>
            <div className="col-lg-12">
                <Previewmodal />
                <Row className='rowForrevert'>
                    <Col xl='6'>
                        <div className="">
                            <img src="/assets/images/adIcon/preview-logo.png" alt="logo" />
                        </div>
                    </Col>
                    <Col xl='6'>
                        <div className='d-flex justify-content-end'>
                            <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                                {centeredModal ? <Minimize2 size={15} /> : <Maximize2 size={15} />}
                                <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview'}</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/* <div className="mobile-frame-video p-1">
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="d-flex m-1">
                            <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                            <div className="ml-1">
                                <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                            </div>
                        </div>
                        <div className="ml-1">
                            <MoreVertical size={10} className='m-0' />
                        </div>
                    </div>
                    <div className="video-container">
                        <img
                            src="https://via.placeholder.com/300x150.png"
                            alt="Placeholder"
                            className="video-thumbnail"
                        />
                        <div className="play-button">
                            <PlayCircle size={40} />
                        </div>
                    </div>
                    <div className="p-1 w-100">
                        <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                            <b>
                                An adv is the promotion of a product, brand
                            </b>
                        </h6>
                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                            <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                            <Send size={10} />
                        </div>
                    </div>
                </div> */}
                {/* ======================video card================= */}
                <div className="row">
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(1) }}>
                        <h3 className='p-heading'>Feeds</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey" style={{ fontSize: '9px' }}>Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-font-color iw-14"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg> */}
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details" >
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: '49.6%', left: '50.3%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                {isFeedPlay ? <Pause onClick={togglePlayFeed} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayFeed} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                            </div>
                                            {imageGroupUrl ? <video width="100%" height="150" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={FeedvideoRef}
                                                // autoPlay
                                                onClick={togglePlayFeed}>
                                                <source
                                                    src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                    type="video/mp4"
                                                />
                                            </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' />}
                                            <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-3px' }}>
                                                <h4 style={{
                                                    fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>{heading}</h4>
                                                {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '26px',
                                                    left: '1px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '100%',
                                                }}>
                                                    <p style={{
                                                        fontSize: '10px',
                                                        marginLeft: '3px',
                                                        color: 'white',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}>{subHeading}</p></div>
                                            </div>
                                        </div>
                                        <div className="detail-box">
                                            <h4 className="small-preview-heading" style={{
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                            </h4>
                                        </div>
                                        <div className="Why-this-ad d-flex justify-content-between m-1">
                                            <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                            <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <img src={s1} alt="" /> */}
                    </div>
                    {/* ================add new card end============= */}
                    {/* ====== second new card ===== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Groups</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey" style={{ fontSize: '9px' }}>Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Suggested Groups</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100" style={{ position: 'relative' }}>
                                                <div className="">
                                                    <div style={{ position: 'absolute', top: '44.6%', left: '50.3%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                        {isGroupPlay ? <Pause onClick={togglePlayGroup} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayGroup} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                    </div>
                                                    {/* small-preview-img*/}
                                                    {/* <img src={group2} alt='' /> */}
                                                    {imageGroupUrl ? <video width="100%" height="150" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={GroupvideoRef}
                                                        // autoPlay
                                                        onClick={togglePlayGroup} >
                                                        <source
                                                            src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                            type="video/mp4" className='rounded-2'
                                                        />
                                                    </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' className='rounded-2' />}
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>
                                                        <p className='small-preview-small preview-know' style={{ fontSize: '8px' }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="small-preview-more mt-1">
                                            <h6>visit all groups<ChevronsRight size={15} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ========  end ======== */}
                    {/* ====== second new Shotz ===== */}
                    {/*  onClick={() => { setAccord(2) }} */}
                    <div className="col-lg-6 small-padding-fake">
                        <h3 className='p-heading'>Shotz</h3>
                        <div className='small-bg-mains'>
                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                    <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                {isShotzPlay ? <Pause onClick={togglePlayShotz} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayShotz} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                            </div>
                                            {imageGroupUrl ? <video width="100%" height="300" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={ShotzvideoRef}
                                                // autoPlay
                                                onClick={togglePlayShotz}>
                                                <source height='300' width='100%'
                                                    src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                    type="video/mp4"
                                                />
                                            </video> : <img style={{ backgroundColor: 'c4c4c4' }} height='300' width='100%'  alt=''/>}
                                        </div>
                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                            <h4 style={{
                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{heading}</h4>

                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '26px',
                                                left: '1px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '100%',
                                            }}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    marginLeft: '3px',
                                                    color: 'white',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>{subHeading}</p></div>
                                        </div>
                                    </div>
                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Story</h3>
                        <div className='small-bg-mains'>
                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                    <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                            </div>
                                            {imageGroupUrl ? <video width="100%" height="300" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={videoRef}
                                                // autoPlay
                                                onClick={togglePlay}>
                                                <source height='300' width='100%'
                                                    src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                    type="video/mp4"
                                                />
                                            </video> : <img style={{ backgroundColor: 'c4c4c4' }} height='300' width='100%' />}
                                            {/* src='https://web-realcom.com/assets/upload/img/video-default.png' */}
                                        </div>
                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                            <h4 style={{
                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{heading}</h4>

                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '26px',
                                                left: '1px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '100%',
                                            }}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    marginLeft: '3px',
                                                    color: 'white',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>{subHeading}</p></div>
                                        </div>
                                    </div>
                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* =====================bizpage=============== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>BizPages</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey" style={{ fontSize: '9px' }}>Sponsored</span></h6>
                                                    {/* <p className='small-preview-small preview-know' style={{ fontSize: '8px' }}>Know more</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Suggested BizPages</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100" style={{ position: 'relative' }}>
                                                <div className="">
                                                    <div style={{ position: 'absolute', top: '44.6%', left: '50.3%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                        {isBizPlay ? <Pause onClick={togglePlayBizpage} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayBizpage} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                    </div>
                                                    {/* <img src={group5} alt='' /> */}
                                                    {imageGroupUrl ? <video width="100%" height="150" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={BizvideoRef}
                                                        // autoPlay
                                                        onClick={togglePlayBizpage}>
                                                        <source
                                                            src={URL.createObjectURL(imageGroupUrl ? imageGroupUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                            type="video/mp4" className='rounded-2'
                                                        />
                                                    </video> : <img src='https://web-realcom.com/assets/upload/img/video-default.png' alt='' className='rounded-2' />}
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>
                                                        <p className='small-preview-small preview-know' style={{ fontSize: '8px' }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="small-preview-more mt-1">
                                            <h6>visit all BizPages<ChevronsRight size={15} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoAdpreview