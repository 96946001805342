import React, { Fragment, useState } from 'react'
import { AlertTriangle, BellOff, Check, Filter, MoreHorizontal, MoreVertical, Settings, Sliders, User } from 'react-feather'
import Pic from './Img/pic.svg'
import Pic1 from './Img/pic1.svg'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, UncontrolledButtonDropdown } from 'reactstrap'
// import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ReportModal from './Modals/ReportModal'
{/* ----------------------------State and function for Report Modal------------------------------------------ */ }


const NotificationView = () => {

    //  ==============================State and function for Report Modal-=========================
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    // ================ Start_Dummy_Array_Data ================//
    const NotificationData = [
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic,
            TypeOf: "Background"
        },
        {
            userName: 'Darlene Robertson',
            action: "Send you a follow Request",
            tym: "1 day ago ",
            url: Pic1,
            TypeOf: "Request"
        },
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic,
            TypeOf: "Background"
        },
        {
            userName: 'Darlene Robertson',
            action: " Send you a follow Request",
            tym: "2 day ago  ",
            url: Pic1
        },
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic1,
            TypeOf: "Background"
        },
        {
            userName: 'Darlene Robertson',
            action: "Send you a Friend Request",
            tym: "3 day ago  ",
            url: Pic1
        },
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic,
            TypeOf: "Background"
        },
        {
            userName: 'Darlene Robertson',
            action: "Send you a follow Request",
            tym: "1 day ago ",
            url: Pic1,
            TypeOf: "Request"
        },
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic,
            TypeOf: "Background"
        },
        {
            userName: 'Darlene Robertson',
            action: " Send you a follow Request",
            tym: "2 day ago  ",
            url: Pic1
        },
        {
            userName: 'Darlene Robertson',
            action: "Send you a follow Request",
            tym: "1 day ago ",
            url: Pic1,
            TypeOf: "Request"
        },
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic,
            TypeOf: "Background"
        },
        {
            userName: 'Paige Turner',
            action: "like your post ",
            tym: "1 Hrs ago ",
            url: Pic1,
            TypeOf: "Background"
        },
        {
            userName: 'Darlene Robertson',
            action: "Send you a follow Request",
            tym: "1 day ago ",
            url: Pic,
            TypeOf: "Request"
        },
    ]

    // ================ End_Dummy_Array_Data ==================//

    //========================== Main_return_Function ====================//
    return (
        <Fragment>
            <div className='Notification_Main_blk_section'>
                <div className='Notification_top_section'>
                    <h4 className='notification_seemore mt-2' style={{ color: 'black', marginLeft: '2px' }}>Notifications</h4>
                    <div className='d-flex align-items-center' >
                        <UncontrolledButtonDropdown >
                            <DropdownToggle style={{ borderRadius: '5px', height: '2rem', width: '5rem', padding: '1px' }} className='mr-1' outline color='primary' >
                                <Filter size={16} /><span>Filter</span>
                            </DropdownToggle>
                            <DropdownMenu className="DropDown_Notification_item  DropDown_Notifications">
                                <DropdownItem className="DropDown_Notification_items">
                                    All Notification
                                </DropdownItem>
                                <DropdownItem className="DropDown_Notification_items">
                                    Unread Notifications
                                </DropdownItem>
                                <DropdownItem className="DropDown_Notification_items">
                                    This week
                                </DropdownItem>
                                <DropdownItem className="DropDown_Notification_items">
                                    This Month
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                        &nbsp;&nbsp;&nbsp;&nbsp;<UncontrolledButtonDropdown >
                            <DropdownToggle className='ml-2 Notification_More_OptionMenu'>
                                <MoreHorizontal size={22} color="#808080" height='15px' width='15px' className='m-1' />
                            </DropdownToggle>
                            <DropdownMenu className="DropDown_Notification_item_moreMenu DropDown_Notifications">
                                <DropdownItem tag='a'><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    className="pr-1"
                                >
                                    <path
                                        d="M6.54453 8.16969C6.10508 8.60914 5.39492 8.60914 4.95547 8.16969L2.14191 5.35719C1.70281 4.91773 1.70281 4.20758 2.14191 3.76812C2.58137 3.32867 3.29363 3.32867 3.73203 3.76812L5.71836 5.78258L10.5805 0.95457C11.0199 0.515469 11.7301 0.515469 12.1695 0.95457C12.609 1.39402 12.609 2.10629 12.1695 2.54574L6.54453 8.16969ZM6.54453 16.0447C6.10508 16.4841 5.39492 16.4841 4.95547 16.0447L0.454484 11.5447C0.0151719 11.1052 0.0151719 10.3951 0.454484 9.95562C0.893867 9.51617 1.60613 9.51617 2.04559 9.95562L5.71836 13.6576L13.9555 5.45562C14.3949 5.01617 15.1051 5.01617 15.5445 5.45562C15.984 5.89508 15.984 6.60523 15.5445 7.04469L6.54453 16.0447Z"
                                        fill="#4D4D4D"
                                    />
                                </svg>
                                    Mark all as read</DropdownItem>
                                <DropdownItem className="DropDown_Notification_items">
                                    <Settings size={16} />&nbsp;&nbsp;&nbsp;Notification Setting
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>

                    </div>
                </div>
                <div className='Notification_TopDown_blk'>
                    <h3>New Notifications</h3>
                    <NavLink to="/Notification"><p>See all</p></NavLink>
                </div>
                <div className='Notification_Main_scroll_box'>
                    {
                        NotificationData.map((data) => (
                            <div className={data.TypeOf === "Background" ? " Notification_profile-blk  " : "Notification_profile-blk_Nxt"}>
                                <div className='d-flex align-items-start' >
                                    <img src={data.url} className=" rounded-circle" alt='biz-img' width={50} height={50} />
                                    <div className='ml-2 mt-2' >
                                        {data.userName ? <h4>{data.userName}&nbsp;<span className='Notification_Action_Blk'>{data.action}</span></h4> : null}
                                        {data.TypeOf === "Background" ? null : <Fragment>
                                            <div className="BizPage-buttons-blk mt-2 mr-5">
                                                <button color='primary' className="Notification_aCCEPECT_section" style={{ width: '6rem' }}>Accept </button>
                                                <button outline className="Notification_Fill_section ml-2 " style={{ width: '6rem' }} >Decline </button>
                                            </div>
                                        </Fragment>
                                        }
                                        <p className={data.TypeOf === "Background" ? "mt-1" : "mt-1 ml-1"}>{data.tym}</p>
                                    </div>
                                </div>
                                <div>
                                    <UncontrolledButtonDropdown >
                                        <DropdownToggle color='flat-primary' className="Notification_More_OptionMenuProfile">
                                            <MoreVertical size={20} color="#808080" />
                                        </DropdownToggle>
                                        <DropdownMenu className="DropDown_Notification_item_moreMenus" style={{ marginTop: '10px' }}>
                                            <DropdownItem className="DropDown_Notification_items">
                                                <Check size={16} />&nbsp;&nbsp;Mark as Unread
                                            </DropdownItem>
                                            <DropdownItem className="DropDown_Notification_items">
                                                <BellOff size={16} />&nbsp;&nbsp;Mute Notifications
                                            </DropdownItem>
                                            <DropdownItem className="DropDown_Notification_items">
                                                <User size={16} />&nbsp;&nbsp;View Profile
                                            </DropdownItem>
                                            <DropdownItem style={{ color: '#4D4D4D' }} onClick={toggleReport} tag='a'><AlertTriangle size={15} />&nbsp;&nbsp;Report</DropdownItem>
                                            
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>
            {/* ------------------------------------Review Report modal start--------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
        </Fragment>
    )
}

export default NotificationView