import React, { useLayoutEffect, useMemo } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AdmanagerHeaderR from "../../AdmanagerHeaderR/AdmanagerHeaderR";

// Use for snakeBar
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loadAdType } from "../../../../Services/Actions/AdManager/getAdTypeAction";
import { Button, Card, Col, Collapse, Input, Popover, PopoverBody, Row, UncontrolledTooltip } from "reactstrap";
import PreviewMoreSection from "../AdPreview/Sections/PreviewMoreSection";
import { Circle, Info } from "react-feather";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SingleImageAdScreen = (props) => {
  const types = useLocation();
  const [countMedia, setCountMedia] = useState(0);
  const [media, setMedia] = useState("");
  const [imageDataUrl, setImageDataUrl] = useState('')
  const [imageGroupUrl, setImageGroupUrl] = useState('')
  const [imageStoryUrl, setImageStoryUrl] = useState('')
  const [imageBizUrl, setImageBizUrl] = useState('')
  const [imageMarketUrl, setImageMarketUrl] = useState('')
  const [imagePodcastUrl, setImagePodcastUrl] = useState('')
  const [imageEventUrl, setImageEventUrl] = useState('')
  const { adType } = useSelector((state) => state.getTypeData);

  const adTypeData = useMemo(() => {
    return adType.find((ad) => ad.id === types.state.typeId);
  }, [adType]);
  // Snackbar Code
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ sev: "success", content: "" });
  const [loading, setLoading] = useState(false);
  const [warningOn, setwarningOff] = useState(false)
  // const[imageDataUrl, setImageDataUrl] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [mediaData, setMediaData] = useState({
    file: "postMedia/oBVuwyFSCUbUs.jpg",
    fileType: "image",
    heading: "",
    subHeading: "",
    callToActionId: "",
  });
  // const { name, value } = e.target;
  // setMediaData((prevData) => ({
  //   ...prevData,
  //   [name]: value,
  // }));

  const [adData, setAdData] = useState({
    discriptions: "",
    websiteLink: "",
    adStatus: "READY_TO_START",
    adTypesId: types.state.typeId,
    adSubTypesId: types.state.subTypeId,
    media: [],
  });

  const mediaInputsHandler = (ev) => {
    const { name, value } = ev.target;
    setMediaData({ ...mediaData, [name]: value });
  };

  //   this function is used for submit details
  const submitDetails = (e) => {
    e.preventDefault();
    if (!mediaData.heading) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Heading !" });
    } else if (!mediaData.subHeading) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Sub-Heading !" });
    } else if (!adData.discriptions) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Description !" });
    } else if (!media?.name) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Select Image !" });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("files", media);
      formData.append("uploadFor", "ads");
      axios
        .post(`${process.env.REACT_APP_IPURL}/admin/UploadFile`, formData, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data.successResult);
            mediaData.file = res.data.data.successResult[0];
            adData.media = [mediaData];
            console.log(adData);
            axios
              .post(
                `${process.env.REACT_APP_IPURL}/ads/create/adMaster/adType/subTypes/adManager`,
                adData,
                {
                  headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
                      }`,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                setLoading(false);
                setOpen(true);
                setAlert({
                  sev: "success",
                  content: "Ad Created  Successfully",
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setOpen(true);
            setAlert({
              sev: "error",
              content: `${res.data.data.errorResult}`,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const imageUpload = () => {
    document.getElementById("input_file").click();
  };
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
    setClicked(!clicked);
  }
  //===================Cancel Snackbar=================
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useLayoutEffect(() => {
    dispatch(loadAdType());
  }, []);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Choose your CTA button");
  function handleBlur(e) {
    console.log(e);
  }

  // const isButtonDisabled = mediaData.heading === '';
  const isButtonDisabled = mediaData.heading === '' || mediaData.subHeading === '' || adData.discriptions === '';
  const isNextButtonEnabled = () => {
    return media !== ""; // Enable the button if media is present
  };
  const [buttonText, setButtonText] = useState('Customize your Lead Generation Form');
  const [clicked, setClicked] = useState(false);
  const [image, setImage] = useState('')
  const handleClick = () => {
    // Change the text and set clicked to true
    setButtonText('Lead Generation Form Customize your Lead Generation Form');
    setClicked(true);
  };
  const handleChange = (e) => {
    console.log("change 2");
    const selectedFile = e.target.files[0];
    setMedia(URL.createObjectURL(selectedFile))
    console.log("========>",)
    if (selectedFile) {
      if (selectedFile.type.slice(0, 5) === "image") {
        setMedia(selectedFile);
      } else {
        setOpen(true);
        setAlert({
          severity: "error",
          content: "Please select an image only!",
        });
        // Reset media state or perform any other necessary actions
        // setMedia(null);
      }
    }
  };

  // ...

  // Usage in your component
  <input type="file" onChange={handleChange} />


  return (
    <>
      {/* <AdmanagerHeaderR /> */}
      {/* <AdManagerHeader/> */}
      <div>
        <Row style={{ height: 'calc(100vh - 80px)' }} className='rowForrevert '>
          {/*=================Side bar================ */}
          <Col xl='2'>
            {/* <CommonDasboard adtype='Single Images Ad' /> */}
            <Card className='h-100 d-none d-sm-block border-right'>
              <div className="p-4 border text-muted">
                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted">
                <div className='pb-1'><h6>Step 2</h6></div>
                <div className='text-dark'><h5><b>Select Ad Format</b></h5></div>
              </div>
              <div disabled className="p-4 borderLeftForDasboard">
                <div className='pb-1'><h6>Step 3</h6></div>
                <div className=' pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                  <p className={adTypeData?.adTypes === 'Lead Generation' ? '' : 'd-none'} ><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 4</h6></div>
                <div className=' pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 5</h6></div>
                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 6</h6></div>
                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
              </div>
            </Card>
          </Col>
          {/*============Form Section============= */}
          <Col xl='10' className='px-2'>
            <Row className='rowForrevert shadow rounded-2'>
              <Col xl='12'>
                <Card className='border-0'>
                  <div className="d-flex">
                    <div className="d-flex align-items-center p-2 border-right">
                      <button className="ad-count-btn">3</button>
                      <p className="ad-count-step px-3">Step 3</p>
                    </div>
                    <div className="p-2 pl-3">
                      <h4>Configure Your Ad</h4>
                      <p className="text-muted">Set-up your ad campaign with an image and description</p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className='rowForrevert shadow rounded-2'>
              <Col xl='12'>
                <Card className='border-0 my-1'>
                  <div className="p-3 d-flex justify-content-between">
                    <div className="navigate-left">
                      <p className="navigate-color">
                        {adTypeData?.adTypes} - ({adTypeData?.adMastrerType.name}) / Single Image Ad
                      </p>
                    </div>
                    <div>
                      <h4>
                        Preview on
                        <span>
                          <button
                            type="button"
                            className="btn btn-lg btn-size btn-toggle"
                            data-toggle="button"
                            aria-pressed="false"
                            autocomplete="off"
                            onClick={toggle}
                          >
                            <div className="handle"></div>
                          </button>
                        </span>
                      </h4>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            {/* ========================input row===================== */}
            <Row className='rowForrevert gap-1 shadow mt-1 rounded-2'>
              <Col xl={isOpen ? '8' : '12'} className={isOpen ? 'p-2 card shadow-sm' : 'p-2 card shadow-sm'}>
                <Row className='rowForrevert'>
                  <Col xl={isOpen ? '12' : '6'}>
                    <div className="p-2">
                      <div className="p-2">
                        {(adTypeData?.adTypes === 'Website Visits' || adTypeData?.adTypes === 'Lead Generation') ? (
                          <>
                            {/* Commented out code block
                              <div>
                               <p className="p-heading">
                                 Call to Action (CTA)
                                  <span className="pl-1">
                                  <img
                                  src="/assets/images/adIcon/alert-circle.png"
                                   alt="circle"
                                    />
                                   </span>
                                    </p>
                                    </div>
                                    <div className="pb-2">
                                    <Inputtype="select"
                                     className="form-control p-2 text-muted"
                                       placeholder="Choose your CTA button">
                                        <option>Contact Us</option>
                                         <option>Signup</option>
                                         <option>Get a quote</option>
                                        </Input></div> */}

                            {/* =========================dropdown==================== */}
                            <div style={{ marginLeft: '-9px' }}>
                              <div>
                                <p className="p-heading">
                                  Call to Action (CTA)
                                  <span className="pl-1">
                                    <Info fill='#828282' id='callTooltip' color='white' size={18} />
                                    <UncontrolledTooltip placement='right' target='callTooltip'>
                                      Add sections to your call to cta action.
                                    </UncontrolledTooltip>
                                  </span>
                                </p>
                              </div>
                              {adTypeData && (
                                <div className="dropdownText">
                                  <div
                                    onClick={() => {
                                      setIsActive(!isActive);
                                    }}
                                    className="dropdownText-btn"
                                  >
                                    <p>{selected}</p>
                                    <span
                                      className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'}
                                    />
                                  </div>
                                  <div
                                    className="dropdowncon-content"
                                    style={{ display: isActive ? 'block' : 'none' }}
                                  >
                                    <div
                                      onClick={(e) => {
                                        setIsSelected(e.target.textContent);
                                        setIsActive(!isActive);
                                      }}
                                      className="item"
                                    >
                                      Contact Us
                                    </div>
                                    <div
                                      className="item"
                                      onClick={(e) => {
                                        setIsSelected(e.target.textContent);
                                        setIsActive(!isActive);
                                      }}
                                    >
                                      Signup
                                    </div>
                                    <div
                                      className="item"
                                      onClick={(e) => {
                                        setIsSelected(e.target.textContent);
                                        setIsActive(!isActive);
                                      }}
                                    >
                                      Get a quote
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* =========================dropdown end================= */}
                          </>
                        ) : null}
                      </div>
                      <div>
                        <div className="mt-2">
                          <p className="p-heading">
                            Ad Heading
                            <span className="pl-1">
                              <Info fill='#828282' id='adTooltip' color='white' size={18} />
                              <UncontrolledTooltip placement='right' target='adTooltip'>
                                Add a catchy heading to engage users!.
                              </UncontrolledTooltip>
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            className="form-control p-2"
                            placeholder="Type your Ad's Heading"
                            maxLength={48}
                            name="heading"
                            value={mediaData.heading}
                            onChange={mediaInputsHandler}
                          />
                        </div>
                        <p className="p-max-car">Max 48 Characters</p>
                        <div className="">
                          <p className="p-heading">
                            Ad Sub-Heading
                            <span className="pl-1">
                              <Info fill='#828282' id='adsubTooltip' color='white' size={18} />
                              <UncontrolledTooltip placement='right' target='adsubTooltip'>
                                Add sections to your ad campaign with sub heading.
                              </UncontrolledTooltip>
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            className="form-control p-2"
                            placeholder="Type your Ad's Heading"
                            maxLength={"60"}
                            name="subHeading"
                            value={mediaData.subHeading}
                            onChange={mediaInputsHandler}
                          />
                        </div>
                        <p className="p-max-car">Max 60 Characters</p>
                        <div className={!isOpen ? 'd-none' : 'p-2 mt-3'} >
                          {/* <img src={imageDataUrl} alt="" /> */}
                          <input
                            type="file"
                            name=""
                            id="input_file"
                            onChange={(e) => {
                              console.log("changed");
                              const file = e.target.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                  const imageDataUrl1 = reader.result;
                                  console.log('===========>imageDataUrl', imageDataUrl1)
                                  setImageGroupUrl(imageDataUrl1);
                                };
                                reader.readAsDataURL(file);
                              }
                              console.log(e.target.files, '===============>e.target.files')
                              const fileType = file ? file.type.slice(0, 5) : "";
                              if (fileType === "image") {
                                setMedia(file);
                                setOpen(false); // Reset open state
                              } else {
                                setOpen(true);
                                setMedia("");
                                setAlert({
                                  sev: "error",
                                  content: "Please Select Image Only !",
                                });
                              }
                            }}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                          />

                        </div>
                      </div>
                    </div>

                    {/* <div className="p-2">
                      {(adTypeData?.adTypes === 'Website Visits' || adTypeData?.adTypes === 'Lead Generation') ? (
                        <>
                          <div>
                            <div>
                              <p className="p-heading">
                                Call to Action (CTA)
                                <span className="pl-1">
                                  <img
                                    src="/assets/images/adIcon/alert-circle.png"
                                    alt="circle"
                                  />
                                </span>
                              </p>
                            </div>
                            {adTypeData && (
                              <div className="dropdownText">
                                <div
                                  onClick={() => {
                                    setIsActive(!isActive);
                                  }}
                                  className="dropdownText-btn"
                                >
                                  <p>{selected}</p>
                                  <span
                                    className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'}
                                  />
                                </div>
                                <div
                                  className="dropdowncon-content"
                                  style={{ display: isActive ? 'block' : 'none' }}
                                >
                                  <div
                                    onClick={(e) => {
                                      setIsSelected(e.target.textContent);
                                      setIsActive(!isActive);
                                    }}
                                    className="item"
                                  >
                                    Contact Us
                                  </div>
                                  <div
                                    className="item"
                                    onClick={(e) => {
                                      setIsSelected(e.target.textContent);
                                      setIsActive(!isActive);
                                    }}
                                  >
                                    Signup
                                  </div>
                                  <div
                                    className="item"
                                    onClick={(e) => {
                                      setIsSelected(e.target.textContent);
                                      setIsActive(!isActive);
                                    }}
                                  >
                                    Get a quote
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            Rest of your code
                          </div>
                        </>
                      ) : null}
                    </div> */}

                  </Col>
                  <Col className={!isOpen ? '' : 'd-none'} xl='6'>
                    <div className="p-2">
                      <p className="p-heading">Add Your Image</p>
                      {media ? (
                        <div className="recomandation-display-block position-relative">
                          <img src={URL.createObjectURL(media)} alt="mediaimg" style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '5px' }} />
                          <div className="recom-btn-cont-blk  bottom-0 rounded-2">
                            {/* new-recom-btn-cont-blk */}
                            <div className="container">
                              <div className="row d-flex justify-content-center">
                                <div className="col-4 px-auto border-right">
                                  <h4
                                    className="text-center"
                                    role="button"
                                    onClick={imageUpload}
                                  >
                                    Edit
                                  </h4>
                                </div>
                                <div className="col-4 px-auto border-left">
                                  <h4
                                    className="text-center"
                                    role="button"
                                    onClick={() => setMedia("")}
                                  >
                                    Delete
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div role='button'
                          onClick={imageUpload}
                          className="upload-img w-100 d-flex flex-column justify-content-center align-items-center"
                        >
                          <img src="/assets/images/adIcon/upload.png" alt="" />
                          <h4 className='mt-2 font-weight-bold'>Upload Image</h4>
                        </div>
                      )}
                      <input
                        type="file"
                        name=""
                        id="input_file"
                        hidden
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                {adTypeData?.adTypes === 'Website Visits' ?
                  <Row className='rowForrevert m-1'>
                    <Col xl='12' style={{ marginLeft: '4px', paddingRight: '12px' }}>
                      <div className="textarea py-2">
                        <div className="">
                          <p className="p-heading">
                            Provide Web link
                            <span className="pl-1">
                              <Info fill='#828282' id='linkTooltip' color='white' size={18} />
                              <UncontrolledTooltip placement='right' target='linkTooltip'>
                                provide your web link url.
                              </UncontrolledTooltip>
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            className="form-control p-2"
                            placeholder="Enter your web link here..."

                            name="subHeading"

                          />
                        </div>
                      </div>
                    </Col>
                  </Row> : null}
                <Row className='rowForrevert m-1'>
                  <Col xl='12' style={{ marginLeft: '4px', paddingRight: '12px' }}>
                    <div className="textarea">
                      <div className="">
                        <p className="p-heading">
                          Ad Description
                          <span className="pl-1">
                            <Info fill='#828282' id='desTooltip' color='white' size={18} />
                            <UncontrolledTooltip placement='right' target='desTooltip'>
                              Describe ad campaign to your target audience.
                            </UncontrolledTooltip>
                          </span>
                        </p>
                      </div>
                      <div className="">
                        <textarea
                          name="discriptions"
                          className="form-control"
                          rows="5"
                          placeholder="Enter your ad description here..."
                          maxLength={"300"}
                          value={adData.discriptions}
                          onChange={(e) =>
                            setAdData({ ...adData, discriptions: e.target.value })
                          }
                        ></textarea>
                      </div>
                      <p className="p-max-car">Max 300 Characters</p>
                    </div>
                  </Col>
                </Row>
                <Row className='rowForrevert mt-1'>
                  {/* <Col  xl={!isOpen ? '6' : '12'} className={!isOpen ? '' : 'd-none'}> */}
                  <div xl={!isOpen ? '6' : '12'} className={adTypeData?.adTypes === 'Lead Generation' ? '' : 'd-none'}>
                    {/* xl={!isOpen ? '6' : '12'} */}
                    <div className="d-flex justify-content-between m-2" >
                      <div>
                        <div style={{ marginLeft: '4px' }}>
                          {!clicked && (
                            <Link to='/Ad/LeadGeneration'>
                              <Button
                                tag='a'
                                style={{
                                  position: 'absolute',
                                  marginRight: '13px',
                                  color: clicked ? '#81c14b' : '#81c14b',
                                  backgroundColor: clicked ? 'white' : 'white',
                                  border: `1px solid ${clicked ? '#81c14b' : '#81c14b'}`
                                }}
                              // onClick={handleClick}
                              // readOnly
                              >
                                Customize your Lead Generation Form
                              </Button></Link>
                          )}
                          {clicked && <span style={{ color: '#4D4D4D', fontSize: '15px' }}>Lead Generation Form Customize your Lead Generation Form</span>}
                          {/* {buttonText} */}
                        </div>
                      </div>
                      <div>
                        {clicked && (
                          <Button tag='a' href='/Ad/LeadGeneration' color='primary' outline style={{ marginRight: '13px' }}>
                            Create Form
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div xl={adTypeData?.adTypes === 'Lead Generation' ? '6' : '12'} className={!isOpen ? '' : 'd-none'} >
                    <div className="p-3">
                      <div className="ad-btn">
                        {/* <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                          Back
                        </Button> */}
                        <div className="">
                          {/* p-3 */}
                          <div className="ad-btn" style={{ marginTop: '-40px' }}>
                            <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2" >
                              Back
                            </Button>
                            <Button
                              id='controlledPopover'
                              color='primary'
                              size='md'
                              className={`ml-2 ${isButtonDisabled ? 'disabled-button' : ''}`}
                              style={{
                                backgroundColor: isNextButtonEnabled() ? '#81C14B' : 'rgb(172, 218, 214)',
                                cursor: isNextButtonEnabled() ? 'pointer' : 'not-allowed',
                                border: 'none',
                                outline: 'none',
                                color: 'white'
                              }}
                              onClick={() => {
                                if (isNextButtonEnabled()) {
                                  navigate('/Ad/ChooseAudience');
                                }
                              }}
                              disabled={!isNextButtonEnabled()}
                            >
                              Next
                            </Button>
                            <Popover
                              placement='top'
                              target='controlledPopover'
                              isOpen={warningOn}
                              toggle={() => setwarningOff(!warningOn)}
                            >
                              {/* <PopoverBody>
                            <div className="text-muted">
                              Your Ad Configuration cannot be changed once you save. Do you want to save?
                            </div>
                            <div className="text-center">
                              <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                                Cancel
                              </Button>
                              <Button onClick={() => navigate('/Ad/ChooseAudience')} color='primary' size='sm' className="px-3 py-1">
                                Save
                              </Button>
                            </div>
                          </PopoverBody> */}
                            </Popover>
                          </div>
                        </div>
                        <Popover
                          placement='top'
                          target='controlledPopover'
                          isOpen={warningOn}
                          toggle={() => setwarningOff(!warningOn)}
                        >
                          {/* <PopoverBody>
                            <div className="text-muted">
                              Your Ad Configuration cannot be changed once you save. Do you want to save?
                            </div>
                            <div className="text-center">
                              <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                                Cancel
                              </Button>
                              <Button onClick={() => navigate('/Ad/ChooseAudience')} color='primary' size='sm' className="px-3 py-1">
                                Save
                              </Button>
                            </div>
                          </PopoverBody> */}
                        </Popover>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              {/*=================Preview Section============== */}
              <Col className={!isOpen ? 'd-none' : 'p-2 card shadow-sm'}>
                <Collapse horizontal isOpen={isOpen}>
                  <PreviewMoreSection heading={mediaData.heading} subHeading={mediaData.subHeading} media={media} discriptions={adData.discriptions} imageDataUrl={imageDataUrl} imageGroupUrl={imageGroupUrl} imageStoryUrl={imageStoryUrl} imageBizUrl={imageBizUrl} setClicked={setClicked} imageMarketUrl={imageMarketUrl} imagePodcastUrl={imagePodcastUrl} imageEventUrl={imageEventUrl} />
                </Collapse>
              </Col>
            </Row>

            <Row className={!isOpen ? 'd-none' : 'rowForrevert p-2 card shadow-sm'}>
              <Col xl='12'>
                <div className='p-3'>
                  <div className="ad-btn">
                    <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                      Back
                    </Button>
                    <Popover
                      placement='top'
                      target='controlledPopover'
                      isOpen={warningOn}
                      toggle={() => setwarningOff(!warningOn)}
                    >
                      <PopoverBody>
                        {/* <div className="text-muted">
                          Your Ad Configuration cannot be changed once you save. Do you want to save?
                        </div> */}
                        {/* <div className="text-center">
                          <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                            Cancel
                          </Button>
                          <Button onClick={() => navigate('/Ad/ChooseAudience')} color='primary' size='sm' className="px-3 py-1">
                            Save
                          </Button>
                        </div> */}
                      </PopoverBody>
                    </Popover>
                    <Button id='controlledPopover' color='primary' size='md' className="ml-2" onClick={() => navigate('/Ad/ChooseAudience')}>
                      Next
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {loading && <Loader loading={loading} />}
          </Col>
        </Row>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }} id="stack">
        {/*==============Snackbar=============== */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.sev}
            sx={{ width: "100%" }}
          >
            {alert.content}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default SingleImageAdScreen;
