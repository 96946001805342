import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import AvatarGroup from '../../NewComponents/AvatarGroup';
import { approveUserFollowingRequests, getUserFollowingRequests, rejectUserFollowingRequests } from '../../Services/Actions/UserProfile/userFollowingRequestsAction';

// ... (import statements for images, AvatarGroup, etc.)

const FriendRequest = () => {
    const { userFollowingRequests } = useSelector(state => state.userFollowingRequestsData);
    const dispatch = useDispatch();

    const approveRequestHandler = (id) => {
        console.log(id);
        // Dispatch the approveUserFollowingRequests action with the request id
        // dispatch(approveUserFollowingRequests(id));
    }

    const rejectRequestHandler = (id) => {
        // Dispatch the rejectUserFollowingRequests action with the request id
        dispatch(rejectUserFollowingRequests(id));
    }

    useEffect(() => {
        dispatch(getUserFollowingRequests());
    }, []);

    return (
        <div className="suggestion-box section-b-space groupBottom-custom-block">
            <div className='CommonHeadingSeg'>
                <h3 style={{ color: "#333333" }}>Follow Requests</h3>
                <h5>{userFollowingRequests.length} Request</h5>
            </div>
            <div className="suggestion-content ratio_115">
                <div className="list-content">
                    <ul className="friend-request-block">
                        {userFollowingRequests?.map(request => (
                            <div className='my-3' key={request.id}>
                                <li>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <img src={request.profileImage} width={50} height={50} className=" bg-img rounded-circle" alt="profileImage" />
                                            <div className='ml-3'>
                                                <h2 style={{ fontWeight: "600", fontSize: "16px", color: "#4D4D4D" }}>{request.fullName}</h2>
                                                <div className="people-likes matual-friend-sec">
                                                    <ul className="matual-friend-blk">
                                                        <AvatarGroup />
                                                    </ul>
                                                    <h6 className='text-nowrap' style={{ cursor: "pointer", fontSize: "12px", color: "#999999" }}>+{request.followersCount} Followers</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ color: "#999999", fontSize: "12px" }} className='text-nowrap'>{request.timeAgo}</div>
                                    </div>
                                </li>
                                <div className='friend-request-btn text-center mt-3 my-2 d-flex justify-content-start'>
                                    {/* "Accept" button with onClick to call approveRequestHandler */}
                                    <Button color='primary' className='px-4 py-2 FollowRequest_Accept_btn' onClick={() => approveRequestHandler(request.id)}>Accept</Button>
                                    {/* "Reject" button with onClick to call rejectRequestHandler */}
                                    <Button outline color='primary py-2 ml-3 px-4 FollowRequest_Reject_btn' onClick={() => rejectRequestHandler(request.id)}>Reject</Button>
                                </div>
                            </div>
                        ))}
                    </ul>
                </div>
                <h5 className="time-hours-blk text-center mt-3"><Link to="/FollowRequests" style={{ color: '#999999' }}>See all Requests</Link></h5>
            </div>
        </div>
    );
}

export default FriendRequest;


// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Button } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import { approveUserFollowingRequests, getUserFollowingRequests, rejectUserFollowingRequests } from '../../Services/Actions/UserProfile/userFollowingRequestsAction';
// import AvatarGroup from '../../NewComponents/AvatarGroup';

// const FriendRequest = () => {
//     const { userFollowingRequests } = useSelector(state => state.userFollowingRequestsData);
//     const dispatch = useDispatch();

//     const approveRequestHandler = (id) => {
//         dispatch(approveUserFollowingRequests(id));
//     }

//     const rejectRequestHandler = (id) => {
//         dispatch(rejectUserFollowingRequests(id));
//     }

//     useEffect(() => {
//         dispatch(getUserFollowingRequests());
//     }, []);

//     return (
//         <div className="suggestion-box section-b-space groupBottom-custom-block">
//             <div className='CommonHeadingSeg'>
//                 <h3 style={{ color: "#333333" }}>Follow Requests</h3>
//                 <h5>{userFollowingRequests.length} Request</h5>
//             </div>
//             <div className="suggestion-content ratio_115">
//                 <div className="list-content">
//                     <ul className="friend-request-block">
//                         {userFollowingRequests.map(request => (
//                             <div className='my-3' key={request.id}>
//                                 <li>
//                                     <div className='d-flex justify-content-between'>
//                                         <div className='d-flex'>
//                                             <img src={request.profileImage} width={50} height={50} className=" bg-img rounded-circle" alt="profileImage" />
//                                             <div className='ml-3'>
//                                                 <h2 style={{ fontWeight: "600", fontSize: "16px", color: "#4D4D4D" }}>{request.fullName}</h2>
//                                                 <div className="people-likes matual-friend-sec">
//                                                     <ul className="matual-friend-blk">
//                                                         <AvatarGroup />
//                                                     </ul>
//                                                     <h6 className='text-nowrap' style={{ cursor: "pointer", fontSize: "12px", color: "#999999" }}>+{request.followersCount} Followers</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div style={{ color: "#999999", fontSize: "12px" }} className='text-nowrap'>{request.timeAgo}</div>
//                                     </div>
//                                 </li>
//                                 <div className='friend-request-btn text-center mt-3 my-2 d-flex justify-content-start'>
//                                     <Button color='primary' className='px-4 py-2 FollowRequest_Accept_btn' onClick={() => approveRequestHandler(request.id)}>Accept</Button>
//                                     <Button outline color='primary py-2 ml-3 px-4 FollowRequest_Reject_btn' onClick={() => rejectRequestHandler(request.id)}>Reject</Button>
//                                 </div>
//                             </div>
//                         ))}
//                     </ul>
//                 </div>
                
//                 <h5 className="time-hours-blk text-center mt-3"><Link to="/FollowRequests" style={{ color: '#999999' }}>See all Requests</Link></h5>
//             </div>
//         </div>
//     );
// }

// export default FriendRequest;
