import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ArrowLeft, ArrowRight, ChevronDown, Copy, Download, Filter, Plus, Search, Trash2, TrendingUp, X } from 'react-feather'
import ReactPaginate from 'react-paginate'
import { Link, NavLink } from 'react-router-dom'
import { Badge, Button, Card, CardBody, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import coverImage from '../../../NewComponents/IMG/reel4.png'
import ChartIcon from '../../../NewComponents/IMG/AdmangerIconChart.svg'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Pagination, Radio, Stack } from '@mui/material'
import '../Ads.css'
import DatePicker from 'react-datepicker';
// import { CSVLink } from 'react-csv'


const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none' // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: '#E5F0E0',
            fontSize: '12px',
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            marginTop: '1%'
        }
    }
}

const TransectionsTable = () => {

    // const [loading, setLoading] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // const [searchValue, setSearchValue] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])
    const [onFilter, setOffFilter] = useState(false)

    const now = new Date();
    const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const [value, onChange] = useState([yesterdayBegin, todayEnd]);
    // const [checkList, setCheckList] = useState({
    //     serialnoCheck: true,
    //     OffOnCheck: true,
    //     adNameCheck: true,
    //     adIdCheck: true,
    //     adTypeCheck: true,
    //     totalImpressionsCheck: true,
    //     totalReachCheck: true,
    //     clickCheck: true,
    //     viewCheck: true,
    //     leadCheck: true,
    //     totalAmountCheck: true,
    //     dailyBudgetCheck: true,
    //     overallBudgetCheck: true,
    //     startDateCheck: true,
    //     endDateCheck: true,
    //     lastSignificantCheck: true,
    // })

    // const handleSearch = (event) => {
    //   setSearchValue(event.target.value)
    //   setRender(!render)
    // }

    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }

    const [deleteallModal, setDeleteallModal] = useState(false)

    const DeletedModal = () => {
        return (
            <>
                <Modal isOpen={deleteallModal} className='modal-dialog-centered' toggle={() => setDeleteallModal(!deleteallModal)}>
                    <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader>
                    <ModalBody>
                        <div className='text-center mb-2'>
                            <Trash2 size={70} color="#EA5455" />
                            <h3 className='mt-2'>Do You Really Want to Delete all ?</h3>
                            <h5>{selectedRows.length}</h5>
                        </div>
                        <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the ‘Action History’ screen." />
                        </InputGroup>
                        <div className="d-flex justify-content-around m-1">
                            <div>  <Button type="submit" color="danger" outline >Yes , Delete it</Button></div>
                            <div><Button color="success" outline onClick={() => setDeleteallModal(!deleteallModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

    const tabledata = [
        {
            id: '1',
            Adname: 'New Traffic Ad - Kickstarter',
            tsnId: '#45678987',
            tsnType: 'Paid to Ad',
            status: 'Success',
            tsnAmount: '₹9578',
            dailBudget: '₹9578',
            availableBalance: '₹9578',
            dateTime: '2022-11-05T11:13:00.000Z',
            paymentMode: 'UPI',
            debitorbankName: 'Union Trust Bank',
            debitorName: 'Rechard Walston',
            acNo: '54658446111',
            view: 'View Charts',
            Duplicate: 'Duplicate',
            ChartIcon: ChartIcon,
            coverImage: coverImage,
            img: <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />,
            dp: <Copy strokeWidth={4} color='#6E6B7B' size={12} />
        },
        {
            id: '2',
            Adname: '',
            tsnId: '#45678987',
            tsnType: 'Balance Top-Up',
            status: 'Failed',
            tsnAmount: '₹9578',
            dailBudget: '₹9578',
            availableBalance: '₹9578',
            dateTime: '2022-11-05T11:13:00.000Z',
            paymentMode: 'UPI',
            debitorbankName: 'Union Trust Bank',
            debitorName: 'Rechard Walston',
            acNo: '54658446111',
            adview: '------'
        },
        {
            id: '3',
            Adname: 'New Traffic Ad - Kickstarter',
            tsnId: '#45678987',
            tsnType: 'Balance Top-Up',
            status: 'Success',
            tsnAmount: '₹9578',
            dailBudget: '₹9578',
            availableBalance: '₹9578',
            dateTime: '2022-11-05T11:13:00.000Z',
            paymentMode: 'UPI',
            debitorbankName: 'Union Trust Bank',
            debitorName: 'Rechard Walston',
            acNo: '54658446111',
            view: 'View Charts',
            Duplicate: 'Duplicate',
            ChartIcon: ChartIcon,
            coverImage: coverImage,
            img: <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />,
            dp: <Copy strokeWidth={4} color='#6E6B7B' size={12} />
        },
        {
            id: '4',
            Adname: 'New Traffic Ad - Kickstarter',
            tsnId: '#45678987',
            tsnType: 'Paid to Ad',
            status: 'Success',
            tsnAmount: '₹9578',
            dailBudget: '₹9578',
            availableBalance: '₹9578',
            dateTime: '2022-11-05T11:13:00.000Z',
            paymentMode: 'UPI',
            debitorbankName: 'Union Trust Bank',
            debitorName: 'Rechard Walston',
            acNo: '54658446111',
            view: 'View Charts',
            Duplicate: 'Duplicate',
            ChartIcon: ChartIcon,
            coverImage: coverImage,
            img: <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />,
            dp: <Copy strokeWidth={4} color='#6E6B7B' size={12} />
        },
        {
            id: '5',
            Adname: 'New Traffic Ad - Kickstarter',
            tsnId: '#45678987',
            tsnType: 'Balance Top-Up',
            status: 'Success',
            tsnAmount: '₹9578',
            dailBudget: '₹9578',
            availableBalance: '₹9578',
            dateTime: '2022-11-05T11:13:00.000Z',
            paymentMode: 'UPI',
            debitorbankName: 'Union Trust Bank',
            debitorName: 'Rechard Walston',
            acNo: '54658446111',
            view: 'View Charts',
            Duplicate: 'Duplicate',
            ChartIcon: ChartIcon,
            coverImage: coverImage,
            img: <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />,
            dp: <Copy strokeWidth={4} color='#6E6B7B' size={12} />
        }
    ]

    const columns = [
        // {
        //     name: 'Sr. No',
        //     selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
        //     omit: !checkList.serialnoCheck,
        //     sortable: true
        // },

        {
            name: 'Transaction ID',
            sortable: true,
            // omit: !checkList.OffOnCheck,
            minWidth: "100px",
            cell: row => (
                <div className="text-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '12px' }}>{row.tsnId + 1}</h5>
                </div>
            )

        },
        {
            name: "Ad Name",
            selector: row => row.userName,
            minWidth: "250px",
            // omit: !checkList.adNameCheck,
            sortable: true,
            cell: row => (
                <Link to="#" className='align-items-start d-flex pb-2'>
                    <div className='align-items-start'>
                        {row.img}
                    </div>
                    <Link style={{ color: '#4D4D4D' }} className='ml-1'>
                        <h5 to="#" className='mb-2 mt-2' style={{ textWrap: 'nowrap' }}>
                            {row.Adname}
                        </h5 >
                        <p className='' style={{ position: 'absolute' }}>{row.adview}</p>
                        <div className='d-flex' style={{ color: '#0066FF' }}>
                            <div className='d-flex align-items-center'>
                                <img src={row.ChartIcon} alt='' />
                                <NavLink className='ml-1' to={'/AdAnalysis'}><u>{row.view}</u></NavLink>
                            </div>

                            &nbsp;
                            &nbsp;
                            <h6>{row.dp}<u>{row.Duplicate}</u></h6>
                        </div>
                    </Link>
                </Link>
            )
        },

        {
            name: 'Transaction Type',
            sortable: true,
            // omit: !checkList.OffOnCheck,
            minWidth: "100px",
            cell: row => (
                <div className="text-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '12px' }}>{row.tsnType}</h5>
                </div>
            )

        },

        {
            name: 'Status',
            sortable: true,
            // omit: !checkList.adIdCheck,
            minWidth: "100px",
            cell: row => (
                <div>

                    {row.status === 'Failed' ? <Badge className='colorForBadgedanger' pill>
                        {row.status}
                    </Badge> : <Badge className='colorForBadgePrimary' pill>
                        {row.status}
                    </Badge>}

                </div>
            )

        },
        {
            name: 'Txn Amount',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '8px', fontSize: '12px' }}>{row.tsnAmount}</h5>
                </div>
            )

        },

        {
            name: 'Available Balance',
            sortable: true,
            // omit: !checkList.totalImpressionsCheck,
            minWidth: "100px",
            cell: row => (
                <div className="text-center fontForSubTablheading" >
                    <h5 style={{ marginLeft: '4px', fontSize: '12px' }}>{row.availableBalance}</h5>
                </div>
            )

        },

        {
            name: "Date & Time",
            minWidth: "100px",
            // omit: !checkList.startDateCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px' }}>{moment(row.DateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px' }}>{moment(row.DateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Payment Mode",
            minWidth: "100px",
            // omit: !checkList.endDateCheck,
            sortable: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '5px', fontSize: '12px' }}>{row.paymentMode}</h5>
                </div>
            )
        },

        {
            name: "Debitor",
            minWidth: "100px",
            // omit: !checkList.lastSignificantCheck,
            sortable: true,
            cell: row => (
                <div className='text-left fontForSubTablheading'>
                    <h4 style={{ fontSize: '12px' }}>{row.debitorbankName}</h4>
                    <h6 style={{ fontSize: '12px' }}>{row.debitorName}</h6>
                    <p style={{ fontSize: '12px' }}>A/C:&nbsp;{row.acNo}</p>
                </div>
            )
        }
    ]



    const filterData = {
        "Transaction ID": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: true, title: "Enter Transaction ID" },
        },
        "Transaction Type": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: false, title: "Enter ID  Dobara " },
            dropdownS: ["Contains"],
        },
        "Status": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: false, title: "Enter ID  Dobara " },
            dropdownS: ["Contains"],
        },
        "Txn Amount": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: false, title: "Enter ID  Dobara " },
            dropdownS: ["Contains"],
        },
        "Available Balance": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: false, title: "Enter ID  Dobara " },
            dropdownS: ["Contains"],
        },
        "Payment Mode": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: false, title: "Enter ID  Dobara " },
            dropdownS: ["Contains"],
        },
        "Debitor": {
            dropdown: ["Contains any", "abh", "kdjflk"],
            input: { type: false, title: "Enter ID  Dobara " },
            dropdownS: ["Contains"],
        },
        // "Status1": {},
        // "Txn Amount": {},
        // "Available Balance": {},
        // "Payment Mode": {},
        // "Debitor": {},
    }


    // const checkListArr = [
    //     {
    //         check: {
    //             ...checkList,
    //             serialnoCheck: !checkList.serialnoCheck
    //         },
    //         state: checkList.serialnoCheck,
    //         name: "Sr. No"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             OffOnCheck: !checkList.OffOnCheck
    //         },
    //         state: checkList.OffOnCheck,
    //         name: "Off/On"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             adNameCheck: !checkList.adNameCheck
    //         },
    //         state: checkList.adNameCheck,
    //         name: "Ad Name"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             adIdCheck: !checkList.adIdCheck
    //         },
    //         state: checkList.adIdCheck,
    //         name: "Ad ID"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             adTypeCheck: !checkList.adTypeCheck
    //         },
    //         state: checkList.adTypeCheck,
    //         name: "Ad Type"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             totalImpressionsCheck: !checkList.totalImpressionsCheck
    //         },
    //         state: checkList.totalImpressionsCheck,
    //         name: "Total Impressions"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             totalReachCheck: !checkList.totalReachCheck
    //         },
    //         state: checkList.totalReachCheck,
    //         name: "Total Reach"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             clickCheck: !checkList.clickCheck
    //         },
    //         state: checkList.clickCheck,
    //         name: "Click"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             viewCheck: !checkList.viewCheck
    //         },
    //         state: checkList.viewCheck,
    //         name: "View"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             leadCheck: !checkList.leadCheck
    //         },
    //         state: checkList.leadCheck,
    //         name: "Lead"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             totalAmountCheck: !checkList.totalAmountCheck
    //         },
    //         state: checkList.totalAmountCheck,
    //         name: "Total Amount spent"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             dailyBudgetCheck: !checkList.dailyBudgetCheck
    //         },
    //         state: checkList.dailyBudgetCheck,
    //         name: "Daily Budget"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             overallBudgetCheck: !checkList.overallBudgetCheck
    //         },
    //         state: checkList.overallBudgetCheck,
    //         name: "Over all Budget"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             startDateCheck: !checkList.startDateCheck
    //         },
    //         state: checkList.startDateCheck,
    //         name: "Start Date & Time"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             endDateCheck: !checkList.endDateCheck
    //         },
    //         state: checkList.endDateCheck,
    //         name: "Ending Date & Time"
    //     },
    //     {
    //         check: {
    //             ...checkList,
    //             lastSignificantCheck: !checkList.lastSignificantCheck
    //         },
    //         state: checkList.lastSignificantCheck,
    //         name: "Last significant edit"
    //     }
    // ]

    const Cardheader = () => {

        const TransectionKeys = Object.keys(filterData)

        const [changeFeild, setChangeFeild] = useState("Transaction ID")

        const [SubFeild, setSubFeild] = useState(filterData[changeFeild].dropdown[0])

        const [SubFeildS, setSubFeildS] = useState(filterData[changeFeild].dropdown[0])
        const [selectedDate, setSelectedDate] = useState(null);
        return (
            // <div className="profile-menu d-flex justify-content-between align-item-center row">
            <Row>
                <Col>
                    <div className='d-flex justify-content-between align-items-center main-container'>
                        <div className={onFilter ? 'd-flex align-items-center border rounded' : ' d-flex align-items-center filter-section'}>
                            {/* DynamicFilterForStyle */}

                            {onFilter ?
                                <>
                                    <Filter className='ml-2' size={20} />
                                    <div className="d-flex align-items-center p-1 apply-filter-button">
                                        <UncontrolledButtonDropdown className='px-2'>
                                            <DropdownToggle style={{ padding: '10px' }} color='primary' outline>
                                                {changeFeild}<ChevronDown size={16} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {TransectionKeys.map((keys, index) => (
                                                    <div className='d-flex align-items-center' onClick={() => setChangeFeild(keys)} ><Radio checked={keys === changeFeild} id="a" size='small' name="uuuu" />&nbsp;<h5>{keys}</h5></div>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle style={{ padding: '10px' }} color='primary' outline>
                                                {SubFeild} <ChevronDown size={16} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {filterData[changeFeild].dropdown.map((item, index) => (
                                                    <div className='d-flex align-items-center' onClick={() => setSubFeild(item)}><Radio id="g" checked={item === SubFeild} size='small' />&nbsp;<h5>{item}</h5></div>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        {filterData[changeFeild].input.type ?
                                            <Input placeholder={filterData[changeFeild].input.title} type='text' className='mx-2' style={{ width: '150px' }} />
                                            : <UncontrolledButtonDropdown>
                                                <DropdownToggle style={{ padding: '10px' }} color='primary' className='mx-2' outline>
                                                    {SubFeildS} <ChevronDown size={16} />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {filterData[changeFeild].dropdownS.map((item, index) => (
                                                        <div className='d-flex align-items-center' onClick={() => setSubFeildS(item)}><Radio id="g" checked={item === SubFeildS} size='small' />&nbsp;<h5>{item}</h5></div>
                                                    ))}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>}
                                        <Button outline size='small' color='primary'>Apply Filter</Button>
                                    </div>
                                </> : null
                            }
                            {/* <>
                                <Button onClick={() => setOffFilter(!onFilter)} className='btn-icon  texts px-1' color='#e6f3db'>
                                    {onFilter ? <X size={20} /> : <Plus size={20} />}
                                </Button>
                                {onFilter ? "" : <p style={{ fontSize: '12px', fontWeight: '400px', textWrap: 'nowrap' }} className='use-filters-text'>Use filters to refine your search.</p>}
                            </> */}
                            <div style={{ position: 'relative' }} className='search-ad-text '>
                                <Input type='search' style={{ paddingLeft: '25px' }} size='58' className='' Placeholder='Search here...' />
                                <div style={{ position: 'absolute', top: '6px', marginLeft: '5px' }}>
                                    <Search size={15} />
                                </div>
                            </div>&nbsp;&nbsp;
                        </div>
                        <div className='d-flex justify-content-between'>
                            {/* <div style={{ position: 'relative' }} className='search-ad-text '>
                                <Input type='search' style={{ paddingLeft: '25px' }} size='58' className='' Placeholder='Search here...' />
                                <div style={{ position: 'absolute', top: '6px', marginLeft: '5px' }}>
                                    <Search size={15} />
                                </div>
                            </div>&nbsp;&nbsp; */}
                            {/* <div>
                                <button className='form-control' style={{ backgroundColor: 'rgba(129, 193, 75, 1)', color: 'white', border: 'none' }}>Search</button>
                            </div>&nbsp;&nbsp; */}
                            <div className='d-flex '>
                                {/* date-and-export */}
                                {/* <DateRangePicker
                                    calendarAriaLabel="Toggle calendar"
                                    clearAriaLabel="Clear value"
                                    dayAriaLabel="Day"
                                    monthAriaLabel="Month"
                                    nativeInputAriaLabel="Date"
                                    onChange={onChange}
                                    value={value}
                                    yearAriaLabel="Year"
                                />&nbsp; */}
                                {/* <input type="date" name="" id="" placeholder='dd/mm/yyyy' className='form-control' />&nbsp;&nbsp; */}
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={date => setSelectedDate(date)}
                                    placeholderText="dd/mm/yyyy"
                                    className="form-control"
                                />&nbsp;&nbsp;
                                <Button outline color='primary' className='font-weight-bold mr-1'>
                                    <Download size={18} /> Export
                                </Button>
                            </div>
                        </div>



                    </div>
                </Col>
            </Row >
            // </div>
        )
    }

    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }

    const CustomPagination = () => {

        const count = Number(Math.ceil(totalItems / rowsPerPage))

        return (
            <div className='d-flex justify-content-between mt-2'>
                <div className="d-flex align-items-center">
                    <Label for='row-per-page' className='text-nowrap'>Row per page</Label>
                    <CustomInput
                        className="form-control mx-50 px-1.5 ml-1 w-50"
                        style={{ backgroundColor: '#E5F0E0' }}
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={onChangeRowsPerValue}
                    >
                        <option value='5'>5 Row</option>
                        <option value='10'>10 Row</option>
                        <option value='25'>25 Row</option>
                        <option value='50'>50 Row</option>
                        <option value='100'>100 Row</option>
                    </CustomInput>
                </div>
                {/* <ReactPaginate
                    previousLabel={<ArrowLeft color='#4D4D4D' size={15} />}
                    nextLabel={<ArrowRight color='#4D4D4D' size={15} />}
                    pageCount={count}
                    activeClassName='active'
                    forcePage={currentPage}
                    onPageChange={page => {
                        setCurrentPage(page.selected)
                        setRender(!render)
                    }}
                    pageClassName='page-item'
                    nextClassName='page-item next'
                    nextLinkClassName='page-link'
                    previousClassName='page-item prev'
                    previousLinkClassName='page-link'
                    pageLinkClassName='page-link'
                    containerClassName="pagination react-paginate justify-content-end my-2 pr-1"
                /> */}
                {/* <Pagination count={10} /> */}
                <Stack>
                    <Pagination count={10} className='PaginationCount'>
                    </Pagination>
                </Stack>
            </div>
        )
    }


    useEffect(() => {
        // getPostfeedLikedetails()
    }, [render])

    return (

        <div>
            <Card>
                <CardBody>
                    <div className='mb-2'>
                        <DeletedModal />
                        <Cardheader />
                    </div>
                    {
                        selectedRows.length !== 0 &&
                        <div style={{ backgroundColor: '#E3F2FD' }} className='d-flex justify-content-between align-items-center p-2 mb-1'>
                            <h4> {selectedRows.length} item selected</h4>
                            <Button className='btn-icon' color='secondary' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                                <Trash2 color='red' size={16} />
                            </Button>
                        </div>
                    }
                    <Row>
                        <Col>
                            <DataTable
                                className='react-dataTable'
                                noHeader
                                responsive
                                selectableRows
                                paginationServer
                                selectableRowsHighlight
                                highlightOnHover
                                pointerOnHover
                                onSelectedRowsChange={handleChange}
                                sortIcon={<ChevronDown />}
                                paginationComponent={CustomPagination}
                                columns={columns}
                                data={tabledata}
                                customStyles={styleTable}
                            // progressPending={loading}
                            />
                        </Col>
                    </Row>
                    <CustomPagination />
                </CardBody>
            </Card>
        </div>
    )
}

export default TransectionsTable