
import { Checkbox } from '@mui/material'
import React from 'react'
import { Accordion } from 'react-bootstrap'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import { Circle, Filter } from 'react-feather'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, UncontrolledButtonDropdown } from 'reactstrap'
import { ForwardArrayData } from '../../Social-Feed-Component/FeedArray'
import UserImg from '../../../NewComponents/IMG/img.svg'




const ShareModal = ({ forwardOpen, toggleForward }) => {
    // forwardOpen ia boolean state and toggleForward is a state toggler function, received here as props to open this modal and then close it again by toggling the state using toggleForward function.
    // prleft to do

    const Sharedto = [
        {
            name: "Your Wall Feed"
        },
        {
            name: "Your Story"
        }
    ]

    //=========================================================================== Main return function =============================================================================//
    return (
        <div>
            {
                forwardOpen !== false &&
                <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered' >

                    <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
                        <h2>Share to  </h2>
                        <Button color='primary' >Share</Button>
                    </div>

                    <ModalBody className='mb-3' >

                        <div className='pb-2'>
                            <Accordion defaultActiveKey="0">
                                <AccordionItem eventKey='1'>
                                    <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                                    <AccordionBody className='p-0'>
                                        <Accordion defaultActiveKey="0">
                                            <AccordionItem eventKey='1'>
                                                <AccordionHeader>Feed</AccordionHeader>
                                                <AccordionBody>
                                                    {
                                                        Sharedto.map((data) => (
                                                            <div className='d-flex justify-content-between align-items-center my-2 Report_Section'>
                                                                <h4>{data.name}</h4>
                                                                <div>
                                                                    <Checkbox />
                                                                    {' '}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem eventKey='2'>
                                                <AccordionHeader>Your Business Pages</AccordionHeader>
                                                <AccordionBody>
                                                    <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                        <div className='ForWard_Img_categories d-flex align-items-center'>
                                                            <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                            <div className="ml-1">
                                                                <h4>Your Biz page name</h4>
                                                            </div>
                                                        </div>
                                                        <Checkbox />
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                            <AccordionItem eventKey='3'>
                                                <AccordionHeader>Your Group</AccordionHeader>
                                                <AccordionBody>

                                                    <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                        <div className='ForWard_Img_categories d-flex align-items-center'>
                                                            <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                            <div className="ml-1">
                                                                <h4>Your Group name</h4>
                                                            </div>
                                                        </div>
                                                        <Checkbox />
                                                    </div>

                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <div className="search-block-podcast mt-2">
                            <div className="input-search-blk">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                            </div>
                        </div>

                        <div className='mt-3 Categories_subHeading_section '>
                            <h4>Connections</h4>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='primary' outline>
                                    <Filter size={20} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag='a'> Recent </DropdownItem>
                                    <DropdownItem tag='a'> Full Name</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='mt-3 mb-3' style={{ height: '300px' }} >
                            {ForwardArrayData.map((data) => (
                                <div className="group-detail-page-blk">
                                    <div className="MyEvents-memberslist-block">
                                        <div className="group-member-img-cont-blk">
                                            <div className='ForWard_Img_categories'>
                                                <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                            </div>
                                            <div className="member-cont-blk">
                                                <h4>{data.name}</h4>
                                                <p className=''>{data.userName}</p>
                                            </div>
                                        </div>

                                        <Checkbox />

                                    </div>
                                </div>
                            ))}
                        </div>

                    </ModalBody>
                </Modal>
            }

        </div>
    )
}

export default ShareModal


