import { Book, BookOpen, Table, Truck } from "react-feather";

 export const HobbiesDataOption = [
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Reading ",
        name1: "Photography",
        icon1: <BookOpen size={16} color="#4D4D4D" />,
        icon3: <Table size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Travle",
        name1: "Books",
        icon1: <Truck size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />

    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Reading ",
        name1: "Reading",
        icon1: <Table size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Movies ",
        name1: "Gaming",
        icon1: <BookOpen size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Gaming ",
        name1: "Travelling",
        icon1: <Truck size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Music ",
        name1: "Director",
        icon1: <Table size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    }

]