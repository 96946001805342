import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { Minus } from 'react-feather';

export default function MultiInput({
  isDisplay,
  i,
  singleOptionCount,
  setSingleOptionCount,
  setTempSingleOption,
  tempSingleOption,
  removeFunc,
  removeOneOption1

}) {
  const [fieldValue, setFieldValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      const { value } = event.detail;
      console.log(`Received Message: ${value}`);
      setFieldValue(value);
      setIsChecked(value);
    };

    const eventName = `single_${i}`;
    console.log(eventName);
    document.addEventListener(eventName, handleCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleCustomEvent);
    };
  }, [i]);

  const singleRadioDataHandler = (e, i) => {
    e.preventDefault();
    const { value } = e.target;
    const eventName = `single_${i}`;
    console.log("cint" + eventName);
    const customEvent = new CustomEvent(eventName, {
      detail: { value },
    });
    document.dispatchEvent(customEvent);
    removeFunc()
  };

  const optionIncrementHandler = (e) => {
    e.preventDefault();
    if (singleOptionCount.length < 10) {
      const nextData = `seq${singleOptionCount.length + 1}`;
      setSingleOptionCount([...singleOptionCount, singleOptionCount.length + 1]);
      setTempSingleOption({ ...tempSingleOption, [nextData]: '' });
    }
  };

  return (
    <>
      <div
        className={isDisplay ? 'form-control d-flex my-2 position-relative' : 'd-flex my-2 position-relative form-control hidebordertext hide-text'}
        key={i}
      >
        <Checkbox defaultChecked disabled={!isDisplay} />
        <input
          id={i}
          type="text"

          className="form-control"
          style={{ border: 'none' }}
          placeholder={isDisplay ? "Type the ‘Option’ here.." : "Option"}
          name={`seq${i}`}
          value={fieldValue}
          onChange={(e) => singleRadioDataHandler(e, i)}
          readOnly={!isDisplay}
          maxLength={32}
        />
        {isDisplay && <div>  {
          i >= 2 && (
            <div className='input-minus-text'>
              <div
                style={{ cursor: 'pointer' }}
                className=""
                role="button"
                onClick={() => removeOneOption1()}
              ><Minus style={{ marginTop: '11px', color: '#81c14b' }} /></div>
            </div>
          )
        }</div>}

      </div>

    </>
  );
}
