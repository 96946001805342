import { Fragment, useState } from 'react'
import { BellOff, EyeOff,Eye, Link2, MoreHorizontal, Navigation, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { Col, Nav, NavItem, Progress, Modal, ModalBody, ModalHeader,ModalFooter, Row, TabContent, TabPane,Button } from 'reactstrap'
import Union from '../../profile-components/Img/Union.svg'
import PollBottomAnalatycs from './PollBottomAnalatycs'
import { getTextPostsAction,deletePostAction,postShowAction,hideAllPostsFromTimelineAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useEffect } from 'react'
import { sweetAlertConfirmation } from "../../group-components/AllGroupsSections/SweetAlert";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import {getAllLikesByPostIdAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction'


const PollCenterComponent = (props) => {
    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    let dispatch = useDispatch();
    const [refresh,setRefresh]=useState(false);
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    const [error,setError]=useState('')
    const [pollData,setPollData]=useState([])
    const [pollId1,setPollId1] = useState([])
    const [deleteOpen,setDeleteOpen]=useState(false)
    const [loading,setLoading]=useState(false)
    const [liked, setLiked] = useState(false);
    

    useEffect(()=>{
        const fetchData = async () => {
        if(props?.pollId){
            setLoading(true)
          try {
              if (user) {
                  const response = await axios.post(
                      `${process.env.REACT_APP_IPURL}/post/getPostById`,
                      {postId:props?.pollId, userId:userId},
                      { headers: { Authorization: `Bearer ${user.token}` } }
                  );
                  setPollData(response.data.data.successResult);
                  setLoading(false);
                  // sweetAlertConfirmation("Successfully Followed")
              } else {
                setError('no user found');
                setLoading(false);
              }
          } catch (error) {
            setLoading(false);
            setError(error);
          }
         }else if(props?.pollId==undefined){
            setLoading(false);
            setPollData([]);
         }else{
            setLoading(false);
            setPollData([])
         }
        }
    
      fetchData();
      },[props?.pollId,liked])

    //   console.log("result data=======================================",pollData)

    const toggleDelete =(e,postId)=>{
        e.preventDefault();
        e.stopPropagation();
        setPollId1(postId);
        setDeleteOpen(true);
    }

    const toggleDeleteOpen=()=>{
        setPollId1([]);
        setDeleteOpen(false);
    }

    const deleteAllPoll=(e)=>{
        
            e.stopPropagation();
            e.preventDefault();
            dispatch(deletePostAction([pollId1]));
            setDeleteOpen(false);
            setPollId1([]);
            sweetAlertConfirmation('Successfully deleted');
        
    }

    const postCopyLink = async (e,id) => {
        e.preventDefault();
        const newCopyLink = `${process.env.REACT_APP_IPURL}/MyPoll/${id}`;
        // setCopyLink(newCopyLink);
        await navigator.clipboard.writeText(newCopyLink);
        sweetAlertConfirmation(`Link Copied`);
      };
   
    // const searchedArticleData = resultAllPollPosts?.filter(item => item?.caption.toLowerCase().includes(pollSearch.pollSearch.pollSearch.toLowerCase()));

    const hidePost = (e,id) => {
        e.preventDefault();
        e.stopPropagation();
        setRefresh(!refresh);
        dispatch(hideAllPostsFromTimelineAction(id))
        sweetAlertConfirmation("successfully hidden from timeline")
    }

    const showHiddenPost = (e,id) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(postShowAction(id))
        setRefresh(!refresh);
        sweetAlertConfirmation("successfully showed to timeline")
    }

    const allLikesByPostId = useSelector((state) => {
        return state.getAllLikesByPostIdData;
      });
      const {
        loading: loadingallLikes,
        error: errorallLikes,
        result: allLikes,
      } = allLikesByPostId;

    useEffect(() => {
        if (pollData && pollData.length > 0) {
            dispatch(getAllLikesByPostIdAction(pollData[0].postId));
        }
    }, [pollData, dispatch,liked]);

    const state = allLikes
    ?.map((like) => like?.userId)
    .includes(userId);

    const setLike= async (e,postId)=>{
        e.stopPropagation();
        e.preventDefault();
        const reactionId = "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305";
        setLoading(true);
      
        try {
          let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        const userId = user.id;
    
        const userHasLiked = allLikes?.some((like) => {
          return like?.userId === userId;
        });
    
        const requestData = {
          postId:postId,
          userId:userId,
        };
    
        if (!userHasLiked) {
          requestData.reactionId = reactionId;
        }
      
          const response = await axios.post(`${process.env.REACT_APP_IPURL}/post/setLike`, requestData, {
            headers: { Authorization: `Bearer ${user?.token}` }
          });
      
          if (response.status === 200) {
            setLiked(!liked);
            console.log("Like status updated successfully", response.data);
          } else {
            console.error("Failed to update like status", response.data);
          }
        } catch (error) {
          console.error("Error in API call", error);
        } finally {
          setLoading(false);
        }
      }

      const doShare =(e)=>{
        e.preventDefault();
      }

    // console.log("pollid============================================",pollData)

    return (
        <Fragment>
            {pollData?.length>0 ?(
                pollData?.map((data) => (
                    <>
                        <div style={{ boxShadow: '0 -2px 5px 0 rgba(129,193,75,0.1)' }}>
                            <div className='d-flex align-items-center p-3' >
                                <div className='mr-auto d-flex align-items-start'>
                                    <img src={Union} alt="poll" />
                                    <div className='ml-2 '>
                                        <h4>{data?.caption}</h4>
                                        <p className='pt-2'>Total Participant: &nbsp; {data.totalVotesOnPoll}</p>
                                    </div>
                                </div>
                                <div className='PollMoreMenu'>
                                    <MoreHorizontal data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Poll_option" style={{width:"30%"}}>
                                        <ul>
                                            <li>{data?.hidden===1 ?
                                                <Link onClick={(e)=>showHiddenPost(e,data?.postId)}><Eye size={15} color="#979797" /> &nbsp;Show to timeline</Link>:
                                                <Link onClick={(e) =>hidePost(e,data?.postId)}><EyeOff size={15} color="#979797" /> &nbsp;Hide from timeline</Link>
                                                }
                                            </li>
                                            <li>
                                                <Link onClick={(e)=>toggleDelete(e)}><Trash2 size={15} color="#979797" />&nbsp;Delete </Link>
                                            </li>
                                            <li>
                                                <Link onClick={(e)=>e.preventDefault()}><Navigation size={15} color="#979797" />&nbsp;Share</Link>
                                            </li>
                                            <li>
                                                <Link onClick={(e) => {
                                                    postCopyLink(e,data?.postId);
                                                    }}><Link2 size={15} color="#979797" /> &nbsp;Copy Link </Link>
                                            </li>
                                            {/* <li>
                                                <Link to="#"><BellOff size={15} color="#979797" /> &nbsp;Turn off notification   </Link>
                                            </li> */}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className='pb-3  ml-3 mr-3'>
                                {
                                    data?.pollOptions?.map((option) => {
                                        const totalVotes = data?.pollOptions.reduce((acc, curr) => acc + curr.totalVotesOnOption, 0);
                                        const percentage = totalVotes > 0 ? (option.totalVotesOnOption / totalVotes) * 100 : 0;
                                        if (props?.currentStatus === 'scheduled') {
                                            return (
                                                <div key={option?.optionText}>
                                                    <label className='d-flex' style={{ marginTop: '12px', marginLeft: '12px' }}>{option.optionText}: 0%</label>
                                                </div>
                                            );
                                        }

                                        const progressStyle = props?.currentStatus === 'completed' ? { backgroundColor: '#909090' } : {};
                        
                                        return (
                                            <div key={option?.optionText}>
                                                <Progress 
                                                    className="Progress_Bar_Poll mt-3" 
                                                    value={percentage} 
                                                    style={progressStyle}
                                                >
                                                    <span className='Progress_Rating'>{`${percentage.toFixed(2)}%`}</span>
                                                </Progress>
                                                <label className='d-flex' style={{ marginTop: '-28px', marginLeft: '12px' }}>{option.optionText}</label>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        <div className="post-panel mb-0">
                        <div className="post-wrapper">
                          <div className="post-details">
                            <div className="like-panel">
                              <div className="left-emoji">
                                <h6>{data.likesCount} Likes</h6>
                              </div>
                              <div className="right-stats">
                                <ul>
                                  <li>
                                    <span
                                      style={{ filter: "contrast(150%)" }}
                                      className="d-flex align-items-center"
                                    >
                                        {data?.sharesCount} &nbsp;{" "}
                                       &nbsp;
                                      <p className="text-muted">Share</p>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="post-react">
                              <ul>
                                <li>
                                  <Link >
                                    <div 
                                    onClick={(e)=>setLike(e,data.postId)}
                                      style={{
                                        backgroundColor: state ? "#81c14b" : "",
                                      }}
                                      className="post-btn-cust"
                                    >
                                      <img 
                                        style={{ filter: "contrast(200%)" }}
                                        src="/assets/images/like1.png"
                                        alt="like"
                                      />
                                    </div>
                                    <span style={{ filter: "contrast(150%)" }}>
                                      {" "}
                                      like
                                    </span>
                                  </Link>
                                </li>
                                <li
                                  data-bs-dismiss="modal"
                                >
                                  <Link onClick={(e)=>doShare(e)}>
                                    <div className="post-btn-cust ">
                                      <img
                                        style={{ filter: "contrast(200%)" }}
                                        src="/assets/images/share.png"
                                      />
                                    </div>
                                    <span style={{ filter: "contrast(150%)" }}>
                                      share
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <PollBottomAnalatycs data={data||[]} currentStatus={props?.currentStatus}/>
                    </>
                ))
            ): loading ? (
                <>
                <br/><br/>
                <Spinner
                    animation='border'
                    role='status'
                    style={{
                        width: '100px',
                        height: '100px',
                        margin: 'auto',
                        display: 'block',
                        color: '#81C14B'
                    }}
                    >
                 <span className='sr-only'>Loading...</span>
                </Spinner>
                <br/><br/>
                </>
            ):(
                
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '15vh', // Adjust the height as needed to fill the available space
                    width: '100%' // Ensure it takes the full width of the container
                  }}>
                  <h2 style={{ textAlign: 'center' }}>No Poll available</h2>
                </div>
            )}
            <div className="vertically-centered-modal">
                {deleteOpen !== false && (
                    <Modal
                    isOpen={deleteOpen}
                    toggle={toggleDeleteOpen}
                    className="modal-dialog-centered"
                    >
                    <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                        <h4>Delete Poll</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-center modalHeading_Section">
                        <p>Do you really want to delete all the selected Poll ?</p>
                        </div>
                        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                        <small>if</small> YES! <small>please click on ok</small>
                        </h1>
                        <div className="d-flex justify-content-center  mt-4">
                        <Button onClick={toggleDeleteOpen} outline>
                            Cancel
                        </Button>
                        <Button
                            onClick={(e)=>deleteAllPoll(e)}
                            color="primary"
                            className="ml-3 "
                        >
                            OK
                        </Button>
                        </div>
                    </ModalBody>
                    </Modal>
                )}
            </div>
            
            {/* {====================anatytics sections===================} */}

        </Fragment>
    )
}

export default PollCenterComponent
