// Category List when user will create new group
export const GET_ALL_GROUP_CATEGORY = "GET_ALL_GROUP_CATEGORY";

// Use to create new group
export const GET_ALL_CREATE_GROUP = "GET_ALL_CREATE_GROUP";


// get all biz category
export const GET_BIZ_CATEGORY = "GET_BIZ_CATEGORY";

// get biz sub category
export const GET_BIZ_SUBCATEGORY = "GET_BIZ_SUBCATEGORY";


// Groups
export const CREATE_GROUP = "CREATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const GET_ALL_GROUPS_BY_USER_ID = "GET_ALL_GROUPS_BY_USER_ID";
export const GET_ALL_USER_GROUPS = "GET_ALL_USER_GROUPS";

export const GET_ALL_GROUPS_MAINPAGE = "GET_ALL_GROUPS_MAINPAGE";
export const GET_ALL_GROUPS_MAINPAGE_SUCCESS = "GET_ALL_GROUPS_MAINPAGE_SUCCESS";
export const GET_ALL_GROUPS_MAINPAGE_ERROR = "GET_ALL_GROUPS_MAINPAGE_ERROR";

export const GET_ALL_GROUPS_JOINED_GROUP= "GET_ALL_GROUPS_JOINED_GROUP";
export const GET_ALL_GROUPS_JOINED_GROUP_SUCCESS = "GET_ALL_GROUPS_JOINED_GROUP_SUCCESS";
export const GET_ALL_GROUPS_JOINED_GROUP_ERROR = "GET_ALL_GROUPS_JOINED_GROUP_ERROR";

export const GET_ALL_GROUPS_INVITATION= "GET_ALL_GROUPS_INVITATION";
export const GET_ALL_GROUPS_INVITATION_SUCCESS = "GET_ALL_GROUPS_INVITATION_SUCCESS";
export const GET_ALL_GROUPS_INVITATION_ERROR = "GET_ALL_GROUPS_INVITATION_ERROR";

export const GET_ALL_SUGGESTED_GROUP= "GET_ALL_SUGGESTED_GROUP";
export const GET_ALL_SUGGESTED_GROUP_SUCCESS = "GET_ALL_SUGGESTED_GROUP_SUCCESS";
export const GET_ALL_SUGGESTED_GROUP_ERROR = "GET_ALL_SUGGESTED_GROUP_ERROR";

export const GET_ALL_REPORT_OPTION_GROUP= "GET_ALL_REPORT_OPTION_GROUP";
export const GET_ALL_REPORT_OPTION_GROUP_SUCCESS = "GET_ALL_REPORT_OPTION_GROUP_SUCCESS";
export const GET_ALL_REPORT_OPTION_GROUP_ERROR = "GET_ALL_REPORT_OPTION_GROUP_ERROR";

export const POST_REPORT_TO_GROUP= "POST_REPORT_TO_GROUP";
export const POST_REPORT_TO_GROUP_SUCCESS= "POST_REPORT_TO_GROUP_SUCCESS";
export const POST_REPORT_TO_GROUP_ERROR= "POST_REPORT_TO_GROUP_ERROR";

export const GET_GROUP_BY_ID= "GET_GROUP_BY_ID";
export const GET_GROUP_BY_ID_SUCCESS= "GET_GROUP_BY_ID_SUCCESS";
export const GET_GROUP_BY_ID_ERROR= "GET_GROUP_BY_ID_ERROR";

export const CREATE_GROUP_POST= "CREATE_GROUP_POST";
export const CREATE_GROUP_POST_SUCCESS= "CREATE_GROUP_POST_SUCCESS";
export const CREATE_GROUP_POST_ERROR= "CREATE_GROUP_POST_ERROR";

export const GET_TRENDING_TAGS_TO_GROUP= "GET_TRENDING_TAGS_TO_GROUP";
export const GET_TRENDING_TAGS_TO_GROUP_SUCCESS= "GET_TRENDING_TAGS_TO_GROUP_SUCCESS";
export const GET_TRENDING_TAGS_TO_GROUP_ERROR= "GET_TRENDING_TAGS_TO_GROUP_ERROR";

export const GET_All_GROUP_ADMIN_AND_MANAGERS= "GET_All_GROUP_ADMIN_AND_MANAGERS";
export const GET_All_GROUP_ADMIN_AND_MANAGERS_SUCCESS= "GET_All_GROUP_ADMIN_AND_MANAGERS_SUCCESS";
export const GET_All_GROUP_ADMIN_AND_MANAGERS_ERROR= "GET_All_GROUP_ADMIN_AND_MANAGERS_ERROR";

export const GET_All_ABOUT_GROUP= "GET_All_ABOUT_GROUP";
export const GET_All_ABOUT_GROUP_SUCCESS= "GET_All_ABOUT_GROUP_SUCCESS";
export const GET_All_ABOUT_GROUP_ERROR= "GET_All_ABOUT_GROUP_ERROR";

export const GET_All_ABOUT_GROUP_MEMBERS= "GET_All_ABOUT_GROUP_MEMBERS";
export const GET_All_ABOUT_GROUP_MEMBERS_SUCCESS= "GET_All_ABOUT_GROUP_MEMBERS_SUCCESS";
export const GET_All_ABOUT_GROUP_MEMBERS_ERROR= "GET_All_ABOUT_GROUP_MEMBERS_ERROR";

export const POST_PIN_FROM_GROUP= "POST_PIN_FROM_GROUP";
export const POST_PIN_FROM_GROUP_SUCCESS= "POST_PIN_FROM_GROUP_SUCCESS";
export const POST_PIN_FROM_GROUP_ERROR= "POST_PIN_FROM_GROUP_ERROR";

export const GET_PIN_FROM_GROUP= "GET_POST_PIN_FROM_GROUP";
export const GET_PIN_FROM_GROUP_SUCCESS= "GET_POST_PIN_FROM_GROUP_SUCCESS";
export const GET_PIN_FROM_GROUP_ERROR= "GET_POST_PIN_FROM_GROUP_ERROR";

export const GET_BLOCKED_GROUP_USERS= "GET_BLOCKED_GROUP_USERS";
export const GET_BLOCKED_GROUP_USERS_SUCCESS= "GET_BLOCKED_GROUP_USERS_SUCCESS";
export const GET_BLOCKED_GROUP_USERS_ERROR= "GET_BLOCKED_GROUP_USERS_ERROR";

export const GET_JOINED_REQUEST_FROM_GROUP= "GET_JOINED_REQUEST_FROM_GROUP";
export const GET_JOINED_REQUEST_FROM_GROUP_SUCCESS= "GET_JOINED_REQUEST_FROM_GROUP_SUCCESS";
export const GET_JOINED_REQUEST_FROM_GROUP_ERROR= "GET_JOINED_REQUEST_FROM_GROUP_ERROR";

export const GET_POST_REQUEST_FROM_GROUP= "GET_POST_REQUEST_FROM_GROUP";
export const GET_POST_REQUEST_FROM_GROUP_SUCCESS= "GET_POST_REQUEST_FROM_GROUP_SUCCESS";
export const GET_POST_REQUEST_FROM_GROUP_ERROR= "GET_POST_REQUEST_FROM_GROUP_ERROR";

export const GET_ALL_CATEGORY_FROM_GROUP= "GET_ALL_CATEGORY_FROM_GROUP";
export const GET_ALL_CATEGORY_FROM_GROUP_SUCCESS= "GET_ALL_CATEGORY_FROM_GROUP_SUCCESS";
export const GET_ALL_CATEGORY_FROM_GROUP_ERROR= "GET_ALL_CATEGORY_FROM_GROUP_ERROR";

export const GET_INVITED_PEOPLE_TO_MODAL= "GET_INVITED_PEOPLE_TO_MODAL";
export const GET_INVITED_PEOPLE_TO_MODAL_SUCCESS= "GET_INVITED_PEOPLE_TO_MODAL_SUCCESS";
export const GET_INVITED_PEOPLE_TO_MODAL_ERROR= "GET_INVITED_PEOPLE_TO_MODAL_ERROR";

export const POST_INVITE_JOIN_OWNED_GROUP= "POST_INVITE_JOIN_OWNED_GROUP";
export const POST_INVITE_JOIN_OWNED_GROUP_SUCCESS= "POST_INVITE_JOIN_OWNED_GROUP_SUCCESS";
export const POST_INVITE_JOIN_OWNED_GROUP_ERROR= "POST_INVITE_JOIN_OWNED_GROUP_ERROR";

export const UN_FOLLOW_FROM_JOINED_GROUP= "UN_FOLLOW_FROM_JOINED_GROUP";
export const UN_FOLLOW_FROM_JOINED_GROUP_SUCCESS= "UN_FOLLOW_FROM_JOINED_GROUP_SUCCESS";
export const UN_FOLLOW_FROM_JOINED_GROUP_ERROR= "UN_FOLLOW_FROM_JOINED_GROUP_ERROR";

export const FOLLOW_GROUP_FROM_GROUP_INVITATION= "FOLLOW_GROUP_FROM_GROUP_INVITATION";
export const FOLLOW_GROUP_FROM_GROUP_INVITATION_SUCCESS= "FOLLOW_GROUP_FROM_GROUP_INVITATION_SUCCESS";
export const FOLLOW_GROUP_FROM_GROUP_INVITATION_ERROR= "FOLLOW_GROUP_FROM_GROUP_INVITATION_ERROR";

export const DECLINE_GROUP_FROM_GROUP_INVITATION= "DECLINE_GROUP_FROM_GROUP_INVITATION";
export const DECLINE_GROUP_FROM_GROUP_INVITATION_SUCCESS= "DECLINE_GROUP_FROM_GROUP_INVITATION_SUCCESS";
export const DECLINE_GROUP_FROM_GROUP_INVITATION_ERROR= "DECLINE_GROUP_FROM_GROUP_INVITATION_ERROR";

export const CREATE_NEW_GROUP= "CREATE_NEW_GROUP";
export const CREATE_NEW_GROUP_SUCCESS= "CREATE_NEW_GROUP_SUCCESS";
export const CREATE_NEW_GROUP_ERROR= "CREATE_NEW_GROUP_ERROR";


export const JOIN_SUGGESTED_GROUP= "JOIN_SUGGESTED_GROUP";
export const JOIN_SUGGESTED_GROUP_SUCCESS= "JOIN_SUGGESTED_GROUP_SUCCESS";
export const JOIN_SUGGESTED_GROUP_ERROR= "JOIN_SUGGESTED_GROUP_ERROR";

export const DELETE_GROUP_BY_PASSWORD= "DELETE_GROUP_BY_PASSWORD";
export const DELETE_GROUP_BY_PASSWORD_SUCCESS= "DELETE_GROUP_BY_PASSWORD_SUCCESS";
export const DELETE_GROUP_BY_PASSWORD_ERROR= "DELETE_GROUP_BY_PASSWORD_ERROR";

export const DELETE_GROUP_BY_USER= "DELETE_GROUP_BY_USER";
export const DELETE_GROUP_BY_USER_SUCCESS= "DELETE_GROUP_BY_USER_SUCCESS";
export const DELETE_GROUP_BY_USER_ERROR= "DELETE_GROUP_BY_USER_ERROR";

export const GROUP_SETTINGS_ALL_IN_ONE= "GROUP_SETTINGS_ALL_IN_ONE";
export const GROUP_SETTINGS_ALL_IN_ONE_SUCCESS= "GROUP_SETTINGS_ALL_IN_ONE_SUCCESS";
export const GROUP_SETTINGS_ALL_IN_ONE_ERROR= "GROUP_SETTINGS_ALL_IN_ONE_ERROR";

export const GROUP_REVIEW_POST= "GROUP_REVIEW_POST";
export const GROUP_REVIEW_POST_SUCCESS= "GROUP_REVIEW_POST_SUCCESS";
export const GROUP_REVIEW_POST_ERROR= "GROUP_REVIEW_POST_ERROR";

export const GROUP_REVIEW_MEMBER= "GROUP_REVIEW_MEMBER";
export const GROUP_REVIEW_MEMBER_SUCCESS= "GROUP_REVIEW_MEMBER_SUCCESS";
export const GROUP_REVIEW_MEMBER_ERROR= "GROUP_REVIEW_MEMBER_ERROR";

export const GROUP_SHARED_TO_STORIES= "GROUP_SHARED_TO_STORIES";
export const GROUP_SHARED_TO_STORIES_SUCCESS= "GROUP_SHARED_TO_STORIES_SUCCESS";
export const GROUP_SHARED_TO_STORIES_ERROR= "GROUP_SHARED_TO_STORIES_ERROR";

export const UPDATE_GROUP_POST= "UPDATE_GROUP_POST";
export const UPDATE_GROUP_POST_SUCCESS= "UPDATE_GROUP_POST_SUCCESS";
export const UPDATE_GROUP_POST_ERROR= "UPDATE_GROUP_POST_ERROR";



export const GROUP_GET_GENERAL_SETTINGS= "GROUP_GET_GENERAL_SETTINGS";
export const GROUP_GET_GENERAL_SETTINGS_SUCCESS= "GROUP_GET_GENERAL_SETTINGS_SUCCESS";
export const GROUP_GET_GENERAL_SETTINGS_ERROR= "GROUP_GET_GENERAL_SETTINGS_ERROR";

export const GROUP_SOCIOMEE_NOTIFICATION_SETTINGS= "GROUP_SOCIOMEE_NOTIFICATION_SETTINGS";
export const GROUP_SOCIOMEE_NOTIFICATION_SUCCESS= "GROUP_SOCIOMEE_NOTIFICATION_SUCCESS";
export const GROUP_SOCIOMEE_NOTIFICATION_ERROR= "GROUP_SOCIOMEE_NOTIFICATION_ERROR";

export const SET_NOTIFICATION_GENERAL_SETTINGS= "SET_NOTIFICATION_GENERAL_SETTINGS";
export const SET_NOTIFICATION_GENERAL_SETTINGS_SUCCESS= "SET_NOTIFICATION_GENERAL_SETTINGS_SUCCESS";
export const SET_NOTIFICATION_GENERAL_SETTINGS_ERROR= "SET_NOTIFICATION_GENERAL_SETTINGS_ERROR";

export const SET_NOTIFICATION_GENERAL_TAB= "SET_NOTIFICATION_GENERAL_TAB";
export const SET_NOTIFICATION_GENERAL_TAB_SUCCESS= "SET_NOTIFICATION_GENERAL_TAB_SUCCESS";
export const SET_NOTIFICATION_GENERAL_TAB_ERROR= "SET_NOTIFICATION_GENERAL_TAB_ERROR";

export const GROUP_ACTIVITY_HISTORY= "GROUP_ACTIVITY_HISTORY";
export const GROUP_ACTIVITY_HISTORY_SUCCESS= "GROUP_ACTIVITY_HISTORY_SUCCESS";
export const GROUP_ACTIVITY_HISTORY_ERROR= "GROUP_ACTIVITY_HISTORY_ERROR";
export const GROUP_ACTIVITY_HISTORY_UN_SUCCESS= "GROUP_ACTIVITY_HISTORY_UN_SUCCESS";

export const POST_GROUP_ROLE= "POST_GROUP_ROLE";
export const POST_GROUP_ROLE_SUCCESS= "POST_GROUP_ROLE_SUCCESS";
export const POST_GROUP_ROLE_ERROR= "POST_GROUP_ROLE_ERROR";
export const POST_GROUP_ROLE_UN_SUCCESS= "POST_GROUP_ROLE_UN_SUCCESS";

export const REMOVE_GROUP_ROLE= "REMOVE_GROUP_ROLE";
export const REMOVE_GROUP_ROLE_SUCCESS= "REMOVE_GROUP_ROLE_SUCCESS";
export const REMOVE_GROUP_ROLE_ERROR= "REMOVE_GROUP_ROLE_ERROR";

export const POST_UN_BLOCK_USER= "POST_UN_BLOCK_USER";
export const POST_UN_BLOCK_USER_SUCCESS= "POST_UN_BLOCK_USER_SUCCESS";
export const POST_UN_BLOCK_USER_ERROR= "POST_UN_BLOCK_USER_ERROR";


export const GET_SINGLE_GROUP = "GET_SINGLE_GROUP";
export const GET_GROUP_ADMINISTRATER = "GET_GROUP_ADMINISTRATER";


// main search
export const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";