import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    getUserReportOption: [],
    loading: true,
};

 const GetUserReportOptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_REPORT_OPTION:
            return {
                ...state,
                getUserReportOption: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default GetUserReportOptionReducer;