import React, { useState } from 'react'
import { Fragment } from 'react'
import "./MpNFT.css"
import "./MpNFT_MediaQuery.css"
import MpHeader from './MpHeader/MpHeader'
import MpNFtTab from './Components/ExploreNTF'
import { Container, TabContent, TabPane } from 'reactstrap'
import ExploreNTF from './Components/ExploreNTF'
import NftDrop from './Components/NftDrop'
import CretionNft from './Components/CretionNft'
import NFTfooter from './Components/NFTfooter'


const MpNFT = (props) => {
    const [currentActiveTab, setCurrentActiveTab] = useState('1')
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)
        console.warn(tab)
    }
    //==================Main_Return_Function =================//
    return (
        <Fragment>
            <MpHeader currentActiveTab={currentActiveTab} toggle={toggle} />
            <div className='page-body' style={{ marginLeft: "20px", marginRight: "20px" }}>
                <Container fluid>
                    <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                            <ExploreNTF />
                        </TabPane>
                        <TabPane tabId="2">
                            Stats
                        </TabPane>
                        <TabPane tabId="3">
                            <NftDrop />
                        </TabPane>
                        <TabPane tabId="4">
                            Activity
                        </TabPane>
                        <TabPane tabId="5">
                           <CretionNft/>
                        </TabPane>
                    </TabContent>
                </Container>
            </div>
            <NFTfooter/>
        </Fragment>
    )
}

export default MpNFT