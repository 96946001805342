import swal from 'sweetalert';

    export function SweetErrorAlert(message) {
        swal({
            title: message,
            icon: "error",
            button:'OK',
            background: 'white',
            timer: 1600,            
          }) 
      }



