import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { FollowerData, ViewedData, LikedData, CommentedDetails } from '../MarketPlaceArray/MarketPlaceCategory';
import { Circle, X } from 'react-feather';
import { Tab, Tabs } from '@mui/material';
import { getProductShareCommentViewPeopleList, followUnfollowUser } from "../../../Services/Actions/MarketPlace/getAllProductsAction";
import { useNavigate } from 'react-router-dom';

const LikeShareCommentDisplay = (props) => {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const dummyData = [LikedData, CommentedDetails, FollowerData, ViewedData];
    const [active, setActive] = useState(0);
    const [peopleListData, setPeopleListData] = useState([]);
    const [peopleCount, setPeopleCount] = useState(0);
    const marketPlaceId = props?.marketPlaceId;
    const [refresh, setRefresh] = useState(false)
    const navigate = useNavigate()


    
    const TabsViewModal = ["Viewed", "Liked", "Commented", "Shared"];

    const fetchData = async (type) => {
        try {
            const body = {
                marketPlaceId: marketPlaceId,
                type: type.toLowerCase(),
                pageIndex: 0,
                pageSize: 20,
            };
            const data = await getProductShareCommentViewPeopleList(body);
            setPeopleListData(data?.result);
            setPeopleCount(data?.resultCount);
            // console.log("API response data:", data);
        } catch (error) {
            console.error(error);
        }
    };


    //reload page load
    useEffect(() => {
        if (props.modal && marketPlaceId) {
            fetchData(TabsViewModal[active]);
        }
    }, [props.modal, marketPlaceId,refresh]);




    //===========>>handling toggle modal<<==============
    const toggle = (e, newTab) => {
        if (marketPlaceId) {
            setActive(newTab);
            fetchData(TabsViewModal[newTab]);
        }
    };


    // ==================>> hadling follow unfollow user <<< =================
    const onfollowStatusHandle = async(e,otherUserId,status,isPrivate) => {
        e.stopPropagation();
        e.preventDefault(); 
        const  isFollowed = status ==="notFollowing"?1:0;
        const body = {
            followingToId: otherUserId,
            isFollowed: isFollowed,
            isPrivate:isPrivate
        }
      const followUnfollowResult =  await followUnfollowUser(body)
      setRefresh(prev => !prev);

    };



    //================>>> handling to navigate the use in user profile <<<=================
    const navigateUserProfile = (e,userId) =>{
        e.stopPropagation();
        let type = (userId !== user?.id) ? "viewer" : "creater";
        navigate(`/MyTimeline?userId=${userId}&type=${type}`)

    } 





    return (
        <Fragment>
            <Modal scrollable isOpen={props.modal} toggle={props.toggle} className="modal-dialog-centered">
                <div className='w-100 border-bottom'>
                    <div className='mx-3 py-4 Profession_Modal_section'>
                        <h4>Post Details</h4>
                        <p onClick={props.toggle}><X size={15} /></p>
                    </div>
                </div>

                <div className="AboutModal_Main_section">
                    <Tabs
                        value={active}
                        onChange={toggle}
                        variant="scrollable"
                        scrollButtons={true}
                        aria-label="scrollable prevent tabs example"
                        TabIndicatorProps={{ style: { background: "#81c14b" } }}
                    >
                        {TabsViewModal.map((tabs, index) => (
                            <Tab
                                key={index}
                                sx={{
                                    textTransform: "capitalize",
                                    fontFamily: "Montserrat",
                                    '&.Mui-selected': {
                                        color: "#81c14b",
                                    },
                                }}
                                label={tabs}
                            />
                        ))}
                    </Tabs>
                </div>

                <div className="MpViwedCountings p-2 px-4">
                    <h6 className='Mp_Color80_Font5'>{TabsViewModal[active]} by {peopleCount} </h6>
                </div>

                <div className='Modal_scroll_box p-2'>
                    {peopleListData.length > 0 ? (
                        peopleListData.map((data, index) => (
                            <div key={index} className="group-detail-page-blk">
                                <div className="MyEvents-memberslist-block">
                                    <div className="group-member-img-cont-blk" onClick={(e)=>{navigateUserProfile(e,data?.id)}}  style = {{cursor :"pointer"}}>
                                        <img src={data?.profileImageThumbnailUrl} alt="img" className='img-fluid rounded-circle' />
                                        <div className="member-cont-blk">
                                            <h4>{data?.fullName}</h4>
                                            <p className=''>{data?.userName}</p>
                                            <div className="d-flex align-items-center mt-1">
                                                <div className='d-flex'>
                                                    <h4>{data?.totalFollowers}</h4>&nbsp;
                                                    <span>Followers</span>
                                                </div>
                                                &nbsp;&nbsp;<Circle fill='#C4C4C4' color='#C4C4C4' size={6} />&nbsp;&nbsp;
                                                {/* <div className='d-flex'>
                                                    <h4>{data?.totalPostPostedCount}</h4>&nbsp;
                                                    <span>Posts</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {user?.id !== data?.id && (
                                        <Button
                                            outline
                                            color="primary"
                                            className="btn-sm button-text w-25"
                                            cursor="pointer"
                                            onClick={(e)=>{onfollowStatusHandle(e,data?.id,data?.followingStatus,data?.isPrivate)}}
                                            disabled={false} >
                                            {data?.followingStatus === "notFollowing" ? "follow" : "unfollow"}
                                        </Button>
                                    )}

                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center">
                            <h3>No data available</h3>
                        </div>
                    )}
                </div>

            </Modal>
        </Fragment>
    );
};

export default LikeShareCommentDisplay;
