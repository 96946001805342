import React, { useEffect, useState } from 'react'
import { ChevronsRight, Link, Maximize2, Minimize2, MoreVertical, Send, X } from 'react-feather';
import { Button, Card, CardBody, CardFooter, CardImg, CardImgOverlay, Col, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap';
import UserPro from '../../../../../NewComponents/IMG/Adspreview.svg'
import UserAdPro from '../../../../../NewComponents/IMG/user2.png'
import s1 from '../../../../../NewComponents/IMG/s1.png'
import group2 from '../../../../../NewComponents/IMG/group2.png'
import group3 from '../../../../../NewComponents/IMG/group3.png'
import Story from '../../../../../NewComponents/IMG/Story.png'
import Market from '../../../../../NewComponents/IMG/Market.png'
import Market2 from '../../../../../NewComponents/IMG/Market2.png'
import Market1 from '../../../../../NewComponents/IMG/Market1.png'
import Groups from '../../../../../NewComponents/IMG/Groups.png'
import Frame1 from '../../../../../NewComponents/IMG/Frame1.png'
import Res from '../../../../../NewComponents/IMG/Res.png'
import Rec1 from '../../../../../NewComponents/IMG/Rec1.png'
import group5 from '../../../../../NewComponents/IMG/group5.png'
import Group6 from '../../../../../NewComponents/IMG/Group6.png'
import Shotzs1 from '../../../../../NewComponents/IMG/Shotzs1.png'
import ad from '../../../../../NewComponents/IMG/ad.png'

const PreviewMoreSection = ({ heading, imageUrl, subHeading, media, discriptions, imageDataUrl, imageGroupUrl, imageStoryUrl, imageBizUrl, setClicked, imageMarketUrl, imagePodcastUrl, imageEventUrl }) => {

    const [accord, setAccord] = useState(1);
    const [centeredModal, setCenteredModal] = useState(false)
    useEffect(() => {
        console.log("========>preview", imageDataUrl)
    }, [imageDataUrl])
    useEffect(() => {
        console.log("========>preview", imageGroupUrl)
    }, [imageGroupUrl, imageStoryUrl, imageBizUrl, imageMarketUrl, imagePodcastUrl, imageEventUrl])
    const Previewmodal = () => {
        return (
            <>
                <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-xl modal-dialog-centered'>
                    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Advanced preview</ModalHeader>
                    <ModalBody>
                        <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                            {/* Feed Card */}
                                            <Col xs='4' xl='4' >
                                                {/* onClick={() => { setAccord(1) }} */}
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="post-details">
                                                            <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt=''  style={{width: '100%', height: '200px', objectFit: 'cover'  }} />
                                                                <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-9px' }}>
                                                                    <h4 style={{
                                                                        fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}>{heading}</h4>

                                                                    {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '26px',
                                                                        left: '1px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '100%',
                                                                    }}>
                                                                        <p style={{
                                                                            fontSize: '10px',
                                                                            marginLeft: '3px',
                                                                            color: 'white',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}>{subHeading}</p></div>
                                                                </div>
                                                                {/* <div><p style={{
                                                                    fontSize: '10px', textWrap: 'nowrap', marginLeft: '3px', overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>{subHeading}</p></div> */}
                                                            </div>
                                                            <div className="detail-box">
                                                                <h4 className="small-preview-heading" style={{
                                                                    fontSize: '10px',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                                </h4>
                                                            </div>
                                                            <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*====================== Podcast Card================= */}
                                            {/* onClick={() => { setAccord(2) }} */}
                                            <Col xs='4' xl='4'  >
                                                <h3 className='p-heading ml-1'>Podcast</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1 ">
                                                    <Card style={{ borderRadius: '5px' }}>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />

                                                                    <div className="ml-1">
                                                                        <h6 className="sizingForTextheading" style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center sizingForText p-2">
                                                                <h6 className="sizingForTextsmall" style={{ fontSize: '12px', marginTop: '0.2rem' }}>Related products</h6>
                                                                <p className="text-info sizingForTextvsmall" style={{ fontSize: '12px', marginTop: '0.2rem' }}>View all</p>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <div className="d-flex p-2" style={{ margin: '-3px' }}>
                                                                <div className='shadow w-100'>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div style={{ position: 'absolute', top: '86px' }}>
                                                                        <img src={ad} alt="" />
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            {/* <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div> */}
                                                                            <h4 style={{
                                                                                fontSize: '10px',
                                                                                textWrap: 'nowrap',
                                                                                marginLeft: '3px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                marginTop: '-18px',
                                                                                color: 'white',
                                                                                padding: '0',
                                                                            }}>
                                                                                {heading && heading.length > 8 ? `${heading.substring(0, 8)}...` : heading}
                                                                            </h4>

                                                                        </h6>
                                                                        <div className="preview-detail-small" style={{ marginTop: '18px' }}>
                                                                            <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                                            <p className='small-preview-small preview-know' style={{
                                                                                fontSize: '10px', whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>Know more</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='shadow ml-2 w-100'>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div style={{ position: 'absolute', top: '86px' }}>
                                                                        <img src={ad} alt="" />
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <h4 style={{
                                                                                fontSize: '10px',
                                                                                textWrap: 'nowrap',
                                                                                marginLeft: '3px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                marginTop: '-18px',
                                                                                color: 'white',
                                                                                padding: '0',
                                                                            }}>
                                                                                {heading && heading.length > 8 ? `${heading.substring(0, 8)}...` : heading}
                                                                            </h4>

                                                                            {/* <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div> */}
                                                                        </h6>
                                                                        <div className="preview-detail-small" style={{ marginTop: '18px' }}>
                                                                            <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                                            <p className='small-preview-small preview-know' style={{
                                                                                fontSize: '10px', whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>Know more</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*================== Group Card=================*/}
                                            {/*  onClick={() => { setAccord(3) }} */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded  py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1" style={{ fontSize: '12px' }}><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow rounded-2 w-100">
                                                                    {/* <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div> */}
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all Groups <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert mt-3'>
                                            {/*==================MarketPlace Card===================== */}
                                            {/* onClick={() => { setAccord(5) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>MarketPlace</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1  shadow w-100">
                                                                    {/* <img src={UserPro} className='img-fluid' alt="cardImage" /> */}
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1  shadow w-100">
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=================Event Card================= */}
                                            {/* onClick={() => { setAccord(6) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning" style={{ fontSize: '12px' }}>Sponsored</p>
                                                                <h6 className="sizingForTextvsmall" style={{ fontSize: '12px' }}>Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=================Event Card end================= */}
                                            {/*=====================Biz Page Card=============== */}
                                            {/* onClick={() => { setAccord(4) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all BizPages <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=====================Biz Page Card end=============== */}
                                        </Row>
                                        <Row className='rowForrevert mt-4'>

                                            {/* <Col xs='4' xl='4' onClick={() => { setAccord(6) }}>
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                    </div>
                                                                    <img src={Res} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                    </div>
                                                                    <img src={Rec1} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning" style={{ fontSize: '12px' }}>Sponsored</p>
                                                                <h6 className="sizingForTextvsmall" style={{ fontSize: '12px' }}>Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col> */}
                                            {/*======================Stories Card================== */}
                                            {/* <Col xs='4' xl='4' onClick={() => { setAccord(7) }}> */}
                                            {/* <h3 className='p-heading ml-1'>Shotz</h3> */}
                                            <div className=" m-1">
                                                {/* className="border rounded py-4 m-1" */}
                                                {/* <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={Shotzs1} />
                                                 
                                                        <CardImgOverlay>
                                                            <div>
                                                                <Progress className='customeForprogressbar' value={25} />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' alt='' />
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>

                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall">Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay>
                                                    </Card> */}
                                            </div>
                                            {/* </Col> */}
                                            {/*==================Shotz=================== */}
                                            {/* onClick={() => { setAccord(8) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>Story</h3>
                                                <div className=" m-1">
                                                    <Card inverse className=''>
                                                        {/* <CardImg alt="" src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} className='w-100' style={{ height: '350px' }} /> */}
                                                        <div className='small-bg-mains'>
                                                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                                                <div className="post-wrapper col-grid-box">
                                                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                                        <div className="profile">
                                                                            <div className="media d-flex mb-1" >
                                                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                                </a>
                                                                                <div className="media-body ml-2">
                                                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>florida roxin</h6>
                                                                                    <h6><span className=" sponsored-text" style={{ color: 'black' }}>Sponsored</span></h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                                                <MoreVertical size={15} style={{ color: 'black' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post-details">
                                                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                            <img
                                                                                src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`}
                                                                                alt="" style={{width: '100%', height: '45.2vh', objectFit: 'cover'  }}
                                                                            />
                                                                        </div>
                                                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                                            <h4 style={{
                                                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>{heading}</h4>

                                                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '26px',
                                                                                left: '1px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                maxWidth: '100%',
                                                                            }}>
                                                                                <p style={{
                                                                                    fontSize: '10px',
                                                                                    marginLeft: '3px',
                                                                                    color: 'white',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                }}>{subHeading}</p></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
    return (
        <>
            <div className="col-lg-12">
                <Previewmodal />
                <Row className='rowForrevert'>
                    <Col xl='6'>
                        <div className="">
                            <img src="/assets/images/adIcon/preview-logo.png" alt="logo" />
                        </div>
                    </Col>
                    <Col xl='6'>
                        <div className='d-flex justify-content-end'>
                            {/* <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                                {centeredModal ? <Minimize2 size={15} /> : <Maximize2 size={15} />} */}
                            {/* <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview'}</span> */}
                            {/* <a href='/Ad/AdPreview'>
                                <div className='d-flex head-less-preview' style={{ color: 'black' }}>
    
                                    <Maximize2 size={18} className='mt-1' />
                                    <h4 className='ml-1 mt-1'>More Less1</h4>
                                </div></a> */}
                            <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                                {centeredModal ? <Minimize2 size={15} /> : <Maximize2 size={15} />}
                                <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview'}</span>
                            </Button>
                            {/* </Button> */}
                        </div>
                    </Col>
                </Row>
                {/* <div className="mobile-frame">
                    <div className='mobileAd-card border rounded p-1'>
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex m-1">
                                    <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                    <div className="ml-1">
                                        <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                        <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored1</h6>
                                    </div>
                                </div>
                                <div className="ml-1">
                                    <MoreVertical size={10} className='m-0' />
                                </div>
                            </div>
                        </div>
                        <img src={UserPro} className='img-fluid' alt="Card image" />
                        <div className="p-1">
                            <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                                <b>
                                    An adv is the promotion of a product, brand
                                </b>
                            </h6>
                            <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                                <Send size={10} />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* =================add new card ================= */}
                <div className="row">
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(1) }}>
                        <h3 className='p-heading'>Feeds</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-font-color iw-14"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg> */}
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' style={{width: '100%', height: '200px', objectFit: 'cover'  }} />
                                            <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-9px' }}>
                                                <h4 style={{
                                                    fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>{heading}</h4>
                                                {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '26px',
                                                    left: '1px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '100%',
                                                }}>
                                                    <p style={{
                                                        fontSize: '10px',
                                                        marginLeft: '3px',
                                                        color: 'white',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}>{subHeading}</p></div>
                                            </div>

                                        </div>

                                        <div className="detail-box">
                                            <h4 className="small-preview-heading" style={{
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                            </h4>
                                        </div>
                                        <div className="Why-this-ad d-flex justify-content-between m-1">
                                            <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                            <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <img src={s1} alt="" /> */}
                    </div>
                    {/* ================add new card end============= */}
                    {/* ====== second new card ===== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Groups</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Suggested Groups</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100 ">
                                                <div >
                                                    {/* small-preview-img*/}
                                                    {/* <img src={group2} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className=' rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading" >This award goes... {discriptions}</h4> */}
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>

                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="recomandation-display-block col-lg-6 p-1 ">
                                                <div className="" style={{width:'40px'}}>
                                                    <img src={group3} alt='' />
                                                </div>
                                                <div className="small-preview-more d-flex  justify-content-center align-item-center" style={{textWrap:'nowrap'}}>
                                                    visit all groups
                                                </div>
                                            </div> */}
                                            {/* <div className="recomandation-display-block col-lg-6 p-2 shadow h-100">
                                                <div className="">
                                                    <img src={group2} alt='' />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading">This award goes...</h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small'>Sponsored</p>
                                                        <p className='small-preview-small preview-know'>Know more</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="small-preview-more mt-1">
                                            <h6>visit all groups<ChevronsRight size={15} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ==============Story=========== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Story</h3>
                        <div className='small-bg-mains'>
                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                    <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <img
                                                src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`}
                                                alt="" style={{width: '100%',height: '44.2vh', objectFit: 'cover'  }}
                                            />
                                        </div>
                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                            <h4 style={{
                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{heading}</h4>

                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '26px',
                                                left: '1px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '100%',
                                            }}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    marginLeft: '3px',
                                                    color: 'white',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>{subHeading}</p></div>
                                        </div>
                                    </div>
                                    {/* <div className="detail-box">
                                        <h4 className="small-preview-heading" style={{
                                            fontSize: '10px',
                                            whiteSpace: 'nowrap',
                                            color:'white',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}>
                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                        </h4>
                                    </div> */}
                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* =====================bizpage=============== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>BizPages</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Suggested BizPages</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100">
                                                <div className="">

                                                    {/* <img src={group5} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className=' rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading">This award BizPages...</h4> */}
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="recomandation-display-block col-lg-6 p-2 shadow h-100">
                                                <div className="">
                                                  
                                                    <img src={group2} alt='' />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading">This award goes...</h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small'>Sponsored</p>
                                                        <p className='small-preview-small preview-know'>Know more</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="small-preview-more mt-1">
                                            <h6>visit all BizPages<ChevronsRight size={15} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =================marketplace========================= */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>MarketPlace</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Copenhagen, Denmark</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100">
                                                <div className="">
                                                    {/* small-preview-img*/}
                                                    {/* <img src={Market} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                    <h4 className="small-preview-heading" style={{
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}>
                                                        {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                    </h4>
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading">Wooden Horse Sculpt...</h4> */}
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="recomandation-display-block col-lg-6 p-1 ">
                                                <div className="" style={{width:'40px'}}>
                                                    <img src={group3} alt='' />
                                                </div>
                                                <div className="small-preview-more d-flex  justify-content-center align-item-center" style={{textWrap:'nowrap'}}>
                                                    visit all groups
                                                </div>
                                            </div> */}
                                            {/* <div className="recomandation-display-block col-lg-6 p-2 shadow h-100">
                                                <div className="">
                                                 
                                                    <img src={Market1} alt='' />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading">Wooden Horse Sculpt...</h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small'>Sponsored</p>
                                                    
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="small-preview-more mt-1">
                                            <h6>visit MarketPlace<ChevronsRight size={15} /></h6>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =================Podcast========================= */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Podcast</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Live Podcast.32</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block p-2 shadow h-100">
                                                <div className="" style={{ position: 'relative' }}>
                                                    {/* small-preview-img*/}
                                                    {/* <img src={Groups} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                </div>
                                                <div style={{ position: 'absolute', top: '8px' }}>
                                                    <img src={ad} alt="" />
                                                </div>
                                                <div className="" style={{ position: 'absolute', }}>
                                                    <h4 style={{
                                                        fontSize: '10px',
                                                        textWrap: 'nowrap',
                                                        marginLeft: '3px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        marginTop: '-18px',
                                                        color: 'white',
                                                        padding: '0',
                                                    }}>
                                                        {heading && heading.length > 10 ? `${heading.substring(0, 10)}...` : heading}
                                                    </h4>

                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading">Live Podcast11...</h4> */}
                                                        {/* <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4> */}
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="recomandation-display-block col-lg-6 p-1 ">
                                                <div className="" style={{width:'40px'}}>
                                                    <img src={group3} alt='' />
                                                </div>
                                                <div className="small-preview-more d-flex  justify-content-center align-item-center" style={{textWrap:'nowrap'}}>
                                                    visit all groups
                                                </div>
                                            </div> */}
                                            {/* <div className="recomandation-display-block col-lg-6 p-2 shadow h-100">
                                                <div className="">
                                            
                                                    <img src={Frame1} alt='' />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading">Live Podcast...</h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small'>Sponsored</p>
                                               
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="small-preview-more mt-1">
                                            <h6>visit all Podcast<ChevronsRight size={15} /></h6>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =================Events========================= */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Events</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <MoreVertical size={15} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>My Hosting Events</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100">
                                                <div className="">
                                                    {/* small-preview-img*/}
                                                    {/* <img src={Res} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className='' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading">My Hosting Events11...</h4> */}
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px', textWrap: 'nowrap' }}>Why this Ad?</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="recomandation-display-block col-lg-6 p-1 ">
                                                <div className="" style={{width:'40px'}}>
                                                    <img src={group3} alt='' />
                                                </div>
                                                <div className="small-preview-more d-flex  justify-content-center align-item-center" style={{textWrap:'nowrap'}}>
                                                    visit all groups
                                                </div>
                                            </div> */}
                                            {/* <div className="recomandation-display-block col-lg-6 p-2 shadow h-100">
                                                <div className="">
                                                    <img src={Frame1} alt='' />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        <h4 className="small-preview-heading">Live Podcast...</h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small'>Sponsored</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="small-preview-more mt-1">
                                            <h6>visit all Events<ChevronsRight size={15} /></h6>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ========  end ======== */}
                </div>
                {/* ========================= */}
            </div>
        </>
    )
}

export default PreviewMoreSection