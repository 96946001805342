import React from 'react'
import Allpage from './pages/Allpage'
import CommonSeeallpage from './pages/CommonSeeallpage'

const PodcastTabData = ({ tabData }) => {
    /*  ---------------------- Dyanmic Nav Pages --------------------  */
    return (
        <div className='mt-2'>
            {
                tabData === '1' ?
                    <Allpage /> : tabData === '2' ?
                        // <CommonSeeallpage tittle={'TV & Film'} /> : tabData === '3' ?
                        //     <CommonSeeallpage tittle={'Health & Fitness'} /> : tabData === '4' ?
                        //         <CommonSeeallpage tittle={'Socity & Culture '} /> : tabData === '5' ?
                        // <CommonSeeallpage tittle={'Comedy'} />
                        "" :
                        <Allpage />
            }
        </div>
    )
}

export default PodcastTabData