import React from 'react'
import { Fragment } from 'react'
import UserImg from '../../NewComponents/IMG/img.svg'
import { Bookmark, Box, Briefcase, Calendar, Circle, Headphones, Home, Settings, ShoppingCart, TrendingUp, UserCheck, Users } from 'react-feather'
import { NavLink, useNavigate } from 'react-router-dom'
import ShotzGreen from '../../NewComponents/IMG/ShotzGreen.svg'

const AppGridView = (props) => {

    const Navigate = useNavigate()

    //===================== strat_Mobile_ViewData ====================//
    const RootData = [
        {
            id: "1",
            active: "active",
            name: "Home",
            icon: <Home className="bar-icon-img" />,
            link: "/Home"

        },
        {
            id: "2",
            name: "Group",
            icon: <Users className="bar-icon-img" />,
            link: "/Group"
        },
        {
            id: "12",
            name: "Top",
            icon: <TrendingUp className="bar-icon-img" />,
            link: "/Tops"
        },
        {
            id: "13",
            name: "Trending",
            icon: <TrendingUp className="bar-icon-img" />,
            link: "/Trending"
        },
        {
            id: "3",
            name: "Connections",
            icon: <UserCheck className="bar-icon-img" />,
            link: "/Connection"
        },
        {
            id: "4",
            name: "Podcast",
            icon: <Headphones className="bar-icon-img" />,
            link: "/Podcast"
        },
        {
            id: "5",
            name: "Shotz",
            icon: <img src={ShotzGreen} className="bar-icon-img" alt='' />,
            link: "/Shotz"
        },
        {
            id: "6",
            name: "Business Page",
            icon: <Briefcase className="bar-icon-img" />,
            link: "/biz"
        },
        {
            id: "7",
            name: "Events",
            icon: <Calendar className="bar-icon-img" />,
            link: "/Events"
        },
        {
            id: "8",
            name: "MarketPlace",
            icon: <ShoppingCart className="bar-icon-img" />,
            link: "/Marketplace"
        },
        {
            id: "9",
            name: "Ad Manager",
            icon: <Box className="bar-icon-img" />,
            link: "/Dashboard"
        },
        {
            id: "10",
            name: "Bookmark",
            icon: <Bookmark className="bar-icon-img" />,
            link: "/Bookmark"
        },
        {
            id: "11",
            name: "Setting",
            icon: <Settings className="bar-icon-img" />,
            link: "/Setting"
        }


    ]
    //===================== end_Mobile_ViewData ====================//

    //================== Main_return_Function ====================//
    return (
        <Fragment>
            <li className="header-btn custom-dropdown d-md-none d-block app-btn">
                <a className="main-link" onClick={props.menuUpdate}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-light strokeWidth-3 iw-16 ih-16"
                    >
                        <rect x="3" y="3" width="7" height="7"></rect>
                        <rect x="14" y="3" width="7" height="7"></rect>
                        <rect x="14" y="14" width="7" height="7"></rect>
                        <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                </a>
                <div
                    className="overlay-bg app-overlay"
                    ref={props.oldRef}
                    onClick={props.closeMeanuBtnClick}
                >

                </div>
                {/*==================== start_App_box_root_Data ====================*/}
                <div className="app-box" ref={props.menuRef}>
                    <ul className="sidebar-icon">
                        <li className='border-bottom'>
                            <div className='d-flex align-items-center p-2'>
                                <img src={UserImg} className='img-fluid' onClick={() => Navigate('/MyProfile')} width={40} height={40} alt='UserImage' />
                                <span className="HeaderactiveMobForIcon">
                                    <Circle
                                        strokeWidth={5}
                                        color="#fff"
                                        fill="#16C31E"
                                        size={10}
                                    />
                                </span>
                                <div className='ml-2'>
                                    <h3>Sufiya Eliza</h3>
                                    <h5 className='text-muted'>Active now</h5>
                                </div>
                            </div>
                        </li>
                        {RootData.map((data, index) => (
                            <li key={index} className={data.active}>
                                <NavLink className='fontForSubCardheading' to={data.link}>
                                    {/* <img
                                        src={data.img}
                                        className="bar-icon-img"
                                        alt="Home"
                                    /> */}
                                    {data.icon}
                                    <h4 className='text-primary'>{data.name}</h4>
                                </NavLink>
                            </li>
                        ))}
                        <li onClick={() => props.updateOpen(true)}>
                            <a className='fontForSubCardheading'>
                                <img
                                    src="/assets/images/power.png"
                                    className="bar-icon-img"
                                    alt="logout"
                                />
                                <h4 className='text-danger' >logout</h4>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </Fragment >
    )
}

export default AppGridView