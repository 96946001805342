import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'reactstrap';

const MpFormDropDownsNew = (props) => {
    const [showDrop, setShowDrop] = useState({});
    const [selectedValues, setSelectedValues] = useState([]);
    const dropdownRefs = useRef([]);


    useEffect(()=>{
        setSelectedValues(props.selectedValues)
    },[props.onDataPass])

    //toggel dropdown
    const toggleDropdown = (index) => {
        setShowDrop((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    //when user click outside dropdown
    const handleClickOutside = (event) => {
        dropdownRefs.current.forEach((ref, index) => {
            if (ref && !ref.contains(event.target)) {
                setShowDrop((prevState) => ({
                    ...prevState,
                    [index]: false
                }));
            }
        });
    };
     

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // ===>> user  change the checck box <<====
    const handleCheckboxChange = (dropdownId, value) => {
        setSelectedValues((prevState) => {
            const existingIndex = prevState.findIndex(item => item.mpAttributeId === dropdownId && item.value === value);

            if (existingIndex !== -1) {
                const updatedSelections = prevState.filter((_, index) => index !== existingIndex);
                notifyParent(updatedSelections);
                return updatedSelections;
            } else {
                const updatedSelections = [...prevState, { mpAttributeId: dropdownId, value }];
                notifyParent(updatedSelections);
                return updatedSelections;
            }
        });
    };

     //passsing data to parent
    const notifyParent = (updatedValues) => {
        if (props.onDataPass) {
            props.onDataPass(updatedValues);
        }
    };




    // ===========================>> compoinent <<===========================
    return (
        <>
            {props.attributesByType?.dropdown && props.attributesByType.dropdown.length > 0 ? (
                props.attributesByType.dropdown.map((dropdown, index) => (
                    <div key={index} ref={(el) => (dropdownRefs.current[index] = el)}>
                        <div className='MpformElementHeading'>
                            <h5>{dropdown?.label || "title"}</h5>
                        </div>
                        {dropdown.mpAttributeValues && dropdown.mpAttributeValues.length > 0 ? (
                            <div className="MpFullDropDown position-relative mt-2">
                                <h5
                                    className='MpFormDropdownHeading'
                                    style={{ color: "#878992", cursor: "pointer" }}
                                    onClick={() => toggleDropdown(index)}
                                >
                                    {dropdown?.placeHolder || "enter values"}
                                </h5>
                                <div className={`${showDrop[index] ? "" : "d-none"}`}>
                                    {dropdown.mpAttributeValues.map((item, itemIndex) => (
                                        <div key={itemIndex} className="MrktPlaceCheckbox">
                                            <Input
                                                bsSize=""
                                                type="checkbox"
                                                checked={selectedValues?.some(selected => selected.mpAttributeId === dropdown.id && selected.value === item) || false}
                                                onChange={() => handleCheckboxChange(dropdown.id, item)}
                                            /> &nbsp;&nbsp;
                                            <h6 style={{ color: "#808080", fontWeight: "400" }}>{item}</h6>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="MpFullDropDown position-relative mt-2">
                                <h5 className='MpFormDropdownHeading' style={{ color: "#878992" }}>
                                    No options available
                                </h5>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div>No dropdowns available</div>
            )}
        </>
    );
}

export default MpFormDropDownsNew;
