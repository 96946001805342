import React, { useState, Fragment, useEffect } from 'react';
import { Input } from 'reactstrap';

const MpAttributeFormInput = ({ data, onDataPass, attributesByType }) => {
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    // Initialize inputValues from data if provided
    if (data && data.attributes) {
      const initialValues = data.attributes.reduce((acc, attr) => {
        acc[attr.id] = attr.value || '';
        return acc;
      }, {});
      setInputValues(initialValues);
    }
  }, [data]);

  const handleInputChange = (event, id) => {
    const value = event.target.value;
    const updatedValues = { ...inputValues, [id]: value };
    setInputValues(updatedValues);
    onDataPass(formatAttributes(updatedValues)); // Pass formatted data to parent component
  };

  const handleBlur = (id) => {
    if (!inputValues[id]) {
      // Handle blur logic if necessary
    }
  };

  // Format inputValues to the desired output structure
  const formatAttributes = (values) => {
    return Object.keys(values).map(key => ({
      mpAttributeId: key,
      value: values[key]
    }));
  };

  return (
    <Fragment>
      {attributesByType?.input?.map(input => (
        <div key={input.id} className='MpformElementHeading'>
          <h5>{input.label}</h5>
          <div className="Mp_InputPannels mt-2">
            <Input
              placeholder={input.placeHolder}
              style={{ padding: "10px" }}
              value={inputValues[input.id] || ''}
              onChange={(event) => handleInputChange(event, input.id)}
              onBlur={() => handleBlur(input.id)}
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default MpAttributeFormInput;
