import React, { useEffect } from 'react'
import { useState } from 'react';
import { Fragment } from 'react';
import { AlertCircle, Bookmark, Globe, Link2, Lock, Search, User } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { Button, Card, Col, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import AvatarGroup from '../../NewComponents/AvatarGroup';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import Invitation from './Components/Invitation';
import GroupMenu from './GroupMenu';
import Pin from "./Img/pin.png"
import Profile from "./Img/Profile.png"
import { useDispatch, useSelector } from "react-redux";
import { getInvitedPeopleToModalAction, loadAllGroupById, postInvitationToOwnedGroupAction } from '../../Services/Actions/Group/getAllGroupMainPageAction';
import { sweetAlertConfirmation } from './AllGroupsSections/SweetAlert';
const OwnedGroup = () => {
    const dispatch = useDispatch()
    const { getInvitedPeopleToModalRequest } = useSelector((state) => { return state.getInvitedPeopleToModalData });
    const { getGroupMainPageOwnGroup } = useSelector((state) => {return state.getAllGroupMainPageData}); 

console.log('getGroupMainPageOwnGroup', getGroupMainPageOwnGroup)

    const [inviteOpen, setInviteOpen] = useState(false)
    const [grpId, setGrpId] = useState('')
    const [friendsId, setFriendsId] = useState([])
    
    const toggleInvite = (id) => {
        dispatch(getInvitedPeopleToModalAction(id))
        setGrpId(id)
        setInviteOpen((Preview) => !Preview)
    }
    const inviteFriends = (e) => {
        let updatedList = [...friendsId]
        if (e.target.checked) {
            updatedList = [...friendsId, e.target.value]
        } else {
            updatedList.splice(friendsId.indexOf(e.target.value), 1)
        }
        setFriendsId(updatedList)
    };

    const sendInvitationData = () => {
        dispatch(postInvitationToOwnedGroupAction(grpId, friendsId))
        setInviteOpen(false)
        setFriendsId('')
        sweetAlertConfirmation('Join Request Send')

    };


    useEffect(() => {
        dispatch(loadAllGroupById())
    },[])
    // ================== Num_Formatter_Function
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }//_================== -Card_Info_Array ==================//
    const OwnedDataInfo = [
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... ",
            pinType: 'pin'

        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... ",
            pinType: 'unpin'


        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... ",
            pinType: 'pin'


        },
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... ",
            pinType: 'unpin'

        },
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... ",
            pinType: 'pin'

        },
    ]
    //================== Invite_Array_Data ==================//
    const InvitationData = [
        {
            img: Profile,
            name: "Samuel Taylor",
            userName: "samuel_taylor",
            id: "1"

        },
        {
            img: Profile,
            name: "Samuel Jackson",
            userName: "samuel_jackson",
            id: "2"

        },
        {
            img: Profile,
            name: "Samuel Dawson",
            userName: "samuel_dawson",
            id: "3"

        },
        {
            img: Profile,
            name: "Samuel Mcguire",
            userName: "samuel_mcguire",
            id: "4"

        },
        {
            img: Profile,
            name: "Samuel Mcguire",
            userName: "samuel_mcguire",
            id: "4"

        },
        {
            img: Profile,
            name: "Samuel Mcguire",
            userName: "samuel_mcguire",
            id: "4"

        }
    ]


    //================== main_return_function ===================//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="group-heading"><h2>Groups</h2></div>
                    <GroupMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="group-single-list-block">
                            <div className="heading-podcast-blk">
                                <h3>Owned Group ({getGroupMainPageOwnGroup && getGroupMainPageOwnGroup.data.successResult.rows.length})</h3>
                                <div className="search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <Row>
                                {
                                    getGroupMainPageOwnGroup && getGroupMainPageOwnGroup.data.successResult.rows.map((data, index) => (
                                        <Col md="3">
                                            <Card className="group-slide-box">
                                                <div className="gallery-img-sublink">
                                                    <Link to="#" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></Link>
                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                        <ul>
                                                            <li>
                                                                <Link to="#"><User size={15} color="#4D4D4D" /> View Profile</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><AlertCircle size={15} color="#4D4D4D" /> Learn More</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <NavLink to={`/GroupDetail/${data.id}/${data.name}/${data.totalMembers}/${index}`}>
                                                    <img alt='' src={data.coverPic} className="img-fluid group-img" />
                                                </NavLink>
                                                <div className="group-content">
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        {data.pinType === "pin" ?
                                                            <><h4 className='text-capitalize'>
                                                            {data.name.substring(0, 20)}
                                                            {data.name.length >= 15 && ' ...'}
                                                        </h4>&nbsp;&nbsp;<span><img src={Pin} alt='' /></span></> :
                                                        <h4 className='text-capitalize'>
                                                            {data.name.substring(0, 20)}
                                                            {data.name.length >= 15 && ' ...'}
                                                        </h4>
                                                        }
                                                    </div>
                                                    <div className="Suggestmember-blk">
                                                        {data.privacy == "Public"? (
                                                            <>
                                                             <span className='text-capitalize'><Globe color='#4D4D4D' size={12} /> {data.privacy} </span> •  <span>
                                                             {                                                        
                                                                data.totalMembers > 0 ?
                                                                 <>                                                        
                                                                     {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                 </>
                                                                 :
                                                                 <p>Be the first</p>
                                                             }
                                                             </span>
                                                             </>
                                                        ):(
                                                            <>
                                                            <span className='text-capitalize'><Lock color='#4D4D4D' size={12} /> {data.privacy} </span> •  <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                            }
                                                            </span>
                                                            </>
                                                        )}
                                                       
                                                    </div>
                                                    {/* <div className="people-likes matual-friend-sec">
                                                        <AvatarGroup />
                                                        <h6>{numFormatter(data.totalMembers)} members</h6>
                                                    </div> */}
                                                    <div className="group-buttons-blk mt-3">
                                                    <NavLink to={`/GroupDetail/${data.id}/${data.name}/${data.totalMembers}/${index}`}><Button className="Invite_Join_Button" color='primary'>View Activity</Button></NavLink>
                                                        <Button  onClick={() => { toggleInvite(data.id) }} className="Invite_Decline_Button ml-2" outline >Invite</Button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))                                  
                                }
                            </Row>
                        </div>
                    </div>

                </div>
                <div className='InviteModal'>
                    {inviteOpen !== false &&
                        <Modal scrollable isOpen={inviteOpen} toggle={toggleInvite} className='modal-dialog-centered'  >
                        <ModalHeader className='d-flex justify-content-center'>
                            <div className='Invitation_Heading_Section'>
                                <h4>Send Invitation</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md="12">
                                    <div className='Invite_SocioMate_Heading'>
                                        <h4>Invite from SocioMate</h4>
                                    </div>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md="12">
                                    <div className="search-block-podcast">
                                        <div className="input-search-blk">
                                            <Search className="search-svg" />
                                            <Input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12">
                                    <div className='Invitaion_List_Heading'>
                                        <h4>{getInvitedPeopleToModalRequest?.length ? 'Friends (' + getInvitedPeopleToModalRequest?.length + ')' : ''}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    {
                                        getInvitedPeopleToModalRequest && getInvitedPeopleToModalRequest.map((data) => (
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className='d-flex align-items-center '>
                                                    <img style={{ width: '40px', borderRadius: '50px' }} src={data.profileImageThumb} alt="profile" className='img-fluid group-img'
                                                        onError={(e) => { e.target.onerror = null; e.target.src = `${process.env.REACT_APP_IMAGEURL}/test/bxlJIIWSZ2SOk.jpg` }}
                                                    />
                                                    <div className='Invitaion_User_Profile ml-1'>
                                                        <h4>{data.fullName}</h4>
                                                        <p>{data.userName}</p>
                                                    </div>
                                                </div>
                                                <div className='Block-btns-blk'>
                                                    <CustomInput
                                                        id={data.id} style={{ cursor: "pointer" }}
                                                        value={data.id}
                                                        defaultChecked={!!friendsId[data?.id]}
                                                        onChange={(e) => {
                                                            inviteFriends(e);
                                                        }}
                                                        type="checkbox"
                                                        className='customcheckboxForFilter'

                                                    />
                                                </div>
                                            </div>

                                        ))
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12">
                                    <div className='d-flex justify-content-center'>
                                        {
                                            getInvitedPeopleToModalRequest?.length
                                                ?
                                                <Button onClick={sendInvitationData} className="Invitation_Send_btn">
                                                    Send </Button>
                                                :
                                                <p className='text-danger'>No contacts exist to show</p>

                                        }
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                    }

                </div>
                <RightSidebar />
            </div>

        </Fragment >
    )
}

export default OwnedGroup