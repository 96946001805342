import React, { Fragment, useState } from 'react'
import { DollarSign } from 'react-feather'
import { Input } from 'reactstrap'

const MpFilterPriceCompo = (props) => {
    const [selectedCurrency, setSelectedCurrency] = useState('USD');


    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };
    const handleKeyPress = (event) => {
        // Allow only numeric characters (0-9) and backspace
        if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };


    return (
        <Fragment>
            <div className="MpFilterValueInput d-flex align-items-center justify-content-between p-2 border " style={{ borderRadius: "5px" }}>
                {selectedCurrency === 'IND' ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8 3H18L17 5H13.74C14.22 5.58 14.58 6.26 14.79 7H18L17 9H15C14.8761 10.2468 14.3345 11.4151 13.4632 12.3154C12.592 13.2157 11.442 13.7953 10.2 13.96V14H9.5L15.5 21H13L7 14V12H9.5C11.26 12 12.72 10.7 12.96 9H7L8 7H12.66C12.1 5.82 10.9 5 9.5 5H7L8 3Z" fill="black" />
                    </svg>
                ) : (
                    <DollarSign size={20} className="MpFilterValueInput_Price_Doller" />
                )}
                <Input placeholder={props.placeholder} className="border-right MpFilterValueInput_Price_First" pattern="[0-9]*" // Add this pattern to allow only numeric input
                    inputMode="numeric"
                    onKeyPress={handleKeyPress} type="text"    maxLength={10} value={props.text} onChange={(e)=>props.settext(e.target.value)}/>
                <Input type="select" className="MpFilterValueInput_Price_two" value={selectedCurrency} onChange={handleCurrencyChange}>
                    {props.options.map((op) => (
                        <option >{op}</option>
                    ))}

                </Input>
            </div>
        </Fragment>
    )
}

export default MpFilterPriceCompo
