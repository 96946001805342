import * as types from "../../Constants/MarketPlace/index";
import axios from "axios";
import { useState ,useEffect} from 'react'


export const getAllMarketPlaceProducts =
  (body) => async (dispatch, getState) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getallproducts`,
        body,
        config
      );
    //   console.log(
    //     "this is data response ================================>>>>>>>>>>>>>",
    //     response.data.data.successResult.rows
    //   );
      if (
        response.data &&
        response.data.data &&
        response.data.data.successResult &&
        response.data.data.successResult
      ) {
        // Dispatch action to indicate successful deletion
        dispatch({
          type: types.GET_ALL_MARKETPLACE_PRODUCTS,
          payload: response.data.data.successResult,
        });
      } else {
        console.error("Unexpected response structure:", response.response);
      }
    } catch (error) {
      //Dispatch action to indicate deletion error
      dispatch({
        type: types.GET_ALL_MARKETPLACE_PRODUCTS_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };




  export const getSingleProductAction=(body) => async (dispatch, getState) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/singlempPost`,
        body,
        config
      );
      // console.log(
      //   "api response ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      if (
        response.data &&
        response.data.data &&
        response.data.data.successResult &&
        response.data.data.successResult
      ) {
        // Dispatch action to indicate successful deletion
        dispatch({
          type: types.GET_SINGLE_MARKETPLACE_PRODUCT,
          payload: response.data.data.successResult,
        });
      } else {
        console.error("Unexpected response structure:", response.response);
      }
    } catch (error) {
      //Dispatch action to indicate deletion error
      dispatch({
        type: types.GET_SINGLE_MARKETPLACE_PRODUCT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };




 export const getAllProductCategoryAction=(body) => async (dispatch, getState) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getmpcategory`,
        body,
        config
      );
      // console.log(
      //   "api response catergoryDAta ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      if (
        response.data &&
        response.data.data &&
        response.data.data.successResult &&
        response.data.data.successResult
      ) {
        // Dispatch action to indicate successful deletion
        dispatch({
          type: types.GET_MARKETPLACE_CATEGORY,
          payload: response.data.data.successResult,
        });
      } else {
        console.error("Unexpected response structure:", response.response);
      }
    } catch (error) {
      //Dispatch action to indicate deletion error
      dispatch({
        type: types.GET_MARKETPLACE_CATEGORY_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const getSuggestedProductList=(body) => async (dispatch, getState) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getSuggestedProductList`,
        body,
        config
      );
      // console.log(
      //   "api response suggested products ;list ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      if (
        response.data &&
        response.data.data &&
        response.data.data.successResult &&
        response.data.data.successResult
      ) {
        // Dispatch action to indicate successful deletion
        dispatch({
          type: types.GET_SUGGESTED_MARKETPLACE_PRODUCT,
          payload: response.data.data.successResult,
        });
      } else {
        console.error("Unexpected response structure:", response.response);
      }
    } catch (error) {
      //Dispatch action to indicate deletion error
      dispatch({
        type: types.GET_SUGGESTED_MARKETPLACE_PRODUCT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const getAllCategoryAndSubcategoryHaveData=(body) => async (dispatch, getState) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getAllCategoryAndSubcategoryHaveData`,
        body,
        config
      );
      // console.log(
      //   "api response suggested products ;list ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      if (
        response.data &&
        response.data.data &&
        response.data.data.successResult &&
        response.data.data.successResult
      ) {
        // Dispatch action to indicate successful deletion
        dispatch({
          type: types.GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA,
          payload: response.data.data.successResult,
        });
      } else {
        console.error("Unexpected response structure:", response.response);
      }
    } catch (error) {
      //Dispatch action to indicate deletion error
      dispatch({
        type: types.GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };




  export const deleteMarketPlaceProduct=(body) => async (dispatch, getState) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/deleteMp`,
        body,
        config
      );
      // console.log(
      //   "api response suggested products ;list ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
     
    } catch (error) {
      console.log(error)
      
    }
  };

  export const getMpReportOptions = async () =>{
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getmpreportOptions`,
        {},
        config
      );
      // console.log(
      //   "api response  report option list ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      return response.data.data?.successResult || []
     
    } catch (error) {
      console.log(error)
      
    }
  }


  export const getPeopleList = async (peopleListPageIndex, searchKeyValue) => {
    try {
       if(searchKeyValue)peopleListPageIndex=0;  //set peopleListPageIndex = when user tap on search key
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      let body = {
        userId: user?.id,
        sortBy: "Public",
        pageIndex: peopleListPageIndex || 0,
        pageSize: 10,
        searchKey :searchKeyValue
     
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/getPeopleList`,
        body,
        config
      );
      // console.log(
      //   "api response people list ======================>>>>>>>>>>>>>",response.data.data.successResult.rows
      // );
      let peopleListData = response.data?.data?.successResult?.rows;
      if (peopleListData) {
        return peopleListData;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };
  
  export const getUserBizAndGroupsData = async (peopleListPageIndex) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      let body = {
        userId: user?.id,
        pageIndex: peopleListPageIndex || 0,
        pageSize: 10,
        searchKey :""
     
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getUserBizPgesAndGroupsListing`,
        body,
        config
      );
      // console.log(
      //   "api response userBizandGroupData list ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
      let userBizandGroupData = response.data?.data?.successResult;
     return userBizandGroupData;
    } catch (error) {
      console.log(error);
    }
  };


  export const reportMarketPlaceProduct = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/reportmpPost`,
        body,
        config
      );
      // console.log(
      //   "api response report ,marketPlaceProduct  ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );

    } catch (error) {
      console.log(error);
    }
  };


  export const fetchMpAttributes = async (categoryId,subCategoryId) => {
    try {
      // console.log("this is categoryId: ",categoryId)
      // console.log("this is subCategoryId:",subCategoryId)

      const body = {
        categoryId: categoryId,
        subCategoryId :subCategoryId,
        subChildCategoryId:""

      };

      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getMpPostValuesAdmin`,
        body,
        config
      );

      if (response?.data?.data?.successResult && response.data.data.successResult.length > 0) {
      //        console.log(
      //          "api response marketPlace atrribute  ======================>>>>>>>>>>>>>",response.data.data.successResult
      // );
        return response.data.data.successResult;
      } 
    } catch (error) {
      console.error("Error fetching marketPlace attributes", error);
    }
  };

  
  export const likeMarketPlaceProduct = async (marketPlaceId, like) => {
    try {
      const body = {
        marketPlaceId: marketPlaceId,
        like: like,
      };
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/addLike`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api response like marketPlace ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during like product", error);
    }
  };
  

  export const getproductComments = async (marketPlaceId,pageSize,pageIndex) => {
    try {
      const body = {
        marketPlaceId: marketPlaceId,
        pageSize: pageSize,
        pageIndex: pageIndex,

      };
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getmpComments`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api response comments of product ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during fetching product comments", error);
    }
  };


  export const addComment = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/addmpcomment`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api response add comment on product ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during add comment comments", error);
    }
  };


  export const getProductShareCommentViewPeopleList = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getMpPostUserList`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api responsegetProductShareCommentViewPeopleList ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during getProductShareCommentViewPeopleList", error);
    }
  };


  
  export const followUnfollowUser = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api followUnfollowUser ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during followUnfollowUser", error);
    }
  };



  
  
  export const likeUnlikeMpSubComment = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/addlikebycomment`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api followUnfollowUser ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during likeUnlikeMpSubComment", error);
    }
  };


  export const editMpProduct = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/updatemppost`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api updatemppost ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during updatemppost", error);
    }
  };


  
  export const getAttributeByCategoryAndSubCategory = async (body) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/getAttributeByCategoryAndSubCategory`,
        body,
        config
      );

      if (response?.data?.data?.successResult) {
        //        console.log(
        //          "api getAttributeByCategoryAndSubCategory ======================>>>>>>>>>>>>>",response.data.data.successResult
        // );
        return response.data.data.successResult;
      }
    } catch (error) {
      console.error("Error during getAttributeByCategoryAndSubCategory", error);
    }
  };




