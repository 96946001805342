import React, { Fragment } from 'react'
import { Circle } from 'react-feather'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { LikeData } from '../Components/EventsDataArray'
import FlwrImg from '../Img/FlwrImg.png'

const InterestedModal = (props) => {
    return (
        <Fragment>
            <div className='Interested_Modal_section'>
                {
                    props.isOpen !== false &&
                    <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered' >
                        <ModalHeader toggle={props.updateOpen} className="My-Member_Header_section">
                            <h2>Interested People</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className="py-2">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div>
                                {LikeData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <img src={FlwrImg} alt="img" className='img-fluid rounded-circle' />
                                                <div className="member-cont-blk">
                                                    <h5>{data.name}</h5>
                                                    <p className=''>{data.join}</p>
                                                    <div className=" d-flex align-items-center mt-1  ">
                                                        <div className='d-flex '>
                                                            <h5>{data.followData}</h5>&nbsp;
                                                            <span style={{ fontSize: '12px' }}>Followers</span>
                                                        </div>
                                                        &nbsp;&nbsp;<Circle fill='#C4C4C4' color='#C4C4C4' size={6} />&nbsp;&nbsp;
                                                        <div className=' d-flex '>
                                                            <h5 style={{marginTop:'1px'}}>{data.post} </h5> &nbsp;
                                                            <span style={{ fontSize: '12px' }}>Posts</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Button outline color='primary' className="w-25 d-flex  justify-content-center btn-add-text"  >{data.btnType}</Button>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default InterestedModal