import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ArrowLeft, ArrowRight, ChevronDown, Download, Filter, Grid, Trash2, X } from 'react-feather'
import ReactPaginate from 'react-paginate'
import { Button, Card, CardBody, CardHeader, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, PopoverBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Popover, Radio } from '@mui/material'
import { Checkbox } from "@mui/material";
import coverImage from '../../../NewComponents/IMG/reel4.png'

const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none', // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: '#E5F0E0',
            fontSize: '12px',
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            whiteSpace: '',
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            // marginTop: '1%',
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: '400'
        }
    }
}

const LeadGenerationTable = () => {

    const [loading, setLoading] = useState(false)
    const [FormOpen, setFormClose] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])
    const [checkList, setCheckList] = useState({
        serialnoCheck: true,
        OffOnCheck: true,
        adNameCheck: true,
        adIdCheck: true,
        adTypeCheck: true,
        totalImpressionsCheck: true,
        totalReachCheck: true,
        clickCheck: true,
        viewCheck: true,
        leadCheck: true,
        totalAmountCheck: true,
        dailyBudgetCheck: true,
        overallBudgetCheck: true,
        startDateCheck: true,
        endDateCheck: true,
        lastSignificantCheck: true,
    })

    // const handleSearch = (event) => {
    //   setSearchValue(event.target.value)
    //   setRender(!render)
    // }


    /*---------------Archieved Ad function--------------*/

    const [Archive, setArchive] = useState(false)

    const toggleArchived = (id) => {
        setArchive(!Archive)
    }


    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const [anchorEl8, setAnchorEl8] = useState(null);
    const [anchorEl9, setAnchorEl9] = useState(null);
    const [anchorEl10, setAnchorEl10] = useState(null);

    const handlePopoverOpen = (event, setAnchorEl) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (setAnchorEl) => {
        setAnchorEl(null);
    };

    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }

    const [deleteallModal, setDeleteallModal] = useState(false)


    const DeletedModal = () => {
        return (
            <>
                <Modal isOpen={deleteallModal} className='modal-dialog-centered' toggle={() => setDeleteallModal(!deleteallModal)}>
                    {/* <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader> */}
                    <ModalBody>
                        <div className='text-center mb-2'>
                            <Trash2 size={70} color="#EA5455" />
                            <h3 className='mt-2'>{selectedRows.length === 0 ? 'Do You Really Want to Delete ?' : 'Do You Really Want to Delete all ?'}</h3>
                        </div>
                        <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the ‘Action History’ screen." />
                        </InputGroup>
                        <div className="d-flex justify-content-center m-1 mt-3">
                            <div >  <Button type="submit" color="danger" outline >Yes , Delete it</Button></div>
                            <div className='ml-3'><Button color="success" outline onClick={() => setDeleteallModal(!deleteallModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

    const tabledata = [
        {
            id: '1',
            LeadaddedDateandTime: '2022-11-05T11:13:00.000Z',
            Name: 'Adam Smith',
            channel: 'Shotz',
            AudioPrefrence: 'Custom',
            targetGender: 'Male',
            formHeading: 'E-Com Learning',
            formDiscription: 'Our aim is to know which type of course is best for you and you guys know this very well so please fill this form and help us.',
            selectedGender: 'Male',
            selectedAnswer: 'C++'
        },
        {
            id: '2',
            LeadaddedDateandTime: '2022-11-05T11:13:00.000Z',
            Name: 'Gill Crist',
            channel: 'Shotz',
            AudioPrefrence: 'Custom',
            targetGender: 'Male',
            formHeading: 'E-Com Learning',
            formDiscription: 'Our aim is to know which type of course is best for you and you guys know this very well so please fill this form and help us.',
            selectedGender: 'Male',
            selectedAnswer: 'C++'
        },
        {
            id: '3',
            LeadaddedDateandTime: '2022-11-05T11:13:00.000Z',
            Name: 'Eleven Thom',
            channel: 'Shotz',
            AudioPrefrence: 'Custom',
            targetGender: 'Male',
            formHeading: 'E-Com Learning',
            formDiscription: 'Our aim is to know which type of course is best for you and you guys know this very well so please fill this form and help us.',
            selectedGender: 'Male',
            selectedAnswer: 'Java'
        },
        {
            id: '4',
            LeadaddedDateandTime: '2022-11-05T11:13:00.000Z',
            Name: 'John whick',
            channel: 'Shotz',
            AudioPrefrence: 'Custom',
            targetGender: 'Male',
            formHeading: 'E-Com Learning',
            formDiscription: 'Our aim is to know which type of course is best for you and you guys know this very well so please fill this form and help us.',
            selectedGender: 'Male',
            selectedAnswer: 'C++'
        },
        {
            id: '5',
            LeadaddedDateandTime: '2022-11-05T11:13:00.000Z',
            Name: 'Sam reven',
            channel: 'Shotz',
            AudioPrefrence: 'Custom',
            targetGender: 'Male',
            formHeading: 'E-Com Learning',
            formDiscription: 'Our aim is to know which type of course is best for you and you guys know this very well so please fill this form and help us.',
            selectedGender: 'Male',
            selectedAnswer: 'Java'
        }
    ]

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
            omit: !checkList.serialnoCheck,
            sortable: true
        },

        {
            name: "Lead added",
            selector: row => row.Adname,
            minWidth: "100px",
            omit: !checkList.adNameCheck,
            sortable: true,

            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5>{moment(row.startDateTime).format('MMM Do YYYY')}</h5>
                    <h5>{moment(row.startDateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: 'Name',
            sortable: true,
            omit: !checkList.adIdCheck,
            minWidth: "150px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl2)} onMouseLeave={() => handlePopoverClose(setAnchorEl2)} className="d-flex align-items-center fontForSubTablheading" >
                    <h5>
                        {row.Name}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl2)}
                        anchorEl={anchorEl2}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
                                voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },

        {
            name: 'Channel',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "120px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl3)} onMouseLeave={() => handlePopoverClose(setAnchorEl3)} className='fontForSubTablheading'>
                    <h5>
                        {row.channel}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl3)}
                        anchorEl={anchorEl3}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
                                voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },

        // {
        //     name: 'Audience Preference',
        //     sortable: true,
        //     omit: !checkList.totalImpressionsCheck,
        //     minWidth: "100px",
        //     cell: row => (
        //         <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl4)} onMouseLeave={() => handlePopoverClose(setAnchorEl4)} className="d-flex align-items-center fontForSubTablheading" >
        //             <h5>
        //                 {row.AudioPrefrence}
        //             </h5>
        //             <Popover
        //                 open={Boolean(anchorEl4)}
        //                 anchorEl={anchorEl4}
        //                 PaperProps={{ style: { maxWidth: 400 } }}
        //                 sx={{
        //                     pointerEvents: 'none',
        //                 }}
        //                 anchorOrigin={{
        //                     vertical: 'bottom',
        //                     horizontal: 'left',
        //                 }}
        //                 transformOrigin={{
        //                     vertical: 'top',
        //                     horizontal: 'right',
        //                 }}
        //                 onClose={handlePopoverClose}
        //                 disableRestoreFocus
        //             >
        //                 {/* <PopoverHeader>View</PopoverHeader> */}
        //                 <PopoverBody>
        //                     <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
        //                         At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
        //                         voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
        //                 </PopoverBody>
        //             </Popover>
        //         </div>
        //     )

        // },

        // {
        //     name: 'Target Gender',
        //     sortable: true,
        //     omit: !checkList.totalReachCheck,
        //     minWidth: "100px",
        //     cell: row => (
        //         <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl5)} onMouseLeave={() => handlePopoverClose(setAnchorEl5)} className="d-flex align-items-center fontForSubTablheading" >
        //             <h5>
        //                 {row.targetGender}
        //             </h5>
        //             <Popover
        //                 open={Boolean(anchorEl5)}
        //                 anchorEl={anchorEl5}
        //                 PaperProps={{ style: { maxWidth: 400 } }}
        //                 sx={{
        //                     pointerEvents: 'none',
        //                 }}
        //                 anchorOrigin={{
        //                     vertical: 'bottom',
        //                     horizontal: 'left',
        //                 }}
        //                 transformOrigin={{
        //                     vertical: 'top',
        //                     horizontal: 'right',
        //                 }}
        //                 onClose={handlePopoverClose}
        //                 disableRestoreFocus
        //             >
        //                 {/* <PopoverHeader>View</PopoverHeader> */}
        //                 <PopoverBody>
        //                     <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
        //                         At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
        //                         voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
        //                 </PopoverBody>
        //             </Popover>
        //         </div>
        //     )

        // },

        {
            name: 'Form Heading',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "120px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl6)} onMouseLeave={() => handlePopoverClose(setAnchorEl6)} className='fontForSubTablheading'>
                    <h5>
                        {row.formHeading}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl6)}
                        anchorEl={anchorEl6}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
                                voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Form Discription',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "120px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl7)} onMouseLeave={() => handlePopoverClose(setAnchorEl7)} className='fontForSubTablheading'>
                    <h5>
                        {row.formDiscription?.substring(0, 21) + '...'}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl7)}
                        anchorEl={anchorEl7}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
                                voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Gender',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "120px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl8)} onMouseLeave={() => handlePopoverClose(setAnchorEl8)} className='fontForSubTablheading'>
                    <h5>
                        {row.selectedGender}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl8)}
                        anchorEl={anchorEl8}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
                                voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Answer',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "120px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl9)} onMouseLeave={() => handlePopoverClose(setAnchorEl9)} className='fontForSubTablheading'>
                    <h5>
                        {row.selectedAnswer}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl9)}
                        anchorEl={anchorEl9}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                At neque mollitia nisi nesciunt explicabo nemo quasi. Perferendis labore ex vero modi eveniet,
                                voluptatum voluptatem aliquam neque facere recusandae magnam impedit.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },

    ]


    const checkListArr = [
        {
            check: {
                ...checkList,
                serialnoCheck: !checkList.serialnoCheck
            },
            state: checkList.serialnoCheck,
            name: "Sr. No"
        },
        {
            check: {
                ...checkList,
                OffOnCheck: !checkList.OffOnCheck
            },
            state: checkList.OffOnCheck,
            name: "Off/On"
        },
        {
            check: {
                ...checkList,
                adNameCheck: !checkList.adNameCheck
            },
            state: checkList.adNameCheck,
            name: "Ad Name"
        },
        {
            check: {
                ...checkList,
                adIdCheck: !checkList.adIdCheck
            },
            state: checkList.adIdCheck,
            name: "Ad ID"
        },
        {
            check: {
                ...checkList,
                adTypeCheck: !checkList.adTypeCheck
            },
            state: checkList.adTypeCheck,
            name: "Ad Type"
        },
        {
            check: {
                ...checkList,
                totalImpressionsCheck: !checkList.totalImpressionsCheck
            },
            state: checkList.totalImpressionsCheck,
            name: "Total Impressions"
        },
        {
            check: {
                ...checkList,
                totalReachCheck: !checkList.totalReachCheck
            },
            state: checkList.totalReachCheck,
            name: "Total Reach"
        },
        {
            check: {
                ...checkList,
                clickCheck: !checkList.clickCheck
            },
            state: checkList.clickCheck,
            name: "Click"
        },
        {
            check: {
                ...checkList,
                viewCheck: !checkList.viewCheck
            },
            state: checkList.viewCheck,
            name: "View"
        },
        {
            check: {
                ...checkList,
                leadCheck: !checkList.leadCheck
            },
            state: checkList.leadCheck,
            name: "Lead"
        },
        {
            check: {
                ...checkList,
                totalAmountCheck: !checkList.totalAmountCheck
            },
            state: checkList.totalAmountCheck,
            name: "Total Amount spent"
        },
        {
            check: {
                ...checkList,
                dailyBudgetCheck: !checkList.dailyBudgetCheck
            },
            state: checkList.dailyBudgetCheck,
            name: "Daily Budget"
        },
        {
            check: {
                ...checkList,
                overallBudgetCheck: !checkList.overallBudgetCheck
            },
            state: checkList.overallBudgetCheck,
            name: "Over all Budget"
        },
        {
            check: {
                ...checkList,
                startDateCheck: !checkList.startDateCheck
            },
            state: checkList.startDateCheck,
            name: "Start Date & Time"
        },
        {
            check: {
                ...checkList,
                endDateCheck: !checkList.endDateCheck
            },
            state: checkList.endDateCheck,
            name: "Ending Date & Time"
        },
        {
            check: {
                ...checkList,
                lastSignificantCheck: !checkList.lastSignificantCheck
            },
            state: checkList.lastSignificantCheck,
            name: "Last significant edit"
        }
    ]


    const Cardheader = () => {
        return (<div className='nav-flex-container mx-3 my-3'>
            <div className='nav-flex size-sm-mt-4'>
                <div className='mr-1'>
                    <Button outline color='primary' className='font-weight-bold'>
                        <Download size={18} /> Export </Button>
                </div>
                {/* <div>
          <Button color='primary' outline> <RotateCcw size={16} /> History</Button>
        </div>
        <div className='mx-1'>
          <Button color='primary' outline  >Preview</Button>
        </div>
        <div>
          <Button color='primary' outline  >Reports</Button>
        </div>
        <div className='mx-1'>
          <Button color='primary' outline  >Ad Stop</Button>
        </div>
        <div>
          <Button color='primary' outline  >More...</Button>
        </div> */}

            </div >

            <div className='nav-flex size-sm-mt-4'>
                <div>
                    <Input type='search' size='58' className='w-100' Placeholder='Search here...' />
                </div>
                <div>
                    <UncontrolledButtonDropdown direction='start'>
                        <DropdownToggle color="transparent" className="ml-1 p-0" >
                            <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                <Filter size={22} color='#81C14B' />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className='shadow' style={{ zIndex: '100', minWidth: 'fit-content' }}>
                            <DropdownItem tag='a'>Brand Awareness (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Website(Link) Visits (CPC)</DropdownItem>
                            <DropdownItem tag='a'>Post Engagement (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Video Views (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Lead Generation (CPL)</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="transparent" className="ml-1 p-0" >
                            <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                <Grid size={22} color='#81C14B' />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className='scrollForAdmanagerFilter dropedownForFilter' right >
                            {checkListArr.map((value, i) => {
                                return (
                                    <DropdownItem className='w-100' onClick={() => setCheckList(value.check)} >
                                        <CustomInput type="checkbox" className='customcheckboxForFilter' id={`group-checkbox-${i}`} label={value.name} style={{ cursor: "pointer" }} checked={value.state} />
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        </div >
        )
    }

    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }

    const CustomPagination = () => {

        const count = Number(Math.ceil(totalItems / rowsPerPage))

        return (
            <div className='d-flex justify-content-between mt-2'>
                <div className="d-flex align-items-center">
                    <Label for='row-per-page' className='text-nowrap'>Row per page</Label>
                    <CustomInput
                        className="form-control mx-50 px-1.5 ml-1 w-50"
                        style={{ backgroundColor: '#E5F0E0' }}
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={onChangeRowsPerValue}
                    >
                        <option value='5'>5 Row</option>
                        <option value='10'>10 Row</option>
                        <option value='25'>25 Row</option>
                        <option value='50'>50 Row</option>
                        <option value='100'>100 Row</option>
                    </CustomInput>
                </div>
                <ReactPaginate
                    previousLabel={<ArrowLeft color='#4D4D4D' size={15} />}
                    nextLabel={<ArrowRight color='#4D4D4D' size={15} />}
                    pageCount={count}
                    activeClassName='active'
                    forcePage={currentPage}
                    onPageChange={page => {
                        setCurrentPage(page.selected)
                        setRender(!render)
                    }}
                    pageClassName='page-item'
                    nextClassName='page-item next'
                    nextLinkClassName='page-link'
                    previousClassName='page-item prev'
                    previousLinkClassName='page-link'
                    pageLinkClassName='page-link'
                    containerClassName="pagination react-paginate justify-content-end my-2 pr-1"
                />
            </div>
        )
    }


    useEffect(() => {
        // getPostfeedLikedetails()
    }, [render])

    return (

        <div style={{ marginTop: '5px' }}>
            <Row>
                <Col xl={FormOpen ? '8' : '12'} >
                    <Card className='shadow'>
                        <CardHeader className='py-3'>
                            <h2 className='py-2'><strong>Leads (5)</strong></h2>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex justify-content-between w-100'>
                                    <div className='d-flex justify-content-between'>
                                        <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "200px", height: "200px" }} />
                                        <div className='ml-2 fontWeightBold px-2'>
                                            <h4>Ad Name</h4>
                                            <h4>Ad Type</h4>
                                            <h4>Ad Id</h4>
                                            <h4>Reach</h4>
                                            <h4>View</h4>
                                            <h4>Impression</h4>
                                            <h4>Click</h4>
                                        </div>
                                        <div className='ml-2 fontWeightBold px-2'>
                                            <h5>: &nbsp;New Traffic Ad - Kickstarter</h5>
                                            <h5>: &nbsp;Lead Generation - ( CPL )</h5>
                                            <h5>: &nbsp;12456</h5>
                                            <h5>: &nbsp;23</h5>
                                            <h5>: &nbsp;54</h5>
                                            <h5>: &nbsp;69</h5>
                                            <h5>: &nbsp;74</h5>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='ml-2 fontWeightBold px-2'>
                                            <h4>Spent Amount</h4>
                                            <h4>Daily Budget</h4>
                                            <h4>Start Date & Time</h4>
                                            <h4>End Date & Time</h4>
                                            <h4>Last Edited</h4>
                                            {FormOpen ? null : <h4>Advance Preview</h4>}
                                        </div>
                                        <div className='ml-2 fontWeightBold px-2'>
                                            <h5>: &nbsp;New Traffic Ad - Kickstarter</h5>
                                            <h5>: &nbsp;Lead Generation - ( CPL )</h5>
                                            <h5>: &nbsp;12456</h5>
                                            <h5>: &nbsp;23</h5>
                                            <h5>: &nbsp;54</h5>
                                            {FormOpen ? null :
                                                <div onClick={() => setFormClose(true)} className='d-flex align-items-center fontForSubchildCardheading'>
                                                    <label className="switch m-1 ml-2">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <DeletedModal />
                            <div className='mb-2'>
                                <Cardheader />
                            </div>
                            {
                                selectedRows.length !== 0 &&
                                <div style={{ backgroundColor: '#E3F2FD' }} className='d-flex justify-content-between align-items-center p-2 mb-1'>
                                    <h4> {selectedRows.length} item selected</h4>
                                    <Col>
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <Button color='primary' outline >
                                                Archive Ad
                                            </Button>
                                            <Button color='primary mx-2' outline >
                                                Reports
                                            </Button>
                                            <Button color='warning' outline >
                                                Pause
                                            </Button>
                                        </div>
                                    </Col>
                                    {/* <Button color='danger' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                                <Trash2 size={16} />
                            </Button> */}
                                </div>
                            }
                            <Row>
                                <Col>
                                    <DataTable
                                        noHeader
                                        responsive
                                        // selectableRowSelected={selectedRows}
                                        selectableRows
                                        paginationServer
                                        selectableRowsHighlight
                                        highlightOnHover
                                        pointerOnHover
                                        onSelectedRowsChange={handleChange}
                                        sortIcon={<ChevronDown />}
                                        paginationComponent={CustomPagination}
                                        columns={columns}
                                        data={tabledata}
                                        customStyles={styleTable}
                                        progressPending={loading}
                                    />
                                </Col>
                            </Row>
                            <CustomPagination />
                        </CardBody>
                    </Card>
                </Col>
                <Col className={FormOpen ? '' : 'd-none'} xl='4'>
                    <Card className='shadow-sm'>
                        <CardHeader className='py-3'>
                            <div className='d-flex justify-content-between'>
                                <h3><strong>{selectedRows[0]?.Name || ''}</strong></h3>
                                <X style={{ cursor: 'pointer' }} onClick={() => setFormClose(false)} />
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label className="p-heading" for="name">Form Heading</Label>
                                    <Input type="text" value={selectedRows.length > 0 ? (selectedRows[0].formHeading) : ''} name="name" id="name" placeholder="Add primary heading" />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="p-heading" for="email">Form Description</Label>
                                    <Input value={selectedRows.length > 0 ? (selectedRows[0].formHeading) : ''} type="textarea" name="message" id="message" placeholder="Enter your message" />
                                </FormGroup>
                                <div className="col-lg-12 col-12 px-0">
                                    <div className="">
                                        <p className="p-heading">
                                            What is your Name ?
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            className="form-control p-2"
                                            placeholder="Type the ‘Hint’ here..."
                                            maxLength={"32"}
                                            value={selectedRows[0]?.Name || ''}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12 px-0">
                                    <div className="d-flex lead-switch">
                                        <p className="p-heading">Please select your Gender ?</p>
                                    </div>

                                    <div className="form-control d-flex my-3 position-relative">

                                        <Radio checked={selectedRows.length > 0 ? true : false} />
                                        <input
                                            type="text"
                                            className="form-control p-2"
                                            style={{ border: "none" }}
                                            placeholder="Type the ‘Option’ here.."
                                            value={'Male'}
                                        />
                                    </div>
                                    <div className="form-control d-flex my-3 position-relative">

                                        <Radio disabled={selectedRows.length > 0 ? true : false} />
                                        <input
                                            type="text"
                                            className="form-control p-2"
                                            style={{ border: "none" }}
                                            placeholder="Type the ‘Option’ here.."
                                            value={'Female'}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12 px-0">
                                    <div className="d-flex lead-switch">
                                        <p className="p-heading">Which Programming Language you want to learn ?</p>
                                    </div>

                                    <div className="form-control d-flex my-3 position-relative">

                                        <Checkbox disabled={selectedRows.length > 0 ? true : false} />
                                        <input
                                            type="text"
                                            className="form-control p-2"
                                            style={{ border: "none" }}
                                            placeholder="Type the ‘Option’ here.."
                                            value={'C++'}
                                        />
                                    </div>
                                    <div className="form-control d-flex my-3 position-relative">

                                        <Checkbox checked={selectedRows.length > 0 ? true : false} />
                                        <input
                                            type="text"
                                            className="form-control p-2"
                                            style={{ border: "none" }}
                                            placeholder="Type the ‘Option’ here.."
                                            value={'Java'}
                                        />
                                    </div>

                                </div>
                            </Form>

                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </div>
    )
}

export default LeadGenerationTable