import { Fragment, useState } from 'react'
import { Check, Search } from 'react-feather'
import { Button, Modal, ModalHeader, ModalBody, CardBody, Card, InputGroup, Input } from 'reactstrap'
import { AvatarGroupSm2 } from '../ConnectionPlaceMenu/AvatarGroupSm2';

export const SociomateList = ({ sociomateList }) => {
    // Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('')

    // Function to toggle modal
    const toggleModal = () => setModalOpen(!modalOpen)

    // Calculate the number of mutual friends
    const sociomateFriendsCount = sociomateList?.length || 0;

     // Handle search input change
     const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase())
    }

    // Filtered mutual friends based on search query
    const filteredSociomates = sociomateList?.filter((data) =>
        data.fullName.toLowerCase().includes(searchQuery) ||
        data.userName.toLowerCase().includes(searchQuery)
    )

    return (
        <Fragment>
            {/* Clicking on this section opens the modal */}
            <div className="people-likes matual-friend-sec" onClick={toggleModal} style={{ cursor: 'pointer' }}>
                {sociomateFriendsCount > 0 ? (
                    <>
                        <AvatarGroupSm2 sociomateList={sociomateList} />
                        <small className='text-primary font-weight-bold d-inline-block mb-0 text-primary align-items-center text-truncate align-self-center'>
                            &nbsp;
                            {sociomateFriendsCount > 3 ? `+${sociomateFriendsCount - 3}` : `${sociomateFriendsCount}`}
                            {` SocioMates`}
                        </small>
                    </>
                ) : (
                    <small className='text-primary font-weight-bold d-inline-block mb-0 text-primary align-items-center text-truncate align-self-center'>
                        0 SocioMate
                    </small>
                )}
            </div>

            {/* Modal for showing mutual friends */}
            <Modal
                isOpen={modalOpen}
                toggle={toggleModal}
                className="modal-dialog-centered modal-xs"
            >
                <ModalHeader toggle={toggleModal} className='bg-light'>
                    <h3>SocioMates ({sociomateFriendsCount})</h3>
                </ModalHeader>
                <ModalBody className='Modal_Body_Section py-1'>
                    {/* Search Input */}
                    <InputGroup className="input-group-merge d-flex px-3 py-1 my-3 rounded bg-light align-items-center mr-2">
                        <Search color='#666666' size={16} />
                        <Input
                            type='search'
                            placeholder="Search..."
                            className='coonection-input-style'
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>

                    {/* Mutual Friends List */}
                    <div className='modal-scroll'>
                        { filteredSociomates.map((data) => (
                            <Card className='m-2 shadow-sm' key={data?.userId}>
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="media media-new d-sm-flex">
                                            <div className="user-img align-items-top">
                                                <img
                                                    src={data?.profileImage}
                                                    className="connection-profile rounded-circle connection-profile-new"
                                                    alt={data?.fullName}
                                                />
                                                <span className="available-stats online"></span>
                                            </div>
                                            <div className="media-body d-md-block pl-1">
                                                <h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>
                                                    {data?.fullName}
                                                </h4>
                                                <div className='d-flex'>
                                                    <p className='user-name-style rem-mob-view'>
                                                        {data?.userName}  |  
                                                    </p>
                                                    <span>
                                                        <img
                                                            src={data?.flagURL}
                                                            className="ml-1 rem-mob-view-small"
                                                            alt="..."
                                                            width={19}
                                                            height={13}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Button color='primary' className='rem-mob-view-small' outline>
                                            <Check size={15} />&nbsp;Following
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        ))}
                        {
                            sociomateList.length === 0 && (
                                <h3 className='text-center connection-sub-heading mt-4'>No SocioMates Found</h3>
                            )
                        }
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}
