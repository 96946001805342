import React from 'react';
import { useState } from 'react';
import { X } from 'react-feather';
import { Button, Modal, Nav, NavItem, NavLink, TabContent, TabPane, } from 'reactstrap';
import small1 from '../../NewComponents/IMG/cricleimg.png'

function ViewedAndLike(props) {
    const [active, setActive] = useState('1')
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const LikeViewStory = [
        {
            userImgView: small1,
            viewUserName: 'Riya Maurya',
            viewStoryTime: '12 min ago',
            btnName: 'Followers',
        },
        {
            userImgView: small1,
            viewUserName: 'Riya Maurya',
            viewStoryTime: '12 min ago',
            btnName: 'SocioMate',
        },
        {
            userImgView: small1,
            viewUserName: 'Riya Maurya',
            viewStoryTime: '12 min ago',
            btnName: 'SocioMate',
        },
        {
            userImgView: small1,
            viewUserName: 'Annu Rajput',
            viewStoryTime: '12 min ago',
            btnName: 'Followers',
        },
        {
            userImgView: small1,
            viewUserName: 'Manni Singh',
            viewStoryTime: '12 min ago',
            btnName: 'SocioMate',
        },
        {
            userImgView: small1,
            viewUserName: 'Jaya Maurya',
            viewStoryTime: '12 min ago',
            btnName: 'SocioMate',
        },
        {
            userImgView: small1,
            viewUserName: 'Rashami Maurya',
            viewStoryTime: '13 min ago',
            btnName: 'SocioMate',
        },
        {
            userImgView: small1,
            viewUserName: 'Riya Maurya',
            viewStoryTime: '12 min ago',
            btnName: 'Followers',
        },
        {
            userImgView: small1,
            viewUserName: 'Nisha Maurya',
            viewStoryTime: '12 min ago',
            btnName: 'SocioMate',
        },
        {
            userImgView: small1,
            viewUserName: 'Neelu Sharma',
            viewStoryTime: '12 min ago',
            btnName: 'SocioMate',
        }
    ]
    return (
        <React.Fragment>

            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} className='modal-dialog-centered modal-md'>
                    <div role='button' className='text-right'>
                        <X className='mt-2 mr-2' onClick={props.likeViewModal} />
                    </div>
                    <Nav tabs>
                        <NavItem className='NavItemThisColor'>
                            <NavLink
                                active={active === '1'}
                                onClick={() => {
                                    toggle('1')
                                }}
                            >
                                45 Viewer
                            </NavLink>
                        </NavItem>
                        <NavItem className='NavItemThisColor'>
                            <NavLink
                                active={active === '2'}
                                onClick={() => {
                                    toggle('2')
                                }}
                            >
                                27 Likes
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className='py-50' activeTab={active}>
                        <TabPane tabId='1'>
                            <div className='modal-scroll '>
                                {
                                    LikeViewStory.map((data,index) => {
                                        return (
                                            <div key={index} className='d-flex justify-content-between align-items-center StoryThis_Section_Box StoryViewHoverColor cursor-p'>
                                                <div className='d-flex align-items-center'>
                                                    <img src={data.userImgView} width={70} className='img-fluid' alt='photo' />
                                                    <div className='ml-2'>
                                                        <p className='StoryThisTextColor'>{data.viewUserName}</p>
                                                        <p><span className='text-muted'>{data.viewStoryTime}</span></p>
                                                    </div>
                                                </div>
                                                <div className='mr-3'>
                                                    <Button color='primary' className='px-4 '>{data.btnName}</Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </TabPane>
                        <TabPane tabId='2'>
                            <div className='modal-scroll '>
                                {
                                    LikeViewStory.map((data) => {
                                        return (
                                            <div className='d-flex justify-content-between align-items-center StoryThis_Section_Box StoryViewHoverColor cursor-p'>
                                                <div className='d-flex align-items-center'>
                                                    <img src={data.userImgView} width={70} className='img-fluid' alt='photo' />
                                                    <div className='ml-2'>
                                                        <p className='StoryThisTextColor'>{data.viewUserName}</p>
                                                        <p><span className='text-muted'>{data.viewStoryTime}</span></p>
                                                    </div>
                                                </div>
                                                <div className='mr-3'>
                                                    <Button color='primary' className='px-4 '>{data.btnName}</Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </TabPane>
                    </TabContent>
                </Modal>
            }
        </React.Fragment>
    );
}

export default ViewedAndLike;