
import React, { useState } from 'react'
import Rating from '@mui/material/Rating';
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
// import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Col, Row, Button, Badge } from 'reactstrap'
import cricleimg from '../../../NewComponents/IMG/cricleimg.png'
import cricleimg1 from '../../../NewComponents/IMG/cricleimg1.png'
import AvatarGroupComponent from '../../Search-Components/AvatarGroupComponent'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal';
import InviteModal from '../../biz-components/AllTypesModal/InviteModal';
import ReportModal from './ModalGrid/ReportModal';

const GridBizPage = () => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const [InviteOpen, setInviteOpen] = useState(false)
    const toggleInvite = () => {
        setInviteOpen((Preview) => !Preview)
    }

    //================= BizPage_ Dummay_CardData ====================//

    const Bizpage = [
        {
            timeline: 'Turn of page notification ',
            topsNumber: '91',
            trendingNumber: '01',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Action Pack',
            content1: 'Music Equipment',
            num: '52400',
            img: cricleimg,
            button: <button className='btn btn-primary'>Following</button>,
            button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>
        },
        {
            timeline: 'Turn of page notification ',
            topsNumber: '92',
            trendingNumber: '02',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Music Equipment',
            content1: 'Music Equipment',
            num: '60400',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>
        },
        {
            timeline: 'Turn of page notification ',
            topsNumber: '93',
            trendingNumber: '03',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Action Pack',
            content1: 'Music Equipment',
            num: '70400',
            img: cricleimg,
            button: <button className='btn btn-primary'>Following</button>,
            button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>
        },
        {
            timeline: 'Turn of page notification ',
            topsNumber: '94',
            trendingNumber: '04',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            num: '92400',
            content: 'Music Equipment',
            content1: 'Music Equipment',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>
        },
        {
            timeline: 'Turn of page notification ',
            topsNumber: '94',
            trendingNumber: '04',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            num: '92400',
            content: 'Music Equipment',
            content1: 'Music Equipment',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Invite</Button>
        }
    ]
    //=============== Main_return_function ==================//
    return (
        <div>
            <Row className='p-2'>
                {
                    Bizpage.map((data, index) => {
                        return (


                            // <Col xl='3' className='p-1' key={index}>
                            //     <div className='border roundedThisBorder'>
                            //         <div className='d-flex p-2'>
                            //             <Badge color='light-primary '>
                            //                 Trending #1
                            //             </Badge>
                            //             <Badge color='primary' pill className='pl-2'>
                            //                 Top #96
                            //             </Badge>
                            //         </div>
                            //         <div className="group-slide-box">
                            //             <div className="gallery-img-sublink-this-bizpage">
                            //                 <UncontrolledButtonDropdown>
                            //                     <DropdownToggle color='flat-primary' >
                            //                         <MoreHorizontal color='#FAF7F7' />
                            //                     </DropdownToggle>
                            //                     <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown'>
                            //                         <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share post</DropdownItem>
                            //                         <DropdownItem divider></DropdownItem>
                            //                         <DropdownItem > <NavLink to='/MyProfile' style={{ color: '#212529' }} > <Eye size={13} />&nbsp;&nbsp;View creator's profile </NavLink></DropdownItem>
                            //                         <DropdownItem divider></DropdownItem>
                            //                         <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                            //                         <DropdownItem divider></DropdownItem>
                            //                         <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                            //                     </DropdownMenu>
                            //                 </UncontrolledButtonDropdown>
                            //             </div>

                            //             <NavLink className="bizpagelink-blk">
                            //                 <img src="https://www.patriotsoftware.com/wp-content/uploads/2019/05/9-business-formulas-math-haters-cant-ignore.jpg" className="BizpageThisimg" alt='img' />
                            //                 <div className="biz-adminprofile-blk"><img src={data.img} alt='img' />
                            //                 </div>
                            //             </NavLink>

                            //             <div>
                            //                 <div><p className='BizPage_text_center'>{data.content}</p></div>
                            //                 <p className='Bizpagetabsfortextcenter'>{data.content1}</p>
                            //                 <p className='m-2'>
                            //                     <Rating name="read-only" value={3} readOnly />
                            //                 </p>
                            //                 <div className="d-flex justify-content-center align-items-center">
                            //                     <AvatarGroupComponent />
                            //                     <p className='People_Sociomate text-muted align-self-center'>+524 Followers</p>
                            //                 </div>
                            //                 <div className='d-flex justify-content-center m-2'>
                            //                     <p>{data.button}</p>
                            //                     <p className='ml-2'>{data.button1}</p>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </Col>

                            <Col xl='3' lg='4' md='6' sm='6' className='p-1' key={index} >
                                <div className='border roundedThisBorder on-hover-bg'>
                                    <div style={{ position: 'relative' }}>
                                        <div >
                                            <img src="/assets/images/group-img-1.jpg" className="BizpageThisimg " alt='img' style={{borderRadius:"5px 5px 0 0"}} />
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center py-1 mr-0 simple-linear' style={{ position: 'absolute', top: '0', left: '5px', right: '5px', borderRadius:'5px 5px 0px 0px' }} >
                                            <div className='d-flex p-1'>
                                               <NavLink to='/MyProfile'>
                                               <Badge style={{color:'white',backgroundColor:'rgb(129, 193, 75)'}} className='px-2 d-flex align-items-center light-font' >
                                                    Trending #{data.trendingNumber}
                                                </Badge>
                                               </NavLink>
                                               <NavLink to='/MyProfile'>
                                                <Badge style={{color:'white',backgroundColor:'rgb(129, 193, 75)'}}  pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                                    Top #{data.topsNumber}
                                                </Badge>
                                                </NavLink>
                                            </div>
                                            <div className=''>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color='flat-primary' style={{ margin: '0', padding: '0' }} >
                                                        <MoreHorizontal color='#FAF7F7' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific'>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share BizPage</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View Profile</NavLink></DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </div>

                                        <div className=" text-center w-100" style={{ position: 'absolute', bottom: '-50px' }} >
                                            <img src={data.img} alt='img' />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='text-center mt-5'>
                                            <NavLink to='/biz' style={{ color: 'black' }} >
                                                <h4 className='user-profile'>{data.content}</h4>
                                            </NavLink>
                                            <p className='text-muted' >{data.content1}</p>
                                        </div>

                                        <div className='text-center' >
                                            <p className='m-2'>
                                                <Rating name="read-only" value={4} readOnly />
                                            </p>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <AvatarGroupComponent />
                                            <p className='align-self-center'><span className='fw-bold' > &nbsp;&nbsp;524</span>&nbsp;&nbsp; Followers </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center m-2 mt-3 mb-3'>
                                        <p>{data.button}</p>
                                        <p className='ml-2'>{data.button1}</p>
                                    </div>

                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
            {/*================== Start_start_Modal_Section ====================*/}
            <InviteModal
                isOpen={InviteOpen}
                updateOpen={toggleInvite}
            />
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport} />
            {/*================== End_start_Modal_Section ======================*/}

        </div>
    )
}

export default GridBizPage