import React, { Component, useEffect, useState,Fragment } from 'react';
import { BellOff, Bookmark, Globe,AlertCircle, Link2, Lock, Share2 ,MoreHorizontal,Search} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink ,useNavigate} from "react-router-dom";
import Slider from "react-slick";
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import {  unFollowFromJoinedGroupAction, sendingSelectedReport, getInvitedPeopleToModalAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { sweetAlertConfirmation } from './SweetAlert';
import { AlertTriangle, MapPin, MoreVertical,Sliders } from 'react-feather';

import {
    getUserFollowerAction,
    postReShareAction,
  } from "../../../Services/Actions/UserProfile/getAllUserProfilePageAction";
  import { Col, Row } from "react-bootstrap";
  import { Checkbox, Pagination, Stack, PaginationItem } from "@mui/material";
  import { loadAllJoinedGroup } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
  import axios from 'axios';
  import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
    CustomInput
  } from "reactstrap";
import { faL } from '@fortawesome/free-solid-svg-icons';
import { loadAllGroupReportOption } from '../../../Services/Actions/Group/getAllGroupMainPageAction';

const JoinedGrpSection = (props) => {
    const dispatch = useDispatch()
    const navigate=useNavigate();
    const { getGroupReportedOption } = useSelector((state) => { return state.getAllReportOptionFromGroupData });
    const { postGroupReport } = useSelector((state) => { return state.postReportOptionGroupData });
    const { getInvitedPeopleToModalRequest } = useSelector((state) => { return state.getInvitedPeopleToModalData });


    const [modalOffNotification, setModalOffNotification] = useState(false);
    let [copyLink, setCopyLink] = useState('')
    const [radioValue, setRadioValue] = useState('')
    const [reportOpen, setReportOpen] = useState(false)
    const [fetchGroupId, setFetchGroupId] = useState('')
    const [remark, setRemark] = useState('')
    const [shareOpen, setShareOpen] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [followerData,setFollowerData]=useState([]);
    const [followingData,setFollowingData]=useState([]);
    const [error,setError]=useState('')
    const [checkedItems, setCheckedItems] = useState([]);
    const [count,setCount]=useState(0)
    let countryIds=[];
    const [searchkey1,setSearchKey1]=useState('');
    const [leaveOpen,setLeaveOpen]=useState(false)
    const [leaveId,setLeaveId]=useState('');
    const [success,setSuccess]=useState(false)
    const [grpId, setGrpId] = useState('')
    const [inviteOpen, setInviteOpen] = useState(false)
    const [filteredPeople, setFilteredPeople] = useState([]);
    const [friendsId, setFriendsId] = useState([])

    useEffect(() => {
      dispatch(loadAllJoinedGroup(props?.userId,pageIndex,pageSize))
      setSuccess(false)
    }, [pageIndex,pageSize,success])

  const getGroupJoinedGroup  = useSelector((state) => { return state.getAllJoinedGroupData });
  const { loading: loadingGroup, error: errorPinnedPage, result: resultData } = getGroupJoinedGroup;


    useEffect(() => {
        dispatch(
          loadAllGroupReportOption()
        );
      }, []);

    const toggleShareOpen = (e) => {
      e.stopPropagation();
      setShareOpen((prevState) => !prevState);
     };

      
    const navigateTo=(e,id,name,totalMembers,index)=>{
      e.stopPropagation();
      e.preventDefault();
      // navigate(``)
      navigate(`/GroupDetail/${id}/${name}/${totalMembers}/${index}`)
  }

  const pageCount = Math.ceil((props?.count)/12);
  const isPrevDisabled = pageIndex <= 0;
  const isNextDisabled = pageIndex >= pageCount - 1;
  
    const handlePageChange = (event, value) => {
      if (value - 1 === pageIndex) return;
  
      setPageIndex(value - 1);
    };

    const inviteFriends = (e) => {
      let updatedList = [...friendsId]
      if (e.target.checked) {
          updatedList = [...friendsId, e.target.value]
      } else {
          updatedList.splice(friendsId.indexOf(e.target.value), 1)
      }
      setFriendsId(updatedList)
  };
  
      
      const BasicsPagination = () => {
          return (
            <>
              <Stack>
                <Pagination
                     count={pageCount}
                     page={pageIndex + 1}
                     onChange={handlePageChange}
                     className="PaginationCounts"
                     renderItem={(item) => (
                       <PaginationItem
                         {...item}
                         disabled={
                           (item.type === 'previous' && isPrevDisabled) ||
                           (item.type === 'next' && isNextDisabled)
                         }
                       />
                     )}
                ></Pagination>
              </Stack>
            </>
          );
        };


    const isShareAllDisabled = checkedItems.length === 0;
    const postCopyLink = async (id, groupName, totNumber, index) => {
        copyLink = `${process.env.REACT_APP_IPURL}/GroupDetail/${id}/${groupName}/${totNumber}/${index}`
        setCopyLink(copyLink)
        navigator.clipboard.writeText(copyLink)
        sweetAlertConfirmation('Link Copied Success')
    };
    const toggle = () => setModalOffNotification(!modalOffNotification);
    const radioHandler = (event) => {
        setRadioValue(event)
    };

    useEffect(() => {
      const fetchData = async () => {
          try {
              const user = JSON.parse(localStorage.getItem('sociomeeUser'));
              if (user) {
                  const response = await axios.post(
                      `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                      { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                      { headers: { Authorization: `Bearer ${user.token}` } }
                  );
                  setFollowerData(response.data.data.successResult.rows);
              } else {
                  setError('no user found');
              }
          } catch (error) {
              setError(error.message);
          }
      };
      fetchData();
  }, [searchKey,pageIndex,pageSize]);
  
  useEffect(() => {
      const fetchData = async () => {
          try {
              const user = JSON.parse(localStorage.getItem('sociomeeUser'));
              if (user) {
                  const response = await axios.post(
                      `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                      { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                      { headers: { Authorization: `Bearer ${user.token}` } }
                  );
                  setFollowingData(response.data.data.successResult.rows);
              } else {
                  setError('no user found');
              }
          } catch (error) {
              setError(error.message);
          }
      };
      fetchData();
  }, [searchKey,pageIndex,pageSize]);
    //================== Num_Formatter_Function ==================//
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    
    const toggleLeaveOpen=()=>{
      setLeaveOpen(false)
      setLeaveId('');
  }

    const handleChange=(e)=>{
      setSearchKey1(e.target.value);
    }

    const shareAll = () => {
      sweetAlertConfirmation(`Your post shared to everyone`);
      setShareOpen((prevState) => !prevState);
      };

    const toggleReport = (id) => {
        setReportOpen((preview) => !preview)
        setFetchGroupId(id)
    }

    const toggleReport1 = () => {
      setReportOpen((preview) => !preview)
      setRadioValue('');
      // setFetchGroupId(id)
  }

    const handleCheckboxChange = (id) => {
      setCheckedItems(prevCheckedItems => {
        if (prevCheckedItems.includes(id)) {
          // Uncheck item
          return prevCheckedItems.filter(itemId => itemId !== id);
        } else {
          // Check item
          return [...prevCheckedItems, id];
        }
      });
    };
    const submitReportGroup = () => {
        dispatch(sendingSelectedReport(radioValue, fetchGroupId, remark))
        setReportOpen(false)
        setFetchGroupId('')
        sweetAlertConfirmation("Successfully Reported")
    }
    // ================== main_return_function==================//
    const leaving =(e,id)=> {
      setLeaveId(id);
      e.stopPropagation();
      setLeaveOpen(true);
    }

    const leaveGroup = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      try {
          const fetchData = async () => {
              try {
                  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                      const response = await axios.post(
                          `${process.env.REACT_APP_IPURL}/group/leave`,
                          {groupId:leaveId,isLeave:true,memberId:user.id},
                          { headers: { Authorization: `Bearer ${user.token}` } }
                      );
                      sweetAlertConfirmation('Successfully Left Group');
                      setLeaveOpen(false);
                      setSuccess(true)
                      props?.setUpdateCount(true)
              } catch (error) {
                setError(error)
              }
          };
        
        fetchData();
       
      } catch (error) {
       setError(error);
      }
    };

    const filterData = (data) => {
      if (!props?.searchKey) {
        return data;
      }
  
      return data.filter((item) => {
        return Object.values(item).some((value) => 
          value && value.toString().toLowerCase().includes(props?.searchKey.toLowerCase())
        );
      });
    };
  
    const filteredJoinedGroupRows = filterData(resultData || []);

    const toggleInvite = (id) => {
      dispatch(getInvitedPeopleToModalAction(id))
      setGrpId(id)
      setInviteOpen((Preview) => !Preview)
  }


    // console.log("data===============================",resultData)
    return (
        <Fragment>
            {
                resultData?.length > 0
                    ?
                    <div className="group-custom-block">
                      <Row className="mt-3">
                          {filteredJoinedGroupRows?.map((data, index) => (
                              <Col md="3" className="mb-5" key={index} style={{ marginBottom: '6rem !important' }}>
                                <div className="BizPage_Card_section">
                                  <div 
                                      className="BizPage_option_menu" 
                                      style={{ 
                                          height: '200px', // Adjust the height as needed
                                          display: 'flex', 
                                          flexDirection: 'column', 
                                          justifyContent: 'space-between', 
                                          paddingRight: "15px"
                                      }}
                                  >
                                      <MoreHorizontal 
                                          size={20} 
                                          className="dropbtn-gal" 
                                          data-bs-toggle="dropdown" 
                                          aria-haspopup="true" 
                                          aria-expanded="false"
                                      />
                                      <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal" style={{width:"190px"}}>
                                          <ul>
                                              <li>
                                                  <a onClick={(e) => toggleShareOpen(e)}>
                                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                                          <Share2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                          Share Group
                                                      </span>
                                                  </a>
                                              </li>
                                              <li onClick={toggle}>
                                                  <a>
                                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                                          <BellOff size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                          Turn off Notification
                                                      </span>
                                                  </a>
                                              </li>
                                              <li onClick={() => toggleInvite(data.id)}>
                                                  <a>
                                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                                      <AlertCircle size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                          Invite
                                                      </span>
                                                  </a>
                                              </li>
                                              <li onClick={(e) => leaving(e, data.id)}>
                                                  <a>
                                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                                          <Bookmark size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                          Leave Group
                                                      </span>
                                                  </a>
                                              </li>
                                              <li onClick={() => toggleReport(data?.id)}>
                                                  <a>
                                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                                          <AlertTriangle size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                          Report Group
                                                      </span>
                                                  </a>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                                      <img onClick={(e)=>navigateTo(e,data.id,data.name,data.totalMembers,index)}
                                          alt='' 
                                          src={data.coverPicUrl} 
                                          className="img-fluid group-img" 
                                          style={{ 
                                              width: '100%', 
                                              height: '200px', // Fixed height
                                              objectFit: 'cover' // Maintain aspect ratio
                                          }}             
                                      />
                                  <div 
                                      className="group-content" 
                                  >
                                      <div className='d-flex align-items-center justify-content-center'>
                                          <span className='text-capitalize'>{data.groupName.substring(0, 10)}
                                              {data.groupName.length >= 10 && ' ...'}</span>
                                      </div>
                                      {data.privacy === 'public' ? (
                                          <div className="member-blk" style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              textAlign: 'center'
                                          }}>
                                              <span className='text-capitalize'>
                                                  <Globe style={{ marginTop: '-3px' }} color='#4D4D4D' size={12} /> {data.privacy && data.privacy}
                                              </span>  • 
                                              <span>
                                                  {data.totalMembers > 0 ? (
                                                      <>
                                                          {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members'}
                                                      </>
                                                  ) : (
                                                      <p>Be the first</p>
                                                  )}
                                              </span>
                                          </div>
                                      ) : (
                                          <div className="Suggestmember-blk" style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              textAlign: 'center'
                                          }}>
                                              <span className='text-capitalize'>
                                                  <Lock style={{ marginTop: '-3px' }} color='#4D4D4D' size={12} /> {data.privacy && data.privacy}
                                              </span> •
                                              <span>
                                                  {data.totalMembers > 0 ? (
                                                      <>
                                                          {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members'}
                                                      </>
                                                  ) : (
                                                      <p>Be the first</p>
                                                  )}
                                              </span>
                                          </div>
                                      )}
                                      {/* <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                          <p>{data.paragraph}</p>
                                      </div> */}
                                      <div className="group-buttons-blk mt-3" onClick={(e)=>navigateTo(e,data.id,data.name,data.totalMembers,index)}>
                                          {/* <NavLink to={`/GroupDetail/${data.id}/${data.groupName}/${data.totalMembers}/${index}`}> */}
                                              <Button className="Invite_Join_Button" color='primary'>View Activity</Button>
                                          {/* </NavLink> */}
                                          <Button 
                                              className="Invite_Decline_Button ml-2" 
                                              outline
                                              onClick={(e) => leaving(e, data.id)}
                                          >
                                              Leave
                                          </Button>
                                      </div>
                                  </div>
                                  </div>
                              </Col>
                          ))}
                      </Row>
                    </div>

                    
                    : <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '15vh', // Adjust the height as needed to fill the available space
                      width: '100%' // Ensure it takes the full width of the container
                    }}>
                    <h2 style={{ textAlign: 'center' }}>No Joined Groups</h2>
                  </div>
                }
            <Modal isOpen={modalOffNotification}
                toggle={toggle}
            >
                <ModalBody>
                    <p className='text-danger' align='center'>Turn off Notification API under development...</p>
                </ModalBody>
            </Modal>


            {
                reportOpen !== false &&
                <Modal scrollable isOpen={reportOpen} toggle={toggleReport1} className='modal-dialog-centered' >
                    <ModalHeader className="modal_Header" toggle={toggleReport1} >
                        <div className='Report_modal_Heading_section' >
                            <span>Report a Group</span>
                        </div>
                    </ModalHeader>

                    <ModalBody>
                        <div className='Modal_Body_Section'>
                            <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                        </div>
                        {getGroupReportedOption && getGroupReportedOption.data.successResult.map((data) => (
                            <div className='d-flex justify-content-between mt-4 Report_Section'>
                                <h4 className='text-capitalize'>{data.reportText}</h4>
                                <div onChange={() => { radioHandler(data?.id) }}>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        className="Check"
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='d-flex justify-content-center mt-3'>
                            <Input
                                id="ReportName"
                                name="ReportName"
                                placeholder="Write a comment..."
                                type="Report"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </div>
                        <hr className='w-100 text-muted' />
                        <div className='text-center'>
                            <Button color='primary' className="Report_summit_btn" 
                            onClick={submitReportGroup}
                            disabled={!radioValue}
                            >Submit</Button>
                            {/* <Button toggle={toggleReport} className="Report_cancel_btn ml-2" outline>Cancel</Button> */}
                            <Button className="Report_cancel_btn ml-2" onClick={toggleReport1}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>
            }

<div className="vertically-centered-modal">
                    {shareOpen && (
        <Modal
          isOpen={shareOpen}
          toggle={toggleShareOpen}
          className="modal-dialog-centered"
        >
          <div className="w-100 border-bottom">
            <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
              <h4>Share People</h4>
              <span style={{ cursor: isShareAllDisabled ? "not-allowed" : "pointer",alignItems:'center' }}
                  onClick={!isShareAllDisabled ? shareAll : undefined}
                  className={isShareAllDisabled ? 'disabled' : ''} 
              >
                Share
              </span>
            </div>
          </div>
          <ModalBody>
            <Row className="mt-2">
              <Col md="8" className="Like_search">
                <div className="profile-search-blk w-100">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                    value={searchKey}
                    autoFocus
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md="4" className="d-flex justify-content-end">
                <UncontrolledButtonDropdown color="secondary" outline>
                  <DropdownToggle
                    outline
                    className="border border-muted ml-3"
                  >
                    <Sliders className="Slider_Filter" size={12} />
                    &nbsp;&nbsp;Filter
                  </DropdownToggle>
                  <DropdownMenu className="DropDown_Group_item">
                    <DropdownItem onClick={() => setCount(0)}>
                      Followers
                    </DropdownItem>
                    <DropdownItem onClick={() => setCount(2)}>
                      Followings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
              <Row className="mt-3">
                {count === 0 && followerData?.filter(data =>
                  data.fullName.includes(searchKey) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                  data.followBackStatus.includes(searchKey)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
                {count === 2 && followingData?.filter(data =>
                  data.fullName.includes(searchKey) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                  data.followBackStatus.includes(searchKey)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </Row>
             
            </Row>
          </ModalBody>
        </Modal>
      )}
       {leaveOpen && (
                      
                      <Modal
                      isOpen={leaveOpen}
                      toggle={toggleLeaveOpen}
                      className="modal-dialog-centered"
                      >
                      <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                          <h4>Leave Group</h4>
                      </ModalHeader>
                      <ModalBody>
                          <div className="d-flex justify-content-center modalHeading_Section">
                          <p>Do you really want to leave the Group ?</p>
                          </div>
                          <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                          <small>If</small> YES! <small>please click on ok</small>
                          </h1>
                          <div className="d-flex justify-content-center  mt-4">
                          <Button onClick={toggleLeaveOpen} outline style={{ width: '100px' }}>
                              Cancel
                          </Button>
                          <Button
                              onClick={(e)=>leaveGroup(e)}
                              color="primary"
                              className="ml-3 "
                              style={{ width: '100px' }}
                          >
                              OK
                          </Button>
                          </div>
                      </ModalBody>
                      </Modal>
                  )}

          <div className='InviteModal'>
                            {inviteOpen !== false &&
                                <Modal scrollable isOpen={inviteOpen} 
                                toggle={toggleInvite} 
                                className='modal-dialog-centered'  >
                                    <ModalHeader className='d-flex justify-content-center'>
                                        <div className='Invitation_Heading_Section'>
                                            <h4>Send Invitation</h4>
                                        </div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col md="12">
                                                <div className='Invite_SocioMate_Heading'>
                                                    <h4>Invite to SocioMates</h4>
                                                </div>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md="12">
                                                <div className="search-block-podcast">
                                                    <div className="input-search-blk">
                                                        <Search className="search-svg" />
                                                        <Input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" value={searchKey} data-bs-target="#peopleSearch" onChange={(e)=>setSearchKey(e.target.value)} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12">
                                                <div className='Invitaion_List_Heading'>
                                                    <h4>{getInvitedPeopleToModalRequest?.length ? 'Friends (' + getInvitedPeopleToModalRequest?.length + ')' : ''}</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                {
                                                    filteredPeople.map((data) => (
                                                        <div className='d-flex justify-content-between mt-3'>
                                                            <div className='d-flex align-items-center '>
                                                                <img style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} src={data.profileImage} alt="profile" className='img-fluid group-img'
                                                                />
                                                                <div className='Invitaion_User_Profile ml-1'>
                                                                    <h4>{data.fullName}</h4>
                                                                    <p>{data.userName}</p>
                                                                </div>
                                                            </div>
                                                            <div className='Block-btns-blk'>
                                                                <CustomInput
                                                                    id={data.id} style={{ cursor: "pointer" }}
                                                                    value={data.id}
                                                                    defaultChecked={!!friendsId[data?.id]}
                                                                    onChange={(e) => {
                                                                        inviteFriends(e);
                                                                    }}
                                                                    type="checkbox"
                                                                    className='customcheckboxForFilter'

                                                                />
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12">
                                                <div className='d-flex justify-content-center'>
                                                    {
                                                        getInvitedPeopleToModalRequest?.length
                                                            ?
                                                            <Button 
                                                            // onClick={sendInvitationData} 
                                                            className="Invitation_Send_btn">
                                                                Send </Button>
                                                            :
                                                            <p className='text-danger'>No contacts exist to show</p>

                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            }
                        </div>
                </div>

                
        <div className='d-flex align-items-center justify-content-center PaginationCounts'>
            <BasicsPagination/>
        </div>
        </Fragment>
    )
}

export default JoinedGrpSection