import React, { useState } from 'react'
import { Grid, List } from 'react-feather'
import { Button } from 'reactstrap'
import GridVideo from './Grid-Components/GridVideo'
// import ListCompoentVideo from './List-Components/ListCompoentVideo'
import ListMedia from './List-Components/ListMedia'

const TopsVideos = () => {
    const [data, setdata] = useState(true)
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='TrendingPeoplethistext'></p>
                <div className='list-grid-toggle rem-mob-view rem-beyond-web' style={{marginRight:'8px'}}>
                    <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                        <Grid size={16} />
                    </Button>
                    <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
                        <List size={16} />
                    </Button>
                </div>
            </div>
            {
                data ? <GridVideo /> : <ListMedia display={'d-none'} />
            }
        </div>

    )
}
export default TopsVideos