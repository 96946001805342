import React from 'react'
import { Fragment } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { blockBizPageUserAction, getFollowedByBizPageUserAction, getBlockedBizPageUserAction } from '../../../../Services/Actions/UserProfile/getAllUserProfilePageAction'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const RemoveModal = (props) => {
    const blockBizPageUser = useSelector((state) => { return state.blockBizPageUserData});
    const { loading: loadingblockBizPageUser, error: errorblockBizPageUser, result: resultblockBizPageUser} = blockBizPageUser 

console.log('resultblockBizPageUser', resultblockBizPageUser)

    let dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getBlockedBizPageUserAction(id)) 
    }, [resultblockBizPageUser]);

    useEffect(() => {
        dispatch(getBlockedBizPageUserAction(id)) 
    }, []);

    const unBlockUser = () => {
        dispatch(blockBizPageUserAction(id, props.userId, props.unblock)) 
        dispatch(getFollowedByBizPageUserAction(id)) 
        props.updateOpen()
        // dispatch(getBlockedBizPageUserAction(id)) 
    }
    
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                    <ModalHeader className="BizDelete_Heading">
                        <h2>Remove Blocked User</h2>
                    </ModalHeader>
                    <ModalBody>
                        <div className='text-center Remove_Modal_section'>
                            <p>Are you sure that you want to unblock your <br /> User ?</p>
                        </div>
                        <div className='mt-4 text-center'>
                            <Button onClick={props.updateOpen} className="w-25" outline>Cancel</Button>
                            <Button color="primary" className="ml-2 w-25" onClick={unBlockUser} >Confirm</Button>

                        </div>

                    </ModalBody>
                </Modal>
            }
        </Fragment>
    )
}

export default RemoveModal