import React from 'react'
import { Card } from 'reactstrap'
import '../../Ad-Manager/Ads.css'

const CommonHeader = () => {
    return (
        <Card className='border-0'>
            <div className="d-flex">
                <div className="d-flex align-items-center p-2 border-right">
                    <button className="ad-count-btn responsiveForTag">3</button>
                    <p className="ad-count-step px-3">Step 3</p>
                </div>
                <div className="p-2 pl-3">
                    <h4>Configure Your Ad</h4>
                    <p className="text-muted">Set-up your ad campaign with an image and description</p>
                </div>
            </div>
        </Card>
    )
}

export default CommonHeader