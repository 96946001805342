import React, { Fragment,useEffect } from "react";
import { Edit2, Image, XCircle } from "react-feather";
import { Card } from "reactstrap";
import CircularStatic from "../CircularProgressWithLabel";

const MediaNew = ({
  toggle,
  handleImageSelect,
  selectedImages,
  setSelectedImages,
  toggleTheFile,
  isLoading,
  clearImages,
  setMediaPost,
  postEditValue,
  captions,
  setCaptions
}) => {

  const handleRemoveMedia = (indexToRemove) => {
    const updatedSelectedImages = selectedImages?.filter((_, index) => index !== indexToRemove);
    const updatedCaption = captions?.filter((_, index) => index !== indexToRemove)
      setSelectedImages(updatedSelectedImages);
      setCaptions(updatedCaption)
      if (updatedSelectedImages.length === 0) {
      clearImages(); 
      setCaptions([])
    }
  };

  


  const handleChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setMediaPost((prevMediaPost) => {
      const updatedMediaPost = [...prevMediaPost, ...newFiles];
      return [...new Map(updatedMediaPost.map((file) => [file.name, file])).values()];
    });
  };

  







  // ========================>>> preview section <<<=======================
  const renderMediaPreviews = () => (
    <div className="Media_Img_section">
      {selectedImages.length > 0 && selectedImages.map((media, index) => {
        // Get the file extension by splitting the filename
        const fileExtension = media.split('.').pop().toLowerCase();
        const videoFormats = ["mp4", "mpg", "mp2", "mpeg", "mpe", "mpv", "ogg", "m4p", "m4v"]; 
        const imageFormats = ["png", "jpg", "jpeg", "gif", "tiff", "psd","pdf", "eps", "ai", "indd", "raw", "jfif"]; 
        // Check if the file is a video or image
        const isVideo = videoFormats.includes(fileExtension);
        const isImage = imageFormats.includes(fileExtension);
  
        return (
          <div key={index} className="border" style={{ borderRadius: "5px", position: "relative" }}>
            {isVideo ? (
              <video width="100%" height="300" controls>
                <source src={process.env.REACT_APP_IMAGEURL + media} type={`video/${fileExtension}`} />
              </video>
            ) : isImage ? (
              <img src={process.env.REACT_APP_IMAGEURL + media} className="img-fluid" alt={`Media ${index}`} />
            ) : null}
  
            <div
              className="remove-icon"
              onClick={() => handleRemoveMedia(index)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                padding: "5px",
                cursor: "pointer",
              }}
            >
              <XCircle color="#ffffff" size={20} />
            </div>
          </div>
        );
      })}
    </div>
  );
  

  // ===================>>> main section <<=======================
  return (
    <Fragment>
      <Card
        className={
          selectedImages &&  selectedImages.length < 1
            ? "Addmedia_sub_section"
            : selectedImages.length > 1
            ? "AddmediaOne_sub_section"
            : null
        }
      >
        <div className="Img_right_section" onClick={() => toggle("/modal/0")}>
        {selectedImages.length === 0 && <XCircle />}
        </div>
        {selectedImages.length === 0  ? (
          <div onClick={toggleTheFile} className="AddPhotoSection">
            <div className="text-center">
              <Image />
              <h3 className="py-2">Add Photo/ Videos</h3>
              <p>or drag and drop</p>
              {isLoading && <CircularStatic />}
            </div>
            <input
              type="file"
              multiple
              onChange={(e) => {
                handleImageSelect(e);
                handleChange(e);
              }}
              className="InputFile d-none"
            />
          </div>
        ) : (
          renderMediaPreviews()
        )}
        <div className="Img_right_section" onClick={() => { setMediaPost([]); clearImages(); }} />
        <div className="ButtonContainer">
          {selectedImages.length > 0 && (
            <div
              className="Edit_left_section"
              onClick={() => toggle("/modal/8")}
            >
              <Edit2 color="#808080" size={15} />
              &nbsp;{selectedImages.length === 1 ? "Edit" : "Edit All"}
            </div>
          )}
          <div
            className={`AddMore_left_section ${selectedImages.length === 0 ? 'full-width' : (selectedImages.length === 1 ? 'ml-2' : 'ml-4')}`}
            onClick={toggleTheFile}
          >
            <input
              type="file"
              multiple
              onChange={(e) => {
                handleImageSelect(e);
              }}
              className="InputFile d-none"
            />
            <Image color="#808080" size={15} />
            &nbsp;Add Photo/ Videos
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default MediaNew;
