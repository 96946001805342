
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalConfig = [
    {
        id: 1,
        modalClass: 'modal-xs'
    }
]

export const ArchivedModal = (props) => {
    const [modal, setModal] = useState(null)

    const toggleModal = id => {
        if (modal !== id) {
            setModal(id)
        } else {
            setModal(null)
        }
    }

    const renderModal = ModalConfig.map(item => {
        return (
            <div className={'theme-{item.modalColor}'} key={item.id}>
                <div onClick={() => toggleModal(item.id)} key={item.title} className='shotz-menu-hover-style'>
                    {/* <h5 className='ml-3'>{props.icon}{props.optioneName}</h5> */}
                    <h5 className=''>{props.icon}{props.optioneName}</h5>
                </div>
                <Modal
                    // isOpen={modal === item.id}
                    isOpen={props.isOpen}
                    // toggle={() => toggleModal(item.id)}
                    toggle={props.singleArchiveModal}
                    className={`modal-dialog-centered ${item.modalClass}`}
                    key={item.id}
                >
                    <ModalHeader className='d-flex justify-content-center Media_header'>
                        <h3>{props.heading}</h3>
                    </ModalHeader>
                    <ModalBody className='d-flex justify-content-center  modalHeading_Section '>
                        <h5 className='py-2 name-style'>{props.discription}</h5>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center border-0 justify-content-center  modalHeading_Section '>
                        {/* <Button variant='outline-secondary' className='w-25' onClick={() => toggleModal(item.id)}> */}
                        <Button variant='outline-secondary' className='w-25' onClick={props.singleArchiveModal}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        {/* <Button variant='primary' className='w-25' onClick={() => toggleModal(item.id)}> */}
                        <Button variant='primary' className='w-25' onClick={props.singleArchiveModal}>
                            <span className='ml-1'>Ok</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    })

    return <div className='demo-inline-spacing'>{renderModal}</div>
}

export const ShotzPopup = (props) => {
    const [modal, setModal] = useState(null)
    const toggleModal = id => {
        if (modal !== id) {
            setModal(id)
        } else {
            setModal(null)
        }
    }

    const renderModal = ModalConfig.map(item => {
        return (
            <div className={'theme-{item.modalColor}'} key={item.id}>
                <div onClick={() => toggleModal(item.id)} key={item.title} className='shotz-menu-hover-style' >
                    {/* <h5 className='ml-3'>{props.icon}{props.optioneName}</h5> */}
                    <h5 className=''>{props.icon}{props.optioneName}</h5>
                </div>
                <Modal
                    // isOpen={modal === item.id}
                    isOpen={props.isOpen}
                    // toggle={() => toggleModal(item.id)}
                    toggle={props.singleDownloadModal}
                    className={`modal-dialog-centered ${item.modalClass}`}
                    modalClassName={item.modalColor}
                    key={item.id}>
                    <ModalHeader className='d-flex justify-content-center Media_header'>
                        <h3>{props.heading}</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h5 className='py-2 name-style'>{props.discription}</h5>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center border-0 justify-content-center  modalHeading_Section '>
                        {/* <Button variant='outline-secondary' className='w-25' onClick={() => toggleModal(item.id)}> */}
                        <Button variant='outline-secondary' className='w-25' onClick={props.singleDownloadModal}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        {/* <Button variant='primary' className='w-25' onClick={() => toggleModal(item.id)}> */}
                        <Button variant='primary' className='w-25' onClick={props.singleDownloadModal}>
                            <span className='ml-1'>Ok</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    })

    return <div className='demo-inline-spacing'>{renderModal}</div>
}