import { Pagination, Stack } from '@mui/material'
import React from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import play from '../../../NewComponents/IMG/play.png'
import { useState } from 'react'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { listDataMedia } from '../DummyArray'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'


const ListMedia = ({ display, space }) => {
    const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]


    return (
        <>
            <div>

                <Row className=''>
                    <Col md='12' className='' >
                        {/* -------------------------------------------------Dynamic list data rendering------------------------------------------------------------------------- */}
                        {listDataMedia.map((data, index) => (

                            <div className='border rounded my-2  on-hover-bg p-2' style={{ position: 'relative' }} key={index}  >
                                {/* <div color={`primary ${display}`} style={{ position: 'absolute', top: '8px', left: '4px', zIndex: '5', fontSize: '12px' }} className='rotated-text trending-number-location' ># {data.trendingNumber}</div> */}
                                <Row className='d-flex align-items-center px-2 py-1'>
                                    {/* <div className='d-flex'>
                                        <div className='right-triangle' style={{ position: 'absolute', top: '0px', zIndex: '4', left: '0' }} ></div>

                                    </div> */}
                                    <Col xl='8'>
                                        <div className='d-flex'>
                                            <div className='d-flex align-items-center'>
                                                <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center rem-mob-view'>
                                                    <p className='PilesThistags text-center'>Top</p>
                                                    <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                                </div>

                                                <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                    <p className='PilesThistags text-center'>Trending</p>
                                                    <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center ' data-bs-toggle="modal" data-bs-target="#imageModel">
                                                <div style={{ position: 'relative' }} className='ml-4 mt-1' >
                                                    <img src='https://images.unsplash.com/photo-1563387852576-964bc31b73af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=907&q=80' width={80} height={80} className='rounded ArticleShowdo' alt=''/>
                                                    {data.video ? <div className='MediaThisVideo' style={{ position: 'absolute' }} >
                                                        <img src={play} width={10} className='play' alt='play' />
                                                    </div> : null }
                                                </div>

                                                <div className='ml-2 mt-2 align-self-start'>
                                                    <h4>{data.caption}</h4>
                                                    {/* <p className='mt-1'>more user-centric <br />
                                                        {seemore ? 'by their very nature,Through your' : 'bright event,boost'} <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '...Read more'}</span> </p> */}
                                                    {/* <p className='text-muted'>19 sept at 07:00 pm</p> */}
                                                    {/* <Badge color='primary' pill className={space}> */}
                                                    <small className='mt-1 mr-3 text-muted ' data-bs-toggle="modal" data-bs-target="#imageModel">{'A book or other written or printed work,in terms of its content rather than its real form.'.slice(0, 70) + '...'}</small> 
                                                    <div className='d-flex' >
                                                        <span className='text-primary' ></span>
                                                        <span className='text-primary px-2' ></span>
                                                    </div>
                                                    {/* </Badge> */}
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center ml-2'>
                                                <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo'  alt=''/>
                                                <div className='ml-3'>
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }}> <p className='user-profile' >{data.name}</p> </NavLink>
                                                    <small className='text-muted  text-nowrap light-for-date'>{data.dateandtime}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl='3'>
                                        <div className='d-flex justify-content-center ml-5 '>
                                            <div className='d-flex align-items-center'>
                                                <div onClick={() => handleLike()}>
                                                    {liked ?
                                                        <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                        :
                                                        <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                    }
                                                </div>
                                                11.2k
                                            </div>
                                            <div className='d-flex align-items-center px-3' data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                                </div>
                                                11.2k
                                            </div>
                                            <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                                </div>
                                                11.2k
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl='1'>
                                        <div className='text-center ml-4'>
                                            <UncontrolledButtonDropdown className='align-self-center'>
                                                <DropdownToggle className='p-0 m-0' color='flat' >
                                                    <MoreHorizontal size={19} color='#999999' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation '>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share post</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }}><Eye size={13} />&nbsp;&nbsp;View creator's profile </NavLink> </DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        ))}
                        {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}

                        {/* <div className='border rounded mt-3'>
                            <Row className='d-flex align-items-center p-4'>
                                <div className='d-flex'>
                                    <Badge color={`light-primary ${display}`}>
                                        Trending #1
                                    </Badge>
                                    <Badge color='primary' pill className={space}>
                                        Top #96
                                    </Badge>
                                </div>
                                <Col xl='7'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex justify-content-start align-items-center mt-2'>
                                            <img src='https://images.unsplash.com/photo-1563387852576-964bc31b73af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=907&q=80' width={80} height={80} className='rounded ArticleShowdo' />
                                            <div className='ml-2'>
                                                <b>This is caption text</b>
                                                <p className='mt-1'>more user-centric <br />
                                                    {seemore ? 'by their very nature,Through your' : 'bright event,boost'} <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '...Read more'}</span>
                                                </p>
                                                <p className='text-muted'>19 sept at 07:00 pm</p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' />
                                            <div className='ml-1'>
                                                <p>Floyd Miles</p>
                                                <p className='text-muted'>19 sept at 07:00 pm</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='4'>
                                    <div className='d-flex justify-content-between ml-2'>
                                        <div className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                            </div>
                                            11.2k
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='1'>
                                    <div className='text-end'>
                                        <UncontrolledButtonDropdown className='align-self-center'>
                                            <DropdownToggle className='p-0 m-0' color='flat' >
                                                <MoreHorizontal size={19} color='#000000' />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>

                            </Row>
                        </div> */}


                        <div className='d-flex align-items-center py-1' >

                            <Row className='w-100'>
                                {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                                <Col xl='3' className='d-flex align-items-center' >
                                    <span className='' >
                                        {/* <p>Total Media Count : <span className='text-primary' >{listDataMedia.length}</span></p> */}
                                        <p>Showing 1 to {listDataMedia.length} of 200 entries</p>
                                    </span>
                                </Col>

                                {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                                <Col xl='6' className='d-flex justify-content-center' >
                                    <div className='d-flex p-2 mx-auto'  >
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={10}
                                                color='primary'
                                                size="large" />
                                        </Stack>
                                    </div>
                                </Col>

                                <Col xl='3' >
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
                <ShareEventModal
                    isOpen={shareOpen}
                    updateOpen={toggleShareOpen}
                />
                {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}
                {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}

                <ReportModal
                    reportOpen={reportOpen}
                    toggleReport={toggleReport}
                />
                <div className='Review_Report_modal'>
                    {
                        reportOpen !== false &&
                        <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                                <h3>Report a Post</h3>
                            </ModalHeader>
                            {/* ---------------------------------------------------ModalBody Start---------------------------------------------------- */}

                            <ModalBody>
                                <div className='Modal_Body_Section'>
                                    <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                                </div>
                                {
                                    ReportDataInfo.map((data) => (
                                        <div className='d-flex justify-content-between mt-4 Report_Section'>
                                            <h4>{data.name}</h4>
                                            <div>
                                                <Input
                                                    name="radio1"
                                                    type="radio"
                                                    className="Check"
                                                />
                                                {' '}
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className='d-flex justify-content-center mt-3'>
                                    <Input
                                        id="ReportName"
                                        name="ReportName"
                                        placeholder="Write a comment..."
                                        type="Report"
                                    />
                                </div>
                                <hr className='w-100 text-muted' />
                                <div className='text-center'>
                                    <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                    <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                                </div>
                            </ModalBody>

                        </Modal>

                    }

                </div>
                {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}
            </div>
        </>
    )
}

export default ListMedia