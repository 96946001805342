import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Modal from "react-modal";
import {
  AlertPostDisplayMild,
  AlertPostDisplayModerate,
  AlertPostDisplaySevere,
  DfltUploadPcPost,
  DoubleMediaNoDespPost,
  EpisodePodcast,
  EventPostDisplay,
  FreeSeriesPcPost,
  MarketPlacePost,
  MarketPlaceSngleAction,
  MediaPostDisplay,
  MediaPostVideoType,
  PaidPodcastPost,
  PaidSeriesPcPost,
  PaidSnglePcPost,
  PollPostDisplayfilled,
  PollPostDisplayUnfilled,
  RecmndNoImgPostDisplay,
  RecommendationPostDisplay,
  ReSharePostType,
  SingleMediaCaptionPost,
  SingleMediaNoDespPost,
  SingleMediaPost,
  SingleVideoType,
  SngleUploadPcPost,
  TextPostDisplay,
  TextTagPostDisplay,
  ThroughtPostDisplay,
  DoubleMediaPostDisplay,
  TripleMediaPostDisplay,
  ArticlePostDisplay,
  DoubleMediaPostVideoType,
  TripleMediaPostVideoType,
  DoubleMediaPostVideoImageType,
  TripleMediaPostVideoImageType,
  SharedPollPostDisplayUnfilled,
  SharedPollPostDisplayfilled,
  SharedThroughtPostDisplay,
  SharedEventPostDisplay,
  SharedRecommendationPostDisplay,
  SharedRecmndNoImgPostDisplay,
  SharedAlertPostDisplaySevere,
  SharedAlertPostDisplayModerate,
  SharedAlertPostDisplayMild,
  SharedSingleVideoType,
  SharedSingleMediaCaptionPost,
  SharedSingleMediaPost,
  SharedDoubleMediaPostVideoImageType,
  SharedDoubleMediaPostVideoType,
  SharedDoubleMediaPostDisplay,
  SharedTripleMediaPostVideoImageType,
  SharedTripleMediaPostVideoType,
  SharedTripleMediaPostDisplay,
  SharedMediaPostDisplay
} from "../AllPostDisplay/PostDisplayPrev";
import Emoji from "../Emoji";
import {AlertTriangle,BellOff,Bookmark,Circle,Copy,EyeOff,MoreHorizontal,Send,Share2,Smile,Edit2,Trash2, Bell,} from "react-feather";
import { MediaPreview2 as MediaPreviewModal2, SharedMediaPreview2 } from "../../Universal-components/post-creations/sub-components/MediaPreviewModal2";
import ActiveCirclelike from "../../../NewComponents/IMG/ActiveCirclelike.svg";
import CmntImg from "../../../NewComponents/IMG/CmntImg.svg";
import CommentActive from "../../../NewComponents/IMG/CommentActive.svg";
import UserImg from "../../../NewComponents/IMG/img.svg";
import LikeImg from "../../../NewComponents/IMG/LikeImg.svg";
import ShrImg from "../../../NewComponents/IMG/ShrImg.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addCommentOnPost } from "../../../Services/Reducers/SocialFeed/addCommentOnPost";
import { useDispatch } from "react-redux";
import FormatTimeAgo from "../FormatTimeAgo";
import PostComment from "../Components/PostComment";
import { Link } from "react-router-dom";
import axios from "axios";
import { addLikeOnComment } from "../../../Services/Actions/SocialFeed/addCommentOnPost";
import Comments from "../../post-components/display-post/Comments";
import { Comment } from "@mui/icons-material";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import { CommentsDisabled, ChatBubble } from "@mui/icons-material";
import ReportPostModal from "../../post-components/Modals/ReportPostModal";
import FeedBlockModal from "./FeedBlockModal";
import FeedUnfollowModal from "./FeedUnfollowModal";
import PostDiscardModal from "./PostDiscardModal";
import { loadAllUserPosts } from "../../../Services/Actions/SocialFeed/getAllUserPostsAction";
import { loadAllPostLikes } from "../../../Services/Actions/SocialFeed/getAllLikesAction";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CommentsDisabledOutlinedIcon from "@mui/icons-material/CommentsDisabledOutlined";
import FeedDeleteModal from "./FeedDeleteModal";
import FeedTaggedPeopleModal from "./FeedTaggedPeopleModal";
import { loadAllCommments } from "../../../Services/Actions/SocialFeed/getAllCommentsAction";
import RepostModal from "./Repost";
import ShareToModal from "../../top-trending-components/ShareToModal";
import MediaPreviewModal from "../../Universal-components/post-creations/sub-components/MediaPreviewModal";
import CreationMainSection from "../CreationMainSection";
import PostEditModal from "./PostEditModal";

const PostCard = ({
  postType,
  setPostType,
  likePost,
  toggle,
  toggleForward,
  unlikePost,
  handleProfileView,
  timeAgo,
  handleSave,
  handleUnsave,
  commentDataArray,
  handleAllComments,
  handleHidePost,
  ForwardArrayData,
  EmoteAvatar,
  handleNotificationOff,
  handleNotificationOn,
  handleCommentOn,
  handleCommentOff,
  handleBlockUnblock,
  setShareCommentView,
  shareCommentView,
  feelingCategory,
  modal, setModal,
  setSelectedImages,
  selectedImages,
  activeTab,
  postConfSettings,
  setActiveTab,
  displayFor
}) => {
  //   set userPost in this state
  const [postComments, setPostComments] = useState([]);
  const [fetchedPost, setFetchedPost] = useState([]);
  const [isliked, setIsLiked] = useState(postType?.liked);
  const [likeCount, setLikeCount] = useState(postType.likesCount);
  const [commentId, setCommentId] = useState(null);
  const [comment, SetComment] = useState(false);
  const [commentData, setCommentData] = useState(null);
  const [commentCount, setCommentCount] = useState('');
  const [commentLikeCount, setCommentLikeCount] = useState(null);
  const [showAllComments, setShowAllComments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPostComment, setShowPostComment] = useState(true);
  const [recommendPostStatus,setRecommendPostStatus]=useState(false)
  const [recommendationCount, setRecommendationCount] =useState(postType.totalRecommended);
  const [editmodal,setEditModal]=useState(false)
  const [deleteModal,setDeleteModal]=useState(false);

 

useEffect(()=>{},[activeTab])

  const openModals = () => {
    setShowModal(!showModal);
  };
  const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const handleEmojiSelect = (emoji) => {
    const updatedText = `${commentData || ""}${emoji}`;
    setCommentData(updatedText);
  };
  useEffect(() => {}, [showModal]);
  const [showWallFeed, setShowWallFeed] = useState(false);
  const [isCommentsAllowed, setIsCommentsAllowed] = useState(
    postType.allowComments
  );


  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/post/getComments`,
        { postId: postType.postId, pageIndex: 0, pageSize: 4 },
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      )
      .then((res) => {
        setPostComments(res.data.data.successResult);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [commentCount]);


  const dispatch = useDispatch();
  const handleCommentLike = (comment) => {
    dispatch(
      addLikeOnComment({
        commentId: comment?.id,
        reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
      })
    );
    setIsLiked(prev=>!prev)
  };


  async function deleteComment(comment, setShowPostComment) {
    try {
      const requestBody = {
        commentId: comment.id,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/deleteComment/`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success === true) {
        axios
          .post(
            `${process.env.REACT_APP_IPURL}/post/getComments`,
            { postId: postType.postId, pageIndex: 0, pageSize: 4 },
            {
              headers: { Authorization: `Bearer ${user?.token}` },
            }
          )
          .then((res) => {
            setPostComments(res.data.data.successResult);
            setCommentCount(res.data.data.successResult.count);
          })
          .catch((error) => {
            console.log(error);
          });
        axios
          .post(
            `${process.env.REACT_APP_IPURL}/post/getComments`,
            { postId: postType.postId, pageIndex: 0, pageSize: 4 },
            {
              headers: { Authorization: `Bearer ${user?.token}` },
            }
          )
          .then((res) => {
            setPostComments(res.data.data.successResult);
            setCommentCount(res.data.data.successResult.count);
          })
          .catch((error) => {
            console.log(error);
          });
        SetComment(false);
        setShowPostComment(false);
        dispatch(loadAllCommments(postType?.postId));
        setTimeout(() => {
          SetComment(true);
          setShowPostComment(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Error commenting on post:", error);
    }
  }


  const { allLikes } = useSelector((state) => state.getPostLikesData);

  const handleCommentData = (e) => {
    setCommentData(e.target.value);
  };
  function handelShare() {
    toggleForward();
    setPostType(postType);
  }
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
  };

  // ------------- Unfollow Modal State -------------

  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
  const toggleUnfollowModal = (request) => {
    setUnfollowModalOpen((prevState) => !prevState);
  };

  // ------------- Delete Modal State -------------

  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toggleDeleteModal = (request) => {
    setDeleteModalOpen((prevState) => !prevState);
  };

  //============block modal------------------------------------
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const toggleBlockModalOpen = (request) => {
    setBlockModalOpen((prevState) => !prevState);
    setSelectedRequest(request);
  };
  const handleReply = async (commentData) => {
    console.log(commentData);
  };





  //====================EDIT Modal========================
  


  const handleComment = async (
    postId,
    commentData,
    setCommentCount,
    commentCount,
    setCommentData,
    setShowPostComment,
    recommendPostStatus
  ) => {
    try {
      const cleanedCommentData = commentData.replace(/@\w+/g, "").trim();

      const requestBody = {
        postId: postId,
        comment: cleanedCommentData,
        commentId: commentId,
        hashTags: [],
        mentionIds: [],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/addComment/`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success === true) {
        axios
          .post(
            `${process.env.REACT_APP_IPURL}/post/getComments`,
            { postId: postType.postId, pageIndex: 0, pageSize: 4 },
            {
              headers: { Authorization: `Bearer ${user?.token}` },
            }
          )
          .then((res) => {
            setPostComments(res.data.data.successResult);
            if(recommendPostStatus && commentData.startsWith("I recommend")){
              const requestData = {
                postId: postId,
              };
              axios
                .post(
                  `${process.env.REACT_APP_IPURL}/post/recommendPostById`,
                  requestData,
                  {
                    headers: {
                      Authorization: `Bearer ${user?.token}`,
                    },
                  }
                )
                .then((response) => {
                  setRecommendationCount(response.data.data.successResult.length);
                })
                .catch((error) => {
                  console.error("Error liking post: ", error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });

        setCommentCount(Number(commentCount) + 1);
        SetComment(false);
        setCommentData("");

        setShowPostComment(false);
        dispatch(loadAllCommments(postId));
        setTimeout(() => {
          SetComment(true);
          setShowPostComment(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Error commenting on post:", error);
    }
  };

  // Function to toggle the display of all comments
  const toggleComments = () => {
    setShowAllComments(!showAllComments);
  };

  const Commenttoggle = () => {
    SetComment(!comment);
  };
  function handleKeypress(e) {
    if (e.key === "Enter") {
      handleComment(
        postType.postId,
        commentData,
        setCommentCount,
        commentCount,
        setCommentData,
        setShowPostComment,
        recommendPostStatus
      );
    }
  }
  const [isNotificationOff, setIsNotificationOff] = useState(
    postType.notificationOff
  );

  const [forwardOpen, setForwardOpen] = useState(false);

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  
  useEffect(() => {}, [commentDataArray, showAllComments]);

  const [BookmarkIcon, SetBookmark] = useState(postType.saved);
  const [modalOpenReshare, setModalOpenReshare] = useState(false);

  const [path, setPath] = useState("/modal/");
  const [emoteAvatarData, setEmoteAvatarData] = useState("");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      width: 400,
    },
  };
  const toggleLike = (postType) => {
    if (isliked === 0) {
      likePost(postType.postId);
      setEmoteAvatarData(dispatch(loadAllPostLikes(postType.postId)));
      setLikeCount(likeCount + 1);
      setIsLiked(1);
    } else {
      unlikePost(postType.postId);
      setLikeCount(likeCount - 1);
      setEmoteAvatarData(dispatch(loadAllPostLikes(postType.postId)));
      setIsLiked(0);
    }
  };

  // -------------  Tagged Modal State -------------
  const [taggedPeopleOpen, setTaggedPeopleOpen] = useState(false);
  const toggletTaggedPeopleOpen = () => {
    setTaggedPeopleOpen((prevState) => !prevState);
  };

  return (
    <>
    
      {editmodal && 
        <PostEditModal
            postType={postType}
            openModal={DeleteModalOpen}
            DeleteModalOpen={toggleDeleteModal}
            feelingCategory={feelingCategory}
            modal={modal} setModal={setModal}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            setEditModal={setEditModal}
            toggle={toggle}
            setPostType={setPostType}
            activeTab={activeTab}
            setActiveTab = {setActiveTab}
            postConfSettings ={postConfSettings} 
         />
      }

      <ReportPostModal
        modalOpen={reportOpen}
        reportModalOpen={toggleReport}
        postId={postType.postId}
      />
      <FeedBlockModal
        postType={postType}
        openModal={blockModalOpen}
        removeModalOpen={toggleBlockModalOpen}
      />
      <FeedUnfollowModal
        postType={postType}
        openModal={unfollowModalOpen}
        unfollowModalOpen={toggleUnfollowModal}
      />
     {deleteModal &&  <FeedDeleteModal
        postType={postType}
        openModal={DeleteModalOpen}
        DeleteModalOpen={toggleDeleteModal}
      />}

      {/* ------------------ Start Tagged Modal  ------------------*/}
      <FeedTaggedPeopleModal
        hy
        postType={postType}
        openModal={taggedPeopleOpen}
        TaggedModalOpen={toggletTaggedPeopleOpen}
      />


      <Card key={postType.postId} className="Post_Display_Crad my-3">
        <CardHeader className="bg-white border-bottom-0 w-100">
          <div className="d-flex d-flex  align-items-start py-2 mr-2 align-items-start py-2 mr-2 ">
            <img
              src={postType.profileImageThumb ||"https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png"}
              className="rounded-circle border"
              width={40}
              height={40}
              alt="UserImage"
              onError={(e) => {
                e.target.onerror = null; 
                e.target.src = "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png"; 
              }}
            />
            <div className="ml-2 PostPreView_User_Info ">
              <div>
                <h2 className="text-wrap">
                  <Link to={"/MyProfile"} style={{ color: "#4d4c4c" }}>
                    <span
                      className="UserProfile-Name"
                      onClick={handleProfileView}
                    >
                      {postType.fullName}
                    </span>{" "}
                  </Link>
                  {postType.feelingName && postType.feelingIconURL && (
                   <>
                      &nbsp;
                      <span
                        style={{
                          color: "rgba(77, 77, 77, 0.5)",
                          fontSize: "14px",
                        }}
                      >
                        is{"  "} &nbsp;
                        <img
                          src={
                            postType.feelingType === "feeling"
                              ? `${process.env.REACT_APP_IMAGEURL}${postType.feelingIconURL}`
                              : postType.feelingType === "activity"
                              ? `${process.env.REACT_APP_IMAGEURL}${postType.feelingSubChildCategoryIconURL || postType.feelingIconURL}`
                              : ''
                          }
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                          alt={postType.feelingName}
                          height={15}
                          width={15}
                          style={{ marginTop: "-4px" }}
                        />
                        {postType.feelingType === "feeling" 
                          ? `feeling ${postType.feelingName}` 
                          : postType.feelingType === "activity" 
                          ? `${postType.feelingName}${postType.feelingSubChildCategoryName ? ` ${postType.feelingSubChildCategoryName}` : ''}` 
                          : null
                        }
                      </span>{" "}
                    </>
                 
                  )}
                  {postType.taggedPeoples &&
                    postType.taggedPeoples.length > 0 && (
                      <>
                        &nbsp;
                        <span
                          style={{
                            color: "rgba(77, 77, 77, 0.5)",
                            FontSize: "14px",
                          }}
                        >
                          with
                        </span>{" "}
                        &nbsp;
                        {postType.taggedPeoples.map((taggedPerson, index) => {
                          if (index === 0) {
                            // First person
                            return (
                              <Link
                                to={"/MyProfile"}
                                style={{ color: "#4d4c4c" }}
                              >
                                <span
                                  key={taggedPerson.id}
                                  style={{ fontSize: "14px" }}
                                >
                                  {taggedPerson.fullName}
                                </span>
                              </Link>
                            );
                          } else if (
                            index === 1 &&
                            postType.taggedPeoples.length === 2
                          ) {
                            // Second person when there are only two tagged people
                            return (
                              <span
                                key={taggedPerson.id}
                                style={{ fontSize: "14px" }}
                              >
                                &nbsp;
                                <span
                                  style={{
                                    color: "rgba(77, 77, 77, 0.5)",
                                    fontSize: "14px",
                                  }}
                                >
                                  and
                                </span>
                                &nbsp;
                                <Link
                                  to={"/MyProfile"}
                                  style={{ color: "#4d4c4c" }}
                                >
                                  {taggedPerson.fullName}
                                </Link>
                              </span>
                            );
                          } else if (
                            index === 1 &&
                            postType.taggedPeoples.length > 2
                          ) {
                            // Second person when there are more than two tagged people
                            return (
                              <Link
                                to={"/MyProfile"}
                                style={{ color: "#4d4c4c" }}
                              >
                                <span
                                  key={taggedPerson.id}
                                  style={{ fontSize: "14px" }}
                                >
                                  {", " + taggedPerson.fullName}
                                </span>
                              </Link>
                            );
                          } else if (index === 2) {
                            // Start showing "and others" when there are more than two tagged people
                            return (
                              <>
                                &nbsp;
                                <span
                                  style={{
                                    color: "rgba(77, 77, 77, 0.5)",
                                    fontSize: "14px",
                                  }}
                                >
                                  and
                                </span>
                                &nbsp;
                                <span
                                  style={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={toggletTaggedPeopleOpen}
                                >
                                  {postType.taggedPeoples.length - 2} others
                                </span>
                              </>
                            );
                          }
                          return null;
                        })}
                      </>
                    )}
                  {postType.displayLocation && (
                    <>
                      <span
                        style={{
                          color: "rgba(77, 77, 77, 0.5)",
                          fontSize: "14px",
                        }}
                      >
                        &nbsp; in
                      </span>
                      &nbsp;
                      <span
                        className="Profile_Location"
                        style={{ fontWeight: "400" }}
                      >
                        {postType.displayLocation}
                      </span>
                    </>
                  )}
                </h2>
              </div>
              <h4>
                {postType.postType === "recommendation" ? (
                  <span
                    style={{
                      color: "#FF5C00",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Recommendation &nbsp;
                    <Circle fill="#C4C4C4" color="#C4C4C4" size={6} /> &nbsp;
                  </span>
                ) : null}
                {timeAgo}
              </h4>

              {/* //---------------------------------------------------New Code----------------------------------------------------------// */}
            </div>

            <div className="d-flex ml-auto">
            {displayFor ==="FeedPostDisPlay" &&
              <div className="rem-mob-view" onClick={() => BookmarkIcon === 0 ? (handleSave(postType.postId), SetBookmark(1)) : (handleUnsave(postType.postId), SetBookmark(0))} 
                style={{ padding: "8px", backgroundColor: "rgba(126, 178, 98, 0.1)", borderRadius: "5px", cursor: "pointer" }}>
                {BookmarkIcon === 0 ? <Bookmark color="#808080" /> : <Bookmark color="#81C14B" fill="#81C14B" />}
              </div>}

              {/* //-------------------------------------------------------------------------------------------------// */}
              {displayFor ==="FeedPostDisPlay" &&
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  style={{ padding: "0 0 0 10px " }}
                  color="flat-secondary"
                >
                  <MoreHorizontal size={18} />
                </DropdownToggle>
                <DropdownMenu
                  style={{
                    zIndex: "2",
                    left: "-255px",
                    minWidth: "fit-content",
                    color: "#A6A6A6",
                  }}
                >
                  {userProfileByUserId.id === postType.userId ? (
                    <>
                      {isNotificationOff === 0 ? (
                        <DropdownItem
                          style={{ color: "#808080" }}
                          onClick={() => {
                            handleNotificationOff(
                              postType.postId,
                              setIsNotificationOff
                            );
                          }}
                        >
                          <BellOff size={15} color="#A6A6A6" /> Turn off this
                          post notification
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          style={{ color: "#808080" }}
                          onClick={() => {
                            handleNotificationOn(
                              postType.postId,
                              setIsNotificationOff
                            );
                          }}
                        >
                          <Bell size={15} color="#A6A6A6" /> Turn on this post
                          notification
                        </DropdownItem>
                      )}

                      {postType?.postType !== "poll" && (
                        isCommentsAllowed === 1 ? (
                          <DropdownItem
                            style={{ color: "#808080" }}
                            onClick={() => {
                              handleCommentOff(
                                postType.postId,
                                setIsCommentsAllowed,
                                isCommentsAllowed
                              );
                            }}
                          >
                            <CommentsDisabledOutlinedIcon
                              fontSize="small"
                              color="#A6A6A6"
                            />{" "}
                            Turn off post comment
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            style={{ color: "#808080" }}
                            onClick={() => {
                              handleCommentOn(
                                postType.postId,
                                setIsCommentsAllowed
                              );
                            }}
                          >
                            <CommentOutlinedIcon
                              fontSize="small"
                              color="#A6A6A6"
                            />{" "}
                            Turn on post comments
                          </DropdownItem>
                        )
                      )}

                      <DropdownItem
                        style={{ color: "#808080" }}
                        onClick={() => {
                          setDeleteModal(true)
                          toggleDeleteModal();
                        }}
                      >
                        <Trash2 size={15} color="#A6A6A6" /> Delete this Post
                      </DropdownItem>
                      <DropdownItem style={{ color: "#808080" }}>
                        <Copy size={15} color="#A6A6A6" /> Copy link
                      </DropdownItem>
                      {/* <DropdownItem style={{ color: "#808080" }}>
                        <Share2 size={15} color="#A6A6A6" /> Share via
                      </DropdownItem> */}
                     {!(postType.postType === "reShare" || 
                        (postType.postType === "poll" && postType.totalVotesOnPoll > 0)) && (
                          <DropdownItem
                            style={{ color: "#808080" }}
                            onClick={() => {
                              let ptype = postType.postType;
                              if (ptype === "recommendation") {
                                setActiveTab(7.6);
                              } else if (ptype === "thought") {
                                setActiveTab(7.1);
                              } else if (ptype === "text") {
                                setActiveTab(0);
                              } else if (ptype === "media") {
                                setActiveTab(0.1);
                              } else if (ptype === "event") {
                                setActiveTab(7.3);
                              } else if (ptype === "poll") {
                                setActiveTab(7.8);
                              } else if (ptype === "feeling") {
                                setActiveTab(1);
                              } else if (ptype === "alert") {
                                setActiveTab(7.7);
                              }
                              setEditModal(true);
                              toggleDeleteModal();
                            }}
                          >
                            <Edit2 size={15} color="#A6A6A6" /> Edit this Post
                          </DropdownItem>
                    )}

                    </>
                  ) : (
                    // If the logged-in user is not the creator
                    <>
                      <DropdownItem
                        style={{ color: "#808080" }}
                        onClick={() => {
                          handleHidePost(postType.postId);
                        }}
                      >
                        <EyeOff size={15} color="#A6A6A6" /> Hide post from your
                        timeline
                      </DropdownItem>

                      {isNotificationOff === 0 ? (
                        <DropdownItem
                          style={{ color: "#808080" }}
                          onClick={() => {
                            handleNotificationOff(
                              postType.postId,
                              setIsNotificationOff
                            );
                          }}
                        >
                          <BellOff size={15} color="#A6A6A6" /> Turn off this
                          post notification
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          style={{ color: "#808080" }}
                          onClick={() => {
                            handleNotificationOn(
                              postType.postId,
                              setIsNotificationOff
                            );
                          }}
                        >
                          <Bell size={15} color="#A6A6A6" /> Turn on this post
                          notification
                        </DropdownItem>
                      )}

                      {/* <DropdownItem style={{ color: "#808080" }}>
                        <Share2 size={15} color="#A6A6A6" /> Share via
                      </DropdownItem> */}

                      {/* <DropdownItem style={{ color: "#808080" }}>
                        <button onClick={setModalOpenReshare}>Repost</button>
                        <Modal
                          isOpen={modalOpenReshare}
                          style={customStyles}
                          onRequestClose={() => setModalOpenReshare(false)}
                        >
                          <div>share to your sociomate</div>
                          <div>
                            <button
                              onClick={() => {
                                setShowWallFeed(true);
                              }}
                            >
                              Your wall feed
                            </button>
                            <RepostModal show={showWallFeed} />
                          </div>

                          <div>Your story</div>
                          <div>Your Business Pages</div>
                          <div>Your Groups</div>

                          <button onClick={() => setModalOpenReshare(false)}>
                            Close
                          </button>
                        </Modal>
                      </DropdownItem> */}

                      <DropdownItem style={{ color: "#808080" }}>
                        <Copy size={15} color="#A6A6A6" /> Copy link
                      </DropdownItem>

                      <DropdownItem
                        style={{ color: "#808080" }}
                        onClick={() => {
                          toggleUnfollowModal();
                        }}
                      >
                        <EyeOff size={15} color="#A6A6A6" /> Unfollow{" "}
                        {postType.fullName}
                      </DropdownItem>

                      <DropdownItem
                        style={{ color: "#808080" }}
                        onClick={() => {
                          toggleBlockModalOpen(postType);
                          handleBlockUnblock(postType);
                        }}
                      >
                        <BellOff size={15} color="#A6A6A6" /> Block{" "}
                        {postType.fullName}
                      </DropdownItem>

                      <DropdownItem
                        style={{ color: "#808080" }}
                        onClick={() => {
                          toggleReport(postType.postId);
                        }}
                      >
                        <EyeOff size={15} color="#A6A6A6" /> Report this Post
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
                 }
            </div>

            {/* //-------------------------------------------------------------------------------------------------// */}
          </div>
        </CardHeader>

        <CardBody style={{ margin: "-20px 0" }}>
          {postType.postType === "text" && (
            <TextPostDisplay
              postType={postType}
              text={postType.caption}
              hashtags={postType.postHashTags}
              txtColor={postType.textPostColor}
            />
          )}

          {postType.postType === "media" &&
          postType.mediaList &&
          postType.mediaList.length > 0 ? (
            postType.mediaList.length === 1 ? (
              postType.mediaList[0].fileType === "video" ? (
                showModal ? (
                  <MediaPreviewModal2
                    videoURL={postType.mediaList}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType.profileImage}
                    fullName={postType.fullName}
                    shareCount={postType.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <SingleVideoType
                    videoURL={postType.mediaList[0]?.fileURL}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    height="400px"
                    onImageClick={openModals}
                  />
                )
              ) : postType.mediaList[0].caption ? (
                showModal ? (
                  <MediaPreviewModal2
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    mediaURL={[postType.mediaList[0]?.fileURL]}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    setShowModal={setShowModal}
                    profileImage={postType.profileImage}
                    fullName={postType.fullName}
                    shareCount={postType.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  // <MediaPreviewModal/>
                  <SingleMediaCaptionPost
                    mediaURL={postType.mediaList[0]?.fileURL}
                    caption={postType.mediaList[0].caption}
                    hashtags={postType.postHashTags}
                    text={postType.caption}
                    onImageClick={openModals}
                  />
                )
              ) : showModal ? (
                <MediaPreviewModal2
                  setCommentIds={setCommentId}
                  SetComment={SetComment}
                  mediaURL={[postType.mediaList[0]?.fileURL]}
                  text={postType.caption}
                  hashtags={postType.postHashTags}
                  setShowModal={setShowModal}
                  profileImage={postType.profileImage}
                  fullName={postType.fullName}
                  shareCount={postType.sharesCount}
                  likeCount={likeCount}
                  commentCount={commentCount}
                  postType={postType}
                  setFetchedPost={setFetchedPost}
                  toggleLike={toggleLike}
                  isliked={isliked}
                  ActiveCirclelike={ActiveCirclelike}
                  LikeImg={LikeImg}
                  handelShare={handelShare}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  handleKeypress={handleKeypress}
                  setCommentCount={setCommentCount}
                  handleComment={handleComment}
                  deleteComment={deleteComment}
                  setShowPostComment={setShowPostComment}
                  showPostComment={showPostComment}
                />
              ) : (
                // <MediaPreviewModal/>
                <SingleMediaPost
                  mediaURL={postType.mediaList[0]?.fileURL}
                  text={postType.caption}
                  hashtags={postType.postHashTags}
                  onImageClick={openModals}
                />
              )
            ) : postType.mediaList.length === 2 ? (
              (postType.mediaList[0].fileType === "image" &&
                postType.mediaList[1].fileType === "video") ||
              (postType.mediaList[0].fileType === "video" &&
                postType.mediaList[1].fileType === "image") ? (
                showModal ? (
                  <MediaPreviewModal2
                    allURL={postType.mediaList}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType.profileImage}
                    fullName={postType.fullName}
                    shareCount={postType.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <DoubleMediaPostVideoImageType
                    postType={postType.mediaList}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    videoURL={
                      postType.mediaList[0]?.fileType === "video"
                        ? postType.mediaList[0]?.fileURL
                        : postType.mediaList[1]?.fileURL
                    }
                    imageURL={
                      postType.mediaList[0]?.fileType === "image"
                        ? postType.mediaList[0]?.fileURL
                        : postType.mediaList[1]?.fileURL
                    }
                    caption={postType.mediaList[0].caption}
                    onImageClick={openModals}
                  />
                )
              ) : postType.mediaList[1].fileType === "video" ? (
                showModal ? (
                  <MediaPreviewModal2
                    videoURL={postType.mediaList}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType.profileImage}
                    fullName={postType.fullName}
                    shareCount={postType.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <DoubleMediaPostVideoType
                    postType={postType.mediaList}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    height="400px"
                    onImageClick={openModals}
                  />
                )
              ) : showModal ? (
                <MediaPreviewModal2
                  setCommentIds={setCommentId}
                  SetComment={SetComment}
                  mediaURL={[
                    postType.mediaList[0]?.fileURL,
                    postType.mediaList[1]?.fileURL,
                  ]}
                  text={postType.caption}
                  hashtags={postType.postHashTags}
                  setShowModal={setShowModal}
                  profileImage={postType.profileImage}
                  fullName={postType.fullName}
                  shareCount={postType.sharesCount}
                  likeCount={likeCount}
                  commentCount={commentCount}
                  postType={postType}
                  setFetchedPost={setFetchedPost}
                  toggleLike={toggleLike}
                  isliked={isliked}
                  ActiveCirclelike={ActiveCirclelike}
                  LikeImg={LikeImg}
                  handelShare={handelShare}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  handleKeypress={handleKeypress}
                  setCommentCount={setCommentCount}
                  handleComment={handleComment}
                  deleteComment={deleteComment}
                  setShowPostComment={setShowPostComment}
                  showPostComment={showPostComment}
                />
              ) : (
                <DoubleMediaPostDisplay
                  date={postType.createdAt}
                  postType={postType.mediaList}
                  text={postType.caption}
                  hashtags={postType.postHashTags}
                  onImageClick={openModals}
                />
              )
            ) : postType.mediaList.length === 3 ? (
              (postType.mediaList[0].fileType === "image" ||
                postType.mediaList[1].fileType === "image" ||
                postType.mediaList[2].fileType === "image") &&
              (postType.mediaList[0].fileType === "video" ||
                postType.mediaList[1].fileType === "video" ||
                postType.mediaList[2].fileType === "video") ? (
                showModal ? (
                  <MediaPreviewModal2
                    allURL={postType.mediaList}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType.profileImage}
                    fullName={postType.fullName}
                    shareCount={postType.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <TripleMediaPostVideoImageType
                    postType={postType}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    caption={postType.mediaList[0].caption}
                    onImageClick={openModals}
                  />
                )
              ) : postType.mediaList[2].fileType === "video" ? (
                showModal ? (
                  <MediaPreviewModal2
                    videoURL={postType.mediaList}
                    caption={postType.mediaList[0].caption}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType.profileImage}
                    fullName={postType.fullName}
                    shareCount={postType.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <TripleMediaPostVideoType
                    postType={postType.mediaList}
                    text={postType.caption}
                    hashtags={postType.postHashTags}
                    height="400px"
                    onImageClick={openModals}
                  />
                )
              ) : showModal ? (
                <MediaPreviewModal2
                  setCommentIds={setCommentId}
                  SetComment={SetComment}
                  mediaURL={[
                    postType.mediaList[0]?.fileURL,
                    postType.mediaList[1]?.fileURL,
                    postType.mediaList[2]?.fileURL,
                  ]}
                  text={postType.caption}
                  hashtags={postType.postHashTags}
                  setShowModal={setShowModal}
                  profileImage={postType.profileImage}
                  fullName={postType.fullName}
                  shareCount={postType.sharesCount}
                  likeCount={likeCount}
                  commentCount={commentCount}
                  postType={postType}
                  setFetchedPost={setFetchedPost}
                  toggleLike={toggleLike}
                  isliked={isliked}
                  ActiveCirclelike={ActiveCirclelike}
                  LikeImg={LikeImg}
                  handelShare={handelShare}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  handleKeypress={handleKeypress}
                  setCommentCount={setCommentCount}
                  handleComment={handleComment}
                  deleteComment={deleteComment}
                  setShowPostComment={setShowPostComment}
                  showPostComment={showPostComment}
                />
              ) : (
                <TripleMediaPostDisplay
                  date={postType.createdAt}
                  postType={postType.mediaList}
                  text={postType.caption}
                  hashtags={postType.postHashTags}
                  onImageClick={openModals}
                />
              )
            ) : // : postType.mediaList[0].fileType === "video" ? (
            //   <MediaPostVideoType
            //     postType={postType.mediaList}
            //     text={postType.caption}
            //     hashtags={postType.postHashTags}
            //   />
            // )
            showModal ? (
              <MediaPreviewModal2
                allURL={postType.mediaList}
                caption={postType.mediaList[0].caption}
                text={postType.caption}
                hashtags={postType.postHashTags}
                setCommentIds={setCommentId}
                SetComment={SetComment}
                setShowModal={setShowModal}
                profileImage={postType.profileImage}
                fullName={postType.fullName}
                shareCount={postType.sharesCount}
                likeCount={likeCount}
                commentCount={commentCount}
                postType={postType}
                setFetchedPost={setFetchedPost}
                toggleLike={toggleLike}
                isliked={isliked}
                ActiveCirclelike={ActiveCirclelike}
                LikeImg={LikeImg}
                handelShare={handelShare}
                commentData={commentData}
                setCommentData={setCommentData}
                handleKeypress={handleKeypress}
                setCommentCount={setCommentCount}
                handleComment={handleComment}
                deleteComment={deleteComment}
                setShowPostComment={setShowPostComment}
                showPostComment={showPostComment}
              />
            ) : (
              <MediaPostDisplay
                date={postType.createdAt}
                postType={postType.mediaList}
                text={postType.caption}
                hashtags={postType.postHashTags}
                onImageClick={openModals}
              />
            )
          ) : null}

          {postType.postType === "thought" && (
            <ThroughtPostDisplay
              text={postType.caption}
              authorName={postType.fullName}
              bgColor={postType.thoughtBackColor}
              txtColor={postType.thoughtForeColor}
              hashtags={postType.postHashTags}
            />
          )}

          {postType.postType === "article" && (
            <ArticlePostDisplay
              text={postType.caption}
              description={postType.articleDescription}
              category={postType.articleCategory}
              articleImg={postType.articleCoverImageURL}
              createdDate={postType.createdAt}
            />
          )}

          {postType.postType === "poll" && postType.totalVotesOnPoll === 0 && (
            <PollPostDisplayUnfilled
              question={postType.caption}
              totalParticipants={postType.totalVotesOnPoll}
              pollOptions={postType.pollOptions}
              postType={postType}
            />
          )}

          {postType.totalVotesOnPoll !== 0 && (
            <PollPostDisplayfilled
              postType={postType}
              question={postType.caption}
              totalParticipants={postType.totalVotesOnPoll}
              pollOptions={postType.pollOptions}
            />
          )}

          {/* //------------------------Event post ------------------------------------// */}

          {postType.postType === "event" && (
            <EventPostDisplay
              postType={postType}
              eventCoverImageURL={postType.eventCoverImageURL}
              eventTitle={postType.caption}
              eventCategory={postType.eventCategory}
              eventStartTime={postType.eventStartTime}
              eventEndTime={postType.eventEndTime}
              eventLocation={postType.eventAddress}
              eventDescription={postType.eventDescription}
              hashtags={postType.postHashTags}
            />
          )}
          {/* //------------------------Alert post ------------------------------------// */}

          {postType.postType === "alert" && (
            <>
              {postType.alertLevel.name === "Severe" ? (
                <AlertPostDisplaySevere alertDescription={postType.caption} />
              ) : postType.alertLevel.name === "Moderate" ? (
                <AlertPostDisplayModerate alertDescription={postType.caption} />
              ) : (
                <AlertPostDisplayMild alertDescription={postType.caption} />
              )}
            </>
          )}

          {/* //------------------------Recommendation post ------------------------------------// */}
          {postType.postType === "recommendation" ? (
            postType.recommendationCoverImageURL ? (
              <RecommendationPostDisplay
                recommendation={postType.caption}
                recommendationCoverImageURL={ postType.recommendationCoverImageURL}
                totalRecommended={postType.totalRecommended}
                hashtags={postType.postHashTags}
                txtColor={postType.thoughtForeColor}
                setCommentData={setCommentData}
                commentData={commentData}
                setRecommendPostStatus={setRecommendPostStatus}

              />
            ) : (
              <RecmndNoImgPostDisplay
                recommendation={postType.caption}
                totalRecommended={recommendationCount}
                postId={postType.postId}
                userId={postType.userId}
                hashtags={postType.postHashTags}
                setCommentData={setCommentData}
                commentData={commentData}
                setRecommendPostStatus={setRecommendPostStatus}

              />
            )
          ) : null}
        
          {/* //------------------------Recommendation post ------------------------------------// */}
         
         
         
         {/* =====================>> reshared post section <<<============================= */}
          {postType.postType === "reShare" &&  postType.reSharedPost.length > 0 && (
          <>
          {postType.reSharedPost?.[0]?.postType === "text" && <ReSharePostType postType={postType} />}
          {postType.reSharedPost?.[0]?.postType === "thought" && <SharedThroughtPostDisplay postType={postType} />}
          {postType.reSharedPost?.[0]?.postType === "poll" && (postType.reSharedPost[0].totalVotesOnPoll === 0 ? <SharedPollPostDisplayUnfilled postType={postType} /> : <SharedPollPostDisplayfilled postType={postType} />)}
          {postType.reSharedPost?.[0]?.postType === "event" && <SharedEventPostDisplay postType={postType} />}
          {postType.reSharedPost?.[0]?.postType === "recommendation" && (postType.reSharedPost[0].recommendationCoverImageURL ? 
              <SharedRecommendationPostDisplay 
                postType={postType}  
                setCommentData={setCommentData}  
                setRecommendPostStatus={setRecommendPostStatus} /> 
            : <SharedRecmndNoImgPostDisplay 
                postType={postType}  
                setCommentData={setCommentData}  
                setRecommendPostStatus={setRecommendPostStatus} />
              )}

          {postType.reSharedPost?.[0]?.postType === "alert" && (
            <>
              {postType.reSharedPost?.[0]?.alertLevel?.name === "Severe" ? (
                <SharedAlertPostDisplaySevere postType={postType} />
              ) : postType.reSharedPost?.[0]?.alertLevel?.name === "Moderate" ? (
                <SharedAlertPostDisplayModerate postType={postType} />
              ) : (
                <SharedAlertPostDisplayMild postType={postType}/>
              )}
            </>
          )}




       {postType.reSharedPost?.[0]?.postType === "media" &&
           postType.reSharedPost?.[0]?.mediaList &&
           postType.reSharedPost?.[0]?.mediaList.length > 0 ? (
            postType.reSharedPost?.[0]?.mediaList.length === 1 ? (
              postType.reSharedPost?.[0]?.mediaList[0].fileType === "video" ? (
                showModal ? (
                  <SharedMediaPreview2
                    videoURL={postType.reSharedPost?.[0]?.mediaList}
                    caption={postType.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType.reSharedPost?.[0]?.caption}
                    hashtags={postType.reSharedPost?.[0]?.postHashTags || []}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType.reSharedPost?.[0]?.profileImage}
                    fullName={postType.reSharedPost?.[0]?.fullName}
                    shareCount={postType.reSharedPost?.[0]?.sharesCount || 0}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <SharedSingleVideoType
                    videoURL={postType.reSharedPost?.[0]?.mediaList[0]?.fileURL}
                    caption={postType.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType.reSharedPost?.[0]?.caption}
                    hashtags={postType.reSharedPost?.[0]?.postHashTags || []}
                    height="400px"
                    onImageClick={openModals}
                    postType={postType}
                  />
                )
              ) : postType.reSharedPost?.[0]?.mediaList[0].caption ? (
                showModal ? (
                  <MediaPreviewModal2
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    mediaURL={[postType.reSharedPost?.[0]?.mediaList[0]?.fileURL]}
                    caption={postType.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType.reSharedPost?.[0]?.caption}
                    hashtags={postType.reSharedPost?.[0]?.postHashTags || []}
                    setShowModal={setShowModal}
                    profileImage={postType.reSharedPost?.[0]?.profileImage}
                    fullName={postType.reSharedPost?.[0]?.fullName}
                    shareCount={postType.reSharedPost?.[0]?.sharesCount || 0}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  // <MediaPreviewModal/>
                  <SharedSingleMediaCaptionPost
                    mediaURL={postType.reSharedPost?.[0]?.mediaList[0]?.fileURL}
                    caption={postType.reSharedPost?.[0]?.mediaList[0].caption}
                    hashtags={postType.reSharedPost?.[0]?.postHashTags || []}
                    text={postType.reSharedPost?.[0]?.caption}
                    onImageClick={openModals}
                    postType ={postType}
                  />
                )
              ) : showModal ? (
                <MediaPreviewModal2
                  setCommentIds={setCommentId}
                  SetComment={SetComment}
                  mediaURL={[postType.reSharedPost?.[0]?.mediaList[0]?.fileURL]}
                  text={postType.reSharedPost?.[0]?.caption}
                  hashtags={postType.reSharedPost?.[0]?.postHashTags || []}
                  setShowModal={setShowModal}
                  profileImage={postType.reSharedPost?.[0]?.profileImage}
                  fullName={postType.reSharedPost?.[0]?.fullName}
                  shareCount={postType.reSharedPost?.[0]?.sharesCount || 0}
                  likeCount={likeCount}
                  commentCount={commentCount}
                  postType={postType}
                  setFetchedPost={setFetchedPost}
                  toggleLike={toggleLike}
                  isliked={isliked}
                  ActiveCirclelike={ActiveCirclelike}
                  LikeImg={LikeImg}
                  handelShare={handelShare}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  handleKeypress={handleKeypress}
                  setCommentCount={setCommentCount}
                  handleComment={handleComment}
                  deleteComment={deleteComment}
                  setShowPostComment={setShowPostComment}
                  showPostComment={showPostComment}
                />
              ) : (
                // <MediaPreviewModal/>
                <SharedSingleMediaPost
                  mediaURL={postType?.reSharedPost?.[0]?.mediaList[0]?.fileURL}
                  text={postType?.reSharedPost?.[0]?.mediaList[0]?.caption}
                  hashtags={postType?.reSharedPost?.[0]?.mediaList[0]?.postHashTags || []}
                  onImageClick={openModals}
                  postType = {postType}
                />
              )
            ) : postType?.reSharedPost?.[0]?.mediaList.length === 2 ? (
              (postType?.reSharedPost?.[0]?.mediaList[0].fileType === "image" &&
                postType?.reSharedPost?.[0]?.mediaList[1].fileType === "video") ||
              (postType?.reSharedPost?.[0]?.mediaList[0].fileType === "video" &&
                postType?.reSharedPost?.[0]?.mediaList[1].fileType === "image") ? (
                showModal ? (
                  <MediaPreviewModal2
                    allURL={postType?.reSharedPost?.[0]?.mediaList}
                    caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType?.reSharedPost?.[0]?.profileImage}
                    fullName={postType?.reSharedPost?.[0]?.fullName}
                    shareCount={postType?.reSharedPost?.[0]?.sharesCount || 0}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <SharedDoubleMediaPostVideoImageType
                    postType={postType}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                    videoURL={
                      postType?.reSharedPost?.[0]?.mediaList[0]?.fileType === "video"
                        ? postType?.reSharedPost?.[0]?.mediaList[0]?.fileURL
                        : postType?.reSharedPost?.[0]?.mediaList[1]?.fileURL
                    }
                    imageURL={
                      postType?.reSharedPost?.[0]?.mediaList[0]?.fileType === "image"
                        ? postType?.reSharedPost?.[0]?.mediaList[0]?.fileURL
                        : postType?.reSharedPost?.[0]?.mediaList[1]?.fileURL
                    }
                    caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                    onImageClick={openModals}
                    
                  />
                )
              ) : postType?.reSharedPost?.[0]?.mediaList[1].fileType === "video" ? (
                showModal ? (
                  <MediaPreviewModal2
                    videoURL={postType?.reSharedPost?.[0]?.mediaList}
                    caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType?.reSharedPost?.[0]?.profileImage}
                    fullName={postType?.reSharedPost?.[0]?.fullName}
                    shareCount={postType?.reSharedPost?.[0]?.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <SharedDoubleMediaPostVideoType
                    postTypeData={postType?.reSharedPost?.[0]?.mediaList}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags ||[]}
                    height="400px"
                    onImageClick={openModals}
                    postType={postType}
                  />
                )
              ) : showModal ? (
                <MediaPreviewModal2
                  setCommentIds={setCommentId}
                  SetComment={SetComment}
                  mediaURL={[
                    postType?.reSharedPost?.[0]?.mediaList[0]?.fileURL,
                    postType?.reSharedPost?.[0]?.mediaList[1]?.fileURL,
                  ]}
                  text={postType?.reSharedPost?.[0]?.caption}
                  hashtags={postType?.reSharedPost?.[0]?.postHashTags}
                  setShowModal={setShowModal}
                  profileImage={postType?.reSharedPost?.[0]?.profileImage}
                  fullName={postType?.reSharedPost?.[0]?.fullName}
                  shareCount={postType?.reSharedPost?.[0]?.sharesCount}
                  likeCount={likeCount}
                  commentCount={commentCount}
                  postType={postType}
                  setFetchedPost={setFetchedPost}
                  toggleLike={toggleLike}
                  isliked={isliked}
                  ActiveCirclelike={ActiveCirclelike}
                  LikeImg={LikeImg}
                  handelShare={handelShare}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  handleKeypress={handleKeypress}
                  setCommentCount={setCommentCount}
                  handleComment={handleComment}
                  deleteComment={deleteComment}
                  setShowPostComment={setShowPostComment}
                  showPostComment={showPostComment}
                />
              ) : (
                <SharedDoubleMediaPostDisplay
                  date={postType?.reSharedPost?.[0]?.createdAt}
                  postTypeData={postType?.reSharedPost?.[0]?.mediaList}
                  text={postType?.reSharedPost?.[0]?.caption}
                  hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                  onImageClick={openModals}
                  postType={postType}
                />
              )
            ) : postType?.reSharedPost?.[0]?.mediaList.length === 3 ? (
              (postType?.reSharedPost?.[0]?.mediaList[0].fileType === "image" ||
                postType?.reSharedPost?.[0]?.mediaList[1].fileType === "image" ||
                postType?.reSharedPost?.[0]?.mediaList[2].fileType === "image") &&
              (postType?.reSharedPost?.[0]?.mediaList[0].fileType === "video" ||
                postType?.reSharedPost?.[0]?.mediaList[1].fileType === "video" ||
                postType?.reSharedPost?.[0]?.mediaList[2].fileType === "video") ? (
                showModal ? (
                  <MediaPreviewModal2
                    allURL={postType?.reSharedPost?.[0]?.mediaList}
                    caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType?.reSharedPost?.[0]?.profileImage}
                    fullName={postType?.reSharedPost?.[0]?.fullName}
                    shareCount={postType?.reSharedPost?.[0]?.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <SharedTripleMediaPostVideoImageType
                    postType={postType}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                    caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                    onImageClick={openModals}
                  />
                )
              ) : postType?.reSharedPost?.[0]?.mediaList[2].fileType === "video" ? (
                showModal ? (
                  <MediaPreviewModal2
                    videoURL={postType?.reSharedPost?.[0]?.mediaList}
                    caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags}
                    setCommentIds={setCommentId}
                    SetComment={SetComment}
                    setShowModal={setShowModal}
                    profileImage={postType?.reSharedPost?.[0]?.profileImage}
                    fullName={postType?.reSharedPost?.[0]?.fullName}
                    shareCount={postType?.reSharedPost?.[0]?.sharesCount}
                    likeCount={likeCount}
                    commentCount={commentCount}
                    postType={postType}
                    setFetchedPost={setFetchedPost}
                    toggleLike={toggleLike}
                    isliked={isliked}
                    ActiveCirclelike={ActiveCirclelike}
                    LikeImg={LikeImg}
                    handelShare={handelShare}
                    commentData={commentData}
                    setCommentData={setCommentData}
                    handleKeypress={handleKeypress}
                    setCommentCount={setCommentCount}
                    handleComment={handleComment}
                    deleteComment={deleteComment}
                    setShowPostComment={setShowPostComment}
                    showPostComment={showPostComment}
                  />
                ) : (
                  <SharedTripleMediaPostVideoType
                    postType={postType}
                    text={postType?.reSharedPost?.[0]?.caption}
                    hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                    height="400px"
                    onImageClick={openModals}
                  />
                )
              ) : showModal ? (
                <MediaPreviewModal2
                  setCommentIds={setCommentId}
                  SetComment={SetComment}
                  mediaURL={[
                    postType?.reSharedPost?.[0]?.mediaList[0]?.fileURL,
                    postType?.reSharedPost?.[0]?.mediaList[1]?.fileURL,
                    postType?.reSharedPost?.[0]?.mediaList[2]?.fileURL,
                  ]}
                  text={postType?.reSharedPost?.[0]?.caption}
                  hashtags={postType?.reSharedPost?.[0]?.postHashTags}
                  setShowModal={setShowModal}
                  profileImage={postType?.reSharedPost?.[0]?.profileImage}
                  fullName={postType?.reSharedPost?.[0]?.fullName}
                  shareCount={postType?.reSharedPost?.[0]?.sharesCount}
                  likeCount={likeCount}
                  commentCount={commentCount}
                  postType={postType}
                  setFetchedPost={setFetchedPost}
                  toggleLike={toggleLike}
                  isliked={isliked}
                  ActiveCirclelike={ActiveCirclelike}
                  LikeImg={LikeImg}
                  handelShare={handelShare}
                  commentData={commentData}
                  setCommentData={setCommentData}
                  handleKeypress={handleKeypress}
                  setCommentCount={setCommentCount}
                  handleComment={handleComment}
                  deleteComment={deleteComment}
                  setShowPostComment={setShowPostComment}
                  showPostComment={showPostComment}
                />
              ) : (
                <SharedTripleMediaPostDisplay
                  date={postType?.reSharedPost?.[0]?.createdAt}
                  postType={postType}
                  text={postType?.reSharedPost?.[0]?.caption}
                  hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                  onImageClick={openModals}
                />
              )
            ) : // : postType.mediaList[0].fileType === "video" ? (
            //   <MediaPostVideoType
            //     postType={postType.mediaList}
            //     text={postType.caption}
            //     hashtags={postType.postHashTags}
            //   />
            // )
            showModal ? (
              <MediaPreviewModal2
                allURL={postType?.reSharedPost?.[0]?.mediaList}
                caption={postType?.reSharedPost?.[0]?.mediaList[0].caption}
                text={postType?.reSharedPost?.[0]?.caption}
                hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                setCommentIds={setCommentId}
                SetComment={SetComment}
                setShowModal={setShowModal}
                profileImage={postType?.reSharedPost?.[0]?.profileImage}
                fullName={postType?.reSharedPost?.[0]?.fullName}
                shareCount={postType?.reSharedPost?.[0]?.sharesCount}
                likeCount={likeCount}
                commentCount={commentCount}
                postType={postType}
                setFetchedPost={setFetchedPost}
                toggleLike={toggleLike}
                isliked={isliked}
                ActiveCirclelike={ActiveCirclelike}
                LikeImg={LikeImg}
                handelShare={handelShare}
                commentData={commentData}
                setCommentData={setCommentData}
                handleKeypress={handleKeypress}
                setCommentCount={setCommentCount}
                handleComment={handleComment}
                deleteComment={deleteComment}
                setShowPostComment={setShowPostComment}
                showPostComment={showPostComment}
              />
            ) : (
              <SharedMediaPostDisplay
                date={postType?.reSharedPost?.[0]?.createdAt}
                postType={postType}
                text={postType?.reSharedPost?.[0]?.caption}
                hashtags={postType?.reSharedPost?.[0]?.postHashTags || []}
                onImageClick={openModals}
              />
            )
          ) : null}
        </>
        
          )}
         {/* =====================>> reshared post End <<<============================= */}



        </CardBody>

        {displayFor ==="FeedPostDisPlay" && 
        <CardFooter className="bg-white border-0 w-100 rem-mob-view">
          <div className="d-flex justify-content-between p-2">
            {/* //---------------------------------------LIKES FOR WEBSITE-------------------------------------------------// */}

           {<div className="d-flex align-items-center w-75">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleLike(postType);
                }}
                width={35}
                src={isliked ? ActiveCirclelike : LikeImg}
                alt="Like Icon"
              />

              <h5 style={{ color: "#808080" }} className="mx-2">
                {likeCount}
              </h5>
              <EmoteAvatar data={emoteAvatarData} />
            </div>}

            {/* //---------------------------------------COMMENTS FOR WEBSITE-------------------------------------------------// */}
           { <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
             {(postType.postType === "poll" || (postType.postType === "reShare" && postType.reSharedPost[0]?.postType === "poll")) ? null : (
                <>
                  <div
                    className="d-flex align-items-center"
                    onClick={Commenttoggle}
                  >
                    {comment ? (
                      <div className="text-center">
                        <img width={35} src={CommentActive} alt="commentIcon" />
                      </div>
                    ) : (
                      <div className="text-center">
                        {postType.allowComments === 0 ? null : ( // Render null if allowComments is 0
                          <img width={35} src={CmntImg} alt="commentIcon" />
                        )}
                      </div>
                    )}
                  </div>
                  {postType.allowComments !== 0 && (
                    <h5 style={{ color: "#808080" }} className="px-3">
                      {commentCount}
                    </h5>
                  )}
                </>
              )}
            </div>
}
            {/* //---------------------------------------SHARES FOR WEBSITE-------------------------------------------------// */}
            {<div className="d-flex align-items-center" onClick={handelShare}>
              <div className="text-center">
                <img
                  width={35}
                  src={ShrImg}
                  alt="shareIcon"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <h5 style={{ color: "#808080" }} className="px-3">
                {postType.sharesCount}
              </h5>
            </div>}
          </div>
          {/* {==========================================comments check============================================================} */}
          {comment ? (
            <PostComment
              SetComment={SetComment}
              setCommentCount={setCommentCount}
              postType={postType}
              postId={postType?.postId}
              allowComments={postType?.allowComments}
              setFetchedPost={setFetchedPost}
              setCommentsData={setCommentData}
              setCommentIds={setCommentId}
              postComments={postComments}
              setPostComments={setPostComments}
              deleteComment={deleteComment}
            />
          ) : null}
          {postType.postType === "poll" ? null : (
            <div className="m-2 position-relative">
              {postType.allowComments === 0 ? (
                <h5 style={{ textAlign: "center" }}>
                  Comments are off for this post.{" "}
                  {userProfileByUserId.id === postType.userId && (
                    <span
                      style={{ cursor: "pointer", color: "#84C34E" }}
                      onClick={() => {
                        handleCommentOn(postType.postId, setIsCommentsAllowed);
                      }}
                    >
                      Turn On Post Comment
                    </span>
                  )}
                </h5>
              ) : (
                <>
                  <Input
                    type="text"
                    style={{ background: "#F9FBF7", paddingRight: "33px" }}
                    placeholder="Write a comment..."
                    value={commentData}
                    onChange={handleCommentData}
                    onKeyPress={handleKeypress}
                  />
                  <div
                    className="positionForSerachSmile"
                    style={{ display: "flex" }}
                  >
                    {/* <Smile color="#808080" strokeWidth={2} /> */}
                    <Emoji
                      color="#808080"
                      strokeWidth={2}
                      onEmojiSelect={handleEmojiSelect}
                    />
                    <Send
                      style={{ cursor: "pointer", marginTop: "3px" }}
                      color="#808080"
                      className="iconForcolor"
                      strokeWidth={2}
                      onClick={() => {
                        handleComment(
                          postType.postId,
                          commentData,
                          setCommentCount,
                          commentCount,
                          setCommentData,
                          setShowPostComment,
                          recommendPostStatus
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </CardFooter>}

        {/*====================== Mobile View (Like Comment Section) =========================*/}
        <div className="rem-web-view">
          {displayFor ==="FeedPostDisPlay" &&
          <CardFooter className="bg-white border-0 px-0 w-100">
            {/* //---------------------------------------LIKES FOR PHONE-------------------------------------------------// */}
           {  <div
              style={{ paddingInline: "5px" }}
              className="d-flex justify-content-between"
            >
              <div className="d-flex justify-content-around align-items-start">
                <div className="pb-1">
                  <div className="d-flex align-items-center pb-1">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggleLike(postType);
                      }}
                      width={30}
                      src={isliked ? ActiveCirclelike : LikeImg}
                      alt="Like Icon"
                    />
                     <h5 style={{ color: "#808080" }} className="px-3">
                      {likeCount}
                    </h5>
                  </div>

                  {/* <EmoteAvatar /> */}
                </div>
                {/* //---------------------------------------COMMENTS FOR PHONE-------------------------------------------------// */}
               { <div
                  className="d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                >
                 {(postType.postType === "poll" || (postType.postType === "reShare" && postType.reSharedPost[0]?.postType === "poll")) ? null : (
                    <>
                      <div
                        className="d-flex align-items-center"
                        onClick={Commenttoggle}
                      >
                        {comment ? (
                          <div className="text-center">
                            <img
                              width={30}
                              src={CommentActive}
                              alt="shareIcon"
                            />
                          </div>
                        ) : (
                          <div className="text-center">
                            {postType.allowComments === 0 ? null : ( // Render null if allowComments is 0
                              <img width={30} src={CmntImg} alt="shareIcon" />
                            )}
                          </div>
                        )}
                      </div>
                      {postType.allowComments !== 0 && (
                        <h5 style={{ color: "#808080" }} className="px-3">
                          {commentCount}
                        </h5>
                      )}
                    </>
                  )}
                </div>}
                {/* //---------------------------------------SHARES FOR PHONE-------------------------------------------------// */}
               {<div
                  onClick={handelShare}
                  className="d-flex align-items-center"
                >
                  <div className="text-center">
                    <img
                      width={30}
                      src={ShrImg}
                      alt="shareIcon"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <h5 className="px-3" style={{ color: "#808080" }} >{postType.sharesCount}</h5>
                </div>}
              </div>
              {/* //===================================================================// */}
              
              { displayFor ==="FeedPostDisPlay" && 
                <div onClick={() => BookmarkIcon === 0 ? (handleSave(postType.postId), SetBookmark(1)) : (handleUnsave(postType.postId), SetBookmark(0))} 
                   style={{ padding: "4px", borderRadius: "5px", cursor: "pointer" }} >
                   {BookmarkIcon === 0 ? <Bookmark color="#808080" /> : <Bookmark color="#81C14B" fill="#81C14B" />}
                 </div>
                 
              }



              {/* //================================================================// */}
            </div>}
            {comment   ? (
              <div className="p-3">
                {postType.firstComment && postType.firstComment.length > 0 ? (
                  postType.firstComment.map((commentData, index) => (
                    <div key={index}>
                      {/* Render the first comment */}
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img
                            src={commentData.profileImageThumb}
                            className="rounded-circle border img-fluid mr-2"
                            width={30}
                            height={30}
                            alt="User Profile"
                          />
                          <h4>{commentData.fullName}</h4>
                        </div>
                        <h5 className="text-muted pr-1">
                          {FormatTimeAgo(commentData.createdAt)}
                        </h5>
                      </div>
                      <div className="p-2 pl-5">
                        <p className="text-muted">{commentData.comment}</p>
                        <div className="text-primary">
                        <button
                            className="btn text-primary"
                            onClick={() => handleCommentLike(commentData, setCommentLikeCount)}
                          >
                            {!isliked ? (
                              <img src="/assets/images/like1.png" alt="Like" width="20" height="20" />
                            ) : (
                              <img src="/assets/images/liked-icon.png" alt="Liked" width="20" height="20" />
                            )}
                            Like ({commentData.likesCount})
                          </button>
                          <button
                            className="btn text-primary"
                            onClick={() => handleReply(commentData)}
                          >
                            {/* Reply ({commentData.replyCount}) */}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: "center" }}>No comments available.</p>
                )}
                {commentCount > 1 && (
                  <div className="text-primary">
                    <p
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowAllComments(true);
                        handleAllComments(postType.postId);
                      }}
                    >
                      Load more comments ↺
                    </p>
                  </div>
                )}

                {commentDataArray && commentDataArray.length > 1 && (
                  <p
                    style={{
                      textAlign: "center",
                      color: "green",
                      cursor: "pointer",
                    }}
                    onClick={toggleComments}
                  >
                    {showAllComments
                      ? "Show fewer comments"
                      : "Load more comments ↺"}
                  </p>
                )}

                {showAllComments &&
                  commentDataArray.map((commentData, index) => (
                    <PostComment key={index} setCommentsData={setCommentData} />
                  ))}
              </div>
            ) : null}

            {postType.postType === "poll" ? null : (
              <div className="m-2 position-relative">
                {postType.allowComments === 0 ? (
                  <h5 style={{ textAlign: "center" }}>
                    Comments are off for this post.{" "}
                    {userProfileByUserId.id === postType.userId && (
                      <span
                        style={{ cursor: "pointer", color: "#84C34E" }}
                        onClick={() => {
                          handleCommentOn(
                            postType.postId,
                            setIsCommentsAllowed
                          );
                        }}
                      >
                        Turn On Post Comment
                      </span>
                    )}
                  </h5>
                ) : (shareCommentView &&
                  <>
                    <Input
                      type="text"
                      style={{
                        background: "#F9FBF7",
                        paddingRight: "33px",
                        cursor: "pointer",
                      }}
                      placeholder="Write a comment..."
                      value={commentData}
                      onChange={handleCommentData}
                      onKeyPress={handleKeypress}
                      No
                      task
                    />
                    <div className="positionForSerachSmile">
                      {/* <Smile color="#808080" strokeWidth={2}  onEmojiSelect={handleEmojiSelect}/> */}
                      <Send
                        color="#808080"
                        className="iconForcolor"
                        strokeWidth={2}
                        onClick={() => {
                          handleComment(
                            postType.postId,
                            commentData,
                            setCommentCount,
                            commentCount,
                            setCommentData,
                            setShowPostComment,
                            recommendPostStatus
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </CardFooter>}
        </div>
      </Card>
    </>
  );
};

export default PostCard;
