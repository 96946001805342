import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";

const commentAdded = (data) => ({
  type: types.ADD_COMMENT_ON_POST,
  payload: data,
});

// add comment on post
export const addCommentOnPost = (comment) => {
    console.log("hit 1")
    return async (dispatch) => {
      try {
        let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  
        if (user) {
            console.log("hit 2")
          const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/addComment/`,
            comment,
            {
              headers: { Authorization: `Bearer ${user?.token}` },
            }
          );
  
          dispatch(commentAdded(response.data));
          console.log("data", response.data);
        }
      } catch (error) {
        console.log("hit 3")
        console.error("Error adding comment:", error);
      }
    };
  };
  

// add like on comment
export const addLikeOnComment = (like) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/setCommentLike`, like, {
        headers: { Authorization: `Bearer ${user?.token}` },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
