import React from 'react'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Button } from 'react-bootstrap'
import UserImg from '../../../NewComponents/IMG/img.svg'
import { Circle, Filter } from 'react-feather'
import { Checkbox } from '@mui/material';
import '../../Shotzs/ShotzStyle.css'

const StoryShareModal = (props) => {
    //  ------------- Share To Array Data Modal  -------------
    const ForwardArrayData = [
        {
            id: "1",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "2",
            img: UserImg,
            name: "Darrell Steward",
            userName: "abriella-bond123"
        },
        {
            id: "3",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "4",
            img: UserImg,
            name: "Ralph Edwards",
            userName: "abriella-bond123"
        },
        {
            id: "5",
            img: UserImg,
            name: "Savannah Nguyen",
            userName: "abriella-bond123"
        },
        {
            id: "6",
            img: UserImg,
            name: "Eleanor Pena",
            userName: "abriella-bond123"
        },
        {
            id: "7",
            img: UserImg,
            name: "Darlene Robertson",
            userName: "abriella-bond123"
        },
        {
            id: "8",
            img: UserImg,
            name: "Bessie Cooper",
            userName: "abriella-bond123"
        }
    ]

    return (
        <>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.shareModalToOpen} className='modal-dialog-centered'>
                    <Row onClick={props.shareModalToOpen} className="d-flex align-items-center bg-light mx-0 py-2">
                        <Col xl='6'>
                            <h2>Share to</h2>
                        </Col>
                        <Col xl='6' className='d-flex justify-content-end'>
                            <Button variant='primary'>Share</Button>
                        </Col>
                    </Row>
                    <ModalBody>
                        <div className="search-block-podcast mt-2">
                            <div className="input-search-blk">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                            </div>
                        </div>
                        <div className='mt-3 Categories_subHeading_section my-2'>
                            <h4>Connections</h4>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='primary' outline>
                                    <Filter size={20} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag='a'> Recent </DropdownItem>
                                    <DropdownItem tag='a'> Full Name</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className='modal-scroll '>
                            {ForwardArrayData.map((data) => (
                                <div className="group-detail-page-blk">
                                    <div className="shotz-share-modal my-1">
                                        <div className="group-member-img-cont-blk">
                                            <div className='ForWard_Img_categories'>
                                                <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                            </div>
                                            <div className="member-cont-blk">
                                                <h4>{data.name}</h4>
                                                <p>{data.userName}</p>
                                            </div>
                                        </div>
                                        <Checkbox />
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </ModalBody>
                </Modal>
            }
        </>
    )
}

export default StoryShareModal