import React, { useEffect, useState } from 'react';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { Fragment } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button,CardTitle,Card, CardBody, Col, Row, UncontrolledButtonDropdown,DropdownItem} from 'reactstrap';
import { BellOff, Edit2, EyeOff, Link2, MessageCircle, MoreHorizontal, Send, Trash2,CornerUpLeft } from 'react-feather';
import like2 from '../../NewComponents/IMG/like2.svg'
import {Sliders,Eye,Bell} from "react-feather"
import { Link,useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux';
import { sweetAlertConfirmation } from "../../components/group-components/AllGroupsSections/SweetAlert";
import { loadProfileByUserId } from '../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { loadAllUserPosts } from '../../Services/Actions/SocialFeed/getAllUserPostsAction';
import { getActionBlockUnblock } from '../../Services/Actions/Connection/getBlockUnblockAction';
import PostCard from '../Social-feed/Components/PostCard';
import ShareToModal from '../top-trending-components/ShareToModal';
import MyProfession from "./ProfileComponentScreens/MyProfession";
import MyEducation from "./ProfileComponentScreens/MyEducation";
import ProfileBio from "./MyProfileComponents/ProfileBio";
import ProfileContactDetail from "./MyProfileComponents/ProfileContactDetail";
import ProfileDetail from "./MyProfileComponents/ProfileDetail";


const MyTextPost = () => {
    // Main_Return_section
    let dispatch=useDispatch();
    let navigate=useNavigate();
    const [data, setData] = useState("");
    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const id = searchParams.get('id');
    const { userId, type } = location.state || {};
    const [error,setError]=useState('');
    const [commentData, setCommentData] = useState(null);
    const [recommendPostStatus,setRecommendPostStatus]=useState(false)
    const [postType, setPostType] = useState();
    const [forwardOpen, setForwardOpen] = useState(false);
    const [commentDataArray, setCommentDataArray] = useState([]);
    const [shareCommentView,setShareCommentView]=useState(true)
    const { feelingCategory } = useSelector((state) => state.FeelingCategoryData);
    const [modal, setModal] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [path, setPath] = useState("/modal/");
    const [likeStatus, setLikeStatus] = useState(false);

    const { postId } = useParams();
    const { userProfileByUserId } = useSelector(
        (state) => state.getUserProfileByUserIdData
      );
    
      useEffect(() => {
        dispatch(loadProfileByUserId());
      }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/post/getPostById`,
                    { postId },
                    {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                        },
                    }
                );
                setData(response.data.data.successResult); // Ensure to access response.data
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [postId]);

      
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/post/getPostById`,
                        {postId:postId,userId: user.id},
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setData(response.data.data.successResult);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [postId]);

    const handleHidePost = async (postId) => {
        try {
          const requestBody = {
            postId: postId,
            isHidden: true,
          };
      
          const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/setPostHide`,
            requestBody,
            {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("sociomeeUser"))?.token
                }`,
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            dispatch(loadAllUserPosts());
          } else {
            console.error(
              "Error Hiding post. Server responded with status:",
              response.status
            );
          }
        } catch (error) {
          console.error("Error Hiding of post:", error);
        }
      };

    

    // console.log("resultData===================>", resultData);

    if (!Array.isArray(data)) {
        return (
            <Spinner
      animation='border'
      role='status'
      style={{
        width: '100px',
        height: '100px',
        margin: 'auto',
        display: 'block',
         color: '#81C14B'
      }}
    >
      <span className='sr-only'>Loading...</span>
    </Spinner>
        ); // Or handle the non-array data appropriately
    }

  
    // console.log("id===================================",id)
  
    
  
    function FormatTimeAgo(createdAt) {
      const now = new Date();
      const createdDate = new Date(createdAt);
      const timeDifference = now - createdDate;
    
      if (timeDifference < 0) {
        return "Scheduled Post"; // Or handle it differently based on your use case
      }
    
      // Define time intervals in milliseconds
      const minute = 60 * 1000;
      const hour = minute * 60;
      const day = hour * 24;
      const week = day * 7;
      const month = day * 30;
      const year = day * 365;
    
      if (timeDifference < minute) {
        const seconds = Math.floor(timeDifference / 1000);
        return `${seconds} Second${seconds !== 1 ? "s" : ""} Ago`;
      } else if (timeDifference < hour) {
        const minutes = Math.floor(timeDifference / minute);
        return `${minutes} Minute${minutes !== 1 ? "s" : ""} Ago`;
      } else if (timeDifference < day) {
        const hours = Math.floor(timeDifference / hour);
        return `${hours} Hour${hours !== 1 ? "s" : ""} Ago`;
      } else if (timeDifference < week) {
        const days = Math.floor(timeDifference / day);
        return `${days} Day${days !== 1 ? "s" : ""} Ago`;
      } else if (timeDifference < month) {
        const weeks = Math.floor(timeDifference / week);
        return `${weeks} Week${weeks !== 1 ? "s" : ""} Ago`;
      } else if (timeDifference < year) {
        const months = Math.floor(timeDifference / month);
        return `${months} Month${months !== 1 ? "s" : ""} Ago`;
      } else {
        const years = Math.floor(timeDifference / year);
        return `${years} Year${years !== 1 ? "s" : ""} Ago`;
      }
    }
  
    const likePost = (postId) => {
      // Add logic here to like the post using an API request
      const requestData = {
        postId: postId,
        reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
      };
  
      axios
        .post(`${process.env.REACT_APP_IPURL}/post/setLike`, requestData, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        })
        .then((response) => {
          // console.log("Post liked successfully");
          setLikeStatus((prevStatus) => ({
            ...prevStatus,
            [postId]: true,
          }));
        })
        .catch((error) => {
          console.error("Error liking post: ", error);
        });
    };
  
    const unlikePost = (postId) => {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/post/setLike`,
          { postId: postId },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        )
        .then((response) => {
          setLikeStatus((prevStatus) => ({
            ...prevStatus,
            [postId]: false,
          }));
        })
        .catch((error) => {
          console.error("Error unliking post: ", error);
        });
    };

  
  const handleProfileView = () => {
    <Link to="/MyProfile" onClick={(e)=>e.preventDefault()}></Link>;
  };
  
  const timeAgo = FormatTimeAgo(data[0]?.createdAt);
  
  const toggleForward = () => {
    setForwardOpen((preState) => !preState);
  };
  
  const handleSave = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isSaved: true,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostSaved`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving on post:", error);
    }
  };
  
  const handleUnsave = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isSaved: false,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostSaved`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving on post:", error);
    }
  };
  //=============================handleCommentLike==============================//
  const handleCommentLike = (comment, setCommentLikeCount) => {
    const requestData = {
      commentId: comment.id,
      // reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
    };
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/setCommentLike`, requestData, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((response) => {
        console.log("liked", response);
        if (response.data.success === true) {
          setCommentLikeCount(comment.likesCount + 1);
        }
      })
      .catch((error) => {
        console.error("Error liking post: ", error);
      });
  };
  
  //==============================handleHidePost=======================//

  
  //==============================handleHidePost=======================//
  const handleNotificationOff = async (postId, setIsNotificationOff) => {
    console.log("postId", postId);
    try {
      const requestBody = {
        postId: postId,
        notificationOff: true,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsNotificationOff(1);
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  
  const handleBlockUnblock = (postType) => {
    dispatch(getActionBlockUnblock(postType));
  };
  
  const toggle = (path) => {
    console.log("toogling path here",path," ",path.indexOf("/modal/"))
    if (path.indexOf("/modal/" > -1)) {
      console.log("toogling path here1",path)
      if (modal) {
        console.log("toogling path here2",parseFloat(path.replace("/modal/", "")))
        setActiveTab(parseFloat(path.replace("/modal/", "")));
  
        setPath(path);
  
        const index = parseFloat(path.replace("/modal/", ""));
  
        const postType =
          index === 0.1
            ? "media"
            : index === 1
            ? "feeling"
            : index === 7.1
            ? "thought"
            : index === 7.3
            ? "event"
            : index === 7.6
            ? "recomendation"
            : index === 7.7
            ? "alert"
            : index === 7.8
            ? "poll"
            : "text";
        setPostType(postType);
      } else {
        const index = parseFloat(path.replace("/modal/", ""));
        if(index===0.1){
          const ptype="media";
          setPostType(ptype)
        }
        setActiveTab(parseFloat(path.replace("/modal/", "")));
        setModal(true);
        setPath(path);
        console.log("modal", modal);
      }
    } else {
      setModal(false);
    }
  };
  
  const handleNotificationOn = async (postId, setIsNotificationOff) => {
    console.log("postId", postId);
    try {
      const requestBody = {
        postId: postId,
        notificationOff: false,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsNotificationOff(0);
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  //==============================handleAllowComments=======================//
  const handleCommentOff = async (
    postId,
    setIsCommentsAllowed,
    isCommentsAllowed
  ) => {
    console.log("postId comment off", postId);
    try {
      const requestBody = {
        postId: postId,
        allowComments: false,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setAllowComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsCommentsAllowed(0);
        dispatch(loadAllUserPosts());
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  
  const EmoteAvatar = () => {
     
    // // return (
    // //   <div className="px-2">
    // //     <ul className="matual-friend-blk">
    // //       {allLikes?.map((item, index) => {
    // //         return (
    // //           <li
    // //             key={index}
    // //             className="popover-cls"
    // //             data-bs-toggle="popover"
    // //             data-placement="right"
    // //             data-name="sufiya eliza"
    // //             data-img={item.profileImageThumb}
    // //           >
    // //             <img src={item.profileImageThumb} className="bg-img" alt="" />
    // //           </li>
    // //         );
    // //       })}
    // //     </ul>
    // //   </div>
    // );
  };
  
  const handleCommentOn = async (
    postId,
    setIsCommentsAllowed,
    isCommentsAllowed
  ) => {
    try {
      const requestBody = {
        postId: postId,
        allowComments: true,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setAllowComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        setIsCommentsAllowed(1);
        dispatch(loadAllUserPosts());
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  
  const handleAllComments = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        commentId: "",
        searchKey: "",
        pageIndex: 0,
        pageSize: 20,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/getComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("load comments section ",response.data.data.successResult.rows)
  
      setCommentDataArray(response.data.data.successResult.rows);
      console.log(commentDataArray)
    } catch (error) {
      console.error("Error commenting on post:", error);
    }
  };



    return (
        <Fragment>
                 <Header></Header>
      <div className="page-body container-fluid profile-page">
        <LeftSidebar></LeftSidebar>
        <div className="page-center">
          <ProfileMenu userId={userId}></ProfileMenu>

          <div className="container-fluid section-t-space px-0">
            <div className="row">
              <div className="content-left col-4 res-full-width order-1">
                <div className="profile-about">
                  <div className="card-main-title">
                    <h3>about</h3>
                  </div>
                  <MyProfession userProfileByUserId={userProfileByUserId} type={type}/>
                  <MyEducation userProfileByUserId={userProfileByUserId} type={type}/>
                
                  <ProfileDetail userProfileByUserId={userProfileByUserId} type={type}/>
                  <ProfileBio userProfileByUserId={userProfileByUserId} type={type}/>
                  <ProfileContactDetail
                    userProfileByUserId={userProfileByUserId} type={type}
                  />
                </div>
              </div>
              <div className="content-center col-xl-8">
              <CardTitle className="PodCast_Title d-flex justify-content-between ">
              <h3>Post detail</h3>
               <Button outline color='secondary' onClick={() => navigate(-1)}><CornerUpLeft size={14} />&nbsp; Back</Button>
              </CardTitle>
          {data.length>0 && (

            <PostCard
            postType={data[0]}
            setPostType={setPostType}
            likePost={likePost}
            unlikePost={unlikePost}
            timeAgo={timeAgo}
            handleProfileView={handleProfileView}
            toggleForward={toggleForward}
            handleSave={handleSave}
            handleUnsave={handleUnsave}
            handleAllComments={handleAllComments}
            commentDataArray={commentDataArray}
            handleCommentLike={handleCommentLike}
            handleHidePost={handleHidePost}
            handleNotificationOff={handleNotificationOff}
            handleNotificationOn={handleNotificationOn}
            handleCommentOn={handleCommentOn}
            EmoteAvatar={EmoteAvatar}
            handleCommentOff={handleCommentOff}
            handleBlockUnblock={handleBlockUnblock}
            setShareCommentView={setShareCommentView}
            shareCommentView={shareCommentView}
            feelingCategory={feelingCategory}
            modal={modal} setModal={setModal}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            toggle={toggle}
            activeTab={activeTab}
            setActiveTab={setActiveTab}

            />

          )}

          
          
          </div>
          {data.length>0 && (
          <ShareToModal 
            forwardOpen={forwardOpen}  
            postType={postType}
            setPostType={setPostType}
            likePost={likePost}
            unlikePost={unlikePost}
            handleProfileView={handleProfileView}
            toggleForward={toggleForward}
            handleSave={handleSave}
            handleUnsave={handleUnsave}
            handleAllComments={handleAllComments}
            commentDataArray={commentDataArray}
            handleCommentLike={handleCommentLike}
            handleHidePost={handleHidePost}
            EmoteAvatar={EmoteAvatar}
            handleNotificationOff={handleNotificationOff}
            handleNotificationOn={handleNotificationOn}
            handleCommentOn={handleCommentOn}
            handleCommentOff={handleCommentOff}
            handleBlockUnblock={handleBlockUnblock} 
            setShareCommentView={setShareCommentView}
            shareCommentView={shareCommentView}  />
          )}
            </div>
          </div>
        </div>
        <RightSidebar></RightSidebar>
      </div>
        </Fragment>
    )
}

export default MyTextPost;