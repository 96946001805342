import { Fragment, useEffect, useState, useRef } from 'react'
import { Link, NavLink,useNavigate,useLocation } from "react-router-dom";
import Sliders from "react-slick";
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { Button, Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap';
import Pd from "./Img/PD.png"
import { ChevronRight, Copy, CornerUpLeft, Edit2, Eye, MessageCircle, MoreHorizontal, MoreVertical, PlayCircle, Plus, Send, Trash2, Volume2, VolumeX } from 'react-feather';
import Media from "./Img/media.png"
import Video from "./Img/mediaVideo.png"
import Play from "./Img/play.png"
import Media1 from "./Img/Media1.png"
import Mic1 from './Img/Mic1.png'
import Prfle from './Img/prfle.png'
import MicImg from './Img/MicImg.png'
import EyeImg from './Img/eye.svg'
import Cmnt from './Img/cmnt.svg'
import Share from './Img/share.svg'
import Paidsvg from './Img/Paidsvg.svg'
import FlwrImg from './Img/FlwrImg.png'
import Like from './Img/likesvg.svg'
import LikeBlack from './Img/LikeBlack.svg'
import { Box, IconButton, Slider, Stack, useTheme, Pagination, Typography } from '@mui/material';
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getTextPostsAction, deletePostAction } from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { data } from 'jquery';
import { sweetAlertConfirmation } from '../group-components/AllGroupsSections/SweetAlert';
import index from '../Events-Components';
import axios from 'axios';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

let TopPodcastSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 2
            }
        }
    ]
};

const MyPodcast = () => {
    let dispatch = useDispatch();

    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));

    const theme = useTheme();
    const [selectActive, setSelectActive] = useState(false)
    const [likedOpen, setLikedOpen] = useState(false)
    const [selectedPodcastType, setSelectedPodcastType] = useState(null);
    const [pageSize,setPageSize]=useState(30);
    const [pageIndex,setPageIndex]=useState(0);
    const [searchKey,setSearchKey]=useState('');
    const [success,setSuccess]=useState(false)
    const [error,setError]=useState('')
    const [LocationData,setLocationData]=useState({});

    useEffect(() => {
        dispatch(getTextPostsAction('podcast',pageSize,pageIndex,searchKey));
        const fetchData= async()=>{


            try {

                const response = await axios.get(
                    `https://api.ipgeolocation.io/ipgeo?apiKey=c1016d597c494a02aa190877148a5688`
                );
                setLocationData(response.data);
                
            } catch (error) {
                
            }
        }
        fetchData();
    }, [success,searchKey])
    
    const getPodCastData = useSelector((state) => { return state.getTextPostsData });
    
    const { loading: loadingAllTextPosts, error: errorAllTextPosts, result: resultAllTextPosts } = getPodCastData

    
    const toggleLiked = () => {
        setLikedOpen((preState) => !preState)
    }
    const [sharedOpen, setsharedOpen] = useState(false)
    const toggleShared = () => {
        setsharedOpen((preState) => !preState)
    }
    const [commentOpen, setCommentOpen] = useState(false)
    const toggleComment = () => {
        setCommentOpen((preState) => !preState)
    }
    const [paused, setPaused] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [deleteOpen,setDeleteOpen]=useState(false);
    const [deleteId,setDeleteId]=useState('');
    const [duration, setDuration] = useState(0);
    const progressRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isPlaying,setIsPlaying]=useState(false)
    const [funcCount,setFuncCount]=useState(0);
    const [addingError,setAddingError]=useState('');

    let count=resultAllTextPosts?resultAllTextPosts.length:0;


    // count abbreviation function
    const numFormatter = (num) => {
        if (num >= 1000 && num < 1000000) {
          return `${(num / 1000).toFixed(1)} K`;
        } else if (num >= 1000000) {
          return `${(num / 1000000).toFixed(1)} M`;
        } else {
          return num.toString();
        }
      };
      

  const audioRef = useRef(null);

  const handlePlay = (e,id) => {
    const audio = audioRef.current;
    setFuncCount(funcCount+1);
    if (audio.paused) {
      audio.play();
      setPaused(false);
      setIsPlaying(true);
    } else {
      audio.pause();
      setPaused(true);
      setIsPlaying(false);
    }
    // if(funcCount<2){
    //     callingAnotherFucnc(id);
    // }
  };

//   console.log("location ==========================================",LocationData)

//   const callingAnotherFucnc=(id)=>{
//     if(LocationData){

    
//     const fetchData = async () => {
//         try {
//             let user = JSON.parse(localStorage.getItem('sociomeeUser'));
//             if (user) {
//                 const response = await axios.post(
//                     `${process.env.REACT_APP_IPURL}/podcast/addListnerLocation`,
//                     { podcastId:id, location2:LocationData.city, location3:LocationData.state_prov, location4:LocationData.country_name,userId:userId},
//                     { headers: { Authorization: `Bearer ${user.token}` } }
//                 );
//             } else {
//                 setAddingError('no user found');
//             }
//         } catch (error) {
//             setAddingError(error);
//         }
//     };
  
//     fetchData();
//    }
//     console.log("somwthis==================")
//   }

  let allTypePods;

  const getUniqueNames = (posts) => {
    // Get all the names from the posts
    const names = posts?.map(post => post.name);
  
    // Use a Set to remove duplicates
    const uniqueNames = [...new Set(names)];
  
    return uniqueNames;
  };

  allTypePods= getUniqueNames(resultAllTextPosts);

  const handleFastForward = () => {
    const audio = audioRef.current;
    audio.currentTime += 10; // Fast forward by 10 seconds (adjust as needed)
  };
  const [recentlyData, setRecentlyData] = useState([]);

  useEffect(() => {
    if (resultAllTextPosts?.length > 0) {
        const podcastPost = resultAllTextPosts.find(post => post.postType === 'podcast');
        if (podcastPost) {
            setRecentlyData([podcastPost]);
        } else {
            setRecentlyData([]);
        }
    }
}, [resultAllTextPosts]);

//   console.log("recently data==============================>",recentlyData)
  const containerStyle = {
    width: '100%',
    maxWidth: '600px', // Set a max width for larger screens if needed
    margin: '0 auto', // Center the container
    padding: '10px',
};

const audioStyle = {
    width: '100%',
};


const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };

      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };

      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [audioRef.current]);

  const handleSeekBarChange = (e, newValue) => {
    const audio = audioRef.current;
    audio.currentTime = newValue;
    setCurrentTime(newValue);
    if (!paused) {
      audio.play();
    }
  };

  
  const formatTitle = (title) => {
    if (!title) return "no title";
    const words = title.split(" ");
    if (words.length <= 3) return title;
    return words.slice(0, 3).join(" ") + "...";
  };

//   console.log("isPauser====================================", isPlaying)

    const PeopleDataList = [
        {

            img: Prfle,
            name: "Marvin McKinney",
            joingData: "Joined on 23 Mar 2021",
            btnType: 'SocioMate ',
            id: "dianne-russell123",

        },
        {
            img: Prfle,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            btnType: 'SocioMate ',
            id: "dianne-russell123",
            btnType1: "Following",

        },
        {
            img: Prfle,
            name: "Marvin McKinney",
            joingData: "Joined on 23 Mar 2021",
            btnType: 'Following ',
            id: "dianne-russell123",

        },
        {
            img: Prfle,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            btnType: 'Following',
            id: "dianne-russell123",

        },
        {
            img: Prfle,
            name: "Marvin McKinney",
            joingData: "Joined on 23 Mar 2021",
            btnType: 'SocioMate ',
            id: "dianne-russell123",

        }
    ]
    const RecentlyPlayedCard = () => (
        <Fragment>
                {recentlyData?.length > 0 && recentlyData?.map((data) => (
                <Card className="Recently__Card_Media">
                    <img src={data?.podcastDetails?.coverImageURL||''} className="img-fluid" />
                    <CardBody className="Recently__Card_buttom_section">
                        <h2>{data?.podcastDetails?.title} </h2><br/>
                        <div style={containerStyle}>
                            <audio id="audio-cont" ref={audioRef} style={audioStyle}>
                                <source src={data?.podcastDetails?.mediaURL||''} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 1,
                            }}
                        >
                            <IconButton aria-label="previous song">
                                <FastRewindRounded fontSize="small" style={{ color: "#979797" }} />
                            </IconButton>
                            <IconButton
                                aria-label={paused ? 'play' : 'pause'}
                                onClick={(e)=>handlePlay(e,data.id)}
                                className="center_btn_action"
                                style={{ backgroundColor: "#81C14B" }}
                            >
                                {paused ? (
                                    <PlayArrowRounded
                                        sx={{ fontSize: '2rem' }}
                                        htmlColor="#ffffff" 


                                    />
                                ) : (
                                    <PauseRounded sx={{ fontSize: '2rem' }}
                                    htmlColor="#ffffff" 
                                    />
                                )}
                            </IconButton>
                            <IconButton aria-label="next song">
                                <FastForwardRounded fontSize="small" style={{ color: "#979797" }}  onClick={handleFastForward} />
                            </IconButton>
                        </Box>
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Typography>{formatTime(currentTime)}</Typography>
                            <Slider
                                aria-label="Seek Bar"
                                value={currentTime}
                                min={0}
                                max={duration}
                                onChange={handleSeekBarChange}
                                sx={{
                                color: "#81C14B",
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 24,
                                    height: 24,
                                    backgroundColor: '#fff',
                                    '&:before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                    },
                                },
                                }}
                            />
                            <Typography>{formatTime(duration)}</Typography>
                        </Stack>
                    </CardBody>
                </Card>
            ))}
        </Fragment>
    )
    const navigate = useNavigate();

    const editPodcast=(e)=>{
        e.preventDefault();
        e.stopPropagation();
        sweetAlertConfirmation("Edit features is in progress")
    }
    const navigationToDetail=(e)=>{
        navigate("/Podcast/NewPodcastSeries")
    }
    const deletePC=(e,id)=>{
        e.stopPropagation()
        e.preventDefault();
        setDeleteId(id);
        setDeleteOpen(true);
    }
    const toggleDeleteOpen=()=>{
        setDeleteId('');
        setDeleteOpen(false)
    }
    const deleteAllPost = async (e) => {
        e.stopPropagation();
        try {
          await dispatch(deletePostAction([deleteId]));
          setSuccess(true);
          sweetAlertConfirmation('Successfully deleted');
          setDeleteOpen((prevState) => !prevState);
        } catch (error) {
         setError(error);
        }
      };

      const postCopyLink = async (e,id,postType) => {
        e.stopPropagation();
        e.preventDefault();
        const fullURL = `${window.location.protocol}/${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
        const newCopyLink = `${fullURL}/MyPodcastDetails/${id}`;
        await navigator.clipboard.writeText(newCopyLink);
        sweetAlertConfirmation(`Link Copied to clipboard`);
      };

      const recentlysettingdata=(e,data)=>{
        e.stopPropagation();
        e.preventDefault();
        setRecentlyData([data])
      }

      const consolingdng=(e)=>{
        e.stopPropagation();
        e.preventDefault();
      }

    const RecentlyMusicsList = () => (
        <Fragment>
            <div>
            {
                resultAllTextPosts
                    ?.filter(data => data.postType === 'podcast' && recentlyData.some(recent => recent.id !== data.id)) // Filter items where postType is 'podcast'
                    .map((data) => (
                        <div key={data.id} className='d-flex justify-content-between mt-3' onClick={(e) => recentlysettingdata(e, data)}>
                            <div className="Recently_Player_List">
                                <img
                                    src={data?.podcastDetails?.coverImageURL || process.env.DUMMY_PODCAST_COVER}
                                    className="img-fluid video_image"
                                    alt='img'
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <div className='Play_Btn_Section'>
                                    <img src={Play} className="img-fluid" alt='img' />
                                </div>
                                <div className='Media_name ml-2'>
                                    <h4>{data?.podcastDetails?.title}</h4>
                                    <div className="Suggestmember-blk">
                                        <span> {data?.day || 'Few '}days ago &nbsp;</span> • <span> Ep {data?.podcastDetails?.totalEpisodes || 0}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='flat-primary'>
                                        <MoreVertical color='#6E6B7B' size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu className="PodCast_DropDown_item">
                                        <DropdownItem onClick={(e) => editPodcast(e)}>
                                            <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => handlePlay(e,data.id)}>
                                            <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => navigationToDetail(e)}>
                                            <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => deletePC(e, data.id)}>
                                            <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Send size={15} color="#4B4B4B" />&nbsp; Share podcast
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => postCopyLink(e, data.id, data.postType)}>
                                            <Copy size={15} color="#4B4B4B" />&nbsp; Copy link
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    ))
            }

            {/* <div className='d-flex justify-content-center mt-4'>
                <div className='Recently_Player_List_botton'>See All</div>
            </div> */}
            </div>

        </Fragment>
    )

    // console.log("searchKeuy=====================>",searchKey)

    
    const AllTypesPodcast = (props) => {
        // Filter posts to only include those with postType 'series' and matching title
        // console.log("props==================================",props)
        const filteredPosts = resultAllTextPosts.filter(post => 
            post.postType === 'podcast' && post.name === props.title
        
            
        );

        // console.log("filteredPosts===========================",filteredPosts)
    
        const handleSeeAllClick = () => {
            setSelectedPodcastType(props.title);
            setSelectActive(true); // Assuming setSelectActive toggles the view
        };

    
        return (
            <Fragment>
                {filteredPosts.length > 0 && (
                    <>
                        <CardTitle className={props.className}>
                            <h4>{props.title}</h4>
                            {filteredPosts.length > 4 && (
                                <p className='d-flex align-items-center' onClick={handleSeeAllClick}>
                                    {props.nav}
                                    <ChevronRight size={16} />
                                </p>
                            )}
                        </CardTitle>
                        <div className="podcast-list-block">
                            <Sliders {...TopPodcastSettings} className="default-space">
                                {filteredPosts.map((data, index) => (
                                    <div key={index} onClick={(e)=>navigateToSomewhere(e,data.id,data?.postType)}>
                                        
                                            <Card className="TopPodcast_Section">
                                                <img
                                                    src={data?.podcastDetails?.coverImageURL}
                                                    className="img-fluid"
                                                    alt="podCast"
                                                    style={{ height: '175px' }}
                                                />
                                                <div className='TopPodcat_Left_Side'>
                                                    <img src={MicImg} width={18} height={19} alt="mic_img" />
                                                    <p className='ml-1'>{numFormatter(data?.podcastDetails?.listenerCount)}</p>
                                                </div>
                                                <div className='TopPodcat_Left_right'>
                                                    <h4>{data?.podcastDetails?.totalEpisode}</h4>
                                                </div>
                                                <div className='TopPodcat_bottom_left'>
                                                    <h4>{formatTitle(data?.podcastDetails?.title)}</h4>
                                                </div>
                                                {data?.podcastDetails?.isPaid===1 && (
                                                    <div className='TopPodcast_bottom_right'>
                                                        <img src={Paidsvg} alt="pd" className='img-fluid' />
                                                    </div>
                                                )}
                                            </Card>
                                    
                                    </div>
                                ))}
                            </Sliders>
                        </div>
                    </>
                )}
            </Fragment>
        );
    };

    const AllTypesModal = (props) => (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal scrollable isOpen={props.isOpen} toggle={props.toggleClick} className='modal-dialog-centered'>
                    <ModalHeader>
                        <h3>{props.title} </h3>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <h4>This list shows the users who have <span style={{ color: "#81C14B" }} >{props.type}</span>  your  <span style={{ color: "#81C14B" }}>Podcast.</span></h4>
                        </div>
                        <div className="py-3">
                            <div className="profile-search-blk w-100 ">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                            </div>
                        </div>
                        <Row className="mt-2">
                            <div className="group-detail-page-blk ">
                                {
                                    PeopleDataList.map((data) => (
                                        <div className="Follower-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <img src={FlwrImg} alt="img" className='img-fluid' />
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p>{data.joingData}</p>
                                                    <div className="Suggestmember-blk py-1">
                                                        <h4>9.8M&nbsp;<span>Followers</span> <span>•</span> 18M&nbsp;<span>Posts</span></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Block-btns-blk'>
                                                <Button outline color='primary'>Sociomate</Button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Row>

                    </ModalBody>
                </Modal>
            }
        </Fragment>
    )

    const handleSearchInputChange = (e) => {
        setSearchKey(e.target.value);
        // You can add additional logic here, such as filtering data based on searchKey
    };

    const newPodcatCreate=(e)=>{
        e.stopPropagation();
        e.preventDefault();
        navigate("/Podcast/NewPodcastSeries");
    }

    const navigateToSomewhere =(e,id,postType)=>{
        e.stopPropagation();
        e.preventDefault();
        navigate(`/MyPodcastDetails/${id}`, {
            state: { postType,userId,type }
        });
    }

    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="gallery-page-section section-b-space">
                            <div className="card-title">
                                <h3>Podcast ({count||0})</h3>
                                <div className="right-setting">
                                    <div className="profile-search-blk ">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="search"
                                            placeholder="Find ..."
                                            data-bs-toggle="modal"
                                            data-bs-target="#peopleSearch"
                                            value={searchKey}
                                            onChange={handleSearchInputChange}
                                        />
                                    </div>
                                    <Link to="/Podcast" className="btn btn-outline-green ms-3">Explore</Link>
                                    <Link to="/Podcast/NewPodcastSeries" className="btn btn-outline-green ms-3">Create / Upload</Link>
                                </div>
                            </div>
                            <CardBody>
                                {!selectActive ? (
                                    <Row>
                                        <Col md="8" className='border-right'>
                                            {allTypePods.map((data, index) => (
                                                <AllTypesPodcast
                                                    key={index} // Adding a key is important when rendering lists in React
                                                    title={data}
                                                    nav="See All"
                                                    setSelectedPodcastType={setSelectedPodcastType} // Pass the state setter as a prop
                                                    setSelectActive={setSelectActive}
                                                    className="PodCast_Title d-flex justify-content-between"
                                                />
                                            ))}
                                        </Col>
                                        <Col md="4">
                                            <CardTitle className="Title_Podcast d-flex justify-content-between">
                                                {recentlyData?.length>0 &&(
                                                  <h4>Recently Played</h4>
                                                )}
                                                <div>
                                                    {recentlyData?.length>0 && recentlyData?.map((data,index)=>(
                                                        <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem onClick={(e)=>editPodcast(e)}>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                             <DropdownItem onClick={(e)=>handlePlay(e,data.id)} >
                                                                <PlayCircle size={15} color="#4B4B4B"/>&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem tag={Link} to="/Podcast/NewPodcastSeries">
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem onClick={(e)=>deletePC(e,data.id)}>
                                                                <Trash2 size={15} color="#4B4B4B"  />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem onClick={(e) => {
                                                                postCopyLink(e,data.id,data.postType);
                                                                }}>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                    ))}
                                                    
                                                </div>

                                            </CardTitle>
                                            <RecentlyPlayedCard />
                                            <RecentlyMusicsList />

                                        </Col>
                                    </Row>
                                ) : (

                                    <Fragment>
                                <CardTitle className="PodCast_Title d-flex justify-content-between ">
                                    <h3>{selectedPodcastType} - See All</h3>
                                    <Button onClick={() => setSelectActive(false)} outline color='secondary'><CornerUpLeft size={14} />&nbsp; Back</Button>
                                </CardTitle>
                                <Row className="mt-4">
                                    {
                                        resultAllTextPosts
                                            .filter(post => post.name === selectedPodcastType)
                                            .map((data) => (
                                                <Col md="6" key={data.id}>
                                                    <div className='Podcast_Media_Card_Box border p-2 mb-3'>
                                                        <img src={data?.podcastDetails?.coverImageURL} className='Podcast_media_blk' alt="img" />
                                                        <p className='Media_Mic'>
                                                            <img src={Mic1} alt="img" className='img-fluid' width={40} height={40} />
                                                        </p>
                                                        {data?.podcastDetails?.isPaid===1 ? (
                                                            <div className='Media_Paid'>
                                                                <img src={Paidsvg} alt="pd" className='img-fluid' />
                                                            </div>
                                                        ):null}
                                                        <div className='w-100 ml-2' onClick={(e)=>navigateToSomewhere(e,data.id,data?.postType)}>
                                                        <div
                                                            className='Podcast_link_heading'
                                                            
                                                            >

                                                                <h3>{formatTitle(data.podcastDetails.title)}</h3>
                                                           
                                                            <h4 className='py-2'>{data.name}</h4>
                                                            <p className='text-muted'>Total Episodes &nbsp;{data.podcastDetails.totalEpisodes}</p>
                                                            <div className='d-flex justify-content-between mt-3'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='PodCast_Icon_Section rounded-circle'>
                                                                        <img src={EyeImg} />
                                                                    </div>
                                                                    <span className='ml-3 Span_text' style={{ cursor: "pointer" }}>{numFormatter(data.podcastDetails.viewsCount)||0}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src={LikeBlack} />
                                                                    </div>
                                                                    <span className='ml-3 Span_text' style={{ cursor: "pointer" }}>{numFormatter(data.podcastDetails.likesCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src={Cmnt} />
                                                                    </div>
                                                                    <span className='ml-3 Span_text' style={{ cursor: "pointer" }}>{numFormatter(data.podcastDetails.commentsCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src={Share} />
                                                                    </div>
                                                                    <span className='ml-3 Span_text' style={{ cursor: "pointer" }}>{numFormatter(data.podcastDetails.sharesCount)}</span>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <MoreVertical style={{ cursor: "pointer" }} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size={20} color="#6E6B7B" />
                                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal podcast_option">
                                                                <ul>
                                                                    <li onClick={(e)=>navigateToSomewhere(e,data.id,data.postType)}>
                                                                        <Link to="#">Play</Link>
                                                                    </li>
                                                                    <li onClick={(e)=>editPodcast(e)}>
                                                                        <Link to="#">Edit Series</Link>
                                                                    </li>
                                                                    <li onClick={(e)=>newPodcatCreate(e)}>
                                                                        <Link to="#">Add new episode</Link>
                                                                    </li>
                                                                    <li onClick={(e)=>newPodcatCreate(e)}>
                                                                        <Link to="#">Create new series</Link>
                                                                    </li>
                                                                    <li onClick={(e)=>deletePC(e,data.id)}>
                                                                        <Link to="#">Delete series</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="#">Bookmark</Link>
                                                                    </li>
                                                                    <li onClick={(e)=>navigateToSomewhere(e,data.id,data.postType)}>
                                                                        <Link to="#">View Series Analytics</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="#" style={{ color: "#1066AD" }}>Learn more</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                    }
                                </Row>
                            </Fragment>
                                )}
                            </CardBody>
                        </div>
                    </div>
                    <AllTypesModal
                        title="Total liked (70)"
                        isOpen={likedOpen}
                        toggleClick={toggleLiked}
                        type="Liked"
                    />
                    <AllTypesModal
                        title="Total Shared (70)"
                        isOpen={sharedOpen}
                        toggleClick={toggleShared}
                        type="Share"
                    />
                    <AllTypesModal
                        title="Total Shared (70)"
                        isOpen={commentOpen}
                        toggleClick={toggleComment}
                        type="Commented"
                    />
                </div>

                <RightSidebar />
            </div>
            <div className="vertically-centered-modal">
                    {deleteOpen !== false && (
                        <Modal
                        isOpen={deleteOpen}
                        toggle={toggleDeleteOpen}
                        className="modal-dialog-centered"
                        >
                        <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                            <h4>Delete Podcast</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex justify-content-center modalHeading_Section">
                            <p>Do you really want to delete the selected Podcast ?</p>
                            </div>
                            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                            <small>If</small> YES! <small>please click on ok</small>
                            </h1>
                            <div className="d-flex justify-content-center  mt-4">
                            <Button onClick={toggleDeleteOpen} outline style={{ width: '100px' }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={(e)=>deleteAllPost(e)}
                                color="primary"
                                className="ml-3 "
                                style={{ width: '100px' }}
                            >
                                OK
                            </Button>
                            </div>
                        </ModalBody>
                        </Modal>
                    )}
                    </div>
                    <br/>
        </Fragment>
    )
}

export default MyPodcast