// import React, { useState, useEffect } from 'react';
// import { Fragment } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Import the styles

// const MpFormDiscription = ({ onDataPass, value }) => {
//     const [text, setText] = useState('');


//     //setting value from backEnd to  edit product 
//     useEffect(()=>{
//         if(value){
//             setText(value)
//         }
//     },[value])


//     const stripHtml = (html) => {
//         let doc = new DOMParser().parseFromString(html, 'text/html');
//         return doc.body.textContent || "";
//     }

//     const handleChange = (content) => {
//         setText(content);
//         onDataPass(stripHtml(content)); // Pass stripped content to parent
//     };

//     const modules = {
//         toolbar: [
//             [{ 'header': [1, 2, false] }],
//             ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//             [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
//             ['link'],
//             ['clean']
//         ]
//     };

//     const formats = [
//         'header',
//         'bold', 'italic', 'underline', 'strike',
//         'list', 'bullet', 'indent',
//         'link',
//     ];

//     return (
//         <Fragment>
//             <div className='MpformElementHeading'>
//                 <h5>Description</h5>
//             </div>
//             <div className="MpFormDiscriptionSection mt-2 border" style={{ borderRadius: "5px" }}>
//                 <ReactQuill
//                     theme="snow"
//                     value={text}
//                     onChange={handleChange}
//                     modules={modules}
//                     formats={formats}
//                 />
//             </div>
//         </Fragment>
//     );
// };

// export default MpFormDiscription;

//0old code with react quill

import React, { useState, useEffect, Fragment } from 'react';

const MpFormDiscription = ({ onDataPass, value }) => {
    const [text, setText] = useState('');

    // Set value from backend to edit product
    useEffect(() => {
        if (value) {
            setText(value);
        }
    }, [value]);

    const handleChange = (event) => {
        const newText = event.target.value;

        // Check if the new text exceeds the limit
        if (newText.length <= 600) {
            setText(newText);
            onDataPass(newText); // Pass text to parent component
        }
    };

    return (
        <Fragment>
            <div className='MpformElementHeading'>
                <h5>Description</h5>
            </div>
            <div className="MpFormDiscriptionSection mt-2">
                <textarea
                    placeholder="Enter your description"
                    value={text}
                    onChange={handleChange}
                    style={{
                        width: '100%',
                        height: '150px',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        resize: 'vertical',
                        outline: 'none', 
                        boxShadow: 'none', 
                        transition: 'border-color 0.3s', 
                    }}
                    onFocus={(e) => e.target.style.border = '2px solid #81c1b4'} 
                    onBlur={(e) => e.target.style.border = '1px solid #ccc'}
                />
                <p style={{textAlign:"right"}}>Max 600 Characters</p>
            </div>
        </Fragment>
    );
};

export default MpFormDiscription;
