
import React, { useState } from 'react'
import { ImageGrid } from "react-fb-image-video-grid";
// import { Input } from 'reactstrap';
import '../../postdisplaycreation.css'

const image = [
    "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    "https://thumbs.dreamstime.com/b/imagination-girl-kiss-lion-love-nature-abstract-concept-young-steals-male-wildlife-children-like-to-imagine-play-129595579.jpg",
    "https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg45jUi84SYeCf4uNAaprS7aoKzS8AohaLwQ&usqp=CAU",
    "https://media.istockphoto.com/photos/picturesque-morning-in-plitvice-national-park-colorful-spring-scene-picture-id1093110112?k=20&m=1093110112&s=612x612&w=0&h=3OhKOpvzOSJgwThQmGhshfOnZTvMExZX2R91jNNStBY=",
    "https://media.istockphoto.com/photos/picturesque-morning-in-plitvice-national-park-colorful-spring-scene-picture-id1093110112?k=20&m=1093110112&s=612x612&w=0&h=3OhKOpvzOSJgwThQmGhshfOnZTvMExZX2R91jNNStBY=",
    "https://media.istockphoto.com/photos/picturesque-morning-in-plitvice-national-park-colorful-spring-scene-picture-id1093110112?k=20&m=1093110112&s=612x612&w=0&h=3OhKOpvzOSJgwThQmGhshfOnZTvMExZX2R91jNNStBY="
];
const Media = (props) => {

    const [count, setCount] = useState(5);


    const Image = ({ count, images }) => {

        const pic = (c, i) => {
            return (
                <img
                    style={{ objectFit: "fill" }}
                    src={c}

                    alt={i}
                    key={Math.random(i)}
                    className='border shadow-sm img-fluid'
                />
            );
        };

        return (
            <>
                {count >= 2 ? (
                    <ImageGrid showModal={false}>
                        {images
                            .filter((arg, i) => (i + 1 <= count ? true : false))
                            .map((a) => pic(a))}
                    </ImageGrid>
                ) : (
                    <ImageGrid showModal={false}>{pic(images[0])}</ImageGrid>
                )}
            </>
        )
    }

    return (
        <div>

            <div>

                <Image count={count} images={props.image} />

            </div>
        </div>
    )
}

export default Media