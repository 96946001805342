import { Checkbox } from '@mui/material'
import React,{ useState, useEffect } from 'react'
import { Search } from 'react-feather'
import { Card, CardBody, Col, Input, InputGroup, Modal, ModalBody, Row } from 'reactstrap'
import { SociomateList } from '../HoverComponents/SociomateList'
import { MutualFriendList } from '../HoverComponents/MutualFriendList'
import { Button } from 'react-bootstrap'
import axios from 'axios'

const AddCategoryModal = (props) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]); // State to track selected categories

  const getRemainingCategory = async () => {
    try {
        const body = {
          socioMateId: props.userId
        };
        let user = JSON.parse(localStorage.getItem("sociomeeUser"));
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        };
        const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getRemainingCategory`,
            body,
            config
        );
        setCategories(response.data.data.successResult.data); // Store fetched data in state
    } catch (error) {
        console.log("Error getting add user to connection:", error);
    }
  };

  const addUserToConnection = async () => {
    try {
        const body = {
            followingUserIds:[props.userId],
            connectionIds:selectedCategoryIds
        };
        let user = JSON.parse(localStorage.getItem("sociomeeUser"));
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        };
        const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/addUserToList`,
            body,
            config
        );
        console.log("User added to connection:", response.data);
    } catch (error) {
        console.log("Error getting add user to connection:", error);
    }
  };

  const handleSaveClick = () =>{
    addUserToConnection();
    props.cateModalOpen();
    setSelectedCategoryIds([]);
  }

  // Re-fetch categories when modal opens
  useEffect(() => {
    if (props.openModal) {
      getRemainingCategory();
      setSelectedCategoryIds([]);
    }
  }, [props.openModal]);

  // Filter categories based on the search query
  const filteredCategories = categories.filter(category =>
    category.connectionName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle category selection
  const handleCategorySelection = (categoryId) => {
    setSelectedCategoryIds((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId) // Remove if already selected
        : [...prevSelected, categoryId] // Add if not selected
    );
  };

  //useEffect(() => {
  //  console.log("selected Id=======>", selectedCategoryIds);
  //}, [selectedCategoryIds]);
  
  //const addCategoryOption = [
  //  {
  //    addCatOption: "School Friends",
  //  },
  //  {
  //    addCatOption: "College Friends",
  //  },
  //  {
  //    addCatOption: "School Friends",
  //  },
  //  {
  //    addCatOption: "Office colleagues",
  //  },
  //  {
  //    addCatOption: "College Friends",
  //  },
  //  {
  //    addCatOption: "College Friends  ",
  //  }
  //]

  return (
    <>
      {
        props.openModal !== false &&
        <Modal isOpen={props.openModal} toggle={props.cateModalOpen} className='modal-dialog-centered'>
          <ModalBody className='Modal_Body_Section'>
            <Row className='border-bottom d-flex align-items-center justify-content-between'>
              <Col xl='8'>
                <h3>Select Category</h3>
              </Col>
              <Col xl='4' className='d-flex justify-content-end'>
              <Button 
                  variant="outline-primary" 
                  className='my-2' 
                  onClick={handleSaveClick}
                  disabled={selectedCategoryIds.length === 0} // Disable if no category is selected
                  style={{
                    backgroundColor: selectedCategoryIds.length === 0 ? '#E6F3DB' : '', // Transparent when disabled
                    color: selectedCategoryIds.length === 0 ? '#000' : '', // Black text when disabled
                  }}
              >
                  Save
                </Button>
              </Col>
            </Row>
            <InputGroup className="input-group-merge d-flex px-3 py-1 my-3 rounded bg-light align-items-center mr-2">
              <Search color='#666666' size={16} />
              <Input
                type='search'
                placeholder="Search Category..."
                className='coonection-input-style'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
              />
            </InputGroup>
            <div className='modal-scroll modal_scrollconnections'>
              {filteredCategories.map((category, index) => (
                <Card className='w-100 mt-2' key={category.id}>
                  <CardBody>
                    <div className='d-flex align-items-center'>
                      <div>
                      <Checkbox
                          {...label}
                          checked={selectedCategoryIds.includes(category.id)} // Check if selected
                          onChange={() => handleCategorySelection(category.id)} // Handle selection
                          className='Check_Input_section'
                      />
                      </div>
                      <div className='ml-3'>
                        <h4>{category?.connectionName}</h4>
                        <SociomateList sociomateList={category?.sociomateList} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          </ModalBody>
        </Modal>
      }</>
  )
}

export default AddCategoryModal