import React, { useState, useEffect } from 'react'
import { AlertTriangle, ArrowLeft, ArrowRight, ChevronDown, Copy, CreditCard, Download, Edit, FileText, Filter, Grid, HelpCircle, Home, Layers, Plus, Search, Settings, Trash2, X } from 'react-feather';
import DataTable from 'react-data-table-component';
import { Badge, Button, Card, CardBody, CardHeader, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, NavLink, Row, UncontrolledButtonDropdown } from 'reactstrap';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactPaginate from 'react-paginate';
import coverImage from '../../../../NewComponents/IMG/reel4.png'
import { Pagination, Stack } from '@mui/material';
import ChartIcon from '../../../../NewComponents/IMG/AdmangerIconChart.svg'
import BlackPin from '../../../../NewComponents/IMG/BlackPin.svg'
import DatePicker from 'react-datepicker';
const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none', // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: '#E5F0E0',
            fontSize: '12px',
            paddingLeft: '6px', // override the cell padding for head cells
            paddingRight: '0px',
            whiteSpace: '',
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
            // marginTop: '1%',
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: '400'
        }
    }
}

export default function AdAnalysisTable() {

    // const [loading, setLoading] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // const [searchValue, setSearchValue] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])

    const now = new Date();
    const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const [value, onChange] = useState([yesterdayBegin, todayEnd]);
    const [checkList, setCheckList] = useState({
        serialnoCheck: true,
        OffOnCheck: true,
        adNameCheck: true,
        adIdCheck: true,
        adTypeCheck: true,
        totalImpressionsCheck: true,
        totalReachCheck: true,
        clickCheck: true,
        viewCheck: true,
        leadCheck: true,
        totalAmountCheck: true,
        dailyBudgetCheck: true,
        overallBudgetCheck: true,
        startDateCheck: true,
        endDateCheck: true,
        lastSignificantCheck: true,
    })

    // const handleSearch = (event) => {
    //   setSearchValue(event.target.value)
    //   setRender(!render)
    // }

    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }

    const [deleteallModal, setDeleteallModal] = useState(false)

    const DeletedModal = () => {
        return (
            <>
                <Modal isOpen={deleteallModal} className='modal-dialog-centered' toggle={() => setDeleteallModal(!deleteallModal)}>
                    <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader>
                    <ModalBody>
                        <div className='text-center mb-2'>
                            <Trash2 size={70} color="#EA5455" />
                            <h3 className='mt-2'>Do You Really Want to Delete all ?</h3>
                            <h5>{selectedRows.length}</h5>
                        </div>
                        <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the ‘Action History’ screen." />
                        </InputGroup>
                        <div className="d-flex justify-content-around m-1">
                            <div>  <Button type="submit" color="danger" outline >Yes , Delete it</Button></div>
                            <div><Button color="success" outline onClick={() => setDeleteallModal(!deleteallModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

    const tabledata = [
        {
            id: '1',
            name: 'New Traffic-Kickstarter',
            reach: '24',
            Impression: '21',
            Lead: '5',
            Click: '29',
            View: '48',
            Likes: '23',
            Shares: '21',
            Bookmarks: '29',
            Platform: 'Mobile App',
            PlatformType: 'Android',
            dateTime: '2022-11-05T11:13:00.000Z',
        },
        {
            id: '2',
            name: 'New Traffic-Kickstarter',
            reach: '24',
            Impression: '21',
            Lead: '5',
            Click: '29',
            View: '48',
            Likes: '23',
            Shares: '21',
            Bookmarks: '29',
            Platform: 'Website',
            PlatformType: 'Windows',
            dateTime: '2022-11-05T11:13:00.000Z',
        },
        {
            id: '3',
            name: 'New Traffic-Kickstarter',
            reach: '24',
            Impression: '21',
            Lead: '5',
            Click: '29',
            View: '48',
            Likes: '23',
            Shares: '21',
            Bookmarks: '29',
            Platform: 'Mobile App',
            PlatformType: 'IOS',
            dateTime: '2022-11-05T11:13:00.000Z',
        }
    ]

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
            // omit: !checkList.serialnoCheck,
            sortable: true,
            center: true
        },

        {
            name: "Ad Name",
            selector: row => row.userName,
            minWidth: "220px",
            // omit: !checkList.adNameCheck,
            sortable: true,
            cell: row => (
                <div >
                    <div className='align-items-center d-flex'>
                        <img className='border' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />
                        <div className='ml-2 fontForSubTablheading'>
                            <h4 style={{ fontSize: '12px' }}>{row.name}</h4>
                            {/* <div className='d-flex text-primary align-items-center'>
                                <img src={ChartIcon} alt='' />
                                <NavLink className='ml-1' to={'/AdAnalysis'} ><u style={{ fontSize: '12px' }}>View Charts</u></NavLink>
                            </div> */}

                        </div>

                    </div>
                </div>
            )
        },


        {
            name: "Date & Time",
            minWidth: "100px",
            // omit: !checkList.startDateCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px' }}>{moment(row.DateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px' }}>{moment(row.DateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: 'Reach',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.reach}</h5>
                </div>
            )

        },
        {
            name: 'View',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.View}</h5>
                </div>
            )

        },
        {
            name: 'Impression',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-54px', fontSize: '12px' }}>{row.Impression}</h5>
                </div>
            )

        },
        {
            name: 'Lead',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.Lead}</h5>
                </div>
            )

        },
        {
            name: 'Click',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.Click}</h5>
                </div>
            )

        },

        {
            name: 'Likes',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.Likes}</h5>
                </div>
            )

        },
        {
            name: 'Shares',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.Shares}</h5>
                </div>
            )

        },
        {
            name: 'Bookmark',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-28px', fontSize: '12px' }}>{row.Bookmarks}</h5>
                </div>
            )

        },
        {
            name: 'Platform',
            sortable: true,
            // omit: !checkList.adTypeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubchildCardheading'>
                    <h5 style={{ fontSize: '12px' }}>{row.Platform}</h5>
                    <h6 style={{ fontSize: '12px' }}>{row.PlatformType}</h6>
                </div>
            )

        },
    ]



    const checkListArr = [
        {
            check: {
                ...checkList,
                serialnoCheck: !checkList.serialnoCheck
            },
            state: checkList.serialnoCheck,
            name: "Sr. No"
        },
        {
            check: {
                ...checkList,
                OffOnCheck: !checkList.OffOnCheck
            },
            state: checkList.OffOnCheck,
            name: "Off/On"
        },
        {
            check: {
                ...checkList,
                adNameCheck: !checkList.adNameCheck
            },
            state: checkList.adNameCheck,
            name: "Ad Name"
        },
        {
            check: {
                ...checkList,
                adIdCheck: !checkList.adIdCheck
            },
            state: checkList.adIdCheck,
            name: "Ad ID"
        },
        {
            check: {
                ...checkList,
                adTypeCheck: !checkList.adTypeCheck
            },
            state: checkList.adTypeCheck,
            name: "Ad Type"
        },
        {
            check: {
                ...checkList,
                totalImpressionsCheck: !checkList.totalImpressionsCheck
            },
            state: checkList.totalImpressionsCheck,
            name: "Total Impressions"
        },
        {
            check: {
                ...checkList,
                totalReachCheck: !checkList.totalReachCheck
            },
            state: checkList.totalReachCheck,
            name: "Total Reach"
        },
        {
            check: {
                ...checkList,
                clickCheck: !checkList.clickCheck
            },
            state: checkList.clickCheck,
            name: "Click"
        },
        {
            check: {
                ...checkList,
                viewCheck: !checkList.viewCheck
            },
            state: checkList.viewCheck,
            name: "View"
        },
        {
            check: {
                ...checkList,
                leadCheck: !checkList.leadCheck
            },
            state: checkList.leadCheck,
            name: "Lead"
        },
        {
            check: {
                ...checkList,
                totalAmountCheck: !checkList.totalAmountCheck
            },
            state: checkList.totalAmountCheck,
            name: "Total Amount spent"
        },
        {
            check: {
                ...checkList,
                dailyBudgetCheck: !checkList.dailyBudgetCheck
            },
            state: checkList.dailyBudgetCheck,
            name: "Daily Budget"
        },
        {
            check: {
                ...checkList,
                overallBudgetCheck: !checkList.overallBudgetCheck
            },
            state: checkList.overallBudgetCheck,
            name: "Over all Budget"
        },
        {
            check: {
                ...checkList,
                startDateCheck: !checkList.startDateCheck
            },
            state: checkList.startDateCheck,
            name: "Start Date & Time"
        },
        {
            check: {
                ...checkList,
                endDateCheck: !checkList.endDateCheck
            },
            state: checkList.endDateCheck,
            name: "Ending Date & Time"
        },
        {
            check: {
                ...checkList,
                lastSignificantCheck: !checkList.lastSignificantCheck
            },
            state: checkList.lastSignificantCheck,
            name: "Last significant edit"
        }
    ]
    const [selectedDate, setSelectedDate] = useState(null);
    const Cardheader = () => {

        return (
            // <div className="profile-menu d-flex justify-content-between align-item-center row">
            <Row>
                <Col>

                    <div className='d-flex align-items-center'>
                        <Button outline color='primary' className='font-weight-bold mr-1'>
                            <Download size={18} /> Export </Button>
                    </div>
                    <div className='d-flex justify-content-between ' style={{ position: 'relative', marginLeft: '108px', marginTop: '-39px' }}>
                        <Input type='search' style={{ paddingLeft: '25px' }} size='58' className='' Placeholder='Search here...' />
                        <div style={{ position: 'absolute', top: '6px', marginLeft: '5px' }}>
                            <Search size={15} />
                        </div>
                        &nbsp;&nbsp;
                        {/* <div>
                        <button className='form-control' style={{backgroundColor:'rgba(129, 193, 75, 1)', color:'white',border:'none'}}>Search</button>
                    </div> */}
                    </div>
                </Col>
                <Col>

                    <div className='d-flex align-items-center justify-content-end'>
                        {/* <DateRangePicker
                            calendarAriaLabel="Toggle calendar"
                            clearAriaLabel="Clear value"
                            dayAriaLabel="Day"
                            monthAriaLabel="Month"
                            nativeInputAriaLabel="Date"
                            onChange={onChange}
                            value={value}
                            yearAriaLabel="Year"
                        /> */}
                        {/* <input type="date" name="" id="" placeholder='dd/mm/yyyy' className='form-control w-50' />&nbsp;&nbsp; */}
                        <div>
                        <DatePicker 
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                            placeholderText="dd/mm/yyyy"
                            className="form-control"
                        />
                        </div>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="transparent" className="ml-1 p-0" >
                                <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                    <Grid size={22} color='#81C14B' />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className='scrollForAdmanagerFilter dropedownForFilter runningdroplist mt-2' right style={{ position: 'absolute', willChange: 'transform', top: '0rem', left: '-40px', transform: "translate3d(-132px, 36px, 0px)" }}>
                                {checkListArr.map((value, i) => {
                                    return (
                                        <DropdownItem className='w-100' onClick={() => setCheckList(value.check)} >
                                            <CustomInput type="checkbox" className='customcheckboxForFilter' id={`group-checkbox-${i}`} label={value.name} style={{ cursor: "pointer" }} checked={value.state} />
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                </Col>
            </Row >
            // </div>
        )
    }

    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }

    const CustomPagination = () => {

        const count = Number(Math.ceil(totalItems / rowsPerPage))

        return (
            <div className='d-flex justify-content-between mt-2'>
                <div className="d-flex align-items-center w-20">
                    <Label for='row-per-page' style={{ fontSize: '13px' }}>Row per page</Label>
                    <CustomInput
                        className="form-control mx-50 px-1.5 ml-1 w-50"
                        style={{ backgroundColor: '#E5F0E0' }}
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={onChangeRowsPerValue}
                    >
                        <option value='5'>5 Row</option>
                        <option value='10'>10 Row</option>
                        <option value='25'>25 Row</option>
                        <option value='50'>50 Row</option>
                        <option value='100'>100 Row</option>
                    </CustomInput>
                </div>
                {/* <ReactPaginate
                    previousLabel={<ArrowLeft color='#4D4D4D' size={15} />}
                    nextLabel={<ArrowRight color='#4D4D4D' size={15} />}
                    pageCount={count}
                    activeClassName='active'
                    forcePage={currentPage}
                    onPageChange={page => {
                        setCurrentPage(page.selected)
                        setRender(!render)
                    }}
                    pageClassName='page-item'
                    nextClassName='page-item next'
                    nextLinkClassName='page-link'
                    previousClassName='page-item prev'
                    previousLinkClassName='page-link'
                    pageLinkClassName='page-link'
                    containerClassName="pagination react-paginate justify-content-end my-2 pr-1"
                /> */}
                <Stack>
                    <Pagination count={10} className='PaginationCount'>
                    </Pagination>
                </Stack>
            </div>
        )
    }


    useEffect(() => {
        // getPostfeedLikedetails()
    }, [render])

    return (
        <div className='my-2'>
            <Card style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px' }}>
                <CardHeader className='fontForSubCardheading p-3'>
                    <h3> Analytics Table</h3>
                </CardHeader>
                <CardBody>
                    <div className='mb-2'>
                        <DeletedModal />
                        <Cardheader />
                    </div>
                    {
                        selectedRows.length !== 0 &&
                        <div style={{ backgroundColor: '#E3F2FD' }} className='d-flex justify-content-between align-items-center p-2 mb-1'>
                            <h4> {selectedRows.length} item selected</h4>
                            <Button className='btn-icon' color='secondary' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                                <Trash2 color='red' size={16} />
                            </Button>
                        </div>
                    }
                    <Row>
                        <Col>
                            <DataTable
                                className='react-dataTable'
                                noHeader
                                responsive
                                // selectableRows
                                paginationServer
                                selectableRowsHighlight
                                highlightOnHover
                                pointerOnHover
                                onSelectedRowsChange={handleChange}
                                sortIcon={<ChevronDown />}
                                paginationComponent={CustomPagination}
                                columns={columns}
                                data={tabledata}
                                customStyles={styleTable}
                            // progressPending={loading}
                            />
                        </Col>
                    </Row>
                    <CustomPagination />

                </CardBody>
            </Card>
        </div>
    );
}