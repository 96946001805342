import { Checkbox } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { Circle, Filter } from 'react-feather';
import UserImg from '../../../NewComponents/IMG/img.svg';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody,  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import {getUserBizAndGroupsData} from '../../../Services/Actions/MarketPlace/getAllProductsAction'


const MpShareModal = ({ forwardOpen, toggleForward, peopleListData, setPeopleListPageIndex ,setSearchKeyValue}) => {
  const Sharedto = [
    {
      name: "Your Wall Feed"
    },
    // {
    //     name: "Your Story"  
    // }
  ];

  const navigate = useNavigate()
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));



   // =====>>> handling api with scroll  of the people list <<======
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
        setPeopleListPageIndex(previousValue => previousValue + 1);
    }
  };




  //=======>> set debounce here for serachkey <<=======
  const [searchKey, setSearchKey] = useState("");
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
        setSearchKeyValue(value); 
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  };
      //calling debouce value after a certain time
   useDebounce(searchKey, 300);
    //set value in use state of the input field
  const handleSearchChange = (e) => {
    setSearchKey(e.target.value); 
  };
  // ===============>>end<<================




  //=======>> checkbox select user  <<========
  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(prev => prev.filter(id => id !== userId));
    } else {
      setSelectedUsers(prev => [...prev, userId]);
    }
  };




  //=======>> getUserBusinessPages and Groups <<===========
  // const [userGroupData,setUserGroupData]=useState([])
  // const [userBizData,setUserBizData]=useState([])
  // const [bizAndGroupPageIndex,setBizAndGroupPageIndex] = useState(0)
  // useEffect(()=>{
  //   const fetchData =async()=>{
  //     const getUserBizGroupData= await getUserBizAndGroupsData(bizAndGroupPageIndex)
  //     if(getUserBizGroupData){
  //       setUserGroupData(prevData => [...prevData, ...getUserBizGroupData?.groupData])
  //       setUserBizData(prevData => [...prevData, ...getUserBizGroupData?.bizPageData])
  //     }
  //   }
  //   if(forwardOpen){ 
  //     fetchData(); //api will hit when toggle will open
  //   }
  // },[forwardOpen,bizAndGroupPageIndex])


   // =====>>> handling api with scroll  of the group and biz page <<======
  //  const handleScrollBizAndGroup = (e) => {
  //   const { scrollTop, scrollHeight, clientHeight } = e.target;
  //   if (scrollHeight - scrollTop === clientHeight) {
  //     setBizAndGroupPageIndex(previousValue => previousValue + 1);
  //   }
  // };



  //=======>> checkbox selected groups  <<========
    // const [selectedGroups, setSelectedGroups] = useState([]);
    // const handleGroupcheck = (e,groupId) => {
    //   if (selectedGroups.includes(groupId)) {
    //     setSelectedGroups(prev => prev.filter(id => id !== groupId));
    //   } else {
    //     setSelectedGroups(prev => [...prev, groupId]);
    //   }
    // };




    //=======>> checkbox selected bizPage  <<========
    // const [selectedBizPages, setSelectedBizPages] = useState([]);
    // const handleBizPages = (e,bizPageId) => {
    //   if (selectedBizPages.includes(bizPageId)) {
    //     setSelectedBizPages(prev => prev.filter(id => id !== bizPageId));
    //   } else {
    //     setSelectedBizPages(prev => [...prev, bizPageId]);
    //   }
    // };

   //  ====>> click on share to wall <<<==========
    const [isShareToWallFeed,setIsShareToWallFeed] = useState(false)
    const handleWallfeed=(e)=>{
      setIsShareToWallFeed(prev => !prev);

    }

  //if user will click outside the modal all the values will be empty
  useEffect(()=>{
    if(!forwardOpen){
      // setSelectedGroups([])
      // setSelectedBizPages([])
      setSelectedUsers([])
      setIsShareToWallFeed(false)
    }
  },[forwardOpen])

//  ======>> share product all the userIds <<=========
  const handleShareClick = () => {
    // {
    //need to add the api for share the prodcut
    //send to user
    // also send data in group and biz page and socila feed

    // }
    // displaying dummy comfirmation box
    if(selectedUsers.length>0){
     sweetAlertConfirmation("Product Shared Successfully")
    }
  //after api hit these values will be empty
  //  setSelectedGroups([])
  //  setSelectedBizPages([])
   setSelectedUsers([])
   setIsShareToWallFeed(false)
    toggleForward();
  };

  const navigateUserProfile = (e,userId) =>{
    e.stopPropagation();
    let type = (userId !== user?.id) ? "viewer" : "creater";
    navigate(`/MyTimeline?userId=${userId}&type=${type}`)

} 




//   =================================>>>>> component jsx  <<<================================

  return (
    <div>
      {forwardOpen !== false && (
        <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered mpshare'>
          <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
          <h2>Share to</h2>
            {(selectedUsers.length > 0 || isShareToWallFeed) && (
                <Button color='primary' onClick={handleShareClick}>Share</Button>
            )}
          </div>
          <ModalBody onScroll={handleScroll}>
            <div className='pb-2'>
              <Accordion defaultActiveKey="0">
                <AccordionItem eventKey='1'>
                  <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                  <AccordionBody className='p-0'>
                    <Accordion defaultActiveKey="0">
                      <AccordionItem eventKey='1'>
                        <AccordionHeader>Feed</AccordionHeader>
                        <AccordionBody>
                          {Sharedto.map((data) => (
                            <div className='d-flex justify-content-between align-items-center my-2 Report_Section' key={data.name}>
                              <h4>{data?.name}</h4>
                              <Checkbox onChange={handleWallfeed} />
                            </div>
                          ))}
                        </AccordionBody>
                      </AccordionItem>
                      {/* <AccordionItem eventKey='2'>
                        <AccordionHeader>Your Business Pages</AccordionHeader>
                        <AccordionBody>
                          <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }} onScroll={handleScrollBizAndGroup}>
                            { userBizData?.length > 0 && userBizData?.map((bizPage, index) => (
                              <div 
                                key={index} 
                                className="group-member-img-cont-blk d-flex align-items-center justify-content-between" 
                                style={{ padding: '10px',borderBottom: '1px solid #e0e0e0' }} // Add padding, margin, and border bottom inline
                              >
                                <div className="ForWard_Img_categories d-flex align-items-center">
                                  <img 
                                    src={bizPage?.coverUrl} 
                                    alt="img" 
                                    className="img-fluid rounded-circle" 
                                    style={{ width: '50px', height: '50px' }} // Set the desired width, height, and margin right here
                                  />
                                  <div className="ml-1">
                                    <h4>{bizPage?.name}</h4>
                                  </div>
                                </div>
                                <Checkbox onChange={(e)=>handleBizPages(e,bizPage?.id)}/>
                              </div>
                            ))}
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem eventKey='3'>
                        <AccordionHeader>Your Groups</AccordionHeader>
                        <AccordionBody>
                          <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }} onScroll={handleScrollBizAndGroup}>
                            {userGroupData.length >0 && userGroupData.map((group, index) => (
                              <div 
                                key={index} 
                                className="group-member-img-cont-blk d-flex align-items-center justify-content-between" 
                                style={{ padding: '10px',borderBottom: '1px solid #e0e0e0' }} // Add padding, margin, and border bottom inline
                              >
                                <div className="ForWard_Img_categories d-flex align-items-center">
                                  <img 
                                    src={group?.coverUrl} 
                                    alt="img" 
                                    className="img-fluid rounded-circle" 
                                    style={{ width: '50px', height: '50px' }} // Set the desired width, height, and margin right here
                                  />
                                  <div className="ml-1">
                                    <h4>{group?.name}</h4>
                                  </div>
                                </div>
                                <Checkbox onChange={(e)=>handleGroupcheck(e,group?.id)} />
                              </div>
                            ))}
                          </div>
                        </AccordionBody>
                      </AccordionItem> */}


                    </Accordion>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="search-block-podcast mt-2">
              <div className="input-search-blk">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" onChange={(e)=>handleSearchChange(e) } />
              </div>
            </div>
            <div className='mt-3 Categories_subHeading_section'>
              <h4>Connections</h4>
              {/* ==============commneted filter button =========== */}
              <UncontrolledButtonDropdown>
                <DropdownToggle color='primary' outline>
                  <Filter size={20} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag='a'> Followers </DropdownItem>
                  <DropdownItem tag='a'> Followers </DropdownItem>
                  <DropdownItem tag='a'> Sociomates </DropdownItem>

                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className='mt-3'>
              {peopleListData.length > 0 && peopleListData.map((data) => (
                <div className="group-detail-page-blk" key={data.id}>
                  <div className="MyEvents-memberslist-block">
                    <div className="group-member-img-cont-blk" onClick={(e)=>{navigateUserProfile(e,data?.id)}}  style = {{cursor :"pointer"}}>
                      <div className='ForWard_Img_categories'>
                        <img src={data?.profilePhoto} 
                          onError={(e) => e.target.src = 'https://sociomee.s3.eu-central-1.amazonaws.com/logo/aySkcPjXkEtK2.png'} 
                          className='img-fluid rounded-circle' />
                        <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                      </div>
                      <div className="member-cont-blk" >
                        <h4>{data?.fullName}</h4>
                        <p>{data?.fullName}</p>
                      </div>
                    </div>
                    <Checkbox onChange={() => handleCheckboxChange(data?.id)}/>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default MpShareModal;
