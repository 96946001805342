import React, { Fragment,useState } from "react";
import { Input } from "reactstrap";

const MpFilterValueInput = (props) => {


   

    const handleKeyPress = (event) => {
        // Allow only numeric characters (0-9) and backspace
        if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    return (
        <div className="MpFilterValueInput d-flex align-items-center justify-content-between p-2 border " style={{ borderRadius: "5px" }}>
            <Input placeholder={props.value}className="border-right MpFilterValueInput_first"   pattern="[0-9]*" // Add this pattern to allow only numeric input
                                            inputMode="numeric"
                                            onChange={handleKeyPress}    type="text"    maxLength={2} value={props.value}/>
            <Input type="select" className="MpFilterValueInput_Two">
                {props.options.map((op) => (
                    <option >{op}</option>
                ))}

            </Input>
        </div>
    )
}

export default MpFilterValueInput;