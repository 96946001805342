import React from 'react'
import { Fragment } from 'react'
import { Edit2, EyeOff, Link2, MoreVertical, Navigation, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Input } from 'reactstrap';
// import Like from '../../../profile-components/Img/Like.svg'
import Snd from "../../../profile-components/Img/snd.png"


let gallerySettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const MediaPreviewModal = () => {
    return (
        <Fragment>
            <div className="modal comment-model" id="imageModel" tabIndex="-1" role="dialog"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div>
                            <div className="image-gallery fgdf">
                                <div className="row m-0">
                                    <div className="col-xl-9 col-lg-8 p-0" style={{background: 'rgba(0,0,0,0.8)'}}>
                                        <Link to="#" data-bs-dismiss="modal"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-light close-btn"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></Link>
                                        <Slider {...gallerySettings} className="default-space">
                                            <div>
                                                <div className="img-part" >
                                                    <img src="/assets/images/post-6.jpg" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="img-part">
                                                    <img src="/assets/images/post-7.jpg" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="img-part">
                                                    <img src="/assets/images/post-8.jpg" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 p-0">
                                        <div className="comment-part">
                                            <div className="user-detail">
                                                <div className="user-media">
                                                    <div className="media">
                                                        <Link className="user-img">
                                                            <img src="/assets/images/story-3.jpg" className="img-fluid bg-img" alt="user" />
                                                            <span className="available-stats"></span>
                                                        </Link>
                                                        <div className="media-body">
                                                            <h5 className="user-name">Paige Turner</h5>
                                                            <h6>alabma, USA</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <div data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                            <MoreVertical color='#4B4B4B' size={20} />
                                                        </div>
                                                        <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                                            <ul>
                                                                <li>
                                                                    <Link to="#"><EyeOff size={15} color="#979797" />Hide post from timeline</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Link2 size={15} color="#979797" />Copy link </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Edit2 size={15} color="#979797" />Edit Post</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Trash2 size={15} color="#979797" />Delete</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Navigation size={15} color="#979797" />Share</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-panel mb-0">
                                                <div className="post-wrapper">
                                                    <div className="post-details">
                                                        <div className="like-panel">
                                                            <div className="left-emoji">
                                                                <ul>
                                                                    <li>
                                                                        <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                                    </li>
                                                                </ul>
                                                                <h6>+75</h6>
                                                            </div>
                                                            <div className="right-stats">
                                                                <ul >
                                                                    <li>
                                                                        <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                                    </li>
                                                                    <li><span className='d-flex align-items-center'>985  &nbsp;<p className='text-muted'>Share</p></span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="post-react">
                                                            <ul>
                                                                <li >
                                                                    <Link to="#">
                                                                        <div className="post-btn-cust"><img src="/assets/images/like1.png" alt="like" /></div> Like
                                                                    </Link>
                                                                </li>
                                                                <li >
                                                                    <Link to="#">
                                                                        <div className="post-btn-cust"><img src="/assets/images/comment1.png" /></div> Comment
                                                                    </Link>
                                                                </li>
                                                                <li >
                                                                    <Link to="#">
                                                                        <div className="post-btn-cust "><img src="/assets/images/share.png" /></div> share
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="comment-section">
                                                            <div className="comments d-block">
                                                                <div className="main-comment">
                                                                    <div className="media">
                                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                        <div className="media-body ml-2">
                                                                            <h4>Pabelo Mukrani</h4>
                                                                            <p className='text-muted'>50 mins ago</p>
                                                                            <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                            </h5>
                                                                            <ul className="comment-option">
                                                                                <li><Link to="#">like (5)</Link></li>
                                                                                <li><Link to="#">reply</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="main-comment">
                                                                    <div className="media">
                                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                        <div className="media-body ml-2">
                                                                            <h4>Pabelo Mukrani</h4>
                                                                            <p className='text-muted'>50 mins ago</p>
                                                                            <h5 className='py-2 profile-comments text-wrap'>As there is no companion loyal and loving than a dog, we are more than elated to present a awesome collection of happy birthday wishes for dogs with pictures. If you go back in time, you will find that dogs have always been men’s best friend and a watchful protector also. From hunting to surveillance, a dog stands by side of its owner unflinchingly and follows him like a shadow everywhere.    &#128578;
                                                                            </h5>
                                                                            <ul className="comment-option">
                                                                                <li><Link to="#">like (5)</Link></li>
                                                                                <li><Link to="#">reply</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="reply">
                                                                <div className="search-input input-style input-lg icon-right">
                                                                    <Input type="text" className="form-control emojiPicker"
                                                                        placeholder="Comment...." />
                                                                    <Link to="#">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                                    </Link>
                                                                    <div className='Media_Modal_send_Icon'>
                                                                        <img src={Snd} width={14} height={14} alt="snd" />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='Reply_Comment_section '>
                                                                <Input
                                                                    placeholder="Comment...."
                                                                    className="w-100"
                                                                    type="text"
                                                                />
                                                                <div className='Media_Modal_send_Icon'>
                                                                    <Send size={15} color="FFFFFF" />
                                                                </div>

                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default MediaPreviewModal