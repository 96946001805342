import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { ChevronLeft, ChevronRight, Search } from 'react-feather'
import { CardText, Col, Input, InputGroup, InputGroupText, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import Emoji from '../../../../NewComponents/IMG/Emoji.svg'


const StickerSection = () => {

    const [active, setActive] = useState('1')
    const [Stickeractive, setStickerActive] = useState(false)

    const top100Films = [
        { title: '😘', year: 'Happy' },
        { title: '😂', year: 'Smile' }
    ]

    const toggle = tab => {
        setActive(tab)
    }

    return <div>
        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        className={active === '1' ? 'text-primary' : ''}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Feeling
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        className={active === '2' ? 'text-primary' : ''}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Activity
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>
                    <div className='p-2'>
                        <CardText tag='h4' style={{ cursor: 'pointer' }}>How are you doing?</CardText>
                        <Autocomplete
                            id="free-solo-demo"
                            className='border mt-1'
                            freeSolo
                            options={top100Films.map((option) => option.title + option.year)}
                            renderInput={(params) => <TextField {...params} placeholder="Search" variant="outlined" />}
                        />
                    </div>
                    <div className='p-2 StickerForslider'>
                        <Row>
                            <Col className='on-hover-bg p-2'>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                    <img src={Emoji} />
                                    <p className='ml-2'>Happy</p>
                                </div>
                            </Col>
                            <Col className='on-hover-bg p-2'>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                    <img src={Emoji} />
                                    <p className='ml-2'>Sad</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col className='on-hover-bg p-2'>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                    <img src={Emoji} />
                                    <p className='ml-2'>Happy</p>
                                </div>
                            </Col>
                            <Col className='on-hover-bg p-2'>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                    <img src={Emoji} />
                                    <p className='ml-2'>Sad</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='on-hover-bg p-2'>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                    <img src={Emoji} />
                                    <p className='ml-2'>Happy</p>
                                </div>
                            </Col>
                            <Col className='on-hover-bg p-2'>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                    <img src={Emoji} />
                                    <p className='ml-2'>Sad</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tabId='2'>

                    {/* ------Header------ */}

                    <div className='p-2'>
                        <CardText tag='h4'>How are you doing?</CardText>
                        <InputGroup className='my-2'>
                            <InputGroupText className='bg-light'>
                                <Search />
                            </InputGroupText>
                            <Input type='search' className='bg-light' placeholder="Search" />
                        </InputGroup>
                    </div>

                    {/* Category and subCategory handeled by condition */}

                    <div className='StickerForslider' >
                        {
                            Stickeractive ?
                                <div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => setStickerActive(false)} className='my-2'>
                                        <ChevronLeft color='#979797' size={20} />
                                        Back
                                    </div>
                                    <Row>
                                        <Col className='my-2 on-hover-bg p-3' style={{ cursor: 'pointer' }}>
                                            <div className='d-flex align-items-center'>
                                                <img src={Emoji}  alt=''/>
                                                <p className='ml-2'>Happy</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='my-2 on-hover-bg p-3' style={{ cursor: 'pointer' }}>
                                            <div className='d-flex align-items-center'>
                                                <img src={Emoji} alt=''/>
                                                <p className='ml-2'>Happy</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> :
                                <div>
                                    <Row>
                                        <Col className='my-2 on-hover-bg p-3'>
                                            <div style={{ cursor: 'pointer' }} onClick={() => setStickerActive(true)} className='d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                                    <img src={Emoji} alt='' />
                                                    <p className='ml-2'>Celibrating</p>
                                                </div>
                                                <ChevronRight color='#979797' size={20} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                        }
                    </div>
                </TabPane>
            </TabContent>
        </React.Fragment>
    </div>
}

export default StickerSection