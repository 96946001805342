import { Avatar, CardContent, CardMedia, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { Bookmark, Globe, Link2, Lock, MoreHorizontal, MoreVertical, Share2, XCircle } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import { Button, Card, CardBody, CardImg, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import AvatarGroup from '../../../NewComponents/AvatarGroup'
import Img from "../Img/IMG.png"
import Img1 from "../Img/IMG1.png"
import Img2 from "../Img/IMG2.png"
import Img3 from "../Img/IMG3.png"
import Img4 from "../Img/IMG4.png"
import Request from "../Img/Request.png"
import Request1 from "../Img/Request.png"
import Media from "../Img/cardMedia.png"
import { getAllPostRequestAction, loadAllSuggestedGroup, loadAllInvitationGroup } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { useDispatch, useSelector } from 'react-redux'
import GroupPropsAvatar from './GroupPropsAvatar'

let ownedGroupSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        }
    ]
}

const MyPostRequest = () => {
    const params = useParams()
    let dispatch = useDispatch();

    const { getAllPostRequest } = useSelector((state) => { return state.getPostRequestFromGroupData });
    const { getAllSuggestedGroup } = useSelector((state) => { return state.getAllGroupSuggestedGroupData });
    const { getGroupInvitation } = useSelector((state) => { return state.getAllGroupInvitationData });

    // console.log('POOOOOOOOOOOOOOOOOOOOOOOST REEEEEEEEEEEEEEQUEST', getAllPostRequest && getAllPostRequest?.data?.successResult?.rows?.length)
    // console.log('SUGGGGGGGGGGGGGGGGGGGGGGESTED', getAllSuggestedGroup)

    useEffect(() => {
        dispatch(loadAllInvitationGroup())
    }, [])

    useEffect(() => {
        dispatch(loadAllSuggestedGroup())
    }, [])

    useEffect(() => {
        dispatch(getAllPostRequestAction(params.id))
    }, [])
    const JoinRequestData = [
        {
            img: Request,
            name: "Kenjomen Norke",
            join: "Joined on 23 Mar 2021",
            mutual: "+5 mutual",
            tym: "2 h"
        },
        {
            img: Request1,
            name: "Kenjomen Norke",
            join: "Joined on 23 Mar 2021",
            mutual: "+5 mutual",
            tym: "3 h"
        }
    ]
    const PostDataFeed = [
        {
            postType: "media",
            img: Media,
            heading: "Celebration new album song launched",
            tag: "#Musiccelebration, #music, #party, #music ",
            trandingTag: "#Musiccelebration,",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text ever since the 1500s"
        },
        {
            postType: "Ntmedia",
            img: Media,
            heading: "Celebration new album song launched",
            tag: "#Musiccelebration, #music, #party, #music ",
            trandingTag: "#Musiccelebration,",
            content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text ever since the 1500s"
        }
    ]
    //================= Card_Info_Array ======================//
    const SuggestedDataInfo = [
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... "


        },

    ]
    //================= row_left_side_data ========================//
    const RowLeftDataPreview = () => (
        <Fragment>
            <div className='About_Gallery_Section'>
                <CardBody>
                    <CardTitle className='d-dlex justify-content-between Trending_Heading_section'>
                        <h4>Gallery</h4>
                        <p>See all →</p>
                    </CardTitle>
                    <Row className="mt-3">
                        <Col md="7 " className="mb-3">
                            <div className='d-flex'>
                                <CardImg src={Img} alt="img" />
                                <CardImg className="ml-2" src={Img1} alt="img" />
                            </div>
                            <div className='d-flex py-2 mt-2 '>
                                <CardImg src={Img2} alt="img" />
                                <CardImg className="ml-2" src={Img3} alt="img" />
                            </div>
                        </Col>
                        <Col md="5">
                            <div>
                                <CardImg src={Img4} alt="img" className="img-fluid imghight" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>

            </div>
            <div className='About_Gallery_Section'>
                <CardBody>
                    <CardTitle className='d-dlex justify-content-between Trending_Heading_section'>
                        <h4>Join Requests ({getGroupInvitation && getGroupInvitation?.data?.successResult?.data?.rows?.length})</h4>
                        <p>See all →</p>
                    </CardTitle>
                    {
                        getGroupInvitation && getGroupInvitation?.data?.successResult?.data?.rows.map((data, index) => {
                            if (index < 2)
                                return (
                                    <>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <div className='d-flex align-items-start' >
                                                <img style={{ width: '50px' }} src={data.profileImageThumb} className="img-fluid rounded-circle" alt="img" />
                                                <div className='ml-2 Join_Request_Profile'>
                                                    <h4>
                                                        {data.fullName.substring(0, 15)}
                                                        {data.fullName.length >= 15 && ' ...'}
                                                    </h4>
                                                    <p>
                                                        {data.createdAt.substring(0, 10)}
                                                    </p>
                                                    <div className="people-likes matual-friend-sec">
                                                    </div>
                                                    <div className="group-buttons-blk mt-2">
                                                        <Button color='primary'>Accept</Button>
                                                        <Button className=" ml-2" outline >Decline</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='Join_Request_Tym'>
                                                <h4>{data.tym}</h4>
                                            </div>
                                        </div>
                                        <hr className='text-muted' />
                                    </>

                                )
                        })
                    }

                </CardBody>
            </div>
            <div className='About_Gallery_Section'>
                <CardBody>
                    <CardTitle className='d-dlex justify-content-between Trending_Heading_section'>
                        <h4>Suggestions... ({getAllSuggestedGroup && getAllSuggestedGroup?.data?.successResult?.length})</h4>
                        <p>See all →</p>
                    </CardTitle>
                    <div className="podcast-list-block">
                        <Slider {...getAllSuggestedGroup && getAllSuggestedGroup?.data?.successResult} className="default-space">
                            {
                                getAllSuggestedGroup && getAllSuggestedGroup?.data?.successResult.map((data) => (
                                    <div>
                                        <Card className="group-slide-box">
                                            <div className="gallery-img-sublink">
                                                <XCircle color="#FFFFFF" size={21} />
                                            </div>
                                            <div className='gallery_menu_sublink'>
                                                <MoreVertical color="#FFFFFF" size={21} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                    <ul>
                                                        <li>
                                                            <Link to="#"><Share2 size={15} color="#4D4D4D" /> Share Group</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                        </li>
                                                        <li >
                                                            <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <img src={data.coverPicUrl} alt='CoverPic' className="img-fluid group-img" />
                                            <div className="group-content">
                                                <h4 className='text-capitalize'>{data.name}</h4>
                                                {data.privacy === "public" ?
                                                    <div className="Suggestmember-blk">
                                                        <span><Globe color='#4D4D4D' size={12} /> Public Group</span> • <span>{data?.totalMembers > 0 ? data?.totalMembers : 'No'} {data?.totalMembers > 1 ? 'Members' : ''} </span>
                                                    </div> :
                                                    <div className="Suggestmember-blk">
                                                            <span><Lock color='#4D4D4D' size={12} /> Private Group</span> • <span>{data?.totalMembers >0 ? data?.totalMembers : 'No'} {data?.totalMembers >1 ? 'Members' : 'Member'} </span>
                                                    </div>
                                                }
                                                <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                    <p>{data.paragraph}</p>
                                                </div>
                                                <div className="group-buttons-blk mt-3">
                                                    <Button className="Suggest_Join_Button" color='primary'>Join Group</Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))
                            }

                        </Slider>

                    </div>
                </CardBody>

            </div>
        </Fragment>
    )
     //================= row_right_side_data ========================//
    const RowRightDataPreview = () => (
        <Fragment>
            {
                PostDataFeed.map((data) => (
                    <div className='About_Gallery_Section'>
                        <Card>
                            {/* <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" src={Request} />

                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <UncontrolledButtonDropdown direction='start'>
                                            <DropdownToggle>
                                                <MoreHorizontal size={20} color="#6E6B7B" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem tag='a'>option 1</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>


                                    </IconButton>
                                }

                                title="Shrimp and Chorizo Paella"
                                subheader="September 14, 2016"
                            /> */}
                            <CardHeader className="Post_Card_header">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex align-items-center '>
                                        <img src={Request} alt="profile" className='img-fluid rounded-circle' width={58} height={58} />
                                        <div className='ml-2'>
                                            <h4>Sufiya Eliza</h4>
                                            <p>20 Min Ago</p>
                                        </div>
                                    </div>
                                    {/* <div className='d-flex justify-content-end'>
                                        <MoreHorizontal size={20} color="#6E6B7B" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                        <div className=" post_Request dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal w-25 ">
                                            <ul>
                                                <li>
                                                    <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </CardHeader>
                            {data.postType === "media" ?
                                <>
                                    <CardMedia
                                        component="img"
                                        height="407"
                                        image={data.img}
                                        alt="Paella dish"
                                    />
                                    <CardContent>
                                        <div className="group-buttons mt-3">
                                            <Button color='primary approved_Post_button'>Approve </Button>
                                            <Button className="approved_Post_button ml-2" outline >Decline </Button>
                                        </div>

                                    </CardContent>
                                </> :
                                <CardContent>
                                    <div className='post_heading_section'>
                                        <h4>{data.heading}</h4>
                                    </div>
                                    <div className='mt-3 d-flex Post_hasTag_section align-items-center '>
                                        <span>{data.trandingTag}</span> &nbsp;<p>{data.tag}</p>
                                    </div>
                                    <div className='Post_content_section mt-2'>
                                        <p>{data.content}</p>
                                    </div>
                                    <div className="group-buttons mt-4">
                                        <Button color='primary approved_Post_button'>Approve </Button>
                                        <Button className="approved_Post_button ml-2" outline >Decline </Button>
                                    </div>
                                </CardContent>
                            }


                        </Card>
                    </div>
                ))
            }


        </Fragment>
    )

    //=========================  main_return_function ===================//
    return (
        <Fragment>
            <div className="group-page-section section-b-space">
                <div className="group-page-title">
                    <Row>
                        <Col md="12">
                            <div className='Blocked_Member_Heading'>
                            <h4>Post Request ({getAllPostRequest && getAllPostRequest?.data?.successResult?.rows?.length})</h4>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="mt-2">
                <Col md="4">
                    <RowLeftDataPreview />
                </Col>
                <Col md="8">
                    <RowRightDataPreview />
                </Col>
            </Row>
        </Fragment>
    )
}

export default MyPostRequest