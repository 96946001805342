import React, { Fragment,useCallback,useMemo } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import LeftSidebar from "../LeftSidebar";
import RightSidebar from "../RightSidebar";
import ProfileMenu from "./ProfileMenu";
// import Models from '../Models';
import MediaModal from "./MediaModal";
import ProfileCover from "./ProfileCover";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledButtonDropdown,
  CustomInput
} from "reactstrap";
import {
  CheckCircle,
  ChevronDown,
  Download,
  Edit2,
  Eye,
  EyeOff,
  Globe,
  Link2,
  MessageCircle,
  MoreVertical,
  Navigation,
  Send,
  Sliders,
  Trash2,
} from "react-feather";
import { Col, Row } from "react-bootstrap";
import { Checkbox, Pagination, Stack } from "@mui/material";
import { useState } from "react";
import JSZip from "jszip";
// import FileSaver from 'file-saver';
import "./profileComponents.css";
import Like from "./Img/likesvg.svg";
// import FlwrImg from './Img/FlwrImg.png'
import Play from "./Img/play.png"
import {
  getTextPostsAction,
  hideAllPostsFromTimelineAction,
  getUserFollowerAction,
  deletePostAction,
  postShowAction,
  getAllCommentsByPostIdAction,
} from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect} from "react";
import { sweetAlertConfirmation } from "../group-components/AllGroupsSections/SweetAlert";
import { SweetErrorAlert } from "../group-components/AllGroupsSections/SweetErrorAlert";
import ShowHidePost from "./ShowHidePost";
import DeleteModal from "./DeleteModal";
import swal from "sweetalert";
import { data } from "jquery";
import CreationMainSection from '../../components/Social-feed/CreationMainSection';
import DiscaedModal from '../../components/Social-feed/DiscaedModal';
import debounce from 'lodash/debounce';
import axios from "axios";
import { useNavigate,useLocation } from 'react-router-dom';
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';

// import like from '../../NewComponents/IMG/like (3).png'

const MyMediaListView = () => {
  let dispatch = useDispatch();
  const navigate=useNavigate();
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  let [copyLink, setCopyLink] = useState("");
  let [btnDisable, setBtnDisable] = useState("disabled");
  let [isAllHidden, setIsAllHidden] = useState();
  const [apiRoute, setApiRoute] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [connectionName, setConnectionName] = useState(0);
  const [searchPosts, setSearchPosts] = useState("");
  const [commentId, setCommentId] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [hiddenOrNot,setHiddenOrNot]=useState(false);
  const [postsTypeMedia,setPostTypeMedia]=useState([]);
  const [postsTypeVideos,setPostsTypeVideo]=useState([]);
  const [allPosts,setAllPosts]=useState([]);
  const [searchPostsData,setSearchPostsData]=useState([]);
  const [deletePostId, setDeletePostId]=useState([]);
  const [mediaAllCount,setMediaAllCount]=useState(0);
  const [mediaAllCountError,setMediaAllCountError]=useState('');
  const [activeTab1, setActiveTab1] = useState('All');
  let countryIds=[];
  const [pageIndex1,setPageIndex1]=useState(0);
  const [pageSize1,setPageSize1]=useState(10);
  const [followerData,setFollowerData]=useState([]);
  const [followingData,setFollowingData]=useState([]);
  const [error,setError]=useState('');
  const [searchKey2,setSearchKey2]=useState('');
  const [count,setCount]=useState(0);
  const [deleteCompleted, setDeleteCompleted] = useState(false);
  const [friendsId, setFriendsId] = useState();
  const [postId, setPostId] = useState([]);
  const [caption, setCaption] = useState([]);
  const [connectionId, setConnectionId] = useState([]);
  const [allowComments, setAllowComments] = useState([]);
  const [locationLAT, setLocationLat] = useState([]);
  const [locationLONG, setLocationLong] = useState([]);

   const debouncedSetSearchKey = useCallback(
     debounce((value) => {
       setSearchKey(value);
      }, 300),
     [] 
   );
   const fetchTextPosts = useCallback(() => {
    dispatch(getTextPostsAction("media", pageSize, pageIndex, searchKey,userId));
  }, [dispatch, searchKey, pageSize, pageIndex,userId]);

  useEffect(() => {
    fetchTextPosts();
    if (deleteCompleted) {
      setDeleteCompleted(false);
    }
  }, [fetchTextPosts, deleteCompleted]);


const handleInputChange = (e) => {
    debouncedSetSearchKey(e.target.value);
};

useEffect(() => {
  const fetchData = async () => {
      try {
          let user = JSON.parse(localStorage.getItem('sociomeeUser'));
          if (user) {
              const response = await axios.post(
                  `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                  { postType:"media", pageSize:5, pageIndex:0, searchKey:'',userId:userId},
                  { headers: { Authorization: `Bearer ${user.token}` } }
              );
              setMediaAllCount(response.data.data.successResult.count);
          } else {
            setMediaAllCountError('no user found');
          }
      } catch (error) {
        setMediaAllCountError(error);
      }
  };

  fetchData();
}, []);

const TabSectionData = [
  {
    className: "nav-item nav-link active",
    id: "AllTab",
    link: "#nav-All",
    ariaControls: "all",
    ariaSelected: "true",
    TabName: `All (${allPosts?.length||0})`,
    activeTab1:"All"
  },
  {
    className: "nav-item nav-link",
    id: "ImageTab",
    link: "#nav-image",
    ariaControls: "nav-image",
    ariaSelected: "false",
    TabName: `Image (${postsTypeMedia?.length||0})`,
    activeTab1:"Image"
  },
  {
    className: "nav-item nav-link",
    id: "VideoTab",
    link: "#nav-video",
    ariaControls: "nav-video",
    ariaSelected: "false",
    TabName: `Video (${postsTypeVideos?.length||0})`,
    activeTab1:"Video"
  },
];

  const getAllPostsUser = useSelector((state) => {
    return state.getTextPostsData;
  });
  const {
    loading: loadingPosts,
    error: errorPosts,
    result: resultPosts,
  } = getAllPostsUser;
  
  const handlePageChange = async(event, value) => {
    setPageIndex(value-1);
  };  

  useEffect(() => {
    let allPostsFiltered, postsTypeMediaFiltered, postsTypeVideosFiltered, searchPostsDataFiltered;
  
    if (!hiddenOrNot) {
      allPostsFiltered = resultPosts?.filter(post =>
        post?.mediaList?.find(media => media?.mediaList !== null) && post.hidden === 0
      );
  
      postsTypeMediaFiltered = allPostsFiltered?.filter(post =>
        post.mediaList?.some(media => media.fileType === "image") && post.hidden === 0
      );
  
      postsTypeVideosFiltered = allPostsFiltered?.filter(post =>
        post.mediaList?.some(media => media.fileType === "video") && post.hidden === 0
      );
  
      searchPostsDataFiltered = allPostsFiltered?.filter(item =>
        item.caption?.toLowerCase().includes(searchPosts?.toLowerCase()) && item.hidden === 0
      );
    } else {
      allPostsFiltered = resultPosts?.filter(post =>
        post?.mediaList?.find(media => media?.mediaList !== null) && post.hidden === 1
      );
  
      postsTypeMediaFiltered = allPostsFiltered?.filter(post =>
        post.mediaList?.some(media => media.fileType === "image") && post.hidden === 1
      );
  
      postsTypeVideosFiltered = allPostsFiltered?.filter(post =>
        post.mediaList?.some(media => media.fileType === "video") && post.hidden === 1
      );
  
      searchPostsDataFiltered = allPostsFiltered?.filter(item =>
        item.caption?.toLowerCase().includes(searchPosts?.toLowerCase()) && item.hidden === 1
      );
    }
  
    setAllPosts(allPostsFiltered);
    setPostTypeMedia(postsTypeMediaFiltered);
    setPostsTypeVideo(postsTypeVideosFiltered);
    setSearchPostsData(searchPostsDataFiltered);
  }, [resultPosts, hiddenOrNot, searchPosts,userId]);
  

  const allPOstsIconChange = allPosts?.filter((post) => post?.hidden === 1);

  const getAllCommentsByUserId = useSelector((state) => {
    return state.getAllCommentsByPostIdData;
  });
  const {
    loading: loadingAllComments,
    error: errorAllComments,
    result: AllComments,
  } = getAllCommentsByUserId;

  useEffect(() => {
    const allPosts = resultPosts?.filter((post) =>
      post?.mediaList?.find((media) => media?.mediaList !== null)
    );
    if (allPOstsIconChange?.length === allPosts?.length) {
      setIsAllHidden(true);
    } else {
      setIsAllHidden(false);
    }
  }, [allPosts, allPOstsIconChange]);

  const [selectedImages1, setSelectedImages1] = useState({});

  const handleCheckboxToggle = (postId) => {
    setSelectedImages1(prevState => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
  };

  const getSelectedPostIds = () => {
    return Object.keys(selectedImages1).filter(postId => selectedImages1[postId]);
  };


  const postCopyLink = async (id) => {
    const newCopyLink = `${process.env.REACT_APP_IPURL}/MyMediaListView/${id}`;
    setCopyLink(newCopyLink);
    await navigator.clipboard.writeText(newCopyLink);
    sweetAlertConfirmation(`Link Copied`);
  };
  
  const [modalImage, setModalImage] = useState('');
  const [postIdToModal, setPostIdToModal] = useState('');

  const imageToModal = (data) => {
        setModalImage(data);
        setPostIdToModal(data.postId);
        dispatch(
          getAllCommentsByPostIdAction(
            data.postId,
            commentId,
            searchKey,
            pageIndex,
            pageSize,
            userId
          )
        );
  };

  //==============add video/photo=================

  const [activeTab, setActiveTab] = useState(1000);
    const states=activeTab;
    const [postType, setPostType] = useState("event");
    const [currState,setCurrState]=useState(0)
    const [modal, setModal]=useState('')
    const [selectedImages,setSelectedImages]=useState('')
    const [path, setPath] = useState("/modal/");
    const [discard, setDisCard] = useState(false);
    const [feelingCategory,setFeelingCategory]=useState("");


    // =================event ==================

    const toggleDiscard = () => {
        setDisCard((preState) => !preState);
      };
      const toggleAllDiscard = () => {
        setModal(false);
        toggleDiscard(false);
    
      };

    const toggle = (path) => {

        console.log(path.indexOf("/modal/"),"   path.indexof(`modal`)")
        if (path.indexOf("/modal/" > -1)) {
          if (modal) {
            setActiveTab(parseFloat(path.replace("/modal/", "")));
        
            setPath(path);
           
            const index = parseFloat(path.replace("/modal/", ""));
          // console.log("in toggle function",index)
            const postType ="event"
           
          } else {
          console.log("in toglle mode elese sections")
          const index = parseFloat(path.replace("/modal/", ""));
          
          
            setActiveTab(parseFloat(path.replace("/modal/", "")));
            setModal(true);
            setPath(path);
          
          }
        } else {
    
          setModal(false);
        }
      };


  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isSelectActive, setIsSelectActive] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOpen1, setDeleteOpen1] = useState(false);
  // const [deletepostId,setDeletePostId]=useState([]);

  

  const toggleDeleteOpen = (e) => {
    e.stopPropagation();
    setDeleteOpen1((prevState) => !prevState);
    // setBtnDisable("disabled");
};

const toggleDeleteOpen1 = (e) => {
  // setDeletePostId(id);
  e.stopPropagation();
  setDeleteOpen((prevState) => !prevState);
  setBtnDisable("disabled");
  setFriendsId("");
  setDeletePostId([]);
};

const toggleDeleteOpen2 = (e,id) => {
  setDeletePostId(id);
  e.stopPropagation();
  setDeleteOpen((prevState) => !prevState);
  setBtnDisable("disabled");
  setFriendsId("");
  // setDeletePostId([]);
};

const deleteAllPost1 = (e) => {
  e.stopPropagation();
  dispatch(deletePostAction(getSelectedPostIds()));
  setDeleteOpen1((prevState) => !prevState);
  setDeletePostId([])
  setIsAllHidden(false);
    setBtnDisable("disabled");
    setIsSelectActive(!isSelectActive);
    setConnectionName(false);
    setSearchPosts("");
    setFriendsId("");
  sweetAlertConfirmation('Successfully deleted')
};

  const deleteAllPost = (e) => {
    e.stopPropagation();
    dispatch(deletePostAction([deletePostId])).then(() => {
      // Set deleteCompleted to true after the delete action is completed
      setDeleteCompleted(true);
    });

    setDeleteOpen((prevState) => !prevState);
    setDeletePostId([])
    sweetAlertConfirmation('Successfully deleted')
  };

  const editPost =()=>{
    SweetErrorAlert("edit functionality is in progress")
  }

  const [downloadOpen, setDownloadOpen] = useState(false);
  const toggleDownloadOpen = () => {
    setDownloadOpen((prevState) => !prevState);
  };

  const toggleShareOpen1 = (e) => {
    e.stopPropagation();
    setShareOpen((prevState) => !prevState);
};

const handleChange=(e)=>{
  setSearchKey2(e.target.value);
}


const downloadFiles = async () => {
  swal({
      title: "Good job!",
      text: "Once the download completes, you can find the file in your Downloads folder.",
      icon: "success",
      button: "OK",
      customClass: {
          container: "my-swal-container",
      },
  });

  setBtnDisable("disabled");

  const selectedPostIds = getSelectedPostIds();

  // Check if only one post is selected
  if (selectedPostIds.length === 1) {
      const postId = selectedPostIds[0];
      const post = allPosts.find(post => post.postId === postId);

      // Assuming only one media item will be downloaded if selectedPostIds.length is 1
      if (post && post.mediaList && post.mediaList.length === 1) {
          const media = post.mediaList[0];
          const fileUrl = media.fileURL;

          // Determine file extension
          const extension = fileUrl.substring(fileUrl.lastIndexOf('.') + 1).toLowerCase();

          // Download directly if it's an image (png, jpg) or video (mp3, mp4)
          if (['png', 'jpg'].includes(extension)) {
              downloadFile(fileUrl, `${postId}.${extension}`);
          } else if (['mp3', 'mp4'].includes(extension)) {
              downloadFile(fileUrl, `${postId}.${extension}`);
          } else {
              console.error(`Unsupported file format: ${extension}`);
              // Optionally handle or notify user about unsupported file format
          }
          return;
      }
  }

  // More than one post selected or no suitable single file found, proceed with ZIP download
  const fileUrls = allPosts
      .filter(post => selectedPostIds.includes(post.postId))
      .map(post => post.mediaList ? post.mediaList.map(media => media.fileURL) : [])
      .flat()
      .filter(url => url !== null);

  const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";
  const zip = new JSZip();

  try {
      // Fetch and add each file to the zip archive
      await Promise.all(fileUrls.map(async (url) => {
          try {
              const response = await fetch(corsProxyUrl + url);
              if (!response.ok) {
                  throw new Error(`Failed to fetch ${url}: ${response.status} ${response.statusText}`);
              }
              const blob = await response.blob();
              // Extract filename from the URL or use a sequential number
              const filename = url.substring(url.lastIndexOf('/') + 1);
              zip.file(filename, blob);
          } catch (err) {
              console.error(`Failed to fetch ${url}: `, err);
              // Optionally handle or log the error
          }
      }));

      // Generate the ZIP file after all files are added
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a URL for the blob data and initiate download
      const zipUrl = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = zipUrl;
      link.download = "media.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

  } catch (error) {
      console.error('Error downloading and zipping files:', error);
      // Optionally display an error message to the user
  }
};

// Function to download a single file
const downloadFile = async (url, filename) => {
  try {
    const corsProxy = 'https://cors-anywhere.herokuapp.com/';
    const proxiedUrl = corsProxy + url;

    const response = await fetch(proxiedUrl);
    const blob = await response.blob();

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    
    // Set the file name based on the URL
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    anchor.download = fileName;
    
    // Programmatically click the anchor element to trigger the download
    anchor.click();

    // Clean up resources
    window.URL.revokeObjectURL(anchor.href);
} catch (error) {
    console.error('Error downloading file:', error);
}
};

  const [shareOpen, setShareOpen] = useState(false);

  const circleCheckboxStyle = {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: '2px solid #ccc',
    appearance: 'none',
    WebkitAppearance: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#f2f2f2'
  };

  const checkedCircleStyle = {
    ...circleCheckboxStyle,
    backgroundColor: '#81c14b'
  };

  const sharePost = () => {
    sweetAlertConfirmation("post Shared successfully")
    setShareOpen((prevState) => !prevState);
  };

  const toggleShareOpen = (
    postId,
    caption,
    allowComments,
    locationLAT,
    locationLONG
  ) => {
    setFriendsId("");
    setApiRoute("/user/getUserFollowers");
    setSortBy("userName");
    dispatch(
      getUserFollowerAction(pageIndex, pageSize, sortBy,countryIds,userId)
    );
    setPostId(postId);
    setCaption(caption);
    setConnectionId(connectionId);
    setAllowComments(allowComments);
    setLocationLat(locationLAT);
    setLocationLong(locationLONG);
    setShareOpen((prevState) => !prevState);
  };

  const [shotzOpen, setShotzOpen] = useState(false);
  const toggleshotzOpen = () => {
    setShotzOpen((prevState) => !prevState);
    setBtnDisable("disabled");
  };

  const toggleshotzOpen1 = () => {

    setShotzOpen((prevState) => !prevState);
    setBtnDisable("disabled");
  };


  const shareAll = () => {
    sweetAlertConfirmation(`post shared`);
    setShareOpen((prevState) => !prevState);
  };

  const getConnectionName = (conName) => {
    // alert(conName)

    if(conName===1){
      setHiddenOrNot(true);
    }else{
      setHiddenOrNot(false);
    }
    
    setConnectionName(conName);
  };


  // ----------------/api call for hide post-----------
  const hideAllPostFromTimeLine = () => {
    dispatch(hideAllPostsFromTimelineAction(getSelectedPostIds()));
    setFriendsId("");
    setShotzOpen((prevState) => !prevState);
    sweetAlertConfirmation("Hided all selected posts from your timeline");
    setBtnDisable("disabled");
    setIsAllHidden(true);
  };

  const showAllPostFromTimeLine = () => {
    dispatch(postShowAction(getSelectedPostIds()));
  
    setFriendsId("");
    setShotzOpen((prevState) => !prevState);
    sweetAlertConfirmation("Show all posts to your timeline");
    setIsAllHidden(false);
    setBtnDisable("disabled");
  };
  const selectAll = (e) => {
    e.stopPropagation();
   
    const updatedSelectedImages = {};
    allPosts.forEach(item => {
      updatedSelectedImages[item.postId] = true;
    });
    setSelectedImages1(updatedSelectedImages);
    // setSelectAllStatus(true);
  };

  const unSelectAll = () => {
    const updatedSelectedImages = {};
    allPosts.forEach(item => {
      updatedSelectedImages[item.postId] = false;
    });
    setSelectedImages1(updatedSelectedImages);
  };

  const handleTabClick = (tabName) => {
    setActiveTab1(tabName);
  };

  const forSelectAll = () => {
    setIsAllHidden(false);
    setBtnDisable("disabled");
    setIsSelectActive((prevState)=>!prevState);
    setConnectionName(false);
    setSearchPosts("");
    setFriendsId("");
    setSelectedImages1({});
  };

  useEffect(() => {

    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                    { searchKey:searchKey2, pageIndex:pageIndex1, pageSize:pageSize1, sortBy:"userName" , countryIds:[],userId:userId },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setFollowerData(response.data.data.successResult.rows);
            } else {
                setError('no user found');
            }
        } catch (error) {
            setError(error.message);
        }
    };
    fetchData();
}, [searchKey2,pageIndex1,pageSize1,userId]);

useEffect(() => {
    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                    { searchKey:searchKey2, pageIndex:pageIndex1, pageSize:pageSize1, sortBy:"userName" , countryIds:[], userId },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setFollowingData(response.data.data.successResult.rows);
            } else {
                setError('no user found');
            }
        } catch (error) {
            setError(error.message);
        }
    };
    fetchData();
}, [searchKey2,pageIndex1,pageSize1,userId]);

const goToProfile = (e,data) => {
  e.stopPropagation();
  navigate(`/MyProfile?userId=${userId}?type=${type}`, { state: { data: data } });
};

const BasicsPagination = () => {
  return (
    <>
      <Stack>
        <Pagination 
          className="PaginationCounts"
          count={Math.ceil(mediaAllCount/12)} 
          page={pageIndex + 1}
          onChange={handlePageChange}    
        >
        </Pagination>
      </Stack>
    </>
  );
};

const RenderVideo = useMemo(() => 
  React.memo(({ fileURL, imageToModal, data }) => (
    <div 
      style={{
        position: 'relative',
        width: '100%',
        height: '245px',
        cursor: 'pointer'
      }}
      onClick={() => imageToModal(data)}
    >
      <video
       style={{ 
        width: '100%', 
        height: '100%', 
        objectFit: 'cover', // Ensures the video covers the entire div
        filter: 'brightness(0.5)', // Darkens the video
        position: 'absolute', // Ensures the video fits perfectly within the div
        top: 0,
        left: 0
      }}
        alt="noPic"
      >
        <source src={fileURL} type="video/mp4" alt="noPic" />
      </video>
      <div 
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
       <PlayArrowRounded
          sx={{ fontSize: '3rem' }}
          htmlColor="#ffffff"
          style={{background:"#51524cb2", borderRadius:"50%"}}
       />
      </div>
    </div>
  )), []

);

// console.log("Active======================================",isSelectActive)

  
  const AllTypMedia = () => (
    <Fragment>
      <div className="container-fluid section-t-space px-0">
        <div className="group-single-list-block">
            <>
              {!isSelectActive ? (
              <Row className="mt-3">
                {loadingPosts ? (
                  <div class="spinner-border m-5" role="status" style={{color:"81C14B"}}>
                    <span class="sr-only" style={{color:"81C14B"}}>Loading...</span>
                  </div>
                ) :  (
                  allPosts?.length>0?(
                  allPosts?.map((data, index) => (
                    <Col md="3" className="mb-4">                    
                        <div className="media-images-blk ">
                        <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                          {data?.mediaList?.slice(0, 1).map((res, index)  =>
                            res.fileURL.substr(-4) === ".mp4"  ? (
                              <RenderVideo key={index} fileURL={res.fileURL} imageToModal={imageToModal} data={data} />
                            ) : (
                              <img
                              onClick={() => {
                                imageToModal(data);
                              }}
                                className="gallery-img"
                                src={res?.fileURL}
                                alt="noPic"
                                loading="lazy"
                              />
                            )
                          )}
                        </a>
                        <div className="text-gallery">
                          <div className="top-gallery-cont">
                            <p>
                              <Eye size={16} /> {data.viewsCount}
                            </p>
                            <div className="gallery-img-sublink">
                              <MoreVertical
                                className="dropbtn-gal"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                size={20}
                              />
                              <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal media_option">
                                <ul>
                                  <li style={{ cursor: "pointer" }}>
                                    <ShowHidePost
                                      postId={data?.postId}
                                      hidden={data.hidden}
                                    />
                                  </li>

                                  <li
                                    onClick={() => {
                                      postCopyLink(data?.postId);
                                    }}
                                  >
                                    <Link to="#">
                                      <Link2 size={15} color="#979797" />{" "}
                                      &nbsp;Copy link
                                    </Link>
                                  </li>
                                  <li onClick={(e) => { editPost(); e.stopPropagation(); }}>
                                    <Link to="#">
                                      <Edit2 size={15} color="#979797" />
                                      &nbsp;Edit Post
                                    </Link>
                                  </li>
                                  <li onClick={(e) => toggleDeleteOpen2(e, data.postId)}>
                                    <Link to="#">
                                      <Trash2 size={15} color="#979797" />
                                      &nbsp;Delete
                                    </Link>
                                  </li>
                                  <li
                                    onClick={() =>
                                      toggleShareOpen(
                                        data.postId,
                                        data.caption,
                                        data.allowComments,
                                        data.locationLAT,
                                        data.locationLONG
                                      )
                                    }
                                  >
                                    <Link to="#">
                                      <Navigation size={15} color="#979797" />
                                      &nbsp;Share
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {data.typeof === "play" ? (
                            <>
                              <div className="center-gallery-cont">
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#imageModel"
                                >
                                  {data.play}
                                </Link>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="bottom-gallery-cont">
                            <p>
                              <img
                                src={Like}
                                width={10}
                                height={15}
                                alt="imge_like"
                              />{" "}
                              {data.liked}
                            </p>
                            <p>
                              <MessageCircle size={16} /> {data.commentsCount}
                            </p>
                            <p>
                              <Send size={16} /> {data.sharesCount}
                            </p>
                          </div>
                        </div>
                        </div>

                        </Col>
                  ))):
                (
                  <>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '15vh', // Adjust the height as needed to fill the available space
                    width: '100%' // Ensure it takes the full width of the container
                  }}>
                  <h2 style={{ textAlign: 'center' }}>No Media Posts</h2>
                </div>
                </>
              ))
              }
              </Row>
                      ):(
                        <Row className="mt-3">
                {loadingPosts ? (
                  <div class="spinner-border m-5" role="status" style={{color:"81C14B"}}>
                    <span class="sr-only" style={{color:"81C14B"}}>Loading...</span>
                  </div>
                ) : (
                  allPosts?.length>0?(
                  allPosts?.map((data, index) => (
                    <Col md="3" className="mb-4" onClick={() => handleCheckboxToggle(data.postId)}>
                        <div className="media-images-blk ">
                        <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                          {data?.mediaList?.slice(0, 1).map((res, index)  =>
                            res.fileURL.substr(-4) === ".mp4"  ? (
                              <RenderVideo key={index} fileURL={res.fileURL} imageToModal={imageToModal} data={data} />
                            ) : (
                              <img
                                className="gallery-img"
                                src={res?.fileURL}
                                alt="noPic"
                              />
                            )
                          )}
                        </a>
                        <div className="text-gallery">
                          <div className="top-gallery-cont">
                            <p>
                              <Eye size={16} /> {data.viewsCount}
                            </p>
                            
                            <div className='Block-btns-blk'>
                                {selectedImages1[data.postId] ? (
                                  <CheckCircle size={24} color="#81c14b" style={circleCheckboxStyle} />
                                ) : (
                                  <input
                                    type="checkbox"
                                    style={selectedImages1[data.postId] ? checkedCircleStyle : circleCheckboxStyle}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="bottom-gallery-cont">
                            <p>
                              <img
                                src={Like}
                                width={10}
                                height={15}
                                alt="imge_like"
                              />{" "}
                              {data.liked}
                            </p>
                            <p>
                              <MessageCircle size={16} /> {data.commentsCount}
                            </p>
                            <p>
                              <Send size={16} /> {data.sharesCount}
                            </p>
                          </div>
                        </div>
                        </div>
                     
                     
                    </Col>
                  ))):
                  (
                    <>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '15vh', // Adjust the height as needed to fill the available space
                      width: '100%' // Ensure it takes the full width of the container
                    }}>
                    <h2 style={{ textAlign: 'center' }}>No Media Posts</h2>
                  </div>
                  </>
                  ))
                }
              </Row>
               )}
              <hr />
              <br />
            </>
        </div>
      </div>
    </Fragment>
  );
  //Img_Section
  const ImgSection = () => (
    <Fragment>
      <div className="container-fluid section-t-space px-0">
        <div className="group-single-list-block">
          <Row className="mt-3">
            {loadingPosts ? (
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              postsTypeMedia?.length>0 ?(
                postsTypeMedia?.map((data, index) => (
                  <Col md="3" className="mb-4">
                    {!isSelectActive ? (
                    <div className="media-images-blk ">
                      <a data-bs-toggle="modal" data-bs-target="#imageModel">
                        {data?.mediaList?.slice(0, 1).map((res, index) => (
                          <img
                            onClick={() => {
                              imageToModal(data);
                            }}
                            className="gallery-img"
                            src={
                              res?.fileURL.substr(-5) === ".com/"
                                ? `${process.env.REACT_APP_IMAGEURL}test/bxlJIIWSZ2SOk.jpg`
                                : res?.fileURL
                            }
                            alt="noPic"
                          />
                        ))}
                      </a>
  
                      <div className="text-gallery">
                        <div className="top-gallery-cont">
                          <p>
                            <Eye size={16} /> {data.viewsCount}
                          </p>
                          <div className="gallery-img-sublink">
                            <MoreVertical
                              className="dropbtn-gal"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              size={20}
                            />
                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal media_option">
                              <ul>
                                <li style={{ cursor: "pointer" }}>
                                  <ShowHidePost
                                    postId={data?.postId}
                                    hidden={data.hidden}
                                  />
                                </li>
  
                                <li
                                  onClick={() => {
                                    postCopyLink(data?.postId);
                                  }}
                                >
                                  <Link to="#">
                                    <Link2 size={15} color="#979797" /> &nbsp;Copy
                                    link
                                  </Link>
                                </li>
                                <li onClick={(e) => { editPost(); e.stopPropagation(); }}>
                                  <Link to="#">
                                    <Edit2 size={15} color="#979797" />
                                    &nbsp;Edit Post
                                  </Link>
                                </li>
                                <li onClick={(e) => toggleDeleteOpen2(e, data.postId)}>
                                  <Link to="#">
                                    <Trash2 size={15} color="#979797" />
                                    &nbsp;Delete
                                  </Link>
                                </li>
                                <li
                                  onClick={() =>
                                    toggleShareOpen(
                                      data.postId,
                                      data.caption,
                                      data.allowComments,
                                      data.locationLAT,
                                      data.locationLONG
                                    )
                                  }
                                >
                                  <Link to="#">
                                    <Navigation size={15} color="#979797" />
                                    &nbsp;Share
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {data.typeof === "play" ? (
                          <>
                            <div className="center-gallery-cont">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#imageModel"
                              >
                                {data.play}
                              </Link>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="bottom-gallery-cont">
                          <p>
                            <img
                              src={Like}
                              width={10}
                              height={15}
                              alt="imge_like"
                            />{" "}
                            {data.liked}
                          </p>
                          <p>
                            <MessageCircle size={16} /> {data.commentsCount}
                          </p>
                          <p>
                            <Send size={16} /> {data.sharesCount}
                          </p>
                        </div>
                      </div>
                    </div>
                    ):(
                      <div className="media-images-blk " onClick={() => handleCheckboxToggle(data.postId)}>
                      <a data-bs-toggle="modal" data-bs-target="#imageModel">
                        {data?.mediaList?.slice(0, 1).map((res, index) => (
                          <img
                            className="gallery-img"
                            src={
                              res?.fileURL.substr(-5) === ".com/"
                                ? `${process.env.REACT_APP_IMAGEURL}test/bxlJIIWSZ2SOk.jpg`
                                : res?.fileURL
                            }
                            alt="noPic"
                          />
                        ))}
                      </a>
  
                      <div className="text-gallery">
                        <div className="top-gallery-cont">
                          <p>
                            <Eye size={16} /> {data.viewsCount}
                          </p>
                          {/* <div className="gallery-img-sublink">
                            <MoreVertical
                              className="dropbtn-gal"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              size={20}
                            />
                            
                          </div> */}
                              <div className='Block-btns-blk'>
                              {selectedImages1[data.postId] ? (
                                <CheckCircle size={24} color="#81c14b" style={circleCheckboxStyle} />
                              ) : (
                                <input
                                  type="checkbox"
                                  style={selectedImages[data.postId] ? checkedCircleStyle : circleCheckboxStyle}
                                />
                              )}
                             </div>
                        </div>
                        {data.typeof === "play" ? (
                          <>
                            <div className="center-gallery-cont">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#imageModel"
                              >
                                {data.play}
                              </Link>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="bottom-gallery-cont">
                          <p>
                            <img
                              src={Like}
                              width={10}
                              height={15}
                              alt="imge_like"
                            />{" "}
                            {data.liked}
                          </p>
                          <p>
                            <MessageCircle size={16} /> {data.commentsCount}
                          </p>
                          <p>
                            <Send size={16} /> {data.sharesCount}
                          </p>
                        </div>
                      </div>
                    </div>
                       )}
                  </Col>
                ))
              ):(
                <>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '15vh', // Adjust the height as needed to fill the available space
                  width: '100%' // Ensure it takes the full width of the container
                }}>
                <h2 style={{ textAlign: 'center' }}>No Media Posts</h2>
              </div>
              </>
              )
             
            )}
          </Row>
        </div>
      </div>
    </Fragment>
  );
  //VIDEO_card
  const VideoSection = () => (
    <Fragment>
      <div className="container-fluid section-t-space px-0">
        <div className="group-single-list-block">
          <Row className="mt-3">
            {loadingPosts ? (
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              postsTypeVideos?.length>0 ?(
                postsTypeVideos?.map((data, index) => (
                  <Col md="3" className="mb-4">
                    {!isSelectActive ? (
                    <div className="media-images-blk">
                      <a data-bs-toggle="modal" data-bs-target="#imageModel">
                        {data?.mediaList?.slice(0, 1).map((res, index) =>
                          res.fileURL.substr(-4) === ".mp4" ? (
                            <RenderVideo key={index} fileURL={res.fileURL} imageToModal={imageToModal} data={data} />
                          ) : (
                            <img
                              className="gallery-img"
                              src={res?.fileURL}
                              alt="noPic"
                            />
                          )
                        )}
                      </a>
  
                      <div className="text-gallery">
                        <div className="top-gallery-cont">
                          <p>
                            <Eye size={16} /> {data.viewsCount}
                          </p>
                          <div className="gallery-img-sublink">
                            <MoreVertical
                              className="dropbtn-gal"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              size={20}
                            />
                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal media_option">
                              <ul>
                                <li style={{ cursor: "pointer" }}>
                                  <ShowHidePost
                                    postId={data?.postId}
                                    hidden={data.hidden}
                                  />
                                </li>
  
                                <li
                                  onClick={() => {
                                    postCopyLink(data?.postId);
                                  }}
                                >
                                  <Link to="#">
                                    <Link2 size={15} color="#979797" /> &nbsp;Copy
                                    link
                                  </Link>
                                </li>
                                <li onClick={(e) => { editPost(); e.stopPropagation(); }}>
                                  <Link to="#">
                                    <Edit2 size={15} color="#979797" />
                                    &nbsp;Edit Post
                                  </Link>
                                </li>
                                <li onClick={(e) => toggleDeleteOpen2(e, data.postId)}>
                                  <Link to="#">
                                    <Trash2 size={15} color="#979797" />
                                    &nbsp;Delete
                                  </Link>
                                </li>
                                <li
                                  onClick={() =>
                                    toggleShareOpen(
                                      data.postId,
                                      data.caption,
                                      data.allowComments,
                                      data.locationLAT,
                                      data.locationLONG
                                    )
                                  }
                                >
                                  <Link to="#">
                                    <Navigation size={15} color="#979797" />
                                    &nbsp;Share
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {data.typeof === "play" ? (
                          <>
                            <div className="center-gallery-cont">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#imageModel"
                              >
                                {data.play}
                              </Link>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="bottom-gallery-cont">
                          <p>
                            <img
                              src={Like}
                              width={10}
                              height={15}
                              alt="imge_like"
                            />{" "}
                            {data.liked}
                          </p>
                          <p>
                            <MessageCircle size={16} /> {data.commentsCount}
                          </p>
                          <p>
                            <Send size={16} /> {data.sharesCount}
                          </p>
                        </div>
                      </div>
                    </div>):(
                      <div className="media-images-blk" onClick={() => handleCheckboxToggle(data.postId)}>
                      <a data-bs-toggle="modal" data-bs-target="#imageModel">
                        {data?.mediaList?.slice(0, 1).map((res, index) =>
                          res.fileURL.substr(-4) === ".mp4" ? (
                            <video
                              height="245"
                              width="100%"
                              controls
                              alt="noPic"
                            >
                              <source
                                src={
                                  res?.fileURL.substr(-5) === ".com/"
                                    ? `${process.env.REACT_APP_IMAGEURL}test/bxlJIIWSZ2SOk.jpg`
                                    : res?.fileURL
                                }
                                type="video/mp4"
                                alt="noPic"
                              />
                            </video>
                          ) : (
                            <img
                              className="gallery-img"
                              src={res?.fileURL}
                              alt="noPic"
                            />
                          )
                        )}
                      </a>
                      <div className="text-gallery">
                        <div className="top-gallery-cont">
                          <p>
                            <Eye size={16} /> {data.viewsCount}
                          </p>
                              <div className='Block-btns-blk'>
                              {selectedImages1[data.postId] ? (
                                <CheckCircle size={24} color="#81c14b" style={circleCheckboxStyle} />
                              ) : (
                                <input
                                  type="checkbox"
                                  style={selectedImages[data.postId] ? checkedCircleStyle : circleCheckboxStyle}
                                />
                              )}
                             </div>
                        </div>
                        {data.typeof === "play" ? (
                          <>
                            <div className="center-gallery-cont">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#imageModel"
                              >
                                {data.play}
                              </Link>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="bottom-gallery-cont">
                          <p>
                            <img
                              src={Like}
                              width={10}
                              height={15}
                              alt="imge_like"
                            />{" "}
                            {data.liked}
                          </p>
                          <p>
                            <MessageCircle size={16} /> {data.commentsCount}
                          </p>
                          <p>
                            <Send size={16} /> {data.sharesCount}
                          </p>
                        </div>
                      </div>
                    </div>
                    )}
                  </Col>
                ))
              ):(
                <>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '15vh', // Adjust the height as needed to fill the available space
                  width: '100%' // Ensure it takes the full width of the container
                }}>
                <h2 style={{ textAlign: 'center' }}>No Video Posts</h2>
              </div>
              </>
              )
              
            )}
          </Row>
        </div>
      </div>
    </Fragment>
  );
  // Tab_Section
  const SectionTab = () => (
    <Fragment>
      <nav>
        <div className="tab-section">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {TabSectionData.map((tabinfo) => (
              <li className="nav-item" key={tabinfo.id}>
                <Link
                  className={`nav-link ${activeTab1 === tabinfo.activeTab1 ? 'active' : ''}`}
                  id={tabinfo.id}
                  data-bs-toggle="tab"
                  to={tabinfo.link}
                  role="tab"
                  aria-controls={tabinfo.ariaControls}
                  aria-selected={activeTab1 === tabinfo.activeTab1 ? 'true' : 'false'}
                  onClick={() => handleTabClick(tabinfo.activeTab1)}
                >
                  {tabinfo.TabName}
                </Link>
              </li>
            ))}
          </ul>
          <div className="tab-content" id="nav-tabContent">
            {activeTab1 === 'All' && (
              <div
              
                className="tab-pane fade show active"
                id="nav-All"
                role="tabpanel"
                aria-labelledby="AllTab"
              >
                <AllTypMedia />
              </div>
            )}
            {activeTab1 === 'Image' && (
              <div
                className="tab-pane fade show active"
                id="nav-image"
                role="tabpanel"
                aria-labelledby="ImageTab"
              >
                <ImgSection />
              </div>
            )}
            {activeTab1 === 'Video' && (
              <div
                className="tab-pane fade show active"
                id="nav-video"
                role="tabpanel"
                aria-labelledby="VideoTab"
              >
                <VideoSection />
              </div>
            )}
          </div>
          <div className='d-flex align-items-center justify-content-center PaginationCounts'>
            <BasicsPagination/>
          </div>
        </div>
      </nav>
    </Fragment>
  );

  // main function
  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <ProfileMenu userId={userId} type={type} />
        <div className="container-fluid section-t-space px-0">
        <div className="page-content">
          <div className="content-center w-100">
            <div className="gallery-page-section section-b-space">
              <div className="card-title">
                <h3>Media ({mediaAllCount||0})</h3>
                <div className="right-setting ">
                  {!isSelectActive ? (
                    <>
                      <div className="profile-search-blk ">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="search-svg"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                        <input
                         type="text"
                         className="form-control"
                         name="search" placeholder="Find..."
                         value={searchKey}
                         onChange={(e) => { setSearchKey(e.target.value) }} />
                      </div>
                        {type !=="viewer" && allPosts?.length > 0 && (
                          <>
                        <UncontrolledButtonDropdown color="secondary" outline>
                        <DropdownToggle
                          outline
                          className="border border-muted ml-3 "
                        >
                          <Sliders className="Slider_Filter" size={12} />
                          &nbsp;&nbsp;Filter
                        </DropdownToggle>
                        <DropdownMenu className="DropDown_item">
                          <DropdownItem onClick={() => getConnectionName(0)}>
                            All visible posts
                          </DropdownItem>
                          <DropdownItem onClick={() => getConnectionName(1)}>
                            All hidden posts
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                       
                      <Link
                        to="#"
                        className="btn btn-outline-grey ml-3"
                        onClick={forSelectAll}
                      >
                        {" "}
                        Select
                      </Link>
                      </>
                     )}
                                           
                      <Button
                        onClick={() => {
                          setModal(true);
                          toggle("/modal/0.1");
                        }}
                        outline
                        color="primary"
                        className="ms-3"
                      >
                        Add Photo / Video
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="mr-4 d-flex">
                        {type !=="viewer" &&(
                          hiddenOrNot ? (
                            <button
                              className="btn btn-outline-grey ml-3 p-7"
                              onClick={toggleshotzOpen1}
                              disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                            >
                              <Eye size={20} />
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-grey ml-3 p-7"
                              onClick={toggleshotzOpen1}
                              disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                            >
                              <EyeOff size={20} />
                            </button>
                          )
                        )}
                          
                        {type !=="viewer" &&(
                        <button
                          className={`btn btn-outline-grey ml-3 p-7`}
                          disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                          onClick={toggleDeleteOpen}
                        >
                          {" "}
                          <Trash2 size={20} />
                        </button>
                        )}
                        <button
                          className={`btn btn-outline-grey ml-3 p-7`}
                          disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                          onClick={toggleShareOpen}
                        >
                          {" "}
                          <Send size={20} />
                        </button>
                        <button
                          className={`btn btn-outline-grey ml-3 p-7`}
                          disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                          onClick={downloadFiles}
                        >
                          {" "}
                          <Download size={20} />
                        </button>
                      </div>
                      <button
                        className="btn btn-outline-primary ml-3"
                        onClick={selectAll}
                      >
                        {" "}
                        Select All
                      </button>
                      <button
                        className="btn btn-outline-primary ml-3"
                        onClick={unSelectAll}
                      >
                        {" "}
                        Unselect All
                      </button>
                      <button
                        onClick={forSelectAll}
                        className="btn btn-outline-grey ml-3"
                      >
                        {" "}
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
              <SectionTab />
            </div>
            <div className="d-flex justify-content-center mb-3">
            </div>
          </div>
        </div>
         </div>
        </div>
        <div className="vertically-centered-modal">
          {shotzOpen !== false && (
            <Modal
              isOpen={shotzOpen}
              toggle={toggleshotzOpen}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center modalHeading_Section">
                <h4>Hide Media</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center  modalHeading_Section ">
                  {hiddenOrNot ? (
                    <h2 className="text-danger">
                      Show all {getSelectedPostIds().length} posts from the feed ?
                    </h2>
                  ) : (
                    <h2 className="text-danger">
                      Hide all {getSelectedPostIds().length} posts from the feed ?
                    </h2>
                  )}
                </div>

                <div className="d-flex justify-content-center  mt-3">
                  <Button onClick={toggleshotzOpen} outline>
                    Cancel
                  </Button>
                  {hiddenOrNot ? (
                    <Button
                      onClick={showAllPostFromTimeLine}
                      color="primary"
                      className="ml-3 "
                    >
                      Show all
                    </Button>
                  ) : (
                    <Button
                      onClick={hideAllPostFromTimeLine}
                      color="primary"
                      className="ml-3 "
                    >
                      Hide all
                    </Button>
                  )}
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
       
        <div className="vertically-centered-modal">
          {deleteOpen !== false && (
            <Modal
              isOpen={deleteOpen}
              toggle={toggleDeleteOpen1}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                <h4>Delete Media</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center modalHeading_Section">
                  <p>Do you really want to delete all the selected Media ?</p>
                </div>
                <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                  <small>if</small> YES! <small>please click on ok</small>
                </h1>
                <div className="d-flex justify-content-center  mt-4">
                  <Button onClick={toggleDeleteOpen1} outline>
                    Cancel
                  </Button>
                  <Button
                    onClick={(e)=>deleteAllPost(e)}
                    color="primary"
                    className="ml-3 "
                  >
                    OK
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}
          {deleteOpen1 !== false && (
            <Modal
              isOpen={deleteOpen1}
              toggle={toggleDeleteOpen}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                <h4>Delete Media</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center modalHeading_Section">
                  <p>Do you really want to delete all the selected Media ?</p>
                </div>
                <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                  <small>if</small> YES! <small>please click on ok</small>
                </h1>
                <div className="d-flex justify-content-center  mt-4">
                  <Button onClick={toggleDeleteOpen} outline>
                    Cancel
                  </Button>
                  <Button
                    onClick={(e)=>deleteAllPost1(e)}
                    color="primary"
                    className="ml-3 "
                  >
                    OK
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
        <div className="vertically-centered-modal">
          {downloadOpen !== false && (
            <Modal
              isOpen={downloadOpen}
              toggle={toggleDownloadOpen}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center modalHeading_Section">
                <h4>Download Media</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center  modalHeading_Section">
                  <p>Do you really want to download the selected Media ?</p>
                </div>
                <div className="d-flex justify-content-center  mt-4">
                  <Button onClick={toggleDownloadOpen} outline>
                    Cancel
                  </Button>
                  <Button
                    onClick={toggleDownloadOpen}
                    color="primary"
                    className="ml-3 "
                  >
                    OK
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
        <div className="vertically-centered-modal">
        {shareOpen !== false && (
            <Modal
              isOpen={shareOpen}
              toggle={toggleShareOpen1}
              className="modal-dialog-centered"
            >
              <div className="w-100 border-bottom">
                <div className=" mx-3 py-3  d-flex justify-content-between Heading_Invite_Section">
                  <h4>Share People</h4>
                  <span style={{ cursor: "pointer" }} onClick={sharePost}>
                  </span>
                </div>
              </div>
              <ModalBody>
                <Row className="mt-2">
                  <Col md="8" className="Like_search">
                    <div className="profile-search-blk w-100 ">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="search-svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Search..."
                        value={searchKey2}
                        data-bs-toggle="modal"
                        data-bs-target="#peopleSearch"
                        autoFocus
                        onChange={handleChange}
                        // value={searchTerm}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="d-flex justify-content-end">
                    <UncontrolledButtonDropdown color="secondary" outline>
                      <DropdownToggle
                        outline
                        className="border border-muted ml-3 "
                      >
                        <Sliders className="Slider_Filter" size={12} />
                        &nbsp;&nbsp;Filter
                      </DropdownToggle>
                      <DropdownMenu className="DropDown_Group_item">

                        {/* <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(1); }}>
                         SocioMate
                        </DropdownItem> */}
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(0); }}>
                         Followers
                        </DropdownItem>
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(2); }}>
                         Followings
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Row className="mt-3" scrollable>
                       {count == 0 && followerData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index}>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk" onClick={(e)=>goToProfile(e,data)}>
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                        {count == 2 && followingData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index} scrollable>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    </Row>
                  <Col
                    md="6"
                    className="d-flex justify-content-end SubHeading_Invite_Section"
                  >
                    <p style={{ cursor: "pointer" }} onClick={shareAll}>
                        Share all
                    </p>
                        </Col>
                        </Row>
                    </ModalBody>
                    </Modal>
                    )}
        </div>
        <RightSidebar />
        {!isSelectActive && (
        <MediaModal data={modalImage} AllComments={AllComments} userId={userId} followerData={followerData} followingData={followingData} />
        )}
        <CreationMainSection
            userId={userId}
                states={states}
                currState={currState}
                    setModal={setModal}
                    modal={modal}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    path={path}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    postType={postType}
                    setPostType={setPostType}
                    toggle={toggle}
                    toggleDiscard={toggleDiscard}
                    feelingCategories={feelingCategory}
                />
                <DiscaedModal
                    discard={discard}
                    toggleDiscard={toggleDiscard}
                    toggleAllDiscard={toggleAllDiscard}
                />
      </div>
    </>
  );
};

export default MyMediaListView;