import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const GroupAvatar = () => {

    const params = useParams()
    const position = params.index
    const { getGroupJoinedGroup } = useSelector((state) => {return state.getAllJoinedGroupData});

    return (
        <div>
            <ul className="matual-friend-blk">
                {
                   getGroupJoinedGroup && getGroupJoinedGroup?.data?.successResult?.rows[position]?.groupMembers?.map((item, index) => {
                    if(index <9)
                        return (
                            <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                data-name="sufiya eliza" data-img={item.profileImageThumb}>
                                <img src={item.profileImageThumb} className="img-fluid bg-img" alt="" />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default GroupAvatar