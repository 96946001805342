import BizOwnImg from '../Img/BizOwnImg.png'
import BizOwnImg1 from '../Img/BizOwnImg1.png'
import BizOwnImg2 from '../Img/BizOwnImg2.png'
import BizOwnImg3 from '../Img/BizOwnImg3.png'
import Post2 from '../Img/ReviewImg.png'
import Post from '../Img/Post.png'
import ReviewImg from '../Img/Post1.png'
import RevImg from '../Img/review.png'

export const BizAllData = [
    {
        img: BizOwnImg,
        typeof: "Pin",
        cardName: "Traditional Wedding ",
        category: "Music Equipment"
    },
    {
        img: BizOwnImg,
        typeof: "Pin",
        cardName: "Traditional Wedding",
        category: "Music Equipment"
    },
    {
        img: BizOwnImg2,
        typeof: "Pin",
        cardName: "Traditional Wedding ",
        category: "Music Equipment"
    },
    {
        img: BizOwnImg2,
        typeof: "Pin",
        cardName: "Traditional Wedding",
        category: "Music Equipment"
    },
    {
        img: BizOwnImg,
        typeof: "Pin",
        cardName: "Traditional Wedding ",
        category: "Music Equipment"
    }
]
export const FollowerData = [
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "SocioMate ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Followers",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Add Friend ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Follow",
        btnid: "Follow",

    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Add Friend ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "SocioMate ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Add Friend ",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    }
]

export const PhotoDataArray = [
    {
        img: Post,
        img1: ReviewImg,
        reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee , managers can really dive into individual performance, employee motivations, and goals.",
        value: 4
    },
    {
        img: Post2,
        img1: ReviewImg,
        reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee , managers can really dive into individual performance, employee motivations, and goals.",
        value: 3
    },
    {
        img: Post,
        img1: ReviewImg,
        reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee , managers can really dive into individual performance, employee motivations, and goals.",
        value: 2
    },
    {
        img: Post2,
        img1: ReviewImg,
        reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee , managers can really dive into individual performance, employee motivations, and goals.",
        value: 4

    },
    {
        img: Post,
        img1: ReviewImg,
        reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee , managers can really dive into individual performance, employee motivations, and goals.",
        value: 1
    },
    {
        img: Post2,
        img1: ReviewImg,
        reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee , managers can really dive into individual performance, employee motivations, and goals.",
        value: 5
    }

]