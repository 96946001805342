import { Autocomplete, Box, Checkbox, Chip, Stack, Switch, TextField } from '@mui/material'
import React, { useState, Fragment } from 'react'
import { CheckCircle, ChevronDown, Play, Upload, XCircle } from 'react-feather'
import { Button, Card, CardImg, CardImgOverlay, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown, InputGroupAddon } from 'reactstrap'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import userImg from '../../../NewComponents/IMG/reel4.png'
import Track from '../../profile-components/Img/Track.png'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Profession, Locations, names } from '../../Social-Feed-Component/FeedArray'

const UploadRecordedPodacast = () => {

  /*  ----------------------States and Function--------------------  */

  const [isOff2, setIsOff2] = useState(false);
  const [checkboxActive, setcheckboxActive] = useState(false);
  const [UploadModals, setUploadModals] = useState(false)
  const toggleModal = () => {
    setUploadModals((prevState) => !prevState)
  }

  const [hastag, setHastag] = useState(false)
  const togglehastag = () => {
    setHastag(!hastag)
  }

  const [checkIn, setCheckIn] = useState(false)
  const togglecheckIn = () => {
    setCheckIn(!checkIn)
  }

  const [PeopleTag, setPeopleTag] = useState(false)
  const togglePeopleTag = () => {
    setPeopleTag(!PeopleTag)
  }

  const [Scehedule, setScedule] = useState(false)
  const toggleScehedule = () => {
    setScedule(!Scehedule)
  }
  const [CommentOn, setCommentOff] = useState(false)
  const toggleComment = () => {
    setCommentOff(!CommentOn)
  }


  const [commentsActive, setCommentsActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  /*  ---------------------- Podecast Type Array --------------------  */

  const podacastType = [
    {
      type: 'Educations'
    },
    {
      type: 'Entertainment'
    },
    {
      type: 'Music'
    },
    {
      type: 'News Commentary'
    },
    {
      type: 'Culture'
    },
    {
      type: 'sport'
    }
  ]

  /*  ----------------------  MUI Slider style management --------------------  */

  const PrettoSlider = styled(Slider)({
    color: '#81C14B',
    height: 4,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

  /*-------------- Modal for Hastag -----------------*/

  const Hastag = () => {

    const [val, setVal] = useState([]);

    const valHtml = val.map((option, index) => {
      // This is to handle new options added by the user (allowed by freeSolo prop).
      const label = option.title || option;
      return (
        <Chip
          sx={{ margin: '1%' }}
          key={label}
          label={label}
          deleteIcon={<XCircle size={18} />}
          onDelete={() => {
            setVal(val.filter(entry => entry !== option));
          }}
        />
      );
    });
    return (
      <>
        {hastag ? (
          <Modal isOpen={hastag} className='modal-dialog-centered' toggle={togglehastag} >
            <ModalHeader toggle={togglehastag} className='d-flex justify-content-center'>
              <h4>Trending # tags</h4>
            </ModalHeader>
            <ModalBody>
              <Stack sx={{ width: '100%' }}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  freeSolo
                  filterSelectedOptions
                  options={Profession.map((option) => option)}
                  onChange={(e, newValue) => setVal(newValue)}
                  getOptionLabel={option => option}
                  renderTags={() => { }}
                  value={val}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className='border rounded'
                      // variant="outlined"
                      placeholder="#tags"
                    // margin="normal"
                    // fullWidth
                    />
                  )}
                />
                {valHtml.length > 0 ?
                  <div className="selectedTags border">{valHtml}</div> : null
                }
              </Stack>

              <div className='scrollForHastag'>
                <ul className='m-2'>
                  <li className='my-3'>
                    <div className='fontForSubTablheading'>
                      <h4>#NewDay</h4>
                      <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                  </li>
                  <li className='my-3'>
                    <div className='fontForSubTablheading'>
                      <h4>#NewMe</h4>
                      <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                  </li>
                  <li className='my-3'>
                    <div className='fontForSubTablheading'>
                      <h4>#NewMe</h4>
                      <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                  </li>
                  <li className='my-3'>
                    <div className='fontForSubTablheading'>
                      <h4>#NewMe</h4>
                      <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                  </li>
                  <li className='my-3'>
                    <div className='fontForSubTablheading'>
                      <h4>#NewMe</h4>
                      <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                  </li>
                </ul>
              </div>

            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for CheckIn -----------------*/

  const CheckIn = () => {


    return (
      <>
        {checkIn ? (
          <Modal isOpen={checkIn} className='modal-dialog-centered' toggle={togglecheckIn} >
            <div className='Profession_Modal_section AboutModal_Main_section p-4 py-3'>
              <h4>Location</h4>
              <h4 className='text-primary'><span><img width={18} src={Track} /></span> Detect Location</h4>
            </div>
            <ModalBody>
              <Stack sx={{ width: '100%' }}>
                <Autocomplete
                  disablePortal
                  id="tags-standard"
                  options={Locations}
                  margin="normal"
                  renderInput={(params) => <TextField variant="outlined" className='border rounded' {...params} placeholder="Search location..." />}
                />
              </Stack>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for tag people -----------------*/

  const PeopleTagModal = () => {
    const [peopleval, setpeopleVal] = useState([]);

    const TagPeopleChip = peopleval.map((option, index) => {
      // This is to handle new options added by the user (allowed by freeSolo prop).
      const label = option.title || option;
      return (
        <Chip
          sx={{ margin: '1%' }}
          key={label}
          label={label}
          deleteIcon={<XCircle size={18} />}
          onDelete={() => {
            setpeopleVal(peopleval.filter(entry => entry !== option));
          }}
        />
      );
    });
    return (
      <>
        {PeopleTag ? (
          <Modal isOpen={PeopleTag} className='modal-dialog-centered' toggle={togglePeopleTag} >
            <ModalHeader toggle={togglePeopleTag} className='d-flex justify-content-center'>
              <h4>Tagged People</h4>
            </ModalHeader>
            <ModalBody>
              <Stack sx={{ width: '100%' }}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  freeSolo
                  filterSelectedOptions
                  options={names.slice(0, 2).map((option) => option)}
                  onChange={(e, newValue) => setpeopleVal(newValue)}
                  getOptionLabel={option => option.name}
                  renderTags={() => { }}
                  value={peopleval}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                      <div className='d-flex align-items-center m-1 p-1'>
                        <img
                          loading="lazy"
                          width={40}
                          height={40}
                          className='border rounded-circle'
                          src='/assets/images/pages-logo3.jpg'
                          // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        <div className='ml-2'>
                          <h3>{option.name}</h3>
                          <p className='text-muted'>{option.category}</p>
                        </div>
                      </div>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className='border rounded'
                      // variant="outlined"
                      placeholder="Search ..."
                    // margin="normal"
                    // fullWidth
                    />
                  )}
                />
                {TagPeopleChip.length > 0 ?
                  <div className="selectedTags border">{TagPeopleChip}</div> : null
                }
              </Stack>
              <div className='my-2 scrollForHastag'>
                <h3>Suggestion</h3>
                <div className='my-1'>
                  <div className='d-flex align-items-center' >
                    <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                    <div className='my-1 ml-1'>
                      <h4>Taha jamal</h4>
                      <p className='text-muted'>SocioMate</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center' >
                    <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                    <div className='my-1 ml-1'>
                      <h4>Taha jamal</h4>
                      <p className='text-muted'>SocioMate</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center' >
                    <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                    <div className='my-1 ml-1'>
                      <h4>Taha jamal</h4>
                      <p className='text-muted'>SocioMate</p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center' >
                    <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                    <div className='my-1 ml-1'>
                      <h4>Taha jamal</h4>
                      <p className='text-muted'>SocioMate</p>
                    </div>
                  </div>
                </div>
              </div>

            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for Schedule post -----------------*/

  const Comment = () => {
    const [listItems, setListItems] = useState([]);

    const handleAddClick = () => {
      setListItems([...listItems, 'New Item']);
    };


    return (
      <>
        {
          Scehedule !== false &&
          <Modal isOpen={Scehedule} className='modal-dialog-centered' toggle={toggleScehedule} >
            <ModalHeader toggle={toggleScehedule} className='d-flex justify-content-center'>
              <h4>Schedule your post </h4>
            </ModalHeader>
            <ModalBody>
              <Row>
                {/* {Schedule === true ? document.querySelector('#picker').click() : ""} */}
                <Col className='my-2'>
                  <Input
                    type="datetime-local"
                    floatLabelType="Auto"
                  />
                  <div>
                    <ul>
                      {listItems.map((item, index) => {
                        if (index < 5)
                          return (
                            <li key={index}> <Input
                              type="datetime-local"
                              className='my-2'
                            /></li>
                          )
                      })}
                    </ul>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='my-2 d-flex align-items-center'>
                        <Checkbox /> <h5>Post Now</h5>
                      </div>
                      <b className='text-primary' onClick={handleAddClick}>+ Add more</b>
                    </div>

                  </div>
                </Col>
              </Row>
            </ModalBody>

          </Modal>
        }
      </>
    )
  }
  /*-------------- Modal for Comment ON/OFF -----------------*/

  const Schedule = () => {

    return (
      <>
        {
          CommentOn !== false &&
          <Modal isOpen={CommentOn} className='modal-dialog-centered' toggle={toggleComment} >
            <ModalHeader toggle={toggleComment} className='d-flex justify-content-center'>
              <h4>Enable Comment </h4>
            </ModalHeader>
            <ModalBody>
              <Row>
                {/* {Schedule === true ? document.querySelector('#picker').click() : ""} */}
                <Col className='my-2'>
                  <div className='d-flex align-items-center justify-content-between styleForTextFooter m-1'>
                    <div className='d-flex'>
                      <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" ><path d="M12 20.9999C16.97 20.9999 21 16.9702 21 12C21 7.02974 16.97 3 12 3C7.03 3 3 7.02974 3 12C3 13.7707 3.512 15.4222 4.395 16.815L3 20.9999L7.745 19.9323C9.07479 20.6408 10.5286 21.0056 12 20.9999Z" /><line x1="7" y1="9" x2="17" y2="9" stroke="white" strokeLinecap="round" /><line x1="7" y1="12" x2="17" y2="12" stroke="white" strokeLinecap="round" /><line x1="7" y1="15" x2="17" y2="15" stroke="white" strokeLinecap="round" /></svg>
                      <div className="tooltip-cls">
                        <span>&nbsp;&nbsp;Comment</span>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      Off
                      <label className="switch mx-1 mb-0">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                      On
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>

          </Modal>
        }
      </>
    )
  }

  /*  ---------------------- Cover Image Upload for Modal --------------------  */

  const UploadModal = () => {
    const uploadData = [
      {
        img: 'https://picsum.photos/900/270?grayscale',
        selectedImg: 'selected'
      },
      {
        img: 'https://picsum.photos/900/270?grayscale',
        selectedImg: 'Unselected'
      },
      {
        img: 'https://picsum.photos/900/270?grayscale',
        selectedImg: 'Unselected'
      },
      {
        img: 'https://picsum.photos/900/270?grayscale',
        selectedImg: 'Unselected'
      },
      {
        img: 'https://picsum.photos/900/270?grayscale',
        selectedImg: 'Unselected'
      },
      {
        img: 'https://picsum.photos/900/270?grayscale',
        selectedImg: 'Unselected'
      }
    ]
    return (
      <>
        {UploadModals ? (
          <Modal isOpen={UploadModals} className='modal-dialog-centered' toggle={toggleModal} >
            <ModalHeader toggle={toggleModal} className='text-center'>
              <h3>Select Your Cover Image </h3>
            </ModalHeader>
            <ModalBody>
              <>
                <Row>
                  {
                    uploadData.map((item, index) => {
                      return (
                        <Col xl='6' xs='6' key={index}>
                          <Card inverse className={item.selectedImg === 'selected' ? 'mb-2 border border-primary' : 'mb-2 border'} >
                            <CardImg
                              alt="Card image cap"
                              src={item.img}
                              width="100%"
                              style={{
                                height: 150,
                                objectFit: 'cover' 
                              }}
                            />
                            <CardImgOverlay className='text-right'>
                              {item.selectedImg === 'selected' ? <CheckCircle color='#81C14B' /> : ''}
                            </CardImgOverlay>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>

              </>
            </ModalBody>
            <div className='text-center m-2' >
              <Button onClick={() => document.querySelector('.filedialog').click()} color='secondary' className='px-5 mr-2' outline>
                Browse
              </Button>
              <input type="file" className="filedialog d-none" />
              <Button color='primary' outline>
                Upload cover photo
              </Button>
            </div>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*  ---------------------- Form Section --------------------  */

  const FormSection = () => {

    const [selectedItem, setSelectedItem] = useState('Select Who Can Listen');

    const handleItemSelect = (item) => {
      setSelectedItem(item);
    };

    const [selectedType1, setSelectedType1] = useState('Choose your category');

    const handleTypeSelect1 = (type) => {
      setSelectedType1(type);
    };


    return (
      <div className='my-2'>
        <div className='m-2'>
          <FormGroup className='w-100 my-1'>

            <h4 className='pb-1' >Podcast Catagories</h4>



            <div>
              <UncontrolledButtonDropdown className='w-100'>
                <DropdownToggle color='secondary text-left' outline className='d-flex border-clr justify-content-between align-items-center custom-toggle secondary Fs14_Fw_400' style={{ fontSize: '14px', padding: '8px 15px' }}>
                  {selectedType1}
                  <ChevronDown size={18} />
                </DropdownToggle>

                <DropdownMenu className='w-100 categories-scroll'>
                  <div className='text-left'>
                    {podacastType.map((type, index) => (
                      <DropdownItem className='Fs14_Fw_400' key={index} onClick={() => handleTypeSelect1(type.type)}>
                        {type.type}
                      </DropdownItem>
                    ))}
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </FormGroup>
        </div>
        <div className='m-2'>
          <FormGroup className='w-100 my-1'>

            <h4 className='pb-1'>Podcast Title</h4>

            <Input name="select" type="text" placeholder='Entertainment Podcast' />
          
         

          </FormGroup>
        </div>
        <Row className='rowForrevert d-flex align-items-start justify-content-between' >
          <Col xl='6'>
            <div>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <span className='ml-2' ><strong>Paid</strong></span>
                </div>
                <div>
                  <span>{isOff2 ? 'yes' : 'no'}</span>
                  <Switch className='colorForSwitch' checked={isOff2} onChange={() => setIsOff2(!isOff2)} />
                </div>
              </div>
              <div className='d-flex justify-content-center mx-1'>
                <Input disabled={!isOff2} name="select" type="select" className='PodacstForInput w-25' placeholder='Enter your product Location...' >
                  <option >
                    $
                  </option>
                  <option >
                    ₹
                  </option>
                  <option >
                    £
                  </option>
                </Input>
                <Input className='ml-1' Placeholder='Enter Amount' type="number" disabled={!isOff2} />
              </div>
            </div>
          </Col>
          <Col xl='6'>
            <div>
              <FormGroup className='w-100'>
                <div style={{ paddingTop: '2px' }}>
                  <h4 className='p-2' >Who Can Listen</h4>
                </div>


                <div className="mr-2">
                  <UncontrolledButtonDropdown className='w-100'>
                    <DropdownToggle color='secondary text-left' outline className='d-flex border-clr justify-content-between align-items-center custom-toggle secondary Fs14_Fw_400' style={{ padding: '8px 15px' }}>
                      {selectedItem}
                      <ChevronDown size={18} />
                    </DropdownToggle>
                    <DropdownMenu className='w-100 categories-scroll' style={{ zIndex: '3' }}>
                      <div className='text-left'>
                        {['Connections', 'Colleagues', 'Family', 'Friends', 'Comedy', 'Education'].map((item, index) => (
                          <DropdownItem className='Fs14_Fw_400' key={index} onClick={() => handleItemSelect(item)}>
                            {item}
                          </DropdownItem>
                        ))}
                      </div>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>

                </div>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row className='rowForrevert'>
          <Col xl='12'>
            <div onClick={() => document.querySelector('.filedialogS').click()} className='text-center border border-primary bg-light p-2 my-2'>
              <Upload accept="audio/*" color='#81C14B' size={38} />
              <input type="file" className="filedialogS d-none" />
              <h4 className='text-primary'>Upload Your Recorded Podcast</h4>
            </div>
            <div style={{ background: "#F6F6F6" }} className='text-center soundUploadForpodcast ' >
              <div className='d-flex justify-content-start align-items-center'>
                <div id="outerContainer">
                  <div id="usercontainer">
                    <div className="useritem">
                      <img src={userImg} className='border-0 rounded-circle' alt='userimag' />
                    </div>
                    <div className="usercircle" style={{ animationDelay: '0s' }} ></div>
                    <div className="usercircle" style={{ animationDelay: '1s' }} ></div>
                    <div className="usercircle" style={{ animationDelay: '2s' }} ></div>
                    <div className="usercircle" style={{ animationDelay: '3s' }} ></div>
                  </div>
                </div>
                <div className='p-2 w-100 caption'>
                  <b className='px-3' >(your caption will be displayed here)</b>
                  <div className='d-flex align-items-center py-2'>
                    <Play fill='black' />
                    <PrettoSlider
                      size="small"
                      defaultValue={70}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                    />
                    <small> 02:01</small>
                  </div>
                </div>
                {/* <audio controls className='ml-3 w-100'>
                  <source src="horse.ogg" type="audio/ogg" />
                  <source src="horse.mp3" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  /*  ---------------------- Setting Section --------------------  */

  const AdvanceSetting = () => {
    return (
      <Row>
        <Col xl='12'>
          <div className="golive-card-box">
            <div className="golive-card-head"><h3>Viewer Setting (more options to be added)</h3></div>
            <div className="golive-card-body">
              <div className="setup-viewer-setting-blk">
                <div className="golivesetting-toggle-box">
                  <div className="gl-setting-toggle-cont">
                    <h4>Turn on comments</h4>
                    <h5>By turning on this option you are agreeing with the fact that viewers can now comment on your podcast.</h5>
                  </div>
                  <div className="toggle-sec">
                    <div className="button toggle-btn">
                      <input type="checkbox" className="checkbox"
                       
                       checked={commentsActive}
                       onChange={() => setCommentsActive(!commentsActive)} />
                      <div className="knobs">
                        <span></span>
                      </div>
                      <div className="layer"></div>
                    </div>
                  </div>
                </div>
                <div className="golivesetting-toggle-box">
                  <div className="gl-setting-toggle-cont">
                    <h4>Enable notification</h4>
                    <h5>You will get notified if any activity happens over your podcast.</h5>
                  </div>
                  <div className="toggle-sec">
                    <div className="button toggle-btn">
                      <input type="checkbox" className="checkbox" 
                       checked={notificationActive}
                       onChange={() => setNotificationActive(!notificationActive)}
                     
                      />
                      <div className="knobs">
                        <span></span>
                      </div>
                      <div className="layer"></div>
                    </div>
                  </div>
                </div>
                <div className='golivesetting-toggle-box1'>
                  <div className="m-31 d-flex align-items-center">
                    <Checkbox
                      onClick={() => setcheckboxActive(!checkboxActive)}
                      checked={checkboxActive ? true : false}
                    />
                    <h4 className='ml-2'>I accept the <span className='text-primary'>Privacy Policy</span></h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Col>
      </Row>
    )
  }

  return (
    <Fragment>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <div className="container-fluid">
            <div className='createForHeadercolor'>
              <h3>Upload Recorded Podcast</h3>
            </div>

            <Row>
              <Col xl='5'>

                {/*  ---------------------- Cover Image Upload for Modal --------------------  */}

                <UploadModal />

                <Card inverse className='w-100 m-1'>
                  <CardImg
                    alt="Card image cap"
                    src="https://picsum.photos/900/270?grayscale"
                    className='img-fluid w-100'
                    style={{
                      height: 384,
                      objectFit: 'cover' 
                    }}
                  />
                  <CardImgOverlay>
                    <div className='bottomcenter'>
                      <Button inverse color='light' onClick={() => toggleModal()} outline>
                        Upload Podcast Cover Image
                      </Button>
                    </div>
                  </CardImgOverlay>
                </Card>

              </Col>
              <Col xl='7'>

                {/*  ---------------------- Form Section --------------------  */}

                <FormSection />

              </Col>
            </Row>

            <Row className='rowForrevert'>
              <Col xl='12'>

                <h3 className='py-1' >About Podcast</h3>
                <div>
                  <Input
                    type="textarea"
                    placeholder='Description...'
                  />
                  <p className='text-right pb-2'>Max 1200 Charaters</p>

                  {/*  ---------------------- Setting Section --------------------  */}

                  <AdvanceSetting />

                  {/*  ---------------------- CommonFooter Section --------------------  */}

                  <div>
                    <Hastag />
                    <CheckIn />
                    <PeopleTagModal />
                    <Schedule />
                    <Comment />
                    <div className="create-btn-cust-option">
                      <h4>Add to your post</h4>
                      <ul className="create-btn-list tooltip-sec">

                        <li id="hashtag-btn"  >
                          <svg onClick={togglehastag} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="16" height="16" viewBox="0 0 16 16" fill={'#999999'} ><path d="M15.8754 8.77081C15.6635 8.1214 14.956 7.77619 14.3032 7.9881L12.7514 8.50421L11.7158 5.42804L13.2675 4.91193C13.9204 4.70001 14.2622 3.99591 14.0537 3.34308C13.8417 2.69367 13.1342 2.34845 12.4814 2.56036L10.9296 3.07648L10.393 1.47003C10.1811 0.820618 9.47358 0.475403 8.82075 0.687317C8.16792 0.899231 7.82612 1.60333 8.03461 2.25617L8.57124 3.86261L5.37885 4.92902L4.84223 3.32257C4.63032 2.67316 3.9228 2.32794 3.26997 2.53986C2.61714 2.75177 2.27534 3.45587 2.48383 4.1087L3.02046 5.71515L1.47212 6.22785C0.819284 6.43976 0.477487 7.14386 0.685983 7.79669C0.856881 8.2923 1.33881 8.61701 1.83442 8.63751C2.06684 8.67169 2.24458 8.58283 3.80659 8.06329L4.84223 11.1395L3.29047 11.6556C2.64106 11.8675 2.29585 12.575 2.50434 13.2244C2.67524 13.72 3.15717 14.0447 3.65278 14.0652C3.8852 14.0994 4.06294 14.0106 5.62495 13.491L6.16157 15.0975C6.36323 15.6751 7.00581 16.0887 7.73383 15.8802C8.38667 15.6683 8.72846 14.9642 8.51997 14.3113L7.98335 12.7049L11.1826 11.6351L11.7192 13.2415C11.9208 13.8192 12.5634 14.2327 13.2915 14.0242C13.9443 13.8123 14.2861 13.1082 14.0776 12.4554L13.541 10.8489L15.0927 10.3328C15.7421 10.1277 16.0873 9.42023 15.8754 8.77081ZM7.19038 10.3841L6.15473 7.30109L9.35053 6.23126L10.3862 9.31427L7.19038 10.3841Z" /></svg>
                          <div className="tooltip-cls">
                            <span>Hashtag</span>
                          </div>
                        </li>
                        <li id="checkin-btn"  >
                          <svg onClick={togglecheckIn} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="13" height="21" viewBox="0 0 13 21" fill={'#999999'}><path d="M6.5 0C2.90643 0 0 3.28666 0 7.35036C0 11.7291 4.10429 17.7669 5.79429 20.0665C6.16571 20.5705 6.84357 20.5705 7.215 20.0665C8.89571 17.7669 13 11.7291 13 7.35036C13 3.28666 10.0936 0 6.5 0ZM6.5 9.97548C5.88432 9.97548 5.29385 9.69891 4.8585 9.2066C4.42315 8.71429 4.17857 8.04658 4.17857 7.35036C4.17857 6.65413 4.42315 5.98642 4.8585 5.49411C5.29385 5.0018 5.88432 4.72523 6.5 4.72523C7.11568 4.72523 7.70615 5.0018 8.1415 5.49411C8.57685 5.98642 8.82143 6.65413 8.82143 7.35036C8.82143 8.04658 8.57685 8.71429 8.1415 9.2066C7.70615 9.69891 7.11568 9.97548 6.5 9.97548Z" /></svg>
                          <div className="tooltip-cls">
                            <span>Check In</span>
                          </div>
                        </li>
                        <li id="friends-btn"  >
                          <svg onClick={togglePeopleTag} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="25" height="21" viewBox="0 0 25 21" fill={'#999999'}><path d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z" /></svg>
                          <div className="tooltip-cls">
                            <span>People Tag</span>
                          </div>
                        </li>
                        <li id="schedule-btn"  >
                          <svg onClick={toggleScehedule} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" fill={'#999999'}><path d="M20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442Z" /><path d="M12 8V12L15 15M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#A6A6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 8V12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" /></svg>
                          <div className="tooltip-cls">
                            <span>Schedule Your Post</span>
                          </div>
                        </li>
                        <li id="comment-btn" >
                          <svg onClick={toggleComment} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" fill={'#999999'}><path d="M12 20.9999C16.97 20.9999 21 16.9702 21 12C21 7.02974 16.97 3 12 3C7.03 3 3 7.02974 3 12C3 13.7707 3.512 15.4222 4.395 16.815L3 20.9999L7.745 19.9323C9.07479 20.6408 10.5286 21.0056 12 20.9999Z" /><line x1="7" y1="9" x2="17" y2="9" stroke="white" strokeLinecap="round" /><line x1="7" y1="12" x2="17" y2="12" stroke="white" strokeLinecap="round" /><line x1="7" y1="15" x2="17" y2="15" stroke="white" strokeLinecap="round" /></svg>
                          <div className="tooltip-cls">
                            <span>Allow Comments</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className='py-2'>
                      <Button color='primary' outline className='w-100'>Post</Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </div>
        <RightSidebar />
      </div >

    </Fragment >
  )
}

export default UploadRecordedPodacast