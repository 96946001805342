import React, { useState } from 'react'
import { Circle, Eye, EyeOff } from 'react-feather'
import { Button, Col, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'
import Smartphone from '../../../NewComponents/IMG/smartphone.svg'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert';


const SecurityLogin = () => {

    /**        States         * */

    const [passwordhide, setpasswordUnhide] = useState(false)
    const [password, setPassword] = useState("");
    const [reEnterPassword, setReEnterPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    /**        Function for eyeOn/Off         * */

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    /**        Sweet Alert Function         * */

    const logOut = () => {
        sweetAlertConfirmation('You have successfull logged out',)

    }

    const PasswordSave = () => {

        sweetAlertConfirmation('Your Password is Updated')

    }

    /**        LoginDevices Data Array         * */

    const LoginSessions = [
        {
            deviceName: 'Vivo V2140',
            devicelocation: 'Rajkot India ',
            loginDateandTime: 'August 10 at 11:07 AM',
            loginStatus: 1
        },
        {
            deviceName: 'Vivo V2140',
            devicelocation: 'Rajkot India ',
            loginDateandTime: 'August 10 at 11:07 AM',
            loginStatus: 1
        },
        {
            deviceName: 'Vivo V2140',
            devicelocation: 'Rajkot India ',
            loginDateandTime: 'August 10 at 11:07 AM',
            loginStatus: 0
        },
        {
            deviceName: 'Vivo V2140',
            devicelocation: 'Rajkot India ',
            loginDateandTime: 'August 10 at 11:07 AM',
            loginStatus: 1
        },
        {
            deviceName: 'Vivo V2140',
            devicelocation: 'Rajkot India ',
            loginDateandTime: 'August 10 at 11:07 AM',
            loginStatus: 0
        },
    ]


    /**        Main Code Render         * */

    return (
        <div className='p-2'>

            {/**        Login Alert Toggle         **/}

            <div className='py-2'>
                <h3>Alert on unrecognized Login?</h3>
                <div className='border-bottom'>
                    <div className="golivesetting-toggle-box py-4">
                        <div className="gl-setting-toggle-cont">
                            <h4>On/Off </h4>
                            <h5 className='text-muted pt-1'>Enable to authorised login alert on your phone </h5>
                        </div>
                        <div className="toggle-sec">
                            <div className="button toggle-btn">
                                <input type="checkbox" className="checkbox" />
                                <div className="knobs">
                                    <span></span>
                                </div>
                                <div className="layer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/**        Change Password Inputes         **/}

            <div className='mt-3'>
                <h3>Change Password</h3>
                <div className='border-bottom py-3'>
                    <Row className='golivesetting-toggle-box redefineClass'>
                        <Col xl='6' sm='12' className="ml-2">
                            <h4>Old Password </h4>
                            <InputGroup className='input-group-merge py-2 mb-2 inputgrouppass'>
                                <Input className='InputForborder' type={passwordhide ? 'password' : 'text'} placeholder='Enter old password' />
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText onClick={() => setpasswordUnhide(!passwordhide)}>
                                        {
                                            passwordhide ?
                                                <EyeOff size={14} /> :
                                                <Eye size={14} />
                                        }

                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className='golivesetting-toggle-box redefineClass'>
                        <Col xl='6' sm='12' className="ml-2">
                            <h4>New Password </h4>
                            <InputGroup className='input-group-merge py-2 mb-2 inputgrouppass'>
                                <Input
                                    type={showPassword ? 'password' : 'text'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder='Enter new password ' />
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText onClick={togglePasswordVisibility}>
                                        {
                                            showPassword ?
                                                <EyeOff size={14} /> :
                                                <Eye size={14} />
                                        }

                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className='golivesetting-toggle-box redefineClass'>
                        <Col xl='6' sm='12' className="ml-2">
                            <h4>Confirm Password  </h4>
                            <InputGroup className='input-group-merge py-2 mb-2 inputgrouppass'>
                                <Input
                                    type={showPassword ? 'password' : 'text'}
                                    value={reEnterPassword}
                                    onChange={(e) => setReEnterPassword(e.target.value)}
                                    placeholder='Confirm password ' />
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText onClick={togglePasswordVisibility}>
                                        {
                                            showPassword ?
                                                <EyeOff size={14} /> :
                                                <Eye size={14} />
                                        }

                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            <div className='d-flex justify-content-between align-items-center'>
                                <FormGroup className='pl-2 '>
                                    <Input type="checkbox" />
                                    <Label>
                                        Remember me    
                                    </Label>
                                </FormGroup>
                                {/* <FormText >
                                    Max 160 Characters
                                </FormText> */}
                            </div>

                        </Col>
                    </Row>

                    <div className='text-end py-2 butsec  '>
                        {/* <FormGroup className='pl-2 '>
                            <Input type="checkbox" />
                            <Label>
                                Remember me
                            </Label>
                        </FormGroup> */}

                        <Button color='primary' onClick={PasswordSave} >Save </Button>
                    </div>
                </div>
            </div>

            {/**        Login Sessions device Data         **/}

            <div>
                <div className='py-2'>
                    <div className='d-flex align-items-center justify-content-between py-2'>
                        <h3>Login in session</h3>
                        <h3 style={{ cursor: 'pointer' }} className='text-primary'>Logout from all Session </h3>
                    </div>
                    {LoginSessions.map((item) => {
                        return <div className='SessionForBar'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-start'>
                                    <img src={Smartphone} />
                                    <div className='ml-2'>
                                        <h4>{item.deviceName} <Circle className='mx-2' fill='#808080' size={5} /> <span>{item.devicelocation}</span></h4>
                                        <h4 className='pt-2'>{item.loginDateandTime}  <Circle className='mx-2' fill='#808080' size={5} /> <span className={item.loginStatus === 1 ? 'text-primary' : 'text-danger'}>{item.loginStatus === 1 ? 'Active Now' : 'Not Active'}</span></h4>
                                    </div>
                                </div>
                                <div><h3 style={{ cursor: 'pointer' }} className='text-danger'
                                    onClick={logOut}
                                >Logout</h3></div>
                            </div>
                        </div>
                    })}

                </div>
            </div>

            {/**        Modal for save password        **/}

            {/* <PasswordSave /> */}
        </div >
    )
}

export default SecurityLogin 