import React from 'react'
import LeftquotationIcon from '../Img/newComponenetImages/LeftquotationIcon.svg'
import RightquotationIcon from '../Img/newComponenetImages/RightquotationIcon.svg'

const BookmarkThought = () => {
    return (
        <div style={{ background: '#E0E0E0' }} className='p-2 BookmarkBorderRadius '>
            <div style={{ marginTop: '2rem', marginBottom: '1rem', borderRadius: '10px', background: '#60715C', color: '#F8DF88' }} className='p-3 mx-3'>
                <img src={LeftquotationIcon} className='LeftIconforthrought' alt="icon" />
                <div className='mx-4 text-center'>
                    <div className='text-left'>
                        <h2>The real test of character is how you treat someone who has no.The real test of character is how you treat someone who has no.</h2>
                    </div>
                </div>
                <div className='text-right'>
                    <img src={RightquotationIcon} alt="icon" className='RightIconforthrought' />
                </div>
            </div>
            <div className='text-right m-2 mb-3'>
                <h3 className='text-white'>  -  Pepper Johnson Potts</h3>
                <h4 style={{ color: '#FF27C3' }}>#thoughtoftheday</h4>
            </div>
        </div>
    )
}

export default BookmarkThought
