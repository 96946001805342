// import React, { Fragment } from 'react'
import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ReportDataInfo } from '../../DummyArray'
import { Pagination, Stack ,Radio} from '@mui/material';

const ReportModal = ({ reportOpen, toggleReport }) => {
    const [radioActive, setRadioActive] = useState(null)

    //===================== Mian_return_function ==================// 
    return (
        <div className='Review_Report_modal'>
            {
                reportOpen !== false &&
                <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered' >
                    <ModalHeader className="Biz_Rating_SECTION" toggle={toggleReport} >
                        <h3>Report</h3>
                    </ModalHeader >
                    <ModalBody>
                        <div className='Modal_Body_Section'>
                            <p>Tell us about how you feel and help us to <br /> make SocioMee more secure.</p>
                        </div>
                        {
                  ReportDataInfo.map((data,index) => (
                    <div
                    key={index}
                    className='d-flex justify-content-between Report_Section'
                  >
                    <h5
                      className='d-flex reort'
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                      }}
                    >
                      {data.name}
                    </h5>
                    <div>
                      <Radio
                        inputProps={{ 'aria-label': 'A' }}
                        name="radio-buttonsOne"
                        // id={`radio-buttonsOneId_${index}`}
                        className='Radio_btn_check'
                        checked={radioActive === index}
                        onClick={() =>{setRadioActive((prevIndex) => (prevIndex === index ? null : index));}}
                      />
                      {' '}
                    </div>
                  </div>
                  ))
                }
                        <div className='d-flex justify-content-center mt-3'>
                            <Input
                                id="ReportName"
                                name="ReportName"
                                placeholder="Write a comment..."
                                type="Repor"
                            />
                        </div>
                        <hr className='w-100 text-muted' />
                        <div className='text-center'>
                            <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                            <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                        </div>
                    </ModalBody>

                </Modal>
            }
        </div>
    )
}

export default ReportModal
