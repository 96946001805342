import React, { Fragment, useState, useEffect } from 'react';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import { Button, CustomInput, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip, InputGroup, InputGroupText } from 'reactstrap';
import { AtSign, Check, ChevronsDown, ChevronsUp, Clock, Globe, Info, Lock, MapPin, Phone, UploadCloud, X, Search, Circle, XCircle } from 'react-feather';
import Mask from '../biz-components/Img/Mask.png'
import './biz.css'
import BizTrack from './Img/BizTrack.png'
import { FormLabel, Radio } from '@mui/material';
import CreationLeftSide from './Components/CreationLeftSide';
import CreationRightSide from './Components/CreationRightSide';
// import Select from 'rc-select';
import { createBussinessPageAction } from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Invite from './Img/inv.svg'
import Bprofile from './Img/BizProfile.png'


const Buttonbehaviour = () => (

    <Fragment>
        <span className='switch-icon-left'>
            <Check size={14} />
        </span>
        <span className='switch-icon-right'>
            <X size={14} />
        </span>
    </Fragment>
)

const CreateBizPage = () => {
    const dispatch = useDispatch()
    const createdBussinessPage = useSelector((state) => { return state.createBizPageData });
    const { loading: loadingcreatedBussinessPage, error: errorcreatedBussinessPage, result: resultcreatedBussinessPage } = createdBussinessPage
    console.log('resultcreatedBussinessPage', resultcreatedBussinessPage)
    // const [data, setData] = useState('');
    const [name, setName] = useState('')
    const [BizCatagory, setBizCatagory] = useState("")
    const [cancleModal, setCancleModal] = useState(false)
    const [SetModal, setSetModal] = useState(false)
    const [show, setShow] = useState(false)

    // const [show, setShow] = useState(false)
    // const [selectedValue, setSelectedValue] = useState('a');
    // const [cancleModal, setCancleModal] = useState(false)

    const [selectedValue, setSelectedValue] = useState('a');
    const [moreAddress, setMoreAddress] = useState(false)
    const [moreUrl, setMoreUrl] = useState(false)
    const [moreEmail, setMoreEmail] = useState(false)
    const [moreContact, setMoreContact] = useState(false)
    const [actionSetUp, setActionSetUp] = useState(false)

    const [bpCategoryIdToProp, setbpCategoryIdToProp] = useState('')
    const [bpCategoryName, setbpCategoryName] = useState('')

    const [bpSubCategoryName, setbpSubCategoryName] = useState()
    const [bpSubCategoryIdToProp, setbpSubCategoryIdToProp] = useState()
    const [bpSubCategoryId, setbpSubCategoryId] = useState()
    const [summary, setSummary] = useState('')
    const [bpaddress, setbpaddress] = useState('')
    const [imageLogo, setImageLogo] = useState("")
    const [logo, setlogo] = useState('')
    const [image, setImage] = useState("")
    const [startingTimeShift1, setStartingTimeShift1] = useState("")
    const [startingMinute, setStartingMinute] = useState("")
    const [endingTimeShift1, setEndingTimeShift1] = useState("")
    const [endingMinute, setEndingMinute] = useState("")
    const [webUrl, setWebUrl] = useState("")
    const [webUrl1, setWebUrl1] = useState("")
    const [email, setEmail] = useState("")
    const [email1, setEmail1] = useState("")
    // const [contactNumber, setContactNumber] = useState("")
    const [contactNumber1, setContactNumber1] = useState('');
    const [contactNumber2, setContactNumber2] = useState('');
   
    const [locationLATToProps, setlocationLATToProps] = useState("")
    const [locationLONGToProps, setlocationLONGToProps] = useState("")

    const [selectedStartingTimeValue, setSelectedStartingTimeValue] = useState('AM');
    const [selectedValueClosing, setSelectedValueClosing] = useState('PM');

    const [status, setstatus] = useState('')
    const [visitorCount, setvisitorCount] = useState('')
    const [operatingStatus, setoperatingStatus] = useState('')
    const [isPrivate, setisPrivate] = useState('')
    const [blockedByAdmin, setblockedByAdmin] = useState('')
    const [blockCode, setblockCode] = useState('')
    const [blockMessage, setblockMessage] = useState('')
    const [visibility, setvisibility] = useState('')
    const [allowCall, setallowCall] = useState('')
    const [allowMessage, setallowMessage] = useState('')
    const [allowSharing, setallowSharing] = useState('')
    const [allowPageSuggestion, setallowPageSuggestion] = useState('')
    const [allowQuestion, setallowQuestion] = useState('')
    const [allowNotificationOnEmail, setallowNotificationOnEmail] = useState('')
    const [allowNotificationOnSms, setallowNotificationOnSms] = useState('')
    const [allowNotification, setallowNotification] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [coverUrl, setcoverUrl] = useState('')
    const [address, setaddress] = useState('')
    const [country, setcountry] = useState('')
    const [city, setcity] = useState('')
    const [zipCode, setzipCode] = useState('')
    const [locationLAT, setlocationLAT] = useState('')
    const [locationLONG, setlocationLONG] = useState('')
    const [isDefault, setisDefault] = useState('')

    const [isCancelClicked, setIsCancelClicked] = useState(false);
    // invite modal
    const [senddropdownOpen, setsendDropdownOpen] = useState(false)
    const [viewerdropdownOpen, setviewerDropdownOpen] = useState(false)



    const createBizPage = {
        bpCategoryId: bpCategoryIdToProp,
        bpCategoryName: bpCategoryName,
        bpSubCategoryId: bpSubCategoryIdToProp,
        bpSubCategoryName: bpSubCategoryName,
        name: name,
        summary: summary,
        bpaddress: bpaddress,
        role: 'superAdmin',
        logo: logo,
        status: status,
        visitorCount: visitorCount,
        operatingStatus: operatingStatus,
        isPrivate: isPrivate,
        blockedByAdmin: blockedByAdmin,
        blockCode: blockCode,
        blockMessage: blockMessage,
        visibility: visibility,
        allowCall: allowCall,
        allowMessage: allowMessage,
        allowSharing: allowSharing,
        allowPageSuggestion: allowPageSuggestion,
        allowQuestion: allowQuestion,
        allowNotificationOnEmail: allowNotificationOnEmail,
        allowNotificationOnSms: allowNotificationOnSms,
        allowNotification: allowNotification,
        coverImages: [
            {
                coverUrl: imageLogo
            },
            {
                coverUrl: image
            }
        ],
        address: [
            {
                address: address,
                country: country,
                city: city,
                zipCode: zipCode,
                locationLAT: locationLATToProps,
                locationLONG: locationLONGToProps,
                isDefault: isDefault
            }
        ]
    }


    // const dispatch = useDispatch()


    // useEffect(() => {
    //     dispatch(loadAllInvitationGroup())
    // },[])

    const createBussinessPage = () => {
        dispatch(createBussinessPageAction(createBizPage))
    }

    const ShowHandle = () => {
        setSetModal(!SetModal)
    }

    const [isMobileView, setIsMobileView] = useState(false);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const [bizCatagoryVale, setBizCatagoryVale] = ('')
    const CatagoryChange = (val) => {
        console.warn(val.target.value)
        setBizCatagoryVale(val.target.value);

    }
    //     const [discard, setDisCard] = useState(false);
    //   const toggleDiscard = () => {
    //     setDisCard((preState) => !preState);
    //   };

    const [bizName, setBizName] = useState("")
    const nameChange = (val) => {
        console.warn(val.target.value)
        setBizName(val.target.value);

    }
    const CancleHandle = () => {
        setCancleModal(!cancleModal);
    }
    const UpdateBizCatagory = () => {
        setBizCatagory(BizCatagory)
    }
    //invite modal
    const toggleinvite = () => {



        setsendDropdownOpen((prevState) => !prevState)

    }

    const toggleViewer = () => {
        setviewerDropdownOpen((prevState) => !prevState)
    }

    const handleCancel = () => {
        setIsCancelClicked(true)
    };

    const BizCreationData = [
        {
            link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
            name: "Address 1",
            name1: "Your Address will display here. ",
        }
    ]
    //  invite data
    const inviteData = [
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Steward@564',
            typesof: 'Following'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Steward@564',
            typesof: 'Following'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Steward@564',
            typesof: 'Following'
        }
    ]
    const BizSummaryData = [
        {
            img: <AtSign size={15} color="#81C14B" />,
            name: "Email Address",
            subTitle: "Your Email Address will display here. "
        },
        {
            img: <Phone size={15} color="#81C14B" />,
            name: "Contact Number ",
            subTitle: "Your contact number will display here.  "
        },
        {
            img: <Clock size={15} color="#81C14B" />,
            name: "Timing ",
            subTitle: "Your Timing will display here. "
        },
        {
            img: <Globe size={15} color="#81C14B" />,
            name: "Website",
            subTitle: "Your website will display here. "
        },

    ]





    const SetUpModal = () => {
        return (
            <>
                <Modal isOpen={SetModal} toggle={ShowHandle} className='modal-dialog-centered'>
                    <div className=' modalHeading'>
                        <h3 >Action Button</h3>
                    </div>
                    <ModalBody>
                        <div class="d-flex align-items-center py-1">
                            <div className='mr-auto' ><b>Auto read OTP</b>
                            </div>
                            <div class="mr-1">On</div>
                            <div >Off</div>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        )
    }
    const CancelButtonModal = () => {
        return (
            <>
                <Modal isOpen={cancleModal} toggle={CancleHandle} className='modal-dialog-centered'>

                    <div className=' modalHeading p-2 pt-2 text-center rounded-2' style={{
                        backgroundColor:
                            '#F6F6F6'
                    }} >
                        <h3 >Cancel BizPage</h3>
                    </div>
                    <ModalBody>
                        <div className='modalBodayParagraph text-center '>
                            <p>Do you really want to cancel the BizPage Creation?</p>
                        </div>
                        <div className='ModalBodayButton text-center mt-4'>

                            <Button outline className="CancleButton" onClick={CancleHandle} >
                                Cancel
                            </Button>&nbsp;&nbsp;
                            <Link to='/Biz'>
                                <Button color="primary" className="Creation_preview_btn" >Confirm</Button>
                            </Link>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        )
    }

    const SendModal = () => {
        const [isSentList, setIsSentList] = useState(inviteData.map(() => false));

        const handleSendClick = (index) => {
            const updatedIsSentList = [...isSentList];
            updatedIsSentList[index] = true;
            setIsSentList(updatedIsSentList);
        }
        const handleCloseModal = () => {
            // Set the senddropdownOpen state to false to close the modal
            toggleinvite();
        };
        return (
            <>
                {senddropdownOpen ? (
                    <Modal isOpen={senddropdownOpen} className='modal-dialog-centered modalmargin' toggle={toggleinvite} >
                        <div

                            onClick={handleCloseModal}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>

                        <ModalHeader className='d-flex justify-content-center align-items-center'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
                                <img src={Invite} height={90} width={90} alt='userimage' />
                            </div>

                            <h2 className='sucessmodal'>BizPage Created Successfully!</h2>
                        </ModalHeader>
                        <ModalBody>
                            <h3> Send Invitation</h3>

                            <div className='d-flex align-items-center justify-content-between'>

                                <InputGroup className="input-group-merge d-flex px-3 py-1 my-31 rounded  align-items-center mr-2" style={{ background: "rgba(246, 246, 246, 1)" }}>
                                    <Search color='#666666' size={16} />
                                    <Input type='search' placeholder=" Search..." className='coonection-input-style' />
                                </InputGroup>

                            </div>
                            <div className="scrollForGolive mt-2">
                                {
                                    inviteData.map((d, i) => {
                                        return (
                                            <div>
                                                <div className='d-flex align-items-center justify-content-between mt-3  mr-3 mb-3 ml-1' key={i} >
                                                    <div className='d-flex align-items-center'>
                                                        <img src={Bprofile} height={45} width={45} className='rounded-circle' alt='userimage' />
                                                        <div className='ml-1 invitetext'>
                                                        <div className='position-relative'>
                                                                <h6 style={{ fontWeight: 'bold', fontSize: '14px',position:"relative",top:"-4px" }} className='text-muted'>{d.userNames}</h6>
                                                             
                                                            </div>

                                                            <h6 className='text-muted ' style={{ position: 'relative', top: '7px' }}>{d.joined}</h6>

                                                        </div>


                                                    </div>
                                                    {/* <hr style={{ height: '1px', color: "#EBEBEB" }}></hr> */}

                                                    <div className='w-25 invitedbutton '>
                                                        <Button
                                                            className='sentex'
                                                            color='primary w-100'
                                                            onClick={() => handleSendClick(i)}
                                                            disabled={isSentList[i]} // Disable the button when it's already sent
                                                        >
                                                            {isSentList[i] ? ' Invited' : 'Invite'}
                                                        </Button>
                                                    </div>



                                                </div>
                                                <hr style={{ height: '1px', color: "#c4c4c4" }}></hr>
                                            </div>









                                        )
                                    })
                                }


                                <Link to='/Biz'>
                                    <Button color='primary ' className='invitebutton'>ok</Button>
                                </Link>

                            </div>




                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //------------------ main_Return_function
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center" >
                    <Row>
                        <Col lg="4">
                            <div className="conta">
                                <h3 className='Bizcrea'>Create BizPage</h3>
                                <br></br>
                                <CreationLeftSide
                                    setName={setName}
                                    setbpCategoryName={setbpCategoryName}
                                    setbpCategoryIdToProp={setbpCategoryIdToProp}
                                    setbpSubCategoryIdToProp={setbpSubCategoryIdToProp}
                                    setbpSubCategoryName={setbpSubCategoryName}
                                    setSummary={setSummary}
                                    setbpaddress={setbpaddress}
                                    setcountry={setcountry}
                                    setcity={setcity}
                                    setzipCode={setzipCode}
                                    setlocationLATToProps={setlocationLATToProps}
                                    setlocationLONGToProps={setlocationLONGToProps}
                                    setImageLogo={setImageLogo}
                                    setImage={setImage}
                                    setStartingTimeShift1={setStartingTimeShift1}
                                    setStartingMinute={setStartingMinute}
                                    setSelectedStartingTimeValue={setSelectedStartingTimeValue}
                                    setSelectedValueClosing={setSelectedValueClosing}
                                    setEndingTimeShift1={setEndingTimeShift1}
                                    setEndingMinute={setEndingMinute}
                                    setWebUrl={setWebUrl}
                                    setWebUrl1={setWebUrl1}
                                    setEmail={setEmail}
                                    setEmail1={setEmail1}
                                    setContactNumber1={setContactNumber1}
                                    setContactNumber2={setContactNumber2}

                                />
                                <hr style={{
                                    position: 'relative', top: '-3 5px', background: 'rgba(0, 0, 0, 0.10);', height: '1px'
                                }}></hr>
                                <FormGroup>
                                    <div className='text-center'>
                                        {/* <Button color="primary" className=" BizCreation_Btn_Fillsection " onClick={createBussinessPage} >Create BizPage </Button> */}
                                        <Button color="primary" className=" BizCreation_Btn_Fillsection " onClick={toggleinvite} >Create BizPage </Button>
                                        <Button onClick={CancleHandle} color="secondary " className=" ml-2 BizCreation_Btn_section    " style={{ color: 'black', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}> Cancel </Button>
                                    </div>
                                    <br></br>
                                </FormGroup>
                            </div>

                        </Col>
                        <Col lg="8">
                            {!isMobileView && (
                                <CreationRightSide
                                    createBizPage={createBizPage}
                                    startingTimeShift1={startingTimeShift1}
                                    startingMinute={startingMinute}
                                    selectedStartingTimeValue={selectedStartingTimeValue}
                                    selectedValueClosing={selectedValueClosing}
                                    endingTimeShift1={endingTimeShift1}
                                    endingMinute={endingMinute}
                                    webUrl={webUrl}
                                    webUrl1={webUrl1}
                                    email={email}
                                    email1={email1}
                                    contactNumber1={contactNumber1}
                                    contactNumber2={contactNumber2}
                                />
                            )}
                        </Col>
                    </Row>
                    <CancelButtonModal />
                    <SetUpModal
                    />
                    <SendModal />

                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default CreateBizPage