import { Fragment, useState } from 'react'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import BizDetailMenu from './BizDetailMenu';
import RightSidebar from "../RightSidebar"
import './biz.css'
import { Col, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane, } from 'reactstrap';
import { ChevronRight } from 'react-feather';
import GeneralSetting from './SettingComponents/GeneralSetting';
import MessageSetting from './SettingComponents/MessageSetting';
import NotificationSetting from './SettingComponents/NotificationSetting';
import BussinessPageRoleSetting from './SettingComponents/BussinessPageRoleSetting';
import OtherPageSetting from './SettingComponents/OtherPageSetting';
import ManagementHistory from './SettingComponents/ManagementHistory';
import TopBanner from './TopBannerSection/TopBanner';


const BizSetting = () => {
    const [forgetActive, setForgetActive] = useState(false);
    const toggleForget = () => {
        setForgetActive(!forgetActive)
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [active, setActive] = useState('1')
    const toggleActive = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const handleEventsNameClick = (tabId) => {
        setCurrentActiveTab(tabId);


    };
    const [unblock, setUnblock] = useState(false)
    const toggleUnBlock = () => {
        setUnblock((preState) => !preState)
    }

    const [currentActiveTab, setCurrentActiveTab] = useState('1')
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)
        console.warn(tab)
    }



    const [setsideclassoption, setsetsideclassoption] = useState(false)
    //============================ BizSetting_Array_Data
    const BizSettingData = [
        {
            id: '1',
            name: "General"
        },
        {
            id: '2',
            name: "Message Setting"
        },
        {
            id: '3',
            name: "Notification"
        },
        {
            id: '4',
            name: "Business page Roles"
        },
        {
            id: '5',
            name: "People are other business pages "
        },
        {
            id: '6',
            name: "Business page Management history"
        }
    ]





    //===============Main_return_function========================//
    return (

        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center ">
                    <div className="bizdetailprofile">
                        <TopBanner />
                        <BizDetailMenu />
                    </div>

                    <div className="gdpost-about-blk mt-3 bizdetailprofile">
                        <Row>
                            <Col md={4}>
                                <div className="custom-card-block  seetingnav">
                                    <div className="customAbout-card-head">
                                        <h3>Biz Page Setting</h3>
                                    </div>
                                    <div className="biz-about-cont">
                                        <ul>
                                            {
                                                BizSettingData.map((data) => (
                                                    <li className={(currentActiveTab === data.id) ? 'Biz_setting ' : "   left_setting"} onClick={() => { toggle(data.id) }}>
                                                        <div className='nave-items-bizPage  d-flex justify-content-between'
                                                            active={currentActiveTab === data.id}
                                                        >
                                                            <span>{data.name}</span>
                                                            <ChevronRight size={20} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>

                                </div>
                                <div className="custom-card-block setnavwindow">
                                    <div className="customAbout-card-head">
                                        <h3> Biz Page Setting</h3>
                                    </div>
                                    <div className="biz-about-cont">
                                        <ul>
                                            {
                                                BizSettingData.map((data, index) => (
                                                    <li key={index} className={setsideclassoption ? 'hide-setting-options21' : (currentActiveTab === (index + 1).toString() ? 'selected-tab1' : '')}>
                                                        <div className='nave-items-bizPage d-flex justify-content-between' onClick={() => {
                                                            handleEventsNameClick((index + 1).toString());
                                                            setsetsideclassoption(true);
                                                            setCurrentActiveTab((index + 1).toString()); // Update the active tab when clicked
                                                        }}>
                                                            <span>{data.name}</span>
                                                            <ChevronRight size={20} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </Col>
                            <Col md={8} >
                                <TabContent className={!setsideclassoption && 'hide-setting-options21 '} activeTab={currentActiveTab}>



                                    <TabPane tabId="1">

                                        <GeneralSetting setsetsideclassoption={setsetsideclassoption} setsideclassoption={setsideclassoption} />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <MessageSetting setsetsideclassoption={setsetsideclassoption} setsideclassoption={setsideclassoption} />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <NotificationSetting setsetsideclassoption={setsetsideclassoption} setsideclassoption={setsideclassoption} />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <BussinessPageRoleSetting setsetsideclassoption={setsetsideclassoption} setsideclassoption={setsideclassoption} />
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <OtherPageSetting setsetsideclassoption={setsetsideclassoption} setsideclassoption={setsideclassoption} />
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <ManagementHistory setsetsideclassoption={setsetsideclassoption} setsideclassoption={setsideclassoption} />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className='UnblockAll_modal'>
                    {
                        unblock !== false &&
                        <Modal isOpen={unblock} toggle={toggleUnBlock} className='modal-dialog-centered'>
                            <ModalHeader className="BizUnblock_Heading">
                                <h2>Do you really want to Unblock These Users ?</h2>
                            </ModalHeader>
                            <ModalBody>

                            </ModalBody>

                        </Modal>
                    }

                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default BizSetting