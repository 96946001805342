import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { AlertTriangle, BellOff, Bookmark, Circle, Copy, EyeOff, Filter, MoreHorizontal, Share2, Smile, UserMinus } from 'react-feather'
import ActiveCirclelike from '../../../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../../../NewComponents/IMG/Circlelike.svg'
import ShareSecIcon from '../../../../NewComponents/IMG/ShareSecIcon.svg'
import CommentSecIcon from '../../../../NewComponents/IMG/CommentSecIcon.svg'
import AvatarGroup from '../../../../NewComponents/AvatarGroup'
import UserImg from '../../../../NewComponents/IMG/img.svg'
import PlayVideo from '../../../../NewComponents/IMG/playVideo.svg'
import Thought from './Thought'
import Event from './Event'
import Podcast from './Podcast'
import Recommendations from './Recommendations'
import Alert from './Alert'
import Poll from './Poll'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'

const Allpostdisplay = () => {

    const [LikeIcon, SetLikeIcon] = useState(false)
    const [BookmarkIcon, SetBookmark] = useState(true)
    const [comment, SetComment] = useState(false)
    const [isShown, setIsShown] = useState(false);
    const [forwardOpen, setForwardOpen] = useState(false)

    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const Commenttoggle = () => {
        SetComment(!comment)
    }
    const [commentR, SetCommentR] = useState('')
    const CommenttoggleR = () => {
        SetCommentR('I am seeking recommendation for buying ')
    }

    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const Sharedto = [
        {
            name: "Your Wall Feed"
        },
        {
            name: "Your Story"
        }
    ]

    let timer;
    const Reactionshowtoggle = () => {
        setIsShown(true)
    }
    const ReactionHidetoggle = () => {
        timer = setTimeout(() => setIsShown(false), 1000);
    }

    const TextPost = () => {
        return <Card className='p-2'>
            <div>
                <h5 className='text-dark'>Celebration new album song launched</h5>

                <div className='mt-2'>

                    <p className='my-2'><b className='text-primary'>#Musiccelebration</b>, #Musiccelebration, #music, #party, #music</p>
                    <p className='text-muted'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text ever since the 1500s...</p>

                </div>
            </div>
        </Card>
    }

    const MediaPost = () => {
        return <Card className='p-2'>
            <div>
                <div className='mediaForGridContainer'>
                    <div><img className='h-100 w-100' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" /></div>
                    <div className='mediaForGrid'>
                        <div><img height={94} className='w-100' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" /></div>
                        <div><img height={94} className='w-100' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" /></div>
                        <div>
                            <div className='position-relative'>
                                <img height={94} className='w-100 imgForShadow' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" />
                                <div className='mediaForshade'>+ 4</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    }

    const MediaPostVideoType = () => {
        return <Card className='p-2'>
            <div>
                <div className='mediaForGridContainer'>

                    <div className='position-relative'>
                        <img className='h-100 w-100' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" />
                        <div className='mediaForVBshade'>
                            <img src={PlayVideo} alt='playVideo' />
                        </div>
                    </div>

                    <div className='mediaForGrid'>
                        <div>
                            <div className='position-relative'>
                                <img height={94} className='w-100' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" />
                                <div className='mediaForVshade'>
                                    <img src={PlayVideo} alt='playVideo' />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='position-relative'>
                                <img height={94} className='w-100' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" />
                                <div className='mediaForVshade'>
                                    <img src={PlayVideo} alt='playVideo' />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='position-relative'>
                                <img height={94} className='w-100 imgForShadow' src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" />
                                <div className='mediaForVshade'>
                                    <img src={PlayVideo} alt='playVideo' />
                                </div>
                                <div className='mediaForshade'>+ 4</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    }



    const allpostType = [
        {
            id: <Thought />
        },
        {
            id: <Event />
        },
        {
            id: <Podcast />
        },
        {
            id: <Recommendations action={CommenttoggleR} />
        },
        {
            id: <Alert />
        },
        {
            id: <MediaPost />
        },
        {
            id: <MediaPostVideoType />
        },
        {
            id: <TextPost />
        },
        {
            id: <Poll />
        }
    ]

    const ForwardArrayData = [
        {
            id: "1",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "2",
            img: UserImg,
            name: "Darrell Steward",
            userName: "abriella-bond123"
        },
        {
            id: "3",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "4",
            img: UserImg,
            name: "Ralph Edwards",
            userName: "abriella-bond123"
        },
        {
            id: "5",
            img: UserImg,
            name: "Savannah Nguyen",
            userName: "abriella-bond123"
        },
        {
            id: "6",
            img: UserImg,
            name: "Eleanor Pena",
            userName: "abriella-bond123"
        },
        {
            id: "7",
            img: UserImg,
            name: "Darlene Robertson",
            userName: "abriella-bond123"
        },
        {
            id: "8",
            img: UserImg,
            name: "Bessie Cooper",
            userName: "abriella-bond123"
        }
    ]

    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const ShareModal = () => {
        const [cSelected, setCSelected] = useState([1])
        const onCheckboxBtnClick = selected => {
            const index = cSelected.indexOf(selected)
            if (index < 0) {
                cSelected.push(selected)
            } else {
                cSelected.splice(index, 1)
            }
            setCSelected([...cSelected])
        }
        return (
            <div className=''>
                {
                    forwardOpen !== false &&
                    <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleForward} className="My-Member_Header_section">
                            <h2>Share to  </h2>

                        </ModalHeader>
                        <ModalBody>
                            <div className='pb-2'>
                                {
                                    Sharedto.map((data) => (
                                        <div className='d-flex justify-content-between my-4 Report_Section'>
                                            <h4>{data.name}</h4>
                                            <div>
                                                <Input
                                                    name="radio1"
                                                    type="radio"
                                                    className="Check"
                                                />
                                                {' '}
                                            </div>
                                        </div>
                                    ))
                                }
                                <Accordion open={open} toggle={toggle}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">Your Business Pages</AccordionHeader>
                                        <AccordionBody accordionId="1">

                                            <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                <div className='ForWard_Img_categories d-flex align-items-center'>
                                                    <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                    <div className="ml-1">
                                                        <h4>Your Biz page anme</h4>
                                                    </div>
                                                </div>
                                                <Button color='primary'  >Shared</Button>
                                            </div>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">Your Group</AccordionHeader>
                                        <AccordionBody accordionId="2">

                                            <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                <div className='ForWard_Img_categories d-flex align-items-center'>
                                                    <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                    <div className="ml-1">
                                                        <h4>Your Biz page anme</h4>
                                                    </div>
                                                </div>
                                                <Button color='primary' outline >Share</Button>
                                            </div>


                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>

                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Invite Connection</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='flat-primary'>
                                        <Filter color='#4D4D4D' />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            {/* <div className=''> */}

                                            <Button color='primary w-25' outline onClick={() => onCheckboxBtnClick(data.id)}
                                                active={cSelected.includes(data.id)}
                                            >

                                                {cSelected == data.id ? "shared" : "share"}
                                            </Button>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }

    const ReportModal = () => {
        return (
            <>
                <div>
                    {
                        reportOpen !== false &&
                        <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                                <h3>Report</h3>
                            </ModalHeader>
                            <ModalBody>
                                <div className='Modal_Body_Section'>
                                    <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                                </div>
                                {
                                    ReportDataInfo.map((data) => (
                                        <div className='d-flex justify-content-between mt-4 Report_Section'>
                                            <h4>{data.name}</h4>
                                            <div>
                                                <Input
                                                    name="radio1"
                                                    type="radio"
                                                    className="Check"
                                                />
                                                {' '}
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className='d-flex justify-content-center mt-3'>
                                    <Input
                                        id="ReportName"
                                        name="ReportName"
                                        placeholder="Write a comment..."
                                        type="Report"
                                    />
                                </div>
                                <hr className='w-100 text-muted' />
                                <div className='text-center'>
                                    <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                    <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                                </div>
                            </ModalBody>

                        </Modal>

                    }

                </div>
            </>
        )
    }



    useEffect(() => {
        // "timer" will be undefined again after the next re-render
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <ShareModal />
            <ReportModal />

            {
                allpostType.map((item, index) => {
                    return (
                        <Card key={index} className='postCardForSize mb-2'>

                            <CardHeader className='border-0 bg-white'>

                                <div className='d-flex justify-content-between align-items-center py-2'>
                                    <div className='d-flex'>
                                        <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                                        <div className='ml-2'>
                                            <h4>Sufiya Eliza</h4>
                                            <small className='text-muted'>20 Min ago</small>
                                        </div>
                                    </div>
                                    <div>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color='flat-secondary' >
                                                <MoreHorizontal size={18} />
                                            </DropdownToggle>
                                            <DropdownMenu style={{ zIndex: '2', left: '-20px', width: 'fit-content' }}>
                                                <DropdownItem tag='a'><EyeOff size={15} /> Hide  post from your timeline</DropdownItem>
                                                <DropdownItem tag='a'><BellOff size={15} /> Turn off this post notification</DropdownItem>
                                                <DropdownItem tag='a'><Share2 size={15} /> Share via</DropdownItem>
                                                <DropdownItem tag='a'><Copy size={15} /> Copy link</DropdownItem>
                                                <DropdownItem tag='a'><UserMinus size={15} /> Unfollow “$username”</DropdownItem>
                                                {/* <DropdownItem divider></DropdownItem> */}
                                                <DropdownItem onClick={toggleReport} tag='a'><AlertTriangle size={15} /> Report this Post</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                            </CardHeader>

                            {item.id}

                            <CardBody>
                                <div className='d-flex justify-content-between align-items-center '>
                                    <div className='d-flex align-items-center '>
                                        <AvatarGroup />
                                        <p>+12k people like to this post</p>
                                    </div>
                                    <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                                        {
                                            BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                                        }
                                    </div>
                                </div>
                            </CardBody>
                            <div className='d-flex justify-content-between border-top p-3'>
                                <div className='text-muted'>
                                    😊😂😒😘 +75
                                </div>
                                <div className='d-flex colorForEverything'>
                                    <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                                    <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                                </div>
                            </div>
                            <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >
                                    {isShown && (
                                        <Card className='reactionFortab shadow-sm'>
                                            <div className='d-flex'>
                                                <Button className='btn-icon rounded-circle' color='flat-success'>
                                                    👍
                                                </Button>
                                                <Button className='btn-icon rounded-circle' color='flat-success'>
                                                    😊
                                                </Button>
                                                <Button className='btn-icon rounded-circle' color='flat-success'>
                                                    🤣
                                                </Button>
                                                <Button className='btn-icon rounded-circle' color='flat-success'>
                                                    😍
                                                </Button>
                                            </div>
                                        </Card>
                                    )}
                                    {
                                        LikeIcon ? <div className='mr-1'>
                                            <img src={ActiveCirclelike} alt='shareIcon' />
                                        </div> : <div className='mr-1'>
                                            <img
                                                onMouseEnter={Reactionshowtoggle}
                                                onMouseLeave={ReactionHidetoggle}
                                                src={Circlelike} alt='shareIcon' />
                                        </div>
                                    }
                                    <h5>Like</h5>
                                </div>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                                    {
                                        comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                            <img width={15} src={CommentSecIcon} alt='shareIcon' />
                                        </div> : <div className='backgroundForallIcons text-center mr-1'>
                                            <img width={15} src={CommentSecIcon} alt='shareIcon' />
                                        </div>
                                    }
                                    <h5>Comment</h5>
                                </div>
                                <div className='d-flex align-items-center'>

                                    <div onClick={toggleForward} className='backgroundForallIcons text-center mr-1'>
                                        <img width={15} src={ShareSecIcon} alt='shareIcon' />
                                    </div>
                                    <h5>Share</h5>
                                </div>

                            </div>
                            {
                                comment ?
                                    <div className='p-3'>

                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                <h4>Robert Fox</h4>
                                            </div>
                                            <h5 className='text-muted pr-1'>40 Mins ago</h5>
                                        </div>
                                        <div className='p-2 pl-5'>
                                            <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                            <div className='text-primary'>
                                                Like (5)
                                                &nbsp;
                                                Reply (6)
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center my-1'>
                                                <div className='d-flex align-items-center'>
                                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                    <h4>Aliza Alveen</h4>
                                                </div>
                                                <h5 className='text-muted'>40 Mins ago</h5>
                                            </div>
                                            <div>
                                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                                <div className='text-primary'>
                                                    Like (5)
                                                    &nbsp;
                                                    Reply (6)
                                                </div>
                                            </div>
                                        </div>

                                    </div> : null
                            }
                            <div className='m-3'>
                                <Input type='text'
                                    onChange={(e) => SetCommentR(e.target.value)}
                                    value={commentR}
                                    className={commentR ? 'text-primary' : ''}
                                    style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />

                                <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                            </div>
                        </Card>

                    )
                })
            }
        </>
    )
}

export default Allpostdisplay