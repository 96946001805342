import React from "react";
import emoji from "../../../NewComponents/IMG/emoji.png";
import fontStyyle from "../Images/FontStyle.svg";
import { useState,useRef, useEffect} from "react";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
//import { Picker } from "emoji-mart";
import Picker from "emoji-picker-react";

const StoryColorFont = ({
  textColor,
  handleColorChange,
  handleFontFamilyChange,
  setFontFamily,
  setTextAreaChange,
  setText,
}) => {
  const [DropDownOpen, setDropDownOpen] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
   const [inputText, setInputText] = useState(""); 
   const pickerRef = useRef(null);
  

  const dummyData = [
    { fontFamily: "", value: "Default", label: "Default" },
    { fontFamily: "Helvetica", value: "Helvetica", label: "Helvetica" },
    { fontFamily: "Arial", value: "Arial", label: "Arial" },
    {
      fontFamily: "Franklin Gothic Medium",
      value: "Franklin Gothic Medium",
      label: "Franklin Gothic Medium",
    },
    {
      fontFamily: "Book Antiqua",
      value: "Book Antiqua",
      label: "Book Antiqua",
    },
    { fontFamily: "Rockwell", value: "Rockwell", label: "Rockwell" },
    {
      fontFamily: "Brush Script MT",
      value: "Brush Script MT",
      label: "Brush Script MT",
    },
    { fontFamily: "Impact", value: "Impact", label: "Impact" },
    { fontFamily: "Arial Black", value: "Arial Black", label: "Arial Black" },
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const onEmojiClick = (emojiObject) => {
    setEmojiPicker(true);
    setInputText(emojiObject.emoji);
    console.log(emojiObject.emoji);
    setTextAreaChange(emojiObject.emoji)
    setText(emojiObject.emoji);
   
  };

  return (
    <div className="d-flex justify-content-center gap-5 align-items-end">
       <div className="text-center">
        <img
          src={emoji}
          width={25}
          alt="emoji"
          onClick={() => {setEmojiPicker((val) => !val);}}
        />
        <div
          ref={pickerRef}
          style={{
            width: "100px",
            height: "140px",
            position: "absolute",
            top: "10%",
            left: "10%",
            zIndex: "2",
          }}
        >
          {emojiPicker && (
            <Picker
              pickerStyle={{ width: "100%" }}
              onEmojiClick={onEmojiClick}
            />
          )}
        </div>
        <h6 className="mt-2">Emoji</h6>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div
          class="input-color-container text-center"
          style={{ width: "25px", height: "25px" }}
        >
          <input
            type="color"
            className="input-color"
            defaultValue="#ffffff"
            value={textColor}
            onChange={handleColorChange}
          />
        </div>
        <h6 className="mt-2 text-nowrap">Text color</h6>
      </div>
      <div className="text-center position-relative">
        <UncontrolledButtonDropdown>
          <DropdownToggle className="StoryFontDropDownToggle">
            <img
              src={fontStyyle}
              width={20}
              alt="emoji"
              onClick={() => {
                setDropDownOpen(!DropDownOpen);
              }}
            />
            <h6
              className="mt-2"
              onClick={() => {
                setDropDownOpen(!DropDownOpen);
              }}
            >
              Font style
            </h6>
          </DropdownToggle>
          <DropdownMenu className="StoryFontDropDownMenu">
            {dummyData.map((item) => (
              <DropdownItem
                key={item.value}
                className="HoverE6"
                style={{ fontSize: ".8rem", fontFamily: item.fontFamily }}
                value={item.value}
                onClick={() => setFontFamily(item.fontFamily)}
              >
                {item.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    </div>
  );
};

export default StoryColorFont;
