import React from 'react'
import { Search } from 'react-feather';
import { Modal, ModalHeader, ModalBody, Input } from "reactstrap"

const CountryCodeModal = ({ modal, toggle }) => {

    const images = require.context('./../Icons/flags', false, /\.(png|jpe?g)$/);
    const flags = images.keys()



    return (
        <Modal scrollable isOpen={modal} toggle={toggle} className="modal-dialog-centered" >
            <ModalHeader toggle={toggle}>Choose Country Code</ModalHeader>
            <div className="CountryCodeSearchbar p-4">
                <div className="CountryCodeSearchPannel d-flex rounded align-items-center px-2  ">
                    <Search size={18} />
                    <Input type="search" placeholder="Search country name or telecode" className="border-0 bg-transparent" />
                </div>
            </div>
            <ModalBody>
                <div className="CountryCodesList">
                    {flags.map((item, index) => (

                        < div className="TransparentBorder list-group-item mt-2 d-flex justify-content-between align-items-center rounded cursor-pointer " >
                            <div className="d-flex align-items-center login-discription">

                                <img src={images(item).default} alt="" className='img-fluid' height='20' width='28' />
                                <h5 className='ml-3 h4 Login000_400'>India</h5>
                            </div>
                            <h5 className='mb-1 h4 Login000_500'>
                                +91
                            </h5>
                        </div>
                    ))}

                </div>
            </ModalBody>
        </Modal >
    )
}

export default CountryCodeModal
