import React from 'react'
import { Button } from 'react-bootstrap'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ArchiveModal = (props) => {
    return (
        <>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.archiveModal} className='modal-dialog-centered'>
                    <ModalHeader onClick={props.archiveModal} className='d-flex justify-content-center Media_header' >
                        <h3>{props.heading}</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h5 className='py-1 name-style'>{props.discription}</h5>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center border-0 justify-content-center  modalHeading_Section '>
                        <Button variant='outline-secondary' className='w-25' onClick={props.archiveModal}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant='primary' className='w-25' onClick={props.archiveModal}>
                            <span className='ml-1'>Ok</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default ArchiveModal