import * as types from '../../Constants/UserProfile/index';
import axios from 'axios';
import {loadHobbiesByUserId} from './getUserProfileByUserIdAction';

const getAllUserSports = (userSports) => ({
    type: types.GET_USERS_SPORTS,
    payload: userSports,
});

const getAllUserMovies = (userMovies) => ({
    type: types.GET_USERS_MOVIES,
    payload: userMovies,
});

const getAllUserMusics = (userMusics) => ({
    type: types.GET_USERS_MUSICS,
    payload: userMusics,
});

const getAllUserEducations = (userEducations) => ({
    type: types.GET_USERS_EDUCATIONS,
    payload: userEducations,
});

const getAllHobies = (userHobbies) => ({
    type: types.GET_ALL_HOBBIES,
    payload: userHobbies,
});

const getAllProfession = (allProfession) => ({
    type: types.GET_ALL_PROFESSION,
    payload: allProfession,
});
const getAllLanguages = (allLanguage) => ({
    type: types.GET_ALL_LANGUAGES,
    payload: allLanguage,
});
const getAllRelationshipStatus = (allMaritalStatus) => ({
    type: types.GET_ALL_MARITAL_STATUS,
    payload: allMaritalStatus,
});

// get all user's sports
export const loadAllUserSports = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/user/getSports`, {}, {headers: { Authorization: `Bearer ${user?.token}` }})
                .then((res) => {
                    dispatch(getAllUserSports(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all user's Music
export const loadAllUserMusics = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        const config = {
            headers: { Authorization: `Bearer ${user?.token}` }
        };
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/user/getMusic`, {}, config)
                .then((res) => {
                    dispatch(getAllUserMusics(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all user's Movies
export const loadAllUserMovies = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        const config = {
            headers: { Authorization: `Bearer ${user?.token}` }
        };
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/user/getMovies`, {}, config)
                .then((res) => {
                    dispatch(getAllUserMovies(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all user's Music
export const loadAllUserEducations = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        const config = {
            headers: { Authorization: `Bearer ${user?.token}` }
        };
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/user/getUserEducation`, {}, config)
                .then((res) => {
                    dispatch(getAllUserEducations(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all user's Music
export const loadAllHobbies = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        const config = {
            headers: { Authorization: `Bearer ${user?.token}` }
        };
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/admin/getAllHobbies`, {}, config)
                .then((res) => {
                    //console.log(res.data,'hobby')
                    dispatch(getAllHobies(res.data.data.successResult.rows))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all professions
export const loadAllProfessions = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/admin/getAllProfessions`, {}, { headers: { Authorization: `Bearer ${user?.token}` } })
                .then((res) => {
                    //console.log('first dfdh dfgdfgdg -------------',res.data.data)
                    dispatch(getAllProfession(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all LANGUAGES
export const loadAllLanguages = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/admin/getAllSpeakLanguages`, {}, { headers: { Authorization: `Bearer ${user?.token}` } })
                .then((res) => {
                    dispatch(getAllLanguages(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// get all marital status
export const loadAllMaritalStatus = () => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/admin/getAllMaritalStatus`, {}, { headers: { Authorization: `Bearer ${user?.token}` } })
                .then((res) => {
                    console.log('first dfdh dfgdfgdg -------------',res.data.data)
                    dispatch(getAllRelationshipStatus(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// add user's hobbies
export const addUserHobbies = (hobbies) => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/user/addHobbies`, hobbies, { headers: { Authorization: `Bearer ${user?.token}` } })
                .then((res) => {
                   // console.log(res.data)
                    dispatch(loadHobbiesByUserId());
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};


// {{devhost}}/admin/getAllHobbies{
//     "searchKey": "",
//     "pageIndex": 0,
//     "pageSize": 10
// }
