export const SocialMessageData = [
    {
        id: 'a',
        heading: "No one ",
        subContent: "No one can send you social message "
    },
    {
        id: 'b',
        heading: "Only my Connections  ",
        subContent: "Only your connections will be able to send you Social messages"
    },
    {
        id: 'c',
        heading: "Anyone on SocioMee",
        subContent: "Anyone on SocioMee can send you Social messages"
    }
]
export const FollowData = [
    {
        id: 'a',
        heading: "No one ",
        subContent: "No one can send you social message "
    },
    {
        id: 'b',
        heading: "People you follow ",
        subContent: "Only the people you follow can follow you"
    },
    {
        id: 'c',
        heading: "Connections of your Connections",
        subContent: "Only connection of your connections can follow you"
    },
    {
        id: 'd',
        heading: "Anyone",
        subContent: "Anyone on SocioMee can follow you"
    }
]
export const ProfileSrchData = [
    {
        id: 'a',
        heading: "Off  ",
        subContent: "Your profile will not be visible on search results"
    },
    {
        id: 'b',
        heading: "On",
        subContent: "Your profile will be visible on search results"
    }
]
export const ProfileWebSrchData = [
    {
        id: 'a',
        heading: "Off  ",
        subContent: "Your profile would not be visible when searched on a browser "
    },
    {
        id: 'b',
        heading: "On",
        subContent: "Your profile will be visible when searched on a browser "
    }
]
export const ProfileGenderData = [
    {
        id: 'a',
        heading: "Off  ",
        subContent: "Your gender will not be visible to anyone"
    },
    {
        id: 'b',
        heading: "On",
        subContent: "Your gender will be visible to everyone on SocioMee"
    }
]
export const ProfileEmailData = [

    {
        id: 'a',
        heading: "No one   ",
        subContent: "No one can view your email ID "
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections will be able to see your email ID"
    },
    {
        id: 'c',
        heading: "Any one ",
        subContent: "Anyone on SocioMee can view your email ID."
    },


]
export const ProfileContactData = [
    {
        id: 'a',
        heading: "No one   ",
        subContent: "No one can view your contact number "
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections can view your contact number"
    },
    {
        id: 'c',
        heading: "Any one ",
        subContent: "Anyone on SocioMee can view your contact number"
    },
]
export const ProfileBirthDayData = [
    {
        id: 'a',
        heading: "No one   ",
        subContent: "No one can view your Birthdate  "
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections can view your birth date"
    },
    {
        id: 'c',
        heading: "Any one ",
        subContent: "Anyone on SocioMee can view your birth date"
    },
]
export const ProfileBirthYearData = [
    {
        id: 'a',
        heading: "No one   ",
        subContent: "No one can view your Birth year "
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections can view your birth year"
    },
    {
        id: 'c',
        heading: "Any one ",
        subContent: "Anyone on SocioMee can view your birth year"
    },
]
export const ProfileFollowData = [
    {
        id: 'a',
        heading: "No one   ",
        subContent: "No one can view your followers and following  "
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections can view your followers and following"
    },
    {
        id: 'c',
        heading: "Any one ",
        subContent: "Anyone on SocioMee can view your followers and following"
    },
]
export const ProfilePrivacyData = [
    {
        id: 'a',
        heading: "Public",
        subContent: "Anyone on SocioMee can view your profile and its content"
    },
    {
        id: 'b',
        heading: "Private ",
        subContent: "Only your connecitons will be able to view your profile content"
    },
]