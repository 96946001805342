import { Checkbox } from '@mui/material';
import React from 'react'
import { Fragment } from 'react'
import { PostStoriesCommentData } from './NotificationArrayData'

const PostStoriesComment = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    //================== Main_return_function =============//
    return (
        <Fragment>
            <div className='SettingPrivacy_Card_Body'>
                {PostStoriesCommentData.map((data) => (
                    <div className='SettingPrivacy_Content_Section'>
                        <div className='SettingPrivacy_SubContent_Section '>
                            <div>
                                <h4>{data.heading}</h4>
                                <p>{data.subContent}</p>
                            </div>
                            <div>
                                {data.id === 'toggle' ?
                                    <Fragment>
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </Fragment> :
                                    <Fragment>
                                        <Checkbox {...label} defaultChecked className='Check_Input_section' />
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default PostStoriesComment