
import React, { useState } from 'react'
import { AlertTriangle, Circle, Eye, Globe, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Button, Col, Row } from 'reactstrap'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import AvatarGroupComponent from '../../Search-Components/AvatarGroupComponent'
// import cover from '../../../NewComponents/IMG/cover.png'
// import cover1 from '../../../NewComponents/IMG/cover1.png'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ReportModal from './ModalGrid/ReportModal'
import { topGroupsData } from '../DummyArray'


const GridGroupTab = () => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // ----------------------------------------------------------------Main return function----------------------------------------------------------------
    return (
        <div>
            <Row className='p-2'>
                {
                    topGroupsData.map((data, index) => (

                        // <Col lg='3' className='p-1'>
                        //     <div className='border roundedThisBorder' key={index}>
                        //         <div className='d-flex pb-2 px-2 mt-2'>
                        //             <Badge color='primary' pill className=''>
                        //                 Top #{data.topsNumber}
                        //             </Badge>
                        //         </div>
                        //         <div className="group-slide-box">
                        //             <div className="gallery-img-sublink-this-bizpage">
                        //                 <UncontrolledButtonDropdown className=''>
                        //                     <DropdownToggle color='flat' >
                        //                         <MoreHorizontal color='#FFFFFF' />
                        //                     </DropdownToggle>
                        //                     <DropdownMenu className='bizpagefordropdownmenu'>
                        //                         <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                        //                         <DropdownItem divider></DropdownItem>
                        //                         <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{color: '#212529'}} ><Eye size={13} />&nbsp;View Profile </NavLink></DropdownItem>
                        //                         <DropdownItem divider></DropdownItem>
                        //                         <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                        //                         <DropdownItem divider></DropdownItem>
                        //                         <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                        //                     </DropdownMenu>
                        //                 </UncontrolledButtonDropdown>
                        //             </div>
                        //             <NavLink className=''>
                        //                 <img src='https://img.freepik.com/free-photo/happy-excited-friends-reunited_23-2149165139.jpg?w=1380&t=st=1675509362~exp=1675509962~hmac=ba24dcfbce5168d61ce770b613b0e06eb2c2c5a1c66be811c07fc98df9d4fc74' alt='linkimg' className="w-100 BizpageThisimg" />
                        //                 <div className=''>
                        //                     <p className='grouptittlecenter'>Uptown Area</p>
                        //                 </div>
                        //                 <div className='d-flex justify-content-center align-items-center mt-2'>
                        //                     <p className='mr-1 align-self-center'><Globe size={16} color='#4D4D4D' /></p>
                        //                     <p className='text-dark'>Public Group<Circle fill='#C4C4C4' className='mx-2' size={6} strokeWidth={0} />Sport</p>
                        //                 </div>
                        //                 <div className="d-flex justify-content-center  mt-2">
                        //                     <AvatarGroupComponent className='align-self-center' />
                        //                     <p className='text-secondary align-self-center'><h6> +79k Members</h6></p>
                        //                     <h6 className='text-secondary align-self-center'> +79k Members</h6>
                        //                 </div>
                        //                 <div className='d-flex justify-content-center mt-3 p-2'>
                        //                     {data.button}
                        //                     &nbsp;
                        //                     {data.button1}
                        //                 </div>
                        //             </NavLink>

                        //         </div>
                        //     </div>
                        // </Col>

                        <Col lg='4' xl='3' className='p-1' key={index} >
                            <div className='roundedThisBorder border on-hover-bg ' >
                                <div className='mb-4' >
                                    <div className='' style={{ position: 'relative' }} >
                                        <img src={data.groupImg} alt='linkimg' className="w-100 BizpageThisimg " />
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center simple-linear py-2 mr-0 px-2' style={{ position: 'absolute', top: '2px', left: '5px', right: '5px', borderRadius: "5px 5px 0 0" }} >
                                        <div className='d-flex'>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='primary' pill className=' px-2 d-flex align-items-center light-font' style={{ color: 'white', backgroundColor: '#81C14B' }}>
                                                    Top #{data.topsNumber}
                                                </Badge>
                                            </NavLink>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='light-primary' className='px-2 ml-2 d-flex align-items-center light-font' style={{ color: 'white', backgroundColor: '#81C14B' }}>
                                                    Trending #{data.trendingNumber}
                                                </Badge>
                                            </NavLink>
                                        </div>
                                        <div>
                                            <UncontrolledButtonDropdown className='' >
                                                <DropdownToggle color='flat' style={{ padding: '0', margin: '0' }} >
                                                    <MoreHorizontal color='#ffffff' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share Group</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;View Profile </NavLink></DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='text-center my-2' >
                                            <NavLink to='/Group' style={{ color: 'black' }} >
                                                <h4 className='user-profile'>German Weddings</h4>
                                            </NavLink>
                                            <p className='text-muted' >Lifestyle</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center mt-1' >
                                            <div className='d-flex align-items-center'>
                                                <Globe size={18} className='mr-2' color='#4D4D4D' />
                                                <p>Public</p>
                                                <Circle fill='#c4c4c4' size={8} strokeWidth={0} className='mx-3' />
                                            </div>
                                            <div>
                                                78.9k members
                                            </div>
                                        </div>
                                        <div>
                                            <p className='px-3 pt-3 text-center pb-3 text-muted' >{'Learn how to master your skills  and create a hustle such that you neend not to ask for money'.slice(0, 60) + '...'}</p>
                                        </div>

                                        <div className='mx-3 d-flex align-items-end' >
                                            {data.button}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>

            {/* ----------------------------------------------------------------Modal component start---------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* ----------------------------------------------------------------Modal component End---------------------------------------------------------------- */}

        </div>
    )
}

export default GridGroupTab