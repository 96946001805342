import { Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Filter } from "react-feather";
import pro_mutual from "../../../NewComponents/IMG/pro_mutual.png";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { loadAllUserSciomate } from "../../../Services/Actions/Connection/getSociomateAction";
import { loadAllUserFollowing } from "../../../Services/Actions/Connection/getUserFollowingAction";
import { useDispatch, useSelector } from "react-redux";
const ConnectionShareModal = (props) => {
  const dispatch = useDispatch();

  const { getUserSociomate } = useSelector((state) => state.userSociomateData);

  const { getUserFollowing } = useSelector(
    (state) => state.GetUserFollowingData
  );
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [selectedFilter, setSelectedFilter] = useState("SocioMate");

  const handleDropdownChange = (filter) => {
    setSelectedFilter(filter);
  };

  //   search item
  const [searchItem, setSearchItem] = useState("");

  const filteredData1 = getUserSociomate?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase())
  );

  const filteredData2 = getUserFollowing?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase())
  );

  // Rest is here ==============

  const fetchFollowingData = () => {
    // Dispatch action to fetch Following data
    // dispatch(loadAllUserFollowing());
  };

  const fetchSocioMateData = () => {
    // Dispatch action to fetch SocioMate data
    // dispatch(loadAllUserSciomate());
  };

  useEffect(() => {
    dispatch(loadAllUserSciomate(selectedFilter));
  }, [selectedFilter]);

  // useEffect(() => {
  //   dispatch(loadAllUserSciomate());
  // }, []);

  return (
    <>
      {props.openModal !== false && (
        <Modal
          isOpen={props.openModal}
          toggle={props.shareModalOpen}
          className="modal-dialog-centered"
        >
          <div className="w-100 border-bottom">
            <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
              <h4>Share </h4>
              <span
                style={{ cursor: "pointer" }}
                onClick={props.shareModalOpen}
              >
                Done
              </span>
            </div>
          </div>
          <ModalBody>
            <Row className="mt-2">
              <Col md="8" className="Like_search">
                <div className="profile-search-blk w-100 ">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="search"
                    placeholder="Search..."
                    data-bs-toggle="modal"
                    data-bs-target="#peopleSearch"
                    value={searchItem}
                    onChange={(e) => setSearchItem(e.target.value)}
                  />
                </div>
              </Col>
              <Col md="4" className="d-flex justify-content-end">
                <UncontrolledButtonDropdown
                  style={{ backgroundColor: "transparent " }}
                  className="p-0 m-0"
                >
                  <DropdownToggle className="d-flex m-0 p-0 bg-light-dropdown-toggle">
                    <Button variant="outline-primary">
                      <Filter size={18} />
                      &nbsp;&nbsp;Filter
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu className="DropDown_Group_item">
                    <DropdownItem
                      className={`border-bottom py-1 shotz-menu-hover-style ${
                        selectedFilter === "SocioMate" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleDropdownChange("SocioMate");
                        fetchSocioMateData();
                      }}
                    >
                      SocioMate
                    </DropdownItem>
                    <DropdownItem
                      className={`border-bottom py-1 shotz-menu-hover-style ${
                        selectedFilter === "Following" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleDropdownChange("Following");
                        fetchFollowingData();
                      }}
                    >
                      Following
                    </DropdownItem>
                    <DropdownItem className="border-bottom py-1 shotz-menu-hover-style">
                      Colleagues
                    </DropdownItem>
                    <DropdownItem className="border-bottom py-1 shotz-menu-hover-style">
                      Family
                    </DropdownItem>
                    <DropdownItem className="border-bottom py-1 shotz-menu-hover-style">
                      Relatives
                    </DropdownItem>
                    <DropdownItem className="py-1 shotz-menu-hover-style">
                      Work
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            </Row>
            <Row className="mt-3">
              {selectedFilter === "SocioMate" && (
                <div>
                  <Col md="6" className="SubHeading_Invite_Section">
                    <h4>SocioMate ({filteredData1?.length})</h4>
                  </Col>
                  <br />
                  {filteredData1?.map((data, index) => (
                    <Fragment>
                      <div className="group-detail-page-blk" key={index}>
                        <div className="Follower-memberslist-block">
                          <div className="group-member-img-cont-blk">
                            <div>
                              <img
                                src={data.profileImage}
                                height="40px"
                                width="40px"
                                className="img-luid rounded-circle"
                                alt="..."
                              />
                            </div>
                            <div className="member-cont-blk">
                              <h4>{data.fullName}</h4>
                              <p className="text-muted">{data.join}</p>
                              <div className="Post_Count">
                                {data.userName || "username"}
                              </div>
                            </div>
                          </div>
                          <div className="Block-btns-blk">
                            <Checkbox {...label} />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}

              {/* Add similar blocks for other conditions */}

              {selectedFilter === "Following" && (
                <div>
                  <Col md="6" className="SubHeading_Invite_Section">
                    <h4>Following ({filteredData2?.length})</h4>
                  </Col>
                  <br />
                  {filteredData2?.map((data, index) => (
                    <Fragment>
                      <div className="group-detail-page-blk" key={index}>
                        <div className="Follower-memberslist-block">
                          <div className="group-member-img-cont-blk">
                            <div>
                              <img
                                src={data.profileImage}
                                height="40px"
                                width="40px"
                                className="img-luid rounded-circle"
                                alt="..."
                              />
                            </div>
                            <div className="member-cont-blk">
                              <h4>{data.fullName}</h4>
                              <p className="text-muted">{data.join}</p>
                              <div className="Post_Count">
                                {data.userName || "username"}
                              </div>
                            </div>
                          </div>
                          <div className="Block-btns-blk">
                            <Checkbox {...label} />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}

              {/* Add similar blocks for other conditions */}
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ConnectionShareModal;
