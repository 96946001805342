import React from 'react'
import { Fragment } from 'react'
import { UploadCloud } from 'react-feather'
import { Link } from 'react-router-dom'
import { Button, FormGroup, Input, Label } from 'reactstrap'

const ReportProblem = () => {
    return (
        <Fragment>
            <div className='SettingPrivacy_Main_section'>
                <div className='hide-setting-options'>
                    <h3>Report a Problem</h3>
                </div>
                <FormGroup>
                    <Label className='my-1 ReportProblem_label ' >
                        <h3>Please explain what happened and how should we resolve the issue </h3>
                    </Label>
                    <Input type='textarea'
                        cols="15"
                        rows="5"
                        placeholder="Type your reasion (optional)..."
                        className="Report_Input_section"
                    />
                    <p className='text-end mt-1 Report_text_Validation'  >Max 360 characters </p>
                </FormGroup>
                <FormGroup>
                    <Label className="Biz_Rating_sab_section">
                        <h3>Upload Screenshot</h3>
                    </Label>

                    <div className="profile-cover profile-cover-new mt-3">
                        <a href="#" className="btn btn-ReportProblem  btn-group btn-grp-new">
                            <ul>
                                <li className="choose-file">
                                    <a className='UploadBtn' href="">
                                        <UploadCloud size={14} />&nbsp;
                                        Upload
                                    </a>
                                    <input type="file"
                                    // onChange={props.changeHandle}
                                    />
                                </li>
                            </ul>
                        </a>
                    </div>
                    <hr className='text-muted' />
                    <div className='Report_Desclimer_section'>
                        <h3>
                            Disclaimer : Learn about how your data will be used<br />Please check our <span> <a href="https://www.sociomee.com/other-terms-and-policies/" target="_blank">Data Policy </a></span>
                        </h3>
                    </div>
                    <div className='Report_Problem_submit'>
                        <Button color="primary" className="ReportSumit_btn" >Submit</Button>
                    </div>
                </FormGroup>
            </div>
        </Fragment>
    )
}

export default ReportProblem