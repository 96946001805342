import React, { useState,useEffect } from 'react'
import { CornerUpRight, MapPin, Send } from 'react-feather'
import EventsImg from '../../NewComponents/IMG/BizImg.png'
import { Button, UncontrolledTooltip } from 'reactstrap'
import axios from 'axios'
import { Link } from 'react-router-dom';

const Event = () => {
    const [eventsHappening, setEventsHappening] = useState([]);
    const [currentEventIndex, setCurrentEventIndex] = useState(0);
// console.log("happening event ",eventsHappening)

    

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/post/upcomingEvents`,
                    {
                        day: "all",
                        categoryId: "",
                        eventLocation: "",
                        lat: "",
                        long: "",
                        radius: "",
                        postIds: []
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                        },
                    }
                );
    
                if (response.data.data.successResult) {
                    setEventsHappening(response.data.data.successResult.thisWeek);
                    // console.log("Upcoming events:", response.data.data.successResult);
                } else {
                    console.log("No upcoming events found.");
                }
            } catch (error) {
                console.error("Error fetching upcoming events:", error);
            }
        };
    
        fetchUpcomingEvents();
    }, []);
    const handleNextEventClick = async(eventId,status) => {
        // Move to the next event
        console.log("on eventChange ",eventId, " ",status)
        setCurrentEventIndex((prevIndex) => (prevIndex + 1) % eventsHappening.length);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/post/changeEventStatus`,
                {
                    id:eventId,
                    status:status
                },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                    },
                }
            );

        } catch (error) {
            console.error("Error fetching upcoming events:", error);
        }


      };
      if (eventsHappening.length === 0) {
        return <div>No upcoming events found.</div>;
      }
      const currentEvent = eventsHappening[currentEventIndex];
    
    let data = ("B1, Bara Chandganj, Sector A, Lucknow, UP 226024")
    //======================== Main_return_function =========================//
    return (
        <div className="event-box section-b-space ratio2_3 shadow-sm groupBottom-custom-block">
            <div className="image-section" >
                <img src={currentEvent.eventCoverImageUrl} className="img-fluid bg-img Events_Img_CardBlk" alt="/event"/>
                <div className="card-title">
                    <h3 style={{ fontWeight: "300" }}>event</h3>
                    <div className='settings SeeAllEventButton' style={{ cursor: "pointer" }}>
                        <h5 style={{ fontSize: "12px", fontWeight: "500" }}><Link to="/Events" >See All</Link></h5>
                    </div>
                </div>
                <div className=" Events_buttom_section ">
                    <div className='d-flex align-items-start'>
                        <MapPin size={22} fill='#FFFFFF' />
                        {data.length ? <h3 className='ml-2 eventTextByText text-wrap' style={{ fontSize: "14px" }} >{currentEvent.eventAddress} <span style={{ cursor: "pointer", }} id="positionTop">...</span></h3> : "...."}
                        <UncontrolledTooltip placement='top' target='positionTop' > Regency Rd, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh 226010</UncontrolledTooltip>

                    </div>
                    <div className='Icon_Blk_section'>
                        <CornerUpRight size={22} color='#FFFFFF' />
                    </div>
                </div>
            </div>
            <div className="event-content text-left p-2 py-3 ml-2">
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fontForSubCardheading'>
                        <h4 style={{ fontSize: "16px", fontWeight: "500", color: " #4d4d4d" }}>{currentEvent.name}</h4>
                        <h5 style={{ color: '#808080', fontWeight: '400', lineHeight: '25px', fontSize: "14px" }} >26 january 2021</h5>
                    </div>
                </div>
            </div>
            <div className='border-top fontForSubchildCardheading p-2 pt-3 ml-2'>
                <h4 style={{ color: "#4d4d4d" }}>{currentEvent.userGoingStatus} People Going</h4>
                <div className='py-3'>
                    <Button color='primary py-2 Events_Going_card_btn' className=" FollowRequest_Reject_btn"  onClick={()=>handleNextEventClick(currentEvent.postId,"going")} >Going</Button>
                    <Button color='primary ml-3 py-2' className="FollowRequest_Accept_btn" onClick={()=>handleNextEventClick(currentEvent.postId,"notInterested")}>Not Interested</Button>
                </div>
            </div>
        </div>
    )
}

export default Event
