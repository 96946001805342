import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { RolesPageData } from './SettingArray'
import { Button } from 'reactstrap'
import PageAssignModal from '../AllTypesModal/PageAssignModal'
import UpdateAssignModal from '../AllTypesModal/UpdateAssignModal'
import { getBizPageAction, removePageRoleFromBizPageAction, getBizPageFollowerListsAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BizProfile from '../Img/BizProfile.png'
import BizProfile1 from '../Img/BizProfile1.png'



const BussinessPageRoleSetting = (props) => {

    let dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getBizPageAction(id))
        dispatch(getBizPageFollowerListsAction('Connections'))
    }, []);




    const getBizPageRole = useSelector((state) => { return state.getBizPageData });
    const { loading: loadingPageRole, error: errorPageRole, result: resultPageRole } = getBizPageRole

    const getBizPageConnections = useSelector((state) => { return state.getBizPageFollowerListsData });
    const { loading: loadingGetBizPageConnections, error: errorGetBizPageConnections, result: resultGetBizPageConnections } = getBizPageConnections

    const removeSuccesss = useSelector((state) => { return state.removePageRoleFromBizPageData });
    const { loading: loadingremoveSuccesss, error: errorremoveSuccesss, result: resultremoveSuccesss } = removeSuccesss


    useEffect(() => {
        dispatch(getBizPageAction(id))
    }, [resultremoveSuccesss]);

    const extractedData = resultPageRole?.map(({ user, role, createdAt }) => ({
        fullName: user.fullName,
        userId: user.id,
        role,
        profileImage: user.profileImage,
        createdAt,
    }));

    const [showFullName, setShowFullName] = useState(false);
    const [userById, setUserById] = useState(false);
    const [photoById, setPhotoById] = useState(false);
    const [fullNameById, setFullNameById] = useState(false);

    const handleClick = () => {
        setShowFullName(!showFullName);
    };

    const handleRemoveRole = (userId) => {
        dispatch(removePageRoleFromBizPageAction(id, userId))
        dispatch(getBizPageAction(id))
    };

    const shortenFullName = (data) => {
        console.log("Data:", data);
        if (data?.fullName?.length > 15) {
            return data?.fullName?.slice(0, 15) + "...";
        } else {
            return data.fullName;
        }
    };


    const [bizAssignRole, setBizAssignRole] = useState(false)
    const toggleAssign = () => {
        setBizAssignRole((preState) => !preState)
    }
    const [selectAssign, setSelectAssign] = useState(false)
    const toggleSelectAssign = (id, photo, fullName) => {
        setUserById(id)
        setPhotoById(photo)
        setFullNameById(fullName)
        toggleAssign(true)
        setSelectAssign((preState) => !preState)
    }
    const [open, setOpen] = useState('1');
    const toggleOpen = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };


    const BizFollowOption = [
       
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Admin"
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Admin"
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Admin"
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Admin"
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Admin"
        }
    ]
    const BizFollowOption1 = [
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Owner"
        },

    ]
    return (
        <Fragment>
            <div className="custom-card-block">
                <div className="customAbout-card-head biz-carddetail-blk" onClick={() => { props.setsetsideclassoption(false); }} style={{cursor:"pointer"}} >
                    <h3> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='hide-setting-options12' style={{marginBottom:"2px"}}>
                        <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                    </svg>Business page Roles </h3>
                    <Button onClick={toggleAssign} color="primary" outline>Assign a page role</Button>
                </div>
                <div className="">
                    {/* {
                        extractedData?.map((data) => (

                            <div className="bizPage_pageRole-blk" >
                                <div className='d-flex align-items-start' >
                                    <img src={data.profileImage} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                    <div className='ml-2 BizProfile_section' >
                                    <h4 onClick={handleClick}>
                                        {showFullName ? data?.fullName : shortenFullName(data?.fullName)}
                                        {data?.fullName?.length > 15 && (
                                        <span style={{cursor: "pointer" }}>
                                            {showFullName ? " less" : data.fullName.slice(0, 15)}
                                        </span>
                                        
                                        )}
                                        {data?.fullName}
                                    </h4>
                                        <h5 >{data.role}</h5>
                                        <p>{data.createdAt}</p>
                                    </div>
                                </div>
                                <div>
                                    {data.role === "superAdmin" ?
                                        ''
                                        : <Button color='danger' onClick={() => handleRemoveRole(data.userId)}  >Remove </Button> 
                                    }

                                </div>
                            </div>
                        ))
                    } */}

                    {
                        BizFollowOption1.map((data) => (

                            <div className="biz-Follow-blk " >
                                <div className='d-flex' >
                                    <img src={data.img} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                    <div className='ml-2 BizProfile_section blockhead ' >
                                        <h4>Cuurent People</h4>
                                        <p className='text-muted text-wrap '>{data.status}</p>
                                        {/* <p className='text-muted' >{data.id}</p> */}
                                    </div>
                                </div>

                                <p className='text-muted text-wrap admintesxt'>{data.status1}</p>
                               
                            </div>
                        ))
                    }
                    {
                        BizFollowOption.map((data) => ( 

                            <div className="biz-Follow-blk " >
                                <div className='d-flex' >
                                    <img src={data.img} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                    <div className='ml-2 BizProfile_section blockhead ' >
                                        <h4>Cuurent People</h4>
                                        <p className='text-muted text-wrap'>{data.status}</p>
                                        {/* <p className='text-muted' >{data.id}</p> */}
                                    </div>
                                </div>
                               
                                <p className='text-muted text-wrap admintesxt'>{data.status1}</p>
                             
                              
                                    <Button
                                        //  onClick={toggleRemove}
                                        color='danger' outline>Remove </Button>
                              
                            </div>
                            
                            
                        ))
                    }


                </div>
            </div>
            <PageAssignModal
                isOpen={bizAssignRole}
                updateOpen={toggleAssign}
                selectAssignState={toggleSelectAssign}
                resultGetBizPageConnections={resultGetBizPageConnections}
            />
            <UpdateAssignModal
                isOpen={selectAssign}
                updateOpen={toggleSelectAssign}
                selectAssignState={toggleSelectAssign}
                AccordionOpen={open}
                AccordionUpdate={toggleOpen}
                userById={userById}
                photoById={photoById}
                fullNameById={fullNameById}
            />
        </Fragment>
    )
}

export default BussinessPageRoleSetting