import React from 'react'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import play from '../../../NewComponents/IMG/play.png'
// import Pollimg from '../../../NewComponents/IMG/Pollimg.png'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
// import LikeImg from '../../../NewComponents/IMG/LikeImg.svg'
// import tagpic2 from '../../../NewComponents/IMG/tagpic2.png'
import { useState } from 'react'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import { cardDataTrendingMedia } from '../DummyArray'
import ReportModal from './ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import play from '../../../NewComponents/IMG/play.png'



const GridCompoentVideo = () => {


    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    // const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    // ------------------------------Main return function-----------------------------
    return (
        <div className='p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
            <Row className='PeopleThisGrid'>
                {/*-----------------------------------------------------Dynamic card data rendering for trending videos----------------------------------------------------- */}
                {cardDataTrendingMedia.map((data, index) => (
                    <Col lg='3' md='4' className='w-100 p-1' key={index} >
                        <div className='Video_Treanding_card on-hover-bg media_card h-100'>
                            <div className='d-flex pb-2'>
                                <NavLink to='/MyProfile'> <Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                    Trending #{data.trendingNumber}
                                </Badge></NavLink>
                                <NavLink to='/MyProfile'>
                                    <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                        Top #{data.topsNumber}
                                    </Badge>
                                </NavLink>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center '>
                                    <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                    <div className='ml-2 '>
                                        <NavLink to='/MyProfile' style={{ color: 'black' }}  ><h4 className='user-profile' >{data.name}</h4> </NavLink>
                                        <small className='text-muted light-for-date'>{data.dateandtime}</small>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown className='align-self-center'style={{marginTop:'-26px'}} >
                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                        <MoreHorizontal size={19} color='#999999' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>

                                        {/* <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>{request.fullName?.substring(0, 21) || 'name'}</h4></NavLink> */}

                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Media</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} > <Eye size={13} />&nbsp;&nbsp;View creator's profile</NavLink></DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* <p className='mt-1'>more user-centric and conv...{seemore ? 'by their very nature, are about connections. Through your' : null} bright event description, you can boost attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p> */}
                            <p>{data.category1}</p>
                            {
                                index === 1 ? "" : < p className='mt-1 text-muted user-profile' data-bs-toggle="modal" data-bs-target="#imageModel">{'When Earth becomes uninhabitable in the future, a farmer and ex-NASA, Cooper, is tasked to pilot a spacecraft, along with a team of researchers, to find a new planet for humans.'.slice(0, 80) + '...'}</p>
                            }

                            <div className='Potrate_treanding_img pt-2' data-bs-toggle="modal" data-bs-target="#imageModel" style={{ cursor: 'pointer' }} >
                                <img src={data.imgUse} alt='img' className='img-fluid' />
                                {data.video ? <div className='MediaThisVideo' style={{ top: '40%', left: '40%' }}>
                                    <img src={play} className='play' alt='play' />
                                </div> : null}
                                <div className='Treanding_caption_section'>{data.caption}</div>
                            </div>
                            <div className='Treanding_bottom_section'>
                                <div className='d-flex align-items-center'>
                                    <div onClick={() => handleLike()}>
                                        {liked ?
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                            :
                                            <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                        }
                                    </div>
                                    &nbsp;
                                    11.2k
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                    </div>
                                    11.2k
                                </div>
                                <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                    </div>
                                    11.2k
                                </div>

                            </div>

                        </div>
                    </Col>
                ))}

                {/* <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <p className='mt-1'>more user-centric and conv...{seemore ? 'by their very nature, are about connections. Through your' : null} bright event description, you can boost attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://media.istockphoto.com/id/1048960030/photo/fishing-boat-in-the-sea-reflection-in-the-water.jpg?s=170667a&w=0&k=20&c=m4fAPdH0wevD2AEmCoMjHlOgswfqYmw6x_DQdK-BJOE=' alt='img' className='img-fluid' />
                            <div className='Treanding_caption_section'>This is caption text</div>
                            <div className='TagThisTopVideo' role='button'>
                                <img src={play} className='play' alt='play' />
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col>
                <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <p className='mt-1'>more user-centric and conv...{seemore ? 'by their very nature, are about connections. Through your' : null} bright event description, you can boost attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://media.istockphoto.com/id/1048960030/photo/fishing-boat-in-the-sea-reflection-in-the-water.jpg?s=170667a&w=0&k=20&c=m4fAPdH0wevD2AEmCoMjHlOgswfqYmw6x_DQdK-BJOE=' alt='img' className='img-fluid' />
                            <div className='Treanding_caption_section'>This is caption text</div>

                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col>
                <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted' >19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <p className='mt-1'>more user-centric and conv...{seemore ? 'by their very nature, are about connections. Through your' : null} bright event description, you can boost attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://media.istockphoto.com/id/1048960030/photo/fishing-boat-in-the-sea-reflection-in-the-water.jpg?s=170667a&w=0&k=20&c=m4fAPdH0wevD2AEmCoMjHlOgswfqYmw6x_DQdK-BJOE=' alt='img' className='img-fluid' />
                            <div className='Treanding_caption_section'>This is caption text</div>
                            <div className='TagThisTopVideo' role='button'>
                                <img src={play} className='play' alt='play' />
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col> */}

            </Row>

            {/*-----------------------------------------------------Modal component start----------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/*-----------------------------------------------------Modal component end----------------------------------------------------- */}

        </div >
    )
}

export default GridCompoentVideo