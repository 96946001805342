import React, { useState } from 'react'
import { Grid, List } from 'react-feather'
import { Button } from 'reactstrap'
import GridGroup from './Grid-Components/GridGroup'
import ListGroup from './List-Components/ListGroup'

const GroupTabsTrending = () => {
    const [data, setdata] = useState(true)
    return (
        <div className=''>
            <div className='d-flex justify-content-between  align-items-center'>
                <p className='TrendingPeoplethistext'></p>
                <div className='list-grid-toggle rem-mob-view rem-beyond-web' style={{marginRight:'8px'}}>
                    <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                        <Grid size={16} />
                    </Button>
                    <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
                        <List size={16} />
                    </Button>
                </div>
            </div>
            {
                data ?
                    <GridGroup />
                    :
                    <ListGroup space={'pl-3'} />
            }
        </div>

    )
}
export default GroupTabsTrending