// import React, { Fragment, useEffect } from 'react'
// import { useState } from 'react'
// import { Circle, MapPin } from 'react-feather'
// import { Button ,Row,NavLink,NavItem,Col,Nav,Modal,ModalBody,TabPane,TabContent} from 'reactstrap'
// import AvatarGroup from '../../../NewComponents/AvatarGroup'
// import VistorMamberModal from './TopBannerModal/VistorMamberModal'
// import ProfileBiz from '../Img/ProfileBiz.png'
// import ProfilePictureModal from './TopBannerModal/ProfilePictureModal'

// import SetUpActionModal from '../AllTypesModal/SetUpActionModal'
// import BannerCarousel from './BannerCarousel'
// import CarouselBanner from './CarouselBanner'
// import BizAvatar from '../BizAvatar'
// import { useDispatch, useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'
// import moment from 'moment';
// import {getAboutUsAction, getAllUserOwnPinnedBizPageReducerAction, getBizPageRatingSummaryAction, getBizPageReviewAction} 
// from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';

// const TopBanner = () => {
//     let dispatch = useDispatch();
//     const { id } = useParams();
//     const getAbout = useSelector((state) => { return state.getAboutUsData});
//     const { loading: loadingGetAbout, error: errorGetAbout, result: resultGetAbout} = getAbout 

//     const getReviews = useSelector((state) => { return state.getBizPageRatingSummaryData});
//     const { loading: loadingresultGetReviews, error: errorresultGetReviews, result: resultGetReviews} = getReviews 

//     // const getCataloguo = useSelector((state) => { return state.getBizPageReviewData});
//     // const { loading: loadinggetReview, error: errorgetReview, result: resultgetReview } = getCataloguo
//     const [active, setActive] = useState('1');
//     const toggle = (tab) => {
//         if (active !== tab) {
//             setActive(tab);
//         }
//     };
//     const MemberData = [
//         {
//             name: 'John Doe',
//             img: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1',
//             joingData: 'Joined',
//             startDate: '2023-08-25', // Replace with the actual date in 'YYYY-MM-DD' format
//             userType: 'Admin',
//         },
//         {
//             name: 'Jane Smith',
//             img: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1',
//             joingData: 'Joined',
//             startDate: '2023-08-27', // Replace with the actual date in 'YYYY-MM-DD' format
//             userType: 'Member',
//         },
//         // Add more member data objects as needed
//     ];


//     useEffect(() => {
//         dispatch(getAllUserOwnPinnedBizPageReducerAction()) 
//     }, []);

//     useEffect(() => {
//         // dispatch(getBizPageReviewAction("dd1bedf0-2011-46df-991c-fce411b1617d")) 
//         dispatch(getBizPageRatingSummaryAction(id)) 
//         // dispatch(getAllQuestionAndAnswerAction("744d8503-e808-40d5-b3b0-759244d803ae")) 
//     }, []);


//     const getPinnedBusinessPage = useSelector((state) => { return state.getAllUserOwnPinnedBizPageData });
//     const { loading: loadingPinnedPage, error: errorPinnedPage, result: resultPinnedPage } = getPinnedBusinessPage

//     const bizPageById = resultPinnedPage?.filter(item => item?.id?.includes(id));
//     console.log(bizPageById);


//     const [memberOpen, setMemberOpen] = useState(false)
//     const toggleMember = () => {
//         setMemberOpen((preView) => !preView)
//     }

//     const [profilePicture, setProfilePicture] = useState(false)
//     const togglePicture = () => {
//         setProfilePicture((preView) => !preView)
//     }
//     const [coverPicture, setCoverPicture] = useState(false)
//     const toggleCover = () => {
//         setCoverPicture((preView) => !preView)
//     }

//     const [actionSetUp, setActionSetUp] = useState(false)

//     const toggleSetUp = () => {
//         setActionSetUp((preview) => !preview)
//     }

//     useEffect(() => {
//         dispatch(getAboutUsAction(id)) 
//     }, []);

//     //=================== Main_return_function ==========================//

//     return (
//         <Fragment>
//             <div className="group-details-top biz-details-top">
//                 <div onClick={toggleCover} className="gd-top-block"
//                     style={{ background: "url(assets/images/bizcover.jpg)" }}
//                 >

//                     <div className="gd-top-btns">
//                         <a href="#" className="grey-btn"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> Invite People</a>
//                         <a href="#" className="grey-btn"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> Edit </a>
//                     </div>
//                 </div>

//                 <div className='BizCover-top-block' >
//                     <CarouselBanner />
//                     <div className="Bizgd-top-btns">
//                         <div className="profile-cover profile-cover-new">
//                             <a href="#" className="btn Bizbtn-solidUpload btn-group btn-grp-new">
//                                 <ul>
//                                     <li className="choose-file">
//                                         <a className=' grey-btn' href="">
//                                             <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> Edit
//                                         </a>
//                                         <input type="file" />
//                                     </li>
//                                 </ul>
//                             </a>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="heading-title-blk head-title-biz">
//                     <div className="row align-items-center">
//                         <div className="col-xl-4 col-lg-4 col-12 order-lg-2">
//                             <div className="biz-user-pro-blk">
//                                 {/* <img src={bizPageById?.[0].logo ? bizPageById?.[0].logo :''} style={{border:'4px solid white' }} alt='profile img' className='rounded-circle Biz_TopBanner_Profile' onClick={togglePicture} /> */}


//                                 <h5>{resultGetAbout?.[0].name ? resultGetAbout?.[0].name : ''}</h5>
//                                 <div>
//                                     <p ><MapPin size={14} color="#81C14B" />{resultGetAbout?.[0].bpLocations?.[0].address} {resultGetAbout?.[0].bpLocations?.[0].city}</p>
//                                 </div>

//                             </div>
//                         </div>
//                         <div className="col-xl-4 col-lg-4 col-12 order-lg-1">
//                             <div className="gd-content-area-top-blk">
//                                 <div className="groupmember-blk Biz_top_groupmember-blk ">
//                                     <span> Entertainment </span>&nbsp;<Circle fill='#C4C4C4' size={7} />&nbsp;<span>Magic</span>
//                                 </div>
//                                 <div className="people-likes matual-friend-sec" >
//                                     <BizAvatar />
//                                     {/* <h6 onClick={toggleMember} style={{ cursor: "pointer" }} >{bizPageById?.[0].visitorCount} Visited today</h6> */}
//                                 </div>
//                                 <div className="people-likes matual-friend-sec" >
//                                     <BizAvatar />
//                                     <h6 onClick={toggleMember} style={{ cursor: "pointer" }} >{resultGetAbout?.[0].visibility}k members</h6>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-xl-4 col-lg-4 col-12 order-lg-3">
//                             <ul className="Biz_followlist-blk">
//                                 <li>{resultGetReviews?.[0].averageRating}/5<span>Rating</span></li>
//                                 <li>0<span>Posts</span></li>
//                                 <li>0<span>Followers</span></li>
//                             </ul>
//                             <div className="create-biz-btn light-green">
//                                 <Button
//                                     outline
//                                     color="primary"
//                                     onClick={toggleSetUp}
//                                 >
//                                     Setup action Button
//                                 </Button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='Members_Visited_data_Modal'>
//                     {
//                         memberOpen !== false &&
//                         <Modal scrollable isOpen={memberOpen} toggle={toggleMember} className='modal-dialog-centered'>
//                             <Row className="Member_Tab_heading_section">
//                                 <Nav>
//                                     <Col md={6}>
//                                         <NavItem>
//                                             <NavLink
//                                                 active={active === '1'}
//                                                 onClick={() => {
//                                                     toggle('1')
//                                                 }}
//                                                 className="Member_link"
//                                             >
//                                                 Members  (70)
//                                             </NavLink>
//                                         </NavItem>
//                                     </Col>
//                                     <Col md={6}>
//                                         <NavItem>
//                                             <NavLink
//                                                 active={active === '2'}
//                                                 onClick={() => {
//                                                     toggle('2')
//                                                 }}
//                                                 className="Member_link"
//                                             >
//                                                 Visited today (40)
//                                             </NavLink>
//                                         </NavItem>
//                                     </Col>
//                                 </Nav>
//                             </Row>
//                             <ModalBody>
//                                 <div className="right-setting pb-3 ">
//                                     <div className="profile-search-blk  w-100 ">
//                                         <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
//                                         <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
//                                     </div>
//                                 </div>
//                                 <Row>
//                                     <TabContent className='py-50' activeTab={active}>
//                                         <TabPane tabId='1'>
//                                             {
//                                                 MemberData.map((data) => (

//                                                     <div className="Biz-members-block">
//                                                         <div className="group-member-img-cont-blk">
//                                                             <img src={data.img} alt="img" />
//                                                             <div className="member-cont-blk">
//                                                                 <h4>{data.name}</h4>
//                                                                 <p>{data.joingData} &nbsp;{moment(data.startDate).format('MM Do YYYY')}&nbsp;</p>
//                                                                 <div className='BizMember_bottom_count'>
//                                                                     <p class="font-weight-bold"> 9.8M&nbsp;<span>Followers</span></p>
//                                                                     &nbsp;&nbsp;<Circle fill='#808080' size={5} />&nbsp;&nbsp;
//                                                                     <p class="font-weight-bold">18M&nbsp;<span> Posts</span></p>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className='Admin-btns-blk'>
//                                                             <Button color='primary' outline>{data.userType}</Button>
//                                                         </div>
//                                                     </div>

//                                                 ))
//                                             }
//                                         </TabPane>
//                                         <TabPane tabId='2'>
//                                             {
//                                                 MemberData.map((data) => (

//                                                     <div className="Biz-members-block">
//                                                         <div className="group-member-img-cont-blk">
//                                                             <img src={data.img} alt="img" />
//                                                             <div className="member-cont-blk">
//                                                                 <h4>{data.name}</h4>
//                                                                 <p>{data.joingData} &nbsp;{moment(data.startDate).format('MM Do YYYY')}&nbsp;</p>
//                                                                 <div className='BizMember_bottom_count'>
//                                                                     <p class="font-weight-bold"> 9.8M&nbsp;<span>Followers</span></p>
//                                                                     &nbsp;&nbsp;<Circle fill='#808080' size={5} />&nbsp;&nbsp;
//                                                                     <p class="font-weight-bold">18M&nbsp;<span> Posts</span></p>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className='Admin-btns-blk'>
//                                                             <Button color='primary' outline>{data.userType}</Button>
//                                                         </div>
//                                                     </div>

//                                                 ))
//                                             }
//                                         </TabPane>
//                                     </TabContent>
//                                 </Row>
//                             </ModalBody>
//                         </Modal>
//                     }
//                 </div>
//             </div>
//             {/*================= Start_Modal_section =========================*/}
//             <VistorMamberModal
//                 isOpen={memberOpen}
//                 updateOpen={toggleMember}
//             />
//             <ProfilePictureModal
//                 isOpen={profilePicture}
//                 updateOpen={togglePicture}
//             />

//             <SetUpActionModal
//                 isOpen={actionSetUp}
//                 updateOpen={toggleSetUp}
//             />
//             {/*================= end_Modal_section =========================*/}
//         </Fragment>
//     )
// }

// export default TopBanner

import moment from 'moment';
import React, { Component, useState } from 'react';
import { Circle, MapPin, XCircle } from 'react-feather';
// import { NavLink } from "react-router-dom";
import { Button, Col, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
// import AvatarGroup from '../../../NewComponents/AvatarGroup';
import BizAvatar from '../BizAvatar'
import SetUpActionModal from '../AllTypesModal/SetUpActionModal'
import Backgrdpic from "../Img/BizCover.png"
import Backgrdpic1 from "../Img/BizProfile.png"
import Pen1 from '../../profile-components/Img/Pen1.png'


import Request1 from "../../group-components/Img/Request1.png"
import Request2 from "../../group-components/Img/Request2.png"
import Request3 from "../../group-components/Img/Request3.png"
import { Link } from "react-router-dom";
import Slider from "react-slick";
let gallerySettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};


export default function TopBanner() {
    const [memberOpen, setMemberOpen] = useState(false)
    const toggleMember = () => {
        setMemberOpen((preView) => !preView)
    }
    const [active, setActive] = useState('1')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const MemberData = [
        {
            img: Request1,
            name: "Kenjomen Norke",
            // startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: "Requested"
        },
        {
            img: Request2,
            name: "Kenjomen Norke",
            // startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: "Follow",

        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: "unfollow ",
        },
        {
            img: Request2,
            name: "Kenjomen Norke",
            // startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: " Follow ",
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            // startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: "unfollow ",
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: "follow ",
        }, {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Following since 3 days",
            mutual: '+3 Mutual Group',
            userType: "requested ",
        }
    ]

    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const openModal = (imageUrl) => {
        setModalImage(imageUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const [actionSetUp, setActionSetUp] = useState(false)

    const toggleSetUp = () => {
        setActionSetUp((preview) => !preview)
    }


    const handleCloseModal = () => {
        // Call the function to close the modal (props.updateOpen)
        // props.updateOpen();
    };

    const [SetModal, setSetModal] = useState(false)
    const [SetModal1, setSetModal1] = useState(false)

    const ShowHandle = () => {
        setSetModal(!SetModal)
    }
    const ShowHandle1 = () => {
        setSetModal1(!SetModal1)
    }

    const handleCloseModal1 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleinvite();
    };
    const handleCloseModal2 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleinvite1();
    };

    const toggleinvite = () => {



        setSetModal((prevState) => !prevState)

    }
    const toggleinvite1 = () => {



        setSetModal1((prevState) => !prevState)

    }
    // const [senddropdownOpen, setsendDropdownOpen] = useState(false)

    const SetUpModal = () => {
        const modalStyle = {
            maxWidth: '40%', 
            height: '80vh',// Set the maximum width to 80% of the screen
        };
    
        return (
            <>
                <Modal isOpen={SetModal} toggle={ShowHandle} className='modal-dialog-centered bizpagepicmodal pt-2 '>
                    <div onClick={handleCloseModal1} className="Modal_False_blk1">
                        <XCircle color="#FFFFFF" />
                    </div>
                    <div>
                        <img src={Backgrdpic} style={{width:"100%"}}></img>
                    </div>
                </Modal>
            </>
        );
    };
    const SetUpModal1 = () => {
        const modalStyle = {
            maxWidth: '40%', 
            height: '80vh',// Set the maximum width to 80% of the screen
        };
    
        return (
            <>
                <Modal isOpen={SetModal1} toggle={ShowHandle1} className='modal-dialog-centered '>
                    <div onClick={handleCloseModal2} className="Modal_False_blk1">
                        <XCircle color="#FFFFFF" />
                    </div>
                    <div>
                        <img  src="assets/images/bizpage-1.jpg" style={{width:"100%"}}></img>
                    </div>
                </Modal>
            </>
        );
    };
    return (
        <>
            <div className="group-details-top biz-details-top ">
                <div
                    className="gd-top-block"
                    style={{ background: "url(assets/images/bizcover.jpg)" }}
                    onClick={ShowHandle}
                ></div>

                <div className="heading-title-blk head-title-biz">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4 col-12 order-lg-2">
                            <div className="biz-user-pro-blk">
                                <img src="assets/images/bizpage-1.jpg" className='rounded-circle Biz_TopBanner_Profile'  onClick={ShowHandle1} />
                                <h5>THE DETECTOR CLUB</h5>
                                <div className='bizmappara'>
                                    <MapPin size={20} color="#81C14B" style={{ marginTop: "5px" }} />
                                    <p className='text-primary bizmaptop'> 228 Hope Street Glasgow, G2 3PN 0141 332 </p>

                                </div>

                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 order-lg-1">
                            <div className="gd-content-area-top-blk entmagvifo">
                                <div className="groupmember-blk">
                                    <span> Entertainment</span> • <span>Magic</span>
                                </div>
                                <div className="people-likes matual-friend-sec padavtar" >
                                    <BizAvatar />
                                    <h6 onClick={toggleMember} style={{ cursor: "pointer" }} >+54 Visited today</h6>
                                </div>
                                <div className="people-likes matual-friend-sec padavtar" >
                                    <BizAvatar />
                                    <h6 onClick={toggleMember} style={{ cursor: "pointer" }} >+12k Followers</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 order-lg-3">
                            <ul className="followlist-blk">
                                <li>2.6/5<span>Rating</span></li>
                                <li>52<span>Posts</span></li>
                                <li>524<span>Followers</span></li>
                            </ul>
                            <div className="create-biz-btn light-green">
                                <Button
                                    outline
                                    color="primary"
                                    onClick={toggleSetUp}
                                >
                                    Setup action Button
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Members_Visited_data_Modal'>


                    <Modal  isOpen={memberOpen} toggle={toggleMember} className='modal-dialog-centered modalbiz '>
                        <div
                            onClick={toggleMember}

                            className="Modal_False_blk12"


                        >
                            <XCircle color="#FFFFFF" />


                        </div>
                       
                        <Row className="Member_Tab_heading_section">
                            <Nav>
                                <Col md={6}>
                                    <NavItem>
                                        <NavLink
                                            active={active === '1'}
                                            onClick={() => {
                                                toggle('1')
                                            }}
                                            className="Member_link"
                                        >
                                            Followers(70)
                                        </NavLink>
                                    </NavItem>
                                </Col>
                                <Col md={6}>
                                    <NavItem>
                                        <NavLink
                                            active={active === '2'}
                                            onClick={() => {
                                                toggle('2')
                                            }}
                                            className="Member_link"
                                        >
                                            Visited today(40)
                                        </NavLink>
                                    </NavItem>
                                </Col>
                            </Nav>
                        </Row>
                        <ModalBody>
                            <div className="right-setting pb-3 ">
                                <div className="profile-search-blk  w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <Row>
                                <TabContent className='py-50' activeTab={active}>
                                <div className="modal-scroll-container">
                                    <TabPane tabId='1'>
                                        {
                                            MemberData.map((data) => (

                                                <div className="Biz-members-block">
                                                    <div className="group-member-img-cont-blk">
                                                        <img src={data.img} alt="img" />
                                                        <div className="member-cont-blk">
                                                            <h4>{data.name}</h4>
                                                            <p>{data.joingData}
                                                                {/* &nbsp;{moment(data.startDate).format('MMM Do YYYY')}&nbsp; */}
                                                            </p>
                                                            <div className='BizMember_bottom_count'>
                                                                <p class="font-weight-bold"> 9.8M&nbsp;<span>Followers</span></p>
                                                                &nbsp;&nbsp;
                                                                <p class="font-weight-bold"><Circle fill='#808080' size={5} />&nbsp;&nbsp;18M&nbsp;<span> Posts</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Admin-btns-blk followbut '>
                                                        <Button style={{width:"100px"}} color='primary' outline>{data.userType}</Button>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </TabPane>
                                    </div>

                                    {/* <div className="modal-scroll-container">
                                    <TabPane tabId='2'>
                                        {
                                            MemberData.map((data) => (

                                                <div className="Biz-members-block">
                                                    <div className="group-member-img-cont-blk">
                                                        <img src={data.img} alt="img" />
                                                        <div className="member-cont-blk">
                                                            <h4>{data.name}</h4>
                                                            <p>{data.joingData}
                                                             
                                                            </p>
                                                            <div className='BizMember_bottom_count'>
                                                                <p class="font-weight-bold"> 9.8M&nbsp;<span>Followers</span></p>
                                                                &nbsp;&nbsp;
                                                                <p class="font-weight-bold"><Circle fill='#808080' size={5} />&nbsp;&nbsp;18M&nbsp;<span> Posts</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Admin-btns-blk'>
                                                        <Button style={{width:"100px"}} color='primary' outline>{data.userType}</Button>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </TabPane>
                                    </div> */}
                                </TabContent>
                            </Row>
                        </ModalBody>
                    </Modal>

                </div>
            </div>
            <SetUpModal
            />
            <SetUpModal1
            />
            <SetUpActionModal
                isOpen={actionSetUp}
                updateOpen={toggleSetUp}
            />
        </>

    );
}