
import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const createCategoryList = (createCategory) => ({
    type: types.CREATE_CATEGORY,
    payload: createCategory,
});
// get all biz category
export const createCategoryAction = ( selectedUserIds,name ) => {
    //console.log("categoryName============>",categoryName)
    return function (dispatch) {
        const user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/hamBurgerMenu/addConnectionCategoryWithMember`, {
                
                userIds:selectedUserIds,
                name:name
            }, {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then((res) => {
                //console.log("sociomate=======", res.data.data.successResult);
                dispatch(createCategoryList(res.data.data.successResult))
              
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };
};