import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Checkbox, Tab } from '@mui/material';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Search, Trash, X } from 'react-feather';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';
import Vectors from '../Story/Images/Vectors.png'
import podcast from '../Story/Images/podcast.png'
import hdfimage from '../Story/Images/hdfimage.png'
import images from '../Story/Images/images.png'
import text from '../Story/Images/text.png'
import { Link } from 'react-router-dom';

const PodcastManage = ({ tabData, Slidetoggle }) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [active, setActive] = useState('1')
    const toggle = tab => {
        setActive(tab)
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleButtonClick = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleModalRequestClose = () => {
        setIsModalOpen(false);
    };
    const [showFileInput, setShowFileInput] = useState(false);
    const handleAddAccountClick = () => {
        setShowFileInput(true);
    };
    const [isModalOpens, setIsModalOpens] = useState(false);
    const [isModalOpensection, setIsModalOpenSection] = useState(false);
    const [isModalAccounts, setIsModalAccounts] = useState(false);
    const [isModalAccount, setIsModalAccount] = useState(false);
    const [isModalWithdrawal, setIsModalWithdrawal] = useState(false);
    const handleCloseModals = () => {
        setIsModalOpens(false);
    };
    const handleCloseModalsection = () => {
        setIsModalOpenSection(false);
    };

    const handleModalRequestCloses = () => {
        setIsModalOpens(false);
    };
    const handleModalRequestCloseSection = () => {
        setIsModalOpenSection(false);
    };
    const handleCloseModalAccount = () => {
        setIsModalAccounts(false);
    };
    const handleCloseModalAccounts = () => {
        setIsModalAccount(false);
    };
    const handleCloseModalWithdrawal = () => {
        setIsModalWithdrawal(false);
    };
    const handleClick = () => {
        setIsModalOpen(false);
        setIsModalOpens(true);
    };
    const handleClickAccount = () => {
        setIsModalOpenSection(false); // Close the first modal
        setIsModalAccounts(true); // Open the second modal
    };
    const handleClickAccounts = () => {
        setIsModalAccounts(false);
        setIsModalAccount(true);
    };
    const handleClickWithdrawal = () => {
        setIsModalAccount(false);
        setIsModalWithdrawal(true);
    };
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [showSearchResult, setShowSearchResult] = useState(false);
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(e.target.value);
        setIsButtonDisabled(value === '');
    };
    const handleSearchClick = () => {
        setShowSearchResult(!showSearchResult);
    };
    const handleModal1Continue = () => {
        setIsModalOpens(false);
        setIsModalOpenSection(true);
    };
    const [accountNumber, setAccountNumber] = useState('');
    const [reAccountNumber, setReAccountNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [accountHolderAddress, setAccountHolderAddress] = useState('');
    const [isButtonDisabled4, setIsButtonDisabled4] = useState(true);
    const handleAccountNumberChange = (event) => {
        setAccountNumber(event.target.value);
        validateForm();
    };
    const handleReAccountNumberChange = (event) => {
        setReAccountNumber(event.target.value);
        validateForm();
    };
    const handleAccountHolderNameChange = (event) => {
        setAccountHolderName(event.target.value);
        validateForm();
    };
    const handleContactNumberChange = (event) => {
        setContactNumber(event.target.value);
        validateForm();
    };
    const handleAccountHolderAddressChange = (event) => {
        setAccountHolderAddress(event.target.value);
        validateForm();
    };
    const validateForm = () => {
        // Validate the form fields
        const isValid =
            accountNumber.trim() !== '' &&
            reAccountNumber.trim() !== '' &&
            accountHolderName.trim() !== '' &&
            contactNumber.trim() !== '' &&
            accountHolderAddress.trim() !== '';
        setIsButtonDisabled4(!isValid);
    };
    const [accounts, setAccounts] = useState([
        { id: 1, name: 'Standard Chartered', details: 'Tom Holland | XXXX XXXX XXXX 2548', img: podcast },
        { id: 2, name: 'HSBC', details: 'Tom Holland | XXXX XXXX XXXX 2548', img: hdfimage },
        { id: 3, name: 'BNP Paribas', details: 'Tom Holland | XXXX XXXX XXXX 2548', img: images },
    ]);
    const imageNames = [podcast, hdfimage, images];
    const imageNames1 = ['Standard Chartered', 'HSBC', 'BNP Paribas'];
    const addLimit = 10;

    const addAccount = () => {
        if (accounts.length < addLimit) {
            const randomIndex1 = Math.floor(Math.random() * imageNames.length);
            const randomIndex = Math.floor(Math.random() * imageNames1.length);
            const newAccount = {
                id: accounts.length + 1,
                name: 'Standard Chartered',
                details: 'Tom Holland | XXXX XXXX XXXX 2548',
                img: imageNames[randomIndex],
                name: imageNames1[randomIndex1],
            };
            setIsButtonDisabled(false);
            setAccounts([...accounts, newAccount]);

            if (accounts.length + 1 === addLimit) {
                setIsButtonDisabled(false);
            }
        }
    };
    const deleteAccount = (accountId) => {
        const updatedAccounts = accounts.filter((account) => account.id !== accountId);
        setAccounts(updatedAccounts);
        setIsButtonDisabled(accounts.length === 0);
    };
    const [activeTab, setActiveTab] = useState('1');
    // Function to handle tab change
    // const handleTabChange = (tabId) => {
    //     setActiveTab(tabId);
    // };
    const [isButtonDisabled1, setIsButtonDisabled1] = useState(true);
    const [isButtonDisabled2, setIsButtonDisabled2] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState(null);

    const handleSelectAccount = (accountId) => {
        setSelectedAccount(accountId);
        setIsButtonDisabled1(false);
    };
    const [isAccountSelected, setIsAccountSelected] = useState(false);
    const handleAccountSelect = () => {
        setIsAccountSelected(true);
        setIsButtonDisabled2(false);
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    return (
        <>
        {/*===========================start main section================*/}
            <Card className='mt-3'>
                <div className='m-3'>
                    <h5 style={{ color: '#00000', fontSize: '20px', fontWeight: '600' }} className='ml-2'>Podcast Dashboard</h5>
                </div>
                <div>
                    <Card className='p-1 py-2 FundsForstyle mt-3 rounded-2 m-3' style={{ border: '1px solid #4D4D4D' }}>
                        <div className='' style={{ backgroundColor: '#4F4F4F', marginTop: '-8px', marginLeft: '-4px', marginRight: '-4px' }}>
                            <div className='d-flex align-items-center justify-content-between m-2'>
                                <h6 className='d-flex align-items-center text-white py-1 ml-md-2' style={{ fontSize: '18px', fontWeight: '600' }}>Total Earning -</h6>
                                <h6 className='d-flex align-items-center text-white py-1 mr-md-5' style={{ fontSize: '18px', fontWeight: '600' }}>£25,500</h6>
                            </div>
                        </div>
                        <div className='p-1'>
                            <div className='d-flex align-items-center justify-content-between' style={{ backgroundColor: '#F5FFF7', marginLeft: '-8px', marginRight: '-8px' }}>
                                <p className='d-flex align-items-center py-1 ml-md-3 Mobile-text' style={{ fontSize: '14px', fontWeight: '600', color: '#717171' }}>Available for withdraw&nbsp;&nbsp;&nbsp;<p style={{ fontSize: '14px', fontWeight: '600', color: '#81C14B' }}>£17,500</p></p>
                                <Button
                                    color="primary"
                                    style={{ color: 'white', fontSize: '13px', fontWeight: '600', position: 'relative', right: '39px' }}
                                    className="mt-1 m-2 Mobile-text-btn"
                                    onClick={handleButtonClick}
                                >Request Withdrawal</Button>
                            </div>
                        </div>
                        <div className='p-1' style={{ marginTop: '-8px', height: '51px' }}>
                            <div className='d-flex align-items-center justify-content-between' style={{ backgroundColor: '#FFF4F4', marginLeft: '-8px', marginRight: '-8px' }}>
                                <p className='d-flex align-items-center py-1 ml-md-3 Mobile-text' style={{ fontSize: '14px', fontWeight: '600', color: '#717171' }}>Previous Requests&nbsp;&nbsp;&nbsp;<p style={{ fontSize: '14px', fontWeight: '600', color: '#DE7070', marginLeft: '34px' }}>£8,000</p></p>
                                <Link to='/ViewStatement'><Button style={{ color: '#747474', fontSize: '13px', fontWeight: '600', position: 'relative', right: '39px' }} className=" mt-1 m-2 bg-white Mobile-text-btn-section">View Statement</Button></Link>
                            </div>
                        </div>
                    </Card>
                </div>
            </Card>
            <div className="container-fluid  mt-3">
                <div className="row">
                    {/* <Card className='shadow col-lg-6 col-12 p-3'> */}
                    <div className={window.innerWidth < 768 ? "col-12 mb-4 shadow p-2 rounded-2" : "col-lg-6 mb-4 shadow p-2 rounded-2"}>
                        <div className="card ml-md-2">
                            <div className="p-3" style={{ border: '1px solid gray', borderRadius: '5px' }}>
                                <div className='' style={{ backgroundColor: '#4F4F4F', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px' }}>
                                    <div className='d-flex align-items-center justify-content-between m-2 p-2'>
                                        <h6 className='d-flex align-items-center text-white py-1 ml-3' style={{ fontSize: '18px', fontWeight: '600' }}>Statistics</h6>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Listener</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>7423</p>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Likes</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>6551</p>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Comments</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>5151</p>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Shares</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>65</p>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Purchases</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>255</p>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Earning</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>£25,500</p>
                                    </div>
                                </div>
                                <div className='p-1 mt-2  hover-text' style={{ marginTop: '-8px', borderBottom: '1px solid #E0E0E0' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <p className='d-flex align-items-center py-1' style={{ fontSize: '13px', fontWeight: '500', color: '#000000', marginLeft: '14px', cursor: 'pointer' }}>Total Downlode</p>
                                        <p className='d-flex align-items-center py-1 ml-md-5 ml-5' style={{ fontSize: '13px', fontWeight: '500', color: '#368C4E', marginRight: "63px", cursor: 'pointer' }}>55</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={window.innerWidth < 768 ? "col-12 mb-4 shadow p-2 rounded-2" : "col-lg-6 mb-4 shadow p-2 rounded-2"}>
                        <div className="card mr-md-2">
                            <div className="p-3" style={{ border: '1px solid gray', borderRadius: '5px' }}>
                                <div className='' style={{ backgroundColor: '#4F4F4F', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px' }}>
                                    <div className='d-flex align-items-center justify-content-between m-2 p-2'>
                                        <h6 className='d-flex align-items-center text-white py-1 ml-3' style={{ fontSize: '18px', fontWeight: '600' }}>Demographic Details</h6>
                                    </div>
                                </div>
                                <TabContext value={activeTab}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === '1' ? 'text-primary' : '#717171'}`}
                                                    onClick={() => handleTabChange('1')}
                                                >
                                                    Gender
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === '2' ? 'text-primary' : '#717171'}`}
                                                    onClick={() => handleTabChange('2')}
                                                >
                                                    Age
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${activeTab === '3' ? 'text-primary' : '#717171'}`}
                                                    onClick={() => handleTabChange('3')}
                                                >
                                                    Location
                                                </button>
                                            </li>
                                        </ul>
                                    </Box>
                                    <TabPanel value="1" style={{ cursor: 'pointer' }}>
                                        <div className='row'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Male</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={85} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>85%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Female</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1'>
                                            <Progress className='sizeForpro  w-100' value={50} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>60%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Other</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={35} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>35%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>95 and above</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={95} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>95%</small>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2" style={{ cursor: 'pointer' }}>
                                        <div className='row'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>18-85</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={85} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>85%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>25-35</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1'>
                                            <Progress className='sizeForpro  w-100' value={35} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>35%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>35-75</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={75} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>75%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>55 and above</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={95} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>95%</small>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3" style={{ cursor: 'pointer' }}>
                                        <div className='row'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Uttar Pradesh</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1 '>
                                            <Progress className='sizeForpro  w-100' value={85} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>95%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Uttarakhand</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1'>
                                            <Progress className='sizeForpro  w-100' value={70} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>50%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Bihar</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1'>
                                            <Progress className='sizeForpro  w-100' value={40} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>35%</small>
                                        </div>
                                        <div className='row mt-2'><p className='' style={{ color: '#000000AB', fontSize: '14px', fontWeight: '600', cursor: 'pointer' }}>Mumbai</p></div>
                                        <div className='d-flex align-items-center justify-content-between p-1'>
                                            <Progress className='sizeForpro  w-100' value={30} />
                                            <small style={{ color: '#48985C', fontSize: '12px', fontWeight: '600', cursor: 'pointer' }}>25%</small>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* =============================Request Withdrawal modal section================= */}
            <Modal isOpen={isModalOpen} className='modal-dialog-centered modal-cross-icon' onRequestClose={handleModalRequestClose} >
                <ModalHeader style={{ backgroundColor: '#81C14B' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 style={{ color: '#000000', fontWeight: '600', fontSize: '18px', margin: 0 }}>Request Withdrawal</h2>
                        <X onClick={handleCloseModal} style={{ cursor: 'pointer', color: '#000000' }} className='cross-icon-section' />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='text-start mb-2'>
                        <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '15px' }}>Added Accounts</h5>
                    </div>
                    <div
                        role="button"
                        className="upload-imgs w-100 d-flex flex-column justify-content-center align-items-center"
                        onClick={handleAddAccountClick}
                    >
                        <img
                            src={Vectors}
                            alt="" style={{ width: '40px' }}
                        />
                        <h2 style={{ color: '#000000', fontWeight: '600', fontSize: '18px', margin: 0 }} >No Account Added</h2>
                        <button className="mt-2 form-control p-2" style={{ backgroundColor: '#81C14B', color: 'white', border: 'none', fontWeight: '600', fontSize: '13px', width: '150px' }} onClick={handleClick}>Add Bank Account</button>
                    </div>
                </ModalBody>
            </Modal>
            {/* =============================Request Withdrawal modal section end================= */}
            {/* =============================Find Bank modal section================= */}
            <Modal isOpen={isModalOpens} className='modal-dialog-centered modal-cross-icon' onRequestCloses={handleModalRequestCloses} >
                <ModalHeader style={{ backgroundColor: '#81C14B' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 style={{ color: '#000000', fontWeight: '600', fontSize: '18px', margin: 0 }}>Find Bank</h2>
                        <X onClick={handleCloseModals} style={{ cursor: 'pointer', color: '#000000' }} className='cross-icon-section' />
                    </div>
                </ModalHeader>
                <ModalBody className=' border-none'>
                    <div className='text-start mb-2'>
                        <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '15px' }}>Enter Bank Swift Code</h5>
                    </div>
                    <div>
                        <div className='search-section'>
                            <Input
                                type='search'
                                placeholder='Enter Bank Swift Code'
                                className='search-section-text'
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                            <Search
                                className='search-icon'
                                size={17}
                                color='#81C14B'
                                onClick={handleSearchClick}
                            />
                        </div>
                        {showSearchResult && (
                            <>
                                <div className='text-start mb-2 mt-3'>
                                    <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '15px' }}>Search Result</h5>
                                </div>
                                <div className='mt-2 rounded-2' style={{ border: '1px solid #C4C4C4', padding: '10px' }}>
                                    <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '15px' }}>Bank Details</h5>
                                    <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '14px' }} className='mt-3'>Bank Name-</h5><p style={{ position: 'relative', top: '-18px', left: '6.2rem', fontWeight: '500', fontSize: '13px', color: '#717171' }}>Standard Chartered</p>
                                    <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '14px' }} className=''>Country -</h5><p style={{ position: 'relative', top: '-18px', left: '6.2rem', fontWeight: '500', fontSize: '13px', color: '#717171' }}>India</p>
                                    <h5 style={{ color: '#4D4D4D', fontWeight: '600', fontSize: '14px' }} className=''>Address -</h5><p style={{ position: 'relative', top: '-18px', left: '5.8rem', fontWeight: '500', fontSize: '13px', color: '#717171' }}>Standard Charted Bank.</p>
                                </div>
                                <Checkbox />
                                <p className='check-details'>Yes, this is my Bank Details.</p>
                            </>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-end align-items-end' style={{ backgroundColor: '#F6F6F6' }}>
                    <button
                        className='d-flex justify-content-center align-items-center form-control'
                        style={{ backgroundColor: isButtonDisabled ? 'rgba(129, 193, 75, 0.2)' : '#81C14B', color: 'white', border: 'none', width: '150px', fontWeight: '600', fontSize: '14px' }}
                        disabled={isButtonDisabled}
                        onClick={handleModal1Continue}
                    >
                        Continue
                    </button>
                </ModalFooter>
            </Modal>
            {/* =============================Enter Bank Account details modal section================= */}
            <Modal isOpen={isModalOpensection} className='modal-dialog-centered modal-cross-icon' onRequestCloseSection={handleModalRequestCloseSection} >
                <ModalHeader style={{ backgroundColor: '#81C14B' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 style={{ color: '#000000', fontWeight: '600', fontSize: '18px', margin: 0, cursor: 'pointer' }}>Enter Bank Account details</h2>
                        <X onClick={handleCloseModalsection} style={{ cursor: 'pointer', color: '#000000' }} className='cross-icon-section' />
                    </div>
                </ModalHeader>
                <ModalBody className=''>
                    <div className='d-flex justify-content-between align-items-center Account-text'>
                        <div className="d-flex align-items-center">
                            <img src={podcast} alt="" />&nbsp;&nbsp;&nbsp;
                            <div className="">
                                <h6 style={{ color: '#333333', fontWeight: '500', fontSize: '14px', cursor: 'pointer' }}>Standard Chartered</h6>
                                <p className='swift-text' >(SWIFTCODE)</p>
                                <p style={{ color: '#717171', fontWeight: '400', fontSize: '14px', marginTop: '-16px', cursor: 'pointer' }}>Standard Chartered Bank Gomtinagar Vibhuti Khand LKO</p>
                            </div>
                        </div>
                    </div>
                    {/*====================Re-account number=============*/}
                    <div className="container mt-2">
                        <div className="row gx-3">
                            <div className="col mt-2">
                                <label className="form-label-text">Account number</label>
                                <input
                                    type="text"
                                    value={accountNumber}
                                    onChange={handleAccountNumberChange}
                                    placeholder="Enter account number..."
                                    className="form-control form-control-text"
                                />
                            </div>
                            <div className="col mt-2">
                                <label className="form-label-text">Re-account number</label>
                                <input
                                    type="text"
                                    value={reAccountNumber}
                                    onChange={handleReAccountNumberChange}
                                    placeholder="Enter re-account number..."
                                    className="form-control form-control-text"
                                />
                            </div>
                        </div>
                    </div>
                    {/* ==================>Account Holder Name======= */}
                    <div className="container mt-2">
                        <div className="row gx-3">
                            <div className="col mt-2">
                                <label className="form-label-text">Account Holder Name</label>
                                <input
                                    type="text"
                                    value={accountHolderName}
                                    onChange={handleAccountHolderNameChange}
                                    placeholder="Enter account holder..."
                                    className="form-control form-control-text"
                                />
                            </div>
                            <div className="col mt-2">
                                <label className="form-label-text">Contact Number</label>
                                <input
                                    type="text"
                                    value={contactNumber}
                                    onChange={handleContactNumberChange}
                                    placeholder="Enter contact number..."
                                    className="form-control form-control-text"
                                />
                            </div>
                        </div>
                    </div>
                    {/* ==================add Account Holder Address======= */}
                    <div className="container mt-2">
                        <div className="row gx-3">
                            <div className="col-12 mt-2">
                                <label className="form-label-text">Account Holder Address</label>
                                <input
                                    type="text"
                                    value={accountHolderAddress}
                                    onChange={handleAccountHolderAddressChange}
                                    placeholder="Account holder address..."
                                    className="form-control form-control-text"
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                {/* ======================add save and continue ================= */}
                <ModalFooter className='d-flex justify-content-end align-items-end mt-2'>
                    <button
                        className='d-flex justify-content-center align-items-center form-control'
                        style={{ backgroundColor: isButtonDisabled4 ? 'rgba(129, 193, 75, 0.2)' : '#81C14B', color: 'white', border: 'none', width: '180px', fontWeight: '600', fontSize: '14px' }}
                        disabled={isButtonDisabled4}
                        onClick={handleClickAccount}
                    >
                        Save & Continue
                    </button>
                </ModalFooter>
            </Modal>
            {/* =============================Enter Bank Account details modal section end ================= */}
            {/* =============================Request Withdrawal modal section================= */}
            <Modal isOpen={isModalAccounts} className='modal-dialog-centered modal-cross-icon' onRequestCloseSections={handleCloseModalAccount}>
                <ModalHeader style={{ backgroundColor: '#81C14B' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 style={{ color: '#000000', fontWeight: '600', fontSize: '18px', margin: 0 }}>Request Withdrawal</h2>
                        <X onClick={handleCloseModalAccount} style={{ cursor: 'pointer', color: '#000000' }} className='cross-icon-section' />
                    </div>
                </ModalHeader>
                <ModalBody className=''>
                    <div className='d-flex justify-content-between'>
                        <h6 className='account-text'>Added Accounts</h6>
                        <h6 className='account-texts' onClick={addAccount}>+ Add Account</h6>
                    </div>
                    {accounts.map((account) => (
                        <div key={account.id} className={`d-flex justify-content-between Account-text-sections m-2 ${selectedAccount === account.id ? 'selected-account' : ''}`} onClick={() => handleSelectAccount(account.id)} style={{ border: selectedAccount === account.id ? '2px solid #81C14B' : '2px solid #F6F6F6' }}>
                            <div className='d-flex align-items-center ' onClick={() => handleSelectAccount(account.id)}>
                                <img src={account.img} alt="" className='' />&nbsp;&nbsp;&nbsp;
                                <div className='ml-2'>
                                    <h6 style={{ color: '#333333', fontWeight: '500', fontSize: '14px', cursor: 'pointer' }}>{account.name}</h6>
                                    <p style={{ color: '#717171', fontWeight: '400', fontSize: '14px', marginRight: 'auto', cursor: 'pointer' }}>{account.details}</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <Trash onClick={() => deleteAccount(account.id)} className='trash-icon' size={20} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter className='d-flex justify-content-end align-items-end' style={{ backgroundColor: '#F6F6F6' }}>
                    <button
                        className='d-flex justify-content-center align-items-center form-control'
                        style={{
                            backgroundColor: isButtonDisabled1 ? 'rgb(129 193 75 / 20%)' : '#81C14B',
                            color: 'white',
                            border: 'none',
                            width: '120px',
                            fontWeight: '600',
                            fontSize: '14px',
                        }}
                        disabled={isButtonDisabled1}
                        onClick={handleClickAccounts}
                    >
                        Continue
                    </button>
                </ModalFooter>
            </Modal>
            {/* =============================Request Withdrawal modal section end================= */}
            {/* =============================Account Transfer modal section================= */}
            <Modal isOpen={isModalAccount} className='modal-dialog-centered modal-cross-icon' onRequestCloseSections={handleCloseModalAccounts}>
                <ModalHeader style={{ backgroundColor: '#81C14B' }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2 style={{ color: '#000000', fontWeight: '600', fontSize: '18px', margin: 0, cursor: 'pointer' }}>Account Transfer</h2>
                        <X onClick={handleCloseModalAccounts} style={{ cursor: 'pointer', color: '#000000' }} className='cross-icon-section' />
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='d-flex justify-content-between align-items-center Account-text' onClick={handleAccountSelect}>
                        <div className="d-flex align-items-center">
                            <img src={podcast} alt="" />&nbsp;&nbsp;&nbsp;
                            <div className="">
                                <h6 style={{ color: '#333333', fontWeight: '500', fontSize: '14px', cursor: 'pointer' }}>Standard Chartered</h6>
                                <p className='swift-text'>(SWIFTCODE)</p>
                                <p style={{ color: '#717171', fontWeight: '400', fontSize: '14px', marginTop: '-16px', cursor: 'pointer' }}>Standard Chartered Bank Gomtinagar Vibhuti Khand LKO</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center p-4">
                        <img src={text} alt='' />
                    </div>
                    <div className="d-flex justify-content-center">
                        <p style={{ color: '#717171', fontWeight: 500, fontSize: '12px', marginTop: '-14px' }}>Withdraw fees will applicable as per amount you</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <p style={{ color: '#717171', fontWeight: 500, fontSize: '12px' }}>request for withdraw </p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Checkbox />&nbsp;&nbsp;&nbsp;
                        <p style={{ color: '#313636', fontSize: '14px', fontWeight: 600, marginTop: '12px' }}>I Accept</p>&nbsp;&nbsp;
                        <p className='term-text'>terms & conditions</p>
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between ' style={{ backgroundColor: '#F6F6F6' }}>
                    <p className='view-text' style={{ cursor: 'pointer' }} >View Exchange Rates</p>
                    <button
                        className='d-flex justify-content-center align-items-center form-control'
                        style={{
                            backgroundColor: isButtonDisabled2 ? 'rgb(129 193 75 / 20%)' : '#81C14B',
                            color: 'white',
                            border: 'none',
                            width: '120px',
                            fontWeight: '600',
                            fontSize: '14px',
                            textWrap: 'nowrap'
                        }}
                        disabled={isButtonDisabled2}
                        onClick={handleClickWithdrawal}
                    >
                        Send Request
                    </button>
                </ModalFooter>
            </Modal>
            {/* =============================Account Transfer end modal section================= */}
            {/* =============================Withdrawal Request Successfully sent  modal section================= */}
            <Modal isOpen={isModalWithdrawal} className='modal-dialog-centered modal-cross-icon' onRequestCloseSections={handleCloseModalWithdrawal}>
                <ModalHeader style={{ position: 'relative', padding: '20px', border: 'none' }}>
                    <X onClick={handleCloseModalWithdrawal} style={{ cursor: 'pointer' }} size={20} className='cross-icon-section' />
                </ModalHeader>
                <div style={{ position: 'relative' }}>
                    <img src='https://media.tenor.com/bm8Q6yAlsPsAAAAj/verified.gif' alt='' style={{ position: 'absolute', height: '100px', left: '39%' }} />
                </div>
                <div className='header-section-request mt-5'>
                    <div className="d-flex justify-content-center">
                        <p style={{ color: '#313636', fontSize: '18px', fontWeight: 600, marginTop: '18px' }}>Withdrawal Request</p>&nbsp;&nbsp;
                    </div>
                    <div className="d-flex justify-content-center">
                        <p style={{ color: '#313636', fontSize: '18px', fontWeight: 600 }}> Successfully sent</p>&nbsp;&nbsp;
                    </div>
                    <div className="custom-message mt-2">
                        <p>Your Request is under review. We will notify you once your request is approved, or you can check its status from the Podcast dashboard.</p>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <p style={{ color: '#48985C', fontSize: '14px', fontWeight: 600, cursor: 'pointer' }} onClick={() => { handleCloseModalWithdrawal(); }}>CONTINUE TO DASHBOARD</p>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal>

            {/* =============================Withdrawal Request Successfully sent  end modal section================= */}
        </>
    );
}

export default PodcastManage;
