
import React, { useState } from 'react'
import { Delete, Eye, Filter, Globe, MapPin, Users, ThumbsUp, MoreHorizontal, MoreVertical, Star, MessageCircle, Send, Link, Edit, Trash2, Share2, AlertTriangle } from 'react-feather'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Eventpic from '../../NewComponents/IMG/Eventpic.png'
import Eventpic1 from '../../NewComponents/IMG/Eventpic1.png'
import Eventpic2 from '../../NewComponents/IMG/Eventpic2.png'
import Eventpic3 from '../../NewComponents/IMG/Eventpic3.png'
import Eventpic4 from '../../NewComponents/IMG/Eventpic4.png'
import like2 from '../../NewComponents/IMG/like2.svg'
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
// import cover1 from './IMG/cover1.png'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'

const ArticleTabs = () => {
  const [seemore, setSeeMore] = useState(false)
  const [basicModal, setBasicModal] = useState(false)
  const theme = useTheme()
  const [shareOpen, setShareOpen] = useState(false)
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState)
  }
  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = () => {
    setReportOpen((preState) => !preState)
  }
  const ReportDataInfo = [
    {
      name: "Spam"
    },
    {
      name: "Nudity"
    },
    {
      name: "Violence"
    },
    {
      name: "Harrasment"
    },
    {
      name: "Suicide or self-injury"
    },
    {
      name: "Terrorism"
    },
    {
      name: "Others"
    }
  ]
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const chackbox = [
    {
      songs: 'music',
      chackbox: <input type="checkbox" />
    },
    {
      songs: 'Arts',
      chackbox: <input type="checkbox" />
    },
    {
      songs: 'Weddings',
      chackbox: <input type="checkbox" />
    },
    {
      songs: 'Food & Recipe',
      chackbox: <input type="checkbox" />
    }
  ]
  const Articledata = [
    {
      sharevia: 'Hide post from timeline',
      shareto: 'Copy link',
      notification: 'Edit Post',
      invite1: 'Share',
      content: 'Delete',
      button: <button className='Article_Going_Button'><Users size={14} />&nbsp;&nbsp;Going</button>,
      button1: <button className='Article_Button_Interested'><ThumbsUp size={14} />&nbsp;&nbsp;Interested</button>,
      Event: Eventpic4
    },
    {
      sharevia: 'Hide post from timeline',
      shareto: 'Copy link',
      notification: 'Edit Post',
      invite1: 'Share',
      content: 'Delete',
      button: <button className='Article_Going_Button'><Users size={14} />&nbsp;&nbsp;Going</button>,
      button1: <button className='Article_Button_Interested'><ThumbsUp size={14} />&nbsp;&nbsp;Interested</button>,
      Event: Eventpic2
    },
    {
      sharevia: 'Hide post from timeline',
      shareto: 'Copy link',
      notification: 'Edit Post',
      invite1: 'Share',
      content: 'Delete',
      button: <button className='Article_Going_Button'><Users size={14} />&nbsp;&nbsp;Going</button>,
      button1: <button className='Article_Button_Interested'><ThumbsUp size={14} />&nbsp;&nbsp;Interested</button>,
      Event: Eventpic3
    },
    {
      sharevia: 'Hide post from timeline',
      shareto: 'Copy link',
      notification: 'Edit Post',
      invite1: 'Share',
      content: 'Delete',
      button: <button className='Article_Going_Button'><Users size={14} />&nbsp;&nbsp;Going</button>,
      button1: <button className='Article_Button_Interested'><ThumbsUp size={14} />&nbsp;&nbsp;Interested</button>,
      Event: Eventpic1
    }
  ]
  return (
    <div>
      <Card className='p-2 border-0'>
        <Row className='d-flex justify-content-around align-items-center'>
          <Col md='6'>
            <div className='d-flex mt-2 ml-2 align-items-center'>
              <p className='peopletabstext'>Showing best results for</p>
              <p className='font-weight-bold'>“Centre table mint condition”</p>
            </div>
          </Col>
          <Col md='6'>
            <div className='d-flex justify-content-end p-2'>
              {/* -------------------- Filter for Mobile-------------------- */}
              <div className='rem-web-view'>
                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                  <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                    <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                  </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
              {/* --------------------Filter for Desktop-------------------- */}
              <div className='rem-mob-view ml-2'>
                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                  <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                    <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                      <Filter size={18} className='mr-2' />
                      Filters
                    </Button>
                  </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='AllTagsThis'>
          {
            Articledata.map((data) => {
              return (
                <Col xl='3'>
                  <div className='p-1 mt-2'>
                    {/* <Slider {...bizSettings} className="default-space"> */}
                    <div className="group-slide-box border">
                      <div className="gallery-img-sublink">
                      </div>
                      <NavLink>
                        <div className='TopRight_Section'>
                          <p><Eye color='#4D4D4D' size={18} />&nbsp;105 </p>
                          <p><img src={like2} className='Article_like_thumble' />&nbsp;105</p>
                          <p><MessageCircle color='#4D4D4D' size={18} />&nbsp;78</p>
                          <p ><Send color='#4D4D4D' size={18} />&nbsp;13</p>
                        </div>
                        <img src={data.Event} className="img-fluid group-img" />
                        <div className='d-flex justify-content-between mt-3'>
                          <p className='ArticleTabs_date_month'>01 July, 2022</p>
                          <p className='Article_Sports'>Sports</p>
                          <p className='MoreHorizontal'>
                            <UncontrolledButtonDropdown className='align-self-center'>
                              <DropdownToggle className='' color='flat' >
                                <MoreHorizontal size={19} color='#000000' />
                              </DropdownToggle>
                              <DropdownMenu className='bizpagefordropdownmenu'>
                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </p>
                        </div>
                        <div className='d-flex justify-content-start'>
                          <p className='ArticleTabs_Liverpool'>Liverpool Win Champions...</p>
                        </div>
                      </NavLink>
                      <div className=''>
                        <small className=''>more user-centric and conv{seemore ? 'by their very nature' : null}attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </small>
                      </div>
                    </div>
                  </div>                   
                </Col>
              )
            })
          }
        </Row>
      </Card>
      <ShareEventModal
        isOpen={shareOpen}
        updateOpen={toggleShareOpen}
      />
      {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}
      {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
      <div className='Review_Report_modal'>
        {
          reportOpen !== false &&
          <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
              <h3>Report a Post</h3>
            </ModalHeader>
            <ModalBody>
              <div className='Modal_Body_Section'>
                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
              </div>
              {
                ReportDataInfo.map((data) => (
                  <div className='d-flex justify-content-between mt-4 Report_Section'>
                    <h4>{data.name}</h4>
                    <div>
                      <Input
                        name="radio1"
                        type="radio"
                        className="Check"
                      />
                      {' '}
                    </div>
                  </div>
                ))
              }
              <div className='d-flex justify-content-center mt-3'>
                <Input
                  id="ReportName"
                  name="ReportName"
                  placeholder="Write a comment..."
                  type="Report"
                />
              </div>
              <hr className='w-100 text-muted' />
              <div className='text-center'>
                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
              </div>
            </ModalBody>

          </Modal>

        }

      </div>
      <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
        <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Article
        </b>
        </ModalHeader>
        {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
        <ModalBody>
          <div>
            <div className='d-flex justify-content-between'>
              <h4>Withim (Km)</h4>
              <h4>8821 Km</h4>
            </div>
            <div className='d-flex justify-content-between mt-2'>
              <div className='w-100'>
                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                  <MatSlider
                    aria-label="Volume"
                    defaultValue={100}
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                      '& .MuiSlider-track': {
                        border: 'none',
                      },
                      '& .MuiSlider-thumb': {
                        width: 20,
                        height: 20,
                        backgroundColor: '#81C14B',
                        '&:before': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                        },
                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                          boxShadow: 'none',
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
              <div className='d-flex align-items-center ml-3' role='button'>
                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
              </div>
            </div>
            <div className='border-top mt-2'>
              <h4 className='mt-3'>Choose locations</h4>
              <ChipBoxLocation />
            </div>
            <div className=''>
              <h4 className='mt-3'>Choose Interest</h4>
              <ChipBox />
            </div>
            <div className='d-flex justify-content-center mt-3 '>
              <Button color='primary' outline>CLEAR ALL</Button>
              <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
            </div>
          </div>

        </ModalBody>
      </Modal>
      {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}
    </div>
  )
}

export default ArticleTabs