import React from 'react'
import { Fragment } from 'react'
import { Circle } from 'react-feather'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ForwardArrayData } from '../Components/EventsDataArray'

const ForwardModal = (props) => {
    return (
        <Fragment>
            <div className='Forward_Modal_section'>
                {
                    props.isOpen !== false &&
                    <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                        <ModalHeader toggle={props.updateOpen} className="My-Member_Header_section">
                            <h2>Forward to  </h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Invite SocioMate</h4>
                                {/* <p>Select All</p> */}
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            <Button
                                                outline
                                                onClick={() => props.updateBtn(data.id)}
                                                active={props.btnActive.includes(data.id)}
                                                style={{
                                                    backgroundColor: props.btnActive.includes(data.id) ? '#81C14B' : '#F6F6F6',
                                                    color: props.btnActive.includes(data.id) ? 'white' : '#4D4D4D',

                                                }}
                                            >
                                                {props.btnActive.includes(data.id) ? "Invited" : "Invite"}
                                            </Button>

                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default ForwardModal