import React, { Fragment } from 'react'
import Header from '../../Header'
import { useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import { Button, Input } from 'reactstrap';
import { ArrowRight, Image } from 'react-feather';
import { Container } from 'react-bootstrap';


const EditorArticle = () => {
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [show, setShow] = useState(false)
    const [selectedImages, setSelectedImages] = useState("");
    const toggleTheFile = (e) => {
        document.querySelector(".InputFile").click()
    }
    const handleImageSelect = (e) => {
        if (e.target.files.length !== 0) {
            setSelectedImages(URL.createObjectURL(e.target.files[0]))
        }

    }
    //==================== Main_return_function ======================//
    return (
        <Fragment>
            <Header />
            <div className=' Main_Editor_artical_blk '>
                <div className='Editor_Box_section  container'>
                    <div className='mt-3 xyz  '>
                        <Editor editorState={editorState} onEditorStateChange={setEditorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editorClassName bg-light border Artical_blk"
                            toolbarClassName="toolbar-class Artical_tool_bar"
                            placeholder='Write the artical here...'
                        />

                        <div className='Main_Heading_main_blk_center'>
                            <div className='dpj_here'>
                                <div >
                                    <Input type='textarea' Placeholder='Headline' className='Artical_main_Headin_input_blk ' />
                                </div>

                                <div className='Artical_Addmedia_sub_section mt-2 w-100'>
                                    {selectedImages === "" ?
                                        <div onClick={(e) => { toggleTheFile(e) }}>
                                            <div className='text-center' >
                                                <Image />
                                                <h3 className='py-2'>Add a cover image that complements your article to attract more readers.</h3>
                                                <h4 className='py-2'>We recommend uploading an image that is 1920x1080 pixels.</h4>
                                                <p>Upload</p>
                                            </div>
                                            <input type="file" name="" onChange={(e) => handleImageSelect(e)} className='InputFile d-none' id="" />
                                        </div> :
                                        <div className='w-100'>
                                            <img src={selectedImages} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='next_btn_blk'>
                            <Button color="primary" className="artical_next_btn">Next &nbsp;<ArrowRight size={15} /></Button>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default EditorArticle