import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { CheckCircle, ChevronDown, Eye, EyeOff, Link2, MapPin, MoreHorizontal, Star, Users, X } from 'react-feather'
import { Link } from 'react-router-dom'
import { GoingCardData } from './EventsArray'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { useState } from 'react'
import DeletedEventModal from './EventsModal/DeletedEventModal'
import {Stack, Pagination,PaginationItem } from '@mui/material'
import ShareEventModal from './EventsModal/ShareEventModal'
import { getEventsAction, postHideUnHideAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import axios from 'axios'
import { useNavigate,useLocation } from 'react-router-dom';

const MyGoing = (props) => {

    // const [value,setValue] =useState('');

    let dispatch = useDispatch();

    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const id = searchParams.get('id');
    const userId = searchParams.get('userId');
    const type = searchParams.get('type');
    const [expandedEventDescription, setExpandedEventDescription] = useState(null);
    const [expandedEventId, setExpandedEventId] = useState(null);

    // useEffect(() => {
    //     dispatch(getEventsAction('going'));
    // }, [])

    // const getAllEvents = useSelector((state) => { return state.getEventsData });
    // const { loading: loadingEvents, error: errorEvents, result: resultEvents } = getAllEvents
    const postHideUnHide = useSelector((state) => { return state.postHideUnHideData });
    let { loading: loadingHideUnHide, error: errorHideUnHide, result: resultHideUnHide } = postHideUnHide

    const [deleteOpen, setDeleteOpen] = useState(false)
    
    const [pageSize,setPageSize]=useState(12);
    const [pageIndex,setPageIndex]=useState(0);
    
    const toggleDeleteOpen = () => {
        setDeleteOpen((prevState) => !prevState)
    }
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    const hideOrUnhideIcon = () => {
      alert('API Issue')
    }

  let [copyLink, setCopyLink] = useState('')

  const postCopyLink = async (id) => {
    copyLink = `${process.env.REACT_APP_IPURL}/Events/${id}`
    setCopyLink(copyLink)
    navigator.clipboard.writeText(copyLink)
    sweetAlertConfirmation(`Link Copied`)
  };

const settingEventAct =(e,value,postId)=>{
  e.stopPropagation();
  // setValue(value);

  const fetchData = async () => {
        try {
            let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/post/changeEventStatus`,
                    { status:value,id:postId},
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                // setMediaAllCount(response.data.data.successResult.count);
            
        } catch (error) {
          console.log(error)
        }
   };

fetchData();

props.setRefresh(true)

sweetAlertConfirmation("Successfully updated")
}

const navigateToSomewhere =(e,id)=>{
  e.stopPropagation();
  e.preventDefault();
  navigate(`/MyPostDetail/${id}`,{ state: { userId, type:"viewer" }})
}

const pageCount = Math.ceil((props?.data?.length)/12);
const isPrevDisabled = pageIndex <= 0;
const isNextDisabled = pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === pageIndex) return;

    setPageIndex(value - 1);
  };


const BasicsPagination = () => {
  return (
    <>
      <Stack>
        <Pagination
             count={pageCount}
             page={pageIndex + 1}
             onChange={handlePageChange}
             className="PaginationCounts"
             renderItem={(item) => (
               <PaginationItem
                 {...item}
                 disabled={
                   (item.type === 'previous' && isPrevDisabled) ||
                   (item.type === 'next' && isNextDisabled)
                 }
               />
             )}
        ></Pagination>
      </Stack>
    </>
  );
};

    return (
        <Fragment>
            <div className="container-fluid section-t-space px-0">
                <div className="group-single-list-block">
                  {props?.data?.length>0 ?(
                      <Row className='mt-3'>
                      {
                          props?.data?.map((event) => (
                              <Col md="3" className="mb-4">
                              <div className="Event-box">
                                <div className="Event-img-sublink">
                                  <EyeOff color='#FFFFFF' size={20} 
                                  onClick={hideOrUnhideIcon}
                                  style={{cursor:'pointer'}}
                                  />
                                </div>
                                <img src={event.eventCoverImageUrl} className="img-fluid event-img" onClick={(e)=>navigateToSomewhere(e,event?.postId)}/>
                                <div className='Event-FirstSection' >
                                  <div className='Event-SecndSection'>
                                    <small>{event?.eventShowDate}  {event?.eventStartTime?.slice(0,4) }<br />{event?.eventStartTime?.slice(11,16) } to {event?.eventEndTime?.slice(11,16)}</small>
                                    <div className='stng_btn text-center'>
                                      <MoreHorizontal color='#808080' size={16} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                      <div className="dropdown-menu dropdown-menu-bottom custom-dropdown drop-menu-gal events_Option" style={{ width: '200px' }}>
                                        <ul>
                                        {/* <li>
                                        <span
                                        onClick={() => hideUnHide(event.postId, event.hidden)}
                                        >
                                        {event.hidden ?
                                        <Link to="#"><EyeOff size={15} color="#979797" /> &nbsp;Hide post from timeline</Link>
                                        :
                                        <Link to="#"><Eye size={15} color="#979797" /> &nbsp;Un Hide post from timeline</Link>
                                        }
                                        </span>
                                        </li> */}
                                          <li 
                                          onClick={() => {toggleShareOpen(event.postId, 0)}}
                                          >
                                            <Link to="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg> Invite</Link>
                                          </li>
                                        <li
                                          onClick={() => {postCopyLink(event?.postId) }}
                                        >
                                          <Link to="#"><Link2 size={15} color="#979797" /> &nbsp;Copy link</Link>
                                        </li>
                                          <li onClick={toggleDeleteOpen} >
                                            <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete </Link>
                                          </li>
                                          <li onClick={toggleShareOpen} >
                                            <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg> Share</Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <h4 className='pt-3' onClick={(e) => navigateToSomewhere(e, event?.postId)}>
                                    {event?.eventDescription
                                      ? event.eventDescription.length >= 20
                                        ? `${event.eventDescription.substring(0, 20)} ...`
                                        : event.eventDescription
                                      : 'No description available'}
                                  </h4>
                                  <div className='Event-bottom-section' onClick={(e)=>navigateToSomewhere(e,event?.postId)}>
                                      <p>
                                        <MapPin color='#808080' size={15} />{' '}
                                        {expandedEventId === event.postId 
                                        ? 
                                        event.eventAddress 
                                        : 
                                        event.eventAddress.substring(0, 20)}
                                        {event.eventAddress.length >= 20 && (
                                        <span style={{ cursor: 'pointer', color: '#81c14b', marginLeft: '4px', fontSize: '12px' }}>
                                          {expandedEventId === event.postId ? ' less ' : ' more '}
                                        </span>
                                        )}
                                      </p>
                                  </div>
                                  {/* <div className='Event_Button'><p>{event.ButtonType} </p></div> */}
                                  <div className='Going_Button'>
                                      <UncontrolledButtonDropdown className='UncontrolledButtonDropdown'>
                                          <DropdownToggle color='flat' >
                                              <p><CheckCircle size={20} color="#FFFFFF" className='mr-2' />&nbsp;Ongoing &nbsp; <ChevronDown size={20} color="#FFFFFF" /></p>
                                          </DropdownToggle>
                                          <DropdownMenu className="Going_Menu">
                                              <DropdownItem className="Option_going_btn" onClick={(e)=>settingEventAct(e,"interested",event.postId)}>
                                                  <Star size={20} />&nbsp;Interested
                                              </DropdownItem >
                                              <DropdownItem className="Option_going_btn" onClick={(e)=>settingEventAct(e,"notInterested")}>
                                                  <X size={20} />Not Interested</DropdownItem>
                                          </DropdownMenu>
                                      </UncontrolledButtonDropdown>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))
                      }

                  </Row>
                  ):(
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '15vh', // Adjust the height as needed to fill the available space
                      width: '100%' // Ensure it takes the full width of the container
                    }}>
                    <h2 style={{ textAlign: 'center' }}>No Going Events</h2>
                  </div>
                  )}
                  
                </div>
            </div>
            <div className="d-flex justify-content-center">
              <BasicsPagination />
            </div>
            <DeletedEventModal
                isOpen={deleteOpen}
                updateOpen={toggleDeleteOpen}
            />
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
        </Fragment>
    )
}

export default MyGoing
