import React from 'react'
import { Fragment, useState } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { AtSign, Check, ChevronsDown, ChevronsUp, Clock, Globe, Info, Lock, MapPin, Phone, UploadCloud, X, Search, Circle, XCircle } from 'react-feather';
import '../biz.css'

const SetUpActionModal = (props) => {



    const handleCloseModal = () => {
        // Call the function to close the modal (props.updateOpen)
        props.updateOpen();
    };


    const SetUpData = [
        {
            title: "Chat with us on MsgMee",
            sabTitle: "Allow people to contact your Page privately on SocioMee messenger.",
        },
        {
            title: "Call Me",
            sabTitle: "Allow people to call you to know more about your business.",
            subtitle: " Add a new number"
        },
        {
            title: "Send Email",
            sabTitle: "Choose an email address where people can contact you.",
            subtitle: " Add a Email"
        },
        {
            title: "Sharing",
            sabTitle: "Allow people to share your Page on SocioMee.",
        }
    ]
    const [activeSectionIndex, setActiveSectionIndex] = useState(null);
    const [sliderStates, setSliderStates] = useState(SetUpData.map(() => false));

    const handleSliderToggle = (index) => {
        setSliderStates((prev) => {
            const newStates = [...prev];
            newStates[index] = !newStates[index];
            setActiveSectionIndex(newStates[index] ? index : null); // Set the active section index
            return newStates;
        });
    };





    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        const limitedValue = inputValue.slice(0, 15); // Limit to 10 digits
        setNewEmail(limitedValue);


    };
    const [inputValue, setInputValue] = useState('');
    const [addedEmail, setAddedEmail] = useState(null);
    const handleInputChange1 = (event) => {
        setInputValue(event.target.value);
        setAddedEmail(null);
    };

    const handleAddEmail = () => {
        if (inputValue.trim() !== '') {
            setEmailAddresses((prevEmails) => [...prevEmails, inputValue.trim()]);
            setInputValue('');
            setAddedEmail(inputValue.trim());
        }
    };
    const handleVerifyClick1 = () => {
        setVerifyDivOpen(true);
    };
    const handleCloseVerifyDiv = () => {
        setVerifyDivOpen(false);

    };
    const handleConfirmButtonClick = () => {
        setVerifyDivOpen(false);

        // If email is verified, set the state variable accordingly
        if (addedEmail) {
            setEmailVerified(true);
        }
    };

    const [removeOpen, setRemoveOpen] = useState(false)
    const toggleRemove = () => {
        toggleEmail(false)
        setRemoveOpen((preState) => !preState)
    }
    const toggleEmail = () => {
        setEmailOpen((preState) => !preState)
        setEmailOpen(!emailOpen)
    }
    const [verifyDivOpen, setVerifyDivOpen] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false)
    const [emailList, setEmailList] = useState(['9876543210']);
    const [emailAddresses, setEmailAddresses] = useState(['sociomee@gmail.com']);
    const [newEmail, setNewEmail] = useState('');
    const [contactOpen, setContactOpen] = useState(false)
    const [addNumberModal, setAddNumberModal] = useState({ id: "", isOpen: false })
    const [addEmailModal, setAddEmailModal] = useState({ id: "", isOpen: false })
    const toggleContact = () => {
        setContactOpen((preState) => !preState)
        setContactOpen(!contactOpen)
    }

    const toggleNumRemove1 = (index) => {
        // Implement your logic for removing a number at the specified index
        const updatedList = [...emailList];
        updatedList.splice(index, 1);
        setEmailList(updatedList);
    };

    const handleAddButtonClick = () => {
        if (newEmail.trim()) {
            // Add the new email to the list
            setEmailList([...emailList, newEmail]);

            // Clear the input field after adding the email
            setNewEmail('');
            setShowOTPSection(false);
        }
    };
    const [showOTPSection, setShowOTPSection] = useState(false);

    const handleVerifyClick = () => {
        // Your verification logic can go here

        // If verification is successful, show the OTP section
        setShowOTPSection(true);
    };
    const handleCancelClick = () => {
        // Your cancel logic can go here
        setShowOTPSection(false); // Hide the OTP section when canceling
    };

    return (

        <Fragment>
            <div className='SetUp_modal_section'>
                {
                    props.isOpen !== false &&
                    <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered setupmargain'>
                        <div
                            onClick={handleCloseModal}

                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>
                        <div className='w-100 border-bottom'>
                            <div className=' mx-3 py-3  d-flex justify-content-between Heading_Invite_Section'>
                                <h4 >Action Button</h4>
                                <span style={{ cursor: "pointer", color: "#81C14B" }} onClick={handleCloseModal} >Done</span>
                            </div>
                        </div>
                        <ModalBody>
                            <div className=''>
                                {
                                    SetUpData.map((data, index) => (
                                        <div className="Biz_Creation_Main_blk ">
                                            <div className='Biz_Creation_setUp_section' key={index} >
                                                <div>
                                                    <h5>{data.title}</h5>
                                                    <small className='sm' style={{ height: "60px" }} >{data.sabTitle}</small>
                                                    &nbsp;
                                                    {sliderStates[index] && <p onClick={() => {data.title === "Call Me" ? setAddNumberModal({ id: index, isOpen: true }) : setAddEmailModal({ id: index, isOpen: true })}} style={{ color: "#81C14B" }} >{data.subtitle}</p>}
                                                    {/* ---------------------- add number modal */}
                                                    {addNumberModal.id === index && (addNumberModal.isOpen) && (
                                                        <div>
                                                            {emailList.map((email, index) => (
                                                                <div key={index} className='BizAbout_Map_section mb-3'>
                                                                    <h4>{email}</h4>

                                                                    <p  >Remove</p>
                                                                </div>
                                                            ))}

                                                            <FormGroup className="d-flex justify-content-between">
                                                                <Input
                                                                    name="location"
                                                                    type="text"  // Use type="text" to allow alphanumeric characters
                                                                    placeholder="Enter your new Phone number"
                                                                    autoComplete="off"
                                                                    maxLength="15"
                                                                    className="mr-3"
                                                                    value={newEmail}
                                                                    onChange={handleInputChange}
                                                                    onKeyPress={(e) => {
                                                                        // Allow only numeric values and prevent exceeding 10 digits
                                                                        if (!/[0-9]/.test(e.key) || newEmail.length >= 15) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <Button color="primary" onClick={handleVerifyClick} disabled={!newEmail.trim()}>
                                                                    verify
                                                                </Button>
                                                            </FormGroup>
                                                            {showOTPSection && (
                                                                <div className='d-flex justify-content-center'>
                                                                    <div className='text-center'>
                                                                        <Label className="AboutBiz_EmailOTP_section mt-3">
                                                                            <h3>Enter OTP</h3>
                                                                        </Label>

                                                                        <div className='d-flex justify-content-center py-3'>
                                                                            <div className='Otp_section'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"  // Use type "text" to allow pattern validation
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"  // Enforce numeric input
                                                                                    inputMode="numeric"  // Improve mobile support
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                    placeholder="0"
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                    placeholder="0"
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                    placeholder="0"
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    placeholder="0"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    placeholder="0"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    placeholder="0"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end AboutBiz_Email_section'>
                                                                            <h4>00:20 sec</h4>
                                                                            {/* <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5> */}
                                                                        </div>
                                                                        <div className='text-center AboutBiz_Email_section'>

                                                                            <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5>
                                                                        </div>
                                                                        <div className='text-center py-2'>
                                                                            <Button outline onClick={handleCancelClick} >Cancel </Button>
                                                                            <Button className="ml-2" onClick={handleAddButtonClick} color="primary">Confirm?</Button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {addEmailModal.id === index && (addEmailModal.isOpen) && (
                                                        <div>
                                      {emailAddresses.map((email, index) => (
                                <div key={index} className='BizAbout_Map_section mb-3'>
                                    <h4>{email}</h4>
                                    {addedEmail === email && <p onClick={handleVerifyClick1} style={{ color: '#81C14B' }}>Verify</p>}
                                    {addedEmail !== email && <p onClick={toggleRemove}>Remove</p>}

                                </div>
                            ))}

                                                            <FormGroup className="d-flex justify-content-between">
                                                                <Input
                                                                    name="location"
                                                                    type="text"  // Use type="text" to allow alphanumeric characters
                                                                    placeholder="Enter your email "
                                                                    autoComplete="off"
                                                                 
                                                                    className="mr-3"
                                                                    value={inputValue}
                                                                    onChange={handleInputChange1}
                                                                    // onKeyPress={(e) => {
                                                                     
                                                                    //     if (!/[0-9]/.test(e.key) || newEmail.length >= 15) {
                                                                    //         e.preventDefault();
                                                                    //     }
                                                                    // }}
                                                                />
                                                                <Button color="primary" disabled={!inputValue.trim()} onClick={handleAddEmail} >
                                    Add
                                </Button>
                                                            </FormGroup>
                                                            {verifyDivOpen && (
                                                                <div className='d-flex justify-content-center'>
                                                                    <div className='text-center'>
                                                                        <Label className="AboutBiz_EmailOTP_section mt-3">
                                                                            <h3>Enter OTP</h3>
                                                                        </Label>

                                                                        <div className='d-flex justify-content-center py-3'>
                                                                            <div className='Otp_section'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"  // Use type "text" to allow pattern validation
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"  // Enforce numeric input
                                                                                    inputMode="numeric"  // Improve mobile support
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                    placeholder="0"
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                    placeholder="0"
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                    placeholder="0"
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    placeholder="0"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    placeholder="0"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                />
                                                                            </div>
                                                                            <div className='Otp_section ml-2'>
                                                                                <Input
                                                                                    maxLength={1}
                                                                                    name="otp"
                                                                                    type="text"
                                                                                    autoComplete="off"
                                                                                    pattern="[0-9]"
                                                                                    inputMode="numeric"
                                                                                    placeholder="0"
                                                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end AboutBiz_Email_section'>
                                                                            <h4>00:20 sec</h4>
                                                                            {/* <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5> */}
                                                                        </div>
                                                                        <div className='text-center AboutBiz_Email_section'>

                                                                            <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5>
                                                                        </div>
                                                                        <div className='text-center py-2'>
                                                                            <Button outline onClick={handleCloseVerifyDiv} >Cancel </Button>
                                                                            <Button className="ml-2" onClick={handleConfirmButtonClick} color="primary">Confirm?</Button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {/* ---------------------- */}
                                                </div>

                                                <div className=' d-flex  slideronoff'>
                                                    <div className="mr-2 Biz_toggle_action_switch py-12">Off</div>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={() => { handleSliderToggle(index); data.title === "Call Me" ?  setAddNumberModal({ id: "", isOpen: false }):setAddEmailModal({ id: "", isOpen: false }) }} checked={sliderStates[index]} />
                                                        <span className="slider round "></span>
                                                    </label>
                                                    <div className='ml-2 Biz_toggle_action_switch py-1' >On</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>

                            </div>
                        </ModalBody>
                    </Modal>

                }
            </div>
        </Fragment>
    )
}

export default SetUpActionModal