import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Card, Col, Row } from 'reactstrap'
import CardContent from '@mui/material/CardContent';
// import Pollimg from '../../../NewComponents/IMG/roundedimg2.svg'
// import Pollimg1 from '../../../NewComponents/IMG/roundedimg3.svg'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal';
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal';
import { trendingTextData } from '../DummyArray';
import ReportModal from './ModalGrid/ReportModal';
import { NavLink } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';


const GridText = () => {
    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    const [shareOpen, setShareOpen] = useState(false)
    const [seemore, setSeeMore] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }


    // ------------------------------------------------Main return function-----------------------------------------------------------
    return (
        <div className='p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />

            {/* <Row className='PeopleThisGrid'> */}

            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 667: 1, 1157: 3 }}
            >
                <Masonry columnsCount={3} gutter="12px" >
                    {
                        trendingTextData.map((data, index) => {
                            return (
                                // <Col xl='3' className='w-100 p-1' key={index} >
                                <div className=''>
                                    <Card className='roundedThisBorder on-hover-bg' style={{height:'292px'}}>
                                        {/*  style={{ height: '270px' }} */}
                                        <div className='d-flex px-2 mt-2'>
                                            <NavLink to='/MyProfile'> <Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                                Trending #{data.trendingNumber}
                                            </Badge></NavLink>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                                    Top #{data.topsNumber}
                                                </Badge>
                                            </NavLink>
                                        </div>
                                        <div className='d-flex justify-content-between  m-2'>
                                            <div className='d-flex align-items-center '>
                                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                                <div className='ml-2'>
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }} > <h4 className='user-profile'>{data.name}</h4></NavLink>
                                                    <small className='text-muted light-for-date'>{data.dateAndTime}</small>
                                                </div>
                                            </div>

                                            <div>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                                        <MoreHorizontal size={19} color='#999999' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: 'black' }} > <Eye size={13} />&nbsp;View creator's profile</NavLink></DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </div>
                                        {/* <div className='d-flex'>
                                            <p className='Celebration'>Celebration new album song launched</p>
                                        </div> */}
                                        {/* <CardContent> */}
                                        {/* <div className='px-3 pb-3'> */}
                                        {/* <p><b className='Musiccelebration'>#Musiccelebration,</b>#Musiccelebration, #music, #party, #music</p> */}

                                        {/* <p className='text-muted'>Lorem Ipsum is simply dummy text of{seemore ? 'has been the industrys standard dummy text ever since the 1500s ' : null}<span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p> */}
                                        {/* <p className='text-muted user-profile mt-1 rounded-2 p-2 ' style={{ backgroundColor: "#f7f5f3", height: "auto", marginLeft: '-7px' }}>
                                                <small className='text-primary user-profile'>{data.Astronomy}</small><br />
                                                {data.trendingText}<small style={{ color: '#81c14b' }}>{data.more}</small></p>
                                        </div> */}
                                        <div className='px-3 pb-3' >
                                            {/* textAstronomy */}
                                            <p className='text-muted user-profile mt-1 p-2 rounded-2 ' style={{ backgroundColor: "#f7f5f3", height: "118px", marginLeft: '-7px ', marginRight: '-7px' }}>
                                                <small className='text-primary user-profile'>{data.Astronomy}</small><br />
                                                <small className='textprofile'>{data.trendingText}</small><small style={{ color: '#81c14b' }}>{data.more}</small></p>
                                        </div>
                                        {/* </CardContent> */}


                                        <div className='Treanding_bottom_section' style={{position: 'absolute', bottom: '0px' }}>
                                            {/*  position: 'absolute', bottom: '0px', */}
                                            <div className='d-flex align-items-center'  >
                                                <div onClick={() => handleLike()}>
                                                    {liked ?
                                                        <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                        :
                                                        <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                    }
                                                </div>
                                                11.2k
                                            </div>
                                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                                </div>
                                                11.5k
                                            </div>
                                            <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                                </div>
                                                11.2k
                                            </div>
                                        </div>

                                    </Card>
                                </div>
                                // </Col>
                            )
                        })
                    }
                </Masonry>
            </ResponsiveMasonry>
            {/* </Row> */}

            {/* ------------------------------------------------Modal components start----------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* ------------------------------------------------Modal components end----------------------------------------------------------- */}

        </div>
    )
}

export default GridText