import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { XCircle } from 'react-feather';
import { Modal, ModalHeader } from 'reactstrap';
import UserImg from '../../../NewComponents/IMG/img.svg'
import { Box } from '@mui/material';

const TagFriends = (props) => {
    const [peopleval, setpeopleVal] = useState([]);

    const names = [
        {
            name: 'Oliver Hansen',
            category: 'SocioMate'
        },
        {
            name: 'Oliver Hansen',
            category: 'SocioMate'
        },
        {
            name: 'Oliver Hansen',
            category: 'SocioMate'
        }
    ];
    const SuggetionPeople = [
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        },
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        },
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        },
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        },
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        },
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        },
        {
            PeopleProfile: UserImg,
            connection: 'SocioMate',
            suggestatePeople: 'Roshani singh'
        }
    ]

    const TagPeopleChip = peopleval.map((option, index) => {
        const label = option.title || option;
        return (
            <Chip
                sx={{ margin: '1%' }}
                key={label}
                label={label}
                deleteIcon={<XCircle size={18} />}
                onDelete={() => {
                    setpeopleVal(peopleval.filter(entry => entry !== option));
                }}
            />
        );
    });

    return (
        <>
            {props.openModal ? (
                <Modal isOpen={props.openModal} className='modal-dialog-centered' toggle={props.peopleModal} >
                    <ModalHeader toggle={props.peopleModal} className='d-flex justify-content-center'>
                        <h4 className='font-weight-bold' style={{ cursor: 'pointer' }}>Tag People</h4>
                    </ModalHeader>
                    <div className='mx-2'>
                        <Stack sx={{ width: '100%', marginTop: '0px' }}>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                freeSolo
                                filterSelectedOptions
                                options={names.slice(0, 2).map((option) => option)}
                                onChange={(e, newValue) => setpeopleVal(newValue)}
                                getOptionLabel={option => option.name}
                                renderTags={() => { }}
                                value={peopleval}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                        <div role='button' className='d-flex align-items-center m-1 p-1'>
                                            <img
                                                loading="lazy"
                                                width={35}
                                                height={35}
                                                className='border rounded-circle'
                                                src='/assets/images/pages-logo3.jpg'
                                                alt=""
                                            />
                                            <div className='ml-2'>
                                                <h5 className='text-secondary font-weight-bold'>{option.name}</h5>
                                                <p className='text-muted'>{option.category}</p>
                                            </div>
                                        </div>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className='border rounded'
                                        placeholder="Search ..."
                                        margin="normal"
                                    />
                                )}
                            />
                            {TagPeopleChip.length > 0 ?
                                <div className="selectedTags border">{TagPeopleChip}</div> : null
                            }
                        </Stack>
                        <div className='scrollSuggestion mx-3'>
                            <h4 className='font-weight-bold on-hover-bg p-2' style={{ cursor: 'pointer' }}>Suggestion</h4>
                            <div className='my-1 mt-2'>
                                {SuggetionPeople.map((data) => (
                                    <div className='d-flex align-items-center py-1 on-hover-bg p-2' role='button' >
                                        <img src={data.PeopleProfile} width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                                        <div className='my-1 ml-1'>
                                            <h5 className='text-secondary font-weight-bold'>{data.suggestatePeople}</h5>
                                            <p className='text-muted'>{data.connection}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal>
            ) : (null)}
        </>
    )
}
export default TagFriends