
import React, { useState } from 'react'
import { AlertTriangle, Bell, Circle, Delete, Edit, Eye, Filter, Globe, Link, MoreHorizontal, Send, Share2, Star, Users, UserX } from 'react-feather'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Input, InputGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import AvatarGroupComponent from './AvatarGroupComponent'
import cover from '../../NewComponents/IMG/cover.png'
import cover1 from '../../NewComponents/IMG/cover1.png'
import { NavLink } from 'react-router-dom'
import Slider from "react-slick";
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'


let ownedGroupSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1
            }
        },
    ]
};

const Group = () => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const Groups = [
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            button: <Button color='primary' outline>Requested</Button>,
            cover: cover1
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Traditional Wedding',
            button: <button className='GroupTabs_Join_Button'>Join Group</button>,
            cover: cover
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            button: <Button color='primary' outline>Requested</Button>,
            cover: cover1
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            button: <Button color='primary' className='px-4'>View Activity</Button>,
            button1: <Button color='primary' outline>Unfollow</Button>,
            cover: cover
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            button: <Button color='primary' className='px-4'>View Activity</Button>,
            button1: <Button color='primary' outline>Unfollow</Button>,
            cover: cover
        }

    ]
    return (
        <div>
            <Card className='p-2 border-0'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col md='6'>
                        <div className='d-flex mt-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Uptown Area”</p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='d-flex justify-content-end'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view ml-2'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="group-custom-block">
                    <Slider {...ownedGroupSettings} className="default-space">
                        {
                            Groups.map((data) => (
                                <div>
                                    <div className="group-slide-box border mt-4">
                                        <div className="gallery-img-sublink-this-bizpage">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color='flat-primary' >
                                                    <MoreHorizontal color='#FAF7F7' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                        <NavLink className='bizpagelink-blk'>
                                            <img src={data.cover} className="img-fluid group-img" />
                                            <div className=''>
                                                <p className='grouptittlecenter'>Uptown Area</p>
                                            </div>
                                            <div className='d-flex justify-content-center mt-2'>
                                                <p className='mr-1 align-self-center'><Globe size={16} color='#4D4D4D' /></p>
                                                <p className='text-dark'>Public Group&nbsp;<Circle fill='#D9D9D9' size={6} strokeWidth={0} />Sport</p>
                                            </div>
                                            <div className="d-flex justify-content-center  mt-2">
                                                <AvatarGroupComponent className='align-self-center' />
                                                {/* <p className='text-secondary align-self-center'><h6> +79k Members</h6></p> */}
                                                <h6 className='text-secondary align-self-center'> +79k Members</h6>
                                            </div>
                                            <div className='d-flex justify-content-center mt-3 p-2'>
                                                {data.button}
                                                &nbsp;
                                                {data.button1}
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>

                            ))
                        }

                    </Slider>
                </div>
            </Card>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Group</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Group
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <h4 className='mt-3'>Choose Category</h4>
                        <ChipBox />
                        {/* <div className=''>
                            <h4 className='mt-3'>Choose Interest</h4>
                            <ChipBox />
                        </div> */}
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default Group