import React from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import Pollimg from '../../../NewComponents/IMG/Pollimg.png'
import { AlertTriangle, Eye, Link, Mic, MoreHorizontal, Share2 } from 'react-feather'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import PaidSvgImg from '../../../NewComponents/IMG/PaidSvgImg.svg'
// import tagpic2 from '../../../NewComponents/IMG/tagpic2.png'
import { useState } from 'react'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
// import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import ReportModal from './ModalGrid/ReportModal'
import { cardDataTopPodcast } from '../DummyArray'
import { NavLink } from 'react-router-dom'
import MicHover from '../../../NewComponents/IMG/podcastIcon.svg'
import { useUserAuth } from '../../../Context/userAuthContext'
import PodcastFooter from '../../podcast-module-component/PodcastFooter'


const GridPodcastTab = () => {

    //  ============================================State to manage Podcast Footer Musicbar called from context=======================
    const { musicBar, setMusicBar } = useUserAuth();

    //   ==========================Function for Music Bar ==================================
    const handleMusicBar = () => {
        setMusicBar(true);
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const [hover, sethover] = useState(0);


    // --------------------------------------------------------Main return function--------------------------------------------------------
    return (
        <div className=' p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
            <Row className='PeopleThisGrid'>
                {cardDataTopPodcast.map((item, index) => (

                    <Col lg='3' md='4' className='w-100 p-1' key={index}  >
                        <div className='Video_Treanding_card on-hover-bg media_card' onMouseOver={() => { sethover(index + 1) }} onMouseLeave={() => { sethover(0) }} >
                            <div className='d-flex pb-2'  >
                                <NavLink to='/MyProfile'> <Badge color='primary' pill className=' px-2 d-flex align-items-center light-font'>
                                    Top #{item.topsNumber}
                                </Badge></NavLink>
                                <NavLink to='/MyProfile'>
                                    <Badge color='light-primary' className='px-2 ml-2 d-flex align-items-center light-font' >
                                        Trending #{item.trendingNumber}
                                    </Badge>
                                </NavLink>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center ' onClick={() => handleMusicBar()} >
                                    <div>
                                        <div style={{ position: 'relative' }} >
                                            <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                        </div>
                                        <div style={{ position: 'absolute', top: '45%', zIndex: '5', left: '45%' }} className=' w-100' >
                                            <img className={`${hover === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                        </div>
                                    </div>
                                    <div className='ml-2'>
                                        <h4 className='user-profile' >{item.name}</h4>
                                        <small className='text-muted light-for-date'>{item.dateandtime}</small>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown className='align-self-center' style={{ marginTop: '-30px' }}>
                                    <DropdownToggle className='p-0 m-0 ml-2' color='flat' >
                                        <MoreHorizontal size={19} color='#999999' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Podcast</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View creator's profile</NavLink></DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>

                            {/* <div className='Potrate_treanding_img pt-2'>
                                <img src='https://img.freepik.com/free-photo/diverse-team-people-working-studio-record-podcast-show-using-camera-sound-equipment-man-woman-having-fun-with-chat-live-broadcast-social-media-channel_482257-42297.jpg?w=1380&t=st=1675492904~exp=1675493504~hmac=a868f23fde921d16302a50a96ff284e8e4cc5300ec3646d9213f4578b006fcee' alt='img' height={210} />
                                <div className='TreandingThisGame'>
                                    <small>{item.category}</small>
                                    <h4>{item.title}</h4>
                                    <div className='PaidThisImg'>
                                        <img src={PaidSvgImg} alt='paid' />
                                    </div>
                                </div>
                                <div className='MicThisNum'>
                                    <Mic size={17} />
                                    <p>34k</p>
                                </div>
                            </div> */}
                            {/*==================================================== list card=============================================== */}
                            <div className='Potrate_treanding_img pt-2'>

                                <div style={{ position: 'relative' }} >
                                    <img src={item.podcastImg} alt='img' height={210} />
                                    <div style={{ position: 'absolute', top: '0px', borderRadius: '10px 10px 0 0' }} className='simple-linear w-100' >
                                        <div className='text-white d-flex py-2 px-2'>
                                            {
                                                index === 0 ? <Mic size={17} /> : " "
                                            }
                                            {
                                                index === 1 ? <Mic size={17} /> : " "
                                            }
                                            {
                                                index === 2 ? <Mic size={17} /> : " "
                                            }
                                            {
                                                index === 3 ? <Mic size={17} /> : " "
                                            }
                                            <p>{item.listenerCount}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='TreandingThisGame' style={{ height: '80px' }}>
                                    {/* =========================add text======================= */}
                                    {
                                        index === 0 ? <h4 style={{ marginLeft: '-11px', marginTop: '19px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 1 ? <h4 style={{ marginLeft: '-11px', marginTop: '19px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 2 ? <h4 style={{ marginLeft: '-11px', marginTop: '19px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 3 ? <h4 style={{ marginLeft: '-11px', marginTop: '19px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 4 ? <h4 style={{ marginLeft: '-11px', fontSize: '14px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 5 ? <h4 style={{ marginLeft: '-11px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 6 ? <h4 style={{ marginLeft: '-11px' }}>{item.title}</h4> : " "
                                    }
                                    {
                                        index === 7 ? <p style={{ marginLeft:'-6px' }}>{item.title}</p> : " "
                                    }
                                    <small style={{ marginLeft: '-11px' }}>{item.category}</small>
                                    <div className='PaidThisImg' >
                                        {/* {
                                            index === 0 ? <img src={PaidSvgImg} alt='paid' style={{
                                                marginTop: '-1px'
                                            }} /> : " "
                                        } */}
                                        {/* {
                                            index === 1 ? <img src={PaidSvgImg} alt='paid' className='svgtext' style={{
                                                marginTop: '-1.5px'
                                            }} /> : " "
                                        } */}
                                        {/* {
                                            index === 2 ? <img src={PaidSvgImg} alt='paid' style={{
                                                marginTop: '-1px'
                                            }} className='paid-card' /> : " "
                                        }
                                        {
                                            index === 3 ? <img src={PaidSvgImg} alt='paid' style={{
                                                marginTop: '-1px'
                                            }} className='paid-card' /> : " "
                                        } */}
                                        {
                                            index === 3 ? <img src={PaidSvgImg} alt='paid' className='' style={{
                                                marginTop: '23px'
                                            }} /> : " "
                                        }
                                    </div>
                                </div>
                                {
                                    index === 4 ? <div className='TreandingThisGames'>

                                        <small style={{ marginLeft: '-11px' }}>{item.category}</small>&nbsp;<Mic size={17} />5k
                                        <div className='PaidThisImg'>
                                            <img src={PaidSvgImg} alt='paid' style={{
                                                marginTop: '-3px'
                                            }} />
                                        </div>
                                    </div> : " "
                                }
                                {
                                    index === 7 ? <div className='TreandingThisGames'>

                                        <small style={{ marginLeft: '-11px' }}>{item.category}</small>&nbsp;<Mic size={17} />5k
                                        <div className='PaidThisImg'>
                                        </div>
                                    </div> : " "
                                }
                                <div>
                                    {
                                        index === 4 ? <div className='TreandingThisGamess d-flex justify-content-end' style={{ maxWidth: '100%', padding: '0 20px' }}>
                                            <Button className='mt-2 topepisode ' style={{
                                                backgroundColor: '#81C14B',
                                                color: 'white',
                                                width: 'auto',
                                                padding: '10px 20px',
                                                borderRadius: '5px',
                                                marginLeft: '1rem'
                                            }}>All episodes</Button>

                                        </div> : " "
                                    }
                                </div>
                                <div>
                                    {
                                        index === 5 ? <div className='TreandingThisGamess d-flex justify-content-end' style={{ maxWidth: '100%', padding: '0 20px' }}>
                                            <Button className='mt-2 topepisode ' style={{
                                                backgroundColor: '#81C14B',
                                                color: 'white',
                                                width: 'auto',
                                                padding: '10px 20px',
                                                borderRadius: '5px',
                                                marginLeft: '1rem'
                                            }}>All episodes</Button>
                                        </div> : " "
                                    }
                                </div>
                                {
                                    index === 6 ? <div className='TreandingThisGamess'>
                                        <div className='PaidThisImg'>
                                            <img src={PaidSvgImg} alt='paid' style={{ marginTop: '9rem' }} />
                                        </div>
                                    </div> : " "
                                }
                            </div>
                            <div className='Treanding_bottom_section'>
                                <div className='d-flex align-items-center'>
                                    {/* {liked ?
                                    <img src={Circlelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    :
                                    <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                } */}
                                    <div onClick={() => handleLike()}>
                                        {liked ?
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /></div>
                                            :
                                            <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                        }
                                    </div>
                                    &nbsp;
                                    11.2k
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                    </div>
                                    11.2k
                                </div>
                                <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                    </div>
                                    11.2k
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
                {/* <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='primary' pill>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://img.freepik.com/free-photo/3d-render-retro-microphone-short-leg-stand-with-headphone-3d-illustration-design_460848-10407.jpg?w=1380&t=st=1675492835~exp=1675493435~hmac=f093d724b7c9e94a42d948f5610e181489c7ad976caddbccf657d86eb302c7cf' alt='img' height={210} />
                            <div className='TreandingThisGame'>
                                <small>Category</small>
                                <h4>Podcast Title</h4>
                                <div className='PaidThisImg'>
                                    <img src={PaidSvgImg} alt='paid' />
                                </div>
                            </div>
                            <div className='MicThisNum'>
                                <Mic size={17} />
                                <p>34k</p>
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col>
                <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='primary' pill>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://img.freepik.com/free-photo/excited-famous-internet-live-show-host-ending-broadcast-while-sending-air-kisses-audience-popular-internet-star-saying-goodbye-public-while-sitting-home-studio-desk_482257-45845.jpg?w=1480&t=st=1675492782~exp=1675493382~hmac=00b07e71084200cdd3364a68e14ee63b06df0d7e5afd6dba1a7ac3206d4d7abc' alt='img' height={210} />
                            <div className='TreandingThisGame'>
                                <small>Category</small>
                                <h4>Podcast Title</h4>
                                <div className='PaidThisImg'>
                                    <img src={PaidSvgImg} alt='paid' />
                                </div>
                            </div>
                            <div className='MicThisNum'>
                                <Mic size={17} />
                                <p>34k</p>
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col>
                <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='primary' pill>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://img.freepik.com/free-photo/front-view-smiley-woman-radio-with-microphone-notebook_23-2148808806.jpg?w=1380&t=st=1675492706~exp=1675493306~hmac=e05690f0c1714eee20e25c99f137f41d30d09e6724b4019a5a72b5e3113220d7' alt='img' height={210} />
                            <div className='TreandingThisGame'>
                                <small>Category</small>
                                <h4>Podcast Title</h4>
                                <div className='PaidThisImg'>
                                    <img src={PaidSvgImg} alt='paid' />
                                </div>
                            </div>
                            <div className='MicThisNum'>
                                <Mic size={17} />
                                <p>34k</p>
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col> */}

            </Row>

            {/* -----------------------------------------------Podcast Musicbar bottom stripe component------------------------------------------------------------- */}
            <PodcastFooter />

            {/* --------------------------------------------------------Modal part start-------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* --------------------------------------------------------Modal part end-------------------------------------------------------- */}

        </div >
    )
}

export default GridPodcastTab