import React from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { NavLink } from 'react-router-dom';
const MyMedia = () => {

    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="page-content">
                            <div className="content-center w-100">
                                <div className="gallery-page-section section-b-space">
                                    <div className="card-title">
                                        <h3>Media</h3>
                                        <div className="right-setting">

                                            <NavLink to="/MyMediaListView" className="btn btn-outline-grey ms-3"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg> List view</NavLink>
                                            <a href="#" className="btn btn-outline-green ms-3">Add photo/video</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RightSidebar />

            </div>
        </>
    )
}

export default MyMedia