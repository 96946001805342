import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { loadTaggedPeople } from "../../Services/Actions/Common/taggedPeopleAction";
import { loadAllTrendingHashtag } from "../../Services/Actions/Common/trandingHashtagAction";
import { deletePost } from "../../Services/Actions/SocialFeed/createSocialpost";
import { loadProfileByUserId } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { emoteData } from "../Social-Feed-Component/FeedArray";
import { loadEventCategory } from "../../Services/Actions/SocialFeed/getEventCategoryAction";
import { loadAllReactions } from "../../Services/Actions/SocialFeed/getAllReactionsAction";
import PostCreation from "./PostCreation";
import FormatTimeAgo from "./FormatTimeAgo";
import { loadAlertLevel } from "../../Services/Actions/SocialFeed/getAlertDataAction";
import { loadAlertRange } from "../../Services/Actions/SocialFeed/getAlertDataAction";
import { loadAllFeelingCategory } from "../../Services/Actions/Common/getFeelingCategoryAction";
import PostCard from "./Components/PostCard";
import { loadAllUserPosts } from "../../Services/Actions/SocialFeed/getAllUserPostsAction";
import getAllUserPosts from "../../Services/Reducers/SocialFeed/getAllUserPosts";
import { loadAllCommments } from "../../Services/Actions/SocialFeed/getAllCommentsAction";
import ShareToModal from "../top-trending-components/ShareToModal";
import { loadAllUserSciomate } from "../../Services/Actions/Connection/getSociomateAction";
import { loadAllConnections } from "../../Services/Actions/SocialFeed/getConnectionListAction";
import { loadAllUserGroups } from "../../Services/Actions/SocialFeed/getUserGroupsAction";
import { loadAllUserConnection } from "../../Services/Actions/Common/getUserConnectionAction";
import { loadAllFeedReportOptions } from "../../Services/Actions/SocialFeed/getFeedReportOptionsAction";
import ConnectionReportModal from "../Connections/ConnectionModal/ConnectionReportModal";
import ReportPostModal from "../post-components/Modals/ReportPostModal";
import { getFeedReportClick } from "../../Services/Actions/SocialFeed/FeedReportClickAction";
import { getActionBlockUnblock } from "../../Services/Actions/Connection/getBlockUnblockAction";
import { loadAllFeedStories } from "../../Services/Actions/Story/getAllstoryActions";
import { loadSearchdata } from "../../Services/Actions/mainSearchAction";
import { useLocation } from 'react-router-dom';

const UserMainCreation = (props) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const location = useLocation();
  const [VideoOpen, setVideoClose] = useState(false);
  const toggleVideo = () => {
    setVideoClose(!VideoOpen);
  };
  const [LikeIcon, SetLikeIcon] = useState(false);
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeCount, setLikeCount] = useState();

  const [BookmarkIcon, SetBookmark] = useState(true);
  const [comment, SetComment] = useState(false);
  const [commentDataArray, setCommentDataArray] = useState([]);

  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [path, setPath] = useState("/modal/");
  const [postType, setPostType] = useState();
  const [pageIndex,setPageIndex]=useState(0);
  const [pageSize,setPageSize]=useState(10);

  const [selectedOption, setSelectedOption] = useState("");

  const [ForwardArrayData, setForwardArrayData] = useState([]);

  const toggle = (path) => {
    if (path.indexOf("/modal/" > -1)) {
      if (modal) {
        setActiveTab(parseFloat(path.replace("/modal/", "")));

        setPath(path);

        const index = parseFloat(path.replace("/modal/", ""));

        const postType =
          index === 0.1
            ? "media"
            : index === 1
            ? "feeling"
            : index === 7.1
            ? "thought"
            : index === 7.3
            ? "event"
            : index === 7.6
            ? "recomendation"
            : index === 7.7
            ? "alert"
            : index === 7.8
            ? "poll"
            : "text";
        setPostType(postType);
      } else {
        setActiveTab(parseFloat(path.replace("/modal/", "")));
        setModal(true);
        setPath(path);
        console.log("modal", modal);
      }
    } else {
      setModal(false);
    }
  };

  const likePost = (postId) => {
    // Add logic here to like the post using an API request
    const requestData = {
      postId: postId,
      reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
    };

    axios
      .post(`${process.env.REACT_APP_IPURL}/post/setLike`, requestData, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((response) => {
        // console.log("Post liked successfully");
        setLikeStatus((prevStatus) => ({
          ...prevStatus,
          [postId]: true,
        }));
      })
      .catch((error) => {
        console.error("Error liking post: ", error);
      });
  };

  const unlikePost = (postId) => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/post/setLike`,
        { postId: postId },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      )
      .then((response) => {
        setLikeStatus((prevStatus) => ({
          ...prevStatus,
          [postId]: false,
        }));
      })
      .catch((error) => {
        console.error("Error unliking post: ", error);
      });
  };

  const Commenttoggle = () => {
    SetComment(!comment);
  };
  const [commentR, SetCommentR] = useState("");
  const CommenttoggleR = () => {
    SetCommentR("I am seeking recommendation for buying ");
  };

  const [forwardOpen, setForwardOpen] = useState(false);
  const toggleForward = () => {
    setForwardOpen((preState) => !preState);
  };

  //  ------------- Start ReportedOption  -------------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
  };

  const EmoteAvatar = () => {
   
    // // return (
    // //   <div className="px-2">
    // //     <ul className="matual-friend-blk">
    // //       {allLikes?.map((item, index) => {
    // //         return (
    // //           <li
    // //             key={index}
    // //             className="popover-cls"
    // //             data-bs-toggle="popover"
    // //             data-placement="right"
    // //             data-name="sufiya eliza"
    // //             data-img={item.profileImageThumb}
    // //           >
    // //             <img src={item.profileImageThumb} className="bg-img" alt="" />
    // //           </li>
    // //         );
    // //       })}
    // //     </ul>
    // //   </div>
    // );
  };

  // const { userProfileByUserId } = useSelector(
  //   (state) => state.getUserProfileByUserIdData
  // );

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(loadProfileByUserId(userId));
    dispatch(loadAllUserPosts("",pageIndex,pageSize,props?.userId||user.id));
  }, [dispatch]);

  const { trandingHashtags } = useSelector(
    (state) => state.TrendingHashtagData
  );
  const { eventCategory } = useSelector((state) => state.getEventCategoryData);
  const { taggedPeople } = useSelector((state) => state.TaggedPeopleData);
  const { alertLevel } = useSelector((state) => state.getAlertData);
  const { alertRange } = useSelector((state) => state.getAlertData);
  const { feelingCategory } = useSelector((state) => state.FeelingCategoryData);
  const { comments } = useSelector((state) => state.getAllCommentsData);
  const { allReactions } = useSelector((state) => state.getAllReactionsData);
  const { getUserSociomate } = useSelector((state) => state.userSociomateData);
  const { allUserPosts } = useSelector((state) => state.getAllUserPostsData);
  const { getPostReport } = useSelector((state) => state.getPostReportData);
  const { allStories } = useSelector((state) => state.getAllStoryData);
  const { allLikes } = useSelector((state) => state.getPostLikesData);
  const { allConnections } = useSelector(
    (state) => state.getAllConnectionsData
  );
  const { allGroups } = useSelector((state) => state.getUserGroupsData);
  const { getUserConnection } = useSelector(
    (state) => state.getUserConnectionData
  );
  const { getFeedReportOption } = useSelector(
    (state) => state.getUserReportOptionsData
  );
  // const { searchData } = useSelector(
  //   (state) => state.getSearchUserData
  // ); 
  


 

  useEffect(() => {
    dispatch(loadAllTrendingHashtag());
    dispatch(loadTaggedPeople());
    dispatch(loadEventCategory());
    dispatch(loadAlertLevel());
    dispatch(loadAlertRange());
    dispatch(loadAllFeelingCategory());
    dispatch(loadAllCommments());
    dispatch(loadAllReactions());
    dispatch(loadAllUserSciomate());
    dispatch(loadAllUserPosts());
    dispatch(loadAllConnections());
    dispatch(loadAllUserGroups(props?.userId||user.id));
    dispatch(loadAllUserConnection());
    dispatch(loadAllFeedReportOptions());
    dispatch(getFeedReportClick());
    dispatch(loadAllFeedStories());
    // dispatch(loadSearchdata())
  }, []);

  //---------------------handle Edit-----------------------------------------//
  const handleEdit = async (postId, userId, caption, postType) => {
    try {
      // Create the request body with postId and userId
      const requestBody = {
        userId: userId,
        id: postId,
        caption: caption,
        postType: postType,
      };

      // Make an API POST request to edit the post using axios
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/editPost`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json", // Set the content type
          },
        }
      );

      if (response.status === 200) {
        dispatch(loadAllUserPosts('',pageIndex,pageSize,userId)); // Assuming this function fetches the updated data
      } else {
        console.error(
          "Error editing post. Server responded with status:",
          response.status
        );
        // Handle other error cases as needed
      }
    } catch (error) {
      // Handle any errors that occur during the edit process
      console.error("Error editing post:", error);
    }
  };

  //===========================handlecomment==================================//
  // const handleComment = async (
  //   postId,
  //   commentData,
  //   setCommentCount,
  //   commentCount,
  //   setCommentData
  // ) => {
  //   try {
  //     const requestBody = {
  //       postId: postId,
  //       comment: commentData,
  //       commentId: "",
  //       hashTags: [],
  //       mentionIds: [],
  //     };

  //     const response = await axios.post(
  //       `${process.env.REACT_APP_IPURL}/post/addComment/`,
  //       requestBody,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${
  //             JSON.parse(localStorage.getItem("sociomeeUser"))?.token
  //           }`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log(response);
  //     if (response.data.success == true) {

  //       setCommentCount(commentCount + 1);
  //     }
  //   } catch (error) {
  //     console.error("Error commenting on post:", error);
  //   }
  // };

  const handleAllComments = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        commentId: "",
        searchKey: "",
        pageIndex: 0,
        pageSize: 20,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/getComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("load comments section ",response.data.data.successResult.rows)

      setCommentDataArray(response.data.data.successResult.rows);
      console.log(commentDataArray)
    } catch (error) {
      console.error("Error commenting on post:", error);
    }
  };
  //=========================handleBookmark=================================//
  const handleSave = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isSaved: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostSaved`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving on post:", error);
    }
  };

  const handleUnsave = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isSaved: false,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostSaved`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error saving on post:", error);
    }
  };
  //=============================handleCommentLike==============================//
  const handleCommentLike = (comment, setCommentLikeCount) => {
    const requestData = {
      commentId: comment.id,
      // reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
    };
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/setCommentLike`, requestData, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((response) => {
        console.log("liked", response);
        if (response.data.success === true) {
          setCommentLikeCount(comment.likesCount + 1);
        }
      })
      .catch((error) => {
        console.error("Error liking post: ", error);
      });
  };

  //==============================handleHidePost=======================//
  const handleHidePost = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isHidden: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostHide`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        dispatch(loadAllUserPosts('',pageIndex,pageSize,props?.userId||user.id));
      } else {
        console.error(
          "Error Hiding post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  //==============================handleHidePost=======================//
  const handleNotificationOff = async (postId, setIsNotificationOff) => {
    console.log("postId", postId);
    try {
      const requestBody = {
        postId: postId,
        notificationOff: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsNotificationOff(1);
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  const handleNotificationOn = async (postId, setIsNotificationOff) => {
    console.log("postId", postId);
    try {
      const requestBody = {
        postId: postId,
        notificationOff: false,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsNotificationOff(0);
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  //==============================handleAllowComments=======================//
  const handleCommentOff = async (
    postId,
    setIsCommentsAllowed,
    isCommentsAllowed
  ) => {
    console.log("postId comment off", postId);
    try {
      const requestBody = {
        postId: postId,
        allowComments: false,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setAllowComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsCommentsAllowed(0);
        dispatch(loadAllUserPosts('',pageIndex,pageSize,props?.userId||user.id));
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  const handleCommentOn = async (
    postId,
    setIsCommentsAllowed,
    isCommentsAllowed
  ) => {
    try {
      const requestBody = {
        postId: postId,
        allowComments: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setAllowComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setIsCommentsAllowed(1);
        dispatch(loadAllUserPosts('',pageIndex,pageSize,props?.userId||user.id));
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  //=============================handleReportPost======================//
  // const handleReport = async (postId) => {
  //   console.log("called");
  //   console.log(postId);

  //   // console.log("postId", postId);
  //   // try {
  //   //   const requestBody = {
  //   //     postId: postId,
  //   //     reportOptionId: reportId,
  //   //   };

  //   //   const response = await axios.post(
  //   //     `${process.env.REACT_APP_IPURL}/post/reportPost`,
  //   //     requestBody,
  //   //     {
  //   //       headers: {
  //   //         Authorization: `Bearer ${
  //   //           JSON.parse(localStorage.getItem("sociomeeUser"))?.token
  //   //         }`,
  //   //         "Content-Type": "application/json",
  //   //       },
  //   //     }
  //   //   );
  //   //   if (response.status === 200) {
  //   //     dispatch(loadAllUserPosts());
  //   //   } else {
  //   //     console.error(
  //   //       "Error Hiding post. Server responded with status:",
  //   //       response.status
  //   //     );
  //   //   }
  //   // } catch (error) {
  //   //   console.error("Error Hiding of post:", error);
  //   // }
  // };

  //======================handleBlockUnblock=========================//
  const handleBlockUnblock = (postType) => {
    dispatch(getActionBlockUnblock(postType));
  };


  //======================handleProfileView==========================//
  const handleProfileView = () => {
    <Link to="/MyProfile"></Link>;
  };
  //==================== Main_return_function ======================//

  return (
    <Fragment>
      <PostCreation feelingCategory={feelingCategory} userId={props?.userId||user.id} />

      <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} postType={postType}  />
      {props.allPostsByUserId?.rows?.map((postType, index) => {
        // Calculate the time difference for each post's createdAt
        const timeAgo = FormatTimeAgo(postType.createdAt);

        return (
          <PostCard
            postType={postType}
            setPostType={setPostType}
            likePost={likePost}
            unlikePost={unlikePost}
            timeAgo={timeAgo}
            handleProfileView={handleProfileView}
            toggleForward={toggleForward}
            handleSave={handleSave}
            handleUnsave={handleUnsave}
            handleAllComments={handleAllComments}
            commentDataArray={commentDataArray}
            handleCommentLike={handleCommentLike}
            EmoteAvatar={EmoteAvatar}
            handleHidePost={handleHidePost}
            handleNotificationOff={handleNotificationOff}
            handleNotificationOn={handleNotificationOn}
            handleCommentOn={handleCommentOn}
            handleCommentOff={handleCommentOff}
            handleBlockUnblock={handleBlockUnblock}
          
          />
        );
      })}
    </Fragment>
  );
};

export default UserMainCreation;
