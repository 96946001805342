import CardImageOne from "../img/cardImage.png";


export const MarketPlaceCate = [
    { cate: "Real Estate", items: 322 },
    { cate: "Vehicles", items: 590 },
    { cate: "Electronics", items: 368 },
    { cate: "Fashion", items: 528 },
    { cate: "Furniture", items: 263 },
    { cate: "Services", items: 322 },
    { cate: "Other", items: 494 }
]



export const MarketPlaceCardData = [
    {
        id: 1,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: true
    },
    {
        id: 2,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: true
    },
    {
        id: 3,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 4,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },

    {
        id: 5,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 6,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 7,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 8,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 9,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 4,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },

    {
        id: 5,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 6,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 7,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 8,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: false
    },
    {
        id: 9,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo Next Men’s Running Shoes",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131...",
        own: true
    }
]


export const MarketPlaceCardData2 = [
    {
        id: 1,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo ",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 2,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 3,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 4,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },

    {
        id: 5,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 6,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 7,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo ",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 8,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 9,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 4,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },

    {
        id: 5,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 6,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 7,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 8,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 9,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    },
    {
        id: 10,
        image: { CardImageOne },
        productName: "Nike Air Zoom Tempo",
        subcate: "Men’s Fashion",
        price: "$3000",
        address: "813 Howard Street Oswego NY 131..."
    }
]





export const singlePageAllItems = {
    "Brand": "chArmkpR",
    "Color": "Black",
    "Category": "Casual Shirt",
    "Material": "Polyster",
    "Condition": "New",
    "Heavy": "200 g",
}


export const Discriptions = [
    "Long-sleeve shirt in classic fit featuring button-down collar",
    "Patch Pocket on Left Chest",
    "Durable Combination Cotton Fabrics",
    "Comfortable and Quality Dress Fabric",
    "Go to Classic button down shirt for all special occasions"
]











// filter dummy data 
export const FilterAllData = {
    "Home": {
        checkBox: {
            "Category": ["Real Estate", "Vehicles", "Electronics", "Fashion", "Furniture", "Other", "Services"],

        },
        areaRange: false,
        locationRange: true,
        price: { title: "Budget" },
        tabs: false
    },
    "Home/Real Estate": {
        checkBox: {
            "Category": ["For Sale: Houses & Apartments", "For Rent: Houses & Apartments", "Lands & Plots", "For Sale: Shops & Offices", "For Rent: Shops & Offices", "PG and Guest House"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"],
            "Amenities": ["Parking Slot", "Pet Allowed", "Bachelors Allowed", "Club House or Gym", "Swimming Pool"],
            "Additional": ["Near to Metro Station", "Near to Airport", "Only In Industry Area", "Near to Railway Station"],
            "Type": ["Houses & Villas", "Apartments", "Builder Floors", "Shops & Offices"],
            "Furnishing": ["Furnished", "Unfurnished", "Semi-Furnished"],
            "Listed By": ["Owner", "Dealer", "Builder"]
        },
        areaRange: {
            title: "Area Range",
            type: "sqft",
            placeholder: "1000"
        },
        locationRange: true,
        price: { title: "Budget" },
        tabs: [{ title: "Bedrooms", tabs: ["1+", "2+", "3+", "4+", "5+", "Studio"] }, { title: "Bathrooms", tabs: ["1+", "2+", "3+", "4+", "5+", "Attached"] }],

    },
    "Home/Vehicles": {
        checkBox: {
            "Vehicles": ["Car", "Motorcycle", "Bicycle", "Scooter", "Bike: Spare Parts", "Commercial Vehicles", "Bike: Accessories", "Commercial Spares"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"],
            "Condition": ["New", "Second hand", "Third Owner"],
            "Company": ["Hero", "Honda", "Yamaha", "KTM", "Royal Einfield", "Other"]
        },
        areaRange: false,
        locationRange: true,
        price: { title: "Price" },
        tabs: false
    },
    "Home/Electronics": {
        checkBox: {
            "Category": ["Mobile Phones", "Tablets", "Mobile and Tablet Accessories", "TVs, Audio-Video", "Kitchen and Appliances", "Computer and Laptops", "Camera and Lenses", "Games and Entertainment", "Fridge", "AC", "Hard Disk, Printer, and Monitor", "Washing Machine"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"],
            "Company": ["Apple", "Samsung", "Vivo", "Oppo", "Realme", "OnePlus", "Redmi", "MI", "Huvai"]
        },
        areaRange: false,
        locationRange: true,
        price: { title: "Budget" },
        tabs: false

    },
    "Home/Fashion": {
        checkBox: {
            "Category": ["Kids Fashion", "Mens Fashion", "Women's Fashion"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"],
            "Gender": ["Men", "Women", "Boys"],
            "Size": ["S", "M", "L", "XL"]
        },
        areaRange: {
            title: "Age Range",
            type: "Year",
            placeholder: "18"
        },
        locationRange: true,
        price: false,
        tabs: false
    },
    "Home/Furniture": {
        checkBox: {
            "Category": ["Sofa & Dining", "Beds & Wardrobes", "Home Decor & Garden", "Kids Furniture", "Other Household Items"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"],
        },
        areaRange: false,
        locationRange: true,
        price: { title: "Budget" },
        tabs: [{ title: "Seating Capacity", tabs: ["1+", "2+", "3+", "4+", "5+", "6+"] }],
    },
    "Home/Services": {
        checkBox: {
            "Category": ["Books", "Gym & Fitness", "Musical Instruments", "Sports Equipment", "Other Hobbies"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"],
            "Language": ["English", "Hindi", "Gujarati", "Punjabi", "Bengla", "Urdu", "Malayalam", "Sanskrit", "Spanish", "Telugu"],
            "Last Arrival": ["Last 30 Days", "Last 60 Days", "Last 90 Days", "Last 150 Days"]
        },
        areaRange: false,
        locationRange: true,
        price: { title: "Budget" },
        tabs: false
    },
    "Home/Other": {
        checkBox: {
            "Category": ["Fishes & Aquarium", "pet Food & Accessories", "Dogs", "Other Pets"],
            "Location": ["DKL Jakarta", "Yogyakarta", "Surabaya", "Jabodetabek", "Bandung"]
        },
        areaRange: false,
        locationRange: true,
        price: { title: "Budget" },
        tabs: false
    }
}

export const ChildCategory = {
    "For Sale: Houses & Apartments": { data: ["Location", "Amenities", "Additional", "Type", "Furnishing"], child: true },
    "For Rent: Houses & Apartments": { data: ["Condition", "Company"], child: true },
    "Lands & Plots": { data: ["Company"], child: true },
    "For Sale: Shops & Offices": { child: false },
    "For Rent: Shops & Offices": { child: false },
    "PG and Guest House": { child: false },
}



export const ChildCate = {
    "Real Estate": { data: ["Location", "Amenities", "Additional", "Type", "Furnishing"], child: true },
    "Vehicles": { data: ["Condition", "Company"], child: true },
    "Electronics": { data: ["Company"], child: true },
    "Fashion": { child: false },
    "Furniture": { child: false },
    "Services": { child: false },
    "Other": { child: false }
}










// all the tabs 

export const AllFormTabs = [
    { title: "Type*", data: ["Apartments", "Houses & Villas", "Builder Floors", "Shops & Offices"] },
    { title: "Bedrooms*", data: ["1+", "2+", "3+", "4+", "5+", "Studio"] },
    { title: "Bathrooms*", data: ["1+", "2+", "3+", "4+", "5+", "Attached"] },
    { title: "Furnishing*", data: ["Furnished", "Semi-Furnished", "Unfurnished"] }
    // {title:"Listed by*",data:["Builder","Dealer","Owner"]},
    // {title:"Pet Allowed?*",data:["Yes","No"]}
]




export const RealEstate = {
    "Type*": {
        type: "tabs",
        data: ["Apartments", "Houses & Villas", "Builder Floors", "Shops & Offices"]
    },

    "Bedrooms*": {
        type: "tabs",
        data: ["1+", "2+", "3+", "4+", "Studio"]
    },
    "Bathrooms*": {
        type: "tabs",
        data: ["1+", "2+", "3+", "4+", "Attached"]
    },
    "Furnishing*": {
        type: "tabs",
        data: ["Furnished", "Semi-Furnished", "Unfurnished"]
    },
    "Listed by*": {
        type: "tabs",
        data: ["Builder", "Dealer", "Owner"]
    },
    "Pet Allowed?*": {
        type: "tabs",
        data: ["Yes", "No"]
    },
    "Super Builtup Area (sqft)": {
        type: "input",
        placeholder: "Enter super builtup area here"
    },
    "Carpet Area (sqft)": {
        type: "input",
        placeholder: "Enter carpet area here"
    },
    "Total Monthly Maintenance*": {
        type: "input",
        placeholder: "Enter your monthly maintenance here"
    },
    "Total Floors": {
        type: "input",
        placeholder: "How many floors do you have?"
    },
    "Car Parking*": {
        type: "tabs",
        data: ["1+", "2+", "3+", "4+"]
    },
    "Bachelor Allowed?*": {
        type: "tabs",
        data: ["Yes", "No"]
    },
    "Additional Amenities": {
        type: "dropdown",
        placeholder: "Select your additional amenities",
        choices: ["Near to Metro Station", "Near to Airport", "Only In Industry Area", "Near to Railway Station"]
    },
    "Location*": {
        type: "location",
        placeholder: "Enter your address"
    },
    "Title*": {
        type: "input",
        placeholder: "Enter your title"
    },
    "Set Price*": {
        type: "price",
        placeholder: "Enter your price for your property",
        choices: ["USD", "IN", "YEN"]
    },
    "Description*": {
        type: "discription",
        placeholder: "Enter your description"
    }
}



export const Electronics = {
    "Type*": {
        type: "tabs",
        data: ["Apartments", "Houses & Villas", "Builder Floors", "Shops & Offices"]
    },

    "Bedrooms*": {
        type: "tabs",
        data: ["1+", "2+", "3+", "4+", "Studio"]
    },
    "Bathrooms*": {
        type: "tabs",
        data: ["1+", "2+", "3+", "4+", "Attached"]
    },
    "Furnishing*": {
        type: "tabs",
        data: ["Furnished", "Semi-Furnished", "Unfurnished"]
    },
    "Listed by*": {
        type: "tabs",
        data: ["Builder", "Dealer", "Owner"]
    },
    "Pet Allowed?*": {
        type: "tabs",
        data: ["Yes", "No"]
    },
    "Super Builtup Area (sqft)": {
        type: "input",
        placeholder: "Enter super builtup area here"
    },
    "Carpet Area (sqft)": {
        type: "input",
        placeholder: "Enter carpet area here"
    },
    "Total Monthly Maintenance*": {
        type: "input",
        placeholder: "Enter your monthly maintenance here"
    },
    "Total Floors": {
        type: "input",
        placeholder: "How many floors do you have?"
    },
    "Car Parking*": {
        type: "tabs",
        data: ["1+", "2+", "3+", "4+"]
    },
    "Bachelor Allowed?*": {
        type: "tabs",
        data: ["Yes", "No"]
    },
    "Additional Amenities": {
        type: "dropdown",
        placeholder: "Select your additional amenities",
        choices: ["Near to Metro Station", "Near to Airport", "Only In Industry Area", "Near to Railway Station"]
    },
    "Location*": {
        type: "location",
        placeholder: "Enter your address"
    },
    "Title*": {
        type: "input",
        placeholder: "Enter your title"
    },
    "Set Price*": {
        type: "price",
        placeholder: "Enter your price for your property",
        choices: ["USD", "IN", "YEN"]
    },
    "Description*": {
        type: "discription",
        placeholder: "Enter your description"
    }
}







export const SortByRadio = [
    "Distance: Nearest First",
    "Date Listed: Newest First",
    "Price: Highest First",
    "Price: Lowest First"
]

export const SortByCheckbox = [
    "All",
    "Uploaded by Me Only",
    "Uploaded by Others Only"
]




export const FollowerData = [
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emma",
        post: "18M",
        btnType: "Follow ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emman",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow",
        btnid: "Follow",

    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emma",
        post: "18M",
        btnType: "Following",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow ",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    }
]

export const ViewedData = [

    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emma",
        post: "18M",
        btnType: "Follow ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emman",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    },

]

export const CommentedDetails = [
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emma",
        post: "18M",
        btnType: "Follow ",
        btnid: "UFollow"
    },  
]


export const LikedData = [
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emma",
        post: "18M",
        btnType: "Follow ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emman",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow",
        btnid: "Follow",

    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Follow",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@emma",
        post: "18M",
        btnType: "Following",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@leslie",
        post: "18M",
        btnType: "Follow ",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "Joined on 23 Mar 2021",
        followData: "9.8M",
        username: "@devon",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    }
]