import * as types from '../../Constants/SocialFeed/index';
import axios from 'axios';

const getEventCategory = (eventCategory) => ({
    type: types.GET_EVENT_CATEGORY,
    payload: eventCategory,
});

// get all event category
export const loadEventCategory = () => {
    return async (dispatch) => {
        try {
          let user = JSON.parse(localStorage.getItem('sociomeeUser'));
          if (user) {
            const response = await axios.post(
              `${process.env.REACT_APP_IPURL}/post/getEventCategory`,
              {
                pageIndex: 0,
                pageSize: 10,
                searchKey: "",
              },
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            );
            
            dispatch(getEventCategory(response.data.data.successResult.rows));
          }
        } catch (error) {
          console.error('Error fetching trending hashtags:', error);
        }
      };
    // return function (dispatch) {
    //     let user = JSON.parse(localStorage.getItem('sociomeeUser'));
       
    //     if (user) {
    //         axios.post(`${process.env.REACT_APP_IPURL}/post/getEventCategory`,
    //         {pageIndex: 0,pageSize: 10,searchKey: ""},
    //         {headers: { Authorization: `Bearer ${user?.token}` }})
    //             .then((res) => {
    //                 dispatch(getEventCategory(res.data.data.successResult.rows))
    //                 console.log("getEventCategory",res.data.data.successResult.rows);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             })
    //     }
    // };
};

