import * as types from "../../Constants/Connection/index.js";

const initialState = {
    userApproveRequests: [], 
  loading: true,
};

const GetApproveRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_APPROVE_REQUESTS:
      return {
        ...state,
        userApproveRequests: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default GetApproveRequestReducer;
