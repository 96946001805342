import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import AdmanagerHeaderR from '../../AdmanagerHeaderR/AdmanagerHeaderR'
import TextField from '@mui/material/TextField';
import { Button, Card, Col, Popover, PopoverBody, Row, UncontrolledTooltip } from 'reactstrap'
import { Circle, Folder, Grid, Info, MoreHorizontal } from 'react-feather';
import Slider from '@mui/material/Slider';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { Autocomplete, Box, Checkbox, Stack } from '@mui/material';

const ChooseAudienceScreen = () => {
    const Gender = [
        'Male',
        'Female',
        'Other'
    ]
    const navigate = useNavigate()
    // const [selectedItems, setSelectedItems] = useState([]);
    const [warningOn, setwarningOff] = useState(false)
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedItems(Gender);
        } else {
            setSelectedItems([]);
        }
    };
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems(selectedItems.filter((item) => item !== value));
        }
    };
    const interestes = [
        "Photography",
        "Videography",
        "Watching",
        "Video Making",
        "World Tour",
        "New Movies",
        "Reading Books",
        "Travelling ",
        "Song",
        "Games",
        "Technology",
    ]
    function valuetext(value) {
        return `${value}°C`;
    }
    const Profession = [
        'Profession -1',
        'Profession -2',
        'Profession -3',
        'Profession -4',
        'Profession -5',
        'Profession -6',
        'Profession -7'
    ];
    const Target = [
        'Lucknow, Uttar Pradesh',
        'Lucknow, Uttar Pradesh, Sarojini Naidu Marg, Block A, Raj Bhavan Colony, Lalbagh, Lucknow, Uttar Pradesh',
        'Lucknow Airport (LKO), Amausi, Lucknow, Uttar Pradesh'
    ]
    const minDistance = 10;
    const [searchValue, setSearchValue] = useState("")
    const [value, setValue] = useState([13, 90]);
    const handleChangeForslider = (event, newValue, activeThumb) => {
        // setValue(newValue);
        // setValue(newValue.map(val => Math.round(val / 10) * 10));
        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistance) < 13 ? 13 : Math.min(newValue[0], value[1] - minDistance), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistance) > 90 ? 90 : Math.max(newValue[1], value[0] + minDistance)]);
        }
    }
    const [Targeted, setTargeted] = useState("")

    const handleTargeted = () => {
        setTargeted(!areAllFieldsFilled());
    }
    useEffect(() => {
        setTargeted(!areAllFieldsFilled());

    }, [Targeted,]); // Add other dependencies as needed
    const PrettoSlider = styled(Slider)({
        color: '#81C14B',
        height: 4,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    });

    // const [isActive, setIsActive] = useState(false);
    // const [selected, setIsSelected] = useState("Custom");
    // Select Your Preference
    function handleBlur(e) {
        console.log(e);
    }
    const [visitCount, setVisitCount] = useState(0);
    const [adText, setAdText] = useState('');

    useEffect(() => {
        const initializeAd = () => {
            // Get visit count from local storage
            const storedVisitCount = parseInt(localStorage.getItem('visitCount'), 10) || 0;

            const adOptions = [
                `${localStorage.getItem("adTypeName")} / Single Image Ad / Choose your Audience`,
                `${localStorage.getItem("adTypeName")} / Carousel Ad / Choose your Audience`,
                `${localStorage.getItem("adTypeName")} / Video Ad / Choose your Audience`,
                `${localStorage.getItem("adTypeName")} /  Full Screen Video Ad / Choose your Audience`,
            ];

            const newVisitCount = (storedVisitCount + 1) % adOptions.length;
            setVisitCount(newVisitCount);

            const newAdText =
                newVisitCount < adOptions.length
                    ? adOptions[newVisitCount]
                    : 'Default Ad Text';

            setAdText(adOptions[localStorage.getItem("adTypeName") !== "Video Views - CPV" ? storedVisitCount : storedVisitCount + 2]);
            // localStorage.setItem('visitCount', newVisitCount);
        };

        initializeAd();
    }, []);
    const [selected, setSelected] = useState('Custom');
    const [isActive, setIsActive] = useState(false);
    // const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [isButtonDisabled1, setIsButtonDisabled] = useState(true);
    const handleDropdownItemClick = (value) => {
        setSelected(value);
        setIsActive(false);
        // setButtonDisabled(false); 
    };
    const handleButtonClick = () => {
        console.log('Button clicked with selected:', selected);
    };
    const [selectedItems, setSelectedItems] = useState([]);

    const areAllFieldsFilled = () => {
        // return selectedItems.length > 0;
    };
    useEffect(() => {
        setIsButtonDisabled(!areAllFieldsFilled());

    }, [selectedItems,]); // Add other dependencies as needed
    const [selectedProfessions, setSelectedProfessions] = useState([Profession[1]]);
    const [selectedInterests, setSelectedInterests] = useState([interestes[0]]);
    const handleInterestsChange = (event, newValue) => {
        setSelectedInterests(newValue);
        setIsButtonDisabled(newValue.length === 0);
    };
    const handleProfessionChange = (event, newValue) => {
        setSelectedProfessions(newValue);
        setIsButtonDisabled(newValue.length === 0);
    };

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(event.target.value);
      setInputValue(value);
      setIsButtonDisabled(value.trim() === '');
      
    };

    const [isSaveChecked, setIsSaveChecked] = useState(false);
    // Function to handle save checkbox change
    const handleSaveCheckboxChange = () => {
      setIsSaveChecked(!isSaveChecked);
    };
    const isButtonDisabled = inputValue.trim() === '' && !isSaveChecked;
    return (
        <>
            <AdmanagerHeaderR />
            <div className="">
                <div className="row">
                    <div className="col-xl-2 ad">
                        <Card className=' d-none d-sm-block border-right'>
                            {/* min-vh-100 */}
                            <div className="p-4 text-muted border">
                                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
                                {/* <div className='text-dark'><p>Choose Campaign Type (e.g., Display, Video, App, etc.)</p></div> */}
                            </div>
                            <div disabled className="p-4 border text-muted">
                                <div className='pb-1'><h6>Step 2</h6></div>
                                <div className='text-dark'><h5><b>Select Ad Format</b></h5></div>
                            </div>
                            <div disabled className="p-4 border text-muted">
                                <div className='pb-1'><h6>Step 3</h6></div>
                                <div className=' pb-2 text-dark'><h5><b>Set up Ad Campaign</b></h5></div>
                                <div className="paddingForStyle">
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                                </div>
                            </div>
                            <div disabled className="p-4 borderLeftForDasboard ">
                                <div className='pb-1'><h6>Step 4</h6></div>
                                <div className=' pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                                <div className="paddingForStyle">
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                                </div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 5</h6></div>
                                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 6</h6></div>
                                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
                            </div>
                        </Card>
                    </div>
                    {/* <div className="col-lg-10 pad-zero"> */}
                    <Col xl='10' className='px-2 mt-1'>

                        {/* <div className="col-lg-12 step-ad-heading">
                            <div className="row step-section">
                                <div className="col-lg-2 ad-count">
                                    <button className="ad-count-btn">4</button>
                                    <p className="ad-count-step">Step 4</p>
                                </div>
                                <div className="col-lg-10">
                                    <p className='ad-count-heading'>Choose Your Audience</p>
                                    <p>Define your target audience details</p>
                                </div>
                            </div>
                        </div> */}
                        <Row className='rowForrevert'>
                            <Col xl='12'>
                                <Card className='border-0'>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center p-2 border-right">
                                            <button className="ad-count-btn">4</button>
                                            <p className="ad-count-step px-3" style={{ textWrap: 'nowrap' }}>Step 4</p>
                                        </div>
                                        <div className="p-2 pl-3">
                                            <h4>Choose Your Audience</h4>
                                            <p className="text-muted">Define your target audience details</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <div className="col-lg-12 pad-zero mt-1  shadow">
                            <div className="row ad-types-of-type-map ">
                                <div className="single-ad pad-zero">
                                    <div className="navigate col-lg-12">
                                        <div className="row">
                                            {/* <div className="navigate-left col-lg-12"> */}
                                            {/* <p className='navigate-color'>Website ( Link) Visits- CPC  /  Single Image Ad  /  Choose your Audience</p> */}
                                            <div className={`ad-container`}>
                                                <p className='navigate-color'>{adText}</p>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 inputs d-flex p-0">
                                        {/* <div className="row"> */}
                                        <div className='col-lg-12 col-12'>
                                            <div className="">
                                                <p className='p-heading'>Audience Preference
                                                    {/* <span className='pl-1'>
                                                        <Info size={15} id='PreferenceFortooltip' />
                                                    </span> */}
                                                    <Info fill='#828282' id='audTooltip' color='white' size={18} />
                                                    <UncontrolledTooltip placement='right' target='audTooltip'>
                                                        The target audience which the user has used in the past
                                                    </UncontrolledTooltip>
                                                    {/* <UncontrolledTooltip placement='top' target='PreferenceFortooltip'>
                                                        Select Your Preference
                                                    </UncontrolledTooltip> */}
                                                </p>
                                                {/* <div className="">
                                                 
                                                    <select id="inputState" className="form-control" name="bpCategoryId"  >
                                                        <option value="1">Customer 1</option>
                                                        <option value="2">Customer 2</option>
                                                        <option value="3">Customer 3</option>
                                                        <option value="4">Customer 4</option>
                                                        <option value="5">Customer 5</option>
                                                        <option value="6">Customer 6</option>
                                                    </select>
                                                </div> */}
                                                {/* =========================dropdown==================== */}
                                                <div>
                                                    <div className="dropdownText">
                                                        <div
                                                            onClick={() => {
                                                                setIsActive(!isActive);
                                                            }}
                                                            className="dropdownText-btn"
                                                        >
                                                            <p>{selected}</p>
                                                            <span className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
                                                        </div>
                                                        <div className="dropdowncon-content" style={{ display: isActive ? 'block' : 'none' }}>
                                                            <div className="item" onClick={() => handleDropdownItemClick('Custom')}>
                                                                Custom
                                                            </div>
                                                            <div className="item" onClick={() => handleDropdownItemClick('Preference-1')}>
                                                                Preference-1
                                                            </div>
                                                            <div className="item" onClick={() => handleDropdownItemClick('Preference-2')}>
                                                                Preference-2
                                                            </div>
                                                            <div className="item" onClick={() => handleDropdownItemClick('Preference-3')}>
                                                                Preference-3
                                                            </div>
                                                            <div className="item" onClick={() => handleDropdownItemClick('Preference-4')}>
                                                                Preference-4
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                {/* =========================dropdown end================= */}
                                            </div>
                                            <div className="">
                                                <div className="ad-heading-upsd-title-input a-price-flex">
                                                    <p className='p-heading'>Target Gender
                                                        <Info fill='#828282' id='genderTooltip' color='white' size={18} />
                                                        <UncontrolledTooltip placement='right' target='genderTooltip'>
                                                            User has to choose their target gender initially. There are male, female, other.
                                                        </UncontrolledTooltip>
                                                    </p>
                                                    <span className="a-price">
                                                        +$120
                                                    </span>
                                                </div>
                                                <div>
                                                    <label>
                                                        <div className='d-flex align-items-center'>
                                                            <Checkbox
                                                                type="checkbox"
                                                                onChange={handleSelectAll}
                                                                className='Check_Input_section'
                                                            />
                                                            <p className='ml-1 FontForSame'>All Genders</p>
                                                        </div>
                                                    </label>
                                                    {Gender.map((item) => (
                                                        <label key={item}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <Checkbox
                                                                    value={item}
                                                                    checked={selectedItems.includes(item)}
                                                                    onChange={handleCheckboxChange}
                                                                    className='Check_Input_section'
                                                                />
                                                                <p className='ml-1 FontForSame'>{item}</p></div>
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="ad-heading-upsd-title-input a-price-flex">
                                                    <p className='p-heading'>
                                                        {/* Target Age Range1 */}
                                                        Target Age Range
                                                        <Info fill='#828282' id='tarTooltip' color='white' size={18} />
                                                        <UncontrolledTooltip placement='right' target='tarTooltip'>
                                                            Users can select age range between 13- 100 years by using the scroller.
                                                        </UncontrolledTooltip>
                                                    </p>
                                                    {/* <span className="a-price">+${value[1]}</span> */}
                                                    <span className="a-price">
                                                        +$100
                                                    </span>
                                                </div>
                                                <Box sx={{ width: '100%' }}>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={value}
                                                        onChange={handleChangeForslider}
                                                        sx={{ color: '#81C14B' }}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={(valuetext, value) => `${value}°C`}
                                                    />
                                                </Box>
                                            </div>
                                            <div className=''>
                                                <div className="ad-heading-upsd-title-input a-price-flex">
                                                    <p className='p-heading'>Targeted Profession
                                                        <Info fill='#828282' id='targetTooltip' color='white' size={18} />
                                                        <UncontrolledTooltip placement='right' target='targetTooltip'>
                                                            Ad creators can target the audience of a particular profession.
                                                        </UncontrolledTooltip>
                                                    </p>
                                                    <span className="a-price">
                                                        +$120
                                                    </span>
                                                </div>
                                                <Stack sx={{ width: '100%' }}>
                                                    <Autocomplete
                                                        multiple
                                                        className='border'
                                                        options={Profession.map((option) => option)}
                                                        defaultValue={[Profession[1]]}
                                                        freeSolo
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip label={option} {...getTagProps({ index })} />
                                                            ))
                                                        }
                                                        onClick={() => handleTargeted(Targeted)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder='Choose Your Profession'
                                                            />
                                                        )}

                                                    />
                                                </Stack>
                                            </div>
                                            <div className=''>
                                                <div className="ad-heading-upsd-title-input a-price-flex">
                                                    <p className='p-heading'>Targeted Interests
                                                        <Info fill='#828282' id='interestsTooltip' color='white' size={18} />
                                                        <UncontrolledTooltip placement='right' target='interestsTooltip'>
                                                            Ad creators can target audiences based on their interests creating ads.
                                                        </UncontrolledTooltip>
                                                    </p>
                                                    <span className="a-price">
                                                        +$120
                                                    </span>
                                                </div>
                                                <div className="Audience-Preference-input">
                                                    <Stack sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            multiple
                                                            className='border'
                                                            options={interestes.map((option) => option)}
                                                            defaultValue={[interestes[0]]}
                                                            freeSolo
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <Chip label={option} {...getTagProps({ index })} />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}

                                                                    placeholder='Choose Your Interestes'
                                                                /> 
                                                            )}

                                                        />
                                                    </Stack>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className="ad-heading-upsd-title-input d-flex align-items-center a-price-flex">
                                                    <p className='p-heading'>Targeted Location
                                                        <Info fill='#828282' id='locationTooltip' color='white' size={18} />
                                                        <UncontrolledTooltip placement='right' target='locationTooltip'>
                                                            System will fetch your current location.
                                                        </UncontrolledTooltip>
                                                    </p>
                                                    <span className="a-price m-0">
                                                        +$120
                                                    </span>
                                                </div>
                                                <div className="Audience-Preference-input">
                                                    <Stack sx={{ width: '100%' }}>
                                                        <Autocomplete
                                                            multiple
                                                            className='border'
                                                            options={Target.map((option) => option)}
                                                            defaultValue={[Target[0]]}
                                                            freeSolo
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <Chip label={option} {...getTagProps({ index })} />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder='Choose Your Target'
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                </div>
                                            </div>
                                            {/* ===========website link======== */}
                                            {/* <div className="ad-heading-upsd-title-input d-flex save-audience">
                                                <div className='d-flex mr-1'>
                                                    <p className='p-heading '>Provide Web link
                                                        <Info fill='#828282' id='webTooltip' color='white' size={18} />
                                                        <UncontrolledTooltip placement='right' target='webTooltip'>
                                                            Add your provide web link url.
                                                        </UncontrolledTooltip></p>
                                                </div>
                                                <input type="text" className='form-control p-2' placeholder='Type your advertisement sub-heading' />
                                            </div> */}
                                            {/* ======================link end=========== */}
                                            <div className="ad-heading-upsd-title-input d-flex save-audience">
                                                <div className='d-flex mr-1'>
                                                    {/* <input type="checkbox" /> */}
                                                    <Checkbox
                                                        className='Check_Input_section'
                                                        checked={isSaveChecked}
                                                        onChange={handleSaveCheckboxChange}
                                                    />
                                                    <p className='p-heading pl-2'>Save this Audience Preference for future.</p>
                                                </div>
                                                <input
                                                    type="text"
                                                    className='form-control p-2'
                                                    placeholder='Enter audience preference...'
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                   
                                                />
                                            </div>
                                            <div className='col-lg-12'>
                                                {/* <div className="ad-btn">
                                                    <Link to="" className="btn-cancel mr-2          ">
                                                        Cancel
                                                    </Link>
                                                    <Link to="/Ad/TotalBudget" className='btn-next'>
                                                        Next
                                                    </Link>
                                                </div> */}
                                                <div className="ad-btn">
                                                    <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                                                        Back
                                                    </Button>

                                                    <Button
                                                        id='controlledPopover'
                                                        size='md'
                                                        outline
                                                        className="ml-2"
                                                        onClick={() => {
                                                            if (!isButtonDisabled) {
                                                                navigate('/Ad/TotalBudget');
                                                            }
                                                            handleButtonClick(); // Your button click logic
                                                        }}
                                                        disabled={isButtonDisabled}
                                                        style={{
                                                            color: isButtonDisabled ? 'white' : '#white',
                                                            backgroundColor: isButtonDisabled ? '#ACDAD6' : '#81C14B',
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                    <Popover
                                                        placement='top'
                                                        target='controlledPopover'
                                                        isOpen={warningOn}
                                                        toggle={() => setwarningOff(!warningOn)}
                                                    >
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* </div> */}
                </div>
                {/* MODEL 1 */}
                <div className="modal fade" id="interestesModel" tabIndex="-1" role="dialog" aria-labelledby="interestesModelTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Interestes</h5>
                                <a href="#" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-dark close-btn"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></a>
                            </div>
                            <div className="modal-body">
                                <div className="searchfilter-blk">
                                    <div className="input-search-blk">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        <input type="text" className="form-control" name="search" placeholder="Find Interest..." value={searchValue} onChange={e => setSearchValue(e.target.value)} />
                                    </div>
                                    <ul className="searchfiler-list">
                                        {interestes
                                            .filter(interestes => interestes.match(new RegExp(searchValue, "i")))
                                            .map(interestes => {
                                                return <li key={interestes}><div className="form-check checkbox_animated"><input type="checkbox" className="form-check-input" id={interestes} /><label className="form-check-label" htmlFor={interestes}>{interestes}</label></div></li>
                                            })}
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-solid">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseAudienceScreen