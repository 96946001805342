import React from 'react'
import { useState,useEffect,Fragment } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Col, Nav, NavItem, Progress, Row, TabContent, TabPane } from 'reactstrap'
import { Modal, ModalBody, ModalHeader,ModalFooter,Button } from 'reactstrap'
import { Checkbox, Pagination, Stack } from "@mui/material";
import axios from 'axios'

const PollBottomAnalatycs = (props) => {
    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const [blockOpen, setBlockOpen] = useState(false)
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    const [pollType, setPollType] = useState('1')
    const [currView,setCurrView]=useState('')
    const [pageSize,setPageSize]=useState(10);
    const [pageIndex,setPageIndex]=useState(0);
    const [searchKey,setSearchKey]=useState('');
    const [likeData,setLikeData]=useState([]);
    const [shareData,setShareData]=useState([]);
    const [viewData,setViewData]=useState([]);
    const [votesData,setVotesData]=useState([]);
    const [errorData,setErrorData]=useState([]);


    const useFetchData = (endpoint, postData, setData, dependencies) => {
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                    if (user) {
                        const response = await axios.post(
                            `${process.env.REACT_APP_IPURL}${endpoint}`,
                            postData,
                            { headers: { Authorization: `Bearer ${user.token}` } }
                        );
                        setData(response.data.data.successResult.rows);
                    } else {
                        setData('no user found');
                    }
                } catch (error) {
                    setData(error.message);
                }
            };
            fetchData();
        }, dependencies);
    };

    const postData = { postId: props.data.postId, pageSize, pageIndex, searchKey };

    useFetchData('/post/getAllLike', postData, setLikeData, [currView]);
    useFetchData('/post/getAllView', postData, setViewData, [currView]);
    useFetchData('/post/getAllShare', postData, setShareData, [currView]);
    useFetchData('/post/getAllPollVotes', postData, setVotesData, [currView]);

    const [analyticsData,setAnalyticsData]=useState([])
    useEffect(()=>{
        const fetchData = async () => {
            if (props?.data) {
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_IPURL}/post/getPollById`,
                  { pollId: props?.data.postId, userId: userId },
                  { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setAnalyticsData(response.data.data.successResult);
              } catch (error) {
                setErrorData(error);
              }
            }
          };
        fetchData();
    }, [props?.data]);

    // console.log("all dataa========================================", analyticsData)

    const toggleShareOpen = (e,view) => {
        e.stopPropagation();
        // setBlockOpen(prevState => !prevState);
        if(!blockOpen){
            setCurrView(view);
        }else{
            setCurrView('')
        }
        setBlockOpen(prevState => !prevState);
    }

    const formatDate = (createdAt) => {
        const date = new Date(createdAt);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const togglePoll = tab => {
        if (pollType !== tab) {
            setPollType(tab)
        }
    }

    // console.log("all dataa========================================", analyticsData)

    const PollAnalyticsData = [
        {
            view: props?.data?.likesCount ||0,
            heading: "Total likes ",
            onClick: "View →"
        },
        {
            view: props?.data?.sharesCount||0,
            heading: "Total shares ",
            onClick: "View →"
        },
        {
            view: props?.data?.viewsCount||0,
            heading: "Viewed",
            onClick: "View →"
        },
        {
            view: props?.data?.totalVotesOnPoll||0,
            heading: "Voted ",
            onClick: "View →"
        }
    ]

    const totalVotes = analyticsData?.GenderAnalysis?.reduce((acc, gender) => acc + gender.value, 0) || 1; // Default to 1 to avoid division by zero

    const GenderPollData = analyticsData?.GenderAnalysis?.map(gender => ({
      heading: gender.name,
      rate: (gender.value / totalVotes) * 100 // Calculate percentage
    })).filter(data => data.rate > 0) || []; // Filter out entries with 0%
  
    const totalLocations = analyticsData?.LocationAnalysis?.reduce((acc, location) => acc + location.value, 0) || 1; // Default to 1 to avoid division by zero
  
    const LocationData = analyticsData?.LocationAnalysis?.map(location => ({
      heading: location.name || "Unknown",
      rate: (location.value / totalLocations) * 100,
      value: location.value
    })).filter(data => data.rate > 0) || [];
  
    const totalAges = analyticsData?.AgeAnalysis?.reduce((acc, age) => acc + age.value, 0) || 1; // Default to 1 to avoid division by zero
  
    const AgePollData = analyticsData?.AgeAnalysis?.map(age => ({
      heading: age.name,
      rate: (age.value / totalAges) * 100,
      value: age.value
    })).filter(data => data.rate > 0) || [];

// console.log("runhning status=================================",props)

const getStatusColor = (status) => {
    switch (status) {
        case 'completed':
            return '#888888';
        case 'scheduled':
            return '#D3D3D3';
        case 'running':
        default:
            return 'initial';
    }
};

  return (
    <div>
    <div>
         <div className='Poll_Analytics p-3'>
            <h4>Poll analytics...</h4>
            <p style={{ color: getStatusColor(props?.currentStatus) }}>
                ●&nbsp;{props?.currentStatus}
            </p>
        </div>
    <Row className="p-3">
        {
            PollAnalyticsData.map((data, index) => (
                <Col key={index} md="6" className="py-2">
                    <div className="Analytics_Poll p-3">
                        <h4>{data.view}</h4>
                        <div className='d-flex Analytics_Flex_box justify-content-between py-2'>
                            <p>{data.heading}</p>
                            <span
                                onClick={(e) => data.view !== 0 && toggleShareOpen(e, data.heading)}
                                onMouseOver={(e) => e.target.style.cursor = data.view !== 0 ? 'pointer' : 'auto'}
                                onMouseOut={(e) => e.target.style.cursor = 'auto'}
                                style={{
                                    color: data.view === 0 ? 'grey' : '#007bff',
                                    pointerEvents: data.view === 0 ? 'none' : 'auto'
                                }}
                            >
                                {data.onClick}
                            </span>
                        </div>
                    </div>
                </Col>
            ))
        }
    </Row>
    <Row className="mb-5 Bottom_AllType_Tab_Section mt-2 p-3 ">
        <Nav className="border-bottom">
            <Col className="d-flex justify-content-around" md="4" >
                <NavItem>
                    <NavLink
                        active={pollType === '1'}
                        onClick={(e) => {
                            togglePoll('1')
                            e.preventDefault()
                        }}
                        className="Poll_link"
                    >
                        Gender
                    </NavLink>
                </NavItem>
            </Col>
            <Col className="d-flex justify-content-around" md="4" >
                <NavItem>
                    <NavLink
                        active={pollType === '2'}
                        onClick={(e) => {
                            togglePoll('2')
                            e.preventDefault()
                        }}
                        className="Poll_link"
                    >
                        Age
                    </NavLink>
                </NavItem>
            </Col>
            <Col className="d-flex justify-content-around" md="4" >
                <NavItem>
                    <NavLink
                        active={pollType === '3'}
                        onClick={(e) => {
                            togglePoll('3')
                            e.preventDefault()
                        }}
                        className="Poll_link"
                    >
                        Location
                    </NavLink>
                </NavItem>
            </Col>
        </Nav>
        {/* <hr className='w-100 text-muted' /> */}
        <Col md="12">
            <TabContent className='py-50' activeTab={pollType}>
                <TabPane tabId="1">
                {GenderPollData.map((data, index) => (
                    <div key={index} className='mt-3'>
                        <div className='d-flex justify-content-between PollGenderPreview'>
                            <h4>{data.heading}</h4>
                            <h5>{data.rate.toFixed(2)}%</h5> {/* Optional: toFixed for better display */}
                        </div>
                        <Progress
                            className="Gender_Progress_Bar mt-2"
                            value={data.rate} 
                            max={100} 
                        />
                    </div>
                ))}

                </TabPane>
                <TabPane tabId="2">
                    {AgePollData && AgePollData.map((data, index) => (
                        <React.Fragment key={index}>
                        <div className='d-flex justify-content-between PollGenderPreview mt-3'>
                            <h4>{data.heading}</h4>
                            <h5>{data.rate.toFixed(2)}%</h5> {/* Optional: toFixed for better display */}
                        </div>
                        <Progress className="Gender_Progress_Bar mt-2" value={data.rate} max={100} />
                        </React.Fragment>
                    ))}
                </TabPane>
                <TabPane tabId="3">
                    {LocationData && LocationData.map((data, index) => (
                        <React.Fragment key={index}>
                        <div className='d-flex justify-content-between PollGenderPreview mt-3'>
                            <h4>{data.heading}</h4>
                            <h5>{data.rate.toFixed(2)}%</h5> {/* Optional: toFixed for better display */}
                        </div>
                        <Progress className="Gender_Progress_Bar mt-2" value={data.rate} max={100} />
                        </React.Fragment>
                    ))}
                </TabPane>
            </TabContent>
        </Col>
    </Row>
    </div>
    <div>
            {
                blockOpen !== false &&
                <Modal scrollable isOpen={blockOpen} toggle={(e)=>toggleShareOpen(e,'')} className='modal-dialog-centered'>
                    <div className='w-100 border-bottom'>
                        <div className="d-flex align-items-center justify-content-center p-3 border-bottom" >         
                                <h2 style={{color:'#81C14B'}}>{currView} </h2>
                        </div>
                    </div>
                    <ModalBody>
                            <div>
                                <p>
                                    {currView=="Total likes "
                                    ? " This list shows the users who have liked your poll."
                                    :currView==="Total shares "
                                    ? " This list shows the users who have shared your poll."
                                    :currView==="Viewed"
                                    ? "  This list shows the users who have viewed your poll. "
                                    : "This list shows the users who have voted on your poll."}
                                </p>
                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="search-svg"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="search"
                                        placeholder="Search...for people"
                                        data-bs-toggle="modal"
                                        data-bs-target="#peopleSearch"
                                        value={searchKey}
                                        onChange={e => setSearchKey(e.target.value)}
                                    />
                                </div>
                            </div>
                        <div>
                        <Row className="mt-3">
                        {
                           currView=="Total likes " && likeData.filter(data => data.fullName.toLowerCase().includes(searchKey.toLowerCase())) // Filter based on fullName containing searchQuery
                                .map((data, index) => (
                                    <Fragment key={index}>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                            <img src={data.profileImageThumb} alt="img" className="img-fluid" />
                                            <div className="member-cont-blk">
                                                <h4>{data.fullName}</h4>
                                                <p className="small">Joined on {formatDate(data.createdAt)}</p>
                                                <div className="d-flex align-items-center">
                                                <p className="text-dark">{data?.FollowerCount ? data?.FollowerCount : 0} <span className="text-dark">Followers &nbsp;</span></p>
                                                <span className="text-dark">
                                                    ● {data.PostCount} <span className="text-dark">Posts</span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                                {/* <div className='Block-btns-blk'>
                                                    <Checkbox {...label} defaultChecked />
                                                </div> */}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                        }
                        {
                           currView==="Voted " && votesData.filter(data => data.fullName.toLowerCase().includes(searchKey.toLowerCase())) // Filter based on fullName containing searchQuery
                                .map((data, index) => (
                                    <Fragment key={index}>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                            <img src={data.profileImageThumb} alt="img" className="img-fluid" />
                                            <div className="member-cont-blk">
                                                <h4>{data.fullName}</h4>
                                                <p className="small">Joined on {formatDate(data.createdAt)}</p>
                                                <div className="d-flex align-items-center">
                                                <p className="text-dark">{data?.FollowerCount ? data?.FollowerCount : 0} <span className="text-dark">Followers &nbsp;</span></p>
                                                <span className="text-dark">
                                                    ● {data.PostCount} <span className="text-dark">Posts</span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                                {/* <div className='Block-btns-blk'>
                                                    <Checkbox {...label} defaultChecked />
                                                </div> */}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                        }
                        {
                           currView==="Viewed" && viewData.filter(data => data.fullName.toLowerCase().includes(searchKey.toLowerCase())) // Filter based on fullName containing searchQuery
                                .map((data, index) => (
                                    <Fragment key={index}>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                            <img src={data.profileImageThumb} alt="img" className="img-fluid" />
                                            <div className="member-cont-blk">
                                                <h4>{data.fullName}</h4>
                                                <p className="small">Joined on {formatDate(data.createdAt)}</p>
                                                <div className="d-flex align-items-center">
                                                <p className="text-dark">{data?.FollowerCount ? data?.FollowerCount : 0} <span className="text-dark">Followers &nbsp;</span></p>
                                                <span className="text-dark">
                                                    ● {data.PostCount} <span className="text-dark">Posts</span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                                {/* <div className='Block-btns-blk'>
                                                    <Checkbox {...label} defaultChecked />
                                                </div> */}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                        }
                        {
                           currView==="Total shares " && shareData.filter(data => data.fullName.toLowerCase().includes(searchKey.toLowerCase())) // Filter based on fullName containing searchQuery
                                .map((data, index) => (
                                    <Fragment key={index}>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                            <img src={data.profileImageThumb} alt="img" className="img-fluid" />
                                            <div className="member-cont-blk">
                                                <h4>{data.fullName}</h4>
                                                <p className="small">Joined on {formatDate(data.createdAt)}</p>
                                                <div className="d-flex align-items-center">
                                                <p className="text-dark">{data?.FollowerCount ? data?.FollowerCount : 0} <span className="text-dark">Followers &nbsp;</span></p>
                                                <span className="text-dark">
                                                    ● {data.PostCount} <span className="text-dark">Posts</span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>

                                                {/* <div className='Block-btns-blk'>
                                                    <Checkbox {...label} defaultChecked />
                                                </div> */}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                        }

                        </Row>
                        </div>
                    </ModalBody>

                </Modal>

            }
        </div>
</div>
  )
}

export default PollBottomAnalatycs
