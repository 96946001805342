import React from 'react'
import { Button } from 'react-bootstrap'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { loadUnfollow } from '../../../Services/Actions/Connection/getFollowUnFollowAction'
import { useDispatch } from 'react-redux'

const UnfollowModal = ({request,openModal,unfollowModalOpen,setunfollowConfirm}) => {
    const dispatch = useDispatch();
    const HandleUnFollow = () =>{
        dispatch(loadUnfollow(request));
        setunfollowConfirm(true);
        unfollowModalOpen();
    }
    
    return (
        <>
            {
                openModal !== false &&
                <Modal isOpen={openModal} toggle={unfollowModalOpen} className='modal-dialog-centered'>
                    <ModalHeader className='d-flex justify-content-center bg-light'>
                        <h2>Unfollow</h2>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to unfollow.</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 justify-content-center'>
                        <Button variant='outline-secondary' onClick={unfollowModalOpen}>
                            <span className='ml-1 w-25'>Cancel</span>
                        </Button>
                        <Button variant='danger' onClick={HandleUnFollow}>
                            <span className='ml-1 w-25'>Unfollow</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default UnfollowModal