import React, { useState, useEffect, Fragment } from 'react';
import { Input } from 'reactstrap';
import Track from "../../profile-components/Img/Track.png";
import trace from "../Icons/trace.svg";

const MpFormLocationInput = ({ onDataPass, value }) => {
  const [suggestion, setSuggestion] = useState(false);
  const [coords, setCoords] = useState({});
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Enter your address"); // Default placeholder

  // Set value from backend to edit product
  useEffect(() => {
    if (value) {
      setLocation(value);
      fetchCoordinates(value); // Fetch coordinates when initial value is set
    }
  }, [value]);

  // Load Google Places API script dynamically
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_LOCATION_KEY}&libraries=places`;
      script.async = true;
      script.onload = () => {
        console.log("Google Places API script loaded.");
      };
      document.body.appendChild(script);
    } else {
      console.log("Google Places API script already loaded.");
    }
  }, []);

  // Fetch user location and coordinates
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setCoords({ latitude, longitude });
        });
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };

    fetchUserLocation();
  }, []);

  const onChangeInputData = (e) => {
    const query = e.target.value;

    if (query.length > 1 && window.google) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({ input: query }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions);
          setSuggestion(true);
        } else {
          setSuggestions([]);
          setSuggestion(false);
        }
      });
    } else {
      setSuggestions([]);
      setSuggestion(false);
    }

    setLocation(query);
    if (query === "") {
      setPlaceholder("Enter your address");
      onDataPass("", coords);
    } else {
      setPlaceholder(query);
    }
  };

  const onSelectSuggestion = async (e, suggestion) => {
    e.stopPropagation();
    const selectedLocation = suggestion.description;
    setLocation(selectedLocation);
    setPlaceholder(selectedLocation);
    setSuggestion(false);

    // Fetch and update coordinates for the selected location
    await fetchCoordinates(selectedLocation);
  };

  const handleBackspace = (e) => {
    if (e.keyCode === 8 && location === "") {
      setLocation("");
      setPlaceholder("Enter your address");
      onDataPass("", coords);
    }
  };

  const handleTrackClick = async () => {
    if (coords.latitude && coords.longitude) {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: coords.latitude, lng: coords.longitude };

      geocoder.geocode({ location: latlng }, async (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const currentLocation = results[0].formatted_address;

            setLocation(currentLocation);
            setPlaceholder(currentLocation);
            await fetchCoordinates(currentLocation);
          } else {
            console.error('No results found');
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    } else {
      console.error('Coordinates are not available');
    }
  };

  // Fetch coordinates for a given location
  const fetchCoordinates = async (address) => {
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const { lat, lng } = results[0].geometry.location;
          setCoords({ latitude: lat(), longitude: lng() });
          onDataPass(address, { latitude: lat(), longitude: lng() });
          resolve();
        } else {
          reject('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  };

  return (
    <Fragment>
      <div className="MpformElementHeading">
        <h5>Location*</h5>
      </div>
      <div className="Mp_InputPannels mt-2 d-flex align-items-center position-relative">
        <div className="MpFormLocationInput border">
          <Input
            value={location}
            placeholder={placeholder}
            style={{ padding: "10px 10px", border: "none" }}
            onChange={onChangeInputData}
            onKeyDown={handleBackspace}
          />
          <img src={Track} alt="location" className='img-fluid' style={{ cursor: "pointer" }} width={18} height={18} onClick={handleTrackClick} />
        </div>
        <div className={` Mp_LocationBoxOnInput ${suggestion === false ? "d-none" : ""} `}>
          <div className='w-100 border-bottom'>
            <div className='mx-3 py-2 Mp_Loaction_Modal_Heading'>
              <h4>Current Residence</h4>
              <p>Save</p>
            </div>
          </div>
          <div className={`Mp_LocationDropdown_Locations d-flex flex-column gap-3 p-3`}>
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className='d-flex align-items-center justify-content-start gap-2'
                onClick={(e) => onSelectSuggestion(e, suggestion)}
              >
                <img src={trace} alt="location-icon" />
                <h6 style={{ color: "#808080", cursor: "pointer" }}>
                  <strong style={{ color: "#4D4D4D" }}>{suggestion.description}</strong>
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MpFormLocationInput;
