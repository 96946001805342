import React, { useState, useEffect, useRef } from 'react'
import { ArrowDownCircle, ChevronRight, ChevronUp, ChevronDown, Circle, Edit2, EyeOff, Filter, Headphones, Link2, MoreVertical, Navigation, Pause, PauseCircle, Play, PlayCircle, Repeat, Shuffle, SkipBack, SkipForward, Trash2, Volume, Volume2, VolumeX, X, ChevronsDown, ChevronsUp, Send, Bookmark, Eye, User, Download, Flag, AlertTriangle, Edit, Copy } from 'react-feather'
import { Badge, Button, Card, CardBody, CardImg, CardText, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import ActiveCirclelike from '../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../NewComponents/IMG/Circlelike.svg'
import CommentActive from '../../NewComponents/IMG/CommentActive.svg'
import CommentLatest from '../../NewComponents/IMG/CommentLatest.svg'
import PlayIcon from '../../NewComponents/IMG/playVideo.svg'
import PodacastMic from '../../NewComponents/IMG/PodacastMic.svg'
import UserImg from '../../NewComponents/IMG/img.svg'
import ShareLatest from '../../NewComponents/IMG/ShareLatest.svg'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Song1 from '../../NewComponents/Audio/malang.mp3'
import Song2 from '../../NewComponents/Audio/Falak.mp3'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { Checkbox, Stack } from '@mui/material'
import { ForwardArrayData } from '../Social-Feed-Component/FeedArray'
import { Link, NavLink } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap';
import MobileBottombar from '../MobileBottombar'
import PodcastFooterHead from './PodcastFooterHead'
import { useUserAuth } from '../../Context/userAuthContext'
import Mic from '../../NewComponents/IMG/Mic.png'



const PodcastFooter = () => {

    const { musicBar, setMusicBar } = useUserAuth()

    /*  ---------------------- States --------------------  */

    const [play, setpause] = useState(false)
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [scoll, setScroll] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLooping, setIsLooping] = useState(false);
    const [isShuffling, setIsShuffling] = useState(false);
    const [volume, setvolume] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(0)
    const audioRef = useRef(null);
    const [show, setShow] = useState(false);
    const [videoTime, setVideoTime] = useState(0);
    const [hovered, setHovered] = useState(false);
    const [isSliderVisible, setSliderVisible] = useState(false);
    const [clearPodcast, setClearPodcast] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShut = () => {
        setShow(false);
        setMusicBar(false);
    }

    const handleSpeakerClick = () => {
        setSliderVisible(!isSliderVisible);
    };



    /*  ----------------------  MUI Slider style management --------------------  */

    const PrettoSlider = styled(Slider)({
        color: '#81C14B',
        height: 4,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    });



    /*  ---------------------- Multy Audio array --------------------  */

    const audioUrl = [
        Song1,
        Song2
    ]

    /*  ---------------------- All Functions --------------------  */

    /*  ------- Handel Play Pause Function ------- */

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    /*  ------- Handel Rewind Function ------- */

    const handleRewind = () => {
        audioRef.current.currentTime -= 10;
        setCurrentTime(audioRef.current.currentTime);
    };

    /*  ------- Handel FastForward Function ------- */

    const handleFastForward = () => {
        // audioRef.current.currentTime += 10;
        setSelectedAudio(selectedAudio + 1)
        setCurrentTime(audioRef.current.currentTime);
    };

    /*  ------- Handel Repeat Function ------- */

    const handleLoopToggle = () => {
        setIsLooping(!isLooping);
    };

    /*  ------- Handel Shuffle Function ------- */

    const handleShuffleToggle = () => {
        setIsShuffling(!isShuffling);
    };

    /*  ------- Handel Shuffle Function ------- */

    const toggleClearPodcast = () => {
        setClearPodcast((prevState) => !prevState);
    };

    /*  ------- Handel Audio Duration Function ------- */

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
    };

    const handleEnded = () => {
        if (isLooping) {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        } else if (isShuffling) {
            const randomTime = Math.floor(Math.random() * duration);
            audioRef.current.currentTime = randomTime;
            audioRef.current.play();
        } else {
            setIsPlaying(false);
        }
    };

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setcommentclose(false)
        setForwardOpen((preState) => !preState)
    }

    const [Commentopen, setcommentclose] = useState(false)
    const toggleComment = () => {
        setcommentclose((preState) => !preState)
    }

    /*  ---------------------- Audio Time Function for Second --------------------  */

    const formatTime = (seconds) => {
        const Rounsseconds = Math.round(seconds)
        const minutes = Math.floor(Rounsseconds / 60);
        const remainingSeconds = Rounsseconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    /*  ---------------------- Music Array --------------------  */

    const musicArray = [
        {
            id: 1,
            name: 'Origin of Revolution',
            duration: '06:27',
            episode: '01',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: true,
            seriesName: 'Wildlife in Jungle'
        },
        {
            id: 2,
            name: 'Year 1947',
            duration: '06:27',
            episode: '02',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: true,
            series: false,
        },
        {
            id: 3,
            name: 'First Drill',
            duration: '06:27',
            episode: '03',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: true,
            seriesName: 'Wildlife in Jungle'
        },
        {
            id: 4,
            name: 'Control',
            duration: '06:27',
            episode: '04',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: false,
        },
        {
            id: 5,
            name: 'Politics',
            duration: '06:27',
            episode: '05',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: true,
            seriesName: 'Wildlife in Jungle'
        },
        {
            id: 6,
            name: 'Fear Inside-out',
            duration: '06:27',
            episode: '06',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: true,
            series: false,
        },
        {
            id: 7,
            name: 'Euphorian dream',
            duration: '06:27',
            episode: '07',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: true,
            seriesName: 'Wildlife in Jungle'
        },
        {
            id: 8,
            name: 'Transcend yourself',
            duration: '06:27',
            episode: '08',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: true,
            series: false,
        },
        {
            id: 9,
            name: 'Suspense',
            duration: '06:27',
            episode: '09',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: true,
            seriesName: 'Wildlife in Jungle'
        },
        {
            id: 10,
            name: 'Psychedelics Effect',
            duration: '06:27',
            episode: '10',
            listnerCount: '105',
            by: 'Deepak Kumar',
            paid: false,
            series: true,
            seriesName: 'Wildlife in Jungle'
        }

    ]


    /*-------------- Modal for Delete User -----------------*/

    const ClearAllModal = () => {
        return (
            <>
                {clearPodcast ? (
                    <Modal isOpen={clearPodcast} className='modal-dialog-centered' toggle={toggleClearPodcast} >

                        <ModalHeader className='d-flex justify-content-center border-0 Media_header'>
                            <h3>Clear Queue</h3>
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <h5 className='py-1 name-style'>This will remove all the podcasts from your queue. Are you sure you want to continue?</h5>
                            <div></div>

                        </ModalBody>
                        <ModalFooter className='d-flex align-items-center justify-content-center border-0 modalHeading_Section' >
                            <div className='text-muted'><Checkbox />&nbsp;&nbsp;Clear Currently playing podcast also?</div>
                            <div className='text-center'>
                                <Button onClick={toggleClearPodcast} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={toggleClearPodcast} >Delete</Button>
                            </div>
                        </ModalFooter>

                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*  ---------------------- Scroll per Header --------------------  */

    const DynamicHeader = () => {

        return (

            <div className='fixed-top animeForHeader'>
                <Card >
                    <div className=' d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <img src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' className='rounded' width={100} height={90} />
                            <h1 className='ml-1' >Kahani mein Twist</h1>
                        </div>
                        <div>
                            <Badge color='light-primary' pill>
                                Free
                            </Badge>
                            <Button onClick={handlePlayPause} color='primary py-2 ml-2'><Pause size={20} /> Pause</Button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
    return (

        <div>
            {musicBar ? (<Card className='fixed-bottom pt-3 pb-3' style={{ boxShadow: "0 0 4px 0 rgba(0,0,0,0.5)" }}>

                <Slider
                    aria-label="time-indicator"
                    size="large"
                    value={currentTime}
                    min={0}
                    step={1}
                    max={duration}
                    valueLabelDisplay={currentTime}
                    sx={{
                        color: '#81c14b',
                        margin: '10px',
                        position: 'absolute', top: '-24px',
                    }}
                />

                <div className='flex-mob-view  align-items-center justify-content-start pod-footer '>

                    <div className='justify-content-start'>
                        <div className='d-flex justify-content-between w-100 align-items-center' >
                            <div className='d-flex'>
                                <div className='position-relative px-3'>
                                    <img width={45} height={40} className='rounded' src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' />
                                    <img className='seriesPlayForIcon' width={25} src={PlayIcon} />
                                </div>
                                <div className='ml-1 d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className='d-flex'>
                                            <div style={{ height: '1.5rem' }} className="px-2 livestatus-con mr-0 rem-mob-view"><span></span> Live</div>
                                            <h4 className='pl-2 m-0' >The city tales1</h4>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='d-flex justify-content-around align-items-center rem-mob-view padding-for-player'>
                                <div className='pr-2'>
                                    {
                                        LikeIcon ? <div onClick={() => SetLikeIcon(!LikeIcon)} className=''>
                                            <img src={ActiveCirclelike} alt='LikeIcon' width={35} />
                                        </div> : <div onClick={() => SetLikeIcon(!LikeIcon)} className=''>
                                            <img src={Circlelike} alt='LikeIcon' width={35} />
                                        </div>
                                    }
                                </div>
                                <div className='' >
                                    <UncontrolledButtonDropdown direction='up'>
                                        <DropdownToggle color='flat-primary p-0'>
                                            <MoreVertical color='#6E6B7B' />
                                        </DropdownToggle>
                                        <DropdownMenu className='existingDrop'>
                                            <DropdownItem onClick={handleFastForward} tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem tag='a'><Download size={15} />&nbsp;Download</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem tag='a'><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>

                            </div>

                            <div style={{ borderRadius: '20px', width: '120px', height: '30px' }} className='border rem-mob-view pr-1 TimerAlignment'>
                                <div className='d-flex align-items-center'>
                                    <img src={PodacastMic} />
                                    <small className='px-2'>{formatTime(currentTime)}/{formatTime(duration)}</small>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ borderRadius: '20px', width: '120px', height: '30px' }} className='border rem-mob-view pr-1'>
                            <div className='d-flex align-items-center'>
                                <img src={PodacastMic} />
                                <small className='px-2'>{formatTime(currentTime)}/{formatTime(duration)}</small>
                            </div>
                        </div> */}
                    </div>

                    <div className='justify-content-center'>

                        <div style={{ cursor: 'pointer' }} className='' >
                            <Repeat className='rem-mob-view play-highlight'
                                onClick={handleLoopToggle} color='#808080' />
                            <SkipBack className='rem-mob-view mx-3 play-highlight' onClick={handleRewind} color='#808080' />
                            {isPlaying ? <PauseCircle className='shadow-play' style={{ cursor: 'pointer' }} onClick={handlePlayPause} size={40} color='#81C14B' /> :
                                <PlayCircle className='shadow-play' style={{ cursor: 'pointer' }} onClick={handlePlayPause} size={40} color='#81C14B' />
                            }
                            <SkipForward role='button' className='rem-mob-view mx-3 play-highlight' onClick={handleFastForward} color='#808080' />
                            <Shuffle className='rem-mob-view play-highlight' onClick={handleShuffleToggle} color='#808080' />
                        </div>

                    </div>

                    <div className='justify-content-end w-100'>
                        <div className='w-100 d-flex justify-content-around align-items-center'>
                            <div className='justify-self-center position-relative'>

                                {isSliderVisible ? <div className='VolumeSliderCustomize'> <Slider
                                    sx={{
                                        '& input[type="range"]': {
                                            WebkitAppearance: "slider-vertical",

                                        },
                                        color: '#81c14b'
                                    }}
                                    orientation="vertical"
                                    defaultValue={30}
                                    aria-label="Volume"
                                    valueLabelDisplay="auto"
                                /></div> : null}
                                {volume ? <VolumeX className='rem-mob-view mx-2' onClick={() => { setvolume(!volume); handleSpeakerClick() }} color='#808080' /> : <Volume2 className='rem-mob-view mx-2' onClick={() => { setvolume(!volume); handleSpeakerClick() }} color='#81C14B' />}

                            </div>
                            <div className=' rem-mob-view justify-self-center'>
                                <div className='text-muted text-nowrap' >Up Next</div>
                                <div className='text-nowrap text-truncate' >{`${"The Unsung heroes of 1857".slice(0, 19)}...`}</div>
                            </div>
                            <div className='pr-4' ><ChevronUp style={{ cursor: 'pointer' }} className=' mx-2' onClick={handleShow} color='#81C14B' /></div>
                            {/* <div style={{ borderRadius: '20px' }} className='border px-1 rem-mob-view'>
                            <UncontrolledButtonDropdown direction='up'>
                                <DropdownToggle color=''>
                                    <small> Audio:High</small>
                                </DropdownToggle>
                                <DropdownMenu className='existingDropQa'>
                                    <div className='p-1'>
                                        <h4>Audio Quality</h4>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-form-control-label-placement"
                                                defaultValue="High"
                                                name="radio-buttons-group"
                                            >
                                                <FormControlLabel sx={{ padding: "5px" }} labelPlacement="end" value="High" control={<Radio />} label=" High" />
                                                <FormControlLabel sx={{ padding: "5px" }} labelPlacement="end" value="Medium" control={<Radio />} label=" Medium" />
                                                <FormControlLabel sx={{ padding: "5px" }} labelPlacement="end" value="Low" control={<Radio />} label=" Low" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div> */}
                        </div>
                    </div>
                    {/* </div> */}

                </div>


                <Offcanvas style={{ height: '100%', backdropFilter: 'saturate(180%) blur(100px)', backgroundColor: 'rgba(255, 255, 255, 0.6) , rgba(255, 255, 255, 0.6)), url(your-image-url)' }} className='bg-transparent' placement='bottom' show={show} onHide={handleClose}>

                    <Offcanvas.Header style={{ flexDirection: 'inherit' }}>
                        <div className="d-flex align-items-center justify-content-between w-100" style={{ position: 'relative' }} >
                            <NavLink to="/Home" className="mr-auto flex-1">
                                <img
                                    src="/assets/images/logo.png"
                                    alt="logo"
                                    className="img-fluid logo-size"
                                />
                            </NavLink>
                            <div className=" d-flex align-items-center justify-content-center flex-grow-1" style={{ position: 'absolute', left: '50%' }} >
                                <ChevronsDown size={40} strokeWidth={1} className="chevron-down" onClick={handleClose} color="#81C14B" style={{ cursor: 'pointer', margin: 'auto' }} />
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                                <X onClick={handleShut} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                        <PodcastFooterHead />
                    </Offcanvas.Header>


                    <Offcanvas.Body className='p-0 scroll-canvas'>
                        <ClearAllModal />
                        <div style={{ overflowX: 'hidden', overflowY: 'hidden', height: "100vh" }}>
                            {/* <Row className='rowForrevert'> */}
                            {/* <Col xl='8' className='m-auto'> */}
                            {/* <Container fluid> */}
                            {/* <div className='d-flex justify-content-between'>
                                    <div className='  w-50 h-100' >
                                        <div className='d-flex justify-content-center align-items-center'>
                                        
                                        </div>
                                    
                                    </div>
                  
                                  
                                </div> */}
                            {/* </Container> */}
                            {/* </Col> */}
                            {/* </Row> */}

                            {/* Deepak sir code  */}
                            {/* <div className='d-flex justify-content-between align-items-center h-100'>
                                <div className='w-50 '>
                                    <div className=' w-100 d-flex align-items-center justify-content-center'>
                                        <div className='d-flex flex-column justify-content-between  '>
                                            <div style={{ width: '500px' }}>
                                                <img className='w-100' src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' />

                                                <div className=''>
                                                    <div className=' d-flex align-items-center justify-content-between p-2'>
                                                        <div className='d-flex align-items-center'>
                                                            <h4 className='m-2'>Kahani mein Twist</h4>
                                                            <div>
                                                                <Badge color='light-primary' pill>
                                                                    Free
                                                                </Badge>
                                                            </div>

                                                        </div>

                                                        <div>
                                                            {
                                                                LikeIcon ? <div onClick={() => SetLikeIcon(!LikeIcon)} className='mr-2'>
                                                                    <img src={ActiveCirclelike} alt='LikeIcon' /> <span>50</span>
                                                                </div> : <div onClick={() => SetLikeIcon(!LikeIcon)} className='mr-2'>
                                                                    <img src={Circlelike} alt='LikeIcon' /> <span>49</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className='px-1' style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className="controlsTime rem-mob-view mr-3">
                                                            {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}
                                                        </p>
                                                        <PrettoSlider
                                                            size="small"
                                                            value={currentTime}
                                                            aria-label="Small"
                                                            valueLabelDisplay="auto"
                                                            max={duration}
                                                        />
                                                        <p className="controlsTime rem-mob-view ml-3">
                                                            {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}
                                                        </p>
                                                    </div>

                                                    <div style={{ cursor: 'pointer' }} className='d-flex align-items-center justify-content-center mb-2' >
                                                        <span> <Repeat className='' onClick={handleLoopToggle} color='#ffffff' /></span>
                                                        <span> <SkipBack className=' mx-4' onClick={handleRewind} color='#ffffff' /></span>
                                                        {isPlaying ? <PauseCircle style={{ cursor: 'pointer' }} onClick={handlePlayPause} size={50} color='#81C14B' /> :
                                                            <PlayCircle style={{ cursor: 'pointer' }} onClick={handlePlayPause} size={50} color='#81C14B' />
                                                        }
                                                        <span><SkipForward className=' mx-4' onClick={handleFastForward} color='#ffffff' /></span>
                                                        <span><Shuffle className='' onClick={handleShuffleToggle} color='#ffffff' /></span>
                                                    </div>
                                                    <audio ref={audioRef} onT src={audioUrl[selectedAudio]}></audio>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className='w-25'>
                              
                                        <div className='d-flex justify-content-between m-2 scroll-for-podcast remove-scroll'>

                                            <Col xl='12' >
                                                <div className='border rounded p-3 d-flex text-align-center list-heading'>
                                                    <CardText>
                                                        <h3><strong>Next in queue</strong></h3>
                                                    </CardText>
                                                </div>
                                                {
                                                    musicArray.map((item, index) => {
                                                        return <div className='d-flex justify-content-between align-items-center py-3' style={{ borderBottom: '1px solid white' }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} >
                                                            <div className='d-flex'>
                                                                <div className='position-relative'>
                                                                    <img width={50} height={50} className='rounded' src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' />
                                                                    <img className='seriesPlayForIcon' width={25} src={PlayIcon} />
                                                                </div>
                                                                <div className='ml-3' >
                                                                    <div className='d-flex'> {item.paid ? <div style={{ height: '1.5rem' }} className="px-2 livestatus-con mr-0 rem-mob-view"><span></span> Paid</div> : null} {item.series ? <h4>Ep {item.episode}</h4> : null} <h4>&nbsp;&nbsp;{item.name}</h4></div>
                                                                    <div className='d-flex' >{item.series ? <p className=''>{item.seriesName}  ● By {item.by}</p> : <p>By {item.by}</p>}</div>

                                                                </div>
                                                            </div>


                                                            <div className='d-flex justify-content-between align-items-center '>
                                                                {hovered && <X />}
                                                                {hovered && <div>
                                                                    <UncontrolledButtonDropdown direction='up'  >
                                                                        <DropdownToggle color='black'>
                                                                            <MoreVertical color='black' />
                                                                        </DropdownToggle>

                                                                        <DropdownMenu className='existingDrop'>
                                                                            <DropdownItem onClick={handleFastForward} tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a'><Download size={15} />&nbsp;Download</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a'><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>}
                                                                <div>{item.duration}</div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </Col>
                                        </div>
                                    
                                </div>

                            </div> */}
                            {/* Deepak sir code  */}

                            <div className='innerscroll ' >

                                <div className='pl h-100 m-auto' >
                                    <div className='d-flex flex-column justify-content-between'>
                                        <div className='foterpodmob'>
                                            <div className='img-container' style={{ backgroundImage: 'linear-gradient(90deg, black, white)', zIndex: '5' }} >
                                                <div className='headerForpodcast p-2 simple-linear'>
                                                    <div className='d-flex align-items-center justify-content-start' >
                                                        <img src={Mic} width={25} height={28} alt='micicon' />
                                                    </div>
                                                    <div className='moreoverFormargin' >
                                                        <UncontrolledButtonDropdown direction='up'>
                                                            <DropdownToggle outline color='flat-primary' style={{ padding: '0px' }}  >
                                                                <MoreVertical color='white' />
                                                            </DropdownToggle>

                                                            <DropdownMenu className='existingDrop'>
                                                                <DropdownItem onClick={handleFastForward} tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><Download size={15} />&nbsp;Download</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                                <img className='w-100 img-container' src='https://assets-global.website-files.com/5fac161927bf86485ba43fd0/638e1ee6f632014caf868863_Blog%20Cover_2022_11_How%20to%20Start%20a%20Podcast%20For%20Free_%2010%20Easy%20Steps%20(2022).webp'  alt=''/>
                                            </div>

                                            <div className=''>
                                                <div className=' d-flex align-items-center justify-content-between p-2'>
                                                    <div className='d-flex align-items-center'>
                                                        <h4 className=''>Kahani mein Twist</h4>
                                                        <div className='px-2' >
                                                            <Badge color='light-primary' pill>
                                                               Paid
                                                            </Badge>
                                                        </div>

                                                    </div>

                                                    <div>
                                                        {
                                                            LikeIcon ? <div onClick={() => SetLikeIcon(!LikeIcon)} className='mr-2  ' >
                                                                <img src={ActiveCirclelike} alt='LikeIcon' /> <span>&nbsp;50</span>
                                                            </div> : <div onClick={() => SetLikeIcon(!LikeIcon)} className='mr-2 fotlike '>
                                                                <img src={Circlelike} alt='LikeIcon' /> <span>&nbsp;49</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>


                                                <div className='px-1' style={{ display: 'flex', alignItems: 'center' }}>
                                                    <p className="controlsTime rem-mob-view mr-3">
                                                        {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}
                                                    </p>
                                                    <Slider
                                                        aria-label="time-indicator"
                                                        size="large"
                                                        value={currentTime}
                                                        min={0}
                                                        step={1}
                                                        max={duration}
                                                        valueLabelDisplay={currentTime}
                                                        sx={{
                                                            color: '#81c14b',
                                                            margin: '0px 2px 0px 4px',
                                                        }}
                                                    />
                                                    <p className="controlsTime rem-mob-view ml-3">
                                                        {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}
                                                    </p>
                                                </div>

                                                <div style={{ cursor: 'pointer' }} className='d-flex align-items-center justify-content-center mb-2' >
                                                    <span> <Repeat className='play-highlight' onClick={handleLoopToggle} color='#81C14B' /></span>
                                                    <span> <SkipBack className=' mx-4 play-highlight' onClick={handleRewind} color='#81C14B' /></span>
                                                    {isPlaying ? <PauseCircle className='shadow-play' style={{ cursor: 'pointer' }} onClick={handlePlayPause} size={50} color='#81C14B' /> :
                                                        <PlayCircle className='shadow-play' style={{ cursor: 'pointer' }} onClick={handlePlayPause} size={50} color='#81C14B' />
                                                    }
                                                    <span><SkipForward className='play-highlight mx-4' onClick={handleFastForward} color='#81C14B' /></span>
                                                    <span><Shuffle className='play-highlight' onClick={handleShuffleToggle} color='#81C14B' /></span>
                                                </div>
                                                <audio ref={audioRef} onT src={audioUrl[selectedAudio]}></audio>

                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className='qu'>
                                    <div className=' d-flex justify-content-between table-head align-items-center'>
                                        <h3><strong>Next in queue</strong></h3>
                                        <Button color='danger' onClick={toggleClearPodcast}>&nbsp;Clear List</Button>
                                    </div>
                                    <div className=''>
                                        {
                                            musicArray.map((item, index) => {
                                                return <div className='d-flex justify-content-between align-items-center py-3' style={{ borderBottom: '1px solid #6c757d' }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} >
                                                    <div className='d-flex align-items-center'>
                                                        <div className='position-relative'>
                                                            <img width={50} height={50} className='rounded' src='https://cdn.pixabay.com/photo/2023/03/25/20/30/podcast-7876792_640.jpg' alt=''/>
                                                            <img className='seriesPlayForIcon' width={25} src={PlayIcon} alt=''/>
                                                        </div>
                                                        <div className='ml-3' >
                                                            {/* <div className='d-flex'> {item.paid ? <div style={{ height: '1.5rem' }} className="px-2 livestatus-con mr-0"><span></span> Paid</div> : null} {item.series ? <h4>Ep {item.episode}</h4> : null} <h4>&nbsp;&nbsp;{item.name}</h4></div> */}
                                                            <div className='d-flex align-items-center'>
                                                                {item.paid ? <div className="pr-1"><Badge color='danger' pill >Paid</Badge></div> : null} {item.series ? <h4>  Ep {item.episode}&nbsp;&nbsp;{item.name}</h4> : <h4>{item.name}</h4>}
                                                            </div>
                                                            <div className='d-flex' style={{ margin: '4px 0 0 0' }} >{item.series ? <p className=''>{item.seriesName}  ● By {item.by}</p> : <p>By {item.by}</p>}</div>

                                                        </div>
                                                    </div>


                                                    <div className='d-flex justify-content-between align-items-center '>
                                                        {hovered && <X />}
                                                        {hovered && <div>
                                                            <UncontrolledButtonDropdown direction='up'  >
                                                                <DropdownToggle color='black'>
                                                                    <MoreVertical color='black' />
                                                                </DropdownToggle>

                                                                <DropdownMenu className='existingDrop'>
                                                                    <DropdownItem onClick={handleFastForward} tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem tag='a'><Download size={15} />&nbsp;Download</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem tag='a'><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>}
                                                        <div>{item.duration}</div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </Card>) : null
            }
        </div >
    )
}

export default PodcastFooter