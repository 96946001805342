import React, { Fragment } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const MarketPlaceDeleteModal = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} className='modal-dialog-centered' toggle={props.toggleOpen} >
                    <ModalHeader className='d-flex justify-content-center border-0'>
                        <h3>Do you really want to Delete This Product ?</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className='text-center'>
                            <Button onClick={props.toggleOpen} color='secondary'>Cancel</Button>
                            &nbsp;
                            &nbsp;
                            <Button color='danger' onClick={()=>{props.toggleOpen(); props.setIsDelete(true)}} >Delete</Button>
                        </div>
                    </ModalBody>
                </Modal>
            }
        </Fragment>
    )
}

export default MarketPlaceDeleteModal
