import * as types from "../../Constants/UserProfile/index";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const getUserProfileByUserId = (userProfileByUserId) => ({
  type: types.GET_PROFILE_BY_USER_ID,
  payload: userProfileByUserId,
});
const getUserSportByUserId = (userSportsByUserId) => ({
  type: types.GET_USERS_SPORTS,
  payload: userSportsByUserId,
});
const getUserHobbieByUserId = (userHobbiesByUserId) => ({
  type: types.GET_USERS_HOBBIES,
  payload: userHobbiesByUserId,
});

// get user profile by user id
export const loadProfileByUserId = (id) => {
  return function (dispatch) {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/getUserProfileById`,
          { userId: id ||user.id },
          { headers: { Authorization: `Bearer ${user?.token}` } }
        )
        .then((res) => {
          //console.log("all posts------------------>:", res.data.data.successResult);
          dispatch(getUserProfileByUserId(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
          const navigate = useNavigate();
          const config = {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`,
            },
          };
          const logoutBody = {
            userId: JSON.parse(localStorage.getItem("sociomeeUser"))?.id,
          };

    // logout ============================================================================================    
         
   axios
            .post(
              `${process.env.REACT_APP_IPURL}/user/logOut/`,
              logoutBody,
              config
            )
            .then((respo) => {
              if (respo.data.data?.successResult) {
                localStorage.removeItem("sociomeeUser");
                navigate("/");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  };
};

// get user profile by user id
export const loadSportsByUserId = (id) => {
  return function (dispatch) {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/getSports`,
          { userId: id||user.id },
          { headers: { Authorization: `Bearer ${user?.token}` } }
        )
        .then((res) => {
          // console.log("all posts:", res.data.data.successResult);
          dispatch(getUserSportByUserId(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const syetemConfigurationSettings = async ({ type = "socialFeed" }) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));

  if (user) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_IPURL}/system/getAllByType`,
        { type: type },
        { headers: { Authorization: `Bearer ${user?.token}` } }
      );

      return res.data.data.successResult[0]; 
    } catch (error) {
      console.error("Error fetching system configuration settings:", error);
      return null; 
    }
  }

  return [];
};

// get user profile by user id
export const loadHobbiesByUserId = (id) => {
  return function (dispatch) {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/getHobbies`,
          { userId: user.id },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          dispatch(getUserHobbieByUserId(res.data.data.successResult.rows));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

// update user profile
export const updateUserProfile = (data) => {
  console.log("all data:=============",data);
  return function (dispatch) {
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (user) {
      axios
        .post(`${process.env.REACT_APP_IPURL}/user/update`, data, {
          headers: { Authorization: `Bearer ${user?.token}` },
        })
        .then((res) => {
          console.log("all posts:=============", res.data.data.successResult);
          dispatch(loadProfileByUserId(user.id));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};


// for uploadProfilePicture upload

export const updateUserProfileImage = () => async (dispatch, getState) => {
  try {
      let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
      // dispatch({ type: types.GET_ALL_USER_BIZPAGE });
      let { data } = await axios.post(
          `${process.env.REACT_APP_IPURL}/user/getUserProfileById`,
          { userId: user.id },
          {
              headers: { Authorization: `Bearer ${user?.token}` }
          })
      dispatch({ type: types.GET_ALL_USER_BIZPAGE_SUCCESS, payload: data.data.successResult.rows });

  } catch (error) {
      dispatch({
          type: types.GET_ALL_USER_BIZPAGE_ERROR,
          payload:
              error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
      })
  }
};


export const updatePrimaryContact = (email,mobil) => async (dispatch, getState) => {
  try {
      let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
      // dispatch({ type: types.GET_ALL_USER_BIZPAGE });
      let { data } = await axios.post(
          `${process.env.REACT_APP_IPURL}/user/updatePrimaryContact`,
          { mobile: mobil,email:email },
          {
              headers: { Authorization: `Bearer ${user?.token}` }
          })
      dispatch({ type: types.UPDATE_ISPRIMARY_SUCCESS, payload: data.data.successResult.rows });

  } catch (error) {
      dispatch({
          type: types.UPDATE_ISPRIMARY_ERROR,
          payload:
              error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
      })
  }
};
