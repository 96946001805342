import { TextareaAutosize } from '@mui/base'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import ShotzEditHeader from '../shotz_components/ShotzEditHeader'
import shotz1 from '../../../NewComponents/Videos/shotzVideos1.mp4'
import ShotzFooter from '../shotz_components/ShotzFooter'
import { useState } from 'react'

export const EditModal = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered modal-lg'>
                    <ModalHeader toggle={props.updateOpen} role="button" className='w-100'>
                        <h3 style={{ color: '#4D4D4D' }}>Edit Shotz</h3>
                    </ModalHeader>
                    <ModalBody>
                        <Row className='rounded'>
                            <Col xl='6' lg='6' md='6' sm='6'>
                                <video
                                    className='w-100 img-fluid'
                                    height="400px"
                                    autoPlay
                                    controls={false}
                                >
                                    <source src={shotz1} type="video/mp4" />
                                </video>
                            </Col>
                            <Col xl='6' lg='6' md='6' sm='6' className='position-relative'>
                                <div className="comment-part ">
                                    <div className="user-media align-items-center py-1 d-flex justify-content-between">
                                        <div className="media">
                                            <Link className="mx-2">
                                                <img src="/assets/images/story-3.jpg" height='40px' width='40px' className="rounded-circle" alt="user" />
                                                <span className="available-stats">
                                                </span>
                                            </Link>
                                            <div className="media-body" style={{ cursor: 'pointer' }}>
                                                <h5 className="user-name">Paige Turner</h5>
                                                <h6>alabma, USA</h6>
                                            </div>
                                        </div>
                                        <ShotzEditHeader />
                                    </div>
                                    <TextareaAutosize aria-label='minimum height' fullWidth className='w-100 h-50 scroll p-2 mt-4 text-area-box' minRows={4} placeholder='Describe your shotz ' />
                                    <div>
                                        <ShotzFooter />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
        </>
    )
}
