import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Accordion } from "react-bootstrap";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ArrowLeftCircle, ArrowRightCircle, Square, X } from "react-feather";
import { BarDumyData } from "./DummyArrayAdmanager";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AdManagerBarPage = ({ SelectedReportedType }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
    barThickness: 40, // Adjust the value to change the bar size
  };

  const labels = [
    "Social Feed",
    "Shortz",
    "Story",
    "MarketPlace",
    "Groups",
    "Biz Pages",
    "Podcast",
  ];

  const [OpenCustomeSelection, setOpenCustomeSelection] = useState(true)

  const [IsDataShow, setIsDataShow] = useState(BarDumyData)

  const ManageDataShow = (label) => {
    if (IsDataShow.find((x) => x.label === label)) {
      setIsDataShow(IsDataShow.filter((x) => x.label !== label));
    } else {
      let NewDataSet = [...IsDataShow, BarDumyData.find((x) => x.label === label)];
      setIsDataShow(NewDataSet);
    }
  };

  const data = {
    labels,
    datasets: IsDataShow
  };

  const LabelData = [
    {
      key: 'Men', value: ''
    },
    {
      key: 'Women', value: ''
    },
    {
      key: 'Other', value: ''
    }
  ]

  return (
    < >
      <Row className='rowForrevert'>
        <Col style={{ right: '0', padding: '10px' }} xl={OpenCustomeSelection ? 10 : 12}>
          <div className="position-absolute d-flex align-items-center justify-content-between w-100 px-4">
            <div className="fontForSubchildCardheading" >
              <h4>Bar Chart</h4>
            </div>
            <div onClick={() => setOpenCustomeSelection(!OpenCustomeSelection)} >
              {OpenCustomeSelection ? <ArrowRightCircle color="#808080" size={25} /> : <ArrowLeftCircle color="#808080" size={25} />}
            </div>
          </div>

          <Bar options={options} height={120} data={data} style={{ paddingTop: '25px' }} />
          <div className="fontForSubchildCardheading d-flex align-items-center justify-content-center">
            <Square strokeWidth={0} fill="#762A89" /> <h5 className="px-2">Men</h5>
            <Square strokeWidth={0} fill="#298DB8" /> <h5 className="px-2">Women</h5>
            <Square strokeWidth={0} fill="#7BD77F"/> <h5>Other</h5>
          </div>
        </Col>
        <Col xl='2' className={OpenCustomeSelection ? '' : 'd-none'}>
          <Card className='shadow' >
            <CardHeader>
              <div className="fontForSubchildCardheading d-flex justify-content-between align-items-center">
                <h4>Customise {SelectedReportedType}</h4>
                <h5><X color="#808080" size={25}  onClick={() => setOpenCustomeSelection(false)} style={{cursor:'pointer'}}/></h5>
              </div>
            </CardHeader>
            <CardBody className='p-0'>
              <div>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordion-text">Our breakdowns</Accordion.Header>
                    <Accordion.Body>
                      <FormGroup>
                        {LabelData.map((data, index) => (
                          <FormControlLabel key={index} control={<Checkbox defaultChecked onChange={() => ManageDataShow(data.key)} />} label={data.key} />
                        ))}
                      </FormGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AdManagerBarPage;
