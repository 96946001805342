import React, { useState } from "react";
import { ChromePicker } from 'react-color'
import { useEffect } from "react";

const ColorPicker = ({
    OpenGradientReference,
    gradientReference,
    closeBgClick,
    clickGradient
}) => {
    console.log('Close bg click', closeBgClick)
    // thought color selection
    const [colorPick, setColors] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        setColors('#f6f6f6')
    }, [])

    const colorChangeUpdater = (colorPick) => {
        clickGradient(colorPick)
        setColors(colorPick)
    }

    return (
        <>
            <div ref={OpenGradientReference}>
                <button style={{ border: 'none', backgroundColor: 'transparent' }}
                    onClick={() => setShowColorPicker(showColorPicker => !showColorPicker)}>
                    <img className='ml-1' ref={gradientReference} width={26} height={26} src="/assets/images/bgColor24Old.png" onClick={(e) => {
                        clickGradient(colorPick);
                    }}
                        alt='...'
                    />
                </button> </div>
            {showColorPicker && (
                <div style={{ position: 'absolute', zIndex: '100', right: '0' }}>
                    <ChromePicker styles={{ marginLeft: '100px' }}
                        color={colorPick}
                        onChange={colorPick => { colorChangeUpdater(colorPick.hex) }}
                    />
                    <div className="close-icon" style={{ position: 'absolute', zIndex: '100', right: '0', top: '0' }}
                        onClick={(e) => {
                            setShowColorPicker(false);
                        }}
                    >
                        <button style={{ color: 'white', background: 'transparent', border: 'none' }}>X</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ColorPicker;

