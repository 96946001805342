import React from 'react'
import { Fragment } from 'react'

const NftDropProfileDetail = () => {

    //================ Main_Return_Function ===================//
    return (
        <Fragment>
            <div className='Drop_Profile_Detail_Page' >
cvbcfbcvbcvbcvb
            </div>
        </Fragment>
    )
}

export default NftDropProfileDetail