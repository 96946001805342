import React, { Fragment, useState } from 'react'
import { Container, Card, CardHeader, CardBody, Button } from 'reactstrap'
import Profile from '../Img/Profile.png'
import CardImg from '../Img/CardImg.png'

const AdCradPrev = () => {
    const [readMore, setReadMore] = useState(false)

    //=================== Main_return_function ==============//
    return (
        <Fragment>
            <Container>
                <div className='Page_Body_section container-fluid'>
                    <Card className='AdPost_Display_Crads my-3'>
                        <CardHeader className='bg-white border-bottom-0 w-100'>
                            <div className='d-flex justify-content-between align-items-start py-2 mr-2'>
                                <div className='AdManager_Card_User'>
                                    <img src={Profile} className='img-fluid' width={40} height={40} alt='UserImage' />
                                    <div className='ml-2 Card_Profile_Info'>
                                        <h3>Foiod Market Zone</h3>
                                        <h4 style={{
                                            margin: "0",
                                            padding: "0",
                                            color: "#888888",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            lineHeight: "18px"
                                        }}>Sponsored</h4>
                                    </div>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className="Post_Dispaly_Card_body" >
                            <div className='Ad_PreviewCard_blk'>
                                <img src={CardImg} alt='paid' className='AdPrev_Img img-fluid' />
                                <div className='AdPreview_Card_bottom_section'>
                                    <div className='AdPreview_Card_content_inner'>
                                        <div className='d-flex align-items-start'>
                                            <img src={Profile} className='img-fluid' width={40} height={40} alt='UserImage' />
                                            <div className='ml-3 Card_Tag_content'>
                                                <h3>Food Market Zone</h3>
                                                <h4>Sponsored</h4>
                                            </div>
                                        </div>
                                        <Button color='primary' className='Card_Know_btn'>Know More</Button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "20px" }}>
                                <div className='Crad_Text-heading'>
                                    <h3>This award goes to the coworker who’s always...</h3>
                                </div>
                                <h4 style={{ color: "#888888", fontSize: "12px", fontWeight: "400", lineHeight: "18px", margin: "0 0 8px" }}>01 July, 2022</h4>
                                <div className='SingleMedia_Content_blk mt-2'>
                                    <h3>
                                        This award goes to the coworker who’s always doing the thankless work of keeping the office up to date on pop culture.
                                        {readMore ? "always doing the thankless work of keeping the office" : null}
                                        <span onClick={() => setReadMore(!readMore)} style={{ color: "#81C14B", cursor: "pointer" }}>...{!readMore ? "Read more" : "Read less"}</span>
                                    </h3>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </Fragment>
    )
}

export default AdCradPrev
