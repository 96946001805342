import React from 'react'
import { Fragment } from 'react'
import { HobbiesDataOption } from '../DummyArray/DummyData'
import Pen1 from '../Img/Pen1.png'

const MyHobbies = () => {

    //======================== Main_Return_Function ===================//
    return (
        <Fragment>
            <div className="setting_Group_blk_section ">
                <div className="Settings_card-title d-flex justify-content-between">
                    <h3>My Hobbies</h3>
                    <div className="settings">
                        <div className="setting-btn" >
                            <img
                                src={Pen1} width={12} className="img-fluid"
                                style={{ cursor: 'pointer' }}
                                alt='penIcon'
                            />
                        </div>
                    </div>
                </div>
                <div className="card-block-box">
                    <ul className="hobbies-blk">
                        {
                            HobbiesDataOption.map((data) => (
                                <li>
                                    {data.icon1}&nbsp;
                                    {data.name1}
                                </li>
                            ))
                        }

                    </ul>

                </div>

            </div>
        </Fragment>
    )
}

export default MyHobbies