import React from 'react'
import { Fragment } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ReportGroupData } from '../Components/EventsDataArray'

const ReportModal = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered' >
                    <ModalHeader toggle={props.updateOpen} className="My-Member_Header_section">
                        <h2>Report a Event</h2>
                    </ModalHeader>
                    <ModalBody>
                        <div className='Modal_Body_Section py-2 AboutModal_Main_section'>
                            <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                        </div>
                        <div>
                            {
                                ReportGroupData.map((data) => (

                                    <div className='d-flex justify-content-between Report_Section py-3 AboutModal_Main_section'>
                                        <h4 className='text-capitalize'>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <Input
                                id="ReportName"
                                name="ReportName"
                                placeholder="Write a comment..."
                                type="Report"
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center'>
                        <Button color="primary" className="w-25">Submit</Button>
                        <Button onClick={props.updateOpen} outline className="ml-2 w-25">Cancel</Button>
                    </ModalFooter>

                </Modal>
            }
        </Fragment>
    )
}

export default ReportModal