import React from 'react'
import { Fragment } from 'react';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import BizMenu from './BizMenu';
import { XCircle } from 'react-feather';
import { Pagination, Rating, Stack } from '@mui/material';
import AvatarGroup from '../../NewComponents/AvatarGroup';
import { Button, Col, Row } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { BizAllData } from './Components/BizPageArray'
import './biz.css'

const BizSuggested = () => {


  //================== main_return_section ====================//
  return (
    <Fragment>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <BizMenu />
          <div className="container-fluid section-t-space px-0">
            <div className="group-single-list-block">
              <div className="Biz-heading_section py-2">
                <h2  className='bizhead'>Suggested Pages (61)</h2>
              </div>
              <Row >
                {
                  BizAllData.map((data) => (
                    <Col lg={3} md={4} sm={6} className="mb-4 cardinbiz cardbizcard" >
                       <NavLink to="/BizDetail" >
                      <div className="BizPage_Card_section1">
                        <div className="BizPage_option_menu">
                          <XCircle color='#FFFFFF' size={20} onClick={(e) => { e.preventDefault(); }}  />
                        </div>
                        <NavLink to="/BizDetail" className="bizpagelink-blk">
                          <img src={data.img} className="img-fluid group-img" />
                          <div className="group-adminprofile-blk">
                            <img src="assets/images/post-6.jpg" className='rounded-circle' />
                            </div>
                        </NavLink>
                        <NavLink to="/BizDetail" >
                        <div className="group-content">
                         
                            <div className='d-flex align-items-center justify-content-center'>
                              <h4 className='CardTextHeading'>{data.cardName}  </h4>
                            </div>
                            <h5>{data.category}</h5>
                            <div className='d-flex justify-content-center'>
                              <Stack spacing={1}>
                                <Rating name="read-only" defaultValue={2.5} readOnly />
                              </Stack>
                            </div>
                            <div className="people-likes matual-friend-sec">
                              <ul className="matual-friend-blk">
                                <AvatarGroup />
                              </ul>
                              <h6>+79k Followers</h6>
                            </div>
                        
                          <div className="BizPage-buttons-blk">
                            {/* <Link to="#" className="group-btn">Invite People</Link> */}
                            <Button outline color='primary' className=" BizBTN_section"  onClick={(e) => { e.preventDefault(); }}  >Follow</Button>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      </NavLink>

                    </Col>
                  ))
                }

              </Row>
              <div className='d-flex justify-content-center mb-2'>
                <Stack>
                  <Pagination count={10} className='PaginationCount'>
                  </Pagination>
                </Stack>
              </div>

            </div>
          </div>
        </div>
        <RightSidebar />
      </div>

    </Fragment>
  )
}

export default BizSuggested
