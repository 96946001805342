import React, { useState } from 'react'
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap'
import UserImg from '../../../NewComponents/IMG/img.svg'


const BlockedPeople = () => {

    /**        States and functions       * */

    const [centeredModal, setCenteredModal] = useState(false)

    const action = (a) => {
        setCenteredModal(!centeredModal)
    }

    /**        blockuser Array         * */

    const blockeduser = [
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
    ]

    /**       Unblock Modal         * */

    const UnblockModal = () => {
        return <>{
            centeredModal === 'a' ? <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered text-center'>
                <div className='py-3 rounded bg-light border-bottom' toggle={() => setCenteredModal(!centeredModal)}>
                    <h2>Unblock User?</h2>
                </div>
                <ModalBody>
                    <h3 className='py-3'>Do you really want to unblock</h3>
                    <div className='p-2 '>
                        <Button className="mr-3" outline onClick={() => setCenteredModal(!centeredModal)}>
                            Cancel
                        </Button>
                        <Button color='danger' onClick={() => setCenteredModal(!centeredModal)}>
                            Unblock
                        </Button>
                    </div>
                </ModalBody>
            </Modal> : <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered text-center'>
                <div className='py-3 rounded bg-light border-bottom' toggle={() => setCenteredModal(!centeredModal)}>
                    <h2>Unblock All User?</h2>
                </div>
                <ModalBody>
                    <h3 className='py-3'>Do you really want to unblock All</h3>
                    <div className='p-2 '>
                        <Button className="mr-3" outline onClick={() => setCenteredModal(!centeredModal)}>
                            Cancel
                        </Button>
                        <Button color='danger' onClick={() => setCenteredModal(!centeredModal)}>
                            Unblock All
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        }
        </>

    }

    return (
        <div>

            <div className='py-2'>
                <div className='d-flex align-items-center justify-content-between py-2 hide-setting-options'>
                    <h4 className='text-muted mb-2'>Blocked people can not see any activity by group </h4>
                    <h3 style={{ cursor: 'pointer', fontSize: "16px", fontWeight: "600" }} onClick={() => setCenteredModal('b')} className='text-primary'>Unblock All</h3>
                </div>
                <div className=' hide-setting-options1'>
                    <h4 className='text-muted mb-2 d-flex justify-content-center'>Blocked people can not see any activity by group  </h4>
                    <h3 style={{ cursor: 'pointer', fontSize: "16px", fontWeight: "600" }} onClick={() => setCenteredModal('b')} className='text-primary d-flex justify-content-center'>Unblock All</h3>
                </div>
            </div>


            <Row className="blockmodalset">
                <UnblockModal />
                {
                    blockeduser.map((item) => {
                        return <Col xl='6' className="mb-4">
                            <div className='d-flex align-items-center justify-content-between border rounded  p-3'>
                                <div className='d-flex align-items-start'>
                                    <img src={UserImg} width={40} height={40} />
                                    <div className='ml-2'>
                                        <h3>{item.fullName}</h3>
                                        <h5 className='pt-2'>{item.userName}</h5>
                                    </div>
                                </div>
                                <div>
                                    <Button color='danger' onClick={() => setCenteredModal('a')} >Unblock</Button>
                                </div>
                            </div>
                        </Col>
                    })
                }
            </Row>

        </div>
    )
}

export default BlockedPeople