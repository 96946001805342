import React from 'react'
import { Fragment } from 'react'
import SocioMeeNFTLogo from '../Icon/SocioMeeNFTLogo.svg'
import Wallet from '../Img/wallet.png'
import NftProfileImg from '../Img/NftProfileImg.png'
import { Search, X } from 'react-feather'
import { Button, Input, Nav, NavItem, NavLink } from 'reactstrap'
import { useState } from 'react'
import CretionNft from '../Components/CretionNft'


const MpHeader = ({ currentActiveTab, toggle }) => {
    //============================ Tab_Data 
    const MpNftTabData = [
        {
            id: '1',
            name: "Explore"
        },
        {
            id: '2',
            name: "Stats"
        },
        {
            id: '3',
            name: "Drops"
        },
        {
            id: '4',
            name: "Activity"
        },
        {
            id: '5',
            name: "Cretion"
        }
    ]

    //======================= Main_Return_Function ===================//
    return (
        <Fragment>
            <header className="header-light">
                <div className="container-fluid custom-padding">
                    <div className="header-section">
                        {/*================= start_Top_Left_Menu_Blk ====================*/}
                        <div className="header-left">
                            <div className="brand-logo">
                                <NavLink to="/Home">
                                    <img
                                        src={SocioMeeNFTLogo}
                                        alt="logo"
                                        width={40}
                                        height={40}
                                        className="img-fluid"
                                    />
                                </NavLink>
                            </div>
                            <li
                                className=" d-lg-block d-none header-btn btn-group message-btn"
                            >
                                <a
                                    className="main-link"
                                    href="/"
                                >
                                    <div className="border rounded">
                                        <Search className="SerachThisButtonleft" size={18} />
                                        <Input
                                            type="text"
                                            className="form-controler w-100"
                                            placeholder="Search items, collections, and accounts"
                                        />
                                    </div>
                                </a>
                            </li>
                        </div>
                        {/*================= End_Top_Left_Menu_Blk =======================*/}

                        {/*================= start_Top_Right_Menu_Blk ====================*/}
                        <div className="header-right">
                            <ul className="option-list">
                                {
                                    MpNftTabData.map((data) => (
                                        <li className=" d-lg-block d-none ml-4" >
                                            <h4
                                                active={currentActiveTab === data.id}
                                                onClick={() => { toggle(data.id) }}
                                                className={currentActiveTab === data.id ? 'NFT_Tab_ActiveBtn_Blk' : "NFT_Tab_Btn_Blk"} > {data.name}</h4>
                                        </li>
                                    ))
                                }
                                <li className=" d-lg-block d-none  ml-4">
                                    {/* <NavLink to='/CretionNft' >
                                        <div>
                                        <Button color="primary " className="NFT_Create_Btn">
                                            Create
                                        </Button>
                                        </div>
                                    </NavLink> */}
                                   
                                </li>
                                <li className=" d-lg-block d-none  ml-2">
                                    <div className='Wallet_Btn_Blk'>
                                        <img src={Wallet} width={18} height={18} />
                                    </div>
                                </li>
                                <li className='ml-2'>
                                    <div className='NFT_Profile_Blk' >
                                        <img src={NftProfileImg} className='img-fluid' />
                                    </div>

                                </li>
                            </ul>
                        </div>
                        {/*================= end_Top_Right_Menu_Blk ======================*/}
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default MpHeader