import React from 'react'
import { useState } from 'react'
import { Button } from 'reactstrap';

export default function ArchiveComponent() {
    const [Archive ,setArchive]=useState(false);
  return (
    <div onClick={() => setArchive(!Archive)}>
                    {Archive ? <Button color='primary'>
                        Archived Ad
                    </Button> :
                        <Button outline color='primary'>
                            Archive Ad
                        </Button>}
                </div>
  )
}
