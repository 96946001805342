import React, { useState,useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import GrpImg1 from '../../NewComponents/IMG/Grly1.svg'
import GrpImg2 from '../../NewComponents/IMG/Grly2.svg'
import GrpImg3 from '../../NewComponents/IMG/Grly3.svg'
import './style.css';
import axios from 'axios'
import { Link } from 'react-router-dom'

const Gallery = () => {
const [Images,setImages]=useState([])
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
 const [userMedia,setUserMedia]=useState([]);
 useEffect(()=>{

 },[Images])
  useEffect(() => {
    const body={
      userId:user.id
    }
   const res= axios
      .post(
        `${process.env.REACT_APP_IPURL}/post/getAllPosts`,
        {body},//userId
        {
          headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
          },
      }
      )
      .then((res) => {
        setUserMedia(res.data.data.successResult);
        // console.log(res.data.data.successResult,"gallery media")
        let len=res?.data?.data?.successResult?.rows?.length;
        let images=[]
        for(let i=0;i<len;i++){
          let url=res?.data?.data?.successResult?.rows[i]?.mediaList
          let urlLength=res?.data?.data?.successResult?.rows[i]?.mediaList?.length;
          for(let j=0;j<urlLength;j++){
            if(url[j].fileType==='image'){
              images.push(url[j].fileURL);
            }
            
          }
        }
        setImages(images)
        console.log(Images,"    images")
        // console.log("gallery media  ",)
      })
      .catch((error) => {
        console.log(error);
      });
     
  }, []);

  return (
    <Card className='groupBottom-custom-block border-0 shadow-sm'>

      <CardBody className='Socialgallery'>
        <div className='CommonHeadingSeg'>
          <h3 style={{ color: "#333333" }}>Gallery</h3>
          <Link to="/MyMediaListView"><h5>{Images.length} Photos</h5></Link>
        </div>
      {/* {  Images.map((url,index)=>{
return (
  <> */}
   {/* <Col xl='8' className='m-0 p-1'>
            <div>
              <img src={url} className='img-fluid Grallery-frstImg' />
            </div>
          </Col> */}
          <Row >
          <Col xl='8' className='m-0 p-1'  >
            <div  style={{ height: '100%', overflow: 'hidden' }} >
              <img src={Images[0]} style={{ width: '100%', height: '100%', objectFit: 'cover' }}   />
            </div>
          </Col>
          <Col xl='4' className='m-0 p-1' >
            <div style={{ height: '50%', overflow: 'hidden' }}>
              <img src={Images[1]} className='img-fluid Grallery-ScndImg'  style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
            <div style={{ height: '50%', overflow: 'hidden' }}>
              <img src={Images[2]} className='img-fluid mt-2' tyle={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          </Col>
        </Row>
        <Row style={{ height: '150px' }}>
          <Col className='m-0 p-1'  style={{ height: '100%', overflow: 'hidden' }}>
            <div style={{ height: '100%', overflow: 'hidden' }} >
              <img src={Images[3]} className='img-fluid Grallery-ThirdImg' style={{ width: '100%', height: '100%', objectFit: 'cover' }}  />
            </div>
          </Col>
          <Col className='m-0 p-1' style={{ height: '100%', overflow: 'hidden' }}>
            <div style={{ height: '100%', overflow: 'hidden' }}>
              <img src={Images[4]} className='img-fluid' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          </Col>

          <Col className='m-0 p-1'>
            <div className='SocialgallerySubChild'style={{ height: '100%', overflow: 'hidden' }}>
              <div className='SocialgallerySubChildCount'>{Images.length-5}</div>
             <Link to="/MyMediaListView"> <img src={Images[5]} className='img-fluid h-100 Grallery-FourthImg' style={{ width: '100%', height: '100%', objectFit: 'cover' }} /></Link>
            </div>
          </Col>
        </Row>
  {/* </>
)
        })} */}
        
      </CardBody>
    </Card>
  )
}

export default Gallery;



// import React, { useState, useEffect } from 'react';
// import { Card, CardBody, Col, Row } from 'reactstrap';
// import axios from 'axios';
// import './style.css';

// const Gallery = () => {
//   const [userMedia, setUserMedia] = useState([]);

//   useEffect(() => {
//     axios
//       .post(
//         `${process.env.REACT_APP_IPURL}/post/getAllPosts`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
//           },
//         }
//       )
//       .then((res) => {
//         setUserMedia(res.data.data.successResult);
//         console.log(res)
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   return (
//     <Card className="groupBottom-custom-block border-0 shadow-sm">
//       <CardBody className="Socialgallery">
//         <div className="CommonHeadingSeg">
//           <h3 style={{ color: "#333333" }}>Gallery</h3>
//           <h5>{userMedia.length} Photos</h5>
//         </div>
//         <Row>
//           {userMedia.map((media, index) => (
//             <Col key={index} xl="4" className="m-0 p-1">
//               <div>
//                 <img src={media.imageUrl} className="img-fluid" alt={`Image ${index + 1}`} />
//               </div>
//             </Col>
//           ))}
//         </Row>
//       </CardBody>
//     </Card>
//   );
// };

// export default Gallery;



