// import React from 'react'
// import Header from '../Header';
// import LeftSidebar from '../LeftSidebar';
// import RightSidebar from '../RightSidebar';
// import BizTopBanner from './BizTopBanner';
// import BizDetailMenu from './BizDetailMenu';
// import './biz.css'
// import { Fragment } from 'react';
// import AboutLeftSide from './Components/AboutLeftSide';
// import AboutRightSide from './Components/AboutRightSide';
// import { Col, Row } from 'reactstrap';
// import TopBanner from './TopBannerSection/TopBanner';
// import AboutTopBanner from './TopBannerSection/AboutTopBanner';

// const BizAbout = () => {
//     return (
//         <Fragment>
//             {/* ----------------import header section------------ */}
//             <Header />
//             <div className="page-body container-fluid profile-page">
//                 <LeftSidebar
//                 />
//                 <div className="page-center">
//                     <TopBanner />
//                     <AboutTopBanner />
//                     <BizDetailMenu />
//                     <div className="container-fluid section-t-space px-0">
//                         <div className="page-content">
//                             <div className="content-center w-100">
//                                 <div className="gdpost-about-blk">
//                                     <Row>
//                                         <Col md={4}>
//                                             <AboutLeftSide />
//                                         </Col>
//                                         <Col md={8}>
//                                             <AboutRightSide />
//                                         </Col>
//                                     </Row>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <RightSidebar></RightSidebar>
//             </div>
//         </Fragment>
//     )
// }

// export default BizAbout

import React from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import TopBanner from './TopBannerSection/TopBanner'
import AboutTopBanner from './TopBannerSection/AboutTopBanner'
import BizDetailMenu from './BizDetailMenu';
import AboutBiz from './Components/AboutBiz';
import './biz.css'
import { Fragment } from 'react';

const BizAbout = () => {
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center ">
                    <div className='bizdetailprofile'><AboutTopBanner  /></div>
                   <div className='bizdetailprofile'><BizDetailMenu /></div>
                   <div className='bizdetailprofile' >  <AboutBiz /></div>
                                 
                  
                    
                </div>
                <RightSidebar></RightSidebar>
            </div>
        </Fragment>
    )
}

export default BizAbout