import React, { Fragment, useState } from 'react'
import Pen1 from '../Img/Pen1.png'
import Briefcase from '../Img/briefcase.png'
import { Button, Input, Label, Modal, ModalFooter } from 'reactstrap'
import { loadProfileByUserId, updateUserProfile } from '../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { loadAllLanguages, loadAllMaritalStatus, loadAllProfessions } from '../../../Services/Actions/UserProfile/getUsersSubModulesAction';
import { addProfessionAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Radio } from '@mui/material';
import { XCircle } from 'react-feather';
import axios from 'axios';
import { SweetErrorAlert } from '../../group-components/AllGroupsSections/SweetErrorAlert';

const MyProfession = (props) => {
    let dispatch = useDispatch();
    const [professionIds,setProfessionIds]=useState('');
    const { allProfessions } = useSelector(state => state.getUserSubModulesData);
    const addProfessionToAdmin = useSelector((state) => state.addAllProfessionData);
    const [filteredProfessions, setFilteredProfessions] = useState(allProfessions?.rows || []);
    const { loading, error, result } = addProfessionToAdmin;
    const [render,setRender] = useState(false)
    const [data,setData]=('')
    const [searchKey,setSearchKey]=useState('')
    const [pageIndex,setPageIndex]= useState(0)
    const [pageSize,setPageSize]=useState(100);

   

    const [professionOpen, setProfessionOpen] = useState(false);
    const toggleProfession = (id) => {
        setProfessionIds(id);
        setProfessionOpen((prevState) => !prevState);
    };
    
    // console.log("props================================",props?.userProfileByUserId?.professionId)
    const [profession, setProfession] = useState(props?.userProfileByUserId?.professionId||null);


  useEffect(() => {
    // Update profession state if prop changes
    if (props?.userProfileByUserId?.professionId !== profession) {
      setProfession(props?.userProfileByUserId?.professionId || null);
    }
  }, [props?.userProfileByUserId?.professionId]);

    
    // console.log("profession========================",profession)

    const changeProfession = () => {
        const data = { professionId: profession };
        if(data==''|| data=={} || data.professionId==''){
            SweetErrorAlert("Please Select atleast one profession")
        }else{
        dispatch(updateUserProfile(data));
        setProfessionOpen((prevState) => !prevState);
        }
    };

    useEffect(() => {
        dispatch(loadAllProfessions());
    }, [render]);

    useEffect(() => {
        if (result === 'Added') {
            dispatch(loadAllProfessions());
        }
    }, [result]);

    useEffect(() => {
        if (searchKey.trim() === '') {
            setFilteredProfessions(allProfessions?.rows || []);
        } else {
            const filtered = allProfessions?.rows.filter((data) =>
                data.name.toLowerCase().includes(searchKey.toLowerCase())
            );
            setFilteredProfessions(filtered);
        }
    }, [searchKey, allProfessions]);

    
    

    const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/admin/getAllProfessions`,
        {
          pageIndex: pageIndex,
          pageSize: pageSize,
          searchKey: searchKey
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`
          }
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching professions:", error);
    }
  };

  useEffect(() => {
    fetchData();

  }, [searchKey, pageIndex, pageSize]);

   
    return (
        <Fragment>
            <div className="card-title-cont-block rounded">
                <div className="card-title About_section">
                    <h3>Profession</h3>
                    {props?.type!=="viewer" && (
                        <div className="settings">
                        {props?.userProfileByUserId?.professionId ? (
                            <div onClick={(e)=>toggleProfession(props?.userProfileByUserId?.professionId)} className="setting-btn">
                            <img src={Pen1} width={12} className="img-fluid" />
                        </div>
                        ):(
                            <div onClick={(e)=>toggleProfession(props?.userProfileByUserId?.professionId)} style={{ position: 'relative',padding:"-1rem"+'!important', color:"#81C14B" }}>
                             <p style={{ position: 'relative',padding:"-1rem"+'!important' }}>+ Add </p>
                            </div>
                        )}
                        
                    </div>
                    )}
                    
                </div>
                {props?.userProfileByUserId?.professionId && (
                <div className="about-content About_section" style={{marginTop:'-24px'}}>
                    <ul >
                        <li className='d-flex align-items-center'>
                           
                            <div className="icon ">
                                <img src={Briefcase} alt="img" className='img-fluid Icon_section' width={20} height={20} />
                            </div>
                            
                            <div className="Profession_detail">
                                <h4>{props.userProfileByUserId?.professionName}</h4>
                            </div>
                        </li>
                    </ul>
                </div>
                )}
            </div>
            <div className='Modal_section Profession' >
           
                        {
                            professionOpen !== false &&
                            
                            <Modal scrollable isOpen={professionOpen} toggle={toggleProfession} className='modal-dialog-centered'>
                                 {/* <h3 className='text-end circle' onChange={onclose}><XCircle/></h3> */}
                                <div className='w-100 border-bottom'>
                                    <div className='mx-3 py-4 Profession_Modal_section'>
                                        <h4>Add Profession</h4>
                                        <p  onClick={changeProfession}>Save</p>
                                    </div>
                                    <div className="AboutModal_Main_section p-3 py-3">
                                        <div className="profile-search-blk w-100 sticky-top ">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            <input type="text" className="form-control " name="search" placeholder="Find profession..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='Modal_scroll_box' id='Scroll-1'>
                                    
                                    <div className="Modal_Option_Data ">
                                        {
                                            filteredProfessions?.map((data) => (
                                                <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-3'>
                                                <h4>{data.name}</h4>
                                                <label 
                                                    htmlFor={data.name} 
                                                    style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                    }}
                                                >
                                                    <Radio
                                                    type="radio"
                                                    id={data.name}
                                                    name="profession"
                                                    onChange={() => setProfession(data.id)}
                                                    checked={data.id === profession}
                                                    style={{ marginLeft: '10px' }}  // Adjust as necessary
                                                    />
                                                </label>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </Modal>
                        }
                    </div>
        </Fragment>
    )
}

export default MyProfession