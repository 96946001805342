import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    createCategory: [],
    loading: true,
};

 const CreateCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SOCIOMATE:
            return {
                ...state,
                createCategory: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default CreateCategoryReducer;