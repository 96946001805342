import React, { useState } from 'react'
import pollIcon from '../../../../NewComponents/IMG/pollIcon.svg'

const Poll = () => {

    const [checked, setChecked] = useState(false)
    const toggle = () => {
        setChecked((prevState) => !prevState)

    }

    const Unfilleddata = [
        {
            valuelabel: 'A',
            valuekey: 1
        },
        {
            valuelabel: 'B',
            valuekey: 2
        },
        {
            valuelabel: 'C',
            valuekey: 3
        },
        {
            valuelabel: 'D',
            valuekey: 4
        }
    ]

    const filleddata = [
        {
            valuelabel: 'A',
            valuekey: 1
        },
        {
            valuelabel: 'B',
            valuekey: 2
        },
        {
            valuelabel: 'C',
            valuekey: 3
        },
        {
            valuelabel: 'D',
            valuekey: 4
        }
    ]

    return (
        <>
            <div className="poll-homeoption-blk p-2">
                <div className='d-flex align-items-center'>
                    <img src={pollIcon} className='mr-1' alt="poll" />
                    <h2>Which is the best car of 2022?</h2>
                </div>

                <h4 className='text-muted my-2 mb-3'>Total Participant: 234</h4>

                {
                    Unfilleddata.map((item, index) => {
                        return (
                            <div onClick={toggle} key={index + 1} style={{ borderRadius: '20px', background: '#E5F0E0' }} className='p-2 mb-2 w-100' >
                                <div className='d-flex justify-content-start align-items-center'>
                                    <input
                                        className="radio_animated ml-2"
                                        type="radio"
                                        id={`inputpoll${index + 1}`}
                                        checked={checked}
                                    />
                                    <h4>{item.valuelabel}</h4>
                                </div>
                            </div>
                        )
                    })
                }


            </div>

            <hr />

            <div className="poll-homeoption-blk p-2">
                <div className='d-flex align-items-center'>
                    <img src={pollIcon} className='mr-1' alt="poll" />
                    <h2>Which is the best car of 2022?</h2>
                </div>

                <h4 className='text-muted my-2 mb-3'>Total Participant: 234</h4>

                {
                    filleddata.map((item, index) => {
                        return (
                            <div key={index} style={{ borderRadius: '20px', background: '#E5F0E0' }} className='mb-2 w-100' >
                                <div className='p-2' style={{ borderRadius: '20px', background: '#FFCC00' }}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <input
                                                className="radio_animated ml-2"
                                                type="radio"
                                                id={`inputpoll${index + 1}`}
                                                checked
                                            />
                                            <h4>{item.valuelabel}</h4>
                                        </div>
                                        <h4>25/100</h4>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </>
    )
}

export default Poll