import React, { useRef, useState } from 'react'
import { AlertCircle, AlertTriangle, Bell, ChevronsDown, ChevronsUp, Circle, Copy, Eye, Filter, Heart, LogIn, LogOut, MessageCircle, Mic, MicOff, MoreHorizontal, MoreVertical, Search, Send, Smile, X, Delete } from 'react-feather'
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import liveLogo from '../../NewComponents/IMG/BgRedLive.svg'
import MainGoLiveIcon from '../../NewComponents/IMG/MainGoLiveIcon.gif'
import PinIcon from '../../NewComponents/IMG/PinIcon.svg'
import Webcam from 'react-webcam'
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import './golive.css'
import { Offcanvas } from 'react-bootstrap'
import Picker from "emoji-picker-react";
import "./golive2.css";
import { Radio } from '@mui/material';


const CreateGoLiveDetail = () => {


  // { for the heart emoji icon}
  const [heartsReleased, setHeartsReleased] = useState(false);
  const [heartCount, setHeartCount] = useState(0);
  const [animationTimeout, setAnimationTimeout] = useState(null);




  // emojii start
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const [emojiIconStyle, setEmojiIconStyle] = useState({
    filter: 'brightness(0) invert(1)', // Initial style for black emoji
  });

  const handleInputFocus = () => {
    // Update the style to change the emoji color to black when the input is focused
    setEmojiIconStyle({ filter: 'none' });
  };

  const [unFollowOpen, setunFollowOpen] = useState(false)
  const toggleunFollow = () => {

    setunFollowOpen((preState) => !preState)

  }

  const handleInputBlur = () => {
    // Update the style to change the emoji color back to white when the input loses focus
    setEmojiIconStyle({ filter: 'brightness(0) invert(1)' });
  };


  const onEmojiClick = (emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    setInputStr(inputStr + emojiObject.emoji);
    setShowPicker(false);
  };
  // emoji end



  // count abbreviation function

  const [isBlinking,] = useState(true);

  const numFormatter = (num) => {
    // eslint-disable-next-line prefer-template
    if (num > 999 && num < 1000000) {
      return `${parseInt((num / 1000))}K`
    } else if (num >= 999999) {
      return `${parseInt((num / 1000000))}M`
    } else if (num <= 999) {
      return `${num}`
    }
  }

  /*-------------- useRef Conditions -----------------*/

  const commentBlk = useRef(null);
  const FullcommentSec = useRef(null);
  const ButtonHalf = useRef(null);
  const ButtonFull = useRef(null);
  const AddWidth = useRef(null);


  // added the height for the class 17vh
  const ClickFullCom = (e) => {
    commentBlk.current.classList.remove("d-none");
    ButtonFull.current.classList.add("d-none");
    ButtonHalf.current.classList.remove("d-none");
    FullcommentSec.current.style.height = "100vh";
    // FullcommentSec.current.classList.add("golive-fullcomment");
    AddWidth.current.classList.add("liveandclose-block-width-change");
  };
  const ClickHalfCom = (e) => {
    commentBlk.current.classList.add("d-none");
    ButtonFull.current.classList.remove("d-none");
    ButtonHalf.current.classList.add("d-none");
    // FullcommentSec.current.classList.remove("golive-fullcomment");
    FullcommentSec.current.style.height = "170px";
    AddWidth.current.classList.remove("liveandclose-block-width-change");
  };

  /*-------------- State with Functions -----------------*/

  const [isOff, setIsOff] = useState(true);
  const [reply, setreply] = useState(false);
  const [Pin, UnPin] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [viewerdropdownOpen, setviewerDropdownOpen] = useState(false)
  const toggleViewer = () => {
    setviewerDropdownOpen((prevState) => !prevState)
  }
  const [senddropdownOpen, setsendDropdownOpen] = useState(false)
  const toggleSender = () => {
    setsendDropdownOpen((prevState) => !prevState)
  }
  const [goliveOut, setGoliveOut] = useState(false)
  const toggleGoLiveBack = () => {
    setGoliveOut((prevState) => !prevState);
    setGoliveEnd(false); // Set goliveEnd to false when cancel is clicked
  };

  const [blockuser, setblockuser] = useState(false)
  const toggleblockuser = () => {
    setblockuser((prevState) => !prevState)
  }
  const [goliveend, setGoliveEnd] = useState(false)
  const toggleGoLiveEnd = () => {
    setGoliveOut(false)
    setGoliveEnd((prevState) => !prevState)
  }

  const [removeuser, setremoveuser] = useState(false)
  const togglesetremoveuser = () => {

    setremoveuser((prevState) => !prevState)
  }

  const [DeleteUser, deleteuser] = useState(false)
  const toggledeleteuser = () => {

    deleteuser((prevState) => !prevState)
  }

  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = () => {
    setReportOpen((preState) => !preState)
  }

  // pinned comment selection 
  const [pinnedComment, setPinnedComment] = useState(false)
  const Pineedclose = () => {
    setPinnedComment(!pinnedComment)
    setShow(false)
  }

  // style for cam

  const webcamForstyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: "50%",
    marginLeft: "-50%",
    objectFit: "cover",
    objectPosition: "center",
  };

  /*-------------- Chat Array -----------------*/

  const chatData = [
    {
      userImg: 'assets/images/story-3.jpg',
      userName: 'sufiya elijaa',
      time: '50 min ago',
      otherComment: 'Thank You So Much ❤❤',

    },
    {
      userImg: 'assets/images/story-3.jpg',
      userName: 'sufiya elijaa',
      time: '50 min ago',
      otherComment: 'Thank You So Much ❤❤',

    }
  ]

  /*-------------- Viewer Array -----------------*/

  const viewerData = [
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Following'
    }
  ]

  /*-------------- Report Label Array -----------------*/

  const ReportDataInfo = [
    {
      name: "Spam"
    },
    {
      name: "Nudity"
    },
    {
      name: "Violence"
    },
    {
      name: "Harrasment"
    },
    {
      name: "Suicide or self-injury"
    },
    {
      name: "Terrorism1"
    },
    {
      name: "Others"
    }
  ]

  /*-------------- Sender Modal Array -----------------*/

  const sendData = [
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    }
  ]


  /*-------------- Share Label Array -----------------*/


  const Sharedto = [
    {
      name: "Post to story"
    },
    {
      name: "Post to Feed"
    },
    {
      name: "Save to local storage"
    },
    {
      name: "Discard Media"
    }
  ]

  /*-------------- Modal for viewer -----------------*/


  const ViewerModal = () => {
    return (
      <>
        {viewerdropdownOpen ? (
          // <Modal isOpen={viewerdropdownOpen} className='' toggle={toggleViewer} >

          //   <ModalHeader className='d-flex justify-content-center'>
          //     <b>{numFormatter('9000')} People watching Live</b>
          //   </ModalHeader>
          //   <ModalBody>
          //     {
          //       viewerData.map((d, i) => {
          //         return (
          //           <div className='d-flex align-items-center justify-content-between m-3' key={i} >
          //             <div className='d-flex align-items-center'>
          //               <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
          //               <div className='ml-1'>
          //                 <h4>{d.userNames}</h4>
          //                 <p className='text-muted'>{d.joined}</p>
          //               </div>
          //             </div>
          //             <div className='w-25'>
          //               {d.typesof === 'Follow' ? <Button color='primary w-100'>Follow</Button>
          //                 : <Button color='primary w-100' disabled>Following</Button>}
          //             </div>
          //           </div>
          //         )
          //       })
          //     }
          //   </ModalBody>
          // </Modal> 
          <Modal isOpen={viewerdropdownOpen} className=' view' toggle={toggleViewer} style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)', borderRadius: '5px' }} >

            <ModalHeader className='d-flex justify-content-center'>
              <b>{numFormatter('9000')} People watching Live</b>
            </ModalHeader>
            <ModalBody>
              {
                viewerData.map((d, i) => {
                  return (
                    <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                      <div className='d-flex align-items-center'>
                        <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                        <div className='ml-1 watching spacing'>
                          <h4 className='usernamees'>{d.userNames}</h4>
                          <p className='text-muted'>{d.joined}</p>
                        </div>
                      </div>
                      {/* <div>
                      {d.typesof === 'Follow' ? <Button style={{ padding: '10px 30px 10px 30px' }} color='primary' ><Plus size={18} />Follow</Button>
                        : <Button color='primary' disabled><Check size={18} />Following</Button>}
                    </div> */}
                      <div>
                        {d.typesof === 'Follow' ? <Button style={{ padding: '10px 30px 10px 30px' }} color='primary' >Follow</Button>
                          : <Button color='primary' disabled>Following</Button>}
                      </div>
                    </div>
                  )
                })
              }
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }


  /// unfoloow modal

  const UnfollowModal = () => {
    return (
      <>
        {unFollowOpen ? (
          <Modal isOpen={unFollowOpen} className='modal-dialog-centered' toggle={toggleunFollow} >
            {/* <ModalHeader toggle={toggleViewer}>
            </ModalHeader> */}
            <ModalHeader className='d-flex justify-content-center'>
              <h4 >Do you really want Unfollow this User?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button onClick={toggleunFollow} color='secondary'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='danger' onClick={toggleunFollow} >UnFollow</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for SendList -----------------*/

  const SendModal = () => {

    // const [isSent, setIsSent] = useState(false);

    // const handleSendClick = () => {
    //   setIsSent(true)
    // };
    const [isSentList, setIsSentList] = useState(sendData.map(() => false));

    const handleSendClick = (index) => {
      const updatedIsSentList = [...isSentList];
      updatedIsSentList[index] = true;
      setIsSentList(updatedIsSentList);
    };

    return (
      <>
        {senddropdownOpen ? (
          <Modal isOpen={senddropdownOpen} className='modal-dialog-centered' toggle={toggleSender} >
            <ModalHeader className='d-flex justify-content-center'>
              <h2>Share live stream</h2>
            </ModalHeader>
            <ModalBody>
              <div className='d-flex align-items-center justify-content-between'>
                <InputGroup>
                  <InputGroupText className='bg-transparent'>
                    <Search size={16} />
                  </InputGroupText>
                  <Input type='search' placeholder='Search..' className="py-2" />
                </InputGroup>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color='primary' outline>
                    <Filter size={15} />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem > Family </DropdownItem>
                    <DropdownItem divider></DropdownItem>
                    <DropdownItem> Office </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>

              <div className="scrollForGolive mt-2">
                {
                  sendData.map((d, i) => {
                    return (
                      <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                        <div className='d-flex align-items-center'>
                          <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                          <div className='ml-1'>
                            <div className='position-relative'>
                              <h4>{d.userNames}</h4>
                              <span className="ShareOnlineUser"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                            </div>
                            <p className='text-muted'>{d.joined}</p>
                          </div>
                        </div>
                        <div className='w-25 '>
                          <Button
                            className='sendtext'
                            color='primary w-100'
                            onClick={() => handleSendClick(i)}
                            disabled={isSentList[i]} // Disable the button when it's already sent
                          >
                            {isSentList[i] ? 'Sent' : 'Send'}
                          </Button>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for Stop Golive -----------------*/

  const StopGoliveModal = () => {
    return (
      <>
        {goliveOut ? (
          <Modal isOpen={goliveOut} className='modal-dialog-centered' toggle={toggleGoLiveBack} >
            <ModalHeader className='d-flex justify-content-center'>
              <h4>Do you really want to end your live session?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center canbutton' >
                <Button outline color='secondary' onClick={toggleGoLiveBack}>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='primary' onClick={toggleGoLiveEnd} >Proceed</Button>
              </div>
              <div style={{
                display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#81C14B', position: 'relative', top: '-8px', marginTop: '45px'
              }}><p> Learn More...</p></div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }


  /*-------------- Modal for delete comment -----------------*/


  const DeleteModal = () => {
    return (
      <>
        {DeleteUser ? (
          <Modal isOpen={DeleteUser} className='modal-dialog-centered' toggle={toggledeleteuser} >
            <ModalHeader className='d-flex justify-content-center'>
              <h4>Do you really sure you want to delete this comment</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button outline color='secondary' onClick={toggledeleteuser}>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='primary' onClick={toggledeleteuser} >Delete</Button>
              </div>

            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for Remove User -----------------*/

  const RemoveModal = () => {
    return (
      <>
        {removeuser ? (
          <Modal isOpen={removeuser} className='modal-dialog-centered' toggle={togglesetremoveuser} >

            <ModalHeader className='d-flex justify-content-center'>
              <h4>Do you really want Remove this User?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button onClick={togglesetremoveuser} outline color='secondary'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='danger' onClick={togglesetremoveuser} >Yes, Remove</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for Block User -----------------*/

  const Blockmodal = () => {
    return (
      <>
        {blockuser ? (
          <Modal isOpen={blockuser} className='modal-dialog-centered' toggle={toggleblockuser} >
            <ModalHeader className='d-flex justify-content-center'>
              <h4>Do you really want to Block This User ?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button onClick={toggleblockuser} outline color='secondary'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='orange' onClick={toggleblockuser} >Block</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }




  /*-------------- Modal for Stop Live Session -----------------*/

  const StopEndedModal = () => {
    const [radioActive, setRadioActive] = useState(null)
    return (
      <>
        {goliveend ? (
          <Modal isOpen={goliveend} className='modal-dialog-centered' toggle={toggleGoLiveEnd} >
            <ModalHeader className='d-flex justify-content-center text-center'>
              <h3>Live Session ended</h3>
              <p className='text-muted'>12k viewers</p>
            </ModalHeader>
            <ModalBody style={{ top: '-10px' }}>
              {
                Sharedto.map((data, index) => (
                  // <div style={(Sharedto.length - 1) === index ? {} : { borderBottom: '1px solid rgba(0, 0, 0, 0.10)' }} className=''>
                  <div
                    key={index}
                    className='d-flex justify-content-between my-4 Report_Section reposect'
                  >
                    <h4
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                      }}
                    >
                      {data.name}
                    </h4>
                    <div>
                      <Radio
                        inputProps={{ 'aria-label': 'A' }}
                        name="radio-buttonsOne"
                        id="radio-buttonsOneId"
                        className='Radio_btn_check'
                        checked={radioActive === index}
                        onClick={() => setRadioActive((prevIndex) => (prevIndex === index ? null : index))}
                      />
                      {' '}
                    </div>
                  </div>
                  // </div>

                ))
              }

              <div className='text-center'>
                <Button onClick={toggleGoLiveEnd} color='secondary' outline className='px-4'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button tag='a' href='/CreateGoLive' color='primary' className='px-5'>Ok</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  /*-------------- Modal for Report User -----------------*/

  const ReportModal = () => {
    const [radioActive, setRadioActive] = useState(null)
    return (
      <>
        <div>
          {
            reportOpen !== false &&
            <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
              <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                <h3>Report</h3>
              </ModalHeader>
              <ModalBody>
                <div className='Modal_Body_Section'>
                  <h4>Tell us about what you feel and help us to make Sociomee more secure.</h4>
                </div>
                <br />
                {
                  ReportDataInfo.map((data, index) => (
                    <div
                      key={index}
                      className='d-flex justify-content-between Report_Section'
                    >
                      <h5
                        className='d-flex reort'
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => {
                          setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                        }}
                      >
                        {data.name}
                      </h5>
                      <div>
                        <Radio
                          inputProps={{ 'aria-label': 'A' }}
                          name="radio-buttonsOne"

                          className='Radio_btn_check'
                          checked={radioActive === index}
                          onClick={() => { setRadioActive((prevIndex) => (prevIndex === index ? null : index)); }}
                        />
                        {' '}
                      </div>
                    </div>
                  ))
                }
                <div className='d-flex justify-content-center mt-3'>
                  <Input
                    id="ReportName"
                    name="ReportName"
                    placeholder="Write a comment..."
                    type="text
                    "
                  />
                </div>
                <hr className='w-100 text-muted' />
                <div className='text-center'>
                  <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                  <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                </div>
              </ModalBody>

            </Modal>

          }

        </div>
      </>
    )
  }

  const MobileHeader = () => {
    const [isBlinking,] = useState(true);
    return (
      <div className="rem-web-view">
        <div className='p-2 mobheaderstyle  mobheaderstyles'>
          <p onClick={toggleGoLiveBack} > <X /> <span>End</span></p>
          <div>
            <h5 className='p-1'>#ENTERTAINMENT</h5>

            <h6 className='p-1 fs-3 fw-bold'>00:05 <span className={`p-1   bg-danger text-white roundeds py-1 px-2  podliveblinkes ${isBlinking ? 'blinkab' : ''}`}>Live</span></h6>

            <h6 onClick={toggleViewer} className='p-1'><Eye size={18} /><span> 128k watching</span></h6>
          </div>
        </div>
      </div>
    )
  }

  const MobileRightSidebar = () => {



    const handleHeartClick = () => {
      setHeartsReleased(true);
      setHeartCount(heartCount + 1);
      clearTimeout(animationTimeout); // Clear any existing timeout
      const timeout = setTimeout(() => {
        setHeartsReleased(false);
      }, 1000);
      setAnimationTimeout(timeout);
    }



    return (
      <div className="rem-web-view postitionForleftsidebar">
        <div className=' d-flex flex-column text-white align-items-center '>
          <div className='d-flex flex-column align-items-center  mobileheart' >
            <Heart className={`heart-button ${heartsReleased ? 'released' : ''}`} strokeWidth={1} size={28} fill='#FF5B58' onClick={handleHeartClick} />
            {heartsReleased && (

              <div className="heart-explosion">
                {Array.from({ length: 5 }, (_, index) => (
                  <span key={index} className="heart-emoji">


                    💕
                  </span>
                ))}

              </div>
            )}
            <p className='hrtcnt'>{heartCount}</p>

          </div>
          <span className='user-select-none' onClick={() => setIsOff(!isOff)}> {isOff ? <Mic className='ml-1' size={24} /> : <MicOff className='ml-1' size={24} />}</span>
          <div className='d-flex flex-column align-items-center'>
            <Send style={{ cursor: 'pointer' }} onClick={toggleSender} className='mx-2' size={24} />
            <p>18</p>
          </div>
          <UncontrolledButtonDropdown direction='up' className=''>
            <DropdownToggle color='flat-primary' className='p-0 m-0'>
              <MoreVertical className='ml-1' color='#ffffff' size={24} />
            </DropdownToggle>
            <DropdownMenu className='goliveFordrop' >
              <DropdownItem> <MessageCircle color='#4D4D4D' size={20} /> Disable Chat</DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem> <Copy color='#4D4D4D' size={20} /> Copy Link</DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem tag='a'> <Bell color='#4D4D4D' size={20} />Turn on notifications </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </div>
    )
  }

  const handleHeartClick = () => {
    setHeartsReleased(true);
    setHeartCount(heartCount + 1);
    // Clear any existing timeout
    const timeout = setTimeout(() => {
      setHeartsReleased(false);
    }, 1000);
    setAnimationTimeout(timeout);
  }

  return (
    <>

      {/*---------------------------------- Above created Modals calls ---------------------------------- */}

      <ViewerModal />
      <SendModal />
      <StopGoliveModal />
      <StopEndedModal />
      <ReportModal />
      <Blockmodal />
      <UnfollowModal />
      <RemoveModal />
      <DeleteModal />
      <div className='golivemainForbackground  '>
        <MobileHeader />
        {/*---------------------------------- Third Party Component For Camera---------------------------------- */}

        <Webcam
          mirrored={true}
          style={webcamForstyle}
        />
        <div className='goliveForheader rem-mob-view '>

          {/*---------------------------------- TOP HEADER---------------------------------- */}

          <div className='d-flex justify-content-start align-items-center p-3'>
            <img src={MainGoLiveIcon} width={100} alt='Live' className='positionIconForMob bg-transparent' />
            {/* <img width={20} src={LiveGif} alt="LiveGif1" className='positionIconForMob' /> */}
            <div className='mx-3 positionWatchingForMob rem-mob-view' style={{ cursor: 'pointer' }}>
              <UncontrolledButtonDropdown direction='up' className=''>
                <DropdownToggle color='flat' className='p-0 m-0'>
                  <h4 className='text-white'>
                    <Eye size={24} />
                    <span> {numFormatter('9000')}</span>
                    &nbsp;
                    Watching
                  </h4>
                </DropdownToggle>
                <DropdownMenu className='py-0' style={{
                  minWidth: '500px',
                  borderRadius: '5px',
                  background: '#FFF',
                  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)',
                }} >
                  <div className='bg-light text-center rounded p-2'>
                    <h3>{numFormatter('9000')} People watching Live</h3>
                  </div>
                  <div>
                    {
                      viewerData.map((d, i) => {
                        return (
                          <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                            <div className='d-flex align-items-center'>
                              <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                              <div className='ml-1 spacing'>
                                <h4 className='text-body usernamees'>{d.userNames}</h4>
                                <p className='text-muted'>{d.joined}</p>
                              </div>
                            </div>
                            <div className='w-25'>
                              {d.typesof === 'Follow' ? <Button color='primary' className='w-100'>Follow</Button>
                                : <Button color='primary' className='w-100' outline >Following</Button>}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>

                </DropdownMenu>
              </UncontrolledButtonDropdown>

            </div>
            <h4 className='positionCategoryForMob text-white'>#SelectedCategory</h4>
          </div>
        </div>

        {/*---------------------------------- MOB SIDEBAR---------------------------------- */}

        <MobileRightSidebar />
        <div className='goliveForcampostition bg-transparent px-3'>
          <Row className="rowForrevert">
            <Col xl='12'>

              {/*-------------------------------------------------- Bottom Options-------------------------------------------------- */}

              <div>
                <Row className="rowForrevert">
                  <Col xl='12'>
                    <div className='d-flex justify-content-between align-items-center h-100 pb-3 rem-mob-view'>
                      <div className='text-white m-2 micsend'>
                        <span className='p-2 rounded positionTimerForMob' style={{ marginRight: '10px' }}>00:03</span>
                        <span className='user-select-none positionMicForMob rem-mob-view' onClick={() => setIsOff(!isOff)}> {isOff ? <Mic className='ml-2' size={24} style={{ marginRight: '10px' }} /> : <MicOff className='ml-2' size={24} style={{ marginRight: '10px' }} />}</span>
                        <Send style={{ cursor: 'pointer', marginRight: '10px' }} onClick={toggleSender} className='mx-2 positionSendForMob rem-mob-view' size={24} />
                        <UncontrolledButtonDropdown direction='up' className='positionMoreviewForMob'>
                          <DropdownToggle color='flat-primary' className='p-0 m-0'>
                            <MoreHorizontal className='ml-2 rotateIconMoreview' color='#ffffff' size={24} />
                          </DropdownToggle>
                          <DropdownMenu className='goliveFordrop' >
                            <DropdownItem > <MessageCircle color='#4D4D4D' size={20} /> Disable Chat</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem > <Copy color='#4D4D4D' size={20} /> Copy Link</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem > <Bell color='#4D4D4D' size={20} />Turn on notifications </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>

                      </div>

                      <div onClick={toggleGoLiveBack} className="wglpausecircle postitionStopForButton"><span></span></div>

                      {/* <div className='wglpausecircleForposition rem-mob-view text-white'>
                        <Heart className='positionLikeForMob' strokeWidth={1} size={28} fill='#FF5B58' />
                        
                        
                         
                      </div> */}
                      <div className='heartplacing'>
                        <div className="heart-animation-app1">
                          <div className='d-flex flex-column align-items-center' >
                            <Heart className={`heart-button ${heartsReleased ? 'released' : ''}`} strokeWidth={1} size={28} fill='#FF5B58' onClick={handleHeartClick} />
                            {heartsReleased && (
                              <div className="heart-explosion1">
                                {Array.from({ length: 5 }, (_, index) => (
                                  <span key={index} className="heart-emojii">
                                    💕
                                  </span>
                                ))}

                              </div>
                            )}
                            <p style={{ position: 'relative', left: '24px', color: "white", top: "-4px" }}>{heartCount}</p>


                          </div>
                        </div>
                      </div>



                    </div>

                    {/*----------------------------------- Comment Sidebar----------------------------------- */}

                    <div className="golive-comment-block commentSectionForpostition" ref={FullcommentSec}>
                      <a className="golive-comment-updown-button d-none" onClick={ClickHalfCom} ref={ButtonHalf}>
                        <ChevronsDown />
                      </a>
                      <a className="golive-comment-updown-button" id='test' onClick={ClickFullCom} ref={ButtonFull}>
                        <ChevronsUp />
                      </a>
                      <div className="goliveuser-blk headerFormain">
                        <img src="assets/images/story-2.jpg" />
                        <span className="HeaderactiveForGoLiveIcon"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                        <div className="goliveuser-cont-blk">
                          <h4>Paige Turner</h4>
                          <p>Alamba, USA</p>
                        </div>
                      </div>

                      <div className="golive-user-comment-blk d-none" ref={commentBlk}>
                        <div className="gl-com-like-share-blk">
                          <div className="gl-heartlist"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="#FF5B96" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="#FF5B96" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> <span>+75</span></div>
                          <div className="comment-share-goliveblk">
                            <span><b>4565</b> Comment</span>
                            {/* <span><b>985</b> Share</span> */}
                          </div>
                        </div>

                        <div className="comment-section golive-comment-section">
                          <div className="comments d-block">
                            <div className="main-comment">
                              <div className="media">
                                <a className="user-img popover-cls"
                                  data-bs-toggle="popover" data-placement="right"
                                  data-name="Pabelo mukrani"
                                  data-img="assets/images/story-2.jpg">
                                  <img src="assets/images/story-2.jpg" className="img-fluid bg-img" alt="user" />
                                </a>
                                <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                                <div className="media-body">
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <a className='d-flex'>
                                      <h5>Pabelo Mukrani</h5>
                                      {
                                        Pin ?
                                          <img src={PinIcon} className='ml-2' alt='Pinicon' /> : null
                                      }
                                    </a>
                                    <UncontrolledButtonDropdown direction='left'>
                                      <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                        <MoreHorizontal size={22} color='#fff' />
                                      </DropdownToggle>
                                      <DropdownMenu className='goliveFordrop' style={{ zIndex: '100', left: '-210px' }}>
                                        <DropdownItem onClick={() => UnPin(!Pin)} tag='a'>
                                          {
                                            Pin ?
                                              <>
                                                <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                </svg>&nbsp;&nbsp; UnPin comment
                                              </> :
                                              <>
                                                <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                </svg>
                                                &nbsp;&nbsp;Pin comment
                                              </>
                                          }
                                        </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggleReport} tag='a'> <AlertTriangle size={20} />&nbsp;&nbsp; Report comment</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={togglesetremoveuser} tag='a'> <LogIn size={20} /> &nbsp;&nbsp;Remove from Go Live </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp;&nbsp; Block </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} />&nbsp;&nbsp; Unfollow </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggledeleteuser} tag='a'> <Delete size={20} />&nbsp;&nbsp;Delete </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                  </div>
                                  <div className="comment-time">
                                    <h6>50 mins ago</h6>
                                  </div>
                                  <p>Oooo Very Cute and Sweet Dog, happy birthday
                                    Cuty.... &#128578;
                                  </p>
                                  <ul className="comment-option">
                                    <li><a>like (5)</a></li>
                                    <li><a onClick={() => setreply(!reply)}>reply (2)</a></li>
                                  </ul>
                                </div>
                              </div>
                              {
                                reply ?
                                  <div className="sub-comment">
                                    {
                                      chatData.map((item, index) => {
                                        return (
                                          <div className="media" key={index}>
                                            <a className="user-img popover-cls"
                                              data-bs-toggle="popover" data-placement="right"
                                              data-name="sufiya eliza"
                                              data-img="assets/images/story-4.jpg">
                                              <img src={item.userImg}
                                                className="img-fluid bg-img"
                                                alt="user" />
                                            </a>
                                            <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                                            <div className="media-body">

                                              <div className='d-flex justify-content-between align-items-center'>

                                                <a className='d-flex'>
                                                  <h5>{item.userName}</h5>
                                                  {
                                                    Pin ?
                                                      <img src={PinIcon} className='ml-2' alt='Pinicon' /> : null
                                                  }
                                                </a>
                                                <UncontrolledButtonDropdown direction='left'>
                                                  <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                    <MoreHorizontal size={22} color='#fff' />
                                                  </DropdownToggle>
                                                  <DropdownMenu className='goliveFordrop' style={{ zIndex: '100', left: '-210px' }}>
                                                    <DropdownItem onClick={() => UnPin(!Pin)} tag='a'>
                                                      {
                                                        Pin ?
                                                          <>
                                                            <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                            </svg> &nbsp;&nbsp;UnPin comment
                                                          </> :
                                                          <>
                                                            <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                            </svg>
                                                            &nbsp;&nbsp; Pin comment
                                                          </>
                                                      }
                                                    </DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem onClick={toggleReport} tag='a'> <AlertTriangle size={20} />&nbsp;&nbsp; Report comment</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem onClick={togglesetremoveuser} tag='a'> <LogIn size={20} /> &nbsp;&nbsp;Remove from Go Live </DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp;&nbsp; Block </DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} /> &nbsp;&nbsp;Unfollow </DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem onClick={toggledeleteuser} tag='a'> <Delete size={20} />&nbsp;&nbsp; Delete </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                              </div>
                                              <div className="comment-time">
                                                <h6>{item.time}</h6>
                                              </div>
                                              <p>{item.otherComment}</p>
                                              <ul className="comment-option">
                                                <li><a>like</a></li>
                                                <li><a>reply</a></li>
                                              </ul>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div> : null
                              }

                            </div>
                            <div className="main-comment">
                              <div className="media">
                                <a className="user-img popover-cls"
                                  data-bs-toggle="popover" data-placement="right"
                                  data-name="pabelo mukrani"
                                  data-img="assets/images/story-2.jpg">
                                  <img src="assets/images/story-2.jpg"
                                    className="img-fluid bg-img"
                                    alt="user" />
                                </a>
                                <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                                <div className="media-body">
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <a className='d-flex'>
                                      <h5>Pabelo Mukrani</h5>
                                      {
                                        Pin ?
                                          <img src={PinIcon} className='ml-2' alt='Pinicon' /> : null
                                      }
                                    </a>
                                    <UncontrolledButtonDropdown direction='left'>
                                      <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                        <MoreHorizontal size={22} color='#fff' />
                                      </DropdownToggle>
                                      <DropdownMenu className='goliveFordrop' style={{ zIndex: '100', left: '-210px' }}>
                                        <DropdownItem onClick={() => UnPin(!Pin)} tag='a'>
                                          {
                                            Pin ?
                                              <>
                                                <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                </svg>&nbsp;&nbsp; UnPin comment
                                              </> :
                                              <>
                                                <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                </svg>
                                                &nbsp;&nbsp;Pin comment
                                              </>
                                          }
                                        </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggleReport} tag='a'> <AlertTriangle size={20} />&nbsp;&nbsp; Report comment</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={togglesetremoveuser} tag='a'> <LogIn size={20} /> &nbsp;&nbsp;Remove from Go Live </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp;&nbsp; Block </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} />&nbsp;&nbsp;Unfollow </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={toggledeleteuser} tag='a'> <Delete size={20} />&nbsp;&nbsp; Delete </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                  </div>
                                  <div className="comment-time">
                                    <h6>50 mins ago</h6>
                                  </div>
                                  <p>It’s party time, Sufiya..... and happy birthday
                                    cuty 🎉🎊
                                  </p>
                                  <ul className="comment-option">
                                    <li><a>like</a></li>
                                    <li><a>reply</a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="golive-commentinput-block">




                        <Input type='text' className='InputForcolor' placeholder='Comment....' value={inputStr}
                          onChange={(e) => setInputStr(e.target.value)} style={{ '--placeholder-color': '#ffffff' }} />
                        <div className='d-flex text-muted commentInputForposition'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowPicker((val) => !val)} className='smileemoji'>
                            <g clip-path="url(#clip0_24016_22635)">
                              <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM14.8 13.857C14.8933 13.7629 15.0044 13.6882 15.1268 13.6374C15.2492 13.5866 15.3804 13.5606 15.513 13.561C15.6455 13.5614 15.7766 13.5881 15.8987 13.6396C16.0208 13.6911 16.1315 13.7663 16.2243 13.8609C16.317 13.9556 16.3901 14.0677 16.4391 14.1908C16.4882 14.3139 16.5123 14.4455 16.5101 14.578C16.5078 14.7105 16.4792 14.8413 16.426 14.9626C16.3728 15.084 16.2959 15.1936 16.2 15.285C15.0794 16.3858 13.5708 17.0018 12 17C10.4292 17.0018 8.92064 16.3858 7.8 15.285C7.61503 15.0984 7.51093 14.8465 7.5102 14.5838C7.50948 14.321 7.61219 14.0685 7.79613 13.8809C7.98006 13.6933 8.23045 13.5856 8.49316 13.5811C8.75586 13.5767 9.00977 13.6758 9.2 13.857C9.94682 14.5912 10.9527 15.0018 12 15C13.09 15 14.077 14.565 14.8 13.857ZM8.5 8C8.89782 8 9.27936 8.15804 9.56066 8.43934C9.84196 8.72064 10 9.10218 10 9.5C10 9.89782 9.84196 10.2794 9.56066 10.5607C9.27936 10.842 8.89782 11 8.5 11C8.10218 11 7.72064 10.842 7.43934 10.5607C7.15804 10.2794 7 9.89782 7 9.5C7 9.10218 7.15804 8.72064 7.43934 8.43934C7.72064 8.15804 8.10218 8 8.5 8ZM15.5 8C15.8978 8 16.2794 8.15804 16.5607 8.43934C16.842 8.72064 17 9.10218 17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11C15.1022 11 14.7206 10.842 14.4393 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10218 14.158 8.72064 14.4393 8.43934C14.7206 8.15804 15.1022 8 15.5 8Z" fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_24016_22635">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {showPicker && (
                            <Picker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
                          )}

                          {/* <Send className='iconForcolor' /> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='sendicon1'>
                            <path d="M5.5093 12.1662H21.0657M5.5093 12.1662L3.03443 19.5908C2.99775 19.6895 2.99019 19.7967 3.01265 19.8996C3.0351 20.0025 3.08662 20.0968 3.16109 20.1712C3.23556 20.2457 3.32985 20.2972 3.43274 20.3197C3.53563 20.3421 3.64281 20.3346 3.74154 20.2979L21.0657 12.1662M5.5093 12.1662L3.03443 4.74153C2.99775 4.64281 2.99019 4.53563 3.01265 4.43274C3.0351 4.32985 3.08662 4.23556 3.16109 4.16109C3.23556 4.08662 3.32985 4.0351 3.43274 4.01265C3.53563 3.99019 3.64281 3.99775 3.74153 4.03443L21.0657 12.1662" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="inputboxForposition rem-web-view">
                      <div className='MobForChat' style={{ bottom: pinnedComment ? "90px" : "39px" }}>
                        <div class="gradient-blur">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        {[...Array(8)].map((index) => (
                          <div key={index} className={`p-1 px-2 d-flex comimgsss`}>
                            <img src="assets/images/story-2.jpg" width={25} height={25} className='rounded-circle mobile' />
                            <div onClick={handleShow} className='rounded-pill text-white chatForpopup'>
                              <div className='d-flex align-items-center'>
                                <h4 className='text-white' style={{ fontWeight: 'bold',fontSize:"13px" }}>Sufiya elija</h4><h6 className='pl-1'>01:00</h6>
                              </div>
                              <small style={{fontSize:"13px"}}>How are you ?</small>
                            </div>
                          </div>
                        ))}
                        <div className={`p-1 px-2 d-flex mobPinnedChat bg-transparent  ${pinnedComment ? "" : " d-none"}`}>
                          <img src="assets/images/story-2.jpg" width={25} height={25} className='rounded-circle' />
                          <div onClick={handleShow} className='rounded-pill text-white chatForpopup'>
                            <div className='d-flex align-items-center'>
                              <h4 className='text-white'>Sufiya elija</h4><h6 className='pl-1'>01:00 <img src={PinIcon} alt="pinedIcon" /></h6>
                            </div>
                            <small><span className='text-primary font-italic'>@Taha sir</span> joined</small>

                          </div>
                        </div>
                      </div>
                      <div className="golive-commentinput-block">
                        <Input type='text' className='InputForcolor1' placeholder='Comment....' value={inputStr} onChange={(e) => setInputStr(e.target.value)}
                        // onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}


                        />
                        <div className='d-flex  commentInputForposition'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowPicker((val) => !val)}>
                            <g clip-path="url(#clip0_24016_22635)">
                              <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM14.8 13.857C14.8933 13.7629 15.0044 13.6882 15.1268 13.6374C15.2492 13.5866 15.3804 13.5606 15.513 13.561C15.6455 13.5614 15.7766 13.5881 15.8987 13.6396C16.0208 13.6911 16.1315 13.7663 16.2243 13.8609C16.317 13.9556 16.3901 14.0677 16.4391 14.1908C16.4882 14.3139 16.5123 14.4455 16.5101 14.578C16.5078 14.7105 16.4792 14.8413 16.426 14.9626C16.3728 15.084 16.2959 15.1936 16.2 15.285C15.0794 16.3858 13.5708 17.0018 12 17C10.4292 17.0018 8.92064 16.3858 7.8 15.285C7.61503 15.0984 7.51093 14.8465 7.5102 14.5838C7.50948 14.321 7.61219 14.0685 7.79613 13.8809C7.98006 13.6933 8.23045 13.5856 8.49316 13.5811C8.75586 13.5767 9.00977 13.6758 9.2 13.857C9.94682 14.5912 10.9527 15.0018 12 15C13.09 15 14.077 14.565 14.8 13.857ZM8.5 8C8.89782 8 9.27936 8.15804 9.56066 8.43934C9.84196 8.72064 10 9.10218 10 9.5C10 9.89782 9.84196 10.2794 9.56066 10.5607C9.27936 10.842 8.89782 11 8.5 11C8.10218 11 7.72064 10.842 7.43934 10.5607C7.15804 10.2794 7 9.89782 7 9.5C7 9.10218 7.15804 8.72064 7.43934 8.43934C7.72064 8.15804 8.10218 8 8.5 8ZM15.5 8C15.8978 8 16.2794 8.15804 16.5607 8.43934C16.842 8.72064 17 9.10218 17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11C15.1022 11 14.7206 10.842 14.4393 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10218 14.158 8.72064 14.4393 8.43934C14.7206 8.15804 15.1022 8 15.5 8Z" fill="white" />
                            </g>
                            <defs>
                              <clipPath id="clip0_24016_22635">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {showPicker && (
                            <Picker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
                          )}

                          {/* <Send className='iconForcolor' /> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M5.5093 12.1662H21.0657M5.5093 12.1662L3.03443 19.5908C2.99775 19.6895 2.99019 19.7967 3.01265 19.8996C3.0351 20.0025 3.08662 20.0968 3.16109 20.1712C3.23556 20.2457 3.32985 20.2972 3.43274 20.3197C3.53563 20.3421 3.64281 20.3346 3.74154 20.2979L21.0657 12.1662M5.5093 12.1662L3.03443 4.74153C2.99775 4.64281 2.99019 4.53563 3.01265 4.43274C3.0351 4.32985 3.08662 4.23556 3.16109 4.16109C3.23556 4.08662 3.32985 4.0351 3.43274 4.01265C3.53563 3.99019 3.64281 3.99775 3.74153 4.03443L21.0657 12.1662" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </div>
                      </div>
                    </div>

                    <Offcanvas placement='bottom' show={show} onHide={handleClose} className='canvas1 '>

                      <Offcanvas.Header >

                      </Offcanvas.Header>
                      <Offcanvas.Body className='p-0 canvas2'>
                        <ul style={{ fontFamily: "Poppins", fontWeight: '400', fontSize: '14px', marginBottom: '7px' }} className='canvas'>
                          <li onClick={Pineedclose} className='text-muted  border-top-0 py-11'>&nbsp;&nbsp;{pinnedComment ? 'Unpin Comment' : "Pin Comment"}</li>
                          <li className='text-muted  border-top-0 py-11' onClick={toggleReport}>&nbsp;&nbsp;Report comment</li>

                          <li className='text-muted border-top-0 py-11' onClick={togglesetremoveuser}>&nbsp;&nbsp;Remove from GoLive </li>
                          <li className='text-muted py-11 border-top-0' onClick={toggleblockuser} > &nbsp;&nbsp;Block </li>
                          <li className='text-muted  border-top-0 py-11' onClick={toggleunFollow}>&nbsp;&nbsp;Unfollow </li>
                          <li className='text-muted py-11 border-top-0' onClick={togglesetremoveuser}>&nbsp;&nbsp;Reply to user</li>



                        </ul>
                      </Offcanvas.Body>
                    </Offcanvas>

                  </Col>
                </Row>
              </div>
            </Col>
          </Row >
        </div >
      </div >
    </>
  )
}

export default CreateGoLiveDetail