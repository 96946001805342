import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MessageCircle, MoreVertical, Music, Send, Share, Share2 } from 'react-feather'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import MediaModal from '../../../components/profile-components/MediaModal'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ShotzModal from '../../Shotzs/shotz_components/ShotzModal'
import ShareToModal from '../ShareToModal'
import ReportModal from './ModalGrid/ReportModal'
import { topAudioShotzdata } from '../DummyArray'
import { NavLink } from 'react-router-dom'
import { ShotzCarouselComponents } from '../../Shotzs/shotz_components/ShotzCarouselComponents'
import { useUserAuth } from '../../../Context/userAuthContext'

const AudioShotz = () => {
    const [shareOpen, setShareOpen] = useState(false)
    //----------------- shotzCarousel Modal open -----------------
    // const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
    {/*------------------------------------------------------------------------- Shotz carousel state called from context---------------------------------------------------------------------- */ }
    const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();
    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    


    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }


    {/* -------------------------------------------------Main return function------------------------------------------------------------------------- */ }
    return (
        <>
            <Row className='p-2'>

                {/* -------------------------------------------------Audio Shotz Card data dynamic rendering ------------------------------------------------------------------------- */}
                {
                    topAudioShotzdata.map((data, index) => {
                        return (
                            <Col xl='3' className='p-2' key={index}>
                                <div className='border roundedThisBorder'>
                                    <div className="media-images-blk shotz-album-block  align-items-center ">
                                        <div className="main-shotz-blk-list">
                                            <figure className="shotz-card-hover m-0">
                                                <div className='image-with-gradient' ></div>
                                                <img className="gallery-img img-gradient rounded" src={data.gridImg} alt="Shotz" onClick={toggleShotzCarouselModal} />

                                                <div className="text-gallery d-flex justify-content-center">
                                                    <div className="top-gallery-cont align-items-center">
                                                        <h5 className='text-white'><Eye color='#FFFFFF' size={20} /> {numFormatter(data.num)}</h5>
                                                        <div className=''>
                                                            {/* <UncontrolledButtonDropdown className=''>
                                                                <DropdownToggle color='flat' >
                                                                    <MoreVertical color='white' className='' size={20} />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu tops-dropdown width-for-dropdown'>
                                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}>
                                                                        <h5><Share size={13} />&nbsp;&nbsp;Share post</h5>
                                                                    </DropdownItem>
                                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                                                        <h5> <NavLink to='/MyProfile' style={{color:'#212529'}} ><Eye size={13} />&nbsp;&nbsp;View creator's profile</NavLink></h5>
                                                                    </DropdownItem>
                                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                                        <h5><Link size={13} />&nbsp;&nbsp;Copy link</h5>
                                                                    </DropdownItem>
                                                                    <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                                        <h5><AlertTriangle size={13} />&nbsp;&nbsp;Report</h5>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown> */}

                                                            <UncontrolledButtonDropdown className='align-self-center' >
                                                                <DropdownToggle className='p-0 m-0' color='' >
                                                                    <MoreVertical size={19} color='white' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share post</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View creator's profile </NavLink></DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                    </div>
                                                    <div className="center-gallery-cont">
                                                        <div onClick={toggleShotzCarouselModal} role='button'>
                                                            <img src="assets/images/shotz-icon.png" className='shotzModel' alt='ShotzIcon' />
                                                        </div>
                                                    </div>
                                                    <div className="Music-gallery-cont mx-2 py-3">
                                                        <p className='shotz-text'><Music size={20} /></p>&nbsp;&nbsp;
                                                        <h5 className='text-white mr-2 marquee'>{data.songs}</h5>
                                                    </div>
                                                    <div className="bottom-gallery-cont align-items-center">
                                                        <div className='shotz-text'><img src={LikeBlack} height={18} alt='like' /> {numFormatter(data.num1)}</div>
                                                        <div className='shotz-text' data-bs-toggle="modal" data-bs-target="#imageModel"><MessageCircle size={18} /> {numFormatter(data.num2)}</div>
                                                        <div className='shotz-text' onClick={toggleForward}><Send size={18} />  {numFormatter(data.num3)}</div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>

            {/* As per current module we dont nee this */}

            {/* <Row> */}
            {/* ------------- Pagination ------------- */}
            {/* <Col xl='12' className='d-flex justify-content-center py-4'>
                    <Stack spacing={2}>
                        <Pagination
                            count={10}
                            color='primary'
                            size="large" />
                    </Stack>
                </Col> */}
            {/* </Row> */}
            {/* -------------------------------------------------------Media modal-------------------------------------------------- */}
            <MediaModal />


            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Review Report modal end Here------------------------------------------------------------- */}

            {/* -------------------------------------------------------Share To Modal start---------------------------------------------------------------------- */}
            <ShareToModal
                forwardOpen={forwardOpen}
                toggleForward={toggleForward}
            />
            {/* -------------------------------------------------------Share To Modal end---------------------------------------------------------------------- */}


            {/* ------------- Start Shotz Carousel Modal ------------- */}
            {/* <ShotzModal
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            /> */}
            <ShotzCarouselComponents
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            />
            {/* ------------- End Shotz Carousel Modal ------------- */}
        </>
    )
}

export default AudioShotz