import axios from "axios";

import * as types from "../../Constants/Common/index";

const getAllFeelingCategory = (feelingCategory) => ({
  type: types.GET_FEELING_CATEGORY,
  payload: feelingCategory,
});

export const loadAllFeelingCategory = () => {
  return async (dispatch) => {
    try {
    
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      if (user) {
        // const response=""
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/admin/getFeelingCategories`,{},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        dispatch(getAllFeelingCategory(response.data.data.successResult.rows));
      }
    } catch (error) {
      console.error("Error fetching Feeling Categories :", error);
    }
  };
};
