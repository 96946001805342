import React, { Fragment } from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import BizMenu from './BizMenu';
import Prfle from '../profile-components/Img/prfle.png'
import { useState } from 'react';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import MyOwnedBiz from './Components/MyOwnedBiz';
import MyFollowedBiz from './Components/MyFollowedBiz';
import MySuggestedBiz from './Components/MySuggestedBiz';


// --------------------------main code----------------------//
const Biz = () => {
    const [followersOpen, setFollowersOpen] = useState(false)
    const toggleFollowers = () => {
        setFollowersOpen((preState) => !preState)
    }
    const PeopleDataList = [
        {
            img: Prfle,
            name: "Marvin McKinney",
            joingData: "Following since 2 Days",
            btnType: 'SocioMate',
            id: "dianne-russell123",
        },
        {
            img: Prfle,
            name: "Kenjomen Norke",
            joingData: "Following since 2 Days",
            btnType: 'SocioMate ',
            id: "dianne-russell123",
            btnType1: "Following",
        },
        {
            img: Prfle,
            name: "Marvin McKinney",
            joingData: "Following since 2 Days",
            btnType: 'Following ',
            id: "dianne-russell123",
        },
        {
            img: Prfle,
            name: "Kenjomen Norke",
            joingData: "Following since 2 Days",
            btnType: 'Following',
            id: "dianne-russell123",
        },
        {
            img: Prfle,
            name: "Marvin McKinney",
            joingData: "Following since 2 Days",
            btnType: 'SocioMate ',
            id: "dianne-russell123",
        }
    ]
    //---------------Main_Return_function-------------------///
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center bizpagecentre">
                    <BizMenu />
                    <MyOwnedBiz />
                    <MyFollowedBiz />
                    <MySuggestedBiz />
                </div>
                <RightSidebar />
            </div>
            <div className='Following_Modal_section'>
                {
                    followersOpen !== false &&
                    <Modal scrollable isOpen={followersOpen} toggle={toggleFollowers} className='modal-dialog-centered '>
                        <ModalBody>
                            <div>
                                <h2>Follower (367)</h2>
                                <p>Awesome! You have 367 Followers</p>
                            </div>
                            <div className="py-4">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search....." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <Row className="mt-2">
                                <div className="group-detail-page-blk ">
                                    <Row>
                                        {
                                            PeopleDataList.map((data) => (
                                                <Col md="12" sm="12">
                                                    <div className="Follower-memberslist-block">
                                                        <div className="group-member-img-cont-blk">
                                                            <img src={data.img} alt="img" className='img-fluid' />
                                                            <div className="member-cont-blk">
                                                                <h4>{data.name}</h4>
                                                                <p>{data.id}</p>
                                                                <p>{data.joingData}</p>
                                                            </div>
                                                        </div>
                                                        <div className='Block-btns-blk'>
                                                            <Button outline color='primary'>Followers</Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default Biz