import React, { useState } from 'react'
import { MoreHorizontal, MoreVertical, Sliders } from 'react-feather'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import UserImg from '../../../NewComponents/IMG/img.svg'


const Connections = () => {

    /**        States and functions       * */

    const [centeredModal, setCenteredModal] = useState(false)

    const action = (a) => {
        setCenteredModal(!centeredModal)
    }

    /**        Connections Array         * */

    const connections = [
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
        {
            fullName: 'Brooklyn Simmons',
            userName: 'Mack Mickel Bel '
        },
    ]

    /**       Unblock Modal         * */

    const UnblockModal = () => {
        return <>{
            centeredModal === 'a' ? <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered text-center'>
                <div className='py-3 rounded bg-light border-bottom' toggle={() => setCenteredModal(!centeredModal)}>
                    <h2>Unblock User?</h2>
                </div>
                <ModalBody>
                    <h3>Do you really want to unblock</h3>
                    <div className='p-2'>
                        <Button color='danger mr-3' outline onClick={() => setCenteredModal(!centeredModal)}>
                            Cancel
                        </Button>
                        <Button color='danger' onClick={() => setCenteredModal(!centeredModal)}>
                            Unblock
                        </Button>
                    </div>
                </ModalBody>
            </Modal> : <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-dialog-centered text-center'>
                <div className='py-3 rounded bg-light border-bottom' toggle={() => setCenteredModal(!centeredModal)}>
                    <h2>Unblock All User?</h2>
                </div>
                <ModalBody>
                    <h3>Do you really want to unblock All</h3>
                    <div className='p-2'>
                        <Button color='danger mr-3' outline onClick={() => setCenteredModal(!centeredModal)}>
                            Cancel
                        </Button>
                        <Button color='danger' onClick={() => setCenteredModal(!centeredModal)}>
                            Unblock All
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        }
        </>

    }

    return (
        <div className='px-2'>
            <div className='Setteing_connection_Heading_section pt-2 hide-setting-options'>
                <h3>My Connections</h3>
            </div>
            <div className='py-2'>
                <div className='d-flex align-items-center justify-content-between py-2'>
                    {/* <h4 className='text-muted'>Blocked people can not see any activity by group </h4>
                    <h3 style={{ cursor: 'pointer' }} onClick={() => setCenteredModal('b')} className='text-primary'>Unblock All</h3> */}
                    <div className="profile-search-blk w-75 ">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        <input
                            type="text"
                            className="form-control border-0"
                            name="search"
                            placeholder="Find connections..."
                        />
                    </div>
                    <UncontrolledButtonDropdown >
                        <DropdownToggle outline color='primary' className="border border-muted ml-3 ">
                            <Sliders className='Slider_Filter' size={12} />&nbsp;&nbsp;Filter
                        </DropdownToggle>
                        <DropdownMenu className="Settings_Fillter_ConnectionTab_section">
                            <DropdownItem className="Settings_Hover_item">Your Followers</DropdownItem>
                            <DropdownItem className="Settings_Hover_item">I’m Following</DropdownItem>
                            <DropdownItem className="Settings_Hover_item">Blocked</DropdownItem>
                            <DropdownItem className="Settings_Hover_item">Friends</DropdownItem>


                        </DropdownMenu>

                    </UncontrolledButtonDropdown>
                </div>
            </div>


            <Row>
                <UnblockModal />
                {
                    connections.map((item) => {
                        return <Col xl='6' className="mb-4">
                            <div className='d-flex align-items-center justify-content-between border rounded  p-3'>
                                <div className='d-flex align-items-start'>
                                    <img src={UserImg} width={40} height={40} />
                                    <div className='ml-2'>
                                        <h3>{item.fullName}</h3>
                                        <h5 className='py-1 text-muted'>{item.userName}</h5>
                                        <p className='text-muted'>Following Since 6 Months</p>
                                    </div>
                                </div>
                                <div>
                                    {/* <Button color='danger' onClick={() => setCenteredModal('a')} >Unblock</Button> */}
                                    <UncontrolledButtonDropdown className="My_Connection_MoreBtn_blk">
                                        <DropdownToggle color='flat-primary' >
                                            <MoreHorizontal color='#808080' size={24} />
                                        </DropdownToggle>
                                        <DropdownMenu className="Settings_Fillter_ConnectionTab_section">
                                            <DropdownItem className="Settings_Hover_item">Unfollow </DropdownItem>
                                            <DropdownItem className="Settings_Hover_item">Block </DropdownItem>
                                            <DropdownItem className="Settings_Hover_item">Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </div>
                        </Col>
                    })
                }
            </Row>

        </div>
    )
}

export default Connections