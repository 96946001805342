import React, { Component, useState, useEffect, Fragment } from 'react';
import { NavLink } from "react-router-dom";
import Slider from 'react-slick';
import { Card, Nav, NavItem } from 'reactstrap';
import "./biz.css";

export default function BizMenu() {
    //==============Start_Slider_settings==================//
    let bizSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    const PodcastNavData = [
        {
            id: "1",
            NavName: "All (4)"
        },
        {
            id: "2",
            NavName: "TV & Film (1)"
        },
        {
            id: "3",
            NavName: "Health & Fitness(1)"
        },
        {
            id: "4",
            NavName: "Socity & Culture (1)"
        },
        {
            id: "5",
            NavName: "Comedy (1)"
        }

    ]
    //============== Main_return_function ==============//

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Add event listener to update window width
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    let MobileviewSlider = {
        // arrows: false,
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            },
        ]
    };
    return (
        <>
            <div className="Biz-heading_section headbiz">
                <h2>Biz Page</h2>
            </div>
            <div className="flex-container">
                <NavLink to="/CreateBiz" style={{ paddingLeft: '2px' }} className='svgcreted'>
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    <h6 className=' cretedbizpage' style={{ display: 'inline-block' }} >Create Biz Page</h6>
                </NavLink>
            </div>
            <div className="profile-menu section-t-space d-lg-flex d-none">
                <ul>
                    <li>
                        <NavLink to="/Biz">
                            <h6 >All Biz Pages(56)</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/OwnedBiz">
                            <h6>Owned Pages(23)</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/JoinedBiz">
                            <h6>Followed Pages(18)</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/BizSuggested">
                            <h6>Suggested Pages(61)</h6>
                        </NavLink>
                    </li>
                </ul>
                <ul className="right-menu   createdbiz ">
                    <li>
                        <NavLink className='cretbiz' to="/CreateBiz">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            <h6>Create Biz Page</h6>
                        </NavLink>
                    </li>
                </ul>
            </div>
            {/* <div className='d-lg-none d-block profile-menu pemu' >
                <Slider {...bizSettings} >
                    <ul>
                        <li className='allbiz'>
                            <NavLink to="/Biz">
                                <h6>All Biz Pages(56)</h6>
                            </NavLink>
                        </li>
                    </ul>
                    <ul className='ownedd'>
                        <li className='owned'>
                            <NavLink to="/OwnedBiz">
                                <h6 className='ownpage'>Owned Pages(23)</h6>
                            </NavLink>
                        </li>
                    </ul>
                    <ul className='foll1'>
                        <li>
                            <NavLink to="/JoinedBiz">
                                <h6 className='foll'>Followed Pages(18)</h6>
                            </NavLink>
                        </li>
                    </ul>
                    <ul className='sugeested sugess' >
                        <li>
                            <NavLink to="/BizSuggested">
                                <h6 className='widt'>Suggested Pages(61)</h6>
                            </NavLink>
                        </li>
                    </ul>
                
                </Slider>
            </div > */}
            <div className='d-lg-none d-block  profile-menu1 pemu BizPage_Card_section112' >
                <Slider  {...MobileviewSlider} className="default-space">



                    <div className='my-1'>
                        <NavLink className='my_events_Navlink d-flex justify-content-center rounded shadow' style={{ padding: "10px" }} to="/Biz">
                            <h6>All Biz Pages(56)</h6>
                        </NavLink>
                    </div>
                    <div className='my-1'>
                        <NavLink className='my_events_Navlink d-flex justify-content-center rounded shadow' style={{ padding: "10px" }} to="/OwnedBiz" >
                            <h6 className='ownpage'>Owned Pages(23)</h6>
                        </NavLink>
                    </div>
                    <div className='my-1'>
                        <NavLink className='my_events_Navlink d-flex justify-content-center rounded shadow' style={{ padding: "10px" }} to="/JoinedBiz" >
                            <h6 className='foll'>Followed Pages(18)</h6>
                        </NavLink>
                    </div>

                    <div className='my-1'>
                        <NavLink className='my_events_Navlink d-flex justify-content-center rounded shadow' style={{ padding: "10px" }} to="/BizSuggested" >
                            <h6 className='widt'>Suggested Pages(61)</h6>
                        </NavLink>
                    </div>
                  


                </Slider>

                
            </div>
        </>
    );
} 