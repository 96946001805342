import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Input } from 'reactstrap'
import AllTabs from './AllTabs'
import People from './People'
import BizPage from './BizPage'
import Group from './Group'
import Shotz from './Shotz'
import Images from './Images'
import Podcast from './Podcast'
import TagTabs from './TagTabs'
import VideoTabs from './VideoTabs'
import PollTabs from './PollTabs'
import TextTabs from './TextTabs'
import ThoughtTabs from './ThoughtTabs'
import ThreatTabs from './ThreatTabs'
import RecommendationTabs from './RecommendationTabs'
import EventTabs from './EventTabs'
import MarketplaceTabs from './MarketplaceTabs'
import ArticleTabs from './ArticleTabs'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import './Search.css'
import Slider from "react-slick";
import { useUserAuth } from '../../Context/userAuthContext'

let ownedGroupSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        },
    ]
};

const Search = () => {

    const { searchHide, setsearchShow } = useUserAuth()

    const [active, setActive] = useState('16')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    return (
        <div>

            <Header></Header>
            <div className="page-body container-fluid profile-page">
                <LeftSidebar></LeftSidebar>
                <div className="page-center">
                    <React.Fragment>
                        <div className="group-custom-block">
                            {
                                searchHide ?
                                    <div className="border rounded m-1 d-xl-none">
                                        <Input
                                            type="text"
                                            className=""
                                            placeholder="Search..."
                                        />
                                    </div>
                                    : null
                            }

                            <Card className='p-2 border-0 p-2'>
                                <Slider {...ownedGroupSettings} className="default-space">
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem text-center'>
                                                <NavLink className=''
                                                    active={active === '1'}
                                                    onClick={() => {
                                                        toggle('1')
                                                    }}
                                                >
                                                    All
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '2'}
                                                    onClick={() => {
                                                        toggle('2')
                                                    }}
                                                >
                                                    People
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>

                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '3'}
                                                    onClick={() => {
                                                        toggle('3')
                                                    }}
                                                >
                                                    BizPage
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '4'}
                                                    onClick={() => {
                                                        toggle('4')
                                                    }}
                                                >
                                                    Groups
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '5'}
                                                    onClick={() => {
                                                        toggle('5')
                                                    }}
                                                >
                                                    Shotz
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '6'}
                                                    onClick={() => {
                                                        toggle('6')
                                                    }}
                                                >
                                                    Media
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '7'}
                                                    onClick={() => {
                                                        toggle('7')
                                                    }}
                                                >
                                                    Podcast
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '8'}
                                                    onClick={() => {
                                                        toggle('8')
                                                    }}
                                                >
                                                    #Tags
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '9'}
                                                    onClick={() => {
                                                        toggle('9')
                                                    }}
                                                >
                                                    Poll
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    {/* <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '10'}
                                                    onClick={() => {
                                                        toggle('10')
                                                    }}
                                                >
                                                    Videos
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div> */}
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '11'}
                                                    onClick={() => {
                                                        toggle('11')
                                                    }}
                                                >

                                                    Text
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className=''
                                                    active={active === '12'}
                                                    onClick={() => {
                                                        toggle('12')
                                                    }}
                                                >
                                                    Article
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>

                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className='NavLink'
                                                    active={active === '13'}
                                                    onClick={() => {
                                                        toggle('13')
                                                    }}
                                                >
                                                    Thought
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className='NavLink'
                                                    active={active === '14'}
                                                    onClick={() => {
                                                        toggle('14')
                                                    }}
                                                >
                                                    Threat
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center'>
                                                <NavLink className='NavLink'
                                                    active={active === '15'}
                                                    onClick={() => {
                                                        toggle('15')
                                                    }}
                                                >
                                                    Event
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center MarketplaceThisWidth'>
                                                <NavLink className='NavLink'
                                                    active={active === '16'}
                                                    onClick={() => {
                                                        toggle('16')
                                                    }}
                                                >

                                                    MarketPlace

                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                    <div>
                                        <Nav pills>
                                            <NavItem className='Search_NavItem w-100 text-center RecommendationThisWidth'>
                                                <NavLink className='NavLink'
                                                    active={active === '17'}
                                                    onClick={() => {
                                                        toggle('17')
                                                    }}
                                                >
                                                    Recommendation
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </div>
                                </Slider>
                            </Card>
                        </div>
                        <div className='mt-3'>
                            <TabContent className='py-50' activeTab={active}>
                                <TabPane tabId='1'>
                                    <AllTabs />
                                </TabPane>
                                <TabPane tabId='2'>
                                    <People />
                                </TabPane>
                                <TabPane tabId='3'>
                                    <BizPage />
                                </TabPane>
                                <TabPane tabId='4'>
                                    <Group />
                                </TabPane>
                                <TabPane tabId='5'>
                                    <Shotz />
                                </TabPane>
                                <TabPane tabId='6'>
                                    <Images />
                                </TabPane>
                                <TabPane tabId='7'>
                                    <Podcast />
                                </TabPane>
                                <TabPane tabId='8'>
                                    <TagTabs />
                                </TabPane>
                                <TabPane tabId='9'>
                                    <PollTabs />
                                </TabPane>
                                {/* <TabPane tabId='10'>
                                    <VideoTabs />
                                </TabPane> */}
                                <TabPane tabId='11'>
                                    <TextTabs />
                                </TabPane>
                                <TabPane tabId='12'>
                                    <ArticleTabs />
                                </TabPane>
                                <TabPane tabId='13'>
                                    <ThoughtTabs />
                                </TabPane>
                                <TabPane tabId='14'>
                                    <ThreatTabs />
                                </TabPane>
                                <TabPane tabId='15'>
                                    <EventTabs />
                                </TabPane>
                                <TabPane tabId='16'>
                                    <MarketplaceTabs />
                                </TabPane>
                                <TabPane tabId='17'>
                                    <RecommendationTabs />
                                </TabPane>
                            </TabContent>
                        </div>

                    </React.Fragment>


                </div>
                <RightSidebar></RightSidebar>
            </div>

        </div >
    )

}

export default Search