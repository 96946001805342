import React, { Fragment, useEffect, useRef } from 'react'
import { Button, CustomInput, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip, Container, DropdownMenu, DropdownItem, UncontrolledButtonDropdown, DropdownToggle } from 'reactstrap';
import { AtSign, Check, ChevronsDown, ChevronsUp, Clock, Globe, Info, MapPin, Phone, UploadCloud, X, ChevronDown, XCircle } from 'react-feather';
import { FormLabel, Radio } from '@mui/material';
import { useState } from 'react';
import BizTrack1 from '../Img/BizTrack1.svg'
import SetUpActionModal from '../AllTypesModal/SetUpActionModal';
import { getCategoryListAction, getSubCategoryListAction, getAboutUsAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import './biz1.css'
import { config } from '@fortawesome/fontawesome-svg-core';
import Pen1 from '../../profile-components/Img/Pen1.png'
import Timemodal from '../AllTypesModal/Timemodal'


mapboxgl.accessToken = 'pk.eyJ1IjoiNzQ4NSIsImEiOiJjbDFua3kwcHIwMDE1M2luMXhleDNqMGZiIn0.Mj40f5LXER6tUfR3ygQLaA'
const CreationLeftSide = ({
    setName,
    setbpCategoryIdToProp,
    setbpCategoryName,
    setbpSubCategoryIdToProp,
    setbpSubCategoryName,
    setSummary,
    setbpaddress,
    setcountry,
    setcity,
    setzipCode,
    setlocationLATToProps,
    setlocationLONGToProps,
    setImageLogo,
    setImage,
    setStartingTimeShift1,
    setStartingMinute,
    setSelectedStartingTimeValue,
    setSelectedValueClosing,
    setEndingTimeShift1,
    setEndingMinute,
    setWebUrl,
    setWebUrl1,

    setEmail,
    setEmail1,
    setContactNumber1,
    setContactNumber2,
}) => {
    let dispatch = useDispatch();
    const bpMainCatogory = useSelector((state) => { return state.getCategoryListData });
    const { loading: loadingCategoryData, error: errorCategoryData, result: mainCategoryData } = bpMainCatogory

    const bpSubMainCatogory = useSelector((state) => { return state.getSubCategoryListData });
    const { loading: loadingsubCategoryData, error: errorsubCategoryData, result: subCategoryData } = bpSubMainCatogory


    const subcategoryNames = subCategoryData?.map((subcategory) => ({ id: subcategory?.id, name: subcategory?.name }));

    // console.log('subcategoryNames',subcategoryNames);

    const [show, setShow] = useState(false)
    // const [selectedStartingTimeValue, setSelectedValue] = useState('a');
    // const [selectedValueClosing, setSelectedValueClosing] = useState('b');
    const [cancleModal, setCancleModal] = useState(false)
    const [moreAddress, setMoreAddress] = useState(false)
    const [moreUrl, setMoreUrl] = useState(false)
    const [moreEmail, setMoreEmail] = useState(false)
    const [moreContact, setMoreContact] = useState(false)
    const [actionSetUp, setActionSetUp] = useState(false)

    const [bpCategoryId, setbpCategoryId] = useState('')
    // const [bpCategoryIdToProp, setbpCategoryIdToProp] = useState('')
    // const [bpCategoryName, setbpCategoryName] = useState('')
    const [bpSubCategoryId, setbpSubCategoryId] = useState()
    // const [bpSubCategoryName, setbpSubCategoryName] = useState()

    // const [summary, setSummary] = useState('')
    // const [bpaddress, setbpaddress] = useState('')
    const [bpaddressThisPage, setbpaddressThisPage] = useState('')

    // const [country, setcountry] = useState('')
    // const [city, setcity] = useState('')
    // const [zipCode, setzipCode] = useState('')

    const [logo, setlogo] = useState('')
    const [status, setstatus] = useState('')
    const [visitorCount, setvisitorCount] = useState('')
    const [operatingStatus, setoperatingStatus] = useState('')
    const [isPrivate, setisPrivate] = useState('')
    const [blockedByAdmin, setblockedByAdmin] = useState('')
    const [blockCode, setblockCode] = useState('')
    const [blockMessage, setblockMessage] = useState('')
    const [visibility, setvisibility] = useState('')
    const [allowCall, setallowCall] = useState('')
    const [allowMessage, setallowMessage] = useState('')
    const [allowSharing, setallowSharing] = useState('')
    const [allowPageSuggestion, setallowPageSuggestion] = useState('')
    const [allowQuestion, setallowQuestion] = useState('')
    const [allowNotificationOnEmail, setallowNotificationOnEmail] = useState('')
    const [allowNotificationOnSms, setallowNotificationOnSms] = useState('')
    const [allowNotification, setallowNotification] = useState('')
    const [logoUrl, setLogoUrl] = useState('')
    const [coverUrl, setcoverUrl] = useState('')
    const [address, setaddress] = useState('')
    const [locationLAT, setlocationLAT] = useState('')
    const [locationLONG, setlocationLONG] = useState('')
    const [isDefault, setisDefault] = useState('')
    const [pinCode, setPincode] = useState()

    const [inputValue, setInputValue] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [hour1, setHour1] = useState('');
    const [minute1, setMinute1] = useState('');
    const [selectedValue, setSelectedValue] = useState('AM');
    const [selectedValue1, setSelectedValue1] = useState('AM');





    const [isAutoDetect, setIsAutoDetect] = useState(true);

    const handleClick = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
  
          if (isAutoDetect) {
            setbpaddress('Gomti Nagar' + ", " + 'Lucknow');
            setbpaddressThisPage('Gomti Nagar' + ", " + 'Lucknow');
            setlocationLAT(latitude);
            setlocationLATToProps(latitude);
            setlocationLONGToProps(longitude);
            setlocationLONG(longitude);
            console.log("Latitude: " + latitude + ", Longitude: " + longitude);
          }
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };
  
    const handleInputChange4 = (event) => {
      setbpaddress(event.target.value);
      setbpaddressThisPage(event.target.value);
      // Add any additional logic or validation if needed
    };


    useEffect(() => {
        dispatch(getCategoryListAction(10, 0));
    }, [])

    useEffect(() => {
        dispatch(getSubCategoryListAction(10, 0, bpCategoryId));
        // console.log('subcategoryNames',subCategoryData);
    }, [bpCategoryId])



    const selectMainCategory = (event) => {
        const selectedIndex = event.target.selectedIndex;
        const selectedData = mainCategoryData[selectedIndex - 1]; // subtract 1 to skip the "Select Main Category" option
        setbpCategoryId(selectedData.id);
        setbpCategoryIdToProp(selectedData.id)
        setbpCategoryName(selectedData.name);
    };


    const selectSubCategory = (event) => {
        const selectedIndex = event.target.selectedIndex;
        const selectedData = subcategoryNames[selectedIndex - 1]; // subtract 1 to skip the "Select Main Category" option
        setbpSubCategoryId(event.target.value);
        setbpSubCategoryIdToProp(selectedData.id)
        setbpSubCategoryName(selectedData.name);
    };

    const selectBizPageName = (event) => {
        setName(event.target.value);
    };

    const selectSummery = (event) => {
        setSummary(event.target.value);
    };


    const selectCityName = (event) => {
        setcity(event.target.value);
    };

    const selectZipCode = (event) => {
        //setzipCode(event.target.value);
    };

    const toggleSetUp = () => {
        setActionSetUp((preview) => !preview)
    }

    const handleChange = (event) => {
        setSelectedValueClosing(event.target.value);
        setSelectedValue(event.target.value);

    }

    const webUrl = (event) => {
        setWebUrl(event.target.value);
    }
    const webUrl1 = (event) => {
        setWebUrl1(event.target.value);
    }


    const email = (event) => {
        setEmail(event.target.value);
    }
    const email1 = (event) => {
        setEmail1(event.target.value);
    }

    // const contactNumber = (event) => {
    //     setContactNumber(event.target.value);
    // }

    const selectSummary = (event) => {
        const newValue = event.target.value;
        setSummary(newValue);
        setInputValue(newValue);
    };

    const handleChangeStartingTime = (event) => {
        setSelectedStartingTimeValue(event.target.value);
        setSelectedValue1(event.target.value);
    }

    const CancleHandle = () => {
        setCancleModal(!cancleModal)
    }


    // const [country, setCountry] = useState('');
    const selectCountry = (event) => {
        setcountry(event.target.value);
    }

    const startingHour = (event) => {
        setStartingTimeShift1(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Update the state with the numeric value
        if (numericValue < 0) {
            setHour('0');
        } else if (numericValue > 12) {
            setHour('12');
        } else {
            setHour(numericValue);
        }

    }

    const startingMinute = (event) => {
        setStartingMinute(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Ensure the minute value is between 0 and 59
        if (numericValue < 0) {
            setMinute('0');
        } else if (numericValue > 59) {
            setMinute('59');
        } else {
            setMinute(numericValue);
        }

    }

    const endingHour = (event) => {
        setEndingTimeShift1(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Update the state with the numeric value
        if (numericValue < 0) {
            setHour1('0');
        } else if (numericValue > 12) {
            setHour1('12');
        } else {
            setHour1(numericValue);
        }

    }
    const endtingMinute = (event) => {
        setEndingMinute(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Ensure the minute value is between 0 and 59
        if (numericValue < 0) {
            setMinute1('0');
        } else if (numericValue > 59) {
            setMinute1('59');
        } else {
            setMinute1(numericValue);
        }
    }

    // Initially set to 'AM' or 'PM' as desired


    const [image1, setImage1] = useState("")
    const [imageLogo1, setImageLogo1] = useState("")

    const fileHandler = async (e) => {
        // e.preventDefault()
        let file = e.target.files[0]
        let groupImage = 'groupImage'
        const form = new FormData()
        form.append('file', file)
        form.append('uploadFor', groupImage)
        try {
            let user = JSON.parse(localStorage.getItem('sociomeeUser'));
            const cofig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user?.token}`
                }
            }
            const { data } = await axios.post(`${process.env.REACT_APP_IPURL}/admin/uploadFile`,
                form, cofig
            )
            console.log("this is the formddata:::", form);
            console.log("this is hte confiq:::", config);
            console.log('image', data)
            setImage(data.data.successResult[0])
            setImageLogo1(data.data.successResult[0])
        } catch (error) {
            console.log('ImageUpload Error', error)
        }
    }
    //  pin validation
    function handleInputChange(event) {
        const input = event.target;
        let inputValue = input.value.trim();
        if (inputValue.length > 6) {
            inputValue = inputValue.slice(0, 6);
            event.target.value = inputValue;

        }
        setzipCode(inputValue);
        setPincode(inputValue);

        // if (inputValue.length === 6) {
        //     input.value = inputValue.slice(0, 6);
        // }
    }
    const combinedOnChange = (event) => {
        handleInputChange(event);
        selectCountry(event);
    };

    function handleInputChange1(event) {
        const input = event.target;
        let inputValue = input.value.trim();
        if (inputValue.length > 17) {
            inputValue = inputValue.slice(0, 17);
            event.target.value = inputValue;

        }
        setContactNumber1(inputValue);



    };
    function handleInputChange2(event) {
        const input = event.target;
        let inputValue = input.value.trim();
        if (inputValue.length > 17) {
            inputValue = inputValue.slice(0, 17);
            event.target.value = inputValue;

        }

        setContactNumber2(inputValue)


    };
    const handleKeyPress = (event) => {
        // Allow only numeric characters (0-9) and backspace
        if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault(); // Prevent the default paste behavior
        // Get the pasted text from the clipboard
        const pastedText = event.clipboardData.getData('text/plain');

        // Remove non-numeric characters from the pasted text
        const numericText = pastedText.replace(/[^\d]/g, '');

        // Update the input value with the cleaned numeric text
        document.execCommand('insertText', false, numericText);

    };

    const handleCancel = () => {
        setImage1(null); // Clear the image when XCircle is clicked
    };
    const handleCancel1 = () => {
        setImageLogo1(null); // Clear the image when XCircle is clicked
    };


    //   end here


    const [isOpen4, setIsOpen4] = useState(false);
    const toggleOpenPoint2 = () => {
        setIsOpen4(!isOpen4);
    };

    const [tymOpen, setTymOpen] = useState(false)
    const toggleTym = () => {
        setTymOpen((preState) => !preState)
        setTymOpen(!tymOpen)

    }
    const handleCloseModal1 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleTym();

    };
    const [additionalHours, setAdditionalHours] = useState([]);
    const [startingHours, setStartingHour] = useState('');
    const [endingHours, setEndingHour] = useState('');

    const addAdditionalHours = () => {
        setAdditionalHours([...additionalHours, { startingHours, endingHours, hour: '', minute: '', selectedValue: 'AM', selectedValue1: 'AM' }]);
        setStartingHour(''); // Reset input fields
        setEndingHour('');
    };
    const removeAdditionalHours = (index) => {
        const updatedHours = [...additionalHours];
        updatedHours.splice(index, 1);
        setAdditionalHours(updatedHours);
    };

       // Function to handle change in ending hour for additional hours
       const handleEndingHourAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].hour1 = numericValue < 0 ? '0' : numericValue > 12 ? '12' : numericValue;
            return updatedHours;
        });
    };

    // Function to handle change in ending minute for additional hours
    const handleEndingMinuteAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].minute1 = numericValue < 0 ? '0' : numericValue > 59 ? '59' : numericValue;
            return updatedHours;
        });
    };
    const handleStartingHourAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].hour = numericValue < 0 ? '0' : numericValue > 12 ? '12' : numericValue;
            return updatedHours;
        });
    };

    // Function to handle change in starting minute for additional hours
    const handleStartingMinuteAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].minute = numericValue < 0 ? '0' : numericValue > 59 ? '59' : numericValue;
            return updatedHours;
        });
    };

    const handleRadioStartingTimeAdditional = (value, index) => {
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].selectedValue1 = value;
            return updatedHours;
        });
    };

    const handleRadioEndingTimeAdditional = (value, index) => {
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].selectedValue = value;
            return updatedHours;
        });
    };


    const fileHandlerLogo = async (e) => {
        // e.preventDefault()
        let file = e.target.files[0]

        let groupImage = 'groupImage'
        const form = new FormData()
        form.append('file', file)
        form.append('uploadFor', groupImage)
        try {
            let user = JSON.parse(localStorage.getItem('sociomeeUser'));
            const cofig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user?.token}`
                }
            }
            const { data } = await axios.post(`${process.env.REACT_APP_IPURL}/admin/uploadFile`,
                form, cofig
            )
            console.log('image', data.data.successResult[0])
            setImageLogo(data.data.successResult[0])
            setImage1(data.data.successResult[0])

        } catch (error) {
            console.log('ImageUpload Error', error)
        }
    }


    //================== Form_Array_section=================//
    // const TopCountryData = ["Choose your country ","India", "Germany", "Canada", "United States", "Sweden", "Japan", "Australia"]

    const TopCountryData = [
        { name: 'India' },
        { name: 'Germany' },
        { name: 'Canada' },
        { name: 'United States' },
        { name: 'Sweden' },
        { name: 'Japan' },
        { name: 'Australia' }
    ]

    const SetUpData = [
        {
            title: "Chat with us on MsgMee",
            sabTitle: "Allow people to contact your Page privately on SocioMee messenger.",
        },
        {
            title: "Call Me ",
            sabTitle: "Allow people to call you to know more about your business.",
        },
        {
            title: "Send Email",
            sabTitle: "Choose an email address where people can contact you.",
        },
        {
            title: "Sharing",
            sabTitle: "Allow people to share your Page on SocioMee.",
        }
    ]

    //================== main_return_section=================//
    return (
        <>

            <Container className="">

                <Fragment>
                    <Form>
                        <FormGroup>
                            <Label
                                for="Biz-Catagory"
                            >
                                <div className=" BizInput_heading_section mt-3" >
                                    <b>Biz Category</b>&nbsp;<Info id='BizCatagoryId' fill='#C4C4C4' color='#fff' size={17} />
                                    <UncontrolledTooltip placement='right' target='BizCatagoryId'>
                                        Page title
                                    </UncontrolledTooltip>
                                </div>
                            </Label>
                            <Input
                             
                                id="Biz-Catagory"
                                name="select"
                                type="select"
                                maxMenuHeight={250}
                                onfocus='this.size=5'
                                className="Biz_form_input_field"
                                onChange={selectMainCategory}
                                value={bpCategoryId}
                                style={{ cursor: "pointer" }}
                                 
                                

                            >
                                <option  > Select Main Category </option>
                             


                                {
                                    mainCategoryData?.map((data, index) => (
                                        <option key={index} value={data.id} >
                                            <span>{data?.name.length > 10 ? data?.name.slice(0, 30) + '...' : data?.name}</span>

                                        </option>
                                    ))
                                }


                            </Input >
                            <span className='downarrow' > <ChevronDown /></span>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                for="Sub-Catagory"
                            >
                                <div className="BizInput_heading_section ">
                                    <b>Sub Category</b>&nbsp;<Info id='SubCatagoryId' fill='#C4C4C4' color='#fff' size={17} />
                                    <UncontrolledTooltip placement='right' target='SubCatagoryId'>
                                        job sub title
                                    </UncontrolledTooltip>
                                </div>
                            </Label>
                            <Input
                                id="Sub-Catagory"
                                name="select"
                                type="select"
                                maxMenuHeight={250}
                                placeholder='jss'
                                onfocus='this.size=5;'
                                className="Biz_form_input_field"
                                onChange={selectSubCategory}
                                value={bpSubCategoryId}

                            >
                                <option> select sub category </option>

                                {
                                    subcategoryNames?.map((data, index) => (
                                        <option key={index} value={data.id}>
                                            <span>{data?.name.length > 10 ? data?.name.slice(0, 30) + '...' : data?.name}</span>
                                        </option>
                                    ))
                                }
                            </Input>
                            <span className='downarrow'> <ChevronDown /></span>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                for="BizPage-Name"
                            >
                                <div className="BizInput_heading_section ">
                                    <b>BizPage Name</b>&nbsp;<Info id='BizPageNameId' fill='#C4C4C4' color='#fff' size={17} />
                                    <UncontrolledTooltip placement='right' target='BizPageNameId'>
                                        Page Name
                                    </UncontrolledTooltip>
                                </div>
                            </Label>
                            <Input
                                type="text"
                                placeholder='Enter Your Business Name '
                                maxLength={48}
                                className="Biz_form_input_field"
                                onChange={selectBizPageName}
                            // value={name}                  
                            />
                            <p className="input-hints">Max 48 Characters</p>
                        </FormGroup>
                        <FormGroup>
                            <Label
                                for='Summery'
                            >
                                <div className="BizInput_heading_section ">
                                    <b style={{ cursor: 'pointer' }}>Summary</b>&nbsp;<Info id='SummeryId' fill='#C4C4C4' color='#fff' size={17} />
                                    <UncontrolledTooltip placement='right' target='SummeryId'>
                                        Biz Summary
                                    </UncontrolledTooltip>
                                </div>
                            </Label>
                            <Input
                                rows="2"
                                className="form-control Biz_form_input_field1 bizsummary"
                                id="exampleText"
                                name="text"
                                type="textarea"
                                maxLength={180}
                                placeholder='Describe your business'
                                onChange={selectSummery}
                                style={{ innerHeight: '400px' }}
                            />
                            <p className="input-hints">Max 180 Characters</p>
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex justify-content-between  align-items-center BizInput_heading_section py-2">
                                <div >
                                    <b>Biz Address</b>&nbsp;<Info id='BizAddressId' fill='#C4C4C4' color='#fff' size={17} />
                                    <UncontrolledTooltip placement='right' target='BizAddressId'>
                                        biz Address
                                    </UncontrolledTooltip>
                                </div>
                                <div className='BizForm_action_section' >
                                    <img src={BizTrack1} alt="map" />
                                    <p onClick={handleClick} className=' ml-2'>Detect Location</p>
                                </div>
                            </div>
                            {/* <div id="map" style={{ height: '400px' }}></div> */}
                            <Input
        id="exampleText"
        type="text"
        placeholder='Where is your biz located at?'
        className="Biz_form_input_field"
        onChange={handleInputChange4}
        value={bpaddressThisPage}
        // readOnly={isAutoDetect}
      />
                            <div className='mt-3'>

                                <Input
                                    id="Sub-Catagory"
                                    name="select"
                                    type="text"
                                    placeholder='Select your country'
                                    className="Biz_form_input_field"
                                    onChange={selectCountry}
                                    maxLength={30}

                                >
                                    <option>
                                        Please select your country
                                    </option>
                                    {/* {
                                                TopCountryData.map((data, index) => (

                                                    <option key={index} maxMenuHeight={250} value={data.name}>
                                                        <span> {data?.name}</span>
                                                    </option>
                                                ))
                                            } */}
                                </Input>


                            </div>
                            <div className='mt-3 addadress'>
                                <Input
                                    id="BizPageName"
                                    name="BizPage-Name"
                                    type="BizPage-Name"
                                    placeholder='Enter your city name'
                                    onChange={selectCityName}
                                    maxLength={30}

                                // value={city} 
                                />
                            </div>
                            <div className='mt-3 addadress'>
                                <Input
                                    id="BizPageName"
                                    name="BizPage-Name"
                                    type="number"
                                    step="1"
                                    placeholder='Enter 6 Digit Zip Code city'
                                    onChange={combinedOnChange}



                                />
                            </div>
                            {moreAddress ? null : (
                                <p onClick={() => setMoreAddress(true)} className='text-end   text-primary Biz_Form_more_location pt-1 '  >Add more Address</p>
                            )}

                        </FormGroup>
                        {moreAddress ? (
                            <>
                                <FormGroup>
                                    <div className="d-flex justify-content-between  align-items-center BizInput_heading_section  py-1">
                                        <div >
                                            <b>Other Address</b>&nbsp;<Info id='BizAddressId' fill='#C4C4C4' color='#fff' size={17} />
                                            <UncontrolledTooltip placement='right' target='BizAddressId'>
                                                Other biz Address
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className='BizForm_action_section' >
                                            <p onClick={() => setMoreAddress(!moreAddress)}>Remove</p>
                                        </div>
                                    </div>
                                    <Input
                                        id="exampleText"
                                        name="text"
                                        type="Biz-Address"
                                        placeholder='Where is your biz located at?'
                                        className="Biz_form_input_field"
                                    />
                                    <div className='mt-3'>
                                        <Input
                                            id="Sub-Catagory"
                                            name="select"
                                            type="Text"
                                            placeholder='Select your country'
                                            className="Biz_form_input_field"
                                            onChange={selectCountry}
                                            maxLength={30}

                                        >
                                            <option>
                                                <span>Please select your country</span>
                                            </option>
                                            {/* {
                                                TopCountryData.map((data, index) => (

                                                    <option key={index} maxMenuHeight={250} value={data.name}>
                                                        <span> {data?.name}</span>
                                                    </option>
                                                ))
                                            } */}
                                        </Input>
                                    </div>
                                    <div className='mt-3 addadress'>
                                        <Input
                                            id="BizPageName"
                                            name="BizPage-Name"
                                            type="BizPage-Name"
                                            placeholder='Enter your city name'
                                            onChange={selectCityName}
                                            maxLength={30}
                                        />
                                    </div>
                                    <div className='mt-3 addadress'>
                                        <Input
                                            id="BizPageName"
                                            name="BizPage-Name"
                                            type="number"
                                            step="1"

                                            placeholder='Enter 6 Digit Zip Code city'
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {/* {for adding  more address} */}
                                    {/* <p className='text-end text-primary1 fw-bolder py-1' onClick={() => setMoreAddress(!moreAddress)} >Add more Address</p> */}
                                    {/* <p className='text-end text-primary1 fw-bolder py-1' >Add more Address</p> */}
                                </FormGroup></>
                        ) : null}
                        {/*================== start_ Add_logo_section ==================*/}
                        <FormGroup>
                            <Label className="BizInput_heading_section ">
                                <b>Add you biz logo</b>
                            </Label>
                            <div className="profile-cover profile-cover-new">
                                {image1 ? (
                                    <div style={{ position: 'relative' }}>

                                        <img
                                            alt='bPage'
                                            style={{ width: '100%',borderRadius:"6px" }}
                                            src={`${process.env.REACT_APP_IMAGEURL}${image1}`}
                                        />
                                        <XCircle
                                            color="white"
                                            onClick={handleCancel}
                                            className='xcircle'// Call handleCancel when XCircle is clicked
                                            style={{ top: '6px', position: 'absolute', right: '6px' }}
                                        />
                                    </div>
                                ) : (
                                    <a href="#" className="btn BizCreationbtn-solidUpload btn-group btn-grp-new">
                                        <ul>
                                            <li className="choose-file" style={{ cursor: 'pointer' }}>
                                                <a className='UploadBtn' href="">
                                                    <UploadCloud size={14} />&nbsp;
                                                    Upload
                                                </a>
                                                <input type="file" onChange={fileHandlerLogo} />
                                            </li>
                                        </ul>
                                    </a>
                                )}
                            </div>
                            {/* <div className="profile-cover profile-cover-new">
                                <a href="#" className="btn BizCreationbtn-solidUpload btn-group btn-grp-new">
                                    <ul>
                                        <li className="choose-file" style={{ cursor: 'pointer' }}>
                                            <a className='UploadBtn' href="">
                                                <UploadCloud size={14} />&nbsp;
                                                Upload
                                            </a>
                                            <input type="file"
                                                onChange={fileHandlerLogo}
                                            />

                                        </li>

                                    </ul>
                                </a> */}
                            {/* {logo ? (
                                    <div style={{ }}>
                                        <XCircle color="black" />
                                        <img
                                            src={`https://sociomee-dev.s3.ap-south-1.amazonaws.com/${logo}`}
                                            className="img-fluid"
                                            alt="logo"
                                        />
                                    </div>
                                ) : null} */}

                            {/* <img alt='bPage'
                           style={{width:'50px'}} src={`${process.env.REACT_APP_IMAGEURL}${image1}`}
                        />
                            </div> */}
                        </FormGroup>
                        <FormGroup>
                            <Label className="BizInput_heading_section ">
                                <b>Add your cover image</b>
                            </Label>
                            <div className="profile-cover profile-cover-new">
                                {imageLogo1 ? (
                                    <div style={{ position: 'relative' }}>

                                        <img
                                            alt='bPage'
                                            style={{ width: '100%',borderRadius:"6px" }}
                                            src={`${process.env.REACT_APP_IMAGEURL}${imageLogo1}`}
                                        />
                                        <XCircle
                                            color="white"
                                            onClick={handleCancel1}
                                            className='xcircle'// Call handleCancel when XCircle is clicked
                                            style={{ top: '6px', position: 'absolute', right: '6px' }}
                                        />
                                    </div>
                                ) : (
                                    <a href="#" className="btn BizCreationbtn-solidUpload btn-group btn-grp-new">
                                        <ul>
                                            <li className="choose-file">
                                                <a className='UploadBtn' href="">
                                                    <UploadCloud size={14} />&nbsp;
                                                    Upload
                                                </a>
                                                <input type="file" onChange={fileHandler} />
                                            </li>
                                        </ul>
                                    </a>
                                )}
                            </div>
                            {/* <div className="profile-cover profile-cover-new">
                                <a href="#" className="btn BizCreationbtn-solidUpload btn-group btn-grp-new">
                                    <ul>
                                        <li className="choose-file">
                                            <a className='UploadBtn' href="">

                                                <UploadCloud size={14} />&nbsp;
                                                Upload
                                            </a>
                                            <input type="file"
                                                onChange={fileHandler}
                                            />
                                        </li>
                                    </ul>
                                </a>
                                <div>
                                <img alt='logo1'
                           style={{width:'50px'}} src={`${process.env.REACT_APP_IMAGEURL}${imageLogo1}`}
                        />
                        </div> */}
                            {/* </div> */}
                        </FormGroup>
                        {/*================== end_ Add_logo_section ==================*/}

                        {/*================== start_Addition_Info_section ==================*/}
                        {
                            show ?
                                <FormGroup>
                                    <Label
                                        for="Opertaing-Hours"
                                    >
                                        <div className="BizInput_heading_section " >
                                            <b>Opertaing Hours</b>&nbsp;<Info id='OpertaingHoursId' fill='#C4C4C4' color='#fff' size={17} />
                                            <UncontrolledTooltip placement='right' target='OpertaingHoursId'>
                                                shop timming
                                            </UncontrolledTooltip>
                                        </div>
                                       
                                    </Label>
                                    {/* <div className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <Input
                                                    name="otp"
                                                    type="text"
                                                    placeholder="hh"
                                                    pattern="[0-9]*"
                                                    maxLength={2}
                                                    autoComplete="off"
                                                    value={hour}
                                                    className="Biz_form_input_field"
                                                    onChange={startingHour}
                                                />
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <Input
                                                    name="otp"
                                                    type="text"
                                                    placeholder="mm"
                                                    pattern="[0-9]*"
                                                    maxLength={2}
                                                    autoComplete="off"
                                                    className="Biz_form_input_field"
                                                    value={minute}
                                                    onChange={startingMinute}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex ml-4 radies'>
                                            <label className='d-flex align-items-center' htmlFor="radio-buttonsOneId">
                                                <Radio
                                                    onChange={handleChangeStartingTime}
                                                    value={'AM'}
                                                    name="radio-buttonsOne"
                                                    id="radio-buttonsOneId"
                                                    className='Radio_btn_check'
                                                    checked={selectedValue1 === 'AM'}
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                                <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                            </label>
                                            <label className='d-flex align-items-center ' >
                                                <Radio
                                                    onChange={handleChangeStartingTime}
                                                    value={'PM'}
                                                    name="radio-buttonsOne"
                                                    className='Radio_btn_check'
                                                    id="radio-buttonsOneId"
                                                    checked={selectedValue1 === 'PM'}
                                                    inputProps={{ 'aria-label': 'B' }} />
                                                <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                            </label>
                                        </div>
                                    </div> */}
                                    {/* <Label className="py-2">
                                        <p style={{ color: "#808080" }} className="Biz_form_tym pb-2" >Closing Time  </p>
                                        <div className='d-flex justify-content-between '>
                                            <div className='d-flex align-items-center  closeinput'>
                                                <div>
                                                    <Input
                                                        name="otp"
                                                        type="text"
                                                        placeholder="hh"
                                                        maxLength={2}
                                                        className="Biz_form_input_field"
                                                        autoComplete="off"
                                                        value={hour1}
                                                        onChange={endingHour}
                                                    />
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <div>
                                                    <Input
                                                       name="otp"
                                                       type="text"
                                                       placeholder="mm"
                                                       maxLength={2}
                                                       className="Biz_form_input_field"
                                                       autoComplete="off"
                                                       value={minute1}
                                                       onChange={endtingMinute}
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex ml-4 radies'>
                                                <label className='d-flex align-items-center '>
                                                    <Radio
                                                        onChange={handleChange}
                                                        value={'AM'}
                                                        name="radio-buttonsTwo"
                                                        className='Radio_btn_check'
                                                        checked={selectedValue === 'AM'}
                                                        inputProps={{ 'aria-label': 'A' }} />
                                                    <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                                </label>
                                                <label className='d-flex align-items-center '>
                                                    <Radio
                                                        onChange={handleChange}
                                                        value={'PM'}
                                                        name="radio-buttonsTwo"
                                                        className='Radio_btn_check'
                                                        checked={selectedValue === 'PM'}
                                                        inputProps={{ 'aria-label': 'B' }} />
                                                    <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                                </label>
                                            </div>
                                        </div>
                                    </Label> */}
                                    {/* <FormGroup>
                                        <Label
                                            for="Website-URL"
                                        >
                                            <div className="BizInput_heading_section ">
                                                <b>Website URL</b>&nbsp;<Info id='WebsiteURLId' fill='#C4C4C4' color='#fff' size={17} />
                                                <UncontrolledTooltip placement='right' target='WebsiteURLId'>
                                                    link
                                                </UncontrolledTooltip>
                                            </div>
                                        </Label>
                                        <Input
                                            id="Website URL"
                                            name="Website-URL-Name"
                                            type="Website-URL Name"
                                            placeholder='Enter your website URL'
                                            maxLength={60}
                                            onChange={webUrl}
                                            className={'addadress'}
                                        />
                                        <p onClick={() => setMoreUrl(!moreUrl)} className="Biz_Form_more_location pt-1 text-primary text-end">Add more URL</p>
                                       
                                    </FormGroup>
                                    {moreUrl ?
                                        <FormGroup>
                                            <Label
                                                for="Website-URL"
                                                className="BizForm_toggle_section d-flex justify-content-end"
                                            >
                                                <p onClick={() => setMoreUrl(!moreUrl)}>Remove</p>
                                            </Label>
                                            <Input
                                                id="Website URL"
                                                name="Website-URL-Name"
                                                type="Website-URL Name"
                                                placeholder='add your website URL'
                                                maxLength={60}
                                                onChange={webUrl}
                                                className={'addadress'}
                                            />

                                        </FormGroup> : null
                                    } */}
                                    <FormGroup className='d-flex justify-content-between'>
                                    <p style={{ color: "#808080" }} className="Biz_form_tym mt-2">Opening Time </p>
                                    <div className="BizAbout_edit_Icon text-end " >
                                            <img src={Pen1} width={12} className="img-fluid" onClick={toggleOpenPoint2} />
                                        </div>
                                    </FormGroup>
                                    

                                    <FormGroup>
                                        <Label for="Website-URL-0">
                                            <div className="BizInput_heading_section">
                                                <b>Website URL</b>&nbsp;<Info id='WebsiteURLId' fill='#C4C4C4' color='#fff' size={17} />
                                                <UncontrolledTooltip placement='right' target='WebsiteURLId'>
                                                    link
                                                </UncontrolledTooltip>
                                            </div>
                                        </Label>
                                        <Input
                                            id="Website-URL-0" // Use a unique ID
                                            name="Website-URL-Name-0" // Use a unique name
                                            type="text" // Assuming the type should be "text" for a URL input
                                            placeholder='Enter your website URL'
                                            maxLength={60}
                                            onChange={webUrl}
                                            className={'addadress'}
                                        />
                                        {moreUrl ? null : (
                                            <p onClick={() => setMoreUrl(true)} className="Biz_Form_more_location pt-1 text-primary text-end">Add more URL</p>
                                        )}
                                    </FormGroup>
                                    {moreUrl ? (
                                        <FormGroup>
                                            <Label
                                                for="Website-URL-1" // Use a unique ID
                                                className="BizForm_toggle_section d-flex justify-content-end"
                                            >
                                                <p onClick={() => setMoreUrl(false)}>Remove</p>
                                            </Label>
                                            <Input
                                                id="Website-URL-1" // Use a unique ID
                                                name="Website-URL-Name-1" // Use a unique name
                                                type="text" // Assuming the type should be "text" for a URL input
                                                placeholder='Add your website URL'
                                                maxLength={60}
                                                onChange={webUrl1}
                                                className={'addadress'}
                                            />
                                        </FormGroup>
                                    ) : null}



                                    <FormGroup>
                                        <Label
                                            for="Email-Address"
                                        >
                                            <div className="BizInput_heading_section">
                                                <b>Email Address</b>&nbsp;<Info id='EmailAddressId' fill='#C4C4C4' color='#fff' size={17} />
                                                <UncontrolledTooltip placement='right' target='EmailAddressId'>
                                                    Biz Gmail
                                                </UncontrolledTooltip>
                                            </div>
                                        </Label>
                                        <Input
                                            id="Email-Address"
                                            name="Email-Address-Name"
                                            type="Email-Address Name"
                                            placeholder='Enter your email ID'
                                            maxLength={70}
                                            onChange={email}
                                            className={'addadress'}
                                        />
                                        {/* <p className="Biz_Form_more_location pt-1 text-primary text-end" onClick={() => (!moreEmail)}>Add more email</p> */}
                                        {moreEmail ? null : (
                                            <p onClick={() => setMoreEmail(true)} className="Biz_Form_more_location pt-1 text-primary text-end">Add more email</p>
                                        )}
                                    </FormGroup>
                                    {
                                        moreEmail ? (
                                            <FormGroup>
                                                <Label
                                                    for="Website-URL"
                                                    className="BizForm_toggle_section d-flex justify-content-end"
                                                >
                                                    <p onClick={() => setMoreEmail(false)}>Remove</p>
                                                </Label>
                                                <Input
                                                    id="Website URL"
                                                    name="Website-URL-Name"
                                                    type="Website-URL Name"
                                                    placeholder='Enter your email ID'
                                                    maxLength={70}
                                                    className={'addadress'}
                                                    onChange={email1}
                                                // onChange={contactNumber}   
                                                />

                                            </FormGroup>
                                        ) : null
                                    }
                                    <FormGroup>
                                        <Label
                                            for="Contact-Number "
                                        >
                                            <div className="BizInput_heading_section">
                                                <b>Contact Number</b>&nbsp;<Info id='ContactNumberId' fill='#C4C4C4' color='#fff' size={17} />
                                                <UncontrolledTooltip placement='right' target='ContactNumberId'>
                                                    Phone number
                                                </UncontrolledTooltip>
                                            </div>
                                        </Label>
                                        <Input
                                            id="Contact-Number"
                                            name="Contact-Number-Name"
                                            type="text"
                                            placeholder='Enter your contact number '
                                            maxLength={17}
                                            onChange={handleInputChange1}
                                            className={'addadress'}
                                            pattern="[0-9]*" // Add this pattern to allow only numeric input
                                            inputMode="numeric"
                                            onKeyPress={handleKeyPress}
                                            onPaste={handlePaste}

                                        // Specify input mode for numeric input
                                        />
                                        {/* <p className="Biz_Form_more_location pt-1 text-primary text-end" onClick={() => setMoreContact(!)} >Add more contact</p> */}
                                        {moreContact ? null : (
                                            <p onClick={() => setMoreContact(true)} className="Biz_Form_more_location pt-1 text-primary text-end">Add more contact</p>
                                        )}
                                    </FormGroup>
                                    {
                                        moreContact ? (
                                            <FormGroup>
                                                <Label
                                                    for="Website-URL"
                                                    className="BizForm_toggle_section d-flex justify-content-end"
                                                >
                                                    <p onClick={() => setMoreContact(!moreContact)}>Remove</p>
                                                </Label>
                                                <Input
                                                    id="Contact-Number"
                                                    name="Contact-Number-Name"
                                                    type="text"
                                                    placeholder='Enter your contact number '
                                                    maxLength={17}
                                                    onChange={handleInputChange2}
                                                    className={'addadress'}
                                                    pattern="[0-9]*" // Add this pattern to allow only numeric input
                                                    inputMode="numeric"
                                                    onKeyPress={handleKeyPress}
                                                    onPaste={handlePaste}

                                                // Specify input mode for numeric input
                                                />

                                            </FormGroup>
                                        ) : null
                                    }
                                    <FormGroup>
                                        <div className='BizForm_action_section'>
                                            <b>Enable Setup button</b>
                                            <p onClick={toggleSetUp}  >Setup</p>
                                        </div>
                                    </FormGroup>
                                </FormGroup> : null
                        }
                        {/*================== end_Addition_Info_section ==================*/}
                        {/* <hr className='w-100 text-muted' /> */}


                        <FormGroup>
                            <div onClick={() => setShow(!show)} className='d-flex justify-content-center align-items-center  InfoSection ' style={{ cursor: "pointer", position: 'relative', top: '13px' }}>
                                {show ? <>
                                    <ChevronsUp size={16} color="#81C14B" /><div className='d-flex align-items-center'>
                                        <p className='BizForm_toggle_section'>&nbsp;Hide</p>
                                    </div></> : <>

                                    <ChevronsDown size={16} color="#81C14B" />
                                    <p className='BizForm_toggle_section'>&nbsp;Additional Info</p>
                                </>}
                            </div>
                        </FormGroup>
                        {/* ==================start_FOrm_btn_section ================== */}
                        {/* <FormGroup>
                    <div className='text-center'>
                        <Button color="primary" className=" BizCreation_Btn_Fillsection " >Create BizPage</Button>
                        <Button onClick={CancleHandle} color="secondary " className=" ml-2 BizCreation_Btn_section" >Cancel</Button>
                    </div>
                </FormGroup> */}
                        {/* ==================End_FOrm_btn_section ================== */}
                    </Form>
                    {/*================= Start_modal_section========================== */}
                    <div className='Cancel_modal_section'>
                        {cancleModal !== false &&
                            <Modal isOpen={cancleModal} toggle={CancleHandle} className='modal-dialog-centered'>
                                <ModalHeader className='BizCreation_CanCel_modal' >
                                    <h3 >Cancel  BizPage</h3 >
                                </ModalHeader>
                                <ModalBody>
                                    <div className='BizCreation_Content_section text-center py-2'>
                                        <p>Do you really want to cancel the BizPage <br />Creation?</p>
                                    </div>
                                    <div className='text-center pt-3'>
                                        <Button outline color="secondary " className="w-25 BizCreation_ CancleBtn_section" >
                                            Cancel
                                        </Button>
                                        <Button onClick={CancleHandle} color="primary" className="w-25 ml-2" >
                                            Confirm?
                                        </Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        }
                    </div>
                    <div className='Time_modal'>
                {
                    tymOpen !== false &&
                    <Modal isOpen={tymOpen} toggle={toggleTym} className='modal-dialog-centered'>
                        <div className='BizAbout_Heading'>
                            <div

                                onClick={handleCloseModal1}
                                className="Modal_False_blk1"
                            >
                                <XCircle color="#FFFFFF" />
                            </div>
                            <div className='BizAbout_Modal_section p-4 '>
                                <h4>Add/Edit Timing </h4>
                                <p onClick={toggleTym}>Save</p>
                            </div>
                        </div>
                        <div className='p-4 '>
                            <Label className="Bio_text">
                                Starting Time
                            </Label>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Input
                                            name="otp"
                                            type="text"
                                            placeholder="hh"
                                            pattern="[0-9]*"
                                            maxLength={2}
                                            autoComplete="off"
                                            value={hour}
                                            className="Biz_form_input_field"
                                            onChange={startingHour}
                                        />
                                    </div>
                                    &nbsp;&nbsp;:&nbsp;&nbsp;
                                    <div>
                                        <Input
                                            name="otp"
                                            type="text"
                                            placeholder="mm"
                                            pattern="[0-9]*"
                                            maxLength={2}
                                            autoComplete="off"
                                            className="Biz_form_input_field"
                                            value={minute}
                                            onChange={startingMinute}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex ml-4'>
                                    <label className='d-flex align-items-center' htmlFor="radio-buttonsOneId">
                                        <Radio
                                            onChange={handleChangeStartingTime}
                                            value={'AM'}
                                            name="radio-buttonsOne"
                                            id="radio-buttonsOneId"
                                            className='Radio_btn_check'
                                            checked={selectedValue1 === 'AM'}
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                    </label>
                                    <label className='d-flex align-items-center ' >
                                        <Radio
                                            onChange={handleChangeStartingTime}
                                            value={'PM'}
                                            name="radio-buttonsOne"
                                            className='Radio_btn_check'
                                            id="radio-buttonsOneId"
                                            checked={selectedValue1 === 'PM'}
                                            inputProps={{ 'aria-label': 'B' }} />
                                        <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                    </label>
                                </div>
                            </div>
                            <div className='py-2'>
                                <Label className="Bio_text">
                                    Ending Time
                                </Label>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="hh"
                                                maxLength={2}
                                                className="Biz_form_input_field"
                                                autoComplete="off"
                                                value={hour1}
                                                onChange={endingHour}
                                            />
                                        </div>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="mm"
                                                maxLength={2}
                                                className="Biz_form_input_field"
                                                autoComplete="off"
                                                value={minute1}
                                                onChange={endtingMinute}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex ml-4'>
                                        <label className='d-flex align-items-center '>
                                            <Radio
                                                onChange={handleChange}
                                                value={'AM'}
                                                name="radio-buttonsTwo"
                                                className='Radio_btn_check'
                                                checked={selectedValue === 'AM'}
                                                inputProps={{ 'aria-label': 'A' }} />
                                            <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                        </label>
                                        <label className='d-flex align-items-center '>
                                            <Radio
                                                onChange={handleChange}
                                                value={'PM'}
                                                name="radio-buttonsTwo"
                                                className='Radio_btn_check'
                                                checked={selectedValue === 'PM'}
                                                inputProps={{ 'aria-label': 'B' }} />
                                            <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span style={{ cursor: "pointer", color: "#81C14B", font: "16px", fontFamily: "Poppins" }} onClick={addAdditionalHours}>   + Add additional hours</span>


                        </div>

                        {additionalHours.map((additionalHour, index) => (
                            <div className='p-4 '>
                                <Label className="Bio_text">
                                    Starting Time
                                </Label>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="hh"
                                                pattern="[0-9]*"
                                                maxLength={2}
                                                autoComplete="off"
                                                value={additionalHour.hour}
                                                className="Biz_form_input_field"
                                                onChange={(event) => handleStartingHourAdditional(event, index)}
                                            />
                                        </div>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="mm"
                                                pattern="[0-9]*"
                                                maxLength={2}
                                                autoComplete="off"
                                                className="Biz_form_input_field"
                                                value={additionalHour.minute}
                                                onChange={(event) => handleStartingMinuteAdditional(event, index)}

                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex ml-4'>
                                        <label className='d-flex align-items-center' htmlFor="radio-buttonsOneId">
                                            <Radio
                                                onChange={() => handleRadioStartingTimeAdditional('AM', index)}
                                                value={'AM'}
                                                name={`radio-starting-time-${index}`}
                                                className='Radio_btn_check'
                                                checked={additionalHour.selectedValue1 === 'AM'}
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                        </label>
                                        <label className='d-flex align-items-center ' >
                                            <Radio
                                                onChange={() => handleRadioStartingTimeAdditional('PM', index)}
                                                value={'PM'}
                                                name={`radio-starting-time-${index}`}
                                                className='Radio_btn_check'
                                                checked={additionalHour.selectedValue1 === 'PM'}
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                        </label>
                                    </div>
                                </div>
                                <div className='py-2'>
                                    <Label className="Bio_text">
                                        Ending Time
                                    </Label>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <Input
                                                    name="otp"
                                                    type="text"
                                                    placeholder="hh"
                                                    maxLength={2}
                                                    className="Biz_form_input_field"
                                                    autoComplete="off"
                                                    value={additionalHour.hour1}
                                                    onChange={(event) => handleEndingHourAdditional(event, index)}
                                                />
                                            </div>
                                            &nbsp;&nbsp;:&nbsp;&nbsp;
                                            <div>
                                                <Input
                                                    name="otp"
                                                    type="text"
                                                    placeholder="mm"
                                                    maxLength={2}
                                                    className="Biz_form_input_field"
                                                    autoComplete="off"
                                                    value={additionalHour.minute1}
                                                    onChange={(event) => handleEndingMinuteAdditional(event, index)}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex ml-4'>
                                            <label className='d-flex align-items-center '>
                                                <Radio
                                                    onChange={() => handleRadioEndingTimeAdditional('AM', index)}
                                                    value={'AM'}
                                                    name={`radio-ending-time-${index}`}
                                                    className='Radio_btn_check'
                                                    checked={additionalHour.selectedValue === 'AM'}
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                                <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                            </label>
                                            <label className='d-flex align-items-center '>
                                                <Radio
                                                    onChange={() => handleRadioEndingTimeAdditional('PM', index)}
                                                    value={'PM'}
                                                    name={`radio-ending-time-${index}`}
                                                    className='Radio_btn_check'
                                                    checked={additionalHour.selectedValue === 'PM'}
                                                    inputProps={{ 'aria-label': 'B' }}
                                                />
                                                <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <span
                                    style={{ cursor: "pointer", color: "red", font: "14px", fontFamily: "Poppins" }}
                                    onClick={() => removeAdditionalHours(index)}
                                >
                                    Remove this field
                                </span>
                            </div>
                        ))}



                    </Modal>
                }

            </div>
                    <SetUpActionModal
                        isOpen={actionSetUp}
                        updateOpen={toggleSetUp}
                    />
                    <Timemodal toggleOpenPoint2={toggleOpenPoint2} setIsOpen4={setIsOpen4} isOpen4={isOpen4}
                     toggleTym={toggleTym} setTymOpen={setTymOpen} tymOpen={tymOpen} 
                     />
                    {/*================= end_modal_section========================== */}
                </Fragment>
            </Container >
           
        </>
    )
}

export default CreationLeftSide