import React, { Fragment } from 'react'
import Header from '../../Header';
import LeftSidebar from '../../LeftSidebar';
import RightSidebar from '../../RightSidebar';
import '../style.css'
import GroupDetailMenu from './GroupDetailMenu';
import GroupDetailsTop from './GroupDetailsTop';
import Private from '../Img/private-profile.png'

const GroupPending = () => {
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <GroupDetailsTop />
                    {/* <GroupDetailMenu /> */}
                    <div className='Group_Pending_Screen_Section mt-3 mb-3'>
                        <div className='abccdrwe'>
                            <img src={Private} alt="private profile" width={150} height={150} className='img-fluid' />
                            <h4>This group is private</h4>
                            <p>Join this group to view & participate in discussions</p>
                        </div>
                    </div>

                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}

export default GroupPending