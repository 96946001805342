import React, { useState } from 'react'
import { Checkbox } from '@mui/material'
import { Filter, Grid, List, Search, } from 'react-feather'
import { Input, DropdownMenu, Button, UncontrolledButtonDropdown, DropdownToggle } from 'reactstrap'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import RightSidebar from '../RightSidebar'
import './Bookmark.css'
import '../post-components/display-post/displaypost.css'
import BookMarkGridandList from './BookMarkGridandList'
import BookMarkCardSection from './BookMarkCardSection'
const Bookmarks = () => {

    const [options, setOptions] = useState([

        { label: 'Media', value: 'Media', isChecked: false },
        { label: 'Go Live', value: 'Go Live', isChecked: false },
        { label: 'Podcast', value: 'Podcast', isChecked: false },
        { label: 'Shotz', value: 'Shotz', isChecked: false },
        { label: 'Recommendation', value: 'Recommendation', isChecked: false },
        { label: 'Threat', value: 'Threat', isChecked: false },
        { label: 'Thought', value: 'Thought', isChecked: false },
        { label: 'Event', value: 'Event', isChecked: false },
        { label: 'Article', value: 'Article', isChecked: false },
        { label: 'Poll', value: 'Poll', isChecked: false },
        { label: 'Sell', value: 'Sell', isChecked: false },
    ]);

    const [selectAll, setSelectAll] = useState(false);

    {/* ------------------------State and function for Enabling/Disabling date wise sorting----------------------- */ }
    const [dateApplied, setdateApplied] = useState(false);
    const onApply = () => {
        setdateApplied(true);
        setcalendarClk((prevState) => !prevState);
    }

    const oncancelApply = () => {
        setdateApplied(false);
        setcalendarClk((prevState) => !prevState);
    }

    const [calendarClk, setcalendarClk] = useState(false);
    const togglecalendarClk = () => {
        setcalendarClk((prevState) => !prevState);
    }


    const now = new Date();
    const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const [value, onChange] = useState([yesterdayBegin, todayEnd]);

    const [data, setdata] = useState(true)


    const handleChange = (index) => {
        const updatedOptions = [...options];
        updatedOptions[index].isChecked = !updatedOptions[index].isChecked;
        setOptions(updatedOptions);
    };
    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setOptions(
            options.map((option) => ({
                ...option,
                isChecked: !selectAll,
            }))
        );
    };

    {/* ------------------------------Function for formatting Date range----------------------------------- */ }
    const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${day}/${month}/${year}`;
    }

    // const Menubar = () => {
    //     const [anchorEl, setAnchorEl] = React.useState(null);



    //     const handleClick = (event) => {
    //         setAnchorEl(event.currentTarget);
    //     };

    //     const handleClose = () => {
    //         setAnchorEl(null);
    //     };
    //     return (
    //         <>
    //             <div>
    //                 <MoreHorizontal aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className='Vertical' />
    //                 <Menu
    //                     id="simple-menu"
    //                     anchorEl={anchorEl}
    //                     keepMounted
    //                     open={Boolean(anchorEl)}
    //                     onClose={handleClose}
    //                 >
    //                     <MenuItem onClick={handleClose}> Hide Post from Timelines</MenuItem>
    //                     <MenuItem onClick={handleClose}> Turn of post notification </MenuItem>
    //                     <MenuItem onClick={handleClose}> Share via   </MenuItem>
    //                     <MenuItem onClick={handleClose}> Copy link </MenuItem>
    //                     <MenuItem onClick={handleClose}> Follow “Jackline Barby” </MenuItem>
    //                     <MenuItem onClick={handleClose}> Block  “Jackline Barby” </MenuItem>
    //                     <MenuItem onClick={handleClose}> Report  </MenuItem>
    //                 </Menu>
    //             </div>
    //         </>
    //     )
    // }
    return (
        <div>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className='BookmarkThisMainCard'>
                        <div className='d-flex justify-content-between px-3 py-2 align-items-center' >
                            <h4 style={{fontSize:'20px'}}>Bookmark (160)</h4>&nbsp;&nbsp;

                            <div className=' d-flex justify-content-between align-items-center'>
                                <div className=" mr-3 px-2 border rounded d-flex align-items-center ">
                                    <Search className="" color='#808080' size={18} />
                                    <Input 
                                        type="text"
                                        className=" border-0 searchbookmark"
                                        placeholder="Search bookmark..."
                                    />
                                </div>
                                {/* new filter with date  */}
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' className="BookmarkFilterButton"  style={{marginLeft:'-0.6rem'}}>
                                        <Filter size={16} />
                                    </DropdownToggle>
                                    <DropdownMenu className=" border-0 p-1  bookmarKDropDownMenu" style={{ minWidth: "fit-content" }}>
                                        {/* <DateRangePicker
                                            calendarAriaLabel="Toggle calendar"
                                            clearAriaLabel="Clear value"
                                            dayAriaLabel="Day"
                                            monthAriaLabel="Month"
                                            nativeInputAriaLabel="Date"
                                            onChange={onChange}
                                            value={value}
                                            yearAriaLabel="Year"
                                        />
                                        <div className='BookmarkFilterContentDropdown py-2'>
                                            <div onClick={handleSelectAll} className='cursor-p px-3 HoverE6_81 d-flex align-items-center justify-content-between'>
                                                <p>All</p>
                                                <Checkbox
                                                    className='Check_Input_section'
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    size='small'
                                                />
                                            </div>
                                            {options.map((option, index) => (
                                                <div key={option.value} onClick={() => handleChange(index)} className="cursor-p px-3 HoverE6_81 d-flex align-items-center justify-content-between">
                                                    <p>{option.label}</p>
                                                    <Checkbox
                                                        className='Check_Input_section'
                                                        checked={option.isChecked}
                                                        onChange={() => handleChange(index)}
                                                        size='small'
                                                    />
                                                </div>
                                            ))}
                                        </div> */}
                                        <div className='p-2'>
                                            <DateRangePicker
                                                calendarAriaLabel="Toggle calendar"
                                                clearAriaLabel="Clear value"
                                                dayAriaLabel="Day"
                                                monthAriaLabel="Month"
                                                nativeInputAriaLabel="Date"
                                                // onChange={(newRange) => onChange(newRange)}
                                                onChange={onChange}
                                                value={value}
                                                yearAriaLabel="Year"
                                            />
                                            <div className='mt-2 d-flex align-items-center justify-content-between' >
                                                <Button color='primary' outline onClick={onApply} >Apply</Button>
                                                <Button color='danger' outline className=' ml-2' onClick={oncancelApply}  >Cancel</Button>
                                            </div>
                                        </div>
                                        <div className='BookmarkFilterContentDropdown p-2'>
                                            <div onClick={handleSelectAll} className='cursor-p px-3 HoverE6_81 d-flex align-items-center justify-content-between'>
                                                <p>All</p>
                                                <Checkbox
                                                    className='Check_Input_section'
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    size='small'
                                                />
                                            </div>
                                            {options.map((option, index) => (
                                                <div key={option.value} onClick={() => handleChange(index)} className="cursor-p px-3 HoverE6_81 d-flex align-items-center justify-content-between">
                                                    <p>{option.label}</p>
                                                    <Checkbox
                                                        className='Check_Input_section'
                                                        checked={option.isChecked}
                                                        onChange={() => handleChange(index)}
                                                        size='small'
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <div className='GridViewListView_Conatainer ml-2 rem-mob-view'>
                                    <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                                        <List size={16} />
                                    </Button>
                                    <Button color='primary' outline className={`ml-2 ${data ? '' : 'GridButtonView'}`} onClick={() => setdata(false)}>
                                        <Grid size={16} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='Today' style={{ textAlign: 'right' }} ><p className='p-2'>{dateApplied ? `Showing Bookmarked result from ${formatDateString(value[0])} to ${formatDateString(value[1])} ` : null}</p></div>
                    </div>
                    <BookMarkGridandList data={data} />
                </div>
                <RightSidebar />
            </div>

        </div>
    )
}

export default Bookmarks