import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ChevronRight, Globe, Users } from 'react-feather'
import { FormControl, FormControlLabel, MenuItem, Select } from '@mui/material'
import Radio from '@mui/material/Radio';
import { Button } from 'react-bootstrap'
import UserList from './UserList';

const StorySettingModal = (props) => {
    const [selectedValue, setSelectedValue] = React.useState('a');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    // ------------- User list modal -------------
    const [userListsModal, setUserListsModal] = useState(false)
    const toggleUserListsModal = () => {
        setUserListsModal((prevState) => !prevState)
    }


    const options = [
        { img: { Globe }, label: 'Public', value: 'a', img: <Globe color='#808080' size={20} /> },
        { img: { Users }, label: 'Connections', value: 'b', img: <Users color='#808080' size={20} /> },
        { img: { Users }, label: 'Colleagues', value: 'c', img: <Users color='#808080' size={20} /> },
        { img: { Users }, label: 'Family', value: 'd', img: <Users color='#808080' size={20} /> },
        { img: { Users }, label: 'Friends', value: 'e', img: <Users color='#808080' size={20} /> },
        { img: { Users }, label: 'Relatives', value: 'f', img: <Users color='#808080' size={20} /> },
    ];

    return (
        <>
            <UserList
                isOpen={userListsModal}
                userListModalOpen={toggleUserListsModal} />

            {
                props.isOpen !== false &&
                <Modal scrollable isOpen={props.isOpen} className='modal-dialog-centered'  >
                    <ModalHeader className='border-bottom font-weight-bold StoryThisPrivacy'>
                        <h3 className='Fw_500'>Story Privacy</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className='py-1'>
                            <h4 className='Fw_500'>Who can see your story?</h4>
                            <h6 className='Fw-500 mt-2'>Your story will be visible for 24 hs on SocioMee and MsgMee.</h6>
                        </div>
                        {options.map((option) => (
                            <div key={option.value} className='d-flex justify-content-between align-items-center cursor-p ' onClick={() => setSelectedValue(option.value)}>
                                <div className='d-flex ml-2'>
                                    {option.img}
                                    <h5 className='ml-4 align-self-center'>{option.label}</h5>
                                </div>
                                <FormControlLabel
                                    value={option.value}
                                    control={
                                        <Radio
                                            checked={selectedValue === option.value}
                                            onChange={handleChange}
                                            value={option.value}
                                            name="radio-buttons"
                                            inputProps={{ 'aria-label': option.label }}
                                        />
                                    }
                                />
                            </div>
                        ))}
                        <FormControl fullWidth className='mt-3'>
                            <Select className='border' defaultValue='None' inputProps={{ 'aria-label': 'Without label' }}>
                                <MenuItem value="None" ><p style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "400" }}>Up To Days</p></MenuItem>
                                <MenuItem value="Arial"><p style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "400" }}>1 Day</p></MenuItem>
                                <MenuItem value="Times New Roman"><p style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "400" }}>2 Day</p></MenuItem>
                                <MenuItem value="Courier New"><p style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "400" }}>3 Day</p></MenuItem>
                                <MenuItem value="Verdana"><p style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "400" }}>4 Day</p></MenuItem>
                                <MenuItem value="Georgia"><p style={{ fontFamily: "poppins", fontSize: "13px", fontWeight: "400" }}>5 Day</p></MenuItem>
                            </Select>
                        </FormControl>
                        <div className='d-flex justify-content-between align-items-center my-3'>
                            <div className='d-flex'>
                                <Users color='#808080' className='mt-1' size={20} />
                                <div className='p-1 ml-3'>
                                    <h5 style={{ color: '#4D4D4D' }}>Hide story from</h5>
                                    <p className='mt-2'>Anshu Singh Parihar, Sakim Khan, Riya singh and 3 others</p>
                                </div>
                            </div>
                            <p onClick={toggleUserListsModal} ><ChevronRight color='#808080' size={30} /></p>
                        </div>
                        <div className='d-flex justify-content-center align-items-center my-3'>




                            <p className='mt-2 addrealtion'>Add Relation Category</p>



                        </div>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center' >
                        <Button variant='outline-secondary' className='mr-2' onClick={props.settingModal}>Cancel</Button>
                        <Button variant='primary' onClick={props.settingModal}>Save</Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default StorySettingModal