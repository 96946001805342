import * as types from "../../Constants/Events/index";


const initialState = {
    createEvents: [],
    loading: true,
    
  };

    export const createEventsReducer = (state = initialState, action) => {
        switch (action.type) {
            case types.CREATE_EVENT_POST_BY_USER:
                return {
                    ...state,
                    createEvents:action.payload,
                    loading: false
                };
                default:
                    return state
                }
        };
    