import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Alert from './display/Sub-component/Alert'
import Buttons from './display/Sub-component/Buttons'
import Event from './display/Sub-component/Event'
import Media from './display/Sub-component/Media'
import Podcast from './display/Sub-component/Podcast'
import Poll from './display/Sub-component/Poll'
import Recommendations from './display/Sub-component/Recommendations'
import Sell from './display/Sub-component/Sell'
import Thought from './display/Sub-component/Thought'
import UniversalCard from './display/Sub-component/UniversalCard'

const AllPostDisplayNav = () => {
    const [active, setActive] = useState('1')

    const toggle = tab => {
        setActive(tab)
    }
    return (
        <React.Fragment>
            <Nav className='justify-content-start' pills>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Media
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Thought
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Alert
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '4'}
                        onClick={() => {
                            toggle('4')
                        }}
                    >
                       Recommendations
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '5'}
                        onClick={() => {
                            toggle('5')
                        }}
                    >
                       Poll
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '6'}
                        onClick={() => {
                            toggle('6')
                        }}
                    >
                       Podcast
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '7'}
                        onClick={() => {
                            toggle('7')
                        }}
                    >
                       Event
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '8'}
                        onClick={() => {
                            toggle('8')
                        }}
                    >
                       Sell
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '9'}
                        onClick={() => {
                            toggle('9')
                        }}
                    >
                       Universal-Card
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '10'}
                        onClick={() => {
                            toggle('10')
                        }}
                    >
                       Buttons
                    </NavLink>
                </NavItem>
            </Nav>
            <div className='p-2 mt-2'>
                <TabContent className='py-50' activeTab={active}>
                    <TabPane tabId='1'>
                        <Media />
                    </TabPane>
                    <TabPane tabId='2'>
                        <Thought />
                    </TabPane>
                    <TabPane tabId='3'>
                       <Alert />
                    </TabPane>
                    <TabPane tabId='4'>
                        <Recommendations />
                    </TabPane>
                    <TabPane tabId='5'>
                        <Poll />
                    </TabPane>
                    <TabPane tabId='6'>
                        <Podcast />
                    </TabPane>
                    <TabPane tabId='7'>
                        <Event />
                    </TabPane>
                    <TabPane tabId='8'>
                        <Sell />
                    </TabPane>
                    <TabPane tabId='9'>
                        <UniversalCard />
                    </TabPane>
                    <TabPane tabId='10'>
                        <Buttons />
                    </TabPane>
                </TabContent>
            </div>
        </React.Fragment>
    )
}
export default AllPostDisplayNav