import React, { useState } from 'react'
import { Download, Eye, MessageCircle, MoreVertical, Music, Send, Copy, Edit, EyeOff, Trash2, Grid, List, CornerUpLeft, Archive, CheckCircle, ChevronLeft, Upload } from 'react-feather'
import { Card, Col, UncontrolledTooltip, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, Button } from 'reactstrap'
import like from '../../../NewComponents/IMG/like.png'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import RightSidebar from '../../RightSidebar'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import { ArchivedModal, ShotzPopup } from '../ArchivedModal'
import { ShotzListView } from '../shotz_list_view/ShotzListView'
import { NavLink } from 'react-router-dom'
import { EditModal } from '../ShotzModal/EditModal';
import ShareModal from '../ShotzModal/ShareModal';
import DeletedModal from '../ShotzModal/DeletedModal';
import ArchiveModal from '../ShotzModal/ArchiveModal';
import HideModal from '../ShotzModal/HideModal';
import { ShotzCarouselComponents } from './ShotzCarouselComponents';
import DownloadModal from '../ShotzModal/DownloadModal';
import { SingleDeleteModal } from '../ShotzModal/SingleDeleteModal';
import '../../Shotzs/ShotzStyle.css'
import { useUserAuth } from '../../../Context/userAuthContext';
import { Checkbox } from '@mui/material';

export const ArchivedShotzList = () => {

    const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();

    const [Gridview, Setgridview] = useState(false)
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    //  ------------- Num Formater  -------------
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    {/*  ------------- End Share Modal ------------- */ }
    // const [Shotzdata, setShotzdata] = useState([

    //     {
    //         Shotzs: 'https://i.pinimg.com/originals/a3/59/dc/a359dc62f2e2468f758f26851b4762a6.jpg',
    //         num: '12000',
    //         songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
    //         icon1: <Music size={22} color='#FFFFFF' />,
    //         num1: '1000',
    //         num2: '30000',
    //         num3: '23000'

    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/originals/b0/8a/ba/b08abab76c4c0bfcdc2adca5e938ae98.jpg',
    //         num: '14000',
    //         num1: '20000',
    //         num2: '33000',
    //         num3: '28000'
    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/originals/0f/7e/61/0f7e6156500b54f20c4a2cfc72902ce7.jpg',
    //         num: '13000',
    //         songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
    //         icon1: <Music size={22} />,
    //         num1: '20000',
    //         num2: '34000',
    //         num3: '22000'
    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/originals/29/10/58/291058e0b7555376c50bec7ea77d4c01.jpg',
    //         num: '12000',
    //         songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
    //         icon1: <Music size={22} color='#FFFFFF' />,
    //         num1: '1000',
    //         num2: '30000',
    //         num3: '23000'

    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/736x/2b/a1/b6/2ba1b6617b8ff7a8ba885e4d10b5a24e.jpg',
    //         num: '14000',
    //         num1: '20000',
    //         num2: '33000',
    //         num3: '28000'
    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/564x/6d/26/31/6d263111aadc9b1788d176e59a01be9a.jpg',
    //         num: '13000',
    //         songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
    //         icon1: <Music size={22} />,
    //         num1: '20000',
    //         num2: '34000',
    //         num3: '22000'
    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/originals/d4/3f/11/d43f11f485c862c19e88de91b1922863.jpg',
    //         num: '12000',
    //         songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
    //         icon1: <Music size={22} color='#FFFFFF' />,
    //         num1: '1000',
    //         num2: '30000',
    //         num3: '23000'

    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/originals/a6/02/cb/a602cb096c09a3311add6cc92058b3cb.jpg',
    //         num: '14000',
    //         num1: '20000',
    //         num2: '33000',
    //         num3: '28000'
    //     },
    //     {
    //         Shotzs: 'https://images.statusfacebook.com/profile_pictures/fb_beautiful_girl/cute_fb_beautiful_girl_03.jpg',
    //         num: '14000',
    //         num1: '20000',
    //         num2: '33000',
    //         num3: '28000'
    //     },
    //     {
    //         Shotzs: 'https://i.pinimg.com/736x/a0/dd/ee/a0ddee56938d2a43df725e6831201508.jpg',
    //         num: '14000',
    //         num1: '20000',
    //         num2: '33000',
    //         num3: '28000'
    //     },
    //     {
    //         Shotzs: 'https://loveshayariimages.in/wp-content/uploads/2022/07/Girls-dp-for-whatsapp-1.jpg',
    //         num: '13000',
    //         songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
    //         icon1: <Music size={22} />,
    //         num1: '20000',
    //         num2: '34000',
    //         num3: '22000'
    //     },
    //     {
    //         Shotzs: 'https://loveshayariimages.in/wp-content/uploads/2022/07/cute-girls-image-download.jpg',
    //         num: '20000',
    //         num1: '2400',
    //         num2: '3900',
    //         num3: '2600'
    //     }
    // ]);
    const [Shotzdata, setShotzdata] = useState([
        {
            Shotzs: 'https://i.pinimg.com/originals/a3/59/dc/a359dc62f2e2468f758f26851b4762a6.jpg',
            num: '12000',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            icon1: <Music size={22} color='#FFFFFF' />,
            num1: '1000',
            num2: '30000',
            num3: '23000',
            id: 1,
            selected: false
        },
        {
            Shotzs: 'https://i.pinimg.com/originals/b0/8a/ba/b08abab76c4c0bfcdc2adca5e938ae98.jpg',
            num: '14000',
            num1: '20000',
            num2: '33000',
            num3: '28000',
            id: 2,
            selected: false
        },
        {
            Shotzs: 'https://i.pinimg.com/originals/0f/7e/61/0f7e6156500b54f20c4a2cfc72902ce7.jpg',
            num: '13000',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            icon1: <Music size={22} />,
            num1: '20000',
            num2: '34000',
            num3: '22000',
            id: 3,
            selected: false
        },
        {
            Shotzs: 'https://www.imagesplatform.com/mohsin_site/uploads/2021_12_13/handsome-munda-pic-ideas.jpg',
            num: '12000',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            icon1: <Music size={22} color='#FFFFFF' />,
            num1: '1000',
            num2: '30000',
            num3: '23000',
            id: 4,
            selected: false
        },
        {
            Shotzs: 'https://1fid.com/wp-content/uploads/2022/02/boy-dp-image-77-1024x1002.jpg',
            num: '14000',
            num1: '20000',
            num2: '33000',
            num3: '28000',
            id: 5,
            selected: false
        },
        {
            Shotzs: 'https://i.pinimg.com/564x/46/48/3b/46483b1f0c55ee6ecf9277666ba33378.jpg',
            num: '13000',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            icon1: <Music size={22} />,
            num1: '20000',
            num2: '34000',
            num3: '22000',
            id: 6,
            selected: false
        },
        {
            Shotzs: 'https://i.pinimg.com/564x/6d/26/31/6d263111aadc9b1788d176e59a01be9a.jpg',
            num: '12000',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            icon1: <Music size={22} color='#FFFFFF' />,
            num1: '1000',
            num2: '30000',
            num3: '23000',
            id: 7,
            selected: false
        },
        {
            Shotzs: 'https://loveshayariimages.in/wp-content/uploads/2022/07/Girls-dp-for-whatsapp-1.jpg',
            num: '14000',
            num1: '20000',
            num2: '33000',
            num3: '28000',
            id: 8,
            selected: false
        },
        {
            Shotzs: 'https://loveshayariimages.in/wp-content/uploads/2022/07/cute-girls-image-download.jpg',
            num: '14000',
            num1: '20000',
            num2: '33000',
            num3: '28000',
            id: 9,
            selected: false
        },
        {
            Shotzs: 'https://images.statusfacebook.com/profile_pictures/fb_beautiful_girl/cute_fb_beautiful_girl_03.jpg',
            num: '14000',
            num1: '20000',
            num2: '33000',
            num3: '28000',
            id: 10,
            selected: false
        },
        {
            Shotzs: 'https://i.pinimg.com/564x/46/48/3b/46483b1f0c55ee6ecf9277666ba33378.jpg',
            num: '13000',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            icon1: <Music size={22} />,
            num1: '20000',
            num2: '34000',
            num3: '22000',
            id: 11,
            selected: false
        },
        {
            Shotzs: 'https://i.pinimg.com/564x/6d/26/31/6d263111aadc9b1788d176e59a01be9a.jpg',
            num: '12000',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            icon1: <Music size={22} color='#FFFFFF' />,
            num1: '1000',
            num2: '30000',
            num3: '23000',
            id: 12,
            selected: false

        },
    ]);
    // Single Select and Multipal select start

    const allSelected = Shotzdata.every((data) => data.selected);

    const handleCardClick = (id) => {
        setShotzdata((prevCards) =>
            prevCards.map((data) =>
                data.id === id ? { ...data, selected: !data.selected } : data
            )
        );

        const flst = Shotzdata.filter((element) => element.id === id ? !element.selected : element.selected);
        if (flst.length > 0) {
            setisDisabled(false);
        } else {
            setisDisabled(true);
        }
    };
    const handleSelectAllClick = () => {
        setShotzdata((prevCards) =>
            prevCards.map((data) => ({ ...data, selected: true }))
        );
        setCheck((prevState) => !prevState);
        setisDisabled(false);
    };

    const handleDeselectAllClick = () => {
        setShotzdata((prevCards) =>
            prevCards.map((data) => ({ ...data, selected: false }))
        );
        setCheck((prevState) => !prevState);
        setisDisabled(true);
    };
    // const handleSelectAllClick = () => {
    //     setShotzdata((prevCards) =>
    //         prevCards.map((data) => ({ ...data, selected: true }))
    //     );
    //     setCheck((prevState) => !prevState)
    // };

    // const handleDeselectAllClick = () => {
    //     setShotzdata((prevCards) =>
    //         prevCards.map((data) => ({ ...data, selected: false }))
    //     );
    //     setCheck((prevState) => !prevState)
    // };

    // Single Select and Multipal select end
    const [downloadModalOpen, setDownloadModalOpen] = useState(false)
    const toggleDownloadModalOpen = () => {
        setDownloadModalOpen((prevState) => !prevState)
    }
    // ------------- Download Modal State -------------
    // const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }

    // -------------Single Download Modal State -------------
    const [singleDownload, setSingleDownload] = useState(false)
    const toggleSingleDownload = () => {
        setSingleDownload((prevState) => !prevState)
    }
    //  ------------- Delete Modal State -------------
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModalOpen = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }

    // -------------Single Delete Modal State -------------
    const [singleDelete, setSingleDelete] = useState(false)
    const toggleSingleDelete = () => {
        setSingleDelete((prevState) => !prevState)
    }

    //  ------------- Archive Modal State -------------
    const [archiveModalOpen, setArchiveModalOpen] = useState(false)
    const toggleArchiveModalOpen = () => {
        setArchiveModalOpen((prevState) => !prevState)
    }
    // ------------- Archive Modal State  -------------
    const [hideShotzModalOpen, setHideShotzModalOpen] = useState(false)
    const toggleHideShotzModalOpen = () => {
        setHideShotzModalOpen((prevState) => !prevState)
    }
    // ------------- Single Remove from Archive Modal State  -------------
    const [removeSingle, setRemoveSingle] = useState(false)
    const toggleRemoveSingle = () => {
        setRemoveSingle((prevState) => !prevState)
    }
    //  ------------- Edit Modal State -------------
    const [editModalOpen, setEditModalOpen] = useState(false)
    const toggleEditModalOpen = () => {
        setEditModalOpen((prevState) => !prevState)
    }
    const [Check, setCheck] = useState(false)
    const [HideShotz, setHideShotz] = useState(false)

    const [isSelectActive, setIsSelectActive] = useState("");
    const [selectedShotz, setSelectedShotz] = useState([]);
    // const isDisabled = allSelected;
    // const [isSelectActive, setIsSelectActive] = useState(false);
    const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
    const [isSolveDisabled, setIsSolveDisabled] = useState(false);
    // ============================add disable =======================
    const [isDisabled, setisDisabled] = useState(!isSelectActive);
    const handleSelectClick = () => {
        setIsSelectActive(!isSelectActive);
        setIsDownloadDisabled(!isSelectActive); // Disable download button when Select is active
        setIsDeleteDisabled(!isSelectActive);   // Disable delete button when Select is active
        setIsSolveDisabled(!isSelectActive);    // Disable solve button when Select is active
        // Add more buttons if needed
    };
    const GridViewSection = () => {
        // ===========================main section start=============================
        return (
            <>
                <div className='nav-flex-container mx-2 my-2'>
                    <div className='d-flex align-items-center justify-content-between' >
                        <div>
                            {/* <NavLink to='/Shotz' className='archive-button'> */}
                            <NavLink to='/ExploreShotz' className='archive-button'>
                                {/* <Button className='archive-button' color='primary' outline variant="outline-primary"> */}
                                <ChevronLeft size={17} />
                                {/* </Button> */}
                            </NavLink>
                        </div>
                        <div className='px-3' >
                            {!Check ?
                                <h3 style={{ cursor: 'pointer' }}><strong> Shotz Archived&nbsp; </strong><span  > {`(${Shotzdata.length})`}</span></h3>
                                // style={{ color: '#81c41b' }}
                                :
                                <h3 style={{ cursor: 'pointer' }}><span> {`${Shotzdata.length})`}</span> Shotz Selected</h3>
                            }
                        </div>
                    </div>
                    <div className='nav-flex-container size-sm-mt-4 mt-2'>
                        <div className="profile-search-blk size-sm-mt-4 mr-1">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                            <input type="text" className="form-control shotz-text" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                        </div>
                        {!isSelectActive ? (
                            <div className='size-sm-mt-4 select-btn-text' style={{ marginLeft: '6px' }}>
                                <Button 
                                    onClick={() => setIsSelectActive(!isSelectActive)}
                                    // variant="outline-primary"
                                    color='primary'
                                    outline

                                >
                                    Select
                                </Button>
                            </div>
                        ) : (
                            <>
                                {/* ==============================add all button=================================== */}
                                <div className='nav-flex-containers size-sm-mt-4' >
                                    <div>
                                        <Button
                                            outline
                                            className={`btn-icon${isDisabled ? ' disabled' : ''}`}
                                            size='sm'
                                            id='positionBottomArchive'
                                            color={isDisabled ? 'secondary' : 'primary'}
                                            onClick={toggleArchiveModalOpen}
                                            disabled={isDisabled}
                                        >
                                            {/* <Archive size={18} /> */}
                                            <Upload size={18} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='positionBottomArchive'>
                                            <h6>UnArchive</h6>
                                        </UncontrolledTooltip>
                                    </div>
                                    <div>
                                        <Button outline className='btn-icon mx-1' size='sm' id='positionBottomDelete' onClick={toggleDeleteModalOpen} color={isDisabled ? 'secondary' : 'primary'} disabled={isDisabled}>
                                            <Trash2 size={18} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='positionBottomDelete'>
                                            <h6>Delete</h6>
                                        </UncontrolledTooltip>
                                    </div>
                                    <div>
                                        <Button outline className='btn-icon' size='sm' id='positionBottomSend' onClick={toggleForward} color={isDisabled ? 'secondary' : 'primary'} disabled={isDisabled}>
                                            <Send size={18} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='positionBottomSend'>
                                            <h6>Share to</h6>
                                        </UncontrolledTooltip>
                                    </div>
                                    <div className='mx-1'>
                                        <Button outline className='btn-icon' size='sm' id='positionBottomDownload' onClick={toggleDownloadModalOpen} color={isDisabled ? 'secondary' : 'primary'} disabled={isDisabled}>
                                            <Download size={18} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='positionBottomDownload'>
                                            <h6>Download</h6>
                                        </UncontrolledTooltip>

                                    </div>
                                </div>
                                <div className='size-sm-mt-4' style={{ marginLeft: '-2px' }} >
                                    {/* select-text-buttons */}
                                    <Button
                                        outline
                                        className='mx-1'
                                        color='primary'
                                        onClick={() => {
                                            if (allSelected === false) {
                                                handleSelectAllClick();
                                            } else {
                                                handleDeselectAllClick();
                                            }
                                        }}
                                    >
                                        {!allSelected ? 'Select All' : 'Unselect All'}
                                    </Button>
                                    {/* <Button
                                        outline
                                        color='primary'
                                        onClick={handleDeselectAllClick} disabled={!allSelected}
                                    > Unselect All</Button> */}
                                    {/* ==================================add button Cancel============================== */}
                                    <Button
                                        outline
                                        color='primary'
                                        onClick={() => {
                                            setIsSelectActive(!isSelectActive);
                                            setSelectedShotz([]);

                                            // Get all elements with the class .shotz-box-selected
                                            const selectedElements = document.querySelectorAll('.shotz-box-selected');

                                            // Loop through the selected elements and remove the class
                                            selectedElements.forEach(element => {
                                                element.classList.remove('shotz-box-selected');
                                            });

                                            setCheck(true);
                                            handleDeselectAllClick();
                                        }}
                                        className=''
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const handleCheckboxChange = () => {

    };
    return (
        <>
            <ShareModal
                isOpen={forwardOpen}
                shareModalToOpen={toggleForward}
            />
            {/* ------------- Top Header ------------- */}
            <Header />
            <div className="page-body container-fluid newsfeed-style1">
                {/* ------------- Left SideBar ------------- */}
                <LeftSidebar />
                <div className="page-center">
                    {/* <Card className='border-0'> */}
                    <ShareModal
                        isOpen={forwardOpen}
                        shareModalToOpen={toggleForward}
                    />
                    <Card className='border-0'>
                        <GridViewSection />
                        {
                            Gridview ?
                                <ShotzListView Check={Check} />
                                :
                                <Row className='AllTagsThis'>
                                    {
                                        Shotzdata.map((data, onSelect) => {
                                            return (
                                                <Col key={data.id} onChange={() => handleCardClick(data.id)} xl='3' lg='4' md='6' sm='12' xs='12' className='p-1 mt-1'>
                                                    <div className={data.selected ? "shotz-box-selected media-images-blk shotz-album-block align-items-center" : "shotz-box media-images-blk shotz-album-block align-items-center"}>
                                                        <div className="main-shotz-blk-list">
                                                            <figure className="shotz-card-hover m-0">
                                                                <div className='gradient-overlay'></div>
                                                                <img className="gallery-img img-gradient rounded" onClick={toggleShotzCarouselModal} src={data.Shotzs} alt="" />
                                                                <div className="text-gallery d-flex justify-content-center">
                                                                    <div className="top-gallery-cont">
                                                                        <p className='shotz-text'>{HideShotz ? <EyeOff size={20} /> : <Eye size={16} />} {numFormatter(data.num)}</p>
                                                                        {/* <div>
                                                                            {!data.selected ?
                                                                                <UncontrolledButtonDropdown direction='left' className='m-0 p-0'>
                                                                                    <DropdownToggle color='flat' className='m-0 p-0'>
                                                                                        <MoreVertical role="button" color='white' size={20} />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu className='obj-container'>
                                                                                        <DropdownItem className='border-bottom  py-2 menu-hover-style' >
                                                                                            <h5><Eye size={15} className='mr-2 icon-color-style' />View</h5>
                                                                                        </DropdownItem>
                                                                                       
                                                                                        <DropdownItem className='border-bottom  py-2 menu-hover-style' onClick={toggleRemoveSingle} >
                                                                                            <h5><Archive size={15} className='mr-2 icon-color-style' />Remove from Archive</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem onClick={toggleEditModalOpen} className=' border-bottom py-2 shotz-menu-hover-style'>
                                                                                            <h5><Edit size={15} className='mr-2 icon-color-style' />Edit</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style'>
                                                                                            <h5><Copy size={15} className='mr-2 icon-color-style' />Copy Link</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style' onClick={toggleSingleDownload} >
                                                                                            <h5><Download size={15} className='mr-2 icon-color-style' />Download</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style' onClick={toggleSingleDelete}>
                                                                                            <h5><Trash2 size={15} className='mr-2 icon-color-style' />Delete</h5>
                                                                                        </DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledButtonDropdown>
                                                                                :
                                                                              
                                                                            }
                                                                        </div> */}
                                                                        {/* <input type="checkbox" checked={data.selected} onChange={handleCheckboxChange} /> */}
                                                                        {/* ====================comment code ================ */}
                                                                        <div>
                                                                            {isSelectActive ? (
                                                                                <input type="checkbox" checked={data.selected} onChange={handleCheckboxChange} />
                                                                            ) : (
                                                                                <UncontrolledButtonDropdown direction='left' className='m-0 p-0'>
                                                                                    <DropdownToggle color='flat' className='m-0 p-0'>
                                                                                        <MoreVertical role="button" color='white' size={20} />
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu className='obj-container'>
                                                                                        <DropdownItem className='border-bottom  py-2 menu-hover-style' >
                                                                                            <h5><Eye size={15} className='mr-2 icon-color-style' />View</h5>
                                                                                        </DropdownItem>

                                                                                        <DropdownItem className='border-bottom  py-2 menu-hover-style' onClick={toggleRemoveSingle} >
                                                                                            <h5><Archive size={15} className='mr-2 icon-color-style' />Remove from Archive</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem onClick={toggleEditModalOpen} className=' border-bottom py-2 shotz-menu-hover-style'>
                                                                                            <h5><Edit size={15} className='mr-2 icon-color-style' />Edit</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style'>
                                                                                            <h5><Copy size={15} className='mr-2 icon-color-style' />Copy Link</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style' onClick={toggleSingleDownload} >
                                                                                            <h5><Download size={15} className='mr-2 icon-color-style' />Download</h5>
                                                                                        </DropdownItem>
                                                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style' onClick={toggleSingleDelete}>
                                                                                            <h5><Trash2 size={15} className='mr-2 icon-color-style' />Delete</h5>
                                                                                        </DropdownItem>
                                                                                    </DropdownMenu>
                                                                                </UncontrolledButtonDropdown>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="center-gallery-cont">
                                                                        <div onClick={toggleShotzCarouselModal}>
                                                                            <img src="assets/images/shotz-icon.png" className='shotzModel' alt='' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="Music-gallery-cont mx-2 py-3">
                                                                        <p className='shotz-text'>{data.icon1}</p>&nbsp;&nbsp;
                                                                        <NavLink to='/UsedAudioShotz'>
                                                                            <h5 className='text-white mr-2'>{data.songs}</h5>
                                                                        </NavLink >
                                                                    </div>
                                                                    <div className="bottom-gallery-cont">
                                                                        <p className='shotz-text'> <img src={like} alt='' />{numFormatter(data.num1)}</p>
                                                                        <p className='shotz-text'><MessageCircle size={16} />{numFormatter(data.num2)}</p>
                                                                        <p className='shotz-text'><Send size={16} />{numFormatter(data.num3)}</p>
                                                                    </div>
                                                                </div>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                        }
                        <Row>
                            {/* ------------- Start Pagination  ------------- */}
                            <Col xl='12' className='d-flex justify-content-center py-4'>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={5}
                                        color='primary'
                                        size="large" />
                                </Stack>
                            </Col>
                            {/* ------------- End Pagination ------------- */}
                        </Row>
                    </Card>
                </div>
                {/* ------------- Right SideBar ------------- */}
                <RightSidebar />
            </div>
            {/* ------------- Downlode Modal ------------- */}
            {/* <DownloadModal
                isOpen={downloadModalOpen}
                downloadModalOpens={toggleDownloadModalOpen}
            /> */}
            <DownloadModal
                isOpen={downloadModalOpen} downloadModalOpen={toggleDownloadModalOpen}
            />
            {/*  ------------- Archive Modal ------------- */}
            <ArchiveModal
                heading="Remove Archived Shotz"
                discription="Do you really want to remove shotz from the archieve"
                isOpen={archiveModalOpen}
                archiveModal={toggleArchiveModalOpen}
            />
            {/*  ------------- Start Delete Modal ------------- */}
            <DeletedModal
                isOpen={deleteModalOpen}
                deleteModalOpens={toggleDeleteModalOpen}
            />
            {/*  ------------- Start Hide Shotz Modal ------------- */}
            <HideModal
                isOpen={hideShotzModalOpen}
                hideModalOpen={toggleHideShotzModalOpen}
            />
            {/*  ------------- End Hide Shotz Modal ------------- */}
            {/* ------------- Edit Shotz Modal ------------- */}
            <EditModal
                isOpen={editModalOpen}
                updateOpen={toggleEditModalOpen}
            />
            {/* ------------- End Edit Shotz Modal ------------- */}
            {/* ------------- Start Shotz Carousel Modal ------------- */}
            <ShotzCarouselComponents
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            />
            {/* ------------- End Shotz Carousel Modal ------------- */}

            {/* ------------- Single remove from archive Modal ------------- */}
            <ArchivedModal
                isOpen={removeSingle}
                singleArchiveModal={toggleRemoveSingle}
                // optioneName="Remove from Archive"
                heading="Archived Shotz"
                discription="Do you really want to remove from archive"
            />
            {/* -------------End Single remove from archive Modal ------------- */}

            {/* ------------- Single download from archive Modal ------------- */}
            <ShotzPopup
                isOpen={singleDownload}
                singleDownloadModal={toggleSingleDownload}
                // optioneName='Download'
                heading="Download Shotz"
                discription="Do you really want to download the selected Shotz?"
            />
            {/* ------------- End remove from archive Modal ------------- */}

            {/* ------------- Single delete from archive Modal ------------- */}
            <SingleDeleteModal
                isOpen={singleDelete}
                singleDeleteModal={toggleSingleDelete}
                // optioneName='Delete'
                heading="Delete Shotz"
                discription="Do you really want to delete the selected Shotz?"
            />
            {/* -------------End Single delete from archive Modal ------------- */}
        </>
    )
}
