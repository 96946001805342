import React from 'react'
import { Fragment } from 'react'

import Slider from "@mui/material/Slider";
import { Input } from "reactstrap";
import { useTheme } from "@mui/material"
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { useState } from 'react';


const MpFilterRangeBetween = (props) => {



    const [value, setValue] = useState([100, 5000]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const theme = useTheme();

    return (
        <Fragment>
            <AccordionBody style={{ borderBottom: "1px solid #E5E5E5 " }}>
                <div className="MpUploadHeading" >
                    <h3 style={{ fontWeight: "600" }}>{props.data.title}</h3>
                    <h5 className='MrktClearAllBtn'>clear all</h5>
                </div>
                <div className="Mp_FilterRange_Selector mt-5">
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={10000}
                        sx={{
                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                width: 24,
                                height: 24,
                                backgroundColor: '#fff',
                                '&:before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                },
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                },
                            },
                        }}
                    />
                    <div className="Mp_FilterRangeBtw_Btn d-flex gap-3 mt-3 align-items-center justify-content-center">
                        <div className="border d-flex align-items-center rounded  p-2">
                            <h4 className='d-flex align-items-center justify-content-center Mp_Color4D_Font6_Flex1'>{props.data.type}</h4>
                            <Input className="Mp_FilterRangebtwn_value Mp_Border0_Flex1" value={value[0]} />
                        </div >
                        <div className="border d-flex align-items-center rounded  p-2">
                            <h4 className='d-flex align-items-center justify-content-center Mp_Color4D_Font6_Flex1' >{props.data.type}</h4>
                            <Input className="Mp_FilterRangebtwn_value Mp_Border0_Flex1" value={value[1]} />
                        </div>
                    </div>
                </div>
            </AccordionBody>
        </Fragment>
    )
}

export default MpFilterRangeBetween;
