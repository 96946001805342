import { Fragment, useEffect, useState } from 'react'
import { AlertTriangle, Clock, Globe, Home, Info, Mail, PhoneCall, RotateCcw, Search, UploadCloud, Users } from 'react-feather'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardImg, CardTitle, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import Profile from "../Img/Profile.png"
import Group from "../Img/Grup.png"
import { sweetAlertConfirmation } from '../AllGroupsSections/SweetAlert';

import EmptyImg from "../Img/empty_Img.png"

import { useDispatch, useSelector } from 'react-redux'
import { getAllGroupCategoryAction, createNewGroupAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import LeftCreation from './LeftCreation'
import RightCreation from './RightCreation'
import axios from 'axios'
import '../styles/createGroup.css';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel'; 


const CreateGroupScreen = () => {

    const navigate = useNavigate()
    const { getAllGroupCategory } = useSelector((state) => { return state.getAllCategoryFromGroupData });
    const { createNewGroup } = useSelector((state) => { return state.postCreateNewGroupData });
    
    let dispatch = useDispatch();
    const [groupName, setGroupName] = useState("")
    const [groupDescription, setGroupDescription] = useState("")
    const [privacy, setPrivacy] = useState("")
    const [category, setCategory] = useState("")
    const [image, setImage] = useState("")
    const [createGroup, setCreateGroup] = useState(`${createNewGroup?.groupId}`)
    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [cancelOpen, setCancelOpen] = useState(false)
    const [groupError,setError] =useState({});
    function onCategory(event) {
        const categoryId = event.target.value;
        const categoryName = event.target.selectedOptions[0].text;
        setCategory(categoryId)
        // Update the selected category name state variable
        setSelectedCategoryName(categoryName);

        // Do other things with the category ID and name as needed
        console.log(`Selected category ID: ${categoryId}`);
        console.log(`Selected category name: ${categoryName}`);
    }

    const confirmGroupCancelation = () => {
        setGroupName('')
        setGroupDescription('')
        setPrivacy('')
        setCategory('')
        setImage('')
        setCreateGroup('')
        setSelectedCategoryName('')
        setCancelOpen((preview) => !preview)
    }

    const fileHandler = async (e) => {
        // e.preventDefault()
        let file = e.target.files[0]
        let groupImage = 'groupImage'
        const form = new FormData()
        form.append('file', file)
        form.append('uploadFor', groupImage)
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            const cofig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user?.token}`
                }
            }
            const { data } = await axios.post(`${process.env.REACT_APP_IPURL}/admin/uploadFile`,
                form, cofig
            )
            console.log('image', data)
            setImage(data.data.successResult[0])
        } catch (error) {
            console.log('ImageUpload Error', error)
        }
    }

    let groupInfo = {
        groupName: groupName,
        description: groupDescription,
        category: category,
        privacy: privacy,
        image: image,
        selectedCategoryName: selectedCategoryName,
    }

    const validateGroupInfo = (groupInfo)=>{
         const errors = {};
        if (!groupInfo.groupName || groupInfo.groupName.trim() === '') {
          errors.groupName = 'Group name is required';
        }
      
        if (!groupInfo.description || groupInfo.description.trim() === '') {
          errors.description = 'Description is required';
        }
      
        if (!groupInfo.category) {
          errors.category = 'Category is required';
        }
      
        if (!groupInfo.privacy) {
          errors.privacy = 'Privacy setting is required';
        }
      
        if (!groupInfo.image) {
          errors.image = 'Image is required';
        }
      
        if (!groupInfo.selectedCategoryName) {
          errors.selectedCategoryName = 'Selected category name is required';
        }

          // Check if there are any errors
        const isValid = Object.keys(errors).length === 0;
        return { isValid, errors };
    }

    useEffect(() => {
        dispatch(getAllGroupCategoryAction())
    }, [])

    const toggleCancle = () => {
        alert('hi')
        setCancelOpen((preview) => !preview);
    }
    const [invitationOpen, setInvitationOpen] = useState(false)
    const toggleInvitation = () => {
        setInvitationOpen((preview) => !preview)
    }

    //========================== Invite_Array_Data ======================//
    const InvitationData = [
        {
            img: Profile,
            name: "Samuel Taylor",
            userName: "samuel_taylor",
            id: "1"

        },
        {
            img: Profile,
            name: "Samuel Jackson",
            userName: "samuel_jackson",
            id: "2"

        },
        {
            img: Profile,
            name: "Samuel Dawson",
            userName: "samuel_dawson",
            id: "3"

        },
        {
            img: Profile,
            name: "Samuel Mcguire",
            userName: "samuel_mcguire",
            id: "4"

        }
    ]



    // const onCategory = (event) => {
    //     const value = event.target.value;
    //     setCategory(value);
    // };

    const createPost = () => {

        const validationResults = validateGroupInfo(groupInfo);

        if (validationResults.isValid) {
            dispatch(createNewGroupAction(groupInfo));
            if (createGroup) {
                sweetAlertConfirmation('Group created successfully')
                setCreateGroup('')
            }

            navigate('/Group')
        } else {
        // Handle validation errors, you can access the error messages from validationResults.errors
        console.log(validationResults.errors);
        setError(validationResults.errors);
        }
        return;
        
    };

    const handleImageDelete = () => {
        setImage('');
      };

    // //================== main_return_function ===================//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="container-fluid section-t-space px-0">
                        <Row>
                            <Col lg="3" className="asd group-main-block p-0">
                                <Fragment>
                                    <div className="content-left">
                                        <div className="suggestion-box section-b-space">
                                            <CardTitle className="card-title">
                                                <h3>Create Group</h3>
                                            </CardTitle>
                                            <div className="suggestion-content ratio_115 no-arrow frz-sug-slider-sec  biz-cration-fsslide-preview">
                                                <Form>
                                                    <FormGroup>
                                                        <Label for="exampleGroup">
                                                            <div className="d-flex align-items-center Grop_Form_Label py-1">
                                                                <p>Group Category </p>&nbsp;<Info id='CategoryId' size={15} color="#C4C4C4" />
                                                                <UncontrolledTooltip placement='right' target='CategoryId'>
                                                                    select the Catagory
                                                                </UncontrolledTooltip>
                                                            </div>
                                                            
                                                        </Label>

                                                        <Input
                                                            id="selectNameexample"
                                                            name="selectName"
                                                            type="select"
                                                            className='Create_GroupInfo_Input w-100'
                                                            onChange={onCategory}
                                                        >
                                                            <option className='text-muted'>Select Group Category</option>
                                                            {
                                                                getAllGroupCategory?.map((data) => (
                                                                    <option value={data.id}>{data.name}</option>
                                                                ))
                                                            }

                                                        </Input>
                                                        { groupError.category && <p className="error-input-msg d-block">
                                                            <svg
                                                            style={{ color: "red" }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="13"
                                                            height="13"
                                                            fill="currentColor"
                                                            className="bi bi-exclamation-circle-fill mr-1"
                                                            viewBox="0 0 16 16"
                                                            >
                                                            <path
                                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                                                                fill="red"
                                                            ></path>{" "}
                                                            </svg>
                                                            
                                                            {groupError.category}
                                                        </p>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="exampleGroup">
                                                            <div className="d-flex align-items-center Grop_Form_Label py-1">
                                                                <p>Group Name</p>&nbsp;<Info id='GroupId' size={15} color="#C4C4C4" />
                                                                <UncontrolledTooltip placement='right' target='GroupId'>
                                                                    Enter the group name
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        </Label>
                                                        <Input

                                                            placeholder="Enter your group name"
                                                            id="groupName"
                                                            type="text"
                                                            name="groupName"
                                                            onChange={e => setGroupName(e.target.value)}
                                                            value={groupName}
                                                        />
                                                        <small className='text-muted d-flex justify-content-end'>Max 60 Characters</small>

                                                        { groupError.groupName && <p className="error-input-msg d-block">
                                                            <svg
                                                            style={{ color: "red" }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="13"
                                                            height="13"
                                                            fill="currentColor"
                                                            className="bi bi-exclamation-circle-fill mr-1"
                                                            viewBox="0 0 16 16"
                                                            >
                                                            <path
                                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                                                                fill="red"
                                                            ></path>{" "}
                                                            </svg>
                                                            
                                                            {groupError.groupName}
                                                        </p>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>
                                                            <div className="d-flex align-items-center Grop_Form_Label py-1">
                                                                <p>Description</p>&nbsp;<Info id='DescriptionId' size={15} color="#C4C4C4" />
                                                                <UncontrolledTooltip placement='right' target='DescriptionId'>
                                                                    enter the description
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        </Label>
                                                        <textarea placeholder='Enter your Description'
                                                            className="form-control"
                                                            name=""
                                                            id=""
                                                            cols="15" rows="5"
                                                            onChange={e => setGroupDescription(e.target.value)}
                                                            value={groupDescription}

                                                        />
                                                        <small className='text-muted d-flex justify-content-end'>Max 180 Characters</small>
                                                        { groupError.description && <p className="error-input-msg d-block">
                                                            <svg
                                                            style={{ color: "red" }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="13"
                                                            height="13"
                                                            fill="currentColor"
                                                            className="bi bi-exclamation-circle-fill mr-1"
                                                            viewBox="0 0 16 16"
                                                            >
                                                            <path
                                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                                                                fill="red"
                                                            ></path>{" "}
                                                            </svg>
                                                            
                                                            {groupError.description}
                                                        </p>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div className="d-flex align-items-center Grop_Form_Label py-1 ">
                                                            <p>Group Privacy {privacy}</p>&nbsp;<Info id='PrivacyId' size={15} color="#C4C4C4" />
                                                            <UncontrolledTooltip placement='right' target='PrivacyId'>
                                                                select the privacy
                                                            </UncontrolledTooltip>
                                                        </div>
                                                        { groupError.privacy && <p className="error-input-msg d-block">
                                                            <svg
                                                            style={{ color: "red" }}
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="13"
                                                            height="13"
                                                            fill="currentColor"
                                                            className="bi bi-exclamation-circle-fill mr-1"
                                                            viewBox="0 0 16 16"
                                                            >
                                                            <path
                                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                                                                fill="red"
                                                            ></path>{" "}
                                                            </svg>
                                                            
                                                            {groupError.privacy}
                                                        </p>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Input
                                                            name="radio1"
                                                            type="radio"
                                                            className="public_Check"
                                                            onChange={e => setPrivacy(e.target.value)}
                                                            value='public'
                                                        />
                                                        {' '}
                                                        <Label className="Check_Label_Section" >
                                                            <h4>Public </h4>
                                                            <p>Anyone can your Group’s post and its member</p>
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup check>
                                                        <Input
                                                            name="radio1"
                                                            type="radio"
                                                            className="Private_Check"
                                                            onChange={e => setPrivacy(e.target.value)}
                                                            value='private'
                                                        />
                                                        {' '}
                                                        <Label className="Private_Label_Section">
                                                            <h4>Private  </h4>
                                                            <p>Only group members can view post and other members of your group</p>
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="exampleGroup">
                                                            <div className="d-flex align-items-center Grop_Form_Label py-1">
                                                                <p>Upload</p>&nbsp;<Info id='UploadId' size={15} color="#C4C4C4" />

                                                                <UncontrolledTooltip placement='right' target='UploadId'>
                                                                    max size 1mb
                                                                </UncontrolledTooltip>
                                                            </div>
                                                            <small>Add your cover image</small>
                                                        </Label>
                                                        <div className="profile-cover profile-cover-new">
                                                            <a href="#" className="btn btn-solidUpload btn-group btn-grp-new">
                                                                <ul>
                                                                    <li className="choose-file">
                                                                        <a className='UploadBtn' href="">
                                                                            <UploadCloud size={14} />&nbsp;
                                                                            Upload
                                                                        </a>
                                                                        <input type="file"
                                                                            onChange={fileHandler}
                                                                        />
                                                                    </li>
                                                                </ul>
                                                            </a>
                                                            { image && <Chip
                                                                label={image || 'Select an image'}
                                                                color={image ? 'primary' : 'default'}
                                                                clickable
                                                                onDelete={image ? handleImageDelete : undefined}
                                                                deleteIcon={image ? <CancelIcon /> : null} 
                                                                component="span"
                                                                className='mt-2'
                                                                />}
                                                        </div>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div className='d-flex justify-content-between'>
                                                            <Button onClick={() => { createPost() }} className="group-btn">Create Group</Button>
                                                            <Button className="group-btn new-create-right">Cancel</Button>
                                                        </div>
                                                    </FormGroup>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            </Col>
                            <Col lg="9" className="col-lg-9 group-main-block p-0 d-none d-lg-block">
                                {/* <RightCreation groupInfo/> */}
                                <RightCreation data={groupInfo} confirmGroupCancelation={confirmGroupCancelation} />
                            </Col>
                        </Row>
                        {/* <div className='Cancel_Modal'>
                            {
                                cancelOpen !== false &&
                                <Modal isOpen={cancelOpen} toggle={toggleCancle} className='modal-dialog-centered' >
                                    <ModalHeader className="d-flex justify-content-center Create_Group_Heading_Section">
                                        <div>
                                            <AlertTriangle size={27} />
                                        </div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className='text-center Create_Group_Body_Section '>
                                            <h4>Do you really want to cancel the group Creation?</h4>
                                        </div>
                                        <div className='Button_section'>
                                            <Button className="Creation_Cnfm_btn">Confirm</Button>
                                            <Button className="Creation_Cncle_btn ml-2" onClick={toggleCancle} outline>Cancel</Button>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            }

                        </div> */}
                        <div className='Send_Invitation_Modal'>
                            {
                                invitationOpen !== false &&
                                <Modal scrollable isOpen={invitationOpen} toggle={toggleInvitation} className='modal-dialog-centered'  >
                                    <ModalHeader className='d-flex justify-content-center'>
                                        <div className='Invitation_Heading_Section'>
                                            <h4>Send Invitation</h4>
                                        </div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col md="12">
                                                <div className='Invite_SocioMate_Heading'>
                                                    <h4>Invite from SocioMate</h4>
                                                </div>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md="12">
                                                <div className="search-block-podcast">
                                                    <div className="input-search-blk">
                                                        <Search className="search-svg" />
                                                        <Input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12">
                                                <div className='Invitaion_List_Heading'>
                                                    <h4>Friends (4)</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                {
                                                    InvitationData.map((data) => (
                                                        <div className='d-flex justify-content-between mt-3'>
                                                            <div className='d-flex align-items-center '>
                                                                <img src={data.img} alt="profile" className='img-fluid' />
                                                                <div className='Invitaion_User_Profile ml-1'>
                                                                    <h4>{data.name}</h4>
                                                                    <p>{data.userName}</p>
                                                                </div>
                                                            </div>
                                                            <div className='Block-btns-blk'>
                                                                <CustomInput type="checkbox" className='customcheckboxForFilter ' id={data.id} style={{ cursor: "pointer" }} />
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12">
                                                <div className='d-flex justify-content-center'>
                                                    <Button className="Invitation_Send_btn">Send</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </ModalBody>


                                </Modal>
                            }

                        </div>

                    </div>
                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default CreateGroupScreen