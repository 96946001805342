import React from "react";
import { Button } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { loadUnfollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import { useDispatch } from "react-redux";
import axios from "axios";
import { loadAllUserPosts } from "../../../Services/Actions/SocialFeed/getAllUserPostsAction";


const FeedUnfollowModal = ({  postType, openModal, unfollowModalOpen }) => {
  const dispatch = useDispatch();

  const HandleUnFollow =async (postType) => {

    try {
      const requestBody = {
        followingToId: postType.userId,
        isFollowed: false,
        isPrivate: postType.isPrivate,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response line 31", response);

      if (response.status === 200) {
        unfollowModalOpen();
        dispatch(loadAllUserPosts());
      } else {
        console.error(
          "User Blocked Successfully. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error saving on post:", error);
    }
  };
  return (
    <>
      {openModal !== false && (
        <Modal
          isOpen={openModal}
          toggle={unfollowModalOpen}
          className="modal-dialog-centered"
        >
          <ModalHeader className="d-flex justify-content-center bg-light">
            <h2>Unfollow</h2>
          </ModalHeader>
          <ModalBody className="text-center">
            <h4 className="py-2">Do you really want to unfollow.</h4>
          </ModalBody>
          <ModalFooter className="d-flex border-0 justify-content-center">
            <Button variant="outline-secondary" onClick={unfollowModalOpen}>
              <span className="ml-1 w-25">Cancel</span>
            </Button>
            <Button variant="danger" onClick={()=>{HandleUnFollow(postType)}}>
              <span className="ml-1 w-25">Unfollow</span>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default FeedUnfollowModal;
