import React, { useState } from 'react'
import { Grid, List } from 'react-feather'
import { Button } from 'reactstrap'
import GridAudio from './Grid-Components/GridAudio'
import AudioTrendingList from './AudioTrendingList'

const AudioTabsTrending = () => {
    const [data, setdata] = useState(true)
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                {/* <p className='TrendingPeoplethistext'>TRENDING AUDIOS</p> */}
                <p className='TrendingPeoplethistext'></p>
                <div className='list-grid-toggle rem-mob-view'>
                    {/* <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                        <Grid size={16} />
                    </Button> */}
                    {/* <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
                        <List size={16} />
                    </Button> */}
                </div>
            </div>
            {
                // data ? <GridAudio /> : 'figma need to update'
                data ? <AudioTrendingList /> : 'figma need to update'
            }
        </div>
    )
}
export default AudioTabsTrending 