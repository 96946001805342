import React from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from "reactstrap"
import Event from "../Universal-components/display/Sub-component/Event"
// import Thought from "../Universal-components/display/Sub-component/Thought"
// import PlayVideo from '../../NewComponents/IMG/playVideo.svg'
import LeftquotationIcon from '../../NewComponents/IMG/LeftquotationIcon.svg'
import RightquotationIcon from '../../NewComponents/IMG/RightquotationIcon.svg'
import podcast from '../../NewComponents/IMG/podcast.png'
import { AlertTriangle, Archive, Download, Edit, Eye, EyeOff, Link, MessageCircle, MoreHorizontal, MoreVertical, Music, Play, Plus, Send, Share2, ThumbsUp, Trash2, Users } from "react-feather"
import img from '../../NewComponents/IMG/img.svg'
import paid from '../../NewComponents/IMG/paid.png'
import Mic from '../../NewComponents/IMG/Mic.png'
import Sell from "../Universal-components/display/Sub-component/Sell"
import MediaModal from "../profile-components/MediaModal"
import like from '../../NewComponents/IMG/like.png'
import Shotzs from '../../NewComponents/IMG/Shotzs.png'
import { NavLink } from "react-router-dom"
import Eventpic4 from '../../NewComponents/IMG/Eventpic4.png'
import like2 from '../../NewComponents/IMG/like2.svg'
// import Poll from "../Universal-components/display/Sub-component/Poll"
import ShareEventModal from "../profile-components/EventsFolder/EventsModal/ShareEventModal"
import { useState } from "react"
import VideoModal from "../Search-Components/VideoModal"
import play from '../../NewComponents/IMG/play.png'
import BookmarkThought from "./Posts/BookmarkThought"
import BookmarkEvents from "./Posts/BookmarkEvents"
import BookmarkSellPost from "./Posts/BookmarkSellPost"
import BookmarkPollPost from "./Posts/BookmarkPollPost"
import shotzImage from "./Img/shotz.jpg"
const MasonryGrid = () => {

    const TextPost = () => {
        return (
            <>
                <Card className='p-2'>
                    <div>
                        <h5 className='text-dark'>Celebration new album song launched</h5>
                        <div className='mt-2'>
                            <p className='my-2'><b className='text-primary'>#Musiccelebration</b>, #Musiccelebration, #music, #party, #music</p>
                            <p className='text-muted'> Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industry's standard dummy text ever since the 1500s...</p>

                        </div>
                    </div>
                </Card>
            </>
        )
    }

    const Podcast = () => {
        const numFormatter = (num) => {
            // eslint-disable-next-line prefer-template
            if (num > 999 && num < 1000000) {
                return `${(num / 1000).toFixed(1).toString()} K`
            } else if (num > 999999) {
                return `${(num / 1000000).toFixed(1).toString()} M`
            } else if (num < 900) {
                return `${num}`
            }
        }
        const Podcastdata = [
            {
                img: img,
                paid: paid,
                text: 'Pappa podden',
                num: '1500'
            },

        ]
        return (
            <div>
                <Card className='border-0'>
                    <Row className='rowForrevert'>
                        {
                            Podcastdata.map((data, index) => {
                                return (
                                    <Col xl='3' className=' w-100'>
                                        <Card>
                                            <img src={podcast} className='podcastImg rounded' />
                                            <div className='UncontrolledButtonDropdowntopright'>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color='flat-primary' >
                                                        <MoreHorizontal color='#FFFFFF' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                                        <DropdownItem className='dropdownitem' ><Edit size={13} />&nbsp;Edit podcast</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Play size={13} />&nbsp;Play podcast</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Plus size={13} />&nbsp;Create new podcast</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;Delete podcast</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem ' ><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem textThisColorLearnmore' ><Eye size={13} />&nbsp;Learn more</DropdownItem>


                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>

                                            <div className='podcastLeft'>
                                                <p className='mt-1 d-flex'><img src={Mic} className='Miccolor' /><p className='micnumber'>{numFormatter(data.num)}</p></p>

                                            </div>
                                            <div className='podcasRight'>
                                                <p className='EP'>S1 Ep 02</p>
                                            </div>
                                            <div className='podcastftBottom'>
                                                <p className='EP'>{data.text}</p>
                                            </div>
                                            <div className='podcastRightBottom'>
                                                <p className='EP'><img src={data.paid} /></p>
                                            </div>


                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
            </div>
        )
    }

    const Alert = () => {
        return (
            <>
                {/* <CardText className='ml-2'>Mild</CardText> */}

                <div className="pvt-event-cont-blk text-dark BookmarkBorderRadius " style={{ background: '#FFD600', fontWeight: '700' }} >
                    <div className="pvt-eventcont" style={{ background: '#FF822E' }} >#Mild</div>
                    <img src={LeftquotationIcon} alt="icon" />
                    <div>
                        <div className='px-4'>Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho</div>
                        <div className='text-right'>
                            <img src={RightquotationIcon} alt="icon" />
                        </div>
                    </div>
                </div>


            </>
        )
    }

    const Shotz = () => {

        const numFormatter = (num) => {
            // eslint-disable-next-line prefer-template
            if (num > 999 && num < 1000000) {
                return `${parseInt(num / 1000)} K`
            } else if (num > 999999) {
                return `${parseInt(num / 1000000)} M`
            } else if (num < 900) {
                return `${num}`
            }
        }
        const Shotzdata = [
            {
                Shotzs: Shotzs,
                icon: <Eye size={18} />,
                num: '12000',
                timeline: 'Hide',
                post: 'Edit',
                link: 'Copy Link ',
                delete: 'Delete',
                share: 'Share',
                download: 'Download',
                archive: 'Add to Achive',
                songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
                icon1: <Music size={25} color='#FFFFFF' />,
                num1: '1000',
                num2: '30000',
                num3: '23000'

            }

        ]
        return (
            <div className="BookmarkBorderRadius">
                <Card className='border-0 BookmarkBorderRadius'>
                    <Row className='AllTagsThis rowForrevert'>
                        {
                            Shotzdata.map((data) => {
                                return (
                                    <Col xl='3' className=' w-100 BookmarkBorderRadius'>
                                        <div className="media-images-blk shotz-album-block border align-items-center BookmarkBorderRadius">
                                            <div className="main-shotz-blk-list">
                                                <a data-bs-toggle="modal" data-bs-target="#imageModel"><img className="gallery-img" src={shotzImage} alt="" /></a>
                                                <div className="text-gallery d-flex justify-content-center">
                                                    <div className="top-gallery-cont">
                                                        <p>{data.icon} {numFormatter(data.num)}</p>
                                                        <div className='gallery-img-sublink-this-bizpage'>
                                                            <UncontrolledButtonDropdown className=''>
                                                                <DropdownToggle color='flat' >
                                                                    <MoreVertical color='white' className='' size={18} />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu'>
                                                                    <DropdownItem className='dropdownitem' ><Send size={13} />&nbsp;&nbsp;{data.share}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><EyeOff size={13} />&nbsp;&nbsp;{data.timeline}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Edit size={13} />&nbsp;&nbsp;{data.post}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;&nbsp;{data.delete}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;{data.link}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Download size={13} />&nbsp;&nbsp;{data.download}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Archive size={13} />&nbsp;&nbsp;{data.archive}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                    </div>
                                                    <div className="center-gallery-cont" >
                                                        <a data-bs-toggle="modal" data-bs-target="#imageModel"><img src="assets/images/shotz-icon.png" className='shotzModel' /></a>
                                                    </div>
                                                    <div className="Music-gallery-cont">
                                                        <p className='shotztabssongstext pl-1'>{data.icon1}</p>
                                                        <p className='shotztabssongs'>{data.songs}</p>
                                                    </div>

                                                    <div className="bottom-gallery-cont">
                                                        <p> <img src={like} /> {numFormatter(data.num1)}</p>
                                                        <p><MessageCircle size={18} /> {numFormatter(data.num2)}</p>
                                                        <p><Send size={18} /> {numFormatter(data.num3)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
                <MediaModal />
            </div>
        )
    }

    const ArticleTabs = () => {
        const Articledata = [
            {
                sharevia: 'Hide post from timeline',
                shareto: 'Copy link',
                notification: 'Edit Post',
                invite1: 'Share',
                content: 'Delete',
                button: <button className='Article_Going_Button'><Users size={14} />&nbsp;&nbsp;Going</button>,
                button1: <button className='Article_Button_Interested'><ThumbsUp size={14} />&nbsp;&nbsp;Interested</button>,
                Event: Eventpic4
            }
        ]
        return (
            <div>
                <Card className='p-2 border-0'>
                    <Row className='AllTagsThis'>
                        {
                            Articledata.map((data) => {
                                return (
                                    <Col xl='3' className='w-100'>
                                        <div className='p-1 mt-2'>
                                            {/* <Slider {...bizSettings} className="default-space"> */}
                                            <div className="group-slide-box border">
                                                <div className="gallery-img-sublink">
                                                </div>
                                                <NavLink>

                                                    <div className='TopRight_Section BookmarkRight8px'>
                                                        <p><Eye color='#4D4D4D' size={18} />&nbsp;105 </p>
                                                        <p><img src={like2} className='Article_like_thumble' />&nbsp;105</p>
                                                        <p><MessageCircle color='#4D4D4D' size={18} />&nbsp;78</p>
                                                        <p ><Send color='#4D4D4D' size={18} />&nbsp;13</p>

                                                    </div>
                                                    <img src={data.Event} className="img-fluid group-img" />
                                                    <div className='d-flex justify-content-between mt-3'>
                                                        <p className='ArticleTabs_date_month'>01 July, 2022</p>
                                                        <p className='Article_Sports'>Sports</p>
                                                        <p className='MoreHorizontal'>
                                                            <UncontrolledButtonDropdown>
                                                                <DropdownToggle color='flat-primary' >
                                                                    <MoreHorizontal color='#808080' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu'>
                                                                    <DropdownItem className='dropdownitem' ><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Edit size={13} />&nbsp;Edit</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;Delete</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-start'>
                                                        <p className='ArticleTabs_Liverpool'>Liverpool Win Champions...</p>
                                                    </div>
                                                    <div className='Article_Liverpool_Champions'>Liverpool have won the 2018/2019 Champions League after beating Tottenham Hotspur 2-0 in an...</div>
                                                </NavLink>

                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
            </div>
        )
    }

    const GridRecommendation = () => {
        const [shareOpen, setShareOpen] = useState(false)
        const toggleShareOpen = () => {
            setShareOpen((prevState) => !prevState)
        }
        const [reportOpen, setReportOpen] = useState(false)
        const toggleReport = () => {
            setReportOpen((preState) => !preState)
        }
        const [active, setActive] = useState('1')

        const toggle = tab => {
            if (active !== tab) {
                setActive(tab)
            }
        }

        const ReportDataInfo = [
            {
                name: "Spam"
            },
            {
                name: "Nudity"
            },
            {
                name: "Violence"
            },
            {
                name: "Harrasment"
            },
            {
                name: "Suicide or self-injury"
            },
            {
                name: "Terrorism"
            },
            {
                name: "Others"
            }
        ]

        return (
            <div className='mt-1'>
                <Row className='PeopleThisGrid PaddinNone'>
                    <Col lg='3' md='4' className='w-100' >
                        <div className='Video_Treanding_card PaddinNone'>
                            <div className='d-flex justify-content-between py-3 px-2'>
                                <div className='d-flex align-items-start '>
                                    <img src={'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'} alt="pic" height={50} width={50} className='rounded-circle' />
                                    <div className='ml-2'>
                                        <h4>Floyd Miles </h4>
                                        <p>19 sept at 07:00 pm</p>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown className='align-self-center'>
                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                        <MoreHorizontal size={19} color='#000000' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><Edit size={13} />&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>

                            <div className='Potrate_treanding_img BookmarkBorderRadius '>
                                <img src='https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png' className="BookmarkBorderRadius" alt='img' height={210} />
                                <div className='TreandingRecommendations BookmarkBorderRadius_BothBottom '>
                                    <small>10 Recommendations</small>
                                    <Button className='text-white'>Recommend</Button>
                                </div>
                            </div>

                            {/* <div className='Treanding_bottom_section'>
                                <div className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={LikeBlack} width={18} height={18} />
                                    </div>
                                    11.2k
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={CommentImg} width={16} height={16} className='' />
                                    </div>
                                    11.2k
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={ShareImg} width={16} height={16} className='' />
                                    </div>
                                    11.2k
                                </div>

                            </div> */}

                        </div>
                    </Col>
                </Row>

                <ShareEventModal
                    isOpen={shareOpen}
                    updateOpen={toggleShareOpen}
                />
                <div className='Review_Report_modal'>
                    {
                        reportOpen !== false &&
                        <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                                <h3>Report a Post</h3>
                            </ModalHeader>
                            <ModalBody>
                                <div className='Modal_Body_Section'>
                                    <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                                </div>
                                {
                                    ReportDataInfo.map((data) => (
                                        <div className='d-flex justify-content-between mt-4 Report_Section'>
                                            <h4>{data.name}</h4>
                                            <div>
                                                <Input
                                                    name="radio1"
                                                    type="radio"
                                                    className="Check"
                                                />
                                                {' '}
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className='d-flex justify-content-center mt-3'>
                                    <Input
                                        id="ReportName"
                                        name="ReportName"
                                        placeholder="Write a comment..."
                                        type="Report"
                                    />
                                </div>
                                <hr className='w-100 text-muted' />
                                <div className='text-center'>
                                    <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                    <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                                </div>
                            </ModalBody>

                        </Modal>

                    }

                </div>
            </div >
        )
    }

    const VideoTabs = () => {

        const numFormatter = (num) => {
            // eslint-disable-next-line prefer-template
            if (num > 999 && num < 1000000) {
                return `${parseInt(num / 1000)} K`
            } else if (num > 999999) {
                return `${parseInt(num / 1000000)} M`
            } else if (num < 900) {
                return `${num}`
            }
        }
        const [modal, setModal] = useState(false);
        const toggle = () => setModal(!modal);
        const chackbox = [
            {
                songs: 'music',
                chackbox: <input type="checkbox" />
            },
            {
                songs: 'Arts',
                chackbox: <input type="checkbox" />
            },
            {
                songs: 'Weddings',
                chackbox: <input type="checkbox" />
            },
            {
                songs: 'Food & Recipe',
                chackbox: <input type="checkbox" />
            }
        ]
        const Videodata = [
            {
                Shotzs: "https://cdn.wallpapersafari.com/76/70/QuZbMJ.jpg",
                icon: <Eye size={16} />,
                num: '1200',
                user: 'View user',
                link: 'Copy Link ',
                edit: 'Edit Post',
                delete: 'Delete',
                share: 'Share post',
                songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
                icon1: <Music size={28} />,
                num1: '1600',
                num2: '3200',
                num3: '2300'

            }

        ]
        return (
            <div>
                <Card className='border-0'>
                    <Row className='AllTagsThis rowForrevert'>

                        {
                            Videodata.map((data, index) => {
                                return (
                                    <Col xl='3' className='w-100 BookmarkBorderRadius'>
                                        <div className="media-images-blk shotz-album-block border align-items-center BookmarkBorderRadius">
                                            <div className="main-shotz-blk-list BookmarkVideoLinearGradient">
                                                <a data-bs-toggle="modal" data-bs-target="#VideoModel" className=""><img src={data.Shotzs} className="gallery-img" alt="sdfh" /></a>
                                                <div className="text-gallery d-flex justify-content-center">
                                                    <div className="top-gallery-cont justify-content-end">

                                                        {/* <Copy color="white" /> */}
                                                        <p className="gallery-img-sublink-this-bizpage">

                                                            <UncontrolledButtonDropdown className=''>
                                                                <DropdownToggle color='flat' >
                                                                    <MoreHorizontal color='white' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu'>
                                                                    <DropdownItem className='dropdownitem' ><Share2 size={13} />&nbsp;{data.share}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;{data.user}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;{data.link}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Edit size={13} />&nbsp;{data.edit}</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp; {data.delete}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </p>
                                                    </div>
                                                    <div className="center-gallery-cont" >
                                                        <a data-bs-toggle="modal" data-bs-target="#VideoModel"><img src={play} className='play' /></a>
                                                    </div>
                                                    <div className="bottom-gallery-cont">
                                                        <p> <img src={like} /> {numFormatter(data.num1)}</p>
                                                        <p><MessageCircle size={18} /> {numFormatter(data.num2)}</p>
                                                        <p><Send size={18} /> {numFormatter(data.num3)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Card>
                <VideoModal />
            </div>
        )
    }

    const CardGrid = [

        {
            grid: < BookmarkThought />
        },
        {
            grid: < GridRecommendation />
        },
        {
            grid: < BookmarkEvents />
        },
        {
            grid: < TextPost />
        },
        {
            grid: < VideoTabs />
        },
        {
            grid: < Podcast />
        },
        {
            grid: < Sell />
        },
        {
            grid: < BookmarkThought />
        },
        {
            grid: < BookmarkSellPost />
        },
        {
            grid: < Alert />
        },
        {
            grid: < BookmarkThought />
        },
        {
            grid: < Event />
        },
        {
            grid: < ArticleTabs />
        },
        {
            grid: < Shotz />
        },

        {
            grid: < BookmarkThought />
        },

        {
            grid: < Alert />
        },
        {
            grid: < Podcast />
        },

        {
            grid: < BookmarkThought />
        },
        {
            grid: < GridRecommendation />
        },
        {
            grid: < BookmarkPollPost />
        },


    ]

    return (
        <div className="mt-2">
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 667: 2, 1157: 3 }}
            >
                <Masonry columnsCount={3} gutter="10px" >
                    {CardGrid.map((item, index) => (
                        <Card className="BookMarkCardShadow">
                            {item.grid}
                        </Card>
                    ))}
                </Masonry>
            </ResponsiveMasonry>
        </div>
    )
}
export default MasonryGrid 