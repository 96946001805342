import * as types from "../../Constants/Events/index";

const initialState = {
    upcommingEvent: [],
    loding: true,
  };
  
   const getUpcommingCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_UPCOMING_EVENTS_BY_USER:
        return {
          ...state,
          upcommingEvent: action.payload,
          loding: false, 
        };
      default:
        return state;
    }
  };
  
  
  export default getUpcommingCategoryReducer                 ;
  