import React, { Fragment, useState } from 'react'
import { Button, Card, CardImg, CardImgOverlay, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Dropdown, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown, Form, Label } from 'reactstrap'
import { CheckCircle, ChevronDown, MoreHorizontal, Play, Trash2, Upload, XCircle } from 'react-feather'
import { Checkbox } from '@mui/material'

import NFTUpload from './NFTUpload'
import NFTfooter from "./NFTfooter"

const CretionNft = () => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const [selectedOption, setSelectedOption] = useState(' Select Who Can Listen');

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
  };
  function handleCheckboxAllowMessageChange() {
    setAllowMessage(!allowMessage);
  }
  const [allowMessage, setAllowMessage] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(' Select Who Can Listen');

  const handleDropdownSelect1 = (option) => {
    setSelectedOption1(option);
  };
  const [checkboxActive, setcheckboxActive] = useState(false);
  return (
    <Fragment>
      {/* <div className="page-center">

        <div className="d-flex p-3 ">
          <h2 >
            Add new NFT
          </h2>
        </div>
        <div>
          <h3>Upload an item</h3>
          <p>PNG, GIF, WebP, MP4 or MP3. Maximum file size 100 Mb.</p>
        </div>
        <NFTUpload />
        <div>
          <h3>Add NFT Details</h3>
          <span></span>
          <p>Fill all the details to Publish NFT.</p>
        </div>
        <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">
          <div className="col-xl-6 mb-4">
          <Fragment>
           
            <div className='MpformElementHeading'>
                <h5>rgrggf</h5>
            </div>
           
            <div className="Mp_InputPannels mt-2">
                <Input placeholder="fgdfgdfdfgdfd"style={{padding:"10px"}} />
            </div>
        </Fragment>
            </div>
            </div>


      </div> */}
      {/* <Row>
        <div className='d-flex justify-content-between'>
          <Col className='col-sm-9 '>
            <div className=' w-100 h-100 ml-2'>

              <div><h4>hhhhhhhhhhhhh</h4>
                <p className='mt-3'>hjfdhkkkkkkkkkh</p>
              </div>

              <NFTUpload />

              <div>
                <h3>Add NFT Details</h3>
                <span></span>
                <p>Fill all the details to Publish NFT.</p>
              </div>
              <div></div>
              
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    Email
                  </Label>
                  <Input
                    id="exampleEmail"
                    name="email"
                    placeholder="with a placeholder"
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">
                    Password
                  </Label>
                  <Input
                    id="examplePassword"
                    name="password"
                    placeholder="password placeholder"
                    type="password"
                  />
                </FormGroup>
              </Col>


            </div>
          </Col>
          <div className='shadow w-50 h-100'>
            <Col className='col-sm-3'>2</Col>
          </div>
        </div>
      </Row> */}

      <Form>

        <FormGroup>
          <div className="d-flex  ">
            <h2 >
              Add new NFT
            </h2>
          </div>

        </FormGroup>
        <FormGroup>
          <div>
            <h3>Upload an item</h3>
            <p>PNG, GIF, WebP, MP4 or MP3. Maximum file size 100 Mb.</p>
          </div>

        </FormGroup>

        <FormGroup>
          <NFTUpload />

        </FormGroup>
        <FormGroup>
          <Label for="exampleAddress">
            Add NFT Details
          </Label>
          <Label for="exampleAddress">
            Fill all the details to Publish NFT
          </Label>

        </FormGroup>
        <FormGroup>
          <Label for="exampleAddress">
            Product Name
          </Label>
          <Input
            id="exampleAddress"
            name="address"
            placeholder="Enter Product Name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="exampleAddress2">
            Description
          </Label>
          <Input
            id="exampleAddress2"
            name="address2"
            placeholder="Enter Description"
          />
        </FormGroup>

        <Row>

          <Col md={3}>

            <FormGroup>
              <Label for="exampleZip">Select Sale type</Label>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle style={{ width: '100%' }} color='secondary text-left' outline className="d-flex justify-content-between"  >
                  Select Zip
                  <ChevronDown size={18} />
                </DropdownToggle>
                <DropdownMenu style={{ width: '100%' }}>
                  <DropdownItem>Auction</DropdownItem>
                  <DropdownItem>Instant Sale</DropdownItem>
                  {/* Add more DropdownItems as needed */}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>

          </Col>
          <Col md={3}>

            <FormGroup>
              <Label for="exampleZip">Add Auction Time</Label>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle style={{ width: '100%' }} color='secondary text-left' outline className="d-flex justify-content-between" >
                  Select Zip
                  <ChevronDown size={18} />
                </DropdownToggle>
                <DropdownMenu style={{ width: '100%' }}>
                  <DropdownItem>Zip Option 1</DropdownItem>
                  <DropdownItem>Zip Option 2</DropdownItem>
                  {/* Add more DropdownItems as needed */}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>

          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleZip">
                Zip
              </Label>
              <Input
                id="exampleZip"
                name="zip"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleZip">
                Zip
              </Label>
              <Input
                id="exampleZip"
                name="zip"
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="exampleAddress">
            Reminders
          </Label>


        </FormGroup>
        <FormGroup>
          <div className="d-flex justify-content-between">
            <h3>Receive Notifications from our platform</h3>
            <label className="switch">
              <input
                type="checkbox"
                checked={allowMessage}
                onChange={handleCheckboxAllowMessageChange}
              />

              <span className="slider round"></span>
            </label>
          </div>

        </FormGroup>
        <Row>
          <Col sm={12}>
            <div className='golivesetting-toggle-box12'>
              <div className="m-31 d-flex align-items-center">
                <Checkbox
                  onClick={() => setcheckboxActive(!checkboxActive)}
                  checked={checkboxActive ? true : false}
                />
                <h4 className='ml-2 form-check-label'>I accept the <span className='text-primary'>Privacy Policy</span></h4>


              </div>
              <div className="d-flex">
                <Button color="primary" className="w-100 mr-2">
                  Discard
                </Button>
                <Button color="primary" className="w-100">
                  Publish
                </Button>
              </div>

            </div>
          </Col>
        </Row>

      </Form>
     
    </Fragment>

    
  )
  
}

export default CretionNft