import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Slider from "react-slick";
import { Col, Modal, Row, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, TabContent, TabPane } from 'reactstrap';
import cricleimg from '../../../NewComponents/IMG/cricleimg.png'
import { ChevronsUp, Image, MoreVertical, Pause, Play, Settings, Type, Volume2, VolumeX, X } from 'react-feather';
// import { Fab } from '@mui/material';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ConnectionReportModal from '../../Connections/ConnectionModal/ConnectionReportModal';
import ViewedAndLike from '../ViewedAndLike';
import StorySettingModal from './StorySettingModal';
import DeleteStoryModal from './DeleteStoryModal';
import { StoriesView } from "../StoryArrayDummy/StoryDummy"

const SelfStoryViewModal = (props) => {
    const [paused, setPaused] = useState(false);
    const [volume, setvolume] = useState(false);
    // const [StoryActiveSlider, setStoryActiveSlider] = useState('1')

    //  ------------- Start Reported Option State -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [StoryActiveSlider, setStoryActiveSlider] = useState('1')

    const togglethisslider = tab => {
        if (StoryActiveSlider !== tab) setStoryActiveSlider(tab)
    }


    const StoriesCreateUsers = [
        {
            userImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcgY_vHt1J7eYDJc1afPQ7RQVX8eitJGirWLdkGIsxY7vI30qsxgy_SdpcC5h-2UCNK6c&usqp=CAU',
            userName: "Ella Sofia",
            storyCreateTime: '1 hour ago',
            storieNum: '3 New',
            totalStories: 3
        },

        {
            userImg: 'https://pbs.twimg.com/media/Flt2ERlaMAQrYK0.jpg',
            userName: "Rohan",
            storyCreateTime: '1 hour ago',
            storieNum: '3 New',
            totalStories: 3

        },
        {
            userImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcgY_vHt1J7eYDJc1afPQ7RQVX8eitJGirWLdkGIsxY7vI30qsxgy_SdpcC5h-2UCNK6c&usqp=CAU',
            userName: "Ella Sofia",
            storyCreateTime: '1 hour ago',
            storieNum: '3 New',
            totalStories: 3
        },

        {
            userImg: 'https://pbs.twimg.com/media/Flt2ERlaMAQrYK0.jpg',
            userName: "Rohan",
            storyCreateTime: '1 hour ago',
            storieNum: '3 New',
            totalStories: 3
        },

    ]
    //  ------------- Story Like View User List -------------
    const [likeViewModal, setLikeViewModal] = useState(false)
    const toggleLikeViewModal = () => {
        setLikeViewModal((preState) => !preState)
    }
    // ------------- Delete Modal State -------------
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModalOpen = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }

    const avatardata = [
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-3.jpg'
        },
        {
            img: 'assets/images/story-4.jpg'
        },
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-3.jpg'
        },
    ]

    var gallerySettings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // ------------- Story setting States -------------
    const [storySettingModal, setStorySettingModal] = useState(false)
    const toggleStorySettingModal = () => {
        setStorySettingModal((prevState) => !prevState)
    }
    return (
        <>
            {/* ------------- Delete Modal ------------- */}
            <StorySettingModal
                isOpen={storySettingModal}
                settingModal={toggleStorySettingModal} />

            {/* ------------- Report Option Modal ----------- */}
            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/* ------------- Delete Modal ------------- */}
            <ViewedAndLike
                isOpen={likeViewModal}
                likeViewModal={toggleLikeViewModal}
            />
            {/* ------------- Delete Modal ------------- */}
            <DeleteStoryModal
                isOpen={deleteModalOpen}
                deleteModalOpens={toggleDeleteModalOpen}
            />
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} className='modal-dialog-centered modal-container modal-fullscreen '>
                    <Row className='d-flex border-0' style={{ maxHeight: "100vh" }}>
                        <Col xl='3' lg='4' md="5" className='story_sidebar border-bottom RemoveInMobileStory col-xxl-3' style={{ height: "100vh", overflowY: "auto" }}>


                            <div className='d-flex justify-content-between py-3 px-2 align-items-center border-bottom' >
                                <h2 className='font-weight-bold'>Stories</h2>
                                <p role="button"><Settings onClick={toggleStorySettingModal} size={22} color='#4D4D4D' /></p>
                            </div>
                            <h3 className='ml-3 my-3'>Your Story</h3>
                            <div className='d-flex justify-content-between align-items-center' onClick={() => { togglethisslider('4') }}>
                                <div className='d-flex align-items-center ml-2'>
                                    <div className="circle-border-style">
                                        <img src={cricleimg} width={60} className='img-fluid' alt='CircleImage' />
                                    </div>
                                    <div className='ml-2 d-flex flex-column'>
                                        <h4 className="d-inline-block text-truncate font500" >Jekerlin Ferdinand</h4>
                                        <h5 className="text-muted mt-1" >20h ago • 16 View</h5>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-2'>
                                    <NavLink to='/CreateStories' className='storyEditBtnDesignViewPage border p-0' style={{ background: 'transparent' }}>
                                        <Type size={14} />
                                    </NavLink>
                                    {/* &nbsp;&nbsp; */}
                                    <NavLink to='/CreateStories' className='storyEditBtnDesignViewPage border p-0' style={{ background: 'transparent' }}>
                                        <Image size={14} />
                                    </NavLink>
                                </div>
                            </div>
                            <div>
                                {/* ----------- Stories Create Users ------------*/}
                                <h3 className='p-3'>Recent Stories</h3>
                                {StoriesCreateUsers.map((data) => (
                                    <div className='d-flex justify-content-between py-2 px-3 align-items-center StoryViewHoverColor cursor-p' >
                                        <div className='d-flex align-items-center' onClick={() => { togglethisslider('1') }}>
                                            <div className='border_style' >
                                                <img src={data.userImg} width={60} className='img-fluid' alt='userPic' />
                                            </div>
                                            <div className='ml-2'>
                                                <h5 className='font500'>{data.userName}</h5>
                                                <h6 className='font500 text-primary mt-1'>{data.storieNum} <span className='text-muted font400'>{data.storyCreateTime}</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <h3 className='p-3'>Viewed Stories</h3>
                                {StoriesCreateUsers.map((data) => (
                                    <div className='d-flex justify-content-between py-2 px-3 align-items-center StoryViewHoverColor cursor-p'>
                                        <div className='d-flex align-items-center' onClick={() => { togglethisslider('1') }}>
                                            <div className='border_style_disable'>
                                                <img src={data.userImg} width={60} className='img-fluid' alt='usePic' />
                                            </div>
                                            <div className='ml-2'>
                                                <h5 className='font500'>{data.userName}</h5>
                                                <h6 className='font500 text-primary mt-1'>{data.storieNum} <span className='text-muted font400'>{data.storyCreateTime}</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* <div className='d-flex justify-content-between py-3 px-2 align-items-center border-bottom' >
                                <h2 className='font-weight-bold'>Stories</h2>
                                <p onClick={toggleStorySettingModal} role="button">
                                    <Settings size={22} color='#4D4D4D' />
                                </p>
                            </div>
                            <h3 className='ml-3 my-3'>Your Story</h3>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center ml-2'>
                                    <div className="circle-border-style">
                                        <img src={cricleimg} width={60} className='img-fluid' alt='photo' />
                                    </div>
                                    <div className='ml-2'>
                                        <h5 className="font-weight-bold d-inline-block text-truncate" style={{ maxWidth: '100px' }}>Jekerlin Ferdinand</h5>
                                        <p className='text-muted'>2 hour Ago</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <NavLink to='/CreateStories' className='border-0 mr-2 p-0' style={{ background: 'transparent' }}>
                                        <Fab aria-label='edit' size='small'>
                                            <Type size={18} />
                                        </Fab>
                                    </NavLink>
                                    &nbsp;&nbsp;
                                    <NavLink to='/CreateStories' className='border-0 mr-2 p-0' style={{ background: 'transparent' }}>
                                        <Fab aria-label='edit'
                                            size='small'>
                                            <Image size={18} />
                                        </Fab>
                                    </NavLink>
                                </div>
                            </div>
                            <div>
                                <h5 className='m-3'>When friends, groups, and pages post stories their stories will show up here.</h5>
                            </div> */}
                        </Col>
                        <Col xl='9' lg='8' md="7" className='story_content right-box p-0 col-xxl-9' style={{ maxHeight: "100vh", overflowY: "auto" }}>
                            <div className='m-3'>
                                <Row className='d-flex '>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={50} />
                                    </Col>
                                </Row>
                                <div className='text-right my-2'>
                                    <X color='#FFFFFF' onClick={props.selfStoryViewModal} />
                                </div>
                                <div className="StoryViewImageSlider" style={{ maxHeight: "100vh" }}>
                                    <div className="col-xl-9 col-lg-11 col-md-11 p-0">
                                        <Slider {...gallerySettings} className="default-space">
                                            {StoriesView.map((item, index) => (
                                                <div className='w-100 d-flex align-items-center  justify-content-center flex-column ' style={{ maxHeight: "100vh", minHeight: "calc(100vh / 1.2)" }}>
                                                    <div>
                                                        <div className="StroyImagePrivewSlider position-relative mb-1"  >
                                                            <img style={{ zIndex: 0 }} className='StoryPreviewImageSrc' src={item.img} alt="" />
                                                            <div className='d-flex justify-content-between zIndex2'>
                                                                <div className='d-flex py-2 align-items-center'>
                                                                    <img src={cricleimg} width={60} className='img-fluid' alt='ImageCircle' />
                                                                    <div className='d-flex flex-column align-items-start ml-2 zIndex2'>
                                                                        <h4 className="text-white d-inline-block text-truncate" >{item.name}</h4>
                                                                        <h6 className="text-white d-inline-block text-truncate" >{item.time}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                    </p>
                                                                    <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                    </p>
                                                                    <UncontrolledButtonDropdown>
                                                                        <DropdownToggle color=''>
                                                                            <MoreVertical size={22} color='#FFFFFF' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='StoryThreeDotButtonMenu' onClick={toggleReport}>
                                                                            <DropdownItem className='dropdownitem'>Report Story</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center StoryTextContainerAdjustment' onClick={toggleLikeViewModal}>
                                                        <ChevronsUp size={40} role='button' color='#ffffff' />
                                                        <h5 style={{ fontSize: "clamp(0.5rem, 0.9rem, 1.4rem)" }} className='text-white text-wrap' role='button'>45+ other person viewed your story</h5>
                                                        <ul className="matual-friend-blk d-flex justify-content-center py-2">
                                                            {
                                                                avatardata.map((item, index) => {
                                                                    return (
                                                                        <li className="popover-cls d-flex" key={index}>
                                                                            <img src={item.img} className="img-fluid bg-img rounded-circle" alt="..." />
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))}

                                        </Slider>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-xl-6 col-lg-8 col-md-11 col-sm-11 mx-auto  imageMaxWidth  StoryAspectRatioAdjustment position-relative">
                                        <TabContent activeTab={StoryActiveSlider}>
                                            <TabPane tabId="1">
                                                <Slider {...gallerySettings} className="default-space StoryBtnSlickSlider">
                                                    <div className='img-view-container rounded Mx_h_100 StoryAspectRatioAdjustment'>
                                                        <img alt="storyImage" src='https://images.pexels.com/photos/1743165/pexels-photo-1743165.jpeg?auto=compress&cs=tinysrgb&w=1600'
                                                            className='img-fluid h-100 img-gradient rounded' />
                                                        <Row className='img-top-head w-100 ml-2'>
                                                            <Col xl='4' lg='4' md='6' sm='6' xs='6' className='d-flex py-2 align-items-center'>
                                                                <img src={cricleimg} width={60} className='img-fluid' alt='UserIma' />
                                                                <div className='ml-2'>
                                                                    <h4 className='text-white'>Ella Sofia</h4>
                                                                    <p className='text-muted'>20 Min ago</p>
                                                                </div>
                                                            </Col>
                                                            <Col xl='8' lg='8' md='6' sm='6' xs='6' className='d-flex align-items-center justify-content-end'>
                                                                <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                </p>
                                                                <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                </p>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color=''>
                                                                        <MoreVertical size={22} color='#FFFFFF' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='bizpagefordropdownmenu' onClick={toggleDeleteModalOpen}>
                                                                        <DropdownItem className='dropdownitem'>Delete Story</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='img-view-container rounded Mx_h_100 StoryAspectRatioAdjustment'>
                                                        <img src='https://images.pexels.com/photos/1624438/pexels-photo-1624438.jpeg?auto=compress&cs=tinysrgb&w=1600'
                                                            className='img-fluid h-100 img-gradient rounded' alt="Edited Imagea" />
                                                        <Row className='img-top-head w-100 ml-2'>
                                                            <Col xl='4' lg='4' md='6' sm='6' xs='6' className='d-flex py-2 align-items-center'>
                                                                <img src={cricleimg} width={60} className='img-fluid' alt='photos' />
                                                                <div className='ml-2'>
                                                                    <h4 className='text-white'>Ella Sofia</h4>
                                                                    <p className='text-muted'>20 Min ago</p>
                                                                </div>
                                                            </Col>
                                                            <Col xl='8' lg='8' md='6' sm='6' xs='6' className='d-flex align-items-center justify-content-end'>
                                                                <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                </p>
                                                                <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                </p>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color=''>
                                                                        <MoreVertical size={22} color='#FFFFFF' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='bizpagefordropdownmenu' onClick={toggleDeleteModalOpen}>
                                                                        <DropdownItem className='dropdownitem'>Delete Story</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className='img-view-container rounded Mx_h_100 StoryAspectRatioAdjustment'>
                                                        <img src='https://images.pexels.com/photos/1266810/pexels-photo-1266810.jpeg?auto=compress&cs=tinysrgb&w=1600'
                                                            className='img-fluid h-100 img-gradient rounded' alt="this " />
                                                        <Row className='img-top-head w-100 ml-2'>
                                                            <Col xl='4' lg='4' md='6' sm='6' xs='6' className='d-flex py-2 align-items-center'>
                                                                <img src={cricleimg} width={60} className='img-fluid' alt='photods' />
                                                                <div className='ml-2'>
                                                                    <h4 className='text-white'>Ella Sofia</h4>
                                                                    <p className='text-muted'>20 Min ago</p>
                                                                </div>
                                                            </Col>
                                                            <Col xl='8' lg='8' md='6' sm='6' xs='6' className='d-flex align-items-center justify-content-end'>
                                                                <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                </p>
                                                                <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                </p>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color=''>
                                                                        <MoreVertical size={22} color='#FFFFFF' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='bizpagefordropdownmenu' onClick={toggleDeleteModalOpen}>
                                                                        <DropdownItem className='dropdownitem'>Delete Story</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Slider>
                                                <div className='text-center StoryTextContainerAdjustment' onClick={toggleLikeViewModal}>
                                                    <ChevronsUp size={40} role='button' color='#ffffff' />
                                                    <h5 className='text-white mb-4' role='button'>45+ other person viewed your story</h5>
                                                    <ul className="matual-friend-blk d-flex justify-content-center py-2">
                                                        {
                                                            avatardata.map((item, index) => {
                                                                return (
                                                                    <li className="popover-cls d-flex" key={index}>
                                                                        <img src={item.img} className="img-fluid bg-img rounded-circle" alt="..." />
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Modal>
            }

        </>
    )
}

export default SelfStoryViewModal