import React, { useState } from 'react'
import { ChevronsRight, Image, XCircle } from 'react-feather'
import { Button, Card, Col, FormText, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap'
import ColorPicker from '../../../Story/ColorPicker'
import CreatepostFooter from './CreatepostFooter'
import CreatepostHeader from './CreatepostHeader'
import Media from './Media'
import { ImageGrid } from "react-fb-image-video-grid";
import Slider from "react-slick";

const Text = (props) => {

    const [mediaSec, setmediaSec] = useState(false)
    const [selectedImages, setSelectedImages] = useState([])
    const [show, setShow] = useState(false)
    const togglehide = () => {
        setShow(!show)
    }
    const onSelectFile = (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles)
        const imageArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file)
        })

        setSelectedImages((previousImages) => previousImages.concat(imageArray))
        console.log(selectedFiles.length)
    }
    var storySettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const filterimg = [
        {
            data: 'https://www.pngfind.com/pngs/m/232-2325702_picture-free-shape-matters-blur-filter-png-transparent.png'
        },
        {
            data: 'https://i.stack.imgur.com/PEnJm.png'
        },
        {
            data: 'https://i.stack.imgur.com/PEnJm.png'
        },
        {
            data: 'https://i.stack.imgur.com/PEnJm.png'
        },
        {
            data: 'https://i.stack.imgur.com/PEnJm.png'
        },
        {
            data: 'https://i.stack.imgur.com/PEnJm.png'
        },
        {
            data: 'https://i.stack.imgur.com/PEnJm.png'
        }
    ]
    return (
        <div>
            <Card className='p-3 postCardForSize'>
                <CreatepostHeader
                    actionM={props.NavMedia}
                    actionT={props.NavThrought}
                    actionG={props.NavGoLive}
                    actionE={props.NavEvent}
                    actionP={props.NavPodcast}
                    actionA={props.NavArticle}
                    actionR={props.NavReccom}
                    actionAl={props.NavAlert}
                    actionPo={props.NavPoll}
                    actionS={props.NavSell}

                />
                <div style={{ background: '#F6F6F6', marginTop: '20px', borderRadius: '5px' }}>
                    <InputGroup className='input-group-merge my-2'>
                        <InputGroupAddon addonType='prepend mx-2'>
                            <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' alt='userImg' />
                        </InputGroupAddon>
                        <Input type='textarea'
                            Placeholder='What’s  going on? #Hashtag... @Mention.'
                            cols="15"
                            rows="5"
                            maxLength={'5000'}
                            className="border-0 inputFortext inputForback" />
                        <img
                            src="/assets/images/pen-solid-2.png"
                            width={15} height={15}
                            className="m-2"
                            alt="pen"
                        />
                    </InputGroup>
                </div>
                <FormText className='text-muted text-right'>{mediaSec ? 'Max 300 Characters' : 'Max 5000 Characters'}</FormText>
                <div className={mediaSec ? 'my-1 d-none' : 'my-1'}>
                    <ColorPicker />
                </div>
                <div className='imgForgrid'>
                    {
                        selectedImages &&
                        selectedImages.map((image, index) => {
                            return (
                                <ImageGrid showModal={false}>
                                    <div key={image} className='imgForstyle'>

                                        <label className='labelForMediaM' for="inputTag">

                                            <Image size={15} />  Add Photo/ Videos
                                            <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name='images' onChange={onSelectFile} type="file" hidden />

                                            {/* <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name={props.name} onChange={props.mediapreview} type="file" hidden /> */}
                                        </label>

                                        <img src={image} className='img-fluid' alt=''/>
                                        <XCircle className='positionForCrossbutton' onClick={() => setSelectedImages(selectedImages.filter((e) => e !== image))} />



                                        {/* <img src={image.File[1]} className='img-fluid' /> */}



                                        <div>
                                            <div className='m-1 text-right'>
                                                <Input type='text' Placeholder='Type your caption' className='form-control' />
                                                <span>Max 60 Characters</span>
                                            </div>
                                            <div>
                                                <Slider {...storySettings} className="default-space">
                                                    {
                                                        filterimg.map((data) => (
                                                            <div>
                                                                <Card className="">
                                                                    <img height={100} width='100%' src={data.data} alt="overview" />
                                                                </Card>

                                                            </div>
                                                        ))
                                                    }
                                                </Slider>
                                            </div>

                                        </div>



                                    </div>

                                </ImageGrid>

                            )
                        })
                    }

                </div>
                {/* {
                    mediaSec ? <div className='imgForgrid'>
                        {
                            selectedImages &&
                            selectedImages.map((image, index) => {
                                return (
                                    <ImageGrid showModal={false}>
                                        <div key={image} className='imgForstyle'>

                                            <label className='labelForMediaM' for="inputTag">

                                                <Image size={15} />  Add Photo/ Videos
                                                <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name='images' onChange={onSelectFile} type="file" hidden />
                                            </label>

                                            <img src={image} className='img-fluid' />
                                            <XCircle className='positionForCrossbutton' onClick={() => setSelectedImages(selectedImages.filter((e) => e !== image))} />



                                            <div>
                                                <div className='m-1 text-right'>
                                                    <Input type='text' Placeholder='Type your caption' className='form-control' />
                                                    <span>Max 60 Characters</span>
                                                </div>
                                                <div>
                                                    <Slider {...storySettings} className="default-space">
                                                        {
                                                            filterimg.map((data) => (
                                                                <div>
                                                                    <Card className="">
                                                                        <img height={100} width='100%' src={data.data} alt="overview" />
                                                                    </Card>

                                                                </div>
                                                            ))
                                                        }
                                                    </Slider>
                                                </div>

                                            </div>



                                        </div>

                                    </ImageGrid>

                                )
                            })
                        }

                    </div>
                        :
                        <div>
                            <Media />
                        </div>
                } */}

                <CreatepostFooter name='images' mediapreview={onSelectFile} mediaaction={() => setmediaSec(!mediaSec)} mediafill={mediaSec ? 'true' : 'false'} />
            </Card>
        </div>
    )
}

export default Text