import React from 'react'
import { Fragment } from 'react'
import Events from './Events'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import "./events.css"


const index = () => {
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <Events />
                </div>
                <RightSidebar />

            </div>

        </Fragment>
    )
}

export default index