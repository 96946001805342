import React, { useEffect, useState } from "react";
import {
  Copy,
  Flag,
  Check,
  Grid,
  List,
  MinusCircle,
  MoreVertical,
  CornerLeftDown,
  PlusCircle,
  Search,
  Share,
  UserPlus,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { loadAllUserFollowers } from "../../../Services/Actions/Connection/getUserFollowersAction";
import { loadAllRemoveFollower } from "../../../Services/Actions/Connection/getRemoveFollowerAction";
import { loadAllFollowUnFollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import { getActionUserReported } from "../../../Services/Actions/Connection/getUserReportAction";
import BlockModal from "../ConnectionModal/BlockModal";
import LeftSidebar from "../../LeftSidebar";
import RightSidebar from "../../RightSidebar";
import ConnectionPlaceMenu from "./ConnectionPlaceMenu";
import { Button, InputGroup } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import india_svg from "../../../NewComponents/IMG/india_svg.svg";
import { FollowersList } from "../ListView/FollowersList";
import UnfollowModal from "../ConnectionModal/UnfollowModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import AddCategoryModal from "../ConnectionModal/AddCategoryModal";
import NewRemoveModal from "../ConnectionModal/NewRemoveModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import PublicAccount from "../ConnectionModal/PublicAccount";
import PrivateAccount from "../ConnectionModal/PrivateAccount";
import CommanHeader from "../CommanHeader";
import Header from "../../Header";
import "../Style.css";
import axios from "axios";
import { NavLink } from "react-router-dom";

const FollowersScreen = () => {
  const [Gridview, Setgridview] = useState(false);
  const [pageIndex, setPageIndex] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(15); // Number of items per page
  const [desiredPage, setDesiredPage] = useState(1);
  const [userId, setUserId] = useState("");
  const [fullName, setFullName]= useState("");

  const { getUserFollowers } = useSelector(
    (state) => state.GetUserFollowersData
  );

  const withdrawUserRequest = async (id) => {
    try {
      const body = {
        id,
        type:"people",
      };
      let user = JSON.parse(localStorage.getItem('sociomeeUser'));
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/hamBurgerMenu/withdrawUserRequest`,
        body,
        config
      );
    } catch (error) {
      console.log('Error getting withdraw request:', error);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAllUserFollowers());
  }, []);

  //search opearation
  const [searchItem, setSearchItem] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);

  const filteredData = getUserFollowers?.rows?.filter((item) =>{
    const fullNameMatch = item.fullName?.toLowerCase().includes(searchItem.toLowerCase());
    const userNameMatch = item.userName?.toLowerCase().includes(searchItem.toLowerCase());
    return fullNameMatch || userNameMatch;
  });

  // ------------------ share Modal State ------------------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };

  //follow back
  const toggleFollowBack = (request) => {
    dispatch(loadAllFollowUnFollow(request));
  };

  const copyProfileURL = (userId) => {
    const profileURL = `${process.env.REACT_APP_API_URL}/user/getUserProfileById?id=${userId}`;

    navigator.clipboard
      .writeText(profileURL)
      .then(() => {
        console.log("Profile URL copied to clipboard:", profileURL);
        alert("copied profile url", profileURL);
      })
      .catch((error) => {
        console.error("Failed to copy profile URL:", error);
      });
  };

  // Pagination logic
  const itemsPerPage = 15; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (event, newPage) => {
      setCurrentPage(newPage);
  };

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

 // handle follow back
  const handleFollowBack = async (request) => {
    await dispatch(loadAllFollowUnFollow(request));
    dispatch(loadAllUserFollowers());
  };
  
//handle withdraw requests
  const handlewithDrawRequests = (request) => {
    withdrawUserRequest(request.followingRequestId)
    setTimeout(() => {
      dispatch(loadAllUserFollowers());
    }, 1000);
  }

 // ------------------ Remove Followers Modal State ------------------
 const [removeModalOpen, setRemoveModalOpen] = useState(false)
 const toggleRemoveModalOpen = () => {
   setRemoveModalOpen((prevState) => !prevState)
 }
 const[removeConfirm,setRemoveConfirm] = useState(false)
   useEffect(()=>{
    if(removeConfirm){
       setTimeout(() => {
         dispatch(loadAllUserFollowers());
         setRemoveConfirm(false);
       }, 1000);
    } 
   },[removeConfirm])

  //  ------------------= Start Add RelationShip State ------------------
  //const [addCategoryOptions, setAddCategoryOptions] = useState(false);
  //const toggleAddCategory = () => {
  // setAddCategoryOptions((preState) => !preState);
  //};

  //  ------------------ Start ReportedOption State  ------------------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
    setSelectedRequest(request)
  };
  const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
              setTimeout(() => {
                dispatch(loadAllUserFollowers());
                setReportConfirm(false);
              }, 1000);
           } 
    },[reportConfirm])


  //block user
  const [blockModalOpen, setBlockModalOpen] = useState(false)
  const toggleBlockModalOpen = () => {
    setBlockModalOpen((prevState) => !prevState)
  }
  const[blockConfirm,setBlockConfirm] = useState(false)
  useEffect(()=>{
    if(blockConfirm){
       setTimeout(() => {
        dispatch(loadAllUserFollowers());
         setBlockConfirm(false);
       }, 1000);
    } 
   },[blockConfirm])


  // ------------- Unfollow Modal State -------------
  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false)
  const toggleUnfollowModal = (request) => {
    setUnfollowModalOpen((prevState) => !prevState)
    setSelectedRequest(request);
  }
  const[unfollowConfirm,setunfollowConfirm] = useState(false)
  useEffect(()=>{
    if(unfollowConfirm){
       setTimeout(() => {
        dispatch(loadAllUserFollowers());
         setunfollowConfirm(false);
       }, 1000);
    } 
   },[unfollowConfirm])


  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <Card>
            {/* comman header calling */}
            <CommanHeader />
            {/* <Header/> */}
            <CardBody>
              {/* connection tabs calling */}
              <ConnectionPlaceMenu />
              {/* ---------------MAIN--------------- */}
              <div className="mppage-heading mt-4">
                <div className="group-custom-block">
                  <div className="nav-flex-container">
                    <div>
                      <h3 className="connection-sub-heading">
                        Your Follower ({getUserFollowers?.rows?.length})
                      </h3>
                    </div>
                    <div className="sub-nav-flex size-sm-mt-4 mb-2">
                      <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                        <Search color="#666666" size={16} />
                        <Input
                          type="search"
                          placeholder="  Find..."
                          className="coonection-input-style"
                          value={searchItem}
                          onChange={(e) => setSearchItem(e.target.value)}
                        />
                      </InputGroup>
                      <div className="list-grid-toggle size-xs-mt-4 rem-mob-view">
                        <Button
                          className={
                            Gridview ? "grid-toggle-customize-css " : ""
                          }
                          outline
                          onClick={() => Setgridview(false)}
                        >
                          <Grid size={16} />
                        </Button>
                        <Button
                          className={
                            Gridview ? " " : "grid-toggle-customize-css"
                          }
                          outline
                          onClick={() => Setgridview(true)}
                        >
                          <List size={16} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {Gridview ? (
                    <FollowersList followingData={filteredData.slice(startIndex, endIndex)} withdrawUserRequest={withdrawUserRequest} />
                  ) : (
                    <Row className="connections_PeopleThisGrid mt-2">
                        {filteredData &&
                          filteredData?.slice(startIndex, endIndex).map((request, index) => {
                          return (
                            <Col lg="4" className="w-100">
                              <div
                                key={index}
                                className="position-relative connection-card  content-mp-block d-flex box-shadow-style justify-content-between"
                              >
                                <div className="media-body d-md-block">
                                  <div class="media media-new d-flex align-items-start">
                                    <div class="user-img d-flex align-items-start popover___wrapper ">
                                      <img
                                        src={request?.profileImage}
                                        className="rounded-circle "
                                        height={45}
                                        width={45}
                                        alt="profile"
                                      />
                                      <span class="available-stats online"></span>
                                      {request?.isPrivate ? <PrivateAccount request={request}/> : <PublicAccount request={request}/>}
                                    </div>
                                    <div class="media-body d-md-block pl-1">
                                      <NavLink to="/MyProfile">
                                        <h4 className="m-0 text-wrap name-style d-inline-block text-truncate">
                                          {request?.fullName?.substring(0, 21) ||
                                            "NA"}
                                        </h4>
                                      </NavLink>
                                      <div className="d-flex my-1 text-wrap">
                                        <p className="user-name-style text-wrap rem-mob-view-small">
                                          {request?.userName || "NA"} |
                                        </p>
                                        <span><img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} /></span>
                                      </div>
                                      <p className="professional-style rem-mob-view">
                                        {request?.profession || "No Profession"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end flex-column justify-content-between">
                                <UncontrolledButtonDropdown
                                    className="d-flex align-items-start flex-column rem-mob-view-small"
                                    direction="left"
                                  >
                                    <DropdownToggle
                                      color="light"
                                      className="connection-cutomize-dropdown-toggle pb-0 pr-0"
                                    >
                                      <MoreVertical size={22} />
                                    </DropdownToggle>
                                    <DropdownMenu className="Connection-dropdown">
                                      {/*<DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={() => toggleFollowBack(request)}
                                      >
                                        <UserPlus size={15} className="mr-2 icon-color-style" />
                                        Follow Back
                                      </DropdownItem>*/}

                                      <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                        onClick={() => { 
                                            toggleRemoveModalOpen();
                                            setFullName(request.fullName);
                                            setUserId(request.id); 
                                          }} 
                                      >
                                        <MinusCircle size={15} className='mr-2' />
                                        Remove Follower
                                      </DropdownItem>
                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={toggleShareOpen}
                                      >
                                        <Share size={15} className="mr-2 icon-color-style" />
                                        Share Profile
                                      </DropdownItem>

                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={() => copyProfileURL(request.div)}
                                      >
                                        <Copy size={15} className="mr-2 icon-color-style" />
                                        Copy Profile URL
                                      </DropdownItem>

                                      <DropdownItem
                                        className="border-bottom py-2 menu-hover-style"
                                        onClick={() => {
                                          toggleBlockModalOpen();
                                          setUserId(request.id);
                                        }}
                                      >
                                        <MinusCircle size={15} className="mr-2 icon-color-style" />
                                        Block
                                      </DropdownItem>

                                      <DropdownItem
                                        className="py-2 menu-hover-style"
                                        onClick={() => toggleReport(request)}
                                      >
                                        <Flag size={15} className="mr-2" />
                                        Report User
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>

                                  <div
                                    role="button"
                                    className="mt-3 mb-0 d-flex align-items-end"
                                  >
                                  {/* Check if the user is a follower */}
                                  {request.isFollower ? (
                                                      <>
                                                        <Button
                                                          color="flat-success"
                                                          className="btn-transparent pb-0 pr-0"
                                                          onClick={() =>
                                                            toggleUnfollowModal(request)
                                                          }
                                                        >
                                                          <Check size={15} color="#666666" />
                                                          &nbsp;
                                                          <p className="font-weight-bold text-right">
                                                            Following
                                                          </p>
                                                        </Button>
                                                      </>
                                                    ) : request.isPrivate ? (
                                                      // If not a follower and the account is private, check follow back request status
                                                      request.followBackRequestStatus === "pending" ? (
                                                                                    <>
                                                                                      <Button color='flat-success' className='btn-transparent pb-0 pr-0'
                                                                                        onClick={() =>
                                                                                          handlewithDrawRequests(request)
                                                                                        }
                                                                                      >
                                                                                        <CornerLeftDown size={15} color='#2F80ED' />
                                                                                          &nbsp;
                                                                                        <p className="font-weight-bold connection-blue-text rem-mob-view">
                                                                                          Withdraw
                                                                                        </p>
                                                                                      </Button>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Button
                                                                                      color="flat-success"
                                                                                      className="btn-transparent pb-0 pr-0"
                                                                                      onClick={() => handleFollowBack(request)}
                                                                                    >
                                                                                      <UserPlus size={15} color="#81C14B" />
                                                                                        &nbsp;
                                                                                      <p className="font-weight-bold text-primary text-right">
                                                                                        Follow Back
                                                                                      </p>
                                                                                    </Button>
                                                                                  )) 
                                                                        : (
                                                                        // If not a follower and the account is not private
                                                                        <>
                                                                          <Button
                                                                            color="flat-success"
                                                                            className="btn-transparent pb-0 pr-0"
                                                                            onClick={() => handleFollowBack(request)}
                                                                          >
                                                                            <UserPlus size={15} color="#81C14B" />
                                                                              &nbsp;
                                                                            <p className="font-weight-bold text-primary text-right">
                                                                              Follow Back
                                                                            </p>
                                                                          </Button>
                                                                        </>
                                                                        )}
                                                                        
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  )}
                  <Row>
                    <Col xl="12" className="d-flex justify-content-center">
                      {getUserFollowers?.length === 0 && (
                        <h3 className="text-center connection-sub-heading">
                          No Followers Found
                        </h3>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            {/*  ------------------ Pagination ------------------ */}
            <div className="d-flex justify-content-center my-3">
            {filteredData?.length > itemsPerPage && (
                    <Stack spacing={2} >
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            size="large"
                            color="primary"
                        />
                    </Stack>
                )}
            </div>
          </Card>
        </div>
        <RightSidebar />
      </div>

      {/*  ------------------ Add Report Option  ------------------ */}

      { reportOpen && (
          <ConnectionReportModal
            request={selectedRequest}
            modalOpen={reportOpen}
            reportModalOpen={toggleReport}
            setReportConfirm ={setReportConfirm}
          />
      )}

      {/*  ------------------ Add CategoryModal Status  ------------------ */}

      {/*<AddCategoryModal
        openModal={addCategoryOptions}
        cateModalOpen={toggleAddCategory}
      />*/}
      {/*  ------------------ Remove Member Modal  ------------------ */}

      { removeModalOpen && (
          <NewRemoveModal
            userId={userId}
            fullName={fullName}
            openModal={removeModalOpen}
            removeModalOpen={toggleRemoveModalOpen}
            setRemoveConfirm={setRemoveConfirm}
          />
      )}
       {/*------------------------- block modal----------------------- */}

       { blockModalOpen && (
          <BlockModal
            request={userId}
            openModal={blockModalOpen}
            removeModalOpen={toggleBlockModalOpen}
            setBlockConfirm={setBlockConfirm}
          />
      )}

      {/* ------------------ Start Share Modal  ------------------*/}
      {/*<ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />*/}
      {/* ------------- Unfollow Modal Modal -------------*/}
      { unfollowModalOpen && (
          <UnfollowModal
            request={selectedRequest}
            openModal={unfollowModalOpen}
            unfollowModalOpen={toggleUnfollowModal}
            setunfollowConfirm={setunfollowConfirm}
          />
      )}
    </>
  );
};

export default FollowersScreen;
