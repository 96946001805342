import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import ALert from '../Universal-components/post-creations/sub-components/Alert'
import Article from '../Universal-components/post-creations/sub-components/Article'
import CreatepostHeader from '../Universal-components/post-creations/sub-components/CreatepostHeader'
import Event from '../Universal-components/post-creations/sub-components/Event'
import Text from '../Universal-components/post-creations/sub-components/Text'
import Poll from '../Universal-components/post-creations/sub-components/Poll'
import Recommendation from '../Universal-components/post-creations/sub-components/Recommendation'
import Thought from '../Universal-components/post-creations/sub-components/Thought'

const DynamicTab = () => {

    const [active, setActive] = useState('1')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    return (
        <React.Fragment>
            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>
                    <Text
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}

                    />
                </TabPane>
                <TabPane tabId='2'>
                    <Thought
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}
                    />
                </TabPane>
                <TabPane tabId='3'>

                </TabPane>
                <TabPane tabId='4'>
                    <Event
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}
                    />
                </TabPane>
                <TabPane tabId='5'>
                    <Link to='/CreateGoLive'>Go Live</Link>
                </TabPane>
                <TabPane tabId='6'>
                    <Article
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}
                    />
                </TabPane>
                <TabPane tabId='7'>
                    <Recommendation
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}
                    />
                </TabPane>
                <TabPane tabId='8'>
                    <ALert
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}
                    />
                </TabPane>
                <TabPane tabId='9'>
                    <Poll
                        NavMedia={() => { toggle('1') }}
                        NavThrought={() => { toggle('2') }}
                        NavGoLive={() => { toggle('3') }}
                        NavEvent={() => { toggle('4') }}
                        NavPodcast={() => { toggle('5') }}
                        NavArticle={() => { toggle('6') }}
                        NavReccom={() => { toggle('7') }}
                        NavAlert={() => { toggle('8') }}
                        NavPoll={() => { toggle('9') }}
                        NavSell={() => { toggle('10') }}
                    />
                </TabPane>
                <TabPane tabId='10'>

                </TabPane>

            </TabContent>
        </React.Fragment>
    )
}

export default DynamicTab