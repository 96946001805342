import React, { useState } from 'react'
import { ChevronsRight, Maximize2, Minimize2, MoreVertical, Send } from 'react-feather'
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import UserAdPro from '../../../../../NewComponents/IMG/user2.png'
import ad from '../../../../../NewComponents/IMG/ad.png'
const PreviewsSection = ({ heading, imageUrl, subHeading, media, discriptions, imageDataUrl, imageGroupUrl, imageStoryUrl, imageBizUrl, setClicked, imageMarketUrl, imagePodcastUrl, imageEventUrl }) => {
    const [centeredModal, setCenteredModal] = useState(false)
    const Previewmodal = () => {
        return (
            <>
                <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-xl modal-dialog-centered'>
                    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Advanced preview</ModalHeader>
                    <ModalBody>
                        <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                            {/* Feed Card */}
                                            <Col xs='4' xl='4' >
                                                {/* onClick={() => { setAccord(1) }} */}
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="post-details">
                                                            <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' style={{ height: '200px', objectFit: 'cover'  }} />
                                                                <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-9px' }}>
                                                                    <h4 style={{
                                                                        fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}>{heading}</h4>

                                                                    {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '26px',
                                                                        left: '1px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '100%',
                                                                    }}>
                                                                        <p style={{
                                                                            fontSize: '10px',
                                                                            marginLeft: '3px',
                                                                            color: 'white',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}>{subHeading}</p></div>
                                                                </div>
                                                                {/* <div><p style={{
                                                                    fontSize: '10px', textWrap: 'nowrap', marginLeft: '3px', overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>{subHeading}</p></div> */}
                                                            </div>
                                                            <div className="detail-box">
                                                                <h4 className="small-preview-heading" style={{
                                                                    fontSize: '10px',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                                </h4>
                                                            </div>
                                                            <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*====================== Podcast Card================= */}
                                            {/* onClick={() => { setAccord(2) }} */}
                                            <Col xs='4' xl='4'  >
                                                <h3 className='p-heading ml-1'>Podcast</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1 ">
                                                    <Card style={{ borderRadius: '5px' }}>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />

                                                                    <div className="ml-1">
                                                                        <h6 className="sizingForTextheading" style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center sizingForText p-2">
                                                                <h6 className="sizingForTextsmall" style={{ fontSize: '12px', marginTop: '0.2rem' }}>Related products</h6>
                                                                <p className="text-info sizingForTextvsmall" style={{ fontSize: '12px', marginTop: '0.2rem' }}>View all</p>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <div className="d-flex p-2" style={{ margin: '-3px' }}>
                                                                <div className='shadow'>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div style={{ position: 'absolute', top: '86px' }}>
                                                                        <img src={ad} alt="" />
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            {/* <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div> */}
                                                                            <h4 style={{
                                                                                fontSize: '10px',
                                                                                textWrap: 'nowrap',
                                                                                marginLeft: '3px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                marginTop: '-18px',
                                                                                color: 'white',
                                                                                padding: '0',
                                                                            }}>
                                                                                {heading && heading.length > 8 ? `${heading.substring(0, 8)}...` : heading}
                                                                            </h4>

                                                                        </h6>
                                                                        <div className="preview-detail-small" style={{ marginTop: '18px' }}>
                                                                            <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                                            <p className='small-preview-small preview-know' style={{
                                                                                fontSize: '10px', whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>Know more</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='shadow ml-2'>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div style={{ position: 'absolute', top: '86px' }}>
                                                                        <img src={ad} alt="" />
                                                                    </div>
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <h4 style={{
                                                                                fontSize: '10px',
                                                                                textWrap: 'nowrap',
                                                                                marginLeft: '3px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                marginTop: '-18px',
                                                                                color: 'white',
                                                                                padding: '0',
                                                                            }}>
                                                                                {heading && heading.length > 8 ? `${heading.substring(0, 8)}...` : heading}
                                                                            </h4>

                                                                            {/* <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div> */}
                                                                        </h6>
                                                                        <div className="preview-detail-small" style={{ marginTop: '18px' }}>
                                                                            <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                                            <p className='small-preview-small preview-know' style={{
                                                                                fontSize: '10px', whiteSpace: 'nowrap',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>Know more</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*================== Group Card=================*/}
                                            {/*  onClick={() => { setAccord(3) }} */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded  py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1" style={{ fontSize: '12px' }}><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow rounded-2">
                                                                    {/* <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div> */}
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all Groups <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert mt-3'>
                                            {/*==================MarketPlace Card===================== */}
                                            {/* onClick={() => { setAccord(5) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>MarketPlace</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1  shadow">
                                                                    {/* <img src={UserPro} className='img-fluid' alt="cardImage" /> */}
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1  shadow">
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=================Event Card================= */}
                                            {/* onClick={() => { setAccord(6) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning" style={{ fontSize: '12px' }}>Sponsored</p>
                                                                <h6 className="sizingForTextvsmall" style={{ fontSize: '12px' }}>Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=================Event Card end================= */}
                                            {/*=====================Biz Page Card=============== */}
                                            {/* onClick={() => { setAccord(4) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }}/>
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>

                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all BizPages <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=====================Biz Page Card end=============== */}
                                        </Row>
                                        <Row className='rowForrevert mt-4'>
                                            {/*==================Shotz=================== */}
                                            {/* onClick={() => { setAccord(8) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>Story</h3>
                                                <div className=" m-1">
                                                    <Card inverse className=''>
                                                        {/* <CardImg alt="" src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} className='w-100' style={{ height: '350px' }} /> */}
                                                        <div className='small-bg-mains'>
                                                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                                                <div className="post-wrapper col-grid-box">
                                                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                                        <div className="profile">
                                                                            <div className="media d-flex mb-1" >
                                                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                                </a>
                                                                                <div className="media-body ml-2">
                                                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>florida roxin</h6>
                                                                                    <h6><span className=" sponsored-text" style={{ color: 'black' }}>Sponsored</span></h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                                                <MoreVertical size={15} style={{ color: 'black' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post-details">
                                                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                            <img
                                                                                src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`}
                                                                                alt=""
                                                                                style={{ width: '100%', height: '45.2vh', objectFit: 'cover' }}
                                                                            />
                                                                        </div>
                                                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                                            <h4 style={{
                                                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>{heading}</h4>

                                                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '26px',
                                                                                left: '1px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                maxWidth: '100%',
                                                                            }}>
                                                                                <p style={{
                                                                                    fontSize: '10px',
                                                                                    marginLeft: '3px',
                                                                                    color: 'white',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                }}>{subHeading}</p></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
    return (
        <>
            <div className="preview-right">

                <div className='col-lg-12'>
                    <div className="row preview-heading-head">
                        <div className='full-flex'>
                            <div className='d-flex' style={{ backgroundColor: '#ebebeb', borderRadius: '5px', cursor: 'pointer' }}>
                                {/* <Minimize2  onClick={() => setMorePreview(true)}/>
                                <h4 className='ml-1' onClick={() => setMorePreview(true)}>More Preview11</h4> */}
                                <Button
                                    size='sm'
                                    outline
                                    color='flat-primary'
                                    onClick={() => setCenteredModal(!centeredModal)}
                                >
                                    {centeredModal ? <Minimize2 size={18} /> : <Maximize2 size={15} />}
                                    <span className='align-middle ml-1' style={{ fontSize: '14px' }}>
                                        More Preview
                                    </span>
                                </Button>

                            </div>

                        </div>
                    </div>
                </div>
                <div className='post-panel-new  rounded-2 mt-2'>
                    <div className="post-wrapper col-grid-box">
                        <div className="post-title mt-1">
                            <div className="profile p-2">
                                <div className="media d-flex mb-1">
                                    <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                        data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                        <img src="/assets/images/image (1).png"
                                            className="img-fluid bg-img" alt="user" />
                                    </a>
                                    <div className="media-body ml-2 p-1">
                                        <h5 className='p-heading'>Food Market Zone</h5>
                                        <h6><span className="color-orange">Sponsored</span></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="setting-btn ms-auto setting-dropdown no-bg">
                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                    <div role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ marginTop: '-61px' }}>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-font-color iw-14"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg> */}
                                        <MoreVertical size={20} />
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                        <ul>
                                            <li>
                                                <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>save post</a>
                                            </li>
                                            <li>
                                                <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></svg>hide post</a>
                                            </li>
                                            <li>
                                                <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>unfollow sufiya</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="post-details">
                            <div className="recomandation-display-block">
                                <img src="/assets/images/image (2).png" alt='' />
                                <div className="recom-btn-cont-blk new-recom-btn-cont-blk">
                                    <h4>Food Market Zone</h4>
                                    <a href="#" className="btn btn-outline text-white" style={{ backgroundColor: 'rgba(129, 193, 75, 1)' }}>KNOW MORE</a>
                                </div>
                            </div>
                            <div className="detail-box">
                                <h5 className="tag">This award goes to the coworker who’s always doing the thankless work of keeping the office up to date on pop culture.</h5>
                            </div>
                            <div className="Why-this-ad d-flex  justify-content-between">
                                <p className='ml-3'>Why this Ad?</p>
                                <img className='mr-2' src="/assets/images/adIcon/Share-icon.png" alt="" style={{ marginBottom: '3px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ======================= */}
            <Previewmodal />
        </>

    )

}

export default PreviewsSection