import React from 'react'
import { Col, Row } from 'reactstrap'

const Media = () => {
    return (
        <div data-bs-toggle="modal" data-bs-target="#imageModel" >
            <Row className='rowForrevert'>
                <Col className='border' ><img className='w-100' height={275} src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" /></Col>
            </Row>
            <Row className='rowForrevert'>
                <Col xl='4' className='border' ><img height={130} src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" className='w-100' /></Col>
                <Col xl='4' className='border' ><img height={130} src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" className='w-100' /></Col>
                <Col xl='4' className='border' >
                    <div className='position-relative'>
                        <img height={130} src="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300" className='w-100' />

                        <div className='mediaForshade'>+ 4</div>
                    </div>

                </Col>
            </Row>

            <Row className='rowForrevert'>
                <Col className='border' >

                    <div class="embed-responsive embed-responsive-4by3">
                        <iframe className='w-100' height={275} src="https://www.youtube.com/embed/DnSjACJcYuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Col>
            </Row>
            <Row className='rowForrevert'>
                <Col xl='4' className='border' >
                    <div class="embed-responsive embed-responsive-4by3">
                        <iframe className='w-100' height={130} src="https://www.youtube.com/embed/DnSjACJcYuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Col>
                <Col xl='4' className='border' >
                    <div class="embed-responsive embed-responsive-4by3">
                        <iframe className='w-100' height={130} src="https://www.youtube.com/embed/DnSjACJcYuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Col>
                <Col xl='4' className='border' >
                    <div className='position-relative'>
                        <div class="embed-responsive embed-responsive-4by3">
                            <iframe className='w-100' height={130} src="https://www.youtube.com/embed/DnSjACJcYuQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>

                        <div className='mediaForshade'>+ 4</div>
                    </div>

                </Col>
            </Row>
        </div>
    )
}

export default Media