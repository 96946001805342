import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { InterestDetails } from './LoginDummyArray/signupdetailsArray';
import { Fragment } from 'react';
import { Row, Button } from 'reactstrap';
import LoginSignUpStepper from './LoginSignUpStepper';
import { Camera } from 'react-feather';
import LangModalNew from './Modal-Components/LangModalNew';
import { useState } from 'react';

const SignUpInterestPage = () => {

    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }



    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")



    //  ---------------------------------- Manage the check box click  -----------------------------------------
    const [state, setState] = React.useState([]);
    const handleChange = (item) => {
        if (state.includes(item)) {
            setState(state.filter(x => x !== item))
        } else {
            let selected = [...state, item]
            setState(selected);
        }
    };
    return (
        <Fragment>
            {/* =========================Language Modal ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row >
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec"><Link className="" to="/"><img src="/assets/images/logo.png" alt="logo" className="img-fluid" /></Link></div>
                            </div>
                            <div className="login-form">

                                {/* ----------------------------------- Stepper ---------------------------------------- */}
                                <LoginSignUpStepper steps={5} active={5} />
                                <div>
                                    <div className="login-title">
                                        <h2>Choose your Interests</h2>
                                    </div>
                                    <div className="login-discription">
                                        <h5 className='Login4D_400 text-center mt-3'>Please select <b className='Login81_400'>at least 3 interests</b> for a better in-app experience</h5>
                                    </div>
                                    <hr />
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <div className="form-group search-group-box">
                                                        <input type="text" className="form-control" placeholder="Search..." />
                                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="#B9B9C3" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="input-icon iw-20 ih-20">
                                                            <circle cx="11" cy="11" r="8"></circle>
                                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                        </svg>
                                                    </div>
                                                    <div className="InterestsItems ">
                                                        {InterestDetails.map((item, index) => (
                                                            <div className={`InterestItem p-2 m-1 rounded  ${state.includes(item.id) ? "active" : ""}`} value={item.name} onClick={(e) => handleChange(item.id)}>
                                                                <Camera size={14} />
                                                                <h6>{item.name}</h6>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="btn-section">
                                                    <Button color="primary" disabled={state.length < 3}>CONTINUE</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* ============================= Language Section ================================== */}
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                {/* --------------------------- Labnguages------------------------------ */}
                                {["English", "Hindi", "Deutsch"].map((item, index) => (
                                    <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                ))}
                            </span>
                            <button onClick={() => setLanguages(true)} >
                                ...More
                            </button>
                        </div>
                    </div >

                    {/* ======================== Bottom Canvas Language section  ======================================= */}
                </div>
            </section >
        </Fragment>
    )
}

export default SignUpInterestPage
