import React from 'react'
import { Card, FormText, Input } from 'reactstrap'
import CreatepostHeader from './CreatepostHeader'
import LeftquotationIcon from '../../../../NewComponents/IMG/LeftquotationIcon.svg'
import RightquotationIcon from '../../../../NewComponents/IMG/RightquotationIcon.svg'
import CreatepostFooter from './CreatepostFooter'
import Coloroption from '../../../post-components/Coloroption'
import { loadProfileByUserId } from '../../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { useSelector } from 'react-redux'

const Thought = (props) => {

    const { userProfileByUserId } = useSelector(state => state.getUserProfileByUserIdData);
    return (
        <Card className='p-3'>
            <CreatepostHeader
                actionM={props.NavMedia}
                actionT={props.NavThrought}
                actionG={props.NavGoLive}
                actionE={props.NavEvent}
                actionP={props.NavPodcast}
                actionA={props.NavArticle}
                actionR={props.NavReccom}
                actionAl={props.NavAlert}
                actionPo={props.NavPoll}
                actionS={props.NavSell}
            />
            <div className='d-flex align-items-center py-2'>
                <img className='rounded-circle border' width={30} height={30} src={userProfileByUserId?.profileImageThumb} alt='profileImage'/>
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >{userProfileByUserId?.fullName}</h5>
            </div>
            <div>
                <h3 style={{ color: '#FF007A' }} className='py-2'>#thoughtOfTheDay</h3>
                <div>
                    <img src={LeftquotationIcon} alt="icon" />
                    <div className='mx-4 text-center'>
                        <Input
                            type="textarea"
                            className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                            placeholder="Express your thoughts..."
                            cols="15"
                            rows="5"
                        />
                        {/* <FormText className='text-muted text-right'>Max 3000 Characters</FormText> */}
                    </div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                </div>
            </div>
            <Coloroption />
            <CreatepostFooter />
        </Card>
    )
}

export default Thought