import * as types from '../../Constants/Connection/index';
import axios from 'axios'

const userSentRequestsGet = (userSentRequests) => ({
    type: types.GET_USER_SENT_REQUESTS,
    payload: userSentRequests,
});


export const AllUserSentRequest = (type) => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user?.token) {
            

            axios.post(
                `${process.env.REACT_APP_IPURL}/hamburgermenu/getAllSentRequestList`,
                {
                    type: type,
                    pageIndex: 0,
                    pageSize: "",
                    sortBy: ""
                },
                { headers: { Authorization: `Bearer ${user?.token}` } }
            )
                .then((res) => {
                    dispatch(userSentRequestsGet(res.data.data.successResult))
                    //console.log("find requestbhbuhbhujbuhjbuyh============>", res);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

