import { Fragment, useState } from 'react'
import { Radio } from '@mui/material'
import { Checkbox } from '@mui/material'
import Pen1 from '../../profile-components/Img/Pen1.png'

import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Clock, Globe, Mail, MapPin, Phone, XCircle, ChevronRight } from 'react-feather'
import HeadingPointModal from './HeadingPointModal'
import HeadingDespModal from './HeadingDespModal'
const Timemodal2 = (props) => {


    const [isOpen, setIsOpen] = useState(false);
    const handleCheckboxChange = () => {
        setIsOpen(!isOpen);
    };
    const [isOpen1, setIsOpen1] = useState(false);
    const handleCheckboxChange1 = () => {
        setIsOpen1(!isOpen1);
    };
    const [isOpen2, setIsOpen2] = useState(false);
    const handleCheckboxChange2 = () => {
        setIsOpen2(!isOpen2);
    };
    const [isOpen3, setIsOpen3] = useState(false);
    const handleCheckboxChange3 = () => {
        setIsOpen3(!isOpen3);
    };
    const [isOpen4, setIsOpen4] = useState(false);
    const handleCheckboxChange4 = () => {
        setIsOpen4(!isOpen4);
    };
    const [isOpen5, setIsOpen5] = useState(false);
    const handleCheckboxChange5 = () => {
        setIsOpen5(!isOpen5);
    };
    const [isOpen6, setIsOpen6] = useState(false);
    const handleCheckboxChange6 = () => {
        setIsOpen6(!isOpen6);
    };
    return (
        <Fragment>
            {
                props.isOpen !== false && (
                    <Modal isOpen={props.isOpen5} toggle={props.toggleOpenPoint3} className='modal-dialog-centered'>
                        <div

                            onClick={props.toggleOpenPoint3}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>

                        <div className='w-100 border-bottom'>
                            <div className=' mx-3 py-3  d-flex justify-content-between Heading_Invite_Section'>
                                <h4 >&nbsp;&nbsp;Add/Edit Timing </h4>
                                <span style={{ cursor: "pointer", color: "#81C14B" }} >Done</span>
                            </div>
                        </div>
                        {/* <ModalHeader className="Biz_Aditional_SECTION">
                            <h3>Update Biz Hours </h3>
                            <h4>Save</h4>
                        </ModalHeader> */}
                        <ModalBody>



                            <FormGroup>
                                <div className="">

                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange} checked={isOpen}  className='checkbox'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Monday</h4>
                                            <p>
                                                <span className={isOpen ? 'open-text' : 'closed-text'}>
                                                    {isOpen ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen  && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen && (
                                            <div className="BizAbout_edit_Icon"  onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </FormGroup>


                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange1} checked={isOpen1} className='checkbox'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Tuesday</h4>
                                            <p>
                                                <span className={isOpen1 ? 'open-text' : 'closed-text'}>
                                                    {isOpen1 ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen1 && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen1 && (
                                            <div className="BizAbout_edit_Icon"  onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange2} checked={isOpen2}className='checkbox' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Wednesday</h4>
                                            <p>
                                                <span className={isOpen2 ? 'open-text' : 'closed-text'}>
                                                    {isOpen2 ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen2 && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen2 && (
                                            <div className="BizAbout_edit_Icon" onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange3} checked={isOpen3} className='checkbox' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Thrusday</h4>
                                            <p>
                                                <span className={isOpen3 ? 'open-text' : 'closed-text'}>
                                                    {isOpen3 ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen3 && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen3 && (
                                            <div className="BizAbout_edit_Icon" onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange4} checked={isOpen4} className='checkbox'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Friday</h4>
                                            <p>
                                                <span className={isOpen4 ? 'open-text' : 'closed-text'}>
                                                    {isOpen4 ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen4 && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen4 && (
                                            <div className="BizAbout_edit_Icon" onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </FormGroup>


                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange5} checked={isOpen5} className='checkbox' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Saturday</h4>
                                            <p>
                                                <span className={isOpen5 ? 'open-text' : 'closed-text'}>
                                                    {isOpen5 ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen5 && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen5 && (
                                            <div className="BizAbout_edit_Icon"onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </FormGroup>

                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <Checkbox onChange={handleCheckboxChange6} checked={isOpen6} className='checkbox' />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div className='me-auto'>
                                            <h4>Sunday</h4>
                                            <p>
                                                <span className={isOpen6 ? 'open-text' : 'closed-text'}>
                                                    {isOpen6 ? 'Open now' : 'CLOSED'}
                                                </span>&nbsp;&nbsp;
                                                {isOpen6 && ' 9:00AM - 6:00PM'}
                                            </p>
                                        </div>
                                        {isOpen6 && (
                                            <div className="BizAbout_edit_Icon" onClick={props.toggleTym}>
                                                <img src={Pen1} width={12} className="img-fluid" alt="Edit icon" />
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </FormGroup>






                        </ModalBody>
                    </Modal>
                )}

        </Fragment>
    )
    
}

export default Timemodal2