import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react'
import { Eye, Flag, Link, MessageCircle, MoreVertical, Music, Send, Share } from 'react-feather'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import ConnectionReportModal from '../../Connections/ConnectionModal/ConnectionReportModal';
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ShotzModal from '../../Shotzs/shotz_components/ShotzModal'
import ShareModal from '../ShotzModal/ShareModal';

const AudioShotzs = () => {
    const [forwardOpen, setForwardOpen] = useState(false)

    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    //----------------- Shotz Carousel Modal State -----------------
    const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }
    //----------------- Report Modal State -----------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    const Shotzdata = [
        {
            gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
            num: '14000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            num1: '20000',
            num2: '33000',
            num3: '28000'
        },
        {
            gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
            num: '13000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            num1: '20000',
            num2: '34000',
            num3: '22000'
        },
        {
            gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
            num: '14000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            num1: '20000',
            num2: '33000',
            num3: '28000'
        },
        {
            gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
            num: '13000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            num1: '20000',
            num2: '34000',
            num3: '22000'
        },
        {
            gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
            num: '14000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            num1: '20000',
            num2: '33000',
            num3: '28000'
        },
        {
            gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
            num: '13000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            num1: '20000',
            num2: '34000',
            num3: '22000'
        },
        {
            gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
            num: '14000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            num1: '20000',
            num2: '33000',
            num3: '28000'
        },
        {
            gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
            num: '13000',
            timeline: 'Hide',
            post: 'Edit',
            link: 'Copy Link ',
            delete: 'Delete',
            share: 'Share',
            Reorts: 'Reorts',
            archive: 'Add to Achive',
            songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
            num1: '20000',
            num2: '34000',
            num3: '22000'
        }
    ]

    return (
        <>
            <Row className='p-2'>
                {
                    Shotzdata.map((data) => {
                        return (
                            <Col xl='3' className='p-2'>
                                <div className='border roundedThisBorder'>
                                    <div className="media-images-blk shotz-album-block  align-items-center ">
                                        <div className="main-shotz-blk-list">
                                            <figure className="shotz-card-hover m-0">
                                                <img className="gallery-img img-gradient rounded" src={data.gridImg} alt="Shotz" />
                                                <div className="text-gallery d-flex justify-content-center">
                                                    <div className="top-gallery-cont align-items-center">
                                                        <h5 className='text-white'>
                                                            <Eye color='#FFFFFF' size={20} /> {numFormatter(data.num)}</h5>
                                                        <div className='gallery-img-sublink-this-bizpage'>
                                                            <UncontrolledButtonDropdown className=''>
                                                                <DropdownToggle color='flat' >
                                                                    <MoreVertical color='white' size={20} />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu'>
                                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleForward}>
                                                                        <h5><Share size={13} />&nbsp;Share post</h5>
                                                                    </DropdownItem>
                                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                                                        <h5><Eye size={13} />&nbsp;View user</h5>
                                                                    </DropdownItem>
                                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                                        <h5><Link size={13} />&nbsp;Copy link</h5>
                                                                    </DropdownItem>
                                                                    <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                                        <h5><Flag size={13} />&nbsp;Report</h5>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                    </div>
                                                    <div className="center-gallery-cont">
                                                        <div onClick={toggleShotzCarouselModal} role='button'>
                                                            <img src="assets/images/shotz-icon.png" className='shotzModel' alt='ShotzIcon' />
                                                        </div>
                                                    </div>
                                                    <div className="Music-gallery-cont mx-2 py-3">
                                                        <p className='shotz-text'><Music size={20} /></p>&nbsp;&nbsp;
                                                        <h5 className='text-white mr-2'>{data.songs}</h5>
                                                    </div>
                                                    <div className="bottom-gallery-cont align-items-center">
                                                        <div className='shotz-text'><img src={LikeBlack} height={18} alt='like' /> {numFormatter(data.num1)}</div>
                                                        <div className='shotz-text'><MessageCircle size={18} /> {numFormatter(data.num2)}</div>
                                                        <div className='shotz-text'><Send size={18} />  {numFormatter(data.num3)}</div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row>
                {/* ------------- Pagination ------------- */}
                <Col xl='12' className='d-flex justify-content-center py-4'>
                    <Stack spacing={2}>
                        <Pagination
                            count={5}
                            color='primary'
                            size="large" />
                    </Stack>
                </Col>
            </Row>
            {/*  -------------- Share Option -------------- */}
            <ShareModal
                isOpen={forwardOpen}
                shareModalToOpen={toggleForward}
            />
            {/*  -------------- Add Report Option -------------- */}
            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/* ------------- Start Shotz Carousel Modal ------------- */}
            <ShotzModal
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            />
        </>
    )
}

export default AudioShotzs