import { Fragment, useState } from 'react'
import { Radio } from '@mui/material'


import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Clock, Globe, Mail, MapPin, Phone, XCircle } from 'react-feather'
import HeadingPointModal from './HeadingPointModal'
import HeadingDespModal from './HeadingDespModal'
const AdditionalModal = (props) => {
 
    const [selectedValue, setSelectedValue] = useState('a');

    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
    };
    return (
        <Fragment>
            {
                props.isOpen !== false && (
                    <Modal isOpen={props.isOpen1} toggle={props.toggleOpen1} className='modal-dialog-centered'>
                        <div

                            onClick={props.toggleOpen1}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>
                        <ModalHeader className="Biz_Aditional_SECTION">
                            <h3>Add Additional Information </h3>
                        </ModalHeader>
                        <ModalBody>

                            <FormGroup className="BizAddition_heading_section">
                                <h3>Select format for Info</h3>
                            </FormGroup>
                            <FormGroup>
                                <div className="bizAdditional-Main-blk">
                                    <div className='d-flex'>
                                        <div className='me-auto'>
                                            <h4>Heading and Description</h4>
                                        </div>
                                        <label className='' htmlFor="radio-buttonsOneId">
                                            <Radio
                                              
                                                value={'a'}
                                                name="radio-buttons"
                                                className='Radio_btn_check'
                                                color='primary'
                                                inputProps={{ 'aria-label': " A " }}
                                                onChange={handleRadioChange}
                                                checked={selectedValue === 'a'}
                                            />
                                        
                                         </label>
                                    </div>
                                    <p>Describe additional information about your business</p>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="bizAdditional-Main-blk">
                                    <div className='d-flex'>
                                        <div className='me-auto'>
                                            <h4>Heading and Points </h4>
                                        </div>
                                        <label className='' htmlFor="radio-buttonsOneId">
                                            <Radio
                                                
                                                value={'b'}
                                                name="radio-buttons"
                                                className='Radio_btn_check'
                                                id="radio-buttonsOneId"
                                                color='primary'
                                                inputProps={{ 'aria-label': 'B' }}
                                                checked={selectedValue === 'b'}
                                                onChange={handleRadioChange}
                                            />
                                         </label>
                                    </div>
                                    <p>List additional features and amenities of your business.</p>

                                </div>
                            </FormGroup>

                            <div className="text-center">
                                <Button className="" onClick={props.toggleOpen1} >Cancel</Button>
                                {selectedValue === 'a' ? <Button onClick={props.toggleOpenPoint1} color="primary " className=" ml-2 ">Add Info </Button> :
                                    <Button onClick={props.toggleOpenPoint} color="primary " className=" ml-2 ">Add Info </Button>}
                            </div>
                        </ModalBody>
                    </Modal>
                )}

        </Fragment>
    )
}

export default AdditionalModal