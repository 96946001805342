import React, { useEffect, useState } from 'react';
import { Copy, CornerLeftDown, Flag, MoreVertical, Share, MinusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { AllUserSentRequest } from '../../../Services/Actions/Connection/getUserSentRequestAction';
import BlockModal from '../ConnectionModal/BlockModal';
import { MutualFriendList } from '../HoverComponents/MutualFriendList';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';
import { NavLink } from 'react-router-dom';

export const PeopleListView = ({requests}) => {
    //const { userSentRequests } = useSelector(
    //    (state) => state.userSentRequestsData
    //  );
    const [userId, setUserId] = useState("");
    const dispatch = useDispatch();
    // -------------  share Modal open ------------- 
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    //  ------------- Start ReportedOption -------------
    const [reportOpen, setReportOpen] = useState(false);
    const toggleReport = () => {
        setReportOpen((preState) => !preState);
    };
    const [reportRequest, setReportRequest] = useState({ id: "", Type: "" });
    const[reportConfirm,setReportConfirm] = useState(false)
        useEffect(()=>{
            if(reportConfirm){
                setTimeout(() => {
                    dispatch(AllUserSentRequest());
                    setReportConfirm(false);
                }, 1000);
            } 
        },[reportConfirm])

    //get user block
    const [blockModalOpen, setBlockModalOpen] = useState(false)
    const toggleBlockModalOpen = () => {
        setBlockModalOpen((prevState) => !prevState);
    }
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
        if(blockConfirm){
        setTimeout(() => {
        dispatch(AllUserSentRequest());
            setBlockConfirm(false);
        }, 1000);
        } 
    },[blockConfirm])

    useEffect(() => {
        dispatch(AllUserSentRequest());
    }, [dispatch])
    return (
        <>
            {
                requests && requests.map((request, index) => {
                    return <div className="connection-card box-shadow-style  content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3" key={index}>
                        <div class="nav-flex-container">
                            <div class="media media-new d-sm-flex">
                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                <img
                                  src={request?.profileImage}
                                  className="rounded-circle "
                                  height={45}
                                  width={45}
                                  alt={request?.name || "User"}
                                />
                                    <span class="available-stats online"></span>
                                    <PublicAccount request={request} />
                                </div>
                                <div class="media-body d-md-block pl-1">
                                    <NavLink to='/MyProfile' >
                                        <h4 className='m-0 name-style d-inline-block text-truncate'>{request?.name?.substring(0, 21) || 'NA'}</h4>
                                    </NavLink>
                                    <div className='d-flex text-wrap'>
                                        <p className='user-name-style rem-mob-view-small text-wrap'>{request?.userName || 'NA'}  |  </p>
                                        <div className='d-flex ml-1'>
                                            <span><img src={request?.countryData[0]?.flagURL} className='rem-mob-view-small' alt='...' width={19} height={13} /></span>
                                            <span className='professional-style ml-1'>{request?.countryData[0]?.name}</span>
                                        </div>
                                    </div>
                                    <MutualFriendList mutualfriendList={request?.mutualfriendList} />
                                    <div className="people-likes matual-friend-sec d-flex justify-content-between ">
                                        {/* <p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '150px' }}>This text is a one line description</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">{request.totalPostCount}</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">{request.totalFollowingCount}</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">{request.totalFollowersCount}</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <div className='ml-sm-4 ml-xs-4 d-flex align-items-center justify-content-center'>
                            {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                            {/* <div className='mt-4 rem-mob-view'> */}
                            <Button outline className='btn-outline-blue' color="lightblue"  >
                                <CornerLeftDown size={15} />&nbsp;<span className='font-weight-bold'>&nbsp;Withdraw</span>
                            </Button>
                            {/* </div> */}
                            <div className='d-flex align-items-end flex-column justify-content-end px-4'>
                                <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                    <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                        <MoreVertical size={22} />
                                    </DropdownToggle>
                                    <DropdownMenu className='Connection-dropdown'>
                                        <DropdownItem tag='a' className='border-bottom py-2 menu-hover-style'>
                                            <CornerLeftDown size={15} className='mr-2' />
                                            Withdraw
                                        </DropdownItem>
                                        <DropdownItem tag='a' className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}>
                                            <Share size={15} className='mr-2' />
                                            Share Profile
                                        </DropdownItem>
                                        <DropdownItem tag='a' className='border-bottom py-2 menu-hover-style'>
                                            <Copy size={15} className='mr-2' />
                                            Copy Profile URL
                                        </DropdownItem>
                                        <DropdownItem
                                            className="border-bottom py-2 menu-hover-style"
                                            onClick={() => {
                                                toggleBlockModalOpen();
                                                setUserId(request.userId);
                                            }}
                                        >
                                            <MinusCircle
                                                size={15}
                                                className="mr-2 icon-color-style"   
                                            />
                                            Block
                                        </DropdownItem>
                                        <DropdownItem tag='a' className='py-2 menu-hover-style' 
                                           onClick={() => {
                                                toggleReport();
                                                setReportRequest({ id: request.userId });
                                             }}
                                        >
                                            <Flag size={15} className='mr-2' />
                                            Report User
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* </ul> */}
                        </div>

                    </div>
                })
            }

            {/*  ------------------ Add Report Option  ------------------ */}
            { reportOpen && (
                <ConnectionReportModal
                    request={reportRequest}
                    modalOpen={reportOpen}
                    reportModalOpen={toggleReport}
                    setReportConfirm ={setReportConfirm}
                />
            )}

            {/*------------------------- block modal----------------------- */}
            { blockModalOpen && (
                <BlockModal
                    request={userId}
                    openModal={blockModalOpen}
                    removeModalOpen={toggleBlockModalOpen}
                    setBlockConfirm={setBlockConfirm}
                />
            )}
        </>
    )
}

