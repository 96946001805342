import React from 'react'
import { Fragment } from 'react'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import PodcastMainPage from './PodcastMainPage'
import RightSidebar from '../RightSidebar'

const Podcast = () => {
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center ">
                    <div className="container-fluid section-t-space p-0 mt-0">
                        <PodcastMainPage /> {/*<<--------Main Page Of Podcast--------*/}
                    </div>
                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default Podcast