import { Rating, Stack } from '@mui/material'
import React from 'react'
import { Fragment } from 'react'
import { Bell, BellOff, Edit2, MoreHorizontal, Plus, Send ,Sliders,Edit,Copy,Users} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import Pin1 from '../Img/pin1.png'
import Pin from '../../group-components/Img/pin.png'
import { getAllUserOwnPinnedBizPageReducerAction, bizPageNotificationAction, bizPinnedAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useState } from 'react'
import { useEffect } from 'react'
import { Globe,Lock } from 'react-feather'
import BizInviteModal from './BizInviteModal'
import SvgPin from '../../biz-components/Img/SvgPin.svg'
import { Checkbox, Pagination,PaginationItem } from "@mui/material";
import axios from 'axios'
import { Card,
    Modal,
    ModalBody,
    ModalHeader} from 'reactstrap';
    import {
        DropdownItem,
        DropdownMenu,
        DropdownToggle,
        UncontrolledButtonDropdown,
        CustomInput
      } from "reactstrap";
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack,faUnlock, faLock } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom'

const BizOwnedCard = (props) => {
    let dispatch = useDispatch();
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const getPinnedBusinessPage = useSelector((state) => { return state.getAllUserOwnPinnedBizPageData });
    const { loading: loadingPinnedPage, error: errorPinnedPage, result: resultPinnedPage } = getPinnedBusinessPage

    const notificationData = useSelector((state) => { return state.bizPageNotificationData });
    const { loading: loadingNotification, error: errorNotification, result: resultNotification } = notificationData

    const isPinnedData = useSelector((state) => { return state.bizPageNotificationData });
    const { loading: loadingIsPinnedData, error: errorIsPinnedData, result: resultIsPinnedData} = isPinnedData

    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const [pageIndex,setPageIndex]=useState(0);
    const [pageSize,setPageSize]=useState(10);
    const [followerData,setFollowerData]=useState([]);
    const [followingData,setFollowingData]=useState([]);
    const [error,setError]=useState('');
    const [shareOpen, setShareOpen] = useState(false);
    const [searchKey,setSearchKey]=useState('');
    const [count,setCount]=useState(0);
    const [share,setShare]=useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                        { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[],userId:user.id },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setFollowerData(response.data.data.successResult.rows);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [searchKey,pageIndex,pageSize]);

    useEffect(() => {
        const fetchData = async () => {
            try {
  
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                        { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[],userId:userId },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setFollowingData(response.data.data.successResult.rows);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [searchKey,pageIndex,pageSize]);
    
        const editPage = () => {
            sweetAlertConfirmation("Edit feature is in progress")
        }
        

        const offNotification = (bpId) => {
        dispatch(bizPageNotificationAction(bpId, 0))
        props?.setSuccess(true)
        }
    
        const onNotification  = (bpId) => {
        dispatch(bizPageNotificationAction(bpId, 1))
        props?.setSuccess(true)
        }

        const sharePost = () => {
            if(share){
                sweetAlertConfirmation("Shared successfully")
            }else{
            sweetAlertConfirmation("Invited successfully")
            }
            setShareOpen((prevState) => !prevState);
          };
    
          
      const shareAll = () => {
        if(share){
            sweetAlertConfirmation("Shared successfully")
        }else{
            sweetAlertConfirmation(`Invitation Sent`);
        }
        setShareOpen((prevState) => !prevState);
      };

        const pinTheBizPage  = (bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion) => {
            dispatch(bizPinnedAction(bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion, 1))
            props?.setSuccess(true)
            }

        const unPinTheBizPage  = (bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion) => {
            dispatch(bizPinnedAction(bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion, 0))
            props?.setSuccess(true)
            }

    const toggleShareOpen = (e,type) => {
        e.stopPropagation();
        setShareOpen((prevState) => !prevState);
        if(type=="share"){
            setShare(true);
        }
    };

    const toggleShareOpen1 = (e) => {
        e.stopPropagation();
        setShareOpen((prevState) => !prevState);
        setShare(false);
    };

    
    const handleChange=(e)=>{
        setSearchKey(e.target.value);
      }

const pageCount = Math.ceil((props?.count)/12);
const isPrevDisabled = props?.pageIndex <= 0;
const isNextDisabled = props?.pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === props?.pageIndex) return;

    props?.setPageIndex(value - 1);
  };


const BasicsPagination = () => {
  return (
    <>
      <Stack>
        <Pagination
             count={pageCount}
             page={props?.pageIndex + 1}
             onChange={handlePageChange}
             className="PaginationCounts"
             renderItem={(item) => (
               <PaginationItem
                 {...item}
                 disabled={
                   (item.type === 'previous' && isPrevDisabled) ||
                   (item.type === 'next' && isNextDisabled)
                 }
               />
             )}
        ></Pagination>
      </Stack>
    </>
  );
};

const fetchData = async (id,followUnFollow) => {
  try {
     
      if (user) {
          const response = await axios.post(
              `${process.env.REACT_APP_IPURL}/bp/followBp`,
              { follow:followUnFollow,businessPageId:id },
              { headers: { Authorization: `Bearer ${user.token}` } }
          );
          if(followUnFollow && response){
            sweetAlertConfirmation("Successfully Followed")
          }else{
            sweetAlertConfirmation("Successfully Unfollowed")
          }
          props?.setSuccess(true);
      } else {
          setError('no user found');
      }
  } catch (error) {
      setError(error.message);
  }
};


const ToggleFollow=(e,id,trueOrfalse)=>{
  e.stopPropagation();
  fetchData(id,trueOrfalse);
}

// console.log("data===================================",props?.categoryId)

const filteredData = () => {
  return props?.categoryId
    ? props?.data.filter((item) => item.bpCategoryId === props?.categoryId)
    : props?.data;
};

const dataList=filteredData();

const postCopyLink = async (id) => {
  const newCopyLink = `${window.location.origin}/BizDetail/${id}`;
  await navigator.clipboard.writeText(newCopyLink);
  sweetAlertConfirmation(`Link Copied to clipBoard`);
};

// console.log("filtered data====================================",dataList)



    return (
        <Fragment>
            <Row className="mt-3">
                {
                    dataList?.map((data, index) => (
                        <Fragment>

                            <Col lg="3" className="mb-4">

                                <div className="BizPage_Card_section">
                                    <div className="BizPage_option_menu">
                                        <MoreHorizontal size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                        <div className="dropdown-menu dropdown-menu-bottom custom-dropdown drop-menu-gal w-50 MyArticle"     style={{
                                            minWidth: '200px', // Adjust width as needed
                                            padding: '0',
                                            margin: '0',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: for visual enhancement
                                            position: 'absolute',
                                            zIndex: '1000',
                                            right: '0', // Ensure the menu aligns properly with the button
                                          }}>
                                          <ul>
                                            {type=="creator" && (
                                              data.isPinned === 0  ? (
                                                <li
                                                  onClick={() => { pinTheBizPage(data.id, data.allowQuestion, data.allowMessage, data.visibility, data.allowPageSuggestion) }}
                                                >
                                                  <Link className='Biz-OPTION_menu' >
                                                    <img src={SvgPin} alt="Pin" />
                                                    <span>Pin this biz page</span>
                                                    </Link>
                                                </li>
                                              ) : (
                                                <li
                                                  onClick={() => { unPinTheBizPage(data.id, data.allowQuestion, data.allowMessage, data.visibility, data.allowPageSuggestion) }}
                                                >
                                                  <Link className='Biz-OPTION_menu' >
                                                    <img src={SvgPin} alt="Unpin" />
                                                    <span>Unpin this biz page</span>
                                                    </Link>
                                                </li>
                                              )
                                            )}
                                            <li
                                              onClick={(e) => toggleShareOpen(e, "share")}
                                            >
                                              <Link className='Biz-OPTION_menu' >
                                              <Send size={15}/>
                                              <span>Share page</span>
                                              </Link>
                                            </li>
                                            {type !== "viewer" && (
                                              <>
                                                <li
                                                  onClick={editPage}
                                                >
                                                  <Link className='Biz-OPTION_menu' >
                                                  <Edit size={15}/>
                                                  <span>Edit page</span>
                                                  </Link>
                                                </li>
                                                <li onClick={(e)=>postCopyLink(data.id)}>
                                                  <Link className='Biz-OPTION_menu'><Copy size={15} color="#4D4D4D" />Copy Link</Link>
                                                </li>
                                                {data.allowNotification === 1 ? (
                                                  <li
                                                    onClick={() => { offNotification(data.id) }}
                                                  >
                                                    <Link  className='Biz-OPTION_menu' >
                                                    <BellOff size={15}/>
                                                    <span>Turn off notification</span>
                                                    </Link>
                                                  </li>
                                                ) : (
                                                  <li
                                                    onClick={() => { onNotification(data.id) }}
                                                  >
                                                    <Link  className='Biz-OPTION_menu' >
                                                    <Bell size={15}/>
                                                    <span>Turn on notification</span>
                                                    </Link>
                                                  </li>
                                                )}
                                              </>
                                            )}
                                            <li onClick={(e)=>toggleShareOpen(e,"invite")}>
                                              <Link className='Biz-OPTION_menu'><Users size={15} color="#4D4D4D" />Invite people </Link>
                                            </li>                                            
                                          </ul>

                                        </div>
                                    </div>
                                    <NavLink to={`/BizDetail/${data.id}`}className="bizpagelink-blk"><img src={data.coverImageUrl} className="img-fluid group-img" />
                                        <div className="group-adminprofile-blk">

                                        {data?.logoThumb ? (
                                        <img src={data?.logoThumb} className='rounded-circle' />
                                        ) : (
                                        <img src={data?.logo} className='rounded-circle' />
                                        )}
                                            
                                            </div>
                                    </NavLink>
                                    <div className="group-content">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <h4 className='CardTextHeading'>{data.name.substring(0, 10)}{data.name.length >= 10 && ' ...'} </h4>&nbsp; &nbsp;{data.isPinned ?
                                                <>
                                                   <span><img src={Pin} color="#979797" /></span>
                                                </> : null
                                            }
                                        </div>
                                        <div className="Suggestmember-blk text-center">
                                            <span>{data.categoryName.substring(0, 10)}{data.categoryName.length >= 10 && ' ...'} </span>&nbsp; &nbsp;{data.isPrivate ?
                                                <>
                                                   <span><Lock color='#4D4D4D' size={12} />&nbsp; Private</span>
                                                </> : <><span> <Globe color='#4D4D4D' size={12} />&nbsp; Public</span></>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <p>{data.email||"No Email Available"}</p>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <Stack spacing={1}>
                                                <Rating name="read-only" defaultValue={data.averageRating||0} readOnly />
                                            </Stack>
                                        </div>
                                        <div className='d-flex justify-content-around text-center mt-2' >
                                            <div>
                                                <h2>{data.postCount}</h2>
                                                <p className='text-muted'>Post</p>
                                            </div>
                                            <div>
                                                <h2>{data.followerCount}</h2>
                                                <p className='text-muted'>Followers</p>
                                            </div>
                                        </div>
                                        <div className="BizPage-buttons-blk">
                                            {type =="creator"?(
                                              <Button onClick={(e)=>toggleShareOpen(e,"invite")} outline color='primary' className=" BizBTN_section" >Invite</Button>
                                            ):(
                                              <>
                                              {data.isFollower ? (
                                                <div className="group-buttons-blk mt-3" onClick={(e)=>ToggleFollow(e,data.id,false)} >
                                                <Button className="Invite_Decline_Button ml-2" color='primary'>Unfollow</Button>
                                                </div>
                                              ):(    
                                                <div className="group-buttons-blk mt-3" onClick={(e)=>ToggleFollow(e,data.id,true)}>                                       
                                                <Button className="Invite_Join_Button" color='primary'>Follow</Button>
                                                </div>
                                              )}
                                              </>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Fragment>
                    ))
                }


            </Row>
            <div className="d-flex justify-content-center">
                        <BasicsPagination />
                      </div>
            <div className="vertically-centered-modal">
          {shareOpen !== false && (
            <Modal
              isOpen={shareOpen}
              toggle={toggleShareOpen1}
              className="modal-dialog-centered"
            >
              <div className="w-100 border-bottom">
                <div className=" mx-3 py-3  d-flex justify-content-between Heading_Invite_Section">
                  <h4>{share? "Share People" : "Invite People"}</h4>
                  <span style={{ cursor: "pointer" }} onClick={sharePost}>
                  </span>
                </div>
              </div>
              <ModalBody>
                <Row className="mt-2">
                  <Col md="8" className="Like_search">
                    <div className="profile-search-blk w-100 ">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="search-svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Search..."
                        value={searchKey}
                        data-bs-toggle="modal"
                        data-bs-target="#peopleSearch"
                        autoFocus
                        onChange={handleChange}
                        // value={searchTerm}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="d-flex justify-content-end">
                    <UncontrolledButtonDropdown color="secondary" outline>
                      <DropdownToggle
                        outline
                        className="border border-muted ml-3 "
                      >
                        <Sliders className="Slider_Filter" size={12} />
                        &nbsp;&nbsp;Filter
                      </DropdownToggle>
                      <DropdownMenu className="DropDown_Group_item">

                        {/* <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(1); }}>
                         SocioMate
                        </DropdownItem> */}
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(0); }}>
                         Followers
                        </DropdownItem>
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(2); }}>
                         Followings
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Row className="mt-3" scrollable>
                       {count == 0 && followerData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index} scrollable>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </Fragment>
                        ))}
                        {count == 2 && followingData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index} scrollable>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                                <div className='Block-btns-blk'>
                                  <Checkbox {...label} defaultChecked />
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    </Row>
                  <Col
                    md="6"
                    className="d-flex justify-content-end SubHeading_Invite_Section"
                  >
                    <p style={{ cursor: "pointer" }} onClick={shareAll}>
                    {share? "Share All" : "Invite All"}
                    </p>
                        </Col>
                        </Row>
                    </ModalBody>
                    </Modal>
                    )}
                </div>
        </Fragment>
    )
}

export default BizOwnedCard
