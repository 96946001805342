import React, { useEffect, useState } from 'react'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, Progress, Row, UncontrolledButtonDropdown } from 'reactstrap'
import typeImage from "../Images/TypeIcon.svg"
import ImageIcon from "../Images/ImageIconbtn.svg"
import ImageLandScapeOne from "../Images/landscapeStoryOne.jpg";
import ImageLandScapeTwo from "../Images/LandScapeStoryTwo.jpg";
import Imagehorizontal from "../Images/HorizontalStoryOne.jpg"
import like from '../../../NewComponents/IMG/userlike.png'
import Send2 from '../../../NewComponents/IMG/Send2.png'
import Slider from 'react-slick';
import { ChevronsUp, Flag, Image, MoreVertical, Pause, Play, Send, Trash2, Volume2, VolumeX, X } from 'react-feather';
import StoryShareModal from '../StoryModal/StoryShareModal';
import ViewedAndLike from '../ViewedAndLike';
import StoryLikeAndView from '../StoryModal/StoryLikeAndView';
import TypeIconWhite from "../Images/typeIconWhite.svg"
import { useNavigate } from 'react-router-dom';
import StorySettingModal from '../StoryModal/StorySettingModal';

const NewStoryViewDesign = ({ isOpen, storyViewModal, isActive, setIsActive }) => {

    const navigate = useNavigate()

    // pause and play 
    const [paused, setPaused] = useState(false);
    // volume on off 
    const [volume, setvolume] = useState(false);

    const [HoverBtn, SetHoverBtn] = useState(false)




    //  ------------- Start Reported Option State -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // ------------- Share Modal State -------------
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }


    //  ------------- Story Like View User List -------------
    const [likeViewModal, setLikeViewModal] = useState(false)
    const toggleLikeViewModal = () => {
        setLikeViewModal((preState) => !preState)
    }


    // ------------- Story setting States -------------
    const [storySettingModal, setStorySettingModal] = useState(false)
    const toggleStorySettingModal = () => {
        setStorySettingModal((prevState) => !prevState)
    }


    // slider function 

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // screen width less than 1024 pixels
                settings: {
                    arrows: false // hide arrows
                }
            }
        ]
    };

    const avatardata = [
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-3.jpg'
        },
        {
            img: 'assets/images/story-4.jpg'
        },
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-3.jpg'
        },
    ]
    // dummy array for the stories 
    const StoriesArray = [ImageLandScapeOne, Imagehorizontal, ImageLandScapeTwo, Imagehorizontal]


    // useEffect(() => {
    //     window.addEventListener("keyup", e => {
    //         if (e.keyCode === 27) {
    //             if (isOpen === true) {
    //                 storyViewModal()
    //             }
    //         }
    //     })
    // },)

    // adding the media query for the contianer change 
    const { clientWidth, clientHeight } = window



    return (
        <>
            {/* Story Share Modal  */}
            <StoryShareModal
                isOpen={forwardOpen}
                shareModalToOpen={toggleForward}
            />
            {/* ------------- Delete Modal ------------- */}
            <StoryLikeAndView
                isOpen={likeViewModal}
                toggleModal={toggleLikeViewModal}
            />

            {/* ------ Add Story Setting Option ------ */}
            <StorySettingModal
                isOpen={storySettingModal}
                settingModal={toggleStorySettingModal} />

            <Modal isOpen={isOpen} style={{ backdropFilter: "blur(5px)" }} className='modal-dialog-centered modal-container modal-fullscreen overflow-hidden ' toggle={storyViewModal}>
                <Row className="StoryWholeContainer_ rowForrevert position-relative">

                    <Col className="StorySideBar_ col-xl-3 d-lg-none d-md-none">
                        {/* header  */}
                        <div className="StorySideBarHead_ py-2 px-3 storyBoderColor" >
                            <h3 className='Story4D_600'>Stories</h3>
                            <h6 className="text-primary cursor-p" onClick={toggleStorySettingModal}>Setting</h6>
                        </div>

                        {/* User Story Design  */}
                        <h4 className='Story4D_500 px-3 py-2'>Your Story</h4>
                        <div className={`StoryDesignUserSelfData px-3 storyBoderColor ${isActive === "self" ? "UserActiveStory" : "HoverE6"}  cursor-p`}>
                            <div className="StoryUserData_" onClick={() => { setIsActive("self") }}>
                                <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="UserImage" className="StoryDataUserImage StatusBorderDashedProfile" />
                                <div className="StoryUsetTextData">
                                    <h5 className='Story4D_600'>Jekerlin Ferdinand</h5>
                                    <h6 className='text-muted Fw_500'>20 h ago • 16 View</h6>
                                </div>
                            </div>
                            <div className="StoryEditPartIcons d-flex gap-1">
                                <span className="StoryImageEditbuttons_  Story4D_500  cursor-p" onClick={() => { navigate("/CreateStories") }} onMouseOver={() => SetHoverBtn(true)} onMouseLeave={() => SetHoverBtn(false)} >
                                    {/* <img src={HoverBtn ? TypeIconWhite : typeImage} alt="Typebutton" className={` ${HoverBtn ? "bg-primary" : ""} EditStoryBtnImage cursor-p `} /> */}
                                    <img src={typeImage} alt="Typebutton" className="EditStoryBtnImage cursor-p" />
                                </span>
                                <span className='StoryImageEditbuttons_  Story4D_500  cursor-p' onClick={() => { navigate("/CreateStories") }}>
                                    {/* <img src={ImageIcon} alt="ImageButton" className='EditStoryBtnImage cursor-p' /> */}
                                    <Image size={16} />
                                </span>
                            </div>
                        </div>

                        {/* Recent Stories Design  */}
                        <h4 className='Story4D_500 px-3 py-2'>Recent Stories </h4>
                        <div className="RecentStoriesList  py-2">
                            {[...Array(4)].map((_, index) => (
                                <div className={`StoryUserData_ px-4 cursor-p BorderRadius5px ${isActive === index ? "UserActiveStory" : "HoverE6"}`} onClick={() => setIsActive(index)}>
                                    <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="UserImage" className="StoryDataUserImage StatusBorderDashed" />
                                    <div className="StoryUsetTextData">
                                        <h5 className='Story4D_600'>Mariya Parveen</h5>
                                        <h6 className='text-muted Fw_500'><span className='Story81_600' >33 New</span> &nbsp;&nbsp;1 h ago</h6>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Viewed Stories  */}
                        <h4 className='Story4D_500 px-3 py-2'>Viewed Stories</h4>
                        <div className="RecentStoriesList  py-2">
                            {[...Array(4)].map((_, index) => (
                                <div className="StoryUserData_ HoverE6 px-4 cursor-p BorderRadius5px">
                                    <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="UserImage" className="StoryDataUserImage StatusBorderDashedGray" />
                                    <div className="StoryUsetTextData">
                                        <h5 className='Story4D_600'>Mariya Parveen</h5>
                                        <h6 className='text-muted Fw_500'><span className='Story81_600' >33 New</span> &nbsp;&nbsp;40 min ago</h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col className="StoryViewArea_ text-white col-xl-9 col-lg-12  col-md-12">

                        {/* Story Progress bar  */}
                        <div className="StoryProgressbar px-3">
                            {[...Array(5)].map((_, index) => (
                                <Progress className="story_Progress_Bar_main w-100 " value={index === 4 ? 50 : 100} />
                            ))}
                        </div>

                        {/*  cross Button for the story   */}
                        <span className='StoryCrossButton_ mr-3' onClick={storyViewModal}>
                            <X color='#ffffff' size={28} />
                        </span>
                        <span className='StoryCrossButton_Mobile mr-3' onClick={storyViewModal}>
                            <X color='#ffffff' size={28} />
                        </span>
                        {/* Story Sllider  */}
                        <div className="StoryPrivew_Image  d-flex justify-content-center ">
                            <div className="col-xl-10 ">
                                <Slider {...settings} className="default-space StorySliderCustomize">
                                    {StoriesArray.map((item, index) => (
                                        <div className='ImagePreviewContainer'>
                                            <div className='ImagePreviewContianerSub' >
                                                <img src={item} alt="" className='ImagePreviewStory_' />
                                                {/* top user Data on story  */}
                                                <div className="StoryUserInfo_ p-3 OnStorySettings ">
                                                    <div className="OnStoryUserInfo">
                                                        <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" alt="UserImage" className="OnStoryUserImg_" />
                                                        <div>
                                                            <h5>Ella Sofia</h5>
                                                            <h6>20 Min ago</h6>
                                                        </div>
                                                    </div>
                                                    <div className="OnStorySettings_">
                                                        <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                        </p>
                                                        <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                        </p>
                                                        <UncontrolledButtonDropdown>
                                                            <DropdownToggle color=''>
                                                                <MoreVertical size={22} color='#FFFFFF' />
                                                            </DropdownToggle>
                                                            <DropdownMenu className='StoryThreeDotButtonMenu'>
                                                                <DropdownItem className='dropdownitem HoverE6'><VolumeX size={14} />&nbsp;&nbsp; Mute </DropdownItem>
                                                                <DropdownItem className='dropdownitem HoverE6'> <Trash2 size={14} /> &nbsp;&nbsp;Delete story</DropdownItem>
                                                                <DropdownItem onClick={toggleReport} className='dropdownitem HoverE6'><Flag size={14} />&nbsp;&nbsp;Report Story</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    ))}
                                </Slider>
                                {isActive === "self" ?
                                    <div className='text-center StoryBottonLikeViewAdjust_' onClick={toggleLikeViewModal}>
                                        <ChevronsUp size={40} role='button' color='#ffffff' />
                                        <h5 style={{ fontSize: "clamp(0.5rem, 0.9rem, 1.4rem)" }} className='text-white text-wrap' role='button'>45+ other person viewed your story</h5>
                                        <ul className="matual-friend-blk d-flex justify-content-center py-2">
                                            {
                                                avatardata.map((item, index) => {
                                                    return (
                                                        <li className="popover-cls d-flex" key={index}>
                                                            <img src={item.img} className="img-fluid bg-img rounded-circle" alt="..." />
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    :
                                    <div className="d-flex flex-wrap justify-content-center gap-1 mt-3">
                                        <img src={like} className='StoryReactionbtn' alt="likeandshare" />
                                        <img src={Send2} onClick={toggleForward} className=" StoryReactionbtn" alt="likeandshare" />
                                        <div className="StoryReplybox_ d-flex flex-nowrap border text-dark rounded-3 w-25" style={{ background: "rgba(255, 255, 255, 0.7)" }}>
                                            <Input placeholder="Reply...." type="text" className="border-0 bg-transparent" />
                                            <span className='StoryReplySendBtn'>
                                                <Send size={16} style={{ transform: "rotate(45deg)" }} />
                                            </span>
                                        </div>
                                        <img src="/assets/svg/emoji/040.svg" alt="smile" className='StoryReplyEmojiIcons' />
                                        <img src="/assets/svg/emoji/113.svg" alt="smile" className='StoryReplyEmojiIcons' />
                                        <img src="/assets/svg/emoji/027.svg" alt="smile" className='StoryReplyEmojiIcons' />
                                        <img src="/assets/svg/emoji/052.svg" alt="smile" className='StoryReplyEmojiIcons' />
                                        <img src="/assets/svg/emoji/039.svg" alt="smile" className='StoryReplyEmojiIcons' />
                                        <img src="/assets/svg/emoji/042.svg" alt="smile" className='StoryReplyEmojiIcons' />
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default NewStoryViewDesign
