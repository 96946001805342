import React from 'react'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2, } from 'react-feather'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import { useState } from 'react'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import LikeBlack from '../../profile-components/Img/likesvg.svg'
// import MediaPreviewModal from '../../Universal-components/post-creations/sub-components/MediaPreviewModal'
import { cardDataTrendingArticle } from '../DummyArray'
import ReportModal from './ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'

const GridArticle = () => {

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }

    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    {/* ----------------------------------------------- Main return function ------------------------------------------------------------- */ }
    return (
        <div className=' p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />

            <Row className='PeopleThisGrid'>
                {/* ----------------------------------------------- Dyanamic card data rendering------------------------------------------------------------- */}
                {cardDataTrendingArticle.map((data, index) => (

                    <Col lg='3' xs='12' className='w-100 p-1' key={index} >
                        <div className='Video_Treanding_card on-hover-bg media_card'>
                            <div className='d-flex pb-2'>
                                <NavLink to='/MyProfile'>
                                    <Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                        Trending #{data.trendingNumber}
                                    </Badge>
                                </NavLink>
                                <NavLink to='/MyProfile'>
                                    <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                        Top #{data.topsNumber}
                                    </Badge>
                                </NavLink>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center '>
                                    <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                    <div className='ml-2'>
                                        <div className='d-flex' >
                                            <NavLink to='/MyProfile' style={{ color: 'black' }} >
                                                <h4 className='text-nowrap user-profile' >{data.name} </h4>
                                            </NavLink>
                                            <small className='px-1 text-nowrap custom-text' >posted an article</small>
                                        </div>
                                        <small className='text-muted light-for-date'>{data.dateandtime}</small>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown className='align-self-center' style={{ marginTop: '-26px' }}>
                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                        <MoreHorizontal size={19} color='#999999' className='hide-on-small-screens' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Article</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View creator's profile</NavLink></DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>

                            <div className='Potrate_treanding_img pt-2'>
                                <img src={data.imgUse} alt='img' height={210} />
                                <div className='TreandingThisGame'>
                                    <div className='d-flex justify-content-between ' >
                                        <p>{data.caption}</p>
                                        <div className='d-flex'>
                                            <Eye color='white' size={18} />
                                            <p className='text-white ml-1'>93k</p>
                                        </div>
                                    </div>
                                    <small>{data.category}</small>
                                </div>
                            </div>


                            {/* <div className='d-flex align-items-center'>
                            <div className='Treanding_bottom_section'>
                                {liked ?
                                    <img src={LikeBlack} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    :
                                    <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                }
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>
                        </div> */}
                            {/* <div className='d-flex justify-content-between p-3'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.5k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} alt='share' width={16} height={16} className='' />
                                </div>
                                11.2k
                            </div>
                        </div> */}

                            <div className='Treanding_bottom_section'>
                                <div className='d-flex align-items-center'>
                                    <div onClick={() => handleLike()}>
                                        {liked ?
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                            :
                                            <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                        }
                                    </div>
                                    &nbsp;
                                    11.2k
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                    </div>
                                    11.2k
                                </div>
                                <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                    </div>
                                    11.2k
                                </div>

                            </div>
                        </div>
                    </Col>
                ))}

                {/* ----------------------------------------------- Article Card 2 Start Here------------------------------------------------------------- */}
                {/* <Col lg='3' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://images.unsplash.com/photo-1562155847-c05f7386b204?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV3JTIwd2FsbHBhcGVyfGVufDB8fDB8fA%3D%3D&w=1000&q=80' alt='img' height={210} />
                            <div className='TreandingThisGame'>
                                <small>Dance</small>
                                <p>Article’s Title</p>
                            </div>

                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col> */}
                {/* ----------------------------------------------- Article Card 3 Start Here------------------------------------------------------------- */}
                {/* <Col lg='3' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://images.pexels.com/photos/9938542/pexels-photo-9938542.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' alt='img' height={210} />
                            <div className='TreandingThisGame'>
                                <small>Game</small>
                                <p>Order on the Court</p>
                            </div>

                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col> */}
                {/* ----------------------------------------------- Article Card 4 Start Here------------------------------------------------------------- */}
                {/* <Col lg='3' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://images.unsplash.com/photo-1562155847-c05f7386b204?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bmV3JTIwd2FsbHBhcGVyfGVufDB8fDB8fA%3D%3D&w=1000&q=80' alt='img' height={210} />
                            <div className='TreandingThisGame'>
                                <small>Entertainment</small>
                                <p>Rudy Maxa's World</p>
                            </div>

                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>
                        
                    </div>
                </Col> */}

            </Row>
            {/* -----------------------------------------------Card End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Report Modal  Start Here------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Report Modal  End Here------------------------------------------------------------- */}

            {/* <MediaPreviewModal /> */}
        </div >
    )
}

export default GridArticle