export const PostStoriesCommentData = [
    {
        id: 'toggle',
        heading: "Allow Push Notifications",
    },
    {
        id: 'toggle',
        heading: "Pause All Notifications",
    },
    {

        heading: "New Post by a Connection you Follow",
        subContent: "Get notified for posts by connections you follow"
    },
    {

        heading: "Shares of your Post",
        subContent: "Get notified when someone shares your post"
    },
    {

        heading: "Likes on your Post",
        subContent: "Get notified when someone comments on your post"
    },
    {

        heading: "Replies on your comment",
        subContent: "Get notified when someone replies on your comment"
    },
    {

        heading: "Posts on your timeline",
        subContent: "Get notified when someone posts on your timeline"
    },
    {

        heading: "Messages received on your story",
        subContent: "Get notified when someone messages on your story"
    },
    {

        heading: "When someone mentions you in their post",
        subContent: "Get notified when someone mentions you in their post"
    },
]

export const FollowerData = [
    {
        heading: "When someone follows you ",
        subContent: "Get notified when someone follows you"
    },
    {
        heading: "When someone accepts your follow request",
        subContent: "Get notified when someone accepts your follow request"
    },
    {
        heading: "When you receive a follow request",
        subContent: "Get notified when you receive a follow request"
    }

]

export const MessgaeData = [
    {
        heading: "When you receive new messages ",
        subContent: "Get notified when you receive new messages"
    },
    {
        heading: "When someone tags you in their message",
        subContent: "Get notified when someone tags you in their message"
    },
    {
        heading: "Group Messages",
        subContent: "Get notified when for messages on Groups that you are part of."
    },
    {
        heading: "MarketPlace Messages",
        subContent: "Get notified for messages on products that you have uploaded for sale."
    }

]

export const LiveVideoData = [
    {
        heading: "Likes received on your video",
        subContent: "Get notified for likes on your video"
    },
    {
        heading: "Comments received on your video",
        subContent: "Get notified for comments received on your video"
    },
    {
        heading: "Shares of your video",
        subContent: "Get notified for shares of your videos"
    },
    {
        heading: "Double Shotz on your video",
        subContent: "Get notified when someone double shotz your Shotz"
    },
    {
        heading: "Triple Shotz on your video",
        subContent: "New Post by a Connection you Follow"
    },
    {
        heading: "When Live video starts",
        subContent: "Get notified when your connections start a live video"
    },
    {
        heading: "When the live video ends",
        subContent: "Get notified when your live videos end"
    },
    {
        heading: "Shares of your Live video",
        subContent: "Get notified when someone shares your live videos"
    },
    {
        heading: "Push notification for live videos",
        subContent: "Get notified when your connections goes live"
    }
]
export const AdsData=[
    {
        heading: "Likes received on your Ad",
        subContent: "Get notified for likes received on your ad"
    },
    {
        heading: "Clicks received on your Ad",
        subContent: "Get notified for clicks received on your ad"
    },
    {
        heading: "Shares of your Ad",
        subContent: "Get notified when someone shares your ad"
    },
    {
        heading: "Comments received on your Ad",
        subContent: "Get notified for comments on your ad"
    }
]
