import React from 'react'
import { useState } from 'react'
import PodcastHeader from './PodcastHeader'
import PodcastTabData from './Sub-components/PodcastTabData'
import PodcastFooter from './PodcastFooter'
import { useUserAuth } from '../../Context/userAuthContext'


const PodcastMainPage = () => {

    const { musicBar } = useUserAuth()

    /*  ----------------------State and Function--------------------  */

    const [categoriesActiveTab, setCategoriesActiveTab] = useState('1')
    const toggleCategories = tab => {
        if (categoriesActiveTab !== tab) setCategoriesActiveTab(tab)
    }
    return (
        <div>

            <PodcastHeader tabData={categoriesActiveTab} Slidetoggle={toggleCategories} /> {/*<<--------Podcast Header --------*/}

            <PodcastTabData tabData={categoriesActiveTab} /> {/*<<-------- Podcast Data --------*/}

            {musicBar ? <PodcastFooter /> : null}{/*<<--------Podcast Footer stripe when any podcast is clicked--------*/}

        </div>
    )
}

export default PodcastMainPage