import React, { useEffect, useState } from 'react'
import { Copy, CornerLeftDown, Flag, Grid, List, MoreVertical, Search, Share, MinusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { AllUserSentRequest } from '../../../Services/Actions/Connection/getUserSentRequestAction';
import {withDrawRequestAction} from '../../../Services/Actions/Connection/getSentRequestWithdrawAction'
import { Button, InputGroup } from 'react-bootstrap';
import BlockModal from '../ConnectionModal/BlockModal';
import { PeopleListView } from '../ListView/PeopleListView';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import PublicAccount from '../ConnectionModal/PublicAccount';
import '../Style.css'
import { NavLink } from 'react-router-dom';
import { type } from '@testing-library/user-event/dist/type';

export const PeopleCard = ({requests}) => {
    //const { userSentRequests } = useSelector((state) => state.userSentRequestsData);
    const [searchItem, setSearchItem] = useState("");
    const [userId, setUserId] = useState("");

    //const filteredData = userSentRequests?.data?.rows?.filter((item) =>
    //item.name.toLowerCase().includes(searchItem.toLowerCase()));

    const dispatch = useDispatch();
    const [Gridview, Setgridview] = useState(false)

    // ------------- share Modal open -------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    // withdraw Request
    const handleRequestWithDraw = (id) =>{
        dispatch (withDrawRequestAction(id))
      }

    useEffect((id)=>{
        dispatch(withDrawRequestAction(id))
    },[])

    //  ------------- Start ReportedOption -------------
    const [reportOpen, setReportOpen] = useState(false);
    const toggleReport = () => {
        setReportOpen((preState) => !preState);
    };
    const [reportRequest, setReportRequest] = useState({ id: "", Type: "" });
    const[reportConfirm,setReportConfirm] = useState(false)
        useEffect(()=>{
            if(reportConfirm){
                setTimeout(() => {
                    dispatch(AllUserSentRequest());
                    setReportConfirm(false);
                }, 1000);
            } 
        },[reportConfirm])

    //get user block
    const [blockModalOpen, setBlockModalOpen] = useState(false)
    const toggleBlockModalOpen = () => {
        setBlockModalOpen((prevState) => !prevState);
    }
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
        if(blockConfirm){
        setTimeout(() => {
        dispatch(AllUserSentRequest());
            setBlockConfirm(false);
        }, 1000);
        } 
    },[blockConfirm])

    useEffect(() => {
        dispatch(AllUserSentRequest())
    }, [dispatch])

    return (<>
        <div className="mppage-heading mt-4">
            <div className="group-custom-block">
                <div className="nav-flex-container">
                    <h3 className='connection-sub-heading'>Requested People ({requests.length})</h3>
                    <div className='sub-nav-flex size-sm-mt-4'>
                        <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                            <Search color='#666666' size={16} />
                            <Input type='search' placeholder="  Find..." className='coonection-input-style' />
                        </InputGroup>
                        <div className='list-grid-toggle size-xs-mt-4 rem-mob-view'>
                            <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                <Grid size={16} />
                            </Button>
                            <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                <List size={16} />
                            </Button>
                        </div>
                    </div>
                </div>
                {Gridview ?
                    <PeopleListView requests={requests} />
                    :
                    <Row className='connections_PeopleThisGrid mt-3'>
                        {
                            requests && requests.map((request, index) => {
                                return <Col lg='4' className='w-100' >
                                    <div key={index} className="connection-card  content-mp-block d-flex box-shadow-style justify-content-between">
                                        <div className="media-body d-md-block">
                                            <div class="media media-new d-flex align-items-start">
                                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                                <img
                                                    src={request?.profileImage}
                                                    className="rounded-circle "
                                                    height={45}
                                                    width={45}
                                                    alt={request?.name || "User"}
                                                />
                                                    <span class="available-stats online"></span>
                                                    <PublicAccount request={request} />
                                                </div>
                                                <div class="media-body d-md-block pl-1">
                                                    <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate'>{request?.name?.substring(0, 21) || 'name'}</h4></NavLink>
                                                    <div className='d-flex'>
                                                        <p className='user-name-style'>{request?.userName || 'NA'}  |  </p>
                                                        <span>
                                                            <img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} />
                                                        </span>
                                                    </div>
                                                    <div className="people-likes matual-friend-sec d-flex justify-content-between ">
                                                        <p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '150px' }}>{request?.profession || "No Profession"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-end flex-column'>
                                            <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                    <MoreVertical size={22} />
                                                </DropdownToggle>
                                                <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                        <CornerLeftDown size={15} className='mr-2' />
                                                        Withdraw Request
                                                    </DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}>
                                                        <Share size={15} className='mr-2' />
                                                        Share Profile
                                                    </DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                                    <DropdownItem
                                                        className="border-bottom py-2 menu-hover-style"
                                                        onClick={() => {
                                                            toggleBlockModalOpen();
                                                            setUserId(request.userId);
                                                        }}
                                                    >
                                                        <MinusCircle
                                                        size={15}
                                                        className="mr-2 icon-color-style"
                                                        />
                                                        Block
                                                    </DropdownItem>
                                                    <DropdownItem className='py-2 menu-hover-style' 
                                                        onClick={() => {
                                                            toggleReport();
                                                            setReportRequest({ id: request.userId });
                                                        }}
                                                    >
                                                        <Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <div role="button" className='mt-3 d-flex align-items-end'>
                                                <Button color='flat-success' className='btn-transparent pb-0 pr-0'>
                                                    <CornerLeftDown size={15} color='#2F80ED' />&nbsp;<span className='connection-blue-text rem-mob-view'>Withdraw</span>
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                }
                <Row>
                    <Col xl='12' className='d-flex justify-content-center'>
                        {
                            requests.length <= 0 && <h3 className='text-center connection-sub-heading'>No Request Sent</h3>
                        }
                    </Col>
                </Row>
            </div>
        </div>
        {/*  ------------------ Add Report Option  ------------------ */}
        { reportOpen && (
            <ConnectionReportModal
                request={reportRequest}
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
                setReportConfirm ={setReportConfirm}
            />
        )}

        {/*------------------------- block modal----------------------- */}
        { blockModalOpen && (
            <BlockModal
                request={userId}
                openModal={blockModalOpen}
                removeModalOpen={toggleBlockModalOpen}
                setBlockConfirm={setBlockConfirm}
            />
        )}
        {/* ------------------ Start Share Modal  ------------------*/}
        <ConnectionShareModal
            openModal={shareOpen}
            shareModalOpen={toggleShareOpen}
        />
    </>)
}

