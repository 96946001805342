import React, { Fragment, useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react';

import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import OffCanvasLanguages from './Modal-Components/OffCanvasLanguages';
import LanguagePopover from './Modal-Components/LanguagePopover';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';


const ForgotOtpVerify = () => {


    const navigate = useNavigate();

    // State for OTP timer
    const [otpTimeLimit, setOtpTimeLimit] = useState(20); // 20 seconds
    const [otpTimer, setOtpTimer] = useState('');

    // State for language modal
    const [show, setShow] = useState(false);

    // State for OTP input
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);

    // Handle close/show for language modal
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Handle toggle for language modal
    const [languages, setLanguages] = useState(false);
    const LanguageModalToggle = () => setLanguages(prevLanguages => !prevLanguages);

    // Handle OTP resending
    const resendOtp = () => {
        if (otpTimeLimit === 0) {
            setOtpTimeLimit(20);
        }
    };

    // Handle OTP submission
    const otpSubmit = () => {
        navigate('/resetpasswordpage');
    };

    // Handle OTP input change and focus on next/previous input
    const handleChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);

        if (e.target.value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        } else if (!e.target.value && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };

    // Handle OTP timer interval and update timer state
    useEffect(() => {
        const interval = setInterval(() => {
            if (otpTimeLimit === 0) {
                setOtpTimer('00:00');
            } else {
                const minutes = Math.floor(otpTimeLimit / 60);
                const seconds = otpTimeLimit % 60;
                setOtpTimer(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
            }

            if (otpTimeLimit > 0) {
                setOtpTimeLimit(prevTimeLimit => prevTimeLimit - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [otpTimeLimit]);


    return (
        <Fragment>
            {/* ------Modal Call -------------- */}
            <OffCanvasLanguages show={show} handleClose={handleClose} />
            {/* language Modal   */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row >
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec">
                                    <Link className="" to="/">
                                        <img
                                            src="/assets/images/logo.png"
                                            alt="logo"
                                            className="img-fluid"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="login-form">
                                <LoginSignUpStepper steps={3} active={2} />
                                <div>
                                    <div className="login-title">
                                        <h2>Enter OTP</h2>
                                    </div>
                                    <div className="login-discription">
                                        <h4>
                                            An OTP has been sent to your phone number ending with
                                            <br />
                                            XXX XXX 4637
                                        </h4>
                                    </div>
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <div className="otpContainer">
                                                        {otp.map((value, index) => (
                                                            <input
                                                                className={`otpInput form-control Login000_400 outline81 ${otp.includes("") === false ? "border border-primary" : ""}`}
                                                                key={index}
                                                                type="tel"
                                                                placeholder='-'
                                                                maxLength={1}
                                                                value={value}
                                                                onChange={(e) => handleChange(e, index)}
                                                                ref={(el) => (inputRefs.current[index] = el)}
                                                            />
                                                        ))}
                                                    </div>
                                                    {/* <p
                                                        className="error-input-msg text-center d-none"
                                                        ref={errorRef}
                                                    >
                                                        {error}
                                                    </p> */}
                                                    {/* <div className="otp-time-count" id=''>00:20 sec</div> */}
                                                    <div className="otp-time-count " id="timer-div">
                                                        <span id="timer">{otpTimer}</span> sec
                                                    </div>
                                                </div>
                                                <div className="resendotp-blk  " id="resendotp">
                                                    Didn't receive OTP yet?{" "}
                                                    <Link
                                                        to="#"
                                                        className={otpTimeLimit > 0 ? "text-secondary" : "color-blue"}
                                                        onClick={resendOtp}
                                                    >
                                                        Resend
                                                    </Link>
                                                </div>
                                                <div className="btn-section">
                                                    <Button
                                                        color='primary'
                                                        // className="btn-primary btn-lg without-input-fill"
                                                        onClick={otpSubmit}
                                                        disabled={otp.join("").length === 6 ? false : true}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                <NavLink className="LoginLanguageBtn text-decoration-underline">English</NavLink>
                                <NavLink className="LoginLanguageBtn">Hindi</NavLink>
                                <NavLink className="LoginLanguageBtn">Deutsch</NavLink>
                            </span>
                            <button id="languageModalDikhao" onClick={LanguageModalToggle} >
                                ...More
                            </button>
                        </div>
                    </div >
                </div>
            </section>
        </Fragment>
    )
}

export default ForgotOtpVerify
