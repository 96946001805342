import * as types from "../../Constants/Common/index.js";

const initialState = { 
    feelingCategory : [],
    loading: true,
};

 const GetFeelingCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FEELING_CATEGORY:
            return {
                ...state,
                feelingCategory : action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default GetFeelingCategoryReducer;