import React from 'react'
const GroupPropsAvatar = (data) => {
    return (
        <div>
            <ul className="matual-friend-blk">
                {
                   data?.followers?.map((item, index) => {
                    if(index <9)
                        return (
                            <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                data-name="sufiya eliza" data-img={item?.profileThumb}>
                                <img src={item?.profileThumb ? item?.profileThumb : item?.profileImageThumb} className="img-fluid bg-img" alt="" />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
export default GroupPropsAvatar