import grid_1 from '../../../NewComponents/IMG/grid_1.png'
import small1 from '../../../NewComponents/IMG/cricleimg.png'




//  ------------- End Story Setting Modal -------------



export const BackgroundGrid = [
    {
        id: 'a',
        background: grid_1
    },
    {
        id: 'b',
        background: 'https://img.rawpixel.com/private/static/images/website/2022-05/rm422-076-x.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=e877fedea1136b8c616786d383ed70c3'
    },
    {
        id: 'c',
        background: 'https://images.pexels.com/photos/9420620/pexels-photo-9420620.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'd',
        background: 'https://images.pexels.com/photos/5110955/pexels-photo-5110955.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'e',
        background: 'https://images.pexels.com/photos/9117243/pexels-photo-9117243.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'f',
        background: 'https://images.pexels.com/photos/9578029/pexels-photo-9578029.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'g',
        background: 'https://images.pexels.com/photos/459335/pexels-photo-459335.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 'h',
        background: 'https://images.pexels.com/photos/754194/pexels-photo-754194.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 'i',
        background: 'https://images.pexels.com/photos/1420440/pexels-photo-1420440.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 'j',
        background: 'https://images.pexels.com/photos/5987164/pexels-photo-5987164.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 'k',
        background: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREcq-h49Ahi7t1WTSfysgML9YbtaSC8DVtOCn0ogqe-9KKf9w_UiJM5qz6-WvIsE2PaH0&usqp=CAU'
    },
    {
        id: 'l',
        background: 'https://www.svgbackgrounds.com/wp-content/uploads/2021/05/liquid-cheese-yellow-background.png'
    },
    {
        id: 'm',
        background: 'https://img.rawpixel.com/private/static/images/website/2022-05/rm422-076-x.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=e877fedea1136b8c616786d383ed70c3'
    },
    {
        id: 'n',
        background: 'https://images.pexels.com/photos/9420620/pexels-photo-9420620.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'o',
        background: 'https://images.pexels.com/photos/5110955/pexels-photo-5110955.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'p',
        background: 'https://images.pexels.com/photos/9117243/pexels-photo-9117243.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'q',
        background: 'https://images.pexels.com/photos/9578029/pexels-photo-9578029.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
    },
    {
        id: 'r',
        background: 'https://images.pexels.com/photos/459335/pexels-photo-459335.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 's',
        background: 'https://images.pexels.com/photos/754194/pexels-photo-754194.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 't',
        background: 'https://images.pexels.com/photos/1420440/pexels-photo-1420440.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 'u',
        background: 'https://images.pexels.com/photos/5987164/pexels-photo-5987164.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        id: 'v',
        background: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREcq-h49Ahi7t1WTSfysgML9YbtaSC8DVtOCn0ogqe-9KKf9w_UiJM5qz6-WvIsE2PaH0&usqp=CAU'
    },
    {
        id: 'w',
        background: 'https://www.svgbackgrounds.com/wp-content/uploads/2021/05/liquid-cheese-yellow-background.png'
    },

]






export const FilterGrid = [
    {

        filters: 'none',
        filtersName: 'None'
    },
    {
        filters: 'sepia(0.5) saturate(1.2) hue-rotate(-10deg)',
        filtersName: 'vintage'
    },
    {
        filters: 'contrast(1.2) saturate(1.2)',
        filtersName: 'Bold'
    },
    {
        filters: 'contrast(1.1) brightness(1.1) saturate(1.2) hue-rotate(-10deg)',
        filtersName: 'Crisp'
    },
    {
        filters: 'grayscale(1) contrast(1.2) brightness(0.9) sepia(0.3)',
        filtersName: 'Noir'
    },
    {
        filters: 'contrast(1.2) saturate(1.5) hue-rotate(-20deg)',
        filtersName: 'Pop'
    },
    {
        filters: 'sepia(0.4) saturate(1.5) hue-rotate(20deg)',
        filtersName: 'Warm'
    },
    {
        filters: 'brightness(1.1) contrast(0.9) saturate(0.8) sepia(0.2)',
        filtersName: 'Pastel'
    },
    {
        filters: 'contrast(0.9) brightness(0.9) saturate(0.8) grayscale(1) sepia(0.2)',
        filtersName: 'Matte'
    },
    {
        filters: 'sepia(0.2) hue-rotate(90deg)',
        filtersName: 'Monochrome'
    },
    {
        filters: 'sepia(0.4) saturate(1.5) hue-rotate(-20deg)',
        filtersName: 'Cool'
    },
    {
        filters: 'contrast(0.9) brightness(1.1) saturate(0.9) sepia(0.3) blur(1px)',
        filtersName: 'Retro'
    },


]



export const StoriesCreateUsers = [
    {
        userImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcgY_vHt1J7eYDJc1afPQ7RQVX8eitJGirWLdkGIsxY7vI30qsxgy_SdpcC5h-2UCNK6c&usqp=CAU',
        userName: "Ella Sofia",
        storyCreateTime: '1 hour ago',
        storieNum: '3 New',
        totalStories: 3
    },

    {
        userImg: 'https://pbs.twimg.com/media/Flt2ERlaMAQrYK0.jpg',
        userName: "Rohan",
        storyCreateTime: '1 hour ago',
        storieNum: '3 New',
        totalStories: 3

    },
    {
        userImg: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcgY_vHt1J7eYDJc1afPQ7RQVX8eitJGirWLdkGIsxY7vI30qsxgy_SdpcC5h-2UCNK6c&usqp=CAU',
        userName: "Ella Sofia",
        storyCreateTime: '1 hour ago',
        storieNum: '3 New',
        totalStories: 3
    },

    {
        userImg: 'https://pbs.twimg.com/media/Flt2ERlaMAQrYK0.jpg',
        userName: "Rohan",
        storyCreateTime: '1 hour ago',
        storieNum: '3 New',
        totalStories: 3
    },

]


export const StoriesView = [
    {
        img: "https://images.pexels.com/photos/1743165/pexels-photo-1743165.jpeg?auto=compress&cs=tinysrgb&w=1600",
        name: "Ella Sofia",
        time: "20 Min ago",
    },
    {
        img: "https://images.pexels.com/photos/1624438/pexels-photo-1624438.jpeg?auto=compress&cs=tinysrgb&w=1600",
        name: "Ella Sofia",
        time: "20 Min ago",
    },
    {
        img: "https://images.pexels.com/photos/1266810/pexels-photo-1266810.jpeg?auto=compress&cs=tinysrgb&w=1600",
        name: "Ella Sofia",
        time: "20 Min ago",
    },
    {
        img: "https://images.pexels.com/photos/1743165/pexels-photo-1743165.jpeg?auto=compress&cs=tinysrgb&w=1600",
        name: "Ella Sofia",
        time: "20 Min ago",
    }
]





export const LikeViewStory = [
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Riya Maurya',
        viewStoryTime: '12 min ago',
        btnName: 'Followers',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Riya Maurya',
        viewStoryTime: '12 min ago',
        btnName: 'SocioMate',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Riya Maurya',
        viewStoryTime: '12 min ago',
        btnName: 'SocioMate',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Annu Rajput',
        viewStoryTime: '12 min ago',
        btnName: 'Followers',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Manni Singh',
        viewStoryTime: '12 min ago',
        btnName: 'SocioMate',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Jaya Maurya',
        viewStoryTime: '12 min ago',
        btnName: 'SocioMate',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Rashami Maurya',
        viewStoryTime: '13 min ago',
        btnName: 'SocioMate',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Riya Maurya',
        viewStoryTime: '12 min ago',
        btnName: 'Followers',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Nisha Maurya',
        viewStoryTime: '12 min ago',
        btnName: 'SocioMate',
    },
    {
        userImgView: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        viewUserName: 'Neelu Sharma',
        viewStoryTime: '12 min ago',
        btnName: 'SocioMate',
    }
]