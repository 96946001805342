import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Alert from './post-creations/sub-components/Alert'
import Article from './post-creations/sub-components/Article'
import Event from './post-creations/sub-components/Event'
import Text from './post-creations/sub-components/Text'
import Poll from './post-creations/sub-components/Poll'
import Recommendation from './post-creations/sub-components/Recommendation'
import Thought from './post-creations/sub-components/Thought'

const AllPostCreationNav = () => {
    const [active, setActive] = useState('1')

    const toggle = tab => {
        setActive(tab)
    }
    return (
        <React.Fragment>
            <Nav className='justify-content-start' pills>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Media
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Thought
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Event
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '4'}
                        onClick={() => {
                            toggle('4')
                        }}
                    >
                        Alert
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '5'}
                        onClick={() => {
                            toggle('5')
                        }}
                    >
                        Recommendation
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '6'}
                        onClick={() => {
                            toggle('6')
                        }}
                    >
                        Poll
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '7'}
                        onClick={() => {
                            toggle('7')
                        }}
                    >
                        Article
                    </NavLink>
                </NavItem>
            </Nav>
            <div className='p-2 mt-2'>
                <TabContent className='py-50' activeTab={active}>
                    <TabPane tabId='1'>
                        <Text />
                    </TabPane>
                    <TabPane tabId='2'>
                        <Thought />
                    </TabPane>
                    <TabPane tabId='3'>
                       <Event/>
                    </TabPane>
                    <TabPane tabId='4'>
                       <Alert/>
                    </TabPane>
                    <TabPane tabId='5'>
                       <Recommendation/>
                    </TabPane>
                    <TabPane tabId='6'>
                       <Poll/>
                    </TabPane>
                    <TabPane tabId='7'>
                       <Article/>
                    </TabPane>
                </TabContent>
            </div>
        </React.Fragment>
    )
}
export default AllPostCreationNav