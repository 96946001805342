
import * as types from "../../Constants/Events/index";

const initialState = {
    userIntrests: [],
    loading: true,
    
  };
    //INTRESTED EVENTS
    export const getUserIntrestedEventsReducer = (state = initialState, action) => {
        switch (action.type) {
            case types.GET_INTRESTED_EVENTS_BY_USER:
                return {
                    ...state,
                    userIntrests:action.payload,
                    loading: false
                };
                default:
                    return state
                }
        };

        