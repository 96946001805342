import { Button } from "reactstrap"
// import Shotzs from '../../../NewComponents/IMG/Shotzs.png'
import Shotzs from '../../NewComponents/IMG/Shotzs.png'
import { Eye, Music } from "react-feather"
import user2 from '../../NewComponents/IMG/userview.svg'
import cover from '../../NewComponents/IMG/cover.png'
import cover1 from '../../NewComponents/IMG/cover1.png'
import Pollimg from '../../NewComponents/IMG/roundedimg2.svg'
import Pollimg1 from '../../NewComponents/IMG/roundedimg3.svg'
import Frame5 from '../../NewComponents/IMG/Frame5.png'
import Frame6 from '../../NewComponents/IMG/Frame6.png'
import Frame7 from '../../NewComponents/IMG/Frame7.png'


//====================================Report Modal data array-=====================
export const ReportDataInfo = [
    {
        name: "Spam"
    },
    {
        name: "Nudity"
    },
    {
        name: "Violence"
    },
    {
        name: "Harrasment"
    },
    {
        name: "Suicide or self-injury"
    },
    {
        name: "Terrorism"
    },
    {
        name: "Others"
    }
]

// export const Bizpage = [
//     {
//         timeline: 'Turn of page notification ',
//         link: 'Copy link',
//         invite1: 'Invite People ',
//         delete: 'Edit page ',
//         share: 'Share page ',
//         content: 'Action Pack',
//         content1: 'Music Equipment',
//         num: '52400',
//         img: cricleimg,
//         button: <button className='btn btn-primary'>Following</button>,
//         button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>
//     },
//     {
//         timeline: 'Turn of page notification ',
//         link: 'Copy link',
//         invite1: 'Invite People ',
//         delete: 'Edit page ',
//         share: 'Share page ',
//         content: 'Music Equipment',
//         content1: 'Music Equipment',
//         num: '60400',
//         img: cricleimg1,
//         button1: <Button Button color='primary' outline>Follow</Button>
//     },
//     {
//         timeline: 'Turn of page notification ',
//         link: 'Copy link',
//         invite1: 'Invite People ',
//         delete: 'Edit page ',
//         share: 'Share page ',
//         content: 'Action Pack',
//         content1: 'Music Equipment',
//         num: '70400',
//         img: cricleimg,
//         button: <button className='btn btn-primary'>Following</button>,
//         button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>
//     },
//     {
//         timeline: 'Turn of page notification ',
//         link: 'Copy link',
//         invite1: 'Invite People ',
//         delete: 'Edit page ',
//         share: 'Share page ',
//         num: '92400',
//         content: 'Music Equipment',
//         content1: 'Music Equipment',
//         img: cricleimg1,
//         button1: <Button Button color='primary' outline>Follow</Button>
//     }
// ]

//============================ card data for top articles===========================
export const cardDataTopArticle = [
    {
        name: 'Floyd Miles',
        topsNumber: '01',
        trendingNumber: '17',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        articleImg: 'https://media.istockphoto.com/id/1431033180/photo/1050237613-save-earth.jpg?s=612x612&w=0&k=20&c=qULgJRlvgnoUtZ8guS8K79q9mACVZalpUnEBQIoc_HQ='
    },
    {
        name: 'Tom allen',
        topsNumber: '02',
        trendingNumber: '18',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        caption: "Let's play",
        articleImg: 'https://media.istockphoto.com/id/1457398261/photo/multi-generation-family-playing-soccer-at-park.jpg?s=612x612&w=0&k=20&c=dtZhN_4Dvifk1ivPma6TLbZx2jSsmUvAbctyatmmsyw='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '19',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Science',
        caption: "The world of wonders",
        articleImg: 'https://media.istockphoto.com/id/509659967/photo/ancient-town-of-matera-at-sunrise-basilicata-italy.jpg?s=612x612&w=0&k=20&c=ROG0EjFEKxStYlCD5qpdEkhHIoFHBsQLw_6zxUYipFE='
    },
    {
        name: 'Raven allen',
        topsNumber: '04',
        trendingNumber: '20',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Psychology',
        caption: "The Turbulent life",
        articleImg: 'https://media.istockphoto.com/id/542727462/photo/houston-texas-skyline.jpg?s=612x612&w=0&k=20&c=ldhxhUFIIaST-o064dsyyzMSV76Eqt0fpjMJUWTP0Is='
    },
]

{/*----------------------------------------------------- card data for top peoples----------------------------------------------------- */ }
export const cardDataTopPeople = [
    {
        name: 'Robert allen',
        topsNumber: '01',
        trendingNumber: '88',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        img1: 'https://media.istockphoto.com/id/1181196036/photo/confident-smiling-indian-young-woman-professional-student-customer-saleswoman-looking-at.jpg?s=612x612&w=0&k=20&c=Va1PbzKrTVfa8l7V9so1Mn25w52-F_5ihmkHAChZ2kA=',
        img2: 'https://media.istockphoto.com/id/1336515315/photo/old-couple-enjoying-using-mobile-phone-at-home.jpg?s=612x612&w=0&k=20&c=1IlSV3qQAGZA-1bVXBspd07f8cgAuUnnsQDbDn-Byy0=',
        img3: 'https://media.istockphoto.com/id/1326852416/photo/young-man-standing-holding-laptop-and-looking-at-camera-with-happy-smile.jpg?s=612x612&w=0&k=20&c=81sYgLF2Oy5X6N-QLfkt1mDzUOC6L_M-o2EJdOid9k0=',
    },
    {
        name: 'Tom allen',
        topsNumber: '02',
        trendingNumber: '89',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        img1: 'https://media.istockphoto.com/id/1185878322/photo/theres-no-better-way-to-stay-organized.jpg?s=612x612&w=0&k=20&c=sz6lOD0uetPBdxPVbbaGJnLHV_kzPuPYC-JQj1zbnD0=',
        img2: 'https://media.istockphoto.com/id/1210060744/photo/portrait-of-nice-attractive-content-cheerful-cheery-guy-pointing-forefinger-aside-advert-copy.jpg?s=612x612&w=0&k=20&c=2UlEcXlgB_OXbFxlknSywX3yMzOVj_JjDk4UYXfZAJw=',
        img3: 'https://media.istockphoto.com/id/1214967264/photo/young-girl-child-with-medical-mask-wearing-protection-against-covid-19-or-coronavirus.jpg?s=612x612&w=0&k=20&c=fn268GU7NpI42fgFtSGGfEijqXZcOWWQDMC-52pAuT4=',
    },
    {
        name: 'Robert Cavan',
        topsNumber: '03',
        trendingNumber: '90',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        img1: 'https://media.istockphoto.com/id/1443211892/photo/portrait-of-indian-young-woman-at-home-stock-photo.jpg?s=612x612&w=0&k=20&c=TR-FhVVY5QT-23Adf7mjlSWhz3E47e1ofmY2nHGfFhw=',
        img2: 'https://media.istockphoto.com/id/1444104733/photo/portrait-of-bank-employee-stock-photo.jpg?s=612x612&w=0&k=20&c=wkvfylsY-RVdSuOenqcIrzpNkDe46bZsr5VkMwTs2lk=',
        img3: 'https://media.istockphoto.com/id/904535280/photo/business-people-having-a-meeting-on-the-hallway.jpg?s=612x612&w=0&k=20&c=5TbKHIGIibVajdarXOg9fXWBKPrN0N6w-dZLLz8b8s4=',
    },
    {
        name: 'Raven allen',
        topsNumber: '04',
        trendingNumber: '91',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        img1: 'https://media.istockphoto.com/id/1477591475/photo/photo-portrait-of-young-pretty-girl-gossiping-wearing-top-isolated-on-purple-background-stock.jpg?s=612x612&w=0&k=20&c=MVU3qywIBavrUo43yF0ZzF1a2z-kiDj0QmXoquUQ0QU=',
        img2: 'https://media.istockphoto.com/id/1288148699/photo/young-business-woman-in-office-stock-photo.jpg?s=612x612&w=0&k=20&c=NNSIerb639k4ukGcYtr48xDc9S77mQ92geHA_CKhu58=',
        img3: 'https://media.istockphoto.com/id/533567012/photo/young-woman-with-face-mask-in-the-street.jpg?s=612x612&w=0&k=20&c=Ma1NFV5zH7pypJRGt6JctZns96gN35C2B4mWy_hF67E=',
    },
]

{/*----------------------------------------------------- card data for top media----------------------------------------------------- */ }
export const cardDataTopMedia = [
    {
        name: 'Floyd Miles',
        video: false,
        topsNumber: '01',
        trendingNumber: '91',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        mediaImg: 'https://images.unsplash.com/photo-1687226013074-5d59ffeb2625?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Tom allen',
        video: true,
        topsNumber: '02',
        trendingNumber: '92',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Let's play",
        mediaImg: 'https://media.istockphoto.com/id/1192236158/photo/explosion-of-colored-powder-on-black-background.jpg?s=612x612&w=0&k=20&c=Qy-L-2pn5GwOJ0j4Z12_ogJpOmUwRr_FR4K-BebFBRM='
    },
    {
        name: 'Floyd Miles',
        video: false,
        topsNumber: '01',
        dateandtime: '19 sept at 07:00 pm',
        category1: "#trendingtitle #2ammotivation #trendinghashtag #trendingtitle #2ammotivation #trendinghashtag",
        mediaImg: 'https://images.unsplash.com/photo-1603729210683-21a3763c1233?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGNvbG91cmZ1bHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Henery havells',
        video: false,
        topsNumber: '03',
        trendingNumber: '93',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        mediaImg: 'https://media.istockphoto.com/id/472128267/photo/beautiful-landscape-in-norther-part-of-india.jpg?s=612x612&w=0&k=20&c=qTFYlIvWkSgB0UW_q0KtXQwszxQdD1GT1S414kL4aPY='
    },
    {
        name: 'Raven allen',
        video: true,
        topsNumber: '04',
        trendingNumber: '94',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life",
        mediaImg: 'https://images.unsplash.com/photo-1603729210683-21a3763c1233?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGNvbG91cmZ1bHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'
    },
]

{/*----------------------------------------------------- card data for top shotz----------------------------------------------------- */ }
export const cardDataTopShotz = [
    // {
    //     gridImg: 'https://images.unsplash.com/photo-1521240104483-b3e91583366c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y29sb3VyZnVsfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',
    //     Shotzs: Shotzs,
    //     icon: <Eye size={18} />,
    //     num: '12000',
    //     view: 'View User',
    //     post: 'Edit',
    //     link: 'Copy Link ',
    //     delete: 'Delete',
    //     share: 'Share',
    //     download: 'Download',
    //     Report: 'Report',
    //     songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
    //     icon1: <Music size={20} color='#FFFFFF' />,
    //     num1: '1000',
    //     num2: '30000',
    //     num3: '23000',
    //     topsNumber: '01',
    //     trendingNumber: '47',
    //     name: 'Sam altman',
    //     dateandtime: '14 Oct at 11:00 am'
    // },
    {
        gridImg: 'https://media.istockphoto.com/id/1232764960/photo/portrait-of-two-smiling-couple-looking-away.jpg?s=612x612&w=0&k=20&c=pb8jfsC3aBZfQJeQSr80cCzcZx0OgsJZf3Bbt4EJnbg=',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        topsNumber: '04',
        trendingNumber: '50',
        name: 'Sam altman',
        dateandtime: '14 Oct at 11:00 am'
    },
    {
        gridImg: 'https://media.istockphoto.com/id/490639696/photo/young-man-celebrating-holi-festival-in-india.jpg?s=612x612&w=0&k=20&c=eOU85GhyllDIQdyCMmWWbGcBUQXnVe5L3AreNpTSyNY=',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        topsNumber: '02',
        trendingNumber: '48',
        name: 'Elon musk',
        dateandtime: '14 Oct at 11:00 am'
    },
    {
        gridImg: 'https://media.istockphoto.com/id/1397798882/photo/portrait-of-a-man-carefully-wiping-a-pot-with-a-rag-on-a-small-base.jpg?s=612x612&w=0&k=20&c=KejtVS1m2Ue5sBrXOfzoXE2YP64EwfDIiyuCn2Pa88Q=',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        topsNumber: '03',
        trendingNumber: '49',
        name: 'Jeff hardy',
        dateandtime: '14 Oct at 11:00 am'
    },
    {
        gridImg: 'https://media.istockphoto.com/id/1232764960/photo/portrait-of-two-smiling-couple-looking-away.jpg?s=612x612&w=0&k=20&c=pb8jfsC3aBZfQJeQSr80cCzcZx0OgsJZf3Bbt4EJnbg=',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        topsNumber: '04',
        trendingNumber: '50',
        name: 'Rey sin',
        dateandtime: '14 Oct at 11:00 am'
    }

]

{/*----------------------------------------------------- card data for top Event----------------------------------------------------- */ }
export const cardDataTopEvent = [
    {
        name: 'Floyd Miles',
        topsNumber: '01',
        trendingNumber: '63',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        eventTitle: 'The Apple meet',
        eventImg: 'https://media.istockphoto.com/id/1420486889/photo/candid-portrait-of-young-middle-eastern-digital-native.jpg?s=612x612&w=0&k=20&c=SV7ZhKg7qCeL6oYZGBaIxqfTTrQZu08kndvIKqdL_PI='
    },
    {
        name: 'Tom allen',
        topsNumber: '02',
        trendingNumber: '64',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Let's play",
        eventTitle: 'Automobile expo',
        eventImg: 'https://media.istockphoto.com/id/139855826/photo/convertible.jpg?s=612x612&w=0&k=20&c=vQwxVQhfKGTE3VOjaWaAVvQPqxj1Xdbr2fj9zKzGXTU='
    },
    {
        name: 'Rey havells',
        topsNumber: '03',
        trendingNumber: '65',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        eventTitle: 'India Today conclave',
        eventImg: 'https://media.istockphoto.com/id/1415056757/photo/colorful-wedding-tents-at-night.jpg?s=612x612&w=0&k=20&c=tJ5NgHKJQs1gJuHTEpd1Uv6peTVxGs5puZUm9zpwT64='
    },
    {
        name: 'Sam smith',
        topsNumber: '04',
        trendingNumber: '66',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life",
        eventTitle: 'The Sunburn fest',
        eventImg: 'https://media.istockphoto.com/id/1247853982/photo/cheering-crowd-with-hands-in-air-at-music-festival.jpg?s=612x612&w=0&k=20&c=rDVKf3hTryuVgUZUme9wuwfsegfJptAvVEKsDwppvJc='
    },
]

{/*----------------------------------------------------- card data for top Podcast----------------------------------------------------- */ }
export const cardDataTopPodcast = [
    {
        name: 'Floyd Miles',
        topsNumber: '01',
        trendingNumber: '72',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        title: 'The truth',
        listenerCount: '100k',
        podcastImg: 'https://media.istockphoto.com/id/1220513148/photo/high-angel-view-of-a-podcaster-behind-microphone.jpg?s=612x612&w=0&k=20&c=CS2K08s1kHE73bj1nfJS4mtEd0LCEnk4v3Gcd_mZL4I='
    },
    {
        name: 'Henery havells',
        topsNumber: '01',
        trendingNumber: '72',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        title: 'The truth',
        listenerCount: '100k',
        podcastImg: 'https://media.istockphoto.com/id/1220513148/photo/high-angel-view-of-a-podcaster-behind-microphone.jpg?s=612x612&w=0&k=20&c=CS2K08s1kHE73bj1nfJS4mtEd0LCEnk4v3Gcd_mZL4I='
    },
    {
        name: 'Tom allen',
        topsNumber: '02',
        trendingNumber: '73',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Spirituality',
        caption: "Let's play",
        title: 'The sacred wisdom',
        listenerCount: '97k',
        podcastImg: 'https://media.istockphoto.com/id/1169477048/photo/i-will-tell-a-joke-now.jpg?s=612x612&w=0&k=20&c=1P3EuuJk9-j2caDWdakykZGZslka5G6Gav9tcQLSfCM='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '74',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Knowledge',
        caption: "The world of wonders",
        title: 'How the turbine works ?',
        listenerCount: '2k',
        podcastImg: 'https://media.istockphoto.com/id/1401474061/photo/entrepreneur-interviewed-on-a-podcast.jpg?s=612x612&w=0&k=20&c=9PNZo4WmuUAXvf5ojBw9xnNySyuTrFNf8RSHXe8ohxQ='
    },
    {
        name: 'Raven allen',
        topsNumber: '04',
        trendingNumber: '75',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', height: '35px', width: '7rem' }}>Episode-5</Button>,
        // , backgroundColor: 'hsla(0,0%,100%,.3)'
        caption: "The Turbulent life",
        title: 'The wall of fame : Lords',
        listenerCount: <span style={{ fontSize: '11px' }}>International Work Podcast...<br></br>International Politics Total<br></br><span className=''> episodes (16)</span></span>,
        podcastImg: 'https://media.istockphoto.com/id/1345287359/photo/diverse-colleagues-recording-podcast-and-talking-in-microphones.jpg?s=612x612&w=0&k=20&c=mTvlCH2V_F7jSX9OGq19WCp75h1SQw7PAGHm1a_VDtY='
    },
    {
        name: 'Raven allen',
        topsNumber: '04',
        trendingNumber: '75',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', height: '35px', width: '7rem' }}>Episode-5</Button>,
        //  backgroundColor: 'hsla(0,0%,100%,.3)',
        caption: "The Turbulent life",
        title: 'The wall of fame : Lords',
        listenerCount: <span style={{ fontSize: '11px' }}>International Work Podcast...<br></br>International Politics Total<br></br><span className=''> episodes (16)</span></span>,
        podcastImg: 'https://media.istockphoto.com/id/1345287359/photo/diverse-colleagues-recording-podcast-and-talking-in-microphones.jpg?s=612x612&w=0&k=20&c=mTvlCH2V_F7jSX9OGq19WCp75h1SQw7PAGHm1a_VDtY='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '74',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', height: '35px', width: '10rem', marginLeft: '-1px' }}>International Politics</Button>,
        // , backgroundColor: 'hsla(0,0%,100%,.3)'
        caption: "The world of wonders",
        title: 'Total episodes (16)',
        listenerCount: <span style={{ fontSize: '14px' }}>Milla is a happiness ambassador whose ultimate goal is breack ...see more</span>,
        podcastImg: 'https://media.istockphoto.com/id/1401474061/photo/entrepreneur-interviewed-on-a-podcast.jpg?s=612x612&w=0&k=20&c=9PNZo4WmuUAXvf5ojBw9xnNySyuTrFNf8RSHXe8ohxQ='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '74',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', height: '33px', width: '10rem', marginLeft: '-1px' }}>International Politics</Button>,
        // , backgroundColor: 'hsla(0,0%,100%,.3)'
        caption: "The world of wonders",
        title: 'Milla is a happiness...see more',
     
        podcastImg: 'https://media.istockphoto.com/id/1401474061/photo/entrepreneur-interviewed-on-a-podcast.jpg?s=612x612&w=0&k=20&c=9PNZo4WmuUAXvf5ojBw9xnNySyuTrFNf8RSHXe8ohxQ='
    },
]

{/*----------------------------------------------------- card data for top Golive grid----------------------------------------------------- */ }
export const topGriddata = [
    {
        user: user2,
        topsNumber: '01',
        trendingNumber: '49',
        viewerCount: '1k',
        img: 'https://media.istockphoto.com/id/533567012/photo/young-woman-with-face-mask-in-the-street.jpg?s=612x612&w=0&k=20&c=Ma1NFV5zH7pypJRGt6JctZns96gN35C2B4mWy_hF67E='
    },
    {
        user: user2,
        topsNumber: '02',
        trendingNumber: '50',
        viewerCount: '2k',
        img: 'https://images.unsplash.com/photo-1579781403261-cbc8f5010479?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGltYWdlJTIwMzAweDIwMHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'
    },
    {
        user: user2,
        topsNumber: '03',
        trendingNumber: '51',
        viewerCount: '9k',
        img: 'https://media.istockphoto.com/id/1457279993/photo/young-engineer-inspecting-solar-panel-plan-at-power-plant-concept-of-professional-occupation.jpg?s=612x612&w=0&k=20&c=cT7EYqcTm0oHPxURdhGMlHNqt9ZludaGBoRaHq0nvT0='
    },
    {
        user: user2,
        topsNumber: '04',
        trendingNumber: '52',
        viewerCount: '3k',
        img: 'https://media.istockphoto.com/id/1311740272/photo/man-meditating-in-lotus-position.jpg?s=612x612&w=0&k=20&c=68FKmwjEU0q0NDTXCIWbI_Lb9QrJ7B0RePXN_ENQrxY='
    }
]

{/*----------------------------------------------------- card data for top Golive grid----------------------------------------------------- */ }
export const topGroupsData = [
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='w-100'>Join Group</Button>,
        cover: cover1,
        topsNumber: '01',
        trendingNumber: '29',
        groupImg: 'https://media.istockphoto.com/id/858790856/photo/young-women-blowing-confetti-from-hands.jpg?s=612x612&w=0&k=20&c=KwbI2iyAroDIqiyIlmOYBuxqnSAoL9yJ5nBKI-qXN1I='
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        content: 'Action Pack',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='w-100'>Join Group</Button>,
        // button: <button className='GroupTabs_Join_Button w-100  '>Join Group</button>,
        cover: cover,
        topsNumber: '02',
        trendingNumber: '30',
        groupImg: 'https://media.istockphoto.com/id/1162712461/photo/self-portrait-of-nice-looking-attractive-winsome-lovely-charming-cute-cheerful-cheery.jpg?s=612x612&w=0&k=20&c=J9FWtCCCGFjbn91FSPRu2pDQdPpRMlXr9CWYMYjpu5I='
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        content: 'Action Pack',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='w-100'>Join Group</Button>,
        cover: cover1,
        topsNumber: '03',
        trendingNumber: '31',
        groupImg: 'https://media.istockphoto.com/id/485992886/photo/adventures-on-the-dolomites-with-dog.jpg?s=612x612&w=0&k=20&c=dAbe_kqunJPoDdX33aFqvumpGcWKzCS0AiJNyqu7Y9s='
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        content: 'Action Pack',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',

        // button: <Button color='primary' className='px-4'>View Activity</Button>,
        // button1: <Button color='primary' outline>Unfollow</Button>,
        // button: <button className='GroupTabs_Join_Button w-100  '>Join Group</button>,
        button: <Button color='primary' className='w-100'>Join Group</Button>,
        cover: cover,
        topsNumber: '04',
        trendingNumber: '32',
        groupImg: 'https://media.istockphoto.com/id/1136837959/photo/happy-friends-having-fun-at-beach-party-on-holi-colors-festival-event-young-people-laughing.jpg?s=612x612&w=0&k=20&c=OPL2vRswFfIkRJivvMhwPMheXuBdvuT_tSE25HLF5Rg='
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        content: 'Action Pack',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',

        // button: <Button color='primary' className='px-4'>View Activity</Button>,
        // button1: <Button color='primary' outline>Unfollow</Button>,
        // button: <button className='GroupTabs_Join_Button w-100  '>Join Group</button>,
        button: <Button color='primary' className='w-100'>Invite</Button>,
        cover: cover,
        topsNumber: '04',
        trendingNumber: '32',
        groupImg: 'https://media.istockphoto.com/id/1136837959/photo/happy-friends-having-fun-at-beach-party-on-holi-colors-festival-event-young-people-laughing.jpg?s=612x612&w=0&k=20&c=OPL2vRswFfIkRJivvMhwPMheXuBdvuT_tSE25HLF5Rg='
    }

]

{/*----------------------------------------------------- card data for top top Thought grid----------------------------------------------------- */ }
export const topThoughtData = [
    {
        img: Pollimg,
        Frame: Frame7,
        className: "ThoughtThisbackgroundcolor w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs',
        ThoughtThistext: <h2 className="text-center" style={{ fontSize: "23px" }}>I am Batman !</h2>,
        topsNumber: '01',
        trendingNumber: '23',
        name: 'Harley ray',
        dateandtime: '21 jan at 03:00 pm'
    },
    {
        img: Pollimg1,
        Frame: Frame5,
        className: "ThoughtThisbackgroundcolor1 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs1',
        ThoughtThistext: 'I’ve missed more than 9000 shots in my career I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed.',
        topsNumber: '02',
        trendingNumber: '24',
        name: 'Chris pratt',
        dateandtime: '19 sept at 07:00 pm'
    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: <h2 className="text-center" style={{ fontSize: "23px" }}>Live like a king</h2>,
        topsNumber: '03',
        trendingNumber: '25',
        name: 'Sam altman',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '04',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: <h2 className="text-center" style={{ fontSize: "23px" }}>Cool !</h2>,

        topsNumber: '05',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Setting goals is the first step in turning the invisible into the visible',
        topsNumber: '06',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '07',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '08',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'I’ve missed more than 9000 shots in my career I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed.',
        topsNumber: '09',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '10',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '11',
        trendingNumber: '26',
        name: 'Ted stoner',
        dateandtime: '01 Dec at 11:00 am'

    },
]

{/*----------------------------------------------------- card data for top top Thought grid----------------------------------------------------- */ }
export const topAudioShotzdata = [
    {
        gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
        num: '14000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        num1: '20000',
        num2: '33000',
        num3: '28000'
    },
    {
        gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
        num: '13000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
        num1: '20000',
        num2: '34000',
        num3: '22000'
    },
    {
        gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
        num: '14000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        num1: '20000',
        num2: '33000',
        num3: '28000'
    },
    {
        gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
        num: '13000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
        num1: '20000',
        num2: '34000',
        num3: '22000'
    },
    {
        gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
        num: '14000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        num1: '20000',
        num2: '33000',
        num3: '28000'
    },
    {
        gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
        num: '13000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
        num1: '20000',
        num2: '34000',
        num3: '22000'
    },
    {
        gridImg: 'https://www.trawell.in/admin/images/upload/76393836Agra_Main.jpg',
        num: '14000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        num1: '20000',
        num2: '33000',
        num3: '28000'
    },
    {
        gridImg: 'https://assets-news.housing.com/news/wp-content/uploads/2022/07/20204813/10-famous-historical-places-in-India-06.jpg',
        num: '13000',
        timeline: 'Hide',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        Reorts: 'Reorts',
        archive: 'Add to Achive',
        songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
        num1: '20000',
        num2: '34000',
        num3: '22000'
    }
]

{/*----------------------------------------------------- card data for top Text grid----------------------------------------------------- */ }
export const topTextData = [
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '91',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: "the act of thinking about or considering something, an idea or subject.the act of thinking about or considering something, an idea or subject.. ",
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '93',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: "the act of thinking about or considering something, an idea or subject.the act of thinking about or considering something, an idea or subject... ",
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },
    {
        img: Pollimg1,
        topsNumber: '01',
        trendingNumber: '92',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: <h2 className="text-center mt-3" style={{ color: 'rgb(129, 193, 75)', fontSize: "23px", marginBottom: '2rem'  }}>#toptitle</h2>
    },

    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '94',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: "the act of thinking about or considering something, an idea or subject.the act of thinking about or considering something, an idea or subject... ",
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '94',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        hashtags: "#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner",
        thought: <h2 className="text-center mt-3" style={{ fontSize: "23px", marginBottom: '2rem' }}>Hey There ! </h2>
    },
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '94',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: "the act of thinking about or considering something, an idea or subject.the act of thinking about or considering something, an idea or subject... ",
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '94',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: "the act of thinking about or considering something, an idea or subject.the act of thinking about or considering something, an idea or subject...",
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '94',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: <h2 class="text-center mt-3" style={{ fontSize: "23px", marginBottom: '2rem' }}>Hello World</h2>
        // hashtags: "#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner"
    },
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '94',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        thought: "the act of thinking about or considering something, an idea or subject.the act of thinking about or considering something, an idea or subject...",
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },



]

{/*----------------------------------------------------- card data for top Poll grid----------------------------------------------------- */ }
export const topPolldata = [
    {
        img: Pollimg,
        topsNumber: '01',
        trendingNumber: '01',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'The organization invests time and money keep employee  technology'

    },
    {
        img: Pollimg1,
        topsNumber: '02',
        trendingNumber: '07',
        name: 'Tom Moody',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'The organization invests time  money  employee updated technology'

    },
    {
        img: Pollimg,
        topsNumber: '03',
        trendingNumber: '93',
        name: 'Henry cavill',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'The organization invests time  money  employee updated technology'

    },
    {
        img: Pollimg,
        topsNumber: '04',
        trendingNumber: '92',
        name: 'tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: "To live a fulfilled life, we need keep creating 'what is next', of our lives."

    },
    {
        img: Pollimg,
        topsNumber: '04',
        trendingNumber: '92',
        name: 'Eric Lesnar',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: "To live a fulfilled life, we need keep creating 'what is next', of our lives."

    },
    {
        img: Pollimg,
        topsNumber: '04',
        trendingNumber: '92',
        name: 'Jeff hardy',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'Thousands of candles can be lighted from a single candle.'

    },
    {
        img: Pollimg,
        topsNumber: '04',
        trendingNumber: '92',
        name: 'Elon musk',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'Thousands of candles can be lighted from a single candle.'

    },
    {
        img: Pollimg,
        topsNumber: '04',
        trendingNumber: '92',
        name: 'Glenn Maxwell',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: "To live a fulfilled life, we need to keep creating of our lives."

    },
]

{/*----------------------------------------------------- Card data for top Recommendation----------------------------------------------------- */ }
export const cardDataTopRecommendation = [
    {
        name: 'Floyd',
        topsNumber: '01',
        trendingNumber: '37',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        imgLink: 'https://media.istockphoto.com/id/920377882/photo/beautiful-landscape-with-high-mountains-with-illuminated-peaks-stones-in-mountain-lake.jpg?s=612x612&w=0&k=20&c=ppnPB3UoILzzxR48gcuUzZx92zepSwegQ-0NqfBDAzk='
    },
    {
        name: 'Tom',
        topsNumber: '02',
        trendingNumber: '38',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Let's play"
    },
    {
        name: 'Henery ',
        topsNumber: '03',
        trendingNumber: '39',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        imgLink: 'https://media.istockphoto.com/id/620970578/photo/aerial-view-of-himalaya.jpg?s=612x612&w=0&k=20&c=306ml7-pzwv_KOhWaOaCebyw2xqGGAhHD_HuIV_LZ4M='
    },
    {
        name: 'Harry',
        topsNumber: '04',
        trendingNumber: '40',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life"
    },
    {
        name: 'Hener',
        topsNumber: '03',
        trendingNumber: '39',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        imgLink: 'https://media.istockphoto.com/id/620970578/photo/aerial-view-of-himalaya.jpg?s=612x612&w=0&k=20&c=306ml7-pzwv_KOhWaOaCebyw2xqGGAhHD_HuIV_LZ4M='
    },
    {
        name: 'Harry',
        topsNumber: '04',
        trendingNumber: '40',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life"
    },
    {
        name: 'Henery',
        topsNumber: '03',
        trendingNumber: '39',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        imgLink: 'https://images.pexels.com/photos/3656989/pexels-photo-3656989.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        name: 'Harry',
        topsNumber: '04',
        trendingNumber: '40',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life"
    },
    {
        name: 'Henery',
        topsNumber: '03',
        trendingNumber: '39',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        imgLink: 'https://images.pexels.com/photos/709552/pexels-photo-709552.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        name: 'Harry ',
        topsNumber: '04',
        trendingNumber: '40',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life"
    },
]

{/* -------------------------------------------------------------------------------------TrendingData Array----------------------------------------------------------------------------------------------------------- */ }

{/* -----------------------------------------------Trending Article Card Data Array------------------------------------------------------------- */ }
export const cardDataTrendingArticle = [
    {
        name: 'Floyd Miles',
        topsNumber: '93',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        imgUse: 'https://images.unsplash.com/photo-1502472584811-0a2f2feb8968?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8ZW52aXJvbm1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Tom allen',
        topsNumber: '94',
        trendingNumber: '02',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        caption: "Let's play",
        imgUse: 'https://images.unsplash.com/photo-1589254065878-42c9da997008?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHJvYm90fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Henery havells',
        topsNumber: '95',
        trendingNumber: '03',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Thriller',
        caption: "The world of wonders",
        imgUse: 'https://media.istockphoto.com/id/1256012711/photo/ready-to-move-to-a-new-home.webp?b=1&s=170667a&w=0&k=20&c=qrfFNaMjh3WLrdxjEJVsl0hGMgnjPVEAAJsmeh5_xJ8='
    },
    {
        name: 'Raven allen',
        topsNumber: '96',
        trendingNumber: '04',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Horror',
        caption: "The Turbulent life",
        imgUse: 'https://images.unsplash.com/photo-1472214103451-9374bd1c798e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGVudmlyb25tZW50fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
    },
]

{/*----------------------------------------------------- card data for trending peoples----------------------------------------------------- */ }
export const cardDataTrendingPeople = [
    {
        name: 'Robert allen',
        topsNumber: '87',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        img1: 'https://images.unsplash.com/photo-1687186735445-df877226fae9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0M3x8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        img2: 'https://images.unsplash.com/photo-1682686581660-3693f0c588d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw0MXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        img3: 'https://images.unsplash.com/photo-1661956602153-23384936a1d3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw0Nnx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Tom allen',
        topsNumber: '88',
        trendingNumber: '02',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        img1: 'https://images.unsplash.com/photo-1674574124475-16dd78234342?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHwzMXx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        img2: 'https://images.unsplash.com/photo-1456926631375-92c8ce872def?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGFuaW1hbHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
        img3: 'https://images.unsplash.com/photo-1444464666168-49d633b86797?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGFuaW1hbHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Robert Cavan',
        topsNumber: '89',
        trendingNumber: '03',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        img1: 'https://images.unsplash.com/photo-1456926631375-92c8ce872def?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGFuaW1hbHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
        img2: 'https://images.unsplash.com/photo-1469474968028-56623f02e42e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bmF0dXJlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',
        img3: 'https://images.unsplash.com/photo-1661956602153-23384936a1d3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw0Nnx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
    },
    {
        name: 'Raven allen',
        topsNumber: '90',
        trendingNumber: '04',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        img1: 'https://images.unsplash.com/photo-1504006833117-8886a355efbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGFuaW1hbHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
        img2: 'https://images.unsplash.com/photo-1661956602153-23384936a1d3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxlZGl0b3JpYWwtZmVlZHw0Nnx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
        img3: 'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=60'
    },
]


{/*----------------------------------------------------- card data for trending videos----------------------------------------------------- */ }

export const cardDataTrendingMedia = [
    {
        name: 'Floyd Miles',
        imgUse: 'https://images.unsplash.com/photo-1424223022789-26fd8f34bba2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        topsNumber: '93',
        trendingNumber: '01',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        video: false
    },
    {
        name: 'Floyd Miles',
        imgUse: 'https://images.unsplash.com/photo-1543722530-d2c3201371e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1474&q=80',
        topsNumber: '93',
        trendingNumber: '01',
        dateandtime: '19 sept at 07:00 pm',
        category1: <p style={{ color: '#81c14b', fontSize: '0.75rem' }} className="mt-2">#trendingtitle #2ammotivation #trendinghashtag #trendingtitle #2ammotivation #trendinghashtag</p>,
        video: false
    },
    {
        name: 'Tom allen',
        imgUse: 'https://images.unsplash.com/photo-1446776811953-b23d57bd21aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80',
        topsNumber: '94',
        trendingNumber: '02',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Let's play",
        video: true
    },
    {
        name: 'Henery havells',
        imgUse: 'https://images.unsplash.com/photo-1543722530-d2c3201371e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1474&q=80',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        video: false
    },
    {
        name: 'Raven allen',
        imgUse: 'https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life",
        video: true
    },
]

{/*----------------------------------------------------- card data for trending Shotz----------------------------------------------------- */ }
export const trendingShotzData = [
    {
        gridImg: 'https://images.unsplash.com/photo-1682687220989-cbbd30be37e9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Baaghi - Tere bin main yun kaise jiya......',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        trendingNumber: '01',
        topsNumber: '90',
        dateandTime: '19 sept at 07:00 pm',
        name: 'Rudy mancuso'
    },
    {
        gridImg: 'https://images.unsplash.com/photo-1628359355624-855775b5c9c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        trendingNumber: '02',
        topsNumber: '91',
        dateandTime: '19 sept at 07:00 pm',
        name: 'Anwar jibawi'
    },
    {
        gridImg: 'https://images.unsplash.com/photo-1603126004012-6b6715b9ed91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Kesariya - Vo lamhe vo baatein....',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        trendingNumber: '03',
        topsNumber: '92',
        dateandTime: '19 sept at 07:00 pm',
        name: 'Max planck'
    },
    {
        gridImg: 'https://images.unsplash.com/photo-1605211419412-16e3f589e435?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80',
        Shotzs: Shotzs,
        icon: <Eye size={18} />,
        num: '12000',
        view: 'View User',
        post: 'Edit',
        link: 'Copy Link ',
        delete: 'Delete',
        share: 'Share',
        download: 'Download',
        Report: 'Report',
        songs: 'Shining in the serene sun like a pearl upon the ocean, comeon....',
        icon1: <Music size={20} color='#FFFFFF' />,
        num1: '1000',
        num2: '30000',
        num3: '23000',
        trendingNumber: '04',
        topsNumber: '93',
        dateandTime: '19 sept at 07:00 pm',
        name: 'Robert kennedy'
    }

]

{/* -----------------------------------------------Trending Event Card Data Array------------------------------------------------------------- */ }
export const cardDataTrendingEvent = [
    {
        name: 'Floyd Miles',
        topsNumber: '93',
        trendingNumber: '01',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        title: "The Sunburn",
        imgUse: 'https://images.unsplash.com/photo-1493676304819-0d7a8d026dcf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80'
    },
    {
        name: 'Tom allen',
        topsNumber: '94',
        trendingNumber: '02',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        title: "Delhi auto expo",
        imgUse: 'https://images.unsplash.com/photo-1485291571150-772bcfc10da5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1528&q=80'
    },
    {
        name: 'Rey havells',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        title: "The mechanized world",
        imgUse: 'https://media.istockphoto.com/id/925425712/photo/woman-wearing-brainwave-scanning-headset-sits-in-a-chair-in-the-modern-brain-study-laboratory.webp?b=1&s=170667a&w=0&k=20&c=TOMhZ74sWUoaqYlQUQ-34HzmzBuRvqYZztyMg7lZv04='
    },
    {
        name: 'Raven allen',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        title: "IAF Day",
        imgUse: 'https://plus.unsplash.com/premium_photo-1661964074409-dfa736a88357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZmlnaHRlciUyMGpldHN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60'
    },
]


{/* -----------------------------------------------Trending Podcast Card Data Array------------------------------------------------------------- */ }
export const cardDataTrendingPodcast = [
    {
        name: 'Floyd Miles',
        topsNumber: '93',
        trendingNumber: '01',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Research',
        title: "The Apocalyptic Brain",
        listenerCount: '20k',
        podcastImg: 'https://media.istockphoto.com/id/1150384596/photo/focused-african-businessman-in-headphones-writing-notes-watching-webinar.jpg?s=612x612&w=0&k=20&c=xZ_xMgvyhtxVPtSxJQHGHjE-HAvv_7OYzbzNN6R3BWc='
    },
    {
        name: 'Tom allen',
        topsNumber: '94',
        trendingNumber: '02',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Knowledge',
        title: "The Biosphere",
        listenerCount: '47k',
        podcastImg: 'https://media.istockphoto.com/id/1409652124/photo/two-young-stylish-radio-show-hosts-record-fresh-podcast-episode-in-home-loft-studio-apartment.jpg?s=612x612&w=0&k=20&c=BBH9Imd9GY_LG2br_FPdxeKmeAyPmmrohv5NYNOAp0w='
    },
    {
        name: 'Henery havells',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Spirituality',
        title: "The Synchronization",
        listenerCount: '87k',
        podcastImg: 'https://media.istockphoto.com/id/1204459993/photo/books-with-headphones-on-a-wooden-table.jpg?s=612x612&w=0&k=20&c=kEBjDlDwjOCcHqp9vFpd-VlswGPBKVkTM2ClEfNcPQM='
    },
    {
        name: 'Raven allen',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        title: "Who's the GOAT ?",
        listenerCount: '23k',
        podcastImg: 'https://media.istockphoto.com/id/925240216/photo/happy-young-woman-of-kinnaur-using-smart-phone-with-headphones.jpg?s=612x612&w=0&k=20&c=iVY-YPe5ruFyzyOziV3z2x0P6iC4LUWZZDum76E14eQ='
    },
    // top and trending =======================
    {
        name: 'Raven allen',
        topsNumber: '04',
        trendingNumber: '75',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'The wall of fame : Lords',
        caption: "The Turbulent life",
        // title: 'The wall of fame : Lords',
        title: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', backgroundColor: 'hsla(0,0%,100%,.3)', height: '35px', width: '7rem' }}>Episode-5</Button>,
        listenerCount: <span style={{ fontSize: '11px', marginTop: '-1rem' }}>International Work Podcast...<br></br>International Politics Total<br></br> episodes (16)</span>,
        podcastImg: 'https://media.istockphoto.com/id/1345287359/photo/diverse-colleagues-recording-podcast-and-talking-in-microphones.jpg?s=612x612&w=0&k=20&c=mTvlCH2V_F7jSX9OGq19WCp75h1SQw7PAGHm1a_VDtY='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '74',
        country: 'Finland',
        followerCount: '55',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Total episodes (16)',
        caption: "The world of wonders",
        // title: 'Total episodes (16)',
        title: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', backgroundColor: 'hsla(0,0%,100%,.3)', height: '35px', width: '10rem' }}>International Politics</Button>,
        listenerCount: <span style={{ fontSize: '14px' }}>Milla is a happiness ambassador whose ultimate goal is breack ...see more</span>,
        podcastImg: 'https://media.istockphoto.com/id/1401474061/photo/entrepreneur-interviewed-on-a-podcast.jpg?s=612x612&w=0&k=20&c=9PNZo4WmuUAXvf5ojBw9xnNySyuTrFNf8RSHXe8ohxQ='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '74',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Total episodes (16)',
        caption: "The world of wonders",
        // title: 'Total episodes (16)',
        title: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', backgroundColor: 'hsla(0,0%,100%,.3)', height: '35px', width: '10rem' }}>International Politics</Button>,
        listenerCount: <span style={{ fontSize: '14px' }}>Milla is a happiness ambassador whose ultimate goal is breack ...see more</span>,
        podcastImg: 'https://media.istockphoto.com/id/1401474061/photo/entrepreneur-interviewed-on-a-podcast.jpg?s=612x612&w=0&k=20&c=9PNZo4WmuUAXvf5ojBw9xnNySyuTrFNf8RSHXe8ohxQ='
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '74',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Milla is a happiness ambassador whose ultimate goal is breack ...see more',
        caption: "The world of wonders",
        // title: 'Total episodes (16)',
        title: <Button className='mt-1' style={{ color: 'white', borderRadius: '20px', backgroundColor: 'hsla(0,0%,100%,.3)', height: '35px', width: '10rem' }}>International Politics</Button>,

        podcastImg: 'https://media.istockphoto.com/id/1401474061/photo/entrepreneur-interviewed-on-a-podcast.jpg?s=612x612&w=0&k=20&c=9PNZo4WmuUAXvf5ojBw9xnNySyuTrFNf8RSHXe8ohxQ='
    },
]


{/* -----------------------------------------------Trending Golive Card Data Array------------------------------------------------------------- */ }
export const trendingGoliveGridData = [
    {
        user: user2,
        Golive: 'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        topsNumber: '93',
        trendingNumber: '01',
        viewerCount: '2k'
    },
    {
        user: user2,
        Golive: 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        topsNumber: '94',
        trendingNumber: '02',
        viewerCount: '4k'
    },
    {
        user: user2,
        Golive: 'https://images.unsplash.com/photo-1525926477800-7a3b10316ac6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        topsNumber: '95',
        trendingNumber: '03',
        viewerCount: '1k'
    },
    {
        user: user2,
        Golive: 'https://images.unsplash.com/photo-1576747249530-46bc7b93ed61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1034&q=80',
        topsNumber: '96',
        trendingNumber: '04',
        viewerCount: '11k'
    }
]

{/* -----------------------------------------------Trending Groups Card Data Array------------------------------------------------------------- */ }
export const trendingGroups = [
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='GroupTabs_Join_Button w-100' outline >Join Group</Button>,
        cover: 'https://media.istockphoto.com/id/1458481862/photo/asian-chinese-lesbian-couple-celebrating-birthday-outdoor-dining-with-friends.webp?b=1&s=170667a&w=0&k=20&c=DDUIqMIEmxoJ0kT6v9LEyRC4Xz0pvMfynXhEfdRQSGw=',
        topsNumber: '92',
        trendingNumber: '01',
        memberCount: '22.9k'
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        // button: <button className='GroupTabs_Join_Button w-100'>Join Group</button>,
        button: <Button color='primary' className='GroupTabs_Join_Button w-100' outline >Join Group</Button>,
        cover: 'https://images.unsplash.com/photo-1444210971048-6130cf0c46cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGdyb3VwfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',
        topsNumber: '93',
        trendingNumber: '02',
        memberCount: '78.9k'
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='GroupTabs_Join_Button w-100' outline >Join Group</Button>,
        cover: 'https://images.unsplash.com/photo-1531844251246-9a1bfaae09fc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGdyb3VwfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60',
        topsNumber: '94',
        trendingNumber: '03',
        memberCount: '31.4k'
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='GroupTabs_Join_Button w-100' outline >Join Group</Button>,
        // button: <button className='GroupTabs_Join_Button w-100'>Join Group</button>,
        // button: <Button color='primary' className='px-4'>View Activity</Button>,
        // button1: <Button color='primary' outline>Unfollow</Button>,
        cover: 'https://images.unsplash.com/photo-1506869640319-fe1a24fd76dc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        topsNumber: '95',
        trendingNumber: '04',
        memberCount: '87k'
    },
    {
        sharevia: 'Share via',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='GroupTabs_Join_Button w-100' outline >Invite</Button>,
        cover: 'https://images.unsplash.com/photo-1506869640319-fe1a24fd76dc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        topsNumber: '95',
        trendingNumber: '04',
        memberCount: '87k'
    }


]

{/* -----------------------------------------------Trending Thoughts Card Data Array------------------------------------------------------------- */ }
export const trendingThoughtData = [
    {
        img: Pollimg,
        Frame: Frame7,
        className: "ThoughtThisbackgroundcolor w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs',
        ThoughtThistext: <h2 fontSize={23}>One day or day one ?</h2>,
        topsNumber: '93',
        trendingNumber: '01',
        name: 'Floyd Lee',
        dateAndTime: '19 sept at 07:00 pm'
    },
    {
        img: Pollimg1,
        Frame: Frame5,
        className: "ThoughtThisbackgroundcolor1 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs1',
        ThoughtThistext: 'Thousands of candles can be lighted from a single candle, and the life of the candle will not be shortened. Happiness never decreases by being shared.',
        topsNumber: '94',
        trendingNumber: '02',
        name: 'Mike tyson',
        dateAndTime: '19 sept at 07:00 pm'
    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: <h2 fontSize={23}>Peace in chaos</h2>,
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Bruce Lee',
        dateAndTime: '19 sept at 07:00 pm'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do.',
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Bruce Lee',
        dateAndTime: '19 sept at 07:00 pm'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: "My friends, love is better than anger. Hope is better than fear. Optimism is better than despair. So let us be loving, hopeful and optimistic. And we'll change the world.",
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Bruce Lee',
        dateAndTime: '19 sept at 07:00 pm'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: <h2 fontSize={23}>Hello !</h2>,
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Bruce Lee',
        dateAndTime: '19 sept at 07:00 pm'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Twenty years from now you will be more disappointed by the things that you didn’t do than by the ones you did do.',
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Bruce Lee',
        dateAndTime: '19 sept at 07:00 pm'

    },
    {
        img: Pollimg,
        Frame: Frame6,
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'The power of imagination makes us infinite.',
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Bruce Lee',
        dateAndTime: '19 sept at 07:00 pm'

    },
]

{/* -----------------------------------------------Trending Poll Card Data Array------------------------------------------------------------- */ }
export const trendingPollData = [
    {
        img: Pollimg,
        topsNumber: '93',
        trendingNumber: '01',
        name: 'Floyd miles',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'Strive not to be a success, but rather to be of value time and rather of value.'

    },
    {
        img: Pollimg1,
        topsNumber: '94',
        trendingNumber: '02',
        name: 'Tom Moody',
        dateAndTime: '19 sept at 07:00 pm',
        // pollText: <p className="mt-2">The organization invests time  money ?</p>
        pollText: 'The world is full of magical things patiently waiting for our wits to grow sharper.'

    },
    {
        img: Pollimg,
        topsNumber: '95',
        trendingNumber: '03',
        name: 'Henry cavill',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'The organization invests time  money  employee updated with technology'

    },
    {
        img: Pollimg,
        topsNumber: '96',
        trendingNumber: '04',
        name: 'Tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        // pollText: <p className="mt-2">Do wormholes exist?</p>
        pollText: 'The world is full of magical things patiently waiting for our wits to grow sharper.'

    },
    {
        img: Pollimg,
        topsNumber: '96',
        trendingNumber: '04',
        name: 'Tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'The world is full of magical things patiently waiting for our wits to grow sharper.'

    },
    {
        img: Pollimg,
        topsNumber: '96',
        trendingNumber: '04',
        name: 'Tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'Few things can help an individual more than to place responsibility him. '

    },
    {
        img: Pollimg,
        topsNumber: '96',
        trendingNumber: '04',
        name: 'Tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        // pollText: <p className="mt-2">DC or Marvel</p>
        pollText: 'Some men see things as they are  say why—I dream things that never why not.? '

    },
    {
        img: Pollimg,
        topsNumber: '96',
        trendingNumber: '04',
        name: 'Tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        pollText: 'Some men see things as they are  say why—I dream things that never why not.? '

    },


]

{/* -----------------------------------------------Trending Text Card Data Array------------------------------------------------------------- */ }
export const trendingTextData = [
    {
        img: Pollimg,
        topsNumber: '96',
        trendingNumber: '01',
        name: 'Tom holland',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: 'The International Space Station Strive not  a success, but rather to be of valueThe International Space Station Strive not  a Space Station  success..',
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },

    {
        img: Pollimg,
        topsNumber: '98',
        trendingNumber: '03',
        name: 'Michael jordan',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: 'The International Space Station Strive not  a success, but rather to be of valueThe International Space Station Strive not  a success..',
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'

    },
    {
        img: Pollimg1,
        topsNumber: '97',
        trendingNumber: '02',
        name: 'Bruce lee',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: <h2 className="text-center mt-3" style={{ color: 'rgb(129, 193, 75)', fontSize: "23px", marginBottom: '2.1rem' }}>#trendingtitle</h2>

    },

    {
        img: Pollimg,
        topsNumber: '98',
        trendingNumber: '03',
        name: 'Michael jordan',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: 'The International Space Station Strive not  a success, but rather to be of valueThe International Space Station Strive not  a success..',
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'

    },
    {
        img: Pollimg,
        topsNumber: '99',
        trendingNumber: '04',
        name: 'Steve smith',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: <h2 className="text-center mt-3" style={{ fontSize: "23px", marginBottom: '2.1rem' }}> Hola !  </h2>

    },
    {
        img: Pollimg,
        topsNumber: '98',
        trendingNumber: '03',
        name: 'Michael jordan',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: 'The International Space Station Strive not  a success, but rather to be of value The International Space Station Strive not  a success..',
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'

    },
    {
        img: Pollimg,
        topsNumber: '98',
        trendingNumber: '03',
        name: 'Michael jordan',
        dateAndTime: '19 sept at 07:00 pm',
        trendingText: 'The International Space Station Strive not a success, but rather to be of value The International Space Station Strive not  a success..',
        more: 'more',
        Astronomy: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'

    },
]

{/* -------------------------------------------------------Trending Recommendation Card Data Array------------------------------------------------------------- */ }
export const cardDataTrendingRecommendation = [
    {
        name: 'Floyd',
        topsNumber: '93',
        trendingNumber: '01',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Research',
        title: "The Apocalyptic Brain",
        listenerCount: '20k',
        imgLink: 'https://media.istockphoto.com/id/1221286969/photo/nakki-lake-is-a-lake-in-the-indian-hill-station-of-mount-abu-in-aravalli-range.jpg?s=612x612&w=0&k=20&c=jLfbsoRnLfuSNLWDwk4fifmv3om-RaYgxR8b0wQJWVQ='
    },
    {
        name: 'Tom',
        topsNumber: '94',
        trendingNumber: '02',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Knowledge',
        title: "The Biosphere",
        listenerCount: '47k',
        // imgLink: 'https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png'
        textdata: 'The International Space Station (ISS) is a large spacecraft in low Earth orbit. It is an artificial satellite, which means it is an object made by humans that orbits Earth.',
        hashtags: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner'
    },
    {
        name: 'Sam',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Spirituality',
        title: "The Synchronization",
        listenerCount: '87k',
        imgLink: 'https://media.istockphoto.com/id/1417325612/photo/naneghat-reverse-fountain-spot-in-the-mountain-ranges-of-sahyadri-mountain-ranges-of.jpg?s=612x612&w=0&k=20&c=3knn1kzqrmX9nWcGTuzJ3MyqBSrVfpclkSP2MGn2npA='
    },
    {
        name: 'Raven',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        title: "Who's the GOAT ?",
        listenerCount: '23k',
        // imgLink: 'https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png'
        hashtags: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner',
        textdata: 'The International Space Station (ISS) is a large spacecraft in low Earth orbit. It is an artificial satellite, which means it is an object made by humans that orbits Earth.',
    },
    {
        name: 'Sam',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Spirituality',
        title: "The Synchronization",
        listenerCount: '87k',
        imgLink: 'https://images.pexels.com/photos/1480807/pexels-photo-1480807.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        name: 'Raven',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        title: "Who's the GOAT ?",
        listenerCount: '23k',
        // imgLink: 'https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png'
        hashtags: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner',
        textdata: 'The International Space Station (ISS) is a large spacecraft in low Earth orbit. It is an artificial satellite, which means it is an object made by humans that orbits Earth.',
    },

    {
        name: 'Sam',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Spirituality',
        title: "The Synchronization",
        listenerCount: '87k',
        imgLink: 'https://images.pexels.com/photos/86703/horseshoe-bend-page-arizona-colorado-river-86703.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        name: 'Raven',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        title: "Who's the GOAT ?",
        listenerCount: '23k',
        // imgLink: 'https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png'
        hashtags: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner',
        textdata: 'The International Space Station (ISS) is a large spacecraft in low Earth orbit. It is an artificial satellite, which means it is an object made by humans that orbits Earth.',
    },
    {
        name: 'Sam',
        topsNumber: '95',
        trendingNumber: '03',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Spirituality',
        title: "The Synchronization",
        listenerCount: '87k',
        imgLink: 'https://images.pexels.com/photos/454880/pexels-photo-454880.jpeg?auto=compress&cs=tinysrgb&w=1600'
    },
    {
        name: 'Raven',
        topsNumber: '96',
        trendingNumber: '04',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Sports',
        title: "Who's the GOAT ?",
        listenerCount: '23k',
        // imgLink: 'https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png'
        hashtags: '#Astronomy #Musiccelebration #music #party #music #cricket #astrophysics #winner',
        textdata: 'The International Space Station (ISS) is a large spacecraft in low Earth orbit. It is an artificial satellite, which means it is an object made by humans that orbits Earth.',
    },


]

{/*----------------------------------------------------- data for top list Articles----------------------------------------------------- */ }
export const listDataArticle = [
    {
        name: 'Floyd Miles',
        topsNumber: '01',
        trendingNumber: '58',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        articleName: "Rudy Maxa's World"
    },
    {
        name: 'Tom allen',
        topsNumber: '02',
        trendingNumber: '59',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        articleName: "Let's play"
    },
    {
        name: 'Henery havells',
        topsNumber: '03',
        trendingNumber: '60',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        articleName: "The world of wonders"
    },
    {
        name: 'Raven allen',
        topsNumber: '04',
        trendingNumber: '61',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        articleName: "The Turbulent life"
    },
]
// ===================================Recomendition list============================================//
export const listDataArticles = [
    {
        name: 'Hugo Schamberger',
        topsNumber: '01',
        trendingNumber: '58',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        category: 'Entertainment',
        articleName: "I am seeking recommendation for  Netherlands for a vacation.",
        dateandtime: '19 sept at 07:00 pm',
    },
    {
        name: 'Hugo Schamberger',
        topsNumber: '02',
        trendingNumber: '59',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        articleName: "I am seeking recommendation for What’s your  first memory?"
    },
    {
        name: 'Hugo Schamberger',
        topsNumber: '03',
        trendingNumber: '60',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        articleName: "I am seeking recommendation for   Netherlands for a vacation."
    },

]
// ===================================Recomendition list end============================================//
{/*----------------------------------------------------- List data for top list Media----------------------------------------------------- */ }
export const listDataMedia = [
    {
        name: 'Floyd Miles',
        topsNumber: '41',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        video: true
    },
    {
        name: 'Tom allen',
        topsNumber: '42',
        trendingNumber: '02',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Let's play",
        video: false
    },
    {
        name: 'Henery havells',
        topsNumber: '43',
        trendingNumber: '03',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders",
        video: true
    },
    {
        name: 'Raven allen',
        topsNumber: '44',
        trendingNumber: '04',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life",
        video: false
    },
]

{/* -------------------------------------------------List View data array------------------------------------------------------------------------- */ }
export const listDataShotz = [
    {
        name: 'Floyd Miles',
        topsNumber: '05',
        trendingNumber: '01',
        country: 'Denmark',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World"
    },
    {
        name: 'Tom allen',
        topsNumber: '06',
        trendingNumber: '02',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Let's play"
    },
    {
        name: 'Henery havells',
        topsNumber: '07',
        trendingNumber: '03',
        country: 'Finland',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The world of wonders"
    },
    {
        name: 'Raven allen',
        topsNumber: '08',
        trendingNumber: '04',
        country: 'Nigeria',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "The Turbulent life"
    },
]

{/*----------------------------------------------------- list data for top event----------------------------------------------------- */ }
export const listDataEvent = [
    {
        name: 'Floyd Miles',
        topsNumber: '05',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: 'Oct 24, 2022 - Oct 27, 2022',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        eventTitle: 'The Apple meet'
    },
    {
        name: 'Tom allen',
        topsNumber: '06',
        trendingNumber: '02',
        country: 'Swiss',
        followerCount: '32',
        postCount: '05',
        dateandtime: 'Oct 24, 2022 - Oct 27, 2022',
        category: 'Entertainment',
        caption: "Let's play",
        eventTitle: 'Automobile expo'
    },
    {
        name: 'Henery havells',
        topsNumber: '07',
        trendingNumber: '03',
        country: 'Finland',
        followerCount: '11',
        postCount: '200',
        dateandtime: 'Oct 24, 2022 - Oct 27, 2022',
        category: 'Entertainment',
        caption: "The world of wonders",
        eventTitle: 'India Today conclave'
    },
    {
        name: 'Raven allen',
        topsNumber: '08',
        trendingNumber: '04',
        country: 'Nigeria',
        followerCount: '55',
        postCount: '18',
        dateandtime: 'Oct 24, 2022 - Oct 27, 2022',
        category: 'Entertainment',
        caption: "The Turbulent life",
        eventTitle: 'The Sunburn fest'
    },
]

{/*----------------------------------------------------- list data for top Podcast----------------------------------------------------- */ }
export const listDataPodcast = [
    {
        name: 'Floyd Miles',
        topsNumber: '06',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        title: 'The truth',
        episode: "Ep1",
        day: "5:00",

    },
    {
        name: 'Floyd Miles',
        topsNumber: '06',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        title: 'The truth',
        episode: "Ep2",
        day: "5:00",

    },
    {
        name: 'Floyd Miles',
        topsNumber: '06',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        title: 'The truth',
        episode: "Ep3",
        day: "5:00",

    },
    {
        name: 'Floyd Miles',
        topsNumber: '06',
        trendingNumber: '01',
        country: 'Denmark',
        followerCount: '254',
        postCount: '12',
        dateandtime: '19 sept at 07:00 pm',
        category: 'Entertainment',
        caption: "Rudy Maxa's World",
        title: 'The truth',
        episode: "Ep4",
        day: "5:00",

    },
]

{/*----------------------------------------------------- list data for top GoLive----------------------------------------------------- */ }
export const listdataGolive = [
    {

        Golive: 'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        topsNumber: '60',
        trendingNumber: '01',
        name: 'Floyd miles',
        text: '@aman&12 Sociomatesjoined',
        dateAndTime: '19 sept at 07:00 pm',
        tag: 'Entertainment',
        viewerCount: '12k'
    },
    {

        Golive: 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        topsNumber: '51',
        trendingNumber: '02',
        name: 'Tom cavan',
        text: '@aman&12 Sociomatesjoined',
        dateAndTime: '19 sept at 07:00 pm',
        tag: 'Thriller',
        viewerCount: '7k'
    },
    {

        Golive: 'https://images.unsplash.com/photo-1525926477800-7a3b10316ac6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
        topsNumber: '52',
        trendingNumber: '03',
        name: 'Hardy schulz',
        text: '@aman&12 Sociomatesjoined',
        dateAndTime: '19 sept at 07:00 pm',
        tag: 'Health',
        viewerCount: '44k'
    },
    {

        Golive: 'https://images.unsplash.com/photo-1576747249530-46bc7b93ed61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1034&q=80',
        topsNumber: '53',
        trendingNumber: '04',
        name: 'Rita fariya',
        text: '@aman&12 Sociomatesjoined',
        dateAndTime: '19 sept at 07:00 pm',
        tag: 'Sci-fi',
        viewerCount: '99k'
    }
]

{/*----------------------------------------------------- list data for top BizPage----------------------------------------------------- */ }
export const listBizpage = [
    {
        timeline: 'Turn of page notification ',
        link: 'Copy link',
        invite1: 'Invite People ',
        delete: 'Edit page ',
        share: 'Share page ',
        content: 'Action Pack',
        content1: 'Music Equipment',
        num: '52400',
        button: <button className='btn btn-primary'>Following</button>,
        topsNumber: '05',
        trendingNumber: '01',
    },
    {
        timeline: 'Turn of page notification ',
        link: 'Copy link',
        invite1: 'Invite People ',
        delete: 'Edit page ',
        share: 'Share page ',
        content: 'Traditional Wedding ',
        content1: 'Music Equipment',
        num: '60400',
        topsNumber: '06',
        trendingNumber: '02',
    },
    {
        timeline: 'Turn of page notification ',
        link: 'Copy link',
        invite1: 'Invite People ',
        delete: 'Edit page ',
        share: 'Share page ',
        content: 'Action Pack',
        content1: 'Music Equipment',
        num: '70400',
        button: <button className='btn btn-primary'>Following</button>,
        topsNumber: '08',
        trendingNumber: '03',
    },
    {
        timeline: 'Turn of page notification ',
        link: 'Copy link',
        invite1: 'Invite People ',
        delete: 'Edit page ',
        share: 'Share page ',
        num: '92400',
        content: 'Traditional Wedding ',
        content1: 'Music Equipment',
        topsNumber: '07',
        trendingNumber: '04',
    }
]

{/*----------------------------------------------------- list data for top Groups----------------------------------------------------- */ }
export const listGroups = [
    {
        sharevia: 'Share via',
        topsNumber: '05',
        trendingNumber: '01',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='w-100' outline>Requested</Button>,

    },
    {
        sharevia: 'Share via',
        topsNumber: '04',
        trendingNumber: '02',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <button className='GroupTabs_Join_Button w-100'>Join Group</button>,

    },
    {
        sharevia: 'Share via',
        topsNumber: '06',
        trendingNumber: '03',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='w-100' outline>Requested</Button>,

    },
    {
        sharevia: 'Share via',
        topsNumber: '07',
        trendingNumber: '04',
        shareto: 'Share to',
        notification: 'Turn of Notification',
        invite1: 'Invite people',
        report: 'Report group',
        leave: 'Leave Group',
        create: 'Create Group',
        button: <Button color='primary' className='px-4'>View Activity</Button>,
        button1: <Button color='primary' outline>Unfollow</Button>,

    }


]

{/*----------------------------------------------------- list data for thoughts----------------------------------------------------- */ }
export const listThoughtdata = [
    {
        className: "ThoughtThisbackgroundcolor w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs',
        ThoughtThistext: 'Keep your face always toward the sunshine—and shadows will fall behind you.',
        trendingNumber: '01',
        topsNumber: '54',
        name: 'Floyd miles'
    },
    {
        className: "ThoughtThisbackgroundcolor1 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs1',
        ThoughtThistext: '’ve missed more than 9000 shots in my career I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed.',
        trendingNumber: '01',
        topsNumber: '54',
        name: 'John cater'
    },
    {
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good, to go for the great things you believe in.',
        trendingNumber: '01',
        topsNumber: '54',
        name: 'Dave huge'

    }
]

{/*----------------------------------------------------- list data for Poll----------------------------------------------------- */ }
export const listTopPolldata = [
    {
        className: "ThoughtThisbackgroundcolor w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs',
        ThoughtThistext: 'If people are doubting how far you can go, go so far that you can’t hear them anymore.',
        topsNumber: '01',
        trendingNumber: '93',
        dateAndTime: '19 sept at 07:00 pm',
        name: 'Floyd miles'
    },
    {
        className: "ThoughtThisbackgroundcolor1 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs1',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '02',
        trendingNumber: '94',
        dateAndTime: '19 sept at 07:00 pm',
        name: 'Dom torreto'
    },
    {
        className: "ThoughtThisbackgroundcolor2 w-90 h-50",
        ThoughtThis: 'ThisThoughtTabs2',
        ThoughtThistext: 'Don’t be afraid to give up the good to go for the great',
        topsNumber: '03',
        trendingNumber: '95',
        dateAndTime: '19 sept at 07:00 pm',
        name: 'Jason Bourne'

    }
]

{/*----------------------------------------------------- list data for Text----------------------------------------------------- */ }
export const listTextdata = [
    {
        topsNumber: '91',
        name: 'Tom Cavanaugh',
        dateandtime: '19 sept at 07:00 pm',
        trendingNumber: '01'

    },
    {
        name: 'Hardy schulz',
        topsNumber: '92',
        dateandtime: '19 sept at 07:00 pm',
        trendingNumber: '02'

    },
    {
        name: 'Rey cena',
        topsNumber: '93',
        dateandtime: '19 sept at 07:00 pm',
        trendingNumber: '03'

    },
    {
        name: 'Steve austin',
        topsNumber: '94',
        dateandtime: '19 sept at 07:00 pm',
        trendingNumber: '04'

    }

]

{/*----------------------------------------------------- list data for Trending Audio----------------------------------------------------- */ }
export const listTrendingAudio = [
    {
        topsNumber: '91',
        trendingNumber: '01',
        category: 'Sports',
        description: 'Badminton is a racquet sport played using a net.',
        count: '124',
        time: '8',
        imageLink: 'https://images.unsplash.com/photo-1579952363873-27f3bade9f55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80'

    },
    {
        topsNumber: '92',
        trendingNumber: '02',
        category: 'Song',
        description: "The buzz around new product is a worth watch",
        count: '22',
        time: '5',
        imageLink: 'https://images.unsplash.com/photo-1567593810070-7a3d471af022?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80'

    },
    {
        topsNumber: '93',
        trendingNumber: '03',
        category: 'Movies',
        description: 'The film starts Akhil getting paid for doing an ad.',
        count: '01',
        time: '3',
        imageLink: 'https://images.unsplash.com/photo-1628126235206-5260b9ea6441?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'

    },
    {
        topsNumber: '94',
        trendingNumber: '04',
        category: 'Psychology',
        description: 'The human brain is most complex structure.',
        count: '33',
        time: '1',
        imageLink: 'https://plus.unsplash.com/premium_photo-1680782694416-8e2536943edc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80'

    }

]

{/*----------------------------------------------------- list data for Top Audio----------------------------------------------------- */ }
export const listTopAudio = [
    {
        topsNumber: '01',
        trendingNumber: '47',
        category: 'Sports',
        description: 'Badminton is a racquet sport played using a net.',
        count: '124',
        time: '8',
        imageLink: 'https://images.unsplash.com/photo-1579952363873-27f3bade9f55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=735&q=80'

    },
    {
        topsNumber: '02',
        trendingNumber: '48',
        category: 'Song',
        description: "The buzz around new product is a worth watch",
        count: '22',
        time: '5',
        imageLink: 'https://images.unsplash.com/photo-1567593810070-7a3d471af022?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80'

    },
    {
        topsNumber: '03',
        trendingNumber: '49',
        category: 'Movies',
        description: 'The film starts Akhil getting paid for doing an ad.',
        count: '01',
        time: '3',
        imageLink: 'https://images.unsplash.com/photo-1628126235206-5260b9ea6441?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'

    },
    {
        topsNumber: '04',
        trendingNumber: '50',
        category: 'Psychology',
        description: 'The human brain is most complex structure.',
        count: '33',
        time: '1',
        imageLink: 'https://plus.unsplash.com/premium_photo-1680782694416-8e2536943edc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80'

    }

]


