import React, { Fragment } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { FilterAllData } from '../MarketPlaceArray/MarketPlaceCategory';
import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const MarketCategoryAccordion = (props) => {
  const [isClicked, setIsClicked] = useState("Home/Real Estate");
  const [isActive, setIsActive] = useState(null);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const allCheckBoxes = Object.keys(props.data);
  const keyValues = Object.values(FilterAllData[props.isClicked]?.checkBox || {})[0] || [];

  const MangeTheClick = (check, index) => {
    props.setIsClicked(`Home/${check}`);

    const areAllCheckboxesClicked = props.data[allCheckBoxes[0]].every((checkbox, i) => i === isActive);

    if (areAllCheckboxesClicked) {
     props.setIsActive(null);
    } else {
      props.setIsActive(index);
    }
  };

  const handleCheckboxChange = (subcate) => {
    const newCheckedCheckboxes = [...props.checkedCheckboxes];
    const subcateIndex = newCheckedCheckboxes.indexOf(subcate);

    if (subcateIndex === -1) {
      newCheckedCheckboxes.push(subcate);
    } else {
      newCheckedCheckboxes.splice(subcateIndex, 1);
    }

    props.setCheckedCheckboxes(newCheckedCheckboxes);
  };
  const handleClearAllClick = () => {
    setIsActive(null);
    setCheckedCheckboxes([]);

  };

  return (
    <Fragment>
      <AccordionBody className='Mp_CategoryAccordion' style={{ borderBottom: "1px solid #E5E5E5 ", paddingLeft: "1.25rem", paddingTop: "1rem", paddingBottom: "1rem", paddingRight: "0rem" }}>
        <div className="MpUploadHeading " style={{ paddingRight: "1rem" }}>
          <h3 className='Mp_Color4D_Font6'>{allCheckBoxes[0]}</h3>
          {/* <h5 className={`MrktClearAllBtn ${props.isActive !== null}`} onClick={props.handleClearAll}>clear all</h5> */}
        </div>
        <div className='d-flex flex-column align-items-start justify-content-start gap-3 '>
          <Accordion className="w-100" defaultActiveKey="0">
            {/* {props.data[allCheckBoxes[0]].map((check, index) => ( */} 
            {props?.categoryData?.map((check, index) => (
              <AccordionItem className='Mp_FilterAccordionItem_btn' style={{ background: "#FFFFFF" }} eventKey={index} onClick={() => { MangeTheClick(check, index) }}>
                <AccordionHeader className='Mp_FilterAccordionItem_btn'>
                  <FormControlLabel control={<Checkbox sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 20,
                      marginRight: "0px",
                      marginBottom: "0px !important",
                    },
                    color: "#cbcbcb",
                    '&.Mui-checked': {
                      color: "#81c14b",
                    },
                  }} size="small" checked={props.isActive === index} />} 
                  label={check?.categoryName} sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#767676" } }} />
                </AccordionHeader>
                <AccordionBody style={{ padding: "0rem 0rem" }}>
                  <div className='d-flex flex-column align-items-start justify-content-start' style={{ paddingLeft: "2.25rem" }}>
                    {check?.subcategories?.length > 0  && check.subcategories.map((subcate, index) => (
                      <div className="MrktPlaceCheckbox" key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 20,
                                },
                                color: "#cbcbcb", 
                                '&.Mui-checked': {
                                  color: "#81c14b",
                                },
                              }}
                              size="small"
                              checked={props.checkedCheckboxes.includes(subcate.id)}
                              onChange={() => handleCheckboxChange(subcate.id)}
                            />
                          }
                          label={subcate?.name}
                          sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#767676" } }}
                        />
                      </div>
                    ))}
                  </div>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </AccordionBody>
    </Fragment>
  );
}

export default MarketCategoryAccordion;
