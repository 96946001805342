import { Radio } from '@mui/material';
import React, { Fragment } from 'react'
import {
    SocialMessageData,
    FollowData,
    ProfileSrchData,
    ProfileGenderData,
    ProfileEmailData,
    ProfileContactData,
    ProfileBirthDayData,
    ProfileBirthYearData,
    ProfileFollowData,
    ProfilePrivacyData,
    ProfileWebSrchData
} from './PrivacyArrayData'

const PrivacySetting = () => {
    const [selectedValue, setSelectedValue] = React.useState('');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);

    }
    const [followValue, setFollowValue] = React.useState('b');
    const handleFollow = (event) => {
        setFollowValue(event.target.value);

    }
    const [srchValue, setSrchValue] = React.useState('b');
    const handleSrch = (event) => {
        setSrchValue(event.target.value);

    }
    const [srchWebValue, setsrchWebValue] = React.useState('a');
    const handleSrchWeb = (event) => {
        setsrchWebValue(event.target.value);

    }

    const [genderValue, setGenderValue] = React.useState('b');
    const handleGender = (event) => {
        setGenderValue(event.target.value);

    }

    const [emailValue, setEmailValue] = React.useState('c');
    const handleEmail = (event) => {
        setEmailValue(event.target.value);
    }

    const [contactValue, setContactValue] = React.useState('b');
    const handleContact = (event) => {
        setContactValue(event.target.value);
    }

    const [birthtValue, setBirthValue] = React.useState('c');
    const handleBirth = (event) => {
        setBirthValue(event.target.value);
    }

    const [birthtYearValue, setBirthYearValue] = React.useState('a');
    const handleYear = (event) => {
        setBirthYearValue(event.target.value);
    }

    const [FollowerValue, setFollowerValue] = React.useState('b');
    const handleFollower = (event) => {
        setFollowerValue(event.target.value);
    }
    const [privacyValue, setPrivacyValue] = React.useState('a');
    const handlePrivacy = (event) => {
        setPrivacyValue(event.target.value);
    }
    //============ main_return_Function =================//
    return (
        <Fragment>
            <div className='SettingPrivacy_Main_section'>
                <div className='hide-setting-options'>
                    <h3>Privacy </h3>
                </div>
                {/*================== start_ social_Message_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can send you social message?</h3>
                    {SocialMessageData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={selectedValue === data.id}
                                        onChange={handleChange}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {/*================== End_ social_Message_Data_section ================== */}

                {/*================== start_ Follow_you_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can Follow you?</h3>
                    {FollowData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={followValue === data.id}
                                        onChange={handleFollow}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ Follow_you_Data_section ================== */}

                {/*================== start_Profile_srch_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>List your profile on search results.</h3>
                    {ProfileSrchData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={srchValue === data.id}
                                        onChange={handleSrch}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ Profile_srch_Data_section ================== */}

                {/*================== start_Profile_srch_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>List your profile in web search result.</h3>
                    {ProfileWebSrchData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={srchWebValue === data.id}
                                        onChange={handleSrchWeb}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ Profile_srch_Data_section ================== */}

                {/*================== start_Profile_Gender_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Hide gender in your Profile.</h3>
                    {ProfileGenderData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={genderValue === data.id}
                                        onChange={handleGender}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ Profile_Gender_Data_section ================== */}

                {/*================== start_EmailPrivacy_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can see your email ID?</h3>
                    {ProfileEmailData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={emailValue === data.id}
                                        onChange={handleEmail}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ EmailPrivacy_Data_section ================== */}

                {/*================== start_Contact_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can see your Contact Number?</h3>
                    {ProfileContactData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={contactValue === data.id}
                                        onChange={handleContact}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ Contact_Data_section ================== */}

                {/*================== start_BirthDay_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can see your Birth Date?</h3>
                    {ProfileBirthDayData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={birthtValue === data.id}
                                        onChange={handleBirth}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_ BirthDay_Data_section ================== */}

                {/*================== start_BirthYear_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can see your Birth Year?</h3>
                    {ProfileBirthYearData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={birthtYearValue === data.id}
                                        onChange={handleYear}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_BirthYear_Data_section ================== */}

                {/*================== start_Follower/Following_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Who can see your Followers and Following?</h3>
                    {ProfileFollowData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={FollowerValue === data.id}
                                        onChange={handleFollower}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_Follower/Following_Data_section ================== */}

                {/*================== start_AccountPrivacy_Data_section ================== */}
                <div className='SettingPrivacy_Card_Body'>
                    <h3 className='py-3'>Account privacy setting</h3>
                    {ProfilePrivacyData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                </div>
                                <div>
                                    <Radio checked={privacyValue === data.id}
                                        onChange={handlePrivacy}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*================== start_AccountPrivacy_Data_section ================== */}



            </div>
        </Fragment>
    )
}

export default PrivacySetting