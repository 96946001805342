import React from 'react'
import { Input, InputGroup, InputGroupText, Modal, ModalHeader } from 'reactstrap'
import { XCircle } from 'react-feather'
import img3 from '../../../NewComponents/IMG/roundedimg3.svg'
import img1 from '../../../NewComponents/IMG/roundedimg2.svg'
import '../../Story/Story.css'
import { Button } from 'react-bootstrap'

const UserList = (props) => {
    return (
        <>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.userListModalOpen} className='modal-dialog-centered'>
                    <ModalHeader>Select people</ModalHeader>
                    <div>
                        <InputGroup className='input-group-merge mb-2 p-3'>
                            <Input placeholder='search...' />
                            <InputGroupText>
                                <XCircle size={22} color='white' />
                            </InputGroupText>
                        </InputGroup>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex ml-2'>
                                <img src={img3} width={60} height={60} alt='peopleimg' className='p-2' />
                                <h5 className='mt-4'>Shreyansh singh</h5>
                            </div>
                            <p className='mb-3 mr-2'><Input type='checkbox'></Input></p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex ml-2'>
                                <img src={img1} width={60} height={60} alt='peopleimg' className='p-2' />
                                <h5 className='mt-4'>Archna Gautam</h5>
                            </div>
                            <p className='mb-3 mr-2'><Input type='checkbox'></Input></p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex ml-2'>
                                <img src={img3} width={60} height={60} alt='peopleimg' className='p-2' />
                                <h5 className='mt-4'>Aman mishra</h5>
                            </div>
                            <p className='mb-3 mr-2'><Input type='checkbox'></Input></p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex ml-2'>
                                <img src={img1} width={60} height={60} alt='peopleimg' className='p-2' />
                                <h5 className='mt-4'>Parul gupta</h5>
                            </div>
                            <p className='mb-3 mr-2'><Input type='checkbox'></Input></p>
                        </div>
                        <div className='d-flex justify-content-end p-3'>
                            <Button variant="outline-secondary" outline onClick={props.userListModalOpen}>Cancel</Button>&nbsp;&nbsp;
                            <Button variant="outline-primary" className='px-4' onClick={props.userListModalOpen}>Save</Button>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default UserList