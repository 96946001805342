import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { ArrowLeft, XCircle } from 'react-feather';
import { Col, Row } from 'reactstrap';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllTrendingHashtag } from "../../../Services/Actions/Common/trandingHashtagAction";

const Hashtag = ({ postType,activeTab, toggle, onSelectedTagsChange,postEditValue}) => {
  // console.log('in hashtag',postEditValue)
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [selectedList, setSelectedList] = useState([]);
  const [hashTagData, setHashTagData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query

  const handleCheck = (id, item) => {
    // console.log("selected item",item)
    const isSelected = selectedList.includes(item);

    if (isSelected) {
      setSelectedList(selectedList.filter(selectedItem => selectedItem !== item));
    } else {
      setSelectedList([...selectedList, item]);
    }
  };

  const dispatch = useDispatch();
  const { trandingHashtags } = useSelector((state) => state.TrendingHashtagData);

  useEffect(() => {
    dispatch(loadAllTrendingHashtag());
    setHashTagData(trandingHashtags);
    if(postEditValue && postEditValue.postHashTags){
      let arr=[];
      postEditValue.postHashTags.map((item)=>{
        arr.push(item.name);
      })

      // console.log("new arr",arr);
      setSelectedList(arr);
      onSelectedTagsChange(arr)
      // console.log("new selected editable list ",selectedList)
    }
  }, []);
// console.log(selectedList,"editable list")
  const handleDoneClick = () => {
    // Handle the selectedList, e.g., send it to the server or perform other action
     // Call the callback with the updated selected ids
     onSelectedTagsChange(selectedList);
     
     if(postType==="recomendation"){
      toggle(`/modal/${7.6}`);
    }else if(postType==="thought"){
      toggle(`/modal/${7.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==="event"){
      toggle(`/modal/${7.3}`);
    }else if(postType==="media"){
      toggle(`/modal/${0.1}`);
    }
  };
 
  // Filter hashTagData based on the searchQuery
  const filteredHashTagData = hashTagData.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Fragment>
      <div className='border-bottom w-100'>
        <div className={`mx-3 py-3  FellingActivity_main_blk `}>
          <div onClick={() =>{    
             if(postType==="recomendation"){
      toggle(`/modal/${7.6}`);
    }else if(postType==="thought"){
      toggle(`/modal/${7.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==="event"){
      toggle(`/modal/${7.3}`);
    }else if(postType==="media"){
      toggle(`/modal/${0.1}`);
    }
     ; onSelectedTagsChange([]); setSelectedList([])}} style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle'>
            <ArrowLeft />
          </div>
          <div>
            <h3>Trending # tags</h3>
          </div>
          <div></div>
        </div>
      </div>
      <div className='mx-3'>
        <div className="right-setting pb-3 pt-3 d-flex align-items-center">
          <div className="profile-search-blk  w-100 mr-4 ">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            <input
              type="text"
              className="form-control Feeling-control"
              name="search"
              placeholder="Search #tag..."
              data-bs-toggle="modal"
              data-bs-target="#peopleSearch"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)} // Update the searchQuery state
            />
          </div>
          <p className='Suggest_tag_btn' onClick={handleDoneClick}>Done</p>
        </div>
      </div>
      <div className='Feeling_scroll_box'>
        <div className="mx-3">
          <Row className="mb-2">
            <Col>
              <div className='Update_Tag_suggest_blk d-flex'>
                {selectedList.map((item, id) => (
                  <div className='Update_HashTag_blk' key={id}>
                    {item}&nbsp;<XCircle color='#808080' size={16} onClick={() => { handleCheck(id, item); }} />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            {filteredHashTagData.map((item, id) => (
              <Col sm={12} className="mb-1" key={item.id}>
                <div className='tag_ppl_main_blk_section ' onClick={() => { handleCheck(id, item.name); }}>
                  <div className='HashTag_ppl_main_blk '>
                    <h3>{item.name}</h3>
                    <p>{item.count}</p>
                  </div>
                  <Checkbox checked={selectedList.includes(item.name)} {...label} defaultChecked className='Check_Input_section' />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Hashtag;

