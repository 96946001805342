import { Fragment, useState } from 'react'
import { Radio } from '@mui/material'


import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Clock, Globe, Mail, MapPin, Phone, XCircle ,ChevronRight} from 'react-feather'
import HeadingPointModal from './HeadingPointModal'
import HeadingDespModal from './HeadingDespModal'
import   Timemodal2 from './timemodal2'
const Timemodal = (props) => {

    const [selectedValue, setSelectedValue] = useState('');
    const [isOpen5, setIsOpen5] = useState(false);

    const toggleOpenPoint3 = () => {
      setIsOpen5(!isOpen5);
    };

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };
    return (
        <Fragment>
            {
                props.isOpen !== false && (
                    <Modal isOpen={props.isOpen4} toggle={props.toggleOpenPoint2} className='modal-dialog-centered'>
                        <div

                            onClick={props.toggleOpenPoint2}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>

                        <div className='w-100 border-bottom'>
                        <div className=' mx-3 py-3  d-flex justify-content-between Heading_Invite_Section'>
                            <h4 >Update Biz Hours</h4>
                            <span style={{ cursor: "pointer" ,color:"#81C14B"}}  onClick={props.toggleOpenPoint2} >Done</span>
                        </div>
                    </div>
                      
                        <ModalBody>


                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <div className='me-auto'>
                                            <h4>Hide biz hours</h4>
                                        </div>
                                        <label className='' htmlFor="radio-buttonsOneId">
                                            <Radio

                                                value={'a'}
                                                name="radio-buttons"
                                                className='Radio_btn_check'
                                                color='primary'
                                                inputProps={{ 'aria-label': " A " }}
                                                onChange={handleRadioChange}
                                                checked={selectedValue === 'a'}
                                            />

                                        </label>
                                    </div>
                                    <p>Visitors won’t see biz timings on this page</p>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <div className='me-auto'>
                                            <h4>Always Open </h4>
                                        </div>
                                        <label className='' htmlFor="radio-buttonsOneId">
                                            <Radio

                                                value={'b'}
                                                name="radio-buttons"
                                                className='Radio_btn_check'
                                                id="radio-buttonsOneId"
                                                color='primary'
                                                inputProps={{ 'aria-label': 'B' }}
                                                checked={selectedValue === 'b'}
                                                onChange={handleRadioChange}
                                            />
                                        </label>
                                    </div>
                                    <p>Public Utility services eg. Parks, beaches, streets
                                    </p>

                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="">
                                    <div className='d-flex align-items-center'>
                                        <div className='me-auto'>
                                            <h4>Temporarily Closed</h4>
                                        </div>
                                        <label className='' htmlFor="radio-buttonsOneId">
                                            <Radio

                                                value={'c'}
                                                name="radio-buttons"
                                                className='Radio_btn_check'
                                                id="radio-buttonsOneId"
                                                color='primary'
                                                inputProps={{ 'aria-label': 'C' }}
                                                checked={selectedValue === 'c'}
                                                onChange={handleRadioChange}
                                            />
                                        </label>
                                    </div>
                                    <p>Indicate that your biz is closed for now but will open in the future
</p>

                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="" onClick={toggleOpenPoint3} style={{cursor:"pointer"}}>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-auto'>
                                            <h4>Standard Hours </h4>
                                        </div>
                                        <label className='' >
                                            {/* <Radio

                                                value={'b'}
                                                name="radio-buttons"
                                                className='Radio_btn_check'
                                                id="radio-buttonsOneId"
                                                color='primary'
                                                inputProps={{ 'aria-label': 'B' }}
                                                checked={selectedValue === 'b'}
                                                onChange={handleRadioChange}
                                            /> */}
                                            <ChevronRight  toggleTym={props.toggleTym} setTymOpen={props.setTymOpen} tymOpen={props.tymOpen}  style={{color:"81C14B"}}/>
                                        </label>
                                    </div>
                                    <p>e.g. Monday to Friday 9 AM - 5 PM</p>

                                </div>
                            </FormGroup>

                            
                        </ModalBody>
                    </Modal>
                )}

<Timemodal2 toggleOpenPoint3={toggleOpenPoint3} setIsOpen5={setIsOpen5} isOpen5={isOpen5}  toggleTym={props.toggleTym} setTymOpen={props.setTymOpen} tymOpen={props.tymOpen} />

        </Fragment>

      
    )
}

export default Timemodal