import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MessageCircle, MoreVertical, Music, Send, Share2 } from 'react-feather'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import like from '../../../NewComponents/IMG/like.png'
// import Shotzs from '../../../NewComponents/IMG/Shotzs.png'
// import ShotzModal from '../../Shotzs/shotz_components/ShotzModal'
// import Pollimg1 from '../../../NewComponents/IMG/roundedimg3.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
// import { ShotzCarouselComponents } from '../../Shotzs/shotz_components/ShotzCarouselComponents'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import MediaModal from '../../profile-components/MediaModal'
import ShareToModal from '../ShareToModal'
import { trendingShotzData } from '../DummyArray'
import ReportModal from './ModalGrid/ReportModal'
import { useUserAuth } from '../../../Context/userAuthContext'
import { ShotzCarouselComponents } from '../../Shotzs/shotz_components/ShotzCarouselComponents'
import LikeIconsvg from '../../../NewComponents/IMG/LikeIconsvg.svg'
import { NavLink } from 'react-router-dom'


const ShotzGridCompoent = () => {

    {/*------------------------------------------------------------------------- Shotz carousel state called from context---------------------------------------------------------------------- */ }
    const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();

    // const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }

    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }


    {/*------------------------------------------------------------------------- Main return function---------------------------------------------------------------------- */ }
    return (
        <div className='p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
            <Row className=''>

                {/*------------------------------------------------------------------------- Dynamic card rendering for trending shotz---------------------------------------------------------------------- */}
                {
                    trendingShotzData.map((data, index) => {
                        return (
                            <Col xl='3' lg='4' md='6' xs='12' className='p-1' key={index} >
                                <div className='border roundedThisBorder on-hover-bg'>
                                    <div className='d-flex p-2'>
                                        <NavLink to='/MyProfile'><Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                            Top #{data.trendingNumber}
                                        </Badge></NavLink>
                                        <NavLink to='/MyProfile'>
                                            <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                                Trending #{data.topsNumber}
                                            </Badge>
                                        </NavLink>
                                    </div>

                                    <div className='d-flex align-items-center ml-1'>
                                        <div className='d-flex align-items-center '>
                                            <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                            <div className='ml-2'>
                                                <NavLink to='/MyProfile' style={{ color: 'black' }} ><h4 className='user-profile'>{data.name}</h4></NavLink>
                                                <small className='text-muted light-for-date'>{data.dateandTime}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="media-images-blk shotz-album-block  align-items-center mt-1 p-2">
                                        <div className="main-shotz-blk-list" style={{ cursor: 'pointer' }} >
                                            <div className='image-with-gradient shotz-card-hover' onClick={toggleShotzCarouselModal} >
                                                <img className="gallery-img img-gradient rounded" src={data.gridImg} alt="Shotz" />
                                            </div>
                                            <div className="text-gallery d-flex justify-content-center" >
                                                <div className="top-gallery-cont align-items-center">
                                                    <h5 className='text-white'><Eye color='#FFFFFF' size={20} /> {numFormatter(data.num)}</h5>
                                                    <div className='gallery-img-sublink-this-bizpage-shotz' >
                                                        <UncontrolledButtonDropdown className=''>
                                                            <DropdownToggle color='flat' >
                                                                <MoreVertical color='white' className='' size={20} />
                                                            </DropdownToggle>
                                                            <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific'>
                                                                <DropdownItem onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share shotz</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem > <NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View creator's profile </NavLink></DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                                <div className="center-gallery-cont">
                                                    <div role='button'>
                                                        <img src="assets/images/shotz-icon.png" className='shotzModel' alt='ShotzIcon' />
                                                    </div>
                                                </div>
                                                <div className="Music-gallery-cont mx-2 py-3 px-2">
                                                    {/* <p className='shotz-text'><Music size={20} /></p>&nbsp;&nbsp; */}
                                                    <h5 className='text-white mr-2 marquee'> {data.icon1} {data.songs}</h5>
                                                </div>
                                                <div className="bottom-gallery-cont align-items-center">
                                                    <div className='shotz-text'>
                                                        {liked ?
                                                            <img src={LikeBlack} width={18} height={18} alt='likeback' onClick={handleLike} />
                                                            :
                                                            <img src={LikeIconsvg} width={18} height={18} alt='likeback' onClick={handleLike} />
                                                        }
                                                        {numFormatter(data.num1)}
                                                    </div>
                                                    <div className='shotz-text'><MessageCircle size={18} /> {numFormatter(data.num2)}</div>
                                                    <div className='shotz-text' onClick={toggleForward}><Send size={18} />  {numFormatter(data.num3)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>

            {/* As per current module we dont nee this */}

            {/* <Row> */}
            {/* ------------- Pagination ------------- */}
            {/* <Col xl='12' className='d-flex justify-content-center py-4'>
                    <Stack spacing={2}>
                        <Pagination
                            count={10}
                            color='primary'
                            size="large" />
                    </Stack>
                </Col> */}
            {/* </Row> */}


            {/*------------------------------------------------------------------------- Modal component start---------------------------------------------------------------------- */}
            <MediaModal />
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />

            {/*----------------- Shotz Carousel Modal ----------------- */}
            {/* {
                shotzCarouselModal !== false &&
                <Modal isOpen={shotzCarouselModal} toggle={toggleShotzCarouselModal} className='modal-dialog-centered modal-xl'>
                    <XCircle size={30} className='close-icon' onClick={toggleShotzCarouselModal} />
                    <div className='shotz-border'>
                        <ShotzModal />
                    </div>
                    <div className='shotz-border'>
                        <ShotzModal />
                    </div>
                    <div className='shotz-border'>
                        <ShotzModal />
                    </div>
                    <div>
                        <ShotzModal />
                    </div>
                </Modal>
            } */}

            {/* ------------- Start Shotz Carousel Modal ------------- */}
            <ShotzCarouselComponents
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            />
            {/* ------------- End Shotz Carousel Modal ------------- */}

        </div>
    )
}

export default ShotzGridCompoent