import React, { Fragment, useState } from 'react'
import { Button, CustomInput, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { RolesAssignPageData } from '../SettingComponents/SettingArray'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { setAssignRoleAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormLabel, Radio } from '@mui/material';


const UpdateAssignModal = (props) => {

    const assignRoleData = useSelector((state) => { return state.setAssignRoleData });
    const { loading: loadingAssignRole, error: errorAssignRole, result: resultAssignRole } = assignRoleData


    let dispatch = useDispatch();
    const { id } = useParams();
    let navigate = useNavigate();

    const [role, setRole] = useState('');
    const [add, setAdd] = useState(0);
    const [update, setUpdate] = useState(0);
    const [remove, setRemove] = useState(0);

    console.log('role', role)
    console.log('role', add)
    console.log('role', update)
    console.log('role', remove)

    const assignRoleToDb = () => {
        dispatch(setAssignRoleAction(id, props.userById, role, add, update, remove, 1, 0))
        navigate(`/BizDetail/${id}`);
    };

    const applyRoleAdmin = () => {
        setRole('admin')
        setAdd('')
        setUpdate('')
        setRemove('')
    };

    const applyRoleManager = () => {
        setRole('manager')
        setAdd('')
        setUpdate('')
        setRemove('')
    };

    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                    <ModalHeader className="Biz_Rating_SECTION" toggle={props.updateOpen}>
                        <h3>Assign Biz Page roles</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className=''>
                            <div className="bizPage_pageRole-Modalblk" >
                                <div className='d-flex align-items-center ' >
                                    <img src={props.photoById} className="img-fluid rounded-circle assignimg" alt='biz-img' width={60} height={60} />
                                    <div className='ml-2 BizProfile_section' >
                                        <h4>{props.fullNameById}</h4>
                                        {/* <p>{props.status}</p>  */}
                                        <p>Joined on 23 Mar 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <Accordion open={props.AccordionOpen} toggle={props.AccordionUpdate}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">
                                        <div className='Assign_Check_Section '>
                                            <Radio
                                                name="radio1"
                                                type="checkbox"
                                                className="Assign_Check"
                                                onChange={applyRoleAdmin}
                                            />
                                            {' '}

                                        </div>
                                        <div className='Assign_Sub_Section1'>
                                            <h4>Assign as Admin</h4>
                                            <p>Admin can approve or remove posts, followers and messages </p>
                                        </div>

                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>
                                            <p className='d-flex align-items-center '>
                                                <CustomInput
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="1"
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => setAdd(e.target.checked ? 1 : 0)}
                                                />&nbsp; Add
                                            </p>
                                            <p className='d-flex align-items-center '>
                                                <CustomInput
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="2"
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => setUpdate(e.target.checked ? 1 : 0)}
                                                />&nbsp; Edit
                                            </p>
                                            <p className='d-flex align-items-center '>
                                                <CustomInput
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="3"
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => setRemove(e.target.checked ? 1 : 0)}
                                                />&nbsp; Delete
                                            </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>

                            </Accordion>
                            {/* <Accordion open={props.AccordionOpen} toggle={props.AccordionUpdate}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">
                                        <div className='Assign_Check_Section'>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Assign_Check"
                                                onChange={applyRoleManager}
                                            />
                                            {' '}
                                            <div className='Assign_Sub_Section'>
                                                <h4>Assign as Manager</h4>
                                                <p>Manager can publish/unpublish posts and comments on the group.</p>
                                            </div>
                                        </div>

                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>
                                        <p className='d-flex align-items-center '>
                                            <CustomInput
                                            type="checkbox"
                                            className='customcheckboxForFilter'
                                            id="4"
                                            style={{ cursor: "pointer" }}
                                            onChange={(e) => setAdd(e.target.checked ? 1 : 0)}
                                            />&nbsp; Add
                                        </p>
                                        <p className='d-flex align-items-center '>
                                            <CustomInput
                                            type="checkbox"
                                            className='customcheckboxForFilter'
                                            id="5"
                                            style={{ cursor: "pointer" }}
                                            onChange={(e) => setUpdate(e.target.checked ? 1 : 0)}
                                            />&nbsp; Update
                                        </p>
                                        <p className='d-flex align-items-center '>
                                            <CustomInput
                                            type="checkbox"
                                            className='customcheckboxForFilter'
                                            id="6"
                                            style={{ cursor: "pointer" }}
                                            onChange={(e) => setRemove(e.target.checked ? 1 : 0)}
                                            />&nbsp; Delete
                                        </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>

                            </Accordion> */}
                        </div>

                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center'>
                        <Button color='primary' className="w-25" onClick={assignRoleToDb}>Save</Button>
                    </ModalFooter>
                </Modal>
            }

        </Fragment>
    )
}

export default UpdateAssignModal