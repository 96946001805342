import * as types from "../../Constants/Events/index";

// const initialState = {
//     allUserGroups: [],
//     loading: true,
//     success: true,
//   };

//   export const getUserEventsReducer = (state = initialState, action) => {

//     switch (action.type) {

//         case types.GET_EVENTS_BY_USER:
//             return {
//                 ...state,
//                 loading: true,
//             };
//         case types.GET_EVENTS_BY_USER_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 success: true,
//                 result: action.payload,
//             };
//         case types.GET_EVENTS_BY_USER_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload,
//             };
//         default:
//             return state;
//     }
// };

const initialState = {
    userEvents: [],
    loading: true,
    
  };

    export const getUserEventsReducer = (state = initialState, action) => {
        switch (action.type) {
            case types.GET_EVENTS_BY_USER:
                return {
                    ...state,
                    userEvents:action.payload,
                    loading: false
                };
                default:
                    return state
                }
        };
    

        // export const getUserCategoriesEventReducer = (state = initialState, action) => {
        //     switch (action.type) {
        //         case types.GET_EVENTS_BY_CATEGORIES:
        //             return {
        //                 ...state,
        //                 userEvents: action.payload,
        //                 loading: false
        //             };
        //             default:
        //                 return state
        //             }
        //     };
        
