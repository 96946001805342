import React, { useRef, useState } from 'react'
import {
    Nav,
    NavItem,
    NavLink,
    Card,
    DropdownToggle,
    DropdownMenu,
    Button,
    UncontrolledButtonDropdown,
    InputGroup,
    InputGroupText,
    Input,
    CardText,
    DropdownItem,
    Col,
    Row,
    TabContent,
    TabPane
} from 'reactstrap'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material'
import { ChevronDown, ChevronUp, Filter, Grid, List, Minus, Plus, PlusCircle, Upload } from 'react-feather';
import TrackLocation from '../profile-components/Img/Track.png'
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import { useNavigate } from 'react-router-dom';
import MobileViewSLider from './Sub-components/MobileViewSLider';
import { useContext } from 'react';
import { useUserAuth } from '../../Context/userAuthContext';
import TopPodacastCard from './Sub-components/TopPodacastCard';
import PodcastManage from './PodcastManage';
import PodcastNavbar from './Sub-components/PodcastNavbar';
import SinglePodcast from './Sub-components/pages/SinglePodcast';
import PodcastPurched from './PodcastPurched';
import TopPodccastTv from './TopPodccastTv';
import CommonSeeallpage from './Sub-components/pages/CommonSeeallpage';
import TvPurchased from './Sub-components/pages/TvPurchased';

const PodcastHeader = ({ tabData, Slidetoggle }) => {
    const navigate = useNavigate()
    const { listView, setListView } = useUserAuth()
    // const listView = ContextData.listView
    // const setListView = ContextData.setListView
    /*  ----------------------State and Function--------------------  */
    const [Free, setFree] = useState(false);
    const [Paid, setPaid] = useState(false);
    const [All, setAll] = useState(false);
    const [data, setdata] = useState(true)
    const [Allcategory, setAllcategory] = useState(false);
    const [showcategory, setshowcategory] = useState(true);
    const [showAll, setShowAll] = useState(true)
    const [cardId, setCardId] = useState(1)
    const handleShowAll = (id) => {
        if (id === '1') {
            setShowAll(true)
        }
        else {
            setShowAll(false)
        }
    }
    const [active, setActive] = useState('1')
    const toggle = tab => {
        setActive(tab)
    }
    const handleFree = (event) => {
        setFree(event.target.checked);
    };
    const handlePaid = (event) => {
        setPaid(event.target.checked);
    };
    const [checkboxes, setCheckboxes] = useState([
        { id: 1, value: false, label: 'Single Podcast' },
        { id: 2, value: false, label: 'Series Podcast ' },
        { id: 3, value: false, label: 'Live Podcast' }
    ]);
    const [categoryboxes, setcategoryboxes] = useState([
        { id: 4, value: false, label: 'TV & Film' },
        { id: 5, value: false, label: 'Health & Fitness' },
        { id: 6, value: false, label: 'Socity & Culture' },
        { id: 7, value: false, label: 'Comedy' }
    ]);
    /*  ----------------------CheckBox handel Function : 1 --------------------  */
    const handleCheckboxChange = (id) => {
        setCheckboxes(
            checkboxes.map((checkbox) => {
                if (checkbox.id === id) {
                    return { ...checkbox, value: !checkbox.value };
                }
                return checkbox;
            })
        );
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.id === id
                ? { ...checkbox, value: !checkbox.value }
                : checkbox
        );
        setCheckboxes(updatedCheckboxes);
        const allChecked = updatedCheckboxes.every(checkbox => checkbox.value);
        setSelectAll(allChecked);
    };
    /*  ----------------------CheckBox handel Function : 2 --------------------  */
    const handlecategoryboxes = (id) => {
        setcategoryboxes(
            categoryboxes.map((checkbox) => {
                if (checkbox.id === id) {
                    return { ...checkbox, value: !checkbox.value };
                }
                return checkbox;
            })
        );
    };
    /*  ----------------------CheckBox handel select All Function : 1 --------------------  */
    const handleSelectAllChange = (event) => {
        // setAll(event.target.checked);
        // setCheckboxes(
        //     checkboxes.map((checkbox) => {
        //         return { ...checkbox, value: event.target.checked };
        //     })
        // );
        setSelectAll(!selectAll);
        const updatedCheckboxes = checkboxes.map(checkbox => ({
            ...checkbox,
            value: !selectAll
        }));
        setCheckboxes(updatedCheckboxes);
        setSelectAll(!selectAll);
    };
    /*  ----------------------CheckBox handel select All Function : 2 --------------------  */

    const handlecategorySelectAll = (event) => {
        setAllcategory(event.target.checked);
        setcategoryboxes(
            categoryboxes.map((checkbox) => {
                return { ...checkbox, value: event.target.checked };
            })
        );
    };
    const [within, setWithin] = useState(20);
    const [text, settext] = useState('');
    const [text1, settext1] = useState('');
    const [text2, settext2] = useState('');
    const [price, setPrice] = useState(70);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [allCategory, setAllCategory] = useState(false);
    const [categoryBoxes, setCategoryBoxes] = useState([
        { id: 4, value: false, label: 'TV & Film' },
        { id: 5, value: false, label: 'Health & Fitness' },
        { id: 6, value: false, label: 'Socity & Culture' },
        { id: 7, value: false, label: 'Comedy' }

    ]);
    const handleCategorySelectAll = () => {
        setAllCategory(!allCategory);
        const updatedBoxes = categoryBoxes.map(checkbox => ({ ...checkbox, value: !allCategory }));
        setCategoryBoxes(updatedBoxes);
        
    };
    const [showcategory1, setShowCategory] = useState(false);

    const handleCategoryBoxes = (id) => {
        const updatedBoxes = categoryBoxes.map(checkbox =>
            checkbox.id === id ? { ...checkbox, value: !checkbox.value } : checkbox
        );
        setCategoryBoxes(updatedBoxes);
        // setAllCategory(false);
        const updatedCheckboxes = checkboxes.map(checkbox =>
            checkbox.id === id
                ? { ...checkbox, value: !checkbox.value }
                : checkbox
        );
        setCategoryBoxes(updatedBoxes);
        const allChecked = updatedBoxes.every(checkbox => checkbox.value);
        setAllCategory(allChecked);
    };
    const [selectAll, setSelectAll] = useState(false);
    const inputRef = useRef(null);
    const handleClear = () => {
        setLocation(0);
        setFree(false);
        setPaid(false);
        setAll(false);
        settext('')
        settext1('')
        settext2('')
        inputRef.current.value = '';
        setSelectAll(false);
        const updatedCheckboxes = checkboxes.map(checkbox => ({
            ...checkbox,
            value: false
        }));
        setCheckboxes(updatedCheckboxes);
        setCategoryBoxes(categoryBoxes.map(checkbox => ({ ...checkbox, value: false })));
        setAllCategory(false);

    };
    /*  ----------------------  MUI Slider style management --------------------  */
    const PrettoSlider = styled(Slider)({
        color: '#81C14B',
        height: 4,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    });

    /*  ----------------------  Podecast Dyanmic Tabs --------------------  */

    const PodcastNavData = [
        {
            id: "1",
            NavName: "All (4)"
        },
        {
            id: "2",
            NavName: "TV & Film (1)"
        },
        {
            id: "3",
            NavName: "Health & Fitness(1)"
        },
        {
            id: "4",
            NavName: "Socity & Culture (1)"
        },
        {
            id: "5",
            NavName: "Comedy (1)"
        }

    ]
    const [isCardOpen, setIsCardOpen] = useState(false);

    const handleButtonClick = () => {
        setIsCardOpen(!isCardOpen); // Toggle the state of isCardOpen
    };
    const [location, setLocation] = useState(20); // Initial location value

    const handleSliderChange = (event, newValue) => {
        setLocation(newValue);
    };

    const incrementLocation = () => {
        setLocation(prevLocation => prevLocation + 1);
    };

    const decrementLocation = () => {
        setLocation(prevLocation => Math.max(0, prevLocation - 1));
    };
    return (
        <React.Fragment>
            {/*  ---------------------- Main Header --------------------  */}
            <div style={{ color: '#4D4D4D', fontWeight: 500 }} className='p-2 mypodehead' >
                <h2><strong>My Podcast</strong></h2>
            </div>
            {/*  ---------------------- Main Navbar --------------------  */}
            <Card className='border-0 pt-3 d-flex podwholenav' style={{ position: 'relative' }}>
                <div className='list-grid-toggle tooglespace'>
                    <Nav tabs className='w-100 podnav'>
                        <NavItem className='ml-2'>
                            <NavLink
                                className={active === '1' ? 'text-primary' : 'text-dark podpad'}
                                active={active === '1'}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Explore
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={active === '2' ? 'text-primary' : 'text-dark podpad'}
                                active={active === '2'}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Manage
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={active === '3' ? 'text-primary' : 'text-dark podpad'}
                                active={active === '3'}
                                onClick={() => {
                                    toggle('3');
                                }}
                            >
                                Purchased
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <div className='d-flex align-items-center px-2 rem-mob-view' style={{ position: 'absolute', top: '15%', right: '0px' }}>
                        {(active === '1' || active === '3') && (
                            <>
                                <Button color='primary mr-1' outline className={listView ? 'GridButtonView' : ''} onClick={() => setListView(true)}>
                                    <Grid size={16} />
                                </Button>
                                <Button color='primary' outline className={listView ? '' : 'GridButtonView'} onClick={() => setListView(false)}>
                                    <List size={16} />
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Card>
            <div>
                <div className='py-2 d-xl-none'>
                    <div>
                        <MobileViewSLider active={tabData} toggleactive={Slidetoggle} />
                    </div>
                </div>
                {/* <div className="profile-menu pod-nav">
                    <div className='d-flex justify-content-between w-100 align-items-center'>
                        <ul className='text-white rem-mob-view'>
                            {PodcastNavData.map((item, index) => {
                                return (
                                    <>
                                        <li key={index} >
                                            <NavLink active={tabData === item.id} onClick={() => {
                                                Slidetoggle(item.id);
                                                handleShowAll(item.id);
                                            }} to="#">
                                                <h6>{item.NavName}</h6>
                                            </NavLink>
                                        </li>

                                    </>
                                )
                            })}
                            <li>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='flat m-0 p-0'>
                                        <NavLink to="#">
                                            <h6>More..</h6>
                                        </NavLink>
                                    </DropdownToggle>
                                    <DropdownMenu style={{ minWidth: '12rem', zIndex: '3' }} className='p-0 m-0 categories-scroll rem-bg existingDrop'>
                                        <DropdownItem tag='a' className='rem-bg'>Religon & Sprituality</DropdownItem>
                                        <DropdownItem tag='a' className='rem-bg'>News & Politics</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </li>
                        </ul>
                        <div className='rem-mob-view filcret'>
                  
                            <UncontrolledButtonDropdown className='mr-3'>
                                <DropdownToggle outline color='primary' >
                                    <Filter size={16} />
                                    <span className='align-middle'>Filter</span>
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '100', minWidth: '20rem', left: '-7rem' }} >
                                    <div className='p-2 scrollForGoliveFilter'>
                                        <div className='d-flex justify-content-between align-items-center p-2'>
                                            <h4>Podcast Filter</h4>
                                            <h6 style={{ cursor: 'pointer' }} ><u>Clear</u></h6>
                                        </div>
                                        <div className='p-2'>
                                            <InputGroup>
                                                <Input type="search" placeholder="Select Location" />
                                                <InputGroupText>
                                                    <img width={25} height={23} src={TrackLocation} />
                                                </InputGroupText>
                                            </InputGroup>
                                        </div>
                                        <div className='p-2'>
                                            <div className='d-flex justify-content-around align-items-center'>
                                                <h4>Within (km)</h4>
                                                <h5>20 km</h5>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='col-sm-8'>
                                                    <PrettoSlider
                                                        size="small"
                                                        defaultValue={70}
                                                        aria-label="Small"
                                                        valueLabelDisplay="auto"
                                                    />
                                                </div>
                                                <div className='col-sm-4'>
                                                    <Button className='btn-icon p-0 m-0' color='primary'>
                                                        <Plus />
                                                    </Button>
                                                    <Button className='btn-icon p-0 ml-1' color='primary'>
                                                        <Minus />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='p-2' >
                                                <h4>Price</h4>
                                            </div>
                                            <div>
                                                <div className='mx-2 px-2' >
                                                    <PrettoSlider
                                                        size="small"
                                                        defaultValue={70}
                                                        aria-label="Small"
                                                        valueLabelDisplay="auto"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <InputGroup>
                                                    <InputGroupText>
                                                        Min
                                                    </InputGroupText>
                                                    <Input className='w-50' />
                                                </InputGroup>
                                                <CardText className='mx-1'>to</CardText>
                                                <InputGroup>
                                                    <InputGroupText>
                                                        Max
                                                    </InputGroupText>
                                                    <Input className='w-50' />
                                                </InputGroup>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <div className='d-flex align-item-center'>
                                                <Checkbox
                                                    checked={Free}
                                                    onChange={handleFree}
                                                    className='Check_Input_section'
                                                />
                                                <label className='pt-2'>Free</label>
                                                <Checkbox
                                                    checked={Paid}
                                                    onChange={handlePaid}
                                                    style={{ marginLeft: "10px" }}
                                                    className='Check_Input_section'
                                                />
                                                <label className='pt-2'> Paid</label>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <div>
                                                <h3>Podcast Type</h3>
                                            </div>
                                            <div>
                                                <div className='ml-3'>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Checkbox
                                                            checked={All}
                                                            onChange={handleSelectAllChange}
                                                            className='Check_Input_section'
                                                        />
                                                        <label className='pt-2 pl-1'>All</label>
                                                    </div>
                                                    {checkboxes.map((checkbox) => (
                                                        <div key={checkbox.id} className='d-flex align-item-center'>
                                                            <Checkbox
                                                                checked={checkbox.value}
                                                                onChange={() => handleCheckboxChange(checkbox.id)}
                                                                className='Check_Input_section'
                                                            />
                                                            <label className='pt-2 pl-1'>{checkbox.label}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <div onClick={() => setshowcategory(!showcategory)} className='d-flex align-item-center justify-content-between'>
                                                <h3>Category</h3>
                                                {showcategory ? <ChevronDown /> : <ChevronUp />}
                                            </div>
                                            {showcategory ?
                                                <div className='ml-3'>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Checkbox
                                                            checked={Allcategory}
                                                            onChange={handlecategorySelectAll}
                                                            className='Check_Input_section'
                                                        />
                                                        <label className='pt-2 pl-1'>All categories</label>
                                                    </div>
                                                    {categoryboxes.map((checkbox) => (
                                                        <div key={checkbox.id} className='d-flex align-item-center'>
                                                            <Checkbox
                                                                checked={checkbox.value}
                                                                onChange={() => handlecategoryboxes(checkbox.id)}
                                                                className='Check_Input_section'
                                                            />
                                                            <label className='pt-2 pl-1'>{checkbox.label}</label>
                                                        </div>
                                                    ))}
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            <UncontrolledButtonDropdown className='mr-1 margin-tab'>
                                <DropdownToggle outline color='primary'>
                                    <Plus size={14} />
                                    <span className='align-middle'>Create New</span>
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '100', minWidth: '18rem', color: '#4D4D4D', left: "-163px" }}>
                                    <DropdownItem onClick={() => navigate('/Podcast/StartLivePodcast')}><img width={20} src={LiveGif} alt="LiveGif1" /> Start Live Podcast</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={() => navigate('/Podcast/UploadRecordedPodacast')}><Upload color='#4D4D4D' size={20} /> Upload recorded podcast </DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={() => navigate('/Podcast/NewPodcastSeries')}><PlusCircle color='#4D4D4D' size={20} /> Create new Podcast Series</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </div> */}
            </div>
            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>
                    <div>
                        <div className="profile-menu pod-nav">
                            <div className='d-flex justify-content-between w-100 align-items-center'>
                                <ul className='text-white rem-mob-view'>
                                    {PodcastNavData.map((item, index) => {
                                        return (
                                            <>
                                                <li key={index} onClick={() => { setCardId(index + 1) }} >
                                                    <NavLink active={tabData === item.id} onClick={() => {
                                                        Slidetoggle(item.id);
                                                        handleShowAll(item.id);
                                                    }} to="#">
                                                        <h6>{item.NavName}</h6>
                                                    </NavLink>
                                                </li>

                                            </>
                                        )
                                    })}
                                    <li>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color='flat m-0 p-0'>
                                                <NavLink to="#">
                                                    <h6>More..</h6>
                                                </NavLink>
                                            </DropdownToggle>
                                            <DropdownMenu style={{ minWidth: '12rem', zIndex: '3' }} className='p-0 m-0 categories-scroll rem-bg existingDrop'>
                                                <DropdownItem tag='a' className='rem-bg'>Religon & Sprituality</DropdownItem>
                                                <DropdownItem tag='a' className='rem-bg'>News & Politics</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </li>
                                </ul>
                                <div className='rem-mob-view filcret'>

                                    <UncontrolledButtonDropdown className='mr-3'>
                                        <DropdownToggle outline color='primary' >
                                            <Filter size={16} />
                                            <span className='align-middle'>Filter</span>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ minWidth: '20rem', left: '-7rem',height:'26rem' }} >
                                            <div className='p-2 scrollForGoliveFilters'>
                                                <div className='d-flex justify-content-between align-items-center p-2'>
                                                    <h4>Podcast Filter</h4>
                                                    <h6 style={{ cursor: 'pointer' }} onClick={handleClear}><u>Clear</u></h6>
                                                </div>
                                                <div className='p-2'>
                                                    <InputGroup>
                                                        <Input
                                                            type="search"
                                                            placeholder="Select Location"
                                                            value={text}
                                                            onChange={(e) => settext(e.target.value)}
                                                            innerRef={inputRef}
                                                        />
                                                        <InputGroupText>
                                                            <img width={25} height={23} src={TrackLocation} alt='' />
                                                        </InputGroupText>
                                                    </InputGroup>
                                                </div>
                                                <div className='p-2'>
                                                    <div className='d-flex justify-content-around align-items-center'>
                                                        <h4 style={{ marginLeft: '-34px' }}>Within (km)</h4>
                                                        <h5>{location} km</h5>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='col-sm-8'>
                                                            <PrettoSlider
                                                                size="small"
                                                                defaultValue={70}
                                                                aria-label="discrete-slider"
                                                                valueLabelDisplay="auto"
                                                                value={location}
                                                                onChange={handleSliderChange}
                                                                step={1}
                                                                min={0}
                                                                max={100}
                                                            />

                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <Button className='btn-icon p-0 m-0' color='primary' onClick={incrementLocation}>
                                                                <Plus />
                                                            </Button>
                                                            <Button className='btn-icon p-0 ml-1' color='primary' onClick={decrementLocation}>
                                                                <Minus />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='p-2' >
                                                        <h4>Price</h4>
                                                    </div>
                                                    <div>
                                                        <div className='mx-2 px-2' >
                                                            <PrettoSlider
                                                                size="small"
                                                                defaultValue={70}
                                                                aria-label="Small"
                                                                valueLabelDisplay="auto"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <InputGroup>
                                                            <InputGroupText>
                                                                Min
                                                            </InputGroupText>
                                                            <Input className='w-50' value={text1}
                                                                onChange={(e) => settext1(e.target.value)} />
                                                        </InputGroup>
                                                        <CardText className='mx-1'>to</CardText>
                                                        <InputGroup>
                                                            <InputGroupText>
                                                                Max
                                                            </InputGroupText>
                                                            <Input className='w-50' value={text2}
                                                                onChange={(e) => settext2(e.target.value)} />
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className='d-flex align-item-center'>

                                                        <Checkbox
                                                            checked={Free}
                                                            onChange={handleFree}
                                                            className='Check_Input_section'
                                                        />
                                                        <label className='pt-2'>Free</label>
                                                        <Checkbox
                                                            checked={Paid}
                                                            onChange={handlePaid}
                                                            style={{ marginLeft: "10px" }}
                                                            className='Check_Input_section'
                                                        />
                                                        <label className='pt-2'> Paid</label>
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    <div>
                                                        <h3>Podcast Type</h3>
                                                    </div>
                                                    <div>
                                                        <div className='ml-3'>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                {/* <input
                                                                    type="checkbox"
                                                                    checked={selectAll}
                                                                    onChange={handleSelectAllChange}
                                                                    className='Check_Input_section'
                                                                /> */}
                                                                <Checkbox
                                                                    type="checkbox"
                                                                    checked={selectAll}
                                                                    onChange={handleSelectAllChange}
                                                                    className='Check_Input_section'
                                                                />
                                                                <label className='pt-2 pl-1'>All</label>
                                                            </div>
                                                            {checkboxes.map((checkbox) => (
                                                                <div key={checkbox.id} className='d-flex align-item-center'>
                                                                    {/* <input
                                                                        type="checkbox"
                                                                        checked={checkbox.value}
                                                                        onChange={() => handleCheckboxChange(checkbox.id)}
                                                                        className='Check_Input_section'
                                                                    /> */}
                                                                    <Checkbox
                                                                        type="checkbox"
                                                                        checked={checkbox.value}
                                                                        onChange={() => handleCheckboxChange(checkbox.id)}
                                                                        className='Check_Input_section'
                                                                    />
                                                                    <label className='pt-2 pl-1'>{checkbox.label}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='p-2'>
                                                    <div onClick={() => setShowCategory(!showcategory)} className='d-flex align-item-center justify-content-between'>
                                                        <h3>Category</h3>
                                                        {showcategory ? <ChevronDown /> : <ChevronUp />}
                                                    </div>
                                                    {showcategory &&
                                                        <div className='ml-3'>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Checkbox
                                                                    checked={allCategory}
                                                                    onChange={handleCategorySelectAll}
                                                                    className='Check_Input_section'
                                                                />
                                                                <label className='pt-2 pl-1'>All categories</label>
                                                            </div>
                                                            {categoryBoxes.map((checkbox) => (
                                                                <div key={checkbox.id} className='d-flex align-item-center'>
                                                                    <Checkbox
                                                                        checked={checkbox.value}
                                                                        onChange={() => handleCategoryBoxes(checkbox.id)}
                                                                        className='Check_Input_section'
                                                                    />
                                                                    <label className='pt-2 pl-1'>{checkbox.label}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    <UncontrolledButtonDropdown className='mr-1 margin-tab'>
                                        <DropdownToggle outline color='primary'>
                                            <Plus size={14} />
                                            <span className='align-middle'>Create New</span>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ minWidth: '18rem', color: '#4D4D4D', left: "-163px" }}>
                                            <DropdownItem onClick={() => navigate('/Podcast/StartLivePodcast')}><img width={20} src={LiveGif} alt="LiveGif1" /> Start Live Podcast</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem onClick={() => navigate('/Podcast/UploadRecordedPodacast')}><Upload color='#4D4D4D' size={20} /> Upload recorded podcast </DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem onClick={() => navigate('/Podcast/NewPodcastSeries')}><PlusCircle color='#4D4D4D' size={20} /> Create new Podcast Series</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </div>
                        </div>
                        {cardId === 1 && <div>
                            <div>
                                <TopPodacastCard Tittle='Top Podcast' />
                            </div>
                            <div>
                                <TopPodacastCard Tittle='TV & Film' />
                            </div>
                            <div>
                                <TopPodacastCard Tittle='Health & Fitness' />
                            </div>
                            <div>
                                <TopPodacastCard Tittle='Socity & Culture ' />
                            </div>
                        </div>}
                        {cardId === 2 && <div>
                            <CommonSeeallpage tittle={'TV & Film'} />
                        </div>}
                        {cardId === 3 && <div>
                            <CommonSeeallpage tittle={'Health & Fitness'} />
                        </div>}
                        {cardId === 4 && <div>
                            <CommonSeeallpage tittle={'Socity & Culture '} />
                        </div>}
                        {cardId === 5 && <div>
                            <CommonSeeallpage tittle={'Comedy'} />
                        </div>}
                    </div>
                </TabPane>
                <TabPane tabId='2'>
                    <div>
                        <PodcastNavbar Tittle='Top Podcast' setIsCardOpen={setIsCardOpen} isCardOpen={isCardOpen} handleButtonClick={handleButtonClick} />
                    </div>
                    {!isCardOpen && <PodcastManage setIsCardOpen={setIsCardOpen} isCardOpen={isCardOpen} handleButtonClick={handleButtonClick} />}
                </TabPane>
                <TabPane tabId='3'>
                    <div className="profile-menu pod-nav">
                        <div className='d-flex justify-content-between w-100 align-items-center'>
                            <ul className='text-white rem-mob-view'>
                                {PodcastNavData.map((item, index) => {
                                    return (
                                        <>
                                            <li key={index} onClick={() => { setCardId(index + 1) }} >
                                                <NavLink active={tabData === item.id} onClick={() => {
                                                    Slidetoggle(item.id);
                                                    handleShowAll(item.id);
                                                }} to="#">
                                                    <h6>{item.NavName}</h6>
                                                </NavLink>
                                            </li>

                                        </>
                                    )
                                })}
                                <li>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle color='flat m-0 p-0'>
                                            <NavLink to="#">
                                                <h6>More..</h6>
                                            </NavLink>
                                        </DropdownToggle>
                                        <DropdownMenu style={{ minWidth: '12rem', zIndex: '3' }} className='p-0 m-0 categories-scroll rem-bg existingDrop'>
                                            <DropdownItem tag='a' className='rem-bg'>Religon & Sprituality</DropdownItem>
                                            <DropdownItem tag='a' className='rem-bg'>News & Politics</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </li>
                            </ul>
                            <div className='rem-mob-view filcret'>
                                <UncontrolledButtonDropdown className='mr-3'>
                                    <DropdownToggle outline color='primary' >
                                        <Filter size={16} />
                                        <span className='align-middle'>Filter</span>
                                    </DropdownToggle>
                                    <DropdownMenu style={{ minWidth: '20rem', left: '-7rem' }} >
                                        <div className='p-2 scrollForGoliveFilters'>
                                            <div className='d-flex justify-content-between align-items-center p-2'>
                                                <h4>Podcast Filter</h4>
                                                <h6 style={{ cursor: 'pointer' }} ><u>Clear</u></h6>
                                            </div>
                                            <div className='p-2'>
                                                <InputGroup>
                                                    <Input type="search" placeholder="Select Location" />
                                                    <InputGroupText>
                                                        <img width={25} height={23} src={TrackLocation} alt='' />
                                                    </InputGroupText>
                                                </InputGroup>
                                            </div>
                                            <div className='p-2'>
                                                <div className='d-flex justify-content-around align-items-center'>
                                                    <h4>Within (km)</h4>
                                                    <h5>20 km</h5>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='col-sm-8'>
                                                        <PrettoSlider
                                                            size="small"
                                                            defaultValue={70}
                                                            aria-label="Small"
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <Button className='btn-icon p-0 m-0' color='primary'>
                                                            <Plus />
                                                        </Button>
                                                        <Button className='btn-icon p-0 ml-1' color='primary'>
                                                            <Minus />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='p-2' >
                                                    <h4>Price</h4>
                                                </div>
                                                <div>
                                                    <div className='mx-2 px-2' >
                                                        <PrettoSlider
                                                            size="small"
                                                            defaultValue={70}
                                                            aria-label="Small"
                                                            valueLabelDisplay="auto"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2'>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            Min
                                                        </InputGroupText>
                                                        <Input className='w-50' />
                                                    </InputGroup>
                                                    <CardText className='mx-1'>to</CardText>
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            Max
                                                        </InputGroupText>
                                                        <Input className='w-50' />
                                                    </InputGroup>
                                                </div>
                                            </div>
                                            <div className='p-2'>
                                                <div className='d-flex align-item-center'>
                                                    <Checkbox
                                                        checked={Free}
                                                        onChange={handleFree}
                                                        className='Check_Input_section'
                                                    />
                                                    <label className='pt-2'>Free</label>
                                                    <Checkbox
                                                        checked={Paid}
                                                        onChange={handlePaid}
                                                        style={{ marginLeft: "10px" }}
                                                        className='Check_Input_section'
                                                    />
                                                    <label className='pt-2'> Paid</label>
                                                </div>
                                            </div>
                                            <div className='p-2'>
                                                <div>
                                                    <h3>Podcast Type</h3>
                                                </div>
                                                <div>
                                                    <div className='ml-3'>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Checkbox
                                                                checked={All}
                                                                onChange={handleSelectAllChange}
                                                                className='Check_Input_section'
                                                            />
                                                            <label className='pt-2 pl-1'>All</label>
                                                        </div>
                                                        {checkboxes.map((checkbox) => (
                                                            <div key={checkbox.id} className='d-flex align-item-center'>
                                                                <Checkbox
                                                                    checked={checkbox.value}
                                                                    onChange={() => handleCheckboxChange(checkbox.id)}
                                                                    className='Check_Input_section'
                                                                />
                                                                <label className='pt-2 pl-1'>{checkbox.label}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-2'>
                                                <div onClick={() => setshowcategory(!showcategory)} className='d-flex align-item-center justify-content-between'>
                                                    <h3>Category</h3>
                                                    {showcategory ? <ChevronDown /> : <ChevronUp />}
                                                </div>
                                                {showcategory ?
                                                    <div className='ml-3'>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Checkbox
                                                                checked={Allcategory}
                                                                onChange={handlecategorySelectAll}
                                                                className='Check_Input_section'
                                                            />
                                                            <label className='pt-2 pl-1'>All categories</label>
                                                        </div>
                                                        {categoryboxes.map((checkbox) => (
                                                            <div key={checkbox.id} className='d-flex align-item-center'>
                                                                <Checkbox
                                                                    checked={checkbox.value}
                                                                    onChange={() => handlecategoryboxes(checkbox.id)}
                                                                    className='Check_Input_section'
                                                                />
                                                                <label className='pt-2 pl-1'>{checkbox.label}</label>
                                                            </div>
                                                        ))}
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                <UncontrolledButtonDropdown className='mr-1 margin-tab'>
                                    <DropdownToggle outline color='primary'>
                                        <Plus size={14} />
                                        <span className='align-middle'>Create New</span>
                                    </DropdownToggle>
                                    <DropdownMenu style={{ minWidth: '18rem', color: '#4D4D4D', left: "-163px" }}>
                                        <DropdownItem onClick={() => navigate('/Podcast/StartLivePodcast')}><img width={20} src={LiveGif} alt="LiveGif1" /> Start Live Podcast</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={() => navigate('/Podcast/UploadRecordedPodacast')}><Upload color='#4D4D4D' size={20} /> Upload recorded podcast </DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem onClick={() => navigate('/Podcast/NewPodcastSeries')}><PlusCircle color='#4D4D4D' size={20} /> Create new Podcast Series</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </div>
                    {cardId === 1 && <div>
                        <div>
                            <PodcastPurched Tittle='Top Podcast' />
                        </div>
                        <div>
                            <PodcastPurched Tittle='TV & Film' />
                        </div>
                        <div>
                            <PodcastPurched Tittle='Health & Fitness' />
                        </div>
                        <div>
                            <PodcastPurched Tittle='Socity & Culture ' />
                        </div>
                    </div>}
                    {cardId === 2 && <div>
                        <TvPurchased tittle={'TV & Film'} />
                    </div>}
                    {cardId === 3 && <div>
                        <TvPurchased tittle={'Health & Fitness'} />
                    </div>}
                    {cardId === 4 && <div>
                        <TvPurchased tittle={'Socity & Culture '} />
                    </div>}
                    {cardId === 5 && <div>
                        <TvPurchased tittle={'Comedy'} />
                    </div>}
                </TabPane>
            </TabContent>
        </React.Fragment>

    )
}

export default PodcastHeader