import React, { useState } from 'react'
import GridRecommendationTab from './Grid-Components/GridRecommendationTab'
import RecomenditionList from './Grid-Components/RecomenditionList'
import { Button } from 'reactstrap'
import { Grid, List } from 'react-feather'

const TopRecommendation = () => {
    const [data, setdata] = useState(true)
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='TrendingPeoplethistext'></p>
                <div className='list-grid-toggle rem-mob-view rem-beyond-web' style={{marginRight:'12px'}}>
                    <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                        <Grid size={16} />
                    </Button>
                    <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
                        <List size={16} />
                    </Button>
                </div>
            </div>
            {
                data ? <GridRecommendationTab /> : <RecomenditionList display={'d-none'} />
            }
        </div>
        //     <div className=''>
//         <div className='d-flex justify-content-between align-items-center'>
//         <p className='TrendingPeoplethistext'></p>
//         <div className='list-grid-toggle rem-mob-view rem-beyond-web' style={{marginRight:'8px'}}>
//             <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
//                 <Grid size={16} />
//             </Button>
//             <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
//                 <List size={16} />
//             </Button>
//         </div>
//     </div>
//     {
//         data ?
//             <GridGroupTab />
//             :
//             <ListGroup display={'d-none'} />
//     }
// </div>
    )
}
export default TopRecommendation