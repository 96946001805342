import React from "react";
import { Route, Routes } from "react-router-dom";

// import history from "./history";
import Home from "./components/Home";
import Login from "./components/login-components/Login";
import Signup from "./components/login-components/Signup";
import Otp from "./components/login-components/Otp";
import SignupEmail from "./components/login-components/SignupEmail";
import SignupProfile from "./components/login-components/SignupProfile";
import SignupDetail from "./components/login-components/SignupDetail";
import SignupInterest from "./components/login-components/SignupInterest";
import MyTimeline from "./components/profile-components/MyTimeline";
import MyProfile from "./components/profile-components/MyProfile";
import UserProfile from "./components/UserProfile-components/UserProfile";
import MyMedia from "./components/profile-components/MyMedia";
import MyMediaListView from "./components/profile-components/MyMediaListView";
import MyShotz from "./components/profile-components/MyShotz";
import MyEvent from "./components/profile-components/MyEvent";
import MyGroups from "./components/profile-components/MyGroups";
import MyPodcast from "./components/profile-components/MyPodcast";
import MyBookMarks from "./components/profile-components/MyBookMarks";
import MyPodcastDetails from "./components/profile-components/MyPodcastDetails";
import MyMarketPlace from "./components/profile-components/MyMarketPlace";
import MyBizPage from "./components/profile-components/MyBizPage";
import MyArticle from "./components/profile-components/MyArticle";
import MyText from "./components/profile-components/MyText";
import MyTextPost from "./components/profile-components/MyTextPost";
import MyRecommendation from "./components/profile-components/MyRecommendation";
import MyPoll from "./components/profile-components/MyPoll";
import Group from "./components/group-components/Group";
import OwnedGroup from "./components/group-components/OwnedGroup";
import JoinedGroup from "./components/group-components/JoinedGroup";
import InviteGroup from "./components/group-components/InviteGroup";
import Suggested from "./components/group-components/Suggested";
import GroupJointRequest from "./components/group-components/GroupJointRequest";
import GdBlockedPeople from "./components/group-components/GdBlockedPeople";
import GdPostRequest from "./components/group-components/GdPostRequest";
import GdSetting from "./components/group-components/GdSetting";
import GroupDetail from "./components/group-components/GrpDiscussionPanel/GroupDetail";
import CreateGroupScreen from "./components/group-components/CreateGroup/CreateGroupScreen";
import NewGrpCreateSection from "./components/group-components/AllGroupsSections/NewGrpCreateSection";

import Biz from "./components/biz-components/Biz";
import BizSuggested from "./components/biz-components/BizSuggested";
import OwnedBiz from "./components/biz-components/OwnedBiz";
import JoinedBiz from "./components/biz-components/JoinedBiz";
import BizDetail from "./components/biz-components/BizDetail";
import BizPost from "./components/biz-components/BizPost";
import BizReview from "./components/biz-components/BizReview";
// import BizPhotos from "./components/biz-components/BizPhotos";
import BizAbout from "./components/biz-components/BizAbout";
import BizSetting from "./components/biz-components/BizSetting"
import Favorites from "./components/Favorites";
import ForgotPassword from "./components/login-components/ForgotPassword";
import ForgotPasswordOtp from "./components/login-components/ForgotPasswordOtp";
import ResetPassword from "./components/login-components/ResetPassword";
// import AdManager from "./components/ad-manager-components/AdManager";
// import AdPausd from "./components/ad-manager-components/AdPausd";
// import AdDrafted from "./components/ad-manager-components/AdDrafted";
// import AdApprovalPending from "./components/ad-manager-components/AdApprovalPending";
// import AdCompleted from "./components/ad-manager-components/AdCompleted";
// import AdTransactionSummary from "./components/ad-manager-components/AdTransactionSummary";
// import AdAllTransactionSummary from "./components/ad-manager-components/AdAllTransactionSummary";

import Marketplace from "./components/marketplace-components/Marketplace";
// import MpPending from "./components/marketplace-components/MpPending";
// import MpSale from "./components/marketplace-components/MpSale";
// import MpSold from "./components/marketplace-components/MpSold";
// import MpDetail from "./components/marketplace-components/MpDetail";
import Tops from "./components/top-trending-components/Tops";
import Trending from "./components/top-trending-components/Trending";
import CreateBiz from "./components/biz-components/CreateBiz";
import CreateBizPage from "./components/biz-components/CreateBizPage";

import GoLive from "./components/golive-components/GoLive";
import GoLiveDetail from "./components/golive-components/GoLiveDetail";
import CreateGoLive from "./components/golive-components/CreateGoLive";
import CreateGoLiveDetail from "./components/golive-components/CreateGoLiveDetail";

import Connection from "./components/Connections/Connection";
import FollowRequestsScreen from "./components/Connections/ConnectionPlaceMenu/FollowRequestsScreen";
import FollowingScreen from "./components/Connections/ConnectionPlaceMenu/FollowingScreen";
import FollowersScreen from "./components/Connections/ConnectionPlaceMenu/FollowersScreen";
import ConnectionMenuScreen from "./components/Connections/ConnectionPlaceMenu/ConnectionMenuScreen";
import CreateAdScreen from "./components/AdmanagerD/AdsScreens/CreateAd/CreateAdScreen";
import AdTypeScreen from "./components/AdmanagerD/AdsScreens/AdType/AdTypeScreen";
import SingleImageAdScreen from "./components/AdmanagerD/AdsScreens/SingleImageAd/SingleImageAdScreen";
import InDevelopment from "./components/InDevelopment";
import CaroselImageAdImage from "./components/AdmanagerD/AdsScreens/CaroselImageAd/CaroselImageAdImage";
import VideoAdScreen from "./components/AdmanagerD/AdsScreens/VideoAd/VideoAdScreen";
import FullVideoScreen from "./components/AdmanagerD/AdsScreens/FullVideo/FullVideoScreen";
import ChooseAudienceScreen from "./components/AdmanagerD/AdsScreens/ChooseAudience/ChooseAudienceScreen";
import TotalBudgetScreen from "./components/AdmanagerD/AdsScreens/TotalBudget/TotalBudgetScreen";
import AdsPreviewScreen from "./components/AdmanagerD/AdsScreens/AdPreview/AdsPreviewScreen";
import AdPreviewMoreScreen from "./components/AdmanagerD/AdsScreens/AdPreviewMore/AdPreviewMoreScreen";
import LeadGenerationScreen from "./components/AdmanagerD/AdsScreens/LeadGeneration/LeadGenerationScreen";
import AdAnalysis from "./components/AdmanagerD/AdsScreens/AdAnalysis/AdAnalysis";
// import Test from "./components/Test";
import Seemore from "./components/Notification-component/Seemore";
import Search from "./components/Search-Components/Search"
import TagTrendingTitle from "./components/Search-Components/TagTrendingTitle";
import Bookmark from './components/Bookmark-Components/Bookmarks'
import CreateStories from "./components/Story/CreateStories"
import NewPodcastSeries from "./components/podcast-module-component/Sub-components/NewPodcastSeries";
import StartLivePodcast from "./components/podcast-module-component/Sub-components/StartLivePodcast";
import UploadRecordedPodacast from "./components/podcast-module-component/Sub-components/UploadRecordedPodacast";
import Events from "./components/Events-Components/index";
import AllEvents from "./components/Events-Components/Components/AllEvents";
import TodayEvents from "./components/Events-Components/Components/TodayEvents";
import ThoughtStory from "./components/Story/ThoughtStory";
import GroupPending from "./components/group-components/GrpDiscussionPanel/GroupPending";
import UniversalNav from "./components/Universal-components/UniversalNav";
import MyAbout from "./components/profile-components/MyAbout";
import ArticleEditor from "./components/Universal-components/post-creations/sub-components/ArticleEditor";
import Feed from "./components/feeds-comp/Feed";
import AdTransactionSummary from './components/Ad-Manager/AdmanagerTables/AdTransactionSummary'
// import SociomateScreen from "./components/Connections/SociomateScreen";
import SocioMateScreens from "./components/Connections/ConnectionPlaceMenu/SocioMateScreens";
import Dashboard from './components/Ad-Manager/Dashboard'
import { Shotz } from "./components/Shotzs/Shotz";
import { ArchivedShotzList } from "./components/Shotzs/shotz_components/ArchivedShotzList";
import ViwerSide from "./components/podcast-module-component/Sub-components/pages/ViwerSide";
import SeriesPodcastView from "./components/podcast-module-component/Sub-components/pages/SeriesPodcastView";
import Setting from "./components/Settings-Component/Setting";
import GoliveSeeAll from "./components/golive-components/GoliveSeeAll";
import LandingPage from "./components/podcast-module-component/Sub-components/pages/LandingPage";
//import CommonSeeallpage from "./components/podcast-module-component/Sub-components/pages/CommonSeeallpage";
import SeeAll from "./components/podcast-module-component/Sub-components/pages/SeeAll";
import SinglePodcast from "./components/podcast-module-component/Sub-components/pages/SinglePodcast";

import ExploreShotz from "./components/Shotzs/shotz_components/ExploreShotz";
import MpSellFom from "./components/marketplace-components/SubComponents/MpSellFom";   
import MpCheckBoxFilter from "./components/marketplace-components/MpFilterComponents/MpCheckBoxFilter"; 
import MpEditProductShell from "./components/marketplace-components/SubComponents/MpEditProductShell";
import MpSingleProductPage from "./components/marketplace-components/SubComponents/MpSingleProductPage";
import UsedAudioShotz from "./components/Shotzs/shotz_components/UsedAudioShotz";
import CataloguesPhoto from "./components/biz-components/BizPhotoComponent/CataloguesPhoto";
import CreateAd from "./components/Ad-Manager/Sub-components/CreateAd";
import CreatedAdTypes from "./components/Ad-Manager/Sub-components/CreatedAdTypes";
import SingleImageAdType from "./components/Ad-Manager/Sub-components/SingleImageAdType";
import CaroselImageType from "./components/Ad-Manager/Sub-components/CaroselImageType";
import VideoAdTypeScreen from "./components/Ad-Manager/Sub-components/VideoAdTypeScreen";
import FullScreenVideoType from "./components/Ad-Manager/Sub-components/FullScreenVideoType";
import ChooseAudience from "./components/Ad-Manager/Sub-components/ChooseAudience";
import Podcast from "./components/podcast-module-component/Podcast";
import SocialFeed from "./components/Social-feed/SocialFeed";
import LeadGenerationPage from "./components/Ad-Manager/Sub-components/LeadGenerationPage";
import LeadGenerationAdType from "./components/Ad-Manager/Sub-components/LeadGenerationAdType";
import TotalBudgetAdScreen from "./components/Ad-Manager/Sub-components/TotalBudgetAdScreen";
import AdpreviewFinal from "./components/Ad-Manager/Sub-components/AdpreviewFinal";
import Mobpost from "./components/Social-Feed-Component/Mobpost";
import PaymentSettings from "./components/Ad-Manager/AdmanagerTables/PaymentSettings";
import LoginPage from "./components/Login-Signup-Component/LoginPage";
import ForgotPasswordPage from "./components/Login-Signup-Component/ForgotPasswordPage";
import ForgotOtpVerify from "./components/Login-Signup-Component/ForgotOtpVerify";
import ResetPasswordPage from "./components/Login-Signup-Component/ResetPasswordPage";
import SignUpPage from "./components/Login-Signup-Component/SignUpPage";
import SignUpOtp from "./components/Login-Signup-Component/SignUpOtp";
import SignUpEmailPage from "./components/Login-Signup-Component/SignUpEmailPage";
import SignUpDetalsPage from "./components/Login-Signup-Component/SignUpDetalsPage";
import SignUpProfileSetup from "./components/Login-Signup-Component/SignUpProfileSetup";
import SignUpInterestPage from "./components/Login-Signup-Component/SignUpInterestPage";
import Adanalytics from "./components/Ad-Manager/Sub-components/Adanalytics";
import EditorArticle from "./components/Social-feed/AllPostTypes/EditorArticle";
import AdAnalysisTable from "./components/AdmanagerD/AdsScreens/AdAnalysis/AdAnalysisTable";
import NonLivePodcast from "./components/podcast-module-component/Sub-components/pages/NonLivePodcast";
import AdMangerReportPage from "./components/Ad-Manager/AdmanagerTables/AdMangerReportPage";
import AdUnRegUser from "./components/AdManger-Unreg-User/AdUnRegUser";
//================ Start_NFT_Routes ==================//
import MpNFT from "./components/MarketPlace-NFT/MpNFT";
import DropProfileDetail from './components/MarketPlace-NFT/Components/NftDropProfileDetail'
import CretionNft from "./components/MarketPlace-NFT/Components/CretionNft";
import SeePurchased from "./components/podcast-module-component/Sub-components/pages/SeePurchased";
import ViewStatement from "./components/podcast-module-component/Sub-components/ViewStatement";
import PodcastList from "./components/podcast-module-component/Sub-components/PodcastList";
//================ End_NFT_Routes ====================//



const Root = () => {
  return (
    // <Router history={history}>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/AdUnRegUser" element={<AdUnRegUser />} />
      <Route exact path="/Signup" element={<Signup />} />
      <Route exact path="/Otp" element={<Otp />} />
      <Route exact path="/SignupEmail" element={<SignupEmail />} />
      <Route exact path="/SignupProfile" element={<SignupProfile />} />
      <Route exact path="/SignupDetail" element={<SignupDetail />} />
      <Route exact path="/SignupInterest" element={<SignupInterest />} />
      <Route exact path="/Home" element={<Home />} />
      <Route exact path="/Post" element={<Mobpost />} />
      <Route exact path="/MyAbout" element={<MyAbout />} />
      <Route exact path="/MyTimeline" element={<MyTimeline />} />
      <Route exact path="/MyProfile" element={<MyProfile />} />
      <Route exact path="/UserProfile" element={<UserProfile />} />
      <Route exact path="/MyMedia" element={<MyMedia />} />
      <Route exact path="/MyMediaListView" element={<MyMediaListView />} />
      <Route exact path="/MyShotz" element={<MyShotz />} />
      <Route exact path="/MyEvent" element={<MyEvent />} />
      <Route exact path="/MyPodcast" element={<MyPodcast />} />
      <Route exact path="/MyGroups" element={<MyGroups />} />
      <Route exact path="/MyPodcastDetails/:podcastId" element={<MyPodcastDetails />} />
      <Route exact path="/MyBookMarks" element={<MyBookMarks />} />
      <Route exact path="/MyMarketPlace" element={<MyMarketPlace />} />
      <Route exact path="/MyBizPage" element={<MyBizPage />} />
      <Route exact path="/MyArticle" element={<MyArticle />} />
      <Route exact path="/MyText" element={<MyText />} />
      <Route exact path="/MyPostDetail/:postId" element={<MyTextPost />} />
      <Route exact path="/MyRecommendation" element={<MyRecommendation />} />
      <Route exact path="/MyPoll" element={<MyPoll />} />
      <Route exact path="/ArticleEditor" element={<ArticleEditor />} />
      <Route exact path="/Feed" element={<Feed />} />
      <Route exact path="/favorites" element={<Favorites />} />
      <Route exact path="/Group" element={<Group />} />
      <Route exact path="/Search" element={<Search />} />
      <Route exact path="/Search/:id" element={<Search />} />
      <Route exact path="/Search/pages" element={<Search />} />
      <Route exact path="/TagTrendingTitle" element={<TagTrendingTitle />} />
      <Route exact path="/CreateStories" element={<CreateStories />} />
      <Route exact path="/ThoughtStory" element={<ThoughtStory />} />
      <Route exact path="/Bookmark" element={<Bookmark />} />
      <Route exact path="/OwnedGroup" element={<OwnedGroup />} />
      <Route exact path="/JoinedGroup" element={<JoinedGroup />} />
      <Route exact path="/InviteGroup" element={<InviteGroup />} />
      <Route exact path="/Suggested" element={<Suggested />} />
      <Route exact path="/GroupJointRequest" element={<GroupJointRequest />} />
      <Route exact path="/GroupDetail/group/:id" element={<GroupDetail />} />
      <Route exact path="/CreateGroupScreen" element={<CreateGroupScreen />} />
      <Route exact path="/NewGrpCreateSection" element={<NewGrpCreateSection />} />
      <Route exact path="/GdBlockedPeople" element={<GdBlockedPeople />} />
      <Route exact path="/GdPostRequest" element={<GdPostRequest />} />
      <Route exact path="/GdSetting" element={<GdSetting />} />

      {/*Start_Biz_page Route */}
      <Route exact path="/Biz" element={<Biz />} />
      <Route exact path="/OwnedBiz" element={<OwnedBiz />} />
      <Route exact path="/JoinedBiz" element={<JoinedBiz />} />
      <Route exact path="/BizDetail" element={<BizDetail />} />
      {/* <Route exact path="/BizDetail/:id" element={<BizDetail />} /> */}
      <Route exact path="/BizPost" element={<BizPost />} />
      <Route exact path="/BizReview" element={<BizReview />} />
      <Route exact path="/CataloguesPhoto" element={<CataloguesPhoto />} />
      <Route exact path="/BizAbout" element={<BizAbout />} />
      <Route exact path="/BizSetting" element={<BizSetting />} />
      {/* <Route exact path="/BizPost/:id" element={<BizPost />} />
      <Route exact path="/BizReview/:id" element={<BizReview />} />
      <Route exact path="/CataloguesPhoto/:id" element={<CataloguesPhoto />} />
      <Route exact path="/BizAbout/:id" element={<BizAbout />} />
      <Route exact path="/BizSetting/:id" element={<BizSetting />} /> */}
      <Route exact path="/BizSuggested" element={<BizSuggested />} />
      {/*End_Biz_page Route */}
      <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
      <Route exact path="/ForgotPasswordOtp" element={<ForgotPasswordOtp />} />
      <Route exact path="/ResetPassword" element={<ResetPassword />} />
      {/* <Route exact path="/AdManager" element={<AdManager />} />
      <Route exact path="/AdPausd" element={<AdPausd />} />
      <Route exact path="/AdDrafted" element={<AdDrafted />} />
      <Route exact path="/AdApprovalPending" element={<AdApprovalPending />} />
      <Route exact path="/AdCompleted" element={<AdCompleted />} /> */}
      <Route exact path="/Notification" element={<Seemore />} />
      {/* <Route
        exact
        path="/AllTransactionSummary"
        element={<AdAllTransactionSummary />}
      /> */}
      <Route exact path="/Marketplace" element={<Marketplace />} />
      <Route exact path="/mpsell" element={<MpSellFom />} />
      <Route exact path="/MpEditProductShell" element={<MpEditProductShell />} />  
      <Route exact path="/MpCheckBoxFilter" element={<MpCheckBoxFilter />} /> 

      <Route exact path="/mpsingleproduct" element={<MpSingleProductPage />} />
      {/* <Route exact path="/MpPending" element={<MpPending />} />
      <Route exact path="/MpSale" element={<MpSale />} />
      <Route exact path="/MpSold" element={<MpSold />} />
      <Route exact path="/MpDetail" element={<MpDetail />} /> */}
      <Route exact path="/Tops" element={<Tops />} />
      <Route exact path="/Trending" element={<Trending />} />
      <Route exact path="/CreateBiz" element={<CreateBiz />} />
      <Route exact path="/CreateBizPage" element={<CreateBizPage />} />
      <Route exact path="/Podcast" element={<Podcast />} />
      <Route exact path="/Podcast/NewPodcastSeries" element={<NewPodcastSeries />} />
      <Route exact path="/Podcast/StartLivePodcast" element={<StartLivePodcast />} />
      <Route exact path="/Podcast/UploadRecordedPodacast" element={<UploadRecordedPodacast />} />
      <Route exact path="/Podcast/View" element={<ViwerSide />} />
      <Route exact path="/YourSeries" element={<LandingPage />} />
      <Route exact path="/Podcast/SeeAll" element={<SeeAll />} />
      <Route exact path="/SeePurchased" element={<SeePurchased />} />
      <Route exact path="/ViewStatement" element={<ViewStatement />} />
      <Route exact path="/PodcastList" element={<PodcastList />} />
      <Route exact path="/SinglePodcast" element={<SinglePodcast />} />
      <Route exact path="/Podcast/Selected" element={<NonLivePodcast />} />
      <Route exact path="/SocialFeed" element={<SocialFeed />} />
      {/* AD */}
      {/* SideNav Pages */}
      <Route exact path="/Dashboard" element={<Dashboard />} />
      <Route exact path="/Dashboard/Report" element={<AdMangerReportPage />} />
      <Route exact path="/Dashboard/TransactionSummary" element={<AdTransactionSummary />} />
      <Route exact path="/Dashboard/PaymentSettings" element={<PaymentSettings />} />


      {/* Add Manager Creation Pages */}
      <Route exact path="/CreateAd" element={<CreateAd />} />
      <Route exact path="/Ad/Display/:id" element={<CreatedAdTypes />} />
      <Route exact path="/Ad/Display/SingelImage" element={<SingleImageAdType />} />
      <Route exact path="/Ad/Display/caroselImage" element={<CaroselImageType />} />
      <Route exact path="/Ad/Display/Video" element={<VideoAdTypeScreen />} />
      <Route exact path="/Ad/Display/FullScreenVideo" element={<FullScreenVideoType />} />
      <Route exact path="/Ad/ChooseAudience" element={<ChooseAudience />} />
      <Route exact path="/Ad/TotalBudget" element={<TotalBudgetAdScreen />} />
      <Route exact path="/Ad/AdPreview" element={<AdpreviewFinal />} />
      <Route exact path="/Ad/AdPreviewMore" element={<AdPreviewMoreScreen />} />
      <Route exact path="/Ad/LeadGeneration" element={<LeadGenerationAdType />} />
      <Route exact path="/LeadGeneration" element={<LeadGenerationPage />} />
      <Route exact path="/AdAnalysis" element={<AdAnalysis />} />
      <Route exact path="/AdAnalysisTable" element={<AdAnalysisTable />} />
      {/* GROUP ROUTES */}
      <Route exact path="/Group/create" element={<CreateGroupScreen />} />
      <Route exact path="GroupDetail/:id/:groupName/:totalMembers/:index" element={<GroupDetail />} />
      <Route exact path="/Events" element={<Events />} />
      {/* <Route exact path="/AllEvents" element={<AllEvents />} /> */}
      <Route exact path="/AllEvents" element={<AllEvents />} />
      <Route exact path="/TodayEvents" element={<TodayEvents />} />
      <Route exact path="/GroupPending" element={< GroupPending />} />
      <Route exact path="/UniversalNav" element={< UniversalNav />} />
      <Route exact path="/GoLive" element={<GoLive />} />
      <Route exact path="/GoLiveDetail" element={<GoLiveDetail />} />
      <Route exact path="/CreateGoLive" element={<CreateGoLive />} />
      <Route exact path="/CreateGoLiveDetail" element={<CreateGoLiveDetail />} />
      <Route exact path="/SelectedCategory" element={<GoliveSeeAll />} />
      <Route exact path="/Connection" element={<Connection />} />
      <Route exact path="/FollowRequests" element={<FollowRequestsScreen />} />
      <Route exact path="/FollowingScreen" element={<FollowingScreen />} />
      <Route exact path="/FollowersScreen" element={<FollowersScreen />} />
      <Route exact path="/SocioMateScreens" element={<SocioMateScreens />} />
      <Route exact path="/Shotz" element={<Shotz />} />
      <Route exact path="/ExploreShotz" element={<ExploreShotz />} />
      <Route exact path="/UsedAudioShotz" element={<UsedAudioShotz />} />
      <Route exact path="/Setting" element={<Setting />} />
      <Route exact path="/ArchivedShotzList" element={<ArchivedShotzList />} />
      <Route exact path="/EditorArticle" element={<EditorArticle />} />
      <Route
        exact
        path="/ConnectionMenuScreen"
        element={<ConnectionMenuScreen />}
      />
      <Route exact path="/in-development" element={<InDevelopment />} />
      {/* <Route path="*" element={<NotFound/>}/> */}
      {/* Login Page Replica  */}
      <Route exact path="/loginpage" element={<LoginPage />} />
      <Route exact path="/forgotpasswordpage" element={<ForgotPasswordPage />} />
      <Route exact path="/forgotverify" element={<ForgotOtpVerify />} />
      <Route exact path="/resetpasswordpage" element={<ResetPasswordPage />} />
      <Route exact path="/signuppage" element={<SignUpPage />} />
      <Route exact path="/signupotp" element={<SignUpOtp />} />
      <Route exact path="/signupemailverify" element={<SignUpEmailPage />} />
      <Route exact path="/signupdetails" element={<SignUpDetalsPage />} />
      <Route exact path="/signupprofilesetup" element={<SignUpProfileSetup />} />
      <Route exact path="/signupinterestchoose" element={<SignUpInterestPage />} />
      <Route exact path="/MpNFT" element={<MpNFT />} />
      <Route exact path="/CretionNft" element={<CretionNft />} />
      <Route exact path="/DropProfileDetail" element={<DropProfileDetail />} />

    </Routes>
    // </Router>
  );
};
export default Root;
