import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Slider from "react-slick";
import { Col, Modal, Row, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, TabContent, TabPane, Input } from 'reactstrap';
import cricleimg from '../../../NewComponents/IMG/cricleimg.png'
import { ChevronLeft, ChevronRight, Image, MoreVertical, Pause, Play, Settings, Smile, Type, Volume2, VolumeX, X } from 'react-feather';
import { Fab } from '@mui/material';
import ProgressBar from 'react-bootstrap/ProgressBar';
import StoryFooter from './StoryFooter';
import ConnectionReportModal from '../../Connections/ConnectionModal/ConnectionReportModal';
import StorySettingModal from './StorySettingModal';
import like from '../../../NewComponents/IMG/userlike.png'
import Send2 from '../../../NewComponents/IMG/Send2.png'
import StoryShareModal from './StoryShareModal';
import { StoriesCreateUsers } from '../StoryArrayDummy/StoryDummy';
import { StoriesView } from '../StoryArrayDummy/StoryDummy';
import StoryEmojies from '../StoryEmojies';
import { useEffect } from 'react';
import { useRef } from 'react';

const StoryModalView = (props) => {
    const [paused, setPaused] = useState(false);
    const [volume, setvolume] = useState(false);
    const [StoryActiveSlider, setStoryActiveSlider] = useState('1')
    const [showIcons, setShowIcons] = useState(false)

    //  ------------- Start Reported Option State -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const togglethisslider = tab => {
        if (StoryActiveSlider !== tab) setStoryActiveSlider(tab)
    }

    // ------------- Share Modal State -------------
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const gallerySettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // nextArrow: <ChevronRight />,
        // prevArrow: <ChevronLeft />
    };


    // ------------- Story setting States -------------
    const [storySettingModal, setStorySettingModal] = useState(false)
    const toggleStorySettingModal = () => {
        setStorySettingModal((prevState) => !prevState)
    }





    return (
        <>
            {/* ------ Add Report Option ------ */}
            <ConnectionReportModal
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/* ------ Add Story Setting Option ------ */}
            <StorySettingModal
                isOpen={storySettingModal}
                settingModal={toggleStorySettingModal} />



            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} className='modal-dialog-centered modal-container modal-fullscreen overflow-hidden '>
                    <Row className='d-flex border-0' style={{ maxHeight: "100vh" }}>
                        <Col xl='3' lg='4' md="5" sm="12" className='story_sidebar RemoveInMobileStory col-xxl-3' style={{ height: "100vh" }}>
                            <div className='d-flex justify-content-between py-3 px-2 align-items-center border-bottom' >
                                <h2 className='font-weight-bold'>Stories</h2>
                                <p role="button"><Settings onClick={toggleStorySettingModal} size={22} color='#4D4D4D' /></p>
                            </div>
                            <h3 className='ml-3 my-3'>Your Story</h3>
                            <div className='d-flex justify-content-between align-items-center' onClick={() => { togglethisslider('4') }}>
                                <div className='d-flex align-items-center ml-2'>
                                    <div className="circle-border-style">
                                        <img src={cricleimg} width={60} className='img-fluid' alt='CircleImage' />
                                    </div>
                                    <div className='ml-2 d-flex flex-column'>
                                        <h4 className="d-inline-block text-truncate font500" >Jekerlin Ferdinand</h4>
                                        <h5 className="text-muted mt-1" >20h ago • 16 View</h5>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-2'>
                                    <NavLink to='/CreateStories' className='storyEditBtnDesignViewPage border p-0' >
                                        <Type size={14} />
                                    </NavLink>
                                    {/* &nbsp;&nbsp; */}
                                    <NavLink to='/CreateStories' className='storyEditBtnDesignViewPage border p-0' >
                                        <Image size={14} />
                                    </NavLink>
                                </div>
                            </div>
                            <div>
                                {/* ----------- Stories Create Users ------------*/}
                                <h3 className='p-3'>Recent Stories</h3>
                                {StoriesCreateUsers.map((data) => (
                                    <div className='d-flex justify-content-between py-2 px-3 align-items-center StoryViewHoverColor cursor-p' >
                                        <div className='d-flex align-items-center' onClick={() => { togglethisslider('1') }}>
                                            <div className='border_style' >
                                                <img src={data.userImg} width={60} className='img-fluid' alt='userPic' />
                                            </div>
                                            <div className='ml-2'>
                                                <h5 className='font500'>{data.userName}</h5>
                                                <h6 className='font500 text-primary mt-1'>{data.storieNum} <span className='text-muted font400'>{data.storyCreateTime}</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <h3 className='p-3'>Viewed Stories</h3>
                                {StoriesCreateUsers.map((data) => (
                                    <div className='d-flex justify-content-between py-2 px-3 align-items-center StoryViewHoverColor cursor-p'>
                                        <div className='d-flex align-items-center' onClick={() => { togglethisslider('1') }}>
                                            <div className='border_style_disable'>
                                                <img src={data.userImg} width={60} className='img-fluid' alt='usePic' />
                                            </div>
                                            <div className='ml-2'>
                                                <h5 className='font500'>{data.userName}</h5>
                                                <h6 className='font500 text-primary mt-1'>{data.storieNum} <span className='text-muted font400'>{data.storyCreateTime}</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col xl='9' lg='8' md="7" sm="12" className='story_content position-relative right-box col-xxl-9' style={{ maxHeight: "100vh", overflowY: "auto" }}>
                            <div className='m-3'>
                                <Row className='d-flex '>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={100} />
                                    </Col>
                                    <Col>
                                        <ProgressBar variant="primary" now={50} />
                                    </Col>
                                </Row>
                                <div className='text-right my-2'>
                                    <X color='#FFFFFF' className="SwitchOnMobileView" onClick={props.storyViewModal} />
                                </div>
                                {/* <div className="row">
                                    <div className="col-xl-5 col-lg-8 col-md-11 col-sm-11 mx-auto  imageMaxWidth  StoryAspectRatioAdjustment">
                                        <TabContent activeTab={StoryActiveSlider} className="Mx_h_100">
                                            <TabPane tabId="1" className="Mx_h_100">
                                                <Slider {...gallerySettings} className="StoryBtnSlickSlider  default-space Mx_h_100 ">
                                                    <div className='img-view-container rounded Mx_h_100 StoryAspectRatioAdjustment'>
                                                        <img src='https://images.pexels.com/photos/1743165/pexels-photo-1743165.jpeg?auto=compress&cs=tinysrgb&w=1600'
                                                            className='img-fluid h-100 w-100 img-gradient rounded MinHeightOfImage' alt="Edited" />
                                                        <Row className='img-top-head w-100 ml-2'>
                                                            <Col xl='4' lg='5' md='6' sm='6' xs='6' className='d-flex py-2 align-items-center'>
                                                                <img src={cricleimg} width={60} className='img-fluid' alt='ImageCircle' />
                                                                <div className='ml-2'>
                                                                    <h4 className="text-white d-inline-block text-truncate" style={{ maxWidth: '100px' }}>Ella Sofia</h4>
                                                                    <h6 className="text-white d-inline-block text-truncate" style={{ maxWidth: '100px' }}>20 Min ago</h6>
                                                                </div>
                                                            </Col>
                                                            <Col xl='8' lg='7' md='6' sm='6' xs='6' className='d-flex align-items-center justify-content-end'>
                                                                <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                </p>
                                                                <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                </p>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color=''>
                                                                        <MoreVertical size={22} color='#FFFFFF' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='StoryThreeDotButtonMenu' onClick={toggleReport}>
                                                                        <DropdownItem className='dropdownitem'>Report Story</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </Col>
                                                        </Row>
                                                        <div className='d-flex StorylikeAndShare'>
                                                            <img src={like} className='mr-2' height={30} alt="likeandshare" />
                                                            <img src={Send2} onClick={toggleForward} height={30} alt="likeandshare" />
                                                        </div>
                                                        <div className="IconsStory text-white ">
                                                            {showIcons ? <img src="/assets/svg/emoji/040.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/113.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/027.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/052.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/039.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/042.svg" alt="smile" /> : " "}
                                                            <Smile size={30} onClick={() => setShowIcons(!showIcons)} />
                                                        </div>
                                                    </div>
                                                    <div className='img-view-container rounded'>
                                                        <img src='https://images.pexels.com/photos/1624438/pexels-photo-1624438.jpeg?auto=compress&cs=tinysrgb&w=1600'
                                                            className='img-fluid w-100 img-gradient h-100 rounded MinHeightOfImage' alt="Edited" />
                                                        <Row className='img-top-head w-100 ml-2'>
                                                            <Col xl='4' lg='5' md='6' sm='6' xs='6' className='d-flex py-2 align-items-center'>
                                                                <img src={cricleimg} width={60} className='img-fluid' alt='ImageCircle' />
                                                                <div className='ml-2'>
                                                                    <h4 className="text-white d-inline-block text-truncate" style={{ maxWidth: '100px' }}>Ella Sofia</h4>
                                                                    <p className="text-muted d-inline-block text-truncate" style={{ maxWidth: '100px' }}>20 Min ago</p>
                                                                </div>
                                                            </Col>
                                                            <Col xl='8' lg='7' md='6' sm='6' xs='6' className='d-flex align-items-center justify-content-end'>
                                                                <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                </p>
                                                                <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                </p>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color=''>
                                                                        <MoreVertical size={22} color='#FFFFFF' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='StoryThreeDotButtonMenu' onClick={toggleReport}>
                                                                        <DropdownItem className='dropdownitem'>Report Story</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </Col>
                                                        </Row>
                                                        <div className='d-flex StorylikeAndShare'>
                                                            <img src={like} className='mr-2' height={30} alt="likeandshare" />
                                                            <img src={Send2} onClick={toggleForward} height={30} alt="likeandshare" />
                                                        </div>
                                                        <div className="IconsStory text-white ">
                                                            {showIcons ? <img src="/assets/svg/emoji/040.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/113.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/027.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/052.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/039.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/042.svg" alt="smile" /> : " "}
                                                            <Smile size={30} onClick={() => setShowIcons(!showIcons)} />
                                                        </div>
                                                    </div>
                                                    <div className='img-view-container rounded'>
                                                        <img src='https://images.pexels.com/photos/1266810/pexels-photo-1266810.jpeg?auto=compress&cs=tinysrgb&w=1600'
                                                            className='img-fluid w-100 img-gradient h-100 rounded MinHeightOfImage' alt="Edited" />
                                                        <Row className='img-top-head w-100 ml-2'>
                                                            <Col xl='4' lg='5' md='6' sm='6' xs='6' className='d-flex py-2 align-items-center'>
                                                                <img src={cricleimg} width={60} className='img-fluid' alt='ImageCircle' />
                                                                <div className='ml-2'>
                                                                    <h4 className="text-white d-inline-block text-truncate" style={{ maxWidth: '100px' }}>Ella Sofia</h4>
                                                                    <p className="text-muted d-inline-block text-truncate" style={{ maxWidth: '100px' }}>20 Min ago</p>
                                                                </div>
                                                            </Col>
                                                            <Col xl='8' lg='7' md='6' sm='6' xs='6' className='d-flex align-items-center justify-content-end'>
                                                                <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                </p>
                                                                <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                </p>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color=''>
                                                                        <MoreVertical size={22} color='#FFFFFF' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className='StoryThreeDotButtonMenu' onClick={toggleReport}>
                                                                        <DropdownItem className='dropdownitem'>Report Story</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </Col>
                                                        </Row>
                                                        <div className='d-flex StorylikeAndShare'>
                                                            <img src={like} className='mr-2 cursor-p' height={30} alt="LikeandShare" />
                                                            <img src={Send2} onClick={toggleForward} height={30} className="cursor-p" alt="LikeandShare" />
                                                        </div>
                                                        <div className="IconsStory text-white ">
                                                            {showIcons ? <img src="/assets/svg/emoji/040.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/113.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/027.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/052.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/039.svg" alt="smile" /> : " "}
                                                            {showIcons ? <img src="/assets/svg/emoji/042.svg" alt="smile" /> : " "}
                                                            <Smile size={30} onClick={() => setShowIcons(!showIcons)} />
                                                        </div>
                                                    </div>
                                                </Slider>
                                                <StoryFooter />
                                            </TabPane>
                                        </TabContent>
                                        <StoryShareModal
                                            isOpen={forwardOpen}
                                            shareModalToOpen={toggleForward}
                                        />
                                    </div>
                                </div> */}
                                <div className="StoryViewImageSlider" style={{ maxHeight: "100vh" }}>
                                    <div className="col-xl-9 col-lg-11 col-md-11 p-0">
                                        <Slider {...gallerySettings} className="default-space">
                                            {StoriesView.map((item, index) => (
                                                <div className='w-100 d-flex align-items-center  justify-content-center flex-column ' style={{ maxHeight: "100vh", minHeight: "calc(100vh / 1.2)" }}>
                                                    <div>
                                                        <div className="StroyImagePrivewSlider position-relative mb-1"  >
                                                            <img style={{ zIndex: 0 }} className='StoryPreviewImageSrc' src={item.img} alt="" />
                                                            <div className='d-flex justify-content-between zIndex2'>
                                                                <div className='d-flex py-2 align-items-center'>
                                                                    <img src={cricleimg} width={60} className='img-fluid' alt='ImageCircle' />
                                                                    <div className='d-flex flex-column align-items-start ml-2 zIndex2'>
                                                                        <h4 className="text-white d-inline-block text-truncate" >{item.name}</h4>
                                                                        <h6 className="text-white d-inline-block text-truncate" >{item.time}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <p className='mr-3' aria-label={paused ? 'play' : 'pause'} onClick={() => setPaused(!paused)} >{paused ? (<Play size={22} color='#FFFFFF' />) : (<Pause size={22} color='#FFFFFF' />)}
                                                                    </p>
                                                                    <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >{volume ? (<Volume2 size={23} color='#FFFFFF' />) : (<VolumeX size={23} color='#FFFFFF' />)}
                                                                    </p>
                                                                    <UncontrolledButtonDropdown>
                                                                        <DropdownToggle color=''>
                                                                            <MoreVertical size={22} color='#FFFFFF' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='StoryThreeDotButtonMenu' onClick={toggleReport}>
                                                                            <DropdownItem className='dropdownitem'>Report Story</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex StorylikeAndShare'>
                                                                <img src={like} className='mr-2 storyReactionBtn' alt="likeandshare" />
                                                                <img src={Send2} onClick={toggleForward} className=" storyReactionBtn" alt="likeandshare" />
                                                            </div>
                                                            <StoryEmojies showIcons={showIcons} setShowIcons={setShowIcons} />
                                                        </div>
                                                        <Input type="text" className="ReplyInputbox" placeholder="reply......." />
                                                    </div>

                                                </div>
                                            ))}

                                        </Slider>

                                        <StoryShareModal
                                            isOpen={forwardOpen}
                                            shareModalToOpen={toggleForward}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal >
            }
        </>
    )
}

export default StoryModalView