import React, { Fragment } from 'react';
import Home from "../../NewComponents/IMG/footerHome.svg";
import MarketPlace from "../../NewComponents/IMG/marketplace.svg";
import CreateNew from '../../NewComponents/IMG/CreateNew.svg';
import MsgMee from '../../NewComponents/IMG/MsgMee.svg';
import Shotz from '../../NewComponents/IMG/Shotzs.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { useUserAuth } from '../../Context/userAuthContext';

const FooterData = () => {
    const navigate = useNavigate();
    const { handleShow, MobPost } = useUserAuth();

    const FooterDataArray = [
        {
            toolTipId: "tooltipForHome",
            toolTipName: "Home",
            Img: Home,
            link: "/Home",
            Operation: () => navigate("/Home")
        },
        // {
        //     toolTipId: "tooltipForGoLive",
        //     toolTipName: "GoLive",
        //     Img: MarketPlace,
        //     link: "/biz",
        //     Operation: () => navigate("/biz")
        // },  
        // {we have commneted this  because we want marketplace in second position}
        {
            toolTipId: "tooltipForMarketplace",
            toolTipName: "Marketplace",
            Img: MarketPlace,
            link: "/Marketplace",
            Operation: () => navigate("/Marketplace")
        },
        {
            toolTipId: "tooltipForShotz",
            toolTipName: "Shotz",
            Operation: handleShow,
            Img: CreateNew,
            link: "/Post"
        },
        {
            toolTipId: "tooltipForGroup",
            toolTipName: "Group",
            Img: MsgMee,
            link: "/Group",
            Operation: () => navigate("/Group")
        },
        {
            toolTipId: "tooltipForPost",
            toolTipName: "Post",
            Img: Shotz,
            link: "/",
            Operation: () => navigate("/")
        },
    ];

    return (
        <Fragment>
            <div className="d-flex justify-content-around mt-3">
                {FooterDataArray.map((data, index) => (
                    <div key={index}>
                        <div onClick={data.Operation} className="main-link">
                            <img 
                                src={data.Img} 
                                alt={data.toolTipName} 
                                width={30} 
                                height={30} 
                                id={data.toolTipId}
                            />
                        </div>
                        <UncontrolledTooltip placement='right' target={data.toolTipId}>
                            {data.toolTipName}
                        </UncontrolledTooltip>
                    </div>
                ))}
            </div>
        </Fragment>
    );
}

export default FooterData;
