import React from 'react'
import { useState } from 'react'
import { Bookmark, ChevronLeft, ChevronRight, Pause, Play, Send, Circle, Smile, User, Users, MapPin, MessageCircle, PhoneCall, Mic } from 'react-feather'
import { Link } from 'react-router-dom'
import { Card, CardHeader, Input, Progress } from 'reactstrap'
import ca from '../Bookmark-Components/Img/ca.svg'
import LikeImg from '../../NewComponents/IMG/LikeImg.svg'
import CmntImg from '../../NewComponents/IMG/CmntImg.svg'
import ShrImg from '../../NewComponents/IMG/ShrImg.svg'
import fpic from '../Bookmark-Components/Img/fpic.png'
import Group from '../Bookmark-Components/Img/Group.png'
import Fr from '../Bookmark-Components/Img/Fr.png'
import Rec from '../Bookmark-Components/Img/Rec.png'
import Rect from '../Bookmark-Components/Img/Rect.svg'
import sngle1 from '../Bookmark-Components/Img/sngle1.svg'
import mic from '../Bookmark-Components/Img/mic.png'
import twk from '../Bookmark-Components/Img/twk.png'
import mic1 from '../Bookmark-Components/Img/mic1.png'
import paid from '../Bookmark-Components/Img/paid.png'
import tom from '../Bookmark-Components/Img/tom.png'
import CommentActive from '../../NewComponents/IMG/CommentActive.svg'
import left from '../Bookmark-Components/Img/left.svg'
import right from '../Bookmark-Components/Img/right.svg'
import play from '../Bookmark-Components/Img/play.svg'
import livelink from '../Bookmark-Components/Img/Frames.png'
import Frames from '../Bookmark-Components/Img/Frames.png'
import ActiveCirclelike from '../../NewComponents/IMG/ActiveCirclelike.svg'
import poll from '../Bookmark-Components/Img/poll.svg'
import bookmark from '../Bookmark-Components/Img/bookmark.png'
import El from '../Bookmark-Components/Img/El.png'
import El1 from '../Bookmark-Components/Img/El1.png'
import Ell2 from '../Bookmark-Components/Img/Ell2.png'
import Girl from '../Bookmark-Components/Img/Girl.png'
import Rpodcast from '../Bookmark-Components/Img/Rpodcast.png'
import './Bookmark.css'

const AllPostType = [
    {
        title1: 'Sufiya Eliza ',
        data: ' Denmark is a Scandinavian country comprising the Jutland Peninsula and numerous islands. Its linked to nearby Sweden via the Öresund bridge. Copenhagen, its capital, is home to royal palaces and colorful Nyhavn harbor, plus the Tivoli amusement park and penhagen its capital',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        img1: 'https://images.pexels.com/photos/416024/pexels-photo-416024.jpeg?auto=compress&cs=tinysrgb&w=1600',
        text: 'is feeling happy with',
        text1: '😀 feeling with happy',
        text2: '',
        text3: '',
        text4: ' ',
        // icon: <ChevronRight size={17} className='text-primary' />,
        read: '.....Read more',
        and: ''
    },
    {
        title1: 'Jason Bourne',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        img1: 'https://images.pexels.com/photos/76971/fighter-jet-fighter-aircraft-f-16-falcon-aircraft-76971.jpeg?auto=compress&cs=tinysrgb&w=1600',
        content: (
            <div className='bookmark-content'>
                <p className='mt-2 bookmark-content-captions'>The French fighter jet is a great ride!!!!</p>
            </div>
        ),
    },
    {
        title1: 'Jason Bourne',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        img1: 'https://images.pexels.com/photos/618833/pexels-photo-618833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',

        content: (
            <div className='bookmark-content'>
                <p className='mt-2 bookmark-content-captions'>The Swiss mountains are a treat to your eyes, heart!!!!</p>
            </div>
        ),
    },
    {
        title1: 'Sufiya Eliza',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        img1: fpic,

        content: (
            <div className='bookmark-content'>
                <p className='mt-2 bookmark-content-captions'>Celebration new album song launched</p>
            </div>
        ),

    },
    {
        title1: 'Sufiya Eliza',
        data: 'India, officially the Republic of India, ISO: Bhārat Gaṇarājya,is a country in South Asia.It is the seventh-largest country by area, the most populous country as Denmark is a Scandinavian country comprising the Jutland Peninsula and numerous islands. Its linked to nearby Sweden via the Öresund bridge. Copenhagen, its capital, is home to royal palaces and colorful Nyhavn harbor, plus the Tivoli amusement park and penhagen its capital.',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        read: '.....Read more',
        img1: 'https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300',
        play: play
    },
    {
        title1: 'Sufiya Eliza ',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        content: (
            <div className='bookmark-content'>
                <p className='mt-2 bookmark-content-captions'>Celebration new album song launched</p>
            </div>
        ),
        img1: tom,
    },
    {
        title1: 'Sufiya Eliza ',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        data21: <div><div className='mt-2' style={{ color: 'gray' }}><MapPin size={16} color='gray' />&nbsp;&nbsp;Güntherstra, Frankfurt , Germany
            <div className='d-flex  p-2 mt-2 ' ><h3 className='usdtext' style={{ color: '#4D4D4D' }} >$ 41 (USD)<br></br><p style={{ color: 'gray' }}>In Stock | New</p></h3>&nbsp;&nbsp;<button style={{ backgroundColor: '#81C14B' }} className='form-control usdtextss messagebtn' ><MessageCircle size={20} color='white' /></button>&nbsp;<button className='form-control usdtextss' style={{ backgroundColor: '#81C14B' }}><PhoneCall size={20} color='white' /></button></div>
        </div></div>,
        // marginLeft: '18rem',
        img1: Rec,
        tomText: <h2>Wooden Horse Sculpture on sale</h2>,
        toptext: <p className='' style={{ color: 'gray' }}>Home & Decor | Sculpture</p>,
    },
    {
        title1: 'Jason Bourne',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
        img1: 'https://images.pexels.com/photos/76971/fighter-jet-fighter-aircraft-f-16-falcon-aircraft-76971.jpeg?auto=compress&cs=tinysrgb&w=1600',
        content: (
            <div className='bookmark-content'>
                <p className='mt-2  bookmark-content-captions'>The French fighter jet is a great ride!!!!</p>
            </div>
        ),

    },

    {
        title1: 'Jason Bourne',
        data: '',
        data1: <div className='mt-2'>Naba Kelebar Utsav Of Para..</div>,
        data21: <div className='mt-2' style={{ color: 'gray' }}><MapPin size={16} color='gray' />&nbsp;&nbsp;Bessemer, North Carolina</div>,
        data3: <div className='pl-1 mt-2'><p style={{ color: 'gray' }}><Users size={15} color='gray' />&nbsp;&nbsp;160 Going&nbsp;&nbsp;&nbsp;&nbsp;<Users size={15} color='gray' />&nbsp;&nbsp;154 Not Interested</p></div>,
        texts: 'Sun, 26 Jun 2022, 06:00PM - 09:30AM',
        img1: Rect,
        server: <div className="pvt-eventconts" style={{ background: '#FF822E', top: '70px' }} >Entertainment </div>,
    },
    {
        title1: 'Jason Bourne',
        data: '',
        // img1: Fr,
        textData: <div className='px-4 sizeForText p-2 rounded-2 SingleMedia_Content_blk mt-3 ' style={{ backgroundColor: 'red', color: 'white', height: '11.5rem' }}>
            <i className="fa fa-quote-left" style={{ height: '25px', width: '25px', color: '#0075ff', marginTop: '1rem' }}></i>&nbsp;&nbsp;
            <p className='ml-3 text-center mt-1'>
                There is going to be some serious Again at CloudPlatform Provider Zoho Cloud Platform @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider ZohoCloudPlatform Provider.
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <p className="tex" style={{ paddingRight: '1rem' }}>
                    <i className="fa fa-quote-right" style={{ height: '25px', width: '25px', color: '#0075ff' }}></i>
                </p>
            </div>
        </div>,
        server: <div className="pvt-eventconts" style={{ background: '#FF822E', top: '5.5rem' }} >#Severe</div>,


    },
    {
        title1: 'Jason Bourne',
        data: '',
        img1: sngle1,
        content: (
            <div className='bookmark-content'>
                <img src={paid} alt='' className='Paid_bottom_tag bookmark-content-tag' />
                <p className='p-2 bookmark-content-caption' >The transition to the hybrid era has not only affected our ways of viewing leader throughout the prison...
                    <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2 Intertext'>International Politics</span>&nbsp;<Mic size={16} />5k
                    <div className='d-flex justify-content-between pt-3'>
                        <p className=''>12th Apr 2021, 5 mins</p>
                        {/* <p><img src={paid} alt='' className='Paid_bottom_tag' /></p> */}
                    </div>
                </p>

            </div>
        ),
        content1: (
            <div className='InternationalTexts' style={{
                position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.2)', // Transparent gray color
                color: 'white',
                top: '63px',
                fontSize: '13px',
                padding: '10px',
                height: '89px',
                width: '100%',
                boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
            }}
            >
                <div className='d-flex justify-content-between InternationalText pt-1 mt-2'>
                    <p style={{ marginTop: '-13px' }} className='TextWork'>International Work-life Podcast.....</p>
                    <p><button className='FollowRequest_Accept_btn btn btn-primary Allbtn ' style={{ marginRight: '-8px', marginTop: '-11px' }} >All Episodes</button></p>
                </div>
                <p style={{ margin: '-15px' }} className='pl-3 InternationalText Intertext mt-1'>International Politics <Circle size={7} fill='gray' /> Total episode..</p>
                <p><img src={twk} alt='' className='mt-3' /></p>
            </div>
        ),

    },
    {
        title1: 'Sufiya Eliza ',
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        texts: 'The transition to the hybrid era has not only affected our ways of viewing',
        img1: bookmark,
        // img1: Rpodcast,
        // img1:'https://wallpapers.com/images/hd/restaurant-background-2ez77umko2vj5w02.jpg',
        content: (
            <div className='bookmark-content'>
                <img src={paid} alt='' className='Paid_bottom_tag bookmark-content-tag' />
                <p className='p-2 bookmark-content-caption'>The transition to the hybrid era has not only affected our ways of viewing leader throughout the prison...
                    <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2 Intertext'>Episode-5</span>&nbsp;<Mic size={16} />5k
                    {/* <p><img src={mic1} alt='' style={{ marginTop: '-41px', marginLeft: '90px' }} /></p> */}
                    <div className='d-flex justify-content-between pt-3'>
                        <p className=''>12th Apr 2021, 5 mins</p>
                        {/* <p><img src={paid} alt='' className='Paid_bottom_tag' /></p> */}
                    </div>
                </p>
            </div>
        ),

        content1: (
            <div style={{
                position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.2)', // Transparent gray color
                color: 'white',
                top: '63px',
                fontSize: '13px',
                padding: '10px',
                height: '87px',
                width: '100%',
                boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
            }}
            >
                <div className='d-flex justify-content-between InternationalText pt-1 mt-2'>
                    <p style={{ marginTop: '-13px' }} className='TextWork'>International Work-life Podcast.....</p>
                    <p><button className='FollowRequest_Accept_btn btn btn-primary Allbtn ' style={{ marginRight: '-8px', marginTop: '-11px' }} >All Episodes</button></p>
                </div>
                <p style={{ margin: '-15px' }} className='pl-3 InternationalText Intertext mt-1'>International Politics <Circle size={7} fill='gray' /> Total episode..</p>
                <p><img src={twk} alt='' className='mt-3' /></p>
            </div>
        ),
    },
    // {
    //     title1: 'Jason Bourne',
    //     text11: <h4 style={{ fontWeight: '500px', fontFamily: 'Poppins' }} className='mt-1'>Celebration new album song launched</h4>,
    //     texts: <div><span style={{ color: '#81C14B' }}>#Musiccelebration,</span><span style={{ color: 'black' }}> #Musiccelebration, #music, #party, #musi</span></div>,
    //     data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
    // },
    {
        title1: 'Jason Bourne',
        data: '',
        // data3: <div className='d-flex justify-content-end p-1  w-100' style={{ fontSize: '15px', color: '#FFFFFF', backgroundColor: '#E0E0E0', }}><p style={{ paddingRight: '18px' }}>-&nbsp;&nbsp;Pepper Johnson Potts</p></div>,
        // though: <div className='d-flex justify-content-end p-1  w-100' style={{ fontSize: '15px', color: '#FF27C3', backgroundColor: '#E0E0E0', borderRadius: '0px 0px 5px 5px', height: '2.6rem' }}><p style={{ paddingRight: '18px' }}>#thoughtoftheday</p></div>,
        textData: <div className='px-4 sizeForText p-2 rounded-2 SingleMedia_Content_blk mt-3 ' style={{ backgroundColor: 'rgb(31, 78, 105)', color: 'rgb(248, 223, 136)', height: '11.5rem' }}>
            <i className="fa fa-quote-left" style={{ height: '25px', width: '25px', color: '#0075ff', marginTop: '1rem' }}></i>&nbsp;&nbsp;
            <p className='ml-3 text-center mt-1'>
                How far can you bend on drink CloudPlatform Provider Zoho Cloud Platform @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider ZohoCloud Provider...
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <p className="tex" style={{ paddingRight: '1rem' }}>
                    <i className="fa fa-quote-right" style={{ height: '25px', width: '25px', color: '#0075ff' }}></i>
                </p>
            </div>
        </div>

    },

    // {
    //     title1: 'Jason Bourne',
    //     alert: <div className='px-4 sizeForText p-2 rounded-2 ' style={{ backgroundColor: 'orange', color: 'white', height: '14rem' }}><img className='mt-4' src={left} alt="icon" /><p className='mt-2 ml-2 text-center'>How far can you bend on drink ingredients before you completely break a recipe? Back in the summer of 2019 we took a crowd favorite cocktail, the Fiery Mandarin, and incrementally swapped out quality ingredients for a bunch of random....</p><img src={right} alt="icon" style={{ paddingLeft: '17rem' }} /></div>,
    //     server: <div className="pvt-eventconts" style={{ background: '#FF822E' }} >#Thought</div>,
    //     imgs: left,
    //     imgss: right,

    // },
    {
        title1: 'Jason Bourne',
        data: '',
        // img1: Fr,
        textss: <h3 style={{ fontSize: '16px', marginTop: '-1.2rem', marginLeft: '1rem' }} className='p-1 pl-3  totaltext'><img src={poll} alt='' />&nbsp;Which is the best car of 2022?</h3>,
        textss1: <p style={{ fontSize: '12px', color: 'gray', marginLeft: '1rem', marginTop: '-0.6rem' }} className='p-1 pl-3 totaltext'>Total Participants: 234</p>,
        border1: <div className='p-2 pl-1 pr-1 cards' style={{ marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard ' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', padding: '5px', borderRadius: '20px' }}><p className='p-1'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;BMW 5 Series</p><p className='p-1'>30%</p></div></div>,
        border2: <div className='p-2 pl-1 pr-1 cards' style={{ marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', padding: '5px', borderRadius: '20px' }}><p className='p-1'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Lexus ES 200</p><p className='p-1'>20%</p></div></div>,
        border3: <div className='p-2 pl-1 pr-1 cards' style={{ marginTop: '-0.7rem' }}> <div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', padding: '5px', borderRadius: '20px' }}><p className='p-1'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Volvo</p><p className='p-1'>25%</p></div></div>,
        border4: <div className='p-2 pl-1 pr-1 cards' style={{ marginBottom: '20px', marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', padding: '5px', borderRadius: '20px' }}><p className='p-1'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Lexus ES</p><p className='p-1'>40%</p></div></div>
    },
    {
        title1: 'Jason Bourne',
        // text11: <h4 style={{ fontWeight: '500px', fontFamily: 'Poppins' }} className='mt-1'>Celebration new album song launched</h4>,
        texts: <div><span style={{ color: '#81C14B' }}>#Musiccelebration,#Musiccelebration, #music, #party, #musi</span></div>,
        data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry 1500s....',
    },
    {
        title1: 'Jason Bourne',
        data: '',
        img1: sngle1,
        content: (
            <div className='bookmark-content'>
                <p className='bookmark-content-caption' >The transition to the hybrid era has not only affected our ways of viewing leader  the prison...
                    <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2'>International Politics</span>&nbsp;<Mic size={16} />5k<br></br>
                    <div className='d-flex justify-content-between mt-2'>
                        <p className='p-1'>12th Apr 2021, 5 mins</p>
                        {/* <p><img src={paid} alt='' className='Paid_bottom_tag' /></p> */}
                    </div>
                </p>

            </div>
        ),
    },
    {
        title1: 'Jason Bourne',
        data: '',
        img1: bookmark,
        // img1: sngle1,
        content: (
            <div className='bookmark-content'>
                <p><img src={paid} alt='' className='Paid_bottom_tag bookmark-content-tag' /></p>
                <p className='p-2 bookmark-content-caption'>The transition to the hybrid era has not only affected our ways of viewing leader  the prison...
                    <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2'>International Politics</span>&nbsp;<Mic size={16} />5k
                    <div className='d-flex justify-content-between mt-2'>
                        <p className='p-1'>12th Apr 2021, 5 mins</p>
                        {/* <p><img src={paid} alt='' className='Paid_bottom_tag' /></p> */}
                    </div>
                </p>

            </div>
        ),
    },
    {
        title1: 'Jason Bourne',
        data: '',
        // img1: Fr,
        textss: <h3 style={{ fontSize: '16px', marginTop: '-1.2rem', marginLeft: '1rem' }} className='p-1 pl-3 totaltext'><img src={poll} alt='' />&nbsp;Which is the best car of 2022?</h3>,
        textss1: <p style={{ fontSize: '12px', color: 'gray', marginLeft: '1rem', marginTop: '-0.6rem' }} className='p-1 pl-3 totaltext'>Total Participants: 210</p>,
        border: <div className='p-2 pl-1 pr-1 position-relative cards' style={{ marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', borderRadius: '20px' }}><p style={{ position: 'absolute', width: '40%', background: 'rgb(255, 204, 0)', borderRadius: '20px', height: '40px' }} className='p-2'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Audio A6</p></div></div>,
        border1: <div className='p-2 pl-1 pr-1 position-relative cards' style={{ marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', borderRadius: '20px' }}><p style={{ position: 'absolute', width: '40%', background: 'rgb(255, 204, 0)', borderRadius: '20px', height: '40px' }} className='p-2'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;BMW 5 Series</p></div></div>,
        border2: <div className='p-2 pl-1 pr-1 position-relative cards' style={{ marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', borderRadius: '20px' }}><p style={{ position: 'absolute', width: '40%', background: 'rgb(255, 204, 0)', borderRadius: '20px', height: '40px' }} className='p-2'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Lexus ES 200</p></div></div>,
        border3: <div className='p-2 pl-1 pr-1 position-relative cards' style={{ marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', borderRadius: '20px' }}><p style={{ position: 'absolute', width: '40%', background: 'rgb(255, 204, 0)', borderRadius: '20px', height: '40px' }} className='p-2'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Volvo</p></div></div>,
        border4: <div className='p-2 pl-1 pr-1 position-relative cards' style={{ marginBottom: '20px', marginTop: '-0.7rem' }}><div className='mt-2  SingleMedia_Content_blk pollcard' style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgb(229, 240, 224)', width: '100%', height: '40px', borderRadius: '20px' }}><p style={{ position: 'absolute', width: '40%', background: 'rgb(255, 204, 0)', borderRadius: '20px', height: '40px' }} className='p-2'><Circle size={14} fill='green' style={{ border: '2px solid rgb(129, 193, 75) ', borderRadius: '50px', padding: '2px' }} />&nbsp;&nbsp;Lexus ES 200</p></div></div>
    },

]
const BookMarkCardSection = () => {
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const toggleReadMore = () => {
        setShowFullText(!showFullText);
    };
    const Commenttoggle = () => {
        SetComment(!comment)
    }
    const [showFullText, setShowFullText] = useState(false);
    const [BookmarkIcon, SetBookmark] = useState(false)
    const [seemore, setSeeMore] = useState(false);
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [comment, SetComment] = useState(false)
    const [commentR, SetCommentR] = useState('')
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    const [isOpen, setIsOpen] = useState(false);

    const toggleText = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };
    const [playbutton, setplaybutton] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(-1);

    const toggleContent = (index) => {
        setExpandedIndex(index === expandedIndex ? -1 : index);
    };

    return (
        <div>
            {AllPostType.map((item, index) => (
                <Card className='bg-white border-bottom-0 w-100 on-hover-bg d-flex justify-content-center mt-3 your-card-component cardtext' key={index}>
                    <div className='d-flex justify-content-between p-2 align-items-center'>
                        <div className='d-flex align-items-start mt-2'>
                            <img src={ca} className='img-fluid rounded-circle' width='40' height='40' alt='UserImage' />&nbsp;&nbsp;
                            <h4 className='text-nowrap'>
                                <span className='UserProfile-Name'>
                                    <Link to={`/MyProfile?userId=${user.id}&type=creater`} style={{ color: '#4D4D4D' }}>
                                        {item.title1}
                                        <br />
                                        <p style={{ color: 'gray', fontSize: '12px' }} className='mt-1 mintext'>20 Min ago</p>

                                        {/* {
                                            index === 0 ? (
                                                <>
                                                    <p style={{ color: 'gray', fontSize: '12px' }} className='mt-1 mintext'>20 Min ago</p>
                                                </>
                                            ) : null
                                        } */}

                                    </Link>
                                </span>
                            </h4><br></br>
                            <p >{item.mic}</p>
                            <div>
                                &nbsp;<span style={{ fontSize: "14px", color: 'rgba(77, 77, 77, 0.50)' }}>{item.text1}</span>&nbsp;
                                <span style={{ color: "rgba(77, 77, 77, 0.5)", fontSize: "14px" }}>{item.and}</span>
                                &nbsp;
                                <span style={{ fontSize: "14px" }}>{item.text2}</span>
                                &nbsp;<span style={{ color: "rgba(77, 77, 77, 0.5)", fontSize: "14px" }}>{item.text3}</span>&nbsp;
                                <span className='Profile_Location' style={{ fontWeight: "400" }}>
                                    {item.text4}
                                    {!seemore ? null : " Ali Ganj, Lucknow, India - 226012..."}
                                    <span style={{ cursor: 'pointer' }} className='text-primary' onClick={toggleText}>
                                        {item.icon}
                                    </span>
                                    {isOpen && <div>{item.text}</div>}
                                </span>
                            </div>
                        </div>
                        <div className='' onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '8px', backgroundColor: 'rgba(126, 178, 98, 0.1)', borderRadius: '5px', cursor: 'pointer' }}   >
                            {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                        </div>
                    </div>
                    <p className='pl-1'>{item.text11}</p>
                    <div className='SingleMedia_blk blkimg' >
                        <Link to={'/MyProfile'} >{item.img1 ? <div className='bookmark-post-card-image'><img src={item.img1} alt='singleMedia' className='img-fluid' /> {item.content && item.content}</div> : null}</Link>
                        <div>{item.server}</div>
                        <h5 style={{ cursor: 'pointer', color: '#4D4D4D' }} className='pl-1 mt-2'>{item.tomText}</h5>
                        <h5 style={{ cursor: 'pointer', color: '#4D4D4D' }} className=' pl-1 mt-2'>{item.toptext}</h5>
                        <h5 style={{ cursor: 'pointer' }} className='text-primary mt-2 pl-1'>{item.texts}</h5>
                        <h5 style={{ cursor: 'pointer' }} className='mt-2'>{item.textData}</h5>
                        <h3 className='pl-1'>{item.data1}</h3>
                        <p>{item.btn}</p>
                        {

                            index === 11 ? <div className='d-flex align-items-center justify-content-between p-1 ml-2'>
                                {playbutton ? <Play onClick={() => setplaybutton(!playbutton)} fill='#000000' /> :
                                    <Pause fill='#000000' onClick={() => setplaybutton(!playbutton)} />}
                                &nbsp;&nbsp;<Progress className='sizeForpro progressFortimeLine progress-bar-success w-100' value={35} />&nbsp;&nbsp;
                                <small style={{ marginRight: '22px' }}>02:45</small>
                            </div> : ""
                        }


                        <p color='gray' className='pl-1' style={{ fontSize: '13px' }}>{item.data2}</p>
                        {
                            index === 8 ? <p color='gray' className='pl-1' style={{ fontSize: '13px' }}>{item.data21}</p> : " "
                        }
                        {
                            index === 6 ? <p color='gray' className='pl-1' style={{ fontSize: '13px' }}>{item.data21}</p> : " "
                        }
                        {
                            index === 6 ? <p >{item.data22}</p> : ""
                        }
                        <p>{item.data3}</p>
                        <p>{item.though}</p>
                        {/* ==================play icon==================== */}
                        {/**/}
                        <div>
                            <div>{item.alert}</div>
                            <div className='text-right'>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div>

                            <p>{item.content1}</p>
                        </div>
                        {
                            index === 10 ? <div className='d-flex justify-content-center align-center'><img src={Frames} alt='' style={{ position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /></div> : ""
                        }
                        {
                            index === 11 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                        }
                        {
                            index === 15 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                        }
                        {
                            index === 16 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                        }
                        {
                            index === 4 ? <img src={item.play} alt="" style={{ position: 'absolute', top: '9rem', left: '42%' }} className='playicon' /> : ""
                        }
                    </div>
                    <p style={{ color: 'gray' }} className='pl-1 mt-2 card-content card-text'>
                        {showFullText ? item.data : item.data.slice(0, 150)}
                        {item.data.length > 150 && (
                            <span style={{ cursor: 'pointer' }} className='text-primary text-nowrap' onClick={toggleReadMore}>
                                {showFullText ? '.....Read Less' : '.....Read More'}
                            </span>
                        )}
                    </p>

                    <h3 className='mt-1'>{item.textss}</h3>
                    <p className='mt-2'>{item.textss1}</p>

                    <div className="poll-wrapper">
                        <div >{item.border}</div>
                        <div>{item.border1}</div>
                        <div>{item.border2}</div>
                        <div>{item.border3}</div>
                        <div>{item.border4}</div>
                    </div>
                </Card>
            ))
            }
        </div >
    )
}

export default BookMarkCardSection;
