import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Lock, Search, Users, Globe } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Button, CardImg, Col, CustomInput, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import GroupAvatar from './GroupAvatar'
import Cover from "../Img/cover.png"
import Profile from "../Img/Profile.png"
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { postInvitationToOwnedGroupAction, selectGroupById } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { data } from 'jquery'
import { sweetAlertConfirmation } from '../AllGroupsSections/SweetAlert'


const GroupDetailsTop = () => {
    const [friendsId, setFriendsId] = useState([])

    const { getGroupById } = useSelector((state) => { return state.getAllGroupByIdData });
    const { getInvitedPeopleToModalRequest } = useSelector((state) => { return state.getInvitedPeopleToModalData });

    const dispatch = useDispatch()
    const params = useParams()
    const totalMembers = params.totalMembers
    let grpId = params.id

    const inviteFriends = (e) => {
        let updatedList = [...friendsId]
        if (e.target.checked) {
            updatedList = [...friendsId, e.target.value]
        } else {
            updatedList.splice(friendsId.indexOf(e.target.value), 1)
        }
        setFriendsId(updatedList)
    };

    const sendInvitationData = () => {
        dispatch(postInvitationToOwnedGroupAction(grpId, friendsId))
        setInvitationOpen(false)
        setFriendsId('')
        sweetAlertConfirmation('Join Request Send')

    };

    useEffect(() => {
        dispatch(selectGroupById(params.id))
    }, [])
    const [invitationOpen, setInvitationOpen] = useState(false)
    const toggleInvitation = () => {
        setInvitationOpen((preview) => !preview)
    }
    //================== Invite_Array_Data ======================//
    // const InvitationData = [
    //     {
    //         img: Profile,
    //         name: "Samuel Taylor",
    //         userName: "samuel_taylor",
    //         id: "1"

    //     },
    //     {
    //         img: Profile,
    //         name: "Samuel Jackson",
    //         userName: "samuel_jackson",
    //         id: "2"

    //     },
    //     {
    //         img: Profile,
    //         name: "Samuel Dawson",
    //         userName: "samuel_dawson",
    //         id: "3"

    //     },
    //     {
    //         img: Profile,
    //         name: "Samuel Mcguire",
    //         userName: "samuel_mcguire",
    //         id: "4"

    //     }
    // ]
    const TopBanerData = () => (
        <Fragment>
            <div className="Group_Top_Cover_Section">
                <CardImg
                    src={getGroupById && getGroupById?.data?.successResult?.coverPic}
                    alt="cover_img"
                    className="img-fluid"
                    style={{ height: '350px', objectFit: 'cover' }}
                />
                <div className="">
                    {/* <div className="setting-dropdown btn-group custom-dropdown arrow-none dropdown-sm">
                        <a className="btn-white btn-cover" href="/" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            edit cover
                        </a>
                        <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                            <ul>
                                <li>
                                    <a href="/" data-bs-toggle="modal" data-bs-target="#choosePhoto"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>choose photo</a>
                                </li>
                                <li className="choose-file">
                                    <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>upload photo</a>
                                    <input type="file" />
                                </li>
                                <li>
                                    <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>remove photo</a>
                                </li>
                            </ul>
                        </div>
                    </div> */}

                </div>
                <div className="heading-title-blk head-title-biz">
                    <Row className="row ">
                        <div className="grp-preview">
                            <Col lg="12">
                                <Row>
                                    <Col lg="10">

                                        <div className="Group-user-pro-blk-new">
                                            <h5 className='text-capitalize'>{getGroupById && getGroupById?.data?.successResult?.name}</h5>
                                        </div>
                                    </Col>
                                    <Col lg="2">
                                        <div className='detail-buttons-blk'>
                                            <div>
                                                <p className='text-center' >{getGroupById?.data?.successResult?.postCount}</p>
                                                {getGroupById?.data?.successResult?.postCount > 1 ? <h4 className='text-muted'>Posts</h4> : <h4 className='text-muted'>Post</h4>}
                                                {/* <h4 className='text-muted'>Posts</h4> */}
                                            </div>
                                            <div className='ml-3'>
                                                <p className='text-center'>
                                                    {totalMembers && totalMembers > 0
                                                        ? <div>
                                                            <NavLink to=""><h3 style={{ fontSize: '21px' }}> {totalMembers} {totalMembers > 1 ? <h4 className='text-muted'>Members</h4> : <h4 className='text-muted'>Member</h4>}</h3></NavLink>
                                                        </div> : <h4 className='text-muted'>0 Member</h4>}
                                                </p>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Col xl="8" lg="8" className="col-12 order-lg-1">
                            <div className="group-content-area-top-blk">

                                {/* <div className="groupmember-blk">
                                    <span><Lock style={{marginTop:"-3px"}} size={14} color="#808080" /> {getGroupById && getGroupById.data.successResult.visibility}</span> • <span className='text-capitalize'>{getGroupById && getGroupById.data.successResult.grpCategory.name}  </span>
                                </div> */}

                                <div className="groupmember-blk">
                                    <span>
                                        {getGroupById && getGroupById.data.successResult.visibility === 'public' ? (
                                            <Lock style={{ marginTop: "-3px", marginRight: "4px" }} size={14} color="#808080" />
                                        ) : (
                                            <Globe style={{ marginTop: "-3px", marginRight: "4px" }} size={14} color="#808080" />
                                        )}
                                        {getGroupById && getGroupById.data.successResult.visibility}
                                    </span> •{" "}
                                    <span className="text-capitalize">
                                        {getGroupById && getGroupById.data.successResult.grpCategory.name}
                                    </span>
                                </div>

                                <div className="people-likes matual-friend-sec Group_Avatar_section">
                                    {totalMembers && totalMembers > 0
                                        ? <div>
                                            <GroupAvatar />
                                            <NavLink to=""><h6> {totalMembers} {totalMembers > 1 ? 'Members' : 'Member'}</h6></NavLink>
                                        </div> : 'No members'}

                                </div>
                                <div className='Group_Detail_paragraph_section' >
                                    <p className='text-capitalize'>{getGroupById && getGroupById.data.successResult.description}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl="4" lg="4" className="col-12 order-lg-3">
                            <div className="create-biz-btn">
                                <Button onClick={toggleInvitation} className="Group_Detail_Invite_btn" >
                                    <Users color='#FFFFFF' size={16} />&nbsp;
                                    <span className='align-middle ms-25'>Invite</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    )

    // ================== main_return_function==================//
    return (
        <Fragment>
            <div className="group-details-top biz-details-top biz-cration-preview">
                <TopBanerData />
                <div className='Send_Invitation_Modal'>
                    {
                        invitationOpen !== false &&
                        <Modal scrollable isOpen={invitationOpen} toggle={toggleInvitation} className='modal-dialog-centered'  >
                            <ModalHeader className='d-flex justify-content-center'>
                                <div className='Invitation_Heading_Section'>
                                    <h4>Send Invitation</h4>
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md="12">
                                        <div className='Invite_SocioMate_Heading'>
                                            <h4>Invite from SocioMate</h4>
                                        </div>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="12">
                                        <div className="search-block-podcast">
                                            <div className="input-search-blk">
                                                <Search className="search-svg" />
                                                <Input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md="12">
                                        <div className='Invitaion_List_Heading'>
                                            <h4>{getInvitedPeopleToModalRequest?.length ? 'Friends (' + getInvitedPeopleToModalRequest?.length + ')' : ''}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        {
                                            getInvitedPeopleToModalRequest && getInvitedPeopleToModalRequest.map((data) => (
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <div className='d-flex align-items-center '>
                                                        <img style={{ width: '40px', borderRadius: '50px' }} src={data.profileImageThumb} alt="profile" className='img-fluid' />
                                                        <div className='Invitaion_User_Profile ml-1'>
                                                            <h4>{data.fullName}</h4>
                                                            <p>{data.userName}</p>
                                                        </div>
                                                    </div>
                                                    <div className='Block-btns-blk'>
                                                        <CustomInput
                                                            id={data.id} style={{ cursor: "pointer" }}
                                                            value={data.id}
                                                            defaultChecked={!!friendsId[data?.id]}
                                                            onChange={(e) => {
                                                                inviteFriends(e);
                                                            }}
                                                            type="checkbox"
                                                            className='customcheckboxForFilter'

                                                        />
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md="12">
                                        <div className='d-flex justify-content-center'>
                                            {
                                                getInvitedPeopleToModalRequest?.length
                                                    ?
                                                    <Button onClick={sendInvitationData} className="Invitation_Send_btn">
                                                        Send </Button>
                                                    :
                                                    <p className='text-danger'>No contacts exist to show</p>

                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    }
                </div>
            </div>

        </Fragment>
    )
}

export default GroupDetailsTop