import { Pagination, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BizFollowedCard from './BizFollowedCard';
import BizOwnedCard from './BizOwnedCard';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getAllUserOwnPinnedBizPageReducerAction, getAllUserOwnFollowedBizPageReducerAction, getCategoryListReducerAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';

const BizTabSection = (props) => {
    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [pageIndex1, setPageIndex1] = useState(0);
    const [pageSize1, setPageSize1] = useState(12);
    const [success,setSuccess]=useState(false);

    const getPinnedBusinessPage = useSelector((state) => state.getAllUserOwnPinnedBizPageData);
    const { loading: loadingPinnedPage, error: errorPinnedPage, result: resultPinnedPage } = getPinnedBusinessPage;

    const getFolloweddBusinessPage = useSelector((state) => state.getAllUserOwnFollowedBizPageData);
    const { loading: loadingFollowedPage, error: errorFollowedPage, result: resultFollowedPage } = getFolloweddBusinessPage;

    let dispatch = useDispatch();

    useEffect(() => {
        if(type=="creator"){
            dispatch(getAllUserOwnFollowedBizPageReducerAction(pageIndex, pageSize, props?.searchKey, userId));
            setSuccess(false)
        }
    }, [dispatch, pageIndex, pageSize, props?.searchKey, userId,success]);

    useEffect(() => {
        dispatch(getAllUserOwnPinnedBizPageReducerAction(pageIndex1, pageSize1, props?.searchKey, userId));
        setSuccess(false)
    }, [dispatch, pageIndex1, pageSize1, props?.searchKey, userId,success]);

    const TabSectionData = [
        {
            className: "nav-item nav-link active",
            id: "AllTab",
            link: "#nav-All",
            ariaControls: "all",
            ariaSelected: "true",
            TabName: `Owned BizPage (${resultPinnedPage?.length ?? 0})`
        },
        {
            className: "nav-item nav-link",
            id: "ImageTab",
            link: "#nav-image",
            ariaControls: "nav-image",
            ariaSelected: "false",
            TabName: `Followed BizPage (${resultFollowedPage?.length ?? 0})`
        }
    ];

    const filteredTabSectionData = TabSectionData.filter(tab => !(type === "viewer" && tab.id === "ImageTab"));

const followedCategoryIds = resultFollowedPage?.map(item => item.bpCategoryId) || [];
const pinnedCategoryIds = resultPinnedPage?.map(item => item.bpCategoryId) || [];
const combinedCategoryIds = [...followedCategoryIds, ...pinnedCategoryIds];

useEffect(()=>{
    if(resultPinnedPage && resultFollowedPage){
        props?.setList(combinedCategoryIds);
    }

},[resultPinnedPage,resultFollowedPage])



    return (
        <Fragment>
            <nav>
                <div className="tab-section">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {
                            filteredTabSectionData.map((tabinfo) => (
                                <li className="nav-item" key={tabinfo.id}>
                                    <Link className={tabinfo.className} id={tabinfo.id} data-bs-toggle="tab"
                                        to={tabinfo.link} role="tab" aria-controls={tabinfo.ariaControls} aria-selected={tabinfo.ariaSelected}>{tabinfo.TabName}</Link>
                                </li>
                            ))
                        }
                    </ul>
                    <div className="tab-content" id="nav-tabContent">
                        {loadingPinnedPage ? (
                            <>
                            <br/><br/>
                            <Spinner
                                animation='border'
                                role='status'
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    margin: 'auto',
                                    display: 'block',
                                    color: '#81C14B'
                                }}
                            >
                                <span className='sr-only'>Loading...</span>
                            </Spinner>
                            <br/>
                            </>
                        ) : (
                            <>
                                {resultPinnedPage?.length > 0 || resultFollowedPage?.length > 0 ? (
                                    <>
                                        <div className="tab-pane fade show active" id="nav-All" role="tabpanel" aria-labelledby="AllTab">
                                            <BizOwnedCard data={resultPinnedPage} count={resultPinnedPage?.length} setPageIndex={setPageIndex} pageIndex={pageIndex} setSuccess={setSuccess} categoryId={props?.categoryId}/>
                                        </div>
                                        <div className="tab-pane fade" id="nav-image" role="tabpanel" aria-labelledby="ImageTab">
                                            <BizFollowedCard data={resultFollowedPage} count={resultFollowedPage?.length} setPageIndex1={setPageIndex1} pageIndex1={pageIndex1} setSuccess={setSuccess} categoryId={props?.categoryId} />
                                        </div>
                                    </>
                                ) : (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '15vh',
                                        width: '100%'
                                    }}>
                                        <h2 style={{ textAlign: 'center' }}>No Business Page</h2>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </nav>
        </Fragment>
    );
}

export default BizTabSection;
