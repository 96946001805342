import * as types from "../../Constants/Common/index.js";

const initialState = { 
    taggedPeople: [],
    loading: true,
};

 const TaggedPeopleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_TAGGED_PEOPLE:
            return {
                ...state,
                taggedPeople: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default TaggedPeopleReducer;