import React from 'react'
import { Fragment } from 'react'
import EventsTopBanner from './Components/EventsTopBanner'
import EventTabMenu from './Components/EventTabMenu'
import TodayEvents from './Components/TodayEvents'


const events = () => {
    return (
        <Fragment>
            <EventsTopBanner />
            <EventTabMenu />
        </Fragment>

    )
}

export default events