import * as types from "../../Constants/UserProfile/index";
import axios from 'axios';
// import { uploadProfilePicture } from "./postProfileCover";


export const loadAllUserHobbiesAction = (search) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_HOBBIES });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getHobbies`,
            { userId: user.id, searchKey:search },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_HOBBIES_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_HOBBIES_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const sendOtp = async (dispatch,getState)=>{
    try {
        
    } catch (error) {
        
    }
  
};




export const loadAllAvailableHobbiesAction = (search) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_AVAILABLE_HOBBIES });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/admin/getAllHobbies`,
            { userId: user.id, searchKey:search },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_AVAILABLE_HOBBIES_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_AVAILABLE_HOBBIES_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};


export const postAllUserHobbiesAction = (selectedHobbies) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.POST_USER_HOBBIES });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addHobbies`,
            { hobbyIds: selectedHobbies },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        if (data.success === false) {
            dispatch({ type: types.POST_USER_HOBBIES_SUCCESS, payload: data.data.errorResult });

        } else if (data.success === true) {
            dispatch({ type: types.POST_USER_HOBBIES_SUCCESS, payload: data.data });
            dispatch(loadAllUserHobbiesAction())
        }

    } catch (error) {
        dispatch({
            type: types.POST_USER_HOBBIES_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllAdminInterestAction = (search) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_ADMIN_INTEREST });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/admin/getAllInterests`,
            { userId: user.id, searchKey:search  },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_ADMIN_INTEREST_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_ADMIN_INTEREST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllUserInterestAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_INTEREST });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getInterests`,
            { userId: user.id},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_INTEREST_SUCCESS, payload: data.data.successResult.rows });
        //console.log("intresteddfgggbrb", data.data.successResult.rows)

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_INTEREST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const postAllUserInterestAction = (interests) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.POST_ALL_USER_INTEREST });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addInterests`,
            { userId: user.id, interestIds: interests },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.POST_ALL_USER_INTEREST_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.POST_ALL_USER_INTEREST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const gatAllAdminSportsAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_ADMIN_SPORTS });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getAllSports`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_ADMIN_SPORTS_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_ADMIN_SPORTS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const gatAllUserSportsAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_SPORTS });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getSports`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_SPORTS_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_SPORTS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const postAllUserSortsAction = (selectedHobbies) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.POST_ALL_USER_SPORTS });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addSports`,
            { userId: user.id, sportsId: selectedHobbies, addSport: true },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.POST_ALL_USER_SPORTS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: types.POST_ALL_USER_SPORTS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllUserMoviesAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_MOVIES });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getAllMovies`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_MOVIES_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_MOVIES_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllUserMusicAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_MUSIC });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getMusic`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_MUSIC_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_MUSIC_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllUserBookAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_BOOK });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getAllBooks`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_BOOK_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_BOOK_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};



export const getAllUserBizPageAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_BIZPAGE });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getUserBusinessPages`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_USER_BIZPAGE_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_USER_BIZPAGE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};







export const getAllSchoolAction = (search, sortBy) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_SCHOOL, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getSchoolCollege`,
            { userId: user.id, sortBy: sortBy, searchKey:search},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_SCHOOL_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_SCHOOL_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};
 
//
// export const addSchoolAction = (school) =>async(dispatch,getDtate) =>{
//     try {
//         let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
//         dispatch({ type: types.GET_ALL_SCHOOL, payload:true });
//         let { data } = await axios.post(
//             `${process.env.REACT_APP_IPURL}/user/addSchoolCollege`,
//             { name, location, educationType, educationUrl },
//             {
//                 headers: { Authorization: `Bearer ${user?.token}` }
//             })
//         dispatch({ type: types.ADD_SCHOOL_COLLEGE_SUCCESS, payload: data.data.successResult.rows });

//     } catch (error) {
//         dispatch({
//             type: types.ADD_SCHOOL_COLLEGE_SUCCESS,
//             payload:
//                 error.response && error.response.data.message
//                     ? error.response.data.message
//                     : error.message,
//         })
//     }
// }


export const addProfessionAction = (profession) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.ADD_PROFESSION });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/admin/addProfessions`,
            { userId: user.id, name: profession.professionId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.ADD_PROFESSION_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.ADD_PROFESSION_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const addSchoolCollegeAction = (name, location, type) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.ADD_SCHOOL_COLLEGE });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addSchoolCollege`,
            { name: name, location: location, educationType: type },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log(" jhdfvjhvgbjhjhjh",data.data)
        dispatch({ type: types.ADD_SCHOOL_COLLEGE_SUCCESS, payload: data.data.success });

    } catch (error) {
        dispatch({
            type: types.ADD_SCHOOL_COLLEGE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getQualificationAction = (search) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_QUALIFICATION });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getQualification`,
            { searchKey: search },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_QUALIFICATION_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.GET_QUALIFICATION_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const addQualificationAction = (qualification) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.ADD_QUALIFICATION });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addQualification`,
            { qualification: qualification },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.ADD_QUALIFICATION_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.ADD_QUALIFICATION_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const addUserEducationAction = (qualificationsId, educationId, description, fromDate, toDate, isGraduate) => async (dispatch, getState) => {


    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.ADD_USER_EDUCATION });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addUserEducation`,
            {
                qualificationsId: qualificationsId,
                educationId: educationId,
                description: description,
                fromDate: fromDate,
                toDate: toDate,
                isGraduate: isGraduate,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.ADD_USER_EDUCATION_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.ADD_USER_EDUCATION_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const updateUserEducationAction = (id,educationId, qualificationsId, description, fromDate, toDate, isGraduate) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/updateUserEducation`,
            {
                id:id,
                education_id: educationId,
                qualifications_id: qualificationsId,
                description: description,
                fromDate: fromDate,
                toDate: toDate,
                isGraduate: isGraduate,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            }
        );
        dispatch({ type: types.UPDATE_USER_EDUCATION_SUCCESS, payload: data.data.successResult });
    } catch (error) {
        dispatch({
            type: types.UPDATE_USER_EDUCATION_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteUserEducationAction = (eId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // Dispatch action to indicate deletion in progress
        // dispatch({ type: types.DELETE_USER_EDUCATION });

        // Make API call to delete user education
        let educationId=[];
        educationId.push(eId);
        const { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/deleteUserEducation`,
            { educationId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            }
        );

        // Dispatch action to indicate successful deletion
        dispatch({ type: types.DELETE_USER_EDUCATION_SUCCESS, payload: data });
        

    } catch (error) {
        // Dispatch action to indicate deletion error
        dispatch({
            type: types.DELETE_USER_EDUCATION_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Media Starts Here

export const getAllPostsByUserAction = (pageIndex, pageSize,userId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_POST_USER_MADE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getAllPosts`,
            {
                userId: userId||user.id,
                searchKey: "",
                pageIndex,
                pageSize
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_POST_USER_MADE_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_POST_USER_MADE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const postHideUnHideAction = (postId, isHidden) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_HIDE_UNHIDE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/setPostHide`,
            {
                userId: user.id,
                postId: postId,
                isHidden: isHidden
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.POST_HIDE_UNHIDE_SUCCESS, payload: data.data.successResult });
    } catch (error) {
        dispatch({
            type: types.POST_HIDE_UNHIDE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const deletePostAction = (postId) => async (dispatch, getState) => {  
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.DELETE_POST, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/deletePost`,
            {
                postId: postId,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('DeletedDataACTION', data)
            dispatch({ type: types.DELETE_POST_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        console.log('DeletedDataActionerror', error)
        dispatch({
            type: types.DELETE_POST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const inviteFriendsToEventAction = (frndId,postId) => async (dispatch, getState) => { 
    console.log('Invite Friends ', frndId,postId)
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.INVITE_FRIENDS_TO_EVENT, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/inviteFriendsToEvent`,
            {
                inviteTo: [frndId],
                eventId : postId
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('invitationResultAction', data)
            dispatch({ type: types.INVITE_FRIENDS_TO_EVENT_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.INVITE_FRIENDS_TO_EVENT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const hideAllPostsFromTimelineAction = (postId) => async (dispatch, getState) => {
    // console.log('hiddenPostIds', postId)
    try {
      const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
      dispatch({ type: types.HIDE_ALL_POSTS_FROM_TIMELINE, payload: true });  
      const { data } = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/hideAllSelectedPost`,
        {
          postId: [postId],
        },
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );
      console.log('hiddenPostIdsAction', data)
      dispatch({
        type: types.HIDE_ALL_POSTS_FROM_TIMELINE_SUCCESS,
        payload: data.data.successResult,
      });
  
    } catch (error) {
      dispatch({
        type: types.HIDE_ALL_POSTS_FROM_TIMELINE_ERROR,
        payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const postShowAction = (postId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.UNDO_SHOW_POST, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/undoPostHide`,
            {
                postId:  Array.isArray(postId) ? postId : [postId],
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.UNDO_SHOW_POST_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.UNDO_SHOW_POST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};


export const getUserFollowerAction = (pageIndex,pageSize,countryIds) => async (dispatch, getState) => {
    // console.log('im here***',pageIndex,pageSize, apiRoute, sortBy)
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_USER_FOLLOWERS_SHARE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
            {
                userId: user.id,
                searchKey: "",
                pageIndex,
                pageSize,
                countryIds
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

        dispatch({ type: types.GET_USER_FOLLOWERS_SHARE_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_USER_FOLLOWERS_SHARE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getUserConnectionAction = (pageIndex, pageSize) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_USER_CONNECTIONS, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getRelationCategory`,
            {
                pageIndex,
                pageSize,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_USER_CONNECTIONS_SUCCESS, payload: data.data.successResult.data.rows });

    } catch (error) {
        dispatch({
            type: types.GET_USER_CONNECTIONS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};


export const postReShareAction = (postId, caption, friendsId, allowComments, locationLAT, locationLONG) => async (dispatch, getState) => {
console.log('******', postId, caption, friendsId, allowComments, locationLAT, locationLONG)
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_RE_SHARE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/reSharePost`,
            {
                userId: user.id,
                postId: postId,
                caption: caption,
                reShareId: friendsId,
                allowComments: allowComments,
                locationLAT: locationLAT,
                locationLONG: locationLONG
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('**********', data.data)
        dispatch({ type: types.POST_RE_SHARE_SUCCESS, payload: data.data.successResult ? data.data.successResult :data.data.errorResult });
    } catch (error) {
        dispatch({
            type: types.POST_RE_SHARE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllUserOwnPinnedBizPageReducerAction = (pageIndex,pageSize,searchKey,userId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_OWNED_PINNED_BUSINESSPAGE });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllListOwnedPinnedBusinessPage`,
            { pageIndex,pageSize,searchKey,userId: userId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
//console.log("first-----------------------", data)
        dispatch({ type: types.GET_ALL_OWNED_PINNED_BUSINESSPAGE_SUCCESS, payload: data.data.successResult.data.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_OWNED_PINNED_BUSINESSPAGE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getAllUserOwnFollowedBizPageReducerAction = (pageIndex,pageSize,searchKey,userId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_PINNED_BIZPAGE });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllListFollowingPinnedBusinessPage`,
            { pageIndex,pageSize,searchKey,userId},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            //console.log("second-----------------------", data)
        dispatch({ type: types.GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_SUCCESS, payload: data.data.successResult.data.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};


// getPeopleListReducer

export const getPeopleListReducerAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_PINNED_BIZPAGE });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getPeopleList`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

        dispatch({ type: types.GET_PEOPLE_LIST_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_PEOPLE_LIST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};


//GET ALL CATEGORY LIST OF BIZPAGE
export const getCategoryListReducerAction = (pageIndex, pageSize) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_ALL_USER_PINNED_BIZPAGE });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/getAllCategory`,
            {
                userId: user.id,
                searchKey: "",
                pageIndex,
                pageSize,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

        dispatch({ type: types.GET_ALL_CATEGORYLIST_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_CATEGORYLIST_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const getEventsAction = (type) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_EVENTS_BY_USER, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getUserEvents`,
            {
                    userId :user.id,
                    type:type
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_EVENTS_BY_USER_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        dispatch({
            type: types.GET_EVENTS_BY_USER_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

//GET PODCAST LIST
export const getTextPostsAction = (posttype, pageSize, pageIndex, searchKey,userId ) => async (dispatch, getState) => {

    if(posttype=="media"){
        const postType=posttype;
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            dispatch({ type: types.GET_TEXT_POSTS, payload:true });
            let { data } = await axios.post(
                `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                {
                    postType, pageSize, pageIndex,searchKey ,userId
                },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                dispatch({ type: types.GET_TEXT_POSTS_SUCCESS, payload: data.data.successResult.rows});
    
        } catch (error) {
            dispatch({
                type: types.GET_TEXT_POSTS_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }

    }else if(posttype=="images"){
        const postType=posttype;
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            dispatch({ type: types.GET_IMAGE_POSTS, payload:true });
            let { data } = await axios.post(
                `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                {
                    postType, pageSize, pageIndex, searchKey, userId
                },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                dispatch({ type: types.GET_IMAGE_POSTS_SUCCESS, payload: data.data.successResult.rows});
    
        } catch (error) {
            dispatch({
                type: types.GET_IMAGE_POSTS_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
    }else{
        const postType=posttype;
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            dispatch({ type: types.GET_TEXT_POSTS, payload:true });
            let { data } = await axios.post(
                `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                {
                    postType, pageSize, pageIndex,searchKey, userId
                },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                dispatch({ type: types.GET_TEXT_POSTS_SUCCESS, payload: data.data.successResult.rows});
    
        } catch (error) {
            dispatch({
                type: types.GET_TEXT_POSTS_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
    }
    
};

export const getAllUserPollAction = (postType, pageIndex ,pageSize ) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALLPOLLL, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getPollByPostType`,
            {
                type:postType, pageSize:pageSize, pageIndex:pageIndex,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_ALLPOLL_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        dispatch({
            type: types.GET_ALLPOLL_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getRecommendationPostsAction = (postType, pageSize, pageIndex,searchKey,userId ) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        // dispatch({ type: types.GET_RECOMMENDATION_POSTS, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
            {
                postType, pageSize, pageIndex, searchKey, userId
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_RECOMMENDATION_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        dispatch({
            type: types.GET_RECOMMENDATION_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getAllCommentsByPostIdAction = (postId, commentId, searchKey, pageIndex, pageSize ) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_COMMENTS, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getComments`,
            {
                postId,
                commentId,
                searchKey,
                pageIndex,
                pageSize,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('myComment', data.data.successResult.rows)
            dispatch({ type: types.GET_ALL_COMMENTS_SUCCESS, payload: data.data.successResult.rows});

    } catch (error) {
        dispatch({
            type: types.GET_ALL_COMMENTS_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const postAddAllCommentsByPostIdAction = (postId, comment, commentId, isSubComment ) => async (dispatch, getState) => {

    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_ADD_COMMENT, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/addComment`,
            {
                postId,
                comment,
                commentId,
                isSubComment,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('AddCommentFromAction', data)
            dispatch({ type: types.POST_ADD_COMMENT_SUCCESS, payload: data.data.successResult.createdAt});

    } catch (error) {
        dispatch({
            type: types.POST_ADD_COMMENT_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getAllLikesByPostIdAction = (postId ) => async (dispatch, getState) => {

    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_LIKE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getAllLike`,
            {
                postId,
 
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_ALL_LIKE_SUCCESS, payload: data.data.successResult.rows});

    } catch (error) {
        dispatch({
            type: types.GET_ALL_LIKE_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getAllShares = (postId ,pageIndex1,pageSize) => async (dispatch, getState) => {

    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_SHARE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getAllShare`,
            {
                postId,
                pageIndex:pageIndex1,
                pageSize:pageSize
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_ALL_SHARE_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        dispatch({
            type: types.GET_ALL_SHARE_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

//marketplace data

export const getMarketPlaceProductsAction = (lat,long) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_MARKET_PLACE_PRODUCTS, payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/marketPlace/getMpPostByType`,
            {
                userId: user.userId,
                typeArr: ["all", "pending", "rejected", "onsale", "sold"]
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            }
        );
        dispatch({ type: types.GET_MARKET_PLACE_PRODUCTS_SUCCESS, payload: data.data.successResult.rows });
    } catch (error) {
        dispatch({
            type: types.GET_MARKET_PLACE_PRODUCTS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
    
};


export const shareShotzAction = (postId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.SHARE_SHOTZ, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/addShare`,
            {
                    postId : postId,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.SHARE_SHOTZ_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        dispatch({
            type: types.SHARE_SHOTZ_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const turnOnOffNotificationTextPostsAction = (postId, notificationOff) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.TURN_ON_OFF_NOTIFICATION_TEXT_POST, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
            {
                    postId : postId,
                    notificationOff :notificationOff
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.TURN_ON_OFF_NOTIFICATION_TEXT_POST_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        dispatch({
            type: types.TURN_ON_OFF_NOTIFICATION_TEXT_POST_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getCategoryListAction = (pageSize, pageIndex) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_CATEGORYLIST, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/getAllCategory`,
            {
                pageSize, 
                pageIndex
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_ALL_CATEGORYLIST_SUCCESS, payload: data.data.successResult.rows});

    } catch (error) {
        dispatch({
            type: types.GET_ALL_CATEGORYLIST_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};


export const getSubCategoryListAction = (pageSize, pageIndex, categoryId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_SUB_CATEGORY, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/getAllSubCategory`,
            {
                pageSize, 
                pageIndex,
                categoryId
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.GET_ALL_SUB_CATEGORY_SUCCESS, payload: data.data.successResult.rows});

    } catch (error) {
        dispatch({
            type: types.GET_ALL_SUB_CATEGORY_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const createBussinessPageAction = (createBizPage) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.CREATE_BIZ_PAGE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/create/businessPage`,
            {
                userId : user.id,
                bpCategoryId: createBizPage.bpCategoryId,
                bpSubCategoryId: createBizPage.bpSubCategoryId,
                categoryId:createBizPage.bpCategoryId,
                name:createBizPage.name,
                summary: createBizPage.summary,
                bpaddress: createBizPage.bpaddress,
                role: "admin",
                logo: createBizPage?.coverImages?.[1]?.coverUrl,
                logoThumb: createBizPage?.coverImages?.[0]?.coverUrl,
                status: 1,
                visitorCount: 5,
                operatingStatus: "",
                isPrivate: "",
                blockedByAdmin: "",
                blockCode: "",
                blockMessage: "",
                visibility: 1,
                allowCall: 0,
                allowMessage: 1,
                allowSharing: 1,
                allowPageSuggestion: 1,
                allowQuestion: 1,
                allowNotificationOnEmail: 1,
                allowNotificationOnSms: 0,
                allowNotification: 1,
                coverImages: [
                    {
                        coverUrl: createBizPage?.coverImages?.[0]?.coverUrl,
                    },
                    {
                        coverUrl: createBizPage?.coverImages?.[1]?.coverUrl,
                    }
                ],
                address: [
                    {
                        address: "STREET 456, Los Angeles",
                        country: "US",
                        city: "Los Angeles",
                        zipCode: "6891",
                        locationLAT: "33.4",
                        locationLONG: "12.34",
                        isDefault:1
                    }
                ]
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.CREATE_BIZ_PAGE_SUCCESS, payload: data.data.successResult});

    } catch (error) {
        console.log('error', error)
        dispatch({
            type: types.CREATE_BIZ_PAGE_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const bizPageNotificationAction = (bpPostId, allow) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.BIZ_PAGE_NOTIFICATION, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/allowPostNotif`,
            {
                bpPostId :bpPostId,
                allow : allow
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.BIZ_PAGE_NOTIFICATION_SUCCESS, payload: data.data.successResult.length})

    } catch (error) {
        dispatch({
            type: types.BIZ_PAGE_NOTIFICATION_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const bizPinnedAction = (businessPageId, allowQuestion,allowMessage,visibility,allowPageSuggestion, pin) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.BIZ_PAGE_PINNED, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/generalSettings`,
            {
                businessPageId:businessPageId,
                allowMessage:allowQuestion,
                allowQuestions:allowMessage,
                visibility:visibility,
                pageSuggestions:allowPageSuggestion,
                pin:pin
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.BIZ_PAGE_PINNED_SUCCESS, payload: data.data.successResult.businessPage.length})
        } catch (error) {
            dispatch({
                type: types.BIZ_PAGE_PINNED_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};


export const addCataloagueAction = (catelogueName, id, video, imageCatalogue , catelogueDescription, image, videoCatelogue, catelogueDescriptionVideo) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.ADD_BIZ_PAGE_CATALOGUE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/createCatalogue`,
            {
                catalogueName:catelogueName,
                businessPageId:id,
                media:[{
                    mediaType:video,
                    mediaURL:videoCatelogue,
                    thumbURL:imageCatalogue,
                    capation:catelogueDescriptionVideo
                },
                {
                    mediaType:image,
                    mediaURL:imageCatalogue,
                    thumbURL:imageCatalogue,
                    capation:catelogueDescription
                }
                ]
             },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.ADD_BIZ_PAGE_CATALOGUE_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.ADD_BIZ_PAGE_CATALOGUE_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};


export const getBizPageCatalogueAction = (businessPageId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_BIZ_PAGE_CATALOGUE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/showCatalogue`,
            {
                businessPageId : businessPageId
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_BIZ_PAGE_CATALOGUE_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.GET_BIZ_PAGE_CATALOGUE_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const getBizPageReviewAction = (businessPageId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_BIZ_PAGE_REVIEW, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/review`,
            {
                businessPageId: businessPageId,
                sortBy:"newest",
                pageIndex:1,
                pageSize:2
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_BIZ_PAGE_REVIEW_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.GET_BIZ_PAGE_REVIEW_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const getBizPageRatingSummaryAction = (businessPageId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_BIZ_PAGE_RATING_SUMMARY, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/showOverView`,
            {
                businessPageId : businessPageId
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_BIZ_PAGE_RATING_SUMMARY_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.GET_BIZ_PAGE_RATING_SUMMARY_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const addBizPageRatingAndReviewsAction = (ratingPoints, remark, mediaURL1, mediaURL2, helpfullCount, businessPageId) => async (dispatch, getState) => {
    console.log('Hello world', ratingPoints, remark, mediaURL1, mediaURL2, helpfullCount, businessPageId)
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.ADD_BIZ_PAGE_RATING_AND_REVIEW, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/createReview`,
            {
                ratingPoint:ratingPoints,
                remark:remark,
                mediaURL:[{
                    mediaURL:mediaURL1
                },
                {
                    mediaURL:mediaURL2
                }],
                helpfullCount:helpfullCount,
                businessPageId:businessPageId,
                userId:user.id
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('Review Data', data)
            dispatch({ type: types.ADD_BIZ_PAGE_RATING_AND_REVIEW_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.ADD_BIZ_PAGE_RATING_AND_REVIEW_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const addBizPageQuestionAction = (businessPageId, question) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.ADD_BIZ_PAGE_QUESTION, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/askQuestion`,
            {
                businessPageId:businessPageId,
                bpAdminId:user.id,
                question:question
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.ADD_BIZ_PAGE_QUESTION_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.ADD_BIZ_PAGE_QUESTION_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};


export const getAllQuestionAndAnswerAction = (businessPageId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_All_QUESTION_AND_ANSWER, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/getViewerFAQ`,
            {
                businessPageId:businessPageId,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_All_QUESTION_AND_ANSWER_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.GET_All_QUESTION_AND_ANSWER_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};


export const getAboutUsAction = (businessPageId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ABOUT_US, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/aboutUs`,
            {
                businessPageId:businessPageId,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('aboutInfoAction', data.data.successResult)
            dispatch({ type: types.GET_ABOUT_US_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.GET_ABOUT_US_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const postPinOrUnpinBizPageAction = (businessPageId, allowMessage, allowQuestions, visibility, pageSuggestions, pin) => async (dispatch, getState) => {
    console.log(pin, "pin");
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_PIN_OR_UNPIB_BIZPAGE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/generalSettings`,
            {                
                businessPageId:businessPageId,
                allowMessage:allowMessage,
                allowQuestions:allowQuestions,
                visibility:visibility,
                pageSuggestions:pageSuggestions,
                pin:pin               
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.POST_PIN_OR_UNPIB_BIZPAGE_SUCCESS, payload: data.data.successResult})
        } catch (error) {
            dispatch({
                type: types.POST_PIN_OR_UNPIB_BIZPAGE_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const deleteBizPageAction = (businessPageId, password) => async (dispatch, getState) => {
     try {
         let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
         dispatch({ type: types.DETELE_BIZPAGE, payload:true });
         let { data } = await axios.post(
             `${process.env.REACT_APP_IPURL}/bp/deletebp`,
             {                
                 businessPageId:businessPageId,
                 password:password,             
             },
             {
                 headers: { Authorization: `Bearer ${user?.token}` }
             })
             dispatch({ type: types.DETELE_BIZPAGE_SUCCESS, payload: data.data.successResult})
         } catch (error) {
             dispatch({
                 type: types.DETELE_BIZPAGE_ERROR,
                 payload:
                 error.response && error.response.data.message
                     ? error.response.data.message
                     : error.message,
             })
         }
 };

 export const instantBizPageMessageAction = (businessPageId, instantReply, allowinstantReplyOn, instantAwayText, awayMessageOn) => async (dispatch, getState) => {
     try {
         let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
         dispatch({ type: types.INSTANT_MESSAGE_BIZPAGE, payload:true });
         let { data } = await axios.post(
             `${process.env.REACT_APP_IPURL}/bp/addMessageSettings`,
             {                
                 businessPageId:businessPageId,
                 instantReply:allowinstantReplyOn, 
                 bpAdminId:user.id,             
                 instantReplyText:instantReply, 
                 awayMessage:awayMessageOn,        
                 awayMessageText:instantAwayText, 
             },
             {
                 headers: { Authorization: `Bearer ${user?.token}` }
             })
             dispatch({ type: types.INSTANT_MESSAGE_BIZPAGE_SUCCESS, payload: data.data.successResult})
         } catch (error) {
             dispatch({
                 type: types.INSTANT_MESSAGE_BIZPAGE_ERROR,
                 payload:
                 error.response && error.response.data.message
                     ? error.response.data.message
                     : error.message,
             })
         }
 };

 export const getInstantBizPageMessageAction = (businessPageId) => async (dispatch, getState) => {

     try {
         let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
         dispatch({ type: types.GET_INSTANT_MESSAGE_BIZPAGE, payload:true });
         let { data } = await axios.post(
             `${process.env.REACT_APP_IPURL}/bp/instantsAndAwayMsages`,
             {                
                businessPageId:businessPageId,
                 bpAdminId:user.id,             
             },
             {
                 headers: { Authorization: `Bearer ${user?.token}` }
             })
             dispatch({ type: types.GET_INSTANT_MESSAGE_BIZPAGE_SUCCESS, payload: data.data.successResult})
         } catch (error) {
            console.log('dataER', error)
             dispatch({
                 type: types.GET_INSTANT_MESSAGE_BIZPAGE_ERROR,
                 payload:
                 error.response && error.response.data.message
                     ? error.response.data.message
                     : error.message,
             })
         }
 };

 export const getBizPageDetailsByIdAction = (businessPageId) => async (dispatch, getState) => {

     try {
         let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
         dispatch({ type: types.GET_BIZ_PAGE_DETAILS, payload:true });
         let { data } = await axios.post(
             `${process.env.REACT_APP_IPURL}/bp/getpagedetails`,
             {                
                id:businessPageId,    
             },
             {
                 headers: { Authorization: `Bearer ${user?.token}` }
             })
             dispatch({ type: types.GET_BIZ_PAGE_DETAILS_SUCCESS, payload: data.data.successResult})
         } catch (error) {
            console.log('dataER', error)
             dispatch({
                 type: types.GET_BIZ_PAGE_DETAILS_ERROR,
                 payload:
                 error.response && error.response.data.message
                     ? error.response.data.message
                     : error.message,
             })
         }
 };

 export const postNotificationSettingsAction = (
    businessPageId,
    allowNotificationOnSms,
    allowNotificationsOnEmail,
    mentions,
    messages,
    newReview,
    commentPosts,
    editCommentWritten,
    newFollower,
    likePosts,
    editPosts,
    newEditSuggestion,
    groupPostShare
  ) => async (dispatch, getState) => {
    console.log(
      'Notfication',
      businessPageId,
      allowNotificationOnSms,
      allowNotificationsOnEmail,
      mentions,
      messages,
      newReview,
      commentPosts,
      editCommentWritten,
      newFollower,
      likePosts,
      editPosts,
      newEditSuggestion,
      groupPostShare
    );
    try {
      let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
      dispatch({ type: types.POST_NOTIFICATION_SETTINGS, payload: true });
  
        // set the boolean values to 1 or 0
      const mentionsValue = mentions === true ? 1 : 0;
      const messagesValue = messages === true ? 1 : 0;
      const newReviewValue = newReview === true ? 1 : 0;
      const commentPostsValue = commentPosts === true ? 1 : 0;
      const editCommentWrittenValue = editCommentWritten === true ? 1 : 0;
      const newFollowerValue = newFollower === true ? 1 : 0;
      const likePostsValue = likePosts === true ? 1 : 0;
      const editPostsValue = editPosts === true ? 1 : 0;
      const newEditSuggestionValue = newEditSuggestion === true ? 1 : 0;
      const groupPostShareValue = groupPostShare === true ? 1 : 0;
  
      let { data } = await axios.post(
        `${process.env.REACT_APP_IPURL}/bp/notificationSettings`,
        {
          businessPageId: businessPageId,
          allowNotification: 1,
          allowNotificationOnSms: allowNotificationOnSms,
          allowNotificationOnEmail: allowNotificationsOnEmail,
          isAllowed: 1,
          notificationType: {
            mentions: mentionsValue,
            messages: messagesValue,
            new_review: newReviewValue,
            comment_post: commentPostsValue,
            edit_comment_written: editCommentWrittenValue,
            new_follower: newFollowerValue,
            like_post: likePostsValue,
            edit_post: editPostsValue,
            new_edit_suggestion: newEditSuggestionValue,
            group_post_share: groupPostShareValue,
          },
        },
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );
      console.log('DetailsByIdAction 2', data);
      dispatch({ type: types.POST_NOTIFICATION_SETTINGS_SUCCESS, payload: data.data.successResult });
    } catch (error) {
      console.log('dataER', error);
      dispatch({
        type: types.POST_NOTIFICATION_SETTINGS_ERROR,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };
  
  export const getBizPageAction = (businessPageId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_BIZ_PAGE_ROLE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/bp/getAllPageAdmin`,
            {                
                businessPageId:businessPageId,    
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.GET_BIZ_PAGE_ROLE_SUCCESS, payload: data.data.successResult})
        } catch (error) {
           console.log('dataER', error)
            dispatch({
                type: types.GET_BIZ_PAGE_ROLE_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const removePageRoleFromBizPageAction = (businessPageId, userId) => async (dispatch, getState) => {
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            dispatch({ type: types.REMOVE_ROLE_FROM_BIZ_PAGE, payload:true });
            let { data } = await axios.post(
                `${process.env.REACT_APP_IPURL}/bp/removeUser`,
                {                
                    businessPageId:businessPageId,
                    userId:userId  
                },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
    
                dispatch({ type: types.REMOVE_ROLE_FROM_BIZ_PAGE_SUCCESS, payload: data.data.successResult})
            } catch (error) {
                dispatch({
                    type: types.REMOVE_ROLE_FROM_BIZ_PAGE_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
    };

    export const getBizPageFollowerListsAction = (sortBy) => async (dispatch, getState) => {
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                dispatch({ type: types.GET_BIZ_PAGE_FOLLOWER_LIST, payload:true });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/post/getPeopleList`, 
                    {                
                        sortBy:sortBy,
                    },
                    {
                        headers: { Authorization: `Bearer ${user?.token}` }
                    })
        
                    dispatch({ type: types.GET_BIZ_PAGE_FOLLOWER_LIST_SUCCESS, payload: data.data.successResult.rows})                
                } catch (error) {
                   console.log('dataER', error)
                    dispatch({
                        type: types.GET_BIZ_PAGE_FOLLOWER_LIST_ERROR,
                        payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                    })
                }
        };

        export const setAssignRoleAction = (businessPageId, userId, role, roleAdd, roleUpdate, roleDelete, add, update) => async (dispatch, getState) => {
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                dispatch({ type: types.SET_ASSIGN_ROLE, payload:true });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/bp/assignRole`, 
                    {                
                        businessPageId:businessPageId,
                        userId:userId,
                        role:role,
                        roleAdd:roleAdd,
                        roleUpdate:roleUpdate,
                        roleDelete:roleDelete,
                        add:add,
                        update:update
                    },
                    {
                        headers: { Authorization: `Bearer ${user?.token}` }
                    })
                        console.log('role rslt', data)
                    dispatch({ type: types.SET_ASSIGN_ROLE_SUCCESS, payload: data.data.successResult})                
                } catch (error) {
                   console.log('dataER', error)
                    dispatch({
                        type: types.SET_ASSIGN_ROLE_ERROR,
                        payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                    })
                }
        };

        export const getFollowedByBizPageUserAction = (businessPageId) => async (dispatch, getState) => {
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                dispatch({ type: types.GET_FOLLOWED_BY_BIZ_PAGE_USERS, payload:true });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/bp/followerList`, 
                    {                
                        businessPageId:businessPageId,
                        searchKey:"",
                        sortBy:"fullName",
                        pageSize:1,
                        pageNumber:0
                    },
                    {
                        headers: {Authorization: `Bearer ${user?.token}`}
                    })
                    console.log('123 Ac', data)
                    dispatch({ type: types.GET_FOLLOWED_BY_BIZ_PAGE_USERS_SUCCESS, payload: data.data.successResult.businessResponse})                
                } catch (error) {
                   console.log('dataER', error)
                    dispatch({
                        type: types.GET_FOLLOWED_BY_BIZ_PAGE_USERS_ERROR,
                        payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                    })
                }
        };

        export const getBlockedBizPageUserAction = (businessPageId) => async (dispatch, getState) => {
            console.log('blocked_users id', businessPageId)
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                dispatch({ type: types.GET_BP_BLOCKED_fOLLOWER, payload:true });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/bp/getallblockedUsers`, 
                    {                
                        businessPageId:businessPageId,
                    },
                    {
                        headers: {Authorization: `Bearer ${user?.token}`}
                    })
                    console.log('blocked_users', data.data.successResult)
                    dispatch({ type: types.GET_BP_BLOCKED_fOLLOWER_SUCCESS, payload: data.data.successResult})                
                } catch (error) {
                   console.log('dataER', error)
                    dispatch({
                        type: types.GET_BP_BLOCKED_fOLLOWER_ERROR,
                        payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                    })
                }
        };

        export const blockBizPageUserAction = (businessPageId, userId, block) => async (dispatch, getState) => {
            console.log('unBlock', businessPageId, userId, block)
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                dispatch({ type: types.BLOCK_BIZ_PAGE_USERS, payload:true });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/bp/blockUnblockPeople`, 
                    {                
                        businessPageId:businessPageId,
                        userId:userId,
                        block:block
                    },
                    {
                        headers: {Authorization: `Bearer ${user?.token}`}
                    })
                    console.log('unBlock', data)
                    dispatch({ type: types.BLOCK_BIZ_PAGE_USERS_SUCCESS, payload: data.data.successResult.businessPage})                
                } catch (error) {
                   console.log('dataER', error)
                    dispatch({
                        type: types.BLOCK_BIZ_PAGE_USERS_ERROR,
                        payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                    })
                }
        };

        export const getBizPageHistoryAction = (businessPageId, pageIndex, pageSize) => async (dispatch, getState) => {
            console.log('block123', businessPageId, pageIndex, pageSize)
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                dispatch({ type: types.GET_BP_HISTORY, payload:true });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/bp/getPageHistory`, 
                    {                
                        businessPageId:businessPageId,
                        pageIndex:pageIndex,
                        pageSize:pageSize
                    },
                    {
                        headers: {Authorization: `Bearer ${user?.token}`}
                    })
                    console.log('block123 ac', data)
                    dispatch({ type: types.GET_BP_HISTORY_SUCCESS, payload: data.data.successResult})                
                } catch (error) {
                   console.log('dataER', error)
                    dispatch({
                        type: types.GET_BP_HISTORY_ERROR,
                        payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                    })
                }
        };


        //load all cetegory podcast============

        export const loadAllPodcastAction = (one) => async (dispatch, getState) => {
            try {
                let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
                // dispatch({ type: types.GET_ALL_AVAILABLE_HOBBIES });
                let { data } = await axios.post(
                    `${process.env.REACT_APP_IPURL}/admin/getAllSeriesByUserID`,
                    { userId: user.id },
                    {
                        headers: { Authorization: `Bearer ${user?.token}` }
                    })
                dispatch({ type: types.GET_ALL_AVAILABLE_HOBBIES_SUCCESS, payload: data.data.successResult.rows });
        
            } catch (error) {
                dispatch({
                    type: types.GET_ALL_AVAILABLE_HOBBIES_ERROR,
                    payload:
                        error.response && error.response.data.message
                            ? error.response.data.message
                            : error.message,
                })
            }
        };

