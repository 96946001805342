import React from 'react'
import { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Filter } from 'react-feather'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledButtonDropdown } from 'reactstrap'
import { BizFollowOption } from './SettingArray'
import RemoveModal from './SettingModal/RemoveModal'
import UnBlockModal from './SettingModal/UnBlockModal'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getFollowedByBizPageUserAction, blockBizPageUserAction, getBlockedBizPageUserAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import moment from 'moment';
import BizProfile from '../Img/BizProfile.png'
import BizProfile1 from '../Img/BizProfile1.png'

const OtherPageSetting = (props) => {

    let dispatch = useDispatch();
    const { id } = useParams();

    const followedBizPageUser = useSelector((state) => { return state.getFollowedByBizPageUserData});
    const { loading: loadingfollowedPageUsers, error: errorfollowedPageUsers, result: resultfollowedPageUsers} = followedBizPageUser 
   
    const blockBizPageUser = useSelector((state) => { return state.blockBizPageUserData});
    const { loading: loadingblockBizPageUser, error: errorblockBizPageUser, result: resultblockBizPageUser} = blockBizPageUser 

    const blockedBizPageUser = useSelector((state) => { return state.getBlockedBizPageUserData});
    const { loading: loadingblockedBizPageUser, error: errorblockedBizPageUser, result: resultblockedBizPageUser} = blockedBizPageUser  
    
    
    const [filterMenu, setFilterMenu] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    
    const filterUsers = (filterMenu) => {
      if (filterMenu) {
        const sortedUsers = resultfollowedPageUsers.sort((a, b) => {
            if (a.user[filterMenu] < b.user[filterMenu]) {
              return -1;
            }
            if (a.user[filterMenu] > b.user[filterMenu]) {
              return 1;
            }
            return 0;
          });
          const mappedUsers = sortedUsers.map((user) => user);
          setFilteredUsers(mappedUsers)
        }
      
      else if(!filterMenu){
        setFilteredUsers(resultfollowedPageUsers)
      } 
    }

    useEffect(() => {
        filterUsers(filterMenu);
      }, [filterMenu, resultfollowedPageUsers]);

    useEffect(() => {
        dispatch(getFollowedByBizPageUserAction(id)) 
    }, [resultblockBizPageUser]);

    useEffect(() => {
        dispatch(getBlockedBizPageUserAction(id)) 
    }, []);

    const [active, setActive] = useState('1')
    const toggleActive = tab => {
        dispatch(getBlockedBizPageUserAction(id)) 
        dispatch(getFollowedByBizPageUserAction(id)) 
        if (active !== tab) {
            setActive(tab)
        }
    }
    const [removeOpen, setRemoveOpen] = useState(false)
    const [userId, setUserId] = useState('')

    const toggleRemove = (userId) => {
        setUserId(userId)
        setUnblock(0)
        setRemoveOpen((preState) => !preState)
    }
    const [unblock, setUnblock] = useState(false)
    const toggleUnBlock = () => {
        setUnblock((preState) => !preState)
    }
    const blockUser = (userId) => {
        setUserId(id)
        dispatch(blockBizPageUserAction(id, userId, 1))         
    }
    

    const BizFollowOption = [
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        }
    ]

    return (
        <Fragment>
            <div className="custom-card-block">
                <div className="customAbout-card-head biz-carddetail-blk">
                    <div onClick={() => { props.setsetsideclassoption(false); }} style={{cursor:"pointer"}} >
                    <h3> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  className='hide-setting-options12'>
                            <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                        </svg>People are other business pages</h3></div>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color='primary' outline >
                            <Filter size={16} />&nbsp;Filter
                        </DropdownToggle>
                        <DropdownMenu className="BizSetting_DropDown_item">
                            <DropdownItem onClick={() => filterUsers('userName')}>
                                User Name 
                            </DropdownItem>
                            <DropdownItem onClick={() => filterUsers('fullName')}>
                                Full Name 
                            </DropdownItem>
                            <DropdownItem onClick={() => filterUsers('email')}>
                                E mail
                            </DropdownItem>
                            <DropdownItem onClick={() => filterUsers('createdAt')}>
                                Follow Date 
                            </DropdownItem>
                            </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>

                {/* this will work when you the database give the data */}
                {/* <div className='mt-3'>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={active === '1'}
                                onClick={() => {
                                    toggleActive('1')
                                }}
                            >
                                <span className='font-weight-bold'>People who follow this</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '2'}
                                onClick={() => {
                                    toggleActive('2')
                                }}
                            >
                                <span className='font-weight-bold'>Blocked</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className='py-50' activeTab={active}>
                        <TabPane tabId='1'>
                        {

                            filteredUsers?.map((data) => {
                                const now = moment();
                                const createdAt = moment(data.createdAt);
                                const diffDuration = moment.duration(now.diff(createdAt));
                                const hoursAgo = diffDuration.asHours();
                                const daysAgo = diffDuration.asDays();
                                const weeksAgo = diffDuration.asWeeks();
                                const monthsAgo = diffDuration.asMonths();
                                const yearsAgo = diffDuration.asYears();
                                let displayTime;
                                if (hoursAgo < 24) {
                                displayTime = `${Math.round(hoursAgo)} hours ago`;
                                } else if (daysAgo < 7) {
                                displayTime = `${Math.round(daysAgo)} days ago`;
                                } else if (weeksAgo < 4) {
                                displayTime = `${Math.round(weeksAgo)} weeks ago`;
                                } else if (monthsAgo < 12) {
                                displayTime = `${Math.round(monthsAgo)} months ago`;
                                } else {
                                displayTime = `${Math.round(yearsAgo)} years ago`;
                                }
                                return (
                                <div className="biz-PeopleFollow-blk" key={data?.id}>
                                    <div className='d-flex align-items-start'>
                                    <img src={`${process.env.REACT_APP_IMAGEURL}${data?.user.profileImageThumb}`} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                    <div className='ml-2 BizProfile_section'>
                                        <h4>{data?.user.fullName}</h4>
                                        <p>{displayTime}</p>
                                    </div>
                                    </div>
                                    <p className=' text-wrap'>{data?.status}</p>
                                    <p style={{ cursor: "pointer" }} className='text-primary'
                                    onClick={() => blockUser(data?.userId)}
                                    > Block </p>
                                </div>
                                );
                            })
                            }
                        </TabPane>
                        <TabPane tabId='2'>
                            <div className='p-3 d-flex justify-content-between biz-BlockedFollow-blk'>
                                <p >Blocked people can not see any activity by group</p>
                                <p onClick={toggleUnBlock} style={{ cursor: "pointer" }} className='text-primary font-weight-bold'>Unblock All</p>
                            </div>

                            {
                                resultblockedBizPageUser?.map((data) => {
                                const now = moment();
                                const createdAt = moment(data?.createdAt);
                                const diffDuration = moment.duration(now.diff(createdAt));
                                const hoursAgo = diffDuration.asHours();
                                const daysAgo = diffDuration.asDays();
                                const weeksAgo = diffDuration.asWeeks();
                                const monthsAgo = diffDuration.asMonths();
                                const yearsAgo = diffDuration.asYears();
                                let displayTime;
                                if (hoursAgo < 24) {
                                displayTime = `${Math.round(hoursAgo)} hours ago`;
                                } else if (daysAgo < 7) {
                                displayTime = `${Math.round(daysAgo)} days ago`;
                                } else if (weeksAgo < 4) {
                                displayTime = `${Math.round(weeksAgo)} weeks ago`;
                                } else if (monthsAgo < 12) {
                                displayTime = `${Math.round(monthsAgo)} months ago`;
                                } else {
                                displayTime = `${Math.round(yearsAgo)} years ago`;
                                }
                                return (
                                    <div className="biz-PeopleFollow-blk" >
                                        <div className='d-flex align-items-start' >
                                            <img  src={`${process.env.REACT_APP_IMAGEURL}${data?.user.profileImageThumb}`} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                            <div className='ml-2 BizProfile_section' >
                                                <h4>{data?.user.fullName}</h4>
                                                <p>{displayTime}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Button onClick={() => toggleRemove(data?.userId)} color='danger'>Unblock</Button>
                                        </div>
                                    </div>
                                )
                                })
                            }

                        </TabPane>
                    </TabContent>
                </div> */}
                 <div className='mt-3'>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={active === '1'}
                                onClick={() => {
                                    toggleActive('1')
                                }}
                            >
                                <span className='font-weight-bold'>People who follow this</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '2'}
                                onClick={() => {
                                    toggleActive('2')
                                }}
                            >
                                <span className='font-weight-bold'>Blocked</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className='py-50' activeTab={active}>
                        <TabPane tabId='1'>
                            {
                                BizFollowOption.map((data) => (
                                    <div className="biz-Follow-blk " >
                                        <div className='d-flex ' >
                                            <img src={data.img} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                            <div className='ml-2 BizProfile_section blockhead ' >
                                                <h4>{data.name}</h4>
                                                <p className='text-muted' >{data.id}</p>
                                            </div>
                                        </div>
                                        <p className='text-muted text-wrap admintesxt'>{data.status}</p>
                                        <p style={{ cursor: "pointer" }} className='text-primary'> View </p>
                                    </div>
                                ))
                            }
                        </TabPane>
                        <TabPane tabId='2'>
                            <div className='p-3 d-flex justify-content-between '>
                                <p className='text-muted'>Blocked people can not see any activity by group</p>
                                <p 
                                onClick={toggleUnBlock} 
                                style={{ cursor: "pointer" }} className='text-primary font-weight-bold'>Unblock All</p>
                            </div>
                            {
                                BizFollowOption.map((data) => (

                                    <div className="biz-Follow-blk " >
                                        <div className='d-flex' >
                                            <img src={data.img} className="img-fluid rounded-circle" alt='biz-img' width={60} height={60} />
                                            <div className='ml-2 BizProfile_section blockhead' >
                                                <h4>{data.name}</h4>
                                                <p className='text-muted' >{data.id}</p>
                                            </div>
                                        </div>
                                        <div className='remmob'>
                                            <Button onClick={toggleRemove} color='danger' outline>Unblock </Button>
                                        </div>
                                    </div>
                                ))
                            }

                        </TabPane>
                    </TabContent>
                </div>
            </div>
            {/*================ start_modal_section ============= */}
            <RemoveModal
                isOpen={removeOpen}
                updateOpen={toggleRemove}
                userId={userId}
                unblock = {unblock}
            />
            <UnBlockModal
                isOpen={unblock}
                updateOpen={toggleUnBlock}
            />
            {/*================ end_modal_section ============= */}
        </Fragment>
    )
}

export default OtherPageSetting