import React, { useState } from 'react'
import ContentLeft from '../ContentLeft'
import ContentRight from '../ContentRight'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import { StorySlider } from '../Story/StorySlider'
import MainFeed from './MainFeed'
import SliderStory from '../Story/SliderStory'
import MainCreation from '../Social-feed/MainCreation'
import { Container } from 'reactstrap'

const NewFeed = () => {


    const [activeScreen, setactiveScreen] = useState(false)

    return (
        <>
            <Header />
            <div className={activeScreen ? 'pointerForfreez page-body container-fluid newsfeed-style1' : "page-body container-fluid newsfeed-style1"} >
                <LeftSidebar />
                <Container>
                    <div className="page-center new-socialfeed-width" >
                    {/* <div className="page-center new-feed-width" > */}
                        {/* <StorySlider /> */}

                        <SliderStory />
                        <div className="container-fluid section-t-space px-0">
                            <div className="page-content">
                                <ContentLeft />
                                <div className={activeScreen ? 'content-center px-0 pointerForworked rounded' : 'content-center px-0 content-center-slider'}>
                                    {/* <MainFeed activeScreen={activeScreen} setactiveScreen={setactiveScreen} /> */}

                                    <MainCreation />

                                </div>
                                <ContentRight />
                            </div>
                        </div>
                    </div>
                </Container>
                <RightSidebar />
            </div>

        </>
    )
}

export default NewFeed