import React, { Fragment } from 'react'
import Slider from 'react-slick'
import Grp2 from '../Img/Grp2.png'
import Grp from '../Img/Grp.png'
import Grp1 from '../Img/Grp1.png'
import { Card, CardImg } from 'reactstrap'
import { Users } from 'react-feather'
import SldrImg from '../Img/SldrImg.png'
import { useEffect } from 'react'

// import { loadAllGroupById } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { loadProfileByUserId } from '../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'



const sportSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};


const MyGroup = (props) => {
    
    const dispatch = useDispatch()

    // const { getGroupMainPageOwnGroup } = useSelector((state) => { return state.getAllGroupMainPageData });
    const { userProfileByUserId } = useSelector(state => state.getUserProfileByUserIdData);

    useEffect(() => {
        dispatch(loadProfileByUserId(props?.userId))
      
    }, [])

        const truncateText = (text) => {
        const words = text.split(' ');
        return words.length > 2 ? `${words.slice(0, 2).join(' ')}...` : text;
    };


 
    return (
        <Fragment>
        <div className="about-profile-box-blk">
          <div className="card-title">
            <h3> {  userProfileByUserId?.groups && userProfileByUserId?.groups?.length >0 ?  'Groups' : 'No Group Found'}</h3>
          </div>
          <div className="card-block-box">
            <Slider {...sportSettings} className="default-space">
              {
                userProfileByUserId?.groups?.map((data,index) => (
                  <div key={data.id}>  {/* Added inline styles for container */}
                    <NavLink to={`/GroupDetail/${data.id}/${data.name}/${data.groupMembers}/${index}`}>
                      <Card className="profile-slide-box"> {/* Adjusted Card dimensions */}
                        <CardImg
                          alt="Card image cap"
                          src={data.groupcoverThumbPic}
                          className="img-fluid "
                           width="100%"
                           height="100%"
                           style={{
                            width: '200px',  // Set the desired width
                            height: '150px', // Set the desired height
                            objectFit: 'cover', // Ensure the image covers the container without distortion
                          }}
                          onError={(e) => e.target.src = `${process.env.DUMMY_GROUP_COVER}`}
                        />
                        <div style={{
                            position: 'absolute',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay with 30% opacity
                            pointerEvents: 'none'
                        }}></div>
                        <div className="story-content">
                          <h6>{truncateText(data.name)}</h6>
                          <p><Users size={10} />&nbsp;{data.groupMembers >0 
                            ? data.groupMembers + ' Members' : data.groupMembers +  ' Member'}</p>
                        </div>
                      </Card>
                    </NavLink>
                  </div>
                ))
              } 
            </Slider>
          </div>
        </div>
      </Fragment>
    )
}

export default MyGroup
