import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useState } from 'react'
import Slider from 'react-slick'
import { Card, CardImg, Modal } from 'reactstrap'
// import Pen1 from '../Img/Pen1.png'
// import Sprt from "../Img/sport.png"
// import SldrImg from '../Img/SldrImg.png'
import { Checkbox } from '@mui/material'
import { getAllUserBookAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'

let sportSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};

const MyBook = (props) => {
    const dispatch = useDispatch()
    const getAllUserBookSuccess = useSelector((state) => { return state.getAllUserBookData});
    const {loading:loadingAllUserBook, error:errorAllUserBook, result:resultAllUserBook} = getAllUserBookSuccess

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [bookOpen, setBookOpen] = useState(false)

    useEffect(() => {
        dispatch(getAllUserBookAction())
      
    }, [])


    const toggleBook = () => {
        setBookOpen((preState) => !preState)
    }
 
    return (
        <Fragment>
           
            {  resultAllUserBook && resultAllUserBook?.length >0 ?

            <div className="about-profile-box-blk">
                <div className="card-title">
                    <h3>Book</h3>
                    <div className="settings">
                        {/* <div onClick={toggleBook} className="setting-btn">
                            <img src={Pen1} width={12} className="img-fluid" />
                        </div> */}
                    </div>
                </div>
                <div className="card-block-box">
                    <Slider {...sportSettings} className="default-space">
                        {
                            resultAllUserBook?.map((data) => (
                                <div>
                                    <Card className="profile-slide-box">
                                        <CardImg
                                            alt="Card image cap"
                                            src={data?.thumbnail}
                                            width="100%"
                                            className="img-fluid "
                                            style={{height:'130px', width:'120px'}}
                                        />
                                        <div className="story-content">
                                            <h6>{data.name}</h6>
                                        </div>
                                    </Card>

                                </div>
                            ))
                        }


                    </Slider>
                </div>

            </div>
              : '' 
              }
            <div className='Modal_section_Basic_info_Book'>
                {
                    bookOpen !== false &&
                    <Modal scrollable isOpen={bookOpen} toggle={toggleBook} className='modal-dialog-centered'>
                        <div className='Profession_Modal_section AboutModal_Main_section p-3 py-3'>
                            <h4>Choose your Book</h4>
                            <p onClick={toggleBook}>Save</p>
                        </div>
                        <div className='Modal_scroll_box'>
                            <div className="AboutModal_Main_section p-3 py-3">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find Book..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                           
                        </div>
                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default MyBook
