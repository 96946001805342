import React, { useState } from 'react'
import classnames from 'classnames'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap'
import PostCreation from './post-creations/PostCreation'
import Display from './display/Display'

const TwowayNav = () => {
    const [active, setActive] = useState('1')

    const toggle = tab => {
        setActive(tab)
    }
    return (
        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Post Creation
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Post Display
                    </NavLink>
                </NavItem>
            </Nav>
            <div className='p-2 mt-2'>
                <TabContent className='py-50' activeTab={active}>
                    <TabPane tabId='1'>
                        <PostCreation />
                    </TabPane>
                    <TabPane tabId='2'>
                       <Display/>
                    </TabPane>
                </TabContent>
            </div>
        </React.Fragment>
    )
}
export default TwowayNav