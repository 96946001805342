import { Menu, MenuItem } from '@mui/material'
import { AlertTriangle, Bell, BellOff, Check, MoreHorizontal, MoreVertical, Phone, Trash2, User, Video, XSquare } from 'react-feather'
import React, { useState } from 'react'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Input, DropdownMenu, DropdownItem, UncontrolledButtonDropdown, DropdownToggle } from 'reactstrap'
import Man from './Img/He.svg'
import Woman from './Img/She.svg'
import './Notification.css'
import { NavLink } from 'react-router-dom'
import ReportModal from './Modals/ReportModal'
import { NotificationData } from './DummyArray'

const Notification = () => {

    // ------------------------------------------State & Function for Report Modal------------------------------------------
    // const [reportOpenModal, setReportOpenModal] = useState(false)
    // const toggleReportModal = () => {
    //     setReportOpenModal((preState) => !preState)
    // }

    // -----------------------------------State & Function for Mute/Unmute notifications------------------------------------
    const [muted, setMuted] = useState(false);
    const toggleMute = () => {
        setMuted((prevState) => !prevState);
    }

    // ----------------------------------------------Report Data Array------------------------------------------------------
    // const ReportDataInfo = [
    //     {
    //         name: "Spam"
    //     },
    //     {
    //         name: "Nudity"
    //     },
    //     {
    //         name: "Violence"
    //     },
    //     {
    //         name: "Harrasment"
    //     },
    //     {
    //         name: "Suicide or self-injury"
    //     },
    //     {
    //         name: "Terrorism"
    //     },
    //     {
    //         name: "Others"
    //     }
    // ]

    // const DropMenu = () => {

    //     const [anchorEl, setAnchorEl] = React.useState(null);

    //     const handleClick = (event) => {
    //         setAnchorEl(event.currentTarget);
    //     };

    //     const handleClose = () => {
    //         setAnchorEl(null);
    //     };
    //     return (

    //         <>
    //             <div>
    //                 <MoreVertical aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className='Vertical' />
    //                 <Menu
    //                     id="simple-menu"
    //                     anchorEl={anchorEl}
    //                     keepMounted
    //                     open={Boolean(anchorEl)}
    //                     onClose={handleClose}
    //                 >
    //                     <MenuItem onClick={handleClose}><Check size={20} color='black' className='mr-2' /> Mark as unread </MenuItem>
    //                     <MenuItem onClick={handleClose}><Bell size={20} color='black' className='mr-2' /> Mute Notifications </MenuItem>
    //                     <MenuItem onClick={handleClose}><User size={20} color='black' className='mr-2' /> View Profile  </MenuItem>
    //                     <MenuItem onClick={toggleReport}><AlertTriangle size={20} color='black' className='mr-2' /> Report </MenuItem>
    //                 </Menu>

    //             </div>
    //         </>
    //     )
    // }


    const NotificationList = () => {

        //     // ------------------------------------------State & Function for Report Modal------------------------------------------
        const [reportOpenModal, setReportOpenModal] = useState(false)

        const toggleReportModal = () => {
            setReportOpenModal((preState) => !preState)
        }


        const Notifiactiondata = [
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
                text: 'Paige Turner',
                Time: '3 Hrs ago',
                post: 'liked your post',
                status: 'read',
                name3: 'Sunny'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                name2: 'The Gong Advertisement',
                post1: 'post',
                img: 'https://img.freepik.com/free-photo/female-model-posing-pink-wall_114579-13995.jpg?w=1380&t=st=1681366653~exp=1681367253~hmac=82a29bb3842141013e507015fc22b76128ceb5c503c2ce7660bdc4753743edbd',
                text: 'Paige Turner',
                Time: '2 Hrs ago',
                post: 'Shared',
                status: 'read',
                name3: 'Jacob Jones'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                name2: 'Galaxy',
                img: 'https://img.freepik.com/free-photo/afro-man-focusing-with-his-fingers_1368-2736.jpg?w=1060&t=st=1681366283~exp=1681366883~hmac=6559d3e03c18241ac23803b220f3067c79fbb5dfddab2aeeb4dea5760806f320',
                text: 'Paige Turner',
                Time: '7 Hrs ago',
                post: 'You have a new friend suggestion',
                status: 'read',


            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
                text: 'Jacob Jones',
                Button: 'Request',
                Time: '9 Hrs ago',
                post: 'sent you a follow request',
                status: 'Unread',
                name3: 'Ajay'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
                text: 'Dr.Robertson',
                Time: '1 day ago',
                post: 'liked your post',
                status: 'Unread',
                name3: 'Sourabh'


            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
                text: 'Paige Turner',
                Time: '6 Hrs ago',
                post: 'liked your post',
                status: 'read',
                name3: 'Abhinav'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
                text: 'Dr.Robertson',
                Time: '5 days ago',
                post: 'sent you a follow request',
                status: 'read',
                Button: 'Request',
                name3: 'Hamid'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
                text: 'Dr.Robertson',
                Time: '1 day ago',
                post: 'liked your post',
                status: 'Unread',
                name3: 'Anthony'


            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
                text: 'Paige Turner',
                Time: '8 Hrs ago',
                post: 'liked your post',
                status: 'read',
                name3: 'Tom'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
                text: 'Dr.Robertson',
                Time: '5 days ago',
                post: 'liked your post',
                status: 'read',
                // Button: 'Request',
                name3: 'Sarvesh'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
                text: 'Jacob Jones',
                Button: 'Request',
                Time: '5 Hrs ago',
                post: 'sent you a follow request',
                status: 'Unread',
                name3: 'Prem'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
                text: 'Dr.Robertson',
                Time: '3 day ago',
                post: 'liked your post',
                status: 'Unread',
                name3: 'Abhay'


            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
                text: 'Paige Turner',
                Time: '9 Hrs ago',
                post: 'liked your post',
                status: 'read',
                name3: 'Hritik'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
                text: 'Dr.Robertson',
                Time: '5 days ago',
                post: 'sent you a follow request',
                status: 'read',
                Button: 'Request',
                name3: 'Shubham'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
                text: 'Dr.Robertson',
                Time: '2 day ago',
                post: 'liked your post',
                status: 'Unread',
                name3: 'Akshay'


            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
                text: 'Paige Turner',
                Time: '6 Hrs ago',
                post: 'liked your post',
                status: 'read',
                name3: 'Abhishek'

            },
            {
                name: 'raj',
                name1: 'Ashish',
                img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
                text: 'Dr.Robertson',
                Time: '8 days ago',
                post: 'liked your post',
                status: 'read',
                // Button: 'Request',
                name3: 'Surendra'

            }


        ]

        return (
            <>
                <ReportModal
                    reportOpen={reportOpenModal}
                    toggleReport={toggleReportModal}
                />
                {NotificationData.map((item, index) => (
                    <div className='CardThisHover'>
                        <div key={index} className={item.status === 'read' ? 'd-flex justify-content-between p-2 mt-2  bg-light CardThisHover ' : 'd-flex justify-content-between p-2 mt-2'} id='fixed_color'>
                            <div className='d-flex align-items-start '>
                                <img src={item.img} height={50} width={50} alt='pollimg' className='rounded-circle' />
                                <div className='ml-3'>
                                    {/* <h4> <span className='user-profile'  >{item.name3}</span> <span className='text-muted'> {item.post} </span> {item.name2} <span className='text-muted'> {item.post1} </span></h4> */}
                                    <h4> <NavLink to='/MyProfile' style={{ color: 'black' }} ><span className='user-profile'  >{item.name3}</span></NavLink> <span className='text-muted'> {item.post} </span> {item.name2} <span className='text-muted'> {item.post1} </span></h4>
                                    {item.Button === 'Request' ?
                                        <div className='mt-2'>
                                            <Button color='primary px-3 py-2' >Accept</Button>
                                            <Button color='secondary px-3 py-2' outline className='ml-2'>Decline</Button>
                                        </div> : null
                                    }
                                    <p className='mt-2 text-muted'>{item.Time} </p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown direction='up' className='d-flex align-items-start' >
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreVertical size={19} color='#808080' />
                                </DropdownToggle>
                                <DropdownMenu style={{ minWidth: 'fit-content', fontSize: '12px', left: '-24px' }}>
                                    <DropdownItem className='dropdownitem' ><Check size={18} color='#6E6B7B' /> Mark as unread</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleMute} ><span >{muted ? <Bell size={18} color='#6E6B7B' /> : <BellOff size={18} color='#6E6B7B' />}</span> {!muted ? 'Mute Notifications' : 'Unmute Notifications'}</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' className='text-dark'><User size={18} color='#6E6B7B' /> &nbsp;View Profile</NavLink></DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReportModal}><AlertTriangle size={18} color='#6E6B7B' /> Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div >
                ))}
            </>
        )
    }
    return (
        <div className='scrollForNotification'>
            <div className='d-flex justify-content-between align-items-center mr-3 mt-2 p-2'>
                <h4 className='ml-3'> New Notifications </h4>
                <NavLink to='/Notification'> See all</NavLink>
            </div>
            <NotificationList />
            {/* <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us more about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>
                }
            </div> */}

        </div>

    )
}

export default Notification
