import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "react-bootstrap";
import { getActionUserReported } from "../../../Services/Actions/Connection/getUserReportAction";
import { getActionUserReportedOption } from "../../../Services/Actions/Connection/getReportOptionAction";
import { getCreatetConnectionListAction } from "../../../Services/Actions/Connection/getCreatedCategoryAction";
import { sendingSelectedReport } from "../../../Services/Actions/Group/getAllGroupMainPageAction";
import { useDispatch, useSelector } from "react-redux";

const ConnectionReportModal = ({request, modalOpen, reportModalOpen,setReportConfirm}) => {
    
  //const [selectedValue, setSelectedValue] = React.useState("1");
  const dispatch = useDispatch();
  //const [open, setOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [remark, setRemark] = useState("");

  const { getUserReportOption } = useSelector(
    (state) => state.userReportOptionData
  );

  //const handleChange = (event) => {
  //  setSelectedValue(event.target.value);
  //};

  const reportSubmit = () =>{
    if(request?.Type === "Group"){
      dispatch(sendingSelectedReport(selectedOption, request.id, remark));
    } else {
      dispatch(getActionUserReported(request, selectedOption, remark));
    }
    reportModalOpen();
    setReportConfirm(true);
    setSelectedOption("");
    setRemark("");
  }

  useEffect(() => {
    if (request) {
      const reportType = request.Type === "Group" ? "group" : "user";
      dispatch(getActionUserReportedOption(reportType));  // Fetch based on type
    }
  },[dispatch, request]);

 
  //  ------------- Start Reported Option  -------------

  return (
    <>
      {modalOpen !== false && (
        <Modal
          isOpen={modalOpen}
          toggle={reportModalOpen}
          className="modal-dialog-centered"
        >
          <ModalHeader >
            <h2>Report</h2>
          </ModalHeader>
          <ModalBody className="Modal_Body_Section">
            <div>
              {/* <h2>Please select a problem</h2> */}
              <p className="pb-1">
                Choose the option that best describes your connection status to
                report it
              </p>
            </div>
            {getUserReportOption?.map((data) => (
              <div
                key={data.id}
                className="d-flex justify-content-between mt-4 Report_Section"
              >
                <div>
                  <h4>{data.reportText}</h4>
                </div><br/><br/>
                <div>
                  <Input name="radio1" type="radio"  onClick={()=>{setSelectedOption(data.id)}} />
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center mt-3">
              <Input
                id="ReportName"
                name="ReportName"
                placeholder="Write a comment..."
                value={remark}
                type="text"
                onChange={(e) => setRemark(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
          <Button
            variant="primary"
            className="w-25"
            onClick={reportSubmit}
            disabled={!selectedOption} // Disable if no option selected
            style={{
              backgroundColor: selectedOption ? '' : '#E6F3DB', // Change color based on selection
              color: selectedOption ? '#fff' : '#000', // Ensure the border matches the background
              cursor: selectedOption ? 'pointer' : 'not-allowed',
            }}
          >
            Submit
          </Button>
            <Button
              variant="outline-secondary"
              className="w-25"
              onClick={() =>{reportModalOpen(); setSelectedOption(""); setRemark("")}}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ConnectionReportModal;
