import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../../Header'
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import ExploreShotzGrid from './ExploreShotzGrid'
import { ShotzListView } from '../shotz_list_view/ShotzListView'
import { Archive } from 'react-feather'

const ExploreShotz = () => {
    const [data, setdata] = useState(true)
    return (
        <>
            <Header />
            <div className="page-body container-fluid newsfeed-style1">
                <LeftSidebar />
                <div className="page-center">

                    <Card>
                        <CardBody>
                            <Row className='rowForrevert'>
                                {/* <Col xl='12'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h3 className=''>Shotz</h3>
                                        <div className="profile-search-blk size-sm-mt-4 ml-3">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                        </div>
                                    </div>
                                </Col> */}

                                {/* <Col xl='6' sm='12'> */}
                                {/* <div className='d-flex justify-content-between align-items-center'> */}
                                {/* <div className='d-flex justify-content-star mt-3'>
                                            <h2 style={{ cursor: 'pointer' }}><strong>Shotz</strong></h2>
                                        </div> */}

                                {/* </div> */}

                                {/* <div style={{ color: '#4D4D4D', fontWeight: 500 }} className='p-2' >
                                        <h2 style={{ cursor: 'pointer' }}><strong>Shotz</strong></h2>
                                    </div> */}
                                {/* </Col> */}
                                {/* ======================================== manage text================================================ */}
                                <Col xl='12' sm='12'>
                                    <div className='d-flex justify-content-between align-items-center pb-2'>
                                        <div className='d-flex justify-content-star' style={{ marginTop: '-1rem' }}>
                                            <h2 style={{ cursor: 'pointer' }}><strong>Shotz</strong></h2>
                                        </div>
                                        <div className='d-flex justify-content-end align-items-center pb-3 manage-text'>
                                            <div className="profile-search-blk  mr-2">
                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                <input type="text" className="form-control  manage-texts" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                            </div>
                                            <div>
                                                <NavLink to='/Shotz' className='mr-1'>
                                                    <Button id='positionBottomExplore' color='primary' outline>
                                                        Manage Shotz
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='positionBottomExplore'>
                                                        <h6>Manage</h6>
                                                    </UncontrolledTooltip>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row className='rowForrevert'> */}
                            {/* <Col xl='12'>
                                    <div className='d-flex align-items-center justify-content-end py-2'> */}
                            {/* <NavLink to='/ExploreShotz' className='mr-2'> */}
                            {/* <NavLink to='/Shotz' className='mr-2'>
                                            <Button id='positionBottomExplore' color='primary' outline>
                                                Manage Shotz
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='positionBottomExplore'>
                                                <h6>Manage</h6>
                                            </UncontrolledTooltip>
                                        </NavLink> */}

                            {/* <NavLink to='/ArchivedShotzList' className='explore-btn'>
                                            <Button id='positionBottomArchives' color='primary' outline>
                                                <svg width="18" height="20" viewBox="0 0 22 22" fill="#81C14B" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_18256_354588)">
                                                        <path d="M18.8283 4.79417L17.5542 3.25417C17.3067 2.9425 16.9308 2.75 16.5 2.75H5.5C5.06917 2.75 4.69333 2.9425 4.43667 3.25417L3.17167 4.79417C2.90583 5.10583 2.75 5.51833 2.75 5.95833V17.4167C2.75 18.425 3.575 19.25 4.58333 19.25H17.4167C18.425 19.25 19.25 18.425 19.25 17.4167V5.95833C19.25 5.51833 19.0942 5.10583 18.8283 4.79417ZM5.72 4.58333H16.28L17.0225 5.4725H4.98667L5.72 4.58333ZM4.58333 17.4167V7.33333H17.4167V17.4167H4.58333ZM12.3292 9.16667H9.67083V11.9167H7.33333L11 15.5833L14.6667 11.9167H12.3292V9.16667Z" fill="#81C14B" />
                                                    </g>
                                                </svg>
                                            </Button>

                                            <UncontrolledTooltip placement='top' target='positionBottomArchives'>
                                                <h6>Archives</h6>
                                            </UncontrolledTooltip>
                                        </NavLink> */}

                            {/* <NavLink to='/ArchivedShotzList'>
                                            <Button id='positionBottomArchives' color='primary' outline>
                                                <Archive size={18} />
                                            </Button>

                                            <UncontrolledTooltip placement='top' target='positionBottomArchives'>
                                                <h6>Archives</h6>
                                            </UncontrolledTooltip>
                                        </NavLink> */}


                            {/* </div> */}
                            {/* </Col>
                            </Row> */}
                            <ExploreShotzGrid />
                        </CardBody>
                    </Card>
                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default ExploreShotz