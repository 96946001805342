import * as types from '../../Constants/index';
import axios from 'axios';



export const loadAllGroupById = (userId,pageIndex,pageSize,searchKey) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_GROUPS_MAINPAGE, payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllListOwnedPinnedGroupPage`,
            { userId: userId||user.id, pageIndex:pageIndex,pageSize:pageSize,searchKey:searchKey||''},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_GROUPS_MAINPAGE_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_ALL_GROUPS_MAINPAGE_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const loadAllJoinedGroup = (userId,pageIndex,pageSize,searchKey) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_GROUPS_JOINED_GROUP, payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllListJoinedPinnedGroupPage`,
            { userId: userId||user.id, pageIndex:pageIndex, pageSize:pageSize,searchKey:searchKey||'' },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_GROUPS_JOINED_GROUP_SUCCESS, payload: data.data.successResult.rows });

    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_ALL_GROUPS_JOINED_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const loadAllInvitationGroup = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_GROUPS_INVITATION,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getUserRequestReceived`,
            { userId:user.id, type:'Group', pageIndex:0, pageSize:3 },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_GROUPS_INVITATION_SUCCESS, payload: data });

    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_ALL_GROUPS_INVITATION_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};



export const loadAllSuggestedGroup = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_SUGGESTED_GROUP, payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllSuggestedGroupPageList`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_SUGGESTED_GROUP_SUCCESS, payload: data });

    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_ALL_SUGGESTED_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const loadAllGroupReportOption = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_ALL_REPORT_OPTION_GROUP, payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/admin/getReportOptions`,
            { userId: user.id, type: 'groupReport' },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_REPORT_OPTION_GROUP_SUCCESS, payload: data });

    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_ALL_REPORT_OPTION_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const sendingSelectedReport = (radioId, fetchGroupId, remark) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_REPORT_TO_GROUP,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/report`,
            { groupId: fetchGroupId, reportedBy: user.id, reportOptionId: radioId, remark: remark },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.POST_REPORT_TO_GROUP_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.POST_REPORT_TO_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const selectGroupById = (fetchGroupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_GROUP_BY_ID,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/getById`,
            { groupId: fetchGroupId, userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_GROUP_BY_ID_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_GROUP_BY_ID_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const createGroupPost = (fetchGroupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.CREATE_GROUP_POST,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/post/create`,
            { groupId: fetchGroupId, userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.CREATE_GROUP_POST_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.CREATE_GROUP_POST_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getTrendingTags = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_TRENDING_TAGS_TO_GROUP,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hashtag/getTrending`,
            { userId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_TRENDING_TAGS_TO_GROUP_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_TRENDING_TAGS_TO_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getallGroupsAdminsEct = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_All_GROUP_ADMIN_AND_MANAGERS,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/administrater/get`,
            { groupId: groupId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_All_GROUP_ADMIN_AND_MANAGERS_SUCCESS, payload: data.data.successResult });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_All_GROUP_ADMIN_AND_MANAGERS_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const detailsAllAboutGroup = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_All_ABOUT_GROUP,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/about`,
            { groupId: groupId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_All_ABOUT_GROUP_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        // dispatch({
        //     type: types.GET_All_ABOUT_GROUP_SUCCESS_ERROR,
        //     payload:
        //     error.response && error.response.data.message
        //         ? error.response.data.message
        //         : error.message,
        // })
    }
};



export const getAllGroupMembers = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_All_ABOUT_GROUP_MEMBERS,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/getGroupMemberDetails`,
            { userId: user.id, groupId: groupId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_All_ABOUT_GROUP_MEMBERS_SUCCESS, payload: data });
    } catch (error) {
        console.log('error', error)

        dispatch({
            type: types.GET_All_ABOUT_GROUP_MEMBERS_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const postPinToGroupAction = (groupId, grpAdminId, isPinned) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_PIN_FROM_GROUP,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/add/pinned/group`,
            { userId: user.id, groupId: groupId, grpAdminId: user.id, isPinned: isPinned },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.POST_PIN_FROM_GROUP_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.POST_PIN_FROM_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getPinFromGroupAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_PIN_FROM_GROUP,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/get/pinned/group`,
            { userId: user.id, groupId: groupId, grpAdminId: user.id },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_PIN_FROM_GROUP_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_PIN_FROM_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getAllBlockedGroupUsersAction = (groupId) => async (dispatch, getState) => {

    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_BLOCKED_GROUP_USERS,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/member/block/getAll`,
            { userId: user.id, groupId: groupId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_BLOCKED_GROUP_USERS_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

        dispatch({
            type: types.GET_BLOCKED_GROUP_USERS_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getAllJoinedGroupRequestAction = (groupId) => async (dispatch, getState) => {

    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_JOINED_REQUEST_FROM_GROUP,  payload: true  });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/member/joinRequets/getAll`,
            { userId: user.id, groupId: groupId },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_JOINED_REQUEST_FROM_GROUP_SUCCESS, payload: data });
    } catch (error) {
        console.log(error)

            dispatch({
                type: types.GET_JOINED_REQUEST_FROM_GROUP_ERROR,
                payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            })
        }
};

export const getAllPostRequestAction = (groupId) => async (dispatch, getState) => {
    try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            dispatch({ type: types.GET_POST_REQUEST_FROM_GROUP,  payload: true  });
            let { data } = await axios.post(
                `${process.env.REACT_APP_IPURL}/group/post/getForReview`,
                { userId: user.id, groupId: groupId },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                    dispatch({ type: types.GET_POST_REQUEST_FROM_GROUP_SUCCESS, payload: data });
        } catch (error) {
            console.error(error);
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : 'Something went wrong. Please try again later.';
            dispatch({
                type: types.GET_POST_REQUEST_FROM_GROUP_ERROR,
                payload: errorMessage,
            });
        }
};



export const getInvitedPeopleToModalAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GET_INVITED_PEOPLE_TO_MODAL,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/invitedPeople/getAll`,
            {groupId: groupId},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GET_INVITED_PEOPLE_TO_MODAL_SUCCESS, payload: data.data.successResult
            });
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.GET_INVITED_PEOPLE_TO_MODAL_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const postInvitationToOwnedGroupAction = (groupId, friendsId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_INVITE_JOIN_OWNED_GROUP,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/invitePeople`,
            {groupId: groupId, grpAdminId:user.id, userIds:friendsId},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.POST_INVITE_JOIN_OWNED_GROUP_SUCCESS, payload: data});
            } catch (error) {

                dispatch({
                    type: types.POST_INVITE_JOIN_OWNED_GROUP_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const unFollowFromJoinedGroupAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.UN_FOLLOW_FROM_JOINED_GROUP,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/leave`,
            {groupId: groupId, memberId: user.id, isLeave:true},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.UN_FOLLOW_FROM_JOINED_GROUP_SUCCESS, payload: data});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.UN_FOLLOW_FROM_JOINED_GROUP_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const followGroupAction = (userId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.FOLLOW_GROUP_FROM_GROUP_INVITATION,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamburgermenu/acceptRequest`,
            {followedById:userId, type:'Group'},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.FOLLOW_GROUP_FROM_GROUP_INVITATION_SUCCESS, payload: data.success});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.FOLLOW_GROUP_FROM_GROUP_INVITATION_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const declineGroupAction = (id) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.DECLINE_GROUP_FROM_GROUP_INVITATION,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamburgermenu/declineRequest`,
            {followingRequestId:id, type:'People'},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.DECLINE_GROUP_FROM_GROUP_INVITATION_SUCCESS, payload: data.success});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.DECLINE_GROUP_FROM_GROUP_INVITATION_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const createNewGroupAction = (groupInfo) => async (dispatch, getState) => {
    const {groupName,privacy,description,category,image} = groupInfo

    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.CREATE_NEW_GROUP,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/create`,
            { 
                userId: user.id, 
                name:groupName, 
                privacy:privacy, 
                grpCategoryId:category, 
                coverPic:image, 
                coverThumb:image,
                description:description, 
                visibility:true, 
                allowMessage:true, 
                allowNotificationOnEmail:true,
                blockMessage : true
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.CREATE_NEW_GROUP_SUCCESS, payload: data.data.successResult});
            } catch (error) {
           
                dispatch({
                    type: types.CREATE_NEW_GROUP_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const toJoinSuggestedGroupAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.JOIN_SUGGESTED_GROUP,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/member/create`,
            {groupId:groupId, memberId:user.id, staus:'pending'},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.JOIN_SUGGESTED_GROUP_SUCCESS, payload: data.success});
            } catch (error) {
                dispatch({
                    type: types.JOIN_SUGGESTED_GROUP_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const deleteGroupByPasswordAction = (password) => async (dispatch, getState) => {
    
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.DELETE_GROUP_BY_PASSWORD,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/public/confirmPassword`,
            {userId:user.id, password:password},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            let notAvailable = data.data.errorResult
            let isAvailable = data.data.successResult

            if(typeof notAvailable !=='undefined'){
                dispatch({ type: types.DELETE_GROUP_BY_PASSWORD_SUCCESS, payload: data.data.errorResult});
            }
            if(typeof isAvailable !=='undefined'){
                dispatch({ type: types.DELETE_GROUP_BY_PASSWORD_SUCCESS, payload: data.data.successResult});
            }

            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.DELETE_GROUP_BY_PASSWORD_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};


export const deleteGroupByUserAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.DELETE_GROUP_BY_USER,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/delete`,
            {groupId:groupId},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.DELETE_GROUP_BY_USER_SUCCESS, payload: data.success});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.DELETE_GROUP_BY_USER_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const grpSettingsAllInOneAction = (groupId, grpType, pinOn) => async (dispatch, getState) => {
    let state
    if(pinOn === 0){
        state = true
    }else{
        state = false
    }
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_SETTINGS_ALL_IN_ONE,  payload: true });
        let { data } = await axios.put(
            `${process.env.REACT_APP_IPURL}/group/update/details`,
            {id:groupId, visibility: state},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GROUP_SETTINGS_ALL_IN_ONE_SUCCESS, payload: {data : data.data.successResult, mode: pinOn}});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.GROUP_SETTINGS_ALL_IN_ONE_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const grpReviewPostAction = (groupId, grpType, grpPostReview) => async (dispatch, getState) => {
    let state
    if(grpPostReview === true){
        state = false
    }else{
        state = true
    }
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_REVIEW_POST,  payload: true });
        let { data } = await axios.put(
            `${process.env.REACT_APP_IPURL}/group/update/details`,
            {id:groupId, isPostReviewed: state},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GROUP_REVIEW_POST_SUCCESS, payload: {data : data.data.successResult, mode: state}});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.GROUP_REVIEW_POST_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};


export const grpReviewMemberAction = (groupId, grpType, grpReviewMember) => async (dispatch, getState) => {
    let state
    if(grpReviewMember === true){
        state = false
    }else{
        state = true
    }
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_REVIEW_MEMBER,  payload: true });
        let { data } = await axios.put(
            `${process.env.REACT_APP_IPURL}/group/update/details`,
            {id:groupId, isMemberReviewed: state},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GROUP_REVIEW_MEMBER_SUCCESS, payload: {data : data.data.successResult, mode: state}});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.GROUP_REVIEW_MEMBER_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};


export const grpSharedToStoriesAction = (groupId, grpType, grpSharedToStories) => async (dispatch, getState) => {
    let state
    if(grpSharedToStories === true){
        state = false
    }else{
        state = true
    }
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_SHARED_TO_STORIES,  payload: true });
        let { data } = await axios.put(
            `${process.env.REACT_APP_IPURL}/group/update/details`,
            {id:groupId, isPostSharedToStory: state},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GROUP_SHARED_TO_STORIES_SUCCESS, payload: {data : data.data.successResult, mode: state}});
            } catch (error) {
                console.log('***ERROR***',error)
                dispatch({
                    type: types.GROUP_SHARED_TO_STORIES_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};


export const updateGroupPostByIdAction = (groupId, groupInfo) => async (dispatch, getState) => {
    const {groupName,privacy,description,category,image} = groupInfo
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.UPDATE_GROUP_POST,  payload: true  });
        let { data } = await axios.put(
            `${process.env.REACT_APP_IPURL}/group/update/details`,
            { 
                id: groupId, 
                name:groupName, 
                privacy:privacy, 
                description:description, 
                grpCategoryId:category, 
                coverPic:image, 
                coverThumb:image,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.UPDATE_GROUP_POST_SUCCESS, payload: data.successResult});
    } catch (error) {
        dispatch({
            type: types.UPDATE_GROUP_POST_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
};

export const getAllGeneralNotificationSettingsAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_GET_GENERAL_SETTINGS,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/get/generalSettings`,
            {groupId:groupId},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GROUP_GET_GENERAL_SETTINGS_SUCCESS, payload: data.data.successResult[0]});
            } catch (error) {
                dispatch({
                    type: types.GROUP_GET_GENERAL_SETTINGS_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const getAllGeneralNotificationAction = (groupId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_SOCIOMEE_NOTIFICATION_SETTINGS,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/notification/settings/getAll`,
            {groupId:groupId},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.GROUP_SOCIOMEE_NOTIFICATION_SUCCESS, payload: data.data.successResult
            });
            } catch (error) {
                dispatch({
                    type: types.GROUP_SOCIOMEE_NOTIFICATION_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};



export const setNotificationToGroupSettingsAction = (checkedOne, grpId, id, notificationType) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.SET_NOTIFICATION_GENERAL_SETTINGS,  payload: true });
        let { data } = await axios.put(
            `${process.env.REACT_APP_IPURL}/group/notification/settings/update`,
            {
                groupId: grpId,
                grpAdminId: user.id,
                isAllowed: checkedOne,
                id:id,
                notificationType:notificationType,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            dispatch({ type: types.SET_NOTIFICATION_GENERAL_SETTINGS_SUCCESS, payload: data.data.successResult
            });
            } catch (error) {
                console.log('error', error)
                dispatch({
                    type: types.SET_NOTIFICATION_GENERAL_SETTINGS_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const setNotificationInGeneralTabAction = (checkedOne, grpId, id) => async (dispatch, getState) => {
    console.log('CheckedOne', checkedOne, grpId, id)
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.SET_NOTIFICATION_GENERAL_TAB,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/notification/settings/set`,
            {
                groupId: grpId,
                grpAdminId: user.id,
                isOn: checkedOne,
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('data*', data)
            dispatch({ type: types.SET_NOTIFICATION_GENERAL_TAB_SUCCESS, payload: data.data.successResult
            });
            } catch (error) {
                console.log('error', error)
                dispatch({
                    type: types.SET_NOTIFICATION_GENERAL_TAB_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};

export const groupActivityHistoryAction = (grpId,pageIndex,pageSize) => async (dispatch, getState) => {
console.log('***')
console.log(grpId,pageIndex,pageSize)
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.GROUP_ACTIVITY_HISTORY,  payload: true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/management/history/getAll`,
            {
                groupId:grpId,
                pageIndex:pageIndex,
                pageSize:pageSize,            
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            dispatch({ type: types.GROUP_ACTIVITY_HISTORY_SUCCESS, payload: data.data.errorResult});

            // if(data.success === false){
            //     console.log('removeError',data?.data?.errorResult)
            //     dispatch({ type: types.GROUP_ACTIVITY_HISTORY_UN_SUCCESS, payload: data.data.errorResult})
            // }
            // else if(data.success === true){
            //     dispatch({ type: types.GROUP_ACTIVITY_HISTORY_SUCCESS, payload: 'Assign Role ' + data.data.successResult });
            // }
        } catch (error) {
            console.log('dfd', error)
                 dispatch({
                    type: types.GROUP_ACTIVITY_HISTORY_ERROR,
                    payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                })
            }
};





export const getAllGroupCategoryAction = () => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        const cofig = {
            headers:{'Authorization': `Bearer ${user?.token}`}
        }
        const {data} = await axios.get(`${process.env.REACT_APP_IPURL}/group/category/getAll`,
        cofig    
        )      
        dispatch({ type: types.GET_ALL_CATEGORY_FROM_GROUP_SUCCESS, payload: data.data.successResult });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_CATEGORY_FROM_GROUP_ERROR,
            payload:
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }       

};

export const postGroupRolesAction = (sendRole,roleEdit, roleUpdate, roleDelete, userId, params) => async (dispatch, getState) => {
  console.log('imHere....')
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_GROUP_ROLE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/administrater/add`,
            {
                userId: userId,
                groupId:params,
                role:sendRole,
                roleAdd:roleEdit,
                roleUpdate:roleUpdate,
                roleDelete:roleDelete
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('removeError',data)
            if(data.success === false){
                console.log('removeError',data.data.errorResult)
                dispatch({ type: types.POST_GROUP_ROLE_UN_SUCCESS, payload: data.data.errorResult})
            }
            else if(data.success === true){
                dispatch({ type: types.POST_GROUP_ROLE_SUCCESS, payload: 'Assign Role ' + data.data.successResult });
            }
            
    } catch (error) {
        dispatch({
            type: types.POST_GROUP_ROLE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};


export const removeGroupRolesAction = ( userId, params) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.REMOVE_GROUP_ROLE, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/group/administrater/remove`,
            {
                groupId: params,
                userId: userId,
                isDeleted : true
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('Removed Data', data)
                dispatch({ type: types.REMOVE_GROUP_ROLE_SUCCESS, payload: data.data.successResult });            
    } catch (error) {
        console.log('postGroupRolesAction error:', error);
        dispatch({
            type: types.REMOVE_GROUP_ROLE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

export const unBlockGroupUserAction = (blockedUserId) => async (dispatch, getState) => {
    try {
        let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        dispatch({ type: types.POST_UN_BLOCK_USER, payload:true });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/setBlockUser`,
            {
                blockedUserId:[blockedUserId],
                isBlocked:true, 
            },
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
            console.log('data',data.data.successResult)
                dispatch({ type: types.POST_UN_BLOCK_USER_SUCCESS, payload: data.data.successResult });            
    } catch (error) {
        dispatch({
            type: types.POST_UN_BLOCK_USER_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
};

