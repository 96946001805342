import React from 'react'
import { Fragment, useState } from 'react'
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import '../style.css'
import MyDiscussion from './MyDiscussion'
import MyMember from './MyMember'
import MyAbout from './MyAbout'
import MySettings from './MySettings'
import GroupMember from '../GrpDiscussionPanel/GrpDetailsPages/GroupMember'
import GrpMember from '../GrpDiscussionPanel/GrpDetailsPages/GrpMember'
import { Link } from 'react-router-dom'
import { Settings } from 'react-feather'

const GroupDetailMenu = () => {
    const [currentActiveTab, setCurrentActiveTab] = useState('1')

    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    }

     // ================== main_return_function==================//
    return (
        <Fragment>
            <Row className="GroupDetail_tab_Section mt-3">
                <Col md="8">
                    <Nav pills style={{ margin: '1%' }}>
                        <NavItem style={{
                            background: "rgba(129, 193, 75, 0.2)",
                            borderRadius: "5px",
                            marginRight: "15px"
                        }}>
                            <NavLink
                                className={
                                    classnames({
                                        active: currentActiveTab === '1'
                                    })}
                                onClick={() => { toggle('1') }}
                            >
                                <span>Discussion</span>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{
                            background: "rgba(129, 193, 75, 0.2)",
                            borderRadius: "5px",
                            marginRight: "15px"
                        }}>
                            <NavLink
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2') }}
                            >
                                <span>Members</span>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{
                            background: "rgba(129, 193, 75, 0.2)",
                            borderRadius: "5px",
                            marginRight: "15px"
                        }}>
                            <NavLink
                                className={
                                    classnames({
                                        active: currentActiveTab === '3'
                                    })}
                                onClick={() => { toggle('3') }}
                            >
                                <span>About</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md="4" className="d-flex align-items-center justify-content-end ">
                    <div className='mr-2'>
                        <Link to="/Group/create"><Button outline color="success" className="Btn_CreateGroup"><p> + Create Group</p></Button></Link>

                    </div>
                    <Nav pills style={{ margin: '1%' }}>
                        <NavItem style={{
                            background: "rgba(129, 193, 75, 0.2)",
                            borderRadius: "5px",
                            marginRight: "15px"
                        }}>
                            <NavLink
                                className={
                                    classnames({
                                        active: currentActiveTab === '4'
                                    })}
                                onClick={() => { toggle('4') }}
                            >
                                <span><Settings size={16} />&nbsp; Setting</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>
            <Row className=" mt-3 ">
                <Col>
                    <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                            <MyDiscussion />
                        </TabPane>
                        <TabPane tabId="2">
                            <MyMember />
                        </TabPane>
                        <TabPane tabId="3">
                            <MyAbout />
                        </TabPane>
                        <TabPane tabId="4">
                            <MySettings />
                        </TabPane>
                    </TabContent>
                </Col>

            </Row>
        </Fragment>
    )
}

export default GroupDetailMenu