
import React from 'react'
import { Fragment } from 'react'
import Slider from "@mui/material/Slider";
import MpFilterValueInput from './MpFilterValueInput';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { useState } from 'react';
import { useTheme } from "@mui/material"



const MpLocationRange = (props) => {


    // const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        props.setValue(newValue);
        props.setSliderValue(newValue);
    };
    const theme = useTheme();
    // const mainIconColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF';

    // const [sliderValue, setSliderValue] = useState(0);

   
  
 

  




    return (
        <Fragment>
            <AccordionBody style={{ borderBottom: "1px solid #E5E5E5 " }}>
                <div className="MpUploadHeading" >
                    <h3 className='Mp_Color4D_Font6'>Location Range</h3>
                    {/* <h5 className='MrktClearAllBtn' onClick={props.handleClearAll}>clear all</h5> */}
                </div>
                <div className='mt-2 mb-2+'>
                    <Slider
                        aria-label="Volume"
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        max={15000}
                        defaultValue={15000}
                        value={props.sliderValue}
                        sx={{
                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                            '& .MuiSlider-track': {
                                border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                width: 24,
                                height: 24,
                                backgroundColor: '#fff',
                                '&:before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                },
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                },
                            },
                        }}
                    />
                </div>
                {/* <MpFilterValueInput value={props.value} options={["Km", "Mi", "M"]} /> */}
                <MpFilterValueInput value={props.value} options={[ "Mi"]} />

            </AccordionBody>
        </Fragment>
    )
}

export default MpLocationRange
