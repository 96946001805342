import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";
import { loadAllPostsByUserId } from "../SocialFeed/getAllPostsByUserIdAction";

const getAllUserPosts = (allUserPosts) => ({
  type: types.GET_ALL_USER_POSTS,
  payload: allUserPosts,
});

const postAdded = () => ({
  type: types.CREATE_POST,
});

const postDeleted = () => ({
  type: types.DELETE_POST,
});

// get all user post
export const loadAllUserPosts = (searchKey, pageIndex = 0, pageSize = 10, userId) => {
  return async (dispatch) => {
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;

      const user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: { Authorization: `Bearer ${user?.token}` },
      };

      if (user) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getFeedPosts`,
            {
              searchKey: searchKey,
              pageIndex: pageIndex,
              pageSize: pageSize,
              lat: latitude,
              long: longitude,
              postType: "",
              userId: userId || user.id,
            },
            config
          );
          
          const final_response = response.data.data?.successResult?.rows || [];

          if (pageIndex === 0) {
            dispatch({
              type: types.GET_ALL_USER_POSTS,
              payload: {
                posts: final_response,
                pageIndex: pageIndex,
              },
            });
          } else {
            dispatch({
              type: types.LOAD_MORE_USER_POSTS,
              payload: {
                posts: final_response,
                pageIndex: pageIndex,
              },
            });
          }
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.log("Token expired or session invalid, redirecting to login...");
            //here we adding this redirection only to social feed api because  when we are working there are multiple api already implimented with axios it  tough to create instance 
            // so we are addding to social feed  of api weill retun 403  so we will redirect user  to  login page 
            localStorage.removeItem('sociomeeUser'); // Clear user data from localStorage
            window.location.href = '/'; // Redirect to login page
          } 
        }
      }
    } catch (error) {
      console.error("Error loading user posts:", error);
    }
  };
};
// Utility function to get the current position
const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      reject(new Error("Geolocation is not supported by your browser."));
    }
  });
};

// add post

export const addPost = (post) => {
  if (post.alertLevelId) {
    post.postType = "alert";
  }

  const user = JSON.parse(localStorage.getItem("sociomeeUser"));

  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/createPost/`, post, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((res) => {
        // Check if the response indicates a successful post creation
        if (res?.data?.success) {
          // Dispatch the action to update state
          dispatch(postAdded());

          // Load all user posts again to reflect the new post
          dispatch(loadAllUserPosts({ pageIndex: 0, pageSize: 3 }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// delete post
export const deletePost = (id) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/deletePost/`, id, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((res) => {
        console.log("delete post response :", res);
        dispatch(postDeleted(res.data));
        dispatch(loadAllUserPosts({ pageIndex: 0, pageSize: 3 }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// undo delete post
export const undoDeletePost = (id) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/undoPostDelete`, id, {
        headers: { Authorization: `Bearer ${user?.token}` },
      })
      .then((res) => {
        dispatch(loadAllUserPosts({ pageIndex: 0, pageSize: 3 }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
