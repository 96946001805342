// import React from 'react'
// import Header from '../Header';
// import LeftSidebar from '../LeftSidebar';
// import RightSidebar from '../RightSidebar';
// import BizDetailMenu from './BizDetailMenu';
// import { Fragment } from 'react';
// import { Row } from 'reactstrap';
// import LeftSideCmponents from './LeftSideCmponents';
// import MySuggestedBiz from './Components/MySuggestedBiz';
// import MyBizPhotos from './Components/MyBizPhotos';
// import MyLatestReviews from './Components/MyLatestReviews';
// import TopBanner from './TopBannerSection/TopBanner';
// import BizDetailSuggestedCard from './Components/BizDetailSuggestedCard';



// const BizDetail = () => {



//     //__--------Main_return_function-----------//
//     return (
//         <Fragment>
//             <Header />
//             <div className="page-body container-fluid profile-page">

//                 <LeftSidebar />
//                 <div className="page-center">
//                     <TopBanner />
//                     <BizDetailMenu />
//                     <div className="container-fluid section-t-space px-0">
//                         <Row>
//                             <div className="content-left col-4 res-full-width">
//                                 <div className="profile-about">
//                                     <LeftSideCmponents
//                                         title="Overview "
//                                     />
//                                 </div>
//                             </div>
//                             <div className="content-center col-xl-8 mb-3">
//                                 <div className="about-profile section-b-space">
//                                     <MyBizPhotos />
//                                 </div>
//                                 <div className="about-profile section-b-space">
//                                     <MyLatestReviews />
//                                 </div>
//                                 <div className="">
//                                     <BizDetailSuggestedCard />
//                                 </div>

//                             </div>
//                         </Row>
//                     </div>


//                 </div>
//                 <RightSidebar />
//             </div>

//         </Fragment>
//     )
// }

// export default BizDetail



import React from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import TopBanner from './TopBannerSection/TopBanner'
import BizDetailMenu from './BizDetailMenu';
import { Fragment } from 'react';
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap';
import Slider from 'react-slick';
import Post from './Img/Post.png'
import ReviewImg from './Img/Post1.png'
import Post2 from './Img/ReviewImg.png'
import BizOwnImg from './Img/BizOwnImg.png'
import BizOwnImg1 from './Img/BizOwnImg1.png'
import BizOwnImg2 from './Img/BizOwnImg2.png'
import BizOwnImg3 from './Img/BizOwnImg3.png'
import RevImg from './Img/review.png'
import LeftSideCmponents from './LeftSideCmponents';
import { Rating, Stack } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { XCircle } from 'react-feather';
import AvatarGroup from '../../NewComponents/AvatarGroup';
import { useState } from 'react';
import BizDetailSuggestedCard from './Components/BizDetailSuggestedCard';
import "./biz.css"
let bizSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [

        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2
            }
        }
    ]
};
let suggestedSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [

        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2
            }
        }
    ]
};
let ReviewSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [

        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

const BizDetail = () => {
    const [askOpen, setAskOpen] = useState(false)
    const toggleAsk = () => {
        setAskOpen((preState) => !preState)
    }


    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (img) => {
        setSelectedImage(img);
        setShowModal(true);
    };

    const [expandedIndices, setExpandedIndices] = useState([]);

    const toggleExpand = (index) => {
        const isExpanded = expandedIndices.includes(index);
        if (isExpanded) {
            setExpandedIndices(expandedIndices.filter((i) => i !== index));
        } else {
            setExpandedIndices([...expandedIndices, index]);
        }
    };

    function truncateWords(str, numWords) {
        const words = str.split(' ');
        if (words.length > numWords) {
            return words.slice(0, numWords).join(' ') + '...';
        }
        return str;
    }


    const PhotoDataArray = [
        {
            img: Post,
            img1: ReviewImg,
            reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations.",
            value: 4
        },
        {
            img: Post2,
            img1: ReviewImg,
            reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations .",
            value: 3
        },
        {
            img: Post,
            img1: ReviewImg,
            reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations .",
            value: 2
        },
        {
            img: Post2,
            img1: ReviewImg,
            reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations .",
            value: 4

        },
        {
            img: Post,
            img1: ReviewImg,
            reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations.",
            value: 1
        },
        {
            img: Post2,
            img1: ReviewImg,
            reviewData: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations.",
            value: 5
        }

    ]

    const BizAllData = [
        {
            img: BizOwnImg,
            typeof: "Pin",
            cardName: "Traditional Wedding ",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg1,
            cardName: "Action Pack",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg2,
            cardName: "Traditional Wedding ",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg3,
            typeof: "Pin",
            cardName: "Action Pack",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg1,
            cardName: "Traditional Wedding ",
            category: "Music Equipment"
        }
    ]

    const OverViewPhotos = () => {
        const [setModal, setSetModal] = useState(false);
        const [selectedImage, setSelectedImage] = useState('');

        const toggleModal = () => {
            setSetModal(!setModal);
        };

        const handleImageClick = (img) => {
            setSelectedImage(img);
            toggleModal();
        };

        const handleCloseModal = () => {
            toggleModal();
        };

        const SetupModal = () => {
            return (
                <Modal isOpen={setModal} toggle={toggleModal} centered className='bizpagepicmodal'>
                    <div onClick={handleCloseModal} className="Modal_False_blk1">
                        <XCircle color="#FFFFFF" />
                    </div>
                    <div>
                        <img src={selectedImage} style={{ width: '100%' }} alt="selected" />
                    </div>
                </Modal>
            );
        };

        return (
            <Fragment>
                <div className="about-profile-box-blk">
                    <div className="card-title d-flex justify-content-between" style={{ paddingBottom: "0px" }} >
                        <h3 className='viewphoto'>Photos (28)</h3>
                        {/* <NavLink to="/CataloguesPhoto"><p>See all →</p></NavLink> */}
                    </div>
                    <div className='p-3'>
                        <Slider {...bizSettings} className="default-space">
                            {PhotoDataArray.map((data, index) => (
                                <div key={index}>
                                    <Card className="Overview_Biz_img" onClick={() => handleImageClick(data.img)}>
                                        <img src={data.img} alt="overview" className="Biz_OverviewImg_section" />
                                    </Card>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

                <SetupModal />
            </Fragment>
        );
    };
    const LatestReview = () => (
        <Fragment>
            <div className="about-profile-box-blk">
                <div className="card-title d-flex justify-content-between" style={{ paddingBottom: "0px" }}>
                    <h3 className='viewphoto'>Latest Reviews </h3>
                    {/* <NavLink to="/BizReview"><p >See all →</p></NavLink> */}
                </div>
                <div className='p-3'>
                    <Slider {...ReviewSettings} className="default-space">
                        {PhotoDataArray.map((data, index) => (
                            <div key={index} className='gdhhsgd'>
                                <div className='Lates_reviews_section'>

                                    <div className='Review_section'>
                                        <img src={data.img1} alt="review_img" className='Review_img_box' />
                                        <div className='ml-311'>
                                          <p>{truncateWords(data.reviewData, 20)}</p>
                                            <div className='bizratingrev'>
                                                <Rating className='py-2' name="read-only" value={data.value} readOnly size='small' />&nbsp;&nbsp;<span>4.2</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </Slider>
                    <div className='py-4'>
                        <h3 className='bizenq'  >Your Reviews summary</h3>
                        <div className=' p-2'>
                            <Row >
                                <Col md="8">
                                    <div>
                                        <span className='d-flex text-muted mt-2'>5 <Progress className="Biz_Progress_Bar barcolor  w-100 ml-2" value={100} style={{}} /></span>
                                        <span className='d-flex text-muted mt-2'>4 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={75} /></span>
                                        <span className='d-flex text-muted mt-2'>3 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={50} /></span>
                                        <span className='d-flex text-muted mt-2'>2 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={25} /></span>
                                        <span className='d-flex text-muted mt-2'>1&nbsp; <Progress className="Biz_Progress_Bar  w-100 ml-2" value={60} /></span>
                                    </div>
                                </Col>
                                <Col md="4" className="d-flex justify-content-center align-items-center">
                                    <div className='text-center'>
                                        <h1>4.9</h1>
                                        <Rating className='py-2' name="read-only" value={4} readOnly size='small' />
                                        <p className='text-muted'>(31)</p>
                                    </div>
                                </Col>
                                {/* <div className='py-4 d-flex align-items-center'>
                                    <img src={RevImg} className="rounded-circle" width={50} />
                                    <div className='ml-3' onClick={toggleAsk}>
                                        <Button color='primary'>Rate this page </Button>
                                    </div>
                                </div> */}
                            </Row>
                        </div>
                        <hr className='text-muted' />
                        <div>
                            <h3 className='bizenq'>Enquiry about your service/product</h3>
                            <div className='p-4'>
                                <h4>Q: Do you make custom cakes?</h4>
                                <h4 className='py-2'>A: Yes! we make custom cakes.</h4>
                            </div>
                        </div>
                        <hr className='text-muted' />
                        <Link className='BizReview_Link' to="#">Biz page Policy</Link>
                    </div>

                </div>
            </div>

        </Fragment>
    )
    // const SuggestedCardData = () => (
    //     <>
    //         <div className="container-fluid section-t-space px-0  ">
    //             <div className="group-main-block">
    //                 <div className="group-custom-block">
    //                     <div className="heading-podcast-blk">
    //                         <h3>Suggested Pages (61)</h3>
    //                         <NavLink to="/BizSuggested" className="single-ancor-blk">See All →</NavLink>
    //                     </div>
    //                 </div>
    //             </div>
    //             <Slider {...suggestedSettings} className="default-space">
    //                 {
    //                     BizAllData.map((data) => (
    //                         <div>
    //                             <div className="group-slide-box">
    //                                 <div className="gallery-img-sublink">
    //                                     <XCircle size={20} color="#FFFFFF" />
    //                                 </div>
    //                                 <NavLink to="/BizDetail" className="bizpagelink-blk"><img src={data.img} className="img-fluid group-img" />
    //                                     <div className="group-adminprofile-blk"><img src="assets/images/post-6.jpg" className='rounded-circle' /></div></NavLink>
    //                                 <div className="group-content">
    //                                     <h4 className='CardTextHeading'>{data.cardName}</h4>
    //                                     <h5>{data.category}</h5>
    //                                     <div className='d-flex justify-content-center'>
    //                                         <Stack spacing={1}>
    //                                             <Rating name="read-only" defaultValue={2.5} readOnly />
    //                                         </Stack>
    //                                     </div>
    //                                     <div className="people-likes matual-friend-sec">
    //                                         <ul className="matual-friend-blk">
    //                                             <AvatarGroup />
    //                                         </ul>
    //                                         <h6>+79k Followers</h6>
    //                                     </div>
    //                                     <div className="group-buttons-blk">
    //                                         <Link to="#" className="group-btn">Follow</Link>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     ))
    //                 }

    //             </Slider>
    //         </div>
    //     </>
    // )
    //__--------Main_return_function-----------//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center ">
                    <div className="bizdetailprofile">
                        <TopBanner />

                    </div>
                    <div className="bizdetailprofile">
                        <BizDetailMenu />

                    </div>

                    <div className="container-fluid section-t-space px-0">
                        <Row>
                            <div className="content-left col-4 res-full-width aboutside">
                                <div className="profile-about bizdetailprofile"  >
                                    <LeftSideCmponents />
                                </div>
                            </div>
                            <div className="content-center col-xl-8 mb-3">
                                <div className="about-profile section-b-space bizdetailprofile">
                                    <OverViewPhotos />
                                </div>
                                <div className="about-profile section-b-space bizdetailprofile">
                                    <LatestReview />
                                </div>
                                <div className=" ">
                                    <BizDetailSuggestedCard />
                                </div>

                            </div>
                            <div className="content-left col-4 res-full-width aboutside1 ">
                                <div className="profile-about bizdetailprofile"  >
                                    <LeftSideCmponents />
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className='ASK_modal'>
                        {
                            askOpen !== false &&
                            <Modal isOpen={askOpen} toggle={toggleAsk} className='modal-dialog-centered'>
                                <ModalHeader className="Ask_Review_heading" >
                                    <h2>Ask a Question</h2>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='p-3'>
                                        <h4 className='pb-2 font-weight-bold'>Question</h4>
                                        <textarea placeholder='Type your answer here... ' className="form-control" name="" id="" cols="15" rows="5"></textarea>
                                        <p className='d-flex justify-content-end text-muted'>Max 240 charectors </p>
                                    </div>
                                    <div className='text-center'>
                                        <Button onClick={toggleAsk} outline>Cancel</Button>
                                        <Button onClick={toggleAsk} className=" ml-2" color="primary">Submit</Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        }
                    </div>

                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default BizDetail


