// import React from 'react'
// import { Fragment } from 'react'
// import Header from '../Header'
// import LeftSidebar from '../LeftSidebar'
// import LeftSideCmponents from './LeftSideCmponents'
// import BizDetailMenu from './BizDetailMenu';
// import { Row } from 'reactstrap'
// import RightSidebar from '../RightSidebar'
// import TopBanner from './TopBannerSection/TopBanner'
// import BizPostCreation from './Components/BizPostCreation'

// const BizPost = () => {
//     return (
//         <Fragment>
//             <Header />
//             <div className="page-body container-fluid profile-page">
//                 <LeftSidebar />
//                 <div className="page-center">
//                     <TopBanner />
//                     <BizDetailMenu />
//                     <div className="container-fluid section-t-space px-0">
//                         <Row>
//                             <div className="content-left col-4 res-full-width">
//                                 <div className="profile-about">
//                                     <LeftSideCmponents
//                                         title="Overview"
//                                     />
//                                 </div>
//                             </div>
//                             <div className="content-center col-xl-8">
//                                 <div className="about-profile ">
//                                     <BizPostCreation />
//                                 </div>
//                             </div>
//                         </Row>
//                     </div>

//                 </div>
//                 <RightSidebar />
//             </div>

//         </Fragment>
//     )
// }

// export default BizPost 

import React from 'react'
import { Fragment } from 'react'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import TopBanner from './TopBannerSection/TopBanner'
import LeftSideCmponents from './LeftSideCmponents'
import BizDetailMenu from './BizDetailMenu';
import { Row } from 'reactstrap'
import RightSidebar from '../RightSidebar'
import BizPostCreation from './Components/BizPostCreation'
import Text from '../Universal-components/post-creations/sub-components/Text'

const BizPost = () => {
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center bizdetailprofile">
                <TopBanner />
                    <BizDetailMenu />
                    <div className="container-fluid section-t-space px-0">
                        <Row>
                            <div className="content-left col-4 res-full-width">
                                <div className="profile-about catnone">
                                    <LeftSideCmponents />
                                </div>
                            </div>
                            <div className="content-center col-xl-8">
                                <div className="about-profile section-b-space">
                                    {/* <BizPostCreation /> */}
                                    <Text />
                                </div>
                            </div>
                        </Row>
                    </div>

                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default BizPost
 