import React, { useState, useEffect } from 'react'
import { AlertTriangle, ArrowLeft, ArrowRight, ChevronDown, CreditCard, Download, FileText, Filter, Grid, HelpCircle, Home, Layers, Plus, Search, Settings, Trash2, X } from 'react-feather';
import DataTable from 'react-data-table-component';
import { Badge, Button, Card, CardBody, CardHeader, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactPaginate from 'react-paginate';
import coverImage from '../../../../NewComponents/IMG/reel4.png'
import { NavLink } from 'react-router-dom';
import ChartIcon from '../../../../NewComponents/IMG/AdmangerIconChart.svg'
import { Pagination, Stack } from '@mui/material';

const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none', // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: '#E5F0E0',
            fontSize: '12px',
            paddingLeft: '6px', // override the cell padding for head cells
            paddingRight: '0px',
            whiteSpace: '',
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '4px', // override the cell padding for data cells
            paddingRight: '4px',
            // marginTop: '1%',
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: '400'
        }
    }
}

export default function AdManagerReportTablePage() {

    // const [loading, setLoading] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // const [searchValue, setSearchValue] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])

    const now = new Date();
    const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const [value, onChange] = useState([yesterdayBegin, todayEnd]);
    const [checkList, setCheckList] = useState({
        serialnoCheck: true,
        userName: true,
        dateTime: true,
        reachCheck: true,
        viewCheck: true,
        ImpressionsCheck: true,
        leadCheck: true,
        clickCheck: true,
        likeCheck: true,
        shareCheck: true,
        bookmarkCheck: true,
        platformCheck: true
    })

    // const handleSearch = (event) => {
    //   setSearchValue(event.target.value)
    //   setRender(!render)
    // }

    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }

    const [deleteallModal, setDeleteallModal] = useState(false)

    const DeletedModal = () => {
        return (
            <>
                <Modal isOpen={deleteallModal} className='modal-dialog-centered' toggle={() => setDeleteallModal(!deleteallModal)}>
                    <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader>
                    <ModalBody>
                        <div className='text-center mb-2'>
                            <Trash2 size={70} color="#EA5455" />
                            <h3 className='mt-2'>Do You Really Want to Delete all ?</h3>
                            <h5>{selectedRows.length}</h5>
                        </div>
                        <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the ‘Action History’ screen." />
                        </InputGroup>
                        <div className="d-flex justify-content-around m-1">
                            <div>  <Button type="submit" color="danger" outline >Yes , Delete it</Button></div>
                            <div><Button color="success" outline onClick={() => setDeleteallModal(!deleteallModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

    const tabledata = [
        {
            id: '1',
            name: 'New Traffic-Kickstarter',
            reach: '24',
            Impression: '21',
            Lead: '5',
            Click: '29',
            View: '48',
            Likes: '23',
            Shares: '21',
            Bookmarks: 'Brand Awareness (CPV)',
            Platform: 'Mobile App',
            PlatformType: 'Android',
            dateTime: '2022-11-05T11:13:00.000Z',
        },
        {
            id: '2',
            name: 'New Traffic-Kickstarter',
            reach: '24',
            Impression: '21',
            Lead: '5',
            Click: '29',
            View: '48',
            Likes: '23',
            Shares: '21',
            Bookmarks: 'Brand Awareness (CPV)',
            Platform: 'Website',
            PlatformType: 'Windows',
            dateTime: '2022-11-05T11:13:00.000Z',
        },
        {
            id: '3',
            name: 'New Traffic-Kickstarter',
            reach: '24',
            Impression: '21',
            Lead: '5',
            Click: '29',
            View: '48',
            Likes: '23',
            Shares: '21',
            Bookmarks: 'Brand Awareness (CPV)',
            Platform: 'Mobile App',
            PlatformType: 'IOS',
            dateTime: '2022-11-05T11:13:00.000Z',
        }
    ]

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
            omit: !checkList.serialnoCheck,
            sortable: true,
            center: true
        },

        {
            name: "Ad Name",
            selector: row => row.userName,
            minWidth: "220px",
            omit: !checkList.userName,
            sortable: true,
            cell: row => (
                <div >
                    <div className='align-items-center d-flex'>
                        <img className='border' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />
                        <div className='ml-2 fontForSubTablheading'>
                            <h4 style={{ fontSize: '13px' }}>{row.name}</h4>
                            <div className='d-flex text-primary align-items-center'>
                                <img src={ChartIcon} alt='' />
                                <NavLink className='ml-1' to={'/AdAnalysis'}><u>View Charts</u></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },


        {
            name: "Date & Time",
            minWidth: "100px",
            omit: !checkList.dateTime,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px' }}>{moment(row.DateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px' }}>{moment(row.DateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: 'Reach',
            sortable: true,
            omit: !checkList.reachCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-26px', fontSize: '12px' }}>{row.reach}</h5>
                </div>
            )

        },
        {
            name: 'View',
            sortable: true,
            omit: !checkList.viewCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-26px', fontSize: '12px' }}>{row.View}</h5>
                </div>
            )

        },
        {
            name: 'Impression',
            sortable: true,
            omit: !checkList.ImpressionsCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-55px', fontSize: '12px' }}>{row.Impression}</h5>
                </div>
            )

        },
        {
            name: 'Lead',
            sortable: true,
            omit: !checkList.leadCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-26px', fontSize: '12px' }}>{row.Lead}</h5>
                </div>
            )

        },
        {
            name: 'Click',
            sortable: true,
            omit: !checkList.clickCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-26px', fontSize: '12px' }}>{row.Click}</h5>
                </div>
            )

        },

        {
            name: 'Likes',
            sortable: true,
            omit: !checkList.likeCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-20px', fontSize: '12px' }}>{row.Likes}</h5>
                </div>
            )

        },
        {
            name: 'Shares',
            sortable: true,
            omit: !checkList.shareCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '-26px', fontSize: '12px' }}>{row.Shares}</h5>
                </div>
            )

        },
        {
            name: 'Ad Type',
            sortable: true,
            omit: !checkList.bookmarkCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubTablheading'>
                    <h5 style={{ marginLeft: '79px', fontSize: '12px', textWrap: 'nowrap' }}>{row.Bookmarks}</h5>
                </div>
            )

        },
        {
            name: "Status",
            minWidth: "200px",
            omit: !checkList.platformCheck,
            center: true,
            cell: row => (
                <div>
                    <p color="warning" className='badge-warning p-2' pill style={{ borderRadius: '20px', fontSize: '12px', marginLeft: '24px' }}>Pending</p>
                    {/* {row.status === 'Pending' ? <Badge color="warning" className='badge-warning' pill >{row.status}</Badge> : null}
                    {row.status === 'Rejected' ? <Badge color="danger" className='badge-danger' pill >{row.status}</Badge> : null}
                    {row.status === 'Approved' ? <Badge color='primary' pill >{row.status}</Badge> : null} */}
                </div>
            )
        },
        {
            name: 'Platform',
            sortable: true,
            omit: !checkList.platformCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className='text-center fontForSubchildCardheading'>
                    <h5 style={{ fontSize: '12px' }}>{row.Platform}</h5>
                    <h6>{row.PlatformType}</h6>
                </div>
            )

        }

    ]



    const checkListArr = [
        {
            check: {
                ...checkList,
                serialnoCheck: !checkList.serialnoCheck
            },
            state: checkList.serialnoCheck,
            name: "Sr. No"
        },
        {
            check: {
                ...checkList,
                userName: !checkList.userName
            },
            state: checkList.userName,
            name: "AdName"
        },
        {
            check: {
                ...checkList,
                dateTime: !checkList.dateTime
            },
            state: checkList.dateTime,
            name: "Date & Time"
        },
        {
            check: {
                ...checkList,
                reachCheck: !checkList.reachCheck
            },
            state: checkList.reachCheck,
            name: "Reachs"
        },
        {
            check: {
                ...checkList,
                viewCheck: !checkList.viewCheck
            },
            state: checkList.viewCheck,
            name: "Views"
        },
        {
            check: {
                ...checkList,
                ImpressionsCheck: !checkList.ImpressionsCheck
            },
            state: checkList.ImpressionsCheck,
            name: "Impressions"
        },
        {
            check: {
                ...checkList,
                leadCheck: !checkList.leadCheck
            },
            state: checkList.leadCheck,
            name: "Leads"
        },
        {
            check: {
                ...checkList,
                clickCheck: !checkList.clickCheck
            },
            state: checkList.clickCheck,
            name: "Clicks"
        },
        {
            check: {
                ...checkList,
                likeCheck: !checkList.likeCheck
            },
            state: checkList.likeCheck,
            name: "Likes"
        },
        {
            check: {
                ...checkList,
                shareCheck: !checkList.shareCheck
            },
            state: checkList.shareCheck,
            name: "Shares"
        },
        {
            check: {
                ...checkList,
                bookmarkCheck: !checkList.bookmarkCheck
            },
            state: checkList.bookmarkCheck,
            name: "Ad Type"
        },
        {
            check: {
                ...checkList,
                platformCheck: !checkList.platformCheck
            },
            state: checkList.platformCheck,
            name: "Status"
        },
        {
            check: {
                ...checkList,
                platformCheck: !checkList.platformCheck
            },
            state: checkList.platformCheck,
            name: "Platforms"
        }
    ]

    const Cardheader = () => {

        return (
            // <div className="profile-menu d-flex justify-content-between align-item-center row">
            <Row>
                <Col>

                    <div className='d-flex align-items-center mt-1 ' style={{ position: 'relative' }}>
                        <Button outline color='primary' className='font-weight-bold mr-1'>
                            <Download size={18} /> Export </Button>
                        <Input type='search' style={{ paddingLeft: '25px' }} size='58' className='' Placeholder='Search here...' />
                        <div style={{ position: 'absolute', top: '7px', marginLeft: '109px' }}>
                            <Search size={15} />
                        </div>

                    </div>

                </Col>
                <Col>
                    <div className='d-flex align-items-center justify-content-end mt-2'>
                        {/* <Input type="text" className="w-100 mx-1 " placeholder="Search" /> */}
                        {/* <DateRangePicker
                            calendarAriaLabel="Toggle calendar"
                            clearAriaLabel="Clear value"
                            dayAriaLabel="Day"
                            monthAriaLabel="Month"
                            nativeInputAriaLabel="Date"
                            onChange={onChange}
                            value={value}
                            yearAriaLabel="Year"
                        /> */}
                        <input type="date" name="" id="" placeholder='dd/mm/yyyy' className='form-control w-50' />&nbsp;&nbsp;
                        <UncontrolledButtonDropdown>
                            <DropdownToggle color="transparent" className="ml-1 p-0" >
                                <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                    <Grid size={22} color='#81C14B' />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className='scrollForAdmanagerFilter dropedownForFilter' right style={{ position: 'absolute', top: '39px', left: '-112px' }}>
                                {checkListArr.map((value, i) => {
                                    return (
                                        <DropdownItem className='w-100' onClick={() => setCheckList(value.check)} >
                                            <CustomInput type="checkbox" className='customcheckboxForFilter' id={`group-checkbox-${i}`} label={value.name} style={{ cursor: "pointer" }} checked={value.state} />
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                </Col>
            </Row >
            // </div>
        )
    }

    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }

    const CustomPagination = () => {

        const count = Number(Math.ceil(totalItems / rowsPerPage))

        return (
            <div className='d-flex justify-content-between mt-2'>
                <div className="d-flex align-items-center">
                    <Label for='row-per-page' className='text-nowrap'>Row per page</Label>
                    <CustomInput
                        className="form-control mx-50 px-1.5 ml-1 w-50"
                        style={{ backgroundColor: '#E5F0E0' }}
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={onChangeRowsPerValue}
                    >
                        <option value='5'>5 Row</option>
                        <option value='10'>10 Row</option>
                        <option value='25'>25 Row</option>
                        <option value='50'>50 Row</option>
                        <option value='100'>100 Row</option>
                    </CustomInput>
                </div>
                {/* <ReactPaginate
                    previousLabel={<ArrowLeft color='#4D4D4D' size={15} />}
                    nextLabel={<ArrowRight color='#4D4D4D' size={15} />}
                    pageCount={count}
                    activeClassName='active'
                    forcePage={currentPage}
                    onPageChange={page => {
                        setCurrentPage(page.selected)
                        setRender(!render)
                    }}
                    pageClassName='page-item'
                    nextClassName='page-item next'
                    nextLinkClassName='page-link'
                    previousClassName='page-item prev'
                    previousLinkClassName='page-link'
                    pageLinkClassName='page-link'
                    containerClassName="pagination react-paginate justify-content-end my-2 pr-1"
                /> */}
             <Stack>
                  <Pagination count={10} className='PaginationCount'>
                  </Pagination>
                </Stack>
            </div>
        )
    }


    useEffect(() => {
        // getPostfeedLikedetails()
    }, [render])

    return (
        <div>

            <CardBody>
                <div className='mb-2'>
                    <DeletedModal />
                    <Cardheader />
                </div>
                {
                    selectedRows.length !== 0 &&
                    <div style={{ backgroundColor: '#E3F2FD' }} className='d-flex justify-content-between align-items-center p-2 mb-1'>
                        <h4> {selectedRows.length} item selected</h4>
                        <Button className='btn-icon' color='secondary' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                            <Trash2 color='red' size={16} />
                        </Button>
                    </div>
                }
                <Row>
                    <Col>
                        <DataTable
                            className='react-dataTable'
                            noHeader
                            responsive
                            // selectableRows
                            paginationServer
                            selectableRowsHighlight
                            highlightOnHover
                            pointerOnHover
                            onSelectedRowsChange={handleChange}
                            sortIcon={<ChevronDown />}
                            paginationComponent={CustomPagination}
                            columns={columns}
                            data={tabledata}
                            customStyles={styleTable}
                        // progressPending={loading}
                        />
                    </Col>
                </Row>
                <CustomPagination />
            </CardBody>
        </div>
    );
}