import React from 'react'
import { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import user2 from '../../../NewComponents/IMG/userview.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ReportModal from './ModalGrid/ReportModal'
import { topGriddata } from '../DummyArray'
import { NavLink } from 'react-router-dom'
import LiveGif from '../../../NewComponents/IMG/LiveGif.gif'


const GridGoliveTab = () => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // ------------------------------------------------------Main return function------------------------------------------------------
    return (
        <>
            <Row className='p-2'>
                {
                    topGriddata.map((data, index) => {
                        return (
                            <>
                                <Col xl='3' className='p-1' key={index}>
                                    <div className='border roundedThisBorder on-hover-bg'>

                                        <div className='d-flex p-2'>
                                            <NavLink to='/MyProfile'><Badge color='primary' pill className=' px-2 d-flex align-items-center light-font'>
                                                Top #{data.topsNumber}
                                            </Badge></NavLink>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='light-primary' className='px-2 ml-2 d-flex align-items-center light-font' >
                                                    Trending #{data.trendingNumber}
                                                </Badge>
                                            </NavLink>
                                        </div>

                                        {/* <div className="CardbodyThis m-1">
                                            <div className='d-flex align-items-center justify-content-center simple-linear'>
                                                <div className="livestatus-con topleft"><span></span> Live </div>
                                                <div className='CountLeft '>
                                                    <Eye color='white' size={18} />
                                                    <p className='text-white align-self-center ml-1'>{data.viewerCount}</p>
                                                </div>
                                                <div className='topright'>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                                            <MoreHorizontal color='white' />
                                                        </DropdownToggle>
                                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                            <img src='https://picsum.photos/300/200' alt='GoLiveImg' className='w-100' style={{ borderRadius: '10px' }} />
                                            <div className="bottomleft rem-round">
                                                <img src='https://picsum.photos/318/180' width={55} height={55} className='rounded-circle ml-2 p-1' alt='img' style={{ borderRadius: '10px' }} />
                                                <div className='ml-1 p-1 mt-1'>
                                                    <p>Demon slayer</p>
                                                    <p className='mt-1'>#Entertainment</p>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="CardbodyThis mx-2 mb-2">
                                            {/* /GoLiveDetail */}
                                            <NavLink to='' >  <div style={{ position: 'relative' }} >
                                                <img src={data.img} alt='GoLiveImg' className='w-100' style={{ borderRadius: '10px' }} />

                                                <div style={{ position: 'absolute', top: '0', borderRadius: '10px 10px 0 0' }} className='w-100' >
                                                    <div className='d-flex align-items-center simple-linear px-2 py-2' style={{ borderRadius: '10px 10px 0 0' }} >
                                                        <span className="GoLive_section d-flex align-items-center" > <img width={20} src={LiveGif} alt="LiveGif1" /> </span>
                                                        <div className="livestatus-con "><span></span> Live </div>
                                                        <div className='d-flex'>
                                                            <Eye color='white' size={18} />
                                                            <p className='text-white align-self-center ml-1'>{data.viewerCount}</p>
                                                        </div>
                                                        <div className='ml-auto'>
                                                            <UncontrolledButtonDropdown>
                                                                <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                                                    <MoreHorizontal color='white' />
                                                                </DropdownToggle>
                                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share Golive</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;View creator's profile</NavLink></DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                                    <DropdownItem divider></DropdownItem>
                                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </NavLink>
                                            <div className="bottomleft rem-round">
                                                <img src='https://picsum.photos/318/180' width={55} height={55} className='rounded-circle ml-2 p-1 mb-2' alt='img' style={{ borderRadius: '10px' }} />
                                                <div className='ml-1 p-1 mt-1'>
                                                    <NavLink to='/MyProfile' style={{ color: 'white' }} ><p className='user-profile' >Demon slayer</p></NavLink>
                                                    <p className='mt-1'>#Entertainment</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                            </>
                        )
                    })
                }
            </Row>

            {/* ------------------------------------------------------Modal Start------------------------------------------------------ */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* ------------------------------------------------------Modal End------------------------------------------------------ */}

        </>
    )
}

export default GridGoliveTab