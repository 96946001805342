import React, { useState } from 'react'
import { AlertCircle, Briefcase, Heart, Mail, MapPin, Phone, PlusCircle, User, XCircle } from 'react-feather'
import Pen1 from '../../profile-components/Img/Pen1.png'
import Clg from '../../profile-components/Img/clg.png'
import Gender from '../../profile-components/Img/Gender.png'
import Birthday from '../../profile-components/Img/Birthday.png'
import Lang from '../../profile-components/Img/Lang.png'
import Rel from '../../profile-components/Img/Rel.png'
import Map1 from '../../profile-components/Img/Map1.png'
import People from '../../profile-components/Img/ppl1.png'
import Track from '../../profile-components/Img/Track.png'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import { Checkbox, FormLabel, Radio } from '@mui/material'
import Hobbies from '../../profile-components/ProfileComponentScreens/Hobbies'
import MyInterest from '../../profile-components/ProfileComponentScreens/MyInterest'
import MySports from '../../profile-components/ProfileComponentScreens/MySports'
import MyMovies from '../../profile-components/ProfileComponentScreens/MyMovies'
import MyMusic from '../../profile-components/ProfileComponentScreens/MyMusic'
import MyBook from '../../profile-components/ProfileComponentScreens/MyBook'
import MyGroup from '../../profile-components/ProfileComponentScreens/MyGroup'
import MyBizPage from '../../profile-components/ProfileComponentScreens/MyBizPage'
import GroupCardData from './GroupCardData'
import CradTypeData from './CradTypeData'
import SettingMyInterest from './SettingMyInterest'
import SettingsMyHobbies from './SettingsMyHobbies'

const PersonalInformation = () => {

  /**        State with functions        * */

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [professionOpen, setProfessionOpen] = useState(false)
  const toggleProfession = () => {
    setProfessionOpen((preState) => !preState)
  }

  const [educationOpen, setEducationOpen] = useState(false)
  const toggleEducation = () => {
    setEducationOpen((preState) => !preState)
  }

  const [addOpen, setAddOpen] = useState(false)
  const toggleAdd = () => {
    toggleEducation(true)
    setAddOpen((preState) => !preState)
  }

  const [basicInfoOpen, setBasicInfoOpen] = useState(false)
  const toggleBasic = () => {
    setBasicInfoOpen((preState) => !preState)
  }
  const [nameOpen, setNameOpen] = useState(false)
  const toggleName = () => {
    toggleBasic(true)
    setNameOpen((preState) => !preState)
  }
  const [langOpen, setLangOpen] = useState(false)
  const toggleLang = () => {
    toggleBasic(true)
    setLangOpen((preState) => !preState)
  }

  const [relOpen, setRelOpen] = useState(false)
  const toggleRel = () => {
    toggleBasic(true)
    setRelOpen((preState) => !preState)
  }
  const [locationOpen, setLocationOpen] = useState(false)
  const toggleLocation = () => {
    toggleBasic(true)
    setLocationOpen((preState) => !preState)
  }
  const [bioOpen, setBioOpen] = useState(false)
  const toggleBio = () => {
    setBioOpen((preState) => !preState)
  }
  const [contactOpen, setContactOpen] = useState(false)
  const toggleContact = () => {
    setContactOpen((preState) => !preState)

  }
  const [mobOpen, setMobOpen] = useState(false)
  const toggleMobNo = () => {
    toggleContact(true)
    setMobOpen((preState) => !preState)
  }
  const [emailOpen, setEmailOpen] = useState(false)
  const toggleEmail = () => {
    toggleContact(true)
    setEmailOpen((preState) => !preState)
  }

  const [showResults, setShowResults] = useState(false)
  const toggle = () => {
    setShowResults(!showResults)
  }
  const [verifyResults, setVerifyResults] = useState(false)
  const toggleVerify = () => {
    setVerifyResults(!verifyResults)
  }

  const [selectedValue, setSelectedValue] = useState('a');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);

  };

  /**        Professional Data Array         * */

  const ProfessionModalOption = [
    {
      name: "Sr. PHP developer",
      valueId: "a",
      valueId1: "A"
    },
    {
      name: "Dot Net developer",
      valueId: "b",
      valueId1: "B"
    },
    {
      name: "Doctor",
      valueId: "c",
      valueId1: "C"
    },
    {
      name: "Teacher",
      valueId: "d",
      valueId1: "D"
    },
    {
      name: "Engineer",
      valueId: "e",
      valueId1: "E"
    },
    {
      name: "Worker",
      valueId: "f",
      valueId1: "F"
    },
    {
      name: "Doctor",
      valueId: "g",
      valueId1: "G"
    },
    {
      name: "Teacher",
      valueId: "h",
      valueId1: "H"
    }
  ]

  /**        Basic Data Array         * */

  const BasicInfoOption = [
    {
      img: People,
      name: "Name",
      infoType: "edit",
      section: "Fadishwar Nath Chakradhar",
      editHandle: toggleName
    },
    {
      img: Gender,
      name: "Gender",
      section: "Male"

    },
    {
      img: Birthday,
      name: "Date of birth",
      section: "17 Aug, 1991"
    },
    {
      img: Lang,
      infoType: "edit",
      name: "Language",
      section: "English, Hindi, Gujrati",
      editHandle: toggleLang

    },
    {
      img: Rel,
      name: "Relationship",
      infoType: "edit",
      section: "Complicated",
      editHandle: toggleRel
    },
    {
      img: Map1,
      infoType: "edit",
      name: "Current Residence",
      section: "Gurgaon, Delhi NCR, INDIA",
      editHandle: toggleLocation
    }
  ]

  /**        Relation Data Array         * */

  const RelationModalOption = [
    {
      name: "Single",
      valueId: "a",
      valueId1: "A"
    },
    {
      name: "Married",
      valueId: "b",
      valueId1: "B"
    },
    {
      name: "Engaged",
      valueId: "c",
      valueId1: "C"
    },
    {
      name: "Divorced",
      valueId: "d",
      valueId1: "D"
    },
    {
      name: "Widowed",
      valueId: "e",
      valueId1: "E"
    },
    {
      name: "Separated",
      valueId: "f",
      valueId1: "F"
    },
    {
      name: "Complicated",
      valueId: "g",
      valueId1: "G"
    },
    {
      name: "In relationship",
      valueId: "h",
      valueId1: "H"
    }

  ]

  /**        Language Data Array         * */

  const LanguageModalOption = [
    {
      name: "English",
      id: 1
    },
    {
      name: "Deutsch",
      id: 2
    },
    {
      name: "Español",
      id: 3
    },
    {
      name: "中文",
      id: 4
    },
    {
      name: "বিষ্ণুপ্রিয়া মণিপুরী",
      id: 5
    },
    {
      name: "Nederlands",
      id: 6
    },
    {
      name: "中文",
      id: 7
    },
    {
      name: "বিষ্ণুপ্রিয়া মণিপুরী",
      id: 8
    },
  ]

  /**        Contact Data Array         * */

  const ContactDetailOption = [
    {
      icon: <Phone strokeWidth={3} color='#81C14B' size={20} />,
      name: "Mobile Number",
      detail: "9876543210",
      editHandle: toggleMobNo
    },
    {
      icon: <Mail strokeWidth={3} color='#81C14B' size={20} />,
      name: "Email Address",
      detail: "email@gmail.com",
      editHandle: toggleEmail
    }
  ]

  /**        Main Render Data         * */

  return (
    <div>
      {/* <div className='p-3 '>
        <h2>Personal Information</h2>
      </div> */}

      {/**       Profession Section         **/}

      <div className="card-title-cont-block border-bottom py-2">
        <div className="card-title About_section">
          <h3>My Profession</h3>
          <div className="settings">
            <div onClick={toggleProfession} className="setting-btn">
              <img src={Pen1} width={12} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="about-content About_section">
          <div className='d-flex  align-items-start justify-content-start px-4 pb-2'>
            <Briefcase strokeWidth={3} color='#81C14B' size={20} />
            <h4 className='px-4'>Sr. React Developer </h4>
          </div>
        </div>
      </div>

      {/**       Education Section         **/}

      <div className="card-title-cont-block border-bottom py-2">
        <div className="card-title About_section">
          <h3>My Education</h3>
          <div className="settings">
            <div onClick={toggleEducation} className="setting-btn">
              <img src={Pen1} width={12} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="about-content About_section ">
          <div className='d-flex align-items-start justify-content-start px-4 pb-2'>
            <img src={Clg} alt="img" className=' mt-2 img-fluid' width={20} height={20} />
            <div className='px-4 clgForstyle'>
              <h4>Studied at Symbiosis University</h4>
              <p className='pt-2'>Batch 2010 to 2014</p>
              <p>Gurgaon, Delhi NCR</p>
              <h5>Gong.io enables revenue teams to realize their fullest potential by unveiling customer reality. </h5>
            </div>
          </div>
        </div>
      </div>

      {/**      BasicInfo Section         **/}

      <div className="card-title-cont-block border-bottom py-2">
        <div className="card-title About_section">
          <h3>My Basic info</h3>
          <div className="settings">
            <div onClick={toggleBasic} className="setting-btn">
              <img src={Pen1} width={12} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="about-content About_section">
          { }
          <div className='d-flex align-items-center justify-content-start px-4 py-2'>
            <User strokeWidth={3} color='#81C14B' size={20} />
            <div className='px-4'>
              <h4>Name</h4>
              <h5>Fadishwar Nath Chakradhar</h5>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-start px-4 py-2'>
            <img src={Gender} width={20} className="img-fluid" />
            <div className='px-4'>
              <h4>Gender</h4>
              <h5>Male</h5>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-start px-4 py-2'>
            <img src={Birthday} width={20} className="img-fluid" />
            <div className='px-4'>
              <h4>Date of birth</h4>
              <h5>17 Aug, 1991</h5>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-start px-4 py-2'>
            <img src={Lang} width={20} className="img-fluid" />
            <div className='px-4'>
              <h4>Language</h4>
              <h5>English, Hindi, Gujrati</h5>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-start px-4 py-2'>
            <Heart strokeWidth={3} color='#81C14B' size={20} />
            <div className='px-4'>
              <h4>Relationship</h4>
              <h5>Complicated</h5>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-start px-4 py-2'>
            <MapPin strokeWidth={3} color='#81C14B' size={20} />
            <div className='px-4'>
              <h4>Current Residence</h4>
              <h5>Gurgaon, Delhi NCR, INDIA</h5>
            </div>
          </div>
        </div>
      </div>

      {/**      Bio Section         **/}

      <div className="card-title-cont-block border-bottom py-2">
        <div className="card-title About_section">
          <h3>My Bio</h3>
          <div className="settings">
            <div onClick={toggleBio} className="setting-btn">
              <img src={Pen1} width={12} className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="about-content About_section ">
          <div className='d-flex px-4'>
            <div>
              <AlertCircle strokeWidth={3} color='#81C14B' size={20} />
            </div>
            <div className='px-4 clgForstyle'>
              <h5>Gong.io enables revenue teams to realize their fullest potential by unveiling customer reality.  The patented Gong Revenue Intelligence Platform captures and understands every customer interaction then delivers insights at scale, empowering revenue teams to make decisions based on data instead of opinions.</h5>
              <h4 className='py-2'>Joined on 16 Feb 2022</h4>
            </div>
          </div>
        </div>
      </div>

      {/**    Contact Section         **/}

      <div className="card-title-cont-block border-bottom py-2">
        <div className="card-title-cont-block">
          <div className="card-title About_section">
            <h3>My Contact Detail</h3>
            <div className="settings">
              <div onClick={toggleContact} className="setting-btn">
                <img src={Pen1} width={12} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="about-content About_section">
            {
              ContactDetailOption.map((data) => (
                <div className='d-flex px-4 py-2'>
                  <div className="icon">
                    {data.icon}
                  </div>
                  <div className="detailsForSpace px-4">
                    <h3 className='pb-1'>{data.name}</h3>
                    <h5 style={{ cursor: "pointer" }}>{data.detail} | <span className='text-blue'>Primary</span></h5>
                    <h5 style={{ cursor: "pointer" }}>{data.detail} | <span className='text-blue'>Set a primary</span></h5>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      {/**    Component wise Section         **/}
      {/* <Hobbies /> */}
      <SettingsMyHobbies />
      {/* <MyInterest /> */}
      <SettingMyInterest />
      <CradTypeData
        title="My Sports"
      />
      <CradTypeData
        title="My Movies"
      />
      <CradTypeData
        title="My Music" />
      <CradTypeData
        title="My Books"
      />
      <GroupCardData
        title="My Groups"
      />
      <GroupCardData
        title="My BizPage"
      />

      {/**    Component wise Section Modals        **/}

      <div className='Modal_section Profession' >
        {
          professionOpen !== false &&
          <Modal scrollable isOpen={professionOpen} toggle={toggleProfession} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Add Profession</h4>
                <p onClick={toggleProfession}>Save</p>
              </div>
            </div>
            <div className="AboutModal_Main_section p-3 py-3">
              <div className="profile-search-blk w-100 ">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                <input type="text" className="form-control" name="search" placeholder="Find profession..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
              </div>
            </div>
            <div className='Modal_scroll_box' id='Scroll-1'>
              <div className="Modal_Option_Data ">
                {
                  ProfessionModalOption.map((data) => (
                    <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-3   '>
                      <h4>{data.name}</h4>
                      <Radio
                        checked={selectedValue === data.valueId}
                        onChange={handleChange}
                        value={data.valueId}
                        name="radio-buttons"
                        className='Radio_btn_check'
                        color='primary'
                        inputProps={{ 'aria-label': data.valueId1 }}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
            <ModalFooter className='d-flex justify-content-between align-items-center'>
              <Label className="Bio_text">
                Add Profession
              </Label>
              <div className='Input_Add_Profession'>
                <Input

                  id="exampleEmail"
                  name="email"
                  placeholder="Enter your Profession"
                  type="email"

                />
              </div>
              <Button color="primary">Add</Button>
            </ModalFooter>
          </Modal>
        }

      </div>

      <div className='Modal_section Education'>
        {
          educationOpen !== false &&
          <Modal scrollable isOpen={educationOpen} toggle={toggleEducation} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Education</h4>
                <p onClick={toggleAdd}>+ Add New</p>
              </div>
            </div>
            <ModalBody>
              <div className="profile-about">
                <div className="card-title-cont-block d-flex justify-content-between mt-3">
                  <div className="about-content Modal_About_section ">
                    <ul>
                      <li>
                        <div className="icon">
                          <img src={Clg} alt="img" className='img-fluid Icon_section' width={20} height={20} />
                        </div>
                        <div className="Education_details">
                          <h4>Studied at Symbiosis University</h4>
                          <h6 className='py-2'>Batch 2010 to 2014<br />Gurgaon, Delhi NCR<br /><small>Gong.io enables revenue teams to realize their fullest potential by unveiling customer reality. </small></h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="settings">
                    <div onClick={toggleAdd} className="setting-btn">
                      <img src={Pen1} width={12} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>

            </ModalBody>

          </Modal>
        }

      </div>

      <div className='Modal_section Add'>
        {
          addOpen ?
            <Modal isOpen={addOpen} toggle={toggleAdd} className='modal-dialog-centered'>
              <div className='w-100 border-bottom'>
                <div className='mx-3 py-4 Profession_Modal_section'>
                  <h4>Add Your Education</h4>
                  <p onClick={toggleAdd}>Save</p>
                </div>
              </div>
              <ModalBody>
                <div className='py-2 d-flex justify-content-around' >
                  <div className='d-flex align-items-center '>
                    <Radio checked={selectedValue === 'a'}
                      onChange={handleChange}
                      value={'a'}
                      name="radio-buttons"
                      className='Radio_btn_check'
                      color='primary'
                      inputProps={{ 'aria-label': 'A' }} />
                    <FormLabel className='ml-2 mt-2'>Add your school</FormLabel>
                  </div>
                  <div className='d-flex align-items-center  ' >
                    <Radio
                      checked={selectedValue === 'b'}
                      onChange={handleChange}
                      value={'b'}
                      name="radio-buttons"
                      className='Radio_btn_check'
                      inputProps={{ 'aria-label': 'B' }} />
                    <FormLabel className='ml-2 mt-2'>Add your college</FormLabel>
                  </div>
                </div>

                {selectedValue == "a" ?
                  <>
                    <div className='py-2'>
                      <FormGroup>
                        <Label className="Bio_text">
                          Institution Name
                        </Label>
                        <div className='form_control_section_input_box '>
                          <Input

                            id="exampleEmail"
                            name="email"
                            placeholder="Enter institution name"
                            type="email"
                            className="Eduction_inputForm "


                          />
                          <PlusCircle className='Add_box_section border-left pl-1' color='#81C14B' size={20} />
                        </div>
                      </FormGroup>
                      <Row className='d-flex justify-content-between'>
                        <Col >
                          <FormGroup>
                            <Label className="Bio_text" >
                              From
                            </Label>
                            <Input
                              name="datetime"
                              type="date"

                              className="w-100"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label className="Bio_text" >
                              To
                            </Label>
                            <Input
                              name="datetime"
                              type="date"

                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <div>
                        <Label className="Bio_text" >
                          Description
                        </Label>
                        <textarea placeholder='Description (optional) ' className="form-control" name="" id="" cols="15" rows="5"></textarea>
                        <p className='Descriptionvalidation'>Max 180 Characters </p>
                      </div>

                    </div>
                  </> :
                  <>
                    <div className='py-2'>
                      <FormGroup>
                        <Label className="Bio_text">
                          Institution Name
                        </Label>
                        <div className='form_control_section_input_box '>
                          <Input

                            id="exampleEmail"
                            name="email"
                            placeholder="Enter institution name"
                            type="email"
                            className="Eduction_inputForm "


                          />
                          <PlusCircle className='Add_box_section border-left pl-1' color='#81C14B' size={20} />
                        </div>
                      </FormGroup>
                      <Row className='d-flex justify-content-between'>
                        <Col >
                          <FormGroup>
                            <Label className="Bio_text" >
                              From
                            </Label>
                            <Input
                              name="datetime"
                              type="date"

                              className="w-100"
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label className="Bio_text" >
                              To
                            </Label>
                            <Input
                              name="datetime"
                              type="date"

                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className='d-flex justify-content-between'>
                        <Label className="Bio_text" >
                          Graduate
                        </Label>
                        <label className="switch ml-4">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div>
                        <Label className="Bio_text" >
                          Description
                        </Label>
                        <textarea placeholder='Description (optional) ' className="form-control" name="" id="" cols="15" rows="5"></textarea>
                        <p className='Descriptionvalidation'>Max 180 Characters </p>
                      </div>

                    </div>
                  </>
                }
              </ModalBody>
            </Modal> : null
        }
      </div>

      <div className='Modal_section_Basic_info'>
        {
          basicInfoOpen !== false &&
          <Modal isOpen={basicInfoOpen} toggle={toggleBasic} className='modal-dialog-centered'>
            <div className='AboutModal_Main_section'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Basic info</h4>
                <p onClick={toggleBasic}>Save</p>
              </div>
            </div>

            {
              BasicInfoOption.map((data) => (
                <div className='d-flex justify-content-between AboutModal_Main_section p-3 py-4  '>
                  <div className='d-flex'>
                    <div className='Basic_Info_modal'>
                      <img src={data.img} alt="img" className='Info_Modal_img img-fluid' width={20} height={20} />
                    </div>
                    <div className='ml-3 Basic_Info'>
                      <h4>{data.name}</h4>
                      <p>{data.section}</p>
                    </div>
                  </div>
                  {data.infoType == "edit" ? <>
                    <div className="settings">
                      <div className="setting-btn" style={{ cursor: "pointer" }}>
                        <img onClick={data.editHandle} src={Pen1} width={12} className="img-fluid" />
                      </div>
                    </div>
                  </> : <>

                  </>
                  }
                </div>
              ))
            }

          </Modal>
        }
      </div>

      <div className='Modal_section_Basic_info_Rel'>
        {
          relOpen !== false &&
          <Modal isOpen={relOpen} toggle={toggleRel} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Relationship Status</h4>
                <p onClick={toggleRel}>Save</p>
              </div>
            </div>

            <div className="AboutModal_Main_section p-3 py-3">
              <div className="profile-search-blk w-100 ">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                <input type="text" className="form-control" name="search" placeholder="Find Relationship..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
              </div>
            </div>
            <div className='Modal_scroll_box'>
              {
                RelationModalOption.map((data) => (
                  <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-3 '>
                    <h4>{data.name}</h4>
                    <Radio
                      checked={selectedValue === data.valueId}
                      onChange={handleChange}
                      value={data.valueId}
                      name="radio-buttons"
                      color='primary'
                      inputProps={{ 'aria-label': data.valueId1 }}
                    />
                  </div>
                ))
              }

            </div>



          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_Location'>
        {
          locationOpen !== false &&
          <Modal scrollable isOpen={locationOpen} toggle={toggleLocation} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Current Residence</h4>
                <p onClick={toggleLocation}>Save</p>
              </div>
            </div>
            <ModalBody>
              <div className='mt-2 form_control_section' >
                <Form>
                  <FormGroup>
                    <Label className="Bio_text">
                      Your current Residence
                    </Label>
                    <div className='form_control_section_input_box'>
                      <Input

                        id="exampleEmail"
                        name="email"
                        placeholder="Enter your current residence "
                        type="email"
                        className="inputForm"
                      />
                      <img src={Track} alt="location" className='img-fluid Map_box_section' width={12} height={12} />
                    </div>

                  </FormGroup>

                </Form>

              </div>

            </ModalBody>
          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_lang'>
        {
          langOpen !== false &&
          <Modal isOpen={langOpen} toggle={toggleLang} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Select your Language</h4>
                <p onClick={toggleLang}>Save</p>
              </div>
            </div>

            <div className="AboutModal_Main_section p-3 py-3">
              <div className="profile-search-blk w-100 ">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                <input type="text" className="form-control" name="search" placeholder="Find language..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
              </div>
            </div>
            <div className='Modal_scroll_box'>
              {
                LanguageModalOption.map((data) => (
                  <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-3 '>
                    <h4>{data.name}</h4>
                    <Checkbox {...label} defaultChecked className='Check_Input_section' />
                  </div>
                ))
              }

            </div>


          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_name'>
        {
          nameOpen !== false &&
          <Modal isOpen={nameOpen} toggle={toggleName} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Edit your name</h4>
                <p onClick={toggleName}>Save</p>
              </div>
            </div>
            <ModalBody>

              <div className='mt-2'>
                <Form>
                  <FormGroup>
                    <Label className="Bio_text">Name</Label>
                    <Input
                      id="exampleName"
                      name="name"
                      placeholder="Enter your name"
                      type="name"
                    />
                    <p className='text-muted d-flex justify-content-end py-1'>Max 64 Characters</p>
                  </FormGroup>
                </Form>
              </div>
            </ModalBody>
          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_Bio'>
        {
          bioOpen !== false &&
          <Modal isOpen={bioOpen} toggle={toggleBio} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Add Bio</h4>
                <p onClick={toggleBio}>Save</p>
              </div>
            </div>
            <ModalBody>

              <div className='py-2'>
                <Label for="exampleEmail" className="Bio_text">
                  Biography
                </Label>
                <textarea className="form-control" placeholder="write your biography..." ></textarea>
                <p className='text-muted d-flex justify-content-end mt-1'>Max 600 Characters</p>
              </div>

            </ModalBody>
          </Modal>
        }
      </div>

      <div className='Modal_section_Basic_info_contact'>
        {
          contactOpen !== false &&
          <Modal isOpen={contactOpen} toggle={toggleContact} className='modal-dialog-centered'>
            <div className=' Profession_Modal_section AboutModal_Main_section p-4 py-4   '>
              <h4>Contact Detail</h4>
              <p onClick={toggleContact}>Save</p>
            </div>
            <div className='mt-2'>
              {
                ContactDetailOption.map((data) => (
                  <div className='d-flex justify-content-between AboutModal_Main_section p-4 py-3 '>
                    <div className='d-flex align-items-start'>
                      <div className='Contact_Icon'>
                        {data.icon}
                      </div>
                      <div className='ml-3 Basic_Info'>
                        <h4>{data.name}</h4>
                        <h6>{data.detail}</h6>
                        <h6>{data.detail}</h6>
                      </div>

                    </div>
                    <div className="settings">
                      <div className="setting-btn">
                        <img onClick={data.editHandle} src={Pen1} width={12} className="img-fluid" />
                      </div>
                    </div>

                  </div>
                ))
              }
            </div>


          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_Mobile_no'>
        {
          mobOpen !== false &&
          <Modal isOpen={mobOpen} toggle={toggleMobNo} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Add/Edit Mobile Number</h4>
                <p onClick={toggleMobNo}>Save</p>
              </div>
            </div>
            <ModalBody>

              <div className='mt-1 form_control_section' >
                <Form>
                  <FormGroup>
                    <Label className="Bio_text">
                      Mobile Number
                    </Label>
                    <div className='form_control_section_input_box'>
                      <Input
                        disabled={true}
                        id="exampleEmail"
                        name="email"
                        placeholder="9876543210"
                        type="email"
                        className="bg-light"
                      />
                      <h6 className='input_box_section text-muted'>Primary</h6>
                    </div>

                  </FormGroup>
                  {showResults ? <FormGroup>
                    <Label className="Bio_text">
                      Enter Other Number
                    </Label>
                    <div className='form_control_section_input_box'>
                      <Input
                        placeholder="Enter your mobile number "
                        id="phone"
                        name="phone"
                        type="number"
                        className="inputForm bg-light "
                      />
                      <p onClick={toggleVerify} className='Verfiy_input_section'>Verify</p>
                      <XCircle onClick={toggle} className='Input_cross_section' color='#81C14B' size={17} />
                    </div>
                    {verifyResults ? <>
                      <div className='mt-4 Input_Verify_section'>
                        <div className='p-3'>
                          <Label className="Bio_text">
                            Enter OTP
                          </Label>
                          <div className='d-flex justify-content-between '>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className='text-center py-4 input_receive_section'>
                            <h4>00:20 sec</h4>
                            <h5 className='py-2'>Didn’t receive OTP yet? <span className='text-muted'>Resend </span></h5>
                          </div>
                          <div className='text-center py-3'>
                            <Button onClick={toggleMobNo} color='primary'>Submit</Button>
                          </div>

                        </div>
                      </div>
                    </> :

                      null}

                  </FormGroup> : null}
                  <p onClick={toggle}>+ Add other Number</p>

                </Form>
              </div>
            </ModalBody>
          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_Email'>
        {
          emailOpen !== false &&
          <Modal isOpen={emailOpen} toggle={toggleEmail} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Add/Edit Email Address </h4>
                <p onClick={toggleEmail}>Save</p>
              </div>
            </div>
            <ModalBody>
              <div className='mt-2 form_control_section' >
                <Form>
                  <FormGroup>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Label className="Bio_text">
                        Email ID
                      </Label>
                      <div>
                        <Radio checked={selectedValue === 'a'}
                          onChange={handleChange}
                          value={'a'}
                          name="radio-buttons"
                          className='Radio_btn_check'
                          inputProps={{ 'aria-label': 'A' }} />
                        <FormLabel className='ml-2 mt-2'>Set as primary </FormLabel>
                      </div>
                    </div>
                    <div className='form_control_section_input_box'>
                      <Input
                        disabled
                        id="exampleEmail"
                        name="email"
                        placeholder="email@gmail.com "
                        type="email"
                      />
                      <h6 className='input_box_section text-muted'>Primary</h6>
                    </div>
                  </FormGroup>
                  {showResults ? <FormGroup>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Label className="Bio_text">
                        Enter other email id
                      </Label>
                      <div>
                        <Radio checked={selectedValue === 'b'}
                          onChange={handleChange}
                          value={'b'}
                          name="radio-buttons"
                          className='Radio_btn_check'
                          inputProps={{ 'aria-label': 'B' }} />
                        <FormLabel className='ml-2 mt-2'>Set as primary </FormLabel>
                      </div>
                    </div>
                    <div className='form_control_section_input_box'>
                      <Input
                        id="exampleEmail"
                        name="email"
                        placeholder="email-@gmil.com"
                        type="email"
                        className="inputForm"
                      />
                      <p onClick={toggleVerify} className='Verfiy_input_section'>Verify</p>
                      <XCircle onClick={toggle} className='Input_cross_section' color='#81C14B' size={17} />
                    </div>
                    {verifyResults ? <>
                      <div className='mt-4 Input_Verify_section'>
                        <div className='p-3'>
                          <Label className="Bio_text">
                            Enter OTP
                          </Label>
                          <div className='d-flex justify-content-between '>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"

                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                            <div className='Otp_section'>
                              <Input
                                maxLength="1"
                                name="otp"
                                type="number"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className='text-center py-4 input_receive_section'>
                            <h4>00:20 sec</h4>
                            <h5 className='py-2'>Didn’t receive OTP yet? <span className='text-muted'>Resend </span></h5>
                          </div>
                          <div className='text-center py-3'>
                            <Button onClick={toggleEmail} color='primary'>Submit</Button>
                          </div>

                        </div>
                      </div>

                    </> : null
                    }

                  </FormGroup> : null}
                  <p onClick={toggle}>+ Add other Number</p>

                </Form>

              </div>


            </ModalBody>
          </Modal>
        }
      </div>

    </div>
  )
}

export default PersonalInformation