import React from 'react'
import { Fragment } from 'react'
import BannerCarousel from './BannerCarousel'
import { useState } from 'react'
import { Camera, Circle, Edit, MapPin } from 'react-feather'
import { Button } from 'reactstrap'
import AvatarGroup from '../../../NewComponents/AvatarGroup'
import VistorMamberModal from './TopBannerModal/VistorMamberModal'
import ProfileBiz from '../Img/ProfileBiz.png'
import ProfilePictureModal from './TopBannerModal/ProfilePictureModal'
import BizAvatar from '../BizAvatar'



import SetUpActionModal from '../AllTypesModal/SetUpActionModal'

const AboutTopBanner = () => {
    const [memberOpen, setMemberOpen] = useState(false)
    const toggleMember = () => {
        setMemberOpen((preView) => !preView)
    }
    const [profilePicture, setProfilePicture] = useState(false)
    const togglePicture = () => {
        setProfilePicture((preView) => !preView)
    }
    const [actionSetUp, setActionSetUp] = useState(false)
    const [isEditable, setIsEditable] = useState(false);

    const handleToggleEdit = () => {
      setIsEditable(!isEditable);
    };
    const toggleSetUp = () => {
        setActionSetUp((preview) => !preview)
    }
    const [print, setPrint] = useState(false)
    const [bizProfile, setBizProfile] = useState(ProfileBiz)
    const handleChange = (e) => {
        console.warn(e.target.files)
        if (e.target.files.length !== 0) {
            setBizProfile(URL.createObjectURL(e.target.files[0]))
        }
        setPrint(true)
    }


    //================= Main_return_function=======================//
    return (
        <Fragment>
            <div className="group-details-top biz-details-top">
                <div >
                    <BannerCarousel

                    />
                    {/* <div className="Bizgd-top-btns">
                        <div className="profile-cover profile-cover-new">
                            <a href="#" className="btn Bizbtn-solidUpload btn-group btn-grp-new">
                                <ul>
                                    <li className="choose-file">
                                        <a className=' grey-btn' href="">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> Edit
                                        </a>
                                        <input type="file"
                                            onChange={handleChangeCover}
                                        />
                                    </li>
                                </ul>
                            </a>
                        </div>
                    </div> */}
                </div>

                <div className="heading-title-blk head-title-biz">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4 col-12 order-lg-2">
                            <div className="biz-user-pro-blk ">
                                <div className='Biz-user_blk_section'>
                                    {/* <div className="image-container1">
                                        <img src={ProfileBiz}  alt='profile img' className='rounded-circle Biz_TopBanner_Profile' onClick={togglePicture} />
                                        <div className="BizAbout_profileEdit_Icon1">
                                            <div className="profile-cover profile-cover-new">
                                                <a href="#" className="btn Bizbtn-solidUpload btn-group btn-grp-new">
                                                    <ul>
                                                        <li className="choose-file">
                                                            <a className=' ' href="">
                                                                <Edit size={16} color=" #81C14B" fill='#ffffff' strokeWidth="3px" />
                                                            </a>
                                                            <input type="file"
                                                                onChange={handleChange}
                                                            />
                                                        </li>
                                                    </ul>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="image-container1">
                                        <img src={bizProfile} alt="profile img" className="rounded-circle Biz_TopBanner_Profile" onClick={togglePicture} />
                                        <div className="BizAbout_profileEdit_Icon1">
                                            <div className="profile-cover profile-cover-new">
                                                <label htmlFor="uploadInput" className="btn Bizbtn-solidUpload btn-group btn-grp-new">
                                                    <Camera size={16} color="#81C14B" fill="#ffffff" strokeWidth="3px" style={{cursor:"pointer"}} />
                                                    <input id="uploadInput" type="file" onChange={handleChange} style={{ display: 'none' }} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 contentEditable={true}>THE DETECTOR CLUB</h5>
                                <div className='bizmappara'>
                                    <MapPin size={20} color="#81C14B" style={{ marginTop: "5px" }} />
                                    <p className='text-primary bizmaptop'> 228 Hope Street Glasgow, G2 3PN 0141 332 </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 order-lg-1">
                            <div className="gd-content-area-top-blk entmagvifo">
                                <div className="groupmember-blk">
                                    <span> Entertainment</span> • <span>Magic</span>
                                </div>
                                <div className="people-likes matual-friend-sec padavtar" >
                                    <BizAvatar />
                                    <h6 onClick={toggleMember} style={{ cursor: "pointer" }} >+54 Visited today</h6>
                                </div>
                                <div className="people-likes matual-friend-sec padavtar" >
                                    <BizAvatar />
                                    <h6 onClick={toggleMember} style={{ cursor: "pointer" }} >+12k Followers</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-12 order-lg-3">
                            <ul className="Biz_followlist-blk">
                                <li>2.6/5<span>Rating</span></li>
                                <li>52<span>Posts</span></li>
                                <li>524<span>Followers</span></li>
                            </ul>
                            <div className="create-biz-btn light-green">
                                <Button
                                    outline
                                    color="primary"
                                    onClick={toggleSetUp}
                                >
                                    Setup action Button
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*================= Start_Modal_section =========================*/}
            <VistorMamberModal
                isOpen={memberOpen}
                updateOpen={toggleMember}
            />
            <ProfilePictureModal
                isOpen={profilePicture}
                updateOpen={togglePicture}
                updateProfile={bizProfile}
            />

            <SetUpActionModal
                isOpen={actionSetUp}
                updateOpen={toggleSetUp}
            />
            {/*================= end_Modal_section =========================*/}
        </Fragment>
    )
}

export default AboutTopBanner