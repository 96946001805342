
import React from 'react'
import { Fragment } from 'react'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import GroupInvitation from './GroupInvitation'
import GroupMenu from './GroupMenu'
import OwnedGrpSection from './AllGroupsSections/OwnedGrpSection';
import InviteGrpSection from './AllGroupsSections/InviteGrpSection';
import JoinedGrpSection from './AllGroupsSections/JoinedGrpSection';
import GroupInvitationSection from './AllGroupsSections/GroupInvitationSection';
import RightSidebar from '../RightSidebar'

const Group = () => {

    //================== main_return_function ===================//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="group-heading"><h2>Groups</h2></div>
                    <GroupMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="group-main-block">
                            <OwnedGrpSection />

                           <JoinedGrpSection />

                            <InviteGrpSection />

                            <GroupInvitationSection /> 

                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}

export default Group