import React, { useState } from 'react';
import { AlertTriangle, BellOff, ChevronLeft, Circle, Copy, Eye, EyeOff, Filter, MoreHorizontal, Send, Search } from 'react-feather';
import { NavLink } from "react-router-dom";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown, InputGroupText, InputGroup } from 'reactstrap';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import UserImg from '../../NewComponents/IMG/img.svg'
import GoliveHeader from './GoliveHeader';
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import { Pagination, Stack ,Radio} from '@mui/material';

export default function GoLive() {

    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt((num / 1000))}K`
        } else if (num >= 999999) {
            return `${parseInt((num / 1000000))}M`
        } else if (num <= 999) {
            return `${num}`
        }
    }

    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)

    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const golivedata = [
        {
            cardthum: 'assets/images/golive-1.jpg',
            userName: 'Demon slayer',
            watchcount: '8k',
            status: 'Active'
        },
        {
            cardthum: 'assets/images/golive-2.jpg',
            userName: 'Demon slayer',
            watchcount: '8k',
            status: 'Active'
        },
        {
            cardthum: 'assets/images/golive-3.jpg',
            userName: 'Demon slayers',
            watchcount: '8k',
            status: 'Active'
        },
        {
            cardthum: 'assets/images/golive-4.jpg',
            userName: 'Demon slayer',
            watchcount: '8k',
            status: 'Active'
        },
        {
            cardthum: 'assets/images/golive-3.jpg',
            userName: 'Demon slayer',
            watchcount: '8k',
            status: 'Active'
        },
        {
            cardthum: 'assets/images/golive-2.jpg',
            userName: 'Demon slayer',
            watchcount: '8k',
            status: 'Active'
        }
    ]

    const ForwardArrayData = [
        {
            id: "1",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "2",
            img: UserImg,
            name: "Darrell Steward",
            userName: "abriella-bond123"
        },
        {
            id: "3",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "4",
            img: UserImg,
            name: "Ralph Edwards",
            userName: "abriella-bond123"
        },
        {
            id: "5",
            img: UserImg,
            name: "Savannah Nguyen",
            userName: "abriella-bond123"
        },
        {
            id: "6",
            img: UserImg,
            name: "Eleanor Pena",
            userName: "abriella-bond123"
        },
        {
            id: "7",
            img: UserImg,
            name: "Darlene Robertson",
            userName: "abriella-bond123"
        },
        {
            id: "8",
            img: UserImg,
            name: "Bessie Cooper",
            userName: "abriella-bond123"
        }
    ]

    const Sharedto = [
        {
            name: "Your Wall Feed"
        },
        {
            name: "Your Story"
        }
    ]

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    const ReportModal = () => {
        const [radioActive, setRadioActive] = useState(null)
        return (
            <>
                <div>
                    {
                        reportOpen !== false &&
                        <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                                <h3>Report</h3>
                            </ModalHeader>
                            <ModalBody>
                                <div className='Modal_Body_Section'>
                                    <p>Tell us about what you feel and help us to  make Sociomee more secure.</p>
                                </div>
                                <br/>
                                {
                  ReportDataInfo.map((data,index) => (
                    <div
                    key={index}
                    className='d-flex justify-content-between Report_Section'
                  >
                    <h5
                      className='d-flex reort'
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                      }}
                    >
                      {data.name}
                    </h5>
                    <div>
                      <Radio
                        inputProps={{ 'aria-label': 'A' }}
                        name="radio-buttonsOne"
                        // id={`radio-buttonsOneId_${index}`}
                        className='Radio_btn_check'
                        checked={radioActive === index}
                        onClick={() =>{setRadioActive((prevIndex) => (prevIndex === index ? null : index));}}
                      />
                      {' '}
                    </div>
                  </div>
                  ))
                }
                                <div className='d-flex justify-content-center mt-3'>
                                    <Input
                                        id="ReportName"
                                        name="ReportName"
                                        placeholder="Write a comment..."
                                        type="Repor"
                                    />
                                </div>
                                <hr className='w-100 text-muted' />
                                <div className='text-center'>
                                    <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                    <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                                </div>
                            </ModalBody>

                        </Modal>

                    }

                </div>
            </>
        )
    }

    const ShareModal = () => {
        const [cSelected, setCSelected] = useState([1])
        const onCheckboxBtnClick = selected => {
            const index = cSelected.indexOf(selected)
            if (index < 0) {
                cSelected.push(selected)
            } else {
                cSelected.splice(index, 1)
            }
            setCSelected([...cSelected])
        }
        return (
            <div className=''>
                {
                    forwardOpen !== false &&
                    <Modal isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleForward} className="My-Member_Header_section">
                            <h2>Share to  </h2>

                        </ModalHeader>
                        <ModalBody className="p-0 m-0">

                            {/* <div className="search-block-podcast mt-2 p-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section p-2'>
                                <h4>Invite Connection</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} color='#81C14B' />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div> */}

                            <div className='d-flex align-items-center justify-content-between' style={{ padding: "10px" }}>
                                <InputGroup className='mr-2'>
                                    <InputGroupText className='bg-transparent text-secondary'>
                                        <Search size={16} />
                                    </InputGroupText>
                                    <Input type='search' placeholder='Search..' className="py-2" />
                                </InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)', borderRadius: '5px', left: "-95px" }}>
                                        <DropdownItem tag='a'> Family </DropdownItem>
                                        <DropdownItem tag='a'> Office </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3 p-2 ' style={{ maxHeight: "65vh", overflowY: "auto" }}>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            {/* <div className=''> */}

                                            <Button color='primary w-25' outline onClick={() => onCheckboxBtnClick(data.id)}
                                                active={cSelected.includes(data.id)}
                                            >

                                                {cSelected == data.id ? "shared" : "share"}
                                            </Button>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }
    const [isBlinking,] = useState(true);
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <GoliveHeader />
                    <ReportModal />
                    <ShareModal />
                    {/* <div className="container-fluid mt-3 px-0"> */}
                    <div className="container-fluid section-t-space px-0">
                        <div className="golive-section" >
                            <div className="golive-block">
                                <div className="gollive-head">
                                    <h3> Trending
                                        <img width={20} src={LiveGif} alt="Live0" /> <small> 514 Live</small></h3>
                                    <NavLink to='/GoLive'> <h4 className='text-primary d-flex align-items-center'> <ChevronLeft /> Back </h4> </NavLink>
                                </div>
                                <div className="row ">

                                    {
                                        golivedata.map((item, index) => {
                                            return (
                                                <div key={index + 1} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                                    <div className="golive-main-blks">
                                                        <div className="golive-main-img">
                                                            <NavLink to="/GoLiveDetail">
                                                                <img src={item.cardthum} alt='thumb' />
                                                            </NavLink>
                                                        </div>
                                                        <div className="golive-status-blk eyees">
                                                            <div className="golive-status-cont">
                                                                <h6 className=''> <span className={`p-1 fs-5  bg-danger text-white roundeds-4 py-1 px-2  podliveblink  goliveblink ${isBlinking ? 'blinkab' : ''}`}>Live</span></h6>
                                                                <div className='CountLeft eyesicon '>
                                                                    <div className='d-flex align-items-center text-white'>
                                                                        <Eye color='white' size={18} />
                                                                        <span className='ml-1'>{numFormatter('8000')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                                                        <MoreHorizontal color='white' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu style={{ left: '-184px', minWidth: 'fit-content' }}>
                                                                        <DropdownItem tag='a'><BellOff size={15} /> &nbsp;&nbsp; Turn off Notification</DropdownItem>
                                                                        <DropdownItem onClick={toggleForward} tag='a'><Send size={15} />&nbsp;&nbsp; Share</DropdownItem>
                                                                        <DropdownItem tag='a'><Copy size={15} />&nbsp;&nbsp; Copy Link</DropdownItem>
                                                                        <DropdownItem onClick={toggleReport} tag='a'><AlertTriangle size={15} />&nbsp;&nbsp; Report</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </div>
                                                        </div>
                                                        <div className="golive-user-blk">
                                                            <img src="assets/images/my-profile.jpg" alt='thumpro' />
                                                            <div className="golive-usercont">
                                                                <h5>{item.userName}</h5>
                                                                {/* <p>{item.status}</p> */}
                                                                <p className='mt-1'>#Entertainment</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className="golive-block">
                                <div className="gollive-head">
                                    <h3>Education <img width={20} src={LiveGif} alt="Live0" /> <small> 5 Live</small></h3>
                                </div>
                                <div className="row ">

                                    {
                                        golivedata.map((item, index) => {
                                            return (
                                                <div key={index + 1} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                                    <div className="golive-main-blks">
                                                        <div className="golive-main-img">
                                                            <NavLink to="/GoLiveDetail">
                                                                <img src={item.cardthum} alt='thumb' />
                                                            </NavLink>
                                                        </div>
                                                        <div className="golive-status-blk eyees">
                                                            <div className="golive-status-cont">
                                                                <h6 className=''> <span className={`p-1 fs-5  bg-danger text-white roundeds-4 py-1 px-2  podliveblink  goliveblink ${isBlinking ? 'blinkab' : ''}`}>Live</span></h6>
                                                                <div className='CountLeft eyesicon'>
                                                                    <div className='d-flex align-items-center text-white'>
                                                                        <Eye color='white' size={18} />
                                                                        <span className='ml-1'>{numFormatter('8000')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                                                        <MoreHorizontal color='white' />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu style={{ left: '-184px', minWidth: 'fit-content' }}>
                                                                        <DropdownItem tag='a'><BellOff size={15} /> Turn off Notification</DropdownItem>
                                                                        <DropdownItem onClick={toggleForward} tag='a'><Send size={15} /> Share</DropdownItem>
                                                                        <DropdownItem tag='a'><Copy size={15} /> Copy Link</DropdownItem>
                                                                        <DropdownItem onClick={toggleReport} tag='a'><AlertTriangle size={15} /> Report</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </div>
                                                        </div>
                                                        <div className="golive-user-blk">
                                                            <img src="assets/images/my-profile.jpg" alt='thumpro' />
                                                            <div className="golive-usercont">
                                                                <h5>{item.userName}</h5>
                                                                {/* <p>{item.status}</p> */}
                                                                <p className='mt-1'>#Entertainment</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                <div className='d-flex justify-content-center'>
                                    <Stack>
                                        <Pagination count={10} className='PaginationCount'>
                                        </Pagination>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </>
    );
} 