import * as types from "../../Constants/UserProfile/index";
const initialState = {
    allUserGroups: [],
    loading: true,
    success: true,
  };

export  const profileImageReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_PROFILE_PICTURE_SUCCESS':
        return {
          ...state,
          profileImage: action.payload.profileImage, // Assuming your response includes the updated profile image URL
        };
      case 'UPDATE_PROFILE_PICTURE_ERROR':
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  

export const getAllUserHobbiesReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_ALL_USER_HOBBIES:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_HOBBIES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_HOBBIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllAvailableHobbiesReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_ALL_AVAILABLE_HOBBIES:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_AVAILABLE_HOBBIES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_AVAILABLE_HOBBIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postAllUserHobbiesReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.POST_USER_HOBBIES:
            return {
                ...state,
                loading: true,
            };
        case types.POST_USER_HOBBIES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_USER_HOBBIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllAdminInterestReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_ADMIN_INTEREST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_ADMIN_INTEREST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_ADMIN_INTEREST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserInterestReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_INTEREST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_INTEREST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_INTEREST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postAllUserInterestReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_ALL_USER_INTEREST:
            return {
                ...state,
                loading: true,
            };
        case types.POST_ALL_USER_INTEREST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_ALL_USER_INTEREST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllAdminSportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_ADMIN_SPORTS:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_ADMIN_SPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_ADMIN_SPORTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserSportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_SPORTS:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_SPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_SPORTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postAllUserSportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_ALL_USER_SPORTS:
            return {
                ...state,
                loading: true,
            };
        case types.POST_ALL_USER_SPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_ALL_USER_SPORTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserMoviesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_MOVIES:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_MOVIES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_MOVIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserMusicReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_MUSIC:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_MUSIC_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_MUSIC_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_BOOK:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_BOOK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_BOOK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USER_BIZPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USER_BIZPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_USER_BIZPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllSchoolPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_SCHOOL:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_SCHOOL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const addAllProfessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_PROFESSION:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_PROFESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_PROFESSION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const addSchoolCollegeReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_SCHOOL_COLLEGE:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_SCHOOL_COLLEGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_SCHOOL_COLLEGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getQualificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_QUALIFICATION:
            return {
                ...state,
                loading: true,
            };
        case types.GET_QUALIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_QUALIFICATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const addQualificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_QUALIFICATION:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_QUALIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_QUALIFICATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const addUserEducationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_USER_EDUCATION:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_USER_EDUCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_USER_EDUCATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

// Media Starts here

export const getAllPostsByUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_POST_USER_MADE:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_ALL_POST_USER_MADE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_POST_USER_MADE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postHideUnHideReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_HIDE_UNHIDE:
            return {
                ...state,
                loading: true,
            };
        case types.POST_HIDE_UNHIDE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_HIDE_UNHIDE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postShowReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_HIDE_UNHIDE:
            return {
                ...state,
                loading: true,
            };
        case types.POST_HIDE_UNHIDE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_HIDE_UNHIDE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const deletePostReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_POST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.DELETE_POST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const getUserFollowerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_FOLLOWERS_SHARE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_USER_FOLLOWERS_SHARE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_USER_FOLLOWERS_SHARE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postReShareReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_RE_SHARE:
            return {
                ...state,
                loading: true,
            };
        case types.POST_RE_SHARE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_RE_SHARE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const getUserConnectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_CONNECTIONS:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_USER_CONNECTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_USER_CONNECTIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const inviteFriendsToEventReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INVITE_FRIENDS_TO_EVENT:
            return {
                ...state,
                loading: action.payload,
            };
        case types.INVITE_FRIENDS_TO_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.INVITE_FRIENDS_TO_EVENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllUserOwnPinnedBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_OWNED_PINNED_BUSINESSPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_OWNED_PINNED_BUSINESSPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_OWNED_PINNED_BUSINESSPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};




export const getAllUserOwnFollowedBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};



// export const getAllUserOwnPinnedGroupPageReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case types.GET_ALL_OWNED_PINNED_GROUPPAGE:
//             return {
//                 ...state,
//                 loading: true,
//             };
//         case types.GET_ALL_OWNED_PINNED_GROUPPAGE_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 success: true,
//                 result: action.payload,
//             };
//         case types.GET_ALL_OWNED_PINNED_GROUPPAGE_ERROR:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload,
//             };
//         default:
//             return state;
//     }
// };

//getpeoplelist
export const getPeopleListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PEOPLE_LIST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_PEOPLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_PEOPLE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_EVENTS_BY_USER:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_EVENTS_BY_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_EVENTS_BY_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getTextPostsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_TEXT_POSTS:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_TEXT_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_TEXT_POSTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getImagePostsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_IMAGE_POSTS:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_IMAGE_POSTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_IMAGE_POSTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getRecommendationPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_RECOMMENDATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_RECOMMENDATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const hideAllPostsFromTimelineReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.HIDE_ALL_POSTS_FROM_TIMELINE:
            return {
                ...state,
                loading: action.payload,
            };
        case types.HIDE_ALL_POSTS_FROM_TIMELINE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.HIDE_ALL_POSTS_FROM_TIMELINE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllCommentsByPostIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_COMMENTS:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_ALL_COMMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_COMMENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postAddAllCommentsByPostIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_ADD_COMMENT:
            return {
                ...state,
                loading: action.payload,
            };
        case types.POST_ADD_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_ADD_COMMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getMarketPlaceProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MARKET_PLACE_PRODUCTS:
            return {
                ...state,
                loading: action.payload,
            };
        case types.GET_MARKET_PLACE_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_MARKET_PLACE_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getCategoryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_CATEGORYLIST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_CATEGORYLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_CATEGORYLIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getSubCategoryListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_SUB_CATEGORY:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_SUB_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const getAllLikesByPostIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_LIKE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_LIKE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_LIKE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllSharesByPostIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_SHARE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_SHARE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ALL_SHARE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const shareShotzReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHARE_SHOTZ:
            return {
                ...state,
                loading: true,
            };
        case types.SHARE_SHOTZ_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.SHARE_SHOTZ_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const turnOnOffNotificationTextPostsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TURN_ON_OFF_NOTIFICATION_TEXT_POST:
            return {
                ...state,
                loading: true,
            };
        case types.TURN_ON_OFF_NOTIFICATION_TEXT_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.TURN_ON_OFF_NOTIFICATION_TEXT_POST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const createBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_BIZ_PAGE:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_BIZ_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.CREATE_BIZ_PAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const bizPageNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.BIZ_PAGE_NOTIFICATION:
            return {
                ...state,
                loading: true,
            };
        case types.BIZ_PAGE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.BIZ_PAGE_NOTIFICATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const bizPinnedReducer= (state = initialState, action) => {
    switch (action.type) {
        case types.BIZ_PAGE_PINNED:
            return {
                ...state,
                loading: true,
            };
        case types.BIZ_PAGE_PINNED_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.BIZ_PAGE_PINNED_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const addBizPageCatalogueReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_BIZ_PAGE_CATALOGUE:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_BIZ_PAGE_CATALOGUE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_BIZ_PAGE_CATALOGUE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getBizPageCatalogueReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BIZ_PAGE_CATALOGUE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BIZ_PAGE_CATALOGUE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BIZ_PAGE_CATALOGUE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getBizPageReviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BIZ_PAGE_REVIEW:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BIZ_PAGE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BIZ_PAGE_REVIEW_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getBizPageRatingSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BIZ_PAGE_RATING_SUMMARY:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BIZ_PAGE_RATING_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BIZ_PAGE_RATING_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const addBizPageRatingAndReviewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_BIZ_PAGE_RATING_AND_REVIEW:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_BIZ_PAGE_RATING_AND_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_BIZ_PAGE_RATING_AND_REVIEW_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const addBizPageQuestionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_BIZ_PAGE_QUESTION:
            return {
                ...state,
                loading: true,
            };
        case types.ADD_BIZ_PAGE_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.ADD_BIZ_PAGE_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAllQuestionAndAnswerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_All_QUESTION_AND_ANSWER:
            return {
                ...state,
                loading: true,
            };
        case types.GET_All_QUESTION_AND_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_All_QUESTION_AND_ANSWER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getAboutUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ABOUT_US:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ABOUT_US_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_ABOUT_US_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postPinOrUnpinBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_PIN_OR_UNPIB_BIZPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.POST_PIN_OR_UNPIB_BIZPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_PIN_OR_UNPIB_BIZPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const deleteBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DETELE_BIZPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.DETELE_BIZPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.DETELE_BIZPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getInstantBizPageMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INSTANT_MESSAGE_BIZPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_INSTANT_MESSAGE_BIZPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_INSTANT_MESSAGE_BIZPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const instantBizPageMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INSTANT_MESSAGE_BIZPAGE:
            return {
                ...state,
                loading: true,
            };
        case types.INSTANT_MESSAGE_BIZPAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.INSTANT_MESSAGE_BIZPAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const getBizPageDetailsByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BIZ_PAGE_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BIZ_PAGE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BIZ_PAGE_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const postNotificationSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.POST_NOTIFICATION_SETTINGS:
            return {
                ...state,
                loading: true,
            };
        case types.POST_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.POST_NOTIFICATION_SETTINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const getBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BIZ_PAGE_ROLE:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BIZ_PAGE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BIZ_PAGE_ROLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const removePageRoleFromBizPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REMOVE_ROLE_FROM_BIZ_PAGE:
            return {
                ...state,
                loading: true,
            };
        case types.REMOVE_ROLE_FROM_BIZ_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.REMOVE_ROLE_FROM_BIZ_PAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getBizPageFollowerListsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BIZ_PAGE_FOLLOWER_LIST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BIZ_PAGE_FOLLOWER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BIZ_PAGE_FOLLOWER_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const setAssignRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ASSIGN_ROLE:
            return {
                ...state,
                loading: true,
            };
        case types.SET_ASSIGN_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.SET_ASSIGN_ROLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getFollowedByBizPageUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FOLLOWED_BY_BIZ_PAGE_USERS:
            return {
                ...state,
                loading: true,
            };
        case types.GET_FOLLOWED_BY_BIZ_PAGE_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_FOLLOWED_BY_BIZ_PAGE_USERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const blockBizPageUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.BLOCK_BIZ_PAGE_USERS:
            return {
                ...state,
                loading: true,
            };
        case types.BLOCK_BIZ_PAGE_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.BLOCK_BIZ_PAGE_USERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getBlockedBizPageUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BP_BLOCKED_fOLLOWER:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BP_BLOCKED_fOLLOWER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BP_BLOCKED_fOLLOWER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getBizPageHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_BP_HISTORY:
            return {
                ...state,
                loading: true,
            };
        case types.GET_BP_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                result: action.payload,
            };
        case types.GET_BP_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};