import React, { useEffect, useState, useRef, useCallback   } from 'react'
import { Fragment } from 'react'
import { ArrowLeft } from 'react-feather'
import { Col, Row } from 'reactstrap'
import { AllPostTypeData } from '../DummyArray'
import { useDispatch , useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MarketPlaceModal from '../../../components/marketplace-components/ModalSection/MarketPlaceModal'
import { getAllProductCategoryAction } from "../../../Services/Actions/MarketPlace/getAllProductsAction";


const AllPostTypes = ({ toggle, activeTab, Navigate }) => {
    const navigate = useNavigate()
    const dispatch= useDispatch()

   
   
    // sell Modal of market Place 
    const [mpModal, setMpModal] = useState(false);
    const Mptoggle = () => {
        setMpModal(!mpModal);
    }
  const handleMarketPlace = () =>{
    setMpModal(!mpModal);
  }



///here we are getiing all the category of marketplace
  useEffect(() => {
    dispatch(getAllProductCategoryAction())
    // LocationProvider()
}, [dispatch])
const { category, error } = useSelector(state => state.getAllProductCategory);







    //============= Main_return_function ==============//
    return (
        

        <Fragment>
            <div className='border-bottom w-100'>
                <div className={`mx-3 py-3  FellingActivity_main_blk `}>
                    <div onClick={() => toggle(`/modal/${activeTab - 7}`)} style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle'>
                        <ArrowLeft />
                    </div>
                    <div>
                        <h3>Create your post</h3>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className='m-1 pb-2 '>
                <Row className='rowRevert'>
                    {AllPostTypeData.map((item, index) => {
                        return (
                            <Col key={index} xl='6'>
                                <div onClick={item.tab === true ? () => { toggle(item.id) } : item.id === '7.2' ? 
                                    () => Navigate('/CreateGoLive') : item.id === '7.4' 
                                    ? () => Navigate('/Podcast/NewPodcastSeries') : item.id === '7.5' 
                                    ? () => Navigate('/EditorArticle') : item.id === '7.9' 
                                    ? () => {handleMarketPlace()} : null} 
                                    className='CreatePostselection styleForTextFooter user-select-none m-1 py-2'>
                                    <img className='px-2' src={item.img} /> <h4>{item.postType}</h4>
                                </div>
                            </Col>)
                    })
                    }
                </Row>
                    {/* //here we are openiign marketplace product create modal  */}
                    {mpModal && (
                    <>
                    <MarketPlaceModal open={mpModal} toggle={Mptoggle} data={""} category={category} />
                    </>
                )}
            </div>
        </Fragment>

    )
}

export default AllPostTypes