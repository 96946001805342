import { Checkbox } from '@mui/material'
import React, { Fragment } from 'react'
import { Modal } from 'reactstrap'
import { FilterData } from '../Components/EventsDataArray'

const FilterModal = (props) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <Fragment>
            <div className='Filter_section_modal'>
                {
                    props.isOpen !== false &&
                    <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                        <div className='Filter_Modal_section AboutModal_Main_section p-3 py-3 '>
                            <h4>Filter by Categories </h4>
                            <p style={{ cursor: "pointer" }} onClick={props.updateOpen}>Done</p>
                        </div>
                        <div className='Modal_scroll_box'>
                            <div className="AboutModal_Main_section p-3 py-4">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            {
                                FilterData.map((data) => (
                                    <div className='Events_Filter_Modal_Option Events_Main_section p-3 py-1 '>
                                        <h4 className=''>{data.name}</h4>
                                        <Checkbox {...label}  />
                                    </div>
                                ))
                            }
                        </div>
                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default FilterModal