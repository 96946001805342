import * as types from '../../Constants/UserProfile/index';
import axios from 'axios';
import { loadProfileByUserId } from './getUserProfileByUserIdAction';

const getAllInterests = (allInterests) => ({
    type: types.GET_ALL_INTERESTS,
    payload: allInterests,
});


// get all interest 
export const loadAllInterests = (search) => {
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/admin/getAllInterests`,{searchKey:search},{ headers: { Authorization: `Bearer ${user?.token}` } })
                .then((res) => {
                    dispatch(getAllInterests(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};

// add interests
export const addInterests = (selectedHobbies)  => async (dispatch, getState) => {
    // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    // return function (dispatch) {
    //     user && axios.post(`${process.env.REACT_APP_IPURL}/user/addInterests/`, 
    // { interestIds: interest }, {headers: { Authorization: `Bearer ${user?.token}` }})
    //         .then((res) => {
    //             //console.log(res)
    //             dispatch()
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // };

    try {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
        // dispatch({ type: types.POST_ALL_USER_SPORTS });
        let { data } = await axios.post(
            `${process.env.REACT_APP_IPURL}/user/addInterests`,
            {interestIds: selectedHobbies},
            {
                headers: { Authorization: `Bearer ${user?.token}` }
            })
        dispatch({ type: types.GET_ALL_INTERESTS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: types.GET_ALL_INTERESTS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }


};

