import { Checkbox } from '@mui/material'
import React, { useState } from 'react'
import { Fragment } from 'react'
import { X } from 'react-feather'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { RolesAssignPageData } from '../SettingComponents/SettingArray'
import { FormLabel, Radio } from '@mui/material';

const PageAssignModal = (props) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

        const [selectedDataId, setSelectedDataId] = useState(null);
        const [selectProfilePhoto, setProfilePhoto] = useState(null);
        const [selectFullName, setFullName] = useState(null);
      
        function handleDataSelect(event, profile, fullName) {
            setProfilePhoto(profile)
            setFullName(fullName)
          setSelectedDataId(event.target.value);
        }

    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                    {/* <ModalHeader className="Biz_Rating_SECTION" toggle={props.updateOpen}>
                        <h3>People (521)</h3>
                    </ModalHeader> */}
                    <div className='w-100 border-bottom Biz_Assign_section_heder'>
                        <div className='mx-3 py-3 d-flex justify-content-between'>
                            <h3>People ({props.resultGetBizPageConnections.length})</h3>
                            <p onClick={props.updateOpen}><X /></p>
                        </div>
                    </div>
                    <div className="AboutModal_Main_section p-3 py-3">
                        <div className="search-block-podcast">
                            <div className="input-search-blk">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                            </div>
                        </div>
                    </div>
                    <ModalBody>

                                <div>
                                    {props.resultGetBizPageConnections.map((data) => (
                                        <div className="bizPage_pageRole-Modalblk" key={data.id}>
                                        <div className='d-flex align-items-center'>
                                            <img src={data.profilePhoto} className="img-fluid rounded-circle assignimg" alt='biz-img' width={60} height={60} />
                                            <div className='ml-2 BizProfile_section'>
                                            <h4>{data.fullName}</h4>
                                            {/* <p>{data.status}</p> */}
                                            <p>Joined on 23 Mar 2021</p> 
                                            </div>
                                        </div>
                                        <div>
                                            <label>
                                            <Radio
                                                type="checkbox"
                                                name="selectedData"
                                                value={data.id}
                                                checked={selectedDataId === data.id}
                                                onChange={ (event) =>handleDataSelect(event, data.profilePhoto, data.fullName)}
                                            />
                                            </label>
                                        </div>
                                        </div>
                                    ))}

                                </div>

                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center'>
                        <Button className="w-25" onClick={props.updateOpen} outline>Cancel</Button>
                        <Button color='primary' onClick={() => props.selectAssignState(selectedDataId, selectProfilePhoto, selectFullName)} className="w-25 ml-3">Assign</Button>
                    </ModalFooter>
                </Modal>
            }
        </Fragment>
    )
}

export default PageAssignModal