import React, { Fragment, useEffect, useState, useRef, useCallback   } from 'react'
import { Grid, List, MapPin, Search } from 'react-feather'
import { Button, Card, Col, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Track from "../../profile-components/Img/Track.png"
import { MarketPlaceCate } from '../MarketPlaceArray/MarketPlaceCategory'
import MarketPlaceModal from '../ModalSection/MarketPlaceModal'
import trace from "../Icons/trace.svg"
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import debounce from 'lodash.debounce';
import { useDispatch , useSelector } from 'react-redux'
import { getAllProductCategoryAction, getAllCategoryAndSubcategoryHaveData } from "../../../Services/Actions/MarketPlace/getAllProductsAction";




const MarketPlaceHeader = ({ setFilterPath, listView, setListView, setSideBarFilter, setSearchKey , setCategoryId, setPageIndex,setLatLongValue}) => {
    const navigate = useNavigate()
    const dispatch= useDispatch()



    // state to manage the Active and non active of the category tabs 
    const [isActive, setIsActive] = useState(null)
    const [catAndSubCatList, setCatAndSubCatList] = useState([]);
    const [loading, setLoading] = useState(true);

    // list view grid view button manager 
    const [location, setlocation] = useState(true)
    const LocationProvider = () => {
        let currentLocation = window.location.pathname
        if (currentLocation === "/marketplace") {
            setlocation(true)
        } else {
            setlocation(false)
        }
    }


    //  =================== Function to set the Filtered Choosed and navigate to where ==================================
    const ToggleFilter = (cate, index) => {
        if (window.location.pathname !== "/marketplace") {
            navigate("/marketplace")
            setFilterPath(`Home/${cate?.categoryName}`)
            setCategoryId(cate.categoryId)
            setIsActive(index)
            setPageIndex(0)
        } else {
            setFilterPath(`Home/${cate?.categoryName}`)
            setCategoryId(cate.categoryId)
            setIsActive(index)
            setPageIndex(0)
        }

    }

    // sell Modal of market Place 
    const [modal, setModal] = useState(false);

    
    const toggle = () => {
        setModal(!modal);
    }

    // MpHeader SLider setting

    let bizSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 5
                }
            },

            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1154,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 933,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 810,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 762,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 624,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1
                }
            },

        ]
    };

    //  ====getting category new or old for post creation-===================

    useEffect(() => {
        dispatch(getAllProductCategoryAction())
        LocationProvider()
    }, [dispatch])
    const { category, error } = useSelector(state => state.getAllProductCategory);

   
    // =============>>getting category and subcategory data for display non empty category =========
    useEffect(() => {
      setLoading(true);
      dispatch(getAllCategoryAndSubcategoryHaveData({}))
          .then(() => setLoading(false))
          .catch(() => setLoading(false));
  }, [dispatch]);
    
        const catAndSubcatListData = useSelector(state => state.getAllCategoryAndSubcategoryHaveData);
        useEffect(() => {
            if (catAndSubcatListData?.categoryAndSubcategoryData) {
                setCatAndSubCatList(catAndSubcatListData.categoryAndSubcategoryData);
            }
        }, [catAndSubcatListData]);

    

   //code for the search the keyword (debouncing concept with 0.3 sec)
   const [searchTerm, setSearchTerm] = useState("");

   // Debounce the setSearchKey function
   const debouncedSetSearchKey = useCallback(
       debounce((value) => {
           setSearchKey(value);
       }, 300),
       [] 
   );

   const handleInputChange = (e) => {
       setSearchTerm(e.target.value);
       debouncedSetSearchKey(e.target.value);
   };






    // =========>> section for the location in header <<=================
    const [suggestions, setSuggestions] = useState([]);
    const [suggestion, setSuggestion] = useState(false);
    const [coords, setCoords] = useState({});
    const [locationValue, setLocationValue] = useState("")
    const [placeholder, setPlaceholder] = useState("Enter your address"); // Default placeholder


    useEffect(() => {
      const loadGoogleMapsScript = () => {
          return new Promise((resolve, reject) => {
              if (window.google && window.google.maps) {
                  resolve(window.google.maps);
                  return;
              }

              const script = document.createElement('script');
              script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_LOCATION_KEY}&libraries=places`;
              script.async = true;
              script.onload = () => {
                  if (window.google && window.google.maps) {
                      resolve(window.google.maps);
                  } else {
                      reject('Google Maps API did not load.');
                  }
              };
              script.onerror = () => reject('Error loading Google Maps API.');
              document.body.appendChild(script);
          });
      };
     //gettting user location
      const fetchUserLocation = async () => {
          if (!navigator.geolocation) {
              console.error('Geolocation is not supported by this browser.');
              return;
          }
  
          const getPosition = () => {
              return new Promise((resolve, reject) => {
                  navigator.geolocation.getCurrentPosition(resolve, reject);
              });
          };
  
          try {
              // Wait for Google Maps API to be loaded
              await loadGoogleMapsScript();
  
              // Get user location
              const position = await getPosition();
              const { latitude, longitude } = position.coords;
              setCoords({ latitude, longitude });
              setLatLongValue({ latitude, longitude })
              // Initialize Google Maps Geocoder
              const geocoder = new window.google.maps.Geocoder();
              const latlng = { lat: latitude, lng: longitude };
  
              // Convert coordinates to address
              const address = await new Promise((resolve, reject) => {
                  geocoder.geocode({ location: latlng }, (results, status) => {
                      if (status === 'OK' && results[0]) {
                          resolve(results[0].formatted_address);
                      } else {
                          reject('No results found or geocoder failed');
                      }
                  });
              });
  
              setLocationValue(address); // Set the address in the input
              setPlaceholder(address); // Optionally update the placeholder
  
          } catch (error) {
              console.error('Error fetching location or address:', error);
          }
      };
  
      fetchUserLocation();
  }, []);
  
  

   //typing on the input (location)
    const onChangeLocationInputData = (e) => {
      const query = e.target.value;
      if (query.length > 1 && window.google) {
          const autocompleteService = new window.google.maps.places.AutocompleteService();
          autocompleteService.getPlacePredictions({ input: query }, (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                  // console.log("Predictions:", predictions);
                  setSuggestions(predictions);
                  setSuggestion(true);
              } else {
                  setSuggestions([]);
                  setSuggestion(false);
              }
          });
      } else {
          setSuggestions([]);
          setSuggestion(false);
      }

      setLocationValue(query);
      if (query === "") {
          setPlaceholder("Enter your address");
      } else {
          setPlaceholder(query);
      }
  };


  //click on image to find  my default current address
    const handleTrackClick = () => {
      if (coords.latitude && coords.longitude) {
          const geocoder = new window.google.maps.Geocoder();
          const latlng = { lat: coords.latitude, lng: coords.longitude };

          geocoder.geocode({ location: latlng }, (results, status) => {
              if (status === 'OK') {
                  if (results[0]) {
                      const currentLocation = results[0].formatted_address;
                      setLocationValue(currentLocation);
                      setPlaceholder(currentLocation);
                  } else {
                      console.error('No results found');
                  }
              } else {
                  console.error('Geocoder failed due to: ' + status);
              }
          });
      } else {
          console.error('Coordinates are not available');
      }
    };




  //when user click on suggestions
  const onSelectSuggestion = (e, suggestion) => {
    e.stopPropagation();
    setLocationValue(suggestion.description);
    setPlaceholder(suggestion.description);
    setSuggestion(false);
    // Fetch place ID from suggestion
    const placeId = suggestion.place_id;
    if (placeId) {
        fetchCoordinates(placeId);
    }
  };



  //remove all the value  from input type
  const handleBackspace = (e) => {
    if (e.keyCode === 8 && locationValue === "") {
        setLocationValue("");
        setPlaceholder("Enter your address");
        setCoords({})
        setLatLongValue({})
        setSuggestion(false);
    }
  };


      // Fetch latitude and longitude from place details
      const fetchCoordinates = (placeId) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const { lat, lng } = results[0].geometry.location;
                setCoords({ latitude: lat(), longitude: lng() });
                setLatLongValue({ latitude: lat(), longitude: lng() })
                // console.log(`Latitude: ${lat()}, Longitude: ${lng()}`);
            } else {
                console.error('Geocode was not successful for the following reason:', status);
            }
        });
    };

    




  // >>>>>>>>>>>>>>>>========================================================================================<<<<<<<<<<<<<<<<<< 
   return (
    <Fragment>
      <Card className="p-3 MarketPlaceFullCardContainer markettop" style={{ width: '100%' }}>
        <Row className="MpRevertRow" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Col xl={12} className="d-flex justify-content-between align-items-center flex-wrap">
            <h3 style={{ fontWeight: "600" }}>Market<span className='text-primary'>Place</span></h3>
            <div className='d-flex gap-1 mt-2'>
              <Button className="MpMobileViewButton_sell" color="primary" outline onClick={toggle}>+ Sell</Button>
              <Button className="MpMobileViewButton_Manage ManageOwnProductBtn" onClick={() => { navigate("/MyMarketPlace") }}>Manage Your Product</Button>

              {modal && (
                <>
                  <MarketPlaceModal open={modal} toggle={toggle} data={MarketPlaceCate} category={category} />
                  {/* <Button className="MpMobileViewButton_Manage ManageOwnProductBtn" onClick={() => { navigate("/MyMarketPlace") }}>Manage Your Product</Button> */}
                </>
              )}
            </div>
          </Col>
          <Col xl={12} className="Mp_HeaderSearchAndLocation justify-content-between align-items-center flex-wrap w-100 row MpRevertRow p-0" style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            <div className="col-md-6 mt-3">
              <div className='MrktPlaceSearch ' style={{ display: 'flex', alignItems: 'center' }}>
                <Search size={16} color="#4D4D4D" />
                <input type="text" placeholder='Find Cars, Mobiles, Vehicle and More...' 
                onChange={handleInputChange} />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-start justify-content-end flex-wrap mt-3 p-0">
              <UncontrolledButtonDropdown className="Mp_MarketPlaceHeader_Location">
                <DropdownToggle style={{ border: "none" }} className="srch_btn_toggle">
                  <div className="MrktPlaceHeaderLocation" style={{ display: 'flex', alignItems: 'center' }}>
                      <MapPin size={17} color="#4D4D4D" />
                      <input type="text" placeholder={placeholder} id='popBottom' className='Mp_LocationSearchInput' style={{ flexGrow: 1, }} 
                        onChange={onChangeLocationInputData}
                        value={locationValue}
                        onKeyDown={handleBackspace}

                      />
                      <img src={Track} alt="location" className='img-fluid' width={18} height={18} onClick={handleTrackClick} />
                  </div>
                </DropdownToggle>
                <DropdownMenu className={`marketPlace_srch_dropMenu ${suggestion === false ? "d-none" : ""}`} >
                <div className='w-100 border-bottom'>
                  <div className='mx-3 py-2 Mp_Loaction_Modal_Heading'>
                    <h4>Current Residence</h4>
                    <p>Save</p> 
                  </div>
                </div>
                <div className="Mp_LocationDropdown_Locations d-flex flex-column gap-3 p-3" >
                {suggestions.length > 0 && suggestions.map((suggestion, index) => (
                    <div key={index} className='d-flex align-items-center justify-content-start gap-2' 
                      onClick={(e) => onSelectSuggestion(e, suggestion)}
                      style={{ color: "#808080", cursor: "pointer" }}>
                      <img src={trace} alt="" />
                      <h5 style={{ color: "#808080" }}>
                        <strong style={{ color: "#4D4D4D" }}>{suggestion?.description}</strong>
                      </h5>
                    </div>
                  ))}
                </div>
              </DropdownMenu>
              </UncontrolledButtonDropdown>
              {location && (
                <>
                  <Button color="primary listViewAndGridViewHide" active={listView === 1} onClick={() => { setListView(1) }} outline className="Mp_ListGrid_Btn ml-2"><Grid size={17} /></Button>
                  <Button color="primary listViewAndGridViewHide" active={listView === 2} onClick={() => { setListView(2) }} outline className="Mp_ListGrid_Btn ml-2"><List size={17} /></Button>
                </>
              )}
            </div>
          </Col>
          <Col sm={12} className="MpRevertRow Mp_Deskto_HeaderTabs w-100 mt-3 mb-3 align-items-center justify-content-between px-3">
            {catAndSubCatList?.slice(0, 6).map((cate, index) => (        //here we are setting only six value for header
              <div
                className={`MrktCated mt-3 ${isActive === index ? "text-primary" : ""}`}
                key={index}
                onClick={() => { ToggleFilter(cate, index) }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  margin: '0 10px',
                }}>
                <Grid size={30} />
                <div className="MrktCateInfo d-flex flex-column align-item-start">
                  <h4>{cate.categoryName}</h4>
                  <h6>{cate.postCount} items</h6>
                </div>
              </div>
            ))}
          </Col>
        </Row>
        <Row className="MpRowRevert Mp_Mobile_HeaderTabs mt-3 mb-3 px-3 align-items-center">
          <Slider {...bizSettings} className="default-space">
            {catAndSubCatList?.length > 0 && catAndSubCatList.map((cate, index) => (
              <div className='w-100' key={index} style={{ padding: '0 10px' }}>
                <Col
                  sm={3}
                  className="MrktCated w-100 mt-3"
                  onClick={() => { ToggleFilter(cate, index) }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}>
                  <Grid size={26} color={isActive === index ? "#81c14b" : `#4D4D4D`} />
                  <div className="MrktCateInfo d-flex flex-column align-item-start">
                    <h4 className={` ${isActive === index ? "Mp_Color81_Font5" : "Mp_Color4D_Font5"}`}>{cate.categoryName}</h4>
                    <h6 className={` ${isActive === index ? "Mp_Color81_Font5" : "Mp_Color4D_Font5"}`}>{cate.postCount} items</h6>
                  </div>
                </Col>
              </div>
            ))}
          </Slider>
        </Row>
      </Card>
    </Fragment>
  );
};

export default MarketPlaceHeader
