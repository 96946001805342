import { sweetAlertConfirmation } from '../../src/components/group-components/AllGroupsSections/SweetAlert'
import { SweetErrorAlert } from '../../src/components/group-components/AllGroupsSections/SweetErrorAlert';


//this is code for to copy code from web and redirect user contact screen in mobile
export const handleContactClick = (event, phoneNumber) => {
    event.preventDefault();

    if (!phoneNumber || phoneNumber === "" || phoneNumber === undefined) {
        SweetErrorAlert("Phone number not available");
        return;
    }
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
        && 'ontouchstart' in window
        && navigator.maxTouchPoints > 1;

    // Additional checks to differentiate mobile and desktop emulation
    const isDesktopEmulation = !isMobile 
        && window.matchMedia("(pointer: coarse)").matches
        && (window.screen.width > 768 || navigator.maxTouchPoints === 1);

    if (isMobile && !isDesktopEmulation) {
        window.location.href = `tel:${phoneNumber}`;
    } else {
        navigator.clipboard.writeText(phoneNumber).then(() => {
            sweetAlertConfirmation('Phone number copied!');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }
};





