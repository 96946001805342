import React, { useState } from 'react'
import { Circle, Mic, Pause, Play } from 'react-feather'
import { Badge, Button, Card, CardBody, CardImg, CardImgOverlay, CardText, CardTitle, Col, Progress, Row } from 'reactstrap'
import MainIcon from '../../../../NewComponents/IMG/podcastIcon.svg'
import banner from '../../../../NewComponents/IMG/Adpreview.svg'
import Wings from '../../../../NewComponents/IMG/PocastSideWing.svg'
import DefaultpodcastImg from '../../../../NewComponents/IMG/DefaultpodcastImg.svg'

const Podcast = () => {
    const [playbutton, setplaybutton] = useState(false)
    return (
        <div>
            <Row>
                <Col>
                    <Card style={{ borderRadius: '0px' }} className='Podcast-section '>
                        <img className='PodcastForBanner' src={DefaultpodcastImg} alt='bannerImage' />
                        <img className='positionForMainPodacastIcon' height={50} src={MainIcon} alt='PodcastIcon' />
                        <img className=' d-flex justify-content-center align-items-center positionForWings' height={50} src={Wings} alt='Wings' />
                        <div className='positionForheadThree'>
                            <h6>
                                The transition to the hybrid era has not only affected our was a Roaring Success... <span className='text-primary'>see more</span>
                            </h6>
                            <Badge color='secondary' pill className='badge-glow'>
                                Episode - 5
                            </Badge>
                            <div className='d-flex justify-content-between'>
                                <small>12th Apr 2021, 5 mins</small>
                                <small><Mic size={15} />5k</small>
                            </div>
                        </div>
                        <CardBody>
                            <div className='d-flex align-items-center justify-content-between'>
                                {playbutton ? <Play onClick={() => setplaybutton(!playbutton)} fill='#000000' /> :
                                    <Pause fill='#000000' onClick={() => setplaybutton(!playbutton)} />}
                                <Progress className='sizeForpro progressFortimeLine progress-bar-success w-100' value={25} />
                                <small>02:45</small>
                            </div>
                            <p>Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.</p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* <br /> */}
            <Row>
                <Col>
                    <Card style={{ borderRadius: '0px' }} className='Podcast-section'>
                        <div className='positionForheadOne'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h6>International Work-life Podcast...</h6>
                                    <small>International Politics <Circle fill='#FFFFFF' size={4} strokeWidth={1} /> Total episodes (16)</small>
                                    <br />
                                    <small><Mic size={15} />20.0k</small>
                                </div>
                                <div>
                                    <Button color='primary'>All episodes</Button>
                                </div>
                            </div>
                        </div>
                        <img className='PodcastForBanner' src={DefaultpodcastImg} alt='bannerImage' />
                        <img className='positionForMainPodacastIcon' height={50} src={MainIcon} alt='PodcastIcon' />
                        <img className=' d-flex justify-content-center align-items-center positionForWings' height={50} src={Wings} alt='Wings' />
                        <div className='d-flex align-items-center positionForheadTwo'>
                            <img src={banner} width={70} height={65} className='rounded' alt='userImg' />
                            <div className='ml-1'>
                                <h6>
                                    The transition to the hybrid era has not only affected our was a Roaring Success... <span className='text-primary'>see more</span>
                                </h6>
                                <Badge color='secondary' pill className='badge-glow'>
                                    Episode - 5
                                </Badge>
                                <div className='d-flex justify-content-between'>
                                    <small>12th Apr 2021, 5 mins</small>
                                    <small><Mic size={15} />5k</small>
                                </div>
                            </div>
                        </div>
                        <CardBody>
                            <div className='d-flex align-items-center justify-content-between'>
                                {playbutton ? <Play onClick={() => setplaybutton(!playbutton)} fill='#000000' /> :
                                    <Pause fill='#000000' onClick={() => setplaybutton(!playbutton)} />}
                                <Progress className='sizeForpro progressFortimeLine progress-bar-success w-100' value={25} />
                                <small> 02:45</small>
                            </div>
                            <p>Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat diseases…and it was a Roaring Success (as in Roaring Drunk) I mean we had Long lines of prisoners fist fighting to use them.</p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Podcast