import React, { useState } from 'react'
import { ChevronRight, Edit, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
// import { NavLink } from 'react-router-dom'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import small1 from '../../NewComponents/IMG/small1.png'
import small2 from '../../NewComponents/IMG/small2.png'
import tagPic from '../../NewComponents/IMG/tagPic.png'
import Pollimg from '../../NewComponents/IMG/Pollimg.png'
import backicon from '../../NewComponents/IMG/backicon.svg'
import CommentImg from '../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../NewComponents/IMG/Share.svg'
import LikeBlack from '../profile-components/Img//likesvg.svg'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import MasonryGrid from '../Bookmark-Components/MasonryGrid'
import GridCompoentVideo from '../top-trending-components/Grid-Components/GridCompoentVideo'

const People = () => {
    const [selectActive, setSelectActive] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen(!reportOpen)
    }
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const People = [

        {
            trend: 'trend',
            top: 'top',
            count: '#1',
            count1: '#2',
            num: '1500'

        },
        {
            trend: 'trend',
            top: 'top',
            count: '#2',
            count1: '#3',
            num: '1500'

        },
        {
            trend: 'trend',
            top: 'top',
            count: '#4',
            count1: '#5',
            num: '1500'

        },
        {
            trend: 'trend',
            top: 'top',
            count: '#6',
            count1: '#5',
            num: '1500'

        },
        {
            trend: 'trend',
            top: 'top',
            count: '#7',
            count1: '#8',
            num: '1500'

        },
        {
            trend: 'trend',
            top: 'top',
            count: '#9',
            count1: '#10',
            num: '1500'

        },

    ]
    return (
        <div className='mt-3 p-2'>
            {/* -----------------------------------------------Ternary function Start Here------------------------------------------------------------- */}
            {!selectActive ?
                <>
                    <Row className='d-flex border roundedThisBorder p-2'>
                        <p className='p-3 TrendingPeoplethistext'>TRENDING #TAGS</p>
                        <Col xl='6' className='p-0 border-right'>
                            {
                                People.map((data) => {
                                    return (
                                        <>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pl-1 pt-3 align-items-center'>
                                                    <div className='TrendingTagsBorders'>
                                                        <p className='PilesThistags text-center'>{data.trend}</p>
                                                        <p className='PilesThistags text-center pt-2'>{data.count}</p>
                                                    </div>
                                                    <div className='TrendingTagsBorders ml-5'>
                                                        <p className='PilesThistags text-center'>{data.top}</p>
                                                        <p className='PilesThistags text-center pt-2'>{data.count1}</p>
                                                    </div>
                                                    <div className='toptitle1 pl-3'>
                                                        <p className='TopTitleThis'>#toptitle1</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center pr-4'>
                                                    <div className='Shotzsss d-flex' style={{ cursor: "pointer" }} onClick={() => setSelectActive(!selectActive)}>
                                                        <h5 className='mt-3 posts'>233 posts</h5>
                                                        <p style={{ cursor: "pointer" }} onClick={() => setSelectActive(!selectActive)} className='mt-3 ml-2 light-for-date '><ChevronRight /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </Col >
                        <Col xl='6' className='p-0'>
                            {
                                People.map((data) => {
                                    return (
                                        <>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex pl-1 pt-3 align-items-center'>
                                                    <div className='TrendingTagsBorders'>
                                                        <p className='PilesThistags text-center'>{data.trend}</p>
                                                        <p className='PilesThistags text-center pt-2'>{data.count}</p>
                                                    </div>
                                                    <div className='TrendingTagsBorders ml-4'>
                                                        <p className='PilesThistags text-center'>{data.top}</p>
                                                        <p className='PilesThistags text-center pt-2'>{data.count1}</p>
                                                    </div>
                                                    <div className='toptitle1 pl-3'>
                                                        <p className='TopTitleThis'>#toptitle1</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center pr-4'>
                                                    <div className='Shotzsss d-flex' style={{ cursor: "pointer" }} onClick={() => setSelectActive(!selectActive)}>
                                                        <h5 className='mt-3 posts'>233 posts</h5>
                                                        <p style={{ cursor: "pointer" }} onClick={() => setSelectActive(!selectActive)} className='mt-3 ml-2 light-for-date '><ChevronRight /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </Col >
                    </Row>
                </> : <>
                    <Row>
                        <Col className='p-1'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div>
                                        <img src={tagPic} alt='tagpic' className='p-1' />
                                    </div>
                                    <div className='mt-4 ml-1'>
                                        <p className='TagThisTrendingTitle'>#trendingtitle</p>
                                        <p className='TagThisCountPost'>554 posts</p>
                                    </div>
                                </div>
                                <img src={backicon} onClick={() => setSelectActive(!selectActive)} alt='back' className='mr-1' style={{ cursor: 'pointer' }} />
                            </div>
                        </Col>
                    </Row>
                    <MasonryGrid />

                </>}
            {/* -----------------------------------------------Ternary function End Here------------------------------------------------------------- */}
            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}
            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Post</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 p-2 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

        </div >
    )
}

export default People