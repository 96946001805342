import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";

const getAllConnections = (allConnections) => ({
  type: types.GET_ALL_CONNECTIONS,
  payload: allConnections,
});

export const loadAllConnections = (searchKey) => {
  return async (dispatch) => {
    
    try {
      console.log("searchkey ",searchKey)
      const body = {
        sortBy: "Connections",
        searchKey:searchKey
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/getPeopleList`,
        body,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
          },
        }
      );
      dispatch(getAllConnections(response.data.data.successResult.rows));
      return response.data.data.successResult.rows
      
    } catch (error) {
      console.error("Error fetching tagged people:", error);
    }
  };
};
