import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { UploadCloud, X } from 'react-feather'

const NFTUpload = () => {


    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageSelect = (event) => {
        const fileName = event.target.files[0];
        if (fileName) {
            const url = URL.createObjectURL(fileName);
            setSelectedImages((prevImages) => [...prevImages, url]);
        }
    };

    const handleRemoveImage = (index) => {
        setSelectedImages((prevImages) =>
            prevImages.filter((image, i) => i !== index)
        );
    };




    return (
        <Fragment>
            <div className="row ">
                <div className="Mp_FormIncludeSomeDetails px-4 mt-3 col-lg-12">
                   
                </div>
                <div className=" d-flex  col-md-12 Mp_Form_ImageUploadInputs1   gap-2 p-4 ">
                    {selectedImages.map((imageUrl, index) => (
                        <div
                            key={index}
                            className="MpChooseImage1 position-relative"
                            style={{ background: ' #F5F6FA' }}
                         
                        >
                            <img
                                src={imageUrl}
                                alt={`imageCont-${index}`}
                                className="w-100 h-100"
                                style={{ objectFit: 'cover', borderRadius: '20px' }}
                            />
                            <X
                                size={12}
                                className="ImageCrossButton_Mp"
                                onClick={() => handleRemoveImage(index)}
                            />
                        </div>
                    ))}

                    {/* Add more upload options button */}
                    <div className="MpChooseImage1 position-relative" style={{ background: ' #F5F6FA' }}>
                        <UploadCloud
                            size={30}
                            color={'#B3BAC2'}
                            onClick={() => document.querySelector('.SelectInput').click()}
                            style={{ boxSizing: 'content-box', padding: '35%' }}
                           
                        />
                   
                        <input
                            type="file"
                            name=""
                            onChange={handleImageSelect}
                            className="SelectInput d-none"
                           
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NFTUpload


