import * as types from "../../Constants/UserProfile/index";

const initialState = {
    userShotz: [],
    loading: true
};

const UserShotz = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_SHOTZ_DATA:
            return {
                ...state,
                userShotz: action.payload,
                loading: false
            };
        default:
            return state
    }
};

export default UserShotz;