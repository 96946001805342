import { Pagination, Stack } from '@mui/material'
import React, { Fragment, useEffect,useState } from 'react'
import { Link } from 'react-router-dom'
import AllMarketTypeData from './AllMarketTypeData'
import MarketApprovalPending from './MarketApprovalPending'
import MarketOnSale from './MarketOnSale'
import MarketSoldOut from './MarketSoldOut'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { data } from 'jquery'
import { useLocation } from 'react-router-dom'

const MarketTabSection = (props) => {

    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [pageIndex,setPageIndex]=useState(0);
    const [pageIndex1,setPageIndex1]=useState(0);
    const [pageIndex2,setPageIndex2]=useState(0);
    const [pageIndex3,setPageIndex3]=useState(0);
    const [pageSize,setPageSize]=useState(12);
    const [dataAll,setDataAll]=useState([]);
    const [dataOnSale,setDataOnSale]=useState([]);
    const [dataSoldOut,setDataSoldOut]=useState([]);
    const [dataApprovalPending,setDataApprovalPending]=useState([]);
    const [activeTab, setActiveTab] = useState(() => {
        if (type === 'viewer') {
            return 'ImageTab'; // or any tab id based on the type condition
        }
        return 'AllTab';
    });
    const [loading, setLoading] = useState(true);
    const [allCount,setAllCount]=useState(0);
    const [onSaleCount,setOnSaleCount]=useState(0);
    const [soldOutCount,setSoldOutCount]=useState(0);
    const [pendingCount,setPendingCount]=useState(0);
        
    const fetchData = async (MPtype, setData,setCount,pageIndex) => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/marketPlace/getMpPostByType`,
                    { type:MPtype, pageIndex, pageSize, userId },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setData(response.data.data.successResult);
                setCount(response.data.data.successResult.resultCount);
                setLoading(false);
            } else {
                setData('no user found');
                setLoading(false);
            }
        } catch (error) {
            setData(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData('all', setDataAll,setAllCount,pageIndex);
    }, [pageIndex, pageSize,props?.searchKey]);

    useEffect(() => {
        fetchData('onsale', setDataOnSale,setOnSaleCount,pageIndex1);
    }, [pageIndex1, pageSize,props?.searchKey]);

    useEffect(() => {
        fetchData('sold', setDataSoldOut,setSoldOutCount,pageIndex2);
    }, [pageIndex2, pageSize,props?.searchKey]);

    useEffect(() => {
        fetchData('pending', setDataApprovalPending,setPendingCount,pageIndex3);
    }, [pageIndex3, pageSize,props?.searchKey]);

//  console.log("allData===============================",dataAll)
        const TabSectionData = [
            {
                className: "nav-item nav-link active",
                id: "AllTab",
                link: "#nav-All",
                ariaControls: "all",
                ariaSelected: "true",
                TabName: `All (${dataAll?.resultCount || 0})`,
                count: dataAll?.resultCount || 0,
                component: <AllMarketTypeData searchKey={props?.searchKey} data={dataAll} count={allCount} setPageIndex={setPageIndex} pageIndex={pageIndex}/>
            },
            {
                className: "nav-item nav-link",
                id: "ImageTab",
                link: "#nav-image",
                ariaControls: "nav-image",
                ariaSelected: "false",
                TabName: `On Sale (${dataOnSale?.resultCount || 0})`,
                count: dataOnSale?.resultCount || 0,
                component: <MarketOnSale searchKey={props?.searchKey} data={dataOnSale} count={onSaleCount} setPageIndex={setPageIndex1} pageIndex={pageIndex1}/>
            },
            {
                className: "nav-item nav-link",
                id: "VideoTab",
                link: "#nav-video",
                ariaControls: "nav-video",
                ariaSelected: "false",
                TabName: `Sold out (${dataSoldOut?.resultCount || 0})`,
                count: dataSoldOut?.resultCount || 0,
                component: <MarketSoldOut searchKey={props?.searchKey} data={dataSoldOut} count={soldOutCount} setPageIndex={setPageIndex2} pageIndex={pageIndex2}/>
            },
            {
                className: "nav-item nav-link",
                id: "InvTab",
                link: "#nav-Inv",
                ariaControls: "nav-Inv",
                ariaSelected: "false",
                TabName: `Approval Pending (${dataApprovalPending?.resultCount || 0})`,
                count: dataApprovalPending?.resultCount || 0,
                component: <MarketApprovalPending searchKey={props?.searchKey} data={dataApprovalPending} count={pendingCount} setPageIndex={setPageIndex3} pageIndex={pageIndex3}/>
            }
        ];

        const filteredTabs = type === "viewer"
        ? TabSectionData.filter(tab => tab.id === "ImageTab" && tab.count > 0)
        : TabSectionData.filter(tab => tab.count > 0 && (tab.id !== "InvTab" || type !== "viewer"));

        const handleTabClick = (e, id) => {
        e.preventDefault();
        setActiveTab(id);
        };
    // console.log("filtered tabs======================",filteredTabs)

    return (
        <Fragment>
             <nav>
                <div className="tab-section">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {filteredTabs.length > 0 ? (
                            filteredTabs.map((tabinfo) => (
                                <li className="nav-item" key={tabinfo.id} onClick={(e) => handleTabClick(e, tabinfo.id)}>
                                    <Link className={`nav-link ${activeTab === tabinfo.id ? 'active' : ''}`} id={tabinfo.id} data-bs-toggle="tab"
                                        to={tabinfo.link} role="tab" aria-controls={tabinfo.ariaControls} aria-selected={tabinfo.ariaSelected}>
                                        {tabinfo.TabName}
                                    </Link>
                                </li>
                            ))
                        ) : loading ? (
                            <Spinner
                                animation='border'
                                role='status'
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    margin: 'auto',
                                    display: 'block',
                                    color: '#81C14B'
                                }}
                            >
                                <span className='sr-only'>Loading...</span>
                            </Spinner>
                        ) : (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '15vh',
                                width: '100%'
                            }}>
                                <h2 style={{ textAlign: 'center' }}>No Products</h2>
                            </div>
                        )}
                    </ul>
                    <div className="tab-content" id="nav-tabContent">
                        {filteredTabs.map((tabinfo) => (
                            <div
                                key={tabinfo.id}
                                className={`tab-pane fade ${activeTab === tabinfo.id ? 'show active' : ''}`}
                                id={tabinfo.link.slice(1)}
                                role="tabpanel"
                                aria-labelledby={`${tabinfo.id}Tab`}
                            >
                                {tabinfo.component}
                            </div>
                        ))}
                    </div>
                </div>
            </nav>
        </Fragment>
    )
}

export default MarketTabSection
