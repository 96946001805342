import React, { Fragment, useState } from 'react';
import "./Login.css";
import { Link, NavLink } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import { AlertCircle, Eye, EyeOff, Info, User } from 'react-feather';
import LanguageModal from './Modal-Components/LanguageModal';
import LoginBlockedModal from './Modal-Components/LoginBlockedModal';
import OffCanvasLanguages from './Modal-Components/OffCanvasLanguages';
import { useUserAuth } from '../../Context/userAuthContext';
import MsgMeeUserModal from './Modal-Components/MsgMeeUserModal';
import { Password } from '@mui/icons-material';
import LangModalNew from './Modal-Components/LangModalNew';


const LoginPage = () => {


    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }


    // login button Disable 
    const [buttonDisable, setButtonDisable] = useState(true)





    // Blocked Modal controller
    const [blockedModal, setBlockedModal] = useState(false)
    const BlocakedToggle = () => {
        setBlockedModal(!blockedModal)
    }


    const [msgmeeModal, setMsgMeeModal] = useState(false)

    const MsgMeeToggle = () => { setMsgMeeModal(!msgmeeModal) }



    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")

    const [showPassword, setShowPassword] = useState(false)





    return (
        <Fragment>
            {/* --------------------------------- Language Modal -------------------------------- */}
            {/* <LanguageModal modal={languages} toggle={LanguageModalToggle} /> */}
            {/* Blocked UserModal  */}
            <LoginBlockedModal modal={blockedModal} toggle={BlocakedToggle} />
            {/* --------------------- Language Modal two -------------------------- */}
            {/* <OffCanvasLanguages show={show} handleClose={handleClose} /> */}
            {/* --------------------------- MsgMee user if Modal ----------------------------- */}
            <MsgMeeUserModal modal={msgmeeModal} toggle={MsgMeeToggle} />
            {/* =========================Full Login Page ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec"><Link aria-current="page" className="active" to="/"><img src="/assets/images/logo.png" alt="logo" className="img-fluid" /></Link></div>
                            </div>
                            <div className="login-form">
                                <div>
                                    <div className="login-title">
                                        <h2>Welcome</h2>
                                    </div>
                                    <div className="login-discription">
                                        <h4>Please Login to your account.</h4>
                                    </div>
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <div className="input-block">

                                                        <Input type="text" className={`form-control pr-5`} onChange={(e) => { e.target.value.length > 0 ? setButtonDisable(false) : setButtonDisable(true) }} placeholder="Enter Mobile Number/Email" name="phone" maxLength={40} />

                                                        <User color='#B9B9C3' className='input-icon iw-20 ih-20' size={16} />
                                                        {/* </div> */}
                                                    </div>
                                                    <p className="error-input-msg LoginAlert400 d-none" >

                                                        <Info color="#EB5757" className='bi bi-exclamation-circle-fill mr-1' size={14} />
                                                        Mobile number does not register with us.
                                                    </p>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-block">
                                                        <Input type={`${showPassword ? "text" : "password"}`} className="form-control pr-5" placeholder="Enter your password" name="password" />
                                                        <EyeOff color='#C4C4C4' size={16} className={`input-icon iw-20 ih-20 ${showPassword ? "d-none" : ""}`} onClick={() => { setShowPassword(!showPassword) }} />
                                                        <Eye color='#C4C4C4' size={16} className={`input-icon iw-20 ih-20 ${showPassword ? "" : "d-none"}`} onClick={() => { setShowPassword(!showPassword) }} />
                                                    </div>
                                                    <p className="error-input-msg" >
                                                        <AlertCircle color="#EB5757" className='bi bi-exclamation-circle-fill mr-1' size={14} />Password Incorrect
                                                    </p>
                                                </div>
                                                <div className="bottom-sec">
                                                    <div className="form-check checkbox_animated"><input type="checkbox" className="form-check-input" id="exampleCheck1" /><label className="" htmlFor="exampleCheck1" >Remember me</label></div>
                                                    <NavLink to="/forgotpasswordpage" className="ms-auto forget-password" >forgot password?</NavLink>
                                                </div>
                                                <div className="btn-section">
                                                    <Button color='primary' disabled={buttonDisable}>LOG IN</Button>
                                                </div>
                                            </form>

                                            {/* ====================== Privacy An  notes Link ======================== */}
                                            <div className="connect-with">
                                                <div className="no-account-blk">
                                                    <p className='Login4D_500'>Don't have an account? <Link className="" to="/signuppage">Get Started</Link></p>
                                                </div>
                                                <div className="privacy-usernoti-blk "><a className='LoginLanguageBtn' rel='noreferrer' target='_blank' href="https://www.sociomee.com/privacy-policy/">Privacy Policy</a> < strong className='LoginLanguageBtn fw-bolder'>•</strong> <a rel='noreferrer' className='LoginLanguageBtn' target='_blank' href="https://www.sociomee.com/user-notice/">User Notice</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* ============================= Language Section ================================== */}
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                {/* --------------------------- Labnguages------------------------------ */}
                                {["English", "Hindi", "Deutsch"].map((item, index) => (
                                    <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                ))}
                            </span>
                            <button onClick={() => setLanguages(true)} >
                                ...More
                            </button>
                        </div>
                    </div >

                    {/* ======================== Bottom Canvas Language section  ======================================= */}
                </div>
            </section>
        </Fragment>
    )
}

export default LoginPage

