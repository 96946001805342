import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Card, Col, Row } from 'reactstrap'
// import Pollimg from '../../../NewComponents/IMG/roundedimg2.svg'
// import Pollimg1 from '../../../NewComponents/IMG/roundedimg3.svg'
// import Frame5 from '../../../NewComponents/IMG/Frame5.png'
// import Frame6 from '../../../NewComponents/IMG/Frame6.png'
// import Frame7 from '../../../NewComponents/IMG/Frame7.png'
import LeftquotationIcon from '../../../NewComponents/IMG/comma.png'
import RightquotationIcon from '../../../NewComponents/IMG/comma1.png'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal';
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
// import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import ReportModal from './ModalGrid/ReportModal'
import { topThoughtData } from '../DummyArray'
import { NavLink } from 'react-router-dom'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'


const GridThoughtTab = () => {

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }



    // ------------------------------------------------------------Main return function------------------------------------------------------------
    return (
        <div className=' p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
            {/* <Row> */}
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 667: 2, 1157: 3 }}
            >
                <Masonry columnsCount={3} gutter="10px" >
                    {
                        topThoughtData.map((data, index) => {
                            return (
                                // <Col xl='3' lg='6' md='12' className='p-1' key={index} >
                                <Card className='roundedThisBorder on-hover-bg' key={index} >
                                    <div className='d-flex p-2'>
                                        <NavLink to='/MyProfile'><Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                            Top #{data.topsNumber}
                                        </Badge></NavLink>&nbsp;
                                        <NavLink to='/MyProfile'><Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                            Trending #{data.trendingNumber}
                                        </Badge></NavLink>

                                    </div>
                                    <div className='d-flex justify-content-between  mx-2'>
                                        <div className='d-flex align-items-center ml-2'>
                                            <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                            <div className='ml-2'>
                                                <div className='d-flex align-items-center' >
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }} >
                                                        <h4 className='user-profile'>{data.name}</h4>
                                                    </NavLink>
                                                    {/* <span style={{fontSize:'13px'}}>emoji-picker-react</span> */}
                                                    <p className='d-none d-lg-block align-items-center ml-2' style={{ fontSize: '13px' }}>posted a thought</p><br />
                                                </div>
                                                <small className='text-muted light-for-date'>19 sept at 07:00 pm</small>
                                            </div>
                                        </div>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className='p-0 m-0' color='flat' >
                                                    <MoreHorizontal size={19} color='#00000040' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share Thought</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem > <NavLink to='/MyProfile' style={{ color: '#212529' }} > <Eye size={13} />&nbsp;&nbsp;View creator's profile </NavLink></DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                    {/* LeftIconforthrought rem-mob-view */}
                                    {
                                        index === 0 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#16C31E', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                            {/* RightIconforthrought rem-mob-view */}
                                        </div> : " "
                                    }

                                    {
                                        index === 1 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#1F4E69', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p className='mt-2 ml-1'>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 2 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#16C31E', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 3 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#FF8B72', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 4 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#16C31E', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 5 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#FF8B72', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 6 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#1F4E69', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 7 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#FF8B72', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 8 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#1F4E69', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 9 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#FF8B72', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    {
                                        index === 10 ? <div style={{ marginTop: '1rem', borderRadius: '10px', background: '#1F4E69', color: '#F8DF88' }} className='p-3 mx-3'>
                                            <img src={LeftquotationIcon} className='' alt="icon" />
                                            <div className='mx-4 text-center'>
                                                <div className='text-center'>
                                                    <p>{data.ThoughtThistext}</p>
                                                </div>
                                            </div>
                                            <div className='text-right'>
                                                <img src={RightquotationIcon} alt="icon" className='' />
                                            </div>
                                        </div> : " "
                                    }
                                    <div className='Treanding_bottom_section mt-2' style={{ height: "50px" }}>
                                        <div className='d-flex align-items-center'>
                                            {/* {liked ?
                            <img src={Circlelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                            :
                            <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                        }
                        &nbsp;
                        11.2k */}
                                            <div onClick={() => handleLike()}>
                                                {liked ?
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                    :
                                                    <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                }
                                            </div>
                                            11.2k
                                        </div>
                                        <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} hseight={16} className='' alt='comment' />
                                            </div>
                                            11.5k
                                        </div>
                                        <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                            </div>
                                            11.2k
                                        </div>

                                    </div>
                                </Card>
                                //  </Col>
                            )
                        })
                    }
                </Masonry>
            </ResponsiveMasonry>
            {/* </Row> */}

            {/* -----------------------------------------------------------------Modal components start------------------------------------------------------------------ */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------------------------Modal components end------------------------------------------------------------------ */}
        </div>
    )
}

export default GridThoughtTab