import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

import { AtSign, Circle, Clock, Globe, MapPin, Phone, Settings, Search, XCircle } from 'react-feather'
import { Button, Col, Row, ModalBody, Modal, Input, ModalHeader, InputGroup, InputGroupText } from 'reactstrap'
import SetUpActionModal from '../AllTypesModal/SetUpActionModal';
import BizAvatar from '../BizAvatar'

import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import Img from '../Img/ad.svg'
import maps from '../Img/maps.svg'
import em from '../Img/em.svg'
import em1 from '../Img/em1.svg'
import em3 from '../Img/em3.svg'
import tm from '../Img/tm.svg'
import "./biz1.css";
import $ from 'jquery';
import ATHERATE from './img/attharate.svg';
import Globees from './img/globe.svg';
import Phones from './img/phone.svg';
import Times from './img/time.svg';
import Location from './img/Loc.svg';
import { Link } from 'react-router-dom';
import Bprofile from '../Img/BizProfile.png'
import Invite from '../Img/inv.svg'




mapboxgl.accessToken = 'pk.eyJ1IjoiNzQ4NSIsImEiOiJjbDFua3kwcHIwMDE1M2luMXhleDNqMGZiIn0.Mj40f5LXER6tUfR3ygQLaA'
const CreationRightSide = ({
    createBizPage,
    startingTimeShift1,
    startingMinute,
    selectedStartingTimeValue,
    selectedValueClosing,
    endingTimeShift1,
    endingMinute,
    webUrl,
    webUrl1,
    email,
    email1,
    contactNumber1,
    contactNumber2,
}) => {

    // console.log('contactNumber', contactNumber)

    const [bpaddress, setbpaddress] = useState('')
    const [locationLAT, setlocationLAT] = useState('')
    const [locationLONG, setlocationLONG] = useState('')
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                setbpaddress('Gomti Nagar' + ", " + 'Lucknow')
                setlocationLAT(latitude)
                setlocationLONG(longitude)
                console.log("Latitude: " + latitude + ", Longitude: " + longitude);
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
        navigator.geolocation.getCurrentPosition(position => {
            const map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [position.coords.longitude, position.coords.latitude],
                zoom: 14
            });
            const marker = new mapboxgl.Marker()
                .setLngLat([position.coords.longitude, position.coords.latitude])
                .addTo(map);
            const geolocate = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            });

            const reverseGeocode = async (longitude, latitude) => {
                const geocodingEndpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`;
                const accessToken = 'pk.eyJ1IjoiNzQ4NSIsImEiOiJjbDFua3kwcHIwMDE1M2luMXhleDNqMGZiIn0.Mj40f5LXER6tUfR3ygQLaA';
                const requestUrl = `${geocodingEndpoint}?access_token=${accessToken}`;
                try {
                    const response = await fetch(requestUrl);
                    if (response.ok) {
                        const address = await response.json();
                        console.log('address*', address);
                        const cityComponent = address.features?.[0]?.context?.find(component =>
                            component?.id?.startsWith('place') && (component?.text || component?.short_code)
                        );
                        const cityName = cityComponent ? cityComponent.text || cityComponent.short_code : 'unknown';
                        console.log('city', cityName); // Log the city name to the console
                    } else {
                        throw new Error(`Error fetching address. Status: ${response.status}.`);
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            reverseGeocode(locationLONG, locationLAT);
            map.addControl(geolocate);
        });
    }, [])


    //   the summary part 
    // $(document).ready(function() {
    //     // Get references to relevant elements
    //     const summaryInput = $('#exampleText');
    //     const summaryDisplay = $('.Biz_Creation_heading_Rightsection p');
    //     const summaryPlaceholder = $('.Biz_Creation_heading_Rightsection span');

    //     // Initial state: hide the summary display
    //     summaryDisplay.hide();

    //     // When user types in the input
    //     summaryInput.on('input', function() {
    //         const inputText = $(this).val();
    //         if (inputText) {
    //             // Show the summary and hide the placeholder
    //             summaryDisplay.text(inputText);
    //             summaryPlaceholder.hide();
    //             summaryDisplay.show(); // Immediately show the updated text
    //         } else {
    //             // Show the placeholder and hide the summary
    //             summaryDisplay.hide();
    //             summaryPlaceholder.show();
    //         }
    //     });
    // });




    const BizCreationData = [
        {
            link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
            name: "Address 1",
            name1: "Your Address will display here. ",
        }
    ]
    const BizSummaryData = [
        {
            img: <AtSign size={15} color="#81C14B" />,
            name: "Email Address",
            subTitle: "Your Email Address will display here. "
        },
        {
            img: <Phone size={15} color="#81C14B" />,
            name: "Contact Number ",
            subTitle: "Your contact number will display here.  "
        },
        {
            img: <Clock size={15} color="#81C14B" />,
            name: "Timing ",
            subTitle: "Your Timing will display here. "
        },
        {
            img: <Globe size={15} color="#81C14B" />,
            name: "Website",
            subTitle: "Your website will display here. "
        },

    ]

    const inviteData = [
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Steward@564',
            typesof: 'Following'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Steward@564',
            typesof: 'Following'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Steward@564',
            typesof: 'Following'
        }
    ]
    const toggleinvite = () => {



        setsendDropdownOpen((prevState) => !prevState)

    }
    const [senddropdownOpen, setsendDropdownOpen] = useState(false)

    const [cancleModal, setCancleModal] = useState(false)
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const CancleHandle = () => {
        setCancleModal(!cancleModal);
    }
    const CancelButtonModal = () => {
        return (
            <>
                <Modal isOpen={cancleModal} toggle={CancleHandle} className='modal-dialog-centered'>

                    <div className=' modalHeading p-2 pt-2 text-center rounded-2' style={{
                        backgroundColor:
                            '#F6F6F6'
                    }} >
                        <h3 >Cancel BizPage</h3>
                    </div>
                    <ModalBody>
                        <div className='modalBodayParagraph text-center '>
                            <p>Do you really want to cancel the BizPage Creation?</p>
                        </div>
                        <div className='ModalBodayButton text-center mt-4'>

                            <Button outline className="CancleButton" onClick={CancleHandle} >
                                Cancel
                            </Button>&nbsp;&nbsp;
                            <Link to='/Biz'>
                                <Button color="primary" className="Creation_preview_btn" >Confirm</Button>
                            </Link>
                        </div>
                    </ModalBody>

                </Modal>
            </>
        )
    }


    const SendModal = () => {
        const [isSentList, setIsSentList] = useState(inviteData.map(() => false));

        const handleSendClick = (index) => {
            const updatedIsSentList = [...isSentList];
            updatedIsSentList[index] = true;
            setIsSentList(updatedIsSentList);
        }
        const handleCloseModal = () => {
            // Set the senddropdownOpen state to false to close the modal
            toggleinvite();
        };
        return (
            <>
                {senddropdownOpen ? (
                    <Modal isOpen={senddropdownOpen} className='modal-dialog-centered modalmargin' toggle={toggleinvite} >
                        <div

                            onClick={handleCloseModal}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>

                        <ModalHeader className='d-flex justify-content-center align-items-center'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <img src={Invite} height={90} width={90} alt='userimage' />
                            </div>


                            <h2 className='sucessmodal'>BizPage Created Successfully!</h2>
                        </ModalHeader>
                        <ModalBody>
                            <h3> Send Invitation</h3>

                            <div className='d-flex align-items-center justify-content-between'>

                                <InputGroup className="input-group-merge d-flex px-3 py-1 my-31 rounded  align-items-center mr-2" style={{ background: "rgba(246, 246, 246, 1)" }}>
                                    <Search color='#666666' size={16} />
                                    <Input type='search' placeholder=" Search..." className='coonection-input-style' />
                                </InputGroup>

                            </div>
                            <div className="scrollForGolive mt-2">
                                {
                                    inviteData.map((d, i) => {
                                        return (
                                            <div>
                                                <div className='d-flex align-items-center justify-content-between mt-3  mr-3 mb-3 ml-1' key={i} >
                                                    <div className='d-flex align-items-center'>
                                                        <img src={Bprofile} height={40} width={40} className='rounded-circle' alt='userimage' />
                                                        <div className='ml-1 invitetext'>
                                                            <div className='position-relative'>
                                                                <h6 style={{ fontWeight: 'bold', fontSize: '14px', position: "relative", top: "-4px" }} className='text-muted'>{d.userNames}</h6>

                                                            </div>

                                                            <h6 className='text-muted ' style={{ position: 'relative', top: '7px' }}>{d.joined}</h6>

                                                        </div>


                                                    </div>
                                                    {/* <hr style={{ height: '1px', color: "#EBEBEB" }}></hr> */}

                                                    <div className='w-25'>
                                                        <Button
                                                            className='sentex'
                                                            color='primary w-100'
                                                            onClick={() => handleSendClick(i)}
                                                            disabled={isSentList[i]} // Disable the button when it's already sent
                                                        >
                                                            {isSentList[i] ? ' Invited' : 'Invite'}
                                                        </Button>
                                                    </div>



                                                </div>
                                                <hr style={{ height: '1px', color: "#c4c4c4" }}></hr>
                                            </div>









                                        )
                                    })
                                }


                                <Link to='/Biz'>
                                    <Button color='primary ' className='invitebutton'>ok</Button>
                                </Link>

                            </div>




                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    /// setup action button
    const [actionSetUp, setActionSetUp] = useState(false)

    const toggleSetUp = () => {
        setActionSetUp((preview) => !preview)
    }
    // end 
    return (
        <Fragment>
            {/*==================start Biz_tOP_Banner_section ===================*/}
            <div className="group-details-top biz-details-top biz-cration-preview" style={{
                borderRadius: '5px',
                background: '#FFF',
                boxShadow: '0px 0px 10px 0px rgba(126, 178, 98, 0.40)',
            }}>
                <div className="grp-preview">
                    <div className="Biz_Creation_Rightsection d-flex justify-content-between">
                        <h3>Preview</h3>
                        <div>

                            <Button color="primary" onClick={toggleinvite} className="BizCreation_Btn_Fillsection savebizpage" >Create BizPage</Button>
                            <Button onClick={CancleHandle} otline className="ml-2 Creation_preview_Cancelbtn" style={{ color: 'black', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }} >Cancel</Button>

                        </div>
                    </div>
                </div>
                <div className="gd-top-block" style={{ backgroundImage: `url(${createBizPage?.coverImages?.[1]?.coverUrl ? `${process.env.REACT_APP_IMAGEURL}${createBizPage?.coverImages?.[1]?.coverUrl}` : 'assets/images/demo-banner-1.jpg'})` }}>
                </div>
                {/* <div className="gd-top-block" style={{ background: "url(assets/images/demo-banner-1.jpg)" }}>
                </div> */}
                <div className="heading-title-blk head-title-biz">
                    <Row >
                        <Col className=" order-lg-2">
                            <div className="bizCreation-user-pro-blk">
                                <img src={createBizPage?.coverImages?.[0]?.coverUrl ? `${process.env.REACT_APP_IMAGEURL}${createBizPage?.coverImages?.[0]?.coverUrl}` : 'assets/images/demo-1.jpg'} alt='logo' />

                                {/* <img src= {createBizPage?.coverImages?.[0]?.coverUrl ?  `${process.env.REACT_APP_IMAGEURL}$createBizPage?.coverImages?.[0]?.coverUrl` : 'assets/images/demo-1.jpg'} */}
                                {/* alt='logo'/> */}
                                <h5> {createBizPage.name ? createBizPage.name : 'Your Business Page Name'} </h5>
                                <p>
                                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-10 ih-10">
                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                        <circle cx="12" cy="10" r="3"></circle>
                                    </svg>
                                    {createBizPage?.address?.[0]?.country ? createBizPage?.address?.[0]?.country : 'address'}&nbsp;
                                    {createBizPage?.address?.[0]?.city ? createBizPage?.address?.[0]?.city : ' '}&nbsp;

                                    {createBizPage?.address?.[0]?.zipCode ? createBizPage?.address?.[0]?.zipCode : ' '}
                                </p>
                            </div>
                        </Col>
                        <Col className=" order-lg-1  bizavtar  " >
                            <div className="gd-content-area-top-blk   previewmember ">
                                <div className="groupmember-blk">
                                    <span className='text-capitalize' style={{ fontSize: '12px' }}>{createBizPage.bpCategoryName ? createBizPage.bpCategoryName : 'Category'}</span>&nbsp;<Circle fill='#C4C4C4' size={5} />&nbsp;
                                    <span style={{ fontSize: '12px' }}>{createBizPage.bpSubCategoryName ? createBizPage.bpSubCategoryName : 'Sub Category'}</span>
                                </div>
                                <div className="people-likes matual-friend-sec">
                                    {/* <ul className="matual-friend-blk">
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                    </ul> */}
                                     <BizAvatar />
                                    <h6>Visited Person</h6>
                                </div>
                                <div className="people-likes matual-friend-sec popmember">
                                    {/* <ul className="matual-friend-blk  popov">
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                        <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                            data-name="User Name" data-img="assets/images/user-sm-1.jpg">
                                            <img src="assets/images/user-sm-1.jpg" className="img-fluid bg-img" alt="" />
                                        </li>
                                    </ul> */}
                                     <BizAvatar />
                                    <h6>Members</h6>
                                </div>
                            </div>
                        </Col>
                        <Col className=" order-lg-4">
                            <ul className="followlist-blk">
                                <li>--<span>Rating</span></li>
                                <li>--<span>Likes</span></li>
                                <li>--<span>Posts</span></li>
                                <li>--<span>Followers</span></li>
                            </ul>
                            <div className="create-biz-btn  "><a className="btn btn-primary green-clr-btn  stngbutton" onClick={toggleSetUp} >Setup your Button</a></div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="profile-menu section-t-space biz-cration-menu-preview  settingicon " style={{
                borderRadius: '5px',
                background: '#FFF',
                boxShadow: '0px 0px 10px 0px rgba(126, 178, 98, 0.40)',
            }}>
                <ul>
                    <li>
                        <a href="#" className="active-disable  ">
                            <h6>Overview</h6>
                        </a>
                    </li>
                    <li>
                        <a href="#">

                            <h6>Post</h6>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <h6>Review</h6>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <h6>Photos</h6>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <h6>About</h6>
                        </a>
                    </li>
                </ul>
                <ul className="right-menu d-xl-flex d-none biz-cration-menu-preview">
                    <li>
                        <a href="#">

                            <h6>Post an offer</h6>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="your-svg-class">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14 settingsvg"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                            <h6>Setting</h6>
                        </a>
                    </li>
                    {/* <Button disable className="border-0" ><Settings size={16} />&nbsp;Setting</Button> */}
                </ul>
            </div>
            <Row className='mt-3' >
                <Col md="5" >
                    <div className="custom-card-block pt-4" style={{
                        borderRadius: '5px',
                        background: '#FFF',
                        boxShadow: '0px 0px 10px 0px rgba(126, 178, 98, 0.40)',
                    }}>
                        <div className="pl-1">
                            <h3>Overview </h3>
                        </div>
                        {/* ===================add map img=================== */}
                        <div className='mt-2 p-2 pl-1 '>
                            <img src={maps} alt="" width='190px' />
                        </div>
                        <div className="bizAbout_cont_section p-2 pl-1">
                            <ul>
                                <div id="map" ></div>
                                <h4 className='pl-1'>Address 1</h4>
                                {/* <p className='pl-1'>Address 2</p> */}
                                <img src={Location} alt="" width='35px' height='35px' className='' />
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col md="7">
                    <div className="custom-card-block" style={{
                        borderRadius: '5px',
                        background: '#FFF',
                        boxShadow: '0px 0px 10px 0px rgba(126, 178, 98, 0.40)',
                    }} >

                        <div className="Biz_Creation_heading_Rightsection">
                            <h3>Summary</h3>

                            {createBizPage.summary ? (
                                <p className='text-wrap' style={{ wordWrap: 'break-word', color: '#B5B5B5', fontSize: '15px', }}>
                                    {createBizPage.summary}
                                </p>
                            ) : (
                                <span style={{ color: '#B5B5B5', fontSize: '12px' }}>Your Summary will display here.</span>
                            )}
                        </div>
                        <div className='p-1 logess '>


                            <div className='ml-2'>
                                <img src={ATHERATE} className='gl' alt="" />
                                <h4 className='headd'> &nbsp;&nbsp; Email Address</h4>
                                <p className='pys-1' style={{ color: '#B5B5B5', fontSize: '12px', overflowWrap: 'break-word', maxWidth: '84%' }}>
                                    {email ? email : 'Your Email  should be displayed here'}
                                    <br />
                                    {email1 ? email1 : ''}
                                </p>
                               
                            </div>
                            <div className='ml-2'>
                                <img src={Phones} className='gl' alt="" />
                                <h4 className='headd'> &nbsp;&nbsp; Contact </h4>
                                <p className='pys-1' style={{ color: '#B5B5B5', fontSize: '12px', overflowWrap: 'break-word', maxWidth: '84%' }}>
                                    {contactNumber1 ? contactNumber1 : 'Your contact should be displayed here'}
                                    <br />
                                    {contactNumber2 ? contactNumber2 : ''}
                                </p>
                                {/* <img src={em3} alt="" width='225px' height='70px' /> */}
                            </div>
                            <div className='ml-2'>
                                <img src={Times} className='gl' alt="" />
                                <h4 className='headd'> &nbsp;&nbsp;  Timing </h4>
                                {/* <p className='pys-1' style={{ color: '#B5B5B5', fontSize: '12px' }}> {startingTimeShift1 ?startingTimeShift1 : 'your  Timing should be disolay here'} </p> */}
                                {/* <div className='ml-31 py-2'>
                                <h4>Opening Time</h4>
                                <p className='py-1'>
                                    {
                                        startingTimeShift1 && startingMinute ?
                                            `${startingTimeShift1} : ${startingMinute} :  ${selectedStartingTimeValue}` : '00:00'
                                    }
                                </p>
                            </div> */}
                                {/* <div className='ml-31 py-2'>
                                <h4>Closing Time</h4>
                                <p className='py-1'>
                                    {
                                        endingTimeShift1 && endingMinute ?
                                            `${endingTimeShift1} : ${endingMinute} :  ${selectedValueClosing}` : '00:00'
                                    }
                                </p>
                            </div> */}
                                {/* <img src={tm} alt="" width='225px' height='70px' /> */}

                            </div>

                            <div className='ml-2'>
                                <img src={Globees} className='gl' alty="" />
                                <h4 className='headd'> &nbsp;&nbsp; Website </h4>


                                <p className='pys-1' style={{ color: '#B5B5B5', fontSize: '12px', overflowWrap: 'break-word', maxWidth: '84%' }}>
                                    {webUrl ? webUrl : 'Your Website should be displayed here'}
                                    <br />
                                    {webUrl1 ? webUrl1 : ''}
                                </p>
                                {/* <p className='pys-1' style={{ color: '#B5B5B5', fontSize: '12px' }}> {webUrl1 ? webUrl1 : 'add your Website should be display here'} </p> */}

                                {/* <img src={em} alt="" width='260px' height='70px' /> */}
                            </div>
                            {/* <div className='ml-3 py-2'>
                                <h4>Opening Time</h4>
                                <p className='py-1'>
                                    {
                                        startingTimeShift1 && startingMinute ?
                                            `${startingTimeShift1} : ${startingMinute} :  ${selectedStartingTimeValue}` : '00:00'
                                    }
                                </p>
                            </div>
                            <div className='ml-3 py-2'>
                                <h4>Closing Time</h4>
                                <p className='py-1'>
                                    {
                                        endingTimeShift1 && endingMinute ?
                                            `${endingTimeShift1} : ${endingMinute} :  ${selectedValueClosing}` : '00:00'
                                    }
                                </p>
                            </div> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <CancelButtonModal />
            <SetUpActionModal
                isOpen={actionSetUp}
                updateOpen={toggleSetUp}
            />
            <SendModal />
            {/*==================end Biz_tOP_Banner_section ===================*/}
        </Fragment>
    )
}

export default CreationRightSide