import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import Pollimg from '../../../NewComponents/IMG/roundedimg2.svg'
// import Pollimg1 from '../../../NewComponents/IMG/roundedimg3.svg'
import PollUserImg from '../../../NewComponents/IMG/PollUserImg.svg'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
// import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal';
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import { trendingPollData } from '../DummyArray'
import ReportModal from './ModalGrid/ReportModal'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import { NavLink } from 'react-router-dom'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'


const GridPoll = () => {
    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }


    // ------------------------------------------------Main return function-----------------------------------------------------------
    return (
        <div className='mt-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
            {/* <Row className='PeopleThisGrid'> */}
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 667: 2, 1157: 3 }}
            >
                <Masonry columnsCount={3} gutter="12px" >
                    {
                        // ------------------------------------------------Dynamic rendering card data-----------------------------------------------------------
                        trendingPollData.map((data, index) => {
                            return (
                                // <Col xl='3' lg='4' md='6' className='w-100 p-1' key={index}>
                                <Card className='roundedThisBorder on-hover-bg-border'>
                                    {/* <div className='d-flex p-2 '>
                                    <Badge color='primary'>
                                        Top #{data.topsNumber}
                                    </Badge>
                                </div> */}

                                    <div className='d-flex p-2'>
                                    <NavLink to='/MyProfile'>
                                            <Badge color='light-primary' className='px-2 ml-2 d-flex align-items-center light-font' >
                                                Trending #{data.trendingNumber}
                                            </Badge>
                                        </NavLink>&nbsp;
                                        <NavLink to='/MyProfile'>
                                            <Badge color='primary' pill className=' px-2 d-flex align-items-center light-font'>
                                                Top #{data.topsNumber}
                                            </Badge>
                                        </NavLink>
                                       

                                    </div>

                                    <div className='d-flex justify-content-between mx-2 '>
                                        <div className='d-flex align-items-center '>
                                            <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                            <div className='ml-2'>
                                                <div className='d-flex' >
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }} >
                                                        <h4 className='user-profile textpolls' style={{ textWrap: 'nowrap' }}>{data.name} </h4>
                                                    </NavLink>
                                                    <small className='px-1 textpolls small-text ' style={{ textWrap: 'nowrap' }} >posted a poll</small>
                                                </div>
                                                <small className='text-muted light-for-date'>{data.dateAndTime}</small>
                                            </div>
                                        </div>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className='p-0 m-0' color='flat' >
                                                    <MoreHorizontal size={19} color='#999999' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share Poll</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} > <Eye size={13} />&nbsp;View user</NavLink></DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                    {/* <div className='d-flex align-items-start p-2'>
                                    <img src={PollUserImg} height={18} width={20} alt='union' className='polluThisserimg' />
                                    <p className='ml-1'>more user-centric and conv...{seemore ? 'by their very nature, are about connections. Through your' : null}attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>
                                </div> */}

                                    <div className='d-flex align-items-start p-2 m-2 rounded-2 mt-2 pollutext' style={{ backgroundColor: '#e6f3db', height: 'auto' }}>
                                        <img src={PollUserImg} height={25} width={25} alt='union' className='polluThisserimg mr-1 mt-1' />
                                        <p className='ml-1 poll-text user-profile' style={{ fontSize: '13px' }}>{data.pollText}</p>
                                    </div>

                                    <div className='d-flex justify-content-between align-items-center px-4 pt-3' style={{ marginLeft: '-1rem' }}>
                                        <div className='d-flex align-items-center'>
                                            <h3 className='text-primary' style={{ fontSize: '13px' }}>10</h3>
                                            <small className=''>&nbsp;Options</small>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <h3 className='text-primary' style={{ fontSize: '13px' }}>156</h3>
                                            <small className=''>&nbsp;Voted</small>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <h3 className='text-primary' style={{ fontSize: '13px' }}>144</h3>
                                            <small className=''>&nbsp;Viewed</small>
                                        </div>
                                    </div>
                                    {/* <div className='ml-4 p-2 pt-4 text-muted fst-italic date-time-fs' style={{ fontSize: '14px' }} >
                                        Posted on 23 Mar 2021
                                    </div> */}
                                    <div className='Treanding_bottom_section mt-3' style={{ height: "50px", width: '100%' }}>
                                        <div className='d-flex align-items-center'>
                                            {/* {liked ?
                            <img src={Circlelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                            :
                            <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                        }
                        &nbsp;
                        11.2k */}
                                            <div onClick={() => handleLike()}>
                                                {liked ?
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                    :
                                                    <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                }
                                            </div>
                                            11.2k
                                        </div>
                                        {/* ============================comment icon============================== */}
                                        {/* <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} hseight={16} className='' alt='comment' />
                                            </div>
                                            11.5k
                                        </div> */}
                                        <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                            </div>
                                            11.2k
                                        </div>

                                    </div>
                                </Card>
                                // </Col>
                            )
                        })
                    }
                </Masonry>
            </ResponsiveMasonry>
            {/* </Row> */}

            {/* ------------------------------------------------Modal components start----------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* ------------------------------------------------Modal components end----------------------------------------------------------- */}

        </div>
    )
}

export default GridPoll