
import React, { useState } from 'react'
import Rating from '@mui/material/Rating';
import { AlertTriangle, Bell, Delete, Edit, Eye, Filter, Link, MoreHorizontal, Share2, Star, Users } from 'react-feather'
// import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { UncontrolledButtonDropdown, Modal, ModalBody, DropdownMenu, DropdownItem, DropdownToggle, Card, Col, Row, InputGroup, Input, Button, ModalHeader } from 'reactstrap'
import cricleimg from '../../NewComponents/IMG/cricleimg.png'
import cricleimg1 from '../../NewComponents/IMG/cricleimg1.png'
import AvatarGroupComponent from './AvatarGroupComponent'
import { NavLink } from 'react-router-dom'
import BlankTab from '../../NewComponents/BlankTab';
import BizpageAccordion from './BizpageAccordion';
import SliderButton from './SliderButton';
import Slider from "react-slick";
import LocationIcon from '../../NewComponents/IMG/LocationIcon.svg'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal';
import { Offcanvas } from 'react-bootstrap';
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation';
import ChipBox from '../top-trending-components/ChipBox';

let ownedGroupSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1
            }
        },
    ]
};

const BizPage = ({ filledColor, dir }) => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const [value, setValue] = React.useState(2);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    const Bizpage = [
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Action Pack',
            content1: 'Music Equipment',
            num: '52400',
            img: cricleimg,
            button: <button className='btn btn-primary'>Following</button>,
            button1: <Button Button color='primary' outline><Users size={15} className='align-self-center' /> Invite</Button>
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Traditional Wedding ',
            content1: 'Music Equipment',
            num: '60400',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Action Pack',
            content1: 'Music Equipment',
            num: '70400',
            img: cricleimg,
            button: <button className='btn btn-primary'>Following</button>,
            button1: <Button Button color='primary' outline><Users size={15} className='align-self-center' /> Invite</Button>
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            num: '92400',
            content: 'Traditional Wedding ',
            content1: 'Music Equipment',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            num: '92400',
            content: 'Traditional Wedding ',
            content1: 'Music Equipment',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>
        },
    ]
    return (
        <div>
            <Card className='p-3 border-0'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col md='6'>
                        <div className='d-flex mt-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Traditional Wedding”</p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='d-flex justify-content-end'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view ml-2'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="group-custom-block">
                    <Slider {...ownedGroupSettings} className="default-space">
                        {
                            Bizpage.map((data) => {
                                return (


                                    <div >
                                        <div className="group-slide-box border mt-4">
                                            <div className="gallery-img-sublink-this-bizpage">
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color='flat-primary' >
                                                        <MoreHorizontal color='#FAF7F7' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <NavLink className="bizpagelink-blk">
                                                <img src="/assets/images/group-img-1.jpg" className="group-img" />
                                                <div className="biz-adminprofile-blk"><img src={data.img} />
                                                </div>
                                            </NavLink>
                                            <div>
                                                <div><p className='BizPage_text_center'>{data.content}</p></div>
                                                <p className='Bizpagetabsfortextcenter'>{data.content1}</p>
                                                <p className='m-2'><Rating name="read-only" value={3} readOnly /></p>
                                                <div className="d-flex justify-content-center">
                                                    <AvatarGroupComponent />
                                                    <p className='People_Sociomate text-muted align-self-center'>524 Followers</p>
                                                </div>
                                                <div className='d-flex justify-content-center m-2'>
                                                    <p>{data.button}</p>
                                                    <p className='ml-2'>{data.button1}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }

                    </Slider>
                </div>

            </Card>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Group</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            <BlankTab />
            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>BizPage
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h4>Withim (Km)</h4>
                            <h4>8821 Km</h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className='w-100'>
                                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                    <MatSlider
                                        aria-label="Volume"
                                        defaultValue={100}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                            '& .MuiSlider-track': {
                                                border: 'none',
                                            },
                                            '& .MuiSlider-thumb': {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#81C14B',
                                                '&:before': {
                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                    boxShadow: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div className='d-flex align-items-center ml-3' role='button'>
                                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                            </div>
                        </div>
                        <div className='border-top mt-2'>
                            <h4 className='mt-3'>Choose locations</h4>
                            <ChipBoxLocation />
                        </div>

                        <h4 className='mt-3'>Choose Category</h4>
                        <ChipBox />
                        <div className='mt-4'>
                            <h4>Choose Rating</h4>
                            <Rating name="read-only" className='RatingThisWidth' value={0} />
                        </div>
                        {/* <div className=''>
                            <h4 className='mt-3'>Choose Interest</h4>
                            <ChipBox />
                        </div> */}
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default BizPage