import React, { useState } from 'react'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card
} from 'reactstrap'
import AdanalyticsCard from '../AdmanagerTables/AdanalyticsCard'
import ArchievedTable from '../AdmanagerTables/ArchievedTable'
import CompletedTable from '../AdmanagerTables/CompletedTable'
import DraftedTable from '../AdmanagerTables/DraftedTable'
import PausedTable from '../AdmanagerTables/PausedTable'
import RunningTable from '../AdmanagerTables/RunningTable'
import ApprovalTable from '../AdmanagerTables/ApprovalTable'
import ScheduleTable from '../AdmanagerTables/ScheduleTable'

const AdmanagerList = () => {
    const [active, setActive] = useState('1')
    const toggle = tab => {
        setActive(tab)
    }
    return (
        <React.Fragment>
            {/*--------------------- Top Ad's Analytical Card Component come from AdManagerTables --------------------- */}
            <AdanalyticsCard active={active} toggle={toggle} currentbutton={'/Dashboard/TransactionSummary'} buttonrole={'View Transaction'} />
            {/*--------------------- Navigation tab  Component --------------------- */}
            <Nav tabs className='nav-item' >
                <NavItem className='ml-4'>
                    <NavLink
                        className={active === '1' ? 'text-primary' : 'text-dark'}
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Running (27)
                    </NavLink>
                </NavItem>
                <NavItem className='ml-4 navtext-item'>
                    <NavLink
                        className={active === '7' ? 'text-primary' : 'text-dark'}
                        active={active === '7'}
                        onClick={() => toggle('7')}
                    >
                        Schedule (25)
                    </NavLink>
                </NavItem>
                <NavItem className='navtext-item' >
                    <NavLink
                        className={active === '2' ? 'text-primary' : 'text-dark'}
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Paused (05)
                    </NavLink>
                </NavItem>
                <NavItem className='navtext-item'>
                    <NavLink
                        className={active === '3' ? 'text-primary' : 'text-dark'}
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Drafted (08)
                    </NavLink>
                </NavItem >
                <NavItem className='navtext-item'>
                    <NavLink
                        className={active === '4' ? 'text-primary' : 'text-dark'}
                        active={active === '4'}
                        onClick={() => {
                            toggle('4')
                        }}
                    >
                        Approval Pending (13)
                    </NavLink>
                </NavItem>
                <NavItem className='navtext-item'>
                    <NavLink
                        className={active === '5' ? 'text-primary' : 'text-dark'}
                        active={active === '5'}
                        onClick={() => {
                            toggle('5')
                        }}
                    >
                        Completed (46)
                    </NavLink>
                </NavItem>
                <NavItem className='navtext-item'>
                    <NavLink
                        className={active === '6' ? 'text-primary' : 'text-dark'}
                        active={active === '6'}
                        onClick={() => {
                            toggle('6')
                        }}
                    >
                        Archieved (46)
                    </NavLink>
                </NavItem>
            </Nav>
            {/*--------------------- Respected pages or Table come from AdmanagerTables --------------------- */}

            <Card className='rounded-0 ' style={{ marginBottom: '0px', boxShadow: 'none' }}>
                <TabContent className='py-50' activeTab={active}>
                    <TabPane tabId='1'>
                        <RunningTable />
                    </TabPane>
                    <TabPane tabId='2'>
                        <PausedTable />
                    </TabPane>
                    <TabPane tabId='3'>
                        <DraftedTable />
                    </TabPane>
                    <TabPane tabId='4'>
                        <ApprovalTable />
                    </TabPane>
                    <TabPane tabId='5'>
                        <CompletedTable />
                    </TabPane>
                    <TabPane tabId='6'>
                        <ArchievedTable />
                    </TabPane>
                    <TabPane tabId='7'>
                       <ScheduleTable/>
                    </TabPane>
                </TabContent>
            </Card>

        </React.Fragment>
    )
}
export default AdmanagerList