
import React from 'react'
import { ChevronRight, EyeOff, MoreVertical, Send, User } from 'react-feather';
import Slider from 'react-slick';
import { Card, CardBody } from 'reactstrap'
import './style.css';

const Birthday = () => {
    const BirthDaySliderSetting = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    return (
        <div className='p-0 m-0'>

            {/* 2 different slider card */}
            <Slider {...BirthDaySliderSetting} className="default-space BirthdaySliderCustomizes">
                <div className="birthday-section section-b-space groupBottom-custom-block">
                    <div className="card-title theBirthdayCardTitle w-100">
                        <div style={{ flex: "1" }} className='text-white' >
                            <h3 className='text-white' style={{ fontWeight: "500" }}>Happy Birthday</h3>
                            <h6 className='text-wrap'>today your collge friend's birthday</h6>
                        </div>
                        <div className="settings SeeAllEventButton d-flex align-items-start justify-content-end" style={{ flex: "0.6" }}>
                            <h5 style={{ fontSize: "12px", fontWeight: "500" }}>See All</h5>
                        </div>
                    </div>

                    <div className="birthday-content">
                        <div className="image-section">
                            <div className="icon">
                                <img src="/assets/images/cake.png" className="img-fluid"
                                    alt="cake" />
                            </div>
                            <div className="center-profile">
                                <img src="/assets/images/story-3.jpg"
                                    className="img-fluid bg-img" alt="user" />
                            </div>
                            <div className="icon">
                                <h5>20+</h5>
                            </div>
                        </div>
                        <div className="details">
                            <h3>sufiya elija</h3>
                            <h6>glasgow, scotland</h6>
                            <p>Lorem 5th Sept 2019 dummy text of the printing and typesetting industry.</p>
                            <form>
                                <input type="text" placeholder="wish birthday to your friend"
                                    className="form-control" />
                                <button type="submit">
                                    <Send color='#fff' size={15} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="birthday-section section-b-space">
                    <div className="birthday-top">
                        <div className="title">
                            <h3>birthday !!!!</h3>
                            <h6>today your collge friend's birthday</h6>
                        </div>
                        <div className="setting">
                            <div className="setting-btn light-btn ms-2 setting-dropdown">
                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                    <a className="d-flex" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <MoreVertical color='#fff' size={12} />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                        <ul>
                                            <li>
                                                <a href="#"><EyeOff size={14} />Hide</a>
                                            </li>
                                            <li>
                                                <a href=""><User size={14} />view profile</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="birthday-content">
                        <div className="image-section">
                            <div className="icon">
                                <img src="/assets/images/cake.png" className="img-fluid"
                                    alt="cake" />
                            </div>
                            <div className="center-profile">
                                <img src="/assets/images/story-3.jpg"
                                    className="img-fluid bg-img" alt="user" />
                            </div>
                            <div className="icon">
                                <h5>20+</h5>
                            </div>
                        </div>
                        <div className="details">
                            <h3>Tina Hammer</h3>
                            <h6>London, England</h6>
                            <p>Lorem 5th Sept 2019 dummy text of the printing and typesetting industry.</p>
                            <form>
                                <input type="text" placeholder="wish birthday to your friend"
                                    className="form-control" />
                                <button type="submit">
                                    <ChevronRight color='#fff' size={15} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </Slider>
            {/* </CardBody> */}
        </div>
    )
}

export default Birthday