import React, { useEffect, useState } from 'react'
import { ChevronsRight, Maximize2, Minimize2, MoreVertical, Send } from 'react-feather';
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import UserAdPro from '../../../../../NewComponents/IMG/user2.png'

const CarouselImage = ({ heading, subHeading, imageDataUrl, discriptions, imageGroupUrl, imageStoryUrl, imageBizUrl }) => {
    const [accord, setAccord] = useState(1);
    const [centeredModal, setCenteredModal] = useState(false)
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        console.log("========>preview", imageDataUrl)
    }, [imageDataUrl, imageGroupUrl, imageStoryUrl, imageBizUrl])
    const Previewmodal = () => {
        return (
            <>
                <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-xl modal-dialog-centered'>
                    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Advanced preview</ModalHeader>
                    <ModalBody>
                        <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                            {/* Feed Card */}
                                            {/*  onClick={() => { setAccord(1) }} */}
                                            <Col xs='4' xl='4'>
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="post-details">
                                                            <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='w-100' style={{width: '100%', height: '200px', objectFit: 'cover'  }} />
                                                                <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-9px' }}>
                                                                    <h4 style={{
                                                                        fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}>{heading}</h4>
                                                                    {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                </div>
                                                            </div>
                                                            <div className="detail-box">
                                                                <h4 className="small-preview-heading" style={{
                                                                    fontSize: '10px',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}>
                                                                    {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                                </h4>
                                                            </div>
                                                            <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=====================Biz Page Card=============== */}
                                            {/* onClick={() => { setAccord(4) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all BizPages <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*================== Group Card=================*/}
                                            {/* onClick={() => { setAccord(3) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded  py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1" style={{ fontSize: '12px' }}><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow w-100">
                                                                    {/* <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div> */}
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow w-100">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt='' className='rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }}  />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <div className="detail-box">
                                                                                <h4 className="small-preview-heading" style={{
                                                                                    fontSize: '10px',
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}>
                                                                                    {discriptions && discriptions.length > 18 ? discriptions.substring(0, 18) + '...' : discriptions}
                                                                                </h4>
                                                                            </div>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '10px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all Groups <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert mt-4'>
                                            {/*======================Stories Card================== */}
                                            {/*==================Shotz=================== */}
                                            {/* onClick={() => { setAccord(8) }} */}
                                            <Col xs='4' xl='4' >
                                                <h3 className='p-heading ml-1'>Story</h3>
                                                <div className=" m-1">
                                                    <Card inverse className=''>
                                                        {/* <CardImg alt="" src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} className='w-100' style={{ height: '350px' }} /> */}
                                                        <div className='small-bg-mains'>
                                                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                                                <div className="post-wrapper col-grid-box">
                                                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                                        <div className="profile">
                                                                            <div className="media d-flex mb-1" >
                                                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                                                    <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                                </a>
                                                                                <div className="media-body ml-2">
                                                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>florida roxin</h6>
                                                                                    <h6><span className=" sponsored-text" style={{ color: 'black' }}>Sponsored</span></h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                                                <MoreVertical size={15} style={{ color: 'black' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post-details">
                                                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                                            <img
                                                                                src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`}
                                                                                alt=""
                                                                                style={{width: '100%', height: '44.2vh', objectFit: 'cover'  }}
                                                                            />
                                                                        </div>
                                                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                                            <h4 style={{
                                                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                            }}>{heading}</h4>

                                                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                top: '26px',
                                                                                left: '1px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                maxWidth: '100%',
                                                                            }}>
                                                                                <p style={{
                                                                                    fontSize: '10px',
                                                                                    marginLeft: '3px',
                                                                                    color: 'white',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                }}>{subHeading}</p></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
    return (
        <>
            <div className="col-lg-12">
                <Previewmodal />
                <Row className='rowForrevert'>
                    <Col xl='6'>
                        <div className="">
                            <img src="/assets/images/adIcon/preview-logo.png" alt="logo" />
                        </div>
                    </Col>
                    <Col xl='6'>
                        <div className='d-flex justify-content-end'>
                            <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                                {centeredModal ? <Minimize2 size={15} /> : <Maximize2 size={15} />}
                                <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview'}</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/* =======================carousel feeds=============== */}
                <div className="row">
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(1) }}>
                        <h3 className='p-heading'>Feeds</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt=''  style={{width: '100%', height: '200px', objectFit: 'cover'  }}  />
                                            <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute', bottom: '-9px' }}>
                                                <h4 style={{
                                                    fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>{heading}</h4>

                                                {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                {/* <div style={{position:'absolute',left:'6px',top:'24px' }}>
                                                    <p>{subHeading}</p></div> */}
                                            </div>
                                        </div>
                                        <div className="detail-box">
                                            <h4 className="small-preview-heading" style={{
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                                {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                            </h4>
                                        </div>
                                        <div className="Why-this-ad d-flex justify-content-between m-1">
                                            <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                            <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <img src={s1} alt="" /> */}
                    </div>
                    {/* =======================end carousel feeds=============== */}
                    {/* ====== carousel new card ===== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Groups</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new'>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Suggested Groups</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100">
                                                <div className="">
                                                    {/* small-preview-img*/}
                                                    {/* <img src={group2} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className='w-100 rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading">This award goes...{subHeading}</h4> */}
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="small-preview-more mt-1">
                                            <h6>visit all groups<ChevronsRight size={15} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ======end carousel new card ===== */}
                    {/* =========Story========= */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Story</h3>
                        <div className='small-bg-mains'>
                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                    <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <img
                                                src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`}
                                                alt=""
                                                style={{width: '100%', height: '44.2vh', objectFit: 'cover'  }}
                                            />
                                        </div>
                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                            <h4 style={{
                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{heading}</h4>

                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '26px',
                                                left: '1px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '100%',
                                            }}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    marginLeft: '3px',
                                                    color: 'white',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>{subHeading}</p></div>
                                        </div>
                                    </div>
                                    {/* <div className="detail-box">
                                        <h4 className="small-preview-heading" style={{
                                            fontSize: '10px',
                                            whiteSpace: 'nowrap',
                                            color:'white',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}>
                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                        </h4>
                                    </div> */}
                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =========end Story========= */}
                    {/* =====================bizpage=============== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>BizPages</h3>
                        <div className='small-bg-main'>
                            <div className='post-panel-new' >
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap' }}>Food Market Zone</h6>
                                                    <h6><span className="color-grey sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                    <MoreVertical size={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 preview-img-view ml-2 ">
                                        <h6 className='small-p-heading'>Suggested BizPages</h6>
                                    </div>
                                    <div className="post-details col-lg-12  rounded-2">
                                        <div className="row">
                                            <div className="recomandation-display-block  p-2 shadow h-100">
                                                <div className="">
                                                    {/* <img src={group5} alt='' /> */}
                                                    <img src={imageGroupUrl ? imageGroupUrl : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfj5wEpsZRRVfjYE-SGz4g91UQg46J9_XKt3zgDDxO2A&s`} alt="" className='w-100 rounded-2' style={{width: '100%', height: '160px', objectFit: 'cover'  }} />
                                                </div>
                                                <div>
                                                    <div className="detail-box">
                                                        {/* <h4 className="small-preview-heading">This award BizPages...</h4> */}
                                                        <h4 className="small-preview-heading" style={{
                                                            fontSize: '10px',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {discriptions && discriptions.length > 25 ? discriptions.substring(0, 25) + '...' : discriptions}
                                                        </h4>
                                                    </div>
                                                    <div className="preview-detail-small">
                                                        <p className='small-preview-small' style={{ fontSize: '10px' }}>Sponsored</p>&nbsp;
                                                        <p className='small-preview-small preview-know' style={{
                                                            fontSize: '10px', whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>Know more</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="small-preview-more mt-1">
                                            <h6>visit all BizPages<ChevronsRight size={15} /></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarouselImage