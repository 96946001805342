import React from 'react';
import './App.css';
import Root from './Root';

function App() {
  return (
    <Root />
  );
}

export default App;
