import React from 'react'
import { Music, Play } from 'react-feather'
import { Col, Row } from 'reactstrap'
import { Stack, useTheme, Slider } from '@mui/material';
import AudioShotzs from './AudioShotzs';

const ShotzAudio = () => {
    const theme = useTheme();
    return (
        <>
            <div className='roundedThisBorder mt-4 p-2'>
                <Row>
                    <Col className='p-1'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex'>
                                <div>
                                    <img src='https://static.toiimg.com/photo/90999017.cms' height={150} width={200} className='m-3 img-gradient rounded img-fluid' alt='audioimg' />
                                    <Music size={35} color='white' className='img-text-centered' />
                                </div>
                                <div className='mt-3'>
                                    <h2 className=''><b>A.i. vs Jobs</b></h2>
                                    <p className='text-secondary mt-2'>Hello ☕️ I'm Positive John :) My mission here is to help your brain</p>
                                    <p className='mt-2 text-secondary'>Audio used in <b>50M</b> Shotzs</p>
                                    <div className='w-100 mt-2'>
                                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                            <Play size={30} color="#81c14b" fill='#81c14b' />
                                            <Slider
                                                aria-label="Volume"
                                                defaultValue={60}
                                                sx={{
                                                    color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                    '& .MuiSlider-track': {
                                                        border: 'none',
                                                    },
                                                    '& .MuiSlider-thumb': {
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor: '#81C14B',
                                                        '&:before': {
                                                            boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                        },
                                                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                            boxShadow: 'none',
                                                        },
                                                    },
                                                }}
                                            />
                                            <p className='ml-4'> 0:30</p>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* ------- Audio Shotz Call ------- */}
                <AudioShotzs />
            </div>
        </>
    )
}
export default ShotzAudio 