import React, { useEffect, useState, useMemo } from "react";
import {
  CheckCircle,
  Copy,
  Flag,
  Grid,
  Search,
  Info,
  List,
  MoreVertical,
  XCircle,
  MinusCircle,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  Card,
  CardBody,
  UncontrolledTooltip,
  Input,
  CustomInput,
} from "reactstrap";
import { getUserFollowingRequests } from "../../../Services/Actions/Connection/getUserFollowingRequest";
import { getUserApproveFollowRequest } from "../../../Services/Actions/Connection/getUserApproveDisapprove";
import { getUserDispproveFollowRequest } from "../../../Services/Actions/Connection/getUserApproveDisapprove";
import {} from "../../../Services/Actions/Connection/getUserReportAction";
import Header from "../../Header";
import LeftSidebar from "../../LeftSidebar";
import RightSidebar from "../../RightSidebar";
import ConnectionPlaceMenu from "./ConnectionPlaceMenu";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { FollowReqestTab } from "./FollowReqestTab";
import { Button, InputGroup } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import decline from "../../../NewComponents/IMG/Frame 2551.svg";
import accept from "../../../NewComponents/IMG/Frame 2560.svg";
import { PendingRequest } from "../ListView/PendingRequest";
import { MutualFriendList } from "../HoverComponents/MutualFriendList";
import BlockModal from "../ConnectionModal/BlockModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import { RejectModal, SingleRejectModal } from "../ConnectionModal/RejectModal";
import { AcceptModal, SingleAcceptModal } from "../ConnectionModal/AcceptModal";
import PublicAccount from "../ConnectionModal/PublicAccount";
import PrivateAccount from "../ConnectionModal/PrivateAccount";
import CommanHeader from "../CommanHeader";
import "../Style.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";

const FollowRequestsScreen = () => {

  const dispatch = useDispatch();
  //search opration
  const [searchItem, setSearchItem] = useState("");
  const [userId,setUserId] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [Gridview, Setgridview] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const { userFollowingRequests } = useSelector(
    (state) => state.userFollowingRequestsData
  );

 // Apply a 500ms debounce on search input changes
 useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedSearch(searchItem);
  }, 300);

  return () => {
    clearTimeout(handler);
  };
}, [searchItem]);

  // Filter the data based on type people.
  const peopleRequests = userFollowingRequests?.data?.rows?.filter((request) => request.Type === 'People') || [];

  // Filter the data based on debounced search term
  const filteredData = useMemo(() => {
    return peopleRequests.filter((item) =>{
      const fullNameMatch = item.fullName?.toLowerCase().includes(debouncedSearch.toLowerCase());
      const userNameMatch = item.userName?.toLowerCase().includes(debouncedSearch.toLowerCase());
      return fullNameMatch || userNameMatch;
    });
  }, [userFollowingRequests, debouncedSearch]);

  //useEffect(() => {
  //  const customEvent = new CustomEvent("userRequestsCounts", {
  //    detail: {
  //      type: "followCount",
  //      count: userFollowingRequests.data?.rows?.length,
  //    },
  //  });
  //  document.dispatchEvent(customEvent);
  //}, [userFollowingRequests]);

  // Helper function to calculate and return the time difference
  const getTimeDifference = (createdAt) => {
  const createdDate = new Date(createdAt);
  const now = new Date();
  
  const differenceInMilliseconds = now - createdDate;
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInSeconds < 60) {
      return `${differenceInSeconds === 1 ? '1 second ago' : `${differenceInSeconds} seconds ago`}`;
    } else if (differenceInMinutes < 60) {
      return `${differenceInMinutes === 1 ? '1 minute ago' : `${differenceInMinutes} minutes ago`}`;
    } else if (differenceInHours < 24) {
      return `${differenceInHours === 1 ? '1 hour ago' : `${differenceInHours} hours ago`}`;
    } else if (differenceInDays === 1) {
      return `1 day ago`;
    } else {
      return `${differenceInDays} days ago`;
    }
  }; 

  // Pagination logic
  const itemsPerPage = 15; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = useMemo(() => {
    return Math.ceil((filteredData?.length || 0) / itemsPerPage);
  }, [filteredData]);

  // Handle page change
  const handlePageChange = (event, newPage) => {
      setCurrentPage(newPage);
  };

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(getUserFollowingRequests());
  }, [dispatch]);

  const [active, setActive] = useState("1");

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };
  // ------------- State management for selecting Single card  -------------
  const [CheckOne, setCheckOne] = useState(false);

  // ------------- State management for selecting Multiple card  -------------
  const [Check, setCheck] = useState(false);

  // ------------- Selecting all cards(State toggle)  -------------
  //const SelectAll = () => {
  //  if (CheckOne === true) {
  //    setCheckOne(false);
  //  }
  //  if (Check === false) {
  //    setCheck(!Check);
  //  }
  //};

  // ------------- UnSelecting all cards(State toggle)  -------------
  //const unSelectAll = () => {
  //  if (Check === true) {
  //    setCheck(!Check);
  //  } else if (CheckOne === true) {
  //    setCheckOne(!CheckOne);
  //  }
  //  setIsSelectActive(!isSelectActive);
  //};

  // store the id
  //const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  //const toggleAcceptModalOpen = ( request) => {
  //  setAcceptModalOpen((prevState) => !prevState);
  //  selectedRequest(request)
  //};

  // -------------Reject Modal State  -------------
  //const [rejectModalOpen, setRejectModalOpen] = useState(false);
  //const toggleRejectModalOpen = (request) => {
  //   setRejectModalOpen((prevState) => !prevState);
  //   selectedRequest(request) 
  //};

  // -------------Single Accept Modal State  -------------
  const [requestData, setRequestData] = useState({ id: "", Type: "" });
  const [singAcceptModalOpen, setSingAcceptModalOpen] = useState(false);
  const toggleSingAcceptModalOpen = () => {
    setSingAcceptModalOpen((prevState) => !prevState);
  };
  const[singleAcceptConfirm,setSingleAcceptConfirm] = useState(false)
    useEffect(()=>{
        if(singleAcceptConfirm){
            setTimeout(() => {
              dispatch(getUserFollowingRequests());
              setSingleAcceptConfirm(false);
            }, 1000);
         }
    },[singleAcceptConfirm])

  // -------------Single Reject Modal State  -------------
  const [singRejectModalOpen, setSingRejectModalOpen] = useState(false);
  const toggleSingRejectModalOpen = () => {
    setSingRejectModalOpen((prevState) => !prevState);
  };
  const[singleRejectConfirm,setSingleRejectConfirm] = useState(false)
  useEffect(()=>{
      if(singleRejectConfirm){
          setTimeout(() => {
            dispatch(getUserFollowingRequests());
            setSingleRejectConfirm(false);
          }, 1000);
       }
  },[singleRejectConfirm])

  // ------------- share Modal State -------------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };

  //  ------------- Start ReportedOption  State -------------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = () => {
    setReportOpen((preState) => !preState);
    //setSelectedRequest(request)
  };
  const [reportRequest, setReportRequest] = useState({ id: "", Type: "" });
  const[reportConfirm,setReportConfirm] = useState(false)
      useEffect(()=>{
        if(reportConfirm){
              setTimeout(() => {
                dispatch(getUserFollowingRequests());
                setReportConfirm(false);
              }, 1000);
          } 
      },[reportConfirm])


  //const [isSelectActive, setIsSelectActive] = useState("");
  //const [selectedShotz, setSelectedShotz] = useState([]);

  //copy user profile
  //const handleCopy= (request)=>{
  //}

  //get user block
  const [blockModalOpen, setBlockModalOpen] = useState(false)
  const toggleBlockModalOpen = () => {
    setBlockModalOpen((prevState) => !prevState);
  }
  const[blockConfirm,setBlockConfirm] = useState(false)
  useEffect(()=>{
    if(blockConfirm){
      setTimeout(() => {
        dispatch(getUserFollowingRequests());
        setBlockConfirm(false);
      }, 1000);
    } 
  },[blockConfirm])

  //const GridViewSection =  React.memo(() => {
  //  return (
  //    <>
  //      <div className="nav-flex-container">
  //        <div>
  //          {!Check ? (
  //            <h3 className="connection-sub-heading">
  //              Requests ({userFollowingRequests.data?.rows?.length})
  //            </h3>
  //          ) : (
  //            <h3>20 Selected</h3>
  //          )}
  //        </div>
  //        <div className="sub-nav-flex size-sm-mt-4 mb-2">
  //          {/*<div className="profile-search-blk mr-2">*/}
  //            {/*<svg
  //              viewBox="0 0 24 24"
  //              width="24"
  //              height="24"
  //              stroke="currentColor"
  //              strokeWidth="2"
  //              fill="none"
  //              strokeLinecap="round"
  //              strokeLinejoin="round"
  //              className="search-svg"
  //            >
  //              <circle cx="11" cy="11" r="8" />
  //              <line x1="21" y1="21" x2="16.65" y2="16.65" />
  //            </svg>
  //            <input
  //              type="text"
  //              className="form-control"
  //              name="search"
  //              placeholder="Find ..."
  //              data-bs-toggle="modal"
  //              data-bs-target="#peopleSearch"
  //              value={searchItem} 
  //              onChange={(e) => {
  //                console.log("Value:", e.target.value);
  //                setSearchItem(e.target.value); 
  //              }}
  //            />*/}
  //              {/*<InputGroup className="input-group-merge d-flex connection-search-style mr-2">
  //                      <Search color="#666666" size={16} />
  //                      <Input
  //                        type="search"
  //                        placeholder="Find..."
  //                        className="coonection-input-style"
  //                        value={searchItem}
  //                        onChange={(e) => {
  //                          console.log("Value:", e.target.value);
  //                          setSearchItem(e.target.value);
  //                        }}
  //                      />
  //            </InputGroup>*/}
  //          {/*</div>*/}

  //          {/*{!isSelectActive ? (
  //            <div className="sub-nav-flex remove-btn-padding">
  //              <Button
  //                className="mr-2"
  //                onClick={() => {
  //                  setIsSelectActive(!isSelectActive);
  //                  setCheckOne(true);
  //                }}
  //                variant="outline-primary"
  //              >
  //                Select
  //              </Button>
  //            </div>
  //          ) : (
  //            <div className="sub-nav-flex">
  //              <Button
  //                variant="outline-primary"
  //                className="mr-2"
  //                onClick={Check ? unSelectAll : SelectAll}
  //              >
  //                {Check ? "Unselect All" : "Select All"}
  //              </Button>
  //              <Button
  //                variant="outline-primary"
  //                onClick={() => {
  //                  setIsSelectActive(!isSelectActive);
  //                  setSelectedShotz([]);
  //                  setCheckOne(false);
  //                  setCheck(false);
  //                }}
  //                className="mr-2"
  //              >
  //                Cancel
  //              </Button>
  //            </div>
  //          )}*/}
  //          <div className="list-grid-toggle size-xs-mt-4 rem-mob-view">
  //            <Button
  //              className={Gridview ? "grid-toggle-customize-css " : ""}
  //              outline
  //              onClick={() => Setgridview(false)}
  //            >
  //              <Grid size={16} />
  //            </Button>
  //            <Button
  //              className={Gridview ? " " : "grid-toggle-customize-css"}
  //              outline
  //              onClick={() => Setgridview(true)}
  //            >
  //              <List size={16} />
  //            </Button>
  //          </div>
  //        </div>
  //      </div>
  //    </>
  //  );
  //});

  return (
    <>
      {/* <FollowRequestHeader /> */}
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <Card>
            {/* comman header calling */}
            <CommanHeader />

            <CardBody>
              {/* connection tabs calling */}
              <ConnectionPlaceMenu />
              <div className="mppage-heading mt-4">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      active={active === "1"}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      <h3>Received</h3>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={active === "2"}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <h3>Sent</h3>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="py-50 mt-4" activeTab={active}>
                  <TabPane tabId="1">
                    <div className="group-custom-block">
                      {/*<GridViewSection />*/}
                      {/*<div className="heading-podcast-blk">*/}
                        {/*{Check ? (
                          <>
                            <div className="d-flex align-items-center  justify-content-between">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  toggleAcceptModalOpen(
                                    userFollowingRequests?.data?.rows?.userId
                                  );
                                }}
                              >
                                Accept All
                              </Button>
                              <Button
                                variant="danger"
                                className="ml-2"
                                color="danger"
                                onClick={() => {
                                  toggleRejectModalOpen(
                                    userFollowingRequests?.data?.rows?.userId
                                  );
                                }}
                              >
                                {" "}
                                Reject All
                              </Button>
                            </div>
                          </>
                        ) : null}*/}
                        {/*{CheckOne ? (
                          <>
                            <div className="d-flex align-items-center  justify-content-between">*/}
                              {/*<Button
                                variant="primary"
                                onClick={toggleAcceptModalOpen}
                              >
                                Accept Selected
                              </Button>*/}
                              {/* Accept selected button functionality */}
                              {/*<Button
                                variant="danger"
                                className="ml-2"
                                color="danger"
                                onClick={toggleRejectModalOpen}
                              >
                                {" "}
                                Reject Selected
                              </Button>*/}
                              {/* Reject selected button functionality */}
                            {/*</div>
                          </>
                        ) : null}*/}
                      {/*</div>*/}

                      <div className="nav-flex-container">
                        <div>
                          <h3 className="connection-sub-heading">
                            Requests ({filteredData?.length})
                          </h3>
                        </div>
                        <div className="sub-nav-flex size-sm-mt-4 mb-2">
                          <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                            <Search color="#666666" size={16} />
                            <Input
                              type="search"
                              placeholder="Find..."
                              className="coonection-input-style"
                              value={searchItem}
                              onChange={(e) => setSearchItem(e.target.value)}
                            />
                          </InputGroup>
                          <div className="list-grid-toggle size-xs-mt-4 rem-mob-view">
                              <Button
                                className={Gridview ? "grid-toggle-customize-css " : ""}
                                outline
                                onClick={() => Setgridview(false)}
                              >
                                <Grid size={16} />
                              </Button>
                              <Button
                                className={Gridview ? " " : "grid-toggle-customize-css"}
                                outline
                                onClick={() => Setgridview(true)}
                              >
                                <List size={16} />
                              </Button>
                          </div>
                        </div>
                      </div>
                      {Gridview ? (
                        <PendingRequest CheckOne={CheckOne} Check={Check} getTimeDifference={getTimeDifference} requestData={filteredData.slice(startIndex, endIndex)} />
                      ) : (
                        <Row className="connections_PeopleThisGrid">
                          {filteredData &&
                                filteredData?.slice(startIndex, endIndex).map((request, index) => {
                                return (
                                  <Col lg="4" className="w-100 mt-2">
                                    <div
                                      key={index}
                                      className={
                                        Check || (CheckOne && index === 0)
                                          ? "box-selected content-mp-block d-flex align-items-center justify-content-between"
                                          : "box-shadow-style content-mp-block d-flex align-items-center justify-content-between"
                                      }
                                    >
                                      <div className="media-body d-md-block">
                                        <div class="media media-new d-flex align-items-start">
                                          <div class="user-img d-flex align-items-start popover___wrapper option-disable">
                                            <img
                                              src={request?.profileImage}
                                              className="rounded-circle "
                                              height={45}
                                              width={45}
                                              alt="shivam singh"
                                            />
                                            <span class="available-stats online"></span>
                                            {request?.isPrivate ? <PrivateAccount request={request}/> : <PublicAccount request={request}/>}
                                          </div>
                                          <div class="media-body d-md-block pl-1">
                                            <Link to="/MyProfile">
                                              <h4 className="m-0 name-style d-inline-block text-truncate text-wrap">
                                                {request?.fullName?.substring(0,21) || "NA"}
                                              </h4>
                                            </Link>
                                              <div className="d-flex ml-1">
                                                <p className="user-name-style">
                                                  {request?.userName || "NA"} |
                                                </p>
                                                <span>
                                                  <img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} />
                                                </span>
                                              </div>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between ml-2 mt-1 option-disable">
                                        <MutualFriendList mutualfriendList={request?.mutualfriendList} />
                                        </div>
                                      </div>
                                      <div className=" d-flex flex-column justify-content-end align-items-end">
                                        {CheckOne || Check ? (
                                          <Checkbox
                                            checked={
                                              Check || (CheckOne && index === 0)
                                                ? true
                                                : null
                                            }
                                          />
                                        ) : (
                                          <div className="d-flex align-items-center option-disable">
                                            <span
                                              role="button"
                                              className="conn-btn"
                                              onClick={() => {
                                                toggleSingAcceptModalOpen();
                                                setRequestData({ id: request.userId, Type: request.Type });
                                              }}
                                            >
                                              <img
                                                src={accept}
                                                className="img-fluid"
                                                alt=""
                                                id="accept-sign"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="accept-sign"
                                              >
                                                Accept
                                              </UncontrolledTooltip>
                                            </span>

                                            <span
                                              role="button"
                                              className="conn-btn mx-2"
                                              onClick={() => {
                                                toggleSingRejectModalOpen();
                                                setRequestData({ id: request.userId, Type: request.Type });
                                              }}
                                            >
                                              <img
                                                src={decline}
                                                className="img-fluid"
                                                alt=""
                                                id="reject-sign"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="reject-sign"
                                              >
                                                Reject
                                              </UncontrolledTooltip>
                                            </span>
                                            <UncontrolledButtonDropdown
                                              className="d-flex align-items-start flex-column justify-content-end px-2 rem-mob-view-small"
                                              direction="left"
                                            >
                                              <DropdownToggle
                                                color="light"
                                                className="connection-cutomize-dropdown-toggle"
                                              >
                                                <MoreVertical size={22} />
                                              </DropdownToggle>
                                              <DropdownMenu className="Connection-dropdown">
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                    toggleSingAcceptModalOpen();
                                                    setRequestData({ id: request.userId, Type: request.Type });
                                                  }}
                                                >
                                                  <CheckCircle
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Accept
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                    toggleSingRejectModalOpen();
                                                    setRequestData({ id: request.userId, Type: request.Type });
                                                  }}
                                                >
                                                  <XCircle
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Decline
                                                </DropdownItem>
                                                <DropdownItem className="border-bottom py-2 menu-hover-style">
                                                  <Copy
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Copy Profile URL
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                      toggleBlockModalOpen();
                                                      setUserId(request.userId);
                                                  }}
                                                >
                                                  <MinusCircle
                                                    size={15}
                                                    className="mr-2 icon-color-style"
                                                  />
                                                  Block
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                    toggleReport();
                                                    setReportRequest({ id: request.userId });
                                                  }}
                                                >
                                                  <Flag
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Report User
                                                </DropdownItem>
                                                {/*<DropdownItem className="py-2 menu-hover-style">
                                                  <Info
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Learn more
                                                </DropdownItem>*/}
                                              </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                          </div>
                                        )}
                                        <div className="mt-3 d-flex align-items-end justify-content-end">
                                          <p className="align-self-end font-weight-bold text-right text-wrap rem-mob-view-small">
                                            {getTimeDifference(request.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                        </Row>
                      )}
                      <Row>
                        <Col xl="12" className="d-flex justify-content-center">
                          {filteredData.length === 0 && (
                            <h3 className="text-center connection-sub-heading">
                              No Requests Found
                            </h3>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <FollowReqestTab />
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
            <div className="d-flex justify-content-center my-3">
            {filteredData?.length > itemsPerPage && (
                    <Stack spacing={2} >
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            size="large"
                            color="primary"
                        />
                    </Stack>
                )}
            </div>
          </Card>
        </div>
        <RightSidebar />
      </div>

      { reportOpen && (
            <ConnectionReportModal
                request={reportRequest}
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
                setReportConfirm ={setReportConfirm}
            />
      )}
      {/* ------------------ Start Share Modal  ------------------*/}
      { shareOpen && (
            <ConnectionShareModal
              openModal={shareOpen}
              shareModalOpen={toggleShareOpen}
            />
      )}
      {/* -------------  Accept Modal  ------------- */}
      {/*<AcceptModal
        request={selectedRequest}
        openModal={acceptModalOpen}
        openAcceptModal={toggleAcceptModalOpen}
      />*/}

      {/* -------------  Reject modal Modal  ------------- */}
      {/*<RejectModal
        request={selectedRequest}
        openModal={rejectModalOpen}
        rejectModalOpen={toggleRejectModalOpen}
      />*/}
      {/* ------------- Single Accept Modal  ------------- */}
      { singAcceptModalOpen && (
            <SingleAcceptModal
                request={requestData}
                openModal={singAcceptModalOpen}
                openSingleAcceptModal={toggleSingAcceptModalOpen}
                setSingleAcceptConfirm={setSingleAcceptConfirm}
            /> 
      )}
      {/* ------------- Single Reject modal Modal  ------------- */}
      { singRejectModalOpen && (
            <SingleRejectModal
                request={requestData}
                openModal={singRejectModalOpen}
                singleRejectModalOpen={toggleSingRejectModalOpen}
                setSingleRejectConfirm={setSingleRejectConfirm}
            />
      )}

      {/* block  */}
      { blockModalOpen && (
          <BlockModal
            request={userId}
            openModal={blockModalOpen}
            removeModalOpen={toggleBlockModalOpen}
            setBlockConfirm={setBlockConfirm}
          />
      )}
    </>
  );
};

export default FollowRequestsScreen;
