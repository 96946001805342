import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

const MpBreadCrumb = (props) => {
    const { categoryData } = props;
    const disabledLinkStyle = {
        pointerEvents: 'none',
        textDecoration: 'none',
        color: '#81C14B' 
    };

    return (
        <Breadcrumb className="Mp_BreadCrumbs" >
            <BreadcrumbItem className="MpBreadCrumb_Items">
                <Link  className='MpbreadCrumbFontSize' to='#' style={disabledLinkStyle}> {categoryData?.categoryName} </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="MpBreadCrumb_Items">
                <Link  className='MpbreadCrumbFontSize' to='#' style={disabledLinkStyle}> {categoryData?.subCategoryName} </Link>
            </BreadcrumbItem>
            { categoryData?.subChildCategoryName && <BreadcrumbItem className="MpBreadCrumb_Items" active>
                <span className='MpbreadCrumbFontSize' >  {categoryData?.subChildCategoryName} </span>
            </BreadcrumbItem> }
        </Breadcrumb>
    )
}

export default MpBreadCrumb
