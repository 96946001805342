import React, { Fragment } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { DropDummyCardData } from '../DummyArray/ExpDummyData'
import { DropSliderFunction } from '../SubComponents/SliderScroll'
import { Heart } from 'react-feather'
import ImgSmple from '../Img/ImgSmple.svg'
import NftAavatarGroup from '../SubComponents/NftAavatarGroup'
import Slider from 'react-slick'
import NftDropProfile from './NftDropProfile'

const NftDrop = () => {

    //==================== Main_Return_Function ===================//
    return (
        <Fragment>
            <NftDropProfile />
            {/*================== start_Active_Drop_Card_Section ===================*/}
            <div className="Drop-custom-block mt-3 ">
                <div className="DropCard_heading_section py-2">
                    <h2>Active drops</h2>
                </div>
                <div className='mt-3'>
                    <Slider {...DropSliderFunction} className="default-space">
                        {
                            DropDummyCardData.map((data) => (
                                <div>
                                    <div className='ExploreNft_Cardbox_section ' >
                                        <div className='ExpoleNft_Bottom_Section'>
                                            <Button color='secondary' outline className="Ntf_Secondary_btn">
                                                Live Now
                                            </Button>
                                            <div className='d-flex align-items-center'>
                                                <Heart color='#81C14B' size={18} />
                                                <h4 className='ml-3'> {data.likeCount}</h4>
                                            </div>

                                        </div>
                                        <div className='ExploreNft_Img_smple'>
                                            <img src={ImgSmple} alt='img' />
                                        </div>
                                        <div className='ExploreNft_Heading_Blk'>
                                            <h3>{data.name}</h3>
                                        </div>
                                        <div className='ExploreNft_SubHeading_Blk'>
                                            <img src={data.icon} />&nbsp;<span>from</span>&nbsp;
                                            <h4>{data.coinType}</h4>
                                        </div>
                                        <div className='ExpoleNft_Bottom_Section'>
                                            <NftAavatarGroup />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
            {/*================== end_Active_Drop_Card_Section =====================*/}

            {/*================== start_Upcoming_Drop_Card_Section =================*/}
            <div className="Drop-custom-block mt-3 ">
                <div className="DropCard_heading_section py-2">
                    <h2>Upcoming drops</h2>
                </div>
                <div className='mt-3'>
                    <Slider {...DropSliderFunction} className="default-space">
                        {
                            DropDummyCardData.map((data) => (
                                <div>
                                    <div className='ExploreNft_Cardbox_section ' >
                                        <div className='ExpoleNft_Bottom_Section'>
                                            <Button color='secondary' outline className="Ntf_Secondary_btn">
                                                Live Now
                                            </Button>
                                            <div className='d-flex align-items-center'>
                                                <Heart color='#81C14B' size={18} />
                                                <h4 className='ml-3'> {data.likeCount}</h4>
                                            </div>

                                        </div>
                                        <div className='ExploreNft_Img_smple'>
                                            <img src={ImgSmple} alt='img' />
                                        </div>
                                        <div className='ExploreNft_Heading_Blk'>
                                            <h3>{data.name}</h3>
                                        </div>
                                        <div className='ExploreNft_SubHeading_Blk'>
                                            <img src={data.icon} />&nbsp;<span>from</span>&nbsp;
                                            <h4>{data.coinType}</h4>
                                        </div>
                                        <div className='ExpoleNft_Bottom_Section'>
                                            <NftAavatarGroup />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>

            </div>
            {/*================== end_Upcoming_Drop_Card_Section ===================*/}

        </Fragment>
    )
}

export default NftDrop