import React from 'react'
import UserImg from '../../../NewComponents/IMG/img.svg'

const RecentActivites = () => {

    /**        RecentActivity dummy data Array         * */

    const Dummydata = [
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'You have created a new post'
        },
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'Started to follow you'
        },
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'You like a post'
        },
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'Started to follow you'
        },
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'You have created a new post'
        },
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'You like a post'
        },
        {
            fullName: 'Brooklyn Simmons',
            dateAndTime: '29 Jun 2022, 08:40 PM',
            activity: 'Started to follow you'
        },
    ]

    return (
        <div>
            <div className='py-2 hide-setting-options'>
                <h3>Recent Activites</h3>
            </div>
            <div>
                {
                    Dummydata.map((item) => {
                        return <div className='d-flex align-items-start border-bottom p-3'>
                            <img src={UserImg} width={50} height={50} />
                            <div className='ml-2'>
                                <h3>{item.fullName}</h3>
                                <p>{item.activity}</p>
                                <h5 className='py-1 text-muted'>{item.dateAndTime}</h5>
                            </div>
                        </div>
                    })
                }

            </div>
        </div>
    )
}

export default RecentActivites