import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Card, Col, Row } from 'reactstrap'
import CardContent from '@mui/material/CardContent'
// import Pollimg from '../../../NewComponents/IMG/roundedimg2.svg'
// import Pollimg1 from '../../../NewComponents/IMG/roundedimg3.svg'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal';
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import ReportModal from './ModalGrid/ReportModal'
import { topTextData } from '../DummyArray'
import { NavLink } from 'react-router-dom'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'


const GridTextTab = () => {
    const [show, setShow] = useState(false)
    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    const [shareOpen, setShareOpen] = useState(false)
    // const [seemore, setSeeMore] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }


    // -------------------------------------------------------Main return function-------------------------------------------------------
    return (
        <div className='mt-1 p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />

            {/* <Row className='PeopleThisGrid'> */}
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 667: 1, 1157: 3 }}
            >
                <Masonry columnsCount={3} gutter="12px" >
                    {
                        topTextData.map((data, index) => {
                            return (
                                // <Col xl='3' className='w-100 p-1' key={index} >
                                <div className='' >
                                    <Card className='roundedThisBorder on-hover-bg-border' style={{height:'292px'}}>
                                        {/*  style={{ height: '258px' }} */}
                                        <div className='d-flex p-2'>
                                            <NavLink to='/MyProfile'>
                                                <Badge color='primary' pill className=' px-2 d-flex align-items-center light-font'>
                                                    Top #{data.topsNumber}
                                                </Badge>
                                            </NavLink>

                                            <NavLink to='/'>
                                                <Badge color='light-primary' className='px-2 ml-2 d-flex align-items-center light-font' >
                                                    Trending #{data.trendingNumber}
                                                </Badge>
                                            </NavLink>


                                        </div>
                                        <div className='d-flex justify-content-between align-items-start mx-2 mb-2'>
                                            <div className='d-flex align-items-center '>
                                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                                <div className='ml-2'>
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }} > <h4 className='user-profile'>{data.name}</h4></NavLink>
                                                    <small className='text-muted light-for-date'>{data.dateAndTime}</small>
                                                </div>
                                            </div>

                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className='p-0 m-0' color='flat' >
                                                    <MoreHorizontal size={19} color='#999999' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Text</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View creator's profile</NavLink></DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                        <div className='px-3 pb-3' >
                                            {/* textAstronomy */}
                                            <p className='text-muted user-profile mt-1 p-2 rounded-2 ' style={{ backgroundColor: "#f7f5f3", height: "118px", marginLeft: '-7px ', marginRight: '-7px',  }}>

                                                <small className='text-primary user-profile'>{data.Astronomy}</small><br />
                                              <small className='textprofile'>{data.thought}</small><small style={{ color: '#81c14b' }}>{data.more}</small></p>
                                        </div>
                                        {/* </CardContent> */}
                                        <div className='Treanding_bottom_section Treanding_bottom_sections' style={{ position: 'absolute', bottom: '0px',}}>
                                            {/*  position: 'absolute', bottom: '0px',  */}
                                            {/* style={{ height: "50px" }} */}
                                            <div className='d-flex align-items-center'>
                                                {/* {liked ?
                                    <img src={Circlelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    :
                                    <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                }
                                &nbsp;
                                11.2k */}
                                                <div onClick={() => handleLike()}>
                                                    {liked ?
                                                        <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                        :
                                                        <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                    }
                                                </div>
                                                11.2k
                                            </div>
                                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={CommentImg} width={16} hseight={16} className='' alt='comment' />
                                                </div>
                                                11.5k
                                            </div>
                                            <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                                </div>
                                                11.2k
                                            </div>

                                        </div>
                                    </Card>
                                </div>
                                // </Col>
                            )
                        })
                    }
                </Masonry>
            </ResponsiveMasonry>
            {/* </Row> */}

            {/* -------------------------------------------------------Modal start------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -------------------------------------------------------Modal end ------------------------------------------------------- */}

        </div >
    )
}

export default GridTextTab