// import { FormControlLabel, Radio } from "@mui/material";
// import React, { useState } from "react";
// import { useEffect } from "react";
// import { Info } from "react-feather";
// import { UncontrolledTooltip } from "reactstrap";
// import InputFieldWidget from "./InputFieldWidget";



// const SingleChoice = ({ remover, inputId, inputRadio, isDisplay }) => {
//   const [singleOptionCount, setSingleOptionCount] = useState([1, 2]);
//   const [singleOption, setSingleOption] = useState({ form_name: "", last_name: "" })
//   const [dataValue, setDataValue] = useState();
//   const [radioValue, setRadioValue] = useState({
//     seq1: "",
//     seq2: "",
//   });


//   const [tempSingleOption, setTempSingleOption] = useState({
//     seq1: "",
//     seq2: "",
//   });
//   const singleDataHandler = (e) => {
//     const { name, value } = e.target;
//     setTempSingleOption({ ...tempSingleOption, [name]: value });
//     // ==============
//     // =======

//   };
//   const singleTextDataHandler = (e) => {
//     const { name, value } = e.target;
//     setSingleOptionCount({ ...singleOptionCount, [name]: value });
//     const eventName = "input_lf_" + inputId;
//     console.log("cint" + eventName);
//     const customEvent = new CustomEvent(eventName, {
//       detail: { value },
//     });
//     document.dispatchEvent(customEvent);
//   };
//   const singleHandler = (e) => {
//     const { name, value } = e.target;
//     setSingleOption({ ...singleOption, [name]: value });
//     const eventName = "input_lf_" + inputId;
//     console.log("cint" + eventName);
//     const customEvent = new CustomEvent(eventName, {
//       detail: { value },
//     });
//     document.dispatchEvent(customEvent);
//   };
//   useEffect(() => {
//     const handleCustomEvent = (event) => {
//       const { value } = event.detail;
//       console.log(`Received Message: ${value}`);
//       setDataValue(value);
//     };
//     const eventName = "input_lf_" + inputId;
//     console.log(eventName);
//     document.addEventListener(eventName, handleCustomEvent);

//     return () => {
//       document.removeEventListener(eventName, handleCustomEvent);
//     };

//   }, []);

//   const singleRadioDataHandler = (e, i) => {
//     const { name, value } = e.target;
//     setTempSingleOption({ ...tempSingleOption, [name]: value });
//     const eventName = "input_lf_" + i;
//     console.log("cint" + eventName);
//     const customEvent = new CustomEvent(eventName, {
//       detail: { value },
//     });
//     document.dispatchEvent(customEvent);
//   };
//   useEffect(() => {
//     const handleCustomEvent = (event) => {
//       const { value } = event.detail;
//       console.log(`Received Message: ${value}`);
//       setRadioValue(value);
//     };
//     const eventName = "input_lf_" + inputRadio;
//     console.log(eventName);
//     document.addEventListener(eventName, handleCustomEvent);

//     return () => {
//       document.removeEventListener(eventName, handleCustomEvent);
//     };

//   }, []);

//   const optionIncrementHandler = (e, i) => {
//     e.preventDefault();
//     if (singleOptionCount.length < 10) {
//       const nextData = `seq${singleOptionCount.length + 1}`;
//       setSingleOptionCount([
//         ...singleOptionCount,
//         singleOptionCount.length + 1,
//       ]);
//       setTempSingleOption({ ...tempSingleOption, [nextData]: "" });
//       const customEvent = new CustomEvent("addnewmultiplechoice", {
//         detail: {},
//       });
//       document.dispatchEvent(customEvent);
//     }

//   };

//   const [selectedValue, setSelectedValue] = useState('female');
//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//   };

//   return (
//     <>
//       <div className="row ad-types-of-type-map shadow mt-4" style={{ margin: '2px' }}>
//         <div className="single-ad ">
//           <div className="col-lg-12 inputs d-flex p-0">
//             <div className="col-lg-12 col-12 mt-3">
//               <div className="d-flex lead-switch">

//                 {isDisplay ? <p className="p-heading">Enter Question</p> : <p className="p-heading">Where is the White House</p>}
//                 {isDisplay ? "" : <p className="start-texts">*</p>}
//                 {isDisplay && <p className="lead-greem" role="button" onClick={remover}>
//                   Remove
//                 </p>}
//               </div>
//               <div className="">
//                 <input
//                   type="text"
//                   className={isDisplay ? "form-control p-2" : "form-control p-2 hidebordertext"}
//                   placeholder="Type the ‘Hint’ here..."
//                   maxLength={"32"}
//                   value={dataValue}
//                   name="form_name"

//                   onChange={singleHandler}
//                   readOnly={!isDisplay}
//                 />
//               </div>
//               {isDisplay && <p className="p-max-car">Upto 32 Characters</p>}

//               <div className="d-flex lead-switch mb-3 mt-3">
//                 {isDisplay && <p className="p-heading">Add Options</p>}
//                 {singleOptionCount?.length < 10 && isDisplay && (
//                   <p
//                     className="lead-greem"
//                     role="button"
//                     onClick={optionIncrementHandler}
//                   >
//                     + Add More
//                   </p>
//                 )}
//               </div>
//               {singleOptionCount &&
//                 singleOptionCount.map((option, i) => {
//                   return (
//                     <InputFieldWidget i={i + 5} isDisplay={isDisplay} singleRadioDataHandler={singleRadioDataHandler} setSingleOptionCount={setSingleOptionCount} remover={remover} />
//                   );
//                 })}
//               <div className="mt-4 mb-3">
//                 <div className="d-flex lead-switch">
//                   {isDisplay && <p className="p-heading">
//                     Mandatory Question
//                     <span className="pl-1">
//                       <Info fill='#828282' id='plTooltip' color='white' size={18} />
//                       <UncontrolledTooltip placement='right' target='plTooltip'>
//                         mandatory question field
//                       </UncontrolledTooltip>
//                     </span>
//                   </p>}
//                   {isDisplay && <label className="switch">
//                     <input type="checkbox" />
//                     <span className="slider round"></span>
//                   </label>}
//                 </div>
//                 {isDisplay && <p>Add a star mark to indicate mandatory question field</p>}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SingleChoice;
// =================new return ==============
import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Info } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import InputFieldWidget from "./InputFieldWidget";

const SingleChoice = ({ remover, inputId, inputMulti, i, isDisplay, star, setStar, star1, setStar1, optInc, multiOptionCount1, removeOneOption }) => {
  const [multiOptionCount, setMultiOptionCount] = useState([1, 2]);
  const [multiValue, setMultiValue] = useState();
  const [dataValue, setDataValue] = useState("")

  const [tempMultiOption, setTempMultiOption] = useState({
    seq1: "",
    seq2: "",
  });
  const multiDataHandler = (e) => {
    const { name, value } = e.target;
    setTempMultiOption({ ...tempMultiOption, [name]: value });
  };
  // ==============singleTextDataHandler===================
  const singleTextDataHandler = (e) => {
    const { name, value } = e.target;
    setTempMultiOption({ ...setTempMultiOption, [name]: value });
    const eventName = "input_lf_" + inputId;
    console.log("cint" + eventName);
    const customEvent = new CustomEvent(eventName, {
      detail: { value },
    });
    document.dispatchEvent(customEvent);
  };
  useEffect(() => {
    const handleCustomEvent = (event) => {
      // Access the message from the custom event's detail property
      const { value } = event.detail;
      console.log(`Received Message: ${value}`);
      setDataValue(value);
    };
    const eventName = "input_lf_" + inputId;
    console.log(eventName);
    document.addEventListener(eventName, handleCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleCustomEvent);
    };

  }, []);
  // ==============singleMultiDataHandler=================
  const singleMultiDataHandler = (e, field) => {
    const { name, value } = e.target;
    setTempMultiOption({ ...setTempMultiOption, [name]: value });
    const eventName = "input_lf_" + inputMulti;
    console.log("cint" + eventName);
    const customEvent = new CustomEvent(eventName, {
      detail: { value },
    });

    document.dispatchEvent(customEvent);

    if (field === 'multi') {
      setMultiValue(value);
    } else if (field === 'second') {
      setSecondValue(value);
    }
  };
  const [multiValues, setMultiValues] = useState(Array(multiOptionCount.length).fill(""));
  // const isDisplay = true;

  const [secondValue, setSecondValue] = useState('');
  useEffect(() => {
    const handleCustomEvent = (event) => {
      const { value } = event.detail;
      console.log(`Received Message: ${value}`);
      setMultiValue(value);
      setSecondValue(value);
    };
    const eventName = "input_lf_" + inputMulti;
    console.log(eventName);
    document.addEventListener(eventName, handleCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleCustomEvent);
    };

  }, []);
  // ============singleMultiDataHandler==============
  const optionIncrementHandler = (e) => {
    e.preventDefault();

    console.log("clicked add");
    console.log(multiOptionCount);
    if (multiOptionCount.length < 100) {
      console.log("dispat");
      const nextData = `seq${multiOptionCount.length + 1}`;
      setMultiOptionCount([...multiOptionCount, multiOptionCount.length + 1]);
      setTempMultiOption({ ...tempMultiOption, [nextData]: "" });
      const customEvent = new CustomEvent("addnewmultiplechoice", {
        detail: {},
      });
      document.dispatchEvent(customEvent);
    }
  };

  const [selectedValue, setSelectedValue] = useState('female');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const handleMultipleChoiceCustomEvent = (event) => {
      const nextData = `seq${multiOptionCount.length + 1}`;
      setMultiOptionCount([...multiOptionCount, multiOptionCount.length + 1]);
      setTempMultiOption({ ...tempMultiOption, [nextData]: "" });

    };
    const eventName = "addnewmultiplechoice";
    console.log(eventName);
    document.addEventListener(eventName, handleMultipleChoiceCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleMultipleChoiceCustomEvent);
    };
  }, []);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const secondDataHandler = (e) => {
    const value = e.target.value;
    setSecondValue(value);
  };
  const placeholderStyle = {
    fontWeight: 'bold',
    color: '#4d4d4d',
    fontSize: '16px'
  };
  return (
    <>
      <div className="row ad-types-of-type-map mt-4" style={{ margin: '2px' }}>
        <div className="single-ad ">
          <div className="col-lg-12 inputs d-flex p-0">
            <div className="col-lg-12 col-12 mt-3">
              <div className="d-flex lead-switch">
                {isDisplay ? <p className="p-heading">Enter Question</p> : <p className="p-heading"></p>}
                {/* Where is the White House* */}
                {isDisplay && <p className="lead-greem" role="button" onClick={remover}>
                  Remove
                </p>}
              </div>
              <div className="">
                <input
                  type="text"
                  className={isDisplay ? "form-control p-2" : "form-control p-2 hidebordertext"}
                  placeholder={!star ? "Type the ‘Hint’ here..." : "Type the ‘Hint’ here*"}
                  style={dataValue ? placeholderStyle : {}}
                  maxLength={32}
                  name="textName"
                  value={dataValue}
                  onChange={singleTextDataHandler}
                  readOnly={!isDisplay}
                />
              </div>
              {isDisplay && <p className="p-max-car">Upto 32 Characters</p>}
              <div className="d-flex lead-switch mb-3 mt-3">
                {isDisplay && <p className="p-heading">Add Options</p>}
                {multiOptionCount?.length < 10 && isDisplay && (
                  <p
                    className="lead-greem"
                    role="button"
                    onClick={optInc}
                  >
                    + Add More
                  </p>
                )}
              </div>
              {multiOptionCount1 &&
                multiOptionCount1.map((option, i, index) => {
                  const isFilled = multiValues[i] !== undefined && multiValues[i] !== "";
                  return (
                    <InputFieldWidget i={i + 5} isDisplay={isDisplay} singleTextDataHandler={singleTextDataHandler} setMultiOptionCount={setMultiOptionCount} isFilled={isFilled} removeOneOption={removeOneOption} remover={() => remover(`seq${index + 1}`)} />);
                })}
              <div className="mt-4 mb-3">
                <div className="d-flex lead-switch">
                  {isDisplay && <p className="p-heading">
                    Mandatory Question
                    <span className="pl-1">
                      <Info fill='#828282' id='plTooltip' color='white' size={18} />
                      <UncontrolledTooltip placement='right' target='plTooltip'>
                        mandatory question field
                      </UncontrolledTooltip>
                    </span>
                  </p>}
                  {isDisplay && <label className="switch">
                    <input type="checkbox" checked={star1} onChange={() => { setStar((prev) => !prev); setStar1((prev) => !prev) }} />
                    <span className="slider round"></span>
                  </label>}
                </div>
                {isDisplay && <p>Add a star mark to indicate mandatory question field</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleChoice;