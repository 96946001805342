import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Calendar, MapPin, Search } from 'react-feather'
import { Button, Card, CardBody, CardImg, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import EventImg from "../Img/EventsImg1.png"
import Edit from "../Img/edit.png"
import { NavLink } from 'react-router-dom'
import * as EventsDataArray from './EventsDataArray'
import Drctn from '../Img/Direction.svg'
import Clndr from '../Img/Clndr.svg'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { upcommingEventCategoryAction } from '../../../Services/Actions/Event/getUpcommingEventPageAction'

const EventsTopBanner = () => {
    let dispatch = useDispatch();
    const { upcommingEvent, loding } = useSelector(state => state.getUpcommingCategoryData)
    useEffect(() => {
        const obj = {
            day: "",
            categoryId: [""],
            eventLocation: "",
            lat: "",
            long: "",
            radius: "",
        };
        dispatch(upcommingEventCategoryAction(obj));
    }, []);
    console.log("upcommingEvent2", upcommingEvent)

    const [datelocationOpen, setDateLocationOpen] = useState(false)
    const toggleLocation = () => {
        setDateLocationOpen((preState) => !preState)
    }
    const [locationOpen, setLocationOpen] = useState(false)
    const toggleEvntLocation = () => {
        setLocationOpen((preState) => !preState)
    }
    const [dateData, setDateData] = useState('2022-06-02')
    const DateGetData = (val) => {
        console.warn(val.target.value)
        setDateData(val.target.value)
    }
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 810);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 810);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //===================== main_return_function
    return (
        <Fragment>
            {/*================= start Top_Banner_Img_section================  */}
            <div className='Events_Top_section' style={{ cursor: "pointer", marginLeft: '2px', marginRight: '2px' }}>
                <CardImg
                    onClick={toggleEvntLocation}
                    alt="Card image cap"
                    src={EventImg}
                    height="50%" 
                    width="100%"
                    style={{maxHeight:'386px'}}
                />

                {/*==================Start_Top_Banner_schedule_section=============== */}
                <div className='Group_Top_Cover_Buttom_LeftSection'>
                    <Card className="event_Card_section text-wrap  Events_Cards_Top_section  event_Card_sections">
                        {/* Events_Cards_Top_sections */}
                        <CardBody className='card-event-drop'>
                            <div className='Events_Card_Top_section Events_Card_Top_sections ' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                <h4 className='event-card-texts web-texts'>Today’s Event</h4>
                                <p style={{ cursor: 'pointer' }} onClick={toggleLocation} className=''><img src={Edit} alt="edit" className='img-fluid web-texts' /></p>
                            </div>
                            {/*  profile-date */}
                            <div className='py-2 d-flex align-items-center text-profile'>
                                <div className="profile-cover profile-cover-new">
                                    <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
                                        <ul>
                                            <li className="choose-file">
                                                <a className='UploadBtn' href="">
                                                    <Calendar color='#FFFFFF' size={15} />
                                                </a>
                                                <input type="date"
                                                    onChange={DateGetData}
                                                />&nbsp;&nbsp;
                                            </li>
                                        </ul>
                                    </a>
                                </div>
                                <div className='ml-2'>
                                    <p className='text-primary form-text-profile' style={{ fontSize: "16px" }} ><strong className=''>{dateData.slice(8, 11)}</strong>&nbsp;<sup className='text-white'>nd</sup>&nbsp;<span className='text-white '> {moment(dateData).format('MMM YYYY')}</span></p>
                                    {/* {dateData.slice(0, 4)} */}
                                </div>
                            </div>
                            {/* <p className='text-primary'>{moment(dateData.slice(0, 6)?.setDateData  ).format('MMM Do YYYY')}</p> */}
                            <div className='d-flex align-items-start py-1 ' style={{ position: 'relative' }}>
                                <MapPin color='#FFFFFF' width={23} className='map-web-text' onClick={toggleLocation} />
                                <div className='event_Location_section ml-2 web-text' >
                                    {/* <h5 style={{ color: 'white' }} className='web-text'>Wembley Centre, Albert Luthuli,
                                        Pitermartizeburg</h5> */}
                                    <u><h5 style={{ color: 'white' }} className='web-text'>
                                        Wembley Centre, Albert Luthuli,
                                        Pitermartizeburg
                                    </h5></u>

                                </div>
                            </div>
                            <div className='Event_Bottom_section py-2'>
                                <p className='hidden-text' style={{ fontSize: "14px" }}>See More →</p>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                {/*==================End_Top_Banner_schedule_section=============== */}
            </div>
            {/*=================End_start Top_Banner_Img_section================  */}
            {/*==================== Start_Top_Banner_Bottom_section=============== */}
            <div className="Group_Top_Cover_Section rem-mob-view">
                <div className="heading-title-blk head-title-biz m-2">
                    <Row>
                        <Col sm="3" className="d-flex align-items-center ">
                            <div className='d-flex  align-items-center ' style={{ marginLeft: '-19px' }}>
                                <div className='EventsCategories_calender'>
                                    <img src={Clndr} width={26} height={32} alt=''/>
                                </div>
                                <div className='ml-2 Events_Remember_Heading' >
                                    <h4 style={{ fontSize: '14px', textWrap: 'nowrap' }}>Events to Remember </h4>
                                    <p style={{ fontSize: '12px', textWrap: 'nowrap' }}>Connect exclusive &<br />priceless event memories.</p>
                                </div>
                            </div>
                        </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {EventsDataArray.EventsReminder.map((data) => (
                            <Col sm="" className="d-flex align-items-center justify-content-center" >
                                <div className='text-center Event_Remember_section'>
                                    <h4 style={{ fontSize: '14px', }}>{data.day}</h4>
                                    <NavLink to={data.route}> <p style={{ fontSize: '12px' }}>{data.count}</p></NavLink>
                                </div>
                            </Col>
                        ))
                        }
                    </Row>
                    <Row >
                        {upcommingEvent?.map((data) => (
                            <>
                                {
                                    data.id === "1" ?
                                        <Col md="3">
                                            <div className='d-flex  align-items-center '>
                                                <div className='EventsCategories_calender'>
                                                    <img src={Clndr} width={26} height={32} alt='' />
                                                </div>
                                                <div className='ml-3 Events_Remember_Heading' >
                                                    <h4>Events to Remember </h4>
                                                    <p>Connect with exclusive event & <br />priceless memories.</p>
                                                </div>
                                            </div>
                                        </Col>
                                        :
                                        (data.id === "0") ?
                                            <Col className="d-flex align-items-center justify-content-between w-100">
                                                <div className='text-center Event_Remember_section'>
                                                    <h4>{data.day}</h4>
                                                    <NavLink to={data.rows}><p>{data.Count}</p></NavLink>
                                                </div>
                                            </Col> : null
                                }
                            </>
                        ))
                        }
                    </Row>
                </div>
            </div>
            {/*==================== End_Top_Banner_Bottom_section=============== */}
            {/*================start_Modal_section====================== */}
            <div className='Location_and_Time_Modal'>
                {
                    datelocationOpen !== false &&
                    <Modal isOpen={datelocationOpen} toggle={toggleLocation} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleLocation} className="Events_modal_header_section">
                            <h2>Select Date & Location</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <FormGroup>
                                    <Label className="Bio_text">
                                        Enter Date
                                    </Label>
                                    <Input
                                        name="datetime"
                                        type="date"
                                        className=""
                                    />
                                </FormGroup>
                            </div>
                            <div>
                                <FormGroup>
                                    <Label className="Bio_text">
                                        Enter Location
                                    </Label>
                                    <div className='form_control_section_input_box'>
                                        <Input

                                            id="exampleEmail"
                                            name="email"
                                            placeholder="Wemblay Center, London, UK"
                                            type="email"
                                            className="inputForm"
                                        />
                                        <img src={Drctn} className='Add_box_section  border-left pl-2' alt='' />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className='bizAbout_Map_Modal'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className='text-end pt-3'>
                                <Button color='secondary' className="w-25" outline style={{ fontSize: '16px' }}>Cancel</Button>
                                <Button color="primary" className="ml-2 w-25" style={{ fontSize: '16px' }}>Ok</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
            <div className='Events_Location_modal'>
                {
                    locationOpen !== false &&
                    <Modal scrollable isOpen={locationOpen} toggle={toggleEvntLocation} className='modal-dialog-centered'>
                        <ModalHeader className="Filter_Modal_section" toggle={toggleEvntLocation}>
                            <h4>Select Location</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className='mt-2 form_control_section' >
                                <FormGroup>
                                    <div className='form_control_section_input_box'>
                                        <Input
                                            id="exampleEmail"
                                            name="email"
                                            placeholder="Wemblay Center"
                                            type="email"
                                            className="Event_inputForm"
                                        />
                                        <img src={Drctn} alt="location" className=' Map_box_section border-left pl-2' />
                                        <Search color='#4D4D4D' size={16} className="Event_location_search-svg" />
                                    </div>
                                </FormGroup>
                            </div>
                        </ModalBody>

                    </Modal>
                }

            </div>
            {/*================end_Modal_section====================== */}
        </Fragment>
    )
}

export default EventsTopBanner