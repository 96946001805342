import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import { Card, CardImg } from "reactstrap";
import {addSports,loadAllSports} from "../../../Services/Actions/UserProfile/getAllUserSportsAction";
import {loadProfileByUserId,loadSportsByUserId} from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import Pen1 from "../Img/Pen1.png";
import '../profileComponents.css'
const MyPorfileSports = (props) => {
  const sportSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  let dispatch = useDispatch();
  const [userSports, setUserSports] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // get user profile by user id
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  // get all sports
  const { allSports } = useSelector((state) => state.getAllSportsData);

  const sportsHandler = (sport) => {
    const exists = userSports.find((sp) => sp.id === sport.id);
    if (exists) {
      setUserSports(userSports?.filter((int) => int.id !== sport.id));
    } else {
      setUserSports([...userSports, sport]);
    }
  };

  const submitSports = () => {
    const sport = {
      addSport: true,
      deleteSport: false,
      sportsId: userSports.map((val) => val.id),
    };
    dispatch(addSports(sport));
  };

  useEffect(() => {
    dispatch(loadProfileByUserId(props?.userId));
    dispatch(loadAllSports());
  }, []);

  // console.log("userId============================",props.userId)

  useEffect(() => {
    let tempInt = userProfileByUserId.sport?.map((sport) =>
      allSports.rows?.filter((intFil) => intFil.id === sport.sports_id)
    );
    setUserSports(tempInt?.map((tem) => tem && tem[0]));
  }, [allSports]);

  // SPORT VIA USER ID
  const { userSportsByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  useEffect(() => {
    dispatch(loadSportsByUserId(props.userId));
  }, []);
  return (
    <>
      <div className="about-profile-box-blk">
        <div className="card-title">
          <h3>Sports</h3>
          <div className="settings">
            {/* <div className="setting-btn"> */}
            {userSportsByUserId?.rows?.length > 0 && props.type !=="viewer" && (
              <a href="#" data-bs-toggle="modal" data-bs-target="#sportsModel">
                <div className="setting-btn">
                  <img
                    src={Pen1}
                    width={12}
                    className="img-fluid"
                    style={{ cursor: "pointer" }}
                    alt="penIcon"
                  />
                </div>
              </a>
            )}
          </div>
        </div>
        <div className="card-block-box">
      {userSportsByUserId?.rows?.length > 0 ? (
        <Slider {...sportSettings} className="default-space">
          {userSportsByUserId.rows.map((sport) => (
            <div key={sport.id}>
              <Card className="profile-slide-box">
                <CardImg
                  alt="Card image cap"
                  src={sport.sportImgURL}
                  width="100%"
                  className="img-fluid"
                />
                <div className="story-content">
                  <h6 style={{ color: "white" }}>{sport.name}</h6>
                </div>
              </Card>
            </div>
          ))}
        </Slider>
      ) : (
        <>
          {props.type !== "viewer" && (
            <div className="mx-3 py-4 Profession_Modal_section">
              <a href="#" data-bs-toggle="modal" data-bs-target="#sportsModel">
                <p>+ Add your sports</p>
              </a>
            </div>
          )}
        </>
      )}
    </div>
      </div>

      <div
        className="modal fade"
        id="sportsModel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="sportsModelTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="Profession_Modal_section AboutModal_Main_section p-3 py-3 ">
              <h4>Choose your Sports</h4>
              <p onClick={submitSports} data-bs-dismiss="modal">Save</p>
            </div>
            <div className="modal-body">
              <div className="searchfilter-blk p-2">
                <div className="input-search-blk">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Find Sport..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
                <ul className="searchfiler-list">
                  {allSports?.rows
                    ?.filter((sports) =>
                      sports.name.match(new RegExp(searchValue, "i"))
                    )
                    .map((sports) => {
                      return (
                        <li key={sports.id}>
                          <table width="100%">
                            <tr>
                              <td width="90%">
                                <label
                                  className="form-check-label"
                                  htmlFor={sports.name}
                                >
                                  {sports.name}
                                </label>
                              </td>
                              <td width="10%">
                                <input
                                  type="checkbox"
                                  id={sports.name}
                                  onClick={() => sportsHandler(sports)}
                                  checked={userSports?.some(
                                    (int) => int?.id === sports?.id
                                  )}
                                />
                              </td>
                            </tr>
                          </table>

                          {/* <div className="form-check checkbox_animated"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={sports.name}
                                                        onClick={() => sportsHandler(sports)}
                                                        checked={userSports?.some((int) => int?.id === sports?.id)}
                                                    /><label className="form-check-label" htmlFor={sports.name}>{sports.name}</label></div> */}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPorfileSports;
