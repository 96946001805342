import settingMedia from '../../../NewComponents/IMG/settingMedia.png'
export const MediaContentData = [
    {
        heading: "Allow Push Notification",
        subContent: "Disable to mute videos on your social feed"
    },
    {
        heading: "Autoplay on Mobile Data",
        subContent: "Use mobile data to autoplay media"
    },
    {
        heading: "Autoplay on Mobile Data",
        subContent: "Use mobile data to autoplay media"
    },
    {
        img: settingMedia,
        typeof: "mediaQuality",
        heading: "Choose Media Quality",
        subContent: "Lets you choose media quality",
        Quality: "High Quality ",
        Quality1: "Medium  ",
        Quality2: "Low "
    }
]