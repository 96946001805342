// import React from 'react'
// import { Fragment } from 'react'
// import { Button } from 'react-bootstrap'
// import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

// const DownloadModal = (props) => {
//     return (
//         <Fragment>
//             {
//                 props.isOpen !== false &&
//                 <Modal isOpen={props.isOpen} toggle={props.downloadModalOpen} className='modal-dialog-centered'>
//                     <ModalHeader onClick={props.downloadModalOpen} className='d-flex justify-content-center Media_header' >
//                         <h3>Download All Shotz</h3>
//                     </ModalHeader>
//                     <ModalBody className='text-center'>
//                         <h5 className='py-1 name-style'>Do you really want to download the selected Shotz?</h5>
//                     </ModalBody>
//                     <ModalFooter className='d-flex align-items-center border-0  justify-content-center  modalHeading_Section '>
//                         <Button variant='outline-secondary' onClick={props.downloadModalOpen}>
//                             <span className='ml-1'>Cancel</span>
//                         </Button>
//                         <Button variant='primary' onClick={props.downloadModalOpen}>
//                             <span className='ml-1'>Download</span>
//                         </Button>
//                     </ModalFooter>
//                 </Modal>
//             }
//         </Fragment>
//     )
// }

// export default DownloadModal

import React from 'react'
import { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const DownloadModal = (props) => {
    // Function to handle the "Download" button click
    // const handleDownloadClick = () => {
    //     // Close the modal
    //     props.downloadModalOpen(false);
    // };

    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.downloadModalOpen} className='modal-dialog-centered'>
                    <ModalHeader onClick={props.downloadModalOpen} className='d-flex justify-content-center Media_header' >
                        <h3>Download All Shotzd</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h5 className='py-1 name-style'>Do you really want to download the selected Shotz?</h5>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center border-0  justify-content-center  modalHeading_Section '>
                        <Button variant='outline-secondary' onClick={props.downloadModalOpen}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant='primary' onClick={props.downloadModalOpen}>
                            <span className='ml-1'>Download</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </Fragment>
    )
}

export default DownloadModal;









// import React from 'react'
// import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// import { Button } from 'react-bootstrap'
// import { Fragment } from 'react'

// const DownloadModal = (props) => {
//     return (
//         <Fragment>
//             {
//                 props.isOpen !== false &&
//                 <Modal isOpen={props.isOpen} toggle={props.downloadModalOpen} className='modal-dialog-centered'>
//                     <ModalHeader onClick={props.downloadModalOpens} className='d-flex justify-content-center Media_header' >
//                         <h3>Download All Shotz</h3>
//                     </ModalHeader>
//                     <ModalBody className='text-center'>
//                         <h5 className='py-1 name-style'>Do you really want to download the selected Shotz?</h5>
//                     </ModalBody>
//                     <ModalFooter className='d-flex align-items-center border-0  justify-content-center  modalHeading_Section '>
//                         <Button variant='outline-secondary' onClick={props.downlodeModals}>
//                             <span className='ml-1'>Cancel</span>
//                         </Button>
//                         <Button variant='primary' onClick={props.downlodeModals}>
//                             <span className='ml-1'>Download</span>
//                         </Button>
//                     </ModalFooter>
//                 </Modal>
//             }
//         </Fragment>
//     )
// }

// export default DownloadModal