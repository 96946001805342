import React, { Fragment, useState } from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { AlertCircle } from 'react-feather';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';

const SignUpOtp = () => {




    const navigate = useNavigate();




    const [otpTimeLimit, setOtpTimeLimit] = useState(20);
    const [otpTimer, setOtpTimer] = useState("");




    //  ------- Otp Resend -------------


    const resendOtp = () => {
        if (otpTimeLimit < 1) {
            setOtpTimeLimit(20)
        }
    }



    // ------------------------------------------ On Otp Submit Page  - ----------------------------------------------------------------
    const otpSubmit = () => {
        navigate("/signupemailverify")
    }


    // ------------------------------------------------ Otp Input Manage ----------------------------------------------- 
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);

        // Focus on next input
        if (e.target.value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
        // Focus on previous input
        else if (!e.target.value && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };


    // ----------------------------------------------------------------- Timer Updation   ---------------------------------------
    useEffect(() => {
        const interval = setInterval(() => {
            if (otpTimeLimit === 0) {
                setOtpTimer("00:00");
            } else {
                const minutes = Math.floor(otpTimeLimit / 60);
                const seconds = otpTimeLimit % 60;
                setOtpTimer(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
            }
            if (otpTimeLimit > 0) {
                setOtpTimeLimit(otpTimeLimit - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    },);


    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }



    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")



    return (
        // -------------------- Otp Page return -------------------------------

        <Fragment>
            {/* =========================Language Modal ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <div>
                <section className="login-section LoginSignUpBackground">
                    <div className="container">
                        <Row >
                            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                                <div className="login-header-section">
                                    <div className="logo-sec"><Link className="" to="/"><img src="/assets/images/logo.png" alt="logo" className="img-fluid" /></Link></div>
                                </div>
                                <div className="login-form">
                                    <LoginSignUpStepper steps={5} active={1} />
                                    <div>
                                        <div className="login-title">
                                            <h2>Enter OTP</h2>
                                        </div>
                                        <div className="login-discription">
                                            <h4>An OTP has been sent to your phone number ending with
                                                <br />XXX XXX 7483</h4>
                                        </div>
                                        <div className="form-sec">
                                            <div>
                                                <form className="theme-form">
                                                    <div className="form-group">
                                                        <div className="otpContainer">
                                                            {/* --------------------------- Otp Inputs ------------------------------------------------------------- */}
                                                            {otp.map((value, index) => (
                                                                <input
                                                                    className={`otpInput form-control Login000_400 outline81 ${otp.filter(x => x === "1").length === 6 ? "border BorderInputAlert" : otp.includes("") === false ? "border border-primary" : ""}`}
                                                                    key={index}
                                                                    type="tel"
                                                                    placeholder='-'
                                                                    maxLength={1}
                                                                    value={value}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    ref={(el) => (inputRefs.current[index] = el)}
                                                                />
                                                            ))}
                                                        </div>
                                                        <p className={`error-input-msg text-start LoginAlert500 ${otp.filter(x => x === "1").length === 6 ? "" : "d-none"}`} >
                                                            <AlertCircle size={16} color='#fff' fill='#EB5757' /> Invalid Otp</p>
                                                        {/* <div className="otp-time-count" id=''>00:20 sec</div> */}
                                                        <div className="otp-time-count" id='timer-div'><span id="timer">{otpTimer}</span> sec</div>
                                                    </div>
                                                    <div className="resendotp-blk" id="resendotp">Didn't receive OTP yet? <Link to='#' style={{ textDecoration: "underline" }} className={`UnderLineText ${otpTimeLimit >= 1 ? "text-secondary" : 'color-blue'}`} onClick={resendOtp}>Resend</Link></div>
                                                    <div className="btn-section">
                                                        <Button color="primary" onClick={otpSubmit} disabled={otp.filter(x => x === "1").length === 6 ? true : otp.join("").length === 6 ? false : true}>CONTINUE</Button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        {/* ============================= Language Section ================================== */}
                        <div className="language-selection-section">
                            <div className="lang-sel-block">
                                <b>Choose Language:</b>
                                <span className="language-list">
                                    {/* --------------------------- Labnguages------------------------------ */}
                                    {["English", "Hindi", "Deutsch"].map((item, index) => (
                                        <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                    ))}
                                </span>
                                <button onClick={() => setLanguages(true)} >
                                    ...More
                                </button>
                            </div>
                        </div >

                        {/* ======================== Bottom Canvas Language section  ======================================= */}
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default SignUpOtp
