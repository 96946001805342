import { Checkbox } from '@mui/material'
import React, { Fragment } from 'react'
import { Modal } from 'reactstrap'
import { HobbiesDataOption } from './ProfessionalArray'
import Book from '../../../NewComponents/IMG/IntersetBook.svg'

const InterestModal = (props) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <Fragment>
            <div className='Modal_section_Basic_info_Interestes'>
                {
                    props.isOpen !== false &&
                    <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>

                        <div className='Profession_Modal_section AboutModal_Main_section p-3 py-3 '>
                            <h4>{props.heading}</h4>
                            <p onClick={props.updateOpen}>Save</p>
                        </div>
                        <div className='Modal_scroll_box'>
                            <div className='Interest_Modal_section AboutModal_Main_section p-3 py-4'>
                                <h4>Please select at<span> {props.title}</span> for a better in-web <br /> experience</h4>
                            </div>
                            <div className="AboutModal_Main_section p-3 py-3">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder={props.placeHolder} data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>

                            {
                                HobbiesDataOption.map((data) => (
                                    <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-4 '>
                                        <div className='d-flex align-items-center'>
                                            <div className='Settings_Icon_Interest'>
                                                <img
                                                    src={Book}
                                                    width="22px"
                                                    height="19px"
                                                    className='img-fluid'
                                                    alt='iconUrl' />

                                            </div>
                                            <h4 className='ml-3  Basic_Info'>{data.name}</h4>
                                        </div>
                                


                                    </div>
                                ))
                            }

                        </div>

                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default InterestModal