import React, { useEffect, useState } from 'react'
import { XCircle } from 'react-feather'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import { Card, Input } from 'reactstrap'
import CreatepostFooter from './CreatepostFooter'
import CreatepostHeader from './CreatepostHeader'

const Article = (props) => {
    const [convertedContent, setConvertedContent] = useState(null);
    const [imgfile, uploadimg] = useState("https://dummyimage.com/640x250")
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const imgFilehandeler = (e) => {
        if (e.target.files.length !== 0) {
            uploadimg(URL.createObjectURL(e.target.files[0]))
        }
    }

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);
    console.log(convertedContent);
    return (
        <Card className='p-3 rounded'>
            <CreatepostHeader
                actionM={props.NavMedia}
                actionT={props.NavThrought}
                actionG={props.NavGoLive}
                actionE={props.NavEvent}
                actionP={props.NavPodcast}
                actionA={props.NavArticle}
                actionR={props.NavReccom}
                actionAl={props.NavAlert}
                actionPo={props.NavPoll}
                actionS={props.NavSell}
            />
            <Input type='textarea' className='positionForArticleSplaceholderS' Placeholder='Headline' />
            <div>
                <div>
                    <input type="file" id="upload" onChange={imgFilehandeler} hidden />
                    <XCircle color='#81C14B' onClick={() => uploadimg("https://dummyimage.com/640x250")} className='positionForImgremoveS' size={20} />
                </div>
                <label className='kitForinputbox' for="upload">
                    <img src={imgfile} alt='Featured Images' className='border border-secondary' width='100%' height={250} />
                </label>
            </div>
            <Editor editorState={editorState} onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editorClassName bg-light border"
                toolbarClassName="toolbar-class"
            />
            <CreatepostFooter
                FirsticonS={'d-none'}
                Secondicon={'d-none'}
                Thirdicon={'d-none'}
            />
        </Card>
    )
}

export default Article