import * as types from "../../Constants/Events/index";

const initialState = {
    onGoingEvents: [],
    loading: true,

};


// ONGOING EVENTS
export const getUserOnGoingEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ONGOING_EVENTS_BY_USER:
            return {
                ...state,
                onGoingEvents: action.payload,
                loading: false
            };
        default:
            return state
    }
};

