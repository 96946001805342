import React from 'react'
import ContentLeft from '../ContentLeft'
import ContentRight from '../ContentRight'
import CreatePost from '../CreatePost'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import { StorySlider } from '../Story/StorySlider'
import Allpostdisplay from '../Universal-components/display/Sub-component/Allpostdisplay'
import UniversalCard from '../Universal-components/display/Sub-component/UniversalCard'
import Media from '../Universal-components/post-creations/sub-components/Media'
import DynamicTab from './DynamicTab'

const Feed = () => {
  return (
    <div>
      <Header />
      <div className="page-body container-fluid newsfeed-style1">
        <LeftSidebar />
        <div className="page-center">
          {/* <StorySlider /> */}
          <div className="container-fluid section-t-space px-0">
            <div className="page-content">
              <ContentLeft />
              <div className="content-center-slider content-center px-0">
                <DynamicTab />
                <div className="post-panel section-t-space ">
                  <Allpostdisplay />
                </div>
              </div>
              <ContentRight />
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  )
}

export default Feed