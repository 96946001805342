import React, { useState } from 'react'
import { Card, CardHeader, CardText, Col, Row } from 'reactstrap'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import SettingSidebar from './SettingSidebar'
import './Setting.css'
import SettingTabData from './SettingTabData'

const Setting = () => {
    const [categoriesActiveTab, setCategoriesActiveTab] = useState('1')
    const [settingsText, setSettingsText] = useState('Settings'); 
    const toggleCategories = tab => {
        if (categoriesActiveTab !== tab)
         setCategoriesActiveTab(tab)
        
    }
    const [sideOptionsClass, setsideOptionsClass] = useState(false)
    return (
        <div>
            <Header />
            <div className="page-body container-fluid newsfeed-style1">
                <LeftSidebar />
                <div className="page-center">
                    <Card>
                        <CardHeader className='p-3 bg-transparent'>
                        <CardText className='settfont hide-setting-options1 setposi'>{settingsText}</CardText>
                        <CardText className='settfont hide-setting-options '>Setting</CardText>
                        
                        </CardHeader>
                        <Row className='rowForrevert'>
                            <Col sm='3' xl='3' className={sideOptionsClass ?  'border-right hide-setting-options' : 'border-right'} >
                                <SettingSidebar tabData={categoriesActiveTab} Slidetoggle={toggleCategories} setsideOptionsClass={setsideOptionsClass} sideOptionsClass={sideOptionsClass} setSettingsText={setSettingsText}/>
                            </Col>
                            <Col sm='9' xl='9'  className={ !sideOptionsClass && 'hide-setting-options'} >
                                <SettingTabData tabData={categoriesActiveTab} setsideOptionsClass={setsideOptionsClass} setSettingsText={setSettingsText}/>
                            </Col>
                        </Row>
                    </Card>

                </div>
                <RightSidebar />
            </div>
        </div>
    )
}

export default Setting