import React, { Fragment, useEffect, useState } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { deleteBizPageAction, getAllUserOwnPinnedBizPageReducerAction } from '../../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux';
import { sweetAlertConfirmation } from '../../../group-components/AllGroupsSections/SweetAlert';
import { useNavigate } from "react-router-dom";
const DeleteModal = (props) => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    console.log("props", props.businessPageId)

    const deletePage = useSelector((state) => { return state.deleteBizPageData });
    const { loading: loadingDeletePage, error: errorDeletePage, result: resultDeletePage } = deletePage

    console.log('DeletePage', resultDeletePage)

    useEffect(() => {
        if (resultDeletePage) {
            (sweetAlertConfirmation('Page successfully deleted'))
            dispatch(getAllUserOwnPinnedBizPageReducerAction())
            // navigate("/MyBizPage");
        }

    }, [resultDeletePage]);

    const [passwordToDelete, setPasswordToDelete] = useState('')
    function inputPassword(event) {
        setPasswordToDelete(event.target.value); // prints the current value of the input
    }

    function deleteBizPage(event) {
        dispatch(deleteBizPageAction(props.businessPageId, passwordToDelete))
    }

    return (
        <Fragment>
            <div className='Delete_business_Modal'>
                {
                    props.isOpen !== false &&
                    <Modal isOpen={props.isOpen} toggle={props.UpdateOpen} className='modal-dialog-centered'>
                        <ModalHeader className="BizDelete_Heading">
                            <h2>Delete Business page </h2>
                        </ModalHeader>
                        {!props.activeState ? (
                            <>
                                <ModalBody>
                                    <div className='BizGeneral_settingDetel_modal'>
                                        <h4>Are you sure you want to delete “Gong Advertisement” Biz Page?</h4>
                                    </div>
                                    <FormGroup className="py-3">
                                        <Label className="Biz_Rating_sab_section">
                                            <h3>Enter  account password for deleting page</h3>
                                        </Label>
                                        <Input
                                            placeholder="Enter your Password here "
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            className="w-100"
                                            value={passwordToDelete}
                                            onChange={inputPassword}
                                        />
                                        {/* <p onClick={props.updateState} className='Forgot_Section py-2'>Forgot Password? </p> */}
                                    </FormGroup>
                                    <div className="group-buttons-blk mt-2">
                                        <Button className="Invite_Decline_Button" outline onClick={props.UpdateOpen}>
                                            Cancel
                                        </Button>
                                        <Button className="Invite_Join_Button ml-2" color='primary' onClick={props.UpdateOpen}>Confirm?</Button>
                                    </div>
                                </ModalBody>
                            </>

                        ) : (
                            <>
                                <div className='d-flex justify-content-center'>
                                    <div className='text-center'>
                                        <Label className="AboutBiz_EmailOTP_section mt-3">
                                            <h3>Enter OTP</h3>
                                        </Label>
                                        <div className='BizGeneral_settingDetel_OtpSection AboutBiz_EmailOTP_section'>
                                            <span>An OTP has been sent to your registered phone<br />
                                                number ending with<br />
                                            </span>
                                            <h3  >XXX XXX 8797</h3>
                                        </div>
                                        <div className='d-flex justify-content-center py-3'>
                                            <div className='Otp_section'>
                                                <Input
                                                    maxLength="1"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength="1"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='Otp_section  ml-2'>
                                                <Input
                                                    maxLength="1"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='Otp_section  ml-2'>
                                                <Input
                                                    maxLength="1"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='Otp_section  ml-2'>
                                                <Input
                                                    maxLength="1"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className='Otp_section  ml-2'>
                                                <Input
                                                    maxLength="1"
                                                    name="otp"
                                                    type="number"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className='text-center AboutBiz_Email_section'>
                                            <h4>00:20 sec</h4>
                                            <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5>
                                        </div>
                                        <div className='text-center py-3'>
                                            <Button onClick={props.UpdateOpen} color='primary'>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                            </>

                        )

                        }

                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default DeleteModal