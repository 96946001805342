// TimeFormatter.js
import React from 'react';

function FormatTimeAgo(createdAt) {
  const now = new Date();
  const createdDate = new Date(createdAt);
  const timeDifference = now - createdDate;

  if (timeDifference < 0) {
    return "Scheduled Post"; // Or handle it differently based on your use case
  }

  // Define time intervals in milliseconds
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  if (timeDifference < minute) {
    const seconds = Math.floor(timeDifference / 1000);
    return `${seconds} Second${seconds !== 1 ? "s" : ""} Ago`;
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return `${minutes} Minute${minutes !== 1 ? "s" : ""} Ago`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return `${hours} Hour${hours !== 1 ? "s" : ""} Ago`;
  } else if (timeDifference < week) {
    const days = Math.floor(timeDifference / day);
    return `${days} Day${days !== 1 ? "s" : ""} Ago`;
  } else if (timeDifference < month) {
    const weeks = Math.floor(timeDifference / week);
    return `${weeks} Week${weeks !== 1 ? "s" : ""} Ago`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return `${months} Month${months !== 1 ? "s" : ""} Ago`;
  } else {
    const years = Math.floor(timeDifference / year);
    return `${years} Year${years !== 1 ? "s" : ""} Ago`;
  }
}

export default FormatTimeAgo;
