import React, { useEffect, useRef, useState } from 'react'
import { ChevronsRight, Maximize2, Minimize2, MoreVertical, Pause, Play, PlayCircle, Send, X } from 'react-feather';
import { Button, Card, CardBody, CardFooter, CardImg, CardImgOverlay, Col, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap';
import UserPro from '../../../../../NewComponents/IMG/Adspreview.svg'
import UserAdPro from '../../../../../NewComponents/IMG/user2.png'
import Shotzs1 from '../../../../../NewComponents/IMG/Shotzs1.png'
import Svs from '../../../../../NewComponents/IMG/Svs.png'
import Story from '../../../../../NewComponents/IMG/Story.png'
import Framev from '../../../../../NewComponents/IMG/Framev.png'
const FullScreenVideoAd = ({ imageStoryUrl, imageShotzUrl, discriptions, heading, subHeading }) => {
    console.log(heading, '===============>heading')
    console.log(subHeading, '===============>subHeading')
    useEffect(() => {
        console.log("========>preview", imageStoryUrl)
    }, [imageStoryUrl, imageShotzUrl])
    const [accord, setAccord] = useState(1);
    const [centeredModal, setCenteredModal] = useState(false)
    const ShotzvideoRef = useRef(null)
    const ShotzsvideoRef = useRef(null)
    const [playVideo, setplayVideo] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [isOpenA, setIsOpenA] = useState(false)
    const [isSlow, setIsSlow] = useState(false)
    const [isShotzPlay, setIsShotzPlay] = useState(false)
    const videoRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isShotzsPlay, setIsShotzsPlay] = useState(false)
    const togglePlay = () => {
        const video = videoRef.current;

        if (video) {
            if (isPlaying) {
                video.pause();
            } else {
                video.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    const togglePlayShotz = () => {
        const videoShotz = ShotzvideoRef.current;

        if (videoShotz) {
            if (isShotzPlay) {
                videoShotz.pause();
            } else {
                videoShotz.play();
            }
            setIsShotzPlay(!isShotzPlay);
        }
    };
    const togglePlayShotzs = () => {
        const videoShotzs = ShotzsvideoRef.current;

        if (videoShotzs) {
            if (isShotzsPlay) {
                videoShotzs.pause();
            } else {
                videoShotzs.play();
            }
            setIsShotzsPlay(!isShotzsPlay);
        }
    };
    const Previewmodal = () => {
        return (
            <>
                <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-xl modal-dialog-centered'>
                    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Advanced preview</ModalHeader>
                    <ModalBody>
                        {/* <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                     
                                            <Col xs='4' xl='4' onClick={() => { setAccord(1) }}>
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={10} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                            <div className="p-1">
                                                                <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                                                                    <b>
                                                                        An adv is the promotion of a product, brand
                                                                    </b>
                                                                </h6>
                                                                <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                    <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                                                                    <Send size={10} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                         
                                            <Col xs='4' xl='4' onClick={() => { setAccord(2) }} >
                                                <h3 className='p-heading ml-1'>Podcast</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1 ">
                                                    <Card style={{ borderRadius: '5px' }}>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 className="sizingForTextheading">florida roxin</h6>
                                                                        <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={10} className='m-0' />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center sizingForText">
                                                                <h6 className="sizingForTextsmall">Related products</h6>
                                                                <p className="text-info sizingForTextvsmall">View all</p>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <div className="d-flex">
                                                                <div>
                                                                    <img src={UserPro} className='img-fluid w-100 p-1' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.4rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.4rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <img src={UserPro} className='img-fluid w-100 p-1' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.4rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.4rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                         
                                            <Col xs='4' xl='4' onClick={() => { setAccord(3) }}>
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1"><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading">Visit all Groups <ChevronsRight size={10} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert'>
                                      
                                            <Col xs='4' xl='4' onClick={() => { setAccord(4) }}>
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1"><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading">Visit all BizPages <ChevronsRight size={10} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                         
                                            <Col xs='4' xl='4' onClick={() => { setAccord(5) }}>
                                                <h3 className='p-heading ml-1'>MarketPlace</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 border">
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 border">
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert'>
                                       
                                            <Col xs='4' xl='4' onClick={() => { setAccord(6) }}>
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1"><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 border">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 border">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                           
                                            <Col xs='4' xl='4' onClick={() => { setAccord(7) }}>
                                                <h3 className='p-heading ml-1'>Stories</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={UserPro} />
                                                        <CardImgOverlay>
                                                            <div>
                                                                <Progress className='customeForprogressbar' value={25} />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' alt='' />
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>

                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall">Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay>
                                                    </Card>
                                                </div>
                                            </Col>
                                        
                                            <Col xs='4' xl='4' onClick={() => { setAccord(8) }}>
                                                <h3 className='p-heading ml-1'>Shotz</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={UserPro} />
                                                        <CardImgOverlay>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' alt='' />
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall">Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row className='rowForrevert mt-4'>
                            {/*======================Stories Card================== */}
                            {/* onClick={() => { setAccord(7) }} */}
                            <Col xs='4' xl='4' >
                                <h3 className='p-heading ml-1'>Shotz</h3>
                                <div className='small-bg-mains'>
                                    <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                        <div className="post-wrapper col-grid-box">
                                            <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                <div className="profile">
                                                    <div className="media d-flex mb-1" >
                                                        <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                            data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                            <img src="/assets/images/image (1).png"
                                                                className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                        </a>
                                                        <div className="media-body ml-2">
                                                            <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                            <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <MoreVertical size={15} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-details">
                                                <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                    <div style={{ position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                        {isShotzsPlay ? <Pause onClick={togglePlayShotzs} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayShotzs} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                    </div>
                                                    {imageStoryUrl ? <video width="100%" height="350" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={ShotzsvideoRef}
                                                        // autoPlay
                                                        onClick={togglePlayShotzs}>
                                                        <source height='350' width='100%'
                                                            src={URL.createObjectURL(imageStoryUrl ? imageStoryUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                            type="video/mp4"
                                                        />
                                                    </video> : <img style={{ backgroundColor: 'c4c4c4' }} alt='' height='350' width='100%' />}
                                                </div>
                                                <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                    <h4 style={{
                                                        fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}>{heading}</h4>

                                                    {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: '26px',
                                                        left: '1px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '100%',
                                                    }}>
                                                        <p style={{
                                                            fontSize: '10px',
                                                            marginLeft: '3px',
                                                            color: 'white',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}>{subHeading}</p></div>
                                                </div>
                                            </div>
                                            <div className="Why-this-ad d-flex justify-content-between m-1">
                                                <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </Col>&nbsp;&nbsp;
                            {/*==================Shotz=================== */}
                            {/* onClick={() => { setAccord(8) }} */}
                            <Col xs='4' xl='4' >
                                <h3 className='p-heading ml-1'>Story</h3>
                                <div className='small-bg-mains'>
                                    <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                        <div className="post-wrapper col-grid-box">
                                            <div className="post-title d-flex" style={{ margin: '2px' }}>
                                                <div className="profile">
                                                    <div className="media d-flex mb-1" >
                                                        <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                            data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                            <img src="/assets/images/image (1).png"
                                                                className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                        </a>
                                                        <div className="media-body ml-2">
                                                            <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                            <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <MoreVertical size={15} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-details">
                                                <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                                    <div style={{ position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                                                        {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                                    </div>
                                                    {imageStoryUrl ? <video width="100%" height="350" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={videoRef}
                                                        autoPlay
                                                        onClick={togglePlay}>
                                                        <source height='350' width='100%'
                                                            src={URL.createObjectURL(imageStoryUrl ? imageStoryUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                            type="video/mp4"
                                                        />
                                                    </video> : <img style={{ backgroundColor: 'c4c4c4' }} alt='' height='350' width='100%' />}
                                                </div>
                                                <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                                    <h4 style={{
                                                        fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}>{heading}</h4>

                                                    {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: '26px',
                                                        left: '1px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '100%',
                                                    }}>
                                                        <p style={{
                                                            fontSize: '10px',
                                                            marginLeft: '3px',
                                                            color: 'white',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        }}>{subHeading}</p></div>
                                                </div>
                                            </div>
                                            <div className="Why-this-ad d-flex justify-content-between m-1">
                                                <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                                <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }
    return (
        <>
            <div className="col-lg-12">
                <Previewmodal />
                <Row className='rowForrevert'>
                    <Col xl='6'>
                        <div className="">
                            <img src="/assets/images/adIcon/preview-logo.png" alt="logo" />
                        </div>
                    </Col>
                    <Col xl='6'>
                        <div className='d-flex justify-content-end'>
                            <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                                {centeredModal ? <Minimize2 size={15} /> : <Maximize2 size={15} />}
                                <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview'}</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/* <div className="mobile-frame-full-video">
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="d-flex m-1">
                            <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                            <div className="ml-1">
                                <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                            </div>
                        </div>
                        <div className="ml-1">
                            <MoreVertical size={10} className='m-0' />
                        </div>
                    </div>
                    <div className="video-container-full-screen">
                        <img
                            src="https://via.placeholder.com/600x900.png"
                            alt="Placeholder"
                            className="video-thumbnail-full-screen"
                        />
                        <div className="story-bar">
                            <div className="story-progress"></div>
                        </div>
                    </div>
                    <div className="p-1 w-100">
                        <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                            <b>
                                An adv is the promotion of a product, brand
                            </b>
                        </h6>
                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                            <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                            <Send size={10} />
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    {/* ====== second new Shotz ===== */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Shotz</h3>
                        <div className='small-bg-mains'>
                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                    <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: '50px', width: '50px', borderRadius: '50px' }}>
                                                {isShotzPlay ? <Pause onClick={togglePlayShotz} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlayShotz} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                            </div>
                                            {imageStoryUrl ? <video width="100%" height="300" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={ShotzvideoRef}
                                                // autoPlay
                                                onClick={togglePlayShotz}>
                                                <source height='300' width='100%'
                                                    src={URL.createObjectURL(imageStoryUrl ? imageStoryUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                    type="video/mp4"
                                                />
                                            </video> : <img style={{ backgroundColor: 'c4c4c4' }} alt='' height='300' width='100%' />}
                                        </div>
                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                            <h4 style={{
                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{heading}</h4>

                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '26px',
                                                left: '1px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '100%',
                                            }}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    marginLeft: '3px',
                                                    color: 'white',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>{subHeading}</p></div>
                                        </div>
                                    </div>
                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* =========Story========= */}
                    <div className="col-lg-6 small-padding-fake" onClick={() => { setAccord(2) }}>
                        <h3 className='p-heading'>Story</h3>
                        <div className='small-bg-mains'>
                            <div className='post-panel-new' style={{ background: '#c4c4c4' }}>
                                <div className="post-wrapper col-grid-box">
                                    <div className="post-title d-flex" style={{ margin: '2px' }}>
                                        <div className="profile">
                                            <div className="media d-flex mb-1" >
                                                <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya eliza" data-img="/assets/images/image (1).png">
                                                    <img src="/assets/images/image (1).png"
                                                        className="img-fluid bg-img" alt="user" style={{ width: '40px' }} />
                                                </a>
                                                <div className="media-body ml-2">
                                                    <h6 className='small-p-heading ' style={{ textWrap: 'nowrap', }}>Food Market Zone</h6>
                                                    <h6><span className="color-white sponsored-text">Sponsored</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                <MoreVertical size={15} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="recomandation-display-block" style={{ position: 'relative' }}>
                                            <div style={{ position: 'absolute', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: '50px', width: '50px', borderRadius: '50px' }}>
                                                {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                                            </div>

                                            {imageStoryUrl ? <video width="100%" height="300" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }} ref={videoRef}
                                                // autoPlay
                                                onClick={togglePlay}>
                                                <source height='300' width='100%'
                                                    src={URL.createObjectURL(imageStoryUrl ? imageStoryUrl : `https://web-realcom.com/assets/upload/img/video-default.png`)}
                                                    type="video/mp4"
                                                />
                                            </video> : <img style={{ backgroundColor: 'c4c4c4' }} alt='' height='300' width='100%' />}
                                        </div>
                                        <div className="recom-btn-cont-blks new-recom-btn-cont-blk" style={{ position: 'absolute' }}>
                                            <h4 style={{
                                                fontSize: '10px', textWrap: 'nowrap', marginLeft: '-9px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>{heading}</h4>

                                            {/* <a href="#" className="btn btn-outline">KNOW MORE</a> */}
                                            <div style={{
                                                position: 'absolute',
                                                top: '26px',
                                                left: '1px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '100%',
                                            }}>
                                                <p style={{
                                                    fontSize: '10px',
                                                    marginLeft: '3px',
                                                    color: 'white',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>{subHeading}</p></div>
                                        </div>
                                    </div>
                                    <div className="Why-this-ad d-flex justify-content-between m-1">
                                        <p className='mt-2' style={{ fontSize: '10px' }}>Why this Ad?</p>
                                        <img src="/assets/images/adIcon/Share-icon.png" alt="" style={{ width: '30px' }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FullScreenVideoAd