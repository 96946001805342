import { Checkbox } from '@mui/material';
import React, { useState } from 'react'
import { ChevronsRight, Circle, Folder, Grid, Maximize2, Minimize2, MoreHorizontal } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import AdmanagerHeaderR from '../../AdmanagerHeaderR/AdmanagerHeaderR'
import PreviewMoreSection from './Sections/PreviewMoreSection';
import PreviewsSection from './Sections/PreviewsSection';
import PaymentSuccess from '../../../Ad-Manager/AdmanagerTables/PaymentSuccess';
import SavePayment from '../../../Ad-Manager/AdmanagerTables/SavePayment';

const AdsPreviewScreen = () => {
  // toggle section state
  const navigate = useNavigate()
  const [morePreview, setMorePreview] = useState(false);
  const [cliked, setUnclicked] = useState(false)
  const handleButtonClick = () => {
    // Add your logic for button click here, e.g., navigating to the next page
    window.history.back();
    window.history.back();
    window.history.back();
  };
  const [PaymentSuccessModal, setPaymentSuccessModal] = useState(false)
  const togglePaymentConfirmation = () => {

    setPaymentSuccessModal((prevState) => !prevState)
  }
  const [PaymentSaveModal, setPaymentSaveModal] = useState(false)
  const togglePaymentSave = () => {

    setPaymentSaveModal((prevState) => !prevState)
  }
  const [centeredModal, setCenteredModal] = useState(false)
  return (
    <>
      <div className=" rowForreverts">
        <Row>
          <Col xl='2' >
            <Card className='d-none d-sm-block border-right'>
              {/* min-vh-100  */}
              <div className="p-4 text-muted border ">
                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
                {/* <div className='text-dark'><p>Choose Campaign Type (e.g., Display, Video, App, etc.)</p></div> */}
              </div>
              <div disabled className="p-4 border text-muted">
                <div className='pb-1'><h6>Step 2</h6></div>
                <div className='text-dark'><h5><b>Select Ad Format</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted ">
                <div className='pb-1'><h6>Step 3</h6></div>
                <div className='text-dark pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted ">
                <div className='pb-1'><h6>Step 4</h6></div>
                <div className='text-dark pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted ">
                <div className='text-dark pb-1'><h6>Step 5</h6></div>
                <div className='text-dark'><h5><b>Select Ad Budget & Duration</b></h5></div>
              </div>
              <div disabled className="p-4 borderLeftForDasboard ">
                <div className='pb-1'><h6>Step 6</h6></div>
                <div className='text-dark'><h5><b>Preview Ad & Launch</b></h5></div>
              </div>
            </Card>
          </Col>
          <Col xl='10' className='px-2'>
            <Row className='rowForrevert'>
              {/*====================header=================== */}
              <Col xl='12' xs='12'>
                <Card className='border-0  rounded-2'>
                  <div className="d-flex justify-content-between p-2">
                    <div className="d-flex align-items-center">
                      <h4 className="font-weight-bold" style={{ marginLeft: '16px' }}>Ad Preview</h4>
                    </div>
                    <div className="d-flex align-items-center" style={{ marginRight: '16px' }}>
                      <Button color='primary' onClick={togglePaymentSave}>Save as drafts</Button>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="ad-types-of-type-map preview-main rowForrevert">
              <Col className="p-0">
                {/*================ ad formater========== */}
                <h3 className='p-heading mt-3'>Ad Format</h3>
                <Card className="">
                <div className=' p-2 rounded-2 mt-3'>
                  <div className="ad-heading-upsd-title-input in-between ml-2 ">
                    <p>Configure Your Ad</p>
                    <button className='d-block btn btn-primary transparent-btn' onClick={handleButtonClick} style={{fontWeight:'500'}}>Edit</button>
                  </div>
                  <div className="d-flex align-items-start py-2 ml-2">
                    <ChevronsRight size={18} color='#81C14B' />
                    <div className='w-100 ml-1'>
                      <div className="d-flex justify-content-between">
                        <h5>Ad Heading</h5>
                        <h5>Max 48 Characters</h5>
                      </div>
                      <div className="d-flex justify-content-between py-2">
                        <h5>Ad Sub-Heading</h5>
                        <h5>Max 60 Characters</h5>
                      </div>
                    </div>
                  </div>
                </div>
                </Card>
                <h3 className='p-heading mt-3'>Targeted Audience</h3>
                <Card className=" mt-3">
                  {/* shadow-sm */}
                  <CardBody>
                    <div className="main-left">
                      <div className="ad-heading-upsd-title-input in-between">
                        <p>Target Gender</p>
                        <button onClick={() => navigate('/Ad/ChooseAudience')} className='d-block btn btn-primary transparent-btn' style={{fontWeight:'500'}}>Edit</button>
                      </div>
                      <div className="preview-data">
                        <div>
                          <ChevronsRight size={18} color='#81C14B' />
                          <span>All <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span>Male <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span>Female <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span>Others <span className='bg-bullet ml-2 mr-2'></span></span>
                        </div>
                      </div>
                    </div>
                    <div className="main-left">
                      <div className="ad-heading-upsd-title-input in-between">
                        <p>Age Range</p>
                        {/* <button onClick={() => navigate('/Ad/ChooseAudience')} className='d-block btn btn-primary transparent-btn'>Edit</button> */}
                      </div>
                      <div className="preview-data">
                        <div>
                          <ChevronsRight size={18} color='#81C14B' />
                          <span>18 years to 26 years</span>
                        </div>
                      </div>
                    </div>
                    <div className="main-left">
                      <div className="ad-heading-upsd-title-input in-between">
                        <p>Targeted Profession</p>
                        {/* <button onClick={() => navigate('/Ad/ChooseAudience')} className='d-block btn btn-primary transparent-btn'>Edit</button> */}
                      </div>
                      <div className="preview-data">
                        <div>
                          <ChevronsRight size={18} color='#81C14B' />
                          <span>Doctors  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span>Engineer  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span>Advocate  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span>Students<span className='bg-bullet ml-2 mr-2'></span></span>
                        </div>
                      </div>
                    </div>
                    <div className="main-left">
                      <div className="ad-heading-upsd-title-input in-between ">
                        <p>Targeted Interests</p>
                        {/* <button onClick={() => navigate('/Ad/ChooseAudience')} className='d-block btn btn-primary transparent-btn'>Edit</button> */}
                      </div>
                      <div className="preview-data">
                        <div>
                          <ChevronsRight size={18} color='#81C14B' />
                          <span>Food  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Singing  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Music  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Acting  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Dancing  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Football<span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Cricket  <span className='bg-bullet ml-2 mr-2'>●</span></span>
                          <span> Shooting <span className='bg-bullet ml-2 mr-2'></span></span>
                        </div>
                      </div>
                    </div>
                    <div className="main-left">
                      <div className="ad-heading-upsd-title-input in-between">
                        <p>Target Locations</p>
                        {/* <button onClick={() => navigate('/Ad/ChooseAudience')} className='d-block btn btn-primary transparent-btn'>Edit</button> */}
                      </div>
                      <div className="preview-data">
                        <div>
                          <ChevronsRight size={18} color='#81C14B' />
                          <span>Lucknow, Uttar Pradesh, Sarojini Naidu Marg, B...</span>
                        </div>
                        <div>
                          <ChevronsRight size={18} color='#81C14B' />
                          <span>132, My Street, Kingston, New York 12401...</span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <h3 className='p-heading mt-3'>Billing Detail</h3>
                <div className="preview-left-end  mt-3">
                  <div className="">
                    <div className="preview-data">
                      <div>
                        <div className="ad-heading-upsd-title-input in-between">
                          <p>Ad Duration</p>
                          <button onClick={() => navigate('/Ad/TotalBudget')} className='d-block btn btn-primary transparent-btn' style={{fontWeight:'500'}}>Edit</button>
                        </div>
                        <div className="d-flex align-items-start py-2">
                          <ChevronsRight size={18} color='#81C14B' />
                          <div className='w-100 ml-1'>
                            <div className="d-flex justify-content-between">
                              <h5>Start Date</h5>
                              <h5>4 Feb, 2021 </h5>
                            </div>
                            <div className="d-flex justify-content-between py-2">
                              <h5>End Date</h5>
                              <h5>28 Feb, 2021</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='border-top'>
                        <div className="ad-heading-upsd-title-input in-between">
                          <p>Amount</p>
                          {/* <button onClick={() => navigate('/Ad/ChooseAudience')} className='d-block btn btn-primary transparent-btn'>Edit</button> */}
                        </div>
                        <div className="d-flex">
                          <ChevronsRight size={18} color='#81C14B' />
                          <div className='w-100 ml-1'>
                            <div className="d-flex justify-content-between">
                              <h5>Your daily budget</h5>
                              <h5><b>$20</b></h5>
                            </div>
                            <div className="d-flex justify-content-between py-2">
                              <h5>Total Amount to be paid</h5>
                              <h5><b>$5000</b></h5>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className={cliked ? 'total-paid-ad-after-selected' : 'total-paid-ad'}>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex justify-content-between mb-2 align-items-center' style={{ marginLeft: '-14px' }}>
                          <Checkbox
                            className='Check_Input_section mr-2'
                            onClick={() => setUnclicked(!cliked)}
                          />
                          {/* <input onClick={() => setUnclicked(!cliked)} type="checkbox" className='mr-2' name="" id="" /> */}
                          <h4>Choose available Balance</h4>
                        </div>
                        <h3 className='mt-2' style={{marginRight:'-15px'}}>$ 200</h3>
                      </div>
                      <div>
                        <h3>Add $ 980 more credit to pay for your Ad</h3>
                      </div>
                    </div>
                    {/* /Ad/AdPreviewMore */}
                    <Link to="" >
                      <button className="budget-btn btn Publish-btn" onClick={togglePaymentConfirmation}>
                        {/* Pay € 980 and Publish Ad */}
                        Pay $ 980 and Publish Ad
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col className="preview-left pad-zero">
                <h3 className='p-heading'>Preview </h3>
                <div>
                  {/* {morePreview ? (
                    <PreviewMoreSection setMorePreview={setMorePreview} />
                  ) : (
                    //================= POST MORE PREVIEW=============
                    <PreviewsSection setMorePreview={setMorePreview} />
                  )} */}
                  {morePreview ? (
                    <> </>
                  ) : (
                    //================= POST MORE PREVIEW=============
                    <>
                      {/* <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                      <Minimize2 size={15} /> : <Maximize2 size={15} />
                      <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview1'}</span>
                    </Button> */}
                    </>
                  )}
                  <PreviewsSection setMorePreview={setMorePreview} onClick={() => setCenteredModal(!centeredModal)} centeredModal={centeredModal} />
                </div>
              </Col>
              <PaymentSuccess setPaymentSuccessModal={setPaymentSuccessModal} PaymentSuccessModal={PaymentSuccessModal} togglePaymentConfirmation={togglePaymentConfirmation} />
              <SavePayment setPaymentSaveModal={setPaymentSaveModal} PaymentSaveModal={PaymentSaveModal} togglePaymentSave={togglePaymentSave} />
              {/*============== POST=============== */}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AdsPreviewScreen