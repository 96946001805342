import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { CheckCircle, ChevronDown, EyeOff, MapPin, MoreHorizontal, Star, Users, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { getEventsAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction'
import { InvitationsCardData } from './EventsArray'
import DeletedEventModal from './EventsModal/DeletedEventModal'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import axios from 'axios'
import ShareEventModal from './EventsModal/ShareEventModal'
import { Pagination, Stack } from '@mui/material'
import { useNavigate,useLocation } from 'react-router-dom';

const MyInvitation = (props) => {

  
  let dispatch = useDispatch();
  
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get('id');
  const userId = searchParams.get('userId');
  const type = searchParams.get('type');

  // const getAllEvents = useSelector((state) => { return state.getEventsData });
  // const { loading: loadingEvents, error: errorEvents, result: resultEvents } = getAllEvents
  let [caption, setCaption] = useState('')
  let [locationLAT, setLocationLAT] = useState('')
  let [locationLONG, setLocationLONG] = useState('')
  let [allowComment, setAllowComment] = useState('')
  const [apiRedirect, setApiRedirect] = useState(0)
  
  const [pageSize,setPageSize]=useState(12);
  const [pageIndex,setPageIndex]=useState(0);
  const [expandedEventId, setExpandedEventId] = useState(null);

  
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  let [postId, setPostId] = useState('')
  const toggleDeleteOpen = () => {
    setDeleteOpen((prevState) => !prevState)
  }

  const postCopyLink = async (id) => {
    const copyLink = `${process.env.REACT_APP_IPURL}/MyEvent/${id}`
    navigator.clipboard.writeText(copyLink)
    sweetAlertConfirmation(`Link Copied`)
  };
  
  const hideOrUnhideIcon = () => {
    alert('API Issue')
  }
  const getPostId = (postId, caption, allowComment, locationLAT, locationLONG) => {
    setPostId(postId)
    setAllowComment(allowComment)
    setCaption(caption)
    setLocationLAT(locationLAT)
    setLocationLONG(locationLONG)
  }

  const handlePageChange = async(event, value) => {
    setPageIndex(value-1);
  };

  const toggleShareOpen = (postId, btnChange) => {
    setApiRedirect(btnChange)
     setShareOpen((prevState) => !prevState)
   }

  const settingEventAct =(e,value,postId)=>{
    e.stopPropagation();
    // setValue(value);
  
    const fetchData = async () => {
          try {
              let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                  const response = await axios.post(
                      `${process.env.REACT_APP_IPURL}/post/changeEventStatus`,
                      { status:value,id:postId},
                      { headers: { Authorization: `Bearer ${user.token}` } }
                  );
                  // setMediaAllCount(response.data.data.successResult.count);
              
          } catch (error) {
            console.log(error)
          }
     };
  
  fetchData();

  props.setRefresh(true)
  
  sweetAlertConfirmation("Successfully updated")
  
  }

  const navigateToSomewhere =(e,id)=>{
    e.stopPropagation();
    e.preventDefault();
    navigate(`/MyPostDetail/${id}`,{ state: { userId, type:"viewer" }})
  }

  return (
    <Fragment>
      <div className="container-fluid section-t-space px-0">
        <div className="group-single-list-block">
          <Row className='mt-3'>
            {
              props?.data && props.data.length > 0 ? (
              props?.data?.map((event) => (
                <Col xl="3" className="mb-4" >
                  <div className="Event-box">
                    <div className="Event-img-sublink">
                      <EyeOff color='#FFFFFF' size={20} 
                      onClick={hideOrUnhideIcon}
                      style={{cursor:'pointer'}}
                      />
                    </div>
                    <img src={event.eventCoverImageUrl} className="img-fluid event-img" onClick={(e)=>navigateToSomewhere(e,event?.postId)}/>
                    <div className='Event-FirstSection' >
                      <div className='Event-SecndSection'>
                      <small>{event?.eventShowDate}  {event?.eventStartTime?.slice(0,4) }<br />{event?.eventStartTime?.slice(11,16) } to {event?.eventEndTime?.slice(11,16)}</small>
                        <div className='stng_btn text-center'>
                          <MoreHorizontal color='#808080' size={16} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => getPostId(event.postId, event.eventDescription, true, event.eventLatitude, event.eventLongitude)} />

                          <div className="dropdown-menu dropdown-menu-bottom custom-dropdown drop-menu-gal events_Option" style={{ width: '200px' /* Set the desired width */ }}>
                            <ul>
                              {/* <li>
                                <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg> Hide post from timeline</Link>
                              </li> */}
                                <li 
                              onClick={() => {toggleShareOpen(event.postId, 0)}}
                              >
                                <Link to="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg> Invite</Link>
                              </li>
                              <li>
                                <Link onClick={() => {postCopyLink(event?.postId) }}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg> Copy link</Link>
                              </li>

                              <li onClick={toggleDeleteOpen} >
                                <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete </Link>
                              </li>
                              <li >
                                <Link onClick={() => {toggleShareOpen(event.postId,1)}}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg> Share</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <h4 className='pt-3' onClick={(e) => navigateToSomewhere(e, event?.postId)}>
                      {event?.eventDescription
                        ? event.eventDescription.length >= 20
                          ? `${event.eventDescription.substring(0, 20)} ...`
                          : event.eventDescription
                        : 'No description available'}
                      </h4>
                      <div className='Event-bottom-section' onClick={(e)=>navigateToSomewhere(e,event?.postId)}>
                        <p>
                          <MapPin color='#808080' size={15} />{' '}
                          {expandedEventId === event.postId 
                          ? 
                          event.eventAddress 
                          : 
                          event.eventAddress.substring(0, 20)}
                          {event.eventAddress.length >= 20 && (
                          <span style={{ cursor: 'pointer', color: '#81c14b', marginLeft: '4px', fontSize: '12px' }}
                          >
                            {expandedEventId === event.postId ? ' less ' : ' more '}
                          </span>
                          )}
                        </p>
                      </div>
                      <div className='Invitation_Button'>
                        <UncontrolledButtonDropdown className='UncontrolledButtonDropdown '>
                          <DropdownToggle color='flat' >
                            <p>Select &nbsp;<ChevronDown size={20} color="#FFFFFF" /> </p>
                          </DropdownToggle>
                          <DropdownMenu className="Going_Menu">
                            <DropdownItem className="Option_going_btn" onClick={(e)=>settingEventAct(e,"going",event.postId)}>
                              <CheckCircle size={20} />&nbsp; Going
                            </DropdownItem>
                            <DropdownItem className="Option_going_btn" onClick={(e)=>settingEventAct(e,"interested",event.postId)}>
                              <Star size={20} />&nbsp;Interested
                            </DropdownItem >
                            <DropdownItem className="Option_going_btn" onClick={(e)=>settingEventAct(e,"notInterested",event.postId)}>
                              <X size={20} />Not Interested</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>



                    </div>
                  </div>
                </Col>
              ))):(
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <h3>No scheduled events</h3>
                </div>
              )
            }

          </Row>
        </div>
      </div>
      <div className='d-flex justify-content-center'>
                    <Pagination
                    count={props?.data?.length/12+1} 
                    page={pageIndex + 1}
                    onChange={handlePageChange}
                    className="PaginationCounts"
                    />
                    </div>
      <DeletedEventModal
        isOpen={deleteOpen}
        updateOpen={toggleDeleteOpen}
      />

      <ShareEventModal
        isOpen={shareOpen}
        updateOpen={toggleShareOpen}
        postId={postId}
        caption={caption} 
        allowComment={allowComment} 
        locationLAT={locationLAT}
        locationLONG={locationLONG}
        apiRedirect = {apiRedirect}
        
      />
    </Fragment>
  )
}

export default MyInvitation
