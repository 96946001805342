import React, { useState } from 'react'
import { AlertTriangle, Edit, Eye, Filter, Link, MessageCircle, MoreHorizontal, Send, Share2, Trash2 } from 'react-feather'
import { Button, Card, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import Pollimg from '../../NewComponents/IMG/roundedimg2.svg'
import Pollimg1 from '../../NewComponents/IMG/roundedimg3.svg'
import Frame2 from '../../NewComponents/IMG/Frame2.png'
import Frame3 from '../../NewComponents/IMG/Frame3.png'
import Frame4 from '../../NewComponents/IMG/Frame4.png'
import Threat1 from '../../NewComponents/IMG/Threat1.png'
import Threat2 from '../../NewComponents/IMG/Threat2.png'
import Threat3 from '../../NewComponents/IMG/Threat3.png'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal';
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'
const ThreatTabs = () => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const Threatdata = [
        {
            img: Pollimg,
            Threat: Threat3,
            title1: '#trendingtitle',
            title2: '#2ammotivation',
            title3: '#trendinghashtag',
            ThreatThistabs: 'ThreatThistabs1',
            Alartthispagetext: 'Alartthispagetext1',
            Mild: '#Mild',
            ThreatThisText: 'ThreatThisText1',
            ThreatThisMild: 'ThreatThisMild1'
        },
        {
            img: Pollimg1,
            Threat: Threat1,
            title1: '#trendingtitle',
            title2: '#2ammotivation',
            title3: '#trendinghashtag',
            ThreatThistabs: 'ThreatThistabs2',
            Alartthispagetext: 'Alartthispagetext2',
            Mild: '#Moderate',
            ThreatThisText: 'ThreatThisText2',
            ThreatThisMild: 'ThreatThisMild2'
        },
        {
            ThreatThistabs: 'ThreatThistabs3',
            img: Pollimg,
            Threat: Threat2,
            Alartthispagetext: 'Alartthispagetext3',
            Mild: '#Severe',
            ThreatThisText: 'ThreatThisText3',
            ThreatThisMild: 'ThreatThisMild3'
        },
        {
            img: Pollimg1,
            Threat: Threat1,
            title1: '#trendingtitle',
            title2: '#2ammotivation',
            title3: '#trendinghashtag',
            ThreatThistabs: 'ThreatThistabs2',
            Alartthispagetext: 'Alartthispagetext2',
            Mild: '#Moderate',
            ThreatThisText: 'ThreatThisText2',
            ThreatThisMild: 'ThreatThisMild2'
        },


    ]
    return (
        <div>
            <Card className='p-2 border-0'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col sm-6>
                        <div className='d-flex mt-2 ml-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Floyd Miles”</p>
                        </div>
                    </Col>
                    <Col sm-6>
                        <div className='d-flex justify-content-end p-2'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='AllTagsThis'>
                    {
                        Threatdata.map((data, index) => {
                            return (
                                <Col md='6' lg='3' sm='6'>
                                    <div className='p-2  mt-2 '>
                                        <Card className='h-100 shadow-lg'>
                                            <CardHeader className='p-2'
                                                avatar={
                                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                        <img src={data.img} />
                                                    </Avatar>

                                                }
                                                action={
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle className='' color='flat' >
                                                            <MoreHorizontal size={19} color='#00000040' />
                                                        </DropdownToggle>
                                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                }
                                                title="Floyd Miles "
                                                subheader="19 sept at 07:00 pm"
                                            />
                                            <div className='d-flex justify-content-start ml-2'>
                                                <p className='trendingtitle'>{data.title1}</p>
                                            </div>

                                            <div className='p-2'>
                                                <div className={data.ThreatThistabs}>
                                                    <p className={data.Alartthispagetext}>"🌧🌧 Water level rises thrice this month by 4 cm. Beware of the consequences. 🌧🌧"</p>
                                                </div>
                                                <div className={data.ThreatThisText}>
                                                    <p className={data.ThreatThisMild}>{data.Mild}</p>
                                                </div>
                                                <div className='d-flex justify-content-around divcolor w-100'>
                                                    <p className='d-flex  align-items-center'><img src={Frame2} /><p className='posteds align-items-center'>{numFormatter(2500)}</p></p>
                                                    <p className='d-flex  align-items-center'><img src={Frame3} /><p className='posteds align-items-center'>{numFormatter(3500)}</p></p>
                                                    <p className='d-flex  align-items-center'><img src={Frame4} /><p className='posteds align-items-center'>{numFormatter(4500)}</p></p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Card>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Review</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Threat
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h4>Withim (Km)</h4>
                            <h4>8821 Km</h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className='w-100'>
                                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                    <MatSlider
                                        aria-label="Volume"
                                        defaultValue={100}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                            '& .MuiSlider-track': {
                                                border: 'none',
                                            },
                                            '& .MuiSlider-thumb': {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#81C14B',
                                                '&:before': {
                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                    boxShadow: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div className='d-flex align-items-center ml-3' role='button'>
                                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                            </div>
                        </div>
                        <div className='border-top border-bottom mt-2'>
                            <h4 className='mt-2'>Choose locations</h4>
                            <ChipBoxLocation />
                        </div>
                        <h4 className='mt-3'>Choose Alert Type</h4>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Severe</p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Moderate</p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Mild</p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default ThreatTabs