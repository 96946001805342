import { Stack, Pagination, Checkbox } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import {
  Eye,
  MessageCircle,
  Download,
  Send,
  Trash2,
  EyeOff,
  Link2,
  Sliders,
  Navigation,
  Loader,
} from "react-feather";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Header from "../Header";
import LeftSidebar from "../LeftSidebar";
import RightSidebar from "../RightSidebar";
import ProfileCover from "./ProfileCover";
import ProfileMenu from "./ProfileMenu";
import { useSelector, useDispatch } from "react-redux";
import { getUsersShotzData } from "../../Services/Actions/UserProfile/getAllUsersShotzAction";
import {
  postShowAction,
  hideAllPostsFromTimelineAction,
  deletePostAction,
  shareShotzAction,
  getUserFollowerAction,
  postReShareAction,
} from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import { CheckCircle, Play } from 'react-feather';
import { Fragment } from "react";
import ViewProfile from "./Img/ViewProfile.png";
import MediaModal from "./MediaModal";
import FilterImg from "./Img/Fltr.svg";
import FlwrImg from "./Img/FlwrImg.png";
import Like from "./Img/likesvg.svg";
import ShotzPlay from "./Img/ShotzPlay.svg";
import { sweetAlertConfirmation } from "../group-components/AllGroupsSections/SweetAlert";
import { SweetErrorAlert } from "../group-components/AllGroupsSections/SweetErrorAlert";
import swal from "sweetalert";
import JSZip from "jszip";
import { useUserAuth } from "../../Context/userAuthContext";
import { ShotzCarouselComponents } from "../Shotzs/shotz_components/ShotzCarouselComponents";
import { getAllCommentsByPostIdAction } from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import debounce from 'lodash/debounce';
import { MyShotzDisplayModal } from '../profile-components/MyShotzDisplayModal'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";


const MyShotz = () => {
  const dispatch = useDispatch();
  const userType = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));

  let [searchShotzModal, setSearchShotzModal] = useState("");
  let [searchShotz, setSearchShotz] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pageSize, setpageSize] = useState(12);
  const [pageIndex, setPageIndex] = useState(0);
  const [paginationCount, setPaginationCount] = useState(10);
  const [isSelectActive, setIsSelectActive] = useState(false);
  const [selectedShotz, setSelectedShotz] = useState([]);
  let [copyLink, setCopyLink] = useState("");
  let [postId, setPostId] = useState("");
  const [currentPostId,setCurrentPostId]=useState("");
  const [mediaModal, setMediaModal] = useState(false);
  const { userShotz, loading } = useSelector((state) => state.userShotzData);

  const deleteResponse = useSelector((state) => {
    return state.deletePostData;
  });
  const {
    loading: loadingPosts,
    error: errorPosts,
    result: postDeleteResponse,
  } = deleteResponse;

  // console.log("userShotz======================>",userShotz)

  const handleCheckboxToggle = (e, postId) => {
    e.stopPropagation();
    e.preventDefault();
    // console.log("post ids =====================>",postId)
    setSelectedImages1(prevState => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
    setSelectAllImage(false);
  };



  const getSelectedPostIds = () => {
    return Object.keys(selectedImages1).filter(postId => selectedImages1[postId]);
  };

  const shareShotzResults = useSelector((state) => {
    return state.shareShotzData;
  });
  const { result: shareShotzResult } = shareShotzResults;

  const getFollowerConnections = useSelector((state) => {
    return state.getUserFollowerData;
  });
  const {
    loading: loadingConnections,
    error: errorConnections,
    result: resultFollwers,
  } = getFollowerConnections;

  const postReShare = useSelector((state) => {
    return state.postReShareData;
  });
  const {
    loading: loadingReSharePosts,
    error: errorReSharePosts,
    result: resultReSharePosts,
  } = postReShare;

  const [apiRoute, setApiRoute] = useState("");
  const [sortBy, setSortBy] = useState("");
  const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();
  const [showHiddenShotz, setShowHiddenShotz] = useState(null);
  const [friendsId, setFriendsId] = useState([]);
  // const [friendsId, setFriendsId] = useState(new Set());
  // console.log("resultReSharePosts", resultReSharePosts);

  useEffect(() => {
    dispatch(getUsersShotzData("shotz", pageSize, pageIndex, userId, searchKey));
  }, [pageIndex, pageSize, searchKey]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [shotzOpen, setShotzOpen] = useState(false);
  const [btnStatus, setBtnStatus] = useState("");
  const [modalImage, setModalImage] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [type, setType] = useState('');
  const [count, setCount] = useState(0);

  const toggleshotzOpen = (e, statusChange) => {
    e.stopPropagation();
    e.preventDefault();
    setBtnStatus(statusChange);
    setShotzOpen((prevState) => !prevState);
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const toggleDeleteOpen = (e) => {
    e.stopPropagation()
    e.preventDefault();
    setDeleteOpen((prevState) => !prevState);
    setDeleteId('');
    setType('')
  };


  const toggleDeleteOpen1 = (e, id, type1) => {
    e.stopPropagation()
    e.preventDefault();
    setDeleteOpen(true);
    setDeleteId(id);
    setType(type1);
  };

  const [selectedImages1, setSelectedImages1] = useState({});

  const [successDelete, setSuccessDelete] = useState();
  const [errorCount, setErrorCount] = useState("")

  useEffect(() => {
    if (successDelete) {
      dispatch(getUsersShotzData("shotz", pageSize, pageIndex, userId, searchKey));
    }
    setSuccessDelete(false);
  }, [successDelete, postDeleteResponse]);



  const deleteShortz = (e) => {
    e.stopPropagation();
    dispatch(deletePostAction([deleteId]));
    sweetAlertConfirmation("Successfully deleted")
    setDeleteId('');
    setType('')
  };

  const deleteShortz1 = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // console.log("entered into delete======================>")
    dispatch(deletePostAction(getSelectedPostIds()));
    setSuccessDelete(true);
    setDeleteOpen(false);
    sweetAlertConfirmation("Successfully deleted")
  };

  const [downloadOpen, setDownloadOpen] = useState(false);
  const toggleDownloadOpen = (e) => {
    e.stopPropagation()
    e.preventDefault();
    setDownloadOpen((prevState) => !prevState);
  };
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      getUserFollowerAction(
        pageIndex,
        100,
        "/user/getUserFollowers",
        "userName"
      )
    );
    setShareOpen((prevState) => !prevState);
  };
  const [subHeading, setSubHeading] = useState("SocioMate");
  const getSocioMate = (e, menu) => {
    e.stopPropagation();
    e.preventDefault();
    setApiRoute("/user/getUserFollowers");
    setSortBy("userName");
    setSubHeading(menu);
    dispatch(
      getUserFollowerAction(
        pageIndex,
        100,
        "/user/getUserFollowers",
        "userName"
      )
    );
  };

  const followingMenu = (e, menu) => {
    e.stopPropagation();
    e.preventDefault();
    setApiRoute("/post/getPeopleList");
    setSortBy("Connections");
    setSubHeading(menu);
    dispatch(
      getUserFollowerAction(
        pageIndex,
        100,
        "/post/getPeopleList",
        "Connections"
      )
    );
  };

  const inviteFriends = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let updatedList = [...friendsId];
    if (e.target.checked) {
      updatedList = [...friendsId, e.target.value];
    } else {
      updatedList.splice(friendsId.indexOf(e.target.value), 1);
    }
    setFriendsId(updatedList);
  };

  // console.log("friendsId", friendsId);
  useEffect(() => {
    if (resultReSharePosts) {
      sweetAlertConfirmation(resultReSharePosts);
    }
    setFriendsId("");
  }, [resultReSharePosts]);

  const shareAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const postId = userShotz.rows[0].postId;
    const caption = userShotz.rows[0].caption;
    const allowComments = userShotz.rows[0].allowComments;
    const locationLAT = userShotz.rows[0].locationLAT;
    const locationLONG = userShotz.rows[0].locationLONG;
    setSelectAllImage(false);
    setBtnDisable("disabled");
    const frndId = resultFollwers?.map((obj) => obj.id);
    setFriendsId(frndId);
    dispatch(
      postReShareAction(
        postId,
        caption,
        frndId,
        allowComments,
        locationLAT,
        locationLONG
      )
    );
    setFriendsId("");
    setShareOpen((prevState) => !prevState);
  };

  const shareToSelected = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const postId = userShotz.rows[0].postId;
    const caption = userShotz.rows[0].caption;
    const allowComments = userShotz.rows[0].allowComments;
    const locationLAT = userShotz.rows[0].locationLAT;
    const locationLONG = userShotz.rows[0].locationLONG;
    dispatch(
      postReShareAction(
        postId,
        caption,
        friendsId,
        allowComments,
        locationLAT,
        locationLONG
      )
    );
    setShareOpen((prevState) => !prevState);
  };

  let [isAllHidden, setIsAllHidden] = useState();

  let [btnDisable, setBtnDisable] = useState("disabled");
  const [selectAllImage, setSelectAllImage] = useState();
  const [success, setSuccess] = useState(false);
  const [isTabOpen, setIsTabOpen] = useState(false)

  useEffect(() => {
    if (success === true) {
      dispatch(getUsersShotzData("shotz", pageSize, pageIndex, userId, searchKey));
    }
    setSuccess(false);
  }, [success]);

  const hidePost = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(hideAllPostsFromTimelineAction(id));
    setSuccess(true);
  };

  const showHiddenPost = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(postShowAction(id));
    setSuccess(true);
  };


  const savePostId = (e, id) => {
    e.stopPropagation();
    setPostId(id);
  };

  const getAllCommentsByUserId = useSelector((state) => {
    return state.getAllCommentsByPostIdData;
  });
  const {
    loading: loadingAllComments,
    error: errorAllComments,
    result: AllComments,
  } = getAllCommentsByUserId;

  const postCopyLink = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    copyLink = `${process.env.REACT_APP_IPURL}/MyShotz/${id}`;
    setCopyLink(copyLink);
    navigator.clipboard.writeText(copyLink);
    sweetAlertConfirmation(`Link Copied`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        if (user) {
          const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
            { postType: "shotz", pageSize: 5, pageIndex: 0, searchKey: '', userId: userId },
            { headers: { Authorization: `Bearer ${user.token}` } }
          );
          setCount(response.data.data.successResult.count);
        } else {
          setErrorCount('no user found');
        }
      } catch (error) {
        setErrorCount(error);
      }
    };

    fetchData();
  }, []);

  const filteredShotzPosts = userShotz?.rows?.filter((data) => {
    if (data.hidden === showHiddenShotz) {
      return false;
    }
    if (
      searchShotz &&
      !data.caption?.toLowerCase().includes(searchShotz.toLowerCase())
    ) {
      return false;
    }
    return true;
  });


  const shareShotz = async (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(shareShotzAction(id));
    sweetAlertConfirmation("Shared Shotz Success");
  };
  const [hiddenPostIds, setHiddenPostIds] = useState([]);
  const [showedPostIds, setShowedPostIds] = useState([]);

  const selectAll = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const updatedSelectedImages = {};
    filteredShotzPosts.forEach(item => {
      updatedSelectedImages[item.postId] = true;
    });
    setSelectedImages1(updatedSelectedImages);
    // setSelectAllStatus(true);
    setSelectAllImage(true);
  };

  const unSelectAll = (e) => {
    e.stopPropagation();
    const updatedSelectedImages = {};
    filteredShotzPosts.forEach(item => {
      updatedSelectedImages[item.postId] = false;
    });
    setSelectedImages1(updatedSelectedImages);
    setSelectAllImage(false);
  };

  const showHideAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (btnStatus === "hide") {
      dispatch(hideAllPostsFromTimelineAction(hiddenPostIds));
      setSuccess(true);
      setShotzOpen((prevState) => !prevState)(
        sweetAlertConfirmation("Your all posts hide from the timeline")
      );
    } else if (btnStatus === "show") {
      dispatch(postShowAction(showedPostIds));
      setSuccess(true);
      setShotzOpen((prevState) => !prevState)(
        sweetAlertConfirmation("All selected posts are visible")
      );
    }
  };

  useEffect(() => {
    const allPostsIconChange = userShotz?.rows?.filter(
      (post) => post.hidden === 1
    );
    if (allPostsIconChange?.length === userShotz?.count) {
      setIsAllHidden(true);
    } else {
      setIsAllHidden(false);
    }
  }, [isAllHidden, userShotz]);

  const forSelectAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setBtnDisable("");
    setIsSelectActive(!isSelectActive);
    setBtnDisable("disabled");
    // setSelectAllImage(false)
    // setFriendsId('')
  };

  const redirection = (e,id) => {
    setCurrentPostId(id)
    e.preventDefault()
    setIsTabOpen(true);
  }


  const downloadFiles = async (e) => {
    // console.log("clieckfk on mdfs================?")
    e.stopPropagation();
    swal({
      title: "Good job!",
      text: "Once it download you can see the zipped file in your task bar/download folder",
      icon: "success",
      button: "OK!",
      customClass: {
        container: "my-swal-container",
      },
    });
    setSelectAllImage(false);
    setBtnDisable("disabled");
    const selectedPostIds = getSelectedPostIds();

    const fileUrls = filteredShotzPosts
      .filter(post => selectedPostIds.includes(post.postId))  // Check if postId is in selectedPostIds
      .map(post => post.mediaList ? post.mediaList.map(media => media.fileURL) : [])
      .flat()
      .filter(url => url !== null);

    const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add each file to the zip archive
    const promises = fileUrls.map(async (url) => {
      try {
        const response = await fetch(corsProxyUrl + url);
        const blob = await response.blob();
        zip.file(url, blob);
      } catch (err) {
        console.error(`Failed to fetch ${url}: `, err);
      }
    });

    await Promise.all(promises);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    const link = document.createElement("a");
    link.href = zipUrl;
    link.download = "media.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const circleCheckboxStyle = {
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: '2px solid #ccc',
    appearance: 'none',
    WebkitAppearance: 'none',
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#f2f2f2'
  };

  const checkedCircleStyle = {
    ...circleCheckboxStyle,
    backgroundColor: '#81c14b'
  };

  const debouncedSetSearchKey = useCallback(
    debounce((value) => {
      setSearchShotz(value);
    }, 300),
    []
  );

  const handleInputChange = (value) => {
    debouncedSetSearchKey(value);
  };

  const handlePageChange = async (event, value) => {
    event.stopPropagation();
    setPageIndex(value - 1);
  };

  const BasicsPagination = () => {
    return (
      <>
        <Stack>
          <Pagination
            count={Math.ceil(count / 12)}
            page={pageIndex + 1}
            onChange={handlePageChange}
            className="PaginationCounts"
          ></Pagination>
        </Stack>
      </>
    );
  };

  console.log("shotz==================================",userShotz?.rows)

  const SectionTab = () => {
    return (
      <>
        <div className="shotz-album">
          <Row className="mt-3">
            {filteredShotzPosts && filteredShotzPosts.length > 0 ? (
              filteredShotzPosts?.map((data, index) => (

                <Col xl="3" className="mb-4">
                  <div key={index}>
                    {isSelectActive ? (
                      <>
                        <a data-bs-toggle="modal" data-bs-target="#imageModel">
                        {data?.thumbnailURL ? (
                            <img
                              onClick={(e) => handleCheckboxToggle(e, data.postId)}
                              className="gallery-img"
                              src={`${process.env.REACT_APP_IMAGEURL}${data.thumbnailURL}`}
                              alt=""
                              style={{ borderRadius: "8px", width: "100%", height: "305px" }}
                            />
                          ) : (
                            <video
                              onClick={(e) => handleCheckboxToggle(e, data.postId)}
                              className="gallery-img"
                              style={{ borderRadius: "8px", width: "100%", height: "305px" }}
                              alt="noPic"
                            >
                              <source
                                src={`${process.env.REACT_APP_IMAGEURL}${data.shotzMediaURL}`}
                                type="video/mp4"
                                alt="noPic"
                              />
                            </video>
                          )}
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: '#81C14B',
                              fontSize: '48px',
                              pointerEvents: 'none',
                            }}
                          >
                            <img src={ShotzPlay} className="img-fluid" alt='img' />
                          </div>
                        </a>
                        <div className="text-gallery">
                          <div className="top-gallery-cont">
                            <p>
                              <Eye size={16} />
                              {data?.viewsCount}
                            </p>
                            <div className='Block-btns-blk'>
                              {selectedImages1[data.postId] || selectAllImage ? (
                                <CheckCircle size={24} color="#81c14b" style={circleCheckboxStyle} />
                              ) : (
                                <input
                                  type="checkbox"
                                  style={selectedImages1[data.postId] ? checkedCircleStyle : circleCheckboxStyle}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="bottom-gallery-cont">
                          <p>
                            <img src={Like} className="iw-14 ih-14" alt="" />{" "}
                            {data?.likesCount}
                          </p>
                          <p>
                            <MessageCircle size={16} /> {data?.commentsCount}
                          </p>
                          <p>
                            <svg
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="iw-14 ih-14"
                            >
                              <line x1="22" y1="2" x2="11" y2="13"></line>
                              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                            </svg>{" "}
                            {data?.sharesCount}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <a data-bs-toggle="modal" data-bs-target="#imageModel">
                          {/* <img onClick={() => handleSelectShot(data?.postId)} className="gallery-img" src={`${process.env.REACT_APP_IPURL}${data?.shotzMediaURL}`} alt="" /> */}
                          <video
                            onClick={(e) => {
                              redirection(e,data.postId);
                            }}
                            className="gallery-img"
                            // height="305"
                            // width="100%"
                            // controls
                            style={{ borderRadius: "8px", width: "100%", height: "305px" }}
                            alt="noPic"
                          >
                            <source
                              src={data?.shotzMedia}
                              type="video/mp4"
                              alt="noPic"
                            />
                          </video>
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: '#81C14B',
                              fontSize: '48px',
                              pointerEvents: 'none',
                            }}
                          >
                            <img src={ShotzPlay} className="img-fluid" alt='img' />
                          </div>
                        </a>
                        <div className="text-gallery">
                          <div className="top-gallery-cont">
                            <p>
                              {data.hidden ? <EyeOff size={16} /> : <Eye size={16} />}
                              {data?.viewsCount}
                            </p>
                            <div className="gallery-img-sublink">

                              <a
                                href="#"
                                className="dropbtn-gal"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={(e) => savePostId(e, data.postId)}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="iw-14 ih-14"
                                >
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="12" cy="5" r="1"></circle>
                                  <circle cx="12" cy="19" r="1"></circle>
                                </svg>
                              </a>
                              <div
                                className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal media_option"
                                style={{ fontSize: "12px" }}
                              >
                                <ul>
                                  <li>
                                    {data.hidden ? (
                                      <span
                                        onClick={(e) => {
                                          showHiddenPost(e, data.postId);
                                        }}
                                      >
                                        <Eye size={15} color="#979797" /> &nbsp;&nbsp;
                                        Show post from timeline
                                      </span>
                                    ) : (
                                      <span
                                        onClick={(e) => {
                                          hidePost(e, data.postId);
                                        }}
                                      >
                                        <EyeOff size={15} color="#979797" />{" "}
                                        &nbsp;&nbsp; Hide post from timeline
                                      </span>
                                    )}
                                  </li>
                                  <li
                                    onClick={(e) => {
                                      postCopyLink(e, data?.postId);
                                    }}
                                  >
                                    <Link to="#">
                                      <Link2 size={15} color="#979797" /> &nbsp;Copy
                                      link
                                    </Link>
                                  </li>

                                  {/* <li>
                              <Link to="#">
                                <svg
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="icon-font-light iw-16 ih-16"
                                >
                                  <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                </svg>{" "}
                                {VideoData[index].edit}
                              </Link>
                            </li> */}
                                  <li onClick={(e) => toggleDeleteOpen1(e, data.postId, "particular")}>
                                    <Link to="#">
                                      <Trash2 size={15} color="#979797" />
                                      &nbsp;Delete
                                    </Link>
                                  </li>
                                  <li
                                    onClick={(e) => {
                                      shareShotz(e, data?.postId);
                                    }}
                                  >
                                    {/* <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg> {VideoData[index].share}</Link> */}
                                    <Link to="#">
                                      <Navigation size={15} color="#979797" />
                                      &nbsp;Share
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="bottom-gallery-cont">
                            <p>
                              <img src={Like} className="iw-14 ih-14" alt="" />{" "}
                              {data?.likesCount}
                            </p>
                            <p>
                              <MessageCircle size={16} /> {data?.commentsCount}
                            </p>
                            <p>
                              <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="iw-14 ih-14"
                              >
                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                              </svg>{" "}
                              {data?.sharesCount}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Col>

              ))) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h3>No Shotz Yet</h3>
              </div>
            )}
            <div className="d-flex justify-content-center">
              <BasicsPagination />
            </div>
          </Row>
        </div>
      </>
    );
  };
  

  //Main_Return_Section
  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          {/* <ProfileCover /> */}
          <ProfileMenu />
          <div className="container-fluid section-t-space px-0">
            <div className="page-content">
              <div className="content-center w-100">
                <Col className="gallery-page-section section-b-space">
                  <div className="card-title">
                    <h3>My Shotz</h3>
                    <div className="right-setting">
                      <div className="profile-search-blk ">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="search-svg"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                        <input
                          type="text"
                          className="form-control"
                          name="search"
                          placeholder="Find ..."
                          value={searchShotz}
                          onChange={(e) => { handleInputChange(e.target.value) }}
                        />
                      </div>

                      {!isSelectActive ? (
                        <>
                          {userType != "viewer" && filteredShotzPosts?.length > 0 && (
                            <>
                              <UncontrolledButtonDropdown color="secondary" outline>
                                <DropdownToggle
                                  outline
                                  className="border border-muted ml-3 "
                                >
                                  <Sliders className="Slider_Filter" size={12} />
                                  &nbsp;&nbsp;Filter
                                </DropdownToggle>
                                <DropdownMenu className="DropDown_item">
                                  {/* <DropdownItem
                             onClick={() => setShowHiddenShotz(null)}
                           >
                             All
                           </DropdownItem> */}
                                  <DropdownItem onClick={() => setShowHiddenShotz(1)}>
                                    Show all visible post
                                  </DropdownItem>
                                  <DropdownItem onClick={() => setShowHiddenShotz(0)}>
                                    Show all hidden posts
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                              <Link
                                to="#"
                                className="btn btn-outline-grey ml-3"
                                onClick={(e) => forSelectAll(e)}
                              >
                                {" "}
                                Select
                              </Link>
                            </>
                          )}

                        </>
                      ) : (
                        <>
                          <div className="mr-4 d-flex">
                            {isAllHidden ? (
                              <button
                                className={`btn btn-outline-grey ml-3 p-7 ${btnDisable ? "disabled" : ""
                                  }`}
                                onClick={(e) => {
                                  toggleshotzOpen(e, "show");
                                }}
                              >
                                {" "}
                                <Eye size={20} />
                              </button>
                            ) : (
                              <button
                                className={`btn btn-outline-grey ml-3 p-7`}
                                disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                                onClick={(e) => {
                                  toggleshotzOpen(e, "hide");
                                }}
                              >
                                {" "}
                                <EyeOff size={20} />
                              </button>
                            )}
                            <button
                              className={`btn btn-outline-grey ml-3 p-7`}
                              disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                              onClick={(e) => toggleDeleteOpen(e)}
                            >
                              {" "}
                              <Trash2 size={20} />
                            </button>
                            <button
                              className={`btn btn-outline-grey ml-3 p-7`}
                              disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                              onClick={(e) => toggleShareOpen(e)}
                            >
                              {" "}
                              <Send size={20} />
                            </button>
                            <button
                              className={`btn btn-outline-grey ml-3 p-7`}
                              disabled={!getSelectedPostIds() || getSelectedPostIds().length === 0}
                              onClick={(e) => downloadFiles(e)}
                            >
                              {" "}
                              <Download size={20} />
                            </button>
                          </div>
                          <button
                            className="btn btn-outline-primary ml-3"
                            onClick={(e) => selectAll(e)}
                          >
                            {" "}
                            Select All
                          </button>
                          <button
                            className="btn btn-outline-primary ml-3"
                            onClick={(e) => unSelectAll(e)}
                          >
                            {" "}
                            Unselect All
                          </button>
                          <button
                            onClick={(e) => {
                              setIsSelectActive(!isSelectActive);
                              setSelectedShotz([]);
                              unSelectAll(e);
                            }}
                            className="btn btn-outline-grey ml-3"
                          >
                            {" "}
                            Cancel
                          </button>
                        </>
                      )}

                      {/* <Link to="/MyMedia" className="btn btn-outline-grey ms-3"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg> Grid view</Link> */}
                      <Link to="/" className="btn btn-outline-green ms-3">Create Shots</Link>
                    </div>
                  </div>
                  <SectionTab />
                </Col>
              </div>
            </div>
          </div>
        </div>
        <div className="vertically-centered-modal">
          {shotzOpen !== false && (
            <Modal
              isOpen={shotzOpen}
              toggle={toggleshotzOpen}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center modalHeading_Section">
                <h4>Hide Shotz</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center  modalHeading_Section">
                  <p>Do you really want to hide the selected shotz Video?</p>
                </div>
                <div className="d-flex justify-content-center  mt-3">
                  <Button onClick={toggleshotzOpen} outline>
                    Cancel
                  </Button>
                  <Button
                    onClick={(e) => showHideAll()}
                    color="primary"
                    className="ml-3 "
                  >
                    OK
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
        <div className="vertically-centered-modal">
          {deleteOpen !== false && (
            <Modal
              isOpen={deleteOpen}
              toggle={toggleDeleteOpen}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section">
                <h4>Delete Shotz Video</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center  modalHeading_Section ">
                  <p>Do you really want to delete the selected shotz video ?</p>
                </div>
                <div className="d-flex justify-content-center  mt-4">
                  <Button onClick={toggleDeleteOpen} outline style={{ width: "100px" }}>
                    Cancel
                  </Button>
                  {type == "particular" ? (
                    <Button
                      onClick={(e) => deleteShortz(e)}
                      color="primary"
                      className="ml-3 "
                      style={{ width: "100px" }}
                    >
                      OK
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => deleteShortz1(e)}
                      color="primary"
                      className="ml-3 "
                      style={{ width: "100px" }}
                    >
                      OK
                    </Button>
                  )}

                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
        <div className="vertically-centered-modal">
          {downloadOpen !== false && (
            <Modal
              isOpen={downloadOpen}
              toggle={(e) => toggleDownloadOpen(e)}
              className="modal-dialog-centered"
            >
              <ModalHeader className="d-flex justify-content-center modalHeading_Section">
                <h4>Download Shotz</h4>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex justify-content-center  modalHeading_Section ">
                  <p>
                    Do you really want to download the selected shotz video ?
                  </p>
                </div>
                <div className="d-flex justify-content-center  mt-4">
                  <Button onClick={toggleDownloadOpen} outline>
                    Cancel
                  </Button>
                  <Button
                    onClick={toggleDownloadOpen}
                    color="primary"
                    className="ml-3 "
                  >
                    OK
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
        <div className="vertically-centered-modal">
          {shareOpen !== false && (
            <Modal
              isOpen={shareOpen}
              toggle={toggleShareOpen}
              className="modal-dialog-centered"
            >
              <div className="w-100 border-bottom">
                <div className=" mx-3 py-3  d-flex justify-content-between Heading_Invite_Section">
                  <h4>
                    Share People <small>maximum 10 selection</small>
                  </h4>
                  <span style={{ cursor: "pointer" }} onClick={(e) => shareToSelected(e)}>
                    {friendsId.length === 0 ? "" : "Done"}
                  </span>
                </div>
              </div>
              <ModalBody>
                <Row className="mt-2">
                  <Col md="8" className="Like_search">
                    <div className="profile-search-blk w-100 ">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="search-svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Search..."
                        data-bs-toggle="modal"
                        data-bs-target="#peopleSearch"
                        value={searchShotzModal}
                        onChange={(e) => setSearchShotzModal(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="d-flex justify-content-end">
                    <UncontrolledButtonDropdown color="secondary" outline>
                      <DropdownToggle
                        outline
                        className="border border-muted ml-3 "
                      >
                        <Sliders className="Slider_Filter" size={12} />
                        &nbsp;&nbsp;Filter
                      </DropdownToggle>
                      <DropdownMenu className="DropDown_Share_item">
                        <DropdownItem
                          onClick={(e) => {
                            getSocioMate(e, "SocioMate");
                          }}
                        >
                          SocioMate
                        </DropdownItem>

                        <DropdownItem
                          onClick={(e) => {
                            followingMenu(e, "Following");
                          }}
                        >
                          Following
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Col md="6" className="SubHeading_Invite_Section">
                    <h4>
                      {" "}
                      {subHeading === "SocioMate"
                        ? "Sociomate (" + resultFollwers?.length + ")"
                        : "Followers (" + resultFollwers?.length + ")"}
                    </h4>
                  </Col>
                  <Col
                    md="6"
                    className="d-flex justify-content-end SubHeading_Invite_Section"
                  >
                    <p style={{ cursor: "pointer" }} onClick={(e) => shareAll(e)}>
                      {friendsId.length > 0 ? "" : "Share All"}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  {loadingConnections ? (
                    <Loader />
                  ) : (
                    <>
                      {errorConnections ? (
                        <div>{errorConnections}</div>
                      ) : (
                        <>
                          {resultFollwers
                            ?.filter((data) =>
                              data?.fullName
                                ?.toLowerCase()
                                .includes(searchShotzModal.toLowerCase())
                            )
                            .map((data, index) => (
                              <Fragment>
                                <div className="group-detail-page-blk">
                                  <div className="Follower-memberslist-block">
                                    <div className="group-member-img-cont-blk">
                                      <img
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        src={
                                          data.profileImageThumb
                                            ? data.profileImageThumb
                                            : data.profilePhoto
                                        }
                                        alt="img"
                                        className="img-fluid"
                                      />
                                      <div className="member-cont-blk">
                                        <h4>{data.fullName}</h4>
                                        {/* <p className='text-muted'>{data.createdAt}</p> */}
                                        <div className=" Post_Count">
                                          {data.status}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Block-btns-blk">
                                      <Checkbox
                                        value={data?.id}
                                        defaultChecked={!!friendsId[data?.id]}
                                        onChange={(e) => {
                                          inviteFriends(e);
                                        }}
                                        type="checkbox"
                                        className="customcheckboxForFilter"
                                        id={data.id}
                                        style={{ cursor: "pointer" }}
                                      />

                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            ))}
                        </>
                      )}
                    </>
                  )}
                </Row>
              </ModalBody>
            </Modal>
          )}
        </div>
        {/* end_share_shotz_Modal              */}
        <RightSidebar />
        {!isSelectActive && isTabOpen && (
          <MyShotzDisplayModal isOpen={isTabOpen} data={userShotz?.rows} setIsTabOpen={setIsTabOpen} postId={currentPostId} setCurrentPostId={setCurrentPostId}/>
        )}
        <ShotzCarouselComponents
          isOpen={shotzCarouselModal}
        // ShotzModalCarousel={toggleShotzCarouselModal}
        />
      </div>
    </>
  );
};

export default MyShotz;
