import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { getSuggestedUsers } from '../../Services/Actions/UserProfile/suggestedUsersAction';
import { sendUserFollowingRequests } from '../../Services/Actions/UserProfile/userFollowingRequestsAction';

// Use for snakebar

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { Button, Card, CardBody, CardImg, CardImgOverlay, CardText, CardTitle } from 'reactstrap';
import { XCircle } from 'react-feather';
import AvatarGroup from '../../NewComponents/AvatarGroup';
import { Link } from 'react-router-dom';

const FriendSuggestion = () => {
  const FriendSuggestsettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  const dispatch = useDispatch();
  const { suggestedUsers } = useSelector(state => state.suggestedUsersData);

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ sev: 'success', content: '' });

  const requestSender = (id, isPrivate) => {
    dispatch(sendUserFollowingRequests(id, isPrivate));
    setOpen(true);
    setAlert({ sev: 'success', content: 'Request Sent Successfully !' });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getSuggestedUsers());
  }, [dispatch]);

  return (
    <div className="suggestion-box section-b-space">
      <div className="CommonHeadingSeg d-flex justify-content-between p-1">
        <h2>Suggestion </h2>
        <div className="settings">
          <div className="seeall-btn">
            <Link to="/Suggested">See All</Link>
          </div>
        </div>
      </div>
      <div className="suggestion-content ratio_115 frz-sug-slider-sec">
        <Slider {...FriendSuggestsettings} className="default-space">
          {suggestedUsers?.rows?.map((user) => (
            <Card className="shadow-none" inverse key={user.id} style={{ width: '20rem', height: '20rem' }}>
              <CardImg alt="Card image cap" className="rounded-0 img-fluid" src={user.profileImage} style={{ height: 250 }} />
              <CardImgOverlay className="text-right">
                <XCircle color="#fff" />
              </CardImgOverlay>
              <CardBody className="fdcont-blk text-center">
                <CardTitle className="text-truncate" tag="h4">
                  {user.fullName}
                </CardTitle>
                <CardText tag="h6" className="text-secondary d-flex align-items-center">
                  &nbsp;&nbsp;
                  <AvatarGroup />&nbsp;+5 Mutual followers
                </CardText>
                <div className="group-buttons-blk">
                  <a href="#" className="group-btn group-btn-new green-clr-btn">
                    Follow
                  </a>
                </div>
              </CardBody>
            </Card>
          ))}
        </Slider>
      </div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert.sev} sx={{ width: '100%' }}>
          {alert.content}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FriendSuggestion;
