import React, { Fragment } from 'react'
import { useState,useEffect, useRef } from 'react';
import { MoreVertical, Plus } from 'react-feather';
import { Button, Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane } from 'reactstrap';
import Header from '../Header';
import { Spinner } from 'react-bootstrap';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileCover from './ProfileCover';
import ProfileMenu from './ProfileMenu';
import Media1 from './Img/Media1.png'
import Mic1 from './Img/Mic1.png'
import Prfle from './Img/prfle.png'
import MicImg from './Img/MicImg.png'
import EyeImg from './Img/eye.svg'
import Cmnt from './Img/cmnt.svg'
import Share from './Img/share.svg'
import Paidsvg from './Img/Paidsvg.svg'
import FlwrImg from './Img/FlwrImg.png'
import LikeBlack from './Img/LikeBlack.svg'
import { Volume2, VolumeX } from 'react-feather';
import { Box, IconButton, Slider, Stack, useTheme,Typography } from '@mui/material';
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate,useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTextPostsAction, deletePostAction } from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { sweetAlertConfirmation } from '../group-components/AllGroupsSections/SweetAlert';



const MyPodcastDetails = () => {
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const dispatch=useDispatch();
  const location=useLocation();
  const { postType } = location.state || "podcast";
  const navigate = useNavigate();
  let { podcastId } = useParams();
  const [paused, setPaused] = useState(true);
  const [value, setValue] = useState(30);
  const [data,setData]=useState([])
  const [error,setError]=useState("");
  const [pcAnalytics,setPcAnalytics]=useState([]);
  const [pageIndex,setPageIndex]=useState(0);
  const [pageSize,setPageSize]=useState(10);
  const [searchKey,setSearchKey]=useState('');
  const [analyticsByType,setAnalyticsByType]=useState([]);
  const [successResult,setSuccessResult]=useState(false);
  const [currentTime, setCurrentTime] = useState(0); // State to track current playback time
  const [duration, setDuration] = useState(0);
  const [sliderVal,setSliderVal]=useState(0);
  const audioRef = useRef(null);
  const [newPodCastId,setNewPodcastId]=useState(podcastId);
  const [deleteOpen,setDeleteOpen]=useState(false);
  const [deleteId,setDeleteId]=useState([]);
  const [success,setSuccess]=useState(false)

  const [active, setActive] = useState('1')
  const toggle = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }

  const audioStyle = {
    width: '100%',
};

// Function to handle play/pause toggle
const togglePlayPause = (e) => {
  // e.preventDefault();
  e.stopPropagation();
  const audio = audioRef.current;
  if (audio.paused) {
    audio.play();
    setPaused(false);
  } else {
    audio.pause();
    setPaused(true);
  }
};

  const [likedOpen, setLikedOpen] = useState(false)
  const toggleLiked = async(e,type) => {
    e.preventDefault();
    e.stopPropagation();
    if(type !='close'){
      await fetchAnalyticsByType(type)
      setLikedOpen((preState) => !preState)
    }else{
      setSuccessResult(false)
    }
    if(successResult){
    setLikedOpen((preState) => !preState)
    }
  }

  const [open, setOpen] = useState(false)
  const toggleOpen = async(e,type) => {
    e.preventDefault();
    e.stopPropagation();
    if(type !='close'){
      await fetchAnalyticsByType(type)
      setOpen((preState) => !preState)
    }else{
      setSuccessResult(false)
    }
    if(successResult){
      setOpen((preState) => !preState)
    }
    
  }
  const [sharedOpen, setsharedOpen] = useState(false)
  const toggleShared = async (e,type) => {
    e.preventDefault();
    e.stopPropagation();
    if(type !='close'){
      await fetchAnalyticsByType(type)
      setsharedOpen((preState) => !preState)
    }else{
      setSuccessResult(false)
    }
    if(successResult){
    setsharedOpen((preState) => !preState)
    }
  }
  const [commentOpen, setCommentOpen] = useState(false)
  const togglePurchase = async(e,type) => {
    e.preventDefault();
    e.stopPropagation();
    if(type !='close'){
      await fetchAnalyticsByType(type)
      setCommentOpen((preState) => !preState)
    }else{
      setSuccessResult(false)
    }
    if(successResult){
    setCommentOpen((preState) => !preState)
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    dispatch(getTextPostsAction('podcast',20,0,''));
  }, [podcastId])


const getPodCastData = useSelector((state) => { return state.getTextPostsData });
const [isMuted, setIsMuted] = useState(false);
    
const { loading: loadingAllTextPosts, error: errorAllTextPosts, result: resultAllTextPosts } = getPodCastData

  useEffect(() => {
    // console.log("postType======================================",postType,podcastId)
    const fetchData = async () => {
        try {
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/podcast/getPodcastEpisodeDetails`,
                    { id:podcastId, userId:user.id},
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setData(response.data.data.successResult);
            } else {
              setError('no user found');
            }
        } catch (error) {
          setError(error);
        }
    };
  
    fetchData();

}, [postType,podcastId]);

useEffect(()=>{

    const fetchData = async () => {
      try {
          if (user) {
              const response = await axios.post(
                  `${process.env.REACT_APP_IPURL}/podcast/getSingalPcAnalytics`,
                  { pcEpisodeId:podcastId, userId:user.id},
                  { headers: { Authorization: `Bearer ${user.token}` } }
              );
              setPcAnalytics(response.data.data.successResult);
          } else {
            setError('no user found');
          }
      } catch (error) {
        setError(error);
      }
  };
  
  fetchData();


},[data,podcastId])

  const paidOrNot=data[0]?.isPaid||0;

  const fetchAnalyticsByType= async (type)=>{
    try{
      if(user){
        const response=await axios.post(
          `${process.env.REACT_APP_IPURL}/podcast/getAnalyticsByType`,
          {pcEpisodeId:podcastId,type:type,pageIndex:pageIndex,pageSize:pageSize,searchKey:searchKey},
          { headers: { Authorization: `Bearer ${user.token}` } }
        );
        setAnalyticsByType(response.data.data.successResult);
        setSuccessResult(true);
      }else{
        setError('no user found');
      }
    }catch(error){
      setError(error);
    }
  }

  const handleFastForward = () => {
    const audio = audioRef.current;
    audio.currentTime += 10; // Fast forward by 10 seconds (adjust as needed)
  };

  const handleBackward = () => {
    const audio = audioRef.current;
    audio.currentTime -= 10; // Fast forward by 10 seconds (adjust as needed)
  };

  const price=data[0]?.amount||0;

  const pocAnalytics = pcAnalytics ? [pcAnalytics] : [];


  // console.log("poca=======================================",pocAnalytics)

  const handleClick = () => {
    navigate('/Podcast');
  };

  const containerStyle = {
    width: '100%',
    maxWidth: '600px', // Set a max width for larger screens if needed
    margin: '0 auto', // Center the container
    padding: '10px',
};


const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


useEffect(() => {
  const audio = audioRef.current;
  if (audio) {
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }
}, [audioRef.current]);

const handleSeekBarChange = (e, newValue) => {
  const audio = audioRef.current;
  audio.currentTime = newValue;
  setCurrentTime(newValue);
  if (!paused) {
    audio.play();
  }
};

const deletePC=(e,id)=>{
  e.stopPropagation()
  e.preventDefault();
  setDeleteId(id);
  setDeleteOpen(true);
}

const toggleDeleteOpen=()=>{
  setDeleteId('');
  setDeleteOpen(false)
}
const deleteAllPost = async (e) => {
  e.stopPropagation();
  try {
    await dispatch(deletePostAction([deleteId]));
    setSuccess(true);
    sweetAlertConfirmation('Successfully deleted');
    setDeleteOpen((prevState) => !prevState);
    handleClick();
  } catch (error) {
   setError(error);
  }
};

const navigateToSomewhere =(e,id,postType)=>{
  e.stopPropagation();
  e.preventDefault();
  navigate(`/MyPodcastDetails/${id}`, {
    state: { postType }
});
}

// console.log("data=============================",x);

useEffect(() => {
  if (audioRef.current) {
    audioRef.current.muted = isMuted;
  }
}, [isMuted]);

const editPodcast=(e)=>{
  e.preventDefault();
  e.stopPropagation();
  sweetAlertConfirmation("Edit feature is in progress")
}

const newPodcatCreate=(e)=>{
  e.stopPropagation();
  e.preventDefault();
  navigate("/Podcast/NewPodcastSeries");
}

  const PlayPodcastCard = () => (
    <Fragment>
      <div className="card-custom-blk">
        <h3>Playing Podcast</h3>
        <div className="playingpodcast-blk">
          <img src={data[0]?.coverImageURL||process.env.DUMMY_PODCAST_COVER} alt="Podcast Cover" />
          <h4>{data[0]?.title}</h4>
          <h5>{formatDate(data[0]?.liveDate)}</h5>
          <p>{data[0]?.caption}</p>
          <div className="audio-tracker-blk">
          <div style={containerStyle}>
            <audio id="audio-cont" ref={audioRef} style={audioStyle}>
                <source src={data[0]?.mediaURL|| process.env.DUMMY_PODCAST_COVER} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
              <IconButton aria-label="previous song">
                <FastRewindRounded fontSize="small" style={{ color: "#979797" }} onClick={handleBackward} />
              </IconButton>
              <IconButton
                aria-label={paused ? 'play' : 'pause'}
                onClick={(e) => togglePlayPause(e)}
                className="center_btn_action"
                style={{ backgroundColor: "#81C14B" }}
              >
                {paused ? (
                  <PlayArrowRounded sx={{ fontSize: '2rem' }} htmlColor="#ffffff" />
                ) : (
                  <PauseRounded sx={{ fontSize: '2rem' }} htmlColor="#ffffff" />
                )}
              </IconButton>
              <IconButton aria-label="next song">
                <FastForwardRounded fontSize="small" style={{ color: "#979797" }} onClick={handleFastForward} />
              </IconButton>
            </Box>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                            <Typography>{formatTime(currentTime)}</Typography>
                            <Slider
                                aria-label="Seek Bar"
                                value={currentTime}
                                min={0}
                                max={duration}
                                onChange={handleSeekBarChange}
                                sx={{
                                color: "#81C14B",
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                '& .MuiSlider-thumb': {
                                    width: 24,
                                    height: 24,
                                    backgroundColor: '#fff',
                                    '&:before': {
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                    },
                                },
                                }}
                            />
                            <Typography>{formatTime(duration)}</Typography>
                        </Stack>
          </div>
        </div>
      </div>
      <div className="card-custom-blk">
        <h3>About the Podcast</h3>
        <p>{data[0]?.description}</p>
      </div>
    </Fragment>
  )
  
  const PodcastAnlytics = () => (
    <Fragment>
      {pocAnalytics.length>0 && pocAnalytics?.map((data,index)=>(
      <div key={index}>
      <div className="card-custom-blk">
        <h3>Podcast Anlytics </h3>
        <Row>
          <Col md="6" sm="12">
            <div className="analitics-custom-blk p-2">
              <ul className="podcast-analitics-blk">
                <li style={{ cursor: "pointer",color: data.listenerCount === 0 ? 'grey' : 'inherit' + ' !important' }} onClick={(e)=>toggleOpen(e,'listened')}  ><span>Total Listener</span> <b>{data.listenerCount}</b></li>
                <li style={{ cursor: "pointer",color: data.likesCount === 0 ? 'grey' : 'inherit' + ' !important' }} onClick={(e)=>toggleLiked(e,'liked')}><span>Total Likes</span> <b>{data.likesCount}</b></li>
                <li style={{ cursor: "pointer",color: 'grey' + ' !important'}}><span>Total Comments</span> <b>{data.commentCount}</b></li>
                <li style={{ cursor: "pointer",color: data.shareCount === 0 ? 'grey' : 'inherit' + ' !important' }} onClick={(e)=>toggleShared(e,'share')}><span>Total Share</span> <b>{data.shareCount}</b></li>
                <li style={{ cursor: "pointer",color: data.downloadCount === 0 ? 'grey' : 'inherit' + ' !important' }}><span>Total Download</span> <b>{data.downloadCount}</b></li>
                {paidOrNot?(
                  <>
                  <li style={{ cursor: "pointer",color: data.purchasesCount === 0 ? 'grey' : 'inherit' + ' !important' }} onClick={(e)=>togglePurchase(e,'paid')}><span>Total Purchases</span> <b>{data.purchasesCount}</b></li>
                  <li style={{ cursor: "pointer",color: data.totalEarning === 0 ? 'grey' : 'inherit' + ' !important' }} ><span>Total Earned</span> <b>${data.totalEarning}</b></li>
                  <li style={{ cursor: "pointer",color: price == 0 ? 'grey' : 'inherit' + ' !important' }}><span>Price</span> <span className="green font-weight-bold" >${price}</span></li>
                  </>
                ):(
                  <li style={{ cursor: "pointer" }}><span>Price</span> <span className="green font-weight-bold" >Free</span></li>
                )}
              </ul>
            </div>
          </Col>
          {data.listenerCount >0 ?(
            <Col md="6" sm="12">
            <div className="analitics-custom-blk p-2">
              <Nav className="border-bottom">
                <Col>
                  <NavItem>
                    <NavLink
                      active={active === '1'}
                      onClick={() => {
                        toggle('1')
                      }}
                      className="Poll_link"
                    >
                      Gender
                    </NavLink>
                  </NavItem>
                </Col>
                <Col>
                  <NavItem>
                    <NavLink
                      active={active === '2'}
                      onClick={() => {
                        toggle('2')
                      }}
                      className="Poll_link"
                    >
                      Age
                    </NavLink>
                  </NavItem>
                </Col>
                <Col>
                  <NavItem>
                    <NavLink
                      active={active === '3'}
                      onClick={() => {
                        toggle('3')
                      }}
                      className="Poll_link"
                    >
                      Location
                    </NavLink>
                  </NavItem>
                </Col>
              </Nav>
              <TabContent className='py-50' activeTab={active}>
              {data?.genderAnalysis?.map((item, index) => {
                    const totalCount = Object.values(item).reduce((sum, count) => sum + count, 0);

                    return (
                      <TabPane tabId='1' key={index}>
                        {Object.keys(item)
                          .filter((key) => item[key] > 0)
                          .map((key) => {
                            const count = item[key];
                            const percentage = ((count / totalCount) * 100).toFixed(2);

                            return (
                              <React.Fragment key={key}>
                                <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                  <h4>{key}</h4>
                                  <h5>{percentage}%</h5>
                                </div>
                                <Progress className="Gender_Progress_Bar mt-2" value={percentage} />
                              </React.Fragment>
                            );
                          })}
                      </TabPane>
                    );
                  })}

              {data?.agenalysis?.map((item, index) => {
                const totalCount = Object.values(item).reduce((sum, count) => sum + count, 0);

                return (
                  <TabPane tabId='2' key={index}>
                    <>
                      {Object.keys(item)
                        .filter((key) => item[key] > 0)
                        .map((key) => {
                          const count = item[key];
                          const percentage = ((count / totalCount) * 100).toFixed(2);

                          return (
                            <React.Fragment key={key}>
                              <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                <h4>{key}</h4>
                                <h5>{percentage}%</h5>
                              </div>
                              <Progress className="Gender_Progress_Bar mt-2" value={percentage} />
                            </React.Fragment>
                          );
                        })}
                    </>
                  </TabPane>
                );
              })}



                <TabPane tabId='3'>
                  <>
                  {data?.locationAnalytics && Object.entries(data.locationAnalytics).map(([location, value], index) => {
                      const totalCount = Object.values(data.locationAnalytics).reduce((sum, count) => sum + count, 0);
                      const percentage = ((value / totalCount) * 100).toFixed(2);

                      return (
                        <React.Fragment key={index}>
                          <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                            <h4>{location}</h4>
                            <h5>{percentage}%</h5>
                          </div>
                          <Progress className="Gender_Progress_Bar mt-2" value={percentage} />
                        </React.Fragment>
                      );
                    })}

                  </>
                </TabPane>

              </TabContent>
            </div>
          </Col>
          ): pocAnalytics.length>0 && data.listenerCount >0 ? (
            <>
            <Col md="6" sm="12">
                  <div className="analitics-custom-blk p-2">
                  <h2 style={{ textAlign: 'center' }}>No Analytics yet to show as 0 Listeners Count</h2>
                </div>
                </Col>
                </>
          ):(
              <>
              <br/><br/>
              <Spinner
                  animation='border'
                  role='status'
                  style={{
                      width: '100px',
                      height: '100px',
                      margin: 'auto',
                      display: 'block',
                      color: '#81C14B'
                  }}
              >
                  <span className='sr-only'>Loading...</span>
              </Spinner>
              <br/>
              </> 
          )}
          
        </Row>

      </div>
      {resultAllTextPosts?.length > 1 && (
        <div className="card-title">
        <h3>Your Other Podcast </h3>
      </div>
      )}
        {
          resultAllTextPosts?.length > 1 && resultAllTextPosts?.filter(post => post.id != podcastId && post.postType=="podcast").slice(0,3).map((data) => (
            <>
      <Row className="mt-3">

            <Col md="12" sm="12" className="mb-3">
            
            <div className='Podcast_Details_box'>
              <div className='d-flex' style={{ cursor: "pointer" }} >
                <img src={data.podcastDetails.coverImageURL} alt="media" className='img-fluid  Podcast_Details_media' width={100} height={100} onClick={(e) => navigateToSomewhere(e,data.id,data.postType)}/>
                {/* onClick={`/MyPodcastDetails/${data.id}`} */}
                <p className='Podcast_Details_Mic'><img src={Mic1} alt="img" className='img-fluid' width={40} height={40} onClick={(e) => navigateToSomewhere(e,data.id,data.postType)}/></p>
                <div className='ml-3' onClick={(e) => navigateToSomewhere(e,data.id,data.postType)}>
                  <h3>{data.name}</h3>
                  <h5 className='py-3'>{data.podcastDetails.title}</h5>
                  <p className='text-muted'>{`uploaded on ${formatDate(data.podcastDetails.liveDate)}`}</p>
                </div>
              </div>
              <div>
              <MoreVertical 
                style={{ cursor: "pointer" }} 
                className="dropbtn-gal" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false" 
                size={20}
                color="#6E6B7B" 
              />
              <div className="dropdown-menu dropdown-menu-left drop-menu-gal podcastDetails_Option" style={{width:"200px",left: '-175px', right: 'auto', top: '100%'}}>
                <ul>
                  <li onClick={(e) => editPodcast(e)}>
                    <Link to="#">Edit podcast</Link>
                  </li>
                  <li onClick={(e) => togglePlayPause(e)}>
                    <Link to="#">Play podcast</Link>
                  </li>
                  <li onClick={(e) => newPodcatCreate(e)}>
                    <Link to="#">Create new podcast</Link>
                  </li>
                  <li onClick={(e) => deletePC(e, data.id)}>
                    <Link to="#">Delete podcast</Link>
                  </li>
                  {/* <li>
                    <Link to="#" style={{ color: "#1066AD" }}>Learn more</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            </div>


              
            </Col>

            </Row>
            </>
          ))
        }
      
      </div>
      ))}
    </Fragment>
  )

  const AllTypesModal = (props) => (
    <Fragment>
      {
        props.isOpen !== false && props?.data?.count > 0 &&
        <Modal scrollable isOpen={props.isOpen} toggle={props.toggleClick} className='modal-dialog-centered'>
          <ModalHeader>
          <h3>{`${props.title} (${props?.data?.count ?? 0})`}</h3>
          </ModalHeader>
          <ModalBody>
            <div>
              <h4>This list shows the users who have <span style={{ color: "#81C14B" }} >{props.type}</span>  your  <span style={{ color: "#81C14B" }}>Podcast.</span></h4>
            </div>
            <div className="py-3">
              <div className="profile-search-blk w-100 ">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" onChange={(e)=>setSearchKey(e.target.value)} />
              </div>
            </div>
            <Row className="mt-2">
              <div className="group-detail-page-blk ">
                {
                  props?.data?.rows.filter(data =>
                    data?.fullName.toLowerCase().includes(searchKey.toLowerCase())
                  ).map((data) => (
                    <div className="Follower-memberslist-block">
                      <div className="group-member-img-cont-blk">
                        <img src={data?.profileImageThumb} alt="img" className='img-fluid' />
                        <div className="member-cont-blk">
                          <h4>{data?.fullName}</h4>
                          <p>{formatDate(data?.joinedOn)}</p>
                          <div className="Suggestmember-blk py-1">
                            <h4>{data?.followersCount}&nbsp;<span>Followers</span> <span>•</span> {data?.postsCount}&nbsp;<span>Posts</span></h4>
                          </div>
                        </div>
                      </div>
                      {/* <div className='Block-btns-blk'>
                        <Button outline color='primary'>Sociomate</Button>
                      </div> */}
                    </div>
                  ))
                }
              </div>
            </Row>

          </ModalBody>
        </Modal>
      }
    </Fragment>
  )

  //Main_return_section
  return (
    <Fragment>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          {/* <ProfileCover /> */}
          <ProfileMenu />
          <div className="container-fluid section-t-space px-0">
            <div className="gallery-page-section section-b-space">
              <div className="card-title">
                <h3>Podcast</h3>
                <div className="right-setting">
                <a href="#!" onClick={handleClick} className="Podcast_Explore_button">
                  Explore
                </a>
                  {/* <div className='Podcast_action_button ml-2 '> */}
                  <Link to="/Podcast/NewPodcastSeries" className="btn btn-outline-green ms-3">Create / Upload</Link>
                  {/* </div> */}
                </div>
              </div>
              <div className="podcast-details-page">
                <Row>
                  <Col lg="4" md="5" sm="5">
                    <PlayPodcastCard />

                  </Col>
                  <Col lg="8" md="7" sm="7">
                    <PodcastAnlytics />
                  </Col>
                </Row>

              </div>

            </div>

          </div>
          {successResult && (
          <AllTypesModal
            title="Total liked"
            isOpen={likedOpen}
            data={analyticsByType}
            toggleClick={(e)=>toggleLiked(e,'close')}
            type="Liked"
          />
        )}
        {successResult && (
          <AllTypesModal
            title="Total Shared"
            isOpen={sharedOpen}
            data={analyticsByType}
            toggleClick={(e)=>toggleShared(e,'close')}
            type="Shared"
          />
        )}
        {successResult && (
          <AllTypesModal
            title="Total Purchases"
            isOpen={commentOpen}
            toggleClick={(e)=>togglePurchase(e,'close')}
            type="Purchased"
          />
        )}
        {successResult && (
          <AllTypesModal
            title="Total Listener"
            isOpen={open}
            data={analyticsByType}
            toggleClick={(e)=>toggleOpen(e,'close')}
            type="Listener"
          />
        )}
        
        </div>

        <RightSidebar />
      </div>
      <div className="vertically-centered-modal">
                    {deleteOpen !== false && (
                        <Modal
                        isOpen={deleteOpen}
                        toggle={toggleDeleteOpen}
                        className="modal-dialog-centered"
                        >
                        <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                            <h4>Delete Podcast</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex justify-content-center modalHeading_Section">
                            <p>Do you really want to delete the selected Podcast ?</p>
                            </div>
                            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                            <small>If</small> YES! <small>please click on ok</small>
                            </h1>
                            <div className="d-flex justify-content-center  mt-4">
                            <Button onClick={toggleDeleteOpen} outline style={{ width: '100px' }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={(e)=>deleteAllPost(e)}
                                color="primary"
                                className="ml-3 "
                                style={{ width: '100px' }}
                            >
                                OK
                            </Button>
                            </div>
                        </ModalBody>
                        </Modal>
                    )}
                    </div>
    </Fragment>
  )
}

export default MyPodcastDetails
