import React, { useState } from 'react'
import { Check, X } from 'react-feather'
import { Button, Modal, ModalBody, Row } from 'reactstrap'
import e from '../../../NewComponents/IMG/e.png'
import { useNavigate } from 'react-router-dom'

// =========================failed======================
const SavePayment = (props) => {
    const navigate = useNavigate()
    return (
        <>
            {props.PaymentSaveModal ? (
                <Modal isOpen={props.PaymentSaveModal} className='modal-dialog-centered' toggle={props.togglePaymentSave} >
                    <ModalBody>
                        <div>
                            <div className='text-center border-bottom'>
                                <h4 className='text-black mt-2'>Drafts Adds</h4>
                                <p className='my-2'>Your Adds has been successfully Drafts.</p>
                            </div>
                            <div>
                                <Row className='ConfirmatioForstyle'>
                                    <div className="d-flex justify-content-center m-1 mt-3">
                                        <div><Button type="submit" color="success" outline >Yes , Drafts it</Button></div>
                                        <div className='ml-3'><Button color="success" outline   >No , Go Back</Button></div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (null)}
        </>
    )
}

export default SavePayment
