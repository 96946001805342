import * as types from "../../Constants/Events/index";





const initialState = {
    inviteFriends: [],
    loding: true,
    
  };

    export const inviteFriendsReducer = (state = initialState, action) => {
        switch (action.type) {
            case types.INVITE_FRIENDS_BY_USER:
                return {
                    ...state,
                    inviteFriends:action.payload,
                    loding: false
                };
                default:
                    return state
                }
        };
    