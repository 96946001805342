import React from 'react'
import ContentLeft from '../ContentLeft';
import ContentRight from '../ContentRight';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import { StorySlider } from '../Story/StorySlider';
import TwowayNav from './TwowayNav';

const UniversalNav = () => {
    return (
        <>
            <Header />
            <div className="page-body container-fluid newsfeed-style1">
                <LeftSidebar />
                <div className="page-center">
                    <StorySlider />
                    <div className="container-fluid section-t-space px-0">
                        <div className="page-content">
                            <ContentLeft />
                            <div className="content-center">
                                <div>
                                    <TwowayNav />
                                </div>
                            </div>
                            <ContentRight />
                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default UniversalNav