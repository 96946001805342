import React, { Fragment, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { addMonths } from '../../date_utils'
import { Radio } from '@mui/material';
import { Gender, OtherGenders } from './LoginDummyArray/signupdetailsArray';
import { Row, Button } from 'reactstrap';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';


const SignUpDetalsPage = () => {


    const navigate = useNavigate()


    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }



    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")




    //  ------------------------------------Manage the date picker ------------------------------------------

    const date2 = new Date("January 01, 2007 01:15:00")
    const [startDay, setStartDay] = useState(date2);
    const [startMonth, setStartMonth] = useState(startDay);
    const [startYear, setStartYear] = useState(startMonth);





    // ----------------------------------------------handle the readio button  ------------------------------------------------

    const [selectedValue, setSelectedValue] = useState('a');

    const handleChange = (event, id) => {

        setSelectedValue(id)
    };


    //  ------------------------------------------ Handle the Readion button --------------------------------
    const [selectedValue2, setSelectedValue2] = useState('a');

    const handleChange2 = (event, id) => {

        setSelectedValue2(id)
    };

    return (
        <Fragment>
            {/* =========================Language Modal ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row >
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec"><Link className="" to="/"><img src="/assets/images/logo.png" alt="logo" className="img-fluid" /></Link></div>
                            </div>
                            <div className="login-form">
                                {/*  -------------------------------------- Stepper ------------------------------------- */}
                                <LoginSignUpStepper steps={5} active={4} />

                                {/* ----------------------- Dob selection Contianer ----------------- */}
                                <div>
                                    <div className="login-title">
                                        <h2>Enter DOB</h2>
                                    </div>
                                    <div className="login-discription">
                                        <h4>Please enter your details below.</h4>
                                    </div>
                                    {/* ------------------------------------- Date Picker section  ------------------------------------ */}
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <div className="row dob-cust-blk">
                                                        <div className="col-4">
                                                            <DatePicker
                                                                renderCustomHeader={() => {
                                                                    if (startMonth !== null && startMonth !== undefined) {
                                                                        return <h4 className="">{startMonth.toLocaleString('default', { month: 'long' })}</h4>
                                                                    }
                                                                }}
                                                                selected={startDay}
                                                                onChange={(date) => { setStartDay(date); setStartMonth(date) }}
                                                                showDayMonthYearPicker
                                                                dateFormat="d"
                                                                className='form-control'
                                                            />
                                                            <label className='LoginBd_400'>Date</label>
                                                        </div>
                                                        <div className="col-4">
                                                            <DatePicker
                                                                // renderCustomHeader={() => (
                                                                //     <h4 className="">{startMonth.toLocaleString('default', { month: 'long' })}</h4>
                                                                // )}
                                                                selected={startMonth}
                                                                onChange={(date) => { setStartMonth(date); setStartYear(date); setStartDay(date) }}
                                                                showMonthYearPicker
                                                                dateFormat="MMM"
                                                                className='form-control'
                                                            />
                                                            <label className='LoginBd_400'>Month</label>
                                                        </div>
                                                        <div className="col-4">
                                                            <DatePicker
                                                                selected={startYear}
                                                                onChange={(date) => { setStartYear(date); setStartMonth(date); setStartYear(date) }}
                                                                showYearPicker
                                                                yearItemNumber={9}
                                                                dateFormat="yyyy"
                                                                className='form-control'
                                                                minDate={new Date('January 01, 1910 01:15:00')}
                                                                maxDate={addMonths(new Date(new Date().setFullYear(new Date().getFullYear() - 13)), 1)}
                                                                showDisabledMonthNavigation
                                                            />
                                                            <label className='LoginBd_400'>Year</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* ---------------------- Gender selection container ---------------------------------- */}

                                                <div className="form-group">
                                                    <h3 className="choose-gender-blk Login4D_500">Choose Gender</h3>
                                                    {Gender.map((item, index) => (
                                                        <div onClick={(e) => handleChange(e, item.id)} style={{ cursor: "pointer" }} className='Profession_Modal_Option AboutModal_Main_section py-2 form-chec  '>
                                                            <p className='Login4D_400'>{item.name}</p>
                                                            <Radio
                                                                checked={selectedValue === item.id}
                                                                onChange={(e) => handleChange(e, item.id)}
                                                                value={item.id}
                                                                name="gender"
                                                                className='Radio_btn_check'
                                                                color='primary'
                                                            // inputProps={{ 'aria-label': data.valueId1 }}
                                                            />
                                                        </div>
                                                    ))}

                                                    {/* --------------------- Other Gender Options -------------------------------------- */}

                                                    {selectedValue === "c" ?
                                                        <div className="specialgender">
                                                            <h3>How should we address you</h3>
                                                            {OtherGenders.map((item, index) => (
                                                                <div onClick={(e) => handleChange2(e, item.id)} style={{ cursor: "pointer" }} className='Profession_Modal_Option AboutModal_Main_section py-2 form-chec  '>
                                                                    <p className='Login4D_400'>{item.name}</p>
                                                                    <Radio
                                                                        checked={selectedValue2 === item.id}
                                                                        onChange={(e) => handleChange2(e, item.id)}
                                                                        value={item.id}
                                                                        name="gender"
                                                                        className='Radio_btn_check'
                                                                        color='primary'
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        : null}


                                                </div>
                                                {/* <p className="error-input-msg text-center d-none" ref={errorRef}>{error}</p> */}

                                                <div className="btn-section">
                                                    <Button color="primary" onClick={() => navigate("/signupinterestchoose")}  >CONTINUE</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ============================= Language Section ================================== */}
                            <div className="language-selection-section">
                                <div className="lang-sel-block">
                                    <b>Choose Language:</b>
                                    <span className="language-list">
                                        {/* --------------------------- Labnguages------------------------------ */}
                                        {["English", "Hindi", "Deutsch"].map((item, index) => (
                                            <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                        ))}
                                    </span>
                                    <button onClick={() => setLanguages(true)} >
                                        ...More
                                    </button>
                                </div>
                            </div >

                            {/* ======================== Bottom Canvas Language section  ======================================= */}
                        </div>
                    </Row>
                </div>
            </section>
        </Fragment>
    )
}

export default SignUpDetalsPage
