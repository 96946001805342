import Flow from '../Icon/Flow.svg'
import Tezos from '../Icon/Tezos.svg'

export const ExpDummyCardData = [
    {
        id: "1",
        icon: Flow,
        name: "Election Season",
        coinType: "0.45 Flow",
        likeCount: "20"
    },
    {
        id: "2",
        icon: Tezos,
        name: "Lorr’s Ad Issues",
        coinType: "7 XTZ",
        likeCount: "12"
    },
    {
        id: "3",
        icon: Flow,
        name: "Science Jobs",
        coinType: "17.59 Flow",
        likeCount: "18"
    },
    {
        id: "4",
        icon: Flow,
        name: "Venture Capitalist",
        coinType: "3 Flow",
        likeCount: "22"
    },
    {
        id: "5",
        name: "Week Ahead",
        coinType: "7 XTZ",
        icon: Flow,
        likeCount: "21"
    },
    {
        id: "6",
        icon: Tezos,
        name: "Tracking on the Go",
        coinType: "0.45 Flow",
        likeCount: "15"
    },
    {
        id: "7",
        icon: Flow,
        name: "Behind One of Big Hacks",
        coinType: "17.59 Flow",
        likeCount: "16"
    },
    {
        id: "8",
        icon: Tezos,
        name: "Capturing Memories",
        coinType: "7 XTZ",
        likeCount: "12"
    }
]

export const DropDummyCardData = [
    {
        id: "1",
        icon: Flow,
        name: "Election Season",
        coinType: "0.45 Flow",
        likeCount: "20"
    },
    {
        id: "2",
        icon: Tezos,
        name: "Lorr’s Ad Issues",
        coinType: "7 XTZ",
        likeCount: "12"
    },
    {
        id: "3",
        icon: Flow,
        name: "Science Jobs",
        coinType: "17.59 Flow",
        likeCount: "18"
    },
    {
        id: "4",
        icon: Flow,
        name: "Venture Capitalist",
        coinType: "3 Flow",
        likeCount: "22"
    },
    {
        id: "5",
        name: "Week Ahead",
        coinType: "7 XTZ",
        icon: Flow,
        likeCount: "21"
    },
    {
        id: "6",
        icon: Tezos,
        name: "Tracking on the Go",
        coinType: "0.45 Flow",
        likeCount: "999"
    },
    {
        id: "7",
        icon: Flow,
        name: "Behind One of Big Hacks",
        coinType: "17.59 Flow",
        likeCount: "111"
    },
    {
        id: "8",
        icon: Tezos,
        name: "Capturing Memories",
        coinType: "7 XTZ",
        likeCount: "14"
    }
]