import React from 'react'

//================== Main_return_function ===============//
const SettingSidebar = (props) => {

    const SettingsArray = [
        {
            id: "1",
            eventsName: "Account"
        },
        {
            id: "2",
            eventsName: "Privacy"
        },
        {
            id: "3",
            eventsName: "Post and Story"
        },
        {
            id: "4",
            eventsName: "Notification"
        },
        {
            id: "5",
            eventsName: "Media & Content"
        },
        {
            id: "6",
            eventsName: "Report a Problem"
        },
        {
            id: "7",
            eventsName: "Language"
        },
        {
            id: "8",
            eventsName: "Legal Policies"
        }

    ]

    //================== Main-return_function ==================//
    return (
        <div>
            <div className='Categories_LeftPannel_slider'>
                <div className="Categories_Left_block">
                    <div className="" id='Categories-Scroll-1'>
                        <div className="biz-about-cont">
                            <ul>
                                {
                                    SettingsArray.map((data) => (
                                        <li className={props.tabData == data.id ? 'SettingForSidestyle' : 'leftSection'}  onClick={() => { props.Slidetoggle(data.id); props.setsideOptionsClass(true);props.setSettingsText(data.eventsName); }} active={props.tabData === data.id}>
                                            <div className='nave-items-bizPage d-flex '  >
                                                {/* <img src={data.icon} width={31} height={31} /> */}
                                                <div className='ml-3 EventsCategories_All_events'>
                                                    <h5>{data.eventsName}</h5>
                                                </div>
                                            </div>

                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingSidebar