// import React from 'react'
// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';

// const AvatarGroup = () => {

//     const params = useParams()
//     const position = params.index
//     const { getGroupJoinedGroup } = useSelector((state) => {return state.getAllJoinedGroupData});

//     return (
//         <div>
//             <ul className="matual-friend-blk">
//                 {
//                    getGroupJoinedGroup && getGroupJoinedGroup?.data?.successResult?.rows[position]?.groupMembers?.map((item, index) => {
//                     if(index <9)
//                         return (
//                             <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
//                                 data-name="sufiya eliza" data-img={item.profileImageThumb}>
//                                 <img src={item.profileImageThumb} className="img-fluid bg-img" alt="" />
//                             </li>
//                         )
//                     })
//                 }
//             </ul>
//         </div>
//     )
// }

// export default AvatarGroup
import axios from 'axios';
import React, { Fragment,useState,useEffect } from 'react'

const AvatarGroup = () => {
    const avatardata = [
        {
            img: 'assets/images/story-2.jpg'
        },
        {
            img: 'assets/images/story-3.jpg'
        },
        {
            img: 'assets/images/story-4.jpg'
        }
    ]
    const [avatarData, setAvatarData] = useState([]);
    // Fetch suggested users data from API
    // navigator.geolocation.getCurrentPosition(
    //     async (geolocation) => {

    // const body={
    //     lat: geolocation.coords.latitude,
    //     long: geolocation.coords.longitude,
    // }
    // const response = await axios.post( `${process.env.REACT_APP_IPURL}/post/getSuggestedUsers`,
    // body,
    // {
    // headers: {
    //     Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
    // },
    // })
    //     .then(response => {
    //         setAvatarData(response.data.data.successResult.rows);
    //         // console.log(response.data.data.successResult.rows)
    //     })
    //     .catch(error => {
    //         console.log(error);
    //     });
    // }
    // )
    return (
        <div>
            <ul className="matual-friend-blk">
                {
                    avatarData.map((item, index) => {
                        return (
                            <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                data-name="sufiya eliza" data-img={item.profileImage}>
                                <img src={item.profileImage} className="img-fluid bg-img" alt="" />
                            </li>
                        )
                    })
                }
            </ul>
        </div >
    )
}

export default AvatarGroup