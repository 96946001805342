import { Pagination, Stack } from '@mui/material'
import { Fragment } from 'react'
import { Row, Card } from 'reactstrap'
import { MarketPlaceCardData } from '../MarketPlaceArray/MarketPlaceCategory'
import { useState ,useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import MpReportModal from '../ModalSection/MpReportModal'
import MarketPlaceDeleteModal from '../ModalSection/MarketPlaceDeleteModal'
import MpShareModal from '../ModalSection/MpShareModal'
import MarketPlaceGridViewCard from './MarketPlaceGridViewCard'
import MarketPlaceListCards from './MarketPlaceListCards'
import {getAllMarketPlaceProducts, getAllCategoryAndSubcategoryHaveData, deleteMarketPlaceProduct, getPeopleList} from '../../../Services/Actions/MarketPlace/getAllProductsAction'
import axios from 'axios' 
import Loader from "../../Loader/Loader"
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'



const MarketPlaceRightSide = ({ listView, setListView , searchKey , sortBy, subcategoryFilter, minPrice, maxPrice, locationRange,attributeVal, categoryId, pageIndex, setPageIndex, latLongValue}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);


    


    // ==============================pagination =====================================

    const BasicsPagination = () => (
        <Fragment>
            <Stack>
                <Pagination count={5} className="PaginationCounts">
                </Pagination>
            </Stack>
        </Fragment>
    )


    // Report Modal handler
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }


    // share modall controller
    const [forwardOpen, setForwardOpen] = useState(false)

    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    // State And function For the Delete Operation

    const [deleteOpen, setDeleteOpen] = useState(false)
    // const [pageIndex, setPageIndex] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const [isDelete, setIsDelete] =useState(false)
    const [marketPlaceId, setMarketPlaceId] = useState(null)
    const [location, setLocation] = useState([]);

    
    // const toggleDelete = () => {
    //     setDeleteOpen((preState) => !preState)
    // } previous code


    // Function to open the delete modal
    const openDeleteModal = (e,id) => {
        setMarketPlaceId(id);
        setDeleteOpen(true);
    };

    // Function to close the delete modal
    const closeDeleteModal = () => {
        setDeleteOpen(false);
    };




   // =================>>delete marketplace product<<=========================
  
   useEffect(() => {
    if (isDelete && marketPlaceId) {
       let body= {}
       body.marketPlaceId=marketPlaceId;
        dispatch(deleteMarketPlaceProduct(body)).then(() => {
            sweetAlertConfirmation("Deleted Succesfully")
            setRefresh(prevRefresh => !prevRefresh); // Refresh the product list after deletion
            setIsDelete(false); // Reset the delete state
            setMarketPlaceId(null)

        }).catch(() => {
            setIsDelete(false); // Reset the delete state in case of an error
            setMarketPlaceId(null)
        });
    }
}, [isDelete, marketPlaceId, dispatch]);


   //assigning if latLongValue contain value so it will assign that value in the body location param
    useEffect(() => {
        if (latLongValue && latLongValue.latitude && latLongValue.longitude) {
            // Create a new location object from latLongValue
            const newLocation = [{
                lat: latLongValue.latitude,
                long: latLongValue.longitude
            }];

            // Update the location state with the new location
            setLocation(newLocation);
        }
    }, [latLongValue]); 





     //=============get all market place body============
    const body = {
      subCategory: subcategoryFilter,
      pageSize: 12,
      searchKey: searchKey,
      lat: 22.29282,
      long: 70.7917062,
      category: categoryId,
      pageIndex: pageIndex,
      subChildCategory: "",
      currency: "",
      location: location || [],
      attribute: "",
      radius: locationRange,
      sortBy: sortBy || "Newest",
      minPrice: minPrice,
      maxPrice: maxPrice,
      attributeVal: attributeVal
    };
      //calling Action
      useEffect(() => {
        setLoading(true); 
        dispatch(getAllMarketPlaceProducts(body)).then(() => {
            setLoading(false); 
        }).catch(() => {
            setLoading(false); 
        });
    }, [dispatch, searchKey, sortBy, pageIndex, subcategoryFilter, minPrice, maxPrice, locationRange, categoryId, attributeVal,location, refresh]);




    //============>>gettting people list to share product==========
        const [peopleListData, setPeopleListData] = useState([]);
        const [peopleListPageIndex,setPeopleListPageIndex] = useState(0)
        const [searchKeyValue,setSearchKeyValue] = useState("")
        useEffect(() => {
            const fetchData = async () => {
              if (forwardOpen) {
                const data = await getPeopleList(peopleListPageIndex, searchKeyValue);
                if (peopleListPageIndex === 0) {
                  // When pageIndex is 0, reset the list
                  setPeopleListData(data);
                } else {
                  // Append data for infinite scroll
                  setPeopleListData(prevData => [...prevData, ...data]);
                }
              } else {
                setPeopleListPageIndex(0);
                setPeopleListData([]);
                setSearchKeyValue("");
              }
            };
        
            fetchData();
          }, [forwardOpen, peopleListPageIndex, searchKeyValue]);


          

      //===============changing the page size===============
        const handlePageChange = async(event, value) => {
            setPageIndex(value-1);
          };

          
        //===============setting page index 0 if searchkey will change=============
        useEffect(() => {
            setPageIndex(0);
          }, [searchKey]);
      


      //==========Getting all marketplace products from store==============
      const { products, error } = useSelector(state => state.marketPlace);
      const data = products?.rows || [];

    






    //   <<<<<<<<<<<<====================================================================>>>>>>>>>>>

      return (
        <Fragment>
            {/* Report Modal */}
            <MpReportModal reportOpen={reportOpen} toggleReport={toggleReport} marketPlaceId = {marketPlaceId} setRefresh = {setRefresh}/>
            {/* Delete Modal */}
            <MarketPlaceDeleteModal isOpen={deleteOpen}  toggleOpen={closeDeleteModal} setIsDelete = {setIsDelete} />
            {/* Share Modal */}
            <MpShareModal forwardOpen={forwardOpen} toggleForward={toggleForward} peopleListData= {peopleListData} setPeopleListPageIndex={setPeopleListPageIndex} setSearchKeyValue = {setSearchKeyValue}/>
            {/* Market Place Cards */}
            <Card className="mt-2 MpCardsMainCardContainer">
                {/* Grid View Cards */}
                <Row className={listView === 1 ? "MpGridSystemAuto MarketPlaceAllCardsClass" : ""}>
                    {loading ? (
                        <Loader /> 
                    ) : (
                        data.length > 0 ? (
                            data.map((item) => (
                                listView === 1 ? (
                                    <MarketPlaceGridViewCard
                                        key={item.id}
                                        data={item}
                                        openDeleteModal={openDeleteModal}
                                        toggleForward={toggleForward}
                                        toggleReport={toggleReport}
                                        setRefresh = {setRefresh}
                                        setMarketPlaceId = {setMarketPlaceId}
                                        
                                    />
                                ) : (
                                    <MarketPlaceListCards
                                        key={item.id}
                                        data={item}
                                        openDeleteModal={openDeleteModal}
                                        toggleForward={toggleForward}
                                        toggleReport={toggleReport}
                                        setRefresh = {setRefresh}
                                        setMarketPlaceId = {setMarketPlaceId}
                                    />
                                )
                            ))
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', padding: '20px' }}>
                                <h2>No products available</h2>
                            </div>
                        )
                    )}
                </Row>
                <div className='d-flex align-items-center justify-content-center PaginationCounts'>
                    <Pagination
                        count={10} 
                        page={pageIndex + 1}
                        onChange={handlePageChange}
                    />
                </div>
            </Card>
        </Fragment>
    );
};
    
    export default MarketPlaceRightSide;