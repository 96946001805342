import React, { useState }  from 'react'
import { useEffect } from 'react';
import { Eye, EyeOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { getTextPostsAction, hideAllPostsFromTimelineAction, postShowAction} from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';

const ShowHidePost = (props) => {

    const getAllPostsUser = useSelector((state) => { return state.getTextPostsData });
    const { loading: loadingPosts, error: errorPosts, result: resultPosts } = getAllPostsUser

    const allPosts = resultPosts?.filter(post => post?.mediaList?.find(media => media?.mediaList !== null));


    let dispatch = useDispatch()
    const [success, setSuccess] = useState(false);
    

    useEffect(() => {
        if (success === true) {
            dispatch(getTextPostsAction('media', allPosts?.length, 0))
        }
        setSuccess(false)
    }, [success]);



    const hidePost = (id) => {
        dispatch(hideAllPostsFromTimelineAction(id))
        setSuccess(true)
    }

    const showHiddenPost = (id) => {
        dispatch(postShowAction(id))
        setSuccess(true)
    }



  return (
    <div style={{fontSize:'12px'}}>

     { props?.hidden 
        ?
        <span onClick={() => {showHiddenPost (props?.postId) }}>
        <Eye size={15} color="#979797" /> &nbsp;&nbsp; 
        Show post to Others
      </span>
       :
       <span
       onClick={() => {hidePost(props?.postId) }}>
        <EyeOff size={15} color="#979797" /> &nbsp;&nbsp; 
        Hide post from Others
     </span>

     }

    </div>
  )
}

export default ShowHidePost
