import React from 'react';
import './biz.css'
import Headers from '../Header'
import LeftSidebar from '../LeftSidebar';
import TopBanner from './TopBannerSection/TopBanner';
import { Col, Row } from 'reactstrap';
import BizPageReview from './Components/BizPageReview';
import PublicReview from './Components/PublicReview';
import RightSidebar from '../RightSidebar';
import BizDetailMenu from './BizDetailMenu'


const BizReview = () => {

    //=============== Main_return_function================//

    return (
        <>
            {/* <Headers />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                <div className="bizdetailprofile">
                        <TopBanner />
                        <BizDetailMenu />
                        </div>
                    <div className="container-fluid section-t-space px-0 ">
                        <div className="page-content">
                            <div className="content-center w-100">
                                <div className="gdpost-about-blk">
                                    <Row>
                                        <Col md={4}>
                                            <BizPageReview />
                                        </Col>
                                        <Col md={8}>
                                            <PublicReview />
                                        </Col>
                                    </Row>
                                  
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <RightSidebar />
            </div> */}

            <PublicReview />


        </>
    )
}

export default BizReview