import React, { useContext, useState } from 'react'
import { ArrowDownCircle, ChevronRight, Circle, Copy, Filter, Edit, MoreVertical, Play, Send, Trash2, Headphones, Repeat, Download, Bookmark, Eye, User, Flag, Pause, AlertTriangle, Trash, ArrowLeft } from 'react-feather';
import Slider from 'react-slick'
import { Badge, Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Mic from '../../../NewComponents/IMG/Mic.png'
import MicHover from '../../../NewComponents/IMG/podcastIcon.svg'
import PaidTag from '../../../NewComponents/IMG/PaidTag.svg'
import PlayIcon from '../../../NewComponents/IMG/playVideo.svg'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import CommentActive from '../../../NewComponents/IMG/CommentActive.svg'
import CommentLatest from '../../../NewComponents/IMG/CommentLatest.svg'
import ShareLatest from '../../../NewComponents/IMG/ShareLatest.svg'
import '../podcast.css'
import PodacastMic from '../../../NewComponents/IMG/PodacastMic.svg'
import { Link, NavLink } from 'react-router-dom';
import { useUserAuth } from '../../../Context/userAuthContext';
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { Checkbox } from '@mui/material'
import { ForwardArrayData } from '../../Social-Feed-Component/FeedArray';
import UserImg from '../../../NewComponents/IMG/img.svg'
import musicAnimation from '../../../NewComponents/Gif/musicAnimation.gif'
import Rect1 from '../../Story/Images/Rect1.png'

// import { ForwardArrayData } from '../../../Social-Feed-Component/FeedArray'




const TopPodacastCard = (props) => {
    const { listView, setListView, setMusicBar, musicBar } = useUserAuth();
    // count abbreviation function
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(1).toString()} K`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(1).toString()} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    /*  ---------------------- States --------------------  */

    // const [style, setStyle] = useState({ display: 'none' });
    const [isPlaying, setIsPlaying] = useState(false);
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [comment, SetComment] = useState(false)
    const [deletepodcast, setdeletepodcast] = useState(false)
    const [downloadpodcast, setdownloadpodcast] = useState(false);


    /*  ---------------------- Function for deleting podcast --------------------  */
    const toggledeletepodcast = () => {
        setdeletepodcast((prevState) => !prevState)
    }

    {/* ----------------------------State and function for Report Modal------------------------------------------ */ }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    /*  ---------------------- Function & state for sharing podcast --------------------  */
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    /*  ---------------------- Function & state for comment modal of podcast --------------------  */
    const [Commentopen, setcommentclose] = useState(false)
    const toggleComment = () => {
        setcommentclose((preState) => !preState)
    }

    /*---------------------- Function for toggling of podcast ------------------------*/
    const toggledownloadpodcast = () => {
        setdownloadpodcast((prevState) => !prevState)
    }

    /*  ---------------------- Dyanmic Card Data Array --------------------  */

    const cardData = [
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'Unpaid',
            podcastType: 'Series',
            seires: true
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '01',
            episodType: 'paid',
            podcastType: 'Single',
            seires: false
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'Unpaid',
            podcastType: 'Series',
            seires: true
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '01',
            episodType: 'paid',
            podcastType: 'Single',
            seires: false
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'Unpaid',
            podcastType: 'Series',
            seires: true
        }
    ]

    /*  ---------------------- Dyanmic List Data Music Array --------------------  */

    const musicArray = [
        {
            id: 1,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 2,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 3,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 4,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 5,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 6,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 7,
            Live: false,
            publisher: 'john wick'
        }
    ]

    // -----------------------------------------------Array data Report Data Info Part-------------------------------------------------------

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harassment"
        },
        {
            name: "Suicide or Self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    /*  ---------------------- Third party react-slick Slider Breakpoint management --------------------  */

    let FriendSuggestsettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    /*-------------- Modal for Delete User -----------------*/

    const DeleteModal = () => {
        return (
            <>
                {deletepodcast ? (
                    <Modal isOpen={deletepodcast} className='modal-dialog-centered' toggle={toggledeletepodcast} >

                        <ModalHeader className='d-flex justify-content-center border-0 Media_header'>
                            <h3>Delete Podcast</h3>
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <h5 className='py-1 name-style'>Do you really want to delete this Podcast ?</h5>
                        </ModalBody>
                        <ModalFooter className='d-flex align-items-center justify-content-center border-0 modalHeading_Section' >
                            <div className='text-center'>
                                <Button onClick={toggledeletepodcast} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={toggledeletepodcast} >Delete</Button>
                            </div>
                        </ModalFooter>

                    </Modal>

                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Download pocast -----------------*/

    const DownloadModal = () => {
        return (
            <>
                {downloadpodcast ? (
                    <Modal isOpen={downloadpodcast} className='modal-dialog-centered' toggle={toggledownloadpodcast} >

                        <ModalHeader className='d-flex justify-content-center border-0 Media_header'>
                            <h3>Download Podcast</h3>
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <h5 className='py-1 name-style'>Do you really want to download this Podcast ?</h5>
                        </ModalBody>
                        <ModalFooter className='d-flex align-items-center justify-content-center border-0 modalHeading_Section' >
                            <div className='text-center'>
                                <Button onClick={toggledownloadpodcast} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={toggledownloadpodcast} >Download</Button>
                            </div>
                        </ModalFooter>

                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Share pocast -----------------*/

    const ShareModal = () => {
        const Sharedto = [
            {
                name: "Your Wall Feed"
            },
            {
                name: "Your Story"
            }
        ]

        return (
            <div>
                {
                    forwardOpen !== false &&
                    <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>

                        <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
                            <h2>Share to  </h2>
                            <Button color='primary' >Share</Button>
                        </div>
                        <ModalBody>
                            <div className='pb-2'>
                                <Accordion defaultActiveKey="0">
                                    <AccordionItem eventKey='1'>
                                        <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                                        <AccordionBody className='p-0'>
                                            <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey='1'>
                                                    <AccordionHeader>Feed</AccordionHeader>
                                                    <AccordionBody>
                                                        {
                                                            Sharedto.map((data) => (
                                                                <div className='d-flex justify-content-between align-items-center my-2 Report_Section'>
                                                                    <h4>{data.name}</h4>
                                                                    <div>
                                                                        <Checkbox />
                                                                        {' '}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='2'>
                                                    <AccordionHeader>Your Business Pages</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='3'>
                                                    <AccordionHeader>Your Group</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>

                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Connections</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            {/* <div className=''> */}

                                            <Checkbox />

                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }
    /*-------------- Modal for Comment -----------------*/
    const CommentModal = () => {
        const [LikeIcon, SetLikeIcon] = useState(false)
        const [comment, SetComment] = useState(false)
        return (
            <div>
                {
                    Commentopen !== false &&
                    <Modal scrollable isOpen={Commentopen} toggle={toggleComment} className='modal-dialog-centered modal-lg'>
                        <div className='p-3 border-bottom'>
                            <h2>Comments </h2>
                        </div>
                        <ModalBody>
                            <div className="p-2">
                                <div className="col p-0">
                                    <div className="comment-part">
                                        <div className="d-flex pb-2">
                                            <div className="user-media">
                                                <div className="media">
                                                    <Link className="user-img">
                                                        <img src="https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg" className="img-fluid bg-img" alt="user" />
                                                        <span className="available-stats"></span>
                                                    </Link>
                                                    <div className="media-body">
                                                        <h5>Kahani mein Twist</h5>
                                                        <h6>This podcast includes short stories inspired from...</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-panel mb-0">
                                            <div className="post-wrapper">
                                                <div className="post-details">
                                                    <div className="like-panel ">
                                                        <div className="left-emoji">
                                                            <ul>
                                                                <li>
                                                                    <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                </li>
                                                                <li>
                                                                    <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                                </li>
                                                            </ul>
                                                            <h6>+75</h6>
                                                        </div>
                                                        <div className="right-stats">
                                                            <ul >
                                                                <li>
                                                                    <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                                </li>
                                                                <li><span className='d-flex align-items-center'>985  &nbsp;<p className='text-muted'>Share</p></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="post-react">
                                                        <ul>
                                                            <li >
                                                                {
                                                                    LikeIcon ? <div className='mr-2'>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' /> <span>50</span>
                                                                    </div> : <div className='mr-2'>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={Circlelike} alt='shareIcon' /> <span>49</span>
                                                                    </div>
                                                                }

                                                            </li>
                                                            <li >
                                                                <div className='mx-2'>
                                                                    <img style={{ cursor: 'pointer' }} width={35} src={CommentLatest} alt='shareIcon' /> <span> 25</span>
                                                                </div>
                                                            </li>
                                                            <li >
                                                                <div className='ml-2'>
                                                                    <img width={35} style={{ cursor: 'pointer' }} onClick={toggleForward} src={ShareLatest} alt='shareIcon' /> <span> 25</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="comment-section">
                                                        <div className="comments d-block scrollForSharePodcast">
                                                            <div className="main-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h4>Pabelo Mukrani</h4>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                        </h5>
                                                                        <ul className="comment-option">
                                                                            <li><Link to="#">like (5)</Link></li>
                                                                            <li onClick={() => SetComment(!comment)}><Link to="#">reply</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {comment ?
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                            </div>
                                                            <div className="main-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h4>Pabelo Mukrani</h4>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                        </h5>
                                                                        <ul className="comment-option">
                                                                            <li><Link to="#">like (5)</Link></li>
                                                                            <li onClick={() => SetComment(!comment)}><Link to="#">reply</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {comment ?
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                            </div>
                                                        </div>
                                                        <div className="reply">
                                                            <div className="search-input input-style input-lg icon-right">
                                                                <Input type="text" className="form-control emojiPicker"
                                                                    placeholder="Comment...." />
                                                                <Link to="#">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                                </Link>
                                                                {/* <div className='Media_Modal_send_Icon'>
                                                                    <img src={Snd} width={14} height={14} alt="snd" />

                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }

    {/* ------------------------------------Review Report modal start--------------------------------------------------------- */ }
    const ReportModal = () => {
        return (
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about how you feel and help us to <br /> make SocioMee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>
                }
            </div>
        )
    }

    const [hover, sethover] = useState(0)
    {/*  ---------------------- Fumction for Music Bar --------------------  */ }
    const handleMusicBar = () => {
        setMusicBar(true);
    }
    {/*  ---------------------- Main return block --------------------  */ }
    return (
        <div>
            <ShareModal />
            <DeleteModal />
            <CommentModal />
            <DownloadModal />
            <ReportModal />
            {/*  ---------------------- Header --------------------  */}
            <Row className='rowForrevert'>
                <Col xl="12">
                    <div className='d-flex justify-content-between m-2'>
                        <h3><strong>{props.Tittle}</strong></h3>
                        <div className='text-primary'>
                            <NavLink to='/Podcast/SeeAll'>
                                {listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}
                            </NavLink>
                        </div>
                    </div>
                </Col>
            </Row>
            {listView ? <Row className='rowForrevert'>
                <Col xl='12' style={{ paddingRight: "7px" }}>
                    <div className="group-custom-block  ">
                        {/*  ---------------------- dyanmic Card handeled by map function in slider --------------------  */}
                        <Slider {...FriendSuggestsettings} className="default-space ">
                            {
                                cardData.map((item, index) => {
                                    return (
                                        <div key={index} onMouseOver={() => { sethover(index + 1) }} onMouseLeave={() => { sethover(0) }}  >
                                            <Card className='w-100 podcastForcard'>
                                                <div className='headerForpodcast p-2 simple-linear'>
                                                    <div className='d-flex align-items-center justify-content-start' >
                                                        {item.seires === true ? null : <div className="livestatus-con mr-0"><span></span> Live</div>}
                                                        <img src={Mic} width={25} height={28} alt='micicon' />
                                                        {numFormatter(item.viewerCount)}
                                                    </div>
                                                    <div className='moreoverFormargin' >
                                                        <UncontrolledButtonDropdown direction='start'>
                                                            <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                                <MoreVertical size={20} color='white' />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ zIndex: '100', color: '#4D4D4D', minWidth: '10rem', right: "13px" }} className=" ">
                                                                <NavLink onClick={handleMusicBar} className='text-dark ' to={item.seires === true ? '/YourSeries' : '/Podcast/view'}><DropdownItem className='toppoddroup'><Play size={15} />&nbsp;&nbsp;Join</DropdownItem></NavLink>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className='toppoddroup' tag='a'><Copy size={15} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' className='toppoddroup' onClick={toggleForward} ><Send size={15} />&nbsp;&nbsp;Share podcast </DropdownItem>
                                                                {/* <DropdownItem divider></DropdownItem> */}
                                                                {/* <DropdownItem className='toppoddroup'><Edit size={15} />
                                                                    <Link to='/Podcast/UploadRecordedPodacast' className='text-dark ' > Edit podcast </Link>
                                                                </DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' className='toppoddroup' onClick={toggledeletepodcast} ><Trash2 size={15} /> Delete podcast</DropdownItem> */}
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                                <div className={`position-relative`}
                                                >
                                                    <NavLink to={item.seires === true ? '/YourSeries' : '/Podcast/view'} onClick={handleMusicBar}  >
                                                        <div className='simple-linear'><img alt="Sample" className='w-100 rounded-top img-fluid' style={{ zIndex: '-1' }} src="https://picsum.photos/300/200" /></div>
                                                        <div className={`podcastcenterForstyle `}
                                                        >
                                                            <img className={`${hover === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                        </div>
                                                    </NavLink>

                                                    {item.episodType === 'paid' ? '' : <div className='paidTagForpostition'>
                                                        <img alt="paid" className='img-fluid' src={PaidTag} />
                                                    </div>}
                                                </div>
                                                <CardBody>
                                                    <CardTitle tag="h3">
                                                        {item.cardTittle}
                                                    </CardTitle>
                                                    <CardSubtitle className="my-2">
                                                        <Badge className='text-secondary' color="light" pill>
                                                            {item.category}
                                                        </Badge>
                                                    </CardSubtitle>
                                                    <CardText className='text-muted'>
                                                        {item.podcastType}-{item.seriesCount} <Circle strokeWidth={0} size={8} fill={'#D9D9D9'} /> Total Episodes-{item.totalEpisodeCount}
                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </Col >
            </Row > :
                <Row className='rem-mob-view '>
                    <Col xl='12'>
                        {
                            musicArray.map((item, index) => {
                                return <div key={index} className='MusicList my-3 d-flex justify-content-between border p-1 rounded bg-white align-items-center my-3' >
                                    <div className='d-flex align-items-center'>
                                        <div className='position-relative'>
                                            {/* <div className='d-flex p-1'>
                                                <span><h4 className='bg-danger w-100 my-1 text-center rounded'><span className='text-white'>LIVE</span></h4></span>

                                                <Badge className='text-secondary ml-1' color="light" pill>
                                                    {props.Tittle}
                                                </Badge>
                                            </div> */}
                                            <div className='d-flex align-items-center pr-3'>
                                                <div className='position-relative'>
                                                    <img width={100} height={100} className='rounded' src={Rect1} alt='' />
                                                    <img className='seriesPlayForIconList' width={50} src={PlayIcon} alt='' />
                                                </div>
                                                <div className='ml-3'>
                                                    <h4>Rättegångspodden</h4>

                                                    <p className='text-muted pt-1'>Series || Ep 01</p>
                                                    {!item.Live ? <p className='text-muted pt-1'>06:00</p> : null}
                                                    <p className='text-muted pt-1'>{`Podcaster : ${item.publisher}`}</p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-around align-items-center' >
                                        <div className='text-muted'>
                                            <div className='d-flex justify-content-start align-items-center '>
                                                {
                                                    LikeIcon ? <div className='mr-2 text-nowrap'>
                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' /> <span>50</span>
                                                    </div> : <div className='mr-2 text-nowrap'>
                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={Circlelike} alt='shareIcon' /> <span>49</span>
                                                    </div>
                                                }
                                                <div className='mx-2 text-nowrap'>
                                                    <img style={{ cursor: 'pointer' }} onClick={toggleComment} width={35} src={CommentLatest} alt='shareIcon' /> <span> 25</span>
                                                </div>

                                                <div className='mx-2 text-nowrap'>
                                                    <img width={35} style={{ cursor: 'pointer' }} onClick={toggleForward} src={ShareLatest} alt='shareIcon' /> <span> 25</span>
                                                </div>

                                                <div className=' ml-2 d-flex align-items-center'>
                                                    {/* <img src={PodacastMic} /> */}
                                                    <div className='p-2 rounded-circle rem-tab-hide' style={{ background: '#E5E5E5' }}><Headphones size={20} color='#808080' /></div> <span className='rem-tab-hide' style={{ paddingLeft: '0.2rem' }}> 25</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div className='d-flex align-items-center justify-content-around' >
                                        <div className={isPlaying ? '' : 'd-block invisible'}> <img src={musicAnimation} alt='...' width={25} className='' /></div>
                                        <Button color='primary' className='rem-mob-view' onClick={() => setIsPlaying(!isPlaying)} >{!isPlaying ? <><Play fill='#FFFFFF' size={15} /> Play</> : <><Pause fill='#FFFFFF' size={15} /> Pause</>}</Button>
                                    </div>

                                    <div className='d-flex justify-content-around align-items-center'>

                                        <div className='d-flex  justify-content-center' >
                                            {item.Live ? <div className=''>
                                                <Badge className='text-white bg-danger rem-tab-hide' pill>
                                                    Live
                                                </Badge>
                                            </div> : <div className='px-4 rem-tab-hide' onClick={toggledownloadpodcast} >
                                                <ArrowDownCircle color='#81C14B' />
                                            </div>}
                                        </div>
                                        {/* <div className='mx-2' >
                                        {showButton === index ? <Button color='primary' className='rem-mob-view' onClick={() => setIsPlaying(!isPlaying)} ><Play fill='#FFFFFF' size={15} /> Play</Button> : null}
                                    </div> */}



                                        <div style={{ paddingTop: '3px' }}>
                                            <UncontrolledButtonDropdown direction='up' className='ml-0'>
                                                <DropdownToggle outline color='flat-primary'>
                                                    <MoreVertical color='#6E6B7B' />
                                                </DropdownToggle>
                                                {/* top: '-200px', */}
                                                <DropdownMenu style={{ left: '-171px' }} className='existingDrop'>
                                                    <DropdownItem tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem tag='a' onClick={toggledownloadpodcast} ><Download size={15} />&nbsp;Download</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash size={15} />&nbsp;Delete</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem tag='a'><Edit size={20} />
                                                        <Link to='/Podcast/UploadRecordedPodacast' className='text-dark' > Edit podcast </Link>
                                                    </DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    {/* <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                    <DropdownItem divider></DropdownItem> */}
                                                    <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem tag='a' onClick={toggleReport} ><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                </div>

                            })
                        }
                    </Col>

                </Row>


            }
        </div >
    )
}

export default TopPodacastCard

