import React, { Fragment } from 'react'
import SocioMeeLogo from '../Img/Icon.svg'
import { Button, Input } from 'reactstrap'
import { EyeOff, User } from 'react-feather'

const AdHeader = () => {

    //====================== Main_return_function =========================//
    return (
        <Fragment>
            <header className="header-light">
                <div className="container-fluid custom-padding">
                    <div className="header-section">
                        <div className="header-left">
                            <div className="brand-logo">
                                <img
                                    src={SocioMeeLogo}
                                    alt="logo"
                                    className="img-fluid"
                                    style={{ minWidth: "250px" }}
                                />
                            </div>
                        </div>
                        <div className="header-right  d-flex align-items-start gap-3 mt-4 ">
                            <div className='d-flex gap-4 '>
                                <div className='Input_Position_section'>
                                    <Input placeholder='Enter your mobile number' className='w-100' type='text' />
                                    <div className='InputBox_Icon'>
                                        <User size={13} color='#B9B9C3' />
                                    </div>
                                </div>
                                <div >
                                    <div className='Input_Position_section'>
                                        <Input placeholder='Enter your password' className='w-100' type='text' />
                                        <div className='InputBoxs_Icon'>
                                            <EyeOff size={13} color='#B9B9C3' />
                                        </div>
                                    </div>
                                    <p style={{
                                        textAlign: "end",
                                        margin: "0",
                                        padding: "0",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        marginTop: '4px',
                                        cursor: "pointer",
                                    }}>Forgot Password?</p>
                                </div>
                            </div>

                            <Button className='Login_Btn_blk' color='primary'>Log In</Button>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default AdHeader
