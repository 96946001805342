import React, { Fragment, useState } from 'react';
import { Badge, Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, NavLink, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { ChevronDown, ChevronLeft, ChevronUp, Circle, Copy, Edit, Grid, Home, List, MoreVertical, Play, Send, Trash2 } from 'react-feather';
import Header from '../../Header';
import LeftSidebar from '../../LeftSidebar';
import PodcastHeader from '../PodcastHeader';
import RightSidebar from '../../RightSidebar';
import PodcastFooter from '../PodcastFooter';
import home from '../../Story/Images/home.png'
import { Link } from 'react-router-dom';
import ViewListStatement from './ViewListStatement';
import SeriesPodcastView from './pages/SeriesPodcastView';

const ViewStatement = () => {
    const [showGrid, setShowGrid] = useState(true);
    const [showList, setShowList] = useState(false);

    const handleGridButtonClick = () => {
        setShowGrid(true);
        setShowList(false);
    };

    const handleListButtonClick1 = () => {
        setShowGrid(false);
        setShowList(true);
    };
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="container-fluid section-t-space p-0 mt-0">
                        <div className='d-flex justify-content-between'>
                            <Link to='/Podcast'><h6 style={{ fontSize: '18px', fontWeight: 600, color: '#000000' }} className='mt-2'><ChevronLeft size={20} style={{ color: '#000000' }} />&nbsp;&nbsp;Previous Request</h6>
                            </Link>
                            <div className='list-grid-toggle rem-mob-view rem-beyond-web' style={{ marginRight: '8px' }}>
                                <Button color='primary' outline onClick={handleGridButtonClick}>
                                    <Grid size={16} />
                                </Button>
                                <Button color='primary' outline onClick={handleListButtonClick1} className="rem-mob-view">
                                    <List size={16} />
                                </Button>
                            </div>
                        </div>
                        {showGrid && <Row>
                            <Col sm="12" md="12" lg="4" className="mt-4">
                                <Card style={{ boxShadow: '0px 4px 8px rgba(255, 136, 0, 0.1)' }}>
                                    <CardBody >
                                        <div style={{ backgroundColor: '#FF880026', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px', borderRadius: '5px 5px 0px 0px' }}>
                                            <div className='d-flex  justify-content-between m-2'>
                                                <p className='d-flex py-1' style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}><img src={home} alt="" style={{ color: '#333333' }} />&nbsp;Request Status</p>
                                                <p className='d-flex py-1' style={{ color: '#FF8800', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Pending&nbsp;&nbsp;<ChevronUp size={18} style={{ color: '#FF8800', cursor: 'pointer' }} /></p>
                                            </div>
                                        </div>
                                        <div className='d-flex  justify-content-between'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Date -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>25 Mar 2022</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Requested Amount -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>£17,500</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Receiver Name -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Kirti Pandey</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Bank Name -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Standard Chartered</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Acc. Number -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>1000 0000 1234 5678</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12" md="12" lg="4" className="mt-4">
                                <Card style={{ boxShadow: '0px 4px 8px rgba(255, 0, 0, 0.1)' }}>
                                    <CardBody >
                                        <div style={{ backgroundColor: '#F95C5C26', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px', borderRadius: '5px 5px 0px 0px' }}>
                                            <div className='d-flex  justify-content-between m-2'>
                                                <p className='d-flex py-1' style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}><img src={home} alt="" style={{ color: '#333333' }} />&nbsp;Request Status</p>
                                                <p className='d-flex py-1' style={{ color: '#FF5B58', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Rejected&nbsp;&nbsp;<ChevronDown size={18} style={{ color: '#FF5B58', cursor: 'pointer' }} /></p>
                                            </div>
                                        </div>
                                        <div className='d-flex  justify-content-between'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Date -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>25 Mar 2022</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Requested Amount -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>£17,500</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Receiver Name -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Kirti Pandey</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Bank Name -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Standard Chartered</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Acc. Number -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>1000 0000 1234 5678</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12" md="12" lg="4" className="mt-4">
                                <Card style={{ boxShadow: '0px 4px 8px rgba(39, 112, 68, 0.1)' }}>
                                    <CardBody >
                                        <div style={{ backgroundColor: '#4FA87426', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px', borderRadius: '5px 5px 0px 0px' }}>
                                            <div className='d-flex  justify-content-between m-2'>
                                                <p className='d-flex py-1' style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}><img src={home} alt="" style={{ color: '#333333' }} />&nbsp;Request Status</p>
                                                <p className='d-flex py-1' style={{ color: '#277044', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Approved&nbsp;&nbsp;<ChevronDown size={18} style={{ color: '#277044', cursor: 'pointer' }} /></p>
                                            </div>
                                        </div>
                                        <div className='d-flex  justify-content-between'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Date -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>25 Mar 2022</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Requested Amount -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>£17,500</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Receiver Name -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Kirti Pandey</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Bank Name -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Standard Chartered</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Acc. Number -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>1000 0000 1234 5678</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12" md="12" lg="4" className="mt-3">
                                <Card style={{ boxShadow: '0px 4px 8px rgba(255, 136, 0, 0.1)' }}>
                                    <CardBody >
                                        <div style={{ backgroundColor: '#FF880026', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px', borderRadius: '5px 5px 0px 0px' }}>
                                            <div className='d-flex  justify-content-between m-2'>
                                                <p className='d-flex py-1' style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}><img src={home} alt="" style={{ color: '#333333' }} />&nbsp;Request Status</p>
                                                <p className='d-flex py-1' style={{ color: '#FF8800', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Pending&nbsp;&nbsp;<ChevronDown size={18} style={{ color: '#FF8800', cursor: 'pointer' }} /></p>
                                            </div>
                                        </div>
                                        <div className='d-flex  justify-content-between'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Date -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>25 Mar 2022</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Requested Amount -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>£17,500</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Receiver Name -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Kirti Pandey</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Bank Name -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Standard Chartered</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Acc. Number -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>1000 0000 1234 5678</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12" md="12" lg="4" className="mt-3">
                                <Card style={{ boxShadow: '0px 4px 8px rgba(255, 0, 0, 0.1)' }}>
                                    <CardBody >
                                        <div style={{ backgroundColor: '#F95C5C26', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px', borderRadius: '5px 5px 0px 0px' }}>
                                            <div className='d-flex  justify-content-between m-2'>
                                                <p className='d-flex py-1' style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}><img src={home} alt="" style={{ color: '#333333', cursor: 'pointer' }} />&nbsp;Request Status</p>
                                                <p className='d-flex py-1' style={{ color: '#FF5B58', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Rejected&nbsp;&nbsp;<ChevronUp size={18} style={{ color: '#FF5B58', cursor: 'pointer' }} /></p>
                                            </div>
                                        </div>
                                        <div className='d-flex  justify-content-between'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Date -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>25 Mar 2022</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Requested Amount -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>£17,500</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Receiver Name -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Kirti Pandey</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Bank Name -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Standard Chartered</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Acc. Number -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>1000 0000 1234 5678</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12" md="12" lg="4" className="mt-3">
                                <Card style={{ boxShadow: '0px 4px 8px rgba(39, 112, 68, 0.1)' }}>
                                    <CardBody >
                                        <div style={{ backgroundColor: '#4FA87426', marginTop: '-24px', marginLeft: '-16px', marginRight: '-16px', borderRadius: '5px 5px 0px 0px' }}>
                                            <div className='d-flex  justify-content-between m-2'>
                                                <p className='d-flex py-1' style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}><img src={home} alt="" style={{ color: '#333333' }} />&nbsp;Request Status</p>
                                                <p className='d-flex py-1' style={{ color: '#277044', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Approved&nbsp;&nbsp;<ChevronUp size={18} style={{ color: '#277044', cursor: 'pointer' }} /></p>
                                            </div>
                                        </div>
                                        <div className='d-flex  justify-content-between'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Request Date -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>25 Mar 2022</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Requested Amount -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>£17,500</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Receiver Name -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Kirti Pandey</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Bank Name -</p>
                                            <p style={{ color: '#717171', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Standard Chartered</p>
                                        </div>
                                        <div className='d-flex  justify-content-between mt-2'>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Acc. Number -</p>
                                            <p style={{ color: '#333333', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>1000 0000 1234 5678</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>}
                        {showList && <ViewListStatement/>}
                        <PodcastFooter />
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment>
    );
};

export default ViewStatement;
