import React from 'react'

const AvatarGroupComponent = () => {
    return (
        <div><div className="people-likes matual-friend-sec">
            <ul className="matual-friend-blk">
                <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                    data-name="sufiya eliza" data-img="/assets/images/story-2.jpg">
                    <img src="/assets/images/story-2.jpg" className="img-fluid bg-img" alt="" />
                </li>
                <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                    data-name="sufiya eliza" data-img="/assets/images/story-3.jpg">
                    <img src="/assets/images/story-3.jpg" className="img-fluid bg-img" alt="" />
                </li>
                <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                    data-name="sufiya eliza" data-img="/assets/images/story-4.jpg">
                    <img src="/assets/images/story-4.jpg" className="img-fluid bg-img" alt="" />
                </li>
                <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                    data-name="sufiya eliza" data-img="/assets/images/story-3.jpg">
                    <img src="/assets/images/story-3.jpg" className="img-fluid bg-img" alt="" />
                </li>
                <li className="popover-cls" data-bs-toggle="popover" data-placement="right"
                    data-name="sufiya eliza" data-img="/assets/images/story-4.jpg">
                    <img src="/assets/images/story-4.jpg" className="img-fluid bg-img" alt="" />
                </li>
            </ul>
            
        </div></div>
    )
}

export default AvatarGroupComponent