import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AlertTriangle, CreditCard, FileText, HelpCircle, Home, Layers, Settings } from 'react-feather';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import AdManagerHeader from '../Sub-components/AdManagerHeader';
import SocioLogo from '../../../NewComponents/IMG/SocioLogo.svg';
import SociomeeLogo from '../../../NewComponents/IMG/SociomeeLogo.svg';
import AdManagerReportTablePage from './Report/AdManagerReportTablePage';
import AdManagerLinePage from './Report/AdManagerLinePage';
import AdManagerBarPage from './Report/AdManagerBarPage';
import { Row, Col, Card, CardHeader } from 'reactstrap';
import { Radio } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const sideDashboardData = [
    { text: 'Dashboard', link: '/Dashboard' },
    { text: 'Report', link: '/Dashboard/Report' },
    { text: 'Billing', link: '/Dashboard/TransactionSummary' },
    { text: 'Payment setting', link: '/Dashboard/PaymentSettings' },
    { text: 'Help', link: 'https://www.sociomee.com/knowledgebase-category/ad-manager/' },
];

export default function AdMangerReportPage() {
    const [selectedValue, setSelectedValue] = useState('Pivot Table');
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <AppBar sx={{ background: '#ffffff', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px', zIndex: '1000' }} position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <img width={25} height={25} src={SocioLogo} alt='' />
                    </IconButton>
                    <Typography noWrap className='w-100' component="div">
                        <AdManagerHeader />
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Left Container */}
            <Drawer onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose} variant="permanent" sx={{ zIndex: '0' }} open={open}>
                <DrawerHeader>
                    <IconButton sx={{ background: '#ffffff' }} onClick={handleDrawerClose}>
                        {open === true ? <img width={195} height={50} src={SociomeeLogo} alt="logo" /> : null}
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {sideDashboardData.map((text, index) => (
                        <a href={text.link} key={text.text} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItem disablePadding component="div" sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: '#81C14B',
                                        }}
                                    >
                                        {index === 0 ? <Home /> : index === 1 ? <Layers /> : index === 2 ? <AlertTriangle /> : index === 3 ? <FileText /> :
                                            index === 4 ? <CreditCard /> : index === 5 ? <Settings /> : index === 6 ? <HelpCircle /> : null}
                                    </ListItemIcon>
                                    <ListItemText primary={text.text} sx={{ opacity: open ? 1 : 0, color: '#81C14B' }} />
                                </ListItemButton>
                            </ListItem>
                        </a>
                    ))}
                </List>
            </Drawer>

            {/* Right Container */}
            <Box activeTab={selectedValue} component="main" sx={{ flexGrow: 1, p: 1, width: '95%' }}>
                <DrawerHeader />
                <div className='my-2'>
                    <Card style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px' }}>
                        <CardHeader className='fontForSubCardheading d-flex justify-content-between p-3'>
                            <h2>Report</h2>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat' className='border'>
                                    {selectedValue}
                                </DropdownToggle>
                                <DropdownMenu style={{ top: '45px', left: '-80px', zIndex: '2' }}>
                                    <DropdownItem onClick={() => setSelectedValue('Pivot Table')}>
                                        <Radio
                                            checked={selectedValue === 'Pivot Table'} 
                                            value="Pivot Table"
                                            name="reportType"
                                            inputProps={{ 'aria-label': 'Pivot Table' }}
                                        />
                                        Pivot Table
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setSelectedValue('Trend')}>
                                        <Radio
                                            checked={selectedValue === 'Trend'}
                                            value="Trend"
                                            name="reportType"
                                            inputProps={{ 'aria-label': 'Trend' }}
                                        />
                                        Trend
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setSelectedValue('Bar')}>
                                        <Radio
                                            checked={selectedValue === 'Bar'}
                                            value="Bar"
                                            name="reportType"
                                            inputProps={{ 'aria-label': 'Bar' }}
                                        />
                                        Bar
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </CardHeader>
                        <Row>
                            <Col xl={12}>
                                {selectedValue === 'Pivot Table' ? <AdManagerReportTablePage /> : selectedValue === 'Trend' ? <AdManagerLinePage SelectedReportedType={selectedValue === 'Trend' ? 'trend chart' : null} /> : selectedValue === 'Bar' ? <AdManagerBarPage SelectedReportedType={selectedValue === 'Bar' ? 'bar chart' : null} /> : null}
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Box>
        </Box>
    );
}
