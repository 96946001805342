import React, { useState } from 'react'
import { Fragment } from 'react';

import { Eye, EyeOff } from 'react-feather';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Input, Row, Button } from 'reactstrap';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';

const SignUpProfileSetup = () => {


    const navigate = useNavigate()


    //  ------------------- Password show or hide manage --------------------------

    const [eyeButton, setEyeButton] = useState(false)


    const [viewSuggestions, setViewSuggestions] = useState(false)

    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }



    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")


    return (
        <Fragment>
            {/* =========================Full Login Page ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row>
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec">
                                    <Link className="" to="/">
                                        <img
                                            src="/assets/images/logo.png"
                                            alt="logo"
                                            className="img-fluid"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="login-form">
                                {/*  ------------------------------ Stepper  ------------------------------- */}
                                <LoginSignUpStepper steps={5} active={3} />
                                <div>
                                    <div className="login-title">
                                        <h2 className='Login4D_500'>What's your name?</h2>
                                    </div>
                                    <div className="login-discription">
                                        {/* <h4>Please enter your details below.</h4> */}
                                    </div>
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form" autoComplete="off">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Write your full name here"
                                                        name="fullName"
                                                        maxLength={64}
                                                        autoComplete="profile-fullName"
                                                    />
                                                    <label className="d-flex justify-content-between">
                                                        <p
                                                            className="error-input-msg invisible"
                                                        // ref={nameRef}
                                                        >
                                                            {/* {error} */}
                                                        </p>
                                                        <p className="instruction-msg">Max 64 Characters</p>
                                                    </label>
                                                </div>
                                                <div className="form-group">
                                                    <h3 className="choose-gender-blk Login4D_500">Pick a username</h3>
                                                    <p className="label-descrip-blk Login80_400">
                                                        Help your friends to find you on SocioMee with a
                                                        unique UserName
                                                    </p>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Pick a username"
                                                        name="userName"
                                                        onChange={(e) => { setViewSuggestions(e.target.value.length > 0 ? true : false) }}
                                                        maxLength={20}
                                                    />
                                                    {/* ------------------- Alert for input username -------------------- */}
                                                    {/* <label className={`d-flex justify-content-between ${viewSuggestions ? "" : "d-none"}`}>
                                                        <p
                                                            className="error-input-msg LoginAlert_600 "

                                                        >
                                                            <AlertCircle fill='#EB5757' color='#fff' className='m1-2' size={17} />
                                                            Username not available
                                                        </p>
                                                        <p className="instruction-msg">Max 20 Characters</p>
                                                    </label> */}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Pick a username</label> */}
                                                    {/* <p className="label-descrip-blk">Help your friends to find you on SocioMee with a unique Username</p> */}
                                                    {/* <input type="text" className="form-control" placeholder="Pick a username"/> */}
                                                    {/* {userNameList && ( */}
                                                    <div className={`username-suggestion ${viewSuggestions ? "" : "d-none"}`}>
                                                        <h4 className='mb-3'>
                                                            Suggestions:{" "}
                                                            <h5 className='Login2F_400 cursor-p'>
                                                                Next suggestions
                                                            </h5>
                                                        </h4>
                                                        <div className="suggestedUsernames">
                                                            {[...Array(6)].map((item, index) => (
                                                                <h6 className='Login33_400 suggestedUserNamesItem' key={index}>@abhishek_sharma</h6>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {/* )} */}
                                                </div>
                                                <div className="form-group">
                                                    <h3 className="choose-gender-blk Login4D_500">Create password</h3>
                                                    <p className="label-descrip-blk Login80_400">
                                                        Enter password of minimum 8 character with atleast
                                                        one lowercase, uppercase, number and special
                                                        character
                                                    </p>
                                                    <div className="input-block">
                                                        <input
                                                            type={!eyeButton ? "password" : "text"}
                                                            className="form-control"
                                                            placeholder="Enter your password here"
                                                            name="password"
                                                            maxLength={25}
                                                            autoComplete="off"
                                                        />


                                                        <Eye color='#C4C4C4' size={14} className={
                                                            !eyeButton
                                                                ? "input-icon iw-20 ih-20 d-none cursor-p"
                                                                : "input-icon iw-20 ih-20 cursor-p"
                                                        } onClick={() => setEyeButton(!eyeButton)} />

                                                        <EyeOff color='#C4C4C4' size={14} className={
                                                            !eyeButton
                                                                ? "input-icon iw-20 ih-20  cursor-p"
                                                                : "input-icon iw-20 ih-20 d-none cursor-p"
                                                        } onClick={() => setEyeButton(!eyeButton)} />
                                                    </div>
                                                    <label className="d-flex justify-content-between">
                                                        <p
                                                            className="error-input-msg invisible"
                                                        // ref={passwordRef}
                                                        >
                                                            ""
                                                        </p>
                                                        <p className="instruction-msg">Min 8 Characters</p>
                                                    </label>
                                                </div>
                                                <div className="bottom-sec">
                                                    <div className="form-check checkbox_animated">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="tnc"
                                                            // value={profile.tnc}
                                                            // checked={profile.tnc}
                                                            id="exampleCheck1"
                                                        // onChange={onChangeHandler}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="exampleCheck1"
                                                        >
                                                            I accept the &nbsp;
                                                            <a target='_blank' href="https://www.sociomee.com/terms-conditions/" rel="noreferrer" >Terms &amp; Conditions</a>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* <p className="error-input-msg d-none" ref={tncRef}>
                                                    {error}
                                                </p> */}
                                                <div className="btn-section">
                                                    <Button
                                                        onClick={() => { navigate("/signupdetails") }}
                                                        color="primary"
                                                    // onClick={submitHandler}
                                                    // disabled={!flag}
                                                    >
                                                        CONTINUE
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ============================= Language Section ================================== */}
                            <div className="language-selection-section">
                                <div className="lang-sel-block">
                                    <b>Choose Language:</b>
                                    <span className="language-list">
                                        {/* --------------------------- Labnguages------------------------------ */}
                                        {["English", "Hindi", "Deutsch"].map((item, index) => (
                                            <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                        ))}
                                    </span>
                                    <button onClick={() => setLanguages(true)} >
                                        ...More
                                    </button>
                                </div>
                            </div >

                            {/* ======================== Bottom Canvas Language section  ======================================= */}
                        </div>
                    </Row>
                </div>
            </section>
        </Fragment>
    )
}

export default SignUpProfileSetup
