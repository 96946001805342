import React, { useState } from 'react'
import { Fragment } from 'react'
import Group from "../Img/Grup.png"
import DefaultImg from "../Img/default_Img.png"
import EmptyImg from "../Img/empty_Img.png"
import { AlertTriangle, Lock, Globe, Mail, PhoneCall, RotateCcw, Users, Settings } from 'react-feather'
import { Button, CardBody, CardImg, CardTitle, Col, Modal, ModalBody, ModalHeader, Row, } from 'reactstrap'
import { Link } from 'react-router-dom'


const RightCreation = ({ data, confirmGroupCancelation }) => {
    const [cancelOpen, setCancelOpen] = useState(false)

    console.log('data', data && data)

    function handleMouseUp() {
        setCancelOpen(false)
    }

    const toggleCancle = () => {
        setCancelOpen((Preview) => !Preview)
    }
    const EmptyData = [
        {
            img: EmptyImg,
            name: "Person Name",
            userName: "samuel_taylor",
        },
        {
            img: EmptyImg,
            name: "Person Name",
            userName: "samuel_taylor",
        },
        {
            img: EmptyImg,
            name: "Person Name",
            userName: "samuel_taylor",
        }

    ]
    const GroupNameStatus = [
        {
            icon: <Globe size={16} color="#808080" />,
            name: "Group privacy",
            sabName: "Public"
        },
        {
            icon: <Users size={16} color="#808080" />,
            name: "Group Category",
            sabName: "Category Name"
        },
        {
            icon: <Mail size={16} color="#808080" />,
            name: "Email",
            sabName: "buzzfeed@gmail.com"
        },
        {
            icon: <PhoneCall size={16} color="#808080" />,
            name: "Contact",
            sabName: "+91 8769531246"
        },
        {
            // icon: <img src={HistoryIcon} className="img-fluid" width={20} height={20} color="#808080" />,
            icon: <RotateCcw size={16} color="#808080" />,
            name: "History",
        }
    ]

    //================== main_return_function ===================//
    return (
        <Fragment>
            <div className="col-md-12">
                <div className="group-details-top biz-details-top biz-cration-preview">
                    <div className="grp-preview">
                        <Col lg="12">
                            <Row>
                                <Col lg="8">
                                    <div className="biz-user-pro-blk-new">
                                        <h5>Create Group</h5>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="group-buttons-blk">
                                        <Button className="group-btn Scandal-clr-btn new-create-right">Create Group</Button>
                                        <Button onClick={toggleCancle} className="group-btn new-create-right">Cancel</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>

                    <CardImg
                        alt="Card image cap"
                        // src={Group}
                        src={data?.image ? `${process.env.REACT_APP_IMAGEURL}${data?.image}` : Group}
                        // src={Group}
                        className="img-fluid"
                        style={{
                            height: 316
                        }}
                    />
                    <div className="heading-title-blk head-title-biz">
                        <Row className="row align-items-center">
                            <div className="grp-preview">
                                <Col lg="12">
                                    <Row>
                                        <Col lg="10">
                                            <div className="biz-user-pro-blk-new">
                                                {/* <h5> {data && data ? data.groupName :'Group Name'}</h5> */}
                                                <h5> {data?.groupName ? data.groupName : 'Group Name'}</h5>
                                            </div>
                                        </Col>
                                        <Col lg="2">
                                            <div className='group-buttons-blk'>
                                                <div>
                                                    <p className='text-center'>- &nbsp;-</p>
                                                    <h4 className='text-muted'>Posts</h4>
                                                </div>
                                                <div className='ml-3'>
                                                    <p className='text-center'>- &nbsp;-</p>
                                                    <h4 className='text-muted'>Members</h4>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                            <Col xl="8" lg="8" className="col-12 order-lg-1">
                                <div className="group-content-area-top-blk">
                                    <div className="groupmember-blk">
                                        <span className='text-capitalize'>
                                            {data?.privacy === 'public' ? (
                                                <Globe style={{ marginTop: "-3px", marginRight: "4px" }} size={14} color="#808080" />
                                            ) : (
                                                <Lock style={{ marginTop: "-3px", marginRight: "4px" }} size={14} color="#808080" />
                                            )}
                                            {data.privacy ? data.privacy : 'Select you'} group</span>
                                        • <span>{data.selectedCategoryName ? data.selectedCategoryName : "Category"}</span>
                                    </div>
                                    <div >
                                        <p className='text-muted'> {data?.description ? data.description : 'No description to show.'}  </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" lg="4" className="col-12 order-lg-3">
                                <div className="create-biz-btn">
                                    <Button className="btn btn-primary green-clr-btn">Invite</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='Tab-title-blk'>
                    <Row className="profile-menu section-t-space biz-cration-menu-preview">
                        <Col md="8">
                            <ul>
                                <li>
                                    <Link to="#" >
                                        <h6>Discussion</h6>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <h6>Members</h6>
                                    </Link>
                                </li>
                                <li>
                                    <Link href="#" className="active-disable">
                                        <h6>About</h6>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4" className="d-flex justify-content-end">
                            <ul className="right-menu d-xl-flex d-none biz-cration-menu-preview">
                                <li>
                                    <Link to="#">
                                        <Settings size={18} color='#ACABAB' />
                                        {/* <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> */}
                                        <h6>Setting</h6>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className="">
                    {/* container-fluid section-t-space px-0 col-lg-12 */}
                    <Row className="page-content">
                        <Col lg="5">
                            <div className="gallery-section section-b-space">
                                <CardBody>
                                    <CardTitle className='d-dlex justify-content-between Creation_Heading_section'>
                                        <h4>Gallery</h4>
                                        <p>See all →</p>
                                    </CardTitle>
                                    <Row className="mt-3">
                                        <Col md="6">
                                            <div className='d-flex'>
                                                <CardImg src={DefaultImg} alt="default" className="img-fluid" />
                                                <CardImg src={DefaultImg} alt="default" className="img-fluid" />
                                            </div>
                                            <div className='d-flex'>
                                                <CardImg src={DefaultImg} alt="default" className="img-fluid" />
                                                <CardImg src={DefaultImg} alt="default" className="img-fluid" />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div>
                                                <CardImg src={DefaultImg} alt="default" className="img-fluid" style={{
                                                    height: 141
                                                }}
                                                    width="100%" />
                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </div>
                            <div className="gallery-section section-b-space">
                                <CardBody>
                                    <CardTitle className='d-dlex justify-content-between Creation_Heading_section'>
                                        <h4>Invite to like your page</h4>
                                        <p>See all →</p>
                                    </CardTitle>
                                    <Row>
                                        <Col md="12">
                                            {
                                                EmptyData.map((data) => (
                                                    <div className='d-flex justify-content-between mt-3'>
                                                        <div className='d-flex align-items-center '>
                                                            <img src={data.img} alt="profile" className='img-fluid' />
                                                            <div className='Invitaion_User_Profile ml-2'>
                                                                <h4>{data.name}</h4>
                                                                <p>{data.userName}</p>
                                                            </div>
                                                        </div>
                                                        <div className='Block-btns-blk'>
                                                            <Button className="Invite_person_btn">Invite</Button>

                                                        </div>
                                                    </div>

                                                ))
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </Col>
                        <Col lg="7 ">
                            <div className="About-card-block  ">
                                <CardTitle className="custom-card-head">
                                    <div className='Group_Title_Section'>
                                        <h4>{data?.groupName ? data.groupName : 'Group Name'}</h4>
                                        <p className='mt-3'>{data?.description ? data.description : 'No description to show.'}</p>
                                    </div>
                                </CardTitle>
                                <div className="biz-about-cont" style={{ minHeight: '350px' }}>
                                    <ul>
                                        <li>


                                            <table width={'100%'}>
                                                <tr>
                                                    <td width={'5%'}>
                                                        <div className='About_Img-Section' >
                                                            <Globe size={16} color="#808080" />
                                                        </div>
                                                    </td>
                                                    <td width={'95%'}>
                                                        <h3 style={{ paddingTop: '8px', marginLeft: '8px' }}>Group Name </h3>
                                                        <p className='py-2 ml-2'>{data?.groupName ? data.groupName : 'No group name to show.'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='About_Img-Section' >
                                                            <Users size={16} color="#808080" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h3 style={{ paddingTop: '8px', marginLeft: '8px' }}>Category </h3>
                                                        <p className='py-2  ml-2'>{data?.selectedCategoryName ? data.selectedCategoryName : 'Category not selected yet.'}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className='About_Img-Section' >
                                                            <Mail size={16} color="#808080" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h3 style={{ paddingTop: '8px', marginLeft: '8px' }}>Email </h3>
                                                        <p className='py-2 ml-2'>{data?.email ? data.email : 'No email to show'}</p> </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className='About_Img-Section' >
                                                            <PhoneCall size={16} color="#808080" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h3 style={{ paddingTop: '8px', marginLeft: '8px' }}>Contact </h3>
                                                        <p className='py-2 ml-2'>{data?.contact ? data.contact : 'No mob to show'}</p> </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className='About_Img-Section' >
                                                            <PhoneCall size={16} color="#808080" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h4 style={{ paddingTop: '8px', marginLeft: '8px' }}>History </h4>
                                                        <p className='py-2 ml-2'>{data?.history ? data.history : 'No mob to show'}</p> </td>
                                                </tr>
                                            </table>
                                            {/*                                             
                                            <div className='ml-3 Create_Group_section'>

                                            
                                                </div> */}

                                        </li>


                                        {/* 
                                        <li>
                                            {
                                                GroupNameStatus.map((data) => (
                                                    <div className='d-flex align-items-start   About_Main_section'>
                                                        <div className='About_Img-Section' >
                                                            {data.icon}
                                                        </div>
                                                        <div className='ml-3 Create_Group_section'>
                                                            <h4>{data.name}klklklklklk</h4>
                                                            <p className='py-2'>{data.sabName}</p>
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='Cancel_Modal'>
                {
                    cancelOpen !== false &&
                    <Modal isOpen={cancelOpen} toggle={toggleCancle} className='modal-dialog-centered' >
                        <ModalHeader className="d-flex justify-content-center Create_Group_Heading_Section">
                            <div>
                                <AlertTriangle size={27} />
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center Create_Group_Body_Section '>
                                <h4>Do you really want to cancel the group Creation?</h4>
                            </div>
                            <div className='Button_section'>
                                <Button className="Creation_Cnfm_btn" onMouseDown={confirmGroupCancelation} onMouseUp={handleMouseUp}>Confirm</Button>
                                <Button className="Creation_Cncle_btn ml-2" onClick={() => { setCancelOpen(false) }} outline>Cancel*</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
        </Fragment >
    )
}

export default RightCreation
