import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ArrowLeft, ArrowRight, ChevronDown, Copy, Download, Edit, Filter, Grid, Paperclip, Plus, RotateCcw, Search, Trash2 } from 'react-feather'
import ReactPaginate from 'react-paginate'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Badge, Button, Card, CardBody, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import coverImage from '../../../NewComponents/IMG/reel4.png'
import ChartIcon from '../../../NewComponents/IMG/AdmangerIconChart.svg'
import BlackPin from '../../../NewComponents/IMG/BlackPin.svg'
import { Pagination, Stack } from '@mui/material'


const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none' // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: '#E5F0E0',
            fontSize: '12px',
            paddingLeft: '12px', // override the cell padding for head cells
            paddingRight: '4px',
            whiteSpace: '',
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            // marginTop: '1%',
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: '400'
        }
    }
}


const ApprovalTable = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])
    const [checkList, setCheckList] = useState({
        serialnoCheck: true,
        adNameCheck: true,
        adIdCheck: true,
        adTypeCheck: true,
        totalImpressionsCheck: true,
        totalReachCheck: true,
        clickCheck: true,
        viewCheck: true,
        leadCheck: true,
        totalAmountCheck: true,
        dailyBudgetCheck: true,
        overallBudgetCheck: true,
        startDateCheck: true,
        endDateCheck: true,
        lastSignificantCheck: true,
    })

    // const handleSearch = (event) => {
    //   setSearchValue(event.target.value)
    //   setRender(!render)
    // }

    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }

    const [deleteallModal, setDeleteallModal] = useState(false)

    const DeletedModal = () => {
        return (
            <>
                <Modal isOpen={deleteallModal} className='modal-dialog-centered' toggle={() => setDeleteallModal(!deleteallModal)}>
                    {/* <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader> */}
                    <ModalBody>
                        <div className='text-center mb-2'>
                            <Trash2 size={70} color="#EA5455" />
                            <h3 className='mt-2'>Do You Really Want to Delete all ?</h3>
                            <h5>({selectedRows.length})</h5>
                        </div>
                        <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the ‘Action History’ screen." />
                        </InputGroup>
                        <div className="d-flex justify-content-center m-1 mt-3">
                            <div >  <Button type="submit" color="danger" outline >Yes , Delete it</Button></div>
                            <div className='ml-3'><Button color="success" outline onClick={() => setDeleteallModal(!deleteallModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

    const tabledata = [
        {
            id: '1',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#4567898',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z',
            status: 'Pending'
        },
        {
            id: '2',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#4567898',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z',
            status: 'Approved'
        },
        {
            id: '3',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#4567898',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z',
            status: 'Rejected'
        },
        {
            id: '4',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#456788',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z',
            status: 'Rejected'
        },
        {
            id: '5',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#456789',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z',
            status: 'Approved'
        }
    ]

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
            omit: !checkList.serialnoCheck,
            center: true,
            sortable: true
        },

        // {
        //     name: 'Off/On',
        //     sortable: true,
        //     omit: !checkList.OffOnCheck,
        //     minWidth: "150px",
        //     cell: row => (
        //         <div className='d-flex align-items-center fontForSubTablheading'>
        //             <h5>Off</h5>
        //             <label className="switch m-1">
        //                 <input type="checkbox" />
        //                 <span className="slider round"></span>
        //             </label>
        //             <h5>On</h5>
        //         </div>
        //     )

        // },

        {
            name: "Ad Name",
            // selector: row => row.userName,
            minWidth: "320px",
            omit: !checkList.adNameCheck,
            sortable: true,
            cell: row => (
                <Link to="#" className='align-items-start d-flex pb-2 '>
                    <div className='align-items-start'>
                        <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />
                    </div>
                    <div style={{ color: '#4D4D4D' }} className='ml-1 onHoverForshowed fontForSubTablheading'>
                        <h4 to="#" className='mb-2' style={{ fontSize: '14px' }}>
                            {row.Adname}
                        </h4 >
                        <div className='d-flex OnMouseDataShow'>
                            {/* <div className='d-flex text-primary align-items-center'>
                                <img src={ChartIcon} />
                                <NavLink className='ml-1' to={'/AdAnalysis'}><u>View Charts</u></NavLink>
                            </div> */}
                            <h6 className='mx-2'><Copy strokeWidth={4} color='#6E6B7B' size={12} /> <u style={{ color: '#0066FF' }} >Duplicate</u></h6>
                            <div className='d-flex align-items-center'>
                                <h6><Edit strokeWidth={4} color='#6E6B7B' size={12} /> Edit</h6>
                                &nbsp;<img src={BlackPin} alt='' />&nbsp;<h6>Pin</h6>
                            </div>
                        </div>
                    </div>
                </Link>
            )
        },

        {
            name: 'Ad ID',
            sortable: true,
            omit: !checkList.adIdCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className="d-flex align-items-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '12px', marginLeft: '20px' }}>
                        {row.AdId}
                    </h5>
                </div>
            )

        },
        {
            name: 'Ad Type',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "200px",
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '6px' }}>
                        {row.AdType}
                    </h5>
                </div>
            )

        },

        // {
        //     name: 'spent Amount',
        //     sortable: true,
        //     omit: !checkList.totalAmountCheck,
        //     maxWidth: "400px",
        //     cell: row => (
        //         <div className="d-flex align-items-center text-danger" >
        //             <h5>
        //                 {row.totalAmountSpent}
        //             </h5>
        //         </div>
        //     )

        // },
        {
            name: 'Daily Budget',
            sortable: true,
            omit: !checkList.dailyBudgetCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div className="d-flex align-items-center fontForSubTablheading" >
                    <h5 className='text-primary' style={{ fontSize: '12px', marginLeft: '-33px' }}>
                        {row.dailBudget}
                    </h5>
                </div>
            )

        },
        // {
        //     name: 'Over all Budget',
        //     sortable: true,
        //     omit: !checkList.overallBudgetCheck,
        //     minWidth: "180px",
        //     cell: row => (
        //         <div className="d-flex align-items-center text-info" >
        //             <h5>
        //                 {row.overAllBudget}
        //             </h5>
        //         </div>
        //     )

        // },

        {
            name: "Start Date & Time",
            minWidth: "180px",
            omit: !checkList.startDateCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.startDateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.startDateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Ending Date & Time",
            minWidth: "200px",
            omit: !checkList.endDateCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.endDateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.endDateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Last edited",
            minWidth: "200px",
            omit: !checkList.lastSignificantCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.lastSingnificantEdit).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.lastSingnificantEdit).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Status",
            minWidth: "200px",
            omit: !checkList.lastSignificantCheck,
            sortable: true,
            cell: row => (
                <div>
                    {row.status === 'Pending' ? <Badge color="warning" className='badge-warning' pill >{row.status}</Badge> : null}
                    {row.status === 'Rejected' ? <Badge color="danger" className='badge-danger' pill >{row.status}</Badge> : null}
                    {row.status === 'Approved' ? <Badge color='primary' pill >{row.status}</Badge> : null}
                </div>
            )
        }
    ]


    const checkListArr = [
        {
            check: {
                ...checkList,
                serialnoCheck: !checkList.serialnoCheck
            },
            state: checkList.serialnoCheck,
            name: "Sr. No"
        },
        {
            check: {
                ...checkList,
                adNameCheck: !checkList.adNameCheck
            },
            state: checkList.adNameCheck,
            name: "Ad Name"
        },
        {
            check: {
                ...checkList,
                adIdCheck: !checkList.adIdCheck
            },
            state: checkList.adIdCheck,
            name: "Ad ID"
        },
        {
            check: {
                ...checkList,
                adTypeCheck: !checkList.adTypeCheck
            },
            state: checkList.adTypeCheck,
            name: "Ad Type"
        },
        {
            check: {
                ...checkList,
                dailyBudgetCheck: !checkList.dailyBudgetCheck
            },
            state: checkList.dailyBudgetCheck,
            name: "Daily Budget"
        },
        {
            check: {
                ...checkList,
                startDateCheck: !checkList.startDateCheck
            },
            state: checkList.startDateCheck,
            name: "Start Date & Time"
        },
        {
            check: {
                ...checkList,
                endDateCheck: !checkList.endDateCheck
            },
            state: checkList.endDateCheck,
            name: "Ending Date & Time"
        },
        {
            check: {
                ...checkList,
                lastSignificantCheck: !checkList.lastSignificantCheck
            },
            state: checkList.lastSignificantCheck,
            name: "Last significant edit"
        }
    ]

    const Cardheader = () => {
        return (<div className='nav-flex-container mx-3 my-3'>
            <div className='nav-flex size-sm-mt-4'>
                <div className='mr-1'>
                    <Button color='primary font-weight-bold'
                        onClick={() => navigate('/CreateAd')}
                    >
                        <Plus size={18}
                        /> Create new Ad
                    </Button>
                </div>
                <div className='mr-1'>
                    <Button outline color='primary' className='font-weight-bold'>
                        <Download size={18} /> Export </Button>
                </div>
            </div >

            <div className='nav-flex size-sm-mt-4'>
                <div style={{ position: 'relative' }} className='d-flex justify-content-between '>

                    <Input type='search' style={{ paddingLeft: '25px', marginTop: '3px' }} size='58' className='' Placeholder='Search here...' />
                    <div style={{ position: 'absolute', top: '9px', marginLeft: '5px' }}>
                        <Search size={15} />
                    </div>&nbsp;&nbsp;
                    {/* <div>
                        <button className='form-control mt-1' style={{ backgroundColor: 'rgba(129, 193, 75, 1)', color: 'white', border: 'none' }}>Search</button>
                    </div> */}
                </div>
                <div className='mt-1'>
                    <UncontrolledButtonDropdown direction='down'>
                        <DropdownToggle color="transparent" className="ml-1 p-0" >
                            <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                <Filter size={22} color='#81C14B' />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className='shadow runningdrop' style={{ zIndex: '100', minWidth: 'fit-content', marginLeft: '-10rem', marginTop: '3px' }}>
                            <DropdownItem tag='a'>Brand Awareness (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Website(Link) Visits (CPC)</DropdownItem>
                            <DropdownItem tag='a'>Post Engagement (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Video Views (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Lead Generation (CPL)</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="transparent" className="ml-1 p-0" >
                            <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                <Grid size={22} color='#81C14B' />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className='scrollForAdmanagerFilter dropedownForFilter runningdroplist mt-2' right style={{ position: 'absolute', willChange: 'transform', top: '0rem', left: '-20px', transform: "translate3d(-132px, 36px, 0px)" }}>
                            {checkListArr.map((value, i) => {
                                return (
                                    <DropdownItem className='w-100' onClick={() => setCheckList(value.check)} >
                                        <CustomInput type="checkbox" className='customcheckboxForFilter' id={`group-checkbox-${i}`} label={value.name} style={{ cursor: "pointer" }} checked={value.state} />
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        </div >
        )
    }

    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }

    const CustomPagination = () => {

        const count = Number(Math.ceil(totalItems / rowsPerPage))

        return (
            <div className='d-flex justify-content-between mt-2'>
                <div className="d-flex align-items-center w-20">
                    <Label for='row-per-page' className='text-nowrap'>Row per page</Label>
                    <CustomInput
                        className="form-control mx-50 px-1.5 ml-1 w-50"
                        style={{ backgroundColor: '#E5F0E0' }}
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={onChangeRowsPerValue}
                    >
                        <option value='5'>5 Row</option>
                        <option value='10'>10 Row</option>
                        <option value='25'>25 Row</option>
                        <option value='50'>50 Row</option>
                        <option value='100'>100 Row</option>
                    </CustomInput>
                </div>
                {/* <ReactPaginate
                    previousLabel={<ArrowLeft color='#4D4D4D' size={15} />}
                    nextLabel={<ArrowRight color='#4D4D4D' size={15} />}
                    pageCount={count}
                    activeClassName='active'
                    forcePage={currentPage}
                    onPageChange={page => {
                        setCurrentPage(page.selected)
                        setRender(!render)
                    }}
                    pageClassName='page-item'
                    nextClassName='page-item next'
                    nextLinkClassName='page-link'
                    previousClassName='page-item prev'
                    previousLinkClassName='page-link'
                    pageLinkClassName='page-link'
                    containerClassName="pagination react-paginate justify-content-end my-2 pr-1"
                /> */}
                <div className='d-flex justify-content-center mb-2'>
                    <Stack>
                        <Pagination count={10} className='PaginationCount'>
                        </Pagination>
                    </Stack>
                </div>
            </div>
        )
    }


    useEffect(() => {
        // getPostfeedLikedetails()
    }, [render])

    return (

        <div>
            <Card>
                <CardBody className='py-0'>
                    <DeletedModal />
                    <div className='mb-2'>
                        <Cardheader />
                    </div>
                    {
                        selectedRows.length !== 0 &&
                        <div style={{ backgroundColor: '#E3F2FD' }} className='d-flex justify-content-between align-items-center p-2 mb-1'>
                            <h4> {selectedRows.length} item selected</h4>
                            {/* <Col>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <Button color='primary' outline >
                                        Preview
                                    </Button>
                                    <Button color='primary mx-2' outline >
                                        Reports
                                    </Button>
                                    <Button color='primary' outline >
                                        Start
                                    </Button>
                                </div>
                            </Col> */}
                            {/* <Button color='danger' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                                Discard Drafts1
                            </Button> */}
                        </div>
                    }
                    <Row>
                        <Col>
                            <DataTable
                                // className='react-dataTable'
                                noHeader
                                responsive
                                // selectableRowSelected={selectedRows}
                                selectableRows
                                paginationServer
                                selectableRowsHighlight
                                highlightOnHover
                                pointerOnHover
                                onSelectedRowsChange={handleChange}
                                sortIcon={<ChevronDown />}
                                paginationComponent={CustomPagination}
                                columns={columns}
                                data={tabledata}
                                customStyles={styleTable}
                                progressPending={loading}
                            />
                        </Col>
                    </Row>
                    <CustomPagination />
                </CardBody>
            </Card>
        </div>
    )
}

export default ApprovalTable