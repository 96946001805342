import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Fragment } from "react";
import {
  Edit2,
  EyeOff,
  Link2,
  MoreVertical,
  Navigation,
  Trash2,
  Smile,
  Send,
} from "react-feather";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Input } from "reactstrap";
// import Like from '../../../profile-components/Img/Like.svg'
import Snd from "../../../profile-components/Img/snd.png";
import { useEffect } from "react";
import PostComment from "../../../../components/Social-feed/Components/PostComment";
import ShrImg from "../../../../NewComponents/IMG/ShrImg.svg";
import CommentActive from "../../../../NewComponents/IMG/CommentActive.svg";
// import "./style.css"
import Emoji from "../../../Social-feed/Emoji";
let gallerySettings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "0",
};

function MediaPreview2({
  mediaURL,
  videoURL,
  caption,
  text,
  hashtags,
  setShowModal,
  profileImage,
  fullName,
  shareCount,
  likeCount,
  commentCount,
  postType,
  setFetchedPost,
  toggleLike,
  isliked,
  ActiveCirclelike,
  LikeImg,
  handelShare,
  commentData,
  setCommentData,
  handleKeypress,
  setCommentCount,
  handleComment,
  deleteComment,
  SetComment,
  setCommentIds,
  showPostComment,
  setShowPostComment,
  allURL,
}) {
  // const [showPostComment,setShowPostComment]=useState(true)
  const [modal, setModal] = useState(true);
  const [urls, setUrls] = useState();
  const toggle = () => {
    setModal(!modal);
    setShowModal();
  };
  useEffect(() => {}, [commentData, commentCount]);
  // console.log("media urls   ", mediaURL);

  const handleCommentData = (e) => {
    setCommentData(e.target.value);
  };
  const handleEmojiSelect = (emoji) => {
    const updatedText = `${commentData || ""}${emoji}`;
    setCommentData(updatedText);
  };

  return (
    <Fragment>
      <div
        className="modal comment-model show "
        id="imageModel"
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
        style={{ display: "block", paddingRight: "2px" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div>
              <div className="image-gallery">
                <div className="row m-0">
                  <div
                    className="col-xl-9 col-lg-8 p-0"
                    style={{ background: "rgba(0,0,0,0.8)" }}
                  >
                    <Link to="#" data-bs-dismiss="modal">
                      <svg
                        onClick={() => setShowModal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-light close-btn"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </Link>
                    <Slider {...gallerySettings} className="default-space">
                      {mediaURL &&
                        mediaURL.map((url, index) => {
                          return (
                            <div key={index}>
                              <div
                                className="img-part"
                                style={{ height: "824px" }}
                              >
                                <img
                                  src={url}
                                  alt=""
                                  tyle={{
                                    height: "80%",
                                    width: "80%",
                                    objectFit: "cover",
                                    marginBottom: "64px",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      {videoURL &&
                        videoURL.map((url, index) => {
                          return (
                            <div key={index}>
                              <div
                                className="img-part"
                                style={{ height: "824px" }}
                              >
                                <video
                                  style={{
                                    height: "80%",
                                    width: "80%",
                                    objectFit: "cover",
                                    marginBottom: "64px",
                                  }}
                                  src={url.fileURL}
                                  controls // Add controls for video playback
                                />
                              </div>
                            </div>
                          );
                        })}
                      {allURL &&
                        allURL.map((url, index) => {
                          if (url.fileType === "image") {
                            return (
                              <div key={index}>
                                <div
                                  className="img-part"
                                  style={{ height: "824px" }}
                                >
                                  <img
                                    src={url.fileURL}
                                    alt=""
                                    style={{
                                      height: "80%",
                                      width: "80%",
                                      objectFit: "cover",
                                      marginBottom: "64px",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else if (url.fileType === "video") {
                            return (
                              <div key={index}>
                                <div
                                  className="img-part"
                                  style={{ height: "824px" }}
                                >
                                  <video
                                    //   className="h-100 w-100 borderForgrid "
                                    style={{
                                      height: "80%",
                                      width: "80%",
                                      objectFit: "cover",
                                      marginBottom: "64px",
                                    }}
                                    src={url.fileURL}
                                    controls // Add controls for video playback
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                    </Slider>
                  </div>
                  <div className="col-xl-3 col-lg-4 p-0">
                    <div className="comment-part">
                      <div className="user-detail">
                        <div className="user-media">
                          <div className="media">
                            <Link className="user-img">
                            <img
                              src={postType.profileImageThumb || "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png"}
                              className="img-fluid bg-img"
                              alt="user"
                            />

                              <span className="available-stats"></span>
                            </Link>
                            <div className="media-body">
                              <h5 className="user-name">{fullName}</h5>
                            </div>
                          </div>
                        </div>
                        {/* <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <div data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                            <MoreVertical color='#4B4B4B' size={20} />
                                                        </div>
                                                        <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                                            <ul>
                                                                <li>
                                                                    <Link to="#"><EyeOff size={15} color="#979797" />Hide post from timeline</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Link2 size={15} color="#979797" />Copy link </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Edit2 size={15} color="#979797" />Edit Post</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Trash2 size={15} color="#979797" />Delete</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Navigation size={15} color="#979797" />Share</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}
                      </div>
                      <div className="post-panel mb-0">
                        <div className="post-wrapper">
                          <div
                            className="post-details"
                            style={{ width: "325px" }}
                          >
                            <div className="like-panel">
                              <div className="left-emoji">
                                <ul>
                                  {/* <li>
                                                                        <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                    </li> */}
                                  <li>
                                    <span className="d-flex align-items-center">
                                      {likeCount}&nbsp;{" "}
                                      <p className="text-muted">LIKES</p>
                                    </span>
                                  </li>
                                </ul>
                                {/* <h6>+75</h6> */}
                              </div>
                              <div className="right-stats">
                                <ul>
                                  <li>
                                    <span className="d-flex align-items-center">
                                      {commentCount}&nbsp;{" "}
                                      <p className="text-muted">Comment</p>
                                    </span>
                                  </li>
                                  <li>
                                    <span className="d-flex align-items-center">
                                      {shareCount} &nbsp;
                                      <p className="text-muted">Share</p>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="post-react">
                              <ul>
                                <div className="d-flex align-items-center w-33">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      toggleLike(postType);
                                    }}
                                    width={35}
                                    src={isliked ? ActiveCirclelike : LikeImg}
                                    alt="Like Icon"
                                  />
                                </div>
                                <div className="text-center">
                                  <img
                                    width={35}
                                    src={CommentActive}
                                    alt="commentIcon"
                                  />
                                </div>

                                <div
                                  className="d-flex align-items-center"
                                  onClick={handelShare}
                                >
                                  <div className="text-center">
                                    <img
                                      width={35}
                                      src={ShrImg}
                                      alt="shareIcon"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </ul>
                            </div>
                            <div className="comment-section">
                              {showPostComment && (
                                <PostComment
                                  postId={postType?.postId}
                                  allowComments={postType?.allowComments}
                                  setFetchedPost={setFetchedPost}
                                  deleteComment={deleteComment}
                                  commentCount={commentCount}
                                  SetComment={SetComment}
                                  setCommentsData={setCommentData}
                                  setCommentIds={setCommentIds}
                                  setShowPostComment={setShowPostComment}
                                />
                              )}
                              <div className="reply">
                                <div className="search-input input-style input-lg icon-right">
                                  <Input
                                    type="text"
                                    className="form-control emojiPicker"
                                    placeholder="Comment...."
                                    value={commentData}
                                    onChange={handleCommentData}
                                    onKeyPress={handleKeypress}
                                  />
                                  {/* <Link to="#">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                                    </Link> */}
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        marginTop: "-33px",
                                        marginRight: "80px",
                                        marginLeft: "189px",
                                      }}
                                    >
                                      <Emoji
                                        onEmojiSelect={handleEmojiSelect}
                                      />
                                    </div>

                                    <div
                                      className="Media_Modal_send_Icon"
                                      onClick={() => {
                                        handleComment(
                                          postType.postId,
                                          commentData,
                                          setCommentCount,
                                          commentCount,
                                          setCommentData,
                                          setShowPostComment
                                        );
                                      }}
                                    >
                                      <img
                                        src={Snd}
                                        width={14}
                                        height={14}
                                        alt="snd"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}


function SharedMediaPreview2({
  mediaURL,
  videoURL,
  caption,
  text,
  hashtags,
  setShowModal,
  profileImage,
  fullName,
  shareCount,
  likeCount,
  commentCount,
  postType,
  setFetchedPost,
  toggleLike,
  isliked,
  ActiveCirclelike,
  LikeImg,
  handelShare,
  commentData,
  setCommentData,
  handleKeypress,
  setCommentCount,
  handleComment,
  deleteComment,
  SetComment,
  setCommentIds,
  showPostComment,
  setShowPostComment,
  allURL,
}) {
  // const [showPostComment,setShowPostComment]=useState(true)
  const [modal, setModal] = useState(true);
  const [urls, setUrls] = useState();
  const toggle = () => {
    setModal(!modal);
    setShowModal();
  };
  useEffect(() => {}, [commentData, commentCount]);
  // console.log("media urls   ", mediaURL);

  const handleCommentData = (e) => {
    setCommentData(e.target.value);
  };
  const handleEmojiSelect = (emoji) => {
    const updatedText = `${commentData || ""}${emoji}`;
    setCommentData(updatedText);
  };

  return (
    <Fragment>
      <div
        className="modal comment-model show "
        id="imageModel"
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
        style={{ display: "block", paddingRight: "2px" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div>
              <div className="image-gallery">
                <div className="row m-0">
                  <div
                    className="col-xl-9 col-lg-8 p-0"
                    style={{ background: "rgba(0,0,0,0.8)" }}
                  >
                    <Link to="#" data-bs-dismiss="modal">
                      <svg
                        onClick={() => setShowModal(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-light close-btn"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </Link>
                    <Slider {...gallerySettings} className="default-space">
                      {postType.reSharedPost?.[0]?.mediaURL &&
                        postType.reSharedPost?.[0]?.mediaURL.map((url, index) => {
                          return (
                            <div key={index}>
                              <div
                                className="img-part"
                                style={{ height: "824px" }}
                              >
                                <img
                                  src={url}
                                  alt=""
                                  tyle={{
                                    height: "80%",
                                    width: "80%",
                                    objectFit: "cover",
                                    marginBottom: "64px",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      {videoURL &&
                        videoURL.map((url, index) => {
                          return (
                            <div key={index}>
                              <div
                                className="img-part"
                                style={{ height: "824px" }}
                              >
                                <video
                                  style={{
                                    height: "80%",
                                    width: "80%",
                                    objectFit: "cover",
                                    marginBottom: "64px",
                                  }}
                                  src={url.fileURL}
                                  controls // Add controls for video playback
                                />
                              </div>
                            </div>
                          );
                        })}
                      {allURL &&
                        allURL.map((url, index) => {
                          if (url.fileType === "image") {
                            return (
                              <div key={index}>
                                <div
                                  className="img-part"
                                  style={{ height: "824px" }}
                                >
                                  <img
                                    src={url.fileURL}
                                    alt=""
                                    style={{
                                      height: "80%",
                                      width: "80%",
                                      objectFit: "cover",
                                      marginBottom: "64px",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else if (url.fileType === "video") {
                            return (
                              <div key={index}>
                                <div
                                  className="img-part"
                                  style={{ height: "824px" }}
                                >
                                  <video
                                    //   className="h-100 w-100 borderForgrid "
                                    style={{
                                      height: "80%",
                                      width: "80%",
                                      objectFit: "cover",
                                      marginBottom: "64px",
                                    }}
                                    src={url.fileURL}
                                    controls // Add controls for video playback
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                    </Slider>
                  </div>
                  <div className="col-xl-3 col-lg-4 p-0">
                    <div className="comment-part">
                      <div className="user-detail">
                        <div className="user-media">
                          <div className="media">
                            <Link className="user-img">
                            <img
                              src={postType.profileImageThumb || "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png"}
                              className="img-fluid bg-img"
                              alt="user"
                            />

                              <span className="available-stats"></span>
                            </Link>
                            <div className="media-body">
                              <h5 className="user-name">{fullName}</h5>
                            </div>
                          </div>
                        </div>
                        {/* <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <div data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                            <MoreVertical color='#4B4B4B' size={20} />
                                                        </div>
                                                        <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                                            <ul>
                                                                <li>
                                                                    <Link to="#"><EyeOff size={15} color="#979797" />Hide post from timeline</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Link2 size={15} color="#979797" />Copy link </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Edit2 size={15} color="#979797" />Edit Post</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Trash2 size={15} color="#979797" />Delete</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#"><Navigation size={15} color="#979797" />Share</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}
                      </div>
                      <div className="post-panel mb-0">
                        <div className="post-wrapper">
                          <div
                            className="post-details"
                            style={{ width: "325px" }}
                          >
                            <div className="like-panel">
                              <div className="left-emoji">
                                <ul>
                                  {/* <li>
                                                                        <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                    </li> */}
                                  <li>
                                    <span className="d-flex align-items-center">
                                      {likeCount}&nbsp;{" "}
                                      <p className="text-muted">LIKES</p>
                                    </span>
                                  </li>
                                </ul>
                                {/* <h6>+75</h6> */}
                              </div>
                              <div className="right-stats">
                                <ul>
                                  <li>
                                    <span className="d-flex align-items-center">
                                      {commentCount}&nbsp;{" "}
                                      <p className="text-muted">Comment</p>
                                    </span>
                                  </li>
                                  <li>
                                    <span className="d-flex align-items-center">
                                      {shareCount} &nbsp;
                                      <p className="text-muted">Share</p>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="post-react">
                              <ul>
                                <div className="d-flex align-items-center w-33">
                                  <img
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      toggleLike(postType);
                                    }}
                                    width={35}
                                    src={isliked ? ActiveCirclelike : LikeImg}
                                    alt="Like Icon"
                                  />
                                </div>
                                <div className="text-center">
                                  <img
                                    width={35}
                                    src={CommentActive}
                                    alt="commentIcon"
                                  />
                                </div>

                                <div
                                  className="d-flex align-items-center"
                                  onClick={handelShare}
                                >
                                  <div className="text-center">
                                    <img
                                      width={35}
                                      src={ShrImg}
                                      alt="shareIcon"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                              </ul>
                            </div>
                            <div className="comment-section">
                              {showPostComment && (
                                <PostComment
                                  postId={postType?.postId}
                                  allowComments={postType?.allowComments}
                                  setFetchedPost={setFetchedPost}
                                  deleteComment={deleteComment}
                                  commentCount={commentCount}
                                  SetComment={SetComment}
                                  setCommentsData={setCommentData}
                                  setCommentIds={setCommentIds}
                                  setShowPostComment={setShowPostComment}
                                />
                              )}
                              <div className="reply">
                                <div className="search-input input-style input-lg icon-right">
                                  <Input
                                    type="text"
                                    className="form-control emojiPicker"
                                    placeholder="Comment...."
                                    value={commentData}
                                    onChange={handleCommentData}
                                    onKeyPress={handleKeypress}
                                  />
                                  {/* <Link to="#">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                                    </Link> */}
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        marginTop: "-33px",
                                        marginRight: "80px",
                                        marginLeft: "189px",
                                      }}
                                    >
                                      <Emoji
                                        onEmojiSelect={handleEmojiSelect}
                                      />
                                    </div>

                                    <div
                                      className="Media_Modal_send_Icon"
                                      onClick={() => {
                                        handleComment(
                                          postType.postId,
                                          commentData,
                                          setCommentCount,
                                          commentCount,
                                          setCommentData,
                                          setShowPostComment
                                        );
                                      }}
                                    >
                                      <img
                                        src={Snd}
                                        width={14}
                                        height={14}
                                        alt="snd"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export { MediaPreview2, SharedMediaPreview2 };
