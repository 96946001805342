import React, { useState } from 'react'
import { Download, Eye, MessageCircle, MoreVertical, Music, Send, Copy, Edit, EyeOff, Trash2, Share, Grid, List, Archive, CheckCircle, CornerUpLeft, ChevronLeft } from 'react-feather'
import { Card, UncontrolledTooltip, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, Button } from 'reactstrap'
import like from '../../../NewComponents/IMG/svg_like.svg'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { NavLink } from 'react-router-dom'
import { ShotzListView } from '../shotz_list_view/ShotzListView'
import { ShotzCarouselComponents } from './ShotzCarouselComponents';
import DeletedModal from '../ShotzModal/DeletedModal';
import HideModal from '../ShotzModal/HideModal';
import ArchiveModal from '../ShotzModal/ArchiveModal';
import { EditModal } from '../ShotzModal/EditModal';
import ShareModal from '../ShotzModal/ShareModal';
import DownloadModal from '../ShotzModal/DownloadModal';
import { SingleDeleteModal } from '../ShotzModal/SingleDeleteModal';
import { ArchivedModal, ShotzPopup } from '../ArchivedModal';
import '../../Shotzs/ShotzStyle.css'
import ShotzMediaModal from './ShotzMediaModal'
import { useUserAuth } from '../../../Context/userAuthContext';
import Checkbox from '@mui/material/Checkbox';
export const ShotzCard = () => {

  const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();

  const [Gridview, Setgridview] = useState(false)
  const [forwardOpen, setForwardOpen] = useState(false)

  const toggleForward = () => {
    setForwardOpen((preState) => !preState)
  }

  // ------------- Download Modal State -------------
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const toggleDownloadModalOpen = () => {
    setDownloadModalOpen((prevState) => !prevState)
  }

  // -------------Single Download Modal State -------------
  const [singleDownload, setSingleDownload] = useState(false)
  const toggleSingleDownload = () => {
    setSingleDownload((prevState) => !prevState)
  }

  // ------------- shotz Carousel Modal State -------------
  // const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
  const toggleShotzCarouselModal = () => {
    setShotzCarouselModal((prevState) => !prevState)
  }
  // ------------- Delete Modal State -------------
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const toggleDeleteModalOpen = () => {
    setDeleteModalOpen((prevState) => !prevState)
  }

  // -------------Single Delete Modal State -------------
  const [singleDelete, setSingleDelete] = useState(false)
  const toggleSingleDelete = () => {
    setSingleDelete((prevState) => !prevState)
  }

  // ------------- Archive Modal State -------------
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const toggleArchiveModalOpen = () => {
    setArchiveModalOpen((prevState) => !prevState)
  }

  // ------------- Archive Modal State -------------
  const [hideShotzModalOpen, setHideShotzModalOpen] = useState(false)
  const toggleHideShotzModalOpen = () => {
    setHideShotzModalOpen((prevState) => !prevState)
  }

  // -------------Single Archive Modal State -------------
  const [singleArchive, setSingleArchive] = useState(false)
  const toggleSingleArchive = () => {
    setSingleArchive((prevState) => !prevState)
  }

  //  ------------- Edit Modal State -------------
  const [editModalOpen, setEditModalOpen] = useState(false)
  const toggleEditModalOpen = () => {
    setEditModalOpen((prevState) => !prevState)
  }

  const [Check, setCheck] = useState(false)
  const [HideShotz, setHideShotz] = useState(false)

  //  ------------- Shotz data -------------
  const [Shotzdata, setShotzdata] = useState([
    {
      Shotzs: 'https://images.unsplash.com/photo-1579176647030-bd86f6fd4e1e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW5zdGFncmFtJTIwcmVlbHN8ZW58MHx8MHx8&w=1000&q=80',
      num: '12000',
      songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
      icon1: <Music size={22} color='#FFFFFF' />,
      num1: '1000',
      num2: '30000',
      num3: '23000',
      id: 1,
      selected: false
    },
    {
      Shotzs: 'https://images.unsplash.com/photo-1531887259712-aa6e090e9289?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW5zdGFncmFtJTIwcmVlbHN8ZW58MHx8MHx8&auto=format&fit=crop&w=2000&q=60',
      num: '14000',
      num1: '20000',
      num2: '33000',
      num3: '28000',
      id: 2,
      selected: false
    },
    {
      Shotzs: 'https://images.unsplash.com/photo-1579176647030-bd86f6fd4e1e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW5zdGFncmFtJTIwcmVlbHN8ZW58MHx8MHx8&w=1000&q=80',
      num: '13000',
      songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
      icon1: <Music size={22} />,
      num1: '20000',
      num2: '34000',
      num3: '22000',
      id: 3,
      selected: false
    },
    {
      Shotzs: 'https://www.imagesplatform.com/mohsin_site/uploads/2021_12_13/handsome-munda-pic-ideas.jpg',
      num: '12000',
      songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
      icon1: <Music size={22} color='#FFFFFF' />,
      num1: '1000',
      num2: '30000',
      num3: '23000',
      id: 4,
      selected: false
    },
    {
      Shotzs: 'https://1fid.com/wp-content/uploads/2022/02/boy-dp-image-77-1024x1002.jpg',
      num: '14000',
      num1: '20000',
      num2: '33000',
      num3: '28000',
      id: 5,
      selected: false
    },
    {
      Shotzs: 'https://i.pinimg.com/564x/46/48/3b/46483b1f0c55ee6ecf9277666ba33378.jpg',
      num: '13000',
      songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
      icon1: <Music size={22} />,
      num1: '20000',
      num2: '34000',
      num3: '22000',
      id: 6,
      selected: false
    },
    {
      Shotzs: 'https://blogger.googleusercontent.com/img/a/AVvXsEhXJ1lfuk2J1uTzEQ0mEsevS-j9NPaoh3MpWS2jwCfAFGtBI2dyq-glTwkWt_nUCPNsrSsg_BaEVWTysFDG4wzINj9ibAIKMPE5aJT8bXV7KrlysimjX0zy4XeQakYap3mx520j1_by85d3wACAUEpj_0gKZbaUB5tOGZCG8sBrB7mVLEqtSN3rnRO5=s16000',
      num: '12000',
      songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
      icon1: <Music size={22} color='#FFFFFF' />,
      num1: '1000',
      num2: '30000',
      num3: '23000',
      id: 7,
      selected: false
    },
    {
      Shotzs: 'https://i.pinimg.com/564x/46/48/3b/46483b1f0c55ee6ecf9277666ba33378.jpg',
      num: '14000',
      num1: '20000',
      num2: '33000',
      num3: '28000',
      id: 8,
      selected: false
    },
    {
      Shotzs: 'https://blogger.googleusercontent.com/img/a/AVvXsEhXJ1lfuk2J1uTzEQ0mEsevS-j9NPaoh3MpWS2jwCfAFGtBI2dyq-glTwkWt_nUCPNsrSsg_BaEVWTysFDG4wzINj9ibAIKMPE5aJT8bXV7KrlysimjX0zy4XeQakYap3mx520j1_by85d3wACAUEpj_0gKZbaUB5tOGZCG8sBrB7mVLEqtSN3rnRO5=s16000',
      num: '14000',
      num1: '20000',
      num2: '33000',
      num3: '28000',
      id: 9,
      selected: false
    },
    {
      Shotzs: 'https://media.istockphoto.com/id/624129686/photo/portrait-boy.jpg?b=1&s=170667a&w=0&k=20&c=yGNLuSKfWaFMi25zOLFzq-TWOEFZwNT1x9XtM5BVIdY=',
      num: '14000',
      num1: '20000',
      num2: '33000',
      num3: '28000',
      id: 10,
      selected: false
    },
    {
      Shotzs: 'https://i.pinimg.com/564x/46/48/3b/46483b1f0c55ee6ecf9277666ba33378.jpg',
      num: '13000',
      songs: 'Panghat - Roohi | Sachin-Jigar | Divya Kumar...',
      icon1: <Music size={22} />,
      num1: '20000',
      num2: '34000',
      num3: '22000',
      id: 11,
      selected: false
    },
    {
      Shotzs: 'https://blogger.googleusercontent.com/img/a/AVvXsEhXJ1lfuk2J1uTzEQ0mEsevS-j9NPaoh3MpWS2jwCfAFGtBI2dyq-glTwkWt_nUCPNsrSsg_BaEVWTysFDG4wzINj9ibAIKMPE5aJT8bXV7KrlysimjX0zy4XeQakYap3mx520j1_by85d3wACAUEpj_0gKZbaUB5tOGZCG8sBrB7mVLEqtSN3rnRO5=s16000',
      num: '12000',
      songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
      icon1: <Music size={22} color='#FFFFFF' />,
      num1: '1000',
      num2: '30000',
      num3: '23000',
      id: 12,
      selected: false

    },
  ]);

  // Single Select and Multipal select start

  const allSelected = Shotzdata.every((data) => data.selected);

  const handleCardClick = (id) => {
    setShotzdata((prevCards) =>
      prevCards.map((data) =>
        data.id === id ? { ...data, selected: !data.selected } : data
      )
    );



    const flst = Shotzdata.filter((element) => element.id === id ? !element.selected : element.selected);
    if (flst.length > 0) {
      setisDisabled(false);
    } else {
      setisDisabled(true);
    }


  };

  const handleSelectAllClick = () => {
    setShotzdata((prevCards) =>
      prevCards.map((data) => ({ ...data, selected: true }))
    );
    setCheck((prevState) => !prevState);
    setisDisabled(false);
  };

  const handleDeselectAllClick = () => {
    setShotzdata((prevCards) =>
      prevCards.map((data) => ({ ...data, selected: false }))
    );
    setCheck((prevState) => !prevState);
    setisDisabled(true);
  };

  // Single Select and Multipal select end

  //  ------------- Num Formatter  -------------

  const numFormatter = (num) => {
    // eslint-disable-next-line prefer-template
    if (num > 999 && num < 1000000) {
      return `${parseInt(num / 1000)} K`
    } else if (num > 999999) {
      return `${parseInt(num / 1000000)} M`
    } else if (num < 900) {
      return `${num}`
    }
  }

  const [isSelectActive, setIsSelectActive] = useState("");
  const [selectedShotz, setSelectedShotz] = useState([]);

  const [isDisabled, setisDisabled] = useState(!isSelectActive);

  const GridViewSection = () => {
    return (
      <div className=' nav-flex-container mx-3 my-3'>
        <div className='d-flex align-items-center' >
          <div className='d-flex justify-content-between align-items-center py-2 mx-2'>
            <NavLink to='/ExploreShotz'>
              {/* <Button className='archive-button' color='primary' outline> */}
              {/* <CornerUpLeft size={17} />&nbsp;&nbsp; Back */}
              {/* <Button className='archive-button' color='primary' outline variant='outline-primary' > */}
              <ChevronLeft size={17} />
              {/* </Button> */}
            </NavLink>
          </div>
          <div >
            {!Check ?
              <h3 className='ml-1' style={{ cursor: 'pointer' }}><strong>My Shotz</strong><span  > {`(${Shotzdata.length})`}</span></h3>
              // style={{ color: '#81c41b' }}
              :
              <h3 className='ml-1'>{`${Shotzdata.length}`} Shotz Selected</h3>
            }
          </div>
        </div>
        <div className='nav-flex-container d-flex align-items-center justify-content-end size-sm-mt-4 mr-1' >
          <div className="profile-search-blk size-sm-mt-4 mr-1 ml-1">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            <input type="text" className="form-control shotz-text" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />

          </div>
          {!isSelectActive ? (
            <div className='size-sm-mt-4' style={{ marginLeft: '6px' }}>
              <Button 
                onClick={() => setIsSelectActive(!isSelectActive)}
                // variant="outline-primary"
                color='primary'
                outline

              >
                Select
              </Button>
            </div>
          ) : (
            <>
              <div className='nav-flex-container size-sm-mt-4 '>
                <div style={{}} className=''>
                  <Button
                    outline
                    className={`btn-icon${isDisabled ? ' disabled' : ''}`}
                    size='sm'
                    id='positionBottomArchive'
                    color={isDisabled ? 'secondary' : 'primary'}
                    onClick={toggleArchiveModalOpen}
                    disabled={isDisabled}
                  >
                    <Archive size={18} />
                  </Button>
                  <UncontrolledTooltip placement='top' target='positionBottomArchive'>
                    <h6>Archive</h6>
                  </UncontrolledTooltip>
                </div>
                <div>
                  <Button outline className='btn-icon mx-1' size='sm' id='positionBottomDelete' color={isDisabled ? 'secondary' : 'primary'} onClick={toggleDeleteModalOpen} disabled={isDisabled} >
                    <Trash2 size={18} />
                  </Button>
                  <UncontrolledTooltip placement='top' target='positionBottomDelete'>
                    <h6>Delete</h6>
                  </UncontrolledTooltip>
                </div>
                <div>
                  <Button outline className='btn-icon ' size='sm' id='positionBottomSend' color={isDisabled ? 'secondary' : 'primary'} onClick={toggleForward} disabled={isDisabled} >
                    <Send size={18} />
                  </Button>
                  <UncontrolledTooltip placement='top' target='positionBottomSend'>
                    <h6>Share to</h6>
                  </UncontrolledTooltip>
                </div>
                <div>
                  <Button outline className='btn-icon ml-1' size='sm' id='positionBottomDownload' onClick={toggleDownloadModalOpen} color={isDisabled ? 'secondary' : 'primary'} disabled={isDisabled}>
                    <Download size={18} />
                  </Button>
                  <UncontrolledTooltip placement='top' target='positionBottomDownload'>
                    <h6>Download</h6>
                  </UncontrolledTooltip>
                </div>
              </div>
              <div className='size-sm-mt-4 select-text-buttons' style={{ marginLeft: '3px' }}>
                <Button
                  outline
                  className='mx-1'
                  color='primary'
                  onClick={() => {
                    if (allSelected === false) {
                      handleSelectAllClick();
                    } else {
                      handleDeselectAllClick();
                    }
                  }}
                >
                  {!allSelected ? 'Select All' : 'Unselect All'}
                </Button>

                {/* <Button
                  // variant="outline-primary"
                  outline
                  color='primary'
                  className='ml-1'
                  onClick={handleDeselectAllClick}
                  disabled={allSelected ? true : false}>
                  Unselect All
                </Button> */}

                <Button
                  outline
                  color='primary'
                  onClick={() => {
                    setIsSelectActive(!isSelectActive);
                    setSelectedShotz([]);

                    // Get all elements with the class .shotz-box-selected
                    const selectedElements = document.querySelectorAll('.shotz-box-selected');

                    // Loop through the selected elements and remove the class
                    selectedElements.forEach(element => {
                      element.classList.remove('shotz-box-selected');
                    });

                    setCheck(true);
                    handleDeselectAllClick();
                  }}
                  className=''
                >
                  Cancel
                </Button>

              </div>
            </>
          )}
          <div className='size-sm-mt-4 Archive-text-btn' style={{ marginRight: '-8px' }} >
            <NavLink to='/ArchivedShotzList' >
              <Button className='mx-2' id='positionBottomArchives' color='primary' outline>
                <Archive size={18} />
              </Button>

              <UncontrolledTooltip placement='top' target='positionBottomArchives'>
                <h6>My Archive</h6>
              </UncontrolledTooltip>
            </NavLink>
          </div>

        </div>
      </div>
    )
  }
  const [cardSelect, setcradSelect] = useState(false);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleCheckboxChange = () => {

  };
  return (
    <>
      <ShareModal
        isOpen={forwardOpen}
        shareModalToOpen={toggleForward}
      />

      <Card className='border-0'>
        <GridViewSection />

        {
          Gridview ?
            <ShotzListView Check={Check} />
            :
            <Row className='AllTagsThis'>
              {
                Shotzdata.map((data, onSelect) => {
                  return (
                    <Col key={data.id} xl='3' lg='4' md='6' sm='12' xs='12' className='p-1 mt-1'>
                      <div onChange={() => handleCardClick(data.id)} className={data.selected ? "shotz-box-selected media-images-blk shotz-album-block align-items-center" : "shotz-box media-images-blk shotz-album-block align-items-center"}>
                        <div className="main-shotz-blk-list" >
                          <figure className="shotz-card-hover m-0">
                            <div className='gradient-overlay'></div>
                            <img className="gallery-img img-gradient rounded img-fluid " onClick={toggleShotzCarouselModal} src={data.Shotzs} alt="" />
                            <div className="text-gallery d-flex justify-content-center">
                              <div className="top-gallery-cont ">
                                <p className='shotz-text'>{HideShotz ? <EyeOff size={20} /> : <Eye size={20} />} {numFormatter(data.num)}</p>
                                {/* ====================comment code ================ */}
                                <div>
                                  {isSelectActive ? (
                                    <input type="checkbox" checked={data.selected} onChange={handleCheckboxChange} />
                                  ) : (
                                    <UncontrolledButtonDropdown direction='left' className='m-0 p-0'>
                                      <DropdownToggle color='flat' className='m-0 p-0'>
                                        <MoreVertical role="button" color='white' size={20} />
                                      </DropdownToggle>
                                      <DropdownMenu className='obj-container'>
                                        <DropdownItem onClick={toggleForward} className='border-bottom py-2 shotz-menu-hover-style'>
                                          <h5><Share size={15} className='mr-2 icon-color-style' />Share</h5>
                                        </DropdownItem>
                                        <DropdownItem onClick={toggleEditModalOpen} className='border-bottom py-2 shotz-menu-hover-style'>
                                          <h5><Edit size={15} className='mr-2 icon-color-style' />Edit</h5>
                                        </DropdownItem>
                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style'>
                                          <h5><Copy size={15} className='mr-2 icon-color-style' />Copy Link</h5>
                                        </DropdownItem>
                                        <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleSingleDownload}>
                                          <h5><Download size={15} className='mr-2 icon-color-style' />Download</h5>
                                        </DropdownItem>
                                        <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleSingleArchive}>
                                          <h5><Archive size={15} className='mr-2 icon-color-style' />Archive</h5>
                                        </DropdownItem>
                                        <DropdownItem className='py-2 menu-hover-style' onClick={toggleSingleDelete}>
                                          <h5><Trash2 size={15} className='mr-2 icon-color-style' />Delete</h5>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                  )}
                                </div>
                                {/* ====================comment code ================ */}
                                {/* <input type="checkbox" checked={data.selected} onChange={handleCheckboxChange} /> */}
                                {/* <Checkbox {...label}/> */}
                              </div>
                              <div className="center-gallery-cont">
                                <div onClick={toggleShotzCarouselModal}>
                                  <img src="assets/images/shotz-icon.png" className='shotzModel' alt='' />
                                </div>
                                {/* <a data-bs-toggle="modal" data-bs-target="#imageModel">
                                  <img src="assets/images/shotz-icon.png" className='shotzModel' />
                                </a> */}
                              </div>
                              <div className="Music-gallery-cont mx-2 py-3 ">
                                <p className='shotz-text'>{data.icon1}</p>&nbsp;&nbsp;
                                <NavLink to='/UsedAudioShotz'>
                                  <h5 className='text-white mr-2'>{data.songs}</h5>
                                </NavLink >
                              </div>
                              <div className="bottom-gallery-cont ">
                                <p className='shotz-text'><img src={like} alt='' />{numFormatter(data.num1)}</p>
                                <p className='shotz-text'><MessageCircle size={20} />{numFormatter(data.num2)}</p>
                                <p className='shotz-text'><Send size={20} />{numFormatter(data.num3)}</p>
                              </div>
                            </div>
                          </figure>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
        }
        {/* ------------- Start Pagination ------------- */}
        <Row>
          <Col xl='12' className='d-flex justify-content-center py-4'>
            <Stack spacing={2}>
              <Pagination
                count={5}
                color='primary'
                size="large" />
            </Stack>
          </Col>
        </Row>
        {/* ------------- End Pagination ------------- */}
      </Card>
      {/* ------------- Download Modal ------------- */}
      <DownloadModal
        isOpen={downloadModalOpen} downloadModalOpen={toggleDownloadModalOpen}
      />
      {/*  ------------- Archive Modal ------------- */}

      <ArchiveModal
        heading="Archived Shotz"
        discription="Do you really want to add shotz to the archieve"
        isOpen={archiveModalOpen}
        archiveModal={toggleArchiveModalOpen}
      />
      {/*  ------------- Delete Modal ------------- */}
      <DeletedModal
        isOpen={deleteModalOpen}
        deleteModalOpens={toggleDeleteModalOpen}
      />
      {/*  ------------- Start Hide Shotz Modal ------------- */}

      <HideModal
        isOpen={hideShotzModalOpen}
        hideModalOpen={toggleHideShotzModalOpen}
      />
      {/*  ------------- End Hide Shotz Modal ------------- */}
      {/* ------------- Edit Shotz Modal ------------- */}

      <EditModal
        isOpen={editModalOpen}
        updateOpen={toggleEditModalOpen}
      />
      {/* ------------- End Edit Shotz Modal ------------- */}
      {/* ------------- Start Shotz Carousel Modal ------------- */}
      <ShotzCarouselComponents
        isOpen={shotzCarouselModal}
        ShotzModalCarousel={toggleShotzCarouselModal}
      />
      {/* <ShotzMediaModal /> */}
      {/* ------------- End Shotz Carousel Modal ------------- */}

      {/* -------------Start, Single Delete Modal ------------- */}
      <SingleDeleteModal
        isOpen={singleDelete}
        singleDeleteModal={toggleSingleDelete}
        // icon={<Trash2 size={15} className='mr-2 icon-color-style' />}
        // optioneName='Delete'
        heading="Delete Shotz"
        discription="Do you really want to delete the Shotz?"
      />
      {/* -------------End, Single Delete Modal ------------- */}

      {/* -------------Start, Single Archive Modal ------------- */}
      <ArchivedModal
        isOpen={singleArchive}
        singleArchiveModal={toggleSingleArchive}
        // icon={<Archive size={15} className='mr-2 icon-color-style' />}
        // optioneName="Add to Archive "
        heading="Archived Shotz"
        discription="Do you really want add to archive Shotz?"
      />
      {/* -------------End, Single Archive Modal ------------- */}

      {/* -------------Start, Single Download Modal ------------- */}
      <ShotzPopup
        isOpen={singleDownload}
        singleDownloadModal={toggleSingleDownload}
        // icon={<Download size={15} className='mr-2 icon-color-style' />}
        // optioneName='Download'
        heading="Download Shotz"
        discription="Do you really want to download Shotz?"
      />
      {/* -------------End, Single Download Modal ------------- */}



    </>
  )
}
