import * as types from "../../Constants/SocialFeed/index.js";

const initialState = { 
    allConnections: [],
    loading: true,
};

 const AllConnectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_CONNECTIONS:
            return {
                ...state,
                allConnections: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default AllConnectionsReducer;