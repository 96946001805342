import React from 'react'
import AllPostCreationNav from '../AllPostCreationNav'

const PostCreation = () => {
  return (
    <div>
      <AllPostCreationNav />
    </div>
  )
}

export default PostCreation