import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { Button } from 'reactstrap'
import { Checkbox, } from '@mui/material';
import { NotificationOption, NotificationValidationOption } from './SettingArray'
import { getBizPageDetailsByIdAction, postNotificationSettingsAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'



const NotificationSetting = (props) => {

    let dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getBizPageDetailsByIdAction(id))
    }, []);


    const getpageDetailsById = useSelector((state) => { return state.getBizPageDetailsByIdData });
    const { loading: loadingGetDetails, error: errorGetDetails, result: resultGetDetails } = getpageDetailsById

    const responseOfnoticationSettingsData = useSelector((state) => { return state.postNotificationSettingsData });
    const { loading: loadingNotificationSettings, error: errorNotificationSettings, result: resultNotificationSettings } = responseOfnoticationSettingsData

    const bpNotificationSettings = resultGetDetails?.bpResponse?.bpNotificationSettings?.[0];
    const notificationType = bpNotificationSettings?.notificationType;

    const notificationOnEmail = resultGetDetails?.bpResponse?.allowNotificationOnEmail
    const notificationOnSms = resultGetDetails?.bpResponse?.allowNotificationOnSms




    const [editPosts, setEditPosts] = useState(0);
    const [mentions, setMentions] = useState(0);
    const [messages, setMessages] = useState(0);
    const [likePosts, setLikePosts] = useState(0);
    const [newReview, setNewReview] = useState(0);
    const [commentPosts, setCommentPosts] = useState(0);
    const [newFollower, setNewFollower] = useState(0);
    const [groupPostShare, setGroupPostShare] = useState(0);
    const [newEditSuggestion, setNewEditSuggestion] = useState(0);
    const [editCommentWritten, setEditCommentWritten] = useState(0);

    const [allowNotificationsOnEmail, setAllowNotificationsOnEmail] = useState(notificationOnEmail === '1' || notificationOnEmail === 1);
    const [allowNotificationOnSms, setAllowNotificationOnSms] = useState(notificationOnSms === '1' || notificationOnSms === 1);

    const saveAllNotificatioSettings = () => {
        dispatch(postNotificationSettingsAction(
            id,
            allowNotificationOnSms,
            allowNotificationsOnEmail,
            mentions,
            messages,
            newReview,
            commentPosts,
            editCommentWritten,
            newFollower,
            likePosts,
            editPosts,
            newEditSuggestion,
            groupPostShare
        ))
    }


    useEffect(() => {
        setAllowNotificationsOnEmail(notificationOnEmail === '1' || notificationOnEmail === 1);
    }, [notificationOnEmail]);

    useEffect(() => {
        setAllowNotificationOnSms(notificationOnSms === '1' || notificationOnSms === 1);
    }, [notificationOnSms]);

    const handleNotificationOnEmail = (event) => {
        setAllowNotificationsOnEmail(event.target.checked);
    }
    const handleNotificationOnSms = (event) => {
        setAllowNotificationOnSms(event.target.checked);
    }
    const [isCheckedAll, setIsCheckedAll] = useState();

    const handleCheckboxChange = (event) => {
        let isChecked = event.target.checked
        setIsCheckedAll(isChecked);
        setEditPosts(isChecked);
        setMentions(isChecked);
        setMessages(isChecked);
        setLikePosts(isChecked);
        setNewReview(isChecked);
        setCommentPosts(isChecked);
        setNewFollower(isChecked);
        setGroupPostShare(isChecked);
        setNewEditSuggestion(isChecked);
        setEditCommentWritten(isChecked);
    }

    const updateCheckedAll = () => {
        const allStates = [editPosts, mentions, messages, likePosts, newReview, commentPosts, newFollower, groupPostShare, newEditSuggestion, editCommentWritten];
        const allTrue = allStates.every((state) => state === true);
        setIsCheckedAll(allTrue);
    };
    useEffect(() => {
        updateCheckedAll();
    }, [editPosts, mentions, messages, likePosts, newReview, commentPosts, newFollower, groupPostShare, newEditSuggestion, editCommentWritten]);



    useEffect(() => {
        setEditPosts(notificationType?.edit_post === '1' || notificationType?.edit_post === 1);
        setMentions(notificationType?.mentions === '1' || notificationType?.mentions === 1);
        setMessages(notificationType?.messages === '1' || notificationType?.messages === 1);
        setLikePosts(notificationType?.like_post === '1' || notificationType?.like_post === 1);
        setNewReview(notificationType?.new_review === '1' || notificationType?.new_review === 1);
        setCommentPosts(notificationType?.comment_post === '1' || notificationType?.comment_post === 1);
        setNewFollower(notificationType?.new_follower === '1' || notificationType?.new_follower === 1);
        setGroupPostShare(notificationType?.group_post_share === '1' || notificationType?.group_post_share === 1);
        setNewEditSuggestion(notificationType?.new_edit_suggestion === '1' || notificationType?.new_edit_suggestion === 1);
        setEditCommentWritten(notificationType?.edit_comment_written === '1' || notificationType?.edit_comment_written === 1);
    }, [notificationType]);

    // useEffect(() => {
    //     if (resultNotificationSettings?.businessPageId === id) {
    //         (sweetAlertConfirmation('Notification settings changed successfully'))
    //         dispatch(getBizPageDetailsByIdAction(id))
    //     }
    // }, [resultNotificationSettings]);


    console.log('successNoti', resultNotificationSettings)



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <Fragment>
            <div className="custom-card-block">
                <div className="customAbout-card-head" onClick={() => { props.setsetsideclassoption(false); }} style={{cursor:"pointer"}} >
                    <h3> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='hide-setting-options12'>
                        <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                    </svg>Notification </h3>
                </div>
                <div className="custom-card-body">
                    <div className="biz-main-SettingTab-blk  ">
                        <div className=''>
                            <div className='mr-auto d-flex justify-content-between'>
                                <h4>Notification on SocioMee</h4>
                                <div className='d-flex'>
                                <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                <label className="switch">
                                    <input type="checkbox" checked={isCheckedAll} onChange={handleCheckboxChange} />
                                    <span className="slider round"></span>
                                </label>
                                <div className='ml-2 BizSetting_msg_toggle py-1'>On</div>
                            </div>
                               
                            </div>
                            <p className='mt-2'> This will enable notifications for activities on your Business Page</p>
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>When someone mentions your page </h5>
                            <Checkbox
                                checked={mentions}
                                defaultChecked={mentions}
                                onChange={() => setMentions(!mentions)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>Messages received on Page </h5>
                            <Checkbox
                                checked={messages}
                                defaultChecked={messages}
                                onChange={() => setMessages(!messages)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>New Review on Page </h5>
                            <Checkbox
                                checked={editPosts}
                                defaultChecked={editPosts}
                                onChange={() => setEditPosts(!editPosts)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>Comments on page posts </h5>
                            <Checkbox
                                checked={likePosts}
                                defaultChecked={likePosts}
                                onChange={() => setLikePosts(!likePosts)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>Edits to comments you have written </h5>
                            <Checkbox
                                checked={newReview}
                                defaultChecked={newReview}
                                onChange={() => setNewReview(!newReview)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>Likes on Page posts  </h5>
                            <Checkbox
                                checked={commentPosts}
                                defaultChecked={commentPosts}
                                onChange={() => setCommentPosts(!commentPosts)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>New Followers on Page </h5>
                            <Checkbox
                                checked={newFollower}
                                defaultChecked={newFollower}
                                onChange={() => setNewFollower(!newFollower)}
                                className='Check_Input_section'
                            />
                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>Group Post shares </h5>
                            <Checkbox
                                checked={groupPostShare}
                                defaultChecked={groupPostShare}
                                onChange={() => setGroupPostShare(!groupPostShare)}
                                className='Check_Input_section'
                            />

                        </div>

                        <div className='Biz_Notification_section py-2'>
                            <h5>New Edit suggestion </h5>
                            <Checkbox
                                checked={newEditSuggestion}
                                defaultChecked={newEditSuggestion}
                                onChange={() => setNewEditSuggestion(!newEditSuggestion)}
                                className='Check_Input_section'
                            />

                        </div>

                        {/* <div className='Biz_Notification_section py-2'>
                            <h5>Edit comment  </h5>
                            <Checkbox
                                checked={editCommentWritten}
                                defaultChecked={editCommentWritten}
                                onChange={() => setEditCommentWritten(!editCommentWritten)}
                                className='Check_Input_section'
                            />
                        </div> */}

                    </div>
                    <div className="biz-main-Setting-blk ">

                        <div className="biz-main-SettingTab-blk ">
                            <div className=''>
                                <div className='mr-auto d-flex justify-content-between'>
                                    <h4>Notification on Email</h4>
                                    <div className=' d-flex'>
                                    <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                    <label className={`switch ${allowNotificationsOnEmail ? 'on' : 'off'}`}>
                                        <input
                                            type="checkbox"
                                            defaultChecked={allowNotificationsOnEmail}
                                            onChange={handleNotificationOnEmail}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                </div>
                                 
                                </div>
                                <p >Turn off to stop receiving notifications on your Email ID</p>
                            </div>
                        </div>
                        <div className="biz-main-SettingTab-blk ">
                            <div className=''>
                                <div className='mr-auto d-flex justify-content-between'>
                                    <h4>Notification on SMS</h4>
                                    <div className=' d-flex'>
                                    <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={allowNotificationOnSms}
                                            onChange={handleNotificationOnSms}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                </div>
                                 
                                </div>
                                <p >Turn off to stop receiving notification on SMS</p>
                            </div>
                        </div>

                    </div>
                    <div className="biz-main-Setting-blk text-end ">
                        {/* <Button outline className="w-25" >Cancel </Button> */}
                        <Button color="primary" className="ml-2 w-25" onClick={saveAllNotificatioSettings}>Save </Button>

                    </div>

                </div>
            </div>

        </Fragment>
    )
}

export default NotificationSetting