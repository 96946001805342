import React from 'react'
import { Fragment } from 'react'
import { ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import '../style.css'
import GalleryLeftSideData from "./GalleryLeftSideData"
import { Edit2, Globe, Users, Mail, Phone, RotateCcw, Lock } from 'react-feather'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {detailsAllAboutGroup} from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

const MyAbout = () => {

    const params = useParams()
    let dispatch = useDispatch();

    const { getAllAboutGroup } = useSelector((state) => { return state.getAllAboutGroupData});

    useEffect(() => {
        dispatch(detailsAllAboutGroup(params.id))
    }, [])

    const [privacydropdownOpen, setPrivacyDropdownOpen] = useState(false)
    const togglePrivacy = () => {
        setPrivacyDropdownOpen(!privacydropdownOpen)
    }
    const [categorydropdownOpen, setCategoryDropdownOpen] = useState(false)
    const toggleCategory = () => {
        setCategoryDropdownOpen(!categorydropdownOpen)
    }

    const GroupAboutData = [
        {
            img: <Globe size={25} color="#808080" />,
            name: "Group privacy",
            subName: "Public",
            editTuype: 'dropDown',
            dropItem1: "Public",
            dropItem2: "Private",
            img1: <Lock size={25} color="#808080" />
        },
        {
            img: <Users size={25} color="#808080" />,
            name: "Group Category",
            subName: "Category Name",
            editTuype: 'CategoryDropDown',

        },
        {
            img: <Mail size={25} color="#808080" />,
            name: "Email",
            subName: "buzzfeed@gmail.com",
            editTuype: 'Modal'
        },
        {
            img: <Phone size={25} color="#808080" />,
            name: "Contact",
            subName: "+91 8769531246",
            editTuype: 'Modal'
        },
        {
            img: <RotateCcw size={25} color="#808080" />,
            name: "History"

        }
    ]
     // ================== main_return_function==================//
    return (
        <Fragment>
            <Row>
                <Col md="4">
                    <GalleryLeftSideData />
                </Col>
                <Col md="8">
                    <div className="About-card-block">
                        <div className="custom-card-head text-wrap">
                            <h4 className='text-capitalize'>{getAllAboutGroup && getAllAboutGroup.data.successResult.name}</h4>
                            <p className='py-2' style={{textTransform: 'capitalize'}}>{getAllAboutGroup && getAllAboutGroup.data.successResult.description}</p>

                        </div>
                        <div className="biz-about-cont">
                            <ul>

                            <li>
                                    {

                                            <Fragment>
                                                {/* <div className='d-flex justify-content-between align-items-center'> */}
                                                <div className='d-flex align-items-center   About_Main_section'>
                                                    <div className='About_Img-Section'>
                                                    <Globe size={25} color="#808080" />
                                                    </div>
                                                    <div className='about_sub_section'>
                                                    <h5 className='text-capitalize'>Group privacy</h5>
                                                        <p className='text-capitalize'>{getAllAboutGroup && getAllAboutGroup.data.successResult.privacy}</p>
                                                    </div>
                                                </div>

                                                <div className='d-flex align-items-center   About_Main_section'>
                                                    <div className='About_Img-Section'>
                                                    <Users size={25} color="#808080" />
                                                    </div>
                                                    <div className='about_sub_section'>
                                                        <h5 className='text-capitalize'>Group Category</h5>
                                                        <p className='text-capitalize'>{getAllAboutGroup && getAllAboutGroup.data.successResult.grpCategory}</p>
                                                    </div>
                                                </div>

                                                <div className='d-flex align-items-center   About_Main_section'>
                                                    <div className='About_Img-Section'>
                                                    <Mail size={25} color="#808080" />
                                                    </div>
                                                    <div className='about_sub_section'>
                                                        <h5 className='text-capitalize'>Email</h5>
                                                        <p className='text-capitalize'>Not Available</p>
                                                    </div>
                                                </div>

                                                
                                                <div className='d-flex align-items-center   About_Main_section'>
                                                    <div className='About_Img-Section'>
                                                    <Phone size={25} color="#808080" />
                                                    </div>
                                                    <div className='about_sub_section'>
                                                        <h5 className='text-capitalize'>Contact</h5>
                                                        <p className='text-capitalize'>Not Available</p>
                                                    </div>
                                                </div>

                                                <div className='d-flex align-items-center   About_Main_section'>
                                                    <div className='About_Img-Section'>
                                                    <RotateCcw size={25} color="#808080" />
                                                    </div>
                                                    <div className='about_sub_section'>
                                                        <h5 className='text-capitalize'>History</h5>
                                                        <p className='text-capitalize'>Not Available</p>
                                                    </div>
                                                </div>
       
                                            </Fragment>

                                    }
                                </li>

                                </ul>
                        </div>
                    </div>
                </Col>



            </Row>


        </Fragment>
    )
}

export default MyAbout