import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { Fragment, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row }
    from 'reactstrap'
import Pen1 from '../../profile-components/Img/Pen1.png'
import Track from '../../profile-components/Img/Track.png'
import { Clock, Globe, Mail, MapPin, Phone, XCircle } from 'react-feather'
import WhatWeDoModal from '../AllTypesModal/WhatWeDoModal'
import AdditionalModal from '../AllTypesModal/AdditionalModal'
import HeadingPointModal from '../AllTypesModal/HeadingPointModal'
import HeadingDespModal from '../AllTypesModal/HeadingDespModal'
import Timemodal from '../AllTypesModal/Timemodal'
import Timemodal2 from '../AllTypesModal/timemodal2'




const AboutBiz = () => {


    const [additionalHoursVisible, setAdditionalHoursVisible] = useState(false);
    const [additionalHours, setAdditionalHours] = useState([]);
    const [startingHours, setStartingHour] = useState('');
    const [endingHours, setEndingHour] = useState('');

    const addAdditionalHours = () => {
        setAdditionalHours([...additionalHours, { startingHours, endingHours, hour: '', minute: '', selectedValue: 'AM', selectedValue1: 'AM' }]);
        setStartingHour(''); // Reset input fields
        setEndingHour('');
    };
    const removeAdditionalHours = (index) => {
        const updatedHours = [...additionalHours];
        updatedHours.splice(index, 1);
        setAdditionalHours(updatedHours);
    };
    const [isOpen, seIsOpen] = useState(false)
    const [isOpen1, seIsOpen1] = useState(false)

    const [additionalInfo, setAdditionalInfo] = useState(false)
    const toggleInfo = () => setAdditionalInfo(!additionalInfo)
    // ----------------start_left_seide_useSate------------------------//
    const [websiteOpen, setWebsiteOpen] = useState(false)
    const toggleWebsite = () => {
        setWebsiteOpen((preState) => !preState)
        setWebsiteOpen(!websiteOpen)

    }
    const [showResults, setShowResults] = useState(false)
    const toggle = () => {
        setShowResults(!showResults)
    }
    const [showResults1, setShowResults1] = useState(false)
    const toggle1 = () => {
        setShowResults1(!showResults1)
    }
    const [tymOpen, setTymOpen] = useState(false)
    const toggleTym = () => {
        setTymOpen((preState) => !preState)
        setTymOpen(!tymOpen)

    }

    const toggleOpen = () => {
        seIsOpen((preState) => !preState)
    }
    // const toggleOpen2 = () => {
    //     seIsOpen2((preState) => !preState)
    // }
    const toggleOpen1 = () => {
        seIsOpen1((preState) => !preState)
    }
    const handleCloseModal1 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleTym();

    };
    const handleCloseModal2 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleMap();

    };
    const handleCloseModal3 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleEmail();

    };
    // const [selectedValue, setSelectedValue] = useState('a');
    const handleCloseModal4 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleContact();

    };
    const handleCloseModal5 = () => {
        // Set the senddropdownOpen state to false to close the modal
        toggleWebsite();

    };


    const [isOpen2, setIsOpen2] = useState(false);

    const toggleOpenPoint = () => {
        setIsOpen2(!isOpen2);
    };
    const [isOpen3, setIsOpen3] = useState(false);

    const toggleOpenPoint1 = () => {
        setIsOpen3(!isOpen3);
    };
    const [isOpen4, setIsOpen4] = useState(false);

    const toggleOpenPoint2 = () => {
        setIsOpen4(!isOpen4);
    };
    // const [selectedValue, setSelectedValue] = useState('a');

    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };
    const [mapOpen, setMapOpen] = useState(false)
    const toggleMap = () => {
        setMapOpen((preState) => !preState)
        setMapOpen(!mapOpen)
    }

    const [emailAddresses, setEmailAddresses] = useState(['sociomee@gmail.com', 'sociomee@gmail.com']);
    const [emailVerified, setEmailVerified] = useState(false);
    const [verifyDivOpen, setVerifyDivOpen] = useState(false);
    const [emailOpen, setEmailOpen] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [addedEmail, setAddedEmail] = useState(null);
    const toggleEmail = () => {
        setEmailOpen((preState) => !preState)
        setEmailOpen(!emailOpen)
    }
    const [removeOpen, setRemoveOpen] = useState(false)
    const toggleRemove = () => {
        toggleEmail(false)
        setRemoveOpen((preState) => !preState)
    }
    const handleInputChange1 = (event) => {
        setInputValue(event.target.value);
        setAddedEmail(null);
    };
    const handleAddEmail = () => {
        if (inputValue.trim() !== '') {
            setEmailAddresses((prevEmails) => [...prevEmails, inputValue.trim()]);
            setInputValue('');
            setAddedEmail(inputValue.trim());
        }
    };

    const handleVerifyClick1 = () => {
        setVerifyDivOpen(true);
    };

    const handleCloseVerifyDiv = () => {
        setVerifyDivOpen(false);

    };


    const handleConfirmButtonClick = () => {
        setVerifyDivOpen(false);

        // If email is verified, set the state variable accordingly
        if (addedEmail) {
            setEmailVerified(true);
        }
    };


    // number start here


    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        const limitedValue = inputValue.slice(0, 15); // Limit to 10 digits
        setNewEmail(limitedValue);


    };

    const [emailList, setEmailList] = useState(['9876543210', '9875215985']);
    const [newEmail, setNewEmail] = useState('');
    const [contactOpen, setContactOpen] = useState(false)
    const toggleContact = () => {
        setContactOpen((preState) => !preState)
        setContactOpen(!contactOpen)
    }

    const toggleNumRemove1 = (index) => {
        // Implement your logic for removing a number at the specified index
        const updatedList = [...emailList];
        updatedList.splice(index, 1);
        setEmailList(updatedList);
    };

    const handleAddButtonClick = () => {
        if (newEmail.trim()) {
            // Add the new email to the list
            setEmailList([...emailList, newEmail]);

            // Clear the input field after adding the email
            setNewEmail('');
            setShowOTPSection(false);
        }
    };
    const [showOTPSection, setShowOTPSection] = useState(false);

    const handleVerifyClick = () => {
        // Your verification logic can go here

        // If verification is successful, show the OTP section
        setShowOTPSection(true);
    };
    const handleCancelClick = () => {
        // Your cancel logic can go here
        setShowOTPSection(false); // Hide the OTP section when canceling
    };



    const [numRemoveOpen, setNumRemoveOpen] = useState(false)
    const toggleNumRemove = () => {
        toggleContact(false)
        setNumRemoveOpen((preState) => !preState)
    }

    // number end here 

    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');

    const [hour1, setHour1] = useState('');
    const [minute1, setMinute1] = useState('');
    const [selectedValue, setSelectedValue] = useState('AM');
    const [selectedValue1, setSelectedValue1] = useState('AM');
    const handleChangeStartingTime = (event) => {
        // setSelectedStartingTimeValue(event.target.value);
        setSelectedValue1(event.target.value);
    }

    const handleChange = (event) => {
        // setSelectedValueClosing(event.target.value);
        setSelectedValue(event.target.value);

    }

    const startingMinute = (event) => {
        // setStartingMinute(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Ensure the minute value is between 0 and 59
        if (numericValue < 0) {
            setMinute('0');
        } else if (numericValue > 59) {
            setMinute('59');
        } else {
            setMinute(numericValue);
        }

    }


    const startingHour = (event) => {
        // setStartingTimeShift1(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Update the state with the numeric value
        if (numericValue < 0) {
            setHour('0');
        } else if (numericValue > 12) {
            setHour('12');
        } else {
            setHour(numericValue);
        }

    }


    const endingHour = (event) => {
        // setEndingTimeShift1(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Update the state with the numeric value
        if (numericValue < 0) {
            setHour1('0');
        } else if (numericValue > 12) {
            setHour1('12');
        } else {
            setHour1(numericValue);
        }

    }
    const endtingMinute = (event) => {
        // setEndingMinute(event.target.value);
        const numericValue = event.target.value.replace(/\D/g, '');

        // Ensure the minute value is between 0 and 59
        if (numericValue < 0) {
            setMinute1('0');
        } else if (numericValue > 59) {
            setMinute1('59');
        } else {
            setMinute1(numericValue);
        }
    }




    // Function to add additional hours


    // Function to remove additional hours by index


    // Function to handle change in starting hour for additional hours
    const handleStartingHourAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].hour = numericValue < 0 ? '0' : numericValue > 12 ? '12' : numericValue;
            return updatedHours;
        });
    };

    // Function to handle change in starting minute for additional hours
    const handleStartingMinuteAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].minute = numericValue < 0 ? '0' : numericValue > 59 ? '59' : numericValue;
            return updatedHours;
        });
    };

    // Function to handle change in selected value for additional hours
    const handleChangeStartingTimeAdditional = (event, index) => {
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].selectedValue = event.target.value;
            return updatedHours;
        });
    };

    // Function to handle change in ending hour for additional hours
    const handleEndingHourAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].hour1 = numericValue < 0 ? '0' : numericValue > 12 ? '12' : numericValue;
            return updatedHours;
        });
    };

    // Function to handle change in ending minute for additional hours
    const handleEndingMinuteAdditional = (event, index) => {
        const numericValue = event.target.value.replace(/\D/g, '');
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].minute1 = numericValue < 0 ? '0' : numericValue > 59 ? '59' : numericValue;
            return updatedHours;
        });
    };

    // Function to handle change in selected value for additional hours
    const handleChangeEndingTimeAdditional = (event, index) => {
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].selectedValue1 = event.target.value;
            return updatedHours;
        });
    };


    const handleRadioStartingTimeAdditional = (value, index) => {
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].selectedValue1 = value;
            return updatedHours;
        });
    };

    const handleRadioEndingTimeAdditional = (value, index) => {
        setAdditionalHours((prevHours) => {
            const updatedHours = [...prevHours];
            updatedHours[index].selectedValue = value;
            return updatedHours;
        });
    };


    // ----------------end_left_seide_useSate------------------------//

    const LeftAllDataArray = [
        {
            typeof: "Address",
            link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
            location: "Dybdevej 10, 5200 Odense, Denmark",
        },
        {
            typeof: "Address",
            link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
            location: "Dybdevej 10, 5200 Odense, New York",
        },
        {
            typeof: "Timing"
        },
        {
            typeof: "Website",
            title: "Website",
            icon: <Globe color='#81C14B' size={20} />,
            link1: "www.behance.net",
            link2: "www.gihub.net",
            click: toggleWebsite


        },
        {
            typeof: "Website",
            title: "Email",
            icon: <Mail color='#81C14B' size={20} />,
            link1: "email@gmail.com",
            link2: "info@sociomee.com",
            click: toggleEmail
        },
        {
            typeof: "Contact",
            title: "Contact",
            number: "+91 987 654 3210"
        }
    ]
    const data = ["Ad managment", "Branding", "App deveploment", "Ad campaign", "Digital solutions", "Branding"]
    const Offerings = ["Facebook Ads", "Print media", "Web design", "E-commerce", "iOS deveploment", "Android deveploment"]

    const RowFirstSideData = () => (
        <div className="custom-card-block">
            <div className="custom-card-head biz-carddetail-blk">
                <h4>Address</h4>
            </div>
            <div className="bizAbout_cont_section">
                <ul>
                    {
                        LeftAllDataArray.map((data, index) => (
                            <>
                                {data.typeof === "Address" ?
                                    <>
                                        <li>
                                            <div className="bizabout-location" key={index} >
                                                {data.link}
                                                <div className='Biz_Location_btn' onClick={toggleMap}>
                                                    <img src={Pen1} width={12} className="img-fluid" />
                                                </div>
                                                <div className='BizAbout_bottom_section'>
                                                    <div className='Biz_Icon'>
                                                        <MapPin color='#81C14B' size={20} />
                                                    </div>
                                                    <div className='ml-3 BizAbout_Detail'>
                                                        <h5>{data.location}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </> : (data.typeof === "Timing") ?
                                        <>
                                            <li>
                                                <div className="BizAbout_blk_section" >
                                                    <div className="BizAbout_Icon">
                                                        <Clock color='#81C14B' size={20} />
                                                    </div>
                                                    <div className="BizDetails">
                                                        <div className=''>
                                                            <div className='me-auto d-flex justify-content-between '>
                                                                <h5>Timing</h5>
                                                                <div className="BizAbout_edit_Icon " onClick={toggleOpenPoint2}>
                                                                    <img src={Pen1} width={12} className="img-fluid" />
                                                                </div>

                                                            </div>
                                                            <h6><span className="green">Open now</span>&nbsp;&nbsp; 9:00AM - 6:00PM</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </> : (data.typeof === "Website") ?
                                            <>
                                                <li>
                                                    <div className="BizAbout_blk_section" >
                                                        <div className="BizAbout_Icon">
                                                            {data.icon}
                                                        </div>
                                                        <div className="BizDetails">
                                                            <div className=''>
                                                                <div className='me-auto d-flex justify-content-between'>
                                                                    <h5>{data.title}</h5>
                                                                    <div className="BizAbout_edit_Icon " onClick={data.click}>
                                                                        <img src={Pen1} width={12} className="img-fluid" />
                                                                    </div>

                                                                </div>
                                                                <h6 >
                                                                    <Link className='BizLink' to="#">{data.link1}</Link><br />
                                                                    <Link className='BizLink' to="#">{data.link2}</Link></h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </> : (data.typeof === "Contact") ?
                                                <>
                                                    <li>
                                                        <div className="BizAbout_blk_section" >
                                                            <div className="BizAbout_Icon">
                                                                <Phone color='#81C14B' size={20} />
                                                            </div>
                                                            <div className="BizDetails">
                                                                <div className=''>
                                                                    <div className='me-auto d-flex justify-content-between '>
                                                                        <h5>{data.title}</h5>
                                                                        <div className="BizAbout_edit_Icon " onClick={toggleContact}>
                                                                            <img src={Pen1} width={12} className="img-fluid" />
                                                                        </div>

                                                                    </div>
                                                                    <h6 >{data.number}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </> : null
                                }
                            </>
                        ))
                    }
                </ul>
            </div>
        </div>
    )

    const RowSecondSideData = () => (
        <Fragment>
            <div className="custom-card-block">
                <div className="custom-card-head abouthead d-flex">
                    <div className='me-auto'>
                        <h4 style={{ fontSize: "20px" }} >About</h4>
                    </div>
                    {/* <div className="BizAbout_edit_Icon " onClick={toggleOpen}>
                        <img src={Pen1} width={12} className="img-fluid" />
                    </div> */}
                </div>
                <div className="custom-card-body">
                    <div className="biz-main-about-blk">
                        <div className=''>
                            <div className='me-auto  d-flex justify-content-between  '>
                                <h4>Summary</h4>
                                <div className="BizAbout_edit_Icon " onClick={toggleOpen}>
                                    <img src={Pen1} width={12} className="img-fluid" />
                                </div>
                            </div>

                        </div>
                        <p>The #1 Platform for Virtual Sales Teams. Get visibility into your conversations and deals with Revenue Intelligence.</p>
                    </div>
                    <div className="biz-main-about-blk ">
                        <div className=''>
                            <div className='me-auto d-flex justify-content-between '>
                                <h4>What we do</h4>
                                <div className="BizAbout_edit_Icon " onClick={toggleOpenPoint1}>
                                    <img src={Pen1} width={12} className="img-fluid" />
                                </div>
                            </div>

                        </div>
                        <p>Gong.io enables revenue teams to realize their fullest potential by unveiling customer reality.</p>
                        <p>The patented Gong Revenue Intelligence Platform captures and understands every customer interaction then delivers insights at scale, empowering revenue teams to make decisions based on data instead of opinions.</p>
                    </div>
                    <div className="biz-main-about-blk">

                        <div className=' '>
                            <div className='me-auto d-flex justify-content-between ' >
                                <h4>{"Service Option"}</h4>
                                <div className="BizAbout_edit_Icon " onClick={toggleOpenPoint}>
                                    <img src={Pen1} width={12} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="service-list-blk">
                            {data.map((menu, index) => (
                                <span>{menu}</span>
                            ))}
                        </div>
                    </div>
                    <div className="biz-main-about-blk">

                        <div className=''>
                            <div className='me-auto d-flex justify-content-between ' >
                                <h4>{"Offerings"}</h4>
                                <div className="BizAbout_edit_Icon " onClick={toggleOpenPoint}>
                                    <img src={Pen1} width={12} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="service-list-blk">
                            {
                                Offerings.map((offerData) => (
                                    <span>{offerData}</span>
                                ))}
                        </div>
                    </div>
                    <div className="biz-main-about-blk">

                        <div className=' '>
                            <div className='me-auto d-flex justify-content-between ' >
                                <h4>Additional Information</h4>
                                <div className="BizAbout_edit_Icon " onClick={toggleOpen1}>
                                    <img src={Pen1} width={12} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <p>Add any additional information for your clients/customer about your biz</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )


    // ---------------main_return_function---------------------------//
    return (
        <>
            <div className="container-fluid section-t-space px-0">
                <div className="page-content">
                    <div className="content-center w-100">
                        <div className="gdpost-about-blk">
                            <Row>
                                <Col md={5} lg={4} className="aboutside">
                                    <RowFirstSideData />
                                </Col>
                                <Col md={7} lg={8}>
                                    <RowSecondSideData />
                                </Col>
                                <Col md={4} className="aboutside1">
                                    <RowFirstSideData />
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            {/* // ----------------start_left_seide_Modal------------------------// */}
            <div className='Website_modal'>
                {
                    websiteOpen !== false &&
                    <Modal isOpen={websiteOpen} toggle={toggleWebsite} className='modal-dialog-centered'>
                        <div className='BizAbout_Heading'>
                            <div

                                onClick={handleCloseModal5}
                                className="Modal_False_blk1"
                            >
                                <XCircle color="#FFFFFF" />
                            </div>
                            <div className='BizAbout_Modal_section p-4 '>
                                <h4>Add/Edit Website </h4>
                                <p onClick={toggleWebsite}>Save</p>
                            </div>
                        </div>
                        <div className='p-4 AboutBiz_section'>
                            <Form>
                                <FormGroup>
                                    <Label className="Bio_text">
                                        Website
                                    </Label>
                                    <div className='BizAbout_input_box'>
                                        <Input

                                            id="Enter web URL"
                                            name="Website"
                                            placeholder="Enter web URL"
                                            type="Website"
                                        />
                                    </div>
                                </FormGroup>
                                {showResults ? (<FormGroup>
                                    <Label className="Bio_text">
                                        Enter other URL
                                    </Label>
                                    <div className='BizAbout_input_box'>
                                        <Input

                                            id="Enter web URL"
                                            name="Website"
                                            placeholder="Enter web URL"
                                            type="Website"
                                        />
                                    </div>
                                </FormGroup>) : null
                                }
                                <p onClick={toggle} className='text-end'>
                                    {showResults ? '- Remove other URL' : '+ Add other URL'}
                                </p>
                            </Form>
                        </div>

                    </Modal>
                }

            </div>
            <div className='Time_modal'>
                {
                    tymOpen !== false &&
                    <Modal isOpen={tymOpen} toggle={toggleTym} className='modal-dialog-centered'>
                        <div className='BizAbout_Heading'>
                            <div

                                onClick={handleCloseModal1}
                                className="Modal_False_blk1"
                            >
                                <XCircle color="#FFFFFF" />
                            </div>
                            <div className='BizAbout_Modal_section p-4 '>
                                <h4>Add/Edit Timing </h4>
                                <p onClick={toggleTym}>Save</p>
                            </div>
                        </div>
                        <div className='p-4 '>
                            <Label className="Bio_text">
                                Starting Time
                            </Label>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Input
                                            name="otp"
                                            type="text"
                                            placeholder="hh"
                                            pattern="[0-9]*"
                                            maxLength={2}
                                            autoComplete="off"
                                            value={hour}
                                            className="Biz_form_input_field"
                                            onChange={startingHour}
                                        />
                                    </div>
                                    &nbsp;&nbsp;:&nbsp;&nbsp;
                                    <div>
                                        <Input
                                            name="otp"
                                            type="text"
                                            placeholder="mm"
                                            pattern="[0-9]*"
                                            maxLength={2}
                                            autoComplete="off"
                                            className="Biz_form_input_field"
                                            value={minute}
                                            onChange={startingMinute}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex ml-4'>
                                    <label className='d-flex align-items-center' htmlFor="radio-buttonsOneId">
                                        <Radio
                                            onChange={handleChangeStartingTime}
                                            value={'AM'}
                                            name="radio-buttonsOne"
                                            id="radio-buttonsOneId"
                                            className='Radio_btn_check'
                                            checked={selectedValue1 === 'AM'}
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                        <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                    </label>
                                    <label className='d-flex align-items-center ' >
                                        <Radio
                                            onChange={handleChangeStartingTime}
                                            value={'PM'}
                                            name="radio-buttonsOne"
                                            className='Radio_btn_check'
                                            id="radio-buttonsOneId"
                                            checked={selectedValue1 === 'PM'}
                                            inputProps={{ 'aria-label': 'B' }} />
                                        <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                    </label>
                                </div>
                            </div>
                            <div className='py-2'>
                                <Label className="Bio_text">
                                    Ending Time
                                </Label>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="hh"
                                                maxLength={2}
                                                className="Biz_form_input_field"
                                                autoComplete="off"
                                                value={hour1}
                                                onChange={endingHour}
                                            />
                                        </div>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="mm"
                                                maxLength={2}
                                                className="Biz_form_input_field"
                                                autoComplete="off"
                                                value={minute1}
                                                onChange={endtingMinute}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex ml-4'>
                                        <label className='d-flex align-items-center '>
                                            <Radio
                                                onChange={handleChange}
                                                value={'AM'}
                                                name="radio-buttonsTwo"
                                                className='Radio_btn_check'
                                                checked={selectedValue === 'AM'}
                                                inputProps={{ 'aria-label': 'A' }} />
                                            <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                        </label>
                                        <label className='d-flex align-items-center '>
                                            <Radio
                                                onChange={handleChange}
                                                value={'PM'}
                                                name="radio-buttonsTwo"
                                                className='Radio_btn_check'
                                                checked={selectedValue === 'PM'}
                                                inputProps={{ 'aria-label': 'B' }} />
                                            <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <span style={{ cursor: "pointer", color: "#81C14B", font: "16px", fontFamily: "Poppins" }} onClick={addAdditionalHours}>   + Add additional hours</span>


                        </div>

                        {additionalHours.map((additionalHour, index) => (
                            <div className='p-4 '>
                                <Label className="Bio_text">
                                    Starting Time
                                </Label>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="hh"
                                                pattern="[0-9]*"
                                                maxLength={2}
                                                autoComplete="off"
                                                value={additionalHour.hour}
                                                className="Biz_form_input_field"
                                                onChange={(event) => handleStartingHourAdditional(event, index)}
                                            />
                                        </div>
                                        &nbsp;&nbsp;:&nbsp;&nbsp;
                                        <div>
                                            <Input
                                                name="otp"
                                                type="text"
                                                placeholder="mm"
                                                pattern="[0-9]*"
                                                maxLength={2}
                                                autoComplete="off"
                                                className="Biz_form_input_field"
                                                value={additionalHour.minute}
                                                onChange={(event) => handleStartingMinuteAdditional(event, index)}

                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex ml-4'>
                                        <label className='d-flex align-items-center' htmlFor="radio-buttonsOneId">
                                            <Radio
                                                onChange={() => handleRadioStartingTimeAdditional('AM', index)}
                                                value={'AM'}
                                                name={`radio-starting-time-${index}`}
                                                className='Radio_btn_check'
                                                checked={additionalHour.selectedValue1 === 'AM'}
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                            <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                        </label>
                                        <label className='d-flex align-items-center ' >
                                            <Radio
                                                onChange={() => handleRadioStartingTimeAdditional('PM', index)}
                                                value={'PM'}
                                                name={`radio-starting-time-${index}`}
                                                className='Radio_btn_check'
                                                checked={additionalHour.selectedValue1 === 'PM'}
                                                inputProps={{ 'aria-label': 'B' }}
                                            />
                                            <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                        </label>
                                    </div>
                                </div>
                                <div className='py-2'>
                                    <Label className="Bio_text">
                                        Ending Time
                                    </Label>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <Input
                                                    name="otp"
                                                    type="text"
                                                    placeholder="hh"
                                                    maxLength={2}
                                                    className="Biz_form_input_field"
                                                    autoComplete="off"
                                                    value={additionalHour.hour1}
                                                    onChange={(event) => handleEndingHourAdditional(event, index)}
                                                />
                                            </div>
                                            &nbsp;&nbsp;:&nbsp;&nbsp;
                                            <div>
                                                <Input
                                                    name="otp"
                                                    type="text"
                                                    placeholder="mm"
                                                    maxLength={2}
                                                    className="Biz_form_input_field"
                                                    autoComplete="off"
                                                    value={additionalHour.minute1}
                                                    onChange={(event) => handleEndingMinuteAdditional(event, index)}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex ml-4'>
                                            <label className='d-flex align-items-center '>
                                                <Radio
                                                    onChange={() => handleRadioEndingTimeAdditional('AM', index)}
                                                    value={'AM'}
                                                    name={`radio-ending-time-${index}`}
                                                    className='Radio_btn_check'
                                                    checked={additionalHour.selectedValue === 'AM'}
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                                <FormLabel className='ml-2 mt-2'>AM</FormLabel>
                                            </label>
                                            <label className='d-flex align-items-center '>
                                                <Radio
                                                    onChange={() => handleRadioEndingTimeAdditional('PM', index)}
                                                    value={'PM'}
                                                    name={`radio-ending-time-${index}`}
                                                    className='Radio_btn_check'
                                                    checked={additionalHour.selectedValue === 'PM'}
                                                    inputProps={{ 'aria-label': 'B' }}
                                                />
                                                <FormLabel className='ml-2 mt-2'>PM</FormLabel>
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <span
                                    style={{ cursor: "pointer", color: "red", font: "14px", fontFamily: "Poppins" }}
                                    onClick={() => removeAdditionalHours(index)}
                                >
                                    Remove this field
                                </span>
                            </div>
                        ))}



                    </Modal>
                }

            </div>
            <div className='Map_Modal'>
                {
                    mapOpen !== false &&
                    <Modal isOpen={mapOpen} toggle={toggleMap} className='modal-dialog-centered'>
                        <div

                            onClick={handleCloseModal2}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>
                        <ModalHeader className="BizAbout_Map_heading">
                            <h4>Add / Edit  Address</h4>
                        </ModalHeader>
                        <ModalBody className="p-4">
                            <div className=' BizAbout_Map_section'>
                                <h4>Dybdevej 10, 5200 Odense, Denmark</h4>
                                <p>Remove</p>
                            </div>
                            <hr className='text-muted' />
                            <div className="BizAbout_Map_subSection">
                                <h4>Add new Address</h4>
                                <span><img src={Track} alt="track" width={16} />&nbsp;&nbsp;Detact Location</span>
                            </div>
                            <div className='bizAbout_Map_Modal'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <FormGroup className="py-3 AboutBiz_section">
                                <div>
                                    <Input
                                        name="location"
                                        type="text"
                                        placeholder="2972 Westheimer Rd"
                                        autoComplete="off"
                                    />
                                </div>
                                <FormGroup className='mt-2'>
                                    <Input
                                        id="exampleSelect"
                                        name="select"
                                        type="select"
                                    >
                                        <option>
                                            Santa Ana
                                        </option>
                                    </Input>
                                    <FormGroup className=' mt-2 d-flex justify-content-between'>
                                        <Input
                                            name="location"
                                            type="text"
                                            placeholder="Illinois"
                                            autoComplete="off"
                                        />
                                        <Input
                                            maxLength={8}
                                            name="otp"
                                            type="text"
                                            autoComplete="off"
                                            pattern="[0-9]"
                                            inputMode="numeric"
                                            placeholder="854867 "
                                            onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                        />

                                    </FormGroup>
                                </FormGroup>

                                <p onClick={toggle1} className='text-end'>
                                    {showResults1 ? '- Remove more Address' : ' + Add more Address'}
                                </p>
                            </FormGroup>
                            {showResults1 ? (<FormGroup>


                                <div className="BizAbout_Map_subSection">
                                    <h4>Add new Address</h4>
                                    <span><img src={Track} alt="track" width={16} />&nbsp;&nbsp;Detact Location</span>
                                </div>
                                <div className='bizAbout_Map_Modal'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <FormGroup className="py-3 AboutBiz_section">
                                    <div>
                                        <Input
                                            name="location"
                                            type="text"
                                            placeholder="2972 Westheimer Rd"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <FormGroup className='mt-2'>
                                        <Input
                                            id="exampleSelect"
                                            name="select"
                                            type="select"
                                        >
                                            <option>
                                                Santa Ana
                                            </option>
                                        </Input>
                                        <FormGroup className=' mt-2 d-flex justify-content-between'>
                                            <Input
                                                name="location"
                                                type="text"
                                                placeholder="Illinois"
                                                autoComplete="off"
                                            />
                                            <Input
                                                maxLength={8}
                                                name="otp"
                                                type="text"
                                                autoComplete="off"
                                                pattern="[0-9]"
                                                inputMode="numeric"
                                                placeholder="854867 "
                                                onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                            />

                                        </FormGroup>
                                    </FormGroup>
                                </FormGroup>
                            </FormGroup>) : null
                            }

                            <div className='text-center'>
                                <Button onClick={toggleMap} outline>Cancle </Button>
                                <Button onClick={toggleMap} className="ml-2" color="primary">Confirm</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
            <div className='Email_Modal'>
                {
                    emailOpen !== false &&
                    <Modal isOpen={emailOpen} toggle={toggleEmail} className='modal-dialog-centered'>
                        <div

                            onClick={handleCloseModal3}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>
                        <ModalHeader className="BizAbout_Map_heading">
                            <h4>Add / Edit  Email Address</h4>
                        </ModalHeader>
                        <ModalBody className="p-4">
                            {emailAddresses.map((email, index) => (
                                <div key={index} className='BizAbout_Map_section mb-3'>
                                    <h4>{email}</h4>
                                    {addedEmail === email && <p onClick={handleVerifyClick1} style={{ color: '#81C14B' }}>Verify</p>}
                                    {addedEmail !== email && <p onClick={toggleRemove}>Remove</p>}

                                </div>
                            ))}
                            <FormGroup className="d-flex justify-content-between">
                                <Input
                                    name="location"
                                    type="text"
                                    placeholder="Enter your new email id"
                                    autoComplete="off"
                                    className="mr-3"
                                    value={inputValue}
                                    onChange={handleInputChange1}
                                />
                                <Button color="primary" disabled={!inputValue.trim()} onClick={handleAddEmail} >
                                    Add
                                </Button>
                            </FormGroup>

                            {verifyDivOpen && (
                                <div className='d-flex justify-content-center'>
                                    <div className='text-center'>
                                        <Label className="AboutBiz_EmailOTP_section mt-3">
                                            <h3>Enter OTP</h3>
                                        </Label>

                                        <div className='d-flex justify-content-center py-3'>
                                            <div className='Otp_section'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"  // Use type "text" to allow pattern validation
                                                    autoComplete="off"
                                                    pattern="[0-9]"  // Enforce numeric input
                                                    inputMode="numeric"  // Improve mobile support
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                    placeholder="2"// Remove non-numeric characters
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                    placeholder="5"
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                    placeholder="7"
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    placeholder="9"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    placeholder="6"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    placeholder="1"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end AboutBiz_Email_section'>
                                            <h4>00:20 sec</h4>
                                            {/* <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5> */}
                                        </div>
                                        <div className='text-center AboutBiz_Email_section'>

                                            <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5>
                                        </div>
                                        <div className='text-center py-2'>
                                            <Button outline onClick={handleCloseVerifyDiv} >Cancle </Button>
                                            <Button className="ml-2" onClick={handleConfirmButtonClick} color="primary">Confirm?</Button>
                                        </div>

                                    </div>
                                </div>
                            )}

                        </ModalBody>

                    </Modal>
                }

            </div>
            <div className='Remove_Modal'>
                {
                    removeOpen !== false &&
                    <Modal isOpen={removeOpen} toggle={toggleRemove} className='modal-dialog-centered'>
                        <ModalHeader className="BizAbout_Map_heading">
                            <h4>Remove Email </h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className=' RemoveBiz_section py-2'>
                                <h5>Do you really want to remove email id?</h5>
                                <h6>sociomee@gmail.com</h6>
                            </div>
                            <div className='text-center py-2'>
                                <Button onClick={toggleRemove} outline>Cancel </Button>
                                <Button onClick={toggleRemove} className="ml-2" color="primary">Confirm?</Button>
                            </div>

                        </ModalBody>

                    </Modal>
                }

            </div>
            <div className='Contact_Modal'>
                {
                    contactOpen !== false &&
                    <Modal isOpen={contactOpen} toggle={toggleContact} className='modal-dialog-centered'>
                        <div

                            onClick={handleCloseModal4}
                            className="Modal_False_blk1"
                        >
                            <XCircle color="#FFFFFF" />
                        </div>
                        <ModalHeader className="BizAbout_Map_heading">
                            <h4>Add/ Edit  Mobile Number </h4>
                        </ModalHeader>
                        <ModalBody className="p-4">
                            {emailList.map((email, index) => (
                                <div key={index} className='BizAbout_Map_section mb-3'>
                                    <h4>{email}</h4>

                                    <p onClick={toggleNumRemove} >Remove</p>
                                </div>


                            ))}

                            <FormGroup className="d-flex justify-content-between">
                                <Input
                                    name="location"
                                    type="text"  // Use type="text" to allow alphanumeric characters
                                    placeholder="Enter your new Phone number"
                                    autoComplete="off"
                                    maxLength="15"
                                    className="mr-3"
                                    value={newEmail}
                                    onChange={handleInputChange}
                                    onKeyPress={(e) => {
                                        // Allow only numeric values and prevent exceeding 10 digits
                                        if (!/[0-9]/.test(e.key) || newEmail.length >= 15) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <Button color="primary" onClick={handleVerifyClick} disabled={!newEmail.trim()}>
                                    verify
                                </Button>
                            </FormGroup>
                            {showOTPSection && (
                                <div className='d-flex justify-content-center'>
                                    <div className='text-center'>
                                        <Label className="AboutBiz_EmailOTP_section mt-3">
                                            <h3>Enter OTP</h3>
                                        </Label>

                                        <div className='d-flex justify-content-center py-3'>
                                            <div className='Otp_section'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"  // Use type "text" to allow pattern validation
                                                    autoComplete="off"
                                                    pattern="[0-9]"  // Enforce numeric input
                                                    inputMode="numeric"  // Improve mobile support
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                    placeholder="2"// Remove non-numeric characters
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                    placeholder="5"
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                    placeholder="7"
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    placeholder="9"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    placeholder="6"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                />
                                            </div>
                                            <div className='Otp_section ml-2'>
                                                <Input
                                                    maxLength={1}
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="off"
                                                    pattern="[0-9]"
                                                    inputMode="numeric"
                                                    placeholder="1"
                                                    onInput={(e) => (e.target.value = e.target.value.replace(/\D/, ''))}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end AboutBiz_Email_section'>
                                            <h4>00:20 sec</h4>
                                            {/* <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5> */}
                                        </div>
                                        <div className='text-center AboutBiz_Email_section'>

                                            <h5 className='py-2'>Didn’t receive OTP yet? <span >Resend </span></h5>
                                        </div>
                                        <div className='text-center py-2'>
                                            <Button outline onClick={handleCancelClick} >Cancel </Button>
                                            <Button className="ml-2" onClick={handleAddButtonClick} color="primary">Confirm?</Button>
                                        </div>

                                    </div>
                                </div>
                            )}

                        </ModalBody>


                    </Modal>
                }


            </div>
            <div className='Remove_Contact_Modal'>
                {
                    numRemoveOpen !== false &&
                    <Modal isOpen={numRemoveOpen} toggle={toggleNumRemove} className='modal-dialog-centered'>
                        <ModalHeader className="BizAbout_Map_heading">
                            <h4>Remove Number </h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className=' RemoveBiz_section py-2'>
                                <h5>Do you really want to remove number?</h5>
                                <h6>9876543210</h6>
                            </div>
                            <div className='text-center py-2'>
                                <Button onClick={toggleNumRemove} outline>Cancel </Button>
                                <Button onClick={toggleNumRemove} className="ml-2" color="primary">Confirm?</Button>
                            </div>

                        </ModalBody>

                    </Modal>
                }

            </div>

            <WhatWeDoModal
                toggleOpen={toggleOpen}
                isOpen={isOpen}
            />
            <AdditionalModal
                toggleOpen1={toggleOpen1}
                isOpen1={isOpen1}
                isOpen2={isOpen2} setIsOpen2={setIsOpen2} toggleOpenPoint={toggleOpenPoint}
                isOpen3={isOpen3} setIsOpen3={setIsOpen3} toggleOpenPoint1={toggleOpenPoint1}
            />
            <HeadingPointModal isOpen2={isOpen2} setIsOpen2={setIsOpen2} toggleOpenPoint={toggleOpenPoint}


            />

            <HeadingDespModal isOpen3={isOpen3} setIsOpen3={setIsOpen3} toggleOpenPoint1={toggleOpenPoint1} />
            <Timemodal toggleOpenPoint2={toggleOpenPoint2} setIsOpen4={setIsOpen4} isOpen4={isOpen4} toggleTym={toggleTym} setTymOpen={setTymOpen} tymOpen={tymOpen} />
            {/* <Timemodal2 toggleOpenPoint3={toggleOpenPoint3} setIsOpen5={setIsOpen5} isOpen5={isOpen5} /> */}

            {/* // ----------------end_left_seide_Modal------------------------// */}

        </>
    )
}

export default AboutBiz