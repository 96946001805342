import { FormControlLabel, Radio } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Minus } from 'react-feather';


export default function InputFieldWidget({ radioValue, isDisplay, i, option, singleOptionCount, setSingleOptionCount, setTempSingleOption, tempSingleOption, removeOneOption, remover, index }) {
    const [fieldValue, setFieldValue] = useState();


    useEffect(() => {
        const handleCustomEvent = (event) => {

            const { value } = event.detail;
            console.log(`Received Message: ${value}`);
            setFieldValue(value);
        };
        const eventName = "single_" + i;
        console.log(eventName);
        document.addEventListener(eventName, handleCustomEvent);

        return () => {
            document.removeEventListener(eventName, handleCustomEvent);
        };

    }, []);

    const optionIncrementHandler = (e) => {
        e.preventDefault();
        if (singleOptionCount.length < 10) {
            const nextData = `seq${singleOptionCount.length + 1}`;
            setSingleOptionCount([
                ...singleOptionCount,
                singleOptionCount.length + 1,
            ]);
            setTempSingleOption({ ...tempSingleOption, [nextData]: "" });
        }
    };
    const [selectedValue, setSelectedValue] = useState('female');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const [isChecked, setIsChecked] = useState(false);

    const handleRadioChange = () => {
        setIsChecked(!isChecked);
    };


    useEffect(() => {
        const handleCustomEvent = (event) => {
            const { value } = event.detail;
            console.log(`Received Message: ${value}`);
            setFieldValue(value);
            setIsChecked(value);
        };

        const eventName = "single_" + i;
        console.log(eventName);
        document.addEventListener(eventName, handleCustomEvent);

        return () => {
            document.removeEventListener(eventName, handleCustomEvent);
        };
    }, [i]);

    const singleRadioDataHandler = (e, i) => {
        const { value } = e.target;
        const eventName = "single_" + i;
        console.log("cint" + eventName);
        const customEvent = new CustomEvent(eventName, {
            detail: { value },
        });
        document.dispatchEvent(customEvent);
    };
    const handleDivClick = () => {
        if (isDisplay) {
            setIsChecked(!isChecked);
        }
    };
    return (
        <>
            <div
                className={isDisplay ? "form-control  d-flex my-1 position-relative" : " d-flex my-1 position-relative form-control hidebordertext hide-text"}
                key={i}
            >
                <div onClick={handleDivClick}>

                    <Radio
                        type="radio"
                        id={`radio${i}`}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        disabled={!isDisplay}
                    />
                </div>
                <input
                    id={i}
                    type="text"
                    className={isDisplay ? "form-control" : "form-control"}
                    style={{ border: "none" }}
                    placeholder={isDisplay ? "Type the ‘Option’ here.." : "Option"}
                    name={`seq${i + 5}`}
                    value={fieldValue}
                    onChange={(e) => singleRadioDataHandler(e, i)}
                    readOnly={!isDisplay}
                    maxLength={32}
                />
                {isDisplay && <div>  {
                    i >= 7 && (
                        <div className='input-minus-text'>
                            <div
                                style={{ cursor: 'pointer' }}
                                className=""
                                role="button"
                                onClick={() => removeOneOption()}
                            ><Minus style={{ marginTop: '11px', color: '#81c14b' }} /></div>
                        </div>
                    )
                }</div>}
            </div>
        </>
    )
}
