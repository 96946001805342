import React, { Fragment } from 'react'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import { StorySlider } from '../Story/StorySlider'
import ContentLeft from '../ContentLeft'
import ContentRight from '../ContentRight'
import MainCreation from './MainCreation'
import './SocilFeed.css'
import SliderStory from '../Story/SliderStory'
import MediaPreview2 from '../Universal-components/post-creations/sub-components/MediaPreviewModal2'
// import MediaPreviewModal2 from '../Universal-components/post-creations/sub-components/MediaPreviewModal2'

const SocialFeed = () => {

    //================= Main_retun_function ====================//
    return (
        <Fragment>
          
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    {/* <StorySlider /> */}
                    <SliderStory />
                    <div className="container-fluid section-t-space px-0">
                        <div className="page-content">
                            <ContentLeft />
                            <div className="content-center px-0  pointerForworked rounded">
                                <MainCreation />
                            </div>
                            <ContentRight />
                        </div>
                    </div>

                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}

export default SocialFeed