import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Send, Smile, PhoneCall,Bookmark } from 'react-feather'
import { Card, CardBody, Input, Button } from 'reactstrap'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import UserImg from '../../../NewComponents/IMG/img.svg'
import CommentLatest from '../../../NewComponents/IMG/CommentLatest.svg'
import CommentActive from '../../../NewComponents/IMG/CommentActive.svg'
import ShareLatest from '../../../NewComponents/IMG/ShareLatest.svg'
import { emoteData } from '../../Social-Feed-Component/FeedArray'
import "../MarketPlace.css"
import timeAgo from "../../../functions/timeAgo";
import MarketPlaceDeleteModal from '../ModalSection/MarketPlaceDeleteModal'
import { likeMarketPlaceProduct, getproductComments, addComment,likeUnlikeMpSubComment } from "../../../Services/Actions/MarketPlace/getAllProductsAction";
import  { handleContactClick } from "../../../../src/functions/copyContact.js"
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { getSingleProductAction, getSuggestedProductList, deleteMarketPlaceProduct, getPeopleList } from "../../../Services/Actions/MarketPlace/getAllProductsAction";









const MpLikeShareCompo = ({ toggleForward,marketPlaceData}) => {

    
    // to navigate the onclick 
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [comment, SetComment] = useState(false)
    const [refresh,setRefresh] =useState(false)


    const [BookmarkIcon, SetBookmark] = useState(true)



    //getiing single mp post data from store
    const { products ,error } = useSelector(state => state.getSingleMarketPlaceProduct);
    const singleProductData=products[0];

    // dispacting the values
    useEffect(() => {
        let body = { marketPlaceId: marketPlaceData?.id };
        dispatch(getSingleProductAction(body));
    }, [dispatch,refresh]);
    // Inside COmment States 


    // num formatter 
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt((num / 1000))}K`
        } else if (num >= 999999) {
            return `${parseInt((num / 1000000))}M`
        } else if (num <= 999) {
            return `${num}`
        }
    }



    const Commenttoggle = () => {
        SetComment(!comment)
    }
  
    let timer;
    // const Reactionshowtoggle = () => {
    //     setIsShown(true)
    // }
    // const ReactionHidetoggle = () => {
    //     // timer = setTimeout(() => setIsShown(false), 1000);
    //     setIsShown(false)
    // }



    const EmoteAvatar = () => {

        return (
            <div className='px-2'>
                <ul className="matual-friend-blk">
                    {
                        emoteData.slice(0, 4).map((item, index) => {
                            return (

                                <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="sufiya eliza" data-img={item.img}>
                                    <img src={item.img} className="bg-img" alt="" />
                                </li>

                            )
                        })
                    }
                </ul>
            </div >
        )
    }


    useEffect(() => {
        // "timer" will be undefined again after the next re-render
        return () => clearTimeout(timer);
    }, [timer])




   //here we are setting fist image as main image
   const [mainImage, setMainImage] = useState('');
   useEffect(() => {
           if (singleProductData?.mpMediaLists?.length) {
               const initialImage = singleProductData.mpMediaLists.find(item => item.sequence === 0) || singleProductData.mpMediaLists[0];
               setMainImage(initialImage.fileURL);
           }
       }, [singleProductData]);

       


    //================>> like product section <<=================

    const [LikeIcon, SetLikeIcon] = useState(0)
    const [likesCount, setLikesCount] = useState(0)
    const [isLiking, setIsLiking] = useState(false); // Add state for loading status
        useEffect(()=>{
            SetLikeIcon(singleProductData?.isliked)  
            setLikesCount(singleProductData?.likesCount)  
        },[singleProductData])


    // Hitting like API
    const likeUnlikeProduct =async(e) => {
        e.stopPropagation();
        SetLikeIcon(prevLikeStatus => !prevLikeStatus);
        if (isLiking) return; // Prevent multiple clicks
        setIsLiking(true); // Set loading status
        try {
            const currentLikeStatus = !LikeIcon;   
            const likeResult = await likeMarketPlaceProduct(singleProductData.id, currentLikeStatus);
            if (likeResult === "Success") {
                if (currentLikeStatus) {
                    setLikesCount(prevLikesCount => prevLikesCount + 1);
                } else {
                    setLikesCount(prevLikesCount => prevLikesCount - 1);
                }
            }
        } catch (error) {
            console.log("something went wrong:", error);
        } finally {
            setIsLiking(false); // Reset loading status
        }
    };


    const likeUnlikeSubComment =async(e,likeStatus,commentId)=>{    
        e.stopPropagation();
        const body ={
            commentId:commentId,
            like :likeStatus?.isliked?0:1
        }
        const likeResult = await likeUnlikeMpSubComment(body); 
        setCommentRefresh(prev => !prev);
    }


    
   // =============>> like product section end <<======================







   //===============>> get all comment section  start <<<=================

   const [commentPageIndex, setCommentPageIndex] = useState(0);
   const [commentData, setCommentData] = useState([])
   const [commentCount,setCommentCount] = useState(0)
   const [showSubcomment, setShowSubcomment] = useState(false);
   const [expandedCommentId, setExpandedCommentId] = useState(null);
   const [commentRefresh,setCommentRefresh] = useState(false)



   useEffect(() => {
     const fetchComments = async () => {
       const marketPlaceId = singleProductData?.id;
       const pageSize = 10;
       try {
         const productComments = await getproductComments(marketPlaceId, pageSize, commentPageIndex);
         setCommentCount(productComments?.totalCommentCount);
         setCommentData(productComments?.rows || []);
       } catch (error) {
         console.error("Error fetching product comments:", error);
       }
     };
 
     if(singleProductData?.id){
        fetchComments();
     }
  
   }, [commentPageIndex,commentRefresh,singleProductData]);


   const handleReplyClick = (id) => {
       setExpandedCommentId(expandedCommentId === id ? null : id);
   };

   //===============>> get all comment section  end <<<================
   



   //===============>> add comment section <<<=================

    const [commentTextValue, setCommentTextValue] = useState("");
    const [subCommentTextValue, setSubCommentTextValue] = useState("");
    const [hashTags, setHashTags] = useState([]);
    const [mentionIds, setMentionIds] = useState([]);
  
    const addCommentHandle = async (cmmtId, isSubComment) => {
      try {
        const body = {
          marketPlaceId: singleProductData?.id,
          comment: isSubComment ? subCommentTextValue : commentTextValue, 
          commentId: cmmtId,
          hashTags: hashTags,
          mentionIds: mentionIds,
        };
  
        const addCommentResult = await addComment(body);
        if (isSubComment) {
          setSubCommentTextValue(""); 
        } else {
          setCommentTextValue("");
        }
        setMentionIds([]);
        setHashTags([]);
        setCommentRefresh(prev => !prev);
      } catch (error) {
        console.error("Error during add comment:", error);
      }
    };
  
    //handling comment
    const handleCommentInput = (e) => {
      setCommentTextValue(e.target.value);
    };
  
    const commentHandle = (e) => {
      e.stopPropagation();
      addCommentHandle(null, false); //sending false for sub commnet
    };
  
    //handling sub comment
    const handleSubCommentInput = (e) => {
      setSubCommentTextValue(e.target.value);
    };
  
    const handleSubCommentHandle = (e, cmmtId) => {
      e.stopPropagation();
      addCommentHandle(cmmtId, true); //sending true for subcomment
    };
 
    // console.log("this is commentText:",commentText)
   //===============>> end add comment section <<<=================



   
   const handleRedirect = () => {
    window.open("https://msgmee.com/", "_blank");  
};

const [isDelete, setIsDelete] = useState(false);
const [soldOut, setSoldOut] = useState(false)
const [marketPlaceId, setMarketPlaceId] = useState(null);

  // State And function For the Delete Operation

  const [deleteOpen, setDeleteOpen] = useState(false)
  const toggleDelete = () => {
      setDeleteOpen((preState) => !preState)
  }


  useEffect(() => {
    let body = {};
    if (isDelete && marketPlaceId) {
      body.marketPlaceId = marketPlaceId;
      dispatch(deleteMarketPlaceProduct(body))
        .then(() => {
          sweetAlertConfirmation("product successfully deleted");
          navigate("/marketplace");
        })
        .catch((error) => {
          console.log("error during delete product", error);
        });
    }
  }, [dispatch, isDelete]);


    // =========>>mark as sold product======================
    const markAsSold = async(e,marketPlaceId,soldStatus) =>{
        try {
            soldStatus = soldStatus === 0 ? 1 : 0;
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/marketPlace/markasSold`,
                {marketPlaceId: marketPlaceId,sold: soldStatus},
                {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,},}
            );
            if(response.data.success)  {
                if(soldStatus){
                    sweetAlertConfirmation("Succesfully Sold Out")        
                }
                else{
                    sweetAlertConfirmation("Succesfully Put On Sale")  
                }
                setRefresh(prevRefresh => !prevRefresh); 
            }
        } catch (error) {
            console.error("Error during  marketplace mark As sold product:", error);
            return error
        }

    }



    // =======================================component===================================
    return (
        <Fragment>
            <Card>

                <CardBody >
                    {/* like */}
                    <div className='d-flex justify-content-between p-2 likempdiv'>
                        <div className='d-flex align-items-center w-75' >

                            {
                                LikeIcon ? <div>
                                    <img style={{ cursor: 'pointer' }} onClick={(e) => {likeUnlikeProduct(e)}} width={35} src={ActiveCirclelike} alt='shareIcon' />
                                </div> : <div>
                                    <img style={{ cursor: 'pointer' }} onClick={(e) => {likeUnlikeProduct(e)}} width={35} src={Circlelike} alt='shareIcon' />
                                </div>
                            }
                            <h5 className='mx-2'>{numFormatter(likesCount)}</h5>
                            {/* <div className="cardmobile"><EmoteAvatar /></div> */}

                            {/* <div><img src={Reaction} width={100} alt='reactions' /></div> */}
                        </div>
                        <div className='d-flex align-items-center  comenshare' style={{ cursor: 'pointer' }} >
                            <div onClick={Commenttoggle} className='d-flex align-items-center'>
                                {
                                    comment ? <div className='text-center'>
                                        <img width={35} src={CommentActive} alt='shareIcon' />
                                    </div> : <div className='text-center'>
                                        <img width={35} src={CommentLatest} alt='shareIcon' />
                                    </div>
                                }
                                <h5 className='px-3'>{numFormatter(commentCount)}</h5>
                            </div>
                            <div onClick={toggleForward} className='d-flex align-items-center'>
                                <div className='text-center'>
                                    <img width={35} src={ShareLatest} alt='shareIcon' />
                                </div>
                                <h5 className='px-3'>{numFormatter(singleProductData?.sharesCount || 0)}</h5>
                            </div>

                            

                        </div>
                        <div className='MpSigleProductBookMark1  mobilebookmark  cardmobileviewmp' onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '8px', backgroundColor: 'rgba(126, 178, 98, 0.1)', borderRadius: '5px', cursor: 'pointer' }}   >
                            {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                        </div>
                    </div>
                    
                      {/* ============mappping comment and subcomment ============= */}
                        {comment ? (
                                    <div style={{ padding: '1rem', maxWidth: '600px', margin: '0 auto' }}>
                                        {commentData && commentData.length > 0 ? (
                                            commentData.map(commentItem => (
                                                <div key={commentItem.id} style={{ marginBottom: '1rem' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={commentItem?.userDetails?.profileImage} alt="UserImage" style={{ borderRadius: '50%', width: '38px', height: '38px', marginRight: '0.5rem' }} />
                                                            <h4 style={{ margin: 0 }}>{commentItem?.userDetails?.userName}</h4>
                                                        </div>
                                                        <h5 style={{ margin: 0, color: '#6c757d' }}>{timeAgo(commentItem?.createdAt)}</h5>
                                                    </div>
                                                    <div style={{ padding: '0.5rem 1rem' }}>
                                                        <p style={{ color: '#6c757d' }}>{commentItem?.commentText}</p>
                                                        <div>
                                                            <span 
                                                                onClick={(e) => likeUnlikeSubComment(e, commentItem, commentItem?.id)}
                                                                style={{ color: commentItem?.isliked ? '#007bff' : '#000', cursor: 'pointer' }} 
                                                                className='text-primary'
                                                            > 
                                                                Like ({commentItem?.cmntlikesCount || 0})
                                                            </span>
                                                            &nbsp;
                                                            <span 
                                                                onClick={() => handleReplyClick(commentItem.id)}
                                                                style={{ color: '#007bff', cursor: 'pointer' }} 
                                                                className='text-primary'
                                                            >
                                                                Reply ({commentItem.subComment ? commentItem.subComment.length : 0})
                                                            </span>
                                                        </div>
                                                        {expandedCommentId === commentItem.id && (
                                                            <div style={{ marginTop: '1rem' }}>
                                                                {commentItem.subComment && commentItem.subComment.length > 0 ? (
                                                                    commentItem.subComment.map(reply => (
                                                                        <div key={reply.id} style={{ marginBottom: '1rem' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <img src={reply?.profileImage} alt="UserImage" style={{ borderRadius: '50%', width: '38px', height: '38px', marginRight: '0.5rem' }} />
                                                                                    <h4 style={{ margin: 0 }}>{reply?.userName}</h4>
                                                                                </div>
                                                                                <h5 style={{ margin: 0, color: '#6c757d' }}>{timeAgo(reply?.timestamp)}</h5>
                                                                            </div>
                                                                            <div style={{ padding: '0.5rem 1rem' }}>
                                                                                <p style={{ color: '#6c757d' }}>{reply?.comment}</p>
                                                                                <div style={{ color: '#007bff',cursor:"pointer" }} className='text-primary' 
                                                                                 onClick={(e) => likeUnlikeSubComment(e, reply, reply?.subcommentId)}>

                                                                                    Like ({reply?.subCommentLikesCount || 0})
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <p style={{ color: '#6c757d' }}>No replies yet.</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {expandedCommentId === commentItem?.id && (
                                                        <div style={{ margin: '1rem 0', position: 'relative' }}>
                                                            <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px', width: '100%', boxSizing: 'border-box', fontSize: '0.8rem' }}
                                                              placeholder='Add reply..' value={subCommentTextValue} onChange = {handleSubCommentInput} />
                                                            <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                                                                <Smile color='#808080' strokeWidth={2} />
                                                                <Send color='#808080' style={{ marginLeft: '0.5rem' }} strokeWidth={2} cursor={"pointer"} onClick={(e)=>{handleSubCommentHandle(e,commentItem?.id);}}/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <p style={{ textAlign: 'center', color: '#6c757d' }}>No comments available.</p>
                                        )}
                                    </div>
                                ) : null}


                        {/* ==============input type for comment================= */}

                        <div className='m-3 position-relative cardmobile'>
                            <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' 
                              value={commentTextValue} onChange = {handleCommentInput} />
                            <div className='positionForSerachSmile'>
                                <Smile color='#808080' strokeWidth={2} />
                                <Send color='#808080' className='iconForcolor' cursor={"pointer"} strokeWidth={2} onClick={(e)=>commentHandle(e)} />
                            </div>

                        </div>



                    {/* ==================================================================== */}
                    <div className='cardmobileviewmp'>
                        <div>
                            <div className='d-flex justify-content-between'>
                                <h5 className='Mp_Single_CardDetails_H5'>{singleProductData?.mpCategory?.name || ""}/{singleProductData?.mpSubCategory?.name || ""}</h5>
                                {/* <Button outline color="success" onClick={() => { navigate("/marketplace") }} ><CornerUpLeft size={16} />&nbsp;Back</Button> */}
                            </div>
                            <div className="Mp_Single_CardDetails  position-relative  ">
                                <h1 className="Mp_SinglePageHeading_Name">{singleProductData?.title ?singleProductData?.title:""}</h1>
                            </div>
                        </div>
                        <div className='Mp_setOrderLeft_ImageText' style={{
                            marginTop: "7px"
                        }}>

                            <h6 className='d-flex align-items-end justify-content-start gap-2 Mp_TextColorGray totalpri'>Totol Price: <h4 className='Mp_ColorPrimaryBold totalpri2' style={{ color: "#81c14b" }} > ${singleProductData?.price ?singleProductData?.price:""}</h4></h6>
                        </div>
                        <div className="Mp_Card_Call_Msg_Btn1 mt-3">
                        <Button className={`${singleProductData?.isOwner  === 1 ? "d-none" : ""}`} color="success" style={{ background: "#81c14b", borderColor: "#81c14b" }} 
                                onClick={(event) => handleContactClick(event, singleProductData?.user?.mobile || "")}>
                            <PhoneCall size={15} color="#fff" style={{ marginRight: "8px" }} /> Call Seller
                        </Button>
                        <Button
                            color="success"
                            outline
                            className={`Mp_Card_Msgbtn ${singleProductData?.isOwner  === 1 ? "d-none" : ""}`}
                            onClick={(e) => { handleRedirect(e) }}
                        >
                            <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="1.5" />
                            </svg>
                            Message
                        </Button>
                        {singleProductData?.isSold === 0 ?
                            <Button className={`${singleProductData?.isOwner  === 1 ? "" : "d-none"}`} color="primary" onClick={(e) => { setSoldOut(false);markAsSold(e,singleProductData?.id,singleProductData?.isSold) }} >
                                Mark AS Sold
                            </Button>
                            :
                            <Button outline className={`MpDeleteButton ${singleProductData?.isOwner  === 1 ? "" : "d-none"}`} onClick={() => {toggleDelete();setMarketPlaceId(singleProductData?.id); }} >
                                Delete
                            </Button>}


                        </div>
                    </div>
                    <MarketPlaceDeleteModal isOpen={deleteOpen} toggleOpen={toggleDelete} setIsDelete= {setIsDelete}/>
                </CardBody>

            </Card>
        </Fragment>
    )
}

export default MpLikeShareCompo
