import React, { useState } from 'react'
import { Pagination, Stack } from '@mui/material'
import { AlertTriangle, Eye, Link, MoreHorizontal, Music, Share2 } from 'react-feather'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'

import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ShotzVideoButton from '../../../NewComponents/IMG/ShotzVideoButton.svg'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { listDataShotz } from '../DummyArray'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { useUserAuth } from '../../../Context/userAuthContext'
import { ShotzCarouselComponents } from '../../Shotzs/shotz_components/ShotzCarouselComponents'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'

const ListShots = ({ display, space }) => {
    const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }

    {/*------------------------------------------------------------------------- Shotz carousel state called from context---------------------------------------------------------------------- */ }
    const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();

    {/*------------------------------------------------------------------------- Function to toggle shotz carousel state---------------------------------------------------------------------- */ }
    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }


    {/* -------------------------------------------------------------Main return function------------------------------------------------------------------------- */ }
    return (
        <div>

            <Row className=''>
                <Col md='12' className='p-2' >
                    {/* -------------------------------------------------List View data rendering------------------------------------------------------------------------- */}
                    {listDataShotz.map((data, index) => (
                        <div className='border rounded my-2 on-hover-bg p-2' key={index} >
                            {/* <div color={`primary ${display}`} style={{ position: 'absolute', top: '8px', left: '4px', zIndex: '5', fontSize: '12px' }} className='rotated-text' ># {data.trendingNumber}</div> */}
                            <Row className='d-flex align-items-center '>
                                <Col xl='8'>
                                    {/* <div className='d-flex ml-3'>
                                        <div className='right-triangle' style={{ position: 'absolute', top: '0px', zIndex: '4', left: '10px' }} ></div>
                                    </div> */}
                                    <div className='d-flex justify-content-start'>
                                        <div className='d-flex align-items-center p-2'>
                                            <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center rem-mob-view'>
                                                <p className='PilesThistags text-center'>Top</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                            </div>

                                            <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                <p className='PilesThistags text-center'>Trending</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-start py-2 ml-3'>
                                            <div style={{ position: 'relative' }} >
                                                <img src='https://images.unsplash.com/photo-1579625731274-2d63b457a742?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80' width={70} height={70} className=' ml-2 rounded' alt='' />
                                                <div style={{ position: 'absolute', top: '35%', left: '-25%' }}>
                                                    <img src={ShotzVideoButton} width={30} className='TrendThisPlayButton' alt='' />
                                                </div>
                                            </div>
                                            {/* <div style={{ position: 'absolute', top: '35%', left: '-1%' }} ><img src={ShotzVideoButton} width={30} className='TrendThisPlayButton' /></div> */}
                                            <div className='px-2 mt-2' onClick={toggleShotzCarouselModal} >
                                                <p className='mr-3' >{'Beyond the world-class education and  teaching, there is so much to love in the UK (also known by its full name: The United Kingdom of Great Britain and Northern Ireland).'.slice(0, 60) + '...'}</p>
                                                {/* <p className=''>More User */}
                                                {/* {seemore ? 'by their very nature,Through your' : null} <small style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '...Read more'}</small> </p> */}
                                                <div className='d-flex'>
                                                    <h5 className='px-1'><Eye color='gray' size={15} />&nbsp;<small style={{ color: "gray" }}>30k</small></h5>
                                                    <Music color='#81C14B' size={20} />&nbsp;&nbsp;
                                                    <NavLink to='/UsedAudioShotz'>
                                                        <p className='mr-2'>Maan Meri Jaan</p>
                                                    </NavLink >

                                                </div>
                                                {/* <p className='text-muted'>{data.dateandtime}</p> */}
                                                {/* <div className='d-flex' >
                                                    <p className='text-primary'>Top #{data.topsNumber}</p>
                                                    <p className='text-primary px-2'>Trending #{data.trendingNumber}</p>
                                                </div> */}
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center align-self-center ml-auto text-nowrap'>
                                                <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' alt='' />
                                                <div className='ml-1'>
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }}><p className='user-profile' >{data.name}</p></NavLink>
                                                    <small className='text-muted light-for-date'>{data.dateandtime}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='3'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className='d-flex align-items-center ml-5'>
                                            <div onClick={() => handleLike()}>
                                                {liked ?
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                    :
                                                    <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                }
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center px-3' data-bs-toggle="modal" data-bs-target="#imageModel"  >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                            </div>
                                            11.2k
                                        </div>

                                    </div>
                                </Col>
                                <Col xl='1' >
                                    <div >
                                        <UncontrolledButtonDropdown className='ml-3'>
                                            <DropdownToggle color='flat' >
                                                <MoreHorizontal color='#999999' size={19} />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific'>
                                                <DropdownItem onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem ><NavLink to='/MyProfile' style={{ color: 'black' }}><Eye size={13} />&nbsp;View creator's profile</NavLink></DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>

                            </Row>

                        </div>
                    ))}
                    {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}

                    {/* <div className='border rounded mt-3'>
                        <Row className='d-flex align-items-center mt-3'>
                            <Col xl='6'>
                                <div className='d-flex ml-3'>
                                    <Badge color={`light-primary ${display}`}>
                                        Trending #1
                                    </Badge>
                                    <Badge color='primary' pill className={space}>
                                        Top #96
                                    </Badge>
                                </div>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src='https://images.unsplash.com/photo-1579625731274-2d63b457a742?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80' width={80} height={80} className='m-3 ml-2 rounded' />
                                    <img src={ShotzVideoButton} width={30} className='TrendThisPlayButton' />
                                    <div>
                                        <p className='mt-1'>More User
                                            {seemore ? 'by their very nature,Through your' : null} <small style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '...Read more'}</small> </p>
                                        <div className='d-flex'>
                                            <Music color='#81C14B' size={20} />&nbsp;&nbsp;
                                            <NavLink to='/UsedAudioShotz'>
                                                <p className='mr-2'>Tere Bin</p>
                                            </NavLink >
                                        </div>
                                        <p className='text-muted'>19 sept at 07:00 pm</p>

                                    </div>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' />
                                        <div className='ml-1'>
                                            <p>Floyd Miles</p>
                                            <p className='text-muted'>19 sept at 07:00 pm</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='6'>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                        </div>
                                        11.2k
                                    </div>
                                    <UncontrolledButtonDropdown className=''>
                                        <DropdownToggle color='flat' >
                                            <MoreHorizontal color='black' className='' size={20} />
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                            <DropdownItem onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem ><Eye size={13} />&nbsp;View user</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Col>

                        </Row>
                    </div> */}
                    {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}

                    {/* <div className='d-flex justify-content-center p-2'>
                        <Stack spacing={2}>
                            <Pagination
                                count={10}
                                color='primary'
                                size="large" />
                        </Stack>
                    </div> */}

                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    {/* <p>Total Shotz Count : <span className='text-primary' >{listDataShotz.length}</span></p> */}
                                    <p>Showing 1 to {listDataShotz.length} of 200 entries</p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex p-2 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}
            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

            {/* ------------- Start Shotz Carousel Modal ------------- */}
            <ShotzCarouselComponents
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            />
            {/* ------------- End Shotz Carousel Modal ------------- */}
        </div>
    )
}

export default ListShots