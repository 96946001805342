import React,{Fragment} from "react";
import { Link } from "react-router-dom";
import {
  Edit2,
  Eye,
  EyeOff,
  Link2,
  MessageCircle,
  MoreHorizontal,
  Navigation,
  Send,
  Trash2,
} from "react-feather";
import { Card, CardBody, Col, Pagination, Row } from "reactstrap";
import { AlertTriangle, MapPin, MoreVertical,Sliders } from 'react-feather';
import { Button,
    Modal,
    ModalBody,
    ModalHeader} from 'reactstrap';
    import {
        DropdownItem,
        DropdownMenu,
        DropdownToggle,
        UncontrolledButtonDropdown,
        CustomInput
      } from "reactstrap";
import like2 from "../../../NewComponents/IMG/like2.svg";
import { Stack } from "react-bootstrap";
import { sweetAlertConfirmation } from "../../group-components/AllGroupsSections/SweetAlert";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, redirect, useNavigate } from 'react-router-dom';
import {
  deletePostAction,
  hideAllPostsFromTimelineAction,
  postShowAction

} from "../../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ArticleBody = (props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
//=========================================================for delete==========================================================================

  const [success, setSuccess] = useState(false);
  const [pageIndex,setPageIndex]=useState(0);
  const [pageSize,setPageSize]=useState(10);
  const [followerData,setFollowerData]=useState([]);
  const [followingData,setFollowingData]=useState([]);
  const [error,setError]=useState('');
  const [shareOpen, setShareOpen] = useState(false);
  const [searchKey,setSearchKey]=useState('');
  const [count,setCount]=useState(0);
  const [deleteOpen,setDeleteOpen]=useState(false);
  const [deleteId,setDeleteId]=useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                    { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setFollowerData(response.data.data.successResult.rows);
            } else {
                setError('no user found');
            }
        } catch (error) {
            setError(error.message);
        }
    };
    fetchData();
}, [searchKey,pageIndex,pageSize]);

useEffect(() => {
    const fetchData = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                    { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setFollowingData(response.data.data.successResult.rows);
            } else {
                setError('no user found');
            }
        } catch (error) {
            setError(error.message);
        }
    };
    fetchData();
}, [searchKey,pageIndex,pageSize]);

useEffect(() => {
  if (success) {
    props.onSuccess();
    setSuccess(false)
  }
}, [success, props.onSuccess]);


  const toggleShareOpen = (e) => {
    e.stopPropagation();
    setShareOpen((prevState) => !prevState);
};


  // COPY THE ARTICAL LINK

  const [copyLink, setCopyLink] = useState("");

  const postCopyLink = async (id) => {
    const newCopyLink = `${process.env.REACT_APP_IPURL}/MyArticle/${id}`;
    setCopyLink(newCopyLink);
    await navigator.clipboard.writeText(newCopyLink);
    sweetAlertConfirmation(`Link Copied`);
  };

  const toggleDeleteOpen =(e)=>{
    setDeleteId([]);
    e.stopPropagation();
    setDeleteOpen((prevState) => !prevState);
}

// console.log("search article===========================?",props?.data)
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const handleChange=(e)=>{
    setSearchKey(e.target.value);
  }

const toggleDeleteOpen1 =(e,id)=>{
    setDeleteId([id])
        e.stopPropagation();
        setDeleteOpen((prevState) => !prevState);
}

  const deleteAllPost = async (e) => {
    e.stopPropagation();
    try {
      await dispatch(deletePostAction(deleteId));
      setSuccess(true);
      sweetAlertConfirmation('Successfully deleted');
      setDeleteOpen((prevState) => !prevState);
    } catch (error) {
     setError(error);
    }
  };
  
const shareAll = () => {
sweetAlertConfirmation(`Your post shared to everyone`);
setShareOpen((prevState) => !prevState);
};

const hidePost = (e,id) => {
  e.stopPropagation();
  dispatch(hideAllPostsFromTimelineAction(id))
  setSuccess(true)
  sweetAlertConfirmation(`Your post hided from timeline`);
}

const showHiddenPost = (e,id) => {
  e.stopPropagation();
  dispatch(postShowAction(id))
  setSuccess(true)
  sweetAlertConfirmation(`Your post showed to timeline`);
}

const editPost =(e)=>{
  sweetAlertConfirmation("edit feature is in progress")
}

const handleCheckboxChange = (id) => {
  setCheckedItems(prevCheckedItems => {
    if (prevCheckedItems.includes(id)) {
      // Uncheck item
      return prevCheckedItems.filter(itemId => itemId !== id);
    } else {
      // Check item
      return [...prevCheckedItems, id];
    }
  });
};

const isShareAllDisabled = checkedItems.length === 0;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const months = ["January", "February", "March", "April", "May", "June",
                  "July", "August", "September", "October", "November", "December"];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${months[monthIndex]}, ${year}`;
};

const truncateText = (text, numWords) => {
  if (!text) return '';
  const words = text.split(' ');
  if (words.length <= numWords) return text;
  return words.slice(0, numWords).join(' ') + '...';
};

const wordLimit = 20;
// const truncatedDescription = truncateText(articleDescription, wordLimit);


const navigateToSomewhere =(e,id)=>{
  e.stopPropagation();
  e.preventDefault();
  navigate(`/MyPostDetail/${id}`,{ state: { userId, type:"viewer" }})
}


  return (
    <div className="tab-section">
      <Row>
        {props?.data?.map((art, index) => (
          <Col lg="4 py-3" key={index} style={{ display: 'flex', flexDirection: 'column' }}>
            <Card className="Artical_Section" style={{ flex: 1, display: 'flex', flexDirection: 'column', borderRadius:"5px", }}>
              <img src={art.articleCoverImageURL} alt="img" style={{ 
                  width: '100%', 
                  height: '200px', // Fixed height
                  borderRadius:"5px",
                  objectFit: 'cover' // Maintain aspect ratio
              }} onClick={(e)=>navigateToSomewhere(e,art.postId)}/>

              <CardBody>
                <div className="Artical_Top_Right">
                  <p className="m-2 ">
                    <Eye size={18} color="#4D4D4D" />
                    &nbsp;{art.viewsCount}
                  </p>
                  <p className=" m-2 ">
                    <img src={like2} className="Article_like_thumble" />
                    &nbsp;{art.likesCount}
                  </p>
                  <p className=" m-2 ">
                    <MessageCircle color="#4D4D4D" size={18} />
                    &nbsp;{art.commentsCount}
                  </p>
                  <p className=" m-2">
                    <Send size={18} color="#4D4D4D" />
                    &nbsp;{art.sharesCount}
                  </p>
                </div>
                <div className="Artical_Main_section d-flex justify-content-between align-items-center">
                  <div className="Artical_Title_Section" onClick={(e)=>navigateToSomewhere(e,art.postId)}>
                    <span>{formatDate(art.createdAt.substring(0, 10))} ● {art.articleCategory}</span>
                  </div>

                  <MoreHorizontal
                    className="dropbtn-gal"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    size={24}
                    color="#808080"
                  />
                  <div className="dropdown-menu dropdown-menu-left custom-dropdown drop-menu-gal w-50 MyArticle">
                    <ul>
                      <li>
                        {art.hidden ? (
                          <Link onClick={(e)=>showHiddenPost(e,art.postId)}>
                          <Eye size={15} color="#808080" /> Show post to
                          timeline
                        </Link>

                        ):(
                          <Link onClick={(e)=>hidePost(e,art.postId)}>
                          <EyeOff size={15} color="#808080" /> Hide post from
                          timeline
                          </Link>
                        )}
                        
                      </li>
                      <li
                        onClick={() => {
                          postCopyLink(art.postId);
                        }}
                      >
                        <Link >
                          <Link2 size={15} color="#808080" /> Copy Link
                        </Link>
                      </li>
                      <li onClick={(e)=>editPost(e)}>
                        <Link>
                          <Edit2 size={15} color="#808080" /> Edit Post
                        </Link>
                      </li>
                      <li onClick={(e)=>toggleShareOpen(e)}>
                        <Link >
                          <Navigation size={15} color="#808080" />
                          Share
                        </Link>
                      </li>
                      <li
                        onClick={(e) => {
                          toggleDeleteOpen1(e,art.postId);
                        }}
                      >
                        <Link >
                          <Trash2 size={15} color="#808080" />
                          Delete
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="Artical_Heading_Section mt-2" onClick={(e)=>navigateToSomewhere(e,art.postId)}>
                  <h4>{art.caption}</h4>
                </div>
                <div className="Artical_subHeading_Section mt-2">
                <p onClick={(e)=>navigateToSomewhere(e,art.postId)}>
                {art?.articleDescription?.length > wordLimit ? (
                  <>
                    {truncateText(art?.articleDescription, wordLimit)}
                    <span
                      className="read-more"
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    >
                    
                    </span>
                  </>
                ) : (
                  art.articleDescription || "No description available"
                )}
              </p>

                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="vertically-centered-modal">
                    {deleteOpen !== false && (
                        <Modal
                        isOpen={deleteOpen}
                        toggle={toggleDeleteOpen}
                        className="modal-dialog-centered"
                        >
                        <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                            <h4>Delete Article</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex justify-content-center modalHeading_Section">
                            <p>Do you really want to delete the selected Article ?</p>
                            </div>
                            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                            <small>If</small> YES! <small>please click on ok</small>
                            </h1>
                            <div className="d-flex justify-content-center  mt-4">
                            <Button onClick={toggleDeleteOpen} outline style={{ width: '100px' }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={(e)=>deleteAllPost(e)}
                                color="primary"
                                className="ml-3 "
                                style={{ width: '100px' }}
                            >
                                OK
                            </Button>
                            </div>
                        </ModalBody>
                        </Modal>
                    )}
                    </div>
                    
                    <div className="vertically-centered-modal">
                    {shareOpen && (
        <Modal
          isOpen={shareOpen}
          toggle={toggleShareOpen}
          className="modal-dialog-centered"
        >
          <div className="w-100 border-bottom">
            <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
              <h4>Share People</h4>
              <span style={{ cursor: isShareAllDisabled ? "not-allowed" : "pointer",alignItems:'center' }}
                  onClick={!isShareAllDisabled ? shareAll : undefined}
                  className={isShareAllDisabled ? 'disabled' : ''} 
              >
                Share
              </span>
            </div>
          </div>
          <ModalBody>
            <Row className="mt-2">
              <Col md="8" className="Like_search">
                <div className="profile-search-blk w-100">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                    value={searchKey}
                    autoFocus
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md="4" className="d-flex justify-content-end">
                <UncontrolledButtonDropdown color="secondary" outline>
                  <DropdownToggle
                    outline
                    className="border border-muted ml-3"
                  >
                    <Sliders className="Slider_Filter" size={12} />
                    &nbsp;&nbsp;Filter
                  </DropdownToggle>
                  <DropdownMenu className="DropDown_Group_item">
                    <DropdownItem onClick={() => setCount(0)}>
                      Followers
                    </DropdownItem>
                    <DropdownItem onClick={() => setCount(2)}>
                      Followings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
              <Row className="mt-3">
                {count === 0 && followerData?.filter(data =>
                  data.fullName.includes(searchKey) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                  data.followBackStatus.includes(searchKey)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
                {count === 2 && followingData?.filter(data =>
                  data.fullName.includes(searchKey) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                  data.followBackStatus.includes(searchKey)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </Row>
             
            </Row>
          </ModalBody>
        </Modal>
      )}
                </div>

      {/* <div className="d-flex justify-content-center mb-3 mt-3">
        <BasicsPagination />
      </div> */}
    </div>
  );
};

// const BasicsPagination = () => {
//   return (
//     <>
//       <Stack>
//         <Pagination count={10} className="PaginationCounts"></Pagination>
//       </Stack>
//     </>
//   );
// };
export default ArticleBody;
