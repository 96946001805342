import React from 'react'
import { Fragment } from 'react'
import { BizFollowOption } from './SettingArray'
import { getBizPageHistoryAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import BizProfile from '../Img/BizProfile.png'
import BizProfile1 from '../Img/BizProfile1.png'

const ManagementHistory = (props) => {

    const bizPageHistory = useSelector((state) => { return state.getBizPageHistoryData});
    const { loading: loadingBizPageHistory, error: errorBizPageHistory, result: resultBizPageHistory }= bizPageHistory 
    
    let dispatch = useDispatch();
    const { id } = useParams();
    
    useEffect(() => {
        dispatch(getBizPageHistoryAction(id)) 
    }, []);
    
    console.log('resultblockBizPageUser', resultBizPageHistory)



    const BizFollowOption = [
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        },
        {
            name: "Dianne Russell",
            id: "current-people123",
            status: "Following since 3 month ago ",
            img: BizProfile1,
            activityData: "Nov 8, 2020, 10:26 PM",
            status1: "Added Photos to ‘Product’ catalogue of Page."
        }
    ]
    return (
        <Fragment>
            <div className="custom-card-block">
                <div className="customAbout-card-head biz-carddetail-blk  " onClick={() => { props.setsetsideclassoption(false); }} style={{cursor:"pointer"}} >
                    <h3> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  className='hide-setting-options12'>
                            <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                        </svg>Page Management History </h3>
                </div>

                {/* this map will work when database give the data */}
                {/* {
                    resultBizPageHistory?.map((data) => (
                        <div className="biz-PeopleFollow-blk" >
                            <div className='d-flex align-items-start' >
                                <img src={data.users.profileImageThumb} className="img-fluid rounded-circle" alt='biz-img' width={60} />
                                <div className='ml-2 BizProfile_section' >
                                    <h4>{data.users.name}</h4>
                                    <h5>{data.createdAt}</h5>
                                    <p className='text-primary'>{data.eventKey}</p>
                                </div>
                            </div>

                        </div>
                    ))
                } */}

{
                    BizFollowOption.map((data) => (
                        <div className="biz-Follow-blk1 " >
                            <div className='d-flex ' >
                                <img src={data.img} className="img-fluid rounded-circle" alt='biz-img' width={60} />
                                <div className='ml-2 BizProfile_section blockhead' >
                                    <h4>{data.name}</h4>
                                    <h5>{data.status1}</h5>
                                    {/* <p className='text-primary'>{data.activityData}</p> */}
                                </div>
                            </div>
                            <p className='text-primary'>{data.activityData}</p>

                        </div>
                    ))
                }
            </div>
        </Fragment>
    )
}

export default ManagementHistory