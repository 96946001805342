import React, { useEffect, useState } from 'react';
import { Smile } from 'react-feather';

const StoryEmojies = () => {
  const [showIcons, setShowIcons] = useState(false);
  const [size, setSize] = useState(24); // default size

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width > 1024) {
        setSize(30);
      } else if (width > 768 && width <= 1024) {
        setSize(30);
      } else {
        setSize(20);
      }
    };

    handleResize(); // set initial size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="IconsStory text-white">
      {showIcons && (
        <>
          <img src="/assets/svg/emoji/040.svg" alt="smile" />
          <img src="/assets/svg/emoji/113.svg" alt="smile" />
          <img src="/assets/svg/emoji/027.svg" alt="smile" />
          <img src="/assets/svg/emoji/052.svg" alt="smile" />
          <img src="/assets/svg/emoji/039.svg" alt="smile" />
          <img src="/assets/svg/emoji/042.svg" alt="smile" />
        </>
      )}
      {!showIcons && <Smile size={size} onClick={() => setShowIcons(true)} />}
    </div>
  );
};

export default StoryEmojies;
