import axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { loadTaggedPeople } from "../../Services/Actions/Common/taggedPeopleAction";
import { loadAllTrendingHashtag } from "../../Services/Actions/Common/trandingHashtagAction";
import { deletePost } from "../../Services/Actions/SocialFeed/createSocialpost";
import { loadProfileByUserId } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { emoteData } from "../Social-Feed-Component/FeedArray";
import { loadEventCategory } from "../../Services/Actions/SocialFeed/getEventCategoryAction";
import { loadAllReactions } from "../../Services/Actions/SocialFeed/getAllReactionsAction";
import PostCreation from "./PostCreation";
import FormatTimeAgo from "./FormatTimeAgo";
import { loadAlertLevel } from "../../Services/Actions/SocialFeed/getAlertDataAction";
import { loadAlertRange } from "../../Services/Actions/SocialFeed/getAlertDataAction";
import { loadAllFeelingCategory } from "../../Services/Actions/Common/getFeelingCategoryAction";
import PostCard from "./Components/PostCard";
import { loadAllUserPosts } from "../../Services/Actions/SocialFeed/getAllUserPostsAction";
import getAllUserPosts from "../../Services/Reducers/SocialFeed/getAllUserPosts";
import { loadAllCommments } from "../../Services/Actions/SocialFeed/getAllCommentsAction";
import ShareToModal from "../top-trending-components/ShareToModal";
import { loadAllUserSciomate } from "../../Services/Actions/Connection/getSociomateAction";
import { loadAllConnections } from "../../Services/Actions/SocialFeed/getConnectionListAction";
import { loadAllUserGroups } from "../../Services/Actions/SocialFeed/getUserGroupsAction";
import { loadAllUserConnection } from "../../Services/Actions/Common/getUserConnectionAction";
import { loadAllFeedReportOptions } from "../../Services/Actions/SocialFeed/getFeedReportOptionsAction";
import ConnectionReportModal from "../Connections/ConnectionModal/ConnectionReportModal";
import ReportPostModal from "../post-components/Modals/ReportPostModal";
import { getFeedReportClick } from "../../Services/Actions/SocialFeed/FeedReportClickAction";
import { getActionBlockUnblock } from "../../Services/Actions/Connection/getBlockUnblockAction";
import { loadAllFeedStories } from "../../Services/Actions/Story/getAllstoryActions";
import { loadSearchdata } from "../../Services/Actions/mainSearchAction";
import { sweetAlertConfirmation } from '../group-components/AllGroupsSections/SweetAlert'
import { SweetErrorAlert } from '../group-components/AllGroupsSections/SweetErrorAlert';
import { syetemConfigurationSettings } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";


const MainCreation = () => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const [VideoOpen, setVideoClose] = useState(false);
  const toggleVideo = () => {
    setVideoClose(!VideoOpen);
  };
  const [LikeIcon, SetLikeIcon] = useState(false);
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeCount, setLikeCount] = useState();

  const [BookmarkIcon, SetBookmark] = useState(true);
  const [comment, SetComment] = useState(false);
  const [commentDataArray, setCommentDataArray] = useState([]);

  const [postConfSettings,setPostConfSettings]=useState([])
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [path, setPath] = useState("/modal/");
  const [postType, setPostType] = useState();
  const [shareCommentView,setShareCommentView]=useState(true)
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [feedPageIndex, setFeedPageIndex] = useState(0); // Local state for page index
  const observer = useRef(null);


  const { allUserPosts, pageIndex, loading } = useSelector((state) => state.getAllUserPostsData);
      useEffect(()=>{
        setFeedPageIndex(pageIndex) // this use effect we are using to refresh the feedPageIndex if any actvity happen in other component and feed post refresh
      },[pageIndex])
 

  const [ForwardArrayData, setForwardArrayData] = useState([]);

  const toggle = (path) => {
    // console.log("toogling path here",path," ",path.indexOf("/modal/"))
    if (path.indexOf("/modal/" > -1)) {
      // console.log("toogling path here1",path)
      if (modal) {
        console.log("toogling path here2",parseFloat(path.replace("/modal/", "")))
        setActiveTab(parseFloat(path.replace("/modal/", "")));

        setPath(path);

        const index = parseFloat(path.replace("/modal/", ""));

        const postType =
          index === 0.1
            ? "media"
            : index === 1
            ? "feeling"
            : index === 7.1
            ? "thought"
            : index === 7.3
            ? "event"
            : index === 7.6
            ? "recommendation"
            : index === 7.7
            ? "alert"
            : index === 7.8
            ? "poll"
            : "text";
        setPostType(postType);
      } else {
        const index = parseFloat(path.replace("/modal/", ""));
        if(index===0.1){
          const ptype="media";
          setPostType(ptype)
        }
        setActiveTab(parseFloat(path.replace("/modal/", "")));
        setModal(true);
        setPath(path);
        // console.log("modal", modal);
      }
    } else {
      setModal(false);
    }
  };

  const likePost = (postId) => {
    // Add logic here to like the post using an API request
    const requestData = {
      postId: postId,
      reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
    };

    axios
      .post(`${process.env.REACT_APP_IPURL}/post/setLike`, requestData, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((response) => {
        setLikeStatus((prevStatus) => ({
          ...prevStatus,
          [postId]: true,
        }));
      })
      .catch((error) => {
        console.error("Error liking post: ", error);
      });
  };

  const unlikePost = (postId) => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/post/setLike`,
        { postId: postId },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      )
      .then((response) => {
        setLikeStatus((prevStatus) => ({
          ...prevStatus,
          [postId]: false,
        }));
      })
      .catch((error) => {
        console.error("Error unliking post: ", error);
      });
  };

  const Commenttoggle = () => {
    SetComment(!comment);
  };
  const [commentR, SetCommentR] = useState("");
  const CommenttoggleR = () => {
    SetCommentR("I am seeking recommendation for buying ");
  };

  const [forwardOpen, setForwardOpen] = useState(false);
  const toggleForward = () => {
    setForwardOpen((preState) => !preState);
  };

  //  ------------- Start ReportedOption  -------------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
  };

  const EmoteAvatar = () => {
   
    // // return (
    // //   <div className="px-2">
    // //     <ul className="matual-friend-blk">
    // //       {allLikes?.map((item, index) => {
    // //         return (
    // //           <li
    // //             key={index}
    // //             className="popover-cls"
    // //             data-bs-toggle="popover"
    // //             data-placement="right"
    // //             data-name="sufiya eliza"
    // //             data-img={item.profileImageThumb}
    // //           >
    // //             <img src={item.profileImageThumb} className="bg-img" alt="" />
    // //           </li>
    // //         );
    // //       })}
    // //     </ul>
    // //   </div>
    // );
  };

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProfileByUserId());
    // dispatch(loadAllUserPosts()); //commented by sumit
  }, [dispatch]);

  const { trandingHashtags } = useSelector(
    (state) => state.TrendingHashtagData
  );
  const { eventCategory } = useSelector((state) => state.getEventCategoryData);
  const { taggedPeople } = useSelector((state) => state.TaggedPeopleData);
  const { alertLevel } = useSelector((state) => state.getAlertData);
  const { alertRange } = useSelector((state) => state.getAlertData);
  const { feelingCategory } = useSelector((state) => state.FeelingCategoryData);
  const { comments } = useSelector((state) => state.getAllCommentsData);
  const { allReactions } = useSelector((state) => state.getAllReactionsData);
  const { getUserSociomate } = useSelector((state) => state.userSociomateData);
  const { getPostReport } = useSelector((state) => state.getPostReportData);
  const { allStories } = useSelector((state) => state.getAllStoryData);
  const { allLikes } = useSelector((state) => state.getPostLikesData);
  const { allConnections } = useSelector(
    (state) => state.getAllConnectionsData
  );
  const { allGroups } = useSelector((state) => state.getUserGroupsData);
  const { getUserConnection } = useSelector(
    (state) => state.getUserConnectionData
  );
  const { getFeedReportOption } = useSelector(
    (state) => state.getUserReportOptionsData
  );

  


 

  useEffect(() => {
    dispatch(loadAllTrendingHashtag());
    dispatch(loadTaggedPeople());
    dispatch(loadEventCategory());
    dispatch(loadAlertLevel());
    dispatch(loadAlertRange());
    dispatch(loadAllFeelingCategory());
    dispatch(loadAllCommments());
    dispatch(loadAllReactions());
    dispatch(loadAllUserSciomate());
    // dispatch(loadAllUserPosts()); //commented b sumit
    dispatch(loadAllConnections());
    dispatch(loadAllUserGroups(userProfileByUserId.id));
    dispatch(loadAllUserConnection());
    dispatch(loadAllFeedReportOptions());
    dispatch(getFeedReportClick());
    dispatch(loadAllFeedStories());
    // dispatch(loadSearchdata())
  }, []);

  //---------------------handle Edit-----------------------------------------//
  const handleEdit = async (postId, userId, caption, postType) => {
    try {
      // Create the request body with postId and userId
      const requestBody = {
        userId: userId,
        id: postId,
        caption: caption,
        postType: postType,
      };

      // Make an API POST request to edit the post using axios
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/editPost`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json", // Set the content type
          },
        }
      );

      if (response.status === 200) {
        dispatch(loadAllUserPosts()); // Assuming this function fetches the updated data
      } else {
        console.error(
          "Error editing post. Server responded with status:",
          response.status
        );
        // Handle other error cases as needed
      }
    } catch (error) {
      // Handle any errors that occur during the edit process
      console.error("Error editing post:", error);
    }
  };


  const handleAllComments = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        commentId: "",
        searchKey: "",
        pageIndex: 0,
        pageSize: 20,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/getComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      setCommentDataArray(response.data.data.successResult.rows);
      // console.log(commentDataArray)
    } catch (error) {
      console.error("Error commenting on post:", error);
    }
  };
  //=========================handleBookmark=================================//
  const handleSave = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isSaved: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostSaved`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      sweetAlertConfirmation("Post Saved Successfully")
    } catch (error) {
      SweetErrorAlert("Error during Bookmark")
      console.error("Error saving on post:", error);
    }
  };

  const handleUnsave = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isSaved: false,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostSaved`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      sweetAlertConfirmation("Removed From Bookmark")
    } catch (error) {
      SweetErrorAlert("Error during Bookmark")
      console.error("Error saving on post:", error);
    }
  };
  //=============================handleCommentLike==============================//
  const handleCommentLike = (comment, setCommentLikeCount) => {
    const requestData = {
      commentId: comment.id,
      // reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
    };
    axios
      .post(`${process.env.REACT_APP_IPURL}/post/setCommentLike`, requestData, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      })
      .then((response) => {
        // console.log("liked", response);
        if (response.data.success === true) {
          setCommentLikeCount(comment.likesCount + 1);
        }
      })
      .catch((error) => {
        console.error("Error liking post: ", error);
      });
  };

  //==============================handleHidePost=======================//
  const handleHidePost = async (postId) => {
    try {
      const requestBody = {
        postId: postId,
        isHidden: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostHide`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        sweetAlertConfirmation("Post Hided Successfully")
        dispatch(loadAllUserPosts());
      } else {
        console.error(
          "Error Hiding post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  //==============================handleHidePost=======================//
  const handleNotificationOff = async (postId, setIsNotificationOff) => {
    try {
      const requestBody = {
        postId: postId,
        notificationOff: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        sweetAlertConfirmation("Post Notification Off")
        setIsNotificationOff(1);
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  const handleNotificationOn = async (postId, setIsNotificationOff) => {
    try {
      const requestBody = {
        postId: postId,
        notificationOff: false,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        sweetAlertConfirmation("Post Notification On")
        setIsNotificationOff(0);
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };
  //==============================handleAllowComments=======================//
  const handleCommentOff = async (
    postId,
    setIsCommentsAllowed,
    isCommentsAllowed
  ) => {
    try {
      const requestBody = {
        postId: postId,
        allowComments: false,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setAllowComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        sweetAlertConfirmation("Post Allow Comment Off")
        setIsCommentsAllowed(0);
        dispatch(loadAllUserPosts());
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  const handleCommentOn = async (
    postId,
    setIsCommentsAllowed,
    isCommentsAllowed
  ) => {
    try {
      const requestBody = {
        postId: postId,
        allowComments: true,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/setAllowComments`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        sweetAlertConfirmation("Post Allow Comment On")
        setIsCommentsAllowed(1);
        dispatch(loadAllUserPosts());
      } else {
        console.error(
          "Error Notification post. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error Hiding of post:", error);
    }
  };

  //======================handleBlockUnblock=========================//
  const handleBlockUnblock = (postType) => {
    dispatch(getActionBlockUnblock(postType));
  };



  //gettting the sytem post related settings
  const getPostConfSetting = async () => {
    if (postConfSettings.length < 1) {
      const settings = await syetemConfigurationSettings({ type: "socialFeed" }); 
       setPostConfSettings(settings); 
    }
  };

  useEffect(() => {
    getPostConfSetting();
  }, []);
  // =========== end========



  //======================handleProfileView==========================//
  const handleProfileView = () => {
    <Link to="/MyProfile"></Link>;
  };



  //==========this is code for infinite scrolling user scroll the page and get new data===============
  useEffect(() => {
    dispatch(loadAllUserPosts(null, feedPageIndex, 10, null)); // Load posts when pageIndex changes
  }, [dispatch, feedPageIndex]);

  useEffect(() => {
    const sentinelElement = document.querySelector('#sentinel');
    if (!sentinelElement) return;

    const observerCallback = (entries) => {
      if (entries[0].isIntersecting) {
        setFeedPageIndex((prevPageIndex) => prevPageIndex + 1);
      }
    };

    observer.current = new IntersectionObserver(observerCallback, {
      // rootMargin: '100px',
      rootMargin: '0.5px',
    });

    observer.current.observe(sentinelElement);

    return () => {
      if (observer.current) {
        observer.current.unobserve(sentinelElement);
      }
    };
  }, []);

//============================end infinite scroll===========================




//   //==================== Main_return_function ======================//
 
return (
  <Fragment>
   
      <PostCreation 
        feelingCategory={feelingCategory} 
        modal={modal} 
        setModal={setModal} 
        setSelectedImages={setSelectedImages}
        selectedImages={selectedImages} 
        toggle={toggle} 
        postType={postType} 
        setPostType={setPostType}
        postConfSettings ={postConfSettings}
      />

      <ShareToModal 
        forwardOpen={forwardOpen}  
        toggleForward={toggleForward}
        postType={postType}
        setPostType={setPostType}
        likePost={likePost}
        unlikePost={unlikePost}
        handleProfileView={handleProfileView}
        handleSave={handleSave}
        handleUnsave={handleUnsave}
        handleAllComments={handleAllComments}
        commentDataArray={commentDataArray}
        handleCommentLike={handleCommentLike}
        EmoteAvatar={EmoteAvatar}
        handleHidePost={handleHidePost}
        handleNotificationOff={handleNotificationOff}
        handleNotificationOn={handleNotificationOn}
        handleCommentOn={handleCommentOn}
        handleCommentOff={handleCommentOff}
        handleBlockUnblock={handleBlockUnblock} 
        setShareCommentView={setShareCommentView}
        shareCommentView={shareCommentView}
      />
    <div >
        {allUserPosts.map((post, index) => {
          const timeAgo = FormatTimeAgo(post.createdAt);
          return (
            <PostCard
              key={post.id || index} 
              postType={post}
              setPostType={setPostType}
              likePost={likePost}
              unlikePost={unlikePost}
              timeAgo={timeAgo}
              handleProfileView={handleProfileView}
              toggleForward={toggleForward}
              handleSave={handleSave}
              handleUnsave={handleUnsave}
              handleAllComments={handleAllComments}
              commentDataArray={commentDataArray}
              handleCommentLike={handleCommentLike}
              EmoteAvatar={EmoteAvatar}
              handleHidePost={handleHidePost}
              handleNotificationOff={handleNotificationOff}
              handleNotificationOn={handleNotificationOn}
              handleCommentOn={handleCommentOn}
              handleCommentOff={handleCommentOff}
              handleBlockUnblock={handleBlockUnblock}
              setShareCommentView={setShareCommentView}
              shareCommentView={shareCommentView}
              feelingCategory={feelingCategory}
              modal={modal}
              setModal={setModal}
              setSelectedImages={setSelectedImages}
              selectedImages={selectedImages}
              toggle={toggle}
              activeTab={activeTab}
              postConfSettings ={postConfSettings}
              setActiveTab={setActiveTab}
              displayFor ="FeedPostDisPlay" //this param we are passing also in share post modal section where it value is "shareModal"(to difrrenciate the post section)
            />
          );
        })}
        {/* Sentinel element for detecting when the last post is visible */}
          <div id="sentinel" style={{ height: '1px' }} />   
          {/* Show custom loading indicator when loading */}
      {loading && <center><h2 style={{color:"#81c14b", marginTop: "5px"}}>Loading  posts...</h2></center>} 
     </div>
  </Fragment>
);


};

export default MainCreation;
