import swal from 'sweetalert';

    export function sweetAlertConfirmation(message) {
        swal({
            title: message,
            icon: "success",
            button:'OK',
            background: 'white',
            timer: 1600,            
          }) 
      }



