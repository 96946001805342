import React, { useEffect, useState } from 'react';
import { ChevronRight, Copy, Check, CornerLeftDown, Flag, MinusCircle, MoreVertical, Share, UserPlus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import india_svg from '../../NewComponents/IMG/india_svg.svg';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowers } from '../../Services/Actions/Common/getUserFollowersAction';
import { loadAllFollowUnFollow } from '../../Services/Actions/Connection/getFollowUnFollowAction';
import { BlockUserModal } from './HoverComponents/BlockUserModal';
import { NavLink } from 'react-router-dom';
import RemoveModal from './ConnectionModal/RemoveModal';
import NewRemoveModal from './ConnectionModal/NewRemoveModal';
import BlockModal from './ConnectionModal/BlockModal';
import ConnectionShareModal from './ConnectionModal/ConnectionShareModal';
import UnfollowModal from './ConnectionModal/UnfollowModal';
import ConnectionReportModal from './ConnectionModal/ConnectionReportModal';
import { Button } from 'react-bootstrap';
import PublicAccount from './ConnectionModal/PublicAccount';
import PrivateAccount from './ConnectionModal/PrivateAccount';
import axios from 'axios';
import './Style.css'

const Followers = () => {
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const dispatch = useDispatch();
    const [userId, setUserId] = useState("");
    const [fullName, setFullName]= useState("");
    
    //search opearation
    const[searchItem, setSearchItem] =useState("");
    const [selectedRequest, setSelectedRequest] = useState(null);

    const filteredData = getUserFollowers?.rows?.filter((item) =>
        item.fullName.toLowerCase().includes(searchItem.toLowerCase()));

    const withdrawUserRequest = async (id) => {
        try {
          const body = {
            id,
            type:"people",
          };
          let user = JSON.parse(localStorage.getItem('sociomeeUser'));
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user?.token}`,
            },
          };
          const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/withdrawUserRequest`,
            body,
            config
          );
        } catch (error) {
          console.log('Error getting withdraw request:', error);
        }
      };

    useEffect(() => {
        dispatch(loadAllUserFollowers())
    }, [dispatch])

    // handle follow back
    const handleFollowBack = async (request) => {
        await dispatch(loadAllFollowUnFollow(request));
        dispatch(loadAllUserFollowers());
    };
    
    //handle withdraw requests
    const handlewithDrawRequests = (request) => {
        withdrawUserRequest(request.followingRequestId)
        setTimeout(() => {
        dispatch(loadAllUserFollowers());
        }, 1000);
    }

    // ------------- Unfollow Modal State -------------
    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false)
    const toggleUnfollowModal = (request) => {
        setUnfollowModalOpen((prevState) => !prevState)
        setSelectedRequest(request);
    }
    const[unfollowConfirm,setunfollowConfirm] = useState(false)
    useEffect(()=>{
        if(unfollowConfirm){
        setTimeout(() => {
            dispatch(loadAllUserFollowers());
            setunfollowConfirm(false);
        }, 1000);
        } 
    },[unfollowConfirm])

    // ------------- share Modal State -------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    // ------------------ Remove Followers Modal State ------------------
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = () => {
    setRemoveModalOpen((prevState) => !prevState)
    }
    const[removeConfirm,setRemoveConfirm] = useState(false)
    useEffect(()=>{
        if(removeConfirm){
        setTimeout(() => {
            dispatch(loadAllUserFollowers());
            setRemoveConfirm(false);
        }, 1000);
        } 
    },[removeConfirm])

    //============block modal------------------------------------
    const [blockModalOpen, setBlockModalOpen] = useState(false);
    const toggleBlockModalOpen = () => {
          setBlockModalOpen((prevState) => !prevState);
      };
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
        if(blockConfirm){
          setTimeout(() => {
            dispatch(loadAllUserFollowers());
            setBlockConfirm(false);
          }, 1000);
        } 
    },[blockConfirm])

    //  ------------- Start Reported Option State -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState);
        setSelectedRequest(request);
    }
    const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
            setTimeout(() => {
                dispatch(loadAllUserFollowers());
                setReportConfirm(false);
            }, 1000);
           } 
    },[reportConfirm])

    return (
        <>
            <div className="mppage-heading">
                <div className="group-custom-block">
                    <div className="heading-podcast-blk">
                        <h3 className='connection-sub-heading'>Your Followers ({getUserFollowers?.rows?.length})</h3>
                        {getUserFollowers?.rows?.length <= 5 ? null : (
                           <NavLink to='/FollowersScreen'>
                              <h5 className=''>View All <ChevronRight size={20} color='#81C14B' className='ml-1' /></h5>
                           </NavLink>
                        )}
                    </div>
                    <Row className='connections_PeopleThisGrid'>
                        {
                            filteredData && filteredData?.slice(0, 6).map((request) => {
                                return <Col lg='4' className='w-100'>
                                    <div className="connection-card box-shadow-style content-mp-block d-flex justify-content-between" >
                                        <div class="media media-new d-flex align-items-start">
                                            <div class="user-img d-flex align-items-start popover___wrapper " >
                                                <img src={request?.profileImage}
                                                    className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                                <span class="available-stats online"></span>
                                                {request?.isPrivate ? <PrivateAccount request={request}/> : <PublicAccount request={request}/>}
                                            </div>
                                            <div class="media-body d-md-block pl-1">
                                                <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>{request.fullName?.substring(0, 21) || 'NA'}</h4></NavLink>
                                                <div className='d-flex my-1 '>
                                                    <p className='user-name-style rem-mob-view-small'>{request?.userName || 'NA'}  |  </p>
                                                    <span>
                                                        <img
                                                            src={request?.countryData[0]?.flagURL}
                                                            className="ml-1 rem-mob-view-small"
                                                            alt="..."
                                                            width={19}
                                                            height={13}
                                                        />
                                                    </span>
                                                </div>
                                                <p className='professional-style rem-mob-view'>{request?.profession || "No profession"}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-end flex-column'>
                                            <UncontrolledButtonDropdown className='d-flex align-items-start flex-column justify-content-end pr-0 rem-mob-view-small' direction='left'>
                                                <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle pb-0 pr-0'>
                                                    <MoreVertical size={22} className='pr-0' />
                                                </DropdownToggle>
                                                <DropdownMenu className='Connection-dropdown'>
                                                    {/*<DropdownItem className='border-bottom py-2 menu-hover-style'><UserPlus size={15} className='mr-2 icon-color-style' />Follow Back</DropdownItem>*/}
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'  
                                                        onClick={() => { 
                                                            toggleRemoveModalOpen();
                                                            setFullName(request.fullName);
                                                            setUserId(request.id); 
                                                        }} 
                                                    >
                                                        <MinusCircle size={15} className='mr-2 icon-color-style' />
                                                        Remove Follower
                                                    </DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2 icon-color-style' />Share Profile</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2 icon-color-style' />Copy Profile URL</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'  
                                                    onClick={()=>{
                                                            toggleBlockModalOpen();
                                                            setUserId(request.id);
                                                        }}
                                                    ><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                                    <DropdownItem className='py-2 menu-hover-style' onClick={()=>{toggleReport(request)}}>
                                                        <Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>

                                            <div role="button" className='mt-3 mb-0 d-flex align-items-end'>
                                            {request.isFollower ? (
                                                      <>
                                                        <Button
                                                          color="flat-success"
                                                          className="btn-transparent pb-0 pr-0"
                                                          onClick={() =>
                                                            toggleUnfollowModal(request)
                                                          }
                                                        >
                                                          <Check size={15} color="#666666" />
                                                          &nbsp;
                                                          <p className="font-weight-bold text-right">
                                                            Following
                                                          </p>
                                                        </Button>
                                                      </>
                                                    ) : request.isPrivate ? (
                                                      // If not a follower and the account is private, check follow back request status
                                                      request.followBackRequestStatus === "pending" ? (
                                                                                    <>
                                                                                      <Button color='flat-success' className='btn-transparent pb-0 pr-0'
                                                                                        onClick={() =>
                                                                                          handlewithDrawRequests(request)
                                                                                        }
                                                                                      >
                                                                                        <CornerLeftDown size={15} color='#2F80ED' />
                                                                                          &nbsp;
                                                                                        <p className="font-weight-bold connection-blue-text rem-mob-view">
                                                                                          Withdraw
                                                                                        </p>
                                                                                      </Button>
                                                                                    </>
                                                                                  ) : (
                                                                                    <Button
                                                                                      color="flat-success"
                                                                                      className="btn-transparent pb-0 pr-0"
                                                                                      onClick={() => handleFollowBack(request)}
                                                                                    >
                                                                                      <UserPlus size={15} color="#81C14B" />
                                                                                        &nbsp;
                                                                                      <p className="font-weight-bold text-primary text-right">
                                                                                        Follow Back
                                                                                      </p>
                                                                                    </Button>
                                                                                  )) 
                                                                        : (
                                                                        // If not a follower and the account is not private
                                                                        <>
                                                                          <Button
                                                                            color="flat-success"
                                                                            className="btn-transparent pb-0 pr-0"
                                                                            onClick={() => handleFollowBack(request)}
                                                                          >
                                                                            <UserPlus size={15} color="#81C14B" />
                                                                              &nbsp;
                                                                            <p className="font-weight-bold text-primary text-right">
                                                                              Follow Back
                                                                            </p>
                                                                          </Button>
                                                                        </>
                                                                        )}
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                    <Row>
                        <Col xl='12' className='d-flex justify-content-center'>
                            {
                                getUserFollowers?.rows?.length===0 && <h3 className='d-flex justify-content-center connection-sub-heading'>No Requests Found</h3>
                            }
                        </Col>
                    </Row>
                </div>
            </div>

            {/*  ------------------ Add Report Option  ------------------ */}
            { reportOpen && (
                <ConnectionReportModal
                    request={selectedRequest}
                    modalOpen={reportOpen}
                    reportModalOpen={toggleReport}
                    setReportConfirm ={setReportConfirm}
                />
            )}

            {/*  ------------------ Add CategoryModal Status  ------------------ */}
            {/*<AddCategoryModal
                openModal={addCategoryOptions}
                cateModalOpen={toggleAddCategory}
            />*/}

            {/*  ------------------ Remove Member Modal  ------------------ */}
            { removeModalOpen && (
                <NewRemoveModal
                    userId={userId}
                    fullName={fullName}
                    openModal={removeModalOpen}
                    removeModalOpen={toggleRemoveModalOpen}
                    setRemoveConfirm={setRemoveConfirm}
                />
            )}

            {/*------------------------- block modal----------------------- */}
            { blockModalOpen && (
                <BlockModal
                    request={userId}
                    openModal={blockModalOpen}
                    removeModalOpen={toggleBlockModalOpen}
                    setBlockConfirm={setBlockConfirm}
                />
            )}
    `
            {/* ------------- Unfollow Modal Modal -------------*/}
            { unfollowModalOpen && (
                <UnfollowModal
                    request={selectedRequest}
                    openModal={unfollowModalOpen}
                    unfollowModalOpen={toggleUnfollowModal}
                    setunfollowConfirm={setunfollowConfirm}
                />
            )}`

            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
        </>
    )
}

export default Followers