import { Slider, useTheme } from '@mui/material'
import React from 'react'
import { Minus, Plus, RotateCw } from 'react-feather'

const StoryCropTool = ({ valueText, TextValue, zoom, setZoom, handleRotate }) => {
    const theme = useTheme()
    return (
        <div className='d-flex w-100 align-items-start'>
            <Minus role='button' size={24} className='mx-2 mt-1 text-muted' onClick={() => { setZoom(zoom - 1) }} />
            <Slider
                className="w-75 mx-3"
                valueLabelDisplay="off"
                sx={{
                    color: theme.palette.mode === "dark" ? "#81C14B" : "#81C14B",
                    "& .MuiSlider-track": {
                        border: "none",
                        backgroundColor: "#81c14b"

                    },
                    "& .MuiSlider-thumb": {
                        width: 24,
                        height: 24,
                        backgroundColor: "#fff",
                        "&:before": {
                            boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                        },
                        "&:hover, &.Mui-focusVisible, &.Mui-active": {
                            boxShadow: "none",
                        },
                    },
                }}
                marks={valueText}
                getAriaValueText={TextValue}
                min={0}
                max={3}
                value={zoom}
                onChange={(e) => setZoom(e.target.value)}
                aria-labelledby="continuous-slider"
            />
            <Plus role='button' size={24} className=' mt-1 text-muted' onClick={() => { setZoom(zoom + 1) }} />
            {/* <RotateCcw role='button' size={30} className='' onClick={handleRotate} /> */}
            <RotateCw role='button' size={21} className='mx-2 mt-1 text-muted' onClick={handleRotate} />
            {/* <span className='bg-primary ml-3 text-white p-1 px-3 rounded-3 cursor-p' onClick={() => { getCropData() }}> <ArrowRight size={18} />&nbsp; Next </span> */}
        </div>
    )
}

export default StoryCropTool
