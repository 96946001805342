import React from 'react'
import { Fragment } from 'react'
import { XCircle } from 'react-feather'
import { Modal } from 'reactstrap'
import ModalPro from '../../Img/modalPro.png'

const ProfilePictureModal = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered TopBanner_profile_Picture ' size="sm">
                    <div>
                        <img src={props.updateProfile} alt="modal_profile" className='img-fluid' />
                    </div>
                    <XCircle size={20} className="Feather_Icon_Profile" color='#FFFFFF' onClick={props.updateOpen} />
                </Modal>
            }
        </Fragment>
    )
}

export default ProfilePictureModal