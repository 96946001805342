import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Card, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import NeteuLogo from '../../../NewComponents/IMG/netsPaymentLogo.png'
import StripeLogo from '../../../NewComponents/IMG/StripeLogo.svg'
import WalletIcon from '../../../NewComponents/IMG/WalletIcon.svg'
import AdCountIcon from '../../../NewComponents/IMG/AdCountIcon.svg'
import TrxIcon from '../../../NewComponents/IMG/TrxIcon.svg'
import e from '../../../NewComponents/IMG/e.png'
import f from '../../../NewComponents/IMG/f.png'
import { Check, CheckCircle, X } from 'react-feather'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import QR from '../../../NewComponents/IMG/QR.svg'
import Invoice from '../Sub-components/Invoice'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PaymentFaild from './PaymentSuccess'
import $ from 'jquery'
const AdanalyticsCard = (props, isDisplay) => {
    //========================Stats and functions====================
    const [PaymentModals, setPaymentModals] = useState(false)
    const [PaymentModalsCard, setPaymentModalsCard] = useState(false)
    const [PaymentModalsupi, setPaymentModalsupi] = useState(false)
    const [PaymentConfirmationModal, setPaymentConfirmationModal] = useState(false)
    const [PaymentView, setPaymentView] = useState(false)
    const [PaymentFailedModal, setPaymentFailedModal] = useState(false)
    const toggleModal = () => {
        setPaymentModals((prevState) => !prevState)
    }
    const toogleChnagePayment = () => {
        setPaymentModalsCard(false)
        setPaymentModalsupi(false)
        setPaymentModals(true)
    }
    const toggleModalCard = () => {
        setPaymentModals((prevState) => !prevState)
        setPaymentModalsCard((prevState) => !prevState)
    }
    const toggleModalUpi = () => {
        setPaymentModals((prevState) => !prevState)
        setPaymentModalsupi((prevState) => !prevState)
    }
    const togglePaymentConfirmation = () => {
        setPaymentModalsupi(false)
        setPaymentModalsCard(false)
        setPaymentConfirmationModal((prevState) => !prevState)
    }
    const togglePaymentFailed = () => {
        setPaymentModalsupi(false)
        setPaymentModalsCard(false)
        setPaymentFailedModal(!PaymentFailedModal);
    }
    const togglePaymentView = () => {
        setPaymentModalsupi(false)
        setPaymentModalsCard(false)
        setPaymentConfirmationModal(false)
        setPaymentView((prevState) => !prevState)
    }
    // =================Not-Seleted-Alert====================
    const PaymentAlert = () => {
        alert("Please select any one payment option")
    }
    //=======================Suggested Amount==================
    const SuggestAmount = [
        {
            sgtAmt: '100'
        },
        {
            sgtAmt: '200'
        },
        {
            sgtAmt: '500'
        },
        {
            sgtAmt: '1000'
        },
        {
            sgtAmt: '2000'
        }
    ]
    // ===================Payment Modal================
    const PaymentModal = () => {
        const [selectedfirst, setSelectedfirst] = useState(false)
        const [selectedSecond, setSelectedSecond] = useState(false)
        const [selectedThird, setSelectedThird] = useState(false)
        const [selectedfourth, setSelectedfourth] = useState(false)
        const Nets = () => {
            setSelectedfirst((prevState) => !prevState)
            setSelectedSecond(false)
            setSelectedThird(false)
            setSelectedfourth(false)
        }
        const Gpay = () => {
            setSelectedfirst(false)
            setSelectedThird(false)
            setSelectedfourth(false)
            setSelectedSecond((prevState) => !prevState)
        }
        const Sripe = () => {
            setSelectedfirst(false)
            setSelectedSecond(false)
            setSelectedfourth(false)
            setSelectedThird((prevState) => !prevState)

        }
        const Apay = () => {
            setSelectedfirst(false)
            setSelectedSecond(false)
            setSelectedThird(false)
            setSelectedfourth((prevState) => !prevState)
        }
        // const [NumberClicked, setNumberClicked] = useState("")
        const [NumberClicked, setNumberClicked] = useState("");
        const handleChange = (event) => {
            setNumberClicked(event.target.value);
        };
        return (
            <>
                {PaymentModals ? (
                    <Modal isOpen={PaymentModals} className='modal-lg modal-dialog-centered' toggle={toggleModal} >
                        <ModalHeader toggle={toggleModal} style={{ background: '#f6f6f6', border: 'none' }}>
                            <div>
                                <div className="modal-header-title" >
                                    <h2 className="modalForPayHead">Add Funds</h2>
                                    <p className="text-muted">These funds will be used to allot the budget while creating Ads</p>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <>
                                <div>
                                    <div className="amount">
                                        <Row className='rowForrevert'>
                                            <Col xl='12'>
                                                <FormGroup>
                                                    <Label style={{ fontSize: '14px' }}>
                                                        Choose an amount to add
                                                    </Label>
                                                    {/* <img src={AmountLogo} className='Amounticon' alt='Amounticon' /> */}
                                                    <div style={{ position: 'relative', marginLeft: '-13px' }}>
                                                        <span className='Amounticon p-0' > $ </span>
                                                        {/* <input
                                                        type="number"
                                                        style={{ paddingLeft: '30px' }}
                                                        placeholder="Enter amount"
                                                        value={NumberClicked}
                                                        onChange={handleChange}
                                                    /> */}
                                                        {/* <Input
                                                        type="number"
                                                        style={{ paddingLeft: '30px' }}
                                                        placeholder="Enter amount"
                                                        value={NumberClicked}
                                                        onChange={handleChange}
                                                    /> */}
                                                        {/* <div>
                                                        <Input
                                                            style={{ paddingLeft: '30px' }}
                                                            placeholder="Enter amount"
                                                            value={NumberClicked}
                                                            onChange={handleChange}
                                                            maxLength='60'
                                                        />
                                                    </div> */}
                                                        {/* <input type="number"
                                                        placeholder="Enter amount"
                                                        style={{ paddingLeft: '30px' }} name="" id="" value={NumberClicked}
                                                        onChange={handleChange} className='form-control' maxLength='60' /> */}
                                                        <input
                                                            type="number"
                                                            name=""
                                                            id=""
                                                            placeholder="Enter amount"
                                                            value={NumberClicked}
                                                            onChange={handleChange}
                                                            maxLength="60"
                                                            style={{
                                                                position: "absolute",
                                                                top: "-5px",
                                                                left: "28px",
                                                                width: "93%",
                                                                padding: "5px",
                                                                border: "1px solid #ccc",
                                                                borderRadius: "5px",
                                                                fontSize: "16px",
                                                                // boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className='d-flex justify-content-end align-items-center mt-3'>
                                            <p className=''>Commonly added amounts</p>
                                            <div>
                                                {
                                                    SuggestAmount.map((Amt, index) => {
                                                        return (
                                                            <b style={{ cursor: 'pointer' }} onClick={() => setNumberClicked(Amt.sgtAmt)} className='m-1'><u>
                                                                +{Amt.sgtAmt}
                                                            </u></b>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {/* <input type="text" className="form-control margin-add-fud" placeholder="Enter amount" /> */}
                                    </div>
                                </div>
                                <div className="amount">
                                    <Label style={{ fontSize: '14px' }}>Choose payment method</Label>
                                    <div className='d-flex justify-content-center text-center payForIcon'>
                                        <div className='w-100'>
                                            <div onClick={Nets} className={`onMouseHovePayment ${selectedfirst ? 'm-2 border border border-primary rounded' : 'm-2 border rounded'}`}>
                                                <img src={NeteuLogo} alt="nets" />{selectedfirst ? <CheckCircle color='#81C14B' /> : ''}
                                            </div>
                                            <div onClick={Gpay} className={`onMouseHovePayment ${selectedSecond ? 'm-2 border border border-primary rounded onMouseHovePayment' : 'm-2 border rounded'}`}><img src="https://cdn-icons-png.flaticon.com/512/6124/6124998.png" alt="Gpay" />{selectedSecond ? <CheckCircle color='#81C14B' /> : ''}</div>
                                        </div>
                                        <div className='w-100 text-center'>
                                            <div onClick={Sripe} className={`onMouseHovePayment ${selectedThird ? 'm-2 border border border-primary rounded onMouseHovePayment' : 'm-2 border rounded'}`}><img src={StripeLogo} alt="stripe" />{selectedThird ? <CheckCircle color='#81C14B' /> : ''}</div>
                                            <div onClick={Apay} className={`onMouseHovePayment ${selectedfourth ? 'm-2 border border border-primary rounded onMouseHovePayment' : 'm-2 border rounded'}`}><img src="https://cdn-icons-png.flaticon.com/512/5977/5977576.png" alt="ApplePay" />{selectedfourth ? <CheckCircle color='#81C14B' /> : ''}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer add-footer" style={{ border: 'none' }}>
                                    <Button disabled={!((selectedfirst || selectedThird) || (selectedSecond || selectedfourth))} onClick={(selectedfirst || selectedThird) ? toggleModalCard : (selectedSecond || selectedfourth) ? toggleModalUpi : PaymentAlert} color='primary' className='w-100' style={{ backgroundColor: (selectedfirst || selectedThird) ? '#81c14b' : (selectedSecond || selectedfourth) ? '#81c14b' : 'rgba(172, 218, 214, 1)', border: 'none', color: 'white' }}
                                    >
                                        Make Payment
                                    </Button>
                                </div>
                            </>
                        </ModalBody>
                    </Modal >
                ) : (null)}
            </>
        )
    }
    //=========================Card Through Modal=======================
    const PaymentModalforCard = ({ setIsConfirmation, isConfirmation }) => {
        // =====================add validation ===============
        const [value, setValue] = useState("");
        const [isButtonDisabled, setButtonDisabled] = useState(true);
        const [inputFieldValue, setInputFieldValue] = useState('');
        const [isValid, setIsValid] = useState(true);
        const handleInputChange = (event) => {
            const input = event.target.value.replace(/\D/g, "");
            setInputFieldValue(value);
            setButtonDisabled(value === '');
            if (input.length > 0) {
                const formattedInput = input.match(/.{1,4}/g).join(" - ");
                setValue(formattedInput);
            } else {
                setValue("");
            }
        };
        // const [selectedDate, setSelectedDate] = useState('');
        const handleChange = (e) => {
            setSelectedDate(e.target.value);
        }
        const [cvv, setCVV] = useState('');
        // Handle input changes validation
        const handleCVVChange = (event) => {
            const inputCVV = event.target.value;
            const sanitizedCVV = inputCVV.replace(/[^0-9]/g, '');
            if (sanitizedCVV.length <= 3) {
                setCVV(sanitizedCVV);
            }
        };
        const [clickedOnce, setClickedOnce] = useState(false);
        const handleButtonClick = () => {
            let isConf = true;
            if (isConfirmation) {
                isConf = false;
                togglePaymentConfirmation();
            } else {
                isConf = true;
                togglePaymentFailed();
            }
            setIsConfirmation(!isConfirmation);
            console.log(isConfirmation)
        };
        const [selectedDate, setSelectedDate] = useState(null);
        // =====================payment modal card======================
        return (
            <>
                {PaymentModalsCard ? (
                    <Modal isOpen={PaymentModalsCard} className='modal-lg modal-dialog-centered' toggle={toggleModalCard} >
                        <div className='PaymentmodalForStyle rounded'>
                            <h3 className="modalForPayHead">Your Payment platform</h3>
                            <h5 onClick={toogleChnagePayment} className="modalForPayHead">Change Payment Method</h5>
                        </div>
                        <ModalBody>
                            <>
                                <div>
                                    <h3>Enter your Card Details</h3>
                                    <div className='my-2' >
                                        <div className="amount">
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label style={{ fontSize: '14px' }}>
                                                            Credit/Debite/ATM Card
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="xxxx - xxxx - xxxx - xxxx"
                                                            value={value}
                                                            onChange={handleInputChange}
                                                            maxLength='25'

                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl='6'>
                                                    <FormGroup>
                                                        <Label>
                                                            Exp. Date
                                                        </Label>
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={selectedDate}
                                                            onChange={date => setSelectedDate(date)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            placeholderText="mm/yyyy"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xl='6'>
                                                    <FormGroup>
                                                        <Label>
                                                            CVV
                                                        </Label>
                                                        <Input
                                                            type="password"
                                                            id="cvv"
                                                            name="cvv"
                                                            placeholder=" - - - "
                                                            value={cvv}
                                                            onChange={handleCVVChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className='pe-none d-flex justify-content-end align-items-center mr-4'>
                                            </div>
                                            {/* <input type="text" className="form-control margin-add-fud" placeholder="Enter amount" /> */}
                                        </div>
                                    </div>
                                    <h5>This payment process is secured by PCI-DSS</h5>
                                </div>
                                <div className='mt-4'>
                                    {/*   onClick={handleButtonClick} */}
                                    <Button
                                        onClick={handleButtonClick}
                                        color="primary"
                                        className="w-100"
                                        style={{
                                            backgroundColor: isButtonDisabled ? 'rgba(172, 218, 214, 1)' : '#81c14b', border: 'none', color: 'white'
                                        }}
                                        disabled={isButtonDisabled}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    // ==================== UPI Through Modal==================
    const PaymentModalforUpi = () => {
        const [value, setValue] = useState('UPI');

        const handleChange = (event) => {
            setValue(event.target.value);
        };
        const handleButtonClick = () => {
            let isConf = true;
            if (isConfirmation) {
                isConf = false;
                togglePaymentConfirmation();

            } else {
                isConf = true;
                togglePaymentFailed();
            }
            setIsConfirmation(!isConfirmation);
            console.log(isConfirmation)
        };
        return (
            <>
                {PaymentModalsupi ? (
                    <Modal isOpen={PaymentModalsupi} className='modal-lg modal-dialog-centered' toggle={toggleModalUpi} >
                        <ModalBody>
                            <>
                                <div>
                                    <FormControl>
                                        <FormLabel id="demo-row-controlled-radio-buttons-group">Select your prefered option </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={value}
                                            className='d-flex'
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="UPI" control={<Radio />} label="UPI" />

                                            <FormControlLabel value="QR" control={<Radio />} label="QR" />
                                        </RadioGroup>
                                    </FormControl>
                                    {value === 'UPI' ?
                                        <div>
                                            <InputGroup className='mb-2'>
                                                <Input placeholder="Enter UPI ID" />
                                                <InputGroupAddon addonType='append'>
                                                    <InputGroupText>UPI ID</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <Label className='ml-1'>1. 234567890@upi</Label>
                                        </div> :
                                        <div className='text-center'>
                                            <img className='w-100' height={200} src={QR} alt='' />
                                            <p>Scan QR code and pay</p>
                                        </div>
                                    }
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={handleButtonClick} color='primary' className='w-100' >Continue</Button>
                                </div>
                            </>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    // ===================Invoice Modal ====================
    const ModalForInvoice = () => {
        return (
            <>
                {PaymentView ? (
                    <Modal isOpen={PaymentView} className='modal-lg modal-dialog-centered' toggle={togglePaymentView} >
                        <ModalBody>
                            <Invoice />
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    //=======================Payment Confirmation Modal===============
    const PaymentConfirmation = () => {
        return (
            <>
                {PaymentConfirmationModal ? (
                    <Modal isOpen={PaymentConfirmationModal} className='modal-dialog-centered' toggle={togglePaymentConfirmation} >
                        <ModalBody>
                            <div>
                                <div className='text-center border-bottom'>
                                    <div style={{ position: 'relative' }}>
                                        <img src={e} width={80} height={80} alt='' />
                                        <div style={{ position: 'absolute', left: '45%', top: '20%', color: '#81c14b' }}>
                                            <Check size={50} />
                                        </div>
                                    </div>
                                    <h4 className='text-primary mt-2'>Payment Confirmation</h4>
                                    <h4 className='mt-2'>Thanks for the Payment</h4>
                                    <p className='my-2'>Your Payment has been received. You can download the Invoice from below.</p>
                                </div>
                                <div>
                                    <h4 onClick={togglePaymentView} className='text-primary text-center mt-2' style={{ cursor: 'pointer' }} ><u >View Invoice</u></h4>
                                    <Row className='ConfirmatioForstyle'>
                                        {/* <Col xl='6'> */}
                                        <div className='d-flex justify-content-between'>
                                            <div><h4>Summary</h4>
                                                <h5>Transactions ID</h5>
                                                <h5>Date & Time </h5>
                                                <h5>Payment Method </h5>
                                                <h5>Added Amount </h5>
                                                <h4>Total Amount in wallet</h4>
                                            </div>
                                            <div className='mt-4'>
                                                <h5>#6541651651</h5>
                                                <h5>12 Feb 2021,04:48PM</h5>
                                                <h5>Net Banking </h5>
                                                <h5>$2350 </h5>
                                                <h4>$2550</h4></div></div>
                                        {/* </Col> */}
                                        {/* <Col xl='6' className='text-right mt-4'>
                                        </Col> */}
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={() => setPaymentConfirmationModal((prevState) => !prevState)} color='primary' className='w-100' >Go Back</Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    // const handleBackdropClick = (event) => {
    //     if ($(event.target).hasClass('modal')) {
    //         togglePaymentConfirmation();
    //     }
    // };
    // ==============PaymentFailed close modal================
    const handleBackdropClick = (event) => {
        if (event.target.className === 'modal') {
            togglePaymentFailed();
        }
    };
    // ==============PaymentFailed================
    const PaymentFailed = () => {
        return (
            <>
                {PaymentFailedModal ? (
                    <Modal isOpen={PaymentFailedModal} className='modal-dialog-centered' toggle={togglePaymentFailed} onClick={handleBackdropClick}>
                        <ModalBody>
                            <div>
                                <div className='text-center border-bottom'>
                                    <div style={{ position: 'relative' }}>
                                        <img src={f} width={80} height={80} alt='' />
                                        <div style={{ position: 'absolute', left: '43.8%', top: '14%', color: '#81c14b' }}>
                                            <X size={60} style={{ color: '#FF0000' }} />
                                        </div>
                                    </div>
                                    <h4 className='mt-2' style={{ color: '#FF0000' }}>Payment Failed</h4>
                                    <h4 className='mt-2'>Payment not success</h4>
                                    <p className='my-2'>Your Payment has been not received.</p>
                                </div>
                                <div>
                                    <h4 onClick={togglePaymentView} className='text-center mt-2' style={{ color: '#FF0000', cursor: 'pointer' }}><u>Failed Invoice</u></h4>
                                    <Row className='ConfirmatioForstyle'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <h4>Failed Summary</h4>
                                                <h5>Transactions ID</h5>
                                                <h5>Date & Time </h5>
                                                <h5>Payment Method </h5>
                                                <h5>Failed Amount</h5>
                                                <h4>Total Amount in wallet</h4>
                                            </div>
                                            <div className='mt-4'>
                                                <h5>#6541651651</h5>
                                                <h5>12 Feb 2021, 04:48 PM </h5>
                                                <h5>Net Banking </h5>
                                                <h5>$2350 </h5>
                                                <h4>$2550</h4>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={togglePaymentFailed} color='primary' className='w-100' >Go Back</Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    // ==============PaymentFailed end================
    const [isConfirmation, setIsConfirmation] = useState(true);
    const [hideLink, setHideLink] = useState(false);

    useEffect(() => {
        const currentURL = window.location.href;
        if (currentURL.includes('/Dashboard/TransactionSummary')) {
            setHideLink(true);
        }
    }, []);
    return (
        <div className='my-2' >
            <Row style={{ marginRight: '4px' }}>
                {/* First Card */}
                <Col sm="12" md="4" xl="4" className="mb-3 mb-md-0" style={{ marginTop: '10px' }}>
                    <Card style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="p-3 h-100 text-wrap">
                        <div className="d-flex align-items-center border-bottom pb-3 fontForSubCardheading">
                            <span className="mr-2">
                                <img src={WalletIcon} alt="Wallet" />
                            </span>
                            <h3>Wallet</h3>
                        </div>
                        <div className="d-flex justify-content-between py-3 font-weight-bold fontForSubCardheading">
                            <h4>Available Funds</h4>
                            <h4>$ 25,375.00</h4>
                        </div>
                        <div className="d-flex justify-content-between align-items-start w-100">
                            <div className="fontForSubchildCardheading">
                                <h5 className="mb-1" style={{ fontStyle: 'italic' }}>Freeze Amount</h5>
                                <h6 className="text-break mr-sm-2" style={{ fontStyle: 'italic' }}>Total Freeze Amount calculated on All Ads</h6>
                            </div>
                            <div className="text-nowrap fontForSubchildCardheading">
                                <h5>$ 5,375.00</h5>
                            </div>
                        </div>
                        <div className="text-right mt-4">
                            <Button onClick={toggleModal} className="py-1 px-3" color="primary">Add Funds</Button>
                        </div>
                        {/* Modals as per above mentions */}
                        <PaymentModal />
                        <PaymentModalforCard setIsConfirmation={setIsConfirmation} isConfirmation={isConfirmation} />
                        <PaymentModalforUpi />
                        <PaymentConfirmation />
                        <PaymentFailed />
                        <ModalForInvoice />
                    </Card>
                </Col>
                {/* Second Card */}
                <Col sm="12" md="4" xl="4" className="mb-3 mb-md-0" style={{ marginTop: '10px' }}>
                    <Card style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="p-3 h-100 text-wrap">
                        <div className="d-flex align-items-center border-bottom pb-3 fontForSubCardheading">
                            <span className="mr-2">
                                <img src={AdCountIcon} alt="Ad-Count" />
                            </span>
                            <h3>Ad Count</h3>
                        </div>
                        <div className="d-flex justify-content-between m-3 fontForSubCardheading">
                            <h4>Total Ad Count</h4>
                            <h4>53</h4>
                        </div>
                        <div className="cus-flex mb-2 rounded bg-light pt-2 p-2">
                            <div className="Freeze-in col-lg-6 border-right text-wrap">
                                <div className="count cus-flex mb-2 fontForSubchildCardheading text-wrap">
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.toggle('1')}
                                    >
                                        Running
                                    </h5>
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary"
                                        onClick={() => props.toggle('1')}
                                    >
                                        27
                                    </h5>
                                </div>
                                <div className="count cus-flex mb-2 fontForSubchildCardheading text-wrap">
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.toggle('3')}
                                    >
                                        Drafted
                                    </h5>
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary"
                                        onClick={() => props.toggle('3')}
                                    >
                                        08
                                    </h5>
                                </div>
                                <div className="count cus-flex mb-2 fontForSubchildCardheading text-wrap">
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.toggle('4')}
                                    >
                                        Approval Pending
                                    </h5>
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary"
                                        onClick={() => props.toggle('4')}
                                    >
                                        13
                                    </h5>
                                </div>
                            </div>
                            <div className="Freeze-in col-lg-6 align-self-start">
                                <div className="count cus-flex mb-2 fontForSubchildCardheading text-wrap">
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.toggle('2')}
                                    >
                                        Paused
                                    </h5>
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary"
                                        onClick={() => props.toggle('2')}
                                    >
                                        05
                                    </h5>
                                </div>
                                <div className="count cus-flex fontForSubchildCardheading text-wrap">
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.toggle('5')}
                                    >
                                        Completed
                                    </h5>
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary"
                                        onClick={() => props.toggle('5')}
                                    >
                                        46
                                    </h5>
                                </div>
                                <div className="count cus-flex mb-2 fontForSubchildCardheading text-wrap mt-2">
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => props.toggle('2')}
                                    >
                                        Schedule
                                    </h5>
                                    <h5
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary"
                                        onClick={() => props.toggle('2')}
                                    >
                                        25
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                {/*===================Third Card with Props for page navigation=============== */}
                <Col sm="12" md="4" xl="4" className="mb-3 mb-md-0" style={{ marginTop: '10px' }}>
                    <Card style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} className="p-3 h-100 text-wrap">
                        <div className="d-flex align-items-center border-bottom pb-3 fontForSubCardheading">
                            <span className="mr-2">
                                <img src={TrxIcon} alt="transactionIcon" />
                            </span>
                            <h3>Transaction Summary</h3>
                        </div>
                        <div className="d-flex justify-content-between py-3 fontForSubCardheading">
                            <h4>Total Spent</h4>
                            <h4>$ 5000</h4>
                        </div>
                        <div className="d-flex justify-content-between align-items-start fontForSubchildCardheading ml-1">
                            <div className="fontForSubchildCardheading">
                                <h5 className="mb-1">Last Transaction</h5>
                                {/* <h6>
                                    {hideLink ? null : (
                                        <a href='/Dashboard/TransactionSummary' style={{ color: 'blue', cursor: 'pointer' }}>
                                            Show last 5 transactions
                                        </a>
                                        
                                    )}
                                </h6> */}
                            </div>
                            <h5>$ 2000</h5>
                        </div>
                        <div className="d-flex justify-content-between align-items-start fontForSubchildCardheading mt-1 ml-1">
                            <div className="fontForSubchildCardheading">
                                <h5 className="mb-1">Ad ID</h5>
                            </div>
                            <h5>#63416566</h5>
                        </div>
                        <div className="d-flex justify-content-between align-items-start fontForSubchildCardheading mt-1 ml-1">
                            <div className="fontForSubchildCardheading">
                                <h5 className="mb-1">Date & Time</h5>
                            </div>
                            <h5>05 DEC 2020, 04:36 pm</h5>
                        </div>
                        <div className="text-right mt-4">
                            <Button className="py-1 ButtondisableForbutton" disabled={props.condition} color="primary" >
                                <NavLink style={{ color: '#ffffff' }} to={props.currentbutton}>
                                    {props.buttonrole}
                                </NavLink>
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AdanalyticsCard