
import React, { Fragment, useState } from 'react'
import UserImg from '../../NewComponents/IMG/img.svg'
import CommentActive from '../../NewComponents/IMG/CommentActive.svg'
import ActiveCirclelike from '../../NewComponents/IMG/ActiveCirclelike.svg'
import LikeImg from '../../NewComponents/IMG/LikeImg.svg'
import CmntImg from '../../NewComponents/IMG/CmntImg.svg'
import ShrImg from '../../NewComponents/IMG/ShrImg.svg'
import { Card, CardBody, CardFooter, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledButtonDropdown } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AlertTriangle, BellOff, Bookmark, ChevronLeft, ChevronRight, Circle, Copy, EyeOff, MoreHorizontal, Send, Share2, Smile, UserMinus } from 'react-feather'
import { emoteData } from '../Social-Feed-Component/FeedArray'
import NewBookMark from '../Bookmark-Components/NewBookMark'
import {
    SingleMediaPost,
    SingleMediaNoDespPost,
    DoubleMediaNoDespPost,
    TextPostDisplay,
    TextTagPostDisplay,
    MediaPostDisplay,
    MediaPostVideoType,
    SingleVideoType,
    AlertPostDisplayModerate,
    RecommendationPostDisplay,
    RecmndNoImgPostDisplay,
    AlertPostDisplayMild,
    AlertPostDisplaySevere,
    PollPostDisplayUnfilled,
    PollPostDisplayfilled,
    EventPostDisplay,
    SingleMediaCaptionPost,
    ThroughtPostDisplay,
    SngleUploadPcPost,
    EpisodePodcast,
    PaidSnglePcPost,
    PaidSeriesPcPost,
    FreeSeriesPcPost,
    DfltUploadPcPost,
    PaidPodcastPost,
    MarketPlacePost,
    MarketPlaceSngleAction,
    ReSharePostType
} from './AllPostDisplay/PostDisplayPrev'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import ShareModal from './Modals/ShareModal'


const NewMasonryGrid = () => {
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [VideoOpen, setVideoClose] = useState(false)
    const toggleVideo = () => {
        setVideoClose(!VideoOpen)
    }

    //================================================================= State and function for Like Comment and Share ===================================================================//
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [BookmarkIcon, SetBookmark] = useState(false)
    const [comment, SetComment] = useState(false)

    const Commenttoggle = () => {
        SetComment(!comment)
    }
    const [commentR, SetCommentR] = useState('')
    const CommenttoggleR = () => {
        SetCommentR('I am seeking recommendation for buying ')
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const [seemore, setSeeMore] = useState(false);

    const EmoteAvatar = () => {

        return (
            <div className='px-2'>
                <ul className="matual-friend-blk">
                    {
                        emoteData.slice(0, 4).map((item, index) => {
                            return (

                                <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="sufiya eliza" data-img={item.img}>
                                    <img src={item.img} className="bg-img" alt="" />
                                </li>

                            )
                        })
                    }
                </ul>
            </div >
        )
    }

    //================================================================= All post type data (Same as social feed) ===================================================================//
    const AllPostTypeData = [
        // {
        //     id: <SingleMediaPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <SingleMediaCaptionPost />
        // },
        // {
        //     id: <SingleMediaNoDespPost />
        // },
        // {
        //     id: <DoubleMediaNoDespPost />
        // },
        // {
        //     id: <MediaPostDisplay />
        // },
        // {
        //     id: <MediaPostVideoType toggleVideo={toggleVideo} />
        // },
        // {
        //     id: <SingleVideoType />
        // },
        // {
        //     id: < TextPostDisplay />
        // },
        // {
        //     id: <TextTagPostDisplay />,
        //     type: "Mention"
        // },
        // {
        //     id: <AlertPostDisplayModerate />
        // },
        // {
        //     id: <AlertPostDisplayMild />
        // },
        // {
        //     id: <AlertPostDisplaySevere />
        // },
        // {
        //     id: <RecommendationPostDisplay action={CommenttoggleR} />,
        //     type: "Mention",
        //     TypeRcmnd: "Rcmnd"
        // },
        // {
        //     id: <RecmndNoImgPostDisplay action={CommenttoggleR} />,
        //     TypeRcmnd: "Rcmnd"
        // },
        // {
        //     id: <ThroughtPostDisplay />
        // },
        // {
        //     id: <PollPostDisplayUnfilled />,
        //     typePoll: "PollNtCmnt"
        // },
        // {
        //     id: <PollPostDisplayfilled />,
        //     typePoll: "PollNtCmnt",
        //     type: "Mention"
        // },
        // {
        //     id: <EventPostDisplay />
        // },
        // {
        //     id: <PaidPodcastPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <PaidSnglePcPost />
        // },
        // {
        //     id: <PaidSeriesPcPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <FreeSeriesPcPost />
        // },
        // {
        //     id: <EpisodePodcast />
        // },
        // {
        //     id: <SngleUploadPcPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <DfltUploadPcPost />
        // },
        // {
        //     id: <MarketPlacePost />,
        //     type: "Mention"
        // },
        // {
        //     id: <MarketPlaceSngleAction />
        // },
        // {
        //     id: <ReSharePostType />
        // }
    ]

    //=========================================================================== Main return function =============================================================================//

    return (
        <Fragment>
            <div>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 667: 2, 1157: 3 }}
                >
                    <Masonry columnsCount={3} gutter="12px">

                        {AllPostTypeData.map((postType) => (
                            <div className=''>
                                <Card className='Post_Display_Crad '>

                                    <div className='bg-white border-bottom-0 w-100  on-hover-bg'>
                                        <div className='d-flex justify-content-between align-items-start p-2 on-hover-bg'>
                                            <div to={`/MyProfile?userId=${user.id}&type=creater`} className='d-flex align-items-start'>
                                                <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                                                <div className='ml-2 PostPreView_User_Info'>
                                                    {postType.type === "Mention" ?
                                                        <div>
                                                            <h2 className='text-wrap'><span className='UserProfile-Name' ><Link to={`/MyProfile?userId=${user.id}&type=creater`} style={{ color: '#4D4D4D' }} >Sufiya Eliza </Link> </span> &nbsp;
                                                                <span style={{ color: "rgba(77, 77, 77, 0.5)", fontSize: "14px" }}>is feeling happy with</span >
                                                                &nbsp;<span style={{ fontSize: "14px" }}>Matt</span>&nbsp;
                                                                <span style={{
                                                                    color: "rgba(77, 77, 77, 0.5)",
                                                                    fontSize: "14px"
                                                                }}></span >
                                                                &nbsp;
                                                                <span style={{ fontSize: "14px" }}>19 others</span>
                                                                &nbsp;<span style={{
                                                                    color: "rgba(77, 77, 77, 0.5)",
                                                                    fontSize: "14px"
                                                                }}>in</span>
                                                                &nbsp;
                                                                <span className='Profile_Location' style={{ fontWeight: "400" }}>
                                                                    354B,
                                                                    {!seemore ? null : " Ali Ganj, Lucknow, India - 226012..."}
                                                                    <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? <ChevronLeft size={18} /> : <ChevronRight size={18} />}</span>
                                                                </span>
                                                            </h2>
                                                        </div> :
                                                        <h2 className='text-wrap'><span className='UserProfile-Name' >Jason Bourne</span>
                                                            &nbsp;{postType.TypeRcmnd ? <span
                                                                style={{
                                                                    color: "rgba(77, 77, 77, 0.5)",
                                                                    fontWeight: "600",
                                                                    fontSize: "14px"
                                                                }}>asking</span> : null}
                                                        </h2>}
                                                    <h4> {postType.TypeRcmnd ?
                                                        <span style={{ color: "#FF5C00", fontWeight: "600", fontSize: "14px" }}>
                                                            Recommendation &nbsp;<Circle fill='#C4C4C4' color='#C4C4C4' size={6} /> &nbsp;</span> : null}
                                                        20 Min ago</h4>
                                                </div>
                                            </div>

                                            <div className='d-flex'>
                                                <div className='rem-mob-view' onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '8px', backgroundColor: 'rgba(126, 178, 98, 0.1)', borderRadius: '5px', cursor: 'pointer' }}   >
                                                    {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                                                </div>

                                                {/* <UncontrolledButtonDropdown>
                                                    <DropdownToggle style={{ padding: '0 0 0 10px ' }} color='flat-secondary' >
                                                        <MoreHorizontal size={19} style={{ color: '#999999' }} />
                                                    </DropdownToggle>
                                                    <DropdownMenu style={{ zIndex: '2', left: '-255px', minWidth: 'fit-content', color: '#A6A6A6' }}>
                                                        <DropdownItem style={{ color: '#808080' }} ><EyeOff size={15} color='#A6A6A6' />  &nbsp;Hide post from your timeline</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><BellOff size={15} color='#A6A6A6' /> &nbsp;Turn off this post notification</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><Share2 size={15} color='#A6A6A6' /> &nbsp;Share via</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><Copy size={15} color='#A6A6A6' /> &nbsp;Copy link</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><UserMinus size={15} color='#A6A6A6' /> &nbsp;Unfollow “$username”</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><AlertTriangle size={15} color='#A6A6A6' /> &nbsp;Report this Post</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown> */}
                                            </div>
                                        </div>
                                        {/* ================================text======================================== */}
                                        {/* <div className='SingleMedia_blk' >
                                            <img src='https://images.pexels.com/photos/416024/pexels-photo-416024.jpeg?auto=compress&cs=tinysrgb&w=1600' alt='singleMedia' className='img-fluid' />
                                        </div> */}
                                        {/* <div style={{ padding: "20px" }}>
                                            <div className='SingleMedia_Content_blk'>
                                                <h3>
                                                    Denmark is a Scandinavian country comprising the Jutland Peninsula and numerous islands. It's linked to nearby Sweden via the Öresund bridge. Copenhagen,
                                                    {!seemore ? null : "its capital, is home to royal palaces and colorful Nyhavn harbor, plus the Tivoli amusement park and penhagen, It's linked to nearby Sweden via the Öresund bridge. Copenhagen, its capital, is home to royal palaces and colorful Nyhavn harbor, plus the Tivoli amusement park and penhagen its capital"}
                                                    <span style={{ cursor: 'pointer' }} className='text-primary text-nowrap' onClick={() => setSeeMore(!seemore)} >{seemore ? '...Read less' : '... Read more'}</span>
                                                </h3>
                                            </div>
                                        </div> */}
                                        {/*======================================Like comment============================= */}
                                        {/* <div className='border-0 w-100 rem-mob-view'>
                                            <div className='d-flex justify-content-between p-2'>
                                                <div className='d-flex align-items-center w-75' >

                                                    {
                                                        LikeIcon ? <div>
                                                            <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' />
                                                        </div> : <div>
                                                            <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={LikeImg} alt='shareIcon' />
                                                        </div>
                                                    }
                                                    <h5 style={{ color: '#808080' }} className='mx-2'>24</h5>
                                                    <EmoteAvatar />
                                                </div>
                                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} >

                                                    {postType.typePoll === "PollNtCmnt" ? null :
                                                        <>

                                                            < div className='d-flex align-items-center' onClick={Commenttoggle}>
                                                                {
                                                                    comment ? <div className='text-center'>
                                                                        <img width={35} src={CommentActive} alt='shareIcon' />
                                                                    </div> : <div className='text-center'>
                                                                        <img width={35} src={CmntImg} alt='shareIcon' />
                                                                    </div>
                                                                }
                                                                <h5 style={{ color: '#808080' }} className='px-3'>24</h5>
                                                            </div>
                                                        </>
                                                    }

                                                    <div className='d-flex align-items-center'>
                                                        <div className='text-center' onClick={toggleForward} >
                                                            <img width={35} src={ShrImg} alt='shareIcon' />
                                                        </div>
                                                        <h5 style={{ color: '#808080' }} className='px-3'>24</h5>
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                comment ? postType.typePoll === "PollNtCmnt" ? null :
                                                    <div className='p-3'>

                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                <h4>Robert Fox</h4>
                                                            </div>
                                                            <h5 className='text-muted pr-1'>40 Mins ago</h5>
                                                        </div>
                                                        <div className='p-2 pl-5'>
                                                            <div className='SingleMedia_Content_blk'>
                                                                <h3>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do
                                                                    in your chosen destination.</h3>
                                                            </div>
                                                            <div className='text-primary'>
                                                                Like (5)
                                                                &nbsp;
                                                                Reply (6)
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center my-1'>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                    <h4>Aliza Alveen</h4>
                                                                </div>
                                                                <h5 className='text-muted'>40 Mins ago</h5>
                                                            </div>
                                                            <div className='SingleMedia_Content_blk'>
                                                                <h3> When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</h3>
                                                                <div className='text-primary'>
                                                                    Like (5)
                                                                    &nbsp;
                                                                    Reply (6)
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> : null
                                            }
                                            {
                                                postType.typePoll === "PollNtCmnt" ? null :
                                                    <div className='m-2 position-relative'>
                                                        <Input type='text' style={{ background: '#F9FBF7', paddingRight: '75px' }} Placeholder='Write a comment...' />
                                                        <div className='positionForSerachSmile' style={{ cursor: 'pointer' }} >
                                                            <Smile color='#808080' strokeWidth={2} />
                                                            <Send color='#808080' className='iconForcolor' strokeWidth={2} />
                                                        </div>

                                                    </div>
                                            }

                                        </div> */}
                                        {/* sdASDFFFFFFFFFFFFFFFFFFFFFFFF */}
                                        {/* < div className="rem-web-view" >
                                            <div className=' border-0 px-0 w-100'>

                                                <div style={{ paddingInline: '5px' }} className='d-flex justify-content-between'>
                                                    <div className='d-flex justify-content-around align-items-start px-2'>
                                                        <div className='pb-1'>
                                                            <div className='d-flex align-items-center pb-1'>
                                                                {
                                                                    LikeIcon ? <div>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={30} src={ActiveCirclelike} alt='shareIcon' />
                                                                    </div> : <div>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={30} src={LikeImg} alt='shareIcon' />
                                                                    </div>
                                                                }
                                                                <h5 className='mx-2'>24</h5>
                                                            </div>
                                                            <div className='py-1' >
                                                                <EmoteAvatar />
                                                            </div>
                                                        </div>
                                                        <div onClick={Commenttoggle} className='d-flex align-items-center'>
                                                            {
                                                                comment ? <div className='text-center'>
                                                                    <img width={30} src={CommentActive} alt='shareIcon' />
                                                                </div> : <div className='text-center'>
                                                                    <img width={30} src={CmntImg} alt='shareIcon' />
                                                                </div>
                                                            }
                                                            <h5 className='px-3'>24</h5>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='text-center' onClick={toggleForward}>
                                                                <img width={30} src={ShrImg} alt='shareIcon' />
                                                            </div>
                                                            <h5 className='px-3'>24</h5>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '4px', borderRadius: '5px', cursor: 'pointer' }}   >
                                                        {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                                                    </div>
                                                </div>
                                                {
                                                    comment ? postType.typePoll === "PollNtCmnt" ? null :
                                                        <div className='p-3'>

                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                    <h4>Robert Fox</h4>
                                                                </div>
                                                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                                                            </div>
                                                            <div className='p-2 pl-5'>
                                                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                                                <div className='text-primary'>
                                                                    Like (5)
                                                                    &nbsp;
                                                                    Reply (6)
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center my-1'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                        <h4>Aliza Alveen</h4>
                                                                    </div>
                                                                    <h5 className='text-muted'>40 Mins ago</h5>
                                                                </div>
                                                                <div>
                                                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                                                    <div className='text-primary'>
                                                                        Like (5)
                                                                        &nbsp;
                                                                        Reply (6)
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div> : null
                                                }
                                                <div className='position-relative px-2'>
                                                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '75px' }} Placeholder='Write a comment...' />
                                                    <div className='positionForSerachSmile'>
                                                        <Smile color='#808080' strokeWidth={2} />
                                                        <Send color='#808080' className='iconForcolor' strokeWidth={2} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="Post_Dispaly_Card_body" >
                                        {postType.id}
                                    </div>
                                    <div className='bg-white border-0 w-100 rem-mob-view on-hover-bg'>
                                        <div className='d-flex justify-content-between p-2'>
                                            <div className='d-flex align-items-center w-75' >

                                                {
                                                    LikeIcon ? <div>
                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' />
                                                    </div> : <div>
                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={LikeImg} alt='shareIcon' />
                                                    </div>
                                                }
                                                <h5 style={{ color: '#808080' }} className='mx-2'>24</h5>
                                                {/* <EmoteAvatar /> */}
                                            </div>
                                            <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} >

                                                {postType.typePoll === "PollNtCmnt" ? null :
                                                    <>

                                                        < div className='d-flex align-items-center' onClick={Commenttoggle}>
                                                            {
                                                                comment ? <div className='text-center'>
                                                                    <img width={35} src={CommentActive} alt='shareIcon' />
                                                                </div> : <div className='text-center'>
                                                                    <img width={35} src={CmntImg} alt='shareIcon' />
                                                                </div>
                                                            }
                                                            <h5 style={{ color: '#808080' }} className='px-3'>24</h5>
                                                        </div>
                                                    </>
                                                }

                                                <div className='d-flex align-items-center'>
                                                    <div className='text-center' onClick={toggleForward} >
                                                        <img width={35} src={ShrImg} alt='shareIcon' />
                                                    </div>
                                                    <h5 style={{ color: '#808080' }} className='px-3'>24</h5>
                                                </div>

                                            </div>
                                        </div>
                                        {
                                            comment ? postType.typePoll === "PollNtCmnt" ? null :
                                                <div className='p-3'>

                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                            <h4>Robert Fox</h4>
                                                        </div>
                                                        <h5 className='text-muted pr-1'>40 Mins ago</h5>
                                                    </div>
                                                    <div className='p-2 pl-5'>
                                                        <div className='SingleMedia_Content_blk'>
                                                            <h3>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do
                                                                in your chosen destination.</h3>
                                                        </div>
                                                        <div className='text-primary'>
                                                            Like (5)
                                                            &nbsp;
                                                            Reply (6)
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center my-1'>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                <h4>Aliza Alveen</h4>
                                                            </div>
                                                            <h5 className='text-muted'>40 Mins ago</h5>
                                                        </div>
                                                        <div className='SingleMedia_Content_blk'>
                                                            <h3> When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</h3>
                                                            <div className='text-primary'>
                                                                Like (5)
                                                                &nbsp;
                                                                Reply (6)
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> : null
                                        }
                                        {/* {
                                            postType.typePoll === "PollNtCmnt" ? null :
                                                <div className='m-2 position-relative'>
                                                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '75px' }} Placeholder='Write a comment...' />
                                                    <div className='positionForSerachSmile' style={{ cursor: 'pointer' }} >
                                                        <Smile color='#808080' strokeWidth={2} />
                                                        <Send color='#808080' className='iconForcolor' strokeWidth={2} />
                                                    </div>

                                                </div>
                                        } */}

                                    </div>

                                    {/*================================================================== Mobile View (Like comment & share section) =====================================================================*/}
                                    {/* < div className="rem-web-view" >
                                        <div className='bg-white border-0 px-0 w-100'>

                                            <div style={{ paddingInline: '5px' }} className='d-flex justify-content-between'>
                                                <div className='d-flex justify-content-around align-items-start px-2'>
                                                    <div className='pb-1'>
                                                        <div className='d-flex align-items-center pb-1'>
                                                            {
                                                                LikeIcon ? <div>
                                                                    <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={30} src={ActiveCirclelike} alt='shareIcon' />
                                                                </div> : <div>
                                                                    <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={30} src={LikeImg} alt='shareIcon' />
                                                                </div>
                                                            }
                                                            <h5 className='mx-2'>24</h5>
                                                        </div>
                                                        <div className='py-1' >
                                                            <EmoteAvatar />
                                                        </div>
                                                    </div>
                                                    <div onClick={Commenttoggle} className='d-flex align-items-center'>
                                                        {
                                                            comment ? <div className='text-center'>
                                                                <img width={30} src={CommentActive} alt='shareIcon' />
                                                            </div> : <div className='text-center'>
                                                                <img width={30} src={CmntImg} alt='shareIcon' />
                                                            </div>
                                                        }
                                                        <h5 className='px-3'>24</h5>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='text-center' onClick={toggleForward}>
                                                            <img width={30} src={ShrImg} alt='shareIcon' />
                                                        </div>
                                                        <h5 className='px-3'>24</h5>
                                                    </div>
                                                </div>
                                                <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '4px', borderRadius: '5px', cursor: 'pointer' }}   >
                                                    {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                                                </div>
                                            </div>
                                            {
                                                comment ? postType.typePoll === "PollNtCmnt" ? null :
                                                    <div className='p-3'>

                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                <h4>Robert Fox</h4>
                                                            </div>
                                                            <h5 className='text-muted pr-1'>40 Mins ago</h5>
                                                        </div>
                                                        <div className='p-2 pl-5'>
                                                            <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                                            <div className='text-primary'>
                                                                Like (5)
                                                                &nbsp;
                                                                Reply (6)
                                                            </div>
                                                            <div className='d-flex justify-content-between align-items-center my-1'>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                                                    <h4>Aliza Alveen</h4>
                                                                </div>
                                                                <h5 className='text-muted'>40 Mins ago</h5>
                                                            </div>
                                                            <div>
                                                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                                                <div className='text-primary'>
                                                                    Like (5)
                                                                    &nbsp;
                                                                    Reply (6)
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div> : null
                                            }
                                            <div className='position-relative px-2'>
                                                <Input type='text' style={{ background: '#F9FBF7', paddingRight: '75px' }} Placeholder='Write a comment...' />
                                                <div className='positionForSerachSmile'>
                                                    <Smile color='#808080' strokeWidth={2} />
                                                    <Send color='#808080' className='iconForcolor' strokeWidth={2} />
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}

                                </Card >
                            </div>
                        ))}

                    </Masonry>
                </ResponsiveMasonry>
            </div>

            {/*================================================================== Share Modal Start =====================================================================*/}
            <ShareModal
                forwardOpen={forwardOpen}
                toggleForward={toggleForward}
            />
            {/*================================================================== Share Modal End  =====================================================================*/}
            <NewBookMark />

        </Fragment >
    )
}

export default NewMasonryGrid