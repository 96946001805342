import React, { useState,useEffect } from 'react'
import { MoreVertical, User, Users, XCircle } from 'react-feather'
import { Link } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
// import GrpImg2 from '../../NewComponents/IMG/Grly2.svg'
// import GrpImg3 from '../../NewComponents/IMG/Grly3.svg'
// import GrpImg1 from '../../NewComponents/IMG/Grly1.svg'
import axios from 'axios'

const LikedBizPage = () => {

    const [selectedLikedIndex, setSelectedLikedIndex] = useState(null);
    const[Likedpages,setLikedPages]=useState([]);

    // const Likedpages = [
    //     {
    //         userProfile: GrpImg1,
    //         pageName: 'Nazakat Chikan',
    //         Category: 'Clothing Store'
    //     },
    //     {
    //         userProfile: GrpImg3,
    //         pageName: 'Nazakat Chikan',
    //         Category: 'Clothing Store'
    //     },
    //     {
    //         userProfile: GrpImg2,
    //         pageName: 'Nazakat Chikan',
    //         Category: 'Clothing Store'
    //     },
    //     {
    //         userProfile: GrpImg1,
    //         pageName: 'Nazakat Chikan',
    //         Category: 'Clothing Store'
    //     },
    //     {
    //         userProfile: GrpImg3,
    //         pageName: 'Nazakat Chikan',
    //         Category: 'Clothing Store'
    //     }
    // ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    
                        userId:"225ccb4c-0da2-47ba-9514-fe18b5c39c4c",
                        searchKey : "",
                        pageIndex:0,
                        pageSize:5
                  
                }
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllSuggestedBusinessPageList`,
                    body,
                    {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                        },
                    }
                );
              
                setLikedPages(response.data.data.successResult);
              //  console.log("Data for suggested ",response.data.data.successResult)
            } catch (error) {
                console.error('Error fetching hashtags:', error);
            }
        };
    
        fetchData();
    }, []);

    const handleRemoveWidget = () => {
        // Clear the Likedpages list when the widget is removed
        setSelectedLikedIndex([])
    };
    //======================== Main_return_function ======================//
    return (
        <>
            <div className="page-list section-b-space">
                <div className="CommonHeadingSeg d-flex justify-content-between p-3">
                    <h3 style={{ color: "#333333" }}>Liked Biz Page</h3>
                    <div>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle className="Biz_PageMore_btn" outline color="primary">
                                <MoreVertical size={15} color='#808080' />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={handleRemoveWidget}> Remove widget</DropdownItem>
                                <Link to="/BizSuggested">
                                <DropdownItem> see all</DropdownItem>
                                </Link>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                </div>
                <div className="list-content">
                    <ul>
                        {
                            Likedpages.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="d-flex align-item-start">
                                            <div className="Biz_Img_Box">
                                                <img src={item.logoUrl} className="img-fluid bg-img" alt="" width={40} height={40} />
                                            </div>
                                            <div className="media-body hashtag-stat ml-3 ">
                                          <Link to="/biz">  <h4 style={{ color: '#4D4D4D' }}>
    {item?.name && item.name.length > 15
        ? item.name.substring(0, 15) + "..."
        : item?.name
    }
</h4></Link>
                                                <h6 style={{ cursor: "pointer", fontSize: "12px", color: "#999999", display: "flex", alignItems: "center" }}>{item.bpCategoryName}
                                                    &nbsp;<Users size={10} />&nbsp;
                                                    +{item.totalMembers}
                                                </h6>
                                            </div>
                                        </div>
                                        <div
                                    onClick={() => setSelectedLikedIndex(index)}
                                    className={selectedLikedIndex === index ? 'favourite-btn active' : 'favourite-btn'}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-dark iw-14 ih-14">
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                    </svg>
                                </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LikedBizPage