import * as types from '../../Constants/Common/index';
import axios from 'axios';

const getAllTrendingHashtag = (trandingHashtags) => ({
  type: types.GET_TRENDING_HASHTAG,
  payload: trandingHashtags,
});

export const loadAllTrendingHashtag = () => {
  return async (dispatch) => {
    try {
      let user = JSON.parse(localStorage.getItem('sociomeeUser'));
      if (user) {
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/hashtag/getTrending`,
          {
            searchKey: "",
            pageIndex: 0,
            pageSize: 10,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        
        dispatch(getAllTrendingHashtag(response.data.data.successResult.rows));
      }
    } catch (error) {
      console.error('Error fetching trending hashtags:', error);
    }
  };
};
