import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, Input, Card, CardBody, } from 'reactstrap';
import { Search, Grid, List, UserMinus, MoreVertical, PlusCircle, Share, Copy, Flag, MinusCircle, UserPlus } from 'react-feather';
import { loadAllUserFollowing } from '../../../Services/Actions/Connection/getUserFollowingAction';
import { loadAllFollowUnFollow } from '../../../Services/Actions/Connection/getFollowUnFollowAction';
import { getActionBlockUnblock } from "../../../Services/Actions/Connection/getBlockUnblockAction";
import BlockModal from '../ConnectionModal/BlockModal';
import Header from '../../Header';
import LeftSidebar from '../../LeftSidebar';
import RightSidebar from '../../RightSidebar';
import ConnectionPlaceMenu from './ConnectionPlaceMenu';
import { Button, InputGroup } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { FollowingList } from '../ListView/FollowingList';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import AddCategoryModal from '../ConnectionModal/AddCategoryModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import UnfollowModal from '../ConnectionModal/UnfollowModal';
import PublicAccount from '../ConnectionModal/PublicAccount';
import PrivateAccount from '../ConnectionModal/PrivateAccount';
import RemoveModal from '../ConnectionModal/RemoveModal';
import CommanHeader from '../CommanHeader';
import { NavLink } from 'react-router-dom';
import '../Style.css';

const FollowingScreen = () => {
    const { getUserFollowing } = useSelector((state) => state.GetUserFollowingData);
    const { getUserBlockUnblock } = useSelector(state => state.userBlockUnblockData)
    const dispatch = useDispatch();
    const [userId, setUserId] = useState("");
    const [Gridview, setGridview] = useState(false);

    const [selectedRequest, setSelectedRequest] = useState(null);

    //search opration 
    const [searchItem, setSearchItem] = useState("")

    const filteredData = getUserFollowing?.rows?.filter((item) =>{
        const fullNameMatch = item.fullName?.toLowerCase().includes(searchItem.toLowerCase());
        const userNameMatch = item.userName?.toLowerCase().includes(searchItem.toLowerCase());
        return fullNameMatch || userNameMatch;
      });
    
    // Reset pagination to the first page when search input changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchItem]);

    useEffect(() => {
        dispatch(loadAllUserFollowing());
    }, []);

    // Share Modal State
    const [shareOpen, setShareOpen] = useState(false);
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState);
    };

    // Unfollow Modal State
    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
    const toggleUnfollowModal = (request) => {
        setUnfollowModalOpen((prevState) => !prevState);
        setSelectedRequest(request)
    };
    const[unfollowConfirm,setunfollowConfirm] = useState(false)
    useEffect(()=>{
        if(unfollowConfirm){
        setTimeout(() => {
            dispatch(loadAllUserFollowing());
            setunfollowConfirm(false);
        }, 1000);
        } 
    },[unfollowConfirm])

    // Add Relationship State
    const [addCategoryOptions, setAddCategoryOptions] = useState(false);
    const toggleAddCategory = () => {
        setAddCategoryOptions((preState) => !preState);
    };

    // removemodal
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = (request) => {
        setRemoveModalOpen((prevState) => !prevState)
        setSelectedRequest(request)
    }

    // Reported Option State
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState)
        setSelectedRequest(request)
    }
    const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
                setTimeout(() =>{
                    dispatch(loadAllUserFollowing());
                    setReportConfirm(false);
                }, 1000);
           } 
    },[reportConfirm])

    // Pagination logic
    const itemsPerPage = 15; // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredData?.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    
    const [blockModalOpen, setBlockModalOpen] = useState(false);
    const toggleBlockModalOpen = () => {
        setBlockModalOpen((prevState) => !prevState);
    };
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
        if(blockConfirm){
        setTimeout(() => {
            dispatch(loadAllUserFollowing());
            setBlockConfirm(false);
        }, 1000);
        } 
    },[blockConfirm])

    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <Card>
                        {/* Comman header */}
                        <CommanHeader />
                        <CardBody>
                            {/* Connection tabs */}
                            <ConnectionPlaceMenu />
                            {/* MAIN */}
                            <div className="mppage-heading mt-4">
                                <div className="group-custom-block">
                                    <div className="nav-flex-container">
                                        <div>
                                            <h3 className="connection-sub-heading">I'm Following ({getUserFollowing.rows?.length})</h3>
                                        </div>
                                        <div className="sub-nav-flex size-sm-mt-4 mb-2">
                                            <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                                                <Search color="#666666" size={16} />
                                                <Input type="search" placeholder="Find..." className="coonection-input-style" value={searchItem} onChange={(e) => setSearchItem(e.target.value)} />
                                            </InputGroup>
                                            <div className="size-xs-mt-4 list-grid-toggle rem-mob-view">
                                                <Button
                                                    className={Gridview ? 'grid-toggle-customize-css ' : ''}
                                                    outline
                                                    onClick={() => setGridview(false)}
                                                >
                                                    <Grid size={16} />
                                                </Button>
                                                <Button
                                                    className={Gridview ? ' ' : 'grid-toggle-customize-css'}
                                                    outline
                                                    onClick={() => setGridview(true)}
                                                >
                                                    <List size={16} />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    {Gridview ? (
                                        <FollowingList  followingData={filteredData.slice(startIndex, endIndex)}/>
                                    ) : (
                                        <Row className="connections_PeopleThisGrid mt-2">
                                            {filteredData &&
                                               filteredData?.slice(startIndex, endIndex).map((request, index) => (
                                                    <Col lg="4" className="w-100" key={index}>
                                                        <div className="connection-card content-mp-block d-flex box-shadow-style justify-content-between">
                                                            <div className="media-body d-md-block">
                                                                <div className="media media-new d-flex align-items-start">
                                                                    <div className="user-img d-flex align-items-start popover___wrapper ">
                                                                        <img
                                                                            src={request?.profileImage}
                                                                            className="rounded-circle "
                                                                            height={45}
                                                                            width={45}
                                                                            alt="kuldeep"
                                                                        />
                                                                        <span className="available-stats online"></span>
                                                                        {request?.isPrivate ? <PrivateAccount request={request}/> : <PublicAccount request={request}/>}
                                                                    </div>
                                                                    <div className="media-body d-md-block pl-1">
                                                                        <NavLink to="/MyProfile">
                                                                            <h4 className="m-0 name-style text-wrap d-inline-block text-truncate">
                                                                                {request?.fullName?.substring(0, 21) || 'NA'}
                                                                            </h4>
                                                                        </NavLink>
                                                                        <div className="d-flex my-1 text-wrap">
                                                                            <p className="text-wrap user-name-style rem-mob-view-small">
                                                                                {request?.userName || 'NA'} |
                                                                            </p>
                                                                            <span>
                                                                                <img
                                                                                    src={request?.countryData[0]?.flagURL}
                                                                                    className="ml-1 rem-mob-view-small"
                                                                                    alt="..."
                                                                                    width={19}
                                                                                    height={13}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                         <p className='professional-style rem-mob-view-small text-wrap'>{request?.profession || "No Profession"}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end flex-column">
                                                                <UncontrolledButtonDropdown
                                                                    className="d-flex align-items-start flex-column justify-content-end rem-mob-view-small"
                                                                    direction="left"
                                                                    >
                                                                    <DropdownToggle
                                                                        color="light"
                                                                        className="connection-cutomize-dropdown-toggle pb-0 pr-0"
                                                                    >
                                                                        <MoreVertical size={22} />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="Connection-dropdown">
                                                                        {/*<DropdownItem className="border-bottom py-2 menu-hover-style">
                                                                        <UserPlus size={15} className="mr-2 icon-color-style" />
                                                                        Follow Back
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                        className="border-bottom py-2 menu-hover-style"
                                                                        onClick={() => toggleRemoveModalOpen(request)}
                                                                        >
                                                                        <MinusCircle size={15} className="mr-2 icon-color-style" />
                                                                        Remove Follower
                                                                        </DropdownItem>*/}
                                                                        <DropdownItem onClick={() => toggleUnfollowModal(request)} className='border-bottom py-2 menu-hover-style'>
                                                                            <UserMinus size={15} className='mr-2' />
                                                                        Unfollow
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                        className="border-bottom py-2 menu-hover-style"
                                                                        onClick={toggleShareOpen}
                                                                        >
                                                                            <Share size={15} className="mr-2 icon-color-style" />
                                                                        Share Profile
                                                                        </DropdownItem>
                                                                        <DropdownItem className="border-bottom py-2 menu-hover-style">
                                                                            <Copy size={15} className="mr-2 icon-color-style" />
                                                                        Copy Profile URL
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                        className="border-bottom py-2 menu-hover-style"
                                                                        onClick={() => {
                                                                            toggleBlockModalOpen();
                                                                            setUserId(request.id);
                                                                        }}
                                                                        >
                                                                            <MinusCircle size={15} className="mr-2 icon-color-style" />
                                                                        Block
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                        className="py-2 menu-hover-style"
                                                                        onClick={() => toggleReport(request)}
                                                                        >
                                                                            <Flag size={15} className="mr-2" />
                                                                        Report User
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                                <div role="button" className='mt-3 d-flex align-items-end' onClick={() => { toggleUnfollowModal(request) }}>
                                                                    <Button color='flat-success' className='btn-transparent mt-1 pb-0 pr-0'>
                                                                        <UserMinus size={15} color='#FF0000' />&nbsp;&nbsp;<p className='connection-red-text'>Unfollow</p>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xl="12" className="d-flex justify-content-center">
                                            {getUserFollowing.rows?.length === 0 && <h3 className="text-center connection-sub-heading">No Following Found</h3>}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>
                        {/* Pagination */}
                        <div className="d-flex justify-content-center my-3">
                         {filteredData?.length > itemsPerPage && (
                                        <Stack spacing={2} >
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                size="large"
                                                color="primary"
                                            />
                                        </Stack>
                                    )}
                        </div>
                    </Card>
                </div>
                <RightSidebar />
            </div>
            {/* Add Category Status */}
            <AddCategoryModal 
                openModal={addCategoryOptions} 
                cateModalOpen={toggleAddCategory} 
            />
            {/* Add Report Option */}
            { reportOpen && (
                <ConnectionReportModal 
                    modalOpen={reportOpen} 
                    reportModalOpen={toggleReport} 
                    request={selectedRequest}
                    setReportConfirm ={setReportConfirm} 
                />
            )}
            {/* Share Modal */}
            <ConnectionShareModal 
                openModal={shareOpen} 
                shareModalOpen={toggleShareOpen} 
            />
            {/* Unfollow Modal */}
            { unfollowModalOpen && (
                <UnfollowModal
                    request={selectedRequest}
                    openModal={unfollowModalOpen}
                    unfollowModalOpen={toggleUnfollowModal}
                    setunfollowConfirm={setunfollowConfirm}
                />
            )}
            {/*------------------------- block modal----------------------- */}
            { blockModalOpen && (
                <BlockModal
                    request={userId}
                    openModal={blockModalOpen}
                    removeModalOpen={toggleBlockModalOpen}
                    setBlockConfirm={setBlockConfirm}
                />
            )}
            {/*  ------------------ Remove Member Modal  ------------------ */}
            <RemoveModal
                request={selectedRequest}
                openModal={removeModalOpen}
                removeModalOpen={toggleRemoveModalOpen}
            />
        </>
    );
};

export default FollowingScreen;
