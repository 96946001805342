import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    getUserWithdrawSentRequest: [],
    loading: true,
};

 const GetWithdrawRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_SENT_REQUEST_WITHDRAW:
            return {
                ...state,
                getUserWithdrawSentRequest: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default GetWithdrawRequestReducer;