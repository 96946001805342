import React, { useState, useEffect } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadAllFeedReportOptions } from "../../../Services/Actions/SocialFeed/getFeedReportOptionsAction";
import { getFeedReportClick } from "../../../Services/Actions/SocialFeed/FeedReportClickAction";




const ReportPostModal = ({ modalOpen, reportModalOpen,postId}) => {
  
    const { getFeedReportOption } = useSelector(
        (state) => state.getUserReportOptionsData
      );

  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [remark, setRemark] = useState("");
  const { getUserReportOption } = useSelector(
    (state) => state.userReportOptionData
  )
  ;

  const handleChange = (id) => {
    setSelectedOption(id);
  
  
  };

  const reportSubmit = (postId,selectedOption) =>{
    dispatch(getFeedReportClick(postId,selectedOption))
    
    reportModalOpen();
   
  }
   



 
  //  ------------- Start Reported Option  -------------

  return (
    <>
      {modalOpen !== false && (
        <Modal
          isOpen={modalOpen}
          toggle={reportModalOpen}
          className="modal-dialog-centered"
        >
          <ModalHeader toggle={reportModalOpen}>
            <h2>Report</h2>
          </ModalHeader>
          <ModalBody className="Modal_Body_Section">
            <div>
              <h2>Please select a problem</h2>
              <p className="pb-1">
              If someone is in immediate danger, get help before reporting to Sociomee. Don't wait
              </p>
            </div>
            {getFeedReportOption?.map((data) => (
              <div
                key={data.id}
                className="d-flex justify-content-between mt-4 Report_Section"
              >
                <div>
                  <h4>{data.reportText}</h4>
                </div><br/><br/>
                <div>
                  <Input name="radio1" type="radio"  onClick={()=>{handleChange(data.id)}} />
                </div>
              </div>
            ))}

          
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button
              variant="primary"
              className="w-25"
              outlined
              onClick={()=>{reportSubmit(postId,selectedOption)}}
            >
              Submit
            </Button>
            <Button
              variant="outline-secondary"
              className="w-25"
              onClick={reportModalOpen}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ReportPostModal;



// import React from 'react'

// // mui dailog box
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// const ReportPostModal = ({ modal, setModal, reportPostBody, setReportPostBody, setOpen, setAlert }) => {

//     // post report function
//     const postReportHandler = () => {
//         if (!reportPostBody.comment) {
//             setOpen(true);
//             setAlert({ sev: "error", content: "Please Enter Reason !", });
//         }
//         else {
//             console.log(reportPostBody)
//         }
//     }

//     return (
//         <>
//             {/* Mui Modal box content */}
//             <Dialog open={modal} onClose={false} scroll={'body'}>
//                 <DialogTitle>Report</DialogTitle>
//                 <DialogContent>
//                     <h3 className='font-bold'>Please select a problem</h3>
//                     <DialogContentText>
//                         If someone is in immediate danger, get help before <br/>
//                         reporting to Facebook. Don't wait
//                     </DialogContentText>
//                     {/* <div className="card-block-box"> */}
//                     <ul className="searchfiler-list mt-2">
//                         {
//                             ['demo', 'demo2', 'demo3'].map((hobbi) => {
//                                 return (
//                                     <li key={hobbi} >
//                                         <div className="form-check checkbox_animated2">
//                                             <input
//                                                 type="checkbox"
//                                                 className="form-check-input"
//                                                 id={hobbi}

//                                             />
//                                             <label className="form-check-label d-flex justify-content-between" htmlFor={hobbi}>{hobbi}</label>
//                                         </div>
//                                     </li>
//                                 )
//                             })
//                         }
//                     </ul>
//                     {/* </div> */}
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={() => setModal(false)}>Cancel</Button>
//                     <Button onClick={postReportHandler}>Submit</Button>
//                 </DialogActions>
//             </Dialog>
//         </>
//     )
// }

// export default ReportPostModal
