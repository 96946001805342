import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { GroupCard } from './GroupCard';
import { PeopleCard } from './PeopleCard';
import { AllRequestedUser } from './AllRequestedUser';
import '../Style.css';
import { useDispatch, useSelector } from 'react-redux';
import { AllUserSentRequest } from '../../../Services/Actions/Connection/getUserSentRequestAction';


export const FollowReqestTab = () => {

  const dispatch = useDispatch();
  const { userSentRequests } = useSelector((state) => state.userSentRequestsData );
  const [active, setActive] = useState('1');

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };

  useEffect(() => {
    // Dispatch the action to load all user sent requests when the component mounts
    dispatch(AllUserSentRequest("All"));
  }, [dispatch]);

   // Filter the data based on the 'type' field
   const groupRequests = userSentRequests?.data?.rows?.filter((request) => request.Type === 'Group') || [];
   const peopleRequests = userSentRequests?.data?.rows?.filter((request) => request.Type === 'People') || [];
   const allRequests = userSentRequests?.data?.rows || []; // Unfiltered data for "All"

  //const handleAllSentRequest = (type) => {
  //  if (type === 'group') {
  //    // Dispatch an action for handling group requests
  //  } else if (type === 'people') {
  //    // Dispatch an action for handling people requests
  //  }
  //};

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={active === '1'}
            onClick={() => {
              //handleAllSentRequest('all'); // Send 'all' as type parameter
              toggle('1');
            }}
          >
            <h4> All ({allRequests.length}) </h4>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '2'}
            onClick={() => {
              //handleAllSentRequest('people'); // Send 'people' as type parameter
              toggle('2');
            }}
          >
            <h4> People ({peopleRequests.length}) </h4>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '3'}
            onClick={() => {
              //handleAllSentRequest('group'); // Send 'group' as type parameter
              toggle('3');
            }}
          >
            <h4> Group ({groupRequests.length}) </h4>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className='py-50' activeTab={active}>
        <TabPane tabId='1'>
          <AllRequestedUser requests={allRequests} />
        </TabPane>
        <TabPane tabId='2'>
          <PeopleCard requests={peopleRequests}/>
        </TabPane>
        <TabPane tabId='3'>
          <GroupCard requests={groupRequests}/>
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};
