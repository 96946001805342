
import React, { useState } from 'react'
import { Delete, Eye, Filter, Globe, MapPin, Users, ThumbsUp, MoreHorizontal, MoreVertical, Star, Send, Link, Edit, Trash2, User, UserPlus, Share2, ThumbsDown, AlertTriangle } from 'react-feather'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Eventpic1 from '../../NewComponents/IMG/Eventpic1.png'
import Eventpic2 from '../../NewComponents/IMG/Eventpic2.png'
import Eventpic3 from '../../NewComponents/IMG/Eventpic3.png'
import Eventpic4 from '../../NewComponents/IMG/Eventpic4.png'
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'


// import cover1 from '../../NewComponents/IMG/cover1.png'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { Offcanvas } from 'react-bootstrap'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'

const EventTabs = () => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const Eventplace = [
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            text: <div className='text-muted'><Users size={14} /> Going</div>,
            text1: <div><ThumbsUp size={14} /> Interested</div>,
            Event: Eventpic4,
            EventforTabsright: 'EventforTabsright1',
            Function: 'Entertainment'
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Traditional Wedding',
            text: <div className='text-muted'><Users size={14} /> Going</div>,
            text1: <div><ThumbsDown size={14} />Not Interested</div>,
            Event: Eventpic2,
            EventforTabsright: 'EventforTabsright2',
            Function: 'Comedy'
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            text: <div className='text-muted'><Users size={14} /> Going</div>,
            text1: <div><ThumbsDown size={14} /> Not Interested</div>,
            Event: Eventpic3,
            EventforTabsright: 'EventforTabsright3',
            Function: 'Fun & Entertainment '
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            text: <div className='text-muted'><Users size={14} /> Going</div>,
            text1: <div><ThumbsUp size={14} /> Interested</div>,
            Event: Eventpic1,
            EventforTabsright: 'EventforTabsright4',
            Function: 'Health & fitness '
        }
    ]
    return (
        <div>
            <Card className='p-2 border-0'>
                <Row className='d-flex align-items-center'>
                    <Col md='6'>
                        <div className='d-flex align-items-center pl-1'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Naba Kelebar Utsav”</p>
                        </div>
                    </Col>
                    <Col md='6'>

                        <div className='d-flex justify-content-end p-2'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='AllTagsThis PeopleThisGrid'>
                    {
                        Eventplace.map((data) => {
                            return (
                                <Col md='3' className='w-100'>
                                    <div className='p-1 mt-2'>
                                        {/* <Slider {...bizSettings} className="default-space"> */}
                                        <div className="group-slide-box border">
                                            <div className="gallery-img-sublink">
                                            </div>
                                            <NavLink>
                                                <div className={data.EventforTabsright}>
                                                    <p className='mt-1'>{data.Function}</p>
                                                </div>
                                                <img src={data.Event} className="img-fluid group-img" />

                                                <div className='d-flex justify-content-between'>
                                                    <p className='EventTabs_text_date'>Sun, 26 Jun 2022, 06:00PM - 09:30AM</p>
                                                    <p>
                                                        <UncontrolledButtonDropdown className='align-self-center'>
                                                            <DropdownToggle className='' color='flat' >
                                                                <MoreHorizontal size={19} color='#000000' />
                                                            </DropdownToggle>
                                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </p>
                                                </div>

                                                <p className='Event_Text'>Naba Kelebar Utsav Of Para..</p>
                                            </NavLink>
                                            <div className='d-flex justify-content-start MapThisBessemer'>
                                                <p><MapPin size={16} color='#808080' /></p>
                                                <p className='EventTabs_Bessemer'>&nbsp;Bessemer, North Carolina</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='text-muted d-flex GoingThis'>
                                                    <Users size={15} color='#808080' className='align-self-center' />
                                                    <p className='ml-2'>160 Going</p>
                                                </div>
                                                <div className='text-muted d-flex GoingThisInterested mr-3'>
                                                    <Users size={15} color='#808080' className='align-self-center' />
                                                    <p className='ml-2'>154 Interested</p>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-center m-2'>
                                                <UncontrolledButtonDropdown className='w-100'>
                                                    <DropdownToggle color='primary' caret>
                                                        Going
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem className='primary'><User size={16} /> Going</DropdownItem>
                                                        <DropdownItem className='primary'><ThumbsDown size={16} /> Not Interested</DropdownItem>
                                                        <DropdownItem className='primary'><ThumbsUp size={16} /> Interested</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Card>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Event</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Event
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h4>Withim (Km)</h4>
                            <h4>8821 Km</h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className='w-100'>
                                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                    <MatSlider
                                        aria-label="Volume"
                                        defaultValue={100}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                            '& .MuiSlider-track': {
                                                border: 'none',
                                            },
                                            '& .MuiSlider-thumb': {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#81C14B',
                                                '&:before': {
                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                    boxShadow: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div className='d-flex align-items-center ml-3' role='button'>
                                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                            </div>
                        </div>
                        <div className='border-top mt-2'>
                            <h4 className='mt-2'>Choose locations</h4>
                            <ChipBoxLocation />
                        </div>
                        <h4 className='mt-3'>Choose Category</h4>
                        <ChipBox />
                        <h4 className='mt-2'>Event date</h4>
                        <div className='d-flex mt-2'>
                            <input class="form-control" type="date" />
                            <p className='p-2'>to</p>
                            <input class="form-control" type="date" />
                        </div>
                        {/* <form className='justify-content-center'>
                                                                <h4 className='d-flex'>
                                                                    Event date
                                                                </h4>
                                                                <div class="d-flex mt-1">
                                                                    <div class="form-group">
                                                                        <input class="form-control" type="date" />
                                                                    </div>
                                                                    <div className=''>to</div>
                                                                    <div class="form-group">
                                                                        <input class="form-control" type="date" />
                                                                    </div>
                                                                </div>
                                                            </form> */}
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>

        </div>
    )
}

export default EventTabs