import React, { useState } from 'react'
import { AlertTriangle, Edit, Eye, Filter, Link, MessageCircle, MoreHorizontal, Send, Share2, Trash2 } from 'react-feather'
import { Button, Card, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import img3 from '../../NewComponents/IMG/img3.png'
import Pollimg from '../../NewComponents/IMG/roundedimg2.svg'
import Pollimg1 from '../../NewComponents/IMG/roundedimg3.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Union from '../../NewComponents/IMG/Union.png'
import like from '../../NewComponents/IMG/like.png'
import Frame2 from '../../NewComponents/IMG/Frame2.png'
import Frame3 from '../../NewComponents/IMG/Frame3.png'
import Frame4 from '../../NewComponents/IMG/Frame4.png'
import CommentImg from '../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../NewComponents/IMG/Share.svg'
import LikeBlack from '../profile-components/Img//likesvg.svg'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal';
const TextTabs = () => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)

    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const Textdata = [
        {
            img: Pollimg
        },
        {
            img: Pollimg1
        },
        {
            img: Pollimg
        },
        {
            img: Pollimg
        }


    ]
    return (
        <div>
            <Card className='p-2 border-0'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col md='6'>
                        <div className='d-flex mt-2 ml-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Esther Howard”</p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='d-flex justify-content-end p-2'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='PeopleThisGrid p-3'>
                    {
                        Textdata.map((data, index) => {
                            return (
                                <Col xl='3' className='w-100 p-1'>
                                    <div className=''>
                                        <Card className='roundedThisBorder'>

                                            <div className='d-flex justify-content-between  m-2'>
                                                <div className='d-flex align-items-center '>
                                                    <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                                    <div className='ml-2'>
                                                        <h4>Floyd Miles </h4>
                                                        <p>19 sept at 07:00 pm</p>
                                                    </div>
                                                </div>

                                                        <UncontrolledButtonDropdown>
                                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                                        <MoreHorizontal size={19} color='#00000040' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div className='d-flex'>
                                                <p className='Celebration'>Celebration new album song launched</p>
                                            </div>
                                            <CardContent>
                                                <div className=''>
                                                    <p><b className='Musiccelebration'>#Musiccelebration,</b>#Musiccelebration, #music, #party, #music</p>
                                                    <p className='text-muted'>Lorem Ipsum is simply dummy text of{seemore ? 'has been the industrys standard dummy text ever since the 1500s ' : null}<span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>
                                                </div>
                                            </CardContent>
                                            <div className='d-flex justify-content-between p-2'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                        <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                                    </div>
                                                    11.2k
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                        <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                                    </div>
                                                    11.2k
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                        <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                                    </div>
                                                    11.2k
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Card>

            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Shotz</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>
                }

            </div>

            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Text
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h4>Withim (Km)</h4>
                            <h4>8821 Km</h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className='w-100'>
                                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                    <MatSlider
                                        aria-label="Volume"
                                        defaultValue={100}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                            '& .MuiSlider-track': {
                                                border: 'none',
                                            },
                                            '& .MuiSlider-thumb': {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#81C14B',
                                                '&:before': {
                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                    boxShadow: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div className='d-flex align-items-center ml-3' role='button'>
                                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                            </div>
                        </div>
                        <div className='border-top mt-2'>
                            <h4 className='mt-2'>Choose locations</h4>
                            <ChipBoxLocation />
                        </div>
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default TextTabs