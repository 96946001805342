import React from 'react'
import { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Button } from 'reactstrap'
import { MsgReplyData } from './SettingArray'
import QuestionAnswer from './SettingModal/QuestionAnswer'
import {getInstantBizPageMessageAction, instantBizPageMessageAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
const MessageSetting = (props) => {

    let dispatch = useDispatch();
    const { id } = useParams();

    const getInstantMessages = useSelector((state) => { return state.getInstantBizPageMessageData});
    const { loading: loadingGetInstantMessages, error: errorGetInstantMessages, result: resultGetInstantMessages} = getInstantMessages
    

    useEffect(() => {
        dispatch(getInstantBizPageMessageAction(id))
    }, []);

    const [instantReply, setInstantReply] = useState(resultGetInstantMessages?.[0].instantReplyText || '');
    const [instantAwayText, setInstantAwayText] = useState(resultGetInstantMessages?.[0].awayMessageText || '');

        function instantReplyText(event) {
        setInstantReply(event.target.value); // prints the current value of the input
      }
      

      function instantAwayTextOnChange(event) {
        setInstantAwayText(event.target.value); // prints the current value of the input
      }

    const [allowinstantReplyOn, setAllowinstantReplyOn] = useState(false);
    const [awayMessageOn, setAwayMessageOn] = useState(false);

    useEffect(() => {
        setAllowinstantReplyOn(resultGetInstantMessages?.[0]?.instantReplyOn || false);
        setAwayMessageOn(resultGetInstantMessages?.[0]?.awayMessageOn || false);
      }, [resultGetInstantMessages]);

      function handleinstantReplyOnChange() {
        setAllowinstantReplyOn(!allowinstantReplyOn);
      }

      function handleAwayMessageOnChange() {
        setAwayMessageOn(!awayMessageOn);
      }

    const [questionOpen, setQuestionOpen] = useState(false)

    const toggleQuestion = () => {
        setQuestionOpen((preState) => !preState)
    }

    const saveInstantMessageData = () => {          
            dispatch(instantBizPageMessageAction(id, instantReply, allowinstantReplyOn, instantAwayText, awayMessageOn))
            (sweetAlertConfirmation('Successfully changed the configuration'))
    }

    
    return (
        <Fragment>
            <div className="custom-card-block">
                <div className="customAbout-card-head" onClick={() => { props.setsetsideclassoption(false); }} style={{cursor:"pointer"}} >
                    <h3> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  className='hide-setting-options12'>
                            <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                        </svg>Message Setting  </h3>
                </div>
                <div className="custom-card-body">

                            <div className="biz-main-SettingTab-blk ">
                                <div className=''>
                                    <div className='mr-auto d-flex justify-content-between'>
                                        <h4>Instant Reply</h4>
                                        <div className=' d-flex'>
                                        <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                        <label className="switch">
                                            <input 
                                            type="checkbox" 
                                            checked={allowinstantReplyOn}
                                            onChange={handleinstantReplyOnChange}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                    </div>
                                       
                                    </div>
                                    <p className='text-muted'>Respond to messages on your page instantly
</p>
                                </div>
                                <div className='py-3'>
                                    <h4>Your Instant Reply</h4>
                                    <textarea
                                        onChange={instantReplyText}
                                        value={instantReply}
                                        placeholder={resultGetInstantMessages?.[0].instantReplyText || 'Enter your message'} 
                                        className="form-control " 
                                        name="" 
                                        id="" 
                                        cols="15" 
                                        rows="5"
                                        maxLength={240}
                                    />
                                    <p className='d-flex justify-content-end text-muted'>Max 240 Characters </p>
                                </div>

                            </div>


                            <div className="biz-main-SettingTab-blk ">
                                <div className=''>
                                    <div className='mr-auto d-flex justify-content-between'>
                                        <h4> Your Away Message</h4>
                                        <div className=' d-flex'>
                                        <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                        <label className="switch">
                                            <input type="checkbox" 
                                            checked={awayMessageOn}
                                            onChange={handleAwayMessageOnChange}
                                            />

                                            <span className="slider round"></span>
                                        </label>
                                        <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                    </div>
                                   
                                    </div>
                                    <p className='text-muted'>Enable away  message to inform user about non availability of the page admin/manager</p>
                                </div>
                                <div className='py-3'>
                                    <h4>Your Away Message </h4>

                                    <textarea
                                        onChange={instantAwayTextOnChange}
                                        value={instantAwayText}
                                        placeholder={resultGetInstantMessages?.[0].instantAwayText || 'Enter your away message'} 
                                        className="form-control" 
                                        name="" 
                                        id="" 
                                        cols="15" 
                                        rows="5"
                                        maxLength={240}
                                    />
                                    <p className='d-flex justify-content-end text-muted'>Max 240 Characters </p>
                                </div>

                            </div>


                    <div className="biz-main-SettingTab-blk">
                        <div className=''>
                            <div className='askqu'>
                                <h4>Frequently Ask Questions </h4>
                                
                                <h4 className='text-primary qamda' style={{ cursor: "pointer" }} onClick={toggleQuestion}>Question and Answer →</h4>
                                <h4 className='text-primary plus1' style={{ cursor: "pointer" ,fontSize:"30px",position:"relative",bottom:"6px"}} onClick={toggleQuestion}>+</h4>
                                {/* <p className='text-muted'>Suggest most commen question regaring your business and answer it for user to see it on your business page </p> */}
                            </div>
                            

                        </div>
                        <div >
                            <p className='text-muted'> Add FAQs about your business which would be visible to visitors on the Page. 
                             {/* it for user to see it on your business page  */}
                             </p> 
                            </div>
                        <div className='mt-3'>
                            <h4>Q:  What problem does your business solve?</h4>
                            <p >Customers with problems are seeking solutions, whether it’s finding a better-fitting swimsuit or an easier way to accomplish an annoying task. Companies should offer solutions that improve customers' lives. A great company creates value by filling a void in the marketplace.</p>
                        </div>
                    </div>
                    <div className="biz-main-Setting-blk text-end ">
                        {/* <Button className="w-25" outline >Cancel </Button> */}
                        <Button color="primary" className="ml-2 w-25" onClick={saveInstantMessageData} >Save</Button>
                    </div>
                </div>

            </div>
            {/* start_modal_section */}
            <QuestionAnswer
                isOpen={questionOpen}
                handleOpen={toggleQuestion}

            />
        </Fragment>
    )
}

export default MessageSetting