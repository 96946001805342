import React from 'react'
import { AlertTriangle, ArrowDownCircle, Bookmark, Circle, Copy, Download, Edit, Edit2, Eye, Filter, Flag, Headphones, MoreVertical, Pause, Play, Repeat, Send, Trash, Trash2, User } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Mic from '../../../../NewComponents/IMG/Mic.png'
import PaidTag from '../../../../NewComponents/IMG/paid.png'
import { useUserAuth } from '../../../../Context/userAuthContext'
import PlayIcon from '../../../../NewComponents/IMG/playVideo.svg'
import ActiveCirclelike from '../../../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../../../NewComponents/IMG/Circlelike.svg'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { Checkbox } from '@mui/material'
import { useState } from 'react'
import UserImg from '../../../../NewComponents/IMG/img.svg'
import { ForwardArrayData } from '../../../Social-Feed-Component/FeedArray';
import CommentLatest from '../../../../NewComponents/IMG/CommentLatest.svg'
import ShareLatest from '../../../../NewComponents/IMG/ShareLatest.svg';
import musicAnimation from '../../../../NewComponents/Gif/musicAnimation.gif'
import MicHover from '../../../../NewComponents/IMG/podcastIcon.svg'


const CommonSeeallpage = (props) => {
    const { musicBar, setMusicBar } = useUserAuth();
    const { listView, setListView } = useUserAuth();
    /* --------------------- States --------------------  */
    // const [style, setStyle] = useState({ display: 'none' });
    const [isPlaying, setIsPlaying] = useState(false);
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [comment, SetComment] = useState(false)
    const [deletepodcast, setdeletepodcast] = useState(false)
    const [downloadpodcast, setdownloadpodcast] = useState(false);
    const [hover, sethover] = useState(0)
    /*  ---------------------- Function for deleting podcast --------------------  */
    const toggledeletepodcast = () => {
        setdeletepodcast((prevState) => !prevState)
    }
    {/* ----------------------------State and function for Report Modal------------------------------------------ */ }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    /*  ---------------------- Function & state for sharing podcast --------------------  */
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    /*  ---------------------- Function & state for comment modal of podcast --------------------  */
    const [Commentopen, setcommentclose] = useState(false)
    const toggleComment = () => {
        setcommentclose((preState) => !preState)
    }
    /*---------------------- Function for toggling of podcast ------------------------*/
    const toggledownloadpodcast = () => {
        setdownloadpodcast((prevState) => !prevState)
    }
    /*  ---------------------- count abbreviation function --------------------  */
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(1).toString()} K`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(1).toString()} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    /*  ---------------------- Dyanmic Card Data Array --------------------  */
    const cardData = [
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'Unpaid'
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'paid'
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'Unpaid'
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'paid'
        },
    ]
    /*  ---------------------- Dyanmic List Data Array --------------------  */
    const musicArray = [
        {
            id: 1,
            Live: false,
            publisher: 'john wick',
        },
        {
            id: 2,
            Live: true,
            publisher: 'john wick',
            series: true
        },
        {
            id: 3,
            Live: false,
            publisher: 'john wick',
            series: true
        },
        {
            id: 4,
            Live: true,
            publisher: 'john wick',
            series: true
        },
        {
            id: 5,
            Live: false,
            publisher: 'john wick',
            series: true
        },
        {
            id: 6,
            Live: false,
            publisher: 'john wick',
            series: false
        },
        {
            id: 7,
            Live: false,
            publisher: 'john wick',
            series: true
        }
    ]
    // -----------------------------------------------Array data Report Data Info Part-------------------------------------------------------
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harassment"
        },
        {
            name: "Suicide or Self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    /*-------------- Modal for Delete User -----------------*/
    const DeleteModal = () => {
        return (
            <>
                {deletepodcast ? (
                    <Modal isOpen={deletepodcast} className='modal-dialog-centered' toggle={toggledeletepodcast} >
                        <ModalHeader className='d-flex justify-content-center border-0 Media_header'>
                            <h3>Delete Podcast</h3>
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <h5 className='py-1 name-style'>Do you really want to delete this Podcast ?</h5>
                        </ModalBody>
                        <ModalFooter className='d-flex align-items-center justify-content-center border-0 modalHeading_Section' >
                            <div className='text-center'>
                                <Button onClick={toggledeletepodcast} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={toggledeletepodcast} >Delete</Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    /*-------------- Modal for Share pocast -----------------*/
    const ShareModal = () => {
        const Sharedto = [
            {
                name: "Your Wall Feed"
            },
            {
                name: "Your Story"
            }
        ]
        return (
            <div>
                {
                    forwardOpen !== false &&
                    <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>

                        <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
                            <h2>Share to  </h2>
                            <Button color='primary' >Share</Button>
                        </div>
                        <ModalBody>
                            <div className='pb-2'>
                                <Accordion defaultActiveKey="0">
                                    <AccordionItem eventKey='1'>
                                        <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                                        <AccordionBody className='p-0'>
                                            <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey='1'>
                                                    <AccordionHeader>Feed</AccordionHeader>
                                                    <AccordionBody>
                                                        {
                                                            Sharedto.map((data) => (
                                                                <div className='d-flex justify-content-between align-items-center my-2 Report_Section'>
                                                                    <h4>{data.name}</h4>
                                                                    <div>
                                                                        <Checkbox />
                                                                        {' '}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='2'>
                                                    <AccordionHeader>Your Business Pages</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='3'>
                                                    <AccordionHeader>Your Group</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Connections</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            <Checkbox />
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }
    /*-------------- Modal for Download pocast -----------------*/
    const DownloadModal = () => {
        return (
            <>
                {downloadpodcast ? (
                    <Modal isOpen={downloadpodcast} className='modal-dialog-centered' toggle={toggledownloadpodcast} >
                        <ModalHeader className='d-flex justify-content-center border-0 Media_header'>
                            <h3>Download Podcast</h3>
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <h5 className='py-1 name-style'>Do you really want to download this Podcast ?</h5>
                        </ModalBody>
                        <ModalFooter className='d-flex align-items-center justify-content-center border-0 modalHeading_Section' >
                            <div className='text-center'>
                                <Button onClick={toggledownloadpodcast} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={toggledownloadpodcast} >Download</Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    /*-------------- Modal for Comment -----------------*/
    const CommentModal = () => {
        const [LikeIcon, SetLikeIcon] = useState(false)
        const [comment, SetComment] = useState(false)
        return (
            <div>
                {
                    Commentopen !== false &&
                    <Modal scrollable isOpen={Commentopen} toggle={toggleComment} className='modal-dialog-centered modal-lg'>

                        <div className='p-3 border-bottom'>
                            <h2>Comments </h2>
                        </div>
                        <ModalBody>
                            <div className="p-2">
                                <div className="col p-0">
                                    <div className="comment-part">
                                        <div className="d-flex pb-2">
                                            <div className="user-media">
                                                <div className="media">
                                                    <Link className="user-img">
                                                        <img src="https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg" className="img-fluid bg-img" alt="user" />
                                                        <span className="available-stats"></span>
                                                    </Link>
                                                    <div className="media-body">
                                                        <h5>Kahani mein Twist</h5>
                                                        <h6>This podcast includes short stories inspired from...</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-panel mb-0">
                                            <div className="post-wrapper">
                                                <div className="post-details">
                                                    <div className="like-panel">
                                                        <div className="left-emoji">
                                                            <ul>
                                                                <li>
                                                                    <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                </li>
                                                                <li>
                                                                    <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                                </li>
                                                            </ul>
                                                            <h6>+75</h6>
                                                        </div>
                                                        <div className="right-stats">
                                                            <ul >
                                                                <li>
                                                                    <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                                </li>
                                                                <li><span className='d-flex align-items-center'>985  &nbsp;<p className='text-muted'>Share</p></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="post-react">
                                                        <ul>
                                                            <li >
                                                                {
                                                                    LikeIcon ? <div className='mr-2'>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' /> <span>50</span>
                                                                    </div> : <div className='mr-2'>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={Circlelike} alt='shareIcon' /> <span>49</span>
                                                                    </div>
                                                                }
                                                            </li>
                                                            <li >
                                                                <div className='mx-2'>
                                                                    <img style={{ cursor: 'pointer' }} width={35} src={CommentLatest} alt='shareIcon' /> <span> 25</span>
                                                                </div>
                                                            </li>
                                                            <li >
                                                                <div className='ml-2'>
                                                                    <img width={35} style={{ cursor: 'pointer' }} onClick={toggleForward} src={ShareLatest} alt='shareIcon' /> <span> 25</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="comment-section">
                                                        <div className="comments d-block scrollForSharePodcast">
                                                            <div className="main-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h4>Pabelo Mukrani</h4>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                        </h5>
                                                                        <ul className="comment-option">
                                                                            <li><Link to="#">like (5)</Link></li>
                                                                            <li onClick={() => SetComment(!comment)}><Link to="#">reply</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {comment ?
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                            </div>
                                                            <div className="main-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h4>Pabelo Mukrani</h4>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                        </h5>
                                                                        <ul className="comment-option">
                                                                            <li><Link to="#">like (5)</Link></li>
                                                                            <li onClick={() => SetComment(!comment)}><Link to="#">reply</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {comment ?
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                            </div>
                                                        </div>
                                                        <div className="reply">
                                                            <div className="search-input input-style input-lg icon-right">
                                                                <Input type="text" className="form-control emojiPicker"
                                                                    placeholder="Comment...." />
                                                                <Link to="#">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                                </Link>
                                                                {/* <div className='Media_Modal_send_Icon'>
                                                                        <img src={Snd} width={14} height={14} alt="snd" />
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }
    {/* ------------------------------------Review Report modal start--------------------------------------------------------- */ }
    const ReportModal = () => {
        return (
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about how you feel and help us to <br /> make SocioMee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }
    {/*  ---------------------- Fumction for Music Bar --------------------  */ }
    const handleMusicBar = () => {
        setMusicBar(true);
    }
    {/*  ---------------------- Main return block --------------------  */ }
    return (
        <div className='mt-1'>
            <ShareModal />
            <DeleteModal />
            <CommentModal />
            <DownloadModal />
            <ReportModal />
            {/*  ---------------------- Header --------------------  */}
            <Row className='rowForrevert'>
                <Col xl='12'>
                    <div className='d-flex justify-content-between m-2'>
                        <h3><strong>{props.tittle}</strong></h3>
                    </div>
                </Col>
            </Row>
            {/*  ---------------------- Dyanmic Card handeled by map function --------------------  */}
            {/* <h2 style={{fontWeight:'bold'}}>TV & Film</h2> */}
            {listView ?
                <Row className='Events_PeopleThisGrid mt-2' style={{ paddingRight: '14px' }}>
                    {
                        cardData.map((item, index) => {
                            return (
                                <Col xs='6' xl='3' className='w-100' >
                                    <div key={index} onMouseOver={() => { sethover(index + 1) }} onMouseLeave={() => { sethover(0) }} >
                                        <Card className='w-100 podcastForcard'>
                                            <div className='headerForpodcast p-2 simple-linear'>
                                                <div className='d-flex align-items-center justify-content-start' >
                                                    <img src={Mic} width={25} height={28} alt='micicon' />
                                                    {numFormatter(item.viewerCount)}
                                                </div>
                                                <div className='moreoverFormargin' >
                                                    {/* <MoreVertical size={22} color='#fff' /> */}
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                            <MoreVertical size={22} color='#fff' />
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ zIndex: '100', left: '-10rem', minWidth: 'fit-content' }}>
                                                            <DropdownItem tag='a' onClick={handleMusicBar} ><Play size={15} /> Join</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'> <Copy size={15} /> Copy  link</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a' onClick={toggleForward} ><Send size={15} /> Share podcast </DropdownItem>
                                                            {/* <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'><Edit size={20} />
                                                                <Link to='/Podcast/UploadRecordedPodacast' className='text-dark' > Edit podcast </Link>
                                                            </DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash2 size={20} />Delete podcast</DropdownItem> */}
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                            <div className='position-relative' style={{ position: 'relative' }} >
                                                <NavLink to='/Podcast/View' >
                                                    <img alt="Sample" className='w-100 rounded-top img-fluid' src="https://picsum.photos/300/200" />
                                                    <div className={`podcastcenterForstyle `}
                                                    >
                                                        <img className={`${hover === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                    </div>
                                                </NavLink>
                                                {item.episodType === 'paid' ? <div className='paidTagForpostition'>
                                                    <img alt="paid" className='img-fluid' src={PaidTag} />
                                                </div> : ''}
                                            </div>
                                            {/* <div className={`position-relative`}
                                            >
                                                <NavLink to={item.seires === true ? '/YourSeries' : '/Podcast/view'} onClick={handleMusicBar}  >
                                                    <div className='simple-linear'><img alt="Sample" className='w-100 rounded-top img-fluid' style={{ zIndex: '-1' }} src="https://picsum.photos/300/200" /></div>
                                                    <div className={`podcastcenterForstyle `}
                                                    >
                                                        <img className={`${hover === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                    </div>
                                                </NavLink>
                                                {item.episodType === 'paid' ? <div className='paidTagForpostition'>
                                                    <img alt="paid" className='img-fluid' src={PaidTag} />
                                                </div> : ''}
                                            </div> */}
                                            <CardBody>
                                                <CardTitle tag="h3">
                                                    {item.cardTittle}
                                                </CardTitle>
                                                <CardSubtitle className="my-2">
                                                    <Badge className='text-secondary' color="light" pill>
                                                        {props.tittle}
                                                    </Badge>
                                                </CardSubtitle>
                                                <CardText className='text-muted'>
                                                    Series-{item.seriesCount} <Circle strokeWidth={0} size={8} fill={'#D9D9D9'} /> Total Episodes-{item.totalEpisodeCount}
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                : <Row className='rem-mob-view '>
                    <Col xl='12'>
                        {
                            musicArray.map((item, index) => {
                                return <div key={index} className='MusicList my-3 d-flex justify-content-between border p-1 rounded bg-white align-items-center my-3' >
                                    <div className='d-flex align-items-center'>
                                        <div className='position-relative'>
                                            <div className='d-flex align-items-center pr-3'>
                                                <div className='position-relative'>
                                                    <img width={100} height={100} className='rounded' src='https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg' alt='' />
                                                    <img className='seriesPlayForIconList' width={50} src={PlayIcon} alt='' />
                                                </div>
                                                <div className='ml-3'>
                                                    <h4>Rättegångspodden</h4>
                                                    <p className='text-muted pt-1'>{item.series ? 'Series' : 'Single'} || Ep 01</p>
                                                    {!item.Live ? <p className='text-muted pt-1'>06:00</p> : null}
                                                    <p className='text-muted pt-1'>{`Podcaster : ${item.publisher}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-around align-items-center rem-tab-hide' >
                                        <div className='text-muted'>
                                            <div className='d-flex justify-content-start align-items-center '>
                                                {
                                                    LikeIcon ? <div className='mr-2 text-nowrap'>
                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' /> <span>50</span>
                                                    </div> : <div className='mr-2 text-nowrap'>
                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={Circlelike} alt='shareIcon' /> <span>49</span>
                                                    </div>
                                                }
                                                <div className='mx-2 text-nowrap '>
                                                    <img style={{ cursor: 'pointer' }} onClick={toggleComment} width={35} src={CommentLatest} alt='shareIcon' /> <span> 25</span>
                                                </div>
                                                <div className='mx-2 text-nowrap'>
                                                    <img width={35} style={{ cursor: 'pointer' }} onClick={toggleForward} src={ShareLatest} alt='shareIcon' /> <span> 25</span>
                                                </div>
                                                <div className=' ml-2 d-flex align-items-center '>
                                                    {/* <img src={PodacastMic} /> */}
                                                    <div className='p-2 rounded-circle rem-tab-hide' style={{ background: '#E5E5E5' }}><Headphones size={20} color='#808080' /></div> <span className='rem-tab-hide' style={{ paddingLeft: '0.2rem' }}> 25</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-around ' >
                                        <div className={isPlaying ? '' : 'd-block invisible'}> <img src={musicAnimation} alt='...' width={25} className='' /></div>
                                        <Button color='primary' className='rem-mob-view' onClick={() => setIsPlaying(!isPlaying)} >{!isPlaying ? <><Play fill='#FFFFFF' size={15} /> Play</> : <><Pause fill='#FFFFFF' size={15} /> Pause</>}</Button>
                                    </div>
                                    <div className='d-flex justify-content-around align-items-center'>
                                        <div className='d-flex  justify-content-center rem-tab-hide' >
                                            {item.Live ? <div className=''>
                                                <Badge className='text-white bg-danger rem-tab-hide' pill>
                                                    Live
                                                </Badge>
                                            </div> : <div className='px-4 rem-tab-hide' onClick={toggledownloadpodcast} >
                                                <ArrowDownCircle color='#81C14B' />
                                            </div>}
                                        </div>
                                        <div style={{ paddingTop: '3px' }}>
                                            <UncontrolledButtonDropdown direction='up' className='ml-0' style={{ margin: '0', padding: '0' }} >
                                                <DropdownToggle color='flat-primary'>
                                                    <MoreVertical color='#6E6B7B' />
                                                </DropdownToggle>
                                                <DropdownMenu style={{ left: '-171px' }} className='existingDrop'>
                                                    <DropdownItem tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag='a' onClick={toggledownloadpodcast} ><Download size={15} />&nbsp;Download</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash size={15} />&nbsp;Delete</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem className='toppoddroup'>&nbsp;&nbsp;<Edit size={15} />
                                                        <Link to='/Podcast/UploadRecordedPodacast' className='text-dark ' > Edit podcast </Link>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    {/* <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                    <DropdownItem divider /> */}
                                                    <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag='a' onClick={toggleReport} ><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </Col>
                </Row>}
        </div>
    )
}
export default CommonSeeallpage