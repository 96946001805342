import React, { useEffect, useRef, useState } from 'react';
import { CheckCircle, Copy, Download, Edit, Eye, MessageCircle, MoreVertical, Music, Pause, Play, PlusCircle, Send, Share, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import ShotzVideoButton from '../../../NewComponents/IMG/ShotzVideoButton.svg'
import { ArchivedModal, ShotzPopup } from '../ArchivedModal';
import { ShotzCarouselComponents } from '../shotz_components/ShotzCarouselComponents';
import ShareModal from '../ShotzModal/ShareModal';
import { EditModal } from '../ShotzModal/EditModal';
import like from '../../../NewComponents/SVG/like.svg'
import comment from '../../../NewComponents/SVG/comment.svg'
import share from '../../../NewComponents/SVG/share.svg'
import '../../Connections/Style.css'
import { NavLink } from 'react-router-dom';
import { SingleDeleteModal } from '../ShotzModal/SingleDeleteModal';
import MediaModal from '../../profile-components/MediaModal';

export const ShotzListView = (props) => {
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const dispatch = useDispatch();
    const [Check, setCheck] = useState(false)
    // ----------------- Edit Modal State -----------------
    const [editModalOpen, setEditModalOpen] = useState(false)
    const toggleEditModalOpen = () => {
        setEditModalOpen((prevState) => !prevState)
    }
    //----------------- Download Modal State -----------------
    const [shotzCarouselModal, setShotzCarouselModal] = useState(false)
    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }
    //----------------- Share Modal State  -----------------
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }




    const Shotzdata = [
        {
            id: 1,
            video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        },
        {
            id: 2,
            video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
        },
        {
            id: 3,
            video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
        },
        {
            id: 4,
            video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
        },
        {
            id: 5,
            video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4"
        },
        {
            id: 6,
            video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"
        },
        {
            id: 7,
            video: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4"
        }
    ]

    // const videoRef = useRef(null);
    // const [playing, setPlaying] = useState(false);
    // const [videoTime, setVideoTime] = useState(0);

    // const videoHandler = (control) => {
    //     if (control === "play") {
    //         videoRef.current.play();
    //         setPlaying(true);
    //         var vid = document.getElementById("video1");
    //         setVideoTime(vid.duration);
    //     } else if (control === "pause") {
    //         videoRef.current.pause();
    //         setPlaying(false);
    //     }
    // };

    // const [isPlaying, setIsPlaying] = useState(false);
    // const videoRef = useRef(null);
    // const handlePlayPause = (control) => {
    //     console.log(control)
    //     if (control == "pause") {
    //         videoRef.current.pause();
    //     }
    //     if (control == "play") {
    //         videoRef.current.play();
    //     }
    //     setIsPlaying(!isPlaying);
    // };
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };


    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    useEffect(() => {
        dispatch(loadAllUserFollowers())
        // const input = videoRef.current;
        // input.select();
    }, [])
    return (
        <>
            <Row className='AllTagsThis'>
                {
                    Shotzdata.map((data, index) => {
                        return (
                            <div key={index} className='d-flex justify-content-center'>
                                <Col md='4'>
                                </Col>
                                <Col md='4'>
                                    <div className="media-images-blk shotz-album-block align-items-center p-1" >
                                        <div className="main-shotz-blk-list">
                                            <figure className="shotz-card-hover m-0">
                                                <video width="100%" style={{ zIndex: '0' }} height="100%" className='VideoFitforcover' ref={videoRef} >
                                                    <source src={data.video} type="video/mp4" />
                                                </video>
                                                {/* <Button className='VideoFitzfocus' onClick={handlePlayPause} style={{ zIndex: '1' }}>{isPlaying ? <Pause size={25} /> : <Play size={25} />}</Button> */}
                                                <button className='VideoFitzfocus bg-transparent ghcvewcwevc border-0' style={{ zIndex: '0' }} onClick={handlePlayPause}>{isPlaying ? <Pause size={30} color='white' /> : <Play size={30} color='white' />}</button>
                                                {/* <button onClick={handlePlayPause}>{isPlaying ? 'a' : 'b'}</button> */}
                                                <div className="text-gallery d-flex justify-content-center ">
                                                    {/* {playing ? (
                                                        <div className="controlsIcon--small" onClick={() => videoHandler("pause")}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-24 ih-24"><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg></div>
                                                    ) : (
                                                        <div className="controlsIcon--small" onClick={() => videoHandler("play")}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWdth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-24 ih-24"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg></div>
                                                    )} */}
                                                    <div className="top-gallery-cont d-flex justify-content-beetween align-items-center">
                                                        <img src="assets/images/shotz-icon.png" height={25} width={25} className='img-fluid' />
                                                        <h5 className="text-white font-weight-bold"><Eye size={18} /> 12 K</h5>
                                                    </div>
                                                    <div className="Music-gallery-cont mx-2 py-3 align-items-center justify-content-start">
                                                        <p className='shotz-text'><Music size={20} color='#FFFFFF' /></p>&nbsp;&nbsp;
                                                        <h5 className='text-white mr-2'>Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...</h5>
                                                    </div>
                                                    <div className="bottom-gallery-cont">
                                                        <p className='shotz-text'><img src={like} />30 k</p>
                                                        <p className='shotz-text'><MessageCircle size={20} />40 k</p>
                                                        <p className='shotz-text'><Send size={20} />20 k</p>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                                <Col md='4'>
                                </Col>
                            </div>
                        )
                    })
                }
                {/* <MediaModal /> */}
                {/* ------------- Start Shotz Carousel Modal ------------- */}
                <ShotzCarouselComponents
                    isOpen={shotzCarouselModal}
                    ShotzModalCarousel={toggleShotzCarouselModal}
                />
                {/* ------------- End Shotz Carousel Modal ------------- */}
            </Row>
        </>
    )
}

