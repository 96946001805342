import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import { Circle, Search, Star } from 'react-feather'
import { Input, InputGroup, InputGroupText } from 'reactstrap'

const AccordionBasic = () => {
    const [open, setOpen] = useState('2')

    const toggle = id => {
        open === id ? setOpen() : setOpen(id)
    }

    return (
        <Accordion open={open} toggle={toggle}>
            <AccordionItem>
                <AccordionHeader targetId='1'><h4>Category <Circle fill='#FBB54B' size={7} strokeWidth={0} /></h4></AccordionHeader>
                <AccordionBody accordionId='1' className='p-2'>
                    <InputGroup className=''>
                        <InputGroupText className='bg-white'>
                            <Search size={14} />
                        </InputGroupText>
                        <Input placeholder='Search category...' />
                    </InputGroup>
                    <div className='p-2'>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <p className='ml-2 MarketplaceThislist'>All categories</p>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <p className='ml-2 MarketplaceThislist'>Real Estate</p>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <p className='ml-2 MarketplaceThislist'>Vehicles</p>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <p className='ml-2 MarketplaceThislist'>Electronics</p>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <p className='ml-2 MarketplaceThislist'>Other products</p>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <p className='ml-2 MarketplaceThislist'>Services</p>
                        </div>
                    </div>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId='2'><h4>Sub category <Circle fill='#FBB54B' size={7} strokeWidth={0} /></h4></AccordionHeader>
                <AccordionBody accordionId='2' className='p-2'>
                    <InputGroup className=''>
                        <InputGroupText className='bg-white'>
                            <Search size={14} />
                        </InputGroupText>
                        <Input placeholder='Search Sub category...' />
                    </InputGroup>
                    <div className='p-2'>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>All categories</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Real Estate</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Vehicles</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Electronics</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Other products</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Services</h5>
                        </div>
                    </div>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId='3'><h4>Child category <Circle fill='#FBB54B' size={7} strokeWidth={0} /></h4></AccordionHeader>
                <AccordionBody accordionId='3' className='p-2'>
                    <InputGroup className=''>
                        <InputGroupText className='bg-white'>
                            <Search size={14} />
                        </InputGroupText>
                        <Input placeholder='Search Child category...' />
                    </InputGroup>
                    <div className='p-2'>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>All categories</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Real Estate</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Vehicles</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Electronics</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Other products</h5>
                        </div>
                        <div className='d-flex p-1'>
                            <input type="checkbox" />
                            <h5 className='ml-2 MarketplaceThislist'>Services</h5>
                        </div>
                    </div>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId='5'><h4>Price range <Circle fill='#FBB54B' size={7} strokeWidth={0} /></h4></AccordionHeader>
                <AccordionBody accordionId='5' className='p-2'>
                    <div className='p-2 MarketplaceThislist'>1000 - 1200</div>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    )
}

export default AccordionBasic