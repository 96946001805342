import Chart from 'react-apexcharts'
import { Calendar } from 'react-feather'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'

const DemographicChart = ({ direction }) => {
  const columnColors = {
    series1: '#553C8B',
    series2: '#9EA9F0',
    series3: '#CCC1FF',
    bg: '#FFEAFE'
  }

  const options = {
    chart: {
      height: 400,
      type: 'bar',
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '15%',
        colors: {
          backgroundBarColors: [columnColors.bg, columnColors.bg, columnColors.bg, columnColors.bg, columnColors.bg],
          backgroundBarRadius: 10
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'start'
    },
    colors: [columnColors.series1, columnColors.series2, columnColors.series3],
    stroke: {
      show: true,
      colors: ['transparent']
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: [
        "13-18",
        "19-24",
        "25-31",
        "32-37",
        "38-43",
        "44-49",
        "50-56",
        "57-62",
        "63-68",
        "69+"
      ],
      title: {
        text: 'Age'
      }
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      opposite: direction === 'rtl'
    }
  }

  const series = [
    {
      name: 'Men 100% (1) Cost per result ₹536.12',
      data: [50, 60, 70, 80, 90, 100, 120, 130, 140, 150]
    },
    {
      name: 'Women 0% (1) Cost per result ₹0.00',
      data: [70, 80, 90, 100, 110, 120, 130, 140, 150, 160]
    },
    {
      name: 'Other 0% (1) Cost per result ₹0.00',
      data: [90, 100, 110, 120, 130, 140, 150, 160, 170, 180]
    }
  ]

  return (
    <Card style={{ boxShadow: 'none' }}>
      {/* <CardHeader className='d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-md-center align-items-start'>
        <CardTitle tag='h4'>Data Science</CardTitle>
        <div className='d-flex align-items-center mt-md-0 mt-1'>
          <Calendar size={17} />
         XXXX
        </div>
      </CardHeader> */}
      {/* <CardBody> */}
      <Chart options={options} series={series} type='bar' height={400} />
      {/* </CardBody> */}
    </Card>
  )
}

export default DemographicChart
