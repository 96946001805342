import * as types from "../../Constants/Events/index";
import axios from 'axios';


const upcommingCategory = (upcommingEvent) => ({
    type: types.GET_UPCOMING_EVENTS_BY_USER,
    payload: upcommingEvent,
});

// get all event category
export const upcommingEventCategoryAction = ({day, categoryId, eventLocation, lat, long, radius}) => {
    console.log('day', day)
    return function (dispatch) {
        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
       
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/post/upcomingEvents`,{
                day, categoryId, eventLocation, lat, long, radius
            },{
                headers: { Authorization: `Bearer ${user?.token}` }
            })
                .then((result) => {
                    dispatch(upcommingCategory(result.data.data.successResult.eventCategories ? result.data.data.successResult.eventCategories : result.data.data.successResult.eventCategories))
                }).catch((error) => {
                    console.log(error);
                })
        }
    };
};

