import React from "react";
import { Col, Row } from "reactstrap"

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div>
        <Row>
          <Col xl='3'>
            <img src="assets/images/logo.png" alt="logo" className="img-fluid my-2" style={{ width: '110px' }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ fontSize: '12px' }}>Office 149, 450 South Brand Brooklyn</p>
            <p style={{ fontSize: '12px' }}>San Diego County, CA 91905, USA</p>
            <p style={{ fontSize: '12px' }}>+1 (123) 456 7891, +44 (876) 543 2198</p>
          </Col>
          <Col className='text-right'>
            <h4>Order ID #3492</h4>
            <p style={{ fontSize: '12px' }}>Order date and time</p>
            <p style={{ fontSize: '12px' }}>01-05-2022, 06:00 pm</p>
          </Col>
        </Row>
        <hr />
        {/* ==============================Print screen======================== */}
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="font-weight-bold text-muted">Invoice To:</h4>
            <h4 className="mt-3" style={{ fontSize: '13px' }}>Jason Simpson</h4>
            <div className="text-muted">
              <p style={{ fontSize: '12px' }}>Shelby Company Limited</p>
              <p style={{ fontSize: '12px' }}>Small Heath, B10 0HF, UK</p>
              <p style={{ fontSize: '12px' }}>718-986-6062</p>
              <p style={{ fontSize: '12px' }}>peakyF@gmail.com</p>
            </div>
          </div>
          <div>
            <h4 className="font-weight-bold text-muted">Payment Details:</h4>
            <div className="text-muted mt-3">
              <p style={{ fontSize: '12px' }}>Total Bill Amount:  1690 USD</p>
              <p style={{ fontSize: '12px' }}>Payment Status: <span className="text-primary"><b>Complete</b></span></p>
              <p style={{ fontSize: '12px' }}>Amount Paid:  2342 USD</p>
              <p style={{ fontSize: '12px' }}>Bank name:  American Bank</p>
              <p style={{ fontSize: '12px' }}>Country:United States</p>
              <p style={{ fontSize: '12px' }}>IBAN:ETD9547621385 SWIFT code:BR91905</p>
            </div>
          </div>
        </div>
        <Row>
          <Col>
            <div className="d-flex justify-content-between mt-3 bg-light p-2 font-weight-bold text-muted">
              <h4>AD DETAILS</h4>
              <div className="d-flex">
                <h4 style={{ marginRight: '-43px' }}>DURATION</h4>&nbsp;
                <h4 className="ml-5" style={{ marginRight: '-5px' }}>PRICE/RATE</h4>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* ----------------------------add Fresh Ad for Startup------------------------------- */}
            <div className="d-flex justify-content-between mt-3 bg-white p-2 font-weight-bold text-muted">
              <div>
               <h3 style={{ color: 'rgb(255, 159, 67)', fontSize: '13px' }} className="font-weight-bold">#JUD8995</h3>
                <h4>Fresh Ad for Startup</h4>
                <h5 style={{ fontSize: '12px' }}>Target Genders (Males, Females)</h5>
                <h5 style={{ fontSize: '12px' }}>Target Age Range (18-65)</h5>
                <h5 style={{ fontSize: '12px' }}>Target Professions (Students, Telecallers, Self-employed)</h5>
                <h5 style={{ fontSize: '12px' }}>Target Interests (Swimming, Basketball, Volleyball)</h5>
                <h5 style={{ fontSize: '12px' }}>Target Locations (France, Italy, Germany)</h5>
              </div>
              <div className="d-flex mt-4">
                <div className="p-2">
                  <h5 style={{ fontSize: '12px' }}>30 days</h5>
                  <h5 style={{ fontSize: '12px' }}>30 days</h5>
                  <h5 style={{ fontSize: '12px' }}>30 days</h5>
                  <h5 style={{ fontSize: '12px' }}>30 days</h5>
                  <h5 style={{ fontSize: '12px' }}>30 days</h5>
                </div>
                <div className="mt-2">
                  <h5 className="ml-5" style={{ fontSize: '12px' }}>$200.00</h5>
                  <h5 className="ml-5" style={{ fontSize: '12px' }}>$200.00</h5>
                  <h5 className="ml-5" style={{ fontSize: '12px' }}>$200.00</h5>
                  <h5 className="ml-5" style={{ fontSize: '12px' }}>$200.00</h5>
                  <h5 className="ml-5" style={{ fontSize: '12px' }}>$200.00</h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div className="d-flex justify-content-between mt-3 bg-white p-2 font-weight-bold text-muted">
              <div>
                <h4>Salesperson:<span style={{ fontSize: '12px' }}>Tommy Shelby</span></h4>
              </div>
              <div className="d-flex">
                <div>
                  <h5 style={{ fontSize: '12px' }}>Subtotal:</h5>
                  <h5 style={{ fontSize: '12px' }}>Discount:</h5>
                  <h5 style={{ fontSize: '12px' }}>Tax:</h5>
                </div>
                <div>
                  <h5 className="ml-5 font-weight-bold" style={{ fontSize: '12px' }}>$1662</h5>
                  <h5 className="ml-5 font-weight-bold" style={{ fontSize: '12px' }}>$28</h5>
                  <h5 className="ml-5 font-weight-bold" style={{ fontSize: '12px' }}>21%</h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-end bg-white font-weight-bold text-muted">
              <div className="d-flex border-top pt-2 mr-4">
                <div>
                  <h5 style={{ fontSize: '12px' }}>Total:</h5>
                </div>
                <div>
                  <h5 className="ml-5 font-weight-bold" style={{ fontSize: '12px' }}>$1690</h5>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="d-flex align-items-center">
          <span style={{ fontSize: '14px' }}>Print date and time:</span> <p style={{ fontSize: '14px' }}>05-05-2022, 08:00 pm</p>
        </div>
      </div>
    );
  }
}