import React, { Fragment } from 'react'

export const AvatarGroupSm = ({ mutualFriends = [] }) => {
    // Get the top 3 mutual friends
    const topMutualFriends = mutualFriends.slice(0, 3);

    return (
        <Fragment>
            <ul className="matual-friend-blk">
                {topMutualFriends.map((friend, index) => (
                    <li key={index} className="popover-cls d-flex" data-bs-toggle="popover" data-placement="right"
                        data-name={friend?.fullName} data-img={friend?.profileImage}>
                        <img src={friend?.profileImage} className="img-fluid bg-img" style={{ border: '2px solid #FFFFFF' }} alt={friend?.fullName} />
                    </li>
                ))}
            </ul>
        </Fragment>
    );
}
