import { Pagination, Stack } from '@mui/material'
import React from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { AlertTriangle, Eye, Link, MoreHorizontal, Plus, Share2 } from 'react-feather'
import TrendImg1 from '../../../NewComponents/IMG/TrendImg1.svg'
import { useState } from 'react'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { listGroups } from '../DummyArray'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'


const ListGroup = ({ display, space }) => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    {/* --------------------------------------------------------Main return function------------------------------------------------------------------ */ }
    return (
        <div>
            <Row className=''>
                <Col md='12' className='' >
                    {/* --------------------------------------------------------List View Section Part 1------------------------------------------------------------------ */}
                    {listGroups.map((data, index) => (

                        <div className='border rounded my-2 on-hover-bg ' key={index} >
                            {/* <div color={`primary ${display}`} style={{ position: 'absolute', top: '8px', left: '4px', zIndex: '5', fontSize: '12px' }} className='rotated-text' ># {data.trendingNumber}</div> */}
                            {/* <div className='d-flex ml-3 mt-2'> */}
                            {/* <Badge color={`light-primary ${display}`}>
                                    Trending #{data.trendingNumber}
                                </Badge>
                                <Badge color='primary' pill className={space}>
                                    Top #{data.topsNumber}
                                </Badge> */}
                            {/* </div> */}

                            <Row className='d-flex align-items-center p-1'>

                                <Col xl='6' className='d-flex'>
                                    <div className='d-flex align-items-center pl-1'>
                                        <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center rem-mob-view'>
                                            <p className='PilesThistags text-center'>Top</p>
                                            <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                        </div>

                                        <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                            <p className='PilesThistags text-center'>Trending</p>
                                            <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center ml-5'>
                                        {/* <div className='right-triangle' style={{ position: 'absolute', top: '0px', zIndex: '4', left: '10px' }} ></div> */}
                                        {/* <img src={TrendImg1} width={70} className='m-3 ml-2 rounded-circle' /> */}
                                        <img src={TrendImg1} width={70} height={70} className=' m-3 ml-2 rounded ArticleShowdo' alt='' />
                                        <div>
                                            <NavLink to='/Group' style={{ color: 'black' }} ><h4 className=''>Georgia Purchasing Group</h4></NavLink>
                                            <p className='BizPageCategory match'>Group category</p>
                                            <div className='d-flex'>
                                                <p className='text-muted mt-1'>This text is a one line of the<br />
                                                    description  group</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='6'>
                                    <div className='d-flex justify-content-between mr-2'>

                                        <div className='text-left mt-3' >
                                            <div className='d-flex' style={{ marginLeft: "80px" }}>
                                                <p className='align-self-center BizPageCategory count'></p>
                                                <p className='align-self-center text-muted ml-1'><span className='align-self-center BizPageCategory count'>150k&nbsp;</span>Members<br></br><span className='align-self-center BizPageCategory count'> 23 </span>posts</p>
                                            </div>
                                            <div>
                                            </div>
                                            {/* <div >
                                                <p className='text-primary'> Top #{data.topsNumber}</p>
                                                <p className='text-primary'> Trending #{data.trendingNumber}</p>
                                            </div> */}
                                        </div>
                                        <div className='align-self-center' style={{ marginLeft: "2rem" }}>
                                            <Button outline color='primary' className='ml-5' >
                                                <span className=''> <Plus size={18} style={{marginLeft:'-5px'}}/>  &nbsp;&nbsp;Join Group</span>
                                            </Button>
                                        </div>
                                        <div className='align-self-center'>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color='flat-primary ml-5' >
                                                    <MoreHorizontal color='#999999' size={19} />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific ' style={{ marginLeft: '4rem' }}>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Post</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;&nbsp;View Profile</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}

                    {/* <div className='border rounded mt-3'>
                        <div className='d-flex ml-3 mt-2'>
                            <Badge color={`light-primary ${display}`}>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className={space}>
                                Top #96
                            </Badge>
                        </div>
                        <Row className='d-flex align-items-center'>
                            <Col xl='6'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src={TrendImg1} width={70} className='m-3 ml-2 rounded-circle' />
                                    <div>
                                        <b className=''>Georgia Purchasing Group</b>
                                        <p className='BizPageCategory'>Group category</p>
                                        <div className='d-flex'>
                                            <p className='text-muted mt-1'>This text is a one line description of the above group</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='6'>
                                <div className='d-flex justify-content-between mr-2'>

                                    <div className='d-flex'>
                                        <b className='align-self-center'>150k</b>
                                        <p className='align-self-center text-muted ml-1'>Members</p>
                                    </div>
                                    <div className='align-self-center'>
                                        <Button color='success' outline className='px-4' >
                                            <span className='ml-1'>Requsted</span>
                                        </Button>
                                    </div>
                                    <div className='align-self-center'>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color='flat-primary' >
                                                <MoreHorizontal color='black' />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div> */}
                    {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}

                    {/* <div className='d-flex justify-content-center p-2'>
                        <Stack spacing={2}>
                            <Pagination
                                count={10}
                                color='primary'
                                size="large" />
                        </Stack>
                    </div> */}

                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    {/* <p>Total Groups Count : <span className='text-primary' >{listGroups.length}</span></p> */}
                                    <p>Showing 1 to {listGroups.length} of 200 entries</p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex  ml-3 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>


            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}
        </div>
    )
}

export default ListGroup