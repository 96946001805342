import React, { Fragment, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Input, Row, Button } from 'reactstrap';
// import indiaflag from "./../public/assets/images/flags/hi_IN.png"
import indiaflag from "./Icons/flags/hi_IN.png"
import CountryCodeModal from './Modal-Components/CountryCodeModal';
import { User } from 'react-feather';
import OffCanvasLanguages from './Modal-Components/OffCanvasLanguages';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';

const ForgotPasswordPage = () => {


    const navigate = useNavigate()


    // login button Disable 
    const [CodeModal, setCodeModal] = useState(false)

    const [userData, setUserData] = useState("")

    const ToggleCountryCode = () => setCodeModal(!CodeModal)


    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")




    // for language modal canvas controller 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }









    return (
        // Main return of Forgot Password 
        <Fragment>
            {/* Modal's Call Country Code Modal and Language Modal */}
            <CountryCodeModal modal={CodeModal} toggle={ToggleCountryCode} />
            <OffCanvasLanguages show={show} handleClose={handleClose} />
            {/* language Modal   */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />

            {/* whole Forgot Page section  */}
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row>
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec">
                                    <NavLink to="/">
                                        <img src="/assets/images/logo.png" alt="logo" className="img-fluid" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="login-form">

                                {/* --------------------------------- Stepper ------------------------------------------------- */}
                                <LoginSignUpStepper steps={3} active={1} />
                                <div>
                                    <div className="login-title">
                                        <h2>Forgot Password</h2>
                                    </div>

                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                {/* Input Form  */}
                                                <div className="form-group">
                                                    {/* <label>Enter your Mobile Number</label> */}
                                                    <div className={`input-block ${userData.length === 10 && 'border border-primary rounded-3'}`}>
                                                        <div className="phone-with-code">
                                                            <div className="phone-code-dropdown">
                                                                {/* ----- Country Code Section ---------- */}
                                                                <Link className="d-flex" onClick={ToggleCountryCode}>
                                                                    <img src={indiaflag} width='18' height='12' alt="flag" />
                                                                    +{91}
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-14 phone-code-arrow "><polyline points="6 9 12 15 18 9"></polyline></svg>
                                                                </Link>
                                                            </div>
                                                            <Input type="tel" className="form-control w-75 pr-5" placeholder="Enter Mobile Number" name="mobile" value={userData} onChange={(e) => { setUserData(e.target.value) }} maxLength={10} />
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#B9B9C3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="input-icon iw-20 ih-20"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                            <User color='#B9B9C3' className='input-icon iw-20 ih-20' size={16} />
                                                        </div>
                                                    </div>

                                                    {/* if want to show error use this below tag or classes or this  */}
                                                    {/* <p className="error-input-msg text-center" >Invalid Mobile Number</p> */}
                                                </div>
                                                <p className="notimsg-blk">When you will click on continue,  you will receive a verification code on the mobile number that you have entered.</p>
                                                <div className="btn-section">
                                                    <Button disabled={userData.length !== 10} onClick={() => { navigate("/forgotverify") }} color='primary' >SEND OTP</Button>
                                                </div>
                                            </form>
                                            <div className="connect-with">
                                                <div className="no-account-blk"><p>Don't have an account? <NavLink to="/signuppage">Create New Account</NavLink></p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Row>
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                {/* --------------------------- Labnguages------------------------------ */}
                                {["English", "Hindi", "Deutsch"].map((item, index) => (
                                    <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                ))}
                            </span>
                            <button onClick={LanguageModalToggle} >
                                ...More
                            </button>
                        </div>
                    </div >
                </div>
            </section>
        </Fragment >
    )
}

export default ForgotPasswordPage
