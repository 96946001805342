import PenIcon from '../../NewComponents/IMG/PenIcon.svg'
import Throught from '../../NewComponents/IMG/ThroughtIcon.svg'
import GoLive from '../../NewComponents/IMG/GoLiveIcon.svg'
import Podcast from '../../NewComponents/IMG/PodcastIconS.svg'
import Articles from '../../NewComponents/IMG/ArticleIcon.svg'
import Recommendation from '../../NewComponents/IMG/RecommendationIcon.svg'
import Alert from '../../NewComponents/IMG/ThreatIcon.svg'
import Poll from '../../NewComponents/IMG/BPollIcon.svg'
import Sell from '../../NewComponents/IMG/SellIcon.svg'
import Event from '../../NewComponents/IMG/EventIcon.svg'
import UserImg from '../../NewComponents/IMG/img.svg'


export const Profession = [
    'Profession -1',
    'Profession -2',
    'Profession -3',
    'Profession -4',
    'Profession -5',
    'Profession -6',
    'Profession -7'
];

export const Locations = [
    { label: 'Lucknow, India' },
    { label: 'Lucknow, India, UP' }
]

export const names = [
    {
        name: 'Oliver Hansen',
        category: 'SocioMate'
    },
    {
        name: 'Oliver Hansen',
        category: 'SocioMate'
    },
    {
        name: 'Oliver Hansen',
        category: 'SocioMate'
    }
];

export const postData = [
    {
        id: '1',
        img: PenIcon,
        postType: 'Text',
        tab: true
    },
    {
        id: '2',
        img: Throught,
        postType: 'Throught',
        tab: true

    },
    {
        id: '3',
        img: GoLive,
        postType: 'GoLive',
        tab: false
    },
    {
        id: '4',
        img: Event,
        postType: 'Event',
        tab: true
    },
    {
        id: '5',
        img: Podcast,
        postType: 'Podcast',
        tab: false
    },
    {
        id: '6',
        img: Articles,
        postType: 'Articles',
        tab: false
    },
    {
        id: '7',
        img: Recommendation,
        postType: 'Recommendation',
        tab: true
    },
    {
        id: '8',
        img: Alert,
        postType: 'Alert',
        tab: true
    },
    {
        id: '9',
        img: Poll,
        postType: 'Poll',
        tab: true
    },
    {
        id: '10',
        img: Sell,
        postType: 'Sell',
        tab: false
    },
]

export const emoteData = [
    {
        img: 'https://www.pngfind.com/pngs/m/35-352574_download-svg-download-png-google-laughing-emoji-transparent.png'
    },
    {
        img: 'assets/images/story-3.jpg'
    },
    {
        img: 'https://www.pngfind.com/pngs/m/21-215575_download-svg-download-png-hugging-emoji-transparent-png.png'
    },
    {
        img: 'assets/images/story-4.jpg'
    }
]

export const filterimg = [
    {
        data: 'https://www.pngfind.com/pngs/m/232-2325702_picture-free-shape-matters-blur-filter-png-transparent.png'
    },
    {
        data: 'https://i.stack.imgur.com/PEnJm.png'
    },
    {
        data: 'https://i.stack.imgur.com/PEnJm.png'
    },
    {
        data: 'https://i.stack.imgur.com/PEnJm.png'
    },
    {
        data: 'https://i.stack.imgur.com/PEnJm.png'
    },
    {
        data: 'https://i.stack.imgur.com/PEnJm.png'
    },
    {
        data: 'https://i.stack.imgur.com/PEnJm.png'
    }
]

export const ForwardArrayData = [
    {
        id: "1",
        img: UserImg,
        name: "Robert Fox",
        userName: "abriella-bond123"
    },
    {
        id: "2",
        img: UserImg,
        name: "Darrell Steward",
        userName: "abriella-bond123"
    },
    {
        id: "3",
        img: UserImg,
        name: "Robert Fox",
        userName: "abriella-bond123"
    },
    {
        id: "4",
        img: UserImg,
        name: "Ralph Edwards",
        userName: "abriella-bond123"
    },
    {
        id: "5",
        img: UserImg,
        name: "Savannah Nguyen",
        userName: "abriella-bond123"
    },
    {
        id: "6",
        img: UserImg,
        name: "Eleanor Pena",
        userName: "abriella-bond123"
    },
    {
        id: "7",
        img: UserImg,
        name: "Darlene Robertson",
        userName: "abriella-bond123"
    },
    {
        id: "8",
        img: UserImg,
        name: "Bessie Cooper",
        userName: "abriella-bond123"
    }
]