import { Rating, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Fragment } from 'react'
import { BellOff,Bell, Edit2, MoreHorizontal, PhoneCall, Plus, Send,Sliders,Copy,Users } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import Pin1 from '../Img/pin1.png'
import Pin from '../../group-components/Img/pin.png'
import Msg from "../Img/msg.png"
import { useEffect } from 'react'
import { Checkbox, Pagination,PaginationItem } from "@mui/material";
import axios from 'axios'
import { Card,
    Modal,
    ModalBody,
    ModalHeader} from 'reactstrap';
    import {
        DropdownItem,
        DropdownMenu,
        DropdownToggle,
        UncontrolledButtonDropdown,
        CustomInput
      } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserOwnFollowedBizPageReducerAction ,bizPageNotificationAction,bizPinnedAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import { SweetErrorAlert } from '../../group-components/AllGroupsSections/SweetErrorAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Globe,Lock } from 'react-feather'
import SvgPin from '../../biz-components/Img/SvgPin.svg'

const BizFollowedCard = (props) => {
    let dispatch = useDispatch();


    const getFolloweddBusinessPage = useSelector((state) => { return state.getAllUserOwnFollowedBizPageData });
    const { loading: loadingFollowedPage, error: errorFollowedPage, result: resultFollowedPage } = getFolloweddBusinessPage
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const [pageIndex,setPageIndex]=useState(0);
    const [pageSize,setPageSize]=useState(10);
    const [followerData,setFollowerData]=useState([]);
    const [followingData,setFollowingData]=useState([]);
    const [error,setError]=useState('');
    const [shareOpen, setShareOpen] = useState(false);
    const [searchKey,setSearchKey]=useState('');
    const [count,setCount]=useState(0);
    const [share,setShare]=useState(false)
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                        { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setFollowerData(response.data.data.successResult.rows);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [searchKey,pageIndex,pageSize]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                        { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setFollowingData(response.data.data.successResult.rows);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [searchKey,pageIndex,pageSize]);

    const copyPhone =(e,contact)=> {
        e.stopPropagation();
        if(contact==null || contact==''){
            SweetErrorAlert("No contact available")
        }else{
            navigator.clipboard.writeText(contact)
            sweetAlertConfirmation("Contact copied to clipboard")
        }
        
    }

    const handleRedirect = () => {
        window.location.href = 'https://msgmee.com/';
      };

      const toggleShareOpen = (e) => {
        e.stopPropagation();
        setShareOpen((prevState) => !prevState);
    };

    const handleChange=(e)=>{
        setSearchKey(e.target.value);
      }

      const sharePost = () => {
        sweetAlertConfirmation("Shared successfully")
        setShareOpen((prevState) => !prevState);
      };

      
  const shareAll = () => {
    sweetAlertConfirmation("Shared successfully")
    setShareOpen((prevState) => !prevState);
  };

  const editPage = () => {
    sweetAlertConfirmation("Edit feature is in progress")
}

const offNotification = (bpId) => {
  dispatch(bizPageNotificationAction(bpId, 0))
  setNotification(true)
  }

  const onNotification  = (bpId) => {
    dispatch(bizPageNotificationAction(bpId, 1))
    setNotification(true)
    }

    const pinTheBizPage  = (bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion) => {
      dispatch(bizPinnedAction(bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion, 1))
      setNotification(true)
      }

  const unPinTheBizPage  = (bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion) => {
      dispatch(bizPinnedAction(bpId,allowQuestion,allowMessage,visibility,allowPageSuggestion, 0))
      setNotification(true)
      }


      const pageCount = Math.ceil(props?.count/12);
      const isPrevDisabled = props?.pageIndex1 <= 0;
      const isNextDisabled = props?.pageIndex1 >= pageCount - 1;
      
        const handlePageChange = (event, value) => {
          if (value - 1 === props?.pageIndex1) return;
      
          props?.setPageIndex1(value - 1);
        };
      
      
      const BasicsPagination = () => {
        return (
          <>
            <Stack>
              <Pagination
                   count={pageCount}
                   page={props?.pageIndex1 + 1}
                   onChange={handlePageChange}
                   className="PaginationCounts"
                   renderItem={(item) => (
                     <PaginationItem
                       {...item}
                       disabled={
                         (item.type === 'previous' && isPrevDisabled) ||
                         (item.type === 'next' && isNextDisabled)
                       }
                     />
                   )}
              ></Pagination>
            </Stack>
          </>
        );
      };

      const filteredData = () => {
        return props?.categoryId
          ? props?.data.filter((item) => item.bpCategoryId === props?.categoryId)
          : props?.data;
      };
      
      const dataList=filteredData();

      const postCopyLink = async (id) => {
        const newCopyLink = `${window.location.origin}/BizDetail/${id}`;
        await navigator.clipboard.writeText(newCopyLink);
        sweetAlertConfirmation(`Link Copied to clipBoard`);
      };

    return (
        <Fragment>
            <Row className="mt-3">
                {
                    dataList?.map((data, index) => (
                      <Fragment>
                        <Col lg="3" className="mb-4">
                            <div className="BizPage_Card_section">
                                <div className="BizPage_option_menu">
                                    <MoreHorizontal size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                    <div className="dropdown-menu dropdown-menu-bottom custom-dropdown drop-menu-gal w-50 MyArticle">
                                        <ul>
                                        <li
                                              onClick={(e) => toggleShareOpen(e, "share")}
                                            >
                                              <Link className='Biz-OPTION_menu' >
                                              <Send size={15}/>
                                              <span>Share page</span>
                                              </Link>
                                            </li>
                                            {/* <li onClick={editPage}>
                                                <Link to="#"><Edit2 size={15} color="#979797" />Edit page </Link>
                                            </li> */}
                                            <li>
                                            {
                                              data.allowNotification === 1 ? (
                                                  <li
                                                    onClick={() => offNotification(data.id)}
                                                  >
                                                    <Link  className='Biz-OPTION_menu' >
                                                    <BellOff size={15}/>
                                                    <span>Turn off notification</span>
                                                    </Link>
                                                  </li>
                                                ) : (
                                                  <li
                                                    onClick={() => onNotification(data.id)}
                                                  >
                                                    <Link  className='Biz-OPTION_menu' >
                                                    <Bell size={15}/>
                                                    <span>Turn on notification</span>
                                                    </Link>
                                                  </li>
                                                )}
                                            </li>
                                            <li onClick={(e)=>toggleShareOpen(e,"invite")}>
                                              <Link className='Biz-OPTION_menu'><Users size={15} color="#4D4D4D" />Invite people </Link>
                                            </li>  
                                            <li>
                                                <Link className='Biz-OPTION_menu' to="/CreateBizPage"><Plus size={15}/>Create new page </Link>
                                            </li>
                                            <li>
                                            { data.isPinned === 0 ? 
                                                    <li onClick={() => {pinTheBizPage(data.id, data.allowQuestion, data.allowMessage, data.visibility, data.allowPageSuggestion)}}>
                                                        <Link className='Biz-OPTION_menu'><img src={SvgPin} alt="Pin" /><span>Pin this biz page</span></Link>
                                                    </li>
                                                :  <li onClick={() => {unPinTheBizPage(data.id, data.allowQuestion, data.allowMessage, data.visibility, data.allowPageSuggestion)}}>
                                                        <Link className='Biz-OPTION_menu'><img src={SvgPin} alt="UnPin"/><span>Unpin this biz page</span></Link>
                                                    </li>
                                                }
                                            </li>
                                            <li onClick={(e)=>postCopyLink(data.id)}>
                                                  <Link className='Biz-OPTION_menu'><Copy size={15} color="#4D4D4D" />Copy Link</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <NavLink to="/BizDetail" className="bizpagelink-blk"><img src={data.coverImageUrl} className="img-fluid group-img" />
                                    <div className="group-adminprofile-blk"><img src={data.logo} className='rounded-circle' /></div>
                                </NavLink>
                                <div className="group-content">
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h4 className='CardTextHeading'>{data.name.substring(0, 20)}{data.name.length >= 15 && ' ...'}</h4>&nbsp; &nbsp;{data.isPinned ?
                                                <>
                                                   <span><img src={Pin} color="#979797" /></span>
                                              </> : null
                                        }
                                    </div>
                                    <div className="Suggestmember-blk text-center">
                                            <span>{data.categoryName.substring(0, 20)}{data.categoryName.length >= 15 && ' ...'} </span>&nbsp; &nbsp;{data.isPrivate ?
                                              <>
                                                 <span><Lock color='#4D4D4D' size={12} />&nbsp; Private</span>
                                              </> : <><span> <Globe color='#4D4D4D' size={12} />&nbsp; Public</span></>
                                            }
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <p>{data.email||"No email available"}</p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Stack spacing={1}>
                                            <Rating name="read-only" defaultValue={data.averageRating||0} readOnly />
                                        </Stack>
                                    </div>
                                    <div className='d-flex justify-content-around text-center mt-2' >
                                        <div>
                                            <h2>{data.postCount}</h2>
                                            <p className='text-muted'>Post</p>
                                        </div>
                                        <div>
                                            <h2>{data.totalFollowers}</h2>
                                            <p className='text-muted'>Followers</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-around mt-2'>
                                        <Button color="primary" outline onClick={(e)=>copyPhone(e,data.defaultContact)}>
                                            <PhoneCall size={15} />&nbsp; Call
                                        </Button>
                                        <Button color="primary" outline onClick={(e)=>handleRedirect(e)}>
                                            <img src={Msg} alt="msg" />&nbsp;Message
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                      </Fragment>
                    ))
                }
                <div className="d-flex justify-content-center">
                        <BasicsPagination />
                      </div>
                      <br/>
            </Row>
            <div className="vertically-centered-modal">
          {shareOpen !== false && (
            <Modal
              isOpen={shareOpen}
              toggle={toggleShareOpen}
              className="modal-dialog-centered"
            >
              <div className="w-100 border-bottom">
                <div className=" mx-3 py-3  d-flex justify-content-between Heading_Invite_Section">
                  <h4>Share People</h4>
                  <span style={{ cursor: "pointer" }} onClick={sharePost}>
                  </span>
                </div>
              </div>
              <ModalBody>
                <Row className="mt-2">
                  <Col md="8" className="Like_search">
                    <div className="profile-search-blk w-100 ">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="search-svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Search..."
                        value={searchKey}
                        data-bs-toggle="modal"
                        data-bs-target="#peopleSearch"
                        autoFocus
                        onChange={handleChange}
                        // value={searchTerm}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="d-flex justify-content-end">
                    <UncontrolledButtonDropdown color="secondary" outline>
                      <DropdownToggle
                        outline
                        className="border border-muted ml-3 "
                      >
                        <Sliders className="Slider_Filter" size={12} />
                        &nbsp;&nbsp;Filter
                      </DropdownToggle>
                      <DropdownMenu className="DropDown_Group_item">

                        {/* <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(1); }}>
                         SocioMate
                        </DropdownItem> */}
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(0); }}>
                         Followers
                        </DropdownItem>
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(2); }}>
                         Followings
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Row className="mt-3" scrollable>
                       {count == 0 && followerData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index} scrollable>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                        {count == 2 && followingData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index} scrollable>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    </Row>
                  <Col
                    md="6"
                    className="d-flex justify-content-end SubHeading_Invite_Section"
                  >
                    <p style={{ cursor: "pointer" }} onClick={shareAll}>
                    Share All
                    </p>
                        </Col>
                        </Row>
                    </ModalBody>
                    </Modal>
                    )}
                </div>
        </Fragment>
    )
}

export default BizFollowedCard
