import React, { Fragment } from 'react'
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'

const ReportShotzModal = (props) => {
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal scrollable isOpen={props.isOpen} toggle={props.reportModalOpen} className='modal-dialog-centered'>
                    <ModalHeader onClick={props.reportModalOpen} className="Biz_Rating_SECTION">
                        <h3>Report a Shotz</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className='Modal_Body_Section'>
                            <p className='py-1'>Choose the option that best describes your connection status to report it</p>
                        </div>
                        {
                            ReportDataInfo.map((data) => (
                                <div className='d-flex justify-content-between mt-4 Report_Section'>
                                    <h4>{data.name}</h4>
                                    <div>
                                        <Input
                                            name="radio1"
                                            type="radio"
                                            className="Check"
                                        />
                                        {' '}
                                    </div>
                                </div>
                            ))
                        }
                        <div className='d-flex justify-content-center mt-3'>
                            <Input
                                id="ReportName"
                                name="ReportName"
                                className='border'
                                placeholder="Write a comment..."
                                type="Report"
                            />
                        </div>
                        <hr className='w-100 text-muted' />
                        <div className='text-center'>
                            <Button className="ml-2" color="primary" onClick={props.reportModalOpen} >Submit</Button>
                            <Button onClick={props.reportModalOpen} outline className="ml-2" >Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>
            }
        </Fragment>
    )
}

export default ReportShotzModal