import React, { useEffect,useRef } from "react";
import { Fragment } from "react";
import {
  Edit2,
  EyeOff,
  Link2,
  MoreVertical,
  Navigation,
  Send,
  Sliders,
  Trash2,
  XCircle
} from "react-feather";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import Like from "./Img/Like.svg";
import Snd from "./Img/snd.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  postAddAllCommentsByPostIdAction,
  getAllCommentsByPostIdAction,
  getAllLikesByPostIdAction,
  getUserFollowerAction,
  postReShareAction,
  getAllShares,
  deletePostAction
} from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import ShowHidePost from "./ShowHidePost";
import { sweetAlertConfirmation } from "../group-components/AllGroupsSections/SweetAlert";
import { Checkbox } from "@mui/material";
import { SweetErrorAlert } from "../group-components/AllGroupsSections/SweetErrorAlert";
import axios from "axios";
import { Carousel } from 'react-bootstrap';
import Emoji from "../Social-feed/Emoji";

// let gallerySettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 3000,
// };

const MediaModal = (props) => {
  const gallerySettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const dispatch = useDispatch();
  const [postId, setPostId] = useState('');
  const [comment, setComment] = useState("");
  const [subComment,setSubComment]=useState("");
  const [replyComment, setReplyComment] = useState("none");
  const [commentId, setReplyCommentId] = useState('');
  const [isSubComment, setIsSubComment] = useState('');
  let [copyLink, setCopyLink] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectAllImage, setSelectAllImage] = useState();
  let [btnDisable, setBtnDisable] = useState("disabled");
  const [friendsId, setFriendsId] = useState();
  const [shareOpen, setShareOpen] = useState(false);
  const [apiRoute, setApiRoute] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [caption, setCaption] = useState([]);
  const [connectionId, setConnectionId] = useState([]);
  const [allowComments, setAllowComments] = useState([]);
  const [locationLAT, setLocationLat] = useState([]);
  const [locationLONG, setLocationLong] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [subHeading, setSubHeading] = useState("SocioMate");
  const [pageIndex1, setPageIndex1] = useState(0);
  const [pageSize,setPageSize]=useState(10);
  const [liked, setLiked] = useState(false);
  const [liked1, setLiked1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commentsData,setCommentsData]=useState([]);
  const [error,setError]=useState('');
  const [commentOpen,setCommentOpen]=useState(false);
  const [searchKey,setSearchKey]=useState('');
  const [count,setCount]=useState(0);
  // const [followerData,setFollowerData] =useState([])
  // const [followingData,setFollowingData] =useState([])
  const [mediaUri,setMediaUri]=useState('');
  const [mediaURL,setMediaURL]=useState(props?.data?.mediaList)
  const carouselRef = useRef(null);


  const followerData=props?.followerData;
  const followingData=props?.followingData;


  useEffect(()=>{
    if(props?.data?.shotzMedia){
      setMediaUri(props?.data?.shotzMedia);
    }else if(props.data?.mediaList && props.data?.mediaList.length>0){
      setMediaURL(props.data?.mediaList);
    }
    
  },[props?.data?.shotzMedia,props?.data?.mediaList])
  
  const getAllCommentsByUserId = useSelector((state) => {
    return state.getAllCommentsByPostIdData;
  });
  const {
    loading: loadingAllComments,
    error: errorAllComments,
    result: AllComments,
  } = getAllCommentsByUserId;

  const postComment = useSelector((state) => {
    return state.postAddAllCommentsByPostIdData;
  });
  const {
    loading: loadingAddComment,
    error: errorAddComment,
    result: addComment,
  } = postComment;

  const allLikesByPostId = useSelector((state) => {
    return state.getAllLikesByPostIdData;
  });
  const {
    loading: loadingallLikes,
    error: errorallLikes,
    result: allLikes,
  } = allLikesByPostId;

  const allSharesByPostId = useSelector((state) => {
    return state.getAllSharesByPostIdData;
  });
  const {
    loading: loadingallShares,
    error: errorallShares,
    result: allShares,
  } = allSharesByPostId;

  // console.log("all comments=======================",AllComments)

  const getAllPostsUser = useSelector((state) => {
    return state.getTextPostsData;
  });
  const {
    loading: loadingPosts,
    error: errorPosts,
    result: resultPosts,
  } = getAllPostsUser;

  const getFollowerConnections = useSelector((state) => {
    return state.getUserFollowerData;
  });
  const {
    loading: loadingConnections,
    error: errorConnections,
    result: resultFollwers,
  } = getFollowerConnections;


  //==================folower following=======================

  // console.log("userId===============================",props?.userId)

  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const state = allLikes
    ?.map((like) => like?.userId)
    .includes(props?.data?.userId);
  // console.log('state',state)
  const allPosts = resultPosts?.filter((post) =>
    post?.mediaList?.find((media) => media?.mediaList !== null)
  );
  const filteredData = resultFollwers?.filter((item) =>
    item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const inviteFriends = (e) => {
    let updatedList = [...friendsId];
    if (e.target.checked) {
      updatedList = [...friendsId, e.target.value];
    } else {
      updatedList.splice(friendsId.indexOf(e.target.value), 1);
    }
    setFriendsId(updatedList);
  };

  const shareAll = () => {
    setSelectAllImage(false);
    setBtnDisable("disabled");
    const frndId = resultFollwers?.map((obj) => obj.id);
    setFriendsId(frndId);
    dispatch(
      postReShareAction(
        postId,
        caption,
        frndId,
        allowComments,
        locationLAT,
        locationLONG
      )
    );
    sweetAlertConfirmation(`Your post shared to everyone`);
    setShareOpen((prevState) => !prevState);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    setSearchKey(event.target.value);
  };

  const sharePost = () => {
    dispatch(
      postReShareAction(
        postId,
        caption,
        friendsId,
        allowComments,
        locationLAT,
        locationLONG
      )
    );
    setShareOpen((prevState) => !prevState);
  };
  const [shotzOpen, setShotzOpen] = useState(false);

  const toggleShareOpen = (
    postId,
    caption,
    allowComments,
    locationLAT,
    locationLONG
  ) => {
    setFriendsId("");
    setApiRoute("/user/getUserFollowers");
    setSortBy("userName");
    dispatch(
      getUserFollowerAction(pageIndex, allPosts?.length, apiRoute, sortBy)
    );
    setPostId(postId);
    setCaption(caption);
    setConnectionId(connectionId);
    setAllowComments(allowComments);
    setLocationLat(locationLAT);
    setLocationLong(locationLONG);
    setShareOpen((prevState) => !prevState);
  };
  const postCopyLink = async (id) => {
    copyLink = `${process.env.REACT_APP_IPURL}/MyMediaListView/${id}`;
    setCopyLink(copyLink);
    navigator.clipboard.writeText(copyLink);
    sweetAlertConfirmation(`Link Copied to clipboard`);
  };

  const toggleDeleteOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteOpen((prevState) => !prevState);
    setSelectAllImage(false);
    setBtnDisable("disabled");
    setFriendsId("");
  };

  useEffect(() => {
    if (addComment || liked1) {
      dispatch(getAllCommentsByPostIdAction(props?.data?.postId, "", "", 0, 20));
    } else {
      // Default case, fetch all comments without specific filters
      dispatch(getAllCommentsByPostIdAction(props?.data?.postId, "", "", 0, 20));
    }
  }, [addComment, liked1, dispatch, props?.data?.postId]);

  useEffect(() => {
    if (props?.data?.postId) {
      dispatch(getAllShares(props?.data?.postId,pageIndex1,pageSize));
    }
  }, [props?.data?.postId, dispatch]);

  useEffect(() => {
    if (props?.data?.postId) {
      dispatch(getAllLikesByPostIdAction(props?.data?.postId));
    }
  }, [props?.data?.postId, dispatch,liked]);

  const sendComment = () => {
    dispatch(
      postAddAllCommentsByPostIdAction(postId, comment, commentId, 0)
    );
    setReplyComment("none");
    setComment("");
  };

  const sendComment1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      postAddAllCommentsByPostIdAction(postId, subComment, commentId, 1)
    );
    setReplyComment("none");
    setSubComment("");
  };

  const replySubComment = (e,id) => {
    e.preventDefault();
    e.stopPropagation();
    setCommentOpen(true);
    setReplyComment((prevId) => (prevId !== id ? id : null));   
    setReplyCommentId(id);
    setIsSubComment(1);
    fetchData(id);
  };

  const setLike= async (e)=>{
    e.stopPropagation();
    e.preventDefault();
    const reactionId = "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305";
    setLoading(true);
  
    try {
      let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
    const userId = user?.id;

    const userHasLiked = allLikes?.some((like) => {
      return like?.userId === userId;
    });

    const requestData = {
      postId: props?.data?.postId
    };

    if (!userHasLiked) {
      requestData.reactionId = reactionId;
    }
  
      const response = await axios.post(`${process.env.REACT_APP_IPURL}/post/setLike`, requestData, {
        headers: { Authorization: `Bearer ${user?.token}` }
      });
  
      if (response.status === 200) {
        setLiked(!liked);
        console.log("Like status updated successfully", response.data);
      } else {
        console.error("Failed to update like status", response.data);
      }
    } catch (error) {
      console.error("Error in API call", error);
    } finally {
      setLoading(false);
    }
  }

  const setLikeComment= async (e,commentId)=>{
    e.stopPropagation();
    e.preventDefault();
    const reactionId = "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305";
    try {
      let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
    const userId = user?.id;

    // Check if the user has already liked the post
    const userHasLiked = AllComments?.some((like) => {

      if(commentId==like.id){
        return like.liked;
      }
    });

   

    const requestData = {
      commentId:commentId
    };

    if (!userHasLiked) {
      requestData.reactionId = reactionId;
    }
  
      const response = await axios.post(`${process.env.REACT_APP_IPURL}/post/setCommentLike`, requestData, {
        headers: { Authorization: `Bearer ${user?.token}` }
      });
  
      if (response.status === 200) {
        setLiked1(!liked1);
        console.log("Like status updated successfully", response.data);
      } else {
        console.error("Failed to update like status", response.data);
      }
    } catch (error) {
      console.error("Error in API call", error);
    } finally {
      setLoading(false);
    }
  }
    const fetchData = async (id) => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/post/getComments`,
                    { commentId:id,postId:props?.data?.postId,searchKey:'',pageIndex:0,pageSize:10 },
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setCommentsData(response.data.data.successResult);
            } else {
                setError('no user found');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    // console.log("shotzMediaURLs====================",props?.data)

    const deleteAllPost = (e) => {
      e.stopPropagation();
      dispatch(deletePostAction([props?.data?.postId])).then(() => {
        // Set deleteCompleted to true after the delete action is completed
        // setDeleteCompleted(true);
      });
      setDeleteOpen((prevState) => !prevState);
    sweetAlertConfirmation('Successfully deleted')
  };
    
 const editPost =(e)=>{
  e.stopPropagation();
  SweetErrorAlert("edit functionality is in progress")
 }

 const handleMediaClick = () => {
  if (carouselRef.current) {
    carouselRef.current.next();
  }
};
  

  return (
    <Fragment>
      <div
        className="modal comment-model"
        id="imageModel"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div>
              <div className="image-gallery">
                <div className="row m-0">
                  <div className="col-xl-9 col-lg-8 p-0">
                  <div className="modal-header">
            <Link to="#" data-bs-dismiss="modal" onClick={() => {
              setMediaUri('');
              setMediaURL([]);
            }}>
              {/* <XCircle size={30} className='close-icon close-icons' data-bs-dismiss="modal" onClick={() => {
              setMediaUri('');
              setMediaURL([]);
            }} style={{ right: '-35vw' }} /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon-light close-btn"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </Link>
          </div>
            <div className="modal-body">
            <div className="carousel-container">
                {props?.data?.shotzMedia && mediaUri ? (
                    <div className="img-part">
                      <video style={{
                                borderRadius: "5px",
                                height: "80vh",
                                width: "110vh",
                                paddingTop: "30px",
                                objectFit: "cover",
                                display: "block"
                              }}
                        controls
                        alt="noPic"
                        onClick={handleMediaClick}
                      >
                        <source src={mediaUri} type="video/mp4" alt="noPic" />
                      </video>
                    </div>
                ) : (
                  <>
                  {mediaURL?.length > 1 ? (
                    <Carousel ref={carouselRef} className="carousel-inner-container" interval={null}>
                    {mediaURL?.map((res, index) =>
                      res.fileType === 'video' ? (
                        <Carousel.Item key={index}>
                          <div className="img-part">
                            <video style={{
                                borderRadius: "5px",
                                height: "80vh",
                                width: "110vh",
                                paddingTop: "30px",
                                objectFit: "cover",
                                display: "block"
                              }}
                              controls
                              alt="noPic"
                              onClick={handleMediaClick}
                            >
                              <source src={res?.fileURL} type="video/mp4" alt="noPic" />
                            </video>
                          </div>
                        </Carousel.Item>
                      ) : (
                        <Carousel.Item key={index}>
                          <div className="img-part">
                            <img style={{
                                borderRadius: "5px",
                                height: "80vh",
                                width: "110vh",
                                paddingTop: "30px",
                                objectFit: "cover",
                                display: "block"
                              }}
                              src={res?.fileURL}
                              alt=""
                              onClick={handleMediaClick}
                            />
                          </div>
                        </Carousel.Item>
                      )
                    )}
                    </Carousel>
                  ):(
                  mediaURL?.map((res, index) =>
                    res.fileType === 'video' ? (
                      
                        <div className="img-part">
                          <video style={{
                                borderRadius: "5px",
                                height: "80vh",
                                width: "110vh",
                                paddingTop: "30px",
                                objectFit: "cover",
                                display: "block"
                              }}
                            controls
                            alt="noPic"
                            onClick={handleMediaClick}
                          >
                            <source src={res?.fileURL} type="video/mp4" alt="noPic" />
                          </video>
                        </div>
                    ) : (
                      
                        <div className="img-part">
                          <img style={{
                                borderRadius: "5px",
                                height: "80vh",
                                width: "110vh",
                                paddingTop: "30px",
                                objectFit: "cover",
                                display: "block"
                              }}
                            src={res?.fileURL}
                            alt=""
                            onClick={handleMediaClick}
                          />
                        </div>
                    )
                  )
                  )}
                  </>
                )}
            </div>
          </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 p-0">
                    <div className="comment-part">
                      <div className="user-detail">
                        <div className="user-media">
                          <div className="media">
                            <Link className="user-img">
                              <img
                                src={props?.data?.profileImageThumb}
                                className="img-fluid bg-img"
                                alt="user"
                              />
                              <span className="available-stats"></span>
                            </Link>
                            <div className="media-body">
                              <h5
                                style={{ filter: "contrast(150%)" }}
                                className="user-name"
                              >
                                {props?.data?.fullName}
                              </h5>
                              <h6 style={{ filter: "contrast(150%)" }}>
                                {props?.data?.userName}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="setting-btn ms-auto setting-dropdown no-bg">
                          <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                            <div
                              data-bs-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <MoreVertical color="#4B4B4B" size={20} />
                            </div>
                            <div
                              className="dropdown-menu dropdown-menu-right custom-dropdown"
                              style={{ minWidth: "200px" }}
                            >
                              <ul>
                                <li style={{ cursor: "pointer" }}>
                                  <ShowHidePost
                                    postId={props?.data?.postId}
                                    hidden={props?.data?.hidden}
                                  />
                                </li>

                                <li
                                  onClick={() => {
                                    postCopyLink(props?.data?.postId);
                                  }}
                                >
                                  <Link to="#">
                                    <Link2 size={15} color="#979797" />{" "}
                                    &nbsp;Copy link
                                  </Link>
                                </li>
                                <li onclick={(e)=>editPost(e)}>
                                  <Link to="#">
                                    <Edit2 size={15} color="#979797" />
                                    &nbsp;Edit Post
                                  </Link>
                                </li>
                                <li onClick={(e)=>toggleDeleteOpen(e)} data-bs-dismiss="modal">
                                  <Link to="#">
                                    <Trash2 size={15} color="#979797" />
                                    &nbsp;Delete
                                  </Link>
                                </li>
                                <li
                                  onClick={(e) =>
                                    toggleShareOpen(
                                      e,
                                      props?.postId,
                                      props?.caption,
                                      props?.allowComments,
                                      props?.locationLAT,
                                      props?.locationLONG
                                    )
                                  }
                                  data-bs-dismiss="modal"
                                >
                                  <Link to="#">
                                    <Navigation size={15} color="#979797" />
                                    &nbsp;Share
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="vertically-centered-modal">
                      {shareOpen !== false && (
            <Modal
              isOpen={shareOpen}
              toggle={toggleShareOpen}
              className="modal-dialog-centered"
            >
              <div className="w-100 border-bottom">
                <div className=" mx-3 py-3  d-flex justify-content-between Heading_Invite_Section">
                  <h4>Share People</h4>
                  <span style={{ cursor: "pointer" }} onClick={sharePost}>
                  </span>
                </div>
              </div>
              <ModalBody>
                <Row className="mt-2">
                  <Col md="8" className="Like_search">
                    <div className="profile-search-blk w-100 ">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="search-svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Search..."
                        value={searchKey}
                        data-bs-toggle="modal"
                        data-bs-target="#peopleSearch"
                        autoFocus
                        onChange={handleChange}
                        // value={searchTerm}
                      />
                    </div>
                  </Col>
                  <Col md="4" className="d-flex justify-content-end">
                    <UncontrolledButtonDropdown color="secondary" outline>
                      <DropdownToggle
                        outline
                        className="border border-muted ml-3 "
                      >
                        <Sliders className="Slider_Filter" size={12} />
                        &nbsp;&nbsp;Filter
                      </DropdownToggle>
                      <DropdownMenu className="DropDown_Group_item">

                        {/* <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(1); }}>
                         SocioMate
                        </DropdownItem> */}
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(0); }}>
                         Followers
                        </DropdownItem>
                        <DropdownItem onClick={(e) => { e.stopPropagation(); setCount(2); }}>
                         Followings
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Row className="mt-3" scrollable>
                       {count == 0 && followerData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index}>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                        {count == 2 && followingData?.filter(data => 
                          data.fullName.includes(searchKey) || 
                          (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) || 
                          data.followBackStatus.includes(searchKey)
                        )
                        .map((data, index) => (
                          <Fragment key={index} scrollable>
                            <div className="group-detail-page-blk">
                              <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                  <img src={data.profileImage} alt="img" className='img-fluid' />
                                  <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                      {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                      {data.followBackStatus}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    </Row>
                  <Col
                    md="6"
                    className="d-flex justify-content-end SubHeading_Invite_Section"
                  >
                    <p style={{ cursor: "pointer" }} onClick={shareAll}>
                        Share all
                    </p>
                        </Col>
                        </Row>
                    </ModalBody>
                    </Modal>
                    )}

                      {deleteOpen !== false && (
                        <Modal
                          isOpen={deleteOpen}
                          toggle={toggleDeleteOpen}
                          className="modal-dialog-centered"
                        >
                          <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                            <h4>Delete Media Video</h4>
                          </ModalHeader>
                          <ModalBody>
                            <div className="d-flex justify-content-center modalHeading_Section">
                              <p>Do you really want to delete all the selected Media ?</p>
                            </div>
                            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                              <small>if</small> YES! <small>please click on ok</small>
                            </h1>
                            <div className="d-flex justify-content-center  mt-4">
                              <Button onClick={toggleDeleteOpen} outline>
                                Cancel
                              </Button>
                              <Button
                                onClick={(e)=>deleteAllPost(e)}
                                color="primary"
                                className="ml-3 "
                              >
                                OK
                              </Button>
                            </div>
                          </ModalBody>
                        </Modal>
                      )}
                      </div>
                      <div className="post-panel mb-0">
                        <div className="post-wrapper">
                          <div className="post-details">
                            <div className="like-panel">
                              <div className="left-emoji">
                                <h6>{allLikes?.length} Likes</h6>
                              </div>
                              <div className="right-stats">
                                <ul>
                                  <li>
                                    <span
                                      style={{ filter: "contrast(150%)" }}
                                      className="d-flex align-items-center"
                                    >
                                      {props?.data?.commentsCount} &nbsp;{" "}
                                      <p className="text-muted">Comment</p>
                                    </span>
                                  </li>
                                  <li>
                                    <span
                                      style={{ filter: "contrast(150%)" }}
                                      className="d-flex align-items-center"
                                    >
                                      {allShares?.count||0} &nbsp;
                                      <p className="text-muted">Share</p>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/* 'saturate('200%')' */}
                            <div className="post-react">
                              <ul>
                                <li>
                                  <Link to="#">
                                    <div onClick={(e)=>setLike(e)}
                                      style={{
                                        backgroundColor: state ? "#81c14b" : "",
                                      }}
                                      className="post-btn-cust"
                                    >
                                      <img 
                                        style={{ filter: "contrast(200%)" }}
                                        src="/assets/images/like1.png"
                                        alt="like"
                                      />
                                    </div>
                                    <span style={{ filter: "contrast(150%)" }}>
                                      {" "}
                                      like
                                    </span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <div className="post-btn-cust">
                                      <img
                                        style={{ filter: "contrast(100%)" }}
                                        src="/assets/images/comment1.png"
                                      />
                                    </div>
                                    <span style={{ filter: "contrast(150%)" }}>
                                      {" "}
                                      Comment
                                    </span>
                                  </Link>
                                </li>
                                <li
                                  onClick={() =>
                                    toggleShareOpen(
                                      props.postId,
                                      props.caption,
                                      props.allowComments,
                                      props.locationLAT,
                                      props.locationLONG
                                    )
                                  }
                                  data-bs-dismiss="modal"
                                >
                                  <Link to="#">
                                    <div className="post-btn-cust ">
                                      <img
                                        style={{ filter: "contrast(200%)" }}
                                        src="/assets/images/share.png"
                                      />
                                    </div>
                                    <span style={{ filter: "contrast(150%)" }}>
                                      share
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="comment-section">
                              <div className="comments d-block">
                                {loadingAllComments ? (
                                  <div class="spinner-border m-5" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  AllComments?.map((data, index) => {
                                    // if (!data.isSubComment) {
                                      return (
                                        <div className="main-comment">
                                          <div className="media">
                                            <img
                                              src={data?.profileImageThumb}
                                              width={30}
                                              height={30}
                                              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                              className="img-fluid bg-img rounded-circle"
                                              alt="user"
                                            />
                                            <div className="media-body ml-2">
                                              <h4
                                                style={{
                                                  filter: "contrast(100%)",
                                                }}
                                              >
                                                {data?.fullName}
                                              </h4>
                                              <p
                                                className="text-muted"
                                                style={{
                                                  filter: "contrast(150%)",
                                                }}
                                              >
                                                {moment(
                                                  data?.createdAt
                                                ).fromNow()}
                                              </p>
                                              <h5
                                                className="py-2 profile-comments"
                                                style={{
                                                  filter: "contrast(150%)",
                                                  whiteSpace: "pre-wrap",  // Ensure white space is handled correctly
                                                  overflowWrap: "break-word",  // Break long words
                                                  wordBreak: "break-word",  // Ensure long words break correctly
                                                  overflow: "hidden"  // Hide any overflow (optional)
                                                }}
                                              >
                                                {data?.comment}
                                              </h5>
                                              <ul className="comment-option">
                                                <li onClick={(e)=>setLikeComment(e,data.id)}>
                                                  <Link to="#" >
                                                    like ({data?.likesCount})
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                      replySubComment(e,data?.id)
                                                    }
                                                  >
                                                    reply ({data?.replyCount})
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              marginTop: "8px",
                                              display:
                                                replyComment === data?.id
                                                  ? "block"
                                                  : "none",
                                            }}
                                          >
                                            <div className="reply">
                                                <div className="search-input input-style input-lg icon-right">
                                                  {commentOpen && commentsData?.rows?.map((data1, index) => (
                                                    <div className="media" key={index}>
                                                      <img
                                                        src={data1?.profileImageThumb}
                                                        width={30}
                                                        height={30}
                                                        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                                                        className="img-fluid bg-img rounded-circle"
                                                        alt="user"
                                                      />
                                                      <div className="media-body ml-2">
                                                        <h4 style={{ filter: "contrast(100%)" }}>{data1?.fullName}</h4>
                                                        <p className="text-muted" style={{ filter: "contrast(150%)" }}>
                                                          {moment(data1?.createdAt).fromNow()}
                                                        </p>
                                                        <h5 className="py-2 profile-comments"   style={{
                                                          filter: "contrast(150%)",
                                                          whiteSpace: "pre-wrap",  // Ensure white space is handled correctly
                                                          overflowWrap: "break-word",  // Break long words
                                                          wordBreak: "break-word",  // Ensure long words break correctly
                                                          overflow: "hidden"  // Hide any overflow (optional)
                                                        }}>
                                                          {data1?.comment}
                                                        </h5>
                                                        <ul className="comment-option">
                                                          <li onClick={(e) => setLikeComment(e, data1?.id)}>
                                                            <Link to="#">like ({data1?.likesCount})</Link>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  ))}

                                                  <br/>

                                                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center', marginTop: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
                                                    <Input
                                                      type="text"
                                                      className="form-control emojiPicker"
                                                      value={subComment}
                                                      onChange={(e) => setSubComment(e.target.value)}
                                                      onClick={() => setPostId(props.data.postId)}
                                                      placeholder="Reply comment here..."
                                                      style={{ flex: 1, padding: '10px', border: 'none', outline: 'none', background: 'transparent', paddingRight: '60px' }} // Make space for the icons
                                                    />
                                                    {/* <span style={{ position: 'absolute', right: '40px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 2 }}>
                                                      <svg
                                                        style={{ filter: 'contrast(150%)' }}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="icon icon-2 iw-14 ih-14"
                                                      >
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                        <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                                        <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                                      </svg>
                                                    </span> */}
                                                    <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', zIndex: 3 }}>
                                                      <img
                                                        style={{ filter: 'contrast(12%)' }}
                                                        src={Snd}
                                                        width={24}
                                                        height={24}
                                                        alt="snd"
                                                        onClick={(e)=>sendComment1(e)}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                          <div className="sub-comment">
                                            {data?.commentDetails?.map(
                                              (replyComments, index) => {
                                                if (
                                                  replyComments?.commentId ===
                                                  data.id &&
                                                  replyComments?.isSubComment ===
                                                  1
                                                ) {
                                                  return (
                                                    <div className="media">
                                                      <img
                                                        src={
                                                          replyComments?.profileImageThumb
                                                        }
                                                        width={30}
                                                        height={30}
                                                        className="img-fluid bg-img rounded-circle"
                                                        alt="user"
                                                      />
                                                      <div className="media-body ml-2">
                                                        <p
                                                          style={{
                                                            filter:
                                                              "contrast(150%)",
                                                          }}
                                                          className="text-muted"
                                                        >
                                                          {moment(
                                                            replyComments?.createdAt
                                                          ).fromNow()}
                                                        </p>
                                                        <h5 className="py-2"   style={{
                                                  filter: "contrast(150%)",
                                                  whiteSpace: "pre-wrap",  // Ensure white space is handled correctly
                                                  overflowWrap: "break-word",  // Break long words
                                                  wordBreak: "break-word",  // Ensure long words break correctly
                                                  overflow: "hidden"  // Hide any overflow (optional)
                                                }}>
                                                          {
                                                            replyComments?.comment
                                                          }
                                                        </h5>
                                                        <ul className="comment-option">
                                                          <li
                                                            style={{
                                                              filter:
                                                                "contrast(150%)",
                                                            }}
                                                          >
                                                            <a href="#">like</a>
                                                          </li>
                                                          <li
                                                            style={{
                                                              filter:
                                                                "contrast(150%)",
                                                            }}
                                                          >
                                                            <a href="#">
                                                              reply
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    // } else {
                                    //   return null;
                                    // }
                                  })
                                )}



                                <br />
                                {/* <a href="/" className="loader">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="iw-15 ih-15"
                                  >
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
                                  </svg>
                                  &nbsp;&nbsp; load more replies
                                </a> */}
                              </div>
                              <div className="reply">
                                  <div
                                    className="search-input input-style input-lg icon-right"
                                    style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}
                                  >
                                    <Input
                                      type="text"
                                      className="form-control emojiPicker"
                                      value={comment}
                                      onChange={(e) => setComment(e.target.value)}
                                      onClick={() => setPostId(props?.data?.postId)}
                                      placeholder="add comment here"
                                      style={{ flex: 1, paddingRight: '40px' }} // Add padding to avoid overlap
                                    />
                                    {/* <span
                                      style={{ position: 'absolute', right: '40px', display: 'flex', alignItems: 'center' }}
                                    >
                                      <Emoji
                                        color="#808080"
                                        strokeWidth={2}
                                        onEmojiSelect={handleEmojiSelect}
                                      />
                                    </span> */}
                                    <div
                                      className="Media_Modal_send_Icon"
                                      style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
                                    >
                                      <img
                                        style={{ filter: 'contrast(150%)', cursor: 'pointer' }}
                                        src={Snd}
                                        width={14}
                                        height={14}
                                        alt="snd"
                                        onClick={sendComment}
                                      />
                                    </div>
                                  </div>
                                </div>



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MediaModal;
