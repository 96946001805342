import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import LanguageSetting from './Language-components/LanguageSetting'
import LegalPolicie from './LegalPolicies/LegalPolicie'
import MediaContent from './MediaContent-component/MediaContent'
import NotificationTabSection from './Notification-components/NotificationTabSection'
import PostStoryTabSection from './Post-Story-Components/PostStoryTabSection'
import PrivacySetting from './PrivacyComponents/PrivacySetting'
import ReportProblem from './ReportProblem/ReportProblem'
import BlockedPeople from './Subcomponents-of-tabdata/BlockedPeople'
import Connections from './Subcomponents-of-tabdata/Connections'
import DeleteAccount from './Subcomponents-of-tabdata/DeleteAccount'

import PersonalInformation from './Subcomponents-of-tabdata/PersonalInformation'
import RecentActivites from './Subcomponents-of-tabdata/RecentActivites'
import SecurityLogin from './Subcomponents-of-tabdata/SecurityLogin '

const SettingTabData = (props) => {

    /**        State and Functions        * */
    const [notificatioActive, setNotificatioActive] = useState('1')
    const [active, setActive] = useState('1')
    const toggle = tab => {
        setActive(tab)
    }

    const [postActive, setPostActive] = useState('1')


    // Initial active tab

    const handleEventsNameClick = (tabId) => {
        setActive(tabId);


    };



    const [setsideclassoption, setsetsideclassoption] = useState(false)
    const [setsideclassoption1, setsetsideclassoption1] = useState(false)
    const [setsideclassoption2, setsetsideclassoption2] = useState(false)
    const [settingsText, setSettingsText] = useState('Settings');

    const SettingsArray1 = [
        {

            eventsName: "    Personal Information"
        },
        {

            eventsName: " Security & Login"
        },
        {

            eventsName: " Blocked People"
        },
        {

            eventsName: "  Recent Activites"
        },
        {

            eventsName: "Connections"
        },
        {

            eventsName: " Delete Account"
        },


    ]

    /**        Account Nav       * */

    const Account = (props) => {
        return (
            <>
                <>
                    <Nav tabs className='pt-3 hide-setting-options'>
                        <NavItem>
                            <NavLink
                                active={active === '1'}
                                onClick={() => {
                                    toggle('1')
                                }}
                            >
                                Personal Information
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '2'}
                                onClick={() => {
                                    toggle('2')
                                }}
                            >
                                Security & Login
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '3'}
                                onClick={() => {
                                    toggle('3')
                                }}
                            >
                                Blocked People
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '4'}
                                onClick={() => {
                                    toggle('4')
                                }}
                            >
                                Recent Activites
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                active={active === '5'}
                                onClick={() => {
                                    toggle('5')
                                }}
                            >
                                Connections
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={active === '6'}
                                className='text-danger'
                                onClick={() => {
                                    toggle('6')
                                }}
                            >
                                Delete Account
                            </NavLink>
                        </NavItem>
                    </Nav>


                    <div>

                        <div className='Categories_LeftPannel_slider1 hide-setting-options1'>
                            <div className="Categories_Left_block">
                                <div className="" id='Categories-Scroll-1'>
                                    <div className="biz-about-cont">
                                        <ul>
                                            {
                                                SettingsArray1.map((data, index) => (
                                                    <li key={index} className={`${setsideclassoption ? 'SettingForSidestyle1 hide-setting-options' : 'SettingForSidestyle1'
                                                        } ${active === (index + 1).toString() ? 'active' : ''}`} setsetsideclassoption={setsetsideclassoption} >

                                                        <div className='nave-items-bizPage d-flex' onClick={() => { handleEventsNameClick((index + 1).toString()); setsetsideclassoption(true); props.setSettingsText(data.eventsName); }}>
                                                            {/* <img src={data.icon} width={31} height={31} /> */}
                                                            <div className='ml-3 EventsCategories_All_events'>
                                                                <h5>{data.eventsName}</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <TabContent className={!setsideclassoption && 'hide-setting-options p-2 gdfgdf'} activeTab={active}  >
                        <div onClick={() => { setsetsideclassoption(false);; props.setSettingsText("Account") }} className='hide-setting-options1 backarbut1'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                        </svg></div>

                        <TabPane tabId='1' >
                            <PersonalInformation setSettingsText={props.setSettingsText} />
                        </TabPane>
                        <TabPane tabId='2'>
                            <SecurityLogin />
                        </TabPane>
                        <TabPane tabId='3'>
                            <BlockedPeople />
                        </TabPane>
                        <TabPane tabId='4'>
                            <RecentActivites />
                        </TabPane>
                        <TabPane tabId='5'>
                            <Connections />
                        </TabPane>
                        <TabPane tabId='6'>
                            <DeleteAccount />
                        </TabPane>
                    </TabContent>
                </>
            </>
        )
    }

    /**        LeftSidebar wise data       * */

    const Privacy = () => {
        return (
            <>
                <PrivacySetting />
            </>
        )
    }
    const PostandStory = (props) => {
        return (
            <>
                <PostStoryTabSection setsetsideclassoption2={props.setsetsideclassoption2} setsideclassoption2={props.setsideclassoption2} setSettingsText={props.setSettingsText} setPostActive={setPostActive} postActive={postActive} />
            </>
        )
    }
    const Notification = (props) => {
        return (
            <>
                <NotificationTabSection setsetsideclassoption1={props.setsetsideclassoption1} setsideclassoption1={props.setsideclassoption1} setSettingsText={props.setSettingsText} setNotificatioActive={setNotificatioActive} notificatioActive={notificatioActive} />
            </>
        )
    }
    const MediaandContent = () => {
        return (
            <>
                <MediaContent />
            </>
        )
    }
    const ReportaProbulem = () => {
        return (
            <>
                <ReportProblem />
            </>
        )
    }
    const Language = () => {
        return (
            <>
                <LanguageSetting />
            </>
        )
    }
    const LegalPolicies = () => {
        return (
            <>
                <LegalPolicie />
            </>
        )
    }


    return (
        <div>


            <div style={{ display: (setsideclassoption || setsideclassoption1 || setsideclassoption2) ? 'none' : '' }} onClick={() => { props.setsideOptionsClass(false); props.setSettingsText("Setting") }} className='hide-setting-options1 backarbut'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
            </svg></div>

            {props.tabData == '1' ?
                <Account setSettingsText={props.setSettingsText} /> : props.tabData == '2' ?
                    <Privacy /> : props.tabData == '3' ?
                        <PostandStory setsetsideclassoption2={setsetsideclassoption2} setsideclassoption2={setsideclassoption2} setSettingsText={props.setSettingsText} setPostActive={setPostActive} postActive={postActive} /> : props.tabData == '4' ?
                            <Notification setsetsideclassoption1={setsetsideclassoption1} setsideclassoption1={setsideclassoption1} setSettingsText={props.setSettingsText} setNotificatioActive={setNotificatioActive} notificatioActive={notificatioActive} /> : props.tabData == '5' ?
                                <MediaandContent /> : props.tabData == '6' ?
                                    <ReportaProbulem /> : props.tabData == '7' ?
                                        <Language /> : props.tabData == '8' ?
                                            <LegalPolicies /> :
                                            <Account />
            }

        </div>
    )
}

export default SettingTabData