import React, { Fragment, useState } from 'react'
import { ChevronDown } from 'react-feather'

import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Input, Button } from 'reactstrap'

const LanguageSetting = () => {

    //=================== start_Language_array_Data ===================//
    const LanguageDataArray = [
        {
            id: '1',
            name: "Deutsch"
        },
        {
            id: '2',
            name: "Hindi"
        },
        {
            id: '3',
            name: "English"
        },
        {
            id: '4',
            name: "Башҡортса"
        },
        {
            id: '5',
            name: "Deutsch"
        },
        {
            id: "6",
            name: "English (UK)"
        }

    ]
    //=================== end_Language_array_Data ===================//

    //===================  start_Update_Event_Update_state ===================//
    const [language, setLanguage] = useState("English (UK)")
    const toggleLanguage = (lang) => {
        setLanguage(lang.target.value)
        console.warn(lang.target.value)
    }
    //===================  End_Update_Event_Update_state ===================//

    //==================== main_return_function ===================//
    return (
        <Fragment>
            <div className='SettingPrivacy_Main_section'>
                <div className='hide-setting-options'>
                    <h3>Change Language</h3>
                </div>
                <div className='SettingLanguage_Card_Body'>
                    <h4 >SocioMee Language</h4>
                    <p className='py-3'>Language for buttons, titles and other text from SocioMee for this account on https://uat.sociomee.com/</p>

                    <div className='d-flex justify-content-between'>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle outline color='primary' className="Language_DropSelect_section">
                                    {language} &nbsp; <ChevronDown size={20} />
                                </DropdownToggle>
                                <DropdownMenu className="language_menu">
                                    <Input type="text" placeholder="Search.." id="myInput" />
                                    {LanguageDataArray.map((data) => (
                                        <DropdownItem className="Language_droup_Item" onClick={toggleLanguage} value={data.name} >{data.name}</DropdownItem>

                                    ))}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div>
                            {/* <Button outline className="mr-2">Cancel</Button> */}
                            <Button color="primary" >&nbsp;&nbsp;Save&nbsp;&nbsp;</Button>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default LanguageSetting