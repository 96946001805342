import { Checkbox } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Filter } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Button, Card, Col, DropdownMenu, DropdownToggle, Input, Row, UncontrolledButtonDropdown } from 'reactstrap';
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import './golive.css'

export default function GoliveHeader() {

    const [options, setOptions] = useState([
        { label: 'Education', value: 'education', isChecked: false },
        { label: 'Comedy', value: 'Comedy', isChecked: false },
        { label: 'TV & Film', value: 'TVFilm', isChecked: false },
        { label: 'Religion & Sprituality', value: 'ReligionSprituality', isChecked: false },
        { label: 'Health & Filtness', value: 'HealthFiltness', isChecked: false },
        { label: 'News & Politics', value: 'News & Politics', isChecked: false },
        { label: 'Socity & Culture ', value: 'Socity & Culture ', isChecked: false },
        { label: 'Sport & Game', value: 'Sport & Game', isChecked: false },
        { label: 'Business & Finace', value: 'Business & Finace', isChecked: false },
        { label: 'Kids & Family', value: 'Kids & Family', isChecked: false },
        { label: 'Art & Craft', value: 'Art & Craft', isChecked: false },

        { label: 'Games & Hobboes', value: 'Games & Hobboes', isChecked: false },
        { label: 'Government', value: 'Government', isChecked: false },
        { label: 'Science & Technolgy', value: 'Science & Technolgy', isChecked: false },
    ]);


    const [selectAll, setSelectAll] = useState(false);

    // const handleChange = (index) => {
    //     const updatedOptions = [...options];
    //     updatedOptions[index].isChecked = !updatedOptions[index].isChecked;
    //     setOptions(updatedOptions);
    //     console.log(updatedOptions);
    // };

    // const handleSelectAll = () => {
    //     setSelectAll(!selectAll);
    //     setOptions(
    //         options.map((option) => ({
    //             ...option,
    //             isChecked: !selectAll,
    //         }))

    //     );
    // };

    const handleSelectAll = () => {
        if (!selectAll) {
          // If "Select All" is not selected, select all options
          setSelectedIds(options.map((option) => option.value));
        } else {
          // If "Select All" is selected, deselect all options
          setSelectedIds([]);
        }
        setSelectAll(!selectAll);
      };

    const [selectedIds, setSelectedIds] = useState([]);

    const handleToggle = (id) => {
        if (selectedIds.includes(id)) {
            // If the ID is already selected, remove it from the selectedIds array
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        } else {
            // If the ID is not selected, add it to the selectedIds array
            setSelectedIds([...selectedIds, id]);
        }
    };
    // const handleChange = (index) => {
    //     const updatedOptions = options.map((option, i) => ({
    //         ...option,
    //         isChecked: i === index ? !option.isChecked : false, // Toggle the clicked option and deselect others
    //     }));
    //     setOptions(updatedOptions);
    // };

    return (
        <>
            <Card className='p-3  goliveheader goheader'>
                <Row>
                    <Col xl='4'>

                        <h2 className='goliovehead'>Golive</h2>

                    </Col>
                    <Col xl='8 srechgolive'>
                        <div className='d-flex align-items-center justify-content-end serchfiltergolive'>
                            <div className="search-box-mp">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon2 iw-16 icon-light"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                <Input type="text" className="form-control search-type searrch" placeholder="Search..." />
                            </div>
                            <UncontrolledButtonDropdown className='mx-2'>
                                <DropdownToggle style={{ padding: '10.5px 11px ' }} outline color='primary' className="filter">
                                    <Filter size={18} />
                                    <span className="filter-text">Filter</span>
                                </DropdownToggle>
                                <DropdownMenu style={{
                                    minWidth: '13rem', left: '-93px', boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                                    borderRadius: '5px',
                                    background: '#FFF',
                                }} className='scrollForGoliveFilter p-3'  >
                                    <div>
                                        <div className='d-flex align-items-center justify-content-between cursor-p' onClick={handleSelectAll}>
                                            <p className='Login80_400'>Select All</p>
                                            <Checkbox
                                                className='Check_Input_section'
                                                checked={selectAll}
                                                onChange={() => handleSelectAll()}
                                                size='small'
                                                sx={{
                                                    color: "#808080",
                                                    '&.Mui-checked': {
                                                        color: "#81c14b",
                                                    },
                                                }}
                                            />

                                        </div>
                                        {options.map((option, index) => (
                                            <div key={option.value}  className="cursor-p d-flex align-items-center justify-content-between">
                                                <p className='Login80_400'>{option.label}</p>
                                                <Checkbox
                                                    className='Check_Input_section'
                                                    checked={selectedIds.includes(option.value)}
                                                    onChange={() => handleToggle(option.value)}
                                                    size='small'
                                                    sx={{
                                                        color: "#808080",
                                                        '&.Mui-checked': {
                                                            color: "#81c14b",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                            {/* <UncontrolledButtonDropdown className='mx-2'>
                                <DropdownToggle style={{ padding: '10.5px 11px ' }} outline color='primary' className="filter">
                                    <Filter size={18} />
                                    <span className="filter-text">Filter</span>
                                </DropdownToggle>
                                <DropdownMenu style={{
                                    minWidth: '13rem', left: '-48px', boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                                    borderRadius: '5px',
                                    background: '#FFF',
                                }} className='scrollForGoliveFilter p-3'>
                                    <div>
                                    <div className='d-flex align-items-center justify-content-between cursor-p' onClick={handleSelectAll}>
                                            <p className='Login80_400'>Select All</p>
                                            <Checkbox
                                                className='Check_Input_section'
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                                size='small'
                                                sx={{
                                                    color: "#808080",
                                                    '&.Mui-checked': {
                                                        color: "#81c14b",
                                                    },
                                                }}
                                            />

                                        </div>
                                        {options.map((option, index) => (
                                            <div key={option.value} onClick={() => handleChange(index)} className="cursor-p d-flex align-items-center justify-content-between">
                                                <p className='Login80_400'>{option.label}</p>
                                                <Checkbox
                                                    className='Check_Input_section'
                                                    checked={option.isChecked}
                                                    onChange={() => handleChange(index)}
                                                    size='small'
                                                    sx={{
                                                        color: "#808080",
                                                        '&.Mui-checked': {
                                                            color: "#81c14b",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown> */}

                            <NavLink to='/CreateGoLive'>
                                <Button color='primary' outline className='text-nowrap golivered padbutton' style={{
                                    width: 'auto', // Ensure the button width adjusts based on content
                                    maxWidth: '100%' // Limit the button's width to its container's width
                                }}>
                                    <div className='livgif'>
                                        <img width={20} src={LiveGif} alt="LiveGif1" />
                                        <span>GoLive</span>
                                    </div>
                                </Button>
                            </NavLink>

                        </div>
                    </Col>
                </Row>
            </Card >
        </>
    );
} 