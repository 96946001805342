import React from 'react';
import Header from '../../Header';
import LeftSidebar from '../../LeftSidebar';
import RightSidebar from '../../RightSidebar';
import Models from '../../Models';
import GroupDetailsTop from './GroupDetailsTop';
import { Fragment } from 'react';
import '../style.css'
import GroupDetailMenu from './GroupDetailMenu';

const GroupDetail = () => {
    // Main_Return_section
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <GroupDetailsTop />
                    <GroupDetailMenu />
                </div>
                <RightSidebar />
            </div>
            <Models></Models>
        </Fragment>
    )
}

export default GroupDetail