import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    connectionList: [],
    loading: true,
};

 const CreateConnectionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_ALL_CONNECTION_LIST:
            return {
                ...state,
                connectionList: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default CreateConnectionListReducer;