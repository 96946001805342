import "./MarketPlace.css"
import "./MpMediaQuery.css"
import React, { Fragment, useEffect } from 'react'
import MarketPlaceHeader from './SubComponents/MarketPlaceHeader'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import { Col, Row } from "reactstrap"
import MarketPlaceRightSide from "./SubComponents/MarketPlaceRightSide"
import { useState } from "react"
import MarketPlaceSortBy from "./SubComponents/MarketPlaceSortBy"
import MarketPlaceFilterSection from "./SubComponents/MarketPlaceFilterSection"
import { BarChart, Filter } from "react-feather"
import { Offcanvas } from "react-bootstrap"
import { Radio } from '@mui/material';




const Marketplace = () => {

  // filter Path filter the data ====================
  const [filterPath, setFilterPath] = useState("Home");

  // // gridView or List View 
  const [listView, setListView] = useState(1)

  // sidebar filter and sort by
  const [sideBarFilter, setSideBarFilter] = useState(null)
  // const [HeaderHeight, setHeaderHeight] = useState(null)



  const handleShow1 = () => setShow1(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
  }
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = (id) => {
    setShow(true);
    setSideBarFilter(id)
  }

  const [radioStates, setRadioStates] = useState({
    distance: false,
    date: false,
    priceHighest: false,
    priceLowest: false,
  });


  const handleLiClick = (radioName) => {
    setRadioStates((prevRadioStates) => ({
      distance: false,
      date: false,
      priceHighest: false,
      priceLowest: false,
      [radioName]: !prevRadioStates[radioName],
    }));
    setSortBy(radioName)
    setShow1(false); // Close Offcanvas when a radio button is clicked
  };


  const [categoryId, setCategoryId]  = useState(null)
  const [searchKey, setSearchKey] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [sortBy , setSortBy] = useState(null)
  const [subcategoryFilter, setSubcategoryFilter] = useState ([])
  const [minPrice,setMinPrice] = useState ('')
  const [maxPrice,setMaxPrice] = useState ('')
  const [locationRange, setLocationRange] = useState(15000)
  const [attributeVal, setAttributeVal] = useState({
    checkBoxValues: [], // This will store values from checkboxes
    tabValues: [] // This will store values from tabs
});
const [refreshFilter, setRefreshFilter] = useState(false)
const [latLongValue,setLatLongValue] =useState({})


const handleAttributeValUpdate = (newValues) => {
    setAttributeVal((prevValues) => ({
        ...prevValues,
        ...newValues
    }));
};



//here we cleaning all the filter by the change of the category
useEffect(()=>{
  setAttributeVal({
    checkBoxValues: [], 
    tabValues: [] 
  })
  // setSubcategoryFilter([])
  setRefreshFilter(prev => !prev);

},[categoryId])

//if category will change  then page will refresh
useEffect(()=>{
  if(refreshFilter){
    setSubcategoryFilter([])
    setRefreshFilter(prev => !prev);

  }
},[refreshFilter])




//  >>>>>>>>>>>>>> ===================================================================================================   <<<<<<<<<<<<<<<<<

  return (
    <Fragment>
      {/* ============== Usiversal header ========================== */}
      <Header />
      <div className="page-body container-fluid profile-page">
        {/* ================== Universal left sidebar ========================= */}
        <LeftSidebar />
        {/* ===========================center content of the page ======================= */}
        <div className="page-center">
          {/* ====== marketPlac header ========================== */}
          <MarketPlaceHeader
            setFilterPath={setFilterPath}
            listView={listView}
            setListView={setListView}
            setSideBarFilter={setSideBarFilter} 
            setSearchKey={setSearchKey}
            setPageIndex = {setPageIndex}
            setCategoryId={setCategoryId}
            setLatLongValue = {setLatLongValue}
            />
          {/* market place sort by and filter icon  */}
          <div className="MpFilterAndSortByMobile align-items-center bg-white mt-2 rounded justify-content-around border p-2 w-100 " style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <span style={{ cursor: "pointer" }} onClick={handleShow1} ><BarChart size={14} color="#4f4f4f" /> Sort By</span>
            <span style={{ cursor: "pointer" }} onClick={() => { handleShow(2) }} ><Filter size={14} color="#4f4f4f" /> Filter</span>
          </div>



          {/* Mobile screen side bar for the filter and sory by  */}
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {sideBarFilter === 2 ? 
              <MarketPlaceFilterSection 
               filterPath={filterPath} 
               setSubcategoryFilter = {setSubcategoryFilter}
               setMinPrice={setMinPrice} 
               setMaxPrice= {setMaxPrice} 
               setLocationRange = {setLocationRange} 
               setAttributeVal={setAttributeVal} 
               categoryId= {categoryId}
               refreshFilter={refreshFilter}
               setRefreshFilter= {setRefreshFilter}
              /> : ''}
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas placement='bottom' show={show1} onHide={handleClose1} className='canvas1 '>

            <Offcanvas.Header >
              <div className='d-flex flex-column marketcn' >
                <h3 className='' style={{ fontWeight: "500" }}>Sort By</h3>
                <h6 className="">Home</h6>
              </div>

            </Offcanvas.Header>
            <Offcanvas.Body className='p-0 canvas2'>
              <ul style={{ fontFamily: "Poppins", fontWeight: '400', fontSize: '14px', marginBottom: '7px' }} className='canvas3'>
                <li className='text-muted' onClick={() => handleLiClick('Nearest')}>
                  <Radio
                    inputProps={{ 'aria-label': 'A' }}
                    name="radio-buttonsOne"
                    id="radio-buttonsOneId"
                    className='Radio_btn_check'
                    checked={radioStates.distance}
                  />
                  &nbsp;&nbsp; Distance: Nearest First
                </li>
                <li className='text-muted' onClick={() => handleLiClick('Newest')}>
                  <Radio
                    inputProps={{ 'aria-label': 'A' }}
                    name="radio-buttonsOne"
                    id="radio-buttonsOneId"
                    className='Radio_btn_check'
                    checked={radioStates.date}
                  />
                  &nbsp;&nbsp; Dated Listed: Newest First
                </li>
                <li className='text-muted' onClick={() => handleLiClick('Highest')}>
                  <Radio
                    inputProps={{ 'aria-label': 'A' }}
                    name="radio-buttonsOne"
                    id="radio-buttonsOneId"
                    className='Radio_btn_check'
                    checked={radioStates.priceHighest}
                  />
                  &nbsp;&nbsp; Price: Highest First
                </li>
                <li className='text-muted' onClick={() => handleLiClick('Lowest')}>
                  <Radio
                    inputProps={{ 'aria-label': 'A' }}
                    name="radio-buttonsOne"
                    id="radio-buttonsOneId"
                    className='Radio_btn_check'
                    checked={radioStates.priceLowest}
                  />
                  &nbsp;&nbsp; Price: Lowest First
                </li>

              </ul>
            </Offcanvas.Body>
          </Offcanvas>

          <div className="MarketPlaceBodyContent markettop " >
            {/* ============== Full left Filter and sort by section =================== */}
            <Row  >
              <Col xl={3}>
                <div className="MpFilterSortBy_Desktop">
                  <MarketPlaceSortBy filterPath={filterPath} setSortBy = {setSortBy}/>
                  <MarketPlaceFilterSection 
                  filterPath={filterPath} 
                  setSubcategoryFilter = {setSubcategoryFilter} 
                  setMinPrice={setMinPrice} 
                  setMaxPrice= {setMaxPrice} 
                  setLocationRange = {setLocationRange}   
                  setAttributeVal={setAttributeVal} 
                  categoryId= {categoryId} 
                  refreshFilter={refreshFilter}  
                  setRefreshFilter= {setRefreshFilter}
                  />
                </div>
              </Col>
              <Col xl={9} className="">
                <MarketPlaceRightSide 
                listView={listView} 
                setListView={setListView} 
                searchKey={searchKey} 
                sortBy={sortBy} 
                subcategoryFilter ={subcategoryFilter} 
                minPrice={minPrice}
                maxPrice= {maxPrice} 
                locationRange={locationRange} 
                attributeVal = {attributeVal} 
                categoryId ={categoryId} 
                pageIndex ={pageIndex} 
                setPageIndex= {setPageIndex} 
                latLongValue={latLongValue}/>
              </Col>
            </Row>
          </div>
        </div>
        {/*  */}
        <RightSidebar />
      </div>
    </Fragment >
  )
}

export default Marketplace
