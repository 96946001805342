import React, { useState, Fragment, useEffect } from 'react';
import { Input } from 'reactstrap';

const MpFormInput = ({ data, onDataPass, value }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  // Setting value param for edit screen backend data  
  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (event) => {
    const titleName = event.target.value;

    if (titleName.length > 64) {
      // setError('Title cannot exceed 64 characters.');
    } else {
      setError(''); 
      setInputValue(titleName);
      onDataPass(titleName); 
    }
  };

  const handleBlur = () => {
    if (!inputValue) {
      setError('Title required.');
    }
  };

  return (
    <Fragment>
      {/* ========= Input form Heading ======================== */}
      <div className='MpformElementHeading'>
        <h5>Title*</h5>
      </div>
      {/* =================== Input container =================== */}
      <div className="Mp_InputPannels mt-2">
        <Input
          placeholder="Enter your title"
          style={{ padding: "10px" }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
         <p style={{textAlign:"right"}}> Max 64 Character</p>
        {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
      </div>
    </Fragment>
  );
};

export default MpFormInput;
