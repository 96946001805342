import React, { Fragment, useState } from 'react'
import './Story.css';
import Slider from 'react-slick';
import StoryImg from './Images/storyImg1.svg'
import { Circle, Plus } from 'react-feather';
import { Fab } from '@mui/material';
import { Progress } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import StoryModalView from './StoryModal/StoryModalView';
import SelfStoryViewModal from './StoryModal/SelfStoryViewModal';
import NewStoryViewDesign from './StoryViewDesign/NewStoryViewDesign';
import { useSelector } from 'react-redux';


const SliderStory = () => {
    const [isActive, setIsActive] = useState("self")


    const [storyView, setStoryView] = useState(false)
    const toggleSetStoryView = (storyOf) => {
        setStoryView((prevState) => !prevState)
        setIsActive(storyOf)
    }
    //  ------------- Self Story Modal State -------------
    const [selfStoryModal, setSelfStoryModal] = useState(false)
    const toggleSetSelfStoryModal = () => {
        setSelfStoryModal((preState) => !preState)
    }


    let SettingsStory = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6.5
                }
            },
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 5.5
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4.5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5
                }
            }
        ]
    };
    const StoryImgArray = [
        {
            img: StoryImg,
            condition: "create"
        },
        {
            img: "assets/images/story-07.png",
            condition: "Update"
        },
        {
            img: "assets/images/story-05.jpg",
            condition: "live",
            userName: "Tripti Badola"
        },
        {
            img: "assets/images/story-rashmika.jpg",
            condition: "live",
            userName: "Rashmika Mandhana"
        },
        {
            img: "assets/images/story-03.jpg",
            condition: "Unread",
            userName: "Nora Fatehi"
        },
        {
            img: "/assets/images/story-02.jpg",
            userName: "Anshika Vishwakarma",
            condition: "Unread",
        },
        {
            img: "/assets/images/story-08.jpg",
            userName: "Tamanna Bhatia",
            condition: "Unread",
        },
        {
            img: "/assets/images/story-01.jpg",
            userName: "Nora Jelli"
        },
        {
            img: "/assets/images/story-3.jpg",
            userName: "Herry Vergo"
        },
        {
            img: "/assets/images/story-2.jpg",
            userName: "Nora Jelli"
        },
        {
            img: "/assets/images/story-4.jpg",
            userName: "Zenni Bilo"
        },
    ]
    const { allStories } = useSelector((state) => state.getAllStoryData);
    //====================== Main_Return_Function ===================//
    return (
        <Fragment>
            <div className="story-section ratio_115">
                <Slider {...SettingsStory} className="default-space StorySliderCustomizes">
                    {allStories?.userStories?.map((item) => (
                        <>
                            {
                                item.condition === "create" ?
                                    <div>
                                        <div className='CreateStory_Main_Card_blk' style={{ background: `var(--storyGradient), url(${item.img})` }}>
                                            <div className='Create_Story'>
                                                <NavLink to={'/CreateStories'}>
                                                    <Fab style={{ background: '#81C14B' }} aria-label='add' size='small'>
                                                        <Plus color='#FFFFFF' />
                                                    </Fab>
                                                    <h3 className='mt-1 Add_Story_text'>
                                                        Add Stories
                                                    </h3>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div> :
                                    item.condition === "Update" ?
                                        <div>
                                            <div className='Story_Main_Card_blk' style={{ background: `var(--updateStoryGradient), url(${item.img})` }} onClick={() => toggleSetStoryView("self")}>
                                                <div className="story_TopLeft_blk">
                                                    <Progress className="story_Progress_Bar w-100 " value={75} />
                                                </div>
                                                <div className='story_buttom_rght_blk'>
                                                    <NavLink to={'/CreateStories'}>
                                                        <Fab style={{ background: '#81C14B' }} aria-label='add' size='small'>
                                                            <Plus color='#FFFFFF' />
                                                        </Fab>
                                                    </NavLink>
                                                </div>

                                            </div>
                                        </div> :
                                        item.condition === "live" ?
                                            <div>
                                                <div className='Story_Main_Card_blk' style={{ background: `var(--updateStoryGradient), url(${item.img})`, cursor: "pointer" }} onClick={() => toggleSetStoryView(0)} >
                                                    <div className='Live_pills'>
                                                        <Circle fill='#fff' size={11} />&nbsp;Live
                                                    </div>
                                                    <div className='Live_Right_blk'>
                                                        04:13
                                                    </div>
                                                    <div className="story-buttomcontent">
                                                        <h3>{item.userName}</h3>
                                                        <h4>Just now</h4>
                                                    </div>
                                                </div>
                                            </div> :
                                            item.condition === "Unread" ?
                                                <div className='StoryUnRead_Main_Card_blk p-1' style={{ background: `var(--updateStoryGradient), url(${item.profileImage})`, cursor: "pointer" }} onClick={() => toggleSetStoryView(0)}>
                                                    <div className="story-buttomcontent">
                                                        <h3>{item.fullName}</h3>
                                                        <h4>3hr ago</h4>
                                                    </div>
                                                </div> :
                                                <div>
                                                    <div className='Story_Main_Card_blk' style={{ background: `var(--updateStoryGradient), url(${item.profileImage})`, cursor: "pointer" }} onClick={() => toggleSetStoryView(0)}>
                                                        <div className="story-buttomcontent">
                                                            <h3>{item.fullName}</h3>
                                                            <h4>48hr ago</h4>
                                                        </div>
                                                    </div>
                                                </div>


                            }
                        </>
                    ))
                    }
                </Slider>
            </div>
            {/* ------ Satrt Story View modal ------*/}
            <NewStoryViewDesign
                isOpen={storyView}
                storyViewModal={toggleSetStoryView}
                isActive={isActive}
                setIsActive={setIsActive}
            />
            {/*  ------------------ Self Story Modal View  ------------------ */}
            {/* <SelfStoryViewModal
                isOpen={selfStoryModal}
                selfStoryViewModal={toggleSetSelfStoryModal}
            /> */}
        </Fragment>
    )
}

export default SliderStory





// import React, { Fragment, useState } from 'react'
// import './Story.css';
// import Slider from 'react-slick';
// import StoryImg from './Images/storyImg1.svg'
// import { Circle, Plus } from 'react-feather';
// import { Fab } from '@mui/material';
// import { Progress } from 'reactstrap';
// import { NavLink } from 'react-router-dom';
// import StoryModalView from './StoryModal/StoryModalView';
// import SelfStoryViewModal from './StoryModal/SelfStoryViewModal';
// import NewStoryViewDesign from './StoryViewDesign/NewStoryViewDesign';

// const SliderStory = () => {

//     const[storyData,setStoryData]=useState([])
//     const [isActive, setIsActive] = useState("self")


//     const [storyView, setStoryView] = useState(false)
//     const toggleSetStoryView = (storyOf) => {
//         setStoryView((prevState) => !prevState)
//         setIsActive(storyOf)
//     }
//     //  ------------- Self Story Modal State -------------
//     const [selfStoryModal, setSelfStoryModal] = useState(false)
//     const toggleSetSelfStoryModal = () => {
//         setSelfStoryModal((preState) => !preState)
//     }


//     let SettingsStory = {
//         dots: false,
//         infinite: false,
//         speed: 500,
//         slidesToShow: 8,
//         slidesToScroll: 2,
//         responsive: [
//             {
//                 breakpoint: 1200,
//                 settings: {
//                     slidesToShow: 6.5
//                 }
//             },
//             {
//                 breakpoint: 1140,
//                 settings: {
//                     slidesToShow: 5.5
//                 }
//             },
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 4.5
//                 }
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 3
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 2.5
//                 }
//             }
//         ]
//     };
//     const StoryImgArray = [
//         {
//             img: StoryImg,
//             condition: "create"
//         },
//         {
//             img: "assets/images/story-07.png",
//             condition: "Update"
//         },
//         {
//             img: "assets/images/story-05.jpg",
//             condition: "live",
//             userName: "Tripti Badola"
//         },
//         {
//             img: "assets/images/story-rashmika.jpg",
//             condition: "live",
//             userName: "Rashmika Mandhana"
//         },
//         {
//             img: "assets/images/story-03.jpg",
//             condition: "Unread",
//             userName: "Nora Fatehi"
//         },
//         {
//             img: "/assets/images/story-02.jpg",
//             userName: "Anshika Vishwakarma",
//             condition: "Unread",
//         },
//         {
//             img: "/assets/images/story-08.jpg",
//             userName: "Tamanna Bhatia",
//             condition: "Unread",
//         },
//         {
//             img: "/assets/images/story-01.jpg",
//             userName: "Nora Jelli"
//         },
//         {
//             img: "/assets/images/story-3.jpg",
//             userName: "Herry Vergo"
//         },
//         {
//             img: "/assets/images/story-2.jpg",
//             userName: "Nora Jelli"
//         },
//         {
//             img: "/assets/images/story-4.jpg",
//             userName: "Zenni Bilo"
//         },
//     ]
//     //====================== Main_Return_Function ===================//
//     return (
//         <Fragment>
//             <div className="story-section ratio_115">
//                 <Slider {...SettingsStory} className="default-space StorySliderCustomizes">
//                     {StoryImgArray.map((item) => (
//                         <>
//                             {
//                                 item.condition === "create" ?
//                                     <div>
//                                         <div className='CreateStory_Main_Card_blk' style={{ background: `var(--storyGradient), url(${item.img})` }}>
//                                             <div className='Create_Story'>
//                                                 <NavLink to={'/CreateStories'}>
//                                                     <Fab style={{ background: '#81C14B' }} aria-label='add' size='small'>
//                                                         <Plus color='#FFFFFF' />
//                                                     </Fab>
//                                                     <h3 className='mt-1 Add_Story_text'>
//                                                         Add Stories
//                                                     </h3>
//                                                 </NavLink>
//                                             </div>
//                                         </div>
//                                     </div> :
//                                     item.condition === "Update" ?
//                                         <div>
//                                             <div className='Story_Main_Card_blk' style={{ background: `var(--updateStoryGradient), url(${item.img})` }} onClick={() => toggleSetStoryView("self")}>
//                                                 <div className="story_TopLeft_blk">
//                                                     <Progress className="story_Progress_Bar w-100 " value={75} />
//                                                 </div>
//                                                 <div className='story_buttom_rght_blk'>
//                                                     <NavLink to={'/CreateStories'}>
//                                                         <Fab style={{ background: '#81C14B' }} aria-label='add' size='small'>
//                                                             <Plus color='#FFFFFF' />
//                                                         </Fab>
//                                                     </NavLink>
//                                                 </div>

//                                             </div>
//                                         </div> :
//                                         item.condition === "live" ?
//                                             <div>
//                                                 <div className='Story_Main_Card_blk' style={{ background: `var(--updateStoryGradient), url(${item.img})`, cursor: "pointer" }} onClick={() => toggleSetStoryView(0)} >
//                                                     <div className='Live_pills'>
//                                                         <Circle fill='#fff' size={11} />&nbsp;Live
//                                                     </div>
//                                                     <div className='Live_Right_blk'>
//                                                         04:13
//                                                     </div>
//                                                     <div className="story-buttomcontent">
//                                                         <h3>{item.userName}</h3>
//                                                         <h4>Just now</h4>
//                                                     </div>
//                                                 </div>
//                                             </div> :
//                                             item.condition === "Unread" ?
//                                                 <div className='StoryUnRead_Main_Card_blk p-1' style={{ background: `var(--updateStoryGradient), url(${item.img})`, cursor: "pointer" }} onClick={() => toggleSetStoryView(0)}>
//                                                     <div className="story-buttomcontent">
//                                                         <h3>{item.userName}</h3>
//                                                         <h4>3hr ago</h4>
//                                                     </div>
//                                                 </div> :
//                                                 <div>
//                                                     <div className='Story_Main_Card_blk' style={{ background: `var(--updateStoryGradient), url(${item.img})`, cursor: "pointer" }} onClick={() => toggleSetStoryView(0)}>
//                                                         <div className="story-buttomcontent">
//                                                             <h3>{item.userName}</h3>
//                                                             <h4>48hr ago</h4>
//                                                         </div>
//                                                     </div>
//                                                 </div>


//                             }
//                         </>
//                     ))
//                     }
//                 </Slider>
//             </div>
//             {/* ------ Satrt Story View modal ------*/}
//             <NewStoryViewDesign
//                 isOpen={storyView}
//                 storyViewModal={toggleSetStoryView}
//                 isActive={isActive}
//                 setIsActive={setIsActive}
//             />
//             {/*  ------------------ Self Story Modal View  ------------------ */}
//             {/* <SelfStoryViewModal
//                 isOpen={selfStoryModal}
//                 selfStoryViewModal={toggleSetSelfStoryModal}
//             /> */}
//         </Fragment>
//     )
// }

// export default SliderStory
