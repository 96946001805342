import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    getUserSociomate: [],
    loading: true,
};

 const GetSociomateReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SOCIOMATE:
            return {
                ...state,
                getUserSociomate: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default GetSociomateReducer;