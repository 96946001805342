import React, { useEffect } from 'react'
import { Archive, Bookmark, ChevronDown, ChevronLeft, ChevronUp, Copy, Download, Edit, Eye, EyeOff, MoreVertical, Music, Send, Share, Smile, Trash2, Volume2, VolumeX, XCircle } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, Row, UncontrolledButtonDropdown, UncontrolledCollapse } from 'reactstrap';
import shotz_white from '../../NewComponents/IMG/shotz_white.svg'
import like_circle_gray from '../../NewComponents/IMG/like_circle_gray.png'
import comments_circle_gray from '../../NewComponents/IMG/comment_circle_gray.png'
import ActiveCirclelike from '../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../NewComponents/IMG/Circlelike.svg'
import CommentLatest from '../../NewComponents/IMG/CommentLatest.svg'
import ShareLatest from '../../NewComponents/IMG/ShareLatest.svg'
import shotz1 from '../../NewComponents/Videos/shotzVideos1.mp4'
import share_circle_gray from '../../NewComponents/IMG/share_circle_gray.png'
import { useState, useRef } from 'react';
// import { Button } from 'react-bootstrap';
import DownloadModals from '../Shotzs/ShotzModal/DownloadModal';
import HideModal from '../Shotzs/ShotzModal/HideModal';
import { EditModal } from '../Shotzs/ShotzModal/EditModal';
import ShareModal from '../Shotzs/ShotzModal/ShareModal';
// import './shortzScript';
import '../Shotzs/ShotzStyle.css'
import ReactPlayer from 'react-player';
import { ArchivedModal, ShotzPopup } from '../Shotzs/ArchivedModal';
// import useScrollSnap from "react-use-scroll-snap";
import { debounce } from 'lodash';
import ArchiveModal from '../Shotzs/ShotzModal/ArchiveModal';
import DeletedModal from '../Shotzs/ShotzModal/DeletedModal';
import DownloadModal from '../Shotzs/ShotzModal/DownloadModal';
import { useUserAuth } from '../../Context/userAuthContext';
import { loadAllCommments } from '../../Services/Actions/SocialFeed/getAllCommentsAction';
import { useDispatch } from 'react-redux';
import { event } from 'jquery';

export const MyShotzDisplayModal = (props) => {

    // console.log("clicked on myshotzdisplay=============================")
    const dispatch = useDispatch();
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const [volume, setvolume] = useState(false);
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [shotzComment, setShotzComment] = useState(false)

    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    // ------------- Download Modal State -------------
    const [downloadModalOpen, setDownloadModalOpen] = useState(false)
    const scrollRef = useRef(null)
    const toggleDownloadModalOpen = () => {
        setDownloadModalOpen((prevState) => !prevState)
    }
    // ------------- Delete Modal State -------------
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModalOpen = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }
    // ------------- Archive Modal State ------------
    const [archiveModalOpen, setArchiveModalOpen] = useState(false)
    const toggleArchiveModalOpen = () => {
        setArchiveModalOpen((prevState) => !prevState)
    }
    // ------------- Archive Modal State -------------
    const [hideShotzModalOpen, setHideShotzModalOpen] = useState(false)
    const toggleHideShotzModalOpen = () => {
        setHideShotzModalOpen((prevState) => !prevState)
    }
    //  ------------- Edit Modal State -------------
    const [editModalOpen, setEditModalOpen] = useState(false)
    const toggleEditModalOpen = () => {
        setEditModalOpen((prevState) => !prevState)
    }

    //  ------------- Play video State -------------
    const [playVideo, setplayVideo] = useState(false)
    const toggleplayVideo = () => {
        setplayVideo((prevState) => !prevState)
    }

    //  ------------- Comment State -------------
    const [comment, setComment] = useState(false)
    const toggleReply = () => {
        setComment((prevState) => !prevState)
    }

    //  ------------- Comment State -------------
    const [BookmarkIcon, SetBookmark] = useState(true)
    const handleBookmark = () => SetBookmark(!BookmarkIcon)

    //  ------------- hovered video State -------------
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = (e) => {
        e.stopPropagation();
        setIsHovered(true);
    };

    const handleMouseLeave = (e) => {
        e.stopPropagation();
        setIsHovered(false);
    };

    //  -------------video State -------------
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = (e) => {
        e.stopPropagation();
        const video = videoRef.current;

        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }

        setIsPlaying(!isPlaying);
    };

    //  ------------- Comment visible Modal State -------------
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        setVisible(!visible)
        console.log(props.shotzData)
    }

    //  ------------- Comment visible Modal State -------------
    const [isOpenA, setIsOpenA] = useState(false);
    const [isSlow, setIsSlow] = useState(false);
    const [shotzData, setShotzData] = useState(false);
    const toggleIsOpenA = (id) => {
        // Check if this function is actually slow
        console.time('toggleIsOpenA');
        console.log("=====",props.shotzData)
        dispatch(loadAllCommments(id))
        setIsOpenA(!isOpenA);
        setIsSlow(true);

        setTimeout(() => {
            setIsSlow(false);
            console.timeEnd('toggleIsOpenA'); // Measure the execution time
        }, 1500);
    }

    const [slideIndex, setSlideIndex] = useState(0);

    const handleWheel = (event) => {
        event.stopPropagation();
        const delta = Math.sign(event.deltaY);
        setSlideIndex((slideIndex + delta + 3) % 3);
    };
    const crossFunction = (e) => {
        // props.ShotzModalCarousel(false)
        e.stopPropagation();
        props?.setIsTabOpen(false)
        props?.setCurrentPostId("")
        setIsOpenA(false)
    }
  
    const [Commentopen, setcommentclose] = useState(false)

    const toggleComment = () => {
        if (window.innerWidth < 992) {
            setcommentclose((preState) => !preState)
            setIsOpenA(false);
        }
    };

    const CommentModal = () => {
        const [LikeIcon, SetLikeIcon] = useState(false)
        return (
            <div>
                {
                    Commentopen !== false &&
                    <Modal scrollable isOpen={Commentopen} toggle={toggleComment} className='modal-dialog-centered modal-lg hide-on-web'>

                        <div className="user-detail d-flex justify-content-between py-1">
                            <div className="user-media">
                                <div className="media px-2">
                                    <Link className="user-img">
                                        <img src="/assets/images/story-3.jpg" className="img-fluid rounded-circle bg-img" alt="user" />
                                        <span className="available-stats"></span>
                                    </Link>
                                    <div className="media-body ">
                                        <h6 className="user-name">Paige Turner</h6>
                                        <h6>alabma, USA</h6>
                                    </div>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat'>
                                    <MoreVertical size={20} />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu obj-container'>
                                    <DropdownItem onClick={toggleForward} className='border-bottom py-2 shotz-menu-hover-style' >
                                        <h5><Share size={15} className='mr-2 icon-color-style' />Share</h5>
                                    </DropdownItem>
                                    <DropdownItem onClick={toggleForward} className='border-bottom py-2 shotz-menu-hover-style' >
                                        <h5><EyeOff size={15} className='mr-2 icon-color-style' />Hide post from timeline</h5>
                                    </DropdownItem>
                                    <DropdownItem onClick={toggleEditModalOpen} className='border-bottom py-2 shotz-menu-hover-style'>
                                        <h5><Edit size={15} className='mr-2 icon-color-style' />Edit</h5>
                                    </DropdownItem>
                                    <DropdownItem className='border-bottom py-2 shotz-menu-hover-style'>
                                        <h5><Copy size={15} className='mr-2 icon-color-style' />Copy Link</h5>
                                    </DropdownItem>
                                    <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                        <h5><Download size={15} className='mr-2 icon-color-style' />Download</h5>
                                    </DropdownItem>
                                    <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                        <h5><Archive size={15} className='mr-2 icon-color-style' />Archive</h5>
                                    </DropdownItem>
                                    <DropdownItem className='py-2 menu-hover-style'>
                                        <h5><Trash2 size={15} className='mr-2 icon-color-style' />Delete</h5>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="post-panel mb-0">
                            <div className="post-wrapper" >
                                <div className="post-details">
                                    <div className="like-panel">
                                        <div className="left-emoji">
                                            <ul>
                                                <li>
                                                    <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                </li>
                                                <li>
                                                    <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                </li>
                                            </ul>
                                            <h6>+75</h6>
                                        </div>
                                        <div className="right-stats">
                                            <ul >
                                                <li>
                                                    <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                </li>
                                                <li>
                                                    <span className='d-flex align-items-center'>985  &nbsp;<p className='text-muted'>Share</p></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="post-react">
                                        <ul>
                                            <li >
                                                <Link >
                                                    <img src={like_circle_gray} height='26px' width='26px' alt="like" />&nbsp;Like
                                                </Link>
                                            </li>
                                            <li >
                                                <Link onClick={toggleComment}>
                                                    <img src={comments_circle_gray} height='25px' width='25px' alt="comments" />&nbsp;Comment
                                                </Link>
                                            </li>
                                            <li >
                                                <Link onClick={toggleForward}>
                                                    <img src={share_circle_gray} height='25px' width='25px' alt="share" />&nbsp;share
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="comment-section">
                                        <div className="comments d-block scrollForShareShotz">
                                            <div className="main-comment">
                                                <div className="media">
                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                    <div className="media-body ml-2">
                                                        <h5>Pabelo Mukrani</h5>
                                                        <p className='text-muted'>50 mins ago</p>
                                                        <h6 className='py-1 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍&#128578;
                                                        </h6>
                                                        <ul className="comment-option">
                                                            <li><Link to="#">like (5)</Link></li>
                                                            <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {comment ? <div className="sub-comment">
                                                    <div className="media">
                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                        <div className="media-body ml-2">
                                                            <h5>Pabelo Mukrani</h5>
                                                            <p className='text-muted'>50 mins ago</p>
                                                            <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                            <ul className="comment-option">
                                                                <li><a href="#">like</a></li>
                                                                <li><a href="#">reply</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="media">
                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                        <div className="media-body  ml-2">
                                                            <h6>sufiya elija</h6>
                                                            <p className='text-muted'>50 mins ago</p>
                                                            <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                            <ul className="comment-option">
                                                                <li><a href="#">like</a></li>
                                                                <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <a href="#" className="loader">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                        load more replies
                                                    </a>
                                                </div> : null}
                                            </div>
                                            <div className="main-comment">
                                                <div className="media">
                                                    <img src="/assets/images/story-2.jpg" width={30} height={30} className="img-fluid bg-img rounded-circle" alt="user" />
                                                    <div className="media-body ml-2">
                                                        <h5>Pabelo Mukrani</h5>
                                                        <p className='text-muted'>50 mins ago</p>
                                                        <h6 className='py-1 profile-comments text-wrap'>As there is no companion loyal and loving than a dog, we are more than elated to present a awesome collection of happy birthday wishes for dogs with pictures. If you go back in time, you will find that dogs have always been men’s best friend and a watchful protector also. From hunting to surveillance, a dog stands by side of its owner unflinchingly and follows him like a shadow everywhere.    &#128578;
                                                        </h6>
                                                        <ul className="comment-option">
                                                            <li><Link to="#">like (5)</Link></li>
                                                            <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                {comment ? <div className="sub-comment">
                                                    <div className="media">
                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                        <div className="media-body ml-2">
                                                            <h5>Pabelo Mukrani</h5>
                                                            <p className='text-muted'>50 mins ago</p>
                                                            <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                            <ul className="comment-option">
                                                                <li><a href="#">like</a></li>
                                                                <li><a href="#">reply</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="media">
                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                        <div className="media-body  ml-2">
                                                            <h6>sufiya elija</h6>
                                                            <p className='text-muted'>50 mins ago</p>
                                                            <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                            <ul className="comment-option">
                                                                <li><a href="#">like</a></li>
                                                                <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <a href="#" className="loader">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                        load more replies
                                                    </a>
                                                </div> : null}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="reply w-100 m-2" style={{ position: 'absolute', bottom: '0' }} >
                                        {/* <div className="search-input mr-4 input-style icon-right">
                                                        <Input type="text" className="form-control emojiPicker"
                                                            placeholder="Comment...." />
                                                        <Link to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                        </Link>
                                                    </div> */}
                                        <div className='search-input mr-4 input-style icon-right'>
                                            <Input type='text' className="form-control emojiPicker" Placeholder='Write a comment...' />
                                            <div className='positionForSerachSmile'>
                                                <Smile color='#808080' strokeWidth={2} />
                                                <Send color='#808080' className='iconForcolor' strokeWidth={2} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }

            </div>
        )

    }

    const handleScrollUp = () => {
        // Get the current scroll position
        const currentPosition = window.scrollY;

        // Calculate the new scroll position
        const newPosition = currentPosition - 10;

        // Animate the scroll to the new position
        window.scrollTo({
            top: newPosition,
            behavior: 'smooth', // Add smooth scrolling effect
        });
    };
    // console.log("data============================?==========>",props?.data,props?.postId)

    const rearrange=(list,targetId)=>{
            // Ensure list is an array
            if (!Array.isArray(list)) {
                console.error("Expected an array but got:", list);
                return [];
            }
        
            // Find the index of the item with the targetId
            const targetIndex = list.findIndex(item => item.postId === targetId);
            console.log("Target Index:", targetIndex, "Target ID:", targetId);
        
            if (targetIndex === -1) {
                // If the item with the targetId is not found, return the original list
                return list;
            }
        
            // Extract the item with the targetId
            const [targetItem] = list.splice(targetIndex, 1);
        
            // Create a new array with the target item at the first index
            return [targetItem, ...list];
        
    }
    

     const targetId = props?.postId;
     const rearrangedList = rearrange(props?.data, targetId);

     console.log("Rearranged List:==========================", rearrangedList);

    
    
    console.log("data==========================================",props?.data,targetId);
    

    return (
        < >
            <CommentModal />
            <ShareModal
                isOpen={forwardOpen}
                shareModalToOpen={toggleForward}
            />

            {
                props.isOpen !== false ?
                    <Modal style={{ height: 'calc(100vh - 36px)', backdropFilter: 'brightness(0.5)' }} isOpen={props.isOpen} toggle={props.ShotzModalCarousel} className={`shortz-modal-n modals modal-dialog myModalTransparent carousel-container position-relative ${isOpenA ? 'modal-xl' : ''}`}  >
                        <XCircle size={30} className='close-icon close-icons' onClick={(e)=>crossFunction(e)} style={{ right: '-35vw' }} />
                        <div className='shortz-container'>
                            {props?.data?.map((item, index) => {
                                // scroll-item
                                return (<div className='scroll-item'> 
                                    <Row className='comment-col-close'>
                                        <Col xl={isOpenA ? '6' : '12'} lg={isOpenA ? '6' : '12'} md={isOpenA ? '11' : '12'} style={{ padding: '0px' }}>
                                            <div className='carousel-wrapper d-flex' onWheel={(e)=>handleWheel(event)} onMouseEnter={(e)=>handleMouseEnter(e)} onMouseLeave={(e)=>handleMouseLeave(e)}>
                                                <div className='carousel-slides p-0' style={{ width: '100%', height: '100%' }}>
                                                    <video
                                                        // ref={el => videoRefs.current[index] = el}
                                                        autoPlay={false}
                                                        onClick={(e)=>togglePlay(e)}
                                                        width="100%"
                                                        height='100%'
                                                        className='VideoFitforcover'
                                                        style={{ padding: '0px', objectFit: 'cover', borderRadius: `${isOpenA ? '1rem 0 0 1rem' : '1rem'}` }}
                                                    >
                                                        <source src={`${process.env.REACT_APP_IMAGEURL}${item?.shotzMediaURL}`} type="video/mp4" />
                                                    </video>

                                                <div className="text-gallery d-flex">
                                                    {isHovered ? <div className={`top-gallery-cont  ${!isOpenA ? 'simple-linear close ' : 'simple-linear open '}`} >
                                                        <img src="assets/images/shotz-icon.png" height={30} width={30} className='img-fluid' alt='' />
                                                        <h5 className="text-white font-weight-bold"><Eye size={25} /> &nbsp;{item.viewsCount}</h5>
                                                    </div> : null}

                                                    <div className="Music-gallery-cont mx-1 p-4 d-flex align-items-center" style={{ zIndex: '3' }} >
                                                        <p className='py-3'>{item.caption}</p>
                                                        <p className='py-3'> &nbsp;&nbsp; {item.hashtag}</p>
                                                    </div>
                                                    <div className={`bottom-gallery-cont padEdit align-items-center ${isOpenA ? (isHovered ? 'bottom-linear open' : null) : (isHovered ? 'bottom-linear close ' : null)} `}>
                                                        <div class='user-img rounded-circle d-flex' >
                                                            <img src={item.profileImageThumb}
                                                                className="rounded-circle" height={45} width={45} alt={item.fullName} />
                                                            <span class="available-stats online"></span>
                                                            <p className='shotz-text p-2'>{item.fullName}</p>
                                                        </div>
                                                        {/* <Button className='padding-for-shotz' color='primary' >Follow</Button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ShotzRightSlider'>
                                                {isOpenA ? null : <div className='d-flex flex-column justify-content-end align-items-center mt-auto'  >
                                                    {/* <div className='d-flex flex-column align-items-center' >
                                                <img style={{ cursor: 'pointer' }} width={40} src={Circlelike} alt='shareIcon' />
                                                <div style={{ color: '#E5E5E5' }}>25</div>
                                            </div> */}
                                                    {
                                                        LikeIcon ? <div className=' d-flex flex-column align-items-center'>
                                                            <img style={{ cursor: 'pointer' }} onClick={(e) => SetLikeIcon(!LikeIcon)} width={40} src={ActiveCirclelike} alt='shareIcon' />
                                                            <div style={{ color: '#E5E5E5' }}>{item.likesCount +1}</div>
                                                        </div> : <div className=' d-flex flex-column align-items-center'>
                                                            <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={40} src={Circlelike} alt='shareIcon' />
                                                            <div style={{ color: '#E5E5E5' }}>{item.likesCount}</div>
                                                        </div>
                                                    }
                                                    <div className=' d-flex flex-column align-items-center py-3' >
                                                        <img style={{ cursor: 'pointer' }} onClick={() => { toggleIsOpenA(item.postId); toggleComment() }} width={40} src={CommentLatest} alt='shareIcon' />
                                                        <div style={{ color: '#E5E5E5' }}>{item.commentsCount}</div>
                                                    </div>
                                                    <div className='d-flex flex-column align-items-center' onClick={toggleForward} >
                                                        <img width={40} style={{ cursor: 'pointer' }} src={ShareLatest} alt='shareIcon' />
                                                        <div style={{ color: '#E5E5E5' }}>Share</div>
                                                    </div>
                                                    <div className='py-3 d-flex flex-column align-items-center' style={{ marginBottom: '5rem' }} >
                                                        <div onClick={handleBookmark} style={{ cursor: 'pointer', background: '#E5E5E5', width: '40px', height: '40px' }} className='rounded-circle d-flex align-items-center justify-content-center'>
                                                            {BookmarkIcon ? <Bookmark style={{ cursor: 'pointer' }} size={25} color='#808080' /> : <Bookmark style={{ cursor: 'pointer' }} size={25} color='#81C14B' fill='#81C14B' />}
                                                        </div>
                                                        <div style={{ color: '#E5E5E5' }}>Bookmark </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={isOpenA ? '5' : '2'}
                                        lg={isOpenA ? '6' : '2'}
                                        md={isOpenA ? '1' : '2'}
                                        sm='5' className={isOpenA ? ' bg-transparent comment-col  py-0 cmnt-close-btn '
                                            : ' d-flex flex-column comment-col-close justify-content-end mt-auto animateClose   p-0'} style={{ paddingLeft: '1px', transition: 'all 0.3s ease 0s' }}>
                                        {/* transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; */}

                                        {isOpenA ? <Card className='comment-part border my-component ' style={{ height: 'calc(100vh - 56px)', borderRadius: " 0 1rem 1rem 0", position: 'relative' }} >
                                            <div className="user-detail d-flex justify-content-between py-1">
                                                <div className="user-media">
                                                    <div className="media px-2">
                                                        <Link className="user-img">
                                                            <img src={item.profileImageThumb} className="img-fluid rounded-circle bg-img" alt="user" />
                                                            <span className="available-stats"></span>
                                                        </Link>
                                                        <div className="media-body " style={{ cursor: 'pointer' }}>
                                                            <h6 className="user-name">{item.fullName}</h6>
                                                            <h6 >{item.homeAddress}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <UncontrolledButtonDropdown className='d-flex align-items-center' >
                                                    <DropdownToggle color='flat'>
                                                        <MoreVertical size={20} />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu obj-container'>
                                                        <DropdownItem onClick={toggleForward} className='border-bottom py-2 shotz-menu-hover-style' >
                                                            <h5><Share size={15} className='mr-2 icon-color-style' />Share</h5>
                                                        </DropdownItem>
                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style' >
                                                            <h5><EyeOff size={15} className='mr-2 icon-color-style' />Hide post from timeline</h5>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={toggleEditModalOpen} className='border-bottom py-2 shotz-menu-hover-style'>
                                                            <h5><Edit size={15} className='mr-2 icon-color-style' />Edit</h5>
                                                        </DropdownItem>
                                                        <DropdownItem className='border-bottom py-2 shotz-menu-hover-style'>
                                                            <h5><Copy size={15} className='mr-2 icon-color-style' />Copy Link</h5>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={toggleDownloadModalOpen} className='border-bottom py-2 menu-hover-style' >
                                                            <h5><Download size={15} className='mr-2 icon-color-style' />Download</h5>
                                                        </DropdownItem>
                                                        {/* <Button outline className='btn-icon' size='sm' color='primary' >
                                                            <Download size={18} />
                                                        </Button> */}
                                                        <DropdownItem onClick={toggleArchiveModalOpen} className='border-bottom py-2 menu-hover-style' >
                                                            <h5><Archive size={15} className='mr-2 icon-color-style' />Archive</h5>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={toggleDeleteModalOpen} className='py-2 menu-hover-style'>
                                                            <h5><Trash2 size={15} className='mr-2 icon-color-style' />Delete</h5>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div className="post-panel mb-0  " style={{ position: 'relative' }} >
                                                <div className="post-wrapper" >
                                                    <div className="post-details" >
                                                        <div className="like-panel">
                                                            <div className="left-emoji" style={{ cursor: 'pointer' }}>
                                                                {/* <ul>
                                                                    <li>
                                                                        <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                                    </li>
                                                                </ul> */}
                                                                <h6 >+{item.likesCount}</h6>
                                                            </div>
                                                            <div className="right-stats" style={{ cursor: 'pointer' }}>
                                                                <ul >
                                                                    <li>
                                                                        <span className='d-flex align-items-center'>{item.commentsCount} &nbsp; <p className='text-muted'>Comment</p></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className='d-flex align-items-center'>{item.sharesCount}  &nbsp;<p className='text-muted'>Share</p></span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="post-react">
                                                            <ul>
                                                                <li >
                                                                    <Link >
                                                                        <img src={like_circle_gray} height='26px' width='26px' alt="like" />&nbsp;Like
                                                                    </Link>
                                                                </li>
                                                                <li >
                                                                    <Link >
                                                                        {/* onClick={toggleIsOpenA}  */}
                                                                        <img src={comments_circle_gray} height='25px' width='25px' alt="comments" />&nbsp;Comment
                                                                    </Link>
                                                                </li>
                                                                <li >
                                                                    <Link onClick={toggleForward} >
                                                                        <img src={share_circle_gray} height='25px' width='25px' alt="share" />&nbsp;share
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="comment-section " style={{ cursor: 'pointer' }}>
                                                            <div className="comments d-block scrollForShareShotz">
                                                                <div className="main-comment">
                                                                    <div className="media">
                                                                        <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                        <div className="media-body ml-2">
                                                                            <h5>Pabelo Mukrani</h5>
                                                                            <p className='text-muted'>50 mins ago</p>
                                                                            <h6 className='py-1 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍&#128578;
                                                                            </h6>
                                                                            <ul className="comment-option">
                                                                                <li><Link to="#">like (5)</Link></li>
                                                                                <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    {comment ? <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h5>Pabelo Mukrani</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h6>sufiya elija</h6>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <a href="#" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                                </div>
                                                                <div className="main-comment">
                                                                    <div className="media">
                                                                        <img src="/assets/images/story-2.jpg" width={30} height={30} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                        <div className="media-body ml-2">
                                                                            <h5>Pabelo Mukrani</h5>
                                                                            <p className='text-muted'>50 mins ago</p>
                                                                            <h6 className='py-1 profile-comments text-wrap'>As there is no companion loyal and loving than a dog, we are more than elated to present a awesome collection of happy birthday wishes for dogs with pictures. If you go back in time, you will find that dogs have always been men’s best friend and a watchful protector also. From hunting to surveillance, a dog stands by side of its owner unflinchingly and follows him like a shadow everywhere.    &#128578;
                                                                            </h6>
                                                                            <ul className="comment-option">
                                                                                <li><Link to="#">like (5)</Link></li>
                                                                                <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    {comment ? <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h5>Pabelo Mukrani</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h6>sufiya elija</h6>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li onClick={toggleReply}><Link to="#">reply</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <a href="#" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reply w-100 m-2" style={{ position: 'absolute', bottom: '0' }} >
                                                {/* <div className="search-input mr-4 input-style icon-right">
                                                        <Input type="text" className="form-control emojiPicker"
                                                            placeholder="Comment...." />
                                                        <Link to="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                        </Link>
                                                    </div> */}
                                                <div className='search-input mr-4 input-style icon-right'>
                                                    <Input type='text' className="form-control emojiPicker" Placeholder='Write a comment...' />
                                                    <div className='positionForSerachSmile'>
                                                        <Smile color='#808080' strokeWidth={2} />
                                                        <Send color='#808080' className='iconForcolor' strokeWidth={2} />
                                                    </div>

                                                </div>
                                            </div>
                                        </Card> : null}
                                        {/* {isOpenA ? <span className='comment-closer d-flex align-items-center' onClick={toggleIsOpenA} style={{ paddingLeft: '1px', transition: 'all 0.3s ease 0s' }}>
                                            <ChevronLeft size={25} color='#81c14b' style={{ cursor: 'pointer' }} className='' />
                                        </span> : null} */}
                                        {/* {isOpenA ? (
                                            <span className='comment-closer d-flex align-items-center your-component' onClick={debouncedToggleIsOpenA} style={{ transition: 'all 1s ease 0.3s' }}>
                                                <ChevronLeft size={25} color='#81c14b' style={{ cursor: 'pointer' }} className='' />
                                            </span>
                                        ) : null} */}
                                        {isOpenA ? (
                                            <span className='comment-closer d-flex align-items-center your-component'  style={{ transition: 'all 1s ease 0.3s' }}>
                                                <ChevronLeft size={25} color='#81c14b' style={{ cursor: 'pointer' }} className='' />
                                            </span>
                                        ) : null}
                                    </Col>
                                </Row> </div>);
                            })}
                        </div>
                    </Modal> : null
            }

            {/* <DownloadModal
                isOpen={downloadModalOpen} downloadModalOpen={toggleDownloadModalOpen}
            /> */}
            {/* ------------- End Download Modal ------------- */}
            {/* ------------- Start Archive Modal ------------- */}

            {/* <ArchiveModal
                heading="Archived Shotz"
                discription="Do you really want to add archive Shotz"
                isOpen={archiveModalOpen}
                archiveModal={toggleArchiveModalOpen}
            /> */}
            {/*  ------------- End Archive Modal ------------- */}

            {/*  ------------- Start Delete Modal ------------- */}
            {/* <DeletedModal
                isOpen={deleteModalOpen}
                deleteModalOpens={toggleDeleteModalOpen}
            /> */}
            {/*  ------------- End Delete Modal ------------- */}
            {/*  ------------- Start Hide Shotz Modal ------------- */}

            {/* <HideModal
                isOpen={hideShotzModalOpen}
                hideModalOpen={toggleHideShotzModalOpen}
            /> */}
            {/*  ------------- End Hide Shotz Modal ------------- */}
            {/* ------------- Start Edit Shotz Modal ------------- */}

            {/* <EditModal
                isOpen={editModalOpen}
                updateOpen={toggleEditModalOpen}
            /> */}
            {/* ------------- End Edit Shotz Modal ------------- */}
        </>
    )
}
