import { Pagination, Stack } from '@mui/material'
import { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
// import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
import { listDataArticle, listDataArticles } from '../DummyArray'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'

const RecomenditionList = ({ display, space }) => {

    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }


    {/*-----------------------------------------------------Main return function----------------------------------------------------- */ }
    return (
        <>
            <div className='rem-mob-view'>

                <Row className='py-0'>

                    <Col md='12' className='' >

                        {listDataArticles.map((data, index) => (
                            <div className='border rounded my-2 on-hover-bg p-2' key={index} >
                                {/* <div color={`primary ${display}`} style={{ position: 'absolute', top: '8px', left: '4px', zIndex: '5', fontSize: '12px' }} className='rotated-text' ># {data.trendingNumber}</div> */}

                                <Row className='d-flex align-items-center px-2'>
                                    {/* <div className='d-flex ml-3'>
                                        <div className='right-triangle' style={{ position: 'absolute', top: '0px', zIndex: '4', left: '0px' }} ></div>
                                    </div> */}

                                    <Col xl='8'>
                                        <div className='d-flex p-2' >
                                            <div className='d-flex align-items-center'>
                                                <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center'>
                                                    <p className='PilesThistags text-center'>Top</p>
                                                    <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                                </div>

                                                <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                    <p className='PilesThistags text-center'>Trending</p>
                                                    <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                                </div>
                                            </div>

                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='d-flex justify-content-start align-items-start ml-4'>
                                                    <img src='https://media.istockphoto.com/id/1221286969/photo/nakki-lake-is-a-lake-in-the-indian-hill-station-of-mount-abu-in-aravalli-range.jpg?s=612x612&w=0&k=20&c=jLfbsoRnLfuSNLWDwk4fifmv3om-RaYgxR8b0wQJWVQ=' width={70} height={70} className=' ml-2 rounded ArticleShowdo' alt='' />
                                                    <div className='px-3 mt-3' >
                                                        <span className='user-profile text-nowrap' >{data.articleName}</span>
                                                        <div className='d-flex align-items-center' >
                                                            <small className='text-muted light-for-date'>{data.dateandtime}</small>
                                                            <div className='d-flex pl-1 align-items-center'  >
                                                            </div>
                                                        </div>
                                                        {/* <div className='d-flex flex-wrap' >
                                                            <p className='text-primary pr-1'>Top #{data.topsNumber}</p>
                                                            <p className='text-primary'>Trending #{data.trendingNumber}</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-start align-items-center ml-5'>
                                                    <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' alt='' />
                                                    <div>
                                                        <NavLink to='/MyProfile' style={{ color: 'black', whiteSpace: 'nowrap', fontSize: '13px' }} ><span className='user-profile pl-1'>{data.name}</span></NavLink><br></br>
                                                        <small className='text-muted light-for-date pl-1'>{data.dateandtime}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col xl='3'>
                                        <div className='d-flex justify-content-center ml-4'>
                                            <div className='d-flex align-items-center'>
                                          <p className='text-muted date-time-fs'>{data.dateandtime}</p> 
                                                <div onClick={() => handleLike()}>
                                                    {liked ?
                                                        
                                                        <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                        :
                                                        <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                    }
                                                </div>
                                                11.2k
                                            </div>
                                            <div className='d-flex align-items-center px-3' data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                                </div>
                                                11.2k
                                            </div>
                                            <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                                </div>
                                                11.2k
                                            </div>
                                        </div>
                                    </Col> */}
                                    <Col xl='1'>
                                        <div className='d-flex justify-content-center'>
                                            <UncontrolledButtonDropdown className='align-self-center' style={{ marginLeft: '34rem' }}>
                                                <DropdownToggle className='p-0 m-0' color='flat' >
                                                    <MoreHorizontal size={19} color='#999999' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific' style={{left:'-9px'}}>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Recommend</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View profile</NavLink></DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        ))}

                        {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}
                        {/* <div className='border rounded mt-3'>
                            <Row className='d-flex align-items-center p-4'>
                                <div className='d-flex ml-3'>
                                    <Badge color={`light-primary ${display}`}>
                                        Trending #1
                                    </Badge>
                                    <Badge color='primary' pill className={space}>
                                        Top #96
                                    </Badge>
                                </div>
                                <Col xl='6'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img src='https://images.unsplash.com/photo-1563387852576-964bc31b73af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=907&q=80' width={80} height={80} className='m-3 ml-2 rounded' />
                                            <div>

                                                <b>Order on the Court</b>
                                                <p className='BizPageCategory'>Game</p>
                                                <p className='text-muted'>20 Oct at 12:00 pm</p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='m-3 ml-2 rounded-circle' />
                                            <div>
                                                <p>Floyd Miles</p>
                                                <p className='text-muted'>19 sept at 07:00 pm</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='4'>
                                    <div className='d-flex justify-content-around ml-2'>
                                        <div className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                            </div>
                                            11.2k
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='2'>
                                    <div className='text-end'>
                                        <UncontrolledButtonDropdown className='align-self-center'>
                                            <DropdownToggle className='p-0 m-0' color='flat' >
                                                <MoreHorizontal size={19} color='#000000' />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>

                            </Row>
                        </div> */}

                        {/*---------------------------- Pagination starts here ------------------ */}
                        {/* <div className='d-flex justify-content-center p-2'>
                            <Stack spacing={2}>
                                <Pagination
                                    count={10}
                                    color='primary'
                                    size="large" />
                            </Stack>
                        </div> */}

                        <div className='d-flex align-items-center py-1' >

                            <Row className='w-100'>
                                {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                                <Col xl='3' className='d-flex align-items-center' >
                                    <span className='' >
                                        {/* <p>Total Articles Count : <span className='text-primary' >{listDataArticle.length}</span></p> */}
                                        <p>Showing 1 to {listDataArticle.length} of 200 entries</p>
                                    </span>
                                </Col>

                                {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                                <Col xl='6' className='d-flex justify-content-center' >
                                    <div className='d-flex p-2 mx-auto'  >
                                        <Stack spacing={2}>
                                            <Pagination
                                                count={10}
                                                color='primary'
                                                size="large" />
                                        </Stack>
                                    </div>
                                </Col>

                                <Col xl='3' >
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
                {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
                <ShareEventModal
                    isOpen={shareOpen}
                    updateOpen={toggleShareOpen}
                />
                {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


                {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}

                <ReportModal
                    reportOpen={reportOpen}
                    toggleReport={toggleReport}
                />
                {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

            </div>
        </>
    )
}

export default RecomenditionList;