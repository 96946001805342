// import { Pagination, Rating, Stack } from '@mui/material'
// import React from 'react'
// import { useState } from 'react'
// import { Fragment } from 'react'
// import { Button, CardTitle, Col, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
// import RplyImg from '../Img/RplyImg.png'
// import RevImg from '../Img/review.png'
// import RevImg1 from '../Img/picture.png'
// import { Link, useParams } from 'react-router-dom'
// import { Send } from 'react-feather'
// import Snd from '../../profile-components/Img/snd.png'
// import { getBizPageReviewAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
// import { useDispatch, useSelector } from 'react-redux'
// import { useEffect } from 'react'

// const PublicReview = () => {

//     let dispatch = useDispatch();
//     const { id } = useParams();

//     useEffect(() => {
//             dispatch(getBizPageReviewAction(id)) 
//     }, []);

//     const reviews = useSelector((state) => { return state.getBizPageReviewData});
//     const { loading: loadingGetReviews, error: errorGetReviews, result: resultGetReviews } = reviews

//     console.log('Reviews', resultGetReviews)

//     const [show, setShow] = useState(false)
//     const [replay, setReplay] = useState(false)
//     const [bata, setData] = useState(false)
//     const [deleteReviewOpen, setDeleteReviewOpen] = useState(false)
//     const toggleDelete = () => {
//         setDeleteReviewOpen((preState) => !preState)
//     }
//     const [reportOpen, setReportOpen] = useState(false)
//     const toggleReport = () => {
//         setReportOpen((preState) => !preState)
//     }
//     const [comment, setComment] = useState('')
//     const handleChange = (val) => {
//         console.warn(val.target.value)
//         setData(val.target.value)
//         setComment(false)
//     }


//     const ReportDataInfo = [
//         {
//             name: "Spam"
//         },
//         {
//             name: "Nudity"
//         },
//         {
//             name: "Violence"
//         },
//         {
//             name: "Harrasment"
//         },
//         {
//             name: "Suicide or self-injury"
//         },
//         {
//             name: "Terrorism"
//         },
//         {
//             name: "Others"
//         }
//     ]
//     //===================== Main_return_section====================//
//     return (
//         <Fragment>
//             <div className='review-counter section-b-space bizdetailprofile'>
//                 <CardTitle className="Biz_ReviewTab_heading_section">
//                     <h3>Public Reviews</h3>
//                 </CardTitle>
//                 <div className='p-4'>
//                     {
//                         resultGetReviews?.map((data) => (
//                             <>


//                                 <div className='BizReview_section_box'>
//                                     <div className='d-flex align-items-start'>
//                                         <img src={data.user.profileImage} alt="reviewImg" className='img-fluid rounded-circle' width={50} />
//                                         <div className='ml-3 BizReview_Detail'>
//                                             <h3>{data.user.fullName}</h3>
//                                             <Rating className='py-1' name="read-only" value={data.ratingPoint} readOnly size="small" />
//                                         </div>
//                                     </div>
//                                     <div className='d-flex align-items-center'>
//                                         <p className=''>{data.activeDate}</p>
//                                     </div>
//                                 </div>
//                                 <div className='BizReview_Center_Section'>
//                                     <p>{data.remark}..{comment ? <>{bata}</> : null}</p>
//                                 </div>

//                                 {data.type === "Media" ?
//                                     <>
//                                         <div className='BizReview_Center_Section Reply_Biz_img py-2'>
//                                             <Row>
//                                                 <Col md={4}>
//                                                     <img src={RplyImg} alt="img" className='Biz_ReplyImg_section ' />
//                                                 </Col>
//                                             </Row>
//                                         </div>

//                                     </> : null

//                                 }
//                                 {data.typeof === "Reply" ?
//                                     <>
//                                         <div className='BizReview_Center_Section BizReview_section_box py-2'>
//                                             <div>
//                                                 <span onClick={() => setShow(!show)} className='text-primary'>Reply (2)</span>
//                                                 <span onClick={toggleReport} className='ml-4 text-primary'>Report</span>
//                                             </div>
//                                             <div>
//                                                 <p>{data.helpfullCount} found this helpful</p>
//                                             </div>
//                                         </div>
//                                         {
//                                             show ? <>
//                                                 <div className='BizReview_Center_Section mt-2'>
//                                                     <div className='BizReview_section_box'>
//                                                         <div className='d-flex align-items-start'>
//                                                             <img src={data.img} alt="reviewImg" className='img-fluid rounded-circle' width={50} />
//                                                             <div className='ml-3 BizReview_Detail'>
//                                                                 <h3>{data.name}</h3>
//                                                                 <p><Rating className='py-1' name="read-only" value={data.value} readOnly size="small" /></p>
//                                                             </div>
//                                                         </div>
//                                                         <div className='d-flex align-items-center'>
//                                                             <p className='text-muted'>{data.activeDate}</p>
//                                                         </div>
//                                                     </div>
//                                                     <div className='BizReview_Center_SubSection '>
//                                                         <p>{data.text}</p>
//                                                     </div>
//                                                     <div className='BizReview_Center_SubSection  BizReview_section_Subbox py-2'>
//                                                         <div>
//                                                             <span className='text-primary'>Edit</span>
//                                                             <span onClick={toggleDelete} className='ml-4 text-primary'>Delete</span>
//                                                         </div>
//                                                         <div>
//                                                             <p>{data.helpfullCount} found this helpful</p>
//                                                         </div>
//                                                     </div>
//                                                     <hr className='text-muted' />
//                                                 </div>
//                                                 <div className='BizReview_Center_Section mt-2'>
//                                                     <div className='BizReview_section_box'>
//                                                         <div className='d-flex align-items-start'>
//                                                             <img src={data.img} alt="reviewImg" className='img-fluid rounded-circle' width={50} />
//                                                             <div className='ml-3 BizReview_Detail'>
//                                                                 <h3>{data.name}</h3>
//                                                                 <p><Rating className='py-1' name="read-only" value={data.value} readOnly size="small" /></p>
//                                                             </div>
//                                                         </div>
//                                                         <div className='d-flex align-items-center'>
//                                                             <p className='text-muted'>{data.activeDate}</p>
//                                                         </div>
//                                                     </div>
//                                                     <div className='BizReview_Center_SubSection '>
//                                                         <p>{data.text}</p>
//                                                     </div>
//                                                     <div className='BizReview_Center_SubSection  BizReview_section_Subbox py-2'>
//                                                         <div>
//                                                             <span className='text-primary'>Edit</span>
//                                                             <span onClick={toggleDelete} className='ml-4 text-primary'>Delete</span>
//                                                         </div>
//                                                         <div>
//                                                             <p>{data.helpfullCount} found this helpful</p>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </> : null
//                                         }
//                                     </> :
//                                     <Fragment>
//                                         <div className='BizReview_Center_Section BizReview_section_box py-2'>
//                                             <div>
//                                                 <span className='text-primary' onClick={() => setReplay(!replay)} >Reply </span>

//                                                 <span onClick={toggleReport} className='ml-4 text-primary'>Report</span>
//                                             </div>
//                                             <div>
//                                                 <p>{data.helpfullCount} found this helpful</p>
//                                             </div>
//                                         </div>
//                                         <div className='BizReview_Center_Section'>
//                                             {replay ?
//                                                 // <Form>
//                                                 //     <FormGroup>
//                                                 //         <Input
//                                                 //             type="text"
//                                                 //             placeholder="Type your comment here"
//                                                 //             className=""
//                                                 //         />
//                                                 //         <div>
//                                                 //             <Send />
//                                                 //         </div>

//                                                 //     </FormGroup>
//                                                 // </Form>

//                                                 <div className="search-input input-style input-lg icon-right">
//                                                     <Input
//                                                         type="text"
//                                                         placeholder="Type your comment here"
//                                                         onChange={handleChange}
//                                                         className="form-control emojiPicker"
//                                                     />
//                                                     <Link to="#">
//                                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
//                                                     </Link>
//                                                     <div className='Media_Modal_send_Icon ' onClick={() => setComment(!comment)}>
//                                                         <img src={Snd} width={14} height={14} alt="snd" />

//                                                     </div>
//                                                 </div>

//                                                 : null}
//                                         </div>
//                                     </Fragment>
//                                 }


//                                 <hr className='text-muted' />
//                             </>
//                         ))
//                     }
//                 </div>

//             </div>
//             <div className='d-flex justify-content-center mb-3'>
//                 <Stack>
//                     <Pagination count={10} className='PaginationCount'>
//                     </Pagination>
//                 </Stack>
//             </div>

//             {/*===================== start_Review_modal_section ========================*/}
//             <div className='Review_Deleted_modal'>
//                 {
//                     deleteReviewOpen !== false &&
//                     <Modal isOpen={deleteReviewOpen} toggle={toggleDelete} className='modal-dialog-centered'>
//                         <ModalHeader className="BizDelete_Heading">
//                             <h2>Are You Sure ?</h2>
//                         </ModalHeader>
//                         <ModalBody>
//                             <div className='text-center Biz_ReviewPage_content_section'>
//                                 <p>Are you sure that you want to Delete your <br /> Review?</p>
//                             </div>
//                             <div className='mt-4 text-center'>
//                                 <Button onClick={toggleDelete} className="w-25" outline>Cancel</Button>
//                                 <Button onClick={toggleDelete} className="w-25 ml-2" color="primary"  >Confirm?</Button>
//                             </div>
//                         </ModalBody>
//                     </Modal>
//                 }
//             </div>
//             <div className='Review_Report_modal'>
//                 {
//                     reportOpen !== false &&
//                     <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
//                         <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
//                             <h3>Report a Review</h3>
//                         </ModalHeader>
//                         <ModalBody>
//                             <div className='Modal_Body_Section'>
//                                 <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
//                             </div>
//                             {
//                                 ReportDataInfo.map((data) => (
//                                     <div className='d-flex justify-content-between mt-4 Report_Section'>
//                                         <h4>{data.name}</h4>
//                                         <div>
//                                             <Input
//                                                 name="radio1"
//                                                 type="radio"
//                                                 className="Check"
//                                             />
//                                             {' '}
//                                         </div>
//                                     </div>
//                                 ))
//                             }
//                             <div className='d-flex justify-content-center mt-3'>
//                                 <Input
//                                     id="ReportName"
//                                     name="ReportName"
//                                     placeholder="Write a comment..."
//                                     type="Report"
//                                 />
//                             </div>
//                             <hr className='w-100 text-muted' />
//                             <div className='text-center'>
//                                 <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
//                                 <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
//                             </div>
//                         </ModalBody>

//                     </Modal>

//                 }

//             </div>
//             {/*===================== end_Review_modal_section ========================*/}


//         </Fragment>
//     )
// }

// export default PublicReview


import React, { Fragment, useEffect } from 'react'
import Headers from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import TopBanner from '../TopBannerSection/TopBanner'
import BizDetailMenu from '../../biz-components/BizDetailMenu'
import { Button, CardTitle, Col, Input, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap'
import { Pagination, Rating, Stack, Radio } from '@mui/material'
import RevImg from '../Img/review.png'
import RevImg1 from '../Img/picture.png'
import { useState } from 'react'
import RplyImg from '../Img/RplyImg.png'
import { Link } from 'react-router-dom'
import { MapPin, Star, XCircle ,Send} from 'react-feather';
import RateImg from '../Img/RateImg.png'

const PublicReview = () => {
    const [show, setShow] = useState(false)
    const [deleteReviewOpen, setDeleteReviewOpen] = useState(false)
    const toggleDelete = () => {
        setDeleteReviewOpen((preState) => !preState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const [askOpen, setAskOpen] = useState(false)
    const toggleAsk = () => {
        setAskOpen((preState) => !preState)
    }

    const [rateBizOpen, setRateBizOpen] = useState(false)
    const toggleRateBiz = () => {
        setRateBizOpen((preState) => !preState)
    }

    const [remarks, setRemarks] = useState(0);
    function addRemarkBizPage(event) {
        setRemarks(event.target.value)
    }
    function handleStarClick(value) {
        setPoints(value);
    }

    const [isReplyOpen, setIsReplyOpen] = useState(false);
    const [isReplyOpenId, setIsReplyOpenId] = useState(-1);

    const toggleReply = (id) => {
            setIsReplyOpenId(id)
            if(isReplyOpenId === id) {
                setIsReplyOpenId(-1)
            }
        setIsReplyOpen(!isReplyOpen);
    };

    useEffect(() => {
    }, [isReplyOpenId, isReplyOpen]) 
    const [points, setPoints] = useState(0);

    const [expandedIndices, setExpandedIndices] = useState([]);

    const toggleExpand = (index) => {
        const isExpanded = expandedIndices.includes(index);
        if (isExpanded) {
            setExpandedIndices(expandedIndices.filter((i) => i !== index));
        } else {
            setExpandedIndices([...expandedIndices, index]);
        }
    };
    const [expandedIndex1, setExpandedIndex1] = useState(-1);

    const toggleExpand1 = (index) => {
        setExpandedIndex1(expandedIndex1 === index ? -1 : index);
    };
    const [expandedIndex2, setExpandedIndex2] = useState(-1);

    const toggleExpand2 = (index) => {
        setExpandedIndex2(expandedIndex2 === index ? -1 : index);
    };

    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        } else {
            return text.substr(0, maxLength) + "...";
        }
    }

    
    const ReviewPreviewArray = [
        {
            name: "Wade Warren",
            value: 3,
            img: RevImg,
            typeof: "NoReply",
            activeDate: "June 5, 2019",
            text: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations, managers can really dive into individual performance, employee motivations, and goals. This added level of depth shows employees their development is valued and gives managers ways to help employees grow.",
            helpCount: "24"


        },
        {
            name: "Marvin McKinney",
            value: 4,
            typeof: "Reply",
            img: RevImg1,
            activeDate: "June 8, 2019",
            text: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations, managers can really dive into individual performance, employee motivations, and goals. This added level of depth shows employees their development is valued and gives managers ways to help employees grow.",
            helpCount: "20",
            replyCount: 2
        },
        {
            name: "Wade Warren",
            value: 3,
            img: RevImg,
            typeof: "NoReply",
            type: "Media",
            activeDate: "June 5, 2019",
            text: "We’re here to put an end to that. By asking specific, targeted questions in employee evaluations, managers can really dive into individual performance, employee motivations, and goals. This added level of depth shows employees their development is valued and gives managers ways to help employees grow.",
            helpCount: "24"
        },
    ]
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const BizPageReview = () => (
        <>
            <div className="review-counter section-b-space bizdetailprofile">

                <div className='p-4'>
                    <h4>Your BizPage  Rating </h4>
                    <h1 className='p-1'>4.9</h1>
                    <div className='d-flex justify-content-between'>
                        <Rating name="read-only" value={4} readOnly />
                        <p className='text-muted'>(31)</p>
                    </div>
                    <div className='py-3'>
                        <h4>Your Reviews summary</h4>
                        <div className='p-3'>
                            <span className='d-flex text-muted mt-2'>5 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={100} /></span>
                            <span className='d-flex text-muted mt-2'>4 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={75} /></span>
                            <span className='d-flex text-muted mt-2'>3 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={50} /></span>
                            <span className='d-flex text-muted mt-2'>2 <Progress className="Biz_Progress_Bar  w-100 ml-2" value={25} /></span>
                            <span className='d-flex text-muted mt-2'>1&nbsp; <Progress className="Biz_Progress_Bar  w-100 ml-2" value={60} /></span>
                        </div>
                    </div>
                    {/* <div className='py-2 d-flex align-items-center'>
                        <img src={RevImg} className="rounded-circle" width={50} />
                        <div className='ml-3' onClick={toggleAsk}>
                            <Button color='primary'>Rate this page </Button>
                        </div>
                    </div> */}
                    <hr className='text-muted' />
                    <Link className='BizReview_Link' to="#">Biz page Policy</Link>
                </div>
            </div>
        </>
    )
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = (data) => {
        setModalData(data);
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const toggleReplyImageModal = () => {
        setReplyImageModalOpen(!replyImageModalOpen);
    };

    const [replyImageModalOpen, setReplyImageModalOpen] = useState(false);
    const handleCloseReplyImageModal = () => {
        setReplyImageModalOpen(false);
    };





    const PublicReviewPrView = () => (
        <Fragment>
            <div className='review-counter section-b-space bizdetailprofile'>
                <CardTitle>
                    <h3>Public Reviews</h3>
                </CardTitle>
                <div className='p-4'>
                    {
                        ReviewPreviewArray.map((data, index) => (
                            <Fragment key={index}>


                                <div className='BizReview_section_box1'>
                                    <div className='d-flex align-items-start'>
                                        <div onClick={() => toggleModal(data)} >
                                            <img src={data.img} alt="reviewImg" className='img-fluid rounded-circle' width={50} />
                                        </div>
                                        <div className='ml-3 BizReview_Detail'>
                                            <h3>{data.name}</h3>
                                            <Rating className='py-1' name="read-only" value={data.value} readOnly size="small" />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <p className='text-muted'>{data.activeDate}</p>
                                    </div>
                                </div>
                                <div
                                // className='BizReview_Center_Section'
                                >
                                    {data.text.length > 20 ? (
                                        <p>
                                            {expandedIndices.includes(index)
                                                ? data.text
                                                : `${data.text.slice(0, 150)}... `}
                                            <span
                                                className='see-more-link'
                                                style={{ color: 'green', cursor: 'pointer' }}
                                                onClick={() => toggleExpand(index)}
                                            >
                                                {expandedIndices.includes(index) ? 'See less' : 'See more'}
                                            </span>
                                        </p>
                                    ) : (
                                        <p>{data.text}</p>
                                    )}
                                </div>

                                {data.type === "Media" ?
                                    <>
                                        <div className=' Reply_Biz_img py-2' onClick={toggleReplyImageModal}>
                                            <Row>
                                                <Col md={4}>
                                                    <img src={RplyImg} alt="img" className='Biz_ReplyImg_section ' />
                                                </Col>
                                            </Row>
                                        </div>

                                    </> : null

                                }
                                <Modal isOpen={replyImageModalOpen} toggle={handleCloseReplyImageModal} centered>

                                    <div onClick={handleCloseReplyImageModal} className="Modal_False_blk1">
                                        <XCircle color="#FFFFFF" />
                                    </div>



                                    <div>
                                        <img src={RplyImg} alt="" style={{ width: '100%' }} />
                                    </div>


                                </Modal>
                                {data.typeof === "Reply" ?
                                    <>
                                        <div className=' BizReview_section_box py-2 repfound'>
                                            <div>
                                                <span onClick={() => setShow(!show)} className='text-primary'style={{cursor:"pointer"}} >Reply (2)</span>
                                                <span onClick={toggleReport} className='ml-4 text-primary'style={{cursor:"pointer"}} >Report</span>
                                            </div>
                                            <div>
                                                <p>{data.helpCount} found this helpful</p>
                                            </div>
                                        </div>
                                        {
                                            show ? <>
                                                <div className='BizReview_Center_Section mt-2'>
                                                    <div className='BizReview_section_box1'>
                                                        <div className='d-flex align-items-start'>
                                                            <div onClick={() => toggleModal(data)}>
                                                                <img src={data.img} alt="reviewImg" className='img-fluid rounded-circle' width={50} />
                                                            </div>
                                                            <div className='ml-3 BizReview_Detail'>
                                                                <h3>{data.name}</h3>
                                                                <p><Rating className='py-1' name="read-only" value={data.value} readOnly size="small" /></p>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <p className='text-muted'>{data.activeDate}</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        {data.text.length > 20 ? (
                                                            <p>
                                                                {expandedIndex2 === index
                                                                    ? data.text
                                                                    : `${data.text.slice(0, 150)}... `}
                                                                <span
                                                                    className='see-more-link'
                                                                    style={{ color: 'green', cursor: 'pointer' }}
                                                                    onClick={() => toggleExpand2(index)}
                                                                >
                                                                    {expandedIndex2 === index ? 'See less' : 'See more'}
                                                                </span>
                                                            </p>
                                                        ) : (
                                                            <p>{data.text}</p>
                                                        )}
                                                    </div>
                                                    <div className=' BizReview_section_box1 py-2'>
                                                        <div>
                                                            <span className='text-primary' onClick={toggleRateBiz} style={{cursor:"pointer"}} >Edit</span>
                                                            <span onClick={toggleDelete} className='ml-4 text-primary' style={{cursor:"pointer"}} >Delete</span>
                                                        </div>
                                                        <div>
                                                            <p>{data.helpCount} found this helpful</p>
                                                        </div>
                                                    </div>
                                                    <hr className='text-muted' />
                                                </div>
                                                <div className='BizReview_Center_Section mt-2'>
                                                    <div className='BizReview_section_box1'>
                                                        <div className='d-flex align-items-start'>
                                                            <div onClick={() => toggleModal(data)}>
                                                                <img src={data.img} alt="reviewImg" className='img-fluid rounded-circle' width={50} />
                                                            </div>
                                                            <div className='ml-3 BizReview_Detail'>
                                                                <h3>{data.name}</h3>
                                                                <p><Rating className='py-1' name="read-only" value={data.value} readOnly size="small" /></p>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <p className='text-muted'>{data.activeDate}</p>
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        {data.text.length > 20 ? (
                                                            <p>
                                                                {expandedIndex1 === index
                                                                    ? data.text
                                                                    : `${data.text.slice(0, 150)}... `}
                                                                <span
                                                                    className='see-more-link'
                                                                    style={{ color: 'green', cursor: 'pointer' }}
                                                                    onClick={() => toggleExpand1(index)}
                                                                >
                                                                    {expandedIndex1 === index ? 'See less' : 'See more'}
                                                                </span>
                                                            </p>
                                                        ) : (
                                                            <p>{data.text}</p>
                                                        )}
                                                    </div>
                                                    <div className=' BizReview_section_box1 py-2'>
                                                        <div>
                                                            <span className='text-primary' onClick={toggleRateBiz} style={{cursor:"pointer"}} >Edit</span>
                                                            <span onClick={toggleDelete} className='ml-4 text-primary' style={{cursor:"pointer"}} >Delete</span>
                                                        </div>
                                                        <div>
                                                            <p>{data.helpCount} found this helpful</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> : null
                                        }
                                    </> :
                                    <div className='BizReview_section_box py-2 repfound'>
                                        <div>
                                            <span className='text-primary' onClick={() => toggleReply(index)} style={{cursor:"pointer"}} >Reply </span>
                                            <span onClick={toggleReport} className='ml-4 text-primary' style={{cursor:"pointer"}} >Report</span>
                                        </div>
                                       
                                        <div>
                                            <p>{data.helpCount} found this helpful</p>
                                        </div>

                                    </div>
                                    

                                }
                                 {index === isReplyOpenId && (
                                            <div className='d-flex'>
                                                <Input type="text" placeholder="Write your reply here" />
                                                {/* Add any additional reply functionality or UI here */}
                                                <div className='sendiconbiz'>
                                                <Send color='#808080' className='iconForcolor' strokeWidth={2} style={{cursor:"pointer"}}  />
                                                </div>
                                            </div>
                                        )}




                                <hr className='text-muted' />
                                <Modal isOpen={isModalOpen} toggle={handleCloseModal} centered>

                                    <div onClick={handleCloseModal} className="Modal_False_blk1">
                                        <XCircle color="#FFFFFF" />
                                    </div>


                                    {/* Modal content using modalData */}
                                    <div>
                                        <img src={modalData?.img} alt="" style={{ width: '100%' }} />
                                    </div>
                                    {/* ... Other modal content ... */}

                                </Modal>
                            </Fragment>
                        ))
                    }
                    
                </div>

            </div>
            <div className='d-flex justify-content-center mb-3'>
                <Stack>
                    <Pagination count={5} className='PaginationCount'>
                    </Pagination>
                </Stack>
            </div>
        </Fragment >

    )
    const [radioActive, setRadioActive] = useState(null)
    //---------------------------- main function---------------------//
    return (
        <>
            <Headers />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="bizdetailprofile">
                        <TopBanner />

                    </div>

                    <div className="bizdetailprofile">
                        <BizDetailMenu />

                    </div>
                    <div className="container-fluid section-t-space px-0">
                        <div className="page-content">
                            <div className="content-center w-100">
                                <div className="gdpost-about-blk">
                                    <Row>
                                        <Col md={4} className='' >
                                            <BizPageReview />
                                        </Col>
                                        <Col md={8}>
                                            <PublicReviewPrView />
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Review_Report_modal'>
                        {
                            reportOpen !== false &&
                            <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                                <ModalHeader>
                                    <div className='Report_modal_Heading_section text-center '>
                                        <span>Report a Review</span>
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='Modal_Body_Section text-center'>
                                        <p>Tell us about what you feel and help us to  make Sociomee more secure.</p>
                                    </div>
                                    {
                                        ReportDataInfo.map((data, index) => (
                                            <div
                                                key={index}
                                                className='d-flex justify-content-between Report_Section'
                                            >
                                                <h5
                                                    className='d-flex reort'
                                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                                                    }}
                                                >
                                                    {data.name}
                                                </h5>
                                                <div>
                                                    <Radio
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        name="radio-buttonsOne"

                                                        className='Radio_btn_check'
                                                        checked={radioActive === index}
                                                        onClick={() => { setRadioActive((prevIndex) => (prevIndex === index ? null : index)); }}
                                                    />
                                                    {' '}
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className='d-flex justify-content-center mt-3'>
                                        <Input
                                            id="ReportName"
                                            name="ReportName"
                                            placeholder="Write a comment..."
                                            type="text"
                                        />
                                    </div>
                                    <hr className='w-100 text-muted' />
                                    <div className='text-center'>
                                        <Button color='primary' className="Report_summit_btn" style={{cursor:"pointer"}} >Submit</Button>
                                        {/* <Button toggle={toggleReport} className="Report_cancel_btn ml-2" outline>Cancel</Button> */}
                                        <Button className="Report_cancel_btn ml-2" onClick={toggleReport} style={{cursor:"pointer"}} >Cancel</Button>
                                    </div>
                                </ModalBody>

                            </Modal>

                        }
                    </div>
                    <div className='Rate_This_page_modal'>
                        {
                            rateBizOpen !== false &&
                            <Modal isOpen={rateBizOpen} toggle={toggleRateBiz} className='modal-dialog-centered' >
                                <ModalHeader className="Biz_Rating_SECTION">
                                    <h3>Edit your reviews</h3>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='Biz_Review_detail_section1 '>
                                        <img src={RateImg} alt="review_img" className='img-fluid rounded-circle ' width={80} height={80} /> 
                                        <div className='ml-3 '>
                                            <h4>THE DETECTOR CLUB</h4>
                                            <p className='revimap'><MapPin size={15} color="#808080" />&nbsp; {truncateText("228 Hope Street Glasgow, G2 3PN 0141 332", 20)} </p>
                                            <p className='revimap1'><MapPin size={15} color="#808080" />&nbsp; 228 Hope Street Glasgow, G2 3PN 0141 332 </p>
                                            <b><Star fill='#FFC700' color='#FFC700' size={12} />&nbsp; 2.6 / 5 Rating </b>
                                        </div>
                                    </div>
                                    <hr className='text-muted' />
                                    <div className='Biz_Rating_sab_section'>
                                        <h3>Rate your experience</h3>
                                        <div className='d-flex py-3 Biz_Rating_status bizeditrat'>
                                            <div className='text-center '>
                                                <Star
                                                    fill={points >= 1 ? '#F9AF21' : '#E0E0E0'}
                                                    color={points >= 1 ? '#F9AF21' : '#E0E0E0'}
                                                    size={41}
                                                    onClick={() => handleStarClick(1)}
                                                />
                                                <h6>Very Poor</h6>
                                            </div>
                                            <div className='text-center ml-3'>
                                                <Star
                                                    fill={points >= 2 ? '#F9AF21' : '#E0E0E0'}
                                                    color={points >= 2 ? '#F9AF21' : '#E0E0E0'}
                                                    size={41}
                                                    onClick={() => handleStarClick(2)}
                                                />
                                                <h6>Poor</h6>
                                            </div>
                                            <div className='text-center ml-3'>
                                                <Star
                                                    fill={points >= 3 ? '#F9AF21' : '#E0E0E0'}
                                                    color={points >= 3 ? '#F9AF21' : '#E0E0E0'}
                                                    size={41}
                                                    onClick={() => handleStarClick(3)}
                                                />
                                                <h6>Average</h6>
                                            </div>
                                            <div className='text-center ml-3'>
                                                <Star
                                                    fill={points >= 4 ? '#F9AF21' : '#E0E0E0'}
                                                    color={points >= 4 ? '#F9AF21' : '#E0E0E0'}
                                                    size={41}
                                                    onClick={() => handleStarClick(4)}
                                                />
                                                <h6>Good</h6>
                                            </div>
                                            <div className='text-center ml-3'>
                                                <Star
                                                    fill={points >= 5 ? '#F9AF21' : '#E0E0E0'}
                                                    color={points >= 5 ? '#F9AF21' : '#E0E0E0'}
                                                    size={41}
                                                    onClick={() => handleStarClick(5)}
                                                />
                                                <h6>Excellent</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='Biz_Rating_sab_section '>
                                        <h3>Write about your experience</h3>
                                        <div className='py-2'>
                                            <textarea placeholder='Type your experieace here...' className="form-control" name="" id="" cols="15" rows="5" maxLength={300}
                                                onChange={addRemarkBizPage}
                                            ></textarea>
                                            <p className='d-flex justify-content-end text-muted mt-1'>Max 300 Characters</p>
                                        </div>
                                    </div>
                                    <div className='text-center py-2'>
                                        <Button outline className="" onClick={toggleRateBiz} >Cancel</Button>
                                        <Button color="primary" onClick={toggleRateBiz} className=" ml-2" >Submit</Button>

                                    </div>

                                </ModalBody>

                            </Modal>
                        }
                    </div>
                    <div className='Review_Deleted_modal'>
                        {
                            deleteReviewOpen !== false &&
                            <Modal isOpen={deleteReviewOpen} toggle={toggleDelete} className='modal-dialog-centered'>
                                <ModalHeader className="d-flex justify-content-center">
                                    <h2>Are You Sure ?</h2>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='text-center Remove_Modal_section'>
                                        <p>Are you sure that you want to Delete your <br /> Review?</p>
                                    </div>
                                    <div className='mt-4 text-center'>
                                        <Button onClick={toggleDelete} outline>Cancel</Button>
                                        <Button onClick={toggleDelete} color="primary" className="ml-2" >Confirm</Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        }
                    </div>
                    <div>
                        {
                            askOpen !== false &&
                            <Modal isOpen={askOpen} toggle={toggleAsk} className='modal-dialog-centered'>
                                <ModalHeader className="Ask_Review_heading" >
                                    <h2>Ask a Question</h2>
                                </ModalHeader>
                                <ModalBody>
                                    <div className='p-3'>
                                        <h4 className='pb-2 font-weight-bold'>Question</h4>
                                        <textarea placeholder='Type your answer here... ' className="form-control" name="" id="" cols="15" rows="5"></textarea>
                                        <p className='d-flex justify-content-end text-muted'>Max 240 charectors </p>
                                    </div>
                                    <div className='text-center'>
                                        <Button onClick={toggleAsk} outline>Cancel</Button>
                                        <Button onClick={toggleAsk} className=" ml-2" color="primary">Submit</Button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        }
                    </div>
                </div>
                <RightSidebar />
            </div>


        </>
    )
}

export default PublicReview