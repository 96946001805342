import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {  ChevronDown } from 'react-feather'
import {useNavigate } from 'react-router-dom'
import {  Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap'
import { Pagination,Stack} from '@mui/material'

const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none', // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: 'rgb(230, 243, 219)',
            fontSize: '12px',
            paddingLeft: '12px',
            paddingRight: '4px',
            whiteSpace: '',
            color: '#5E5873',
            // fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            // marginTop: '1%',
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: '400'
        }
    }
}
const ViewListStatement = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])
    const [checkList, setCheckList] = useState({
        serialnoCheck: true,
        OffOnCheck: true,
        adNameCheck: true,
        adIdCheck: true,
        adTypeCheck: true,
        totalImpressionsCheck: true,
        totalReachCheck: true,
        clickCheck: true,
        viewCheck: true,
        leadCheck: true,
        totalAmountCheck: true,
        dailyBudgetCheck: true,
        overallBudgetCheck: true,
        startDateCheck: true,
        endDateCheck: true,
        lastSignificantCheck: true,
    })
    /*---------------Archieved Ad function--------------*/
    const [Archive, setArchive] = useState(false)
    const toggleArchived = (id) => {
        setArchive(!Archive)
    }
    const [adTypeTooltip, setadTypeTooltip] = useState(null);
    const handlePopoverOpen = (event, setAnchorEl) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = (setAnchorEl) => {
        setAnchorEl(null);
    };
    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }
    const tabledata = [
        {
            id: '1',
        },
        {
            id: '2',
        },
        {
            id: '3',
        },
        {
            id: '4',
        },
        {
            id: '5',
        }
    ]

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
            omit: !checkList.serialnoCheck,
            center: true,
            sortable: true
        },
        {
            name: 'Receiver Name-',
            sortable: true,
            omit: !checkList.OffOnCheck,
            minWidth: "150px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setadTypeTooltip)} onMouseLeave={() => handlePopoverClose(setadTypeTooltip)} className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '13px', marginLeft: '6px', fontWeight: 500 }}>Esther Howard</h5>
                </div>
            )
        },
        {
            name: 'Bank Name -',
            sortable: true,
            omit: !checkList.totalImpressionsCheck,
            minWidth: "150px",
            center: true,
            cell: row => (
                <div className="fontForSubTablheading d-flex align-items-center" >
                    <h5  style={{ fontSize: '13px', fontWeight: 500  }}>Standard Chartered</h5>
                </div>
            )

        },
        {
            name: 'Acc. Number -',
            sortable: true,
            omit: !checkList.totalReachCheck,
            minWidth: "200px",
            center: true,
            cell: row => (
                <div className="d-flex align-items-center underLineForLink fontForSubTablheading" >
                    <h5 className='' style={{ fontSize: '13px',  fontWeight: 500  }}>1000 0000 1234 5678</h5>
                </div>
            )
        },
        {
            name: 'Request Date -',
            sortable: true,
            omit: !checkList.adIdCheck,
            minWidth: "150px",
            cell: row => (
                <div className="d-flex align-items-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '13px', marginLeft: '15px', fontWeight: 500  }}>25 Mar 2022</h5>
                </div>
            )
        },
        {
            name: 'Requested Amount -',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "200px",
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '13px', marginLeft: '35px', fontWeight: 500  }}>£17,500</h5>
                </div>
            )
        },
        {
            name: 'Request Status',
            sortable: true,
            omit: !checkList.OffOnCheck,
            minWidth: "150px",
            cell: row => (
                <div  className="d-flex align-items-center" >
                    <div className='d-flex align-items-center fontForSubchildCardheading'>
                        <h5 className='mt-2' style={{background: '#F95C5C26',padding: '9px',borderRadius: '17px',color: '#F95C5C',marginLeft:'21px'}}>Rejected</h5>
                    </div>
                </div>
            )
        },
    ]
    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }
    const CustomPagination = () => {
        const count = Number(Math.ceil(totalItems / rowsPerPage))
        return (
                <div className='d-flex justify-content-center mb-2'>
                    <Stack>
                        <Pagination count={10} className='PaginationCount'>
                        </Pagination>
                    </Stack>
                </div>
        )
    }
    useEffect(() => {
    }, [render])
    return (
        <div>
            <Card className="mt-4">
                <CardBody className='py-0'>
                    <div className='mb-2'>
                    </div>
                    <Row>
                        <Col>
                            <DataTable
                                noHeader
                                responsive
                                // selectableRowSelected={selectedRows}
                                selectableRows
                                paginationServer
                                selectableRowsHighlight
                                highlightOnHover
                                pointerOnHover
                                onSelectedRowsChange={handleChange}
                                sortIcon={<ChevronDown />}
                                paginationComponent={CustomPagination}
                                columns={columns}
                                data={tabledata}
                                customStyles={styleTable}
                                progressPending={loading}
                            />
                        </Col>
                    </Row>
                    <CustomPagination />
                </CardBody>
            </Card>
        </div>
    )
}

export default ViewListStatement