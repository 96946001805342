import React, { useState } from 'react'
import { ChevronDown, ChevronUp, Image, Search } from 'react-feather'
import { Button, CardText, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap'
import LocationIcon from '../../../biz-components/Img/BizTrack1.svg'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import StickerSection from './StickerSection'



const CreatepostFooter = (props) => {

    const [Hashtag, setHastag] = useState(false)
    const [Smiley, setSmiley] = useState(false)
    const [Location, setLocation] = useState(false)
    const [Tag, setTag] = useState(false)
    const [Schedule, setSchedule] = useState(false)
    const [Comments, setComments] = useState(false)
    const theme = useTheme();
    const [personName, setPersonName] = useState([]);
    const [open, setOpen] = useState(false);
    const [listItems, setListItems] = useState([]);

    const handleAddClick = () => {
        setListItems([...listItems, 'New Item']);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const ITEM_HEIGHT = 38;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const getStyles = (name, personName, theme) => {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const names = [
        {
            name: 'Oliver Hansen',
            category: 'SocioMate'
        },
        {
            name: 'Oliver Hansen',
            category: 'SocioMate'
        },
        {
            name: 'Oliver Hansen',
            category: 'SocioMate'
        }
    ];

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const toggleHastag = () => {
        setHastag(!Hashtag)
        setSmiley(false)
        setLocation(false)
        setTag(false)
        setSchedule(false)
    }
    const toggleSmily = () => {
        setSmiley(!Smiley)
        setHastag(false)
        setLocation(false)
        setTag(false)
        setSchedule(false)
    }
    const toggleLocation = () => {
        setLocation(!Location)
        setHastag(false)
        setSmiley(false)
        setTag(false)
        setSchedule(false)
    }
    const toggleTag = () => {
        setTag(!Tag)
        setHastag(false)
        setSmiley(false)
        setLocation(false)
        setSchedule(false)
    }
    const toggleSchedule = () => {
        setSchedule(!Schedule)
        setTag(false)
        setHastag(false)
        setSmiley(false)
        setLocation(false)
    }
    const toggleComments = () => {
        setComments(!Comments)
        setSchedule(false)
        setTag(false)
        setHastag(false)
        setSmiley(false)
        setLocation(false)
    }

    const HashtagComp = () => {
        return <div className='my-3'>
            <CardText tag='h4'>Trending # tags</CardText>
            <InputGroup className='my-2'>
                <InputGroupText className='bg-white'>
                    <Search />
                </InputGroupText>
                <Input type='search' placeholder="Search #tag..." />
            </InputGroup>
            <ul className='m-2'>
                <li className='my-3'>
                    <div>
                        <h4>#NewDay</h4>
                        <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                </li>
                <li className='my-3'>
                    <div>
                        <h4>#NewMe</h4>
                        <h5 className='text-muted'>used in 254 post</h5>
                    </div>
                </li>
            </ul>
        </div>
    }

    const LocationComp = () => {
        return <div className='my-3'>
            <CardText tag='h4'>Location</CardText>
            <InputGroup className='mt-2'>
                <Input type='search' Placeholder='Search location...' />
                <Button className='bg-white'>
                    <img src={LocationIcon} alt='LocationIcon' />
                </Button>
            </InputGroup>
        </div>
    }

    const TagComp = () => {
        return <div className='my-3'>

            <div>
                <FormControl sx={{ m: 1, width: '100%' }}>
                    <InputLabel sx={{ pr: 1 }} id="demo-multiple-chip-label"><Search />Search</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Search  " />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, borderColor: 'primary' }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <CardText className='m-2' tag='h4'>Suggestion</CardText>
                        {names.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, personName, theme)}
                            >
                                {/* <h2>{name}</h2> */}
                                <div className='d-flex my-2 align-items-center' >
                                    <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                                    <div className='ml-1'>
                                        <h4>{name.name}</h4>
                                        <h5 className='text-muted'>{name.category}</h5>
                                    </div>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    }

    const SceduleComp = () => {
        return <div className='border bg-light p-2 my-2'>
            <Row>
                <Col>
                    <div onClick={handleClick} className='d-flex align-items-center justify-content-between'>
                        <h4>Schedule your post </h4>
                        <div className='d-flex'>
                            <b className='mr-2'>Add</b>
                            {open ? <ChevronUp color='#808080' size={20} /> : <ChevronDown color='#808080' size={20} />}
                        </div>
                    </div>
                </Col>
            </Row>
            {
                open ?
                    <Row>
                        <Col className='my-2'>
                            <Input
                                type="datetime-local"
                                color="primary"
                            />
                            <div>
                                <ul>
                                    {listItems.map((item, index) => {
                                        if (index < 5)
                                            return (
                                                <li key={index}> <Input
                                                    type="datetime-local"
                                                    className='my-2'
                                                /></li>
                                            )
                                    })}
                                </ul>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='ml-4 my-2 d-flex align-item-center'>
                                        <Input
                                            type="checkbox"
                                        />
                                        <p>Post Now</p>
                                    </div>
                                    <b className='text-primary' onClick={handleAddClick}>+ Add more</b>
                                </div>

                            </div>
                        </Col>
                    </Row> : null
            }
        </div>

    }

    const CommentComp = () => {
        return <div>
            <div className='my-2 p-2 d-flex align-items-center justify-content-between '>
                <h5>Allow Comment</h5>
                <div className='d-flex align-items-center'>
                    <label>Off</label>
                    <label className="switch mx-2">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                    <label>On</label>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            <div className="create-btn-cust-option">
                <h4>Add to your post</h4>
                <ul className="create-btn-list tooltip-sec">
                    <li className={props.FirsticonS} >
                        <label className='labelForMedia' for="inputTag">
                            <Image onClick={props.mediaaction} strokeWidth={2} color={props.mediafill === 'true' ? '#81C14B' : '#A6A6A6'} />
                            {
                                props.mediafill === 'true' ?
                                    <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name={props.name} onChange={props.mediapreview} type="file" hidden />
                                    :
                                    (null)
                            }
                            {/* <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name={props.name} onChange={props.mediapreview} type="file" hidden /> */}
                        </label>
                        <div className="tooltip-cls">
                            <span>Media</span>
                        </div>
                    </li>
                    <li id="hashtag-btn" className={props.Firsticon} >
                        <svg onClick={toggleHastag} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="16" height="16" viewBox="0 0 16 16" fill={Hashtag == true ? '#81C14B' : '#999999'} ><path d="M15.8754 8.77081C15.6635 8.1214 14.956 7.77619 14.3032 7.9881L12.7514 8.50421L11.7158 5.42804L13.2675 4.91193C13.9204 4.70001 14.2622 3.99591 14.0537 3.34308C13.8417 2.69367 13.1342 2.34845 12.4814 2.56036L10.9296 3.07648L10.393 1.47003C10.1811 0.820618 9.47358 0.475403 8.82075 0.687317C8.16792 0.899231 7.82612 1.60333 8.03461 2.25617L8.57124 3.86261L5.37885 4.92902L4.84223 3.32257C4.63032 2.67316 3.9228 2.32794 3.26997 2.53986C2.61714 2.75177 2.27534 3.45587 2.48383 4.1087L3.02046 5.71515L1.47212 6.22785C0.819284 6.43976 0.477487 7.14386 0.685983 7.79669C0.856881 8.2923 1.33881 8.61701 1.83442 8.63751C2.06684 8.67169 2.24458 8.58283 3.80659 8.06329L4.84223 11.1395L3.29047 11.6556C2.64106 11.8675 2.29585 12.575 2.50434 13.2244C2.67524 13.72 3.15717 14.0447 3.65278 14.0652C3.8852 14.0994 4.06294 14.0106 5.62495 13.491L6.16157 15.0975C6.36323 15.6751 7.00581 16.0887 7.73383 15.8802C8.38667 15.6683 8.72846 14.9642 8.51997 14.3113L7.98335 12.7049L11.1826 11.6351L11.7192 13.2415C11.9208 13.8192 12.5634 14.2327 13.2915 14.0242C13.9443 13.8123 14.2861 13.1082 14.0776 12.4554L13.541 10.8489L15.0927 10.3328C15.7421 10.1277 16.0873 9.42023 15.8754 8.77081ZM7.19038 10.3841L6.15473 7.30109L9.35053 6.23126L10.3862 9.31427L7.19038 10.3841Z" /></svg>
                        <div className="tooltip-cls">
                            <span>Hashtag</span>
                        </div>
                    </li>
                    <li id="smeily-btn" className={props.Secondicon} >
                        <img onClick={toggleSmily} alt='smiley' src="/assets/images/smile.png" className="addpost-option-btn" width="20" height="20" viewBox="0 0 16 16" fill={Smiley == true ? '#81C14B' : '#999999'} />
                        <div className="tooltip-cls">
                            <span>Feeling & Activity</span>
                        </div>
                    </li>
                    <li id="checkin-btn" className={props.Thirdicon} >
                        <svg onClick={toggleLocation} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="13" height="21" viewBox="0 0 13 21" fill={Location == true ? '#81C14B' : '#999999'}><path d="M6.5 0C2.90643 0 0 3.28666 0 7.35036C0 11.7291 4.10429 17.7669 5.79429 20.0665C6.16571 20.5705 6.84357 20.5705 7.215 20.0665C8.89571 17.7669 13 11.7291 13 7.35036C13 3.28666 10.0936 0 6.5 0ZM6.5 9.97548C5.88432 9.97548 5.29385 9.69891 4.8585 9.2066C4.42315 8.71429 4.17857 8.04658 4.17857 7.35036C4.17857 6.65413 4.42315 5.98642 4.8585 5.49411C5.29385 5.0018 5.88432 4.72523 6.5 4.72523C7.11568 4.72523 7.70615 5.0018 8.1415 5.49411C8.57685 5.98642 8.82143 6.65413 8.82143 7.35036C8.82143 8.04658 8.57685 8.71429 8.1415 9.2066C7.70615 9.69891 7.11568 9.97548 6.5 9.97548Z" /></svg>
                        <div className="tooltip-cls">
                            <span>Check In</span>
                        </div>
                    </li>
                    <li id="friends-btn" className={props.Fourthicon} >
                        <svg onClick={toggleTag} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="25" height="21" viewBox="0 0 25 21" fill={Tag == true ? '#81C14B' : '#999999'}><path d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z" /></svg>
                        <div className="tooltip-cls">
                            <span>People Tag</span>
                        </div>
                    </li>
                    <li id="schedule-btn" className={props.Fifthicon} >
                        <svg onClick={toggleSchedule} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" fill={Schedule == true ? '#81C14B' : '#999999'}><path d="M20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442Z" /><path d="M12 8V12L15 15M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#A6A6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 8V12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" /></svg>
                        <div className="tooltip-cls">
                            <span>Schedule Your Post</span>
                        </div>
                    </li>
                    <li id="comment-btn" className={props.Sixthicon} >
                        <svg onClick={toggleComments} xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" fill={Comments == true ? '#81C14B' : '#999999'}><path d="M12 20.9999C16.97 20.9999 21 16.9702 21 12C21 7.02974 16.97 3 12 3C7.03 3 3 7.02974 3 12C3 13.7707 3.512 15.4222 4.395 16.815L3 20.9999L7.745 19.9323C9.07479 20.6408 10.5286 21.0056 12 20.9999Z" /><line x1="7" y1="9" x2="17" y2="9" stroke="white" strokeLinecap="round" /><line x1="7" y1="12" x2="17" y2="12" stroke="white" strokeLinecap="round" /><line x1="7" y1="15" x2="17" y2="15" stroke="white" strokeLinecap="round" /></svg>
                        <div className="tooltip-cls">
                            <span>Allow Comments</span>
                        </div>
                    </li>
                </ul>
            </div>
            {
                Hashtag ? <HashtagComp /> : Smiley ? <StickerSection /> : Location ? <LocationComp /> : Tag ? <TagComp /> : Schedule ? <SceduleComp /> : Comments ? <CommentComp /> : null
            }

            <div className='py-2'>
                <Button className='w-100 btn btn-post'>Post</Button>
            </div>
        </div>
    )
}

export default CreatepostFooter