
import React, { useEffect } from 'react'
import { useState } from 'react';
import swal from 'sweetalert';
import { Fragment } from 'react';
import { AlertCircle, Bookmark,Edit2,BellOff,Bell, Globe, Link2,Trash2, Lock, Search, User, MoreVertical, Share2, XCircle,MoreHorizontal } from 'react-feather';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Slider from 'react-slick';
import { Button, Card, Col, CustomInput, Input, Modal, ModalBody, ModalHeader, Row, } from 'reactstrap';
import Pin1 from '../../profile-components/Img/pin1.png'
import { AlertTriangle,Sliders } from 'react-feather';
import pin from '../Img/pin.png'
import Profile from "../Img/Profile.png"
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Pagination, Stack,PaginationItem } from "@mui/material";
import { loadAllGroupById, getInvitedPeopleToModalAction, postInvitationToOwnedGroupAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import "./allGroup.css"
import { sweetAlertConfirmation } from './SweetAlert';
import { SweetErrorAlert } from './SweetErrorAlert';
import PinIcon from '../../../NewComponents/IMG/PinIcon.svg'
import Pin from "../Img/pin.png"
import axios from 'axios';
import { faL } from '@fortawesome/free-solid-svg-icons';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
  } from "reactstrap";

const OwnedGrpSection = (props) => {
    const dispatch = useDispatch()
    const navigate=useNavigate();
    const userId=props?.userId;
    const type=props?.type;
    // const  getGroupMainPageOwnGroup = props?.data;
    // const { getGroupReportedOption } = useSelector((state) => { return state.getAllReportOptionFromGroupData });
    // const { postGroupReport } = useSelector((state) => { return state.postReportOptionGroupData });
    const { getInvitedPeopleToModalRequest } = useSelector((state) => { return state.getInvitedPeopleToModalData });
    let user = props?.user;
    // const { postInvitationToOwnGroup } = useSelector((state) => { return state.postInvitationToOwnGroupData });


    let [copyLink, setCopyLink] = useState('')
    const [inviteOpen, setInviteOpen] = useState(false)

    const [radioValue, setRadioValue] = useState('')
    const [fetchGroupId, setFetchGroupId] = useState('')
    const [reportOpen, setReportOpen] = useState(false)
    const [remark, setRemark] = useState('')
    const [friendsId, setFriendsId] = useState([])
    const [grpId, setGrpId] = useState('')
    const [deleteOpen,setDeleteOpen]=useState(false);
    const [deleteId,setDeleteId]=useState('');
    const [error,setError]=useState('');
    const [followerData,setFollowerData]=useState([]);
    const [followingData,setFollowingData]=useState([]);
    const [success,setSuccess]=useState(false);
    const [pageSize,setPageSize]=useState(12);
    const [pageIndex,setPageIndex]=useState(0);
    const [searchKey,setSearchKey]=useState("");
    const [onSuccess,setOnSuccess]=useState(false)
    const [leaveId,setLeaveId]=useState('');
    const [leaveOpen,setLeaveOpen]=useState(false)
    const [shareOpen, setShareOpen] = useState(false);
    const [modalOffNotification, setModalOffNotification] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [searchkey1,setSearchKey1]=useState('');
    const [count,setCount]=useState(0)

    useEffect(() => {
        
        dispatch(loadAllGroupById(userId,pageIndex,pageSize))
        setOnSuccess(false);
        
    }, [pageIndex,pageSize,onSuccess])

    
  const  getGroupMainPageOwnGroup  = useSelector((state) => { return state.getAllGroupMainPageData });
  const { loading: loadingGroup, error: errorPinnedPage, result: resultData } = getGroupMainPageOwnGroup;

    const toggleInvite = (id) => {
        dispatch(getInvitedPeopleToModalAction(id))
        setGrpId(id)
        setInviteOpen((Preview) => !Preview)
    }
      


    const postCopyLink = async (id) => {
        copyLink = `${process.env.REACT_APP_IPURL}/Group/${id}`
        setCopyLink(copyLink)
        navigator.clipboard.writeText(copyLink)
        sweetAlertConfirmation('Link Copied')
    };

    const radioHandler = (event) => {
        setRadioValue(event)
    }; 

    const toggleLeaveOpen=()=>{
        setLeaveOpen(false)
        setLeaveId('');
    }
    
const pageCount = Math.ceil((props?.count)/12);
const isPrevDisabled = pageIndex <= 0;
const isNextDisabled = pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === pageIndex) return;

    setPageIndex(value - 1);
  };

    
    const BasicsPagination = () => {
        return (
          <>
            <Stack>
              <Pagination
                   count={pageCount}
                   page={pageIndex + 1}
                   onChange={handlePageChange}
                   className="PaginationCounts"
                   renderItem={(item) => (
                     <PaginationItem
                       {...item}
                       disabled={
                         (item.type === 'previous' && isPrevDisabled) ||
                         (item.type === 'next' && isNextDisabled)
                       }
                     />
                   )}
              ></Pagination>
            </Stack>
          </>
        );
      };

    const toggleDeleteOpen=()=>{
        setDeleteOpen(false)
        setDeleteId('');
    }

    const inviteFriends = (e) => {
        let updatedList = [...friendsId]
        if (e.target.checked) {
            updatedList = [...friendsId, e.target.value]
        } else {
            updatedList.splice(friendsId.indexOf(e.target.value), 1)
        }
        setFriendsId(updatedList)
    };

    //======================= Num_Formatter_Function =================//
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    // const deleteGroup=(e,id)=>{
    //     e.stopPropagation();
    //     e.preventDefault();
    //    setDeleteOpen(true);
    //    setDeleteId(id);
    //  }

     const [filteredPeople, setFilteredPeople] = useState([]);

        useEffect(() => {
            if (getInvitedPeopleToModalRequest) {
            // Filter people based on searchKey
            const filtered = getInvitedPeopleToModalRequest.filter(
                data =>
                data.fullName.toLowerCase().includes(searchKey.toLowerCase()) ||
                data.userName.toLowerCase().includes(searchKey.toLowerCase())
            );
            setFilteredPeople(filtered);
            }
        }, [searchKey, getInvitedPeopleToModalRequest]);

        const deleteAllPost = async (e) => {
            e.preventDefault();
            e.stopPropagation();
            try {
                const fetchData = async () => {
                    try {
                        let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                            const response = await axios.post(
                                `${process.env.REACT_APP_IPURL}/group/delete`,
                                {groupId:deleteId},
                                { headers: { Authorization: `Bearer ${user.token}` } }
                            );
                            sweetAlertConfirmation('Successfully deleted');
              setDeleteOpen(false);
              setSuccess(true)
              props?.setUpdateCount(true)
                    } catch (error) {
                      console.log("error=",error)
                    }
                };
              
              fetchData();
              
            } catch (error) {
             setError(error);
            }
          };

    const pinUnpin=(e,grpId)=>{
        // e.stopPropagation();
        // e.preventDefault();
      
            const fetchData = async () => {
                try {
                    
                    if (user) {
                        const response = await axios.post(
                            `${process.env.REACT_APP_IPURL}/group/add/pinned/group`,
                            { grpAdminId: userId, isPinned:0,groupId:grpId},
                            { headers: { Authorization: `Bearer ${user.token}` } }
                        );
                        setOnSuccess(true);
                        sweetAlertConfirmation("Successfully Unpinned");
                    } else {
                        setError('no user found');
                    }
                } catch (error) {
                    setError(error);
                }
            };
        
        fetchData();
        
        
    }

    const unpin=(e,grpId)=>{
        // e.stopPropagation();
        // e.preventDefault();

        const fetchData = async () => {
            try {
                
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/group/add/pinned/group`,
                        { grpAdminId: userId, isPinned:1,groupId:grpId},
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setOnSuccess(true);
                    sweetAlertConfirmation("Successfully Pinned");
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error);
            }
        };
    
    fetchData();
    

    }

    const handleCheckboxChange = (id) => {
        setCheckedItems(prevCheckedItems => {
          if (prevCheckedItems.includes(id)) {
            // Uncheck item
            return prevCheckedItems.filter(itemId => itemId !== id);
          } else {
            // Check item
            return [...prevCheckedItems, id];
          }
        });
      };

    const navigateTo=(e,id,name,totalMembers,index)=>{
        e.stopPropagation();
        e.preventDefault();
        // navigate(``)
        navigate(`/GroupDetail/${id}/${name}/${totalMembers}/${index}`)
    }

    const leaving =(e,id)=> {
        setLeaveId(id);
        e.stopPropagation();
        setLeaveOpen(true);
      }

      const leaveGroup = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const fetchData = async () => {
                try {
                    
                        const response = await axios.post(
                            `${process.env.REACT_APP_IPURL}/group/leave`,
                            {groupId:leaveId,isLeave:true,memberId:user.id},
                            { headers: { Authorization: `Bearer ${user.token}` } }
                        );
                        sweetAlertConfirmation('Successfully Left Group');
                        setLeaveOpen(false);
                        setOnSuccess(true)
                        props?.setUpdateCount(true)
                } catch (error) {
                  setError(error)
                }
            };
          
          fetchData();
         
        } catch (error) {
         setError(error);
        }
      };

      const joinGroupRequest=(e,id,privacy)=>{
        e.stopPropagation();
        const status=privacy=="private"?"pending":"accepted";

            const fetchData = async () => {
                try {
                    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                        const response = await axios.post(
                            `${process.env.REACT_APP_IPURL}/group/member/create`,
                            {groupId:id,staus:status,memberId:user.id},
                            { headers: { Authorization: `Bearer ${user.token}` } }
                        );
                        if(status=="pending"){
                            sweetAlertConfirmation('Successfully Requested to Join Group');
                        }else{
                            sweetAlertConfirmation('Successfully Joined');
                        }
                        
                        // setLeaveOpen(false);
                        setOnSuccess(true)
                        props?.setUpdateCount(true)
                } catch (error) {
                  setError(error)
                }
            };
          
          fetchData();

      }

      const withdrawRequest=(e,groupId)=>{
        e.stopPropagation();
        const fetchData = async () => {
            try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/group/member/withdraw`,
                        {groupId:groupId,memberId:user.id},
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    
                    sweetAlertConfirmation('Successfully Withdrawn');
                    setOnSuccess(true)
                    props?.setUpdateCount(true)
            } catch (error) {
              setError(error)
            }
        };
       fetchData();
      }

      const handleChange=(e)=>{
        setSearchKey1(e.target.value);
      }

      const shareAll = () => {
        sweetAlertConfirmation(`Your post shared to everyone`);
        setShareOpen((prevState) => !prevState);
        };

      const isShareAllDisabled = checkedItems.length === 0;

      const toggleShareOpen = (e) => {
        e.stopPropagation();
        setShareOpen((prevState) => !prevState);
       };

       const toggle = () => setModalOffNotification(!modalOffNotification);

       
    const toggleReport = (id) => {
        setReportOpen((preview) => !preview)
        setFetchGroupId(id)
    }


    const filterData = () => {
        if (!props?.searchKey) {
          return resultData;
        }
    
        return resultData?.filter((data) => {
          return Object.values(data).some((value) => 
            value.toString().toLowerCase().includes(props?.searchKey.toLowerCase())
          );
        });
      };
    
      const filteredRows = filterData();

    //   console.log("resultData==================================",resultData)

    return (

        <Fragment>
            {!loadingGroup? (
                    <>
                                    
                    { resultData?.length > 0 ? (
                        <div className="group-custom-block">
                            <Row className="mt-3">
                            {filteredRows?.map((data, index) => (
                                <Col md="3" className="mb-5" key={index}>
                                    <div className="BizPage_Card_section">
                                    <div 
                                        className="BizPage_option_menu" 
                                        style={{ 
                                            height: '200px', // Adjust the height as needed
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            justifyContent: 'space-between', 
                                            paddingRight: "15px"
                                        }}
                                    >
                                        {props?.type === "creator" && data.isMember === "accepted" ? (
                                            <>
                                                <MoreHorizontal 
                                                    size={20} 
                                                    className="dropbtn-gal" 
                                                    data-bs-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false" 
                                                />
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal" style={{width:"190px"}}>
                                                    <ul>
                                                        {data.isPinned ? (
                                                            <li onClick={(e) => pinUnpin(e, data.id)}>
                                                                <a>
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img src={Pin1} alt="Pin Icon" style={{ marginRight: '8px' }} />
                                                                        UnPin Group
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ) : (
                                                            <li onClick={(e) => unpin(e, data.id)}>
                                                                <a>
                                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <img src={Pin1} alt="Pin Icon" style={{ marginRight: '8px' }} />
                                                                        Pin Group
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )}


                                                        <li onClick={() => postCopyLink(data?.id)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Link2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Copy Link
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li onClick={() => toggle(data?.id)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                  <Bell size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Turn on Notification
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li onClick={(e) => navigateTo(e, data.id, data.name, data.totalMembers, index)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Edit2 size={15} color="#979797" style={{ marginRight: '8px' }} />
                                                                    Edit Group
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li onClick={() => toggleInvite(data.id)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <AlertCircle size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Invite
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li onClick={(e) => toggleShareOpen(e)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                   <Share2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Share Group
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        ) : props?.type === "viewer" && data.isMember === "accepted" ? (
                                            <>
                                                <MoreHorizontal 
                                                    size={20} 
                                                    className="dropbtn-gal" 
                                                    data-bs-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false"

                                                />
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                    <ul>
                                                        <li>
                                                            <a onClick={(e) => toggleShareOpen(e)}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Share2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Share Group
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li onClick={toggle}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <BellOff size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Turn off Notification
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li onClick={() => postCopyLink(data?.id, data.groupName, data.totalMembers, index)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Link2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Copy Link
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li onClick={(e) => leaving(e, data.id)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Bookmark size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Leave Group
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li onClick={() => toggleReport(data?.id)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <AlertTriangle size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Report Group
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <MoreHorizontal 
                                                    size={20} 
                                                    className="dropbtn-gal" 
                                                    data-bs-toggle="dropdown" 
                                                    aria-haspopup="true" 
                                                    aria-expanded="false" 
                                                />
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                    <ul>
                                                        <li>
                                                            <a onClick={(e) => toggleShareOpen(e)}>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Share2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Share Group
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li onClick={() => postCopyLink(data?.id, data.groupName, data.totalMembers, index)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Link2 size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Copy Link
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li onClick={() => toggleReport(data?.id)}>
                                                            <a>
                                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <AlertTriangle size={15} color="#4D4D4D" style={{ marginRight: '8px' }} />
                                                                    Report Group
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                        <img onClick={(e)=>navigateTo(e,data.id,data.name,data.totalMembers,index)}
                                            alt='' 
                                            src={data.coverPic} 
                                            className="img-fluid group-img" 
                                            style={{ 
                                                width: '100%', 
                                                height: '200px', // Fixed height
                                                objectFit: 'cover' // Maintain aspect ratio
                                            }}             
                                        />
                                    <div 
                                        className="group-content" 
                                
                                    >
                                        <div className='d-flex align-items-center justify-content-center' onClick={(e)=>navigateTo(e,data.id,data.name,data.totalMembers,index)}>
                                            <span className='text-capitalize'>{data.name.substring(0, 15)}{data.name.length >= 15 && ' ...'}</span>
                                        </div>
                                        {data.privacy === 'public' ? (
                                                <div className="member-blk" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center'
                                                }}>
                                                    <span className='text-capitalize'>
                                                        <Globe style={{ marginTop: '-3px' }} color='#4D4D4D' size={12} /> {data.privacy && data.privacy}
                                                    </span>  • 
                                                    <span>
                                                    
                                                        {data.totalMembers > 0 ? (
                                                            <>
                                                                {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members'}
                                                            </>
                                                        ) : (
                                                            <p>Be the first</p>
                                                        )}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className="Suggestmember-blk" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textAlign: 'center'
                                                }}>
                                                    <span className='text-capitalize'>
                                                        <Lock style={{ marginTop: '-3px' }} color='#4D4D4D' size={12} /> {"private" && "private"}
                                                    </span>&nbsp; • &nbsp;
                                                    <span>
                                                        {data.totalMembers > 0 ? (
                                                            <>
                                                                {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members'}
                                                            </>
                                                        ) : (
                                                            <p>Be the first</p>
                                                        )}
                                                    </span>
                                                </div>
                                            )}

                                        {props?.type === "creator" ? (
                                            <div className="group-buttons-blk mt-3" onClick={(e)=>navigateTo(e,data.id,data.name,data.totalMembers,index)}>
                                                <Button className="Invite_Join_Button" color='primary'>View Activity</Button>
                                                <Button 
                                                    onClick={() => { toggleInvite(data.id) }} 
                                                    className="Invite_Decline_Button ml-2" 
                                                    outline
                                                >
                                                    Invite
                                                </Button>
                                            </div>
                                        ) : (
                                            data.isMember === "accepted" ? (
                                                <div className="group-buttons-blk mt-3" onClick={(e) => leaving(e, data.id)}>
                                                    <Button className="Invite_Decline_Button ml-2" color='primary'>Leave</Button>
                                                </div>
                                            ) : data.isMember === "notMember" ? (
                                                <div className="group-buttons-blk mt-3" onClick={(e) => joinGroupRequest(e, data.id, data.privacy)}>
                                                    <Button className="Invite_Join_Button" color='primary'>Join Group</Button>
                                                </div>
                                            ) : (
                                                <div className="group-buttons-blk mt-3" onClick={(e) => withdrawRequest(e, data.id)}>
                                                    <Button className="Invite_Decline_Button ml-2" color='primary'>Withdraw</Button>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    </div>
                                </Col>
                            ))}
                            </Row>

                        </div> 
                    ):(
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '15vh',
                            width: '100%'
                            }}>
                            <h2 style={{ textAlign: 'center' }}>No Groups</h2>
                        </div>
                    )}

                    <div className="vertically-centered-modal">
                            {deleteOpen && (
                            
                                <Modal
                                isOpen={deleteOpen}
                                toggle={toggleDeleteOpen}
                                className="modal-dialog-centered"
                                >
                                <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                                    <h4>Delete Group</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="d-flex justify-content-center modalHeading_Section">
                                    <p>Do you really want to delete the selected Group ?</p>
                                    </div>
                                    <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                                    <small>If</small> YES! <small>please click on ok</small>
                                    </h1>
                                    <div className="d-flex justify-content-center  mt-4">
                                    <Button onClick={toggleDeleteOpen} outline style={{ width: '100px' }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={(e)=>deleteAllPost(e)}
                                        color="primary"
                                        className="ml-3 "
                                        style={{ width: '100px' }}
                                    >
                                        OK
                                    </Button>
                                    </div>
                                </ModalBody>
                                </Modal>
                            )}

                        {leaveOpen && (
                            
                            <Modal
                            isOpen={leaveOpen}
                            toggle={toggleLeaveOpen}
                            className="modal-dialog-centered"
                            >
                            <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                                <h4>Leave Group</h4>
                            </ModalHeader>
                            <ModalBody>
                                <div className="d-flex justify-content-center modalHeading_Section">
                                <p>Do you really want to leave the Group ?</p>
                                </div>
                                <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                                <small>If</small> YES! <small>please click on ok</small>
                                </h1>
                                <div className="d-flex justify-content-center  mt-4">
                                <Button onClick={toggleLeaveOpen} outline style={{ width: '100px' }}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e)=>leaveGroup(e)}
                                    color="primary"
                                    className="ml-3 "
                                    style={{ width: '100px' }}
                                >
                                    OK
                                </Button>
                                </div>
                            </ModalBody>
                            </Modal>
                        )}
                                    {shareOpen && (
                    <Modal
                    isOpen={shareOpen}
                    toggle={toggleShareOpen}
                    className="modal-dialog-centered"
                    >
                    <div className="w-100 border-bottom">
                    <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
                    <h4>Share People</h4>
                    <span style={{ cursor: isShareAllDisabled ? "not-allowed" : "pointer",alignItems:'center' }}
                        onClick={!isShareAllDisabled ? shareAll : undefined}
                        className={isShareAllDisabled ? 'disabled' : ''} 
                    >
                        Share
                    </span>
                    </div>
                    </div>
                    <ModalBody>
                    <Row className="mt-2">
                    <Col md="8" className="Like_search">
                        <div className="profile-search-blk w-100">
                        <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="search-svg"
                        >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                        <input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Search..."
                            value={searchKey}
                            autoFocus
                            onChange={handleChange}
                        />
                        </div>
                    </Col>
                    <Col md="4" className="d-flex justify-content-end">
                        <UncontrolledButtonDropdown color="secondary" outline>
                        <DropdownToggle
                            outline
                            className="border border-muted ml-3"
                        >
                            <Sliders className="Slider_Filter" size={12} />
                            &nbsp;&nbsp;Filter
                        </DropdownToggle>
                        <DropdownMenu className="DropDown_Group_item">
                            <DropdownItem onClick={() => setCount(0)}>
                            Followers
                            </DropdownItem>
                            <DropdownItem onClick={() => setCount(2)}>
                            Followings
                            </DropdownItem>
                        </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </Col>
                    <Row className="mt-3">
                        {count === 0 && followerData?.filter(data =>
                        data.fullName.includes(searchKey) ||
                        (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                        data.followBackStatus.includes(searchKey)
                        ).map((data) => (
                        <Fragment key={data.id}>
                            <div className="group-detail-page-blk">
                            <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                <img src={data.profileImage} alt="img" className='img-fluid' />
                                <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                    {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                    {data.followBackStatus}
                                    </div>
                                </div>
                                </div>
                                <div className='Block-btns-blk'>
                                <Checkbox
                                    checked={checkedItems.includes(data.id)}
                                    onChange={() => handleCheckboxChange(data.id)}
                                    color="primary"
                                />
                                </div>
                            </div>
                            </div>
                        </Fragment>
                        ))}
                        {count === 2 && followingData?.filter(data =>
                        data.fullName.includes(searchKey) ||
                        (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                        data.followBackStatus.includes(searchKey)
                        ).map((data) => (
                        <Fragment key={data.id}>
                            <div className="group-detail-page-blk">
                            <div className="Follower-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                <img src={data.profileImage} alt="img" className='img-fluid' />
                                <div className="member-cont-blk">
                                    <h4>{data.fullName}</h4>
                                    <p className='text-muted'>
                                    {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                                    </p>
                                    <div className="Post_Count">
                                    {data.followBackStatus}
                                    </div>
                                </div>
                                </div>
                                <div className='Block-btns-blk'>
                                <Checkbox
                                    checked={checkedItems.includes(data.id)}
                                    onChange={() => handleCheckboxChange(data.id)}
                                    color="primary"
                                />
                                </div>
                            </div>
                            </div>
                        </Fragment>
                        ))}
                    </Row>
                    
                    </Row>
                    </ModalBody>
                    </Modal>
                    )}

                    <div className='InviteModal'>
                            {inviteOpen !== false &&
                                <Modal scrollable isOpen={inviteOpen} 
                                toggle={toggleInvite} 
                                className='modal-dialog-centered'  >
                                    <ModalHeader className='d-flex justify-content-center'>
                                        <div className='Invitation_Heading_Section'>
                                            <h4>Send Invitation</h4>
                                        </div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col md="12">
                                                <div className='Invite_SocioMate_Heading'>
                                                    <h4>Invite to SocioMates</h4>
                                                </div>
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md="12">
                                                <div className="search-block-podcast">
                                                    <div className="input-search-blk">
                                                        <Search className="search-svg" />
                                                        <Input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" value={searchKey} data-bs-target="#peopleSearch" onChange={(e)=>setSearchKey(e.target.value)} />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12">
                                                <div className='Invitaion_List_Heading'>
                                                    <h4>{getInvitedPeopleToModalRequest?.length ? 'Friends (' + getInvitedPeopleToModalRequest?.length + ')' : ''}</h4>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                {
                                                    filteredPeople.map((data) => (
                                                        <div className='d-flex justify-content-between mt-3'>
                                                            <div className='d-flex align-items-center '>
                                                                <img style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} src={data.profileImage} alt="profile" className='img-fluid group-img'
                                                                />
                                                                <div className='Invitaion_User_Profile ml-1'>
                                                                    <h4>{data.fullName}</h4>
                                                                    <p>{data.userName}</p>
                                                                </div>
                                                            </div>
                                                            <div className='Block-btns-blk'>
                                                                <CustomInput
                                                                    id={data.id} style={{ cursor: "pointer" }}
                                                                    value={data.id}
                                                                    defaultChecked={!!friendsId[data?.id]}
                                                                    onChange={(e) => {
                                                                        inviteFriends(e);
                                                                    }}
                                                                    type="checkbox"
                                                                    className='customcheckboxForFilter'

                                                                />
                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="12">
                                                <div className='d-flex justify-content-center'>
                                                    {
                                                        getInvitedPeopleToModalRequest?.length
                                                            ?
                                                            <Button 
                                                            // onClick={sendInvitationData} 
                                                            className="Invitation_Send_btn">
                                                                Send </Button>
                                                            :
                                                            <p className='text-danger'>No contacts exist to show</p>

                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            }
                        </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-center PaginationCounts'>
                    <BasicsPagination/>
                    </div>
                    </>
            ):(
                <>
                <br/><br/>
                <Spinner
                    animation='border'
                    role='status'
                    style={{
                        width: '100px',
                        height: '100px',
                        margin: 'auto',
                        display: 'block',
                        color: '#81C14B'
                    }}
                >
                    <span className='sr-only'>Loading...</span>
                </Spinner>
                <br/>
                </> 
            )}
        </Fragment>
    )
}

export default OwnedGrpSection