import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Card, CardBody, Col, Row } from 'reactstrap';
// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



const AdOverviewChart = () => {
    const options = {
        scales: {
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: 'Month',
                        label: 'Months', // Add label property
                    },
                },
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: 'Value',
                        label: 'Value', // Add label property
                    },
                },
            ],
        },

        responsive: true,
        hover: {
            mode: 'label'
        },
        tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
            titleFontColor: '#000',
            bodyFontColor: '#000'
        },
        layout: {
            padding: {
                top: -5,
                bottom: -5
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    padding: 10,
                    boxWidth: 10,
                    color: '#4D4D4D',
                    fontFamily: 'Montserrat',
                }
            },
            hover: {
                mode: 'label'
            },
        },
    };
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
        labels: [0, '23/12', '30/12', '23/03', '24/03', '25/03', '5 Day', '6 Day', '7 Day', '8 Day', '9 Day', '10 Day'],
        datasets: [
            {
                data: [10, 110, 210, 210, 160, 155, 155, 200, 255, 205, 265, 250],
                label: 'Total Reach',
                borderColor: '#DCA594',
                lineTension: 0.5,
                pointStyle: 'circle',
                backgroundColor: '#DCA594',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: '#808080',
                pointHoverBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: 'rgba(0, 0, 0, 0.25)'
            },
            {
                data: [20, 120, 220, 220, 170, 160, 160, 202, 265, 210, 270, 255],
                label: 'Total Impression',
                borderColor: '#B28CD0',
                lineTension: 0.5,
                pointStyle: 'circle',
                backgroundColor: '#B28CD0',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: '#808080',
                pointHoverBorderColor: '#fff',
                pointHoverBackgroundColor: '#B28CD0',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: 'rgba(0, 0, 0, 0.25)'
            },
            {
                data: [30, 130, 230, 230, 180, 80, 80, 101, 130, 105, 135, 180],
                label: 'Total Lead',
                borderColor: '#7BD77F',
                lineTension: 0.5,
                pointStyle: 'circle',
                backgroundColor: '#7BD77F',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: '#808080',
                pointHoverBorderColor: '#fff',
                pointHoverBackgroundColor: '#7BD77F',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: 'rgba(0, 0, 0, 0.25)'
            },
            {
                data: [40, 140, 240, 240, 190, 180, 130, 202, 265, 210, 270, 185],
                label: 'Total Click',
                borderColor: '#E90064',
                lineTension: 0.5,
                pointStyle: 'circle',
                backgroundColor: '#E90064',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: '#808080',
                pointHoverBorderColor: '#fff',
                pointHoverBackgroundColor: '#E90064',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: 'rgba(0, 0, 0, 0.25)'
            },
            {
                data: [50, 150, 250, 250, 200, 120, 130, 140, 215, 190, 210, 225],
                label: 'Total View',
                borderColor: '#009FBD',
                lineTension: 0.5,
                pointStyle: 'circle',
                backgroundColor: '#009FBD',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: '#808080',
                pointHoverBorderColor: '#fff',
                pointHoverBackgroundColor: '#009FBD',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: 'rgba(0, 0, 0, 0.25)'
            },
        ],
    };

    return (
        <Card className='shadow'>
            <div className='d-flex justify-content-between p-4'>

                <div className="col-9 fontForSubCardheading">
                    <h3>Ad Overview</h3>
                </div>
                <div className="col-3">
                    <ul className="right-menu d-xl-flex">
                        <li className='mr-1'>
                            <select className="form-control">
                                <option>All</option>
                                <option>Monthly</option>
                                <option>Yearly</option>
                            </select>
                        </li>
                        <li>
                            <select className="form-control">
                                <option>Result</option>
                                <option>Monthly</option>
                                <option>Yearly</option>
                            </select>
                        </li>
                    </ul>
                </div>
            </div>
            <CardBody>
                <Row>
                    <Col xl='1' className='p-0'>
                        <div className='rotate-text' >
                            <p> User Engagement Count</p>
                        </div>
                    </Col>
                    <Col xl='11' className='p-0'>
                    <Line options={options} data={data} />
                    <div className='text-center'>
                        <p>Days</p>
                        </div>
                    </Col>
                </Row>

                
            </CardBody>
        </Card>
    );
}
export default AdOverviewChart
