import React, { useState } from 'react'
import { Pagination, Stack } from '@mui/material'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import LeftquotationIcon from '../../../NewComponents/IMG/comma.png'
import RightquotationIcon from '../../../NewComponents/IMG/comma1.png'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { listThoughtdata } from '../DummyArray'
import { NavLink } from 'react-router-dom'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'


const ListThought = ({ display, space }) => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }


    {/* -----------------------------------------------------------Main return function--------------------------------------------------------------- */ }
    return (
        <div>
            <Row className='mt-2'>
                <Col md='12' className='' >
                    {/* -----------------------------------------------------------List View Section Part 1--------------------------------------------------------------- */}
                    {listThoughtdata.map((data, index) => (

                        <div className='border rounded  on-hover-bg pl-3 p-2' key={index} >
                            <Row className='d-flex align-items-center py-1 '>
                                <Col xl='7' className='d-flex' >
                                    {/* <div className='d-flex ml-2'>
                                        <Badge color={`light-primary ${display}`}>
                                            Trending #{data.trendingNumber}
                                        </Badge>
                                        <Badge color='primary' pill className={space}>
                                            Top #{data.topsNumber}
                                        </Badge>
                                    </div> */}

                                    <div className='d-flex align-items-center ml-2 '>
                                        <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center'>
                                            <p className='PilesThistags text-center'>Top</p>
                                            <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                        </div>

                                        <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                            <p className='PilesThistags text-center'>Trending</p>
                                            <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center' >
                                        {
                                            index === 0 ? <div style={{ marginLeft: '3rem', borderRadius: '10px', background: '#16C31E', color: '#F8DF88', width: "20rem" }} className='p-1'>
                                                <img src={LeftquotationIcon} className='LeftIconforthrought rem-mob-view' alt="icon" />
                                                <div className='mx-1 text-center'>
                                                    <div className='text-center'>
                                                        <p>{data.ThoughtThistext.slice(0, 80) + '...'}</p>
                                                    </div>
                                                </div>
                                                <div className='text-right'>
                                                    <img src={RightquotationIcon} alt="icon" className='RightIconforthrought rem-mob-view' />
                                                </div>
                                            </div> : " "
                                        }
                                        {
                                            index === 1 ? <div style={{ marginLeft: '3rem', borderRadius: '10px', background: '#1F4E69', color: '#F8DF88', width: "20rem" }} className='p-1'>
                                                <img src={LeftquotationIcon} className='LeftIconforthrought rem-mob-view' alt="icon" />
                                                <div className='mx-1 text-center'>
                                                    <div className='text-center'>
                                                        <p>{data.ThoughtThistext.slice(0, 80) + '...'}</p>
                                                    </div>
                                                </div>
                                                <div className='text-right'>
                                                    <img src={RightquotationIcon} alt="icon" className='RightIconforthrought rem-mob-view' />
                                                </div>
                                            </div> : " "
                                        }
                                        {
                                            index === 2 ? <div style={{ marginLeft: '3rem', borderRadius: '10px', background: '#FF8B72', color: '#F8DF88', width: "20rem" }} className='p-1'>
                                                <img src={LeftquotationIcon} className='LeftIconforthrought rem-mob-view' alt="icon" />
                                                <div className='mx-1 text-center'>
                                                    <div className='text-center'>
                                                        <p>{data.ThoughtThistext.slice(0, 80) + '...'}</p>
                                                    </div>
                                                </div>
                                                <div className='text-right'>
                                                    <img src={RightquotationIcon} alt="icon" className='RightIconforthrought rem-mob-view' />
                                                </div>
                                            </div> : " "
                                        }
                                        <div className='d-flex  justify-content-start align-items-center' style={{ marginLeft: "2rem" }}  >
                                            <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-3 rounded-circle ArticleShowdo'  alt=''/>
                                            <div className='ml-4'>
                                                <p className='user-profile'>{data.name}</p>
                                                <div className='d-flex align-items-center'>
                                                    <p className=''>@Aman&nbsp;</p>
                                                    <img src='https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png' alt='India' height={15} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='4'>
                                    <div className='d-flex justify-content-center align-items-center' style={{ marginLeft: "7rem" }}>
                                        <div className='d-flex align-items-center'>
                                            <div onClick={() => handleLike()}>
                                                {liked ?
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                    :
                                                    <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                }
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center px-3' data-bs-toggle="modal" data-bs-target="#imageModel" >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                            </div>
                                            11.2k
                                        </div>

                                    </div>
                                </Col>
                                <Col xl='1' >
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className='p-0 m-0' color='flat'>
                                            <MoreHorizontal size={19} color='#00000040' className='ml-4'/>
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation' style={{left:'28px'}}>
                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share Thought</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem > <NavLink to='/MyProfile' style={{ color: '#212529' }} > <Eye size={13} />&nbsp;&nbsp;View creator's profile </NavLink></DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}

                    {/* <div className='border rounded mt-3'>
                        <Row className='d-flex align-items-center mt-3'>
                            <Col xl='6'>
                                <div className='d-flex ml-2'>
                                    <Badge color={`light-primary ${display}`}>
                                        Trending #1
                                    </Badge>
                                    <Badge color='primary' pill className={space}>
                                        Top #96
                                    </Badge>
                                </div>
                                <div className='d-flex justify-content-start align-items-center p-2'>
                                    <div style={{ marginTop: '', borderRadius: '10px', background: '#60715C', color: '#F8DF88', height: '6rem', width: '14rem' }} className='p-1'>
                                        <img src={LeftquotationIcon} className='LeftIconforthrought rem-mob-view' alt="icon" />
                                        <div className='mx-2 text-center'>
                                            <div className='text-left'>
                                                <p>Happiness depends the upon ourselves.</p>
                                            </div>
                                        </div>
                                        <div className='text-right'>
                                            <img src={RightquotationIcon} alt="icon" className='RightIconforthrought rem-mob-view' />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-start align-items-center pl-4'>
                                        <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' />
                                        <div className='ml-2'>
                                            <p>Floyd Miles</p>
                                            <p className=''>@Aman</p>
                                       
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='6'>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                        </div>
                                        11.2k
                                    </div>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                            <MoreHorizontal color='black' />
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Col>

                        </Row>
                    </div> */}
                    {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}

                    {/* <div className='d-flex justify-content-center p-2'>
                        <Stack spacing={2}>
                            <Pagination
                                count={10}
                                color='primary'
                                size="large" />
                        </Stack>
                    </div> */}

                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    {/* <p>Total Thoughts Count : <span className='text-primary' >{listThoughtdata.length}</span></p> */}
                                    <p>Showing 1 to {listThoughtdata.length} of 200 entries</p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex p-2 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />

            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

        </div >
    )
}

export default ListThought