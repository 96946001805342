import React from 'react'
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown } from 'reactstrap'
import tagPic from '../../NewComponents/IMG/tagPic.png'
import play from '../../NewComponents/IMG/play.png'
import tagpic1 from '../../NewComponents/IMG/tagpic1.png'
import Pollimg from '../../NewComponents/IMG/Pollimg.png'
import { AlertTriangle, Delete, Edit, Eye, Inbox, Link, MoreHorizontal, MoreVertical, Play, Send, Share2, Trash2 } from 'react-feather'
import Frame2 from '../../NewComponents/IMG/Frame2.png'
import Frame3 from '../../NewComponents/IMG/Frame3.png'
import Frame4 from '../../NewComponents/IMG/Frame4.png'
import fullpicTrending from '../../NewComponents/IMG/fullpicTrending.png'
import tagpic2 from '../../NewComponents/IMG/tagpic2.png'

import { useState } from 'react'


const TagTrendingTitle = () => {
  const [active, setActive] = useState('1')

  const toggle = tab => {
    if (active !== tab) {
      setActive(tab)
    }
  }
  const numFormatter = (num) => {
    // eslint-disable-next-line prefer-template
    if (num > 999 && num < 1000000) {
      return `${parseInt(num / 1000)} K`
    } else if (num > 999999) {
      return `${parseInt(num / 1000000)} M`
    } else if (num < 900) {
      return `${num}`
    }
  }
  return (
    <div>
      {/* <Trentabs/> */}
      <Row>
        <Col>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
              <div>
                <img src={tagPic} className='m-3' />
              </div>
              <div className='mt-5'>
                <p className='TagThisTrendingTitle'>#trendingtitle</p>
                <p className='TagThisCountPost'>554 posts</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className='AllTagsThis'>
        <Col md="3" className='p-1'>
          <Card className='h-100'>
            <div className='d-flex justify-content-between align-items-start m-2'>
              <img src={Pollimg} alt='userImg' />
              <div className='ml-2'>
                <p className='TagThisTitle'>Floyd Miles <small className='text-body'> is watching House of Cards with </small> Ryan Reynolds at National Theatre, Prague</p>
                <p className='text-muted'>1 sept at 07:00 pm</p>
              </div>

              <UncontrolledButtonDropdown>
                <DropdownToggle className='p-0 m-0' color='flat' >
                  <MoreHorizontal size={19} color='#00000040' />
                </DropdownToggle>
                <DropdownMenu className='bizpagefordropdownmenu'>
                  <DropdownItem className='dropdownitem' ><Share2 size={13} />&nbsp;Share post</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><AlertTriangle size={13} />&nbsp;Edit</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;Delete</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <p className='TagThisAmmotivation ml-2'>#trendingtitle #2ammotivation #trendinghashtag</p>
            <p className='TagThisAmmotivation ml-2'>#trendingtitle #2ammotivation #trendinghashtag</p>
            <p className='TagTrenThisText p-2'>
              Hello ☕️ I'm Positive John :) My mission here is to help your brand to act
              more user-centric and conv...   <span className='text-info'>Read more</span>
            </p>
            {/* <div className='text-center'> */}
            <img src={tagpic1} height={329} className='p-2' />
            {/* </div> */}
            <div className='TagTrendingTitleThisbuttonSeoundcard p-2'>
              <div className='d-flex justify-content-between p-2 TagTrendingThisTextColor'>
                <p className='d-flex mt-3 mb-2'><img src={Frame2} /><p className='posteds'>{numFormatter(3500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame3} /><p className='posteds'>{numFormatter(4500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame4} /><p className='posteds'>{numFormatter(5500)}</p></p>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="3" className='p-1'>
          <Card className='h-100'>
            <div className='d-flex justify-content-between align-items-start m-2'>
              <img src={Pollimg} alt='userImg' />
              <div className='ml-2'>
                <p className='TagThisTitle'>Floyd Miles <small className='text-body'> is watching House of Cards with </small> Ryan Reynolds at National Theatre, Prague</p>
                <p className='text-muted'>1 sept at 07:00 pm</p>
              </div>

              <UncontrolledButtonDropdown>
                <DropdownToggle className='p-0 m-0' color='flat' >
                  <MoreHorizontal size={19} color='#00000040' />
                </DropdownToggle>
                <DropdownMenu className='bizpagefordropdownmenu'>
                  <DropdownItem className='dropdownitem' ><Share2 size={13} />&nbsp;Share post</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><AlertTriangle size={13} />&nbsp;Edit</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;Delete</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <p className='TagThisAmmotivation'>#trendingtitle</p>

            {/* <div className='text-center'> */}
            <img src={fullpicTrending} height={357} className='p-2' />
            {/* </div> */}
            <div className='TagTrendingTitleThisbuttonSeoundcard p-2'>
              <div className='d-flex justify-content-between p-2 TagTrendingThisTextColor'>
                <p className='d-flex mt-3 mb-2'><img src={Frame2} /><p className='posteds'>{numFormatter(3500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame3} /><p className='posteds'>{numFormatter(4500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame4} /><p className='posteds'>{numFormatter(5500)}</p></p>
              </div>
            </div>
          </Card>
        </Col>
        <Col md="3" className='p-1'>
          <Card className='h-100'>
            <div className='d-flex justify-content-between align-items-start m-2'>
              <img src={Pollimg} alt='userImg' />
              <div className='ml-2'>
                <p className='TagThisTitle'>Floyd Miles <small className='text-body'> is watching House of Cards with </small> Ryan Reynolds at National Theatre, Prague</p>
                <p className='text-muted'>1 sept at 07:00 pm</p>
              </div>

              <UncontrolledButtonDropdown>
                <DropdownToggle className='p-0 m-0' color='flat' >
                  <MoreHorizontal size={19} color='#00000040' />
                </DropdownToggle>
                <DropdownMenu className='bizpagefordropdownmenu'>
                  <DropdownItem className='dropdownitem' ><Share2 size={13} />&nbsp;Share post</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><AlertTriangle size={13} />&nbsp;Edit</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;Delete</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <p className='TagThisAmmotivation'>#trendingtitle #2ammotivation #trendinghashtag</p>
            <div className='TagThisButtonPlay'>
              <img src={play} className='play' alt='play' />
            </div>
            {/* <div className='text-center'> */}
            <img src={tagpic2} height={357} className='p-2' />
            {/* </div> */}
            <div className='TagTrendingTitleThisbuttonSeoundcard p-2'>
              <div className='d-flex justify-content-between p-2 TagTrendingThisTextColor'>
                <p className='d-flex mt-3 mb-2'><img src={Frame2} /><p className='posteds'>{numFormatter(3500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame3} /><p className='posteds'>{numFormatter(4500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame4} /><p className='posteds'>{numFormatter(5500)}</p></p>
              </div>
            </div>

          </Card>
        </Col>
        <Col md="3" className='p-1'>
          <Card className='h-100'>
            <div className='d-flex justify-content-between align-items-start m-2'>
              <img src={Pollimg} alt='userImg' />
              <div className='ml-2'>
                <p className='TagThisTitle'>Floyd Miles <small className='text-body'> is watching House of Cards with </small> Ryan Reynolds at National Theatre, Prague</p>
                <p className='text-muted'>1 sept at 07:00 pm</p>
              </div>

              <UncontrolledButtonDropdown>
                <DropdownToggle className='p-0 m-0' color='flat' >
                  <MoreHorizontal size={19} color='#00000040' />
                </DropdownToggle>
                <DropdownMenu className='bizpagefordropdownmenu'>
                  <DropdownItem className='dropdownitem' ><Share2 size={13} />&nbsp;Share post</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><AlertTriangle size={13} />&nbsp;Edit</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem className='dropdownitem' ><Trash2 size={13} />&nbsp;Delete</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <p className='TagThisAmmotivation'>#trendingtitle #2ammotivation #trendinghashtag</p>
            <div className='TagThisButtonPlay'>
              <img src={play} className='play' alt='play' />
            </div>
            {/* <div className='text-center'> */}
            <img src={tagpic2} height={357} className='p-2' />
            {/* </div> */}
            <div className='TagTrendingTitleThisbuttonSeoundcard p-2'>
              <div className='d-flex justify-content-between p-2 TagTrendingThisTextColor'>
                <p className='d-flex mt-3 mb-2'><img src={Frame2} /><p className='posteds'>{numFormatter(3500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame3} /><p className='posteds'>{numFormatter(4500)}</p></p>
                <p className='d-flex mt-3 mb-2'><img src={Frame4} /><p className='posteds'>{numFormatter(5500)}</p></p>
              </div>
            </div>

          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TagTrendingTitle