import React, { useEffect, useLayoutEffect, useMemo, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AdmanagerHeaderR from "../../AdmanagerHeaderR/AdmanagerHeaderR";
import SociomeeLog from '../../../../NewComponents/IMG/SociomeeLogo.svg'
import UserPro from '../../../../NewComponents/IMG/Adpreview.svg'
import UserAdPro from '../../../../NewComponents/IMG/user2.png'
import Mic from '../../../../NewComponents/IMG/Mic.png'
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loadAdType } from "../../../../Services/Actions/AdManager/getAdTypeAction";
import { Button, Card, CardBody, CardHeader, CardText, Col, Collapse, Progress, Row, Modal, ModalHeader, ModalBody, Input, PopoverBody, Popover, UncontrolledTooltip } from "reactstrap";
import { ChevronsRight, Circle, Folder, Grid, Info, Maximize2, MoreHorizontal, MoreVertical, Send, Trash2, Users, X } from "react-feather";
import CommonDasboard from "../CommonDasboard";
import CommonHeader from "../CommonHeader";
import PreviewMoreSection from "../AdPreview/Sections/PreviewMoreSection";
import CarouselImage from "../AdPreview/Sections/CarouselImage";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const CaroselImageAdImage = () => {
  const types = useLocation();
  const { adType } = useSelector((state) => state.getTypeData);
  const adTypeData = useMemo(() => {
    return adType.find((ad) => ad.id === types.state.typeId);
  }, [adType]);
  //===========Snackbar Code============
  // const [countMedia, setCountMedia] = useState(0);
  const mediaCountRef = useRef(0);
  // const [media, setMedia] = useState("");
  const [imageDataUrl, setImageDataUrl] = useState('')
  const [imageGroupUrl, setImageGroupUrl] = useState('')
  const [imageStoryUrl, setImageStoryUrl] = useState('')
  const [imageBizUrl, setImageBizUrl] = useState('')
  const [imageMarketUrl, setImageMarketUrl] = useState('')
  const [imagePodcastUrl, setImagePodcastUrl] = useState('')
  const [imageEventUrl, setImageEventUrl] = useState('')
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ sev: "success", content: "" });
  const [loading, setLoading] = useState(false);
  // used for identify all fields are filled or not
  const [flag, setFlag] = useState(false);
  const [warningOn, setwarningOff] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [Imgdelete, setImgdelete] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
    setClicked(!clicked);
  }
  const [mediaData, setMediaData] = useState([
    {
      file: "",
      fileType: "image",
      heading: "",
      subHeading: "",
      callToActionId: "",
    },
  ]);
  const [adData, setAdData] = useState({
    discriptions: "",
    websiteLink: "",
    adStatus: "READY_TO_START",
    adTypesId: types.state.typeId,
    adSubTypesId: types.state.subTypeId,
    media: [],
  });
  const mediaInputsHandler = (ev, i) => {
    const { name, value, files } = ev.target;
    const newData = { ...data, heading: ev.target.value };
    setData(newData);
    // setIsButtonDisabled(value.trim() === '');
    let cloneArr = mediaData;
    let tempObj = cloneArr[i];
    if (name === "file") {
      tempObj = { ...tempObj, [name]: files[0] };
    } else {
      tempObj = { ...tempObj, [name]: value };
    }
    cloneArr[i] = tempObj;
    setMediaData([...cloneArr]);
  };
  const deleteImageHandler = (i) => {
    console.log("delete", i);
    let cloneArr = mediaData;
    let tempObj = cloneArr[i];
    tempObj = { ...tempObj, file: "" };
    cloneArr[i] = tempObj;
    setMediaData([...cloneArr]);
    setImgdelete(!Imgdelete)
  };
  //=============this function is used for submit details==========
  const submitDetails = (e) => {
    e.preventDefault();
    if (!flag) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Fill All Details !" });
    } else if (!adData.discriptions) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Description !" });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("uploadFor", "ads");
      mediaData.map((media) => formData.append("files", media.file));
      axios
        .post(`${process.env.REACT_APP_IPURL}/admin/UploadFile`, formData, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data.successResult);
            mediaData.file = res.data.data.successResult[0];
            adData.media = mediaData.map((media, i) => {
              media.file = res.data.data.successResult[i];
              return media;
            });
            console.log(adData);
            axios
              .post(
                `${process.env.REACT_APP_IPURL}/ads/create/adMaster/adType/subTypes/adManager`,
                adData,
                {
                  headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
                      }`,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                setLoading(false);
                setOpen(true);
                setAlert({
                  sev: "success",
                  content: "Ad Created  Successfully",
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setOpen(true);
            setAlert({
              sev: "error",
              content: `${res.data.data.errorResult}`,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const imageUpload = (id) => {
    document.getElementById(`input_file${id}`).click();
  };
  const [data, setData] = useState({
    heading: "",
    subHeading: ""
    // Add other data properties if needed
  });
  const isButtonDisabled = mediaData.heading === '' || mediaData.subHeading === '' || adData.discriptions === '';

  // const DeleteAlert = () => {
  //   return (
  //     <Modal isOpen={Imgdelete} toggle={() => setImgdelete(!Imgdelete)} className='modal-dialog-centered'>
  //       <ModalHeader className='' toggle={() => setImgdelete(!Imgdelete)}></ModalHeader>
  //       <ModalBody className='text-center'>
  //         <div className="m-3">
  //           <Trash2 color="red" size={58} />
  //           <h2 className="py-2">You Deleted One Image</h2>
  //         </div>
  //       </ModalBody>
  //     </Modal>
  //   )
  // }
  //===========================Cancel Snackbar======================
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useLayoutEffect(() => {
    dispatch(loadAdType());
  }, []);
  useEffect(() => {
    mediaData &&
      mediaData.map((media) => {
        if (
          media.heading &&
          media.subHeading &&
          media.file &&
          adData.discriptions
        ) {
          setFlag(true);
        } else {
          setFlag(false);
        }
      });
  }, [mediaData, adData]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Choose your CTA button");
  function handleBlur(e) {
    console.log(e);
  }
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const handleButtonClick = () => {
    // Add your logic for button click here, e.g., navigating to the next page
    console.log('Button clicked');
  };
  const [buttonText, setButtonText] = useState('Customize your Lead Generation Form');
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {

    setButtonText('Lead Generation Form Customize your Lead Generation Form');
    setClicked(true);
  };
  // const isButtonDisabled = data.heading === '' || data.subHeading === '' || adData.discriptions === '';
  return (
    <>
      {/* <DeleteAlert /> */}
      <AdmanagerHeaderR />
      <div >
        <Row style={{ height: 'calc(100vh - 80px)' }} className='rowForrevert shadow rounded-2'>
          {/*===========================Side bar============== */}
          <Col xl='2'>
            <Card className='h-100 d-none d-sm-block border-right '>
              <div className="p-4 border text-muted">
                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted">
                <div className='pb-1'><h6>Step 2</h6></div>
                <div className='text-dark'><h5><b>Select Ad Format</b></h5></div>
              </div>
              <div disabled className="p-4 borderLeftForDasboard">
                <div className='pb-1'><h6>Step 3</h6></div>
                <div className=' pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                  <p className={adTypeData?.adTypes === 'Lead Generation' ? '' : 'd-none'} ><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 4</h6></div>
                <div className=' pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 5</h6></div>
                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 6</h6></div>
                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
              </div>
            </Card>
          </Col>
          {/*=========================Main Card===================== */}
          <Col xl='10' className='px-2'>
            <Row className='rowForrevert'>
              {/* header */}
              <Col xl='12' xs='12'>
                <CommonHeader />
              </Col>
            </Row>
            <Row className='rowForrevert shadow rounded-2 mt-1'>
              {/*================Sub-header============== */}
              <Col xl='12'>
                <Card className='border-0 my-1'>
                  <div className="p-3 d-flex justify-content-between">
                    <div className="navigate-left">
                      <p className="navigate-color">
                        {adTypeData?.adTypes} - (
                        {adTypeData?.adMastrerType.name}) / Carousel Images ad
                      </p>
                    </div>
                    <div className="navigate-right">
                      <h4>
                        Preview on
                        <span>
                          <button
                            type="button"
                            class="btn btn-lg btn-size btn-toggle"
                            data-toggle="button"
                            aria-pressed="false"
                            autocomplete="off"
                            onClick={toggle}
                          >
                            <div class="handle"></div>
                          </button>
                        </span>
                      </h4>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className='rowForrevert gap-1 shadow mt-1'>
              <Col xl={isOpen ? '8' : '12'} className={isOpen ? 'p-2 card shadow-sm' : 'p-2 card shadow-sm'} >
                <Row className='rowForrevert'>
                  <Col xl='12'>
                    <div className="p-2">
                      {mediaData &&
                        mediaData.map((data, i) => {
                          return (
                            <div
                              className="inputs d-flex mb-3 p-0 input-img"
                              key={i}
                            >
                              {/* <div className="col-lg-6 col-12"> */}
                              <div className={isOpen ? "col-lg-12 col-12" : "col-lg-6 col-12"}>
                                {(adTypeData?.adTypes === 'Website Visits' || adTypeData?.adTypes === 'Lead Generation') ? (
                                  <>
                                    {/* =========================dropdown==================== */}
                                    <div>
                                      <div>
                                        <p className="p-heading">
                                          Call to Action (CTA)
                                          <span className="pl-1">
                                            <Info fill='#828282' id='callTooltip' color='white' size={18} />
                                            <UncontrolledTooltip placement='right' target='callTooltip'>
                                              Add sections to your call to cta action.
                                            </UncontrolledTooltip>
                                          </span>
                                        </p>
                                      </div>
                                      <div className="dropdownText">
                                        <div
                                          onClick={(e) => {
                                            setIsActive(!isActive);
                                          }}
                                          className="dropdownText-btn"
                                        >
                                          <p>{selected}</p>
                                          <span
                                            className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
                                          />
                                        </div>
                                        <div
                                          className="dropdowncon-content"
                                          style={{ display: isActive ? "block" : "none" }}
                                        >
                                          <div
                                            onClick={(e) => {
                                              setIsSelected(e.target.textContent);
                                              setIsActive(!isActive);
                                            }}
                                            className="item"
                                          >
                                            Contact Us
                                          </div>
                                          <div
                                            className="item"
                                            onClick={(e) => {
                                              setIsSelected(e.target.textContent);
                                              setIsActive(!isActive);
                                            }}
                                          >
                                            Signup
                                          </div>
                                          <div
                                            className="item"
                                            onClick={(e) => {
                                              setIsSelected(e.target.textContent);
                                              setIsActive(!isActive);
                                            }}
                                          >
                                            Get a quote
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* =========================dropdown end================= */}
                                  </>) : null}
                                <div className="d-flex justify-content-between mt-2">
                                  <p className="p-heading">
                                    Ad Heading
                                    <span className="pl-1">
                                      <Info fill='#828282' id='adTooltip' color='white' size={18} />
                                      <UncontrolledTooltip placement='right' target='adTooltip'>
                                        Add a catchy heading to engage users!.
                                      </UncontrolledTooltip>
                                    </span>
                                  </p>
                                  <p className="p-heading">({i + 1}/5) Media</p>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control p-2"
                                    placeholder="Type your Ad's Heading"
                                    maxLength={48}
                                    name="heading"
                                    value={mediaData.heading}
                                    onChange={mediaInputsHandler}
                                  />
                                </div>
                                <p className="p-max-car">Max 48 Characters</p>

                                <div className="">
                                  <p className="p-heading">
                                    Ad Sub-Heading
                                    <span className="pl-1">
                                      {/* <img
                                        src="/assets/images/adIcon/alert-circle.png"
                                        alt=""
                                      /> */}
                                      <Info fill='#828282' id='adhTooltip' color='white' size={18} />
                                      <UncontrolledTooltip placement='right' target='adhTooltip'>
                                        Add sections to your ad campaign with sub heading.
                                      </UncontrolledTooltip>
                                    </span>
                                  </p>
                                </div>
                                <div className="">
                                  <input
                                    type="text"
                                    className="form-control p-2"
                                    placeholder="Type your Ad's Heading"
                                    maxLength={"60"}
                                    name="subHeading"
                                    value={mediaData.subHeading}
                                    onChange={mediaInputsHandler}
                                  />
                                </div>
                                <p className="p-max-car">Max 60 Characters</p>
                              </div>
                              <Col className={!isOpen ? '' : 'd-none'} xl='12'>
                                <div className="col-lg-6 col-12">
                                  <div className="d-flex justify-content-between">
                                    <p className="p-heading">Add Your Image</p>
                                    {i !== 0 && (
                                      <p
                                        className="p-heading text-primary"
                                        role="button"
                                        onClick={() =>
                                          setMediaData(
                                            mediaData.filter(
                                              (med, ind) =>
                                                ind !== mediaData.length - 1
                                            )
                                          )
                                        }
                                      >
                                        Remove
                                      </p>
                                    )}
                                  </div>
                                  {data.file?.name ? (
                                    <div className="recomandation-display-block position-relative">
                                      <img
                                        src={URL.createObjectURL(data.file)}
                                        alt=""
                                        style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '5px'  }}

                                      />
                                      {/* new-recom-btn-cont-blk */}
                                      <div className="recom-btn-cont-blk  bottom-0" style={{ borderRadius: '5px' }}>
                                        <div className="container ">
                                          <div className="row d-flex justify-content-center" >
                                            <div className="col-4 px-auto border-right">
                                              <h4
                                                className="text-center"
                                                role="button"
                                                onClick={() => imageUpload(i)}
                                              >
                                                Edit
                                              </h4>
                                            </div>
                                            <div className="col-4 px-auto border-left">
                                              <h4
                                                className="text-center"
                                                role="button"
                                                onClick={() => deleteImageHandler(i)}
                                              >
                                                Delete
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      role="button"
                                      onClick={() => imageUpload(i)}
                                      className="upload-img w-100 d-flex flex-column justify-content-center align-items-center"
                                    >
                                      <img
                                        src="/assets/images/adIcon/upload.png"
                                        alt=""
                                      />
                                      <h4 className="mt-2 font-weight-bold">
                                        Upload Image
                                      </h4>
                                    </div>
                                  )}

                                  <input
                                    type="file"
                                    name="file"
                                    id={`input_file${i}`}
                                    hidden
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      if (file) {
                                        const reader = new FileReader();
                                        reader.onloadend = () => {
                                          const imageDataUrl1 = reader.result;
                                          console.log('===========>imageDataUrl', imageDataUrl1)
                                          setImageGroupUrl(imageDataUrl1)
                                          // if (mediaCountRef.current === 0) {
                                          //   setImageDataUrl(imageDataUrl1);
                                          //   mediaCountRef.current = 1;
                                          // } else if (mediaCountRef.current === 1) {
                                          //   setImageGroupUrl(imageDataUrl1);
                                          //   mediaCountRef.current = 2;
                                          // }
                                          // else if (mediaCountRef.current === 2) {
                                          //   setImageStoryUrl(imageDataUrl1);
                                          //   mediaCountRef.current = 3;
                                          // } else if (mediaCountRef.current === 3) {
                                          //   setImageBizUrl(imageDataUrl1);
                                          //   mediaCountRef.current = 4;
                                          // } else if (mediaCountRef.current === 4) {
                                          //   setImageMarketUrl(imageDataUrl1)
                                          //   mediaCountRef.current = 5;
                                          // }
                                          // else if (mediaCountRef.current === 5) {
                                          //   setImagePodcastUrl(imageDataUrl1)
                                          //   mediaCountRef.current = 6;
                                          // }

                                          // setImageDataUrl(imageDataUrl1)
                                          // setImageGroupUrl(imageGroupUrl)
                                          // onImageSelect(imageDataUrl);
                                        };
                                        reader.readAsDataURL(file);

                                      }
                                      e.target.files[0].type.slice(0, 5) === "image"
                                        ? mediaInputsHandler(e, i)
                                        : deleteImageHandler(e, i);
                                      e.target.files[0].type.slice(0, 5) !==
                                        "image" && setOpen(true);
                                      setAlert({
                                        sev: "error",
                                        content: "Please Select Image Only !",
                                      });
                                    }}
                                    onClick={(e) => {
                                      e.target.value = null;
                                    }}
                                  />
                                </div>
                              </Col>
                            </div>

                          );
                        })}
                      {mediaData.length < 5 && (
                        <div class="ad-buttons-blk">
                          <div
                            class="ad-btn-new ad-green-clr-btn ml-3"
                            role="button"
                            onClick={() =>
                              setMediaData([
                                ...mediaData,
                                {
                                  file: "",
                                  fileType: "image",
                                  heading: "",
                                  subHeading: "",
                                  callToActionId: "",
                                },
                              ])
                            }
                          >
                            + Add More Images
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {adTypeData?.adTypes === 'Website Visits' ?
                  <Row className='rowForrevert m-1'>
                    <Col xl='12' style={{ marginLeft: '22px', paddingRight: '37px' }}>
                      <div className="textarea py-2">
                        <div className="">
                          <p className="p-heading">
                            Provide Web link
                            <span className="pl-1">
                              <Info fill='#828282' id='plTooltip' color='white' size={18} />
                              <UncontrolledTooltip placement='right' target='plTooltip'>
                                provide your web link url.
                              </UncontrolledTooltip>
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            className="form-control p-2"
                            placeholder="Enter your web link here......"
                            // maxLength={"60"}
                            name="subHeading"
                          // value={mediaData.subHeading}
                          // onChange={mediaInputsHandler}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row> : null}
                <Row className='rowForrevert'>
                  {/* Discription Box */}
                  <Col xl='12'>
                    <div className="textarea px-3 py-2">
                      <div className="ml-2">
                        <p className="p-heading ml-1">
                          Ad Description{" "}
                          <span className="pl-1">
                            <Info fill='#828282' id='deTooltip' color='white' size={18} />
                            <UncontrolledTooltip placement='right' target='deTooltip'>
                              Describe ad campaign to your target audience.
                            </UncontrolledTooltip>
                          </span>
                        </p>
                      </div>
                      <div className="">
                        <textarea
                          name="message"
                          className="form-control"
                          rows="5"
                          placeholder="Type your advertisement sub-heading..."
                          maxLength={"300"}
                          value={adData.discriptions}
                          onChange={(e) =>
                            setAdData({ ...adData, discriptions: e.target.value })
                          }
                        ></textarea>
                      </div>
                      <p className="p-max-car">Max 300 Characters</p>
                    </div>
                  </Col>
                </Row>
                <Row className='rowForrevert mt-1'>
                  {/* <Col></Col> */}
                  <div xl={!isOpen ? '6' : '12'} className={adTypeData?.adTypes === 'Lead Generation' ? '' : 'd-none'}>
                    <div className="d-flex justify-content-between m-2">
                      <div>
                        <div style={{ marginLeft: '19px' }}>
                          {!clicked && (
                            <Link to='/Ad/LeadGeneration'>
                              <Button
                                tag='a'
                                style={{
                                  position: 'absolute',
                                  marginTop: '26px',
                                  marginRight: '13px',
                                  color: clicked ? '#81c14b' : '#81c14b',
                                  backgroundColor: clicked ? 'white' : 'white',
                                  border: `1px solid ${clicked ? '#81c14b' : '#81c14b'}`
                                }}
                              // onClick={handleClick}
                              // readOnly
                              >
                                Customize your Lead Generation Form
                              </Button></Link>
                          )}
                          {clicked && <span style={{ color: '#4D4D4D', fontSize: '15px' }}>Lead Generation Form Customize your Lead Generation Form</span>}
                        </div>
                      </div>
                      <div>
                        {clicked && (
                          <Button tag='a' href='/Ad/LeadGeneration' color='primary' outline style={{ marginRight: '13px' }}>
                            Create Form
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div xl={adTypeData?.adTypes === 'Lead Generation' ? '6' : '12'} className={!isOpen ? '' : 'd-none'} >
                    <div className="p-3">
                      <div className="ad-btn">
                        {/* <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                          Back
                        </Button> */}
                        <div className="">
                          {/* p-3 */}
                          <div className="ad-btn" style={{ marginTop: '-20px' }}>
                            <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                              Back
                            </Button>
                            <Button
                              id='controlledPopover'
                              color='primary'
                              size='md'
                              className={`ml-2 ${isButtonDisabled ? 'disabled-button' : ''}`}
                              style={{
                                backgroundColor: isButtonDisabled ? 'rgb(172, 218, 214)' : '#81C14B',
                                cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                                border: 'none',
                                outline: 'none',
                                color: 'white'
                              }}
                              onClick={() => {
                                if (!isButtonDisabled) {
                           
                                  navigate('/Ad/ChooseAudience');
                                }
                              }}
                              disabled={isButtonDisabled}
                            >
                              Next
                            </Button>
                            {/* Popover logic can be added here if needed */}
                          </div>

                        </div>
                        <Popover
                          placement='top'
                          target='controlledPopover'
                          isOpen={warningOn}
                          toggle={() => setwarningOff(!warningOn)}
                        >
                        </Popover>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              {/* Preview Section */}
              <Col className={!isOpen ? 'd-none' : 'p-2 card shadow-sm'}>
                <Collapse horizontal isOpen={isOpen}>
                  {/* <PreviewMoreSection /> */}
                  <CarouselImage heading={mediaData.heading} subHeading={mediaData.subHeading} discriptions={adData.discriptions} imageDataUrl={imageDataUrl} imageGroupUrl={imageGroupUrl} imageStoryUrl={imageStoryUrl} imageBizUrl={imageBizUrl} />
                </Collapse>
              </Col>
            </Row>

            <Row className={!isOpen ? 'd-none' : 'rowForrevert p-2 card shadow-sm'}>
              <Col xl='12'>
                <div className="p-3">
                  <div className="ad-btn">
                    <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                      Back
                    </Button>
                    <Button id='controlledPopover' color='primary' size='md' className="ml-2" onClick={() => navigate('/Ad/ChooseAudience')} >
                      Next
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {loading && <Loader loading={loading} />}
          </Col>
        </Row>
      </div >
      <Stack spacing={2} sx={{ width: "100%" }} id="stack">
        {/* Snackbar */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.sev}
            sx={{ width: "100%" }}
          >
            {alert.content}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CaroselImageAdImage;
