import React, { Fragment, useState } from 'react';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

const MpFilterTabComponent = ({ data, setAttributeVal, attributeVal }) => {
    const [activeTabs, setActiveTabs] = useState({});

    const handleClick = (dataIndex, index, value) => {
        setActiveTabs((prevState) => ({
            ...prevState,
            [dataIndex]: index,
        }));

        setAttributeVal((prevValues) => {
            const newTabValues = [...prevValues.tabValues];
            newTabValues[dataIndex] = value; // Set the value for the current tab index
            return {
                ...prevValues,
                tabValues: newTabValues
            };
        });
    };

    const handleClearAll = (dataIndex) => {
        setActiveTabs((prevState) => ({
            ...prevState,
            [dataIndex]: null,
        }));

        setAttributeVal((prevValues) => {
            const newTabValues = [...prevValues.tabValues];
            newTabValues[dataIndex] = undefined; // Clear the value for the current tab index
            return {
                ...prevValues,
                tabValues: newTabValues.filter(v => v !== undefined) // Remove undefined values
            };
        });
    };

    return (
        <Fragment>
            {data.map((data, dataIndex) => (
                <AccordionBody key={dataIndex} style={{ borderBottom: "1px solid #E5E5E5" }}>
                    <div>
                        <div className="MpUploadHeading">
                            <h3 style={{ fontWeight: "600" }}>{data?.label}</h3>
                            <h5
                                className='MrktClearAllBtn'
                                onClick={() => handleClearAll(dataIndex)}
                                style={{ cursor: "pointer", color: "#81c14b" }}
                            >
                                Clear All
                            </h5>
                        </div>
                        <div className="Mp_Filter_Tabs mt-3">
                            <div className="Mp_Filter_Tabs_Top">
                                {data?.AttributeValues.length > 0 && data.AttributeValues.map((tab, index) => (
                                    <div
                                        className={`Mp_Tab_navItems Mp_TabsHover ${activeTabs[dataIndex] === index ? "Mp_TabsActive" : ""}`}
                                        onClick={() => handleClick(dataIndex, index, tab?.value)}
                                        key={index}
                                    >
                                        {tab?.value}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </AccordionBody>
            ))}
        </Fragment>
    );
};

export default MpFilterTabComponent;
