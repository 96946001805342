import React from 'react'
import { Fragment } from 'react'
import { Clock, Globe, Mail, MapPin, Phone, XCircle } from 'react-feather'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const HeadingDespModal = (props) => {
    return (
        <Fragment>

            {
                props.isOpen !== false &&
                < Modal isOpen={props.isOpen3} toggle={props.toggleOpenPoint1} className='modal-dialog-centered' >
                    <div

                        onClick={props.toggleOpenPoint1}
                        className="Modal_False_blk1"
                    >
                        <XCircle color="#FFFFFF" />
                    </div>
                    <ModalHeader className="Biz_Rating_SECTION">
                        <h3>Add / Edit Additional Info</h3>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="BizAddition_heading_section">
                            <h3>Heading and Description </h3>
                        </FormGroup>
                        <FormGroup>
                            <Label className="Biz_Rating_sab_section">
                                <h3>Heading</h3>
                            </Label>
                            <Input
                                id="exampleEmail"
                                name="catalogue"
                                placeholder="Enter your heading here "
                                type="catalogue"
                                maxLength="60"
                            />
                            <p className='text-end mt-1 WataDo_limit_section'>Max 60 charectors </p>
                        </FormGroup>
                        <FormGroup>
                            <Label className='my-1 Biz_Rating_sab_section' ><h3>Description</h3></Label>
                            <Input type='textarea'
                                cols="15"
                                rows="5"
                                placeholder="Enter your description here "
                                maxLength="300"
                            />
                            <p className='text-end mt-1 WataDo_limit_section'>Max 300 Characters</p>
                        </FormGroup>
                        <div className='text-center'>
                            <Button className=""    onClick={props.toggleOpenPoint1} outline>Cancel</Button>
                            <Button color="primary " onClick={props.toggleOpenPoint1} className=" ml-2 ">&nbsp;Save&nbsp;</Button>
                        </div>
                    </ModalBody>
                </Modal>
            }
        </Fragment >
    )
}

export default HeadingDespModal