import React, { Fragment, useEffect, useState } from 'react';
import { Input } from 'reactstrap';

const handleKeyPress = (event) => {
    // Allow only numeric characters (0-9) and backspace
    if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
    }
};

const handlePaste = (event) => {
    event.preventDefault(); 
    // Get the pasted text from the clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from the pasted text
    const numericText = pastedText.replace(/[^\d]/g, '');

    // Update the input value with the cleaned numeric text
    document.execCommand('insertText', false, numericText);
};

const MpFormPriceInput = ({ data, onDataPass ,value}) => {
    const [price, setPrice] =useState(null)
    
    //setting value param for edit screen backEnd data  
    useEffect(() => {
        if (value) {
            setPrice(value);
        }
    }, [value]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setPrice(inputValue)
        onDataPass({ value: inputValue, type: 'input' }); // Passing data to parent component immediately
    };

    const handleSelectChange = (event) => {
        //this is selection of the currency will develope future
        // const selectValue = event.target.value;
        // onDataPass({ value: selectValue, type: 'select' }); // Passing data to parent component immediately
    };

    return (
        <Fragment>
            <div className='MpformElementHeading'>
                <h5>{data.title}</h5>
            </div>
            <div className="MpFormPriceInput d-flex align-items-center justify-content-between border mt-2" style={{ borderRadius: "5px", padding: "4px 0", paddingRight: '10px' }}>
                <Input
                    placeholder={data.placeholder}
                    className="border-right rounded-0"
                    style={{ width: "70%", border: "none" }}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={handleInputChange}
                    maxLength={8}
                    value = {price}
                />
                <Input
                    type="select"
                    style={{ width: "30%", border: "none" }}
                    onChange={handleSelectChange}
                >
                    <option value="1">USD</option>
                    <option value="2">IND</option>
                    <option value="3">UAE</option>
                </Input>
            </div>
        </Fragment>
    );
};

export default MpFormPriceInput;
