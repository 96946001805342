import React, { Fragment, useState } from 'react'
import Header from '../../../Header'
import LeftSidebar from '../../../LeftSidebar'
import RightSidebar from '../../../RightSidebar'
import PodcastHeader from '../../PodcastHeader'
import CommonSeeallpage from './CommonSeeallpage'
import PodcastFooter from '../../PodcastFooter'



const SinglePodcast = () => {
    return (
        <Fragment>

            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="container-fluid section-t-space p-0 mt-0">
                        {/*  ----------------------  Common Header --------------------  */}
                        <PodcastHeader />
                        <PodcastFooter />
                    </div>
                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default SinglePodcast