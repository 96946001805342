import React, { useEffect, useState } from 'react';
import { AlertCircle, Bookmark, EyeOff, Globe, Link2, Lock, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import Slider from "react-slick";
import { Button, Card, } from 'reactstrap';
import { sweetAlertConfirmation } from './SweetAlert';
import PinIcon from '../../../NewComponents/IMG/PinIcon.svg'
import { loadAllInvitationGroup, followGroupAction, declineGroupAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';


const InviteGrpSection = () => {

    const dispatch = useDispatch()
    const { getGroupInvitation } = useSelector((state) => { return state.getAllGroupInvitationData });
    const { followGroup } = useSelector((state) => { return state.postFollowGroupFromGroupInvitationData });
    const { declineGroup } = useSelector((state) => { return state.postDeclineGroupFromGroupInvitationData });
    
    console.log('*****', getGroupInvitation)
    
    useEffect(() => {
        dispatch(loadAllInvitationGroup())
    }, [])

    const followInvitationGroup = (id) => {
        dispatch(followGroupAction(id))
        sweetAlertConfirmation('Send Follow Request')
    }
    const declineInvitationRequest = (id) => {
        dispatch(declineGroupAction(id))
        sweetAlertConfirmation('Declined from the group')
    }


    const member = 0
    let ownedGroupSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    //================== Num_Formatter_Function ==================//
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }//================== Card_Info_Array ==================//
    const InviteDataInfo = [
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... "


        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... "


        },
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
    ]

    // ================== main_return_function==================//
    return (
        <>
            {getGroupInvitation && getGroupInvitation?.data?.successResult?.data?.rows?.length > 0 ?
                <div className="group-custom-block">
                    <div className="heading-podcast-blk">
                        <h3>Group Invitation... ({getGroupInvitation && getGroupInvitation?.data?.successResult?.data?.rows?.length})</h3>
                        {getGroupInvitation && getGroupInvitation?.data?.successResult?.data?.rows?.length > 4 ?
                            <NavLink to="/InviteGroup" className="single-ancor-blk"> See All → </NavLink> :
                            <NavLink to="" className="disabled"> See All → </NavLink>
                        }
                    </div>
                    <Slider {...ownedGroupSettings} className="default-space">

                        {
                            getGroupInvitation && getGroupInvitation?.data?.successResult?.data?.rows?.map((data, index) => (
                                <div>
                                    <Card className="group-slide-box">
                                        <div className="gallery-img-sublink">

                                        <div className='d-flex justify-content-between'>
                                                    <div>
                                                        {data?.isPinned === 1 ? <img src={PinIcon} alt='pined' style={{position: 'absolute', right: '225px'}}/> : ''}
                                                    </div>
                                            <Link to="#" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></Link>
                                           </div>
                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                <ul>
                                                    <li>
                                                        <Link to="/MyProfile"><User size={15} color="#4D4D4D" /> View Profile</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#"><AlertCircle size={15} color="#4D4D4D" /> Learn More</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <NavLink to={`/GroupDetail/${data.id}/${data.groupName}/${member}/${index}`}>
                                           <img alt='' src={data.coverImage} className="img-fluid group-img" 
                                           onError={(e) => {e.target.onerror = null; e.target.src=`${process.env.REACT_APP_IMAGEURL}/test/bxlJIIWSZ2SOk.jpg`}}
                                          />
                                        </NavLink>
                                        <div className="group-content">
                                            <h4 className='text-capitalize'>
                                                {data.fullName.substring(0, 20)}
                                                {data.fullName.length >= 15 && ' ...'}
                                            </h4>
                                            {data.Type === "People" ?
                                                <div className="Suggestmember-blk">
                                                    <span><Globe style={{ marginTop: '-3px' }} color='#4D4D4D' size={12} /> People </span> • <span>
                                                        {
                                                            data.totalMembers > 0 ?
                                                                <>
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members'}
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                        }
                                                    </span>
                                                </div> :
                                                <div className="Suggestmember-blk">
                                                    <span><Lock style={{ marginTop: '-3px' }} color='#4D4D4D' size={12} />  Group </span> • <span>
                                                        {
                                                            data.totalMembers > 0 ?
                                                                <>
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members'}
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                        }
                                                    </span>
                                                </div>
                                            }
                                            <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                <p>{data.paragraph}</p>
                                            </div>
                                            <div className="group-buttons-blk mt-3">
                                                <Button className="Invite_Join_Button" color='primary'
                                                    onClick={() => {
                                                        followInvitationGroup(data.userId)
                                                    }}
                                                >
                                                    {data.status === 'pending' ? 'Follow' : 'UnFollow'}
                                                </Button>
                                                <Button className="Invite_Decline_Button ml-2" outline
                                                    onClick={() => {
                                                        declineInvitationRequest(data.id)
                                                    }}
                                                >Decline</Button>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))
                        }
                    </Slider>
                </div> : ''}
        </>
    )
}

export default InviteGrpSection