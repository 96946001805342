import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import '../style.css'
import { Pagination, Stack } from '@mui/material'
import { Accordion } from 'react-bootstrap'
import Request from "../Img/Request.png"
import Request1 from "../Img/Request1.png"
import Request2 from "../Img/Request2.png"
import Request3 from "../Img/Request3.png"
import { Button, CardTitle, Col, CustomInput, Input, Modal, ModalBody, ModalFooter, Row, } from 'reactstrap'
import moment from 'moment'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import Success from "../Img/Success.png"
import { useParams } from 'react-router-dom'
import { getallGroupsAdminsEct, getAllGroupMembers, postGroupRolesAction, removeGroupRolesAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { useDispatch, useSelector } from 'react-redux'
import { data } from 'jquery'
import { sweetAlertConfirmation } from '../AllGroupsSections/SweetAlert'


const MyGroupMember = ({ basisOnFilter, searchMember }) => {

    const params = useParams()
    let dispatch = useDispatch();
    const { getAllGroupAdmins } = useSelector((state) => state.getAllGroupAdminAndMembersData);
    const { getAllGroupMembersData } = useSelector((state) => state.getAllGroupMembersData);
    // const { postGroupRolesData } = useSelector((state) =>  state.postGroupRolesData );

    const postGroupRolesData = useSelector((state) => { return state.postGroupRolesData });
    const { loading: loadingEvents, error: errorEvents, result: resultEvents } = postGroupRolesData

    // console.log('SearchQuery***', resultEvents)

    const [filterSuperAdmin, setFilterSuperAdmin] = useState('');
    const [filterAdmin, setFilterAdmin] = useState('');
    const [filterMembers, setFilterMembers] = useState('');
    const [filterManager, setFilterManager] = useState('');

    useEffect(() => {
        if (basisOnFilter === 'All') {
            setFilterSuperAdmin('superAdmin')
            setFilterAdmin('admin')
            setFilterMembers('members')
            setFilterManager('manager')
        }
        else if (basisOnFilter === 'superAdmin') {
            setFilterSuperAdmin('superAdmin')
            setFilterAdmin('')
            setFilterMembers('')
            setFilterManager('')
        }
        else if (basisOnFilter === 'admin') {
            setFilterSuperAdmin('')
            setFilterAdmin('admin')
            setFilterMembers('')
            setFilterManager('')
        }
        else if (basisOnFilter === 'members') {
            setFilterSuperAdmin('')
            setFilterAdmin('')
            setFilterMembers('members')
            setFilterManager('')
        }
        else if (basisOnFilter === 'manager') {
            setFilterSuperAdmin('')
            setFilterAdmin('')
            setFilterMembers('')
            setFilterManager('manager')
        }
        else {
            setFilterSuperAdmin('superAdmin')
            setFilterAdmin('admin')
            setFilterMembers('members')
            setFilterManager('manager')
        }
    }, [basisOnFilter]);

    useEffect(() => {
        if (searchMember !== '') {
            setFilterSuperAdmin('')
            setFilterAdmin('')
            setFilterMembers('members')
            setFilterManager('')
        }
    }, [searchMember]);






    const updateRoleAndClose = () => {
        dispatch(getAllGroupMembers(params.id));
        setAssignSuccessfully(false)
        setAssignGroup(false)
    }
    const [removeRoleChanged, setRemoveRoleChanged] = useState(false);

    const toggleRemove = (groupId) => {
        dispatch(removeGroupRolesAction(groupId, params.id))
        setAssignSuccessfully(false)
        setRemoveRoleChanged(true)
        setAssignGroup(false)
    }


    useEffect(() => {
        if (removeRoleChanged === true) {
            dispatch(getAllGroupMembers(params.id));
            setRemoveRoleChanged(false)
        }
        setRemoveRoleChanged(false)
    }, [removeRoleChanged, dispatch, params.id,]);

    const [statusPostRole, setStatusPostRole] = useState(false)

    useEffect(() => {
        if (resultEvents) {
            setStatusPostRole(true)
        }

    }, [resultEvents]);

    useEffect(() => {
        if (statusPostRole) {
            dispatch(getAllGroupMembers(params.id));
            sweetAlertConfirmation(resultEvents)
            setStatusPostRole(false)
        }
        setStatusPostRole(false)
    }, [statusPostRole, dispatch, params.id]);




    const [open, setOpen] = useState(null);

    const toggleOpen = (id) => {
        if (open === id) {
            setOpen(null);
        } else {
            setOpen(id);
        }
    };

    const [assignGroup, setAssignGroup] = useState(false)
    const [memberId, setMemberId] = useState(false)
    const [fullName, setFullName] = useState(false)
    const [followingDate, setFollowingDate] = useState(false)
    const [profileImage, setProfileImage] = useState(false)
    const [userId, setUserId] = useState(false)
    const [role, setRole] = useState(false)

    const toggleClose = (id, fullName, followingDate, profileImage, role, userId) => {
        setMemberId(id)
        setFullName(fullName)
        setFollowingDate(followingDate)
        setProfileImage(profileImage)
        setRole(role)
        setUserId(userId)

        setAssignGroup((prevState) => !prevState)
    }
    const [assignSuccessfully, setAssignSuccessfully] = useState(false)
    const toggleSuccessfully = () => {
        setAssignGroup(false)
        setAssignSuccessfully((prevState) => !prevState)
    }


    //--------------Array_LISTING fOR ALL TYPES like AdminData_ ModeratorsData_ MembersData_ RequestData_ 

    const [roleEdit, setRoleEdit] = useState(false)
    const [roleUpdate, setRoleUpdate] = useState(false)
    const [roleDelete, setRoleDelete] = useState(false)


    const [roleEditModerator, setRoleEditModerator] = useState(false)
    const [roleUpdateModerator, setRoleUpdateModerator] = useState(false)
    const [roleDeleteModerator, setRoleDeleteModerator] = useState(false)

    const [roleEditManager, setRoleEditManager] = useState(false)
    const [roleUpdateManager, setRoleUpdateManager] = useState(false)
    const [roleDeleteManager, setRoleDeleteManager] = useState(false)

    const [sendRole, setSendRole] = useState('admin')


    const handleOptionChangeAdmin = (event) => {
        const { id, checked } = event.target;

        if (id === "1") {
            setRoleEdit(checked ? 1 : 0);
        } else if (id === "2") {
            setRoleUpdate(checked ? 1 : 0);
        } else if (id === "3") {
            setRoleDelete(checked ? 1 : 0);
        }
    }

    const handleOptionChangeModerator = (event) => {
        const { id, checked } = event.target;

        if (id === "4") {
            setRoleEditModerator(checked ? 1 : 0);
        } else if (id === "5") {
            setRoleUpdateModerator(checked ? 1 : 0);
        } else if (id === "6") {
            setRoleDeleteModerator(checked ? 1 : 0);
        }
    }

    const handleOptionChangeManager = (event) => {
        const { id, checked } = event.target;

        if (id === "7") {
            setRoleEditManager(checked ? 1 : 0);
        } else if (id === "8") {
            setRoleUpdateManager(checked ? 1 : 0);
        } else if (id === "9") {
            setRoleDeleteManager(checked ? 1 : 0);
        }
    }


    const cancelAllSelection = (role) => {
        setSendRole(role)

        setRoleEdit(false)
        setRoleUpdate(false)
        setRoleDelete(false)

        setRoleEditModerator(false)
        setRoleUpdateModerator(false)
        setRoleDeleteModerator(false)

        setRoleEditManager(false)
        setRoleUpdateManager(false)
        setRoleDeleteManager(false)
    }

    const applyRole = () => {
        console.log('manager*****', sendRole)
        if (sendRole === 'admin') {
            console.log(sendRole, roleEdit, roleUpdate, roleDelete, userId, params.id)
            dispatch(postGroupRolesAction(sendRole, roleEdit, roleUpdate, roleDelete, userId, params.id))
        }

        else if (sendRole === "moderator") {
            console.log(sendRole, roleEditModerator, roleUpdateModerator, roleDeleteModerator, userId, params.id)
            dispatch(postGroupRolesAction(sendRole, roleEditModerator, roleUpdateModerator, roleDeleteModerator, userId, params.id))
        }

        else if (sendRole === "manager") {
            console.log(sendRole, roleEditManager, roleUpdateManager, roleDeleteManager, userId, params.id)
            dispatch(postGroupRolesAction(sendRole, roleEditManager, roleUpdateManager, roleDeleteManager, userId, params.id))

        }

        setAssignSuccessfully((prevState) => !prevState)
    }



    //======================= Assign_Group_Role_Modal =============//
    const AssignGroupData = ({ memberId, fullName, followingDate, profileImage, role }) => (
        <Fragment>
            <Row className="row align-items-center">
                <Col md="12" className="mb-2">
                    <h3>Assign Group Role</h3>
                </Col>
            </Row>
            <hr />
            <Row>
                <div className="group-detail-page-blk mt-2">
                    <Row>

                        <Col md="12" sm="12">
                            <div className="group-members-block">
                                <div className="group-member-img-cont-blk">
                                    <img src={profileImage} alt="img" />
                                    <div className="member-cont-blk">
                                        <h4>{fullName ? fullName : ''}</h4>
                                        <p>{followingDate ? followingDate : ''}</p>
                                        <p>{role}</p>
                                        {/* - {roleEdit}, {roleUpdate}, {roleDelete}, {sendRole} */}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md="12" sm="12">
                            <Accordion open={false} toggle={toggleOpen}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">
                                        <div className='Assign_Check_Section'>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Assign_Check"
                                                onClick={() => { cancelAllSelection('admin') }}
                                            />
                                            {' '}
                                            <div className='Assign_Sub_Section'>
                                                <h4>Assign as Admin</h4>
                                                <p>Admin can approve or remove posts, followers and messages on the group.</p>
                                            </div>
                                        </div>

                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>

                                            <p className='d-flex align-items-center '>
                                                <Input
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="1"
                                                    name="roleEdit"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleEdit}
                                                    onChange={handleOptionChangeAdmin}
                                                />&nbsp; Add
                                            </p>
                                            <p className='d-flex align-items-center '>
                                                <Input
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="2"
                                                    name="roleUpdate"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleUpdate}
                                                    onChange={handleOptionChangeAdmin}
                                                />&nbsp; Update
                                            </p>

                                            <p className='d-flex align-items-center '>
                                                <Input
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="3"
                                                    name="roleDelete"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleDelete}
                                                    onChange={handleOptionChangeAdmin}
                                                />&nbsp; Delete </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                        <Col md="12" sm="12">
                            <Accordion open={false} toggle={toggleOpen}>
                                <AccordionItem>
                                    <AccordionHeader targetId="2">
                                        <div className='Assign_Check_Section'>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Assign_Check"
                                                onClick={() => { cancelAllSelection('moderator') }}
                                            />
                                            {' '}
                                            <div className='Assign_Sub_Section'>
                                                <h4>Assign as Moderators</h4>
                                                <p>Moderators can approve or remove posts, followers and messages on the group.</p>
                                            </div>
                                        </div>

                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>
                                            <p className='d-flex align-items-center '>
                                                <Input
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="4"
                                                    name="roleEditModerator"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleEditModerator}
                                                    onChange={handleOptionChangeModerator}
                                                />&nbsp; Add
                                            </p>
                                            <p className='d-flex align-items-center '>
                                                <Input
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="5"
                                                    name="roleUpdateModerator"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleUpdateModerator}
                                                    onChange={handleOptionChangeModerator}
                                                />&nbsp; Update
                                            </p>

                                            <p className='d-flex align-items-center '>
                                                <Input
                                                    type="checkbox"
                                                    className='customcheckboxForFilter'
                                                    id="6"
                                                    name="roleDeleteModerator"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleDeleteModerator}
                                                    onChange={handleOptionChangeModerator}
                                                />&nbsp; Delete </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                        <Col md="12" sm="12">
                            <Accordion open={false} toggle={toggleOpen}>
                                <AccordionItem>
                                    <AccordionHeader targetId="3">
                                        <div className='Assign_Check_Section'>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Assign_Check"
                                                onClick={() => { cancelAllSelection('manager') }}
                                            />
                                            {' '}
                                            <div className='Assign_Sub_Section'>
                                                <h4>Assign as Manager</h4>
                                                <p>Manager can publish/unpublish posts and comments on the group.</p>
                                            </div>
                                        </div>
                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>
                                            <p className='d-flex align-items-center '>
                                                <Input type="checkbox" className='customcheckboxForFilter'
                                                    id="7"
                                                    name="roleEditManager"
                                                    style={{ cursor: "pointer" }}
                                                    checked={roleEditManager}
                                                    onChange={handleOptionChangeManager}
                                                />&nbsp; Add </p>

                                            <p className='d-flex align-items-center '>
                                                <Input type="checkbox" className='customcheckboxForFilter'
                                                    id="8"
                                                    style={{ cursor: "pointer" }}
                                                    name="roleUpdateManager"
                                                    checked={roleUpdateManager}
                                                    onChange={handleOptionChangeManager}
                                                />&nbsp; Update  </p>

                                            <p className='d-flex align-items-center '>
                                                <Input type="checkbox" className='customcheckboxForFilter'
                                                    id="9" style={{ cursor: "pointer" }}
                                                    name="roleDeleteManager"
                                                    checked={roleDeleteManager}
                                                    onChange={handleOptionChangeManager}
                                                />&nbsp; Delete </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>

                    </Row>
                </div>
            </Row>

        </Fragment>
    )
    const SuccessfullyData = () => (
        <Fragment>
            <Row>
                <Col md="12" sm="12">
                    <div className='d-flex justify-content-center'>
                        <img src={Success} alt="succes" className='img-fluid' />
                    </div>
                </Col>
                <Col md="12" sm="12" className="py-2">
                    <div className='d-flex justify-content-center Success_Heading_Section'>
                        <h4 align='center'>
                            {
                                resultEvents ? resultEvents : 'Role Assigned Successfully!'
                            }

                        </h4>
                    </div>
                </Col>
            </Row>
            <div className="group-detail-page-blk mt-3">
                <Row>

                    <Col md="12" sm="12">
                        <div className="group-members-block">
                            <div className="group-member-img-cont-blk">
                                <img src={profileImage} alt="img" />
                                <div className="member-cont-blk">
                                    <h4>{fullName ? fullName : ''}</h4>
                                    <p>{followingDate ? followingDate : ''}</p>
                                    <p>{role}</p>
                                </div>
                            </div>
                            <div className='Block-btns-blk'>
                                <Button className="People_Assign_btn"><p>{role}</p></Button>
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col md="12" sm="12">
                        <div className='d-flex justify-content-center'>
                            <Button color='primary' className="Success_Ok_btn" onClick={updateRoleAndClose}><p>Ok</p></Button>
                        </div>
                    </Col>
                </Row>

            </div>
        </Fragment>
    )
    // ================== main_return_function==================//
    return (
        <Fragment>
            <div className="group-page-section section-b-space">

                {
                    getAllGroupMembersData && getAllGroupMembersData?.data?.successResult[0]?.[filterSuperAdmin]?.map((data) => (
                        <>
                            <div className=' group-title'>
                                <Row className="group-detail-page-blk  ">
                                    <CardTitle className="admin_Title_Name">
                                        <h4>Super Admin</h4>
                                    </CardTitle>
                                    <Col md="12" sm="12">
                                        <div className="group-members-block">
                                            <div className='group-member-img-cont-blk profile_super_Member '>
                                                <img alt='' src={getAllGroupAdmins && data?.profileImageThumb === null ? 'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0=' : data.profileImageThumb} />
                                                <div className='member-cont-blk'>
                                                    <h4>{data?.fullName ? data?.fullName : 'No name to show'}</h4>
                                                    <p>{data?.role}</p>
                                                    <p>{data?.bio ? data?.bio : "Super Admin"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ))
                }

                <div className=' group-title'>
                    <Row className="group-detail-page-blk  ">
                        {
                            getAllGroupMembersData && getAllGroupMembersData?.data.successResult[0]?.[filterAdmin]?.map((data) => (
                                <>
                                    <CardTitle className="admin_Title_Name">
                                        <h4>{[filterAdmin] ? 'Admin (' + getAllGroupMembersData?.data?.successResult[0]?.adminCount + ')' : ''}</h4>
                                        {/* <h4>Admins  ({getAllGroupMembersData && getAllGroupMembersData?.data?.successResult[0]?.adminCount})</h4> */}
                                    </CardTitle>
                                    <Col md="6" sm="6">
                                        <div className="group-members-block">
                                            <div className="group-member-img-cont-blk">
                                                <img alt='' src={getAllGroupAdmins && data?.profileImageThumb === null ? 'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0=' : data.profileImageThumb} />
                                                <div className="member-cont-blk">
                                                    <h4>{data?.fullName ? data?.fullName : 'No name to show'}</h4>
                                                    <p>{data?.followingDate?.slice(24)} &nbsp;{moment(data?.startDate).format('MMM Do YYYY')}&nbsp;</p>
                                                    <p className='text-capitalize'>{data?.role}</p>
                                                </div>
                                            </div>
                                            <div className='Admin-btns-blk'>
                                                <Button
                                                    onClick={() => { toggleClose(data?.id, data?.fullName, data?.followingDate, data?.profileImageThumb, data?.role, data?.userId) }}
                                                    color='primary'>Modify</Button>
                                                <Button className="ml-2" color='danger' outline
                                                    onClick={() => { toggleRemove(data?.userId) }}
                                                >Remove</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            ))
                        }
                    </Row>
                </div>

                <div className=' group-title'>
                    <Row className="group-detail-page-blk  ">

                        {
                            getAllGroupMembersData && getAllGroupMembersData?.data?.successResult[0]?.[filterManager]?.map((data) => (
                                <>
                                    <CardTitle className="admin_Title_Name">
                                        <h4>{[filterManager] ? 'Manager (' + getAllGroupMembersData?.data?.successResult[0]?.managerCount + ')' : ''}</h4>
                                    </CardTitle>
                                    <Col md="6" sm="6">
                                        <div className="group-members-block">
                                            <div className="group-member-img-cont-blk">
                                                <img alt='' src={getAllGroupAdmins && data?.profileImageThumb === null ? 'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0=' : data.profileImageThumb} />
                                                <div className="member-cont-blk">
                                                    <h4>{data?.fullName ? data?.fullName : 'No name to show'}</h4>
                                                    <p>{data?.followingDate?.slice(24)} &nbsp;{moment(data?.startDate).format('MMM Do YYYY')}&nbsp;</p>
                                                    <p className='text-capitalize'>{data.role}</p>
                                                </div>
                                            </div>
                                            <div className='Admin-btns-blk'>
                                                <Button
                                                    onClick={() => { toggleClose(data?.id, data?.fullName, data?.followingDate, data?.profileImageThumb, data?.role, data?.userId) }}
                                                    color='primary'>Modify</Button>
                                                <Button className="ml-2" color='danger' outline
                                                    onClick={() => { toggleRemove(data?.userId) }}
                                                >Remove</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </>

                            ))
                        }
                    </Row>
                </div>

                <div className=' group-title'>

                    <Row className="group-detail-page-blk  ">
                        <CardTitle className="admin_Title_Name">
                            <h4> {filterMembers === 'members' ? 'Members (' + getAllGroupMembersData?.data?.successResult[0]?.membersCount + ')' : ''}</h4>
                        </CardTitle>
                        {getAllGroupMembersData?.data?.successResult[0]?.[filterMembers]?.filter(data => data?.fullName?.toLowerCase().includes(searchMember?.toLowerCase()))
                            .map((data, index) => (
                                <Col md="6" sm="6" key={index}>
                                    <div className="group-members-block">
                                        <div className="group-member-img-cont-blk">
                                            <img alt='' src={getAllGroupAdmins && data?.profileImageThumb === null ? 'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?b=1&s=170667a&w=0&k=20&c=-qQGlKM8OQsSJCEkHnqS9FI94VRTkZ-7tg0K0u02XL0=' : data.profileImageThumb} />
                                            <div className="member-cont-blk">
                                                <h4>{data?.fullName ? data?.fullName : 'No name to show'}</h4>
                                                <p>{data?.followingDate?.slice(24)} &nbsp;{moment(data?.startDate).format('MMM Do YYYY')}&nbsp;</p>
                                                <p className='text-capitalize'>{data.role}</p>
                                            </div>
                                        </div>
                                        <div className='Admin-btns-blk'>
                                            <Button onClick={() => { toggleClose(data?.id, data?.fullName, data?.followingDate, data?.profileImageThumb, data?.role, data?.userId) }} color='primary'>Modify</Button>
                                            <Button className="ml-2" color='danger' outline onClick={() => { toggleRemove(data?.userId) }}>Remove</Button>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>

            </div>

            <div className='AssignDataModal'>
                {
                    assignGroup !== false &&
                    <Modal isOpen={assignGroup} toggle={toggleClose} className='modal-dialog-centered' scrollable  >
                        <ModalBody>
                            <AssignGroupData memberId={memberId} fullName={fullName} followingDate={followingDate} profileImage={profileImage} role={role} />
                        </ModalBody>
                        <ModalFooter className='d-flex justify-content-center'>
                            <div>
                                <Button onClick={applyRole} color='primary' className="Admin_Modify_btn"><p>Save</p></Button>
                            </div>
                        </ModalFooter>

                    </Modal>
                }
            </div>
            <div className='SuccessfullyModal'>
                {
                    assignSuccessfully !== false &&
                    <Modal isOpen={assignSuccessfully} toggle={toggleSuccessfully} className='modal-dialog-centered' scrollable  >
                        <ModalBody>
                            <SuccessfullyData />
                        </ModalBody>

                    </Modal>
                }
            </div>
            <div className='d-flex justify-content-center'>
                <Stack>
                    <Pagination count={10} className='PaginationCount'>
                    </Pagination>
                </Stack>
            </div>
        </Fragment>
    )
}

export default MyGroupMember