import React from 'react'
import { AlertTriangle, Circle, MapPin } from 'react-feather'
import { Card, Col, FormText, Input, Row } from 'reactstrap'
import LeftquotationIcon from '../../../../NewComponents/IMG/LeftquotationIcon.svg'
import RightquotationIcon from '../../../../NewComponents/IMG/RightquotationIcon.svg'
import CreatepostFooter from './CreatepostFooter'
import CreatepostHeader from './CreatepostHeader'

const ALert = (props) => {
    return (
        <Card className='p-3'>
            <CreatepostHeader
                actionM={props.NavMedia}
                actionT={props.NavThrought}
                actionG={props.NavGoLive}
                actionE={props.NavEvent}
                actionP={props.NavPodcast}
                actionA={props.NavArticle}
                actionR={props.NavReccom}
                actionAl={props.NavAlert}
                actionPo={props.NavPoll}
                actionS={props.NavSell}
            />
            <div className='d-flex align-items-center my-2 py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' />
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >Sufiya Eliza</h5>
            </div>
            <div>
                <h3 style={{ color: '#F30000' }} className='py-2'>#creatalert</h3>
                <div>
                    <img src={LeftquotationIcon} alt="icon" />
                    <div className='mx-4 text-center'>
                        <Input
                            type="textarea"
                            className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                            placeholder="Define the threat..."
                            cols="15"
                            rows="5"
                        />
                    </div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                    <FormText className='text-muted text-right'>Max 320 Characters</FormText>
                </div>
            </div>
            <Row>
                <Col>
                    <div className='d-flex align-items-center'>
                        <AlertTriangle className='mr-2' size={18} color='#FF822E' />
                        <h4>Alert Level</h4>
                    </div>
                    <div className='my-2'>
                        <Input id='eventcategory' type='select'>
                            <option data-content={<Circle size={4} fill='#FFD600' />}> Moderate</option>
                            <option> <Circle size={4} fill='#FF822E' /> Mild</option>
                            <option> <Circle size={4} fill='#FF1010' /> Severe</option>
                        </Input>
                    </div>
                </Col>
                <Col>
                    <div className='d-flex align-items-center'>
                        <MapPin className='mr-2' size={18} color='#16C31E' />
                        <h4>Post Alert within</h4>
                    </div>
                    <div className='my-2'>
                        <Input id='eventcategory' type='select'>
                            <option> 1 km</option>
                            <option> 2 km</option>
                            <option> 3 km</option>
                        </Input>
                    </div>
                </Col>
            </Row>
            <CreatepostFooter
                FirsticonS={'d-none'}
                Firsticon={'d-none'}
                Secondicon={'d-none'}
                Thirdicon={'d-none'}
                Fourthicon={'d-none'}
                Fifthicon={'d-none'}
                Sixthicon={'d-none'}
            />
        </Card>
    )
}

export default ALert