import React, { useState } from 'react'
import { AlertTriangle, Eye, Link, MoreHorizontal, Music, Pause, Play, Share2 } from 'react-feather'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { listTrendingAudio } from './DummyArray'
import GridAudio from './Grid-Components/GridAudio'
import { Pagination, Stack } from '@mui/material'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ReportModal from './Grid-Components/ModalGrid/ReportModal'
import musicAnimation from '../../NewComponents/Gif/musicAnimation.gif'


const AudioTrendingList = () => {

    // ---------------------------------------State & Function for toggling audio state---------------
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen((prevState) => !prevState)
    }
    // --------------------------------------------State & Function for toggling Report-------------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    // -------------------------------------------State & Function for toggling Share state-----------------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    // ------------------------------------------------State & Function for playing music----------------------------
    const [isPlaying, setIsPlaying] = useState(false)
    const toggleplay = () => {
        setIsPlaying((prevState) => !prevState)
    }
    const [isPlayings, setIsPlayings] = useState(false);

    const togglePlayPause = () => {
        setIsPlayings(!isPlayings);
        // Add  play/pause functionality here
    };

    // --------------------------------------main return function-----------------------------
    return (
        <div>

            {!open ? <Row>
                <Col md='12 mt-2' >

                    {/* -----------------------------------------------------------Dynamic Audio list data conditional rendering--------------------------------------------------------------- */}
                    {listTrendingAudio.map((data, index) => (
                        <div className='border rounded my-2 ml-2  on-hover-bg w-100' key={index}  >
                            <Row className='d-flex align-items-center py-2 justify-content-between w-100'>
                                <Col md='6' >
                                    <div className='d-flex' >
                                        <div className='d-flex align-items-center ml-3'>
                                            {/* <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center rem-mob-view'>
                                                <p className='PilesThistags text-center'>Top</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                            </div> */}
                                            <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                <p className='PilesThistags text-center'>Trending</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between ml-3'>
                                            <div className='d-flex justify-content-start align-items-start '>
                                                <div className='px-3' style={{ position: 'relative' }} >
                                                    <div className='image-with-gradient mt-2'  >
                                                        <img src={data.imageLink} width={70} height={70} className='rounded ArticleShowdo' alt='' />
                                                    </div>
                                                    <div style={{ position: 'absolute', top: '38%', left: '38%' }} onClick={togglePlayPause}>
                                                        {isPlayings ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="#ffff">
                                                                <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="#ffff">
                                                                <path d="M8 5v14l11-7z" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='sporttext' style={{ textWrap: 'nowrap' }}>
                                                    <small className='text-primary'>#{data.category}</small>
                                                    <p className='pr-1 d-block d-md-inline d-sm-inline d-xl-flex audio-img' style={{ textWrap: 'pretty' }}>
                                                    {data.description.slice(0, 60) + '...'}
                                                </p>
                                                    {/* <p className='pr-1 '>Used in <span className='BizPageCategory count' >{data.count}k</span> shotz</p> */}
                                                    <small className='pr-1 text-muted light-for-date' style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{data.time}hr ago</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col md='4' className='d-none d-md-block'>
                                    <div className='d-flex align-items-center justify-content-around d-none d-md-none d-sm-none d-xl-flex'>
                                        <div className={isPlaying ? '' : 'd-block invisible'}>
                                            <img src={musicAnimation} alt='...' width={25} className='' />
                                        </div>
                                        <Button color='primary ArticleShowdos' style={{ width: '100px' }} className='rem-mob-view' onClick={toggleplay} >{!isPlaying ? <><Play fill='#FFFFFF' size={15} /> Play</> : <><Pause fill='#FFFFFF' size={15} /> Pause</>}</Button>
                                    </div>
                                </Col>
                                <Col md='2' className=' d-flex  justify-content-end align-items-center' >
                                    <div className='d-flex flex-column align-items-center rem-mob-view  d-none d-md-block d-sm-none d-xl-flex d-xxl-block podcastlists' style={{ cursor: 'pointer' }} onClick={toggleOpen}>
                                        <p className='pr-1 user-profile ArticleShowdos usedtextin'>Used in <span className='BizPageCategory count'>{data.count}k</span> shotz</p>
                                    </div>
                                    <div className='d-flex ml-5'>
                                        <UncontrolledButtonDropdown className='align-items-center d-flex'>
                                            <DropdownToggle color='flat m-0 p-0 text-center ml-3 d-none d-md-block'>
                                                <MoreHorizontal className='text-muted textpodcasts' />
                                            </DropdownToggle>

                                            <DropdownMenu className='bizpagefordropdownmenu dropdownLocation dropdown-for-user-specific'>
                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}>
                                                    <Share2 size={13} />&nbsp;&nbsp;Share audio
                                                </DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem'>
                                                    <NavLink to='/MyProfile' style={{ color: '#212529' }}>
                                                        <Eye size={13} />&nbsp;&nbsp;View Profile
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem'>
                                                    <Link size={13} />&nbsp;&nbsp;Copy link
                                                </DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' onClick={toggleReport}>
                                                    <AlertTriangle size={13} />&nbsp;&nbsp;Report
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    <p>Total Audios Count : <span className='text-primary' >{listTrendingAudio.length}</span></p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex p-2 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>

            </Row> : <GridAudio toggleOpen={toggleOpen} />}



            {/* -----------------------------------------------------------Modal Part starts here--------------------------------------------------------------- */}

            {/* -----------------------------------------------------------Share Modal--------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            {/* -----------------------------------------------------------Report Modal--------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------------------Modal Part ends here--------------------------------------------------------------- */}

        </div>

    )
}

export default AudioTrendingList