// import  { Fragment, useState } from 'react'

// import { Link } from 'react-router-dom'



// import Header from '../../Header'
// import LeftSidebar from '../../LeftSidebar'
// import TopBanner from '../TopBannerSection/TopBanner'
// import BizDetailMenu from '../BizDetailMenu'
// import RightSidebar from '../../RightSidebar'
// import CataloguesTab from './CataloguesTab'
// import CreateCatalogue from '../AllTypesModal/CreateCatalogue'
// import DeletedModal from '../AllTypesModal/DeletedModal'
// import DownloadModal from '../AllTypesModal/DownloadModal'




// const CataloguesPhoto = () => {
// const [catalogueOpen, setCatalogueOpen] = useState(false)
// const toggleCatalogue = () => {
//     setCatalogueOpen((preState) => !preState)
// }

// const [print, setPrint] = useState(false)
// const [imgfile, uploadimg] = useState('')
// const imgFilehandeler = (e) => {
//     if (e.target.files.length !== 0) {
//         uploadimg(URL.createObjectURL(e.target.files[0]))
//     }
//     setPrint(true)
// }

// const [deleteOpen, setDeleteOpen] = useState(false)
//     const toggleDelete = () => {
//         setDeleteOpen((preState) => !preState)
//     }

//     const [download, setDownload] = useState(false)
//     const toggleDownload = () => {
//         setDownload((preState) => !preState)
//     }
// const [cataLogueActive, setCataLogueActive] = useState(false)

//     //======================= Main_Return_function ========================//
//     return (
//         <Fragment>
//             <Header />
//             <div className="page-body container-fluid profile-page">
//                 <LeftSidebar />
//                 <div className="page-center">
//                     <TopBanner />
//                     <BizDetailMenu />
//                     <div className="container-fluid section-t-space px-0">
//                         <div className="content-center w-100">
//                             <div className="gallery-page-section section-b-space">
//                                 <div className="Aboutcard-title ">
//                                     <h4>Catalogues and Photo</h4>
//                                     <div className="right-setting">
//                                         <Link to={"#"} className="btn btn-outline-green ms-3">Add photo/video</Link>
//                                     </div>
//                                 </div>
//                                 {/* <TabFunction /> */}
//                                 <div className='p-3'>
//                                     <CataloguesTab />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <CreateCatalogue
//                         isOpen={catalogueOpen}
//                         toggleOpen={toggleCatalogue}
//                         changeHandle={imgFilehandeler}
//                         srcUrl={imgfile}
//                         InActiveSate={print}
//                         UpdateState={setPrint}
//                         admoreActive={cataLogueActive}
//                         admoreUpdateActive={setCataLogueActive}
//                     />
//                     <DeletedModal
//                         isOpen={deleteOpen}
//                         toggleOpen={toggleDelete}
//                     />
//                     <DownloadModal
//                         isOpen={download}
//                         toggleOpen={toggleDownload}
//                     />
//                 </div>
//                 <RightSidebar />
//             </div>
//         </Fragment>
//     )
// }

// export default CataloguesPhoto
import React from 'react'
import axios from 'axios';
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
// import Models from '../Models'
import TopBanner from '../TopBannerSection/TopBanner'
import BizDetailMenu from '../BizDetailMenu'
import RightSidebar from '../../RightSidebar'
import { Button, Card, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { Fragment, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import BizImg1 from '../Img/BizImg1.png'
import BizImg2 from '../Img/BizImg2.png'
import BizImg3 from '../Img/BizImg3.png'
import BizLike from '../Img/BLike.svg'
import { BellOff, CornerUpLeft, Download, Edit2, Eye, Image, MessageCircle, MoreVertical, Plus, PlusCircle, Send, Star, Trash2, UploadCloud, XCircle, X } from 'react-feather'
import { Pagination, Stack } from '@mui/material'
import '../biz.css'
import MpImageUploadSection from './catUpload';






const BizPhotos = () => {
    const [selectActive, setSelectActive] = useState(true)
    const [active, setActive] = useState('1')

    const toggle = tab => {
        setSelectActive(!selectActive)
        if (active !== tab) {
            setSelectActive(tab === '1');  // Set selectActive to true only when tab is '1'
            setActive(tab);
        }
    }
    const [catalogueOpen, setCatalogueOpen] = useState(false)
    const toggleCatalogue = () => {
        setCatalogueOpen((preState) => !preState)
    }

    const handleFileChange = (e) => {
        // Handle file change here
        console.log('File selected:', e.target.files[0]);
    };


    const CatalogueMediaData = [
        {
            img: BizImg1,
            count: "581",
            totalPic: "20",
            tittle: " Car Bazar "
        },
        {
            img: BizImg2,
            count: "581",
            totalPic: "20",
            tittle: "Food Plaza"
        },
        {
            img: BizImg3,
            count: "581",
            totalPic: "20",
            tittle: "  Media "
        },
        // {
        //     img: BizImg2,
        //     count: "581",
        //     totalPic: "20",
        //     tittle: "Foods"
        // },
        // {
        //     img: BizImg3,
        //     count: "581",
        //     totalPic: "20",
        //     tittle: "Natural Beauty"
        // }

    ]
    const CatalogueMediaData1 = [
        {
            img: BizImg1,
            count: "581",
            totalPic: "20",
            tittle: " Car Bazar "
        },
        {
            img: BizImg2,
            count: "581",
            totalPic: "20",
            tittle: "Food Plaza"
        },
        {
            img: BizImg3,
            count: "581",
            totalPic: "20",
            tittle: "  Media "
        },
        {
            img: BizImg2,
            count: "581",
            totalPic: "20",
            tittle: "Foods"
        },
        {
            img: BizImg3,
            count: "581",
            totalPic: "20",
            tittle: "Natural Beauty"
        }

    ]

    const CataloguesTabsection = () => (
        <Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1');
                            console.log("hit tab for toogle")
                        }}
                    >
                        All Catalogues (53)
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Car Bazar  (12)
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Food Plaza (09)
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '4'}
                        onClick={() => {
                            toggle('4')
                        }}
                    >
                        Media (32)
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={active} className="py-4">
                <TabPane tabId='1'>
                    <AllCatalogue

                    />

                </TabPane>
                <TabPane tabId='2'>
                    <AllCatalogue
                        tittle="Car Bazar (12)"
                    />
                </TabPane>
                <TabPane tabId='3'>
                    <AllCatalogue
                        tittle="Food Plaza (09)"
                    />

                </TabPane>
                <TabPane tabId='4'>
                    <AllCatalogue
                        tittle="Media (32)"
                    />

                </TabPane>
            </TabContent>

        </Fragment>
    )

    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = (data) => {
        setModalData(data);
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const [deleteReviewOpen, setDeleteReviewOpen] = useState(false)
    const toggleDelete = () => {
        setDeleteReviewOpen((preState) => !preState)
    }
    const [deleteReviewOpen3, setDeleteReviewOpen3] = useState(false)
    const toggleDelete3 = () => {
        setDeleteReviewOpen3((preState) => !preState)
    }
    const [deleteReviewOpen4, setDeleteReviewOpen4] = useState(false)
    const toggleDelete4 = () => {
        setDeleteReviewOpen4((preState) => !preState)
    }
    const [deleteReviewOpen1, setDeleteReviewOpen1] = useState(false)
    const toggleDelete1 = () => {
        setDeleteReviewOpen1((preState) => !preState)
    }
    const [deleteReviewOpen2, setDeleteReviewOpen2] = useState(false)
    const toggleDelete2 = () => {
        setDeleteReviewOpen2((preState) => !preState)
    }

    // --------------------------_start_Catalogue_section----------------------//
    const AllCatalogue = (props) => (


        <Fragment>
            {selectActive ? (
                <Row>
                    <Col lg={3} sm={6} className="mb-4">
                        <Card className="Biz_Catalogue_Section" style={{ cursor: "pointer" }} onClick={toggleCatalogue}>
                            <div className='text-center'>
                                <PlusCircle color='#81C14B' size={24} />
                                <h3 className='py-2'>Create Catalogue</h3>
                                <h4 className='py-2'>Create Catalogue in just few<br /> minutes</h4>
                            </div>
                        </Card>
                    </Col>
                    {
                        CatalogueMediaData.map((data, index) => (
                            <Col lg={3} sm={6} className="mb-4" key={index}>
                                <div className="Biz_catalogue_media_section" >
                                    <img className="Biz_catalogue_img img-fluid1" src={data.img} alt="" style={{ cursor: "pointer" }} onClick={() => {
                                        // Call the toggle function with the corresponding tab ID
                                        toggle((index + 2).toString());
                                        // Optionally, you can scroll to the tab content

                                    }} />
                                    <div>
                                        <div className='Catalogue_Top_section'>
                                            <MoreVertical size={24} color="#FFFFFF" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Biz_Drop_Option bizphotodroup ">
                                                <ul>
                                                    <li>
                                                        <Link to="#"><BellOff size={16} />Turn off Notification</Link>
                                                    </li>
                                                    <li onClick={toggleDelete2}>
                                                        <Link to="#"><Download size={16} />Download Catalogue  </Link>
                                                    </li>
                                                    <li onClick={toggleDelete1}>
                                                        <Link to="#"><Edit2 size={16} />Edit Catalogue </Link>
                                                    </li>
                                                    <li onClick={toggleDelete} >
                                                        <Link to="#" ><Trash2 size={16} />Delete Catalogue </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='Catalogue_bottom_section rounded-bottom'>
                                            <div className='d-flex justify-content-between '>
                                                <div className='p-2 d-flex align-items-center'>
                                                    <Eye size={18} color="#FFFFFF" />&nbsp;<span> {data.count}</span>
                                                </div>
                                                <div className='p-2 d-flex align-items-center ' style={{ cursor: "pointer" }} >
                                                    <Image size={18} color="#FFFFFF" />&nbsp;<span > {data.totalPic} Photos </span>
                                                </div>
                                            </div>
                                            <div className='pt-2 p-2'>
                                                <span>{data.tittle}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        ))
                    }

                </Row>
            ) : (
                <>
                    <div className='Catalogues_heading_section'>
                        <h3>{props.tittle}</h3>
                        <div className='catalogbutton addpc' style={{ cursor: "pointer" }}>
                            {/* <Button onClick={() => setSelectActive(!selectActive)} >
                                <CornerUpLeft size={14} />
                                <span className='align-middle ms-25'>Back</span>
                            </Button> */}
                            <div color="primary" outline className="">
                                <label htmlFor="fileInput" className="">
                                    <span className='align-middle ms-25' style={{ position: "relative", top: "3px" }} >+ Add Photos </span>
                                </label>
                                <input
                                    id="fileInput"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>

                    </div>

                    <Row className="mt-3">
                        {
                            CatalogueMediaData1.map((data) => (
                                <Col lg={3} md={4} sm={6} className="mb-4">
                                    <div className="Biz_catalogue_Allmedia_section">
                                        <img className="Biz_catalogue_img img-fluid1" src={data.img} alt="" onClick={() => toggleModal(data)} />
                                        <div>
                                            <div className='Catalogue_Top_section'>
                                                <MoreVertical size={24} color="#FFFFFF" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Biz_Drop_Option  bizphotodroup">
                                                    <ul>
                                                        <li>
                                                            <Link to="#"><BellOff size={16} />Turn off Notification</Link>
                                                        </li>
                                                        <li onClick={toggleDelete3}>
                                                            <Link to="#"><Download size={16} />Download</Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="#"><Edit2 size={16} />Edit </Link>
                                                        </li> */}
                                                        <li onClick={toggleDelete4}>
                                                            <Link to="#"><Trash2 size={16} />Delete </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='Catalogue_LeftTop_section'>
                                                <Eye size={18} color="#FFFFFF" />&nbsp;<span> {data.count}</span>
                                            </div>
                                            <div className='Catalogue_buttom_Count rounded-bottom'>
                                                <div className='d-flex align-items-center '>
                                                    <div className='Biz_Icon_btn rounded-circle'>
                                                        <img src={BizLike} width={10} />
                                                    </div>
                                                    &nbsp;<span>107</span>
                                                </div>
                                                <div className='d-flex align-items-center '>
                                                    <div className='Biz_Icon_btn rounded-circle'>
                                                        <MessageCircle color='#FFFFFF' size={16} />
                                                    </div>
                                                    &nbsp;<span>103</span>
                                                </div>
                                                <div className='d-flex align-items-center '>
                                                    <div className='Biz_Icon_btn rounded-circle'>
                                                        <Send color='#FFFFFF' size={16} />
                                                    </div>
                                                    &nbsp;<span>87</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            ))
                        }

                    </Row>
                    <Modal isOpen={isModalOpen} toggle={handleCloseModal} className='modal-dialog-centered'>



                        <div onClick={handleCloseModal} className="Modal_False_blk1">
                            <XCircle color="#FFFFFF" />
                        </div>


                        <div>
                            <img src={modalData?.img} alt="" style={{ width: '100%' }} />
                        </div>



                    </Modal>


                </>
            )}
            <div className='Review_Deleted_modal'>
                {
                    deleteReviewOpen !== false &&
                    <Modal isOpen={deleteReviewOpen} toggle={toggleDelete} className='modal-dialog-centered'>
                        <ModalHeader className="d-flex justify-content-center">
                            <h2>Are You Sure ?</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center Remove_Modal_section'>
                                <p>Are you sure that you want to Delete your <br />Catalogue</p>
                            </div>
                            <div className='mt-4 text-center'>
                                <Button onClick={toggleDelete} outline>Cancel</Button>
                                <Button onClick={toggleDelete} color="primary" className="ml-2" >Confirm</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
            <div className='Review_Deleted_modal'>
                {
                    deleteReviewOpen3 !== false &&
                    <Modal isOpen={deleteReviewOpen3} toggle={toggleDelete3} className='modal-dialog-centered'>
                        <ModalHeader className="d-flex justify-content-center">
                            <h2>Are You Sure ?</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center Remove_Modal_section'>
                                <p>Are you sure that you want to edit your <br />Photo</p>
                            </div>
                            <div className='mt-4 text-center'>
                                <Button onClick={toggleDelete3} outline>Cancel</Button>
                                <Button onClick={toggleDelete3} color="primary" className="ml-2" >Confirm</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
            <div className='Review_Deleted_modal'>
                {
                    deleteReviewOpen4 !== false &&
                    <Modal isOpen={deleteReviewOpen4} toggle={toggleDelete4} className='modal-dialog-centered'>
                        <ModalHeader className="d-flex justify-content-center">
                            <h2>Are You Sure ?</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center Remove_Modal_section'>
                                <p>Are you sure that you want to Delete your <br />Photo</p>
                            </div>
                            <div className='mt-4 text-center'>
                                <Button onClick={toggleDelete4} outline>Cancel</Button>
                                <Button onClick={toggleDelete4} color="primary" className="ml-2" >Confirm</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
            <div className='Review_Deleted_modal'>
                {
                    deleteReviewOpen1 !== false &&
                    <Modal isOpen={deleteReviewOpen1} toggle={toggleDelete1} className='modal-dialog-centered'>

                        <ModalBody>
                            <div className='text-center Remove_Modal_section'>
                                <p>Are you sure that you want to Download your <br />Catalogue</p>
                            </div>
                            <div className='mt-4 text-center'>
                                <Button onClick={toggleDelete1} outline>Cancel</Button>
                                <Button onClick={toggleDelete1} color="primary" className="ml-2" >Confirm</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
            <div className='Review_Deleted_modal'>
                {
                    deleteReviewOpen2 !== false &&
                    <Modal isOpen={deleteReviewOpen2} toggle={toggleDelete2} className='modal-dialog-centered'>

                        <ModalBody>
                            <div className='text-center Remove_Modal_section'>
                                <p>Are you sure that you want to download your <br />Catalogue</p>
                            </div>
                            <div className='mt-4 text-center'>
                                <Button onClick={toggleDelete2} outline>Cancel</Button>
                                <Button onClick={toggleDelete2} color="primary" className="ml-2" >Confirm</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
            <div className='d-flex justify-content-center'>
                <Stack>
                    <Pagination count={5} className='PaginationCount'>
                    </Pagination>
                </Stack>
            </div>
        </Fragment>
    )
    // --------------------------_end_Catalogue_section----------------------//

    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageSelect = (event) => {
        const fileName = event.target.files[0];
        if (fileName) {
            const url = URL.createObjectURL(fileName);
            setSelectedImages((prevImages) => [...prevImages, url]);
        }
    };

    const handleRemoveImage = (index) => {
        setSelectedImages((prevImages) =>
            prevImages.filter((image, i) => i !== index)
        );
    };
    const handleCancel1 = () => {
        setImageLogo1(null); // Clear the image when XCircle is clicked
    };
    const [imageLogo1, setImageLogo1] = useState("")
    const [catalogueName, setCatalogueName] = useState("");
    const fileHandler = async (e) => {
        // e.preventDefault()
        let file = e.target.files[0]
        let groupImage = 'groupImage'
        const form = new FormData()
        form.append('file', file)
        form.append('uploadFor', groupImage)
        try {
            let user = JSON.parse(localStorage.getItem('sociomeeUser'));
            const cofig = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user?.token}`
                }
            }
            const { data } = await axios.post(`${process.env.REACT_APP_IPURL}/admin/uploadFile`,
                form, cofig
            )
            console.log("this is the formddata:::", form);
            // console.log("this is hte confiq:::", config);
            console.log('this is the response==================================', data)

            setImageLogo1(data.data.successResult[0])
            setCatalogueName("");
        } catch (error) {
            console.log('ImageUpload Error', error)
        }
    }

    const [additionalImages, setAdditionalImages] = useState([]);
    const fileInputRef = useRef(null);
    const fileHandler1 = (e) => {
        // Handle the file upload for the initial image
        const file = e.target.files[0];
        setImageLogo1(file);
    };
    const handleAddMoreProduct = () => {
        // Trigger the file input for adding more products
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleCancelAdditionalImage = (index) => {
        const updatedImages = [...additionalImages];
        updatedImages.splice(index, 1);
        setAdditionalImages(updatedImages);
    };
    const handleFileSelection = (e) => {
        // Handle the file selection for additional images
        const files = Array.from(e.target.files);
        setAdditionalImages([...additionalImages, ...files]);
    };


    // main_return_function
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">

                    <div className="bizdetailprofile">
                        <TopBanner />
                        <BizDetailMenu />
                    </div>

                    <div className="container-fluid section-t-space px-0">
                        <div className="content-center w-100">
                            <div className="gallery-page-section section-b-space bizdetailprofile">
                                <div className="card-title newcat catnone">
                                    <h3>Catalogues and Photo</h3>
                                    {/* <div className="right-setting">
                                        <Link to={"#"} className="btn btn-outline-green ms-3">Add photo/video</Link>
                                    </div> */}

                                    <div onClick={toggleCatalogue}>
                                        <h5 className='newcatlog'>+ Creat new Catalogues</h5>
                                    </div>
                                </div>
                                {/* <TabFunction /> */}
                                <div className='p-3'>
                                    <CataloguesTabsection />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Catalogues_Modal'>
                        {
                            catalogueOpen !== false &&
                            <Modal isOpen={catalogueOpen} toggle={toggleCatalogue} className='modal-dialog-centered'>

                                <div

                                    onClick={toggleCatalogue}
                                    className="Modal_False_blk1"
                                >
                                    <XCircle color="#FFFFFF" />
                                </div>

                                <ModalHeader className="Biz_modal_heading">
                                    <h3>Create new Catalogues</h3>
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label className="biz_label">
                                            Catalogues Name
                                        </Label>
                                        <Input
                                            id="exampleEmail"
                                            name="catalogue"
                                            placeholder="Enter your catalogue name here..."
                                            type="catalogue"
                                            maxLength={30}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="biz_label">
                                            Add your Product
                                        </Label>





                                        <div className="profile-cover profile-cover-new upload2">
                                            {imageLogo1 ? (
                                                <div style={{ position: 'relative' }}>

                                                    <img
                                                        alt='bPage'
                                                        style={{ width: '100%', borderRadius: "6px" }}
                                                        src={`${process.env.REACT_APP_IMAGEURL}${imageLogo1}`}
                                                    />
                                                    <XCircle
                                                        color="white"
                                                        onClick={handleCancel1}
                                                        className='xcircle'// Call handleCancel when XCircle is clicked
                                                        style={{ top: '6px' }}
                                                    />
                                                    &nbsp;
                                                    &nbsp;


                                                    {additionalImages.map((image, index) => (

                                                        <div key={index} style={{ position: 'relative' }}>
                                                            <img
                                                                alt={`Additional Image ${index}`}
                                                                style={{ width: '100%', borderRadius: '6px', marginBottom: "1.5rem" }}
                                                                src={URL.createObjectURL(image)}
                                                            />
                                                            <XCircle
                                                                color="white"
                                                                onClick={() => handleCancelAdditionalImage(index)}
                                                                className='xcircle'
                                                                style={{ top: '6px', position: 'absolute', right: '6px' }}
                                                            />
                                                        </div>


                                                    ))}
                                                    <div className='d-flex justify-content-end'>
                                                        <p className='' style={{ color: "#81C14B" }} onClick={handleAddMoreProduct}>
                                                            + Add more Product
                                                        </p>
                                                    </div>
                                                    &nbsp;
                                                    &nbsp;

                                                </div>



                                            ) : (
                                                <a href="#" className="btn BizCreationbtn-solidUpload btn-group btn-grp-new">
                                                    <ul>
                                                        <li className="choose-file">
                                                            <a className='UploadBtn' href="">
                                                                <UploadCloud size={14} />&nbsp;
                                                                Upload
                                                            </a>
                                                            <input type="file" onChange={fileHandler} />
                                                        </li>
                                                    </ul>
                                                </a>
                                            )}


                                            <input
                                                type="file"
                                                onChange={handleFileSelection}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                multiple
                                            />

                                        </div>
                                        <div className="profile-cover profile-cover-new upload1" >
                                            <MpImageUploadSection />
                                        </div>
                                    </FormGroup>


                                    {/* <div className='upload1' >
                                        <bizMobileCatlogUpload/>
                                        </div> */}
                                    <div className="text-center py-2">
                                        <Button onClick={toggleCatalogue} className="" outline>Cancel</Button>
                                        <Button onClick={toggleCatalogue} color="primary " className=" ml-2"> &nbsp;Save &nbsp;</Button>
                                    </div>

                                </ModalBody>
                            </Modal>
                        }

                    </div>


                </div>
                <RightSidebar />
            </div>
            {/* <Models /> */}

        </>
    )
}

export default BizPhotos