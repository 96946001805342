import React, { useState } from "react";
import { ChevronLeft, ChevronRight, Maximize2, Minimize2, MoreVertical } from 'react-feather'
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import PreviewMoreSection from "../AdPreview/Sections/PreviewMoreSection";
import Demographic from './Sections/Demographic'
import Platform from './Sections/Platform'
import { useNavigate } from "react-router-dom";
import AdOverview from "./Sections/AdOverview";
import AdOverviewChart from "./AdOverviewChart";
import SingleImagePreview from "../AdPreview/SingleImagePreview";
import AdAnalysisTable from "./AdAnalysisTable";

const Performance = () => {
    const [chartToggle, setChartToggle] = useState(false);
    const [budegetAndTimeModal, setbudegetAndTimeModal] = useState(false);
    const [showTable, HideTable] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const ToggleForbudegetAndTimeModal = () => {
        setbudegetAndTimeModal(!budegetAndTimeModal)
    }

    const ToggleForTable = () => {
        setbudegetAndTimeModal(false)
        HideTable(!showTable)
    }


    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const BudegetAndTimeModal = () => {
        return (
            <Modal isOpen={budegetAndTimeModal} toggle={() => setbudegetAndTimeModal(!budegetAndTimeModal)} className='modal-dialog-centered'>
                <ModalHeader className='border-0' toggle={() => setbudegetAndTimeModal(!budegetAndTimeModal)}>Budget and Time</ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>Daily Budget</h4>
                        <h4><b>$50</b></h4>
                    </div>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>Overall Budget</h4>
                        <h4>$ 500</h4>
                    </div>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>Start Date</h4>
                        <h4>23-Mar-22</h4>
                    </div>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>End Date</h4>
                        <h4>23-Mar-22</h4>
                    </div>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>Duration</h4>
                        <h4>90 days</h4>
                    </div>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>Freezed Amount</h4>
                        <h4>90 days</h4>
                    </div>
                    <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                        <h4>Spent Amount</h4>
                        <h4>90 days</h4>
                    </div>
                </ModalBody>

            </Modal>
        )
    }

    const CardData = [
        {
            cardhading: 'Budget and Time',
            firstrowlabel: 'Daily Budget',
            firstrowdata: '$50',
            Secondrowlabel: 'Overall Budget',
            Secondrowdata: '$ 500',
            Thirdrowlabel: 'Start Date',
            Thirdrowdata: '23-Mar-22',
            Fourthrowlabel: 'End Date',
            Fourthrowdata: '23-Mar-22',
            Fifthrowlabel: 'Duration',
            Fifthrowdata: '90 days',
            nav: ToggleForbudegetAndTimeModal
        },
        {
            cardhading: 'Analytics',
            firstrowlabel: 'Total Reach',
            firstrowdata: '889',
            Secondrowlabel: 'Total Impression',
            Secondrowdata: '889',
            Thirdrowlabel: 'Total Lead',
            Thirdrowdata: '2822',
            Fourthrowlabel: 'Total Click',
            Fourthrowdata: '24',
            Fifthrowlabel: 'Total View',
            Fifthrowdata: '05',
            nav: ToggleForTable
        },
        {
            cardhading: 'User Engagement',
            firstrowlabel: 'Total Likes',
            firstrowdata: '$ 500',
            Secondrowlabel: 'Total Comments',
            Secondrowdata: '$ 500',
            Thirdrowlabel: 'Total Shares',
            Thirdrowdata: '23-Mar-22',
            Fourthrowlabel: 'Total Bookmarks',
            Fourthrowdata: '23-Mar-22',
            Fifthrowlabel: 'Duration',
            Fifthrowdata: '90 days',
            nav: ToggleForTable
        }
    ]

    return (
        <div className="background mt-2">
            <BudegetAndTimeModal />
            <h2 className="text-white py-4">Nerolac Paints - New Home New Colors</h2>
            <Row className='rowForrevert'>
                {
                    CardData.map((item) => {
                        return (
                            <Col xl='4' className='mb-2 AnalyticsForAnime'>
                                <Card style={{ borderRadius: '12px' }} className='h-100 shadow'>
                                    <div>
                                        <div className="d-flex justify-content-between m-3 fontForSubCardheading">
                                            <h3>{item.cardhading}</h3>
                                        </div>
                                    </div>
                                    <CardBody className='mt-2'>
                                        <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                                            <h4>{item.firstrowlabel}</h4>
                                            <h4><b>{item.firstrowdata}</b></h4>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                                            <h4>{item.Secondrowlabel}</h4>
                                            <h4>{item.Secondrowdata}</h4>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                                            <h4>{item.Thirdrowlabel}</h4>
                                            <h4>{item.Thirdrowdata}</h4>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                                            <h4>{item.Fourthrowlabel}</h4>
                                            <h4>{item.Fourthrowdata}</h4>
                                        </div>
                                        <div className="d-flex justify-content-between mt-3 fontForSubCardheading">
                                            <h4>{item.Fifthrowlabel}</h4>
                                            <h4>{item.Fifthrowdata}</h4>
                                        </div>
                                        <div className=" text-muted text-center mt-4">
                                            <h3 style={{ cursor: 'pointer' }} onClick={item.nav}><u>{showTable ? 'Hide Details' : 'View Details'}</u></h3>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }

            </Row>
            {showTable ? <Row className='rowForrevert'>
                <Col>
                    <AdAnalysisTable />
                </Col>
            </Row> : null}
            <Row className='mb-2 rowForrevert'>
                <Col sm='12' >
                    {/* <AdOverview
                        warningColorShade={'#7BD77F'}
                        lineChartDanger={'#DCA594'}
                        lineChartPrimary={'#B28CD0'}
                        tooltipShadow={'rgba(0, 0, 0, 0.25)'}
                        gridLineColor={'rgba(200, 200, 200, 0.2)'}
                    /> */}
                    <AdOverviewChart />
                </Col>
            </Row>
            <Card>
                {/* <ReviewChart /> */}
                <div className="panel shadow-sm">
                    <div className="container-fluid">
                        {/* chart tab */}
                        {chartToggle ? <Platform setChartToggle={setChartToggle} /> :
                            <Demographic setChartToggle={setChartToggle} />}
                    </div>
                </div>
            </Card>
            <Card className="panel">
                <div className="container ">
                    {/* <div className="row border-bottom">
                        <div className="col-12 py-2 col-lg-12">
                            <div className="row">
                                <div className="col-10 col-lg-10 p-0 d-flex align-items-center">
                                    <h3>
                                        Ad preview and comments
                                    </h3>
                                </div>
                                <div className="col-2 col-lg-2 p-0">
                                    <select className="form-control">
                                        <option>Module</option>
                                        <option>Monthly</option>
                                        <option>Yearly</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="row">
                                <div className="col-6 p-0 d-flex align-items-center">
                                    <h4>1 of 1 Ad</h4>
                                </div>
                                <div className="col-6 p-0">
                                    <div className="d-flex justify-content-between">
                                        <button className="next tab-button-active p-2">
                                            <ChevronLeft />
                                        </button>
                                        <button className="next tab-button-active p-2">
                                            <ChevronRight />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="row pt-3 pb-3 border-bottom">
                        <div className="col-6 col-lg-10">
                            <div className="row">
                                <div className="col-6 col-lg-4 p-0 d-flex align-items-center">
                                    <select className="form-control w-50">
                                        <option>Mobile News Feed</option>
                                        <option>Monthly</option>
                                        <option>Yearly</option>
                                    </select>
                                </div>
                                <div className="col-6 col-lg-3 p-0"></div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="row">
                                <div className="col-6 p-0 d-flex align-items-center">
                                    <h4>1 of 23 </h4>
                                </div>
                                <div className="col-6 p-0">
                                    <div className="d-flex justify-content-between">
                                        <button className="next tab-button-active p-2">
                                            <ChevronLeft />
                                        </button>
                                        <button className="next tab-button-active p-2">
                                            <ChevronRight />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <Row>
                        <Col xl='12'>
                            <div className="row pt-3 pb-3">
                                <div className="col-6">
                                    <h3>This is an example</h3>
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <button className="tab-button-active" onClick={toggle} >
                                        {!isOpen ? <Minimize2 /> : <Maximize2 />}
                                        <h5>Advance Preview</h5>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xl='12' className='p-0'>
                            <SingleImagePreview />
                        </Col>
                        {/* <Col xl={isOpen ? '6' : '12'}>
                            <div className="row pt-3 pb-3">
                                <div className="col-12 col-lg-8 d-lg-flex justify-content-end">
                                    <div className={isOpen ? 'post-panel section-t-space w-100' : 'post-panel section-t-space w-50 '}>
                                        <div className="post-wrapper col-grid-box section-b-space card">
                                            <div className="post-title">
                                                <div className="profile">
                                                    <div className="media">
                                                        <a
                                                            className="popover-cls user-img"
                                                            data-bs-toggle="popover"
                                                            data-placement="right"
                                                            data-name="sufiya eliza"
                                                            data-img="assets/images/my-profile.jpg"
                                                        >
                                                            <img
                                                                src="/assets/images/my-profile.jpg"
                                                                className="img-fluid bg-img"
                                                                alt="user"
                                                            />
                                                        </a>
                                                        <div className="media-body">
                                                            <h5>sufiya eliza</h5>
                                                            <h6>Sponsored</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="setting-btn ms-auto setting-dropdown no-bg">
                                                    <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                                        <div
                                                            role="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                className="icon icon-font-color iw-14"
                                                            >
                                                                <circle cx="12" cy="12" r="1"></circle>
                                                                <circle cx="19" cy="12" r="1"></circle>
                                                                <circle cx="5" cy="12" r="1"></circle>
                                                            </svg>
                                                        </div>
                                                        <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                                            <ul>
                                                                <li>
                                                                    <a href="">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            className="icon-font-light iw-16 ih-16"
                                                                        >
                                                                            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                                                        </svg>
                                                                        save post
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            className="icon-font-light iw-16 ih-16"
                                                                        >
                                                                            <rect
                                                                                x="3"
                                                                                y="3"
                                                                                width="18"
                                                                                height="18"
                                                                                rx="2"
                                                                                ry="2"
                                                                            ></rect>
                                                                            <line
                                                                                x1="9"
                                                                                y1="9"
                                                                                x2="15"
                                                                                y2="15"
                                                                            ></line>
                                                                            <line
                                                                                x1="15"
                                                                                y1="9"
                                                                                x2="9"
                                                                                y2="15"
                                                                            ></line>
                                                                        </svg>
                                                                        hide post
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            className="icon-font-light iw-16 ih-16"
                                                                        >
                                                                            <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                                                                            <line
                                                                                x1="15"
                                                                                y1="9"
                                                                                x2="9"
                                                                                y2="15"
                                                                            ></line>
                                                                            <line
                                                                                x1="9"
                                                                                y1="9"
                                                                                x2="15"
                                                                                y2="15"
                                                                            ></line>
                                                                        </svg>
                                                                        unfollow sufiya
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-details">
                                                <div className="sponsor-slides-blk">
                                                    <img src="/assets/images/group-img-8.jpg" />
                                                    <div className="sponsor-cont-btn-blk">
                                                        <div className="sponsor-cont-blk">
                                                            <h4>Build your personal blog post</h4>
                                                            <p>From on Sociomee</p>
                                                        </div>
                                                        <div className="sponsor-btn-blk">
                                                            <a href="#" className="btn btn-primary">
                                                                Get Quote
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="detail-box">
                                                    <h5 className="tag">
                                                        <span>#birthday</span> <span>#celebration</span>
                                                    </h5>
                                                    <h5 className="tag">
                                                        Happy brirthday <span>@DwyaneJhonson</span>
                                                    </h5>
                                                    <p>
                                                        Hi this is my cool dad. Today was his birthday and
                                                        I gifted him this amazing mobile and headphone...
                                                        <a href="#">more</a>
                                                    </p>
                                                    <div className="bookmark favourite-btn">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            className="iw-14 ih-14"
                                                        >
                                                            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="people-likes">
                                                        <ul>
                                                            <li
                                                                className="popover-cls"
                                                                data-bs-toggle="popover"
                                                                data-placement="right"
                                                                data-name="sufiya eliza"
                                                                data-img="assets/images/story-2.jpg"
                                                            >
                                                                <img
                                                                    src="assets/images/story-2.jpg"
                                                                    className="img-fluid bg-img"
                                                                    alt=""
                                                                />
                                                            </li>
                                                            <li
                                                                className="popover-cls"
                                                                data-bs-toggle="popover"
                                                                data-placement="right"
                                                                data-name="sufiya eliza"
                                                                data-img="assets/images/story-3.jpg"
                                                            >
                                                                <img
                                                                    src="assets/images/story-3.jpg"
                                                                    className="img-fluid bg-img"
                                                                    alt=""
                                                                />
                                                            </li>
                                                            <li
                                                                className="popover-cls"
                                                                data-bs-toggle="popover"
                                                                data-placement="right"
                                                                data-name="sufiya eliza"
                                                                data-img="assets/images/story-4.jpg"
                                                            >
                                                                <img
                                                                    src="assets/images/story-4.jpg"
                                                                    className="img-fluid bg-img"
                                                                    alt=""
                                                                />
                                                            </li>
                                                        </ul>
                                                        <h6>+12 people react this post</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center ">
                                    <img src="/assets/images/message-square.png" alt="" />
                                    <h3 className="font-weight-normal text-center">
                                        Comment not supported
                                    </h3>
                                    <p className="text-center">
                                        Comment preview are not supported for dynamic ads
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xl='6' className={isOpen ? 'p-2' : 'd-none'}>
                            <Collapse horizontal isOpen={isOpen}>
                                <div>
                                    <SingleImagePreview />
                                </div>
                            </Collapse>
                        </Col> */}
                    </Row>
                </div>
            </Card>
        </div>
    )
}

export default Performance