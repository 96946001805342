import * as types from "../../Constants/Connection/index.js";

const initialState = {
    userDisapproveReq: [], 
  loading: true,
};

const GetDisapproveRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_DISAPPROVE_REQUESTS:
      return {
        ...state,
        userDisapproveReq: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default GetDisapproveRequestReducer;
