import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";

const getAllUserGroups = (allGroups) => ({
  type: types.GET_ALL_USER_GROUPS,
  payload: allGroups,
});

export const loadAllUserGroups=(id) => async (dispatch, getState) => {
  try {
    const body= {userId:id,pageIndex:0,pageSize:10}
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_IPURL}/marketPlace/getSuggestedProductList`,
      body,
      config
    );
    // console.log(
    //   "api response suggested group data ======================>>>>>>>>>>>>>",response.data.data.successResult
    // );
    if (
      response.data &&
      response.data.data &&
      response.data.data.successResult &&
      response.data.data.successResult
    ) {
      // Dispatch action to indicate successful deletion
      dispatch(getAllUserGroups(response.data.data.successResult));
    } 
  } catch (error) {
    console.error("Error fetching tagged people:", error);
   
  }
};
