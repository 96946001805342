import React, { useState } from 'react'
import { CheckCircle, MapPin, Star } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap'

const Event = () => {
    const [seemore, setSeeMore] = useState(false)
    return (
        <div>
            <Row>
                <Col style={{ width: 'auto' }}>
                    <Card className="h-100 upcomming-text m-3 mr-2 text-UpComming" style={{ borderRadius: '0px' }}>
                        <img className='w-100 rounded-top' height={250} src="https://picsum.photos/900/270?grayscale" alt='bannerImage' />
                        <CardBody>
                            <div className='d-flex justify-content-center mb-2'>
                                <Button className='w-100' color='primary' outline ><CheckCircle size={18} /> Going</Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button color='primary' className='w-100' outline ><Star size={16} /> Interested</Button>
                            </div>
                            <h4>Get started with live music near you</h4>
                            <h6 className='text-muted'>Entertainment</h6>
                            <div className='d-flex justify-content-between text-primary my-2'>
                                <small>12th Apr 2021</small>
                                <small>11:00 AM - 08:00 PM</small>
                            </div>
                            <a href={'https://goo.gl/maps/bV32YS55UtQM7hhb7'} style={{ cursor: 'pointer' }} className='text-muted mb-2'><MapPin size={16} /> Clarendon Park Road, Leicester, LE2 3, United Kin</a>
                            <p>Events, by their very nature, are about connections. Through your event description, you{seemore ? 'can boost attendance Events, by their very nature, are about connections. Through your' : null} bright event description, you can boost attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Event