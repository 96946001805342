import React, { useState, useEffect } from 'react';
// import { FilterAllData } from '../MarketPlaceArray/MarketPlaceCategory';
import Radio from '@mui/material/Radio';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { ChildCate, ChildCategory } from '../MarketPlaceArray/MarketPlaceCategory';
import { ArrowLeft } from 'react-feather';
import axios from 'axios';

const MarketPlaceModal = (props) => {
    const navigate = useNavigate();
    const [filteredCategories, setFilteredCategories] = useState(props.category);
    const [searchInput, setSearchInput] = useState('');
    const [category, setCategory] = useState("");
    const [subCate, setSubCate] = useState("");
    const [subChildValue, setSubChildValue] = useState("");
    const [value, setValue] = useState('');
    const [subValue, setSubValue] = useState('');
    const [subChildCate, setSubChildCate] = useState('');
    const [disable, setDisable] = useState(true);
    const [openedContainer, setOpenedContainer] = useState(0);
    const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);
    const [subCategories, setSubCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);

    const categories = props.category;


    // const Values = ChildCate[category];
    // const Data = ChildCate[category].data;

    useEffect(() => {
        window.matchMedia("(max-width: 992px)").addEventListener('change', e => setMatches(e.matches));
    }, []);

    // useEffect(() => {
    //     ButtonDisable();
    // }, [value, subValue, subChildValue]);

    const categoryChage = async (e, item) => {
        e.stopPropagation();
        setValue(item.name);
        setCategory(item.id);
        await fetchSubCategories(item.id);
        setSubValue('')
        setSubChildValue('')

    };

    const subCategoryChange = async (e,item) => {
        e.stopPropagation();
        setSubValue(item.name);
        setSubCate(item.subcatid);
        setSubChildValue('')
        await fetchSubChildCategories(item.subcatid);
    };

    const subChildCategoryChange = (e,item) => {
        e.stopPropagation();
        setSubChildValue(item.value);
        setSubChildCate(item.subChildCatid);
        setDisable(false)
    };
    
   // ==============>>gettting categoty from props<<=====================
        useEffect(() => {
            setFilteredCategories(categories);
        }, [categories]);
        //filter category by name (searchKey)
        const handleSearchInputChange = (e) => {
            const input = e.target.value.toLowerCase();
            setSearchInput(e.target.value);
    
            const filtered = categories?.filter((category) =>
                category.name.toLowerCase().includes(input)
            );
            setFilteredCategories(filtered);
        };




    // ===================>>fetching fetchSubCategories ==============================
    const fetchSubCategories = async (categoryId) => {
        try {
            const body = { categoryId: categoryId };
            let user = JSON.parse(localStorage.getItem("sociomeeUser"));
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            };
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/marketPlace/getmpsubcategory`,
                body,
                config
            );
            
            if (response?.data?.data?.successResult && response.data.data.successResult.length > 0) {
                setSubCategories(response.data.data.successResult);
                setOpenedContainer(1);
                setDisable(true)
            } else {
                setSubCategories([]);
                setOpenedContainer(0); // No data, close the sub-category container
                setDisable(false)
            }
        } catch (error) {
            console.log("Error fetching subcategories:", error);
        }
    };


    // ===================>>fetching fetchSubChildCategories ==============================
    const fetchSubChildCategories = async (subCategoryId) => {
        try {
            const categoryId = category;
            const body = { categoryId, subCategoryId };
    
            let user = JSON.parse(localStorage.getItem("sociomeeUser"));
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            };
    
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/marketPlace/getmpsubchildcategory`,
                JSON.stringify(body),
                config
            );
    
            if (response?.data?.data?.successResult && response.data.data.successResult.length > 0) {
                setChildCategories(response.data.data.successResult);
                setOpenedContainer(2);
            } else {
                setChildCategories([]);
                setOpenedContainer(1); // No data, close the child category container
                setDisable(false)
            }
        } catch (error) {
            console.log("Error fetching child categories:", error);
        }
    };
    



    // =============>>> formating data object to pass as props to next mpShell screen<<<<<<<<===============
        const data = {}
        data.categoryId = category;
        data.categoryName = value;
        data.subCategoryId = subCate || null;
        data.subCategoryName = subValue || "";
        data.subChildCategoryId = subChildCate || null;
        data.subChildCategoryName = subChildValue || "";

    const ManageToggle = (e) => {
        props.toggle();
        if (e.target.innerText === "Next") {
            navigate('/mpsell', { state: data });
        } else {
            console.log("Other Button Clicked");
        }
    };

    // const ButtonDisable = () => {
    //     if (value && subValue && subChildValue) {
    //         setDisable(false);
    //     } else if (value && subValue && !Data) {
    //         setDisable(false);
    //     } else {
    //         setDisable(true);
    //     }
    // };
    return (
        <div>
            <Modal scrollable isOpen={props.open} toggle={props.toggle} className={`modal-dialog-centered MP_Modal_Adjustments position-relative ${openedContainer === 0 ? "" : openedContainer === 1 ? "modal-lg" : openedContainer === 2 ? "modal-xl" : "modal-xl"}`}>
                <ArrowLeft color='#7f7f7f' className={`position-absolute ${matches ? openedContainer >= 1 ? "" : "d-none" : "d-none"}`} style={{ left: "10px", top: "15px" }} onClick={() => setOpenedContainer(openedContainer - 1)} />
                <ModalHeader toggle={props.toggle} className="d-flex flex-column-reverse">
                    <div className="Mp_Modalheader">
                        <h3>Create Item For Marketplace</h3>
                        <h5 className='text-muted text-center'>Select your Category and sub Category here to continue..</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
            <Row>
                <Col md={matches ? 12 : openedContainer === 0 ? 12 : openedContainer === 1 ? 6 : openedContainer === 2 ? 4 : ""} className={`mb-2 ${matches ? openedContainer === 0 ? "Mp_CateList_d-block" : "d-none" : ""}`}>
                    <Card className="border">
                        <CardHeader className="bg-white">
                            <div className="Mp_SellModel_Cate d-flex align-items-center justify-content-between">
                                <Input placeholder="Select Your Category" style={{ border: "none" }} onChange={(e)=>handleSearchInputChange(e)}/>
                            </div>
                        </CardHeader>
                        <CardBody className="MpModalCategoryCard">
                            <div className='d-flex flex-column align-items-start justify-content-start' style={{ padding: "0rem 0.5rem" }}>
                                {filteredCategories?.map((item, index) => (
                                    <FormControl key={index}>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={(e) => categoryChage(e, item)}
                                            className="Radio_btn_check"
                                        >
                                            <FormControlLabel
                                                sx={{ '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                                                value={item.name}
                                                key={index}
                                                name="subcate"
                                                control={<Radio sx={{
                                                    '& .MuiSvgIcon-root': { fontSize: 20 },
                                                    color: "#808080",
                                                    '&.Mui-checked': { color: "#81c14b" },
                                                }} />}
                                                label={item.name}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                ))}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                {subCategories?.length > 0 &&(
                    <Col md={matches ? 12 : openedContainer === 1 ? 6 : 4} className={`mb-2 ${matches ? (openedContainer === 1 ? "Mp_CateList_d-block" : "d-none") : (openedContainer > 0 ? "" : "d-none")}`}>
                        <Card className="border">
                            <CardHeader className="bg-white">
                                <div className="Mp_SellModel_Cate d-flex align-items-center justify-content-between">
                                    <Input placeholder="Select Your Sub Category" style={{ border: "none" }} />
                                </div>
                            </CardHeader>
                            <CardBody className="MpModalCategoryCard">
                                <div className='d-flex flex-column align-items-start justify-content-start' style={{ padding: "0rem 0.5rem" }}>
                                    {subCategories.map((item, index) => (
                                        <FormControl key={index}>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={subValue}
                                                onChange={(e) => subCategoryChange(e, item)}
                                                className="Radio_btn_check"
                                            >
                                                <FormControlLabel
                                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                                                    value={item.name}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                '& .MuiSvgIcon-root': { fontSize: 20 },
                                                                color: "#808080",
                                                                '&.Mui-checked': { color: "#81c14b" },
                                                            }}
                                                        />
                                                    }
                                                    label={item.name}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}

                {childCategories?.length > 0 &&(
                    <Col md={matches ? 12 : 4} className={`${matches ? (openedContainer === 2 ? "Mp_CateList_d-block" : "d-none") : (openedContainer > 1 ? "" : "d-none")}`}>
                        <Card className="border">
                            <CardHeader className="bg-white">
                                <div className="Mp_SellModel_Cate d-flex align-items-center justify-content-between">
                                    <Input placeholder="Select Your Child Category" style={{ border: "none" }} />
                                </div>
                            </CardHeader>
                            <CardBody className="MpModalCategoryCard">
                                <div className='d-flex flex-column align-items-start justify-content-start' style={{ padding: "0rem 0.5rem" }}>
                                    {childCategories.map((childItem, index) => (
                                        <FormControl key={index}>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                // defaultValue={childItem.value}
                                                value={subChildValue}
                                                onChange={(e)=>subChildCategoryChange(e,childItem)}
                                                className="Radio_btn_check"
                                            >
                                                <FormControlLabel
                                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '15px' } }}
                                                    value={childItem.value}
                                                    // key={index}
                                                    name="subChildCate"
                                                    control={<Radio sx={{
                                                        '& .MuiSvgIcon-root': { fontSize: 20 },
                                                        color: "#808080",
                                                        '&.Mui-checked': { color: "#81c14b" },
                                                    }} />}
                                                    label={childItem.value}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
            <div className="Mp_SellModal_btn d-flex align-items-center justify-content-center gap-4 mt-3">
                <Button outline className="w-75" onClick={ManageToggle}>
                    Cancel
                </Button>
                <Button disabled={disable} color="primary" className="w-75" onClick={ManageToggle}>
                    Next
                </Button>
            </div>
        </ModalBody>
            </Modal>
        </div>
    );
};

export default MarketPlaceModal;
