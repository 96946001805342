import React, { Fragment, useEffect } from 'react';
import Slider from 'react-slick';
import { Card, CardImg } from 'reactstrap';
import { Users } from 'react-feather';
import { loadProfileByUserId } from '../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const sportSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };



const MyBizPage = (props) => {
    const dispatch = useDispatch();
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    const { userProfileByUserId } = useSelector(state => state.getUserProfileByUserIdData);

    useEffect(() => {
        dispatch(loadProfileByUserId(userId||props?.userId));
    }, []);

    
    const truncateText = (text) => {
        const words = text.split(' ');
        return words.length > 2 ? `${words.slice(0, 2).join(' ')}...` : text;
    };

    return (
        <Fragment>
            <div className="about-profile-box-blk">
                <div className="card-title">
                    <h3>BizPage</h3>
                </div>
                <div className="card-block-box">
                    <Slider {...sportSettings} className="default-space">
                        {
                            userProfileByUserId.businessPages?.map((data) => (
                                <div key={data.id}>
                                    <Link to="/BizDetail">
                                        <Card className="profile-slide-box">
                                        <CardImg
                                            alt="Card image cap"
                                            src={data?.businessPageLogo}
                                            width="100%"
                                            className="img-fluid"
                                            height="100%"
                                            style={{
                                             width: '200px',  // Set the desired width
                                             height: '150px', // Set the desired height
                                             objectFit: 'cover', // Ensure the image covers the container without distortion
                                           }}
                                        />
                                         <div style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Dark overlay with 30% opacity
                                                    pointerEvents: 'none'
                                                }}></div>
                                        <div className="story-content">
                                            <h6 style={{ color: 'white' }}>{truncateText(data.name)}</h6>
                                            <p><Users size={10} />&nbsp;{data.followerCount > 0 ? data.followerCount + ' Followers' : '0 Follower'}</p>
                                        </div>
                                        </Card>
                                    </Link>
                                    </div>

                            ))
                        }
                    </Slider>
                </div>
            </div>
        </Fragment>
    );
};

export default MyBizPage;
