import React, { Fragment, useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import MarketCategoryAccordion from "../MpFilterComponents/CategoryAccordion";
import MpCategory from "../MpFilterComponents/MpCategory";
import MpLocationRange from "../MpFilterComponents/MpLocationRange";
import { FilterAllData } from "../MarketPlaceArray/MarketPlaceCategory";
import MpFilterPriceFull from "../MpFilterComponents/MpFilterPriceFull";
import MpFilterRangeBetween from "../MpFilterComponents/MpFilterRangeBetween";
import MpFilterTabComponent from "../MpFilterComponents/MpFilterTabComponent";
import MpFilterValueInput from "../MpFilterComponents/MpFilterValueInput";
import MpCheckBoxFilter from "../MpFilterComponents/MpCheckBoxFilter";
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import Slider from "react-slick"; 
import { useDispatch, useSelector } from "react-redux";
import { getAttributeByCategoryAndSubCategory } from "../../../Services/Actions/MarketPlace/getAllProductsAction";





const getNestedData = (path, data) => {
    const keys = path.split('/');
    let currentData = data;
    for (let key of keys) {
        if (currentData[key]) {
            currentData = currentData[key];
        } else {
            return {}; 
        }
    }
    return currentData;
};

const MarketPlaceFilterSection = (props) => {
    // Get the nested data based on the filterPath
    const filterData = getNestedData(props.filterPath, FilterAllData);

    // Extract the various filter options
    const checkboxe = filterData.checkBox || [];
    const areaRange = filterData.areaRange || [];
    const locationRange = filterData.locationRange || true;
    const price = filterData.price || true; 
    const tabs = filterData.tabs || [];
    const [catAndSubCatData,setCatAndSubCatData] = useState([])

    const [text, settext] = useState("");
    const [text1, settext1] = useState("");
    const [value, setValue] = useState(15000);
    const [sliderValue, setSliderValue] = useState(15000);
    const [isActive, setIsActive] = useState(null);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
    const [activeStates, setActiveStates] = useState({});
    const [isClicked, setIsClicked] = useState("Home/Real Estate");
    const handleClearAll = () => {
        setSliderValue(15000);
        setValue(15000);
        settext("");
        settext1("");
        setIsActive(null);
        setCheckedCheckboxes([]);
        setActiveStates({});
    };

    useEffect(()=>{
        if(props.refreshFilter){
            // setSliderValue(15000);
            // setValue(15000);
            // settext("");
            // settext1("");
            setIsActive(null);
            setActiveStates({});
            setCheckedCheckboxes([]);

        }
    },[props.refreshFilter])

     

    // ==========>>>getting category data  to pass the child component=====================
    const [categoryData,setCategoryData] =useState([])
    const catAndSubcatListData =useSelector(state => state.getAllCategoryAndSubcategoryHaveData)
    useEffect(() => {
      if (catAndSubcatListData?.categoryAndSubcategoryData) {
        setCategoryData(catAndSubcatListData.categoryAndSubcategoryData);
      }
    //   props.setRefreshFilter(prev => !prev);
    }, [catAndSubcatListData]);

    props.setSubcategoryFilter(checkedCheckboxes)
    props.setMinPrice(text);
    props.setMaxPrice(text1);
    props.setLocationRange(value)


    //==========>>here we are getting all the attribute of the category and subcategory==============
    // / Initializing state for categorized arrays
    const [DropDownArray, setDropDownArray] = useState([]);
    const [TabArray, setTabArray] = useState([]);
    const [InputArray, setInputArray] = useState([]);
    const [attribute, setAttribute] = useState([]);
    
    useEffect(() => {
        if (props?.categoryId) {
            const fetchAttributesByCategoryId = async () => {
                setDropDownArray([]);
                setTabArray([]);
                setInputArray([]);
                //props?.categoryId ||
                // const body = { categoryId:  "bb0108f1-1afc-4429-8c35-9b0b7d2bf61e" };
                const body = { categoryId:  props?.categoryId };

                const Attributes = await getAttributeByCategoryAndSubCategory(body);
                // console.log("This is attribute data ===>>>", Attributes);
                setAttribute(Attributes);
    
                if (Attributes.length > 0) {
                    const dropDownArray = [];
                    const tabArray = [];
                    const inputArray = [];
    
                    Attributes.forEach(item => {
                        switch (item.type) {
                            case 'dropdown':
                                dropDownArray.push(item);
                                break;
                            case 'tab':
                                tabArray.push(item);
                                break;
                            case 'input':
                                inputArray.push(item);
                                break;
                            default:
                                break;
                        }
                    });
    
                    setDropDownArray(dropDownArray);
                    setTabArray(tabArray);
                    setInputArray(inputArray);
                }
            }

            if(props?.categoryId){
                // console.log("yes this is categoryId====>>>",props?.categoryId)
                fetchAttributesByCategoryId();
            }  
        }
    
    }, [props?.categoryId]);
    
    // Logging the arrays to check if they're updated correctly
    // console.log('DropDownArray:', DropDownArray);
    // console.log('TabArray:', TabArray);
    // console.log('InputArray:', InputArray);

    
    return (
        <Fragment>
            {/*  =================================== Accordion Filter By ================================== */}
            <Accordion defaultActiveKey="0" className='border mt-3' style={{ borderRadius: "5px", marginBottom: "20px" }}>
                <AccordionItem eventKey="0" style={{ background: "#FFFFFF" }}>
                    <div className="MpUploadHeading1" style={{ paddingRight: "1rem", paddingTop: "1rem" }}>
                        <h5 className='MrktClearAllBtn' onClick={handleClearAll}>Clear all</h5>
                    </div>
                    &nbsp;
                    &nbsp;

                    <AccordionHeader style={{ borderBottom: "1px solid #E5E5E5 " }}>
                        <div className='d-flex flex-column'>
                            <h3 className="Mp_Color4D_Font5">Filter By</h3>
                            <h6 className="Mp_Color80_Font4">{props.filterPath ? props.filterPath : ""}</h6>
                        </div>
                    </AccordionHeader>

                    <AccordionBody>
                        {/* ========================== All the checkboxes==================================  */}
                        {props.filterPath === "Home" ? 
                            <MarketCategoryAccordion 
                                categoryData = {categoryData}
                                data={checkboxe}                           
                                isActive={isActive} 
                                checkedCheckboxes={checkedCheckboxes} 
                                setIsActive={setIsActive} 
                                setCheckedCheckboxes={setCheckedCheckboxes} 
                                handleClearAll={handleClearAll} 
                                isClicked={isClicked} 
                                setIsClicked={setIsClicked} 
                            /> : 
                            <MpCategory 
                                filterPath ={props.filterPath}
                                categoryData = {categoryData}
                                data={checkboxe}  
                                activeStates={activeStates} 
                                setCheckedCheckboxes={setCheckedCheckboxes} 
                                setActiveStates={setActiveStates} 
                                handleClearAll={handleClearAll} 
                            />
                        }
                        {/*================================== Location Range slider Sec======================= */}
                        {locationRange ? 
                            <MpLocationRange 
                                setSliderValue={setSliderValue} 
                                setValue={setValue} 
                                sliderValue={sliderValue} 
                                value={value} 
                                handleClearAll={handleClearAll} 
                            /> : null
                        }
                        {/*========================== Price Boxes========================================  */}
                        {price ? 
                            <MpFilterPriceFull 
                                data={price} 
                                settext={settext} 
                                settext1={settext1} 
                                text={text} 
                                text1={text1} 
                            /> : null
                        }
                        {/*================================ Between range sliders==========================  */}
                        {areaRange.length === 0 ? null : 
                            <MpFilterRangeBetween 
                                data={areaRange} 
                            />
                        }


                        {/*================================== Tabs====================================== */}
                        {/* {DropDownArray.length > 0 && 
                            <MpFilterTabComponent 
                                data={DropDownArray} 
                            />} */}
                         {/* /=====here we  have added 2 more tab */}
                         {DropDownArray.length > 0 && <MpCheckBoxFilter 
                                data={DropDownArray} 
                                setAttributeVal = {props.setAttributeVal} 
                            />}

                        {TabArray.length > 0 && 
                            <MpFilterTabComponent 
                                data={TabArray} 
                                setAttributeVal = {props.setAttributeVal} 
                            /> } 
                      

                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </Fragment>
    );
};

export default MarketPlaceFilterSection;
