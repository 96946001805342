import React from 'react'
import { Fragment } from 'react';
import { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Button, Input, Row } from 'reactstrap';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';

const ResetPasswordPage = () => {





    // -------------------------------- To manage the hide unhide of input  password  =----------------------------------------------

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);




    // -----------------------------------------------------Language Selection ---------------------------------------------------
    const [selectedLanguage, setSelectedLanguage] = useState("English")




    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }





    return (


        <Fragment>
            {/* =========================Full Login Page ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />


            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row>
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec">
                                    <NavLink to="/">
                                        <img src="/assets/images/logo.png" alt="logo" className="img-fluid" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="login-form">
                                {/*  ---------------------------------------------- Stepper  ---------------------------------------------- */}
                                <LoginSignUpStepper steps={3} active={3} />
                                <div>
                                    <div className="login-title">
                                        <h2>Reset Password</h2>
                                    </div>
                                    <div className="login-discription">
                                        <h4 className='Login4f_400'>Enter password of minimum 8 character with atleast one lowercase, uppercase, number and special character</h4>
                                    </div>
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    {/* <label>Enter your password</label> */}
                                                    <div className="input-block">
                                                        <Input type={passwordShown ? "text" : "password"} className={`form-control`} placeholder="Enter password" maxLength={25} /> {/* border-danger  ------ class for warning or alert  */}
                                                        <Eye color='#4E4F5080' size={14} className={`input-icon iw-20 ih-20 ${passwordShown ? "" : "d-none"}`} onClick={() => setPasswordShown(false)} />
                                                        <EyeOff color='#4E4F5080' size={14} className={`input-icon iw-20 ih-20 ${passwordShown ? "d-none" : ""}`} onClick={() => setPasswordShown(true)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    {/* <label>Confirm Password</label> */}
                                                    <div className="input-block">
                                                        <Input type={passwordShown2 ? "text" : "password"} className={`form-control `} placeholder="Confirm Password" maxLength={25} /> {/* border-danger  ------ class for warning or alert  */}
                                                        <Eye color='#4E4F5080' size={14} className={`input-icon iw-20 ih-20 ${passwordShown2 ? "" : "d-none"}`} onClick={() => setPasswordShown2(false)} />
                                                        <EyeOff color='#4E4F5080' size={14} className={`input-icon iw-20 ih-20 ${passwordShown2 ? "d-none" : ""}`} onClick={() => setPasswordShown2(true)} />
                                                    </div>
                                                    {/* <p className="error-input-msg text-center d-none" ref={errorRef}>{error}</p> */}
                                                </div>
                                                <div className="bottom-sec">
                                                    {/* <div className="form-check checkbox_animated">
                                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                        <label className="form-check-label Login03_400" htmlFor="exampleCheck1">Log me out of all other devices</label>
                                                    </div> */}
                                                </div>
                                                <div className="btn-section">
                                                    <Button color="primary" className="dffewfwef" >CONTINUE</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                {["English", "Hindi", "Deutsch"].map((item, index) => (
                                    <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                ))}
                            </span>
                            <button id="ShowTheLanguageModal" onClick={LanguageModalToggle} >
                                ...More
                            </button>
                        </div>
                    </div >
                </div>
            </section>
        </Fragment>
    )
}

export default ResetPasswordPage
