import { React, useEffect, useState } from "react";
import { Info } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";


const ShortAnswer = ({ remover, inputId, isDisplay, setStarSecond1, setStarSecond, starSecond1, starSecond }) => {
  const [formDetail, setFormDetail] = useState({ formName: "" });
  const [dataValue1, setDataValue1] = useState();

  const inputHandler = (ev) => {
    const { name, value } = ev.target;
    setDataValue1(value);
    setIsDisplay(value === '');
    setFormDetail({ ...formDetail, [name]: value });
    const eventName = "input_lf_" + inputId;
    console.log("cint" + eventName);
    const customEvent = new CustomEvent(eventName, {
      detail: { value },
    });
    document.dispatchEvent(customEvent);
  };

  useEffect(() => {
    const handleCustomEvent = (event) => {
      // Access the message from the custom event's detail property
      const { value } = event.detail;
      console.log(`Received Message: ${value}`);
      setDataValue1(value);
    };
    const eventName = "input_lf_" + inputId;
    console.log(eventName);
    document.addEventListener(eventName, handleCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleCustomEvent);
    };

  }, []);
  const [isDisplay1, setIsDisplay] = useState(true);


  return (
    <>
      {/* <input type="text" name="" id="" /> */}
      <div className="row ad-types-of-type-map  mt-4" style={{ margin: '2px' }}>
        <div className="single-ad">
          <div className="col-lg-12 inputs d-flex p-0">
            <div className="col-lg-12 col-12">
              <div className="d-flex justify-content-between">
                <p className={isDisplay ? "p-heading" : " p-heading p-2 hideborders"}>
                  Give Hint about the question in field to user
                </p>
                {isDisplay && <p className="lead-greem" role="button" onClick={remover}>
                  Remove
                </p>
                }
              </div>
              <div className="">
                {isDisplay && <p className="mb-4">
                  Hint would indicate the kind of information required to fill.
                </p>}
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="formName"
                  value={dataValue1}
                  className={isDisplay ? "form-control p-2" : "form-control p-2 text-field"}
                  placeholder={!starSecond ? "Type the ‘Hint’ here..." : "Type the ‘Hint’ here*"}
                  maxLength={"165"}
                  onChange={inputHandler}
                  readOnly={!isDisplay}
                />
                {/* {!isDisplay && <p className="start-text">*</p>} */}
                {isDisplay && <p className="p-max-car">Upto 165 Characters</p>}
              </div>

              <div className="">
                <div className="d-flex lead-switch">
                  {isDisplay && <p className="p-heading">
                    Mandatory Question
                    <span className="pl-1">
                      <Info fill='#828282' id='plTooltip' color='white' size={18} />
                      <UncontrolledTooltip placement='right' target='plTooltip'>
                        mandatory question field
                      </UncontrolledTooltip>
                    </span>
                  </p>}
                  {isDisplay && <label className="switch">
                    <input type="checkbox" checked={starSecond1} onChange={() => { setStarSecond((prev) => !prev); setStarSecond1((prev) => !prev) }} />
                    <span className="slider round"></span>
                  </label>}
                </div>
                {isDisplay && <p>Add a star mark to indicate mandatory question field</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShortAnswer;