import React, { useState } from 'react'
import { Button, Offcanvas } from 'react-bootstrap'
import { ChevronDown, Filter } from 'react-feather'
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import Counrtries from '../../top-trending-components/Counrtries'

export const CountryFilters = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const AvatarGroup = () => {
        const avatardata = [
            {
                img: 'assets/images/flag-india.png'
            },
            {
                img: 'assets/images/flag.jpg'
            },
            {
                img: 'assets/images/flag-india.png'
            },
            {
                img: 'assets/images/flag.jpg'
            }
        ]
        return (
            <>
                <ul className="matual-friend-blk p-0">
                    {
                        avatardata.map((item) => {
                            return (
                                <li className='p-0 my-0'>
                                    <img src={item.img} className="img-fluid rounded-circle border" alt="" />
                                </li>
                            )
                        })
                    }
                </ul>
            </>
        )
    }

    return (
        <>
            <UncontrolledButtonDropdown className="rounded d-flex align-items-center p-0 rem-mob-view">
                <DropdownToggle color="transparent" className="p-0 d-flex align-items-center " >
                    <Button variant="outline-primary" className='d-flex align-items-center p-2'>
                        <AvatarGroup /> +50 countries<ChevronDown size={15} className='ml-2' />
                    </Button>
                </DropdownToggle>
                <DropdownMenu style={{ top: '50px', zIndex: '100' }} className='country-drop-menu' >
                    <Counrtries />
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            {/* --------------------Mobile Filter-------------------- */}
            <Button variant="outline-primary" onClick={handleShow} className='d-flex align-items-center p-2 rem-web-view'>
                <AvatarGroup color='#81C14B' size={25} />
            </Button>
            <Offcanvas show={show} onHide={handleClose} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><strong>Country</strong></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <UncontrolledButtonDropdown className="rounded d-flex align-items-center p-0">
                        <Counrtries />
                    </UncontrolledButtonDropdown>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}