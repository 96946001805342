import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    userBlockUnblock: [],
    loading: true,
};

 const GetUserBlockUnBlockReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_BLOCK_UNBLOCK:
            return {
                ...state,
                userBlockUnblock: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default GetUserBlockUnBlockReducer;