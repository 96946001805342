import zIndex from "@mui/material/styles/zIndex";
import React, { useEffect, useRef, useState } from "react";
import { X ,XCircle} from "react-feather";

const StoryTextRotaterDragger = ({ selectedText, setSlectedText, dummyTexts }) => {
    const [AllTexts, setAllText] = useState([]);

    const offscreen = new OffscreenCanvas(256, 256);
    function measureText(txt) {
        const ctx = offscreen.getContext("2d");
        ctx.font = "30px Arial";
        let metrics = ctx.measureText(txt);
        metrics.height =
            metrics.actualBoundingBoxAscent - metrics.actualBoundingBoxDescent;
        return metrics;
    }




    useEffect(() => {
        const script = document.createElement("script");
        script.type = "module";
        script.src =
            "https://unpkg.com/@deckdeckgo/drag-resize-rotate@latest/dist/deckdeckgo-drag-resize-rotate/deckdeckgo-drag-resize-rotate.esm.js";
        script.onload = () => {
            window.dispatchEvent(new Event("deckdeckgoLoaded"));
        };
        document.head.appendChild(script);
    }, []);

    useEffect(() => {
        const loadCustomElements = () => {
            const deckDeckGoDRR = window.deckDeckGoDRR;
            if (deckDeckGoDRR) {
                deckDeckGoDRR();
            }
        };

        if (window.deckDeckGoDRR) {
            loadCustomElements();
        } else {
            window.addEventListener("deckdeckgoLoaded", loadCustomElements);
        }

        return () => {
            window.removeEventListener("deckdeckgoLoaded", loadCustomElements);
        };
    }, []);

    useEffect(() => {
        const updatedTexts = dummyTexts.map((item) => {
            let text = item.text;
            let metrics = measureText(text);
            return { ...item, width: metrics.width, height: metrics.height };
        });

        setAllText(updatedTexts);


    }, [dummyTexts, measureText]);

    const handleCancelText = (index) => {
        // Create a copy of the AllTexts array
        const updatedAllTexts = [...AllTexts];
        // Set the text content of the specified deckgo-drr to an empty string
        updatedAllTexts[index].text = "";
        // Update the state to trigger a re-render
        setAllText(updatedAllTexts);
    };



    return (
        <>
            {AllTexts.map((item, index) => (
                <deckgo-drr
                
                    key={index}
                    style={{
                        "--width": `${item.width / 25}px`,
                        "--height": `${item.height }px`,
                        "--top": "45%",
                        "--left": "45%",
                        "--deckgo-drr-border": "1px solid #ffffff",
                        "--deckgo-drr-rotate-anchor-action-border": "1px solid #ffffff",
                        "--deckgo-drr-rotate-anchor-presentation-border-right": "1px solid #ffffff",
                        "--deckgo-drr-anchor-background": "#81c14b",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        zIndex: "2",
                        
                    }}
                    className={`${selectedText === index ? "selectedTextBorder" : ""}`}
                    onClick={() => setSlectedText(index)}
                >

                    {/* <X size={15}  /> */}
                  

                    <svg className="StoryTextCustomize_">
                        {item.text}
                    </svg>
                    <pre
                        className={`addedtext `}
                        onClick={() => {
                            setSlectedText(index);
                        }}
                        style={{
                            width: "auto",
                            height: "auto",
                            color: item.color,
                            fontFamily: item.fontStyle,
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            maxWidth: "100%",
                            maxHeight: "fit-content",
                            padding: "5px",
                            fontSize: "1.2vw",
                        }}
                    >
                        {item.text}
                    </pre>
                    <div style={{
                        position: "absolute",
                        top: "-31px",
                        left: "-12px",
                        display: "flex",
                        alignItems: "flex-start",
                        color:"white",
                        cursor:"pointer"
                    }}  >
                        <XCircle onClick={() => handleCancelText(index)} />
                    </div>
                </deckgo-drr>
            ))}
        </>
    );
};

export default StoryTextRotaterDragger;
