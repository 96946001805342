import React from 'react'
import { Fragment } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Blockchain from '../Icon/Blockchain.svg'
import Category from '../Icon/Category.svg'
import Collections from '../Icon/Collections.svg'
import Saletype from '../Icon/Saletype.svg'
import Pricerange from '../Icon/Pricerange.svg'
import DataAdded from '../Icon/Dateadded.svg'
import ImgSmple from '../Img/ImgSmple.svg'
import NftAavatarGroup from '../SubComponents/NftAavatarGroup'
import { Heart, MoreHorizontal } from 'react-feather'
import { ExpDummyCardData } from '../DummyArray/ExpDummyData'

const ExploreNTF = () => {

  //===========Start_Tab_Data ==============//
  const ExploreTabData = [
    {
      id: "1",
      icon: Blockchain,
      name: "Blockchain"
    },
    {
      id: "2",
      icon: Category,
      name: "Category"
    },
    {
      id: "3",
      icon: Collections,
      name: "Collections"
    },
    {
      id: "4",
      icon: Saletype,
      name: " Sale type"
    },
    {
      id: "5",
      icon: Pricerange,
      name: "Price range"
    }
  ]
  //=========== End_Tab_Data ==============//

  //=================== Main_Return_Function ====================//
  return (
    <Fragment>
      <div>
        <div className='ExploreNft_Heading' >
          <h1>Explore NFTs</h1>
        </div>
        {/*===================== start_Explore_NFT_Tab ===============*/}
        <div className="ExploreNftTab_Section">
          <Row>
            <Col sm="8">
              <div className='ExploreNft-Tab-block-box '>
                <ul className='Tab_Block'>
                  {
                    ExploreTabData.map((data) => (
                      <li>
                        <img src={data.icon} alt='blockchanin' width={16} height={16} />&nbsp;&nbsp;
                        {data.name}
                      </li>
                    ))
                  }
                </ul>
              </div>
            </Col>
            <Col sm="4" >
              <div className='d-flex justify-content-end '>
                <ul className='Tab_Block'>
                  <li>
                    <img src={DataAdded} alt='blockchanin' width={16} height={16} />&nbsp;&nbsp;
                    Date added
                  </li>
                </ul>

              </div>
            </Col>

          </Row>

        </div>
        {/*===================== end_Explore_NFT_Tab =================*/}
        <div className='ExploreNftTab_Section'>
          <div className="container-fluid section-t-space px-0">
            <div className="group-single-list-block">
              <Row className='mt-4 ExploreNft_CardThisGrid '>
                {
                  ExpDummyCardData.map((data) => (
                    <Col md="3" className="mb-4 w-100">
                      <div className='ExploreNft_Cardbox_section ' >
                        <div className='d-flex justify-content-between'>
                          <NftAavatarGroup />
                          <MoreHorizontal size={18} color='#000' />
                        </div>
                        <div className='ExploreNft_Img_smple'>
                          <img src={ImgSmple} alt='img' />
                        </div>
                        <div className='ExploreNft_Heading_Blk'>
                          <h3>{data.name}</h3>
                        </div>
                        <div className='ExploreNft_SubHeading_Blk'>
                          <img src={data.icon} />&nbsp;<span>from</span>&nbsp;
                          <h4>{data.coinType}</h4>
                        </div>
                        <div className='ExpoleNft_Bottom_Section'>
                          <Button color="primary" className="Explore_Card_Button">
                            Buy Now
                          </Button>
                          <div className='d-flex align-items-center'>
                            <Heart color='#81C14B' size={18} />
                            <h4 className='ml-3'> {data.likeCount}</h4>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </Row>
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}

export default ExploreNTF