import React, { useRef, useState,useEffect } from 'react';
import { AlertCircle, AlertTriangle, Check, ChevronsDown, ChevronsUp, Circle, Copy, Download, Eye, Filter, Heart, LogOut, MoreHorizontal, MoreVertical, Plus, Search, Send, Smile, User, Users, Volume2, VolumeX, X ,Slash} from 'react-feather';
import { NavLink, useNavigate } from "react-router-dom";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown } from 'reactstrap';
import MainIcon from '../../../../NewComponents/IMG/podcastIcon.svg'
import Wings from '../../../../NewComponents/IMG/PocastSideWing.svg'
import MainGoLiveIcon from '../../../../NewComponents/IMG/MainGoLiveIcon.gif'
import UserImg from '../../../../NewComponents/IMG/img.svg'
import PinIcon from '../../../../NewComponents/IMG/PinIcon.svg'
import { Offcanvas } from 'react-bootstrap';
import { useUserAuth } from '../../../../Context/userAuthContext';
import Picker from "emoji-picker-react";
import { Radio } from '@mui/material';
import Frames from '../../../Bookmark-Components/Img/Frames.png'

function ViwerSide() {

    /*--- Calling bottom musicbar state from context ----*/
    const { setMusicBar } = useUserAuth();

    /*-------------- useRef Conditions -----------------*/
    const navigate = useNavigate()
    const commentBlk = useRef(null);
    const FullcommentSec = useRef(null);
    const ButtonHalf = useRef(null);
    const ButtonFull = useRef(null);
    const AddWidth = useRef(null);
    const videoRef = useRef(null);
    const ClickFullCom = (e) => {
        commentBlk.current.classList.remove("d-none");
        ButtonFull.current.classList.add("d-none");
        ButtonHalf.current.classList.remove("d-none");
        FullcommentSec.current.classList.add("golive-fullcomment");
        AddWidth.current.classList.add("liveandclose-block-width-change");
    };
    const ClickHalfCom = (e) => {
        commentBlk.current.classList.add("d-none");
        ButtonFull.current.classList.remove("d-none");
        ButtonHalf.current.classList.add("d-none");
        FullcommentSec.current.classList.remove("golive-fullcomment");
        AddWidth.current.classList.remove("liveandclose-block-width-change");
    };

    // count abbreviation function

    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt((num / 1000))}K`
        } else if (num >= 999999) {
            return `${parseInt((num / 1000000))}M`
        } else if (num <= 999) {
            return `${num}`
        }
    }

    /*-------------- State with Functions -----------------*/


    const [currentTime, setCurrentTime] = useState(0);
    const [volumeON, setvolumeOff] = useState(false)
    const [playing, setPlaying] = useState(false);
    const [videoTime, setVideoTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const [reply, setreply] = useState(false);
    const [show, setShow] = useState(false);
    const [isOff, setIsOff] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPicker, setShowPicker] = useState(false);
    const [inputStr, setInputStr] = useState("");


    const [blockuser, setblockuser] = useState(false)
    const toggleblockuser = () => {
        setblockuser((prevState) => !prevState)
    }

    const [viewerdropdownOpen, setviewerDropdownOpen] = useState(false)
    const toggleViewer = () => {
        setviewerDropdownOpen((prevState) => !prevState)
    }

    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [removeuser, setremoveuser] = useState(false)
    const togglesetremoveuser = () => {
        setremoveuser((prevState) => !prevState)
    }

    const [senddropdownOpen, setsendDropdownOpen] = useState(false)
    const toggleSender = () => {
        setsendDropdownOpen((prevState) => !prevState)
    }

    const [emojiOpen, setemojiClose] = useState(false)
    const toggleEmojiBox = () => {
        setemojiClose(!emojiOpen)
    }

    // pinned comment selection 
    const [pinnedComment, setPinnedComment] = useState(false)
    const Pineedclose = () => {
        setPinnedComment(!pinnedComment)
        setShow(false)
    }

    /// emojii
    const onEmojiClick = (emojiObject) => {
        setInputStr((prevInput) => prevInput + emojiObject.emoji);
        setInputStr(inputStr + emojiObject.emoji);
        setShowPicker(false);
    };


    // { for the heart emoji icon}
    const [heartsReleased, setHeartsReleased] = useState(false);
    const [heartCount, setHeartCount] = useState(0);
    const [animationTimeout, setAnimationTimeout] = useState(null);

    /*-------------- Viewer Array -----------------*/

    const viewerData = [
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Following'
        }
    ]

    /*-------------- Report Label Array -----------------*/

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    /*-------------- Sender Modal Array -----------------*/

    const sendData = [
        {
            userNames: 'Darrell Steward',
            joined: 'Darrell@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Fox Steward',
            joined: 'Fox@564',
            typesof: 'Following'
        },
        {
            userNames: 'John SNow',
            joined: 'Snow@123',
            typesof: 'Follow'
        },
        {
            userNames: 'Amy Stuart',
            joined: 'Stuart@123',
            typesof: 'Following'
        }
    ]

    /*-------------- Modal for viewer -----------------*/

    const ViewerModal = () => {
        return (
            <>
                {viewerdropdownOpen ? (
                    <Modal isOpen={viewerdropdownOpen} className='' toggle={toggleViewer} >
                        <ModalHeader className='d-flex justify-content-center'>
                            <b>{numFormatter('9000')} People watching Live</b>
                        </ModalHeader>
                        <ModalBody>
                            {
                                viewerData.map((d, i) => {
                                    return (
                                        <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                                            <div className='d-flex align-items-center mobwatch'>
                                                <img src='https://i.pinimg.com/originals/bd/a5/be/bda5be61177acdb5fd46c3219f8b81a0.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                                                <div className='ml-1'>
                                                    <h4>{d.userNames}</h4>
                                                    <p className='text-muted'>{d.joined}</p>
                                                </div>
                                            </div>
                                            <div>
                                                {d.typesof === 'Follow' ? <Button style={{ padding: '10px 30px 10px 30px' }} color='primary' ><Plus size={18} />Follow</Button>
                                                    : <Button color='primary' disabled><Check size={18} />Following</Button>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for SendList -----------------*/

    const SendModal = () => {
        return (
            <>
                {senddropdownOpen ? (
                    // <Modal isOpen={senddropdownOpen} className='modal-dialog-centered' toggle={toggleSender} >

                    //     <ModalHeader className='d-flex justify-content-center'>
                    //         <h2>Share live stream</h2>
                    //     </ModalHeader>
                    //     <ModalBody>
                    //         <div className='d-flex align-items-center justify-content-between'>
                    //             <InputGroup>
                    //                 <InputGroupText className='bg-transparent'>
                    //                     <Search />
                    //                 </InputGroupText>
                    //                 <Input type='search' placeholder='Search..' />
                    //             </InputGroup>
                    //             <UncontrolledButtonDropdown>
                    //                 <DropdownToggle color='flat-primary'>
                    //                     <Filter color='#4D4D4D' />
                    //                 </DropdownToggle>
                    //                 <DropdownMenu>
                    //                     <DropdownItem tag='a'> Family </DropdownItem>
                    //                     <DropdownItem tag='a'> Office </DropdownItem>
                    //                 </DropdownMenu>
                    //             </UncontrolledButtonDropdown>
                    //         </div>

                    <Modal isOpen={senddropdownOpen} className='modal-dialog-centered' toggle={toggleSender} >
                        <ModalHeader className='d-flex justify-content-center'>
                            <h2>Share live stream</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className='d-flex align-items-center justify-content-between'>
                                <InputGroup className='mr-2'>
                                    <InputGroupText className='bg-transparent'>
                                        <Search />
                                    </InputGroupText>
                                    <Input type='search' placeholder='Search..' />
                                </InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' >
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Family </DropdownItem>
                                        <DropdownItem tag='a'> Office </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="scrollForSharePodcast mt-2">
                                {
                                    sendData.map((d, i) => {
                                        return (
                                            <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                                                <div className='d-flex align-items-center sharemodalpod'>
                                                    <img src='https://c4.wallpaperflare.com/wallpaper/586/603/742/minimalism-4k-for-mac-desktop-wallpaper-preview.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                                                    <div className='ml-1'>
                                                        <h4>{d.userNames}</h4>
                                                        <p className='text-muted'>{d.joined}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button color='primary'><Send size={18} /> <span>send</span></Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}

            </>
        )
    }

    /*-------------- Modal for Report User -----------------*/

    const ReportModal = () => {
        const [radioActive, setRadioActive] = useState(null)
        return (
            <>
                <div>
                    {
                        reportOpen !== false &&
                        <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                            <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                                <h3>Report</h3>
                            </ModalHeader>
                            <ModalBody>
                                <div className='Modal_Body_Section'>
                                    <h4>Tell us about what you feel and help us to make Sociomee more secure.</h4>
                                </div>
                                <br />
                                {
                                    ReportDataInfo.map((data, index) => (
                                        <div
                                            key={index}
                                            className='d-flex justify-content-between Report_Section'
                                        >
                                            <h5
                                                className='d-flex reort'
                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                                                }}
                                            >
                                                {data.name}
                                            </h5>
                                            <div>
                                                <Radio
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    name="radio-buttonsOne"

                                                    className='Radio_btn_check'
                                                    checked={radioActive === index}
                                                    onClick={() => { setRadioActive((prevIndex) => (prevIndex === index ? null : index)); }}
                                                />
                                                {' '}
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className='d-flex justify-content-center mt-3'>
                                    <Input
                                        id="ReportName"
                                        name="ReportName"
                                        placeholder="Write a comment..."
                                        type="text
                        "
                                    />
                                </div>
                                <hr className='w-100 text-muted' />
                                <div className='text-center'>
                                    <Button className="w-25 ml-2 reportbu" color="primary" onClick={toggleReport} >Submit</Button>
                                    <Button onClick={toggleReport} outline className="w-25 ml-2 reportbu" >Cancel</Button>
                                </div>
                            </ModalBody>

                        </Modal>

                    }

                </div>
            </>
        )
    }

    /*-------------- Modal for Block User -----------------*/

    const Blockmodal = () => {
        return (
            <>
                {blockuser ? (
                    <Modal isOpen={blockuser} className='modal-dialog-centered' toggle={toggleblockuser} >

                        <ModalHeader className='d-flex justify-content-center'>
                            <h4>Do you really want to Block This User ?</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center'>
                                <Button onClick={toggleblockuser} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='orange' onClick={toggleblockuser} >Block</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    const EmojiBoxModal = () => {
        return (
            <>
                {emojiOpen ? (
                    <Modal isOpen={emojiOpen} className='modal-dialog-centered modal-sm' toggle={toggleEmojiBox} >


                        <ModalBody>

                            EmojiBoxModal
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Remove User -----------------*/

    const RemoveModal = () => {
        return (
            <>
                {removeuser ? (
                    <Modal isOpen={removeuser} className='modal-dialog-centered' toggle={togglesetremoveuser} >

                        <ModalHeader className='d-flex justify-content-center'>
                            <h4>Do you really want Unfollow this User?</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center'>
                                <Button onClick={togglesetremoveuser} color='secondary'>Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={togglesetremoveuser} >Yes, UnFollow</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Video Handel function -----------------*/

    const videoHandler = (control) => {
        if (control === "play") {
            videoRef.current.play();
            setPlaying(true);
            var vid = document.getElementById("video1");
            setVideoTime(vid.duration);
        } else if (control === "pause") {
            videoRef.current.pause();
            setPlaying(false);
        }
    };

    const fastForward = () => {
        videoRef.current.currentTime += 5;
    };

    const revert = () => {
        videoRef.current.currentTime -= 5;
    };

    window.setInterval(function () {
        setCurrentTime(videoRef.current?.currentTime);
        setProgress((videoRef.current?.currentTime / videoTime) * 100);
    }, 1000);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentTime(videoRef.current?.currentTime);
          setProgress((videoRef.current?.currentTime / videoTime) * 100);
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      }, [videoTime]);
    const MobileHeader = () => {
        const [isBlinking,] = useState(true);


        return (
            <div className="rem-web-view">
                <div className='p-2 mobheaderstyle'>
                    <p onClick={() => navigate('/Podcast')} > <X /> <span>End</span></p>
                    <div>
                        <h5 className='p-1'>#ENTERTAINMENT</h5>
                        {/* <h6 className='p-1 fs-5 fw-bold'>00:03 <span className='bg-danger text-white rounded py-0 px-1'>Live</span></h6> */}
                        <h6 className='p-1 fs-3 fw-bold'>00:05 <span className={`p-1  bg-danger text-white roundeds-4 py-1 px-2 podsliveblink   ${isBlinking ? 'blinkab' : ''}`}>Live</span></h6>
                        <h6 onClick={toggleViewer} className='p-1'><Eye size={18} /><span> 128k watching</span></h6>
                    </div>
                </div>
            </div>
        )
    }

    const MobileRightSidebar = () => {
        const handleHeartClick = () => {
            setHeartsReleased(true);
            setHeartCount(heartCount + 1);
            clearTimeout(animationTimeout); // Clear any existing timeout
            const timeout = setTimeout(() => {
                setHeartsReleased(false);
            }, 1000);
            setAnimationTimeout(timeout);
        }

        return (
            <div style={{ zIndex: '1000' }} className="rem-web-view postitionForleftsidebar">
                <div className=' d-flex flex-column text-white align-items-center '>
                    <div className='d-flex flex-column align-items-center podheart'>
                        {/* <Heart className='' strokeWidth={1} size={28} fill='#FF5B58' />
                        <p>18</p> */}
                        <Heart className={`heart-button ${heartsReleased ? 'released' : ''}`} strokeWidth={1} size={28} fill='#FF5B58' onClick={handleHeartClick} />
                        {heartsReleased && (

                            <div className="heart-explosion">
                                {Array.from({ length: 5 }, (_, index) => (
                                    <span key={index} className="heart-emoji">


                                        💕
                                    </span>
                                ))}

                            </div>

                        )}
                        <p className='hrtcnt'>{heartCount}</p>
                    </div>
                    <span className='user-select-none' onClick={() => setIsOff(!isOff)}> {isOff ? <Volume2 className='ml-1' size={24} /> : <VolumeX className='ml-1' size={24} />}</span>
                    <div className='d-flex flex-column align-items-center'>
                        <Send style={{ cursor: 'pointer' }} onClick={toggleSender} className='mx-2' size={24} />
                        <p>18</p>
                    </div>
                    {/* <div className='d-flex flex-column align-items-center'>
                        <Download style={{ cursor: 'pointer' }} className='mx-2' size={24} />
                        <p>18</p>
                    </div> */}
                    <UncontrolledButtonDropdown direction='up' className=''>
                        <DropdownToggle color='flat-primary' className='p-0 m-0'>
                            <MoreVertical className='ml-1' color='#ffffff' size={24} />
                        </DropdownToggle>
                        <DropdownMenu className='goliveFordrop' >
                            <DropdownItem ><Copy size={20} /> Copy Link</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={() => navigate('/MyProfile')}><User size={20} /> Creator's Profile</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleReport} tag='a'><AlertTriangle size={20} /> Report Podcast</DropdownItem>
                            {/* <DropdownItem divider></DropdownItem> */}
                            {/* <DropdownItem onClick={togglesetremoveuser} tag='a'><Download size={20} /> Save Video </DropdownItem> */}
                            {/* <DropdownItem ><X size={20} /> Not Interested </DropdownItem> */}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        )
    }
    const handleHeartClick = () => {
        setHeartsReleased(true);
        setHeartCount(heartCount + 1);
        // Clear any existing timeout
        const timeout = setTimeout(() => {
            setHeartsReleased(false);
        }, 1000);
        setAnimationTimeout(timeout);
    }

    
    return (
        <>
            <div className="golive-video-section">
                <MobileHeader />
                {/* <video id="video1" className="video" ref={videoRef}>
                    <source src="assets/images/video/golive-video.mp4" type="video/mp4" />
                </video> */}
                <div className="video-container1">
                <img className="video" ref={videoRef} src='https://dlcdnrog.asus.com/rog/media/1637784966993.webp' />
                </div>
             


                {/*---------------------------------- Above created Modals calls ---------------------------------- */}

                <SendModal />
                <ViewerModal />
                <ReportModal />
                <RemoveModal />
                <Blockmodal />
                <EmojiBoxModal />
                {/* <div className="controlsContainer">
                    <div className="controls">
                        <div className="controlsIcon" onClick={revert}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-24 ih-24"><polygon points="11 19 2 12 11 5 11 19"></polygon><polygon points="22 19 13 12 22 5 22 19"></polygon></svg></div>

                        {playing ? (
                            <div className="controlsIcon--small" onClick={() => videoHandler("pause")}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-24 ih-24"><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg></div>
                        ) : (
                            <div className="controlsIcon--small" onClick={() => videoHandler("play")}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWdth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-24 ih-24"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg></div>
                        )}

                        <div className="controlsIcon" onClick={fastForward}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-24 ih-24"><polygon points="13 19 22 12 13 5 13 19"></polygon><polygon points="2 19 11 12 2 5 2 19"></polygon></svg></div>
                    </div>
                </div> */}
                <div className="controlsContainer">
                    <div className="controls">

                        <div className='podcastMicImg'>
                            <div className='podcastMic'>
                                {/* <img className='' height={100} src={MainIcon} alt='PodcastIcon' />
                                <img className='podcastMicpositionForWings' height={105} src={Wings} alt='Wings' /> */}
                                 <img src={Frames} alt=''   className='blinking-form blinkicons podcastMicpositionForWings' /> 
                            </div>
                        </div>
                    </div>
                </div>
                <MobileRightSidebar />
                <div className="timecontrols">
                    <div className="inputboxForposition rem-web-view">
                        <div className='MobForChat   chtspod EditedChanges' style={{ bottom: pinnedComment ? "90px" : "39px" }}>
                            <div class="gradient-blur">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {[...Array(6)].map((index) => (
                                <div key={index} className={`p-1 px-2 d-flex`}>
                                    <img src={UserImg} width={25} height={25} className='rounded-circle' />
                                    <div onClick={handleShow} className='rounded-pill text-white chatForpopup'>
                                        <div className='d-flex align-items-center'>
                                            <h4 className='text-white'>Sufiya elija</h4><h6 className='pl-1'>01:00</h6>
                                        </div>
                                        <small>How are you ?</small>
                                    </div>
                                </div>
                            ))}
                            <div className={`p-1 px-2 d-flex mobPinnedChat bg-transparent  ${pinnedComment ? "" : " d-none"}`}>
                                <img src={UserImg} width={25} height={25} className='rounded-circle' />
                                <div onClick={handleShow} className='rounded-pill text-white chatForpopup'>
                                    <div className='d-flex align-items-center'>
                                        <h4 className='text-white'>Sufiya elija</h4><h6 className='pl-1'>01:00 <img src={PinIcon} alt="pinedIcon" /></h6>
                                    </div>
                                    <small><span className='text-primary font-italic'>@Taha sir</span> joined</small>

                                </div>
                            </div>
                        </div>
                        <Input type='text' className='InputForcolor12 rounded-0' placeholder='Comment....' value={inputStr}
                            onChange={(e) => setInputStr(e.target.value)} />
                        <div className='d-flex text-muted commentInputForposition'>
                            <Smile onClick={() => setShowPicker((val) => !val)} />
                            {showPicker && (
                                <Picker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
                            )}

                            <Send className='iconForcolor' />
                        </div>
                    </div>
                    <p className="controlsTime rem-mob-view">
                        {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}<span> / </span>
                        {Math.floor(videoTime / 60) + ":" + ("0" + Math.floor(videoTime % 60)).slice(-2)}
                    </p>
                    <div className="time_progressbarContainer rem-mob-view">
                        <div
                            //   style={{ width: `${progress}%` }}
                            className="time_progressBar"
                        ></div>
                    </div>
                    <div className="userlikeshare-block rem-mob-view">
                        <div className="user-emozi-blk" style={{ cursor: "pointer" }}>
                            👍 😀 😁 😍 😗 😜
                        </div>
                        <div className="fav-share-block">
                            <div onClick={toggleSender} className="customfavshareblk">
                                <Send className='' size={24} /> 35
                            </div>
                            <div className="customfavshareblk">
                                {/* <Heart size={24} stroke="currentColor" strokeWidth="1" fill='#FF5B58' strokeLinecap="round" strokeLinejoin="round" /> 357 */}
                                <div className='wholeheart'>
                                    <div className="heart-animation-app ">


                                        <div className='d-flex flex-column align-items-center podwebheart' >
                                            <Heart className={`heart-button ${heartsReleased ? 'released' : ''}`} strokeWidth={1} size={28} fill='#FF5B58' onClick={handleHeartClick} />
                                            {heartsReleased && (
                                                <div className="heart-explosion1">
                                                    {Array.from({ length: 5 }, (_, index) => (
                                                        <span key={index} className="heart-emojii">
                                                            💕
                                                        </span>

                                                    ))}


                                                </div>
                                            )}
                                            <span className='heartcount'>{heartCount}</span>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*---------------------------------- TOP HEADER---------------------------------- */}

                <div className="liveandclose-block" ref={AddWidth}>
                    <div className="live-comment-view-block rem-mob-view">
                        {/* <div className="livestatus-con">Live</div> */}
                        <img src={MainGoLiveIcon} width={100} alt='Live' className='positionIconForMob bg-transparent' />
                        <div className='mx-3 positionWatchingForMob' style={{ cursor: 'pointer' }}>
                            <UncontrolledButtonDropdown direction='up' className=''>
                                <DropdownToggle color='flat' className='p-0 m-0'>
                                    <div className='text-white d-flex align-items-center'>
                                        <Eye size={24} />
                                        <h4 style={{ paddingLeft: '0px' }} >{numFormatter('9000')}&nbsp;Listening</h4>
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu className='py-0' style={{ minWidth: '25rem' }} >
                                    <div className='bg-light text-center py-2'>
                                        <b>{numFormatter('9000')} People watching Live</b>
                                    </div>
                                    <div>
                                        {
                                            viewerData.map((d, i) => {
                                                return (
                                                    <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                                                        <div className='d-flex align-items-center'>
                                                            <img src='https://i.pinimg.com/originals/bd/a5/be/bda5be61177acdb5fd46c3219f8b81a0.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                                                            <div className='ml-1'>
                                                                <h4 className='text-body'>{d.userNames}</h4>
                                                                <p className='text-muted podjoined'>{d.joined}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {d.typesof === 'Follow' ? <Button style={{ padding: '10px 30px 10px 30px' }} color='primary' ><Plus size={18} />Follow</Button>
                                                                : <Button color='primary' disabled><Check size={18} />Following</Button>}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </DropdownMenu>
                            </UncontrolledButtonDropdown>

                        </div>
                        <h4>#Entertainment</h4>
                    </div>
                    <div className="golive-close-details-blk rem-mob-view">
                        {volumeON ? < VolumeX color='white' onClick={() => setvolumeOff(!volumeON)} size={24} /> : < Volume2 color='white' onClick={() => setvolumeOff(!volumeON)} size={24} />}


                        <div className="golive-3dot-blk">
                            <UncontrolledButtonDropdown direction='start'>
                                <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                    <MoreVertical size={22} color='#fff' />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '100', minWidth: 'fit-content' }}>
                                    <DropdownItem><Copy size={20} /> &nbsp;&nbsp;Copy Link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={() => navigate('/MyProfile')} ><User size={20} /> &nbsp;&nbsp;Creator's Profile</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={toggleReport}><AlertTriangle size={20} />&nbsp;&nbsp; Report Podcast</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    {/* <DropdownItem onClick={togglesetremoveuser}><Download size={20} /> Save Video </DropdownItem> */}
                                    <DropdownItem><X size={20} /> &nbsp;&nbsp;Not Interested </DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem><Slash size={20} /> &nbsp;&nbsp;Hide</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <NavLink to="/Podcast" onClick={() => setMusicBar(false)} ><X color='white' size={24} /></NavLink>
                    </div>
                </div>

                {/*----------------------------------- Comment Sidebar----------------------------------- */}

                <div className="golive-comment-block" ref={FullcommentSec}>
                    {/* <a className="golive-comment-updown-button d-none" onClick={ClickHalfCom} ref={ButtonHalf}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><polyline points="7 13 12 18 17 13"></polyline><polyline points="7 6 12 11 17 6"></polyline></svg></a> */}
                    {/* <a className="golive-comment-updown-button" onClick={ClickFullCom} ref={ButtonFull}><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg></a> */}
                    <a className="golive-comment-updown-button margin-for-animation d-none chevron-down" onClick={ClickHalfCom} ref={ButtonHalf}>
                        <ChevronsDown />
                    </a>
                    <a className="golive-comment-updown-button margin-for-animation chevron-down" id='test' onClick={ClickFullCom} ref={ButtonFull}>
                        <ChevronsUp />
                    </a>
                    <div className="goliveuser-blk headerFormain">
                        <img src={UserImg} />
                        <span className="HeaderactiveForGoLiveIcon"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                        <div className="goliveuser-cont-blk">
                            <h4>Paige Turner</h4>
                            <p>Alamba, USA</p>
                        </div>
                    </div>

                    <div className="golive-user-comment-blk d-none" ref={commentBlk}>
                        <div className="gl-com-like-share-blk">
                            <div className="gl-heartlist"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="#FF5B96" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="#FF5B96" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> <span>+75</span></div>
                            <div className="comment-share-goliveblk">
                                <span><b>4565</b> Comment</span>
                                <span><b>985</b> Share</span>
                            </div>
                        </div>

                        <div className="comment-section golive-comment-section">
                            <div className="comments d-block">
                                <div className="main-comment">
                                    <div className="media">
                                        <a className="user-img popover-cls"
                                            data-bs-toggle="popover" data-placement="right"
                                            data-name="Pabelo mukrani"
                                            data-img="assets/images/story-2.jpg">
                                            <img src={UserImg} className="img-fluid bg-img" alt="user" />
                                        </a>
                                        <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>

                                        <div className="media-body">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <a className='d-flex' >
                                                    <h5>Pabelo Mukrani</h5>
                                                    {
                                                        pinnedComment ?
                                                            <img src={PinIcon} className='ml-2' alt='Pinicon' /> : null
                                                    }
                                                </a>
                                                <UncontrolledButtonDropdown direction='start '>
                                                    <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                        <MoreHorizontal size={22} color='#fff' />
                                                    </DropdownToggle>
                                                    <DropdownMenu style={{ zIndex: '100' }} className='obj-container' >
                                                        <DropdownItem onClick={() => setPinnedComment(!pinnedComment)} tag='a'>
                                                            {
                                                                pinnedComment ?
                                                                    <>
                                                                        <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                                        </svg> UnPin comment
                                                                    </> :
                                                                    <>
                                                                        <svg width="20" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M8.04543 9.88211L8.67887 11.9041L8.11552 12.8504L4.80333 10.8787L2.54052 14.6798L1.78567 14.8713L1.59418 14.1164L3.85699 10.3153L0.544806 8.34355L1.10816 7.39721L3.09292 6.93389L6.21015 1.69748L5.42153 1.22802L5.98489 0.281677L12.5146 4.16883L11.9513 5.11517L11.1627 4.6457L8.04543 9.88211ZM2.38572 8.15774L7.30668 11.0872L6.89252 9.66575L10.2163 4.08235L7.15649 2.26083L3.83269 7.84423L2.38572 8.15774Z" fill="#808080" />
                                                                        </svg>
                                                                        Pin comment
                                                                    </>
                                                            }
                                                        </DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem tag='a'> <User size={20} /> View Profile</DropdownItem>
                                                        {/* <DropdownItem divider></DropdownItem>
                                                        <DropdownItem tag='a'> <Copy size={20} /> Copy Link</DropdownItem> */}
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} /> Block user </DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem onClick={toggleReport} tag='a'>  <AlertTriangle size={20} /> Report...</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div className="comment-time">
                                                <h6>50 mins ago</h6>
                                            </div>
                                            <p>Oooo Very Cute and Sweet Dog, happy birthday
                                                Cuty.... &#128578;
                                            </p>
                                            <ul className="comment-option">
                                                <li><a>like (5)</a></li>
                                                <li><a onClick={() => setreply(!reply)}>reply (1)</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {reply ?
                                        <div className="sub-comment">
                                            <div className="media">
                                                <a className="user-img popover-cls"
                                                    data-bs-toggle="popover" data-placement="right"
                                                    data-name="sufiya elija"
                                                    data-img="assets/images/story-3.jpg">
                                                    <img src={UserImg}
                                                        className="img-fluid bg-img"
                                                        alt="user" />
                                                </a>
                                                <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                                                <div className="media-body">
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <a>
                                                            <h5>Pabelo Mukrani</h5>
                                                        </a>
                                                        <UncontrolledButtonDropdown direction='start'>
                                                            <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                                <MoreHorizontal size={22} color='#fff' />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ zIndex: '100' }}>
                                                                <DropdownItem tag='a'> <User size={20} /> View Profile</DropdownItem>
                                                                {/* <DropdownItem divider></DropdownItem>
                                                        <DropdownItem tag='a'> <Copy size={20} /> Copy Link</DropdownItem> */}
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} /> Block user </DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem onClick={toggleReport} tag='a'>  <AlertTriangle size={20} /> Report...</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                    <div className="comment-time">
                                                        <h6>50 mins ago</h6>
                                                    </div>
                                                    <p>Thank You So Much ❤❤</p>
                                                    <ul className="comment-option">
                                                        <li><a>like</a></li>
                                                        <li><a>reply</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                                <div className="main-comment">
                                    <div className="media">
                                        <a className="user-img popover-cls"
                                            data-bs-toggle="popover" data-placement="right"
                                            data-name="pabelo mukrani"
                                            data-img="assets/images/story-2.jpg">
                                            <img src={UserImg}
                                                className="img-fluid bg-img"
                                                alt="user" />
                                        </a>
                                        <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                                        <div className="media-body">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <a>
                                                    <h5>Pabelo Mukrani</h5>
                                                </a>
                                                <UncontrolledButtonDropdown direction='start'>
                                                    <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                        <MoreHorizontal size={22} color='#fff' />
                                                    </DropdownToggle>
                                                    <DropdownMenu style={{ zIndex: '100' }}>
                                                        <DropdownItem tag='a'> <User size={20} /> View Profile</DropdownItem>
                                                        {/* <DropdownItem divider></DropdownItem>
                                                        <DropdownItem tag='a'> <Copy size={20} /> Copy Link</DropdownItem> */}
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} /> Block user </DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem onClick={toggleReport} tag='a'>  <AlertTriangle size={20} /> Report...</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div className="comment-time">
                                                <h6>50 mins ago</h6>
                                            </div>
                                            <p>It’s party time, Sufiya..... and happy birthday
                                                cuty 🎉🎊
                                            </p>
                                            <ul className="comment-option">
                                                <li><a>like</a></li>
                                                <li><a>reply</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="golive-commentinput-block">
                        <Input type='text' className='InputForcolor' Placeholder='Comment....' value={inputStr}
                            onChange={(e) => setInputStr(e.target.value)} />
                        <div className='d-flex text-white commentInputForposition'>
                            <Smile onClick={() => setShowPicker((val) => !val)} />
                            {showPicker && (
                                <Picker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
                            )}

                            <Send className='iconForcolor' />
                        </div>
                    </div>
                    <Offcanvas placement='bottom' show={show} onHide={handleClose} className='canvas1 '>
                        <Offcanvas.Header >

                        </Offcanvas.Header>
                        <Offcanvas.Body className='p-0 canvas2'>
                            <ul style={{ fontFamily: "Poppins", fontWeight: '400', fontSize: '14px', marginBottom: '7px' }} className=' canvas'>
                                <li className='text-muted  border-top-0 py-11'>Follow</li>
                                <li className='text-muted border-top-0  py-11'>Remove from  Podcast</li>
                                <li onClick={Pineedclose} className='text-muted border-top-0  py-11'>{pinnedComment ? 'Unpin Comment' : "Pin Comment"}</li>
                                <li className='text-muted border-top-0  py-11'>Block Comment</li>
                                <li className='text-muted  border-top-0  py-11'>Report Comment</li>
                            </ul>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            </div>
        </>
    );
}
export default ViwerSide 