import React from "react";
import {
  AlertTriangle,
  BellOff,
  Edit,
  Eye,
  Link2,
  MapPin,
  MoreVertical,
  PhoneCall,
  Send,
  ShoppingCart,
  Trash2,
} from "react-feather";
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import cardImg from "../img/cardImage.png";
import { useNavigate } from "react-router-dom";
import Mrktgreen from "../Icons/mrktgreen.svg";
import Mrktwhite from "../Icons/mrktwhite.svg";
import { handleContactClick } from "../../../../src/functions/copyContact.js";
import { sweetAlertConfirmation } from "../../group-components/AllGroupsSections/SweetAlert";
import axios from "axios";

const MarketPlaceGridViewCard = ({
  data,
  openDeleteModal,
  toggleForward,
  toggleReport,
  setRefresh,
  setMarketPlaceId,
}) => {
  const navigate = useNavigate();

  // num formatter
  const numFormatter = (num) => {
    // eslint-disable-next-line prefer-template
    if (num > 999 && num < 1000000) {
      return `${parseInt(num / 1000)} K`;
    } else if (num >= 999999) {
      return `${parseInt(num / 1000000)} M`;
    } else if (num <= 999) {
      return `${num}`;
    }
  };

  const handleTheNavigation = (card) => {
    let id = card.id;
    navigate(`/mpsingleproduct`, { state: id });
    sessionStorage.setItem("selectedUser", JSON.stringify(card));
  };

  // =========>>mark as sold product======================
  const markAsSold = async (e, marketPlaceId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/markasSold`,
        { marketPlaceId: marketPlaceId, sold: 1 },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
          },
        }
      );
      if (response.data.success) {
        sweetAlertConfirmation("Succesfully Sold Out");
        setRefresh((prevRefresh) => !prevRefresh);
      }
    } catch (error) {
      console.error("Error during  marketplace mark As sold product:", error);
      return error;
    }
  };
  // =========>>allow notification marketPlace product======================
  const allowNotification = async (e, marketPlaceId, allowNotification) => {
    e.stopPropagation();
    try {
      const newStatus = !allowNotification;
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/marketPlace/allowNotif`,
        { marketPlaceId: marketPlaceId, allowNotif: newStatus },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
          },
        }
      );
      if (response.data.success) {
        sweetAlertConfirmation("Notification Setting Changed");
        setRefresh((prevRefresh) => !prevRefresh);
      }
    } catch (error) {
      console.error("Error during  notification setting change:", error);
      return error;
    }
  };

  ///setting dummy value for jump to edit screen
  const jumpToEditPost = (e, productCategoryData) => {
    e.stopPropagation();
    const data = {
      marketPlaceId: productCategoryData?.id,
    };
    navigate("/MpEditProductShell", { state: data }); //commented willopen it
  };

  return (
    <Col sm={4} className="w-100">
      {/* ============ Cards ======================================== */}
      <Card className="marketplaceCardSec2" key={data.id}>
        <div className="position-relative">
            
          <img
            src={data.thumbnailURL}
            alt="market"
            className="img-fluid Mp_Card_img"
            onClick={() => {
              handleTheNavigation(data);
            }}
          />
          <div className="MarketPlaceCard_RightEye  MarketPlaceMobileCardRightEye">
            <p className="MpDesktopEye">
              <Eye color="white" size={20} />
              &nbsp; {numFormatter(data?.viewsCount || 0)}
            </p>
            <p className="MpMobileEye">
              <Eye color="white" size={14} />
              &nbsp; {numFormatter(data?.viewsCount || 0)}
            </p>
          </div>
          <UncontrolledButtonDropdown className="Mp_ThreeDotIcon">
            <DropdownToggle className="border-0 bg-transparent ">
              <MoreVertical color="#fff" size={18} />
            </DropdownToggle>
            <DropdownMenu
              className={`${
                data.isOwner === true
                  ? "MpDropdownOptionMenu"
                  : "MpDropdownOptionMenu2"
              }`}
            >
              {data.isOwner ? (
                <>
                  <DropdownItem
                    className={`MpDropdownMenuItems`}
                    onClick={(e) =>
                      allowNotification(e, data.id, data.allowNotification)
                    }
                  >
                    {data.allowNotification
                      ? " Turn Off Notification"
                      : " Turn On Notification"}
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => jumpToEditPost(e, data)}
                    className={`MpDropdownMenuItems`}
                  >
                    <Edit size={16} color="#808080" /> Edit Product
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => openDeleteModal(e, data.id)}
                    className={`MpDropdownMenuItems `}
                  >
                    <Trash2 size={16} color="#808080" /> Delete Product
                  </DropdownItem>
                  <DropdownItem className="MpDropdownMenuItems">
                    <Link2 size={16} color="#808080" /> Copy link{" "}
                  </DropdownItem>
                  <DropdownItem
                    onClick={toggleForward}
                    className="MpDropdownMenuItems"
                  >
                    <Send size={16} color="#808080" /> Share{" "}
                  </DropdownItem>
                  <DropdownItem
                    className={`MpDropdownMenuItems `}
                    onClick={(e) => markAsSold(e, data.id)}
                  >
                    <ShoppingCart color="#808080" size={16} /> Mark as Sold{" "}
                  </DropdownItem>
                </>
              ) : (
                <>
                  <DropdownItem
                    onClick={toggleForward}
                    className="MpDropdownMenuItems"
                  >
                    <Send size={16} color="#808080" /> Share{" "}
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => {
                      toggleReport();
                      setMarketPlaceId(data?.id);
                    }}
                    className={`MpDropdownMenuItems`}
                  >
                    {" "}
                    <AlertTriangle color="#808080" size={16} /> Report
                  </DropdownItem>
                  {/* <DropdownItem className={`MpDropdownMenuItems `}><ShoppingCart color="#808080" size={16} /> Put on Sale </DropdownItem> */}
                  <DropdownItem className="MpDropdownMenuItems">
                    <Link2 size={16} color="#808080" /> Copy link{" "}
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>

        <div className="MarketPlace_Card_BottomLeftPosition "></div>
        <div className="MarketPlace_MrktIconLeft  MarketPlaceMobileMrktIconLeft">
          {/*  ===================== Ternary to toggle the bag icon of the card  ====================== */}
          <img
            src={data?.isOwner === 1 ? Mrktgreen : Mrktwhite}
            className="Mp_MarketSvgICon MpMobileMarketSvgICon"
            alt="marketIcon"
          />
        </div>
        <div className="MarketPlaceCardContent ">
          <div className="Mp_Card_Bottom_Sec MpMobileCardBottomSec">
            <h3>
              {data.title.length > 45
                ? `${data.title.substring(0, 45)}...`
                : data.title}
            </h3>
          </div>
          <div className="MarketPlace_SubCate_Price py-1  MarketPlaceMobileSubCatePrice ">
            <h5>{data?.mpCategory?.name}</h5>

            <p>${data.price}</p>
          </div>
          <div className="MarketPlace_End_section py-1 MarketPlaceMobileEndSection">
            <p>
              <MapPin color="#808080" size={18} />
              &nbsp;
              {data.location.length > 24
                ? `${data.location.substring(0, 24)}...`
                : data.location}
            </p>
          </div>
          <div className="Mp_Card_Call_Msg_Btn mt-1 MpMobileCardCallMsgBtn">
            <Button
              className="MpCardBtnSizeManage border-primary"
              color="primary"
              onClick={(event) => handleContactClick(event, data?.user.mobile)}
            >
              <PhoneCall
                size={15}
                color="#fff"
                style={{ marginRight: "8px" }}
              />{" "}
              Call
            </Button>
            <a
              href="https://msgmee.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                color="primary"
                outline
                className="Mp_Card_Msgbtn MpCardBtnSizeManage"
                block
              >
                <svg
                  width="27"
                  height="30"
                  viewBox="0 0 27 30"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  className="Mp_MsgBtn_svg"
                  style={{ marginRight: "8px", width: "15px", height: "15px" }}
                >
                  <path
                    d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z"
                    stroke="#81C14B"
                    stroke-width="1.5"
                  />
                </svg>
                Message
              </Button>
            </a>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default MarketPlaceGridViewCard;
