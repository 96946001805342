import React, { useEffect } from "react";
import axios from "axios";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XCircle } from 'react-feather';
import {
  Button,
  Card,
  CardImg,
  Col,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//import { uploadCoverPicture } from '../../Services/Actions/UserProfile/postProfileCover';
import { updateUserProfile } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
//import { addPost } from "../../Services/Actions/SocialFeed/getAllUserPostsAction";
import { loadProfileByUserId } from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import "./profileComponents.css";
import Prfle from "./Img/prfle.png";
import { ArrowLeft, CheckCircle, X } from "react-feather";
import FlwrImg from "./Img/FlwrImg.png";
import { loadAllUserFollowers } from "../../Services/Actions/Connection/getUserFollowersAction";
import { loadAllUserFollowing } from "../../Services/Actions/Connection/getUserFollowingAction";
import ProfileMenu from "./ProfileMenu";
import { useLocation, useNavigate } from 'react-router-dom';
import { sweetAlertConfirmation } from "../group-components/AllGroupsSections/SweetAlert";
import { FollowReqestTab } from "../Connections/ConnectionPlaceMenu/FollowReqestTab";
import { Spinner } from 'react-bootstrap';
import Backgrdpic from '../biz-components/Img/BizCover.png'

const ProfileCover = (props) => {
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate=useNavigate();
  
  const [followingSearch, setFollowingSearch] = useState("");
  const [followerSearch, setFollowerSearch] = useState("");
  const [render, setRender] = useState(false);
  const [select, setSelect] = React.useState(false);
  const [success,setSuccess]=useState(false);
  const [loading, setLoading]=useState(false);
  
  let dispatch = useDispatch();
  // const [userId,setUserId]=useState(props.userId);
  const toggleSelectOpen = () => {
    setSelect((preState) => !preState);
  };
  // setSelectedfirst((prevState) => !prevState)
  const [followingOpen, setFollowingOpen] = useState(false);
  const toggleFollowing = () => {
    setFollowingOpen((preState) => !preState);
    setFollowingSearch("")
    setRender(!render)
  };
  const [followersOpen, setFollowersOpen] = useState(false);
  const toggleFollowers = () => {
    setFollowersOpen((preState) => !preState);
    setFollowerSearch("")
    setRender(!render)
  };
  const [removeOpen, setRemoveOpen] = useState(false);
  const toggleRemove = () => {
    setRemoveOpen((preState) => !preState);
  };
  const [chooseOpen, setChooseOpen] = useState(false);
  const toggleChoose = () => {
    setChooseOpen((preState) => !preState);
  };
  const [choose, setChoose] = useState(false);
  const ChooseImage = (e) => {
    e.preventDefault();
    setSelectedImage("");
    setChoose((preState) => !preState);
  };
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  // get user profile by user id
  // const { userProfileByUserId } = useSelector(
  //   (state) => state.getUserProfileByUserIdData
  // );
  const { getUserFollowers } = useSelector(
    (state) => state.GetUserFollowersData
  );
  const { getUserFollowing } = useSelector(
    (state) => state.GetUserFollowingData
  );

  const userProfileByUserId=props?.userProfileByUserId;

  useEffect(() => {
    // dispatch(loadProfileByUserId(props.userId));
    dispatch(loadAllUserFollowers(followerSearch,props.userId));
    dispatch(loadAllUserFollowing(followingSearch,props.userId));
    setSuccess(false)
  }, [props.userId,success]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [SetModal1, setSetModal1] = useState(false)

  const handleImageSelect = async (event) => {
    const files = Array.from(event.target.files);
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    formData.append("uploadFor", "userProfileCover");
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/admin/uploadFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate the progress percentage
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percent);
          },
        }
      );
  
      // Update the selected image after successful upload
      setSelectedImage(response.data.data.successResult[0]);
      setLoading(false)
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setLoading(false);
  };
  
  const handleUpdateCover = async () => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/user/update`,
        { coverImage: selectedImage },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        dispatch(loadProfileByUserId(user.id));
        setChoose((preState) => !preState);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRemoveCover = async () => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/user/update`,
        { coverImage: "" },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        dispatch(loadProfileByUserId(props.userId));
        toggleRemove();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFollowingSearch = (e) => {
    setFollowingSearch(e.target.value);
    setRender(!render);
  };
  const handleFollowerSearch = (e) => {
    setFollowerSearch(e.target.value);
    setRender(!render);
  };


  const [selectedFile, setSelectedFile] = useState([]);

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    console.log("in handelImagesSelect files ", files);
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    formData.append("uploadFor", "userProfileDp");
    const response = await axios.post(
      `${process.env.REACT_APP_IPURL}/admin/uploadFile`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("sociomeeUser"))?.token
          }`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("hello", response.data.data.successResult);
    console.log(
      "check selectedImages and response after uploading images",
      selectedFile,
      "       ",
      response.data.data.successResult
    );
    setSelectedFile(response.data.data.successResult);
    handleUpdate(response.data.data.successResult[0]);
  };
  const handleUpdate = async (profile) => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/user/update`,
        { profileImage: profile, profileImageThumb: profile },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        user.profileImage = profile;
        // user.profileImageThumb = profile;
  
        // Save the updated user object back to local storage
        localStorage.setItem("sociomeeUser", JSON.stringify(user));
        dispatch(loadProfileByUserId(user.id));

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [userMedia, setUserMedia] = useState([]);
  const [imageURL,setImageURL]=useState('');
  const [errorFollowing,setErrorFollowing]=useState('');
  const { allPostsByUserId } = useSelector(
    (state) => state.getAllPostsByUserIdData
  );
  const allMediaForGallery = useMemo(() => {
    const media = userMedia?.rows
      ?.filter((post) => post.postType === "media")
      ?.slice(0, 9)
      ?.map((media) => media?.mediaList && [...media.mediaList]);
    return media?.flat();
  }, [userMedia]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/post/getAllPosts`,
        {userId:props?.userId,pageSize:10,pageIndex:0,searchKey:""},
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      )
      .then((res) => {
        setUserMedia(res.data.data.successResult);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [allPostsByUserId,props?.userId]);

  const handleImageClick = (image) => {
     setSelectedImage(image);
  };

  const updateCover = () => {
    dispatch(updateUserProfile(userMedia));
  };

  const settingData =(e,id)=> {
    e.stopPropagation();
    props.setUserId(id);
    setFollowersOpen(false);
    setFollowingOpen(false);
    navigate(`/MyTimeline?userId=${id}&type=viewer`)
  }

  const ShowHandle1 = (e,url) => {
    e.stopPropagation()
    e.preventDefault()
    setImageURL(url);
    setSetModal1(!SetModal1)
}

const closeImg=()=>{
  setImageURL('');
  setSetModal1(!SetModal1)
}

const SetUpModal1 = () => {
    // const modalStyle = {
    //     maxWidth: '40%', 
    //     height: '80vh',// Set the maximum width to 80% of the screen
    // };

    return (
        <>
           <Modal isOpen={SetModal1} toggle={closeImg} className='modal-dialog-centered bizpagepicmodal pt-2'>
            <div onClick={closeImg} className="Modal_False_blk1">
              <XCircle color="#FFFFFF" />
            </div>
            <div> {/* Optional padding for better spacing */}
              <img src={imageURL} style={{ width: "100%", height: "100%"}} alt="Modal Content" />
            </div>
          </Modal>
        </>
    );
};

  const FollowUnFollow =(e,id,type,isPrivate)=>{
    e.stopPropagation();
    e.preventDefault();

    const isFollowed = type === "follow" ? 1 : 0;

      const fetchData = async () => {
        try {
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
                    { followedById:user.id, isFollowed:isFollowed, isPrivate:isPrivate,followingToId:id},
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setSuccess(true);
                // sweetAlertConfirmation("Successfully Followed")
            } else {
              setErrorFollowing('no user found');
            }
        } catch (error) {
          setErrorFollowing(error);
        }
     };
  
    fetchData();
        
  }

  const commonStyle = {
    width: '100px', // Adjust the width as needed
    color: 'primary',
    textAlign: 'center',
    padding: '10px 0', // Adjust padding as needed
  };

  const followBackStyle = {
    ...commonStyle,
    backgroundColor: '#81C14B',
    color: 'white',
  };

  const unfollowStyle = {
    ...commonStyle,
    color: 'black',
  };

  return (
    <>
      <div className="profile-cover">
        <img
          src={
            userProfileByUserId.coverImage}
          className="img-fluid bg-img"
          alt="cover"
          onClick={(e)=>ShowHandle1(e,userProfileByUserId.coverImage)}
        />
        <div className="profile-box d-lg-block d-none">
          <div className="profile-content">
            <div className="image-section">
              <div className="profile-img">
                <div>
                  <img
                    src={userProfileByUserId.profileImage}
                    className="img-fluid bg-img"
                    alt="profile"
                    onClick={(e)=>ShowHandle1(e,userProfileByUserId.profileImage)}
                  />
                </div>
              </div>
            </div>
            <div className="profile-detail">
              <a>
                <h2>{userProfileByUserId.fullName}</h2>
              </a>
              <div className="description">
                <p>{userProfileByUserId.userName}</p>
              </div>
              <div className="counter-stats">
                <ul id="counter">
                  <li>
                    <h3 className="counter-value" data-count="546">
                      {userProfileByUserId.totalPostCount}
                    </h3>
                    <h5>Posts</h5>
                  </li>
                  <li onClick={toggleFollowing} style={{ cursor: "pointer" }}>
                    <h3 className="counter-value" data-count="26335">
                      {userProfileByUserId.followingCount}
                    </h3>
                    <h5>Followings</h5>
                  </li>
                  <li onClick={toggleFollowers} style={{ cursor: "pointer" }}>
                    <h3 className="counter-value" data-count="6845">
                      {userProfileByUserId.followersCount}
                    </h3>
                    <h5>Followers</h5>
                  </li>
                </ul>
              </div>
              {currentPath ==="/MyProfile" && (
                <div className="profile-cover profile-cover-new">
                <a href="#" className="btn btn-solid btn-group btn-grp-new">
                  <ul>
                    {user.id === props.userId  ?(
                       <li className="choose-file">
                       <a href="#">Edit Profile</a>
                       <input
                         type="file"
                         accept="image/*"
                         onChange={handleFileChange}
                       />
                     </li>
                    ):(
                      <>
                   <li className="choose-file">
                      {userProfileByUserId.isFollowing ? (
                        userProfileByUserId.isFollower ? (
                          <a onClick={(e)=>FollowUnFollow(e,userProfileByUserId.id,"unfollow",userProfileByUserId.isPrivate)}>Unfollow</a>
                        ) : (
                          <a onClick={(e)=>FollowUnFollow(e,userProfileByUserId.id,"unfollow",userProfileByUserId.isPrivate)}>Unfollow</a>
                        )
                      ) : userProfileByUserId.followingStatus === "pending" ? (
                        <a onClick={(e)=>FollowUnFollow(e,userProfileByUserId.id,"unfollow",userProfileByUserId.isPrivate)}>Requested</a>
                      ) : (
                        <a onClick={(e)=>FollowUnFollow(e,userProfileByUserId.id,"follow",userProfileByUserId.isPrivate)}>Follow</a>
                      )}
                    </li>

                    </>
                    )}
                  </ul>
                </a>
              </div>
              )}
            </div>
          </div>
        </div>
        {currentPath ==="/MyProfile" && (
        <div className="setting-dropdown btn-group custom-dropdown arrow-none dropdown-sm">
          {user.id === props.userId && (
            <a
            className="btn-white btn-cover"
            href="/"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            edit cover
          </a>
          )}
          <div className="dropdown-menu dropdown-menu-right custom-dropdown">
            <ul>
              <li className="choose-file">
                <a onClick={ChooseImage} href="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon-font-light iw-16 ih-16"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>
                  upload photo
                </a>
              </li>
              {userProfileByUserId.isDefaultCover ? (
              <li>
                
                  <a onClick={toggleRemove} href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon-font-light iw-16 ih-16"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                  remove photo
                </a>
                
              </li>
              ):null}
            </ul>
          </div>
        </div>
        )}
      </div>
      <div className="d-lg-none d-block">
        <div className="profile-box">
          <div className="profile-content">
            <div className="image-section">
              <div className="profile-img">
                <div>
                  {/* <img
                    src="/assets/images/my-profile.jpg"
                    className="img-fluid bg-img"
                    alt="profile"
                  /> */}
                    <img
                    src={userProfileByUserId.profileImage}
                    className="img-fluid"
                    alt="verified"
                  />
                </div>
                {/* <span className="stats">
                  <img
                    src={userProfileByUserId.profileImage}
                    className="img-fluid"
                    alt="verified"
                  />
                </span> */}
              </div>
            </div>
            <div className="profile-detail">
              <a href="/">
                <h2>{userProfileByUserId.fullName}</h2>
              </a>
              <h5>Student</h5>
              <div className="description">
                <p>
                  {userProfileByUserId.userName}
                  <span> {userProfileByUserId.homeAddress}</span>
                </p>
              </div>
              <div className="counter-stats">
                <ul id="counter">
                  <li>
                    <h3 className="counter-value" data-count="546">
                      {userProfileByUserId.followingCount}
                    </h3>
                    <h5>following</h5>
                  </li>
                  <li>
                    <h3 className="counter-value" data-count="26335">
                      {userProfileByUserId.totalPostCount}
                    </h3>
                    <h5>post</h5>
                  </li>
                  <li>
                    <h3 className="counter-value" data-count="6845">
                      {userProfileByUserId.followersCount}
                    </h3>
                    <h5>followers</h5>
                  </li>
                </ul>
              </div>
                <a href="/" className="btn btn-solid">
                Edit profile
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Following_Modal_section">
        {followingOpen !== false && (
          <Modal
            scrollable
            isOpen={followingOpen}
            toggle={toggleFollowing}
            className="modal-dialog-centered"
          >
            <ModalBody>
              <div>
                <h2>Following ({userProfileByUserId.followingCount})</h2>
              </div>
              <div className="py-3">
                <div className="profile-search-blk w-100 ">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                    data-bs-toggle="modal"
                    data-bs-target="#peopleSearch"
                    value={followingSearch}
                    onChange={(e) => {
                      handleFollowingSearch(e);
                    }}
                  />
                </div>
              </div>
              <Row className="mt-2">
                {getUserFollowing?.rows?.map((data) => (
                  <div className="group-detail-page-blk">
                    <div className="Follower-memberslist-block">
                      <div className="group-member-img-cont-blk" onClick={(e)=>settingData(e,data.id)}>
                        <img
                          src={data.profileImage}
                          alt="img"
                          className="img-fluid"
                        />
                        <div className="member-cont-blk">
                          <h4>{data.fullName}</h4>
                          <p>{data.userName}</p>
                        </div>
                      </div>
                      <div className="Block-btns-blk">
                        <Button
                          outline
                          style={unfollowStyle}
                          onClick={(e)=>FollowUnFollow(e,data.id,"unfollow",data.isPrivate)}
                        >
                          Unfollow
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            </ModalBody>
          </Modal>
        )}
      </div>
      <div className="Following_Modal_section">
        {followersOpen !== false && (
          <Modal
            scrollable
            isOpen={followersOpen}
            toggle={toggleFollowers}
            className="modal-dialog-centered"
          >
            <ModalBody>
              <div>
                <h2>Followers ({userProfileByUserId.followersCount})</h2>
                {/* <p>
                  Awesome! You have {userProfileByUserId.followersCount}{" "}
                  Followers
                </p> */}
              </div>
              <div className="py-3">
                <div className="profile-search-blk w-100 ">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                    data-bs-toggle="modal"
                    data-bs-target="#peopleSearch"
                    value={followerSearch}
                    onChange={(e) => {
                      handleFollowerSearch(e);
                    }}
                  />
                </div>
              </div>
              <Row className="mt-2">
                <div className="group-detail-page-blk ">
                  {getUserFollowers?.rows?.map((data) => (
                    <div className="Follower-memberslist-block">
                      <div className="group-member-img-cont-blk" onClick={(e)=>settingData(e,data.id)}>
                        <img
                          src={data.profileImage}
                          alt="img"
                          className="img-fluid"
                        />
                        <div className="member-cont-blk">
                          <h5>{data.fullName}</h5>
                          <p>{data.userName}</p>
                          {/* <p>{data.joingData}</p> */}
                        </div>
                      </div>
                      <div className="Block-btns-blk">
                      {data.followBackStatus == "not Following Back" && data.followBackRequestStatus==null ? (
                          <Button
                            outline
                            style={followBackStyle}
                            onClick={(e)=>FollowUnFollow(e,data.id,"follow",data.isPrivate)}
                          >
                            Follow back
                          </Button>
                        ) : data.followBackStatus == "not Following Back" && data.followBackRequestStatus=="pending" ? (
                          <Button
                          outline
                          style={unfollowStyle}
                          onClick={(e)=>FollowUnFollow(e,data.id,"unfollow",data.isPrivate)}
                        >
                          Requested
                        </Button>
                        ) : (
                          <Button
                            outline
                            style={unfollowStyle}
                            onClick={(e)=>FollowUnFollow(e,data.id,"unfollow",data.isPrivate)}
                          >
                            Unfollow
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Row>
            </ModalBody>
          </Modal>
        )}
      </div>
      <div className="Remove_modal">
        {removeOpen !== false && (
          <Modal
            isOpen={removeOpen}
            toggle={toggleRemove}
            className="modal-dialog-centered"
          >
            <ModalHeader className="d-flex justify-content-center">
              <h2>Remove cover photo</h2>
            </ModalHeader>
            <ModalBody>
              <div className="text-center Remove_Modal_section">
                <p>
                  Are you sure that you want to remove your <br /> cover photo?
                </p>
              </div>
              <div className="mt-4 text-center">
                <Button onClick={toggleRemove} outline>
                  Cancel
                </Button>
                <Button
                  onClick={handleRemoveCover}
                  color="primary"
                  className="ml-2"
                >
                  Confirm
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>{" "}
      <div className="Choose_Photo_modal">
        {choose !== false && (
          <Modal
            isOpen={choose}
            toggle={ChooseImage}
            className="modal-dialog-centered"
          >
            <div className="w-100 border-bottom">
              <div className="mx-3 py-3  d-flex justify-content-between align-items-center">
                <div>
                  <h2>Select your display photo </h2>
                  <p className="pt-2">
                    Set your display photo from your device
                  </p>
                </div>
                <div
                  onClick={() => {
                    setChoose((preState) => !preState);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <X size={20} color="#6E6B7B" />
                </div>
              </div>
            </div>
            <ModalBody>
              <Row className="mt-3">
                <Col className="mb-4">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageSelect}
                  />
                  <Card
                    style={{ cursor: "pointer" }}
                    onClick={toggleSelectOpen}
                    className="Choose_Photo_section"
                  >
                  {loading &&(
                  <Spinner
                  animation='border'
                  role='status'
                  style={{
                      width: '100px',
                      height: '100px',
                      margin: 'auto',
                      display: 'block',
                      color: '#81C14B',
                      animationDuration: '1s'
                  }}
                  >
                      <span className='sr-only'>Loading...</span>
                  </Spinner>
                
                  )}
                    {selectedImage && (
                      <img
                        alt="Selected image"
                        src={`${process.env.REACT_APP_IMAGEURL}${selectedImage}`}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <div>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setChoose((preState) => !preState);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  color="primary"
                  outline
                  onClick={handleUpdateCover}
                >
                  Upload
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </div>
      <div className="Choose_Photo_modal">
        {chooseOpen !== false && (
          <Modal
            scrollable
            isOpen={chooseOpen}
            toggle={toggleChoose}
            className="modal-dialog-centered"
          >
            <div className="w-100 border-bottom">
              <div className="mx-3 py-3  d-flex justify-content-between align-items-center">
                <div>
                  <h2>Select your display photo </h2>
                  <p className="pt-2">
                    Set your display photo from your SocioMee account
                  </p>
                </div>
                <div onClick={toggleChoose} style={{ cursor: "pointer" }}>
                  <X size={20} color="#6E6B7B" />
                </div>
              </div>
            </div>
            <ModalBody>
              <Row className="mt-3">
                {allMediaForGallery.map((data) => (
                  <Col sm="4" className="mb-4" key={data.id}>
                    <>
                      <Card
                        style={{ cursor: "pointer" }}
                        onClick={() => handleImageClick(data)}
                        className="Choose_Photo_section"
                      >
                        <CardImg
                          alt="Card image cap"
                          src={data?.fileURL}
                          style={{
                            height: 100,
                          }}
                          width="100%"
                        />
                        <div className="Choose_check_top_right">
                          {selectedImage === data ? (
                            <CheckCircle color="#81C14B" />
                          ) : (
                            ""
                          )}
                        </div>
                      </Card>
                    </>
                  </Col>
                ))}
              </Row>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <div>
                <Button color="secondary" outline>
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  color="primary"
                  outline
                  onClick={updateCover}
                >
                  Upload photo
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
        {/* {userId !== user.id && (
          <ProfileMenu userId={userId}/>
        )} */}
      </div>
      <SetUpModal1/>
    </>
  );
};

export default ProfileCover;
