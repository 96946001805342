import React, { Fragment, useState } from 'react';
import { ArrowLeft, Calendar, XCircle } from 'react-feather';
import { Button, Col, Input, Row } from 'reactstrap';
import moment from 'moment';
import { Checkbox } from '@mui/material';

const SchedulePost = ({ activeTab, toggle, onScheduledPostDate }) => {
  const [dateDataArray, setDateDataArray] = useState([new Date()]);
  const [listItems, setListItems] = useState(1);

  const handleAddClick = () => {
    if (listItems < 6) {
      setListItems(listItems + 1);
      const newDate = new Date();
      setDateDataArray([...dateDataArray, newDate]);
    }
  };

  const handleDoneClick = () => {
    onScheduledPostDate(dateDataArray);
    toggle(`/modal/${activeTab - 5}`);
  };

  const handleDeleteClick = (index) => {
    if (listItems > 1) {
      const updatedDateDataArray = [...dateDataArray];
      updatedDateDataArray.splice(index, 1);
      setDateDataArray(updatedDateDataArray);
      setListItems(listItems - 1);
    }
  };

  const DateGetData = (val, index) => {
    const updatedDateDataArray = [...dateDataArray];
    updatedDateDataArray[index] = new Date(val.target.value);
    setDateDataArray(updatedDateDataArray);
  };

  return (
    <Fragment>
      <div className='border-bottom w-100'>
        <div className={`mx-3 py-3 FellingActivity_main_blk`}>
          <div onClick={() => toggle(`/modal/${activeTab - 5}`)} style={{ cursor: "pointer" }} className='Arrow_main_blk_section' >
            <ArrowLeft />
          </div>
          <div>
            <h3>Schedule your post</h3>
          </div>
          <div>
            <Button color="primary" onClick={handleDoneClick}>Done</Button>
          </div>
        </div>
      </div>
      <div className='mx-3 py-3'>
        <Row>
          <Col>
            <div className='Schedule_post_blk_section'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className="profile-cover profile-cover-new d-flex align-items-center">
                  <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
                    <ul>
                      <li className="choose-file" >
                        <a className='UploadBtn' href="">
                          <Calendar color='#81C14B' size={20} onChange={(val) => DateGetData(val, 0)} />
                        </a>
                        <input type="datetime-local" value={dateDataArray[0]} onChange={(val) => DateGetData(val, 0)} />
                      </li>
                    </ul>
                  </a>
                  <h3 className='ml-3'> {moment(dateDataArray[0]).format('DD MMM YYYY hh:mm:ss a')}</h3>
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(0)}>
                  <XCircle color='#C4C4C4' />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ul>
              {[...Array(listItems)].map((item, index) => {
                if (index > 0 && index < 6) {
                  return (
                    <li key={index}>
                      <div className='Schedule_post_blk_section mt-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className="profile-cover profile-cover-new d-flex align-items-center">
                            <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
                              <ul>
                                <li className="choose-file">
                                  <a className='UploadBtn' href="">
                                    <Calendar color='#81C14B' size={20} />
                                  </a>
                                  <input type="datetime-local" value={dateDataArray[index + 1]} onChange={(val) => DateGetData(val, index + 1)} />
                                </li>
                              </ul>
                            </a>
                            <h3 className='ml-3'> {moment(dateDataArray[index + 1]).format('DD MMM YYYY hh:mm:ss a')}</h3>
                          </div>
                          <div style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(index + 1)}>
                            <XCircle color='#C4C4C4' />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='my-2 d-flex align-items-center'>
                <Checkbox />&nbsp;<h5>Post Now</h5>
              </div>
              {listItems < 6 && (
                <b className='text-primary' onClick={handleAddClick} style={{ cursor: "pointer" }}>+ Add more</b>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default SchedulePost;







// import { Checkbox } from '@mui/material';
// import moment from 'moment/moment';
// import React, { Fragment, useState } from 'react'
// import { ArrowLeft, Calendar, XCircle } from 'react-feather'
// import { Button, Col, Input, Row } from 'reactstrap'

// const SchedulePost = ({ activeTab, toggle, onScheduledPostDate }) => {
//   const [listItems, setListItems] = useState([]);
//   const date = new Date();
//   const [dateDataArray, setDateDataArray] = useState(date);

//   const handleAddClick = () => {
//     console.log("hit 1")
//     setListItems([...listItems, listItems.length]);
//     console.log("listItems",listItems);
//     setDateDataArray([...dateDataArray, "2023-04-08T15:11"]);
//     console.log("dateDataArray",dateDataArray);
//   };

//   const handleDoneClick = () => {
//     onScheduledPostDate(dateDataArray);
//     toggle(`/modal/${99}`);
//   };

//   const toggleTheFile = (e) => {
//     document.querySelector(".InputFile").click();
//   };

//   const DateGetData = (val, index) => {
//     const updatedDateDataArray = [...dateDataArray];
//     updatedDateDataArray[index] = val.target.value;
//     setDateDataArray(updatedDateDataArray);

//     // Call the onScheduledPostDate callback with the selected date
//     onScheduledPostDate(updatedDateDataArray[index]);
//   };

//   return (
//     <Fragment>
//       <div className='border-bottom w-100'>
//         <div className={`mx-3 py-3 FellingActivity_main_blk`}>
//           <div onClick={() => toggle(`/modal/${99}`)} style={{ cursor: "pointer" }} className='Arrow_main_blk_section' >
//             <ArrowLeft />
//           </div>
//           <div>
//             <h3>Schedule your post</h3>
//           </div>
//           <div>
//             <Button color="primary" onClick={handleDoneClick}>Done</Button>
//           </div>
//         </div>
//       </div>
//       <div className='mx-3 py-3'>
//         <Row>
//           <Col>
//             <div className='Schedule_post_blk_section'>
//               <div className='d-flex justify-content-between align-items-center'>
//                 <div className="profile-cover profile-cover-new d-flex align-items-center">
//                   <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
//                     <ul>
//                       <li className="choose-file" >
//                         <a className='UploadBtn' href="">
//                           <Calendar color='#81C14B' size={20} onChange={(val) => DateGetData(val, 0)} />
//                         </a>
//                         <input type="datetime-local" value={dateDataArray[0]} onChange={(val) => DateGetData(val, 0)} />
//                       </li>
//                     </ul>
//                   </a>
//                   <h3 className='ml-3'> {moment(dateDataArray[0]).format('DD MMM YYYY hh:mm:ss a')}</h3>
//                 </div>
//                 <div style={{ cursor: "pointer" }} onClick={() => toggle(`/modal/${activeTab - 5}`)}>
//                   <XCircle color='#C4C4C4' />
//                 </div>
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <Row>
//           <Col sm={12} >
//             <ul>
//               {[...Array(listItems)].map((item, index) => {
//                 if (index < 5)
//                   return (
//                     <li key={index}>
//                       <div className='Schedule_post_blk_section mt-3'>
//                         <div className='d-flex justify-content-between align-items-center'>
//                           <div className="profile-cover profile-cover-new d-flex align-items-center">
//                             <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
//                               <ul>
//                                 <li className="choose-file">
//                                   <a className='UploadBtn' href="">
//                                     <Calendar color='#81C14B' size={20} />
//                                   </a>
//                                   <input type="datetime-local" value={dateDataArray[index + 1]} onChange={(val) => DateGetData(val, index + 1)} />
//                                 </li>
//                               </ul>
//                             </a>
//                             <h3 className='ml-3'> {moment(dateDataArray[index + 1]).format('DD MMM YYYY hh:mm:ss a')}</h3>
//                           </div>
//                           <div style={{ cursor: "pointer" }} onClick={() => { setListItems(listItems - 1) }}>
//                             <XCircle color='#C4C4C4' />
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   )
//               })}
//             </ul>
//           </Col>
//         </Row>
//         <Row>
//           <Col sm={12}>
//             <div className='d-flex align-items-center justify-content-between'>
//               <div className='my-2 d-flex align-items-center'>
//                 <Checkbox />&nbsp;<h5>Post Now</h5>
//               </div>
//               <b className='text-primary' onClick={()=>{handleAddClick()}} style={{ cursor: "pointer" }}>+ Add more</b>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </Fragment>
//   )
// }

// export default SchedulePost;



// import { Checkbox } from '@mui/material';
// import moment from 'moment/moment';
// import React, { Fragment, useState } from 'react'
// import { ArrowLeft, Calendar, XCircle } from 'react-feather'
// import { Col, Input, Row } from 'reactstrap'

// const SchedulePost = ({ activeTab, toggle }) => {
//     const [listItems, setListItems] = useState(0);

//     const date = new Date();

//     const handleAddClick = (id) => {

//         setListItems([...listItems, id]);
//     };

//     const toggleTheFile = (e) => {
//         document.querySelector(".InputFile").click()
//     }
//     const [dateData, setDateData] = useState(date)
//     const DateGetData = (val) => {
//         console.warn(val.target.value)
//         setDateData(val.target.value)
//     }
//     //============== Main_return_function =======================//
//     return (
//         <Fragment>
//             <div className='border-bottom w-100'>
//                 <div className={`mx-3 py-3  FellingActivity_main_blk `}>
//                     <div onClick={() => toggle(`/modal/${activeTab - 5}`)} style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle' >
//                         <ArrowLeft />
//                     </div>
//                     <div>
//                         <h3>Schedule your post</h3>
//                     </div>
//                     <div></div>
//                 </div>
//             </div>
//             <div className='mx-3 py-3'>
//                 <Row>
//                     <Col>
//                         <div className='Schedule_post_blk_section'>
//                             <div className='d-flex justify-content-between align-items-center'>
//                                 <div className="profile-cover profile-cover-new d-flex align-items-center ">
//                                     <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
//                                         <ul>
//                                             <li className="choose-file" >
//                                                 <a className='UploadBtn' href="">
//                                                     <Calendar color='#81C14B' size={20} onChange={DateGetData} />
//                                                 </a>
//                                                 <input type="datetime-local"
//                                                 />
//                                             </li>
//                                         </ul>
//                                     </a>
//                                     <h3 className='ml-3'> {moment(dateData).format('DD MMM YYYY hh:mm:ss a')}</h3>
//                                 </div>
//                                 <div style={{ cursor: "pointer" }} onClick={() => toggle(`/modal/${activeTab - 5}`)}>
//                                     <XCircle color='#C4C4C4' />
//                                 </div>
//                             </div>
//                         </div>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col sm={12} >
//                         <ul>
//                             {[...Array(listItems)].map((item, index) => {
//                                 if (index < 5)
//                                     return (
//                                         <li key={index}>
//                                             <div className='Schedule_post_blk_section mt-3'>
//                                                 <div className='d-flex justify-content-between align-items-center'>
//                                                     <div className="profile-cover profile-cover-new d-flex align-items-center " onChange={DateGetData}>
//                                                         <a href="#" className=" btn-EventUpload btn-group btn-grp-new">
//                                                             <ul>
//                                                                 <li className="choose-file">
//                                                                     <a className='UploadBtn' href="">
//                                                                         <Calendar color='#81C14B' size={20} />
//                                                                     </a>
//                                                                     <input type="datetime-local"
//                                                                     />
//                                                                 </li>
//                                                             </ul>
//                                                         </a>
//                                                         <h3 className='ml-3'> {moment(dateData).format('DD MMM YYYY hh:mm:ss a')}</h3>
//                                                     </div>
//                                                     <div style={{ cursor: "pointer" }} onClick={() => { setListItems(listItems - 1) }}>
//                                                         <XCircle color='#C4C4C4' />
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     )
//                             })}
//                         </ul>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col sm={12}>
//                         <div className='d-flex align-items-center justify-content-between'>
//                             <div className='my-2 d-flex align-items-center'>
//                                 <Checkbox />&nbsp;<h5>Post Now</h5>
//                             </div>
//                             <b className='text-primary' onClick={() => { setListItems(listItems + 1) }} style={{ cursor: "pointer" }}>+ Add more</b>
//                         </div>
//                     </Col>
//                 </Row>
//             </div>
//         </Fragment>
//     )
// }

// export default SchedulePost