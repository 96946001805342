import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Bookmark, Globe, Link2, Lock, MoreVertical, Share2, XCircle } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Button, Card, Input, Modal, ModalBody, ModalHeader, } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sweetAlertConfirmation } from './SweetAlert';
import { SweetErrorAlert } from './SweetErrorAlert';

import { loadAllSuggestedGroup, toJoinSuggestedGroupAction, unFollowFromJoinedGroupAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';

//Slider_Settings_BreakPoint
let ownedGroupSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};

const GroupInvitationSection = () => {
    const dispatch = useDispatch()
    const { getAllSuggestedGroup } = useSelector((state) => { return state.getAllGroupSuggestedGroupData});
   console.log('All suggested Group', getAllSuggestedGroup)
   
    const { toJoinSuggestedGroup, error } = useSelector((state) => { return state.postToJoinSuggestedGroupData });

    console.log('All suggested Group', toJoinSuggestedGroup)


    useEffect(() => {
        dispatch(loadAllSuggestedGroup())
    }, [])

    const [reportOpen, setReportOpen] = useState(false)
    const [joinAlert, setJoinAlert] = useState(false)
    
    let [copyLink, setCopyLink] = useState('')

    const postCopyLink = async (id) => {
        copyLink = `${process.env.REACT_APP_IPURL}/Group/${id}`
        setCopyLink(copyLink)
        (sweetAlertConfirmation('Link Copied'))
      };

    const toggleReport = () => {
        setReportOpen((preview) => !preview)
    }
    const [joinRequest, setJoinRequest] = useState(true)
    const toggleJoin = () => {
        setJoinRequest(!joinRequest)
    }
    //-------------------------------Num_Formatter_Function
    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }


useEffect(() => {
if(toJoinSuggestedGroup){
    setJoinAlert(true)
}
},[toJoinSuggestedGroup])

    useEffect(() => {    
        if(joinAlert)
            {
                sweetAlertConfirmation('Requested to suggested group')    
                setJoinAlert(false)            
            }
            
    }, [joinAlert])

    useEffect(() => {
        if(error){
            SweetErrorAlert('ERROR! Session Expired')
        }
        },[error])

        const unFollow = (id) => {
            alert(id)
            dispatch(unFollowFromJoinedGroupAction(id))
            SweetErrorAlert('Please check the backend API')
        }

    const joinSuggestedGroup = (groupId) => {
        dispatch(toJoinSuggestedGroupAction(groupId))
      };


    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    // ================== Main_Function ==================//
    return (
        <Fragment>
            {getAllSuggestedGroup && getAllSuggestedGroup.data.successResult.length >0 ? 
                        <div className="group-custom-block">
                        <div className="heading-podcast-blk">
                            <h3>Suggested Group ({getAllSuggestedGroup && getAllSuggestedGroup.data.successResult.length})</h3>
                            {getAllSuggestedGroup && getAllSuggestedGroup.data.successResult.length >4 ?
                        <NavLink to="/Suggested" className="single-ancor-blk"> See All → </NavLink> :
                        <NavLink to="" className="disabled"> See All → </NavLink>
                        }                        
                        </div>
                        <div className="heading-podcast-blk">
                            <p>Find a groups you might be interested in.</p>
                        </div>
                        <Slider {...ownedGroupSettings} className="default-space">
                            {
                                getAllSuggestedGroup && getAllSuggestedGroup.data.successResult.map((data, index) => (
                                    <div>
                                        {data.privacy === "public" ?
                                            <Card className="group-slide-box">
                                                <div className="gallery-img-sublink">
                                                    <XCircle color="#FFFFFF" style={{cursor:'pointer'}} size={21} 
                                                     onClick={() => { unFollow(data?.id) }}
                                                    />
                                                </div>
                                                {/* <div className='gallery_menu_sublink'>
                                                    <MoreVertical color="#FFFFFF" size={21} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                        <ul>
                                                            <li>
                                                                <Link ><Share2 size={15} color="#4D4D4D" /> Share Group</Link>
                                                            </li>
                                                            <li onClick={() => {postCopyLink(data?.id)}}>
                                                            <Link to="#"> <Link2 size={15} color="#4D4D4D" /> Copy Link </Link>
                                                            </li>
                                                            <li onClick={toggleReport}>
                                                                <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                                                {/* <NavLink to={`/GroupDetail/${data.id}/${data.groupName}/${data.totalMembers}/${index}`}><img alt='Profile Pic' src={`${data.coverPicUrl}` ? `${data.coverPicUrl}` : '/assets/images/group-img-1.jpg'} className="img-fluid group-img" /></NavLink> */}
                                                <NavLink to={`/GroupDetail/${data.id}/${data.groupName}/${data.totalMembers}/${index}`}>
                                                <img 
                                                    alt='Profile Pic' 
                                                    src={data.coverPicUrl} 
                                                    className="img-fluid group-img"
                                                    onError={(e) => {e.target.onerror = null; e.target.src=`${process.env.REACT_APP_IMAGEURL}/test/bxlJIIWSZ2SOk.jpg`}}
                                                />
                                                </NavLink>
                                                <div className="group-content">
                                                <h4 className='text-capitalize'>
                                                    {data.name.substring(0, 20)} 
                                                    {data.name.length >= 15 && ' ...'}
                                                </h4>  
                                                    <div className="Suggestmember-blk">
        
                                                        {/* {data.totalMembers >0 ? <AvatarGroup> {data.totalMembers} </AvatarGroup> : <p>Be a member</p> }  */}
                                                        <span className='text-capitalize'><Globe style={{marginTop:'-2px'}}
                                                         color='#4D4D4D' size={12} /> {data.privacy ? data.privacy :'Private'}</span>&nbsp; • &nbsp;
                                                        <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                            }
                                                        </span>
                                                        {/* <span>{numFormatter(data.totalMembers > 0 ? <AvatarGroup> {data.totalMembers} members </AvatarGroup> : <p>Be a member</p>)}</span> */}
                                                    </div>
                                                    <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                        <p>{data.paragraph}</p>
                                                    </div>
                                                    <div className=" mt-3">
                                                            {data?.isRequested === 0 ?
                                                             <Button className="Group_Join_btn" color='primary' onClick={() => {joinSuggestedGroup(data.id)}}>
                                                                Join Group
                                                            </Button> 
                                                            :
                                                            <Button className="Group_Join_btn" style={{backgroundColor:'#ffc107', color:'black', border:'0px'}}>
                                                                Approval Pending
                                                            </Button>

                                                            }
                                                    </div>
                                                </div>
                                            </Card> :
                                            <Card className="group-slide-box" key={index}>
                                                <div className="gallery-img-sublink">
                                                    <XCircle color="#FFFFFF" style={{cursor:'pointer'}} size={21} 
                                                     onClick={() => { unFollow(data?.id) }}
                                                    />
                                                </div>
                                                {/* <div className='gallery_menu_sublink'>
                                                    <MoreVertical color="#FFFFFF" size={21} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                        <ul>
                                                            <li>
                                                                <Link to="#"><Share2 size={15} color="#4D4D4D" /> Share Group</Link>
                                                            </li>
                                                            <li onClick={() => {postCopyLink(data?.id)}}>
                                                                <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                            </li>
                                                            <li onClick={toggleReport}>
                                                                <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                                                {/* src={data.coverPicUrl} onError={('/assets/images/group-img-1.jpg')} */
                                                /* onError={('/assets/images/group-img-1.jpg')} */}
        
                                                    <NavLink to="/GroupPending">
                                                        <img 
                                                            alt='Profile Pic' 
                                                            src={data.coverPicUrl} 
                                                            className="img-fluid group-img"
                                                            onError={(e) => {e.target.onerror = null; e.target.src=`${process.env.REACT_APP_IMAGEURL}/test/bxlJIIWSZ2SOk.jpg`}}
                                                        />
                                                     </NavLink>
                                                
        
                                                <div className="group-content">
                                                    <h4 className='text-capitalize'>{data.name}</h4>
                                                    <div className="Suggestmember-blk">
                                                        <span className='text-capitalize'><Lock style={{marginTop:'-2px'}} color='#4D4D4D' size={12} />&nbsp;{data.privacy ? data.privacy :'Private'} &nbsp;</span>
                                                         • &nbsp;
                                                        <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                        <p>{data.paragraph}</p>
                                                    </div>
                                                    <div className=" mt-3">
                                                        {joinRequest ?
                                                            <div className=" mt-3">
                                                                <Button id="1" onClick={toggleJoin} className="Group_Join_btn" color='primary'>Join Group</Button>
                                                            </div>
                                                            : (
                                                                <div className=" mt-3">
                                                                    <NavLink id="2" to="#"><Button onClick={toggleJoin} className="w-100" color='warning'>Approval Pending</Button></NavLink>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Card>
                                        }
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
            : ''}

            <div >
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered' >
                        <ModalHeader className="modal_Header">
                            <div className='Report_modal_Heading_section'>
                                <h4>Report a page</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button color='primary' className="Report_summit_btn">Submit</Button>
                                <Button className="Report_cancel_btn ml-2" outline>Cancel</Button>
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>

        </Fragment>
    )
}

export default GroupInvitationSection