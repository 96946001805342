import React, { Fragment } from 'react'
import { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Ads from './Ads'
import FollowingFollowers from './FollowingFollowers'
import LiveVideo from './LiveVideo'
import Message from './Message'
import PostStoriesComment from './PostStoriesComment'






const NotificationTabSection = (props) => {
    // const [notificatioActive, setNotificatioActive] = useState('2')
    const toggleNotification = tab => {
        props.setNotificatioActive(tab)
    }

    const handleEventsNameClick = (tabId) => {
        props.setNotificatioActive(tabId);
        props.setsetsideclassoption1(true);
    };



    const SettingsArray2 = [
        {

            eventsName: "   Post, Stories and Comments  "
        },
        {

            eventsName: " Following and Followers "
        },
        {

            eventsName: "  Messages "
        },
        {

            eventsName: " Live and Videos  "
        },
        {

            eventsName: "   Ads"
        },


    ]
    return (
        <Fragment>
            
                <Nav tabs className='pt-3 hide-setting-options'>
                    <NavItem>
                        <NavLink
                            active={props.notificatioActive === '1'}
                            onClick={() => {
                                toggleNotification('1')
                            }}
                        >
                            Post, Stories and Comments
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={props.notificatioActive === '2'}
                            onClick={() => {
                                toggleNotification('2')
                            }}
                        >
                            Following and Followers
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={props.notificatioActive === '3'}
                            onClick={() => {
                                toggleNotification('3')
                            }}
                        >
                            Messages
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={props.notificatioActive === '4'}
                            onClick={() => {
                                toggleNotification('4')
                            }}
                        >
                            Live and Videos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={props.notificatioActive === '5'}
                            onClick={() => {
                                toggleNotification('5')
                            }}
                        >
                            Ads
                        </NavLink>
                    </NavItem>
                </Nav>

                <div>

                    <div className='Categories_LeftPannel_slider1 hide-setting-options1'>
                        <div className="Categories_Left_block">
                            <div className="" id='Categories-Scroll-1'>
                                <div className="biz-about-cont">
                                    <ul>
                                        {
                                            SettingsArray2.map((data, index) => (
                                                <li key={index}
                                                    //  className={props.setsideclassoption1 ? 'SettingForSidestyle leftSection hide-setting-options' : 'SettingForSidestyle leftSection'}
                                                    className={`${
                                                        props.setsideclassoption1
                                                            ? 'SettingForSidestyle1 hide-setting-options'
                                                            : 'SettingForSidestyle1'
                                                    } ${props.notificatioActive === (index + 1).toString() ? 'active' : ''}`}
                                                    setsetsideclassoption1={props.setsetsideclassoption1}
                                                >
                                                    <div className='nave-items-bizPage d-flex' onClick={() => { handleEventsNameClick((index + 1).toString()); props.setsetsideclassoption1(true); props.setSettingsText(data.eventsName); }}>
                                                        {/* <img src={data.icon} width={31} height={31} /> */}
                                                        <div className='ml-3 EventsCategories_All_events'>
                                                            <h5>{data.eventsName}</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <TabContent className={!props.setsideclassoption1 && 'hide-setting-options p-2'} activeTab={props.notificatioActive}>
                    <div onClick={() => { props.setsetsideclassoption1(false); props.setSettingsText("Notifications") }} className='hide-setting-options1 backarbut1'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.6195 2.99028C16.5034 2.87387 16.3655 2.78152 16.2136 2.7185C16.0617 2.65548 15.8989 2.62305 15.7345 2.62305C15.5701 2.62305 15.4073 2.65548 15.2554 2.7185C15.1036 2.78152 14.9656 2.87387 14.8495 2.99028L6.53951 11.3003C6.44681 11.3928 6.37326 11.5027 6.32308 11.6237C6.2729 11.7446 6.24707 11.8743 6.24707 12.0053C6.24707 12.1362 6.2729 12.2659 6.32308 12.3869C6.37326 12.5079 6.44681 12.6178 6.53951 12.7103L14.8495 21.0203C15.3395 21.5103 16.1295 21.5103 16.6195 21.0203C17.1095 20.5303 17.1095 19.7403 16.6195 19.2503L9.37951 12.0003L16.6295 4.75028C17.1095 4.27028 17.1095 3.47028 16.6195 2.99028Z" fill="black" />
                    </svg></div>
                    <TabPane tabId='1'>
                        <PostStoriesComment setSettingsText={props.setSettingsText}  setNotificatioActive={props.setNotificatioActive} notificatioActive={props.notificatioActive} />
                    </TabPane>
                    <TabPane tabId='2'>
                        <FollowingFollowers setSettingsText={props.setSettingsText} setNotificatioActive={props.setNotificatioActive} notificatioActive={props.notificatioActive} />
                    </TabPane>
                    <TabPane tabId='3'>
                        <Message />
                    </TabPane>
                    <TabPane tabId='4'>
                        <LiveVideo />
                    </TabPane>
                    <TabPane tabId='5'>
                        <Ads />
                    </TabPane>
                </TabContent>
            

        </Fragment>
    )
}

export default NotificationTabSection