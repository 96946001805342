import { CleaningServices } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MoreVertical } from 'react-feather';

const Weather = () => {
    const [weather, setWeather] = useState('');

    const [currentTime, setCurrentTime] = useState(new Date());

  
    // this is used for get current location and weather details
    useEffect(() => {
        // Get user's geolocation using browser's built-in geolocation API
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                // Make API request to weather API using the obtained latitude and longitude
                axios.get(`https://fcc-weather-api.glitch.me/api/current?lat=${latitude}&lon=${longitude}`)
                    .then(response => {
                        setWeather(response.data);
                        // console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            (error) => {
                console.log(error);
            }
            
        );
        // Update current time every second
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="weather-section section-b-space groupBottom-custom-block">
                <div className="card-title light-version">
                    <h3 style={{ fontSize: "18px", fontWeight: "400", color: " #FFFFFF" }}>weather</h3>
                    <div className="settings">
                        {/* <div className="setting-btn light-btn">
                            <a href="#" className="d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-light strokeWidth-3 iw-11 ih-11"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                            </a>
                        </div> */}
                        <div className="setting-btn light-btn ms-2 setting-dropdown">
                            <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                <a className="d-flex" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <MoreVertical color='#fff' size={12} />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                    <ul>
                                        <li>
                                            <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>change city</a>
                                        </li>
                                        <li>
                                            <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>setting</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="weather-content">
                    <div className="top-title">
                        <h2>{weather?.main?.temp}°C</h2>
                        <h5>{currentTime.getHours()}:{currentTime.getMinutes()}</h5>
                    </div>
                    <div style={{display:'flex', gap:"6px"}}>
                    <img src={weather?.weather?.[0]?.icon} alt="" />
                    <h5 style={{
                        fontSize: "14px",
                        fontWeight: "400"
                    }}>{weather?.weather?.[0]?.description}</h5>
                    </div>
                   
                    <h5 style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        marginLeft:"5px"
                    }}><span>{new Date().getDate()}-{new Date().getMonth()}-{new Date().getFullYear()} </span><span> &nbsp; {weather?.name}</span></h5>
                </div>
                <div className="flaks-img">
                    <img src="/assets/images/snow-flaks.png" className="img-fluid"
                        alt="snow" />
                </div>
                <div className="snowflakes" aria-hidden="true">
                    <div className="snowflake">
                        ❅
                    </div>
                    <div className="snowflake">
                        ❆
                    </div>
                    <div className="snowflake">
                        ❅
                    </div>
                    <div className="snowflake">
                        ❆
                    </div>
                    <div className="snowflake">
                        ❅
                    </div>
                    <div className="snowflake">
                        ❆
                    </div>
                    <div className="snowflake">
                        ❅
                    </div>
                    <div className="snowflake">
                        ❆
                    </div>
                    <div className="snowflake">
                        ❅
                    </div>
                    <div className="snowflake">
                        ❆
                    </div>
                    <div className="snowflake">
                        ❅
                    </div>
                    <div className="snowflake">
                        ❆
                    </div>
                </div>
            </div>
        </>
    );
}
export default Weather 