import * as types from "../../Constants/Events/index";
import axios from 'axios';


const setEventData = (event) => ({
    type: types.GET_EVENTS_BY_USER,
    payload: event
});



export const getUserEventsAction = ({searchKey, pageIndex, pageSize }) => {
    return dispatch => {
        let users = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        //console.log(users)
        if (users?.token) {
            axios.post(`${process.env.REACT_APP_IPURL}/post/getAllEventByUserId`, {
                searchKey, pageIndex, pageSize 
            }, {
                headers: { Authorization: `Bearer ${users?.token}` }
            })
                .then((result) => {
                   // console.log("result",result)
                    dispatch(setEventData(result.data.data.successResult.rows ? result.data.data.successResult.rows : result.data.data.successResult));
                }).catch((err) => {
                    console.log(err)
                });
        }
        
    }
}

//ONGOING EVENT

const getOnGoingEvent = (onGoingEvents) => ({
    type: types.GET_ONGOING_EVENTS_BY_USER,
    payload: onGoingEvents
});



export const getUserOnGoingEventsAction = ({eventId, type, userId, lat, long, radius, pageIndex, pageSize, location}) => {
    return dispatch => {
        let users = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        //console.log(users)
        if (users?.token) {
            axios.post(`${process.env.REACT_APP_IPURL}/post/getEventUserList`, {
                eventId, type, userId, lat, long, radius, pageIndex, pageSize, location
            }, {
                headers: { Authorization: `Bearer ${users?.token}` }
            })
                .then((result) => {
                   // console.log("ongoresult",result)
                    dispatch(getOnGoingEvent(result.data.data.successResult.result));
                }).catch((err) => {
                    console.log(err)
                });
        }
        
    }
}


//INTRESTED EVENTS
const getIntrestedEvent = (userIntrests) => ({
    type: types.GET_INTRESTED_EVENTS_BY_USER,
    payload: userIntrests
});



export const getUserIntrestedEventsAction = ({id}) => {
    console.log('======================', id)
    return dispatch => {
        let users = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        //console.log(users)
        if (users?.token) {
            axios.post(`${process.env.REACT_APP_IPURL}/post/getEventById`, {
              id,
            }, {
                headers: { Authorization: `Bearer ${users?.token}` }
            })
                .then((result) => {
                   console.log("ongoresult",result)
                    dispatch(getIntrestedEvent(result.data.data.successResult));
                }).catch((err) => {
                    console.log(err)
                });
        }
        
    }
}





const allInviteFriends = (inviteFriends) => ({
    type: types.INVITE_FRIENDS_BY_USER,
    payload: inviteFriends
});

export const allInvitedFriendsListAction = ({inviteTo, eventId}) => {
    return  (dispatch) => {
        let users = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));

        if (users?.token) {
            axios.post(`${process.env.REACT_APP_IPURL}/post/inviteFriendsToEvent`, {
                inviteTo, eventId
            }, {
                headers: { Authorization: `Bearer ${users?.token}` }
            })
                .then((result) => {
                   // console.log("ongoresult",result)
                    dispatch(allInviteFriends(result.data.data.successResult));
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
}