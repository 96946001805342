import { blue } from '@mui/material/colors'
import React, { Fragment, useRef, useState } from 'react'
import { AlertTriangle, ArrowDownCircle, ChevronRight, Circle, Copy, CornerUpLeft, Download, Edit, Edit2, Eye, Headphones, MoreVertical, Play, PlayCircle, Plus, Repeat, Send, Trash, Trash2, User } from 'react-feather';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Badge, Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, NavLink, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { useUserAuth } from '../../../Context/userAuthContext';
import Mic from '../../../NewComponents/IMG/Mic.png'
import MicHover from '../../../NewComponents/IMG/podcastIcon.svg'
import Rect1 from '../../Story/Images/Rect1.png'
import purched from '../../Story/Images/purched.png'
import PaidTag from '../../../NewComponents/IMG/PaidTag.svg'
import Sliders from "react-slick";
import { useSelector } from 'react-redux';
import podcast from '../../Story/Images/podcast.png'
import { Box, IconButton, Stack } from '@mui/material';
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';
const PodcastNavbar = (props) => {
    const PodcastNavDataText = [
        {
            id: "1",
            NavName: "Single Podcast (25)"
        },
        {
            id: "2",
            NavName: "Series Podcast (75)"
        },
    ];
    const [isCardOpen, setIsCardOpen] = useState(false);

    const handleButtonClick = () => {
        setIsCardOpen(!isCardOpen); // Toggle the state of isCardOpen
    };
    const cardData1 = [
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'purched',
            podcastType: 'Series',
            seires: true
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '01',
            episodType: 'Unpaid',
            podcastType: 'Single',
            seires: false
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'purched',
            podcastType: 'Series',
            seires: true
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '01',
            episodType: 'Unpaid',
            podcastType: 'Single',
            seires: false
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'purched',
            podcastType: 'Series',
            seires: true
        }
    ]
    const { listView, setListView, setMusicBar, musicBar } = useUserAuth();
    // count abbreviation function
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(1).toString()} K`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(1).toString()} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    /*  ---------------------- States --------------------  */
    // const [style, setStyle] = useState({ display: 'none' });
    const [isPlaying, setIsPlaying] = useState(false);
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [comment, SetComment] = useState(false)
    const [deletepodcast, setdeletepodcast] = useState(false)
    const [downloadpodcast, setdownloadpodcast] = useState(false);


    /*  ---------------------- Function for deleting podcast --------------------  */
    const toggledeletepodcast = () => {
        setdeletepodcast((prevState) => !prevState)
    }

    {/* ----------------------------State and function for Report Modal------------------------------------------ */ }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    /*  ---------------------- Function & state for sharing podcast --------------------  */
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    /*  ---------------------- Function & state for comment modal of podcast --------------------  */
    const [Commentopen, setcommentclose] = useState(false)
    const toggleComment = () => {
        setcommentclose((preState) => !preState)
    }

    /*---------------------- Function for toggling of podcast ------------------------*/
    const toggledownloadpodcast = () => {
        setdownloadpodcast((prevState) => !prevState)
    }
    let FriendSuggestsettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const [hover, sethover] = useState(0)
    const [hover1, sethover1] = useState(0)
    const [hover2, sethover2] = useState(0)
    const [hover3, sethover3] = useState(0)
    const handleMusicBar = () => {
        setMusicBar(true);
    }
    const musicArray = [
        {
            id: 1,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 2,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 3,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 4,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 5,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 6,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 7,
            Live: false,
            publisher: 'john wick'
        }
    ]
    let TopPodcastSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    const PodcastTotalList = [
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "paid"

        },
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "Upaid"

        },
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "Unpaid"

        },
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "paid"

        }

    ]
    const [sharedOpen, setsharedOpen] = useState(false)
    const toggleShared = () => {
        setsharedOpen((preState) => !preState)
    }
    const handlePlay = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play();
            setPaused(false);
        } else {
            audio.pause();
            setPaused(true);
        }
    };
    const handleFastForward = () => {
        const audio = audioRef.current;
        audio.currentTime += 10; // Fast forward by 10 seconds (adjust as needed)
    };
    const theme = useTheme();
    const mainIconColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF';
    const getPodCastData = useSelector((state) => { return state.getTextPostsData });
    const { loading: loadingAllTextPosts, error: errorAllTextPosts, result: resultAllTextPosts } = getPodCastData
    const [isDivOpen, setIsDivOpen] = useState(false);

    const handleImageClick = () => {
        setIsDivOpen(!isDivOpen); // Toggle the state of isDivOpen
    };
    const [paused, setPaused] = useState(false);
    const audioRef = useRef(null);
    const [volume, setVolume] = useState(1);
    const [selectActive, setSelectActive] = useState(false)
    const AllTypesPodcast = (props) => (
        <Fragment>
            <CardTitle className={props.className}>
                <h4>{props.title}</h4>
                <p className='d-flex align-items-center '
                    onClick={() => setSelectActive(!selectActive)}>{props.nav}<ChevronRight size={16} /></p>
            </CardTitle>
            <div className="podcast-list-block">
                <Sliders {...TopPodcastSettings} className="default-space">
                    {
                        resultAllTextPosts?.map((data) => (
                            <div>
                                <Card className="TopPodcast_Section">
                                    <img src={data?.podcastDetails?.coverImageURL} className="img-fluid " alt="podCast" style={{ height: '175px' }} />
                                    <div className='TopPodcat_Left_Side'>
                                        {/* <img src={MicImg} width={18} height={19} alt="mic_img" /> */}
                                        <p className='ml-1'>{numFormatter(data?.podcastDetails?.listenerCount)}+++</p>
                                    </div>
                                    <div className='TopPodcat_Left_right'>
                                        <h4>{data?.podcastDetails?.totalEpisode}</h4>
                                    </div>
                                    <div className='TopPodcat_bottom_left'>
                                        <h4>{data?.podcastDetails?.title}</h4>
                                    </div>
                                    {data?.podcastDetails?.isPaid === "paid" ? <>
                                        <div className='TopPodcast_bottom_right'>
                                            <img src={data?.podcastDetails?.mediaURL} alt="img" className='img-fluid' width={70} height={22} />
                                        </div></> :
                                        <>
                                        </>
                                    }
                                </Card>
                            </div>
                        ))
                    }
                </Sliders>
            </div>
        </Fragment>
    )
    return (
        <>
            <div className="profile-menu pod-nav">
                <div className='d-flex justify-content-between w-100 align-items-center'>
                    <ul className='text-white rem-mob-view'>
                        {PodcastNavDataText.map((item, index) => (
                            <li key={index}>
                                <Link to='' style={{ backgroundColor: 'white', border: '1px solid #81C14B' }}>
                                    <h5 style={{ color: '#81C14B', fontSize: '14px', fontWeight: '600' }}>{item.NavName}</h5>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link to=''>
                        <Button
                            color="primary"
                            style={{ color: 'white', fontSize: '14px', fontWeight: '600' }}
                            onClick={props.handleButtonClick}
                        >
                            Total Podcast (100)
                        </Button>
                    </Link>

                </div>
            </div>
            {props.isCardOpen && (
                <div>
                    <div>
                        <Row className='rowForrevert'>
                            <Col xl="12">
                                <div className='d-flex justify-content-between m-2'>
                                    <h3><strong>{props.Tittle}</strong></h3>
                                    <div className='text-primary'>
                                        {/* <NavLink to='Podcast/SeeAll'>
                                            {listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}
                                        </NavLink> */}
                                        <Link to="/Podcast/SeeAll">{listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {listView ? <Row className='rowForrevert'>
                            <Col xl='12' style={{ paddingRight: "7px" }}>
                                <div className="group-custom-block  ">
                                    <Slider {...FriendSuggestsettings} className="default-space ">
                                        {
                                            cardData1.map((item, index) => {
                                                return (
                                                    <div key={index} onMouseOver={() => { sethover(index + 1) }} onMouseLeave={() => { sethover(0) }}  >
                                                        <Card className='w-100 podcastForcard'>
                                                            <div className='headerForpodcast p-2 simple-linear'>
                                                                <div className='d-flex align-items-center justify-content-start' >
                                                                    {/* {item.seires === true ? null : <div className="livestatus-con mr-0"><span></span> Live</div>} */}
                                                                    <img src={Mic} width={25} height={28} alt='micicon' />
                                                                    {numFormatter(item.viewerCount)}
                                                                </div>
                                                                <div className='moreoverFormargin' >
                                                                    <UncontrolledButtonDropdown direction='start'>
                                                                        <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                                            <MoreVertical size={20} color='white' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu style={{ zIndex: '100', color: '#4D4D4D', minWidth: '10rem', right: "13px" }} className=" ">
                                                                            <NavLink onClick={handleMusicBar} className='text-dark ' to={item.seires === true ? '/YourSeries' : '/Podcast/view'} style={{ marginLeft: '-13px' }}>
                                                                                <DropdownItem className='toppoddroup' tag='a'><Play size={15} />&nbsp;&nbsp;Join</DropdownItem></NavLink>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem className='toppoddroup' tag='a'><Copy size={15} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a' className='toppoddroup' onClick={toggleForward} ><Send size={15} />&nbsp;&nbsp;Share podcast </DropdownItem>
                                                                            {/* <DropdownItem divider></DropdownItem> */}
                                                                            {/* <DropdownItem className='toppoddroup'><Edit size={15} />
                                                                    <Link to='/Podcast/UploadRecordedPodacast' className='text-dark ' > Edit podcast </Link>
                                                                </DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' className='toppoddroup' onClick={toggledeletepodcast} ><Trash2 size={15} /> Delete podcast</DropdownItem> */}
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                            <div className={`position-relative`}
                                                            >
                                                                <Link to="/PodcastList"><img
                                                                    alt="Sample"
                                                                    className='w-100 rounded-top img-fluid'
                                                                    style={{ zIndex: '-1', cursor: 'pointer' }}
                                                                    src="https://picsum.photos/300/200"
                                                                // onClick={handleImageClick}
                                                                /></Link>
                                                                <NavLink to={item.seires === true ? '/MyPodcast' : '/Podcast/view'}   >

                                                                    <div className='simple-linear'>
                                                                        {/* <img
                                                                            alt="Sample"
                                                                            className='w-100 rounded-top img-fluid'
                                                                            style={{ zIndex: '-1' }}
                                                                            src="https://picsum.photos/300/200"
                                                                            onClick={handleImageClick}
                                                                        /> */}
                                                                    </div>
                                                                    <div className={`podcastcenterForstyle `}
                                                                    >
                                                                        <img className={`${hover === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                                    </div>
                                                                </NavLink>
                                                                {item.episodType === 'purched' ? <div className='paidTagForpostition'>
                                                                    <img alt="purched" className='img-fluid' src={PaidTag} style={{ height: '86px', position: 'relative', top: '-16px' }} />
                                                                </div> : ''}
                                                            </div>
                                                            <CardBody>
                                                                <CardTitle tag="h3">
                                                                    {item.cardTittle}
                                                                </CardTitle>
                                                                <CardSubtitle className="my-2">
                                                                    <Badge className='text-secondary' color="light" pill>
                                                                        {item.category}
                                                                    </Badge>
                                                                </CardSubtitle>
                                                                <CardText className='text-muted'>
                                                                    {item.podcastType}-{item.seriesCount} <Circle strokeWidth={0} size={8} fill={'#D9D9D9'} /> Total Episodes-{item.totalEpisodeCount}
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </Col >
                        </Row > :
                            <Row className='rem-mob-view '>
                                <Col xl='12'>
                                    {
                                        musicArray.map((item, index) => {
                                            return <div key={index} className='MusicList my-3 d-flex justify-content-between border p-1 rounded bg-white align-items-center my-3' >
                                                <div className='d-flex align-items-center'>
                                                    <div className='position-relative'>

                                                        <div className='d-flex align-items-center pr-3'>
                                                            <div className='position-relative'>
                                                                <img width={100} height={100} className='rounded' src={Rect1} alt='' />

                                                            </div>
                                                            <div className='ml-3'>
                                                                <h4>Rättegångspodden</h4>
                                                                <p className='text-muted pt-1'>Series || Ep 01</p>
                                                                {!item.Live ? <p className='text-muted pt-1'>06:00</p> : null}
                                                                <p className='text-muted pt-1'>{`Podcaster : ${item.publisher}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-around align-items-center' >
                                                    <div className='text-muted'>
                                                        <div className='d-flex justify-content-start align-items-center '>
                                                            {
                                                                LikeIcon ? <div className='mr-2 text-nowrap'>

                                                                </div> : <div className='mr-2 text-nowrap'>

                                                                </div>
                                                            }


                                                            <div className=' ml-2 d-flex align-items-center'>

                                                                <div className='p-2 rounded-circle rem-tab-hide' style={{ background: '#E5E5E5' }}><Headphones size={20} color='#808080' /></div> <span className='rem-tab-hide' style={{ paddingLeft: '0.2rem' }}> 25</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-around align-items-center'>

                                                    <div className='d-flex  justify-content-center' >
                                                        {item.Live ? <div className=''>
                                                            <Badge className='text-white bg-danger rem-tab-hide' pill>
                                                                Live
                                                            </Badge>
                                                        </div> : <div className='px-4 rem-tab-hide' onClick={toggledownloadpodcast} >
                                                            <ArrowDownCircle color='#81C14B' />
                                                        </div>}
                                                    </div>

                                                    <div style={{ paddingTop: '3px' }}>
                                                        <UncontrolledButtonDropdown direction='up' className='ml-0'>
                                                            <DropdownToggle outline color='flat-primary'>
                                                                <MoreVertical color='#6E6B7B' />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ left: '-171px' }} className='existingDrop'>
                                                                <DropdownItem tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledownloadpodcast} ><Download size={15} />&nbsp;Download</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash size={15} />&nbsp;Delete</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className=''>&nbsp;&nbsp;<Edit size={15} />
                                                                    <Link to='/Podcast/UploadRecordedPodacast' className='text-dark ' > Edit podcast </Link>
                                                                </DropdownItem>
                                                                {/* <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                    <DropdownItem divider></DropdownItem> */}
                                                                <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleReport} ><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </div >
                </div>
            )}
            {props.isCardOpen && (
                <div>
                    <div>
                        <Row className='rowForrevert'>
                            <Col xl="12">
                                <div className='d-flex justify-content-between m-2'>
                                    <h3><strong>TV & Film</strong></h3>
                                    <div className='text-primary'>
                                        <Link to="/Podcast/SeeAll">{listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {listView ? <Row className='rowForrevert'>
                            <Col xl='12' style={{ paddingRight: "7px" }}>
                                <div className="group-custom-block  ">
                                    <Slider {...FriendSuggestsettings} className="default-space ">
                                        {
                                            cardData1.map((item, index) => {
                                                return (
                                                    <div key={index} onMouseOver={() => { sethover1(index + 1) }} onMouseLeave={() => { sethover1(0) }}  >
                                                        <Card className='w-100 podcastForcard'>
                                                            <div className='headerForpodcast p-2 simple-linear'>
                                                                <div className='d-flex align-items-center justify-content-start' >
                                                                    {/* {item.seires === true ? null : <div className="livestatus-con mr-0"><span></span> Live</div>} */}
                                                                    <img src={Mic} width={25} height={28} alt='micicon' />
                                                                    {numFormatter(item.viewerCount)}
                                                                </div>
                                                                <div className='moreoverFormargin' >
                                                                    <UncontrolledButtonDropdown direction='start'>
                                                                        <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                                            <MoreVertical size={20} color='white' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu style={{ color: '#4D4D4D', minWidth: '10rem', right: "13px" }} className=" ">
                                                                            <NavLink onClick={handleMusicBar} className='text-dark ' to={item.seires === true ? '/YourSeries' : '/Podcast/view'} style={{ marginLeft: '-13px' }}>
                                                                                <DropdownItem className='toppoddroup' tag='a'><Play size={15} />&nbsp;&nbsp;Join</DropdownItem></NavLink>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem className='toppoddroup' tag='a'><Copy size={15} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a' className='toppoddroup' onClick={toggleForward} ><Send size={15} />&nbsp;&nbsp;Share podcast </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                            <div className={`position-relative`}
                                                            >
                                                                <Link to="/PodcastList"><img
                                                                    alt="Sample"
                                                                    className='w-100 rounded-top img-fluid'
                                                                    style={{ zIndex: '-1', cursor: 'pointer' }}
                                                                    src="https://picsum.photos/300/200"
                                                                /></Link>
                                                                <NavLink to={item.seires === true ? '/MyPodcast' : '/Podcast/view'}   >
                                                                    <div className='simple-linear'>
                                                                    </div>
                                                                    <div className={`podcastcenterForstyle `}
                                                                    >
                                                                        <img className={`${hover1 === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                                    </div>
                                                                </NavLink>
                                                                {item.episodType === 'purched' ? <div className='paidTagForpostition'>
                                                                    <img alt="purched" className='img-fluid' src={PaidTag} style={{ height: '86px', position: 'relative', top: '-16px' }} />
                                                                </div> : ''}
                                                            </div>
                                                            <CardBody>
                                                                <CardTitle tag="h3">
                                                                    {item.cardTittle}
                                                                </CardTitle>
                                                                <CardSubtitle className="my-2">
                                                                    <Badge className='text-secondary' color="light" pill>
                                                                        {item.category}
                                                                    </Badge>
                                                                </CardSubtitle>
                                                                <CardText className='text-muted'>
                                                                    {item.podcastType}-{item.seriesCount} <Circle strokeWidth={0} size={8} fill={'#D9D9D9'} /> Total Episodes-{item.totalEpisodeCount}
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </Col >
                        </Row > :
                            <Row className='rem-mob-view '>
                                <Col xl='12'>
                                    {
                                        musicArray.map((item, index) => {
                                            return <div key={index} className='MusicList my-3 d-flex justify-content-between border p-1 rounded bg-white align-items-center my-3' >
                                                <div className='d-flex align-items-center'>
                                                    <div className='position-relative'>

                                                        <div className='d-flex align-items-center pr-3'>
                                                            <div className='position-relative'>
                                                                <img width={100} height={100} className='rounded' src={Rect1} alt='' />

                                                            </div>
                                                            <div className='ml-3'>
                                                                <h4>Rättegångspodden</h4>
                                                                <p className='text-muted pt-1'>Series || Ep 01</p>
                                                                {!item.Live ? <p className='text-muted pt-1'>06:00</p> : null}
                                                                <p className='text-muted pt-1'>{`Podcaster : ${item.publisher}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-around align-items-center' >
                                                    <div className='text-muted'>
                                                        <div className='d-flex justify-content-start align-items-center '>
                                                            {
                                                                LikeIcon ? <div className='mr-2 text-nowrap'>

                                                                </div> : <div className='mr-2 text-nowrap'>

                                                                </div>
                                                            }


                                                            <div className=' ml-2 d-flex align-items-center'>

                                                                <div className='p-2 rounded-circle rem-tab-hide' style={{ background: '#E5E5E5' }}><Headphones size={20} color='#808080' /></div> <span className='rem-tab-hide' style={{ paddingLeft: '0.2rem' }}> 25</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-around align-items-center'>

                                                    <div className='d-flex  justify-content-center' >
                                                        {item.Live ? <div className=''>
                                                            <Badge className='text-white bg-danger rem-tab-hide' pill>
                                                                Live
                                                            </Badge>
                                                        </div> : <div className='px-4 rem-tab-hide' onClick={toggledownloadpodcast} >
                                                            <ArrowDownCircle color='#81C14B' />
                                                        </div>}
                                                    </div>

                                                    <div style={{ paddingTop: '3px' }}>
                                                        <UncontrolledButtonDropdown direction='up' className='ml-0'>
                                                            <DropdownToggle outline color='flat-primary'>
                                                                <MoreVertical color='#6E6B7B' />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ top: '-200px', left: '-171px' }} className='existingDrop'>
                                                                <DropdownItem tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledownloadpodcast} ><Download size={15} />&nbsp;Download</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash size={15} />&nbsp;Delete</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleReport} ><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </div >
                </div>
            )}
            {props.isCardOpen && (
                <div>
                    <div>
                        <Row className='rowForrevert'>
                            <Col xl="12">
                                <div className='d-flex justify-content-between m-2'>
                                    <h3><strong>Health & Fitness</strong></h3>
                                    <div className='text-primary'>
                                        <Link to="/Podcast/SeeAll">{listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {listView ? <Row className='rowForrevert'>
                            <Col xl='12' style={{ paddingRight: "7px" }}>
                                <div className="group-custom-block  ">
                                    <Slider {...FriendSuggestsettings} className="default-space ">
                                        {
                                            cardData1.map((item, index) => {
                                                return (
                                                    <div key={index} onMouseOver={() => { sethover2(index + 1) }} onMouseLeave={() => { sethover2(0) }}  >
                                                        <Card className='w-100 podcastForcard'>
                                                            <div className='headerForpodcast p-2 simple-linear'>
                                                                <div className='d-flex align-items-center justify-content-start' >
                                                                    {/* {item.seires === true ? null : <div className="livestatus-con mr-0"><span></span> Live</div>} */}
                                                                    <img src={Mic} width={25} height={28} alt='micicon' />
                                                                    {numFormatter(item.viewerCount)}
                                                                </div>
                                                                <div className='moreoverFormargin' >
                                                                    <UncontrolledButtonDropdown direction='start'>
                                                                        <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                                            <MoreVertical size={20} color='white' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu style={{ color: '#4D4D4D', minWidth: '10rem', right: "13px" }} className=" ">
                                                                            <NavLink onClick={handleMusicBar} className='text-dark ' to={item.seires === true ? '/YourSeries' : '/Podcast/view'} style={{ marginLeft: '-13px' }}>
                                                                                <DropdownItem className='toppoddroup' tag='a'><Play size={15} />&nbsp;&nbsp;Join</DropdownItem></NavLink>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem className='toppoddroup' tag='a'><Copy size={15} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a' className='toppoddroup' onClick={toggleForward} ><Send size={15} />&nbsp;&nbsp;Share podcast </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                            <div className={`position-relative`}
                                                            >
                                                                <Link to="/PodcastList"><img
                                                                    alt="Sample"
                                                                    className='w-100 rounded-top img-fluid'
                                                                    style={{ zIndex: '-1', cursor: 'pointer' }}
                                                                    src="https://picsum.photos/300/200"
                                                                /></Link>
                                                                <NavLink to={item.seires === true ? '/MyPodcast' : '/Podcast/view'}   >
                                                                    <div className='simple-linear'>
                                                                    </div>
                                                                    <div className={`podcastcenterForstyle `}
                                                                    >
                                                                        <img className={`${hover2 === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                                    </div>
                                                                </NavLink>
                                                                {item.episodType === 'purched' ? <div className='paidTagForpostition'>
                                                                    <img alt="purched" className='img-fluid' src={PaidTag} style={{ height: '86px', position: 'relative', top: '-16px' }} />
                                                                </div> : ''}
                                                            </div>
                                                            <CardBody>
                                                                <CardTitle tag="h3">
                                                                    {item.cardTittle}
                                                                </CardTitle>
                                                                <CardSubtitle className="my-2">
                                                                    <Badge className='text-secondary' color="light" pill>
                                                                        {item.category}
                                                                    </Badge>
                                                                </CardSubtitle>
                                                                <CardText className='text-muted'>
                                                                    {item.podcastType}-{item.seriesCount} <Circle strokeWidth={0} size={8} fill={'#D9D9D9'} /> Total Episodes-{item.totalEpisodeCount}
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </Col >
                        </Row > :
                            <Row className='rem-mob-view '>
                                <Col xl='12'>
                                    {
                                        musicArray.map((item, index) => {
                                            return <div key={index} className='MusicList my-3 d-flex justify-content-between border p-1 rounded bg-white align-items-center my-3' >
                                                <div className='d-flex align-items-center'>
                                                    <div className='position-relative'>

                                                        <div className='d-flex align-items-center pr-3'>
                                                            <div className='position-relative'>
                                                                <img width={100} height={100} className='rounded' src={Rect1} alt='' />

                                                            </div>
                                                            <div className='ml-3'>
                                                                <h4>Rättegångspodden</h4>
                                                                <p className='text-muted pt-1'>Series || Ep 01</p>
                                                                {!item.Live ? <p className='text-muted pt-1'>06:00</p> : null}
                                                                <p className='text-muted pt-1'>{`Podcaster : ${item.publisher}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-around align-items-center' >
                                                    <div className='text-muted'>
                                                        <div className='d-flex justify-content-start align-items-center '>
                                                            {
                                                                LikeIcon ? <div className='mr-2 text-nowrap'>

                                                                </div> : <div className='mr-2 text-nowrap'>

                                                                </div>
                                                            }


                                                            <div className=' ml-2 d-flex align-items-center'>

                                                                <div className='p-2 rounded-circle rem-tab-hide' style={{ background: '#E5E5E5' }}><Headphones size={20} color='#808080' /></div> <span className='rem-tab-hide' style={{ paddingLeft: '0.2rem' }}> 25</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-around align-items-center'>

                                                    <div className='d-flex  justify-content-center' >
                                                        {item.Live ? <div className=''>
                                                            <Badge className='text-white bg-danger rem-tab-hide' pill>
                                                                Live
                                                            </Badge>
                                                        </div> : <div className='px-4 rem-tab-hide' onClick={toggledownloadpodcast} >
                                                            <ArrowDownCircle color='#81C14B' />
                                                        </div>}
                                                    </div>

                                                    <div style={{ paddingTop: '3px' }}>
                                                        <UncontrolledButtonDropdown direction='up' className='ml-0'>
                                                            <DropdownToggle outline color='flat-primary'>
                                                                <MoreVertical color='#6E6B7B' />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ top: '-200px', left: '-171px' }} className='existingDrop'>
                                                                <DropdownItem tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledownloadpodcast} ><Download size={15} />&nbsp;Download</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash size={15} />&nbsp;Delete</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleReport} ><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </div >
                </div>
            )}
            {props.isCardOpen && (
                <div>
                    <div>
                        <Row className='rowForrevert'>
                            <Col xl="12">
                                <div className='d-flex justify-content-between m-2'>
                                    <h3><strong>Socity & Culture</strong></h3>
                                    <div className='text-primary'>
                                        {/* <NavLink to='Podcast/SeeAll'>
                                            {listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}
                                        </NavLink> */}
                                        <Link to="/Podcast/SeeAll">{listView ? 'See All' : 'See all episodes'} {listView ? <ChevronRight size={18} /> : ""}</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {listView ? <Row className='rowForrevert'>
                            <Col xl='12' style={{ paddingRight: "7px" }}>
                                <div className="group-custom-block  ">
                                    <Slider {...FriendSuggestsettings} className="default-space ">
                                        {
                                            cardData1.map((item, index) => {
                                                return (
                                                    <div key={index} onMouseOver={() => { sethover3(index + 1) }} onMouseLeave={() => { sethover3(0) }}  >
                                                        <Card className='w-100 podcastForcard'>
                                                            <div className='headerForpodcast p-2 simple-linear'>
                                                                <div className='d-flex align-items-center justify-content-start' >
                                                                    {/* {item.seires === true ? null : <div className="livestatus-con mr-0"><span></span> Live</div>} */}
                                                                    <img src={Mic} width={25} height={28} alt='micicon' />
                                                                    {numFormatter(item.viewerCount)}
                                                                </div>
                                                                <div className='moreoverFormargin' >
                                                                    <UncontrolledButtonDropdown direction='start'>
                                                                        <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                                                            <MoreVertical size={20} color='white' />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu style={{ zIndex: '100', color: '#4D4D4D', minWidth: '10rem', right: "13px" }} className=" ">
                                                                            <NavLink onClick={handleMusicBar} className='text-dark ' to={item.seires === true ? '/YourSeries' : '/Podcast/view'} style={{ marginLeft: '-13px' }}>
                                                                                <DropdownItem className='toppoddroup' tag='a'><Play size={15} />&nbsp;&nbsp;Join</DropdownItem></NavLink>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem className='toppoddroup' tag='a'><Copy size={15} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                                            <DropdownItem divider></DropdownItem>
                                                                            <DropdownItem tag='a' className='toppoddroup' onClick={toggleForward} ><Send size={15} />&nbsp;&nbsp;Share podcast </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                            <div className={`position-relative`}
                                                            >
                                                                <Link to="/PodcastList"><img
                                                                    alt="Sample"
                                                                    className='w-100 rounded-top img-fluid'
                                                                    style={{ zIndex: '-1', cursor: 'pointer' }}
                                                                    src="https://picsum.photos/300/200"
                                                                /></Link>
                                                                <NavLink to={item.seires === true ? '/MyPodcast' : '/Podcast/view'}   >
                                                                    <div className='simple-linear'>
                                                                    </div>
                                                                    <div className={`podcastcenterForstyle `}
                                                                    >
                                                                        <img className={`${hover3 === index + 1 ? "" : "d-none"} `} src={MicHover} width={50} height={52} alt='micicon' />
                                                                    </div>
                                                                </NavLink>
                                                                {item.episodType === 'purched' ? <div className='paidTagForpostition'>
                                                                    <img alt="purched" className='img-fluid' src={PaidTag} style={{ height: '86px', position: 'relative', top: '-16px' }} />
                                                                </div> : ''}
                                                            </div>
                                                            <CardBody>
                                                                <CardTitle tag="h3">
                                                                    {item.cardTittle}
                                                                </CardTitle>
                                                                <CardSubtitle className="my-2">
                                                                    <Badge className='text-secondary' color="light" pill>
                                                                        {item.category}
                                                                    </Badge>
                                                                </CardSubtitle>
                                                                <CardText className='text-muted'>
                                                                    {item.podcastType}-{item.seriesCount} <Circle strokeWidth={0} size={8} fill={'#D9D9D9'} /> Total Episodes-{item.totalEpisodeCount}
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Slider>
                                </div>
                            </Col >
                        </Row > :
                            <Row className='rem-mob-view '>
                                <Col xl='12'>
                                    {
                                        musicArray.map((item, index) => {
                                            return <div key={index} className='MusicList my-3 d-flex justify-content-between border p-1 rounded bg-white align-items-center my-3' >
                                                <div className='d-flex align-items-center'>
                                                    <div className='position-relative'>

                                                        <div className='d-flex align-items-center pr-3'>
                                                            <div className='position-relative'>
                                                                <img width={100} height={100} className='rounded' src={Rect1} alt='' />

                                                            </div>
                                                            <div className='ml-3'>
                                                                <h4>Rättegångspodden</h4>
                                                                <p className='text-muted pt-1'>Series || Ep 01</p>
                                                                {!item.Live ? <p className='text-muted pt-1'>06:00</p> : null}
                                                                <p className='text-muted pt-1'>{`Podcaster : ${item.publisher}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-around align-items-center' >
                                                    <div className='text-muted'>
                                                        <div className='d-flex justify-content-start align-items-center '>
                                                            {
                                                                LikeIcon ? <div className='mr-2 text-nowrap'>

                                                                </div> : <div className='mr-2 text-nowrap'>

                                                                </div>
                                                            }


                                                            <div className=' ml-2 d-flex align-items-center'>

                                                                <div className='p-2 rounded-circle rem-tab-hide' style={{ background: '#E5E5E5' }}><Headphones size={20} color='#808080' /></div> <span className='rem-tab-hide' style={{ paddingLeft: '0.2rem' }}> 25</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='d-flex justify-content-around align-items-center'>

                                                    <div className='d-flex  justify-content-center' >
                                                        {item.Live ? <div className=''>
                                                            <Badge className='text-white bg-danger rem-tab-hide' pill>
                                                                Live
                                                            </Badge>
                                                        </div> : <div className='px-4 rem-tab-hide' onClick={toggledownloadpodcast} >
                                                            <ArrowDownCircle color='#81C14B' />
                                                        </div>}
                                                    </div>

                                                    <div style={{ paddingTop: '3px' }}>
                                                        <UncontrolledButtonDropdown direction='up' className='ml-0'>
                                                            <DropdownToggle outline color='flat-primary'>
                                                                <MoreVertical color='#6E6B7B' />
                                                            </DropdownToggle>
                                                            <DropdownMenu style={{ top: '-200px', left: '-171px' }} className='existingDrop'>
                                                                <DropdownItem tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleForward}><Send size={15} />&nbsp;Share</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledownloadpodcast} ><Download size={15} />&nbsp;Download</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggledeletepodcast} ><Trash size={15} />&nbsp;Delete</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                {/* <DropdownItem tag='a'><Bookmark size={15} />&nbsp;Bookmark</DropdownItem>
                                                    <DropdownItem divider></DropdownItem> */}
                                                                <DropdownItem tag='a'><Eye size={15} />&nbsp;View all episodes</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a'><User size={15} />&nbsp;View creator’s profile</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem tag='a' onClick={toggleReport} ><AlertTriangle size={15} />&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        })
                                    }
                                </Col>
                            </Row>
                        }
                    </div >
                </div>
            )}
            {/* {isDivOpen && (
                <div className="container-fluid section-t-space px-0">
                    <div className="gallery-page-section section-b-space">
                        <div className="card-title">
                            <h3>Podcast (162)</h3>
                            <div className="right-setting">
                                <div className="profile-search-blk ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                                <Link to="#" className="btn btn-outline-green ms-3">Explore</Link>
                                <Link to="#" className="btn btn-outline-green ms-3">Create / Upload</Link>
                            </div>
                        </div>

                        <CardBody>
                            {!selectActive ? (
                                <Row>
                                    <Col md="8" className='border-right'>
                                        <AllTypesPodcast
                                            title="My Top Podcast"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between"
                                        />
                                        <AllTypesPodcast
                                            title="TV & Film"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                        <AllTypesPodcast
                                            title="Health & Fitness"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                        <AllTypesPodcast
                                            title="Socity & Culture"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                        <AllTypesPodcast
                                            title="Religion & Sprituality"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                    </Col>
                                    <Col md="4">
                                        <CardTitle className="Title_Podcast d-flex justify-content-between">
                                            <h4>Recently Played</h4>
                                            <div>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color='flat-primary' >
                                                        <MoreVertical color='#6E6B7B' size={20} />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="PodCast_DropDown_item">
                                                        <DropdownItem>
                                                            <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>

                                        </CardTitle>
                                        <Card>
                                            <div>
                                                <img src="https://sociomeeofficial.com/static/media/media.364082e8.png" alt="" className='w-100' />
                                            </div>
                                            <CardBody className="Recently__Card_buttom_section">
                                                <h2>Brita Zackari ochdf </h2>
                                                <audio id="audio-cont" ref={audioRef} controls>
                                                    <source src="${process.env.REACT_APP_IMAGEURL}/podcast/s3D9A0kImb3Em.mp3" type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        mt: 1,
                                                    }}
                                                >
                                                    <IconButton aria-label="previous song">
                                                        <FastRewindRounded fontSize="small" style={{ color: "#979797" }} />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label={paused ? 'play' : 'pause'}
                                                        onClick={handlePlay}
                                                        className="center_btn_action"
                                                        style={{ backgroundColor: "#81C14B" }}
                                                    >
                                                        {paused ? (
                                                            <PlayArrowRounded
                                                                sx={{ fontSize: '2rem' }}
                                                                htmlColor={mainIconColor}


                                                            />
                                                        ) : (
                                                            <PauseRounded sx={{ fontSize: '2rem' }}
                                                                htmlColor={mainIconColor}
                                                            />
                                                        )}
                                                    </IconButton>
                                                    <IconButton aria-label="next song">
                                                        <FastForwardRounded fontSize="small" style={{ color: "#979797" }} onClick={handleFastForward} />
                                                    </IconButton>
                                                </Box>
                                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} width={50} alignItems="center">
                                                    <Slider
                                                        aria-label="Volume"
                                                        defaultValue={30}
                                                        sx={{
                                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                            '& .MuiSlider-track': {
                                                                border: 'none',
                                                            },
                                                            '& .MuiSlider-thumb': {
                                                                width: 24,
                                                                height: 24,
                                                                backgroundColor: '#fff',
                                                                '&:before': {
                                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                },
                                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                    boxShadow: 'none',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Stack>
                                            </CardBody>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className="Recently_Player_List">
                                                    <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' className="img-fluid video_image" alt='' style={{ width: '90px' }} />
                                                    <div className='Play_Btn_Section'>
                                                        <img src={Play} className="img-fluid" alt='' />
                                                    </div>
                                                    <div className='Media_name ml-2'>
                                                        <div className="Suggestmember-blk">
                                                            <span></span> • <span>Total Episodes 12</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>

                                            </div>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className="Recently_Player_List">
                                                    <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' className="img-fluid video_image" alt='' style={{ width: '90px' }} />
                                                    <div className='Play_Btn_Section'>
                                                        <img src={Play} className="img-fluid" alt='' />
                                                    </div>
                                                    <div className='Media_name ml-2'>
                                                        <div className="Suggestmember-blk">
                                                            <span></span> • <span>Total Episodes 12</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>

                                            </div>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className="Recently_Player_List">
                                                    <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' className="img-fluid video_image" alt='' style={{ width: '90px' }} />
                                                    <div className='Play_Btn_Section'>
                                                        <img src={Play} className="img-fluid" alt='' />
                                                    </div>
                                                    <div className='Media_name ml-2'>
                                                        <div className="Suggestmember-blk">
                                                            <span></span> • <span>Total Episodes 12</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>

                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (

                                <Fragment>
                                    <CardTitle className="PodCast_Title d-flex justify-content-between ">
                                        <h3>My Top Podcast</h3>
                                        <Button onClick={() => setSelectActive(!selectActive)} outline color='secondary'><CornerUpLeft size={14} />&nbsp; Back</Button>
                                    </CardTitle>
                                    <Row className="mt-4">
                                        {
                                            PodcastTotalList.map((data) => (
                                                <Col md="6">

                                                    <div className=' Podcast_Media_Card_Box border p-2 mb-3'>
                                                        <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' alt="img" className=' Podcast_media_blk' />
                                                        <p className='Media_Mic'><img src='https://sociomeeofficial.com/static/media/Mic1.fcd0755a.png' alt="img" className='img-fluid' width={40} height={40} /></p>
                                                        {data.typePodcast === "paid" ? <>
                                                            <div className='Media_Paid'>
                                                                <img src='/static/media/PaidTag.20ee1678.svg' alt="pd" className='img-fluid Media_Paid' />
                                                            </div>
                                                        </> : <>

                                                        </>
                                                        }
                                                        <div className='w-100 ml-2 '>
                                                            <Link className='Podcast_link_heading' to="/MyPodcastDetails"><h3  >{data.name}</h3></Link>
                                                            <h4 className='py-2'>{data.title} </h4>
                                                            <p className='text-muted'>{data.episode}</p>
                                                            <div className='d-flex justify-content-between mt-3'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='PodCast_Icon_Section rounded-circle'>
                                                                        <img src='/static/media/eye.57c2f094.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }} onClick={toggleShared}>{numFormatter(data.shareCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src='/static/media/LikeBlack.7fd2858a.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }}>{numFormatter(data.likeCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src='/static/media/cmnt.974e04cc.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }} onClick={toggleComment} >{numFormatter(data.CmntCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src='/static/media/share.d1c92cfb.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }} onClick={toggleShared}>{numFormatter(data.shareCount)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {data.typePodcast === "paid" ? <>
                                                                <MoreVertical style={{ cursor: "pointer" }} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size={20} color="#6E6B7B" />
                                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal podcast_option">
                                                                    <ul>
                                                                        <li>
                                                                            <Link to="#">Play all</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Edit Series</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Add new episode</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Create new series</Link>

                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Delete series</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Bookmark</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">View Series Analytics</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#" style={{ color: "#1066AD" }}>Learn more</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div></> :
                                                                <>
                                                                    <MoreVertical style={{ cursor: "pointer" }} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size={20} color="#6E6B7B" />
                                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal podcast_option">
                                                                        <ul>
                                                                            <li>
                                                                                <Link to="#">Edit podcast</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Play podcast</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Create new podcast</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Delete podcast</Link>

                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" style={{ color: "#1066AD" }}>Learn more</Link>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Fragment>
                            )}
                        </CardBody>
                    </div>
                </div>
            )} */}
        </>


    )
}

export default PodcastNavbar
