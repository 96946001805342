import React, { Fragment, useEffect, useState } from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { Link, NavLink } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap';
import Market from "./Img/MarketPlace.png"
import Pin from "./Img/pin.png"
import { Checkbox, Pagination, Rating, Stack } from '@mui/material';
import { BellOff, Edit2, Globe, Lock, MoreHorizontal, PhoneCall, Plus, Search, Send, Sliders, Users } from 'react-feather';
import Msg from "./Img/msg.png"
import ViewProfile from "./Img/ViewProfile.png"
import Profile2 from "./Img/profile2.png"
import Profile3 from "./Img/profile3.png"
import PinGrp from './Img/PinGrp.svg'
import FilterImg from "./Img/Fltr.svg"
import FlwrImg from './Img/FlwrImg.png'
import MsgSvg from './Img/MsgSvg.svg'
import Pin1 from './Img/pin1.png'
import { getAllUserOwnPinnedBizPageReducerAction, getAllUserOwnFollowedBizPageReducerAction, getCategoryListReducerAction } from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux';
import { FilterData } from '../Events-Components/Components/EventsDataArray';
import BizTabSection from './BizPageComponents/BizTabSection';
import { data } from 'jquery';
import { useLocation } from 'react-router-dom';

const MyBizPage = () => {
    let dispatch = useDispatch();
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));

    //=============================================================>>>>>>>>>>>>>>>>>>>>Invite ==>>socioMate/people ==> search
    const [searchKey, setSearchKey] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [list,setList]=useState([])
    const [filteredCategoryList, setFilteredCategoryList] = useState([]);


    //=======================================================================================>>>>>>>>>>>>>>>>Get all Category List
    const getCategoryListPage = useSelector((state) => { return state.getCategoryListData });
    const { loading: loadingCategoryListPage, error: errorCategoryListPage, result: resultCategoryListPage } = getCategoryListPage


    useEffect(() => {
        dispatch(getCategoryListReducerAction())
    }, []);


  useEffect(() => {
    if (resultCategoryListPage && list.length>0) {
      const filteredList = resultCategoryListPage.filter(item => 
        list.includes(item.id)
      );
      setFilteredCategoryList(filteredList);
    }
  }, [resultCategoryListPage, list]);


    // console.log("list==================================",list)
    // console.log("resultCategoryListPage============================",filteredCategoryList)

    // const filterdList=()=>{

    // }

    return (
        <>
            < Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="gallery-page-section section-b-space">
                            <div className="card-title">
                                <h3>My BizPage</h3>
                                <div className="right-setting">
                                    <div className="profile-search-blk ">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch"
                                            onChange={(e) => { setSearchKey(e.target.value) }} />

                                    </div>
                                    <UncontrolledButtonDropdown>
                                        {type=="creator" && (
                                        <DropdownToggle outline className="border border-muted ml-3 ">
                                            <Sliders className='Slider_Filter' size={12} />&nbsp;&nbsp;Filters
                                        </DropdownToggle>
                                        )}
                                        <DropdownMenu className="DropDown_item">

                                            {filteredCategoryList?.map((data) => (
                                                <DropdownItem onClick={() => { setCategoryId(data.id) }}>{data.name}...</DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    <Link to="/CreateBizPage" className="btn btn-outline-green ms-3">Create Biz Page </Link>
                                </div>
                            </div>

                            <BizTabSection searchKey={searchKey} categoryId={categoryId} setCategoryId={setCategoryId} setList={setList}/>
                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default MyBizPage