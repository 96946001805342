import React, { useEffect, useState } from 'react'
import { Copy, CornerUpLeft, Edit3, Flag, Grid, List, MinusCircle, MoreVertical, Plus, PlusCircle, Search, Share, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllUserConnection } from '../../../Services/Actions/Common/getUserConnectionAction'
import {getCreatetConnectionListAction, getRemainingSociomateFromSociomateList} from '../../../Services/Actions/Connection/getCreatedCategoryAction'
import Header from '../../Header' 
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import ConnectionPlaceMenu from './ConnectionPlaceMenu'
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Button, InputGroup } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { CategoriesList } from '../ListView/CategoriesList'
import { SociomateList } from '../HoverComponents/SociomateList'
import BlockUserModal from '../HoverComponents/BlockUserModal'
import { MemberList } from '../ListView/MemberList'
import AddSocioMateModal from '../ConnectionModal/AddSocioMateModal'
import AddRemainingSociomateModal from '../ConnectionModal/AddRemainingSociomateModal'
import ConnectionEditModal from '../ConnectionModal/ConnectionEditModal'
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal'
import AddCategoryModal from '../ConnectionModal/AddCategoryModal'
import RemoveModal from '../ConnectionModal/RemoveModal'
import DeleteModal from '../ConnectionModal/DeleteModal'
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal'
import CreateCategoryModal from './CreateCategoryModal'
import PublicAccount from '../ConnectionModal/PublicAccount'
import PrivateAccount from '../ConnectionModal/PrivateAccount'
import CommanHeader from '../CommanHeader'

const ConnectionMenuScreen = () => {
    const [Gridview, Setgridview] = useState(false)
    const [member, setMember] = useState(false)
    const [fullName, setFullName] = useState("")
    const [userId, setUserId] = useState("")
    const [connectionListId, setConnectionListId] = useState("")
    //const [debouncedSearch, setDebouncedSearch] = useState(searchConn)
    const [deleteId, setDeleteId] = useState("");
    const [selectedRequest, setSelectedRequest] = useState(null);

    //search
    const [searchItem, setSearchItem] = useState("")
    const [searchConn, setSearchConn] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const dispatch = useDispatch();
    // GET FOLLOWING DATA
    const { getUserConnection } = useSelector(state => state.GetUserConnectionData)

    // connectionList data
    const { connectionList} = useSelector(state =>state.connectionListData) 
   
    //useEffect(() => {
    //    const handler = setTimeout(() => {
    //        setDebouncedSearch(searchConn);
    //    }, 2000); // 2 seconds debounce
    
    //    return () => {
    //        clearTimeout(handler);
    //    };
    //}, [searchConn]);

    const filteredConnection = getUserConnection?.filter((item) =>
      item.name.toLowerCase().includes(searchConn.toLowerCase())
    );

    const filteredData = connectionList?.rows?.length > 0
    ? connectionList.rows.filter((item) => {
        const fullNameMatch = item.fullName?.toLowerCase().includes(searchItem.toLowerCase());
        const userNameMatch = item.userName?.toLowerCase().includes(searchItem.toLowerCase());
        return fullNameMatch || userNameMatch;
      })
    : [];
  
    const connectionId = connectionList?.connectionId;

    //------------Block User Modal State------------ 
    const [blockModalOpen, setBlockModalOpen] = useState(false);
    const toggleBlockModalOpen = () => {
        setBlockModalOpen((prevState) => !prevState);
    };

    // ----------- Edit Categories state ----------- 
    const [editCategories, setEditCategories] = useState(false)
    const toggleEditCategories = () => {
        setEditCategories((preState) => !preState)
    }

    // ----------- Create Categories state ----------- 
    const [createCategories, setCreateCategories] = useState(false)
    const toggleCreateCategories = () => {
        setCreateCategories((preState) => !preState)
    }

    //  ----------- Add Users Option -----------
    const [addUserOptions, setAddUserOptions] = useState(false)
    const toggleAddUserOptions = () => {
        setAddUserOptions((preState) => !preState)
    }
    // -----------= share Modal State -----------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    // ----------- Remove Followers Modal state -----------
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = () => {
        setRemoveModalOpen((prevState) => !prevState)
    }
    // ----------- Delete Categoris Modal state  -----------
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const toggleDeleteModalOpen = () => {
        setDeleteModalOpen((prevState) => !prevState)
    }

    //  ----------- Start Add category State -----------
    const [addRelationShipOptions, setAddRelationShipOptions] = useState(false)
    const toggleAddCategory = () => {
        setAddRelationShipOptions((preState) => !preState)
    }

    //  ----------- Start ReportedOption -----------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState)
        setSelectedRequest(request)
    }
   
    // mouse on hover components state
    //const [mouseHover, setMouseHover] = useState(null)

    useEffect(() => {
        dispatch(loadAllUserConnection())
    }, [dispatch])

    // Pagination logic for sociomate.
    const sociomatePerPage = 14; // Number of items per page for sociomate
    const [currentPage, setCurrentPage] = useState(1);
    // Calculate the total number of pages
    const totalPagesSocimate = Math.ceil(filteredData?.length / sociomatePerPage);
    // Handle page change
    const handlePageChangeSociomate = (event, newPage) => {
        setCurrentPage(newPage);
    };
    // Calculate the start and end index for the current page
    const startIndexSociomate = (currentPage - 1) * sociomatePerPage;
    const endIndexSociomate = startIndexSociomate + sociomatePerPage;

    // Pagination logic for Category.
    const categoryPerPage = 17; // Number of items per page for category
    const [currentPageCat, setCurrentPageCat] = useState(1);

    const totalPagesCategory = Math.ceil(filteredConnection?.length / categoryPerPage);

    const handlePageChangeCategory = (event, newPage) => {
        setCurrentPageCat(newPage);
    };
    const startIndexCategory = (currentPageCat - 1) * categoryPerPage;
    const endIndexCategory = startIndexCategory + categoryPerPage;
    
    const[reportConfirm,setReportConfirm]=useState(false)
    useEffect(()=>{
        if(reportConfirm){
            setTimeout(() => {
               dispatch(getCreatetConnectionListAction(categoryName));
               setReportConfirm(false);
             }, 1000);
        }
    },[reportConfirm])
   
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <Card>
                        {/* comman header calling */}
                        <CommanHeader />
                        <CardBody>
                            {/* connection tabs calling */}
                            <ConnectionPlaceMenu />
                            {member ?
                                // {/*  ----------- Members Sections ----------- */}
                                <>
                                <div className="mppage-heading mt-4">
                                    <div className="group-custom-block">
                                        <div className='nav-flex-container mb-1'>
                                            <div onClick={() => setMember(!member)} className='d-flex'>
                                                <Button className='archive-button' color='success' variant="outline-primary">
                                                    <CornerUpLeft size={17} /> Back
                                                </Button>
                                            </div>
                                            <div className='sub-nav-flex size-sm-mt-4'>
                                                <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                                                    <Search color='#666666' size={16} />
                                                    <Input 
                                                    type='search' 
                                                    placeholder="  Find..." 
                                                    className='coonection-input-style'
                                                    value={searchItem}
                                                    onChange={(e) => setSearchItem(e.target.value)} 
                                                    />
                                                </InputGroup>
                                                <div className='list-grid-toggle size-sm-mt-4'>
                                                    <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                                        <Grid size={16} />
                                                    </Button>
                                                    <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                                        <List size={16} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        {Gridview ?
                                            <MemberList filteredData={Array.isArray(filteredData) ? filteredData.slice(startIndexSociomate, endIndexSociomate) : []}/>
                                            :
                                            <Row className='connections_PeopleThisGrid mt-3'>
                                                <Col lg='4' className='w-100'>
                                                    <div className="connection-card cus-cur-pointer py-4 content-mp-block box-shadow-style d-flex align-items-center justify-content-center" 
                                                    onClick={() => {
                                                        toggleAddUserOptions(); 
                                                        dispatch(getRemainingSociomateFromSociomateList(connectionId));
                                                    }}>
                                                        <div class="media media-new d-sm-flex py-2">
                                                            <div class="pl-1 d-flex align-items-center justify-content-center">
                                                                <span className='green-text text-center'>
                                                                    <Plus size={20} /> Add SocioMate
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    filteredData && filteredData?.slice(startIndexSociomate, endIndexSociomate).map((request, index) => {
                                                        return <Col lg='4' className='w-100' key={index}>
                                                            <div key={index} className="connection-card connection-card-new content-mp-block d-flex box-shadow-style justify-content-between">
                                                                <div className="media-body d-md-block">
                                                                    <div class="media media-new d-flex align-items-start">
                                                                        <div class="user-img d-flex align-items-start popover___wrapper " >
                                                                        <img
                                                                            src={request?.profileImage}
                                                                            className="rounded-circle "
                                                                            height={45}
                                                                            width={45}
                                                                            alt="shivam singh"
                                                                        />
                                                                            <span class="available-stats online"></span>
                                                                        {request?.isPrivate ? <PrivateAccount request={request}/> : <PublicAccount request={request}/>}
                                                                        </div>
                                                                        <div class="media-body d-md-block pl-1">
                                                                            <h4 className='m-0 name-style d-inline-block text-truncate'>{request.fullName || 'NA'}</h4>
                                                                            <div className='d-flex'>
                                                                                <p className='user-name-style'>{request.userName || 'NA'}  |  </p>
                                                                                    <span>
                                                                                        <img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} />
                                                                                    </span>
                                                                            </div>
                                                                            <p className='professional-style'>{request?.profession || "No Profession"}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-center justify-content-end'>
                                                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                                            <MoreVertical size={22} />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='Connection-dropdown'>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                                            onClick={() => {
                                                                                toggleRemoveModalOpen();
                                                                                setFullName(request?.fullName);
                                                                                setConnectionListId(request?.connectionListId);
                                                                            }}><MinusCircle size={15} className='mr-2' />Remove From List</DropdownItem>
                                                                            {/*<DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddCategory}>
                                                                                <PlusCircle size={15} className='mr-2' />Add To New Category
                                                                            </DropdownItem>*/}
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                                                            <DropdownItem
                                                                                className="border-bottom py-2 menu-hover-style"
                                                                                onClick={() => {
                                                                                setCategoryName(request.ConnectionName);
                                                                                setUserId(request.id);
                                                                                toggleBlockModalOpen();
                                                                                }}
                                                                            >
                                                                                <MinusCircle
                                                                                    size={15}
                                                                                    className="mr-2 icon-color-style"
                                                                                />
                                                                                Block
                                                                            </DropdownItem>
                                                                            <DropdownItem className='py-2 menu-hover-style' 
                                                                                onClick={() => {
                                                                                    toggleReport(request);
                                                                                    setCategoryName(request.ConnectionName);
                                                                                }}>
                                                                                <Flag size={15} className='mr-2' />Report User
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                               {
                                                    filteredData.length === 0 && (
                                                        <h3 className='text-center connection-sub-heading'>No Sociomate Found</h3>
                                                    )
                                                }

                                            </Row>
                                        }
                                    </div>    
                                </div>
                                <div className='d-flex justify-content-center my-3'>
                                {filteredData?.length > sociomatePerPage && (
                                        <Stack spacing={2} >
                                            <Pagination
                                                count={totalPagesSocimate}
                                                page={currentPage}
                                                onChange={handlePageChangeSociomate}
                                                size="large"
                                                color="primary"
                                            />
                                        </Stack>
                                    )}
                                </div>
                                </>
                                 :
                                // {/*  ----------- Categories Sections ----------- */}
                                <>
                                <div className="mppage-heading mt-4" >
                                    <div className="group-custom-block">
                                        <div className='nav-flex-container'>
                                            <h3 className='connection-sub-heading'>Categories ({getUserConnection?.length})</h3>
                                            <div className='sub-nav-flex size-sm-mt-4'>
                                                <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                                                    <Search color='#666666' size={16} />
                                                    <Input 
                                                    type='search' 
                                                    placeholder="  Find..." 
                                                    className='coonection-input-style'
                                                    value={searchConn}
                                                    onChange={(e) => setSearchConn(e.target.value)} 
                                                    />
                                                </InputGroup>
                                                <div className='list-grid-toggle size-xs-mt-4 rem-mob-view'>
                                                    <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                                        <Grid size={16} />
                                                    </Button>
                                                    <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                                        <List size={16} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        {Gridview ?
                                            <CategoriesList 
                                            filteredConnection={filteredConnection?.slice(startIndexCategory, endIndexCategory)} 
                                            setMember={setMember}  
                                            connectionId={connectionId}
                                            />
                                            :
                                            <Row className='connections_PeopleThisGrid mt-3'>
                                                <Col lg='4' className='w-100'>
                                                    <div onClick={toggleCreateCategories} className="connection-card cus-cur-pointer py-4 content-mp-block box-shadow-style d-flex align-items-center justify-content-center" >
                                                        <div class="media media-new d-sm-flex">
                                                            <div class="pl-1 d-flex align-items-center justify-content-center" >
                                                                <span className='text-center green-text'>
                                                                    <Plus size={20} /> Create Category
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </Col>
                                                {
                                                    filteredConnection && filteredConnection?.slice(startIndexCategory, endIndexCategory).map((request, index) => {
                                                        return <Col lg='4' className='w-100' key={index} role='button' >
                                                            <div className="connection-card  content-mp-block d-flex align-items-center justify-content-between box-shadow-style">
                                                                <div class="media media-new d-sm-flex">
                                                                    <div class="media-body pl-1" >
                                                                        <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '150px' }} 
                                                                        onClick={() => {
                                                                            dispatch(getCreatetConnectionListAction(request.name)); 
                                                                            setMember(!member); 
                                                                            setCategoryName(request.name)
                                                                        }}>{request.name}</h4>
                                                                        <SociomateList sociomateList={request?.sociomateList} />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-end flex-column justify-content-end'>
                                                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                                            <MoreVertical size={22} />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className='Connection-dropdown'>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                                            onClick={() => {
                                                                                toggleAddUserOptions(); 
                                                                                dispatch(getCreatetConnectionListAction(request.name)); //this should call to get connectionId for <AddRemainingSociomateModal>
                                                                                dispatch(getRemainingSociomateFromSociomateList(request.id));
                                                                                setCategoryName(request.name);
                                                                            }}>
                                                                                <PlusCircle size={15} className='mr-2 icon-color-style' />Add SocioMates
                                                                            </DropdownItem>
                                                                            <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                                            onClick={() => {
                                                                                dispatch(getCreatetConnectionListAction(request.name)); 
                                                                                toggleEditCategories(); 
                                                                                setCategoryName(request.name);
                                                                            }}>
                                                                                <Edit3 size={15} className='mr-2 icon-color-style' />Edit Category
                                                                            </DropdownItem>
                                                                            <DropdownItem className='py-2 menu-hover-style' 
                                                                            onClick={() => {
                                                                                toggleDeleteModalOpen();
                                                                                setDeleteId(request.id);
                                                                            }}>
                                                                                <Trash2 size={15} className='mr-2 icon-color-style' />Delete Category
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                                {
                                                    filteredConnection?.length <= 0 && <h3 className='text-center connection-sub-heading'>No connection Found</h3>
                                                }
                                            </Row>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center my-3'>
                                {filteredConnection?.length > categoryPerPage && (
                                        <Stack spacing={2} >
                                            <Pagination
                                                count={totalPagesCategory}
                                                page={currentPageCat}
                                                onChange={handlePageChangeCategory}
                                                size="large"
                                                color="primary"
                                            />
                                        </Stack>
                                    )}
                                </div>
                                </>
                            }
                        </CardBody>
                    </Card>
                </div>
                <RightSidebar />
            </div>
            {/* ----------- Add SocioMate Modal ----------- */}

            {/*<AddSocioMateModal
                openModal={addUserOptions}
                addUserModal={toggleAddUserOptions}
            />*/}

            { addUserOptions && (
                <AddRemainingSociomateModal
                    openModal={addUserOptions}
                    openAddUserModal={toggleAddUserOptions}
                    connectionId={connectionId}
                    categoryName={categoryName}
                />
            )}

            {/* ----------- Edit Categories Modal ----------- */}

            { editCategories && (
                <ConnectionEditModal
                    openModal={editCategories}
                    editModal={toggleEditCategories}
                    connectionId={connectionId}
                    categoryName={categoryName}
                />
            )}
            {/* ----------- Add Report Option ----------- */}

            { reportOpen && (
                <ConnectionReportModal
                    request={selectedRequest}
                    modalOpen={reportOpen}
                    reportModalOpen={toggleReport}
                    setReportConfirm ={setReportConfirm}
                />
            )}

            {/* ----------- Add CategoryModal Status ----------- */}
{/*
            <AddCategoryModal
                openModal={addRelationShipOptions}
                cateModalOpen={toggleAddCategory}
            />*/}

            {/* ----------- Remove SocioMate Modal ----------- */}

            { removeModalOpen && (
                <RemoveModal
                    openModal={removeModalOpen}
                    removeModalOpen={toggleRemoveModalOpen}
                    fullName={fullName}
                    connectionListId={connectionListId}
                    categoryName={categoryName}
                />
            )}

            {/* ----------- Delete Categories Modal ----------- */}

            { deleteModalOpen && (
                <DeleteModal
                    openModal={deleteModalOpen}
                    openDeleteModal={toggleDeleteModalOpen}
                    deleteId={deleteId}
                />
            )}
            {/*  ----------- Start Share Modal -----------*/}

            { shareOpen && (
                <ConnectionShareModal
                    openModal={shareOpen}
                    shareModalOpen={toggleShareOpen}
                />
            )}
            {/*  ----------- Start Edit New category Modal -----------*/}

            { createCategories && (
                <CreateCategoryModal
                    OpenModal={createCategories}
                    openCreateModal={toggleCreateCategories}
                />
            )}
             {/*------------------------- block modal----------------------- */}

           { blockModalOpen && (
                <BlockUserModal
                    userId={userId}
                    openModal={blockModalOpen}
                    blockModalOpen={toggleBlockModalOpen}
                    categoryName={categoryName}
                /> 
            )}
        </>
    )
}

export default ConnectionMenuScreen