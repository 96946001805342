export const GET_ALL_MARKETPLACE_PRODUCTS = "GET_ALL_MARKETPLACE_PRODUCTS";
export const GET_ALL_MARKETPLACE_PRODUCTS_SUCCESS = "GET_ALL_MARKETPLACE_PRODUCTS_SUCCESS";
export const GET_ALL_MARKETPLACE_PRODUCTS_ERROR = "GET_ALL_MARKETPLACE_PRODUCTS_ERROR";

//single market place post
export const GET_SINGLE_MARKETPLACE_PRODUCT = "GET_SINGLE_MARKETPLACE_PRODUCT";
export const GET_SINGLE_MARKETPLACE_PRODUCT_SUCCESS = "GET_SINGLE_MARKETPLACE_PRODUCT_SUCCESS";
export const GET_SINGLE_MARKETPLACE_PRODUCT_ERROR = "GET_SINGLE_MARKETPLACE_PRODUCT_ERROR";

//marketPlace Category
export const GET_MARKETPLACE_CATEGORY = "GET_MARKETPLACE_CATEGORY";
export const GET_MARKETPLACE_CATEGORY_SUCESS = "GET_MARKETPLACE_CATEGORY_SUCESS";
export const GET_MARKETPLACE_CATEGORY_ERROR = "GET_MARKETPLACE_CATEGORY_ERROR";

//marketPlace Sub Category
export const GET_MARKETPLACE_SUB_CATEGORY = "GET_MARKETPLACE_SUB_CATEGORY";
export const GET_MARKETPLACE_SUB_CATEGORY_SUCESS = "GET_MARKETPLACE_SUB_CATEGORY_SUCESS";
export const GET_MARKETPLACE_SUB_CATEGORY_ERROR = "GET_MARKETPLACE_SUB_CATEGORY_ERROR";

//marketPlace Sub Child Category
export const GET_MARKETPLACE_SUBCHILD_CATEGORY = "GET_MARKETPLACE_SUBCHILD_CATEGORY";
export const GET_MARKETPLACE_SUBCHILD_CATEGORY_SUCESS = "GET_MARKETPLACE_SUBCHILD_CATEGORY_SUCESS";
export const GET_MARKETPLACE_SUBCHILD_CATEGORY_ERROR = "GET_MARKETPLACE_SUBCHILD_CATEGORY_ERROR";

//suggested MarketPlace Product
export const GET_SUGGESTED_MARKETPLACE_PRODUCT = "GET_SUGGESTED_MARKETPLACE_PRODUCT"
export const GET_SUGGESTED_MARKETPLACE_PRODUCT_SUCCESS = "GET_SUGGESTED_MARKETPLACE_PRODUCT_SUCCESS"
export const GET_SUGGESTED_MARKETPLACE_PRODUCT_ERROR = "GET_SUGGESTED_MARKETPLACE_PRODUCT_ERROR"

//getAllcategory and subcategory which have date for nav baar
export const GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA = "GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA";
export const GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA_SUCCESS = "GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA_SUCCESS"
export const GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA_ERROR = "GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA_ERROR"