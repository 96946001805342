



// import { Checkbox } from '@mui/material'
// import React, { useState } from 'react';
// import { Button } from 'react-bootstrap';
// import { Search, X } from 'react-feather';
// import { Card, CardBody, Input, InputGroup } from 'reactstrap';
// // ==================country============================//
// const options = ['Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua', 'Armenia',
//     'Azerbaijan', 'Bahamas', 'Belgium', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'India', 'Bhutan',
//     'Bolivia', 'Bosnia', 'Botswana', 'Brazil', 'Brunei', 'Azerbaijan', 'Bahamas', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Brunei', 'Bhutan',
//     'Bolivia', 'Bosnia', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina']


// const Countries = () => {


//     const [selected, setSelected] = useState([]);
//     const [selectedDisplay, setSelectedDisplay] = useState([]);
//     const [searchPlaceholder, setSearchPlaceholder] = useState(" Search ");

//     const isAllSelected = options.length > 0 && selected.length === options.length;

//     const handleChange = (event) => {
//         const value = event.target.value;
//         if (value === 'selectall') {
//             if (selected.length === options.length) {
//                 setSelected([]);
//                 setSelectedDisplay([]);
//             } else {
//                 setSelected([...options]);
//                 setSelectedDisplay(['Select All']); // Add 'Select All' to selectedDisplay
//             }
//             return;
//         }

//         const list = [...selected];
//         const index = list.indexOf(value);
//         if (index === -1) {
//             list.push(value);
//         } else {
//             list.splice(index, 1);
//         }

//         setSelected(list);
//         setSelectedDisplay(list);
//         setSearchPlaceholder(list.length === 0 ? "Search" : "");
//     };
//     const handleRemoveSelected = (country) => {
//         const updatedSelected = selected.filter((item) => item !== country);
//         setSelected(updatedSelected);
//         setSelectedDisplay(updatedSelected);
//     };

//     const [searchText, setSearchText] = useState('');

//     const handleClearClick = () => {
//         setSelected([]);
//     };

//     const selectedItems = selectedDisplay.map((country) => (
//         <span key={country} className="selected-country">
//             {country}
//             <X
//                 size={14}
//                 color="gray"
//                 onClick={() => handleRemoveSelected(country)}
//                 style={{ cursor: 'pointer', marginLeft: '5px' }}
//             />
//         </span>
//     ));

//     return (
//         <>
//             <Card className="">
//                 <div className="p-2 border-bottom d-flex align-items-center justify-content-between">
//                     <h3 className="pb-2">Country Filters</h3>
//                     <Button variant="primary">Save</Button>
//                 </div>
//                 <CardBody>
//                     <InputGroup className="input-group-merge d-flex px-2 py-2 rounded bg-light align-items-center mr-2">
//                         <Search color="#666666" size={16} />
//                         <Input
//                             type="search"
//                             placeholder={searchPlaceholder}
//                             className="coonection-input-style"
//                             value={searchText}
//                             onChange={(e) => setSearchText(e.target.value)}
//                         />
//                         <p
//                             className='position-relative d-flex align-items-center justify-content-center'
//                             style={{
//                                 right: '12rem',
//                                 backgroundColor: selectedItems.length > 0 ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
//                                 height: '32px',
//                                 width: '100px',
//                                 borderRadius: '20px',
//                                 textWrap:"nowarp"
//                             }}
//                         >
//                             {selectedItems}
//                         </p>
//                         <X
//                             size={25}
//                             color="gray"
//                             style={{
//                                 backgroundColor: '#c2c0c0',
//                                 borderRadius: '50px',
//                                 cursor: 'pointer',

//                             }}
//                             className="p-1"
//                             onClick={handleClearClick}
//                         />
//                     </InputGroup>
//                     <p className="p-3 font-weight-bold my-2 bg-light rounded">Countries</p>
//                     <div className="d-flex flex-wrap align-items-center p-0 contries-scroll">
//                         <label className="lable_style col-xl-4 col-lg-4 col-sm-4 d-flex align-items-center p-0">
//                             <Checkbox
//                                 value="selectall"
//                                 onChange={handleChange}
//                                 checked={isAllSelected}
//                                 style={{
//                                     color: '#4E924D',
//                                 }}
//                             />
//                             &nbsp;Select All
//                         </label>

//                         {options.map((option) => (
//                             <div key={option} className="col-6 col-xxl-4 d-flex align-items-center p-0">
//                                 <label className="lable_style col-4 d-flex  align-items-center p-0">
//                                     <Checkbox
//                                         value={option}
//                                         onChange={handleChange}
//                                         checked={selected.includes(option)}
//                                         style={{
//                                             color: '#4E924D',
//                                         }}
//                                     />
//                                     &nbsp;{option}
//                                 </label>
//                             </div>
//                         ))}
//                     </div>
//                 </CardBody>
//             </Card>
//         </>
//     );
// };

// export default Countries;
// import React, { useState } from 'react';
// import { Button } from 'react-bootstrap';
// import { Search, X } from 'react-feather';
// import { Card, CardBody, Input, InputGroup } from 'reactstrap';
// import { Checkbox } from '@mui/material';

// const allOptions = [
//   'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua', 'Armenia',
//   'Azerbaijan', 'Bahamas', 'Belgium', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'India', 'Bhutan',
//   'Bolivia', 'Bosnia', 'Botswana', 'Brazil', 'Brunei', 'Azerbaijan', 'Bahamas', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Brunei', 'Bhutan',
//   'Bolivia', 'Bosnia', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina'
// ];

// const Countries = () => {
//   const [selectedCountries, setSelectedCountries] = useState([]);
//   const [searchPlaceholder, setSearchPlaceholder] = useState('Search');
//   const [searchText, setSearchText] = useState('');

//   const isAllSelected = allOptions.length > 0 && selectedCountries.length === allOptions.length;

//   const handleChange = (event) => {
//     const value = event.target.value;
//     if (value === 'selectall') {
//       if (selectedCountries.length === allOptions.length) {
//         setSelectedCountries([]);
//       } else {
//         setSelectedCountries([...allOptions]);
//       }
//       return;
//     }      

//     const list = [...selectedCountries];
//     const index = list.indexOf(value);
//     if (index === -1) {
//       list.push(value);
//     } else {
//       list.splice(index, 1);
//     }

//     setSelectedCountries(list);
//   };

//   const handleRemoveSelected = (country) => {
//     const updatedSelected = selectedCountries.filter((item) => item !== country);
//     setSelectedCountries(updatedSelected);
//   };

//   // const handleClearClick = () => {
//   //   setSelectedCountries([]);
//   // };

//   const filteredOptions = allOptions.filter(option =>
//     option.toLowerCase().includes(searchText.toLowerCase())
//   );

//   const selectedItems = selectedCountries.map((country) => (
//     <span key={country} className="selected-country">
//       {country}
//       <X
//         size={14}
//         color="gray"
//         // onClick={() => handleRemoveSelected(country)}
//         style={{ cursor: 'pointer', marginLeft: '5px' }}
//       />
//     </span>
//   ));

//   return (
//     <>
//       <Card className="">
//         <div className="p-2 border-bottom d-flex align-items-center justify-content-between">
//           <h3 className="pb-2">Country Filters</h3>
//           <Button variant="primary">Save</Button>
//         </div>
//         <CardBody>
//           <InputGroup className="input-group-merge d-flex px-2 py-2 rounded bg-light align-items-center mr-2">
//             <Search color="#666666" size={16} />
//             <Input
//               type="search"
//               placeholder={searchPlaceholder}
//               className="coonection-input-style"
//               value={searchText}
//               onChange={(e) => setSearchText(e.target.value)}
//             />
//             <p
//               className='position-relative d-flex align-items-center justify-content-center'
//               style={{
//                 right: '12rem',
//                 backgroundColor: selectedItems.length > 0 ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
//                 height: '32px',
//                 width: '100px',
//                 borderRadius: '20px',
//               }}
//             >
//               {selectedItems}
//             </p>
//             <X
//               size={25}
//               color="gray"
//               style={{
//                 backgroundColor: '#c2c0c0',
//                 borderRadius: '50px',
//                 cursor: 'pointer',
//               }}
//               className="p-1"
//             // onClick={handleClearClick}
//             />
//           </InputGroup>
//           <p className="p-3 font-weight-bold my-2 bg-light rounded">Countries</p>
//           <div className="d-flex flex-wrap align-items-center p-0 contries-scroll">
//             <label className="lable_style col-xl-4 col-lg-4 col-sm-4 d-flex align-items-center p-0">
//               <Checkbox
//                 value="selectall"
//                 onChange={handleChange}
//                 checked={isAllSelected}
//                 style={{
//                   color: '#4E924D',
//                 }}
//               />
//               &nbsp;Select All
//             </label>

//             {filteredOptions.map((option) => (
//               <div key={option} className="col-6 col-xxl-4 d-flex align-items-center p-0">
//                 <label className="lable_style col-4 d-flex  align-items-center p-0">
//                   <Checkbox
//                     value={option}
//                     onChange={handleChange}
//                     checked={selectedCountries.includes(option)}
//                     style={{
//                       color: '#4E924D',
//                     }}
//                   />
//                   &nbsp;{option}
//                 </label>
//               </div>
//             ))}
//           </div>
//         </CardBody>
//       </Card>
//     </>
//   );
// };

// export default Countries;
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Search, X } from 'react-feather';
import { Card, CardBody, Input, InputGroup } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';

const allOptions = [
  'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua', 'Armenia',
  'Azerbaijan', 'Bahamas', 'Belgium', 'Barbados', 'Belarus', 'Belize', 'India', 'Bhutan',
  'Bolivia', 'Bosnia', 'Botswana', 'Brazil', 'Brunei', 'Azerbaijan', 'Bahamas', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Brunei', 'Bhutan',
  'Bolivia', 'Bosnia', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina'
];

const Countries = () => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [searchText, setSearchText] = useState('');
  const isAllSelected = allOptions.length > 0 && selectedCountries.length === allOptions.length;
  const handleChange = (event) => {
    const value = event.target.value;
    if (value === 'selectall') {
      if (selectedCountries.length === allOptions.length) {
        setSelectedCountries([]);
      } else {
        setSelectedCountries([...allOptions]);
      }
      return;
    }

    const list = [...selectedCountries];
    const index = list.indexOf(value);
    if (index === -1) {
      list.push(value);
    } else {
      list.splice(index, 1);
    }

    setSelectedCountries(list);
  };

  const handleRemoveSelected = (country) => {
    const updatedSelected = selectedCountries.filter((item) => item !== country);
    setSelectedCountries(updatedSelected);
  };

  // Combine selected countries into a single string for display in the search input
  const selectedText = selectedCountries.join(', ');

  // Updated search functionality
  const filteredOptions = allOptions.filter(option =>
    option.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Card className="">
        <div className="p-2 border-bottom d-flex align-items-center justify-content-between">
          <h3 className="pb-2">Country Filters</h3>
          <Button variant="primary">Save</Button>
        </div>
        <CardBody>
          <div>
            {
              !selectedText ? (
                <Search
                  color="#666666"
                  size={16}
                  style={{
                    position: 'absolute',
                    top: '14%',
                    left: '28px',
                    transform: 'translateY(-50%)',
                  }}
                />
              ) : (
                null // You can use null to represent an empty element
              )
            }

            <Input style={{ borderRadius: '20px', margin: '-2px', marginTop: '5px' }}
              type="search"
              className="connection-input-style"
              value={selectedText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <X
              size={25}
              color="gray"
              style={{
                backgroundColor: '#c2c0c0',
                borderRadius: '50px',
                cursor: 'pointer',
                position: 'absolute',
                left: '84%',
                marginTop: '-29px'
              }}
              className="p-1"
              onClick={() => setSelectedCountries([])}
            />
          </div>
          <p className="p-3 font-weight-bold my-2 bg-light rounded">Countries</p>
          <div className="d-flex flex-wrap align-items-center p-0 contries-scroll">
            <label className="label_style col-xl-4 col-lg-4 col-sm-4 d-flex align-items-center p-0">
              <Checkbox
                value="selectall"
                onChange={handleChange}
                checked={isAllSelected}
                style={{
                  color: '#4E924D',
                }}
              />
              &nbsp;Select All
            </label>

            {filteredOptions.map((option) => (
              <div key={option} className="col-6 col-xxl-4 d-flex align-items-center p-0">
                <label className="label_style col-4 d-flex align-items-center p-0">
                  <Checkbox
                    value={option}
                    onChange={handleChange}
                    checked={selectedCountries.includes(option)}
                    style={{
                      color: '#4E924D',
                    }}
                  />
                  &nbsp;{option}
                </label>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Countries;
