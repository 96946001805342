import React, { Fragment, useState, useEffect } from "react";
import { Col, Input, Label, Modal, ModalBody, Row, ModalHeader, Button } from "reactstrap";
import Pen1 from "../Img/Pen1.png";
import Pen2 from "../Img/Pen1.png";
import Sprt from "../Img/sport.png";
import Clg from "../Img/clg.png";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import {
  getAllSchoolAction,
  addSchoolCollegeAction,
  getQualificationAction,
  addQualificationAction,
  addUserEducationAction,
  updateUserEducationAction,
  deleteUserEducationAction
} from "../../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import { Alert, FormGroup, FormLabel, Radio } from "@mui/material";
import { PlusCircle, Trash2, XCircle } from "react-feather";
import { data } from "jquery";
import { sweetAlertConfirmation } from "../../../components/group-components/AllGroupsSections/SweetAlert";
// import { toast } from 'react-toastify';
// import { SuccessToast,ErrorToast } from "../../../NewComponents/ToastNotification";

const MyEducation = (props) => {
  let dispatch = useDispatch();
  let fromDateFormatted;
  let toDateFormatted;
  const [schoolName, setSchoolName] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [collegeId, setCollegeId] = useState("");
  const [schoolDropDown, setSchoolDropDown] = useState("");
  const [schoolAdded, setSchoolAdded] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [locationDropDown, setLocationDropDown] = useState("");
  const [locationName, setLocationName] = useState("");
  const [loadLocation, setLoadLocation] = useState();
  const [locationPresent, setLocationPresent] = useState();
  const [locationForm, setLocationForm] = useState();

  const [qualificationName, setQualificationName] = useState("");
  const [loadQualification, setLoadQualification] = useState();
  const [qualificationDropDown, setQualificationDropDown] = useState("");
  const [hideQualification, setHideQualification] = useState();
  const [qualificationId, setQualificationId] = useState("");
  const [lastQualificationId, setLastQualificationId] = useState("");
  const [reloadEducation, setReloadEducation] = useState(false);

  const [fromDateUser, setFromDateUser] = useState("");
  const [toDate, setToDate] = useState("");
  const [description, setDescription] = useState("");

  const [institutionPresent, setInstitutionPresent] = useState();
  const [loadSchool, setLoadSchool] = useState();

  const [educationId, setEducationId] = useState("");
  const [educationType, setEducationType] = useState("");
  const [location, setLocation] = useState("");
  const [displayForm, setDisplayForm] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [resultAllQualifications, setResultAllQualifications] = useState([]);
  const [suggestionModal, setSuggestionModal] = useState(false)
  const [qualificationSuggestionModal, setQualificationSuggestionModal] = useState(false)
  const [isGraduate, setIsGraduate] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [eduId, setEduId] = useState('');
  const [deleteId,setDeleteId]=useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [isTouched1, setIsTouched1] = useState(false);
  const [error,setError]=useState('');
  
  const [selectedValue, setSelectedValue] = useState("school");

  const toggleDeleteOpen = (e,id) => {
    e.stopPropagation();
    setDeleteId(id);
    setDeleteOpen((prevState) => !prevState);
  }
  const toggleDeleteOpen1=(e)=>{
    e.stopPropagation();
    setDeleteOpen((prevState)=>!prevState)
    // educationOpen
    setDeleteId('');
    setEducationOpen((prevState)=>!prevState)
  }

  const handleCheckboxChange = () => {
    setIsGraduate(prevState => !prevState);
  };

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  const getAllSchoolSuccess = useSelector((state) => {
    return state.getAllSchoolPageData;
  });
  const { result: resultAllSchool } = getAllSchoolSuccess;

  // const addSchoolCollegeSuccess = useSelector((state) => { return state.addSchoolCollegeData })

  const addedQualification = useSelector((state) => {
    return state.addQualificationData;
  });
  const { result: resultAddedQualification } = addedQualification;

  const getAllQualification = useSelector((state) => {
    return state.getQualificationData;
  });
  const { result: resultAllQualification } = getAllQualification;

  const addUserEducation = useSelector((state) => {
    return state.addUserEducationData;
  });
  let { result: resultUserEducation } = addUserEducation;
  // qualification added

  useEffect(() => {
    dispatch(getQualificationAction());
  }, []);

  useEffect(() => {
    dispatch(loadProfileByUserId(props?.userId));
  }, [props?.userId]);

  useEffect(() => {
    if (loadSchool) {
      dispatch(getAllSchoolAction());
    }
  }, [loadSchool]);

  useEffect(() => {
    if (reloadEducation) {
      dispatch(loadProfileByUserId(props?.userId));
    }
  }, [reloadEducation,props?.userId]);

  useEffect(() => {
    if (schoolAdded) {
      dispatch(getAllSchoolAction());
    }
  }, [schoolAdded]);

  // console.log("schoolName============================",props?.userProfileByUserId)

  const handleSchoolNameChange = async (e) => {
    const inputText = e.target.value;
    setSchoolName(inputText);
    dispatch(getAllSchoolAction(schoolName, "school"));
    setSuggestionModal(!suggestionModal)
  };

  const handleSchoolNameChange1 = async (e) => {
    // console.log("clieckedf=================================")
    // const inputText = e.target.value;
    // setSchoolName(inputText);
    // dispatch(getAllSchoolAction(schoolName, "school"));
    dispatch(getAllSchoolAction(schoolName, "school"));
    setSuggestionModal(!suggestionModal)
  };

  const handleCollegeNameChange = async (e) => {
    const inputText = e.target.value;
    setCollegeName(inputText);
    dispatch(getAllSchoolAction(collegeName, "college"));
    setSuggestionModal(!suggestionModal)
  };

  const handleSuggestionClick = (suggestion) => {
    setSchoolName(suggestion.name);
    setSchoolId(suggestion.id);
    setSelectedSuggestion(suggestion);
    setSuggestionModal(!suggestionModal)
  };
  const handleSuggestionClickCollege = (suggestion) => {
    setCollegeName(suggestion.name);
    setSchoolId(suggestion.id);
    setSelectedSuggestion(suggestion);
    setSuggestionModal(!suggestionModal)
  };

  const handleQualificationNameChange = (e) => {
    setQualificationName(e.target.value);
    dispatch(getQualificationAction(qualificationName));
    setQualificationSuggestionModal(!qualificationSuggestionModal)
  };

  const handleQualificationNameChange1 = (e) => {
    // setQualificationName(e.target.value);
    dispatch(getQualificationAction(qualificationName));
    setQualificationSuggestionModal(!qualificationSuggestionModal)
  };

  const handleQualificationClick = (qualification) => {
    setQualificationName(qualification.name);
    setLastQualificationId(qualification.id);
    setQualificationSuggestionModal(!qualificationSuggestionModal)
  };

  // console.log("selelted Value-================>",selectedValue)

  const saveUserEducation = () => {
    if(!fromDateUser|| !toDate || !lastQualificationId || !schoolId){
      setError("please fill all the required fields")
    }else{
    fromDateFormatted = fromDateUser.slice(0, 7);
    toDateFormatted = toDate.slice(0, 7);
    if (selectedValue == "school") {
      dispatch(
        addUserEducationAction(
          lastQualificationId,
          schoolId,
          description,
          fromDateFormatted,
          toDateFormatted,
          true
        )
      );
    }else{
    dispatch(
      addUserEducationAction(
        lastQualificationId,
        schoolId,
        description,
        fromDateFormatted,
        toDateFormatted,
        isGraduate
      )
    );
  }
    setAddOpen(!addOpen)
    setReloadEducation(true);
    setSchoolId('')
    setCollegeId('')
    setCollegeName('')
    setSchoolName('')
    setLastQualificationId('')
    setQualificationName('')
    setIsGraduate(false);
    sweetAlertConfirmation("Education added successfully");
}
};

  const editUserEducation = () => (event) => {
    event.stopPropagation();
    if(!fromDateUser|| !toDate || !lastQualificationId || !schoolId){
      setError("please fill all the required fields")
    }else{
   
      let data = resultAllSchool?.find(
        (x) => x.name.toLowerCase().trim() === schoolName?.toLowerCase().trim()
      );
      const findSchoolById = resultAllSchool?.findIndex(
        (schoolId) =>
          schoolId?.name?.toLowerCase().trim() ===
          schoolName?.toLowerCase().trim()
      );
      fromDateFormatted = fromDateUser.slice(0, 7);
      toDateFormatted = toDate.slice(0, 7);
      if (selectedValue == "school") {
        dispatch(
          updateUserEducationAction(
            eduId,
            educationId,
            lastQualificationId,
            description,
            fromDateFormatted,
            toDateFormatted,
            true
          )
        );
      }else{
        dispatch(
          updateUserEducationAction(
            eduId,
            educationId,
            lastQualificationId,
            description,
            fromDateFormatted,
            toDateFormatted,
            isGraduate
          )
        );
      }
      setAddOpen(false)
      setReloadEducation(true);
      setSchoolId('')
      setCollegeId('')
      setCollegeName('')
      setSchoolName('')
      setLastQualificationId('')
      setQualificationName('')
      setEditOpen(false);
      setEducationOpen(false)
      setIsGraduate(false);
      sweetAlertConfirmation("Education edited successfully");
      setEduId('');
      setError('')
    }
    
  };

  const descriptionOnChange = (e) => {
    setDescription(e.target.value);
  };

  const [educationOpen, setEducationOpen] = useState(false);
  const toggleEducation = () => {
    setEducationOpen((preState) => !preState);
  };

  // console.log("addOpen=============================",addOpen)
  //ADD EDUCATION=============================================================
  const toggleAdd = () => {
    toggleEducation(true);
    setAddOpen((preState) => !preState);
    setFromDateUser('')
    setToDate('');
    setSchoolId('')
    setCollegeId('')
    setCollegeName('')
    setSchoolName('')
    setLastQualificationId('')
    setQualificationName('')
    setError('')
  };

  const editAdd = (id, education_id, Studied_at, qualification, fromDate, eduType, qualifications_id, todate) => {
    toggleEducation(true);
    setEduId(id);
    setEditOpen((preState) => !preState);
    setSchoolId('')
    setEducationId(education_id);
    setCollegeId('')
    setFromDateUser(fromDate)
    setToDate(todate);
    setCollegeName(Studied_at)
    setSchoolName('')
    setQualificationName(qualification)
    setLastQualificationId(qualifications_id)
    setSelectedValue(eduType)
    setError('')
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (resultAddedQualification === "qualification added") {
      dispatch(getQualificationAction());
      setHideQualification(true);
      let data = resultAllQualification?.find(
        (x) =>
          x.name.toLowerCase().trim() ===
          qualificationDropDown.toLowerCase().trim()
      );
      if (data) {
        setQualificationId(data.id);
      }
      setQualificationId(resultAllQualification[0].id);
      setLastQualificationId(resultAllQualification[0]?.id);
    }
  }, [resultAddedQualification]);


  const deleteUserEducation = (e) => {
    e.stopPropagation();
    try {
      dispatch(deleteUserEducationAction(deleteId));
      toggleEducation(false);
      setReloadEducation(true);
      setDeleteOpen((preState) => !preState);
    } catch (error) {
      console.error(error)
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
  };
  const handleBlur1 = () => {
    setIsTouched1(true);
  };

  return (
    <Fragment>
      <div className="card-title-cont-block">
        {/* <div style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle'>
        <XCircle onClick={toggleAdd}/>
      </div> */}
        <div className="card-title About_section">
          <h3>Education</h3>
          <div className="settings">
            {props.type!=="viewer" && (
              userProfileByUserId?.education?.length > 0 ? (
                <div onClick={toggleEducation} className="setting-btn">
               <img src={Pen1} width={12} alt="editLogo" className="img-fluid" />
               </div>
             ):(
              <div  onClick={toggleEducation} style={{ position: 'relative',padding:"-1rem"+'!important', color:"#81C14B" }}>
               <p style={{ position: 'relative',padding:"-1rem"+'!important' }}>+ Add </p>
              </div>
             )
            )}
                          
          </div>
        </div>
        {userProfileByUserId?.education?.map((data) => (
          <div className="about-content About_section ">
            <ul>
              <li>
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    stroke="#81C14B"
                    fill="none"
                    className="iw-18 ih-18"
                  >
                    <path
                      d="M5.66667 14.6668V8.41683L9 6.56516M9 9.66683L16.5 5.50016L9 1.3335L1.5 5.50016L9 9.66683ZM9 9.66683L14.1333 6.81516C14.8088 8.53024 15.0005 10.3978 14.6875 12.2143C12.5738 12.4195 10.581 13.2948 9 14.7127C7.41921 13.295 5.42674 12.4197 3.31333 12.2143C3.00017 10.3978 3.19186 8.53018 3.8675 6.81516L9 9.66683Z"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="Education_details">
                  <h4 className="text-capitalize">
                    {data.qualification}
                    <br />
                    <small style={{ fontSize: "14px", color: "black" }}>
                      {" "}
                      Studied at {data.Studied_at}
                    </small>
                  </h4>
                  <p className="py-2 text-capitalize">
                    {/* Batch {data.fromDate} to {data.toDate} */}
                    {data.isGraduate ? `Batch ${new Date(data.fromDate).getFullYear()} to ${new Date(data.toDate).getFullYear()}` : `Batch ${new Date(data.fromDate).getFullYear()} - Pursuing`}
                    <br />
                    {data.location} - {data.educationType}
                    <br />
                    <small    style={{
                      display: 'block', /* Ensures the text wraps correctly */
                      wordWrap: 'break-word', /* Allows long words to break and wrap */
                      overflowWrap: 'break-word', /* Prevents text overflow */
                      whiteSpace: 'pre-wrap', /* Preserves whitespace and allows wrapping */
                      margin: 0 /* Optional: removes any default margins */
                    }}>{data.description}</small>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        ))}
      </div>
      <div className="Modal_section Education">
        {educationOpen !== false && (
          <Modal
            scrollable
            isOpen={educationOpen}
            toggle={toggleEducation}
            className="modal-dialog-centered"
          >
            <div className="w-100 border-bottom">
              <div className="mx-3 py-4 Profession_Modal_section">
                <h4>Education</h4>
                <p onClick={toggleAdd}>+ Add New</p>
              </div>
            </div>
            <ModalBody>
              <div className="profile-about">
                {userProfileByUserId?.education?.map((data) => (
                  <div className="card-title-cont-block d-flex justify-content-between mt-3">
                    <div className="about-content Modal_About_section ">
                      <ul>
                        <li>
                          <div className="icon">
                            <img
                              src={Clg}
                              alt="img"
                              className="img-fluid Icon_section"
                              width={20}
                              height={20}
                            />
                          </div>
                          <div className="Education_details">
                            <h4 className="text-capitalize">
                              {data.qualification}
                              <br />
                              <small
                                style={{ fontSize: "14px", color: "black" }}
                              >
                                {" "}
                                Studied at {data.Studied_at}
                              </small>
                            </h4>
                            <h6 className="py-2">
                              {data.isGraduate ? `Batch ${new Date(data.fromDate).getFullYear()} to ${new Date(data.toDate).getFullYear()}` : `Batch ${new Date(data.fromDate).getFullYear()} - Pursuing`}
                              <br />
                              {data.location}, {data.ducationType}
                              <br />
                              <small    style={{
                              display: 'block', /* Ensures the text wraps correctly */
                              wordWrap: 'break-word', /* Allows long words to break and wrap */
                              overflowWrap: 'break-word', /* Prevents text overflow */
                              whiteSpace: 'pre-wrap', /* Preserves whitespace and allows wrapping */
                              margin: 0 /* Optional: removes any default margins */
                            }}>{data.description}</small>
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="settings d-flex">
                      {/* <div onClick={toggleAdd} className="setting-btn"> */}
                      <div onClick={(e) => {
                        e.stopPropagation();
                        editAdd(data.id, data.education_id, data.Studied_at, data.qualification, data.fromDate, data.educationType, data.qualifications_id, data.toDate,data.location);
                      }} className="setting-btn">
                        <img src={Pen2} width={12} className="img-fluid" />
                      </div>
                      &nbsp;
                      <span style={{ fontSize: '12px' }} onClick={(e)=>toggleDeleteOpen(e,data.id)}>
                        <Trash2 size={22} style={{ color: "#800000" }} />
                      </span>
                      <div className='vertically-centered-modal'>
                        {
                          deleteOpen !== false &&
                          <Modal isOpen={deleteOpen} toggle={(e)=>toggleDeleteOpen1(e)} className='modal-dialog-centered'>
                            {/* <ModalHeader className='d-flex justify-content-center Delete_Media_modal_section '> */}
                            <ModalHeader className='d-flex justify-content-center primary ' style={{ backgroundColor: '#81c14b' }}>
                              <h4 style={{ color: 'white' }}>Delete Education ? </h4>
                            </ModalHeader>
                            <ModalBody>

                              <div className='d-flex justify-content-center modalHeading_Section'>
                                <p>Do you really want to delete ?</p>
                              </div>
                              <br />
                              <div className='d-flex justify-content-center  mt-4'>
                                <Button onClick={(e)=>toggleDeleteOpen1(e)} outline >Cancel</Button>
                                <Button onClick={(e)=>deleteUserEducation(e)} className="ml-3 Delete_Media_modal_section " style={{ color: 'white' }}>OK</Button>
                              </div>
                            </ModalBody>
                          </Modal>
                        }
                      </div>
                    </div>

                  </div>
                ))}
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
      <div className="Modal_section Add">
        {addOpen !== false && (
          <Modal
            isOpen={addOpen}
            toggle={toggleAdd}
            className="modal-dialog-centered"
          >
            <div className="w-100 border-bottom">
              <div className="mx-3 py-4 Profession_Modal_section">
                <h4>Add Education</h4>
                <p onClick={saveUserEducation}>Save</p>
              </div>
             
              {error&&(<p style={{ color: 'red', textAlign: 'right' }}>
                {error}&nbsp;&nbsp;</p>)}
              <br/>
            </div>
            <ModalBody>
              <div className="py-2 d-flex justify-content-around">
                <div className="d-flex align-items-center ">
                  <Radio
                    checked={selectedValue === "school"}
                    onChange={handleChange}
                    value={"school"}
                    name="radio-buttons"
                    className="Radio_btn_check"
                    color="primary"
                    inputProps={{ "aria-label": "School" }}
                  />
                  <FormLabel className="ml-2 mt-2">Add your school</FormLabel>
                </div>
                <div className="d-flex align-items-center  ">
                  <Radio
                    checked={selectedValue === "college"}
                    onChange={handleChange}
                    value={"college"}
                    name="radio-buttons"
                    className="Radio_btn_check"
                    inputProps={{ "aria-label": "College" }}
                  />
                  <FormLabel className="ml-2 mt-2">Add your college</FormLabel>
                </div>
              </div>

              {selectedValue === "school" && (
                <>
                  <div className="py-2">
                    <FormGroup>
                      <Label className="Bio_text">Institution Name</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="Enter institution name (Required)"
                          type="text"
                          className="inputForm"
                          value={schoolName}
                          onChange={handleSchoolNameChange}
                          onBlur={handleBlur}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                          onClick={() => handleSchoolNameChange1()}
                        />
                        {isTouched && schoolName =='' && schoolName.trim() === '' && (
                          <p style={{ color: 'red', fontSize: '12px', marginTop: '5px', textAlign: 'right' }}>This field is required</p>
                        )}
                      </div>
                      <ul>
                        {suggestionModal && resultAllSchool?.map((suggestion) => (
                          <li
                            key={suggestion.id}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 2px #888888",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                      <br />

                      <Label className="Bio_text">Qualification</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="qualification (Required)"
                          type="text"
                          className="inputForm"
                          value={qualificationName}
                          onChange={handleQualificationNameChange}
                          onBlur={handleBlur1}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                          onClick={() => handleQualificationNameChange1()}
                        />
                        {isTouched1 && qualificationName =='' && qualificationName.trim() === '' && (
                          <p style={{ color: 'red', fontSize: '12px', marginTop: '5px', textAlign: 'right' }}>This field is required</p>
                        )}
                      </div>
                      <ul className="qualificationList">
                        {qualificationSuggestionModal && resultAllQualification?.map((qualification) => (
                          <li
                            key={qualification.id}
                            onClick={() =>
                              handleQualificationClick(qualification)
                            }
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 4px #888888",
                            }}
                          >
                            {qualification.name}
                          </li>
                        ))}
                      </ul>
                      <br />
                    </FormGroup>
                    <br />
                    <Row className="d-flex justify-content-between">
                      <Col>
                        <FormGroup>
                          <h4>Batch</h4>
                          <br/>
                          <Input
                            name="datetime"
                            type="date"
                            value={fromDateUser}
                            onChange={(e) => setFromDateUser(e.target.value)}
                            className="w-40"
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col>
                        <FormGroup>
                          <br/>
                          <Input
                            name="datetime"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            min={fromDateUser}
                            style={{ marginTop: "18px" }}
                            disabled={!fromDateUser}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />

                    <div>
                      <Label className="Bio_text">Description</Label>
                      <textarea
                        placeholder="Description (optional)"
                        className="form-control"
                        name=""
                        id=""
                        cols="15"
                        rows="5"
                        maxLength={180}
                        onChange={descriptionOnChange}
                      ></textarea>
                      <p className="Descriptionvalidation">
                        Max 180 Characters{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {selectedValue === "college" && (
                <>
                  <div className="py-2">
                    <FormGroup>
                      <Label className="Bio_text">Institution Name</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="Enter institution name (Required)"
                          type="text"
                          className="inputForm"
                          value={collegeName}
                          onChange={handleCollegeNameChange}
                          onBlur={handleBlur}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                        />
                        {isTouched && collegeName =='' && collegeName.trim() === '' && (
                          <p style={{ color: 'red', fontSize: '12px', marginTop: '5px', textAlign: 'right' }}>This field is required</p>
                        )}
                      </div>
                      <ul>
                        {suggestionModal && resultAllSchool?.map((suggestion) => (
                          <li
                            key={suggestion.id}
                            onClick={() => handleSuggestionClickCollege(suggestion)}
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 2px #888888",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                      <br />
                      <Label className="Bio_text">Qualification</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="qualification (Required)"
                          type="text"
                          className="inputForm"
                          value={qualificationName}
                          onChange={handleQualificationNameChange}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                        />
                      </div>
                      <ul className="qualificationList">
                        {qualificationSuggestionModal && resultAllQualification?.map((qualification) => (
                          <li
                            key={qualification.id}
                            onClick={() =>
                              handleQualificationClick(qualification)
                            }
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 4px #888888",
                            }}
                          >
                            {qualification.name}
                          </li>
                        ))}
                      </ul>
                      <br />
                    </FormGroup>
                    <br />
                    <Row className="d-flex justify-content-between">
                      <Col>
                        <FormGroup>
                          <h4>Batch</h4>
                          <br/>
                          <Input
                            name="datetime"
                            type="date"
                            value={fromDateUser || ''}
                            onChange={(e) => setFromDateUser(e.target.value)}
                            className="w-40"
                            placeholder={fromDateUser ? "" : "From"}
                          />
                        </FormGroup>
                      </Col>
                        <Col>
                        <FormGroup>
                          <br/>
                          <Input
                            name="datetime"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            min={fromDateUser}
                            style={{ marginTop: "18px" }}
                            disabled={!fromDateUser}
                          />
                        </FormGroup>
                      </Col> 
                    </Row>
                    <br />
                    <div className="d-flex justify-content-between">
                      <Label className="Bio_text">Graduate</Label>
                      <label className="switch ml-4">
                        <input
                          type="checkbox"
                          checked={!isGraduate}
                          onChange={handleCheckboxChange} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div>
                      <Label className="Bio_text">Description</Label>
                      <textarea
                        placeholder="Description (optional)"
                        className="form-control"
                        name=""
                        id=""
                        cols="15"
                        rows="5"
                        onChange={descriptionOnChange}
                      ></textarea>
                      <p className="Descriptionvalidation">
                        Max 180 Characters{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
          </Modal>
        )}
      </div>

      <div className="Modal_section Add">
        {editOpen !== false && (
          <Modal
            isOpen={editOpen}
            toggle={editAdd}
            className="modal-dialog-centered"
          >
            <div className="w-100 border-bottom">
              <div className="mx-3 py-4 Profession_Modal_section">
                <h4>Edit Education</h4>
                <p onClick={editUserEducation(eduId)}>Save</p>
              </div>
              {error&&(<p style={{ color: 'red', textAlign: 'right' }}>
                {error}&nbsp;&nbsp;</p>)}
              <br/>
            </div>
            <ModalBody>
              <div className="py-2 d-flex justify-content-around">
                <div className="d-flex align-items-center ">
                  <Radio
                    checked={selectedValue == "school"}
                    onChange={handleChange}
                    value={"school"}
                    name="radio-buttons"
                    className="Radio_btn_check"
                    color="primary"
                    inputProps={{ "aria-label": "School" }}
                  />
                  <FormLabel className="ml-2 mt-2">Add your school</FormLabel>
                </div>
                <div className="d-flex align-items-center  ">
                  <Radio
                    checked={selectedValue == "college"}
                    onChange={handleChange}
                    value={"college"}
                    name="radio-buttons"
                    className="Radio_btn_check"
                    inputProps={{ "aria-label": "College" }}
                  />
                  <FormLabel className="ml-2 mt-2">Add your college</FormLabel>
                </div>
              </div>

              {selectedValue === "school" && (
                <>
                  <div className="py-2">
                    <FormGroup>
                      <Label className="Bio_text">Institution Name</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="Enter institution name"
                          type="text"
                          className="inputForm"
                          value={schoolName}
                          onChange={handleSchoolNameChange}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                          onClick={handleSchoolNameChange1}
                        />
                      </div>
                      <ul>
                        {suggestionModal && resultAllSchool?.map((suggestion) => (
                          <li
                            key={suggestion.id}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 2px #888888",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                      <br />

                      <Label className="Bio_text">Qualification</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="qualification (Required)"
                          type="text"
                          className="inputForm"
                          value={qualificationName}
                          onChange={handleQualificationNameChange}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                          onClick={handleQualificationNameChange1}
                        />
                      </div>
                      <ul className="qualificationList">
                        {qualificationSuggestionModal && resultAllQualification?.map((qualification) => (
                          <li
                            key={qualification.id}
                            onClick={() =>
                              handleQualificationClick(qualification)
                            }
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 4px #888888",
                            }}
                          >
                            {qualification.name}
                          </li>
                        ))}
                      </ul>
                      <br />
                    </FormGroup>
                    <br />
                    <Row className="d-flex justify-content-between">
                      <Col>
                        <FormGroup>
                          <h4>Batch</h4>
                          <Input
                            name="datetime"
                            type="date"
                            value={fromDateUser}
                            onChange={(e) => setFromDateUser(e.target.value)}
                            className="w-40"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            name="datetime"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            style={{ marginTop: "18px" }}
                            min={fromDateUser}
                            disabled={!fromDateUser}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />

                    <div>
                      <Label className="Bio_text">Description</Label>
                      <textarea
                        placeholder="Description (optional)"
                        className="form-control"
                        name=""
                        id=""
                        cols="15"
                        rows="5"
                        maxLength={180}
                        onChange={descriptionOnChange}
                      ></textarea>
                      <p className="Descriptionvalidation">
                        Max 180 Characters{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {selectedValue === "college" && (
                <>
                  <div className="py-2">
                    <FormGroup>
                      <Label className="Bio_text">Institution Name</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="Enter institution name"
                          type="text"
                          className="inputForm"
                          value={collegeName}
                          onChange={handleCollegeNameChange}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                        />
                      </div>
                      <ul>
                        {suggestionModal && resultAllSchool?.map((suggestion) => (
                          <li
                            key={suggestion.id}
                            onClick={() => handleSuggestionClickCollege(suggestion)}
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 2px #888888",
                            }}
                          >
                            {suggestion.name}
                          </li>
                        ))}
                      </ul>
                      <br />
                      <Label className="Bio_text">Qualification</Label>
                      <div className="form_control_section_input_box">
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="qualification (optional)"
                          type="text"
                          className="inputForm"
                          value={qualificationName}
                          onChange={handleQualificationNameChange}
                        />
                        <PlusCircle
                          className="Add_box_section"
                          color="#81C14B"
                          size={17}
                        />
                      </div>
                      <ul className="qualificationList">
                        {qualificationSuggestionModal && resultAllQualification?.map((qualification) => (
                          <li
                            key={qualification.id}
                            onClick={() =>
                              handleQualificationClick(qualification)
                            }
                            style={{
                              margin: "5px",
                              padding: "8px",
                              cursor: "pointer",
                              // boxShadow: "2px 2px 4px #888888",
                            }}
                          >
                            {qualification.name}
                          </li>
                        ))}
                      </ul>
                      <br />
                    </FormGroup>
                    <br />
                    <Row className="d-flex justify-content-between">
                      <Col>
                        <FormGroup>
                          <h4>Batch</h4>
                          <Input
                            name="datetime"
                            type="date"
                            value={fromDateUser}
                            onChange={(e) => setFromDateUser(e.target.value)}
                            className="w-40"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            name="datetime"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            style={{ marginTop: "18px" }}
                            disabled={!fromDateUser}
                            min={fromDateUser}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <div className="d-flex justify-content-between">
                      <Label className="Bio_text">Graduate</Label>
                      <label className="switch ml-4">
                        <input
                          type="checkbox"
                          checked={!isGraduate}
                          onChange={handleCheckboxChange} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div>
                      <Label className="Bio_text">Description</Label>
                      <textarea
                        placeholder="Description (optional)"
                        className="form-control"
                        name=""
                        id=""
                        cols="15"
                        rows="5"
                        onChange={descriptionOnChange}
                      ></textarea>
                      <p className="Descriptionvalidation">
                        Max 180 Characters{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </ModalBody>
          </Modal>
        )}
      </div>

     

    </Fragment>
  );
};

export default MyEducation;
