import React, { Fragment, useState } from 'react'
import UserImg from '../../NewComponents/IMG/img.svg'
import CommentActive from '../../NewComponents/IMG/CommentActive.svg'
import ActiveCirclelike from '../../NewComponents/IMG/ActiveCirclelike.svg'
import LikeImg from '../../NewComponents/IMG/LikeImg.svg'
import CmntImg from '../../NewComponents/IMG/CmntImg.svg'
import ShrImg from '../../NewComponents/IMG/ShrImg.svg'
import ca from '../Bookmark-Components/Img/ca.svg'
import left from '../Bookmark-Components/Img/left.svg'
import right from '../Bookmark-Components/Img/right.svg'
import ReImg from '../Bookmark-Components/Img/ReImg.svg'
import play from '../Bookmark-Components/Img/play.svg'
import poll from '../Bookmark-Components/Img/poll.svg'
import mic1 from '../Bookmark-Components/Img/mic1.png'
import Frame from '../Bookmark-Components/Img/Frame.svg'
import livelink from '../Bookmark-Components/Img/livelink.svg'
import sngle1 from '../Bookmark-Components/Img/sngle1.svg'
import paid from '../Bookmark-Components/Img/paid.png'
import twk from '../Bookmark-Components/Img/twk.png'
import Rect from '../Bookmark-Components/Img/Rect.svg'
import Rpodcast from '../Bookmark-Components/Img/Rpodcast.png'
import f5 from '../Bookmark-Components/Img/f5.png'
import { Button, Card, CardBody, CardFooter, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledButtonDropdown, Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { AlertTriangle, BellOff, Bookmark, ChevronLeft, ChevronRight, Circle, Copy, EyeOff, MoreHorizontal, Send, Share2, Smile, UserMinus, Users, Play, Pause, MapPin, MessageCircle, PhoneCall, Mic } from 'react-feather'
import { emoteData } from '../Social-Feed-Component/FeedArray'
import mic from '../Bookmark-Components/Img/mic.png'
import Rec from '../Bookmark-Components/Img/Rec.png'
import Frames from '../Bookmark-Components/Img/Frames.png'
import bookmark from '../Bookmark-Components/Img/bookmark.png'
import {
    SingleMediaPost,
    SingleMediaNoDespPost,
    DoubleMediaNoDespPost,
    TextPostDisplay,
    TextTagPostDisplay,
    MediaPostDisplay,
    MediaPostVideoType,
    SingleVideoType,
    AlertPostDisplayModerate,
    RecommendationPostDisplay,
    RecmndNoImgPostDisplay,
    AlertPostDisplayMild,
    AlertPostDisplaySevere,
    PollPostDisplayUnfilled,
    PollPostDisplayfilled,
    EventPostDisplay,
    SingleMediaCaptionPost,
    ThroughtPostDisplay,
    SngleUploadPcPost,
    EpisodePodcast,
    PaidSnglePcPost,
    PaidSeriesPcPost,
    FreeSeriesPcPost,
    DfltUploadPcPost,
    PaidPodcastPost,
    MarketPlacePost,
    MarketPlaceSngleAction,
    ReSharePostType
} from './AllPostDisplay/PostDisplayPrev'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import ShareModal from './Modals/ShareModal'

const NewBookMark = () => {
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [VideoOpen, setVideoClose] = useState(false)
    const toggleVideo = () => {
        setVideoClose(!VideoOpen)
    }
    const [playbutton, setplaybutton] = useState(false);
    //================================================================= State and function for Like Comment and Share ===================================================================//

    const [LikeIcon, SetLikeIcon] = useState(false)
    const [BookmarkIcon, SetBookmark] = useState(false)
    const [comment, SetComment] = useState(false)

    const Commenttoggle = () => {
        SetComment(!comment)
    }
    const [commentR, SetCommentR] = useState('')
    const CommenttoggleR = () => {
        SetCommentR('I am seeking recommendation for buying ')
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const [seemore, setSeeMore] = useState(false);
    const [isGreen, setIsGreen] = useState(false);
    const EmoteAvatar = () => {

        return (
            <div className='px-2'>
                <ul className="matual-friend-blk">
                    {
                        emoteData.slice(0, 4).map((item, index) => {
                            return (

                                <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="sufiya eliza" data-img={item.img}>
                                    <img src={item.img} className="bg-img" alt="" />
                                </li>

                            )
                        })
                    }
                </ul>
            </div >
        )
    }

    //================================================================= All post type data (Same as social feed) ===================================================================//
    const AllPostTypeDatas = [
        {
            title1: 'Sufiya Eliza ',
            data: 'Denmark is a Scandinavian country comprising',
            img1: 'https://images.pexels.com/photos/416024/pexels-photo-416024.jpeg?auto=compress&cs=tinysrgb&w=1600',
            text: 'is feeling happy with',
            text1: 'Matt',
            text2: '19 others',
            text3: 'in',
            text4: '354B',
            icon: <ChevronRight size={17} className='text-primary' />,
            // read: '.....Read more',
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg
        },
        {
            title1: 'Jason Bourne',
            img1: 'https://images.pexels.com/photos/76971/fighter-jet-fighter-aircraft-f-16-falcon-aircraft-76971.jpeg?auto=compress&cs=tinysrgb&w=1600',
            tomText: <h2>Wooden Horse Sculpture on sale</h2>,
            data1: <div className='d-flex  p-2'><h3 className='usdtext' style={{ color: '#4D4D4D' }}>$ 41 (USD)<br></br><p style={{ color: 'gray' }}>In Stock | New</p></h3><button style={{ marginLeft: '5rem', backgroundColor: '#81C14B' }} className='form-control'><MessageCircle size={20} color='white' /></button>&nbsp;<button className='form-control' style={{ backgroundColor: '#81C14B' }}><PhoneCall size={20} color='white' /></button></div>,
            // datatext: 'There is going to be some serious consequence on the eastern coast of Odisha, after itll be hit by a Tsunami originally originated in the Bay of Bengal, 137kms far from the coast and whose epicenter lies 48km below earths surface.',
            data: <div className='mt-2'><MapPin size={16} color='gray' />&nbsp;&nbsp;Güntherstra, Frankfurt , Germany</div>,
            toptext: <p className='' style={{ color: 'gray' }}>Home & Decor | Sculpture</p>,

            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg
        },

        // {
        //     title1: 'Sufiya Eliza ',
        //     data: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. has been the industrys standard dummy text ever since the 1500s....',
        //     img1: Rec,
        //     tomText: <h2>Wooden Horse Sculpture on sale</h2>,
        //     toptext: 'Home & Decor | Sculpture',

        // },
        {
            title1: 'Jason Bourne',
            img1: 'https://images.pexels.com/photos/618833/pexels-photo-618833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            data: 'Lorem Ipsum is simply dummy  ever since 1500s..',
            texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
            // read: '.....Read more',
            content: (
                <div className='bookmark-content'>
                    <p className='bookmark-content-captionss'>The Swiss mountains are a treat to your eyes, heart & Brain !!!!</p>
                </div>
            ),
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg

        },
        {
            title1: 'Jason Bourne',
            alert: <div className='px-4 sizeForText p-2 rounded-2 SingleMedia_Content_blk text-server   ' style={{ backgroundColor: 'red', color: 'white', height: '13rem' }}>
                <i className="fa fa-quote-left ml-2" style={{ height: '25px', width: '25px', color: '#0075ff', marginTop: '2rem' }}></i>
                <p className=' text-center mt-2'>
                    There is going to be on drink ingredients before youThere is going to be some serious Again at CloudPlatform Provider Zoho Cloud Platform @DwyaneJhonson Threat Group
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <p className="tex" style={{ paddingRight: '1rem' }}>
                        <i className="fa fa-quote-right" style={{ height: '25px', width: '25px', color: '#0075ff' }}></i>
                    </p>
                </div>
            </div>,
            server: <div className="pvt-eventconts" style={{ background: '#FF822E', top: '5rem' }} >#Severe</div>,

        },
        {
            title1: 'Jason Bourne',
            img1: ReImg,
            data: 'Lorem Ipsum is simply dummy  ever since 1500s..',
            texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
            // read: '.....Read more',
            content: (
                <div className='bookmark-content'>
                    <p className='bookmark-content-captionss'>The Swiss mountains are a treat to your eyes, heart & Brain !!!!</p>
                </div>
            ),
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg

        },
        {
            title1: 'Sufiya Eliza ',
            img1: 'https://images.pexels.com/photos/2676096/pexels-photo-2676096.jpeg?auto=compress&cs=tinysrgb&w=1600',
            text: 'is feeling happy with',
            text1: 'Matt',
            text2: '19 others',
            text3: 'in',
            text4: '354B',
            title: 'Recommendation',
            icon: <ChevronRight size={17} className='text-primary' />,
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            data: <h3>I am seeking recommendation for buying a car..</h3>,
            // comment: CmntImg,
            // shrc: ShrImg,
            icon1: <Circle size={5} fill='gray' />,
            Recommendation: <div className='bookmark-contentt'><div className='d-flex justify-content-between align-items-center w-100 bookmark-Recommendations-content-captionss'>
                <h4 className="text-white">25 Recommendations</h4>
                <Button color='white' className='btn btn-outline' outline style={{ height: '2.3rem', width: '7rem' }}>Recommend</Button>
            </div>
            </div>
        },
        {
            title1: 'Sufiya Eliza ',
            img1: 'https://images.pexels.com/photos/13694183/pexels-photo-13694183.jpeg?auto=compress&cs=tinysrgb&w=1600',
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg,
            play: play,
            data: <h3>I am seeking recommendation for buying a car..</h3>,
        },
        {
            title1: 'Sufiya Eliza ',
            // img1: 'https://images.pexels.com/photos/13694183/pexels-photo-13694183.jpeg?auto=compress&cs=tinysrgb&w=1600',
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg,
            textss: <p style={{ fontSize: '14px', color: 'gray', backgroundColor: 'rgb(230, 243, 219)', height: '66px', width: '122%', marginLeft: '-43px' }} className='p-2 rounded-2 mt-4 pollcards'><img src={poll} alt='' />&nbsp;&nbsp;The organization invests time and money to keep the employee technology...</p>,
            data: <div className='d-flex justify-content-between align-items-center p-2' style={{ marginTop: '-1.2rem' }}>
                <div className='d-flex align-items-center'>
                    <h3 className='text-primary'>10</h3>
                    <small className=''>&nbsp;Options</small>
                </div>
                <div className='d-flex align-items-center'>
                    <h3 className='text-primary'>156</h3>
                    <small className=''>&nbsp;Voted</small>
                </div>
                <div className='d-flex align-items-center pr-1'>
                    <h3 className='text-primary'>144</h3>
                    <small className=''>&nbsp;Viewed</small>
                </div>
            </div>,
            data1: <p style={{ marginLeft: '0.6rem', color: 'gray', marginTop: '-1.5rem' }}>Posted on 23 Mar 2021</p>
        },
        {
            title1: 'Jason Bourne',
            img1: 'https://images.pexels.com/photos/2167380/pexels-photo-2167380.jpeg?auto=compress&cs=tinysrgb&w=1600',
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg,
            play: play,
            data: <h3>The Swiss mountains are a treat !!!!</h3>,
        },
        {
            title1: 'Sufiya Eliza ',
            img1: 'https://images.pexels.com/photos/6883804/pexels-photo-6883804.jpeg?auto=compress&cs=tinysrgb&w=1600',
            text: 'is feeling happy with',
            text1: 'Matt',
            text2: '19 others',
            text3: 'in',
            text4: '354B',
            title: 'Recommendation',
            icon: <ChevronRight size={17} className='text-primary' />,
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            data: <h3>I am seeking recommendation for buying a car..</h3>,
            // comment: CmntImg,
            // shrc: ShrImg,
            icon1: <Circle size={5} fill='gray' />,
            Recommendation: <div className='bookmark-content'><div className='d-flex justify-content-between align-items-center w-100 positionForbgColorReccos bookmark-Recommendations-content-captionss'>
                <h4 className="text-white">10 Recommendations</h4>
                <Button color='white' className='btn btn-outline' outline style={{ height: '2.3rem', width: '7rem' }}>Recommend</Button>
            </div>
            </div>
        },
        {
            title1: 'Jason Bourne',
            alert: <div className='px-4 sizeForText p-2 rounded-2 SingleMedia_Content_blk text-server' style={{ backgroundColor: 'rgb(255, 214, 0)', color: 'black', height: '13rem' }}>
                <i className="fa fa-quote-left ml-2" style={{ height: '25px', width: '25px', color: '#0075ff', marginTop: '2rem' }}></i>
                <p className=' text-center mt-2'>
                    How far can you bend on drink ingredients before youThere is going to be some serious Again at CloudPlatform Provider Zoho Cloud Platform @DwyaneJhonson Threat Group
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <p className="tex" style={{ paddingRight: '1rem' }}>
                        <i className="fa fa-quote-right" style={{ height: '25px', width: '25px', color: '#0075ff' }}></i>
                    </p>
                </div>
            </div>,
            server: <div className="pvt-eventconts" style={{ background: '#FF822E', top: '5rem' }} >#Mild</div>,

        },
        {
            title1: 'Sufiya Eliza ',
            data: 'The world famous masterpiece equivalent...',
            img1: 'https://kurifly.com/wp-content/uploads/2021/08/WhatsApp-Image-2021-08-21-at-7.15.30-PM.jpeg',
            text: 'is feeling happy with',
            text1: 'Matt',
            text2: '19 others',
            text3: 'in',
            text4: '354B',
            icon: <ChevronRight size={17} className='text-primary' />,
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg,
            // data1:'Home & Decor | Sculpture | Aesthetics',
            // data2:' Danish nagar Bagmugaliya, Bhopal, Madhya Pradesh 462026',
        },
        {
            title1: 'Jason Bourne',
            img1: 'https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300',
            data: ' A mountain is an elevated portion of the Earths crust generally with steep ..',
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg,
        },
        {
            title1: 'Jason Bourne',
            alert: <div className='px-4 sizeForText p-2 rounded-2 SingleMedia_Content_blk text-server' style={{ backgroundColor: 'rgb(31, 78, 105)', color: 'rgb(248, 223, 136)', height: '13rem' }}>
                <i className="fa fa-quote-left ml-2" style={{ height: '25px', width: '25px', color: '#0075ff', marginTop: '2rem' }}></i>
                <p className=' text-center mt-2'>
                    How far can you bend on drink ingredients before youThere is going to be some serious Again at CloudPlatform Provider Zoho Cloud Platform @DwyaneJhonson Threat Group
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <p className="tex" style={{ paddingRight: '1rem' }}>
                        <i className="fa fa-quote-right" style={{ height: '25px', width: '25px', color: '#0075ff' }}></i>
                    </p>
                </div>
            </div>,

        },
        {
            title1: 'Jason Bourne',
            data: <div className='North'><MapPin size={16} color='gray' />&nbsp;&nbsp;Bessemer, North Carolina</div>,
            data1: <div className='ml-1' style={{ marginTop: '-8px' }}><p style={{ color: 'gray' }}><Users size={15} color='gray' />&nbsp;&nbsp;160 Going&nbsp;&nbsp;&nbsp;&nbsp;<Users size={15} color='gray' />&nbsp;&nbsp;154 Not Interested</p></div>,
            data2: '',
            texts: 'Sun, 26 Jun 2022, 06:00PM - 09:30AM',
            img1: Rect,
            server: <div className="pvt-eventconts" style={{ background: '#FF822E', top: '80px' }} >Entertainment</div>,
        },

        {
            title1: 'Jason Bourne',
            alert: <div className='px-4 sizeForText p-2 rounded-2 SingleMedia_Content_blk text-server' style={{ backgroundColor: 'red', color: 'white', height: '13rem' }}>
                <i className="fa fa-quote-left ml-2" style={{ height: '25px', width: '25px', color: '#0075ff', marginTop: '2rem' }}></i>
                <p className=' text-center mt-2'>
                    There is going to be on drink ingredients before youThere is going to be some serious Again at CloudPlatform Provider Zoho Cloud Platform @DwyaneJhonson Threat Group
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <p className="tex" style={{ paddingRight: '1rem' }}>
                        <i className="fa fa-quote-right" style={{ height: '25px', width: '25px', color: '#0075ff' }}></i>
                    </p>
                </div>
            </div>,
            server: <div className="pvt-eventconts" style={{ background: '#FF822E', top: '5rem' }} >#Moderate</div>,

        },
        {
            title1: 'Jason Bourne',
            data: '',
            img1: sngle1,
            content: (
                <div className='bookmark-content'>
                    <div style={{ position: 'relative' }}>
                        {/* <img src={paid} alt='paid' style={{ position: 'absolute', top: '-7.5rem', left: '19.5rem' }} /> */}
                    </div>
                    <p className='p-2 bookmark-content-caption' >The transition to the hybrid era has not only affected our ways of viewing leader throughout the prison...
                        <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2 Intertext'>International Politics</span>&nbsp;<Mic size={16} />5k
                        <div className='d-flex justify-content-between pt-3'>
                            <p className=''>12th Apr 2021, 5 mins</p>
                        </div>
                    </p>
                </div>

            ),
        },
        {
            title1: 'Jason Bourne',
            data: ' Denmark is a Scandinavian country comprising ..',
            texts: 'The transition to the hybrid era has not only affected our ways of viewing',
            // img1: f5,
            img1:bookmark,
            // read: '.....Read more',
            content: (
                <div className='bookmark-contentt'>
                    <p className='bookmark-content-captionn'>The transition to the hybrid era has not only affected our ways of viewing leader throughout the prison to ...see more<br /><br />
                        <span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2'>Episode-5</span>&nbsp;<Mic size={16} />5k
                        <div className='d-flex justify-content-between mt-2'>
                            <p className='p-1'>12th Apr 2021, 5 mins</p>
                            {/* <p><img src={paid} alt='' className='Paid_bottom_tag' /></p> */}
                        </div>
                    </p>
                </div>
            ),
            content1: (
                <div style={{
                    position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.2)', // Transparent gray color
                    color: 'white',
                    top: '63px',
                    fontSize: '13px',
                    padding: '10px',
                    height: '98px',
                    width: '100%',
                    boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
                }}
                >
                    <div className='d-flex justify-content-between'>
                        <p style={{ marginTop: '-13px' }} className=' mt-1'>International Work-life Podcast...</p>
                        <p><button className='FollowRequest_Accept_btn btn btn-primary ml-2'>All Episodes</button></p>
                    </div>
                    <p style={{}} className=' pt-1'>International Politics <Circle size={7} fill='gray' /> Total episodes (16)</p>
                    <p><img src={twk} alt='' className='mt-2' /></p>
                </div>

            ),
        },
        {
            title1: 'Jason Bourne',
            img1: 'https://images.pexels.com/photos/2167380/pexels-photo-2167380.jpeg?auto=compress&cs=tinysrgb&w=1600',
            // LikeIcon: ActiveCirclelike,
            // likeimg: LikeImg,
            // text6: 24,
            // comment: CmntImg,
            // shrc: ShrImg,
            data: <h3>The Swiss mountains your eyes, heart & Brain!!!</h3>,
        },
        {
            title1: 'Jason Bourne',
            data: ' Denmark is a Scandinavian country comprising..',
            texts: 'The transition to the hybrid era has not only affected our ways of viewing',
            img1: sngle1,
            // read: '.....Read more',
            content: (
                <div className='bookmark-content'>
                    <div style={{ position: 'relative' }}>
                        {/* <img src={paid} alt='paid' style={{ position: 'absolute', top: '-7.5rem', left: '19.5rem' }} /> */}
                    </div>
                    <p className='p-2 bookmark-content-caption' >The transition to the hybrid era has not only affected our ways of viewing leader throughout the prison...
                        <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2 Intertext'>Episode-5</span>&nbsp;<Mic size={16} />5k
                        <div className='d-flex justify-content-between pt-3'>
                            <p className=''>12th Apr 2021, 5 mins</p>
                        </div>
                    </p>
                </div>

            ),
            content1: (
                <div style={{
                    position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.2)', // Transparent gray color
                    color: 'white',
                    top: '63px',
                    fontSize: '13px',
                    padding: '10px',
                    height: '105px',
                    width: '100%',
                    boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
                }}
                >
                    <div className='d-flex justify-content-between'>
                        <p style={{ marginTop: '-13px' }} className=' mt-1'>International Work-life Podcast...</p>
                        <p><button className='FollowRequest_Accept_btn btn btn-primary ml-3'>All Episodes</button></p>
                    </div>
                    <p style={{}} className=' pt-1'>International Politics <Circle size={7} fill='gray' /> Total episodes (16)</p>
                    <p><img src={twk} alt='' className='mt-2' /></p>

                </div>

            ),
        },
        {
            title1: 'Jason Bourne',
            data: ' Denmark is a Scandinavian country comprising ..',
            texts: 'The transition to the hybrid era has not only affected our ways of viewing',
            img1: bookmark,
            // read: '.....Read more',
            content: (
                <div className='bookmark-content'>
                    {/* <p><img src={paid} alt='' className='Paid_bottom_tag bookmark-content-tag' /></p> */}
                    <p className='p-2 bookmark-content-caption'>The transition to the hybrid era has not only affected our ways of viewing leader  the prison...
                        <br /><br /><span style={{ borderRadius: '20px', backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)' }} className='p-2'>International Politics</span>&nbsp;<Mic size={16} />5k
                        <div className='d-flex justify-content-between mt-2'>
                            <p className='p-1'>12th Apr 2021, 5 mins</p>
                            {/* <p><img src={paid} alt='' className='Paid_bottom_tag' /></p> */}
                        </div>
                    </p>

                </div>
            ),
        },
        {
            title1: 'Jason Bourne',
            texts: '#Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',
            data: 'Lorem Ipsum is simply dummy text of 1500s  #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber Lorem Ipsum is simply dummy text of 1500s....',
        },
        {
            title1: 'Sufiya Eliza',
            texts: 'Article #Musiccelebration #Musiccelebration #music #party #music #AirForce #Rafael #Gripen #Su-57 #F-22 Raptor #B-52 Bomber',

            data: 'How far can you bend on drink a recipe can you bend on drink a recipeHow far can you bend on drink a recipe.....',
        },

        // {
        //     id: <SingleMediaNoDespPost />
        // },
        // <h6 className='my-2 mb-3' style={{ color: "#999999", fontSize: "14px" }}>Total Participant: 210</h6>
        // {


        //     id: <DoubleMediaNoDespPost />
        // },
        // {
        //     id: <MediaPostDisplay />
        // },
        // {
        //     id: <MediaPostVideoType toggleVideo={toggleVideo} />
        // },
        // {
        //     id: <SingleVideoType />
        // },
        // {
        //     id: < TextPostDisplay />
        // },
        // {
        //     id: <TextTagPostDisplay />,
        //     type: "Mention"
        // },
        // {
        //     id: <AlertPostDisplayModerate />
        // },
        // {
        //     id: <AlertPostDisplayMild />
        // },
        // {
        //     id: <AlertPostDisplaySevere />
        // },
        // {
        //     id: <RecommendationPostDisplay action={CommenttoggleR} />,
        //     type: "Mention",
        //     TypeRcmnd: "Rcmnd"
        // },
        // {
        //     id: <RecmndNoImgPostDisplay action={CommenttoggleR} />,
        //     TypeRcmnd: "Rcmnd"
        // },
        // {
        //     id: <ThroughtPostDisplay />
        // },
        // {
        //     id: <PollPostDisplayUnfilled />,
        //     typePoll: "PollNtCmnt"
        // },
        // {
        //     id: <PollPostDisplayfilled />,
        //     typePoll: "PollNtCmnt",
        //     type: "Mention"
        // },
        // {
        //     id: <EventPostDisplay />
        // },
        // {
        //     id: <PaidPodcastPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <PaidSnglePcPost />
        // },
        // {
        //     id: <PaidSeriesPcPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <FreeSeriesPcPost />
        // },
        // {
        //     id: <EpisodePodcast />
        // },
        // {
        //     id: <SngleUploadPcPost />,
        //     type: "Mention"
        // },
        // {
        //     id: <DfltUploadPcPost />
        // },
        // {
        //     id: <MarketPlacePost />,
        //     type: "Mention"
        // },
        // {
        //     id: <MarketPlaceSngleAction />
        // },
        // {
        //     id: <ReSharePostType />
        // }
    ]

    //=========================================================================== Main return function =============================================================================//

    return (
        <Fragment>
            <div>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 667: 2, 1157: 3 }}
                >
                    <Masonry columnsCount={3} gutter="12px">
                        {AllPostTypeDatas.map((item, index) => (
                            <div className=''>
                                <Card className='Post_Display_Crad on-hover-bg mt-1 '>
                                    <div className='bg-white border-bottom-0 w-100  on-hover-bg'>
                                        <div className='d-flex justify-content-between align-items-start p-2'>
                                            <div to={`/MyProfile?userId=${user.id}&type=creater`} className='d-flex align-items-start'>
                                                <img src={ca} className='img-fluid rounded-circle' width='40' height='40' alt='UserImage' />
                                                <div className='ml-2 PostPreView_User_Info'>
                                                    {item.type === "Mention" ?
                                                        <div>
                                                            <h2 className='text-wrap'><span className='UserProfile-Name' ><Link to={`/MyProfile?userId=${user.id}&type=creater`} style={{ color: '#4D4D4D' }} >{item.title1}</Link> </span> &nbsp;
                                                                {/* <span style={{ color: "rgba(77, 77, 77, 0.5)", fontSize: "14px" }}>is feeling happy with</span > */}
                                                                {/* &nbsp;<span style={{ fontSize: "14px" }}>Matt</span>&nbsp;
                                                                <span style={{
                                                                    color: "rgba(77, 77, 77, 0.5)",
                                                                    fontSize: "14px"
                                                                }}></span >
                                                                &nbsp;
                                                                <span style={{ fontSize: "14px" }}>19 others</span>
                                                                &nbsp;<span style={{
                                                                    color: "rgba(77, 77, 77, 0.5)",
                                                                    fontSize: "14px"
                                                                }}>in</span>
                                                                &nbsp;
                                                                <span className='Profile_Location' style={{ fontWeight: "400" }}>
                                                                    354B,
                                                                    {!seemore ? null : " Ali Ganj, Lucknow, India - 226012..."}
                                                                    <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? <ChevronLeft size={18} /> : <ChevronRight size={18} />}</span>
                                                                </span> */}
                                                            </h2>
                                                        </div> :
                                                        <h2 className=''><span className='UserProfile-Name' >{item.title1}</span>
                                                            &nbsp;{item.TypeRcmnd ? <span
                                                                style={{
                                                                    color: "rgba(77, 77, 77, 0.5)",
                                                                    fontWeight: "600",
                                                                    fontSize: "14px"
                                                                }}>asking</span> : null}
                                                            {/* ===================change text==================== */}
                                                            <span style={{ color: "rgba(77, 77, 77, 0.5)", fontSize: "14px" }}>{item.text}</span >
                                                            &nbsp;<span style={{ fontSize: "14px" }}>{item.text1}</span>&nbsp;
                                                            <span style={{
                                                                color: "rgba(77, 77, 77, 0.5)",
                                                                fontSize: "14px"
                                                            }}></span >
                                                            &nbsp;
                                                            <span style={{ fontSize: "14px" }}>{item.text2}</span>
                                                            &nbsp;<span style={{
                                                                color: "rgba(77, 77, 77, 0.5)",
                                                                fontSize: "14px"
                                                            }}>{item.text3}</span>
                                                            &nbsp;
                                                            <span className='Profile_Location' style={{ fontWeight: "400" }}>
                                                                {item.text4}
                                                                <span>{item.icon}</span>
                                                                {!seemore ? null : " Ali Ganj, Lucknow, India - 226012..."}
                                                                <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} ></span>
                                                            </span>
                                                        </h2>}
                                                    <Link to={`/MyProfile?userId=${user.id}&type=creater`} style={{ color: '#4D4D4D' }}>
                                                        <p style={{ color: 'gray', fontSize: '12px' }} className=''>20 Min ago</p>

                                                        {/* {
                                            index === 0 ? (
                                                <>
                                                    <p style={{ color: 'gray', fontSize: '12px' }} className='mt-1 mintext'>20 Min ago</p>
                                                </>
                                            ) : null
                                        } */}

                                                    </Link>

                                                    <h3 className='mt-1'>{item.textss}</h3>
                                                    <p className='mt-2'>{item.textss1}</p>
                                                    <div>{item.border}</div>
                                                    <div>{item.border1}</div>
                                                    <div>{item.border2}</div>
                                                    <div>{item.border3}</div>
                                                    <div>{item.border4}</div>
                                                </div>
                                            </div>
                                            {/* <p>{item.mic}</p> */}
                                            <div>
                                                {/* {
                                                    index === 16 ? <img src={mic} alt="" style={{ position: 'absolute', bottom: '40%', left: '37%', borderRadius: '50px', backgroundColor: 'rgba(255,255,255, 0.3)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)', padding: '15px' }} /> : ""
                                                } */}
                                                {
                                                    index === 16 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                                                }
                                                {/* {
                                                    index === 16 ? <img src={livelink} alt="" style={{ position: 'absolute', bottom: '20%', left: '15%' }} className="blinking-form" /> : ""
                                                } */}
                                                {
                                                    index === 17 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                                                }
                                                {/* {
                                                    index === 17 ? <img src={mic} alt="" style={{ position: 'absolute', bottom: '58%', left: '37%', borderRadius: '50px', backgroundColor: 'rgba(255,255,255, 0.3)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)', padding: '15px' }} /> : ""
                                                }
                                                {
                                                    index === 17 ? <img src={livelink} alt="" style={{ position: 'absolute', bottom: '45%', left: '15%' }} className="blinking-form" /> : ""
                                                } */}
                                                {/* {
                                                    index === 19 ? <img src={mic} alt="" style={{ position: 'absolute', bottom: '54%', left: '36%', borderRadius: '50px', backgroundColor: 'rgba(255,255,255, 0.3)', boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)', padding: '15px' }} /> : ""
                                                }
                                                {
                                                    index === 19 ? <img src={livelink} alt="" style={{ position: 'absolute', bottom: '40%', left: '15%' }} className="blinking-form" /> : ""
                                                } */}
                                                {
                                                    index === 19 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                                                }
                                                {
                                                    index === 20 ? <img src={Frames} alt='' style={{ position: 'absolute', top: '47%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinking-form blinkicons' /> : ""
                                                }
                                            </div>
                                            <div className='d-flex'>
                                                <div className='rem-mob-view' onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '8px', backgroundColor: 'rgba(126, 178, 98, 0.1)', borderRadius: '5px', cursor: 'pointer', marginRight: '-0.7rem' }}   >
                                                    {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                                                </div>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle style={{ padding: '0 0 0 10px ' }} color='flat-secondary' >
                                                        {/* <MoreHorizontal size={19} style={{ color: '#999999' }} /> */}
                                                    </DropdownToggle>
                                                    <DropdownMenu style={{ zIndex: '2', left: '-255px', minWidth: 'fit-content', color: '#A6A6A6' }}>
                                                        <DropdownItem style={{ color: '#808080' }} ><EyeOff size={15} color='#A6A6A6' />  &nbsp;Hide post from your timeline</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><BellOff size={15} color='#A6A6A6' /> &nbsp;Turn off this post notification</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><Share2 size={15} color='#A6A6A6' /> &nbsp;Share via</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><Copy size={15} color='#A6A6A6' /> &nbsp;Copy link</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><UserMinus size={15} color='#A6A6A6' /> &nbsp;Unfollow “$username”</DropdownItem>
                                                        <DropdownItem style={{ color: '#808080' }} ><AlertTriangle size={15} color='#A6A6A6' /> &nbsp;Report this Post</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </div>
                                        {/* ================================text======================================== */}
                                        <div className='SingleMedia_blk' >
                                            <Link to={`/MyProfile?userId=${user.id}&type=creater`} >{item.img1 ? <div className='bookmark-post-card-image'><img src={item.img1} alt='singleMedia' className='img-fluid' /> {item.content && item.content}{item.Recommendation && item.Recommendation}</div> : null}</Link>
                                            <h5 style={{ cursor: 'pointer' }} className='text-primary p-2'>{item.texts}</h5>
                                            <h5 style={{ cursor: 'pointer', color: '#4D4D4D' }} className='pl-1 mt-2'>{item.tomText}</h5>
                                        </div>
                                        <div>
                                            {
                                                index === 17 ? <div className='d-flex align-items-center justify-content-between p-1'>
                                                    {playbutton ? <Play onClick={() => setplaybutton(!playbutton)} fill='#000000' /> :
                                                        <Pause fill='#000000' onClick={() => setplaybutton(!playbutton)} />}
                                                    &nbsp;&nbsp;<Progress className='sizeForpro progressFortimeLine progress-bar-success w-100' value={35} />&nbsp;&nbsp;
                                                    <small style={{ marginRight: '6px' }}>02:45</small>
                                                </div> : ""
                                            }
                                        </div>
                                        <div >{item.server}</div>
                                        <div>
                                            <div>{item.alert}</div>
                                            <div className='text-right'>
                                                {/* <img src={item.imgss} alt="icon" /> */}
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative' }} >
                                            <h5>{item.content}</h5>
                                        </div>
                                        <h5>{item.content1}</h5>
                                        <div >
                                            <p className='mt-2'>{item.data3}</p>
                                            <p>{item.though}</p>
                                            <div className='SingleMedia_Content_blk p-2' >
                                                <h3 className='d-flex flex-column  textCard singletext' style={{ marginTop: '-1.5rem' }}>
                                                    {item.data}</h3>
                                                <span style={{ cursor: 'pointer', position: 'relative', left: '240px', bottom: '23px', color: 'rgb(129, 193, 75)' }}>{item.read}</span>
                                                {/* <h3 className='d-flex flex-column'>{item.data}{item.read}<span style={{ cursor: 'pointer' }} className='text-primary ' {isGreen ? 'text-success' : 'text-primary'}></span>
                                                    <h5 style={{ cursor: 'pointer', color: '#4D4D4D' }} className=' pl-1 mt-2'>{item.toptext}</h5> */}
                                                {/* <span style={{ cursor: 'pointer' }} className='text-primary text-nowrap' onClick={() => setSeeMore(!seemore)} >{seemore ? '...Read less' : '... Read more'}</span> */}

                                                {/* </h3> */}
                                                <div>
                                                    <img src={item.play} alt="" style={{ position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)' }} className='blinkicons' />
                                                </div>

                                            </div>
                                            <p className='p-2'>{item.data1}</p>

                                        </div>
                                        {/*======================================Like comment============================= */}
                                        {/* ===========================alert icon=================== */}
                                    </div>
                                    <div className="Post_Dispaly_Card_body" >
                                        {item.id}
                                    </div>
                                    {
                                        index === 1 ? < div className='SingleMedia_Content_blk'>
                                            <h3 className='pl-1 pr-1' style={{}} >{item.datatext}</h3>
                                            {/*  marginBottom: '15px'  */}
                                        </div> : " "
                                    }


                                </Card >
                            </div>
                        ))}
                    </Masonry>
                </ResponsiveMasonry >
            </div >

            {/*================================================================== Share Modal Start =====================================================================*/}
            < ShareModal
                forwardOpen={forwardOpen}
                toggleForward={toggleForward}
            />
            {/*================================================================== Share Modal End  =====================================================================*/}

        </Fragment >
    )
}

export default NewBookMark