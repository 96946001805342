import React, { useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";

// MUI Dialog box
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { Bookmark, Box, Briefcase, Calendar, Database, Headphones, Home, Key, Layout, Mic, Search, ShoppingCart, Star, TrendingUp, UserCheck, Users } from 'react-feather';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import ShotzGreen from '../NewComponents/IMG/ShotzGreen.svg'
import { useUserAuth } from '../Context/userAuthContext';

const LeftSidebar = () => {

    const { setShotzCarouselModal } = useUserAuth();

    const userProfile = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [tooltipOpen, setTooltipOpen] = useState(false)
    // MUI State
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState('1');
    const toggle = tab => {
        setActive(tab)
    }

    let navigate = useNavigate();

    const logoutUser = () => {
        setOpen(false)
        const config = {
            headers: { Authorization: `Bearer ${userProfile.token}` }
        };
        const logoutBody = { userId: userProfile.id }
        console.log(logoutBody)

        axios.post(`${process.env.REACT_APP_IPURL}/user/logOut/`, logoutBody, config)
            .then((respo) => {
                if (respo.data.data?.successResult) {
                    localStorage.removeItem('sociomeeUser');
                    navigate('/');
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="sidebar-panel panel-lg">
                <ul className="sidebar-icon">
                    <li
                        onClick={() => toggle('1')}
                        className={active === '1' && 'active'}>
                        <NavLink to="/Home" >
                            {/* <img id='tooltipForHome' src="/assets/images/left-side-menu-icon/home.svg" className="bar-icon-img" alt="Home" /> */}
                            <Home id='tooltipForHome' className="bar-icon-img" />
                            <h4>Home</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForHome'>
                            Home
                        </UncontrolledTooltip>
                    </li>
                    {/* <li
                        onClick={() => toggle('2')}
                        className={active === '2' && 'active'}
                    >
                        <NavLink to="/SocialFeed">
                            <Star id='tooltipForfav' className="bar-icon-img" />
                            <h4>Dpk_Socialfeed</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForfav'>
                            Social Feed
                        </UncontrolledTooltip>
                    </li> */}
                    <li
                        onClick={() => toggle('3')}
                        className={active === '3' && 'active'}>

                        <NavLink
                            active={active === '3'}
                            to="/UniversalNav">
                            <Database id='tooltipForUnivers' className="bar-icon-img" />
                            <h4>Univesral</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForUnivers'>
                            Univesral
                        </UncontrolledTooltip>
                    </li>
                    {/* ====== top side baar====== */}
                    <li onClick={() => toggle('15')}
                        className={active === '15' && 'active'}>
                        <NavLink
                            active={active === '15'}
                            to="/Tops">
                            <TrendingUp id='tooltipForTop' className='bar-icon-img' />
                            <h4>Top</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForTop'>
                            Top
                        </UncontrolledTooltip>
                    </li>
                    {/* ================= */}
                    {/* ====== trending side baar====== */}
                    <li onClick={() => toggle('16')}
                        className={active === '16' && 'active'}>
                        <NavLink
                            active={active === '16'}
                            to="/Trending">
                            <TrendingUp id='tooltipForTrending' className='bar-icon-img' />
                            {/* <img src="/static/media/Treanding.510ac298.svg" alt="Home-link" id='tooltipForTrending' className='bar-icon-img' style={{color:'#81c14b'}}/> */}
                            <h4>Trending</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForTrending'>
                            Trending
                        </UncontrolledTooltip>
                    </li>
                    {/* ================= */}
                    {/* <li onClick={() => toggle('4')}
                        className={active === '4' && 'active'}>
                        <NavLink
                            active={active === '4'}
                            to="/Feed">
                            <Layout id='tooltipForFeeds' className="bar-icon-img" />
                            <h4>Feeds</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForFeeds'>
                            Feeds
                        </UncontrolledTooltip>
                    </li> */}
                    <li onClick={() => toggle('5')}
                        className={active === '5' && 'active'}>
                        <NavLink
                            active={active === '5'}
                            to="/Group">
                            <Users id='tooltipForGroup' className="bar-icon-img" />
                            {/* <img id='tooltipForGroup' src="/assets/images/left-side-menu-icon/users.svg" className="bar-icon-img" alt="Groups" /> */}
                            <h4>Groups</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForGroup'>
                            Groups
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('6')}
                        className={active === '6' && 'active'}>
                        <NavLink
                            active={active === '6'}
                            to="/Connection">
                            <UserCheck id='tooltipForconnect' className="bar-icon-img" />
                            {/* <img src="/assets/images/left-side-menu-icon/user-check.svg" alt="Connections " /> */}
                            <h4>Connections </h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForconnect'>
                            Connections
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('7')}
                        className={active === '7' && 'active'}>
                        <NavLink
                            active={active === '7'}
                            to="/Podcast">
                            {/* <img id='tooltipForPodcast' src="/assets/images/left-side-menu-icon/headphones.svg" className="bar-icon-img" alt="Podcast" /> */}
                            <Mic className="bar-icon-img" id='tooltipForPodcast' />
                            <h4>Podcast</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForPodcast'>
                            Podcast
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('8')}
                        className={active === '8' && 'active'}>
                        <NavLink
                            onClick={() => setShotzCarouselModal(true)}
                            active={active === '8'}
                            to="/ExploreShotz">
                            <img id='tooltipForShotz' src={ShotzGreen} className="bar-icon-img" alt="Shotz" />
                            <h4>Shotz</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForShotz'>
                            Shotz
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('9')}
                        className={active === '9' && 'active'}>
                        <NavLink
                            active={active === '9'}
                            to="/biz">
                            <Briefcase id='tooltipForbiz' className="bar-icon-img" />
                            {/* <img id='tooltipForbiz' src="/assets/images/left-side-menu-icon/briefcase.svg" className="bar-icon-img" alt="Business Page" /> */}
                            <h4>Business Page</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForbiz'>
                            Business Page
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('10')}
                        className={active === '10' && 'active'}>
                        <NavLink
                            active={active === '10'}
                            to="/Events">
                            <Calendar id='tooltipForEve' className="bar-icon-img" />
                            {/* <img id='tooltipForEve' src="/assets/images/left-side-menu-icon/calendar.svg" className="bar-icon-img" alt="Shotz" /> */}
                            {/* <img id='tooltipForEve' src="/assets/images/left-side-menu-icon/zap.svg" className="bar-icon-img" alt="Events" /> */}
                            <h4>Events</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForEve'>
                            Events
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('11')}
                        className={active === '11' && 'active'}>
                        <NavLink
                            active={active === '11'}
                            to="/marketplace">
                            <ShoppingCart id='tooltipForMark' className="bar-icon-img" />
                            {/* <img id='tooltipForMark' src="/assets/images/left-side-menu-icon/shopping-cart.svg" className="bar-icon-img" alt="MarkrtPlace" /> */}
                            <h4>MarketPlace</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForMark'>
                            MarketPlace
                        </UncontrolledTooltip>
                    </li>
                    <li onClick={() => toggle('12')}
                        className={active === '12' && 'active'}>
                        <NavLink
                            active={active === '12'}
                            to="/MpNFT">
                            <ShoppingCart id='tooltipForDcoin' className="bar-icon-img" />
                            {/* <img id='tooltipForMark' src="/assets/images/left-side-menu-icon/shopping-cart.svg" className="bar-icon-img" alt="MarkrtPlace" /> */}
                            <h4>D-Coin</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForDcoin'>
                            D-Coin
                        </UncontrolledTooltip>
                    </li>

                    <li onClick={() => toggle('13')}
                        className={active === '13' && 'active'}>
                        <NavLink
                            active={active === '13'}
                            target={'_blank'} to="/Dashboard">
                            <Box id='tooltipForAd' className="bar-icon-img" />
                            <h4>Ad-Manager</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForAd'>
                            Ad Manager
                        </UncontrolledTooltip>
                    </li>
                    {/* <li onClick={() => toggle('13')}
                        className={active === '13' && 'active'}>
                        <NavLink
                            active={active === '13'}
                            to="/Search">
                            <Search id='tooltipForSearch' className='bar-icon-img' />
                            <h4>Search</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForSearch'>
                            Search
                        </UncontrolledTooltip>
                    </li> */}
                    <li onClick={() => toggle('14')}
                        className={active === '14' && 'active'}>
                        <NavLink
                            active={active === '14'}
                            to="/Bookmark">
                            <Bookmark id='tooltipForBook' className='bar-icon-img' />
                            <h4>Bookmark</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForBook'>
                            Bookmark
                        </UncontrolledTooltip>
                    </li>

                    <li onClick={() => setOpen(true)}>
                        <a>
                            <img src="/assets/images/left-side-menu-icon/power.svg" className="bar-icon-img" alt="logout" />
                            <h4>logout</h4>
                        </a>
                    </li>
                    <li>
                        <NavLink to="/loginpage">
                            <Key id='tooltipForBook' className='bar-icon-img' />
                            <h4>Log In</h4>
                        </NavLink>
                        <UncontrolledTooltip placement='right' target='tooltipForBook'>
                            Login
                        </UncontrolledTooltip>
                    </li>
                </ul>
            </div>
            {/* MUI Dialog Box  */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure, you want to logout ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={logoutUser}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default LeftSidebar;