import { Pagination, Stack } from '@mui/material'
import React from 'react'
import { Fragment } from 'react'
import { Button, Col, Row } from 'reactstrap'
import Request from "../Img/Request.png"
import Request1 from "../Img/Request1.png"
import Request2 from "../Img/Request2.png"
import Request3 from "../Img/Request3.png"
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getAllJoinedGroupRequestAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import GroupPropsAvatar from './GroupPropsAvatar'

const MyJoinRequest = () => {
    const params = useParams()
    let dispatch = useDispatch();

    const { getAllJoinedRequest } = useSelector((state) => { return state.getJoinedRequestFromGroupData });
    useEffect(() => {
        dispatch(getAllJoinedGroupRequestAction(params.id))
    }, [])
    const RequestData = [
        {
            img: Request,
            name: "Kathryn Murphy",
            contact: "kathryn-murphy@123",
            connection: "Followed by John +12 "
        },
        {
            img: Request1,
            name: "Kathryn Murphy",
            contact: "kathryn-murphy@123",
            connection: "Followed by John +12 "
        },
        {
            img: Request2,
            name: "Kathryn Murphy",
            contact: "kathryn-murphy@123",
            connection: "Followed by John +12 "
        },
        {
            img: Request3,
            name: "Kathryn Murphy",
            contact: "kathryn-murphy@123",
            connection: "Followed by John +12 "
        },
        {
            img: Request,
            name: "Kathryn Murphy",
            contact: "kathryn-murphy@123",
            connection: "Followed by John +12 "
        }
    ]
    const JoinRequestData = () => (
        <Fragment>

            <div className="group-page-section section-b-space">
                <div className="group-page-title">
                    <Row className="row align-items-center">
                        <Col md="12" sm="12" >
                            <h3>Join Request ({getAllJoinedRequest && getAllJoinedRequest?.data?.successResult?.rows?.length})</h3>
                        </Col>
                    </Row>
                </div>
                <div className="group-detail-page-blk mt-2">
                    <Row>
                        {
                            getAllJoinedRequest && getAllJoinedRequest?.data?.successResult?.rows.map((data) => (
                                <Col md="6" sm="6">
                                    <div className="group-members-block">
                                        <div className="group-member-img-cont-blk">
                                            <img src={data.profileImageThumb} alt="img" />
                                            <div className="member-cont-blk">
                                                <h4 className='text-capitalize'>{data?.fullName}</h4>
                                                <p className='text-capitalize'>{data?.addressBy}</p>
                                                <p><GroupPropsAvatar followers={data.followers} /> {data?.followers?.length} +Connections</p>
                                            </div>
                                        </div>
                                        <div className='Admin-btns-blk'>
                                            <Button color='primary'>Accept</Button>
                                            <Button color='secondary' className=" ml-2" outline>Decline</Button>
                                            {/* Admin_Decline_btn  Admin_Modify_btn */}
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <Stack>
                    <Pagination count={10} className='PaginationCount'>
                    </Pagination>
                </Stack>
            </div>
        </Fragment>
    )
    return (
        <Fragment>
            <JoinRequestData />
        </Fragment>
    )
}

export default MyJoinRequest