import Pen1 from '../Img/Pen1.png';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import { Phone, Mail, XCircle } from 'react-feather';
import { Radio,Checkbox, FormControlLabel,FormLabel } from '@mui/material';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { sweetAlertConfirmation } from "../../../components/group-components/AllGroupsSections/SweetAlert";
import { useDispatch,useSelector } from 'react-redux';
import {updatePrimaryContact} from '../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';

// const otpInputRefs = Array(6).fill(null).map(() => useRef(null));

const ProfileContactDetail = ({ userProfileByUserId,type }) => {
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const dispatch=useDispatch();

  const [contactOpen, setContactOpen] = useState(false);
  const [mobOpen, setMobOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showResults1,setShowResults1]=useState(false);
  const [verifyResults, setVerifyResults] = useState(false);
  const [verifyResults1, setVerifyResults1] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [otpOpen, setOtpOpen]=useState(false);
  const [otpOpen1, setOtpOpen1]=useState(false);
  const [primaryIndex, setPrimaryIndex] = useState(-1);
  const [mobil,setMobil] = useState('');
  const [emailId,setEmailId] = useState('');
  const [emailIsPrimary,setEmailIsPrimary]=useState('');
  const [currentUser, setCurrentUser] = useState({
    isEmail: false,
    countryId: "",
    languagId: "12c840f6-fddf-44d3-9680-8c6411ecaff7",
    email: "",
    mobile: "",
    type: "addContact",
  });
  const [searchValue, setSearchValue] = useState("");
  const errorRef = useRef(null);
  const [userData, setUserData] = useState({
    code: "+91",
    mobile: "",
    languagId: "12c840f6-fddf-44d3-9680-8c6411ecaff7",
  });
  const [phoneCode, setPhoneCode] = useState([]);
  const [error, setError] = useState("");

  const [defaultCountryCode, setDefaultCountryCode] = useState();
  const [defaultPhoneCode, setDefaultPhoneCode] = useState();
  const [flag, setFlag] = useState(false);
  const [resendOtpLimit, setResendOtpLimit] = useState({ times: 0 })

  const handleChange = (index,mobil) => {
    setMobil(mobil);
    if (primaryIndex === index) {
      setPrimaryIndex(-1); // Toggle off if clicking the same primary checkbox again
    } else {
      setPrimaryIndex(index); // Set the index of the selected primary mobile number
    }
  };

  const handleChange1 = (index,email) => {
    setEmailIsPrimary(email);
    if (primaryIndex === index) {
      setPrimaryIndex(-1); // Toggle off if clicking the same primary checkbox again
    } else {
      setPrimaryIndex(index); // Set the index of the selected primary mobile number
    }
  };

  const toggleMobNo = () => {

    toggleContact(true)
    setMobOpen((preState) => !preState)
    setOtpOpen(false)
  }

  const toggleMobNo1 = () => {
    
    dispatch(updatePrimaryContact("",mobil));
    sweetAlertConfirmation("Primary number updated successfully");

    toggleContact(true)
    setMobOpen((preState) => !preState)
    setContactOpen(!contactOpen);
  }

  const toggleEmail1 = () => {
    
    dispatch(updatePrimaryContact(emailIsPrimary,""));
    sweetAlertConfirmation("Primary Email updated successfully");

    toggleContact(true)
    setEmailOpen((preState) => !preState)
    setContactOpen(!contactOpen);
  }
    const [timer, setTimer] = useState(20);
    const [startTimer, setStartTimer] = useState(false);

    useEffect(() => {
      let interval;
      if (startTimer && timer > 0) {
        interval = setInterval(() => {
          setTimer(prevTimer => prevTimer - 1);
        }, 1000);
      } else if (!startTimer) {
        setTimer(20); // Reset timer if not started
      }
  
      return () => clearInterval(interval);
    }, [startTimer, timer]);

  const contactOptions = [
    {
      type: 'mobile',
      icon: <Phone strokeWidth={3} color='#81C14B' size={20} />,
      name: 'Mobile Number',
      detail: userProfileByUserId.mobile,
    },
    {
      type: 'email',
      icon: <Mail strokeWidth={3} color='#81C14B' size={20} />,
      name: 'Email Address',
      detail: userProfileByUserId.email,
    },
  ];

  const contactOptions2 = userProfileByUserId?.contact?.filter(c => c.isEmail).length > 0
  ? userProfileByUserId.contact
    .filter(c => c.isEmail) // Filter out contacts where isEmail is true (only emails)
    .map((c, index) => ({
      type: 'email',
      icon: <Mail strokeWidth={3} color='#81C14B' size={20} />,
      name: 'Email Address',
      detail: c.contact || "", // Use the contact value for detail (email address)
      isPrimary: c.isPrimary, // Assign isPrimary from the filtered contact
    }))
  : [
    {
      type: 'email',
      icon: <Mail strokeWidth={3} color='#81C14B' size={20} />,
      name: 'Email Address',
      detail: '',
      isPrimary: 1, 
    }
  ];

  const contactOptions1 = userProfileByUserId.contact
    ?.filter(c => !c.isEmail) // Filter out num
    .map((c, index) => ({
      type: 'mobile',
      icon: <Phone strokeWidth={3} color='#81C14B' size={20} />,
      name: 'Mobile Number',
      detail: c.contact, // Only mobile numbers will be included
      isPrimary: c.isPrimary,
    })) || [{
      type: 'mobile',
      icon: <Phone strokeWidth={3} color='#81C14B' size={20} />,
      name: 'Mobile Number',
      detail: '',
      isPrimary: 1, 
    }];
  const mobIcon = contactOptions1.length > 0 ? contactOptions1[0].icon : null;
  const mobType = contactOptions1.length > 0 ? contactOptions1[0].type : '';
  const mobName = contactOptions1.length > 0 ? contactOptions1[0].name : '';

  const toggleContact = () => {
    setContactOpen(!contactOpen);
  };

  const toggleMobile = () => {
    toggleContact();
    setMobOpen(!mobOpen);
  };

  const toggleEmail = () => {
    toggleContact();
    setEmailOpen(!emailOpen);
    setOtpOpen1(false)
  };

  const toggle = () => {
    setUserData({ ...userData, mobile: "" });
    setEmailId('');
    setOtpOpen1(false)
    setOtpOpen(false)
    setEmailId('');
    setStartTimer(false)
    setVerifyResults(true);
    setShowResults(!showResults);
  };

  const toggle1 = () => {
    setVerifyResults1(true);
    setShowResults1(!showResults1);
  };
  const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const toggleVerify = (e,Vtype) => {
      e.preventDefault();
      if(Vtype=='mobile'){
      const phoneFormat = /^[6-9]\d{9}$/;
      if (!userData.code) {
        errorRef.current.classList.remove("d-none");
        setError("Please Select Phone Code");
      } else if (defaultCountryCode !== currentUser.countryId) {
        errorRef.current.classList.remove("d-none");
        setError("Please select right country code");
      } else if (!userData.mobile) {
        errorRef.current.classList.remove("d-none");
        setError("Please Enter Phone Number !");
      } else if (
        userData.mobile.length > 10 ||
        userData.mobile.length < 10 ||
        !userData.mobile.match(phoneFormat)
      ) {
        errorRef.current.classList.remove("d-none");
        setError("Please Enter Valid Phone Number ! ");
      } else {
        
        setCurrentUser({ ...currentUser, mobile: `${userData.code} ${userData.mobile}` });
        currentUser.mobile = `${userData.code} ${userData.mobile}`;
        axios
          .post(`${process.env.REACT_APP_IPURL}/public/sendOtp`, currentUser)
          .then((res) => {
            if (res.data.data?.successResult) {
              // navigate("/Otp", { state: user });
              // console.log("Otp sent successfully")
              setVerifyResults(true);
              setStartTimer(true);
              setOtpOpen(true);
            }else if(res.data.error){
              setError("Something went wrong");
              errorRef.current.classList.remove("d-none");
            } else {
              errorRef.current.classList.remove("d-none");
              setError(res.data.data?.errorResult);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }else if(Vtype=='email'){
      setVerifyResults1(!verifyResults1)
      setOtpOpen1(true);
      setStartTimer(true);
      if (!emailId) {
        errorRef.current.classList.remove("d-none");
        setError("Please Enter Email Address !");
      } else if (!emailId.match(emailFormat)) {
        errorRef.current.classList.remove("d-none");
        setError("Please Enter a Valid Email Address !");
      } else {
        axios
          .post(`${process.env.REACT_APP_IPURL}/public/sendOtp`, {
            isEmail: true,
            countryId: "",
            languagId: "12c840f6-fddf-44d3-9680-8c6411ecaff7",
            email: emailId,
            mobile: "",
            type: "addEmail",
          })
          .then((res) => {
            if (res.data.data?.successResult) {
              // console.log("Email verification sent successfully");
              setVerifyResults1(true);
              setStartTimer(true);
              setOtpOpen1(true);
            } else if (res.data.error) {
              setError("Something went wrong");
              errorRef.current.classList.remove("d-none");
            } else {
              errorRef.current.classList.remove("d-none");
              setError(res.data.data?.errorResult);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

    }
  };

  

  let navigate = useNavigate();

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value.replace(/[^0-9]/gi, '') : d))]);
    errorRef.current.classList.add('d-none')
};

const complete = otp.join("");

const otpSubmit = (e,type) => {
  e.preventDefault();
  e.stopPropagation();
  if(type=="mobile"){

    if (complete === "" || complete === null || complete.length < 6) {
      errorRef.current.classList.remove('d-none');
      setError('Invalid OTP')
  }
  else {

      const insertData = {
        contact: currentUser.mobile,
        isEmail: false,
        countryId: currentUser.countryId,
        otp:complete,
        type: "addContact"
      };

      setCurrentUser({ ...currentUser, sentTo: currentUser.mobile, type: currentUser.type, otp: complete });
            axios.post(`${process.env.REACT_APP_IPURL}/user/addContact/`, insertData,{headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`
            } })
              .then((res) => {
                if(res.data.data.successResult){
                setFlag(true);
                setMobOpen((prevState) => !prevState);
                setContactOpen(false);
                navigate(`/MyProfile?userId=${user.Id}&type=creater`);
                sweetAlertConfirmation("Number added successfully");
                setOtpOpen(false);
                }else{
                  errorRef.current.classList.remove('d-none');
                  errorRef.current.style.textAlign = "center";
                  errorRef.current.style.color = "red";
                 setError(res.data.data.errorResult);
                }
              })
              .catch((err) => {
                errorRef.current.classList.remove('d-none');
                errorRef.current.style.textAlign = "center";
                errorRef.current.style.color = "red";
                setError(err);
              });
  }

  }else{

    if (complete === "" || complete === null || complete.length < 6) {
      errorRef.current.classList.remove('d-none');
      setError('Invalid OTP')
      }
      else {

      const insertData = {
        contact: emailId,
        isEmail: true,
        countryId: currentUser.countryId,
        otp:complete,
        type:"addEmail"
      };

            axios.post(`${process.env.REACT_APP_IPURL}/user/addContact/`, insertData,{headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`
            } })
              .then((res) => {
                if(res.data.data.successResult){
                setFlag(true);
                setEmailOpen((prevState) => !prevState);
                setContactOpen(false);
                navigate(`/MyProfile?userId=${user.Id}&type=creater`);
                sweetAlertConfirmation("Email added successfully");
                setOtpOpen1(false);
                }else{
                  errorRef.current.classList.remove('d-none');
                  errorRef.current.style.textAlign = "center";
                  errorRef.current.style.color = "red";
                 setError(res.data.data.errorResult);
                }
              })
              .catch((err) => {
                errorRef.current.classList.remove('d-none');
                errorRef.current.style.textAlign = "center";
                errorRef.current.style.color = "red";
                setError(err);
              });

            }
          
  }
}

const resendOtp = (e,type) => {
  e.stopPropagation();
  if(type=="mobile"){
    setOtp([...otp.map(v => "")])
  errorRef.current.classList.add('d-none')
  currentUser && axios.post(`${process.env.REACT_APP_IPURL}/public/sendOtp`, currentUser)
      .then((res) => {
          if (res.data.data?.successResult) {
              document.getElementById('timer-div').style.display = 'block';
              document.getElementById('resendotp').style.display = 'none';
              resendOtpLimit.times += 1;
              if (resendOtpLimit.times === 2) {
                  document.getElementById('resendotp').style.display = 'none';
                  timerFunc();
              }
              setStartTimer(true)
          }
          else {
              errorRef.current.classList.remove('d-none'); setError(res.data.data?.errorResult)
          }
      })
      .catch((err) => {
          console.log(err)
      })
  }else{

    setOtp([...otp.map(v => "")])
    errorRef.current.classList.add('d-none')
    const insertData = {
      email: emailId,
      isEmail: true,
      countryId: currentUser.countryId,
      otp:complete,
      type:"addEmail"
    };
    currentUser && axios.post(`${process.env.REACT_APP_IPURL}/public/sendOtp`, insertData)
        .then((res) => {
            if (res.data.data?.successResult) {
                document.getElementById('timer-div').style.display = 'block';
                document.getElementById('resendotp').style.display = 'none';
                resendOtpLimit.times += 1;
                if (resendOtpLimit.times === 2) {
                    document.getElementById('resendotp').style.display = 'none';
                    timerFunc();
                }
                setStartTimer(true)
            }
            else {
                errorRef.current.classList.remove('d-none'); setError(res.data.data?.errorResult)
            }
        })
        .catch((err) => {
            console.log(err)
        })

  }
 
}

const timerFunc = () => {
  let startTimer = 20;
  document.getElementById('resendotp').style.display = 'none';
  let resendTimer = setInterval(function () {
      if (startTimer <= 0) {
          clearInterval(resendTimer);
          document.getElementById('timer').innerHTML = '00:00';
          document.getElementById('resendotp').style.display = 'block';
          document.getElementById('timer-div').style.display = 'none';
          // console.log(resendOtpLimit.times)
          if (resendOtpLimit.times === 2) {
              document.getElementById('resendotp').style.display = 'none';
              document.getElementById('timer-div').style.display = 'none';
          }
      }
      else {
          document.getElementById('timer').innerHTML = `00:${startTimer ? startTimer : '00'}`;
      }
      startTimer -= 1;
      if (flag) {
          clearInterval(resendTimer);
      }
  }, 1000);
}

const inputfocus = (elmnt) => {
  // console.log()
  if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
          elmnt.target.form.elements[next].focus()
      }
  }
  else {
      const next = elmnt.target.tabIndex;
      if (next < 6 && elmnt.target.value) {
          elmnt.target.form.elements[next].focus()
      }
  }
}
  const numberFilter = (e) => {
    var ASCIICode = e.which ? e.which : e.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      e.preventDefault();
    if (e.target.value.length >= 10) {
      e.preventDefault();
    }
    if (e.key === "Enter") {
      toggleVerify(e);
    }
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_IPURL}/public/getAllCountry/`)
      .then((res) => {
        setPhoneCode(res.data.data.successResult.rows);
        //console.log(res.data.data.successResult.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const countryCodeHandler = (id, teleCode, name, iconURL) => {
    setDefaultPhoneCode({
      id: id,
      teleCode: teleCode,
      name: name,
      iconURL: iconURL,
    });
    userData.code = `+${teleCode}`;
    if (userData.code) {
      // filter country by phone code
      const filterCode = phoneCode.filter((curr) => {
        return `+${curr.teleCode}` === userData.code;
      });
      currentUser.countryId = filterCode[0].id;
    }
  };
  useEffect(() => {
    axios
      .get(
        "https://api.ipgeolocation.io/ipgeo?apiKey=c1016d597c494a02aa190877148a5688"
      )
      .then((res) => {
        //setUserData({ ...userData, code: res.data.calling_code });'
        // console.log({ ...userData, code: res.data.calling_code });
        // filter country by phone code
        const filterCode = phoneCode.find((curr) => {
          return `+${curr.teleCode}` === res.data.calling_code;
        });
        currentUser.countryId = filterCode?.id;
        setDefaultCountryCode(filterCode?.id);
        setDefaultPhoneCode(filterCode);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [phoneCode]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      <div className="card-title-cont-block border-bottom py-2">
        <div className="card-title-cont-block">
          <div className="card-title About_section">
            <h3>My Contact Detail</h3>
            {type !== "viewer" && (
            <div className="settings">
              
                <div onClick={toggleContact} className="setting-btn">
                <img src={Pen1} width={12} className="img-fluid" alt="Edit" />
              </div>
              
            </div>
            )}
          </div>
          <div className="about-content About_section">
            {contactOptions.map((data) => (
              <div className='d-flex px-4 py-2' key={data.type}>
                <div className="icon">{data.icon}</div>
                <div className="detailsForSpace px-4">
                  <h3 className='pb-1'>{data.name}</h3>
                  <h5 style={{ cursor: 'pointer' }}>
                    {data.detail}|{' '}
                    <span className='text-blue'>
                      {data.type === 'mobile' ? 'Mobile' : 'Email'}
                    </span>
                  </h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={contactOpen}
        toggle={toggleContact}
        className='modal-dialog-centered'
      >
        <div className=' Profession_Modal_section AboutModal_Main_section p-4 py-4'>
          <h4>Contact Detail</h4>
          <p onClick={toggleContact}>Save</p>
        </div>
        <div className='mt-2'>
        <div
          className="d-flex justify-content-between AboutModal_Main_section p-4 py-3"
          key={mobType}
        >
          <div className="d-flex align-items-start">
            <div className="Contact_Icon">{mobIcon}</div>
            <div className="ml-3 Basic_Info">
              <h4>{mobName}</h4>
              {contactOptions1.map((data) => (
              <h6>{data.detail !== null ? data.detail : 'null'}</h6>
            ))}
            </div>
          </div>
          <div className="settings">
            <div className="setting-btn">
              <img
    
                onClick={() => toggleMobile()}
                src={Pen1}
                width={12}
                className="img-fluid"
                alt="Edit"
              />
            </div>
          </div>
        </div>

          {contactOptions2.map((data) => (
            <div
              className='d-flex justify-content-between AboutModal_Main_section p-4 py-3 '
              key={data.type}
            >
              <div className='d-flex align-items-start'>
                <div className='Contact_Icon'>{data.icon}</div>
                <div className='ml-3 Basic_Info'>
                  <h4>{data.name}</h4>
                  <h6>{data.detail}</h6>
                </div>
              </div>
              <div className="settings">
                <div className="setting-btn">
                    <img
                      onClick={toggleEmail}
                      src={Pen1}
                      width={12}
                      className="img-fluid"
                      alt="Edit"
                    />
                </div>
              </div>
            </div>
          ))} 

        </div>
      </Modal>

      <div className='Modal_section_Basic_info_Mobile_no'>
        {
          mobOpen !== false &&
          <Modal isOpen={mobOpen} toggle={toggleMobNo} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Add/Edit Mobile Number</h4>
                <p onClick={toggleMobNo1}>Save</p>
              </div>
            </div>
            <ModalBody>
              <div className='mt-1 form_control_section' scrollable >
                <Form>
                {contactOptions1.map((data, index) => (
                  <FormGroup key={index}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <FormLabel className="Bio_text">
                        Mobile Number
                      </FormLabel>
                      {!data.isPrimary && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={primaryIndex === index}
                              onChange={() => handleChange(index,data.detail)}
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<RadioButtonCheckedIcon />}
                              inputProps={{ 'aria-label': ' Set as primary' }}
                            />
                          }
                          label="Set as primary"
                          className='ml-2 mt-2'
                        />
                      )}
                    </div>
                    <div className='form_control_section_input_box'>
                      <Input
                        disabled={data.isPrimary === 1}
                        id={`mobileNumber_${index}`}
                        name="mobileNumber"
                        placeholder={data.detail}
                        type="tel"
                        className="bg-light"
                      />
                      <h6 className='input_box_section text-muted'>{data.isPrimary === 1 ? 'primary' : 'secondary'}</h6>
                    </div>
                    </FormGroup>
                    ))}
                  {showResults ? <FormGroup>
                    <Label className="Bio_text">
                      Enter Other Number
                    </Label>
                    <div className='form_control_section_input_box'>
                    <form className="theme-form">
                        <div className="form-group">
                          {/* <label>Enter your Mobile Number</label> */}
                          <div
                            className={`input-block ${
                              userData.mobile.length === 10 &&
                              "border border-success rounded-3"
                            }`}
                          >
                            <div className="phone-with-code">
                              <div className="phone-code-dropdown">
                                <a
                                  className="d-flex"
                                  data-bs-toggle="modal"
                                  data-bs-target="#languageModal"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={defaultPhoneCode?.iconURL}
                                    width="18"
                                    height="12"
                                  />
                                  +{defaultPhoneCode?.teleCode}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="iw-14 phone-code-arrow "
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </a>
                              </div>
                              <input
                                type="text"
                                className="form-control w-75 pr-5"
                                placeholder="Enter Mobile Number"
                                name="mobile"
                                value={userData.mobile}
                                onChange={(e) => {
                                  setUserData({
                                    ...userData,
                                    mobile: e.target.value.replace(
                                      /[^0-9]/gi,
                                      ""
                                    ),
                                  });
                                  errorRef.current.classList.add("d-none");
                                }}
                                onKeyPress={numberFilter}
                                maxLength={"10"}
                              />
                              <p onClick={(e)=>toggleVerify(e,"mobile")} disabled={
                              userData.mobile.length !== 10 ? true : false
                              } className='Verfiy_input_section'>Verify</p>
                              <XCircle onClick={toggle} className='Input_cross_section' color='#81C14B' size={17} />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div id='error-div'>
                    <p ref={errorRef} style={{ color: 'red', textAlign: 'center' }}>{error}</p>
                    </div>
                    {verifyResults && otpOpen ? <>
                      <div>

                        <div className="login-title text-center">
                            <h2>Enter OTP</h2>
                        </div>
                        <br/>
                        <div className="form-sec">
                            <div>
                                <form className="theme-form">
                                    <div className="form-group">
                                        <div className="otpContainer">
                                            {otp.map((data, index) => {
                                              return (<input
                                                  name="otp"
                                                  type="text"
                                                  placeholder="-"
                                                  autoComplete="off"
                                                  className={`otpInput form-control ${otp.join("").length === 6 && 'border border-success'} ${error && 'border-danger'}`}
                                                  tabIndex={index + 1}
                                                  maxLength="1"
                                                  key={index}
                                                  value={data}
                                                  onChange={e => {handleOtpChange(e.target, index);setError('')}}
                                                  onKeyUp={e => inputfocus(e)}
                                                  onKeyPress={numberFilter} />)
                                              })}
                                        </div>
                                        <br/>
                                        <p className="error-input-msg text-center d-none" ref={errorRef}>{error}</p>
                                        <h4 className="form-group text-center">00:{timer < 10 ? `0${timer}` : timer} sec</h4>
                                    </div>
                                    <div className="resendotp-blk" id="resendotp">Didn't receive OTP yet? <Link to='#' className={'color-blue'}  onClick={(e)=>resendOtp(e,"mobile")}>Resend</Link></div>
                                    <div className="btn-section text-center">
                                        <button className="btn btn-solid btn-lg without-input-fill" onClick={(e)=>otpSubmit(e,"mobile")} disabled={otp.join("").length === 6 ? false : true}>CONTINUE</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </> :

                      null}

                  </FormGroup> : null}
                  <p onClick={toggle}>+ Add other Number</p>
                  
                </Form>
              </div>
             
            </ModalBody>
          </Modal>
        }

      </div>

      <div className='Modal_section_Basic_info_Email'>
        {
          emailOpen !== false &&
          <Modal isOpen={emailOpen} toggle={toggleEmail} className='modal-dialog-centered'>
            <div className='w-100 border-bottom'>
              <div className='mx-3 py-4 Profession_Modal_section'>
                <h4>Add/Edit Email Address </h4>
                <p onClick={toggleEmail1}>Save</p>
              </div>
            </div>
            <ModalBody>
              <div className='mt-2 form_control_section' >
                <Form>
                {contactOptions2.map((data,index) => (
                  <FormGroup>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                      <FormLabel className="Bio_text">
                        Email ID
                      </FormLabel>
                    {!data.isPrimary && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={primaryIndex === index}
                            onChange={() => handleChange1(index,data.detail)}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<RadioButtonCheckedIcon />}
                            inputProps={{ 'aria-label': ' Set as primary' }}
                          />
                          }
                          label="Set as primary"
                          className='ml-2 mt-2'
                      />
                    )}
                  </div>
                    <div className='form_control_section_input_box'>
                      <Input
                        disabled={data.isPrimary === 1}
                        id={`exampleEmail_${index}`}
                        name="email"
                        placeholder={data.detail}
                        type="email"
                      />
                      <h6 className='input_box_section text-muted'>{data.isPrimary === 1 ? 'primary' : 'secondary'}</h6>
                    </div>
                  </FormGroup>
                   ))}
                  {showResults1 ? <FormGroup>
                    <div className='form_control_section_input_box'>
                      <Input
                        id="exampleEmail"
                        name="email"
                        placeholder={userProfileByUserId.email}
                        type="email"
                        className="inputForm"
                        onChange={(e)=>setEmailId(e.target.value)}
                        value={emailId}
                      />
                     <p 
                        onClick={(e) => toggleVerify(e, "email")} 
                        disabled={!validateEmail(emailId)} 
                        className={`Verfiy_input_section ${!validateEmail(emailId) ? 'disabled' : ''}`}
                      >
                        Verify
                      </p>
                      <XCircle onClick={toggle} className='Input_cross_section' color='#81C14B' size={17} />
                    </div><br/><br/>
                    {verifyResults1 && otpOpen1? <>
                      <div>

                        <div className="login-title text-center">
                            <h2>Enter OTP</h2>
                        </div>
                        <br/>
                        <div className="form-sec">
                            <div>
                                <form className="theme-form">
                                    <div className="form-group">
                                        <div className="otpContainer">
                                            {otp.map((data, index) => {
                                              return (<input
                                                  name="otp"
                                                  type="text"
                                                  placeholder="-"
                                                  autoComplete="off"
                                                  className={`otpInput form-control ${otp.join("").length === 6 && 'border border-success'} ${error && 'border-danger'}`}
                                                  tabIndex={index + 1}
                                                  maxLength="1"
                                                  key={index}
                                                  value={data}
                                                  onChange={e => {handleOtpChange(e.target, index);setError('')}}
                                                  // onFocus={e => e.target.select()}
                                                  onKeyUp={e => inputfocus(e)}
                                                  onKeyPress={numberFilter} />)
                                              })}
                                        </div>
                                        <br/>
                                        <p className="error-input-msg text-center d-none" ref={errorRef}>{error}</p>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <h4 className="form-group text-center">00:{timer < 10 ? `0${timer}` : timer} sec</h4>
                                    </div>
                                    <div className="resendotp-blk" id="resendotp">Didn't receive OTP yet? <Link to='#' className={'color-blue'}  onClick={(e)=>resendOtp(e,"email")}>Resend</Link></div>
                                    <div className="btn-section text-center">
                                        <button className="btn btn-solid btn-lg without-input-fill" onClick={(e)=>otpSubmit(e,"email")} disabled={otp.join("").length === 6 ? false : true}>CONTINUE</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    </> : null
                    }

                  </FormGroup> : null}
                  <p onClick={toggle1}>+ Add other Email</p>

                </Form>

              </div>


            </ModalBody>
          </Modal>
        }
      </div>

      <div
        className="modal fade"
        id="languageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="bioModelTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h2
                className="modal-title font-weight-bold text-black"
                id="exampleModalLongTitle"
              >
                Choose Country Code
              </h2>
              <a href="#" data-bs-dismiss="modal" aria-label="Close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon-dark close-btn"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </a>
            </div>
            <div className="modal-body">
              <form className="theme-form pr-3 pl-3 p-2">
                <div className="input-search-blk">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search Country..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  />
                </div>

                {/* list of countries */}
                <ul className="list-group">
                  {phoneCode &&
                  phoneCode?.filter((code) =>
                    code.name.match(new RegExp(searchValue, "i"))
                  )?.length <= 0 ? (
                    <h2 className="text-center">Invalid Search</h2>
                  ) : (
                    phoneCode
                      ?.filter((code) =>
                        code.name.match(new RegExp(searchValue, "i"))
                      )
                      ?.map(({ id, name, iconURL, teleCode }) => {
                        return (
                          <li
                            className={`list-group-item mt-2 d-flex justify-content-between align-items-center border-white rounded cursor-pointer phone-code-list ${
                              defaultPhoneCode?.id === id && "active-phone-code"
                            }`}
                            key={id}
                            onClick={() =>
                              countryCodeHandler(id, teleCode, name, iconURL)
                            }
                            data-bs-dismiss="modal"
                          >
                            <div className="d-flex align-items-center login-discription">
                              <img
                                src={iconURL}
                                alt=""
                                height="20"
                                width="28"
                              />
                              <h5 className="ml-3 h4 font-weight-bold">
                                {name}
                              </h5>
                            </div>
                            <h5 className="mb-1 h4 font-weight-bold">
                              +{teleCode}
                            </h5>
                          </li>
                        );
                      })
                  )}
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileContactDetail;