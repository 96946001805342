import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const getWithdrawRequest = (getUserWithdrawSentRequest) => ({
    type: types.GET_USER_SENT_REQUEST_WITHDRAW,
    payload: getUserWithdrawSentRequest,
});
// get all biz category
export const withDrawRequestAction = (id,type) => {
    //console.log(user.userId);
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    return function (dispatch) {
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/hamBurgerMenu/withdrawUserRequest`,
            { 
             id: id,
             type: type
            },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                .then((res) => {
                    //console.log("sociomate=======",res.data.data)
                    dispatch(getWithdrawRequest(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};
