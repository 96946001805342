import * as types from "../../Constants/UserProfile/index";
import axios from "axios";

const setShotzData = (shotz) => ({
    type: types.GET_USER_SHOTZ_DATA,
    payload: shotz
});


export const getUsersShotzData = (postType, pageSize, pageIndex,userId , searchKey) => {
    return dispatch => {
        let users = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
        if (users?.token) {
            axios.post(`${process.env.REACT_APP_IPURL}/post/getAllUserPosts`, {
                postType, pageIndex, pageSize,userId,searchKey
            }, {
                headers: { Authorization: `Bearer ${users?.token}` }
            })
                .then((result) => {
                    dispatch(setShotzData(result.data.data.successResult));
                }).catch((err) => {
                    console.log(err)
                });
        }
    }
}
