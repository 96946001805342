import React, { useState, useRef } from 'react';
import { Fragment } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { ChevronRight, Grid } from 'react-feather';
import Slider from 'react-slick';
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, NavLink, UncontrolledButtonDropdown } from 'reactstrap';
import MobileFilter from '../podcast-module-component/Sub-components/MobileFilter';

const TrendingNavList = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /*  ----------------------  Podecast Dyanmic Tabs --------------------  */

    // const PodcastNavData = [
    //     {
    //         id: "1",
    //         NavName: "Media"
    //     },
    //     {
    //         id: "2",
    //         NavName: "Text"
    //     },
    //     {
    //         id: "3",
    //         NavName: "Peoples"
    //     },
    //     {
    //         id: "5",
    //         NavName: "#Tags"
    //     },
    //     {
    //         id: "6",
    //         NavName: "Shotz"
    //     },
    //     {
    //         id: "7",
    //         NavName: " Groups"
    //     },
    //     {
    //         id: "8",
    //         NavName: "BizPage"
    //     },
    //     {
    //         id: "9",
    //         NavName: "Thought"
    //     },
    //     {
    //         id: "10",
    //         NavName: "Recommendation"
    //     },
    //     {
    //         id: "11",
    //         NavName: "Events"
    //     },
    //     {
    //         id: "12",
    //         NavName: "Podcast"
    //     },
    //     {
    //         id: "13",
    //         NavName: "Golive"
    //     },
    //     {
    //         id: "15",
    //         NavName: "Poll"
    //     },
    //     {
    //         id: "16",
    //         NavName: "Article"
    //     },
    //     {
    //         id: "17",
    //         NavName: "Audio"
    //     }

    // ]

    /*  ---------------------- Third party react-slick Slider Breakpoint management --------------------  */
    const [activeItem, setActiveItem] = useState(null);
    const prevActiveItemRef = useRef(null);

    const handleNavLinkClick = (item) => {
        if (activeItem !== item.id) {
            // Store the currently active item as the previous active item
            prevActiveItemRef.current = activeItem;
            setActiveItem(item.id);

            // Set the text color for the newly active item to white
            props.toggleactive(item.id);
            props.setName(item.NavName);

            // Reset the text color for the previously active item to black
            if (prevActiveItemRef.current !== null) {
                props.toggleactive(prevActiveItemRef.current);
            }
        }
    };
    let MobileviewSlider = {
        // arrows: false,
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2.5
                }
            },
        ]
    };
    // ============================== Trending Nav Text Start ==========================
    return (
        <div >
            <Slider  {...MobileviewSlider} className="default-space" >
                {props.PodcastNavData.map((item, index) => (
                    <Fragment index={index}>
                        {/* slick-slide slick-active auto-wid */}
                        <div
                            className='my-1'
                            style={{
                                backgroundColor: activeItem === item.id ? '#81c14b' : '#e6f3db',
                                borderRadius: '5px',
                            }}
                        >
                            <NavLink
                                className='my_events_Navlink d-flex justify-content-center rounded shadow'
                                active={props.active === item.id}
                                onClick={() => handleNavLinkClick(item)}
                                to="#"
                            >
                                <h5 style={{ color: activeItem === item.id ? 'white' : 'black', fontSize: '14px' }} >
                                    {item.NavName}
                                </h5>
                            </NavLink>
                        </div>
                    </Fragment>
                ))}
            </Slider>

            <div >

            </div>
        </div>
        // ============================== Trending Nav Text end ==========================


    )
}

export default TrendingNavList