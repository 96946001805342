import React from 'react'
import { Fragment } from 'react'
import { XCircle } from 'react-feather'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const HeadingPointModal = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen2} toggle={props.toggleOpenPoint} className='modal-dialog-centered'>
                    <div

                        onClick={props.toggleOpenPoint}
                        className="Modal_False_blk1"
                    >
                        <XCircle color="#FFFFFF" />
                    </div>

                    <ModalHeader className="Biz_Aditional_SECTION" >
                        <h3>Add Additional Information </h3>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup className="BizAddition_heading_section">
                            <h3>Heading and Points </h3>
                        </FormGroup>
                        <FormGroup>
                            <Label className="Biz_Rating_sab_section">
                                <h3>Heading</h3>
                            </Label>
                            <Input
                                id="exampleEmail"
                                name="catalogue"
                                placeholder="Enter your heading here "
                                type="catalogue"
                                maxLength="60"
                            />
                            <p className='text-end mt-1 WataDo_limit_section'>Max 60 charectors </p>
                        </FormGroup>
                        <FormGroup>
                            {props.formSubmitted ? <div className="service-Amenitieslist-blk">
                                <span>{props.formSubmitted}&nbsp;<XCircle color='#808080' size={20} /></span>
                            </div> : null}
                        </FormGroup>
                        <Form onSubmit={props.formSubmit}>
                            <FormGroup>
                                <Label className="Biz_Rating_sab_section">
                                    <h3>Type your amenities and tap on add</h3>
                                </Label>
                                <Input
                                    id="exampleEmail"
                                    name="catalogue"
                                    placeholder="Add amenities"
                                    type="text"
                                    maxLength="18"
                                    value={props.formValue}
                                    onChange={props.formChangeValue}

                                />
                                <p className='text-end mt-1 WataDo_limit_section'>Max 18 Pointers</p>
                                <p className='Heading_Points_section text-end mt-2'>+ Add more </p>
                            </FormGroup>
                        </Form>
                        <div className="text-center">
                            <Button className="" onClick={props.toggleOpenPoint}outline>Cancel</Button>
                            <Button color="primary " className=" ml-2 ">&nbsp;Save&nbsp; </Button>
                        </div>
                    </ModalBody>
                </Modal>
            }

        </Fragment>
    )
}

export default HeadingPointModal