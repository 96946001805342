import React, { useEffect, useState } from 'react'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import ContentLeft from '../../../ContentLeft'
import ContentRight from '../../../ContentRight'
import Header from '../../../Header'
import LeftSidebar from '../../../LeftSidebar'
import RightSidebar from '../../../RightSidebar'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import CreatepostHeader from './CreatepostHeader'
import CreatepostFooter from './CreatepostFooter'
import { Card, Input } from 'reactstrap'
import { XCircle } from 'react-feather'

const ArticleEditor = (props) => {
    const [convertedContent, setConvertedContent] = useState(null);
    const [imgfile, uploadimg] = useState("https://dummyimage.com/640x250")
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const imgFilehandeler = (e) => {
        if (e.target.files.length !== 0) {
            uploadimg(URL.createObjectURL(e.target.files[0]))
        }
    }

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);
    console.log(convertedContent);
    return (
        <>
            <Header />
            <div className="page-body container-fluid newsfeed-style1">
                <LeftSidebar />
                <div className="page-center">
                    <div className="container-fluid px-0">
                        <div className="page-content">
                            <ContentLeft />
                            <div style={{ fontSize: 'calc(10px+2vmin)' }} className="content-center">
                                <Card className='p-3 rounded'>
                                    <CreatepostHeader
                                        actionM={props.NavMedia}
                                        actionT={props.NavThrought}
                                        actionG={props.NavGoLive}
                                        actionE={props.NavEvent}
                                        actionP={props.NavPodcast}
                                        actionA={props.NavArticle}
                                        actionR={props.NavReccom}
                                        actionAl={props.NavAlert}
                                        actionPo={props.NavPoll}
                                        actionS={props.NavSell}
                                    />
                                    <Input type='textarea' className='positionForArticleSplaceholderS' Placeholder='Headline' />
                                    <div>
                                        <div>
                                            <input type="file" id="upload" onChange={imgFilehandeler} hidden />
                                            <XCircle color='#81C14B' onClick={() => uploadimg("https://dummyimage.com/640x250")} className='positionForImgremoveS' size={20} />
                                        </div>
                                        <label className='kitForinputbox' for="upload">
                                            <img src={imgfile} alt='Featured Images' className='border border-secondary' width='100%' height={250} />
                                        </label>
                                    </div>
                                    <Editor editorState={editorState} onEditorStateChange={setEditorState}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editorClassName bg-light border"
                                        toolbarClassName="toolbar-class"
                                    />
                                    <CreatepostFooter
                                        FirsticonS={'d-none'}
                                        Firsticon={'d-none'}
                                        Secondicon={'d-none'}
                                    />
                                </Card>

                            </div>
                            <ContentRight />
                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </>
    )

}

export default ArticleEditor