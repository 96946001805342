import { Radio } from '@mui/material'
import React, { useState } from 'react'
import { Fragment } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'
import Profile from '../../../NewComponents/IMG/AccountImg.png'
const DeleteAccount = () => {
    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);

    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword, setShowPassword] = useState(false);
    //==================== start_DeleteAccount_data ==============//
    const AccountDeleteData = [
        {
            id: "a",
            reason: "Bored of using sociomee"
        },
        {
            id: "b",
            reason: "Other Reason"
        }
    ]
    //==================== end_DeleteAccount_data ==============//

    //============ Main_return_function =================//
    return (
        <Fragment>
            <div className='SettingPrivacy_Main_section'>
                {/* <div>
                    <h3><span className='text-danger'>Deleting</span> Account</h3>
                </div> */}
                <div className='Delete_Account_Content_Section_blk pb-3'>
                    <h4>Please select your reason for<span> deactivating</span>&nbsp;your account.</h4>
                </div>
                {
                    AccountDeleteData.map((data) => (
                        <div className='SettingAccount_Content_Section mt-2'>
                            <div className='SettingAccount_DeleteContent_Section'>
                                <div>
                                    <h4>{data.reason}</h4>
                                </div>
                                <div>
                                    <Radio checked={selectedValue === data.id}
                                        onChange={handleChange}
                                        value={data.id}
                                        name="radio-buttons"
                                        className='Radio_btn_check'
                                        color='primary'
                                        inputProps={{ 'aria-label': 'A' }} />
                                </div>
                            </div>


                        </div>
                    ))
                }
                {selectedValue === "b" && (
                    <Fragment>
                        <div className='py-2'>
                            <textarea placeholder='Enter your reason here ' className="form-control" name="" id="" cols="15" rows="5"></textarea>
                            <p className='d-flex justify-content-end text-muted'>Max 240 charectors </p>
                        </div>
                    </Fragment>
                )}
                <div className='d-flex justify-content-center'>
                    <div className='Delete_Account_profileSection'>
                        <img src={Profile} alt='profile' className='rounded-circle' />
                        <div className='ml-3'>
                            <h3>Deepak kumar</h3>
                            <p>rohan@734675</p>
                        </div>
                    </div>
                </div>
                <div className='text-center pt-4 pb-3 Delete_Account_DetailSection'>
                    <h3> Profile associated with&nbsp;<span>u**************gmail.com</span>&nbsp;will be deleted</h3>
                    <h4 className='pt-2'>Not your account? <span
                        style={{
                            color: "#FF9922",
                            fontWeight: "600",
                            fontSize: "18px"
                        }} ><NavLink to="/" className='AccountProfile-login'>Login to your account</NavLink></span></h4>
                </div>
                <div className=' Delete_Account_InputSection'>

                    <div className="Delete_Account_InputBlock">
                        <Label className="SettingAccount_DeleteContent_Section">
                            <h4>Enter password</h4>
                        </Label>
                        <InputGroup className='input-group-merge '>
                            <Input
                                type={showPassword ? 'password' : 'text'}
                            />

                            <InputGroupAddon addonType='prepend' >
                                <InputGroupText onClick={togglePasswordVisibility} >
                                    {
                                        showPassword ?
                                            <EyeOff size={14} /> :
                                            <Eye size={14} />
                                    }

                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                        <p className='text-end mt-2'
                            style={{
                                fontSize: '14px',
                                fontWeight: "600", color: " #0066FF"
                            }}>
                            Forgot password?
                        </p>
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <Button className="Setting_Deleted_btn" outline>Cancel</Button>
                    <Button className=" ml-3 Setting_Deleted_btn" color="primary">Confirm </Button>
                </div>

            </div>
        </Fragment>
    )
}

export default DeleteAccount