// reducers/marketPlaceReducer.js
import * as types from '../../Constants/MarketPlace/index';

const initialState = {
  products: [],
  error: null,
};

export const marketPlaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_MARKETPLACE_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case types.GET_ALL_MARKETPLACE_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const singleMarketPlaceProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SINGLE_MARKETPLACE_PRODUCT:
      return {
        ...state,
        products: action.payload,
      };
    case types.GET_SINGLE_MARKETPLACE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllProductCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MARKETPLACE_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case types.GET_MARKETPLACE_CATEGORY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const getSuggestedProductListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUGGESTED_MARKETPLACE_PRODUCT:
      return {
        ...state,
        suggestedProducts: action.payload,
      };
    case types.GET_SUGGESTED_MARKETPLACE_PRODUCT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const getAllCategoryAndSubcategoryHaveDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA:
      return {
        ...state,
        categoryAndSubcategoryData: action.payload,
      };
    case types.GET_ALL_CATEGORY_AND_SUBCATEGORY_HAVEDATA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};



