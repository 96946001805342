import React, { Fragment } from 'react'
import Header from '../../../Header'
import LeftSidebar from '../../../LeftSidebar'
import RightSidebar from '../../../RightSidebar'
import SeriesPodcastView from './SeriesPodcastView'
import CommonPurchased from './CommonPurchased'

const SeePurchased = () => {
  return (
    <Fragment>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <div className="page-content">
            <div className="container-fluid section-t-space p-0 mt-0">

            <CommonPurchased/>

            </div>
          </div>

        </div>
        <RightSidebar />
      </div>

    </Fragment>
  )
}

export default SeePurchased