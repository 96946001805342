import React from 'react'
import { ChevronDown, Globe, Lock } from 'react-feather'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'

const ShotzEditHeader = () => {
    return (
        <>
            <UncontrolledButtonDropdown direction='start'>
                <DropdownToggle color='flat-primary' className='d-flex align-items-center m-0 p-0'>
                    <Globe color='#4D4D4D' size={16} /><h5 className='header-text-style mx-2'>Public</h5>
                    <ChevronDown color='#4D4D4D' size={18} />
                </DropdownToggle>
                <DropdownMenu >
                    <DropdownItem className='border-bottom py-2 shotz-menu-hover-style' >
                        <h5><Globe color='#4D4D4D' size={15} />&nbsp; Public</h5>
                    </DropdownItem>
                    <DropdownItem className='py-2 shotz-menu-hover-style'>
                        <h5><Lock color='#4D4D4D' size={15} />&nbsp; Private</h5>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </>
    )
}

export default ShotzEditHeader