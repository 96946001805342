import React, { useState } from 'react'
import { Pagination, Stack } from '@mui/material'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { listTextdata } from '../DummyArray'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'


const ListText = ({ display, space }) => {
    const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }


    {/* ---------------------------------------------------------------Main return function----------------------------------------------------------- */ }
    return (
        <div>
            <Row className='mt-2'>
                <Col md='12' className='' >
                    {/* -------------------------------------------------Dynamic list text data rendering------------------------------------------------------------------------- */}
                    {listTextdata.map((data, index) => (

                        <div className='border rounded p-2 on-hover-bg' key={index} >
                            <Row className='d-flex align-items-center'>
                                <Col xl='7'>
                                    {/* <div className='d-flex ml-2'>
                                        <Badge color={`light-primary ${display}`}>
                                            Trending #{data.trendingNumber}
                                        </Badge>
                                        <Badge color='primary' pill className={space}>
                                            Top #{data.topsNumber}
                                        </Badge>
                                    </div> */}
                                    <div className='d-flex justify-content-start align-items-center pl-1'>

                                        <div className='d-flex align-items-center mr-2'>
                                            <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center'>
                                                <p className='PilesThistags text-center'>Top</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                            </div>

                                            <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                <p className='PilesThistags text-center'>Trending</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: '', borderRadius: '10px', width: '20rem' }} className='p-1 border  ml-5'>
                                            {/* <img src={LeftquotationIcon} className='LeftIconforthrought rem-mob-view' alt="icon" /> */}
                                            <div className='mx-2 '>
                                                <div className='pt-1'>
                                                    {/* <p className='ml-1 text-justify text-wrap p-2'>more user-centric and {seemore ? 'by their very nature, are about connections. Through your' : null}attendance or<span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? ' Read less' : '... Read more'}</span> </p> */}
                                                    <p>{'Many of life’s failures are people who did not realize how close  they gave up.'.slice(0, 100) + '...'}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='d-flex justify-content-start align-items-center  ml-3 text-nowrap'>
                                            <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-5 rounded-circle ArticleShowdo' alt='' />
                                            <div className='ml-1'>
                                                <NavLink to='/MyProfile' style={{ color: 'black' }}><p className='user-profile' >{data.name}</p></NavLink>
                                                <small className='text-muted light-for-date'>{data.dateandtime}</small>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='4'>
                                    <div className='d-flex justify-content-center align-items-center ml-5'>
                                        <div className='d-flex align-items-center'>
                                            <div onClick={() => handleLike()}>
                                                {liked ?
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                    :
                                                    <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                }
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center px-3' data-bs-toggle="modal" data-bs-target="#imageModel" >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                            </div>
                                            11.2k
                                        </div>

                                    </div>
                                </Col>
                                <Col xl='1' >
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className='ml-2 p-0' color='flat-primary' >
                                            <MoreHorizontal color='#999999' size={19} />
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: 'black' }}><Eye size={13} />&nbsp;View creator's profile </NavLink></DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>

                            </Row>
                        </div>
                    ))}
                    {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}

                    {/* <div className='border rounded mt-3'>
                        <Row className='d-flex align-items-center mt-3'>
                            <Col xl='6'>
                                <div className='d-flex ml-2'>
                                    <Badge color={`light-primary ${display}`}>
                                        Trending #1
                                    </Badge>
                                    <Badge color='primary' pill className={space}>
                                        Top #96
                                    </Badge>
                                </div>
                                <div className='d-flex justify-content-start align-items-center p-2'>
                                    <div style={{ marginTop: '', borderRadius: '10px', width: '14rem' }} className='p-1 border'>
                                        <div className='mx-2 text-center'>
                                            <div className='text-center pt-3'>
                                                <p className='ml-1 text-justify'>more user-centric and {seemore ? 'by their very nature, are about connections. Through your' : null}attendance or<span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-start align-items-center pl-2'>
                                        <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' />
                                        <div className='ml-1'>
                                            <p>Floyd Miles</p>
                                            <p className='text-muted'>19 sept at 07:00 pm</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='6'>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                        </div>
                                        11.2k
                                    </div>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className='m-0 p-0' color='flat-primary' >
                                            <MoreHorizontal color='black' />
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><Edit size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Col>

                        </Row>
                    </div> */}
                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    {/* <p>Total Text Count : <span className='text-primary' >{listTextdata.length}</span></p> */}
                                    <p>Showing 1 to {listTextdata.length} of 200 entries</p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex p-2 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}

            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

        </div>
    )
}

export default ListText