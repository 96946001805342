export const DataForTable = [
    {
        color: "#000",
        label: "Reach",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#B28CD0",
        borderColor: "#B28CD0",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#2a2c30",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#2a2c30",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: [0, 14, 21, 9, 35, 31, 36, 20, 12, 18],
        show: true
    },
    {
        color: "#000",
        label: "Impression",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#7BD77F",
        borderColor: "#7BD77F",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#f84c1e",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#f84c1e",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: [20, 39, 10, 11, 16, 2, 40, 23, 12, 53],
        show: true
    },
    {
        color: "#000",
        label: "Lead",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#e90064",
        borderColor: "#e90064",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#f84c1e",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#f84c1e",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: [10, 50, 30, 15, 46, 9, 30, 32, 12, 22],
        show: true
    },
    {
        color: "#000",
        label: "Click",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#DCA594",
        borderColor: "#DCA594",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#f84c1e",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#f84c1e",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: [10, 50, 30, 15, 46, 9, 20, 32, 12, 30],
        show: true
    },
    {
        color: "#000",
        label: "View",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#009fbd",
        borderColor: "#009fbd",
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBorderColor: "#f84c1e",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#f84c1e",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: [2, 50, 30, 10, 46, 9, 30, 32, 12, 20],
        show: true
    },
]


export const BarDumyData = [
    {
        label: "Men",
        data: [4, 3, 6, 8, 7, 13, 22],
        backgroundColor: "#762A89",
    },
    {
        label: "Women",
        data: [5, 12, 4, 11, 15, 5, 6],
        backgroundColor: "#298DB8",
    },
    {
        label: "Other",
        data: [2, 10, 2, 6, 7, 4, 3],
        backgroundColor: "#7BD77F",
    },
]