import React, { useEffect } from 'react'
import {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody, Col, Form, FormGroup, Label, Row, Input, UncontrolledTooltip } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { sweetAlertConfirmation } from '../AllGroupsSections/SweetAlert';
import { SweetErrorAlert } from '../AllGroupsSections/SweetErrorAlert';
import { useNavigate } from 'react-router-dom'
import { updateGroupPostByIdAction, selectGroupById } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { Info, UploadCloud } from 'react-feather';
import axios from 'axios'


const GroupInfoSettings = () => {
    const { getAllGroupCategory, groupPostError } = useSelector((state) => { return state.getAllCategoryFromGroupData });
    
    const { getGroupById } = useSelector((state) => { return state.getAllGroupByIdData });

    console.log('getGroupById', getGroupById)

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const grpId = params.id



    const [category, setCategory] = useState("")
    const [privacy, setPrivacy] = useState("")
    const [groupDescription, setGroupDescription] = useState("")
    const [groupName, setGroupName] = useState("")
    const [image, setImage] = useState("")

    const onCategory = (event) => {
        const value = event.target.value;
        setCategory(value);
    };  
    useEffect(() => {
        selectGroupById(grpId)
        setGroupName(getGroupById?.data?.successResult?.name)
        setCategory(getGroupById?.data?.successResult?.grpCategory?.name)
        setPrivacy(getGroupById?.data?.successResult?.privacy)
        setGroupDescription(getGroupById?.data?.successResult?.description)
        setImage(getGroupById?.data?.successResult?.coverThumb)

    }, [getGroupById, grpId])

    let groupInfo = {
        groupName: groupName,
        description: groupDescription,
        category: category,
        privacy: privacy,
        image:image,
    }
  
    const updatePost = () => {
        dispatch(updateGroupPostByIdAction(grpId, groupInfo))
        if (groupPostError) {
            SweetErrorAlert('All fields are mondatory')
        } else {
            sweetAlertConfirmation('Updated Successfully')
            navigate('/Group')
        }
    };
    const fileHandler = async(e) =>{
        // e.preventDefault()
        let file = e.target.files[0]
        let groupImage = 'groupImage'
        const form = new FormData()
        form.append('file', file)
        form.append('uploadFor', groupImage)
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            const cofig = {
                headers:{
                    'Content-Type':'multipart/form-data',
                    'Authorization': `Bearer ${user?.token}`
                }
            }
            const {data} = await axios.post(`${process.env.REACT_APP_IPURL}/admin/uploadFile`,
            form,cofig            
            )            
            setImage(data.data.successResult[0])
            
        } catch (error) {
        }       
    }

  return (
    <div className="custom-card-block">
    <div className="setting-card-head biz-carddetail-blk">
        <h4>Group Info Settings</h4>
    </div>
    <CardBody className="custom-card-body">
        <div className="biz-main-about-blk ">
            <Row>
                <Col md="6">
                    <Form>
                        <FormGroup>
                            <Label className="GroupName_Label_Section" for="GroupName">
                                <h4>Group Name</h4>
                            </Label>
                            <Input

                                placeholder="Enter your group name"
                                id="groupName"
                                type="text"
                                name="groupName"
                                onChange={e => setGroupName(e.target.value)}
                                value={groupName}
                            />

                            <p className='validation text-muted'>Max 60 charectors </p>
                        </FormGroup>
                        <FormGroup>
                            <Label className="GroupName_Label_Section" for="selectNameexample">
                                <h4>Group Category</h4>

                            </Label>
                            <Input
                                id="selectNameexample"
                                name="selectName"
                                type="select"
                                className="Create_GroupInfo_Input w-100"
                                onChange={onCategory}
                                value={category}
                                >
                                <option disabled={!category}>
                                    {category
                                    ? getGroupById?.data?.successResult?.grpCategory?.name
                                    : "Select your category"}
                                </option>
                                {getAllGroupCategory?.map((data) => (
                                    <option key={data.id} value={data.id}>
                                    {data.name}
                                    </option>
                                ))}
                                </Input>

                        </FormGroup>
                        <FormGroup>
                            <Input
                                name="radio1"
                                type="radio"
                                className="public_Check"
                                onChange={() => setPrivacy('public')}
                                checked={privacy === 'public'}
                            />
                            {' '}
                            <Label className="Check_Label_Section" >
                                <h4>Public </h4>
                                <p>Anyone can your Group’s post and its member </p>
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                name="radio1"
                                type="radio"
                                className="Private_Check"
                                onChange={() => setPrivacy('private')}
                                checked={privacy === 'private'}
                            />
                            {' '}
                            <Label className="Private_Label_Section">
                                <h4>Private  </h4>
                                <p>Only group members can view post and other members of your group</p>
                            </Label>
                        </FormGroup>
                    </Form>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="text"
                            className="GroupName_Label_Section"
                        >
                            <h4>Description </h4>
                        </Label>
                        {/* <Input
                            type="textarea"
                            name="text"
                            id="text"
                            placeholder="Description is the..."
                        /> */}
                        <textarea placeholder='Enter your Description'
                            className="form-control"
                            name=""
                            id=""
                            cols="15" rows="5"
                            onChange={e => setGroupDescription(e.target.value)}
                            value={groupDescription}

                        />
                        <p className='Descriptionvalidation'>Max 180 Characters </p>
                    </FormGroup>
                    <FormGroup>
                                <Label for="exampleGroup">
                                    <div className="d-flex align-items-center Grop_Form_Label py-1">
                                        <img src={image} alt='coverImg' width={'100%'}/> 
                                        <p>Upload</p>&nbsp;<Info id='UploadId' size={15} color="#C4C4C4" />
                                        <UncontrolledTooltip placement='right' target='UploadId'>
                                            max size 1mb
                                        </UncontrolledTooltip>
                                    </div>
                                </Label>
                                <div className="profile-cover profile-cover-new">
                                    <a href="#" className="btn btn-solidUpload btn-group btn-grp-new">
                                        <ul>
                                            <li className="choose-file">
                                                <a className='UploadBtn' href="">
                                                    <UploadCloud size={14} />&nbsp;
                                                    Upload
                                                </a>
                                                <input  type="file" 
                                                onChange={fileHandler}
                                                />
                                            </li>
                                        </ul>
                                    </a>

                                </div>
                            </FormGroup>
                    <div className='d-flex justify-content-end'>
                        <Button outline className="Group_Cancle_btn"><p>Cancel</p></Button>
                        <Button color='primary' className="Group_Update_btn ml-3"
                            onClick={ updatePost}>
                            <p>Update</p></Button>
                    </div>
                </Col>
            </Row>

        </div>

    </CardBody>

</div>

  )
}

export default GroupInfoSettings
