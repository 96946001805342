import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Circle, Grid } from 'react-feather'
import ColorPicker from '../Story/ColorPicker'

const Coloroption = () => {
    const Coloroptiondata = [
        {
            text: '#FB6E6E'
        },
        {
            text: '#A8FFAC'
        },
        {
            text: '#7DC1FF'
        },
        {
            text: '#A49494'
        },
        {
            text: '#FD98FF'
        },
        {
            text: '#F8DF88'
        },
        {
            text: '#60715C'
        },
        {
            text: '#353535'
        },
        {
            text: '#FFFFFF'
        },
    ]
    return (
        <div className=''>
            <Row>
                <Col>
                    <h4 style={{ color: '#4D4D4D' }}>Text Color</h4>
                    <div className='d-flex'>
                        {
                            Coloroptiondata.map((item) => {
                                return <>
                                    <Circle className='shadowForcircle my-2' fill={item.text} strokeWidth={0} size={30} />
                                </>
                            })
                        }
                        <ColorPicker />
                    </div>

                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 style={{ color: '#4D4D4D' }}>Background Color</h4>
                    <div className='d-flex'>
                        {
                            Coloroptiondata.map((item) => {
                                return <>
                                    <Circle className='shadowForcircle mt-2' fill={item.text} strokeWidth={0} size={30} />
                                </>
                            })
                        }
                        <ColorPicker />
                    </div>

                </Col>

            </Row>

        </div>
    )
}

export default Coloroption