import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  // FacebookAuthProvider,
  GithubAuthProvider,
} from "firebase/auth";
import { auth } from "../firsbase";
import { useLocation } from "react-router-dom";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }
  // function facebookSignIn() {
  //   const facebookAuthProvider = new FacebookAuthProvider();
  //   return signInWithPopup(auth, facebookAuthProvider);
  // }
  function githubSignIn() {
    const githubAuthProvider = new GithubAuthProvider();
    return signInWithPopup(auth, githubAuthProvider);
  }

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);





  // manage the state of the podcast list view and grid view 
  const [listView, setListView] = useState(true)
  const [MobPost, setMobPost] = useState(false);
  const [searchHide, setsearchShow] = useState(false)


  const handleClose = () => setMobPost(false);
  const handleShow = () => setMobPost(true);


  // manage the state of the podcast musicbar  
  const [musicBar, setMusicBar] = useState(false);


  // ------------- shotz Carousel Modal State -------------
  const [shotzCarouselModal, setShotzCarouselModal] = useState(false)








  // Login SignUp Module States 
  const [totalSteps, setTotalSteps] = useState(null)
  const [currentStep, SetCurrentStep] = useState(null)






  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        // facebookSignIn,
        githubSignIn,
        setUpRecaptha,
        listView,
        setListView,
        MobPost,
        searchHide,
        setsearchShow,
        setMobPost,
        handleClose,
        handleShow,
        // login SignUp 
        totalSteps,
        currentStep,
        setTotalSteps,
        SetCurrentStep,
        musicBar,
        setMusicBar,
        shotzCarouselModal,
        setShotzCarouselModal,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
