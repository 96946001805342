import React, { useState } from "react";


import Adanalytics from "../../../Ad-Manager/Sub-components/Adanalytics";


const AdAnalysis = (props) => {



  return (
    <>
      <Adanalytics />
    </>
  );
};

export default AdAnalysis;
