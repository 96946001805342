import React, {useEffect} from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadAllGroupById, loadAllJoinedGroup, loadAllSuggestedGroup, loadAllInvitationGroup } from '../../Services/Actions/Group/getAllGroupMainPageAction';

export default function GroupMenu() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(loadAllGroupById())
    }, [])

    useEffect(() => {
        dispatch(loadAllJoinedGroup())
    }, [])

    useEffect(() => {
        dispatch(loadAllSuggestedGroup())
    }, [])
   
    useEffect(() => {
        dispatch(loadAllInvitationGroup())
    }, [])


    const  { getGroupMainPageOwnGroup } = useSelector((state) => { return state.getAllGroupMainPageData });
    const { getGroupJoinedGroup } = useSelector((state) => {return state.getAllJoinedGroupData});
    const { getAllSuggestedGroup } = useSelector((state) => { return state.getAllGroupSuggestedGroupData});
    const { getGroupInvitation } = useSelector((state) => {return state.getAllGroupInvitationData});
    
    const totalOwnGroup = getGroupMainPageOwnGroup && getGroupMainPageOwnGroup.data.successResult.rows.length
    const totalJoinedGroup = getGroupJoinedGroup && getGroupJoinedGroup.data.successResult.count
    const totalSuggestedGroup =  getAllSuggestedGroup && getAllSuggestedGroup.data.successResult.length
    const totalInviteGroup = getGroupInvitation && getGroupInvitation.data.successResult.data.rows.length

//================== main_return_function ===================//
    return (
        <>
            <div className="profile-menu section-t-space">
                <ul>
                    <li>
                        <NavLink to="/Group">
                            <h6>All({totalOwnGroup+totalJoinedGroup+totalSuggestedGroup+totalInviteGroup})</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/OwnedGroup">
                            <h6>Owned Group ({totalOwnGroup})</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/JoinedGroup">
                            <h6>Joined Group ({totalJoinedGroup})</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/InviteGroup">
                            <h6>Requests ({totalInviteGroup})</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/Suggested">
                            <h6>Suggested ({totalSuggestedGroup})</h6>
                        </NavLink>
                    </li>
                </ul>
                <ul className="right-menu d-xl-flex d-none">
                    <li>
                        <NavLink to="/NewGrpCreateSection">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                            <h6>Create Group</h6>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}
// import React from 'react'
// import { Fragment, useState } from 'react'
// import classnames from 'classnames'
// import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
// import Group from './Group'
// import OwnedGroup from './OwnedGroup'
// import GroupInvitation from './GroupInvitation'
// import JoinedGrpSection from './AllGroupsSections/JoinedGrpSection'

// const GroupMenu = (prop) => {
//     const [currentActiveTab, setCurrentActiveTab] = useState('1')

//     const toggle = tab => {
//         if (currentActiveTab !== tab) setCurrentActiveTab(tab)
//     }
//     return (
//         <Fragment>
//             <Row className="profile-menu section-t-space " >
//                 <Col md="8">
//                     <Nav pills style={{ margin: '1%' }}>
//                         <NavItem style={{
//                             background: "rgba(129, 193, 75, 0.2)",
//                             borderRadius: "5px",
//                             marginRight: "15px"
//                         }}>
//                             <NavLink
//                                 className={
//                                     classnames({
//                                         active: currentActiveTab === '1'
//                                     })}
//                                 onClick={() => { toggle('1') }}
//                             >
//                                 <span>All (05)</span>
//                             </NavLink>
//                         </NavItem>
//                         <NavItem style={{
//                             background: "rgba(129, 193, 75, 0.2)",
//                             borderRadius: "5px",
//                             marginRight: "15px"
//                         }}>
//                             <NavLink
//                                 className={
//                                     classnames({
//                                         active: currentActiveTab === '2'
//                                     })}
//                                 onClick={() => { toggle('2') }}
//                             >
//                                 <span>Owned Group (14)</span>
//                             </NavLink>
//                         </NavItem>
//                         <NavItem style={{
//                             background: "rgba(129, 193, 75, 0.2)",
//                             borderRadius: "5px",
//                             marginRight: "15px"
//                         }}>
//                             <NavLink
//                                 className={
//                                     classnames({
//                                         active: currentActiveTab === '3'
//                                     })}
//                                 onClick={() => { toggle('3') }}
//                             >
//                                 <span>Joined Group (17)</span>
//                             </NavLink>
//                         </NavItem>
//                         <NavItem style={{
//                             background: "rgba(129, 193, 75, 0.2)",
//                             borderRadius: "5px",
//                             marginRight: "15px"
//                         }}>
//                             <NavLink
//                                 className={
//                                     classnames({
//                                         active: currentActiveTab === '4'
//                                     })}
//                                 onClick={() => { toggle('4') }}
//                             >
//                                 <span>Requests (13)</span>
//                             </NavLink>
//                         </NavItem>
//                     </Nav>
//                 </Col>
//                 <Col md="4">
//                     <div>

//                     </div>
//                 </Col>
//             </Row>
//             <Row className="mt-3">
//                 <Col md="12">
//                     <TabContent activeTab={currentActiveTab}>
//                         <TabPane tabId="1">
//                             <JoinedGrpSection />
//                         </TabPane>
//                         <TabPane tabId="2">
//                             <JoinedGrpSection />
//                         </TabPane>
//                         <TabPane tabId="3">
//                             <GroupInvitation />
//                         </TabPane>
//                         <TabPane tabId="4">
//                             request
//                         </TabPane>
//                     </TabContent>
//                 </Col>
//             </Row>
//         </Fragment>
//     )
// }

// export default GroupMenu