import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Radio } from '@mui/material';
import { getMpReportOptions, reportMarketPlaceProduct } from '../../../Services/Actions/MarketPlace/getAllProductsAction'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'



const MpReportModal = (props) => {
    const ReportDataInfo = [
        {
            name: "Offensive Content"
        },
        {
            name: "Fraud"
        },
        {
            name: "Duplicate ad"
        },
        {
            name: "Product Already Sold"
        },
        {
            name: "Other"
        },

    ]
    const [radioActive, setRadioActive] = useState(null)
    const [reportOptions,setReportOptions] = useState([])
    const [reportOptionId, setReportOptionId] = useState(null)
    const [remark, setRemark] = useState(null)
    
    
    //========> geting the report options <<=====
    useEffect(()=>{
        const fetchReportOptionData = async()=>{
        const data = await getMpReportOptions()
        setReportOptions(data)
        }

        fetchReportOptionData()
    },[props.reportOpen])





    // ===>> api call for report marketPlace product <<========
    const handleReportProduct = async () =>{
        let body = {
            marketPlaceId: props.marketPlaceId,
            reportOptionId: reportOptionId,
            remark: remark
        }
        
        const  result = await reportMarketPlaceProduct(body)
        sweetAlertConfirmation("Reported Successfully")
        props.setRefresh(prevRefresh => !prevRefresh)
        setReportOptionId(null)
        setRemark(null)
        setRadioActive(null)
    }
            
      










    // =============================>>>>>> component <<<<<<<<==================================
    return (

        <>
            <div>
                {
                    props.reportOpen !== false &&
                    <Modal scrollable isOpen={props.reportOpen} toggle={props.toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={props.toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <h4>Tell us about what you feel and help us to make Sociomee more secure.</h4>
                            </div><br></br>
                            {
                                reportOptions?.map((data, index) => (
                                    <div key={index} className='d-flex justify-content-between mt-41 Report_Section'>
                                        <h4
                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                            onClick={() => {
                                                setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                                            }}
                                        >
                                            {data?.reportText}
                                        </h4>
                                        <div>
                                            <Radio
                                                inputProps={{ 'aria-label': 'A' }}
                                                name="radio-buttonsOne"
                                                id="radio-buttonsOneId"
                                                className='Radio_btn_check'
                                                checked={radioActive === index}
                                                onClick={() => {setRadioActive((prevIndex) => (prevIndex === index ? null : index)); setReportOptionId(data?.id)}}
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="text"
                                    onChange ={(e)=>setRemark(e.target.value)}
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                               {reportOptionId && <Button className="w-25 ml-2" color="primary" onClick={()=>{props.toggleReport();handleReportProduct()}} >Submit</Button>}
                                <Button onClick={props.toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
        </>
    )
}

export default MpReportModal; 
