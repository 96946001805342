import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import { LanguagesAll } from '../LoginDummyArray/signupdetailsArray'
import { Search } from 'react-feather'
import { Input } from 'reactstrap'

const OffCanvasLanguages = ({ show, handleClose }) => {
    return (
        <div>
            <Offcanvas show={show} onHide={handleClose} placement='bottom' className="LanguageOffCanvas ">
                <Offcanvas.Header closeButton>
                    <div className='d-flex align-items-center justify-content-start ml-2 w-100 fs-6 fw-bold'>
                        Choose Language
                    </div>

                </Offcanvas.Header>
                <div className="LanguageSerchBar2 d-flex align-items-center border rounded  px-2 mx-4 my-1">
                    <Input type="search" placeholder="Search Language" className="border-0" />
                    <Search size={16} color="#4d4d4d" />
                </div>
                <Offcanvas.Body>
                    <div className="LanguageTableAlltabs">
                        {LanguagesAll.map((item) => (
                            <div className="LanguageTabsMore p-2 rounded m-1">
                                {item}
                            </div>
                        ))}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default OffCanvasLanguages
