import React from 'react'
import img_1 from '../../NewComponents/IMG/img_1.png'
import profile from '../../NewComponents/IMG/profile_conn.png'
import { AlertTriangle, Check, Circle, Eye, Link, MapPin, MoreHorizontal, Plus, Share2 } from 'react-feather';
import { Badge, Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal';
import { useState } from 'react';
import ReportModal from './Grid-Components/ModalGrid/ReportModal';
import { cardDataTopPeople } from './DummyArray';
import { NavLink } from 'react-router-dom';
import { Pagination, Stack } from '@mui/material';

const TopPeopleTabs = () => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    // const [topTab, setTopTab] = useState(true);

    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            // return `${(num / 1000).toFixed(1).toString()} K`
            return `${(num / 1000).toString()} K`
        } else if (num > 999999) {
            // return `${(num / 1000000).toFixed(1).toString()} M`
            return `${(num / 1000000).toString()} M`
        } else if (num < 900) {
            return `${num}`
        }
    }

    const peopleTable = [5, 6, 7, 8, 9, 10, 11, 12];
    //---------------------------------------Main return function----------------------------
    return (
        <div className='p-2'>
            <p className='TrendingPeoplethistext '></p>

            <Row className='mt-2'>
                {/*-----------------------------------------------------Dynamic card data rendering for trending peoples----------------------------------------------------- */}
                {cardDataTopPeople.map((data, index) => (

                    <Col xl='3' lg='4' md='6' className='d-flex justify-content-center mt-2 p-1' key={index} >
                        {/* {
                            index === cardDataTopPeople.length - 1
                                ? { borderBottom: '1px solid rgba(129, 193, 75, 0.2)' }
                                : {}
                        } */}
                        <Card className='border w-100 shadow-sm bg-white roundedThisBorder on-hover-bg-border'>
                            <div className='d-flex px-2 mt-2 '>
                            <NavLink to='/MyProfile'>
                                    <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                        Top #{data.topsNumber}
                                    </Badge>
                                </NavLink>&nbsp;
                                <NavLink to='/MyProfile'>
                                    <Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                        Trending #{data.trendingNumber}
                                    </Badge>
                                </NavLink>
                                
                            </div>
                            <div className='p-2'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img src={profile} alt='userImg' height='50px' width='50px' />
                                        <Circle strokeWidth={5} stroke='white' size={12} fill='#16C31E' className='CricleThisFill' />
                                        <div className='ml-2'>
                                            <NavLink to='/MyProfile' style={{ color: 'black' }} ><h4 className='user-profile'>{data.name}</h4></NavLink>
                                            <div className='d-flex text-muted'>
                                                <MapPin size={14} className='align-self-center' />
                                                <small className='user-profile' > &nbsp;{data.country}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginTop: '-21px'}}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle className='p-0 m-0' color='flat'    >
                                                <MoreHorizontal size={19} color='#999999' />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu dropdownLocation dropdown-for-user-specific mt-1'>
                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share post</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View profile</NavLink></DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-around mt-2' >
                                    <div className='text-center w-50' >
                                        <p className="FollwerThisFontsize"> {data.followerCount}</p>
                                        <h4 className='' style={{ color: 'rgba(0, 0, 0, 0.50)' }}>Followers</h4 >
                                    </div>
                                    <span className='border-right' ></span>
                                    <div className='text-center w-50' >
                                        <p className="FollwerThisFontsize" >{data.postCount}</p>
                                        <h4 style={{ color: 'rgba(0, 0, 0, 0.50)' }}>Post</h4 >
                                    </div>
                                </div>
                                <p className='RecentThisText'>Recent media</p>
                                <div className='p-2'>
                                    <Row className=' mb-2'>
                                        <Col xl='8' lg='8' md='8' sm='8' xs='8' className='m-0 px-1'>
                                            <div><img src={data.img1} className='img-fluid circularCorner' alt='' /></div>
                                        </Col>
                                        <Col xl='4' lg='4' md='4' sm='4' xs='4' className='m-0 px-1'>
                                            <div><img src={data.img2} className='img-fluid circularCorner' alt='' /></div>
                                            <div className='diagonalthisgradient'>
                                                <img src={data.img3} className='img-fluid mt-1 circularCorner' alt='' />
                                                <span className='NumThisPeople text-muted' >+{numFormatter(90000)}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    {(index % 2 === 0) ? <div className='d-flex w-100 justify-content-evenly' > <Button style={{ backgroundColor: '#e6f3db', outline: 'none', border: 'none', color: '#81C14B' }}><Check size={18} /> Following</Button>&nbsp;&nbsp;&nbsp;
                                        <Button color='primary p-2 w-100' outline className=''>
                                            <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                                <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="3" />
                                            </svg>
                                            Message</Button>
                                    </div> : <Button color='primary p-2 w-100'><Plus size={18} /> Follow</Button>}
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}

                {/* <Col lg='3' className='d-flex justify-content-center mt-2 p-1'>
                    <Card className='border w-100 shadow-sm bg-white roundedThisBorder'>
                        <div className='d-flex p-2 mt-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-2'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='p-2'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src={profile} alt='userImg' height='50px' width='50px' />
                                    <Circle strokeWidth={0} size={10} fill='#16C31E' className='CricleThisFill' />
                                    <div className='ml-2'>
                                        <h4>Robert Fox</h4>
                                        <div className='d-flex text-muted'>
                                            <MapPin size={14} className='align-self-center' />
                                            <p>&nbsp;Denmark</p>
                                        </div>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                        <MoreHorizontal size={19} color='black' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='d-flex justify-content-around mt-2'>
                                <div className='text-center'>
                                    <p className="FollwerThisFontsize">456</p>
                                    <h4 className='text-muted'>Followers</h4>
                                </div>
                                <span className='border-right'></span>
                                <div className='text-center mx-3'>
                                    <p className="FollwerThisFontsize">12</p>
                                    <h4 className='text-muted'>Post</h4>
                                </div>
                            </div>
                            <p className='RecentThisText'>Recent media</p>
                            <div className='p-2'>
                                <Row>
                                    <Col xl='8' className='m-0 p-1'>
                                        <div><img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid circularCorner' /></div>
                                    </Col>
                                    <Col xl='4' className='m-0 p-1'>
                                        <div><img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid circularCorner' /></div>
                                        <div className='diagonalthisgradient'>
                                            <img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid mt-2 circularCorner' />
                                            <span className='NumThisPeople'>+{numFormatter(90000)}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Button color='primary p-2'><Check size={18} /> Following</Button>
                                <Button color='primary p-2' outline className='ml-2'>
                                    <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                        <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="1.5" />
                                    </svg>
                                    Message</Button>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg='3' className='d-flex justify-content-center mt-2 p-1'>
                    <Card className='border w-100 shadow-sm bg-white roundedThisBorder'>
                        <div className='d-flex p-2 mt-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-2'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='p-2'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src={profile} alt='userImg' height='50px' width='50px' />
                                    <Circle strokeWidth={0} size={10} fill='#16C31E' className='CricleThisFill' />
                                    <div className='ml-2'>
                                        <h4>Robert Fox</h4>
                                        <div className='d-flex text-muted'>
                                            <MapPin size={14} className='align-self-center' />
                                            <p>&nbsp;Denmark</p>
                                        </div>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                        <MoreHorizontal size={19} color='black' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='d-flex justify-content-around mt-2'>
                                <div className='text-center'>
                                    <p className="FollwerThisFontsize">456</p>
                                    <h4 className='text-muted'>Followers</h4>
                                </div>
                                <span className='border-right'></span>
                                <div className='text-center mx-3'>
                                    <p className="FollwerThisFontsize">12</p>
                                    <h4 className='text-muted'>Post</h4>
                                </div>
                            </div>
                            <p className='RecentThisText'>Recent media</p>
                            <div className='p-2'>
                                <Row>
                                    <Col xl='8' className='m-0 p-1'>
                                        <div><img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid circularCorner' /></div>
                                    </Col>
                                    <Col xl='4' className='m-0 p-1'>
                                        <div><img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid circularCorner' /></div>
                                        <div className='diagonalthisgradient'>
                                            <img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid mt-2 circularCorner' />
                                            <span className='NumThisPeople'>+{numFormatter(90000)}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Button color='primary p-2'><Check size={18} /> Following</Button>
                                <Button color='primary p-2' outline className='ml-2'>
                                    <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                        <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="1.5" />
                                    </svg>
                                    Message</Button>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col lg='3' className='d-flex justify-content-center mt-2 p-1'>
                    <Card className='border w-100 shadow-sm bg-white roundedThisBorder'>
                        <div className='d-flex p-2 mt-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-2'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='p-2'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src={profile} alt='userImg' height='50px' width='50px' />
                                    <Circle strokeWidth={0} size={10} fill='#16C31E' className='CricleThisFill' />
                                    <div className='ml-2'>
                                        <h4>Robert Fox</h4>
                                        <div className='d-flex text-muted'>
                                            <MapPin size={14} className='align-self-center' />
                                            <p>&nbsp;Denmark</p>
                                        </div>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                        <MoreHorizontal size={19} color='black' />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='d-flex justify-content-around mt-2'>
                                <div className='text-center'>
                                    <p className="FollwerThisFontsize">456</p>
                                    <h4 className='text-muted'>Followers</h4>
                                </div>
                                <span className='border-right'></span>
                                <div className='text-center mx-3'>
                                    <p className="FollwerThisFontsize">12</p>
                                    <h4 className='text-muted'>Post</h4>
                                </div>
                            </div>
                            <p className='RecentThisText'>Recent media</p>
                            <div className='p-2'>
                                <Row>
                                    <Col xl='8' className='m-0 p-1'>
                                        <div><img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid circularCorner' /></div>
                                    </Col>
                                    <Col xl='4' className='m-0 p-1'>
                                        <div><img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid circularCorner' /></div>
                                        <div className='diagonalthisgradient'>
                                            <img src='https://thumbs.dreamstime.com/b/same-size-small-lemon-big-strawberry-neutral-background-colorful-fruits-different-118762568.jpg' className='img-fluid mt-2 circularCorner' />
                                            <span className='NumThisPeople'>+{numFormatter(90000)}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Button color='primary p-2'><Check size={18} /> Following</Button>
                                <Button color='primary p-2' outline className='ml-2'>
                                    <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                        <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="1.5" />
                                    </svg>
                                    Message</Button>
                            </div>
                        </div>
                    </Card>
                </Col> */}

            </Row>

            <Row className='mt-2 rem-mob-view'>
                <Col md='12' className='p-1' >
                    <div className='border roundedThisBorder media-images-blk shotz-album-block p-2' >
                        <p className='TrendingPeoplethistexttwo p-3' style={{ borderBottom: '1px solid rgba(129, 193, 75, 0.2)' }}>Top Peoples</p >
                        {peopleTable.map((num, index) => (
                            <div style={(peopleTable.length - 1) === index ? {} : { borderBottom: '1px solid rgba(129, 193, 75, 0.2)' }} className=''>

                                <Row className='d-flex align-items-center' >
                                    <Col md='7'>
                                        <div className='d-flex justify-content-start align-items-center p-2'>
                                            <div className='d-flex align-items-center ml-1'>
                                                <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center'>
                                                    <p className='PilesThistags text-center'>Top</p>
                                                    <p className='PilesThistags text-center pt-1'>#{num}</p>
                                                </div>
                                                <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                    <p className='PilesThistags text-center'>Trending</p>
                                                    <p className='PilesThistags text-center pt-1'>#{num}</p>
                                                </div>
                                            </div>
                                            <img src={img_1} alt='img  ' width={50} className='rounded-circle ml-4' />
                                            <div className='ml-3'>
                                                <NavLink to='/MyProfile' style={{ color: '#999999' }} >
                                                    {/* <h4 className='user-profile'>Darrell Steward</h4> */}
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }} ><h4 className='user-profile'>Darrell Steward</h4></NavLink>
                                                </NavLink>
                                                {/* <p className='PeopleFollowersThis mt-1'>44k followers</p> */}
                                                <div className='d-flex'>
                                                    {/* <p className='PeopleFollowersThis mt-1'>Evil tv enthusiast.</p> */}
                                                    <p className='PeopleFollowersThis mt-1' style={{ fontSize: "12px" }} >{'Professional martial arts Trainer.Thinker.Internet buff.Zombie Guru.Self proclaimed hero.Analytical thinker.Rare of the rarest'.slice(0, 120) + '...'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md='5' className='d-flex justify-content-around align-items-center p-0'>
                                        {/* <div className='d-flex pt-2 '> */}
                                        <div className='mr-1' style={{ marginLeft: '-45px' }}>
                                            <p className='PeopleFollowersThis mt-1 '>44k followers</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center justify-content-xl-end button-container" style={{  marginLeft: '-10px'}}>
                                            <Button style={{ backgroundColor: '#e6f3db', outline: 'none', border: 'none', color: '#81C14B', width: 'auto' }}>
                                                <Check size={18} /> Following
                                            </Button>
                                            <Button color='primary ColorThisButtonpadding  btnmsg btnmsgs' outline size='' className='px-2 ml-3' style={{ width: 'auto' }}>
                                                <svg width="15" height="15" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px" }}>
                                                    <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="3" />
                                                </svg>
                                                Message
                                            </Button>
                                        </div>
                                        <div>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className='p-0 m-0 moredown' color='flat' >
                                                    <MoreHorizontal size={19} color='#999999' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu dropdownLocation dropdown-for-user-specific mt-1'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share post</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View profile</NavLink></DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                        {/* </div> */}
                                    </Col>
                                </Row>
                            </div>
                        ))}

                    </div>

                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    {/* <p>Total Media Count : <span className='text-primary' >{listDataMedia.length}</span></p> */}
                                    <p>Showing 1 to 12 of 200 entries</p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex p-2 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
            {/* <Row className='mt-3 rem-mob-view'>
                <Col md='12' className='p-1' >
                    <div className='border roundedThisBorder'>
                        <p className='TrendingPeoplethistexttwo'>Trending Peoples</p >
                        <Row className='d-flex align-items-center p-4'>
                            <Col md='7'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div className='TrendingTagsBorders'>
                                        <p className='PilesThistags text-center pt-3'>#5</p>
                                    </div>
                                    <img src={img_1} alt='img  ' width={70} className='m-3 rounded-circle ml-2' />
                                    <div>
                                        <h2 className=''>Darrell Steward</h2>
                                        <p className='PeopleFollowersThis mt-1'>44k followers</p>
                                        <div className='d-flex'>
                                            <p className='PeopleFollowersThis mt-1'>Evil tv enthusiast.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md='5' className='d-flex justify-content-end p-0'>
                                <div className='d-flex pt-2'>
                                    <Button color='primary ColorThisButtonpadding' size='' className=''><Check size={18} className='mr-1' />Following</Button>
                                    <Button color='primary ColorThisButtonpadding' outline size='' className='pl-2'>
                                        <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                            <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="1.5" />
                                        </svg>
                                        Message
                                    </Button>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className='' color='flat' >
                                            <MoreHorizontal size={19} color='black' />
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row> */}

            {/*-----------------------------------------------------Modal components start----------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/*-----------------------------------------------------Modal components end----------------------------------------------------- */}

        </div >
    )
}

export default TopPeopleTabs
