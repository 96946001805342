import * as types from "../../Constants/Story/index";
import axios from "axios";

const getAllStories = (allStories) => ({
  type: types.GET_ALL_STORIES,
  payload: allStories,
});

export const loadAllFeedStories = () => {
  return async (dispatch) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      if (user) {
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/story/listStories`,
          {
            pageIndex: 0,
            pageSize: 10,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        dispatch(getAllStories(response.data.data.successResult));
      }
    } catch (error) {
      console.error("Error fetching Stories:", error);
    }
  };
};
