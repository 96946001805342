import React, { Fragment, useState } from 'react'
import Header from '../../../Header'
import LeftSidebar from '../../../LeftSidebar'
import RightSidebar from '../../../RightSidebar'
import PodcastHeader from '../../PodcastHeader'

import CommonSeeallpage from './CommonSeeallpage'
import PodcastFooter from '../../PodcastFooter'
import { Badge, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, NavLink, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Circle, Copy, Edit, Link, MoreVertical, Play, Send, Trash2 } from 'react-feather'


const SeeAll = () => {
    return (
        <Fragment>

            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="container-fluid section-t-space p-0 mt-0">

                        {/*  ----------------------  Common Header --------------------  */}

                        <PodcastHeader />
                        {/*  ----------------------Commom Card Component --------------------  */}
                        <CommonSeeallpage tittle={'TV & Film'} />
                        <PodcastFooter />
                    </div>
                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default SeeAll