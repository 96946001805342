import React, { Fragment, useState } from 'react'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import MpFilterPriceCompo from './MpFilterPriceCompo'
import MpFilterPriceCompo1 from './MpFilterPriceCompo1'

const MpFilterPriceFull = (props) => {
    
     const [text,settext]=useState("");
     const [text1,settext1]=useState("");
    return (
        <Fragment>
            {/* ========================= Component Whole Return =========================== */}
            <AccordionBody style={{ borderBottom: "1px solid #E5E5E5 " }}>
                <div className="MpUploadHeading mb-3" >
                    <h3 className='Mp_Color4D_Font6'>{props.data.title}</h3>
                    {/* <h5 className='MrktClearAllBtn' onClick={()=>{settext("");settext1("")}}>clear all</h5> */}
                </div>
                <MpFilterPriceCompo placeholder="Min Price" options={["USD", "IND"]} text={props.text} settext={props.settext}/>
                &nbsp;
                <MpFilterPriceCompo1 placeholder="Max Price" options={["USD", "IND"]} text1={props.text1} settext1={props.settext1} />
            </AccordionBody>
        </Fragment>
    )
}

export default MpFilterPriceFull
