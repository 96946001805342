import React from 'react'
import { Button, Card, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import CreatepostHeader from './CreatepostHeader'
import pollIcon from '../../../../NewComponents/IMG/pollIcon.svg'
import DateTimePicker from 'react-datetime-picker'
import CreatepostFooter from './CreatepostFooter'

const Poll = (props) => {
    return (
        <Card className='p-3 rounded'>
            <CreatepostHeader
                actionM={props.NavMedia}
                actionT={props.NavThrought}
                actionG={props.NavGoLive}
                actionE={props.NavEvent}
                actionP={props.NavPodcast}
                actionA={props.NavArticle}
                actionR={props.NavReccom}
                actionAl={props.NavAlert}
                actionPo={props.NavPoll}
                actionS={props.NavSell}
            />
            <div className='d-flex align-items-center my-2 py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' alt=''/>
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >Sufiya Eliza</h5>
            </div>
            <div className='d-flex'>
                <img src={pollIcon} className='mr-1' alt="poll" />
                <h4>Poll Question</h4>
            </div>
            <Form className='my-2'>
                <FormGroup>
                    <Input type='text' Placeholder='Write your poll question here...' />
                </FormGroup>
                <div className='pl-2'>
                    <FormGroup>
                        <Label tag='h4' className='my-1' for='helperText'>Option 1</Label>
                        <Input type='text' id='helperText' placeholder='Add 1st Poll option ' />
                    </FormGroup>
                    <FormGroup>
                        <Label tag='h4' className='my-1' for='helperText'>Option 2</Label>
                        <Input type='text' id='helperText' placeholder='Add 2st Poll option ' />
                    </FormGroup>
                </div>
            </Form>
            <div className='text-right'>
                <Button className='text-primary' color='flat'><h4>Add More Option</h4></Button>
            </div>
            <div>
                <h4>Poll Duration</h4>
                <p className='text-muted my-4   '>This poll will be automatically disabled after the selected time duration</p>
            </div>
            <Row>
                <Col>
                    <DateTimePicker
                        className="form-control"
                        placeholder="dd-mm-yyyy"
                    />
                </Col>
                <Col>
                    <DateTimePicker
                        className="form-control"
                        placeholder="dd-mm-yyyy"
                    /></Col>
            </Row>
            <CreatepostFooter 
            FirsticonS='d-none'
            Firsticon='d-none'
            Secondicon='d-none'
            Thirdicon='d-none'
            Fourthicon='d-none'
            Sixthicon='d-none'
            />

        </Card>
    )
}

export default Poll