import React, { Fragment, useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { Input } from 'reactstrap';
import { SortByRadio, SortByCheckbox } from '../MarketPlaceArray/MarketPlaceCategory';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Offcanvas } from 'react-bootstrap';

const MarketPlaceSortBy = (props) => {
    const [isRadioCheck, setIsRadioCheck] = useState(null);
    const SortByArray = ["Nearest", "Newest", "Highest", "Lowest"];

    // Function to handle radio button change
    const handleRadioChange = (index) => {
        setIsRadioCheck(index);
    };

    // Effect to handle side effects when isRadioCheck changes
    useEffect(() => {
        if (isRadioCheck !== null) {
            const selectedSortBy = SortByArray[isRadioCheck];
            // Send the index or the selectedSortBy value to the parent component or use it as needed
            if (props.setSortBy) {
                props.setSortBy(selectedSortBy);
            }
        }
    }, [isRadioCheck, props.setSortBy]);

    return (
        <Fragment>
            <Accordion defaultActiveKey="1" className='border mt-2' style={{ borderRadius: "5px" }} >
                <AccordionItem eventKey='1' style={{ background: "#FFFFFF" }}>
                    <AccordionHeader targetId="1" style={{ borderBottom: "1px solid #E5E5E5 " }}>
                        <div className='d-flex flex-column' >
                            <h3 className='' style={{ fontWeight: "500" }}>Sort By</h3>
                            <h6 className="">{props.filterPath}</h6>
                        </div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className='d-flex flex-column align-items-start justify-content-start gap-3 ' style={{ padding: "1rem 1.25rem" }}>
                            {SortByRadio.map((item, index) => (
                                <div key={index} className="MrktPlaceRadio" onClick={() => handleRadioChange(index)}>
                                    <Input type="radio" name="sort" checked={isRadioCheck === index} style={{ marginTop: "0" , cursor: "pointer"}} />&nbsp;&nbsp; <h5>{item} </h5>
                                </div>
                            ))}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </Fragment>
    );
};

export default MarketPlaceSortBy;
