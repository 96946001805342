import DateTimePicker from "react-datetime-picker"
import { AlertTriangle, Circle, MapPin, Minus, Plus } from "react-feather"
import { Button, CardBody, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap"
import LeftquotationIcon from '../../NewComponents/IMG/LeftquotationIcon.svg'
import RightquotationIcon from '../../NewComponents/IMG/RightquotationIcon.svg'
import pollIcon from '../../NewComponents/IMG/pollIcon.svg'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';


export const TextPost = (props) => {
    return (
        <CardBody>
            <div style={{ background: '#F6F6F6', borderRadius: '5px' }}>
                <InputGroup className='input-group-merge'>
                    <InputGroupAddon addonType='prepend m-2'>
                        <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' alt='userImg' />
                    </InputGroupAddon>
                    <Input type='textarea'
                        onClick={props.quick}
                        Placeholder='What’s going on? #Hashtag... @Mention.'
                        cols="15"
                        rows="5"
                        maxLength={'5000'}
                        style={{ color: props.color }}
                        className="border-0 inputFortext inputForback" />
                    <img
                        src="/assets/images/pen-solid-2.png"
                        width={15} height={15}
                        className="m-2"
                        alt="pen"
                    />
                </InputGroup>
            </div>
        </CardBody>
    )
}

export const ThroughtPost = (props) => {
    return (
        <CardBody>
            <div className='d-flex align-items-center py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' alt='profileImage' />
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >UserName</h5>
            </div>
            <div>
                <h3 style={{ color: '#FF007A' }} className='py-2'>#thoughtOfTheDay</h3>
                <div>
                    <img src={LeftquotationIcon} alt="icon" />
                    <div className='mx-4 text-center'>
                        <Input
                            type="textarea"
                            className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                            placeholder="Express your thoughts..."
                            cols="15"
                            rows="5"
                            onClick={props.quick}
                            style={{ background: props.backcolor, color: props.color }}
                        />
                        {/* <FormText className='text-muted text-right'>Max 3000 Characters</FormText> */}
                    </div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                </div>
            </div>
        </CardBody>
    )
}

export const RecommendationPost = (props) => {
    return (
        <CardBody>
            <div className='d-flex align-items-center my-2 py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' />
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >Sufiya Eliza</h5>
            </div>
            <div>
                <div className='mx-4 text-center'>
                    <Input
                        type="textarea"
                        className="d-flex text-primary positionForReccplaceholder align-items-center text-left w-100"
                        value='I am seeking a recommendation for '
                        onClick={props.quick}
                        cols="15"
                        rows="5"
                    />
                </div>
            </div>
        </CardBody>
    )
}

export const AlertPost = (props) => {
    /*  ----------------------  MUI Slider style management --------------------  */

    const PrettoSlider = styled(Slider)({
        color: '#81C14B',
        height: 4,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    });
    return (
        <CardBody>
            <div className='d-flex align-items-center my-2 py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' />
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >Sufiya Eliza</h5>
            </div>
            <div>
                <h3 style={{ color: '#F30000' }} className='py-2'>#creatalert</h3>
                <div>
                    <img src={LeftquotationIcon} alt="icon" />
                    <div className='mx-4 text-center'>
                        <Input
                            type="textarea"
                            className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                            placeholder="Define the threat..."
                            cols="15"
                            rows="5"
                            style={{ background: props.backcolor }}
                            onClick={props.quick}
                        />
                    </div>
                    <div className='text-right'>
                        <img src={RightquotationIcon} alt="icon" />
                    </div>
                    <FormText className='text-muted text-right'>Max 320 Characters</FormText>
                </div>
            </div>
            <Row>
                <Col>
                    <div className='d-flex align-items-center'>
                        <AlertTriangle className='mr-2' size={18} color='#FF822E' />
                        <h4>Alert Level</h4>
                    </div>
                    <div className='my-2'>
                        <Input id='eventcategory' type='select'>
                            <option data-content={<Circle size={4} fill='#FFD600' />}> Moderate</option>
                            <option> <Circle size={4} fill='#FF822E' /> Mild</option>
                            <option> <Circle size={4} fill='#FF1010' /> Severe</option>
                        </Input>
                    </div>
                </Col>
                <Col>
                    <div className='d-flex align-items-center'>
                        <MapPin className='mr-2' size={18} color='#16C31E' />
                        <h4>Post Alert within</h4>
                    </div>
                    <div className='my-2'>
                        <div className='d-flex align-items-center'>
                            <div className='col-sm-8'>
                                <PrettoSlider
                                    size="small"
                                    defaultValue={70}
                                    aria-label="Small"
                                    valueLabelDisplay="auto"
                                />
                            </div>
                            <div className='col-sm-4 rem-mob-view'>
                                <Button className='btn-icon p-0 m-0' color='primary'>
                                    <Plus />
                                </Button>
                                <Button className='btn-icon p-0 ml-1' color='primary'>
                                    <Minus />
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </CardBody>
    )
}

export const PollPost = (props) => {
    return (
        <CardBody>
            <div className='d-flex align-items-center my-2 py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' />
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >Sufiya Eliza</h5>
            </div>
            <div className='d-flex'>
                <img src={pollIcon} className='mr-1' alt="poll" />
                <h4>Poll Question</h4>
            </div>
            <Form className='my-2'>
                <FormGroup>
                    <Input onClick={props.quick} type='text' Placeholder='Write your poll question here...' />
                </FormGroup>
                <div className='pl-2'>
                    <FormGroup>
                        <Label tag='h4' className='my-1' for='helperText'>Option 1</Label>
                        <Input type='text' id='helperText' placeholder='Add 1st Poll option ' />
                    </FormGroup>
                    <FormGroup>
                        <Label tag='h4' className='my-1' for='helperText'>Option 2</Label>
                        <Input type='text' id='helperText' placeholder='Add 2st Poll option ' />
                    </FormGroup>
                </div>
            </Form>
            <div className='text-right'>
                <Button className='text-primary' color='flat'><h4>Add More Option</h4></Button>
            </div>
            <div>
                <h4>Poll Duration</h4>
                <p className='text-muted my-4'>This poll will be automatically disabled after the selected time duration</p>
            </div>
            <Row>
                <Col>
                    <DateTimePicker
                        className="form-control"
                        placeholder="dd-mm-yyyy"
                    />
                </Col>
                <Col>
                    <DateTimePicker
                        className="form-control"
                        placeholder="dd-mm-yyyy"
                    /></Col>
            </Row>
        </CardBody>
    )
}