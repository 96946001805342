// Tranding hashtag
export const GET_TRENDING_HASHTAG = "GET_TRENDING_HASHTAG";

export const GET_TAGGED_PEOPLE = 'GET_TAGGED_PEOPLE';

export const GET_FEELING_CATEGORY = 'GET_FEELING_CATEGORY';

export const GET_FEELING = 'GET_FEELING';

export const GET_USER_FOLLOWERS = "GET_USER_FOLLOWERS";

export const GET_USER_FOLLOWING = "GET_USER_FOLLOWING";

export const GET_USER_FOLLOWING_ERROR = "GET_USER_FOLLOWING_ERROR";

export const GET_USER_CONNECTION = "GET_USER_CONNECTION";

export const GET_ADD_USER_CONNECTION = "GET_ADD_USER_CONNECTION";

