import React, { Fragment, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import indiaflag from "./Icons/flags/hi_IN.png"
import { ChevronDown, User } from 'react-feather';
import { Button, Input, Row } from "reactstrap"
import CountryCodeModal from './Modal-Components/CountryCodeModal';
import LanguageModal from './Modal-Components/LanguageModal';
import LangModalNew from './Modal-Components/LangModalNew';


const SignUpPage = () => {


    const navigate = useNavigate()

    // Button disable Manage 
    const [userData, setUserData] = useState("")

    // country code Model controller 
    const [CodeModal, setCodeModal] = useState(false)

    const ToggleCountryCode = () => setCodeModal(!CodeModal)




    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }




    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")






    return (

        // ===================== Sign Up Page Return ==============================
        <Fragment>

            {/* -------------- Modal  Call --------------  */}
            <CountryCodeModal modal={CodeModal} toggle={ToggleCountryCode} />
            {/* <LanguageModal modal={languages} toggle={LanguageModalToggle} /> */}
            {/* =========================Full Login Page ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            {/* -------------------- Sign up Section ------------------------- */}
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row>
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec"><Link className="" to="/"><img src="/assets/images/logo.png" alt="logo" className="img-fluid" /></Link></div>
                            </div>
                            <div className="login-form">
                                <div>
                                    <div className="login-title">
                                        <h2>Welcome</h2>
                                    </div>
                                    <div className="login-discription">
                                        <h4>Please SignUp to your account.</h4>
                                    </div>
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    {/* <label>Enter your Mobile Number</label> */}
                                                    <div className={`input-block ${userData.length === 10 && 'border border-success rounded-3'}`}>
                                                        <div className="phone-with-code">
                                                            <div className="phone-code-dropdown">
                                                                <Link className="d-flex" onClick={ToggleCountryCode}>
                                                                    <img src={indiaflag} width='18' height='12' alt="flag" />
                                                                    +{91}
                                                                    <ChevronDown className='iw-14 phone-code-arrow' />
                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-14 phone-code-arrow "><polyline points="6 9 12 15 18 9"></polyline></svg> */}
                                                                </Link>
                                                            </div>
                                                            <Input type="number" className="form-control w-75 pr-5" maxLength={10} placeholder="Enter Mobile Number" name="mobile" value={userData} onChange={(e) => { setUserData(e.target.value) }} />
                                                            <User color='#B9B9C3' className='input-icon iw-20 ih-20' size={16} />
                                                        </div>
                                                    </div>
                                                    {/* <p className="error-input-msg text-center d-none" ref={errorRef}>{error}</p> */}
                                                </div>
                                                <p className="notimsg-blk">When you will click on continue,  you will receive a verification code on the mobile number that you have entered.</p>
                                                <div className="btn-section">
                                                    <Button color="primary" disabled={userData.length !== 10 ? true : false} onClick={() => { navigate("/signupotp") }} >CONTINUE</Button>
                                                </div>
                                                <div className="connect-with">
                                                    <div className="no-account-blk">
                                                        <p>Already have an account? <Link className="" to="/">Login</Link></p>
                                                    </div>
                                                    <div className="privacy-usernoti-blk "><a className='LoginLanguageBtn' rel='noreferrer' target='_blank' href="https://www.sociomee.com/privacy-policy/">Privacy Policy</a> < strong className='LoginLanguageBtn fw-bolder'>•</strong> <a rel='noreferrer' className='LoginLanguageBtn' target='_blank' href="https://www.sociomee.com/user-notice/">User Notice</a></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Row>

                    {/* ------------------------ Language Area  ----------------------------------- */}
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                {["English", "Hindi", "Deutsch"].map((item, index) => (
                                    <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                ))}
                            </span>
                            <button onClick={LanguageModalToggle} >
                                ...More
                            </button>
                        </div>
                    </div >
                    {/* <LoginLanguage setUser={user}></LoginLanguage> */}
                </div>
            </section>
        </Fragment>
    )
}

export default SignUpPage
