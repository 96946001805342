import React from 'react'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Circle, Filter, Sliders } from 'react-feather';
import { Accordion, Checkbox } from '@mui/material';
import { ShareData } from '../EventsArray'
import { Fragment } from 'react';
import FlwrImg from '../../Img/FlwrImg.png'
import { useDispatch, useSelector } from 'react-redux';
import { getUserFollowerAction, postReShareAction, getUserConnectionAction, inviteFriendsToEventAction } from '../../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useEffect } from 'react';
import { useState } from 'react';
import { SweetErrorAlert } from '../../../group-components/AllGroupsSections/SweetErrorAlert';
import { sweetAlertConfirmation } from '../../../group-components/AllGroupsSections/SweetAlert';

import axios from 'axios';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

const ShareEventModal = (props) => {

    // let dispatch = useDispatch();
    const [pageIndex, setPageIndex] = useState(0);

    //   (pageIndex,allPosts?.length,apiRoute, sortBy)
    const getUserConnection = useSelector((state) => { return state.getUserConnectionData });
    let { loading: loadingUserConnection, error: errorUserConnection, result: resultUserConnection } = getUserConnection

    const getFollowerConnections = useSelector((state) => { return state.getUserFollowerData });
    const { loading: loadingConnections, error: errorConnections, result: resultFollwers } = getFollowerConnections

    const getInvitationResult = useSelector((state) => { return state.inviteFriendsToEventData });
    const { error: errorinvitation, result: invitationResult } = getInvitationResult
    const [count,setCount]=useState(0);

    // console.log("props======================>",props)




    const [friendsId, setFriendsId] = useState([])
    const [subHeading, setSubHeading] = useState("SocioMate");
    const [apiRoute, setApiRoute] = useState("");
    const [sortBy, setSortBy] = useState("");

    const getSocioMate = (menu) => {
        setApiRoute("/user/getUserFollowers")
        setSortBy("userName")
        setSubHeading(menu)
        dispatch(getUserFollowerAction(apiRoute, sortBy))

    }

    useEffect(() => {
        dispatch(getUserFollowerAction(pageIndex, resultFollwers?.length, "/user/getUserFollowers", "userName"));
        dispatch(getUserConnectionAction());
    }, [])

    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const filteredData = resultFollwers?.filter(item =>
        item?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const alertMessage = () => {
        SweetErrorAlert('Api Not Available')
    }

    const followingMenu = (menu) => {
        setApiRoute("/post/getPeopleList")
        setSortBy("Connections")
        setSubHeading(menu)
        dispatch(getUserFollowerAction(pageIndex, resultFollwers?.length, apiRoute, sortBy))

    }

    const [apiResult, setApiResult] = useState(null);

    useEffect(() => {
        if(count==1){
        const fetchData = async () => {
            try {
                let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowings/`,
                        { countryIds: [] },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setApiResult(response.data);
                    // Handle the response data as needed
                } else {
                    console.log('User not found in local storage');
                }
            } catch (error) {
                console.error('API Error:', error);
                // Handle the error
            }
        };

        fetchData();
      }else if(count==0){
        const fetchData = async () => {
            try {
                let user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                        { countryIds: [] },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setApiResult(response.data);
                    // Handle the response data as needed
                } else {
                    console.log('User not found in local storage');
                }
            } catch (error) {
                console.error('API Error:', error);
                // Handle the error
            }
        };

        fetchData();
      }
    }, [count]);


    const [shareOpen, setShareOpen] = useState(false)

    const shareAll = (action) => {
        const frndId = resultFollwers?.map(obj => obj.id)
        //   setFriendsId(frndId)
        if (action === 'shareAll') {
            dispatch(postReShareAction(props.postId, props.postCaption, frndId, props.allowComment, props.locationLAT, props.locationLONG))
            sweetAlertConfirmation(`Your post shared to everyone`)
            props.isOpen((prevState) => !prevState)
        } else if (action === 'inviteAll') {
            dispatch(inviteFriendsToEventAction(friendsId, props.postId))
            sweetAlertConfirmation(`Inivitation send successfully`)
            props.isOpen((prevState) => !prevState)
        }

        // toggleShareOpen((prevState) => !prevState)
    }

    const sharePost = () => {
        const frndId = resultFollwers?.map(obj => obj.id)
        setFriendsId(frndId)
        dispatch(postReShareAction(props.postId, props.postCaption, frndId, props.allowComment, props.locationLAT, props.locationLONG))

        // setShareOpen((prevState) => !prevState)
    }


    const inviteFriends = (e) => {
        let updatedList = [...friendsId]
        if (e.target.checked) {
            updatedList = [...friendsId, e.target.value]
        } else {
            updatedList.splice(friendsId.indexOf(e.target.value), 1)
        }
        setFriendsId(updatedList)
        //   console.log(updatedList)
    };

    const [check, setCheck] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const handleCheck = (id, item) => {
        // Check if the item is already selected
        const isSelected = check.includes(id);

        if (isSelected) {
            // If selected, remove the item from the check and selectedList
            setCheck(prevCheck => prevCheck.filter(x => x !== id));
            setSelectedList(prevSelectedList => prevSelectedList.filter(selectedItem => selectedItem.id !== id));
        } else {
            // If not selected, add the item to check and selectedList
            setCheck(prevCheck => [...prevCheck, id]);
            setSelectedList(prevSelectedList => [...prevSelectedList, item]);
        }
        console.log("selected people list in share ", selectedList)
    };
    const Sharedto = [
        {
            name: "Your Wall Feed",
        },
        {
            name: "Your Story",
        },
    ];
    const [postName, setPostNmae] = useState("")
    const Shared = () => {
        sweetAlertConfirmation("Shared successfully");
        // props.isOpen=false;
    }
    const Invite=()=>{
        sweetAlertConfirmation("Invited successfully");
        // props.isOpen=false;
    }
    const dispatch = useDispatch();
    const { getUserSociomate } = useSelector((state) => state.userSociomateData);
    const { allConnections } = useSelector((state) => state.getAllConnectionsData);
    const [searchQuery, setSearchQuery] = useState("")
    const [allPeople, setAllPeople] = useState(allConnections);

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    async function handelShare(selectedList) {

        console.log("here is selected list and props  ", selectedList, props)
        let user = JSON.parse(localStorage.getItem("sociomeeUser"));
        // props.toggleForward;
        const body = {
            userId: user.id,
            otherUserId: selectedList,
            chatHeadType: "",
            messageData: {
                messageType: props.postType?.postType,
                messageText: props.postType?.caption,
                messageJson: "",
                isForwarded: "",
                replyMessageId: ""
            }

        }
        try {
            let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
            await axios.post(
                `${process.env.REACT_APP_IPURL}/messenger/sendUserMessage`,
                body,
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
        } catch (error) {

        }
    }
    return (
        <div>
            {
                props.isOpen !== false &&
                <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                    <div className='w-100 border-bottom'>
                    {props.apiRedirect !== 0 ? (
                        <div className="d-flex align-items-center justify-content-between p-3 border-bottom">           
                                <h2>Share to </h2>
                                <Button color="primary" onClick={Shared}>
                                  Share
                                </Button>         
                        </div>
                          ) : (
                            <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                                                          
                                <h2>Invite to </h2>
                                <Button color="primary" onClick={Invite}>
                                   Invite
                                </Button>               
                        </div>
                        )}
                    </div>
                    <ModalBody>
                        <div>
                        <Row className="mt-2">
                            <div className="pb-2">
                                <Accordion defaultActiveKey="0">
                                    <AccordionItem eventKey="1">
                                        {/* <AccordionHeader className="AccordionForSrtyle">
                                            <h4>Additional Features</h4>
                                        </AccordionHeader> */}
                                        <AccordionBody className="p-0">
                                            <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey="1">
                                                    <AccordionHeader>Feed</AccordionHeader>
                                                    <AccordionBody>
                                                        {Sharedto.map((data) => (
                                                            <div className="d-flex justify-content-between align-items-center my-2 Report_Section border-bottom">
                                                                <h4>{data.name}</h4>
                                                                <div>
                                                                    <Checkbox />{" "}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey="2">
                                                    <AccordionHeader>Your Business Pages</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className="ForWard_Img_categories d-flex align-items-center">
                                                                <img
                                                                    src={FlwrImg}
                                                                    alt="img"
                                                                    className="img-fluid rounded-circle" style={{ height: '60px', width: '60px' }}
                                                                />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page name</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey="3">
                                                    <AccordionHeader>Your Group</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className="ForWard_Img_categories d-flex align-items-center">
                                                                <img
                                                                    src={FlwrImg}
                                                                    alt="img"
                                                                    className="img-fluid rounded-circle" style={{ height: '60px', width: '60px' }}
                                                                />
                                                                <div className="ml-1">
                                                                    <h4>Your Group name</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            {/* <Col md="4" className="d-flex justify-content-end pt-1">
                                <UncontrolledButtonDropdown color='secondary' outline>
                                    <DropdownToggle outline className="border border-muted ml-3 ">
                                        <Sliders className='Slider_Filter' size={12} />&nbsp;&nbsp;Filter
                                    </DropdownToggle>
                                    <DropdownMenu className="DropDown_Group_item">
                                        <DropdownItem
                                            onClick={() => { getSocioMate('SocioMate') }}
                                        >SocioMate
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => { followingMenu('Following') }}
                                        >
                                            Followings
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={alertMessage}>
                                            Colleagues
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={alertMessage}>
                                            Family
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={alertMessage}>
                                            Relatives
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={alertMessage}>
                                            Work
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col> */}
                            {/* <Col md="6" className="SubHeading_Invite_Section">


                                <h4>{subHeading}  (
                                    {
                                        searchTerm && searchTerm ? filteredData?.length : resultFollwers?.length
                                    }

                                    )<small></small></h4>

                            </Col>
                            <Col md="6" className="d-flex justify-content-end SubHeading_Invite_Section">

                                {props.apiRedirect
                                    ?
                                    <>
                                        {friendsId.length < 1 ?
                                            <p style={{ cursor: 'pointer' }}
                                                onClick={() => { shareAll('shareAll') }}
                                            >Share all</p>
                                            : ''
                                        }
                                    </>
                                    :
                                    <>
                                        {friendsId.length < 1 ?
                                            <p style={{ cursor: 'pointer' }}
                                                onClick={() => { shareAll('inviteAll') }}
                                            >Invite All</p>
                                            : ''
                                        }
                                    </>

                                }



                            </Col> */}
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="search-svg"
                                    >
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="search"
                                        placeholder="Search...for people"
                                        data-bs-toggle="modal"
                                        data-bs-target="#peopleSearch"
                                        value={searchQuery}
                                        onChange={e => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="mt-3 Categories_subHeading_section ">
                                <h4>Connection</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="primary" outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu style={{ position: 'absolute', willChange: 'transform', top: '0px', left: '-49px', transform: 'translate3d(-36px, 42px, 0px)' }}>
                                        <DropdownItem tag="a" onClick={(e)=>(setCount(1))}> Recent </DropdownItem>
                                        <DropdownItem tag="a" onClick={(e)=>(setCount(0))}> Followers</DropdownItem>
                                        <DropdownItem tag="a" onClick={(e)=>(setCount(1))}> Followings</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </Row>
                        </div>
                        {/* <Row className="mt-3">
                            {

                                searchTerm && searchTerm ?

                                    filteredData?.map((data, index) => (
                                        <Fragment>
                                            <div className="group-detail-page-blk">
                                                <div className="Follower-memberslist-block">
                                                    <div className="group-member-img-cont-blk">
                                                        {loadingConnections ?
                                                            <div class="spinner-border m-5" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                            : ''}
                                                        <img style={{ width: '45px', height: '45px' }} src={data?.profileImageThumb ? data?.profileImageThumb : data.profilePhoto} alt="img" className='img-fluid' />
                                                        <div className="member-cont-blk">
                                                            <h4 className='text-capitalize'>
                                                                {loadingConnections ?
                                                                    <div class="spinner-border m-5" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    : ''}
                                                                {data?.fullName?.substring(0, 20)}
                                                                {data?.fullName?.length >= 16 && ' ...'}
                                                            </h4>
                                                            <p style={{ fontSize: '12px' }} className='text-muted'>{data?.followingDate ? data?.followingDate?.slice(0, 10) : ''}</p>
                                                            <div style={{ fontSize: '12px' }} className=" Post_Count">
                                                                {data?.status ? data?.status : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Block-btns-blk'>
                                                        <Checkbox
                                                            value={data?.id}
                                                            defaultChecked={!!friendsId[data?.id]}
                                                            onChange={(e) => {
                                                                inviteFriends(e);
                                                            }}
                                                            type="checkbox"
                                                            className='customcheckboxForFilter'
                                                            id={data.id} style={{ cursor: "pointer" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ))

                                    :

                                    resultFollwers?.map((data, index) => (
                                        <Fragment>
                                            <div className="group-detail-page-blk">
                                                <div className="Follower-memberslist-block">
                                                    <div className="group-member-img-cont-blk">
                                                        <img style={{ width: '45px', height: '45px' }} src={data?.profileImageThumb ? data?.profileImageThumb : data.profilePhoto} alt="img" className='img-fluid' />
                                                        <div className="member-cont-blk">
                                                            <h4 className='text-capitalize'>
                                                                {loadingConnections ?
                                                                    <div class="spinner-border m-5" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    : ''}
                                                                {data?.fullName?.substring(0, 20)}
                                                                {data?.fullName?.length >= 16 && ' ...'}
                                                            </h4>
                                                            <p style={{ fontSize: '12px' }} className='text-muted'>{data?.followingDate ? data?.followingDate?.slice(0, 10) : ''}</p>
                                                            <div style={{ fontSize: '12px' }} className=" Post_Count">
                                                                {data?.status ? data?.status : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Block-btns-blk'>
                                                        <Checkbox
                                                            value={data?.id}
                                                            defaultChecked={!!friendsId[data?.id]}
                                                            onChange={(e) => {
                                                                inviteFriends(e);
                                                            }}
                                                            type="checkbox"
                                                            className='customcheckboxForFilter'
                                                            id={data.id} style={{ cursor: "pointer" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ))
                            }
                        </Row> */}
                        <div>
                        <Row className="mt-3">
                        {
                            apiResult.data.successResult.rows
                                .filter(data => data.fullName.toLowerCase().includes(searchQuery.toLowerCase())) // Filter based on fullName containing searchQuery
                                .map((data, index) => (
                                    <Fragment key={index}>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                                <div className="group-member-img-cont-blk">
                                                    <img src={data.profileImage} alt="img" className='img-fluid' />
                                                    <div className="member-cont-blk">
                                                        <h4>{data.fullName}</h4>
                                                        <p className='text-muted'>{data?.totalFollowersCount ? data?.totalFollowersCount : 0} Followers</p>
                                                        <div className="Post_Count">
                                                            {data.followBackStatus}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                        }

                        </Row>
                        </div>
                        {/* <div
                            className="mt-3"
                            style={{
                                height: "500px",
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            {allPeople?.map((data) => (
                                <div className="group-detail-page-blk">
                                    <div className="MyEvents-memberslist-block" onClick={() => handleCheck(data.id, data)}>
                                        <div className="group-member-img-cont-blk">
                                            <div className="ForWard_Img_categories">
                                                <img
                                                    src={data.profilePhoto}
                                                    alt="img"
                                                    className="img-fluid rounded-circle"
                                                />
                                                <Circle
                                                    fill="#81C14B"
                                                    strokeWidth={5}
                                                    color="#FFFFFF"
                                                    size={15}
                                                    className="right_imge"
                                                />
                                            </div>
                                            <div className="member-cont-blk">
                                                <h4>{data.fullName}</h4>
                                                <p className="">{data.userName}</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                        <Checkbox checked={check.includes(data.id)} {...label} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    </ModalBody>

                </Modal>

            }
        </div>
    )
}

export default ShareEventModal
