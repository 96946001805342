import React, { useState, useEffect, Fragment } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from 'reactstrap';
import { ChevronDown } from 'react-feather';

const MpFormTabs = (props) => {
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [dropdownOpen, setDropdownOpen] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    // Initialize the selectedIndexes array with default values
    const initialIndexes = props.attributesByType?.tab?.map(() => 0) || [];
    // setSelectedIndexes(initialIndexes);  //  commented this due to error
    // Initialize the dropdownOpen array with default values
    const initialDropdowns = props.attributesByType?.tab?.map(() => false) || [];
    setDropdownOpen(initialDropdowns);
  }, [props.attributesByType]);

  
  const handleClick = (tabIndex, itemIndex) => {
    // Avoid updating the state if the index is the same
    if (selectedIndexes[tabIndex] !== itemIndex) {
      const updatedIndexes = [...selectedIndexes];
      updatedIndexes[tabIndex] = itemIndex;
      setSelectedIndexes(updatedIndexes);
      notifyParent(tabIndex, itemIndex);
    }
  };

  const handleDropdownSelect = (tabIndex, itemIndex) => {
    // Avoid updating the state if the index is the same
    if (selectedIndexes[tabIndex] !== itemIndex) {
      const updatedIndexes = [...selectedIndexes];
      updatedIndexes[tabIndex] = itemIndex;
      setSelectedIndexes(updatedIndexes);
      toggleDropdown(tabIndex);
      notifyParent(tabIndex, itemIndex);
    }
  };

  const toggleDropdown = (index) => {
    const updatedDropdowns = [...dropdownOpen];
    updatedDropdowns[index] = !updatedDropdowns[index];
    setDropdownOpen(updatedDropdowns);
  };

  const notifyParent = (tabIndex, itemIndex) => {
    if (props.onDataPass) {
      const selectedTab = props.attributesByType?.tab[tabIndex];
      const selectedValue = selectedTab?.mpAttributeValues[itemIndex] || '';
      const mpAttributeId = selectedTab?.id || '';

      props.onDataPass([{ mpAttributeId: mpAttributeId, value: selectedValue }]);
    }
  };

  return (
    <Fragment>
      {props.attributesByType?.tab?.map((tab, tabIndex) => (
        <div key={tabIndex}>
          <div className='MpformElementHeading'>
            <h5>{tab.label}</h5>
          </div>
          {isMobile ? (
            <UncontrolledButtonDropdown
              className='w-100'
              isOpen={dropdownOpen[tabIndex]}
              toggle={() => toggleDropdown(tabIndex)}
            >
              <DropdownToggle
                color='secondary text-left'
                outline
                className='d-flex border-clr justify-content-between align-items-center custom-toggle secondary Fs14_Fw_400'
                style={{ fontSize: '14px', padding: '8px 15px' }}
              >
                {tab?.mpAttributeValues[selectedIndexes[tabIndex]] || ''}
                <ChevronDown size={18} />
              </DropdownToggle>
              <DropdownMenu className='w-100 categories-scroll'>
                {tab?.mpAttributeValues.map((item, itemIndex) => (
                  <DropdownItem
                    key={itemIndex}
                    onClick={() => handleDropdownSelect(tabIndex, itemIndex)}
                    className={`Fs14_Fw_400 ${selectedIndexes[tabIndex] === itemIndex ? 'Mp_TabsActive' : ''}`}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          ) : (
            <div className="MpFormTabs mt-2">
              {tab?.mpAttributeValues.map((item, itemIndex) => (
                <span
                  key={itemIndex}
                   className={`Mp_Form_Tabs_ ${selectedIndexes[tabIndex] === itemIndex ? 'Mp_TabsActive' : ''}`}
                  onClick={() => handleClick(tabIndex, itemIndex)}
                >
                  {item}
                </span>
              ))}
            </div>
          )}
        </div>
      ))}
    </Fragment>
  );
};

export default MpFormTabs;
