import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { deletePostAction} from '../../../../Services/Actions/UserProfile/getAllUserProfilePageAction'
import { sweetAlertConfirmation } from '../../../group-components/AllGroupsSections/SweetAlert'

const DeletedEventModal = ({isOpen, updateOpen, postId, onSuccess}) => {
let dispatch = useDispatch();

const deleteEvent = () => {
  dispatch(deletePostAction([postId]));  
  onSuccess('success')
  updateOpen()
};

  return (
    <div>
      {
        isOpen !== false &&
        <Modal isOpen={isOpen} toggle={updateOpen} className='modal-dialog-centered'>
          <ModalHeader className='d-flex justify-content-center Delete_Media_modal_section'>
            <h4>Delete Event</h4>
          </ModalHeader>
          <ModalBody>

            <div className='d-flex justify-content-center  modalHeading_Section'>
              <p>Please mention reason below and click ok to delete ?</p>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <input style={{width:'80%'}}
                type="text" className="form-control"
                name="search" placeholder="Please mention the reason.."
                autoFocus
               />
            </div>
            <div className='d-flex justify-content-center  mt-4'>
              <Button onClick={updateOpen} outline >Cancel</Button>
              <Button onClick={deleteEvent} color="primary" className="ml-3 ">OK</Button>
            </div>
          </ModalBody>
        </Modal>
      }
    </div>
  )
}

export default DeletedEventModal
