import { Checkbox, FormControlLabel } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import AccordionBody from 'react-bootstrap/esm/AccordionBody'; 

const MpCategory = (props) => {
    const [isActive, setIsActive] = useState(null);
    const [activeStates, setActiveStates] = useState({});

    const handleClearAll = () => {
        setActiveStates({});
    };

    const handleCheckboxChange = (item) => {
        props.setActiveStates((prevStates) => ({
            ...prevStates,
            [item]: !prevStates[item],
        }));
       
        //here we are assinging the subcategory to the array
        props.setCheckedCheckboxes((prevStates) => {
            if (prevStates.includes(item)) {
                return prevStates.filter((i) => i !== item);
            } else {
                return [...prevStates, item];
            }
        });
        
    };

    const isCheckboxActive = (item) => {
        return props.activeStates[item] || false;
    };



    // =======================finding perticular category by name  from all cateory listing===================>
    const [categoryObject, setCategoryObject] = useState(null);
    useEffect(() => {
        const parts = props?.filterPath?.split("/");
        const category = parts[0];
        const catName = parts[1];
        const matchedCategories = props.categoryData?.filter(cat => cat.categoryName === catName);
        setCategoryObject(matchedCategories.length > 0 ? matchedCategories[0] : null);
    }, [props.filterPath, props.categoryData]);

    if (!categoryObject) {
        return null;
    }

    return (
        <Fragment>
            <AccordionBody style={{ borderBottom: "1px solid #E5E5E5" }}>
                <div>
                    <div className="MpUploadHeading">
                        <h3 style={{ fontWeight: "600" }}>{categoryObject.categoryName}</h3>
                    </div>
                    <div className='d-flex flex-column align-items-start justify-content-start' style={{ padding: "1rem .25rem" }}>
                        {categoryObject.subcategories.length >0 && categoryObject.subcategories.map((subcategory) => (
                            <div key={subcategory.id} className="MrktPlaceCheckbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                                color: "#cbcbcb",
                                                '&.Mui-checked': {
                                                    color: "#81c14b",
                                                },
                                            }}
                                            size="small"
                                            checked={isCheckboxActive(subcategory.id)}
                                            onChange={() => handleCheckboxChange(subcategory.id)}
                                        />
                                    }
                                    label={subcategory.name}
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#767676" } }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </AccordionBody>
        </Fragment>
    );
};

export default MpCategory;
