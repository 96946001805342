import React from 'react'
import UserPro from '../../../../NewComponents/IMG/Adspreview.svg'
import UserAdPro from '../../../../NewComponents/IMG/Adspreview.svg'
import { Button, Card, CardBody, CardHeader, CardImg, CardImgOverlay, Col, Modal, ModalBody, ModalHeader, Progress, Row } from 'reactstrap'
import { ChevronsRight, Maximize2, Minimize2, MoreVertical, Send, X } from 'react-feather'
import { useState } from 'react'
import Story from '../../../../NewComponents/IMG/Story.png'
import Market from '../../../../NewComponents/IMG/Market.png'
import Market2 from '../../../../NewComponents/IMG/Market2.png'
import group2 from '../../../../NewComponents/IMG/group2.png'
import Res from '../../../../NewComponents/IMG/Res.png'
import Rec1 from '../../../../NewComponents/IMG/Rec1.png'
import group5 from '../../../../NewComponents/IMG/group5.png'
import Shotzs1 from '../../../../NewComponents/IMG/Shotzs1.png'
import Group6 from '../../../../NewComponents/IMG/Group6.png'
import Groups from '../../../../NewComponents/IMG/Groups.png'
import as from '../../../../NewComponents/IMG/as.png'
const SingleImagePreview = () => {
    const [centeredModal, setCenteredModal] = useState(false)
    const [accord, setAccord] = useState(1);

    const Previewmodal = () => {
        return (
            <>
                <Modal isOpen={centeredModal} toggle={() => setCenteredModal(!centeredModal)} className='modal-xl modal-dialog-centered'>
                    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>Advanced preview</ModalHeader>
                    <ModalBody>
                        {/* <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                           
                                            <Col xs='4' xl='4' onClick={() => { setAccord(1) }}>
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={10} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                            <div className="p-1">
                                                                <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                                                                    <b>
                                                                        An adv is the promotion of a product, brand
                                                                    </b>
                                                                </h6>
                                                                <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                    <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                                                                    <Send size={10} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(2) }} >
                                                <h3 className='p-heading ml-1'>Podcast</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1 ">
                                                    <Card style={{ borderRadius: '5px' }}>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                                                    <div className="ml-1">
                                                                        <h6 className="sizingForTextheading">florida roxin</h6>
                                                                        <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={10} className='m-0' />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center sizingForText">
                                                                <h6 className="sizingForTextsmall">Related products</h6>
                                                                <p className="text-info sizingForTextvsmall">View all</p>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <div className="d-flex">
                                                                <div>
                                                                    <img src={UserPro} className='img-fluid w-100 p-1' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.4rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.4rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <img src={UserPro} className='img-fluid w-100 p-1' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.4rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.4rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(3) }}>
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1"><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading">Visit all Groups <ChevronsRight size={10} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert'>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(4) }}>
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1"><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading">Visit all BizPages <ChevronsRight size={10} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(5) }}>
                                                <h3 className='p-heading ml-1'>MarketPlace</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 border">
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 border">
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert'>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(6) }}>
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={10} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1"><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 border">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 border">
                                                                    <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div>
                                                                    <img src={UserPro} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '0.3rem' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                                            <p style={{ fontSize: '0.2rem' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={8} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(7) }}>
                                                <h3 className='p-heading ml-1'>Stories</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={UserPro} />
                                                        <CardImgOverlay>
                                                            <div>
                                                                <Progress className='customeForprogressbar' value={25} />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' />
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall">Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col xs='4' xl='4' onClick={() => { setAccord(8) }}>
                                                <h3 className='p-heading ml-1'>Shotz</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={UserPro} />
                                                        <CardImgOverlay>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' alt=''/>
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall">Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row className='rowForrevert'>
                            <Col>
                                <div className="preview-right p-0">
                                    <div className="col-lg-12 p-0">
                                        <Row className='rowForrevert'>
                                            {/* Feed Card */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(1) }}>
                                                <h3 className='p-heading ml-1'>Feeds</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border py-4 m-1 rounded">
                                                    <Card>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={as} width={40} height={40} className='rounded-circle' alt='' />
                                                                    <div className="ml-1">
                                                                        <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            {/* <img src={UserPro} className='img-fluid' alt="cardImage" /> */}
                                                            <img src="/assets/images/image (2).png" alt='' style={{ width: '100%' }} />
                                                            <div className="p-1">
                                                                <h6 className="text-dark ml-2" style={{ fontSize: '12px' }}>
                                                                    <b>
                                                                        An adv is the promotion of a product, brand
                                                                    </b>
                                                                </h6>
                                                                <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                    <p className="sizingForTextsmall" style={{ fontSize: '12px' }}><u>Why this Ad?</u></p>
                                                                    <Send size={15} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*====================== Podcast Card================= */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(2) }} >
                                                <h3 className='p-heading ml-1'>Podcast</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1 ">
                                                    <Card style={{ borderRadius: '5px' }}>
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex m-1">
                                                                    <img src={as} width={40} height={40} className='rounded-circle' alt='' />

                                                                    <div className="ml-1">
                                                                        <h6 className="sizingForTextheading" style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                        <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="ml-1">
                                                                    <MoreVertical size={15} className='m-0' />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center sizingForText p-2">
                                                                <h6 className="sizingForTextsmall" style={{ fontSize: '12px' }}>Related products</h6>
                                                                <p className="text-info sizingForTextvsmall" style={{ fontSize: '12px', marginTop: '0.2rem' }}>View all</p>
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-0 border rounded'>
                                                            <div className="d-flex p-2">
                                                                <div className='shadow'>
                                                                    <img src={Group6} className='img-fluid w-100 p-1' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='shadow'>
                                                                    <img src={Groups} className='img-fluid w-100 p-1 ' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*================== Group Card=================*/}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(3) }}>
                                                <h3 className='p-heading ml-1'>Groups</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded  py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={as} width={40} height={40} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall m-1" style={{ fontSize: '12px' }}><b>Suggested Groups</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    {/* <div className="d-flex justify-content-end">
                                                                        <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div>
                                                                    </div> */}
                                                                    <img src={group2} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={group2} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all Groups <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='rowForrevert mt-3'>
                                            {/*==================MarketPlace Card===================== */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(5) }}>
                                                <h3 className='p-heading ml-1'>MarketPlace</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={as} width={40} height={40} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1  shadow">
                                                                    {/* <img src={UserPro} className='img-fluid' alt="cardImage" /> */}
                                                                    <img src={Market} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1  shadow">
                                                                    <img src={Market2} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=================Event Card================= */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(6) }}>
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={as} width={40} height={40} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={Res} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={Rec1} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning" style={{ fontSize: '12px' }}>Sponsored</p>
                                                                <h6 className="sizingForTextvsmall" style={{ fontSize: '12px' }}>Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=================Event Card end================= */}
                                            {/*=====================Biz Page Card=============== */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(4) }}>
                                                <h3 className='p-heading ml-1'>BizPages</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={as} width={40} height={40} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>Suggested BizPage</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={group5} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                        {/* <div>
                                                                            <X size={10} className='m-0' />
                                                                        </div> */}
                                                                    </div>
                                                                    <img src={group5} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                        <div>
                                                            <div className="text-center pb-1">
                                                                <h4 className="sizingForTextheading" style={{ fontSize: '12px' }}>Visit all BizPages <ChevronsRight size={15} /> </h4>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*=====================Biz Page Card end=============== */}
                                        </Row>
                                        <Row className='rowForrevert mt-4'>
                                            {/* <Col xs='4' xl='4' onClick={() => { setAccord(6) }}>
                                                <h3 className='p-heading ml-1'>Events</h3>
                                                <div style={{ backgroundColor: '#C4C4C4' }} className="border rounded py-4 m-1">
                                                    <Card>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex m-1">
                                                                <img src={UserAdPro} width={35} height={35} className='rounded-circle' alt='' />
                                                                <div className="ml-1">
                                                                    <h6 style={{ fontSize: '12px' }}>florida roxin</h6>
                                                                    <h6 style={{ fontSize: '10px', marginTop: '0.2rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="ml-1">
                                                                <MoreVertical size={15} className='m-0' />
                                                            </div>
                                                        </div>
                                                        <h6 className="sizingForTextsmall ml-1" style={{ fontSize: '12px' }}><b>My Hosting Events</b></h6>
                                                        <CardBody className='p-1 rounded'>
                                                            <div className="d-flex">
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                    </div>
                                                                    <img src={Res} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 shadow">
                                                                    <div className="d-flex justify-content-end">
                                                                    </div>
                                                                    <img src={Rec1} className='img-fluid' alt="cardImage" />
                                                                    <div className="p-1">
                                                                        <h6 className="text-dark" style={{ fontSize: '12px' }}>
                                                                            <b>
                                                                                An adv is the promotion of a product, brand
                                                                            </b>
                                                                        </h6>
                                                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3 p-2">
                                                                            <p style={{ fontSize: '12px' }} ><u>Why this Ad?</u></p>
                                                                            <Send size={15} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="sizingForTextvsmall text-warning" style={{ fontSize: '12px' }}>Sponsored</p>
                                                                <h6 className="sizingForTextvsmall" style={{ fontSize: '12px' }}>Canner Film Festival</h6>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Col> */}
                                            {/*======================Stories Card================== */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(7) }}>
                                                <h3 className='p-heading ml-1'>Shotz</h3>
                                                <div className=" m-1">
                                                    {/* className="border rounded py-4 m-1" */}
                                                    <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={Shotzs1} />
                                                        {/* <CardImgOverlay>
                                                            <div>
                                                                <Progress className='customeForprogressbar' value={25} />
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' alt='' />
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall">Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay> */}
                                                    </Card>
                                                </div>
                                            </Col>
                                            {/*==================Shotz=================== */}
                                            <Col xs='4' xl='4' onClick={() => { setAccord(8) }}>
                                                <h3 className='p-heading ml-1'>Story</h3>
                                                <div className=" m-1">
                                                    <Card inverse className=''>
                                                        <CardImg alt="Card image cap" src={Story} />
                                                        {/* <CardImgOverlay>
                                                            <div className="d-flex justify-content-between align-items-center m-1 text-white ">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={UserAdPro} width={10} height={10} className='rounded-circle' alt='' />
                                                                    <h6 className="sizingForTextvsmall ml-1" >florida</h6>
                                                                </div>
                                                                <div>
                                                                    <h6 style={{ fontSize: '0.4rem' }} >Sponsored</h6>
                                                                </div>
                                                            </div>
                                                            <div className="colorStoriesForbottomsmall p-1 text-white">
                                                                <p className="sizingForTextvsmall">Sponsored</p>
                                                                <h6 className="sizingForTextvsmall">Canner Film Festival</h6>
                                                            </div>
                                                            <div className="d-flex justify-content-between whyAdForShotzPositionsmall my-1">
                                                                <p className="sizingForTextvsmall" style={{ cursor: 'pointer' }} >Why this Ad?</p>
                                                                <MoreVertical size={10} />
                                                            </div>
                                                        </CardImgOverlay> */}
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        )
    }


    return (
        <>
            <Card className='py-2' >
                <Previewmodal />
                <CardHeader>
                    <Row>
                        <Col xl='6'>
                            <div className="">
                                <img src="/assets/images/adIcon/preview-logo.png" alt="logo" />
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='d-flex justify-content-end'>
                                <Button size='sm' outline color='flat-primary' onClick={() => setCenteredModal(!centeredModal)}>
                                    {centeredModal ? <Minimize2 size={15} /> : <Maximize2 size={15} />}
                                    <span className='align-middle ml-1'>{centeredModal ? 'Less Preview' : 'More Preview'}</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {/* {centeredModal ? <div className="mobile-frame" >
                        <div className="mobileAd-card">
                            <img src={UserPro} className='img-fluid' alt="Card image" />
                        </div>
                    </div> :
                        <div className="monitor-frame" >
                            <div className="monitorAd-card">
                                <img src='https://c4.wallpaperflare.com/wallpaper/974/565/254/windows-11-windows-10-minimalism-hd-wallpaper-thumb.jpg' className='img-fluid' alt="Card image" />
                            </div>
                        </div>
                    } */}
                    <Row className='text-center'>
                        <Col className='d-flex justify-content-center' >
                            <div className="mobile-frame">
                                <div className='mobileAd-card border rounded p-1'>
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex m-1">
                                                <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                <div className="ml-1">
                                                    <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                    <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                </div>
                                            </div>
                                            <div className="ml-1">
                                                <MoreVertical size={10} className='m-0' />
                                            </div>
                                        </div>
                                    </div>
                                    <img src={UserPro} className='img-fluid' alt="Card image" />
                                    <div className="p-1">
                                        <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                                            <b>
                                                An adv is the promotion of a product, brand
                                            </b>
                                        </h6>
                                        <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                            <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                                            <Send size={10} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-center' >
                            <div className="monitor-frame" >
                                <div className="monitorAd-card">
                                    <div className='border rounded p-1'>
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex m-1">
                                                    <img src={UserAdPro} width={20} height={20} className='rounded-circle' alt='' />
                                                    <div className="ml-1">
                                                        <h6 style={{ fontSize: '0.6rem' }}>florida roxin</h6>
                                                        <h6 style={{ fontSize: '0.4rem', marginTop: '0.2rem' }} >Sponsored</h6>
                                                    </div>
                                                </div>
                                                <div className="ml-1">
                                                    <MoreVertical size={10} className='m-0' />
                                                </div>
                                            </div>
                                        </div>
                                        <img src='https://c4.wallpaperflare.com/wallpaper/974/565/254/windows-11-windows-10-minimalism-hd-wallpaper-thumb.jpg' className='img-fluid' alt="Card image" />
                                        <div className="p-1">
                                            <h6 className="text-dark" style={{ fontSize: '0.6rem' }}>
                                                <b>
                                                    An adv is the promotion of a product, brand
                                                </b>
                                            </h6>
                                            <div className="d-flex justify-content-between align-items-center text-muted mt-3">
                                                <p className="sizingForTextsmall"><u>Why this Ad?</u></p>
                                                <Send size={10} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>

    )
}

export default SingleImagePreview