import React, { useState } from "react";
import { Filter } from "react-feather";
import {
  Button,
  Card,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  InputGroup,
  Input,
} from "reactstrap";
import AvatarGroupComponent from "./AvatarGroupComponent";
import img from "../../NewComponents/IMG/img.svg";
import img1 from "../../NewComponents/IMG/roundedimg2.svg";
import img2 from "../../NewComponents/IMG/roundedimg3.svg";
import img3 from "../../NewComponents/IMG/roundedimg4.svg";
import SliderButton from "./SliderButton";
import PeopleAccordion from "./PeopleAccordion";
import LocationIcon from "../../NewComponents/IMG/LocationIcon.svg";
import { Offcanvas } from "react-bootstrap";
import { useTheme, Slider as MatSlider, Stack } from "@mui/material";
import { MinusSquare, PlusSquare } from "react-feather";
import ChipBoxLocation from "../top-trending-components/ChipBoxLocation";
import ChipBox from "../top-trending-components/ChipBox";
import { useSelector } from "react-redux";
import axios from "axios";

const People = () => {
  const [show, setShow] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
 

  const theme = useTheme();
  const searchResult = useSelector((state) => state.mainSearchReducer.result);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const chackbox = [
    {
      songs: "music",
      chackbox: <input type="checkbox" />,
    },
    {
      songs: "Arts",
      chackbox: <input type="checkbox" />,
    },
    {
      songs: "Weddings",
      chackbox: <input type="checkbox" />,
    },
    {
      songs: "Food & Recipe",
      chackbox: <input type="checkbox" />,
    },
  ];
  const People = [
    {
      img: img1,
      name: "Esther Howard",
      designation: "designation/followers/mutual",
      text1: "Atlanta",
      button: (
        <Button color="primary" className="px-4">
          Follow
        </Button>
      ),
    },
    {
      img: img1,
      name: "Darlene Robertson",
      designation: "designation/followers/mutual",
      text1: "New York",
      button: (
        <Button color="primary" outline>
          Cancel Request
        </Button>
      ),
    },
    {
      img: img2,
      name: "Guy Hawkins",
      designation: "designation/followers/mutual",
      text1: "Northern Africa",
      button: (
        <Button color="primary" outline>
          Following{" "}
        </Button>
      ),
    },
    {
      img: img1,
      name: "Darlene Robertson",
      designation: "designation/followers/mutual",
      text1: "New York",
      button: (
        <Button color="primary" outline>
          Cancel Request
        </Button>
      ),
    },
    {
      img: img1,
      name: "Esther Howard",
      designation: "designation/followers/mutual",
      text1: "Atlanta",
      button: (
        <Button color="primary" className="px-4">
          Follow
        </Button>
      ),
    },
    {
      img: img2,
      name: "Guy Hawkins",
      designation: "designation/followers/mutual",
      text1: "Northern Africa",
      button: (
        <Button color="primary" outline>
          Following{" "}
        </Button>
      ),
    },
  ];

  const handleDebugClick = () => {
    console.log(searchResult);
  };

  const handleFollow = async (element) => {

    console.log("hitted enter");
    try {
      const reqBody = {
        isPrivate: element.isPrivate,
        followingToId: element.id,
        isFollowed: 1,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
        reqBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response=====>", response.data.data.successResult);
      
    } catch (error) {
      console.log(error);
    }
    // Create a custom event with custom data
const customEvent = new CustomEvent('myCustomEvent', {
  detail: { key: 'value' }
});
window.dispatchEvent(customEvent);
  };
  return (
    <div>
      <Card className="p-3 border-0">
        <Row className="d-flex justify-content-around align-items-center">
          <Col md="6">
            <div className="d-flex mt-2 align-items-center">
              <p className="peopletabstext">Showing best results for</p>
              <p className="font-weight-bold" onClick={handleDebugClick}>
                “Esther Howard”
              </p>
            </div>
          </Col>
          <Col md="6">
            <div className="d-flex justify-content-end">
              {/* -------------------- Filter for Mobile-------------------- */}
              <div className="rem-web-view">
                <UncontrolledButtonDropdown
                  style={{ backgroundColor: "transparent " }}
                  className="p-o m-0"
                >
                  <DropdownToggle className="d-flex m-0 p-0 bg-light-dropdown-toggle">
                    <Filter
                      size={20}
                      color="#81c14b"
                      className="mr-2"
                      onClick={() => setBasicModal(!basicModal)}
                    />
                  </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
              {/* --------------------Filter for Desktop-------------------- */}
              <div className="rem-mob-view ml-2">
                <UncontrolledButtonDropdown
                  style={{ backgroundColor: "transparent " }}
                  className="p-o m-0"
                >
                  <DropdownToggle className="d-flex m-0 p-0 bg-light-dropdown-toggle">
                    <Button
                      color="primary"
                      outline
                      className="align-items-center"
                      onClick={() => setBasicModal(!basicModal)}
                    >
                      <Filter size={18} className="mr-2" />
                      Filters
                    </Button>
                  </DropdownToggle>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {searchResult != null
            ? searchResult?.users?.map((data, index) => {
                return (
                  <Col xl="6" md="12" sm="6" lg="12" className="mt-4">
                    <div className="border rounded">
                      <Row className="d-flex align-items-center">
                        <Col xl="8">
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="mb-3 rounded-circle">
                              <img
                                src={data.profileImageThumb}
                                width={70}
                                className="m-3 rounded-circle"
                              />
                            </div>
                            <div>
                              <p className="peopletabstext">{data.fullName}</p>
                              <p className="People_designation">
                                {data.userName}
                              </p>
                              <div className="d-flex">
                                <AvatarGroupComponent />
                                <p className="People_Sociomate mt-2">
                                  {" "}
                                  +{data.followersCount} Sociomate
                                </p>
                              </div>
                              <p className="People_Atlanta">{data.text1}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xl="4" className="d-flex justify-content-end">
                          <div className="mr-2">
                            <p className="p-2">
                              {data.followingStatus === "notFollowing" ? (
                                <Button
                                  color="primary"
                                  className="px-4"
                                  onClick={() => handleFollow(data)}
                                >
                                  Follow
                                </Button>
                              ) : (
                                <Button color="primary" outline>
                                  Cancel Request
                                </Button>
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })
            : null}
        </Row>
      </Card>

      <Modal
        isOpen={basicModal}
        className="modal-dialog-centered "
        toggle={() => setBasicModal(!basicModal)}
      >
        <ModalHeader toggle={() => setBasicModal(!basicModal)}>
          <b>People</b>
        </ModalHeader>
        {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
        <ModalBody>
          <div>
            <div className="d-flex justify-content-between">
              <h4>Withim (Km)</h4>
              <h4>8821 Km</h4>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div className="w-100">
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 0 }}
                  alignItems="center"
                >
                  <MatSlider
                    aria-label="Volume"
                    defaultValue={100}
                    sx={{
                      color:
                        theme.palette.mode === "dark" ? "#81C14B" : "#81C14B",
                      "& .MuiSlider-track": {
                        border: "none",
                      },
                      "& .MuiSlider-thumb": {
                        width: 20,
                        height: 20,
                        backgroundColor: "#81C14B",
                        "&:before": {
                          boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                        },
                        "&:hover, &.Mui-focusVisible, &.Mui-active": {
                          boxShadow: "none",
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
              <div className="d-flex align-items-center ml-3" role="button">
                <MinusSquare color="#81C14B" role="button" />{" "}
                <PlusSquare color="#81C14B" role="button" />
              </div>
            </div>
            <div className="border-top mt-2">
              <h4 className="mt-3">Choose locations</h4>
              <ChipBoxLocation />
            </div>
            <div className="">
              <h4 className="mt-3">Choose Interest</h4>
              <ChipBox />
            </div>
            <div className="d-flex justify-content-center mt-3 ">
              <Button color="primary" outline>
                CLEAR ALL
              </Button>
              <Button color="primary" className="APPLYThisWidth ml-2">
                APPLY
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default People;
