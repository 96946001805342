export const SocialMessageData = [
    {
        id: 'a',
        heading: "Allow",
        subContent: "Any SocioMee user will be able to share your post"
    },
    {
        id: 'b',
        heading: "Do not allow",
        subContent: "No one will be able to share your post"
    },

]
export const ProfileTagData = [
    {
        id: 'a',
        heading: "No one",
        subContent: "No one will be able to tag you on their pots"
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections will be able to tag you can their posts"
    },
    {
        id: 'c',
        heading: "Anyone on SocioMee",
        subContent: "Anyone on SocioMee will be able to tag you on their post"
    },

]
export const ProfileCommentData = [
    {
        id: 'a',
        heading: "No one",
        subContent: "No one will be able to comment on your posts"
    },
    {
        id: 'b',
        heading: "Only my Connections",
        subContent: "Only your connections will be able to comment on your posts"
    },
    {
        id: 'c',
        heading: "Anyone on SocioMee",
        subContent: "Anyone on SocioMee can comment on your posts"
    },

]
export const StreamingMessageData = [
    {
        id: 'a',
        heading: "Off",
        subContent: "Turn off chat options during live streams"
    },
    {
        id: 'b',
        heading: "On",
        subContent: "Turn on Chat options during live streams"
    },

]
export const StreamingLiveData = [
    {
        id: 'a',
        heading: "Save in Gallery",
        subContent: "Save live recording in device gallery"
    },
    {
        id: 'b',
        heading: "Post in Story",
        subContent: "Post live video to stories"
    },
    {
        id: 'c',
        heading: "Post in Social Feed",
        subContent: "Post Live Videos on HomeFeed"
    },

]