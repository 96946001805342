import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Input,
} from "reactstrap";
import HeaderStory from "../HeaderStory";
import cricleimg from "../../NewComponents/IMG/cricleimg.png";
import { ArrowLeft, Image, Italic, Settings, Type, X } from "react-feather";
import { TextareaAutosize, useTheme } from "@mui/material";
import emoji from "../../NewComponents/IMG/emoji.png";
import grid_1 from "../../NewComponents/IMG/grid_1.png";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import StorySettingModal from "./StoryModal/StorySettingModal";
import DiscardStory from "./StoryModal/DiscardStory";
import "../Story/Story.css";
import Draggable from "react-draggable";
import { useNavigate } from "react-router-dom";
import { createRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import typeImage from "./Images/TypeIcon.svg";
import { BackgroundGrid, FilterGrid } from "./StoryArrayDummy/StoryDummy";
import "cropperjs/dist/cropper.css";
import StoryCropTool from "./StoryImageEditTools/StoryCropTool";
import StoryColorFont from "./StoryImageEditTools/StoryColorFont";
import StoryTextEditMach from "./StoryTextEditMach";
import StoryTextDrapRotate from "./StoryImageEditTools/StoryTextDragRotate";
import StoryTextRotaterDragger from "./StoryImageEditTools/StoryTextRotaterDragger";
import AddtextWhite from "./Images/AddtextWhite.svg";
import AddtextBlack from "./Images/AddTextBlack.svg";
import dropdownIcon from "./Images/chevron-up.png";
import Picker from "emoji-picker-react";
import { EmojiObjects } from "@mui/icons-material";
import activeText from "./Images/activeText.svg";
import inactiveText from "./Images/inactiveTextIcon.svg";
import activeMedia from "./Images/activeMedia.svg";
import inactiveMedia from "./Images/inactiveMedia.svg";
import profileImg from "./Images/profileImg.svg";

// crop the data and image edit

const CreateStories = (emojiObject) => {
  const navigate = useNavigate();

  // ------------- Story People Modal  -------------
  const [active, setActive] = useState("1");
  const [image, setImage] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [ImageFilter, setImageFilter] = useState("none");
  const [text, setText] = useState('');
  const [fontFamily, setFontFamily] = useState("None");
  const [imageActive, setImageActive] = useState(grid_1);
  const [textAlign, setTextAlign] = useState("left");
  const [showInputBox, setShowInputBox] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [textColor, setTextColor] = useState("#ffffff");
  const [croppedImage, setCroppedImage] = useState(null);
  const [textClick, setTextClick] = useState(false);
  const [fontSize, setFontSize] = useState(20);
  const [rotateText, setRotateText] = useState(null);
  const [linecount, setLinecount] = useState(0);
  // const [activeFilter, setActiveFilter]=useState(false)

  //image adjustment
  const [showAdjustment, setShowAdjustment] = useState(false);
  const [exposure, setExposure] = useState(100);
  const [contrast, setContrast] = useState(95);
  const [temperature, setTemperature] = useState(0);
  const [vignettes, setVignettes] = useState(0);
  const [saturation, setSaturation] = useState(170);

  const handleBoxClick = () => {
    setShowAdjustment(!showAdjustment);
  };

  const handleExposureChange = (e) => {
    setExposure(parseInt(e.target.value));
  };

  const handleContrastChange = (e) => {
    setContrast(parseInt(e.target.value));
  };

  const handleTemperatureChange = (e) => {
    setTemperature(parseInt(e.target.value));
  };

  const handleVignettesChange = (e) => {
    setVignettes(parseInt(e.target.value));
  };

  // const handleGrainChange = (e) => {
  //   setGrain(parseInt(e.target.value));
  // };

  const handleSaturationChange = (e) => {
    setSaturation(parseInt(e.target.value));
  };

  const handleReset = () => {
    setExposure(95);
    setContrast(95);
    setTemperature(0);
    setVignettes(0);
    setSaturation(170);
  };

  //end image adjustment

  //Switching of text and file upload buttons
  // to make tab green

  const [writingMode, setWritingMode] = useState(false);
  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };


  // Do nothing when double-clicked on text preview
  const handleTextClick = (event) => {
    if (event.detail === 2 && !writingMode) {
      // setText('');           
    }
  };


  // const handleTextClick = (event) => {
  //   if (event.detail === 2) {
  //     setWritingMode((prevWritingMode) => !prevWritingMode);
  //   }
  // };

  // const [button1Color, setButton1Color] = useState('');
  // const [button2Color, setButton2Color] = useState('');

  // cropped Image Height and Width
  const [croppedImgHeight, setCroppedImgHeight] = useState(null);
  const [croppedImgWeight, setCroppedImgWeight] = useState(null);

  // clicked text for text edit
  const [selectedText, setSlectedText] = useState(null);

  // image story flow states
  const [textAreaChange, setTextAreaChange] = useState("");
  // text add function from the context
  const [TextAddedToImage, setTextAddedToImage] = useState([]);

  // text writing mode
  const [IsWritingMode, setIsWrittingMode] = useState(false);

  const ManageTextAddOrRemove = (e) => {
    if (IsWritingMode) {
      // const letTextArea = document.querySelector('.InputTextStory_')
      const AnewText = {
        id: TextAddedToImage.length,
        text: textAreaChange,
        color: textColor,
        fontStyle: fontFamily,
      };
      const ArrayLen = [...TextAddedToImage, AnewText];
      setTextAddedToImage(ArrayLen);
      setIsWrittingMode(false);
    } else {
      setIsWrittingMode(true);
    }
  };
  function adjustTextareaHeight() {
    const textarea = document.querySelector(".InputTextStory_");
    textarea.style.height = ""; // Reset the height to allow the textarea to shrink


    // Set the new height to accommodate the content
    textarea.style.height = textarea.scrollHeight + "px";
  }

  const onEmojiClick = (emojiObject) => {
    // Assuming emojiObject and text variables are defined

    const emojiText = text + ' ' + emojiObject.emoji;
    //console.log(emojiObject.emoji)
    setText(emojiText);
    setTextAreaChange(emojiText);
    setShowPicker(true);
  };

  const toggleEmojiPicker = () => {
    setShowPicker(!showPicker);
  };

  // when we click outside the emoji picker emoji-picker will disappear
  const pickerRef = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // funtion to know the imag actual width and height

  const imgRef = useRef(null);
  const ParentContainerRef = useRef(null);

  useEffect(() => {
    if (croppedImage) {
      if (imgRef.current) {
        const imageElement = imgRef.current;
        const parentCont = ParentContainerRef.current;

        const handleImageLoad = () => {
          // Get the width and height of the image
          const imageWidth = imageElement.naturalWidth;
          const imageHeight = imageElement.naturalHeight;

          // let the parent contianer
          const width = parentCont.clientWidth;
          const height = parentCont.clientHeight;

          // // Access the parent container's width and height
          // const parentWidth = imageElement.parentNode.clientWidth;
          // const parentHeight = imageElement.parentNode.clientHeight;

          // Use the width and height values as per the parent container
          const croppedImgWidth = (imageWidth / width) * 100;
          const croppedImgHeight = (imageHeight / height) * 100;


          // setCroppedImgWidth(croppedImgWidth);
          // setCroppedImgHeight(croppedImgHeight);
          //   console.warn("Width:", croppedImgWidth, width, imageWidth);
          //   console.warn("Height:", croppedImgHeight, height, imageHeight);
        };

        imageElement.addEventListener("load", handleImageLoad);

        return () => {
          imageElement.removeEventListener("load", handleImageLoad);
        };
      }
    }
  }, [croppedImage]);

  // function end to know the actual width and heigh of the image

  const [textStyle, setTextStyle] = useState({
    color: textColor,
    maxWidth: "100%",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    fontFamily: fontFamily,
    border: `1px solid #fff`,
    transform: `rotate(${rotateText}deg)`,
    textAlign: textAlign,
  });
  // set the initial color value to black (#000000)
  // ------------------text resize start-------------------
  const handleColorChange = (event) => {
    setTextColor(event.target.value);
    if (selectedText !== null) {
      TextAddedToImage[selectedText].color = event.target.value;
    }
  };

  const textRef = useRef(null);

  //------------------------ text resize end--------------------

  const handleFileChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  //  ------------- handle Filter Change -------------

  const handleFilterChange = (Filter) => {
    setImageFilter(Filter);
  };

  //   ------------- Handle Text Change -------------
  const handleTextChange = (e) => {
    setText(e.target.value);
    // setShowPicker(false);
  };

  //  ------------- Handle FontFamily Change Text -------------

  const handleFontFamilyChange = (e) => {
    setFontFamily(e.target.value);
  };

  const handleFontStyleImageEdit = (value) => {
    if (selectedText !== null) {
      TextAddedToImage[selectedText].fontStyle = value;
    }
    // setFontFamily(value)
  };

  // ------------- Internal Style -------------

  // handleFontsize

  const calculateFontSize = () => {
    // Set your desired minimum and maximum font sizes
    const minFontSize = 14;
    const maxFontSize = 24;



    // Calculate the font size based on the length of the text
    const fontSize = maxFontSize - text.length / 10; // You can adjust the divisor for a smoother effect

    // Ensure the font size is within the desired range
    return Math.max(minFontSize, Math.min(maxFontSize, fontSize));
  };

  // change the font size


  const handleWheel = (event) => {
    event.preventDefault();

    // Define the minimum and maximum font sizes
    const minFontSize = 5;
    const maxFontSize = 200; // Set the maximum font size to your desired value

    // Get the text content height and container height
    const textContentHeight = textRef.current.scrollHeight;
    const containerHeight = textRef.current.parentElement.clientHeight;

    // Calculate the new font size
    const newFontSize = fontSize + (event.deltaY > 0 ? -1 : 1);

    // Calculate the estimated text content height with the new font size
    const estimatedTextHeight = textContentHeight * (newFontSize / fontSize);

    // Calculate the maximum allowed container height (e.g., 97% of the parent container)
    const maxAllowedHeight = 0.96 * containerHeight;

    // Ensure the new font size is within the desired range and that text content doesn't exceed the maximum allowed container height
    if (
      (newFontSize >= minFontSize && newFontSize <= maxFontSize) &&
      (newFontSize < fontSize || estimatedTextHeight <= maxAllowedHeight)
    ) {
      setFontSize(newFontSize);
    }
  };




  // ------------- Story setting States -------------
  const [storySettingModal, setStorySettingModal] = useState(false);
  const toggleStorySettingModal = () => {
    setStorySettingModal((prevState) => !prevState);
  };
  // ------------- Delete Modal State -------------
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const toggleDiscardModalOpen = () => {
    setDiscardModalOpen((prevState) => !prevState);
  };

  const valueText = [
    {
      value: 0,
      label: "Orignal",
    },
    {
      value: 1,
      label: "1x",
    },
    {
      value: 2,
      label: "2x",
    },
    {
      value: 3,
      label: "3x",
    },
  ];

  function TextValue(value) {
    return `${value}x`;
  }

  // Crop the Data and edit the image

  const [filterMobile, setFilterMobile] = useState(false);

  const [MobiletextWrite, setMobiletextWrite] = useState(false);

  const MobileTextStyle = {
    color: textColor,
    // maxWidth: "100%",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    // transform: "translate(-50%, -50%)",
    fontFamily: fontFamily,
    fontSize: "calc(100vw / 32)",
    userSelect: "none",
  };

  const cropperRef = createRef();

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      );
      // setImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleRotate = () => {
    if (cropperRef.current) {
      cropperRef.current.cropper.rotate(90);
    }
  };

  const theme = useTheme();

  useEffect(() => {
    setTextStyle({
      color: textColor,
      maxWidth: "100%",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      fontFamily: fontFamily,
      // border: `1px solid #fff`,
      transform: `rotate(${rotateText}deg)`,
      textAlign: textAlign,
    });
  }, [textColor, fontFamily, rotateText, textAlign]);

  // current Activetab with State
  const [ImageEditCurrentStep, setImageEditCurrentStep] = useState("1");

  // To Manage the Steps of the Image Edit process
  const ManageImageEditFlow = (current, setStep) => {

    setImageEditCurrentStep(setStep);

  };

  // funtion to manage the add text on the image
  const ManageAddTextToImage = (e) => {
    setShowInputBox(true);
    ManageTextAddOrRemove(e);
    if (ImageEditCurrentStep !== "3") {
      ManageImageEditFlow(ImageEditCurrentStep, "3");
    }
  };

  const handleDelete = () => {
    window.addEventListener("keyup", (e) => {
      let key = e.keyCode;
      if (key === 46) {
        if (TextAddedToImage.length > 0) {
          setTextAddedToImage(
            TextAddedToImage.filter(
              (x) => TextAddedToImage.indexOf(x) !== selectedText
            )
          );
        }
      }
    });
  };

  return (
    <>
      {/* HEADER */}

      <div className="header">
        <HeaderStory />
      </div>

      {/* Story setting modal calling */}

      <StorySettingModal
        isOpen={storySettingModal}
        settingModal={toggleStorySettingModal}
      />

      {/* Story Discard Modal Calling */}

      <DiscardStory
        isOpen={discardModalOpen}
        discardModal={toggleDiscardModalOpen}
      />

      <Row
        className="rowForrevert DeskTopViewStoryCreation  pl-1  pt-2 pb-2"
        style={{
          maxHeight: "100vh",
          overflow: "hidden",
          position: "absolute",
          top: "85px",
        }}
      >
        <input
          type="file"
          onChange={handleFileChange}
          multiple="multiple"
          name="file"
          id="file_upload"
          className="d-none"
        />
        <Col
          xl="3"
          lg="4"
          md="4"
          sm="5"
          className="story_sidebar  d-flex flex-column "

        >
          <div>
            <div
              className="d-flex justify-content-between align-items-center p-3"
              style={{ height: "4.4rem" }}
            >
              <h4 className="Fw_500">Your Story</h4>
              <p onClick={toggleStorySettingModal} role="button">
                <Settings size={22} color="#4D4D4D" />
              </p>
            </div>
            <Row className="d-flex align-items-center border-top border-bottom py-2">
              <Col
                xl="8"
                lg="8"
                md="7"
                sm="7"
                xs="7"
                className="d-flex align-items-center"
              >
                <img
                  src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVvcGxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                  className=" ml-2 rounded-circle"
                  height="60px"
                  width="60px"
                  alt="..."
                />
                <p className="Fw_500 ml-2">Ella Sofia</p>
              </Col>
              <Col
                xl="4"
                lg="4"
                md="3"
                sm="3"
                xs="3"
                className="d-flex justify-content-end"
              >
                <div className="StoryEditPartIcons d-flex gap-1 pr-1 align-items-center">
                  <span
                    className={`StoryImageEditbuttons_ Story4D_500 cursor-p ${active === "1" ? "green-button" : ""
                      }`}
                    onClick={() => toggle("1")}
                  >
                    <img
                      src={active === "1" ? activeText : inactiveText}
                      width="38px"
                      alt="Typebutton"
                    />
                  </span>
                  <span
                    onClick={() => {
                      toggle("2");
                      document.querySelector("#file_upload").click();
                    }}
                  >
                    <img src={active === "2" ? activeMedia : inactiveMedia} />
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          {/*  ------------- Start Image Editor ------------- */}
          <Box sx={{ m: 1 }} className="mt-3 p-2">
            {/* {console.log(TextAddedToImage)} */}
            {/* {active === "2" ? <div className='border-top border-bottom py-2 d-flex align-items-center' role='button' onClick={() => setShowInputBox(true)} >
                        <Fab className='btn-icon border-secondary rounded-circle' size='small' color='flat-success' onClick={ManageTextAddOrRemove}>
                            <Type size={20} />
                        </Fab>
                        <h4 className='ml-3'>Add Text</h4>
                    </div>
                        : ""} */}
            {active === "2" ? (
              <div
                className="d-flex align-items-center justify-content-start gap-1 cursor-p"
                onClick={(e) => {
                  ManageAddTextToImage(e);
                }}
              >
                {" "}
                <span className="StoryImageEditbuttons_AddText  Story4D_500  cursor-p">
                  {/* <img src={ImageIcon} alt="ImageButton" className='EditStoryBtnImage cursor-p' /> */}
                  <img src={AddtextBlack} alt="AddTexticon" />
                </span>
                <h4 className="ml-2 Story4D_500">Add Text</h4>
              </div>
            ) : (
              ""
            )}
            {active === "1" ? (
              <>
                {" "}
                <TextareaAutosize
                  aria-label="minimum height"
                  value={text}
                  onChange={handleTextChange}
                  fullWidth
                  className="StoryCreationTextAreaMain w-100 scroll p-2 text-area-box"
                  minRows={4}
                  maxlength="700"
                  placeholder="Write your text here"
                  style={{ fontSize: `${calculateFontSize()}px` }}
                />
                <p
                  className="text-end"
                  style={{
                    fontSize: "10px",
                    bottom: "8px",
                    textTransform: Italic,
                  }}
                >
                  <i>Max 700 Characters</i>
                </p>
                <FormControl
                  fullWidth
                  className="mt-2"
                  style={{ background: "#EFEFEFCC" }}
                >
                  <Select
                    className="border"
                    onChange={handleFontFamilyChange}
                    defaultValue="Default"
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ height: "44px" }}
                  >
                    <MenuItem sx={{ fontFamily: "" }} value="Default">
                      Default
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Helvetica" }}
                      value="Helvetica"
                    >
                      Helvetica
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Arial" }} value="Arial">
                      Arial
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Franklin Gothic Medium" }}
                      value="Franklin Gothic Medium"
                    >
                      Franklin Gothic Medium
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Book Antiqua" }}
                      value="Book Antiqua"
                    >
                      Book Antiqua
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Rockwell" }} value="Rockwell">
                      Rockwell
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Brush Script MT" }}
                      value="Brush Script MT"
                    >
                      Brush Script MT
                    </MenuItem>
                    <MenuItem sx={{ fontFamily: "Impact" }} value="Impact">
                      Impact
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "Arial Black" }}
                      value="Arial Black"
                    >
                      Arial Black
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box className="d-flex mt-3">
                  <div className="text-center ml-2">
                    <img
                      style={{
                        marginTop: "4px",
                        height: "33px",
                        width: "33px",
                      }}
                      src={emoji}
                      width={30}
                      alt="emoji"
                      onClick={toggleEmojiPicker}
                    />
                    {showPicker && (
                      <div
                        ref={pickerRef}
                        style={{
                          position: "absolute",
                          right: "100px",
                          left: "1px",
                          zIndex: "2",
                        }}
                      >
                        <Picker
                          pickerStyle={{ width: "300px" }}
                          onEmojiClick={onEmojiClick}
                        />
                      </div>
                    )}
                    <p className="mt-2 Fw_500">Emoji</p>
                  </div>
                  <div className="ml-5 mt-1 text-center d-flex justify-content-center flex-column align-items-center">
                    <div class="input-color-container text-center">
                      <input
                        type="color"
                        className="input-color"
                        defaultValue="#81c14b"
                        value={textColor}
                        onChange={handleColorChange}
                      />
                    </div>
                    <p className="mt-2 Fw_500 mt-2">Text color</p>
                  </div>
                </Box>
              </>
            ) : (
              ""
            )}
            <h4 className="my-3 Fw_500">
              {active === "2" ? "Add Filter" : "Background"}
            </h4>
            <Box>
              <Row
                className={`p-1 pb-3 border rounded change_background_scroll_filter m-0  ${image ? "" : "d-none"
                  }`}
              >
                {FilterGrid.map((data) => (
                  <Col
                    key={data.filters}
                    xl="3"
                    lg="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="py-2 text-center"
                  >
                    <img
                      src={croppedImage ? croppedImage : image}
                      onClick={() => handleFilterChange(data.filters)}
                      style={{
                        filter: data.filters,
                      }}
                      className="updatebackgroundStory backgroun_style img-fluid w-100 h-100  rounded border BackgroundFilterImg"
                      alt="..."
                    />
                    <p className="text-secondary ">{data.filtersName}</p>
                  </Col>
                ))}

                {/* if image appear then this dropdown will appear */}
              </Row>

              <div className="adjustment-container">
                <div
                  className={`adjustment-title ${showAdjustment ? "active" : ""
                    }`}
                  onClick={handleBoxClick}
                >
                  <p>Adjustment</p>
                  <img
                    style={{ height: "25px", width: "27px" }}
                    src={dropdownIcon}
                    alt="dropdownIcon"
                  />
                </div>

                {setCroppedImage && showAdjustment && (
                  <div className="card">
                    <div className="card-body">
                      <div style={{ display: setImage ? "block" : "none" }}>
                        <div>
                          <label>Exposure:</label>
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={exposure}
                            onChange={handleExposureChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#81C14B" }}>{exposure}</span>
                        </div>
                        <div>
                          <label>Contrast:</label>
                          <input
                            type="range"
                            min="-100"
                            max="100"
                            value={contrast}
                            onChange={handleContrastChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#81C14B" }}>{contrast}</span>
                        </div>
                        <div>
                          <label>Temperature:</label>
                          <input
                            type="range"
                            min="-100"
                            max="100"
                            value={temperature}
                            onChange={handleTemperatureChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#81C14B" }}>
                            {temperature}
                          </span>
                        </div>
                        <div>
                          <label>Vignettes:</label>
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={vignettes}
                            onChange={handleVignettesChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#81C14B" }}>{vignettes}</span>
                        </div>
                        <div>
                          <label>Saturation:</label>
                          <input
                            type="range"
                            min="0"
                            max="200"
                            value={saturation}
                            onChange={handleSaturationChange}
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#81C14B" }}>{saturation}</span>
                        </div>
                        <Button
                          className="reset"
                          onClick={handleReset}
                          color="primary"
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Box>
            <Row
              className={`p-1  border rounded change-background-scroll m-0 ${image === null ? " " : "d-none"
                }`}
            >
              {BackgroundGrid.map((data) => (
                <Col
                  key={data.background}
                  xl="3"
                  lg="3"
                  md="3"
                  sm="3"
                  xs="3"
                  className=""
                  onClick={() => setImageActive(data.background)}
                >
                  <img
                    src={data.background}
                    className="img-fluid backgroun_style rounded h-100 w-100 border BackgroundFilterImg"
                    alt="..."
                  />
                </Col>
              ))}
            </Row>
          </Box>

          {/*  ------------- End Image Editor ------------- */}
          <div
            className="d-flex justify-content-center align-items-end PostButtonOfContent py-3 shadow"
            style={{ marginTop: "30px" }}
          >
            <br />
            <Button outline onClick={toggleDiscardModalOpen}>
              Discard
            </Button>
            &nbsp;&nbsp;
            <Button color="primary">Share to Story</Button>
          </div>
        </Col>

        <Col
          xl="9"
          lg="8"
          md="8"
          sm="7"
          className="story_content story_right_conten px-3"
        >
          <TabContent
            activeTab={active}
            className="story_inner_content "
            style={{ height: "100vh" }}
          >
            <TabPane tabId="1">
              <div
                className="border-bottom py-4 col-12 d-flex justify-content-between"
                style={{ height: "4.4rem" }}
              >
                <h4 className="Fw_500">Story Preview</h4>
                <h3 className="text-white">Story Preview</h3>
                {image ? (
                  <span
                    onClick={() => {
                      toggle("2");
                    }}
                    style={{ height: "fit-content" }}
                    className="bg-primary ml-3 text-white p-1 px-3 rounded-3 cursor-p"
                  >
                    {" "}
                    <ArrowLeft size={18} />
                    &nbsp; Crop{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center my-4 text-defult-img-container">
                  <div className="Story_Creation_Preview_section bg-dark rounded shadow">
                    <img
                      src={croppedImage ? croppedImage : imageActive}
                      className={`img-fluid w-100 h-100 rounded StoryImagePreviewImage ${croppedImage ? "bg-dark" : ""
                        }`}
                      alt="Text Preview"
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        filter: ImageFilter,
                      }}
                    />
                    <div
                      className="text-container"
                      onClick={() => {
                        setTextClick(false);
                      }}
                    >
                      {text && writingMode === false ? (
                        <p
                          className="text-area-box-img"
                          onWheel={handleWheel}
                          style={{
                            ...textStyle,
                            fontSize: `${fontSize}px`,
                            whiteSpace: 'wrap',
                          }}
                          ref={textRef}

                          onClick={handleTextClick}
                        >
                          {text}
                        </p>
                      ) : (
                        <>
                          <textarea
                            className="ml-2 mr-2 text-area-box-img"
                            value={text}
                            cols={100}


                            onChange={(e) => {

                              const newText = e.target.value;
                              setText(newText);

                            }}
                            style={{
                              fontSize: `${calculateFontSize()}px`,
                              display: "none",
                            }}

                          ></textarea>
                          <p
                            style={{
                              color: "#999999",
                              fontSize: "25px",
                              textDecoration: "underline",
                            }}
                          >
                            Text Preview
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>

            {/* after image select edit image and create post  */}
            <TabPane tabId="2">
              {/* -------------------- Header of the Edit Contaner ----------------------------- */}
              <div className="ImageEditCropSectionHeader p-4">
                {/* <h4 className='StoryPrivewMain_Heading Fw_500'>Story Preview</h4> */}
                <h4 className="Fw_500">Story Preview</h4>

                {/* --------------------------------when adding image  ---------------------------------- */}
                <h5
                  className={`CropTheStoryImage_Text text-nowrap Fw_500  ${ImageEditCurrentStep === "1" ? "" : "d-none"
                    }`}
                >
                  Select photo to crop and rotate
                </h5>

                {/* ------------------------------ when cropping the image ----------------------------------------- */}
                {/* crop tools to crop image  */}
                {ImageEditCurrentStep === "2" ? (
                  <StoryCropTool
                    valueText={valueText}
                    TextValue={TextValue}
                    zoom={zoom}
                    setZoom={setZoom}
                    handleRotate={handleRotate}
                  />
                ) : (
                  ""
                )}
                {/* crop button to crop the image  */}
                {ImageEditCurrentStep === "2" ? (
                  <span
                    onClick={() => {
                      getCropData();
                      ManageImageEditFlow(ImageEditCurrentStep, "3");
                      //getCropData("disable")
                    }}
                    className="bg-primary   px-2 py-1 text-white rounded-2 cursor-p"
                    style={{ justifySelf: "flex-end", alignSelf: "start" }}
                  >
                    Crop
                  </span>
                ) : (
                  ""
                )}

                {/* ------------------------------------- when addding the text  ----------------------------------------- */}
                {/* text customize tool  */}
                {ImageEditCurrentStep === "3" ? (
                  TextAddedToImage.length > 0 ? (
                    <StoryColorFont
                      textColor={textColor}
                      setFontFamily={handleFontStyleImageEdit}
                      handleColorChange={handleColorChange}
                      setSlectedText={setSlectedText}
                      setTextAreaChange={setTextAreaChange}
                      setText={setText}
                    />
                  ) : (
                    <h6></h6>
                  )
                ) : (
                  ""
                )}
                {/* text Add Button  */}
                {ImageEditCurrentStep === "3" ? (
                  <span
                    onClick={() => {
                      ManageTextAddOrRemove();
                    }}
                    className="bg-primary   px-2 py-1 text-white rounded-2 cursor-p"
                    style={{ justifySelf: "flex-end", alignSelf: "start" }}
                  >
                    Add
                  </span>
                ) : (
                  ""
                )}
              </div>

              {/* ------------------------------------ Image Edit Container ---------------------------------------*/}
              <div className="StoryImageEditContainer_">
                {/* kal ye fix karna hai */}
                {/* {ImageUpladStep === "text" ? <StoryTextRotaterDragger /> : " "} */}

                {/* ---------------------------- Full gray container of the image edit ------------------------------------------ */}
                <div className="StoryImageEditContainer_Sub position-relative">
                  {/* ------------------------------------Step Image : while image selecting----------------------------------- */}
                  {image !== null ? (
                    <div
                      className={`ImageEditContainer_main ${ImageEditCurrentStep === "1" ? "" : "d-none"
                        }`}
                      onClick={() =>
                        ManageImageEditFlow(ImageEditCurrentStep, "2")
                      }



                    >
                      <img
                        src={image}
                        alt="OnEditImage"
                        className="OnEditImagePrivew_"
                      />
                    </div>
                  ) : (
                    // Center image icon if not selected the image
                    <div
                      className="StoryImageUploadIconContainer"
                      onClick={() => {
                        document.querySelector("#file_upload").click();
                      }}
                    >
                      <Image
                        size={130}
                        className="text-secondary"
                        strokeWidth={1.5}
                      />
                      <h5 className="mt-2 text-secondary">
                        Select photo to crop and rotate
                      </h5>
                    </div>
                  )}


                  <div
                    ref={ParentContainerRef}
                    className={`ImageEditContainer_main ${IsWritingMode ? "" : ""
                      } ${ImageEditCurrentStep === "3" ? "" : "d-none"}`}

                  >

                    {console.log(selectedText)}
                    {ImageEditCurrentStep === "3" ? (
                      <StoryTextRotaterDragger
                        setSlectedText={setSlectedText}
                        selectedText={selectedText}
                        setTextAreaChange={setTextAreaChange}
                        setText={setText}

                        dummyTexts={
                          TextAddedToImage.length > 0 ? TextAddedToImage : []
                        }

                      />
                    ) : (
                      " "
                    )}


                    <img
                      className="OnEditImagePrivew_"
                      ref={imgRef}
                      onDoubleClick={(e) =>
                        e.preventDefault() // for diable double click not reset the picture

                      }
                      style={{
                        filter: `brightness(${exposure}%) contrast(${contrast}%) saturate(${saturation}%) sepia(${temperature}%) ${ImageFilter}`,
                        borderRadius: `${vignettes}%`,
                      }}
                      src={croppedImage ? croppedImage : image}
                      alt="OnEditImage"
                    />

                    {/* ------------------------ when Text writing the text area -------------------------------------- */}
                    {/* text to write the text  */}
                    {IsWritingMode ? (
                      <div className="w-50 text-center ">
                        <textarea
                          onChange={(e) => {
                            setTextAreaChange(e.target.value);
                            setText(e.target.value);
                          }}
                          placeholder="Write text...."
                          id="myTextarea"
                          className="InputTextStory_ bg-transparent text-white text-center border-0 ml-2 mr-2 p-3"
                          onInput={adjustTextareaHeight}
                          // value={text} // for the emojit part come in text 
                          maxLength={100} // Set the maximum length of the textarea
                        ></textarea>
                      </div>
                    ) : (
                      " "
                    )}
                  </div>

                  {/* -------------------------------------------Image Cropper  ------------------------------------- */}
                  {/* the cropper section  */}
                  {ImageEditCurrentStep === "2" ? (
                    <Cropper
                      ref={cropperRef}
                      style={{
                        height: "80vh",
                        width: "90%",
                        aspectRatio: " 16 /9",
                        margin: "auto auto",
                      }}
                      zoomTo={zoom}
                      initialAspectRatio={9 / 16}
                      preview=".img-preview"
                      src={image}
                      viewMode={2}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      dragMode="move"
                      checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                      guides={true}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* crop section  */}
              {/* <div className='d-none'>
                            <div className='py-4 d-flex justify-content-between'>
                                <div className='col-2 '>
                                    <h3>Story Preview</h3>
                                </div>
                                <div className='d-flex col-10 align-items-start'>
                                    <MinusCircle role='button' size={24} className='mx-2 mt-1 text-muted' onClick={() => { setZoom(zoom - 1) }} />
                                    <Slider className="w-75 mx-3" valueLabelDisplay="on" sx={{
                                        color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                        '& .MuiSlider-track': {
                                            border: 'none',
                                        },
                                        '& .MuiSlider-thumb': {
                                            width: 24,
                                            height: 24,
                                            backgroundColor: '#fff',
                                            '&:before': {
                                                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                            },
                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                boxShadow: 'none',
                                            },
                                        },
                                    }} marks={valueText} getAriaValueText={TextValue} min={0} max={3} value={zoom} onChange={(e) => setZoom(e.target.value)} aria-labelledby='continuous-slider' />
                                    <PlusCircle role='button' size={24} className=' mt-1 text-muted' onClick={() => { setZoom(zoom + 1) }} />
                                    <RotateCw role='button' size={24} className='mx-2 mt-1 text-muted' onClick={handleRotate} />
                                    <span className='bg-primary ml-3 text-white p-1 px-3 rounded-3 cursor-p' onClick={() => { getCropData() }}> <ArrowRight size={18} />&nbsp; Next </span>
                                </div>
                            </div>
                            <div className="CropContainer">
                                <div style={{ width: "100%" }}>
                                   
                                    <Cropper
                                        ref={cropperRef}
                                        style={{ height: "80vh", width: "90%", aspectRatio: " 16 /9", margin: "auto auto" }}
                                        zoomTo={zoom}
                                        initialAspectRatio={9 / 16}
                                        preview=".img-preview"
                                        src={image}
                                        viewMode={2}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        dragMode='move'
                                        checkOrientation={true} 
                                        guides={true}
                                    />
                                </div>
                            </div>
                        </div> */}
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      {/* --------------------------------------------------- Mobile story Creation design ---------------------------------------- */}
      <Row
        className="MobileStoryView bg-dark w-100 p-0 m-0 rowForrevert position-relative  "
        style={{
          height: "100vh",
          width: "100%",
          backgroundImage: `url(${imageActive})`,
          paddingTop: "56.25%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <img src={imageActive} alt="" style={{ position: "absolute", left: "0", top: "0", width: "100%", height: "100%", aspectRatio: }} /> */}
        <input type="file" name="image" id="image" className="d-none" />
        <div className="StoryCreationCrossBtn d-flex align-items-center justify-content-between px-3">
          <X size={25} color="#fff" onClick={() => navigate(-1)} />

          {/* <FormControl className='w-50 p-0'>
                    <Select className='border' onChange={handleFontFamilyChange} defaultValue='None' inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Helvetica">Helvetica</MenuItem>
                        <MenuItem value="Arial">Arial</MenuItem>
                        <MenuItem value="Franklin Gothic Medium">Franklin Gothic Medium</MenuItem>
                        <MenuItem value="Book Antiqua">Book Antiqua</MenuItem>
                        <MenuItem value="Rockwell">Rockwell</MenuItem>
                        <MenuItem value="Brush Script MT">Brush Script MT</MenuItem>
                        <MenuItem value="Impact">Impact</MenuItem>
                        <MenuItem value="Arial Black">Arial Black</MenuItem>
                    </Select>
                </FormControl> */}
          <div className="FilterAndTextBtn d-flex align-items-center gap-3">
            <input
              type="color"
              className="StoryMobileColorPicker shadow"
              value={textColor}
              onChange={handleColorChange}
            />
            <div className="StoryMobileTextBtn text-white">
              <Type
                size={18}
                onClick={() => setMobiletextWrite(!MobiletextWrite)}
              />
            </div>
            <div className="StoryMobileFilter">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setFilterMobile(!filterMobile);
                }}
              >
                <path
                  d="M11.417 12.6665C11.417 15.4279 9.17842 17.6665 6.41699 17.6665C3.65557 17.6665 1.41699 15.4279 1.41699 12.6665C1.41699 9.90508 3.65557 7.6665 6.41699 7.6665C9.17842 7.6665 11.417 9.90508 11.417 12.6665Z"
                  fill="white"
                  fill-opacity="0.49"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  d="M18.584 12.6665C18.584 15.4279 16.3454 17.6665 13.584 17.6665C10.8226 17.6665 8.58398 15.4279 8.58398 12.6665C8.58398 9.90508 10.8226 7.6665 13.584 7.6665C16.3454 7.6665 18.584 9.90508 18.584 12.6665Z"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  d="M14.4167 6.66659C14.4167 9.33596 12.2527 11.4999 9.58333 11.4999C6.91396 11.4999 4.75 9.33596 4.75 6.66659C4.75 3.99721 6.91396 1.83325 9.58333 1.83325C12.2527 1.83325 14.4167 3.99721 14.4167 6.66659Z"
                  fill="white"
                  fill-opacity="0.38"
                  stroke="#fff"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          className={`${filterMobile
            ? "StoryMobiletextFieldUpperFilter"
            : "FiltersMobileView"
            } ${MobiletextWrite ? "" : "d-none"} px-3`}
        >
          <Input
            onChange={handleTextChange}
            placeholder="write text...."
            className="text-white border-color-white bg-transparent w-100 px-2 mt-2 z-index-2"
          />
        </div>
        <div className={`FiltersMobileView ${filterMobile ? "" : "d-none"} `}>
          {BackgroundGrid.map((data) => (
            <img
              src={data.background}
              className="img-fluid "
              onClick={() => setImageActive(data.background)}
              alt="..."
            />
          ))}
        </div>
        <div className="StoryMobileBottomOptions text-white px-3 w-100 d-flex align-items-center justify-content-between">
          <Settings size={22} onClick={toggleStorySettingModal} />
          <div className="StoryMobileShareNowBtn m-2">
            <Button outline color="primary">
              Share to Story
            </Button>
          </div>
        </div>
        <div className="ParenMobiletextContainer">
          {text && (
            <Draggable bounds="parent">
              <pre
                style={MobileTextStyle}
                className="TextMobileView"
                onClick={handleTextClick}
              >
                {text}
              </pre>
            </Draggable>
          )}
        </div>
      </Row>

      {/* -------------------------------------------Mobile story Creation Design end ------------------------------------------------- */}
    </>
  );
};
export default CreateStories;
