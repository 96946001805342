import React, { useState } from 'react'
import { IconButton, Pagination, Stack } from '@mui/material'
import { AlertTriangle, Eye, Link, Mic, MoreHorizontal, Pause, Play, Share2 } from 'react-feather'
import { Badge, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import mics from '../../top-trending-components/Grid-Components/mics.png'
import micimg from '../../top-trending-components/Grid-Components/micimg.svg'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
import PaidSvgImg from '../../../NewComponents/IMG/PaidSvgImg.svg'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { listDataPodcast } from '../DummyArray'
import ReportModal from '../Grid-Components/ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
import { useUserAuth } from '../../../Context/userAuthContext'
import PodcastFooter from '../../podcast-module-component/PodcastFooter'
import { PauseRounded, PlayArrowRounded } from '@mui/icons-material'
import { useTheme } from '@mui/material';
import musicAnimation from '../../../NewComponents/Gif/musicAnimation.gif'
const ListPodcast = ({ display, space }) => {

    {/*  ---------------------- State to manage Podcast Footer Musicbar called from context --------------------  */ }
    const { musicBar, setMusicBar } = useUserAuth();
    const [isPlaying, setIsPlaying] = useState(false);
    // const [paused, setPaused] = useState(false);
    const theme = useTheme();
    const mainIconColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF';
    {/*  ---------------------- Fumction for Music Bar --------------------  */ }
    const handleMusicBar = () => {
        setMusicBar(true);
    }

    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked((prevState) => !prevState);
    }


    //   ---------------------------------------Main return function---------------------------
    return (
        <div>
            <Row className='mt-2'>
                <Col md='12' className='' >
                    {listDataPodcast.map((data, index) => (

                        <div className='border rounded on-hover-bg  ' key={index} >
                            {/* <div color={`primary ${display}`} style={{ position: 'absolute', top: '8px', left: '4px', zIndex: '5', fontSize: '12px' }} className='rotated-text' ># {data.trendingNumber}</div> */}
                            <Row className='d-flex align-items-center'>
                                <Col xl='8' className='d-flex' >
                                    {/* <div className='d-flex ml-3'>
                                        <div className='right-triangle' style={{ position: 'absolute', top: '0px', zIndex: '4', left: '10px' }} ></div>
                                    </div> */}
                                    <div className='d-flex w-100 pl-1' >
                                        <div className='d-flex align-items-center mr-2'>
                                            <div className=' d-flex flex-column TrendingTagsBorders mr-2 justify-content-center'>
                                                <p className='PilesThistags text-center'>Top</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.topsNumber}</p>
                                            </div>
                                            {/* <div className=' d-flex flex-column TrendingTagsBorders justify-content-center'>
                                                <p className='PilesThistags text-center'>Trending</p>
                                                <p className='PilesThistags text-center pt-1'>#{data.trendingNumber}</p>
                                            </div> */}
                                        </div>

                                        <div className='d-flex justify-content-between align-items-center w-100 ml-2'>
                                            <div className='d-flex align-items-start m-2' onClick={handleMusicBar} >
                                                <div style={{ position: 'relative' }} >
                                                    <img src='https://images.unsplash.com/photo-1579625731274-2d63b457a742?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80' width={70} height={70} className=' rounded' alt="aa" />
                                                    <div style={{ position: 'absolute', top: '40%', left: '-35%' }} >
                                                        <micimg size={19} className='MicThisPosgition' color='white' />
                                                        <img src={micimg} alt="" className='MicThisPosgition' />

                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column justify-content-start px-3 mt-2' style={{ marginLeft: '-4px' }} >
                                                    <h4 className=''>{data.title}</h4 >
                                                    <p className='BizPageCategory match' >#{data.category}</p>
                                                    {/* <p className='text-muted'>{data.dateandtime}</p> */}
                                                    <div className='d-flex'>
                                                        {
                                                            index === 0 ? <p className='BizPageCategory'>Single||{data.episode}</p> : " "
                                                        }
                                                        {/* {
                                                            index === 1 ? <p className='BizPageCategory'>live ||{data.episode}</p> : " "
                                                        } */}
                                                          {
                                                            index === 1 ? <p className='BizPageCategory'>Single||{data.episode}</p> : " "
                                                        }
                                                        {/* {
                                                            index === 2 ? <p className='BizPageCategory'>paid||{data.episode}</p> : " "
                                                        } */}
                                                          {
                                                            index === 2 ? <p className='BizPageCategory'>Single||{data.episode}</p> : " "
                                                        }
                                                        {/* {
                                                            index === 3 ? <p className='BizPageCategory'>epsoid||{data.episode}</p> : " "
                                                        } */}
                                                          {
                                                            index === 3 ? <p className='BizPageCategory'>Single||{data.episode}</p> : " "
                                                        }
                                                        <div>
                                                            <p className='BizPageCategory px-2'>{data.day}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className='d-flex align-items-center ml-1  usertextlist' >
                                                <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className=' rounded-circle ArticleShowdo ' alt="aa" />
                                                <div className='ml-1'>
                                                    <NavLink to='/MyProfile' style={{ color: 'black' }}> <p className='user-profile'>{data.name}</p></NavLink>
                                                    <small className='text-muted light-for-date' style={{ fontSize: '12px' }}>{data.dateandtime}</small>
                                                </div>
                                            </div> */}
                                            <div className='d-none d-md-block   rem-mob-views' style={{ marginRight: '25%' }}>
                                                <div className='d-flex align-items-center usertextlist hide-text'>
                                                    {/*  style={{ marginLeft: '15px' }} */}
                                                    <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='rounded-circle ArticleShowdo' alt="aa" />
                                                    <div className='ml-1' style={{ textWrap: 'nowrap' }}>
                                                        <NavLink to='/MyProfile' style={{ color: 'black' }}>
                                                            <p className='user-profile'>{data.name}</p>
                                                        </NavLink>
                                                        <small className='text-muted light-for-date' style={{ fontSize: '12px' }}>{data.dateandtime}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='d-flex align-items-center justify-content-around ml-5 usertextlist' >
                                            <div className={isPlaying ? '' : 'd-block invisible'}> <img src={musicAnimation} alt='...' width={25} className='mr-4' /></div>
                                            <Button color='primary' className='rem-mob-view' style={{ width: '100px' }} onClick={() => setIsPlaying(!isPlaying)} >{!isPlaying ? <><Play fill='#FFFFFF' size={15} /> Play</> : <><Pause fill='#FFFFFF' size={15} /> Pause</>}</Button>
                                        </div> */}
                                        <div className='d-none d-md-block   mt-4 ml-3 '>
                                            <div className='d-flex align-items-center justify-content-around usertextlist d-none d-md-block d-sm-none d-xl-flex  '>
                                                <div className={isPlaying ? '' : 'd-block invisible '}>
                                                    <img src={musicAnimation} alt='...' width={25} className='mr-4 audio-text' />
                                                </div>
                                                <Button color='primary' className='btn-audio' style={{ width: '100px' }} onClick={() => setIsPlaying(!isPlaying)} >{!isPlaying ? <><Play fill='#FFFFFF' size={15} /> Play</> : <><Pause fill='#FFFFFF' size={15} /> Pause</>}</Button>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                                {/* <Col xl='3'>
                                    <div className='d-flex justify-content-center align-items-center ' style={{ marginLeft: '2.5rem' }}>
                                        <div className='d-flex align-items-center'>
                                            <div onClick={() => handleLike()}>
                                                {liked ?
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                    :
                                                    <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                }
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center px-2' data-bs-toggle="modal" data-bs-target="#imageModel"  >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                            </div>
                                            11.2k
                                        </div>
                                        <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                            </div>
                                            11.2k
                                        </div>

                                    </div>
                                </Col> */}
                                <Col xl='3'>
                                    <div className='d-none d-md-block rem-mob-view podcastlist d-none d-md-none d-sm-none d-xl-flex'>
                                        <div className='d-flex justify-content-center align-items-center usertextlist' style={{ marginLeft: '2.5rem' }}>
                                            <div className='d-flex align-items-center'>
                                                <div onClick={() => handleLike()}>
                                                    {liked ?
                                                        <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                                        :
                                                        <div className='Treanding_icon_liked rounded-circle' > <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                                    }
                                                </div>
                                                11.2k
                                            </div>
                                            <div className='d-flex align-items-center px-2' data-bs-toggle="modal" data-bs-target="#imageModel"  >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                                </div>
                                                11.2k
                                            </div>
                                            <div className='d-flex align-items-center' onClick={toggleShareOpen} style={{ position: 'relative' }} >
                                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                    <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                                </div>
                                                11.2k
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl='1'>
                                    <div className='d-flex justify-content-end align-items-center audiotext podcast-text moretextpodcast'>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle className='mr-1 p-0 ml-2 podcastlist podcastlists ' color='flat'>
                                                <MoreHorizontal color='#999999' size={19} className='textpodcast' />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation'>
                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}>
                                                    <Share2 size={13} />&nbsp;&nbsp;Share post
                                                </DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem'>
                                                    <NavLink to='/MyProfile' style={{ color: '#212529' }}>
                                                        <Eye size={13} />&nbsp;&nbsp;View creator's profile
                                                    </NavLink>
                                                </DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem'>
                                                    <Link size={13} />&nbsp;&nbsp;Copy link
                                                </DropdownItem>
                                                <DropdownItem divider></DropdownItem>
                                                <DropdownItem className='dropdownitem' onClick={toggleReport}>
                                                    <AlertTriangle size={13} />&nbsp;&nbsp;Report
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </Col>


                            </Row>
                        </div>
                    ))}
                    {/* -------------------------------------------------List View Section Part 2------------------------------------------------------------------------- */}

                    {/* <div className='border rounded mt-3'>
                        <Row className='d-flex align-items-center mt-3'>
                            <Col xl='6'>
                                <div className='d-flex ml-3'>
                                    <Badge color={`light-primary ${display}`}>
                                        Trending #1
                                    </Badge>
                                    <Badge color='primary' pill className={space}>
                                        Top #96
                                    </Badge>
                                </div>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <img src='https://images.unsplash.com/photo-1579625731274-2d63b457a742?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80' width={80} height={80} className='m-3 ml-2 rounded' />
                                    <Mic size={19} className='MicThisPosgition' color='white' />
                                    <div className='MicThisImg'>
                                        <img src={PaidSvgImg} alt='paid' height={60} width={60} />
                                    </div>
                                    <div>
                                        <small>Category</small>
                                        <p className=''>Podcast Title</p >

                                        <p className='text-muted'>19 sept at 07:00 pm</p>

                                    </div>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='ml-2 rounded-circle ArticleShowdo' />
                                        <div className='ml-1'>
                                            <p>Floyd Miles</p>
                                            <p className='text-muted'>19 sept at 07:00 pm</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='6'>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                        </div>
                                        11.2k
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                            <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                        </div>
                                        11.2k
                                    </div>
                                    <UncontrolledButtonDropdown className='align-self-center'>
                                        <DropdownToggle className='' color='flat' >
                                            <MoreHorizontal size={19} color='#000000' />
                                        </DropdownToggle>
                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                            <DropdownItem divider></DropdownItem>
                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Col>

                        </Row>
                    </div> */}
                    {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}

                    {/* <div className='d-flex justify-content-center p-2'>
                        <Stack spacing={2}>
                            <Pagination
                                count={10}
                                color='primary'
                                size="large" />
                        </Stack>
                    </div> */}

                    <div className='d-flex align-items-center py-1' >

                        <Row className='w-100'>
                            {/* -----------------------------------------------------------Total list data count display--------------------------------------------------------------- */}
                            <Col xl='3' className='d-flex align-items-center' >
                                <span className='' >
                                    {/* <p>Total Podcasts Count : <span className='text-primary' >{listDataPodcast.length}</span></p> */}
                                    <p>Showing 1 to {listDataPodcast.length} of 200 entries</p>
                                </span>
                            </Col>

                            {/*---------------------------------------------------------------Pagination starts here ---------------------------------------------------------------- */}
                            <Col xl='6' className='d-flex justify-content-center' >
                                <div className='d-flex p-2 mx-auto'  >
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={10}
                                            color='primary'
                                            size="large" />
                                    </Stack>
                                </div>
                            </Col>

                            <Col xl='3' >
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

            {/* -----------------------------------------------Podcast Musicbar bottom stripe component------------------------------------------------------------- */}
            <PodcastFooter />

            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

        </div>
    )
}

export default ListPodcast