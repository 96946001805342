import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import Slider from 'react-slick'
import { Card, CardImg, Modal } from 'reactstrap'
import Pen1 from '../Img/Pen1.png'
import Sprt from "../Img/sport.png"
import SldrImg from '../Img/SldrImg.png'
import { Checkbox } from '@mui/material'
import { getAllUserMusicAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
let sportSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};


const MyMusic = () => {
    const dispatch = useDispatch()

    const getAllUserMusicSuccess = useSelector((state) => { return state.getAllUserMusicData});
    const {loading:loadingAllUserMusic, error:errorAllUserMusic, result:resultAllUserMusic} = getAllUserMusicSuccess

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [musicOpen, setMusicOpen] = useState(false)
    const toggleMusic = () => {
        setMusicOpen((preState) => !preState)
    }

    useEffect(() => {
        dispatch(getAllUserMusicAction())
      
    }, [])

    const SliderData = [
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Mughal-e-Aazam",
            musicName: "AR Rahman",
            bookName: "The Great Gatsby"

        },
        {
            img: Sprt,
            sportName: "Cricket",
            movieName: "Shole",
            musicName: "Michael Jackson",
            bookName: "Alice's Adventure in Wonderland"

        },
        {
            img: Sprt,
            sportName: "Hockey",
            movieName: "PK",
            musicName: "Kishan Maharaj",
            bookName: "Adventures of Huckleberry Finn"

        },
        {
            img: Sprt,
            sportName: "Golf",
            movieName: "Raees",
            musicName: "Ustad Bismillah Khan",
            bookName: "Pride and Prejudice"
        },
        {
            img: Sprt,
            sportName: "Cricket",
            movieName: "Shaun of the Dead",
            musicName: "Aasha Bhosle",
            bookName: "The Catcher in the Rye."
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },

    ]
    return (
        <Fragment>
            {  resultAllUserMusic && resultAllUserMusic.length >0 ?  
            <div className="about-profile-box-blk">
                <div className="card-title">
                    <h3>Music</h3>
                    <div className="settings">
                        {/* <div onClick={toggleMusic} className="setting-btn">
                            <img src={Pen1} width={12} className="img-fluid" />
                        </div> */}
                    </div>
                </div>
                <div className="card-block-box">
                    
                    <Slider {...sportSettings} className="default-space">
                        {
                         
                            resultAllUserMusic?.map((data) => (
                                <div>
                                    <Card className="profile-slide-box">
                                        <CardImg
                                            alt="Card image cap"
                                            src={data?.thumbnail}
                                            width="100%"
                                            className="img-fluid "
                                        />
                                        <div className="story-content">
                                            <h6>{data?.name}</h6>
                                        </div>
                                    </Card>

                                </div>
                            ))
                        }
                    </Slider>
                </div>

            </div>:''
            }
            <div className='Modal_section_Basic_info_Music'>
                {
                    musicOpen !== false &&
                    <Modal isOpen={musicOpen} toggle={toggleMusic} className='modal-dialog-centered'>

                        <div className='Profession_Modal_section AboutModal_Main_section p-3 py-3'>
                            <h4>Choose your Music</h4>
                            <p onClick={toggleMusic}>Save</p>
                        </div>
                        <div className='Modal_scroll_box'>
                            <div className="AboutModal_Main_section p-3 py-3">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find music..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>

                            {
                                SliderData.map((data) => (
                                    <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-3 '>
                                        <div className='d-flex align-items-center Basic_Info '>
                                            <img src={data.img} className="img-fluid sport_img" alt='sport' width={36} height={36} />
                                            <h4 className='ml-3 '>{data.musicName}</h4>
                                        </div>
                                        <Checkbox {...label} defaultChecked />
                                    </div>

                                ))
                            }
                        </div>
                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default MyMusic
