import React from 'react'
import { Fragment } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import '../biz.css'

const WhatWeDoModal = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.toggleOpen} className='modal-dialog-centered'>
                    <ModalHeader className="Biz_Rating_SECTION">
                        <h3>Add / Edit Summary Info</h3>
                    </ModalHeader>
                    <ModalBody>
                        {/* <FormGroup>
                            <Label className="Biz_Rating_sab_section">
                                <h3> Summary Heading</h3>
                            </Label>
                            <Input
                                id="exampleEmail"
                                name="catalogue"
                                placeholder="Enter your  Summary heading here"
                                type="catalogue"
                                maxLength={24}
                                
                            />
                            <p className='text-end mt-1 WataDo_limit_section'>Max 24 Characters</p>
                        </FormGroup> */}
                        <FormGroup>
                            <Label className='my-1 Biz_Rating_sab_section' ><h3> Summary Description</h3></Label>
                            <Input type='textarea'
                                cols="15"
                                rows="5"
                                placeholder="Enter your summary description here "
                                maxLength={180}
                            />
                            <p className='text-end mt-1 WataDo_limit_section'>Max 180 Characters</p>
                        </FormGroup>
                        <div className="text-center">
                            <Button onClick={props.toggleOpen} className="" outline>Cancel</Button>
                            <Button onClick={props.toggleOpen} color="primary " className=" ml-2 ">&nbsp;Save&nbsp;</Button>
                        </div>
                    </ModalBody>
                </Modal>
            }
        </Fragment>
    )
}

export default WhatWeDoModal