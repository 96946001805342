
import React from 'react'
import AdManagerSidebar from './AdManagerSidebar'

const Dashboard = () => {
  return (
    <>

      {/*------------------- Fist or Main component page------------------- */}
      <AdManagerSidebar />

    </>
  )
}

export default Dashboard