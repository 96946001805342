import React from 'react'
import { Fragment } from 'react'
import { XCircle } from 'react-feather'
import { ChevronRight, MoreHorizontal } from 'react-feather'
import { NavLink, Link } from 'react-router-dom'
import Slider from 'react-slick'
import AvatarGroup from '../../../NewComponents/AvatarGroup'
import { BizAllData } from './BizPageArray'
import PinSvg from '../Img/PinSvg.svg'
import { Button } from 'reactstrap'
import { Rating, Stack } from '@mui/material'
import './biz1.css'

//==============Start_Slider_settings==================//
let bizSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2
            }
        },

        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};
//==============end_Slider_settings==================//

const MySuggestedBiz = () => {
    return (
        <Fragment>
            <div className="group-custom-block mt-3">
                <div className="Biz-heading_section py-2 ">
                    <h2  className='bizhead bizpageheading'>Suggested Pages (61)</h2>
                    <NavLink to='/BizSuggested' className="single-ancor-blk d-flex align-items-center seeallbiz ">See All <ChevronRight size={16} /></NavLink>
                </div>
                <Slider {...bizSettings} className="default-space">
                    {
                        BizAllData.map((data) => (
                            <div className=''>
                                 <NavLink to="/BizDetail" >
                                <div className="BizPage_Card_section borderbiz " >
                                    <div className="BizPage_option_menu">
                                        <XCircle color='#FFFFFF' size={20} onClick={(e) => { e.preventDefault(); }}  />
                                    </div>
                                    <NavLink to="/BizDetail" className="bizpagelink-blk">
                                        <img src={data.img} className="img-fluid group-img" />
                                        <div className="group-adminprofile-blk"><img src="assets/images/post-6.jpg" className='rounded-circle' /></div>
                                    </NavLink>

                                    <NavLink to="/BizDetail" >
                                    <div className="group-content">
                                      
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <h4 className='CardTextHeading'>{data.cardName}  </h4>
                                        </div>
                                        <h5>{data.category}</h5>
                                        <div className='d-flex justify-content-center'>
                                            <Stack spacing={1}>
                                                <Rating name="read-only" defaultValue={2.5} readOnly />
                                            </Stack>
                                        </div>
                                        <div className="people-likes matual-friend-sec">
                                            <ul className="matual-friend-blk">
                                                <AvatarGroup />
                                            </ul>
                                            <h6>+79k Followers</h6>
                                        </div>
                                     
                                        <div className="BizPage-buttons-blk">
                                            {/* <Link to="#" className="group-btn green-clr-btn">Followed</Link>
                                            <Link to="#" className="group-btn">invite</Link> */}
                                            <Button outline color='primary' className=" BizBTN_section"  onClick={(e) => { e.preventDefault();  }}  >Follow</Button>
                                        </div>
                                       
                                    </div>
                                    </NavLink>
                                    

                                </div>
                                </NavLink>


                            </div>
                            
                        ))
                    }
                </Slider>
            </div>

        </Fragment>
    )
}

export default MySuggestedBiz