import React from "react";
import { Button } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getUserApproveFollowRequest } from "../../../Services/Actions/Connection/getUserApproveDisapprove";
import { useDispatch } from "react-redux";

export const AcceptModal = ({ request, openModal, openAcceptModal }) => {
  const dispatch = useDispatch();

  const handleAccept = (request) => {
    alert("accepted you");
    console.log("request================>", request);
    dispatch(getUserApproveFollowRequest(request));
    openAcceptModal();
  };

  return (
    <>
      {openModal !== false && (
        <Modal
          isOpen={openModal}
          toggle={openAcceptModal}
          className="modal-dialog-centered"
        >
          <ModalHeader className="d-flex justify-content-center bg-light">
            <p>Accept All Request</p>
          </ModalHeader>
          <ModalBody className="text-center my-2">
            <p className="name-style">Do you really want to Accept </p>
          </ModalBody>
          <ModalFooter className="d-flex border-0 justify-content-center w-100">
            <Button variant="outline-secondary" onClick={openAcceptModal}>
              <span className="ml-1 w-25">Cancel</span>
            </Button>
            <Button variant="primary" onClick={() => handleAccept(request)}>
              <span className="ml-1 w-25">Accept</span>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export const SingleAcceptModal = (props) => {
  const dispatch = useDispatch();
  const singleHandleAccept = (request) => {
    dispatch(getUserApproveFollowRequest(request));
    props.openSingleAcceptModal();
    props.setSingleAcceptConfirm(true);
  };
  return (
    <>
      {props.penModal !== false && (
        <Modal
          isOpen={props.openModal}
          toggle={props.openSingleAcceptModal}
          className="modal-dialog-centered"
        >
          <ModalHeader className="d-flex justify-content-center bg-light">
            <h2>Accept Request</h2>
          </ModalHeader>
          <ModalBody className="text-center my-2">
            <h5 className="name-style">Do you really want to Accept?</h5>
          </ModalBody>
          <ModalFooter className="d-flex border-0 justify-content-center w-100">
            <Button
              variant="outline-secondary"
              onClick={props.openSingleAcceptModal}
            >
              <span className="ml-1 w-25">Cancel</span>
            </Button>
            <Button variant="primary" 
              onClick={() =>{
                singleHandleAccept(props.request);
                }}
              >
              <span className="ml-1 w-25">Accept</span>
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
