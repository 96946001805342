import React, { Fragment, useState } from 'react'
import ModalPro from '../Img/Carousel.png'
import Carouse1 from '../Img/Carouse1.png'
import Carouse2 from '../Img/Carouse2.png'
import CoverModal from './TopBannerModal/CoverModal'


const BannerCarousel = () => {
    const [coverPicture, setCoverPicture] = useState(false)
    const toggleCover = () => {
        setCoverPicture((preView) => !preView)
    }

    const [data, setData] = useState(false)
    const [bizCover, setCover] = useState(ModalPro)
    const handleChangeCover = (e) => {
        console.warn(e.target.files)
        if (e.target.files.length !== 0) {
            setCover(URL.createObjectURL(e.target.files[0]))
        }
        setData(true)
    }


    //================= Main_return_function ======================//
    return (
        <Fragment>
            <div className='BizCover-top-block' >
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" >
                    <div class="carousel-inner" onClick={toggleCover}>
                        <div class="carousel-item active">
                            <img class="d-block w-100" src={bizCover} height="400px" alt="First slide" />
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src={Carouse1} height="400px" alt="Second slide" />
                        </div>
                        <div class="carousel-item">
                            <img class="d-block w-100" src={Carouse2} height="400px" alt="Third slide" />
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <div className="Bizgd-top-btns">
                    <div className="profile-cover profile-cover-new">
                        <a href="#" className="btn Bizbtn-solidUpload btn-group btn-grp-new">
                            <ul>
                                <li className="choose-file">
                                    <a className=' grey-btn' href="">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> Edit
                                    </a>
                                    <input type="file"
                                        onChange={handleChangeCover}

                                    />
                                </li>
                            </ul>
                        </a>
                    </div>
                </div>
            </div>
            <CoverModal
                isOpen={coverPicture}
                updateOpen={toggleCover}
                updateCover={bizCover}

            />
        </Fragment>
    )
}

export default BannerCarousel