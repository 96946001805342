import { Checkbox } from '@mui/material';
import React from 'react'
import { Fragment } from 'react'
import {  MessgaeData } from './NotificationArrayData'

const Message = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    //================== Main_return_function =============//
    return (
        <Fragment>
            <div className='SettingPrivacy_Card_Body'>
                { MessgaeData.map((data) => (
                    <div className='SettingPrivacy_Content_Section'>
                        <div className='SettingPrivacy_SubContent_Section '>
                            <div>
                                <h4>{data.heading}</h4>
                                <p>{data.subContent}</p>
                            </div>
                            <div>
                                <Checkbox {...label} defaultChecked className='Check_Input_section' />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default Message