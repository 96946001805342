import React from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import Models from '../Models';
import ProfileCover from './ProfileCover';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardImgOverlay, CardTitle, Col, Row } from 'reactstrap';
import { Bookmark } from 'react-feather';

const MyBookMarks = () => {
    const BookMarkCard = () => {
        return (
            <>
                <Row className="mt-3">
                    <Col lg="3">
                        <Card className="BookMark_Card_Section">
                            <img
                                src="assets/images/Group.png"
                                className="img-fluid" />
                            <div className='BookMark-card-Subdata'>
                                <p>Sun, 26 Jun 2022, 06:00PM</p>
                                <h4>Findy Global for highly skille...</h4>
                                <span>Bessemer, North Carolina</span>
                            </div>
                            <CardBody>
                                <CardImgOverlay>
                                    <CardTitle tag="h5">
                                        <div className='BookMark_Card_Box'>
                                            <Bookmark size={18} color="green" />
                                        </div>
                                    </CardTitle>
                                </CardImgOverlay>
                                <div className='BookMark_Button_section'>
                                    <p>Event</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="3">
                        <Card className="BookMark_Card_Section">
                            <img
                                src="assets/images/Group.png"
                                className="img-fluid" />
                            <div className='BookMark-card-Subdata'>
                                <p>Sun, 26 Jun 2022, 06:00PM</p>
                                <h4>Findy Global for highly skille...</h4>
                                <span>Bessemer, North Carolina</span>
                            </div>
                            <CardBody>
                                <CardImgOverlay>
                                    <CardTitle tag="h5">
                                        <div className='BookMark_Card_Box'>
                                            <Bookmark size={18} color="green" />
                                        </div>
                                    </CardTitle>
                                </CardImgOverlay>
                                <div className='BookMark_Button_section'>
                                    <p>Event</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </>
        )
    }
    const TextCardData = () => {
        return (
            <>
                <Row className="mt-3">
                    <Col lg="3">
                        <Card className="BookMarkText_Card_Section">
                            <div className='TextCenter_section'>
                                <div className='BookMark_Card_Box'>
                                    <Bookmark size={18} color="green" />
                                </div>
                                <div className='BookMarkText_Card_box mt-1'>
                                    <p>The Homeland Security Department failed to fully vet some of the Afghan evacuees it brought into the U.S. during last year’s airlift, the department’s inspector general said in a devastating audit, which warned that so...</p>
                                </div>
                            </div>
                            <CardBody>
                                <div className='BookMark_Button_section'>
                                    <p>Text</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
    
    //-------- Main_return_function
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    {/* <ProfileCover /> */}
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="gallery-page-section section-b-space">
                            <div className="card-title">
                                <h3>BookMark (162)</h3>
                                <div className="right-setting">
                                    <div className="profile-search-blk ">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                    </div>
                                    <NavLink to="/MyMediaListView" className="btn btn-outline-grey ms-3"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg> Filter</NavLink>
                                </div>
                            </div>
                            <BookMarkCard />
                            <TextCardData />
                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>

        </>
    )
}

export default MyBookMarks