import React, { Fragment, useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

const MpCheckBoxFilter = ({ data, setAttributeVal }) => {
    const [activeStates, setActiveStates] = useState({});
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

    const handleCheckboxChange = (item, value) => {
        setActiveStates((prevStates) => ({
            ...prevStates,
            [item]: !prevStates[item],
        }));

        setCheckedCheckboxes((prevStates) => {
            if (prevStates.includes(item)) {
                return prevStates.filter((i) => i !== item);
            } else {
                return [...prevStates, item];
            }
        });

        setAttributeVal((prevValues) => ({
            ...prevValues,
            checkBoxValues: checkedCheckboxes.includes(item)
                ? prevValues.checkBoxValues.filter((v) => v !== value)
                : [...prevValues.checkBoxValues, value]
        }));
    };

    const handleClearAll = (category) => {
        const newActiveStates = { ...activeStates };
        const newCheckedCheckboxes = [...checkedCheckboxes];
        
        category.AttributeValues.forEach((attributeVal) => {
            delete newActiveStates[attributeVal.id];
            const index = newCheckedCheckboxes.indexOf(attributeVal.id);
            if (index !== -1) {
                newCheckedCheckboxes.splice(index, 1);
            }
        });

        setAttributeVal((prevValues) => ({
            ...prevValues,
            checkBoxValues: prevValues.checkBoxValues.filter(
                (v) => !category.AttributeValues.some((attributeVal) => attributeVal.value === v)
            )
        }));

        setActiveStates(newActiveStates);
        setCheckedCheckboxes(newCheckedCheckboxes);
    };

    return (
        <Fragment>
            {data.length > 0 && data.map((category, index) => (
                <AccordionBody key={index} style={{ borderBottom: "1px solid #E5E5E5" }}>
                    <div className="MpUploadHeading d-flex justify-content-between align-items-center">
                        <h3 style={{ fontWeight: "600" }}>{category?.label}</h3>
                        <h5 className='MrktClearAllBtn' onClick={() => handleClearAll(category)} style={{ cursor: "pointer", color: "#81c14b" }}>
                            Clear All
                        </h5>
                    </div>
                    <div className='d-flex flex-column align-items-start justify-content-start' style={{ padding: "1rem .25rem" }}>
                        {category?.AttributeValues && category.AttributeValues.map((attributeVal) => (
                            <div key={attributeVal?.id} className="MrktPlaceCheckbox">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                                color: "#cbcbcb",
                                                '&.Mui-checked': {
                                                    color: "#81c14b",
                                                },
                                            }}
                                            size="small"
                                            checked={activeStates[attributeVal?.id] || false}
                                            onChange={() => handleCheckboxChange(attributeVal?.id, attributeVal?.value)}
                                        />
                                    }
                                    label={attributeVal?.value}
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px', color: "#767676" } }}
                                />
                            </div>
                        ))}
                    </div>
                </AccordionBody>
            ))}
        </Fragment>
    );
};

export default MpCheckBoxFilter;
