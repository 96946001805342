import React, { useEffect, useState } from 'react'
import { Copy, CornerLeftDown, Flag, MoreVertical, Share } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledButtonDropdown } from 'reactstrap';
import group_profile from '../../../NewComponents/IMG/group_profile.png'
import { AllUserSentRequest } from '../../../Services/Actions/Connection/getUserSentRequestAction';
import { Grid, List, Search } from 'react-feather'
import { Button, InputGroup } from 'react-bootstrap';
import { GroupListView } from '../ListView/GroupListView';
import  BlockUserModal  from '../HoverComponents/BlockUserModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import { GroupMembers } from '../HoverComponents/GroupMembers';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';
import { NavLink } from 'react-router-dom';

export const GroupCard = ({requests}) => {
    const { userSentRequests } = useSelector((state) => state.userSentRequestsData);
    const dispatch = useDispatch();
    
    const [Gridview, Setgridview] = useState(false)
    useEffect(() => {
        dispatch(AllUserSentRequest())
    }, [])

    // ------------- Share Modal open -------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    //  ------------- Start ReportedOption -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // mouse on hover components state
    const [mouseHover, setMouseHover] = useState(null)

   

    return (<>
        <div className="mppage-heading mt-4">
            <div className="group-custom-block">
                <div className='nav-flex-container'>
                    <h3 className='connection-sub-heading'>Requested Group ({requests.length})</h3>
                    <div className='sub-nav-flex size-sm-mt-4'>
                        <InputGroup className="input-group-merge d-flex connection-search-style mr-2">
                            <Search color='#666666' size={16} />
                            <Input type='search' placeholder="  Find..." className='coonection-input-style' />
                        </InputGroup>
                        <div className='list-grid-toggle size-xs-mt-4 rem-mob-view'>
                            <Button className={Gridview ? 'grid-toggle-customize-css ' : ''} outline onClick={() => Setgridview(false)}>
                                <Grid size={16} />
                            </Button>
                            <Button className={Gridview ? ' ' : 'grid-toggle-customize-css'} outline onClick={() => Setgridview(true)}>
                                <List size={16} />
                            </Button>
                        </div>
                    </div>
                </div>
                {Gridview ?
                    <GroupListView requests={requests}/>
                    :
                    <Row className='connections_PeopleThisGrid mt-3'>
                        {
                            requests && requests.map((request, index) => {
                                return <Col lg='4' className='w-100'>
                                    <div key={index} className="connection-card box-shadow-style  content-mp-block d-flex justify-content-between">
                                        <div className="media-body d-md-block">
                                            <div class="media media-new d-sm-flex">
                                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                                    <img src={request?.profileImage} className="rounded-circle" height={45} width={45} alt="shivam singh" />
                                                    <span class="available-stats online"></span>
                                                    {/*<PublicAccount request ={request}/>*/}
                                                </div>
                                                <div class="media-body d-md-block pl-1">
                                                    <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate'>{request?.name?.substring(0, 21) || 'NA'}</h4></NavLink>
                                                    <p className='user-name-style'>{request?.categoryName?.substring(0, 21) || 'NA'}</p>
                                                </div>
                                            </div>
                                            <div className='ml-2 mt-1'>
                                                <GroupMembers groupMembersList={request?.mutualfriendList}/>
                                                {/*<p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '160px' }}>This text is a one line description</p>*/}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-end flex-column'>
                                            <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                                <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                                    <MoreVertical size={22} />
                                                </DropdownToggle>
                                                <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><CornerLeftDown size={15} className='mr-2' />Withdraw Request</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Group</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Group URL</DropdownItem>
                                                    {/*<div className='border-bottom py-2 menu-hover-style'>
                                                        <BlockUserModal
                                                            btnName='Block Group' />
                                                    </div>*/}
                                                    <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                        <Flag size={15} className='mr-2' />Report Group
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <div role="button" className='mt-3 d-flex align-items-end'>
                                                <Button color='flat-success' className='btn-transparent pr-0 pb-0'>
                                                    <CornerLeftDown size={15} color='#2F80ED' />&nbsp;<span className='connection-blue-text rem-mob-view'>Withdraw</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                        {
                            requests.length <= 0 && <h3 className='text-center connection-sub-heading'>No Request Sent</h3>
                        }
                    </Row>
                }
            </div>
        </div>
        {/*  ------------------ Add Report Option  ------------------ */}
        <ConnectionReportModal
            modalOpen={reportOpen}
            reportModalOpen={toggleReport}
        />
        {/* ------------------ Start Share Modal  ------------------*/}
        <ConnectionShareModal
            openModal={shareOpen}
            shareModalOpen={toggleShareOpen}
        />
    </>)
}

