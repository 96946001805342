import React, { Fragment, useState } from 'react'
import { Button, Modal } from 'reactstrap'
import { LikeViewStory } from "../StoryArrayDummy/StoryDummy"
// import FlwrImg from '../../Events-Components/Img/FlwrImg.png'
import { Circle, X } from 'react-feather'
import { Tab, Tabs } from '@mui/material'


const StoryLikeAndView = ({ isOpen, toggleModal }) => {


    // data array 
    const dummyData = [LikeViewStory, LikeViewStory]


    const [active, setActive] = useState(0)

    const toggleTab = (e, tab) => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const TabsViewModal = [
        "45 Viewer", "27 Likes"

    ]



    return (
        <Fragment>
            <Modal scrollable isOpen={isOpen} toggle={toggleModal} className="modal-dialog-centered">

                <div className='w-100 border-bottom'>
                    <div className='mx-3 py-4 Profession_Modal_section'>
                        <h4>Story Details</h4>
                        <p onClick={toggleModal}><X size={15} /></p>
                    </div>
                </div>

                <div className="AboutModal_Main_section ">
                    <Tabs
                        value={active}
                        onChange={toggleTab}
                        variant="scrollable"
                        scrollButtons={true}
                        aria-label="scrollable prevent tabs example"
                        TabIndicatorProps={{ style: { background: "#81c14b" } }}
                    >
                        {TabsViewModal.map((tabs) => (
                            <Tab sx={{
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                                '&.Mui-selected': {
                                    color: "#81c14b",
                                },
                            }} label={tabs} />
                        ))}

                    </Tabs>
                </div>
                <div className="MpViwedCountings p-2 px-4">

                    {/* <h6 className='Mp_Color80_Font5'>{TabsViewModal[active]} by 12</h6> */}


                </div>

                <div className='Modal_scroll_box p-2'>
                    {dummyData[active].map((data) => (
                        <div className="group-detail-page-blk">
                            <div className="MyEvents-memberslist-block">
                                <div className="group-member-img-cont-blk">
                                    <img src={data.userImgView} alt="img" className='img-fluid rounded-circle' />
                                    <div className="member-cont-blk">
                                        <h5>{data.viewUserName}</h5>
                                        {/* <p className=''>@sharmaG</p> */}
                                        <div className=" d-flex align-items-center mt-1  ">
                                            <div className='d-flex '>
                                                <h6 className="text-muted">{data.viewStoryTime}</h6>&nbsp;
                                                {/* <span>Followers</span> */}
                                            </div>
                                            {/* &nbsp;&nbsp;<Circle fill='#C4C4C4' color='#C4C4C4' size={6} />&nbsp;&nbsp;
                                            <div className=' d-flex '>
                                                <h4>{data.post} </h4> &nbsp;
                                                <span>Posts</span>
                                            </div> */}
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <Button outline color='primary' className="btn-sm button-text w-25 ">{data.btnName}</Button>
                            </div>
                        </div>
                    ))
                    }
                </div>

            </Modal>
        </Fragment>
    )
}

export default StoryLikeAndView
