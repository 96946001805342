import { Book, BookOpen, Table, Truck } from "react-feather";
import Sprt from '../Img/sport.png'

export const HobbiesDataOption = [
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Reading ",
        name1: "Photography",
        icon1: <BookOpen size={16} color="#4D4D4D" />,
        icon3: <Table size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Travle",
        name1: "Books",
        icon1: <Truck size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />

    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Reading ",
        name1: "Reading",
        icon1: <Table size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Movies ",
        name1: "Gaming",
        icon1: <BookOpen size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Gaming ",
        name1: "Travelling",
        icon1: <Truck size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    },
    {
        icon: <Book color='#FFFFFF' size={22} />,
        name: "Music ",
        name1: "Director",
        icon1: <Table size={16} color="#4D4D4D" />,
        icon3: <Book size={16} color="#FFFFFF" />
    }

]
export const SliderData = [
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Mughal-e-Aazam",
        musicName: "AR Rahman",
        bookName: "The Great Gatsby"

    },
    {
        img: Sprt,
        sportName: "Cricket",
        movieName: "Shole",
        musicName: "Michael Jackson",
        bookName: "Alice's Adventure in Wonderland"

    },
    {
        img: Sprt,
        sportName: "Hockey",
        movieName: "PK",
        musicName: "Kishan Maharaj",
        bookName: "Adventures of Huckleberry Finn"

    },
    {
        img: Sprt,
        sportName: "Golf",
        movieName: "Raees",
        musicName: "Ustad Bismillah Khan",
        bookName: "Pride and Prejudice"
    },
    {
        img: Sprt,
        sportName: "Cricket",
        movieName: "Shaun of the Dead",
        musicName: "Aasha Bhosle",
        bookName: "The Catcher in the Rye."
    },
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Dil Wale Dulhaniya le jaye ge",
        musicName: "Lata Mangeshkar",
        bookName: "Ulysses"
    },
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Dil Wale Dulhaniya le jaye ge",
        musicName: "Lata Mangeshkar",
        bookName: "Ulysses"
    },
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Dil Wale Dulhaniya le jaye ge",
        musicName: "Lata Mangeshkar",
        bookName: "Ulysses"
    },
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Dil Wale Dulhaniya le jaye ge",
        musicName: "Lata Mangeshkar",
        bookName: "Ulysses"
    },
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Dil Wale Dulhaniya le jaye ge",
        musicName: "Lata Mangeshkar",
        bookName: "Ulysses"
    },
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Dil Wale Dulhaniya le jaye ge",
        musicName: "Lata Mangeshkar",
        bookName: "Ulysses"
    },

]
export const SilderDataOption = [
    {
        img: Sprt,
        sportName: "Footboll",
        movieName: "Mughal-e-Aazam"
    },
    {
        img: Sprt,
        sportName: "Cricket",
        movieName: "Kamfu-panda"
    },
    {
        img: Sprt,
        sportName: "Khokho",
        movieName: "One Upon times a  Mumbai"
    }
]