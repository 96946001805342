import React, { useEffect, useState,useCallback } from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row, UncontrolledButtonDropdown,DropdownItem,
    DropdownMenu,
    DropdownToggle, Modal,ModalBody,ModalHeader,Button,ModalFooter } from 'reactstrap';
import {Sliders,Eye,Bell} from "react-feather"
import { BellOff, Edit2, EyeOff, Link2, MessageCircle, MoreHorizontal, Send, Trash2 } from 'react-feather';
import like2 from '../../NewComponents/IMG/like2.svg'
import { Pagination, Stack, PaginationItem } from '@mui/material';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTextPostsAction,
    postShowAction,
    hideAllPostsFromTimelineAction,
    deletePostAction,
    shareShotzAction,
    turnOnOffNotificationTextPostsAction,} from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import TextData from './TextModule/TextData';
import { sweetAlertConfirmation } from "../../components/group-components/AllGroupsSections/SweetAlert";
import CreationMainSection from '../../components/Social-feed/CreationMainSection';
import DiscaedModal from '../../components/Social-feed/DiscaedModal';
import debounce from 'lodash/debounce';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';

const MyText = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));

    let dispatch = useDispatch();
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize,setPageSize]=useState(12);
    const[searchKey,setSearchKey]=useState("");
    const [allCount,setAllCount]=useState(0);
    // const [thoughtCount,setThoughtCount]=useState(0);
    // const [textCount,setTextCount]=useState(0);
    // const [alertCount,setAlertCount]=useState(0);
    const [countError,setCountError]=useState('');
    // const [apiResponse,setApiResponse]=useState('');


    useEffect(() => {
      const fetchData = async () => {
          try {
              let user = JSON.parse(localStorage.getItem('sociomeeUser'));
              if (user) {
                  const response = await axios.post(
                      `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                      { postType:"text", pageSize:5, pageIndex:0, searchKey:''},
                      { headers: { Authorization: `Bearer ${user.token}` } }
                  );
                  setAllCount(response.data.data.successResult.count);
                  // setApiResponse(response.data.data.successResult);
              } else {
                setCountError('no user found');
              }
          } catch (error) {
            setCountError(error);
          }
      };
    
      fetchData();
    }, []);

    useEffect(() => {
        dispatch(getTextPostsAction('text', pageSize, pageIndex,searchKey));
    }, [pageSize, pageIndex,searchKey]) 
    
    const [textPostsSearch, setTextPostsSearch] = useState('')  
    const getTextsPosts = useSelector((state) => { return state.getTextPostsData });
    const [connectionName, setConnectionName] = useState(0);
    const [paginationCount, setPaginationCount] = useState(10);
    
    
    const { loading: loadingAllTextPosts, error: errorAllTextPosts, result: resultAllTextPosts } = getTextsPosts
   

    const filteredData = resultAllTextPosts?.filter(item =>
        item?.caption?.toLowerCase().includes(textPostsSearch?.toLowerCase()));

    const getConnectionName = (conName) => {
            // alert(conName)
        setConnectionName(conName);
    };
    
  const [success, setSuccess] = useState(false);

    const showHiddenPost = (id) => {
        dispatch(postShowAction(id));
        setSuccess(true);
        sweetAlertConfirmation("successful")
      };

    // const BasicsPagination = () => (
    //     <Fragment>
    //       <Stack>
    //         <Pagination
    //           count={Math.ceil(allCount / paginationCount)}
    //           className="PaginationCounts"
    //           page={pageIndex + 1}
    //           onChange={(event, page) => setPageIndex(page - 1)}
    //         />
    //       </Stack>
    //     </Fragment>
    //   );

    const pageCount = Math.ceil(allCount/pageSize);
const isPrevDisabled = pageIndex <= 0;
const isNextDisabled = pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === pageIndex) return;

    setPageIndex(value - 1);
  };

      const BasicsPagination = () => {
        return (
          <>
            <Stack>
              <Pagination
                   count={pageCount}
                   page={pageIndex + 1}
                   onChange={handlePageChange}
                   className="PaginationCounts"
                   renderItem={(item) => (
                     <PaginationItem
                       {...item}
                       disabled={
                         (item.type === 'previous' && isPrevDisabled) ||
                         (item.type === 'next' && isNextDisabled)
                       }
                     />
                   )}
              ></Pagination>
            </Stack>
          </>
        );
      };

      const hidePost = (id) => {
        dispatch(hideAllPostsFromTimelineAction(id));
        setSuccess(true);
        sweetAlertConfirmation("successfully hided")
      };
      const editText = (id) => {
        alert("Design not available");
      };

      const deleteTextPost = (postId) => {
        dispatch(deletePostAction(postId));
        sweetAlertConfirmation("Post Deleted successfully");
        setSuccess(true);
      };

      const shareTextPost = async (id) => {
        dispatch(shareShotzAction(id));
        sweetAlertConfirmation("Post shared successfully");
        setSuccess(true);
      };

      
      let [copyLink, setCopyLink] = useState("");
      const postCopyLink = async (id) => {
        copyLink = `${process.env.REACT_APP_IPURL}/MyText/${id}`;
        setCopyLink(copyLink);
        navigator.clipboard.writeText(copyLink);
        sweetAlertConfirmation(`Link Copied`);
      };

      const [expandedPosts, setExpandedPosts] = useState({});

      const notificationOff = (postId) => {
        dispatch(turnOnOffNotificationTextPostsAction(postId, false));
        setSuccess(true);
      };

      const notificationOn = (postId) => {
        dispatch(turnOnOffNotificationTextPostsAction(postId, true));
        setSuccess(true);
        sweetAlertConfirmation("successfully turned off notifications")
      };


      const filteredDataThought = resultAllTextPosts?.filter(
        (element) => element.postType == "thought"
      );

      const filteredDataAlert = resultAllTextPosts?.filter(
        (element) => element.postType == "alert"
      )


      const toggleExpand = (postId) => {
        setExpandedPosts((prev) => ({
          ...prev,
          [postId]: !prev[postId]
        }));
        // setPostOpen(true);
      };

      const [readMoreStates, setReadMoreStates] = useState({});
    

    const renderCaption = (caption, postId) => {
      if (!caption) {
          return null; // or return an empty string, or any default content you want to render when caption is undefined
      }
  
      const isReadMore = !readMoreStates[postId];
      const words = caption.split(' ');
  
      if (isReadMore) {
          return (
              <>
                  {words.slice(0, 10).join(' ')}
                  {words.length > 10 && (
                      <span>
                          ...{" "}
                          {/* <span
                              className="text-success"
                              style={{ cursor: 'pointer' }}
                              onClick={() => toggleReadMore(caption)}
                          >
                              Read more
                          </span> */}
                      </span>
                  )}
              </>
          );
      }
    }

  const handleRedirect = (path) => {
    navigate(path, {
      state: { userId, type:"viewer" }
    });
  };
  

  const [activeTab, setActiveTab] = useState(1000);
  const states=activeTab;
  const [postType, setPostType] = useState("event");
  const [currState,setCurrState]=useState(0)
  const [modal, setModal]=useState('')
  const [selectedImages,setSelectedImages]=useState('')
  const [path, setPath] = useState("/modal/");
  const [discard, setDisCard] = useState(false);
  const [feelingCategory,setFeelingCategory]=useState("");


  // =================create text==================

  const toggleDiscard = () => {
      setDisCard((preState) => !preState);
    };
    const toggleAllDiscard = () => {
      setModal(false);
      toggleDiscard(false);
  
    };

  const toggle = (path) => {

      console.log(path.indexOf("/modal/"),"   path.indexof(`modal`)")
      if (path.indexOf("/modal/" > -1)) {
        if (modal) {
          setActiveTab(parseFloat(path.replace("/modal/", "")));
      
          setPath(path);
         
          const index = parseFloat(path.replace("/modal/", ""));
        console.log("in toggle function",index)
          const postType ="event"
         
        } else {
        console.log("in toglle mode elese sections")
        const index = parseFloat(path.replace("/modal/", ""));
        
        
          setActiveTab(parseFloat(path.replace("/modal/", "")));
          setModal(true);
          setPath(path);
        
        }
      } else {
  
        setModal(false);
      }
    };

    const debouncedSetSearchKey = useCallback(
          debounce((value) => {
              setSearchKey(value);
          }, 3000),
          [] 
      );

    const handleInputChange = (value) => {
      debouncedSetSearchKey(value);
    };
  

    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    <Fragment>
                        <div className="container-fluid section-t-space px-0">
                            <div className="page-content">
                                <div className="content-center w-100">
                                    <div className="gallery-page-section section-b-space">
                                        <div className="card-title">
                                            <h3>
                                            {connectionName === 0
                                            ? `All Text (${allCount ? allCount : '0'})`
                                            : connectionName === 1
                                            ? `Thought (${filteredDataThought?.length ? filteredDataThought.length : '0'})`
                                            : connectionName === 2
                                            ? `Alert (${filteredDataAlert?.length ? filteredDataAlert.length : '0'})`
                                            : ''}
                                            </h3>
                                            <div className="right-setting">
                                                <div className="profile-search-blk ">
                                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="search" placeholder="Find..."
                                                      data-bs-toggle="modal" data-bs-target="#peopleSearch"
                                                      onChange={(e) => { handleInputChange(e.target.value) }} />
                                                </div>
                                                {/* <button className="btn btn-outline-green ms-3">Filter</button> */}
                                                <UncontrolledButtonDropdown color="secondary" outline>
                                              <DropdownToggle
                                                outline
                                                className="border border-muted ml-3 "
                                              >
                                                <Sliders className="Slider_Filter" size={12} />
                                                &nbsp;&nbsp;Filter
                                              </DropdownToggle>
                                              <DropdownMenu className="DropDown_item">
                                                <DropdownItem onClick={() => getConnectionName(0)}>
                                                  All
                                                </DropdownItem>
                                                {/* <DropdownItem onClick={() => getConnectionName(1)}>
                                                  Text
                                                </DropdownItem> */}
                                                <DropdownItem onClick={() => getConnectionName(1)}>
                                                  Thought
                                                </DropdownItem>
                                                <DropdownItem onClick={() => getConnectionName(2)}>
                                                  Alert
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                              <Link onClick={() => {
                                                setModal(true);
                                                console.log(modal)
                                                toggle("/modal/0.1");
                                              }} className="btn btn-outline-green ms-3">Create Post</Link>
                                            </div>
                                        </div>
                                        {/* <TextData connectionName={connectionName}/>  */}
                                        {/* <TextData filteredData={textPostsSearch}/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-section">
            <Row>
            {connectionName == 0 &&
                resultAllTextPosts?.map((data, index) => (
                    <Col lg="4" className="mb-4" key={data.postId}>
                        <Card
                            className={
                                (data?.alertLevel?.name
                                    ? data?.alertLevel?.name === "Severe"
                                    : "")
                                    ? "Severe_Text_Section post-card"
                                    : data?.alertLevel?.name
                                    ? data?.alertLevel?.name === "Moderate"
                                        ? "Mordreate_Text_Section post-card"
                                        : "Mild_Text_Section post-card"
                                    : "Text_Section post-card"
                            }
                        >
                            <CardBody>
                                <div className="TextLeft_Top">
                                    <p>{data?.postType == 'text' ? 'Text' :
                                        data?.postType == 'thought' ? 'Thought' :
                                        data?.postType == 'alert' ? 'Alert' : ''} </p>
                                </div>
                                <div
                                    className={
                                        data?.alertLevel?.name === "Severe"
                                            ? "Severe_TextRight_Top"
                                            : data?.alertLevel?.name === "Moderate"
                                            ? "Mordreate_TextRight_Top"
                                            : data?.alertLevel?.name === "Mild"
                                            ? "Mlid_TextRight_Top"
                                            : "Text_TextRight_Top"
                                    }
                                >
                                    <MoreHorizontal
                                        style={{ cursor: "pointer" }}
                                        color="#4D4D4D"
                                        size={20}
                                        className="dropbtn-gal"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    />
                                    <div
                                        className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Text"
                                        style={{ minWidth: "225px", fontSize: "13px" }}
                                    >
                                        <ul>
                                            <li style={{ cursor: "pointer" }}>
                                                {data.hidden ? (
                                                    <span
                                                        onClick={() => {
                                                            showHiddenPost(data.postId);
                                                        }}
                                                    >
                                                        <Eye size={15} color="#979797" /> &nbsp;&nbsp;
                                                        Show post from timeline
                                                    </span>
                                                ) : (
                                                    <span
                                                        onClick={() => {
                                                            hidePost(data.postId);
                                                        }}
                                                    >
                                                        <EyeOff size={15} color="#979797" /> &nbsp;&nbsp; Hide post from timeline
                                                    </span>
                                                )}
                                            </li>
                                            <li onClick={editText}>
                                                <Link to="#">
                                                    <Edit2 color="#808080" size={16} /> Edit{" "}
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    deleteTextPost(data.postId);
                                                }}
                                            >
                                                <Link to="#">
                                                    <Trash2 color="#808080" size={16} /> Delete{" "}
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    shareTextPost(data?.postId);
                                                }}
                                            >
                                                <Link to="#">
                                                    <Send color="#808080" size={16} /> Share
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    postCopyLink(data?.postId);
                                                }}
                                            >
                                                <Link to="#">
                                                    <Link2 color="#808080" size={16} /> Copy Link{" "}
                                                </Link>
                                            </li>
                                            {data?.notificationOff ? (
                                                <li
                                                    onClick={() => {
                                                        notificationOff(data?.postId);
                                                    }}
                                                >
                                                    <Link to="#">
                                                        <BellOff color="#808080" size={16} /> Turn off notification
                                                    </Link>
                                                </li>
                                            ) : (
                                                <li
                                                    onClick={() => {
                                                        notificationOn(data?.postId);
                                                    }}
                                                >
                                                    <Link to="#">
                                                        <Bell color="#808080" size={16} /> Turn on notification
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </CardBody>
                            <div className="ml-2 mt-3" onClick={() => handleRedirect(`/MyPostDetail/${data.postId}`)}>
                                <div className="Mordreate_SubText_part">
                                    {data?.postType === "alert" && (
                                        <span
                                            className="pl-1 pr-2 pt-1 pb-1"
                                            style={{
                                                backgroundColor: `${data?.alertLevel?.tagBackColor || data?.alertLevel?.name === "Severe"
                                                    ? data.alertLevel.tagBackColor
                                                    : ""
                                                    }`,
                                                color: `${data?.alertLevel?.tagForeColor}`,
                                                borderTopLeftRadius: "10px",
                                                borderBottomRightRadius: "10px",
                                                fontSize: "11px",
                                            }}
                                        >
                                            ● {data?.alertLevel?.name}
                                        </span>
                                    )}
                                </div>

                                <div className="Text_Center_part">
                                    <p className="ml-2 mt-2" style={{ minHeight: "50px" }}>
                                        {renderCaption(data.caption, data.postId)}
                                    </p>
                                    <hr className="mx-3" />
                                </div>
                            </div>

                            <div className="d-flex justify-content-evenly text-center Recommendation_Bottom_section mb-2">
                                <div className="Recommendation_btn">
                                    <p className=" m-2 ">
                                        <img
                                            src={like2}
                                            className="RecommendationImg img-fluid"
                                            alt="Like"
                                        />
                                    </p>
                                    <h4>{data?.likesCount}</h4>
                                </div>
                                <div className="Recommendation_btn">
                                    <p className=" m-2 ">
                                        <MessageCircle size={20} color="#999999" />
                                    </p>
                                    <h4>{data?.commentsCount}</h4>
                                </div>
                                <div className="Recommendation_btn">
                                    <p className=" m-2">
                                        <Send size={20} color="#999999" />
                                    </p>
                                    <h4>{data?.sharesCount}</h4>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}

             {connectionName == 1 &&
                filteredDataThought?.map((data, index) => (
                    <Col lg="4" className="mb-4" key={data.postId}>
                        <Card
                            className={data?.className}
                            style={{
                                backgroundColor: `${data.thoughtBackColor}`,
                                color: `${data.thoughtForeColor}`,
                            }}
                        >
                            <CardBody>
                                <div className="TextLeft_Top" style={{ fontSize: "20px" }}>
                                <p>{data?.postType == 'thought' ? 'Thought' : ''}</p>
                                </div>
                                <div className="Severe_TextRight_Top">
                                    <MoreHorizontal
                                        style={{ cursor: "pointer" }}
                                        color="#4D4D4D"
                                        size={20}
                                        className="dropbtn-gal"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    />
                                    <div
                                        className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Text"
                                        style={{ minWidth: "225px", fontSize: "13px" }}
                                    >
                                        <ul>
                                            <li style={{ cursor: "pointer" }}>
                                                {data.hidden ? (
                                                    <span
                                                        onClick={() => {
                                                            showHiddenPost(data.postId);
                                                        }}
                                                    >
                                                        <Eye size={15} color="#979797" /> &nbsp;&nbsp;
                                                        Show post from timeline
                                                    </span>
                                                ) : (
                                                    <span
                                                        onClick={() => {
                                                            hidePost(data.postId);
                                                        }}
                                                    >
                                                        <EyeOff size={15} color="#979797" /> &nbsp;&nbsp; Hide post from timeline
                                                    </span>
                                                )}
                                            </li>
                                            <li onClick={editText}>
                                                <Link to="#">
                                                    <Edit2 color="#808080" size={16} /> Edit{" "}
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    deleteTextPost(data.postId);
                                                }}
                                            >
                                                <Link to="#">
                                                    <Trash2 color="#808080" size={16} /> Delete{" "}
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    shareTextPost(data?.postId);
                                                }}
                                            >
                                                <Link to="#">
                                                    <Send color="#808080" size={16} /> Share
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    postCopyLink(data?.postId);
                                                }}
                                            >
                                                <Link to="#">
                                                    <Link2 color="#808080" size={16} /> Copy Link{" "}
                                                </Link>
                                            </li>
                                            {data?.notificationOff ? (
                                                <li
                                                    onClick={() => {
                                                        notificationOff(data?.postId);
                                                    }}
                                                >
                                                    <Link to="#">
                                                        <BellOff color="#808080" size={16} /> Turn off notification
                                                    </Link>
                                                </li>
                                            ) : (
                                                <li
                                                    onClick={() => {
                                                        notificationOn(data?.postId);
                                                    }}
                                                >
                                                    <Link to="#">
                                                        <Bell color="#808080" size={16} /> Turn on notification
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </CardBody>
                            <div className="ml-2 mt-3" onClick={() => handleRedirect(`/MyPostDetail/${data.postId}`)}>
                                <div className="Text_Center_part">
                                    <p className="ml-2 mt-2" style={{ minHeight: "70px" }}>
                                        {renderCaption(data.caption, data.postId)}
                                    </p>
                                    <hr className="mx-3" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-evenly text-center Recommendation_Bottom_section mb-2">
                                <div className="Recommendation_btn">
                                    <p className=" m-2 ">
                                        <img
                                            src={like2}
                                            className="RecommendationImg img-fluid"
                                            alt="Like"
                                        />
                                    </p>
                                    <h4>{data.likesCount}</h4>
                                </div>
                                <div className="Recommendation_btn">
                                    <p className=" m-2 ">
                                        <MessageCircle size={20} color="#999999" />
                                    </p>
                                    <h4>{data.commentsCount}</h4>
                                </div>
                                <div className="Recommendation_btn">
                                    <p className=" m-2">
                                        <Send size={20} color="#999999" />
                                    </p>
                                    <h4>{data.sharesCount}</h4>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
              {connectionName==2 && 
            filteredDataAlert?.map((data, index) => (
            <Col lg="4" className="mb-4" key={data.postId}>
              <Card
                className={data?.className}
                style={{
                  backgroundColor: `${data.alertBackColor}`,
                  color: `${data.alertForeColor}`,
                }}
              >
                <CardBody>
                  <div className="TextLeft_Top" style={{ fontSize: "20px" }}>
                    <p>{data?.postType == 'alert' ? 'Alert' : ''}</p>
                  </div>
                  <div
                    className={
                      data?.alertLevel?.name === "Severe"
                        ? "Severe_TextRight_Top"
                        : data?.alertLevel?.name === "Moderate"
                        ? "Mordreate_TextRight_Top"
                        : data?.alertLevel?.name === "Mild"
                        ? "Mlid_TextRight_Top"
                        : "Text_TextRight_Top"
                    }
                  >
                    <MoreHorizontal
                      style={{ cursor: "pointer" }}
                      color="#4D4D4D"
                      size={20}
                      className="dropbtn-gal"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                    <div
                      className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Text"
                      style={{ minWidth: "225px", fontSize: "13px" }}
                    >
                      <ul>
                        <li style={{ cursor: "pointer" }}>
                          {data.hidden ? (
                            <span
                              onClick={() => {
                                showHiddenPost(data.postId);
                              }}
                            >
                              <Eye size={15} color="#979797" /> &nbsp;&nbsp;
                              Show post from timeline
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                hidePost(data.postId);
                              }}
                            >
                              <EyeOff size={15} color="#979797" /> &nbsp;&nbsp;
                              Hide post from timeline
                            </span>
                          )}
                        </li>
                        <li onClick={editText}>
                          <Link to="#">
                            <Edit2 color="#808080" size={16} /> Edit{" "}
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            deleteTextPost(data.postId);
                          }}
                        >
                          <Link to="#">
                            <Trash2 color="#808080" size={16} /> Delete{" "}
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            shareTextPost(data?.postId);
                          }}
                        >
                          <Link to="#">
                            <Send color="#808080" size={16} /> Share
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            postCopyLink(data?.postId);
                          }}
                        >
                          <Link to="#">
                            <Link2 color="#808080" size={16} /> Copy Link{" "}
                          </Link>
                        </li>
                        {data?.notificationOff ? (
                          <li
                            onClick={() => {
                              notificationOff(data?.postId);
                            }}
                          >
                            <Link to="#">
                              <BellOff color="#808080" size={16} /> Turn off
                              notification
                            </Link>
                          </li>
                        ) : (
                          <li
                            onClick={() => {
                              notificationOn(data?.postId);
                            }}
                          >
                            <Link to="#">
                              <Bell color="#808080" size={16} /> Turn on
                              notification
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
                <div className="ml-2 mt-3" onClick={() => handleRedirect(`/MyPostDetail/${data.postId}`)}>
                  <div className="Mordreate_SubText_part">
                    {data?.postType === "alert" && (
                      <span
                        className="pl-1 pr-2 pt-1 pb-1"
                        style={{
                          backgroundColor: `${
                            data?.alertLevel?.tagBackColor ||
                            data?.alertLevel?.name === "Severe"
                              ? data.alertLevel.tagBackColor
                              : ""
                          }`,
                          color: `${data?.alertLevel?.tagForeColor}`,
                          borderTopLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                          fontSize: "11px",
                        }}
                      >
                        ● {data?.alertLevel?.name}
                      </span>
                    )}
                  </div>
                  <div className="Text_Center_part">
                    <p className="ml-2 mt-2" style={{ minHeight: "70px" }}>
                      {expandedPosts[data.postId]
                        ? data.caption
                        : `${data.caption.substring(0, 90)}...`}
                      <span
                        onClick={() => toggleExpand(data.postId)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                      </span>
                    </p>
                    <hr className="mx-3" />
                  </div>
                </div>
                <div className="d-flex justify-content-evenly text-center Recommendation_Bottom_section mb-2">
                  <div className="Recommendation_btn">
                    <p className=" m-2 ">
                      <img
                        src={like2}
                        className="RecommendationImg img-fluid"
                        alt="Like"
                      />
                    </p>
                    <h4>{data.likesCount}</h4>
                  </div>
                  <div className="Recommendation_btn">
                    <p className=" m-2 ">
                      <MessageCircle size={20} color="#999999" />
                    </p>
                    <h4>{data.commentsCount}</h4>
                  </div>
                  <div className="Recommendation_btn">
                    <p className=" m-2">
                      <Send size={20} color="#999999" />
                    </p>
                    <h4>{data.sharesCount}</h4>
                  </div>
                </div>
              </Card>
            </Col>
               ))}
            </Row>
            <div className="Pagination_section" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '10vh'
              }}
            >
              <BasicsPagination />
            </div>
          </div>
                </Fragment>
                </div>
                <RightSidebar />
            </div>
              <CreationMainSection
              userId={userId}
                states={states}
                currState={currState}
                    setModal={setModal}
                    modal={modal}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    path={path}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    postType={postType}
                    setPostType={setPostType}
                    toggle={toggle}
                    toggleDiscard={toggleDiscard}
                    feelingCategories={feelingCategory}
                />
                <DiscaedModal
                    discard={discard}
                    toggleDiscard={toggleDiscard}
                    toggleAllDiscard={toggleAllDiscard}
                />
        </>
    )
}

export default MyText