import React, { useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import { Grid } from 'react-feather'
import { Card, Form, FormGroup, FormText, Input, Label } from 'reactstrap'
import CreatepostFooter from './CreatepostFooter'
import CreatepostHeader from './CreatepostHeader'

const Event = (props) => {
    const [imgfile, uploadimg] = useState('https://deconova.eu/wp-content/uploads/2016/02/default-placeholder.png')

    const imgFilehandeler = (e) => {
        if (e.target.files.length !== 0) {
            uploadimg(URL.createObjectURL(e.target.files[0]))
        }
    }
    return (
        <Card className='p-3 rounded'>
            <CreatepostHeader
                actionM={props.NavMedia}
                actionT={props.NavThrought}
                actionG={props.NavGoLive}
                actionE={props.NavEvent}
                actionP={props.NavPodcast}
                actionA={props.NavArticle}
                actionR={props.NavReccom}
                actionAl={props.NavAlert}
                actionPo={props.NavPoll}
                actionS={props.NavSell}
            />
            <Form className='my-2'>
                <FormGroup>
                    <Label for='eventcategory' tag='h4' className='my-1' >Event Catagory</Label>
                    <Input id='eventcategory' type='select'>
                        <option>Social</option>
                        <option>Social</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label tag='h4' className='my-1' for='helperText'>Event Title*</Label>
                    <Input type='text' id='helperText' placeholder='Enter your event title....' />
                    <FormText className='text-muted text-right'>Max 64 Characters</FormText>
                </FormGroup>
                <FormGroup>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Label tag='h4' className='my-1'  >Upload Event Cover Photo</Label>
                        <Input type='file' className='customeForuploader' onChange={imgFilehandeler} />
                    </div>
                    <img
                        src={imgfile}
                        className="event-img-prev w-100"
                        alt="event cover"
                    />
                </FormGroup>
                <FormGroup>
                    <Label tag='h4' className='my-1' >Description</Label>
                    <Input type='textarea'
                        cols="15"
                        rows="5"
                    />
                </FormGroup>
                <FormGroup>
                    <div className='d-flex justify-content-between align-items-center event-date-time'>
                        <div>
                            <Label tag='h4' className='mb-2' >Event Start Date & Time</Label>
                            <DateTimePicker
                                className="form-control"
                                placeholder="dd-mm-yyyy"
                            />
                        </div>
                        <div>
                            <Label tag='h4' className='mb-2' >Event End Date & Time</Label>
                            <DateTimePicker
                                className="form-control"
                                placeholder="dd-mm-yyyy"
                            />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for='eventcategory' tag='h4' className='my-1' >Address or Link to Event</Label>
                    <Input Placeholder='Type Address or Link to Event' type='text' />
                </FormGroup>
            </Form>
            <CreatepostFooter
                FirsticonS={'d-none'}
                Firsticon={'d-none'}
                Secondicon={'d-none'}
            />
        </Card>
    )
}

export default Event