import React, { useState, useRef, useEffect, Fragment } from 'react';
import EmojiButton from '@joeattardi/emoji-button';

const Emoji = ({ onEmojiSelect }) => {
    const [emojiButton, setEmojiButton] = useState(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        if (!emojiButton) {
            setEmojiButton(new EmojiButton());
        }
    }, [emojiButton]);

    const handleEmojiButtonClick = () => {
        if (emojiButton) {
            emojiButton.pickerVisible ? emojiButton.hidePicker() : emojiButton.showPicker(buttonRef.current);
        }
    };

    useEffect(() => {
        if (emojiButton) {
            emojiButton.on('emoji', emoji => {
                // Call the callback function to handle emoji selection
                onEmojiSelect(emoji);
            });
        }
    }, [emojiButton, onEmojiSelect]);

    return (
        <Fragment>
            <div className='Emoji_Btn_section'>
                <button   ref={buttonRef} onClick={handleEmojiButtonClick}>😀</button>
            </div>
        </Fragment>
    );
}

export default Emoji;
