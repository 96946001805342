
import { AlertTriangle, Bell, BellOff, Calendar, Check, Filter, MoreHorizontal, MoreVertical, Settings, User, XSquare } from 'react-feather'
import React, { useState } from 'react'
import { Row, Col, Modal, ModalHeader, Input, ModalBody, Button, UncontrolledPopover, PopoverHeader, PopoverBody, Card, CardBody } from 'reactstrap'
import Header from "../Header"
import LeftSidebar from '../LeftSidebar'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import RightSidebar from '../RightSidebar';
import { NavLink } from 'react-router-dom'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReportModal from './Modals/ReportModal'
import { SeeMoreNotificationdata } from './DummyArray'

const Seemore = () => {

    {/* ----------------------------State and function for Report Modal------------------------------------------ */ }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const dateFormat = 'dd/MM/yyyy';
    {/* ----------------------------State and function for Mute Notifications------------------------------------------ */ }
    const [muted, setMuted] = useState(false)
    const toggleMute = () => {
        setMuted((preState) => !preState)
    }

    {/* ------------------------State and function for Enabling/Disabling calendar popover----------------------- */ }
    const [calendarClk, setcalendarClk] = useState(false);
    const togglecalendarClk = () => {
        setcalendarClk((prevState) => !prevState);
    }

    {/* ------------------------State and function for Enabling/Disabling date wise sorting----------------------- */ }
    const [dateApplied, setdateApplied] = useState(false);
    const onApply = () => {
        setdateApplied(true);
        setcalendarClk((prevState) => !prevState);
    }

    const oncancelApply = () => {
        setdateApplied(false);
        setcalendarClk((prevState) => !prevState);
    }

    const [read, setRead] = useState(false)
    const toggleRead = () => {
        setRead((prevState) => !prevState)
    }


    // -----------------------------------------------Array data Notifications Part-------------------------------------------------------

    // const [Notifiactiondata, setNotifiactiondata] = useState([
    //     {
    //         name: 'Prem Ahuja',
    //         name1: 'Rabbi Shergill Guitar Show',
    //         img: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
    //         text: 'Paige Turner',
    //         Time: '11:34 am',
    //         Button: 'Request',
    //         post: 'has invited you to join the event',
    //         status: 'read',
    //         isChecked: false
    //     },
    //     {
    //         name: 'Alex Adams -',
    //         name1: 'Nainital Trip.',
    //         img: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80',
    //         text: 'Jacob Jones',
    //         Time: '11:34 am',
    //         post: 'liked your photo',
    //         status: 'Unread',
    //         isChecked: false
    //     },
    //     {
    //         name: 'Alex Adams - ',
    //         name1: 'Nainital Trip with my official Group pa...',
    //         img: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
    //         text: 'Dr.Robertson',
    //         Time: '11:34 am',
    //         post: 'and 42 others have liked your photo',
    //         status: 'Unread',
    //         isChecked: false
    //     },
    //     {
    //         name: 'Alex Adams - ',
    //         name1: 'Nainital Trip with my office friends',
    //         img: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    //         text: 'Paige Turner',
    //         Time: '1 Hrs ago',
    //         post: 'commented on your photo',
    //         status: 'read',
    //         isChecked: false

    //     },
    //     {
    //         name: 'Prem Ahuja',
    //         name1: 'Rabbi Shergill Guitar Show',
    //         img: 'https://img.freepik.com/premium-photo/handsome-young-man-standing-against-blue-wall_259150-20778.jpg?w=1380',
    //         text: 'Paige Turner',
    //         Time: '11:34 am',
    //         post: 'has invited you to join the event',
    //         Button: 'Request',
    //         status: 'read',
    //         dayandtime: 'Yesterday, 21 March 2022',
    //         isChecked: false
    //     },
    //     {
    //         name: 'Alex Adams -',
    //         name1: 'Nainital Trip.',
    //         img: 'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=1060&t=st=1677827629~exp=1677828229~hmac=acfb4f4a4365db793c01b09cc36b6af3b83487309d709b635570c38a60e298e7',
    //         text: 'Jacob Jones',
    //         Time: '11:34 am',
    //         post: 'liked your photo',
    //         status: 'Unread',
    //         isChecked: false
    //     },
    //     {
    //         name: 'Alex Adams -',
    //         name1: 'Nainital Trip.',
    //         img: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=761&q=80',
    //         text: 'Jacob Jones',
    //         Time: '11:34 am',
    //         post: 'liked your photo',
    //         status: 'Unread',
    //         isChecked: false
    //     },
    //     {
    //         name: 'Alex Adams - ',
    //         name1: 'Nainital Trip with my official Group pa...',
    //         img: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
    //         text: 'Dr.Robertson',
    //         Time: '11:34 am',
    //         post: 'and 42 others have liked your photo',
    //         status: 'Unread',
    //         isChecked: false
    //     }
    // ])

    const now = new Date();
    const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const [value, onChange] = useState([yesterdayBegin, todayEnd]);

    {/* ------------------------------Function for formatting Date range----------------------------------- */ }
    const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `${day}/${month}/${year}`;
    }


    // const handleChange = (index) => {
    //     const updatedNotifiactiondata = [...Notifiactiondata];
    //     updatedNotifiactiondata[index].isChecked = !updatedNotifiactiondata[index].isChecked;
    //     setNotifiactiondata(updatedNotifiactiondata);
    // };
    const [isOpen, setIsOpen] = useState(false);

    const closeDate = () => {
        //setIsOpen(!isOpen);
        if (calendarClk) {
            togglecalendarClk();
        }
    }
    // -----------------------------------------------Main return block-------------------------------------------------------
    return (

        <div>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <Row>
                        <Col lg='3'>
                        </Col>
                        <Col lg='6'>
                            <div className='shadow'>
                                {calendarClk ? <div className='shadow date bg-light rounded-2'
                                    placement="start"
                                    target="PopoverLegacy"
                                    trigger="legacy"
                                    isOpen={calendarClk}
                                    toggle={togglecalendarClk}
                                >
                                    {/* ==================================date========================================== */}
                                    <p style={{ color: '4D4D4D', fontSize: '14px' }} className='pt-3 pl-2'>From Date - To Date</p>
                                    <PopoverBody>
                                        <DateRangePicker
                                            calendarAriaLabel="Toggle calendar"
                                            clearAriaLabel="Clear value"
                                            dayAriaLabel="Day"
                                            monthAriaLabel="Month"
                                            nativeInputAriaLabel="Date"
                                            // onChange={(newRange) => onChange(newRange)}
                                            onChange={onChange}
                                            value={value}
                                            isOpen={isOpen}
                                            yearAriaLabel="Year"
                                            format={dateFormat}

                                        />
                                        <div className='mt-2 d-flex align-items-center justify-content-between' >
                                            <Button color='primary' outline onClick={onApply} >Apply</Button>
                                            <Button color='danger' outline className=' ml-2' onClick={oncancelApply}  >Cancel</Button>
                                        </div>
                                    </PopoverBody>
                                </div> : null}
                                {/* ----------------------------Header part start------------------------------------------ */}
                                <div className="d-flex justify-content-between align-items-center heading_section p-2">
                                    <h4 className='notification_seemore mt-2' style={{ color: 'black', marginLeft: '2px' }}>Notifications</h4>
                                    <div className='d-flex justify-content-between mt-2 ml-3'>
                                        {/* ------------------------------Filter Notifications as per range date START----------------------------------- */}
                                        <Button outline color='primary' className='mx-2' id="PopoverLegacy" onClick={togglecalendarClk} ><Calendar size={18} /></Button><br></br><br></br>

                                        {/* ------------------------------Filter Notifications as per range date END----------------------------------- */}
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle style={{ borderRadius: '5px' }} className='mr-4' outline color='primary' onClick={closeDate} >

                                                <Filter size={18} />
                                                &nbsp;
                                                Filter
                                            </DropdownToggle>
                                            <DropdownMenu className="DropDown_Notification_item mt-4 filterNotification">
                                                <DropdownItem className="DropDown_Notification_items">
                                                    All Notification
                                                </DropdownItem>
                                                <DropdownItem className="DropDown_Notification_items">
                                                    Unread Notifications
                                                </DropdownItem>
                                                <DropdownItem className="DropDown_Notification_items">
                                                    This week
                                                </DropdownItem>
                                                <DropdownItem className="DropDown_Notification_items">
                                                    This Month
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <UncontrolledButtonDropdown >
                                            <DropdownToggle className='ml-2 mt-1 Notification_More_OptionMenu'>
                                                <MoreHorizontal size={22} color="#808080" height='15px' width='15px' className='m-1' onClick={closeDate} />
                                            </DropdownToggle>
                                            <DropdownMenu className="DropDown_Notification_item_moreMenu" style={{ marginLeft: '-3rem', marginTop: '2.2rem' }}>
                                                <DropdownItem tag='a'><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="19"
                                                    height="19"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                    className="pr-1"
                                                >
                                                    <path
                                                        d="M6.54453 8.16969C6.10508 8.60914 5.39492 8.60914 4.95547 8.16969L2.14191 5.35719C1.70281 4.91773 1.70281 4.20758 2.14191 3.76812C2.58137 3.32867 3.29363 3.32867 3.73203 3.76812L5.71836 5.78258L10.5805 0.95457C11.0199 0.515469 11.7301 0.515469 12.1695 0.95457C12.609 1.39402 12.609 2.10629 12.1695 2.54574L6.54453 8.16969ZM6.54453 16.0447C6.10508 16.4841 5.39492 16.4841 4.95547 16.0447L0.454484 11.5447C0.0151719 11.1052 0.0151719 10.3951 0.454484 9.95562C0.893867 9.51617 1.60613 9.51617 2.04559 9.95562L5.71836 13.6576L13.9555 5.45562C14.3949 5.01617 15.1051 5.01617 15.5445 5.45562C15.984 5.89508 15.984 6.60523 15.5445 7.04469L6.54453 16.0447Z"
                                                        fill="#4D4D4D"
                                                    />
                                                </svg>
                                                    Mark all as read</DropdownItem>
                                                <DropdownItem className="dropdown-item">
                                                    <Settings size={16} />&nbsp;&nbsp;&nbsp;Notification Setting
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                                {/* ------------------------------------Body part start map function with array data-------------------------------------- */}
                                <Row>
                                    <div className='Today' >{dateApplied ? `Showing notification result from ${formatDateString(value[0])} to ${formatDateString(value[1])} ` : <p className='Today '>Today </p>}</div>
                                    {
                                        SeeMoreNotificationdata.map((data, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        <div key={index} className={data.status === 'read' ? 'd-flex justify-content-between p-2  CardThisHover   bg-light' : 'd-flex justify-content-between CardThisHover  p-2'} id='fixed_color'>
                                                            <div className='d-flex align-items-start mt-2'>
                                                                <img src={data.img} height={50} width={50} alt='pollimg' className='rounded-circle' />
                                                                <div className='ml-3'>
                                                                    {/* <h4><NavLink to='/MyProfile' style={{ color: 'black' }} ><span className='user-profile'> {data.name}</span> </NavLink><span className='text-muted'> {data.post}</span> {data.event ? <NavLink to='/Events' style={{ color: 'black' }} ><span className='user-profile' >{data.name1}</span> </NavLink> : data.name1}</h4> */}
                                                                    <h4><NavLink to='/MyProfile' style={{ color: 'black' }} ><span className='user-profile'> {data.name}</span> </NavLink><span className='text-muted'> {data.post}</span> {<NavLink to={data.event ? '/Events' : '/MyProfile'} style={{ color: 'black' }} ><span className='user-profile' >{data.name1}</span> </NavLink>}</h4>
                                                                    {data.Button === 'Request' ?
                                                                        <div className='mt-2 d-flex '>
                                                                            <Button color='primary px-3 p-2' >interested</Button>
                                                                            <Button color='secondary px-3 p-2' outline className='ml-2'>Not Interested</Button>
                                                                        </div> : null
                                                                    }
                                                                    <p className='mt-2 text-muted'>{data.Time} </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <UncontrolledButtonDropdown >
                                                                    <DropdownToggle color='flat-primary' className="Notification_More_OptionMenuProfile mt-2">
                                                                        <MoreHorizontal color='#808080' size={19} onClick={closeDate} />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="DropDown_Notification_item_moreMenu">
                                                                        <DropdownItem tag='a'><svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="17"
                                                                            height="16"
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            className="pr-1"
                                                                        >
                                                                            <path
                                                                                d="M6.54453 8.16969C6.10508 8.60914 5.39492 8.60914 4.95547 8.16969L2.14191 5.35719C1.70281 4.91773 1.70281 4.20758 2.14191 3.76812C2.58137 3.32867 3.29363 3.32867 3.73203 3.76812L5.71836 5.78258L10.5805 0.95457C11.0199 0.515469 11.7301 0.515469 12.1695 0.95457C12.609 1.39402 12.609 2.10629 12.1695 2.54574L6.54453 8.16969ZM6.54453 16.0447C6.10508 16.4841 5.39492 16.4841 4.95547 16.0447L0.454484 11.5447C0.0151719 11.1052 0.0151719 10.3951 0.454484 9.95562C0.893867 9.51617 1.60613 9.51617 2.04559 9.95562L5.71836 13.6576L13.9555 5.45562C14.3949 5.01617 15.1051 5.01617 15.5445 5.45562C15.984 5.89508 15.984 6.60523 15.5445 7.04469L6.54453 16.0447Z"
                                                                                fill="#4D4D4D"
                                                                            />
                                                                        </svg>
                                                                            Mark  as read</DropdownItem>
                                                                        <DropdownItem className="DropDown_Notification_items">
                                                                            <BellOff size={16} />&nbsp;&nbsp;Mute Notifications
                                                                        </DropdownItem>
                                                                        <DropdownItem className="DropDown_Notification_items">
                                                                            <User size={16} />&nbsp;&nbsp;View Profile
                                                                        </DropdownItem>
                                                                        <DropdownItem style={{ color: '#4D4D4D' }} onClick={toggleReport} tag='a'><AlertTriangle size={15} />&nbsp;&nbsp;Report</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </div>
                                                        </div>
                                                    </div >
                                                    <span className='Today' >{data.dayandtime}</span>
                                                </>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                        <Col lg='3'>
                        </Col>
                    </Row>
                    {/* ------------------------------------Review Report modal start--------------------------------------------------------- */}
                    <ReportModal
                        reportOpen={reportOpen}
                        toggleReport={toggleReport}
                    />
                    {/* ------------------------------------Review Report modal end----------------------------------------------------------- */}

                </div >
                <RightSidebar />
            </div >
        </div >

    )
}

export default Seemore