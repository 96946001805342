import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { loadAdType } from "../../../../Services/Actions/AdManager/getAdTypeAction";
import AdmanagerHeaderR from "../../AdmanagerHeaderR/AdmanagerHeaderR";
import "../../style.css";
// MUI Dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Popover, PopoverBody, Row } from "reactstrap";
import CreateAdDashboard from "./CreateAdDashboard";
import { Circle } from "react-feather";

const CreateAdScreen = () => {
  const [adTypeId, setAdTypeId] = useState("");
  const { adType } = useSelector((state) => state.getTypeData);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [StepMenuShow, setStepMenuHide] = useState(false)
  const [warningOn, setwarningOff] = useState(false)
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(loadAdType());
  }, []);

  // navigate to next screen
  const submitHandler = (e) => {
    e.preventDefault();
    if (!adType) {
      alert("Please Select Type");
    } else {
      navigate(`/Ad/Display/${adTypeId}`);
    }
  };

  // CANCEL
  const logoutUser = () => {
    setOpen(false);
    navigate("/Dashboard");
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <AdmanagerHeaderR /> */}
      <div >
        <Row style={{ height: 'calc(100vh - 100px)' }} className='rowForrevert gap-1'>
          <Col xl='2'>
            {/* <CreateAdDashboard /> */}
            <Card className='h-100 d-none d-sm-block border-right'>
              <div className="p-4 borderLeftForDasboard">
                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 2</h6></div>
                <div className=''><h5><b>Select Ad Format</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 3</h6></div>
                <div className=' pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 4</h6></div>
                <div className=' pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 5</h6></div>
                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 6</h6></div>
                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
              </div>
            </Card>
          </Col>
          <Col>
            <Row className='rowForrevert '>
              <Col style={{ paddingInlineStart: '8px' }} xl='12'>
                <Card className='border-0 shadow rounded-2'>
                  <div className="d-flex">
                    <div className="d-flex align-items-center p-2 border-right">
                      <button className="ad-count-btn responsiveForTag">1</button>
                      <p className="ad-count-step px-3">Step 1</p>
                    </div>
                    <div className="p-2 pl-3">
                      <h4>Select Campaign Type</h4>
                      <p className="text-muted">Choose Campaign Type (e.g., Display, Video, App, etc.)</p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className='rowForrevert'>
              <Col xl='12'>
                <div className="row rowForrevert">
                  {adType.map(({ id, adTypes, descriptions, adMastrerType }) => {
                      const handleDivClick = () => {
                        const radioBtn = document.getElementById(adTypes);
                        if (radioBtn) {
                          radioBtn.click();
                        }
                        setAdTypeId(id);
                      };
                    return (
                      <div
                        className="form-check col-md-6 checkbox_animated "
                        key={id}
                      >
                        <div className="col-lg-12 p-0">
                          <div className="ad-type AdHov d-flex shadow rounded-2" onClick={() => { handleDivClick(); localStorage.setItem("adTypeName", `${adTypes} - ${adMastrerType.name}`) } }>
                            <div>
                              <div className="form-check checkbox_animated">
                                <ul>
                                  <li className="pad-zero">
                                    <div className="form-check checkbox_animated d-flex align-items-start">
                                      <input
                                        style={{ marginTop: '-2px' }}
                                        type="radio"
                                        className="radio_animated radio_animated_lg"
                                        id={adTypes}
                                        name="ItsMe"
                                      />
                                      <label
                                        className="form-check-label ml-2"
                                        htmlFor={adTypes}
                                        role="button"
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                      >
                                        <h4 className="type">{adTypes} - ({adMastrerType.name})</h4>
                                        <p className="dec">{descriptions}</p>
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
            <Row className='rowForrevert'>
              <Col xl='12'>
                <div className='p-3'>
                  <div className="ad-btn">
                    <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                      Back
                    </Button>
                    {/* <Popover
                      placement='top'
                      target='controlledPopover'
                      isOpen={warningOn}
                      toggle={() => setwarningOff(!warningOn)}
                    > */}
                    {/* <PopoverBody>
                        <div className="text-muted">
                          Your selected Ad Type cannot be changed once you save. Do you want to save?11
                        </div>
                        <div className="text-center">
                          <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                            Cancel
                          </Button>
                          <Button  color='primary' size='sm' className="px-3 py-1">
                            Save
                          </Button>
                        </div>
                      </PopoverBody> */}
                    {/* </Popover> */}
                    <Button
                      disabled={!adTypeId}
                      id='controlledPopover'
                      size='md'
                      className="ml-2"
                      onClick={submitHandler}
                      style={{
                        backgroundColor: !adTypeId ? 'rgba(172, 218, 214, 1)' : 'rgba(129, 193, 75, 1)', color: 'white', borderColor: '#81C14B'
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                <Modal isOpen={open} toggle={() => setOpen(!open)} className='modal-dialog-centered text-center'>
                  <ModalBody className='my-2'>
                    <h2 className="text-muted" >We're sorry to see you go! </h2>
                    <h4 className="py-2">Your drafted will be saved </h4>
                  </ModalBody>
                  <div className="">
                    <Button color='secondary' outline onClick={() => setOpen(!open)}>
                      No
                    </Button>
                    <Button color='primary' onClick={() => setOpen(!open)}>
                      Yes
                    </Button>
                  </div>
                </Modal>
                {/* <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title text-center">
                    {
                      "OOPS! You just stopped Do you really want to discard this AD?"
                    }
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={logoutUser}>Yes</Button>
                  </DialogActions>
                </Dialog> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateAdScreen;
