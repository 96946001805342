import React from 'react'
import { AlertCircle, Info } from 'react-feather'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'reactstrap'

const LoginBlockedModal = ({ modal, toggle }) => {
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered" >
            <div className='d-flex flex-column align-items-center justify-content-evenly text-center gap-4 p-5'>
                <AlertCircle size={100} color='#EB5757' strokeWidth={2} />
                <h4>Your account has been blocked.</h4>

                <p>Please <Link style={{ color: "#1976D2", fontWeight: "500" }} to="/">contact</Link> our support team for more info or read our <Link style={{ color: "#1976D2", fontWeight: "500" }} to='/'>Terms & Conditions</Link> to know more. </p>
                <Button outline className="py-3 px-5 w-75 bg-transparent text-dark " onClick={toggle} > OK</Button>
            </div>
        </Modal>

    )
}

export default LoginBlockedModal
