import React from 'react';
import FriendRequest from './left-right-components/FriendRequest';
import LikedBizPage from './left-right-components/LikedBizPage';
import Weather from './left-right-components/Weather';
import { useSelector } from 'react-redux';
import ProfileCard from './left-right-components/ProfileCard';
import FrndSuggestion from './left-right-components/FrndSuggestion';

const ContentLeft = () => {
  const { userFollowingRequests } = useSelector(state => state.userFollowingRequestsData)
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  return (
    <>

      <div className="content-left rem-mob-view">
        {/* <ProfileBox /> */}
        <ProfileCard />
        <Weather />
        {/* <FriendSuggestion /> */}
        <FrndSuggestion />
        
     
       
        {
          userFollowingRequests?.length > 0 && <FriendRequest />
        }
        <LikedBizPage />
      </div>

    </>
  );
}

export default ContentLeft;