import React, { useState } from 'react'
// import { Grid, List } from 'react-feather'
import { Col, Row } from 'reactstrap'
// import BookMarkCard from './BookMarkCard'
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import MasonryGrid from './MasonryGrid'
import BookMarkNewCard from './BookMarkNewCard';
import NewMasonryGrid from './NewMasonryGrid';
import BookMarkCardSection from './BookMarkCardSection';

const BookMarkGridandList = ({ data }) => {

    // const now = new Date();
    // const yesterdayBegin = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    // const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);



    // const [data, setdata] = useState(true)
    // const [value, onChange] = useState([yesterdayBegin, todayEnd]);
    return (
        <div className='mt-2'>
            {/* <div className='d-flex justify-content-end mt-2'>
                <div className='list-grid-toggle mr-3 rem-mob-view'>
                    <Button color='primary' outline className={data ? 'GridButtonView' : ''}>
                        <List size={16} />
                    </Button>
                    <Button color='primary' outline className={data ? '' : 'GridButtonView'} >
                        <Grid size={16} />
                    </Button>
                </div>
                <DateRangePicker
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date"
                    onChange={onChange}
                    value={value}
                    yearAriaLabel="Year"
                />
            </div> */}
            <div>
                {
                    data ?
                        <Row>
                            <Col lg='0' xl='3' md='0' >
                            </Col>
                            <Col lg='12' xl='6' md='12'  >
                                {/* <BookMarkCard />   */}
                                {/* <MainCreation /> */}
                                {/* <BookMarkNewCard /> */}
                                <BookMarkCardSection />
                            </Col>
                            <Col lg='0' xl='3' md='0' >
                            </Col>
                        </Row>
                        :
                        // <MasonryGrid />
                        <NewMasonryGrid />
                }
            </div>
        </div>
    )
}
export default BookMarkGridandList 