import React, { useState } from 'react'
import { Fragment } from 'react'
import { BellOff, Edit2, EyeOff, Link2, MoreHorizontal, Navigation, Trash2 } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { Col, Nav, NavItem, Progress, Modal, ModalBody, ModalHeader,ModalFooter, Row, TabContent, TabPane,Button } from 'reactstrap'
import Union from '../../profile-components/Img/Union.svg'
import ViewProfile from "../../profile-components/Img/ViewProfile.png"
import Profile2 from "../../profile-components/Img/profile2.png"
import Profile3 from "../../profile-components/Img/profile3.png"
import Profile4 from "../../profile-components/Img/profile4.png"
import PollCenterComponent from './PollCenterComponent'
import { getAllUserPollAction, getTextPostsAction,deletePostAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { sweetAlertConfirmation } from "../../group-components/AllGroupsSections/SweetAlert";
import { useLocation,useNavigate  } from 'react-router-dom';

const PollLeftComponents = ({pollSearch}) => {

    let dispatch = useDispatch();
    const [pageIndex,setPageIndex]=useState(0);
    const [pageSize,setPageSize]=useState(10);
    const [type,setType] = useState("all");
    const [pollId,setPollId] = useState('')
    const [pollId1,setPollId1] = useState([])
    const [deleteOpen,setDeleteOpen]=useState(false);
    const location=useLocation();
    const navigate = useNavigate();

    const getQueryParams = (search) => new URLSearchParams(search);

    useEffect(() => {
        dispatch(getTextPostsAction('poll',pageSize,pageIndex,pollSearch));
    }, [pollSearch,pageIndex])

    const {allpolls,pollError}=useSelector((state)=>{return state.allPollData});

    // console.log("allPolls========================>",allpolls?.result)

    const currentDate = new Date().toISOString();    

    const [active, setActive] = useState('1')
    const [currentActiveTab, setCurrentActiveTab] = useState('1')
    const [blockOpen, setBlockOpen] = useState(false)
    const [pollType, setPollType] = useState('1')

    const PollAnalyticsData = [
        {
            view: "2.5k",
            heading: "Total likes ",
            onClick: "View →"
        },
        {
            view: "3.5k",
            heading: "Total shares ",
            onClick: "View →"
        },
        {
            view: "4.5k",
            heading: "Viewed",
            onClick: "View →"
        },
        {
            view: "1.5k",
            heading: "Voted ",
            onClick: "View →"
        }
    ]
    const GenderPollData = [
        {
            heading: "Male",
            rate: "2.1k",
            value: "75"
        },
        {
            heading: "Female",
            rate: "899",
            value: "45"
        },
        {
            heading: "Other",
            rate: "325",
            value: "25"
        }
    ]
    const AgePollData = [
        {
            heading: "18-24",
            rate: "85%",
            value: "85"
        },
        {
            heading: "25-34",
            rate: "35%",
            value: "65"
        },
        {
            heading: "35-54",
            rate: "75%",
            value: "45"
        },
        {
            heading: "55 and above",
            rate: "95%",
            value: "75"
        }

    ]
    const LocationData = [
        {
            heading: "Argentina",
            rate: "85%",
            value: "85"
        },
        {
            heading: "Bangladesh",
            rate: "35%",
            value: "65"
        },
        {
            heading: "Canada",
            rate: "75%",
            value: "45"
        },
        {
            heading: "Denmark",
            rate: "95%",
            value: "75"
        }

    ]
    const toggleActive = (count) => {
        let newType;
        switch(count) {
            case 2:
                newType = "running";
                break;
            case 3:
                newType = "completed";
                break;
            case 4:
                newType = "scheduled";
                break;
            default:
                newType = "all";
                break;
        }
        setType(newType);

        // Update query parameters
        // const queryParams = getQueryParams(location.search);
        // queryParams.set('type', newType);
        // navigate(`${location.pathname}?${queryParams.toString()}`);
    };

    useEffect(() => {
        dispatch(getAllUserPollAction(type, pageIndex, pageSize));
    }, [type, pageIndex, pageSize, dispatch]); 

    const togglePoll = tab => {
        if (pollType !== tab) {
            setPollType(tab)
        }
    }

    const toggle = (id) => {
        // if (currentActiveTab !== tab) setCurrentActiveTab(tab)
        setPollId(id);
    }

    const toggleDelete =(e,postId)=>{
        e.stopPropagation();
        setPollId1(postId);
        setDeleteOpen(true);
    }

    const toggleDeleteOpen=()=>{
        setPollId1([]);
        setDeleteOpen(false);
    }

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    };

    const deleteAllPoll=(e)=>{
        
            e.stopPropagation();
            dispatch(deletePostAction([pollId1]));
            setDeleteOpen(false);
            setPollId1([]);
            sweetAlertConfirmation('Successfully deleted');
        
    }

    const toggleBlock = () => {
        setBlockOpen((prevState) => !prevState)
    }
    const ProgressBarData = [
        {
            labelName: "Audi A6",
            value: "40",
            rating: "30%"
        },
        {
            labelName: "BMW 5 Series",
            value: "30",
            rating: "20%"
        },
        {
            labelName: "Lexus ES 200",
            value: "20",
            rating: "12%"
        },
        {
            labelName: "Volvo Hatchback",
            value: "50",
            rating: "38%"
        }

    ]
    const CompletedPollPreviewData = () => (
        <Fragment>
            <div className='d-flex align-items-center p-3'>
                <div className='mr-auto d-flex align-items-start'>
                    <img src={Union} alt="poll" />
                    <div className='ml-2'>
                        <h4>Which is the best Car of 2022 ?</h4>
                        <p className='pt-2'>Total Participant: 234k</p>
                    </div>
                </div>
                <div className='PollMoreMenu'>
                    <MoreHorizontal data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Poll_option">
                        <ul>
                            {/* <li>
                                <Link to="#"><EyeOff size={15} color="#979797" /> &nbsp;Hide from timeline</Link>
                            </li> */}
                            <li>
                                <Link onClick={(e)=>toggleDelete(e)}><Trash2 size={15} color="#979797" />&nbsp;Delete </Link>
                            </li>
                            <li>
                                <Link to="#"><Navigation size={15} color="#979797" />&nbsp;Share</Link>
                            </li>
                            <li>
                                <Link to="#"><Link2 size={15} color="#979797" /> &nbsp;Copy Link </Link>
                            </li>
                            <li>
                                <Link to="#"><BellOff size={15} color="#979797" /> &nbsp;Turn off notification   </Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className='p-3'>
                {
                    ProgressBarData.map((data) => (
                        <>
                            <Progress className="Completed_Bar_Poll mt-3" value={data.value}>
                                <div className='d-flex '>
                                    <label>{data.labelName}</label>

                                </div>
                                <span className='Progress_Rating'>{data.rating}</span>
                            </Progress>
                        </>
                    ))
                }

            </div>




            <div className='Poll_Btn_section'></div>
            <div className='Completed_Analytics p-3'>
                <h4>Poll analytics</h4>
                <p>  ● Completed</p>
            </div>
            <Row className="p-3">
                {
                    PollAnalyticsData.map((data) => (
                        <>
                            <Col md="6" className="py-2">
                                <div className="Analytics_Poll p-3">
                                    <h4>{data.view}</h4>
                                    <div className='d-flex Analytics_Flex_box justify-content-between py-2'>
                                        <p>{data.heading}</p>
                                        <span onClick={toggleBlock} >{data.onClick}</span>
                                    </div>
                                </div>
                            </Col>
                        </>
                    ))
                }
            </Row>
            <Row className="mb-5 Bottom_AllType_Tab_Section mt-2 p-3 ">
                <Nav className="border-bottom">
                    <Col className="d-flex justify-content-around" md="4" >
                        <NavItem>
                            <NavLink
                                active={pollType === '1'}
                                onClick={() => {
                                    togglePoll('1')
                                }}
                                className="Member_link"
                            >
                                Gender
                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col className="d-flex justify-content-around" md="4" >
                        <NavItem>
                            <NavLink
                                active={pollType === '2'}
                                onClick={() => {
                                    togglePoll('2')
                                }}
                                className="Member_link"
                            >
                                Age
                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col className="d-flex justify-content-around" md="4" >
                        <NavItem>
                            <NavLink
                                active={pollType === '3'}
                                onClick={() => {
                                    togglePoll('3')
                                }}
                                className="Member_link"
                            >
                                Location
                            </NavLink>
                        </NavItem>
                    </Col>
                </Nav>

                <Col md="12">
                    <TabContent className='py-50' activeTab={pollType}>
                        <TabPane tabId="1">
                            {GenderPollData.map((data, index) => (
                                <>
                                    <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                        <h4>{data.heading}</h4>
                                        <h5>{data.rate}</h5>
                                    </div>
                                    <Progress className="Gender_Progress_Bar mt-2" value={data.value} />

                                </>
                            ))}
                        </TabPane>
                        <TabPane tabId="2">
                            {AgePollData.map((data, index) => (
                                <>
                                    <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                        <h4>{data.heading}</h4>
                                        <h5>{data.rate}</h5>
                                    </div>
                                    <Progress className="Gender_Progress_Bar mt-2" value={data.value} />
                                </>
                            ))}
                        </TabPane>
                        <TabPane tabId="3">
                            {LocationData.map((data, index) => (
                                <>
                                    <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                        <h4>{data.heading}</h4>
                                        <h5>{data.rate}</h5>
                                    </div>
                                    <Progress className="Gender_Progress_Bar mt-2" value={data.value} />
                                </>
                            ))}
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>

        </Fragment>

    )

    const SchedulePollPreviewData = () => (
        <Fragment>
            <div className='d-flex align-items-center p-3'>
                <div className='mr-auto d-flex align-items-start'>
                    <img src={Union} alt="poll" />
                    <div className='ml-2 '>
                        <h4>Which is the best Car of 2022 ?</h4>
                        <p className='pt-2'> Scheduled for 8th Sep 2022</p>
                    </div>
                </div>
                <div className='PollMoreMenu'>
                    <MoreHorizontal data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal Poll_option">
                        <ul>
                            <li>
                                <Link to="#"><Edit2 size={15} color="#979797" /> &nbsp;Edit </Link>
                            </li>
                            <li>
                                <Link to="#"><Trash2 size={15} color="#979797" />&nbsp;Delete </Link>
                            </li>
                            <li>
                                <Link to="#"><Navigation size={15} color="#979797" />&nbsp;Share</Link>
                            </li>
                            <li>
                                <Link to="#"><BellOff size={15} color="#979797" /> &nbsp;Turn off notification   </Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className='p-3'>
                {
                    ProgressBarData.map((data) => (
                        <>
                            <Progress className="Schedule_Bar_Poll mt-3" value={data.value}>
                                <div className='d-flex '>
                                    <label>{data.labelName}</label>

                                </div>
                                <span className='Progress_Rating'>{data.rating}</span>
                            </Progress>
                        </>
                    ))
                }

            </div>
            <div className='Poll_Btn_section'></div>
            <div className='Scheduled_Analytics p-3'>
                <h4>Poll analytics</h4>
                <p style={{ fontStyle: "italic" }}> Scheduled for 8th Sep 2022</p>
            </div>
            <Row className="p-3">
                {
                    PollAnalyticsData.map((data) => (
                        <>
                            <Col md="6" className="py-2">
                                <div className="Analytics_Poll p-3">
                                    <h4>0</h4>
                                    <div className='d-flex Analytics_Flex_box justify-content-between py-2'>
                                        <p>{data.heading}</p>
                                        <span  >{data.onClick}</span>
                                    </div>
                                </div>
                            </Col>
                        </>
                    ))
                }
            </Row>
            <Row className="mb-5 Bottom_AllType_Tab_Section mt-2 p-3 ">
                <Nav className="border-bottom">
                    <Col className="d-flex justify-content-around" md="4" >
                        <NavItem>
                            <NavLink
                                active={pollType === '1'}
                                onClick={() => {
                                    togglePoll('1')
                                }}
                                className="Member_link"
                            >
                                Gender
                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col className="d-flex justify-content-around" md="4" >
                        <NavItem>
                            <NavLink
                                active={pollType === '2'}
                                onClick={() => {
                                    togglePoll('2')
                                }}
                                className="Member_link"
                            >
                                Age
                            </NavLink>
                        </NavItem>
                    </Col>
                    <Col className="d-flex justify-content-around" md="4" >
                        <NavItem>
                            <NavLink
                                active={pollType === '3'}
                                onClick={() => {
                                    togglePoll('3')
                                }}
                                className="Member_link"
                            >
                                Location
                            </NavLink>
                        </NavItem>
                    </Col>
                </Nav>
                <Col md="12">
                    <TabContent className='py-50' activeTab={pollType}>
                        <TabPane tabId="1">
                            {GenderPollData.map((data, index) => (
                                <>
                                    <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                        <h4>{data.heading}</h4>
                                        <h5>0%</h5>
                                    </div>
                                    <Progress className="Gender_Progress_Bar mt-2" value={data.value} />

                                </>
                            ))}
                        </TabPane>
                        <TabPane tabId="2">
                            {AgePollData.map((data, index) => (
                                <>
                                    <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                        <h4>{data.heading}</h4>
                                        <h5>0%</h5>
                                    </div>
                                    <Progress className="Gender_Progress_Bar mt-2" value={data.value} />
                                </>
                            ))}
                        </TabPane>
                        <TabPane tabId="3">
                            {LocationData.map((data, index) => (
                                <>
                                    <div className='d-flex justify-content-between PollGenderPreivew mt-3'>
                                        <h4>{data.heading}</h4>
                                        <h5>0%</h5>
                                    </div>
                                    <Progress className="Gender_Progress_Bar mt-2" value={data.value} />
                                </>
                            ))}
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>

        </Fragment>
    )

    const currentStatus = allpolls?.result
    .find(data => data.pollId === pollId)?.currentStatus || 
    allpolls?.result[0]?.currentStatus || '';

    return (
        <>
        <div className="gdpost-about-blk mt-3" >
            <Row>
                <Col md={5}>
                    <div className='Poll_RowFirtSide_section'>
                        <Nav tabs style={{padding:'8px', cursor:"pointer"}}>
                            <NavItem>
                                
                                <NavLink
                                    active={active === '1'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleActive(1)
                                    }}
                                >
                                    All Polls {type === "all" && `(${allpolls?.resultCount || 0})`} &nbsp;&nbsp;
                                </NavLink>
                               
                            </NavItem>
                            <NavItem>
                        
                                <NavLink
                                    active={active === '2'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleActive(2)
                                    }}
                                >
                                    Running {type === "running" && `(${allpolls?.resultCount || 0})`}  &nbsp;&nbsp;
                                </NavLink>
                                
                            </NavItem>
                            <NavItem>
                              
                                <NavLink
                                    active={active === '3'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleActive(3)
                                    }}
                                >
                                    Completed {type === "completed" && `(${allpolls?.resultCount || 0})`} &nbsp;&nbsp;
                                </NavLink>
                            </NavItem>
                            <NavItem>
                             
                                <NavLink
                                    active={active === '4'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleActive(4)
                                    }}
                                >
                                    Scheduled {type === "scheduled" && `(${allpolls?.resultCount || 0})`}
                                </NavLink>
                          
                            </NavItem>
                        </Nav>
                        <TabContent >
                            <TabPane>
                                <div className="Poll-about-cont" scrollable style={{ maxHeight: '795px', overflowY: 'auto', cursor: "pointer" }}>
                                    <ul>
                                        {
                                            allpolls?.result?.map((data) => (
                                                <li>
                                                    <div className='d-flex align-items-start  ' onClick={() => { toggle(data?.pollId) }} >
                                                        <img src={Union} alt="poll" />
                                                        <div className='ml-2'>
                                                        <h4>
                                                            {data?.pollCaption}
                                                        </h4>
    
                                                            {
                                                            // data?.pollEndTime > currentDate ?
                                                            //     <><h4>{data?.pollCaption}</h4>
                                                            //         {/* <p className='py-2 text-muted'>Completed on 23 Mar 2021</p> */}
                                                            //         <div className='Completed_Poll py-2 '>
                                                            //             {/* <h5 >{data?.viewsCount}&nbsp;<span>Viewed</span></h5> */}
                                                            //             <h5 className='ml-5'>{data?.votesCount}&nbsp;<span>Voted</span></h5>
                                                            //         </div>
                                                            //     </> : 
                                                            //     (data?.type === "Schedule") ?
                                                            //         <><h4>{data?.pollCaption}</h4>
                                                            //             
                                                            //         </> : 
                                                            //         <>
    
                                                            //         <h4>{data?.pollCaption}</h4>
                                                            //         <p className='py-2 text-muted'>{data?.post}</p>
                                                            //         <p className='py-2 text-muted'>Completed on: {new Date(data?.pollEndTime).toUTCString().substring(0, 16)}</p>
                                                                    
                                                            //         </>
                                                            currentStatus=="scheduled"?(
                                                                <p className='py-2 text-muted'>Scheduled On {formatDate(data?.scheduledDate)}</p>
                                                            ): currentStatus=="running"?(
                                                                <>
                                                                <p className='py-2 text-muted'>Posted On {formatDate(data?.pollStartTime)}</p>
                                                                <div className='Running_POll py-1'>
                                                                    <h5 style={{ color: 'grey' }}>
                                                                        {data?.viewsCount}&nbsp;<span>Viewed</span>
                                                                        </h5>
                                                                        <h5 className="ml-5">
                                                                        {data?.votesCount}&nbsp;<span>Voted</span>
                                                                        </h5>
                                                                    </div>
                                                                 </>

                                                            ):(
                                                                <>
                                                                <br/>
                                                                <div className='Running_POll py-1'>
                                                                    <h5 style={{ color: 'grey' }}>
                                                                        {data?.viewsCount}&nbsp;<span style={{ color: 'grey' }}>Viewed</span>
                                                                        </h5>
                                                                        <h5 className="ml-5" style={{ color: 'grey' }}>
                                                                        {data?.votesCount}&nbsp;<span style={{ color: 'grey' }}>Voted</span>
                                                                        </h5>
                                                                    </div>

                                                                    </>
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
    
                            </TabPane>
                        </TabContent>
                    </div>
                </Col>
                <Col md={7} >
                {allpolls?.result?.length>0 &&(
                    <div className='Poll_RowFirtSide_section'>
                    <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                        <PollCenterComponent 
                            pollSearch={pollSearch} 
                            pollId={pollId || allpolls?.result[0]?.pollId} 
                            currentStatus={currentStatus}
                        />

                        </TabPane>
                        {/* <TabPane tabId="2">
                            <CompletedPollPreviewData />
                        </TabPane>
                        <TabPane tabId="3">
                            <SchedulePollPreviewData />

                        </TabPane>
                        <TabPane tabId="4">
                            get data with the help of api

                        </TabPane> */}
                    </TabContent>
                </div>
                )} 
                </Col>
            </Row>
        </div>
        <div>
              {blockOpen &&(
                <div className='Modal_section_Basic_info_Mobile_no'>
                    <Modal className='modal-dialog-centered'>
                        <ModalHeader style={{ backgroundColor: '#81c14b', color: 'white', textAlign: 'center', borderBottom: 'none', paddingBottom: '0' }}>
                            <h3 className="modal-title" style={{ color: 'white', textAlign: 'center' }}>Full Post Content</h3>
                        </ModalHeader>
                        <ModalBody style={{ textAlign: 'center' }}>
                           this is something
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: 'center' }}>
                            <Button color="secondary" style={{
                                backgroundColor: '#f5fafa',
                                borderColor: '#81c14b',
                                color: '#81c14b',
                            }}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            )}
        </div>
        <div className="vertically-centered-modal">
              {deleteOpen !== false && (
                <Modal
                  isOpen={deleteOpen}
                  toggle={toggleDeleteOpen}
                  className="modal-dialog-centered"
                >
                  <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                    <h4>Delete Poll</h4>
                  </ModalHeader>
                  <ModalBody>
                    <div className="d-flex justify-content-center modalHeading_Section">
                      <p>Do you really want to delete all the selected Poll ?</p>
                    </div>
                    <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                      <small>if</small> YES! <small>please click on ok</small>
                    </h1>
                    <div className="d-flex justify-content-center  mt-4">
                      <Button onClick={toggleDeleteOpen} outline>
                        Cancel
                      </Button>
                      <Button
                        onClick={(e)=>deleteAllPoll(e)}
                        color="primary"
                        className="ml-3 "
                      >
                        OK
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
              )}
        </div>
    </>
    )
}

export default PollLeftComponents
