import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const getRemoveFollower = (getUserRemoveFollower) => ({
    type: types.GET_REMOVE_FOLLOWER,
    payload: getUserRemoveFollower,
});
// get all biz category
export const loadAllRemoveFollower = (userId) => {

    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    return function (dispatch) {
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/user/removeFollower`,
            { 
                followerId: userId,
                
            }, {
                    headers: { Authorization: `Bearer ${user?.token}` }
                })
                .then((res) => {
                    //console.log("follower=====>",res.data.data.successResult)
                    dispatch(getRemoveFollower(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};