import React, { Fragment, useState } from 'react'
import { CheckCircle, Star, UserPlus, X, XCircle } from 'react-feather'
import FlwrImg from '../Img/FlwrImg.png'
import EventImg from '../Img/EventImg.png'
import { Button, Col } from 'reactstrap'
import moment from 'moment'

const EventsListView = () => {
    const [isIconVisible, setIconVisibility] = useState(true);

    const handleIconClick = () => {
        setIconVisibility(false);
        // Perform additional actions if needed
    };
    return (
        <Fragment>
            <div className="Categories_Left_block left_bar m-3 mt-3 Categories-text">
                <div className="EventsCustom-card-head  biz-carddetail-blk d-dlex justify-content-between">
                    <div className='d-flex align-items-center' >
                        <div className='EventsCategories_calender'>
                            <UserPlus color='#81C14B' size={24} />
                        </div>
                        <h4 className='ml-2' style={{ fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>Invitation</h4>
                    </div>&nbsp;&nbsp;
                    <p style={{ color: "#0066FF", cursor: "pointer", fontSize: '12px', textWrap: 'nowrap' }} className='interest-texts' >See All→</p>
                </div>
                <div className="EventsCustom-card-head " >
                    <div className='events_Invitaion_Card_section w-100'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div className=' Invitaion_Card_section'>
                                <img src={EventImg} width={50} height={50} className="img-fluid Invitation_Img_section" alt='' />
                                <div className='ml-2'>
                                    <h5>Get started with live Music near you</h5>
                                    <p>{moment('2022-06-02').format('MMM Do YYYY')}</p>
                                </div>
                            </div>
                            <div>
                                {isIconVisible && (
                                    <div style={{ cursor: 'pointer' }} onClick={handleIconClick}>
                                        <XCircle color='#C4C4C4' size={20} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between mt-3' style={{ marginLeft: '-12px' }}>
                            <Button color="primary" className="w-50 d-flex justify-content-center" >Interested</Button>
                            <Button outline className=" w-50 ml-2 d-flex justify-content-center">Not interested</Button>
                        </div>

                    </div>
                    <div className='events_Invitaion_Card_section w-100 mt-3'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div className=' Invitaion_Card_section'>
                                <img src={EventImg} width={50} height={50} className="img-fluid Invitation_Img_section" alt='' />
                                <div className='ml-2'>
                                    <h5>Get started with live Music near you </h5>
                                    <p>{moment('2022-06-02').format('MMM Do YYYY')}</p>
                                </div>
                            </div>
                            {isIconVisible && (
                                <div style={{ cursor: 'pointer' }} onClick={handleIconClick}>
                                    <XCircle color='#C4C4C4' size={20} />
                                </div>
                            )}
                        </div>
                        <div className='d-flex justify-content-between mt-3' style={{ marginLeft: '-12px' }}>
                            <Button color="primary" className="w-50 d-flex justify-content-center" >Interested</Button>
                            <Button outline className=" w-50 ml-2 d-flex justify-content-center">Not interested</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default EventsListView