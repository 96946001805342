import React,{useEffect} from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { Link, NavLink } from 'react-router-dom';
import { Edit2, Eye, EyeOff, Link2, MessageCircle, MoreHorizontal, MoreVertical, Navigation, Send, Sliders, ThumbsUp, Trash2, User, Users } from 'react-feather';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
// import like2 from '../../NewComponents/IMG/like2.svg'
// import Artical from "./Img/Artical.png"
// import Murga from "./Img/Murga.png"
// import Lion from "./Img/Lion.png"
// import Hourse from "./Img/Murga.png"
// import Hiran from "./Img/hiran.png"
// import FilterImg from "./Img/Fltr.svg"
import { Pagination, Stack,PaginationItem } from '@mui/material';
import ArticleBody from './ArticleBodyComponent/ArticleBody';
import { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
    getTextPostsAction,
  } from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
  import { useLocation } from 'react-router-dom';
  import axios from 'axios';

const MyArticle = () => {
  const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');
  // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
const[articleSearch,setArticleSearch] = useState('')
const [pageIndex,setPageIndex]=useState(0);
const [pageSize,setPageSize]=useState(2);
const [category,setCategory]=useState('All');
const [searchedArticleData, setSearchedArticleData] = useState([]);
const [tabType,setTabType]=useState(0);
const [success,setSuccess]=useState(false);
const dispatch=useDispatch();
const [count,setCount]=useState(0);
const [errorFetching,setErrorFetching]=useState('')

    useEffect(() => {
        dispatch(getTextPostsAction("article",pageSize, pageIndex, articleSearch, userId));
        setSuccess(false)
      }, [pageIndex,pageSize,success]);

      useEffect(()=>{
        const fetchData = async () => {
          try {
              if (user) {
                  const response = await axios.post(
                      `${process.env.REACT_APP_IPURL}/post/getAllUserPosts`,
                      {postType:"article",pageSize:3, pageIndex:0, userId:userId},
                      { headers: { Authorization: `Bearer ${user.token}` } }
                  );
                  setCount(response.data.data.successResult.count);
                  // sweetAlertConfirmation("Successfully Followed")
              } else {
                setErrorFetching('no user found');
              }
          } catch (error) {
            setErrorFetching(error);
          }
       };
    
      fetchData();
      },[])

      const getArticlePosts = useSelector((state) => {
        return state.getTextPostsData;
      });
    
      const {
        loading: loadingAllArticlePOst,
        error: errorAllArticlePOst,
        result: resultAllArticlePost,
      } = getArticlePosts;
        
      const articleCategories = resultAllArticlePost?.map(row => row.articleCategory?.trim());
      const uniqueCategories = [...new Set(articleCategories)];

      useEffect(() => {
        let filteredData = resultAllArticlePost || [];
        
        const lowercasedSearch = articleSearch.toLowerCase();
        const lowercasedCategory = category.toLowerCase();
        
        filteredData = filteredData.filter(item => {
            const matchesSearch = item.caption?.toLowerCase().includes(lowercasedSearch) ||
                                  item.articleCategory?.toLowerCase().includes(lowercasedSearch);
            
            const matchesCategory = category === 'All' || item.articleCategory.toLowerCase() === lowercasedCategory;
            
            const matchesHiddenStatus = (tabType === 0) || 
                                        (tabType === 1 && item.hidden === 1) || 
                                        (tabType === 2 && item.hidden === 0);
            
            return matchesSearch && matchesCategory && matchesHiddenStatus;
        });
    
        setSearchedArticleData(filteredData);
      }, [category, articleSearch, resultAllArticlePost, tabType, success]);

      const handleSuccess = () => {
        setSuccess(true); // Trigger useEffect in parent
      };


      
    
const pageCount = Math.ceil(count/pageSize);
const isPrevDisabled = pageIndex <= 0;
const isNextDisabled = pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === pageIndex) return;

    setPageIndex(value - 1);
  };


const BasicsPagination = () => {
  return (
    <>
      <Stack>
        <Pagination
             count={pageCount}
             page={pageIndex + 1}
             onChange={handlePageChange}
             className="PaginationCounts"
             renderItem={(item) => (
               <PaginationItem
                 {...item}
                 disabled={
                   (item.type === 'previous' && isPrevDisabled) ||
                   (item.type === 'next' && isNextDisabled)
                 }
               />
             )}
        ></Pagination>
      </Stack>
    </>
  );
};

    // Main_return_section
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    {/* <ProfileCover /> */}
                    <ProfileMenu userId={userId} type={type}/>
                    {/* <HeadingSection /> */}
                    <div className="container-fluid section-t-space px-0">
                    <div className="page-content">
                        <div className="content-center w-100">
                            <div className="gallery-page-section section-b-space">
                                <div className="card-title">
                                    <h3>My Article ({count||0})</h3>
                                    <div className="right-setting">
                                        <div className="profile-search-blk ">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            name="search" 
                                            placeholder="Find ..." 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#peopleSearch" 
                                            value={articleSearch}
                                            onChange={(e) => {setArticleSearch(e.target.value)}}
                                            />
                                        </div>
                                        <UncontrolledButtonDropdown className=" ml-3">
                                            <DropdownToggle outline className="border border-muted ">
                                                <Sliders className='Slider_Filter' size={12} />&nbsp;&nbsp;Filter
                                            </DropdownToggle>
                                            <DropdownMenu className="DropDown_item">
                                                 <DropdownItem onClick={(e)=>setCategory('All')}>
                                                    All
                                                    </DropdownItem>
                                                {uniqueCategories?.map((data,index)=>(
                                                    <DropdownItem key={index} onClick={(e)=>setCategory(data)}>
                                                    {data}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <UncontrolledButtonDropdown className=" ml-3">
                                            <DropdownToggle outline className="border border-muted ">
                                                Type
                                            </DropdownToggle>
                                            <DropdownMenu className="DropDown_item">
                                                <DropdownItem onClick={(e)=>setTabType(0)}>
                                                    All
                                                </DropdownItem>
                                                <DropdownItem onClick={(e)=>setTabType(1)}>
                                                    Hidden from timeline
                                                </DropdownItem>
                                                <DropdownItem onClick={(e)=>setTabType(2)}>
                                                    Visible to timeline
                                                </DropdownItem>
                                               
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <Link to="/EditorArticle" className="btn btn-outline-green ms-3"> + Create New</Link>
                                    </div>
                                </div>
                                {searchedArticleData?.length > 0 && searchedArticleData ? (
                                    <>
                                    <ArticleBody onSuccess={handleSuccess} data={searchedArticleData}/>
                                    <div className="d-flex justify-content-center">
                                    <BasicsPagination />
                                    </div>
                                    </>
                                ):(
                                    <>
                                    {tabType === 1 ? (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '15vh', // Adjust the height as needed to fill the available space
                                            width: '100%' // Ensure it takes the full width of the container
                                          }}>
                                          <h2 style={{ textAlign: 'center' }}>No Hidden Article Posts</h2>
                                        </div>
                                      ) : tabType === 2 ? (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '15vh', // Adjust the height as needed to fill the available space
                                            width: '100%' // Ensure it takes the full width of the container
                                          }}>
                                          <h2 style={{ textAlign: 'center' }}>No Visible Article Posts</h2>
                                        </div>
                                      ) : (
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '15vh', // Adjust the height as needed to fill the available space
                                            width: '100%' // Ensure it takes the full width of the container
                                          }}>
                                          <h2 style={{ textAlign: 'center' }}>No Article Posts</h2>
                                        </div>
                                      )}
                                    </>
                                    
                                )}
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <RightSidebar />
            </div>
        </>
    )
}

export default MyArticle