import React from 'react'
import { Button, Card, Col, Row } from 'reactstrap'
import Recc from '../../../../NewComponents/IMG/Exampleimagerecc.svg'

const Recommendations = ({ action }) => {
    return (
        <>
            <Row>
                <Col xl='12'>
                    <Card>
                        <img src={Recc} alt='reccImg' />
                        <div className='d-flex justify-content-between align-items-center w-100 positionForbgColorRecco'>
                            <h4 className="text-white">25 Recommendations</h4>
                            <Button color='white' onClick={() => action()} className='btn btn-outline' outline>Recommend</Button>
                        </div>

                        <h3 className='m-3'>I am seeking recommendation for buying a car</h3>
                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default Recommendations