import React, { Fragment } from 'react'
import { useState } from 'react'
import { Button } from 'reactstrap'
import { GeneralSettingData } from './SettingArray'
import DeleteModal from './SettingModal/DeleteModal'
import Pinsvg from '../Img/PinSvg.svg'
import { getAboutUsAction, postPinOrUnpinBizPageAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'

const GeneralSetting = (props) => {

    let dispatch = useDispatch();
    const { id } = useParams();
    let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));

    const getAbout = useSelector((state) => { return state.getAboutUsData });
    const { loading: loadingGetAbout, error: errorGetAbout, result: resultGetAbout } = getAbout


    const isPinned = useSelector((state) => { return state.postPinOrUnpinBizPageData });
    const { loading: loadingIsPinned, error: errorIsPinned, result: resultIsPinned } = isPinned

    console.log('Instant', resultGetAbout, 'HJGJG', resultIsPinned)

    useEffect(() => {
        if (resultIsPinned?.businessPage?.[1] === 1) {
            (sweetAlertConfirmation('Successfully pinned the page'))
            dispatch(getAboutUsAction(id))
        } else if (resultIsPinned?.businessPage?.[1] === 0) {
            (sweetAlertConfirmation('Successfully unpinned the page'))
            dispatch(getAboutUsAction(id))
        }

    }, [resultIsPinned]);


    const [isPinZero, setIsPinZero] = useState('')

    useEffect(() => {
        dispatch(getAboutUsAction(id))
        setIsPinZero(resultGetAbout?.[0]?.isPin)
        // setAllowMessage (resultGetAbout?.[0]?.allowMessage)
        // setAllowQuestion (resultGetAbout?.[0]?.allowQuestions)
        // setVisibility (resultGetAbout?.[0]?.visibility)
        // setPageSuggestions (resultGetAbout?.[0]?.allowPageSuggestion)
    }, []);

    const [bizDelOpen, setBizDelOpen] = useState(false)
    const toggleBizDel = () => {
        setBizDelOpen((preState) => !preState)
    }
    const [forgetActive, setForgetActive] = useState(false);
    const toggleForget = () => {
        setForgetActive(!forgetActive)
    }

    const pinUnPinBizPage = (pinValue) => {
        console.log(pinValue, "pinValue")
        dispatch(postPinOrUnpinBizPageAction(id, resultGetAbout?.[0]?.allowMessage, resultGetAbout?.[0]?.allowQuestion, resultGetAbout?.[0]?.visibility, resultGetAbout?.[0]?.allowPageSuggestion, pinValue))
        // dispatch(getAboutUsAction('dd1bedf0-2011-46df-991c-fce411b1617d'))     
    }

    const [allowMessage, setAllowMessage] = useState(false);
    const [allowQuestion, setAllowQuestion] = useState(false);
    const [allowVisibility, setAllowVisibility] = useState(false);
    const [allowPageSuggestions, setAllowPageSuggestion] = useState(false);

    useEffect(() => {
        setAllowMessage(resultGetAbout?.[0]?.allowMessage || false);
        setAllowQuestion(resultGetAbout?.[0]?.allowQuestion || false);
        setAllowVisibility(resultGetAbout?.[0]?.visibility || false);
        setAllowPageSuggestion(resultGetAbout?.[0]?.allowPageSuggestion || false);
    }, [resultGetAbout]);

    function handleCheckboxAllowMessageChange() {
        setAllowMessage(!allowMessage);
    }

    function handleCheckboxAllowQuestionChange() {
        setAllowQuestion(!allowQuestion);
    }

    function handleCheckboxAllowVisibilityChange() {
        setAllowVisibility(!allowVisibility);
    }

    function handleCheckboxAllowSuggestionChange() {
        setAllowPageSuggestion(!allowPageSuggestions);
    }

    function saveAllSettings() {
        dispatch(postPinOrUnpinBizPageAction(id, allowMessage, allowQuestion, allowVisibility, allowPageSuggestions, resultGetAbout?.[0]?.isPin))
    }
    const [isPinned1, setPinned] = useState(false);

    const handlePinToggle = () => {
        setPinned((prevState) => !prevState);
    };

    return (
        <Fragment>
            <>
                <div className="custom-card-block">
                    <div className="customAbout-card-head" onClick={() => { props.setsetsideclassoption(false); }}style={{cursor:"pointer"}} >
                    
                        <h3> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  className='hide-setting-options12'>
                            <path d="M16.6195 2.98833C16.5034 2.87192 16.3655 2.77956 16.2136 2.71655C16.0617 2.65353 15.8989 2.62109 15.7345 2.62109C15.5701 2.62109 15.4073 2.65353 15.2554 2.71655C15.1036 2.77956 14.9656 2.87192 14.8495 2.98833L6.53951 11.2983C6.44681 11.3908 6.37326 11.5007 6.32308 11.6217C6.2729 11.7427 6.24707 11.8724 6.24707 12.0033C6.24707 12.1343 6.2729 12.264 6.32308 12.385C6.37326 12.5059 6.44681 12.6158 6.53951 12.7083L14.8495 21.0183C15.3395 21.5083 16.1295 21.5083 16.6195 21.0183C17.1095 20.5283 17.1095 19.7383 16.6195 19.2483L9.37951 11.9983L16.6295 4.74833C17.1095 4.26833 17.1095 3.46833 16.6195 2.98833Z" fill="#4E4E4E" />
                        </svg>General Setting</h3>
                    </div>
                    <div className="custom-card-body">
                        <div className="biz-main-SettingTab-blk ">


                        {/* when api is working then we can use this code for pin and unpin  */}


                            {/* <div className='PinPage'>
                                <div>
                                    <h4>Pin Page </h4>
                                    <p>your pinned page will appear on top of your page list</p>
                                </div>
                                <p style={{ cursor: "pointer" }} className='text-primary'>
                                    {resultGetAbout?.[0]?.isPin}jjjjj
                                    {resultGetAbout?.[0]?.isPin ?
                                        <> 
                                        <div onClick={() => pinUnPinBizPage(0)}> Unpin 
                                        </div></> :
                                        <>
                                            <div  onClick={() => pinUnPinBizPage(1)} style={{
                                                display:"flex"
                                            }}>
                                                <img src={Pinsvg} />&nbsp;Pin
                                            </div>
                                        </>
                                    } </p>
                            </div> */}
                            <div className=''>
                                <div className='d-flex justify-content-between'>
                                    <h4>Pin Page </h4>
                                    <p style={{ cursor: 'pointer' }} className='text-primary' onClick={handlePinToggle}>
                                    {isPinned1 ? (
                                        <>
                                            <div style={{ display: 'flex' }}>
                                            <img src={Pinsvg} alt="Pin Icon" />&nbsp;Unpin
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ display: 'flex' }}>
                                                {/* <img src={Pinsvg} alt="Pin Icon" /> */}
                                                &nbsp;Pin
                                            </div>
                                        </>
                                    )}
                                </p>
                                    
                                </div>
                                <p>your pinned page will appear on top of your page list</p>
                                
                            </div>
                        </div>

                        <div className="biz-main-SettingTab-blk ">
                            <div className=''>
                                <div  className='d-flex justify-content-between'>
                                    <h4>Messenger</h4>
                                    <div className=' d-flex'>
                                    <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={allowMessage}
                                            onChange={handleCheckboxAllowMessageChange}
                                        />

                                        <span className="slider round"></span>
                                    </label>
                                    <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                </div>
                                    
                                </div>
                                <p>Allow people to contact your page privately in SocioMee Messenger</p>
                            </div>
                        </div>


                        <div className="biz-main-SettingTab-blk ">
                            <div className='' >
                                <div  className='d-flex justify-content-between'>
                                    <h4>Allow people to ask Question on page</h4>
                                    <div className=' d-flex'>
                                    <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={allowQuestion}
                                            onChange={handleCheckboxAllowQuestionChange}
                                        />

                                        <span className="slider round"></span>
                                    </label>
                                    <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                </div>
                                </div>
                              
                                <p>Allow people to ask you question on page, which will be visible to everyone publicly after you have answered them. </p>
                            </div>
                        </div>


                        <div className="biz-main-SettingTab-blk">
                            <div className='DelBizData'>
                                <h4>Delete Business page </h4>
                                <p>Your, page will be deleted along with all posts, connections and information permanently.</p>

                            </div>
                            <div className='pt-4'>
                                <Button color='orange' onClick={toggleBizDel} >
                                    Delete BizPage Permanently
                                </Button>
                            </div>
                        </div>
                        <div className="biz-main-SettingTab-blk">
                            <div className='P' >
                                <div  className='d-flex justify-content-between'>
                                    <h4>Page Visibility</h4>
                                    <div className=' d-flex'>
                                    <div className="mr-2 BizSetting_msg_toggle py-1">Unpublish</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={allowVisibility}
                                            onChange={handleCheckboxAllowVisibilityChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className='ml-2 BizSetting_msg_toggle py-1' >Publish</div>
                                </div>
                                   
                                </div>
                                <p>Enabling this would make your page visible to the public. Unpublished pages will only appear to people with a role on the page.
 </p>
                            </div>
                        </div>
                        <div className="biz-main-SettingTab-blk">
                            <div className=''  >
                                <div  className='d-flex justify-content-between' >
                                    <h4>Similer page Suggestion</h4>
                                  
                                    <div className=' d-flex'>
                                    <div className="mr-2 BizSetting_msg_toggle py-1">Off</div>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={allowPageSuggestions}
                                            onChange={handleCheckboxAllowSuggestionChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className='ml-2 BizSetting_msg_toggle py-1' >On</div>
                                </div>
                                </div>
                                <p>Include Gong Advertisment when SocioMee recommends similar Pages to users</p>
                                
                            </div>
                        </div>
                        <div className="biz-main-about-blk text-end ">
                            {/* <Button outline className="w-25">Cancel</Button> */}
                            <Button color="primary" className="ml-2 w-25" onClick={saveAllSettings}>Save </Button>
                        </div>
                    </div>
                </div>
                {/* =====================Start_modal_section ========================== */}
                <DeleteModal
                    isOpen={bizDelOpen}
                    UpdateOpen={toggleBizDel}
                    activeState={forgetActive}
                    updateState={toggleForget}
                    businessPageId={id}
                />
                {/* =====================end_modal_section ========================== */}
            </>
        </Fragment>
    )
}

export default GeneralSetting