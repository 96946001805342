import React from 'react'
import { useState } from 'react';
import { Fragment } from 'react'
import { Copy, Eye, Flag, MoreVertical, Music, Share, Volume2, VolumeX, XCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, Row, UncontrolledButtonDropdown } from 'reactstrap';
import shotz_white from '../../../NewComponents/IMG/shotz_white.svg'
import like_circle_gray from '../../../NewComponents/IMG/like_circle_gray.png'
import comments_circle_gray from '../../../NewComponents/IMG/comment_circle_gray.png'
import share_circle_gray from '../../../NewComponents/IMG/share_circle_gray.png'
import HideModal from '../ShotzModal/HideModal';
import ReportShotzModal from '../ShotzModal/ReportShotzModal';
import ShareModal from '../ShotzModal/ShareModal';
import DownloadModal from '../ShotzModal/DownloadModal';
import { Button } from 'react-bootstrap';
import '../../Shotzs/ShotzStyle.css'

const ShotzModal = (props) => {
    const [forwardOpen, setForwardOpen] = useState(false)
    const [volume, setvolume] = useState(false);

    // ------------- Select File State -------------
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    // ------------- Download Modal State -------------
    const [downloadModalOpen, setDownloadModalOpen] = useState(false)
    const toggleDownloadModalOpen = () => {
        setDownloadModalOpen((prevState) => !prevState)
    }
    // ------------- Archive Modal State -------------
    const [hideShotzModalOpen, setHideShotzModalOpen] = useState(false)
    const toggleHideShotzModalOpen = () => {
        setHideShotzModalOpen((prevState) => !prevState)
    }
    // ------------- Report Modal State -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    return (
        <Fragment>
            <ShareModal
                isOpen={forwardOpen}
                shareModalToOpen={toggleForward}
            />
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.ShotzModalCarousel} className='modal-dialog carousel-container modal-lg'>
                    <XCircle size={30} className='close-icon' onClick={props.ShotzModalCarousel} />
                    <div className='shotz-border'>
                        <Row>
                            <Col xl='7' lg='7' md='7' sm='7' className='mr-0 pr-0 '>
                                <div className="media-images-blk border-0 shotz-album-block align-items-center">
                                    <div className="main-shotz-blk-list d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#shotzModel">
                                        <video
                                            className='w-100 stotz-video-style img-fluid'
                                            controls={false}
                                            autoPlay>
                                            <source src='https://vod-progressive.akamaized.net/exp=1680349222~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F390%2F29%2F726954265%2F3370901662.mp4~hmac=13cca758a718170f7b045996e90ebadeb5cbda23bf5defefde9b26e7a78ed535/vimeo-prod-skyfire-std-us/01/390/29/726954265/3370901662.mp4?filename=file.mp4' className='img-fluid h-100' type="video/mp4" />
                                        </video>
                                        <div className="text-gallery d-flex align-items-center justify-content-center">
                                            <div className="top-gallery-cont d-flex align-items-center">
                                                <img src={shotz_white} className="img-fluid" alt="...." />
                                                <div className='mx-2'>
                                                    <span className='text-white '> <Eye color='#ffffff' className='mx-2' size={20} />&nbsp;126</span>
                                                    {/* <ChevronUp size={40} color='#ffffff' /> */}
                                                </div>
                                            </div>

                                            <div className="bottom-gallery-cont">
                                                <div>
                                                    <div className="my-2 d-flex">
                                                        <Music color='#ffffff' size={18} />&nbsp;&nbsp;
                                                        <h5 className='text-white'>Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt .........</h5>
                                                    </div>
                                                    <div>
                                                        <h4 className='text-white my-2'>Live Cornetto Nutella Ice Cream Rolls 😳<span className='text-primary'> #shorts #streetfood #ashortaday #icecream</span></h4>
                                                        <div className='d-flex justify-content-between'>
                                                            <Button variant='outline-primary' className='text-white'>Follow</Button>
                                                            <div className='d-flex justify-content-end'>
                                                                <div className='d-flex'>
                                                                    <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >
                                                                        {volume ? (<Volume2 color='#ffffff' className='mr-2' size={30} />) : (<VolumeX color='#ffffff' className='mr-2' size={30} />)}
                                                                    </p>
                                                                    {/* <Volume2 color='#ffffff' className='mr-2' size={30} /> */}
                                                                    {/* <ChevronDown color='#ffffff' size={40} /> */}
                                                                </div>
                                                                <a class="down carousel-control" href="#carousel-example-vertical" role="button" data-slide="next">
                                                                    <span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='5' lg='5' md='5' sm='5' className='ml-0 pl-0'>
                                <div className="comment-part">
                                    <div className="user-detail d-flex justify-content-between">
                                        <div className="user-media py-3">
                                            <div className="media">
                                                <Link className="mx-2">
                                                    <img src="/assets/images/story-3.jpg" height='40px' width='40px' className="rounded-circle" alt="user" />
                                                    <span className="available-stats"></span>
                                                </Link>
                                                <div className="media-body">
                                                    <h5 className="user-name">Paige Turner</h5>
                                                    <h6>alabma, USA</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <UncontrolledButtonDropdown className=''>
                                            <DropdownToggle color='flat' >
                                                <MoreVertical className='' size={20} />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleForward}>
                                                    <h5><Share size={13} />&nbsp; Share post</h5>
                                                </DropdownItem>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                                    <h5><Eye size={13} />&nbsp; View user</h5>
                                                </DropdownItem>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                    <h5><Copy size={13} />&nbsp; Copy link</h5>
                                                </DropdownItem>
                                                <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                    <h5><Flag size={13} />&nbsp; Report</h5>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                    <div className="post-panel mb-0">
                                        <div className="post-wrapper">
                                            <div className="post-details">
                                                <div className="like-panel">
                                                    <div className="left-emoji">
                                                        <ul>
                                                            <li>
                                                                <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                            </li>
                                                            <li>
                                                                <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                            </li>
                                                        </ul>
                                                        <h6>+75</h6>
                                                    </div>
                                                    <div className="right-stats">
                                                        <ul >
                                                            <li>
                                                                <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                            </li>
                                                            <li>
                                                                <span className='d-flex align-items-center'>985 &nbsp;<p className='text-muted'>Share</p></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="post-react">
                                                    <ul>
                                                        <li >
                                                            <img src={like_circle_gray} height='26px' width='26px' alt="like" />&nbsp;Like
                                                        </li>
                                                        <li >
                                                            <img src={comments_circle_gray} height='25px' width='25px' alt="comments" />&nbsp;Comment
                                                        </li>
                                                        <li >
                                                            <img src={share_circle_gray} height='25px' width='25px' alt="share" />&nbsp;share
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="comment-section">
                                                    <div className="comments d-block">
                                                        <div className="main-comment">
                                                            <div className="media">
                                                                <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                <div className="media-body ml-2">
                                                                    <h5>Pabelo Mukrani</h5>
                                                                    <p className='text-muted'>50 mins ago</p>
                                                                    <h6 className='py-1 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍&#128578;
                                                                    </h6>
                                                                    <ul className="comment-option">
                                                                        <li><Link to="/">like (5)</Link></li>
                                                                        <li><Link to="/">reply</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="sub-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h5>Pabelo Mukrani</h5>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                        <ul className="comment-option">
                                                                            <li><a >like</a></li>
                                                                            <li><a >reply</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body  ml-2">
                                                                        <h6>sufiya elija</h6>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                        <ul className="comment-option">
                                                                            <li><a >like</a></li>
                                                                            <li><a >reply</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <a className="loader">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                    load more replies
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="main-comment">
                                                            <div className="media">
                                                                <img src="/assets/images/story-2.jpg" width={30} height={30} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                <div className="media-body ml-2">
                                                                    <h5>Pabelo Mukrani</h5>
                                                                    <p className='text-muted'>50 mins ago</p>
                                                                    <h6 className='py-1 profile-comments text-wrap'>As there is no companion loyal and loving than a dog, we are more than elated to present a awesome collection of happy birthday wishes for dogs with pictures. If you go back in time, you will find that dogs have always been men’s best friend and a watchful protector also. From hunting to surveillance, a dog stands by side of its owner unflinchingly and follows him like a shadow everywhere.    &#128578;
                                                                    </h6>
                                                                    <ul className="comment-option">
                                                                        <li><Link to="/">like (5)</Link></li>
                                                                        <li><Link to="/">reply</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reply">
                                                        <div className="search-input input-style input-lg icon-right">
                                                            <Input type="text" className="form-control emojiPicker"
                                                                placeholder="Comment...." />
                                                            <Link to="/">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='shotz-border'>
                        <Row>
                            <Col xl='7' lg='7' md='7' sm='7' className='mr-0 pr-0 '>
                                <div className="media-images-blk border-0 shotz-album-block align-items-center">
                                    <div className="main-shotz-blk-list d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#shotzModel">
                                        <video
                                            className='w-100 stotz-video-style img-fluid'
                                            controls={false}
                                            autoPlay>
                                            <source src='https://download-video.akamaized.net/2/playback/66c05a72-9bff-4b18-92b3-ef62a9c0eb94/d4a46b39-b3e4e923?__token__=st=1680334812~exp=1680349212~acl=%2F2%2Fplayback%2F66c05a72-9bff-4b18-92b3-ef62a9c0eb94%2Fd4a46b39-b3e4e923%2A~hmac=4d35c39b7642709f7888c381bbee020be076504b074aa1b07591ff23ea70c5b8&r=dXM%3D' className='img-fluid h-100' type="video/mp4" />
                                        </video>
                                        <div className="text-gallery d-flex align-items-center justify-content-center">
                                            <div className="top-gallery-cont d-flex align-items-center">
                                                <img src={shotz_white} className="img-fluid" alt="...." />
                                                <div className='mx-2'>
                                                    <span className='text-white '> <Eye color='#ffffff' className='mx-2' size={20} />&nbsp;126</span>
                                                    {/* <ChevronUp size={40} color='#ffffff' /> */}
                                                </div>
                                            </div>

                                            <div className="bottom-gallery-cont">
                                                <div>
                                                    <div className="my-2 d-flex">
                                                        <Music color='#ffffff' size={18} />&nbsp;&nbsp;
                                                        <h5 className='text-white'>Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt .........</h5>
                                                    </div>
                                                    <div>
                                                        <h4 className='text-white my-2'>Live Cornetto Nutella Ice Cream Rolls 😳<span className='text-primary'> #shorts #streetfood #ashortaday #icecream</span></h4>
                                                        <div className='d-flex justify-content-between'>
                                                            <Button variant='outline-primary' className='text-white'>Follow</Button>
                                                            <div className='d-flex justify-content-end'>
                                                                <div className='d-flex'>
                                                                    <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >
                                                                        {volume ? (<Volume2 color='#ffffff' className='mr-2' size={30} />) : (<VolumeX color='#ffffff' className='mr-2' size={30} />)}
                                                                    </p>
                                                                    {/* <Volume2 color='#ffffff' className='mr-2' size={30} /> */}
                                                                    {/* <ChevronDown color='#ffffff' size={40} /> */}
                                                                </div>
                                                                <a class="down carousel-control" href="#carousel-example-vertical" role="button" data-slide="next">
                                                                    <span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='5' lg='5' md='5' sm='5' className='ml-0 pl-0'>
                                <div className="comment-part">
                                    <div className="user-detail d-flex justify-content-between">
                                        <div className="user-media py-3">
                                            <div className="media">
                                                <Link className="mx-2">
                                                    <img src="/assets/images/story-3.jpg" height='40px' width='40px' className="rounded-circle" alt="user" />
                                                    <span className="available-stats"></span>
                                                </Link>
                                                <div className="media-body">
                                                    <h5 className="user-name">Paige Turner</h5>
                                                    <h6>alabma, USA</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <UncontrolledButtonDropdown className=''>
                                            <DropdownToggle color='flat' >
                                                <MoreVertical className='' size={20} />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleForward}>
                                                    <h5><Share size={13} />&nbsp; Share post</h5>
                                                </DropdownItem>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                                    <h5><Eye size={13} />&nbsp; View user</h5>
                                                </DropdownItem>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                    <h5><Copy size={13} />&nbsp; Copy link</h5>
                                                </DropdownItem>
                                                <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                    <h5><Flag size={13} />&nbsp; Report</h5>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                    <div className="post-panel mb-0">
                                        <div className="post-wrapper">
                                            <div className="post-details">
                                                <div className="like-panel">
                                                    <div className="left-emoji">
                                                        <ul>
                                                            <li>
                                                                <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                            </li>
                                                            <li>
                                                                <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                            </li>
                                                        </ul>
                                                        <h6>+75</h6>
                                                    </div>
                                                    <div className="right-stats">
                                                        <ul >
                                                            <li>
                                                                <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                            </li>
                                                            <li>
                                                                <span className='d-flex align-items-center'>985 &nbsp;<p className='text-muted'>Share</p></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="post-react">
                                                    <ul>
                                                        <li >

                                                            <img src={like_circle_gray} height='26px' width='26px' alt="like" />&nbsp;Like

                                                        </li>
                                                        <li >

                                                            <img src={comments_circle_gray} height='25px' width='25px' alt="comments" />&nbsp;Comment

                                                        </li>
                                                        <li >

                                                            <img src={share_circle_gray} height='25px' width='25px' alt="share" />&nbsp;share

                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="comment-section">
                                                    <div className="comments d-block">
                                                        <div className="main-comment">
                                                            <div className="media">
                                                                <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                <div className="media-body ml-2">
                                                                    <h5>Pabelo Mukrani</h5>
                                                                    <p className='text-muted'>50 mins ago</p>
                                                                    <h6 className='py-1 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍&#128578;
                                                                    </h6>
                                                                    <ul className="comment-option">
                                                                        <li><Link to="/">like (5)</Link></li>
                                                                        <li><Link to="/">reply</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="sub-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h5>Pabelo Mukrani</h5>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                        <ul className="comment-option">
                                                                            <li><a >like</a></li>
                                                                            <li><a >reply</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body  ml-2">
                                                                        <h6>sufiya elija</h6>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                        <ul className="comment-option">
                                                                            <li><a >like</a></li>
                                                                            <li><a >reply</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <a className="loader">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                    load more replies
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="main-comment">
                                                            <div className="media">
                                                                <img src="/assets/images/story-2.jpg" width={30} height={30} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                <div className="media-body ml-2">
                                                                    <h5>Pabelo Mukrani</h5>
                                                                    <p className='text-muted'>50 mins ago</p>
                                                                    <h6 className='py-1 profile-comments text-wrap'>As there is no companion loyal and loving than a dog, we are more than elated to present a awesome collection of happy birthday wishes for dogs with pictures. If you go back in time, you will find that dogs have always been men’s best friend and a watchful protector also. From hunting to surveillance, a dog stands by side of its owner unflinchingly and follows him like a shadow everywhere.    &#128578;
                                                                    </h6>
                                                                    <ul className="comment-option">
                                                                        <li><Link to="/">like (5)</Link></li>
                                                                        <li><Link to="/">reply</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reply">
                                                        <div className="search-input input-style input-lg icon-right">
                                                            <Input type="text" className="form-control emojiPicker"
                                                                placeholder="Comment...." />
                                                            <Link to="/">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col xl='7' lg='7' md='7' sm='7' className='mr-0 pr-0 '>
                                <div className="media-images-blk border-0 shotz-album-block align-items-center">
                                    <div className="main-shotz-blk-list d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#shotzModel">
                                        <video
                                            className='w-100 stotz-video-style img-fluid'
                                            controls={false}
                                            autoPlay>
                                            <source src='https://vod-progressive.akamaized.net/exp=1680349138~acl=%2Fvimeo-prod-skyfire-std-us%2F01%2F1971%2F22%2F559857411%2F2647574468.mp4~hmac=d0e19169a73f6c5aabc68239568f5ce9910a70d2827d8038edb33ec98532dff1/vimeo-prod-skyfire-std-us/01/1971/22/559857411/2647574468.mp4' className='img-fluid h-100' type="video/mp4" />
                                        </video>
                                        <div className="text-gallery d-flex align-items-center justify-content-center">
                                            <div className="top-gallery-cont d-flex align-items-center">
                                                <img src={shotz_white} className="img-fluid" alt="...." />
                                                <div className='mx-2'>
                                                    <span className='text-white '> <Eye color='#ffffff' className='mx-2' size={20} />&nbsp;126</span>
                                                    {/* <ChevronUp size={40} color='#ffffff' /> */}
                                                </div>
                                            </div>

                                            <div className="bottom-gallery-cont">
                                                <div>
                                                    <div className="my-2 d-flex">
                                                        <Music color='#ffffff' size={18} />&nbsp;&nbsp;
                                                        <h5 className='text-white'>Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt .........</h5>
                                                    </div>
                                                    <div>
                                                        <h4 className='text-white my-2'>Live Cornetto Nutella Ice Cream Rolls 😳<span className='text-primary'> #shorts #streetfood #ashortaday #icecream</span></h4>
                                                        <div className='d-flex justify-content-between'>
                                                            <Button variant='outline-primary' className='text-white'>Follow</Button>
                                                            <div className='d-flex justify-content-end'>
                                                                <div className='d-flex'>
                                                                    <p aria-label={volume ? 'volumex' : 'volume2'} onClick={() => setvolume(!volume)} >
                                                                        {volume ? (<Volume2 color='#ffffff' className='mr-2' size={30} />) : (<VolumeX color='#ffffff' className='mr-2' size={30} />)}
                                                                    </p>
                                                                    {/* <Volume2 color='#ffffff' className='mr-2' size={30} /> */}
                                                                    {/* <ChevronDown color='#ffffff' size={40} /> */}
                                                                </div>
                                                                <a class="down carousel-control" href="#carousel-example-vertical" role="button" data-slide="next">
                                                                    <span class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                                                                    <span class="sr-only">Next</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl='5' lg='5' md='5' sm='5' className='ml-0 pl-0'>
                                <div className="comment-part">
                                    <div className="user-detail d-flex justify-content-between">
                                        <div className="user-media py-3">
                                            <div className="media">
                                                <Link className="mx-2">
                                                    <img src="/assets/images/story-3.jpg" height='40px' width='40px' className="rounded-circle" alt="user" />
                                                    <span className="available-stats"></span>
                                                </Link>
                                                <div className="media-body">
                                                    <h5 className="user-name">Paige Turner</h5>
                                                    <h6>alabma, USA</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <UncontrolledButtonDropdown className=''>
                                            <DropdownToggle color='flat' >
                                                <MoreVertical className='' size={20} />
                                            </DropdownToggle>
                                            <DropdownMenu className='bizpagefordropdownmenu'>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleForward}>
                                                    <h5><Share size={13} />&nbsp; Share post</h5>
                                                </DropdownItem>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style' >
                                                    <h5><Eye size={13} />&nbsp; View user</h5>
                                                </DropdownItem>
                                                <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                    <h5><Copy size={13} />&nbsp; Copy link</h5>
                                                </DropdownItem>
                                                <DropdownItem className='py-2 menu-hover-style' onClick={toggleReport}>
                                                    <h5><Flag size={13} />&nbsp; Report</h5>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                    <div className="post-panel mb-0">
                                        <div className="post-wrapper">
                                            <div className="post-details">
                                                <div className="like-panel">
                                                    <div className="left-emoji">
                                                        <ul>
                                                            <li>
                                                                <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                            </li>
                                                            <li>
                                                                <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                            </li>
                                                        </ul>
                                                        <h6>+75</h6>
                                                    </div>
                                                    <div className="right-stats">
                                                        <ul >
                                                            <li>
                                                                <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                            </li>
                                                            <li>
                                                                <span className='d-flex align-items-center'>985 &nbsp;<p className='text-muted'>Share</p></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="post-react">
                                                    <ul>
                                                        <li >
                                                            <img src={like_circle_gray} height='26px' width='26px' alt="like" />&nbsp;Like
                                                        </li>
                                                        <li >
                                                            <img src={comments_circle_gray} height='25px' width='25px' alt="comments" />&nbsp;Comment
                                                        </li>
                                                        <li >
                                                            <img src={share_circle_gray} height='25px' width='25px' alt="share" />&nbsp;share
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="comment-section">
                                                    <div className="comments d-block">
                                                        <div className="main-comment">
                                                            <div className="media">
                                                                <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                <div className="media-body ml-2">
                                                                    <h5>Pabelo Mukrani</h5>
                                                                    <p className='text-muted'>50 mins ago</p>
                                                                    <h6 className='py-1 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍&#128578;
                                                                    </h6>
                                                                    <ul className="comment-option">
                                                                        <li><Link to="/">like (5)</Link></li>
                                                                        <li><Link to="/">reply</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="sub-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h5>Pabelo Mukrani</h5>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                        <ul className="comment-option">
                                                                            <li><a >like</a></li>
                                                                            <li><a >reply</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body  ml-2">
                                                                        <h6>sufiya elija</h6>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h6 className='py-1'>Thank You So Much ❤❤</h6>
                                                                        <ul className="comment-option">
                                                                            <li><a >like</a></li>
                                                                            <li><a >reply</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <a className="loader">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                    load more replies
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="main-comment">
                                                            <div className="media">
                                                                <img src="/assets/images/story-2.jpg" width={30} height={30} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                <div className="media-body ml-2">
                                                                    <h5>Pabelo Mukrani</h5>
                                                                    <p className='text-muted'>50 mins ago</p>
                                                                    <h6 className='py-1 profile-comments text-wrap'>As there is no companion loyal and loving than a dog, we are more than elated to present a awesome collection of happy birthday wishes for dogs with pictures. If you go back in time, you will find that dogs have always been men’s best friend and a watchful protector also. From hunting to surveillance, a dog stands by side of its owner unflinchingly and follows him like a shadow everywhere.    &#128578;
                                                                    </h6>
                                                                    <ul className="comment-option">
                                                                        <li><Link to="/">like (5)</Link></li>
                                                                        <li><Link to="/">reply</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reply">
                                                        <div className="search-input input-style input-lg icon-right">
                                                            <Input type="text" className="form-control emojiPicker"
                                                                placeholder="Comment...." />
                                                            <Link to="/">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }

            {/* ------------- Downlode Modal ------------- */}
            <DownloadModal
                isOpen={downloadModalOpen}
                downloadModalOpens={toggleDownloadModalOpen}
            />
            {/*  ------------- Start Hide Shotz Modal ------------- */}
            <HideModal
                isOpen={hideShotzModalOpen}
                hideModalOpen={toggleHideShotzModalOpen}
            />
            {/*  ------------- Start Report Shotz Modal ------------- */}
            <ReportShotzModal
                isOpen={reportOpen}
                reportModalOpen={toggleReport}
            />
            {/*  ------------- End Report Shotz Modal ------------- */}
        </Fragment>
    )
}

export default ShotzModal