import React, { useEffect, useState } from "react";
import {
  Copy,
  Flag,
  Check,
  CornerLeftDown,
  MinusCircle,
  MoreVertical,
  PlusCircle,
  Share,
  UserPlus,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { loadAllUserFollowers } from "../../../Services/Actions/Common/getUserFollowersAction";
import { loadAllFollowUnFollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import { MutualFriendList } from "../HoverComponents/MutualFriendList";
import BlockModal from "../ConnectionModal/BlockModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import UnfollowModal from "../ConnectionModal/UnfollowModal";
import NewRemoveModal from "../ConnectionModal/NewRemoveModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import "../Style.css";
import PublicAccount from "../ConnectionModal/PublicAccount";
import PrivateAccount from "../ConnectionModal/PrivateAccount";
import { NavLink } from "react-router-dom";

export const FollowersList = ({followingData, withdrawUserRequest}) => {
  //const { getUserFollowers } = useSelector(
  //  (state) => state.GetUserFollowersData
  //);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [fullName, setFullName]= useState("");

  const [selectedRequest, setSelectedRequest] = useState(null);
  useEffect(() => {
    dispatch(loadAllUserFollowers());
  }, []);

  // ----------- share Modal open -----------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };
  // ----------- Remove Followers Modal open -----------
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const toggleRemoveModalOpen = () => {
    setRemoveModalOpen((prevState) => !prevState)
  }
  const[removeConfirm,setRemoveConfirm] = useState(false)
    useEffect(()=>{
     if(removeConfirm){
        setTimeout(() => {
          dispatch(loadAllUserFollowers());
          setRemoveConfirm(false);
        }, 1000);
     } 
    },[removeConfirm])

// ------------- Unfollow Modal State -------------
  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false)
  const toggleUnfollowModal = (request) => {
    setUnfollowModalOpen((prevState) => !prevState)
    setSelectedRequest(request);
  }
  const[unfollowConfirm,setunfollowConfirm] = useState(false)
  useEffect(()=>{
    if(unfollowConfirm){
      setTimeout(() => {
        dispatch(loadAllUserFollowers());
        setunfollowConfirm(false);
      }, 1000);
    } 
  },[unfollowConfirm])

  // handle follow back
  const handleFollowBack = async (request) => {
    await dispatch(loadAllFollowUnFollow(request));
    dispatch(loadAllUserFollowers());
  };

  // handle withdraw requests
  const handlewithDrawRequests = (request) => {
    withdrawUserRequest(request.followingRequestId)
    setTimeout(() => {
      dispatch(loadAllUserFollowers());
    }, 1000);
  }


  //============block modal------------------------------------
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const toggleBlockModalOpen = () => {
    setBlockModalOpen((prevState) => !prevState);
  };
  const[blockConfirm,setBlockConfirm] = useState(false)
  useEffect(()=>{
    if(blockConfirm){
       setTimeout(() => {
        dispatch(loadAllUserFollowers());
         setBlockConfirm(false);
       }, 1000);
    } 
   },[blockConfirm])


  //  ----------- Start ReportedOption  -----------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
    setSelectedRequest(request);
  };

  const[reportConfirm,setReportConfirm] = useState(false)
  useEffect(()=>{
    if(reportConfirm){
      setTimeout(() => {
        dispatch(loadAllUserFollowers());
        setReportConfirm(false);
      }, 1000);
   } 
  },[reportConfirm])

  //  ----------- MouseHover over Image state & function  -----------
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {followingData &&
        followingData.map((request, index) => {
          return (
            <div key={index} className="connection-card  box-shadow-style content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3">
              <div class="media media-new d-flex align-items-start">
                <div class="user-img d-flex align-items-start popover___wrapper ">
                  <img
                    src={request?.profileImage}
                    className="rounded-circle "
                    height={45}
                    width={45}
                    alt="shivam singh"
                  />
                  <span class="available-stats online"></span>
                  {request?.isPrivate ? <PrivateAccount request={request}/> : <PublicAccount request={request}/>}
                </div>
                <div className="sub-nav-flex">
                  <div class="media-body d-md-block pl-1">
                    <NavLink to="/MyProfile">
                      <h4 className="m-0 text-wrap name-style d-inline-block text-truncate">
                        {request?.fullName?.substring(0, 21) || "NA"}
                      </h4>
                    </NavLink>
                    <div className="d-flex my-1 text-wrap">
                      <p className="user-name-style text-wrap rem-mob-view-small">
                        {request?.userName || "NA"} |
                      </p>
                      <div className="d-flex ml-1">
                        <span><img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} /></span>
                        <span className="professional-style ml-1">{request?.countryData[0]?.name}</span>
                      </div>
                    </div>
                    {/* <h6 className='professional-style text-wrap mr-5'>I am a freelance mobile app developer who specializes in native, as well as hybrid app development.</h6> */}
                    <MutualFriendList mutualfriendList={request?.mutualfriendList} />
                  </div>
                </div>
              </div>
              <div className="pt-2 ml-1">
                <ul
                  id="counter"
                  className="d-flex align-items-center justify-content-around "
                >
                  <li className="text-center">
                    <h3
                      className="counter-value green-text-style"
                      data-count="26335"
                    >
                      {request.totalPostCount}
                    </h3>
                    <h5 className="professional-style">Post</h5>
                  </li>
                  <li className="mx-3">
                    <hr
                      style={{ height: "30px", width: "1px", color: "#000000" }}
                    />
                  </li>
                  <li className="text-center ">
                    <h3
                      className="counter-value green-text-style"
                      data-count="546"
                    >
                      {request.followingCount}
                    </h3>
                    <h5 className="professional-style">Following</h5>
                  </li>
                  <li className="mx-3">
                    <hr
                      style={{ height: "30px", width: "1px", color: "#000000" }}
                    />
                  </li>
                  <li className="text-center">
                    <h3
                      className="counter-value green-text-style"
                      data-count="6845"
                    >
                      {request.followersCount}
                    </h3>
                    <h5 className="professional-style">Followers</h5>
                  </li>
                </ul>
              </div>
              <div className="ml-sm-4 ml-xs-4 d-flex align-items-center justify-content-center rem-mob-view">
                {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                {request.isFollower ? (
                                  <>
                                    <Button
                                      outline
                                      color="secondary"
                                      className="text-wrap"
                                      onClick={() => toggleUnfollowModal(request)}
                                    >
                                      <Check size={15} />
                                      &nbsp;
                                      <span className="font-weight-bold">
                                        Following
                                      </span>
                                    </Button>
                                </>
                                ) : request.isPrivate ? (
                                                      // If not a follower and the account is private, check follow back request status
                                                      request.followBackRequestStatus === "pending" ? (
                                                        <>
                                                          <Button
                                                            color="lightblue"
                                                            className="btn-outline-blue"
                                                            onClick={() => handlewithDrawRequests(request)}
                                                          >
                                                            <CornerLeftDown size={15} />
                                                            &nbsp;
                                                            <span className="font-weight-bold">
                                                              Withdraw
                                                            </span>
                                                          </Button>
                                                        </>
                                                      ) : (
                                                        <Button
                                                          outline
                                                          color="primary"
                                                          className="text-wrap"
                                                          onClick={() => handleFollowBack(request)}
                                                        >
                                                          <UserPlus size={15} />
                                                          &nbsp;
                                                          <span className="font-weight-bold">
                                                            Follow Back
                                                          </span>
                                                        </Button>
                                                      )
                                ) : (
                                  // If not a follower and the account is not private
                                  <>
                                    <Button
                                      outline
                                      color="primary"
                                      className="text-wrap"
                                      onClick={() => handleFollowBack(request)}
                                    >
                                      <UserPlus size={15} />
                                      &nbsp;
                                      <span className="font-weight-bold">
                                        Follow Back
                                      </span>
                                    </Button>
                                  </>
                                )}

                <div className="d-flex align-items-end flex-column justify-content-end">
                <UncontrolledButtonDropdown
                  className="d-flex align-items-end flex-column justify-content-end px-4"
                  direction="left"
                >
                  <DropdownToggle
                    color="light"
                    className="connection-cutomize-dropdown-toggle"
                  >
                    <MoreVertical size={22} />
                  </DropdownToggle>
                  <DropdownMenu className="Connection-dropdown">
                      {/*<DropdownItem className="border-bottom py-2 menu-hover-style">
                        <UserPlus size={15} className="mr-2 icon-color-style" />
                        Follow Back
                      </DropdownItem>*/}

                    <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                          onClick={() => { 
                                              toggleRemoveModalOpen();
                                              setFullName(request.fullName);
                                              setUserId(request.id); 
                                            }} 
                                        >
                        <MinusCircle size={15} className='mr-2' />
                        Remove Follower
                    </DropdownItem>

                    <DropdownItem
                      className="border-bottom py-2 menu-hover-style"
                      onClick={toggleShareOpen}
                    >
                      <Share size={15} className="mr-2 icon-color-style" />
                      Share Profile
                    </DropdownItem>

                    <DropdownItem className="border-bottom py-2 menu-hover-style">
                      <Copy size={15} className="mr-2 icon-color-style" />
                      Copy Profile URL
                    </DropdownItem>

                    <DropdownItem
                      className="border-bottom py-2 menu-hover-style"
                      onClick={() => {
                        toggleBlockModalOpen();
                        setUserId(request.id);
                      }}
                    >
                      <MinusCircle size={15} className="mr-2 icon-color-style" />
                      Block
                    </DropdownItem>

                    <DropdownItem
                      className="py-2 menu-hover-style"
                      onClick={() => toggleReport(request)}
                    >
                      <Flag size={15} className="mr-2" />
                      Report User
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                </div>
                {/* </ul> */}
              </div>
            </div>
          );
        })}
      {followingData.length <= 0 && (
        <h3 className="text-center connection-sub-heading">
          No Followers Found
        </h3>
      )}
      {/*  ------------------ Add Report Option ------------------ */}

      {/*  ------------------ Add Report Option  ------------------ */}

      { reportOpen && (
          <ConnectionReportModal
            request={selectedRequest}
            modalOpen={reportOpen}
            reportModalOpen={toggleReport}
            setReportConfirm ={setReportConfirm}
          />
      )}

      {/*  ------------------ Add CategoryModal Status  ------------------ */}

      {/*<AddCategoryModal
        openModal={addCategoryOptions}
        cateModalOpen={toggleAddCategory}
      />*/}

      {/*  ------------------ Remove Member Modal  ------------------ */}

      { removeModalOpen && (
          <NewRemoveModal
            userId={userId}
            fullName={fullName}
            openModal={removeModalOpen}
            removeModalOpen={toggleRemoveModalOpen}
            setRemoveConfirm={setRemoveConfirm}
          />
      )}

      {/*------------------------- block modal----------------------- */}

      { blockModalOpen && (
          <BlockModal
            request={userId}
            openModal={blockModalOpen}
            removeModalOpen={toggleBlockModalOpen}
            setBlockConfirm={setBlockConfirm}
          />
      )}

      {/* ------------- Unfollow Modal Modal -------------*/}
      { unfollowModalOpen && (
          <UnfollowModal
            request={selectedRequest}
            openModal={unfollowModalOpen}
            unfollowModalOpen={toggleUnfollowModal}
            setunfollowConfirm={setunfollowConfirm}
          />
      )}

      {/* ------------------ Start Share Modal  ------------------*/}
      {/*<ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />*/}
    </>
  );
};
