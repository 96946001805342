import React, { Fragment, useState } from 'react'
import { Button, Card, CardImg, CardImgOverlay, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Dropdown, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown, Form, Label } from 'reactstrap'
import { CheckCircle, ChevronDown, MoreHorizontal, Play, Trash2, Upload, XCircle } from 'react-feather'
const NFTfooter = () => {
    return (
        <Fragment>
            <footer className="header-light nftfoot">
                <div className="container-fluid custom-padding">
                    <Form>
                        <FormGroup className="d-flex justify-content-between">
                            <div className='d-flex'>
                                <h3 className='mr-3'>Privacy Policy</h3>
                                <h3 className='mr-3'>License</h3>
                                <h3 className='mr-3'>API</h3>
                                <span className='mr-3'>© 2021 All rights reserved</span>
                             
                            </div>
                            <div className='d-flex' >
                            <h3 className='mr-3'>Currency - USD  <ChevronDown size={18} /></h3>
                                <h3 className='mr-3'>License &nbsp;<spam><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
  <path d="M7.51172 0.0234375C3.36328 0.0234375 0.0234375 3.36328 0.0234375 7.51172C0.0234375 11.6602 3.36328 15 7.51172 15C11.6602 15 15 11.6602 15 7.51172C15 3.36328 11.6602 0.0234375 7.51172 0.0234375ZM1.5 7.51172C1.5 7.05469 1.57031 6.59766 1.67578 6.17578L5.26172 9.76172V10.5C5.26172 11.3438 5.92969 12.0117 6.73828 12.0117V13.4531C3.82031 13.1016 1.5 10.5703 1.5 7.51172ZM11.9414 11.5547C11.7305 10.957 11.168 10.5 10.5 10.5H9.76172V8.25C9.76172 7.86328 9.41016 7.51172 8.98828 7.51172H4.48828V6H6C6.42188 6 6.73828 5.68359 6.73828 5.26172V3.75H8.25C9.09375 3.75 9.76172 3.08203 9.76172 2.27344V1.95703C11.9414 2.83594 13.5234 5.01562 13.5234 7.51172C13.5234 9.05859 12.8906 10.5 11.9414 11.5547Z" fill="#81C14B"/>
</svg></spam></h3>
                            </div>

                        </FormGroup>
                    </Form>
                </div>
            </footer>
        </Fragment>
    )
}

export default NFTfooter