import React, { useEffect, useState } from "react";
import {
  Copy,
  CornerLeftDown,
  Flag,
  MoreVertical,
  Share,
  MinusCircle,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { AllUserSentRequest } from "../../../Services/Actions/Connection/getUserSentRequestAction";
import { withDrawRequestAction } from "../../../Services/Actions/Connection/getSentRequestWithdrawAction";
import { getActionBlockUnblock } from "../../../Services/Actions/Connection/getBlockUnblockAction";
import { Button } from "react-bootstrap";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import BlockModal from "../ConnectionModal/BlockModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import { GroupMembers } from "../HoverComponents/GroupMembers";
import PublicAccount from "../ConnectionModal/PublicAccount";
import "../Style.css";
import { NavLink } from "react-router-dom";

export const AllRequestedUser = ({requests}) => {
  //const { userSentRequests } = useSelector(
  //  (state) => state.userSentRequestsData
  //);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };

  //handle withdraw requests
  const handlewithDrawRequests = (request) => {
    if(request){
      // Dispatch the withdraw request action regardless of the request type
      dispatch(withDrawRequestAction(request.id, request.Type));
    }
    setTimeout(() => {
      dispatch(AllUserSentRequest());
    }, 1000);
  }

  //useEffect((id) => {
  //  dispatch(withDrawRequestAction(id));
  //}, []);

  const toggleBlock = (id) => {
    dispatch(getActionBlockUnblock(id));
    console.log("block");
  };

  useEffect(() => {
    dispatch(AllUserSentRequest());
  }, [dispatch]);

  //user report 
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = () => {
    setReportOpen((preState) => !preState);
  };
  const [reportRequest, setReportRequest] = useState({ id: "", Type: "" });
  const[reportConfirm,setReportConfirm] = useState(false)
      useEffect(()=>{
        if(reportConfirm){
              setTimeout(() => {
                dispatch(AllUserSentRequest());
                setReportConfirm(false);
              }, 1000);
          } 
      },[reportConfirm])

  //get user block
  const [blockModalOpen, setBlockModalOpen] = useState(false)
  const toggleBlockModalOpen = () => {
    setBlockModalOpen((prevState) => !prevState);
  }
  const[blockConfirm,setBlockConfirm] = useState(false)
  useEffect(()=>{
    if(blockConfirm){
      setTimeout(() => {
      dispatch(AllUserSentRequest());
        setBlockConfirm(false);
      }, 1000);
    } 
  },[blockConfirm])
  // console.log("All people and group request", userSentRequests)
  return (
    <>
      <div className="mppage-heading mt-4">
        <div className="group-custom-block">
          <div className="heading-podcast-blk">
            <h3 className="connection-sub-heading">Requested All </h3>
          </div>

          <Row className="connections_PeopleThisGrid">
            {requests && requests.map((request, index) => {
                  return (
                    <Col lg="4" className="w-100" key={index}>
                      { request.Type === "People" 
                        ? ( <div className="connection-card connection-card-new box-shadow-style content-mp-block d-flex justify-content-between">
                            <div className="media-body d-md-block">
                              <div class="media media-new d-flex align-items-start">
                                <div class="user-img rounded-circle d-flex align-items-start popover___wrapper">
                                  <img
                                      src={request?.profileImage}
                                      className="rounded-circle "
                                      height={45}
                                      width={45}
                                      alt={request?.name || "User"}
                                  />
                                  <span class="available-stats online"></span>
                                  <PublicAccount request={request} />
                                </div>

                                <div class="media-body d-md-block pl-1">
                                  <NavLink to="/MyProfile">
                                    <h4 className="m-0 name-style d-inline-block text-truncate">
                                      {request?.name || "NA"}
                                    </h4>
                                  </NavLink>
                                  <div className="d-flex ">
                                    <p className="user-name-style">
                                      {request?.userName || "NA"} |
                                    </p>
                                    <span><img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} /></span>
                                  </div>
                                  <div className="people-likes matual-friend-sec d-flex justify-content-between">
                                    <p
                                      className="m-0 professional-style d-inline-block text-truncate"
                                      style={{ width: "180px" }}
                                    >
                                      {request?.profession || "No Profession"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-end flex-column">
                              <UncontrolledButtonDropdown
                                className="d-flex align-items-end flex-column justify-content-end"
                                direction="left"
                              >
                                <DropdownToggle
                                  color="light"
                                  className="connection-cutomize-dropdown-toggle"
                                >
                                  <MoreVertical size={22} />
                                </DropdownToggle>
                                <DropdownMenu className="Connection-dropdown">
                                  <DropdownItem className="border-bottom py-2 menu-hover-style"
                                    onClick={() =>
                                      handlewithDrawRequests(request)
                                    }
                                  >
                                    <CornerLeftDown size={15} className="mr-2" />
                                    Withdraw Request
                                  </DropdownItem>
                                  <DropdownItem
                                    className="border-bottom py-2 menu-hover-style"
                                    onClick={toggleShareOpen}
                                  >
                                    <Share size={15} className="mr-2" />
                                    Share Profile
                                  </DropdownItem>
                                  <DropdownItem className="border-bottom py-2 menu-hover-style">
                                    <Copy size={15} className="mr-2" />
                                    Copy Profile URL
                                  </DropdownItem>
                                  <DropdownItem
                                    className="border-bottom py-2 menu-hover-style"
                                    onClick={() => {
                                      toggleBlockModalOpen();
                                      setUserId(request.userId);
                                    }}
                                  >
                                    <MinusCircle
                                      size={15}
                                      className="mr-2 icon-color-style"
                                    />
                                    Block
                                  </DropdownItem>
                                  <DropdownItem
                                    className="py-2 menu-hover-style"
                                    onClick={() => {
                                      toggleReport();
                                      setReportRequest({ id: request.userId, Type: request.Type });
                                    }}
                                  >
                                    <Flag size={15} className="mr-2" />
                                    Report User
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                              <div className="mt-3">
                                <Button
                                  color="flat-success"
                                  className="btn-transparent pr-0 pb-0"
                                  onClick={() =>
                                    handlewithDrawRequests(request)
                                  }
                                >
                                  <CornerLeftDown size={15} color="#2F80ED" />
                                  &nbsp;
                                  <span
                                    className="connection-blue-text rem-mob-view"
                                  >
                                    Withdraw
                                  </span>
                                </Button>
                              </div>
                            </div>
                            </div> )
                        : ( <div className="connection-card box-shadow-style  content-mp-block d-flex justify-content-between">
                              <div className="media-body d-md-block">
                                  <div class="media media-new d-sm-flex">
                                      <div class="user-img d-flex align-items-start popover___wrapper " >
                                          <img src={request?.profileImage} className="rounded-circle" height={45} width={45} alt="shivam singh" />
                                          <span class="available-stats online"></span>
                                          {/*<PublicAccount request ={request}/>*/}
                                      </div>
                                      <div class="media-body d-md-block pl-1">
                                          <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate'>{request?.name?.substring(0, 21) || 'NA'}</h4></NavLink>
                                          <p className='user-name-style'>{request?.categoryName?.substring(0, 21) || 'NA'}</p>
                                      </div>
                                  </div>
                                  <div className='ml-2 mt-1'>
                                      <GroupMembers groupMembersList={request?.mutualfriendList}/>
                                      {/*<p className='m-0 professional-style d-inline-block text-truncate' style={{ width: '160px' }}>This text is a one line description</p>*/}
                                  </div>
                              </div>
                              <div className='d-flex align-items-end flex-column'>
                                  <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                      <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                          <MoreVertical size={22} />
                                      </DropdownToggle>
                                      <DropdownMenu className='Connection-dropdown'>
                                          <DropdownItem className='border-bottom py-2 menu-hover-style'
                                            onClick={() =>
                                              handlewithDrawRequests(request)
                                            }
                                          >
                                            <CornerLeftDown size={15} className='mr-2' />
                                            Withdraw Request
                                          </DropdownItem>
                                          <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}>
                                            <Share size={15} className='mr-2' />
                                            Share Group
                                          </DropdownItem>
                                          <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                            <Copy size={15} className='mr-2' />
                                            Copy Group URL
                                          </DropdownItem>
                                          {/*<DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  //onClick={() => {
                                                  //    toggleBlockModalOpen();
                                                  //    setUserId(request.userId);
                                                  //}}
                                                >
                                                  <MinusCircle
                                                    size={15}
                                                    className="mr-2 icon-color-style"
                                                  />
                                                  Block Group
                                          </DropdownItem>*/}
                                          <DropdownItem className='py-2 menu-hover-style' 
                                            onClick={() => {
                                              toggleReport();
                                              setReportRequest({ id: request.userId, Type: request.Type });
                                            }}
                                          >
                                              <Flag size={15} className='mr-2' />Report Group
                                          </DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                  <div role="button" className='mt-3 d-flex align-items-end'>
                                      <Button color='flat-success' className='btn-transparent pr-0 pb-0'
                                        onClick={() =>
                                          handlewithDrawRequests(request)
                                        }
                                      >
                                          <CornerLeftDown size={15} color='#2F80ED' />&nbsp;<span className='connection-blue-text rem-mob-view'>Withdraw</span>
                                      </Button>
                                  </div>
                              </div>
                            </div> )
                      }
                    </Col>
                  );
                })}
                { requests.length === 0 && (
                  <h3 className="text-center connection-sub-heading">
                    No Request Sent
                  </h3>
                )}
          </Row>
        </div>
      </div>
      { reportOpen && (
            <ConnectionReportModal
                request={reportRequest}
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
                setReportConfirm ={setReportConfirm}
            />
      )}
      {/*<ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />*/}

      {/*------------------------- block modal----------------------- */}
      { blockModalOpen && (
          <BlockModal
            request={userId}
            openModal={blockModalOpen}
            removeModalOpen={toggleBlockModalOpen}
            setBlockConfirm={setBlockConfirm}
          />
      )}
    </>
  );
};
