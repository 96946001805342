import React, { useState } from 'react'
import { AlertTriangle, Edit, Eye, Filter, Link, MoreHorizontal, Share2 } from 'react-feather'
import { Badge, Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Pollimg from '../../NewComponents/IMG/roundedimg2.svg'
import Pollimg1 from '../../NewComponents/IMG/roundedimg3.svg'
import PollUserImg from '../../NewComponents/IMG/PollUserImg.svg'
import CommentImg from '../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../NewComponents/IMG/Share.svg'
import LikeBlack from '../profile-components/Img//likesvg.svg'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal';
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'
const GridPoll = () => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const Polldata = [
        {
            img: Pollimg
        },
        {
            img: Pollimg1
        },
        {
            img: Pollimg
        },
        {
            img: Pollimg
        }


    ]
    return (
        <Card>
            <div className='p-1'>
                <Row className='d-flex justify-content-around align-items-center p-2'>
                    <Col md='6'>
                        <div className='d-flex mt-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Esther Howard”</p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='d-flex justify-content-end'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view ml-2'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='PeopleThisGrid p-3'>
                    {
                        Polldata.map((data, index) => {
                            return (
                                <Col md='3' className='w-100 p-1' >
                                    <div className=''>
                                        <Card className='roundedThisBorder'>

                                            <div className='d-flex justify-content-between m-2'>
                                                <div className='d-flex align-items-center '>
                                                    <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                                    <div className='ml-2'>
                                                        <h4>Floyd Miles </h4>
                                                        <p className='text-muted'>19 sept at 07:00 pm</p>
                                                    </div>
                                                </div>

                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                                        <MoreHorizontal size={19} color='#00000040' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu'>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                            <div className='d-flex align-items-start p-2'>
                                                <img src={PollUserImg} height={18} width={20} alt='union' className='polluThisserimg' />
                                                <small className='ml-1'>more user-centric and conv...{seemore ? 'by their very nature, are absmallout connections. Through your' : null}attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </small>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center px-4 ml-2'>
                                                <div className='d-flex align-items-center'>
                                                    <h4 className='text-primary'>10</h4>
                                                    <p className=''>&nbsp;Options</p>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <h4 className='text-primary'>156</h4>
                                                    <p className=''>&nbsp;Voted</p>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <h4 className='text-primary'>144</h4>
                                                    <p className=''>&nbsp;Viewed</p>
                                                </div>
                                            </div>
                                            <div className='ml-4 p-2 text-muted'>
                                                Posted on 23 Mar 2021
                                            </div>
                                            <div className='d-flex justify-content-between px-4 p-2'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                        <img src={LikeBlack} width={18} height={18} alt='likeback' />
                                                    </div>
                                                    11.2k
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                        <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                                    </div>
                                                    11.2k
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                                        <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                                    </div>
                                                    11.2k
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <ShareEventModal
                                        isOpen={shareOpen}
                                        updateOpen={toggleShareOpen}
                                    />
                                    <div className='Review_Report_modal'>
                                        {
                                            reportOpen !== false &&
                                            <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                                                <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                                                    <h3>Report a Poll</h3>
                                                </ModalHeader>
                                                <ModalBody>
                                                    <div className='Modal_Body_Section'>
                                                        <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                                                    </div>
                                                    {
                                                        ReportDataInfo.map((data) => (
                                                            <div className='d-flex justify-content-between mt-4 Report_Section'>
                                                                <h4>{data.name}</h4>
                                                                <div>
                                                                    <Input
                                                                        name="radio1"
                                                                        type="radio"
                                                                        className="Check"
                                                                    />
                                                                    {' '}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className='d-flex justify-content-center mt-3'>
                                                        <Input
                                                            id="ReportName"
                                                            name="ReportName"
                                                            placeholder="Write a comment..."
                                                            type="Report"
                                                        />
                                                    </div>
                                                    <hr className='w-100 text-muted' />
                                                    <div className='text-center'>
                                                        <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                                        <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                                                    </div>
                                                </ModalBody>

                                            </Modal>

                                        }

                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                    <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Poll
                    </b>
                    </ModalHeader>
                    {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                    <ModalBody>
                        <div>
                            <div className='d-flex justify-content-between'>
                                <h4>Withim (Km)</h4>
                                <h4>8821 Km</h4>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <div className='w-100'>
                                    <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                        <MatSlider
                                            aria-label="Volume"
                                            defaultValue={100}
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                '& .MuiSlider-track': {
                                                    border: 'none',
                                                },
                                                '& .MuiSlider-thumb': {
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: '#81C14B',
                                                    '&:before': {
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                    },
                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                        boxShadow: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                    </Stack>
                                </div>
                                <div className='d-flex align-items-center ml-3' role='button'>
                                    <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                                </div>
                            </div>
                            <div className='border-top border-bottom mt-2'>
                                <h4 className='mt-2'>Choose locations</h4>
                                <ChipBoxLocation />
                            </div>
                            <h4 className='mt-2'>Choose Poll Type</h4>
                            <div className='d-flex justify-content-between p-2'>
                                <p id='1'>Completed Polls</p>
                                <input type="checkbox" />
                            </div>
                            <div className='d-flex justify-content-between p-2'>
                                <p id='1'>Running Polls</p>
                                <input type="checkbox" />
                            </div>
                            <div className='d-flex justify-content-center mt-3 '>
                                <Button color='primary' outline>CLEAR ALL</Button>
                                <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </div>

        </Card>
    )
}

export default GridPoll