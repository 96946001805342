import React, { useEffect } from 'react'
import { useState } from "react";
import {Button} from 'reactstrap'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import {  NavLink } from 'react-router-dom';
import MarketTabSection from './MarketPlaceFolder/MarketTabSection';
import {getTextPostsAction} from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import MarketPlaceModal from '../marketplace-components/ModalSection/MarketPlaceModal'
import { MarketPlaceCate } from '../marketplace-components/MarketPlaceArray/MarketPlaceCategory'
import { getAllProductCategoryAction } from "../../Services/Actions/MarketPlace/getAllProductsAction";


const MyMarketPlace = () => {
    const navigate = useNavigate()
    let dispatch=useDispatch();
    const [searchKey, setSearchKey] = useState("");
    const [modal, setModal] = useState(false);
    const [location, setlocation] = useState(true)

    // useEffect(() => {
    //     // Initial API call when component mounts
    //     dispatch(getTextPostsAction("marketPlace", 5, 0, ''));
    //   }, []);

      useEffect(() => {
        dispatch(getAllProductCategoryAction())
        LocationProvider()
    }, [dispatch])
    const { category, error } = useSelector(state => state.getAllProductCategory);

      const toggle = (e) => {
        // e.stopPropagation();
        setModal(!modal);
      }

    const LocationProvider = () => {
        let currentLocation = window.location.pathname
        if (currentLocation === "/marketplace") {
            setlocation(true)
        } else {
            setlocation(false)
        }
    }

    //   const getAllPostsUser = useSelector((state) => {
    //     return state.getTextPostsData;
    //   });

    return (
        <>
            < Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    {/* <ProfileCover /> */}
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="gallery-page-section section-b-space">
                            <div className="card-title">
                                <h3>Market Place</h3>
                                <div className="right-setting">
                                    <div className="profile-search-blk ">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        name="search" 
                                        placeholder="Find ..." 
                                        data-bs-toggle="modal" 
                                        data-bs-target="#peopleSearch" 
                                        onChange={(e) => {setSearchKey(e.target.value)}}
                                        />
                                    </div>
                                    <NavLink onClick={(e)=>toggle(e)} className="btn btn-outline-grey ms-3"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-13 ih-13"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg> Sell</NavLink>
                                    {modal &&(
                                        <>
                                        <MarketPlaceModal open={modal} toggle={(e)=>toggle(e)} data={MarketPlaceCate} category = {category} />
                                        {/* <Button className="MpMobileViewButton_Manage ManageOwnProductBtn"  onClick={(e) => { navigate("/MyMarketPlace") }}> Manage Your Product</Button> */}
                                        </>
                                     )}
                                </div>
                            </div>
                            <MarketTabSection searchKey = {searchKey}/>
                        </div>
                    </div>
                </div>
                
                <RightSidebar />
            </div>

        </>
    )
}

export default MyMarketPlace
