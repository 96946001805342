import React, { Fragment, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import MpFormTabs from '../MpFormComponents/MpFormTabs'
import MpFormTabsNew from '../MpFormComponents/MpFormTabsNew'
import MpFormInput from '../MpFormComponents/MpFormInput'
import MpAttributeFormInput from '../MpFormComponents/MpAttributeFormInput'
import MpFormDropdowns from '../MpFormComponents/MpFormDropdowns'
import MpFormDropDownsNew from '../MpFormComponents/MpFormDropDownsNew'
import MpFormLocationInput from '../MpFormComponents/MpFormLocationInput'
import MpFormPriceInput from '../MpFormComponents/MpFormPriceInput'
import MpFormDiscription from '../MpFormComponents/MpFormDiscription'
import SingleImagePink from '../img/singleImagePink.png'
import MpImageUploadSection from '../MpFormComponents/MpImageUploadSection'
import Header from '../../Header'

import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import MpFormCompoHeader from '../MpFormComponents/MpFormCompoHeader'
import MpBreadCrumb from '../MpFormComponents/MpBreadCrumb'
import { useLocation, useNavigate } from 'react-router-dom';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert';
import { SweetErrorAlert } from '../../group-components/AllGroupsSections/SweetErrorAlert';
import axios from 'axios'
import { fetchMpAttributes, } from '../../../Services/Actions/MarketPlace/getAllProductsAction'








const MpSellFom = () => {
    const location = useLocation();
    const categoryData = location.state;
    let navigate = useNavigate();

    // Dummy data for the components 
    const carParking = { title: "Car Parking", data: ["1+", "2+", "3+", "4+"] }
    const bachelor = { title: "Car Parking", data: ["Yes", "No"] }
    const aminities = { title: "Additional Amenities ", choices: ["Near to Metro Station", "Near to Airport", "Only In Industry Area", "Near to Railway Station"] }
    const TabOne = { title: "Type*", data: ["Apartments", "Houses & Villas", "Builder Floors", "Shops & Offices"] }
    const TabTwo = { title: "Bedrooms*", data: ["1+", "2+", "3+", "4+", "5+", "Studio"] }
    const TabThree = { title: "Bathrooms*", data: ["1+", "2+", "3+", "4+", "5+", "Attached"] }
    const TabFour = { title: "Furnishing*", data: ["Furnished", "Semi-Furnished", "Unfurnished"] }
    const TabFive = { title: "Listed by*", data: ["Builder", "Dealer", "Owner"] }
    const TabSix = { title: "Pet Allowed?*", data: ["Yes", "No"] }
    const InputOne = { title: "Super Builtup Area (sqft)", placeholder: "Enter super builtup area here" }
    const InputTwo = { title: "Carpet Area (sqft)", placeholder: "Enter carpet area here" }
    const InputThree = { title: "Total Monthly Maintenance*", placeholder: "Enter your monthly maintenance here" }
    const InputFour = { title: "Total Floors", placeholder: "How many floors do you have?" }
    const InputFive = { title: "Location", placeholder: "Enter your address" }
    const InputSix = { title: "Title*", placeholder: "Enter your title" }
    const inputSeven = { title: "Set Price*", placeholder: "Enter your price for your property", choices: ["USD", "IND", "UAE"] }

    
    //getting user details
    let user = JSON.parse(localStorage.getItem("sociomeeUser"));

    // calll active 
    const [isCall, setIsCall] = useState(false);
    const [isMsg, setIsMsg] = useState(false);
    const [communicationMode, setCommunicationMode] = useState('');

    const [mpAttributeValues, setMpAttributeValues] = useState([])

    // common form data
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState("")
    const [productLocation, setProductLocation] = useState("")
    const [description, setDescription] = useState("")
    const [locationLat, setLocationLat] = useState('')
    const [locationLong, setLocationLong] = useState('')
    const [media, setMedia] = useState([]);
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [condition, setCondtion] = useState("")
    const [currencyId, setCurrencyId] = useState("855d4f70-d3a0-4723-aa03-b1ede6442586")
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [attributes, setAttributes] =useState([])





    const handleCheckboxChange = (mode) => {
        if (mode === 'call') {
          setIsCall(!isCall);
        } else if (mode === 'msg') {
          setIsMsg(!isMsg);
        }
      };
    //   ENUM('Chat', 'Call', 'Both')
    useEffect(() => {
        if (isCall && isMsg) {
          setCommunicationMode('Both');
        } else if (isCall) {
          setCommunicationMode('Call');
        } else if (isMsg) {
          setCommunicationMode('Chat');
        } else {
          setCommunicationMode('');
        }
      }, [isCall, isMsg]);
      

    const handleSelectChange = (event) => {
        // Handle the selection change if needed
        console.log("Selected value:", event.target.value);
    };



      //setiing the title of the product
      const handleProductTitle = (productTitle) => {
        setTitle(productTitle);
      };
      //setting the price of the product
      const handleProductPrice = (price) => {
        setPrice(price.value);
      }
      //setting the description of the product
      const handleProductDescription = (description) => {
        setDescription(description);
        }
     //setting the location of the product
     const handleProductLocation = (location, coords) => {
        setProductLocation(location); //location stirng
        setLocationLat(coords.latitude) //location lat
        setLocationLong(coords.longitude) //location long 
        
        }
     const handleMediaData = (mediaArray) =>{
        setMedia(mediaArray)
        const foundMedia = mediaArray.find(item => item.sequence === 0);
            if (foundMedia) {
                setThumbnailURL(foundMedia.fileURL);
            } else {
                setThumbnailURL(''); 
            }
     }


  //   ==================>>fetching  mpAttributeValues============>>>
    useEffect(()=>{
        const categoryId =categoryData.categoryId;
        const subCategoryId= categoryData.subCategoryId;
    const fetchAttributeData = async () =>{
        const attributeData = await fetchMpAttributes(categoryId,subCategoryId)
        setMpAttributeValues(attributeData)
    }
        fetchAttributeData()
    },[categoryData])

        // Step 1: Extract unique types (filtering the attribute data behalf of the input type)
        const uniqueTypes = [...new Set(mpAttributeValues?.map(attr => attr.type))];
        // Step 2: Create an object to hold arrays for each type
        const attributesByType = uniqueTypes.reduce((acc, type) => {
        acc[type] = mpAttributeValues?.filter(attr => attr.type === type);
        return acc;
        }, {});



       
//we are handling if user select any attribute  during creation of the  market place product 
    const handleAttributeData = (data) => {
        setAttributes(prevAttributes => {
        // Create a copy of the existing attributes
        const newAttributes = [...prevAttributes];
        data.forEach(item => {
            const index = newAttributes.findIndex(attr => attr.mpAttributeId === item.mpAttributeId);
            if (index >= 0) {
            newAttributes[index] = item;
            } else {
            newAttributes.push(item);
            }
        });
        return newAttributes;
        });
    };


    //====>>handling dropdown data <<=====
    const handleDropdownData = async(newData) => {
        setAttributes(newData)
    };
    // console.log("this is attributes:",attributes)




    // ============>>> create market place post  <<<=====================

     const createMarketPlacePost = async(e) =>{
        e.stopPropagation()
            try {
              let user = JSON.parse(localStorage.getItem("sociomeeUser"));
              const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${user?.token}`,
                },
              };
              //final body of the api
                const body={
                    categoryId: categoryData.categoryId,
                    subCategoryId: categoryData.subCategoryId,
                    subChildCategoryId: categoryData.subChildCategoryId,
                    title: title,
                    description: description,
                    price: price,
                    communicationMode: communicationMode,
                    condition: condition,
                    currencyId: currencyId,
                    media: media,
                    thumbnailURL: thumbnailURL,
                    location: productLocation,
                    locationLAT: locationLat,
                    locationLONG: locationLong,
                    attributes : attributes

                }
                    //handling mandatory field error
                    if (!title || !price || !communicationMode || !productLocation || !media.length ) {
                      setFormErrorMessage('Please fill all required fields.');
                      return;
                  }
                     setFormErrorMessage('')
               //final api call      
               const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/marketPlace/insertmppost`,
                body,
                config
              );
              if (response?.data?.data?.successResult) {
                sweetAlertConfirmation("Product sent for Approval")
                navigate("/MyMarketPlace");
              } else {
                const failMessage= response.data.data?.errorResult || "Product creation failed"
                SweetErrorAlert(failMessage)
                navigate("/marketplace")
              }
              setFormErrorMessage('')
            } catch (error) {
              console.error("Error during marketPlace post creation", error);
            }
     } 




    return (
        // ================== The whole Sell Page Component return ===========================
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                {/* <div className='cardmobile'> <MpFormCompoHeader /></div> */}
                   
                    <Card className="mt-3 border markettop">
                        {/* ============= header Of the sell Page ================== */}
                        <CardHeader className="d-flex align-items-center justify-content-center p-3">
                            <h2 style={{ fontWeight: "600", color: "#4D4D4D" }}>
                                SELL YOUR PRODUCT
                            </h2>
                        </CardHeader>
                        <CardBody >
                            <div className='row border-bottom'>
                                <div className="Mp_SellForm_Head p-4 border-bottom col-lg-12">
                                    <h4>SELECTED CATEGORY</h4>
                                    <div className='d-flex align-items-center gap-3 mt-2'>
                                        {/* =============== selected Category Path ========================= */}
                                        <MpBreadCrumb 
                                        categoryData = { categoryData }
                                        />
                                    </div>
                                </div>
                                <div className="Mp_FormIncludeSomeDetails px-4 mt-4 col-lg-12">
                                    <h4 className='Mp_Color4D_Font6' >INCLUDE SOME DETAILS</h4>
                                </div>
                                {/* <div className="Mp_Tab_Compo px-4 py-1 mb-4 mt-2 col-lg-12">
                                    <MpFormTabs data={TabOne} />
                                </div>

                                <div className="Mp_Tab_Compo px-4 py-1 mb-4 col-lg-12">
                                    <MpFormTabs data={TabTwo} />
                                </div>
                                <div className="Mp_Tab_Compo px-4 py-1 mb-4 col-lg-12">
                                    <MpFormTabs data={TabThree} />
                                </div>
                                <div className="Mp_Tab_Compo px-4 py-1 mb-4 col-lg-12">
                                    <MpFormTabs data={TabFour} />
                                </div> */}
                                {/* ==>commented form here sumit badola<=== */}
                                <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">
                                    <div className="col-xl-6 mb-4">
                                      {attributesByType && attributesByType.input && attributesByType.input.length > 0 && (
                                       <MpAttributeFormInput data={TabFive} attributesByType={attributesByType} onDataPass={handleAttributeData}/>)}
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                    {attributesByType && attributesByType.tab && attributesByType.tab.length > 0 && (
                                        <MpFormTabsNew data={bachelor} attributesByType={attributesByType} onDataPass={handleAttributeData}/>)}
                                    </div>

                                    <div className="col-xl-6  mb-4">
                                    {attributesByType && attributesByType.dropdown && attributesByType.dropdown.length > 0 && (
                                        <MpFormDropDownsNew data={aminities} attributesByType={attributesByType} onDataPass={handleDropdownData}  selectedValues={attributes} />)}
                                    </div>

                                     {/*<div className="col-xl-6 mb-4">
                                            <MpFormTabs data={TabOne} />
                                        </div>
                                    <div className="col-xl-6 mb-4">
                                            <MpFormTabs data={TabTwo} />
                                        </div>
                                    <div className="col-xl-6 mb-4">
                                            <MpFormTabs data={TabThree} />
                                        </div>
                                    <div className="col-xl-6 mb-4">
                                            <MpFormTabs data={TabFour} />
                                        </div>*/}
                                </div> 
                                 <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">
                                   {/* <div className="col-xl-6 mb-4">
                                      {attributesByType && attributesByType.input && attributesByType.input.length > 0 && (
                                       <MpAttributeFormInput data={TabFive} attributesByType={attributesByType} onDataPass={handleAttributeData}/>)}
                                    </div>
                                     <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={TabFive} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={TabSix} />
                                    </div> 
                                     <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputTwo} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputThree} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputFour} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={carParking} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={bachelor} />
                                    </div> 
                                    <div className="col-xl-6  mb-4">
                                        <MpFormDropdowns data={aminities} />
                                    </div> 
                                    <div className="col-xl-6 mb-4">
                                    {attributesByType && attributesByType.tab && attributesByType.tab.length > 0 && (
                                        <MpFormTabsNew data={bachelor} attributesByType={attributesByType} onDataPass={handleAttributeData}/>)}
                                    </div> 
                                    <div className="col-xl-6  mb-4">
                                    {attributesByType && attributesByType.dropdown && attributesByType.dropdown.length > 0 && (
                                        <MpFormDropDownsNew data={aminities} attributesByType={attributesByType} onDataPass={handleDropdownData}  selectedValues={attributes} />)}
                                    </div> */}

                                </div>   
                                 {/* =====>>here<<===== */}
                                      {/* >>===========common form of the mp post creation=======>>> */}
                                    <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">                                                                 
                                        <div className="col-xl-6 mb-4">
                                            <div className=" mb-4">
                                                <MpFormInput data={InputSix} onDataPass={handleProductTitle} />
                                            </div>
                                            <div className=" mb-4">
                                                <MpFormPriceInput data={inputSeven} onDataPass={handleProductPrice} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6  mb-4 ">
                                            <div className=" mb-4">
                                                <MpFormLocationInput data={InputFive} onDataPass ={handleProductLocation}/>
                                            </div>
                                            <div className="mb-4">
                                                <MpFormDiscription onDataPass={handleProductDescription}/>
                                            </div>
                                        </div>                                       
                                    </div>
                            </div>


                            {/* ============================= upload Image section =-============================ */}
                            <MpImageUploadSection onMediaDataChange={handleMediaData}  />
                            {/* =================== Seller Details =============================== */}
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <div className="Mp_FormIncludeSomeDetails px-3 mt-3">
                                        <h4>REVIEW YOUR DETAILS</h4>
                                    </div>
                                    <div className="Mp_Form_sellerProfile p-3">
                                        <div className='Mp_From_Seller mt-3' >
                                            <img src={`${process.env.REACT_APP_IMAGEURL}${user?.profileImage}`} alt="" className='img-fluid' />
                                            <div className='Mp_setOrderLeft_ImageText px-3' >
                                                <h6>Your name:</h6>
                                                <h5 className='Mp_singleVarient'>{ user.fullName }</h5>
                                                <h6 className='d-flex align-items-end justify-content-start gap-2' style={{ color: "#878992", fontWeight: "400" }}>{user?.mobile || "Not Available"}</h6>

                                            </div>
                                        </div>
                                        {/* <NavLink><u>edit Profile</u> </NavLink> */}

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="Mp_FormIncludeSomeDetails px-3 mt-3 ">
                                        <h4>COMMUNICATION MODE</h4>
                                    </div>
                                    <div className="Mp_Form_SellerCommunicaion p-3">
                                      <h5>Select your mode*</h5>
                                        <div className='d-flex align-items-center justify-content-start gap-3 mt-2'>
                                            <button className={`Mp_FormSeller_Btn ${communicationMode === 'Call' ? 'MpSellerBtnActive' : ''}`} onClick={() => handleCheckboxChange('call')}>
                                                <input
                                                    type="checkbox"
                                                    value="call"
                                                    checked={isCall}
                                                    onChange={() => handleCheckboxChange('call')}
                                                /> Call
                                            </button>

                                            <button className={`Mp_FormSeller_Btn ${communicationMode === 'Chat' ? 'MpSellerBtnActive' : ''}`} onClick={() => handleCheckboxChange('msg')}>
                                                <input
                                                    type="checkbox"
                                                    value="msg"
                                                    checked={isMsg}
                                                    onChange={() => handleCheckboxChange('msg')}
                                                /> MsgMee
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-flex-start py-4 px-3'>
                               <Button 
                                  color="primary" 
                                  className="Mp_FormPostNow_Btn" 
                                  onClick={createMarketPlacePost}
                                  disabled={!title}
                                  >Post Now
                               </Button>
                                 <div style={{ color: 'red', marginTop: '10px', marginLeft: '20px' }}>
                                     {formErrorMessage && <div>{formErrorMessage}</div>}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <RightSidebar />
            </div>

        </Fragment >
    )
}

export default MpSellFom
