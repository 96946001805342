import React from 'react'
import { ChevronDown, Globe } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Media from '../../../../NewComponents/IMG/MediaIcon.svg'
import Throught from '../../../../NewComponents/IMG/ThroughtIcon.svg'
import GoLive from '../../../../NewComponents/IMG/GoLiveIcon.svg'
import Podcast from '../../../../NewComponents/IMG/PodcastIconS.svg'
import Articles from '../../../../NewComponents/IMG/ArticleIcon.svg'
import Recommendation from '../../../../NewComponents/IMG/RecommendationIcon.svg'
import Alert from '../../../../NewComponents/IMG/ThreatIcon.svg'
import Poll from '../../../../NewComponents/IMG/BPollIcon.svg'
import Sell from '../../../../NewComponents/IMG/SellIcon.svg'
import Event from '../../../../NewComponents/IMG/EventIcon.svg'

const CreatepostHeader = (props) => {
    return (
        <div className='d-flex justify-content-between border-bottom pb-3'>
            <div className='d-flex align-items-center'>
                <h3 style={{ color: '#4D4D4D' }}>Create Post</h3>
                <UncontrolledButtonDropdown className='ml-3 d-flex align-items-center'>
                    <DropdownToggle color='flat-primary' className='d-flex align-items-center m-0 p-0'>
                        <h5 className='header-text-style d-flex align-items-center'><Globe color='#979797' size={16} /> Public</h5><ChevronDown color='#979797' size={18} />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag='a' className='header-text-style'>Public</DropdownItem>
                        <DropdownItem tag='a' className='header-text-style'>Private</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </div>
            <div className='d-flex align-items-center text-muted'>
                <h4 className='header-text-style'><NavLink to='/CreateGoLive' className='text-muted'><img src="/assets/images/hotspot_pulse-1.svg" alt="golive" />Go live</NavLink></h4>
                <UncontrolledButtonDropdown className='ml-3'>
                    <DropdownToggle color='flat-secondary' className='m-0 p-0 d-flex align-items-center'>
                        <h4 className='header-text-style'>More</h4><ChevronDown color='#979797' size={18} />
                    </DropdownToggle>
                    <DropdownMenu className='border-0 shadow' style={{ minWidth: '23.2rem', left: '-19rem' }}>
                        <div className='m-2 my-0'>
                            <Row className='rowForrevert'>
                                <Col onClick={props.actionM} className='onhovForPostselection m-1 user-select-none'><img className='px-2' src={Media} alt="micon" />Media</Col>
                                <Col onClick={props.actionT} className='onhovForPostselection m-1 user-select-none'>
                                    <img className='px-2' src={Throught} alt="throughticon" />
                                    Thought </Col>
                            </Row>
                            <Row className='rowForrevert'>

                                <Col className='onhovForPostselection m-1 user-select-none'>
                                    <NavLink to='/CreateGoLive' onClick={props.actionG} >
                                        <img className='px-2' src={GoLive} alt="goliveicon" /> Go Live
                                    </NavLink>
                                </Col>
                                <Col onClick={props.actionE} className='onhovForPostselection m-1 user-select-none'> <img className='px-2' src={Event} alt="eventicon" /> Event</Col>
                            </Row>
                            <Row className='rowForrevert'>
                                <Col className='onhovForPostselection m-1 user-select-none'>
                                    <NavLink to='/Podcast' onClick={props.actionP} >
                                        <img className='px-2' src={Podcast} alt="podcasticon" /> Podcast
                                    </NavLink>
                                </Col>
                                <Col className='onhovForPostselection m-1 user-select-none'>
                                    <NavLink to='/ArticleEditor' onClick={props.actionA} >
                                        <img className='px-2' src={Articles} alt="articleicon" /> Articles
                                    </NavLink>
                                </Col>
                                {/* <Col onClick={props.actionA} className='onhovForPostselection m-1 user-select-none'><img width={20} height={20} src="assets/images/Blog.png" alt="" /> Articles</Col> */}
                            </Row>
                            <Row className='rowForrevert'>
                                <Col onClick={props.actionR} className='onhovForPostselection m-1 user-select-none'>
                                    <img className='px-2'
                                        src={Recommendation}
                                        alt="Recooicon"
                                    />
                                    Recommendation
                                </Col>
                                <Col onClick={props.actionAl} className='onhovForPostselection m-1 user-select-none'><img className='px-2' src={Alert} alt="aleticon" /> Alert</Col>
                            </Row>
                            <Row className='rowForrevert'>
                                <Col onClick={props.actionPo} className='onhovForPostselection m-1 user-select-none'>
                                    <img className='px-2' src={Poll} alt="pollicon" /> Poll
                                </Col>
                                <Col className='onhovForPostselection m-1 user-select-none'>
                                    <NavLink to='/Marketplace' onClick={props.actionS} >
                                        <img className='px-2' src={Sell} alt="sellicon" /> Sell
                                    </NavLink>
                                </Col>
                            </Row>
                        </div>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            </div>
        </div>
    )
}

export default CreatepostHeader