import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
function RepostModal({ post, onClose, onRepost,show }) {
  const [comment, setComment] = useState('');
if(!show){
  return (<></>)
}
;

  return (
    <div className="repost-modal">
      <textarea
        placeholder="Add a comment..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <button >Repost</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
}


export default RepostModal

// function Post({ post, onRepost }) {
//   const [isRepostModalOpen, setIsRepostModalOpen] = useState(false);

//   const handleRepostClick = () => {
//     setIsRepostModalOpen(true);
//   };

//   const handleCloseRepostModal = () => {
//     setIsRepostModalOpen(false);
//   };

//   return (
//     <div className="post">
//       <p>{post.text}</p>
//       <button onClick={handleRepostClick}>Repost</button>
//       {isRepostModalOpen && (
//         <RepostModal post={post} onClose={handleCloseRepostModal} onRepost={onRepost} />
//       )}
//     </div>
//   );
// }