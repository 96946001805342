import React from 'react'
import { Fragment } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const HideModal = (props) => {
    return (
        <Fragment>
            <div className='vertically-centered-modal'>
                {
                    props.isOpen !== false &&
                    <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                        <ModalHeader className='d-flex justify-content-center modalHeading_Section'>
                            <h4>Hide Media</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className='d-flex justify-content-center  modalHeading_Section '>
                                <p>Do you really want to hide the selected Media?</p>
                            </div>
                            <div className='d-flex justify-content-center  mt-3'>
                                <Button onClick={props.updateOpen} outline >Cancel</Button>
                                <Button onClick={props.updateOpen} color="primary" className="ml-3 ">OK</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default HideModal
