  import React, { useState, Fragment, useEffect, useCallback } from 'react';
  import { UploadCloud, X } from 'react-feather';
  import axios from 'axios';

  const MpImageUploadSection = React.memo(({ onMediaDataChange, value }) => {
    const [media, setMedia] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [showUploadError, setShowUploadError] = useState(false); // New state for handling upload errors
    const [refresh, setRefresh] = useState(true);

    // Update media only if value has actually changed
    useEffect(() => {
      if (value && refresh) {
        setMedia(value);
        setRefresh(prev => !prev);
      }
    }, [value, refresh]);

    // Memoized handler for media data change
    const handleMediaChange = useCallback(() => {
      if (JSON.stringify(value) !== JSON.stringify(media)) {
        onMediaDataChange(media);
      }
    }, [media, onMediaDataChange, value]);

    useEffect(() => {
      handleMediaChange();
    }, [media, handleMediaChange]);

    const handleImageSelect = async (event) => {
      const file = event.target.files[0];
      console.log("this is file.length   ==>>",file)
      if (file) {
        setUploading(true);
        setShowUploadError(false); // Reset error state

        const form = new FormData();
        form.append('file', file);
        form.append('uploadFor', 'marketPlaceImage');

        try {
          let user = JSON.parse(localStorage.getItem('sociomeeUser'));
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${user?.token}`,
            },
          };
          const { data } = await axios.post(`${process.env.REACT_APP_IPURL}/admin/uploadFile`, form, config);
          const imageUrl = data.data.successResult[0];

          // Reset file input value to ensure it is considered fresh
          document.querySelector('.SelectInput').value = null;

          setMedia((prevMedia) => [
            ...prevMedia,
            {
              fileType: 'image',
              fileURL: imageUrl,
              sequence: prevMedia.length,
            },
          ]);
        } catch (error) {
          console.error('ImageUpload Error', error);
          setShowUploadError(true); // Set error state if upload fails
        } finally {
          setUploading(false);
        }
      }
    };

    const handleRemoveImage = (index) => {
      setMedia((prevMedia) =>
        prevMedia
          .filter((_, i) => i !== index)
          .map((image, i) => ({ ...image, sequence: i }))
      );
    };

    return (
      <Fragment>
        <div className="row border-bottom">
          <div className="Mp_FormIncludeSomeDetails px-4 mt-3 col-lg-12">
            <h4>UPLOAD ITEM PHOTOS*</h4>
          </div>
          <div className="col-md-12 Mp_Form_ImageUploadInputs gap-2 p-4">
            {showUploadError && (
              <div className="alert alert-danger" role="alert">
                Error uploading image. Please try again.
              </div>
            )}
            {media.map((image, index) => (
              <div key={index} className="MpChooseImage position-relative" style={{ background: '#F5F6FA' }}>
                <img
                  src={image.fileURL.startsWith('http') ? image.fileURL : `${process.env.REACT_APP_IMAGEURL}${image.fileURL}`}
                  alt={`imageCont-${index}`}
                  className="w-100 h-100"
                  style={{ objectFit: 'cover', borderRadius: '20px' }}
                />
                <X
                  size={12}
                  className="ImageCrossButton_Mp"
                  onClick={() => handleRemoveImage(index)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ))}
            <div className="MpChooseImage position-relative" style={{ background: '#F5F6FA' }}>
              {uploading ? (
                <div
                  style={{
                    border: '4px solid rgba(0, 0, 0, 0.1)',
                    borderLeftColor: '#81C14B',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    animation: 'spin 1s linear infinite',
                    margin: 'auto',
                  }}
                ></div>
              ) : (
                <UploadCloud
                  size={30}
                  color={'#B3BAC2'}
                  onClick={() => document.querySelector('.SelectInput').click()}
                  style={{ boxSizing: 'content-box', padding: '35%', cursor: 'pointer' }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageSelect}
                className="SelectInput d-none"
                disabled={uploading}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  });

  export default MpImageUploadSection;
