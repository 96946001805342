import React from 'react'
import { Fragment } from 'react'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import Events from '../Events'
import EventsTopBanner from './EventsTopBanner'

const TodayEvents = () => {
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <EventsTopBanner />
                </div>
                <RightSidebar />
            </div>

        </Fragment>
    )
}

export default TodayEvents