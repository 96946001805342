import React, { Fragment } from 'react'

const BizAvatar = () => {
    const avatardata = [
        {
            img: '../assets/images/story-2.jpg'
        },
        {
            img: '../assets/images/story-3.jpg'
        },
        {
            img: '../assets/images/story-4.jpg'
        }
    ]
    //=============== Main_return_function ================//
    return (
        <Fragment>
            <div>
                <ul className="matual-friend-blk">
                    {
                        avatardata.map((item, index) => {
                            return (
                                <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="sufiya eliza" data-img={item.img}>
                                    <img src={item.img} className="AvatarGroupImg bg-img" alt="" />
                                </li>
                            )
                        })
                    }
                </ul>
            </div >
        </Fragment>
    )
}

export default BizAvatar