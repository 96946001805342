import { Radio } from '@mui/material';
import React from 'react'
import { Fragment } from 'react'
import { StreamingMessageData, StreamingLiveData } from './PostStoryArray'

const LiveStreaming = () => {
    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);

    };
    const [sessionValue, setSessionValue] = React.useState('b');

    const handleSession = (event) => {
        setSessionValue(event.target.value);

    };

    //======================= main_return_function ==========================//
    return (
        <Fragment>
            {/*=============== start_SocialMsg_Data_section ================*/}
            <div className='SettingPrivacy_Card_Body'>
                <h3 className='py-3'>Chat options on live</h3>
                {StreamingMessageData.map((data) => (
                    <div className='SettingPrivacy_Content_Section'>
                        <div className='SettingPrivacy_SubContent_Section '>
                            <div>
                                <h4>{data.heading}</h4>
                                <p>{data.subContent}</p>
                            </div>
                            <div>
                                <Radio checked={selectedValue === data.id}
                                    onChange={handleChange}
                                    value={data.id}
                                    name="radio-buttons"
                                    className='Radio_btn_check'
                                    color='primary'
                                    inputProps={{ 'aria-label': 'A' }} />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            {/*=============== End_SocialMsg_Data_section ================*/}

            {/*=============== start_Live_recording_Data_section ================*/}
            <div className='SettingPrivacy_Card_Body'>
                <h3 className='py-3'>Live recording session</h3>
                {StreamingLiveData.map((data) => (
                    <div className='SettingPrivacy_Content_Section'>
                        <div className='SettingPrivacy_SubContent_Section '>
                            <div>
                                <h4>{data.heading}</h4>
                                <p>{data.subContent}</p>
                            </div>
                            <div>
                                <Radio checked={sessionValue === data.id}
                                    onChange={handleSession}
                                    value={data.id}
                                    name="radio-buttons"
                                    className='Radio_btn_check'
                                    color='primary'
                                    inputProps={{ 'aria-label': 'A' }} />
                            </div>
                        </div>
                    </div>
                ))}

            </div>
            {/*=============== start_Live_recording_Data_section ================*/}
        </Fragment>
    )
}

export default LiveStreaming