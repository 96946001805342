
import React, { useState } from 'react'
import Rating from '@mui/material/Rating';
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
// import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { UncontrolledButtonDropdown, Modal, ModalBody, DropdownMenu, DropdownItem, DropdownToggle, Col, Row, Input, Button, Badge, ModalHeader } from 'reactstrap'
import cricleimg from '../../../NewComponents/IMG/cricleimg.png'
import cricleimg1 from '../../../NewComponents/IMG/cricleimg1.png'
import AvatarGroupComponent from '../../Search-Components/AvatarGroupComponent'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal';
import InviteModal from '../../biz-components/AllTypesModal/InviteModal';

const GridBizpageTab = () => {
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const [InviteOpen, setInviteOpen] = useState(false)
    const toggleInvite = () => {
        setInviteOpen((Preview) => !Preview)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]

    const Bizpage = [
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Action Pack',
            content1: 'Music Equipment',
            num: '52400',
            img: cricleimg,
            button: <button className='btn btn-primary'>Following</button>,
            button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>,
            topsNumber: '01',
            trendingNumber: '11'
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Traditional Wedding ',
            content1: 'Music Equipment',
            num: '60400',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>,
            topsNumber: '02',
            trendingNumber: '12'
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            content: 'Action Pack',
            content1: 'Music Equipment',
            num: '70400',
            img: cricleimg,
            button: <button className='btn btn-primary'>Following</button>,
            button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>,
            topsNumber: '03',
            trendingNumber: '13'
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            num: '92400',
            content: 'Traditional Wedding ',
            content1: 'Music Equipment',
            img: cricleimg1,
            button1: <Button Button color='primary' outline>Follow</Button>,
            topsNumber: '04',
            trendingNumber: '14'
        },
        {
            timeline: 'Turn of page notification ',
            link: 'Copy link',
            invite1: 'Invite People ',
            delete: 'Edit page ',
            share: 'Share page ',
            num: '92400',
            content: 'Traditional Wedding ',
            content1: 'Music Equipment',
            img: cricleimg1,
            button1: <Button Button color='primary' outline onClick={toggleInvite}> Invite</Button>,
            topsNumber: '04',
            trendingNumber: '14'
        }
    ]

    return (
        <div>
            <InviteModal
                isOpen={InviteOpen}
                updateOpen={toggleInvite}
            />
            <Row className='p-2'>
                {
                    Bizpage.map((data, index) => {
                        return (
                            // <Col lg='3' className='p-1' key={index} >
                            //     <div className='border roundedThisBorder'>
                            //         <div className='d-flex p-2'>
                            //             <Badge color='primary' pill className=''>
                            //                 Top #{data.topsNumber}
                            //             </Badge>
                            //         </div>
                            //         <div className="group-slide-box">
                            //             <div className="gallery-img-sublink-this-bizpage">
                            //                 <UncontrolledButtonDropdown>
                            //                     <DropdownToggle color='flat-primary' >
                            //                         <MoreHorizontal color='#FAF7F7' />
                            //                     </DropdownToggle>
                            //                     <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown'>
                            //                         <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share post</DropdownItem>
                            //                         <DropdownItem divider></DropdownItem>
                            //                         <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{color: '#212529'}} ><Eye size={13} />&nbsp;&nbsp;View Profile</NavLink></DropdownItem>
                            //                         <DropdownItem divider></DropdownItem>
                            //                         <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                            //                         <DropdownItem divider></DropdownItem>
                            //                         <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                            //                     </DropdownMenu>
                            //                 </UncontrolledButtonDropdown>
                            //             </div>

                            //             <NavLink className="bizpagelink-blk">
                            //                 <img src="/assets/images/group-img-1.jpg" className="BizpageThisimg" alt='img' />
                            //                 <div className="biz-adminprofile-blk"><img src={data.img} alt='img' />
                            //                 </div>
                            //             </NavLink>

                            //             <div>
                            //                 <div><p className='BizPage_text_center'>{data.content}</p></div>
                            //                 <p className='Bizpagetabsfortextcenter'>{data.content1}</p>
                            //                 <p className='m-2'>
                            //                     <Rating name="read-only" value={3} readOnly />
                            //                 </p>
                            //                 <div className="d-flex justify-content-center">
                            //                     <AvatarGroupComponent />
                            //                     <p className='People_Sociomate text-muted align-self-center'>524 Followers</p>
                            //                 </div>
                            //                 <div className='d-flex justify-content-center m-2'>
                            //                     <p>{data.button}</p>
                            //                     <p className='ml-2'>{data.button1}</p>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </Col>

                            <Col xl='3' lg='4' md='6' sm='6' className='p-1' key={index} >
                                <div className='border roundedThisBorder on-hover-bg'>
                                    <div style={{ position: 'relative' }}>
                                        <div >
                                            <img src="/assets/images/group-img-1.jpg" className="BizpageThisimg " alt='img' style={{ borderRadius: "5px 5px 0 0" }} />
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center py-1 mr-0 simple-linear' style={{ position: 'absolute', top: '0', left: '5px', right: '5px', borderRadius: '5px 5px 0px 0px' }} >

                                            <div className='d-flex p-2'>
                                                <NavLink to='/MyProfile'><Badge style={{ color: 'white', backgroundColor: '#81C14B' }} pill className=' px-2 d-flex align-items-center light-font'>
                                                    Top #{data.topsNumber}
                                                </Badge></NavLink>
                                                <NavLink to='/MyProfile'>
                                                    <Badge style={{ color: 'white', backgroundColor: '#81C14B' }} className='px-2 ml-2 d-flex align-items-center light-font' >
                                                        Trending #{data.trendingNumber}
                                                    </Badge>
                                                </NavLink>
                                            </div>
                                            <div className=''>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color='flat-primary' style={{ margin: '0', padding: '0' }} >
                                                        <MoreHorizontal color='#FAF7F7' />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenu width-for-dropdown dropdownLocation dropdown-for-user-specific'>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share BizPage</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View Profile</NavLink></DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>
                                        </div>

                                        <div className=" text-center w-100" style={{ position: 'absolute', bottom: '-50px' }} >
                                            <img src={data.img} alt='img' />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='text-center mt-5'>
                                            <NavLink to='/biz' style={{ color: 'black' }} >
                                                <h4 className='user-profile'>{data.content}</h4>
                                            </NavLink>
                                            <p className='text-muted' >{data.content1}</p>
                                        </div>

                                        <div className='text-center' >
                                            <p className='m-2'>
                                                <Rating name="read-only" value={4} readOnly />
                                            </p>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <AvatarGroupComponent />
                                            <p className='align-self-center'><span className='fw-bold' >&nbsp;&nbsp;524</span>&nbsp;&nbsp;Followers </p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-center m-2 mt-3 mb-3'>
                                        <p>{data.button}</p>
                                        <p className='ml-2'>{data.button1}</p>
                                    </div>

                                </div>
                            </Col>
                        )
                    })
                }


            </Row>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Review</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
        </div>
    )
}

export default GridBizpageTab