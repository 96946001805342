import React from 'react'
import { Button } from 'reactstrap'

const Buttons = () => {
  return (
    <div className='m-3'>

      <Button color='primary p-1' >Example</Button>
      <br />
      <br />
      <Button color='primary p-2' >Example</Button>
      <br />
      <br />
      <Button color='primary px-3 py-2' >Example</Button>
      <br />
      <br />
      <Button color='primary' >Example</Button>
      <br />
      <br />
      <Button color='primary w-50' >Example</Button>
      <br />
      <br />
      <Button color='primary w-100' >Example</Button>
    </div>
  )
}

export default Buttons