// import React, { useState } from 'react';
// import { Mic, PlayCircle, Video, ChevronDown } from 'react-feather';
// import Webcam from 'react-webcam';
// import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
// import Header from '../Header';
// import LeftSidebar from '../LeftSidebar';
// import RightSidebar from '../RightSidebar';
// import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
// import { Checkbox } from '@mui/material';
// import Down from './img/down3.png'




// const CreateGoLive = () => {
//   const [selectedCategory, setSelectedCategory] = useState('Select Category');


//   const handleCategorySelect = (category) => {
//     setSelectedCategory(category);
//   };
//   const [selectedCategory1, setSelectedCategory1] = useState('Select  ');

//   const handleCategorySelect1 = (category) => {
//     setSelectedCategory1(category);
//   };
//   const [selectedCategory2, setSelectedCategory2] = useState(' Video ');

//   const handleCategorySelect2 = (category) => {
//     setSelectedCategory2(category);
//   };
//   const [selectedCategory3, setSelectedCategory3] = useState(' Webcam ');

//   const handleCategorySelect3 = (category) => {
//     setSelectedCategory3(category);
//   };
//   const [selectedCategory4, setSelectedCategory4] = useState('Audio ');

//   const handleCategorySelect4 = (category) => {
//     setSelectedCategory4(category);
//   };

//   const [isOpen, setIsOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSelectChange = (event) => {
//     // Handle the select change event here
//     console.log('Selected option:', event.target.value);
//   };
//   const [accept, setaccept] = useState(false)
//   return (
//     <>
//       <Header />
//       <div className="page-body container-fluid profile-page">
//         <LeftSidebar />
//         <div className="page-center">
//           <div className="row">
//             <div className="col-lg-6 col-sm-12">
//               <div className="golive-card-box">
//                 <div className="golive-card-head">Go Live</div>
//                 <div className="golive-card-full-body">
//                   <div className="golive-streaming-preview-blk  bg-dark">
//                     <div style={{ zIndex: '1' }} className="golive-setting-preview-blk">
//                       <h4>Preview</h4>
//                     </div>
//                     <div className='text-center'>
//                       <Webcam
//                         mirrored={true}
//                         style={{ width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center" }}
//                       />
//                     </div>
//                     <div style={{ zIndex: '0' }} className="golive-ready-live-streaming-blk">
//                       <p className='readylive'><span className={accept ? "bg-danger" : "bg-white"}></span> Ready to start Live Stream</p>
//                       {/* <a href="#" className="golive-create-btn disable-btn">Go Live</a> */}
//                       <a href="/CreateGoLiveDetail" className={`golive-create-btn style ${accept ? "goLiveRedBtn" : "disable-btn"} `}>Go Live</a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-12">
//               <div className="golive-card-box">
//                 <div className="golive-card-head">Setup Media</div>
//                 <div className="golive-card-body">
//                   <div className="setup-media-block">
//                     <div className="row">
//                       <div className="col-lg-8 col-md-7 col-sm-8 col-7">
//                         <div className="set-media-form-blk">
//                           <Video color='#6E6B7B'  />
//                           {/* <span className='drouparrow' style={{ cursor: 'pointer' }}><img src={Down} alt='' /></span>
//                           <select id="inputState" className="form-control Fs14_Fw_400" style={{ color: "#4d4d4d", cursor: 'pointer' }}>
                           

//                             <option>Webcam1 </option>
//                             <option>Webcam2</option>

//                           </select> */}
//                            <UncontrolledButtonDropdown className='w-100'>
//                             <DropdownToggle color='secondary text-left Fs14_Fw_400' outline>
//                               {selectedCategory3}
//                               <span className='down2' style={{ position: 'absolute', right: "-5px" }}><ChevronDown /></span>
//                             </DropdownToggle>
//                             <DropdownMenu className='w-100' style={{
//                               boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
//                               borderRadius: '5px',
//                               background: '#FFF'
//                             }}>
//                               <div className='scrollForGoliveFilter text-left'>

//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect3('Webcam')}>
//                                  Webcam1
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect3(' Webcam1')}>
//                                 Webcam1
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect3(' Webcam2')}>
//                                 Webcam2
//                                 </DropdownItem>

//                               </div>
//                             </DropdownMenu>
//                           </UncontrolledButtonDropdown>
//                         </div>
//                       </div>
//                       <div className="col-lg-4 col-md-5 col-sm-4 col-5 whocan textvideo">
//                         <div className="set-media-form-blk ">
//                           {/* <span className='drouparrow' id="inputState" style={{ cursor: 'pointer' }}><img src={Down} alt='' /></span> */}

//                           {/* <select
//                             id="inputState"
//                             className={`form-control test Fs14_Fw_400 ${isOpen ? "open" : ""}`}
//                             style={{ color: "#4d4d4d", padding: '3px' }}
//                           >
//                             <span><Mic /></span>

//                             <option >
//                               Test video
//                             </option>
//                             <option>High definition(720p)</option>
//                             <option>Standard definition(360p)</option>
//                             <option>Low definition(180p)</option>
//                           </select> */}
//                            <UncontrolledButtonDropdown className='w-100 '>
//                             <DropdownToggle color='secondary text-left Fs14_Fw_400 textpadd' outline  >
//                               {selectedCategory2}
//                               <span className='down3' style={{ position: 'absolute', right: "-13px" }}><ChevronDown /></span>
//                             </DropdownToggle>
//                             <DropdownMenu className='w-100 show1' style={{
//                               zIndex: '3',
//                               boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
//                               borderRadius: '5px',
//                               background: '#FFF',
//                             }}>
//                               <div className='scrollForGoliveFilter text-left '>

//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect2(' High Video...')}>
//                                 High Video(720p)
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect2('Standard Vi...')}>
//                                 Standard Video(360p)
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect2('Low Video... ')}>
//                                 Low Video(240)
//                                 </DropdownItem>
                              
//                               </div>
//                             </DropdownMenu>
//                           </UncontrolledButtonDropdown>
//                           {/* <span className='drouparrow' id="inputState" style={{ cursor: 'pointer', position: "absolute", right: '12px' }}><img src={Down} alt='' /></span> */}

//                         </div>
//                       </div>


//                     </div>

//                     <div className="row">
//                       <div className="col-lg-8 col-md-7 col-sm-8 col-7">
//                         {/* <div className="set-media-form-blk ">
//                           <Mic color='#6E6B7B' />
//                           <span className='drouparrow1' style={{ cursor: 'pointer' }} onClick={toggleDropdown} ><img src={Down} alt='' /></span>
//                           <select id="inputState"className={`form-control Fs14_Fw_400 ${isOpen ? 'open' : ''}`} style={{ color: "#4d4d4d" }}  onChange={handleSelectChange}>
//                             <option>Microphone     </option>
//                             <option>Microphone1  </option>
//                             <option>Microphone2  </option>
//                           </select>


//                         </div> */}
                       
//                         <div className="set-media-form-blk ">
//                         <Mic color='#6E6B7B' />
//                           <UncontrolledButtonDropdown className='w-100'>
//                             <DropdownToggle color='secondary text-left Fs14_Fw_400' outline>
//                               {selectedCategory4}
//                               <span className='down2' style={{ position: 'absolute', right: "-5px" }}><ChevronDown /></span>
//                             </DropdownToggle>
//                             <DropdownMenu className='w-100' style={{
//                               boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
//                               borderRadius: '5px',
//                               background: '#FFF'
//                             }}>
//                               <div className='scrollForGoliveFilter text-left'>

//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect4('Microphone')}>
//                                   Microphone
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect4(' Microphone1')}>
//                                   Microphone1
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect4(' Microphone2')}>
//                                   Microphone2
//                                 </DropdownItem>

//                               </div>
//                             </DropdownMenu>
//                           </UncontrolledButtonDropdown>
//                         </div>
//                       </div>
//                       <div className="col-lg-4 col-md-5 col-sm-4 col-5 whocan textvideo">
//                         <a className="gl-test-button fw-light" style={{ color: "#4d4d4d" }}>
//                           <PlayCircle />Test audio
//                         </a>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-lg-8 col-md-7 col-sm-8 col-7">
//                         <label className="setmedia-formlabel">Catagories</label>
//                         <div className="set-media-form-blk ">
//                           <UncontrolledButtonDropdown className='w-100'>
//                             <DropdownToggle color='secondary text-left Fs14_Fw_400' outline>
//                               {selectedCategory}
//                               <span className='down2' style={{ position: 'absolute', right: "-5px" }}><ChevronDown /></span>
//                             </DropdownToggle>
//                             <DropdownMenu className='w-100' style={{
//                               boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
//                               borderRadius: '5px',
//                               background: '#FFF'
//                             }}>
//                               <div className='scrollForGoliveFilter text-left'>

//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Educational')}>
//                                   Educational
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Entertainment')}>
//                                   Entertainment
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Music')}>
//                                   Music
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Sport')}>
//                                   Sport
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Dancing')}>
//                                   Dancing
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Playing Game')}>
//                                   Playing Game
//                                 </DropdownItem>
//                               </div>
//                             </DropdownMenu>
//                           </UncontrolledButtonDropdown>

//                         </div>
//                       </div>
//                       <div className="col-lg-4 col-md-5 col-sm-4 col-5 whocan textvideo">
//                         <label className="setmedia-formlabel">Who Can See</label>
//                         <div className="set-media-form-blk">
//                           <UncontrolledButtonDropdown className='w-100 '>
//                             <DropdownToggle color='secondary text-left Fs14_Fw_400' outline  >
//                               {selectedCategory1}
//                               <span className='down3' style={{ position: 'absolute', right: "-13px" }}><ChevronDown /></span>
//                             </DropdownToggle>
//                             <DropdownMenu className='w-100  show3' style={{
//                               zIndex: '3',
//                               boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
//                               borderRadius: '5px',
//                               background: '#FFF'
//                             }}>
//                               <div className='scrollForGoliveFilter text-left'>

//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Public')}>
//                                   Public
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Connections')}>
//                                   Connections
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Colleagues')}>
//                                   Colleagues
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Family')}>
//                                   Family
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Friends')}>
//                                   Friends
//                                 </DropdownItem>
//                                 <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Relatives')}>
//                                   Relatives
//                                 </DropdownItem>

//                               </div>
//                             </DropdownMenu>
//                           </UncontrolledButtonDropdown>
//                           {/* <select id="inputState" className="form-control">
//                             <option>Public</option>
//                             <option>Connections</option>
//                             <option>Colleagues</option>
//                             <option>Family</option>
//                             <option>Friends</option>
//                             <option>Relatives</option>
//                           </select> */}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row align-items-center custom-row-golive">
//                       <div className="col-lg-8 col-md-7 col-sm-8 col-7 priploi">
//                         <div className="d-flex align-items-center">
//                           <Checkbox onClick={() => setaccept(!accept)} checked={accept ? true : false} />
//                           {/* <input type="checkbox" onClick={() => setaccept(!accept)} checked={accept ? true : false} className="form-check-input" id="exampleCheck1" /> */}
//                           <label className="form-check-label cursor-p" style={{ marginLeft: ".2rem" }} onClick={() => { setaccept(!accept) }} htmlFor="exampleCheck1">I accept the <span className='text-primary'>Privacy Policy</span></label>
//                         </div>
//                       </div>
//                       <div className="col-lg-4 col-md-5 col-sm-4 col-5 golivebutton">
//                         <div className="golive-button-main">
//                           <Button tag='a' disabled={accept ? false : true} href="/CreateGoLiveDetail" outline={accept ? false : true} color='danger' >Go Live</Button>
//                           {/* <a href="/CreateGoLiveDetail" className={`golive-create-btn ${accept ? "btn-danger" : "disable-btn"} `}>Go Live</a> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col-md-12">
//               <div className="golive-card-box">
//                 <div className="golive-card-head">
//                   <h4>Viewer Setting </h4>
//                 </div>
//                 <div className="golive-card-body">
//                   <div className="setup-viewer-setting-blk">
//                     <div className="golivesetting-toggle-box">
//                       <div className="gl-setting-toggle-cont">
//                         <h4 >Turn on comments</h4>
//                         <h5 className=' mt-2'>Viewers can rewind any of your live videos started in Live Producer or on a mobile device. Live videos that are longer than eight hours can't be rewound.</h5>
//                       </div>
//                       <div className="toggle-sec">
//                         <div className="button toggle-btn">
//                           <input type="checkbox" className="checkbox" />
//                           <div className="knobs">
//                             <span></span>
//                           </div>
//                           <div className="layer"></div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="golivesetting-toggle-box">
//                       <div className="gl-setting-toggle-cont ">
//                         <h4>Viewer can see the go live in the scoop (story) section</h4>
//                         <h5 className='mt-2'>Only when the user is live and once they end go live it will not be visible in the scoop section.</h5>
//                       </div>
//                       <div className="toggle-sec">
//                         <div className="button toggle-btn">
//                           <input type="checkbox" className="checkbox" />
//                           <div className="knobs">
//                             <span></span>
//                           </div>
//                           <div className="layer"></div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="golivesetting-toggle-box">
//                       <div className="gl-setting-toggle-cont">
//                         <h4>Enable notification</h4>
//                         <h5 className='mt-2'>Viewers can message you directly through Messenger from your live video.</h5>
//                       </div>
//                       <div className="toggle-sec">
//                         <div className="button toggle-btn">
//                           <input type="checkbox" className="checkbox" />
//                           <div className="knobs">
//                             <span></span>
//                           </div>
//                           <div className="layer"></div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <RightSidebar />
//       </div >
//     </> 
//   )
// }

// export default CreateGoLive 


import React, { useState } from 'react';
import { Mic, PlayCircle, Video, ChevronDown } from 'react-feather';
import Webcam from 'react-webcam';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import { Checkbox } from '@mui/material';
import Down from './img/down3.png'




const CreateGoLive = () => {
  const [selectedCategory, setSelectedCategory] = useState('Select Category');

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };
  const [selectedCategory1, setSelectedCategory1] = useState('Select ');

  const handleCategorySelect1 = (category) => {
    setSelectedCategory1(category);
  };
  const [accept, setaccept] = useState(false)
  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="golive-card-box">
                <div className="golive-card-head golivehead">Go Live</div>
                <div className="golive-card-full-body">
                  <div className="golive-streaming-preview-blk  bg-dark">
                    <div style={{ zIndex: '1' }} className="golive-setting-preview-blk">
                      <h4>Preview</h4>
                    </div>
                    <div className='text-center'>
                      <Webcam
                        mirrored={true}
                        style={{ width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center" }}
                      />
                    </div>
                    <div style={{ zIndex: '0' }} className="golive-ready-live-streaming-blk">
                      <p className='readylive'><span className={accept ? "bg-danger" : "bg-white"}></span> Ready to start Live Stream</p>
                      {/* <a href="#" className="golive-create-btn disable-btn">Go Live</a> */}
                      <a href="/CreateGoLiveDetail" className={`golive-create-btn style ${accept ? "goLiveRedBtn" : "disable-btn"} `}>Go Live</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="golive-card-box">
                <div className="golive-card-head">Setup Media</div>
                <div className="golive-card-body">
                  <div className="setup-media-block">
                    <div className="row">
                      <div className="col-lg-8 col-md-7 col-sm-8 col-7">
                        <div className="set-media-form-blk">
                          <Video color='#6E6B7B' />
                          <span className='drouparrow'><img src={Down}  alt=''/></span>
                          <select id="inputState" className="form-control Fs14_Fw_400" style={{ color: "#4d4d4d" }}>
                            <option>Webcam  </option>
                           
                            <option>Webcam1 </option>
                            <option>Webcam2</option>
                           
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5 col-sm-4 col-5">
                        <select
                          id="inputState"
                          className="form-control test Fs14_Fw_400"
                          style={{ color: "#4d4d4d" }}
                        >
                          <option> Test video   <span style={{position:'absolute',right:"7px"}}><ChevronDown /></span></option>
                          <option>High definition (720p)</option>
                          <option>Standard definition (360p)</option>
                          <option> Low definition (180p)</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 col-md-7 col-sm-8 col-7">
                        <div className="set-media-form-blk ">
                          <Mic color='#6E6B7B' />
                          <span className='drouparrow1'><img src={Down}  alt=''/></span>
                          <select id="inputState" className="form-control Fs14_Fw_400" style={{ color: "#4d4d4d" }}>
                            <option>Microphone     </option>
                            <option>Microphone1  </option>
                            <option>Microphone2  </option>
                          </select>


                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5 col-sm-4 col-5">
                        <a className="gl-test-button   fw-light" style={{ color: "rgba(110, 107, 123, 1)" }}>
                          <div className='playci'>
                          <PlayCircle  className='playcircle'/>Test audio
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 col-md-7 col-sm-8 col-7">
                        <label className="setmedia-formlabel">Catagories</label>
                        <div className="set-media-form-blk ">
                          <UncontrolledButtonDropdown className='w-100'>
                            <DropdownToggle color='secondary text-left Fs14_Fw_400' outline>
                              {selectedCategory}
                              <span className='down2' style={{position:'absolute',right:"-5px"}}><ChevronDown /></span>
                            </DropdownToggle>
                            <DropdownMenu className='w-100' style={{
                              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                              borderRadius: '5px',
                              background: '#FFF'
                            }}>
                              <div className='scrollForGoliveFilter text-left'>
                              
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Educational')}>
                                  Educational
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Entertainment')}>
                                  Entertainment
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Music')}>
                                  Music
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Sport')}>
                                  Sport
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Dancing')}>
                                  Dancing
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect('Playing Game')}>
                                  Playing Game
                                </DropdownItem>
                              </div>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                          {/* <select id="inputState" className="form-control">
                            <option>Select Category</option>
                            <option>Education</option>
                            <option>Comedy</option>
                          </select> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5 col-sm-4 col-5 whocan">
                        <label className="setmedia-formlabel">Who Can See</label>
                        <div className="set-media-form-blk">
                          <UncontrolledButtonDropdown className='w-100 '>
                            <DropdownToggle color='secondary text-left Fs14_Fw_400' outline  >
                              {selectedCategory1}
                              <span className='down3' style={{position:'absolute',right:"-11px"}}><ChevronDown /></span>
                            </DropdownToggle>
                            <DropdownMenu className='w-100 dropshow' style={{
                              zIndex: '3',
                              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                              borderRadius: '5px',
                              background: '#FFF'
                            }}>
                              <div className='scrollForGoliveFilter text-left'>

                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Public')}>
                                  Public
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Connections')}>
                                  Connections
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Colleagues')}>
                                  Colleagues
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Family')}>
                                  Family
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Friends')}>
                                  Friends
                                </DropdownItem>
                                <DropdownItem className="Fs14_Fw_400" onClick={() => handleCategorySelect1('Relatives')}>
                                  Relatives
                                </DropdownItem>

                              </div>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                          {/* <select id="inputState" className="form-control">
                            <option>Public</option>
                            <option>Connections</option>
                            <option>Colleagues</option>
                            <option>Family</option>
                            <option>Friends</option>
                            <option>Relatives</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center custom-row-golive">
                      <div className="col-lg-8 col-md-7 col-sm-8 col-7" style={{
                        left:'-12px'
                      }}>
                        <div className="d-flex align-items-center priv">
                          <Checkbox onClick={() => setaccept(!accept)} checked={accept ? true : false} className='checkbox'/>
                          {/* <input type="checkbox" onClick={() => setaccept(!accept)} checked={accept ? true : false} className="form-check-input" id="exampleCheck1" /> */}
                          <label className="form-check-label cursor-p" style={{ marginLeft: ".2rem" }} htmlFor="exampleCheck1">I accept the
                           <a href='https://www.sociomee.com/privacy-policy/'  className='text-primary'>Privacy Policy</a></label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5 col-sm-4 col-5 golive" >
                        <div className="golive-button-main">
                          <Button tag='a' disabled={accept ? false : true} href="/CreateGoLiveDetail" outline={accept ? false : true} color='danger' >Go Live</Button>
                          {/* <a href="/CreateGoLiveDetail" className={`golive-create-btn ${accept ? "btn-danger" : "disable-btn"} `}>Go Live</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="golive-card-box">
                <div className="golive-card-head">
                  <h4>Viewer Setting </h4>
                </div>
                <div className="golive-card-body">
                  <div className="setup-viewer-setting-blk">
                    <div className="golivesetting-toggle-box">
                      <div className="gl-setting-toggle-cont">
                        <h4 >Turn on comments</h4>
                        <h5 className=' mt-2'> Turning on comments will let your followers/non-followers to leave a comment on your live session and enable two-way communication.</h5>
                      </div>
                      <div className="toggle-sec">
                        <div className="button toggle-btn">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                    <div className="golivesetting-toggle-box">
                      <div className="gl-setting-toggle-cont ">
                        <h4>Viewer can see the go live in the scoop (story) section</h4>
                        <h5 className='mt-2'> Turning on notification will let your followers to see/join your live session right from their story section & share comments.</h5>
                      </div>
                      <div className="toggle-sec">
                        <div className="button toggle-btn">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                    <div className="golivesetting-toggle-box">
                      <div className="gl-setting-toggle-cont">
                        <h4>Enable notification</h4>
                        <h5 className='mt-2'> When you turn on notifications you will get real-time alerts on likes, replies, and new interactions your followers make.</h5>
                      </div>
                      <div className="toggle-sec">
                        <div className="button toggle-btn">
                          <input type="checkbox" className="checkbox" />
                          <div className="knobs">
                            <span></span>
                          </div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
      </div >
    </>
  )
}

export default CreateGoLive