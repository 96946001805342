import React from 'react'
import { Fragment } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const DeleteModal = (props) => {
    return (
        <Fragment>
            <div className='Delete_Events_modal'>
                {
                    props.isOpen !== false &&
                    <Modal isOpen={props.isOpen} toggle={props.updateOpen} className="modal-dialog-centered" >
                        <ModalHeader className='d-flex justify-content-center Media_header '>
                            <h4>Delete Event</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className='d-flex justify-content-center  modalHeading_Section'>
                                <p>Do you really want to delete the Health & fitness Event ?</p>
                            </div>
                            <div className='d-flex justify-content-center  mt-4'>
                                {/* <Button onClick={props.updateOpen} outline className="w-25" >Cancel</Button>
                                <Button onClick={props.updateOpen} color="primary" className="ml-3 w-25 ">OK</Button> */}
                            <Button onClick={props.updateOpen} outline className="w-25" >Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='danger' onClick={props.updateOpen} >Delete</Button>
                                </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default DeleteModal