import React, {useState, useRef} from 'react'
import { Pause } from 'react-feather'
import { Badge, Button, Card } from 'reactstrap'
import Header from '../Header';

const PodcastFooterHead = () => {

    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    /*  ------- Handel Play Pause Function ------- */

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div className='w-100 animeForHeader'>
            {/* <Header /> */} 
            <div className=' d-flex align-items-center justify-content-between p-3'>
                <div className='d-flex align-items-center rem-mob-view'>
                    {/* <img src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' className='rounded' width={100} height={90} /> */}
                    {/* <h1 className='ml-2'>Kahani mein Twist</h1> */}
                    {/* <Badge color='light-primary' pill className='m-2 rem-mob-view'>
                        Free
                    </Badge> */}
                </div>
                <div>
                    
                    {/* <Button onClick={handlePlayPause} className='rem-mob-view' color='primary py-2 ml-2'><Pause size={20} /> Pause</Button> */}
                </div>
            </div>

        </div>
    )
}

export default PodcastFooterHead
