import { Accordion, Checkbox, Pagination, Rating, Stack } from '@mui/material';
import React from 'react'
import { Fragment, useState } from 'react'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { BellOff, MoreHorizontal, Send, Trash2, Users, MessageCircle, Edit, Copy, Circle, Filter } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';

import AvatarGroup from '../../NewComponents/AvatarGroup';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import BizMenu from './BizMenu';
import SvgPin from './Img/SvgPin.svg'
import PinSvg from './Img/PinSvg.svg'
import { BizAllData } from './Components/BizPageArray'
import InviteModal from './AllTypesModal/InviteModal'
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { ForwardArrayData } from '../Social-Feed-Component/FeedArray';
import UserImg from '../../NewComponents/IMG/img.svg'

const JoinedBiz = () => {

    const [followersOpen, setFollowersOpen] = useState(false)
    const toggleFollowers = () => {
        setFollowersOpen((Preview) => !Preview)
    }
    const [isPinned, setIsPinned] = useState(false);
    const [isPinnedindex, setIsPinnedindex] = useState([]);

 

    const togglePin = (index) => {
        setIsPinned(!isPinned);
        const indexExists = isPinnedindex.indexOf(index) !== -1;
        const newArray = indexExists
            ? isPinnedindex.filter((i) => i !== index)  // Unpin: Remove the index if it exists
            : [...isPinnedindex, index];  // Pin: Add the index if it doesn't exist
    
        setIsPinnedindex(newArray);
    };
    const [InviteOpen, setInviteOpen] = useState(false)
    const toggleInvite = () => {
        setInviteOpen((Preview) => !Preview)
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    const ShareModal = () => {
        const Sharedto = [
            {
                name: "Your Wall Feed"
            },
            {
                name: "Your Story"
            }
        ]

        return (
            <div>
                {
                    forwardOpen !== false &&
                    <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>

                        <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
                            <h2>Share to  </h2>
                            <Button color='primary' onClick={toggleForward} >Share</Button>
                        </div>
                        <ModalBody>
                            <div className='pb-2'>

                                <Accordion defaultActiveKey="0">
                                    <AccordionItem eventKey='1'>
                                        <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                                        <AccordionBody className='p-0'>
                                            <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey='1'>
                                                    <AccordionHeader>Feed</AccordionHeader>
                                                    <AccordionBody>
                                                        {
                                                            Sharedto.map((data) => (
                                                                <div className='d-flex justify-content-between align-items-center my-2 Report_Section'>
                                                                    <h4>{data.name}</h4>
                                                                    <div>
                                                                        <Checkbox />
                                                                        {' '}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='2'>
                                                    <AccordionHeader>Your Business Pages</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='3'>
                                                    <AccordionHeader>Your Group</AccordionHeader>
                                                    <AccordionBody>

                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>

                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionBody>
                                    </AccordionItem>

                                </Accordion>
                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Connections</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            {/* <div className=''> */}

                                            <Checkbox />


                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }

    //============================  Main_return_function ============================//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <BizMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="group-single-list-block">
                            <div className="Biz-heading_section py-2">
                                <h2  className='bizhead'>Followed Pages (61)</h2>
                            </div>
                            <Row >
                                {
                                    BizAllData.map((data,index) => (
                                        <Col lg={3} md={4} sm={6} className=" mb-4 cardinbiz cardbizcard" >
                                             <NavLink to="/BizDetail" >
                                            <div className="BizPage_Card_section1">
                                                <div className="BizPage_option_menu">
                                                    <MoreHorizontal size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ marginLeft: '9px' }} />
                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                        <ul>
                                                        <li onClick={() => {togglePin(index)}}>
                                                        <Link to="#" className='Biz-OPTION_menu' >
                                                        <img src={SvgPin} color="#808080" /> {(isPinnedindex.indexOf(index) !== -1) ? 'Unpin This Page' : 'Pin This Page'}
                                                        </Link>
                                                    </li>
                                                            <li>
                                                                <Link to="#" className='Biz-OPTION_menu'><MessageCircle size={15} color="#4D4D4D" />Send message</Link>
                                                            </li>
                                                            <li >
                                                                <Link to="#" className='Biz-OPTION_menu'><MessageCircle size={15} color="#4D4D4D" />Share page via message</Link>
                                                            </li>
                                                            <li onClick={toggleForward}>
                                                                <Link to="#" className='Biz-OPTION_menu'><Send size={15} color="#4D4D4D" />Share page  </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" className='Biz-OPTION_menu'><BellOff size={15} color="#4D4D4D" />Turn off Notification </Link>
                                                            </li>

                                                            <li onClick={toggleInvite}>
                                                                <Link to="#" className='Biz-OPTION_menu'><Users size={15} color="#4D4D4D" /> Invite People to Page</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" className='Biz-OPTION_menu'><BellOff size={15} color="#4D4D4D" />  Report Page</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <NavLink to="/BizDetail" className="bizpagelink-blk"><img src={data.img} className="img-fluid group-img" />
                                                    <div className="group-adminprofile-blk"><img src="assets/images/post-6.jpg" className='rounded-circle' /></div></NavLink>
                                                <NavLink to="/BizDetail" >
                                                <div className="group-content">

                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <h4 className='CardTextHeading'>{data.cardName}  </h4>&nbsp; &nbsp;{data.typeof === "Pin" ?
                                                        <>
                                                            {(isPinnedindex.indexOf(index) !== -1) && <span><img src={PinSvg} /></span>}
                                                        </> : null
                                                    }
                                                </div>
                                                    <h5>{data.category}</h5>
                                                    <div className='d-flex justify-content-center'>
                                                        <Stack spacing={1}>
                                                            <Rating name="read-only" defaultValue={2.5} readOnly />
                                                        </Stack>
                                                    </div>
                                                    <div className="people-likes matual-friend-sec">
                                                        <ul className="matual-friend-blk">
                                                            <AvatarGroup />
                                                        </ul>
                                                        <h6>+79k Followers</h6>
                                                    </div>

                                                    <div className="BizPage-buttons-blk">
                                                        {/* <Link to="#" className="group-btn">Invite People</Link> */}
                                                        <Button color='primary' className="BizBtn_Fill_section "  onClick={(e) => { e.preventDefault(); }} >Followed</Button>
                                                        <Button  onClick={(e) => { e.preventDefault(); toggleInvite(); }} outline color='primary' className=" BizBTN_section" >Invite</Button>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        </NavLink>
                                        </Col>
                            ))
                                }
                        </Row>
                        <div className='d-flex justify-content-center mb-2'>
                            <Stack>
                                <Pagination count={10} className='PaginationCount'>
                                </Pagination>
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
            <RightSidebar />
            <ShareModal/>
            <InviteModal
                isOpen={InviteOpen}
                updateOpen={toggleInvite}

            />
        </div>
        </Fragment >
    )
}

export default JoinedBiz