import TodayImg from "../Img/TodayImg.png"
import Arts from '../Img/Arts.svg'
import Education from '../Img/education.svg'
import Fitness from '../Img/fitness.svg'
import Food from '../Img/food.svg'
import Health from '../Img/health.svg'
import Comedy from '../Img/comedy.svg'
import Musics from '../Img/musics.svg'
import FrdImg from '../Img/FrdImg.svg'
import FrdImg1 from '../Img/FrdImg1.svg'
import FrdImg2 from '../Img/FrdImg2.svg'
import Clndr from '../Img/Clndr.svg'

export const EventsDataArray = [
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "Tag",
        eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "ntTag",
        eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Science & Technology ",
        cardType: "Tag",
        eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "ntTag",
        eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Comedy",
        cardType: "Tag",
        eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "ntTag",
        eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    }
]
export const EventsReminder = [
    // {
    //     id: "1",
    //     img: Clndr,
    //     day: "Events to Remember11 ",
    //     content: "Connect with exclusive event &",
    //     subCotent: 'priceless memories.'
    // },

    {
        id: "0",
        day: "Today",
        count: '12 Events',
        route: "/TodayEvents"
    },
    {
        id: "0",
        day: "Tommorrow",
        count: '41 Events'
    },
    {
        id: "0",
        day: "This Week",
        count: '36 Events'
    },
    {
        id: "0",
        day: "This Month",
        count: '45 Events'
    },
    {
        id: "0",
        day: "Next Month",
        count: '51 Events'
    },
]
export const FilterData = [
    {
        name: "Music"
    },
    {
        name: "Arts"
    },
    {
        name: "Comedy "
    },
    {
        name: "Weddings"
    },
    {
        name: "Food & Recipe"
    },
    {
        name: "Health & Fitness"
    },
    {
        name: "Business & Financial"
    },
    {
        name: "Science & Technology"
    }
]
export const LikeData = [
    {
        name: "Emman Thoma",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "SocioMate ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Followers",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Add Friend ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Follow",
        btnid: "Follow",

    },
    {
        name: "Devon Lane",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Add Friend ",
        btnid: "UFollow"
    },
    {
        name: "Emman Thoma",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "SocioMate ",
        btnid: "UFollow"
    },
    {
        name: "Leslie Alexander",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Add Friend ",
        btnid: "UFollow"
    },
    {
        name: "Devon Lane",
        join: "emman-thoma",
        followData: "9.8M",
        post: "18M",
        btnType: "Following ",
        btnid: "UFollow"
    }
]
export const EventsCategoriesArray = [
    {
        icon: Musics,
        id: "1",
        eventsName: "Music",
        count: "20"
    },
    {
        icon: Arts,
        id: "2",
        eventsName: "Arts ",
        count: "50"
    },
    {
        icon: Comedy,
        id: "3",
        eventsName: "Comedy",
        count: "105"
    },
    {
        id: "4",
        icon: Fitness,
        eventsName: "Fitness ",
        count: "90"
    },
    {
        id: "5",
        icon: Food,
        eventsName: "Food ",
        count: "205"
    },
    {
        id: "6",
        icon: Health,
        eventsName: "Health ",
        count: "121"
    },
    {
        id: "7",
        icon: Education,
        eventsName: "Educational ",
        count: "9999"
    },
    {
        id: "8",
        icon: Arts,
        eventsName: "Arts",
        count: "3999"
    },
    {
        id: "9",
        icon: Comedy,
        eventsName: "Comedy",
        count: "7999"
    },
    {
        id: "10",
        icon: Fitness,
        eventsName: "Fitness ",
        count: "7999"
    },
    {
        id: "11",
        icon: Food,
        eventsName: "Food ",
        count: "3999"
    },
    {
        id: "12",
        icon: Health,
        eventsName: "Health",
        count: "7999"
    },
    {
        id: "13",
        icon: Education,
        eventsName: "Educational",
        count: "3999"
    }

]
export const CardEventData = [
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "Tag",
        date: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "ntTag",
        date: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Science & Technology ",
        cardType: "Tag",
        date: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "ntTag",
        date: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Comedy",
        cardType: "Tag",
        date: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    },
    {
        img: TodayImg,
        tag: "Health & fitness",
        cardType: "ntTag",
        date: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
        place: "Naba Kelebar Utsav Of Param..",
        location: "Wemblay Center, London, UK",
        pplCount: "+50 people interested"
    }
]
export const ForwardArrayData = [
    {
        id: "1",
        img: FrdImg,
        name: "Robert Fox",
        userName: "abriella-bond123"
    },
    {
        id: "2",
        img: FrdImg1,
        name: "Darrell Steward",
        userName: "abriella-bond123"
    },
    {
        id: "3",
        img: FrdImg2,
        name: "Robert Fox",
        userName: "abriella-bond123"
    },
    {
        id: "4",
        img: FrdImg,
        name: "Ralph Edwards",
        userName: "abriella-bond123"
    },
    {
        id: "5",
        img: FrdImg1,
        name: "Savannah Nguyen",
        userName: "abriella-bond123"
    },
    {
        id: "6",
        img: FrdImg2,
        name: "Eleanor Pena",
        userName: "abriella-bond123"
    },
    {
        id: "7",
        img: FrdImg,
        name: "Darlene Robertson",
        userName: "abriella-bond123"
    },
    {
        id: "8",
        img: FrdImg2,
        name: "Bessie Cooper",
        userName: "abriella-bond123"
    }
]
export const ReportGroupData = [
    {
        name: "Abusive"
    },
    {

        name: "Forge Ad"
    }
]
