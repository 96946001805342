//Events
//getUserEvent
export const GET_EVENTS_BY_USER = "GET_EVENTS_BY_USER";

//UPCOMING EVENTS
export const GET_UPCOMING_EVENTS_BY_USER = "GET_UPCOMING_EVENTS_BY_USER";
// export const GET_EVENTS_BY_USER_SUCCESS = "GET_EVENTS_BY_USER_SUCCESS";
// export const GET_EVENTS_BY_USER_ERROR = "GET_EVENTS_BY_USER_ERROR";

//get OnGoing events
export const GET_ONGOING_EVENTS_BY_USER = "GET_ONGOING_EVENTS_BY_USER"

// get Intrested events
export const GET_INTRESTED_EVENTS_BY_USER = "GET_INTRESTED_EVENTS_BY_USER";

export const GET_EVENTS_BY_CATEGORY_LIST = "GET_EVENTS_BY_CATEGORY_LIST";

export const CREATE_EVENT_POST_BY_USER = "CREATE_EVENT_POST_BY_USER";
// user invite his friends
export const INVITE_FRIENDS_BY_USER = "INVITE_FRIENDS_BY_USER"


//export const 