import * as types from '../Constants/index';
import axios from 'axios';

const mainSearchAction = (result) => ({
    type: types.GET_SEARCH_RESULT,
    payload: result,
});
// get all biz category
export const loadSearchdata = (body) => {
    
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
   
    return function (dispatch) {
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/search/all`,body,{ headers: { Authorization: `Bearer ${user?.token}` }})
                .then((res) => {
                    dispatch(mainSearchAction(res.data.data.successResult))
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
};
