import React, { Fragment, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown } from 'reactstrap';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileCover from './ProfileCover';
import ProfileMenu from './ProfileMenu'
import './profileComponents.css'
import Map from './Img/map.png'
import Ppl from './Img/ppl.png'
import { CheckCircle, MapPin, X, Star, Users, MoreHorizontal, EyeOff, ChevronDown, Sliders } from 'react-feather';
import Eye from './Img/eye.png'
import Vector from './Img/Vector.png'
import classnames from 'classnames'
import ViewProfile from "./Img/ViewProfile.png"
import Profile2 from "./Img/profile2.png"
import Profile3 from "./Img/profile3.png"
import FlwrImg from './Img/FlwrImg.png'
import { getEventsAction } from '../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import CreationMainSection from '../../components/Social-feed/CreationMainSection';
import DiscaedModal from '../../components/Social-feed/DiscaedModal';

// import Star from './Img/Star 4.png'
import { Checkbox, Pagination, PaginationItem, Stack } from '@mui/material';
import EventsTabSection from './EventsFolder/EventsTabSection';
import DeletedEventModal from './EventsFolder/EventsModal/DeletedEventModal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

const MyEvent = () => {

    const [searchTerm, setSearchTerm] = useState("");
    const [currentActiveTab, setCurrentActiveTab] = useState('1')
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    }

    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));

    const [activeTab, setActiveTab] = useState(1000);
    const states=activeTab;
    const [postType, setPostType] = useState("event");
    const [currState,setCurrState]=useState(0)
    const [modal, setModal]=useState('')
    const [selectedImages,setSelectedImages]=useState('')
    const [path, setPath] = useState("/modal/");
    const [discard, setDisCard] = useState(false);
    const [feelingCategory,setFeelingCategory]=useState("");
    
    const toggleDiscard = () => {
        setDisCard((preState) => !preState);
      };
      const toggleAllDiscard = () => {
        setModal(false);
        toggleDiscard(false);
    
      };

    const toggle1 = (path) => {

        console.log(path.indexOf("/modal/"),"   path.indexof(`modal`)")
        if (path.indexOf("/modal/" > -1)) {
          if (modal) {
            setActiveTab(parseFloat(path.replace("/modal/", "")));
        
            setPath(path);
           
            const index = parseFloat(path.replace("/modal/", ""));
          console.log("in toggle function",index)
            const postType ="event"
           
          } else {
          console.log("in toglle mode elese sections")
          const index = parseFloat(path.replace("/modal/", ""));
          
          
            setActiveTab(parseFloat(path.replace("/modal/", "")));
            setModal(true);
            setPath(path);
          
          }
        } else {
    
          setModal(false);
        }
      };

    const debouncedSetSearchKey = useCallback(
        debounce((value) => {
            setSearchTerm(value);
        }, 300),
        [] 
    );

    const handleInputChange = (value) => {
        debouncedSetSearchKey(value);
    };
    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    <Fragment>
                        <div className="container-fluid section-t-space px-0">
                            <div className="page-content">
                                <div className="content-center w-100">
                                    <div className="gallery-page-section section-b-space">
                                        <div className="card-title">
                                            <h3>My Events</h3>
                                            <div className="right-setting">
                                                <div className="profile-search-blk ">
                                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="search" placeholder="Find..."
                                                        data-bs-toggle="modal" data-bs-target="#peopleSearch"
                                                        onChange={(e) => { handleInputChange(e.target.value) }} />
                                                </div>
                                                    <a onClick={() => {
                                                        setModal(true);
                                                        toggle1("/modal/7.3");
                                                    }} className="btn btn-outline-green ms-3">+ Create Events</a>
                                                </div>
                                        </div>
                                        <EventsTabSection searchTerm={searchTerm} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
                <RightSidebar />
                <CreationMainSection
                userId={userId}
                states={states}
                currState={currState}
                    setModal={setModal}
                    modal={modal}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    path={path}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    postType={postType}
                    setPostType={setPostType}
                    toggle={toggle}
                    toggleDiscard={toggleDiscard}
                    feelingCategories={feelingCategory}
                />
                <DiscaedModal
                    discard={discard}
                    toggleDiscard={toggleDiscard}
                    toggleAllDiscard={toggleAllDiscard}
                />
            </div>
        </>
    )
}

export default MyEvent