import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown } from 'reactstrap'
import classnames from 'classnames'
// import { useState } from 'react'
import { Link, unstable_HistoryRouter, useNavigate } from 'react-router-dom'
import { AlertTriangle, Calendar, CheckCircle, ChevronDown, ChevronRight, Circle, CornerUpLeft, CornerUpRight, Filter, Grid, List, MapPin, MoreHorizontal, Search, Share2, Star, User, UserPlus, Users, X } from 'react-feather'
import Slider from 'react-slick'
import AvatarGroup from '../../../NewComponents/AvatarGroup'
import MyEvents from './MyEvents'
import { EventsCategoriesArray, CardEventData, LikeData, ForwardArrayData } from './EventsDataArray'
import { Avatar, Pagination, Stack } from '@mui/material'
import FlwrImg from '../Img/FlwrImg.png'
import eventpic from '../Img/eventpic.png'
import avtar from '../Img/avtar.png'
import avt from '../Img/avt.png'
import Event from '../../Universal-components/display/Sub-component/Event'
import EventsListView from './EventsListView'
import ReportModal from '../EventsModal/ReportModal'
import FilterModal from '../EventsModal/FilterModal'
import ForwardModal from '../EventsModal/ForwardModal'
import InterestedModal from '../EventsModal/InterestedModal'
import { useDispatch, useSelector } from 'react-redux';
import { loadEventCategory, loadEventCategoryList } from '../../../Services/Actions/SocialFeed/getEventCategoryAction';
import { upcommingEventCategoryAction } from '../../../Services/Actions/Event/getUpcommingEventPageAction'
import music from '../Img/music.png'
import pa from '../Img/pa.png'
import cm from '../Img/cm.png'
import fast from '../Img/fast.png'
import heart from '../Img/heart.png'
import { Offcanvas } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { FaTimes } from 'react-icons/fa';
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import InviteModal from '../../biz-components/AllTypesModal/InviteModal'
// =================== Slider
let EventsSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,

            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }

    ]
};
// =================== numFormatter

const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
        return `${(num / 1000).toFixed(1).toLocaleString(undefined, {})} k`
    } else if (num > 999999) {
        return `${(num / 1000000).toFixed(1).toLocaleString(undefined, {})} M`
    } else if (num < 900) {
        return `${num}`
    }
}


//=================== Main_function 
const EventTabMenu = () => {
    let dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const { eventCategory, loading } = useSelector(state => state.getEventCategoryData)
    const filteredData = eventCategory?.filter(item =>
        item?.categoryName?.toLowerCase().includes(searchTerm?.toLowerCase()));
    useEffect(() => {
        dispatch(loadEventCategory());
    }, []);
    console.log('filteredData', filteredData)
    console.log('searchTerm', searchTerm)

    ///////////////////////////////////
    const { upcommingEvent, loding } = useSelector(state => state.getUpcommingCategoryData)

    useEffect(() => {
        const obj = {
            day: "today",
            categoryId: filteredData.categoryId,

            eventLocation: "",
            lat: "",
            long: "",
            radius: "",
        };
        dispatch(upcommingEventCategoryAction(obj));

    }, []);
    const [InviteOpen, setInviteOpen] = useState(false)
    const toggleInvite = () => {
        setInviteOpen((Preview) => !Preview)
    }

    console.log("upcommingEvent", upcommingEvent)

    //   // event category list
    //   const { userEvents, loading } = useSelector(state => state.getEventCategoryData)


    //   useEffect(() => {
    //       const obj = {
    //           day: "",
    //           categoryId: "", 
    //           eventLocation: "",
    //           lat: "",
    //           long: "",
    //           radius: ""
    //       };
    //       dispatch(userEvents(obj));

    //       console.log('userEvents', userEvents)

    //   }, []);

    //     let dispatch = useDispatch();

    //     const getEventData = useSelector((state) => { return state.getUserEventsData });
    //     const { loading: loadingresultEvent, error: errorresultEvent, result: resultEvent } = getEventData

    // console.log('Events data',resultEvent)
    const filteredDataText = [
        {
            id: '1',
            image: music,
            name: 'Music(16)'
        },
        {
            id: '2',
            image: pa,
            name: 'Arts(32)'
        },
        {
            id: '3',
            image: cm,
            name: 'Comedy(09)'
        },
        {
            id: '1',
            image: heart,
            name: 'Fitness(11)'
        },
        {
            id: '2',
            image: fast,
            name: 'Food(21)'
        },
        {
            id: '3',
            image: cm,
            name: 'Health(45)'
        },
        {
            id: '1',
            image: music,
            name: 'Music(16)'
        },
        {
            id: '2',
            image: pa,
            name: 'Arts(32)'
        },
        {
            id: '3',
            image: cm,
            name: 'Comedy(09)'
        },
        {
            id: '1',
            image: heart,
            name: 'Fitness(11)'
        },
        {
            id: '2',
            image: fast,
            name: 'Food(21)'
        },
        {
            id: '3',
            image: cm,
            name: 'Health(45)'
        }
    ]
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [selectActive, setSelectActive] = useState(false)
    const [eventActive, setEventActive] = useState(false)


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    // const handleShow = () => setShow(true);
    const [cSelected, setCSelected] = useState([1])
    const onCheckboxBtnClick = selected => {
        const index = cSelected.indexOf(selected)
        if (index < 0) {
            cSelected.push(selected)
        } else {
            cSelected.splice(index, 1)
        }
        setCSelected([...cSelected])
    }
    const [currentActiveTab, setCurrentActiveTab] = useState('1')
    const toggle = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)

    }
    const [categoriesActiveTab, setCategoriesActiveTab] = useState('1')
    const toggleCategories = tab => {
        if (categoriesActiveTab !== tab) setCategoriesActiveTab(tab)
    }
    const [listActiveTab, setListActiveTab] = useState(false)
    const toggleList = () => {
        setListActiveTab((preState) => !preState)
    }
    const [filterOpen, setFilterOpen] = useState(false)
    const toggleFilter = () => {
        setFilterOpen((preState) => !preState)
    }
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }
    const [interestedOpen, setInterestedOpen] = useState(false)
    const toggleInterested = () => {
        setInterestedOpen((preState) => !preState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((prevState) => !prevState)
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleShow = () => {
        if (windowWidth >= 280 && windowWidth <= 820) {

            console.log('Event Categories1 clicked!');
            setShow(true);
        }
    };

    const [isMapVisible, setIsMapVisible] = useState(false);

    const toggleMap = () => {
        setIsMapVisible(!isMapVisible);
    };
    const navigate = useNavigate();

    // const handleClick = () => {
    //     navigate('/MyHosted ');
    //     console.log('MyHosted=======', 'MyHosted');
    // };

    const [isMusic, setIsMusic] = useState(false);

    const handleClick = () => {
        console.log("hellloooo")
        setIsMusic(!isMusic);


    };
    const handleClick1 = () => {
        setIsSectionVisible((prev) => !prev);
        setTimeout(() => {
            setIsSectionVisible((prev) => !prev);
          }, 200);
    };
    const [isMobileView, setIsMobileView] = useState(false);
    const [currentActiveTab1, setCurrentActiveTab1] = useState('1')
    const toggles = tab => {
        if (currentActiveTab1 !== tab) setCurrentActiveTab1(tab)
    }
    const [createOpen, setCreateOpen] = useState(false)
    const toggleCreate = () => {
        setCreateOpen((preState) => !preState)
    }
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 820);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBackdropClick = (event) => {
        const handleClose = () => setShow(false);
    };
    // const [seemore, setSeeMore] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [selectedOption, setSelectedOption] = useState('Interested');

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };
    const [isSectionVisible, setIsSectionVisible] = useState(true);


    //=================== Start_EventsCategories_function ===================//
    const EventsCategories = () => (
        <Fragment>
            <div className='Categories_LeftPannel_slider Categories_LeftPannel_sliders m-2' >
                <div className="Categories_Left_block" >
                    <div className="EventsCustom-card-head  biz-carddetail-blk d-dlex justify-content-between">

                        <h4 style={{ fontSize: '14px', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '8px', display: 'flex', alignItems: 'center' }} onClick={handleShow}>
                            <div className='EventsCategories_calender'>
                                <Calendar color='#81C14B' size={24} />
                            </div>
                        </h4>
                        <h4
                            className="text event-categories-text"
                            style={{
                                fontSize: '14px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            Event Categories
                        </h4>
                    </div>
                    <div className="custom-card-head biz-carddetail-blk Total_count_Events_Categories rem-mob-view-text">
                        <h5>All Events ({numFormatter(eventCategory.length ? eventCategory.length : '0')})</h5>
                    </div>
                    <div className="Categories_LeftScroll_block rem-mob-view-text" id='Categories-Scroll-1'>
                        <div className="biz-about-cont">
                            <ul>
                                {/* =================api data========= */}
                                {/* {

                                    filteredData?.map((data) => (
                                        <li className={categoriesActiveTab === data.categoryId ? 'Events_categories_leftSection' : 'leftSection'}>
                                            <div className='nave-items-bizPage d-flex ' onClick={() => { toggleCategories(data.categoryId) }} active={categoriesActiveTab === data.categoryId} >

                                                <img src={data.thumbnailUrl} width={31} height={31} alt='' />
                                                <div className='ml-3 EventsCategories_All_events'>
                                                    <h5>{data.categoryName} &nbsp;({numFormatter(data.events.length)})</h5>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                } */}
                                {
                                    filteredDataText?.map((data) => (
                                        <li className={categoriesActiveTab === data.categoryId ? 'Events_categories_leftSection' : 'leftSection'}>
                                            <div className='nave-items-bizPage d-flex' >
                                                {/* <p>{data.id}</p> */}
                                                <img src={data.image} width={31} height={31} alt='' />
                                                <div className='ml-3 EventsCategories_All_events'>
                                                    {/* onClick={() => setSelectActive(!selectActive)} */}
                                                    <Link onClick={handleClick1}>
                                                        <h5>{data.name} &nbsp;</h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ==========================top to bottom modal all event cate.....===================== */}
                <Offcanvas placement='bottom' show={show} onHide={handleClose} className='canvas1 event_drop all-event-cat canvas1-text' >
                    <Offcanvas.Header>
                        <h4>All Event Categories</h4>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='p-0 canvas2 Categories_LeftScroll_blocks' >
                        <ul style={{ fontFamily: "Poppins", fontWeight: '400', fontSize: '14px', marginBottom: '7px' }} className='canvas'>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '1'
                                    })}
                                onClick={() => { toggle('1'); handleClose() }}
                            >
                                <li className='text-muted border-top-0'><img src={music} width={31} height={31} alt='' />&nbsp;Music(16)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2'); handleClose() }}
                            ><li className='text-muted border-top-0' ><img src={pa} width={31} height={31} alt='' />&nbsp;Arts(09)</li></NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '1'
                                    })}
                                onClick={() => { toggle('1'); handleClose() }}
                            >
                                <li className='text-muted border-top-0 py-11' ><img src={cm} width={31} height={31} alt='' />&nbsp;Comedy(09)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2'); handleClose() }}
                            >
                                <li className='text-muted py-11 border-top-0'  ><img src={fast} width={31} height={31} alt='' />&nbsp;Food(21)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2'); handleClose() }}
                            >
                                <li className='text-muted py-11 border-top-0'  ><img src={cm} width={31} height={31} alt='' />&nbsp;Health(45)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '1'
                                    })}
                                onClick={() => { toggle('1'); handleClose() }}
                            >
                                <li className='text-muted border-top-0'><img src={music} width={31} height={31} alt='' />&nbsp;Music(16)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2'); handleClose() }}
                            ><li className='text-muted border-top-0' ><img src={pa} width={31} height={31} alt='' />&nbsp;Arts(09)</li></NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '1'
                                    })}
                                onClick={() => { toggle('1'); handleClose() }}
                            >
                                <li className='text-muted border-top-0 py-11' ><img src={cm} width={31} height={31} alt='' />&nbsp;Comedy(09)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2'); handleClose() }}
                            >
                                <li className='text-muted py-11 border-top-0'  ><img src={fast} width={31} height={31} alt='' />&nbsp;Food(21)</li>
                            </NavLink>
                            <NavLink style={{ borderBottom: '1px solid #6c757d' }}
                                className={
                                    classnames({
                                        active: currentActiveTab === '2'
                                    })}
                                onClick={() => { toggle('2'); handleClose() }}
                            >
                                <li className='text-muted py-11 border-top-0'  ><img src={cm} width={31} height={31} alt='' />&nbsp;Health(45)</li>
                            </NavLink>
                        </ul>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </Fragment>
    )
    //=================== End_EventsCategories_function ===================//

    // useEffect(() => {
    //     dispatch(getUserEventsAction())
    // }, []);
    const UpCommingEvents = () => (
        <Fragment>
            <TabContent activeTab={categoriesActiveTab} style={{ marginTop: '10px' }}>
                <div className="custom-card-block m-2">
                    <div className="custom-card-head biz-carddetail-blk">
                        <h4>Upcomming Events</h4>
                    </div>
                </div>
                <Fragment>
                    <TabPane tabId="1">
                        <div className="container-fluid section-t-space px-0" >
                            {isSectionVisible && (<div className="group-main-block m-1">

                                <ScheduleEvents
                                    title="Today"
                                />

                                <ScheduleEvents
                                    title="Tommorrow"
                                />
                                <ScheduleEvents
                                    title="This Week"
                                />
                            </div>)}
                        </div>
                    </TabPane>
                </Fragment>
                <TabPane tabId="2">
                    Arts (32)
                </TabPane>
                <TabPane tabId="3">
                    Comedy (09)
                </TabPane>
                <TabPane tabId="4">
                    Fitness(11)
                </TabPane>
                <TabPane tabId="5">
                    Food (21)
                </TabPane>
                <TabPane tabId="6">
                    Health (45)
                </TabPane>
            </TabContent>
        </Fragment>
    )
    let MobileviewSlider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,

                }
            },

            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    const ScheduleEvents = (props) => (
        <Fragment>
            <div className="group-custom-block m-1" >
                {/* style={{ marginTop: '-8px' }} */}
                <div className="heading-podcast-blk ">
                    <h3 style={{ marginLeft: '4px' }}>{props.title} ({upcommingEvent.length ? upcommingEvent.length : '0'})</h3>
                    <p className='d-flex align-items-center text-primary ' style={{ cursor: "pointer" }} onClick={() => setSelectActive(!selectActive)} >See All <ChevronRight size={20} /></p>
                </div>
                {/* <Slider {...EventsSettings} className="default-space">
                    {
                        upcommingEvent?.map((data) => (
                            <div className='p-1'>
                                <div className="MyEvents_box_slider_section">
                                    <div className="MyEvent_tAG">
                                        <div className='event_Tag_section'>
                                            <h5 >{data.tag}</h5>
                                        </div>
                                    </div>
                                    <div className="MyEvent_tAG">
                                        <div className='event_Tag_section'>
                                            <h5>{data.name}</h5>
                                        </div>
                                    </div>

                                    <Link to="/AllEvents" ><img src={eventpic} alt="img" onClick={toggleList} className="img-fluid MyEvent-img" /></Link>
                                    <div className="MyEvent_BodySection">
                                        <div className='MyEvent_First_BodySection'>

                                            <small style={{ fontSize: '12px', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }} className=' event-card-text d-block d-lg-inline'>Sun,26 Jun 2022, 06:00PM - 09:30AM</small>&nbsp;&nbsp;
                                            <div className='MyEvents_more_btn text-center' style={{ marginRight: '-13px' }}>
                                                <UncontrolledButtonDropdown className='align-self-center' >
                                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                                        <MoreHorizontal color='#808080' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenus width-for-dropdown dropdownLocation' style={{ marginLeft: '-10px', marginTop: '-22px' }}>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Events</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleInvite} ><Users size={13} />&nbsp;&nbsp;Invite</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal MyeventsCategories_Option mt-5" style={{ marginRight: '-20px' }}  >
                                                </div>
                                            </div>
                                        </div>
                                        <div className='py-1'>

                                            <h4 style={{ fontSize: '13px', textWrap: 'nowrap' }} className='event-card-text'>Naba Kelebar Of Param..</h4>
                                        </div>
                                        <div className='py-1 event-card-text d-flex' >
                                            <MapPin color='#808080' size={16} />&nbsp;

                                            <span color='#808080' style={{ textWrap: 'nowrap' }}>&nbsp;Wemblay Center,UK</span>
                                        </div>
                                        <div className="people-likes matual-friend-sec matual-friend-section mt-2" style={{ position: 'relative', left: '7px' }} >

                                            <h6 style={{ cursor: "pointer" }} onClick={toggleInterested}>
                                                <img src={avt} alt="" style={{ position: 'absolute', left: '-7px', width: '28px', top: '-7px' }} className='avt-img-section' />
                                                <img src={avt} alt="" style={{ position: 'absolute', left: '7px', width: '28px', top: '-7px' }} className='avt-img-sections' />
                                                <img src={avt} alt="" style={{ position: 'absolute', left: '26px', width: '28px', top: '-7px' }} />
                                            </h6>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5%', maxWidth: '90%' }}>
                                                <h6 style={{ cursor: "pointer", color: '#808080', fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '42px' }} className='BizBTN_sections'>50 people interested</h6>
                                            </div>


                                        </div>

                                        <div className="BizPage-buttons-blk mt-3 d-flex" style={{ marginLeft: '-5px' }}>

                                            <Button className="BizBTN_section BizBTN_sections" style={{ marginLeft: "5px", background: '#F6F6F6', color: '#4D4D4D', paddingLeft: '3px' }}><Star size={16} className='star-img' />&nbsp;Interested</Button>

                                            <Button className=" BizBTN_section BizBTN_sections" style={{ background: '#F6F6F6', color: '#4D4D4D', paddingLeft: '3px' }} onClick={toggleForward}>&nbsp;<CornerUpRight size={15} className='star-img' />Forword</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider> */}
                <Slider {...MobileviewSlider} className="default-space">
                    {
                        upcommingEvent?.map((data) => (
                            <div className='p-1'>
                                <div className="MyEvents_box_slider_section">
                                    <div className="MyEvent_tAG">
                                        <div className='event_Tag_section'>
                                            <h5 >{data.tag}</h5>
                                        </div>
                                    </div>
                                    <div className="MyEvent_tAG">
                                        <div className='event_Tag_section'>
                                            <h5>{data.name}</h5>
                                        </div>
                                    </div>

                                    <Link to="/AllEvents" ><img src={eventpic} alt="img" onClick={toggleList} className="img-fluid MyEvent-img" /></Link>
                                    <div className="MyEvent_BodySection">
                                        <div className='MyEvent_First_BodySection'>

                                            <small style={{ fontSize: '12px', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }} className=' event-card-text d-block d-lg-inline'>Sun,26 Jun 2022, 06:00PM - 09:30AM</small>&nbsp;&nbsp;
                                            <div className='MyEvents_more_btn text-center' style={{ marginRight: '-9px' }}>
                                                <UncontrolledButtonDropdown className='align-self-center' >
                                                    <DropdownToggle className='p-0 m-0' color='flat' >
                                                        <MoreHorizontal color='#808080' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className='bizpagefordropdownmenus width-for-dropdown dropdownLocation' style={{ marginLeft: '-10px', marginTop: '-22px' }}>
                                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Events</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleInvite} ><Users size={13} />&nbsp;&nbsp;Invite</DropdownItem>
                                                        <DropdownItem divider></DropdownItem>
                                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal MyeventsCategories_Option mt-5" style={{ marginRight: '-20px' }}  >
                                                </div>
                                            </div>
                                        </div>
                                        <div className='py-1'>

                                            <h4 style={{ fontSize: '13px', textWrap: 'nowrap' }} className='event-card-text'>Naba Kelebar Of Param..</h4>
                                        </div>
                                        <div className='py-1 event-card-text d-flex' >
                                            <MapPin color='#808080' size={16} />&nbsp;

                                            <span color='#808080' style={{ textWrap: 'nowrap' }}>&nbsp;Wemblay Center,UK</span>
                                        </div>
                                        <div className="people-likes matual-friend-sec matual-friend-section mt-2" style={{ position: 'relative', left: '7px' }} >

                                            <h6 style={{ cursor: "pointer" }} onClick={toggleInterested}>
                                                <img src={avt} alt="" style={{ position: 'absolute', left: '-7px', width: '28px', top: '-7px' }} className='avt-img-section' />
                                                <img src={avt} alt="" style={{ position: 'absolute', left: '12px', width: '28px', top: '-7px' }} className='avt-img-sections' />
                                                <img src={avt} alt="" style={{ position: 'absolute', left: '26px', width: '28px', top: '-7px' }} />
                                            </h6>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5%', maxWidth: '90%' }}>
                                                <h6 style={{ cursor: "pointer", color: '#808080', fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '42px' }} className='BizBTN_sections'>50 people interested</h6>
                                            </div>


                                        </div>

                                        <div className="BizPage-buttons-blk mt-3 d-flex" style={{ marginLeft: '-5px' }}>

                                            <Button className="BizBTN_section BizBTN_sections" style={{ marginLeft: "5px", background: '#F6F6F6', color: '#4D4D4D', paddingLeft: '3px' }}><Star size={16} className='star-img' />&nbsp;Interested</Button>

                                            <Button className=" BizBTN_section BizBTN_sections" style={{ background: '#F6F6F6', color: '#4D4D4D', paddingLeft: '3px' }} onClick={toggleForward}>&nbsp;<CornerUpRight size={15} className='star-img' />Forword</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>

            </div>
        </Fragment>
    )
    //=================== end_Slider_Schedule_Events_Function_Like_Today_Tomorrow_week_Months ===================//
    //=================== Start_ List_and_Grid_View_Function  ===================//
    const UpcommingEventsList = () => (
        <Fragment>
            <div className="Categories_Left_block mb-3" >
                <div className="custom-card-head biz-carddetail-blk p-1  m-2">
                    <div className='Events_list_heading '>
                        <h4 style={{ fontSize: '15px' }}>Upcomming Events</h4>
                        <p className='mt-1' style={{ fontSize: '15px' }}>This week ({upcommingEvent.length ? upcommingEvent.length : '0'})</p>
                    </div>
                    <div className='d-flex align-items-center '>
                        <Button color='primary' outline onClick={toggleList}
                            active={listActiveTab === true}
                        >
                            <List size={16} />
                        </Button>
                        <Button color='primary' outline className="ml-2 mr-2" onClick={toggleList}
                            active={listActiveTab === false}
                        >
                            <Grid size={16} />
                        </Button>
                        <Button onClick={() => setSelectActive(!selectActive)} outline color="primary"><CornerUpLeft size={14} />&nbsp;Back</Button>
                    </div>
                </div>
            </div>
            {listActiveTab === false ?
                <Fragment>
                    <Row className="Events_PeopleThisGrid m-1">
                        {
                            upcommingEvent?.map((data) => (
                                <Col lg="3 " className="w-100" >
                                    <div className="events-custom-block">
                                        <div className="MyEvents_box_slider_section">
                                            <div className="MyEvent_tAG">
                                                <div className='event_Tag_section'>
                                                    <h5>{data.name}</h5>
                                                </div>
                                            </div>
                                            {/* ====api img url====== */}
                                            {/* <Link to="#" ><img src={data.thumbnailUrl} alt="img" className="img-fluid MyEvent-img" /></Link> */}
                                            <Link to="/AllEvents" ><img src={eventpic} alt="img" onClick={toggleList} className="img-fluid MyEvent-img" /></Link>
                                            <div className="MyEvent_BodySection">
                                                <div className='MyEvent_First_BodySection '>
                                                    {/* ======api data===*/}
                                                    {/* <small>{data.createdAt}</small> */}
                                                    <small style={{ fontSize: '12px', textWrap: 'nowrap' }} className=''>Sun,26 Jun 2022, 06:00PM - 09:30AM</small>
                                                    <div className='MyEvents_more_btn text-center' style={{ marginLeft: '11px' }}>
                                                        <UncontrolledButtonDropdown className='align-self-center' >
                                                            <DropdownToggle className='p-0 m-0' color='flat' >
                                                                <MoreHorizontal color='#808080' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className='bizpagefordropdownmenus  dropdownLocation'>
                                                                <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Events</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className='dropdownitem' onClick={toggleInvite} ><Users size={13} />&nbsp;&nbsp;Invite</DropdownItem>
                                                                <DropdownItem divider></DropdownItem>
                                                                <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                        <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal MyeventsCategories_Option mt-5" style={{ marginRight: '-20px' }}  >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='py-2'>
                                                    {/* ====api location place===== */}
                                                    {/* <h4>{data.place}</h4> */}
                                                    <h4 style={{ fontSize: '14px', textWrap: 'nowrap' }} className=''>Naba Kelebar Of Param..</h4>
                                                </div>
                                                <div className='py-1' style={{ marginTop: '-10px' }}>
                                                    <MapPin color='#808080' size={16} />&nbsp;
                                                    {/* ====api location data===== */}
                                                    {/* <span>{data.location}</span> */}
                                                    <span>Bessemer,North Carolina</span>
                                                </div>
                                                <div className="people-likes matual-friend-sec ">
                                                    <AvatarGroup />
                                                    {/* ====api data===== */}
                                                    {/* <h6 style={{ cursor: "pointer" }} onClick={toggleInterested}>{data.pplCount}</h6> */}
                                                    <small style={{ textWrap: 'nowrap' }}><Users size={12} />&nbsp;160 Going</small>
                                                    <small className='ml-5' style={{ textWrap: 'nowrap', paddingLeft: '38px' }}><Users size={12} />&nbsp;154 Interested</small>
                                                </div>
                                                <div className="BizPage-buttons-blk mt-2" >
                                                    <UncontrolledButtonDropdown className='UncontrolledButtonDropdown w-50'>
                                                        <DropdownToggle className='border-select' color="primary" style={{ background: '#F6F6F6', color: '#4D4D4D', padding: '9px' }}>
                                                            <p className='' style={{ fontSize: '14px' }}><Star size={16} />&nbsp;{selectedOption}<ChevronDown size={16} /></p>
                                                            <DropdownMenu className="mt-1">
                                                                <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Interested')} >
                                                                    <Star size={16} />&nbsp;Interested
                                                                </DropdownItem>
                                                                <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Going')} >
                                                                    <CheckCircle size={18} />&nbsp;Going
                                                                </DropdownItem>
                                                                <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Not Interested')}>
                                                                    <X size={18} />&nbsp;Not Interested</DropdownItem>
                                                            </DropdownMenu>
                                                        </DropdownToggle>
                                                    </UncontrolledButtonDropdown>&nbsp;&nbsp;&nbsp;
                                                    {/* <Button onClick={toggleForward} outline color='primary' className="BizBTN_section border-select w-50" style={{ background: '#F6F6F6', color: '#4D4D4D', padding: '9px'}}>&nbsp;<CornerUpRight size={15} />Forword</Button> */}
                                                    <div className='border-select w-50 rounded-2 ' style={{ background: '#F6F6F6', color: '#4D4D4D', padding: '9px', cursor: 'pointer' }}  >
                                                        <p className='d-flex align-items-center justify-content-center' style={{ fontSize: '14px' }} onClick={toggleForward}>&nbsp;<CornerUpRight size={15} />Forword</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <div className='d-flex justify-content-center mb-3'>
                        <Stack>
                            <Pagination count={10} className='PaginationCount' />

                        </Stack>
                    </div>
                </Fragment>
                : listActiveTab === true ?
                    <Fragment>
                        <Row >
                            <Col sm="12" lg="3" md="12">
                                <EventsCategories />
                            </Col>
                            <Col sm="12" lg="5" md="12" >
                                <Event />
                            </Col>
                            <Col sm="12" lg="4" md="12" >
                                <EventsListView />
                            </Col>
                        </Row>
                    </Fragment>
                    : null
            }
        </Fragment>
    )
    const [showInput, setShowInput] = useState(false);

    // useEffect to add a resize event listener
    useEffect(() => {
        const handleResize = () => {
            // Hide the input box when the screen is wider than 500px
            if (window.innerWidth >= 820) {
                setShowInput(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //_=============Main_Function_Block=============//   
    const isMobile = window.innerWidth >= 750;
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 750) {
                setIsSearch(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleSearchIconClick = () => {
        setIsSearch((prev) => !prev);
    };

    const handleCloseIconClick = () => {
        setIsSearch(false);
        setSearchTerm('');
    };
    return (
        <Fragment>
            {/* ===============False_state_Upcoming_section=============== */}
            {!selectActive ? (
                <>
                    <Row className="EventsDetail_tab_Section EventsDetail_tab_Sections mt-3 m-1">
                        <Col md="8" style={{ marginLeft: '-16px' }}>
                            <Nav className='p-1' pills style={{ margin: '1%' }}>
                                <NavItem
                                    style={{
                                        background: (!isSearch || window.innerWidth > 750) ? "rgba(129, 193, 75, 0.2)" : "white",
                                        color: "rgba(0, 0, 0, 0.5)",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        borderRadius: "5px",
                                        marginRight: "15px",
                                        marginLeft: '16px',
                                        display: 'block'
                                    }}
                                >
                                    <NavLink
                                        className={classnames({
                                            active: currentActiveTab === '1',
                                            hide: 'nav-pills .nav-link.active'
                                        })}
                                        onClick={() => { toggle('1') }}
                                        style={{
                                            display: (isSearch && window.innerWidth <= 750) ? 'none' : 'block'
                                        }}
                                    >
                                        {(!isSearch || window.innerWidth > 750) && (<span>Upcoming</span>)}
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{
                                    background: (!isSearch || window.innerWidth > 750) ? "rgba(129, 193, 75, 0.2)" : "white",
                                    color: "rgba(0, 0, 0, 0.5)",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    borderRadius: "5px",
                                    marginRight: "12px"
                                }}>
                                    <NavLink
                                        className={classnames({
                                            active: currentActiveTab === '2',
                                            hide: 'nav-pills .nav-link.active'
                                        })}
                                        onClick={() => { toggle('2') }}
                                        style={{
                                            display: (isSearch && window.innerWidth <= 750) ? 'none' : 'block'
                                        }}
                                    >
                                        {(!isSearch || window.innerWidth > 750) && (<span>My Events</span>)}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="4" className="d-flex align-items-center justify-content-end p-2 input-card-event-text">
                            <div className="group-search-assign-filter card-filter">
                                <div className="Events_srch_block">
                                    <div className="input-Events_search-blk vghfthftgh">
                                        <Search
                                            onClick={handleSearchIconClick}
                                            className={`search-svg ${isSearch ? 'hide' : ''}`}
                                        />
                                        {(isSearch || window.innerWidth > 750) && (
                                            <Input
                                                type="text"
                                                className="form-control1 border-0 text-input input-search-text"
                                                name="search"
                                                placeholder="Find Events ..."
                                                data-bs-toggle="modal"
                                                data-bs-target="#peopleSearch"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            />
                                        )}
                                        {window.innerWidth <= 750 && isSearch && (
                                            <X
                                                style={{ position: 'absolute', top: '10px', right: '4px', color: '#8e8e9b' }}
                                                size={18}
                                                onClick={handleCloseIconClick}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isSearch || isMobile && (
                                <Nav pills style={{ margin: '1%' }}>
                                    <NavItem style={{
                                        background: "rgba(129, 193, 75, 0.2)",
                                        borderRadius: "5px",
                                        // marginRight: "15px"
                                    }}>
                                        <NavLink
                                            onClick={toggleFilter}
                                            active={filterOpen === true}
                                        >
                                            {filterOpen ?
                                                <span style={{ color: "#FFFFFF" }} className='d-flex align-items-center'><Filter color=' #FFFFFF' size={16} />&nbsp;Filter </span> :
                                                <span style={{ color: "#808080" }} className='d-flex align-items-center'><Filter color='#808080' size={16} />&nbsp;Filter </span>
                                            }
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            )}
                        </Col>
                    </Row>
                    <Row className=" mt-3 ">
                        <Col>
                            <TabContent activeTab={currentActiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col lg="3">
                                            <EventsCategories />
                                        </Col>
                                        <Col lg="9">
                                            <UpCommingEvents />
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <MyEvents />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    {/* ===============True_state_Upcoming_section=============== */}

                    <Row className="EventsDetail_tab_Section  EventsDetail_tab_Sections mt-3 m-1">
                        <Col md="8" style={{ marginLeft: '-2px' }}>
                            <Nav className='p-1' pills style={{ margin: '1%' }}>
                                <NavItem
                                    style={{
                                        background: (!isSearch || window.innerWidth > 750) ? "rgba(129, 193, 75, 0.2)" : "white",
                                        color: "rgba(0, 0, 0, 0.5)",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        borderRadius: "5px",
                                        marginRight: "15px",
                                        display: 'block' // Show the item by default
                                    }}
                                >
                                    <NavLink
                                        className={classnames({
                                            active: currentActiveTab === '1',
                                            hide: 'nav-pills .nav-link.active' // Add hide class when isSearch is true
                                        })}
                                        onClick={() => { toggle('1') }}
                                        style={{
                                            display: (isSearch && window.innerWidth <= 750) ? 'none' : 'block' // Hide on mobile devices when isSearch is true
                                        }}
                                    >
                                        {(!isSearch || window.innerWidth > 750) && (<span>Upcoming</span>)}
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{
                                    background: (!isSearch || window.innerWidth > 750) ? "rgba(129, 193, 75, 0.2)" : "white",
                                    color: "rgba(0, 0, 0, 0.5)",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    borderRadius: "5px",
                                    marginRight: "12px"
                                }}>
                                    <NavLink
                                        className={classnames({
                                            active: currentActiveTab === '2',
                                            hide: 'nav-pills .nav-link.active' // Add hide class when isSearch is true
                                        })}
                                        onClick={() => { toggle('2') }}
                                        style={{
                                            display: (isSearch && window.innerWidth <= 750) ? 'none' : 'block'
                                        }}
                                    >
                                        {(!isSearch || window.innerWidth > 750) && (<span>My Events</span>)}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="4" className="d-flex align-items-center justify-content-end p-2 input-card-event-text">
                            <div className="group-search-assign-filter">
                                <div className="Events_srch_block">
                                    <div className="input-Events_search-blk vghfthftgh" >
                                        <Search
                                            onClick={() => {
                                                setIsSearch((prev) => !prev);
                                            }}
                                            className={`search-svg ${isSearch ? 'hide' : ''}`}
                                        />
                                        {(isSearch || window.innerWidth > 750) && (
                                            <Input
                                                type="text"
                                                className="form-control1 border-0 text-input input-search-text"
                                                name="search"
                                                placeholder="Find Events ..."
                                                data-bs-toggle="modal"
                                                data-bs-target="#peopleSearch"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                            />
                                        )}
                                        {window.innerWidth <= 750 && isSearch && (
                                            <X
                                                style={{ position: 'absolute', top: '10px', right: '4px', color: '#8e8e9b' }}
                                                size={18}
                                                onClick={handleCloseIconClick}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isSearch || isMobile && (
                                <Nav pills style={{ margin: '1%' }}>
                                    <NavItem style={{
                                        background: "rgba(129, 193, 75, 0.2)",
                                        borderRadius: "5px",
                                        // marginRight: "15px"
                                    }}>
                                        <NavLink
                                            onClick={toggleFilter}
                                            active={filterOpen === true}
                                        >
                                            {filterOpen ?
                                                <span style={{ color: "#FFFFFF" }} className='d-flex align-items-center'><Filter color=' #FFFFFF' size={16} />&nbsp;Filter </span> :
                                                <span style={{ color: "#808080" }} className='d-flex align-items-center'><Filter color='#808080' size={16} />&nbsp;Filter </span>
                                            }
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            )}
                        </Col>
                    </Row>
                    <Row className=" mt-3 ">
                        <Col>
                            <TabContent activeTab={currentActiveTab}>
                                <TabPane tabId="1">
                                    <UpcommingEventsList />
                                </TabPane>
                                <TabPane tabId="2">
                                    <MyEvents />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </>
            )}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/*=================== Start_Report_section_modal  ===================*/}
            <ReportModal
                isOpen={reportOpen}
                updateOpen={toggleReport}
            />
            {/*=================== End_Report_section_modal =================== */}
            {/*=================== Start_Filter_section_modal =================== */}
            <FilterModal
                isOpen={filterOpen}
                updateOpen={toggleFilter}
            />
            {/*=================== End_Filter_section_modal =================== */}

            {/*=================== Start_Forward_section_modal =================== */}
            <ForwardModal
                isOpen={forwardOpen}
                updateOpen={toggleForward}
                btnActive={cSelected}
                updateBtn={onCheckboxBtnClick}
            />
            {/*=================== Start_Forward_section_modal =================== */}

            {/*=================== Start_Interested_section_modal =================== */}
            <InterestedModal
                isOpen={interestedOpen}
                updateOpen={toggleInterested}

            />
            <InviteModal
                isOpen={InviteOpen}
                updateOpen={toggleInvite}

            />
            {/*=================== end_Interested_section_modal =================== */}
        </Fragment >
    )
}

export default EventTabMenu