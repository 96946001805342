import React, { useState, useRef } from 'react'
import { AlertTriangle, BellOff, Bookmark, ChevronDown, Circle, Copy, Edit, EyeOff, Filter, Globe, Image, MoreHorizontal, Send, Share2, Smile, TrendingUp, UserMinus, Users, X, XCircle } from 'react-feather'
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap'
import Colour from './Colour'
import './NewFeed.css'
// import Media from '../../NewComponents/IMG/MediaIcon.svg'
// import Reaction from '../../NewComponents/IMG/Reaction.svg'
// import BookmarkLatest from '../../NewComponents/IMG/BookmarkLatest.svg'
// import BookmarkActive from '../../NewComponents/IMG/BookmarkActive.svg'
import videoExample from '../../NewComponents/Videos/shotzVideos1.mp4'
import Track from '../profile-components/Img/Track.png'
// import LikeActive from '../../NewComponents/IMG/LikeActive.svg'
import CommentLatest from '../../NewComponents/IMG/CommentLatest.svg'
import CommentActive from '../../NewComponents/IMG/CommentActive.svg'
import ShareLatest from '../../NewComponents/IMG/ShareLatest.svg'
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import GoliveAlertIcon from '../../NewComponents/IMG/GoliveAlertIcon.gif'
import UserImg from '../../NewComponents/IMG/img.svg'
import DateTimePicker from 'react-datetime-picker'
import { useNavigate } from 'react-router-dom'
import ActiveCirclelike from '../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../NewComponents/IMG/Circlelike.svg'
import { Autocomplete, Box, Checkbox, Chip, Stack, TextField } from '@mui/material'
import { Profession, Locations, names, postData, emoteData, filterimg, ForwardArrayData } from './FeedArray'
import { AlertPost, PollPost, RecommendationPost, TextPost, ThroughtPost } from './PostCreations'
import { AlertPostDisplayMild, AlertPostDisplayModerate, AlertPostDisplaySevere, EpisodePodcast, EventPostDisplay, MediaPostDisplay, MediaPostVideoType, PollPostDisplayfilled, PollPostDisplayUnfilled, RecommendationPostDisplay, SingleEpisodePodcast, TextPostDisplay, ThroughtPostDisplay } from './PostDisplay'
import Slider from 'react-slick'
import Media from '../Universal-components/post-creations/sub-components/Media'
import StickerSection from '../Universal-components/post-creations/sub-components/StickerSection'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import ReactPlayer from 'react-player'
import Top from '../../NewComponents/IMG/Top.svg'
import Treanding from '../../NewComponents/IMG/Treanding.svg'


const Mobpost = ({ activeScreen, setactiveScreen }) => {

    /*  ---------------------- State and functions --------------------  */

    const Navigate = useNavigate()
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [BookmarkIcon, SetBookmark] = useState(true)
    const [comment, SetComment] = useState(false)
    const [quickFunctionON, setquickFunctionOFF] = useState(false)

    const toggleQuick = () => {
        // setactiveScreen(true)
        setquickFunctionOFF(true)
    }

    const [selectedMediaFiles, setSelectedMediaFiles] = useState([]);

    const fileSelectedHandler = (event) => {
        const seletedmedia = event.target.files;
        const FileArray = Array.from(seletedmedia);

        const MediaArray = FileArray.map((file) => {
            return URL.createObjectURL(file);
        });

        setSelectedMediaFiles((previousImages) => previousImages.concat(MediaArray))


        if (MediaPost !== true) {
            toggleMediaPost(true)
        }
    }

    const removeFile = (file) => {

        if (selectedMediaFiles.length === 1) {
            setposdiscard(true)

            setSelectedMediaFiles(selectedMediaFiles.filter(i => i !== file))
            setMediaPost(false)
        } else {
            setSelectedMediaFiles(selectedMediaFiles.filter(i => i !== file))
        }
    };

    const fileInput = useRef(null);

    const openFileDialog = () => {
        fileInput.current.click();
    };

    const [MediaPost, setMediaPost] = useState(false)
    const toggleMediaPost = () => {
        if (MediaPost !== true) {
            setMediaPost(!MediaPost)
            openFileDialog(true)
        }
    }

    const [hastag, setHastag] = useState(false)
    const togglehastag = () => {
        setHastag(!hastag)
    }

    const [checkIn, setCheckIn] = useState(false)
    const togglecheckIn = () => {
        setCheckIn(!checkIn)
    }

    const [PeopleTag, setPeopleTag] = useState(false)
    const togglePeopleTag = () => {
        setPeopleTag(!PeopleTag)
    }

    const [Scehedule, setScedule] = useState(false)
    const toggleScehedule = () => {
        setScedule(!Scehedule)
    }

    const [AddActivity, setAddActivity] = useState(false)
    const toggleActivity = () => {
        setAddActivity(!AddActivity)
    }

    const Commenttoggle = () => {
        SetComment(!comment)
    }
    const [commentR, SetCommentR] = useState('')
    const CommenttoggleR = () => {
        SetCommentR('I am seeking recommendation for buying ')
    }

    const [goLiveOpen, setgoLiveOff] = useState(false)
    const togglegoliveAlert = () => {
        setgoLiveOff(!goLiveOpen)
    }

    const [postmodalOpen, setpostmodalOff] = useState(true)
    const togglepostModal = () => {
        if (activeScreen === true) {
            setpostmodalOff(!postmodalOpen)
        } else {
            // setactiveScreen(!activeScreen)
            setpostmodalOff(!postmodalOpen)
        }
    }

    const toggleNoneFocous = () => {
        // setactiveScreen(false)
        setquickFunctionOFF(false)
        setpostmodalOff(false)
        setposdiscard(false)
        setpostType('0')
    }

    const [postType, setpostType] = useState('0')
    const togglepostSwitch = (tab) => {
        if (postType !== tab) {
            setpostType(tab)
        }
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    const [ActionOpen, setActionClose] = useState(false)
    const toggleAction = () => {
        setActionClose(!ActionOpen)
    }

    const [ImageOpen, setImageClose] = useState(false)
    const toggleImage = () => {
        setImageClose(!ImageOpen)
    }

    const [posdiscard, setposdiscard] = useState(false)
    const togglesetposdiscard = () => {
        setposdiscard((preState) => !preState)
    }

    const [selectedColor, setSelectedColor] = useState("#F6F6F6")

    const Colorhandel = (value) => {
        setSelectedColor(value)
    }
    const [selectedColorText, setSelectedColorText] = useState("#000000")

    const ColorhandelText = (value) => {
        setSelectedColorText(value)
    }

    const [imgfile, uploadimg] = useState('https://deconova.eu/wp-content/uploads/2016/02/default-placeholder.png')

    const imgFilehandeler = (e) => {
        if (e.target.files.length !== 0) {
            uploadimg(URL.createObjectURL(e.target.files[0]))
        }
    }

    /*-------------- Emote mix avatar -----------------*/

    const EmoteAvatar = () => {

        return (
            <div className='px-2'>
                <ul className="matual-friend-blk">
                    {
                        emoteData.slice(0, 4).map((item, index) => {
                            return (

                                <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="sufiya eliza" data-img={item.img}>
                                    <img src={item.img} className="bg-img" alt="" />
                                </li>

                            )
                        })
                    }
                </ul>
            </div >
        )
    }

    /*-------------- Modal for ImageandVideo Add -----------------*/

    const MediaAdd = () => {

        var storySettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        };
        var ImageSettings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        const [preview, setpreview] = useState(true)


        return (
            <>

                {MediaPost ? (
                    <Modal isOpen={MediaPost} className='modal-dialog-centered modal-xl' toggle={() => setMediaPost(!MediaPost)} >
                        <ModalHeader toggle={() => setMediaPost(!MediaPost)}> Add Media  </ModalHeader>
                        <ModalBody>
                            {preview ?
                                <div>
                                    <Slider {...ImageSettings} className="default-space">
                                        {selectedMediaFiles.map((file, i) => (
                                            <div>
                                                <div className='position-relative imgResposiveForUpload Video_Treanding_card'>
                                                    <XCircle key={i} className='PostiotionForCrossIcon' color='#808080' onClick={() => removeFile(file)} />
                                                    <img src={file} alt="img" />
                                                    <div onClick={() => document.querySelector('.inputForMedia').click()} className='positionforAddonText'>
                                                        <Image color='#808080' size={15} />  Add Photo/ Videos
                                                        <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name='images' onChange={fileSelectedHandler} type="file" hidden />
                                                    </div>
                                                    <div>
                                                        <div className='text-right m-1'>
                                                            <Input type='text' Placeholder='Type your caption' className='form-control w-100' />
                                                            <small>Max 60 Characters</small>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </Slider>
                                    <div className='pt-4'>
                                        <Slider {...storySettings} className="default-space">
                                            {
                                                filterimg.map((data) => (
                                                    <div>
                                                        <Card className="">
                                                            <img height={100} width='100%' src={data.data} alt="overview" />
                                                        </Card>

                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>
                                </div> :
                                <div className='scrollForMedia'>
                                    <Media image={selectedMediaFiles} />
                                </div>}
                        </ModalBody>
                        <div className='m-2'>
                            <Button onClick={() => setpreview(false)} className='w-100' color='primary'>{preview ? 'preview' : 'Post'}  </Button>
                        </div>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for video View -----------------*/

    const VideoModal = () => {

        return (
            <>
                {ImageOpen ? (
                    <Modal isOpen={ImageOpen} className='modal-dialog-centered' toggle={toggleImage} >
                        <ModalHeader toggle={toggleImage} className='d-flex justify-content-center'>
                            <h4>Videos</h4>
                        </ModalHeader>
                        <div>
                            <ReactPlayer
                                url={videoExample}
                                controls={true}
                                className='w-100'
                            />
                        </div>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for GoLive Alert -----------------*/

    const GoLiveAlert = () => {

        return (
            <>
                {goLiveOpen ? (
                    <Modal isOpen={goLiveOpen} className='modal-dialog-centered' toggle={togglegoliveAlert} >
                        <ModalHeader toggle={togglegoliveAlert} className='d-flex justify-content-center'>
                            <h4>Share live videos</h4>
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <img className='w-50' src={GoliveAlertIcon} alt='golivealert' />
                            <h3>Go live and show your friends what you’re up to!</h3>
                        </ModalBody>
                        <div className='text-center m-3'>
                            <Button color='primary' onClick={() => Navigate('/CreateGoLive')}> OK </Button>
                        </div>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Hastag -----------------*/

    const Hastag = () => {

        const [val, setVal] = useState([]);

        const valHtml = val.map((option, index) => {
            // This is to handle new options added by the user (allowed by freeSolo prop).
            const label = option.title || option;
            return (
                <Chip
                    sx={{ margin: '1%' }}
                    key={label}
                    label={label}
                    deleteIcon={<XCircle size={18} />}
                    onDelete={() => {
                        setVal(val.filter(entry => entry !== option));
                    }}
                />
            );
        });
        return (
            <>
                {hastag ? (
                    <Modal isOpen={hastag} className='modal-dialog-centered' toggle={togglehastag} >
                        <ModalHeader toggle={togglehastag} className='d-flex justify-content-center'>
                            <h4>Trending # tags</h4>
                        </ModalHeader>
                        <ModalBody>
                            <Stack sx={{ width: '100%' }}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    freeSolo
                                    filterSelectedOptions
                                    options={Profession.map((option) => option)}
                                    onChange={(e, newValue) => setVal(newValue)}
                                    getOptionLabel={option => option}
                                    renderTags={() => { }}
                                    value={val}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            className='border rounded'
                                            // variant="outlined"
                                            placeholder="#tags"
                                        // margin="normal"
                                        // fullWidth
                                        />
                                    )}
                                />
                                {valHtml.length > 0 ?
                                    <div className="selectedTags border">{valHtml}</div> : null
                                }
                            </Stack>

                            <div className='scrollForHastag'>
                                <ul className='m-2'>
                                    <li className='my-3'>
                                        <div>
                                            <h4>#NewDay</h4>
                                            <h5 className='text-muted'>used in 254 post</h5>
                                        </div>
                                    </li>
                                    <li className='my-3'>
                                        <div>
                                            <h4>#NewMe</h4>
                                            <h5 className='text-muted'>used in 254 post</h5>
                                        </div>
                                    </li>
                                    <li className='my-3'>
                                        <div>
                                            <h4>#NewMe</h4>
                                            <h5 className='text-muted'>used in 254 post</h5>
                                        </div>
                                    </li>
                                    <li className='my-3'>
                                        <div>
                                            <h4>#NewMe</h4>
                                            <h5 className='text-muted'>used in 254 post</h5>
                                        </div>
                                    </li>
                                    <li className='my-3'>
                                        <div>
                                            <h4>#NewMe</h4>
                                            <h5 className='text-muted'>used in 254 post</h5>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for CheckIn -----------------*/

    const CheckIn = () => {
        return (
            <>
                {checkIn ? (
                    <Modal isOpen={checkIn} className='modal-dialog-centered' toggle={togglecheckIn} >
                        <div className='Profession_Modal_section AboutModal_Main_section p-4 py-3'>
                            <h4>Location</h4>
                            <h4 className='text-primary'><span><img width={18} src={Track} /></span> Detect Location</h4>
                        </div>
                        <ModalBody>
                            <Stack sx={{ width: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id="tags-standard"
                                    options={Locations}
                                    margin="normal"
                                    renderInput={(params) => <TextField variant="outlined" className='border rounded' {...params} placeholder="Search location..." />}
                                />
                            </Stack>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Postdiscard -----------------*/

    const PostdiscardAlert = () => {
        return (
            <>
                {posdiscard ? (
                    <Modal isOpen={posdiscard} className='modal-dialog-centered' toggle={togglesetposdiscard} >
                        <ModalHeader toggle={togglesetposdiscard}>
                            Post Discard
                        </ModalHeader>
                        <ModalBody className='text-center'>
                            <div className='p-2 py-4'>
                                <h3>Are you sure you want to discard this post ?</h3>
                            </div>
                            <div className='m-1 my-2 text-center'>
                                <Button outline color='primary' > Save to Draft </Button>
                                <Button className='text-white mx-2' color='primary' onClick={toggleNoneFocous} >Discard</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Activity -----------------*/

    const Activity = () => {
        return (
            <>
                {AddActivity ? (
                    <Modal isOpen={AddActivity} className='modal-dialog-centered' toggle={toggleActivity} >
                        <ModalHeader toggle={toggleActivity} > Add Your Feeling/Activity </ModalHeader>
                        <ModalBody>
                            <StickerSection />
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Post Actions (Tag, CheckIn, Scheduled)  After development please remove this -----------------*/

    const ActionPost = () => {
        return (
            <>
                {ActionOpen ? (
                    <Modal isOpen={ActionOpen} className='modal-dialog-centered modal-lg' toggle={toggleAction} >
                        <ModalHeader toggle={toggleAction} >Post Action </ModalHeader>
                        <ModalBody>
                            <CardHeader className='border-0 p-0 bg-white rounded'>
                                <div className='d-flex align-items-center rounded'>
                                    <h3 className='styleForTabDesigne text-nowrap' style={{ color: '#808080' }}><strong>Create Post</strong></h3>
                                    <div className='border-bottom d-flex justify-content-between align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <h4 onClick={togglegoliveAlert} className='userSelectiontab p-2 text-nowrap' style={{ color: '#808080' }}><img width={20} src={LiveGif} alt="LiveGif1" /> <strong>Go Live</strong></h4>
                                            <UncontrolledButtonDropdown className='d-flex align-items-center userSelectiontab'>
                                                <DropdownToggle color='flat-primary' className='d-flex align-items-center'>
                                                    <h4 className='header-text-style d-flex align-items-center'><Globe color='#979797' size={16} /><strong>&nbsp;Public</strong></h4><ChevronDown color='#979797' size={18} />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem className='header-text-style'><Globe color='#979797' size={16} /> Public</DropdownItem>
                                                    <DropdownItem className='header-text-style'><Users color='#979797' size={16} /> Friends</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>

                                    </div>
                                </div>

                            </CardHeader>
                            <TextPost />
                            <CardFooter className='bg-white'>
                                <div>
                                    <Row>
                                        <Col>
                                            <div className='d-flex justify-content-between styleForTextFooter align-items-center m-1'>
                                                <div className='d-flex align-items-center'>
                                                    <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="16" viewBox="0 0 16 16" ><path d="M15.8754 8.77081C15.6635 8.1214 14.956 7.77619 14.3032 7.9881L12.7514 8.50421L11.7158 5.42804L13.2675 4.91193C13.9204 4.70001 14.2622 3.99591 14.0537 3.34308C13.8417 2.69367 13.1342 2.34845 12.4814 2.56036L10.9296 3.07648L10.393 1.47003C10.1811 0.820618 9.47358 0.475403 8.82075 0.687317C8.16792 0.899231 7.82612 1.60333 8.03461 2.25617L8.57124 3.86261L5.37885 4.92902L4.84223 3.32257C4.63032 2.67316 3.9228 2.32794 3.26997 2.53986C2.61714 2.75177 2.27534 3.45587 2.48383 4.1087L3.02046 5.71515L1.47212 6.22785C0.819284 6.43976 0.477487 7.14386 0.685983 7.79669C0.856881 8.2923 1.33881 8.61701 1.83442 8.63751C2.06684 8.67169 2.24458 8.58283 3.80659 8.06329L4.84223 11.1395L3.29047 11.6556C2.64106 11.8675 2.29585 12.575 2.50434 13.2244C2.67524 13.72 3.15717 14.0447 3.65278 14.0652C3.8852 14.0994 4.06294 14.0106 5.62495 13.491L6.16157 15.0975C6.36323 15.6751 7.00581 16.0887 7.73383 15.8802C8.38667 15.6683 8.72846 14.9642 8.51997 14.3113L7.98335 12.7049L11.1826 11.6351L11.7192 13.2415C11.9208 13.8192 12.5634 14.2327 13.2915 14.0242C13.9443 13.8123 14.2861 13.1082 14.0776 12.4554L13.541 10.8489L15.0927 10.3328C15.7421 10.1277 16.0873 9.42023 15.8754 8.77081ZM7.19038 10.3841L6.15473 7.30109L9.35053 6.23126L10.3862 9.31427L7.19038 10.3841Z" /></svg>
                                                    <div className="tooltip-cls">
                                                        <span>Hashtag</span>
                                                    </div>
                                                </div>
                                                <div className="tooltip-cls pr-1 text-primary">
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='d-flex styleForTextFooter align-items-center m-1'>
                                                <svg fill='#A6A6A6' onClixmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="21" viewBox="0 0 13 21"><path d="M6.5 0C2.90643 0 0 3.28666 0 7.35036C0 11.7291 4.10429 17.7669 5.79429 20.0665C6.16571 20.5705 6.84357 20.5705 7.215 20.0665C8.89571 17.7669 13 11.7291 13 7.35036C13 3.28666 10.0936 0 6.5 0ZM6.5 9.97548C5.88432 9.97548 5.29385 9.69891 4.8585 9.2066C4.42315 8.71429 4.17857 8.04658 4.17857 7.35036C4.17857 6.65413 4.42315 5.98642 4.8585 5.49411C5.29385 5.0018 5.88432 4.72523 6.5 4.72523C7.11568 4.72523 7.70615 5.0018 8.1415 5.49411C8.57685 5.98642 8.82143 6.65413 8.82143 7.35036C8.82143 8.04658 8.57685 8.71429 8.1415 9.2066C7.70615 9.69891 7.11568 9.97548 6.5 9.97548Z" /></svg>
                                                <div className="tooltip-cls">
                                                    <span className='text-primary' >Lucknow, India</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='d-flex justify-content-between styleForTextFooter align-items-center m-1'>
                                                <div className="d-flex align-items-center">
                                                    <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="21" viewBox="0 0 25 21" ><path d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z" /></svg>
                                                    <div className="tooltip-cls">
                                                        <span>People Tag</span>
                                                    </div>
                                                </div>
                                                <div className="tooltip-cls pr-1 text-primary">
                                                    <span>2</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='d-flex justify-content-between styleForTextFooter align-items-center m-1'>
                                                <div className="d-flex align-items-center">
                                                    <svg fill='#A6A6A6' onClixmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="25" viewBox="0 0 24 24" ><path d="M20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442Z" /><path d="M12 8V12L15 15M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#A6A6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 8V12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" /></svg>
                                                    <div className="tooltip-cls">
                                                        <span className='text-primary'>03 March 2023, 10:23 AM</span>
                                                    </div>
                                                </div>
                                                <div className="tooltip-cls pr-1 text-primary">
                                                    <span>+2</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='d-flex align-items-center justify-content-between styleForTextFooter m-1'>
                                                <div className='d-flex'>
                                                    <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" ><path d="M12 20.9999C16.97 20.9999 21 16.9702 21 12C21 7.02974 16.97 3 12 3C7.03 3 3 7.02974 3 12C3 13.7707 3.512 15.4222 4.395 16.815L3 20.9999L7.745 19.9323C9.07479 20.6408 10.5286 21.0056 12 20.9999Z" /><line x1="7" y1="9" x2="17" y2="9" stroke="white" strokeLinecap="round" /><line x1="7" y1="12" x2="17" y2="12" stroke="white" strokeLinecap="round" /><line x1="7" y1="15" x2="17" y2="15" stroke="white" strokeLinecap="round" /></svg>
                                                    <div className="tooltip-cls">
                                                        <span>&nbsp;&nbsp;Allow Comment</span>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    Off
                                                    <label className="switch mx-1 mb-0">
                                                        <input type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    On
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Button color='primary' className='w-100 my-2'>Post</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </CardFooter>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for tag people -----------------*/

    const PeopleTagModal = () => {
        const [peopleval, setpeopleVal] = useState([]);

        const TagPeopleChip = peopleval.map((option, index) => {
            // This is to handle new options added by the user (allowed by freeSolo prop).
            const label = option.title || option;
            return (
                <Chip
                    sx={{ margin: '1%' }}
                    key={label}
                    label={label}
                    deleteIcon={<XCircle size={18} />}
                    onDelete={() => {
                        setpeopleVal(peopleval.filter(entry => entry !== option));
                    }}
                />
            );
        });
        return (
            <>
                {PeopleTag ? (
                    <Modal isOpen={PeopleTag} className='modal-dialog-centered' toggle={togglePeopleTag} >
                        <ModalHeader toggle={togglePeopleTag} className='d-flex justify-content-center'>
                            <h4>Tagged People</h4>
                        </ModalHeader>
                        <ModalBody>
                            <Stack sx={{ width: '100%' }}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    freeSolo
                                    filterSelectedOptions
                                    options={names.slice(0, 2).map((option) => option)}
                                    onChange={(e, newValue) => setpeopleVal(newValue)}
                                    getOptionLabel={option => option.name}
                                    renderTags={() => { }}
                                    value={peopleval}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                            <div className='d-flex align-items-center m-1 p-1'>
                                                <img
                                                    loading="lazy"
                                                    width={40}
                                                    height={40}
                                                    className='border rounded-circle'
                                                    src='/assets/images/pages-logo3.jpg'
                                                    // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                <div className='ml-2'>
                                                    <h3>{option.name}</h3>
                                                    <p className='text-muted'>{option.category}</p>
                                                </div>
                                            </div>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className='border rounded'
                                            // variant="outlined"
                                            placeholder="Search ..."
                                        // margin="normal"
                                        // fullWidth
                                        />
                                    )}
                                />
                                {TagPeopleChip.length > 0 ?
                                    <div className="selectedTags border">{TagPeopleChip}</div> : null
                                }
                            </Stack>
                            <div className='my-2 scrollForHastag'>
                                <h3>Suggestion</h3>
                                <div className='my-1'>
                                    <div className='d-flex align-items-center' >
                                        <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                                        <div className='my-1 ml-1'>
                                            <h4>Taha jamal</h4>
                                            <p className='text-muted'>SocioMate</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center' >
                                        <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                                        <div className='my-1 ml-1'>
                                            <h4>Taha jamal</h4>
                                            <p className='text-muted'>SocioMate</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center' >
                                        <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                                        <div className='my-1 ml-1'>
                                            <h4>Taha jamal</h4>
                                            <p className='text-muted'>SocioMate</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center' >
                                        <img src='/assets/images/pages-logo3.jpg' width={40} height={40} className=" bg-img rounded-circle" alt="profileImage" />
                                        <div className='my-1 ml-1'>
                                            <h4>Taha jamal</h4>
                                            <p className='text-muted'>SocioMate</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*-------------- Modal for Schedule post -----------------*/

    const Schedule = () => {
        const [listItems, setListItems] = useState([]);

        const handleAddClick = () => {
            setListItems([...listItems, 'New Item']);
        };


        return (
            <>
                {
                    Scehedule !== false &&
                    <Modal isOpen={Scehedule} className='modal-dialog-centered' toggle={toggleScehedule} >
                        <ModalHeader toggle={toggleScehedule} className='d-flex justify-content-center'>
                            <h4>Schedule your post </h4>
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                {/* {Schedule === true ? document.querySelector('#picker').click() : ""} */}
                                <Col className='my-2'>
                                    <Input
                                        type="datetime-local"
                                        floatLabelType="Auto"
                                    />
                                    <div>
                                        <ul>
                                            {listItems.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <li key={index}> <Input
                                                            type="datetime-local"
                                                            className='my-2'
                                                        /></li>
                                                    )
                                            })}
                                        </ul>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='my-2 d-flex align-items-center'>
                                                <Checkbox /> <h5>Post Now</h5>
                                            </div>
                                            <b className='text-primary' onClick={handleAddClick}>+ Add more</b>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>

                    </Modal>
                }
            </>
        )
    }

    /*-------------- Modal for Share -----------------*/

    const ShareModal = () => {
        const Sharedto = [
            {
                name: "Your Wall Feed"
            },
            {
                name: "Your Story"
            }
        ]

        return (
            <div>
                {
                    forwardOpen !== false &&
                    <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>

                        <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
                            <h2>Share to  </h2>
                            <Button onClick={toggleForward} color='primary' >Share</Button>
                        </div>
                        <ModalBody>
                            <div className='pb-2'>
                                <Accordion defaultActiveKey="0">
                                    <AccordionItem eventKey='1'>
                                        <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                                        <AccordionBody className='p-0'>
                                            <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey='1'>
                                                    <AccordionHeader>Feed</AccordionHeader>
                                                    <AccordionBody>
                                                        {
                                                            Sharedto.map((data) => (
                                                                <div className='d-flex justify-content-between align-items-center my-2 Report_Section border-bottom'>
                                                                    <h4>{data.name}</h4>
                                                                    <div>
                                                                        <Checkbox />
                                                                        {' '}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='2'>
                                                    <AccordionHeader>Your Business Pages</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page name</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='3'>
                                                    <AccordionHeader>Your Group</AccordionHeader>
                                                    <AccordionBody>

                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Group name</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>

                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionBody>
                                    </AccordionItem>

                                </Accordion>
                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Connections</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            {/* <div className=''> */}

                                            <Checkbox />


                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }

    const allpostType = [
        {
            id: <TextPostDisplay />
        },
        {
            id: <MediaPostDisplay />
        },
        {
            id: <AlertPostDisplayMild />
        },
        {
            id: <RecommendationPostDisplay action={CommenttoggleR} />
        },
        {
            id: <ThroughtPostDisplay />
        },
        {
            id: <AlertPostDisplayModerate />
        },
        {
            id: <MediaPostVideoType view={toggleImage} />
        },
        {
            id: <EventPostDisplay />
        },
        {
            id: <SingleEpisodePodcast />
        },
        {
            id: <PollPostDisplayUnfilled />
        },
        {
            id: <EpisodePodcast />
        },
        {
            id: <AlertPostDisplaySevere />
        },
        {
            id: <PollPostDisplayfilled />
        }
    ]

    return (
        <div>
            <ShareModal />
            <div>
                <GoLiveAlert />
                <PostdiscardAlert />

                <Card className='shadow-sm border'>
                    <CardHeader className='border-0 p-0 bg-white rounded'>
                        {/* <div className='d-flex align-items-center rounded'>
                            <h3 className='styleForTabDesigne text-nowrap' style={{ color: '#808080' }}><strong>Create Post</strong></h3>
                            <div className=' d-flex justify-content-between align-items-center border-bottom w-100'>
                                <div className='d-flex align-items-center'>
                                    <h4 onClick={togglegoliveAlert} className='userSelectiontab p-2 text-nowrap' style={{ color: '#808080' }}><img width={20} src={LiveGif} alt="LiveGif1" /> <strong>Go Live</strong></h4>
                                    <UncontrolledButtonDropdown className='d-flex align-items-center userSelectiontab'>
                                        <DropdownToggle color='flat-primary' className='d-flex align-items-center'>
                                            <h4 className='header-text-style d-flex align-items-center'><Globe color='#979797' size={16} /><strong>&nbsp;Public</strong></h4><ChevronDown color='#979797' size={18} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem className='header-text-style'><Globe color='#979797' size={16} /> Public</DropdownItem>
                                            <DropdownItem className='header-text-style'><Users color='#979797' size={16} /> Friends</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>

                            </div>
                            <div>
                                {
                                    postmodalOpen || quickFunctionON ? <div className={` text-right ${postmodalOpen || quickFunctionON ? 'paddingForPostdiscardcrossdisaapered' : 'paddingForPostdiscardAppered'}`}>
                                        {postmodalOpen || quickFunctionON ? <XCircle size={20} onClick={togglesetposdiscard} className='p-0' strokeWidth={2} color='#FFFFFF' /> : null}
                                    </div> : null
                                }
                            </div>
                        </div> */}
                        <Row className='rowForrevert'>
                            <Col xs='4'>
                                <h4 className='styleForTabDesigneMob' style={{ color: '#808080' }}><strong>Create Post</strong></h4>
                            </Col>
                            <Col xs='8' className='p-0 border-bottom'>
                                <div className='d-flex align-items-center'>
                                    <h4 onClick={togglegoliveAlert} className='userSelectiontab pl-0 text-nowrap' style={{ color: '#808080' }}><img width={20} src={LiveGif} alt="LiveGif1" /> <strong>Go Live</strong></h4>
                                    <UncontrolledButtonDropdown className='d-flex align-items-center userSelectiontab'>
                                        <DropdownToggle color='flat-primary' className='d-flex align-items-center'>
                                            <h4 className='header-text-style d-flex align-items-center'><Globe color='#979797' size={16} /><strong>&nbsp;Public</strong></h4><ChevronDown color='#979797' size={18} />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem className='header-text-style'><Globe color='#979797' size={16} /> Public</DropdownItem>
                                            <DropdownItem className='header-text-style'><Users color='#979797' size={16} /> Friends</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </Col>

                            {
                                postmodalOpen || quickFunctionON ? <div className={` text-right ${postmodalOpen || quickFunctionON ? 'paddingForPostdiscardcrossdisaapered' : 'paddingForPostdiscardAppered'}`}>
                                    {postmodalOpen || quickFunctionON ? <XCircle size={20} onClick={togglesetposdiscard} className='' strokeWidth={2} color='#FFFFFF' /> : null}
                                </div> : null
                            }
                        </Row>
                    </CardHeader>
                    {postType === '1' ? <TextPost color={selectedColorText} quick={toggleQuick} /> :
                        postType === '2' ? <ThroughtPost backcolor={selectedColor} color={selectedColorText} quick={toggleQuick} /> :
                            postType === '4' ? <CardBody>
                                <Form className='my-2'>
                                    <FormGroup>
                                        <Label for='eventcategory' tag='h4' className='my-1' >Event Catagory</Label>
                                        <Input id='eventcategory' type='select'>
                                            <option>Social</option>
                                            <option>Social</option>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label tag='h4' className='my-1' for='helperText'>Event Title*</Label>
                                        <Input type='text' id='helperText' placeholder='Enter your event title....' />
                                        <FormText className='text-muted text-right'>Max 64 Characters</FormText>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <Label tag='h4' className='my-1'  >Upload Event Cover Photo</Label>
                                            <Input type='file' className='customeForuploader' onChange={imgFilehandeler} />
                                        </div>
                                        <img
                                            src={imgfile}
                                            className="event-img-prev w-100"
                                            alt="event cover"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label tag='h4' className='my-1' >Description</Label>
                                        <Input type='textarea'
                                            cols="15"
                                            rows="5"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <Label tag='h4' className='mb-2' >Event Start Date & Time</Label>
                                                <DateTimePicker
                                                    className="form-control"
                                                    placeholder="dd-mm-yyyy"
                                                />
                                            </div>
                                            <div>
                                                <Label tag='h4' className='mb-2' >Event End Date & Time</Label>
                                                <DateTimePicker
                                                    className="form-control"
                                                    placeholder="dd-mm-yyyy"
                                                />
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for='eventcategory' tag='h4' className='my-1' >Address or Link to Event</Label>
                                        <Input onClick={toggleQuick} Placeholder='Type Address or Link to Event' type='text' />
                                    </FormGroup>
                                </Form>
                            </CardBody> :
                                postType === '7' ? <RecommendationPost quick={toggleQuick} /> :
                                    postType === '8' ? <AlertPost quick={toggleQuick} /> :
                                        postType === '9' ? <PollPost quick={toggleQuick} />
                                            : <TextPost color={selectedColorText} quick={toggleQuick} />
                    }
                    <div className='p-1'>
                        {postType === '2' ? <Colour show={postType} colorManager={Colorhandel} colorManagerText={ColorhandelText} /> : postType === '0' ?
                            <Colour show={postType} colorManagerText={ColorhandelText} /> : postType === '1' ?
                                <Colour show={postType} colorManagerText={ColorhandelText} /> : <Colour show={postType} colorManagerText={ColorhandelText} />
                        }
                    </div>
                    <CardFooter className='bg-white'>
                        <MediaAdd />
                        <Activity />
                        <div className='d-flex gap-2'>
                            <div style={{ cursor: 'pointer' }} onClick={openFileDialog} className='styleForTextFooter'>
                                <Image size={22} strokeWidth={2} color='#A6A6A6' />
                                <h5 className='px-1'>Media</h5>
                                <input
                                    type="file"
                                    multiple
                                    style={{ display: "none" }}
                                    ref={fileInput}
                                    onChange={fileSelectedHandler}
                                />
                            </div>
                            <div onClick={toggleActivity} style={{ cursor: 'pointer' }} className='styleForTextFooter'>
                                <Smile size={22} strokeWidth={2} color='#A6A6A6' />
                                <h5 className='px-1'>Activity</h5>
                            </div>
                            <div className='styleForTextFooter px-3'>
                                <Edit id='CreatePostTooltip' style={{ cursor: 'pointer' }} onClick={togglepostModal} strokeWidth={2} color='#A6A6A6' />
                                <UncontrolledTooltip placement='top' target='CreatePostTooltip'>
                                    Create Post
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </CardFooter>
                    {quickFunctionON ?
                        <CardFooter className='bg-white'>
                            <div>
                                <Hastag />
                                <CheckIn />
                                <PeopleTagModal />
                                <Schedule />
                                <ActionPost />
                                <Row>
                                    <Col xl='12'>
                                        <div style={{ cursor: 'pointer' }} onClick={togglehastag} className='d-flex styleForTextFooter userSelectiontab align-items-center m-1'>
                                            <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="16" viewBox="0 0 16 16" ><path d="M15.8754 8.77081C15.6635 8.1214 14.956 7.77619 14.3032 7.9881L12.7514 8.50421L11.7158 5.42804L13.2675 4.91193C13.9204 4.70001 14.2622 3.99591 14.0537 3.34308C13.8417 2.69367 13.1342 2.34845 12.4814 2.56036L10.9296 3.07648L10.393 1.47003C10.1811 0.820618 9.47358 0.475403 8.82075 0.687317C8.16792 0.899231 7.82612 1.60333 8.03461 2.25617L8.57124 3.86261L5.37885 4.92902L4.84223 3.32257C4.63032 2.67316 3.9228 2.32794 3.26997 2.53986C2.61714 2.75177 2.27534 3.45587 2.48383 4.1087L3.02046 5.71515L1.47212 6.22785C0.819284 6.43976 0.477487 7.14386 0.685983 7.79669C0.856881 8.2923 1.33881 8.61701 1.83442 8.63751C2.06684 8.67169 2.24458 8.58283 3.80659 8.06329L4.84223 11.1395L3.29047 11.6556C2.64106 11.8675 2.29585 12.575 2.50434 13.2244C2.67524 13.72 3.15717 14.0447 3.65278 14.0652C3.8852 14.0994 4.06294 14.0106 5.62495 13.491L6.16157 15.0975C6.36323 15.6751 7.00581 16.0887 7.73383 15.8802C8.38667 15.6683 8.72846 14.9642 8.51997 14.3113L7.98335 12.7049L11.1826 11.6351L11.7192 13.2415C11.9208 13.8192 12.5634 14.2327 13.2915 14.0242C13.9443 13.8123 14.2861 13.1082 14.0776 12.4554L13.541 10.8489L15.0927 10.3328C15.7421 10.1277 16.0873 9.42023 15.8754 8.77081ZM7.19038 10.3841L6.15473 7.30109L9.35053 6.23126L10.3862 9.31427L7.19038 10.3841Z" /></svg>
                                            <div className="tooltip-cls">
                                                <span>Hashtag</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ cursor: 'pointer' }} onClick={togglecheckIn} className='d-flex styleForTextFooter userSelectiontab align-items-center m-1'>
                                            <svg fill='#A6A6A6' onClixmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="21" viewBox="0 0 13 21"><path d="M6.5 0C2.90643 0 0 3.28666 0 7.35036C0 11.7291 4.10429 17.7669 5.79429 20.0665C6.16571 20.5705 6.84357 20.5705 7.215 20.0665C8.89571 17.7669 13 11.7291 13 7.35036C13 3.28666 10.0936 0 6.5 0ZM6.5 9.97548C5.88432 9.97548 5.29385 9.69891 4.8585 9.2066C4.42315 8.71429 4.17857 8.04658 4.17857 7.35036C4.17857 6.65413 4.42315 5.98642 4.8585 5.49411C5.29385 5.0018 5.88432 4.72523 6.5 4.72523C7.11568 4.72523 7.70615 5.0018 8.1415 5.49411C8.57685 5.98642 8.82143 6.65413 8.82143 7.35036C8.82143 8.04658 8.57685 8.71429 8.1415 9.2066C7.70615 9.69891 7.11568 9.97548 6.5 9.97548Z" /></svg>
                                            <div className="tooltip-cls">
                                                <span>Check In</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ cursor: 'pointer' }} onClick={togglePeopleTag} className='d-flex styleForTextFooter userSelectiontab align-items-center m-1'>
                                            <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="21" viewBox="0 0 25 21" ><path d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z" /></svg>
                                            <div className="tooltip-cls">
                                                <span>People Tag</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ cursor: 'pointer' }} onClick={toggleScehedule} className='d-flex styleForTextFooter userSelectiontab align-items-center m-1'>
                                            <svg fill='#A6A6A6' onClixmlns="http://www.w3.org/2000/svg" className="addpost-option-btn pr-1" width="25" height="25" viewBox="0 0 24 24" ><path d="M20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442Z" /><path d="M12 8V12L15 15M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#A6A6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 8V12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" /></svg>
                                            <div className="tooltip-cls">
                                                <span>Schedule Your Post</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='d-flex align-items-center justify-content-between styleForTextFooter m-1'>
                                            <div className='d-flex'>
                                                <svg fill='#A6A6A6' xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" ><path d="M12 20.9999C16.97 20.9999 21 16.9702 21 12C21 7.02974 16.97 3 12 3C7.03 3 3 7.02974 3 12C3 13.7707 3.512 15.4222 4.395 16.815L3 20.9999L7.745 19.9323C9.07479 20.6408 10.5286 21.0056 12 20.9999Z" /><line x1="7" y1="9" x2="17" y2="9" stroke="white" strokeLinecap="round" /><line x1="7" y1="12" x2="17" y2="12" stroke="white" strokeLinecap="round" /><line x1="7" y1="15" x2="17" y2="15" stroke="white" strokeLinecap="round" /></svg>
                                                <div className="tooltip-cls">
                                                    <span>&nbsp;&nbsp;Comment</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                Off
                                                <label className="switch mx-1 mb-0">
                                                    <input type="checkbox" />
                                                    <span className="slider round"></span>
                                                </label>
                                                On
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button color='primary' outline className='w-100 my-2'>Post</Button>
                                    </Col>
                                </Row>
                            </div>
                        </CardFooter> : null
                    }
                    {postmodalOpen ? (
                        <div>

                            <>
                                <div className='m-1 pb-2 mx-3'>
                                    <Row className='rowForrevert'>
                                        {postData.map((item, index) => {
                                            return (
                                                <Col className='userSelectiontab' key={index} xl='6'>
                                                    <div active={postType === item.id} onClick={item.tab === true ? () => { togglepostSwitch(item.id) } : item.id === '3' ? () => Navigate('/CreateGoLive') : item.id === '5' ? () => Navigate('/Podcast') : item.id === '6' ? () => Navigate('/ArticleEditor') : item.id === '10' ? () => Navigate('/Marketplace') : null} className='onhovForPostselection styleForTextFooter user-select-none m-1 py-2'>
                                                        <img className='px-2' src={item.img} /> <h4>{item.postType}</h4>
                                                    </div>
                                                </Col>)
                                        })
                                        }
                                    </Row>
                                </div>
                            </>

                        </div>
                    ) : (null)}

                </Card>
            </div >
        </div >
    )
}

export default Mobpost