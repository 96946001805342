import React, { useState } from 'react'
import { Check, X } from 'react-feather'
import { Button, Modal, ModalBody, Row } from 'reactstrap'
import e from '../../../NewComponents/IMG/e.png'
import { useNavigate } from 'react-router-dom'

// =========================failed======================
const PaymentSuccess = (props) => {
    const navigate = useNavigate()
    return (
        <>
            {props.PaymentSuccessModal ? (
                <Modal isOpen={props.PaymentSuccessModal} className='modal-dialog-centered' toggle={props.togglePaymentConfirmation} >
                    <ModalBody>
                        <div>
                            <div className='text-center border-bottom'>
                                <div style={{ position: 'relative' }}>
                                    <img src={e} width={80} height={80} alt='' />
                                    <div style={{ position: 'absolute', left: '45%', top: '20%', color: '#81c14b' }}>
                                        <Check size={50} />
                                    </div>
                                </div>
                                <h4 className='text-black mt-2'>Thanks for your payment</h4>
                                {/* <h4 className='mt-2'>Thanks for the Transaction Successful</h4> */}
                                <p className='my-2'>Your payment has been received. You can see all payment.</p>
                            </div>
                            <div>
                                {/* <h4 onClick={togglePaymentView} className='text-primary text-center mt-2' style={{ cursor: 'pointer' }} ><u >View Invoice</u></h4> */}
                                <Row className='ConfirmatioForstyle'>
                                        {/* <Col xl='6'> */}
                                        <div className='d-flex justify-content-between'>
                                            <div><h4>Summary</h4>
                                                <h5 className='mt-2'>Transactions ID</h5>
                                                <h5>Date & Time </h5>
                                                <h5>Payment Method </h5>
                                                <h5>Added Amount </h5>
                                                <h4 style={{fontSize:'13px'}}>Opening available balance</h4>
                                            </div>
                                            <div className='mt-4'>
                                                <h5>#6541651651</h5>
                                                <h5>12 Feb 2021,04:48PM</h5>
                                                <h5>Net Banking </h5>
                                                <h5>$2350 </h5>
                                                <h4 className='mt-1'>$2550</h4></div></div>
                                        {/* </Col> */}
                                        {/* <Col xl='6' className='text-right mt-4'>
                                        </Col> */}
                                    </Row>
                            </div>
                            <div className='mt-2'>
                                <Button onClick={() => navigate('/Dashboard')} color='primary' className='w-100' >Go Back</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (null)}
        </>
    )
}

export default PaymentSuccess
