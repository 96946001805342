import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import india_svg from '../../../NewComponents/IMG/india_svg.svg';
import img_1 from '../../../NewComponents/IMG/img_1.png'
import profile from '../../../NewComponents/IMG/profile_conn.png'
import { Button } from 'react-bootstrap';
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';


const PublicAccount = ({request}) => {
 

    return (
        <Card className='popover____content' >
            <div className={`d-flex align-items-center border-bottom p-2`}>
                <img 
                    src={request?.profileImage} 
                    alt='...' 
                    height='60px' 
                    width='60px' 
                />
                <div className='ml-3'>
                    <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '120px' }}>{request?.fullName || "NA"}</h4>
                    <div className='d-flex'>
                        <p className='user-name-style'>{request?.userName || "NA"} | </p>
                        <span>
                            <img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} />
                        </span>
                    </div>
                </div>
            </div>
            <CardBody>
                <div className='border-bottom'>
                    <ul id="counter" className='d-flex justify-content-around '>
                        <li className='text-center'>
                            <h3 className="counter-value green-text-style" data-count="546">{request?.followingCount || 0}</h3>
                            <h5 className='professional-style'>Following</h5>
                        </li>
                        <li className='text-center mx-3'>
                            <h3 className="counter-value green-text-style" data-count="26335">{request?.totalPostCount || 0}</h3>
                            <h5 className='professional-style'>Post</h5>
                        </li>
                        <li className='text-center'>
                            <h3 className="counter-value green-text-style" data-count="6845">{request?.followersCount || 0}</h3>
                            <h5 className='professional-style'>Followers</h5>
                        </li>
                    </ul>
                </div>
                <Row className='pt-3'>
                    <Col xl='4' lg='4' md='4' sm='4' xs='4'>
                        {request?.postData && request?.postData[0] && request?.postData[0]?.fileURL ? (
                            <img src={request.postData[0].fileURL} alt='...' className='m-1 img-fluid' />
                        ) : (
                            <img src={img_1} alt='...' className='m-1 img-fluid' />
                        )}
                        {request.postData && request.postData[1] && request.postData[1].fileURL ? (
                            <img src={request.postData[1].fileURL} alt='...' className='m-1 img-fluid' />
                        ) : (
                            <img src={img_1} alt='...' className='m-1 img-fluid' />
                        )}
                    </Col>
                    <Col xl='4' lg='4' md='4' sm='4' xs='4'>
                        {/* Repeat the same pattern for the other columns */}
                        {/* ... */}
                        {request?.postData && request?.postData[2] && request?.postData[2]?.fileURL ? (
                            <img src={request.postData[0].fileURL} alt='...' className='m-1 img-fluid' />
                        ) : (
                            <img src={img_1} alt='...' className='m-1 img-fluid' />
                        )}
                        {request?.postData && request?.postData[3] && request?.postData[3]?.fileURL ? (
                            <img src={request.postData[1].fileURL} alt='...' className='m-1 img-fluid' />
                        ) : (
                            <img src={img_1} alt='...' className='m-1 img-fluid' />
                        )}
                    </Col>
                    <Col xl='4' lg='4' md='4' sm='4' xs='4'>
                        {/* Repeat the same pattern for the other columns */}
                        {/* ... */}
                        {request?.postData && request?.postData[4] && request?.postData[4]?.fileURL ? (
                            <img src={request.postData[0].fileURL} alt='...' className='m-1 img-fluid' />
                        ) : (
                            <img src={img_1} alt='...' className='m-1 img-fluid' />
                        )}
                        {request?.postData && request?.postData[5] && request?.postData[5]?.fileURL ? (
                            <img src={request.postData[1].fileURL} alt='...' className='m-1 img-fluid' />
                        ) : (
                            <img src={img_1} alt='...' className='m-1 img-fluid' />
                        )}
                    </Col>
                </Row>
                <div className='w-100 d-flex justify-content-between'>
                    <Button variant='outline-secondary' className='w-100 m-1' >
                        <span className='ml-1'>Following</span>
                    </Button>
                    <Button variant='primary' className='w-100 m-1'>
                        <span>Message</span>
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default PublicAccount