import { useEffect,useState }  from 'react'
// import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { loadAllTrendingHashtag } from '../../Services/Actions/Common/trandingHashtagAction';
import TrendIcon from '../../NewComponents/IMG/TrendIcon.svg'
import './style.css';
import axios from 'axios';

const TrendingHashtags = () => {
    const [hashTagData, setHashTagData] = useState([]);
//     const dispatch = useDispatch();
//     const { trandingHashtags } = useSelector(state => state.TrendingHashtagData)

//  console.log("trending hashtags",trandingHashtags)
 useEffect(() => {
    const fetchData = async () => {
        try {
            const body = {
                "searchKey":"",
            "pageIndex":0,
            "pageSize":5
            }
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/hashtag/getTrending`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                    },
                }
            );
          
            setHashTagData(response.data.data.successResult.rows);
            // console.log("Data for suggested Hashtags",response.data.data.successResult.rows)
        } catch (error) {
            console.error('Error fetching hashtags:', error);
        }
    };

    fetchData();
}, []);

    // const [pageSize] = useState({
    //     pageIndex: 0,
    //     pageSize: 5
    // })

    // console.log(trandingHashtags)

    //=============== Dummy_Treanding_data ================//
    const TreandingCardData = [
        {
            name: 'game ',
            postCount: "1452 "
        },
        {
            name: 'ipl',
            postCount: "552 "
        },
        {
            name: 'fifaworldcup',
            postCount: "1112 "
        },
        {
            name: 'cristianoronaldo',
            postCount: "152 "
        },
        {
            name: "fcbarcilona",
            postCount: '703  '
        }
    ]
    //=============== Dummy_Treanding_data ================//

    //========================== Main_retun_function ==================//
    return (
        <>
            <div className="event-box section-b-space ratio2_3 shadow-sm">
                <div className="column p-3">
                    <div className="CommonHeadingSeg">
                        <h3 style={{ color: "#333333" }}>Trending #Tags</h3>
                    </div>
                    {
                        hashTagData.map((item,index) => {
                            return (
                                <div key={index} className="d-flex align-items-start py-2">
                                    <div className="hashtag-icob">
                                        <img src={TrendIcon} alt="icon" />
                                    </div>
                                    <div className="hashtag-stat ml-2">
                                       <Link to="/Trending"> <h4 >#{item.name}</h4></Link>
                                        <h6 style={{ fontSize: "12px", color: "#999999" }} >{item.count} Posts</h6>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <hr />
                    <div className='text-center' >
                        <h5 className="time-hours-blk text-center mt-3" ><Link to="/Trending" style={{ color: '#999999' }}>See more tags</Link></h5>
                        {/* <Link to="#" style={{ fontSize: "14px", fontWeight: "500", color: "#999999" }}>See more tags</Link> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrendingHashtags