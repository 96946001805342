import * as types from "../../Constants/index";

const initialState = {
  allUserGroups: [],
  loading: true,
};


export const getAllGroupMainPageReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.GET_ALL_GROUPS_MAINPAGE:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_GROUPS_MAINPAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };

    case types.GET_ALL_GROUPS_MAINPAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllJoinedGroupReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.GET_ALL_GROUPS_JOINED_GROUP:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_GROUPS_JOINED_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };

    case types.GET_ALL_GROUPS_JOINED_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllGroupInvitationReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.GET_ALL_GROUPS_INVITATION:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_GROUPS_INVITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getGroupInvitation: action.payload,
      };

    case types.GET_ALL_GROUPS_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllGroupSuggestedGroupReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.GET_ALL_SUGGESTED_GROUP:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_SUGGESTED_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSuggestedGroup: action.payload,
      };

    case types.GET_ALL_SUGGESTED_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAllReportOptionFromGroupReducer = (state = initialState, action) => {

  switch (action.type) {

    case types.GET_ALL_REPORT_OPTION_GROUP:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_REPORT_OPTION_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        getGroupReportedOption: action.payload,
      };

    case types.GET_ALL_REPORT_OPTION_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const postReportOptionGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.POST_REPORT_TO_GROUP:
      return {
        loading: true,
      };

    case types.POST_REPORT_TO_GROUP_SUCCESS:
      return {
        loading: false,
        postGroupReport: action.payload,
      };

    case types.POST_REPORT_TO_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getAllGroupByIdReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_GROUP_BY_ID:
      return {
        loading: true,
      };

    case types.GET_GROUP_BY_ID_SUCCESS:
      return {
        loading: false,
        getGroupById: action.payload,
      };

    case types.GET_GROUP_BY_ID_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const createGroupPostReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.CREATE_GROUP_POST:
      return {
        loading: true,
      };

    case types.CREATE_GROUP_POST_SUCCESS:
      return {
        loading: false,
        createGroupPostById: action.payload,
      };

    case types.CREATE_GROUP_POST_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getGroupTrendingHashTagReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_TRENDING_TAGS_TO_GROUP:
      return {
        loading: true,
      };

    case types.GET_TRENDING_TAGS_TO_GROUP_SUCCESS:
      return {
        loading: false,
        trendingTags: action.payload,
      };

    case types.GET_TRENDING_TAGS_TO_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getAllGroupAdminAndMembersReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_All_GROUP_ADMIN_AND_MANAGERS:
      return {
        loading: true,
      };

    case types.GET_All_GROUP_ADMIN_AND_MANAGERS_SUCCESS:
      return {
        loading: false,
        getAllGroupAdmins: action.payload,
      };

    case types.GET_All_GROUP_ADMIN_AND_MANAGERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getAllAboutGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_All_ABOUT_GROUP:
      return {
        loading: true,
      };

    case types.GET_All_ABOUT_GROUP_SUCCESS:
      return {
        loading: false,
        getAllAboutGroup: action.payload,
      };

    case types.GET_All_ABOUT_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const getAllGroupMembersReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_All_ABOUT_GROUP_MEMBERS:
      return {
        loading: true,
      };

    case types.GET_All_ABOUT_GROUP_MEMBERS_SUCCESS:
      return {
        loading: false,
        getAllGroupMembersData: action.payload,
      };

    case types.GET_All_ABOUT_GROUP_MEMBERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const postPinToGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.POST_PIN_FROM_GROUP:
      return {
        loading: true,
      };

    case types.POST_PIN_FROM_GROUP_SUCCESS:
      return {
        loading: false,
        postPinToGroup: action.payload,
      };

    case types.POST_PIN_FROM_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getPinFromGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_PIN_FROM_GROUP:
      return {
        loading: true,
      };

    case types.GET_PIN_FROM_GROUP_SUCCESS:
      return {
        loading: false,
        getPinFromGroup: action.payload,
      };

    case types.GET_PIN_FROM_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const getAllBlockedUsersByGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_BLOCKED_GROUP_USERS:
      return {
        loading: true,
      };

    case types.GET_BLOCKED_GROUP_USERS_SUCCESS:
      return {
        loading: false,
        getAllBlockedGroupUsers: action.payload,
      };

    case types.GET_BLOCKED_GROUP_USERS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getJoinedRequestFromGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_JOINED_REQUEST_FROM_GROUP:
      return {
        loading: true,
      };

    case types.GET_JOINED_REQUEST_FROM_GROUP_SUCCESS:
      return {
        loading: false,
        getAllJoinedRequest: action.payload,
      };

    case types.GET_JOINED_REQUEST_FROM_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getPostRequestFromGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_POST_REQUEST_FROM_GROUP:
      return {
        loading: true,
      };

    case types.GET_POST_REQUEST_FROM_GROUP_SUCCESS:
      return {
        loading: false,
        getAllPostRequest: action.payload,
      };

    case types.GET_POST_REQUEST_FROM_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getInvitedPeopleToModalReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_INVITED_PEOPLE_TO_MODAL:
      return {
        loading: true,
      };

    case types.GET_INVITED_PEOPLE_TO_MODAL_SUCCESS:
      return {
        loading: false,
        getInvitedPeopleToModalRequest: action.payload,
      };

    case types.GET_INVITED_PEOPLE_TO_MODAL_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const postInvitationToOwnGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.POST_INVITE_JOIN_OWNED_GROUP:
      return {
        loading: true,
      };

    case types.POST_INVITE_JOIN_OWNED_GROUP_SUCCESS:
      return {
        loading: false,
        postInvitationToOwnGroup: action.payload,
      };

    case types.POST_INVITE_JOIN_OWNED_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const postUnFollowFromJoinedGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.UN_FOLLOW_FROM_JOINED_GROUP:
      return {
        loading: true,
      };

    case types.UN_FOLLOW_FROM_JOINED_GROUP_SUCCESS:
      return {
        loading: false,
        unFollowFromJoinedGroupReducer: action.payload,
      };

    case types.UN_FOLLOW_FROM_JOINED_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const postFollowGroupFromGroupInvitationReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FOLLOW_GROUP_FROM_GROUP_INVITATION:
      return {
        loading: true,
      };

    case types.FOLLOW_GROUP_FROM_GROUP_INVITATION_SUCCESS:
      return {
        loading: false,
        followGroup: action.payload,
      };

    case types.FOLLOW_GROUP_FROM_GROUP_INVITATION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const postDeclineGroupFromGroupInvitationReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.DECLINE_GROUP_FROM_GROUP_INVITATION:
      return {
        loading: true,
      };

    case types.DECLINE_GROUP_FROM_GROUP_INVITATION_SUCCESS:
      return {
        loading: false,
        declineGroup: action.payload,
      };

    case types.DECLINE_GROUP_FROM_GROUP_INVITATION_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const postCreateNewGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.CREATE_NEW_GROUP:
      return {
        loading: true,
      };

    case types.CREATE_NEW_GROUP_SUCCESS:
      return {
        loading: false,
        createNewGroup: action.payload,
      };

    case types.CREATE_NEW_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const postToJoinSuggestedGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.JOIN_SUGGESTED_GROUP:
      return {
        loading: true,
      };

    case types.JOIN_SUGGESTED_GROUP_SUCCESS:
      return {
        loading: false,
        toJoinSuggestedGroup: action.payload,
      };

    case types.JOIN_SUGGESTED_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};



export const deleteGroupByIdReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.DELETE_GROUP_BY_PASSWORD:
      return {
        loading: true,
      };

    case types.DELETE_GROUP_BY_PASSWORD_SUCCESS:
      return {
        loading: false,
        deleteGroupByPassword: action.payload,
      };

    case types.DELETE_GROUP_BY_PASSWORD_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const deleteGroupByUserReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.DELETE_GROUP_BY_USER:
      return {
        loading: true,
      };

    case types.DELETE_GROUP_BY_USER_SUCCESS:
      return {
        loading: false,
        deleteGroupByUser: action.payload,
      };

    case types.DELETE_GROUP_BY_USER_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const groupSettingsAllInOneReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GROUP_SETTINGS_ALL_IN_ONE:
      return {
        loading: true,
      };

    case types.GROUP_SETTINGS_ALL_IN_ONE_SUCCESS:
      return {
        loading: false,
        groupSettingsAll: action.payload,
      };

    case types.GROUP_SETTINGS_ALL_IN_ONE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const groupReviewPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_REVIEW_POST:
      return {
        loading: true,
      };
    case types.GROUP_REVIEW_POST_SUCCESS:
      return {
        loading: false,
        groupReviewSettings: action.payload,
      };
    case types.GROUP_REVIEW_POST_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const groupReviewMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_REVIEW_MEMBER:
      return {
        loading: true,
      };
    case types.GROUP_REVIEW_MEMBER_SUCCESS:
      return {
        loading: false,
        groupReviewMember: action.payload,
      };
    case types.GROUP_REVIEW_MEMBER_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const groupSharedToStoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_SHARED_TO_STORIES:
      return {
        loading: true,
      };
    case types.GROUP_SHARED_TO_STORIES_SUCCESS:
      return {
        loading: false,
        sharedToStoriesReducer: action.payload,
      };
    case types.GROUP_SHARED_TO_STORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateGroupPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_GROUP_POST:
      return {
        loading: true,
      };
    case types.UPDATE_GROUP_POST_SUCCESS:
      return {
        loading: false,
        updateGroupPostByIdReducer: action.payload,
      };
    case types.UPDATE_GROUP_POST_ERROR:
      return {
        loading: false,
        groupPostError: action.payload,
      };
    default:
      return state;
  }
};

export const getAllGeneralNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_GET_GENERAL_SETTINGS:
      return {
        loading: true,
      };
    case types.GROUP_GET_GENERAL_SETTINGS_SUCCESS:
      return {
        loading: false,
        getAllGeneralNotificationSettingsReducer: action.payload,
      };
    case types.GROUP_GET_GENERAL_SETTINGS_ERROR:
      return {
        loading: false,
        getAllGeneralNotificationSettingsError: action.payload,
      };
    default:
      return state;
  }
};

export const setNotificationToGroupSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_GENERAL_SETTINGS:
      return {
        loading: true,
      };
    case types.SET_NOTIFICATION_GENERAL_SETTINGS_SUCCESS:
      return {
        loading: false,
        setAllGeneralNotification: action.payload,
      };
    case types.SET_NOTIFICATION_GENERAL_SETTINGS_ERROR:
      return {
        loading: false,
        setAllGeneralNotificationError: action.payload,
      };
    default:
      return state;
  }
};

export const setNotificationToGroupSettingsTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_GENERAL_TAB:
      return {
        loading: true,
      };
    case types.SET_NOTIFICATION_GENERAL_TAB_SUCCESS:
      return {
        loading: false,
        setAllGeneralNotificationTab: action.payload,
      };
    case types.SET_NOTIFICATION_GENERAL_TAB_ERROR:
      return {
        loading: false,
        setAllGeneralNotificationTabError: action.payload,
      };
    default:
      return state;
  }
};

export const socioMeeNotificationSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_SOCIOMEE_NOTIFICATION_SETTINGS:
      return {
        loading: true,
      };
    case types.GROUP_SOCIOMEE_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        socioMeeNotificationSettingsReducer: action.payload,
      };
    case types.GROUP_SOCIOMEE_NOTIFICATION_ERROR:
      return {
        loading: false,
        socioMeeNotificationSettingsError: action.payload,
      };
    default:
      return state;
  }
};

export const groupActivityHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GROUP_ACTIVITY_HISTORY:
      return {
        loading: true,
      };
    case types.GROUP_ACTIVITY_HISTORY_SUCCESS:
      return {
        loading: false,
        grpActivityHistorySuccess: action.payload,
      };
      case types.GROUP_ACTIVITY_HISTORY_UN_SUCCESS:
        return {
            ...state,
            loading: false,
            success: false,
            error:false,
            grpActivityHistorySuccess: action.payload,
        };
    case types.GROUP_ACTIVITY_HISTORY_ERROR:
      return {
        loading: false,
        grpActivityHistoryError: action.payload,
      };
    default:
      return state;
  }
};


export const getAllCategoryFromGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_ALL_CATEGORY_FROM_GROUP:
      return {
        loading: true,
      };

    case types.GET_ALL_CATEGORY_FROM_GROUP_SUCCESS:
      return {
        loading: false,
        getAllGroupCategory: action.payload,
      };

    case types.GET_ALL_CATEGORY_FROM_GROUP_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// export const postGroupRolesReducer = (state = initialState, action) => {
//   switch (action.type) {

//     case types.POST_GROUP_ROLE:
//       return {
//         loading: true,
//       };

//     case types.POST_GROUP_ROLE_SUCCESS:
//       return {
//         loading: false,
//         getAllGroupCategory: action.payload,
//       };

//     case types.POST_GROUP_ROLE_ERROR:
//       return {
//         loading: false,
//         error: action.payload,
//       };

//     default:
//       return state;
//   }
// };


export const postGroupRolesReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.POST_GROUP_ROLE:
          return {
              ...state,
              loading: action.payload,
          };
      case types.POST_GROUP_ROLE_SUCCESS:
          return {
              ...state,
              loading: false,
              success: true,
              result: action.payload,
          };
          case types.POST_GROUP_ROLE_UN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                error:false,
                result: action.payload,
            };
      case types.POST_GROUP_ROLE_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };
      default:
          return state;
  }
};

export const removeGroupRolesReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.REMOVE_GROUP_ROLE:
          return {
              ...state,
              loading: action.payload,
          };
      case types.REMOVE_GROUP_ROLE_SUCCESS:
          return {
              ...state,
              loading: false,
              success: true,
              result: action.payload,
          };
      case types.REMOVE_GROUP_ROLE_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };
      default:
          return state;
  }
};


export const unBlockGroupUserReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.REMOVE_GROUP_ROLE:
          return {
              ...state,
              loading: action.payload,
          };
      case types.REMOVE_GROUP_ROLE_SUCCESS:
          return {
              ...state,
              loading: false,
              success: true,
              unBlockResult: action.payload,
          };
      case types.REMOVE_GROUP_ROLE_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };
      default:
          return state;
  }
};

// export default {
//   getAllGroupMainPageReducer,
//   getAllJoinedGroupReducer,
// }




