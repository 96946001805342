
import React, { useState } from 'react'
import { AlertTriangle, Delete, Edit, Eye, Filter, Link, MapPin, MoreHorizontal, MoreVertical, Send, Share2, Star, Trash2, UserPlus, Users } from 'react-feather'
import { Button, Card, Col, Input, InputGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { useTheme, Slider as MatSlider, Stack, Checkbox } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import camara4 from '../../NewComponents/IMG/camara4.png'
import camara1 from '../../NewComponents/IMG/camara1.png'
import camara2 from '../../NewComponents/IMG/camara2.png'
import camara3 from '../../NewComponents/IMG/camara3.png'
import { NavLink } from 'react-router-dom'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import { Accordion } from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

const MarketplaceTabs = () => {
    const [show, setshow] = useState(false)
    const [open, setOpen] = useState('')
    // const settoggle = (id) => {
    //     if (open === id) {
    //         setOpen();
    //     } else {
    //         setshow(id)
    //     }
    //     // setshow(false)
    // }
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }
    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const Marketplace = [
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            button: <button className='Group_Requested'>Going</button>,
            button1: <button type='button' className='BizPage_Invite'>Interested</button>,
            camara: camara4
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Traditional Wedding',
            button: <button className='Group_Requested'>Going</button>,
            button1: <button type='button' className='Invite'>Interested</button>,
            camara: camara1
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            button: <button className='Group_Requested'>Going</button>,
            button1: <button type='button' className='BizPage_Invite'>Interested</button>,
            camara: camara2
        },
        {
            sharevia: 'Share via',
            shareto: 'Share to',
            notification: 'Turn of Notification',
            invite1: 'Invite people',
            content: 'Action Pack',
            report: 'Report group',
            leave: 'Leave Group',
            create: 'Create Group',
            content: 'Action Pack',
            button: <button className='Group_Requested'>Going</button>,
            button1: <button type='button' className='BizPage_Invite'>Interested</button>,
            camara: camara3
        }
    ]
    return (
        <div>
            <Card className='p-2 border-0'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col md='6'>
                        <div className='d-flex mt-2 ml-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Centre table mint condition”</p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='d-flex justify-content-end p-2'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className=''>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view ml-2'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className=''>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='AllTagsThis'>
                    {
                        Marketplace.map((data) => {
                            return (
                                <Col xl='3' className=''>
                                    <div className='p-1 mt-2'>
                                        {/* <Slider {...bizSettings} className="default-space"> */}
                                        <div className="group-slide-box border">
                                            <div className="gallery-img-sublink">
                                            </div>
                                            <NavLink>
                                                <div className='Marketplace_Eye_view d-flex align-items-center'>
                                                    <p className='d-flex align-items-center'><Eye size={19} className='align-items-center' /></p>
                                                    <p className='Marketplace_Count'>284</p>
                                                </div>
                                                <div className='marketplaceright'>
                                                    <p><UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreHorizontal color='#FAF7F7' />
                                                        </DropdownToggle>
                                                        <DropdownMenu className='bizpagefordropdownmenu'>
                                                            <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown></p>
                                                </div>
                                                <img src={data.camara} className="img-fluid group-img" />
                                                <div className='d-flex justify-contant-start MarketplaceThis pl-1'>
                                                    Centre table mint condition...
                                                </div>
                                            </NavLink>
                                            <div className='d-flex justify-content-between m-1'>
                                                <p className='Marketplace_tabs_text ml-2'>Home Decoration </p>
                                                <p className='font-weight-bold mr-2'>$3000</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex align-items-center p-2'>
                                                    <MapPin size={15} color='#808080' className='' />
                                                    <p className='Marketplace_text ml-2'>813 Howard Street Oswego NY 131...</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Card>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Group</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Marketplace
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h4>Withim (Km)</h4>
                            <h4>8821 Km</h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className='w-100'>
                                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                    <MatSlider
                                        aria-label="Volume"
                                        defaultValue={100}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                            '& .MuiSlider-track': {
                                                border: 'none',
                                            },
                                            '& .MuiSlider-thumb': {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#81C14B',
                                                '&:before': {
                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                    boxShadow: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div className='d-flex align-items-center ml-3' role='button'>
                                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                            </div>
                        </div>
                        <div className='border-top mt-2'>
                            <h4 className='mt-3'>Choose locations</h4>
                            <ChipBoxLocation />
                        </div>
                        {/* <div className=''>
                            <h4 className='mt-3'>Choose Interest</h4>
                            <ChipBox />
                        </div> */}
                        <div className='d-flex justify-content-between align-items-center mt-2'>
                            <div className='p-1'>
                                <b>Price</b>
                                <Input
                                    name="select"
                                    type="select"
                                    className='mt-2'
                                >
                                    <option>
                                        ₹ &nbsp;
                                    </option>
                                    <option>
                                        $
                                    </option>
                                    <option>
                                        £
                                    </option>
                                </Input>
                            </div>
                            <div className='p-1'>
                                <b>Minimum</b>
                                <Input placeholder='Amount...' className='mt-2'></Input>
                            </div>
                            <div className='p-1'>
                                <b>Miximum</b>
                                <Input placeholder='Amount...' className='mt-2'></Input>
                            </div>
                        </div>
                        <div>
                            <h4 className='mt-2'>Select Category</h4>
                            <Accordion defaultActiveKey="0">
                                <AccordionItem className='align-items-center' eventKey='1' onClick={() => { setshow(!show) }}>
                                    <AccordionHeader targetId="1"><Checkbox checked={show} />&nbsp;&nbsp; Property{open}</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <div className='d-flex align-items-center'>
                                            <Checkbox />
                                            < p className='ml-2'>Villa</p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div>
                            <b>Carpet Area</b>
                            <Input placeholder='Enter carpet area' className='mt-2' />
                        </div>
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default MarketplaceTabs