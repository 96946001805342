import React from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Col, Row } from "reactstrap"
import { ChevronDown, ChevronUp, Eye, MessageCircle, Music, Send } from "react-feather"
import MediaModal from "../../profile-components/MediaModal"
import like from '../../../NewComponents/IMG/like.png'
import { ShotzCarouselComponents } from "./ShotzCarouselComponents"
import { useState } from "react"
import { useUserAuth } from "../../../Context/userAuthContext"

const ExploreShotzGrid = () => {

    // Shotz Carousel state called from context 
    const { shotzCarouselModal, setShotzCarouselModal } = useUserAuth();

    const toggleShotzCarouselModal = () => {
        setShotzCarouselModal((prevState) => !prevState)
    }
    const Shotz = (props) => {
        const numFormatter = (num) => {
            // eslint-disable-next-line prefer-template
            if (num > 999 && num < 1000000) {
                return `${parseInt(num / 1000)} K`
            } else if (num > 999999) {
                return `${parseInt(num / 1000000)} M`
            } else if (num < 900) {
                return `${num}`
            }
        }
        const Shotzdata = [
            {
                Shotzs: props.shotz,
                icon: <Eye size={18} />,
                num: '12000',
                timeline: 'Hide',
                post: 'Edit',
                link: 'Copy Link ',
                delete: 'Delete',
                share: 'Share',
                download: 'Download',
                archive: 'Add to Achive',
                songs: 'Kesariya - Brahmāstra | Ranbir Kapoor | Alia Bhatt...',
                icon1: <Music size={25} color='#FFFFFF' />,
                num1: '1000',
                num2: '30000',
                num3: '23000'

            }

        ]
        return (
            <>
                <Row className='AllTagsThis rowForrevert p-0'>
                    {
                        Shotzdata.map((data) => {
                            return (
                                <Col xl='3' className='w-100'>
                                    <div className="media-images-blk shotz-album-block align-items-center">
                                        <div className="main-shotz-blk-list" onClick={toggleShotzCarouselModal}>
                                            <figure className="shotz-card-hover m-0">
                                                <img className="img-fluid img-gradient" src={data.Shotzs} alt="" />
                                                <figcaption >The Day</figcaption>
                                                <div className="text-gallery d-flex justify-content-center">
                                                    <div className="top-gallery-cont d-flex justify-content-beetween">
                                                        <img src="assets/images/shotz-icon.png" height={25} width={25} className='img-fluid' />
                                                        <h5 className="text-white font-weight-bold">{data.icon} {numFormatter(data.num)}</h5>
                                                    </div>
                                                    <div className="Music-gallery-cont mx-2 py-3">
                                                        <p className='shotz-text'>{data.icon1}</p>&nbsp;&nbsp;
                                                        <h5 className='text-white mr-2'>{data.songs}</h5>
                                                    </div>
                                                    <div className="bottom-gallery-cont">
                                                        <p className='shotz-text'><img src={like} />{numFormatter(data.num1)}</p>
                                                        <p className='shotz-text'><MessageCircle size={20} />{numFormatter(data.num2)}</p>
                                                        <p className='shotz-text'><Send size={20} />{numFormatter(data.num3)}</p>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                <MediaModal />
            </>
        )
    }

    const CardGrid = [
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/45/e1/c8/45e1c806ba5b0ff20446e7749ec9338b.jpg' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/61/4e/ad/614ead93d9ad1e78a55b68d4221d62a6.jpg' />
        },
        {
            grid: <Shotz shotz='https://i0.wp.com/www.anmolsms.com/wp-content/uploads/2020/12/43e4eebee019e145c343e2f3130a4824.jpg?ssl=1' />
        },
        {
            grid: <Shotz shotz='https://images.unsplash.com/photo-1611246981370-93febc04e6d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y3V0ZSUyMGdpcmxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/39853/woman-girl-freedom-happy-39853.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/originals/d6/39/2e/d6392eb38b8b7b9e7e3e73d135f217bd.jpg' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/834872/pexels-photo-834872.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/61/4e/ad/614ead93d9ad1e78a55b68d4221d62a6.jpg' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/f7/cd/02/f7cd02610ca5756e8db8e7cb779b1da7.jpg' />
        },
        {
            grid: <Shotz shotz='https://images.unsplash.com/photo-1611246981370-93febc04e6d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y3V0ZSUyMGdpcmxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/1557843/pexels-photo-1557843.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/fd/72/72/fd7272a40fdaad19f4c88197dbb9a0a9--ki.jpg' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/789305/pexels-photo-789305.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/1323207/pexels-photo-1323207.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/257849/pexels-photo-257849.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/614503/pexels-photo-614503.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/1104035/pexels-photo-1104035.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/45/e1/c8/45e1c806ba5b0ff20446e7749ec9338b.jpg' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/61/4e/ad/614ead93d9ad1e78a55b68d4221d62a6.jpg' />
        },
        {
            grid: <Shotz shotz='https://i0.wp.com/www.anmolsms.com/wp-content/uploads/2020/12/43e4eebee019e145c343e2f3130a4824.jpg?ssl=1' />
        },
        {
            grid: <Shotz shotz='https://images.unsplash.com/photo-1611246981370-93febc04e6d5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y3V0ZSUyMGdpcmxzfGVufDB8fDB8fA%3D%3D&w=1000&q=80' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/834872/pexels-photo-834872.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/61/4e/ad/614ead93d9ad1e78a55b68d4221d62a6.jpg' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/736x/f7/cd/02/f7cd02610ca5756e8db8e7cb779b1da7.jpg' />
        },
        {
            grid: <Shotz shotz='https://images.pexels.com/photos/39853/woman-girl-freedom-happy-39853.jpeg?auto=compress&cs=tinysrgb&w=1600' />
        },
        {
            grid: <Shotz shotz='https://i.pinimg.com/originals/d6/39/2e/d6392eb38b8b7b9e7e3e73d135f217bd.jpg' />
        },


    ]

    return (

        <div className="mt-1 pt-1">
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }} >
                <Masonry gutter="10px">
                    {CardGrid.map((data) => (
                        <div>
                            {data.grid}
                        </div>
                    ))}
                </Masonry>
            </ResponsiveMasonry>
            {/* ------------- Start Shotz Carousel Modal ------------- */}
            <ShotzCarouselComponents
                isOpen={shotzCarouselModal}
                ShotzModalCarousel={toggleShotzCarouselModal}
            />
            {/* ------------- End Shotz Carousel Modal ------------- */}
        </div>
    )
}
export default ExploreShotzGrid 