import React from 'react'
import { Fragment } from 'react'
import { Bookmark, Globe, Link2, Lock, MoreVertical, Share2, XCircle } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import GroupMenu from './GroupMenu';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllJoinedGroup } from '../../Services/Actions/Group/getAllGroupMainPageAction';
import { useEffect } from 'react';
const JoinedGroup = () => {

    const dispatch = useDispatch()
    const { getGroupJoinedGroup } = useSelector((state) => {return state.getAllJoinedGroupData});
    useEffect(() => {
        dispatch(loadAllJoinedGroup())
    },[])

    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    //================== main_return_function ===================//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="group-heading"><h2>Groups</h2></div>
                    <GroupMenu />
                    <div className="container-fluid section-t-space px-0">
                        <div className="group-single-list-block">
                            <Row>
                                {
                                  getGroupJoinedGroup && getGroupJoinedGroup.data.successResult.rows.map((data, index) => (
                                        <Col md="3">
                                            <Card className="group-slide-box">
                                                <div className="gallery-img-sublink">
                                                    <XCircle color="#FFFFFF" size={21} />
                                                </div>
                                                <div className='gallery_menu_sublink'>
                                                    <MoreVertical color="#FFFFFF" size={21} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                        <ul>
                                                            <li>
                                                                <Link to="#"><Share2 size={15} color="#4D4D4D" /> Share Group</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <NavLink to= {`/GroupDetail/${data.id}/${data.groupName}/${data.totalMembers}/${index}`}>
                                                    <img alt='Profile Pic' src={data.coverPicUrl ? data.coverPicUrl : ('/assets/images/group-img-1.jpg')} className="img-fluid group-img" />
                                                </NavLink>

                                                {/* <NavLink to={`/GroupDetail/${data.id}/${data.name}/${data.totalMembers}/${index}`}>
                                                    <img alt='' src={data.coverPic} className="img-fluid group-img" />
                                                </NavLink> */}
                                                
                                                <div className="group-content">
                                                    <h4 className='text-capitalize'>{data.groupName}</h4>
                                                    {data.privacy === "public" ?
                                                        <div className="Suggestmember-blk">
                                                            <span className='text-capitalize'><Globe style={{marginTop:'-3px'}} color='#4D4D4D' size={12} />&nbsp;{data.privacy ? data.privacy :'Private'}</span> 
                                                            &nbsp; • &nbsp; 
                                                            <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                            }
                                                        </span>
                                                        </div> :
                                                        <div className="Suggestmember-blk">
                                                            <span className='text-capitalize'><Lock style={{marginTop:'-3px'}} color='#4D4D4D' size={12} />&nbsp;{data.privacy ? data.privacy :'Private'}</span> 
                                                            &nbsp; • &nbsp;
                                                            <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                            }
                                                        </span>
                                                        </div>
                                                    }
                                                    <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                        <p>{data.paragraph}</p>
                                                    </div>
                                                    <div className="group-buttons-blk mt-3">
                                                    <NavLink to= {`/GroupDetail/${data.id}/${data.groupName}/${data.totalMembers}/${index}`}>
                                                        <Button className="Invite_Join_Button" color='primary'>View Activity</Button>
                                                    </NavLink>
                                                        <Button className="Invite_Decline_Button ml-2" outline >Unfollow</Button>
                                                    </div> 
                                                </div>
                                            </Card>
                                        </Col>
                                    ))
                                }

                            </Row>

                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}

export default JoinedGroup