import React from 'react'
import { Fragment } from 'react'
import '../style.css'
import Slider from 'react-slick';
import Img from "../Img/IMG.png"
import Img1 from "../Img/IMG1.png"
import Img2 from "../Img/IMG2.png"
import Img3 from "../Img/IMG3.png"
import Img4 from "../Img/IMG4.png"
import { Bookmark, Globe, Link2, Lock, MoreVertical, Share2, XCircle } from 'react-feather';
import Suggest from "../Img/suggestImg.png"
import Trending from "../Img/trending.png"
import { Button, Card, CardBody, CardImg, CardTitle, Col, Row } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getTrendingTags, unFollowFromJoinedGroupAction} from '../../../Services/Actions/Group/getAllGroupMainPageAction';

import { useEffect } from 'react';
import { SweetErrorAlert } from '../AllGroupsSections/SweetErrorAlert';

let SuggestionsSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};

const GalleryLeftSideData = () => {
    let dispatch = useDispatch();

    const unFollow = (id) => {
        dispatch(unFollowFromJoinedGroupAction(id))
        SweetErrorAlert('Doesnt find out the backend API To remove suggestions')
    }

    const { getAllSuggestedGroup } = useSelector((state) => { return state.getAllGroupSuggestedGroupData});
    const { trendingTags } = useSelector((state) => { return state.getGroupTrendingHashTagData});

    useEffect(() => {
        dispatch(getTrendingTags())
    }, [])

    const numFormatter = (num) => {
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})}k`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})}M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    const TrendingData = [
        {
            hasTag: '#game ',
            postData: "1452 Posts "
        },
        {
            hasTag: '#game ',
            postData: "1452 Posts "
        }
    ]
    //_------------------------------Card_Info_Array
    const SuggestedDataInfo = [
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... "


        },
        {
            name: 'Singer Group',
            GroupType: "Private",
            num: 24231,
            paragraph: "Learn how to master your commun... "


        },
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
        {
            name: 'Movies',
            GroupType: "Public",
            num: 24231,
            paragraph: "Learn how to master your commun... "

        },
    ]
    return (
        <Fragment>
            <div className='About_Gallery_Section'>
                <CardBody>
                    <CardTitle className='d-dlex justify-content-between Trending_Heading_section'>
                        <h4>Gallery</h4>
                        <p>See all →</p>
                    </CardTitle>
                    <Row className="mt-3">
                        <Col md="7">
                            <div className='d-flex'>
                                <CardImg src={Img} alt="default" className="img-fluid" />
                                <CardImg src={Img1} alt="default" className="img-fluid ml-2" />

                            </div>
                            <div className='d-flex mt-2'>
                                <CardImg src={Img2} alt="default" className="img-fluid" />
                                <CardImg src={Img3} alt="default" className="img-fluid ml-2" />
                            </div>
                        </Col>
                        <Col md="5">
                            <div>
                                <CardImg src={Img4} alt="default" className="img-fluid" style={{
                                    height: 167
                                }}
                                    width="100%" />

                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </div>
            <div className='About_Gallery_Section'>
                <CardBody>
                    <CardTitle className='d-flex justify-content-between Trending_Heading_section'>
                        <h4>Suggestions</h4>
                        <NavLink to="/Suggested"> <p>See all →</p></NavLink>
                    </CardTitle>
                    <div className="podcast-list-block">
                        <Slider {...SuggestionsSettings} className="default-space">
                            {
                                getAllSuggestedGroup && getAllSuggestedGroup.data.successResult.map((data, index) => (
                                    <div>
                                        <Card className="group-slide-box">
                                            <div className="gallery-img-sublink">
                                                <XCircle color="#FFFFFF"
                                                style={{cursor:'pointer'}} size={21} 
                                                onClick={() => { unFollow(data?.id) }}                                               
                                            />
                                            </div>
                                            <div className='gallery_menu_sublink'>
                                                <MoreVertical color="#FFFFFF" size={21} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                                    <ul>
                                                        <li>
                                                            <Link to="#"><Share2 size={15} color="#4D4D4D" /> Share Group</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#"><Link2 size={15} color="#4D4D4D" /> Copy Link</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#"><Bookmark size={15} color="#4D4D4D" /> Report Group</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <NavLink to={`/Suggested`}><img alt='Profile Pic' src={`${data.coverPicUrl}` ? `${data.coverPicUrl}` : '/assets/images/group-img-1.jpg'} className="img-fluid group-img" /></NavLink>
                                            <div className="group-content">
                                            <h4 className='text-capitalize'>
                                                    {data?.name?.substring(0, 20)} 
                                                    {data?.name?.length >= 15 && ' ...'}
                                                </h4>  
                                                    <div className="Suggestmember-blk">
        
                                                        {/* {data.totalMembers >0 ? <AvatarGroup> {data.totalMembers} </AvatarGroup> : <p>Be a member</p> }  */}
                                                        <span className='text-capitalize'><Globe style={{marginTop:'-2px'}}
                                                         color='#4D4D4D' size={12} /> {data.privacy ? data.privacy :'Private'}</span>&nbsp; • &nbsp;
                                                        <span>
                                                            {                                                        
                                                               data.totalMembers > 0 ?
                                                                <>                                                        
                                                                    {numFormatter(data.totalMembers)} {data.totalMembers === 1 ? 'Member' : 'Members' }
                                                                </>
                                                                :
                                                                <p>Be the first</p>
                                                            }
                                                        </span>
                                                        {/* <span>{numFormatter(data.totalMembers > 0 ? <AvatarGroup> {data.totalMembers} members </AvatarGroup> : <p>Be a member</p>)}</span> */}
                                                    </div>
                                                    <div className="people-likes matual-friend-sec Suggestmember-blk-text ">
                                                        <p>{data.paragraph}</p>
                                                    </div>
                                                    <div className=" mt-3">
                                                        <NavLink to="/GroupDetail"><Button className="Group_Join_btn" color='primary'>Join Group</Button></NavLink>
                                                    </div>
                                            </div>
                                        </Card>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </CardBody>

            </div>
            <div className='About_Gallery_Section'>
                <CardBody>
                    <CardTitle className="Trending_Heading_section">
                        <h4>Trending Tags</h4>
                    </CardTitle>
                    <div className="biz-about-cont">
                        <ul>
                            <li>
                                {
                                   trendingTags && trendingTags.data.successResult.rows.map((data, index) => {
                                    if(index <3)
                                        return (<div className='d-flex  align-items-center trending_section'>
                                            <img alt='' src={Trending} className="img-fluid" />
                                            <div className='Tranding_data'>
                                                <h4>{data.name}</h4>
                                                <p>{data.count} </p>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </li>
                        </ul>
                        <span>See more tags</span>
                    </div>
                </CardBody>

            </div>
        </Fragment>
    )
}

export default GalleryLeftSideData