import moment from 'moment'
import React from 'react'
import { Fragment } from 'react'
import Request1 from "../../../group-components/Img/Request1.png"
import Request2 from "../../../group-components/Img/Request2.png"
import Request3 from "../../../group-components/Img/Request2.png"

import { Button, Col, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { useState } from 'react'
import { Circle } from 'react-feather'

const VistorMamberModal = (props) => {
    const [active, setActive] = useState('1')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const MemberData = [
        {
            img: Request1,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Sociomate"
        },
        {
            img: Request2,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Follow",

        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Following ",
        },
        {
            img: Request2,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Add Friend  ",
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Following ",
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Following ",
        }, {
            img: Request3,
            name: "Kenjomen Norke",
            startDate: '2022-06-08T12:00:00.000Z',
            joingData: "Joined on",
            mutual: '+3 Mutual Group',
            userType: "Following ",
        }
    ]
    return (
        <Fragment>
            <div className='Members_Visited_data_Modal'>
                {
                    props.isOpen !== false &&
                    <Modal scrollable isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered modalbiz'>
                        <Row className="Member_Tab_heading_section">
                            <Nav>
                                <Col md={6}>
                                    <NavItem>
                                        <NavLink
                                            active={active === '1'}
                                            onClick={() => {
                                                toggle('1')
                                            }}
                                            className="Member_link"
                                        >
                                            Members  (70)
                                        </NavLink>
                                    </NavItem>
                                </Col>
                                <Col md={6}>
                                    <NavItem>
                                        <NavLink
                                            active={active === '2'}
                                            onClick={() => {
                                                toggle('2')
                                            }}
                                            className="Member_link"
                                        >
                                            Visited today (40)
                                        </NavLink>
                                    </NavItem>
                                </Col>
                            </Nav>
                        </Row>
                        <ModalBody>
                            <div className="right-setting pb-3 ">
                                <div className="profile-search-blk  w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <Row>
                                <TabContent className='py-50' activeTab={active}>
                                <TabPane tabId='1'>
                                        {
                                            MemberData.map((data) => (

                                                <div className="Biz-members-block">
                                                    <div className="group-member-img-cont-blk">
                                                        <img src={data.img} alt="img" />
                                                        <div className="member-cont-blk">
                                                            <h4>{data.name}</h4>
                                                            <p>{data.joingData}
                                                                {/* &nbsp;{moment(data.startDate).format('MMM Do YYYY')}&nbsp; */}
                                                            </p>
                                                            <div className='BizMember_bottom_count'>
                                                                <p class="font-weight-bold"> 9.8M&nbsp;<span>Followers</span></p>
                                                                &nbsp;&nbsp;
                                                                <p class="font-weight-bold"><Circle fill='#808080' size={5} />&nbsp;&nbsp;18M&nbsp;<span> Posts</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Admin-btns-blk followbut '>
                                                        <Button style={{width:"100px"}} color='primary' outline>{data.userType}</Button>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </TabPane>
                                    <TabPane tabId='2'>
                                    {
                                            MemberData.map((data) => (

                                                <div className="Biz-members-block">
                                                    <div className="group-member-img-cont-blk">
                                                        <img src={data.img} alt="img" />
                                                        <div className="member-cont-blk">
                                                            <h4>{data.name}</h4>
                                                            <p>{data.joingData}
                                                                {/* &nbsp;{moment(data.startDate).format('MMM Do YYYY')}&nbsp; */}
                                                            </p>
                                                            <div className='BizMember_bottom_count'>
                                                                <p class="font-weight-bold"> 9.8M&nbsp;<span>Followers</span></p>
                                                                &nbsp;&nbsp;
                                                                <p class="font-weight-bold"><Circle fill='#808080' size={5} />&nbsp;&nbsp;18M&nbsp;<span> Posts</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Admin-btns-blk followbut '>
                                                        <Button style={{width:"100px"}} color='primary' outline>{data.userType}</Button>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default VistorMamberModal