import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Accordion } from "react-bootstrap";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ArrowLeftCircle, ArrowRightCircle, X } from "react-feather";
import { DataForTable } from "./DummyArrayAdmanager";
ChartJS.register(...registerables);

function AdManagerLinePage({ SelectedReportedType }) {
  const [tooltipModel, setTooltipModel] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({});
  const [openCustomSelection, setOpenCustomSelection] = useState(true);
  const [isDataShow, setIsDataShow] = useState(DataForTable);

  const manageDataShow = (label) => {
    if (isDataShow.find((x) => x.label === label)) {
      setIsDataShow(isDataShow.filter((x) => x.label !== label));
    } else {
      let newDataSet = [...isDataShow, DataForTable.find((x) => x.label === label)];
      setIsDataShow(newDataSet);
    }
  };

  const Tooltip = ({ data, isShow, position, color }) => {
    const top = position.top + window.pageYOffset + data.y;
    const left = position.left + window.pageXOffset + data.x;

    return (
      isShow && (
        <div style={{ top, left, color }} className="tooltip-wrapper">
          {data.yLabel}
        </div>
      )
    );
  };

  const data = {
    labels: ["23/12-30/12", "23/03", "24/03", "25/03", "5 Days", "6 Days", "7 Days", "8 Days", "9 Days", "10 Days"],
    datasets: isDataShow,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            stepSize: 10,
          },
          gridLines: {
            drawTicks: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      mode: "index",
      intersect: false,
      enabled: false,
      custom: function (tooltipModel) {
        setTooltipModel(tooltipModel);

        if (tooltipModel.opacity === 0) {
          setShowTooltip(false);
          return;
        }

        setShowTooltip(true);
        setPosition(this._chart.canvas.getBoundingClientRect());
      },
    },
  };

  const LabelData = [
    { key: 'Reach', value: '' },
    { key: 'Impression', value: '' },
    { key: 'Lead', value: '' },
    { key: 'Click', value: '' },
    { key: 'View', value: '' },
  ];

  return (
    <>
      <Row className='rowForrevert'>
        <Col xl={openCustomSelection ? 10 : 12}>
          <Card>
            <CardBody>
              <div style={{ height: '500px' }}>
                {tooltipModel?.dataPoints !== undefined &&
                  tooltipModel?.dataPoints.map((tooltip, index) => (
                    <Tooltip
                      key={index}
                      data={tooltip}
                      isShow={showTooltip}
                      position={position}
                      color={tooltipModel.labelColors[index].borderColor}
                    />
                  ))}
                <div style={{ right: '0' }} onClick={() => setOpenCustomSelection(!openCustomSelection)} className="position-absolute px-1">
                  {openCustomSelection ? <ArrowRightCircle color="#808080" size={25} /> : <ArrowLeftCircle color="#808080" size={25} />}
                </div>
                <Line options={options} height={450} data={data} />
              </div>
            </CardBody>
          </Card>
        </Col>
        {openCustomSelection && (
          <Col xl={2}>
            <Card className='shadow'>
              <CardHeader>
                <div className="fontForSubchildCardheading d-flex justify-content-between align-items-center">
                  <h4>Customise {SelectedReportedType}</h4>
                  <h5 onClick={() => setOpenCustomSelection(false)}><X color="#808080" size={25} style={{cursor:'pointer'}}/></h5>
                </div>
              </CardHeader>
              <CardBody className='p-0'>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Our breakdowns</Accordion.Header>
                      <Accordion.Body>
                        <FormGroup>
                          {LabelData.map((data, index) => (
                            <FormControlLabel key={index} control={<Checkbox defaultChecked onChange={() => manageDataShow(data.key)} />} label={data.key} />
                          ))}
                        </FormGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
}

export default AdManagerLinePage;
