import React from 'react'
import { Fragment } from 'react'
import { Button } from 'react-bootstrap'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const DeletedModal = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.deleteModalOpens} className='modal-dialog-centered'>
                    <ModalHeader onClick={props.deleteModalOpens} className='d-flex justify-content-center Media_header' >
                        <h3>Delete All Shotz</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h5 className='py-1 name-style'>Do you really want to delete the selected Shotz?</h5>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center justify-content-center border-0 modalHeading_Section '>
                        <Button variant='outline-secondary' onClick={props.deleteModalOpens}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant='danger' onClick={props.deleteModalOpens}>
                            <span className='ml-1'>Delete</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </Fragment>
    )
}

export default DeletedModal