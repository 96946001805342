import { useState,useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import ProfileMenu from './ProfileMenu'
import { useDispatch, useSelector } from 'react-redux';
import OwnedGrpSection from '../../components/group-components/AllGroupsSections/OwnedGrpSection';
import JoinedGrpSection from '../group-components/AllGroupsSections/JoinedGrpSection'
import {loadAllJoinedGroup,loadAllGroupById} from '../../Services/Actions/Group/getAllGroupMainPageAction'
import { useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios'

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MyGroups = () => {
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const dispatch = useDispatch()
  // const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');
  // let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const [count,setCount]=useState(0)
  const [count1,setCount1]=useState(0);
  const [error,setError]=useState('')
  const [updateCount,setUpdateCount]=useState(false)
  const [searchKey,setSearchKey]=useState('');
  const [loading,setLoading]=useState(true)

  useEffect(() => {
    const fetchData = async () => {
        try {
            
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllListOwnedPinnedGroupPage`,
                    { userId: userId||user.id, pageIndex:0,pageSize:5},
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setCount(response.data.data.successResult.count);
                setUpdateCount(false)
                setLoading(false)
            } else {
              setError('no user found');
            }
        } catch (error) {
          setError(error);
        }
    };
  
    fetchData();
  }, [updateCount]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            
            if (user) {
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllListJoinedPinnedGroupPage`,
                    { userId: userId||user.id, pageIndex:0,pageSize:5},
                    { headers: { Authorization: `Bearer ${user.token}` } }
                );
                setCount1(response.data.data.successResult.count);
                setUpdateCount(false)
            } else {
              setError('no user found');
            }
        } catch (error) {
          setError(error);
        }
    };
  
    fetchData();
  }, [updateCount]);

  const TabSectionData = [
    {
      className: "nav-item nav-link active",
      id: "OwnedTab",
      link: "#nav-Owned",
      ariaControls: "nav-Owned",
      ariaSelected: "true",
      TabName: `Owned Groups (${count})`,
      count: count
    },
    {
      className: "nav-item nav-link",
      id: "JoinedTab",
      link: "#nav-Joined",
      ariaControls: "nav-Joined",
      ariaSelected: "false",
      TabName: `Joined Groups (${count1})`,
      count: count1
    }
  ];


  const filteredTabs = TabSectionData.filter(tab => {
    if (type === "viewer" && tab.id === "JoinedTab") {
      return false;
    }
    return tab.count > 0;
  });
 


  return (
    <>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <ProfileMenu />
          <div className="container-fluid section-t-space px-0">
            <div className="page-content">
              <div className="content-center w-100">
                <div className="gallery-page-section section-b-space">
                  <div className="card-title">
                    <h3>My Groups</h3>
                    <div className="right-setting">
                      <div className="profile-search-blk ">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />
                      </div>
                      <Link to="/Group/create" className="btn btn-outline-green ms-3"> + Create Group</Link>
                    </div>
                  </div>
                  <nav>
                    <div className="tab-section">
                      {loading?(
                        <>
                        <br/><br/>
                        <Spinner
                            animation='border'
                            role='status'
                            style={{
                                width: '100px',
                                height: '100px',
                                margin: 'auto',
                                display: 'block',
                                color: '#81C14B'
                            }}
                        >
                            <span className='sr-only'>Loading...</span>
                        </Spinner>
                        <br/>
                        </> 
                      ):(
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {filteredTabs?.length >0 ? (
                          filteredTabs.map(tabinfo => (
                            <li className="nav-item" key={tabinfo.id}>
                              <Link 
                                className={tabinfo.className} 
                                id={tabinfo.id} 
                                data-bs-toggle="tab"
                                to={tabinfo.link} 
                                role="tab" 
                                aria-controls={tabinfo.ariaControls} 
                                aria-selected={tabinfo.ariaSelected}
                              >
                                {tabinfo.TabName}
                              </Link>
                            </li>
                          ))
                        ):(
                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '15vh', // Adjust the height as needed to fill the available space
                            width: '100%' // Ensure it takes the full width of the container
                          }}>
                          <h2 style={{ textAlign: 'center' }}>No Groups</h2>
                        </div>
                        )}
                      
                      </ul>
                      )}
                      
                      <div class="tab-content" id="nav-tabContent">
                      {count>0 && (
                        <div className="tab-pane fade show active" id="nav-Owned" role="tabpanel" aria-labelledby="OwnedTab">
                          <br />
                          <OwnedGrpSection  type={type} userId={userId} count={count} user={user} setUpdateCount={setUpdateCount} searchKey={searchKey}/>
                        </div> 
                      )}
                      {count1>0 && (
                        <div className="tab-pane fade" id="nav-Joined" role="tabpanel" aria-labelledby="JoinedTab">
                          <br />
                          <JoinedGrpSection type={type} userId={userId} count={count1} user={user} setUpdateCount={setUpdateCount} searchKey={searchKey}/>
                        </div>
                      )}
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </>
  )
}

export default MyGroups