import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AdmanagerHeaderR from "../../AdmanagerHeaderR/AdmanagerHeaderR";

// Use for snakeBar
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loadAdType } from "../../../../Services/Actions/AdManager/getAdTypeAction";
import { Button, Card, CardBody, CardHeader, CardText, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, Navbar, Popover, PopoverBody, Progress, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import CommonDasboard from "../CommonDasboard";
import CommonHeader from "../CommonHeader";
import SociomeeLog from '../../../../NewComponents/IMG/SociomeeLogo.svg'
import UserPro from '../../../../NewComponents/IMG/Adpreview.svg'
import UserAdPro from '../../../../NewComponents/IMG/user2.png'
import Mic from '../../../../NewComponents/IMG/Mic.png'
import { ChevronsRight, Circle, Info, Maximize2, MoreVertical, Pause, Play, Send, X } from "react-feather";
import PreviewMoreSection from "../AdPreview/Sections/PreviewMoreSection";
import VideoAdpreview from "../AdPreview/Sections/VideoAdpreview";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const VideoAdScreen = () => {
  const types = useLocation();
  const [media, setMedia] = useState("");
  const { adType } = useSelector((state) => state.getTypeData);
  const adTypeData = useMemo(() => {
    return adType.find((ad) => ad.id === types.state.typeId);
  }, [adType]);

  //===================Snackbar Code======================
  const [countMedia, setCountMedia] = useState(0);
  const mediaCountRef = useRef(0);
  const [imageDataUrl, setImageDataUrl] = useState('')
  const [imageGroupUrl, setImageGroupUrl] = useState('')
  const [imageStoryUrl, setImageStoryUrl] = useState('')
  const [imageBizUrl, setImageBizUrl] = useState('')
  const [imageMarketUrl, setImageMarketUrl] = useState('')
  const [imagePodcastUrl, setImagePodcastUrl] = useState('')
  const [imageEventUrl, setImageEventUrl] = useState('')
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ sev: "success", content: "" });
  const [loading, setLoading] = useState(false);
  const [warningOn, setwarningOff] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
    setClicked(!clicked);
  }
  const [mediaData, setMediaData] = useState({
    file: "",
    fileType: "video",
    heading: "",
    subHeading: "",
    callToActionId: "",
  });
  const [adData, setAdData] = useState({
    discriptions: "",
    websiteLink: "",
    adStatus: "READY_TO_START",
    adTypesId: types.state.typeId,
    adSubTypesId: types.state.subTypeId,
    media: [],
  });

  const mediaInputsHandler = (ev) => {
    const { name, value } = ev.target;
    setMediaData({ ...mediaData, [name]: value });
    // const { name, value } = ev.target;
    setMediaData({ ...mediaData, [name]: value });
  };
  const mediaInputsHandlerText = (ev) => {
    const { name, value } = ev.target;
    setMediaData({ ...mediaData, [name]: value });
    // const { name, value } = ev.target;
    setMediaData({ ...mediaData, [name]: value });
  };

  //this function is used for submit details
  const submitDetails = (e) => {
    e.preventDefault();
    if (!mediaData.heading) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Heading !" });
    } else if (!mediaData.subHeading) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Sub-Heading !" });
    } else if (!adData.discriptions) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Enter Description !" });
    } else if (!media?.name) {
      setOpen(true);
      setAlert({ sev: "error", content: "Please Select Video !" });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("files", media);
      formData.append("uploadFor", "ads");
      axios
        .post(`${process.env.REACT_APP_IPURL}/admin/UploadFile`, formData, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data.successResult);
            mediaData.file = res.data.data.successResult[0];
            adData.media = [mediaData];
            console.log(adData);
            axios
              .post(
                `${process.env.REACT_APP_IPURL}/ads/create/adMaster/adType/subTypes/adManager`,
                adData,
                {
                  headers: {
                    Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
                      }`,
                  },
                }
              )
              .then((response) => {
                console.log(response);
                setLoading(false);
                setOpen(true);
                setAlert({
                  sev: "success",
                  content: "Ad Created  Successfully",
                });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setOpen(true);
            setAlert({
              sev: "error",
              content: `${res.data.data.errorResult}`,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const imageUpload = () => {
    document.getElementById("input_file").click();
  };

  // Cancel Snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useLayoutEffect(() => {
    dispatch(loadAdType());
  }, []);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Choose your CTA button");
  function handleBlur(e) {
    console.log(e);
  }
  // const isButtonDisabled = mediaData.heading === '';

  const [buttonText, setButtonText] = useState('Customize your Lead Generation Form');
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    // Change the text and set clicked to true
    setButtonText('Lead Generation Form Customize your Lead Generation Form');
    setClicked(true);
  };
  const [playVideo, setplayVideo] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isOpenA, setIsOpenA] = useState(false)
  const [isSlow, setIsSlow] = useState(false)
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlay = () => {
    const video = videoRef.current;

    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const isButtonDisabled = mediaData.heading === '' || mediaData.subHeading === '' || adData.discriptions === '';
  return (
    <>
      {/* <AdmanagerHeaderR /> */}
      <div >
        <Row style={{ height: 'calc(100vh - 80px)' }} className='rowForrevert shadow rounded-2'>
          <Col xl='2'>
            <Card className='h-100 d-none d-sm-block border-right'>
              <div className="p-4 border text-muted ">
                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted  ">
                <div className='pb-1'><h6>Step 2</h6></div>
                <div className='text-dark'><h5><b>Select Ad Format</b></h5></div>
              </div>
              <div disabled className="p-4 borderLeftForDasboard">
                <div className='pb-1'><h6>Step 3</h6></div>
                <div className=' pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                  <p className={adTypeData?.adTypes === 'Lead Generation' ? '' : 'd-none'} ><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 4</h6></div>
                <div className=' pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                <div className="paddingForStyle">
                  <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                  <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                </div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 5</h6></div>
                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
              </div>
              <div disabled className="p-4 border text-muted disabledbutton ">
                <div className='pb-1'><h6>Step 6</h6></div>
                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
              </div>
            </Card>
          </Col>
          {/*===============Main Card============= */}
          <Col xl='10' className='px-2'>
            <Row className='rowForrevert'>
              <Col xl='12'>
                <CommonHeader />
              </Col>
            </Row>
            <Row className='rowForrevert shadow rounded-2 mt-1'>
              {/*===============Sub-header============ */}
              <Col xl='12'>
                <Card className='border-0 my-1'>
                  <div className="p-3 d-flex justify-content-between">
                    <div className="navigate-left">
                      <p className="navigate-color">
                        {adTypeData?.adTypes} - (
                        {adTypeData?.adMastrerType.name}) / Video Ad
                      </p>
                    </div>
                    <div className="navigate-right col-lg-6">
                      <h4>
                        Preview on
                        <span>
                          <button
                            type="button"
                            class="btn btn-lg btn-size btn-toggle"
                            data-toggle="button"
                            aria-pressed="false"
                            autocomplete="off"
                            onClick={toggle}
                          >
                            <div class="handle"></div>
                          </button>
                        </span>
                      </h4>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className='rowForrevert gap-1 shadow rounded-2  mt-1'>
              {/*============Form Section================ */}
              <Col xl={isOpen ? '8' : '12'} className={isOpen ? 'p-2 card shadow-sm' : 'p-2 card '}>
                <div className="col-lg-12 inputs d-flex p-0 input-img">
                  {/* <div className="row"> */}
                  <div className={isOpen ? "col-lg-12 col-12" : "col-lg-6 col-12"}>
                    {(adTypeData?.adTypes === 'Website Visits' || adTypeData?.adTypes === 'Lead Generation') ? (
                      <>
                        {/* <div>
                          <p className="p-heading">
                            Call to Action (CTA)
                            <span className="pl-1">
                              <img
                                src="/assets/images/adIcon/alert-circle.png"
                                alt="circle"
                              />
                            </span>
                          </p>
                        </div> */}
                        {/* <div className="pb-2">
                          <Input
                            type="select"
                            className="form-control p-2 text-muted"
                            placeholder="Choose your CTA button"
                          >
                            <option>Contact Us</option>
                            <option>Signup</option>
                            <option>Get a quote</option>
                          </Input>
                        </div> */}
                        {/* =========================dropdown==================== */}
                        <div>
                          <div>
                            <p className="p-heading">
                              Call to Action (CTA)
                              <span className="pl-1">
                                <Info fill='#828282' id='callTooltip' color='white' size={18} />
                                <UncontrolledTooltip placement='right' target='callTooltip'>
                                  Add sections to your call to cta action.
                                </UncontrolledTooltip>
                              </span>
                            </p>
                          </div>
                          <div className="dropdownText">
                            <div
                              onClick={(e) => {
                                setIsActive(!isActive);
                              }}
                              className="dropdownText-btn"
                            >
                              <p>{selected}</p>
                              <span
                                className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
                              />
                            </div>
                            <div
                              className="dropdowncon-content"
                              style={{ display: isActive ? "block" : "none" }}
                            >
                              <div
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                                className="item"
                              >
                                Contact Us
                              </div>
                              <div
                                className="item"
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                              >
                                Signup
                              </div>
                              <div
                                className="item"
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                              >
                                Get a quote
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* =========================dropdown end================= */}

                      </>) : null}
                    <div className="mt-2">
                      <p className="p-heading">
                        Ad Heading
                        <span className="pl-1">
                          <Info fill='#828282' id='heTooltip' color='white' size={18} />
                          <UncontrolledTooltip placement='right' target='heTooltip'>
                            Add a catchy heading to engage users!.
                          </UncontrolledTooltip>
                        </span>
                      </p>
                    </div>
                    <div className="">
                      <input
                        type="text"
                        className="form-control p-2"
                        placeholder="Type your Ad's Heading"
                        maxLength={48}
                        name="heading"
                        value={mediaData.heading}
                        onChange={mediaInputsHandler}
                      />
                    </div>
                    <p className="p-max-car">Max 48 Characters</p>

                    <div className="">
                      <p className="p-heading">
                        Ad Sub-Heading
                        <span className="pl-1">
                          <Info fill='#828282' id='subTooltip' color='white' size={18} />
                          <UncontrolledTooltip placement='right' target='subTooltip'>
                            Add sections to your ad campaign with sub heading.
                          </UncontrolledTooltip>
                        </span>
                      </p>
                    </div>
                    <div className="">
                      <input
                        type="text"
                        className="form-control p-2"
                        placeholder="Type your Ad's Heading"
                        maxLength={"60"}
                        name="subHeading"
                        value={mediaData.subHeading}
                        onChange={mediaInputsHandler}
                      />
                    </div>
                    <p className="p-max-car">Max 60 Characters</p>
                  </div>
                  {/* ============add image============ */}
                  <Col className={!isOpen ? '' : 'd-none'} xl='12'>
                    <div className="col-lg-6 col-12">
                      <p className="p-heading Upload-text">Upload Your Video</p>
                      {media?.name ? (
                        <div className="recomandation-display-block position-relative pb-5 Upload-text rounded-2" style={{ position: 'relative' }}>
                          <div style={{ position: 'absolute', top: '44.6%', left: '50.3%', transform: 'translate(-50%, -50%)', backgroundColor: '#000000', opacity: '50%', height: ' 50px', width: ' 50px', borderRadius: '50px' }}>
                            {isPlaying ? <Pause onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} /> : <Play onClick={togglePlay} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />}
                          </div>
                          <video width="100%" height="300" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                            ref={videoRef}
                            // autoPlay
                            onClick={togglePlay}>
                            <source
                              src={URL.createObjectURL(media)}
                              type="video/mp4"
                            />
                          </video>
                          <div className="recom-btn-cont-blk new-recom-btn-cont-blk bottom-0 rounded-1" style={{ paddingTop: '13px' }}>
                            <div className="container ">
                              <div className="row d-flex justify-content-center">
                                <div className="col-4 px-auto border-right">
                                  <h4
                                    className="text-center"
                                    role="button"
                                    onClick={imageUpload}
                                  >
                                    Edit
                                  </h4>
                                </div>
                                <div className="col-4 px-auto border-left">
                                  <h4
                                    className="text-center"
                                    role="button"
                                    onClick={() => setMedia("")}
                                  >
                                    Delete
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div role='button'
                          onClick={imageUpload}
                          className="upload-img w-100 d-flex flex-column justify-content-center align-items-center Upload-text-img"
                        >
                          <img src="/assets/images/adIcon/upload.png" alt="" />
                          <h4 className='mt-2 font-weight-bold'>Upload Video</h4>
                        </div>
                      )}
                      <p className="p-max-car Upload-text">Recommended Video Ratio 4:5</p>
                      <input
                        type="file"
                        name=""
                        id="input_file"
                        hidden
                        onChange={(e) => {
                          setImageGroupUrl(e.target.files[0])
                          // if (mediaCountRef.current === 0) {
                          //   e.target.files[0].type.slice(0, 5) === "video"
                          //     ? setImageDataUrl(e.target.files[0])
                          //     : setMedia("");

                          //   mediaCountRef.current = 1;
                          // } else if (mediaCountRef.current === 1) {
                          //   setImageGroupUrl(e.target.files[0]);
                          //   mediaCountRef.current = 2;
                          // }
                          // else if (mediaCountRef.current === 2) {
                          //   setImageStoryUrl(e.target.files[0]);
                          //   mediaCountRef.current = 3;
                          // } else if (mediaCountRef.current === 3) {
                          //   setImageBizUrl(e.target.files[0]);
                          //   mediaCountRef.current = 4;
                          // } else if (mediaCountRef.current === 4) {
                          //   setImageMarketUrl(e.target.files[0])
                          //   mediaCountRef.current = 5;
                          // }
                          // else if (mediaCountRef.current === 5) {
                          //   setImagePodcastUrl(e.target.files[0])
                          //   mediaCountRef.current = 6;
                          // }
                          e.target.files[0].type.slice(0, 5) === "video"
                            ? setMedia(e.target.files[0])
                            : setMedia("");
                          e.target.files[0].type.slice(0, 5) !== "video" &&
                            setOpen(true);
                          setAlert({
                            sev: "error",
                            content: "Please Select Video Only !",
                          });
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                      />
                    </div>
                    {/* </div> */}
                  </Col>
                </div>

                {adTypeData?.adTypes === 'Website Visits' ?
                  <Row className='rowForrevert m-1 pl-1'>
                    <Col xl='12'>
                      <div className="textarea px-2 py-2">
                        <div className="">
                          <p className="p-heading">
                            Provide Web link
                            <span className="pl-1">
                              <Info fill='#828282' id='ProvideTooltip' color='white' size={18} />
                              <UncontrolledTooltip placement='right' target='ProvideTooltip'>
                                provide your web link url.
                              </UncontrolledTooltip>
                            </span>
                          </p>
                        </div>
                        <div className="">
                          <input
                            type="text"
                            className="form-control p-2"
                            placeholder="Enter your web link here..."
                            maxLength={"60"}
                            name="subHeading"
                            value={mediaData.subHeading}
                            onChange={mediaInputsHandler}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row> : null}
                <Row className='rowForrevert'>
                  {/*==================Discription Box================== */}
                  <Col xl='12'>
                    <div className="textarea px-2 py-2" style={{ marginLeft: '6px' }}>
                      <div className="">
                        <p className="p-heading">
                          Ad Description
                          <span className="pl-1">
                            <Info fill='#828282' id='descTooltip' color='white' size={18} />
                            <UncontrolledTooltip placement='right' target='descTooltip'>
                              Describe ad campaign to your target audience.
                            </UncontrolledTooltip>
                          </span>
                        </p>
                      </div>
                      <div className="">
                        <textarea
                          name="discriptions"
                          className="form-control"
                          rows="5"
                          placeholder="Enter your ad description here..."
                          maxLength={"300"}
                          value={adData.discriptions}
                          onChange={(e) =>
                            setAdData({ ...adData, discriptions: e.target.value })
                          }
                        ></textarea>
                      </div>
                      <p className="p-max-car">Max 300 Characters</p>
                    </div>
                  </Col>
                </Row>
                <Row className='rowForrevert mt-1'>
                  {/* Col */}
                  <div sm={!isOpen ? '6' : '12'} className={adTypeData?.adTypes === 'Lead Generation' ? '' : 'd-none'}>
                    <div className="d-flex justify-content-between m-2">
                      <div>
                        <div style={{ marginLeft: '19px' }}>
                          {!clicked && (
                            <Link to='/Ad/LeadGeneration'>
                              <Button
                                tag='a'
                                style={{
                                  position: 'absolute',
                                  marginTop: '43px',
                                  marginRight: '13px',
                                  color: clicked ? '#81c14b' : '#81c14b',
                                  backgroundColor: clicked ? 'white' : 'white',
                                  border: `1px solid ${clicked ? '#81c14b' : '#81c14b'}`
                                }}
                              // onClick={handleClick}
                              // readOnly
                              >
                                Customize your Lead Generation Form
                              </Button></Link>
                          )}
                          {clicked && <span style={{ color: '#4D4D4D', fontSize: '15px' }}>Lead Generation Form Customize your Lead Generation Form</span>}
                        </div>
                      </div>
                      <div>
                        {clicked && (
                          <Button tag='a' href='/Ad/LeadGeneration' color='primary' outline style={{ marginRight: '13px' }}>
                            Create Form
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div xl={adTypeData?.adTypes === 'Lead Generation' ? '6' : '12'} className={!isOpen ? '' : 'd-none'} >
                    {/* <div className="ad-btn">
                      <Link to="" className="btn-cancel">
                        Cancel
                      </Link>
                      <Button
                        className="btn-next ml-2 without-input-fill"
                        onClick={submitDetails}
                        disabled={
                          !adData.discriptions ||
                            !mediaData.heading ||
                            !mediaData.subHeading ||
                            !media?.name
                            ? true
                            : false
                        }
                      >
                        Next
                      </Button>
                    </div> */}
                    <div className="p-3">
                      <div className="ad-btn">
                        <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2" >
                          Back
                        </Button>
                        <Button
                          id='controlledPopover'
                          color='primary'
                          size='md'
                          className={`ml-2 ${isButtonDisabled ? 'disabled-button' : ''}`}
                          style={{
                            backgroundColor: isButtonDisabled ? 'rgb(172, 218, 214)' : '#81C14B',
                            cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                            border: 'none',
                            outline: 'none',
                            color: 'white'
                          }}
                          onClick={() => {
                            if (!isButtonDisabled) {
                              // Existing logic
                              // navigate('/Ad/ChooseAudience');

                              // Additional logic
                              navigate('/Ad/ChooseAudience');
                            }
                          }}
                          disabled={isButtonDisabled}
                        >
                          Next
                        </Button>
                        <Popover
                          placement='top'
                          target='controlledPopover'
                          isOpen={warningOn}
                          toggle={() => setwarningOff(!warningOn)}
                        >
                          {/* <PopoverBody>
                            <div className="text-muted">
                              Your Ad Configuration cannot be changed once you save. Do you want to save?
                            </div>
                            <div className="text-center">
                              <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                                Cancel
                              </Button>
                              <Button onClick={() => navigate('/Ad/ChooseAudience')} color='primary' size='sm' className="px-3 py-1">
                                Save
                              </Button>
                            </div>
                          </PopoverBody> */}
                        </Popover>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              {/* Preview Section */}
              <Col className={!isOpen ? 'd-none' : 'p-2 card shadow-sm'}>
                <Collapse horizontal isOpen={isOpen}>
                  {/* <PreviewMoreSection /> */}
                  <VideoAdpreview imageDataUrl={imageDataUrl} imageGroupUrl={imageGroupUrl} imageStoryUrl={imageStoryUrl} imageBizUrl={imageBizUrl} heading={mediaData.heading} subHeading={mediaData.subHeading} media={media} discriptions={adData.discriptions} />
                </Collapse>
              </Col>
            </Row>
            <Row className={!isOpen ? 'd-none' : 'rowForrevert p-2 card shadow-sm'}>
              <Col xl='12'>
                {/* <div className="ad-btn">
                  <Link to="" className="btn-cancel">
                    Cancel
                  </Link>
                  <Button
                    className="btn-next ml-2 without-input-fill"
                    onClick={submitDetails}
                    disabled={
                      !adData.discriptions ||
                        !mediaData.heading ||
                        !mediaData.subHeading ||
                        !media?.name
                        ? true
                        : false
                    }
                  >
                    Next
                  </Button>
                </div> */}
                <div className="p-3">
                  <div className="ad-btn">
                    <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                      Back
                    </Button>
                    <Button id='controlledPopover' color='primary' size='md' className="ml-2" onClick={() => navigate('/Ad/ChooseAudience')} >
                      Next
                    </Button>
                    {/* <Popover
                      placement='top'
                      target='controlledPopover'
                      isOpen={warningOn}
                      toggle={() => setwarningOff(!warningOn)}
                     
                    >
                      <PopoverBody> */}
                    {/* <div className="text-muted">
                          Your Ad Configuration cannot be changed once you save. Do you want to save?
                        </div> */}
                    {/* <div className="text-center">
                          <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                            Cancel
                          </Button>
                          <Button color='primary' size='sm' className="px-3 py-1">
                            Save
                          </Button>
                        </div> */}
                    {/* </PopoverBody> */}
                    {/* </Popover> */}
                  </div>
                </div>
              </Col>
            </Row>
            {loading && <Loader loading={loading} />}
          </Col>
        </Row>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }} id="stack">
        {/*===================Snackbar================= */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={alert.sev}
            sx={{ width: "100%" }}
          >
            {alert.content}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default VideoAdScreen;
