import React from "react";
import { Fragment, useState } from "react";
import { ChevronRight } from "react-feather";
import {
  Button,
  CardBody,
  CardTitle,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Request from "../Img/Request.png";
import Request1 from "../Img/Request1.png";
import Request2 from "../Img/Request2.png";
import Request3 from "../Img/Request3.png";
import moment from "moment";
import MyGroupMember from "./MyGroupMember";

// import { Switch } from 'antd'
import "./tglSwitchAntd.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { sweetAlertConfirmation } from "../../group-components/AllGroupsSections/SweetAlert";
import { SweetErrorAlert } from "../../group-components/AllGroupsSections/SweetErrorAlert";
import { useNavigate } from "react-router-dom";
import {
  postPinToGroupAction,
  getPinFromGroupAction,
  deleteGroupByPasswordAction,
  deleteGroupByUserAction,
  grpReviewPostAction,
  grpSettingsAllInOneAction,
  grpReviewMemberAction,
  grpSharedToStoriesAction,
  updateGroupPostByIdAction,
  loadAllSuggestedGroup,
  getAllGroupCategoryAction,
  getAllGeneralNotificationSettingsAction,
  getAllGeneralNotificationAction,
  groupActivityHistoryAction,
  getallGroupsAdminsEct,
} from "../../../Services/Actions/Group/getAllGroupMainPageAction";
import { Checkbox } from "@mui/material";
import NotificationSetting from "./NotificationSetting";
import GroupInfoSettings from "./GroupInfoSettings";

const MySettings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const grpId = params.id;
  const dispatch = useDispatch();
  const { postPinToGroup } = useSelector((state) => {
    return state.postPinToGroupData;
  });
  const { getPinFromGroup } = useSelector((state) => {
    return state.getPinFromGroupData;
  });
  const { getAllGroupAdmins } = useSelector((state) => {
    return state.getAllGroupAdminAndMembersData;
  });
  const { getAllGroupMembersData } = useSelector((state) => {
    return state.getAllGroupMembersData;
  });
  const { deleteGroupByPassword } = useSelector((state) => {
    return state.deleteGroupByIdData;
  });
  const { deleteGroupByUser } = useSelector((state) => {
    return state.deleteGroupByUserData;
  });
  const { groupSettingsAll } = useSelector((state) => {
    return state.groupSettingsAllInOneData;
  });
  const { groupReviewSettings } = useSelector((state) => {
    return state.groupReviewPostReducerData;
  });
  const { groupReviewMember } = useSelector((state) => {
    return state.groupReviewMemberData;
  });
  const { sharedToStoriesReducer } = useSelector((state) => {
    return state.groupSharedToStoriesData;
  });
  const { getAllGroupCategory } = useSelector((state) => {
    return state.getAllCategoryFromGroupData;
  });
  const { updateGroupPostReducer, error } = useSelector((state) => {
    return state.updateGroupPostData;
  });
  const {
    getAllGeneralNotificationSettingsReducer,
    getAllGeneralNotificationSettingsError,
  } = useSelector((state) => {
    return state.getAllGeneralNotificationData;
  });
  const {
    socioMeeNotificationSettingsReducer,
    socioMeeNotificationSettingsError,
  } = useSelector((state) => {
    return state.socioMeeNotificationSettingsData;
  });
  const { grpActivityHistorySuccess, grpActivityHistoryError } = useSelector(
    (state) => {
      return state.groupActivityHistoryData;
    }
  );
  // let initialPinVal = getPinFromGroup?.data?.successResult?.isPinned
console.log('getAllGroupAdmins', getAllGroupAdmins)
  useEffect(() => {
    setGrpPostReview(getAllGeneralNotificationSettingsReducer?.isPostReviewed);
    setMemberReview(getAllGeneralNotificationSettingsReducer?.isMemberReviewed);
    setShareStory(
      getAllGeneralNotificationSettingsReducer?.isPostSharedToStory
    );
    setPinon(getAllGeneralNotificationSettingsReducer?.visibility);
  }, [getAllGeneralNotificationSettingsReducer]);

  const updatePost = () => {
    dispatch(updateGroupPostByIdAction(grpId, groupInfo));
    if (error) {
      SweetErrorAlert("All fields are mondatory");
    } else {
      sweetAlertConfirmation("Updated Successfully");
      navigate("/Group");
    }
  };
  let [isPinned, setIsPinned] = useState(
    `${getPinFromGroup?.data?.successResult?.isPinned}`
  );
  const [successResults, setSuccessResult] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [role, setRole] = useState("");
  const [category, setCategory] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupName, setGroupName] = useState("");

  const [pinOn, setPinon] = useState();

  const [grpPostReview, setGrpPostReview] = useState();
  const [memberReview, setMemberReview] = useState();
  const [shareStory, setShareStory] = useState();

  const [isCheckBoxEnable, setCheckBoxEnable] = useState();

  let groupInfo = {
    groupName: groupName,
    description: groupDescription,
    category: category,
    privacy: privacy,
  };

  useEffect(() => {
    dispatch(loadAllSuggestedGroup());
  }, []);

    useEffect(() => {
    dispatch(getallGroupsAdminsEct(grpId));
  }, []);

  useEffect(() => {
    dispatch(getAllGroupCategoryAction());
  }, []);

  useEffect(() => {
    dispatch(getAllGeneralNotificationSettingsAction(grpId));
  }, []);

  useEffect(() => {
    dispatch(getAllGeneralNotificationAction(params.id));
  }, []);

  const onCategory = (event) => {
    const value = event.target.value;
    setCategory(value);
  };

  useEffect(() => {
    if (deleteGroupByPassword === "correctPassword") {
      setConfirmPassword(true);
    } else if (deleteGroupByPassword === "incorrectPassword") {
      setConfirmPassword(false);
    }
  }, [deleteGroupByPassword]);

  const [settingActivneTab, setSettingActiveTab] = useState("1");
  const toggleSetting = (tab) => {
    dispatch(groupActivityHistoryAction(params.id, "0", "20"));
    if (settingActivneTab !== tab) setSettingActiveTab(tab);
  };
  const [deleteOpen, setDeleteOpen] = useState(false);
  const toggleDelete = () => {
    setDeleteOpen((prevState) => !prevState);
  };
  const [forgetOpen, setForgetOpen] = useState(false);
  const toggleForget = () => {
    setDeleteOpen(false);
    setForgetOpen((prevState) => !prevState);
  };

  const confirmDelete = () => {
    dispatch(deleteGroupByPasswordAction(passwordValue));
    setDeleteOpen(false);

    if (confirmPassword) {
      SweetErrorAlert("Wrong Password");
    } else {
      dispatch(deleteGroupByUserAction(grpId));
      sweetAlertConfirmation("Deleted Successfully");
      navigate("/Group");
    }
  };

  const changeText = (e) => {
    setPasswordValue(e.target.value);
  };

  const togglePin = (grpType) => {
    dispatch(grpSettingsAllInOneAction(grpId, grpType, pinOn));
    sweetAlertConfirmation(
      privacy
        ? `Your group visibility is "OFF" `
        : `Your group visibility is "ON"`
    );
    pinOn ? setPinon(false) : setPinon(true);
    // setPinon((prevState) => !prevState)
  };

  const groupPrivacyToggle = (grpType) => {
    dispatch(grpReviewPostAction(grpId, grpType, grpPostReview));
    sweetAlertConfirmation(
      grpPostReview
        ? 'Your post reviewed "OFF" '
        : 'Your Your post reviewed "ON"'
    );
    grpPostReview ? setGrpPostReview(false) : setGrpPostReview(true);
    // setPinon((prevState) => !prevState)
  };

  const reviewMemberToggle = (grpType) => {
    dispatch(grpReviewMemberAction(grpId, grpType, memberReview));
    sweetAlertConfirmation(
      groupReviewMember?.mode
        ? 'Review request send "OFF" '
        : 'Review request send "ON"'
    );
    memberReview ? setMemberReview(false) : setMemberReview(true);
    // setPinon((prevState) => !prevState)
  };

  const shareStoryToggle = (grpType) => {
    dispatch(grpSharedToStoriesAction(grpId, grpType, shareStory));
    sweetAlertConfirmation(
      sharedToStoriesReducer?.mode
        ? 'Post share to stories "OFF" '
        : 'Post share to stories"ON"'
    );
    shareStory ? setShareStory(false) : setShareStory(true);
    // setPinon((prevState) => !prevState)
  };

  const handleChange = (event) => {
    setPinon(event.target.value);
  };

  useEffect(() => {
    dispatch(getPinFromGroupAction(params.id));
  }, []);

  const Toggler = () => {
    isPinned ? setIsPinned(false) : setIsPinned(true);
    dispatch(postPinToGroupAction(params.id, params.grpAdminId, isPinned));

    setSuccessResult(postPinToGroup && postPinToGroup?.data?.successResult);

    if (successResults === "Success" && isPinned === true) {
      sweetAlertConfirmation("Un Pinned the group Successfully");
    } else if (successResults === "Success" && isPinned === false) {
      sweetAlertConfirmation("Pin the group successfully");
    } else {
    }
    useEffect(() => {
      dispatch(postPinToGroupAction(params.id, params.grpAdminId, isPinned));
    }, []);
  };

  const GeneralSettingPreviewData = () => (
    <Fragment>
      <div className="custom-card-block">
        <div className="setting-card-head biz-carddetail-blk">
          <h4>General Settings </h4>
        </div>
        <CardBody className="custom-card-body">
          <div className="biz-main-about-blk ">
            <div className="Setting_Section">
              <div>
                <h5>Pin Page </h5>
                <small>
                  your pinned page will appear on top of your page list
                </small>
              </div>
              <div>
                {isPinned ? (
                  <p className="text-capitalize">
                    Pin “{params.groupName} Group”
                  </p>
                ) : (
                  <p className="text-capitalize">
                    UnPin “{params.groupName} Group”
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div c>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isPinned}
                    onChange={Toggler}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className="biz-main-about-blk ">
            <div className="Setting_Section">
              <div>
                <h5>
                  Group Visibility{" "}
                  {pinOn ? (
                    <span className="text-success">ON</span>
                  ) : (
                    <span className="text-danger">OFF</span>
                  )}{" "}
                </h5>
                <small>
                  Your default Group visibility is{" "}
                  <span className="text-danger">{`${getAllGeneralNotificationSettingsReducer?.privacy}`}</span>
                  . Turn off visibility to make your Group appear only to
                  members with a role in the Group.
                </small>
              </div>
              <div>
                <p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={pinOn}
                      onChange={() => {
                        togglePin("visibility");
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </p>
              </div>
            </div>
          </div>
          <div className="biz-main-about-blk ">
            <div className="Setting_Section">
              <div>
                <h5>Review Post </h5>
                <small>
                  Review all posts by members before it gets posted.
                </small>
              </div>
              <p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={grpPostReview}
                    onChange={() => {
                      groupPrivacyToggle("reviewMember");
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </p>
            </div>
          </div>
          <div className="biz-main-about-blk ">
            <div className="Setting_Section">
              <div>
                <h5>Review Member </h5>
                <small>Review Group join requests.</small>
              </div>
              <p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={memberReview}
                    onChange={() => {
                      reviewMemberToggle("postReview");
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </p>
            </div>
          </div>
          <div className="biz-main-about-blk ">
            <div className="Setting_Section">
              <div>
                <h5>Group post shares to stories </h5>
                <small>
                  Turn off to prohibit sharing of Group posts to personal
                  profile stories
                </small>
              </div>
              <p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={shareStory}
                    onChange={() => {
                      shareStoryToggle("postReview");
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </p>
            </div>
          </div>
          <div className="biz-main-about-blk ">
            <p>
              {getAllGroupAdmins &&
              getAllGroupAdmins[0].role === "superAdmin" ? (
                <div className="Setting_Section">
                  <div>
                    <h5>Delete Group </h5>
                  </div>
                  <p>
                    <Button onClick={toggleDelete} color="danger" outline>
                      Delete “Group Name” permanently
                    </Button>
                  </p>
                </div>
              ) : (
                <div className="Setting_Section"></div>
              )}
            </p>
          </div>
        </CardBody>
      </div>
    </Fragment>
  );

  const RoleSettingPreviewData = () => (
    <Fragment>
      <div className="custom-card-block">
        <div className="setting-card-head biz-carddetail-blk">
          <h4>Role Settings </h4>
        </div>
        <Row className="group-detail-page-blk mt-3">
          <Col md="12" sm="12">
            {/* <MyGroupMember /> */}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
  const GroupActivitySettingPreviewData = () => (
    <Fragment>
      <div className="custom-card-block">
        <div className="setting-card-head ">
          <h4>Members</h4>
          <p className="mt-2">
            This is a history of actions taken by people who manage this page.
          </p>
        </div>
        <div className="group-detail-page-blk mt-2">
          <Row>
            {grpActivityHistorySuccess === "NoHistoryFound" ? (
              <Col md="12" sm="12">
                <div className="group-members-block">
                  <div className="group-member-img-cont-blk">
                    <div className="member-cont-blk">
                      <h4>No history found !</h4>
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              grpActivityHistorySuccess?.map((data) => (
                <Col lg="6" md="6" sm="12">
                  <div className="group-members-block">
                    <div className="group-member-img-cont-blk">
                      <img src={data?.profileImageThumb} alt="img" />
                      <div className="member-cont-blk">
                        <h4>{data?.fullName}</h4>
                        <p>{data?.eventKey}</p>
                        <p>
                          {moment(data?.historyDate).format("MMM Do YYYY")}
                          &nbsp;{" "}
                          {moment(data?.historyDate).format("hh:mm:ss a")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      </div>
    </Fragment>
  );

  const GroupInfoSettingPreviewData = () => (
    <Fragment>
      <div className="custom-card-block">
        <div className="setting-card-head biz-carddetail-blk">
          <h4>Group Info Settings </h4>
        </div>
        <CardBody className="custom-card-body">
          <div className="biz-main-about-blk ">
            <Row>
              <Col md="6">
                <Form>
                  <FormGroup>
                    <Label className="GroupName_Label_Section" for="GroupName">
                      <h4>Group Name</h4>
                    </Label>
                    <Input
                      placeholder="Enter your group name"
                      id="groupName"
                      type="text"
                      name="groupName"
                      onChange={(e) => setGroupName(e.target.value)}
                      value={groupName}
                    />

                    <p className="validation text-muted">Max 60 charectors </p>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className="GroupName_Label_Section"
                      for="selectNameexample"
                    >
                      <h4>Group Category</h4>
                    </Label>
                    <Input
                      id="selectNameexample"
                      name="selectName"
                      type="select"
                      className="Create_GroupInfo_Input w-100"
                      onChange={onCategory}
                    >
                      <option>Select Group Category</option>
                      {getAllGroupCategory?.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="radio1"
                      type="radio"
                      className="public_Check"
                      onChange={(e) => setPrivacy(e.target.value)}
                      value="public"
                    />{" "}
                    <Label className="Check_Label_Section">
                      <h4>Public </h4>
                      <p>Anyone can your Group’s post and its member </p>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="radio1"
                      type="radio"
                      className="Private_Check"
                      onChange={(e) => setPrivacy(e.target.value)}
                      value="private"
                    />{" "}
                    <Label className="Private_Label_Section">
                      <h4>Private </h4>
                      <p>
                        Only group members can view post and other members of
                        your group
                      </p>
                    </Label>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="text" className="GroupName_Label_Section">
                    <h4>Description </h4>
                  </Label>
                  {/* <Input
                                        type="textarea"
                                        name="text"
                                        id="text"
                                        placeholder="Description is the..."
                                    /> */}
                  <textarea
                    placeholder="Enter your Description"
                    className="form-control"
                    name=""
                    id=""
                    cols="15"
                    rows="5"
                    onChange={(e) => setGroupDescription(e.target.value)}
                    value={groupDescription}
                  />
                  <p className="Descriptionvalidation">Max 180 Characters </p>
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <Button outline className="Group_Cancle_btn">
                    <p>Cancel</p>
                  </Button>
                  <Button
                    color="primary"
                    className="Group_Update_btn ml-3"
                    onClick={() => {
                      updatePost();
                    }}
                  >
                    <p>Update</p>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </div>
    </Fragment>
  );
  // Main_Return_Function
  return (
    <Fragment>
      <Row>
        <Col md="4">
          <div className="custom-card-block">
            <div className="setting-card-head biz-carddetail-blk">
              <h4>Group Settings </h4>
            </div>
            <div className="setting_section">
              <ul>
                <li
                  onClick={() => {
                    toggleSetting("1");
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>General</span>
                    <ChevronRight size={20} />
                  </div>
                </li>
                <li
                  onClick={() => {
                    toggleSetting("2");
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>Group Info</span>
                    <ChevronRight size={20} />
                  </div>
                </li>
                <li
                  onClick={() => {
                    toggleSetting("3");
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>Notification </span>
                    <ChevronRight size={20} />
                  </div>
                </li>
                <li
                  onClick={() => {
                    toggleSetting("4");
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>Page Roles </span>
                    <ChevronRight size={20} />
                  </div>
                </li>
                <li
                  onClick={() => {
                    toggleSetting("5");
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>Members(followers & request)</span>
                    <ChevronRight size={20} />
                  </div>
                </li>
                <li
                  onClick={() => {
                    toggleSetting("6");
                  }}
                >
                  <div className=" d-flex justify-content-between align-items-center">
                    <span>Group Activity History </span>
                    <ChevronRight size={20} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col md="8">
          <TabContent activeTab={settingActivneTab}>
            <TabPane tabId="1">
              <GeneralSettingPreviewData />
            </TabPane>
            <TabPane tabId="2">
              {/* <GroupInfoSettingPreviewData /> */}
              <GroupInfoSettings />
            </TabPane>
            <TabPane tabId="3">
              {/* <NotificationSettingPreviewData /> */}
              <NotificationSetting />
            </TabPane>
            <TabPane tabId="4">
              {/* <RoleSettingPreviewData /> */}
              <MyGroupMember />
            </TabPane>
            <TabPane tabId="5">members</TabPane>
            <TabPane tabId="6">
              <GroupActivitySettingPreviewData />
              {/* <GroupInfoSettings /> */}
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      {deleteOpen !== false && (
        <Modal
          isOpen={deleteOpen}
          toggle={toggleDelete}
          className="modal-dialog-centered"
          scrollable
        >
          <ModalHeader className="Delete_Modal_Heading_section">
            <h4>Delete “Group Name” page </h4>
          </ModalHeader>
          <ModalBody>
            <div className="mt-2">
              <Form>
                <FormGroup>
                  <Label
                    className="GroupName_Label_Section py-2"
                    for="GroupName"
                  >
                    <h4>Enter your password</h4>
                  </Label>
                  <Input
                    id="GroupName"
                    name="GroupName"
                    placeholder="Enter your password"
                    type="Group"
                    className="w-100"
                    value={passwordValue}
                    onChange={changeText}
                  />
                  <p onClick={toggleForget} className="Forgot_Section py-3">
                    Forgot Password?{" "}
                  </p>
                </FormGroup>
              </Form>
            </div>
            <div className="group-buttons-blk mt-3">
              <Button className="Invite_Decline_Button " outline>
                Cancel
              </Button>
              <Button
                className="Invite_Join_Button ml-2"
                color="primary"
                onClick={() => {
                  confirmDelete(passwordValue);
                }}
              >
                Confirm?
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <div className="Forget_Password_Modal">
        {forgetOpen !== false && (
          <Modal
            isOpen={forgetOpen}
            toggle={toggleForget}
            className="modal-dialog-centered"
            scrollable
          >
            <ModalHeader className="Delete_Modal_Heading_section">
              <h4>Delete “Group Name” page </h4>
            </ModalHeader>
            <ModalBody className="text-center">
              <div>
                <h4>Enter OTP</h4>
              </div>
              <div>
                <span>
                  An OTP has been sent to your registered phone
                  <br />
                  number ending with
                  <br />
                  XXX XXX 8797
                </span>
              </div>
              <div className="d-flex justify-content-center">
                <div className="ForgetInput mr-5">
                  <Input
                    maxLength="1"
                    name="otp"
                    type="text"
                    placeholder="-"
                    autoComplete="off"
                  />
                </div>
                <div className="ForgetInput">
                  <Input
                    maxLength="1"
                    name="otp"
                    type="text"
                    placeholder="-"
                    autoComplete="off"
                  />
                </div>
                <div className="ForgetInput">
                  <Input
                    maxLength="1"
                    name="otp"
                    type="text"
                    placeholder="-"
                    autoComplete="off"
                  />
                </div>
                <div className="ForgetInput">
                  <Input
                    maxLength="1"
                    name="otp"
                    type="text"
                    placeholder="-"
                    autoComplete="off"
                  />
                </div>
                <div className="ForgetInput">
                  <Input
                    maxLength="1"
                    name="otp"
                    type="text"
                    placeholder="-"
                    autoComplete="off"
                  />
                </div>
                <div className="ForgetInput">
                  <Input
                    maxLength="1"
                    name="otp"
                    type="text"
                    placeholder="-"
                    autoComplete="off"
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    </Fragment>
  );
};

export default MySettings;
