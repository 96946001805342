import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Circle, Folder, Grid, MoreHorizontal } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Popover, PopoverBody, Row } from 'reactstrap';
import { getSingleAdTypeOfType, loadAdType } from '../../../../Services/Actions/AdManager/getAdTypeAction';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AdTypeScreen = () => {
    const { adType } = useSelector((state) => state.getTypeData);
    const { singleTypeOfType } = useSelector(state => state.getTypeData)
    const [singleTypeId, setSingleTypeId] = useState({ id: '', format: '' })
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const adTypeData = useMemo(() => {
        return adType.find(ad => ad.id === id)
    }, [adType])

    // 
    const [state, setState] = useState({});
    const [warningOn, setwarningOff] = useState(false)

    const submitHandler = (e) => {
        e.preventDefault();
        if (!singleTypeId.id) {
            alert("Please Select Sub Type");
        } else {
            navigate(`/Ad/Display/${singleTypeId.format}`, { state: { typeId: adTypeData.id, subTypeId: singleTypeId.id } });
        }
    }

    useEffect(() => {
        setState({ ...singleTypeOfType })
        console.log("====================>",singleTypeOfType)
    }, [singleTypeOfType])

    // TAD YPE OF TYPES
    useLayoutEffect(() => {
        dispatch(getSingleAdTypeOfType(id))
        dispatch(loadAdType());
    }, [id])

    // CANCEL
    const logoutUser = () => {
        setOpen(false);
        navigate("/Dashboard");
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            {/* <AdmanagerHeaderR /> */}

            <div>
                <Row style={{ height: 'calc(100vh - 80px)' }} className='rowForrevert gap-1'>
                    <Col xl='2' className="d-none d-sm-block">
                        <Card className='d-none d-sm-block border-right'>
                            <div className="p-4 text-muted border">
                                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
                                {/* <div className='text-dark'><p>Choose Campaign Type (e.g., Display, Video, App, etc.)</p></div> */}
                            </div>
                            <div disabled className="p-4 borderLeftForDasboard">
                                <div className='pb-1'><h6>Step 2</h6></div>
                                <div className=''><h5><b>Select Ad Format</b></h5></div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 3</h6></div>
                                <div className=' pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                                <div className="paddingForStyle">
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                                </div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 4</h6></div>
                                <div className=' pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                                <div className="paddingForStyle">
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                                </div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 5</h6></div>
                                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 6</h6></div>
                                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
                            </div>
                        </Card>
                    </Col>

                    <Col>



                        {/* <Card className='border-0'>
                            <div className="d-flex">
                                <div className="d-flex align-items-center p-2 border-right">
                                    <button className="ad-count-btn responsiveForTag">2</button>
                                    <p className="ad-count-step px-3">Step 2</p>
                                </div>
                                <div className="p-2 pl-3">
                                    <h4>Choose Ad Display Format</h4>
                                    <p className="text-muted">Select the best format to display your Ad.</p>
                                </div>
                            </div>
                        </Card> */}
                        <Row className='rowForrevert shadow rounded-2'>
                            <Col xl='12'>
                                <Card className='border-0'>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center p-2 border-right">
                                            <button className="ad-count-btn">2</button>
                                            <p className="ad-count-step px-3">Step 2</p>
                                        </div>
                                        <div className="p-2 pl-3">
                                            <h4>Choose Ad Display Format</h4>
                                            <p className="text-muted">Select the best format to display your Ad.</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>


                        <div className="col-lg-12 my-1 shadow rounded-2 ">
                            <div className="row ad-types-of-type-map">
                                <div className='p-3 ml-1'>
                                    <h4 className='text-primary' >{adTypeData?.adTypes} - ({adTypeData?.adMastrerType.name})</h4>
                                </div>
                                {
                                    singleTypeOfType.successResult?.map(({ id, name, file, note, adFormat }, index) => {
                                        return (
                                            <div className={'col-lg-6 pad-zero '} key={id}>
                                                <div>
                                                    <div className={`ad-type d-flex ad-types-type AdHov  border ${singleTypeId.id === id && 'border-primary borderForADSelection'}`} onClick={() => { setSingleTypeId({ id: id, format: adFormat });
                                                    localStorage.setItem('visitCount', index);
                                                 }} role='button'>
                                                        <div className='mr-3'>
                                                            <img src={file}
                                                                alt="ad"
                                                                className='ch-150'
                                                            />
                                                        </div>
                                                        <div className='ad-types-type-dec'>
                                                            <h4 className='type mt-2'>{name}</h4>
                                                            <p className='dec-types mt-2 pad-zero'>{note}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <Row className='rowForrevert'>
                                <Col xl='12'>
                                    <div className='p-3'>
                                        <div className="ad-btn">
                                            <Button onClick={() => navigate(-1)} outline color='secondary' size='md' className="ml-2">
                                                Back
                                            </Button>
                                            <Button
                                                disabled={!singleTypeId.id}
                                                id="controlledPopover"
                                                color="primary"
                                                size="md"
                                                className="ml-2"
                                                onClick={submitHandler}
                                                style={{
                                                    backgroundColor: !singleTypeId.id ? 'rgba(172, 218, 214, 1)' : 'rgba(129, 193, 75, 1)',
                                                    color: 'white',
                                                    border: '',
                                                }}
                                            >
                                                Next
                                            </Button>
                                            {/* <Popover
                                                placement='top'
                                                target='controlledPopover'
                                                isOpen={warningOn}
                                                toggle={() => setwarningOff(!warningOn)}
                                            >
                                                <PopoverBody>
                                                    <div className="text-muted">
                                                        Your selected Ad format cannot be changed once you save. Do you want to save?
                                                    </div>
                                                    <div className="text-center">
                                                        <Button outline onClick={() => setwarningOff(false)} color='primary' size='sm' className="mr-2 p-2 py-1">
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={submitHandler} color='primary' size='sm' className="px-3 py-1">
                                                            Save
                                                        </Button>
                                                    </div>
                                                </PopoverBody>
                                            </Popover> */}
                                        </div>
                                    </div>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title text-center">
                                            {
                                                "OOPS! You just stopped Do you really want to discard this AD?"
                                            }
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description"></DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>No</Button>
                                            <Button onClick={logoutUser}>Yes</Button>
                                        </DialogActions>
                                    </Dialog>
                                </Col>
                            </Row>


                        </div>

                        {/* <div className='col-lg-12'>
                            <div className="ad-btn">
                                <Link to="/Ad/LeadGeneration" className="btn-cancel">
                                    Cancel
                                </Link>
                                <button className='btn-next without-input-fill ml-2' disabled={!singleTypeId.id ? true : false} onClick={submitHandler}>
                                    Next
                                </button>
                            </div>
                        </div> */}
                    </Col>

                </Row>
            </div>
        </>
    )
}

export default AdTypeScreen