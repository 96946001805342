import React from 'react'
import NewFeed from './Social-Feed-Component/NewFeed'

const Home = () => {
  return (
    <div>
      <NewFeed />
    </div>
  )
}

export default Home 