import { Checkbox } from '@mui/material'
import React from 'react'
import { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import { MediaContentData } from './MediaArray'


const MediaContent = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    //================== Main_return_function =============//
    return (
        <Fragment>
            <div className='SettingPrivacy_Main_section'>
                <div className='hide-setting-options'>
                    <h3>Media & Content </h3>
                </div>
                <div className='SettingPrivacy_Card_Body'>
                    {MediaContentData.map((data) => (
                        <div className='SettingPrivacy_Content_Section'>
                            <div className='SettingPrivacy_SubContent_Section '>
                                <div>
                                    <h4>{data.heading}</h4>
                                    <p>{data.subContent}</p>
                                    {data.typeof === "mediaQuality" ?
                                        <Fragment>
                                            <Row className='Media_content_section Events_PeopleThisGrid '>
                                                <Col className="w-100 text-center">
                                                    <img src={data.img} className="High-Quality" alt='media_content' />
                                                    <h4>High Quality </h4>
                                                </Col>
                                                <Col className="w-100 text-center">
                                                    <img src={data.img} className="Medium-Quality" alt='media_content' />
                                                    <h4>Medium </h4>
                                                </Col>
                                                <Col className="w-100 text-center">
                                                    <img src={data.img} className="Low-Quality" alt='media_content' />
                                                    <h4>Low</h4>
                                                </Col>
                                            </Row>
                                        </Fragment> : null}
                                </div>
                                <div>
                                    {data.typeof !== "mediaQuality" ?
                                        <label className="switch">
                                            <input type="checkbox" />
                                            <span className="slider round"></span>
                                        </label> : null}

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

export default MediaContent