import React, { useEffect, useState } from "react";
import { BarChart2, Edit2, Eye, Home, RotateCcw } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import SociomeeLogo from '../../../NewComponents/IMG/SociomeeLogo.svg'

const AdmanagerHeaderR = () => {
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const [open, setOpen] = useState(false);
  // get user profile by user id
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfileByUserId());
  }, []);
  return (
    <>
      <header className="header-light border position-fixed unset">
        <div className="mobile-fix-menu"></div>
        <div className="container-fluid custom-padding">
          <div className="header-section">
            <div className="header-left">
              <div className="brand-logo ads-logo">
                <NavLink to="/Home">
                  <img
                    src={SociomeeLogo}
                    alt="logo"
                    className="img-fluid"
                  />
                </NavLink>
                <Link to="/Dashboard">
                  <h3 className="ad-title" style={{ color: "black" }}>
                    Ads Manager
                  </h3>
                </Link>
              </div>
            </div>

            <div className="header-right">
              <li className="header-btn custom-dropdown dropdown-lg btn-group notification-btn">
                <a
                  className="main-link"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon-light strokeWidth-3 iw-16 ih-16"
                  >
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                  </svg>
                  <span className="count warning">2</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-header">
                    <span>Notification</span>
                    <div className="mobile-close">
                      <h5>close</h5>
                    </div>
                  </div>
                </div>
              </li>
              <ul className="option-list">
                <li className="header-btn custom-dropdown profile-btn btn-group">
                  <a
                    className="main-link"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-light strokeWidth-3 d-sm-none d-block iw-16 ih-16"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <div className="media d-none d-sm-flex">
                      <div className="user-img">
                        <img
                          src={userProfileByUserId.profileImage}
                          className="img-fluid bg-img"
                          alt={userProfileByUserId?.fullName}
                        />
                        <span className="available-stats online"></span>
                      </div>

                      <div className="media-body d-none d-md-block">
                        <h4>{userProfileByUserId?.fullName}</h4>
                        <span>{userProfileByUserId?.userName}</span>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header">
                      <span>profile</span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li>
                          <NavLink to={`/MyProfile?userId=${user.id}&type=creater`}>
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className=""
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">Profile</h5>
                                  <h6>Profile preview & settings</h6>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className=""
                              >
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">setting & privacy</h5>
                                  <h6>all settings & privacy</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-help-circle"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">help & support</h5>
                                  <h6>browse help here</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => setOpen(true)}>
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-log-out"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">log out</h5>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="header-btn custom-dropdown d-md-none d-block app-btn">
                  <div>
                    <ul className="sidebar-icon">
                      <li className="active text-center">
                        <NavLink onClick={toggle}>
                          <Home />
                          <h4>Home</h4>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                </li>
                <li className="header-btn custom-dropdown dropdown-lg btn-group message-btn">
                  {/* <a className="main-link" href="#" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-light strokeWidth-3 iw-16 ih-16"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                                            <span className="count success">2</span>
                                        </a> */}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Collapse horizontal isOpen={isOpen}>
              <div className="text-center d-flex justify-content-between p-1 m-1">
                <NavLink className='text-center text-dark'>
                  <BarChart2 />
                  <h4>Performance</h4>
                </NavLink>
                <NavLink className='text-center text-dark'>
                  <Edit2 />
                  <h4>Edit</h4>
                </NavLink>
                <NavLink className='text-center text-dark'>
                  <Eye />
                  <h4>Review</h4>
                </NavLink>
                <NavLink className='text-center text-dark'>
                  <RotateCcw />
                  <h4>History</h4>
                </NavLink>
              </div>
            </Collapse>
          </div>
        </div>
      </header>
    </>
  );
};

export default AdmanagerHeaderR;
