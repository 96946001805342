import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';
import { Printer } from "react-feather";
import { Button } from 'reactstrap';
import { ComponentToPrint } from './ComponentToPrint';

export default class Invoice extends Component {
    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => {
                        return <Button color='primary' outline ><Printer size={18} /></Button>;
                    }}
                    content={() => this.componentRef}
                />
                <ComponentToPrint ref={el => (this.componentRef = el)} />
            </div>
        )
    }
}
