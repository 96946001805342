import React, { useRef, useState, useEffect } from 'react';
import { AlertCircle, AlertTriangle, Check, ChevronsDown, ChevronsUp, Circle, Copy, Eye, Filter, Heart, LogOut, MessageCircle, MoreHorizontal, MoreVertical, Plus, Search, Send, Smile, Volume, Volume2, VolumeX, X } from 'react-feather';
import { NavLink, useNavigate } from "react-router-dom";
import LiveGif from '../../NewComponents/IMG/LiveGif.gif'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown } from 'reactstrap';
import { FormLabel, Radio } from '@mui/material';
import PinIcon from '../../NewComponents/IMG/PinIcon.svg'
import UserImg from '../../NewComponents/IMG/img.svg'
import { Offcanvas } from 'react-bootstrap';
import EmojiPicker from "emoji-picker-react";
import Picker from "emoji-picker-react";
import MainGoLiveIcon from '../../NewComponents/IMG/MainGoLiveIcon.gif'

import $ from 'jquery';

import "./golive2.css";



function GoLiveDetail() {
  const [heartsReleased, setHeartsReleased] = useState(false);
  const [heartCount, setHeartCount] = useState(0);
  const [animationTimeout, setAnimationTimeout] = useState(null);
  const commentBlk = useRef(null);
  const FullcommentSec = useRef(null);
  const ButtonHalf = useRef(null);
  const ButtonFull = useRef(null);
  const AddWidth = useRef(null);
  const ClickFullCom = (e) => {
    commentBlk.current.classList.remove("d-none");
    ButtonFull.current.classList.add("d-none");
    ButtonHalf.current.classList.remove("d-none");
    // FullcommentSec.current.style.transition = "0.5s ease";
    FullcommentSec.current.style.height = "100vh";
    // FullcommentSec.current.classList.add("golive-fullcomment");
    AddWidth.current.classList.add("liveandclose-block-width-change");
  };
  const ClickHalfCom = (e) => {
    commentBlk.current.classList.add("d-none");
    ButtonFull.current.classList.remove("d-none");
    ButtonHalf.current.classList.add("d-none");
    // FullcommentSec.current.style.transition = "0.5s ease";
    // FullcommentSec.current.classList.remove("golive-fullcomment");
    FullcommentSec.current.style.height = "170px";
    AddWidth.current.classList.remove("liveandclose-block-width-change");
  };

  // count abbreviation function

  const numFormatter = (num) => {
    // eslint-disable-next-line prefer-template
    if (num > 999 && num < 1000000) {
      return `${parseInt((num / 1000))}K`
    } else if (num >= 999999) {
      return `${parseInt((num / 1000000))}M`
    } else if (num <= 999) {
      return `${num}`
    }
  }

  // { for the 2nd emojii}
  $("#test1 i").on("click", function (event) {
    event.preventDefault();
    console.log($(this).text());
  });

  const navigate = useNavigate()

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [viewerdropdownOpen, setviewerDropdownOpen] = useState(false)
  const [blockuser, setblockuser] = useState(false)
  const [show, setShow] = useState(false);
  const [isOff, setIsOff] = useState(true);
  const videoRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // emojii start
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  // const [emojiIconStyle, setEmojiIconStyle] = useState({
  //   filter: 'brightness(0) invert(1) sepia(1) saturate(5) hue-rotate(175deg)',

  // });






  const onEmojiClick = (emojiObject) => {


    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    setInputStr(inputStr + emojiObject.emoji);
    setShowPicker(false);
  };
  // emoji end

  const toggleblockuser = () => {
    setblockuser((prevState) => !prevState)
    const video = videoRef.current;
    video.pause();

  }
  const toggleViewer = () => {
    setviewerDropdownOpen((prevState) => !prevState)
    const video = videoRef.current;
    video.pause();

  }
  const [unFollowOpen, setunFollowOpen] = useState(false)
  const toggleunFollow = () => {

    setunFollowOpen((preState) => !preState)
    const video = videoRef.current;
    video.pause();
  }
  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = () => {
    setReportOpen((preState) => !preState)
    const video = videoRef.current;
    video.pause();
  }
  const [removeuser, setremoveuser] = useState(false)
  const [reply, setreply] = useState(false);
  const togglesetremoveuser = () => {
    setremoveuser((prevState) => !prevState)
    const video = videoRef.current;
    video.pause();
  }

  const [senddropdownOpen, setsendDropdownOpen] = useState(false)
  const toggleSender = () => {
    setsendDropdownOpen((prevState) => !prevState)
    const video = videoRef.current;



    video.pause();
  }

  // pinned comment selection 
  const [pinnedComment, setPinnedComment] = useState(false)
  const Pineedclose = () => {
    setPinnedComment(!pinnedComment)
    setShow(false)
  }

  const viewerData = [
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Following'
    }
  ]

  const ReportDataInfo = [
    {
      name: "Spam"
    },
    {
      name: "Nudity"
    },
    {
      name: "Violence"
    },
    {
      name: "Harrasment"
    },
    {
      name: "Suicide or self-injury"
    },
    {
      name: "Terrorism"
    },
    {
      name: "Others"
    }
  ]

  const sendData = [
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Darrell@123',
      typesof: 'Follow'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    },
    {
      userNames: 'Darrell Steward',
      joined: 'Steward@564',
      typesof: 'Following'
    }
  ]

  const ViewerModal = () => {
    return (
      <>
        {viewerdropdownOpen ? (
          <Modal isOpen={viewerdropdownOpen} className=' view' toggle={toggleViewer} style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)', borderRadius: '5px' }} >

            <ModalHeader className='d-flex justify-content-center'>
              <b>{numFormatter('8000')} People watching Live</b>
            </ModalHeader>
            <ModalBody>
              {
                viewerData.map((d, i) => {
                  return (
                    <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                      <div className='d-flex align-items-center'>
                        <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                        <div className='ml-1 spacing'>
                          <h4 className='usernamees'>{d.userNames}</h4>
                          <p className='text-muted'>{d.joined}</p>
                        </div>
                      </div>
                      {/* <div>
                        {d.typesof === 'Follow' ? <Button style={{ padding: '10px 30px 10px 30px' }} color='primary' ><Plus size={18} />Follow</Button>
                          : <Button color='primary' disabled><Check size={18} />Following</Button>}
                      </div> */}
                      <div>
                        {d.typesof === 'Follow' ? <Button style={{ padding: '10px 30px 10px 30px' }} color='primary' >Follow</Button>
                          : <Button color='primary' disabled>Following</Button>}
                      </div>
                    </div>
                  )
                })
              }
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }
  const SendModal = () => {
    const [isSentList, setIsSentList] = useState(sendData.map(() => false));

    const handleSendClick = (index) => {
      const updatedIsSentList = [...isSentList];
      updatedIsSentList[index] = true;
      setIsSentList(updatedIsSentList);
    };
    return (
      <>
        {senddropdownOpen ? (
          <Modal isOpen={senddropdownOpen} className='modal-dialog-centered' toggle={toggleSender}  >
            {/* <ModalHeader toggle={toggleViewer}>
            </ModalHeader> */}
            <ModalHeader className='d-flex justify-content-center'>
              <h2>Share live stream</h2>
            </ModalHeader>
            <ModalBody>
              <div className='d-flex align-items-center justify-content-between'>
                <InputGroup className='mr-2'>
                  <InputGroupText className='bg-transparent text-secondary'>
                    <Search size={16} />
                  </InputGroupText>
                  <Input type='search' placeholder='Search..' className="py-2" />
                </InputGroup>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color='primary' outline>
                    <Filter size={20} />
                  </DropdownToggle>
                  <DropdownMenu style={{ boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)', borderRadius: '5px' }}>
                    <DropdownItem tag='a'> Family </DropdownItem>
                    <DropdownItem tag='a'> Office </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="scrollForGolive mt-2">
                {
                  sendData.map((d, i) => {
                    return (
                      <div className='d-flex align-items-center justify-content-between mt-3  mr-3 mb-3 ml-1' key={i} >
                        <div className='d-flex align-items-center'>
                          <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                          <div className='ml-1'>
                            <div className='position-relative'>
                              <h4 className='fw-lighter'>{d.userNames}</h4>
                              <span className="ShareOnlineUser"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                            </div>
                            <h6 className='text-muted'>{d.joined}</h6>
                          </div>
                        </div>
                        <div className='w-25'>
                          <Button
                            className='sendtext'
                            color='primary w-100'
                            onClick={() => handleSendClick(i)}
                            disabled={isSentList[i]} // Disable the button when it's already sent
                          >
                            {isSentList[i] ? 'Sent' : 'Send'}
                          </Button>
                        </div>
                      </div>
                    )
                  })
                }
              </div>


            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }
  const ReportModal = () => {
    const [radioActive, setRadioActive] = useState(null)
    return (
      <>
        <div>
          {
            reportOpen !== false &&
            <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
              <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                <h3>Report</h3>
              </ModalHeader>
              <ModalBody>
                <div className='Modal_Body_Section'>
                  <h4>Tell us about what you feel and help us to make Sociomee more secure.</h4>
                </div>
                {
                  ReportDataInfo.map((data,index) => (
                    <div
                    key={index}
                    className='d-flex justify-content-between Report_Section'
                  >
                    <h5
                      className='d-flex reort'
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={() => {
                        setRadioActive((prevIndex) => (prevIndex === index ? null : index));
                      }}
                    >
                      {data.name}
                    </h5>
                    <div>
                      <Radio
                        inputProps={{ 'aria-label': 'A' }}
                        name="radio-buttonsOne"
                        // id={`radio-buttonsOneId_${index}`}
                        className='Radio_btn_check'
                        checked={radioActive === index}
                        onClick={() =>{setRadioActive((prevIndex) => (prevIndex === index ? null : index));}}
                      />
                      {' '}
                    </div>
                  </div>
                  ))
                }
                <div className='d-flex justify-content-center mt-3'>
                  <Input
                    id="ReportName"
                    name="ReportName"
                    placeholder="Write a comment..."
                    type="text
                    "
                  />
                </div>
                <hr className='w-100 text-muted' />
                <div className='text-center'>
                  <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                  <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                </div>
              </ModalBody>

            </Modal>

          }

        </div>
      </>
    )
  }


  const Blockmodal = () => {
    return (

      <>
        {blockuser ? (
          <Modal isOpen={blockuser} className='modal-dialog-centered' toggle={toggleblockuser} >
            {/* <ModalHeader toggle={toggleViewer}>
            </ModalHeader> */}
            <ModalHeader className='d-flex justify-content-center'>
              <h4>Do you really want to Block This User ?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button onClick={toggleblockuser} color='secondary'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='orange' onClick={toggleblockuser} >Block</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  const UnfollowModal = () => {
    return (
      <>
        {unFollowOpen ? (
          <Modal isOpen={unFollowOpen} className='modal-dialog-centered' toggle={toggleunFollow} >
            {/* <ModalHeader toggle={toggleViewer}>
            </ModalHeader> */}
            <ModalHeader className='d-flex justify-content-center'>
              <h4 >Do you really want Unfollow this User?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button onClick={toggleunFollow} color='secondary'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='danger' onClick={toggleunFollow} >UnFollow</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  const RemoveModal = () => {
    return (
      <>
        {removeuser ? (
          <Modal isOpen={removeuser} className='modal-dialog-centered' toggle={togglesetremoveuser} >
            {/* <ModalHeader toggle={toggleViewer}>
            </ModalHeader> */}
            <ModalHeader className='d-flex justify-content-center'>
              <h4>Do you really want Remove  this User?</h4>
            </ModalHeader>
            <ModalBody>
              <div className='text-center'>
                <Button onClick={togglesetremoveuser} color='secondary'>Cancel</Button>
                &nbsp;
                &nbsp;
                <Button color='danger' onClick={togglesetremoveuser} >Remove</Button>
              </div>
            </ModalBody>
          </Modal>
        ) : (null)}
      </>
    )
  }

  const videoHandler = (control) => {
    if (control === "play") {
      videoRef.current.play();
      setPlaying(true);
      var vid = document.getElementById("video1");
      setVideoTime(vid.duration);
    } else if (control === "pause") {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  const fastForward = () => {
    videoRef.current.currentTime += 5;
  };

  const revert = () => {
    videoRef.current.currentTime -= 5;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(videoRef.current?.currentTime);
      setProgress((videoRef.current?.currentTime / videoTime) * 100);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [videoTime]);
  // {the blinking option in  mobile view}
  const MobileHeader = () => {
    const [isBlinking,] = useState(true);
    return (
      <div className="rem-web-view">
        <div className='p-2 mobheaderstyle mobheaderstyles'>
          <p onClick={() => navigate('/GoLive')} > <X /> <span></span></p>
          <div>
            <h5 className='p-1'>#ENTERTAINMENT</h5>


            <h6 className='p-1 fs-3 fw-bold'>00:05 <span className={`p-1 fs-5  bg-danger text-white roundeds-4 py-1 px-2  podliveblinkess ${isBlinking ? 'blinkab' : ''}`}>Live</span></h6>


            <h6 onClick={toggleViewer} className='p-1'><Eye size={18} /><span> 128k watching</span></h6>
          </div>
        </div>
      </div>
    )
  }


  const MobileRightSidebar = () => {
    const handleHeartClick = () => {
      setHeartsReleased(true);
      setHeartCount(heartCount + 1);
      clearTimeout(animationTimeout); // Clear any existing timeout
      const timeout = setTimeout(() => {
        setHeartsReleased(false);
      }, 1000);
      setAnimationTimeout(timeout);
    }

    return (



      <div className="rem-web-view postitionForleftsidebar">
        <div className=' d-flex flex-column text-white align-items-center '>
          <div className='heartmobile'>
            <div className='d-flex flex-column align-items-center' >
              <Heart className={`heart-button ${heartsReleased ? 'released' : ''}`} strokeWidth={1} size={28} fill='#FF5B58' onClick={handleHeartClick} />
              {heartsReleased && (

                <div className="heart-explosion1">
                  {Array.from({ length: 5 }, (_, index) => (
                    <span key={index} className="heart-emojii">

                      💕
                    </span>
                  ))}

                </div>
              )}
              <p className='hrtcnt'>{heartCount}</p>

            </div>
          </div>
          <span className='user-select-none' onClick={() => setIsOff(!isOff)}> {isOff ? <Volume2 className='ml-1' size={24} /> : <VolumeX className='ml-1' size={24} />}</span>
          <div className='d-flex flex-column align-items-center'>
            <Send style={{ cursor: 'pointer' }} onClick={toggleSender} className='mx-2' size={24} />
            <p> &nbsp;18</p>
          </div>
          <UncontrolledButtonDropdown direction='up' className=''>
            <DropdownToggle color='flat-primary' className='p-0 m-0'>
              <MoreVertical className='ml-1' color='#ffffff' size={24} />
            </DropdownToggle>

            <DropdownMenu className='goliveFordrop' >

              <DropdownItem tag='a'> <Copy size={20} /> &nbsp;Copy Link</DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem onClick={toggleReport} tag='a'> <AlertTriangle size={20} />&nbsp; Report</DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} />&nbsp; UnFollow </DropdownItem>
              <DropdownItem divider></DropdownItem>
              <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp; Block user </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </div>
    )
  }
  const handleHeartClick = () => {
    setHeartsReleased(true);
    setHeartCount(heartCount + 1);
    // Clear any existing timeout
    const timeout = setTimeout(() => {
      setHeartsReleased(false);
    }, 1000);
    setAnimationTimeout(timeout);
  }
  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video) {
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    }
  };



  return (
    <>
      <div className="golive-video-section">
        <MobileHeader />

        <div className="video-container1">
          <video
            id="video1"
            className="video"
            ref={videoRef}
            onClick={togglePlayPause}
            autoPlay
            loop
            playsInline
          >
            <source src="assets/images/video/golive-video.mp4" type="video/mp4" />
          </video>
        </div>

        <Blockmodal />
        <SendModal />
        <ViewerModal />
        <ReportModal />
        <UnfollowModal />
        <RemoveModal />
        <MobileRightSidebar />
        <div className="timecontrols">
          <div className="inputboxForposition rem-web-view">
            <div className='MobForChat EditedChanges' style={{ bottom: pinnedComment ? "90px" : "39px", marginLeft: '-10px' }}>
              <div class="gradient-blur">
                <div></div>
                <div></div>
                <div></div>
              </div>
              {[...Array(6)].map((index) => (
                <div key={index} className={`p-1 px-2 d-flex comimgsss`}>
                  <img src="assets/images/story-2.jpg" width={25} height={25} className='rounded-circle  mobile' />
                  <div onClick={handleShow} className='rounded-pill text-white chatForpopup'>
                    <div className='d-flex align-items-center'>
                      <h4 className='text-white'>Sufiya elija</h4><h6 className='pl-1'>01:05</h6>
                    </div>
                    <small>How are you ?</small>
                  </div>
                </div>
              ))}
              <div className={`p-1 px-2 d-flex mobPinnedChat bg-transparent  ${pinnedComment ? "" : " d-none"}`}>
                <img src="assets/images/story-2.jpg" width={25} height={25} className='rounded-circle' />
                <div onClick={handleShow} className='rounded-pill text-white chatForpopup'>
                  <div className='d-flex align-items-center'>
                    <h4 className='text-white'>Sufiya elija</h4><h6 className='pl-1'>01:00 <img src={PinIcon} alt="pinedIcon" /></h6>
                  </div>
                  <small><span className='text-primary font-italic'>@Taha sir</span> joined</small>

                </div>
              </div>
            </div>


            {/* <Input type='text' className='InputForcolor1 rounded-0' placeholder='Comment....' value={inputStr} onChange={(e) => setInputStr(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}


            />
            <div className='d-flex text-muted commentInputForposition'>
              <img

                className="emoji-icon"
                src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
                onClick={() => setShowPicker((val) => !val)}
                style={emojiIconStyle}
              />
              {showPicker && (
                <EmojiPicker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
              )}


              <Send className='iconForcolor' style={{ cursor: 'pointer', color: 'rgba(129, 193, 75, 1)' }} />

            </div> */}

            <div className="golive-commentinput-block">
              <Input type='text' className='InputForcolor' placeholder='Comment....' value={inputStr} onChange={(e) => setInputStr(e.target.value)}
              // onFocus={handleInputFocus}
              // onBlur={handleInputBlur}


              />
              <div className='d-flex text-muted commentInputForposition'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowPicker((val) => !val)} className='smileemoji'>
                  <g clip-path="url(#clip0_24016_22635)">
                    <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM14.8 13.857C14.8933 13.7629 15.0044 13.6882 15.1268 13.6374C15.2492 13.5866 15.3804 13.5606 15.513 13.561C15.6455 13.5614 15.7766 13.5881 15.8987 13.6396C16.0208 13.6911 16.1315 13.7663 16.2243 13.8609C16.317 13.9556 16.3901 14.0677 16.4391 14.1908C16.4882 14.3139 16.5123 14.4455 16.5101 14.578C16.5078 14.7105 16.4792 14.8413 16.426 14.9626C16.3728 15.084 16.2959 15.1936 16.2 15.285C15.0794 16.3858 13.5708 17.0018 12 17C10.4292 17.0018 8.92064 16.3858 7.8 15.285C7.61503 15.0984 7.51093 14.8465 7.5102 14.5838C7.50948 14.321 7.61219 14.0685 7.79613 13.8809C7.98006 13.6933 8.23045 13.5856 8.49316 13.5811C8.75586 13.5767 9.00977 13.6758 9.2 13.857C9.94682 14.5912 10.9527 15.0018 12 15C13.09 15 14.077 14.565 14.8 13.857ZM8.5 8C8.89782 8 9.27936 8.15804 9.56066 8.43934C9.84196 8.72064 10 9.10218 10 9.5C10 9.89782 9.84196 10.2794 9.56066 10.5607C9.27936 10.842 8.89782 11 8.5 11C8.10218 11 7.72064 10.842 7.43934 10.5607C7.15804 10.2794 7 9.89782 7 9.5C7 9.10218 7.15804 8.72064 7.43934 8.43934C7.72064 8.15804 8.10218 8 8.5 8ZM15.5 8C15.8978 8 16.2794 8.15804 16.5607 8.43934C16.842 8.72064 17 9.10218 17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11C15.1022 11 14.7206 10.842 14.4393 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10218 14.158 8.72064 14.4393 8.43934C14.7206 8.15804 15.1022 8 15.5 8Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_24016_22635">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {showPicker && (
                  <Picker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
                )}

                {/* <Send className='iconForcolor' /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='sendicon1'>
                  <path d="M5.5093 12.1662H21.0657M5.5093 12.1662L3.03443 19.5908C2.99775 19.6895 2.99019 19.7967 3.01265 19.8996C3.0351 20.0025 3.08662 20.0968 3.16109 20.1712C3.23556 20.2457 3.32985 20.2972 3.43274 20.3197C3.53563 20.3421 3.64281 20.3346 3.74154 20.2979L21.0657 12.1662M5.5093 12.1662L3.03443 4.74153C2.99775 4.64281 2.99019 4.53563 3.01265 4.43274C3.0351 4.32985 3.08662 4.23556 3.16109 4.16109C3.23556 4.08662 3.32985 4.0351 3.43274 4.01265C3.53563 3.99019 3.64281 3.99775 3.74153 4.03443L21.0657 12.1662" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between rem-mob-view align-items-center w-100'>
            <div className='text-white d-flex' >
              <h4 className="controlsTime">
                {Math.floor(currentTime / 60) + ":" + ("0" + Math.floor(currentTime % 60)).slice(-2)}
                {/* {Math.floor(videoTime / 60) + ":" + ("0" + Math.floor(videoTime % 60)).slice(-2)} */}
              </h4>


              <h4 style={{ width: "33px", cursor: 'pointer', height: '33px' }} className='pl-2 d-flex flex-nowrap rem-mob-view' id='test1'>
                <i>👍</i>
                <i>😍</i>
                <i>🤣</i>
                <i>😊</i>
                <i>😒</i>
                <i>😂</i>

              </h4>

            </div>

            <div className='w-75 text-right text-white'>
              <span className='onHoverForshow d-xl-none'>
                👍12😍🤣😊😒😂
              </span>


              {/* <Heart className=' ml-3' strokeWidth={1} size={28} fill='#FF5B58' /> 18 */}
              <div className='wholeheart'>
                <div className="heart-animation-app detailheart">


                  <div className='d-flex flex-column align-items-center' >
                    <Heart className={`heart-button ${heartsReleased ? 'released' : ''}`} strokeWidth={1} size={28} fill='#FF5B58' onClick={handleHeartClick} />
                    {heartsReleased && (
                      <div className="heart-explosion1">
                        {Array.from({ length: 5 }, (_, index) => (
                          <span key={index} className="heart-emojii">
                            💕
                          </span>

                        ))}


                      </div>
                    )}
                    <span className='heartcount
                  '>{heartCount}</span>


                  </div>

                </div>
              </div>
              <div className='sendicon'>
                <Send style={{ cursor: 'pointer' }} size={20} onClick={toggleSender} className="text-white " /> &nbsp;24
              </div>


            </div>
          </div>

        </div>
        <div className="liveandclose-block rem-mob-view" ref={AddWidth}>
          <div className="live-comment-view-block d-flex align-items-center">
            <img src={MainGoLiveIcon} width={100} alt='Live' className='positionIconForMob bg-transparent' />
            <div className='mx-3' style={{ cursor: 'pointer' }}>
              <UncontrolledButtonDropdown direction='up' className=''>
                <DropdownToggle style={{ borderRadius: '10px' }} color='flat' className='p-0 m-0'>
                  <h4 className='text-white'>
                    <Eye size={24} />
                    <span> {numFormatter('8000')}</span>
                    &nbsp;
                    Watching
                  </h4>
                </DropdownToggle>
                <DropdownMenu className='py-0 view1' style={{
                  minWidth: '500px', borderRadius: '5px', borderRadius: '5px',
                  background: '#FFF',
                  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20)'
                }} >
                  <div className='bg-light text-center p-2'>
                    <b>{numFormatter('8000')} People watching Live</b>
                  </div>
                  <div>
                    {
                      viewerData.map((d, i) => {
                        return (
                          <div className='d-flex align-items-center justify-content-between m-3' key={i} >
                            <div className='d-flex align-items-center'>
                              <img src='assets/images/story-3.jpg' height={50} width={50} className='rounded-circle' alt='userimage' />
                              <div className='ml-1 spacing'>
                                <h4 className='text-body usernamees'>{d.userNames}</h4>

                                <p className='text-muted'>{d.joined}</p>
                              </div>
                            </div>
                            <div className='w-25'>
                              {d.typesof === 'Follow' ? <Button color='primary' className='w-100'>Follow</Button>
                                : <Button color='primary' className='w-100' outline >Following</Button>}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>

                </DropdownMenu>
              </UncontrolledButtonDropdown>

            </div>
            <h4>#Entertainment</h4>
          </div>
          <div className="golive-close-details-blk">
            {/* <a href="#" className="share-details-golive-btn"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><line x1="1" y1="1" x2="23" y2="23"></line><path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path><path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg></a> */}

            {/* <a href="#" className="share-details-golive-btn"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><polyline points="4 14 10 14 10 20"></polyline><polyline points="20 10 14 10 14 4"></polyline><line x1="14" y1="10" x2="21" y2="3"></line><line x1="3" y1="21" x2="10" y2="14"></line></svg></a> */}

            {/* <Send style={{ cursor: 'pointer' }} size={24} onClick={toggleSender} className="text-white share-details-golive-btn" /> */}

            {/* <a href="#" className="share-details-golive-btn"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg></a> */}

            <div className="golive-3dot-blk">
              <UncontrolledButtonDropdown direction='start'>
                <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                  <MoreHorizontal size={22} color='#fff' />
                </DropdownToggle>
                <DropdownMenu style={{ zIndex: '100' }} className="goLiveDropDownMenuItem">
                  {/* <DropdownItem tag='a'>Save Video</DropdownItem>
                  <DropdownItem divider></DropdownItem> */}
                  <DropdownItem tag='a'> <Copy size={15} />&nbsp;&nbsp;Copy Link</DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem onClick={toggleReport} tag='a'> <AlertTriangle size={15} />&nbsp;&nbsp;Report </DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={15} />&nbsp;&nbsp;UnFollow </DropdownItem>
                  <DropdownItem divider></DropdownItem>
                  <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={15} />&nbsp;&nbsp;Block user </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <NavLink to="/GoLive"><X color='white' size={24} /></NavLink>
          </div>
        </div>

        {/*----------------------------------- Comment Sidebar----------------------------------- */}

        <div className="golive-comment-block" ref={FullcommentSec}>
          <a className="golive-comment-updown-button d-none" onClick={ClickHalfCom} ref={ButtonHalf}>
            <ChevronsDown />
          </a>
          <a className="golive-comment-updown-button" onClick={ClickFullCom} ref={ButtonFull}>
            <ChevronsUp />
          </a>
          <div className="goliveuser-blk headerFormain">
            <img src="assets/images/story-2.jpg" />
            <span className="HeaderactiveForGoLiveIcon"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
            <div className="goliveuser-cont-blk">
              <h4>Paige Turner</h4>
              <p>Alamba, USA</p>
            </div>
          </div>

          <div className="golive-user-comment-blk d-none" ref={commentBlk}>
            <div className="gl-com-like-share-blk">
              <div className="gl-heartlist"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="#FF5B96" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="#FF5B96" strokeLinecap="round" strokeLinejoin="round" className="iw-16 ih-16"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> <span>+75</span></div>
              <div className="comment-share-goliveblk">
                <span><b>4565</b> Comment</span>
                {/* <span><b>985</b> Share</span> */}
              </div>
            </div>

            <div className="comment-section golive-comment-section">
              <div className="comments d-block">
                <div className="main-comment">
                  <div className="media">
                    <a href="#" className="user-img popover-cls position-relative"
                      data-bs-toggle="popover" data-placement="right"
                      data-name="Pabelo mukrani"
                      data-img="assets/images/story-2.jpg">
                      <img src="assets/images/story-2.jpg" className="img-fluid bg-img" alt="user" />
                    </a>
                    <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                    <div className="media-body">
                      <div className='d-flex justify-content-between align-items-center'>
                        <a className='d-flex' href="#">
                          <h5>Pabelo Mukrani</h5>
                        </a>
                        <UncontrolledButtonDropdown direction='start'>
                          <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                            <MoreHorizontal size={22} color='#fff' />
                          </DropdownToggle>
                          <DropdownMenu style={{ zIndex: '100' }}>
                            <DropdownItem tag='a'> <Copy size={20} />&nbsp;&nbsp;Copy Link</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} />&nbsp;&nbsp;Unfollow</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp;&nbsp;Block user </DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleReport} tag='a'>  <AlertTriangle size={20} />&nbsp;&nbsp;Report...</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>
                      <div className="comment-time">
                        <h6>50 mins ago</h6>
                      </div>
                      <p>Oooo Very Cute and Sweet Dog, happy birthday
                        Cuty.... &#128578;
                      </p>
                      <ul className="comment-option">
                        <li><a href="#">like (5)</a></li>
                        <li><a onClick={() => setreply(!reply)} href="#">reply (1)</a></li>
                      </ul>
                    </div>
                  </div>
                  {reply ?
                    <div className="sub-comment">
                      <div className="media">
                        <a href="#" className="user-img popover-cls  position-relative"
                          data-bs-toggle="popover" data-placement="right"
                          data-name="sufiya elija"
                          data-img="assets/images/story-3.jpg">
                          <img src="assets/images/story-3.jpg"
                            className="img-fluid bg-img"
                            alt="user" />
                        </a>
                        <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                        <div className="media-body">
                          <div className='d-flex justify-content-between align-items-center'>
                            <a className='d-flex' href="#">
                              <h5>Pabelo Mukrani</h5>
                            </a>
                            <UncontrolledButtonDropdown direction='start'>
                              <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                                <MoreHorizontal size={22} color='#fff' />
                              </DropdownToggle>
                              <DropdownMenu style={{ zIndex: '100' }}>
                                <DropdownItem tag='a'> <Copy size={20} />&nbsp;&nbsp;Copy Link</DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} />&nbsp;&nbsp;Unfollow</DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp;&nbsp;Block user </DropdownItem>
                                <DropdownItem divider></DropdownItem>
                                <DropdownItem onClick={toggleReport} tag='a'>  <AlertTriangle size={20} /> &nbsp;&nbsp;Report...</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </div>
                          <div className="comment-time">
                            <h6>50 mins ago</h6>
                          </div>
                          <p>Thank You So Much ❤❤</p>
                          <ul className="comment-option">
                            <li><a href="#">like</a></li>
                            <li><a href="#">reply</a></li>
                          </ul>
                        </div>
                      </div>
                    </div> : null
                  }
                </div>
                <div className="main-comment">
                  <div className="media">
                    <a href="#" className="user-img popover-cls position-relative"
                      data-bs-toggle="popover" data-placement="right"
                      data-name="pabelo mukrani"
                      data-img="assets/images/story-2.jpg">
                      <img src="assets/images/story-2.jpg"
                        className="img-fluid bg-img"
                        alt="user" />
                    </a>
                    <span className="HeaderactiveForGoLiveIconMedia"><Circle strokeWidth={5} color="#fff" fill="#16C31E" size={10} /></span>
                    <div className="media-body">
                      <div className='d-flex justify-content-between align-items-center'>
                        <a className='d-flex' href="#">
                          <h5>Pabelo Mukrani</h5>
                        </a>
                        <UncontrolledButtonDropdown direction='start'>
                          <DropdownToggle outline className='p-0 m-0' color='flat-primary'>
                            <MoreHorizontal size={22} color='#fff' />
                          </DropdownToggle>
                          <DropdownMenu style={{ zIndex: '100', minWidth: '12rem' }}>
                            <DropdownItem tag='a'> <Copy size={20} /> &nbsp;&nbsp;Copy Link</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleunFollow} tag='a'> <LogOut size={20} />&nbsp;&nbsp;Unfollow</DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleblockuser} tag='a'> <AlertCircle size={20} />&nbsp;&nbsp;Block user </DropdownItem>
                            <DropdownItem divider></DropdownItem>
                            <DropdownItem onClick={toggleReport} tag='a'>  <AlertTriangle size={20} /> &nbsp;&nbsp;Report...</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>
                      <div className="comment-time">
                        <h6>50 mins ago</h6>
                      </div>
                      <p>It’s party time, Sufiya..... and happy birthday
                        cuty 🎉🎊
                      </p>
                      <ul className="comment-option">
                        <li><a href="#">like</a></li>
                        <li><a href="#">reply</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="golive-commentinput-block">
            <Input type='text' className='InputForcolor' placeholder='Comment....' value={inputStr} onChange={(e) => setInputStr(e.target.value)}
            // onFocus={handleInputFocus}
            // onBlur={handleInputBlur}


            />
            <div className='d-flex text-muted commentInputForposition'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={() => setShowPicker((val) => !val)} className='smileemoji'>
                <g clip-path="url(#clip0_24016_22635)">
                  <path d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM14.8 13.857C14.8933 13.7629 15.0044 13.6882 15.1268 13.6374C15.2492 13.5866 15.3804 13.5606 15.513 13.561C15.6455 13.5614 15.7766 13.5881 15.8987 13.6396C16.0208 13.6911 16.1315 13.7663 16.2243 13.8609C16.317 13.9556 16.3901 14.0677 16.4391 14.1908C16.4882 14.3139 16.5123 14.4455 16.5101 14.578C16.5078 14.7105 16.4792 14.8413 16.426 14.9626C16.3728 15.084 16.2959 15.1936 16.2 15.285C15.0794 16.3858 13.5708 17.0018 12 17C10.4292 17.0018 8.92064 16.3858 7.8 15.285C7.61503 15.0984 7.51093 14.8465 7.5102 14.5838C7.50948 14.321 7.61219 14.0685 7.79613 13.8809C7.98006 13.6933 8.23045 13.5856 8.49316 13.5811C8.75586 13.5767 9.00977 13.6758 9.2 13.857C9.94682 14.5912 10.9527 15.0018 12 15C13.09 15 14.077 14.565 14.8 13.857ZM8.5 8C8.89782 8 9.27936 8.15804 9.56066 8.43934C9.84196 8.72064 10 9.10218 10 9.5C10 9.89782 9.84196 10.2794 9.56066 10.5607C9.27936 10.842 8.89782 11 8.5 11C8.10218 11 7.72064 10.842 7.43934 10.5607C7.15804 10.2794 7 9.89782 7 9.5C7 9.10218 7.15804 8.72064 7.43934 8.43934C7.72064 8.15804 8.10218 8 8.5 8ZM15.5 8C15.8978 8 16.2794 8.15804 16.5607 8.43934C16.842 8.72064 17 9.10218 17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11C15.1022 11 14.7206 10.842 14.4393 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10218 14.158 8.72064 14.4393 8.43934C14.7206 8.15804 15.1022 8 15.5 8Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_24016_22635">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {showPicker && (
                <Picker pickerStyle={{ width: "20%" }} onEmojiClick={onEmojiClick} />
              )}

              {/* <Send className='iconForcolor' /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className='sendicon1'>
                <path d="M5.5093 12.1662H21.0657M5.5093 12.1662L3.03443 19.5908C2.99775 19.6895 2.99019 19.7967 3.01265 19.8996C3.0351 20.0025 3.08662 20.0968 3.16109 20.1712C3.23556 20.2457 3.32985 20.2972 3.43274 20.3197C3.53563 20.3421 3.64281 20.3346 3.74154 20.2979L21.0657 12.1662M5.5093 12.1662L3.03443 4.74153C2.99775 4.64281 2.99019 4.53563 3.01265 4.43274C3.0351 4.32985 3.08662 4.23556 3.16109 4.16109C3.23556 4.08662 3.32985 4.0351 3.43274 4.01265C3.53563 3.99019 3.64281 3.99775 3.74153 4.03443L21.0657 12.1662" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
          <Offcanvas placement='bottom' show={show} onHide={handleClose} className='canvas1'>
            <Offcanvas.Header>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0' style={{
              margin: "3px"
            }}>
              <ul style={{ fontFamily: "Poppins", fontWeight: '400', fontSize: '14px', marginBottom: '7px' }} className='canvas'>
                <li className='text-muted  border-top-0 py-112' >Copy Link</li>
                <li className='text-muted  border-top-0 py-112' onClick={toggleunFollow}>Unfollow user</li>

                {/* <li className='text-muted py-11' onClick={togglesetremoveuser}>Remove from Live session</li>
                <li className='text-muted py-11' onClick={togglesetremoveuser}>Reply to user</li> */}
                {/* <li onClick={Pineedclose} className='text-muted  py-11'>{pinnedComment ? 'Unpin Comment' : "Pin Comment"}</li> */}
                <li className='text-muted border-top-0 py-112' onClick={toggleblockuser} >Block User</li>
                <li className='text-muted  border-top-0 py-112' onClick={toggleReport}>Report...</li>

              </ul>
            </Offcanvas.Body>
          </Offcanvas>

        </div>
      </div>
    </>
  );
}
export default GoLiveDetail 