import React, { useState } from 'react'
import { Fragment } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Input, Row } from 'reactstrap';
import LoginSignUpStepper from './LoginSignUpStepper';
import LangModalNew from './Modal-Components/LangModalNew';

const SignUpEmailPage = () => {

    const navigate = useNavigate()


    //  ------------------------------Button enable or disable ------------------------------
    const [enteredEmail, setEnteredEmail] = useState(true)

    // Language Modal Controller 
    const [languages, setLanguages] = useState(false)
    const LanguageModalToggle = () => {
        setLanguages(!languages);
    }



    // Language Selection 
    const [selectedLanguage, setSelectedLanguage] = useState("English")

    return (
        <Fragment>
            {/* =========================Full Login Page ==================================== */}
            <LangModalNew modal={languages} toggle={LanguageModalToggle} />
            <section className="login-section LoginSignUpBackground">
                <div className="container">
                    <Row >
                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 m-auto">
                            <div className="login-header-section">
                                <div className="logo-sec"><Link className="" to="/"><img src="/assets/images/logo.png" alt="logo" className="img-fluid" /></Link></div>
                            </div>
                            <div className="login-form">

                                {/*  ------------------------- Steppper ------------------------------------- */}
                                <LoginSignUpStepper steps={5} active={2} />
                                <div>
                                    <div className="login-title">
                                        <h2>What's your email address?</h2>
                                    </div>
                                    <div className="login-discription">
                                        {/* <h4>Please fill the form below.</h4> */}
                                    </div>
                                    <div className="form-sec">
                                        <div>
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    {/* <label>Enter your Email Address</label>     ----alert input className ------border-danger*/}
                                                    <Input type="email" className={`form-control`} onChange={(e) => setEnteredEmail(e.target.value.length > 0 ? false : true)} placeholder="Enter Email Address" maxLength={'30'} />
                                                    {/* <p className="error-input-msg text-center d-none" ref={errorRef}>{error}</p> */}

                                                    {/* --------------------- error  message  ---------------------- */}
                                                    {/* <p className="error-input-msg text-center LoginAlert400">**Caption text, description, error notification**</p>   */}
                                                </div>
                                                <div className="connect-with">
                                                    <h6><span>OR</span></h6>
                                                    <ul className="social-login-blk">
                                                        <li><Link className='Login4D_500'><img src="/assets/images/google-icon.png" alt="googleSignIn" /> Continue with Google</Link></li>
                                                        <li><Link className='Login4D_500'><img src="/assets/images/apple-icon.png" alt='appleSignIn' /> Continue with Apple</Link></li>
                                                    </ul>
                                                </div>
                                                {/* <p className="notimsg-blk">Provide your email for better communication. </p> */}
                                                <div className="btn-section mt-5">
                                                    <Button color="primary" onClick={() => { navigate("/signupprofilesetup") }} disabled={enteredEmail}>CONTINUE</Button>

                                                </div>
                                                <div className="skip-reg-block">
                                                    <Link to={"/signupprofilesetup"} className='LoginSkipButton cursor-p' >Skip</Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* ============================= Language Section ================================== */}
                    <div className="language-selection-section">
                        <div className="lang-sel-block">
                            <b>Choose Language:</b>
                            <span className="language-list">
                                {/* --------------------------- Labnguages------------------------------ */}
                                {["English", "Hindi", "Deutsch"].map((item, index) => (
                                    <NavLink onClick={() => { setSelectedLanguage(item) }} className={`LoginLanguageBtn ${selectedLanguage === item ? "text-decoration-underline" : ""}`}>{item}</NavLink>
                                ))}
                            </span>
                            <button onClick={() => setLanguages(true)} >
                                ...More
                            </button>
                        </div>
                    </div >

                    {/* ======================== Bottom Canvas Language section  ======================================= */}
                </div>
            </section>
        </Fragment>
    )
}

export default SignUpEmailPage
