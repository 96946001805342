import React, { useState, useEffect } from 'react'
import { Bookmark, Eye, EyeOff, MoreHorizontal, RefreshCcw, Send, Smile } from 'react-feather'
import { Button, Card, CardBody, CardFooter, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, UncontrolledButtonDropdown } from 'reactstrap'
import AvatarGroup from '../../../../NewComponents/AvatarGroup'
import UserImg from '../../../../NewComponents/IMG/img.svg'
import LikeSecIcon from '../../../../NewComponents/IMG/LikeSecIcon.svg'
import ActiveCirclelike from '../../../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../../../NewComponents/IMG/Circlelike.svg'
import ShareSecIcon from '../../../../NewComponents/IMG/ShareSecIcon.svg'
import CommentSecIcon from '../../../../NewComponents/IMG/CommentSecIcon.svg'
import LeftquotationIcon from '../../../../NewComponents/IMG/LeftquotationIcon.svg'
import RightquotationIcon from '../../../../NewComponents/IMG/RightquotationIcon.svg'
import Thought from './Thought'
import Alert from './Alert'
import Recommendations from './Recommendations'
import Poll from './Poll'
import Podcast from './Podcast'
import Event from './Event'

const UniversalCard = () => {

    const [LikeIcon, SetLikeIcon] = useState(false)
    const [BookmarkIcon, SetBookmark] = useState(true)
    const [comment, SetComment] = useState(false)
    const [isShown, setIsShown] = useState(false);
    const [Deletepost, Setdeletepost] = useState(true)
    const [deletepost, setDeletepost] = useState(false)
    const [Hide, SetHide] = useState(true)

    const Commenttoggle = () => {
        SetComment(!comment)
    }
    let timer;
    const Reactionshowtoggle = () => {
        setIsShown(true)
    }
    const ReactionHidetoggle = () => {
        timer = setTimeout(() => setIsShown(false), 1000);
    }
    const Hidetoggle = () => {
        SetHide(!Hide)
    }

    const Deletetoggle = () => {
        Setdeletepost(!Deletepost)
        setDeletepost((prevState) => !prevState)
    }

    const togglefordeleteconfirm = () => {
        setDeletepost((prevState) => !prevState)
    }


    const DeleteModal = () => {
        return (
            <>
                {deletepost ? (
                    <Modal isOpen={deletepost} className='modal-dialog-centered' toggle={togglefordeleteconfirm} >
                        {/* <ModalHeader toggle={toggleViewer}>
                </ModalHeader> */}
                        <ModalHeader className='d-flex justify-content-center'>
                            <h4>Do you really want to delete this post</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center'>
                                <Button color='secondary' onClick={togglefordeleteconfirm} >Cancel</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='primary' onClick={Deletetoggle} >Proceed</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    useEffect(() => {
        // "timer" will be undefined again after the next re-render
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <DeleteModal />
            <Card className={Hide ? 'postCardForSize' : 'cardAnimate'}
            // className={Deletepost ? '' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={18} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                />
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {isShown && (
                            <Card className='reactionFortab shadow-sm'>
                                <div className='d-flex'>
                                    <Button className='btn-icon rounded-circle' color='flat-success'>
                                        👍
                                    </Button>
                                    <Button className='btn-icon rounded-circle' color='flat-success'>
                                        😊
                                    </Button>
                                    <Button className='btn-icon rounded-circle' color='flat-success'>
                                        🤣
                                    </Button>
                                    <Button className='btn-icon rounded-circle' color='flat-success'>
                                        😍
                                    </Button>
                                </div>
                            </Card>
                        )}
                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='LikeIcon' />
                            </div> : <div className='mr-1'>
                                <img
                                    onMouseEnter={Reactionshowtoggle}
                                    onMouseLeave={ReactionHidetoggle}
                                    src={Circlelike} alt='LikeIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>
            <Card className={Hide ? 'd-none' : 'Danimated fadeOut UnodCardForbackcolor'} onClick={Hidetoggle} >
                <div className='d-flex justify-content-between align-items-center m-2'>
                    <div>
                        <EyeOff size={40} />
                        <h4>Unhide me</h4>
                    </div>
                    <div>
                        <Button color='secondary' outline>Undo 02: 00</Button>
                    </div>
                </div>

            </Card>
            &nbsp;

            {/*----------------------------------------------------- 2nd Card */}

            <Card className={Deletepost ? 'postCardForSize' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={15} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                {/* <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                <Thought />
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                {/* like */}
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='shareIcon' />
                            </div> : <div className='mr-1'>
                                <img src={Circlelike} alt='shareIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>

            {/*----------------------------------------------------- 3nd Card */}

            <Card className={Deletepost ? 'postCardForSize' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={15} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                {/* <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                <div className="pvt-event-cont-blk text-dark m-2" style={{ background: '#FF822E', fontWeight: '700' }} >
                    <div className="pvt-eventcont" style={{ background: '#FFD600', color: "#000000" }} >#Moderate</div>
                    <img src={LeftquotationIcon} alt="icon" />
                    <div>
                        <div className='px-4'>Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho Cloud Platform  @DwyaneJhonson Threat Group Takes Aim Again at CloudPlatform Provider Zoho</div>
                        <div className='text-right'>
                            <img src={RightquotationIcon} alt="icon" />
                        </div>
                    </div>
                </div>
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                {/* like */}
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='shareIcon' />
                            </div> : <div className='mr-1'>
                                <img src={Circlelike} alt='shareIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>

            {/*----------------------------------------------------- 4nd Card */}

            <Card className={Deletepost ? 'postCardForSize' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={15} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                {/* <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                <Recommendations />
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                {/* like */}
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='shareIcon' />
                            </div> : <div className='mr-1'>
                                <img src={Circlelike} alt='shareIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>

            {/*----------------------------------------------------- 5nd Card */}

            <Card className={Deletepost ? 'postCardForSize' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={15} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                {/* <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                <Poll />
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                {/* like */}
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='shareIcon' />
                            </div> : <div className='mr-1'>
                                <img src={Circlelike} alt='shareIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>

            {/*----------------------------------------------------- 6nd Card */}

            <Card className={Deletepost ? 'postCardForSize' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={15} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                {/* <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                <Podcast />
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                {/* like */}
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='shareIcon' />
                            </div> : <div className='mr-1'>
                                <img src={Circlelike} alt='shareIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>

            {/*----------------------------------------------------- 7nd Card */}

            <Card className={Deletepost ? 'postCardForSize' : 'removed-item'}
            >
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex'>
                            <img src={UserImg} className='img-fluid' width={40} height={40} alt='UserImage' />
                            <div className='ml-2'>
                                <h4>Sufiya Eliza</h4>
                                <small className='text-muted'>20 Min ago</small>
                            </div>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color='flat-secondary' >
                                    <MoreHorizontal size={15} />
                                </DropdownToggle>
                                <DropdownMenu style={{ zIndex: '2' }}>
                                    <DropdownItem onClick={Hidetoggle} tag='a'>Hide</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem onClick={togglefordeleteconfirm} tag='a'>Delete</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </CardHeader>

                {/* <img
                    className='img-fluid'
                    alt="Sample"
                    src="https://picsum.photos/300/200"
                /> */}
                <Event />
                <CardBody>
                    <div className='d-flex justify-content-between align-items-center '>
                        <div className='d-flex align-items-center '>
                            <AvatarGroup />
                            <p>+12k people like to this post</p>
                        </div>
                        <div onClick={() => SetBookmark(!BookmarkIcon)} style={{ cursor: 'pointer' }}>
                            {
                                BookmarkIcon ? <Bookmark color='#81C14B' size={18} strokeWidth={2} /> : <Bookmark fill='#81C14B' color='#81C14B' size={18} strokeWidth={2} />
                            }
                        </div>
                    </div>
                </CardBody>
                <div className='d-flex justify-content-between border-top p-3'>
                    <div className='text-muted'>
                        😊😂😒😘 +75
                    </div>
                    <div className='d-flex colorForEverything'>
                        <h5 className='text-muted mr-1'><span className='font-weight-bold'>4565</span> Comment</h5>
                        <h5 className='text-muted'><span className='font-weight-bold'>985</span> Share</h5>
                    </div>
                </div>
                {/* like */}
                <div className='d-flex justify-content-around p-3' style={{ background: '#F6F6F6' }}>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} >

                        {
                            LikeIcon ? <div className='mr-1'>
                                <img src={ActiveCirclelike} alt='shareIcon' />
                            </div> : <div className='mr-1'>
                                <img src={Circlelike} alt='shareIcon' />
                            </div>
                        }
                        <h5>Like</h5>
                    </div>
                    <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={Commenttoggle} >
                        {
                            comment ? <div className='backgroundGForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div> : <div className='backgroundForallIcons text-center mr-1'>
                                <img width={15} src={CommentSecIcon} alt='shareIcon' />
                            </div>
                        }
                        <h5>Comment</h5>
                    </div>
                    <div className='d-flex align-items-center'>

                        <div className='backgroundForallIcons text-center mr-1'>
                            <img width={15} src={ShareSecIcon} alt='shareIcon' />
                        </div>
                        <h5>Share</h5>
                    </div>
                </div>
                {
                    comment ?
                        <div className='p-3'>

                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                    <h4>Robert Fox</h4>
                                </div>
                                <h5 className='text-muted pr-1'>40 Mins ago</h5>
                            </div>
                            <div className='p-2 pl-5'>
                                <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                <div className='text-primary'>
                                    Like (5)
                                    &nbsp;
                                    Reply (6)
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-1'>
                                    <div className='d-flex align-items-center'>
                                        <img src={UserImg} className='img-fluid mr-2' width={48} height={48} />
                                        <h4>Aliza Alveen</h4>
                                    </div>
                                    <h5 className='text-muted'>40 Mins ago</h5>
                                </div>
                                <div>
                                    <p className='text-muted'>When you plan a trip you’ll want to know what are the must-visit tourist attractions and the best free things to do in your chosen destination.</p>
                                    <div className='text-primary'>
                                        Like (5)
                                        &nbsp;
                                        Reply (6)
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
                <div className='m-3'>
                    <Input type='text' style={{ background: '#F9FBF7', paddingRight: '33px' }} Placeholder='Write a comment...' />
                    {/* <div className='positionForSerachSmile'> */}
                    <Smile color='#808080' strokeWidth={2} className='positionForSerachSmile' />
                    {/* </div> */}
                </div>

            </Card>

            <Card className={Deletepost ? 'd-none' : 'Danimated fadeOut UnodCardForbackcolor'} onClick={Deletetoggle} >
                <div className='d-flex justify-content-between align-items-center m-2'>
                    <div>
                        <RefreshCcw size={40} />
                        <h4>Restore me</h4>
                    </div>
                    <div>
                        <Button color='secondary' outline>Undo 02: 00</Button>
                    </div>
                </div>

            </Card>
        </div>
    )
}

export default UniversalCard