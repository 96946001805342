import React, { useState } from 'react'
import { Grid, List } from 'react-feather'
import { Button } from 'reactstrap'
import GridPodcast from './Grid-Components/GridPodcast'
// import ListCompoentVideo from './List-Components/ListCompoentVideo'
import ListPodcast from './List-Components/ListPodcast'

const PodCastTabsTrending = () => {
    const [data, setdata] = useState(true)
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                {/* <p className='TrendingPeoplethistext'>TOP PODCASTS</p> */}
                {/* rem-beyond-web */}
                <p className='TrendingPeoplethistext'></p>
                <div className='list-grid-toggle' style={{ marginRight: '8px' }}>
                    <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                        <Grid size={16} />
                    </Button>
                    <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
                        <List size={16} />
                    </Button>
                </div>
            </div>
            {
                data ?
                    <GridPodcast />
                    :
                    <ListPodcast />
            }
        </div>


    )
}
export default PodCastTabsTrending
