// get all biz category
export const GET_BIZ_CATEGORY = "GET_BIZ_CATEGORY";

// get biz sub category
export const GET_BIZ_SUBCATEGORY = "GET_BIZ_SUBCATEGORY";

// Create a bussiness page
export const CREATE_BIZ_PAGE = "CREATE_BIZ_PAGE";

// get user's bussiness page
export const GET_USER_BIZ_PAGE = "GET_USER_BIZ_PAGE";

// get user's single bussiness page
export const GET_SINGLE_BIZ_PAGE = "GET_SINGLE_BIZ_PAGE";