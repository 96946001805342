import React from 'react'
import { Fragment } from 'react'
import AdHeader from './Components/AdHeader'
import './AdUnRegUser.css';
import AdFooter from './Components/AdFooter';
import AdCradPrev from './Components/AdCradPrev';

const AdUnRegUser = () => {
    //=================== Mian_return_function =================//
    return (
        <Fragment>
            <AdHeader />
            <AdCradPrev />
            <AdFooter />
        </Fragment>
    )
}

export default AdUnRegUser
