import React, { useState } from 'react'
import TopPodacastCard from '../TopPodacastCard'

const Allpage = () => {
  const [activeTab, setActiveTab] = useState(false)

  return (
    <>
      {/* <div>
        <TopPodacastCard Tittle='Top Podcast'  />  
      </div>
      <div>
        <TopPodacastCard Tittle='TV & Film' /> 
      </div>
      <div>
        <TopPodacastCard Tittle='Health & Fitness' />  
      </div>
      <div>
        <TopPodacastCard Tittle='Socity & Culture ' />  
      </div> */}
    </>
  )
}

export default Allpage