import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import {
    Button, Col, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown,
} from 'reactstrap'
import '../style.css'
import classnames from 'classnames'
import MyBlockedPeople from './MyBlockedPeople'
import MyJoinRequest from './MyJoinRequest'
import MyPostRequest from "./MyPostRequest"
import MyGroupMember from './MyGroupMember'
import { Link, useParams } from 'react-router-dom'
import Request from "../Img/Request.png"
import Request1 from "../Img/Request1.png"
import Request2 from "../Img/Request2.png"
import Request3 from "../Img/Request3.png"
import Success from "../Img/Success.png"
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { Filter, Share2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { getAllGroupMembers, postGroupRolesAction} from '../../../Services/Actions/Group/getAllGroupMainPageAction';


const MyMember = () => {
    const params = useParams()
    let dispatch = useDispatch();
    const { getAllGroupMembersData } = useSelector((state) =>  state.getAllGroupMembersData );
    const postGroupRolesData = useSelector((state) => { return state.postGroupRolesData });
    const { loading: loadingEvents, error: errorEvents, result: resultEvents } = postGroupRolesData

    // const allMembers = useSelector((state) => state.getAllGroupMembersData);
    // const { loading: loadingAllMembers, error: errorAllMembers, result: allGroupMembers } = allMembers;

        useEffect(() => {
            dispatch(getAllGroupMembers(params.id))
        }, [dispatch, params.id]);
    

    const [searchQuery, setSearchQuery] = useState("");
    const [searchMember, setSearchMember] = useState("");
    
    const [currentActiveTab, setCurrentActiveTab] = useState('1')
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    }
    const [groupRole, setGroupRole] = useState(false)
    const toggleGroup = () => {
        setGroupRole((prevState) => !prevState)
    }
    const [assignGroup, setAssignGroup] = useState(false)
    const toggleClose = () => {
        setGroupRole(false)
        setAssignGroup((prevState) => !prevState)
    }
    const [assignSuccessfully, setAssignSuccessfully] = useState(false)

    const toggleCloseModal = () => {
        setAssignSuccessfully((prevState) => !prevState)
    }
    const toggleSuccessfully = () => {
        setAssignGroup(false)
        setAssignSuccessfully((prevState) => !prevState)
    }
    const [open, setOpen] = useState('1');
    const toggleOpen = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const updateRoleAndClose = () => {
        dispatch(getAllGroupMembers(params.id));
            setAssignSuccessfully(false)
            setAssignGroup(false)
          }

    const applyRole = () => {

        if(sendRole === 'admin'){
         console.log(sendRole,roleEdit, roleUpdate, roleDelete, userId, params.id)
         dispatch(postGroupRolesAction(sendRole,roleEdit, roleUpdate, roleDelete, userId, params.id))
        }
 
        else if (sendRole === "manager"){
         console.log(sendRole,roleEditManager, roleUpdateManager, roleDeleteManager, userId, params.id)
         dispatch(postGroupRolesAction(sendRole,roleEditManager, roleUpdateManager, roleDeleteManager, userId, params.id))
 
        }
 
         setAssignSuccessfully((prevState) => !prevState)
     }
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
    //========================= Array_List_Data =======================//
    const PeopleDataList = [
        {

            img: Request,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "1"
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "2"
        },
        {
            img: Request2,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "3"
        },
        {
            img: Request1,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "4"
        },
        {
            img: Request,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "5"
        }
    ]
    const AssignRoleData = [
        {

            img: Request,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "1"
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "2"
        },
    ]
    //============================-Tab_Section =========================//
console.log('090', searchQuery)
    const [selectedId, setSelectedId] = useState(null);
    // const [assignGroup, setAssignGroup] = useState(false)
    const [memberId, setMemberId] = useState(false)
    const [fullName, setFullName] = useState(false)
    const [followingDate, setFollowingDate] = useState(false)
    const [profileImage, setProfileImage] = useState(false)
    const [userId, setUserId] = useState(false)
    const [role, setRole] = useState(false)
    const [homeAddress, setHomeAddress] = useState(false)

    

    const handleRadioChange = (e, fullName, createdAt, profileImageThumb, userId, homeAddress) => {
        console.log(e, fullName, createdAt, profileImageThumb, userId, homeAddress)
        setSelectedId(e.target.id);
        setFullName(fullName)
        setFollowingDate(createdAt)
        setProfileImage(profileImageThumb)
        setUserId(userId)
        setHomeAddress(homeAddress)        
      };

    // const handleRadioChange = (e) => {
    //     setSelectedId(e.target.id);

    //   };
    const [roleEdit, setRoleEdit] = useState(false)
    const [roleUpdate, setRoleUpdate] = useState(false)
    const [roleDelete, setRoleDelete] = useState(false) 

    const [roleEditManager, setRoleEditManager] = useState(false)
    const [roleUpdateManager, setRoleUpdateManager] = useState(false)
    const [roleDeleteManager, setRoleDeleteManager] = useState(false) 

    const [sendRole, setSendRole] = useState('admin') 


    const handleOptionChangeAdmin = (event) => {
        const { id, checked } = event.target;      
        if (id === "1") {
            alert(id)
          setRoleEdit(checked ? 1 : 0);
        } else if (id === "2") {
            alert(id)
          setRoleUpdate(checked ? 1 : 0);
        } else if (id === "3") {
            alert(id)
          setRoleDelete(checked ? 1 : 0);
        }
      }

 
      const handleOptionChangeManager = (event) => {
        const { id, checked } = event.target;      
        if (id === "7") {
          setRoleEditManager(checked ? 1 : 0);
        } else if (id === "8") {
          setRoleUpdateManager(checked ? 1 : 0);
        } else if (id === "9") {
          setRoleDeleteManager(checked ? 1 : 0);
        }
      }
      

      const cancelAllSelection = (role) => {
        setSendRole(role)

        setRoleEdit(false)
        setRoleUpdate(false)
        setRoleDelete(false)

        setRoleEditManager(false)
        setRoleUpdateManager(false)
        setRoleDeleteManager(false)
      }
      const [basisOnFilter, setBasisOnFilter] = useState('All') 

      const filterOption = (onTheBasis) => {
        setBasisOnFilter(onTheBasis)
      }
      

    const MemberTabSection = () => (
        <Fragment>

            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={
                            classnames({
                                active: currentActiveTab === '1'
                            })}
                        onClick={() => { toggle('1') }}
                    >
                        <span>Group Members</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={
                            classnames({
                                active: currentActiveTab === '2'
                            })}
                        onClick={() => { toggle('2') }}
                    >
                        <span>Blocked People</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={
                            classnames({
                                active: currentActiveTab === '3'
                            })}
                        onClick={() => { toggle('3') }}
                    >
                        <span>Join Request</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={
                            classnames({
                                active: currentActiveTab === '4'
                            })}
                        onClick={() => { toggle('4') }}
                    >
                        <span>Post Request</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                    <MyGroupMember basisOnFilter={basisOnFilter} searchMember = {searchMember}/>
                </TabPane>
                <TabPane tabId="2">
                    <MyBlockedPeople />
                </TabPane>
                <TabPane tabId="3">
                    <MyJoinRequest />
                </TabPane>
                <TabPane tabId="4">
                    <MyPostRequest />
                </TabPane>
            </TabContent>

        </Fragment>
    )

    //=================== Role_Assign_Page_Modal ======================//
    const PeopleData = () => (
        <Fragment>
            <Row className="row align-items-center">
                <Col md="12" sm="12" className="mb-2">
                    <h3>People Search</h3>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md="12" sm="12">
                    <div className="search-block-podcast">
                        <div className="input-search-blk">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                            {/* <input type="text" className="form-control" name="search" placeholder="Search***" data-bs-toggle="modal" data-bs-target="#peopleSearch" /> */}
                            <input
                                type="text"
                                className="form-control"
                                name="search"
                                placeholder="Search" 
                                data-bs-toggle="modal"
                                data-bs-target="#peopleSearch"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                autoFocus
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <div className="group-detail-page-blk mt-2">
                    <Row>
                        {
                               getAllGroupMembersData.data.successResult[0].members
                               .filter((data) =>
                                   data.fullName.toLowerCase().includes(searchQuery.toLowerCase())
                               )
                               .map((data, index) => (
                                <Col md="12" sm="12">
                                    <div className="group-members-block">
                                        <div className="group-member-img-cont-blk">
                                            <img src={data.profileImageThumb} alt="img" className='img-fluid' />
                                            <div className="member-cont-blk">
                                                <h4>{data.fullName}1</h4>
                                                <p>{data.joingData}</p>
                                                <p>{data.homeAddress}</p>
                                            </div>
                                        </div>
                                        <div className='Block-btns-blk'>
                                            {/* <CustomInput type="radio" className='customcheckboxForFilter ' id={data.id} style={{ cursor: "pointer" }} /> */}
                                            <CustomInput
                                                type="radio"
                                                className="customcheckboxForFilter "
                                                id={data.id}
                                                style={{ cursor: "pointer" }}
                                                checked={selectedId === data.id}
                                                onChange={(e) => {handleRadioChange(e, data?.fullName, data?.createdAt, data?.profileImageThumb, data?.userId, data?.homeAddress)}}

                                            />
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }

                    </Row>
                </div>
            </Row>
        </Fragment>
    )
    //=================== Assign_Group_Role_Modal ===================//
    const AssignGroupData = () => (
        <Fragment>
            <Row className="row align-items-center">
                <Col md="12" className="mb-2">
                    <h3>Assign Group Person</h3>
                </Col>
            </Row>
            <hr />
            <Row>
                <div className="group-detail-page-blk mt-2">
                    <Row>

                                <Col md="12" sm="12">
                                    <div className="group-members-block">
                                        <div className="group-member-img-cont-blk">
                                            <img src={profileImage} alt="img" />
                                            <div className="member-cont-blk">
                                                <h4>{fullName}</h4>
                                                <p>{followingDate}</p>
                                                <p>{homeAddress}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                        <Col md="12" sm="12">
                            <Accordion open={open} toggle={toggleOpen}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">
                                        <div className='Assign_Check_Section'>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Assign_Check"
                                                onClick={() => {cancelAllSelection('admin')}}
                                            />
                                            {' '}
                                            <div className='Assign_Sub_Section'>
                                                <h4>Assign as Admin</h4>
                                                <p>Admin can approve or remove posts, followers and messages on the group.</p>
                                            </div>
                                        </div>

                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>

                                            <p className='d-flex align-items-center '>
                                            <Input
                                                type="checkbox"
                                                className='customcheckboxForFilter'
                                                id="1"
                                                name="roleEdit"
                                                style={{ cursor: "pointer" }}
                                                checked={roleEdit}
                                                onChange={handleOptionChangeAdmin}
                                                />&nbsp; Add
                                            </p>
                                            <p className='d-flex align-items-center '>
                                            <Input
                                                type="checkbox"
                                                className='customcheckboxForFilter'
                                                id="2"
                                                name="roleUpdate"
                                                style={{ cursor: "pointer" }}
                                                checked={roleUpdate}
                                                onChange={handleOptionChangeAdmin}
                                                />&nbsp; Update
                                                </p>

                                            <p className='d-flex align-items-center '>
                                            <Input
                                            type="checkbox"
                                            className='customcheckboxForFilter'
                                            id="3"
                                            name="roleDelete"
                                            style={{ cursor: "pointer" }}
                                            checked={roleDelete}
                                            onChange={handleOptionChangeAdmin}
                                            />&nbsp; Delete </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                        <Col md="12" sm="12">
                            <Accordion open={open} toggle={toggleOpen}>
                                <AccordionItem>
                                    <AccordionHeader targetId="2">
                                        <div className='Assign_Check_Section'>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Assign_Check"
                                                onClick={() => {cancelAllSelection('manager')}}
                                            />
                                            {' '}
                                            <div className='Assign_Sub_Section'>
                                                <h4>Assign as Manager</h4>
                                                <p>Manager can publish/unpublish posts and comments on the group.</p>
                                            </div>
                                        </div>

                                    </AccordionHeader>
                                    <AccordionBody>
                                        <div className='d-flex justify-content-around Accordin_Body_Section'>
                                            <p className='d-flex align-items-center '>
                                                <Input type="checkbox" className='customcheckboxForFilter' 
                                                id="7" 
                                                name="roleEditManager"
                                                style={{ cursor: "pointer" }}
                                                checked={roleEditManager}
                                                onChange={handleOptionChangeManager}                                                 
                                                />&nbsp; Add </p>

                                            <p className='d-flex align-items-center '>
                                                <Input type="checkbox" className='customcheckboxForFilter' 
                                                id="8" 
                                                style={{ cursor: "pointer" }} 
                                                name="roleUpdateManager"
                                                checked={roleUpdateManager}
                                                onChange={handleOptionChangeManager}
                                                />&nbsp; Update  </p>

                                            <p className='d-flex align-items-center '>
                                                <Input type="checkbox" className='customcheckboxForFilter' 
                                                id="9" style={{ cursor: "pointer" }} 
                                                name="roleDeleteManager"
                                                checked={roleDeleteManager}
                                                onChange={handleOptionChangeManager}
                                                />&nbsp; Delete </p>
                                        </div>
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </Col>

                    </Row>
                </div>
            </Row>

        </Fragment>
    )

    //======================= Assign_Group_Role_Successfully_Modal ================//

    const SuccessfullyData = () => (
        <Fragment>
            <Row>
                <Col md="12" sm="12">
                    <div className='d-flex justify-content-center'>
                        <img src={Success} alt="succes" className='img-fluid' />
                    </div>
                </Col>
                <Col md="12" sm="12" className="py-2">
                    <div className='d-flex justify-content-center Success_Heading_Section'>
                        <h4 align='center'>
                            {
                                resultEvents ? resultEvents : 'Role Assigned Successfully!' 
                            }
                            
                        </h4>
                    </div>
                </Col>
            </Row>
            <div className="group-detail-page-blk mt-3">
                <Row>

                            <Col md="12" sm="12">
                                <div className="group-members-block">
                                    <div className="group-member-img-cont-blk">
                                        <img src={profileImage} alt="img" />
                                        <div className="member-cont-blk">
                                        <h4>{fullName ? fullName : ''}</h4>
                                            <p>{followingDate ? followingDate : ''}</p>
                                            <p>{role}</p> 
                                        </div>
                                    </div>
                                    <div className='Block-btns-blk'>
                                        <Button className="People_Assign_btn"><p>{role}</p></Button>
                                    </div>
                                </div>
                            </Col>

                </Row>
                <Row>
                    <Col md="12" sm="12">
                        <div className='d-flex justify-content-center'>
                            <Button color='primary' className="Success_Ok_btn" onClick={updateRoleAndClose}><p>Ok</p></Button>
                        </div>
                    </Col>
                </Row>

            </div>
        </Fragment>
    )

    //====================== Main_return_function ===============================//
    return (
        <Fragment>
            <div className="container-fluid section-t-space px-0">
                <div className="page-content">
                    <div className="content-center w-100">
                        <div className="group-page-section section-b-space">
                            <div className="group-page-title">
                                <Row className="row align-items-center">
                                    <Col md="4">
                                        {/* <h3>Current people</h3> */}
                                    </Col>
                                    <Col md="8">
                                        <div className="group-search-assign-filter">
                                            <div className="search-block-podcast">
                                                <div className="input-search-blk">
                                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                    <input type="text" 
                                                    className="form-control" 
                                                    name="search" 
                                                    placeholder="Search" 
                                                    data-bs-toggle="modal" 
                                                    data-bs-target="#peopleSearch" 
                                                    onChange={(e) => setSearchMember(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <button onClick={toggleGroup} className="assign-group-btn">Assign Group Role</button>
                                            <UncontrolledButtonDropdown className="ml-2 Dropwodn_btn_section" >
                                                <DropdownToggle color='primary'  >
                                                    <Filter size={16} />&nbsp; <span>Filter</span>
                                                </DropdownToggle>
                                                <DropdownMenu className="Group_Dropdown_menu">
                                                    <DropdownItem href='#' tag='a' className="Group_DrppDown_Item"
                                                     onClick={() => {filterOption('All')}}
                                                    >All</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem href='#' tag='a' className="Group_DrppDown_Item"
                                                     onClick={() => {filterOption('superAdmin')}}
                                                    >Super Admin</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem href='#' tag='a' className="Group_DrppDown_Item"
                                                     onClick={() => {filterOption('admin')}}
                                                    >Admins</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem href='#' tag='a' className="Group_DrppDown_Item"
                                                     onClick={() => {filterOption('manager')}}
                                                    >Manager</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem href='#' tag='a' className="Group_DrppDown_Item"
                                                     onClick={() => {filterOption('members')}}
                                                    >Members</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>


                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='pplDataModal'>
                                {
                                    groupRole !== false &&
                                    <Modal isOpen={groupRole} toggle={toggleGroup} className='modal-dialog-centered' scrollable >
                                        <ModalBody>
                                            <PeopleData />
                                        </ModalBody>
                                        <ModalFooter className='d-flex justify-content-center'>
                                            <div >
                                                <Button className="People_Cancel_btn" onClick={toggleGroup} outline><p>Cancel</p></Button>
                                                <Button color='primary' onClick={toggleClose} className="People_Assign_btn ml-3"><p>Assign</p></Button>
                                            </div>
                                        </ModalFooter>
                                    </Modal>
                                }
                            </div>
                            <div className='AssignDataModal'>
                                {
                                    assignGroup !== false &&
                                    <Modal isOpen={assignGroup} toggle={toggleClose} className='modal-dialog-centered' scrollable  >
                                        <ModalBody>
                                            <AssignGroupData />
                                        </ModalBody>
                                        <ModalFooter className='d-flex justify-content-center'>
                                            <div>
                                                <Button color='primary'  onClick={applyRole} className="Admin_Modify_btn"><p>Save</p></Button>
                                            </div>
                                        </ModalFooter>

                                    </Modal>
                                }
                            </div>
                            <div className='SuccessfullyModal'>
                                {
                                    assignSuccessfully !== false &&
                                    <Modal isOpen={assignSuccessfully} toggle={toggleSuccessfully} className='modal-dialog-centered' scrollable  >
                                        <ModalBody>
                                            <SuccessfullyData />
                                        </ModalBody>

                                    </Modal>
                                }
                            </div>
                        </div>
                        <MemberTabSection />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MyMember