
// get user profile by user id
export const GET_PROFILE_BY_USER_ID = "GET_PROFILE_BY_USER_ID";

// User Profile sub modules(Movies,Sports,Educations,Musics)

export const GET_USERS_MOVIES = "GET_USERS_MOVIES";

export const GET_USERS_MUSICS = "GET_USERS_MUSICS";

export const GET_USERS_EDUCATIONS = "GET_USERS_EDUCATIONS";

export const GET_ALL_HOBBIES = "GET_ALL_HOBBIES";

export const GET_ALL_PROFESSION = "GET_ALL_PROFESSION";

export const GET_ALL_LANGUAGES = "GET_ALL_LANGUAGES";

export const GET_ALL_MARITAL_STATUS = "GET_ALL_MARITAL_STATUS";

// get all interest
export const GET_ALL_INTERESTS = "GET_ALL_INTERESTS";
export const GET_ALL_INTERESTS_SUCCESS = "GET_ALL_INTERESTS_SUCCESS";
export const GET_ALL_INTERESTS_ERROR = "GET_ALL_INTERESTS_ERROR";

// add interest
export const ADD_INTERESTS = "ADD_INTERESTS";


// ALL ABOUTS USER PROFILE SPORTS
export const GET_USERS_SPORTS = "GET_USERS_SPORTS";
export const GET_USERS_HOBBIES = "GET_USERS_HOBBIES";
export const LOAD_ALL_SPORTS = "LOAD_ALL_SPORTS";
export const ADD_SPORTS = "ADD_SPORTS";

// UPDATE USER DATA
export const UPDATE_USER_BIO = "UPDATE_USER_BIO";

// GET SUGGESTED USERS
export const GET_SUGGESTED_USERS = "GET_SUGGESTED_USERS";

// GET USER following request
export const GET_FOLLOWING_REQUESTS = "GET_FOLLOWING_REQUESTS";

//GET USER SHOTZ DATA
export const GET_USER_SHOTZ_DATA = "GET_USER_SHOTZ_DATA";

//upload profile and cover
export const UPLOAD_FILE = "UPLOAD_FILE";

// HOBBIES
export const GET_ALL_USER_HOBBIES = "GET_ALL_USER_HOBBIES";
export const GET_ALL_USER_HOBBIES_SUCCESS = "GET_ALL_USER_HOBBIES_SUCCESS";
export const GET_ALL_USER_HOBBIES_ERROR = "GET_ALL_USER_HOBBIES_ERROR";

export const GET_ALL_AVAILABLE_HOBBIES = "GET_ALL_AVAILABLE_HOBBIES";
export const GET_ALL_AVAILABLE_HOBBIES_SUCCESS = "GET_ALL_AVAILABLE_HOBBIES_SUCCESS";
export const GET_ALL_AVAILABLE_HOBBIES_ERROR = "GET_ALL_AVAILABLE_HOBBIES_ERROR";

export const POST_USER_HOBBIES = "POST_USER_HOBBIES";
export const POST_USER_HOBBIES_SUCCESS = "POST_USER_HOBBIES_SUCCESS";
export const POST_USER_HOBBIES_ERROR = "POST_USER_HOBBIES_ERROR";

export const GET_ALL_ADMIN_INTEREST = "GET_ALL_ADMIN_INTEREST";
export const GET_ALL_ADMIN_INTEREST_SUCCESS = "GET_ALL_ADMIN_INTEREST_SUCCESS";
export const GET_ALL_ADMIN_INTEREST_ERROR = "GET_ALL_ADMIN_INTEREST_ERROR";

export const GET_ALL_USER_INTEREST = "GET_ALL_USER_INTEREST";
export const GET_ALL_USER_INTEREST_SUCCESS = "GET_ALL_USER_INTEREST_SUCCESS";
export const GET_ALL_USER_INTEREST_ERROR = "GET_ALL_USER_INTEREST_ERROR";

export const POST_ALL_USER_INTEREST = "POST_ALL_USER_INTEREST";
export const POST_ALL_USER_INTEREST_SUCCESS = "POST_ALL_USER_INTEREST_SUCCESS";
export const POST_ALL_USER_INTEREST_ERROR = "POST_ALL_USER_INTEREST_ERROR";

export const GET_ALL_ADMIN_SPORTS = "GET_ALL_ADMIN_SPORTS";
export const GET_ALL_ADMIN_SPORTS_SUCCESS = "GET_ALL_ADMIN_SPORTS_SUCCESS";
export const GET_ALL_ADMIN_SPORTS_ERROR = "GET_ALL_ADMIN_SPORTS_ERROR";

export const GET_ALL_USER_SPORTS = "GET_ALL_USER_SPORTS";
export const GET_ALL_USER_SPORTS_SUCCESS = "GET_ALL_USER_SPORTS_SUCCESS";
export const GET_ALL_USER_SPORTS_ERROR = "GET_ALL_USER_SPORTS_ERROR";


export const POST_ALL_USER_SPORTS = "POST_ALL_USER_SPORTS";
export const POST_ALL_USER_SPORTS_SUCCESS = "POST_ALL_USER_SPORTS_SUCCESS";
export const POST_ALL_USER_SPORTS_ERROR = "POST_ALL_USER_SPORTS_ERROR";

export const GET_ALL_USER_MOVIES = "GET_ALL_USER_MOVIES";
export const GET_ALL_USER_MOVIES_SUCCESS = "GET_ALL_USER_MOVIES_SUCCESS";
export const GET_ALL_USER_MOVIES_ERROR = "GET_ALL_USER_MOVIES_ERROR";

export const GET_ALL_USER_MUSIC = "GET_ALL_USER_MUSIC";
export const GET_ALL_USER_MUSIC_SUCCESS = "GET_ALL_USER_MUSIC_SUCCESS";
export const GET_ALL_USER_MUSIC_ERROR = "GET_ALL_USER_MUSIC_ERROR";

export const GET_ALL_USER_BOOK = "GET_ALL_USER_BOOK";
export const GET_ALL_USER_BOOK_SUCCESS = "GET_ALL_USER_BOOK_SUCCESS";
export const GET_ALL_USER_BOOK_ERROR = "GET_ALL_USER_BOOK_ERROR";

export const GET_ALL_USER_BIZPAGE = "GET_ALL_USER_BIZPAGE";
export const GET_ALL_USER_BIZPAGE_SUCCESS = "GET_ALL_USER_BIZPAGE_SUCCESS";
export const GET_ALL_USER_BIZPAGE_ERROR = "GET_ALL_USER_BIZPAGE_ERROR";

export const UPDATE_ISPRIMARY_SUCCESS = "UPDATE_ISPRIMARY_SUCCESS";
export const UPDATE_ISPRIMARY_ERROR = "UPDATE_ISPRIMARY_ERROR";

export const ADD_PROFESSION = "ADD_PROFESSION";
export const ADD_PROFESSION_SUCCESS = "ADD_PROFESSION_SUCCESS";
export const ADD_PROFESSION_ERROR = "ADD_PROFESSION_ERROR";

export const GET_ALL_SCHOOL = "GET_ALL_SCHOOL";
export const GET_ALL_SCHOOL_SUCCESS = "GET_ALL_SCHOOL_SUCCESS";
export const GET_ALL_SCHOOL_ERROR = "GET_ALL_SCHOOL_ERROR";

export const ADD_SCHOOL_COLLEGE = "ADD_SCHOOL_COLLEGE";
export const ADD_SCHOOL_COLLEGE_SUCCESS = "ADD_SCHOOL_COLLEGE_SUCCESS";
export const ADD_SCHOOL_COLLEGE_ERROR = "ADD_SCHOOL_COLLEGE_ERROR";

export const GET_QUALIFICATION = "GET_QUALIFICATION";
export const GET_QUALIFICATION_SUCCESS = "GET_QUALIFICATION_SUCCESS";
export const GET_QUALIFICATION_ERROR = "GET_QUALIFICATION_ERROR";

export const ADD_QUALIFICATION = "ADD_QUALIFICATION";
export const ADD_QUALIFICATION_SUCCESS = "ADD_QUALIFICATION_SUCCESS";
export const ADD_QUALIFICATION_ERROR = "ADD_QUALIFICATION_ERROR";

export const ADD_USER_EDUCATION = "ADD_USER_EDUCATION";
export const ADD_USER_EDUCATION_SUCCESS = "ADD_USER_EDUCATION_SUCCESS";
export const ADD_USER_EDUCATION_ERROR = "ADD_USER_EDUCATION_ERROR";

export const UPDATE_USER_EDUCATION = "UPDATE_USER_EDUCATION";
export const UPDATE_USER_EDUCATION_SUCCESS = "UPDATE_USER_EDUCATION_SUCCESS";
export const UPDATE_USER_EDUCATION_ERROR = "UPDATE_USER_EDUCATION_ERROR";

export const DELETE_USER_EDUCATION = "DELETE_USER_EDUCATION";
export const DELETE_USER_EDUCATION_SUCCESS="DELETE_USER_EDUCATION_SUCCESS"
export const DELETE_USER_EDUCATION_ERROR="DELETE_USER_EDUCATION_ERROR"

export const GET_ALL_POST_USER_MADE = "GET_ALL_POST_USER_MADE";
export const GET_ALL_POST_USER_MADE_SUCCESS = "GET_ALL_POST_USER_MADE_SUCCESS";
export const GET_ALL_POST_USER_MADE_ERROR = "GET_ALL_POST_USER_MADE_ERROR";

export const POST_HIDE_UNHIDE = "POST_HIDE_UNHIDE";
export const POST_HIDE_UNHIDE_SUCCESS = "POST_HIDE_UNHIDE_SUCCESS";
export const POST_HIDE_UNHIDE_ERROR = "POST_HIDE_UNHIDE_ERROR";

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";

export const UNDO_SHOW_POST = "UNDO_SHOW_POST";
export const UNDO_SHOW_POST_SUCCESS = "UNDO_SHOW_POST_SUCCESS";
export const UNDO_SHOW_POST_ERROR = "UNDO_SHOW_POST_ERROR";

export const GET_USER_FOLLOWERS_SHARE = "GET_USER_FOLLOWERS_SHARE";
export const GET_USER_FOLLOWERS_SHARE_SUCCESS = "GET_USER_FOLLOWERS_SHARE_SUCCESS";
export const GET_USER_FOLLOWERS_SHARE_ERROR = "GET_USER_FOLLOWERS_SHARE_ERROR";

export const GET_USER_CONNECTIONS = "GET_USER_CONNECTIONS";
export const GET_USER_CONNECTIONS_SUCCESS = "GET_USER_CONNECTIONS_SUCCESS";
export const GET_USER_CONNECTIONS_ERROR = "GET_USER_CONNECTIONS_ERROR";

export const POST_RE_SHARE = "POST_RE_SHARE";
export const POST_RE_SHARE_SUCCESS = "POST_RE_SHARE_SUCCESS";
export const POST_RE_SHARE_ERROR = "POST_RE_SHARE_ERROR";

export const GET_EVENTS_BY_USER = "GET_EVENTS_BY_USER";
export const GET_EVENTS_BY_USER_SUCCESS = "GET_EVENTS_BY_USER_SUCCESS";
export const GET_EVENTS_BY_USER_ERROR = "GET_EVENTS_BY_USER_ERROR";

export const GET_TEXT_POSTS = "GET_TEXT_POSTS";
export const GET_TEXT_POSTS_SUCCESS = "GET_TEXT_POSTS_SUCCESS";
export const GET_TEXT_POSTS_ERROR = "GET_TEXT_POSTS_ERROR";

export const GET_IMAGE_POSTS = "GET_IMAGE_POSTS";
export const GET_IMAGE_POSTS_SUCCESS = "GET_IMAGE_POSTS_SUCCESS";
export const GET_IMAGE_POSTS_ERROR = "GET_IMAGE_POSTS_ERROR";

export const GET_ALLPOLLL = "GET_ALLPOLLL";
export const GET_ALLPOLL_SUCCESS = "GET_ALLPOLL_SUCCESS";
export const GET_ALLPOLL_ERROR = "GET_ALLPOLL_ERROR";

export const GET_RECOMMENDATION_POSTS = "GET_RECOMMENDATION_POSTS";
export const GET_RECOMMENDATION_SUCCESS = "GET_RECOMMENDATION_SUCCESS";
export const GET_RECOMMENDATION_ERROR = "GET_RECOMMENDATION_ERROR";

export const GET_MARKET_PLACE_PRODUCTS = "GET_MARKET_PLACE_PRODUCTS";
export const GET_MARKET_PLACE_PRODUCTS_SUCCESS = "GET_MARKET_PLACE_PRODUCTS_SUCCESS";
export const GET_MARKET_PLACE_PRODUCTS_ERROR = "GET_MARKET_PLACE_PRODUCTS_ERROR";

export const HIDE_ALL_POSTS_FROM_TIMELINE = "HIDE_ALL_POSTS_FROM_TIMELINE";
export const HIDE_ALL_POSTS_FROM_TIMELINE_SUCCESS = "HIDE_ALL_POSTS_FROM_TIMELINE_SUCCESS";
export const HIDE_ALL_POSTS_FROM_TIMELINE_ERROR = "HIDE_ALL_POSTS_FROM_TIMELINE_ERROR";

export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
export const GET_ALL_COMMENTS_ERROR = "GET_ALL_COMMENTS_ERROR";

export const GET_ALL_LIKE = "GET_ALL_LIKE";
export const GET_ALL_LIKE_SUCCESS = "GET_ALL_LIKE_SUCCESS";
export const GET_ALL_LIKE_ERROR = "GET_ALL_LIKE_ERROR";

export const GET_ALL_SHARE = "GET_ALL_SHARE";
export const GET_ALL_SHARE_SUCCESS = "GET_ALL_SHARE_SUCCESS";
export const GET_ALL_SHARE_ERROR = "GET_ALL_SHARE_ERROR";

export const POST_ADD_COMMENT = "POST_ADD_COMMENT";
export const POST_ADD_COMMENT_SUCCESS = "POST_ADD_COMMENT_SUCCESS";
export const POST_ADD_COMMENT_ERROR = "POST_ADD_COMMENT_ERROR";

export const INVITE_FRIENDS_TO_EVENT = "INVITE_FRIENDS_TO_EVENT";
export const INVITE_FRIENDS_TO_EVENT_SUCCESS = "INVITE_FRIENDS_TO_EVENT_SUCCESS";
export const INVITE_FRIENDS_TO_EVENT_ERROR = "INVITE_FRIENDS_TO_EVENT_ERROR";

export const TURN_ON_OFF_NOTIFICATION_TEXT_POST = "TURN_ON_OFF_NOTIFICATION_TEXT_POST";
export const TURN_ON_OFF_NOTIFICATION_TEXT_POST_SUCCESS = "TURN_ON_OFF_NOTIFICATION_TEXT_POST_SUCCESS";
export const TURN_ON_OFF_NOTIFICATION_TEXT_POST_ERROR = "TURN_ON_OFF_NOTIFICATION_TEXT_POST_ERROR";
// Shotz
export const SHARE_SHOTZ= "SHARE_SHOTZ";
export const SHARE_SHOTZ_SUCCESS= "SHARE_SHOTZ_SUCCESS";
export const SHARE_SHOTZ_ERROR= "SHARE_SHOTZ_ERROR";
// sonu
export const GET_ALL_OWNED_PINNED_BUSINESSPAGE = "GET_ALL_OWNED_PINNED_BUSINESSPAGE";
export const GET_ALL_OWNED_PINNED_BUSINESSPAGE_SUCCESS = "GET_ALL_OWNED_PINNED_BUSINESSPAGE_SUCCESS";
export const GET_ALL_OWNED_PINNED_BUSINESSPAGE_ERROR = "GET_ALL_OWNED_PINNED_BUSINESSPAGE_ERROR";


export const GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE = "GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE";
export const GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_SUCCESS = "GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_SUCCESS";
export const GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_ERROR = "GET_ALL_OWNED_FOLLOWED_PINNED_BUSINESSPAGE_ERROR";



//fetch all list of owned pinned group page

// export const GET_ALL_OWNED_PINNED_GROUPPAGE = "GET_ALL_OWNED_PINNED_GROUPPAGE";
// export const GET_ALL_OWNED_PINNED_GROUPPAGE_SUCCESS = "GET_ALL_OWNED_PINNED_GROUPPAGE_SUCCESS";
// export const GET_ALL_OWNED_PINNED_GROUPPAGE_ERROR = "GET_ALL_OWNED_PINNED_GROUPPAGE_ERROR";



//getPeopleList
export const GET_PEOPLE_LIST = "GET_PEOPLE_LIST";
export const GET_PEOPLE_LIST_SUCCESS = "GET_PEOPLE_LIST_SUCCESS";
export const GET_PEOPLE_LIST_ERROR = "GET_PEOPLE_LIST_ERROR";


//GET ALL CATEGORY LIST OF BIZPAGE
export const GET_ALL_CATEGORYLIST = "GET_ALL_CATEGORYLIST";
export const GET_ALL_CATEGORYLIST_SUCCESS = "GET_ALL_CATEGORYLIST_SUCCESS";
export const GET_ALL_CATEGORYLIST_ERROR = "GET_ALL_CATEGORYLIST_ERROR";

export const GET_ALL_SUB_CATEGORY = "GET_ALL_SUB_CATEGORY";
export const GET_ALL_SUB_CATEGORY_SUCCESS = "GET_ALL_SUB_CATEGORY_SUCCESS";
export const GET_ALL_SUB_CATEGORY_ERROR = "GET_ALL_SUB_CATEGORY_ERROR";

export const CREATE_BIZ_PAGE = "CREATE_BIZ_PAGE";
export const CREATE_BIZ_PAGE_SUCCESS = "CREATE_BIZ_PAGE_SUCCESS";
export const CREATE_BIZ_PAGE_ERROR = "CREATE_BIZ_PAGE_ERROR";

export const BIZ_PAGE_NOTIFICATION = "BIZ_PAGE_NOTIFICATION";
export const BIZ_PAGE_NOTIFICATION_SUCCESS = "BIZ_PAGE_NOTIFICATION_SUCCESS";
export const BIZ_PAGE_NOTIFICATION_ERROR = "BIZ_PAGE_NOTIFICATION_ERROR";

export const BIZ_PAGE_PINNED = "BIZ_PAGE_PINNED";
export const BIZ_PAGE_PINNED_SUCCESS = "BIZ_PAGE_PINNED_SUCCESS";
export const BIZ_PAGE_PINNED_ERROR = "BIZ_PAGE_PINNED_ERROR";

export const ADD_BIZ_PAGE_CATALOGUE = "ADD_BIZ_PAGE_CATALOGUE";
export const ADD_BIZ_PAGE_CATALOGUE_SUCCESS = "ADD_BIZ_PAGE_CATALOGUE_SUCCESS";
export const ADD_BIZ_PAGE_CATALOGUE_ERROR = "ADD_BIZ_PAGE_CATALOGUE_ERROR";


export const GET_BIZ_PAGE_CATALOGUE = "GET_BIZ_PAGE_CATALOGUE";
export const GET_BIZ_PAGE_CATALOGUE_SUCCESS = "GET_BIZ_PAGE_CATALOGUE_SUCCESS";
export const GET_BIZ_PAGE_CATALOGUE_ERROR = "GET_BIZ_PAGE_CATALOGUE_ERROR"; 

export const GET_BIZ_PAGE_REVIEW = "GET_BIZ_PAGE_REVIEW";
export const GET_BIZ_PAGE_REVIEW_SUCCESS = "GET_BIZ_PAGE_REVIEW_SUCCESS";
export const GET_BIZ_PAGE_REVIEW_ERROR = "GET_BIZ_PAGE_REVIEW_ERROR"; 

export const GET_BIZ_PAGE_RATING_SUMMARY = "GET_BIZ_PAGE_RATING_SUMMARY";
export const GET_BIZ_PAGE_RATING_SUMMARY_SUCCESS = "GET_BIZ_PAGE_RATING_SUMMARY_SUCCESS";
export const GET_BIZ_PAGE_RATING_SUMMARY_ERROR = "GET_BIZ_PAGE_RATING_SUMMARY_ERROR"; 

export const ADD_BIZ_PAGE_RATING_AND_REVIEW = "ADD_BIZ_PAGE_RATING_AND_REVIEW";
export const ADD_BIZ_PAGE_RATING_AND_REVIEW_SUCCESS = "ADD_BIZ_PAGE_RATING_AND_REVIEW_SUCCESS";
export const ADD_BIZ_PAGE_RATING_AND_REVIEW_ERROR = "ADD_BIZ_PAGE_RATING_AND_REVIEW_ERROR"; 

export const ADD_BIZ_PAGE_QUESTION = "ADD_BIZ_PAGE_QUESTION";
export const ADD_BIZ_PAGE_QUESTION_SUCCESS = "ADD_BIZ_PAGE_QUESTION_SUCCESS";
export const ADD_BIZ_PAGE_QUESTION_ERROR = "ADD_BIZ_PAGE_QUESTION_ERROR"; 

export const GET_All_QUESTION_AND_ANSWER= "GET_All_QUESTION_AND_ANSWER";
export const GET_All_QUESTION_AND_ANSWER_SUCCESS = "GET_All_QUESTION_AND_ANSWER_SUCCESS";
export const GET_All_QUESTION_AND_ANSWER_ERROR = "GET_All_QUESTION_AND_ANSWER_ERROR"; 

export const GET_ABOUT_US= "GET_ABOUT_US";
export const GET_ABOUT_US_SUCCESS = "GET_ABOUT_US_SUCCESS";
export const GET_ABOUT_US_ERROR = "GET_ABOUT_US_ERROR"; 

export const POST_PIN_OR_UNPIB_BIZPAGE= "POST_PIN_OR_UNPIB_BIZPAGE";
export const POST_PIN_OR_UNPIB_BIZPAGE_SUCCESS = "POST_PIN_OR_UNPIB_BIZPAGE_SUCCESS";
export const POST_PIN_OR_UNPIB_BIZPAGE_ERROR = "POST_PIN_OR_UNPIB_BIZPAGE_ERROR"; 

export const DETELE_BIZPAGE= "DETELE_BIZPAGE";
export const DETELE_BIZPAGE_SUCCESS = "DETELE_BIZPAGE_SUCCESS";
export const DETELE_BIZPAGE_ERROR = "DETELE_BIZPAGE_ERROR"; 

export const GET_INSTANT_MESSAGE_BIZPAGE= "GET_INSTANT_MESSAGE_BIZPAGE";
export const GET_INSTANT_MESSAGE_BIZPAGE_SUCCESS = "GET_INSTANT_MESSAGE_BIZPAGE_SUCCESS";
export const GET_INSTANT_MESSAGE_BIZPAGE_ERROR = "GET_INSTANT_MESSAGE_BIZPAGE_ERROR"; 

export const INSTANT_MESSAGE_BIZPAGE= "INSTANT_MESSAGE_BIZPAGE";
export const INSTANT_MESSAGE_BIZPAGE_SUCCESS = "INSTANT_MESSAGE_BIZPAGE_SUCCESS";
export const INSTANT_MESSAGE_BIZPAGE_ERROR = "INSTANT_MESSAGE_BIZPAGE_ERROR"; 

export const GET_BIZ_PAGE_DETAILS= "GET_BIZ_PAGE_DETAILS";
export const GET_BIZ_PAGE_DETAILS_SUCCESS = "GET_BIZ_PAGE_DETAILS_SUCCESS";
export const GET_BIZ_PAGE_DETAILS_ERROR = "GET_BIZ_PAGE_DETAILS_ERROR"; 

export const POST_NOTIFICATION_SETTINGS= "POST_NOTIFICATION_SETTINGS";
export const POST_NOTIFICATION_SETTINGS_SUCCESS = "POST_NOTIFICATION_SETTINGS_SUCCESS";
export const POST_NOTIFICATION_SETTINGS_ERROR = "POST_NOTIFICATION_SETTINGS_ERROR"; 

export const GET_BIZ_PAGE_ROLE= "GET_BIZ_PAGE_ROLE";
export const GET_BIZ_PAGE_ROLE_SUCCESS = "GET_BIZ_PAGE_ROLE_SUCCESS";
export const GET_BIZ_PAGE_ROLE_ERROR = "GET_BIZ_PAGE_ROLE_ERROR"; 

export const REMOVE_ROLE_FROM_BIZ_PAGE= "REMOVE_ROLE_FROM_BIZ_PAGE";
export const REMOVE_ROLE_FROM_BIZ_PAGE_SUCCESS = "REMOVE_ROLE_FROM_BIZ_PAGE_SUCCESS";
export const REMOVE_ROLE_FROM_BIZ_PAGE_ERROR = "REMOVE_ROLE_FROM_BIZ_PAGE_ERROR"; 

export const GET_BIZ_PAGE_FOLLOWER_LIST= "GET_BIZ_PAGE_FOLLOWER_LIST";
export const GET_BIZ_PAGE_FOLLOWER_LIST_SUCCESS = "GET_BIZ_PAGE_FOLLOWER_LIST_SUCCESS";
export const GET_BIZ_PAGE_FOLLOWER_LIST_ERROR = "GET_BIZ_PAGE_FOLLOWER_LIST_ERROR"; 

export const SET_ASSIGN_ROLE= "SET_ASSIGN_ROLE";
export const SET_ASSIGN_ROLE_SUCCESS = "SET_ASSIGN_ROLE_SUCCESS";
export const SET_ASSIGN_ROLE_ERROR = "SET_ASSIGN_ROLE_ERROR"; 

export const GET_FOLLOWED_BY_BIZ_PAGE_USERS= "GET_FOLLOWED_BY_BIZ_PAGE_USERS";
export const GET_FOLLOWED_BY_BIZ_PAGE_USERS_SUCCESS = "GET_FOLLOWED_BY_BIZ_PAGE_USERS_SUCCESS";
export const GET_FOLLOWED_BY_BIZ_PAGE_USERS_ERROR = "GET_FOLLOWED_BY_BIZ_PAGE_USERS_ERROR"; 

export const BLOCK_BIZ_PAGE_USERS= "BLOCK_BIZ_PAGE_USERS";
export const BLOCK_BIZ_PAGE_USERS_SUCCESS = "BLOCK_BIZ_PAGE_USERS_SUCCESS";
export const BLOCK_BIZ_PAGE_USERS_ERROR = "BLOCK_BIZ_PAGE_USERS_ERROR"; 

export const GET_BP_BLOCKED_fOLLOWER= "GET_BP_BLOCKED_fOLLOWER";
export const GET_BP_BLOCKED_fOLLOWER_SUCCESS = "GET_BP_BLOCKED_fOLLOWER_SUCCESS";
export const GET_BP_BLOCKED_fOLLOWER_ERROR = "GET_BP_BLOCKED_fOLLOWER_ERROR"; 

export const GET_BP_HISTORY = "GET_BP_HISTORY";
export const GET_BP_HISTORY_SUCCESS = "GET_BP_HISTORY_SUCCESS";
export const GET_BP_HISTORY_ERROR = "GET_BP_HISTORY_ERROR"; 

