import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { UploadCloud, X } from 'react-feather'

const MpImageUploadSection = () => {


    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageSelect = (event) => {
        const fileName = event.target.files[0];
        if (fileName) {
            const url = URL.createObjectURL(fileName);
            setSelectedImages((prevImages) => [...prevImages, url]);
        }
    };

    const handleRemoveImage = (index) => {
        setSelectedImages((prevImages) =>
            prevImages.filter((image, i) => i !== index)
        );
    };




    return (
        <Fragment>
            <div className="">
                {/* <div className="Mp_FormIncludeSomeDetails px-4 mt-3 col-lg-12">
                    <h4>UPLOAD ITEM PHOTOS*</h4>
                </div> */}
                <div className="col-md-12 Mp_Form_ImageUploadInputs  gap-2  " >
                    {selectedImages.map((imageUrl, index) => (
                        <div
                            key={index}
                            className="MpChooseImage position-relative"
                            style={{ background: ' #F5F6FA' }}
                        >
                            <img
                                src={imageUrl}
                                alt={`imageCont-${index}`}
                                className="w-100 h-100"
                                style={{ objectFit: 'cover', borderRadius: '20px' }}
                            />
                            <X
                                size={12}
                                className="ImageCrossButton_Mp"
                                onClick={() => handleRemoveImage(index)}
                            />
                        </div>
                    ))}

                    {/* Add more upload options button */}
                    {/* <div className="MpChooseImage position-relative" style={{ background: ' #F5F6FA' }}>
                        <UploadCloud
                            size={30}
                            color={'#B3BAC2'}
                            onClick={() => document.querySelector('.SelectInput').click()}
                            style={{ boxSizing: 'content-box', padding: '35%' }}
                        />
                        <input
                            type="file"
                            name=""
                            onChange={handleImageSelect}
                            className="SelectInput d-none"
                        />
                    </div> */}
                    <div className="profile-cover profile-cover-new ">
                        <a href="#" className="btn BizCreationbtn-solidUpload btn-group btn-grp-new">
                            <ul>
                                <li className="choose-file">
                                    <a className='UploadBtn' href="">
                                        <UploadCloud size={14} onClick={() => document.querySelector('.SelectInput').click()} />&nbsp;
                                        Upload
                                    </a>
                                    <input type="file" onChange={handleImageSelect} />
                                </li>
                            </ul>
                        </a>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default MpImageUploadSection



