import React from 'react'
import { Fragment } from 'react'
import { Link} from 'react-router-dom'
import { CardImg, Col, Row } from 'reactstrap'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import GroupInvitation from '../AllGroupsSections/GroupInvitationSection'

const NewGrpCreateSection = () => {
   // ================== main_return_function==================//
  return (
    <Fragment>
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <div className="create-biz-page-block">

            <Row>
              <Col md="6">
                <CardImg
                  alt="Card image cap"
                  src="/assets/images/grp/crt-grp.png"
                  style={{
                    height: 361
                  }}
                  width="100%"
                />
              </Col>
              <Col md="6" className="d-flex align-items-center">

                <div className="new-create-grp-content">
                  <h1 className='mb-2'>Create Your Group</h1>
                  <p className='grp-create-p mb-4'>
                    Create a group & express yourself. Get connected to the people around the world with similar interest.
                  </p>
                  <div className="group-buttons-blk mb-2">
                    <Link to="/Group/create" className="group-btn green-clr-btn grp-create-btn">Create Group</Link>
                  </div>
                  <div className="groupmember-blk mb-2">
                    <span>By clicking create group, you agree to the</span>
                  </div>
                  <div className="groupmember-blk mb-2">
                    <span><Link to="#" >Sociomee terms & Conditions.</Link></span>
                  </div>
                </div>

              </Col>
            </Row>
          </div>
          <div className="container-fluid section-t-space px-0">
            <div className="group-main-block">
              <div className="group-custom-block">
                <GroupInvitation />
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>

    </Fragment>
  )
}

export default NewGrpCreateSection
