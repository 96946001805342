import React, { useRef, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";


// MUI Dialog box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Notification from "./Notification-component/Notification";
import { Menu, MenuItem, Typography } from "@mui/material";
import { Badge as Badgemui } from "@mui/material";


import axios from "axios";
import {
  PopoverHeader,
  Input,
  ButtonDropdown,
  Badge,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileByUserId } from "../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import {
  ArrowRightCircle,
  Bell,
  Bold,
  Circle,
  Filter,
  Search,
  TrendingUp,
  X,
} from "react-feather";
import Vector from "../../src/components/Notification-component/Img/Vector.png";
import { MoreHorizontal, Settings } from "react-feather";
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Pollimg from "../NewComponents/IMG/roundedimg2.svg";
import Pollimg1 from "../NewComponents/IMG/roundedimg3.svg";
import NotificationIcon from "../NewComponents/IMG/bell.svg";
import MsgMee from "../NewComponents/IMG/Msg.svg";
import HeaderCenter from "./Header-components/HeaderCenter";
import AppGridView from "./Header-components/AppGridView";
import FooterData from "./Header-components/FooterData";
import { useUserAuth } from "../Context/userAuthContext";
import NotificationView from "./Notification-component/NotificationView";
import $ from 'jquery';
import { loadSearchdata } from "../Services/Actions/mainSearchAction";
import { Try } from "@mui/icons-material";

const Header = () => {
  let user = JSON.parse(localStorage.getItem('sociomeeUser'));
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef(null);
  const [searchKey, setSearchKey] = useState("");
  const [pageIndex, setpageIndex] = useState(0)
  const [radius, setradius] = useState(1500)
  const [sortBy, setsortBy] = useState("")
  const [long, setlong] = useState(70.78746)
  const [lat, setlat] = useState(22.29768)
  const [location, setlocation] = useState("")
  const [pageSize, setpageSize] = useState(10)
  const [reloadData, setreloadData] = useState(false)
  //===================== end_Mobile_ViewData ====================//




  const Menubar = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <div>
          <MoreHorizontal
            color="#808080"
            size={17}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <img src={Vector} alt="check-icon " className="mr-2" /> Mark all
              as read{" "}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Settings size={18} color="black" className="mr-2" /> Notification
              Setting{" "}
            </MenuItem>
          </Menu>
        </div>
      </>
    );
  };

  const searchResult  = useSelector(
    (state) => state.mainSearchReducer.result
  );

  const dispatch = useDispatch();
  const [formDropdown, setFormDropdown] = useState(false);
  const toggleFormDropdown = () => setFormDropdown(!formDropdown);

  // MUI State
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();

  // Mobile Menu Button
  const olRef = useRef(null);
  const menuRef = useRef(null);
  // const [userData, setUserData] = useState();
  const [scrollInnerModal, setScrollInnerModal] = useState(false);
  const menuBtnClick = (e) => {
    olRef.current.classList.add("show");
    menuRef.current.classList.add("show");
  };
  const closeMenuBtnClick = (e) => {
    olRef.current.classList.remove("show");
    menuRef.current.classList.remove("show");
  };

  const logoutUser = () => {
    setOpen(false);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
          }`,
      },
    };
    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    const logoutBody = {
      userId : user.id,
      sessionId : user.sessionId
    };

    axios
      .post(`${process.env.REACT_APP_IPURL}/user/logOut/`, logoutBody, config)
      .then((respo) => {
        if (respo.data.data?.successResult) {
          localStorage.removeItem("sociomeeUser");
          navigate("/");
        }
      })
      .catch((err) => {
        localStorage.removeItem("sociomeeUser");
        navigate("/");
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { searchHide, setsearchShow } = useUserAuth()
  const toggle = () => {
    setsearchShow('/header')
  }

  // const makePostRequest = async () => {
  //   try {
  //     const result = await axios.post(
  //       `${process.env.REACT_APP_IPURL}/user/getUserProfileById`,
  //       { userId: user.id },
  //       { headers: { Authorization: `Bearer ${user?.token}` } }
  //     ); // Replace with your API endpoint
  //     setUserProfileById(result.data);
  //   } catch (error) {
  //     setError(error);
  //   }
  // };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("sociomeeUser"));
    if (!user?.token) {
      navigate("/");
      navigate("/AdUnRegUser");
    }
  }, []);

  useEffect(() => {
    //call search action
    dispatch(loadSearchdata({ searchKey, lat, long, radius, location, pageSize, pageIndex, sortBy }));
  }, [searchKey , lat, long, pageSize, pageIndex, radius, sortBy, location, reloadData])

useEffect(()=>{
  window.addEventListener('myCustomEvent', function(event) {
    dispatch(loadSearchdata({ searchKey, lat, long, radius, location, pageSize, pageIndex, sortBy }));
  });
},[]);

const handleFollow= async(element)=>{
try {
  const reqBody={
    isPrivate: element.isPrivate,
    followingToId: element.id,
    isFollowed:1
}
  const response=
   await axios.post(`${process.env.REACT_APP_IPURL}/user/followUnFollow`,reqBody,{
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("sociomeeUser"))?.token
      }`,
      "Content-Type": "application/json", 
    },
   }
   );
  //  console.log("response=====>",response.data.data.successResult)
  setreloadData((prev)=>!prev);
} catch (error) {
  console.log(error)
}
 
  


}
  const debounceDelay = 500;
  let debounceTimer;

  const handleSearchKeyChange = (e) => {
     clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setSearchKey(e.target.value);
    }, debounceDelay);
    
    if (e.target.value === "") {
      closeDropdown();
    } else {
      const currentPath = window.location.pathname;
      // Check if the path contains "/Search"
      if (currentPath.includes('/Search')) {
        // The URL contains "/Search"
        console.log('Search detected in URL');
      } else {
        openDropdown();
      }
    }
    //data-bs-toggle="dropdown"
  }

  const openDropdown = () => {
    $(dropdownRef.current).addClass("show");
  };

  const closeDropdown = () => {
    $(dropdownRef.current).removeClass("show");
  };

  // console.log("user.profile==========================================",user.profileImage,`${process.env.REACT_APP_IMAGEURL}${user.profileImage}`)

  return (
    <>
      <header className="header-light">

        <div className="mobile-fix-menu fixed-bottom">
          <FooterData />
        </div>
        <div className="container-fluid custom-padding">
          <div className="header-section">
            <div className="header-left">
              <div className="brand-logo">
                <NavLink to="/Home">
                  <img
                    src="/assets/images/logo.png"
                    alt="logo"
                    className="img-fluid"
                  />
                </NavLink>
              </div>
              {/*=================== Start_Srch_Code_section ==================*/}
              <li
                className=" d-lg-block d-none header-btn custom-dropdown dropdown-lg btn-group message-btn"
                onClick={() => setIsActive(!isActive)}
              >
                <a
                  className="main-link"
                  href="#"

                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="border rounded">
                    <Search className="SerachThisButtonleft" size={18} />
                    {isActive ? (
                      <X size={19} onClick={closeDropdown} className="headerforxicon" />
                    ) : null}
                    <Input
                      type="text"
                      className="form-controler w-100"
                      placeholder="Search here..."
                      onChange={handleSearchKeyChange}
                    />
                  </div>
                </a>
                <div className="dropdown-menu drops" ref={dropdownRef}>

                  <div className="d-flex m-3">
                    <p className="m-2">
                      <Badge
                        color="secondary"
                        pill
                        className="d-flex BadgeThisColor"
                      >
                        <TrendingUp
                          size={15}
                          color="#00000080"
                          className="mt-1"
                        />
                        &nbsp;&nbsp;<p className="text-body"> #uefacup</p>
                      </Badge>
                    </p>
                    <p className="m-2 d-flex">
                      <Badge pill className="d-flex BadgeThisColor">
                        <TrendingUp
                          size={15}
                          color="#00000080"
                          className="mt-1"
                        />
                        &nbsp;&nbsp;<p className="text-body">#queenvictoria</p>
                      </Badge>
                    </p>
                  </div>

                  <div className="d-flex justify-content-between m-2">
                    <p className="ml-3">Recent search</p>
                    <p className="mr-3">
                      <u className="text-muted">Clear all recent</u>
                    </p>
                  </div>
                  <div className="d-flex justify-content-between m-3">
                    <p className="text-muted ml-2">
                      <Search size={18} className="mr-1" />
                      This is a related recent search
                    </p>
                    <p className="mr-2">
                      <X size={18} className="text-muted" />
                    </p>
                  </div>
                  <div className="d-flex justify-content-between m-3">
                    <p className="text-muted ml-2 text-break">
                      <Search size={18} className="mr-1" />
                      the time i reincarnated as a slime season...
                    </p>
                    <p className="mr-2">
                      <X size={18} className="text-muted" />
                    </p>
                  </div>
                  <div className="d-flex justify-content-between m-3">
                    <p className="ml-2">Suggested Results</p>
                    <p>
                      <u className="text-muted mr-2">Clear all recent</u>
                    </p>
                  </div>
                   {searchResult != null ? searchResult?.users?.map((element)=>{
                    return (<div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center ml-2">
                      <img style={{height:56}} src={element.profileImageThumb} className="rounded-circle ml-2 p-2" />
                      <div>
                        <p className="fontThiscolor">{element.fullName}</p>
                        <p className="People_designation">Irish Actor</p>
                      </div>
                    </div>
                   { element.followingStatus === "notFollowing" ? <div className="d-flex mr-4">
                      {/* <button className="ButtonThisHeaderSearchbar"> */}
                      <Button
                        style={{cursor : "pointer"}}
                        color="primary"
                        className="ButtonThisHeaderSearchbar"
                        onClick={()=>{handleFollow(element);console.log("hitted for follow",element.followFromOtherSide)}}
                      >
                        Follow
                      </Button>
                      {/* </button> */}
                    </div> : <div className="d-flex">
                      <p className="textThisSocioMate mr-4">Following</p>
                    </div> }
                  </div>)
                   }) : 0}
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center ml-2">
                      <img src={Pollimg} className="rounded-circle ml-2 p-2" />
                      <div>
                        <p className="fontThiscolor">Cicelia Murphy</p>
                        <p className="People_designation">Doctor</p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <p className="textThisSocioMate mr-4">SocioMate</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center ml-2">
                      <img src={Pollimg1} className="rounded-circle ml-2 p-2" />
                      <div>
                        <p className="fontThiscolor">Cicelia Murphy</p>
                        <p className="People_designation">Irish Actor</p>
                      </div>
                    </div>
                    <div className="d-flex">
                      <p className="textThisSocioMate mr-4">Following</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center ml-2">
                      <img src={Pollimg} className="rounded-circle ml-2 p-2" />
                      <div>
                        <p className="fontThiscolor">Cicelia Murphy</p>
                      </div>
                    </div>
                    <div className="d-flex mr-4">
                      {/* <button className="ButtonThisHeaderSearchbar"> */}
                      <Button
                        color="primary"
                        className="ButtonThisHeaderSearchbar"
                        
                      >
                        Follow
                      </Button>
                      {/* </button> */}
                    </div>
                  </div>
                  <NavLink to="/Search">
                    <div className="d-flex justify-content-center">
                      <p className="mr-1 headertextcolor">View all results</p>
                      <p>
                        <ArrowRightCircle color="#81C14B" size={18} />
                      </p>
                    </div>
                  </NavLink>
                  <div className="dropdown-content"></div>
                </div>
              </li>
              {/*=================== end_Srch_Code_section ==================*/}

              {/*=================== Start_Header_Center_section ===================*/}
              <HeaderCenter />

              {/*=================== Start_Header_Center_section ===================*/}
            </div>
            <div className="header-right">
              <ul className="option-list">
                {/*============== Start_Header_Message_code ==================*/}
                <li className=" d-lg-block d-none header-btn custom-dropdown dropdown-lg btn-group message-btn">
                  <div className="Header_Left_sideIcon  rounded-circle">
                    <img src={MsgMee} alt="msg" />
                  </div>
                </li>
                {/* <div className="d-xl-none" role="button">
                  <NavLink to="/Notification">
                    <Search size={16} color="#81c14b" />
                  </NavLink>
                </div> */}
                <div className="rem-web-view" role="button">
                  {/* <NavLink to="/GoLive" >
                    <img
                      src="" // Replace with the actual image source
                      alt="icon"
                      style={{ width: '16px', height: '16px', color: '#81c14b' }}
                    />
                  </NavLink> */}
                  <NavLink to="/Search" className="pr-1">
                    {searchHide ? '' : <Search onClick={toggle} size={16} color="#81c14b" />}
                    &nbsp;
                  </NavLink>
                  <NavLink to="/Notification">
                    <Bell size={16} color="#81c14b" />
                  </NavLink>
                </div>
                {/*============== Start_Header_Message_code ==================*/}
                <AppGridView
                  menuUpdate={menuBtnClick}
                  menuRef={menuRef}
                  closeMeanuBtnClick={closeMenuBtnClick}
                  oldRef={olRef}
                  updateOpen={setOpen}
                />
                {/*==================== End_App_box_root_Data ====================*/}

                {/*============== Start_Header_Notification_code ==================*/}
                <li className=" d-lg-block d-none header-btn custom-dropdown dropdown-lg btn-group message-btn ml-3 ">
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      color="primary"
                      className="Header_Left_sideIcon  rounded-circle border-0"
                    >
                      <img src={NotificationIcon} alt="Notification" />
                      <div className="Notification_Count">12</div>
                    </DropdownToggle>
                    <DropdownMenu className="thatNotificationdropdown">

                      {/* <Notification className="shadow-lg" /> */}
                      <NotificationView />
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </li>
                {/*============== End_Header_Notification_code ==================*/}

                <li className=" d-lg-block d-none header-btn custom-dropdown profile-btn btn-group">
                  <a
                    className="main-link"
                    href="/"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon-light strokeWidth-3 d-sm-none d-block iw-16 ih-16"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <div className="d-flex d-none d-sm-flex headerFormain align-items-center">
                      {user && user.profileImage &&(
                         <img
                         src={`${process.env.REACT_APP_IMAGEURL}${user.profileImage}`}
                        
                         width={50}
                         height={50}
                         className="rounded-circle Header_profile_img"
                         alt={user?.fullName}
                       />
                      )}
                      <span className="HeaderactiveForIcon">
                        <Circle
                          strokeWidth={5}
                          color="#fff"
                          fill="#16C31E"
                          size={10}
                        />
                      </span>
                      <div className="ml-1">
                        <h4 className="text-wrap">
                          {user.fullName?.substring(0, 21)}
                          {user?.fullName?.length >= 21 &&
                            " ..."}
                        </h4>
                        <span>active now</span>
                      </div>
                    </div>
                  </a>
                  {/* <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header">
                      <span>profile</span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li>
                          <NavLink to="/MyProfile">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className=""
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">Profile</h5>
                                  <h6>Profile preview & settings</h6>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/Setting">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className=""
                              >
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">setting & privacy</h5>
                                  <h6>all settings & privacy</h6>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <a href="/">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-help-circle"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">help & support</h5>
                                  <h6>browse help here</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => setOpen(true)}>
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-log-out"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">log out</h5>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header">
                      <span>profile</span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li>
                          <NavLink to={`/MyProfile?userId=${user.id}&type=creater`}>
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className=""
                              >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">Profile</h5>
                                  <h6>Profile preview & settings</h6>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/Setting">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className=""
                              >
                                <circle cx="12" cy="12" r="3"></circle>
                                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">setting & privacy</h5>
                                  <h6>all settings & privacy</h6>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <a href="/">
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-help-circle"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">help & support</h5>
                                  <h6>browse help here</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a onClick={() => setOpen(true)}>
                            <div className="media">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-log-out"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                              </svg>
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">log out</h5>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </header>

      {/* MUI Dialog Box  */}

      {open ? <Modal
        isOpen={open} toggle={() => setOpen(!open)}
        className='modal-dialog-centered'
      >
        {/* <ModalHeader toggle={handleClose}></ModalHeader> */}
        <ModalBody className='m-5 text-center'>
          <h2>Are You Sure, you want to logout ?</h2>

        </ModalBody>
        <div className="text-center p-2">
          <Button outline onClick={handleClose} color='primary px-5'>No</Button>
          <Button onClick={logoutUser} color='danger ml-2 px-5'>Yes</Button>
        </div>
      </Modal> : null}
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure, you want to logout ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={logoutUser}>Yes</Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};
export default Header;
