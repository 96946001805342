import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";

const getAllPostLikes = (allLikes) => ({
  type: types.GET_ALL_POST_LIKES,
  payload: allLikes,
});

export const loadAllPostLikes = (postId) => {
  return async (dispatch) => {
    try {
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      if (user) {
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/post/getAllLike`,
          {
            postId: postId,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
    
        dispatch(getAllPostLikes(response.data?.data?.successResult?.rows));
      }
    } catch (error) {
      console.error("Error fetching likes:", error);
    }
  };
};
