import React from 'react'
import { Fragment } from 'react'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const QuestionAnswer = (props) => {
    return (
        <Fragment>
            {
                props.isOpen !== false &&
                <Modal isOpen={props.isOpen} toggle={props.handleOpen} className='modal-dialog-centered'>
                    <ModalHeader className="Biz_Aditional_SECTION">
                        <h3>Enter Your FAQ</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className='BizAddition_heading_section'>
                            <Form className="py-2">
                                <FormGroup>
                                    <Label className="Biz_Messagesetting_section">
                                        <h3>Question</h3>
                                    </Label>
                                    <Input
                                        id="exampleEmail"
                                        name="email"
                                        placeholder="Enter your question here  "
                                        type="email"
                                        maxLength={120}
                                    />
                                    <p className='WataDo_limit_section text-end  mt-1'>Max 120 Characters </p>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="Biz_Messagesetting_section">
                                        <h3> Autometed response</h3>
                                    </Label>
                                    <textarea placeholder='Enter your responce here ' className="form-control" name="" id="" cols="15" rows="5"     maxLength={300}></textarea>
                                    <p className='WataDo_limit_section text-end  mt-1'>Max 300 Characters  </p>
                                </FormGroup>
                            </Form>
                            <div className='text-center'>
                                <Button onClick={props.handleOpen} className="" color="primary" >Save </Button>
                                <Button outline className="ml-2 " onClick={props.handleOpen}>Cancel </Button>
                            </div>

                        </div>

                    </ModalBody>
                </Modal>
            }
        </Fragment>
    )
}

export default QuestionAnswer