import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AlertTriangle, Check, CheckCircle, CreditCard, Eye, FileText, HelpCircle, Home, Info, Layers, MoreHorizontal, Settings, X } from 'react-feather';
import SocioLogo from '../../../NewComponents/IMG/SocioLogo.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap';
import AdManagerHeader from '../Sub-components/AdManagerHeader';
import TrxIcon from '../../../NewComponents/IMG/TrxIcon.svg'
import NeteuLogo from '../../../NewComponents/IMG/netsPaymentLogo.png'
import StripeLogo from '../../../NewComponents/IMG/StripeLogo.svg'
import CurrencyLogo from '../../../NewComponents/IMG/Currency.svg'
import AmountLogo from '../../../NewComponents/IMG/AmountIcon.svg'
import WalletIcon from '../../../NewComponents/IMG/WalletIcon.svg'
import ConfirmIcon from '../../../NewComponents/IMG/Frame 2560.svg'
import QR from '../../../NewComponents/IMG/QR.svg'
import Invoice from '../Sub-components/Invoice'
import SociomeeLogo from '../../../NewComponents/IMG/SociomeeLogo.svg'
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import e from '../../../NewComponents/IMG/e.png'
import f from '../../../NewComponents/IMG/f.png'
const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const sideDashboardData = [
    { text: 'Dashboard', link: '/Dashboard' },
    { text: 'Report', link: '#' },
    { text: 'Billing', link: '/Dashboard/TransactionSummary' },
    { text: 'Payment setting', link: '/Dashboard/PaymentSettings' },
    // { text: 'Setting', link: '#' },
    { text: 'Help', link: 'https://www.sociomee.com/knowledgebase-category/ad-manager/' }
    // <a href='https://www.sociomee.com/knowledgebase-category/ad-manager/'>Help</a>
]
export default function PaymentSettings() {

    /*------------- Payment Modal and states ----------------*/

    const [PaymentModals, setPaymentModals] = useState(false)
    const [PaymentModalsCard, setPaymentModalsCard] = useState(false)
    const [PaymentModalsupi, setPaymentModalsupi] = useState(false)
    const [PaymentConfirmationModal, setPaymentConfirmationModal] = useState(false)
    const [PaymentView, setPaymentView] = useState(false)
    const [Hidepayment, setShowpayment] = useState(false)

    const PaymentAlert = () => {

        alert("Please select any one payment option")
    }

    const toggleModal = () => {
        setPaymentModals((prevState) => !prevState)
    }

    const togglepayment = () => {
        setShowpayment(true)
        setPaymentModals((prevState) => !prevState)
    }

    const toogleChnagePayment = () => {
        setPaymentModalsCard(false)
        setPaymentModalsupi(false)
        setPaymentModals(true)

    }

    const toggleModalCard = () => {
        setPaymentModals((prevState) => !prevState)
        setPaymentModalsCard((prevState) => !prevState)
    }
    const toggleModalUpi = () => {
        setPaymentModals((prevState) => !prevState)
        setPaymentModalsupi((prevState) => !prevState)
    }
    const togglePaymentConfirmation = () => {
        setPaymentModalsupi(false)
        setPaymentModalsCard(false)
        setPaymentConfirmationModal((prevState) => !prevState)
    }
    const togglePaymentView = () => {
        setPaymentModalsupi(false)
        setPaymentModalsCard(false)
        setPaymentConfirmationModal(false)
        setPaymentView((prevState) => !prevState)
    }


    // Payment Modal Sub-Componenet
    const currencylist = [
        {
            currency: ' Choose currency'
        },
        {
            currency: ' Choose currency 1'
        },
        {
            currency: ' Choose currency 2'
        },
        {
            currency: ' Choose currency 3'
        },
        {
            currency: ' Choose currency 4'
        },
        {
            currency: ' Choose currency 5'
        }
    ]

    const SuggestAmount = [
        {
            sgtAmt: '100'
        },
        {
            sgtAmt: '200'
        },
        {
            sgtAmt: '500'
        },
        {
            sgtAmt: '1000'
        },
        {
            sgtAmt: '2000'
        }
    ]

    const PaymentModal = () => {

        const [selectedfirst, setSelectedfirst] = useState(false)
        const [selectedSecond, setSelectedSecond] = useState(false)
        const [selectedThird, setSelectedThird] = useState(false)
        const [selectedfourth, setSelectedfourth] = useState(false)
        const Nets = () => {
            setSelectedfirst((prevState) => !prevState)
            setSelectedSecond(false)
            setSelectedThird(false)
            setSelectedfourth(false)
        }
        const Gpay = () => {
            setSelectedfirst(false)
            setSelectedThird(false)
            setSelectedfourth(false)
            setSelectedSecond((prevState) => !prevState)
        }
        const Sripe = () => {
            setSelectedfirst(false)
            setSelectedSecond(false)
            setSelectedfourth(false)
            setSelectedThird((prevState) => !prevState)

        }
        const Apay = () => {
            setSelectedfirst(false)
            setSelectedSecond(false)
            setSelectedThird(false)
            setSelectedfourth((prevState) => !prevState)
        }
        return (
            <>
                {PaymentModals ? (
                    <Modal isOpen={PaymentModals} className='modal-lg modal-dialog-centered' toggle={toggleModal} >
                        <ModalHeader toggle={toggleModal}>
                            {Hidepayment ?
                                <div>
                                    <div className="modal-header-title">
                                        <h2 className="modalForPayHead">Add payment information</h2>
                                        {/* <p className="text-muted">These funds will be used to allot the budget while creating Ads</p> */}
                                    </div>
                                </div> :
                                <div>
                                    <div className="modal-header-title">
                                        <h2 className="modalForPayHead">Add Funds</h2>
                                        <p className="text-muted">These funds will be used to allot the budget while creating Ads</p>
                                    </div>
                                </div>
                            }
                        </ModalHeader>
                        <ModalBody>
                            <>
                                <div>{Hidepayment ? null :
                                    <div>
                                        <div className="amount">
                                            <Row>
                                                <Col xl='4'>
                                                    <FormGroup>
                                                        <img src={CurrencyLogo} className='currencyicon' alt='currencyicon' />
                                                        <Label>
                                                            Choose an amount to add
                                                        </Label>
                                                        <Input type='select'>

                                                            {
                                                                currencylist.map((item, index) => {
                                                                    return (
                                                                        <option key={index}>
                                                                            &nbsp;&nbsp;{item.currency}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </Input>
                                                        {/* <img src={AmountLogo} className='Amounticon' alt='Amounticon' />
                                                <Input type="text" className='mx-4 pl-5' placeholder="Enter amount" /> */}
                                                    </FormGroup>
                                                </Col>
                                                <Col xl='8'>
                                                    <FormGroup>
                                                        <Label
                                                            style={{ visibility: 'collapse' }}
                                                        >
                                                            Choose an amount to add
                                                        </Label>
                                                        <img src={AmountLogo} className='Amounticon' alt='Amounticon' />
                                                        <Input type="number" style={{ paddingLeft: '45px' }} placeholder="Enter amount" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className='pe-none d-flex justify-content-end align-items-center mr-4'>
                                                <p className='mr-4'>Commonly added amounts</p>
                                                <div>
                                                    {
                                                        SuggestAmount.map((Amt, index) => {
                                                            return (
                                                                <b className='m-1'><u>
                                                                    +{Amt.sgtAmt}
                                                                </u></b>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {/* <input type="text" className="form-control margin-add-fud" placeholder="Enter amount" /> */}
                                        </div>
                                    </div>}
                                    <div className="amount">
                                        <Label>Choose payment method</Label>
                                        <div className='d-flex justify-content-center text-center payForIcon'>
                                            <div className='w-100'>
                                                <div onClick={Nets} className={selectedfirst ? 'm-2 border border border-primary rounded' : 'm-2 border rounded'}>
                                                    <img src={NeteuLogo} alt="nets" />{selectedfirst ? <CheckCircle color='#81C14B' /> : ''}
                                                </div>
                                                <div onClick={Gpay} className={selectedSecond ? 'm-2 border border border-primary rounded' : 'm-2 border rounded'}><img src="https://cdn-icons-png.flaticon.com/512/6124/6124998.png" alt="Gpay" />{selectedSecond ? <CheckCircle color='#81C14B' /> : ''}</div>
                                            </div>
                                            <div className='w-100 text-center'>
                                                <div onClick={Sripe} className={selectedThird ? 'm-2 border border border-primary rounded' : 'm-2 border rounded'}><img src={StripeLogo} alt="stripe" />{selectedThird ? <CheckCircle color='#81C14B' /> : ''}</div>
                                                <div onClick={Apay} className={selectedfourth ? 'm-2 border border border-primary rounded' : 'm-2 border rounded'}><img src="https://cdn-icons-png.flaticon.com/512/5977/5977576.png" alt="ApplePay" />{selectedfourth ? <CheckCircle color='#81C14B' /> : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer add-footer">
                                    <Button disabled={selectedfirst || selectedThird ? false : selectedSecond || selectedfourth === true ? false : true} onClick={selectedfirst || selectedThird == true ? toggleModalCard : selectedSecond || selectedfourth == true ? toggleModalUpi : PaymentAlert} color='primary' className='w-100' >Make Payment</Button>
                                </div>
                            </>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    const [value, setValue] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [inputFieldValue, setInputFieldValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const handleInputChange = (event) => {
        const input = event.target.value.replace(/\D/g, "");
        setInputFieldValue(value);
        setButtonDisabled(value === '');
        if (input.length > 0) {
            const formattedInput = input.match(/.{1,4}/g).join(" - ");
            setValue(formattedInput);
        } else {
            setValue("");
        }
    };
    const [selectedDate, setSelectedDate] = useState(null);
    const PaymentModalforCard = () => {
        return (
            <>
                {PaymentModalsCard ? (
                    <Modal isOpen={PaymentModalsCard} className='modal-lg modal-dialog-centered' toggle={toggleModalCard} >
                        <div className='PaymentmodalForStyle rounded'>
                            <h3 className="modalForPayHead">Your Payment platform</h3>
                            <h5 onClick={toogleChnagePayment} className="modalForPayHead">Change Payment Method</h5>
                        </div>
                        <ModalBody>
                            <>
                                <div>
                                    <h3>Enter your Card Details</h3>
                                    <div className='my-2' >
                                        <div className="amount">
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>
                                                            Credit/Debite/ATM Card
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="xxxx - xxxx - xxxx - xxxx"
                                                            maxLength='25'
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl='6'>
                                                    <FormGroup>
                                                        <Label>
                                                            Exp. Date
                                                        </Label>
                                                        {/* <Input type="month" placeholder="mm-yyyy" /> */}
                                                        <DatePicker
                                                            className="form-control"
                                                            selected={selectedDate}
                                                            onChange={date => setSelectedDate(date)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            placeholderText="mm/yyyy"
                                                        />

                                                    </FormGroup>
                                                </Col>
                                                <Col xl='6'>
                                                    <FormGroup>
                                                        <Label>
                                                            CVV
                                                        </Label>
                                                        <Input type="text" placeholder=" - - - " maxLength={3} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className='pe-none d-flex justify-content-end align-items-center mr-4'>
                                            </div>
                                            {/* <input type="text" className="form-control margin-add-fud" placeholder="Enter amount" /> */}
                                        </div>
                                    </div>
                                    <h5>This payment process is secured by PCI-DSS</h5>
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={togglePaymentConfirmation} color='primary' className='w-100' >Continue</Button>
                                </div>
                            </>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    const PaymentModalforUpi = () => {
        const [value, setValue] = useState('UPI');

        const handleChange = (event) => {
            setValue(event.target.value);
        };
        return (
            <>
                {PaymentModalsupi ? (
                    <Modal isOpen={PaymentModalsupi} className='modal-lg modal-dialog-centered' toggle={toggleModalUpi} >

                        <ModalBody>
                            <>
                                <div>
                                    <FormControl>
                                        <FormLabel id="demo-row-controlled-radio-buttons-group">Select your prefered option </FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={value}
                                            className='d-flex'
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="UPI" control={<Radio />} label="UPI" />

                                            <FormControlLabel value="QR" control={<Radio />} label="QR" />
                                        </RadioGroup>
                                    </FormControl>
                                    {value === 'UPI' ?
                                        <div>
                                            <InputGroup className='mb-2'>
                                                <Input placeholder="Enter UPI ID" />
                                                <InputGroupAddon addonType='append'>
                                                    <InputGroupText>@example</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <Label><b>1.</b>000000000@example</Label>
                                        </div> :
                                        <div className='text-center'>
                                            <img className='w-100' height={200} src={QR} alt='' />
                                            <p>Scan QR code and pay</p>
                                        </div>
                                    }
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={togglePaymentConfirmation} color='primary' className='w-100' >Continue</Button>
                                </div>
                            </>
                        </ModalBody>

                    </Modal>
                ) : (null)}
            </>
        )
    }
    const ModalForInvoice = () => {
        return (
            <>
                {PaymentView ? (
                    <Modal isOpen={PaymentView} className='modal-lg modal-dialog-centered' toggle={togglePaymentView} >
                        <ModalBody>
                            <Invoice />
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    const [isConfirmation, setIsConfirmation] = useState(true);
    const [PaymentFailedModal, setPaymentFailedModal] = useState(false)
    const togglePaymentFailed = () => {
        setPaymentModalsupi(false)
        setPaymentModalsCard(false)
        setPaymentFailedModal(!PaymentFailedModal);
    }
    const [clickedOnce, setClickedOnce] = useState(false);
    const handleButtonClick = () => {
        let isConf = true;
        if (isConfirmation) {
            isConf = false;
            togglePaymentConfirmation();
        } else {
            isConf = true;
            togglePaymentFailed();
        }
        setIsConfirmation(!isConfirmation);
        console.log(isConfirmation)
    };
    const PaymentConfirmation = () => {
        return (
            <>
                {PaymentConfirmationModal ? (
                    <Modal isOpen={PaymentConfirmationModal} className='modal-dialog-centered' toggle={togglePaymentConfirmation} >
                        <ModalBody>
                            <div>
                                <div className='text-center border-bottom'>
                                    <div style={{ position: 'relative' }}>
                                        <img src={e} width={80} height={80} alt='' />
                                        <div style={{ position: 'absolute', left: '45%', top: '20%', color: '#81c14b' }}>
                                            <Check size={50} />
                                        </div>
                                    </div>
                                    <h4 className='text-primary mt-2'>Payment Confirmation</h4>
                                    <h4 className='mt-2'>Thanks for the Payment</h4>
                                    <p className='my-2'>Your Payment has been received. You can download the Invoice from below.</p>
                                </div>
                                <div>
                                    <h4 onClick={togglePaymentView} className='text-primary text-center mt-2' style={{ cursor: 'pointer' }} ><u >View Invoice</u></h4>
                                    <Row className='ConfirmatioForstyle'>
                                        {/* <Col xl='6'> */}
                                        <div className='d-flex justify-content-between'>
                                            <div><h4>Summary</h4>
                                                <h5>Transactions ID</h5>
                                                <h5>Date & Time </h5>
                                                <h5>Payment Method </h5>
                                                <h5>Added Amount </h5>
                                                <h4>Total Amount in wallet</h4>
                                            </div>
                                            <div className='mt-4'>
                                                <h5>#6541651651</h5>
                                                <h5>12 Feb 2021,04:48PM</h5>
                                                <h5>Net Banking </h5>
                                                <h5>$2350 </h5>
                                                <h4>$2550</h4></div></div>
                                        {/* </Col> */}
                                        {/* <Col xl='6' className='text-right mt-4'>
                                        </Col> */}
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={() => setPaymentConfirmationModal((prevState) => !prevState)} color='primary' className='w-100' >Go Back</Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }
    // const handleBackdropClick = (event) => {
    //     if ($(event.target).hasClass('modal')) {
    //         togglePaymentConfirmation();
    //     }
    // };
    // ==============PaymentFailed close modal================
    const handleBackdropClick = (event) => {
        if (event.target.className === 'modal') {
            togglePaymentFailed();
        }
    };
    // ==============PaymentFailed================
    const PaymentFailed = () => {
        return (
            <>
                {PaymentFailedModal ? (
                    <Modal isOpen={PaymentFailedModal} className='modal-dialog-centered' toggle={togglePaymentFailed} onClick={handleBackdropClick}>
                        <ModalBody>
                            <div>
                                <div className='text-center border-bottom'>
                                    <div style={{ position: 'relative' }}>
                                        <img src={f} width={80} height={80} alt='' />
                                        <div style={{ position: 'absolute', left: '43.8%', top: '14%', color: '#81c14b' }}>
                                            <X size={60} style={{ color: '#FF0000' }} />
                                        </div>
                                    </div>
                                    <h4 className='mt-2' style={{ color: '#FF0000' }}>Payment Failed</h4>
                                    <h4 className='mt-2'>Payment not success</h4>
                                    <p className='my-2'>Your Payment has been not received.</p>
                                </div>
                                <div>
                                    <h4 onClick={togglePaymentView} className='text-center mt-2' style={{ color: '#FF0000', cursor: 'pointer' }}><u>Failed Invoice</u></h4>
                                    <Row className='ConfirmatioForstyle'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <h4>Failed Summary</h4>
                                                <h5>Transactions ID</h5>
                                                <h5>Date & Time </h5>
                                                <h5>Payment Method </h5>
                                                <h5>Failed Amount</h5>
                                                <h4>Total Amount in wallet</h4>
                                            </div>
                                            <div className='mt-4'>
                                                <h5>#6541651651</h5>
                                                <h5>12 Feb 2021, 04:48 PM </h5>
                                                <h5>Net Banking </h5>
                                                <h5>$2350 </h5>
                                                <h4>$2550</h4>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Button onClick={togglePaymentFailed} color='primary' className='w-100' >Go Back</Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                ) : (null)}
            </>
        )
    }



    const theme = useTheme();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    // const [Switchtab, setSwitchtab] = useState(null)

    const [active, setActive] = useState('1')
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [show, sethide] = useState(false)



    // toggle for uper niche
    const [activeTab, setActiveTab] = useState('1')

    const toggleTab = tab => {
        setActiveTab(tab)
    }

    const [BusinessInfo, setBusinessInfo] = useState(false)
    const toggleBusinessInfo = () => {
        setBusinessInfo(!BusinessInfo)
    }


    const ModalForBusinessInfo = () => {

        const [selectedValue, setSelectedValue] = useState('Yes');

        const handleChange = (event) => {
            setSelectedValue(event.target.value);
        };

        const [checked, setChecked] = React.useState(true);

        const handleForCheckbox = (event) => {
            setChecked(event.target.checked);
        };

        const regions = [
            { label: 'India' },
            { label: 'Denmark' },
            { label: 'Canada' },
            { label: 'Sweden' },
            { label: 'Japan' }
        ];

        const currency = [
            { label: 'US dollar (USD)' },
            { label: 'Euro (EUR)' },
            { label: 'Japanese yen (JPY)' },
            { label: 'Pound sterling (GBP)' },
            { label: 'Australian dollar (AUD)' }
        ];

        const timeZone = [
            { label: '	(GMT-12:00) International Date Line West' },
            { label: '(GMT-10:00) Hawaii' },
            { label: '	(GMT-09:00) Alaska' },
            { label: '(GMT-07:00) Arizona' },
            { label: '(GMT-06:00) Saskatchewan' }
        ];

        return (
            <>
                {BusinessInfo ? (
                    <Modal isOpen={BusinessInfo} className='modal-lg modal-dialog-centered' toggle={toggleBusinessInfo} >
                        <div className='p-2 d-flex justify-content-center align-items-center'>
                            <h3 className='font-weight-bold'>Business info</h3>
                            <X className='ml-auto' onClick={toggleBusinessInfo} />
                        </div>
                        <ModalBody>
                            <Box
                                component="form"
                                autoComplete="off"
                            >
                                <FormLabel><h4 className='font-weight-bold text-dark'>Billing country or region</h4></FormLabel>
                                <Autocomplete
                                    variant="filled"
                                    disablePortal
                                    id="combo-box-demo"
                                    className='rounded border'
                                    defaultValue={regions[0]}
                                    options={regions}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <Grid sx={{ mt: 1, mb: 1 }} container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormLabel><h4 className='font-weight-bold text-dark'>Currency and time zone</h4></FormLabel>
                                        <Autocomplete
                                            variant="filled"
                                            disablePortal
                                            id="combo-box-demo"
                                            className='rounded border'
                                            defaultValue={currency[0]}
                                            options={currency}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <FormLabel><h4 className='text-transparent'>Currency and time zone</h4></FormLabel> */}
                                        <Autocomplete
                                            variant="filled"
                                            disablePortal
                                            id="combo-box-demo"
                                            className='rounded border'
                                            defaultValue={timeZone[0]}
                                            sx={{ mt: '29px' }}
                                            options={timeZone}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel><h4 className='font-weight-bold text-dark'>Business name</h4></FormLabel>
                                        <TextField
                                            error={true}
                                            id="standard-error-helper-text"
                                            className='rounded border'
                                            placeholder='Add a name'
                                            inputProps={{ inputMode: 'text' }}
                                            fullWidth
                                        />
                                        <Typography className='text-muted' variant="caption" display="block" gutterBottom>
                                            Enter your legal name if you don't have a business name.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel><h4 className='font-weight-bold text-dark'>Business address</h4></FormLabel>
                                        <Typography className='text-muted' variant="caption" display="block" gutterBottom>
                                            The legal address registered with your government and tax agency. If you aren't a registered business, enter your postal address.
                                        </Typography>
                                        <TextField
                                            id="outlined-select-currency"
                                            className='rounded border'
                                            placeholder='Street address 1'
                                            inputProps={{ inputMode: 'address' }}
                                            fullWidth
                                        />
                                        <TextField
                                            id="outlined-select-currency"
                                            className='rounded border'
                                            placeholder='Street address 2'
                                            inputProps={{ inputMode: 'address' }}
                                            fullWidth
                                            sx={{ mt: 1 }}
                                        />
                                        <TextField
                                            id="outlined-select-currency"
                                            className='rounded border'
                                            placeholder='Town or city'
                                            inputProps={{ inputMode: 'address' }}
                                            fullWidth
                                            sx={{ mt: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Autocomplete
                                            variant="filled"
                                            disablePortal
                                            id="combo-box-demo"
                                            className='rounded border'
                                            value={regions[0]}
                                            options={regions}
                                            renderValue={(value) => `⚠️  - ${value}`}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            className='rounded border'
                                            placeholder='PIN code'
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>
                                <hr />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormLabel><h4 className='font-weight-bold text-dark'>Business info</h4></FormLabel>
                                        <TextField
                                            className='rounded border'
                                            label='GST number (optional)'
                                            placeholder='22ABCDE1234A1Z1'
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <FormLabel><h4 className='text-transparent'>Currency and time zone</h4></FormLabel> */}
                                        <TextField
                                            className='rounded border'
                                            sx={{ mt: '29px' }}
                                            label='PAN (optional)'
                                            placeholder='ABCDE1234A'
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Checkbox
                                            required
                                            onChange={handleForCheckbox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography className='text-muted' variant="subtitle2" display="block" gutterBottom>
                                            The legal address registered with your government and tax agency. If you aren't a registered business, enter your postal address.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel><h4 className='font-weight-bold text-dark'>Advertising purpose</h4></FormLabel>
                                    </Grid>
                                </Grid>
                                <div className='d-flex align-items-center'>
                                    <Radio
                                        checked={selectedValue === 'Yes'}
                                        onChange={handleChange}
                                        value="Yes" />
                                    <Typography className='text-muted ml-4' variant="caption" display="block" >
                                        Yes, I am buying ads for business purposes
                                    </Typography>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Radio
                                        checked={selectedValue === 'No'}
                                        onChange={handleChange}
                                        value="No" />
                                    <Typography className='text-muted ml-4' variant="caption" display="block" >
                                        No, I am not buying ads for business purposes
                                    </Typography>
                                </div>
                                <hr />
                                <Typography className='text-muted' variant="caption" display="block" >
                                    SocioMee is required to share your business information with local tax authorities that request it.
                                </Typography>
                                <div className='text-right'>
                                    <Button color='primary' >Save</Button>
                                </div>
                            </Box>
                        </ModalBody>
                    </Modal >
                ) : (null)
                }
            </>
        )
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <ModalForBusinessInfo />
            <CssBaseline />
            <AppBar sx={{ background: '#ffffff', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px', zIndex: '1000' }} position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            // backgroundColor: '#81C14B',
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        {/* <MenuIcon /> */}
                        <img width={25} height={25} src={SocioLogo} alt='' />
                    </IconButton>
                    <Typography noWrap className='w-100' component="div">
                        <AdManagerHeader />
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose} variant="permanent" sx={{ zIndex: '0' }} open={open}>
                <DrawerHeader>
                    <IconButton sx={{ background: '#ffffff' }} onClick={handleDrawerClose}>
                        {open === true ? <img width={195} height={50} src={SociomeeLogo} alt="logo" /> : null}
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {/* {sideDashboardData.map((text, index) => (
                        <ListItem key={text.text} disablePadding component={Link} to={text.link} sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index === 0 ? <Home color='#81C14B' /> : index === 1 ? <Layers color='#81C14B' /> : index === 2 ? <AlertTriangle color='#81C14B' /> : index === 3 ? <FileText color='#81C14B' /> :
                                        index === 4 ? <CreditCard color='#81C14B' /> : index === 5 ? <Settings color='#81C14B' /> : index === 6 ? <HelpCircle color='#81C14B' /> : null}
                                </ListItemIcon>
                                <ListItemText primary={text.text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))} */}
                    {sideDashboardData.map((text, index) => (
                        <a href={text.link} key={text.text} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItem disablePadding component="div" sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: '#81C14B', // Apply color to the icon
                                        }}
                                    >
                                        {index === 0 ? <Home /> : index === 1 ? <Layers /> : index === 2 ? <AlertTriangle /> : index === 3 ? <FileText /> :
                                            index === 4 ? <CreditCard /> : index === 5 ? <Settings /> : index === 6 ? <HelpCircle /> : null}
                                    </ListItemIcon>
                                    <ListItemText primary={text.text} sx={{ opacity: open ? 1 : 0, color: '#81C14B' }} /> {/* Apply color to the text */}
                                </ListItemButton>
                            </ListItem>
                        </a>
                    ))}
                </List>
            </Drawer>
            <Box activeTab={active} component="main" sx={{ flexGrow: 1, p: 1, width: '95%' }}>
                <DrawerHeader />
                <div>
                    <Row>
                        <Col xl='2' ><h4 className='text-muted font-weight-bold'>Payment setting</h4></Col>
                        <Col xl='5' >
                            <PaymentModal />
                            <PaymentModalforCard  setIsConfirmation={setIsConfirmation} isConfirmation={isConfirmation} />
                            <PaymentModalforUpi />
                            <PaymentFailed />
                            <PaymentConfirmation />
                            <ModalForInvoice />
                            {/* <Card className='p-1 py-2 AddInfoBorder mt-3'>
                                <div>
                                    <div className='d-flex align-items-center p-1'>
                                        <AlertTriangle size={22} fill='#D93616' color='white' />&nbsp;<h5 className='font-weight-bold'>Business info missing</h5>
                                    </div>
                                    <div className='pl-2 py-1'>
                                        <h5 className='text-muted py-2'>You'll need to add business information to your account before you can run ads.</h5>
                                        <Button onClick={toggleBusinessInfo} color='primary' >Add info</Button>
                                    </div>
                                </div>
                            </Card> */}
                            <Card className='p-1 py-2 mt-3 FundsForstyle'>
                                <div className='p-1 border-bottom'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5 className='d-flex align-items-center font-weight-bold text-muted py-1'>Prepaid funds</h5>
                                        <Button className='border-0' onClick={() => toggleModal()} color='secondary' outline>Add funds</Button>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h2 className='py-2'>€&nbsp;0.00</h2>
                                        {/* <UncontrolledButtonDropdown>
                                            <DropdownToggle color='flat-secondary text-secondary p-1 border-0'>
                                                <MoreHorizontal />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => navigate('/Dashboard/TransactionSummary')}><h5><Eye size={16} /> View History</h5></DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown> */}
                                    </div>
                                </div>
                                <div className='p-1'>
                                    <h5 className='font-weight-bold text-muted py-2'>How you'll pay</h5>
                                    <div className='d-flex align-items-center'>
                                        <img src={WalletIcon} alt="Wallet" />&nbsp;<h5 className='font-weight-bold text-muted'>Prepaid funds</h5>
                                    </div>
                                    <h6 className='text-muted p-1 '>We'll deduct funds about once a day when you run ads. If funds run out, your ads will be paused.</h6>
                                </div>
                            </Card>
                            <Card className='p-1 py-2 FundsForstyle mt-3'>
                                <div className='p-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5 className='d-flex align-items-center font-weight-bold text-muted py-1'>Freeze Amount</h5>
                                        <p>$ 5,375.00</p>
                                    </div>
                                    <h5 className='py-2 text-muted'>Total Freeze Amount calculated on All Ads</h5>
                                </div>
                                <div className='p-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5 className='d-flex align-items-center font-weight-bold text-muted py-1'>Total Spent Amount</h5>
                                        <p>$ 5000</p>
                                    </div>
                                    <h5 className='py-2 text-muted'>Total Spent Amount</h5>
                                </div>
                            </Card>
                            {/* <Card className='p-1 py-2 FundsForstyle mt-3'>
                                <div className='p-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5 className='d-flex align-items-center font-weight-bold text-muted py-1'>Payment methods&nbsp;<Info fill='#828282' id='paymenTooltip' color='white' size={18} /></h5>
                                        <Button className='border-0' onClick={() => togglepayment()} color='secondary' outline>Add payment method</Button>
                                    </div>
                                    <h5 className='py-2 text-muted'>You haven't added any payment methods.</h5>
                                </div>
                                <UncontrolledTooltip placement='right' target='paymenTooltip'>
                                    Will be used to pay for your ads.
                                </UncontrolledTooltip>
                            </Card> */}
                            {/* <Card className='p-1 py-2 FundsForstyle mt-3'>
                                <div className='p-1 underLineForLink'>
                                    <h5 className='d-flex align-items-center font-weight-bold text-muted py-1'>Payment activity</h5>
                                    <h5 className='py-2 text-muted'>You have no recent spending. <NavLink className='underlinetext' to='/CreateAd'>Create ad</NavLink></h5>
                                </div>
                            </Card> */}
                            <Card className='p-1 py-2 FundsForstyle mt-3'>
                                <div className='p-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className='d-flex align-items-center font-weight-bold text-muted py-1'>Business info</h4>
                                        <Button className='border-0' onClick={toggleBusinessInfo} color='secondary' outline>Edit</Button>
                                    </div>
                                </div>
                                <table className='ml-1 mt-2'>
                                    <tr>
                                        <th><h5 className='font-weight-bold text-muted'>Business name</h5></th>
                                        <th><h5 className='font-weight-bold text-muted'>Address</h5></th>
                                        <th><h5 className='font-weight-bold text-muted'>Currency</h5></th>
                                    </tr>
                                    <tr>
                                        <td><p className='d-flex align-items-center text-muted'>-</p></td>
                                        <td><p className='d-flex align-items-center text-muted'>Denmark</p></td>
                                        <td><p className='d-flex align-items-center text-muted'>Danish krone Kr</p></td>
                                    </tr>
                                    <tr>
                                        <th><h5 className='font-weight-bold text-muted'>Tax ID</h5></th>
                                    </tr>
                                    <tr>
                                        <td><p className='d-flex align-items-center text-muted'>-</p></td>
                                    </tr>
                                </table>
                            </Card>
                        </Col>
                        <Col xl='3' >
                            <Card className='p-1 py-2 mt-3 FundsForstyle'>
                                <div className='p-1'>
                                    <h5 className='font-weight-bold text-muted py-2'>Payment history</h5>
                                    <div className='d-flex align-items-center underLineForLink'>
                                        <img src={TrxIcon} alt="transectionICon" />&nbsp;<p className='text-muted'><NavLink className='underlinetext' to='/Dashboard/TransactionSummary'>View transaction history</NavLink></p>
                                    </div>
                                </div>
                            </Card>
                            <Card className='p-1 py-2 mt-3 FundsForstyle'>
                                <div className='p-1'>
                                    <h5 className='font-weight-bold text-muted py-2'>Help Center</h5>
                                    <div className='d-flex align-items-center underLineForLink text-muted'>
                                        <HelpCircle size={32} />&nbsp;<p className='text-muted'>
                                            <a href="https://www.sociomee.com/knowledgebase-category/ad-manager/">Open Help Center</a>
                                            {/* <NavLink className='underlinetext' to='https://www.sociomee.com/knowledgebase-category/ad-manager/'>Open Help Center</NavLink> */}
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xl='2' ></Col>
                    </Row>
                </div>
            </Box>
        </Box >
    );
}