import React, { useEffect, useState } from "react";
import { ArrowLeft, CheckSquare, Circle, FilePlus, PlusCircle, PlusSquare } from "react-feather";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip } from "reactstrap";
import MultiChoice from "./MultiChoice";
import ShortAnswer from "./ShortAnswer";
import SingleChoice from "./SingleChoice";


const LeadGenerationScreen = () => {
  const [formDetail, setFormDetail] = useState({ formName: "", formDesc: "" });
  const [inputType, setInputType] = useState([]);
  const [multiOptionCount1, setMultiOptionCount1] = useState([1, 2]);
  const [multiOptionCount2, setMultiOptionCount2] = useState([1, 2]);
  const [star, setStar] = useState(false)
  const [star1, setStar1] = useState(false)
  const [starFirst, setStarFirst] = useState(false)
  const [starFirst1, setStarFirst1] = useState(false)
  const [starSecond, setStarSecond] = useState(false)
  const [starSecond1, setStarSecond1] = useState(false)
  const inputHandler = (ev) => {
    const { name, value } = ev.target;
    setFormDetail({ ...formDetail, [name]: value });
    setIsSubmitDisabled(value.length === 0);

  };
  const inputRemover = ({ type, id }) => {
    setInputType(inputType.filter(inp => inp.id !== id))
    // inp.type !== type && 
  }
  const navigate = useNavigate()
  const [Desktop, setMobile] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const submitHandler = () => {
    setIsDisabled(!isDisabled);
    window.history.back();
  };

  const optInc = () => {
      setMultiOptionCount1([...multiOptionCount1, multiOptionCount1.length + 1]);
  };
  const optInc1 = () => {
    setMultiOptionCount2([...multiOptionCount2, multiOptionCount2.length + 1]);
};

  const removeOneOption = () => {
    console.log(multiOptionCount1, "multiOptionCount")
    setMultiOptionCount1(prevState => prevState.slice(0, -1));
  }
  const removeOneOption1 = () => {
    console.log(multiOptionCount2, "multiOptionCount")
    setMultiOptionCount2(prevState => prevState.slice(0, -1));
  }
  const buttonStyle = {
    backgroundColor: isSubmitDisabled ? 'rgb(172, 218, 214)' : '#81c14b',
    color: 'white',
    // padding: '10px 20px',
    border: 'none',
    cursor: isSubmitDisabled ? 'not-allowed' : 'pointer',
    transition: 'background-color 0.3s',
    width: '100%'
  };
  const [singleOptionCount, setSingleOptionCount] = useState([1, 2]);
  const optionIncrementHandler = (e) => {
    e.preventDefault();
    if (singleOptionCount.length < 10) {
      const nextData = `seq${singleOptionCount.length + 1}`;
      setSingleOptionCount([
        ...singleOptionCount,
        singleOptionCount.length + 1,
      ]);

      // setTempSingleOption({ ...tempSingleOption, [nextData]: "" });
    }
  };
  const [multiOptionCount, setMultiOptionCount] = useState([1, 2]);
  const [visitCount, setVisitCount] = useState(0);
  const [adText, setAdText] = useState('');

  useEffect(() => {
    const initializeAd = () => {
      // Get visit count from local storage
      const storedVisitCount = parseInt(localStorage.getItem('visitCount'), 10) || 0;

      const adOptions = [
        `${localStorage.getItem("adTypeName")}/  Single Image Ad  /  Configure Your Ad `,
        `${localStorage.getItem("adTypeName")}/  Carousel Image Ad  /  Configure Your Ad `,
        `${localStorage.getItem("adTypeName")}/  Video Ad  / Configure Your Ad `,
        `${localStorage.getItem("adTypeName")}/  Full Screen Video Ad  / Configure Your Ad `,
      ];

      const newVisitCount = (storedVisitCount + 1) % adOptions.length;
      setVisitCount(newVisitCount);

      const newAdText =
        newVisitCount < adOptions.length
          ? adOptions[newVisitCount]
          : 'Default Ad Text';

      setAdText(adOptions[localStorage.getItem("adTypeName") !== "Video Views - CPV" ? storedVisitCount : storedVisitCount + 2]);
      // localStorage.setItem('visitCount', newVisitCount);
    };

    initializeAd();
  }, []);
  return (
    <div>
      <Card className='pl-2' style={{ backgroundColor: '#f9fbf7' }}>
        <CardHeader className='py-3'>
          <div className="d-flex align-items-center"><ArrowLeft
            onClick={() => navigate(-1)}
          />	&nbsp;&nbsp;<h4><strong>Lead Generation Form</strong></h4></div>
        </CardHeader>
        <CardBody className='p-0 '>
          <Row>
            {/* ==================col-sm-2============ */}
            <Col xl='3' className='border-right p-0'>
              <Card>
                <CardHeader className='bg-transparent'>
                  <div className="">
                    <h4 className="p-heading" style={{ color: 'rgba(77, 77, 77, 1)' }}>Select Input Type</h4>
                    <p className="">Choose kind of information to be filled
                      by user.</p>
                  </div>
                </CardHeader>
                <CardBody style={{ display: 'contents' }} className='m-2'>
                  {/* ================input box============ */}
                  <div onClick={() => setInputType([...inputType, { type: "short", id: Math.floor(Math.random() * 10) },])} className='d-flex align-items-center' style={{ marginLeft: '13px' }}><FilePlus id='shortid' color='#4D4D4D' size={15} />&nbsp;<p style={{ cursor: 'pointer', fontSize: '13px', textWrap: 'nowrap' }}>Short answer (+$1)</p>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="d-flex lead-switch ml-5">
                      {/* <p className="p-heading">Add Options</p> */}
                      {multiOptionCount?.length < 10 && (
                        <p style={{ fontSize: '13px', marginTop: '-7px', position: 'absolute', right: '6px' }}
                          className="lead-greem"
                          role="button"
                          onClick={optionIncrementHandler}
                        >
                          + Add More
                        </p>
                      )}
                    </div>
                    <UncontrolledTooltip placement='top' target='shortid'>
                      Short answer (+$1)
                    </UncontrolledTooltip>
                  </div>
                  {/* ================input box end============ */}
                  {/* ================Short answer start============ */}
                  <div onClick={() => setInputType([...inputType, { type: "single", id: Math.floor(Math.random() * 10) },])} className='d-flex align-items-center mt-2' style={{ marginLeft: '13px' }}><PlusCircle id='singleid' color='#4D4D4D' size={15} />&nbsp;<p style={{ cursor: 'pointer', fontSize: '13px' }}>Single Choice Options (+$1)</p>
                    <UncontrolledTooltip placement='top' target='singleid'>
                      Single Choice Options (+$1)
                    </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="d-flex lead-switch">
                      {/* <p className="p-heading">Add Options1</p> */}
                      {multiOptionCount?.length < 10 && (
                        <p style={{ fontSize: '13px', position: 'absolute', right: '6px', marginTop: '-7px' }}
                          className="lead-greem"
                          role="button"
                          onClick={optionIncrementHandler}
                        >
                          + Add More
                        </p>
                      )}
                    </div>
                  </div>
                  {/* ================Short answer end============ */}
                  {/* =========Multiple answer start========= */}
                  <div onClick={() => setInputType([...inputType, { type: "multi", id: Math.floor(Math.random() * 10) },])} className='d-flex align-items-center mt-2' style={{ marginLeft: '13px', marginBottom: '15px' }}><PlusSquare id='multiid' color='#4D4D4D' size={15} />&nbsp;<p style={{ cursor: 'pointer', fontSize: '13px' }}>Multi Choice Options (+$1)</p>
                    <UncontrolledTooltip placement='top' target='multiid'>
                      Multi Choice Options (+$1)
                    </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="d-flex lead-switch">
                      {/* <p className="p-heading">Add Options</p> */}
                      {multiOptionCount?.length < 10 && (
                        <p style={{ fontSize: '13px', position: 'absolute', right: '6px', marginTop: '-7px' }}
                          className="lead-greem"
                          role="button"
                          onClick={optionIncrementHandler}
                        >
                          + Add More
                        </p>
                      )}
                    </div>
                  </div>
                  {/* =======Multiple answer end========= */}
                </CardBody>
              </Card>
            </Col>
            {/* ==================col-sm-2 end============ */}
            <Col xl='4' className='border-right p-0 '>
              <Card style={{ backgroundColor: '#f9fbf7' }}>
                <CardHeader className='bg-transparent'>
                  <div className="">
                    {/* <h4 className=" text-primary" style={{ fontSize: '16px',lineHeight:'21px',color: '#4d4d4d', marginBottom: '0.5rem',marginRight: '0.3rem',fontFamily: "Poppins"}}> Lead Generation- CPL  /  Single Image Ad  /  Configure Your Ad </h4> */}
                    <h4 className=" text-primary" style={{ fontSize: '16px', lineHeight: '21px', color: '#4d4d4d', marginBottom: '0.5rem', marginRight: '0.3rem', fontFamily: "Poppins" }}>{adText} </h4>
                    <h3 className="p-heading">Customize Form</h3>
                  </div>
                </CardHeader>
                <CardBody className='m-1 '>
                  <div className="row ad-types-of-type-map position-relative shadow" style={{ borderTop: '10px solid #81C14B', borderRadius: '7px 7px 7px 7px', margin: '2px' }}>
                    <div className="single-ad w-100">
                      <div className="col-lg-12 inputs d-flex p-0">
                        <div className="col-lg-12 col-12 mt-3">
                          <div className="">
                            <p className="p-heading">Create Form</p>
                          </div>
                          <div className="">
                            <input
                              type="text"
                              className="form-control p-2"
                              placeholder="Form Name"
                              maxLength={32}
                              name="formName"
                              onChange={inputHandler}
                              value={formDetail.formName}
                            />
                          </div>
                          <p className="p-max-car">Upto 32 Characters</p>
                        </div>
                      </div>
                      <div className="textarea col-lg-12 mt-4">
                        <div className="">
                          <p className="p-heading">Form Description</p>
                        </div>
                        <div className="">
                          <textarea
                            className="form-control"
                            rows="5"
                            placeholder="Write your description"
                            maxLength={"48"}
                            name="formDesc"
                            value={formDetail.formDesc}
                            onChange={inputHandler}
                          >
                            {" "}
                          </textarea>
                        </div>
                        <p className="p-max-car">Max 48 Characters</p>
                      </div>
                    </div>
                  </div>
                  {inputType &&
                    inputType.map(({ type, id }, index) => {
                      return type === "single" ? (
                        <SingleChoice key={id + Math.random()} inputId={index} isDisplay={true} inputRadio={id} optInc={optInc} removeOneOption={removeOneOption} multiOptionCount1={multiOptionCount1} star={false} setStar={setStar} star1={star1} setStar1={setStar1} remover={() => inputRemover({ type: type, id: id })} />
                      ) : type === "multi" ? (
                        <MultiChoice key={id + Math.random()} inputId={index} isDisplay={true} inputMulti={id} remover={() => inputRemover({ type: type, id: id })} optInc1={optInc1} removeOneOption1={removeOneOption1} multiOptionCount2={multiOptionCount2} starFirst={false} setStarFirst={setStarFirst} starFirst1={starFirst1} setStarFirst1={setStarFirst1}/>
                      ) : type === "short" ? (
                        <ShortAnswer key={id + Math.random()} isDisplay={true} inputId={index} inputIdRadio={index} remover={() => inputRemover({ type: type, id: id })} starSecond={false} setStarSecond={setStarSecond} starSecond1={starSecond1} setStarSecond1={setStarSecond1}/>
                      ) : null
                    })}
                  {/* <div className='d-flex formToolForAnime bounce justify-content-around align-items-center border rounded py-2'> */}
                  {/* <div onClick={() => setInputType([...inputType, { type: "single", id: Math.floor(Math.random() * 10) },])} className='d-flex align-items-center'><PlusCircle id='singleid' color='#4D4D4D' size={15} /> <p style={{ cursor: 'pointer' }}>Radio Button</p>
                      <UncontrolledTooltip placement='top' target='singleid'>
                        Single Choice Options (+$1)
                      </UncontrolledTooltip>
                    </div> */}
                  {/* <div onClick={() => setInputType([...inputType, { type: "multi", id: Math.floor(Math.random() * 10) },])} className='d-flex align-items-center'><PlusSquare id='multiid' color='#4D4D4D' size={15} /><p style={{ cursor: 'pointer' }}>Check Box</p>
                      <UncontrolledTooltip placement='top' target='multiid'>
                        Multi Choice Options (+$1)
                      </UncontrolledTooltip>
                    </div> */}
                  {/* </div> */}
                </CardBody>
              </Card>
            </Col>
            <Col xl='5' className='p-0' style={{ backgroundColor: '#f9fbf7' }}>
              <Card >
                <CardHeader className='bg-transparent'>
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between align-items-end pb-2">
                        <div>
                          <h3 className="p-heading mb-0">Preview </h3>
                          <div style={{ marginBottom: '-17px', marginTop: '19px' }} className="d-flex pt-3">
                            <Button className={Desktop === false ? 'boderForactive' : ''} onClick={() => setMobile(false)} color='flat pb-0'><h4>Desktop</h4></Button>
                            <Button className={Desktop === true ? 'boderForactive' : ''} onClick={() => setMobile(true)} color='flat pb-0'><h4>Mobile</h4></Button>
                          </div>
                        </div>
                        {/* primary */}
                        {/* <Button color='' size='lg' style={{ backgroundColor: '#ACDAD6', color: 'white' }}>Save</Button> */}
                        <div className="btn-section d-flex justify-content-center" style={{ position: 'absolute', top: '-2px', right: '18px' }}>
                          <Link to=''>
                            <button
                              className={`btn ${isSubmitDisabled ? 'btn-disabled' : 'btn-enabled'}`}
                              style={buttonStyle}
                              onClick={submitHandler}
                              disabled={isSubmitDisabled}
                            >
                              Save
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className='m-1' style={{ padding: '25px' }}>
                  {
                    Desktop ?
                      <div className="" >
                        {/* paddingForform */}
                        {/* formToolForPreview */}
                        <div className="row ad-types-of-type-map border border-primary  bounce ">
                          <div className="single-ad">
                            <div className="col-sm-12 inputs d-flex p-0">
                              <div className="col-lg-12 col-12">
                                <div className="">
                                  <p className="p-heading" style={{ marginLeft: '17px' }}>
                                    {formDetail.formName || "Form name*"}
                                    {/* <p style={{position:'absolute',top:'6px',left:'145px',color:'red'}}>*</p> */}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="textarea col-sm-12">
                              <div className="">
                                <p className=" font-weight-normal" style={{ marginLeft: '17px', fontSize: '14px' }}>
                                  {formDetail.formDesc || "Form Description*"}
                                  {/* <p style={{position:'absolute',top:'6px',left:'185px',color:'red'}}>*</p> */}
                                </p>
                              </div>
                            </div>
                            {inputType &&
                              inputType.map(({ type, id }, index) => {
                                return type === "single" ? (
                                  <SingleChoice key={id + Math.random()} inputId={index} isDisplay={false}  star={star} setStar={setStar} inputRadio={id} remover={() => inputRemover({ type: type, id: id })} />
                                ) : type === "multi" ? (
                                  <MultiChoice key={id + Math.random()} inputId={index} isDisplay={false} inputMulti={id} remover={() => inputRemover({ type: type, id: id })} starFirst={starFirst} setStarFirst={setStarFirst} />
                                ) : type === "short" ? (
                                  <ShortAnswer isDisplay={false} key={id + Math.random()} inputId={index} inputIdRadio={index} remover={() => inputRemover({ type: type, id: id })} starSecond={starSecond} setStarSecond={setStarSecond}/>
                                ) : null
                              })}
                            <div className="btn-section d-flex justify-content-center mt-5">
                              <Link to='' style={{ width: '100%' }}>
                                <button
                                  className={`btn ${isSubmitDisabled ? 'btn-disabled' : 'btn-enabled'}`}
                                  style={buttonStyle}
                                  onClick={submitHandler}
                                  disabled={isSubmitDisabled}
                                >
                                  Submit
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div> :
                      <div className="row ad-types-of-type-map border border-primary bounce ">
                        {/* formToolForPreview  */}
                        <div className="single-ad">
                          <div className="col-lg-12 inputs d-flex p-0">
                            <div className="col-lg-12 col-12">
                              <div className="">
                                <p className="p-heading" style={{ marginLeft: '17px' }}>
                                  {formDetail.formName || "Form name*"}
                                  {/* <p style={{position:'absolute',top:'6px',left:'145px',color:'red'}}>*</p> */}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="textarea col-lg-12">
                            <div className="">
                              <p className="font-weight-normal" style={{ marginLeft: '17px', fontSize: '14px', color: '#4d4d4d', fontFamily: 'sans-serif' }}>
                                {formDetail.formDesc || "Form Description*"}
                                {/* <p style={{position:'absolute',top:'6px',left:'160px',color:'red'}}>*</p> */}
                              </p>
                            </div>
                          </div>
                          {inputType &&
                            inputType.map(({ type, id }, index) => {
                              return type === "single" ? (
                                <SingleChoice key={id + Math.random()} inputId={index} isDisplay={false} optInc={optInc} removeOneOption={removeOneOption} multiOptionCount1={multiOptionCount1} star={star} setStar={setStar} inputRadio={id} remover={() => inputRemover({ type: type, id: id })} />
                              ) : type === "multi" ? (
                                <MultiChoice key={id + Math.random()} inputId={index} isDisplay={false} inputMulti={id} remover={() => inputRemover({ type: type, id: id })} removeOneOption1={removeOneOption1} multiOptionCount2={multiOptionCount2} starFirst={starFirst} setStarFirst={setStarFirst} starFirst1={starFirst1} setStarFirst1={setStarFirst1}/>
                              ) : type === "short" ? (
                                <ShortAnswer key={id + Math.random()} isDisplay={false} inputId={index} inputIdRadio={index} remover={() => inputRemover({ type: type, id: id })} starSecond={starSecond} setStarSecond={setStarSecond} starSecond1={starSecond1} setStarSecond1={setStarSecond1}/>
                              ) : null
                            })}
                          <div className="btn-section d-flex justify-content-center mt-5">
                            <Link to='' style={{ width: '100%' }}>
                              <button
                                className={`btn ${isSubmitDisabled ? 'btn-disabled' : 'btn-enabled'}`}
                                style={buttonStyle}
                                onClick={submitHandler}
                                disabled={isSubmitDisabled}
                              >
                                Submit
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}

export default LeadGenerationScreen;