import React from 'react'
import { AlertTriangle, Eye, EyeOff,Trash2, Link2, MapPin, MoreVertical, Send,Sliders } from 'react-feather'
import { Link } from 'react-router-dom'
import { Card, Col, Row,Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalBody,
    ModalHeader} from 'reactstrap';
    import {
        DropdownItem,
        DropdownMenu,
        DropdownToggle,
        UncontrolledButtonDropdown,
        CustomInput
      } from "reactstrap";
import Mrkt from '../Img/MrktImg.svg'
import Market from "../Img/MarketPlace.png"
import { useState,Fragment,useEffect,  } from 'react'
import Sale from "../Img/Sale.png"
import { useSelector ,useDispatch} from 'react-redux'
import ShareModal from './ModalFolder/ShareModal'
import HideModal from './ModalFolder/HideModal'
import { getMarketPlaceProductsAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { deletePostAction} from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { loadProfileByUserId } from '../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import { Checkbox, Pagination, Stack,PaginationItem  } from "@mui/material";
import axios from 'axios';

const MarketSoldOut = (props) => {
  

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const filteredData = props?.data?.result?.filter(item =>
        item?.title?.toLowerCase().includes(props?.searchKey?.toLowerCase()));

        //console.log('rasOnSale', filteredData)
        // console.log("props =========================",props);

        const toggleshotzOpen = (e,id) => {
            e.stopPropagation();
            setShotzOpen((prevState) => !prevState)
        }

        const [pageIndex,setPageIndex]=useState(0);
        const [pageSize,setPageSize]=useState(10);
        const [followerData,setFollowerData]=useState([]);
        const [followingData,setFollowingData]=useState([]);
        const [error,setError]=useState('');
        const [searchKey,setSearchKey]=useState('');
        const [count,setCount]=useState(0);
        const [checkedItems, setCheckedItems] = useState([]);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                    if (user) {
                        const response = await axios.post(
                            `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                            { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                            { headers: { Authorization: `Bearer ${user.token}` } }
                        );
                        setFollowerData(response.data.data.successResult.rows);
                    } else {
                        setError('no user found');
                    }
                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }, [searchKey,pageIndex,pageSize]);
    
        useEffect(() => {
            const fetchData = async () => {
                try {
                    const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                    if (user) {
                        const response = await axios.post(
                            `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                            { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                            { headers: { Authorization: `Bearer ${user.token}` } }
                        );
                        setFollowingData(response.data.data.successResult.rows);
                    } else {
                        setError('no user found');
                    }
                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }, [searchKey,pageIndex,pageSize]);


    const [shareOpen, setShareOpen] = useState(false)
    const [deleteOpen,setDeleteOpen]=useState(false);
    const [deleteId,setDeleteId]=useState([]);
    const toggleShareOpen = (e) => {
        e.stopPropagation()
        setShareOpen((prevState) => !prevState)
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [shotzOpen, setShotzOpen] = useState(false)
    const toggleShotzOpen = () => {
        setShotzOpen((prevState) => !prevState)
    }

    let [copyLink, setCopyLink] = useState('')
    const postCopyLink = async (id) => { 
        copyLink = `${process.env.REACT_APP_IPURL}/MyMarketPlace/${id}`
        setCopyLink(copyLink)
        navigator.clipboard.writeText(copyLink)
        sweetAlertConfirmation('link copied to clipboard')
    };

    
    const toggleDeleteOpen =(e)=>{
        setDeleteId([]);
        e.stopPropagation();
        setDeleteOpen((prevState) => !prevState);
    }
    

    const handleChange=(e)=>{
        setSearchKey(e.target.value);
      }

    const toggleDeleteOpen1 =(e,id)=>{
        setDeleteId([id])
            e.stopPropagation();
            setDeleteOpen((prevState) => !prevState);
    }
    const deleteAllPost = (e) => {
        e.stopPropagation();
        dispatch(deletePostAction(deleteId));
        setDeleteOpen((prevState) => !prevState);
        sweetAlertConfirmation('Successfully deleted')
      };

      const sharePost = () => {
        sweetAlertConfirmation("post Shared successfully")
        setShareOpen((prevState) => !prevState);
      };

      
  const shareAll = () => {
    sweetAlertConfirmation(`Your post shared to everyone`);
    setShareOpen((prevState) => !prevState);
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems(prevCheckedItems => {
      if (prevCheckedItems.includes(id)) {
        // Uncheck item
        return prevCheckedItems.filter(itemId => itemId !== id);
      } else {
        // Check item
        return [...prevCheckedItems, id];
      }
    });
  };
  
  const isShareAllDisabled = checkedItems.length === 0;

  const ediptost =()=>{
    sweetAlertConfirmation("Edit feature is in progress")
  }

  const getSingleProduct = async(e,id)=>{
    //navigate(`/mpsingleproduct`, { state: id })
  
  }
  
  const hideOrNot=()=>{
    sweetAlertConfirmation("Hide feature is in progress")
  }

  const pageCount = Math.ceil(props?.count/12);
const isPrevDisabled = props?.pageIndex <= 0;
const isNextDisabled = props?.pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === props?.pageIndex) return;

    props?.setPageIndex(value - 1);
  };


const BasicsPagination = () => {
  return (
    <>
      <Stack>
        <Pagination
             count={pageCount}
             page={props?.pageIndex + 1}
             onChange={handlePageChange}
             className="PaginationCounts"
             renderItem={(item) => (
               <PaginationItem
                 {...item}
                 disabled={
                   (item.type === 'previous' && isPrevDisabled) ||
                   (item.type === 'next' && isNextDisabled)
                 }
               />
             )}
        ></Pagination>
      </Stack>
    </>
  );
};

  return (
    <Fragment>
            <div className="container-fluid section-t-space px-0">
                <div className="group-single-list-block">
                    {filteredData && filteredData.length > 0 ? (
                      <>
                        <Row>
                            {filteredData.map((data) => (
                                <Col md="3" key={data.id}>
                                    <Card className="market-slide-box">
                                        <img src={data?.thumbnailUrl} alt="market" className="img-fluid group-img" style={{cursor:"pointer"}} onClick={(e)=>getSingleProduct(e,data?.id)}/>
                                        <div className="gallery-img-sublink">
                                            <MoreVertical size={21} color="#FFFFFF" className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal">
                                            <ul>
                                                    <li onClick={hideOrNot}>
                                                        <Link to="#"><EyeOff size={16} color="#808080" /> Hide </Link>
                                                    </li>
                                                    <li onClick={postCopyLink}>
                                                        <Link to="#"><Link2 size={16} color="#808080" /> Copy link </Link>
                                                    </li>
                                                    <li onClick={toggleShareOpen}>
                                                        <Link to="#"><Send size={16} color="#808080" /> Share post </Link>
                                                    </li>
                                                    <li onClick={ediptost}>
                                                        <Link to="#"><AlertTriangle color="#808080" size={16} /> edit </Link>
                                                    </li>
                                                    <li onClick={(e) => toggleDeleteOpen1(e,data.id)}>
                                                        <Link to="#"><Trash2 size={16} color="#808080" /> Delete </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='MarketPlace_Card_LeftPosition '>
                                            <p><Eye color='white' size={20} />&nbsp; {data.totalViews}</p>
                                        </div>
                                        <div className='MarketPlace_Card_BottomLeftPosition ' style={{cursor:"pointer"}} onClick={(e)=>getSingleProduct(e,data?.id)}>
                                        {data.adminApproval === "accepted"?(
                                                <div className='BottomLeftPosition'>
                                                  <p>{data.type} </p>
                                                </div>
                                              ):(
                                                <div className='BottomLeftPosition'>
                                                  <p>Pending for Approval</p>
                                                </div>
                                              )} 
                                        </div>
                                        <div className="MarketPlace-content" style={{cursor:"pointer"}} onClick={(e)=>getSingleProduct(e,data?.id)}>
                                            <div className="MarketPlace_Bottom_Section">
                                                <h3>
                                                {data?.title?.substring(0, 14)}
                                                {data?.title?.length >= 14 && ' ...'}
                                                </h3>
                                            </div>
                                            <div className="MarketPlace_Sub_Section py-2 ">
                                                <h5>
                                                {data?.categoryName?.substring(0, 20)}
                                                {data?.categoryName?.length >= 20 && ' ...'}
                                                </h5>
                                                <p>
                                                    {data?.currencySymbol}
                                                    {data?.price?.substring(0, 7)}
                                                    {data?.price?.length >= 7 && ''} /-
                                                </p>
                                            </div>
                                            <div className="MarketPlace_End_section py-1">
                                                <p>
                                                    <MapPin color="#808080" size={18} />
                                                    &nbsp;
                                                    {data?.location?.substring(0, 30)}
                                                    {data?.location?.length >= 30 && ' ...'}
                                                </p>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <div className="d-flex justify-content-center">
                        <BasicsPagination />
                      </div>
                      <br/>
                        </>
                    ) : (
                      <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>No products yet</h3>
                    )}
                     <div className="vertically-centered-modal">
                    {deleteOpen !== false && (
                        <Modal
                        isOpen={deleteOpen}
                        toggle={toggleDeleteOpen}
                        className="modal-dialog-centered"
                        >
                        <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                            <h4>Delete Media Video</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex justify-content-center modalHeading_Section">
                            <p>Do you really want to delete all the selected Media ?</p>
                            </div>
                            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                            <small>if</small> YES! <small>please click on ok</small>
                            </h1>
                            <div className="d-flex justify-content-center  mt-4">
                            <Button onClick={toggleDeleteOpen} outline>
                                Cancel
                            </Button>
                            <Button
                                onClick={(e)=>deleteAllPost(e)}
                                color="primary"
                                className="ml-3 "
                            >
                                OK
                            </Button>
                            </div>
                        </ModalBody>
                        </Modal>
                    )}
                    </div>
                     <div className="vertically-centered-modal">
                     <div className="vertically-centered-modal">
                    {shareOpen && (
        <Modal
          isOpen={shareOpen}
          toggle={toggleShareOpen}
          className="modal-dialog-centered"
        >
          <div className="w-100 border-bottom">
            <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
              <h4>Share People</h4>
              <span style={{ cursor: isShareAllDisabled ? "not-allowed" : "pointer",alignItems:'center' }}
                  onClick={!isShareAllDisabled ? shareAll : undefined}
                  className={isShareAllDisabled ? 'disabled' : ''} 
              >
                Share
              </span>
            </div>
          </div>
          <ModalBody>
            <Row className="mt-2">
              <Col md="8" className="Like_search">
                <div className="profile-search-blk w-100">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                    value={searchKey}
                    autoFocus
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md="4" className="d-flex justify-content-end">
                <UncontrolledButtonDropdown color="secondary" outline>
                  <DropdownToggle
                    outline
                    className="border border-muted ml-3"
                  >
                    <Sliders className="Slider_Filter" size={12} />
                    &nbsp;&nbsp;Filter
                  </DropdownToggle>
                  <DropdownMenu className="DropDown_Group_item">
                    <DropdownItem onClick={() => setCount(0)}>
                      Followers
                    </DropdownItem>
                    <DropdownItem onClick={() => setCount(2)}>
                      Followings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
              <Row className="mt-3">
                {count === 0 && followerData?.filter(data =>
                  data.fullName.includes(searchKey) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                  data.followBackStatus.includes(searchKey)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
                {count === 2 && followingData?.filter(data =>
                  data.fullName.includes(searchKey) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey)) ||
                  data.followBackStatus.includes(searchKey)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </Row>
             
            </Row>
          </ModalBody>
        </Modal>
      )}
                </div>
                </div>
                    <HideModal isOpen={shotzOpen} updateOpen={toggleShotzOpen} />
                </div>
            </div>
        </Fragment>
  )
}

export default MarketSoldOut
