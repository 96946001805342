import { Checkbox } from '@mui/material'
import React, { useState, Fragment } from 'react'
import { CheckCircle, ChevronDown, Mic, PlayCircle } from 'react-feather'
import { Button, Card, CardImg, CardImgOverlay, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import LivePodcastCam from './LivePodcastCam'
import LiveGif from '../../../NewComponents/IMG/LiveGif.gif'

const StartLivePodcast = () => {

    /*  ----------------------States and Function--------------------  */

    const [checkboxActive, setcheckboxActive] = useState(false);
    const [UploadModals, setUploadModals] = useState(false)
    const [ActiveLive, setActiveLive] = useState(false)


    const [commentsActive, setCommentsActive] = useState(false);
    const [notificationActive, setNotificationActive] = useState(false);
   

    const toggleModal = () => {
        setUploadModals((prevState) => !prevState)
    }

    /*  ---------------------- Podecast Type Array --------------------  */

    const podacastType = [
        {
            type: 'Educations'
        },
        {
            type: 'Entertainment'
        },
        {
            type: 'Music'
        },
        {
            type: 'Educations'
        },
        {
            type: 'News Commentary'
        },
        {
            type: 'Culture'
        }
    ]

    /*  ---------------------- Cover Image Upload for Modal --------------------  */

    const UploadModal = () => {

        /*  ---------------------- Cover Image Array --------------------  */

        const uploadData = [
            {
                img: 'https://picsum.photos/900/270?grayscale',
                selectedImg: 'selected'
            },
            {
                img: 'https://picsum.photos/900/270?grayscale',
                selectedImg: 'Unselected'
            },
            {
                img: 'https://picsum.photos/900/270?grayscale',
                selectedImg: 'Unselected'
            },
            {
                img: 'https://picsum.photos/900/270?grayscale',
                selectedImg: 'Unselected'
            },
            {
                img: 'https://picsum.photos/900/270?grayscale',
                selectedImg: 'Unselected'
            },
            {
                img: 'https://picsum.photos/900/270?grayscale',
                selectedImg: 'Unselected'
            }
        ]

        return (
            <>
                {UploadModals ? (
                    <Modal isOpen={UploadModals} className='modal-dialog-centered' toggle={toggleModal} >
                        <ModalHeader toggle={toggleModal} className='text-center'>
                            <div className=''>
                                <h3>Select Your Cover Image for Podcast</h3>
                                <p>Use Profile picture, Camera or select a file from device Gallery.</p>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <>
                                <Row>
                                    {
                                        uploadData.map((item, index) => {
                                            return (
                                                <Col xl='6' xs='6' key={index}>
                                                    <Card inverse className={item.selectedImg === 'selected' ? 'mb-2 border border-primary' : 'mb-2 border'} >
                                                        <CardImg
                                                            alt="Card image cap"
                                                            src={item.img}
                                                            width="100%"
                                                            style={{
                                                                height: 150,
                                                                objectFit: "cover",
                                                                
                                                                
                                                            }}
                                                        />
                                                        <CardImgOverlay className='text-right'>
                                                            {item.selectedImg === 'selected' ? <CheckCircle color='#81C14B' /> : ''}
                                                        </CardImgOverlay>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>

                            </>
                        </ModalBody>
                        <div className='text-center m-2' >
                            <Button onClick={() => document.querySelector('.filedialog').click()} color='secondary' className='px-5 mr-2' outline>
                                Browse
                            </Button>
                            <input type="file" className="filedialog d-none" />
                            <Button color='primary' outline>
                                Upload cover photo
                            </Button>
                        </div>
                    </Modal>
                ) : (null)}
            </>
        )
    }

    /*  ---------------------- Form Section --------------------  */

    const FormSection = () => {
        const [selectedOption, setSelectedOption] = useState(' Select Who Can Listen');

        const handleDropdownSelect = (option) => {
            setSelectedOption(option);
        };
        const [selectedOption1, setSelectedOption2] = useState(' Mic testing');

        const handleDropdownSelect2 = (option) => {
            setSelectedOption2(option);
        };

        const [selectedType1, setSelectedType1] = useState('Choose your category');

        const handleTypeSelect1 = (type) => {
            setSelectedType1(type);
        };
        return (
            <div className='my-2'>
                <Card>
                    <div className='createForHeadercolor'>
                        <h3>Setup media</h3>
                    </div>
                    <Row className='rowForrevert'>
                        <Col xl='6'>
                            <div className='d-flex align-items-center m-2'>
                                <div className='w-100'>
                                    <Label tag='h4' for="exampleSelect">
                                        Mic Selection
                                    </Label>
                                    <div style={{ marginTop: '0.7rem' }} className='d-flex align-items-center p-0'>
                                        <Mic size={24} />
                                        <UncontrolledButtonDropdown className='w-100'>
                                            <DropdownToggle color='secondary text-left' outline className='d-flex border-clr justify-content-between align-items-center custom-toggle secondary Fs14_Fw_400' style={{ fontSize: '14px', padding: '8px 15px' }}>
                                                {selectedOption1}
                                                <ChevronDown size={18} />
                                            </DropdownToggle>
                                            <DropdownMenu className='w-100 who-can-listen-scroll' style={{ zIndex: '3' }}>
                                                <div className='text-left'>
                                                    <DropdownItem className='Fs14_Fw_400' onClick={() => handleDropdownSelect2('Microphone')}>Microphone</DropdownItem>
                                                    <DropdownItem className='Fs14_Fw_400' onClick={() => handleDropdownSelect2('Detect your System')}>Detect your System</DropdownItem>
                                                    <DropdownItem className='Fs14_Fw_400' onClick={() => handleDropdownSelect2('  Add Mic')}>  Add Mic</DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='d-flex align-items-center m-2'>

                                <FormGroup className='w-100 my-2'>
                                    <Label tag='h4' for="exampleSelect">
                                        Test Your Audio
                                    </Label>
                                    <div className='d-flex align-items-center my-1'>
                                        {/* <Mic size={24} /> */}
                                        {/* <Input disabled name="select" type="select" className='PodacstForInput' >
                                            <option>
                                                Live Podcast
                                            </option>
                                        </Input> */}
                                        {/* <Input type='text' placeholder='Live Podcast' disabled /> */}
                                        {/* <div className="col-lg-4 col-md-5 col-sm-4 col-5"> */}
                                        <a className="podcast-test-button w-100">
                                            <PlayCircle /> Test audio
                                        </a>
                                        {/* </div> */}
                                    </div>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>

                    <Row className='rowForrevert'>
                        <Col xl='6'>
                            <div className='m-2'>
                                <FormGroup className=''>

                                    <h4 className='pb-1'>Catagories</h4>

                                    {/* <Input name="select" type="select" className='PodacstForInput' >
                                        {
                                            podacastType.map((types, index) => {
                                                return (
                                                    <option key={index}>
                                                        {types.type}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Input> */}
                                    <div>

                                        <UncontrolledButtonDropdown className='w-100'>
                                            <DropdownToggle color='secondary text-left' outline className='d-flex border-clr justify-content-between align-items-center custom-toggle secondary Fs14_Fw_400' style={{ fontSize: '14px', padding: '8px 15px' }}>
                                                {selectedType1}
                                                <ChevronDown size={18} />
                                            </DropdownToggle>

                                            <DropdownMenu className='w-100 categories-scroll'>
                                                <div className='text-left'>
                                                    {podacastType.map((type, index) => (
                                                        <DropdownItem className='Fs14_Fw_400' key={index} onClick={() => handleTypeSelect1(type.type)}>
                                                            {type.type}
                                                        </DropdownItem>
                                                    ))}
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xl='6'>
                            <div className='m-2'>
                                <FormGroup className='w-100 my-1'>

                                    <h4 className='pb-1' >Who Can Listen</h4>

                                    {/* <Input name="select" className='PodacstForInput' type="select" >
                                        <option>Public</option>
                                        <option>Friends</option>
                                        <option>SocioMate</option>
                                    </Input> */}

                                    <div className="">
                                        <UncontrolledButtonDropdown className='w-100'>
                                            <DropdownToggle color='secondary text-left' outline className='d-flex border-clr justify-content-between align-items-center custom-toggle secondary Fs14_Fw_400' style={{ fontSize: '14px', padding: '8px 15px' }}>
                                                {selectedOption}
                                                <ChevronDown size={18} />
                                            </DropdownToggle>
                                            <DropdownMenu className='w-100 who-can-listen-scroll' style={{ zIndex: '3' }}>
                                                <div className='text-left'>
                                                    <DropdownItem className='Fs14_Fw_400' onClick={() => handleDropdownSelect('Public')}>Public</DropdownItem>
                                                    <DropdownItem className='Fs14_Fw_400' onClick={() => handleDropdownSelect('Friends')}>Friends</DropdownItem>
                                                    <DropdownItem className='Fs14_Fw_400' onClick={() => handleDropdownSelect('SocioMate')}>SocioMate</DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>

                                    </div>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }

    /*  ---------------------- Setting Section --------------------  */

    const AdvanceSetting = () => {

        return (
            <Row>
                <Col xl='12'>
                    <div className="golive-card-box">
                        <div className="golive-card-head"><h3>Viewer Setting (More options to be added)</h3></div>
                        <div className="golive-card-body">
                            <div className="setup-viewer-setting-blk">
                                <div className="golivesetting-toggle-box">
                                    <div className="gl-setting-toggle-cont">
                                        <h4>Turn on comments</h4>
                                        <h5>By turning on this option you are agreeing with the fact that viewers can now comment on your podcast.</h5>
                                    </div>
                                    <div className="toggle-sec">
                                        <div className="button toggle-btn">
                                            <input type="checkbox" className="checkbox"
                                             checked={commentsActive}
                                             onChange={() => setCommentsActive(!commentsActive)}
                                             />
                                            <div className="knobs">
                                                <span></span>
                                            </div>
                                            <div className="layer"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="golivesetting-toggle-box">
                                    <div className="gl-setting-toggle-cont">
                                        <h4>Enable notification</h4>
                                        <h5>You will get notified if any activity happens over your podcast.</h5>
                                    </div>
                                    <div className="toggle-sec">
                                        <div className="button toggle-btn">
                                            <input type="checkbox" className="checkbox"
                                              checked={notificationActive}
                                              onChange={() => setNotificationActive(!notificationActive)} />
                                            <div className="knobs">
                                                <span></span>
                                            </div>
                                            <div className="layer"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='golivesetting-toggle-box1'>
                                    <div className="m-31 d-flex align-items-center">
                                        <Checkbox
                                            onClick={() => setcheckboxActive(!checkboxActive)}
                                            checked={checkboxActive ? true : false}
                                        />
                                        <h4 className='ml-2 form-check-label'>I accept the <span className='text-primary'>Privacy Policy</span></h4>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <Button disabled={checkboxActive ? false : true} onClick={() => setActiveLive(!ActiveLive)} color='primary' className='w-100'>Start Live</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
        )
    }

    return (
        <>
            {
                ActiveLive ? <LivePodcastCam back={() => setActiveLive(false)} /> :
                    <Fragment>

                        {/*  ---------------------- Form Section --------------------  */}

                        <Header />
                        <div className="page-body container-fluid profile-page">
                            <LeftSidebar />
                            <div className="page-center">
                                <div className="container-fluid">
                                    <div className='createForHeadercolor mb-1'>
                                        <h3><img width={20} src={LiveGif} alt="LiveGif1" /> &nbsp;Live Podcast</h3>
                                        {/* <DropdownItem onClick={() => navigate('/Podcast/StartLivePodcast')}><img width={20} src={LiveGif} alt="LiveGif1" /> Start Live Podcast</DropdownItem> */}
                                    </div>
                                    { /*  ---------------------- Cover Image Upload for Modal --------------------  */}
                                    <UploadModal />

                                    <Card inverse className='w-100'>
                                        <CardImg
                                            alt="Card image cap"
                                            src="https://picsum.photos/900/270?grayscale"
                                            className='img-fluid w-100'
                                        />
                                        <CardImgOverlay>
                                            <div className='bottomright'>
                                                <Button inverse color='light' onClick={() => toggleModal()} outline>
                                                    Upload Podcast Cover Image
                                                </Button>
                                            </div>
                                        </CardImgOverlay>

                                    </Card>

                                    {/*  ---------------------- Form Section --------------------  */}

                                    <FormSection />

                                    {/*  ---------------------- Setting Section --------------------  */}

                                    <AdvanceSetting />
                                </div>
                            </div>
                            <RightSidebar />
                        </div>

                    </Fragment>
            }
        </>
    )
}

export default StartLivePodcast