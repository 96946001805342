import React from 'react'
import AllPostDisplayNav from '../AllPostDisplayNav'

const Display = () => {
    return (
        <div>
            <AllPostDisplayNav />
        </div>
    )
}

export default Display