import React from 'react'
import { Card, Input } from 'reactstrap'
import CreatepostFooter from './CreatepostFooter'
import CreatepostHeader from './CreatepostHeader'

const Recommendation = (props) => {
    return (
        <Card className='p-3 rounded'>
            <CreatepostHeader
                actionM={props.NavMedia}
                actionT={props.NavThrought}
                actionG={props.NavGoLive}
                actionE={props.NavEvent}
                actionP={props.NavPodcast}
                actionA={props.NavArticle}
                actionR={props.NavReccom}
                actionAl={props.NavAlert}
                actionPo={props.NavPoll}
                actionS={props.NavSell}
            />
            <div className='d-flex align-items-center my-2 py-2'>
                <img className='rounded-circle border' width={30} height={30} src='https://web.aflia.net/wp-content/uploads/2018/12/dp_placeholder-275x300.jpg' alt='' />
                &nbsp;<h5 style={{ color: '#4D4D4D', fontWeight: '500' }} >Sufiya Eliza</h5>
            </div>
            <div>
                <div className='mx-4 text-center'>
                    <Input
                        type="textarea"
                        className="d-flex text-primary positionForReccplaceholder align-items-center text-left w-100"
                        value='I am seeking a recommendation for '
                        cols="15"
                        rows="5"
                    />
                </div>
            </div>
            <CreatepostFooter
                Sixthicon={'d-none'}
            />
        </Card>
    )
}

export default Recommendation