import React from 'react'
import { Circle } from 'react-feather'
import { Col, Input, Row } from 'reactstrap'
import ColorWheel from '../../NewComponents/IMG/ColorWheel.gif'

const Colour = (props) => {
    const Coloroptiondata = [
        {
            text: '#FB6E6E'
        },
        {
            text: '#A8FFAC'
        },
        {
            text: '#7DC1FF'
        },
        {
            text: '#A49494'
        },
        {
            text: '#FD98FF'
        },
        {
            text: '#F8DF88'
        },
        {
            text: '#60715C'
        },
        {
            text: '#353535'
        },
        {
            text: '#FFFFFF'
        },
    ]
    return (
        <div>

            {props.show === '0' ?
                < Row className='rowForrevert'>
                    <Col>
                        <p style={{ color: '#A6A6A6' }} className='px-3'>Text Color</p>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div style={{ marginRight: '-4px', paddingLeft: '10px' }} className='d-flex justify-content-between align-items-center'>
                                {
                                    Coloroptiondata.map((item) => {
                                        return <>
                                            <Circle className='' onClick={() => { props.colorManagerText(item.text) }} fill={item.text} color={item.text} size={25} />
                                        </>
                                    })
                                }
                            </div>
                            <Input type='color' onChange={(e) => { props.colorManagerText(e.target.value) }} style={{ visibility: "hidden" }} className=' ColorWheel' />
                            <img onClick={() => document.querySelector('.ColorWheel').click()} width={30} height={30} alt='ColorWheelicon' src={ColorWheel} />
                        </div>

                    </Col>
                </Row> : props.show === '1' ?
                    < Row className='rowForrevert'>
                        <Col>
                            <p style={{ color: '#A6A6A6' }} className='px-3'>Text Color</p>
                            <div className='d-flex justify-content-between align-items-center gap-1 px-3'>
                                <div className='d-flex justify-content-between align-items-center gap-1 '>
                                    {
                                        Coloroptiondata.map((item) => {
                                            return <>
                                                <Circle className='' onClick={() => { props.colorManagerText(item.text) }} fill={item.text} color={item.text} size={25} />
                                            </>
                                        })
                                    }
                                </div>
                                <Input type='color' onChange={(e) => { props.colorManagerText(e.target.value) }} style={{ visibility: "hidden" }} className=' ColorWheel' />
                                <img onClick={() => document.querySelector('.ColorWheel').click()} width={30} height={30} alt='ColorWheelicon' src={ColorWheel} />
                            </div>

                        </Col>
                    </Row> : props.show === '2' ?
                        < Row className='rowForrevert'>
                            <Col>
                                <p style={{ color: '#A6A6A6' }} className='px-3'>Text Color</p>
                                <div className='d-flex justify-content-between align-items-center gap-1 px-3'>
                                    <div className='d-flex justify-content-between align-items-center gap-1 '>
                                        {
                                            Coloroptiondata.map((item) => {
                                                return <>
                                                    <Circle className='' onClick={() => { props.colorManagerText(item.text) }} fill={item.text} color={item.text} size={25} />
                                                </>
                                            })
                                        }
                                    </div>
                                    <Input type='color' onChange={(e) => { props.colorManagerText(e.target.value) }} style={{ visibility: "hidden" }} className=' ColorWheel' />
                                    <img onClick={() => document.querySelector('.ColorWheel').click()} width={30} height={30} alt='ColorWheelicon' src={ColorWheel} />
                                </div>

                            </Col>
                        </Row> : null
            }

            {props.show === '2' ?
                <Row className='rowForrevert'>
                    <Col>
                        <p style={{ color: '#A6A6A6' }} className='px-3'>Background Color</p>
                        <div className='d-flex justify-content-between align-items-center gap-1 px-3'>
                            <div className='d-flex justify-content-between align-items-center gap-1 '>

                                {
                                    Coloroptiondata.map((item) => {
                                        return <>
                                            <Circle className='' onClick={() => { props.colorManager(item.text) }} fill={item.text} color={item.text} size={25} />
                                        </>
                                    })
                                }
                            </div>
                            <Input type='color' onChange={(e) => { props.colorManager(e.target.value) }} style={{ visibility: "hidden" }} className='ColorWheelb' />
                            <img onClick={() => document.querySelector('.ColorWheelb').click()} width={30} height={30} alt='ColorWheelicon' src={ColorWheel} />
                        </div>

                    </Col>
                </Row> : null
            }


        </div >
    )
}

export default Colour