import React from 'react'
import { Fragment } from 'react'
import NftProfileImg from '../Img/NftProfileImg.png'

const NftAavatarGroup = () => {

    const avatardata = [
        {
            img: NftProfileImg
        },
        {
            img: NftProfileImg
        },
        {
            img: NftProfileImg
        }
    ]

    //=================== Main_Return_Function ==================//
    return (
        <Fragment>
            <div>
                <ul className="matual-friend-blk">
                    {
                        avatardata.map((item, index) => {
                            return (
                                <li 
                                    data-name="sufiya eliza" data-img={item.img}>
                                    <img src={item.img} className="img-fluid bg-img" alt="" />
                                </li>
                            )
                        })
                    }
                </ul>
            </div >
        </Fragment>
    )
}

export default NftAavatarGroup