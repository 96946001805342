import * as types from "../../Constants/SocialFeed/index";
import axios from "axios";
import { loadAllUserPosts } from "./getAllUserPostsAction";


const getPostReported = (getPostReport) => ({
  type: types.GET_POST_REPORT,
  payload: getPostReport,
});

export const getFeedReportClick = (postId, reportOptionId) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/post/reportPost`,
          {
            postId: postId,
            reportOptionId: reportOptionId,
          },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          dispatch(getPostReported(res.data.data.successResult));
          if (res.status === 200) {
            dispatch(loadAllUserPosts());
          } else {
            console.error(
              "Error Rep[orting] post. Server responded with status:",
              res.status
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};
