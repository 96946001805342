import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Modal, ModalHeader, ModalBody, Row, Col, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import './Trending.css'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import '../Search-Components/Search.css'
import Slider from "react-slick";
import { useTheme, Slider as MatSlider, Rating, Checkbox } from '@mui/material';
import Stack from '@mui/material/Stack';
import ChipBox from './ChipBox'
import ChipBoxLocation from './ChipBoxLocation'
import PeopleTabsTrendind from './PeopleTabsTrendind'
import TagsTabsTrending from './TagsTabsTrending'
// import ImagesTabsTrending from './ImagesTabsTrending'
import VideosTabsTrending from './VideosTabsTrending'
import ShotzsTabsTrending from './ShotzsTabsTrending'
import BizPageTabsTrending from './BizPageTabsTrending'
import GroupTabsTrending from './GroupTabsTrending'
import ThoughtTabsTrending from './ThoughtTabsTrending'
import RecommendationTabsTrending from './RecommendationTabsTrending'
// import ThreatTabsTrending from './ThreatTabsTrending'
import TextTabsTrending from './TextTabsTrending'
import PollTabsTrending from './PollTabsTrending'
import GoLiveTabsTrending from './GoLiveTabsTrending'
import EventTabsTrending from './EventTabsTrending'
import PodCastTabsTrending from './PodCastTabsTrending'
import AudioTabsTrending from './AudioTabsTrending'
import AriticleTabsTrending from './AriticleTabsTrending'
import { ChevronDown, ChevronRight, Filter, MinusSquare, PlusSquare } from 'react-feather'
// import Counrtries from './Counrtries'
import MediaPreviewModal from '../Universal-components/post-creations/sub-components/MediaPreviewModal'
import { CountryFilters } from '../Connections/HoverComponents/CountryFilters'
import Counrtries from './Counrtries'
// import { CheckBox } from '@mui/icons-material'
import $ from 'jquery';
import TrendingNavList from './TrendingNavList'


let ownedGroupSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 14,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 425,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 10,
                slidesToScroll: 3
            }
        },
    ]
};


const Trending = () => {

    // -----------------------------------------------Trending Countries and Filter Start Here-------------------------------------------------------------

    const Trendingcountries = (props) => {
        const [basicModal, setBasicModal] = useState(false)
        const theme = useTheme()

        const [locationRadius, setLocationRadius] = useState(8821)
        const increaseRadius = () => {
            setLocationRadius((prevState) => prevState + 50)
        }
        const decreaseRadius = () => {
            setLocationRadius((prevState) => prevState - 50)
        }
        const [isOpen, setIsOpen] = useState(false);

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };
        const [temperature, setTemperature] = useState(0);

        const handleTemperatureChange = (e) => {
            setTemperature(parseInt(e.target.value, 10));
        };

        // const increaseTemperature = () => {
        //     setTemperature((prevTemp) => prevTemp + 5);
        // };

        // const decreaseTemperature = () => {
        //     setTemperature((prevTemp) => prevTemp - 5);
        // };
        const decreaseTemperature = () => {
            if (temperature > 0) {
                setTemperature(temperature - 1);
            }
        };

        const increaseTemperature = () => {
            setTemperature(temperature + 1);
        };
        // const AvatarGroup = () => {
        //     const avatardata = [
        //         {
        //             img: 'assets/images/flag-india.png'
        //         },
        //         {
        //             img: 'assets/images/flag.jpg'
        //         },
        //         {
        //             img: 'assets/images/flag-india.png'
        //         },
        //         {
        //             img: 'assets/images/flag.jpg'
        //         }
        //     ]
        //     return (
        //         <div>
        //             <ul className="matual-friend-blk">
        //                 {
        //                     avatardata.map((item, index) => {
        //                         return (
        //                             <li key={index} className="popover-cls" data-bs-toggle="popover" data-placement="right"
        //                                 data-name="sufiya eliza" data-img={item.img}>
        //                                 <img src={item.img} className="img-fluid bg-img" alt="" />
        //                             </li>
        //                         )
        //                     })
        //                 }
        //             </ul>
        //         </div >
        //     )
        // }
        const [isCounrtriesOpen, setIsCounrtriesOpen] = useState(false);

        const toggleCounrtries = () => {
            setIsCounrtriesOpen(!isCounrtriesOpen);
        };
        const handleCountryModal = () => {
            setIsCounrtriesOpen(!isCounrtriesOpen);
            if (!isCounrtriesOpen) {
                $('.country-toggle').click();
            }

        }
        return (
            <>
                <Row className="d-flex align-items-center mt-2">
                    <Col lg='8' md='8' className='' >
                        <div className='d-flex align-items-center p-2'>
                            <div className='d-flex position-relative toptexts' onClick={handleCountryModal}>
                                <h2 style={{ cursor: 'pointer', textWrap: "nowrap" }} className='toptext'>Trending {name}</h2>
                                &nbsp;&nbsp;
                                {/* <h4 style={{ color: '#81c14b', cursor: 'pointer', textWrap: "nowrap" }} className='mt-1 Countrynametexts'>
                                    / Country name
                                </h4> */}
                                <UncontrolledButtonDropdown className="rounded d-flex align-items-center p-0">
                                    <DropdownToggle color="transparent" className="p-0 d-flex align-items-center " >
                                        <span style={{ color: '#81c14b', cursor: 'pointer', textWrap: "nowrap", fontSize: '13px' }}>/Country name</span>
                                        <ChevronDown size={15} className='ml-2' color='green' />
                                    </DropdownToggle>
                                    <DropdownMenu style={{ top: '50px', zIndex: '100', left: '-9rem' }} className='country-drop-menu' >
                                        <Counrtries />
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>


                    </Col>
                    <Col lg='4' md='4' className='d-flex align-items-center justify-content-end'>
                        <ul className="align-self-center">
                            <li>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-0 m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center filterbt' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2 filtericon' />
                                            <span className="button-text">Filters</span>
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </li>
                        </ul>

                        <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                            <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>
                                {/* ----------------------------------------filter props ModalHeader start here------------------------------                     */}
                                {
                                    props.data === '1' ?
                                        'Media Filter' :
                                        props.data === '2' ?
                                            'Text Filter' :
                                            props.data === '3' ?
                                                'Peoples Filter' :
                                                props.data === '5' ?
                                                    'Tags Filter' :
                                                    props.data === '6' ?
                                                        'Shotzs Filter' :
                                                        props.data === '7' ?
                                                            'Groups Filter' :
                                                            props.data === '8' ?
                                                                'Bizpage Filter' :
                                                                props.data === '9' ?
                                                                    'Thoughts Filter' :
                                                                    props.data === '10' ?
                                                                        'Recommendation Filter' :
                                                                        props.data === '11' ?
                                                                            'Events Filter' :
                                                                            props.data === '12' ?
                                                                                'Podcast Filter' :
                                                                                props.data === '13' ?
                                                                                    'Golive Filter' :
                                                                                    props.data === '15' ?
                                                                                        'Poll Filter' :
                                                                                        props.data === '16' ?
                                                                                            'Article Filter' :
                                                                                            props.data === '17' ?
                                                                                                'Audio Filter' : null
                                }
                            </b>
                            </ModalHeader>
                            {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                            <ModalBody>
                                {
                                    props.data === '1' ?
                                        <div>
                                            <div className='d-flex justify-content-between'>
                                                <h4>Withim (Km)</h4>
                                                <h4>{temperature}km</h4>
                                            </div>
                                            <div className='d-flex justify-content-between mt-2'>
                                                <div className='w-100'>
                                                    {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                    {/* ==========================change km value=============== */}
                                                    <div>
                                                        <input
                                                            type="range"
                                                            min="0"
                                                            max="8821"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                        />

                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                    </div>
                                                    {/* ==========================change km value end =============== */}
                                                    {/* dsadFDFF */}
                                                    {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                    {/* </div> */}
                                                    {/* SDASDFFF */}
                                                </div>
                                                <div className='d-flex align-items-center ml-3' role='button'>
                                                    <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                </div>
                                            </div>
                                            <div className=' mt-2'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h4>Choose locations</h4>
                                                    <CountryFilters />
                                                </div>
                                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                    <ChipBoxLocation />
                                                </div>
                                            </div>
                                            <div className=''>
                                                <h4 className='mt-3'>Choose Interest</h4>
                                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                    <ChipBox />
                                                </div>
                                            </div>
                                            <div className='p-2' >
                                                <div className='d-flex jsutify-content-around align-items-center w-100' >
                                                    <p>Live Videos</p>
                                                    <div className='ml-auto' ><Checkbox /></div>
                                                </div>
                                                <div className='d-flex jsutify-content-around align-items-center w-100' >
                                                    <p>People Post</p>
                                                    <div className='ml-auto' ><Checkbox /></div>
                                                </div>
                                                <div className='d-flex jsutify-content-around align-items-center w-100' >
                                                    <p>Pages Post</p>
                                                    <div className='ml-auto' ><Checkbox /></div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-center mt-3 '>
                                                <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                            </div>
                                        </div> :
                                        props.data === '2' ?
                                            <div>
                                                <div className='d-flex justify-content-between'>
                                                    <h4>Withim (Km)</h4>
                                                    <h4>{temperature}km</h4>
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <div className='w-100'>
                                                        {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                        {/* ==========================change km value=============== */}
                                                        <div>
                                                            <input
                                                                type="range"
                                                                min="0"
                                                                max="8821"
                                                                value={temperature}
                                                                onChange={handleTemperatureChange}
                                                                style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}
                                                            />

                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                        </div>
                                                        {/* ==========================change km value end =============== */}
                                                        {/* dsadFDFF */}
                                                        {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                        {/* </div> */}
                                                        {/* SDASDFFF */}
                                                    </div>
                                                    <div className='d-flex align-items-center ml-3' role='button'>
                                                        <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} />
                                                        <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                    </div>
                                                </div>
                                                <div className=' mt-2'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <h4 className=''>Choose locations</h4>
                                                        <CountryFilters />
                                                    </div>
                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                        <ChipBoxLocation />
                                                    </div>
                                                </div>
                                                {/* <div className=''>
                                                    <h4 className='mt-3'>Choose Interest</h4>
                                                    <ChipBox />
                                                </div> */}
                                                <div className='d-flex justify-content-center mt-3 '>
                                                    <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                    <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                </div>
                                            </div> :
                                            props.data === '3' ?
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <h4>Withim (Km)</h4>
                                                        <h4>{temperature}km</h4>
                                                    </div>
                                                    <div className='d-flex justify-content-between mt-2'>
                                                        <div className='w-100'>
                                                            {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                            {/* ==========================change km value=============== */}
                                                            <div>
                                                                <input
                                                                    type="range"
                                                                    min="0"
                                                                    max="8821"
                                                                    value={temperature}
                                                                    onChange={handleTemperatureChange}
                                                                    style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                />

                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                            </div>
                                                            {/* ==========================change km value end =============== */}
                                                            {/* dsadFDFF */}
                                                            {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                            {/* </div> */}
                                                            {/* SDASDFFF */}
                                                        </div>
                                                        <div className='d-flex align-items-center ml-3' role='button'>
                                                            <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                        </div>
                                                    </div>
                                                    <div className=' mt-2'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h4 className=''>Choose locations</h4>
                                                            <CountryFilters />
                                                        </div>
                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                            <ChipBoxLocation />
                                                        </div>
                                                    </div>
                                                    <div className=''>
                                                        <h4 className='mt-3'>Choose Interest</h4>
                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                            <ChipBox />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center mt-3 '>
                                                        <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                        <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                    </div>
                                                </div> :
                                                props.data === '5' ?
                                                    <div>
                                                        <div className='d-flex justify-content-between'>
                                                            <h4>Withim (Km)</h4>
                                                            <h4>{temperature}km</h4>
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <div className='w-100'>
                                                                {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                {/* ==========================change km value=============== */}
                                                                <div>
                                                                    <input
                                                                        type="range"
                                                                        min="0"
                                                                        max="8821"
                                                                        value={temperature}
                                                                        onChange={handleTemperatureChange}
                                                                        style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                    />

                                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                </div>
                                                                {/* ==========================change km value end =============== */}
                                                                {/* dsadFDFF */}
                                                                {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                {/* </div> */}
                                                                {/* SDASDFFF */}
                                                            </div>
                                                            <div className='d-flex align-items-center ml-3' role='button'>
                                                                <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                            </div>
                                                        </div>
                                                        <div className=' mt-2'>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h4 className=''>Choose locations</h4>
                                                                <CountryFilters />
                                                            </div>
                                                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                <ChipBoxLocation />
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <h4 className='mt-2'>Choose Interest</h4>
                                                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                <ChipBox />
                                                            </div>
                                                        </div>

                                                        <div className='d-flex justify-content-center mt-3 '>
                                                            <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                            <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                        </div>
                                                    </div> :
                                                    props.data === '6' ?
                                                        <div>
                                                            <div className='d-flex justify-content-between'>
                                                                <h4>Withim (Km)</h4>
                                                                <h4>{temperature}km</h4>
                                                            </div>
                                                            <div className='d-flex justify-content-between mt-2'>
                                                                <div className='w-100'>
                                                                    {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                    {/* ==========================change km value=============== */}
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            min="0"
                                                                            max="8821"
                                                                            value={temperature}
                                                                            onChange={handleTemperatureChange}
                                                                            style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                        />

                                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                    </div>
                                                                    {/* ==========================change km value end =============== */}
                                                                    {/* dsadFDFF */}
                                                                    {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                    {/* </div> */}
                                                                    {/* SDASDFFF */}
                                                                </div>
                                                                <div className='d-flex align-items-center ml-3' role='button'>
                                                                    <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                </div>
                                                            </div>
                                                            <div className=' mt-2'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <h4 className=''>Choose locations</h4>
                                                                    <CountryFilters />
                                                                </div>
                                                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                    <ChipBoxLocation />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-between border-top ChooseThisTrendingSong mt-3'>
                                                                <h4 className=''>Choose trending song</h4>
                                                                <ChevronRight color='#333333' />
                                                            </div>
                                                            <div className='d-flex justify-content-center mt-3 '>
                                                                <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                            </div>
                                                        </div> :
                                                        props.data === '7' ?
                                                            <div>
                                                                <div className='d-flex justify-content-between'>
                                                                    <h4>Withim (Km)</h4>
                                                                    <h4>{temperature}km</h4>
                                                                </div>
                                                                <div className='d-flex justify-content-between mt-2'>
                                                                    <div className='w-100'>
                                                                        {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                        {/* ==========================change km value=============== */}
                                                                        <div>
                                                                            <input
                                                                                type="range"
                                                                                min="0"
                                                                                max="8821"
                                                                                value={temperature}
                                                                                onChange={handleTemperatureChange}
                                                                                style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                            />

                                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                        </div>
                                                                        {/* ==========================change km value end =============== */}
                                                                        {/* dsadFDFF */}
                                                                        {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                        {/* </div> */}
                                                                        {/* SDASDFFF */}
                                                                    </div>
                                                                    <div className='d-flex align-items-center ml-3' role='button'>
                                                                        <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                    </div>
                                                                </div>
                                                                <div className=' mt-2'>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <h4 className=''>Choose locations</h4>
                                                                        <CountryFilters />
                                                                    </div>
                                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                        <ChipBoxLocation />
                                                                    </div>
                                                                </div>
                                                                <div className=''>
                                                                    <h4 className='mt-3'>Choose Category</h4>
                                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                        <ChipBox />
                                                                    </div>
                                                                </div>
                                                                {/* <h4 className='mt-2'>Event date</h4>
                                                                <div className='d-flex mt-2'>
                                                                    <input class="form-control" type="date" />
                                                                    <p className='p-2'>to</p>
                                                                    <input class="form-control" type="date" />
                                                                </div> */}
                                                                {/* <form className='justify-content-center'>
                                                                <h4 className='d-flex'>
                                                                    Event date
                                                                </h4>
                                                                <div class="d-flex mt-1">
                                                                    <div class="form-group">
                                                                        <input class="form-control" type="date" />
                                                                    </div>
                                                                    <div className=''>to</div>
                                                                    <div class="form-group">
                                                                        <input class="form-control" type="date" />
                                                                    </div>
                                                                </div>
                                                            </form> */}
                                                                <div className='d-flex justify-content-center mt-3 '>
                                                                    <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                    <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                </div>
                                                            </div> :
                                                            props.data === '8' ?
                                                                <div>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <h4>Withim (Km)</h4>
                                                                        <h4>{temperature}km</h4>
                                                                    </div>
                                                                    <div className='d-flex justify-content-between mt-2'>
                                                                        <div className='w-100'>
                                                                            {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                            {/* ==========================change km value=============== */}
                                                                            <div>
                                                                                <input
                                                                                    type="range"
                                                                                    min="0"
                                                                                    max="8821"
                                                                                    value={temperature}
                                                                                    onChange={handleTemperatureChange}
                                                                                    style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                />

                                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                            </div>
                                                                            {/* ==========================change km value end =============== */}
                                                                            {/* dsadFDFF */}
                                                                            {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                            {/* </div> */}
                                                                            {/* SDASDFFF */}
                                                                        </div>
                                                                        <div className='d-flex align-items-center ml-3' role='button'>
                                                                            <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                        </div>
                                                                    </div>
                                                                    <div className=' mt-2'>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <h4 className=''>Choose locations</h4>
                                                                            <CountryFilters />
                                                                        </div>
                                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                            <ChipBoxLocation />
                                                                        </div>
                                                                    </div>
                                                                    <div className=''>
                                                                        <h4 className='mt-3'>Choose Interest</h4>
                                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                            <ChipBox />
                                                                        </div>
                                                                    </div>
                                                                    {/* <h4 className='mt-3'>Choose Podcast Type</h4>
                                                                    <div className='d-flex justify-content-between p-2'>
                                                                        <p id='1'>Single Podcast</p>
                                                                        <input type="checkbox" />
                                                                    </div>
                                                                    <div className='d-flex justify-content-between p-2'>
                                                                        <p id='1'>Series Podcast </p>
                                                                        <input type="checkbox" />
                                                                    </div>
                                                                    <div className='d-flex justify-content-between p-2'>
                                                                        <p id='1'>Live Podcast</p>
                                                                        <input type="checkbox" />
                                                                    </div>
                                                                    <div className='d-flex justify-content-between p-2'>
                                                                        <p id='1'>Paid Podcast</p>
                                                                        <input type="checkbox" />
                                                                    </div> */}
                                                                    <div className='d-flex justify-content-center mt-3 '>
                                                                        <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                        <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                    </div>
                                                                </div> :
                                                                props.data === '9' ?
                                                                    <div>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <h4>Withim (Km)</h4>
                                                                            <h4>{temperature}km</h4>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between mt-2'>
                                                                            <div className='w-100'>
                                                                                {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                {/* ==========================change km value=============== */}
                                                                                <div>
                                                                                    <input
                                                                                        type="range"
                                                                                        min="0"
                                                                                        max="8821"
                                                                                        value={temperature}
                                                                                        onChange={handleTemperatureChange}
                                                                                        style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                    />

                                                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                </div>
                                                                                {/* ==========================change km value end =============== */}
                                                                                {/* dsadFDFF */}
                                                                                {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                {/* </div> */}
                                                                                {/* SDASDFFF */}
                                                                            </div>
                                                                            <div className='d-flex align-items-center ml-3' role='button'>
                                                                                <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                            </div>
                                                                        </div>
                                                                        <div className=' mt-2'>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <h4 className=''>Choose locations</h4>
                                                                                <CountryFilters />
                                                                            </div>
                                                                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                <ChipBoxLocation />
                                                                            </div>
                                                                        </div>
                                                                        {/* <h4 className='mt-3'>Choose Category</h4>
                                                                        <ChipBox /> */}
                                                                        <div className='d-flex justify-content-center mt-3 '>
                                                                            <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                            <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                        </div>
                                                                    </div> :
                                                                    props.data === '10' ?
                                                                        <div>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <h4>Withim (Km)</h4>
                                                                                <h4>{temperature}km</h4>
                                                                            </div>
                                                                            <div className='d-flex justify-content-between mt-2'>
                                                                                <div className='w-100'>
                                                                                    {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                    {/* ==========================change km value=============== */}
                                                                                    <div>
                                                                                        <input
                                                                                            type="range"
                                                                                            min="0"
                                                                                            max="8821"
                                                                                            value={temperature}
                                                                                            onChange={handleTemperatureChange}
                                                                                            style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                        />

                                                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                    </div>
                                                                                    {/* ==========================change km value end =============== */}
                                                                                    {/* dsadFDFF */}
                                                                                    {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                    {/* </div> */}
                                                                                    {/* SDASDFFF */}
                                                                                </div>
                                                                                <div className='d-flex align-items-center ml-3' role='button'>
                                                                                    <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                </div>
                                                                            </div>
                                                                            <div className=' mt-2'>
                                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                                    <h4 className=''>Choose locations</h4>
                                                                                    <CountryFilters />
                                                                                </div>
                                                                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                    <ChipBoxLocation />
                                                                                </div>
                                                                            </div>

                                                                            <h4 className='mt-3'>Choose Category</h4>
                                                                            <ChipBox />
                                                                            <div className='mt-4'>
                                                                                <h4>Choose Rating</h4>
                                                                                <Rating name="read-only" className='RatingThisWidth' value={3} readOnly />
                                                                                {/* <div className='d-flex justify-content-between mt-2'>
                                                                                    <div className='w-100'>
                                                                                        <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                                                                            <MatSlider
                                                                                                aria-label="Volume"
                                                                                                defaultValue={100}
                                                                                                sx={{
                                                                                                    color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                                                    '& .MuiSlider-track': {
                                                                                                        border: 'none',
                                                                                                    },
                                                                                                    '& .MuiSlider-thumb': {
                                                                                                        width: 20,
                                                                                                        height: 20,
                                                                                                        backgroundColor: '#81C14B',
                                                                                                        '&:before': {
                                                                                                            boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                                                        },
                                                                                                        '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                                                            boxShadow: 'none',
                                                                                                        },
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Stack>
                                                                                    </div>
                                                                                    <div className='d-flex align-items-center ml-3'>
                                                                                        <MinusSquare color='#81C14B' /> <PlusSquare color='#81C14B' />
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                            {/* <hr /> */}
                                                                            <div className='d-flex justify-content-center mt-3 '>
                                                                                <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                            </div>
                                                                        </div> :
                                                                        props.data === '11' ?
                                                                            <div>
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <h4>Withim (Km)</h4>
                                                                                    <h4>{temperature}km</h4>
                                                                                </div>
                                                                                <div className='d-flex justify-content-between mt-2'>
                                                                                    <div className='w-100'>
                                                                                        {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                        {/* ==========================change km value=============== */}
                                                                                        <div>
                                                                                            <input
                                                                                                type="range"
                                                                                                min="0"
                                                                                                max="8821"
                                                                                                value={temperature}
                                                                                                onChange={handleTemperatureChange}
                                                                                                style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                            />

                                                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                        </div>
                                                                                        {/* ==========================change km value end =============== */}
                                                                                        {/* dsadFDFF */}
                                                                                        {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                        {/* </div> */}
                                                                                        {/* SDASDFFF */}
                                                                                    </div>
                                                                                    <div className='d-flex align-items-center ml-3' role='button'>
                                                                                        <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='mt-2'>
                                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                                        <h4 className=''>Choose locations</h4>
                                                                                        <CountryFilters />
                                                                                    </div>
                                                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                        <ChipBoxLocation />
                                                                                    </div>
                                                                                </div>
                                                                                <div className=''>
                                                                                    <h4 className='mt-3'>Choose Category</h4>
                                                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                        <ChipBox />
                                                                                    </div>
                                                                                </div>
                                                                                <form className='justify-content-center my-3'>
                                                                                    <h4 className='d-flex my-3'>
                                                                                        Event date
                                                                                    </h4>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="">
                                                                                            <input className="form-control" type="date" />
                                                                                        </div>
                                                                                        <div className='px-2 align-self-center '>to</div>
                                                                                        <div className="">
                                                                                            <input className="form-control" type="date" />
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                                <div className='d-flex justify-content-center mt-3 '>
                                                                                    <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                    <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                                </div>
                                                                            </div> :
                                                                            props.data === '12' ?
                                                                                <div>
                                                                                    <div className='d-flex justify-content-between'>
                                                                                        <h4>Withim (Km)</h4>
                                                                                        <h4>{temperature}km</h4>
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-between mt-2'>
                                                                                        <div className='w-100'>
                                                                                            {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                            {/* ==========================change km value=============== */}
                                                                                            <div>
                                                                                                <input
                                                                                                    type="range"
                                                                                                    min="0"
                                                                                                    max="8821"
                                                                                                    value={temperature}
                                                                                                    onChange={handleTemperatureChange}
                                                                                                    style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                                />

                                                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                            </div>
                                                                                            {/* ==========================change km value end =============== */}
                                                                                            {/* dsadFDFF */}
                                                                                            {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                            {/* </div> */}
                                                                                            {/* SDASDFFF */}
                                                                                        </div>
                                                                                        <div className='d-flex align-items-center ml-3' role='button'>
                                                                                            <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className=' mt-2'>
                                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                                            <h4 className=''>Choose locations</h4>
                                                                                            <CountryFilters />
                                                                                        </div>
                                                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                            <ChipBoxLocation />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        <h4 className='mt-3'>Choose Category</h4>
                                                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                            <ChipBox />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='' >
                                                                                        <h4 className='mt-3 mb-1' >Choose Podcast type</h4>
                                                                                        <div className='px-2' >
                                                                                            <div className='d-flex justify-content-between align-items-center' >
                                                                                                <div>Live Podcast</div>
                                                                                                <div> <Checkbox /> </div>
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                                <div>Series Podcast</div>
                                                                                                <div> <Checkbox /> </div>
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                                <div>Single Podcast</div>
                                                                                                <div> <Checkbox /> </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='d-flex justify-content-center mt-3 '>
                                                                                        <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                        <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                                    </div>
                                                                                </div> :
                                                                                props.data === '13' ?
                                                                                    <div>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <h4>Withim (Km)</h4>
                                                                                            <h4>{temperature}km</h4>
                                                                                        </div>
                                                                                        <div className='d-flex justify-content-between mt-2'>
                                                                                            <div className='w-100'>
                                                                                                {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                                {/* ==========================change km value=============== */}
                                                                                                <div>
                                                                                                    <input
                                                                                                        type="range"
                                                                                                        min="0"
                                                                                                        max="8821"
                                                                                                        value={temperature}
                                                                                                        onChange={handleTemperatureChange}
                                                                                                        style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                                    />

                                                                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                                </div>
                                                                                                {/* ==========================change km value end =============== */}
                                                                                                {/* dsadFDFF */}
                                                                                                {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                                {/* </div> */}
                                                                                                {/* SDASDFFF */}
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center ml-3' role='button'>
                                                                                                <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className=' mt-2'>
                                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                                <h4 className=''>Choose locations</h4>
                                                                                                <CountryFilters />
                                                                                            </div>
                                                                                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                <ChipBoxLocation />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <h4 className='mt-3'>Choose Category</h4>
                                                                                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                <ChipBox />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <h4 className='mt-2'>Choose Poll Type</h4>
                                                                                        <div className='d-flex justify-content-between p-2'>
                                                                                            <p id='1'>Completed Polls</p>
                                                                                            <input type="checkbox" />
                                                                                        </div>
                                                                                        <div className='d-flex justify-content-between p-2'>
                                                                                            <p id='1'>Running Polls</p>
                                                                                            <input type="checkbox" />
                                                                                        </div> */}
                                                                                        <div className='d-flex justify-content-center mt-3 '>
                                                                                            <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                            <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                                        </div>
                                                                                    </div> :
                                                                                    props.data === '15' ?
                                                                                        <div>
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <h4>Withim (Km)</h4>
                                                                                                <h4>{temperature}km</h4>
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-between mt-2'>
                                                                                                <div className='w-100'>
                                                                                                    {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                                    {/* ==========================change km value=============== */}
                                                                                                    <div>
                                                                                                        <input
                                                                                                            type="range"
                                                                                                            min="0"
                                                                                                            max="8821"
                                                                                                            value={temperature}
                                                                                                            onChange={handleTemperatureChange}
                                                                                                            style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                                        />

                                                                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                                    </div>
                                                                                                    {/* ==========================change km value end =============== */}
                                                                                                    {/* dsadFDFF */}
                                                                                                    {/* <div className="App">
                                                        <input
                                                            type="range"
                                                            min="-100"
                                                            max="100"
                                                            value={temperature}
                                                            onChange={handleTemperatureChange}
                                                            color='green'
                                                        /> */}

                                                                                                    {/* <button onClick={increaseTemperature}>+</button>
                                                        <button onClick={decreaseTemperature}>-</button> */}
                                                                                                    {/* </div> */}
                                                                                                    {/* SDASDFFF */}
                                                                                                </div>
                                                                                                <div className='d-flex align-items-center ml-3' role='button'>
                                                                                                    <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='mt-2'>
                                                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                                                    <h4 className=''>Choose locations</h4>
                                                                                                    <CountryFilters />
                                                                                                </div>
                                                                                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                    <ChipBoxLocation />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <h4 className='mt-3' >Choose Poll type</h4>
                                                                                                <div className='px-2' >
                                                                                                    <div className='d-flex align-items-center justify-content-between' >
                                                                                                        <div>Completed Polls</div>
                                                                                                        <div> <Checkbox /> </div>
                                                                                                    </div>
                                                                                                    <div className='d-flex align-items-center justify-content-between' >
                                                                                                        <div>Running Polls</div>
                                                                                                        <div> <Checkbox /> </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-center mt-3 '>
                                                                                                <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                                <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                                            </div>
                                                                                        </div> :
                                                                                        props.data === '16' ?
                                                                                            <div>
                                                                                                <div className='d-flex justify-content-between'>
                                                                                                    <h4>Withim (Km)</h4>
                                                                                                    <h4>{temperature}km</h4>
                                                                                                </div>
                                                                                                <div className='d-flex justify-content-between mt-2'>
                                                                                                    <div className='w-100'>
                                                                                                        {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                        <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                    },
                                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </Stack> */}
                                                                                                        {/* ==========================change km value=============== */}
                                                                                                        <div>
                                                                                                            <input
                                                                                                                type="range"
                                                                                                                min="0"
                                                                                                                max="8821"
                                                                                                                value={temperature}
                                                                                                                onChange={handleTemperatureChange}
                                                                                                                style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                                            />

                                                                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                                                                    <button onClick={decreaseTemperature}>-</button> */}
                                                                                                        </div>
                                                                                                        {/* ==========================change km value end =============== */}
                                                                                                        {/* dsadFDFF */}
                                                                                                        {/* <div className="App">
                                                                                                      <input
                                                                                                        type="range"
                                                                                                      min="-100"
                                                                                                      max="100"
                                                                                                     value={temperature}
                                                                                                    onChange={handleTemperatureChange}
                                                                                                                    color='green'
                                                                                                                  /> */}

                                                                                                        {/* <button onClick={increaseTemperature}>+</button>
                                                                                               <button onClick={decreaseTemperature}>-</button> */}
                                                                                                        {/* </div> */}
                                                                                                        {/* SDASDFFF */}
                                                                                                    </div>
                                                                                                    <div className='d-flex align-items-center ml-3' role='button'>
                                                                                                        <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='mt-2'>
                                                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                                                        <h4 className=''>Choose locations</h4>
                                                                                                        <CountryFilters />
                                                                                                    </div>
                                                                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                        <ChipBoxLocation />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div>
                                                                                                    <h4 className='mt-3'>Choose Interest</h4>
                                                                                                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                        <ChipBox />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='d-flex justify-content-center mt-3 '>
                                                                                                    <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                                    <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                                                </div>
                                                                                            </div> :
                                                                                            props.data === '17' ?
                                                                                                <div>
                                                                                                    <div className='d-flex justify-content-between'>
                                                                                                        <h4>Withim (Km)</h4>
                                                                                                        <h4>{temperature}km</h4>
                                                                                                    </div>
                                                                                                    <div className='d-flex justify-content-between mt-2'>
                                                                                                        <div className='w-100'>
                                                                                                            {/* <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center" >
                                                                                                          <MatSlider
                                                            aria-label="Volume"
                                                            defaultValue={100}
                                                            sx={{
                                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                                '& .MuiSlider-track': {
                                                                    border: 'none',
                                                                },
                                                                '& .MuiSlider-thumb': {
                                                                    width: 20,
                                                                    height: 20,
                                                                    backgroundColor: '#81C14B',
                                                                    '&:before': {
                                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                                                             },
                                                                                             '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                                             boxShadow: 'none',
                                                                                                         },
                                                                                                            },
                                                                                                         }}
                                                                                                           />
                                                                                                     </Stack> */}
                                                                                                            {/* ==========================change km value=============== */}
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    type="range"
                                                                                                                    min="0"
                                                                                                                    max="8821"
                                                                                                                    value={temperature}
                                                                                                                    onChange={handleTemperatureChange}
                                                                                                                    style={{ width: '100%', accentColor: '#81c14b', height: '5px', outline: 'none', border: 'none' }}

                                                                                                                />

                                                                                                                {/* <button onClick={increaseTemperature}>+</button>
                                                                                                             <button onClick={decreaseTemperature}>-</button> */}
                                                                                                            </div>
                                                                                                            {/* ==========================change km value end =============== */}
                                                                                                            {/* dsadFDFF */}
                                                                                                            {/* <div className="App">
                                                                                                        <input
                                                                                                      type="range"
                                                                                                   min="-100"
                                                                                                     max="100"
                                                                                                     value={temperature}
                                                                                                       onChange={handleTemperatureChange}
                                                                                                        color='green'
                                                                                                           /> */}

                                                                                                            {/* <button onClick={increaseTemperature}>+</button>
                                                                                                         <button onClick={decreaseTemperature}>-</button> */}
                                                                                                            {/* </div> */}
                                                                                                            {/* SDASDFFF */}
                                                                                                        </div>
                                                                                                        <div className='d-flex align-items-center ml-3' role='button'>
                                                                                                            <MinusSquare color='#81C14B' role='button' onClick={decreaseTemperature} /> <PlusSquare color='#81C14B' role='button' onClick={increaseTemperature} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='mt-2'>
                                                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                                                            <h4 className=''>Choose locations</h4>
                                                                                                            <CountryFilters />
                                                                                                        </div>
                                                                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                            <ChipBoxLocation />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <h4 className='mt-3'>Choose Interest</h4>
                                                                                                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                                            <ChipBox />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='d-flex justify-content-center mt-3'>
                                                                                                        <Button color='primary' className='w-50' outline>CLEAR ALL</Button>
                                                                                                        <Button color='primary' className='APPLYThisWidth ml-2 w-50'>APPLY</Button>
                                                                                                    </div>
                                                                                                </div> : null
                                }
                            </ModalBody>
                        </Modal>
                    </Col>
                </Row>
            </>
        )
    }
    // -----------------------------------------------Trending Countries and Filter End Here-------------------------------------------------------------
    const [active, setActive] = useState('1')

    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    const [name, setName] = useState('Media')
    const handleClick = (newName) => {
        setName(newName)
    }
    // ============= Trending text ===============
    const PodcastNavData = [
        {
            id: "1",
            NavName: "Media"
        },
        {
            id: "2",
            NavName: "Text"

        },
        {
            id: "3",
            NavName: "Peoples"
        },
        {
            id: "5",
            NavName: "#Tags"
        },
        {
            id: "6",
            NavName: "Shotz"
        },
        {
            id: "7",
            NavName: " Groups"
        },
        {
            id: "8",
            NavName: "BizPage"
        },
        {
            id: "9",
            NavName: "Thought"
        },
        {
            id: "10",
            NavName: "Recommendation"
        },
        {
            id: "11",
            NavName: "Events"
        },
        {
            id: "12",
            NavName: "Podcast"
        },
        {
            id: "13",
            NavName: "Golive"
        },
        {
            id: "15",
            NavName: "Poll"
        },
        {
            id: "16",
            NavName: "Article"
        },
        {
            id: "17",
            NavName: "Audio"
        }

    ]
    // ============= Trending text ===============
    // -----------------------------------------------Main return function Start Here-------------------------------------------------------------
    return (
        <div>
            <MediaPreviewModal />
            {/* ------------------------Header section start here-------------------------------- */}
            <Header />
            <div className="page-body container-fluid profile-page">
                {/* ------------------------Left Sidebar start here-------------------------------- */}
                <LeftSidebar />
                <div className="page-center">
                    <Card className='border-0 p-3'>
                        {/* ---------------------------------- Trending Countries start here------------------------ */}
                        <Trendingcountries data={active} />
                        <React.Fragment >
                            <div>
                                <Card className='shadow p-3 bg-white mb-3 mt-3 rounded border-0'>
                                    {/* -----------------------------------------------Silder Part Start Here------------------------------------------------------------- */}
                                    {/* <Slider {...ownedGroupSettings} className="default-space new-width">
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '1'}
                                                        onClick={() => {
                                                            toggle('1')
                                                            handleClick(' Media')
                                                        }}
                                                    >
                                                        Media
                                                      
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '2'}
                                                        onClick={() => {
                                                            toggle('2')
                                                            handleClick('Text')
                                                        }}
                                                    >
                                                     
                                                        Text
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>

                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '3'}
                                                        onClick={() => {
                                                            toggle('3')
                                                            handleClick('Peoples')
                                                        }}
                                                    >
                                                    
                                                        Peoples
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                     
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '5'}
                                                        onClick={() => {
                                                            toggle('5')
                                                            handleClick('#Tags')
                                                        }}
                                                    >
                                                      
                                                        #Tags
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '6'}
                                                        onClick={() => {
                                                            toggle('6')
                                                            handleClick('Shotz')
                                                        }}
                                                    >
                                                        Shotz
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '7'}
                                                        onClick={() => {
                                                            toggle('7')
                                                            handleClick('Groups')
                                                        }}
                                                    >
                                                     
                                                        Groups
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '8'}
                                                        onClick={() => {
                                                            toggle('8')
                                                            handleClick('Bizpage')
                                                        }}
                                                    >
                                                        Bizpage
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '9'}
                                                        onClick={() => {
                                                            toggle('9')
                                                            handleClick('Thought')
                                                        }}
                                                    >
                                                       
                                                        Thought
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '10'}
                                                        onClick={() => {
                                                            toggle('10')
                                                            handleClick('Recommendation')
                                                        }}
                                                    >
                                                        
                                                        Recommendation
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '11'}
                                                        onClick={() => {
                                                            toggle('11')
                                                            handleClick('Events')
                                                        }}
                                                    >
                                                     
                                                        Events
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className=''
                                                        active={active === '12'}
                                                        onClick={() => {
                                                            toggle('12')
                                                            handleClick('Podcast')
                                                        }}
                                                    >
                                                      
                                                        Podcast
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className='NavLink'
                                                        active={active === '13'}
                                                        onClick={() => {
                                                            toggle('13')
                                                            handleClick('Golive')
                                                        }}
                                                    >
                                                    
                                                        Golive
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>

                                    
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className='NavLink'
                                                        active={active === '15'}
                                                        onClick={() => {
                                                            toggle('15')
                                                            handleClick('Poll')
                                                        }}
                                                    >
                                                     
                                                        Poll
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className='NavLink'
                                                        active={active === '16'}
                                                        onClick={() => {
                                                            toggle('16')
                                                            handleClick('Articles')
                                                        }}
                                                    >
                                                        Articles
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <div>
                                            <Nav pills>
                                                <NavItem className='Search_NavItem w-100 text-center'>
                                                    <NavLink className='NavLink'
                                                        active={active === '17'}
                                                        onClick={() => {
                                                            toggle('17')
                                                            handleClick('Audio')
                                                        }}
                                                    >
                                                        Audio
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </Slider> */}
                                    {/* ========================== Nav Text Trending ========================= */}
                                    <TrendingNavList className={active === '1' ? 'active' : ''} toggleactive={toggle} PodcastNavData={PodcastNavData} setName={setName} />
                                    {/* ========================== Nav Text Trending ========================= */}
                                    {/* ======================== */}
                                    {/* -----------------------------------------------Silder Part End Here------------------------------------------------------------- */}
                                </Card>

                            </div>
                            <div className=''>
                                {/* -----------------------------------------------TabContent Part Start Here------------------------------------------------------------- */}
                                <TabContent className='py-50' activeTab={active}>
                                    {/* ------------------------------------Media tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='1'>
                                        <VideosTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Text tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='2'>
                                        <TextTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Peoples tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='3'>
                                        <PeopleTabsTrendind />
                                    </TabPane>
                                    {/* <TabPane tabId='4'>
                                        <ImagesTabsTrending />
                                    </TabPane> */}
                                    {/* ------------------------------------HashTags tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='5'>
                                        <TagsTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Shotzs tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='6'>
                                        <ShotzsTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Groups tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='7'>
                                        <GroupTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------BizPage tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='8'>
                                        <BizPageTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Thoughts tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='9'>
                                        <ThoughtTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Recommendation tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='10'>
                                        <RecommendationTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Events tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='11'>
                                        <EventTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Podcast tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='12'>
                                        <PodCastTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Golive tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='13'>
                                        <GoLiveTabsTrending />
                                    </TabPane>
                                    {/* <TabPane tabId='14'>
                                        <ThreatTabsTrending />
                                    </TabPane> */}
                                    {/* ------------------------------------Polls tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='15'>
                                        <PollTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Article tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='16'>
                                        <AriticleTabsTrending />
                                    </TabPane>
                                    {/* ------------------------------------Audios tabs start Here--------------------------------------------- */}
                                    <TabPane tabId='17'>
                                        <AudioTabsTrending />
                                    </TabPane>

                                </TabContent>
                                {/* -----------------------------------------------TabContent Part End Here------------------------------------------------------------- */}

                            </div>

                        </React.Fragment>
                    </Card>

                </div>
                {/* ------------------------Right Sidebar start here-------------------------------- */}
                <RightSidebar />
            </div>

        </div >
    )

}

export default Trending