import React, { Fragment } from 'react'
import { Button } from 'reactstrap'

const AdFooter = () => {

    //===================== Main_return_function =====================//
    return (
        <Fragment>
            <div className='Ad_Footer_Section'>
                <div className="container-fluid custom-padding">
                    <div className='Ad_Footer_InnerBlk'>
                        <div className='Footer_content'>
                            <h3>Connect freely with your friends</h3>
                            <p>Join the new way of connecting with your friends and become sociomate.</p>
                        </div>
                        <div className='d-flex'>
                            <Button color='primary' outline className='mr-3 Footer_LoginButton_blk'>Log In</Button>
                            <span className='Footer_span'>Or</span>
                            <Button color='primary' className='ml-3 Footer_SgnpButton_blk'>Sign Up</Button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default AdFooter
