import * as types from "../../Constants/UserProfile/index";

const initialState = {
  allPoll: {},
  loading: true,
};

export const getAllPollReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALLPOLL_SUCCESS:
      return {
        ...state,
        allpolls: action.payload,
        loading: false,
      };
    case types.GET_ALLPOLL_ERROR:
        return {
            ...state,
            pollError: action.payload,
            loading: false,
          };
    default:
      return state;
  }
};

