import React from 'react'
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
// import Pollimg from '../../../NewComponents/IMG/Pollimg.png'
import { AlertTriangle, Eye, Link, MoreHorizontal, Share2 } from 'react-feather'
import CommentImg from '../../../NewComponents/IMG/Comment.svg'
import ShareImg from '../../../NewComponents/IMG/Share.svg'
import LikeBlack from '../../profile-components/Img//likesvg.svg'
// import tagpic2 from '../../../NewComponents/IMG/tagpic2.png'
import { useState } from 'react'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ActiveCirclelike from '../../../NewComponents/IMG/ActiveCirclelike.svg'
// import Circlelike from '../../../NewComponents/IMG/Circlelike.svg'
import ShareToModal from '../ShareToModal'
import { cardDataTrendingRecommendation } from '../DummyArray'
import ReportModal from './ModalGrid/ReportModal'
import { NavLink } from 'react-router-dom'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import '../Trending.css'
const GridRecommendation = () => {
    const [liked, setLiked] = useState(true);
    const handleLike = () => {
        setLiked(!liked);
    }
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    {/* -------------------------------------------------------Main return function------------------------------------------------------------- */ }
    return (
        <div className='mt-2 p-2'>
            <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
            {/* <Row className=''> */}

            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 667: 2, 1157: 3 }}
            >
                <Masonry columnsCount={3} gutter="12px" >
                    {/* -------------------------------------------------------Recommendation card data Dynamic rendering------------------------------------------------------------- */}
                    {cardDataTrendingRecommendation.map((data, index) => (

                        // <Col xl='4' lg='6' md='12' className='p-1 d-flex flex-column' key={index}  >
                        <div className='Video_Treanding_card p-2 d-flex flex-column on-hover-bg'>
                            <div className='d-flex pb-2'  >
                                <NavLink to='/MyProfile'> <Badge color='primary' pill className=' px-2 d-flex align-items-center light-font'>
                                Trending #{data.trendingNumber}
                                </Badge></NavLink>
                                <NavLink to='/MyProfile'>
                                    <Badge color='light-primary' className='px-2 ml-2 d-flex align-items-center light-font' >
                                    Top #{data.topsNumber}
                                    </Badge>
                                </NavLink>
                            </div>
                            <div className='d-flex flex-wrap justify-content-between'>
                                <div className='d-flex align-items-center Recom'>
                                    <img
                                        src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
                                        alt='pollimg'
                                        height={50}
                                        width={50}
                                        className='rounded-circle'
                                    />
                                    <div className='ml-2'>
                                        <div className='d-flex align-items-start'>
                                            <NavLink to='/MyProfile' style={{ color: 'black' }}>
                                                <h4 className='user-profile'>{data.name}</h4>
                                            </NavLink>&nbsp;
                                            <p className='d-none  d-lg-block  d-flex align-items-center ml-1 ' style={{ fontSize: '13px' }}>asking for recommendation</p>
                                            {/* <small className=''></small> */}
                                        </div>
                                        <small className='text-muted light-for-date'>{data.dateandtime}</small>
                                    </div>
                                </div>
                                <UncontrolledButtonDropdown className='align-self-center morehorizontal' style={{ marginTop: '-26px' }}>
                                    <DropdownToggle color='flat m-0 p-0 '>
                                        <MoreHorizontal color='#999999' size={19} />
                                    </DropdownToggle>
                                    <DropdownMenu className='bizpagefordropdownmenu dropdownLocation dropdown-for-user-specific'>
                                        <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;&nbsp;Share Recommend</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' > <NavLink to='/MyProfile' style={{ color: '#212529' }} ><Eye size={13} />&nbsp;&nbsp;View Profile</NavLink></DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;&nbsp;Copy link</DropdownItem>
                                        <DropdownItem divider></DropdownItem>
                                        <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;&nbsp;Report</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>

                            {(index % 2 === 0) ? (
                                <>
                                    <small className='mt-1'>I am seeking recommendation for awesome places in Netherlands for a vacation.</small>

                                    <div className='Potrate_treanding_img pt-2'>
                                        <img src={data.imgLink} alt='img' className='img-fluid' />
                                        <div className='TreandingRecommendations'>
                                            <small style={{marginLeft: '13px'}}>10 Recommendations</small>
                                            <Button className='text-white py-1 px-2 border-white d-block d-lg-inline' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px', marginRight:'14px' }}>
                                                Recommend
                                            </Button>
                                            {/* <Button className={`text-white py-1 px-2 border-white ${window.innerWidth === 820 ? 'd-none' : ''}`}>
                                                {window.innerWidth === 820 ? 'Recom...' : 'Recommend'}
                                            </Button> */}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <small className=''>I am seeking recommendation for awesome places in Netherlands for a vacation.</small>

                                    <div className='short-recommendation mt-2'>
                                        <small style={{marginLeft: '13px'}}>10 Recommendations</small>
                                        <Button className='text-white py-1 px-2 border-white d-block d-lg-inline' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px', marginRight:'14px' }}>
                                            Recommend
                                        </Button>
                                    </div>
                                    {/* Additional content for the second case */}
                                    {/* <div className='Potrate_treanding_img pt-2'>
                                    <small className='' ></small>
                                    <img src={data.imgLink} alt='img' className='img-fluid' />
                                    <div>{data.hashtags}</div>
                                    <div>{data.textdata}</div>
                                </div> */}
                                </>
                            )}

                            <div className='Treanding_bottom_section mt-2 d-flex justify-content-between p-1'>
                                <div className='d-flex align-items-center'>
                                    <div onClick={() => handleLike()}>
                                        {liked ? (
                                            <div className='Treanding_icon_section_forCpunt rounded-circle'> <img src={LikeBlack} width={19} height={19} alt='likeback' /> </div>
                                        ) : (
                                            <div className='Treanding_icon_liked rounded-circle'> <img src={LikeBlack} width={20} height={20} alt='likeback' /></div>
                                        )}
                                    </div>
                                    11.2k
                                </div>
                                <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                    </div>
                                    11.2k
                                </div>
                                <div className='d-flex align-items-center' onClick={toggleForward} style={{ position: 'relative' }} >
                                    <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                        <img src={ShareImg} width={16} height={16} className='' alt='share' style={{ position: 'absolute', left: '8px', top: '10px' }} />
                                    </div>
                                    11.2k
                                </div>
                            </div>
                        </div>


                        // </Col>
                    ))}
                </Masonry>
            </ResponsiveMasonry>

            {/* <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png' alt='img' height={210} />
                            <div className='TreandingRecommendations'>
                                <small>10 Recommendations</small>
                                <Button className='text-white'>Recommend</Button>
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>

                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col>
                <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png' alt='img' height={210} />
                            <div className='TreandingRecommendations'>
                                <small>10 Recommendations</small>
                                <Button className='text-white'>Recommend</Button>
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col>
                <Col lg='3' md='4' className='w-100 p-1'>
                    <div className='Video_Treanding_card'>
                        <div className='d-flex py-2'>
                            <Badge color='light-primary'>
                                Trending #1
                            </Badge>
                            <Badge color='primary' pill className='pl-3'>
                                Top #96
                            </Badge>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center '>
                                <img src='https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt='pollimg' height={50} width={50} className='rounded-circle' />
                                <div className='ml-2'>
                                    <h4>Floyd Miles </h4>
                                    <p className='text-muted'>19 sept at 07:00 pm</p>
                                </div>
                            </div>
                            <UncontrolledButtonDropdown className='align-self-center'>
                                <DropdownToggle className='p-0 m-0' color='flat' >
                                    <MoreHorizontal size={19} color='#000000' />
                                </DropdownToggle>
                                <DropdownMenu className='bizpagefordropdownmenu'>
                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                    <DropdownItem divider></DropdownItem>
                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className='Potrate_treanding_img pt-2'>
                            <img src='https://secretnyc.co/wp-content/uploads/2022/08/New-Project-16.png' alt='img' height={210} />
                            <div className='TreandingRecommendations'>
                                <small>10 Recommendations</small>
                                <Button className='text-white'>Recommend</Button>
                            </div>
                        </div>

                        <div className='Treanding_bottom_section'>
                            <div className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle' >
                                    {liked ?
                                        <img src={LikeBlack} width={20} height={20} alt='likeback' onClick={handleLike} />
                                        :
                                        <img src={ActiveCirclelike} width={35} height={35} alt='likeback' onClick={handleLike} />
                                    }
                                </div>
                                &nbsp;
                                11.2k
                            </div>
                            <div data-bs-toggle="modal" data-bs-target="#imageModel" className='d-flex align-items-center'>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={CommentImg} width={16} height={16} className='' alt='comment' />
                                </div>
                                11.2k
                            </div>
                            <div className='d-flex align-items-center' onClick={toggleForward}>
                                <div className='Treanding_icon_section_forCpunt rounded-circle'>
                                    <img src={ShareImg} width={16} height={16} className='' alt='share' />
                                </div>
                                11.2k
                            </div>

                        </div>

                    </div>
                </Col> */}

            {/* </Row> */}

            {/* -------------------------------------------------------Modal components start------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />

            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -------------------------------------------------------Modal components End------------------------------------------------------------- */}

        </div >
    )
}

export default GridRecommendation