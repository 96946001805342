import React, { useState } from 'react'
import { Card } from 'reactstrap'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap'
import Commontables from './Commontables'
import AdanalyticsCard from './AdanalyticsCard'
import TransectionsTable from './TransectionsTable'

const SummaryTab = () => {
    const [active, setActive] = useState('1')

    const toggle = tab => {
        setActive(tab)
    }
    return (
        <React.Fragment>
            <AdanalyticsCard currentbutton={'/Dashboard'} buttonrole={'Back'} />
            <Nav tabs className='w-100 nav-items' >
                <NavItem className='ml-4'>
                    <NavLink
                        className={active === '1' ? 'text-primary' : 'text-dark'}
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        All (3)
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink style={{marginLeft:'14px'}}
                        className={active === '2' ? 'text-primary' : 'text-dark'}
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Spent (3)
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={active === '3' ? 'text-primary' : 'text-dark'}
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Top up (0)
                    </NavLink>
                </NavItem>
            </Nav>
            <Card className='rounded-0 ' style={{ marginBottom: '0px', boxShadow: 'none' }}>
                <TabContent className='py-50' activeTab={active}>
                    <TabPane tabId='1'>
                        <TransectionsTable />
                    </TabPane>
                    <TabPane tabId='2'>
                        <TransectionsTable />
                    </TabPane>
                    <TabPane tabId='3'>
                        <TransectionsTable />
                    </TabPane>
                    
                </TabContent>
            </Card>
        </React.Fragment>
    )
}
export default SummaryTab