import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import Vector from '../../profile-components/Img/Vector.png'
import { Check, CheckCircle, ChevronDown, Edit, MapPin, MoreHorizontal, Share2, Star, Trash2, UserPlus, Users, X } from 'react-feather'
import { Link } from 'react-router-dom'
import { EventsDataArray } from './EventsDataArray'
import { useDispatch, useSelector } from 'react-redux'
import { getUserIntrestedEventsAction } from '../../../Services/Actions/Event/getAllEventPageAction'
import R from "../Img/R.png"
import R2 from "../Img/R2.png"
import InviteModal from '../../biz-components/AllTypesModal/InviteModal'
import ShareEventModal from '../../profile-components/EventsFolder/EventsModal/ShareEventModal'
import ReportModal from '../EventsModal/ReportModal'
import DeleteModal from '../EventsModal/DeleteModal'
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown } from 'reactstrap'
import { Image, Globe } from 'react-feather'
import DateTimePicker from 'react-datetime-picker'
import ShareToModal from '../../top-trending-components/ShareToModal'
const MyInterested = () => {
  let dispatch = useDispatch();

  const { userIntrests, loading } = useSelector(state => state.getUserIntrestedEventsData)


  useEffect(() => {
    const obj = {
      id: "abbf74ef-ea6d-46a3-89e7-953bd3c59549",
    };
    dispatch(getUserIntrestedEventsAction(obj));

  }, []);
  console.log('userIntrests***', userIntrests)
  //===================== Num_Formatter_Function
  const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return `${(num / 1000).toFixed(3).toLocaleString(undefined, {})} k`
    } else if (num > 999999) {
      return `${(num / 1000000).toFixed(3).toLocaleString(undefined, {})} M`
    } else if (num < 900) {
      return `${num}`
    }
  }
  const [shareOpen, setShareOpen] = useState(false)
  const toggleShare = () => {
    setShareOpen((preState) => !preState)
  }
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState)
  }
  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = () => {
    setReportOpen((prevState) => !prevState)
  }
  const [InviteOpen, setInviteOpen] = useState(false)
  const toggleInvite = () => {
    setInviteOpen((Preview) => !Preview)
  }
  const [deletepodcast, setdeletepodcast] = useState(false)
  const toggledeletepodcast = () => {
    setdeletepodcast((prevState) => !prevState)
  }
  const [selectedOption, setSelectedOption] = useState('Select');

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const [createOpen, setCreateOpen] = useState(false)
  const toggleCreate = () => {
    setCreateOpen((preState) => !preState)
  }
  const [imgfile, uploadimg] = useState('https://deconova.eu/wp-content/uploads/2016/02/default-placeholder.png')
  const imgFilehandeler = (e) => {
    if (e.target.files.length !== 0) {
      uploadimg(URL.createObjectURL(e.target.files[0]))
    }
  }
  const [forwardOpen, setForwardOpen] = useState(false)
  const toggleForward = () => {
    setForwardOpen((preState) => !preState)
  }
  const [dropdownText, setDropdownText] = useState('Public');

  const handleDropdownSelect = (selectedText) => {
    setDropdownText(selectedText);
  };
  const userIntrestsCard = [
    {
      img: R,
      tag: "Health & fitness",
      cardType: "Tag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R2,
      tag: "Health & fitness",
      cardType: "ntTag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R,
      tag: "Science & Technology ",
      cardType: "Tag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R2,
      tag: "Health & fitness",
      cardType: "ntTag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R,
      tag: "Comedy",
      cardType: "Tag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R2,
      tag: "Health & fitness",
      cardType: "ntTag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R,
      tag: "Health & fitness",
      cardType: "Tag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R2,
      tag: "Health & fitness",
      cardType: "ntTag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R,
      tag: "Science & Technology ",
      cardType: "Tag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R2,
      tag: "Health & fitness",
      cardType: "ntTag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R,
      tag: "Comedy",
      cardType: "Tag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    },
    {
      img: R2,
      tag: "Health & fitness",
      cardType: "ntTag",
      eventShedule: "Sun, 26 Jun 2022, 06:00PM - 09:30AM",
      place: "Naba Kelebar Utsav Of Param..",
      location: "Wemblay Center, London, UK",
      pplCount: "+50 people interested"
    }
  ]
  //===================== main_return_function
  const CreateEventsHeader = (props) => (
    <Fragment>
      <div className='d-flex justify-content-between border-bottom pb-3'>
        <div className='d-flex align-items-center'>
          <h3 style={{ color: '#4D4D4D', fontSize: '16px' }}>Create Post</h3>
          <UncontrolledButtonDropdown className='ml-3 d-flex align-items-center'>
            <DropdownToggle color='flat-primary' className='d-flex m-0 p-0'>
              <h5 className='' style={{ fontSize: '12px', fontWeight: '500', color: '#808080', fontFamily: 'sans-serif Poppins' }}>
                <Globe color='#979797' size={14} />&nbsp;{dropdownText}
              </h5>
              <ChevronDown color='#979797' size={17} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag='a' onClick={() => handleDropdownSelect('Public')} style={{ fontSize: '12px', fontWeight: '500', color: '#808080', fontFamily: 'sans-serif Poppins' }}>Public</DropdownItem>
              <DropdownItem tag='a' onClick={() => handleDropdownSelect('Private')} style={{ fontSize: '12px', fontWeight: '500', color: '#808080', fontFamily: 'sans-serif Poppins' }}>Private</DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
        {/* <div className='d-flex align-items-center text-muted'>
          <h4 className='header-text-style'><Link to='/CreateGoLive' className='text-muted'><img src="/assets/images/hotspot_pulse-1.svg" alt="golive" />Go live</Link></h4>
          <UncontrolledButtonDropdown className='ml-3'>
            <DropdownToggle color='flat-secondary' className='m-0 p-0 d-flex align-items-center'>
              <h4 className='header-text-style'>More</h4><ChevronDown color='#979797' size={18} />
            </DropdownToggle>
            <DropdownMenu style={{ minWidth: '23.2rem', left: '-19rem' }}>
              <div className='m-2 my-0'>
                <Row className='rowForrevert'>
                  <Col className='border onhovForPostselection m-1 user-select-none'><img width={20} height={20} src="/assets/images/Media.png" alt="" /> Media</Col>
                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <img width={20} height={20} src="/assets/images/Thought.png" alt="" />
                    Thought</Col>
                </Row>
                <Row className='rowForrevert'>

                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <Link to='/CreateGoLive' className='text-dark' >
                      <img width={20} height={20} src="/assets/images/Go_live.png" alt="" /> Go Live
                    </Link>
                  </Col>
                  <Col className='border onhovForPostselection m-1 user-select-none'> <img width={20} height={20} src="assets/images/Event.png" alt="" /> Event</Col>
                </Row>
                <Row className='rowForrevert'>
                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <Link to='/Podcast/createPodcast' className='text-dark' >
                      <img width={20} height={20} src="assets/images/Audio.png" alt="" /> Podcast
                    </Link>
                  </Col>
                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <Link to='/ArticleEditor' className='text-dark' >
                      <img width={20} height={20} src="assets/images/Blog.png" alt="" /> Articles
                    </Link>
                  </Col>

                </Row>
                <Row className='rowForrevert'>
                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <img width={20} height={20}
                      src="/assets/images/Recommendation.png"
                      alt=""
                    />
                    Recommendation
                  </Col>
                  <Col className='border onhovForPostselection m-1 user-select-none'><img width={20} height={20} src="/assets/images/Threat.png" alt="" /> Alert</Col>
                </Row>
                <Row className='rowForrevert'>
                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <img width={20} height={20} src="/assets/images/Poll.png" alt="" /> Poll
                  </Col>
                  <Col className='border onhovForPostselection m-1 user-select-none'>
                    <Link to='/Marketplace' className='text-dark' >
                      <img width={20} height={20} src="assets/images/Sell.png" alt="" /> Sell
                    </Link>
                  </Col>
                </Row>
              </div>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div> */}
      </div>
    </Fragment>
  )
  // ======================footer===============
  const CreateEventsFooter = (props) => (

    <div>
      <div className="create-btn-cust-option">
        <h4>Add to your post</h4>
        <ul className="create-btn-list tooltip-sec">
          <li className={props.Firsticon} >
            <label className='labelForMedia' for="inputTag">
              <Image onClick={props.mediaaction} strokeWidth={2} color={props.mediafill === 'true' ? '#81C14B' : '#A6A6A6'} />
              {
                props.mediafill === 'true' ?
                  <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name={props.name} onChange={props.mediapreview} type="file" hidden />
                  :
                  (null)
              }
              {/* <input id="inputTag" multiple accept='png, jpeg, webp' className='inputForMedia' name={props.name} onChange={props.mediapreview} type="file" hidden /> */}
            </label>
            <div className="tooltip-cls">
              <span>Media</span>
            </div>
          </li>
          <li id="hashtag-btn"  >
            <svg xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="16" height="16" viewBox="0 0 16 16" fill="#A6A6A6" ><path d="M15.8754 8.77081C15.6635 8.1214 14.956 7.77619 14.3032 7.9881L12.7514 8.50421L11.7158 5.42804L13.2675 4.91193C13.9204 4.70001 14.2622 3.99591 14.0537 3.34308C13.8417 2.69367 13.1342 2.34845 12.4814 2.56036L10.9296 3.07648L10.393 1.47003C10.1811 0.820618 9.47358 0.475403 8.82075 0.687317C8.16792 0.899231 7.82612 1.60333 8.03461 2.25617L8.57124 3.86261L5.37885 4.92902L4.84223 3.32257C4.63032 2.67316 3.9228 2.32794 3.26997 2.53986C2.61714 2.75177 2.27534 3.45587 2.48383 4.1087L3.02046 5.71515L1.47212 6.22785C0.819284 6.43976 0.477487 7.14386 0.685983 7.79669C0.856881 8.2923 1.33881 8.61701 1.83442 8.63751C2.06684 8.67169 2.24458 8.58283 3.80659 8.06329L4.84223 11.1395L3.29047 11.6556C2.64106 11.8675 2.29585 12.575 2.50434 13.2244C2.67524 13.72 3.15717 14.0447 3.65278 14.0652C3.8852 14.0994 4.06294 14.0106 5.62495 13.491L6.16157 15.0975C6.36323 15.6751 7.00581 16.0887 7.73383 15.8802C8.38667 15.6683 8.72846 14.9642 8.51997 14.3113L7.98335 12.7049L11.1826 11.6351L11.7192 13.2415C11.9208 13.8192 12.5634 14.2327 13.2915 14.0242C13.9443 13.8123 14.2861 13.1082 14.0776 12.4554L13.541 10.8489L15.0927 10.3328C15.7421 10.1277 16.0873 9.42023 15.8754 8.77081ZM7.19038 10.3841L6.15473 7.30109L9.35053 6.23126L10.3862 9.31427L7.19038 10.3841Z" /></svg>
            <div className="tooltip-cls">
              <span>Hashtag</span>
            </div>
          </li>
          <li id="smeily-btn" className={props.Secondicon} >
            <img alt='smiley' src="/assets/images/smile.png" className="addpost-option-btn" width="20" height="20" viewBox="0 0 16 16" fill="#A6A6A6" />
            <div className="tooltip-cls">
              <span>Smiley</span>
            </div>
          </li>
          <li id="checkin-btn" className={props.Thirdicon} >
            <svg xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="13" height="21" viewBox="0 0 13 21" fill="#A6A6A6"><path d="M6.5 0C2.90643 0 0 3.28666 0 7.35036C0 11.7291 4.10429 17.7669 5.79429 20.0665C6.16571 20.5705 6.84357 20.5705 7.215 20.0665C8.89571 17.7669 13 11.7291 13 7.35036C13 3.28666 10.0936 0 6.5 0ZM6.5 9.97548C5.88432 9.97548 5.29385 9.69891 4.8585 9.2066C4.42315 8.71429 4.17857 8.04658 4.17857 7.35036C4.17857 6.65413 4.42315 5.98642 4.8585 5.49411C5.29385 5.0018 5.88432 4.72523 6.5 4.72523C7.11568 4.72523 7.70615 5.0018 8.1415 5.49411C8.57685 5.98642 8.82143 6.65413 8.82143 7.35036C8.82143 8.04658 8.57685 8.71429 8.1415 9.2066C7.70615 9.69891 7.11568 9.97548 6.5 9.97548Z" /></svg>
            <div className="tooltip-cls">
              <span>Check In</span>
            </div>
          </li>
          <li id="friends-btn" className={props.Fourthicon} >
            <svg xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="25" height="21" viewBox="0 0 25 21" fill="#A6A6A6"><path d="M24.634 14.5714L21.1065 10.9695C20.6377 10.4903 20.001 10.2227 19.3369 10.2227H16.2391C15.5476 10.2227 14.989 10.7938 14.989 11.5006V14.6632C14.989 15.3421 15.2507 15.989 15.7195 16.4682L19.247 20.0701C19.7353 20.5692 20.5283 20.5692 21.0167 20.0701L24.6301 16.3763C25.1223 15.8772 25.1223 15.0705 24.634 14.5714ZM17.493 13.7328C16.9735 13.7328 16.5555 13.3055 16.5555 12.7744C16.5555 12.2433 16.9735 11.816 17.493 11.816C18.0126 11.816 18.4306 12.2433 18.4306 12.7744C18.4306 13.3015 18.0126 13.7328 17.493 13.7328ZM8.75043 10.2187C11.5123 10.2187 13.7507 7.9306 13.7507 5.10737C13.7507 2.28813 11.5123 0 8.75043 0C5.98857 0 3.75018 2.28813 3.75018 5.11136C3.75018 7.9306 5.98857 10.2187 8.75043 10.2187ZM13.7429 14.6592V11.7402C13.2663 11.5964 12.7702 11.4926 12.2506 11.4926H11.5982C10.731 11.8999 9.7661 12.1315 8.75043 12.1315C7.73475 12.1315 6.77377 11.8999 5.90263 11.4926H5.25026C2.35168 11.4966 0 13.9005 0 16.8635V18.5247C0 19.5829 0.839885 20.4415 1.87509 20.4415H15.6258C16.2313 20.4415 16.7625 20.142 17.1063 19.6867L14.8406 17.3706C14.1335 16.6479 13.7429 15.6855 13.7429 14.6592Z" /></svg>
            <div className="tooltip-cls">
              <span>People Tag</span>
            </div>
          </li>
          <li id="schedule-btn" className={props.Fifthicon} >
            <svg xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" fill="#A6A6A6"><path d="M20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442Z" /><path d="M12 8V12L15 15M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#A6A6A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12 8V12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" /></svg>
            <div className="tooltip-cls">
              <span>Schedule Your Post</span>
            </div>
          </li>
          <li id="comment-btn" className={props.Sixthicon} >
            <svg xmlns="http://www.w3.org/2000/svg" className="addpost-option-btn" width="24" height="24" viewBox="0 0 24 24" fill="#A6A6A6"><path d="M12 20.9999C16.97 20.9999 21 16.9702 21 12C21 7.02974 16.97 3 12 3C7.03 3 3 7.02974 3 12C3 13.7707 3.512 15.4222 4.395 16.815L3 20.9999L7.745 19.9323C9.07479 20.6408 10.5286 21.0056 12 20.9999Z" /><line x1="7" y1="9" x2="17" y2="9" stroke="white" strokeLinecap="round" /><line x1="7" y1="12" x2="17" y2="12" stroke="white" strokeLinecap="round" /><line x1="7" y1="15" x2="17" y2="15" stroke="white" strokeLinecap="round" /></svg>
            <div className="tooltip-cls">
              <span>Allow Comments</span>
            </div>
          </li>
        </ul>
      </div>
      <div className='py-2'>
        {/* <Button className='w-100 btn btn-post'>Post</Button> */}
        <button className='w-100 form-control mt-3' style={{ fontSize: '14px', fontWeight: '500', color: 'white', backgroundColor: '#81C14B', fontFamily: 'sans-serif Poppins' }}>Post</button>
      </div>
    </div>
  )
  //===================== main_return_function
  return (
    <Fragment>
      <div className="container-fluid section-t-space px-0">
        <div className="group-single-list-block">
          <Row className='mt-3 Events_PeopleThisGrid p-2 mr-1'>
            {
              userIntrestsCard.map((data) => (

                // <Col md="3" className="mb-4 w-100">
                //   <div className="MyEvents_box_section">
                //     <div className="MyEvent_tAG">
                //       <div className='event_Tag_section'>
                //         <h5>{data.eventDescription}</h5>
                //       </div>

                //     </div>
                //     <Link to="#" ><img src={data.eventCoverImageUrl} className="img-fluid MyEvent-img" /></Link>
                //     <div className='MyEvent_BodySection' >
                //       <div className='MyEvent_First_BodySection '>
                //         <small >{data.eventStartDate}</small>
                //         <div className='MyEvents_more_btn text-center'>
                //           <MoreHorizontal color='#808080' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                //           <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal MyeventsCategories_Option">
                //             <ul>
                //               <li>
                //                 <Link to="#"><Share2 color='#808080' size={16} /> Share </Link>
                //               </li>
                //               <li>
                //                 <Link to="#"><UserPlus color='#808080' size={16} /> Invite</Link>
                //               </li>
                //               <li>
                //                 <Link to="#"><Edit color='#808080' size={16} /> Edit</Link>
                //               </li>
                //               <li>
                //                 <Link to="#"><Trash2 color='#808080' size={16} /> Delete</Link>
                //               </li>
                //             </ul>
                //           </div>

                //         </div>

                //       </div>
                //       <h4 className='pt-3'>{data.eventAddress}</h4>
                //       <div className='MyEvent-bottom-Bodysection'>
                //         <p><MapPin color='#808080' size={15} />&nbsp; {data.postDisplayLocation}</p>
                //         <div className='d-flex justify-content-between'>
                //           <p className="d-flex align-items-start" ><Users size={15} color="#808080" />&nbsp; <span>{numFormatter(154)}&nbsp;Going</span> </p>
                //           <p className='d-flex align-items-start'><Users size={15} color="#808080" />&nbsp;  <span>{numFormatter(160)}&nbsp;Interested</span> </p>
                //         </div>
                //       </div>
                //       <div className='MyEvents_Btn_Types mt-2'>
                //         <UncontrolledButtonDropdown className='UncontrolledButtonDropdown'>
                //           <DropdownToggle color='flat' >
                //             <p> <Star className='mr-2' color='#FFFFFF' size={22} />&nbsp;Interested &nbsp; <img src={Vector} alt="vector" /></p>
                //           </DropdownToggle>
                //           <DropdownMenu className="Going_Menu">
                //             <DropdownItem className="Option_going_btn d-flex align-items-center" >
                //               <CheckCircle size={18} />&nbsp;Going
                //             </DropdownItem>
                //             <DropdownItem className="Option_going_btn d-flex align-items-center">
                //               <X size={18} />&nbsp;Not Interested</DropdownItem>
                //           </DropdownMenu>
                //         </UncontrolledButtonDropdown>
                //       </div>
                //     </div>

                //   </div>

                // </Col>
                <Col md="3" className="mb-4 w-100">
                  <div className="MyEvents_box_section" style={{ marginRight: '-8px', marginLeft: '6px' }}>
                    <div className="MyEvent_tAG">
                      <div className='event_Tag_section'>
                        <h5>{data.tag}</h5>
                      </div>
                    </div>
                    <Link to="/AllEvents" ><img src={data.img} className="img-fluid MyEvent-img" alt='' /></Link>
                    <div className='MyEvent_BodySection' >
                      <div className='MyEvent_First_BodySection '>
                        <small >{data.eventShedule}</small>
                        <div className='MyEvents_more_btn text-center'>
                          {/* <MoreHorizontal color='#808080' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                          <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal MyeventsCategories_Option">
                            <ul>
                              <li>
                                <Link to="#"><Share2 color='#808080' size={16} /> Share </Link>
                              </li>
                              <li>
                                <Link to="#"><UserPlus color='#808080' size={16} /> Invite</Link>
                              </li>
                              <li>
                                <Link to="#"><Edit color='#808080' size={16} /> Edit</Link>
                              </li>
                              <li>
                                <Link to="#"><Trash2 color='#808080' size={16} /> Delete</Link>
                              </li>
                            </ul>
                          </div> */}
                          <UncontrolledButtonDropdown className='align-self-center' >
                            <DropdownToggle className='p-0 m-0' color='flat' >
                              <MoreHorizontal color='#808080' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                            </DropdownToggle>
                            <DropdownMenu className='bizpagefordropdownmenus  dropdownLocation'>
                              <DropdownItem className='dropdownitem' onClick={toggleForward}><Share2 size={13} />&nbsp;&nbsp;Share Events</DropdownItem>
                              <DropdownItem divider></DropdownItem>
                              <DropdownItem className='dropdownitem' onClick={toggleInvite} ><Users size={13} />&nbsp;&nbsp;Invite</DropdownItem>
                              <DropdownItem divider></DropdownItem>
                              <DropdownItem className='dropdownitem' onClick={toggleCreate}>
                                <Edit size={13} />&nbsp;&nbsp;Edit
                              </DropdownItem>
                              <DropdownItem divider></DropdownItem>
                              <DropdownItem tag='a' className='dropdownitem' onClick={toggledeletepodcast}><Trash2 size={15} />&nbsp;&nbsp;Delete</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </div>
                      </div>
                      <h4 className='pt-3'>{data.place}</h4>
                      <div className='MyEvent-bottom-Bodysection'>
                        <p><MapPin color='#808080' size={15} />&nbsp; {data.location}</p>
                        <div className='d-flex justify-content-between'>
                          <p className="d-flex align-items-start" ><Users size={15} color="#808080" />&nbsp; <span>{numFormatter(154)}&nbsp;Going</span> </p>
                          <p className='d-flex align-items-start'><Users size={15} color="#808080" />&nbsp;  <span>{numFormatter(160)}&nbsp;Interested</span> </p>
                        </div>
                      </div>
                      <div className='MyEvents_Invitaion_Btn_types mt-2' style={{ backgroundColor: '#F6F6F6', border: '1px solid #c4c4c4'}} >
                        <UncontrolledButtonDropdown className='UncontrolledButtonDropdown'>
                          <DropdownToggle color='flat' className="UncontrolledButtonDropdowntext">
                            <p style={{ color: '#4D4D4D' }}>{selectedOption} <ChevronDown size={20} style={{ color: '#808080' }} /></p>
                          </DropdownToggle>
                          <DropdownMenu className=" mt-1">
                            <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Select')}>
                              <Check size={18} />&nbsp;Select
                            </DropdownItem>
                            <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Going')}>
                              <CheckCircle size={18} />&nbsp;Going
                            </DropdownItem>
                            <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Interested')}>
                              <Star size={18} />&nbsp;Interested
                            </DropdownItem>
                            <DropdownItem className="Option_going_btn d-flex align-items-center" onClick={() => handleOptionSelect('Not Interested')}>
                              <X size={18} />&nbsp;Not Interested
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>
                    </div>
                  </div>
                </Col>

              ))
            }
          </Row>

        </div>
      </div>
      {/*=====================  START_Create_Events_MODAL ===================== */}
      <div className='Create_Events_modal'>
        {
          (createOpen !== false) &&
          <Modal scrollable isOpen={createOpen} toggle={toggleCreate} className='modal-dialog-centered'>
            <Card className='p-3 rounded'>
              <CreateEventsHeader />
            </Card>
            <ModalBody>
              <Card className='p-3 rounded' style={{ marginTop: '-29px' }}>
                <Form className='my-2'>
                  <FormGroup>
                    <Label for='eventcategory' tag='h4' className='my-1' >Event Catagory</Label>
                    <Input id='eventcategory' type='select'>
                      <option>Social</option>
                      <option>Social</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label tag='h4' className='my-1' for='helperText'>Event Title*</Label>
                    <Input type='text' id='helperText' placeholder='Enter your event title....' />
                    <FormText className='text-muted text-right'>Max 64 Characters</FormText>
                  </FormGroup>
                  <FormGroup>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Label tag='h4' className='my-1'  >Upload Event Cover Photo</Label>
                      <Input type='file' className='customeForuploader' onChange={imgFilehandeler} />
                    </div>
                    <img
                      src={imgfile}
                      className="event-img-prev w-100"
                      alt="event cover"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label tag='h4' className='my-1' >Description</Label>
                    <Input type='textarea'
                      cols="15"
                      rows="5"
                      placeholder="Enter Events Description"
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className='event-date-time-container'>
                      <div>
                        <Label tag='h4' className='mb-2' >Event Start Date</Label>
                        <input type="date" name="" id="" placeholder='dd/mm/yyyy' className='form-control' />
                      </div>
                      <span style={{ color: '#4D4D4D', paddingTop: '26px', fontSize: '16px' }} className='two-text'>&nbsp;to&nbsp;</span>
                      <div >
                        <Label tag='h4' className='mb-2 ml-2' >Event End Date</Label>
                        <input type="date" name="" id="" placeholder='dd/mm/yyyy' className='form-control' />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className='event-date-time-container'>
                      <div>
                        <Label tag='h4' className='mb-2'>Event Start Time</Label>
                        <input type="time" name="" id="" placeholder='enter your time' className='form-control' style={{ width: '100%' }} />
                      </div>
                      <span style={{ color: '#4D4D4D', paddingTop: '26px', fontSize: '16px' }} className='two-text'>&nbsp;to&nbsp;</span>
                      <div> {/* Set a fixed width for the second input container */}
                        <Label tag='h4' className='mb-2 ml-2'>Event End Time</Label>
                        <input type="time" className="form-control" style={{ width: '100%' }} />
                      </div>
                    </div>

                  </FormGroup>
                  <FormGroup style={{ marginTop: '-1px' }}>
                    <Label for='eventcategory' tag='h4' className='my-1' >Address or Link to Event</Label>
                    <Input Placeholder='Type Address or Link to Event' type='text' />
                  </FormGroup>
                </Form>
                <CreateEventsFooter />
              </Card>
            </ModalBody>

          </Modal>
        }
      </div>
      <DeleteModal
        isOpen={deletepodcast}
        updateOpen={toggledeletepodcast}

      />
      <InviteModal
        isOpen={InviteOpen}
        updateOpen={toggleInvite}

      />
      {/* <ShareEventModal
        isOpen={shareOpen}
        updateOpen={toggleShareOpen}
      /> */}
      <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} />
      {/*=================== Start_Report_section_modal  ===================*/}
      <ReportModal
        isOpen={reportOpen}
        updateOpen={toggleReport}
      />
    </Fragment>
  )
}

export default MyInterested