import React, { useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ProfileCover from './ProfileCover';
import { useDispatch, useSelector } from "react-redux";
import { loadProfileByUserId } from '../../Services/Actions/UserProfile/getUserProfileByUserIdAction';

export default function ProfileMenu(props) {
    let dispatch = useDispatch();
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [userId, setUserId] = useState(props.userId||user.id);
    const type = userId === user.id ? "creator" : "viewer";

    const { userProfileByUserId } = useSelector(
        (state) => state.getUserProfileByUserIdData
      );

    useEffect(() => {
        dispatch(loadProfileByUserId(userId));
    },[userId])

    return (
        <>
            <ProfileCover setUserId={setUserId} userProfileByUserId={userProfileByUserId} userId={userId} type={type} />
            <div className="profile-menu section-t-space">
                <ul>
                    <li>
                        <NavLink to={`/MyTimeline?userId=${userId}&type=${type}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14">
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            <h6>timeline</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/MyProfile?userId=${userId}&type=${type}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                            </svg>
                            <h6>about</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/MyMediaListView?userId=${userId}&type=${type}`}>
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14">
                                <rect x="2" y="2" width="20" height="20" rx="2.18" ry="2.18"></rect>
                                <line x1="7" y1="2" x2="7" y2="22"></line>
                                <line x1="17" y1="2" x2="17" y2="22"></line>
                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                <line x1="2" y1="7" x2="7" y2="7"></line>
                                <line x1="2" y1="17" x2="7" y2="17"></line>
                                <line x1="17" y1="17" x2="22" y2="17"></line>
                                <line x1="17" y1="7" x2="22" y2="7"></line>
                            </svg>
                            <h6>Media</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/MyShotz?userId=${userId}&type=${type}`}>
                            <img src="/assets/images/Group-profile-icon.svg" alt="Shotz Icon" />
                            <h6>Shotz</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/MyEvent?userId=${userId}&type=${type}`}>
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                <polyline points="14 2 14 8 20 8"></polyline>
                                <line x1="16" y1="13" x2="8" y2="13"></line>
                                <line x1="16" y1="17" x2="8" y2="17"></line>
                                <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                            <h6>Event</h6>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/MyPodcast?userId=${userId}&type=${type}`}>
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14">
                                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                <line x1="12" y1="19" x2="12" y2="23"></line>
                                <line x1="8" y1="23" x2="16" y2="23"></line>
                            </svg>
                            <h6>Podcast</h6>
                        </NavLink>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-14 ih-14">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                            <h6>More...</h6>
                        </a>
                        <ul className="dropdown-menu my-0 py-0 rounded shadow-lg bg-body rounded" aria-labelledby="navbarDropdown">
                            <NavLink to={`/MyGroups?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> Groups
                            </NavLink>

                            <NavLink to={`/MyText?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> Text
                            </NavLink>

                            <NavLink to={`/MyMarketPlace?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> MarketPlace
                            </NavLink>

                            <NavLink to={`/MyRecommendation?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> Recommendation
                            </NavLink>

                            <NavLink to={`/MyBizPage?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> BizPage
                            </NavLink>

                            <NavLink to={`/MyPoll?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> Poll
                            </NavLink>
                            <NavLink to={`/MyArticle?userId=${userId}&type=${type}`} className='ancher-text menu-item-text border-bottom'> Article
                            </NavLink>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    );
}
