import React, { Fragment } from 'react'
import Header from '../Header'
import LeftSidebar from '../LeftSidebar'
import RightSidebar from '../RightSidebar'
import UserProfileCover from './UserProfileCover'
import './UserProfile.css'
import UserProfileTab from './UserProfileTab'
import ProfileMenu from '../profile-components/ProfileMenu'

const UserProfile = () => {
    //=============== Main_return_function ==============//
    return (
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <UserProfileCover />
                    <ProfileMenu />
                    <div className="container-fluid section-t-space px-0">
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}

export default UserProfile