import React from 'react'
import { Card, CardText, Col, Row } from 'reactstrap'
import Group from '../NewComponents/IMG/Group.png'

const BlankTab = () => {
    return (
        <div>
            <Row>
                <Col xl='12'>
                    <Card className='w-100 h-100 p-3 mt-1 border-0'>
                        <CardText tag='h4' >Showing best results for<span className='font-weight-bold'> "Cillian Murphy"</span></CardText>
                        <div className='m-5 p-5 text-center'>
                            <div>
                                <img src={Group} className='img-fluid' alt='PNF' />
                            </div>
                            <p className=' bg-white mt-4'>Sorry! We could not find any BizPage related to “Cillian Murphy”</p>
                            <p className='text-black-50  mt-1'>Make sure that everything is spelt correctly or try</p>
                            <p className='text-black-50'>different keywords.</p>
                        </div>
                    </Card>
                </Col>

            </Row>
        </div>
    )
}

export default BlankTab