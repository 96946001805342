import $ from 'jquery';

$(document).keydown(function(e) {
    var parentElement = $(".shortz-modal-n").parent();
    parentElement.addClass("myClass");
    var divHeight = $(".scroll-item").height();
    // Check if the pressed key is "u" (85) or "d" (68)
    if (e.keyCode === 38) { // "u" key
        // Scroll up
        $(parentElement).animate({ scrollTop: '-='+ divHeight }, 'fast');
    } else if (e.keyCode === 40 || e.keyCode === 13 ) { // "d" key
        // Scroll down
        $(parentElement).animate({ scrollTop: '+='+ divHeight }, 'fast');
    }
});