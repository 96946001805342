import React from 'react'
import { Music, Play } from 'react-feather'
import { Badge, Col, Row } from 'reactstrap'
import AudioImg from '../../../NewComponents/IMG/AudioImg.svg'
import { Stack, useTheme, Slider } from '@mui/material';
import AudioShotz from './AudioShotz';
import backicon from '../../../NewComponents/IMG/backicon.svg'

const GridAudio = ({ toggleOpen }) => {
    const theme = useTheme();



    return (
        <div className='roundedThisBorder mt-4 p-2'>
            <Row className=''>
                <Col className='p-1'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                            <div>
                                <img src={AudioImg} className='m-3' alt='audioimg' />
                                <Music size={35} color='white' className='MusicThisIcon' />
                            </div>
                            <div className='mt-3'>
                                <h2 className=''><b>A.i. vs Jobs</b></h2>
                                <p className='text-secondary mt-2 text-wrap w-60'>Hello ☕️ I'm Positive John :) My mission here is to help your brain,Now immerse yourself in healing. </p>

                                <div className='d-flex py-2'>
                                <Badge color='light-primary' className='px-2 d-flex align-items-center light-font' >
                                    Trending #1
                                </Badge>
                                <Badge color='primary' pill className='pl-1 px-2 d-flex align-items-center light-font'>
                                    Top #47
                                </Badge>
                            </div>
                                <p className=' text-secondary'>Audio used in <b>50M</b> Shotzs</p>
                                <div className='w-1000 mt-2'>
                                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                        <Play size={30} color="#81c14b" fill='#81c14b' />
                                        <Slider
                                            aria-label="Volume"
                                            defaultValue={100}
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                '& .MuiSlider-track': {
                                                    border: 'none',
                                                },
                                                '& .MuiSlider-thumb': {
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: '#81C14B',
                                                    '&:before': {
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                    },
                                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                        boxShadow: 'none',
                                                    },
                                                },
                                            }}
                                        />
                                        <p className='ml-4'> 0:30</p>
                                    </Stack>

                                </div>
                            </div>
                        </div>

                        <div onClick={toggleOpen} className='pr-1 align-self-start py-3' style={{cursor: 'pointer'}} >
                            {/* <ChevronLeft  /> */}
                            <img src={backicon} alt='back' />
                        </div>
                    </div>
                </Col>
            </Row>
            <AudioShotz />
        </div>
    )
}

export default GridAudio   