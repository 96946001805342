import { Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { Filter } from "react-feather";
import pro_mutual from "../../../NewComponents/IMG/pro_mutual.png";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FeedTaggedPeopleModal = ({ TaggedModalOpen, openModal, postType }) => {
  const [filteredTaggedPeoples, setFilteredTaggedPeoples] = useState(
    postType.taggedPeoples
  );
  const [searchItem, setSearchItem] = useState("");

  const handleSearch = (searchValue) => {
    const filteredPeople = postType.taggedPeoples.filter((person) =>
      person.fullName.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    setFilteredTaggedPeoples(filteredPeople);
  };
  

  return (
    <>
      {openModal !== false && (
        <Modal
          isOpen={openModal}
          toggle={TaggedModalOpen}
          className="modal-dialog-centered"
        >
          <div className="w-100 border-bottom">
            <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
              <h4>Tagged People </h4>
              <span style={{ cursor: "pointer" }} onClick={TaggedModalOpen}>
                Close
              </span>
            </div>
          </div>

          <ModalBody>
            <Row className="mt-2">
              <Col md="8" className="Like_search" style={{ width: "100%" }}>
                <div className="profile-search-blk w-100">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="search"
                    placeholder="Search..."
                    data-bs-toggle="modal"
                    data-bs-target="#peopleSearch"
                    value={searchItem}
                    onChange={(e) => {
                      setSearchItem(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              {filteredTaggedPeoples.map((data, index) => (
                <div className="group-detail-page-blk" key={data.id}>
                  <div className="Follower-memberslist-block">
                    <div className="group-member-img-cont-blk">
                      <div>
                        <Link to={"/MyProfile"}>
                          <img
                            src={`${data.profileThumb}`}
                            height="35px"
                            width="35px"
                            className="img-luid rounded-circle"
                            onError={(e) => e.currentTarget.src = 'https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/X0mSUU7CQMExa.png'} 
                            alt="Profile"
                          />
                        </Link>
                      </div>
                      <div className="member-cont-blk">
                        <Link to={"/MyProfile"}>
                          <h4>{data.fullName}</h4>
                        </Link>
                        <p className="text-muted">{data.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default FeedTaggedPeopleModal;
