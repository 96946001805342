import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'reactstrap'

const NftDropProfile = () => {

    //=================== Main_Return_Function ===================//
    return (
        <Fragment>
            <div className='Drop_Cover_MainSection'>
                <div className='Drop_Position_Section' >
                    <span>1280 X 420</span>
                </div>
                <div className='Drop_Profile_Center_Section'>
                    <Button color='secondary' outline className="Ntf_Secondary_btn">
                        Live Now
                    </Button>
                    <h1>Invisible Friends</h1>
                    <h2>Ends in: 18h 54m 29s</h2>
                    <div className='mt-5'>
                        <NavLink to="/DropProfileDetail" >
                            <Button className="Profile_Drop_Detail_btn" color="primary" >
                                View Drop Details
                            </Button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NftDropProfile