import React from 'react'
import { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import PostCreation from '../../Universal-components/post-creations/PostCreation'
import Media from '../../Universal-components/post-creations/sub-components/Media'
import '../style.css'
import GalleryLeftSideData from "./GalleryLeftSideData"

const MyDiscussion = () => {

    //     <Fragment>
    //         {
    //             SuggestionsCardData.map((data) => (
    //                 <Card className="Suggestion_box_Section">
    //                     <img src={data.img} className="img-fluid" alt='suggest' />
    //                     <CardBody>
    //                         <div className='d-flex justify-content-center  Suggestion_Heading'>
    //                             <h4>{data.name}</h4>
    //                         </div>
    //                         <div className='d-flex justify-content-center mt-2 Suggestion_Heading' >
    //                             <div className='d-flex align-items-center '>
    //                                 <Globe size={12} color="#4D4D4D" />&nbsp;
    //                                 <span>{data.groupType}</span>&nbsp;
    //                             </div>
    //                             <div>
    //                                 ●&nbsp;
    //                                 <span>{data.member}</span>
    //                             </div>
    //                         </div>
    //                         <div className='d-flex justify-content-center mt-2 Suggestion_Heading'>
    //                             <p>{data.title} </p>
    //                         </div>
    //                         <div className='d-flex justify-content-center mt-2'>
    //                             <Button className="join_btn"><p>{data.btnName}</p></Button>
    //                         </div>

    //                     </CardBody>
    //                 </Card>
    //             ))
    //         }
    //     </Fragment >
    // )
    // const GalleryLeftSideData = () => (
    //     <Fragment>
    //         <div className='About_Gallery_Section'>
    //             <CardBody>
    //                 <CardTitle className='d-dlex justify-content-between Trending_Heading_section'>
    //                     <h4>Gallery</h4>
    //                     <p>See all →</p>
    //                 </CardTitle>
    //                 <Row className="mt-3">
    //                     <Col md="6 " className="mb-4">
    //                         <div className='d-flex'>
    //                             <Card className=''>
    //                                 <img src={Img} height="80px" width="75px" className='img-fluid Img_Card' />
    //                             </Card>
    //                             <Card className='ml-4'>
    //                                 <img src={Img1} height="80px" width="75px" className='img-fluid Img_Card' />
    //                             </Card>
    //                         </div>
    //                         <div className='d-flex top_Grllery_Section'>
    //                             <Card className=''>
    //                                 <img src={Img2} height="80px" width="75px" className='img-fluid Img_Card' />
    //                             </Card>
    //                             <Card className='ml-4'>
    //                                 <img src={Img3} height="80px" width="75px" className='img-fluid Img_Card' />
    //                             </Card>
    //                         </div>
    //                     </Col>
    //                     <Col md="6">
    //                         <div>
    //                             <Card >
    //                                 <img src={Img4} width="100px" height="100px" className='img-fluid Img_Card' />
    //                             </Card>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //             </CardBody>
    //         </div>
    //         <div className='About_Gallery_Section'>
    //             <CardBody>
    //                 <CardTitle className='d-dlex justify-content-between Trending_Heading_section'>
    //                     <h4>Suggestions</h4>
    //                     <p>See all →</p>
    //                 </CardTitle>
    //                 <div className="podcast-list-block">
    //                     <Slider {...SuggestionsSettings} className="default-space">
    //                         <div>
    //                             <SuggestionsCard />
    //                         </div>
    //                         <div>
    //                             <SuggestionsCard />
    //                         </div>
    //                         <div>
    //                             <SuggestionsCard />
    //                         </div>
    //                         <div>
    //                             <SuggestionsCard />
    //                         </div>
    //                     </Slider>
    //                 </div>
    //             </CardBody>

    //         </div>
    //         <div className='About_Gallery_Section'>
    //             <CardBody>
    //                 <CardTitle className="Trending_Heading_section">
    //                     <h4>Trending Tags </h4>
    //                 </CardTitle>
    //                 <div className="biz-about-cont">
    //                     <ul>
    //                         <li>
    //                             {
    //                                 TrendingData.map((data) => (
    //                                     <div className='d-flex  align-items-center trending_section'>
    //                                         <img src={Trending} className="img-fluid" />
    //                                         <div className='Tranding_data'>
    //                                             <h4>{data.hasTag}</h4>
    //                                             <p>{data.postData} </p>
    //                                         </div>
    //                                     </div>
    //                                 ))
    //                             }
    //                         </li>
    //                     </ul>
    //                     <span>See more tags</span>
    //                 </div>
    //             </CardBody>

    //         </div>
    //     </Fragment>
    // )
    // main_Return_Function
    return (
        <Fragment>
            <Row>
                <Col md="4">
                    <GalleryLeftSideData />
                </Col>
                <Col md="8">
                    <div>
                        <PostCreation />
                    </div>
                </Col>

            </Row>

        </Fragment>
    )
}

export default MyDiscussion