import * as types from "../../Constants/SocialFeed/index.js";

const initialState = { 
    getPostReport: [],
    loading: true,
};

 const GetPostReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_POST_REPORT:
            return {
                ...state,
                getPostReport: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default GetPostReportReducer;