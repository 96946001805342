import React,{Component} from 'react'; 
import { NavLink } from "react-router-dom";
// import Header from './Header';
// import Footer from './Footer';

export class Home extends Component {  

  render() {
    return (
      <>
        hi
    
      </>
    );
  }
} 
export default Home 