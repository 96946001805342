import React from 'react'
import { Progress } from 'reactstrap'

const LoginSignUpStepper = ({ steps, active }) => {


    return (
        <div className="signup-progress-bar">
            <Progress className="story_Progress_Bar w-100 " value={100 * (active / steps)} />
        </div>
    )
}

export default LoginSignUpStepper
