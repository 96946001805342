import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import './style.css';
import { Button } from 'reactstrap';
import axios from 'axios'; 
import { Link, NavLink } from 'react-router-dom';

const GroupSuggesion = () => {
    const [groupData, setGroupData] = useState([]);
    const ownedGroupSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handelJoinGroup=async(groupId)=>{
        const user = JSON.parse(localStorage.getItem("sociomeeUser"));
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/group/member/create`,
                {
                    groupId:groupId,
                    memberId:user.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                    },
                }
            );

        } catch (error) {
            console.error("Error fetching upcoming events:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    "searchKey": "",
                    "pageIndex": 0,
                    "pageSize": 5
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllSuggestedGroupPageList`,
                    body,
                    {
                        headers: {
                            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                        },
                    }
                );
              
                setGroupData(response.data.data.successResult);
                // console.log("Data for suggested Groups", response.data.data.successResult);
            } catch (error) {
                console.error('Error fetching suggested groups:', error);
            }
        };
    
        fetchData();
    }, []);

    return (
        <div className="groupBottom-custom-block group-custom-block-hashtag shadow-sm" style={{ borderRadius: "5px" }}>
            <div className="CommonHeadingSeg py-2 pr-2">
                <h3 style={{ color: "#333333" }}>Suggested for You</h3>
                <h5>Groups you might be interested in.</h5>
            </div>
            <Slider {...ownedGroupSettings} className="default-space GroupSSliderCustomizes">
                {groupData.map((group, index) => (
                    <div key={index}>
                        <div>
                            <NavLink to={`/GroupDetail/${group.id}`}>
                                <img src={group.coverPicUrl} className="img-fluid group-img Suggestion_Grp_Img" alt={`Group ${group.name}`} />
                            </NavLink>
                            <div className="py-2">
                            <h4 style={{ color: "#4d4d4d", margin: "0 0 4px" }}>
                                     {group.name.length > 15
                                         ? group.name.substring(0, 12) + " ..."
                                         : group.name
                                     }
                                 </h4>
                                <div className="Grpupmember_Main_blk CommonHeadingSeg">
                                    <h6 style={{ fontSize: "12px", color: "#999999" }}>{group.totalMembers} members • {group.posts} Posts</h6>
                                </div>
                            </div>
                        </div>
                        <div className="group-buttons-blk">
                            <Button color="primary" className="Group_Join_btn_blk w-100" onClick={()=>{handelJoinGroup(group.id,)}}>Join Group</Button>
                        </div>
                    </div>
                ))}
            </Slider>
            <Link to="/Group">
                <div className="more-hags mt-3">
                    <h5 className="time-hours-blk text-start ">
                        <NavLink to="/Suggested" style={{ color: '#999999' }}>See more Groups</NavLink>
                    </h5>
                </div>
            </Link>
        </div>
    );
}

export default GroupSuggesion;
