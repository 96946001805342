
import React, { useState } from 'react'
import { AlertTriangle, Edit, Eye, Filter, Link, MoreHorizontal, Play, Plus, Share2, Trash2 } from 'react-feather'
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap'
import AvatarGroupComponent from './AvatarGroupComponent'
import img from '../../NewComponents/IMG/img.svg'
import EP from '../../NewComponents/IMG/EP.png'
import paid from '../../NewComponents/IMG/paid.png'
import Mic from '../../NewComponents/IMG/Mic.png'
import podcast from '../../NewComponents/IMG/podcast.png'
import { InputGroup } from 'react-bootstrap'
import PodcastAccordion from './PodcastAccordion'
import SliderButton from './SliderButton';
import LocationIcon from '../../NewComponents/IMG/LocationIcon.svg'
import Slider from '@mui/material/Slider';
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
import { useTheme, Slider as MatSlider, Stack } from '@mui/material';
import { MinusSquare, PlusSquare } from 'react-feather'
import ChipBoxLocation from '../top-trending-components/ChipBoxLocation'
import ChipBox from '../top-trending-components/ChipBox'
const Podcast = () => {
    const [basicModal, setBasicModal] = useState(false)
    const theme = useTheme()
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    const ReportDataInfo = [
        {
            name: "Spam"
        },
        {
            name: "Nudity"
        },
        {
            name: "Violence"
        },
        {
            name: "Harrasment"
        },
        {
            name: "Suicide or self-injury"
        },
        {
            name: "Terrorism"
        },
        {
            name: "Others"
        }
    ]
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const chackbox = [
        {
            songs: 'music',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Arts',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Weddings',
            chackbox: <input type="checkbox" />
        },
        {
            songs: 'Food & Recipe',
            chackbox: <input type="checkbox" />
        }
    ]
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(1).toString()} K`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(1).toString()} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    const Podcastdata = [
        {
            img: img,
            paid: paid,
            text: 'Pappa podden',
            num: '1500'
        },
        {
            img: img,
            text: 'Pappa podden',
            num: '2500'
        },
        {
            img: img,
            paid: paid,
            text: 'Parched Power with Deepak',
            num: '8500'
        },
        {
            img: img,
            text: 'Parched Power with Deepak',
            num: '6500'
        }

    ]
    return (
        <div>
            <Card className='p-2 border-0'>
                <Row className='d-flex justify-content-around align-items-center'>
                    <Col md='6'>
                        <div className='d-flex mt-2 ml-2 align-items-center'>
                            <p className='peopletabstext'>Showing best results for</p>
                            <p className='font-weight-bold'>“Esther Howard”</p>
                        </div>
                    </Col>
                    <Col md='6'>
                        <div className='d-flex justify-content-end p-2'>
                            {/* -------------------- Filter for Mobile-------------------- */}
                            <div className='rem-web-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Filter size={20} color='#81c14b' className='mr-2' onClick={() => setBasicModal(!basicModal)} />
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                            {/* --------------------Filter for Desktop-------------------- */}
                            <div className='rem-mob-view'>
                                <UncontrolledButtonDropdown style={{ backgroundColor: 'transparent ' }} className='p-o m-0'>
                                    <DropdownToggle className='d-flex m-0 p-0 bg-light-dropdown-toggle'>
                                        <Button color='primary' outline className='align-items-center' onClick={() => setBasicModal(!basicModal)}>
                                            <Filter size={18} className='mr-2' />
                                            Filters
                                        </Button>
                                    </DropdownToggle>
                                </UncontrolledButtonDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='AllTagsThis'>
                    {
                        Podcastdata.map((data, index) => {
                            return (
                                <Col xl='3' className='p-1 mt-2'>
                                    <Card>
                                        <img src={podcast} className='podcastImg rounded' />
                                        <div className='UncontrolledButtonDropdowntoprightSearchBar'>
                                            <UncontrolledButtonDropdown className='align-self-center'>
                                                <DropdownToggle className='p-0 m-0' color='flat' >
                                                    <MoreHorizontal size={19} color='#FAF7F7' />
                                                </DropdownToggle>
                                                <DropdownMenu className='bizpagefordropdownmenu'>
                                                    <DropdownItem className='dropdownitem' onClick={toggleShareOpen}><Share2 size={13} />&nbsp;Share post</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Eye size={13} />&nbsp;View user</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' ><Link size={13} />&nbsp;Copy link</DropdownItem>
                                                    <DropdownItem divider></DropdownItem>
                                                    <DropdownItem className='dropdownitem' onClick={toggleReport}><AlertTriangle size={13} />&nbsp;Report</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>

                                        <div className='podcastLeft'>
                                            <p className='mt-1 d-flex'><img src={Mic} className='Miccolor' /><p className='micnumber'>{numFormatter(data.num)}</p></p>

                                        </div>
                                        <div className='podcasRight'>
                                            <p className='EP'>S1 Ep 02</p>
                                        </div>
                                        <div className='podcastftBottom'>
                                            <p className='EP'>{data.text}</p>
                                        </div>
                                        <div className='podcastRightBottom'>
                                            <p className='EP'><img src={data.paid} /></p>
                                        </div>


                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Card>
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            <div className='Review_Report_modal'>
                {
                    reportOpen !== false &&
                    <Modal scrollable isOpen={reportOpen} toggle={toggleReport} className='modal-dialog-centered'>
                        <ModalHeader toggle={toggleReport} className="Biz_Rating_SECTION">
                            <h3>Report a Post</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div className='Modal_Body_Section'>
                                <p>Tell us about what you feel and help us to <br /> make Sociomee more secure.</p>
                            </div>
                            {
                                ReportDataInfo.map((data) => (
                                    <div className='d-flex justify-content-between mt-4 Report_Section'>
                                        <h4>{data.name}</h4>
                                        <div>
                                            <Input
                                                name="radio1"
                                                type="radio"
                                                className="Check"
                                            />
                                            {' '}
                                        </div>
                                    </div>
                                ))
                            }
                            <div className='d-flex justify-content-center mt-3'>
                                <Input
                                    id="ReportName"
                                    name="ReportName"
                                    placeholder="Write a comment..."
                                    type="Report"
                                />
                            </div>
                            <hr className='w-100 text-muted' />
                            <div className='text-center'>
                                <Button className="w-25 ml-2" color="primary" onClick={toggleReport} >Submit</Button>
                                <Button onClick={toggleReport} outline className="w-25 ml-2" >Cancel</Button>
                            </div>
                        </ModalBody>

                    </Modal>

                }

            </div>
            <Modal isOpen={basicModal} className='modal-dialog-centered ' toggle={() => setBasicModal(!basicModal)}>
                <ModalHeader toggle={() => setBasicModal(!basicModal)}><b>Podcast
                </b>
                </ModalHeader>
                {/* ----------------------------------------filter props ModalBody start here------------------------------                     */}
                <ModalBody>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h4>Withim (Km)</h4>
                            <h4>8821 Km</h4>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            <div className='w-100'>
                                <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                                    <MatSlider
                                        aria-label="Volume"
                                        defaultValue={100}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                            '& .MuiSlider-track': {
                                                border: 'none',
                                            },
                                            '& .MuiSlider-thumb': {
                                                width: 20,
                                                height: 20,
                                                backgroundColor: '#81C14B',
                                                '&:before': {
                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                    boxShadow: 'none',
                                                },
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                            <div className='d-flex align-items-center ml-3' role='button'>
                                <MinusSquare color='#81C14B' role='button' /> <PlusSquare color='#81C14B' role='button' />
                            </div>
                        </div>
                        <div className='border-top border-bottom mt-2'>
                            <h4 className='mt-2'>Choose locations</h4>
                            <ChipBoxLocation />
                        </div>
                        <h4 className='mt-3'>Choose Category</h4>
                        <ChipBox />
                        <h4 className='mt-3'>Choose Podcast Type</h4>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Single Podcast</p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Series Podcast </p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Live Podcast</p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-between p-2'>
                            <p id='1'>Paid Podcast</p>
                            <input type="checkbox" />
                        </div>
                        <div className='d-flex justify-content-center mt-3 '>
                            <Button color='primary' outline>CLEAR ALL</Button>
                            <Button color='primary' className='APPLYThisWidth ml-2'>APPLY</Button>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default Podcast