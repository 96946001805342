import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import * as types from "../../Services/Constants/UserProfile/index";
import {
  addInterests,
  loadAllInterests,
} from "../../Services/Actions/UserProfile/getAllInterestsAction";
import {
  loadProfileByUserId,
  updateUserProfile,
} from "../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import Header from "../Header";
import LeftSidebar from "../LeftSidebar";
import RightSidebar from "../RightSidebar";
import MyPorfileSports from "./MyProfileComponents/MyPorfileSports";
import MyProfileGroup from "./MyProfileComponents/MyProfileGroup";
import MyProfileHobbies from "./MyProfileComponents/MyProfileHobbies";
import ProfileBio from "./MyProfileComponents/ProfileBio";
import ProfileContactDetail from "./MyProfileComponents/ProfileContactDetail";
import ProfileDetail from "./MyProfileComponents/ProfileDetail";
import ProfileProfession from "./MyProfileComponents/ProfileProfession";
import ProfileCover from "./ProfileCover";
import ProfileMenu from "./ProfileMenu";
import Pen1 from "./Img/Pen1.png";
import MyMovies from "./ProfileComponentScreens/MyMovies";
import MyMusic from "./ProfileComponentScreens/MyMusic";
import MyBook from "./ProfileComponentScreens/MyBook";
import MyGroup from "./ProfileComponentScreens/MyGroup";
import MyBizPage from "./ProfileComponentScreens/MyBizPage";
import MyInterest from "./ProfileComponentScreens/MyInterest";
import MyProfession from "./ProfileComponentScreens/MyProfession";
import MyEducation from "./ProfileComponentScreens/MyEducation";
import { useLocation } from 'react-router-dom';

export default function MyProfile() {

  const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const [hasRecords, setHasRecords] = useState(false);
  // const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const location = useLocation();
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfileByUserId(userId));
  }, []);

  useEffect(() => {
    const fetchMovies = async () => {
        try {

            let { data } = await axios.post(
                `${process.env.REACT_APP_IPURL}/user/getAllMovies`,
                { userId: userId||user.id },
                {
                    headers: { Authorization: `Bearer ${user?.token}` }
                }
            );
            if (data && data.data && data.data.successResult.length > 0) {
              setHasRecords(true);
          } else {
              setHasRecords(false);
          }
        } catch (error) {
            console.error("Error fetching movies:", error.response && error.response.data.message
                ? error.response.data.message
                : error.message);
        }
    };
    fetchMovies();
  }, []);

  // console.log("userProfileBy id===============================",location.state)

  return (
    <>
      <Header></Header>
      <div className="page-body container-fluid profile-page">
        <LeftSidebar></LeftSidebar>
        <div className="page-center">
          <ProfileMenu userId={userId}></ProfileMenu>
          <div className="container-fluid section-t-space px-0">
            <div className="row">
              <div className="content-left col-4 res-full-width order-1">
                <div className="profile-about">
                  <div className="card-main-title">
                    <h3>about</h3>
                  </div>

                  {/* User Profession  */}
                  {userProfileByUserId?.professionName !==null && type=="viewer" ? (
                    <MyProfession userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  ):(
                    <MyProfession userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  )}
                  {userProfileByUserId?.education?.length > 0 && type=="viewer" ? (
                  <MyEducation userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  ):(
                    <MyEducation userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  )}

                  {userProfileByUserId?.fullName && type=="viewer" ? (
                  <ProfileDetail userProfileByUserId={userProfileByUserId}  userId={userId} type={type}/>
                  ):(
                    <ProfileDetail userProfileByUserId={userProfileByUserId}  userId={userId} type={type}/>
                  )}

                  {userProfileByUserId?.bio !== null && type=="viewer" ? (
                  <ProfileBio userProfileByUserId={userProfileByUserId}  userId={userId} type={type}/>
                  ):(
                    <ProfileBio userProfileByUserId={userProfileByUserId}  userId={userId} type={type}/>
                  )}
                  {/* User contact detail */}
                  {userProfileByUserId?.contact?.length > 0 && type=="viewer" ? (
                    <ProfileContactDetail
                    userProfileByUserId={userProfileByUserId} userId={userId} type={type}
                  />
                  ):(
                    <ProfileContactDetail
                    userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  )}
                  
                </div>
              </div>
              <div className="content-center col-xl-8">
                <div className="about-profile section-b-space">
                {userProfileByUserId?.hobbies?.length > 0 && type=="viewer" ? (
                  <MyProfileHobbies
                    userProfileByUserId={userProfileByUserId} userId={userId} type={type}
                  ></MyProfileHobbies>
                ):(
                  <MyProfileHobbies
                    userProfileByUserId={userProfileByUserId} userId={userId} type={type}
                  ></MyProfileHobbies>
                )}
                {userProfileByUserId?.interests?.length > 0 && type=="viewer" ? (
                  <MyInterest userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                ):(
                  <MyInterest userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                )}

                 {userProfileByUserId?.sport?.length > 0 &&  type=="viewer" ? (
                  <MyPorfileSports userProfileByUserId={userProfileByUserId} userId={userId} type={type}></MyPorfileSports>
                 ):(
                  <MyPorfileSports userProfileByUserId={userProfileByUserId} userId={userId} type={type}></MyPorfileSports>
                 )}

                  {/* <MyMovies /> */}
                  {hasRecords && <MyMovies userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>}
                  {userProfileByUserId?.music?.length > 0 && type=="viewer" ? (
                  <MyMusic userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                   ):(
                    <MyMusic userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                   )}
                  {userProfileByUserId?.book?.length > 0 && (
                  <MyBook userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  )}
                  {userProfileByUserId?.groups?.length > 0 && (
                  <MyGroup userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  )}
                  {userProfileByUserId?.businessPages?.length > 0 && (
                  <MyBizPage userProfileByUserId={userProfileByUserId} userId={userId} type={type}/>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar></RightSidebar>
      </div>

     
    </>
  );
}

