import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Info } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import MultiInput from "./MultiInput";

const MultiChoice = ({ remover, inputId, inputMulti, i, isDisplay,optInc1,removeOneOption1,multiOptionCount2,starFirst,starFirst1,setStarFirst1,setStarFirst }) => {
  const [multiOptionCount, setMultiOptionCount] = useState([1, 2]);
  const [multiValue, setMultiValue] = useState();
  const [dataValue, setDataValue] = useState("")

  const [tempMultiOption, setTempMultiOption] = useState({
    seq1: "",
    seq2: "",
  });
  const multiDataHandler = (e) => {
    const { name, value } = e.target;
    setTempMultiOption({ ...tempMultiOption, [name]: value });
  };
  // ==============singleTextDataHandler===================
  const singleTextDataHandler = (e) => {
    const { name, value } = e.target;
    setTempMultiOption({ ...setTempMultiOption, [name]: value });
    const eventName = "input_lf_" + inputId;
    console.log("cint" + eventName);
    const customEvent = new CustomEvent(eventName, {
      detail: { value },
    });
    document.dispatchEvent(customEvent);
  };
  useEffect(() => {
    const handleCustomEvent = (event) => {
      // Access the message from the custom event's detail property
      const { value } = event.detail;
      console.log(`Received Message: ${value}`);
      setDataValue(value);
    };
    const eventName = "input_lf_" + inputId;
    console.log(eventName);
    document.addEventListener(eventName, handleCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleCustomEvent);
    };

  }, []);
  // ==============singleMultiDataHandler=================
  const singleMultiDataHandler = (e, field) => {
    const { name, value } = e.target;
    setTempMultiOption({ ...setTempMultiOption, [name]: value });
    const eventName = "input_lf_" + inputMulti;
    console.log("cint" + eventName);
    const customEvent = new CustomEvent(eventName, {
      detail: { value },
    });

    document.dispatchEvent(customEvent);

    if (field === 'multi') {
      setMultiValue(value);
    } else if (field === 'second') {
      setSecondValue(value);
    }
  };
  const [multiValues, setMultiValues] = useState(Array(multiOptionCount.length).fill(""));


  // const singleMultiDataHandler = (e, i) => {
  //   const newValue = e.target.value;
  //   setMultiValues(prevMultiValues => {
  //     const newMultiValues = [...prevMultiValues];
  //     newMultiValues[i] = newValue;
  //     return newMultiValues;
  //   });
  // };
  const [secondValue, setSecondValue] = useState('');
  useEffect(() => {
    const handleCustomEvent = (event) => {
      // Access the message from the custom event's detail property
      const { value } = event.detail;
      console.log(`Received Message: ${value}`);
      setMultiValue(value);
      setSecondValue(value);
    };
    const eventName = "input_lf_" + inputMulti;
    console.log(eventName);
    document.addEventListener(eventName, handleCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleCustomEvent);
    };

  }, []);
  // ============singleMultiDataHandler==============
  const optionIncrementHandler = (e) => {
    e.preventDefault();

    console.log("clicked add");
    console.log(multiOptionCount);
    if (multiOptionCount.length < 10) {
      console.log("dispat");
      const nextData = `seq${multiOptionCount.length + 1}`;
      setMultiOptionCount([...multiOptionCount, multiOptionCount.length + 1]);
      setTempMultiOption({ ...tempMultiOption, [nextData]: "" });
      const customEvent = new CustomEvent("addnewmultiplechoice", {
        detail: {},
      });
      document.dispatchEvent(customEvent);

    }
  };
  // ======================add the optionIncrementHandler ===============
  // const optionIncrementHandler = (e, i) => {
  //   e.preventDefault();
  //   if (multiOptionCount.length < 10) {
  //     const nextData = `seq${multiOptionCount.length + 1}`;
  //     setMultiOptionCount([
  //       ...multiOptionCount,
  //       multiOptionCount.length + 1,
  //     ]);
  //     setTempMultiOption({ ...tempMultiOption, [nextData]: "" });
  //   }
  // };
  const [selectedValue, setSelectedValue] = useState('female');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const handleMultipleChoiceCustomEvent = (event) => {
      // Access the message from the custom event's detail property
      const nextData = `seq${multiOptionCount.length + 1}`;
      setMultiOptionCount([...multiOptionCount, multiOptionCount.length + 1]);
      setTempMultiOption({ ...tempMultiOption, [nextData]: "" });

    };
    const eventName = "addnewmultiplechoice";
    console.log(eventName);
    document.addEventListener(eventName, handleMultipleChoiceCustomEvent);

    return () => {
      document.removeEventListener(eventName, handleMultipleChoiceCustomEvent);
    };
  }, []);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const secondDataHandler = (e) => {
    const value = e.target.value;
    setSecondValue(value);
  };
  const placeholderStyle = {
    fontWeight: 'bold',
    color: '#4d4d4d',
    fontSize:'16px'
  };
  return (
    <>
      <div className="row ad-types-of-type-map  mt-4" style={{ margin: '2px' }}>
        <div className="single-ad ">
          <div className="col-lg-12 inputs d-flex p-0">
            <div className="col-lg-12 col-12 mt-3">
              <div className="d-flex lead-switch">
              {isDisplay ? <p className="p-heading">Enter Question</p> : <p className="p-heading"></p>}
                {isDisplay && <p className="lead-greem" role="button" onClick={remover}>
                  Remove
                </p>}
              </div>
              <div className="">
                <input
                  type="text"
                  className={isDisplay ? "form-control p-2" : "form-control p-2 hidebordertext"}
                  placeholder={!starFirst ? "Type the ‘Hint’ here..." : "Type the ‘Hint’ here*"}
                  style={dataValue ? placeholderStyle : {}}
                  maxLength={'32'}
                  value={dataValue}
                  onChange={singleTextDataHandler}
                  readOnly={!isDisplay}
                />
              </div>
              {isDisplay && <p className="p-max-car">Upto 32 Characters</p>}
              <div className="d-flex lead-switch mb-3 mt-3">
                {isDisplay && <p className="p-heading">Add Options</p>}
                {/* {multiOptionCount?.length < 10 && isDisplay && (
                  <p
                    className="lead-greem"
                    role="button"
                    onClick={optionIncrementHandler}
                  >
                    + Add More
                  </p>
                )} */}
                {multiOptionCount?.length < 10 && isDisplay && (
                  <p
                    className="lead-greem"
                    role="button"
                    // onClick={optionIncrementHandler}
                    onClick={optInc1}
                  >
                    + Add More
                  </p>
                )}
              </div>

              {/* <div>
                {multiOptionCount &&
                  multiOptionCount.map((option, i) => {
                    const isFilled = multiValues[i] !== undefined && multiValues[i] !== "";
                    return (
                      <div key={i} className={isDisplay ? "form-control d-flex my-3 position-relative" : "form-control d-flex my-3 position-relative hideborders"}>

                        <Checkbox defaultChecked />
                        <input
                          id={`field-${i}`}
                          i={i}
                          isDisplay={isDisplay}
                          type="text"
                          className="form-control p-2"
                          style={{ border: "none" }}
                          placeholder="Type the ‘Option’ here.."
                          name={`seq${option}`}
                          value={multiValue || ""}
                          onChange={(e) => singleMultiDataHandler(e, i)}
                          readOnly={!isDisplay || isFilled}
                          maxLength={32}
                        />
                        {i >= 2 && !isFilled && (
                          <div className='input-minus' role='button' onClick={() => setMultiOptionCount(multiOptionCount.filter(opt => opt !== option))}></div>
                        )}
                      </div>
                    );
                  })}
              </div> */}

              {multiOptionCount2 &&
                multiOptionCount2.map((option, i, index) => {
                  const isFilled = multiValues[i] !== undefined && multiValues[i] !== "";
                  return (
                    <MultiInput i={i} isDisplay={isDisplay} singleTextDataHandler={singleTextDataHandler} setMultiOptionCount={setMultiOptionCount} isFilled={isFilled} remover={() => remover(`seq${index + 1}`)} removeOneOption1={removeOneOption1}/>);
                })}
              {/* <Checkbox {...label} defaultChecked />
              <div>
                <input
                  type="text"
                  className="form-control p-2"
                  style={{ border: "none" }}
                  placeholder="Type the ‘Option’ here.."
                  value={multiValue}
                  onChange={(e) => singleMultiDataHandler(e, 'multi')}
                  readOnly={!isDisplay}
                  maxLength={32}
                />
                <input
                  type="text"
                  className="form-control p-2"
                  style={{ border: "none" }}
                  placeholder="Type the ‘Option’ here.."
                  value={secondValue}
                  onChange={secondDataHandler}
                  readOnly={!isDisplay}
                  maxLength={32}
                />
              </div> */}
              {/* // value={tempMultiOption[`seq${option}`]} */}
              <div className="mt-4 mb-3">
                <div className="d-flex lead-switch">
                  {isDisplay && <p className="p-heading">
                    Mandatory Question
                    <span className="pl-1">
                      <Info fill='#828282' id='plTooltip' color='white' size={18} />
                      <UncontrolledTooltip placement='right' target='plTooltip'>
                        mandatory question field
                      </UncontrolledTooltip>
                    </span>
                  </p>}
                  {isDisplay && <label className="switch">
                    <input type="checkbox" checked={starFirst1} onChange={() => { setStarFirst((prev) => !prev); setStarFirst1((prev) => !prev) }} />
                    <span className="slider round"></span>
                  </label>}
                </div>
                {isDisplay && <p>Add a star mark to indicate mandatory question field</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiChoice;