import React, { useEffect, useState } from 'react'
import { Circle, Folder, Grid, MoreHorizontal } from 'react-feather'
import { Link } from 'react-router-dom'
import { Card, Col, Input, InputGroup, InputGroupText, Row } from 'reactstrap'
import AdmanagerHeaderR from '../../AdmanagerHeaderR/AdmanagerHeaderR'
import DatePicker from 'react-datepicker';
const TotalBudgetScreen = () => {
    const [visitCount, setVisitCount] = useState(0);
    const [adText, setAdText] = useState('');

    useEffect(() => {
        const initializeAd = () => {
            // Get visit count from local storage
            const storedVisitCount = parseInt(localStorage.getItem('visitCount'), 10) || 0;

            const adOptions = [
                `${localStorage.getItem("adTypeName")}/  Single Image Ad  /  Choose Your Audience  /  Budget & Ad duration`,
                `${localStorage.getItem("adTypeName")}/  Carousel Image Ad  /  Choose Your Audience  /  Budget & Ad duration`,
                `${localStorage.getItem("adTypeName")}/  Video Ad  /  Choose Your Audience  /  Budget & Ad duration`,
                `${localStorage.getItem("adTypeName")}/  Full Screen Video Ad  /  Choose Your Audience  /  Budget & Ad duration`,
            ];

            const newVisitCount = (storedVisitCount + 1) % adOptions.length;
            setVisitCount(newVisitCount);

            const newAdText =
                newVisitCount < adOptions.length
                    ? adOptions[newVisitCount]
                    : 'Default Ad Text';

            setAdText(adOptions[localStorage.getItem("adTypeName") !== "Video Views - CPV" ? storedVisitCount : storedVisitCount + 2]);
            // localStorage.setItem('visitCount', newVisitCount);
        };

        initializeAd();
    }, []);
    const [dailyBudget, setDailyBudget] = useState('');
    const [startingDate, setStartingDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Enable the button when all required fields are filled
    const isButtonDisabled = dailyBudget === '' || startingDate === '' || endDate === '';

    const handleDailyBudgetChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setDailyBudget(value);
    };
    const [selectedDate, setSelectedDate] = useState(null);
    const calculateDateDifference = () => {
        if (startingDate && endDate) {
            const timeDifference = endDate.getTime() - startingDate.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            return daysDifference;
        }
        return 0;
    };

    const daysDifference = calculateDateDifference();
    return (
        // ===================================start ad code=========================
        <>
            <div>
                <Row>
                    <Col xl='2'>
                        <Card className='d-none d-sm-block border-right'>
                            {/* min-vh-100  */}
                            <div className="p-4 text-muted border ">
                                <div className='text-dark pb-1'><h6>Step 1</h6></div>
                                <div className='text-dark'><h5><b>Select Campaign Type</b></h5></div>
                                {/* <div className='text-dark'><p>Choose Campaign Type (e.g., Display, Video, App, etc.)</p></div> */}
                            </div>
                            <div disabled className="p-4 border text-muted ">
                                <div className='pb-1'><h6>Step 2</h6></div>
                                <div className='text-dark'><h5><b>Select Ad Format</b></h5></div>
                            </div>
                            <div disabled className="p-4 border text-muted">
                                <div className='pb-1'><h6>Step 3</h6></div>
                                <div className='text-dark pb-2'><h5><b>Set up Ad Campaign</b></h5></div>
                                <div className="paddingForStyle">
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad Heading</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad SubHeading</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Ad Discription</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Add Creatives For Your Audience</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Add Lead Generation Form</p>
                                </div>
                            </div>
                            <div disabled className="p-4 border text-muted">
                                <div className='pb-1'><h6>Step 4</h6></div>
                                <div className='text-dark pb-2'><h5><b>Select Ad Target Audience</b></h5></div>
                                <div className="paddingForStyle">
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Gender</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Age Range</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Profession</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Intrests</p>
                                    <p><Circle size={5} fill='#4D4D4D' /> Target Location</p>
                                </div>
                            </div>
                            <div disabled className="p-4 borderLeftForDasboard ">
                                <div className='pb-1'><h6>Step 5</h6></div>
                                <div className=''><h5><b>Select Ad Budget & Duration</b></h5></div>
                            </div>
                            <div disabled className="p-4 border text-muted disabledbutton ">
                                <div className='pb-1'><h6>Step 6</h6></div>
                                <div className=''><h5><b>Preview Ad & Launch</b></h5></div>
                            </div>
                        </Card>
                    </Col>
                    <Col xl='10' className='px-2'>
                        {/* <Row className='rowForrevert'>
                            <Col xl='12'>
                                <Card className='border-0'>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center p-2 border-right">
                                            <button className="ad-count-btn">5</button>
                                            <p className="ad-count-step px-3" style={{ textWrap: 'nowrap' }}>Step 5</p>
                                        </div>
                                        <div className="p-2 pl-3">
                                            <h4>Budget & Ad duration</h4>
                                            <p className="text-muted">Assign a budget and duration for your Ad</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row> */}
                        <Row className='rowForrevert'>
                            <Col xl='12'>
                                <Card className=' shadow border-0'>
                                    <div className="d-flex">
                                        <div className="d-flex align-items-center p-2 border-right">
                                            <button className="ad-count-btn">5</button>
                                            <p className="ad-count-step px-3" style={{ textWrap: 'nowrap' }}>Step 5</p>
                                        </div>
                                        <div className="p-2 pl-1">
                                            <h4>Budget & Ad duration</h4>
                                            <p className="text-muted">budget and duration for your Ad</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <div className='shadow'>
                            <Card className=' border-0 my-1 p-5'>
                                <div className="row">
                                    <div className="single-ad pad-zero">
                                        <div className="navigate col-lg-12">
                                            <div className="row">
                                                <div className="navigate-left col-lg-12">
                                                    {/* <h4 className='navigate-color'>Website ( Link) Visits- CPC  /  Single Image Ad  /  Choose Your Audience  /  Budget & Ad duration</h4> */}
                                                    <div className={`ad-container`}>
                                                        <h4 className='navigate-color'>{adText}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 inputs d-flex p-0">
                                            <div className='col-lg-12 col-12'>
                                                <div className="">
                                                    <div className="ad-heading-upsd-title-input">
                                                        <p className='p-heading'>Your daily ad budget</p>
                                                    </div>
                                                    <div className="">
                                                        <InputGroup>
                                                            <InputGroupText className='bg-transparent text-primary'>
                                                                $
                                                            </InputGroupText>
                                                            <Input
                                                                type="number"
                                                                className='border-left-0'
                                                                placeholder="Enter your daily budget"
                                                                value={dailyBudget}
                                                                onInput={handleDailyBudgetChange}
                                                            />
                                                        </InputGroup>
                                                    </div>
                                                    <p className="p-max-car">Minimum daily budget is $20/- only</p>
                                                </div>
                                                <div className="">
                                                    <div className="ad-heading-upsd-title-input">
                                                        <p className='p-heading'>Choose the duration of ad</p>
                                                    </div>
                                                    <Row>
                                                        <Col xl='8'>
                                                            <div className='d-flex align-items-end justify-content-around'>
                                                                <div className="w-100">
                                                                    <p>Starting Date</p>
                                                                    {/* <Input
                                                                        type="date"
                                                                        className='p-2 form-control'
                                                                        onChange={(e) => setStartingDate(e.target.value)}
                                                                    /> */}
                                                                    <DatePicker
                                                                        selected={startingDate}
                                                                        onChange={date => setStartingDate(date)}
                                                                        placeholderText="dd/mm/yyyy"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                &nbsp;
                                                                <div className="w-100">
                                                                    <p>To</p>
                                                                    {/* <Input
                                                                        type="date"
                                                                        className='form-control p-2'
                                                                        onChange={(e) => setEndDate(e.target.value)}
                                                                    /> */}
                                                                    <DatePicker
                                                                        selected={endDate}
                                                                        onChange={date => setEndDate(date)}
                                                                        placeholderText="dd/mm/yyyy"
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm='4' className='AdDurationForStyle mt-2'>
                                                            <div className="m-2">
                                                                <h4>Ad Duration</h4>
                                                                {daysDifference !== null && daysDifference >= 0 && (
                                                                    <h4>{daysDifference} {daysDifference === 1 ? 'Day' : 'Days'}</h4>
                                                                )}
                                                                {daysDifference !== null && daysDifference < 0 && (
                                                                    <h4 className="ml-1" style={{color:'#4d4d4d',fontWeight:'500'}}>----</h4>
                                                                )}
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="">
                                                    <div className="ad-heading-upsd-title-input total-amount">
                                                        <p className='p-heading'>Total Amount to be paid</p>
                                                        <h2 style={{ fontSize: '30px' }}>$00.00</h2>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <div className="ad-btn">
                                                        <Link
                                                            to={isButtonDisabled ? '#' : '/Ad/AdPreview'} // Disable link when button is disabled
                                                            className={`btn-next ${isButtonDisabled ? 'disabled' : ''}`}
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor: isButtonDisabled ? '#ACDAD6' : '#81C14B',
                                                            }}
                                                        >
                                                            Preview Ad
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TotalBudgetScreen