// import React, { useState } from "react";
// import { Fragment } from "react";
// import {
//   ChevronDown,
//   ChevronUp,
//   Clock,
//   Globe,
//   Mail,
//   MapPin,
//   Phone,
// } from "react-feather";
// import { Link, useParams } from "react-router-dom";
// import Pen1 from "../profile-components/Img/Pen1.png";
// import mapboxgl from "mapbox-gl";
// import axios from "axios";
// import { useEffect } from "react";
// import { getAboutUsAction } from "../../Services/Actions/UserProfile/getAllUserProfilePageAction";
// import { useDispatch, useSelector } from "react-redux";
// mapboxgl.accessToken =
//   "pk.eyJ1IjoiNzQ4NSIsImEiOiJjbDFua3kwcHIwMDE1M2luMXhleDNqMGZiIn0.Mj40f5LXER6tUfR3ygQLaA";

// const LeftAllDataArray = [
//   {
//     typeof: "Address",
//     address: "Address 1",
//     // link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
//     link: (
//       <iframe
//         src=""
//         width="600"
//         height="450"
//         allowfullscreen=""
//         loading="lazy"
//         referrerpolicy="no-referrer-when-downgrade"
//       ></iframe>
//     ),
//     location: "Dybdevej 10, 5200 Odense, Denmark****",
//   },
//   {
//     typeof: "Address",
//     address: "Address 2",
//     link: (
//       <iframe
//         src=""
//         width="600"
//         height="450"
//         allowfullscreen=""
//         loading="lazy"
//         referrerpolicy="no-referrer-when-downgrade"
//       ></iframe>
//     ),
//     location: "Dybdevej 10, 5200 Odense, New York",
//   },
//   {
//     typeof: "Timing",
//   },
//   {
//     typeof: "Website",
//     title: "Website",
//     icon: <Globe color="#81C14B" size={20} />,
//     link1: "www.behance.net",
//     link2: "www.gihub.net",
//   },
//   {
//     typeof: "Website",
//     title: "Email",
//     icon: <Mail color="#81C14B" size={20} />,
//     link1: "email@gmail.com",
//     link2: "info@sociomee.com",
//   },
//   {
//     typeof: "Contact",
//     title: "Contact",
//     number: "+91 987 654 3210",
//   },
// ];
// const LeftSideCmponents = (props) => {
//   const [tymShow, setTymShow] = useState(false);
//   let dispatch = useDispatch();
//   const { id } = useParams();

//   const getAbout = useSelector((state) => {
//     return state.getAboutUsData;
//   });
//   const {
//     loading: loadingGetAbout,
//     error: errorGetAbout,
//     result: resultGetAbout,
//   } = getAbout;

//   const bpLocations = resultGetAbout?.map((item) => item.bpLocations).flat();
//   const bpOperatingHours = resultGetAbout
//     ?.map((item) => item.bpOperatingHours)
//     .flat();
//   const bpWebSitesAddress = resultGetAbout
//     ?.map((item) => item.bpWebsites)
//     .flat();

//     const bpEmail = resultGetAbout
//     ?.map((item) => item.phone)
//     .flat();

   

//   const today = new Date();
//   const dayOfWeek = today.toLocaleString("default", { weekday: "long" });

//   const dayOperatingHours = resultGetAbout
//     ?.map((item) => item.bpOperatingHours)
//     .flat()
//     .filter((item) => item.dayName === dayOfWeek);

//   resultGetAbout?.map((item) => {
//     item.bpOperatingHours.map((operatingHour) => {
//       console.log("operatingHour", operatingHour?.startingTimeShift1);
//       console.log("operatingHour", operatingHour?.endingTimeShift3);
//     });
//   });

//   bpWebSitesAddress?.map((item) => {
//     console.log("webSite", item?.websiteUrl);
//   });

//   const [openClose, setOpenClose] = useState(null);

//   const currentTime = new Date().getTime();

//   dayOperatingHours?.forEach((operatingHour) => {
//     const startingTime = new Date(operatingHour?.startingTimeShift1);

//     // Check if current time is between 9:00 AM and 11:00 PM
//     const openingTime = new Date(
//       startingTime.getFullYear(),
//       startingTime.getMonth(),
//       startingTime.getDate(),
//       12,
//       0,
//       0
//     );
//     const closingTime = new Date(
//       startingTime.getFullYear(),
//       startingTime.getMonth(),
//       startingTime.getDate(),
//       23,
//       0,
//       0
//     );

//     if (
//       currentTime < openingTime.getTime() ||
//       currentTime > closingTime.getTime()
//     ) {
//       setOpenClose("CLOSED");
//       return; // exit the loop if openClose is already determined
//     }
//   });

//   if (openClose === null) {
//     setOpenClose("OPEN");
//   }

//   useEffect(() => {
//     dispatch(getAboutUsAction(id));
//   }, []);

//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition((bpLocations) => {
//       const map = new mapboxgl.Map({
//         container: "map",
//         style: "mapbox://styles/mapbox/streets-v11",
//         center: [bpLocations?.coords?.longitude, bpLocations?.coords?.latitude],
//         zoom: 14,
//       });

//       const marker = new mapboxgl.Marker()
//         .setLngLat([
//           bpLocations?.coords?.longitude,
//           bpLocations?.coords?.latitude,
//         ])
//         .addTo(map);

//       const geolocate = new mapboxgl.GeolocateControl({
//         positionOptions: {
//           enableHighAccuracy: true,
//         },
//         trackUserLocation: true,
//       });

//       const reverseGeocode = async (longitude, latitude) => {
//         const geocodingEndpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`;
//         const accessToken =
//           "pk.eyJ1IjoiNzQ4NSIsImEiOiJjbDFua3kwcHIwMDE1M2luMXhleDNqMGZiIn0.Mj40f5LXER6tUfR3ygQLaA";
//         const requestUrl = `${geocodingEndpoint}?access_token=${accessToken}`;
//         try {
//           const response = await fetch(requestUrl);
//           if (response.ok) {
//             const address = await response.json();
//             console.log("address*", address);
//             const cityComponent = address.features?.[0]?.context?.find(
//               (component) =>
//                 component?.id?.startsWith("place") &&
//                 (component?.text || component?.short_code)
//             );
//             const cityName = cityComponent
//               ? cityComponent.text || cityComponent.short_code
//               : "unknown";
//           } else {
//             throw new Error(
//               `Error fetching address. Status: ${response.status}.`
//             );
//           }
//         } catch (error) {
//           console.error(error);
//         }
//       };

//       reverseGeocode(bpLocations.coords.longitude, bpLocations.coords.latitude);
//       map.addControl(geolocate);
//     });
//   }, []);

//   //====================== Main_return_section ===================//
//   return (
//     <Fragment>
//       <div className="custom-card-block ml-4 mr-4">
//         <div className="BizPage_leftSide_Heading biz-carddetail-blk">
//           <h3>{props.title}</h3>
//         </div>
//         <div id="map" style={{ height: "200px" }}></div>

//         <div className="bizabout-location  mb-4 mt-4">
//           <div className="BizAbout_bottom_section">
//             <div className="Biz_Icon">
//               <MapPin color="#81C14B" size={20} />
//             </div>
//             <div className="ml-3 BizAbout_Detail">
//               <h6>
//                 Address 1 <br /> {bpLocations?.[0].address}
//               </h6>
//               <h6>
//                 {bpLocations?.[0].city} {bpLocations?.[0].country}{" "}
//                 {bpLocations?.[0].zipCode}
//               </h6>
//             </div>
//           </div>
//         </div>
//         <hr />

//         <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d${bpLocations?.[0].locationLONG}!3d${bpLocations?.[0].locationLAT}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2s${bpLocations?.[0].city}j%2010%2C%205200%20Odense%2C%20${bpLocations?.[0].country}!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin"
//                 width="100%"
//                 height="200"
//                 allowfullscreen=""
//                 loading="lazy"
//                 referrerpolicy="no-referrer-when-downgrade"
//                 ></iframe>

//         <div className="bizabout-location  mb-4 mt-4">
//           <div className="BizAbout_bottom_section">
//             <div className="Biz_Icon">
//               <MapPin color="#81C14B" size={20} />
//             </div>
//             <div className="ml-3 BizAbout_Detail">
//               <h6>
//                 Address 2 <br /> {bpLocations?.[0].address}
//               </h6>
//               <h6>
//                 {bpLocations?.[0].city} {bpLocations?.[0].country}{" "}
//                 {bpLocations?.[0].zipCode}
//               </h6>
//             </div>
//           </div>
//         </div>
//         <hr />

//         <div className="BizAbout_blk_section   mb-4 mt-4">
//           <div className="BizAbout_Icon">
//             <Clock color="#81C14B" size={20} />
//           </div>
//           <div className="BizDetails">
//             <div className="d-flex">
//               <div className="me-auto">
//                 <h6>
//                   Timing
//                   <small className="green">
//                     <srong>
//                       {openClose === "OPEN" ? (
//                         <span style={{ fontWeight: "bold" }}> Open Now</span>
//                       ) : (
//                         <span style={{ fontWeight: "bold", color: "red" }}>
//                           {" "}
//                           Close Now
//                         </span>
//                       )}
//                     </srong>
//                   </small>
//                 </h6>
//                 <h6>
//                   <span className="green">
//                     {dayOperatingHours &&
//                     dayOperatingHours[0] &&
//                     dayOperatingHours[0].startingTimeShift1 &&
//                     dayOperatingHours[0].endingTimeShift3 ? (
//                       <span>
//                         {dayOperatingHours[0].dayName}&nbsp;&nbsp;
//                         {dayOperatingHours[0].startingTimeShift1} - {" "}
//                         {dayOperatingHours[0].endingTimeShift3}
//                       </span>
//                     ) : (
//                       <span>No operating hours available</span>
//                     )}

//                     <span onClick={() => setTymShow(!tymShow)}>
//                       {" "}
//                       {!tymShow ? (
//                         <ChevronDown size={18} />
//                       ) : (
//                         <ChevronUp size={18} />
//                       )}
//                     </span>
//                   </span>
//                 </h6>
//                 <div>
//                   {tymShow ? (
//                     <Fragment>
//                       <hr className="text-muted" />
//                       <div
//                         className="BizDetailTymList"
//                         style={{
//                           display: "flex",
//                           flexDirection: "column",
//                           fontSize: "14px",
//                           fontWeight: "bold",
//                           color: "#404040",
//                         }}
//                       >
//                         <table>
//                           {resultGetAbout?.map((item) => {
//                             return item.bpOperatingHours.map(
//                               (operatingHour) => (
//                                 <tr key={operatingHour.dayName}>
//                                   <td>
//                                     <span className="green BizTymListDay">
//                                       {operatingHour.dayName}
//                                     </span>
//                                   </td>
//                                   <td>
//                                     <span className="ml-3">
//                                       {operatingHour.startingTimeShift1} -{" "}
//                                       {operatingHour.endingTimeShift3}
//                                     </span>
//                                   </td>
//                                 </tr>
//                               )
//                             );
//                           })}
//                         </table>
//                       </div>
//                     </Fragment>
//                   ) : null}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <hr />
//         <div className="BizAbout_blk_section   mb-4 mt-4">
//           <div className="BizAbout_Icon">
//             <Globe color="#81C14B" size={20} />
//           </div>
//           <div className="BizDetails">
//             <div className="d-flex">
//               <div className="me-auto">
//                 <h6>Web sites</h6>
//                 <h6>
//                   {bpWebSitesAddress?.map((item) => {
//                     return (
//                       <>
//                         <Link className="OverView_BizLink" to="#">
//                           {item?.websiteUrl}
//                         </Link>{" "}
//                         <br />
//                       </>
//                     );
//                   })}
//                 </h6>
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr />

//         <div className="BizAbout_blk_section   mb-4 mt-4">
//           <div className="BizAbout_Icon">
//             <Mail color="#81C14B" size={20} />
//           </div>
//           <div className="BizDetails">
//             <div className="d-flex">
//               <div className="me-auto">
//                 <h6>Email</h6>
//                 <h6>
//                   {bpEmail?.map((item) => {
//                     return (
//                       <>
//                         <Link className="OverView_BizLink" to="#">
//                           {item?.emailAddress ? item?.emailAddress : 'No mail to show'}
//                         </Link>{" "}
//                         <br />
//                       </>
//                     );
//                   })}
//                 </h6>
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr />
//         <div className="BizAbout_blk_section   mb-4 mt-4">
//           <div className="BizAbout_Icon">
//           <Phone color="#81C14B" size={20} />
//           </div>
//           <div className="BizDetails">
//             <div className="d-flex">
//               <div className="me-auto">
//                 <h6>Contact</h6>
//                 <h6>
//                   {bpEmail?.map((item) => {
//                     return (
//                       <>
//                         <Link className="OverView_BizLink" to="#">
//                           {item?.mobile ? item?.mobile : 'No phone to show'}
//                         </Link>{" "}
//                         <br />
//                       </>
//                     );
//                   })}
//                 </h6>
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr />
//         {/* <div className="bizAbout_cont_section">
//           <ul>
//             {LeftAllDataArray.map((data, index) => (
//               <>
//                 {data.typeof === "Address" ? (
//                   <>
//                     <li>
//                       <div className="bizabout-location" key={index}>
//                         {data.link}
//                         <div className="BizAbout_bottom_section">
//                           <div className="Biz_Icon">
//                             <MapPin color="#81C14B" size={20} />
//                           </div>
//                           <div className="ml-3 BizAbout_Detail">
//                             <h5>{data.address}</h5>
//                             <h6>{data.location}</h6>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   </>
//                 ) : data.typeof === "Timing" ? (
//                   <>
//                     <li>
//                       <div className="BizAbout_blk_section">
//                         <div className="BizAbout_Icon">
//                           <Clock color="#81C14B" size={20} />
//                         </div>
//                         <div className="BizDetails">
//                           <div className="d-flex">
//                             <div className="me-auto">
//                               <h5>Timing</h5>
//                               <h6>
//                                 <span className="green">Open now</span>
//                                 &nbsp;&nbsp; 9:00AM - 6:00PM
//                                 <span onClick={() => setTymShow(!tymShow)}>
//                                   {" "}
//                                   {!tymShow ? (
//                                     <ChevronDown size={18} />
//                                   ) : (
//                                     <ChevronUp size={18} />
//                                   )}
//                                 </span>
//                               </h6>
//                               <div>
//                                 {tymShow ? (
//                                   <Fragment>
//                                     <hr className="text-muted" />
//                                     <div className="BizDetailTymList ">
//                                       <span className="green BizTymListDay">
//                                         Monday
//                                       </span>
//                                       <h6 className="ml-2 ">9:00AM - 6:00PM</h6>
//                                       <span className="green BizTymListDay">
//                                         Tuesday
//                                       </span>
//                                       <h6 className="ml-2 ">9:00AM - 6:00PM</h6>
//                                       <span className="green BizTymListDay">
//                                         Wednesday
//                                       </span>
//                                       <h6 className="ml-2 ">9:00AM - 6:00PM</h6>
//                                       <span className="green BizTymListDay">
//                                         Thursday
//                                       </span>
//                                       <h6 className="ml-2 ">9:00AM - 6:00PM</h6>
//                                       <span className="green BizTymListDay">
//                                         Friday
//                                       </span>
//                                       <h6 className="ml-2 ">9:00AM - 6:00PM</h6>
//                                       <span className="green BizTymListDay">
//                                         Saturday
//                                       </span>
//                                       <h6 className="ml-2 ">9:00AM - 6:00PM</h6>
//                                     </div>
//                                   </Fragment>
//                                 ) : null}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   </>
//                 ) : data.typeof === "Website" ? (
//                   <>
//                     <li>
//                       <div className="BizAbout_blk_section">
//                         <div className="BizAbout_Icon">{data.icon}</div>
//                         <div className="BizDetails">
//                           <div className="d-flex">
//                             <div className="me-auto">
//                               <h5>{data.title}</h5>
//                               <h6>
//                                 <Link className="OverView_BizLink" to="#">
//                                   {data.link1}
//                                 </Link>
//                                 <br />
//                                 <Link className="OverView_BizLink" to="#">
//                                   {data.link2}
//                                 </Link>
//                               </h6>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   </>
//                 ) : data.typeof === "Contact" ? (
//                   <>
//                     <li>
//                       <div className="BizAbout_blk_section">
//                         <div className="BizAbout_Icon">
//                           <Phone color="#81C14B" size={20} />
//                         </div>
//                         <div className="BizDetails">
//                           <div className="d-flex">
//                             <div className="me-auto">
//                               <h5>{data.title}</h5>
//                               <h6>{data.number}</h6>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   </>
//                 ) : null}
//               </>
//             ))}
//           </ul>
//         </div> */}
//       </div>
//     </Fragment>
//   );
// };
// export default LeftSideCmponents;

import React from 'react'
import { Fragment } from 'react'
import { Clock, Globe, Mail, MapPin, Phone } from 'react-feather'
import { Link } from 'react-router-dom'
import Pen1 from '../profile-components/Img/Pen1.png'

const LeftAllDataArray = [
    {
        typeof: "Address",
        link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
        location: "Dybdevej 10, 5200 Odense, Denmark",
    },
    {
        typeof: "Address",
        link: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2266.133469088304!2d10.344347315868879!3d55.39057698045794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464cdfc6c8acf0bd%3A0xc9e5ab1219682ea!2sDybdevej%2010%2C%205200%20Odense%2C%20Denmark!5e0!3m2!1sen!2sin!4v1656575287386!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>,
        location: "Dybdevej 10, 5200 Odense, New York",
    },
    {
        typeof: "Timing"
    },
    {
        typeof: "Website",
        title: "Website",
        icon: <Globe color='#81C14B' size={20} />,
        link1: "www.behance.net",
        link2: "www.gihub.net",



    },
    {
        typeof: "Website",
        title: "Email",
        icon: <Mail color='#81C14B' size={20} />,
        link1: "email@gmail.com",
        link2: "info@sociomee.com",

    },
    {
        typeof: "Contact",
        title: "Contact",
        number: "+91 987 654 3210"
    }
]
const LeftSideCmponents = () => {
    return (
        <Fragment>
            <div className="custom-card-block "  >
                <div className="custom-card-head biz-carddetail-blk">
                    <h4 className='lscbizdetail'>Address</h4>
                </div>
                <div className="bizAbout_cont_section">
                    <ul>
                        {
                            LeftAllDataArray.map((data, index) => (
                                <>
                                    {data.typeof === "Address" ?
                                        <>
                                            <li>
                                                <div className="bizabout-location" key={index} >
                                                    {data.link}
                                                    <div className='BizAbout_bottom_section'>
                                                        <div className='Biz_Icon'>
                                                            <MapPin color='#81C14B' size={20} />
                                                        </div>
                                                        <div className='ml-3 BizAbout_Detail'>
                                                            <h5>{data.location}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </> : (data.typeof === "Timing") ?
                                            <>
                                                <li>
                                                    <div className="BizAbout_blk_section" >
                                                        <div className="BizAbout_Icon">
                                                            <Clock color='#81C14B' size={20} />
                                                        </div>
                                                        <div className="BizDetails">
                                                            <div className='d-flex'>
                                                                <div className='me-auto'>
                                                                    <h5  className='lscbizdetail'>Timing</h5>
                                                                    <h6><span className="green">Open now</span>&nbsp;&nbsp; 9:00AM - 6:00PM</h6>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </> : (data.typeof === "Website") ?
                                                <>
                                                    <li>
                                                        <div className="BizAbout_blk_section" >
                                                            <div className="BizAbout_Icon">
                                                                {data.icon}
                                                            </div>
                                                            <div className="BizDetails">
                                                                <div className='d-flex'>
                                                                    <div className='me-auto'>
                                                                        <h5  className='lscbizdetail'>{data.title}</h5>
                                                                        <h6 ><Link className='BizLink' to="#">{data.link1}</Link><br />
                                                                            <Link className='BizLink' to="#">{data.link2}</Link></h6>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </> : (data.typeof === "Contact") ?
                                                    <>
                                                        <li>
                                                            <div className="BizAbout_blk_section" >
                                                                <div className="BizAbout_Icon">
                                                                    <Phone color='#81C14B' size={20} />
                                                                </div>
                                                                <div className="BizDetails">
                                                                    <div className='d-flex'>
                                                                        <div className='me-auto'>
                                                                            <h5  className='lscbizdetail'>{data.title}</h5>
                                                                            <span >{data.number}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </> : null
                                    }
                                </>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default LeftSideCmponents