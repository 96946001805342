import React from 'react'
import { Fragment } from 'react'
import { Card, CardImg } from 'react-bootstrap'
import Sprt from '../../profile-components/Img/sport.png'
import Slider from 'react-slick'

const CradTypeData = (props) => {

    let sportSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const SliderData = [
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Mughal-e-Aazam",
            musicName: "AR Rahman",
            bookName: "The Great Gatsby"

        },
        {
            img: Sprt,
            sportName: "Cricket",
            movieName: "Shole",
            musicName: "Michael Jackson",
            bookName: "Alice's Adventure in Wonderland"

        },
        {
            img: Sprt,
            sportName: "Hockey",
            movieName: "PK",
            musicName: "Kishan Maharaj",
            bookName: "Adventures of Huckleberry Finn"

        },
        {
            img: Sprt,
            sportName: "Golf",
            movieName: "Raees",
            musicName: "Ustad Bismillah Khan",
            bookName: "Pride and Prejudice"
        },
        {
            img: Sprt,
            sportName: "Cricket",
            movieName: "Shaun of the Dead",
            musicName: "Aasha Bhosle",
            bookName: "The Catcher in the Rye."
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },
        {
            img: Sprt,
            sportName: "Footboll",
            movieName: "Dil Wale Dulhaniya le jaye ge",
            musicName: "Lata Mangeshkar",
            bookName: "Ulysses"
        },

    ]
    //=================== Main_retun_function ====================//
    return (
        <Fragment>
            <div className="setting_Group_blk_section">
                <div className="Settings_card-title">
                    <h3>{props.title}</h3>
                </div>
                <div className="card-block-box">
                    <Slider {...sportSettings} className="default-space">
                        {
                            SliderData.map((data) => (
                                <div>
                                    <Card className="profile-slide-box">
                                        <CardImg
                                            alt="Card image cap"
                                            src={data.img}
                                            width="100%"
                                            style={{ height: '200px' }}

                                        />
                                        <div className="story-content">
                                            <h6>{data.movieName}</h6>
                                        </div>
                                    </Card>

                                </div>
                            ))
                        }


                    </Slider>
                </div>

            </div>
        </Fragment>
    )
}

export default CradTypeData