import { Book, Camera, Move, Music, Paperclip, PenTool } from "react-feather"

export const Gender = [
    {
        id: "a",
        name: "Male"
    },
    {
        id: "b",
        name: "Female"
    },
    {
        id: "c",
        name: "Other"
    },
]


export const OtherGenders = [
    {
        id: "a",
        name: "He"
    },
    {
        id: "b",
        name: "She"
    },
    {
        id: "c",
        name: "Rather not specify"
    },
]


export const InterestDetails = [
    {
        id: "1",
        name: "Photography",
        icon: <Camera size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "2",
        name: "Books",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "3",
        name: "Reading",
        icon: <Paperclip size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "4",
        name: "Songs",
        icon: <Music size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "5",
        name: "Travelling",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "6",
        name: "Writing",
        icon: <PenTool size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "7",
        name: "Philosophy",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "8",
        name: "Astrology",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "9",
        name: "Animator",
        icon: <Move size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "10",
        name: "Animals",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "11",
        name: "Acting",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "12",
        name: "Sleeping",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "13",
        name: "Dancing",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "14",
        name: "Story",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "15",
        name: "Food",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "16",
        name: "Gaming",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "17",
        name: "Health",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "18",
        name: "Fashion",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "19",
        name: "Cooking",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "20",
        name: "Director",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "21",
        name: "Architect ",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "22",
        name: "Movies",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "23",
        name: "Books",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "24",
        name: "Reading",
        icon: <Paperclip size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "25",
        name: "Songs",
        icon: <Music size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "26",
        name: "Travelling",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "27",
        name: "Writing",
        icon: <PenTool size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "28",
        name: "Philosophy",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "29",
        name: "Astrology",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "30",
        name: "Animator",
        icon: <Move size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "32",
        name: "Animals",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "32",
        name: "Acting",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "33",
        name: "Sleeping",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "34",
        name: "Dancing",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
    {
        id: "35",
        name: "Story",
        icon: <Book size={15} color="#fff" fill="#4D4D4D" />
    },
]





export const LanguagesAll = [
    "English",
    "Afrikaans",
    "Deutsch",
    "Corsu",
    "Македонски",
    "Creole",
    "Allemand",
    "Grec",
    "Hebreu",
    "Hindi",
    "Hongrois",
    "Indonésien",
    "Italien",
    "Japonais",
    "Javanais",
    "Coréen",
    "Norvégien",
    "Polonais",
    "Portugais",
    "Roumain",
    "Russe",
    "Serbe",
    "Slovaque",
    "Slovène",
    "Espagnol",
    "Suédois",
    "Télougou",
    "Thaï",
    "Turc",
    "Ukrainien",
    "Vietnamien",
    "Gallois",
    "Langue des signes",
    "Algérien",
    "Araméen",
    "Arménien",
    "Berbère",
    "Birman",
    "Bosniaque",
    "Brésilien",
    "Bulgare",
    "Chypriote",
    "Corse",
    "Créole",
    "Ecossais",
    "Egyptien",
    "Espéranto",
    "Estonien",
    "Finnois",
    "Flamand",
    "Géorgien",
    "Hawaïen",
    "Indonésien",
    "Inuit",
    "Irlandais",
    "Islandais",
    "Latin",
    "Mandarin",
    "Népalais",
    "Sanskrit",
    "Tagalog",
    "Tahitien",
    "Tibétain",
    "Tsigane",
    "Bulgare",
    "Catalan",
    "Cantonais",
    "Croate",
    "Tchèque",
    "Danois",
    "Hollandais",
    "Lituanien",
    "Malais",
    "Malayalam",
    "Panjabi",
    "Tamoul",
    "Anglais",
    "Finlandais",
    "Français",
    "English",
    "Afrikaans",
    "Deutsch",
    "Corsu",
    "Македонски",
    "Creole",
    "Allemand",
    "Grec",
    "Hebreu",
    "Hindi",
    "Hongrois",
    "Indonésien",
    "Italien",
    "Japonais",
    "Javanais",
    "Coréen",
    "Norvégien",
    "Polonais",
    "Portugais",
    "Roumain",
    "Russe",
    "Serbe",
    "Slovaque",
    "Slovène",
    "Espagnol",
    "Suédois",
    "Télougou",
    "Thaï",
    "Turc",
    "Ukrainien",
    "Vietnamien",
    "Gallois",
    "Langue des signes",
    "Algérien",
    "Araméen",
    "Arménien",
    "Berbère",
    "Birman",
    "Bosniaque",
    "Brésilien",
    "Bulgare",
    "Chypriote",
    "Corse",
    "Créole",
    "Ecossais",
    "Egyptien",
    "Espéranto",
    "Estonien",
    "Finnois",
    "Flamand",
    "Géorgien",
    "Hawaïen",
    "Indonésien",
    "Inuit",
    "Irlandais",
    "Islandais",
    "Latin",
    "Mandarin",
    "Népalais",
    "Sanskrit",
    "Tagalog",
    "Tahitien",
    "Tibétain",
    "Tsigane",
    "Bulgare",
    "Catalan",
    "Cantonais",
    "Croate",
    "Tchèque",
    "Danois",
    "Hollandais",
    "Lituanien",
    "Malais",
    "Malayalam",
    "Panjabi",
    "Tamoul",
    "Anglais",
    "Finlandais",
    "Français",
    "English",
    "Afrikaans",
    "Deutsch",
    "Corsu",
    "Македонски",
    "Creole",
    "Allemand",
    "Grec",
    "Hebreu",
    "Hindi",
    "Hongrois",
    "Indonésien",
    "Italien",
    "Japonais",
    "Javanais",
    "Coréen",
    "Norvégien",
    "Polonais",
    "Portugais",
    "Roumain",
    "Russe",
    "Serbe",
    "Slovaque",
    "Slovène",
    "Espagnol",
    "Suédois",
    "Télougou",
    "Thaï",
    "Turc",
    "Ukrainien",
    "Vietnamien",
    "Gallois",
    "Langue des signes",
    "Algérien",
    "Araméen",
    "Arménien",
    "Berbère",
    "Birman",
    "Bosniaque",
    "Brésilien",
    "Bulgare",
    "Chypriote",
    "Corse",
    "Créole",
    "Ecossais",
    "Egyptien",
    "Espéranto",
    "Estonien",
    "Finnois",
    "Flamand",
    "Géorgien",
    "Hawaïen",
    "Indonésien",
    "Inuit",
    "Irlandais",
    "Islandais",
    "Latin",
    "Mandarin",
    "Népalais",
    "Sanskrit",
    "Tagalog",
    "Tahitien",
    "Tibétain",
    "Tsigane",
    "Bulgare",
    "Catalan",
    "Cantonais",
    "Croate",
    "Tchèque",
    "Danois",
    "Hollandais",
    "Lituanien",
    "Malais",
    "Malayalam",
    "Panjabi",
    "Tamoul",
    "Anglais",
    "Finlandais",
    "Français",
]