import { Pagination, Stack } from '@mui/material'
import React, { Fragment } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, CardBody, CardTitle, Col, CustomInput, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Request from "../Img/Request.png"
import Request1 from "../Img/Request1.png"
import Request2 from "../Img/Request2.png"
import Request3 from "../Img/Request3.png"
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getAllBlockedGroupUsersAction, unBlockGroupUserAction } from '../../../Services/Actions/Group/getAllGroupMainPageAction';
import { sweetAlertConfirmation } from '../AllGroupsSections/SweetAlert'

const MyBlockedPeople = () => {

    const params = useParams()
    let dispatch = useDispatch();

    const { getAllBlockedGroupUsers } = useSelector((state) => { return state.getAllBlockedUsersByGroupData });


    const {unBlockResult} = useSelector((state) => { return state.unBlockGroupUserData });
    // const { loading: loadingEvents, error: errorEvents, result: resultEvents } = unBlockUser

    // console.log('getAllBlockedGroupUsers:', getAllBlockedGroupUsers);

    useEffect(() => {
        dispatch(getAllBlockedGroupUsersAction(params.id))
    }, [])

    const [selectActive, setSelectActive] = useState(false)

    const [unBlockOpen, setUnBlockOpen] = useState(false)
    const [blockedUserId, setBlockedUserId] = useState(false)
    const [userImage, setUserImage] = useState(false)
    const [userFullName, setUserFullName] = useState(false)
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const handleSelectClick = (userId) => {
        if (selectedUserIds.includes(userId)) {
          setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
        } else {
          setSelectedUserIds([...selectedUserIds, userId]);
        }
      };

      const unBlockAll = () => {
        const selectedIds = getAllBlockedGroupUsers?.data?.successResult?.rows
          ?.filter((data) => selectedUserIds.includes(data.id))
          ?.map((data) => data.id);
          dispatch(unBlockGroupUserAction(selectedIds))
          sweetAlertConfirmation('Unblocked successfully')
          dispatch(getAllBlockedGroupUsersAction(params.id))
      };




    const toggleUnBlock = (userId, fullName, image) => {
        setBlockedUserId(userId)
        setUserFullName(fullName)
        setUserImage(image)
        setUnBlockOpen((prevState) => !prevState)
    }

    const [unBlockAllOpen, setUnBlockAllOpen] = useState(false)

    const toggleUnBlockAll = () => {

        dispatch(unBlockGroupUserAction(blockedUserId))
        setUnBlockOpen((prevState) => !prevState)
        sweetAlertConfirmation('Unblocked successfully')
        dispatch(getAllBlockedGroupUsersAction(params.id))
    }
    const [selectOpen, setSelectOpen] = useState(false)
    const toggleSelect = () => {
        setSelectOpen((prevState) => !prevState)
    }

    const cancelModal = () => {
        setUnBlockOpen((prevState) => !prevState)
    }

    const [modalClosed, setModalClosed] = useState(false)

    //--------------Array_List_Data 
    const BlockedData = [
        {

            img: Request,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "1"
        },
        {
            img: Request3,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "2"
        },
        {
            img: Request2,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "3"
        },
        {
            img: Request1,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "4"
        },
        {
            img: Request,
            name: "Kenjomen Norke",
            joingData: "Joined on 23 Mar 2021",
            mutual: '+3 Mutual Group',
            id: "5"
        }
    ]
    //========================= Block_People_Function ====================//
    const BlockedPeopleData = () => {
        return (
            <Fragment>
                <div className="group-page-section section-b-space">
                    {!selectActive ? (
                        <>
                            <div className="group-page-title">
                                <Row className="row align-items-center">
                                    {
                                        getAllBlockedGroupUsers && getAllBlockedGroupUsers?.data?.successResult?.rows?.length > 0 ?
                                            <>
                                                <Col md="4" className="mb-2">
                                                    <h3>Blocked People (10)</h3>
                                                    <p className='text-muted'>Blocked  people cannot see any activity by group</p>
                                                </Col>
                                                <Col md="8" className="Group_Btns_section">
                                                    <div className="group-search-assign-filter">
                                                        <Button color='primary' onClick={() => setSelectActive(!selectActive)} >Select</Button>
                                                        <Button color='primary' className="ml-2 " onClick={toggleUnBlockAll} >Unblock All</Button>
                                                        {/* Block_UnblockAll_btn  */}
                                                    </div>
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <Col md="4" className="mb-2">
                                                    <h3>Blocked People ({getAllBlockedGroupUsers && getAllBlockedGroupUsers?.data?.successResult?.rows?.length})</h3>
                                                    <p className='text-muted'>No blocked user to show</p>
                                                </Col>
                                                <Col md="8" className="Group_Btns_section">
                                                    <div className="group-search-assign-filter">
                                                    </div>
                                                </Col>
                                            </>
                                    }

                                </Row>
                            </div>
                            <div className="group-detail-page-blk mt-2">
                                <Row>
                                    {
                                        getAllBlockedGroupUsers && getAllBlockedGroupUsers?.data?.successResult?.rows?.map((data) => (
                                            <Col md="6" sm="6">
                                                <div className="group-members-block">
                                                    <div className="group-member-img-cont-blk">
                                                        <img src={data.profileImage} alt="img" />
                                                        <div className="member-cont-blk">
                                                            <h4>{data.fullName}</h4>
                                                            <p>{data.addressBy}</p>
                                                            <p>{data.bio}</p>
                                                        </div>
                                                    </div>
                                                    <div className='Block-btns-blk'>
                                                        <Button className="Unblock_btn "
                                                            onClick={() => { toggleUnBlock(data?.id, data?.fullName, data?.profileImage) }}
                                                        ><p>Unblock</p></Button>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Stack>
                                    <Pagination count={10} className='PaginationCount'>
                                    </Pagination>
                                </Stack>
                            </div>
                        </>
                    ) : (
                        <>
                            <Row>
                                {
                                     getAllBlockedGroupUsers && getAllBlockedGroupUsers?.data?.successResult?.rows?.map((data) => (
                                        <Col md="6" sm="6">
                                            <div className="group-members-block">
                                                <div className="group-member-img-cont-blk">
                                                    <img src={data.profileImage} alt="img" />
                                                    <div className="member-cont-blk">
                                                        <h4>{data.fullName}</h4>
                                                        <p>{data.addressBy}</p>
                                                        <p>{data.bio}</p>
                                                    </div>
                                                </div>
                                                <div className='Block-btns-blk'>
                                                    <CustomInput
                                                        checked={selectedUserIds.includes(data.id)}
                                                        type="checkbox"
                                                        className="customcheckboxForFilter"
                                                        id={data.id}
                                                        style={{ cursor: "pointer" }}
                                                        onChange={() => handleSelectClick(data.id)}
                                                       />
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                            <div className='d-flex justify-content-center'>
                                <Button onClick={unBlockAll} className="Admin_Modify_btn"><p className='ml-2 mr-2' > Unblock All </p></Button>
                            </div>

                        </>
                    )}


                </div>
            </Fragment>
        )
    }
    //--------------UnBLOCK_Modal_
    // const UnBlockModal = ({ userImage, userFullName }) => (
    //     <Fragment>
    //         <Row className="mt-3">
    //             <Col md="12" className="d-flex justify-content-center">
    //                 <div className='BlockProfile_section'>
    //                     <h6>Do you really want to unblock </h6>
    //                     <div className='d-flex  align-items-center mt-4'>
    //                         <span><img className='img-fluid rounded-circle mr-4' height={50} width={50} src={userImage} alt='User image' /></span>
    //                         <p className='text-center'>{userFullName}</p>
    //                     </div>
                        
    //                 </div>
    //             </Col>
    //             <div className="d-flex justify-content-center mt-3 BlockSubProfile_section" >
    //                 <p>Post and discussion will be visible to {userFullName}</p>
    //             </div>
    //             <div className="d-flex justify-content-center mt-3">
    //                 <Button className="UnBlock_Cancle_btn" outline onClick={cancelModal}><p>Cancel****</p> </Button>
    //                 <Button onClick={toggleUnBlockAll} className="UnBlock_ok_btn ml-2"><p>Ok***</p> </Button>
    //             </div>
    //         </Row>
    //     </Fragment>
    // )
    // const UnBlockAllModal = () => (
    //     <Fragment>
    //         <Row className="mt-3">
    //             <Col md="12" className="d-flex justify-content-center">
    //                 <div className='BlockProfile_section'>
    //                     <h6>Do you really want to unblock all? </h6>

    //                 </div>
    //             </Col>
    //             <div className="d-flex justify-content-center mt-3 BlockSubProfile_section" >
    //                 <p>Post and discussion will be visible to “Kenjomen Norke”</p>
    //             </div>
    //             <div className="d-flex justify-content-center mt-3">
    //                 <Button className="UnBlock_Cancle_btn" outline><p>Cancel</p> </Button>
    //                 <Button className="UnBlock_ok_btn ml-2"><p>Ok</p> </Button>
    //             </div>
    //         </Row>

    //     </Fragment>
    // )
    const SelectModal = () => (
        <Fragment>
            <Row>
                <Col md="12">
                    <h4>Select All</h4>
                </Col>
            </Row>
            <Row>
                <div className="group-detail-page-blk mt-2">
                    <Row>
                        {
                            BlockedData.map((data) => (
                                <Col md="6" sm="6">
                                    <div className="group-members-block">
                                        <div className="group-member-img-cont-blk">
                                            <img src={data.img} alt="img" />
                                            <div className="member-cont-blk">
                                                <h4>{data.name}</h4>
                                                <p>{data.joingData}</p>
                                                <p>{data.mutual}</p>
                                            </div>
                                        </div>
                                        <div className='Block-btns-blk'>
                                            <CustomInput type="checkbox" className='customcheckboxForFilter' id="1" style={{ cursor: "pointer", }} />
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                        <div className='d-flex justify-content-center'>
                            <Button onClick={toggleUnBlockAll} className="Admin_Modify_btn"><p>Ok</p></Button>
                        </div>
                    </Row>
                </div>
            </Row>
        </Fragment>
    )
    //_Main_Return_fUNCTION
    return (
        <Fragment>
            <BlockedPeopleData />
            <div className='UnBlock_Modal_Section'>
                {unBlockOpen !== false &&

                    <Modal isOpen={unBlockOpen} toggle={toggleUnBlock} className='modal-dialog-centered' >
                        <ModalHeader className="d-flex justify-content-center  align-items-center modal_Heading_BlockUser">
                            <div>
                                <h4>Unblock User ?</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            {/* <UnBlockModal userImage={userImage} userFullName={userFullName} /> */}

                        <Fragment>
                            <Row className="mt-3">
                                <Col md="12" className="d-flex justify-content-center">
                                    <div className='BlockProfile_section'>
                                        <h6>Do you really want to unblock </h6>
                                        <div className='d-flex  align-items-center mt-4'>
                                            <span><img className='img-fluid rounded-circle mr-4' height={50} width={50} src={userImage} alt='User image' /></span>
                                            <p className='text-center'>{userFullName}</p>
                                        </div>
                                        
                                    </div>
                                </Col>
                                <div className="d-flex justify-content-center mt-3 BlockSubProfile_section" >
                                    <p>Post and discussion will be visible to {userFullName}</p>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <Button className="UnBlock_Cancle_btn" outline onClick={cancelModal}><p>Cancel</p> </Button>
                                    <Button onClick={toggleUnBlockAll} className="UnBlock_ok_btn ml-2"><p>Ok</p> </Button>
                                </div>
                            </Row>
                        </Fragment>

                        </ModalBody>
                    </Modal>
                }
            </div>
            {/* <div className='UnBlockAll_Modal_Section'>
                {
                    unBlockAllOpen !== false &&
                    <Modal isOpen={unBlockAllOpen} toggle={toggleUnBlockAll} className='modal-dialog-centered'>
                        <ModalHeader className="d-flex justify-content-center  align-items-center modal_Heading_BlockUser">
                            <div>
                                <h4>Unblock User?</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <UnBlockAllModal />
                        </ModalBody>
                    </Modal>
                }
            </div> */}
            <div className='Select_Modal_Section'>
                {
                    selectOpen !== false &&
                    <Modal isOpen={selectOpen} toggle={toggleSelect} className='modal-dialog-centered' size="lg" >
                        <ModalBody>
                            <SelectModal />
                        </ModalBody>
                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default MyBlockedPeople