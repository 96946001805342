import React, { Fragment } from 'react'
import { useState ,useEffect} from 'react'
import { AlertTriangle, BellOff, Bookmark, Circle, Edit, Eye, Filter, Link2, MapPin, MoreVertical, PhoneCall, Send, ShoppingCart, Trash2 } from 'react-feather'
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import singleOne from "../img/singleImageOne.png"
import SingleImagePink from '../img/singleImagePink.png'
import { singlePageAllItems, Discriptions } from '../MarketPlaceArray/MarketPlaceCategory'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import UserImg from '../../../NewComponents/IMG/img.svg'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { MarketPlaceCardData2 } from '../MarketPlaceArray/MarketPlaceCategory'
import Mrkt from "../../profile-components/Img/MrktImg.svg"
import cardImg from "../img/cardImage.png";
import Mrktgreen from "../Icons/mrktgreen.svg"
import Mrktwhite from "../Icons/mrktwhite.svg"
import MpLikeShareCompo from './MpLikeShareCompo'

import LeftSidebar from '../../LeftSidebar'
import MarketPlaceHeader from './MarketPlaceHeader'
import RightSidebar from '../../RightSidebar'

import Header from '../../Header'
import LikeShareCommentDisplay from '../ModalSection/LikeShareCommentDisplay'
import MpReportModal from '../ModalSection/MpReportModal'
import MarketPlaceDeleteModal from '../ModalSection/MarketPlaceDeleteModal'
import { Checkbox } from '@mui/material'
import MpShareModal from '../ModalSection/MpShareModal'
import "../MarketPlace.css"
import Back from "../img/Group 37733.svg"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { getSingleProductAction, getSuggestedProductList, deleteMarketPlaceProduct, getPeopleList } from "../../../Services/Actions/MarketPlace/getAllProductsAction";
import  { handleContactClick } from "../../../../src/functions/copyContact.js"
import { useLocation } from 'react-router-dom';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import axios from 'axios'





const MpSingleProductPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const id = location.state;
    const [refresh,setRefresh] =useState(false)



    // dispacting the values
    useEffect(() => {
        let body = { marketPlaceId: id };
        dispatch(getSingleProductAction(body));
    }, [dispatch, id,refresh]);

    
    //getiing single mp post data from store
const { products ,error } = useSelector(state => state.getSingleMarketPlaceProduct);
const singleProductData=products[0];


    //accesing own user data from session 
    const [SelectedUser, setSelectedUser] = useState(
        JSON.parse(sessionStorage.getItem("selectedUser"))
    )


    // to navigate the onclick 


    // state to change the image onclick
    const [image, setImage] = useState(singleOne)


    // keys of the singlePageAllItems object 
    const AllItemsDetails = Object.keys(singlePageAllItems)


    // share too  array 
    const ForwardArrayData = [
        {
            id: "1",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "2",
            img: UserImg,
            name: "Darrell Steward",
            userName: "abriella-bond123"
        },
        {
            id: "3",
            img: UserImg,
            name: "Robert Fox",
            userName: "abriella-bond123"
        },
        {
            id: "4",
            img: UserImg,
            name: "Ralph Edwards",
            userName: "abriella-bond123"
        },
        {
            id: "5",
            img: UserImg,
            name: "Savannah Nguyen",
            userName: "abriella-bond123"
        },
        {
            id: "6",
            img: UserImg,
            name: "Eleanor Pena",
            userName: "abriella-bond123"
        },
        {
            id: "7",
            img: UserImg,
            name: "Darlene Robertson",
            userName: "abriella-bond123"
        },
        {
            id: "8",
            img: UserImg,
            name: "Bessie Cooper",
            userName: "abriella-bond123"
        }
    ]


    // All Dummy Images
    const SomeOtherImages = [
        singleOne,
        "https://www.hdwallpapers.in/download/actor_tom_holland_is_wearing_black_t_shirt_and_green_jerkin_4k_hd_boys-1440x2560.jpg",
        "https://w0.peakpx.com/wallpaper/145/263/HD-wallpaper-ram-charan-rrr-shirt.jpg",
        "https://w0.peakpx.com/wallpaper/671/450/HD-wallpaper-vijay-in-white-shirt-master.jpg"
    ]



    // share modall controller
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }




    // business page slider settings

    let bizSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            },

            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    // business page slider settings

    let SuggestedSliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 786,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 472,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };



    // num formatter 
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt((num / 1000))} K`
        } else if (num >= 999999) {
            return `${parseInt((num / 1000000))} M`
        } else if (num <= 999) {
            return `${num}`
        }
    }



    // Viewed and like Comment modal
    const [viewModal, setViewModal] = useState(false);

    const ViewToggle = () => setViewModal(prestate => !prestate);


    // BookMark 
    const [BookmarkIcon, SetBookmark] = useState(true)

    // Handle the Sold button Click Event 

    const [soldOut, setSoldOut] = useState(false)

    const OnSoldClick = (e) => {
        setSoldOut(true)
    }





    // Report Modal handler
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    // State And function For the Delete Operation

    const [deleteOpen, setDeleteOpen] = useState(false)
    const toggleDelete = () => {
        setDeleteOpen((preState) => !preState)
    }

    const handleRedirect = () => {
        window.open("https://msgmee.com/", "_blank");  
    };
    

    //here we are setting fist image as main image
    const [mainImage, setMainImage] = useState('');
    useEffect(() => {
            if (singleProductData?.mpMediaLists?.length) {
                // Setting the initial main image to the first image in the list or the one with sequence 0
                const initialImage = singleProductData.mpMediaLists.find(item => item.sequence === 0) || singleProductData.mpMediaLists[0];
                setMainImage(initialImage.fileURL);
            }
        }, [singleProductData]);




    // =============>>selecting suggested marketPlace products<<===============
    useEffect(() => {
        let body = { pageIndex: 0,pageSize:20 };
        dispatch(getSuggestedProductList(body));
    }, [dispatch,]);
    let { suggestedProducts } = useSelector((state) => state.getSuggestedProductList);
    const suggestedProductCount = suggestedProducts?.count || 0;
          suggestedProducts = suggestedProducts?.rows || [];


    //============>allow notification on/off<<=================>>
    const allowNotification = async(e, marketPlaceId,allowNotification) => {
       e.stopPropagation();
        try {
            const newStatus = !allowNotification;
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/marketPlace/allowNotif`,
                {marketPlaceId: marketPlaceId,allowNotif : newStatus},
                {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,},}
            );
            if(response.data.success)  {
                sweetAlertConfirmation("Notification Setting Changed")
                setRefresh(prevRefresh => !prevRefresh); 
                
            }
        } catch (error) {
            console.error("Error during  notification setting change:", error);
            return error
        }
    };




    // =========>>mark as sold product======================
    const markAsSold = async(e,marketPlaceId,soldStatus) =>{
        try {
            soldStatus = soldStatus === 0 ? 1 : 0;
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/marketPlace/markasSold`,
                {marketPlaceId: marketPlaceId,sold: soldStatus},
                {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,},}
            );
            if(response.data.success)  {
                if(soldStatus){
                    sweetAlertConfirmation("Succesfully Sold Out")        
                }
                else{
                    sweetAlertConfirmation("Succesfully Put On Sale")  
                }
                setRefresh(prevRefresh => !prevRefresh); 
            }
        } catch (error) {
            console.error("Error during  marketplace mark As sold product:", error);
            return error
        }

    }

    // ==============>>>delete marketPlace product<<============

   const [isDelete, setIsDelete] = useState(false);
   const [marketPlaceId, setMarketPlaceId] = useState(null);
   useEffect(() => {
     let body = {};
     if (isDelete && marketPlaceId) {
       body.marketPlaceId = marketPlaceId;
       dispatch(deleteMarketPlaceProduct(body))
         .then(() => {
           sweetAlertConfirmation("product successfully deleted");
           navigate("/marketplace");
         })
         .catch((error) => {
           console.log("error during delete product", error);
         });
     }
   }, [dispatch, isDelete]);





  //============>>gettting people list to share product==========
  const [peopleListData, setPeopleListData] = useState([]);
  const [peopleListPageIndex,setPeopleListPageIndex] = useState(0)
  const [searchKeyValue,setSearchKeyValue] = useState("")
  useEffect(() => {
      const fetchData = async () => {
        if (forwardOpen) {
          const data = await getPeopleList(peopleListPageIndex, searchKeyValue);
          if (peopleListPageIndex === 0) {
            // When pageIndex is 0, reset the list
            setPeopleListData(data);
          } else {
            // Append data for infinite scroll
            setPeopleListData(prevData => [...prevData, ...data]);
          }
        } else {
          setPeopleListPageIndex(0);
          setPeopleListData([]);
          setSearchKeyValue("");
        }
      };
  
      fetchData();
    }, [forwardOpen, peopleListPageIndex, searchKeyValue]);


    ///jumping to edit screen of the product
    const jumpToEditPost = (e,mpId)=>{
    e.stopPropagation();
    const data = {
        marketPlaceId :mpId
    }
    navigate('/MpEditProductShell', { state: data }) 
   }
    


                  //    ============================================================================================


    return (
        // Full Single Page Return
        <Fragment>
              {/* ============== Usiversal header ========================== */}
              <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className='cardmobile'><MarketPlaceHeader /></div>
                    <div className='mt-3 markettop1 bfgfg'>
                        <Row>
                            <Col lg={5}>
                              {/*  ========= Grid Image area Of single Page =============== */}
                                <div className="Mp_SingleGridImages">
                                    <img 
                                        src={mainImage} 
                                        alt="" 
                                        className='img-fluid MpGrid_Image_BigScreen w-100 rounded MP_GridImageSizeDecieder' 
                                    />
                                    <div className='MarketPlace_MrktIconLeft_SingleProductPage MarketPlaceMobileMrktIconLeft zIndexImage'>
                                        <img src={(singleProductData?.isOwner===1 )? Mrktgreen : Mrktwhite}  className="Mp_MarketSvgICon" alt="marketIcon" />
                                    </div>
                                    <div className='MarketPlace_MrktIconLeft_SingleProductPage1 MarketPlaceMobileMrktIconLeft zIndexImage' style={{ cursor: 'pointer' }} onClick={() => { navigate("/marketplace") }}>

                                         {/*  ===================== Ternary to toggle the bag icon of the card  ====================== */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
                                            <path d="M4.42578 12.7852H20.0508" stroke="#1D1E20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.7279 19.0589L4.42578 12.7839L10.7279 6.50781" stroke="#1D1E20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="Mp-SingleProductMoreImages mt-3">
                                        <Slider {...bizSettings} className="default-space">
                                            {singleProductData?.mpMediaLists
                                                .sort((a, b) => a.sequence - b.sequence)
                                                .map((item, index) => (
                                                    <div key={index} className='MP_GridImageSizeDecieder' >
                                                        <img
                                                            src={item.fileURL}
                                                            onClick={() => setMainImage(item.fileURL?item.fileURL:"")}

                                                            alt="SliderImage"
                                                            className='img-fluid rounded'
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                ))}
                                        </Slider>
                                    </div>
                                </div>
                                {/* ====================== like share and comment card ================================== */}
                                <MpLikeShareCompo toggleForward={toggleForward} marketPlaceData={singleProductData}/>

                                {/* ====================== ends like share and comment card ============================= */}
                            </Col>
                            <Col lg={7} className="Mp_SingleProductPage_Details">
                                {/* ======= Single Page Product title ================= */}
                                <div className='cardmobile'>
                                    <div className='d-flex justify-content-between'>
                                        <h5 className='Mp_Single_CardDetails_H5'>{singleProductData?.mpCategory?.name || ""}/{singleProductData?.mpSubCategory?.name || ""}</h5>

                                        {/* <Button outline color="success" onClick={() => { navigate("/marketplace") }} ><CornerUpLeft size={16} />&nbsp;Back</Button> */}
                                    </div>
                                    <div className="Mp_Single_CardDetails  position-relative  ">
                                        <h1 className="Mp_SinglePageHeading_Name">{singleProductData?.title ?singleProductData?.title:""}</h1>
                                    </div>
                                </div>
                                {/*==================== Selected size and color Card ================= */}
                                <Card className="p-3 mt-3 cardmobile ">
                                    <div className="Mp_SetOrder">
                                        <div className="Mp_setOrderLeft">
                                            <h5>Set Order {soldOut === true ? <h6 className={`Mp_Product_Status2 ${singleProductData.owner === 1 ? "" : "d-none"}`}>Marked as Sold</h6> : <h6 className={`Mp_Product_Status ${singleProductData?.isOwner === 1 ? "" : "d-none"}`}>Active</h6>} </h5>
                                            <div className='Mp_setOrderLeft_Image mt-3' >
                                            <img
                                                src={singleProductData?.mpMediaLists?.find(media => media.sequence === 0)?.fileURL ||singleProductData?.mpMediaLists?.[0]?.fileURL}
                                                alt="Product"
                                                className='img-fluid'
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }  }
                                            />

                                                <div className='Mp_setOrderLeft_ImageText px-3' >
                                                    {/* <h6>Selected Size:</h6> commneted this will appl dynamic after some time */}
                                                    {/* <h5 className='Mp_singleVarient'>L (Large)Black Colour</h5> */}
                                                    <h5 className='Mp_singleVarient'>{singleProductData?.title ?singleProductData?.title:""}</h5>
                                                    <h6 className='d-flex align-items-end justify-content-start gap-2 Mp_TextColorGray'>Totol Price: <h4 className='Mp_ColorPrimaryBold' style={{ color: "#81c14b" }} > ${singleProductData?.price ?singleProductData?.price:""}</h4></h6>
                                                </div>
                                            </div>

                                            {/* Book Mark Button  ========================= */}
                                            <div className='MpSigleProductBookMark  ' onClick={() => SetBookmark(!BookmarkIcon)} style={{ padding: '8px', backgroundColor: 'rgba(126, 178, 98, 0.1)', borderRadius: '5px', cursor: 'pointer' }}   >
                                                {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                                            </div>
                                            <UncontrolledButtonDropdown className="MpThreeDotMenu_singleProduct">
                                                <DropdownToggle className="border-0 bg-transparent ">
                                                    <MoreVertical color='#000' size={18} />
                                                </DropdownToggle>
                                                <DropdownMenu className={`${singleProductData?.isOwner === 1 ? "MpDropdownOptionMenu" : "MpDropdownOptionMenu2"}`}>
                                                    <DropdownItem className={`MpDropdownMenuItems ${singleProductData?.isOwner ? "" : "d-none"}`} onClick ={(e)=>allowNotification(e,singleProductData.id,singleProductData.allowNotification) }><BellOff size={16} color="#808080" /> {singleProductData?.allowNotification ? " Turn Off Notification" : " Turn On Notification"}</DropdownItem>
                                                    <DropdownItem onClick={(e) => jumpToEditPost(e,singleProductData?.id)} className={`MpDropdownMenuItems ${singleProductData?.isOwner ? "" : "d-none"}`}>
                                                        <Edit size={16} color="#808080" /> Edit Product
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => {toggleDelete();setMarketPlaceId(singleProductData?.id); }} className={`MpDropdownMenuItems ${singleProductData?.isOwner ? "" : "d-none"}`}><Trash2 size={16} color="#808080" /> Delete Product
                                                    </DropdownItem>
                                                    <DropdownItem className="MpDropdownMenuItems"><Link2 size={16} color="#808080" /> Copy link </DropdownItem>
                                                    <DropdownItem onClick={toggleForward} className="MpDropdownMenuItems"><Send size={16} color="#808080" /> Share </DropdownItem>
                                                    <DropdownItem onClick={toggleReport} className={`MpDropdownMenuItems ${singleProductData?.isOwner ? "d-none" : ""}`} ><AlertTriangle color="#808080" size={16} /> Report </DropdownItem>
                                                    <DropdownItem onClick={(e) => { setSoldOut(false);markAsSold(e,singleProductData?.id,singleProductData?.isSold) }} className={`MpDropdownMenuItems ${singleProductData?.isOwner ? "" : "d-none"}`}><ShoppingCart color="#808080" size={16} />  {singleProductData?.isSold === 0 ? "Mark As Sold" : "Put On Sale"} </DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>

                                            {/* ---------------------------------------------- all modal Call ----------------------------------------------------- */}

                                            <MpReportModal reportOpen={reportOpen} toggleReport={toggleReport} marketPlaceId = {id} />
                                            <MarketPlaceDeleteModal isOpen={deleteOpen} toggleOpen={toggleDelete} setIsDelete= {setIsDelete}/>
                                            <LikeShareCommentDisplay modal={viewModal} toggle={ViewToggle} marketPlaceId = {singleProductData?.id} />
                                            {/* --------------------------Share Modal -------------------------------------- */}
                                            <MpShareModal forwardOpen={forwardOpen} toggleForward={toggleForward} peopleListData= {peopleListData} setPeopleListPageIndex={setPeopleListPageIndex} setSearchKeyValue = {setSearchKeyValue}/>


                                            <div className="Mp_Card_Call_Msg_Btn1 mt-3">
                                                <Button className={`${singleProductData?.isOwner  === 1 ? "d-none" : ""}`} color="success" style={{ background: "#81c14b", borderColor: "#81c14b" }} 
                                                     onClick={(event) => handleContactClick(event, singleProductData?.user?.mobile || "")}>
                                                    <PhoneCall size={15} color="#fff" style={{ marginRight: "8px" }} /> Call Seller
                                                </Button>
                                                <Button
                                                    color="success"
                                                    outline
                                                    className={`Mp_Card_Msgbtn ${singleProductData?.isOwner  === 1 ? "d-none" : ""}`}
                                                    onClick={(e) => { handleRedirect(e) }}
                                                >
                                                    <svg width="27" height="30" viewBox="0 0 27 30" fill="white" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "8px", width: '15px', height: "15px" }}  >
                                                        <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#81C14B" stroke-width="1.5" />
                                                    </svg>
                                                    Message
                                                </Button>
                                                {singleProductData?.isSold === 0 ?
                                                    <Button className={`${singleProductData?.isOwner  === 1 ? "" : "d-none"}`} color="primary" onClick={(e) => { setSoldOut(false);markAsSold(e,singleProductData?.id,singleProductData?.isSold) }} >
                                                        Mark AS Sold
                                                    </Button>
                                                    :
                                                    <Button outline className={`MpDeleteButton ${singleProductData?.isOwner  === 1 ? "" : "d-none"}`} onClick={() => {toggleDelete();setMarketPlaceId(singleProductData?.id); }} >
                                                        Delete
                                                    </Button>}

                                            </div>
                                        </div>
                                        <div className="Mp_setOrderRight d-flex align-items-end flex-column justify-content-end  cardmobile  ">


                                            <div className='w-100 d-flex align-items-center justify-content-between gap-2 ' style={{ fontWeight: "500" }}>
                                                <div style={{ cursor:singleProductData?.isOwner? "pointer":"default", color: "#4d4d4d" }} onClick={singleProductData?.isOwner  === 1 ?() => { ViewToggle() }:null} className="d-flex align-items-center gap-1">
                                                    <Eye size={18} /> {numFormatter(singleProductData?.viewsCount || 0)}
                                                </div>
                                                <div style={{ cursor: "pointer", color: "#4d4d4d" }} className=" d-flex align-items-center gap-1  ">
                                                    <svg width="27" height="30" viewBox="0 0 27 30" fill="transparent" xmlns="http://www.w3.org/2000/svg" className='Mp_MsgBtn_svg' style={{ marginRight: "0px", width: '15px', height: "15px" }}  >
                                                        <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#4d4d4d" stroke-width="2" />
                                                    </svg> {numFormatter(0)}

                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </Card>
                                {/* ====================Ends Selected size and color Card ================= */}

                                {/* ================= Selller Details ================ */}
                                {singleProductData?.isOwner === 1 ? "" :
                                    <Card className="p-3 ">
                                        <h5 className='Mp_SinglePage_CardHeading'>Seller Details</h5>
                                        <div className='Mp_setOrderLeft_Image mt-3' >
                                            <img src={singleProductData?.user?.profileImage} alt="" className='img-fluid' />
                                            <div className='Mp_setOrderLeft_ImageText1 px-3' >
                                                <h6>Seller name:</h6>
                                                <h5 className='Mp_singleVarient maploccc'>{singleProductData?.user?.fullName || ""}</h5>

                                                <h6 className='d-flex align-items-end justify-content-start gap-2 Mp_TextColorGray maplocc'>Member Since Oct 2020</h6>

                                                
                                                <h6 className='mt-2  gap-1  maploc ' style={{ color: "#808080" }}><MapPin color='#808080' size={15} className='mapicon' />{singleProductData?.location || "no location available"}</h6>
                                            </div>
                                            
                                        </div>
                                        
                                        {/* <h6 className='mt-2 d-flex gap-1 align-items-center' style={{ color: "#808080" }}><MapPin color='#808080' size={25} />813 Howard Street Oswego NY 131...</h6> */}
                                        <div className='d-flex align-items-center justify-content-center w-100 gapmp mpother'>
                                            <h5 className='py-2  MpSellerDetails_View' onClick={() => { navigate("/MyMarketPlace") }} >View other items From seller</h5>
                                        </div>
                                    </Card>
                                }
                                {/* ================= Ends Selller Details ================ */}

                                {/* ============ About Item some Discription ================= */}
                               
                                <Card className="p-3 mt-3">
                                    {/* Check if there is a selected attribute */}
                                    {singleProductData?.mpSubCategory?.mpAttributes?.some(attr => attr.selectedAtt) && (
                                        <>
                                        <h5 className='Mp_SinglePage_CardHeading Mp_Color4D_Font6 mpdescr'>About Item</h5>
                                        <div className="Mp_AllItems_Items mt-3">
                                            {singleProductData?.mpSubCategory?.mpAttributes?.map((attribute, attrIndex) => 
                                            attribute.selectedAtt ? (
                                                <div className='Mp_Color87_Font5' key={attrIndex} style={{ marginBottom: '10px' }}>
                                                <strong>{attribute?.label}</strong>:&nbsp;
                                                <span className='Mp_Color16_Font6 aboutmp'>
                                                    {attribute?.selectedAtt?.mpAttributeValues?.map(value => value?.value).join(', ')}
                                                </span>
                                                </div>
                                            ) : null
                                            )}
                                        </div>
                                        </>
                                    )}

                                    {singleProductData?.description && (
                                        <>
                                        <h5 className='Mp_SinglePage_CardHeading mt-3 descpgap Mp_Color4D_Font6 mpdescr'>Description</h5>
                                        <div className="Mp_AllItems_Discription p-3" style={{ marginTop: '20px' }}>
                                            <p className='parmp'>{singleProductData?.description || ""}</p>
                                        </div>
                                        </>
                                    )}

                                    {/* {Discriptions.map((li) => (
                                        <li typeof='dot'><p className='parmp'>{li}</p></li>
                                    ))} */}
                                </Card>


                                {/* ============ About Item some Discription ends ================= */}



                                {/* =================== Suggested card sections starts  ============================= */}


                                {/*=============================== suggested product card =========================== */}
                                <div className="Biz-heading_section py-2 mb-2 mt-4">
                                    <h2>Suggested Products ({suggestedProductCount? suggestedProductCount : 0})</h2>
                                    {suggestedProducts.length > 0 && (
                                    <span style={{ cursor: "pointer" }} className="single-ancor-blk d-flex align-items-center" onClick={() => { navigate("/marketplace") }}>
                                        See All <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                    </span>
                                    )}
                                </div>
                                    {suggestedProducts.length > 0 ? (
                                        <Slider {...SuggestedSliderSettings} className="default-space">
                                        {suggestedProducts.map((product) => (
                                            <div key={product.id}>
                                            <Card onClick={() => navigate(`/mpsingleproduct`,{ state: product.id })} className="marketplaceCardSec p-2">
                                                <img src={product?.mpMediaLists[0]?.fileURL || ""} alt="market" className="img-fluid Mp_Card_img" style={{ 
                                                    width: '100%', 
                                                    height: '170px', // Fixed height
                                                    borderRadius:"5px",
                                                    objectFit: 'cover' // Maintain aspect ratio
                                                }}/>
                                                <div className='MarketPlace_Card_BottomLeftPosition '></div>
                                                <div className='MarketPlace_MrktIconLeft'>
                                                <img src={Mrktwhite} style={{ width: "12px", height: "12px" }} alt="marketsvg" />
                                                </div>
                                                <div className="MarketPlaceCardContent ">
                                                <div className='Mp_Card_Bottom_Sec'>
                                                    <h3 className='Mptruncated' title={product?.title || ""}>
                                                    {product?.title || ""}
                                                    </h3>
                                                </div>
                                                <div className='MarketPlace_SubCate_Price py-1 gap-2 '>
                                                    <h5>
                                                    {product?.categoryName||"No Category"}

                                                    </h5>
                                                    <p>$ 
                                                    {product?.price||0.0}
                                                    </p>
                                                </div>
                                                </div>
                                            </Card>
                                            </div>
                                        ))}
                                        </Slider>
                                        ) : (
                                        <p>No suggested products available.</p>
                                    )}
                                {/*================================= suggested products card section end ========================== */}

                            </Col>
                        </Row >
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment >
    )
}

export default MpSingleProductPage
