
import ImgWoman from './Img/ImgWoman.png'
import ImgWoman2 from './Img/ImgWoman (2).png'
// --------------------------------------------------------------------Array for Report data options----------------------------------------------------------------
export const ReportDataInfo = [
    {
        name: "Spam"
    },
    {
        name: "Nudity"
    },
    {
        name: "Violence"
    },
    {
        name: "Harrasment"
    },
    {
        name: "Suicide or self-injury"
    },
    {
        name: "Terrorism"
    },
    {
        name: "Others"
    }
]

// --------------------------------------------------------------------Array for Notification List------------------------------------------------------------------
export const NotificationData = [
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
        text: 'Paige Turner',
        Time: '3 Hrs ago',
        post: 'liked your post',
        status: 'read',
        name3: 'Sunny'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        name2: 'The Gong Advertisement',
        post1: 'post',
        img: 'https://img.freepik.com/free-photo/female-model-posing-pink-wall_114579-13995.jpg?w=1380&t=st=1681366653~exp=1681367253~hmac=82a29bb3842141013e507015fc22b76128ceb5c503c2ce7660bdc4753743edbd',
        text: 'Paige Turner',
        Time: '2 Hrs ago',
        post: 'Shared',
        status: 'read',
        name3: 'Jacob Jones'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        name2: 'Galaxy',
        img: 'https://img.freepik.com/free-photo/afro-man-focusing-with-his-fingers_1368-2736.jpg?w=1060&t=st=1681366283~exp=1681366883~hmac=6559d3e03c18241ac23803b220f3067c79fbb5dfddab2aeeb4dea5760806f320',
        text: 'Paige Turner',
        Time: '7 Hrs ago',
        post: 'You have a new friend suggestion',
        status: 'read',


    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
        text: 'Jacob Jones',
        Button: 'Request',
        Time: '9 Hrs ago',
        post: 'sent you a follow request',
        status: 'Unread',
        name3: 'Ajay'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
        text: 'Dr.Robertson',
        Time: '1 day ago',
        post: 'liked your post',
        status: 'Unread',
        name3: 'Sourabh'


    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
        text: 'Paige Turner',
        Time: '6 Hrs ago',
        post: 'liked your post',
        status: 'read',
        name3: 'Abhinav'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
        text: 'Dr.Robertson',
        Time: '5 days ago',
        post: 'sent you a follow request',
        status: 'read',
        Button: 'Request',
        name3: 'Hamid'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
        text: 'Dr.Robertson',
        Time: '1 day ago',
        post: 'liked your post',
        status: 'Unread',
        name3: 'Anthony'


    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
        text: 'Paige Turner',
        Time: '8 Hrs ago',
        post: 'liked your post',
        status: 'read',
        name3: 'Tom'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
        text: 'Dr.Robertson',
        Time: '5 days ago',
        post: 'liked your post',
        status: 'read',
        // Button: 'Request',
        name3: 'Sarvesh'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
        text: 'Jacob Jones',
        Button: 'Request',
        Time: '5 Hrs ago',
        post: 'sent you a follow request',
        status: 'Unread',
        name3: 'Prem'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
        text: 'Dr.Robertson',
        Time: '3 day ago',
        post: 'liked your post',
        status: 'Unread',
        name3: 'Abhay'


    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
        text: 'Paige Turner',
        Time: '9 Hrs ago',
        post: 'liked your post',
        status: 'read',
        name3: 'Hritik'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
        text: 'Dr.Robertson',
        Time: '5 days ago',
        post: 'sent you a follow request',
        status: 'read',
        Button: 'Request',
        name3: 'Shubham'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&w=1000&q=80',
        text: 'Dr.Robertson',
        Time: '2 day ago',
        post: 'liked your post',
        status: 'Unread',
        name3: 'Akshay'


    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cGVyc29ufGVufDB8fDB8fA%3D%3D&w=1000&q=80',
        text: 'Paige Turner',
        Time: '6 Hrs ago',
        post: 'liked your post',
        status: 'read',
        name3: 'Abhishek'

    },
    {
        name: 'raj',
        name1: 'Ashish',
        img: 'https://us.123rf.com/450wm/deagreez/deagreez2104/deagreez210409556/168182563-photo-of-optimistic-brunette-lady-wear-white-cloth-walking-in-park-outside.jpg?ver=6',
        text: 'Dr.Robertson',
        Time: '8 days ago',
        post: 'liked your post',
        status: 'read',
        // Button: 'Request',
        name3: 'Shikha'

    }


]

// ----------------------------------------------------------------Array for see more Notifications List------------------------------------------------------------
export const SeeMoreNotificationdata = [
    {
        name: 'Matt damon',
        name1: 'Rabbi Shergill Guitar Show',
        img: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        text: 'Paige Turner',
        Time: '11:34 am',
        Button: 'Request',
        post: 'has invited you to join the event',
        status: 'read',
        isChecked: false,
        event: true,
    },
    {
        name: 'Christian bale',
        name1: 'Nainital Trip.',
        img: ImgWoman,
        text: 'Jacob Jones',
        Time: '11:50 am',
        post: 'liked your photo',
        status: 'Unread',
        isChecked: false,
        event: false,
    },
    {
        name: 'Advait allen',
        name1: 'Nainital Trip with my official Group',
        img: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        text: 'Dr.Robertson',
        Time: '11:58 am',
        post: 'and 42 others have liked your photo',
        status: 'Unread',
        isChecked: false,
        event: false,
    },
    {
        name: 'Alex Adams',
        name1: 'Nainital Trip with my office friends',
        img: ImgWoman2,
        text: 'Paige Turner',
        Time: '3 Hrs ago',
        post: 'commented on your photo',
        status: 'read',
        isChecked: false,
        event: false,

    },
    {
        name: 'Lele pons',
        name1: 'Rabbi Shergill Guitar Show',
        img: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        text: 'Paige Turner',
        Time: '01:23 pm',
        post: 'has invited you to join the event',
        // Button: 'Request',
        status: 'read',
        dayandtime: <p className=" mr-1 mt-3" style={{ paddingLeft: '0.2rem' }}>Yesterday, 21 March 2022</p>,
        isChecked: false,
        event: true,
    },
    {
        name: 'Jeff hardy',
        name1: 'Nainital Trip.',
        img: ImgWoman,
        text: 'Jacob Jones',
        Time: '02:39 pm',
        post: 'liked your photo',
        status: 'Unread',
        isChecked: false,
        event: false,
    },
    {
        name: 'Steven spielberg',
        name1: 'Nainital Trip.',
        img: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        text: 'Jacob Jones',
        Time: '03:42 pm',
        post: 'liked your photo',
        status: 'Unread',
        isChecked: false,
        event: false,
    },
    {
        name: 'Joe biden',
        name1: 'Nainital Trip with my official Group',
        img: 'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80',
        text: 'Dr.Robertson',
        Time: '06:01 pm',
        post: 'and 42 others have liked your photo',
        status: 'Unread',
        isChecked: false,
        event: false,
    }
]