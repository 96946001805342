import React from 'react'
import { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Col, Row } from 'reactstrap';
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import CreationImg from './Img/CreationImg.png'
import BizOwnImg from './Img/BizOwnImg.png'
import BizOwnImg1 from './Img/BizOwnImg1.png'
import BizOwnImg2 from './Img/BizOwnImg2.png'
import BizOwnImg3 from './Img/BizOwnImg3.png'
import { XCircle } from 'react-feather';
import { Rating, Stack } from '@mui/material';
import AvatarGroup from '../../NewComponents/AvatarGroup';
import MySuggestedBiz from './Components/MySuggestedBiz';
import './biz.css'

let bizSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};

const CreateBiz = () => {

    const BizAllData = [
        {
            img: BizOwnImg,
            typeof: "Pin",
            cardName: "Traditional Wedding ",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg1,
            cardName: "Action Pack",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg2,
            cardName: "Traditional Wedding ",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg3,
            typeof: "Pin",
            cardName: "Action Pack",
            category: "Music Equipment"
        },
        {
            img: BizOwnImg1,
            cardName: "Traditional Wedding ",
            category: "Music Equipment"
        }
    ]
    //---------------- main_return_function--------------------///
    return (
        <Fragment>
            {/*-----------header section-------------- */}
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div className="create-biz-page-block">
                        <Row >
                            <Col md="6 " className="mb-3">
                                <div className='creationimg'>
                                    <img src={CreationImg} alt="img" width={300} />
                                </div>
                            </Col>
                            <Col md="6" className="d-flex align-items-center ">
                                <div>
                                    <h3>Create Your Biz Page</h3>
                                    <p>You're now one step closer to success. Create your business page to reach out to more customers and start growing your business today!</p>
                                    <NavLink to="/CreateBizPage" className="btn btn-primary">Get Started</NavLink>
                                    <div className="term-condition-blk">
                                        By clicking Get Started, you agree to the<br />
                                        <a href="#">Biz Pages terms.</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='Biz_suggested_section mt-2'>
                        <div className="container-fluid section-t-space px-0" >
                            <MySuggestedBiz />
                        </div>
                    </div>
                </div>
                <RightSidebar />
            </div>
        </Fragment>
    )
}
export default CreateBiz