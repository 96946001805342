import React, { Fragment, useState } from 'react'
import { CheckCircle, Mail, MapPin, Star } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap'
import Header from '../../Header'
import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import { Message } from '@mui/icons-material'

const AllEvents = () => {
    const [seemore, setSeeMore] = useState(false)
    const [seemoretext, setSeeMoreText] = useState(false)
    return (

        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <div>
                        <Row className='d-flex justify-content-center'>
                            <Col className="col-12 col-lg-6">
                                <Card className="h-100 upcomming-text m-2" style={{ borderRadius: '0px' }}>
                                    <img className='w-100 rounded-top' height={250} src="https://picsum.photos/900/270?grayscale" alt='bannerImage' />
                                    <CardBody>
                                        <div className='d-flex justify-content-center mb-2'>
                                            <Button className='w-50' color='primary' outline ><CheckCircle size={18} /> Going</Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button color='primary' className='w-50' outline ><Star size={18} /> Interested</Button>
                                            &nbsp;&nbsp;
                                            <Button color='primary' className='w-50' outline ><Mail size={18} />&nbsp;Invite</Button>
                                        </div>
                                        <h4>Get started with live music near you</h4>
                                        <h6 className='text-muted'>Entertainment</h6>
                                        <div className='d-flex justify-content-between text-primary my-2'>
                                            <small>12th Apr 2021</small>
                                            <small>11:00 AM - 08:00 PM</small>
                                        </div>
                                        <a href={'https://goo.gl/maps/bV32YS55UtQM7hhb7'} style={{ cursor: 'pointer' }} className='text-muted mb-2'><MapPin size={16} /> Clarendon Park Road, Leicester, LE2 3, United Kin</a>
                                        <p>Events, by their very nature, are about connections. Through your event description, you{seemore ? 'can boost attendance Events, by their very nature, are about connections. Through your' : null} bright event description, you can boost attendance or hosting of <span style={{ cursor: 'pointer' }} className='text-primary' onClick={() => setSeeMore(!seemore)} >{seemore ? 'Read less' : '... Read more'}</span> </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </Fragment>


    )
}

export default AllEvents