import React, { useEffect } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Transparentimg from "../../NewComponents/IMG/Transparentimg.png";
import gif_1 from "../../NewComponents/Gif/live_2.gif";
import { Fab } from "@mui/material";
import StoryModalView from "./StoryModal/StoryModalView";
import { Plus } from "react-feather";
import "../Story/Story.css";
import { ProgressBar } from "react-bootstrap";
import SelfStoryViewModal from "./StoryModal/SelfStoryViewModal";
import StoryOutSideViewModal from "./StoryModal/StoryOutSideViewModal";
import axios from "axios";
import { useDispatch } from "react-redux";

export const StorySlider = () => {
  // ------------- Story View Modal State -------------
  const [storyView, setStoryView] = useState(false);
  const toggleSetStoryView = () => {
    setStoryView((prevState) => !prevState);
  };
  //  ------------- Self Story Modal State -------------
  const [selfStoryModal, setSelfStoryModal] = useState(false);
  const toggleSetSelfStoryModal = () => {
    setSelfStoryModal((preState) => !preState);
  };

  const dispatch = useDispatch();

  const getAllStories = async () => {
    try {
      const requestBody = {
        pageIndex: 0,
        pageSize: 10,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/story/listStories`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        console.log("response for story",response)
      } else {
        console.error("Error Fetching Story Data", response.status);
      }
    } catch (error) {
      console.error("Error Fetching story Data", error);
    }
  };
  useEffect(() => {
    getAllStories();
  });

  var storySettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  const StoryData = [
    {
      currentStatusofPost: "Live",
      class: "live-block",
      img: "/assets/images/story-2(1).jpg",
      name: "",
      currentStatus: "Active now",
    },
    {
      currentStatusofPost: "Live",
      class: "live-block",
      img: "/assets/images/story-3.jpg",
      name: "Petey Cruiser",
      currentStatus: "Active now",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-4.jpg",
      name: "Petey Cruiser",
      currentStatus: "2 hrs ago",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-3.jpg",
      name: "Petey Cruiser",
      currentStatus: "5 hrs ago",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-2.jpg",
      name: "Petey Cruiser",
      currentStatus: "A Day ago",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-2.jpg",
      name: "Petey Cruiser",
      currentStatus: "1 Week ago",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-4.jpg",
      name: "Petey Cruiser",
      currentStatus: "A Month ago",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-3.jpg",
      name: "Petey Cruiser",
      currentStatus: "A year ago",
    },
    {
      currentStatusofPost: null,
      class: null,
      img: "/assets/images/story-2.jpg",
      name: "Petey Cruiser",
      currentStatus: "A year ago",
    },
  ];
  return (
    <>
      <div className="story-section ratio_115">
        {/* ------ Satrt Story View modal ------*/}
        <StoryModalView
          isOpen={storyView}
          storyViewModal={toggleSetStoryView}
        />
        {/*  ------------------ Self Story Modal View  ------------------ */}
        <SelfStoryViewModal
          isOpen={selfStoryModal}
          selfStoryViewModal={toggleSetSelfStoryModal}
        />
        {/* ------ End Story View modal ------*/}
        {/* ------ Satrt Story slider ------*/}
        <Slider {...storySettings} className="default-space">
          <div className="story-box add-box">
            <div className="position-relative">
              <div className="story-bg story-gif-align">
                <img
                  src={Transparentimg}
                  className="mg-fluid bg-img-story bg-img"
                  alt="img-t"
                />
              </div>
              <div className="add-icon">
                <NavLink to={"/CreateStories"}>
                  <div className="text-center">
                    <Fab
                      style={{ background: "#81C14B" }}
                      aria-label="add"
                      size="small"
                    >
                      <Plus color="#FFFFFF" />
                    </Fab>
                    <h5 className="text-white font-weight-bold text-center mt-1">
                      Add stories
                    </h5>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div
            className="story-box added-story-container"
            onClick={toggleSetSelfStoryModal}
          >
            <ProgressBar
              className="story-time-progress rounded-0 w-100"
              variant="primary"
              now={100}
            />
            <div className="story-bg story-gif-align">
              <img
                className="img-fluid added-story rounded"
                src="https://images.pexels.com/photos/6746840/pexels-photo-6746840.jpeg?auto=compress&cs=tinysrgb&w=1600"
                alt="img-t"
              />
            </div>
            <div className="add-icon-story">
              <NavLink to={"/CreateStories"}>
                <div className="text-center">
                  <Fab
                    style={{ background: "#81C14B" }}
                    aria-label="add"
                    size="small"
                  >
                    <Plus color="#FFFFFF" />
                  </Fab>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="story-box" onClick={toggleSetStoryView}>
            <div className="story-bg story-gif-align">
              <img
                src="https://images.pexels.com/photos/14656105/pexels-photo-14656105.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load"
                className="img-fluid bg-img-story"
                data-adaptive-background="1"
                alt=""
              />
              <div className="gif-align">
                <img src={gif_1} alt="..." />
              </div>
            </div>
            <div className="story-content">
              <h6>Rohit sharma</h6>
              <span>2 hour ago</span>
            </div>
          </div>
          {StoryData.map((item, index) => {
            return (
              <div key={index}>
                <div className="story-box" onClick={toggleSetStoryView}>
                  <div className="story-bg story-gif-align">
                    <img
                      src={item.img}
                      className="img-fluid bg-img-story"
                      data-adaptive-background="1"
                      alt=""
                    />
                  </div>
                  <div className="story-content">
                    <h6>{item.name}</h6>
                    <span>{item.currentStatus}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        {/* ------ End Story slider ------*/}
      </div>
    </>
  );
};
