import React from 'react'
import { Fragment } from 'react'


const LegalPolicie = () => {
  
  //================== Start_Legal_Policy_Array_Data =============================//
  const LegalPolicyData = [
    {
      name: "Terms of Service",
      link: "https://www.sociomee.com/terms-conditions/"
    },
    {
      name: " Data Policy",
      link: "https://www.sociomee.com/privacy-policy/"
    },
    {
      name: " Cookies Policy",
      link: "https://www.sociomee.com/cookie-policy/"
    },
    {
      name: "Community Standards",
      link: "https://www.sociomee.com/community-standards/"
    },
    {
      name: "About SocioMee",
      link: "https://www.sociomee.com/about-us/"
    }
  ]
   //================== End_Legal_Policy_Array_Data =============================//

  //====== main_return_function ==========//
  return (
    <Fragment>
      <div className='SettingPrivacy_Main_section'>
        <div className='Legal_Policies_Heading hide-setting-options'>
          <h3>Legal Policies</h3>
        </div>
        <div className='mt-3'>
          {
            LegalPolicyData.map((data) => (
              <div className='Legal_Policies_Blk_section'>
                <h3 className='Legal_Policies_Content_section'>
                  <a className='single-ancor-blk' href={data.link} target='_blank'>{data.name}</a>
                </h3>
              </div>
            ))
          }
        </div>
      </div>
    </Fragment>
  )
}

export default LegalPolicie