import React, { useState } from 'react'
import AudioTopList from './AudioTopList'
import GridAudioTabs from './Grid-Components/GridAudioTabs'

const AudioTop = () => {
    const [data, setdata] = useState(true)
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                {/* <p className='TrendingPeoplethistext'>TOP AUDIOS</p> */}
                <p className='TrendingPeoplethistext'></p>
                <div className='list-grid-toggle rem-mob-view rem-beyond-web'>
                    {/* <Button color='primary' outline className={data ? 'GridButtonView' : ''} onClick={() => setdata(true)}>
                        <Grid size={16} />
                    </Button> */}
                    {/* <Button color='primary' outline className={data ? '' : 'GridButtonView'} onClick={() => setdata(false)}>
                        <List size={16} />
                    </Button> */}
                </div>
            </div>
            {
                // data ? <GridAudioTabs /> : null
                data ? <AudioTopList /> : null
            }
        </div>
    )
}
export default AudioTop 