import React, { useEffect, useContext, useState  } from 'react'
import { Link } from 'react-router-dom'
import MyHosted from './MyHosted'
import { TabSectionData } from './EventsArray'
import MyGoing from './MyGoing'
import MyInsterested from './MyInsterested'
import MyInvitation from './MyInvitation'
import { Fragment } from 'react'
import { Pagination, Stack } from '@mui/material'
import { getEventsAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
const ThemeContext = React.createContext()

const EventsTabSection = ({searchTerm}) => {

    let dispatch = useDispatch();
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    const [refresh,setRefresh]=useState(false)

    useEffect(() => {
        dispatch(getEventsAction('all',userId));
        setRefresh(false)
    }, [dispatch,refresh])

    const getEventsData = useSelector((state) => { return state.getEventsData });
    const { loading: loadingEvents, error: errorEvents, result: resultEvents } = getEventsData
    const TabSectionData = [
        {
            className: "nav-item nav-link active",
            id: "hosting",
            link: "#nav-All",
            ariaControls: "all",
            ariaSelected: "true",
            TabName: `My hosted (${resultEvents?.hostingEventsCount ? resultEvents?.hostingEventsCount : 0})`,
            count: resultEvents?.hostingEventsCount || 0
        },
        {
            className: "nav-item nav-link",
            id: "going",
            link: "#nav-image",
            ariaControls: "nav-image",
            ariaSelected: "false",
            TabName: `Ongoing (${resultEvents?.goingEventsCount ? resultEvents?.goingEventsCount : 0})`,
            count: resultEvents?.goingEventsCount || 0
        },
        {
            className: "nav-item nav-link",
            id: "interested",
            link: "#nav-video",
            ariaControls: "nav-video",
            ariaSelected: "false",
            TabName: `Interested (${resultEvents?.interestedEventsCount ? resultEvents?.interestedEventsCount : 0})`,
            count: resultEvents?.interestedEventsCount || 0
        },
        {
            className: "nav-item nav-link",
            id: "scheduled",
            link: "#nav-Inv",
            ariaControls: "nav-Inv",
            ariaSelected: "false",
            TabName: `Scheduled Event (${resultEvents?.scheduledEventsCount ? resultEvents?.scheduledEventsCount : 0})`,
            count: resultEvents?.scheduledEventsCount || 0
        }
    ];
    
    const filteredTabs = type === "viewer" 
    ? TabSectionData.filter(tab => tab.id === "hosting" && tab.count > 0)
    : TabSectionData.filter(tab => tab.count > 0);

    return (
        <div>
            <nav>
                <div className="tab-section">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {filteredTabs.map((tabinfo) => (
                            <li className="nav-item" key={tabinfo.id}>
                                <Link className={tabinfo.className} id={tabinfo.id} data-bs-toggle="tab"
                                    to={tabinfo.link} role="tab" aria-controls={tabinfo.ariaControls} aria-selected={tabinfo.ariaSelected}>
                                    {tabinfo.TabName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-All" role="tabpanel" aria-labelledby="AllTab">
                                <MyHosted searchTerm={searchTerm} data={resultEvents?.hostingEvents||[]} setRefresh={setRefresh}/>
                            </div>
                            <div className="tab-pane fade" id="nav-image" role="tabpanel" aria-labelledby="ImageTab">
                                <MyGoing searchTerm={searchTerm} data={resultEvents?.goingEvents||[]} setRefresh={setRefresh}/>
                            </div>
                            <div className="tab-pane fade" id="nav-video" role="tabpanel" aria-labelledby="VideoTab">
                                <MyInsterested searchTerm={searchTerm} data={resultEvents?.interestedEvents||[]} setRefresh={setRefresh}/>
                            </div>
                            <div className="tab-pane fade" id="nav-Inv" role="tabpanel" aria-labelledby="InvTab">
                                <MyInvitation searchTerm={searchTerm} data={resultEvents?.scheduledEvents||[]} setRefresh={setRefresh}/>
                            </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default EventsTabSection
