import React, { Fragment, useRef, useState } from 'react'
import { ChevronRight, Copy, CornerUpLeft, Edit2, Link, MoreVertical, PlayCircle, Plus, Send, Trash2 } from 'react-feather'
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { useTheme } from 'styled-components';
import Slider from 'react-slick';
import { useUserAuth } from '../../../Context/userAuthContext';
import { Box, IconButton, Stack } from '@mui/material';
import { FastForwardRounded, FastRewindRounded, PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import Sliders from "react-slick";
import Header from '../../Header';
import LeftSidebar from '../../LeftSidebar';
import RightSidebar from '../../RightSidebar';
import { NavLink } from 'react-router-dom';
const PodcastList = () => {
    const PodcastNavDataText = [
        {
            id: "1",
            NavName: "Single Podcast (25)"
        },
        {
            id: "2",
            NavName: "Series Podcast (75)"
        },
    ];
    const [isCardOpen, setIsCardOpen] = useState(false);

    const handleButtonClick = () => {
        setIsCardOpen(!isCardOpen); // Toggle the state of isCardOpen
    };
    const cardData1 = [
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'purched',
            podcastType: 'Series',
            seires: true
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '01',
            episodType: 'Unpaid',
            podcastType: 'Single',
            seires: false
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'purched',
            podcastType: 'Series',
            seires: true
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '01',
            episodType: 'Unpaid',
            podcastType: 'Single',
            seires: false
        },
        {
            viewerCount: '34000',
            cardTittle: 'Brita Zackari och Parisa',
            category: ' TV & Film',
            seriesCount: '1',
            totalEpisodeCount: '16',
            episodType: 'purched',
            podcastType: 'Series',
            seires: true
        }
    ]
    const { listView, setListView, setMusicBar, musicBar } = useUserAuth();
    // count abbreviation function
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${(num / 1000).toFixed(1).toString()} K`
        } else if (num > 999999) {
            return `${(num / 1000000).toFixed(1).toString()} M`
        } else if (num < 900) {
            return `${num}`
        }
    }
    /*  ---------------------- States --------------------  */
    // const [style, setStyle] = useState({ display: 'none' });
    const [isPlaying, setIsPlaying] = useState(false);
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [comment, SetComment] = useState(false)
    const [deletepodcast, setdeletepodcast] = useState(false)
    const [downloadpodcast, setdownloadpodcast] = useState(false);


    /*  ---------------------- Function for deleting podcast --------------------  */
    const toggledeletepodcast = () => {
        setdeletepodcast((prevState) => !prevState)
    }

    {/* ----------------------------State and function for Report Modal------------------------------------------ */ }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen((preState) => !preState)
    }

    /*  ---------------------- Function & state for sharing podcast --------------------  */
    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setForwardOpen((preState) => !preState)
    }

    /*  ---------------------- Function & state for comment modal of podcast --------------------  */
    const [Commentopen, setcommentclose] = useState(false)
    const toggleComment = () => {
        setcommentclose((preState) => !preState)
    }

    /*---------------------- Function for toggling of podcast ------------------------*/
    const toggledownloadpodcast = () => {
        setdownloadpodcast((prevState) => !prevState)
    }
    let FriendSuggestsettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };
    const [hover, sethover] = useState(0)
    const handleMusicBar = () => {
        setMusicBar(true);
    }
    const musicArray = [
        {
            id: 1,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 2,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 3,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 4,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 5,
            Live: false,
            publisher: 'john wick'
        },
        {
            id: 6,
            Live: true,
            publisher: 'john wick'
        },
        {
            id: 7,
            Live: false,
            publisher: 'john wick'
        }
    ]
    let TopPodcastSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };
    const PodcastTotalList = [
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "paid"

        },
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "Upaid"

        },
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "Unpaid"

        },
        {
            name: "‘Inspired’ Photoshoot",
            title: "Motivational ",
            episode: "Total Episodes  12",
            Viewcount: 2000,
            likeCount: 1000,
            CmntCount: 3000,
            shareCount: 1500,
            typePodcast: "paid"

        }

    ]
    const [sharedOpen, setsharedOpen] = useState(false)
    const toggleShared = () => {
        setsharedOpen((preState) => !preState)
    }
    const handlePlay = () => {
        const audio = audioRef.current;
        if (audio.paused) {
            audio.play();
            setPaused(false);
        } else {
            audio.pause();
            setPaused(true);
        }
    };
    const handleFastForward = () => {
        const audio = audioRef.current;
        audio.currentTime += 10; // Fast forward by 10 seconds (adjust as needed)
    };
    const theme = useTheme();
    // const mainIconColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF';
    const getPodCastData = useSelector((state) => { return state.getTextPostsData });
    const { loading: loadingAllTextPosts, error: errorAllTextPosts, result: resultAllTextPosts } = getPodCastData
    const [isDivOpen, setIsDivOpen] = useState(false);

    const handleImageClick = () => {
        setIsDivOpen(!isDivOpen); // Toggle the state of isDivOpen
    };
    const [paused, setPaused] = useState(false);
    const audioRef = useRef(null);
    const [volume, setVolume] = useState(1);
    const [selectActive, setSelectActive] = useState(false)
    const AllTypesPodcast = (props) => (
        <Fragment>
            <CardTitle className={props.className}>
                <h4>{props.title}</h4>
                <p className='d-flex align-items-center '
                    onClick={() => setSelectActive(!selectActive)}>{props.nav}<ChevronRight size={16} /></p>
            </CardTitle>
            <div className="podcast-list-block">
                <Sliders {...TopPodcastSettings} className="default-space">
                    {
                        resultAllTextPosts?.map((data) => (
                            <div>
                                <Card className="TopPodcast_Section">
                                    <img src={data?.podcastDetails?.coverImageURL} className="img-fluid " alt="podCast" style={{ height: '175px' }} />
                                    <div className='TopPodcat_Left_Side'>
                                        {/* <img src={MicImg} width={18} height={19} alt="mic_img" /> */}
                                        <p className='ml-1'>{numFormatter(data?.podcastDetails?.listenerCount)}+++</p>
                                    </div>
                                    <div className='TopPodcat_Left_right'>
                                        <h4>{data?.podcastDetails?.totalEpisode}</h4>
                                    </div>
                                    <div className='TopPodcat_bottom_left'>
                                        <h4>{data?.podcastDetails?.title}</h4>
                                    </div>
                                    {data?.podcastDetails?.isPaid === "paid" ? <>
                                        <div className='TopPodcast_bottom_right'>
                                            <img src={data?.podcastDetails?.mediaURL} alt="img" className='img-fluid' width={70} height={22} />
                                        </div></> :
                                        <>
                                        </>
                                    }
                                </Card>
                            </div>
                        ))
                    }
                </Sliders>
            </div>
        </Fragment>
    )
    return (

        <div>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />

                <div className="container-fluid section-t-space px-0 m-3">
                    <div className="gallery-page-section section-b-space">
                    <div className="card-title">
                            <h3>Analytics Podcast (162)</h3>
                            <div className="right-setting">
                                <div className="profile-search-blk ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find ..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                                <NavLink to="#" className="btn btn-outline-green ms-3">Explore</NavLink>
                                <NavLink to="#" className="btn btn-outline-green ms-3">Create / Upload</NavLink>
                            </div>
                        </div>

                        <CardBody>
                            {!selectActive ? (
                                <Row>
                                    <Col md="8" className='border-right'>
                                        <AllTypesPodcast
                                            title="My Top Podcast"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between"
                                        />
                                        <AllTypesPodcast
                                            title="TV & Film"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                        <AllTypesPodcast
                                            title="Health & Fitness"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                        <AllTypesPodcast
                                            title="Socity & Culture"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                        <AllTypesPodcast
                                            title="Religion & Sprituality"
                                            nav="See All"
                                            className="PodCast_Title d-flex justify-content-between mt-4"
                                        />
                                    </Col>
                                    <Col md="4">
                                        <CardTitle className="Title_Podcast d-flex justify-content-between">
                                            <h4>Recently Played</h4>
                                            <div>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color='flat-primary' >
                                                        <MoreVertical color='#6E6B7B' size={20} />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="PodCast_DropDown_item">
                                                        <DropdownItem>
                                                            <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                        <DropdownItem>
                                                            <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </div>

                                        </CardTitle>
                                        <Card>
                                            <div>
                                                <img src="https://sociomeeofficial.com/static/media/media.364082e8.png" alt="" className='w-100' />
                                            </div>
                                            <CardBody className="Recently__Card_buttom_section">
                                                <h2>Brita Zackari ochdf </h2>
                                                <audio id="audio-cont" ref={audioRef} controls>
                                                    <source src={`${process.env.REACT_APP_IMAGEURL}/podcast/s3D9A0kImb3Em.mp3`} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        mt: 1,
                                                    }}
                                                >
                                                    <IconButton aria-label="previous song">
                                                        <FastRewindRounded fontSize="small" style={{ color: "#979797" }} />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label={paused ? 'play' : 'pause'}
                                                        onClick={handlePlay}
                                                        className="center_btn_action"
                                                        style={{ backgroundColor: "#81C14B" }}
                                                    >
                                                        {paused ? (
                                                            <PlayArrowRounded
                                                                sx={{ fontSize: '2rem' }}
                                                            // htmlColor={mainIconColor}


                                                            />
                                                        ) : (
                                                            <PauseRounded sx={{ fontSize: '2rem' }}
                                                            // htmlColor={mainIconColor}
                                                            />
                                                        )}
                                                    </IconButton>
                                                    <IconButton aria-label="next song">
                                                        <FastForwardRounded fontSize="small" style={{ color: "#979797" }} onClick={handleFastForward} />
                                                    </IconButton>
                                                </Box>
                                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} width={50} alignItems="center">
                                                    {/* <Slider
                                                        aria-label="Volume"
                                                        defaultValue={30}
                                                        sx={{
                                                            color: theme.palette.mode === 'dark' ? '#81C14B' : '#81C14B',
                                                            '& .MuiSlider-track': {
                                                                border: 'none',
                                                            },
                                                            '& .MuiSlider-thumb': {
                                                                width: 24,
                                                                height: 24,
                                                                backgroundColor: '#fff',
                                                                '&:before': {
                                                                    boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                                                },
                                                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                                    boxShadow: 'none',
                                                                },
                                                            },
                                                        }}
                                                    /> */}
                                                </Stack>
                                            </CardBody>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className="Recently_Player_List">
                                                    <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' className="img-fluid video_image" alt='' style={{ width: '90px' }} />
                                                    <div className='Play_Btn_Section'>
                                                        {/* <img src={Play} className="img-fluid" alt='' /> */}
                                                    </div>
                                                    <div className='Media_name ml-2'>
                                                        <div className="Suggestmember-blk">
                                                            <span></span> • <span>Total Episodes 12</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>

                                            </div>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className="Recently_Player_List">
                                                    <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' className="img-fluid video_image" alt='' style={{ width: '90px' }} />
                                                    <div className='Play_Btn_Section'>
                                                        {/* <img src={Play} className="img-fluid" alt='' /> */}
                                                    </div>
                                                    <div className='Media_name ml-2'>
                                                        <div className="Suggestmember-blk">
                                                            <span></span> • <span>Total Episodes 12</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>

                                            </div>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <div className="Recently_Player_List">
                                                    <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' className="img-fluid video_image" alt='' style={{ width: '90px' }} />
                                                    <div className='Play_Btn_Section'>
                                                        {/* <img src={Play} className="img-fluid" alt='' /> */}
                                                    </div>
                                                    <div className='Media_name ml-2'>
                                                        <div className="Suggestmember-blk">
                                                            <span></span> • <span>Total Episodes 12</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color='flat-primary' >
                                                            <MoreVertical color='#6E6B7B' size={20} />
                                                        </DropdownToggle>
                                                        <DropdownMenu className="PodCast_DropDown_item">
                                                            <DropdownItem>
                                                                <Edit2 size={15} color="#4B4B4B" />&nbsp; Edit podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <PlayCircle size={15} color="#4B4B4B" />&nbsp; Play podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Plus size={15} color="#4B4B4B" />&nbsp; Create new podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Trash2 size={15} color="#4B4B4B" />&nbsp; Delete podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Send size={15} color="#4B4B4B" />&nbsp; Share podcast</DropdownItem>
                                                            <DropdownItem>
                                                                <Copy size={15} color="#4B4B4B" />&nbsp; Copy link</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>

                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (

                                <Fragment>
                                    <CardTitle className="PodCast_Title d-flex justify-content-between ">
                                        <h3>My Top Podcast</h3>
                                        <Button onClick={() => setSelectActive(!selectActive)} outline color='secondary'><CornerUpLeft size={14} />&nbsp; Back</Button>
                                    </CardTitle>
                                    <Row className="mt-4">
                                        {
                                            PodcastTotalList.map((data) => (
                                                <Col md="6">

                                                    <div className=' Podcast_Media_Card_Box border p-2 mb-3'>
                                                        <img src='https://sociomeeofficial.com/static/media/media.364082e8.png' alt="img" className=' Podcast_media_blk' />
                                                        <p className='Media_Mic'><img src='https://sociomeeofficial.com/static/media/Mic1.fcd0755a.png' alt="img" className='img-fluid' width={40} height={40} /></p>
                                                        {data.typePodcast === "paid" ? <>
                                                            <div className='Media_Paid'>
                                                                <img src='/static/media/PaidTag.20ee1678.svg' alt="pd" className='img-fluid Media_Paid' />
                                                            </div>
                                                        </> : <>

                                                        </>
                                                        }
                                                        <div className='w-100 ml-2 '>
                                                            <NavLink className='Podcast_link_heading' to="/MyPodcastDetails"><h3  >{data.name}</h3></NavLink>
                                                            <h4 className='py-2'>{data.title} </h4>
                                                            <p className='text-muted'>{data.episode}</p>
                                                            <div className='d-flex justify-content-between mt-3'>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='PodCast_Icon_Section rounded-circle'>
                                                                        <img src='/static/media/eye.57c2f094.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }} onClick={toggleShared}>{numFormatter(data.shareCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src='/static/media/LikeBlack.7fd2858a.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }}>{numFormatter(data.likeCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src='/static/media/cmnt.974e04cc.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }} onClick={toggleComment} >{numFormatter(data.CmntCount)}</span>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="PodCast_Icon_Section rounded-circle">
                                                                        <img src='/static/media/share.d1c92cfb.svg' alt='' />
                                                                    </div>
                                                                    <span className=' ml-3 Span_text' style={{ cursor: "pointer" }} onClick={toggleShared}>{numFormatter(data.shareCount)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {data.typePodcast === "paid" ? <>
                                                                <MoreVertical style={{ cursor: "pointer" }} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size={20} color="#6E6B7B" />
                                                                <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal podcast_option">
                                                                    <ul>
                                                                        <li>
                                                                            <Link to="#">Play all</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Edit Series</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Add new episode</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Create new series</Link>

                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Delete series</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">Bookmark</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#">View Series Analytics</Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link to="#" style={{ color: "#1066AD" }}>Learn more</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div></> :
                                                                <>
                                                                    <MoreVertical style={{ cursor: "pointer" }} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size={20} color="#6E6B7B" />
                                                                    <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal podcast_option">
                                                                        <ul>
                                                                            <li>
                                                                                <Link to="#">Edit podcast</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Play podcast</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Create new podcast</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#">Delete podcast</Link>

                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" style={{ color: "#1066AD" }}>Learn more</Link>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Fragment>
                            )}
                        </CardBody>
                    </div>
                </div>
                <RightSidebar />
            </div>
         
        </div>
    )
}

export default PodcastList
