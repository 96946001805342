import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { ArrowLeft, ArrowRight, ChevronDown, Copy, Download, Edit, Filter, Grid, Paperclip, Plus, RotateCcw, Search, Trash2, X } from 'react-feather'
import ReactPaginate from 'react-paginate'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, PopoverBody, PopoverHeader, Row, UncontrolledButtonDropdown, UncontrolledPopover } from 'reactstrap'
import coverImage from '../../../NewComponents/IMG/reel4.png'
import ChartIcon from '../../../NewComponents/IMG/AdmangerIconChart.svg'
import BlackPin from '../../../NewComponents/IMG/BlackPin.svg'
import AdManagerSearch from '../Sub-components/AdManagerSearch'
import { Pagination, Popover, Stack, Typography } from '@mui/material'
import ArchiveComponent from './ArchiveComponent'
import archive from '../../Events-Components/Img/archive.svg'

const styleTable = {
    rows: {
        style: {
            minHeight: '80px',
            textDecorationLine: 'none', // override the row height
        }
    },
    headCells: {
        style: {
            backgroundColor: '#E5F0E0',
            fontSize: '12px',
            paddingLeft: '12px', // override the cell padding for head cells
            paddingRight: '4px',
            whiteSpace: '',
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            textTransform: 'uppercase'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            // marginTop: '1%',
            color: '#4D4D4D',
            fontSize: '12px',
            fontWeight: '400'
        }
    }
}

const CompletedTable = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [render, setRender] = useState()
    const [totalItems, SetTotalItems] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])
    const [checkList, setCheckList] = useState({
        serialnoCheck: true,
        OffOnCheck: true,
        adNameCheck: true,
        adIdCheck: true,
        adTypeCheck: true,
        totalImpressionsCheck: true,
        totalReachCheck: true,
        clickCheck: true,
        viewCheck: true,
        leadCheck: true,
        totalAmountCheck: true,
        dailyBudgetCheck: true,
        overallBudgetCheck: true,
        startDateCheck: true,
        endDateCheck: true,
        lastSignificantCheck: true,
    })

    // const handleSearch = (event) => {
    //   setSearchValue(event.target.value)
    //   setRender(!render)
    // }


    /*---------------Archieved Ad function--------------*/

    const [Archive, setArchive] = useState(false)

    const toggleArchived = (id) => {
        setArchive(!Archive)
    }


    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const [anchorEl8, setAnchorEl8] = useState(null);
    const [anchorEl9, setAnchorEl9] = useState(null);
    const [anchorEl10, setAnchorEl10] = useState(null);

    const handlePopoverOpen = (event, setAnchorEl) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (setAnchorEl) => {
        setAnchorEl(null);
    };

    //-------------------------- handle Table Selected rows : part 1
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows)
    }

    const [deleteallModal, setDeleteallModal] = useState(false)
    const [restartModal, setRestartModal] = useState(false)


    const DeletedModal = () => {
        return (
            <>
                <Modal isOpen={deleteallModal} className='modal-dialog-centered' toggle={() => setDeleteallModal(!deleteallModal)}>
                    {/* <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader> */}
                    <ModalBody>
                        <div className='text-center mb-2'>
                            {/* <Trash2 size={70} color="#EA5455" /> */}
                            <img src={archive} alt="" style={{ color: 'green' }} />
                            <h3 className='mt-2'>{selectedRows.length === 0 ? '' : 'Do You Really Archived Ad ?'}</h3>
                        </div>
                        <div className='text-center mb-2'>
                            <h5>Yes really archived the ad</h5>
                        </div>
                        {/* <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the ‘Archived Ad’ screen." />
                        </InputGroup> */}
                        <div className="d-flex justify-content-center m-1 mt-3">
                            <div >  <Button type="submit" color="success" outline >Yes , Archived it</Button></div>
                            <div className='ml-3'><Button color="success" outline onClick={() => setDeleteallModal(!deleteallModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
    // =========================re-start==============
    const RestartModal = () => {
        return (
            <>
                <Modal isOpen={restartModal} className='modal-dialog-centered' toggle={() => setRestartModal(!restartModal)}>
                    {/* <ModalHeader toggle={() => setDeleteallModal(!deleteallModal)}></ModalHeader> */}
                    <ModalBody>
                        <div className='text-center mb-2'>
                            <h3 className='mt-2'>{selectedRows.length === 0 ? '' : 'Do You Really Restart the Ad ?'}</h3>
                        </div>
                        <div className='text-center mb-2'>
                            <h5>Yes you really restart the ad</h5>
                        </div>
                        {/* <h5>Additional comment :</h5>
                        <InputGroup className='input-group-merge mt-1'>
                            <Input type='textarea' rows="4" placeholder="This comment will be visible in the Restart Ad’ screen." />
                        </InputGroup> */}
                        <div className="d-flex justify-content-center m-1 mt-3">
                            <div >  <Button type="submit" color="success" outline >Yes , Restart it</Button></div>
                            <div className='ml-3'><Button color="success" outline onClick={() => setRestartModal(!restartModal)} >No , Go Back</Button></div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }

    const tabledata = [
        {
            id: '1',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#4567898',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z'
        },
        {
            id: '2',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#4567898',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z'
        },
        {
            id: '3',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#4567898',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '13',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z'
        },
        {
            id: '4',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#456788',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z'
        },
        {
            id: '5',
            Adname: 'New Traffic Ad - Kickstarter',
            AdId: '#456789',
            AdType: 'Brand Awareness (CPV)',
            Impressions: '954',
            TotalReach: '654',
            NoOfClick: '12',
            NoOfView: '12',
            NoOfLead: '12',
            totalAmountSpent: '$9578',
            dailBudget: '$9578',
            overAllBudget: '$9578',
            startDateTime: '2022-11-05T11:13:00.000Z',
            endDateTime: '2022-11-08T11:18:00.000Z',
            lastSingnificantEdit: '2022-11-05T11:13:00.000Z'
        }
    ]

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + (currentPage * rowsPerPage) + 1,
            omit: !checkList.serialnoCheck,
            center: true,
            sortable: true
        },

        {
            name: 'Complete Status',
            sortable: true,
            omit: !checkList.OffOnCheck,
            minWidth: "150px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl1)} onMouseLeave={() => handlePopoverClose(setAnchorEl1)} className="d-flex align-items-center" >
                    <div className='d-flex align-items-center fontForSubTablheading'>
                        <h5 className='mt-2'>Off</h5>
                        <label className="switch m-1">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                        <h5 className='mt-2'>On</h5>
                    </div>
                    <Popover
                        open={Boolean(anchorEl1)}
                        anchorEl={anchorEl1}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Click the on/off button to showcase your ad and if you wish to stop then click on the off button.
                            </h5>
                            {/*  voluptatum voluptatem aliquam neque facere recusandae magnam impedit. */}
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },

        {
            name: "Ad Name",
            selector: row => row.Adname,
            minWidth: "320px",
            omit: !checkList.adNameCheck,
            sortable: true,

            cell: row => (
                <Link to="#" className='align-items-start d-flex pb-2 '>
                    <div className='align-items-start'>
                        <img className='border rounded' src={coverImage} alt='Profile_Picture' style={{ width: "50px", height: "50px" }} />
                    </div>
                    <div style={{ color: '#4D4D4D' }} className='ml-1 onHoverForshowed fontForSubTablheading'>
                        <h4 to="#" className='mb-2' style={{ fontSize: '14px' }}>
                            {row.Adname}
                        </h4 >
                        <div className='d-flex OnMouseDataShow'>
                            <div className='d-flex text-primary align-items-center'>
                                <img src={ChartIcon} alt='' />
                                <NavLink className='ml-1' to={'/AdAnalysis'}><u>View Charts</u></NavLink>
                            </div>
                            <h6 className='mx-2'><Copy strokeWidth={4} color='#6E6B7B' size={12} /> <u style={{ color: '#0066FF' }} >Duplicate</u></h6>
                            <div className='d-flex align-items-center'>
                                {/* <h6><Edit strokeWidth={4} color='#6E6B7B' size={12} /> Edit</h6> */}
                                <img src={BlackPin} alt='' />&nbsp;<h6>Pin</h6>
                            </div>
                        </div>
                    </div>
                </Link>
            )
        },

        {
            name: 'Ad ID',
            sortable: true,
            omit: !checkList.adIdCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl2)} onMouseLeave={() => handlePopoverClose(setAnchorEl2)} className="d-flex align-items-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '12px', marginLeft: '20px' }}>
                        {row.AdId}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl2)}
                        anchorEl={anchorEl2}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>Ad id unique identification number for particular Ad</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Ad Type',
            sortable: true,
            omit: !checkList.adTypeCheck,
            minWidth: "200px",
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl3)} onMouseLeave={() => handlePopoverClose(setAnchorEl3)} className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '6px' }}>
                        {row.AdType}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl3)}
                        anchorEl={anchorEl3}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>It is a digital advertising metric that measures the cost incurred  for each view or impression of the content.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Impressions',
            sortable: true,
            omit: !checkList.totalImpressionsCheck,
            minWidth: "150px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl4)} onMouseLeave={() => handlePopoverClose(setAnchorEl4)} className="d-flex align-items-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '12px', marginLeft: '-37px' }}>
                        {row.Impressions}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl4)}
                        anchorEl={anchorEl4}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>You can get to know on how much impression your adverstment has gained so far.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Reach',
            sortable: true,
            omit: !checkList.totalReachCheck,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl5)} onMouseLeave={() => handlePopoverClose(setAnchorEl5)} className="d-flex align-items-center fontForSubTablheading" >
                    <h5 style={{ fontSize: '12px', marginLeft: '-19px' }}>
                        {row.TotalReach}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl5)}
                        anchorEl={anchorEl5}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>The total number of unique individuals products exposed to a particular ad or campaign.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },

        {
            name: 'View',
            sortable: true,
            omit: !checkList.viewCheck,
            selector: row => row.NoOfView,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl6)} onMouseLeave={() => handlePopoverClose(setAnchorEl6)} className="d-flex align-items-center underLineForLink fontForSubTablheading" >
                    <h5 className='underlinetext' style={{ fontSize: '12px', marginLeft: '-13px' }}>
                        {row.NoOfView}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl6)}
                        anchorEl={anchorEl6}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>It refers to the act of a user seeing or watching a specific piece of content, such as an advertisement.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Lead',
            sortable: true,
            omit: !checkList.leadCheck,
            selector: row => row.NoOfLead,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl7)} onMouseLeave={() => handlePopoverClose(setAnchorEl7)} className="d-flex align-items-center underLineForLink fontForSubTablheading" >
                    <h5 className='underlinetext'>
                        <NavLink className='text-body' to='/LeadGeneration' style={{ fontSize: '12px', marginLeft: '-13px' }}>{row.NoOfLead}</NavLink>
                    </h5>
                    <Popover
                        open={Boolean(anchorEl7)}
                        anchorEl={anchorEl7}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >

                        <PopoverBody>
                            <h5>This signifies a potential customer who has shown interest in your product or service by providing contact information</h5>
                        </PopoverBody>


                    </Popover>
                </div>
            )

        },

        {
            name: 'Click',
            sortable: true,
            omit: !checkList.clickCheck,
            selector: row => row.NoOfClick,
            minWidth: "100px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl8)} onMouseLeave={() => handlePopoverClose(setAnchorEl8)} className="d-flex align-items-center underLineForLink fontForSubTablheading" >
                    <h5 className='underlinetext'>
                        <span style={{ boxSizing: "content-box", fontSize: '12px', marginLeft: '-13px' }}>{row.NoOfClick}</span>
                    </h5>
                    <Popover
                        open={Boolean(anchorEl8)}
                        anchorEl={anchorEl8}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <PopoverBody>
                            <h5>Clicks in the ad dashboard represent the number of times users have interacted with your ad by clicking on it.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },

        {
            name: 'Spent Amount',
            sortable: true,
            omit: !checkList.totalAmountCheck,
            minWidth: "150px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl9)} onMouseLeave={() => handlePopoverClose(setAnchorEl9)} className="d-flex align-items-center fontForSubTablheading" >
                    <h5 className='text-danger' style={{ fontSize: '12px', marginLeft: '-44px' }}>
                        {row.totalAmountSpent}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl9)}
                        anchorEl={anchorEl9}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                            <h5>The spent amount in your ad dashboard reflects the total expenditure on your advertising campaign.</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        {
            name: 'Daily Budget',
            sortable: true,
            omit: !checkList.dailyBudgetCheck,
            minWidth: "150px",
            center: true,
            cell: row => (
                <div onMouseEnter={(e) => handlePopoverOpen(e, setAnchorEl10)} onMouseLeave={() => handlePopoverClose(setAnchorEl10)} className="d-flex align-items-center fontForSubTablheading" >
                    <h5 className='text-primary' style={{ fontSize: '12px', marginLeft: '-44px' }}>
                        {row.dailBudget}
                    </h5>
                    <Popover
                        open={Boolean(anchorEl10)}
                        anchorEl={anchorEl10}
                        PaperProps={{ style: { maxWidth: 400 } }}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        {/* <PopoverHeader>View</PopoverHeader> */}
                        <PopoverBody>
                        <h5>Your daily budget is the maximum amount you're willing to spend on advertising in a single day</h5>
                        </PopoverBody>
                    </Popover>
                </div>
            )

        },
        // {
        //     name: 'Over all Budget',
        //     sortable: true,
        //     omit: !checkList.overallBudgetCheck,
        //     minWidth: "180px",
        //     cell: row => (
        //         <div className="d-flex align-items-center text-info" >
        //             <b>
        //                 {row.overAllBudget}
        //             </b>
        //         </div>
        //     )

        // },

        {
            name: "Start Date & Time",
            minWidth: "180px",
            omit: !checkList.startDateCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.startDateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.startDateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Ends Date & Time",
            minWidth: "200px",
            omit: !checkList.endDateCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.endDateTime).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.endDateTime).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Last edited",
            minWidth: "200px",
            omit: !checkList.lastSignificantCheck,
            sortable: true,
            cell: row => (
                <div className='fontForSubTablheading'>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.lastSingnificantEdit).format('MMM Do YYYY')}</h5>
                    <h5 style={{ fontSize: '12px', marginLeft: '9px' }}>{moment(row.lastSingnificantEdit).format('hh:mm:ss a')}</h5>
                </div>
            )
        },

        {
            name: "Action",
            minWidth: "200px",
            omit: !checkList.lastSignificantCheck,

            sortable: true,
            cell: row => (
                <div onClick={() => setArchive(!Archive)}>
                    {/* {Archive ? <Button color='primary'>
                        Archived Ad
                    </Button> :
                        <Button outline color='primary'>
                            Archive Ad
                        </Button>} */}
                    <ArchiveComponent />
                </div>
            )
        }
    ]


    const checkListArr = [
        {
            check: {
                ...checkList,
                serialnoCheck: !checkList.serialnoCheck
            },
            state: checkList.serialnoCheck,
            name: "Sr. No"
        },
        {
            check: {
                ...checkList,
                OffOnCheck: !checkList.OffOnCheck
            },
            state: checkList.OffOnCheck,
            name: "Off/On"
        },
        {
            check: {
                ...checkList,
                adNameCheck: !checkList.adNameCheck
            },
            state: checkList.adNameCheck,
            name: "Ad Name"
        },
        {
            check: {
                ...checkList,
                adIdCheck: !checkList.adIdCheck
            },
            state: checkList.adIdCheck,
            name: "Ad ID"
        },
        {
            check: {
                ...checkList,
                adTypeCheck: !checkList.adTypeCheck
            },
            state: checkList.adTypeCheck,
            name: "Ad Type"
        },
        {
            check: {
                ...checkList,
                totalImpressionsCheck: !checkList.totalImpressionsCheck
            },
            state: checkList.totalImpressionsCheck,
            name: "Impressions"
        },
        {
            check: {
                ...checkList,
                totalReachCheck: !checkList.totalReachCheck
            },
            state: checkList.totalReachCheck,
            name: "Reach"
        },
        {
            check: {
                ...checkList,
                viewCheck: !checkList.viewCheck
            },
            state: checkList.viewCheck,
            name: "View"
        },
        {
            check: {
                ...checkList,
                leadCheck: !checkList.leadCheck
            },
            state: checkList.leadCheck,
            name: "Lead"
        },
        {
            check: {
                ...checkList,
                clickCheck: !checkList.clickCheck
            },
            state: checkList.clickCheck,
            name: "Click"
        },
        {
            check: {
                ...checkList,
                totalAmountCheck: !checkList.totalAmountCheck
            },
            state: checkList.totalAmountCheck,
            name: "Spent Amount"
        },
        {
            check: {
                ...checkList,
                dailyBudgetCheck: !checkList.dailyBudgetCheck
            },
            state: checkList.dailyBudgetCheck,
            name: "Daily Budget"
        },
        {
            check: {
                ...checkList,
                startDateCheck: !checkList.startDateCheck
            },
            state: checkList.startDateCheck,
            name: "Start Date & Time"
        },
        {
            check: {
                ...checkList,
                endDateCheck: !checkList.endDateCheck
            },
            state: checkList.endDateCheck,
            name: "Ending Date & Time"
        },
        {
            check: {
                ...checkList,
                lastSignificantCheck: !checkList.lastSignificantCheck
            },
            state: checkList.lastSignificantCheck,
            name: "Last edited"
        }
    ]

    const Cardheader = () => {
        return (<div className='nav-flex-container mx-3 my-3'>
            <div className='nav-flex size-sm-mt-4'>
                <div className='mr-1'>
                    <Button color='primary font-weight-bold'
                        onClick={() => navigate('/CreateAd')}
                    >
                        <Plus size={18}
                        /> Create new Ad
                    </Button>
                </div>
                <div className='mr-1'>
                    <Button outline color='primary' className='font-weight-bold'>
                        <Download size={18} /> Export </Button>
                </div>
            </div >

            <div className='nav-flex size-sm-mt-4'>
                <div style={{ position: 'relative' }} className='d-flex justify-content-between '>

                    <Input type='search' style={{ paddingLeft: '25px', marginTop: '3px' }} size='58' className='' Placeholder='Search here...' />
                    <div style={{ position: 'absolute', top: '9px', marginLeft: '5px' }}>
                        <Search size={15} />
                    </div>&nbsp;&nbsp;
                    {/* <div>
                        <button className='form-control mt-1' style={{ backgroundColor: 'rgba(129, 193, 75, 1)', color: 'white', border: 'none' }}>Search</button>
                    </div> */}
                </div>
                <div className='mt-1'>
                    <UncontrolledButtonDropdown direction='down'>
                        <DropdownToggle color="transparent" className="ml-1 p-0" >
                            <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                <Filter size={22} color='#81C14B' />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className='shadow runningdrop' style={{ zIndex: '100', minWidth: 'fit-content', marginLeft: '-10rem', marginTop: '3px' }}>
                            <DropdownItem tag='a'>Brand Awareness (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Website(Link) Visits (CPC)</DropdownItem>
                            <DropdownItem tag='a'>Post Engagement (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Video Views (CPV)</DropdownItem>
                            <DropdownItem tag='a'>Lead Generation (CPL)</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle color="transparent" className="ml-1 p-0" >
                            <div className='border border-primary rounded' style={{ padding: '6px', alignSelf: 'center' }}>
                                <Grid size={22} color='#81C14B' />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className='scrollForAdmanagerFilter dropedownForFilter runningdroplist mt-2' right style={{ position: 'absolute', willChange: 'transform', top: '0rem', left: '-20px', transform: "translate3d(-132px, 36px, 0px)" }}>
                            {checkListArr.map((value, i) => {
                                return (
                                    <DropdownItem className='w-100' onClick={() => setCheckList(value.check)} >
                                        <CustomInput type="checkbox" className='customcheckboxForFilter' id={`group-checkbox-${i}`} label={value.name} style={{ cursor: "pointer" }} checked={value.state} />
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
        </div >
        )
    }

    const onChangeRowsPerValue = (e) => {
        setRowsPerPage(e.currentTarget.value)
        const offset = currentPage * rowsPerPage
        if (offset >= rowsPerPage) {
            setCurrentPage(0)
        }
        setRender(!render)
    }

    const CustomPagination = () => {

        const count = Number(Math.ceil(totalItems / rowsPerPage))

        return (
            <div className='d-flex justify-content-between mt-2'>
                <div className="d-flex align-items-center">
                    <Label for='row-per-page' className='text-nowrap'>Row per page</Label>
                    <CustomInput
                        className="form-control mx-50 px-1.5 ml-1 w-50"
                        style={{ backgroundColor: '#E5F0E0' }}
                        type='select'
                        id='rows-per-page'
                        value={rowsPerPage}
                        onChange={onChangeRowsPerValue}
                    >
                        <option value='5'>5 Row</option>
                        <option value='10'>10 Row</option>
                        <option value='25'>25 Row</option>
                        <option value='50'>50 Row</option>
                        <option value='100'>100 Row</option>
                    </CustomInput>
                </div>
                {/* <ReactPaginate
                    previousLabel={<ArrowLeft color='#4D4D4D' size={15} />}
                    nextLabel={<ArrowRight color='#4D4D4D' size={15} />}
                    pageCount={count}
                    activeClassName='active'
                    forcePage={currentPage}
                    onPageChange={page => {
                        setCurrentPage(page.selected)
                        setRender(!render)
                    }}
                    pageClassName='page-item'
                    nextClassName='page-item next'
                    nextLinkClassName='page-link'
                    previousClassName='page-item prev'
                    previousLinkClassName='page-link'
                    pageLinkClassName='page-link'
                    containerClassName="pagination react-paginate justify-content-end my-2 pr-1"
                /> */}
                <div className='d-flex justify-content-center mb-2'>
                    <Stack>
                        <Pagination count={10} className='PaginationCount'>
                        </Pagination>
                    </Stack>
                </div>
            </div>
        )
    }


    useEffect(() => {
        // getPostfeedLikedetails()
    }, [render])
    return (

        <div>

            <Card>
                <CardBody className='py-0'>
                    <DeletedModal />
                    <RestartModal />
                    <div className='mb-2'>
                        <Cardheader />
                    </div>
                    {
                        selectedRows.length !== 0 &&
                        <div style={{ backgroundColor: '#E3F2FD' }} className='d-flex justify-content-between align-items-center p-2 mb-1'>
                            <h4> {selectedRows.length} item selected</h4>
                            <Col>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <Button color='primary' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                                        Archive Ad
                                    </Button>&nbsp;&nbsp;
                                    {/* <Button color='primary mx-2' outline >
                                        Reports
                                    </Button> */}
                                    <Button color='primary' outline onClick={() => setRestartModal(!restartModal)}>
                                        Re-Start Ad
                                    </Button>
                                </div>
                            </Col>
                            {/* <Button color='danger' outline onClick={() => setDeleteallModal(!deleteallModal)}>
                                <Trash2 size={16} />
                            </Button> */}
                        </div>
                    }
                    <Row>
                        <Col>
                            <DataTable
                                noHeader
                                responsive
                                // selectableRowSelected={selectedRows}
                                selectableRows
                                paginationServer
                                selectableRowsHighlight
                                highlightOnHover
                                pointerOnHover
                                onSelectedRowsChange={handleChange}
                                sortIcon={<ChevronDown />}
                                paginationComponent={CustomPagination}
                                columns={columns}
                                data={tabledata}
                                customStyles={styleTable}
                                progressPending={loading}
                            />
                        </Col>
                    </Row>
                    <CustomPagination />
                </CardBody>
            </Card>
            <DeletedModal />
        </div>
    )
}

export default CompletedTable