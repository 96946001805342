import React, { useEffect, useState, useContext  } from 'react'
import { Fragment } from 'react'
import { EventCardData } from './EventsArray'
import { Button, Col, Row } from 'reactstrap'
import { Eye, EyeOff, Link2, MapPin, MoreHorizontal, Users } from 'react-feather'
import { Link } from 'react-router-dom'
import DeletedEventModal from './EventsModal/DeletedEventModal'
import ShareEventModal from './EventsModal/ShareEventModal'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsAction,postShowAction,hideAllPostsFromTimelineAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import axios from 'axios'
import { Pagination, Stack,PaginationItem } from '@mui/material'
import { useNavigate,useLocation } from 'react-router-dom';

const ThemeContext = React.createContext();
const MyHosted = (props) => {   

  let dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const type = new URLSearchParams(window.location.search).get('type');
  const userId = new URLSearchParams(window.location.search).get('userId');

  const deleteEvents = useSelector((state) => { return state.deletePostData });
  const { result: resultDeleteEvents } = deleteEvents

  
  const [pageSize,setPageSize]=useState(12);
  const [pageIndex,setPageIndex]=useState(0);
  const [deleteMessage, setDeleteMessage] = useState(false)
  const [data,setData]=useState([]);

  const handleSuccess = (msg) => {
    if(msg === 'success'){
      setDeleteMessage(true)
    }
  };

  const navigateToSomewhere =(e,id)=>{
    e.stopPropagation();
    e.preventDefault();
    navigate(`/MyPostDetail/${id}`,{ state: { userId, type:"viewer" }})
  }

  const [deleteOpen, setDeleteOpen] = useState(false)


  const toggleDeleteOpen = () => {
    setDeleteOpen((prevState) => !prevState)
  }
  const [shareOpen, setShareOpen] = useState(false)
  const [apiRedirect, setApiRedirect] = useState(0)
  const [showDescription, setShowDescription] = useState(false);
  const [showAddress, setShowAddress] = useState(false);

  const toggleShareOpen = (postId, btnChange) => {
   setApiRedirect(btnChange)
    setShareOpen((prevState) => !prevState)
  }

  const hideOrUnhideIcon = () => {
    alert('API Issue')
  }

  let [postId, setPostId] = useState('')
  let [caption, setCaption] = useState('')
  let [locationLAT, setLocationLAT] = useState('')
  let [locationLONG, setLocationLONG] = useState('')
  let [allowComment, setAllowComment] = useState('')

  const [expandedEventId, setExpandedEventId] = useState(null);
  const toggleEventAddress = (eventId) => {
    if (eventId === expandedEventId) {
      setExpandedEventId(null);
    } else {
      setExpandedEventId(eventId);
    }
  };

  const [expandedEventDescription, setExpandedEventDescription] = useState(null);
  const toggleEventDescription = (eventId) => {
    if (eventId === expandedEventDescription) {
      setExpandedEventDescription(null);
    } else {
      setExpandedEventDescription(eventId);
    }
  };
  
  const getPostId = (postId, caption, allowComment, locationLAT, locationLONG) => {
    setPostId(postId)
    setAllowComment(allowComment)
    setCaption(caption)
    setLocationLAT(locationLAT)
    setLocationLONG(locationLONG)
  }

let [copyLink, setCopyLink] = useState('')

const hideUnHide =(postId,hiddenOrNot)=>{

    if(hiddenOrNot==0){

    dispatch(hideAllPostsFromTimelineAction(postId))
    // setSuccess(true)
    sweetAlertConfirmation("post hidden successfully");
    }else{
      dispatch(postShowAction(postId));
      sweetAlertConfirmation("post showed on timeline successfully")
    }

}

const postCopyLink = async (id) => {
  copyLink = `${process.env.REACT_APP_IPURL}/MyEvent/${id}`
  setCopyLink(copyLink)
  navigator.clipboard.writeText(copyLink)
  sweetAlertConfirmation(`Link Copied`)
};

const filteredData = props?.data?.filter(item =>
  item?.eventDescription?.toLowerCase().includes(props?.searchTerm?.toLowerCase()) ||
  item?.eventCategoryName?.toLowerCase().includes(props?.searchTerm?.toLowerCase())
);

// console.log("filtered data============================",filteredData)

const pageCount = Math.ceil((filteredData.length)/12);
const isPrevDisabled = pageIndex <= 0;
const isNextDisabled = pageIndex >= pageCount - 1;

  const handlePageChange = (event, value) => {
    if (value - 1 === pageIndex) return;

    setPageIndex(value - 1);
  };


const BasicsPagination = () => {
  return (
    <>
      <Stack>
        <Pagination
             count={pageCount}
             page={pageIndex + 1}
             onChange={handlePageChange}
             className="PaginationCounts"
             renderItem={(item) => (
               <PaginationItem
                 {...item}
                 disabled={
                   (item.type === 'previous' && isPrevDisabled) ||
                   (item.type === 'next' && isNextDisabled)
                 }
               />
             )}
        ></Pagination>
      </Stack>
    </>
  );
};

  //=============== main_return_function===============//
  return (
    <Fragment>
      <div className="container-fluid section-t-space px-0">
        <div className="group-single-list-block">
          {filteredData?.length>0 ?(
            <Row className='mt-3'>
            {filteredData?.map((event, index) => (
              <Col md="3" className="mb-4" key={event?.postId}>
                <div className="Event-box" key={index}>
                  <div className="Event-img-sublink">
                    <Eye color='#FFFFFF' size={20} 
                    onClick={hideOrUnhideIcon}
                    style={{cursor:'pointer'}}                      
                    />
                  </div>
                  { event?.eventCoverImageUrl.substr(-4) === '.mp4'
                  ?
                  <video controls alt="noPic" style={{marginBottom:'-5px', width:'100%', height:'140px'}} onClick={(e)=>navigateToSomewhere(e,event?.postId)}>
                  <source src={event?.eventCoverImageUrl}  type="video/mp4" alt="noPic" />
                  </video>
                  :
                  <img style={{width:'100%', height:'140px'}} className="gallery-img"
                  src={event?.eventCoverImageUrl} alt="noPic" onClick={(e)=>navigateToSomewhere(e,event?.postId)}/>
                 }

                  <div className='Event-FirstSection' >
                    <div className='Event-SecndSection'>
                      <small>{event.eventShowDate}  {event.eventStartTime.slice(0,4) }<br />{event.eventStartTime.slice(11,16) } to {event.eventEndTime.slice(11,16)}</small>
                      <div className='stng_btn text-center'>
                        <MoreHorizontal
                         color='#808080' size={16}
                          className="dropbtn-gal"
                           data-bs-toggle="dropdown"
                            aria-haspopup="true"
                             aria-expanded="false"
                             onClick={() => getPostId(event.postId, event.eventDescription, true, event.eventLatitude, event.eventLongitude)}
                             
                             />
                        <div className="dropdown-menu dropdown-menu-bottom custom-dropdown drop-menu-gal events_Option" style={{ width: '200px' }}>
                          <ul>
                          {/* <li>
                              <span>
                              {event?.hidden ?
                              <Link onClick={() => hideUnHide(event.postId, event.hidden)}><EyeOff size={15} color="#979797" /> &nbsp;Show post to timeline</Link>
                              :
                              <Link  onClick={() => hideUnHide(event.postId, event.hidden)}><Eye size={15} color="#979797" /> &nbsp;Hide post from timeline</Link>
                              }
                              </span>
                              </li> */}
                          <li 
                            onClick={() => {toggleShareOpen(event.postId, 1)}}
                            >
                              <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg> Send</Link>
                            </li>
                            <li 
                            onClick={() => {toggleShareOpen(event.postId, 0)}}
                            >
                              <Link to="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg> Invite</Link>
                            </li>
                            <li
                              onClick={() => {postCopyLink(event?.postId) }}
                            >
                              <Link to="#"><Link2 size={15} color="#979797" /> &nbsp;Copy Link</Link>
                            </li>
                            <li onClick={toggleDeleteOpen} >
                              <Link to="#"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon-font-light iw-16 ih-16"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete</Link>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>

                    <h4 className='pt-3' onClick={(e) => navigateToSomewhere(e, event?.postId)}>
                      {event?.eventDescription
                        ? event.eventDescription.length >= 20
                          ? `${event.eventDescription.substring(0, 20)} ...`
                          : event.eventDescription
                        : 'No description available'}
                    </h4>


                    <div className='Event-bottom-section' onClick={(e)=>navigateToSomewhere(e,event?.postId)}>
                    <p>
                      <MapPin color='#808080' size={15} />{' '}
                      {expandedEventId === event.postId 
                      ? 
                      event.eventAddress 
                      : 
                      event.eventAddress.substring(0, 20)}
                      {event.eventAddress.length >= 20 && (
                      <span style={{ cursor: 'pointer', color: '#81c14b', marginLeft: '4px', fontSize: '12px' }}>
                        {expandedEventId === event.postId ? ' less ' : ' more '}
                      </span>
                      )}
                    </p>
                    
                      <div className='Events-bottom-paragraph'>
                        <p><Users size={15} color="#808080" />&nbsp; {event.totalGoing} Going</p>
                        <p className='ml-3'><Users size={15} color="#808080" />&nbsp; {event.totalInterested} Interested</p>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <Button onClick={() => {toggleShareOpen(event.postId,0)}}  outline color='primary' className="w-100 border-0 Events_SendBtn" >Send Invite</Button>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            </Row>
          ):(
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '15vh', // Adjust the height as needed to fill the available space
              width: '100%' // Ensure it takes the full width of the container
            }}>
            <h2 style={{ textAlign: 'center' }}>No hosted Events</h2>
          </div>
          )}
          
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <BasicsPagination />
      </div>
      <DeletedEventModal
        isOpen={deleteOpen}
        updateOpen={toggleDeleteOpen}
        postId={postId}
        onSuccess={handleSuccess}
      />
      <ShareEventModal
        isOpen={shareOpen}
        updateOpen={toggleShareOpen}
        postId={postId}
        caption={caption} 
        allowComment={allowComment} 
        locationLAT={locationLAT}
        locationLONG={locationLONG}
        apiRedirect = {apiRedirect}
        
      />
    </Fragment>
  )
}

export default MyHosted
