import * as types from "../../Constants/SocialFeed/index.js";

const initialState = {
  allGroups: [],
  loading: true,
};

const UserGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_USER_GROUPS:
      return {
        ...state,
        allGroups: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default UserGroupReducer;
