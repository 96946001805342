import React, { Fragment } from 'react'
import { useState } from 'react'
import { Book, BookOpen, Truck } from 'react-feather'
import { Modal, Table } from 'reactstrap'
import Pen1 from '../Img/Pen1.png'
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';
import { loadAllUserHobbiesAction, loadAllAvailableHobbiesAction, postAllUserHobbiesAction } from '../../../Services/Actions/UserProfile/getAllUserProfilePageAction';
import { useEffect } from 'react'
import Loader from '../../../components/Loader/Loader';
import { SweetErrorAlert } from '../../group-components/AllGroupsSections/SweetErrorAlert'
import { useNavigate } from 'react-router-dom'
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'

const Hobbies = (props) => {
    const navigate = useNavigate()

    const getAllUserHobbiesSuccess = useSelector((state) => { return state.getAllUserHobbiesData });
    const { loading, error, result } = getAllUserHobbiesSuccess


    const getAllAvailableHobbiesSuccess = useSelector((state) => { return state.getAllAvailableHobbiesData });
    const { loading: loadingAvailableHobbies, error: errorAvailableHobbies, result: resultAvailableHobbies } = getAllAvailableHobbiesSuccess

    const postAllHobbiesSuccess = useSelector((state) => { return state.postAllUserHobbiesData });
    const { loading: postAllHobbies, error: errorPostHobbies, result: resultPostHobbies } = postAllHobbiesSuccess
    const [friendsId, setFriendsId] = useState([])
    // const [userHobbyResult, setUserHobbyResult] = useState()
 
    const dispatch = useDispatch()

    const [hobbieSearch,setHobbiesSearch] = useState("")
    useEffect(() => {
        dispatch(loadAllUserHobbiesAction())
        dispatch(loadAllAvailableHobbiesAction())
    }, [])

    useEffect(() => {
        dispatch(postAllUserHobbiesAction())
    }, [])
    const handleSearch = (e) => {
        setHobbiesSearch(e.target.value);
        console.log(hobbieSearch)
        dispatch(loadAllAvailableHobbiesAction(hobbieSearch))
      };
    const inviteFriends = (e) => {

        let updatedList = [...friendsId]
        if (e.target.checked) {
            updatedList = [...friendsId, e.target.value]
        } else {
            updatedList.splice(friendsId.indexOf(e.target.value), 1)
        }
        setFriendsId(updatedList)
    };

    const [hobbiesOpen, setHobbiesOpen] = useState(false)

    const toggleHobbies = () => {
        let hobbies = result.map(userHobby => {
            return userHobby.id
        })
        setFriendsId(hobbies)
        setHobbiesOpen((preState) => !preState)
        setHobbiesSearch("")
    }



    const saveHobbies =  () => {
        // Call the action to save the selected hobbies
        dispatch(postAllUserHobbiesAction(friendsId));

        // Close the modal
        setHobbiesOpen((prevState) => !prevState);

        // Show a success message
        sweetAlertConfirmation('Your hobbies updated successfully');
    };


      

    return (
        <Fragment>
            <div className="about-profile-box-blk">
                <div className="card-title">
                    <h3>Hobbies</h3>
                    {result && result.length > 0 && props.type!=="viewer" && (
                        <div className="settings">
                            <div onClick={toggleHobbies} className="setting-btn">
                                <img
                                    alt='penIcon'
                                    style={{ cursor: 'pointer' }}
                                    src={Pen1} width={12}
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="card-block-box">
                    
                        <ul className="hobbies-blk">
                            {
                                result?.length > 0 ?  result?.map((data, index) => (
                                    <li>
                                        <img style={{ width: '12px', marginRight: '4px' }} src={data.url} alt='HobbiesIcon' />
                                        {data.name}
                                    </li>
                                ))
                                : (
                                    <>
                                    {props.type!=="viewer" &&(
                                        <div className="mx-3 py-4 Profession_Modal_section">
                                        <p onClick={toggleHobbies}>+ Add your hobbies</p>
                                    </div>
                                    )}
                                    </>
                                )
                                    
                            }

                        </ul>
                    
                </div>

            </div>
            <div className='Modal_section_Basic_info_Email'>
                {
                    hobbiesOpen !== false &&
                    <Modal scrollable isOpen={hobbiesOpen} toggle={toggleHobbies} className='modal-dialog-centered'>
                        <div className='Profession_Modal_section AboutModal_Main_section p-3 py-4 '>
                            <h4>Choose your Hobbies </h4>
                            <p onClick={saveHobbies}>Save</p>
                        </div>
                        <div className='Modal_scroll_box mb-4'>
                            <div className="AboutModal_Main_section p-3 py-4">
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Find hobbies..." data-bs-toggle="modal" data-bs-target="#peopleSearch"  value={hobbieSearch}
      onChange={handleSearch} />
                                </div>
                            </div>
                            {
                                loadingAvailableHobbies ?
                                    (<Loader />)
                                    : errorAvailableHobbies ?
                                        (SweetErrorAlert(errorAvailableHobbies))
                                        : (
                                            resultAvailableHobbies?.map((data) => (
                                                <div className='Profession_Modal_Option AboutModal_Main_section p-3 py-3 '>
                                                    <div className='d-flex align-items-center'>
                                                        <div>
                                                            <img style={{ width: '28px', marginRight: '4px' }} src={data.url} alt='HobbiesIcon' />
                                                        </div>
                                                        <h4 className='ml-3  Basic_Info'>{data.name}</h4>
                                                    </div>
                                                    {/* <Checkbox  /> */}

                                                    <input
                                                        value={data?.id}
                                                        checked={friendsId.includes(data.id)}
                                                        onChange={(e) => {
                                                            inviteFriends(e);
                                                        }}
                                                        type="checkbox"
                                                        className='customcheckboxForFilter'
                                                        id={data.id} style={{ cursor: "pointer" }}
                                                    />
                                                </div>
                                            ))
                                        )
                            }
                        </div>

                    </Modal>
                }
            </div>
        </Fragment>
    )
}

export default Hobbies



