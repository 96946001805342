import React from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import { ShotzCard } from './shotz_components/ShotzCard';
export const Shotz = () => {
    return (<>
        <Header />
        <div className="page-body container-fluid newsfeed-style1">
            <LeftSidebar />
            <div className="page-center">
                <ShotzCard />
            </div>
            <RightSidebar />
        </div>
    </>)
}