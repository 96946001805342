import React, { useState } from 'react'
import { ChevronRight } from 'react-feather'
// import { NavLink } from 'react-router-dom'
import { Col, NavLink, Row } from 'reactstrap'
// import small1 from '../../NewComponents/IMG/small1.png'
// import small2 from '../../NewComponents/IMG/small2.png'
// import tagPic from '../../NewComponents/IMG/tagPic.png'
// import Pollimg from '../../NewComponents/IMG/Pollimg.png'
import backicon from '../../NewComponents/IMG/backicon.svg'
// import CommentImg from '../../NewComponents/IMG/Comment.svg'
// import ShareImg from '../../NewComponents/IMG/Share.svg'
// import LikeBlack from '../profile-components/Img//likesvg.svg'
import ShareEventModal from '../profile-components/EventsFolder/EventsModal/ShareEventModal'
// import MasonryGrid from '../Bookmark-Components/MasonryGrid'
import ReportModal from './Grid-Components/ModalGrid/ReportModal'
import NewMasonryGrid from '../Bookmark-Components/NewMasonryGrid'

const People = () => {
    const [selectActive, setSelectActive] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = () => {
        setReportOpen(!reportOpen)
    }
    const numFormatter = (num) => {
        // eslint-disable-next-line prefer-template
        if (num > 999 && num < 1000000) {
            return `${parseInt(num / 1000)} K`
        } else if (num > 999999) {
            return `${parseInt(num / 1000000)} M`
        } else if (num < 900) {
            return `${num}`
        }
    }


    {/* -----------------------------------------------Array data of peoples------------------------------------------------------------- */ }
    const People = [

        {
            trend: 'Trending',
            top: 'Top',
            count: '#1',
            count1: '#2',
            num: '1500',
            title: 'IceBucket',

        },
        {
            trend: 'Trending',
            top: 'Top',
            count: '#2',
            count1: '#3',
            num: '1500',
            title: 'Freefall',

        },
        {
            trend: 'Trending',
            top: 'Top',
            count: '#4',
            count1: '#5',
            num: '1500',
            title: 'PokemonGo',

        },
        {
            trend: 'Trending',
            top: 'Top',
            count: '#6',
            count1: '#5',
            num: '1500',
            title: 'DragonFly',

        },
        {
            trend: 'Trending',
            top: 'Top',
            count: '#7',
            count1: '#8',
            num: '1500',
            title: 'FlyingSaucer',

        },
        {
            trend: 'Trending',
            top: 'Top',
            count: '#9',
            count1: '#10',
            num: '1500',
            title: 'Avionics',

        },

    ]


    {/* -----------------------------------------------Main return function------------------------------------------------------------- */ }
    return (
        <div className='mt-3 p-2'>
            {/* -----------------------------------------------Ternary function Start Here------------------------------------------------------------- */}
            {!selectActive ?
                <>
                    <Row className='d-flex border roundedThisBorder p-2'>
                        <p className='p-3 TrendingPeoplethistext'>TRENDING #TAGS</p>
                        <Col xl='6' className='p-0 '>
                            {/* border-right */}
                            {
                                People.map((data) => {
                                    return (
                                        <>
                                            <div className='d-flex justify-content-between align-items-center on-hover-bg py-2 border-right d-none d-xl-flex' onClick={() => setSelectActive(!selectActive)}>
                                                <div className='d-flex pl-1 align-items-center'>
                                                    <div className='TrendingTagsBorders d-flex flex-column justify-content-center align-items-center  '>
                                                        <p className='PilesThistags text-center'>{data.top}</p>
                                                        <p className='PilesThistags text-center pt-2'>{data.count1}</p>
                                                    </div>
                                                    <div className='TrendingTagsBorders ml-3 d-flex flex-column justify-content-center align-items-center'>
                                                        <p className='PilesThistags text-center'>{data.trend}</p>
                                                        <p className='PilesThistags text-center pt-2 '>{data.count}</p>
                                                    </div>
                                                    {/* =========================add used count======================= */}
                                                    <div className='toptitle1 pl-2 ml-1'>
                                                        <p className='TopTitleThis'>#{data.title}</p>
                                                        <div className='text pt-1'>
                                                            <span className='gray-text' style={{ textWrap: 'nowrap' }}>Used in 23k</span>-
                                                            <span className='text-primary post' style={{ textWrap: 'nowrap' }}>233 posts</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {/* <div className='Shotzsss d-flex align-items-center' style={{ cursor: "pointer", paddingLeft: '20px' }} onClick={() => setSelectActive(!selectActive)}>
                                                            <h5 className='align-self-center text-primary'> 233 posts</h5>
                                                            <p style={{ cursor: "pointer", color: '#999999' }} className=' ml-2'><ChevronRight /></p>
                                                        </div> */}
                                                    <div className='d-flex justify-content-start align-items-center align-self-center ml-auto text-nowrap'>
                                                        {/* Show the image on tablet-sized screens (medium and larger) */}
                                                        <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='rounded-circle ArticleShowdo hide-on-small-screens d-none d-md-block' alt='' />
                                                        <div className=''>
                                                            <NavLink to='/MyProfile' style={{ color: 'black' }}>
                                                                {/* Hide the text on all mobile devices */}
                                                                <p className='user-profile hide-on-small-screens  d-none d-sm-none d-md-block  d-lg-block'>Floyd Miles</p>
                                                            </NavLink>
                                                            {/* Hide the text on all mobile devices */}
                                                            <small className='text-muted light-for-date pl-1 light-for-date hide-on-small-screen d-sm-none d-none d-md-block d-lg-block' style={{ marginLeft: '11px' }}>19 sept at 07:00 pm</small>
                                                        </div>
                                                        {/* Show the icon on tablet-sized screens (medium and larger) */}
                                                        <p style={{ cursor: "pointer", color: '#999999' }} className='ml-2 light-for-date hide-on-small-screen d-none d-md-none d-lg-block'><ChevronRight /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </Col >
                        <Col xl='6' className='p-0'>
                            {
                                People.map((data) => {
                                    return (
                                        <>
                                            <div className='d-flex justify-content-between align-items-center on-hover-bg py-2' onClick={() => setSelectActive(!selectActive)}>
                                                <div className='d-flex pl-1 align-items-center'>
                                                    <div className='TrendingTagsBorders d-flex flex-column justify-content-center align-items-center  '>
                                                        <p className='PilesThistags text-center'>{data.top}</p>
                                                        <p className='PilesThistags text-center pt-2'>{data.count1}</p>
                                                    </div>
                                                    <div className='TrendingTagsBorders ml-3 d-flex flex-column justify-content-center align-items-center'>
                                                        <p className='PilesThistags text-center'>{data.trend}</p>
                                                        <p className='PilesThistags text-center pt-2 '>{data.count}</p>
                                                    </div>
                                                    {/* =========================add used count======================= */}
                                                    <div className='toptitle1 pl-2 ml-1'>
                                                        <p className='TopTitleThis'>#{data.title}</p>
                                                        <div className='text pt-1'>
                                                            <span className='gray-text' style={{ textWrap: 'nowrap' }}>Used in 23k</span>-
                                                            <span className='text-primary post' style={{ textWrap: 'nowrap' }}>233 posts</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {/* <div className='Shotzsss d-flex align-items-center' style={{ cursor: "pointer", paddingLeft: '20px' }} onClick={() => setSelectActive(!selectActive)}>
                                                            <h5 className='align-self-center text-primary'> 233 posts</h5>
                                                            <p style={{ cursor: "pointer", color: '#999999' }} className=' ml-2'><ChevronRight /></p>
                                                        </div> */}
                                                    <div className='d-flex justify-content-start align-items-center align-self-center ml-auto text-nowrap'>
                                                        {/* Show the image on tablet-sized screens (medium and larger) */}
                                                        <img src='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80' width={50} className='rounded-circle ArticleShowdo hide-on-small-screens d-none d-md-block d-lg-block' alt='' />
                                                        <div className=''>
                                                            <NavLink to='/MyProfile' style={{ color: 'black' }}>
                                                                {/* Hide the text on all mobile devices */}
                                                                <p className='user-profile hide-on-small-screens  d-none d-sm-none d-md-block d-lg-block'>Floyd Miles</p>
                                                            </NavLink>
                                                            {/* Hide the text on all mobile devices */}
                                                            <small className='text-muted light-for-date pl-1 light-for-date hide-on-small-screen d-sm-none d-none d-md-block d-lg-block' style={{ marginLeft: '11px' }}>19 sept at 07:00 pm</small>
                                                        </div>
                                                        {/* Show the icon on tablet-sized screens (medium and larger) */}
                                                        <p style={{ cursor: "pointer", color: '#999999' }} className='ml-2 light-for-date hide-on-small-screen d-none d-md-none d-lg-block'><ChevronRight /></p>
                                                    </div>





                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </Col >
                    </Row>
                </> : <>
                    <Row>
                        <Col className='p-1'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    {/* <div>
                                        <img src={tagPic} alt='tagpic' className='p-1' />
                                    </div> */}
                                    <div className='mt-4 ml-1'>
                                        <p className='TagThisTrendingTitle'>#TrendingTitle</p>
                                        <p className='TagThisCountPost'>554 posts</p>
                                    </div>
                                </div>
                                <img src={backicon} onClick={() => setSelectActive(!selectActive)} alt='back' className='mr-1' style={{ cursor: 'pointer' }} />
                            </div>
                        </Col>
                    </Row>
                    {/* <MasonryGrid /> */}
                    <NewMasonryGrid />

                </>}
            {/* -----------------------------------------------Ternary function End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Share Event Modal  Start Here------------------------------------------------------------- */}
            <ShareEventModal
                isOpen={shareOpen}
                updateOpen={toggleShareOpen}
            />
            {/* -----------------------------------------------Share Event Modal  End Here------------------------------------------------------------- */}


            {/* -----------------------------------------------Review Report modal  Start Here------------------------------------------------------------- */}
            <ReportModal
                reportOpen={reportOpen}
                toggleReport={toggleReport}
            />
            {/* -----------------------------------------------Review Report modal  End Here------------------------------------------------------------- */}

        </div >
    )
}

export default People