import React from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import { LanguagesAll } from '../LoginDummyArray/signupdetailsArray'
import { Search } from 'react-feather'

const LangModalNew = ({ modal, toggle }) => {
    return (
        <Modal isOpen={modal} toggle={toggle} className="vertically-centered-modal modal-lg">
            <ModalHeader toggle={toggle}>Choose Language</ModalHeader>
            <ModalBody>
                <div className="LanguageSerchBar2 d-flex align-items-center border rounded  px-2 my-2">
                    <Input type="search" placeholder="Search Language" className="border-0" />
                    <Search size={16} color="#4d4d4d" />
                </div>
                <div className="LanguageModalAllLang">
                    {LanguagesAll.map((item, index) => (
                        <div className='cursor-p'>
                            <div id={`tooltip-${index}`} data-toggle="tooltip" style={{ width: "fit-content" }}>{item}</div>
                            <UncontrolledTooltip delay={0} hide="false" placement="left" target={`tooltip-${index}`}>{item}</UncontrolledTooltip>
                        </div>
                    ))}

                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default LangModalNew
