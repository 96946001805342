import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux';
import { groupActivityHistoryAction,getAllGeneralNotificationAction, setNotificationToGroupSettingsAction, setNotificationInGeneralTabAction} from '../../../Services/Actions/Group/getAllGroupMainPageAction'; 
import { useParams } from 'react-router-dom'
import { sweetAlertConfirmation } from '../AllGroupsSections/SweetAlert';

const NotificationSetting = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const grpId = params.id
    let user = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_KEY));
    console.log('user', user)
    const { socioMeeNotificationSettingsReducer, socioMeeNotificationSettingsError } = useSelector((state) => { return state.socioMeeNotificationSettingsData });
    const { setAllGeneralNotification, setAllGeneralNotificationError } = useSelector((state) => { return state.setNotificationToGroupSettingsData });
    const { setAllGeneralNotificationTab, setAllGeneralNotificationTabError } = useSelector((state) => { return state.setNotificationToGroupSettingsTabData });
    const { grpActivityHistoryReducer, grpActivityHistoryError } = useSelector((state) => { return state.groupActivityHistoryData });
    
    const allIsAllowed = socioMeeNotificationSettingsReducer?.every((setting) => setting.isAllowed === 1);
    const emailNotificationId = socioMeeNotificationSettingsReducer?.find(
        (notification) => notification?.notificationType === 'notification_on_email'
      );

    console.log(grpActivityHistoryReducer,'grpActivityHistoryReducer')
    console.log(socioMeeNotificationSettingsReducer,'socioMeeNotificationSettingsReducer')

    useEffect(() => {
        if (allIsAllowed) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
      }, [socioMeeNotificationSettingsReducer, allIsAllowed]);

      const [isChecked, setIsChecked] = useState(allIsAllowed);
 
useEffect(() => {
    if (setAllGeneralNotificationTab === 'Success') {
        dispatch(getAllGeneralNotificationAction(grpId))
        if(isChecked){
            sweetAlertConfirmation('Enabled all notification')
        }else{
            sweetAlertConfirmation('Disabled all notification')
        }        
    }
  }, [setAllGeneralNotificationTab, dispatch, grpId]);

    useEffect(() => {
        const notificationSettings = {};
        socioMeeNotificationSettingsReducer?.forEach((data) => {
          notificationSettings[data.id] = data?.isAllowed === 1;
        });
        setNotificationCheckBox(notificationSettings);
      }, [socioMeeNotificationSettingsReducer]);

    const [notificationCheckBox, setNotificationCheckBox] = useState([])

    const updateCheckBox = (event, id, notificationType) => {
        const isChecked = event.target.checked;
        setNotificationCheckBox(prevState => ({ ...prevState, [id]: isChecked }));
        dispatch(setNotificationToGroupSettingsAction(isChecked, grpId, id, notificationType))
        dispatch(getAllGeneralNotificationAction(grpId))
        sweetAlertConfirmation('Successfully Updated')
      }     

      const updateAllNotification = (event) => {
        setIsChecked(event.target.checked);
        const isChecked = event.target.checked;
        dispatch(setNotificationInGeneralTabAction(isChecked, grpId))        
      }

    return (
        <div>
            <div className="custom-card-block">
                <div className="setting-card-head biz-carddetail-blk">
                    <h4>Notification Settings </h4>
                </div>
                <CardBody className="custom-card-body">
                    <div className="biz-main-about-blk ">
                        <div className='Setting_Section'>
                            <div>
                                <h5>Notification on SocioMee</h5>
                                <small>This will enable notifications for activities on your Group.</small>
                            </div>
                            <p><label className="switch">
                                <input 
                                type="checkbox" 
                                checked={isChecked}
                                    onChange={(e) => {
                                        updateAllNotification(e);
                                      }}                              
                                />
                                <span className="slider round"></span>
                            </label></p>
                        </div>
                    </div>

                    <div className="biz-main-about-blk ">
                        {
                            socioMeeNotificationSettingsReducer && socioMeeNotificationSettingsReducer.map((data) => (
                                <div className='d-flex justify-content-between mt-3'>
                                    <div className='d-flex align-items-center '>
                                        <div className='Invitaion_User_Profile ml-1'>
                                            <h5>{data.notificationType}</h5>
                                        </div>
                                    </div>
                                    <div className='Block-btns-blk'>
                                        <input
                                        id={data.id}
                                        style={{ cursor: "pointer" }}
                                        value={data.id}
                                        checked={!!notificationCheckBox[data.id]}
                                        onChange={(e) => {
                                          updateCheckBox(e, data.id, data.notificationType);
                                        }}
                                        type="checkbox"
                                        className='customcheckboxForFilter'
                                        />
                                    </div>
                                </div>

                            ))
                        }


                    </div>
                    <div className="biz-main-about-blk ">
                        <div className='Setting_Section'>
                            <div>
                                <h5>Notification on Email </h5>
                                <small>Turn off to stop receiving notifications on your </small>
                                <p>{user.email}</p>
                            </div>
                            <p><label className="switch">
                                <input type="checkbox" 
                                    id={emailNotificationId?.id}
                                    style={{ cursor: "pointer" }}
                                    value={emailNotificationId?.id}
                                    checked={!!notificationCheckBox[emailNotificationId?.id]}
                                    onChange={(e) => {
                                    updateCheckBox(e, emailNotificationId?.id, 'notification_on_email');
                                    }}
                                //  checked={isChecked}
                                //      onChange={(e) => {
                                //          updateAllNotification(e);
                                //        }}   
                                />
                                <span className="slider round"></span>
                            </label></p>
                        </div>
                    </div>
                </CardBody>
            </div>
        </div>
    )
}

export default NotificationSetting
