import React, { useState } from 'react'
import { Fragment } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { ChevronRight, Grid } from 'react-feather';
import Slider from 'react-slick';
import { Button, Card, DropdownItem, DropdownMenu, DropdownToggle, NavLink, UncontrolledButtonDropdown } from 'reactstrap';
import MobileFilter from './MobileFilter';
import TopPodacastCard from './TopPodacastCard';
import CommonSeeallpage from './pages/CommonSeeallpage';

const MobileViewSLider = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /*  ----------------------  Podecast Dyanmic Tabs --------------------  */

    const PodcastNavData = [
        {
            id: "1",
            NavName: "All (4)"
        },
        {
            id: "2",
            NavName: "TV & Film (1)"
        },
        {
            id: "3",
            NavName: "Health & Fitness(1)"
        },
        {
            id: "4",
            NavName: "Socity & Culture (1)"
        },
        {
            id: "5",
            NavName: "Comedy (1)"
        }

    ]

    /*  ---------------------- Third party react-slick Slider Breakpoint management --------------------  */

    let MobileviewSlider = {
        // arrows: false,
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2.5
                }
            },
        ]
    };
    const [cardId, setCardId] = useState()
    return (
        <div>
            <Slider  {...MobileviewSlider} className="default-space">
                {PodcastNavData.map((item, index) => (
                    <Fragment index={index}>

                        <div active={props.active === item.id} className='my-1' key={index} onClick={() => { setCardId(index + 1) }}>
                            <NavLink className='my_events_Navlink d-flex justify-content-center rounded shadow' active={props.active === item.id} onClick={() => { props.toggleactive(item.id) }} to="#">
                                <h6>{item.NavName}</h6>
                            </NavLink>
                        </div>
                        {/* <li key={index} onClick={() => { setCardId(index + 1) }} >
                            <NavLink active={props.active === item.id} onClick={() => { props.toggleactive(item.id) }} to="#">
                                <h6>{item.NavName}</h6>
                            </NavLink>
                        </li> */}
                    </Fragment>
                ))}
            </Slider>
            <div >
                <MobileFilter />
            </div>
        </div>
        // <div>
        //     <Grid id='pot' onClick={handleShow} />
        //     <Offcanvas show={show} onHide={handleClose}>
        //         <Offcanvas.Header closeButton>
        //         </Offcanvas.Header>
        //         <Offcanvas.Body>
        //             {PodcastNavData.map((item, index) => (
        //                 <Fragment index={index}>

        //                     <div className='col-sm-12' active={props.active === item.id}>
        //                         <NavLink className='my_events_Navlink d-flex justify-content-center rounded shadow-sm' active={props.active === item.id} onClick={() => { props.toggleactive(item.id) }} to="#">
        //                             <h6>{item.NavName}</h6>
        //                         </NavLink>
        //                     </div>
        //                 </Fragment>
        //             ))}
        //             <div>
        //                 <UncontrolledButtonDropdown>
        //                     <DropdownToggle color='flat m-0 p-0'>
        //                         <NavLink to="#">
        //                             <h6>More..</h6>
        //                         </NavLink>
        //                     </DropdownToggle>
        //                     <DropdownMenu style={{ minWidth: '12rem' }} className='p-0 m-0'>
        //                         <DropdownItem tag='a'>Religon & Sprituality</DropdownItem>
        //                         <DropdownItem tag='a'>News & Politics</DropdownItem>
        //                     </DropdownMenu>
        //                 </UncontrolledButtonDropdown>
        //             </div>
        //         </Offcanvas.Body>
        //     </Offcanvas>
        // </div>


    )
}

export default MobileViewSLider