import React, { Fragment } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

const UnBlockModal = (props) => {
    return (
        <Fragment>
            <div className='UnblockAll_modal'>
                {
                    props.isOpen !== false &&
                    <Modal isOpen={props.isOpen} toggle={props.updateOpen} className='modal-dialog-centered'>
                        <ModalHeader className="BizUnblock_Heading">
                            <h2>Unblock Blocked User</h2>
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-center mt-2 BizSetting_Unblock_Heading'>
                                <h2>Are You Sure?</h2>
                                <p>Do you want to Unblock All</p>
                            </div>
                            {/* <FormGroup className="mt-3">
                                <Label className='my-1 Biz_Rating_sab_section' >
                                    <h3>Additional comment :</h3>
                                </Label>
                                <Input type='textarea'
                                    cols="15"
                                    rows="5"
                                    placeholder="Enter your description here "
                                />
                                <p className='text-end mt-1 WataDo_limit_section'>Max 300 Characters</p>
                            </FormGroup> */}
                            <div className='text-center mt-3'>
                                <Button onClick={props.updateOpen} className="" color='orange' >Yes,unblock it!</Button>
                                <Button onClick={props.updateOpen} className="w-25 ml-2" outline >Cancel</Button>
                            </div>
                        </ModalBody>


                    </Modal>
                }

            </div>
        </Fragment>
    )
}

export default UnBlockModal