import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AlertTriangle, CreditCard, FileText, HelpCircle, Home, Layers, Settings } from 'react-feather';
import SocioLogo from '../../../NewComponents/IMG/SocioLogo.svg'
import SociomeeLogo from '../../../NewComponents/IMG/SociomeeLogo.svg'
import AdManagerHeader from './AdManagerHeader';
import { Link } from 'react-router-dom';
import TotalBudgetScreen from '../../AdmanagerD/AdsScreens/TotalBudget/TotalBudgetScreen';



const drawerWidth = 240;
const drawerBcolor = 'transparent';

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const sideDashboardData = [
    { text: 'Dashboard', link: '/Dashboard' },
    { text: 'Report', link: '/Dashboard/Report' },
    { text: 'Billing', link: '/Dashboard/TransactionSummary' },
    { text: 'Payment setting', link: '/Dashboard/PaymentSettings' },
    { text: 'Setting', link: '#' },
    { text: 'Help', link: '#' },
]





export default function TotalBudgetAdScreen() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    // const [Switchtab, setSwitchtab] = useState(null)

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar sx={{ background: '#ffffff', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px', zIndex: '1000' }} position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            // backgroundColor: '#81C14B',
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        {/* <MenuIcon /> */}
                        <img width={25} height={25} src={SocioLogo} />
                    </IconButton>
                    <Typography noWrap className='w-100' component="div">
                        <AdManagerHeader />
                    </Typography>

                </Toolbar>

            </AppBar>
            <Drawer onMouseEnter={handleDrawerOpen} onMouseLeave={handleDrawerClose} variant="permanent" sx={{ zIndex: '0' }} open={open}>
                <DrawerHeader>
                    <IconButton sx={{ background: '#ffffff' }} onClick={handleDrawerClose}>
                        {open === true ? <img width={195} height={50} src={SociomeeLogo} alt="logo" /> : null}
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {sideDashboardData.map((text, index) => (
                        <ListItem key={text.text} disablePadding component={Link} to={text.link} sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index === 0 ? <Home color='#81C14B' /> : index === 1 ? <Layers color='#81C14B' /> : index === 2 ? <AlertTriangle color='#81C14B' /> : index === 3 ? <FileText color='#81C14B' /> :
                                        index === 4 ? <CreditCard color='#81C14B' /> : index === 5 ? <Settings color='#81C14B' /> : index === 6 ? <HelpCircle color='#81C14B' /> : null}
                                </ListItemIcon>
                                <ListItemText primary={text.text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 1, width: '95%' }}>
                <DrawerHeader />
                <TotalBudgetScreen />
            </Box>
        </Box>
    );
}