import * as types from '../../Constants/Common/index';
import axios from 'axios';


const getTaggedPeople = (taggedPeople) => ({
    type: types.GET_TAGGED_PEOPLE,
    payload: taggedPeople,
  });
  
  // // Thunk Action to Fetch Tagged People
  export const loadTaggedPeople = (searchQuery) => {
    return async (dispatch) => {
        try {
            const body = {
                searchKey:searchQuery,
                pageIndex:0,
                pageSize:20,
                connectionId:""
            }; 
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/post/getFriendsToTag`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
                    },
                }
            );
          
       dispatch(getTaggedPeople(response.data.data.successResult.rows))
     } catch (error) {
        // Dispatch failure action if there's an error
        console.error('Error fetching tagged people:', error);
        // dispatch(getTaggedPeopleFailure(error));
      }
    };
  };
  