import React, { useState, useEffect, useRef } from 'react'
import { AlertTriangle, ArrowDownCircle, Bookmark, ChevronUp, Circle, Edit2, Eye, EyeOff, Filter, Headphones, Link2, MoreVertical, Navigation, Pause, PauseCircle, Play, PlayCircle, Repeat, Share, Shuffle, SkipBack, SkipForward, Trash2, User, Volume, Volume2, VolumeX } from 'react-feather'
import { Badge, Button, Card, CardBody, CardImg, CardText, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap'
import ActiveCirclelike from '../../../../NewComponents/IMG/ActiveCirclelike.svg'
import Circlelike from '../../../../NewComponents/IMG/Circlelike.svg'
import CommentActive from '../../../../NewComponents/IMG/CommentActive.svg'
import CommentLatest from '../../../../NewComponents/IMG/CommentLatest.svg'
import PlayIcon from '../../../../NewComponents/IMG/playVideo.svg'
import PodacastMic from '../../../../NewComponents/IMG/PodacastMic.svg'
import UserImg from '../../../../NewComponents/IMG/img.svg'
import ShareLatest from '../../../../NewComponents/IMG/ShareLatest.svg'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import Song1 from '../../../../NewComponents/Audio/malang.mp3'
import Song2 from '../../../../NewComponents/Audio/Falak.mp3'
import { Accordion } from 'react-bootstrap'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { Checkbox, tableFooterClasses } from '@mui/material'
import { ForwardArrayData } from '../../../Social-Feed-Component/FeedArray'
import { Link } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap';
import MobileBottombar from '../../../MobileBottombar'
import PodcastFooter from '../../PodcastFooter'
import musicAnimation from '../../../../NewComponents/Gif/musicAnimation.gif'
import '../../podcast.css'
import { useUserAuth } from '../../../../Context/userAuthContext'
import Podcast from '../../../Story/Images/podcast.png'




const SeriesPodcastView = () => {
    const { listView, setListView, setMusicBar, musicBar } = useUserAuth();

    /*  ---------------------- States --------------------  */

    const [play, setpause] = useState(false)
    const [LikeIcon, SetLikeIcon] = useState(false)
    const [scoll, setScroll] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLooping, setIsLooping] = useState(false);
    const [isShuffling, setIsShuffling] = useState(false);
    const [volume, setvolume] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(0)
    const audioRef = useRef(null);
    const audioRef1 = useRef(null);
    const [show, setShow] = useState(false);
    const [BookmarkIcon, SetBookmark] = useState(true)
    const [showButton, setShowButton] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleBookmark = () => SetBookmark(!BookmarkIcon)
    const handleMouseEnter = (index) => setShowButton(index);
    const handleMouseLeave = (index) => setShowButton(null);

    /*  ----------------------  MUI Slider style management --------------------  */

    const PrettoSlider = styled(Slider)({
        color: '#81C14B',
        height: 4,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
                transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
                transform: 'rotate(45deg)',
            },
        },
    });



    /*  ---------------------- Multy Audio array --------------------  */

    const audioUrl = [
        Song1,
        Song2
    ]

    /*  ---------------------- All Functions --------------------  */

    /*  ------- Handel Play Pause Function ------- */

    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    const handlePlayPause1 = () => {
        if (isPlaying1) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying1(!isPlaying1);
    };
    /*  ------- Handel Rewind Function ------- */

    const handleRewind = () => {
        audioRef.current.currentTime -= 10;
        setCurrentTime(audioRef.current.currentTime);
    };

    /*  ------- Handel FastForward Function ------- */

    const handleFastForward = () => {
        // audioRef.current.currentTime += 10;
        setSelectedAudio(selectedAudio + 1)
        setCurrentTime(audioRef.current.currentTime);
    };

    /*  ------- Handel Repeat Function ------- */

    const handleLoopToggle = () => {
        setIsLooping(!isLooping);
    };

    /*  ------- Handel Shuffle Function ------- */

    const handleShuffleToggle = () => {
        setIsShuffling(!isShuffling);
    };

    /*  ------- Handel Audio Duration Function ------- */

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
    };

    const handleEnded = () => {
        if (isLooping) {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        } else if (isShuffling) {
            const randomTime = Math.floor(Math.random() * duration);
            audioRef.current.currentTime = randomTime;
            audioRef.current.play();
        } else {
            setIsPlaying(false);
        }
    };

    const [forwardOpen, setForwardOpen] = useState(false)
    const toggleForward = () => {
        setcommentclose(false)
        setForwardOpen((preState) => !preState)
    }


    const [Commentopen, setcommentclose] = useState(false)
    const toggleComment = () => {
        setcommentclose((preState) => !preState)
    }

    /*  ---------------------- Audio Time Function for Second --------------------  */

    const formatTime = (seconds) => {
        const Rounsseconds = Math.round(seconds)
        const minutes = Math.floor(Rounsseconds / 60);
        const remainingSeconds = Rounsseconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    /*  ---------------------- Scroll per Header --------------------  */

    const DynamicHeader = () => {

        return (

            <div className='fixed-top animeForHeader'>
                <Card >
                    <div className=' d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <img src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' className='rounded' width={100} height={90} alt=''/>
                            <h1 className='ml-2'>Kahani mein Twist</h1>
                        </div>
                        <div>
                            <Badge color='light-primary' pill>
                                Paid
                            </Badge>
                            <Button onClick={handlePlayPause} color='primary py-2 ml-2'><Pause size={20} /> Pause</Button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    /*-------------- Modal for Share -----------------*/

    const ShareModal = () => {
        const Sharedto = [
            {
                name: "Your Wall Feed"
            },
            {
                name: "Your Story"
            }
        ]

        return (
            <div>
                {
                    forwardOpen !== false &&
                    <Modal scrollable isOpen={forwardOpen} toggle={toggleForward} className='modal-dialog-centered'>

                        <div className='d-flex align-items-center justify-content-between p-3 border-bottom'>
                            <h2>Share to  </h2>
                            <Button color='primary' >Share</Button>
                        </div>
                        <ModalBody>
                            <div className='pb-2'>

                                <Accordion defaultActiveKey="0">
                                    <AccordionItem eventKey='1'>
                                        <AccordionHeader className='AccordionForSrtyle'><h4>Additional Features</h4></AccordionHeader>
                                        <AccordionBody className='p-0'>
                                            <Accordion defaultActiveKey="0">
                                                <AccordionItem eventKey='1'>
                                                    <AccordionHeader>Feed</AccordionHeader>
                                                    <AccordionBody>
                                                        {
                                                            Sharedto.map((data) => (
                                                                <div className='d-flex justify-content-between align-items-center my-2 Report_Section'>
                                                                    <h4>{data.name}</h4>
                                                                    <div>
                                                                        <Checkbox />
                                                                        {' '}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='2'>
                                                    <AccordionHeader>Your Business Pages</AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>
                                                    </AccordionBody>
                                                </AccordionItem>
                                                <AccordionItem eventKey='3'>
                                                    <AccordionHeader>Your Group</AccordionHeader>
                                                    <AccordionBody>

                                                        <div className="group-member-img-cont-blk d-flex align-items-center justify-content-between">
                                                            <div className='ForWard_Img_categories d-flex align-items-center'>
                                                                <img src={UserImg} alt="img" className='img-fluid rounded-circle' />
                                                                <div className="ml-1">
                                                                    <h4>Your Biz page anme</h4>
                                                                </div>
                                                            </div>
                                                            <Checkbox />
                                                        </div>

                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionBody>
                                    </AccordionItem>

                                </Accordion>
                            </div>
                            <div className="search-block-podcast mt-2">
                                <div className="input-search-blk">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    <input type="text" className="form-control" name="search" placeholder="Search..." data-bs-toggle="modal" data-bs-target="#peopleSearch" />
                                </div>
                            </div>
                            <div className='mt-3 Categories_subHeading_section '>
                                <h4>Connections</h4>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline>
                                        <Filter size={20} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag='a'> Recent </DropdownItem>
                                        <DropdownItem tag='a'> Full Name</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className='mt-3  '>
                                {ForwardArrayData.map((data) => (
                                    <div className="group-detail-page-blk">
                                        <div className="MyEvents-memberslist-block">
                                            <div className="group-member-img-cont-blk">
                                                <div className='ForWard_Img_categories'>
                                                    <img src={data.img} alt="img" className='img-fluid rounded-circle' />
                                                    <Circle fill='#81C14B' strokeWidth={5} color='#FFFFFF' size={15} className='right_imge' />
                                                </div>
                                                <div className="member-cont-blk">
                                                    <h4>{data.name}</h4>
                                                    <p className=''>{data.userName}</p>
                                                </div>
                                            </div>
                                            {/* <div className=''> */}

                                            <Checkbox />


                                            {/* </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>

                        </ModalBody>
                    </Modal>
                }

            </div>
        )
    }

    /*-------------- Modal for Comment -----------------*/


    const CommentModal = () => {
        const [LikeIcon, SetLikeIcon] = useState(false)
        const [comment, SetComment] = useState(false)
        return (
            <div>
                {
                    Commentopen !== false &&
                    <Modal scrollable isOpen={Commentopen} toggle={toggleComment} className='modal-dialog-centered modal-lg'>

                        <div className='p-3 border-bottom'>
                            <h2>Comments </h2>
                        </div>
                        <ModalBody>
                            <div className="p-2">
                                <div className="col p-0">
                                    <div className="comment-part">
                                        <div className="d-flex pb-2">
                                            <div className="user-media">
                                                <div className="media">
                                                    <Link className="user-img">
                                                        <img src="https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg" className="img-fluid bg-img" alt="user" />
                                                        <span className="available-stats"></span>
                                                    </Link>
                                                    <div className="media-body">
                                                        <h5>Kahani mein Twist</h5>
                                                        <h6>This podcast includes short stories inspired from...</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-panel mb-0">
                                            <div className="post-wrapper">
                                                <div className="post-details">
                                                    <div className="like-panel podcomment">
                                                        <div className="left-emoji">
                                                            <ul>
                                                                <li>
                                                                    <img src="/assets/svg/emoji/040.svg" alt="smile" />
                                                                </li>
                                                                <li>
                                                                    <img src="/assets/svg/emoji/113.svg" alt="heart" />
                                                                </li>
                                                            </ul>
                                                            <h6>+75</h6>
                                                        </div>
                                                        <div className="right-stats">
                                                            <ul >
                                                                <li>
                                                                    <span className='d-flex align-items-center'>4565 &nbsp; <p className='text-muted'>Comment</p></span>
                                                                </li>
                                                                <li><span className='d-flex align-items-center'>985  &nbsp;<p className='text-muted'>Share</p></span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="post-react">
                                                        <ul>
                                                            <li >
                                                                {
                                                                    LikeIcon ? <div className='mr-2'>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' /> <span>50</span>
                                                                    </div> : <div className='mr-2'>
                                                                        <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={Circlelike} alt='shareIcon' /> <span>49</span>
                                                                    </div>
                                                                }

                                                            </li>
                                                            <li >
                                                                <div className='mx-2'>
                                                                    <img style={{ cursor: 'pointer' }} width={35} src={CommentLatest} alt='shareIcon' /> <span> 25</span>
                                                                </div>
                                                            </li>
                                                            <li >
                                                                <div className='ml-2'>
                                                                    <img width={35} style={{ cursor: 'pointer' }} onClick={toggleForward} src={ShareLatest} alt='shareIcon' /> <span> 25</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="comment-section">
                                                        <div className="comments d-block scrollForSharePodcast">
                                                            <div className="main-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h4>Pabelo Mukrani</h4>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                        </h5>
                                                                        <ul className="comment-option">
                                                                            <li><Link to="#">like (5)</Link></li>
                                                                            <li onClick={() => SetComment(!comment)}><Link to="#">reply</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {comment ?
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                            </div>
                                                            <div className="main-comment">
                                                                <div className="media">
                                                                    <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                    <div className="media-body ml-2">
                                                                        <h4>Pabelo Mukrani</h4>
                                                                        <p className='text-muted'>50 mins ago</p>
                                                                        <h5 className='py-2 profile-comments'>Oooo Very cute Sweet Dog, Hap...😍   &#128578;
                                                                        </h5>
                                                                        <ul className="comment-option">
                                                                            <li><Link to="#">like (5)</Link></li>
                                                                            <li onClick={() => SetComment(!comment)}><Link to="#">reply</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                {comment ?
                                                                    <div className="sub-comment">
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body ml-2">
                                                                                <h4>Pabelo Mukrani</h4>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="#">like</a></li>
                                                                                    <li><a href="#">reply</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media">
                                                                            <img src="/assets/images/story-2.jpg" width={35} height={35} className="img-fluid bg-img rounded-circle" alt="user" />
                                                                            <div className="media-body  ml-2">
                                                                                <h5>sufiya elija</h5>
                                                                                <p className='text-muted'>50 mins ago</p>
                                                                                <h5 className='py-2'>Thank You So Much ❤❤</h5>
                                                                                <ul className="comment-option">
                                                                                    <li><a href="/">like</a></li>
                                                                                    <li><a href="/">reply</a></li>
                                                                                </ul>
                                                                            </div>

                                                                        </div>
                                                                        <a href="/" className="loader">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="iw-15 ih-15"><polyline points="23 4 23 10 17 10"></polyline><path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path></svg>
                                                                            load more replies
                                                                        </a>
                                                                    </div> : null}
                                                            </div>
                                                        </div>
                                                        <div className="reply">
                                                            <div className="search-input input-style input-lg icon-right">
                                                                <Input type="text" className="form-control emojiPicker"
                                                                    placeholder="Comment...." />
                                                                <Link to="#">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-2 iw-14 ih-14"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                                                                </Link>
                                                                {/* <div className='Media_Modal_send_Icon'>
                                                                    <img src={Snd} width={14} height={14} alt="snd" />

                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                }

            </div>
        )

    }

    /*  ---------------------- Music Array --------------------  */

    const musicArray = [
        {
            id: 1,
            name: 'Origin of Revolution ',
            duration: '06:27',
            episode: '01',
            listnerCount: '105',
            live: true
        },
        {
            id: 2,
            name: 'Year 1947',
            duration: '06:27',
            episode: '02',
            listnerCount: '105',
            live: false
        },
        {
            id: 3,
            name: 'First Drill',
            duration: '06:27',
            episode: '03',
            listnerCount: '105',
            live: true
        },
        {
            id: 4,
            name: 'Control',
            duration: '06:27',
            episode: '04',
            listnerCount: '105',
            live: false
        },
        {
            id: 5,
            name: 'Politics',
            duration: '06:27',
            episode: '05',
            listnerCount: '105',
            live: true
        },
        {
            id: 6,
            name: 'End is Near',
            duration: '06:27',
            episode: '06',
            listnerCount: '105',
            live: false
        },
        {
            id: 7,
            name: 'Suspense',
            duration: '06:27',
            episode: '07',
            listnerCount: '105',
            live: true
        },
    ]

    // useEffect(() => {
    //     document.addEventListener('scroll', (e) => {

    //         let lastKnownScrollPosition = window.scrollY;
    //         if (lastKnownScrollPosition > 50) {
    //             setScroll(true)
    //         } else {
    //             setScroll(false)
    //         }
    //     })
    // },[])

    // useEffect(() => {

    //     audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    //     audioRef.current.addEventListener('ended', handleEnded);
    //     return () => {
    //         audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    //         audioRef.current.removeEventListener('ended', handleEnded);
    //     };

    // },)


    return (
        <div >
            <ShareModal />
            <CommentModal />
            <Card style={{ marginBottom: '6rem' }} className='seriespodcast'>

                {/*  ----------------------  Header --------------------  */}

                <div className='p-3 border-bottom'>
                    <h3><strong>Playing  Podcast</strong></h3>
                </div>
                <CardBody>

                    {/*  ----------------------  Series or Audio Thumb Card --------------------  */}

                    <Row>
                        <Col xl='5' sm='12'>
                            <div className='my-2'>
                                <Card>
                                    <CardImg
                                        alt="Card image cap"
                                        src="https://sociomee.s3.eu-central-1.amazonaws.com/postMedia/EERt5AjAOGqLe.jpg"
                                        style={{
                                            height: 300,
                                            objectFit: 'cover'  
                                        }}
                                        width="100%"
                                    />
                                </Card>
                            </div>
                        </Col>
                        <Col>
                            <div className='m-2'>
                                <h2><b>Kahani mein Twist</b></h2>
                                <p className='pt-2'>This podcast includes short stories inspired from day to day life but every story has an unexpected turn of events that might leave you shocked, surprised and maybe amused! For feedbacks and suggestions please dm on Sociomee handle @cat.cattier.cattiest.</p>
                                <div className='d-flex align-items-center py-2'>
                                    <img className='rounded-circle' width={20} height={20} src={Podcast} alt=''/>
                                    <h5 className='ml-1'>Publisher name</h5>&nbsp;&nbsp;
                                    <Badge color='mx-1 rounded' pill style={{background:'#7EB26233',color:'#81C14B'}}>
                                       Free
                                    </Badge>
                                </div>
                                <i><h4 className='text-muted pt-1 pb-2'>Posted on <span className='font-weight-normal'>12 Apr 2021</span></h4></i>
                                <Row>
                                    <Col xl='2'>
                                        <div onClick={handlePlayPause1} className='my-3'>
                                            {isPlaying1 ?
                                                <Button outline color='primary'><Pause fill='#FFFFFF' size={15} />&nbsp; Pause </Button> :
                                                <Button color='primary'> <Play fill='#FFFFFF' size={15} />&nbsp;Play</Button>
                                                
                                            }
                                        </div>
                                          {/* <Button color='primary'>Pay to Play</Button>
                                          <p  className='paid-text'>Rs 3000</p> */}
                                    </Col>
                                </Row>
                                <Row className='d-flex' >
                                    <Col xl='10' xs='10' >
                                        <div className='d-flex justify-content-start align-items-center pt-3'>
                                            {
                                                LikeIcon ? <div className='mr-2'>
                                                    <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={ActiveCirclelike} alt='shareIcon' /> <span>50</span>
                                                </div> : <div className='mr-2'>
                                                    <img style={{ cursor: 'pointer' }} onClick={() => SetLikeIcon(!LikeIcon)} width={35} src={Circlelike} alt='shareIcon' /> <span>49</span>
                                                </div>
                                            }

                                            <div className='mx-2'>
                                                <img style={{ cursor: 'pointer' }} onClick={toggleComment} width={35} src={CommentLatest} alt='shareIcon' /> <span> 25</span>
                                            </div>

                                            <div className='ml-2'>
                                                <img width={35} style={{ cursor: 'pointer' }} onClick={toggleForward} src={ShareLatest} alt='shareIcon' /> <span> 25</span>
                                            </div>
                                            <div className='ml-3 d-flex align-items-center rem-mob-view'>
                                                <div className='p-2 rounded-circle' style={{ background: '#E5E5E5' }}><Headphones size={21} color='#808080' /></div> <span style={{ paddingLeft: '0.2rem' }}> 25</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl='2' xs='2' className='d-flex justify-content-center' >
                                        <div onClick={handleBookmark} style={{ padding: '4px', borderRadius: '5px', cursor: 'pointer' }} className='d-flex justify-content-center align-items-center pt-3'  >
                                            {BookmarkIcon ? <Bookmark color='#808080' /> : <Bookmark color='#81C14B' fill='#81C14B' />}
                                        </div>
                                    </Col>
                                </Row>


                            </div>
                        </Col>
                    </Row>

                    {/*  ----------------------  Scroll per header --------------------  */}
                    <div className='hidden-mobile'>
                        {scoll === true ? <DynamicHeader /> : null}
                    </div>

                    {/*  ---------------------- Episode Management --------------------  */}

                    <div className='border rounded p-3 rem-mob-view'>
                        <audio ref={audioRef} src={audioUrl[selectedAudio]}></audio>  {/*<<-------- Hidden Audio Bar --------*/}
                        <CardText>
                            {/* <h3><strong>Episode ({musicArray.length})</strong></h3> */}
                            <h3><strong>Next in Queue (19)</strong></h3>
                        </CardText>
                        <Row>
                            <Col xl='12'>
                                {
                                    musicArray.map((item, index) => {
                                        return <div key={index} className='MusicList my-3 p-1'  onClick={()=>{setMusicBar(true);}}   >
                                            <div className='d-flex mw-100'>
                                                <div className='position-relative'>
                                                    <img width={45} height={40} className='rounded' src="https://sociomee.s3.eu-central-1.amazonaws.com/postMedia/EERt5AjAOGqLe.jpg" alt=''/>
                                                    {isPlaying ? null : <img className='seriesPlayForIcon' width={25} src={PlayIcon} alt=''/>}

                                                </div>
                                                <div className='ml-3'>
                                                    <h4>{item.name}</h4>
                                                    <p className='text-muted'>{item.duration}</p>
                                                </div>
                                            </div>
                                            <div className='text-muted d-inline-block d-flex align-items-center justify-content-around'>
                                                <h5  >12th Apr 2021  ●  Ep {item.episode}</h5>
                                                <div className='d-flex align-items-center pl-2'>
                                                    {/* <img src={PodacastMic} /> */}
                                                    <Headphones color='#81C14B' />
                                                    <h5 className='ml-3'>{item.listnerCount}</h5>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-around' >
                                                <div className={isPlaying ? '' : 'd-block invisible'}> <img src={musicAnimation} alt='...' width={25} className='' /></div>
                                                <Button color='primary' className='rem-mob-view' onClick={() => setIsPlaying(!isPlaying)} >{!isPlaying ? <><Play fill='#FFFFFF' size={15} /> Play</> : <><Pause fill='#FFFFFF' size={15} /> Pause</>}</Button>
                                            </div>
                                            <div className='d-flex justify-content-around align-items-center'>
                                                {item.live ? <div className=''>
                                                    <Badge className='text-white bg-danger' pill>
                                                        Live
                                                    </Badge>
                                                </div> : <div className='px-4'>
                                                    <ArrowDownCircle color='#81C14B' />
                                                </div>}

                                                <div>
                                                    <UncontrolledButtonDropdown direction='up'>
                                                        <DropdownToggle color='flat-primary'>
                                                            <MoreVertical color='#6E6B7B' />
                                                        </DropdownToggle>
                                                        {/* top: '-200px', */}
                                                        <DropdownMenu style={{  left: '-171px', zIndex: '10000' }} className='existingDrop'>
                                                            <DropdownItem onClick={handleFastForward} tag='a'><Repeat size={15} />&nbsp;Repeat</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a' onClick={toggleForward} > <Share size={15} />&nbsp; Share</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a' onClick={handleBookmark} ><Bookmark size={15} />&nbsp; Bookmark</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'> <Eye size={15} />&nbsp; View all episodes</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'><User size={15} />&nbsp; View creator’s profile</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'> <AlertTriangle size={15} />&nbsp; Report</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Col>
                        </Row>

                    </div>

                    {/*----------------------------------------------- Mobile List----------------------------------------------- */}

                    <div className='border rounded p-3 d-xl-none '>
                        <audio ref={audioRef} onT src={audioUrl[selectedAudio]}></audio>  {/*<<-------- Hidden Audio Bar --------*/}
                        <CardText >
                            <h3><strong>Episode (5)</strong></h3>
                        </CardText>
                        <Row className=' rem-web-view'>
                            <Col xl='12'>
                                {
                                    musicArray.map((index) => {
                                        return <div className='d-flex justify-content-between align-items-center py-3' onClick={()=>{setMusicBar(true);}}>
                                            <div className='d-flex'>
                                                <div className='position-relative'>
                                                    <img width={50} height={50} className='rounded' src='https://www.androidguys.com/wp-content/uploads/2015/12/Wave-Wallpapers-1.jpg' />
                                                    <img className='seriesPlayForIcon' width={25} src={PlayIcon} />
                                                </div>
                                                <div className='ml-3'>
                                                    <h4>Rättegångspodden</h4>
                                                    <p className='text-muted'>06:27</p>
                                                    <div className='text-muted'>
                                                        <h5>12th Apr 2021  ●  Ep 02</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <div>
                                                    <UncontrolledButtonDropdown direction='up' className="threedot">
                                                        <DropdownToggle color='flat-primary'>
                                                            <MoreVertical color='#6E6B7B' />
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{top: '-200px', left: '-171px', zIndex: '10000' }} className='existingDrop'>
                                                            <DropdownItem onClick={handleFastForward} tag='a'>Repeat</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a' onClick={toggleForward}>Share</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'>Download</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a' onClick={handleBookmark} >Bookmark</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'>View all episodes</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'>View creator’s profile</DropdownItem>
                                                            <DropdownItem divider></DropdownItem>
                                                            <DropdownItem tag='a'>Report</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Col>
                        </Row>

                    </div>
                </CardBody>
            </Card>

            {/*  ---------------------- Music Footer --------------------  */}

            <PodcastFooter />

        </div>
    )
}

export default SeriesPodcastView