import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import MpFormTabs from '../MpFormComponents/MpFormTabs'
import MpFormInput from '../MpFormComponents/MpFormInput'
import MpFormDropdowns from '../MpFormComponents/MpFormDropdowns'
import MpFormLocationInput from '../MpFormComponents/MpFormLocationInput'
import MpFormPriceInput from '../MpFormComponents/MpFormPriceInput'
import MpFormDiscription from '../MpFormComponents/MpFormDiscription'
import SingleImagePink from '../img/singleImagePink.png'
import MpImageUploadSection from '../MpFormComponents/MpImageUploadSection'
import Header from '../../Header'

import LeftSidebar from '../../LeftSidebar'
import RightSidebar from '../../RightSidebar'
import MpFormCompoHeader from '../MpFormComponents/MpFormCompoHeader'
import MpBreadCrumb from '../MpFormComponents/MpBreadCrumb'
import { useDispatch, useSelector, } from "react-redux";
import { getSingleProductAction, editMpProduct } from "../../../Services/Actions/MarketPlace/getAllProductsAction";
import { useLocation, useNavigate } from 'react-router-dom';
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert';








const MpEditProductShell = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    let navigate = useNavigate();
    const data = location.state;
    const marketPlaceId = data?.marketPlaceId;
    let user = JSON.parse(localStorage.getItem('sociomeeUser'))



    // Dummy data for the components 
    const carParking = { title: "Car Parking", data: ["1+", "2+", "3+", "4+"] }
    const bachelor = { title: "Car Parking", data: ["Yes", "No"] }
    const aminities = { title: "Additional Amenities ", choices: ["Near to Metro Station", "Near to Airport", "Only In Industry Area", "Near to Railway Station"] }
    const TabOne = { title: "Type*", data: ["Apartments", "Houses & Villas", "Builder Floors", "Shops & Offices"] }
    const TabTwo = { title: "Bedrooms*", data: ["1+", "2+", "3+", "4+", "5+", "Studio"] }
    const TabThree = { title: "Bathrooms*", data: ["1+", "2+", "3+", "4+", "5+", "Attached"] }
    const TabFour = { title: "Furnishing*", data: ["Furnished", "Semi-Furnished", "Unfurnished"] }
    const TabFive = { title: "Listed by*", data: ["Builder", "Dealer", "Owner"] }
    const TabSix = { title: "Pet Allowed?*", data: ["Yes", "No"] }
    const InputOne = { title: "Super Builtup Area (sqft)", placeholder: "Enter super builtup area here" }
    const InputTwo = { title: "Carpet Area (sqft)", placeholder: "Enter carpet area here" }
    const InputThree = { title: "Total Monthly Maintenance*", placeholder: "Enter your monthly maintenance here" }
    const InputFour = { title: "Total Floors", placeholder: "How many floors do you have?" }
    const InputFive = { title: "Location", placeholder: "Enter your address" }
    const InputSix = { title: "Title*", placeholder: "Enter your title" }
    const inputSeven = { title: "Set Price*", placeholder: "Enter your price for your property", choices: ["USD", "IND", "UAE"] }





    //===================== dispacting the values==========================

    useEffect(() => {
        let body = { marketPlaceId: marketPlaceId };
        dispatch(getSingleProductAction(body));
    }, [dispatch,]);

    const { products, error } = useSelector(state => state.getSingleMarketPlaceProduct);
    const singleProductData = products[0];
    //  console.log("this is single product data=============>>>>",singleProductData)


    const [categoryName, setCategoryName] = useState("category")
    const [subCategoryName, setSubCategoryName] = useState("subcategory")
    const [subChildCategoryName, setSubChildCategoryName] = useState("subChildCategory")

    const [isCall, setIsCall] = useState(false);
    const [isMsg, setIsMsg] = useState(false)

    const [productLocation, setProductLocation] = useState('')
    const [locationLat, setLocationLat] = useState(null)
    const [locationLong, setLocationLong] = useState(null)


    const [title, setTitle] = useState('')
    const [price, setPrice] = useState()
    const [description, setDescription] = useState()
    const [communicationMode, setCommunicationMode] = useState('');
    const [media, setMedia] = useState([])
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [attributes, setAttributes] = useState([])
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [currencyId, setCurrencyId] = useState("855d4f70-d3a0-4723-aa03-b1ede6442586")




    useEffect(() => {
        if (singleProductData) {
            setTitle(singleProductData?.title);
            setPrice(singleProductData?.price);
            setProductLocation(singleProductData?.location);
            setDescription(singleProductData?.description);
            const cleanedArray = singleProductData?.mpMediaLists?.length
                ? singleProductData.mpMediaLists.map(({ id, createdAt, capation, marketPLaceId, ...rest }) => rest)
                : [];
            setMedia(cleanedArray)
            // Initialize the checkbox states based on the API data
            if (singleProductData?.communicationMode === 'Call') {
                setIsCall(true);
                setIsMsg(false);
            } else if (singleProductData?.communicationMode === 'Chat') {
                setIsCall(false);
                setIsMsg(true);
            } else if (singleProductData?.communicationMode === 'Both') {
                setIsCall(true);
                setIsMsg(true);
            } else {
                setIsCall(false);
                setIsMsg(false);
            }
        }
    }, [singleProductData]);

    // Handling checkbox changes
    const handleCheckboxChange = (mode) => {
        if (mode === 'call') {
            setIsCall(!isCall);
        } else if (mode === 'msg') {
            setIsMsg(!isMsg);
        }
    };

    // Update communicationMode based on checkboxes
    useEffect(() => {
        if (isCall && isMsg) {
            setCommunicationMode('Both');
        } else if (isCall) {
            setCommunicationMode('Call');
        } else if (isMsg) {
            setCommunicationMode('Chat');
        } else {
            setCommunicationMode('');
        }
    }, [isCall, isMsg]);

    // setiing the title of the product
    const handleProductTitle = (productTitle) => {
        setTitle(productTitle);
    };

    //setting the price of the product
    const handleProductPrice = (price) => {
        setPrice(price.value);
    }
    //setting the location of the product
    const handleProductLocation = (location, coords) => {
        setProductLocation(location); //location stirng
        setLocationLat(coords?.latitude) //location lat
        setLocationLong(coords?.longitude) //location long 

    }
    //setting the description of the product
    const handleProductDescription = (description) => {
        setDescription(description);
    }


    // Memoizing the media data handler
    const handleMediaData = useCallback((mediaArray) => {
        // Remove S3 URL from every fileURL
        const s3UrlPattern = /https:\/\/[^/]+\.com\//;

        const updatedMedia = mediaArray && mediaArray.length > 0
            ? mediaArray.map(item => ({
                ...item,
                fileURL: s3UrlPattern.test(item.fileURL) ? item.fileURL.replace(s3UrlPattern, "") : item.fileURL
            }))
            : []; // Return an empty array if mediaArray is null or empty


        setMedia(updatedMedia);

        const foundMedia = updatedMedia.find(item => item.sequence === 0);
        setThumbnailURL(foundMedia ? foundMedia.fileURL : '');
    }, []);



    //creatin object for passing to component
    const categoryData = {
        categoryName: singleProductData?.mpCategory?.name,
        subCategoryName: singleProductData?.mpSubCategory?.name,
        subChildCategoryName: ''
    }


    //hittiung update post api
    const handleUpdatePost = async () => {
        try {
            //final body of the api
            const body = {
                marketPlaceId: singleProductData?.id,
                title: title,
                description: description,
                price: price,
                communicationMode: communicationMode,
                currencyId: currencyId,
                media: media,
                thumbnailURL: thumbnailURL,
                location: productLocation,
                attributes: attributes,
                isSold: singleProductData?.isSold,
                locationLAT: locationLat || singleProductData?.locationLAT,
                locationLONG: locationLong || singleProductData?.locationLONG,
                isOwner: singleProductData?.isOwner,
                currency: singleProductData?.currencyId,
                allowNotification: singleProductData?.allowNotification,

            }
            //handling mandatory field error
            if (!title || !price || !communicationMode || !productLocation || !media.length) {
                setFormErrorMessage('Please fill all required fields.');
                return;
            }
            setFormErrorMessage('')
            const editProductResult = await editMpProduct(body)
            sweetAlertConfirmation("Succuesfully Updated.")
            navigate("/marketplace")
        } catch (error) {
            console.log("error during update product", error)
        }
    }






    return (
        // ================== The whole Sell Page Component return ===========================
        <Fragment>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    {/* <div className='cardmobile'> <MpFormCompoHeader /></div> */}

                    <Card className="mt-3 border markettop">
                        {/* ============= header Of the sell Page ================== */}
                        <CardHeader className="d-flex align-items-center justify-content-center p-3">
                            <h2 style={{ fontWeight: "600", color: "#4D4D4D" }}>
                                EDIT YOUR PRODUCT
                            </h2>
                        </CardHeader>
                        <CardBody >
                            <div className='row border-bottom'>
                                <div className="Mp_SellForm_Head p-4 border-bottom col-lg-12">
                                    <h4>SELECTED CATEGORY</h4>
                                    <div className='d-flex align-items-center gap-3 mt-2'>
                                        <MpBreadCrumb categoryData={categoryData} />
                                    </div>
                                </div>
                                <div className="Mp_FormIncludeSomeDetails px-4 mt-4 col-lg-12">
                                    <h4 className='Mp_Color4D_Font6' >INCLUDE SOME DETAILS</h4>
                                </div>
                                {/* <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">

                                        <div className="col-xl-6 mb-4">
                                                <MpFormTabs data={TabOne} />
                                            </div>
                                        <div className="col-xl-6 mb-4">
                                                <MpFormTabs data={TabTwo} />
                                            </div>
                                        <div className="col-xl-6 mb-4">
                                                <MpFormTabs data={TabThree} />
                                            </div>
                                        <div className="col-xl-6 mb-4">
                                                <MpFormTabs data={TabFour} />
                                            </div>
                                </div> */}
                                {/* <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={TabFive} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={TabSix} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputOne} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputTwo} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputThree} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormInput data={InputFour} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={carParking} />
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <MpFormTabs data={bachelor} />
                                    </div>
                                    <div className="col-xl-6  mb-4">
                                        <MpFormDropdowns data={aminities} />
                                    </div>
                                    <div className="col-xl-6  mb-4 ">
                                        <MpFormLocationInput data={InputFive} />
                                    </div>

                                    <div className="col-xl-6 mb-4">
                                        <div className=" mb-4">
                                            <MpFormInput data={InputSix} />
                                        </div>

                                        <div className=" mb-4">
                                            <MpFormPriceInput data={inputSeven} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 mb-4">
                                        <div className="">
                                            <MpFormDiscription />
                                        </div>
                                    </div>

                                </div> */}
                                <div className="Mp_Tab_Compo px-0 py-1 d-flex col-md-12 d-flex flex-wrap">
                                    <div className="col-xl-6 mb-4">
                                        <div className=" mb-4">
                                            <MpFormInput data={InputSix} onDataPass={handleProductTitle} value={title} />
                                        </div>
                                        <div className=" mb-4">
                                            <MpFormPriceInput data={inputSeven} onDataPass={handleProductPrice} value={price} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6  mb-4 ">
                                        <div className=" mb-4">
                                            <MpFormLocationInput data={InputFive} onDataPass={handleProductLocation} value={productLocation} />
                                        </div>
                                        <div className="mb-4">
                                            <MpFormDiscription onDataPass={handleProductDescription} value={description} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* ============================= upload Image section =-============================ */}
                            <MpImageUploadSection onMediaDataChange={handleMediaData} value={media} />
                            {/* =================== Seller Details =============================== */}
                            <div className="row border-bottom">
                                <div className="col-md-6">
                                    <div className="Mp_FormIncludeSomeDetails px-3 mt-3">
                                        <h4>REVIEW YOUR DETAILS</h4>
                                    </div>
                                    <div className="Mp_Form_sellerProfile p-3">
                                        <div className='Mp_From_Seller mt-3' >
                                            <img src={`${process.env.REACT_APP_IMAGEURL}${user?.profileImage}`} alt="" className='img-fluid' />
                                            <div className='Mp_setOrderLeft_ImageText px-3' >
                                                <h6>Your name:</h6>
                                                <h5 className='Mp_singleVarient'>{user?.fullName}</h5>
                                                <h6 className='d-flex align-items-end justify-content-start gap-2' style={{ color: "#878992", fontWeight: "400" }}>{user?.mobile || "Not Available"}</h6>
                                            </div>
                                        </div>
                                        {/* <NavLink><u>edit Profile</u> </NavLink> */}

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="Mp_FormIncludeSomeDetails px-3 mt-3 ">
                                        <h4>COMMUNICATION MODE</h4>
                                    </div>
                                    <div className="Mp_Form_SellerCommunicaion p-3">
                                        <h5>Select your mode*</h5>
                                        <div className='d-flex align-items-center justify-content-start gap-3 mt-2'>
                                            <button
                                                className={`Mp_FormSeller_Btn ${isCall ? 'MpSellerBtnActive' : ''}`}
                                                onClick={() => handleCheckboxChange('call')}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={isCall}
                                                    onChange={() => handleCheckboxChange('call')}
                                                />
                                                <span>Call</span>
                                            </button>
                                            <button
                                                className={`Mp_FormSeller_Btn ${isMsg ? 'MpSellerBtnActive' : ''}`}
                                                onClick={() => handleCheckboxChange('msg')}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={isMsg}
                                                    onChange={() => handleCheckboxChange('msg')}
                                                />
                                                <span>MsgMee</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-flex-start py-4 px-3'>
                                <Button color="primary" className="Mp_FormPostNow_Btn" onClick={handleUpdatePost}> Update Post</Button>
                                <div style={{ color: 'red', marginTop: '10px', marginLeft: '20px' }}>
                                    {formErrorMessage && <div>{formErrorMessage}</div>}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <RightSidebar />
            </div>

        </Fragment >
    )
}

export default MpEditProductShell
