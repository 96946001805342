import React from 'react'
import { MapPin, MessageCircle, PhoneCall } from 'react-feather'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

const BookmarkSellPost = () => {
    return (
        <div>
            <Row>
                <Col>
                    <Card style={{ borderRadius: '15px' }}>
                        <img className='w-100 rounded-top' height={200} src="https://picsum.photos/900/270?grayscale" alt='bannerImage' />
                        <CardBody className='p-0'>
                            <div className='p-3'>
                                <h3>Wooden Horse Sculpture on sale</h3>
                                <p className='text-muted my-2'>Home & Decor | Sculpture </p>
                                <p className='text-muted my-2'><MapPin size={18} /> Güntherstra, Frankfurt , Germany</p>
                            </div>
                            <div style={{ background: '#F6F6F6' }} className='d-flex justify-content-between my-2 p-3'>
                                <div>
                                    <h3>$ 41 (USD)</h3>
                                    <h5 className='text-muted'>In Stock  |  New</h5>
                                </div>
                                <div>
                                    <Button color='primary' className=' mr-2' ><svg width="27" height="30" viewBox="0 0 27 30" fill="transparent" xmlns="http://www.w3.org/2000/svg" className='BookMarkSvgIcon'   >
                                        <path d="M2.94338 15.6301L2.94339 15.6301C3.20276 16.1179 3.52878 16.5491 3.90441 16.9181C1.44861 18.3249 0.525961 21.4188 1.864 23.9354C3.23387 26.512 6.44814 27.4949 9.04262 26.1446L9.04264 26.1446L17.1708 21.9141L20.9267 25.9877L22.2281 27.3992V25.4794V19.2593C24.5241 17.7996 25.3555 14.8128 24.0566 12.3699L24.0566 12.3699C23.7972 11.8821 23.4712 11.4509 23.0956 11.0819C25.5514 9.67507 26.474 6.58124 25.136 4.06459C23.7661 1.48803 20.5519 0.505034 17.9574 1.85539L18.3036 2.52067L17.9574 1.85539L5.17566 8.50798C5.17566 8.50798 5.17566 8.50798 5.17566 8.50798C2.57678 9.86062 1.57104 13.049 2.94338 15.6301Z" stroke="#ffffff" stroke-width="2.5" />
                                    </svg></Button>
                                    <Button color='primary' className='px-4' ><PhoneCall size={14} /></Button>
                                </div>
                            </div>
                            <div className='p-3'>
                                <h5>
                                    Happy brirthday <span className='text-primary'>@DwyaneJhonson</span> Hi he is my cool dad. Today is his birthday and I gifted him this amazing mobile and headphone....<span className='text-primary'>more</span>
                                </h5>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default BookmarkSellPost