import React, { useState,useEffect } from 'react'
import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import ProfileMenu from './ProfileMenu';
import ProfileCover from './ProfileCover';
import { Link, NavLink, redirect, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap';
import Car from './Img/Car.png'
import { BellOff, Bell, Edit2, EyeOff,BellOn, Link2, MapPin, MessageCircle, MoreHorizontal, MoreVertical, Send, Sliders, Trash2 } from 'react-feather';
import like2 from '../../NewComponents/IMG/like2.svg'
import Bmw from "./Img/Bmw.png"
import { Fragment } from 'react';
import { Checkbox, Pagination, Stack } from '@mui/material';
import FlwrImg from './Img/FlwrImg.png'
import { useDispatch,useSelector } from 'react-redux';
import {getRecommendationPostsAction,deletePostAction} from '../../Services/Actions/UserProfile/getAllUserProfilePageAction'
import CreationMainSection from '../../components/Social-feed/CreationMainSection';
import DiscaedModal from '../../components/Social-feed/DiscaedModal';
import { sweetAlertConfirmation } from '../group-components/AllGroupsSections/SweetAlert';
import axios from 'axios';
import { SweetErrorAlert } from '../group-components/AllGroupsSections/SweetErrorAlert';
import { FaBell, FaBellSlash } from 'react-icons/fa'; // Using FontAwesome icons as an example


const MyRecommendation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const type = new URLSearchParams(window.location.search).get('type');
    const userId = new URLSearchParams(window.location.search).get('userId');
    let user = JSON.parse(localStorage.getItem('sociomeeUser'));
    const [pageIndex, setPageIndex]=useState(0);
    const [pageSize,setPageSize]=useState(10);
    const [searchKey,setSearchKey]=useState('');
    const [searchKey1,setSearchKey1]=useState('');
    const [success,setSuccess]=useState(false);
    const [error,setError]=useState('');
    const [deleteId,setDeleteId]=useState('');
    const [count,setCount]=useState(0);
    const [followerData,setFollowerData]=useState([]);
    const [followingData,setFollowingData]=useState([]);
    const [checkedItems, setCheckedItems] = useState([]);

    const getRecPosts = useSelector((state) => { return state.getRecommendPostsData });
    const {error: errorAllRecPosts, result: resultAllRecPosts } = getRecPosts
    useEffect(() => {
        dispatch(getRecommendationPostsAction("recommendation",pageSize,pageIndex,searchKey,userId));
        setSuccess(false)
      }, [success]);

      useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowers`,
                        { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setFollowerData(response.data.data.successResult.rows);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [searchKey1,pageIndex,pageSize]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('sociomeeUser'));
                if (user) {
                    const response = await axios.post(
                        `${process.env.REACT_APP_IPURL}/user/getUserFollowings`,
                        { searchKey:searchKey, pageIndex:pageIndex, pageSize:pageSize, sortBy:"userName" , countryIds:[] },
                        { headers: { Authorization: `Bearer ${user.token}` } }
                    );
                    setFollowingData(response.data.data.successResult.rows);
                } else {
                    setError('no user found');
                }
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }, [searchKey1,pageIndex,pageSize]);

      const RecommendationData = Array.isArray(resultAllRecPosts) ? resultAllRecPosts?.filter(post => post.caption && post.caption.toLowerCase().includes(searchKey.toLowerCase())).map(post => ({
            img: post.recommendationCoverImageURL ? (
              <img 
                src={post.recommendationCoverImageURL} 
                alt="recommendation-cover" 
                style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
                className='img-fluid RecommendationImg' 
              />
            ) : null,
            id: post.id,
            heading: post.recommendationCount,
            subHeading: post.caption,
            locationName: post.location,
            like: post.likesCount,
            comment: post.commentsCount,
            share: "Share",
            hide: "Hide from timeline",
            edit: "Edit",
            del: "Delete",
            copy: 'Copy Link',
            alt: post.notification
          }))
      : [];
   

    const [activeTab, setActiveTab] = useState(1000);
    const states=activeTab;
    const [postType, setPostType] = useState("event");
    const [currState,setCurrState]=useState(0)
    const [modal, setModal]=useState('')
    const [selectedImages,setSelectedImages]=useState('')
    const [path, setPath] = useState("/modal/");
    const [discard, setDisCard] = useState(false);
    const [feelingCategory,setFeelingCategory]=useState("");


    // =================event ==================

    const toggleDiscard = () => {
        setDisCard((preState) => !preState);
      };
      const toggleAllDiscard = () => {
        setModal(false);
        toggleDiscard(false);
    
      };

    const toggle = (path) => {

        console.log(path.indexOf("/modal/"),"   path.indexof(`modal`)")
        if (path.indexOf("/modal/" > -1)) {
          if (modal) {
            setActiveTab(parseFloat(path.replace("/modal/", "")));
        
            setPath(path);
           
            const index = parseFloat(path.replace("/modal/", ""));
        //   console.log("in toggle function",index)
            const postType ="event"
           
          } else {
        //   console.log("in toglle mode elese sections")
          const index = parseFloat(path.replace("/modal/", ""));
          
          
            setActiveTab(parseFloat(path.replace("/modal/", "")));
            setModal(true);
            setPath(path);
          
          }
        } else {
    
          setModal(false);
        }
      };

      const postCopyLink = async (id) => {
        const newCopyLink = `${process.env.REACT_APP_IPURL}/MyRecommendation/${id}`;
        await navigator.clipboard.writeText(newCopyLink);
        sweetAlertConfirmation(`Link Copied`);
      };
      

    const [deleteOpen, setDeleteOpen] = useState(false)
    const toggleDeleteOpen = () => {
        setDeleteOpen((prevState) => !prevState)
        setDeleteId('');
    }
    const deleteRec =(e,id)=>{
        e.stopPropagation();
        e.preventDefault();
        setDeleteId(id);
        setDeleteOpen(true)
    }    
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = (e) => {
        e.stopPropagation();
        setShareOpen((prevState) => !prevState)
    }

    const fetchData = async (noti, postId) => {
        try {
            const user = JSON.parse(localStorage.getItem('sociomeeUser'));
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/post/setPostNotification`,
                { notificationOff: noti, postId: postId, userId: user.id },
                { headers: { Authorization: `Bearer ${user.token}` } }
            );
    
            if (response.data) {
                sweetAlertConfirmation(`Successfully turned ${noti === 0 ? 'Off' : 'On'} notification`);
                setSuccess(true)
            } else {
                SweetErrorAlert("Something went wrong!");
            }
        } catch (error) {
            setError(error.message);
        }
    };
    
    const turnOnOff = (e, noti, postId) => {
        fetchData(noti, postId);
    };
    
    const handlePageChange = async(event, value) => {
        event.stopPropagation();
        setPageIndex(value-1);
      };

      const sharePost = () => {
        sweetAlertConfirmation("post Shared successfully")
        setShareOpen((prevState) => !prevState);
      };

      const handleChange=(e)=>{
        setSearchKey1(e.target.value);
      }

      const EditPost=()=>{
        sweetAlertConfirmation("Edit feature is in progress")
      }

    const navigateToSomewhere =(e,id)=>{
        e.stopPropagation();
        e.preventDefault();
        navigate(`/MyPostDetail/${id}`,{ state: { userId, type:"viewer" }})
    }

    const RecommendationCardData = () => (
        <Fragment>
            <div className="tab-section">
                <Row>    {
                        RecommendationData.map((data, index) => (
                            <Col lg="4 py-3">
                                <Card className="Recommendation_section">
                                {data.img ? (
                                <div className='Recommendation_Img' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={(e)=>navigateToSomewhere(e,data.id)}>
                                    <img
                                        src={data.img.props.src}
                                        alt={data.img.props.alt}
                                        style={{ width: '400px', height: '200px', objectFit: 'cover', align: 'center' }}
                                    />
                                </div>
                                ) : (
                                ""
                               )}
                                    <div className='Recommendation_Body_Section' onClick={(e)=>navigateToSomewhere(e,data.id)} >
                                        <p>{data.heading} Recommendations</p>
                                    </div>
                                    <CardBody >
                                        <div className='Recommendation_RightPosition '>
                                            <MoreHorizontal color='#4D4D4D' size={20} className="dropbtn-gal" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown drop-menu-gal recommendation">
                                                <ul>
                                                    {/* <li>
                                                        <Link to="#"><EyeOff size={16} color="#808080" /> {data.hide}</Link>
                                                    </li> */}
                                                    <li onClick={EditPost}>
                                                        <Link to="#"><Edit2 size={16} color="#808080" /> {data.edit}</Link>
                                                    </li> 
                                                    <li onClick={(e)=>deleteRec(e,data.id)} >
                                                        <Link to="#"><Trash2 size={16} color="#808080" /> {data.del}</Link>
                                                    </li>
                                                    <li onClick={toggleShareOpen}>
                                                        <Link to="#"><Send size={16} color="#808080" /> {data.share}</Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={() => {
                                                            postCopyLink(data?.id);
                                                            }}><Link2 size={16} color="#808080" /> {data.copy}</Link>
                                                    </li>
                                                    <li onClick={(e)=>turnOnOff(e,data.alt,data.id)}>
                                                        <Link to='#' >
                                                        {data.alt === 1 ? <Bell color="#808080" size={16} /> : <BellOff color="#808080" size={16} />}
                                                        {data.alt === 1 ? " Turn On Notification" : " Turn Off Notification"}
                                                       </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div onClick={(e)=>navigateToSomewhere(e,data.id)}>
                                        <div className='Recommendation_SubHeading'>
                                            <p>{data.subHeading}</p>
                                        </div>
                                        <div className='mt-3  Recommendation_Location'>
                                            <p><MapPin color='#808080' size={15} />&nbsp;{data.locationName} </p>
                                        </div>
                                        <hr />
                                        <div className='d-flex justify-content-evenly text-center Recommendation_Bottom_section' onClick={(e)=>navigateToSomewhere(e,data.id)}>
                                            <div className='Recommendation_btn'>
                                                <p className=' m-2 '><img src={like2} className='RecommendationImg img-fluid' /></p>
                                                <h4>{data.like}</h4>
                                            </div>
                                            <div className='Recommendation_btn'>
                                                <p className=' m-2 '><MessageCircle size={20} color="#999999" /></p>
                                                <h4>{data.comment}</h4>
                                            </div>
                                            <div className='Recommendation_btn'>
                                                <p className=' m-2'><Send size={20} color="#999999" /></p>
                                                <h4>{data.share}</h4>
                                            </div>
                                        </div>
                                        </div>


                                    </CardBody>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        count={(resultAllRecPosts?.length/12)+1} 
                        page={pageIndex + 1}
                        onChange={handlePageChange}
                        className="PaginationCounts"
                        />
                    </div>
            </div>
        </Fragment>
    )

    const deleteAllPost = async (e) => {
        e.stopPropagation();
        try {
          await dispatch(deletePostAction(deleteId));
          setSuccess(true);
          sweetAlertConfirmation('Successfully deleted');
          setDeleteOpen((prevState) => !prevState);
        } catch (error) {
         setError(error);
        }
      };

      const shareAll = () => {
        sweetAlertConfirmation(`Your post shared`);
        setShareOpen((prevState) => !prevState);
        };

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setCheckedItems(prevCheckedItems => {
      if (prevCheckedItems.includes(id)) {
        // Uncheck item
        return prevCheckedItems.filter(itemId => itemId !== id);
      } else {
        // Check item
        return [...prevCheckedItems, id];
      }
    });
  };

  // Determine if 'Share all' should be disabled
  const isShareAllDisabled = checkedItems.length === 0;

    return (
        <>
            <Header />
            <div className="page-body container-fluid profile-page">
                <LeftSidebar />
                <div className="page-center">
                    <ProfileMenu />
                    {/* <HeadingSection /> */}
                    <div className="container-fluid section-t-space px-0">
                <div className="page-content">
                    <div className="content-center w-100">
                        <div className="gallery-page-section section-b-space">
                            <div className="card-title">
                                <h3>Recommendation ({RecommendationData.length||0})</h3>
                                <div className="right-setting">
                                <div className="profile-search-blk ">
                                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            <input 
                                            type="text" 
                                            className="form-control" 
                                            name="search" 
                                            placeholder="Find ..." 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#peopleSearch" 
                                            value={searchKey}
                                            onChange={(e) => {setSearchKey(e.target.value)}}
                                            />
                                        </div>
                                        <Link onClick={() => {
                                    setModal(true);
                                    toggle("/modal/7.6");
                                    }} className="btn btn-outline-green ms-3">Create Recommendation</Link>
                                </div>
                            </div>
                            <RecommendationCardData />
                            </div>
                     </div>
                </div>
                </div>
                </div>
                <div className='vertically-centered-modal'>
                {deleteOpen !== false && (
                        <Modal
                        isOpen={deleteOpen}
                        toggle={toggleDeleteOpen}
                        className="modal-dialog-centered"
                        >
                        <ModalHeader className="d-flex justify-content-center Delete_Media_modal_section ">
                            <h4>Delete Recommendation Post</h4>
                        </ModalHeader>
                        <ModalBody>
                            <div className="d-flex justify-content-center modalHeading_Section">
                            <p>Do you really want to delete the selected Recommendation Post ?</p>
                            </div>
                            <h1 style={{ textAlign: "center", marginTop: "20px" }}>
                            <small>If</small> YES! <small>please click on ok</small>
                            </h1>
                            <div className="d-flex justify-content-center  mt-4">
                            <Button onClick={toggleDeleteOpen} outline style={{ width: '100px' }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={(e)=>deleteAllPost(e)}
                                color="primary"
                                className="ml-3 "
                                style={{ width: '100px' }}
                            >
                                OK
                            </Button>
                            </div>
                        </ModalBody>
                        </Modal>
                    )}
                </div>
                <div className='vertically-centered-modal'>
                {shareOpen && (
        <Modal
          isOpen={shareOpen}
          toggle={toggleShareOpen}
          className="modal-dialog-centered"
        >
          <div className="w-100 border-bottom">
            <div className="mx-3 py-3 d-flex justify-content-between Heading_Invite_Section">
              <h4>Share People</h4>
              <span style={{ cursor: "pointer" }} onClick={sharePost}>
              </span>
            </div>
          </div>
          <ModalBody>
            <Row className="mt-2">
              <Col md="8" className="Like_search">
                <div className="profile-search-blk w-100">
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="search-svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search..."
                    value={searchKey1}
                    autoFocus
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col md="4" className="d-flex justify-content-end">
                <UncontrolledButtonDropdown color="secondary" outline>
                  <DropdownToggle
                    outline
                    className="border border-muted ml-3"
                  >
                    <Sliders className="Slider_Filter" size={12} />
                    &nbsp;&nbsp;Filter
                  </DropdownToggle>
                  <DropdownMenu className="DropDown_Group_item">
                    <DropdownItem onClick={() => setCount(0)}>
                      Followers
                    </DropdownItem>
                    <DropdownItem onClick={() => setCount(2)}>
                      Followings
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
              <Row className="mt-3">
                {count === 0 && followerData?.filter(data =>
                  data.fullName.includes(searchKey1) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey1)) ||
                  data.followBackStatus.includes(searchKey1)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
                {count === 2 && followingData?.filter(data =>
                  data.fullName.includes(searchKey1) ||
                  (data.totalFollowersCount && data.totalFollowersCount.toString().includes(searchKey1)) ||
                  data.followBackStatus.includes(searchKey1)
                ).map((data) => (
                  <Fragment key={data.id}>
                    <div className="group-detail-page-blk">
                      <div className="Follower-memberslist-block">
                        <div className="group-member-img-cont-blk">
                          <img src={data.profileImage} alt="img" className='img-fluid' />
                          <div className="member-cont-blk">
                            <h4>{data.fullName}</h4>
                            <p className='text-muted'>
                              {data?.totalFollowersCount ? data.totalFollowersCount : 0} Followers
                            </p>
                            <div className="Post_Count">
                              {data.followBackStatus}
                            </div>
                          </div>
                        </div>
                        <div className='Block-btns-blk'>
                          <Checkbox
                            checked={checkedItems.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                            color="primary"
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </Row>
              <Col
                md="6"
                className="d-flex justify-content-end SubHeading_Invite_Section"
              >
                <p
                  style={{ cursor: isShareAllDisabled ? "not-allowed" : "pointer" }}
                  onClick={!isShareAllDisabled ? shareAll : undefined}
                  className={isShareAllDisabled ? 'disabled' : ''}                
                >
                  Share all
                </p>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
                </div>
                <RightSidebar />
                <CreationMainSection
                states={states}
                currState={currState}
                    setModal={setModal}
                    modal={modal}
                    setSelectedImages={setSelectedImages}
                    selectedImages={selectedImages}
                    path={path}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    postType={postType}
                    setPostType={setPostType}
                    toggle={toggle}
                    toggleDiscard={toggleDiscard}
                    feelingCategories={feelingCategory}
                />
                <DiscaedModal
                    discard={discard}
                    toggleDiscard={toggleDiscard}
                    toggleAllDiscard={toggleAllDiscard}
                />
            </div>

        </>
    )
}

export default MyRecommendation