import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import GoLive from "../../NewComponents/IMG/GoLive.svg"
import Shotz from '../../NewComponents/IMG/shotz.svg'
import User from '../../NewComponents/IMG/users.svg'
import Post from '../../NewComponents/IMG/CreatePost.svg'
import Top from '../../NewComponents/IMG/Top.svg'
import Treanding from '../../NewComponents/IMG/Treanding.svg'
import ShotzGrey from '../../NewComponents/IMG/ShotzGrey.svg'

const HeaderCenter = () => {

    //============== Start_CenterHeaderData_Array ========================//
    const CenterHeaderData = [
        {
            toolTipId: "tooltipForHome",
            toolTipName: "Home",
            Img: "/assets/images/home-menu.svg",
            link: "/Home"
        },
        {
            toolTipId: "tooltipForGoLive",
            toolTipName: "GoLive",
            Img: GoLive,
            link: "/GoLive"
        },
        {
            toolTipId: "tooltipForShotz",
            toolTipName: "Shotz",
            Img: ShotzGrey,
            link: "/ExploreShotz"
        },
        {
            toolTipId: "tooltipForGroup",
            toolTipName: "Group",
            Img: User,
            link: "/Group"
        },
        // {
        //     toolTipId: "tooltipForPost",
        //     toolTipName: "Post",
        //     Img: Post,
        //     link: "/"
        // },
        {
            toolTipId: "tooltipForPost",
            toolTipName: "Top",
            Img: Top,
            link: "/Tops"
        },
        {
            toolTipId: "tooltipForTrending",
            toolTipName: "Trending",
            Img: Treanding,
            link: "/Trending"
        }
    ]
    //============== End_CenterHeaderData_Array ========================//


    //====================== Main_return_function ========================//
    return (
        <Fragment>
            <ul className="btn-group menu-btn-group">
                {CenterHeaderData.map((data, index) => (
                    <li className=" d-lg-block d-none header-btn home-btn cust-home-btn " id={data.toolTipId} key={index}>
                        <NavLink to={data.link} className="main-link">
                            <img src={data.Img} alt="Home-link" />
                        </NavLink>
                        <UncontrolledTooltip placement='right' target={data.toolTipId}>
                            {data.toolTipName}
                        </UncontrolledTooltip>
                    </li>
                ))
                }
            </ul>
        </Fragment>
    )
}

export default HeaderCenter